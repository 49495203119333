import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CategoryEvent, SupportCategory } from '@app/support/models';
import { SupportViewFacade } from '../../store/support-view/support-view-facade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SupportService } from '@app/support/services';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';

@Component({
  selector: 'app-sm-menu',
  templateUrl: './sm-menu.component.html',
})
export class SmMenuComponent implements OnInit {
  @Output()
  public selectItem = new EventEmitter<CategoryEvent>();

  public categories$: Observable<Array<SupportCategory>>;
  public userBusinessProfiles: AccountProfile[] = [];

  constructor(private supportService: SupportService, private riaUserFacade: SupportViewFacade) {}
  public ngOnInit(): void {
    this.riaUserFacade.getRIAUserFlag();
    this.categories$ = this.supportService.getCategories().pipe(
      map(categories =>
        categories.data.map(category => {
          return new SupportCategory(category);
        })
      )
    );
  }
  public onSelectItem(category: SupportCategory): void {
    this.selectItem.emit(category.mapToEvent());
  }
}
