import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { TileInformation, UIKitModalConfiguration } from '@uikit/tiles';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent {
  @Output() onSaveSettings = new EventEmitter<void>()
  @Output() onResetSettings = new EventEmitter<void>();
  @Input() tileName: string;
  @Input() tileId: number;
  @Input() instanceId: number;
  @Input() quickActionText: string;
  @Input() quickAction: () => void;
  @Input() showModal: boolean;
  @Input() modalConfig: UIKitModalConfiguration;
  @Input() removeTileModalConfig: UIKitModalConfiguration;
  @Input() isLoading: boolean;
  @Input() hideHeader: boolean;
  @Input() hasErrored: boolean;
  @Input() isFlipped: boolean = false;
  @Input() withSettings: boolean = true;
  @Input() canMoveFromContent: boolean;
  @Input() showBubble: boolean = false;
  @Input() preventTitleOverlap: boolean = false;
  @Input() hasHeader: boolean;
  @Input() teaserText: string; 
  @Input() bubbleNumber: number;

  isRemoveModalActive: boolean = false;

  defaultTileModalConfig: UIKitModalConfiguration = {
    title: 'Are you sure you want to remove this tile?',
    message: 'You can add it back anytime by selecting "Add Tiles" on the dashboard.',
    status: 'info',
    okText: 'Remove Tile',
    okAction: this.remove.bind(this),
    cancelText: 'Keep Tile',
    cancelAction: this.dismissModal.bind(this)
  };

  constructor(@Inject('$scope') private scope: ng.IScope) {}
    
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal) {
      this.showModal = changes.showModal.currentValue;
    }
    if (this.showBubble) {
      this.quickActionText = this.searchBubbleNumberPattern(this.bubbleNumber, this.quickActionText);
    }
  }

  flip(): void {
    this.isFlipped = !this.isFlipped;
  }

  resetSettings(): void {
    this.flip();
    this.onResetSettings.emit();
  }

  saveSettings(): void {
    this.onSaveSettings.emit();
    this.flip();
  }

  showConfirm(): void {
    this.isRemoveModalActive = true;
  }

  dismissModal(): void {
    this.isRemoveModalActive = false;
  }

  remove(): void {
    this.scope.$emit(
      '$uikitTileRemove',
      new TileInformation(+this.tileId, +this.instanceId)
    );
  }

  searchBubbleNumberPattern(bubbleNumberPattern: number, description: string) {
    let layoutEmbebed = `
      <span class="uikit-bubble-embebed-container">
        <span class="uikit-bubble-embebed-inner">
          <span class="uikit-bubble">
            <span class="uikit-bubble-number">${bubbleNumberPattern}</span>
          </span>
        </span>
      </span>
    `;
    let newDescription = description.replace(bubbleNumberPattern.toString(), layoutEmbebed);
    return newDescription;
  }
}
