<div class="d-flex flex-column-reverse align-items-center terms-and-conditions">
  <div class="col-11 col-md-8 col-xl-7 loader-container">
    <app-dot-loader [showWhen]="isLoading"></app-dot-loader>
  </div>

  <div *ngIf="!isLoading" class="col-12 form-group">
    <button
      type="submit"
      class="btn-ok button--lg"
      [ngClass]="{ 'btn-agree': brandName === 'axos' }"
      (click)="acceptTermsAndConditions()"
    >
      {{ buttonText }}
    </button>
  </div>

  <div class="pdf-container">
    <app-pdf-renderer [resourceUrl]="fileUrl"></app-pdf-renderer>
  </div>

  <div *ngIf="alertText" class="col-11 col-md-10 col-xl-7">
    <app-alert-notification alertType="error" (dismiss)="dismissAlert()">
      <p class="alert-text" [innerText]="alertText"></p>
    </app-alert-notification>
  </div>

  <div class="col-12 form-group">
    <h1 class="header__title">Electronic Agreement and Disclosure Statement</h1>
    <p class="header__subtitle">
      Review the agreement before moving forward.
    </p>
  </div>
</div>
