export interface HighlightOptions {
  indices: number[];
  growAmount: number;
  explode: boolean;
  shadowRadius: number;
  shadowColor: string;
}

const defaultOptions: HighlightOptions = {
  indices: [],
  growAmount: 15,
  explode: false,
  shadowRadius: 0,
  shadowColor: null,
};

export class Highlight {
  // Chart.js plugin for doughnut charts
  // Given a list of doughnut slices to highlight, this will grow (outwards or inwards) those slices
  //   this will also fade away (color opacity 50%) the slices that are not highlighted
  id: 'highlight';
  private originalSliceValues = {};

  beforeInit(chartInstance) {
    // Initialize plugin options, use default(s) if no value(s) provided
    chartInstance.options.plugins.highlight = {
      ...defaultOptions,
      ...chartInstance.options.plugins.highlight,
    };
  }

  beforeLayout(chartInstance) {
    // There must be a padding equal or greater than the grow amount
    const pluginOptions: HighlightOptions = chartInstance.options.plugins.highlight;
    const minimumPadding = pluginOptions.growAmount + pluginOptions.shadowRadius;

    const chartPadding = chartInstance.options.layout.padding;
    chartPadding.top = Math.max(chartPadding.top, minimumPadding);
    chartPadding.right = Math.max(chartPadding.right, minimumPadding);
    chartPadding.bottom = Math.max(chartPadding.bottom, minimumPadding);
    chartPadding.left = Math.max(chartPadding.left, minimumPadding);
  }

  beforeUpdate(chartInstance) {
    const pluginOptions: HighlightOptions = chartInstance.options.plugins.highlight;

    // Calculate color, not highlighted slices should be faded by 50%
    const dataset = chartInstance.config.data.datasets[0];
    dataset.backgroundColor = dataset.backgroundColor.map((color, i) => {
      if (pluginOptions.explode) return color;

      const [r, g, b] = this.parseColor(color);

      return `rgba(${r},${g},${b},${
        pluginOptions.indices.indexOf(i) === -1 && pluginOptions.indices.length ? '.5' : '1'
      })`;
    });
  }

  beforeRender(chartInstance) {
    const pluginOptions: HighlightOptions = chartInstance.options.plugins.highlight;
    if (!pluginOptions.indices.length) return; // no slices have been highlighted

    // Grow highlighted slices
    const meta = chartInstance.config.data.datasets[0]._meta;
    meta[Object.keys(meta)[0]].data.forEach(slice => {
      if (!this.originalSliceValues[slice._index]) {
        // backup original values, this is needed since this function is executed multiple times
        //   and we don't want to grow the slice more each time it is executed
        this.originalSliceValues[slice._index] = {
          outerRadius: slice._model.outerRadius,
          innerRadius: slice._model.innerRadius,
        };
      }

      if (pluginOptions.indices.indexOf(slice._index) >= 0) {
        // grow slice
        slice._model.outerRadius = this.originalSliceValues[slice._index].outerRadius + pluginOptions.growAmount;

        if (pluginOptions.explode) {
          slice._model.innerRadius = this.originalSliceValues[slice._index].innerRadius + pluginOptions.growAmount;
        }
      }
    });
  }

  afterDraw(chartInstance) {
    const pluginOptions: HighlightOptions = chartInstance.options.plugins.highlight;
    if (!pluginOptions.shadowRadius || !pluginOptions.shadowColor) return;

    const ctx = chartInstance.chart.ctx;
    const fillBackup = ctx.fill;
    ctx.fill = function() {
      ctx.save();
      ctx.shadowColor = pluginOptions.shadowColor;
      ctx.shadowBlur = pluginOptions.shadowRadius;
      fillBackup.apply(this, arguments);
      ctx.restore();
    };
  }

  resize() {
    // clear the backup so that it is recalculated on the next render
    this.originalSliceValues = {};
  }

  private parseColor(input) {
    // Given a color string (hex/rgb) it returns an array of [r,g,b]
    // source: https://stackoverflow.com/a/21966100/1893039
    if (input.substr(0, 1) === '#') {
      const collen = (input.length - 1) / 3;
      const fact = [17, 1, 0.062272][collen - 1];

      return [
        Math.round(parseInt(input.substr(1, collen), 16) * fact),
        Math.round(parseInt(input.substr(1 + collen, collen), 16) * fact),
        Math.round(parseInt(input.substr(1 + 2 * collen, collen), 16) * fact),
      ];
    } else {
      return input
        .split('(')[1]
        .split(')')[0]
        .split(',')
        .map(x => +x);
    }
  }
}
