import { NgModule } from '@angular/core';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { StoreModule } from '@ngrx/store';
import { initialPdpLayoutState, pdpLayoutReducer } from './core';

@NgModule({
  imports: [
    StoreModule.forFeature(AasStoreFeatures.PdpLayout, pdpLayoutReducer, {
      initialState: initialPdpLayoutState,
    }),
  ],
})
export class LayoutModule {}
