import * as angular from 'angular';

import { PaymentsHistoryComponent } from './activity/payments-history/payments-history.component';
import { PaymentOptionsComponent } from './activity/scheduled-payments/payment-options/payment-options.component';
import { AddCheckRecipientController } from './add-check-recipient/add-check-recipient.controller';
import { CheckConfirmationController } from './add-check-recipient/check-confirmation/check-confirmation.controller';
import { ElectronicCheckpointController } from './add-electronic-recipient/electronic-checkpoint/electronic-checkpoint.controller';
import { ElectronicConfirmationController } from './add-electronic-recipient/electronic-confirmation/electronic-confirmation.controller';
import { SearchRecipientController } from './add-electronic-recipient/search-recipient/search-recipient.controller';
import { WelcomeController } from './add-electronic-recipient/welcome/welcome.controller';
import { BillPayRouterHelper } from './bill-pay-router.helper';
import { BillPayConfig } from './bill-pay.config';
import { BillPayController } from './bill-pay.controller';
import { EStatementsAccountsController } from './e-statements/accounts/e-statements-accounts.controller';
import { EStatementsAuthenticationController } from './e-statements/authentication/e-statements-authentication.controller';
import { PayBillsHelper } from './pay-bills.helper';
import { PayConfirmationController } from './pay/multipay/pay-confirmation/pay-confirmation.controller';
import { PayReviewController } from './pay/multipay/pay-review/pay-review.controller';
import { PayController } from './pay/pay.controller';
import { PaymentsTabComponent } from './pay/recipients/payments-tab/payments-tab.component';
import { RecipientInformationComponent } from './pay/recipients/recipient-information/recipient-information.component';
import { RecipientsComponent } from './pay/recipients/recipients.component';
import { SchedulePaymentController } from './schedule-payment/schedule-payment.controller';
import { UpdateRecurrentPayment } from './update-recurrent-payment/update-recurrent-payment.controller';
import { UpdateSinglePayment } from './update-single-payment/update-single-payment.controller';
import { UpdatedPaymentConfirmation } from './update-single-payment/updated-payment-confirmation/updated-payment-confirmation.controller';
import { SbbTileComponent } from './sbb-tile/sbb-tile.component';
import { downgradeComponent } from '@angular/upgrade/static';
import { EStatementsConfirmationComponent } from '@app/bill-pay/components/e-statements/confirmation/e-statements-confirmation.component';
import { EStatementsComponent } from '@app/bill-pay/components/e-statements/e-statements.component';

export const billPay = angular
  .module('udb.billPay', ['ui.router'])
  /* controllers */
  .controller('BillPayController', BillPayController)
  .controller('PayController', PayController)
  .controller('WelcomeController', WelcomeController)
  .controller('SearchRecipientController', SearchRecipientController)
  .controller('SchedulePaymentController', SchedulePaymentController)
  .controller('ElectronicCheckpointController', ElectronicCheckpointController)
  .controller('ElectronicConfirmationController', ElectronicConfirmationController)
  .controller('AddCheckRecipientController', AddCheckRecipientController)
  .controller('EStatementsAuthenticationController', EStatementsAuthenticationController)
  .controller('EStatementsAccountsController', EStatementsAccountsController)
  .controller('UpdateRecurrentPayment', UpdateRecurrentPayment)
  .controller('UpdateSinglePayment', UpdateSinglePayment)
  .controller('CheckConfirmationController', CheckConfirmationController)
  .controller('UpdatedPaymentConfirmation', UpdatedPaymentConfirmation)
  .controller('PayReviewController', PayReviewController)
  .controller('PayConfirmationController', PayConfirmationController)
  .directive('appSbbTile', downgradeComponent({ component: SbbTileComponent }))
  .directive('appEStatementsConfirmation', downgradeComponent({ component: EStatementsConfirmationComponent }))
  .directive('appEStatements', downgradeComponent({ component: EStatementsComponent }))

  /* components */
  .component('paymentsHistory', PaymentsHistoryComponent)
  .component('paymentOptions', PaymentOptionsComponent)
  .component('recipients', RecipientsComponent)
  .component('recipientInformation', RecipientInformationComponent)
  .component('paymentsTab', PaymentsTabComponent)
  /* services */
  .service('billPayRouterHelper', BillPayRouterHelper)
  .service('payBillsHelper', PayBillsHelper)
  .config(BillPayConfig).name;
