<div class="simulate-deposit-container">
  <p>Simulate a Deposit</p>
  <div class="recommended-deposit-container" *ngIf="recommendedDepositAmount">
    <p>Our Recommendation</p>
    <div class="row">
      <div class="col-8">
        <p class="recommended-deposit-label">
          To reach your goal, deposit {{ recommendedDepositAmount | currency }} monthly.
        </p>
      </div>
      <div class="col-4 text-right">
        <button
          class="recommended-deposit-simulate-btn"
          (click)="simulateDeposit(recommendedDepositAmount, recommendedDepositFrequency)"
        >
          Simulate
        </button>
      </div>
    </div>
  </div>
  <div class="row form-wrapper">
    <div class="col-6">
      <label class="label">Deposit Amount</label>
      <div class="text">
        <input
          type="text"
          class="amount-textbox"
          id="simulateDepositAmount"
          #simulateDepositAmount
          mask="separator.2"
          thousandSeparator=","
          separatorLimit="100000"
        />
      </div>
    </div>
    <div class="col-6">
      <label class="label">Frequency</label>
      <select class="frequency-dropdown" id="simulateDepositFrequency" #simulateDepositFrequency>
        <option>Weekly</option>
        <option>Bi-weekly</option>
        <option>Monthly</option>
        <option>Quarterly</option>
      </select>
    </div>
  </div>
  <div class="row btn-container">
    <div class="col-sm-6 col-md-6 col-lg-6 ml-auto mr-auto text-center">
      <button
        class="button btn-ok btn-width"
        (click)="simulateDeposit(simulateDepositAmount.value, simulateDepositFrequency.value)"
      >
        Simulate Deposit
      </button>
    </div>
  </div>
  <div class="row btn-container">
    <div class="col-sm-6 col-md-6 col-lg-6 ml-auto mr-auto text-center">
      <button
        class="button--outline btn-cancel btn-width"
        id="makeDeposit"
        (click)="makeDeposit(simulateDepositAmount.value, simulateDepositFrequency.value)"
      >
        Make This Deposit
      </button>
    </div>
  </div>
</div>
