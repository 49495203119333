import { IStateService } from 'angular-ui-router';
import { Inject } from '../../../decorators/decorators';
import { IScope } from 'angular';

@Inject('$state', '$scope')
export class GlobalAlertController {
  constructor(private readonly state: IStateService, private readonly scope: IScope) {}

  shouldBeDisplayed: boolean = false;
  validRoutes: string[] = ['udb.dashboard', 'udb.accounts.dashboard', 'auth.login'];
  $onInit(): void {
    this.shouldBeDisplayed = this.validRoutes.includes(this.state.current.name);
    this.scope.$on('$stateChangeSuccess', () => {
      this.shouldBeDisplayed = false; // Forces the component to reload retrieving new sitecore data for global alert.
      setTimeout(
        () => (this.shouldBeDisplayed = this.validRoutes.includes(this.state.current.name))
      );
    });
  }
}
