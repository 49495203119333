import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core/enums/aas-store-features.enum';
import { StatementStateType, StatementsAndDocumentsStateType, TaxFormStateType } from '../types';

const getStatementsAndDocumentsState = createFeatureSelector<StatementsAndDocumentsStateType>(
  AasStoreFeatures.StatementsAndDocuments
);

export const getSelectedAccountDocuments = createSelector(getStatementsAndDocumentsState, state => {
  return state.selectedAccountDocuments;
});

export const getSelectedAccountStatementsLatestThree = createSelector(
  getStatementsAndDocumentsState,
  (state: StatementsAndDocumentsStateType) => {
    let statements: StatementStateType[] = [];
    let apiCallWasSuccesful = false;

    const accountDocuments = state.accountsDocuments.find(x => x.accountNumber === state.selectedAccountDocuments);
    if (accountDocuments) {
      apiCallWasSuccesful = accountDocuments.statementsApiCallWasSuccesful;

      const allStatements = accountDocuments.statements;
      const sortedStatements = allStatements.sort((a, b) => {
        let c = new Date(a.statementDate).getTime();
        let d = new Date(b.statementDate).getTime();
        return d - c;
      });
      statements = sortedStatements.slice(0, 3);
    }

    return {
      apiCallWasSuccesful,
      statements,
    };
  }
);

export const getSelectedAccountTaxFormsLatestThree = createSelector(
  getStatementsAndDocumentsState,
  (state: StatementsAndDocumentsStateType) => {
    let taxForms: TaxFormStateType[] = [];
    let apiCallWasSuccesful = false;

    const accountDocuments = state.accountsDocuments.find(x => x.accountNumber === state.selectedAccountDocuments);
    if (accountDocuments) {
      apiCallWasSuccesful = accountDocuments.taxFormsApiCallWasSuccesful;

      const allTaxForms = accountDocuments.taxForms;
      const sortedTaxForms = allTaxForms.sort((a, b) => {
        let c = new Date(b.taxFormDate).getTime();
        let d = new Date(a.taxFormDate).getTime();
        return c - d;
      });
      taxForms = sortedTaxForms.slice(0, 3);
    }

    return {
      apiCallWasSuccesful,
      taxForms,
    };
  }
);

export const getAllDocuments = createSelector(
  getStatementsAndDocumentsState,
  (state: StatementsAndDocumentsStateType) => {
    const accountDocuments = state.accountsDocuments.filter(x => x.accountNumber === state.selectedAccountDocuments);
    if (accountDocuments.length === 0) {
      return { statements: [], taxForms: [] };
    }

    const allStatements: StatementStateType[] = [];
    const allTaxForms: TaxFormStateType[] = [];

    accountDocuments.forEach(accountDoc => {
      if (accountDoc.statements) {
        allStatements.push(...accountDoc.statements);
      }
      if (accountDoc.taxForms) {
        allTaxForms.push(...accountDoc.taxForms);
      }
    });

    const sortedStatements = [...allStatements].sort((a, b) => {
      let c = new Date(b.statementDate).getTime();
      let d = new Date(a.statementDate).getTime();
      return c - d;
    });

    const sortedTaxForms = [...allTaxForms].sort((a, b) => {
      let c = new Date(b.taxFormDate).getTime();
      let d = new Date(a.taxFormDate).getTime();
      return c - d;
    });

    if (sortedStatements.length === 0) {
      return { statements: [], taxForms: sortedTaxForms };
    }

    if (sortedTaxForms.length === 0) {
      return { statements: sortedStatements, taxForms: [] };
    }

    return { statements: sortedStatements, taxForms: sortedTaxForms };
  }
);
