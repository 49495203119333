<div class="tray-container">
  <app-dot-loader *ngIf="loading; else categoryContainer">Saving</app-dot-loader>
  <ng-template #categoryContainer>
    <div class="card-container">
      <h5 class="title">
        Select Category:
      </h5>
      <ng-container *ngFor="let category of categories">
        <div class="card" [ngClass]="{ selected: isCategorySelected(category) }" (click)="selectCategory(category)">
          <app-svg-icon
            [iconName]="category.imageFile"
            [styles]="{ width: '23px', height: '19px', fill: '#1E3869' }"
          ></app-svg-icon>
          <div class="card-title" [ngStyle]="{ 'font-size': category.fontSize == 'small' ? '8px' : '10px' }">
            {{ category.name }}
          </div>
        </div>
      </ng-container>
      <div class="actions">
        <label class="uk-switch">
          <input type="checkbox" [formControl]="onlyThisTransaction" />
          <span class="slider">
            <i class="slider--icon" [ngClass]="onlyThisTransaction.value ? 'bofi-check-mark' : 'bofi-close'"></i>
          </span>
        </label>
        <div class="actions-text">Update this and all future transactions like this one</div>
      </div>
      <div class="actions save-button">
        <button class="uk-btn solid secondary sm" (click)="updateCategory()">
          Save
        </button>
      </div>
    </div>
  </ng-template>
</div>
