<div class="payments-tab">
  <div class="row" *ngIf="isEmpty">
    <div class="col-md-12">
      <p class="text-center empty not-payment">You have not sent any payments to this recipient.</p>
      <button
        class="uk-btn outline primary icon-right lg pull center-block schedule-btn"
        (click)="goToScheduledPayment()"
      >
        Schedule A Payment
      </button>
    </div>
  </div>
  <div class="row" *ngIf="!isEmpty">
    <div class="col-md-4">
      <p class="title space-down-10">Payment History</p>
      <p class="info-row info-payment" *ngFor="let payment of paymentsHistory">
        {{ payment.amount | currency }} on {{ payment.deliveryDate | date: 'M/dd/yyyy' }}
      </p>
      <a (click)="goToHistory()" class="view-all link">View all ></a>
    </div>
    <div class="col-md-4">
      <p class="title space-down-10">Payments Scheduled</p>
      <div class="info-row" *ngFor="let scheduledPayment of scheduledPayments">
        {{ scheduledPayment.amount | currency }} on {{ scheduledPayment.deliveryDate | date: 'M/dd/yyyy' }}
        <img class="frequency-icon" [src]="getIcon(scheduledPayment)" alt="" />
        <app-payment-options
          [payment]="scheduledPayment"
          (getScheduledPayments)="_loadScheduledPayments()"
        ></app-payment-options>
      </div>
    </div>
    <div class="col-md-4">
      <button class="uk-btn outline primary right lg pull schedule-btn" (click)="goToScheduledPayment()">
        Schedule A Payment
      </button>
    </div>
  </div>
</div>
