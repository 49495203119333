import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getInternalDepositAccountsList } from '@app/accounts/store/selectors';
import { UserProfileViews } from '@app/user-profile/enums';
import { olbSettings, STATE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-direct-deposit-view',
  templateUrl: './direct-deposit-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './direct-deposit-view.component.scss'],
})
export class DirectDepositViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  @Input()
  expanded = false;

  @Output() createRequest = new EventEmitter<void>();

  brand: string;
  isDirectDepositEnabled: boolean;
  isMobileDevice = false;

  private subsink = new SubSink();

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.brand = this.settings.brand.replace(' ', '');

    this.subsink.sink = this.store
      .select(getInternalDepositAccountsList)
      .pipe(filter(data => data.length > 0))
      .subscribe(accounts => {
        this.checkDirectDepositFeature(accounts);
      });

    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goNewRequest() {
    this.createRequest.emit();
  }

  private checkDirectDepositFeature(accounts: OlbAccount[]) {
    this.isDirectDepositEnabled =
      this.featureFlagService.isDirectDepositEnabled() &&
      accounts.filter(acc => {
        return acc.canMakeDepositSwitch;
      }).length > 0;

    if (!this.isDirectDepositEnabled) {
      this.state.go('udb.userProfile', { view: UserProfileViews.SignInAndSecurity });
    }
  }
}
