<div class="edit-recipient-confirmation">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <img src="/img/icons/billPay/checkmark.svg" width="87" aria-hidden="true" alt="check mark" />
    </div>
  </div>
  <div class="container">
    <div class="frow center">
      <div class="fcol-md-8 text-center">
        <h4>
          Changes saved.
        </h4>

        <table class="table table-striped confirmation__table">
          <caption [hidden]="true">Edit recipient change details.</caption>
          <tr [hidden]="true">
            <th></th>
            <th></th>
          </tr>
          <tbody>
            <tr></tr>
            <tr>
              <td class="text-left">
                Recipient
                <br class="show-responsive" />
                <span class="show-responsive text-right">{{ recipient?.displayName }}</span>
              </td>
              <td class="text-right hide-responsive">
                <span>{{ recipient?.displayName }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                {{ recipient?.paymentMethod === 0 ? 'Account/Reference Number/Memo' : 'Account Number' }}
                <br class="show-responsive" />
                <span class="show-responsive text-right">{{ recipient?.paymentAccount }}</span>
              </td>
              <td class="text-right hide-responsive">
                <span>{{ recipient?.paymentAccount }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Recipient Type
                <br class="show-responsive" />
                <span class="show-responsive text-right">{{ paymentMethod }}</span>
                <app-tooltip class="details-help show-responsive" [content]="tooltip" theme="olb2"></app-tooltip>
              </td>
              <td class="text-right hide-responsive">
                <span>{{ paymentMethod }}</span>
                <app-tooltip class="details-help" [content]="tooltip" theme="olb2"></app-tooltip>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Recipient's Address
                <span class="show-responsive text-right">
                  <br />
                  {{ recipient?.billingAddress.addressLine1 }}
                  <br />
                  {{ recipient?.billingAddress.addressLine2 }}
                  <br *ngIf="recipient?.billingAddress.addressLine2" />
                  {{ recipient?.billingAddress.city }},
                  {{ recipient?.billingAddress.stateCode }}
                  {{ recipient?.billingAddress.zipCode }}
                </span>
              </td>
              <td class="text-right hide-responsive">
                <span>
                  {{ recipient?.billingAddress.addressLine1 }}
                  <br />
                  {{ recipient?.billingAddress.addressLine2 }}
                  <br *ngIf="recipient?.billingAddress.addressLine2" />
                  {{ recipient?.billingAddress.city }},
                  {{ recipient?.billingAddress.stateCode }}
                  {{ recipient?.billingAddress.zipCode }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Nickname
                <br class="show-responsive" />
                <span class="show-responsive text-right">{{ recipient?.nickName }}</span>
              </td>
              <td class="text-right hide-responsive">
                <span>{{ recipient?.nickName }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center">
          <a id="edit-recipient-return" (click)="goToBillPayDashboard()">Go to Bill Pay Dashboard</a>
        </div>
      </div>
    </div>
  </div>
</div>
