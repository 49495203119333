<app-funding-shared-header></app-funding-shared-header>

<div class="brokerage-form">
  <div class="container">
    <div class="header-section-title text-center">
      <div>
        <h1>Funding Account</h1>
        <div id="padding-top-account" class="frow center">
          <p class="m-t-lg">
            {{ displayName }}
          </p>
        </div>
      </div>
    </div>

    <div class="frow center">
      <h3 class="text-center fcol-md-8">
        Enter the information for the account you want to transfer from.
      </h3>
    </div>

    <div class="frow center m-t-lg">
      <div class="fcol-md-8">
        <p>
          <span class="text-bold">NOTE:</span>
          Electronic funding from a brokerage account requires a full account transfers. For partial transfer, please
          send us a
          <u><a class="link" (click)="gotoSM()">Secure Message</a></u>
        </p>
      </div>
    </div>
    <form
      name="brokerageFormData"
      [formGroup]="brokerageForm"
      class="css-form"
      novalidate
      (submit)="submitTransfer($event)"
    >
      <div class="frow center m-t-lg">
        <div class="fcol-md-3">
          <label for="brokerageInstitution" class="uk-label">Brokerage Institution</label>
          <input
            class="uk-input"
            formControlName="brokerageInstitution"
            id="brokerageInstitution"
            required
            [(ngModel)]="transfer.brokerageInstitution"
            maxlength="30"
            minlength="4"
          />
          <div *ngIf="!this.isBusy">
            <div
              class="text--error"
              *ngIf="
                brokerageForm.get('brokerageInstitution').invalid &&
                (brokerageForm.get('brokerageInstitution').touched || isSubmitted)
              "
            >
              Please set a Brokerage Institution.
            </div>
          </div>
        </div>
        <div class="fcol-md-3">
          <label for="brokerageAccountNumber" class="uk-label">Brokerage Account Number</label>
          <input
            class="uk-input"
            formControlName="brokerageAccountNumber"
            id="brokerageAccountNumber"
            required
            [(ngModel)]="transfer.brokerageAccountNumber"
            maxlength="30"
            minlength="4"
          />
          <div *ngIf="!this.isBusy">
            <div
              class="text--error"
              *ngIf="
                brokerageForm.get('brokerageAccountNumber').invalid &&
                (brokerageForm.get('brokerageAccountNumber').touched || isSubmitted)
              "
            >
              Please set a Full Account Number.
            </div>
          </div>
        </div>
      </div>
      <div class="frow center m-t-lg">
        <div class="fcol-md-6">
          <span class="text-bold">Most Recent Statement</span>
          <app-file-upload [(files)]="transfer.documents"></app-file-upload>
        </div>
      </div>
      <div class="frow center m-t-lg">
        <div class="disclaimer__body fcol-md-8">
          <h3>BROKERAGE TRANSFER AGREEMENT AND DISCLAIMER</h3>
          <br />
          <br />
          <p>
            <u><b>Client Authorization:</b></u>
            By checking this box, I authorize Axos Clearing LLC (“Axos Clearing”) to make a request to the delivering
            institution to transfer all assets in my account at the delivering institution to Axos Clearing. I
            understand that to the extent any assets in my account at the delivering institution are not readily
            transferable, with or without penalties, such assets may not be transferred within the time frames required
            by New York Stock Exchange Rule 412 or similar rule of FINRA or other designated examining authority. I
            understand that if I own any nontransferable proprietary money market fund assets that are part of my
            account at the delivering institution, I must contact the delivering institution to instruct them to
            liquidate these investments and transfer the resulting credit balance to Axos Clearing as the successor
            custodian. I understand that I will be contacted with respect to the disposition of any other assets that
            are part of my account at the delivering institution which are nontransferable or which cannot be held at
            Axos Clearing. I authorize the delivering institution to deduct any outstanding fees due to them from the
            credit balance in my account at the delivering institution. If my account at the delivering institution does
            not contain a credit balance, or if the credit balance in the account is insufficient to satisfy any
            outstanding fees due to the delivering institution I understand that I must contact the delivering
            institution to authorize them to liquidate the assets in my account at the delivering institution to the
            extent necessary to satisfy that obligation to the delivering institution. I understand that upon receiving
            this transfer instruction, the delivering institution will cancel all open orders for my account at the
            delivering institution. I also understand that if so required, I may be requested to complete an actual
            paper transfer form, letter of authorization, or other document if required by the delivering institution.
          </p>
          <br />
          <br />
          <p>
            <u><b>Maintenance and Setup Fees:</b></u>
            If the assets I am transferring are considered nonstandard assets by Axos Clearing, I understand that I will
            be charged set-up and maintenance fees by Axos Clearing. I understand that fractional shares of stock are
            nontransferable, and I agree that any fractional shares will be liquidated by the delivering institution
            upon the transfer of the whole shares. The delivering institution may or may not charge a fee for this
            liquidation. I understand that the delivering institution may or may not assess account fees for the
            transfer and/or termination of my account with them.
          </p>
          <br />
          <br />
          <p>
            <u><b>Mutual Funds, Dividends and Capital Gains:</b></u>
            I understand that by transferring my mutual funds to Axos Clearing, all future correspondence concerning my
            mutual fund positions must be directed to Axos Clearing. Furthermore, I acknowledge that while my fund
            positions are being held in street name with Axos Clearing, I will not be able to have direct communication
            with the fund company concerning my account. Therefore, I authorize the delivering institution to release
            any information relevant to this transfer to Axos Clearing. I understand that the delivering institution may
            provide Axos Clearing with mutual fund dividends and capital gains distribution instructions for each mutual
            fund position so that Axos Clearing may implement these instructions. In the event that the instructions are
            not provided by the delivering institution or the dividend and/or capital gains distribution option
            requested is not available at Axos Clearing, the instructions will default to reinvest (unless reinvestment
            is not available, in which case dividends and capital gains will be paid in cash and subject to any
            applicable taxes).
          </p>
          <br />
        </div>
      </div>
      <div class="frow center">
        <img src="/img/icons/chevron_orange.svg" />
        <input
          id="confirm"
          formControlName="acceptTerms"
          class="uk-input"
          name="acceptTerms"
          type="checkbox"
          [(ngModel)]="transfer.acceptTerms"
          checked="transfer.acceptTerms"
        />
        <label for="confirm">
          I have read and agree to the terms in the Brokerage Transfer Agreement and Disclaimer above.
        </label>
      </div>
      <div class="frow center" *ngIf="termsError">
        <span class="text--error">
          Please indicate that you have read and agree to the terms in the Brokerage Transfer Agreement and Disclaimer.
        </span>
      </div>
      <div class="frow center m-t-lg" *ngIf="!isBusy">
        <div class="flex-row--space-around action-buttons">
          <button type="button" class="uk-btn lg outline primary" (click)="goToFunding('udb.funding')">
            Back
          </button>
          <button type="submit" class="uk-btn solid secondary lg">
            Agree & Submit
          </button>
        </div>
      </div>
      <app-dot-loader [showWhen]="isBusy">Verifying...</app-dot-loader>
    </form>
  </div>
</div>
