import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { CryptoService } from '@app/crypto/services';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-verify-account-modal',
  templateUrl: './verify-account-modal.component.html',
  styleUrls: ['./verify-account-modal.component.scss'],
})
export class VerifyAccountModalComponent implements OnInit {
  @Output() displayVerifyAccountModal = new EventEmitter<void>();
  form: UntypedFormGroup;
  submitted: boolean;
  accountsLoaded = false;
  isLoadingUpdate = false;
  internalAccounts: OlbAccount[];
  selectedAccount: OlbAccount;
  constructor(
    private cachedAccountsService: CachedAccountsService,
    private cryptoService: CryptoService,
    private serviceHelper: ServiceHelper
  ) {}
  ngOnInit() {
    this.getAccounts();
    this.form = this.createForm();
  }

  closeModal() {
    this.displayVerifyAccountModal.emit();
  }

  submitForm() {
    this.submitted = true;

    if (this.form.invalid) return;
    this.isLoadingUpdate = true;
    this.cryptoService
      .enrollCryptoAccount(this.form.get('accountId').value)
      .pipe(
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe({
        error: errors => {
          this.serviceHelper.errorHandler(errors);
          this.isLoadingUpdate = false;
        },
      });
  }

  private getAccounts() {
    const accounts = this.cachedAccountsService.getFromAccounts(true);
    this.internalAccounts = accounts.internalAccounts;
    this.accountsLoaded = true;
  }

  private createForm() {
    return new UntypedFormGroup({
      accountId: new UntypedFormControl('', [Validators.required]),
    });
  }
}
