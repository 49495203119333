<div id="udb-scheduler-transfers">
  <div class="header-section-title text-center">
    <div>
      <h1>Scheduled</h1>
      <p class="hide-cs hide-xs" id="padding-top-account">
        View and Edit Your Scheduled Transfer Series
      </p>
    </div>
  </div>
  <div class="scheduler-transfers-content view-container">
    <app-alert-notification
      class="alert-wig hide-sm hide-md hide-lg"
      *ngIf="showConfirmation"
      message="showConfirmation"
      type="'success'"
      (dismiss)="showConfirmation = null"
    ></app-alert-notification>
    <div class="filters hide-cs hidden-xs">
      <div class="frow">
        <div class="fcol-lg-3 fcol-md-3 text-left">
          <label for="fromacc" class="uk-label">Filter From Accounts</label>
          <select [(ngModel)]="selectedFrom" id="fromacc" class="uk-input" [attr.aria-label]="filterFrom[0].label">
            <option [ngValue]="filter" *ngFor="let filter of filterFrom">{{ filter.label }}</option>
          </select>
        </div>
        <div class="fcol-lg-3 fcol-md-3 text-left">
          <label for="toacc" class="uk-label">Filter To Accounts</label>
          <select [(ngModel)]="selectedTo" id="toacc" class="uk-input" [attr.aria-label]="filterTo[0].label">
            <option [ngValue]="filter" *ngFor="let filter of filterTo">{{ filter.label }}</option>
          </select>
        </div>
        <div class="fcol-lg-3 fcol-md-3 text-left">
          <label for="freqs" class="uk-label">Filter Frequencies</label>
          <select
            [(ngModel)]="selectedFrequency"
            id="freqs"
            class="uk-input"
            [attr.aria-label]="filterFrequency[0].label"
          >
            <option [ngValue]="filter" *ngFor="let filter of filterFrequency">{{ filter.label }}</option>
          </select>
        </div>
        <div class="fcol-lg-3 fcol-md-3">
          <label for="recs" class="uk-label">Filter Recurring Amounts</label>
          <select
            [(ngModel)]="selectedAmount"
            id="reqs"
            class="uk-input"
            [attr.aria-label]="filterRecurringAmount[0].label"
          >
            <option [ngValue]="filter" *ngFor="let filter of filterRecurringAmount">{{ filter.label }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="frow filter-section-mobile hide-sm hide-md hide-lg" *ngIf="!isLoading">
      <div class="filter" (click)="mobileFilter = true">
        <i class="bofi-filter"></i>
        Filter
      </div>
    </div>

    <h1
      class="pad-scheduled text-center"
      *ngIf="
        isLoading === false &&
        isDefaultFiltersSet() === true &&
        filterSchedulerTransferRow(
          schedulerTransfers,
          selectedFrom.value,
          selectedTo.value,
          selectedFrequency.value,
          selectedAmount.value
        )?.length === 0
      "
    >
      <div *ngIf="isDefaultFiltersSet() == true">
        You do not have any scheduled transfers at this time
      </div>
      <div *ngIf="isDefaultFiltersSet() == false">
        No results match your selected search criteria
      </div>
    </h1>
    <table
      class="table ut-table hide-cs hidden-xs"
      *ngIf="shouldShowScheduledTransfersTable()"
      aria-label="Schedule transfers table."
    >
      <thead>
        <tr class="header-table">
          <th *ngFor="let header of headers" (click)="sort(header.subValue, header.value)">
            {{ header.label }}
            <i *ngIf="headerIdOrderBy === header.value && reverse" class="fa fa-caret-down" aria-hidden="true"></i>
            <i *ngIf="headerIdOrderBy === header.value && !reverse" class="fa fa-caret-up" aria-hidden="true"></i>
          </th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody *ngIf="!isLoading">
        <ng-container
          *ngIf="
            filterSchedulerTransferRow(
              schedulerTransfers,
              selectedFrom.value,
              selectedTo.value,
              selectedFrequency.value,
              selectedAmount.value
            ) as filteredTrans
          "
        >
          <ng-container
            *ngIf="filteredTrans === null || filteredTrans.length === 0; else schedulerTransferDataTemplate"
          >
            <tr>
              <td colspan="8">
                <h2 class="text-center">
                  {{ messageOnNoData(filteredTrans) }}
                </h2>
              </td>
            </tr>
          </ng-container>
          <ng-template #schedulerTransferDataTemplate>
            <tr class="table-row" *ngFor="let tran of filteredTrans">
              <td>
                {{ tran.accountNicknameFrom }}
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
              <td>
                {{ tran.accountNicknameTo }}
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
              <td>
                {{ mapFrequency(tran.frequency) }}
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
              <td *ngIf="tran.sendUntilOptions !== 1">
                {{ mapSendUntilOptions(tran.sendUntilOptions, tran.frequency, tran.pendingNumberOfTransfers) }}
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
              <td *ngIf="tran.sendUntilOptions === 1">
                {{ tran.lastTransferDate | date: 'MM/dd/yyyy' }}
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
              <td>
                {{ tran.nextTransferDate | date: 'MM/dd/yyyy':'+0000' }}
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
              <td class="recurring-amount">
                {{ mapRecurringAmount(tran) }}
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
              <td>
                <div class="dropdown">
                  <button
                    class="dropdown-toggle ut-btn-options"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-label="Transfer Configuration"
                  >
                    <i class="bofi-gear"></i>
                  </button>
                  <ul
                    *ngIf="!tran.isAch && !tran.isMP && !tran.isTrading && !tran.isRia"
                    class="dropdown-menu ut-options-menu"
                  >
                    <li (click)="edit(tran)">
                      <a>
                        <i class="bofi-edit"></i>
                        {{ tran.frequency === 1 ? 'Edit Transfer' : 'Edit Next Transfer' }}
                      </a>
                    </li>
                    <li (click)="cancel(tran)">
                      <a>
                        <i class="bofi-close"></i>
                        {{ tran.frequency === 1 ? 'Remove Transfer' : 'Cancel Next Transfer' }}
                      </a>
                    </li>
                    <li *ngIf="tran.frequency !== 1">
                      <div class="dropdown-divider"></div>
                    </li>
                    <li *ngIf="tran.frequency !== 1" (click)="editSeries(tran)">
                      <a>
                        <i class="bofi-edit"></i>
                        Edit Series
                      </a>
                    </li>
                    <li *ngIf="tran.frequency !== 1" (click)="remove(tran)">
                      <a>
                        <i class="bofi-close"></i>
                        Remove Series
                      </a>
                    </li>
                  </ul>
                  <ul
                    *ngIf="!tran.isAch && !tran.isMP && !tran.isTrading && tran.isRia"
                    class="dropdown-menu ut-options-menu"
                  >
                    <li *ngIf="!riaMoveMoneyPilotEnabled || tran.riaTransferType != 1" class="no-edit">
                      Action is required by your advisor to modify scheduled transfers associated with an
                      advisor-managed account.
                    </li>
                    <li
                      *ngIf="riaMoveMoneyPilotEnabled && tran.riaTransferType === 1"
                      (click)="cancelNextRiaTransfer()"
                    >
                      <a>
                        <i class="bofi-close"></i>
                        Cancel Next Transfer
                      </a>
                    </li>
                    <li
                      *ngIf="riaMoveMoneyPilotEnabled && tran.riaTransferType === 1"
                      (click)="cancelRIATransfer(tran)"
                    >
                      <a>
                        <i class="bofi-close"></i>
                        Remove Series
                      </a>
                    </li>
                  </ul>
                  <ul *ngIf="tran.isAch" class="dropdown-menu ut-options-menu">
                    <li class="ach-warning">
                      <div class="ach-text">
                        For questions regarding this transaction, please contact Loan Servicing at (866) 923-7112
                      </div>
                    </li>
                  </ul>
                  <ul *ngIf="tran.isMP" class="dropdown-menu ut-options-menu">
                    <li *ngIf="tran.frequency !== 1" (click)="remove(tran)">
                      <a>
                        <i class="bofi-close"></i>
                        Remove Series
                      </a>
                    </li>
                  </ul>
                  <ul *ngIf="tran.isTrading" class="dropdown-menu ut-options-menu">
                    <li (click)="remove(tran)">
                      <a>
                        <i class="bofi-close"></i>
                        {{ tran.frequency === 1 ? 'Remove Transfer' : 'Remove Series' }}
                      </a>
                    </li>
                  </ul>
                </div>
                <span class="options-tooltip" *ngIf="tran.isPayoff">Loan Payoff</span>
              </td>
            </tr>
          </ng-template>
        </ng-container>
      </tbody>
    </table>
    <hr />
    <ng-container
      *ngIf="
        filterSchedulerTransferRow(
          schedulerTransfers,
          selectedFrom.value,
          selectedTo.value,
          selectedFrequency.value,
          selectedAmount.value
        ) as filteredTrans
      "
    >
      <ng-container *ngIf="!mobileCancel && !mobileRemove && !mobileEdit && !mobileFilter">
        <ng-container *ngFor="let tran of filteredTrans">
          <div class="mobile-panel hide-sm hide-md hide-lg" class="table ut-table hidden-sm hidden-md hidden-lg">
            <div *ngIf="!isLoading">
              <div *ngIf="filteredTrans === null || filteredTrans.length === 0">
                <h2 class="text-center">
                  {{ messageOnNoData(filteredTrans) }}
                </h2>
              </div>
              <div class="row header-row">
                <div class="col-md-6">
                  <span>{{ headers[0].label }}</span>
                  <br />
                  <br />
                  <label>{{ tran.accountNicknameFrom }}</label>
                  <br />
                  <br />
                  <span>{{ headers[3].label }}</span>
                  <br />
                  <br />
                  <label *ngIf="tran.sendUntilOptions !== 1">
                    {{ mapSendUntilOptions(tran.sendUntilOptions, tran.frequency, tran.pendingNumberOfTransfers) }}
                  </label>
                  <label *ngIf="tran.sendUntilOptions === 1">
                    {{ tran.lastTransferDate | date: 'MM/dd/yyyy' }}
                  </label>
                  <br />
                  <br />
                  <span>{{ headers[2].label }}</span>
                  <br />
                  <br />
                  <label>{{ mapFrequency(tran.frequency) }}</label>
                </div>
                <div class="col-md-6">
                  <span>{{ headers[1].label }}</span>
                  <br />
                  <br />
                  <label>{{ tran.accountNicknameTo }}</label>
                  <br />
                  <br />
                  <span>{{ headers[5].label }}</span>
                  <br />
                  <br />
                  <label>{{ mapRecurringAmount(tran) }}</label>
                  <br />
                  <br />
                  <span>{{ headers[4].label }}</span>
                  <br />
                  <br />
                  <label>{{ tran.nextTransferDate | date: 'MM/dd/yyyy':'+0000' }}</label>
                </div>
              </div>
              <div class="dropdown">
                <button
                  class="dropdown-toggle ut-btn-options"
                  type="button"
                  data-toggle="dropdown"
                  (click)="openEdit(tran)"
                  aria-haspopup="true"
                  aria-expanded="false"
                  aria-label="Transfer Configuration"
                >
                  <i class="bofi-gear"></i>
                </button>
                <ul
                  *ngIf="!tran.isAch && !tran.isMP && !tran.isTrading && tran.isRia"
                  class="dropdown-menu ut-options-menu"
                >
                  <li class="no-edit-mobile" *ngIf="!riaMoveMoneyPilotEnabled || tran.riaTransferType != 1">
                    Action is required by your advisor to modify scheduled transfers associated with an advisor-managed
                    account.
                  </li>
                  <li *ngIf="riaMoveMoneyPilotEnabled && tran.riaTransferType === 1" (click)="cancelNextRiaTransfer()">
                    <a>
                      <i class="bofi-close"></i>
                      Cancel Next Transfer
                    </a>
                  </li>
                  <li *ngIf="riaMoveMoneyPilotEnabled && tran.riaTransferType === 1" (click)="cancelRIATransfer(tran)">
                    <a>
                      <i class="bofi-close"></i>
                      Remove Series
                    </a>
                  </li>
                </ul>
                <ul *ngIf="tran.isAch" class="dropdown-menu ut-options-menu">
                  <li class="ach-warning">
                    <div class="ach-text">
                      For questions regarding this transaction, please contact Loan Servicing at (866) 923-7112
                    </div>
                  </li>
                </ul>
                <ul *ngIf="tran.isMP || tran.isTrading" class="dropdown-menu ut-options-menu">
                  <li *ngIf="tran.frequency !== 1" (click)="remove(tran)">
                    <a>
                      <i class="bofi-close"></i>
                      Remove Series
                    </a>
                  </li>
                </ul>
              </div>
              <hr />
            </div>
          </div>
        </ng-container>

        <app-dot-loader [showWhen]="isLoading">Loading</app-dot-loader>
      </ng-container>
    </ng-container>

    <div class="frow center">
      <button class="uk-btn lg solid secondary" (click)="goToRoute('udb.transfers.transferFunds')">
        Set up new transfer
      </button>
    </div>
  </div>
</div>

<div class="filter-modal" *ngIf="mobileFilter">
  <i class="bofi-close right" data-dismiss="modal" aria-label="Close" (click)="mobileFilter = false"></i>
  <h3 class="more-info-title">Filter Scheduled Transfers</h3>
  <div class="hr-line"></div>
  <div class="frow filters">
    <div class="fcol-lg-3 fcol-md-3 text-left">
      <label class="uk-label" for="filterform">From Account</label>
      <select [(ngModel)]="selectedFrom" id="filterform" class="uk-input" [attr.aria-label]="filterFrom[0].label">
        <option [ngValue]="filter" *ngFor="let filter of filterFrom">
          {{ filter.label }}
        </option>
      </select>
    </div>
    <div class="fcol-lg-3 fcol-md-3 text-left">
      <label class="uk-label" for="filterto">To Account</label>
      <select [(ngModel)]="selectedTo" id="filterto" class="uk-input" [attr.aria-label]="filterTo[0].label">
        <option [ngValue]="filter" *ngFor="let filter of filterTo">
          {{ filter.label }}
        </option>
      </select>
    </div>
    <div class="fcol-lg-3 fcol-md-3 text-left">
      <label class="uk-label" for="filterfrequency">Frequency</label>
      <select
        [(ngModel)]="selectedFrequency"
        id="filterfrequency"
        class="uk-input"
        [attr.aria-label]="filterFrequency[0].label"
      >
        <option [ngValue]="filter" *ngFor="let filter of filterFrequency">
          {{ filter.label }}
        </option>
      </select>
    </div>
    <div class="fcol-lg-3 fcol-md-3">
      <label class="uk-label" for="filteramount">Recurring Amount</label>
      <select
        [(ngModel)]="selectedAmount"
        id="filteramount"
        class="uk-input"
        [attr.aria-label]="filterRecurringAmount[0].label"
      >
        <option [ngValue]="filter" *ngFor="let filter of filterRecurringAmount">
          {{ filter.label }}
        </option>
      </select>
    </div>
    <div>
      <button class="uk-btn solid secondary lg" (click)="mobileFilter = false">
        APPLY FILTER
      </button>
      <button class="uk-btn outline primary lg" (click)="cancelMobileFilter()">
        CANCEL
      </button>
    </div>
  </div>
</div>

<section class="modal-styles">
  <div class="edit-modal-transfers" *ngIf="mobileEdit && !transfers.isRia">
    <div *ngIf="!transfers.isAch && !transfers.isMP">
      <div class="tran-row" (click)="editMobile(transfers)">
        <i class="bofi-edit"></i>
        <div>{{ transfers.frequency === 1 ? 'Edit Transfer' : 'Edit Next Transfer' }}</div>
        <i class="fa fa-chevron-right"></i>
      </div>
      <hr />
      <div class="tran-row">
        <i class="bofi-close"></i>
        <div (click)="cancelMobile(transfers)">
          {{ transfers.frequency === 1 ? 'Remove Transfer' : 'Cancel Next Transfer' }}
        </div>
        <i class="fa fa-chevron-right"></i>
      </div>
      <hr />
      <div class="tran-row" *ngIf="transfers.frequency !== 1" (click)="editmobileSeries(transfers)">
        <i class="bofi-edit"></i>
        <div>Edit Series</div>
        <i class="fa fa-chevron-right"></i>
      </div>
      <hr *ngIf="transfers.frequency !== 1" />
      <div class="tran-row" *ngIf="transfers.frequency !== 1" (click)="removeMobile(transfers)">
        <i class="bofi-close"></i>
        <div>Remove Series</div>
        <i class="fa fa-chevron-right"></i>
      </div>
      <hr *ngIf="transfers.frequency !== 1" />
    </div>
    <div *ngIf="transfers.isAch">
      <div class="ach-warning">
        <div class="ach-text">
          For questions regarding this transaction, please contact Loan Servicing at (866) 923-7112
        </div>
      </div>
    </div>
    <div *ngIf="transfers.isMP">
      <div class="tran-row" *ngIf="transfers.frequency !== 1" (click)="removeMobile(transfers)">
        <i class="bofi-close"></i>
        <div>Remove Series</div>
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
    <button class="uk-btn outline primary lg" (click)="mobileEdit = false">CLOSE</button>
  </div>

  <div class="edit-modal-transfers" *ngIf="mobileCancel">
    <div class="cancelModal">
      <div class="title">Are you sure you want to cancel this next transfer?</div>
      <div class="content">
        By selecting confirm, you will remove this specific transaction from your schedule. This will not affect other
        transfers in the series
      </div>
    </div>
    <app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>
    <div *ngIf="!isLoading">
      <button class="uk-btn solid secondary lg" (click)="cancelCurrentTransfer(true)">
        CONFIRM
      </button>
      <button class="uk-btn outline primary lg" (click)="mobileCancel = false">
        CANCEL
      </button>
    </div>
  </div>

  <div class="edit-modal-transfers" *ngIf="mobileRemove">
    <div class="cancelModal">
      <div class="title">Cancel Transfer Series</div>
      <div class="content">Are you sure you want to cancel your transfer series?</div>
    </div>
    <app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>
    <div *ngIf="!isLoading">
      <button class="uk-btn solid secondary lg" (click)="removeTransferSeries(true)">
        CONFIRM
      </button>
      <button class="uk-btn outline primary lg" (click)="mobileRemove = false">
        CANCEL
      </button>
    </div>
  </div>
</section>

<div class="modal-component">
  <div
    class="modal inmodal"
    id="editschedulerTransfer"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style="display: none;"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="header">
            <h1>Edit scheduler transfer</h1>
          </div>
          <hr />

          <div class="col-md-6">
            <div class="row">
              <label class="pull-left">
                Amount
              </label>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group custom-control-wrapper">
                  <div class="input--primary">
                    <input
                      type="text"
                      id="tf-amount"
                      placeholder="Amount"
                      [(ngModel)]="currentTransferEdit.amount"
                      format
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group custom-control-wrapper">
                    <label class="label--primary" for="tf-memo">Memo</label>
                    <label class="label--primary">(OPTIONAL)</label>
                    <div class="input--primary">
                      <input type="text" id="tf-memo" [(ngModel)]="currentTransferEdit.memo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">SEND CONFIRMATION</div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <span class="checkbox-bofi checkbox-blue checkbox-sm">
                      <!-- ! confirmationEmail where does this thing originally come from ?? -->
                      <input type="checkbox" [(ngModel)]="confirmationEmail" id="checkTextMessageEdit" />
                      <label class="label--primary" for="checkTextMessageEdit"></label>
                    </span>
                    <b>E-mail:</b>
                    darren@gmail.com
                  </div>

                  <div class="row">
                    <span class="checkbox-bofi checkbox-blue checkbox-sm">
                      <!-- ! where does this thing com from??? > confirmationTextMessage -->
                      <input type="checkbox" [(ngModel)]="confirmationTextMessage" id="checkPhoneEdit" />
                      <label class="label--primary" for="checkPhoneEdit"></label>
                    </span>
                    <b>Text:</b>
                    (***) ***-3728
                  </div>
                </div>
              </div>
              <div class="row">NEXT TRANSFER DATE</div>
              <div class="row">
                <div class="input--primary">
                  <input type="text" [(ngModel)]="currentTransferEdit.beginSendingOn" readonly />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- ! should be here as part of id on button-{{modalName}}>  modalName -->
            <button [id]="'button-'" type="button" class="btn-modal-success">
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
