<div class="category-element" (click)="selectCategory()">
    <div class="category-element__item" [ngClass]="{ 'category-element__item--active': category.active }">
        <div class="category-element__img">
            <div
                    class="category-element__position category-element__position--svg-custom"
                    [ngClass]="{ 'category-element__position--svg-custom--active': category.active }"
            >
                <div>
                    <img [src]="getImagePath()" />
                </div>
            </div>
        </div>

        <div class="category-element__item--adjustment">
            <p
                    class="category-element__item__desc category-element__position--svg-custom"
                    [ngClass]="{ 'category-element__position--svg-custom--active': category.active }"
            >
                {{ category.name }}
            </p>
        </div>
    </div>
</div>
