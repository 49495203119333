<div class="container-shadow expenses">
  <div class="expenses-column">
    <div class="expenses-title">
      <h2 class="encode-sans font-weight-normal font-primary">Expenses</h2>
      <div class="expenses-title_nickname font-roboto">{{ accountNickName }}</div>
    </div>
    <div class="row donut-section">
      <div class="inside-donut-wrapper">
        <div class="category-selected font-roboto text-center">
          {{
            filters.categories.length ? 'CATEGORIES (' + filters.categories.length + ')' : 'TOTAL'
          }}
        </div>
        <div class="inside-donut">
          <app-svg-icon
            [iconName]="arrowPosition"
            [iconColor]="'#2F5B88'"
            width="16px"
            height="13px"
          ></app-svg-icon>
          <span
            [style.font-size]="getPercentageChangeFontSize(percentageChange | percentageRound)"
            class="encode-sans"
            [textContent]="percentageChange | percentageRound"
          ></span>
          <span class="percentage-sign">%</span>
        </div>
        <div class="donutLast-month font-roboto">
          <div class="font-weight-normal">compared with</div>
          <div class="font-weight-normal">last month</div>
        </div>
      </div>
      <div *ngIf="expensesGrid" class="donut-chart" [class.hide]="isEmpty">
        <app-donut-chart
          [height]="250"
          [width]="250"
          [chartjsOptions]="{ cutoutPercentage: 55 }"
          [filters]="filters"
          [categories]="expensesGrid"
          (toggleCategoryFilter)="toggleSelectedCategory($event.categoryId)"
        ></app-donut-chart>
      </div>
      <div class="empty-donut-chart" [class.hide]="!isEmpty">
        <img src="assets/img/logos/charts/empty-expenses.svg" alt="" />
      </div>
    </div>
    <div class="row total-values">
      <div class="totalValues_column">
        <div class="encode-sans total-values-amount">{{ totalValueThisMonth | currency }}</div>
        <div class="period text-center font-weight-normal">
          {{ currentPeriod | date: 'MMM yyyy' }}
        </div>
      </div>
      <div class="totalValues_column">
        <div class="encode-sans total-values-amount">{{ totalValueLastMonth | currency }}</div>
        <div class="period text-center font-weight-normal">{{ lastTimePeriod }}</div>
      </div>
    </div>
  </div>
  <div class="expenses-grid">
    <div
      class="category-row-item"
      *ngFor="let category of expensesGrid; trackBy: categoryTrackBy"
      [class.selected]="filters.categories.indexOf(category.id) >= 0"
    >
      <div class="category-tooltip font-weight-normal">
        <img
          alt=""
          style="filter: brightness(200);"
          src="assets/img/logos/categories/{{ category.imageFile }}"
        />
        : {{ category.amount | currency }}
        <div class="arrow">
          <app-svg-icon
            [iconName]="'utility-arrow-down'"
            [iconColor]="'#4c4c4c'"
            width="16px"
            height="13px"
          ></app-svg-icon>
        </div>
      </div>

      <div class="category-border" [ngStyle]="{ 'background-color': category.color }"></div>
      <div class="category-item" (click)="toggleSelectedCategory(category.id)">
        <div class="col-10">
          <div class="row category-text">
            <img
              alt=""
              class="category-image"
              src="assets/img/logos/categories/{{
                filters.categories.indexOf(category.id) >= 0 ? 'checked.svg' : category.imageFile
              }}"
            />
            <div class="category-name">{{ category.name }}</div>
          </div>
        </div>
        <div class="col-2">
          <div class="row category-percentage">
            <span>
              {{ category.percentage != 0 ? category.percentage.toString() + '%' : '--' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
