import { Directive, Input, OnInit, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCurrency]',
})
export class CurrencyDirective implements OnInit {
  @Input() allowBlank: boolean = false;
  @Input() allowNegatives: boolean = false;
  @Input() onlyDecimal: boolean = false;
  @Input() maxLength: number = 11;
  @Input() minValue: number = 0;

  private readonly emptyValue: string = '$';
  private readonly signRegex: string = this.allowNegatives ? '(?:-)?' : '';
  private readonly validFormatRegex = new RegExp(
    `^${this.signRegex}[0-9]{0,${+this.maxLength - 5}}(?:\\.[0-9]{0,2})?$`
  );
  private readonly validCharactersRegex = this.allowNegatives ? /[^-0-9.]/g : /[^0-9.]/g;
  private readonly milesRegex = /(\d)(?=(\d\d\d)+(?!\d))/g;
  private defaultValue: string = this.allowBlank ? '' : '$0.00';
  private element: any;

  constructor(private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit() {
    //attrs.$set('ngTrim', 'false');
    this.formatAndUpdateView(this.element.value, 0);
    if (this.allowNegatives) {
      this.maxLength = +this.maxLength + 1;
    } else {
      this.maxLength = +this.maxLength;
    }

    if (this.minValue > 0) {
      this.defaultValue = `${this.emptyValue}${this.minValue}`;
    }
  }

  private getSign(val: string): string {
    if (this.allowNegatives && val.length > 0 && val[0] === '-') return '-';
    return '';
  }

  private getFormatted(val: string): string {
    val = String(val).replace(this.validCharactersRegex, '') || '';
    let sign = this.getSign(val);
    let valueArray = val.replace(sign, '').split('.');

    if (this.onlyDecimal) {
      valueArray[1] = valueArray.length > 1 ? valueArray[1] : valueArray[0];
      valueArray[0] = '0';
    }

    let result =
      valueArray.length > 1
        ? `${this.emptyValue}${valueArray[0].replace(this.milesRegex, '$1,')}.${valueArray[1]}`
        : `${this.emptyValue}${valueArray[0].replace(this.milesRegex, '$1,')}`;
    return sign + result;
  }

  private formatAndUpdateView(val: string, pos?: number): string {
    let result = this.getFormatted(val);
    let previous = this.element.value || '';
    //Check if the new formatted value added a ',' for miles (compared with the previous one);
    //if so, then we move the cursor 1 position to the right
    if (pos <= 1 || (result.indexOf(',') != -1 && (previous.indexOf(',') == -1 || previous.indexOf(',') == 4))) {
      pos += 1;
    }
    //If the new formatted value removed a ',', then we move the cursor 1 position to the left
    if (result.indexOf(',') == -1 && previous.indexOf(',') != -1) {
      pos -= 1;
    }

    return result;
  }

  private isValidInput(val: string): any {
    return this.validFormatRegex.test(val);
  }

  @HostListener('focus', ['$event.target.value'])
  public onFocus(value) {
    const modelValue = Number(String(value).replace(this.validCharactersRegex, ''));
    if (modelValue == 0 || isNaN(modelValue)) {
      this.element.value = this.formatAndUpdateView(this.emptyValue, 0);
    }
  }

  @HostListener('keydown', ['$event'])
  public onkeyDown(e: any) :void  {
    const allowedKeys: string[] = ['tab', 'backspace', 'enter', 'shift', 'capsLock', 'control', 'alt']
    const isValidInput = this.isValidInput(e.key);
    const isAllowed = allowedKeys.some((key) => key === e.key.toLowerCase());
    
    if (!isValidInput && !isAllowed) {
        e.preventDefault();
    }
}

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: any) :void {
    const value = event.target.value;
    let cursorInit = this.element.selectionStart;
    let cleaned = String(value).replace(this.validCharactersRegex, '');
    let previous = this.getFormatted(this.element.value);
    let current = this.getFormatted(cleaned);

    if (value === '' || value === this.emptyValue || (value === `${this.emptyValue}0` && !this.onlyDecimal)) {
      this.element.value = this.formatAndUpdateView(this.emptyValue, 1);
    } else if (!this.isValidInput(cleaned)) {
      this.element.value = this.formatAndUpdateView(this.element.value, cursorInit - 1);
    } else if (previous === current) {
      this.element.value = this.formatAndUpdateView(cleaned);
    } else {
      this.element.value = this.formatAndUpdateView(cleaned, cursorInit);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  public onBlur(value: any) :void  {
    const modelValue = Number(String(value).replace(this.validCharactersRegex, ''));
    if (
      (this.element.value === this.emptyValue || modelValue === 0 || modelValue < this.minValue || isNaN(modelValue)) &&
      !this.allowBlank
    ) {
      this.element.value = this.defaultValue;
    }
    if (this.element.value.indexOf('.') === this.element.value.length - 1) {
      this.element.value = this.element.value.replace('.', '');
    }
  }
}
