<div class="row text-center wallet-container p-4 m-2">
  <div class="col-12">
    <app-svg-icon [iconName]="icon" size="24px"></app-svg-icon>
  </div>
  <div class="col-12 d-flex justify-content-center align-items-center">
    <p class="wallet-title">
      Wallet
    </p>
    <app-tooltip symbol="i" [content]="tooltip"></app-tooltip>
  </div>
  <div class="col-12 mt-1">
    <p class="wallet-value-label">Value</p>
  </div>
  <div class="col-12">
    <p class="wallet-value">{{ wallet.currentValue | currency }}</p>
  </div>
  <div class="col-12 d-flex justify-content-center mt-2" *ngIf="$manageWalletUrl | async; let manageWalletUrl">
    <a class="btn-text" [href]="manageWalletUrl" target="_blank">
      Manage Wallet
      <app-svg-icon [iconName]="redirectIcon" size="12px"></app-svg-icon>
    </a>
  </div>
</div>
