import { AxosInvestPortfolioController } from './axos-invest-portfolio.controller';

export const AxosInvestPortfolioComponent: ng.IComponentOptions = {
  controller: AxosInvestPortfolioController,
  templateUrl: 'axos-invest/overview/portfolio/axos-invest-portfolio.tpl.html',
  controllerAs: '$ap',
  bindings: {
    milestones: '<',
    formulaSummary: '<',
  },
};
