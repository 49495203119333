import { Inject } from 'decorators/decorators';

@Inject('$state')
export class SendMailController {
  constructor(private readonly state: ng.ui.IStateService) {}

  goToDashboard(): void {
    this.state.go('udb.dashboard');
  }
}
