<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-7 col-lg-6 col-xl-5">
      <div class="row">
        <div class="col-8">
          <p>Current Target Allocation</p>
        </div>
        <div class="col-4 text-right">
          <button
            class="link current-target-selector-button"
            type="button"
            (click)="selectAllocation(portfolioAllocations.Start)"
          >
            <app-svg-icon [size]="'1rem'" [iconName]="editIcon"></app-svg-icon>
            Edit
          </button>
        </div>
      </div>
      <app-main-portfolio-details
        class="col-12 d-block mt-2"
        [details]="currentAllocation"
      ></app-main-portfolio-details>
      <div class="row mt-4">
        <div class="col-8">
          <p>End Target Allocation</p>
        </div>
        <div class="col-4 text-right">
          <button
            class="link end-target-selector-button"
            type="button"
            (click)="selectAllocation(portfolioAllocations.End)"
          >
            <app-svg-icon [size]="'1rem'" [iconName]="editIcon"></app-svg-icon>
            Edit
          </button>
        </div>
      </div>
      <app-main-portfolio-details class="col-12 d-block mt-2" [details]="endAllocation"></app-main-portfolio-details>
      <div class="row center">
        <button class="btn-ok form-btn m-4" (click)="savePortfolio.emit()" *ngIf="!saveAllocation">
          Save Portfolio
        </button>
        <app-dot-loader *ngIf="saveAllocation"></app-dot-loader>
      </div>
    </div>
  </div>
</div>
