import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { addDays, differenceInDays, format, isAfter } from 'date-fns';

import { PaymentInfo, PayoffDetailsResponse } from '@app/accounts/models';
import { AccountDetail, OlbAccount } from '@app/accounts/models';
import { IncomingTransaction } from '@app/accounts/models';
import { loanGracePeriodInDays } from '@app/config/constants';
import { OlbEvents } from '@core/enums';
import {
  AccountsService,
  DialogService,
  LoanService,
  OlbEventService,
} from '@core/services';
import { STATE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import * as moment from 'moment';

import { PaymentStatus } from '../../enums';

@Component({
  selector: 'app-loan-account-details-view',
  templateUrl: './loan-account-details-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanAccountDetailsViewComponent implements OnInit, OnDestroy {
  @Input() account: OlbAccount;

  details: AccountDetail;
  incomingTransaction: IncomingTransaction = null;
  isLoading = true;
  isPastDue = false;
  showPaymentButton = true;
  isSignatureCardActive = false;
  paymentInfo: PaymentInfo;
  paymentPayoff: PaymentInfo;
  payoffCalculated: PayoffDetailsResponse;
  showPayoffCalc = false;
  isPayoffFlowActive = false;
  isRiaShareAccountFlag = false;
  isRiaAuthorizeTransfersFlag = false;
  shouldDisplayShareAccount = false;
  shouldDisplayAuthorizeTransfer = false;
  private currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  private percent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 3,
  });
  private subsink = new SubSink();

  constructor(
    private cd: ChangeDetectorRef,
    private accountsService: AccountsService,
    private loanService: LoanService,
    private dialogService: DialogService,
    private serviceHelper: ServiceHelper,
    private olbEventService: OlbEventService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    forkJoin({
      accountDetails: this.accountsService.getAccountDetails(this.account.id),
      pendingPayments: this.loanService.getPendingPayments([this.account.id]),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;

          this.cd.markForCheck();

          this.olbEventService.emit(
            OlbEvents.AccountDetailsRetrieved,
            this.details
          );
        })
      )
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: ({ accountDetails, pendingPayments }) => {
          this.details = new AccountDetail(accountDetails.data);
          this.incomingTransaction = pendingPayments.shift() || null;
          if (!this.details.accountPayment.dueDate) {
            this.isPastDue = false;
          } else {
            const dueDate = new Date(
              this.details.accountPayment.dueDate || null
            );

            this.isPastDue = isAfter(
              new Date(),
              addDays(dueDate, loanGracePeriodInDays) // <- legacy hardcoded grace period, instead of the JH field.
            );
          }

          this.showPayoffCalc = this.isShowPayoffCalc();
          this.isPayoffFlowActive = this.featureFlagService.isPayoffFlowActive();
          this.isRiaShareAccountFlag = this.featureFlagService.isRiaShareAccountActive();
          this.isRiaAuthorizeTransfersFlag = this.featureFlagService.isRiaAuthorizeTransfersActive();
          this.setPaymentsData();
          this.validateIfIsDepositAccount();
        },
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goToTransferFunds() {
    const difference = differenceInDays(
      Date.now(),
      new Date(this.account.loanDueDate)
    );
    const isLate = difference > 89;
    const isMature = this.account.statusCode === '3';
    const lateMessage =
      'Your loan is more than 90 days past due and is not eligible for online payments.\n\n' +
      'Please call Collection Department at 858-350-6200 ext. 1241.';
    const matureMessage =
      'Your loan is matured and is not eligible for online payments. \n\n' +
      'Please call Loan Servicing at 866-923-7112.';

    if (!isLate && !isMature) {
      sessionStorage.setItem('isPayoff', 'false');
      this.state.go('udb.transfers.loanPayment', {
        toAccountId: this.account.id,
      });
    } else {
      this.dialogService.open({
        cancelText: null,
        content: isLate ? lateMessage : matureMessage,
        okText: 'Close',
      });
    }
  }

  calculatePayoff(date: Date) {
    this.subsink.sink = this.accountsService
      .calculatePayoff(this.account.accountNumber, date.toLocaleDateString())
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: response => {
          this.payoffCalculated = response.data;
          this.cd.markForCheck();
        },
      });
  }

  generateLoanQuote(date: Date) {
    this.subsink.sink = this.accountsService
      .generateLoanQuote(
        this.account.accountNumber,
        date,
        new Date(),
        this.account.id,
        this.details.statementDescription
      )
      .subscribe({
        next: () => {
          this.cd.markForCheck();
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  makePayment(payoffDetail: any) {
    this.state.go('udb.transfers.loanPayment', {
      toAccountId: this.account.id,
      isPayoff: true,
      tooltip: payoffDetail,
      payoffCalculated: this.payoffCalculated,
    });
  }

  private isShowPayoffCalc(): boolean {
    return (
      this.featureFlagService.isPayoffCalcActive() &&
      this.account.canPayoffLoan &&
      this.account.isOpen &&
      this.loanDueDateValidation(this.account.loanDueDate) &&
      !this.account.isClosed && // Considering both flags (isOpen and isClosed) since Admin portal currently does not validate each other to avoid a yes/yes scenario
      this.account.canBeToAccount &&
      this.account.statusCode === '1'
    );
  }

  private loanDueDateValidation(date: Date): boolean {
    const limitDate = moment.utc().subtract(90, 'days');
    return moment(date).isAfter(limitDate);
  }

  private setPaymentsData() {
    this.paymentPayoff = {
      title: 'Loan Payoff Calculator',
      account: this.account,
      items: [
        {
          name: 'Outstanding Balance',
          value: this.currencyFormat(
            this.details.accountPayment.outstandingBalance
          ),
        },
        {
          name: 'Interest Rate',
          value: this.percent.format(this.details.accountInterest.interestRate),
        },
      ],
    };

    // Pending Payment
    if (this.incomingTransaction) {
      this.paymentInfo = {
        title: 'Loan Payment Pending',
        account: this.account,
        status: PaymentStatus.PaymentConfirmed,
        items: [
          {
            name: 'Payment Amount',
            value: this.currencyFormat(this.incomingTransaction.paymentAmout),
          },
          {
            name: 'Scheduled Date',
            value: this.dateFormat(this.incomingTransaction.scheduledDate),
          },
          {
            name: 'Post Date',
            value: this.dateFormat(this.incomingTransaction.deliveryDate),
          },
          {
            name: 'Payment Account',
            value: this.incomingTransaction.externalBankName,
          },
        ],
      };

      return this.setPaymentButtonFlag(PaymentStatus.PaymentPending);
    }

    // Past Due Payment
    if (!this.incomingTransaction && this.details.totalAmountDue > 0) {
      const keyNameMap = {
        billedPrincipal: 'Billed Principal',
        billedInterest: 'Billed Interest',
        billedEscrow: 'Billed Escrow',
        billedLateCharges: 'Billed Late Charges',
        billedOtherCharges: 'Billed Other Charges',
      };

      this.paymentInfo = {
        title: this.isPastDue ? 'Past Due Loan Payment' : 'Next Loan Payment',
        isPastDue: isAfter(
          Date.now(),
          new Date(this.details.accountPayment.dueDate || null)
        ),
        dueDate: this.details.accountPayment.dueDate,
        items: [],
      };

      Object.keys(keyNameMap).forEach(key => {
        if (this.details[key]) {
          this.paymentInfo.items.push({
            name: keyNameMap[key],
            value: this.currencyFormat(this.details[key]),
          });
        }
      });

      this.paymentInfo.items.push({
        name: 'Total Amount Due',
        value: this.currencyFormat(this.details.totalAmountDue),
        bold: true,
      });

      return this.setPaymentButtonFlag(PaymentStatus.NextDuePayment);
    }

    // Confirmed Payment
    if (
      differenceInDays(
        Date.now(),
        new Date(this.details.accountPayment.lastPaymentDate || null)
      ) <= 3
    ) {
      this.paymentInfo = {
        title: 'Payment Confirmed',
        account: this.account,
        status: PaymentStatus.PaymentConfirmed,
        items: [
          {
            name: 'Payment Date',
            value: this.dateFormat(this.details.accountPayment.lastPaymentDate),
          },
          {
            name: 'Payment Amount',
            value: this.currencyFormat(
              this.details.accountPayment.lastPaymentAmount
            ),
          },
        ],
      };

      return this.setPaymentButtonFlag(PaymentStatus.PaymentConfirmed);
    }

    // Next Payment
    if (this.details.accountPayment.dueDate) {
      this.paymentInfo = {
        title: 'Next Payment',
        items: [
          {
            name: 'Due Date',
            value: this.dateFormat(this.details.accountPayment.dueDate),
          },
          {
            name: 'Payment Amount',
            value: this.currencyFormat(this.details.accountPayment.amount),
          },
        ],
      };

      return this.setPaymentButtonFlag(PaymentStatus.NextPayment);
    }

    // Default; no payment due
    this.paymentInfo = {
      title: 'No Payment Due at this time',
    };

    return this.setPaymentButtonFlag(PaymentStatus.Default);
  }

  private setPaymentButtonFlag(status: PaymentStatus) {
    this.showPaymentButton =
      (status === PaymentStatus.NextDuePayment ||
        status === PaymentStatus.NextPayment) &&
      this.account.canBeToAccount;
  }

  private currencyFormat(amount: number) {
    return this.currency.format(amount);
  }

  private dateFormat(date: Date = null) {
    return format(new Date(date), 'MM/dd/yyyy');
  }

  private validateIfIsDepositAccount() {
    if (this.account.isLoan) {
      this.shouldDisplayAuthorizeTransfer = false;
      this.shouldDisplayShareAccount = true;
    }
  }
}
