import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import {
  StatementsRequest,
  StatementsResponse,
  TaxFormDocumentRequest,
  TaxFormsRequest,
  TaxFormsResponse,
} from './types';

@Injectable()
export class StatementsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/document');
  }

  /**
   * Returns the Statements from account number.
   *
   * @param request StatementsRequest
   * @returns WebApiResponse of List StatementsResponse
   */
  getStatements(request: StatementsRequest): WebApiResponse<StatementsResponse[]> {
    return this.get(`statements/${request.accountNumber}`);
  }

  /**
   * Returns the Statement from account number and Date.
   *
   * @param request StatementsRequest
   * @returns Observable Blob of Statement
   */
  getStatement(request: StatementsRequest): Observable<Blob> {
    return this.download(`statement/${request.accountNumber}/${request.statementDate}`, {});
  }

  /**
   * Returns the Tax Forms from account.
   *
   * @param request TaxFormsRequest
   * @returns WebApiResponse of TaxFormsResponse
   *
   */
  getTaxForms(request: TaxFormsRequest): WebApiResponse<TaxFormsResponse[]> {
    return this.get(`taxforms/${request.accountNumber}`);
  }

  /**
   * Returns the Tax Form Document.
   *
   * @param request TaxFormDocumentRequest
   * @returns Observable<Blob>
   *
   */
  getTaxForm(request: TaxFormDocumentRequest): Observable<Blob> {
    return this.download(
      `taxform/${request.accountNumber}/${request.formType}/${request.taxYear}/${request.hostKey}`,
      {}
    );
  }
}
