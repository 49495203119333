import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { NavigationIcons } from '@shared/enums/svg-icons';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  animations: [
    trigger('slideDown', [
      state(
        'true',
        style({
          opacity: '1',
          transform: 'translate(0, 0.5625rem)',
        })
      ),
      state(
        'false',
        style({
          opacity: '0',
        })
      ),
      transition('* => *', animate('200ms ease')),
    ]),
  ],
})
export class FilterDropdownComponent {
  @Output() collapse = new EventEmitter();

  @Input() isHighlighted: Observable<boolean>;
  isOpen = false;
  icons = {
    ChevronDown: NavigationIcons.ChevronDown,
  };
  constructor() {}
  onAnimationStart($event) {
    if ($event.toState === true) $event.element.style.display = 'block';
  }
  onAnimationEnd($event) {
    if ($event.toState === false) $event.element.style.display = 'none';
  }

  open() {
    // since close() is also executed, open the dropdown in the next vm tick
    setTimeout(() => {
      this.isOpen = true;
    }, 0);
  }

  @HostListener('document:click')
  emitCollapse() {
    // this is also called automatically if clicked outside of the dropbox
    if (!this.isOpen) return;
    this.isOpen = false;
    this.collapse.emit();
  }

  onClickInside(event) {
    // stop the propagation to prevent closing the dropdown if clicked inside
    event.stopPropagation();
  }
}
