<h3 class="mt-5 mb-4">How do I deposit a check from my laptop or desktop computer?</h3>

<ol>
  <li>
    Click on the
    <b>Make a Deposit tab.</b>
  </li>
  <li>
    Fill out the form including uploaded photos of the front and back of the check you’re
    depositing.
  </li>
  <li>
    Click
    <b>Submit.</b>
  </li>
</ol>
