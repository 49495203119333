import { FlowService } from '@legacy/services/flow.service';

export function flowServiceFactory($injector: any) {
  return $injector.get('flowService');
}

export const flowServiceProvider = {
  provide: FlowService,
  useFactory: flowServiceFactory,
  deps: ['$injector'],
};
