<div class="sbb-message-container">
  <div class="sbb-message">
    <p>Pay It Now service is offered to personal account users.</p>

    <p>Open a personal account to get started</p>
  </div>
  <div class="sbb-button">
    <button class="uk-btn solid secondary lg button" (click)="onOpenAccount()">
      Open an account
    </button>
  </div>
</div>
