import { Component, Input, OnInit } from '@angular/core';

import { AssetCategory } from '@app/axos-invest/enums';
import { AssetDisplay, PortfolioDetails } from '@app/axos-invest/models';

@Component({
  selector: 'app-detailed-portfolio',
  templateUrl: './detailed-portfolio.component.html',
  styleUrls: ['./detailed-portfolio.component.scss'],
})
export class DetailedPortfolioComponent implements OnInit {
  @Input() assets: AssetDisplay[];
  @Input() portfolioDetails: PortfolioDetails;
  bonds: AssetDisplay[];
  stocks: AssetDisplay[];
  cash: AssetDisplay[];

  ngOnInit(): void {
    this.loadAssetCategories();
  }

  private loadAssetCategories() {
    this.bonds = this.assets.filter(asset => asset.category === AssetCategory.Bonds);
    this.stocks = this.assets.filter(asset => asset.category === AssetCategory.Stocks);
    this.cash = this.assets.filter(asset => asset.category === AssetCategory.Cash);
  }
}
