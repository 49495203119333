import { Component, Input } from '@angular/core';

import { SecureFormsDocument } from '@app/secure-forms/models';
import { saveFile } from '@app/utils';
import { SecureFormsService } from '@core/services';

@Component({
  selector: 'app-document-section',
  templateUrl: './document-section.component.html',
  styleUrls: ['./document-section.component.scss'],
})
export class DocumentSectionComponent {
  @Input() documents: SecureFormsDocument[];
  @Input() label: string;

  constructor(private secureFormService: SecureFormsService) {}

  downloadFile(formDocument: SecureFormsDocument) {
    this.secureFormService.getForm(formDocument.formNumber, true).subscribe(response => {
      saveFile(response, formDocument.fileName);
    });
  }
}
