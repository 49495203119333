<!-- // TODO SBB: Navigation to update contact info will be different. -->

<h3 class="mt-5 mb-4">How do I update my contact information for my account?</h3>

<p class="mb-3">Your contact information can easily be changed online.</p>

<ol>
  <li>Click on your name on the upper right-hand corner.</li>
  <li>
    Select the
    <b>Contact Info tab.</b>
  </li>
  <li>Click the pencil icon to edit your email, phone, and mailing address.</li>
  <li>Save changes.</li>
</ol>
