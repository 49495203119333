import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { addDays, addMonths, format, parseISO, startOfToday } from 'date-fns';

import { getBrandProperty } from '@app/store/selectors';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { isDateValid } from '@app/utils';
import { BrandProperty } from '@core/enums';
import { TransactionService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { CommunicationIcons, UtilityIcons } from '@shared/enums';
import { CustomerDetail } from '@shared/models';
import { hasNumberNotZeroValidator } from '@shared/utils/validators';

import { LimitRequest, RequestLimitDate } from '../../models';
import { DebitCardService } from '../../services';

@Component({
  selector: 'app-request-limit-change',
  templateUrl: './request-limit-change-view.component.html',
  styleUrls: ['./request-limit-change-view.component.scss'],
})
export class RequestLimitChangeViewComponent implements OnInit, OnDestroy {
  busy: boolean;
  card: DebitCard;
  cardMask: string;
  contactPhoneNumber: string;
  endDate: RequestLimitDate;
  errors = {
    type: 'Type of Limit Change required.',
    amount: 'Enter an amount.',
    explanation: 'Enter at least 20 characters.',
  };
  holidays: Date[];
  iconConfig = {
    calendar: {
      size: '1.2rem',
      icon: CommunicationIcons.Calendar,
    },
    check: {
      size: '3rem',
      icon: UtilityIcons.Checkmark,
      fill: 'var(--accent)',
    },
  };
  limitDays = 2;
  limitRequest: LimitRequest;
  loading: boolean;
  nextAvailableDate: Date;
  profile: Partial<CustomerDetail>;
  requestLimitChangeForm: UntypedFormGroup;
  startDate: RequestLimitDate;
  step = 1;
  tooltip =
    'Your card has two daily limits. One is for retail purchases you make with your card. The other is for cash withdrawals from an ATM.';

  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private $stateParams: ng.ui.IStateParamsService,
    @Inject(STATE) private $state: ng.ui.IStateService,
    private store: Store,
    private fb: UntypedFormBuilder,
    private transactionService: TransactionService,
    private debitCardService: DebitCardService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.loading = true;
    const { card, id } = this.$stateParams;
    if (!card) {
      this.$state.go('udb.accounts.details', { id, tab: 1 });
    } else {
      this.card = card;
      this.cardMask = this.card.cardNumberMask.slice(this.card.cardNumberMask.length - 5);
      this.subsink.sink = combineLatest([
        this.store.select(getProfileDetails),
        this.transactionService.getHolidayDates(),
        this.store.select(getBrandProperty(BrandProperty.ContactPhoneNumber)),
      ]).subscribe({
        next: ([profile, holidays, contactPhoneNumber]) => {
          this.profile = profile;
          this.holidays = holidays.data.dates.map(holiday => parseISO(holiday));
          this.contactPhoneNumber = contactPhoneNumber;
          this.setInitialDates();
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  requestLimitChange() {
    if (this.requestLimitChangeForm.invalid) {
      this.requestLimitChangeForm.markAllAsTouched();
    } else {
      const formValue = this.requestLimitChangeForm.value;
      this.limitRequest = new LimitRequest({
        ...formValue,
        cardMask: this.card.cardNumberMask,
        startDate: format(formValue.startDate, 'yyyy-LL-dd'),
        endDate: format(formValue.endDate, 'yyyy-LL-dd'),
        limitAmount: formValue.limitAmount.toString(),
      });
      this.busy = true;
      this.debitCardService
        .requestTemporaryLimit(this.card.accountId, this.limitRequest)
        .pipe(
          finalize(() => {
            this.busy = false;
            this.step = 2;
          })
        )
        .subscribe({
          error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        });
    }
  }

  startDateChange(event: MatDatepickerInputEvent<Date>) {
    this.endDate = {
      minDate: event.value,
      maxDate: addDays(event.value, this.limitDays),
    };

    this.requestLimitChangeForm.patchValue({
      endDate: event.value,
    });
  }

  cancelRequestLimitChange() {
    this.$state.go('udb.accounts.details', { id: this.card.accountId, tab: 4, debitCardId: this.card.debitCardId });
  }

  gotoMyAccounts() {
    this.$state.go('udb.accounts.dashboard');
  }

  private setupForm() {
    this.requestLimitChangeForm = this.fb.group({
      limitType: this.fb.control('', [Validators.required]),
      limitAmount: this.fb.control(0, [Validators.required, hasNumberNotZeroValidator]),
      explanation: this.fb.control('', [Validators.required, Validators.minLength(20)]),
      startDate: this.fb.control(this.nextAvailableDate, [Validators.required]),
      endDate: this.fb.control(addDays(this.nextAvailableDate, 2), [Validators.required]),
    });
  }

  private setInitialDates() {
    this.nextAvailableDate = this.getNextValidDate();

    this.startDate = {
      minDate: this.nextAvailableDate,
      maxDate: addMonths(addDays(this.nextAvailableDate, this.limitDays), 11),
    };

    this.endDate = {
      minDate: this.nextAvailableDate,
      maxDate: addDays(this.nextAvailableDate, 2),
    };

    this.setupForm();
  }

  private getNextValidDate(): Date {
    let validDays = 0;

    this.nextAvailableDate = startOfToday();
    while (validDays < 2) {
      if (isDateValid(this.nextAvailableDate, this.holidays)) {
        validDays++;
      }
      this.nextAvailableDate = addDays(this.nextAvailableDate, 1);
    }
    this.loading = false;

    return this.nextAvailableDate;
  }
}
