<div class="stop-payment-extend frow center">
  <div class="fcol-cs-12 fcol-sm-10">
    <div class="frow fcol-cs-12 container__btn-back">
      <a class="finline middle" (click)="redirectToPage(accountId)">
        <i class="bofi-arrow-chevron" aria-hidden="true"></i>
        <span>Back</span>
      </a>
    </div>

    <div class="frow fcol-cs-12">
      <img src="/img/icons/accounts/stop_payment.svg" width="100" aria-hidden="true" />
      <div class="fcol-cs-8 fcol-sm-10">
        <h2>Stop Payment Extension</h2>
        <p>Extend a stop payment to a check or an ACH payment.</p>
      </div>
    </div>
    <div class="remove-contain">
      <div class="frow between middle fcol-cs-12 question">
        <p>
          <b>Are you sure you would like to extend the stop payment?</b>
        </p>
      </div>
      <div class="remove-item frow" *ngIf="spExtend">
        <div class="fcol-xs-3">
          <b>Type:</b>
          <span>{{ spExtend.stopType }}</span>
        </div>
        <div class="fcol-xs-3">
          <b>Requested Date:</b>
          <span>{{ spExtend.requestedDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>

      <div class="remove-item frow" *ngIf="spExtend">
        <div class="fcol-xs-3">
          <b>Check Number:</b>
          <span *ngIf="spExtend.stopType === 'Check'">
            <span>{{ '#' + spExtend.lowCheckNumber }}</span>
            <span *ngIf="spExtend.highCheckNumber && spExtend.lowCheckNumber !== spExtend.highCheckNumber">
              {{ ' - #' + spExtend.highCheckNumber }}
            </span>
          </span>
          <span *ngIf="spExtend.stopType === 'ACH'">N/A</span>
        </div>
        <div class="fcol-xs-3">
          <b>Expiration Date:</b>
          <span>{{ spExtend.expirationDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>

      <div class="remove-item frow">
        <div class="fcol-xs-3">
          <b>Account:</b>
          <span>{{ accountName }}</span>
        </div>
        <div class="fcol-xs-3">
          <b>Status:</b>
          <i class="bofi-pending">
            <span>In Process</span>
          </i>
        </div>
      </div>

      <div
        *ngIf="
          spExtend &&
          ((spExtend.highCheckNumber && spExtend.lowCheckNumber === spExtend.highCheckNumber) ||
            spExtend.stopType === 'ACH')
        "
        class="remove-item frow"
      >
        <div class="fcol-xs-8">
          <b>Amount:</b>
          <span>{{ spExtend.amount | currency }}</span>
        </div>
      </div>

      <div
        *ngIf="
          spExtend &&
          ((spExtend.highCheckNumber && spExtend.lowCheckNumber === spExtend.highCheckNumber) ||
            spExtend.stopType === 'ACH')
        "
        class="remove-item frow"
      >
        <div class="fcol-xs-8">
          <b>Date</b>
          <b *ngIf="spExtend.stopType === 'Check'">Written</b>
          <b *ngIf="spExtend.stopType === 'ACH'">of Expected Debit</b>
          :
          <span>{{ spExtend.checkDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>

      <div class="remove-item frow" *ngIf="spExtend">
        <div
          *ngIf="
            (spExtend.highCheckNumber && spExtend.lowCheckNumber === spExtend.highCheckNumber) ||
            spExtend.stopType === 'ACH' ||
            spExtend.stopType === 'ACH'
          "
          class="fcol-xs-8"
        >
          <b>Payee:</b>
          <span>{{ spExtend.payeeDebitingCompany }}</span>
        </div>
      </div>

      <div class="fcol-xs-12 extend-date">
        <p>
          Once submitted, your NEW Expiration Date will be:
          <b>{{ extendDate | date: 'MM/dd/yyyy' }}</b>
          .
        </p>
      </div>

      <div class="frow fcol-xs-12 stop-payment-extend__note">
        <div class="note fcol-xs-8">
          <p>
            <b>NOTE:</b>
            There is a fee for an extension on a stop payment. Click 'Submit' to accept this fee and continue with the
            extension.
            <a href="{{ terms }}" target="_blank">View Terms</a>
          </p>
        </div>
        <div class="fee fcol-xs-4">
          <span>
            Stop Payment Extension Fee:
            <span class="text-error">{{ extendFee | currency }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="frow end fcol-cs-12" *ngIf="!isBusy">
      <button type="button" (click)="redirectToPage(accountId)" class="uk-btn sm outline primary btn--cancel">
        Cancel
      </button>
      <button type="button" (click)="extend()" class="uk-btn solid secondary sm">
        Confirm Extension
      </button>
    </div>
    <app-dot-loader [showWhen]="isBusy">Verifying...</app-dot-loader>
  </div>
</div>
