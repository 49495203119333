import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyExtended',
})
export class CurrencyExtendedPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    const [suffix = null, fallback = 'N/A', dash = false, currencyCode, display, digitsInfo, locale] = args;

    if (isNaN(value)) {
      return fallback;
    } else {
      let transformed = super.transform(value, currencyCode, display, digitsInfo, locale);

      if (suffix) transformed += ` ${suffix}`;
      if (dash && value === 0) transformed = '$--';

      return transformed;
    }
  }
}
