<h3 class="mt-5 mb-4">How do I transfer money between my accounts?</h3>

<ol>
  <li>
    Click on
    <b>Move Money</b>
    and select the
    <b>Schedule Transfer tab.</b>
  </li>
  <li>
    You can make a
    <b>One Time</b>
    or a
    <b>Recurring transfer.</b>
    After selecting your eligible
    <b>"From Account"</b>
    and
    <b>"To Account",</b>
    you may select the date you wish to make the one-time transfer which can be same day or future
    dated.
  </li>
  <li>
    For a recurring transfer, you can also select the frequency option from the drop down and how
    long you would like the recurring transfers to continue.
  </li>
  <li>
    Completed transfers will be listed in your online account activity and on your account
    statements.
  </li>
</ol>
