import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NinthWaveWebConnect } from '../ninth-wave-web-connect.interface';
import * as moment from 'moment';
import { DownloadOptionsConstants } from '../download-options.constants';
import { NWFileDownloadTypeProperty } from '@legacy/typings/app/download-transaction/nw-file-download-type-property.interface';
import { NinthWaveService } from '@core/services';
import { FileResponse } from '@legacy/typings/app/download-transaction/file-response.interface';
import { STATE } from '@core/tokens';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ModalErrorComponent } from '../../modals/download-transactions/modal-error/modal-error.component';

@Component({
  selector: 'app-download-form',
  templateUrl: './download-form.component.html',
  styleUrls: ['./download-form.component.scss'],
})
export class DownloadFormComponent implements OnInit {
  @Input() accounts: OlbAccount[];
  @Input() defaultAccount: number;
  @Input() nwFileFormats: NWFileDownloadTypeProperty[];
  downloadForm: FormGroup;
  transactionPeriodOptions = DownloadOptionsConstants.TRANSACTION_PERIOD_OPTIONS;
  monthMap = DownloadOptionsConstants.MonthMap.MONTH_MAP;
  submittingForm: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private ninthWaveService: NinthWaveService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.downloadForm = this.formBuilder.group({
      accountId: [this.defaultAccount, Validators.required],
      transactionPeriod: ['', Validators.required],
      optionSelected: ['', Validators.required],
      fileFormat: ['', Validators.required],
    });
  }

  onOptionSelected(value: string): void {
    this.downloadForm.get('optionSelected').setValue(value);
  }

  onSubmit(): void {
    const webConnect = this.convertToNinthWaveWebConnect();
    this.downloadForm.disable();
    this.submittingForm = true;

    this.ninthWaveService.TransactionsDownload(webConnect).subscribe({
      next: (resp: OlbResponse<FileResponse>) => {
        this.downloadFile(resp, webConnect);
        this.navigateToDetails();
      },
      error: () => {
        this.downloadForm.enable();
        this.submittingForm = false;
        this.cdr.detectChanges();
        this.matDialog.open(ModalErrorComponent);
      },
    });
  }

  convertToNinthWaveWebConnect(): NinthWaveWebConnect {
    const selectedPeriod = this.downloadForm.get('transactionPeriod').value;
    const optionSelected = this.downloadForm.get('optionSelected').value;

    let startDate: string;
    let endDate: string;

    if (selectedPeriod === 'Month') {
      const [month, year] = optionSelected.split(' ');
      const monthNumber = this.getMonthNumber(month) + 1;
      startDate = moment(`${year}-${monthNumber}-01`).format('MM/DD/YYYY');
      endDate = moment(`${year}-${monthNumber}-01`).endOf('month').format('MM/DD/YYYY');
    }

    if (selectedPeriod === 'Quarter') {
      const [quarter, year] = optionSelected.split(' ');
      const quarterData = this.getQuarterDates(quarter);
      startDate = moment(`${year}-${quarterData.start}`).format('MM/DD/YYYY');
      endDate = moment(`${year}-${quarterData.end}`).format('MM/DD/YYYY');
    }

    if (selectedPeriod === 'Year') {
      startDate = moment(`${optionSelected}-01-01`).format('MM/DD/YYYY');
      endDate = moment(`${optionSelected}-12-31`).format('MM/DD/YYYY');
    }

    // Check if endDate is greater than the current date.
    // Currently, if we just leave future day, Ninthwave will not retrieve any transactions.
    if (moment(endDate, 'MM/DD/YYYY').isAfter(moment(), 'day')) {
      endDate = moment().format('MM/DD/YYYY'); // Replace endDate with the current date
    }

    return this.createNinthWaveWebConnect(startDate, endDate);
  }

  private downloadFile(resp: OlbResponse<FileResponse>, webConnect: NinthWaveWebConnect): void {
    const byteArray = new Uint8Array(
      atob(resp.data.fileContents)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], {
      type: resp.data.contentType,
    });
    const accountId = +webConnect.accountIds[0];
    const name = this.accounts.find((account: OlbAccount) => account.id === accountId)?.accountNumber;
    const extension =  resp.data.fileDownloadName.slice(1, -1).split('.').pop();
    const fileName = `${name}-${webConnect.startDate}-${
      webConnect.endDate
    }.${extension}`;
    saveAs(blob, fileName);
  }

  private navigateToDetails(): void {
    this.state.go('udb.accounts.details', {
      id: this.downloadForm.value.accountId,
    });
  }

  private createNinthWaveWebConnect(startDate: string, endDate: string): NinthWaveWebConnect {
    return {
      accountIds: [this.downloadForm.value.accountId],
      fileFormat: this.downloadForm.value.fileFormat,
      startDate,
      endDate,
      maxLimit: 1000, // max limit placeholder
    };
  }

  private getMonthNumber(month: string): number {
    return this.monthMap[month];
  }

  private getQuarterDates(quarter: string): { start: string; end: string } {
    const quarterMap = {
      Q1: { start: '01/01', end: '03/31' },
      Q2: { start: '04/01', end: '06/30' },
      Q3: { start: '07/01', end: '09/30' },
      Q4: { start: '10/01', end: '12/31' },
    };
    return quarterMap[quarter];
  }
}
