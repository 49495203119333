<div
  class="click-switch-detail"
  [ngClass]="switch.status"
  [class.click-switch-without-message]="!statusMessage"
>
  <div class="click-switch-name">
    {{ switch.name }}
  </div>
  <div class="click-switch-body" [attr.data-accounts]="switch.accounts?.length">
    <div class="click-switch-accounts">
      <div class="click-switch-account" *ngFor="let account of switch.accounts">
        <div class="click-switch-account-item">
          <div class="click-switch-account-item-title">
            Income Type
          </div>
          <div class="click-switch-account-item-value">
            {{ switch.incomeType ? switch.incomeType : 'N/A' }}
          </div>
        </div>
        <div class="click-switch-account-item">
          <div class="click-switch-account-item-title">
            Deposit Account
          </div>
          <div class="click-switch-account-item-value">
            {{ account.account }}
          </div>
        </div>
        <div class="click-switch-account-item">
          <div class="click-switch-account-item-title">Deposit Percentage</div>
          <div class="click-switch-account-item-value">{{ account.splitAmount }}%</div>
        </div>
      </div>
    </div>
  </div>
  <div class="click-switch-right-section">
    <div class="click-switch-view-section">
      <div
        class="click-switch-view-button"
        *ngIf="showViewBtn"
        (click)="goToConfirmation.emit(switch)"
      >
        VIEW
      </div>
    </div>
    <div class="click-switch-status">
      <div class="click-switch-status-container">
        <div class="click-switch-status-wrapper">
          <span class="click-switch-status-dot"></span>
          <div class="click-switch-status-label">
            {{ switchStatus }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="click-switch-footer" [innerHtml]="statusMessage"></div>
</div>
