import { SimilarBiller } from './SimilarBiller';

export class Biller {
  addressMatchRequired?: boolean;
  isAddressMatchRequired?: boolean;
  addressMatchRequiredSpecified?: boolean;
  alias?: string;
  billerId?: number;
  billerGroupId?: number;
  billerName?: string;
  billerAddressId?: number;
  emailAddress?: string;
  name?: string;
  nickName?: string;
  paymentAccountHint?: string;
  paymentMethod?: number;
  paymentAccount?: string;
  phoneNumber?: string;
  allBillers?: SimilarBiller[];
  [index: string]: any;
}
