import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { finalize } from 'rxjs/operators';

import { SpousalConsentRequest } from '@app/axos-trading/models';
import { validEmailRegex } from '@app/config/regexes';
import { HttpStatusCode, OlbEvents } from '@core/enums';
import { OlbSettings } from '@core/models';
import { OlbEventService } from '@core/services';
import { PropertyBagService } from '@core/services/property-bag.service';
import { olbSettings } from '@core/tokens';
import { TradingAccount } from '@legacy/accounts/typings/TradingAccount';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AlertsIcons, CommunicationIcons, SvgIconNames } from '@shared/enums';

@Component({
  selector: 'app-resend-consent-modal',
  templateUrl: './resend-consent-modal.component.html',
  styleUrls: ['./resend-consent-modal.component.scss'],
})
export class ResendConsentModalComponent implements OnInit {
  backgroundUrl: string;
  icon = {
    name: AlertsIcons.ExclamationCircle as SvgIconNames,
    size: '5rem',
    color: 'white',
  };
  maskedPhone: string;
  isBusy = false;
  showForm = false;
  resendForm: UntypedFormGroup;
  title = 'You cannot modify beneficiaries while pending spousal consent';
  errors = [
    { name: 'required', message: "Spuse's Email is required." },
    { name: 'pattern', message: "Spouse's Email address is invalid." },
  ];

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(MAT_DIALOG_DATA) private account: TradingAccount,
    private fb: UntypedFormBuilder,
    private propertyBagService: PropertyBagService,
    private olbEventService: OlbEventService,
    private dialogRef: MatDialogRef<ResendConsentModalComponent>,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    const { brand } = this.env;
    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;

    this.resendForm = this.fb.group({
      spouseEmail: this.fb.control(this.account.spousalConsentEmail, [
        Validators.required,
        Validators.pattern(validEmailRegex),
      ]),
    });
  }

  showResendForm(): void {
    this.icon.name = CommunicationIcons.Envelope;
    this.showForm = true;
    this.title = "We'll resend a consent link to your spouse's email below";
  }

  resendConsent(): void {
    if (this.resendForm.invalid) {
      this.resendForm.markAllAsTouched();
    } else {
      this.isBusy = true;
      const spouseEmail = this.resendForm.get('spouseEmail').value;
      const request = new SpousalConsentRequest({
        taxPlanType: this.account.taxPlanType,
        spouseEmail,
        accountNumber: this.account.accountNumber,
      });

      this.propertyBagService
        .resendSpousalConsent(request)
        .pipe(
          finalize(() => {
            this.isBusy = true;
          })
        )
        .subscribe({
          next: () => {
            this.dialogRef.close(spouseEmail);
          },
          error: err => {
            if (err.status !== HttpStatusCode.NotFound) {
              this.serviceHelper.errorHandler(err);
            }
          },
        });
    }
  }

  cancelSpousalConsent(): void {
    const request = new SpousalConsentRequest({
      taxPlanType: this.account.taxPlanType,
      accountNumber: this.account.accountNumber,
    });
    this.propertyBagService
      .cancelSpousalConsent(request)
      .pipe(
        finalize(() => {
          this.isBusy = true;
        })
      )
      .subscribe({
        next: () => {
          this.dialogRef.close('cancel');
          this.olbEventService.emit(OlbEvents.CancelSpousalConsent);
        },
        error: err => {
          if (err.status !== HttpStatusCode.NotFound) {
            this.serviceHelper.errorHandler(err);
          }
        },
      });
  }
}
