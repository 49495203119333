import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';

import { allCategories } from '@app/pfm/models/category';
import { PfmTransaction } from '@app/pfm/models/pfm-transaction.model';
import { recategorizeTransaction } from '@app/pfm/store/spending/spending.actions';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { RecategorizeTransactionRequest } from '@legacy/typings/app/account-aggregation/RecategorizeTransactionRequest';

@Component({
  selector: 'app-recategorize',
  templateUrl: './recategorize.component.html',
  styleUrls: ['./recategorize.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecategorizeComponent implements OnInit {
  @Input()
  expand: boolean;
  @Input()
  transaction: PfmTransaction;
  @Output() closeTray = new EventEmitter();
  onlyThisTransaction: UntypedFormControl;
  selectedCategory: Partial<Category>;
  categories = allCategories.sort((a: Category, b: Category) => a.id - b.id);
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountAggregationService: AccountAggregationService,
    private readonly store: Store
  ) {}
  ngOnInit(): void {
    this.onlyThisTransaction = this.formBuilder.control(false);
    this.selectedCategory = { id: this.transaction.olbCategoryId, name: this.transaction.olbCategoryName };
  }

  isCategorySelected = (category: Category) => this.selectedCategory.id === category.id;

  selectCategory = (category: Category) => (this.selectedCategory = category);

  updateCategory() {
    if (this.selectedCategory.id !== this.transaction.olbCategoryId) {
      this.loading = true;
      const request: RecategorizeTransactionRequest = {
        transactionId: this.transaction.transactionId,
        transactionDesc: !!this.transaction.simpleDescription
          ? this.transaction.simpleDescription
          : this.transaction.originalDescription,
        matchingTransactions: this.onlyThisTransaction.value,
        olbCategory: this.selectedCategory.name,
        olbCategoryId: this.selectedCategory.id,
        previousOlbCategory: this.transaction.olbCategoryName,
      };
      this.accountAggregationService
        .recategorizeTransaction(request)
        .then(_ => {
          this.store.dispatch(recategorizeTransaction({ payload: request }));
        })
        .finally(() => {
          this.loading = false;
          this.closeTray.emit();
        });
    }
    this.closeTray.emit();
  }
}
