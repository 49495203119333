import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserPreferencesState } from '../types';

const userPreferencesState = createFeatureSelector<UserPreferencesState>(AasStoreFeatures.UserPreferences);

export function getUserPreferencesForAccount(accountNumber: string) {
  return createSelector(userPreferencesState, state => {
    return state.userPreferences.filter(x => x.accountNumber === accountNumber);
  });
}

export const preferencesState = createSelector(userPreferencesState, state => state);
