import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { KeyValuePair } from '@core/models';
import { FilesIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch-details',
  templateUrl: './click-switch-details.component.html',
  styleUrls: ['./click-switch-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickSwitchDetailsComponent {
  @Input()
  canEdit = false;
  @Input()
  title: string;
  @Input()
  details: KeyValuePair<string, string>[];

  @Output()
  edit = new EventEmitter<never>();

  editIcon = FilesIcons.Pencil;
}
