export enum ContainerType {
  Insurance = 'insurance',
  Bill = 'bill',
  RealEstate = 'realEstate',
  Reward = 'reward',
  Bank = 'bank',
  Loan = 'loan',
  CreditCard = 'creditCard',
  Investment = 'investment',
}
