import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Chart } from 'chart.js';
import { differenceInDays, differenceInMonths, differenceInWeeks, format, isBefore, isWeekend, sub } from 'date-fns';

import { isSameOrBefore } from '@app/utils';

import { TotalValueIntervalPeriodDateState } from '../../../core';
import {
  DATE_FORMAT,
  RIA_NEGATIVE_MOVEMENTS_COLORS,
  RIA_NO_CHANGE_MOVEMENTS_COLORS,
  RIA_POSITIVE_MOVEMENTS_COLORS,
  SIZE_DIFFERENCE,
  TOTAL_VALUE_CONFIGURATION,
  WEEKS_DIFFERENCE,
} from './constants';
import { TotalValueConfiguration, TotalValueFilter } from './models';
import { Delta } from '../../../core/store/enums';
import {
  asyncCanvasUpdate,
  createChartColorContext,
  justifyChartLabelsCenter,
  justifyChartLabelsEvenly,
} from './functions';
import { JustifyChartLabelsContext, ProcessedLabel } from './types';
import {
  DESKTOP_FULL_HD_MIN_WIDTH,
  DESKTOP_HD_MAX_WIDTH,
  DESKTOP_HD_MIN_WIDTH,
  LAPTOP_MAX_WIDTH,
  MOBILE_MAX_WIDTH,
} from '@app/Areas/AAS/features/product-details-page/view/product-details-page/constants';

@Component({
  selector: 'app-total-value-graph',
  templateUrl: './total-value-graph.component.html',
  styleUrls: ['./total-value-graph.component.scss'],
})
export class TotalValueGraphComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() data: TotalValueIntervalPeriodDateState[];
  @Input() hasError: boolean;
  @Input() filter: TotalValueFilter;
  @Input() displayColor: Delta;
  @Output() tooltipValue = new EventEmitter<any>();
  @ViewChild('chartCanvas') chartCanvas: ElementRef;
  fromDate: Date;
  toDate: Date;
  chart: Chart;
  chartConfiguration: TotalValueConfiguration;
  color: string;
  minValueOfArray = 0;
  maxValueOfArray = 0;
  tooltipFormatText = DATE_FORMAT.LessThanAYear;
  private readonly palettes = {
    [Delta.Gain]: RIA_POSITIVE_MOVEMENTS_COLORS,
    [Delta.Loss]: RIA_NEGATIVE_MOVEMENTS_COLORS,
    [Delta.NoChange]: RIA_NO_CHANGE_MOVEMENTS_COLORS,
  };

  constructor() {}

  ngOnChanges() {
    this.calculateTimelapse();
  }

  ngAfterViewInit(): void {
    this.calculateTimelapse();
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  private calculateTimelapse() {
    if (this.hasError) {
      this.data = this.generateEmptyData();
      this.hasError = true;
    }
    this.color = this.getColor();
    this.minValueOfArray = this.minimumValueOfArray(this.data);
    this.maxValueOfArray = this.maximumValueOfArray(this.data);
    this.toDate = new Date(this.data[0].date);
    const firstAvailableDate = new Date(this.data.slice(-1)[0].date);
    this.fromDate = firstAvailableDate;
    this.chartConfiguration = this.validateYTD();
    const dataLabels: number[] = [];
    const tooltipLabels: string[] = [];
    const data: number[] = [];

    let itemDate = this.toDate;
    while (isSameOrBefore(this.fromDate, itemDate)) {
      const dataElement = this.data.find(t => new Date(t.date).toISOString() === itemDate.toISOString());

      if (dataElement || isBefore(itemDate, firstAvailableDate)) {
        const labels = this.setupLabels(itemDate);
        dataLabels.push(labels.data);
        tooltipLabels.push(labels.tooltip);

        data.push(dataElement?.value || 0);
      }

      itemDate = sub(itemDate, { days: 1 });
    }

    const operation = this.chart ? this.updateChart : this.createChart;
    operation.bind(this)(data.reverse(), dataLabels.reverse(), tooltipLabels.reverse());
  }

  private generateEmptyData() {
    let days = 0;
    const today = new Date();
    const totalValueResponse: TotalValueIntervalPeriodDateState[] = [];
    days = differenceInDays(today, sub(new Date(), { months: 3 }));
    for (let i = 0; i <= days; i++) {
      const date = sub(today, { days: i });
      if (isWeekend(date)) continue;
      totalValueResponse.push({
        date,
        value: 0,
      });
    }
    return totalValueResponse;
  }

  private setupLabels(day: Date): { data: number; tooltip: string } {
    return {
      data: this.chartConfiguration.dataLabelFormat ? day.getTime() : 0,
      tooltip: this.chartConfiguration.tooltipLabel.replace(
        '${date}',
        format(new Date(day), this.chartConfiguration.timeLabelFormat)
      ),
    };
  }

  private createChart(data: number[], dataLabels: string[]) {
    if (this.chartCanvas?.nativeElement) {
      this.chart = new Chart(this.chartCanvas.nativeElement, {
        type: 'line',
        data: {
          labels: dataLabels,
          datasets: [
            {
              data,
              borderColor: this.color,
              borderWidth: 1,
              fill: true,
              tension: 0,
              pointRadius: 0,
              pointHitRadius: 25,
              pointHoverBackgroundColor: '#FFFFFF',
              pointHoverBorderWidth: 0,
              pointHoverRadius: 4,
              backgroundColor: this.createGradientBackground(),
            },
          ],
        },
        options: {
          interaction: {
            mode: 'index',
            intersect: false,
          },
          layout: {
            padding: {
              top: 14,
              left: -20,
              right: -5,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          devicePixelRatio: 1,
          stacked: false,
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                autoSkip: true,
                gridLines: {
                  display: false,
                },
                time: {
                  displayFormats: this.getFormat(),
                  unit: this.chartConfiguration.unit,
                  unitStepSize: this.chartConfiguration.unitStepSize,
                },
                ticks: {
                  callback: this.threeMonthFilterXAxisLabelTickCallBack(),
                  maxRotation: 0,
                },
                scaleLabel: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  callback: (): string => {
                    return '';
                  },
                },
              },
            ],
          },
          onHover: (e, activeelements) => {
            if (activeelements.length == 0 && e != null) {
              this.tooltipValue.emit({ value: null, hover: false });
            }
          },
          hover: {
            animationDuration: 0,
            mode: 'index',
            intersect: false,
          },
          tooltips: {
            enabled: false,
            custom: tooltipModel => {
              this.setTooltips(tooltipModel);
            },
            mode: 'index',
            intersect: false,
            position: 'nearest',
          },
          animation: {
            duration: 0,
          },
        },
      });
      this.setYAxisChartSettings();
    }
  }

  private async updateChart(data: number[], dataLabels: string[]): Promise<void> {
    this.minValueOfArray = this.minimumValueOfArray(data);
    this.maxValueOfArray = this.maximumValueOfArray(data);
    this.setYAxisChartSettings();
    this.chart.data.labels = dataLabels;
    this.chart.data.datasets[0].data = data;
    this.chart.data.datasets[0].borderColor = this.color;
    this.chart.data.datasets[0].pointHoverBackgroundColor = '#FFFFFF';
    this.chart.data.datasets[0].backgroundColor = this.createGradientBackground();
    this.chart.options.scales.xAxes[0].time.unit = this.chartConfiguration.unit;
    this.validateFilterTooltip(dataLabels[dataLabels.length - 1], dataLabels[0]);
    if (this.filter.label === 'YTD' || this.filter.label === 'All') {
      this.validateFilterAll(dataLabels[dataLabels.length - 1], dataLabels[0]);
    } else {
      this.chart.options.scales.xAxes[0].time.unitStepSize = this.chartConfiguration.unitStepSize;
      this.chart.options.scales.xAxes[0].ticks.callback = null;
    }
    if (this.filter.label === '1Y') {
      this.set1YearFilterXAxisLabels();
    } else if (this.filter.label === '3M') {
      this.setLabelsFor3M();
    } else if (this.filter.label === '6M') {
      this.setLabelsByWeeks(dataLabels[dataLabels.length - 1], dataLabels[0], 4);
    }
    this.chart.options.scales.xAxes[0].time.displayFormats = this.getFormat();
    this.chart.options.tooltips.footerFontColor = this.color;
    this.chart.update();
    const MS_TO_PERFORM_CHART_UPDATE = 100;
    await asyncCanvasUpdate(MS_TO_PERFORM_CHART_UPDATE, setTimeoutRef => {
      this.chart.update();
      clearTimeout(setTimeoutRef);
    });
  }

  private createGradientBackground(): string | CanvasGradient {
    return createChartColorContext(this.chartCanvas.nativeElement, this.getDeltaMovement(), this.palettes)
      .backgroundColor;
  }

  private getFormat() {
    return {
      millisecond: this.chartConfiguration.dataLabelFormat,
      second: this.chartConfiguration.dataLabelFormat,
      minute: this.chartConfiguration.dataLabelFormat,
      hour: this.chartConfiguration.dataLabelFormat,
      day: this.chartConfiguration.dataLabelFormat,
      week: this.chartConfiguration.dataLabelFormat,
      month: this.chartConfiguration.dataLabelFormat,
      quarter: this.chartConfiguration.dataLabelFormat,
      year: this.chartConfiguration.dataLabelFormat,
    };
  }

  private validateYTD() {
    if (this.filter.label !== 'YTD')
      return (this.chartConfiguration = TOTAL_VALUE_CONFIGURATION.find(x => x.filter === this.filter.label));
    const difference = differenceInMonths(this.toDate, this.fromDate);
    return difference < 6
      ? (this.chartConfiguration = TOTAL_VALUE_CONFIGURATION.find(x => x.filter === '3M'))
      : (this.chartConfiguration = TOTAL_VALUE_CONFIGURATION.find(x => x.filter === '6M'));
  }

  private setUnitSetSize(newUnitStepSize: number): void {
    this.chart.options.scales.xAxes[0].time.unitStepSize = newUnitStepSize;
  }

  private validateFilterTooltip(initialDate: string, finalDate: string) {
    const weeksDifference = differenceInWeeks(new Date(initialDate), new Date(finalDate));
    this.chart.options.scales.xAxes[0].time.unitStepSize = 1;
    switch (true) {
      case weeksDifference <= WEEKS_DIFFERENCE.LessThanAYear:
        this.tooltipFormatText = DATE_FORMAT.LessThanAYear;
        break;
      case weeksDifference > WEEKS_DIFFERENCE.FirstOneToSevenYears &&
        weeksDifference < WEEKS_DIFFERENCE.LastOneToSevenYears:
        this.tooltipFormatText = DATE_FORMAT.OneToSevenYears;
        break;
      case weeksDifference > WEEKS_DIFFERENCE.FirstEightToThirtyYears &&
        weeksDifference < WEEKS_DIFFERENCE.LasttEightToThirtyYears:
        this.tooltipFormatText = DATE_FORMAT.EightToThirtyYears;
        break;
      case weeksDifference > WEEKS_DIFFERENCE.MoreThanThirtyOneYears:
        this.tooltipFormatText = DATE_FORMAT.MoreThanThirtyOneYears;
        break;
    }
  }

  private validateFilterAll(initialDate: string, finalDate: string) {
    const weeksDifference = differenceInWeeks(new Date(initialDate), new Date(finalDate));
    this.chart.options.scales.xAxes[0].time.unitStepSize = 1;
    switch (true) {
      case weeksDifference <= 12:
        const datesToShow: number = 5;
        this.chartConfiguration.dataLabelFormat = DATE_FORMAT.MMDD;
        if (datesToShow >= weeksDifference) {
          this.chart.options.scales.xAxes[0].time.unit = 'day';
          const daysDifference = differenceInDays(new Date(initialDate), new Date(finalDate));
          this.setCenteredLabels({
            datesToShow: 5,
            amount: daysDifference,
            unitStepSizeSetter: this.setUnitSetSize.bind(this),
          });
          return;
        }
        this.setCenteredLabels({
          datesToShow: 5,
          amount: weeksDifference,
          unitStepSizeSetter: this.setUnitSetSize.bind(this),
        });
        break;
      case weeksDifference <= 26 && weeksDifference > 12:
        this.chartConfiguration.dataLabelFormat = DATE_FORMAT.MMDD;
        this.setCenteredLabels({
          datesToShow: 5,
          amount: weeksDifference,
          unitStepSizeSetter: this.setUnitSetSize.bind(this),
        });
        break;
      case weeksDifference > 26 && weeksDifference < 33:
        // range - 6.5 months to 8.5 months
        this.chartConfiguration.dataLabelFormat = DATE_FORMAT.MMMYYYY;
        this.chart.options.scales.xAxes[0].time.unit = 'week';
        this.setCenteredLabels({
          datesToShow: 4,
          amount: weeksDifference,
          unitStepSizeSetter: this.setUnitSetSize.bind(this),
        });
        break;
      case weeksDifference > 34 && weeksDifference < 51:
        // range - 8.5 months to almost a year
        this.chartConfiguration.dataLabelFormat = DATE_FORMAT.MMMYYYY;
        this.setCenteredLabels({
          datesToShow: 4,
          amount: weeksDifference,
          unitStepSizeSetter: this.setUnitSetSize.bind(this),
        });
        break;
      case weeksDifference > 52 && weeksDifference < 156:
        // range - 1 year to 3 years
        this.chartConfiguration.dataLabelFormat = DATE_FORMAT.MMMYYYY;
        this.setCenteredLabels({
          datesToShow: 5,
          amount: weeksDifference,
          unitStepSizeSetter: this.setUnitSetSize.bind(this),
        });
        break;
      case weeksDifference > 157 && weeksDifference < 260:
        this.chartConfiguration.dataLabelFormat = DATE_FORMAT.YYYY;
        this.setCenteredLabels({
          datesToShow: 4,
          amount: weeksDifference,
          unitStepSizeSetter: this.setUnitSetSize.bind(this),
        });
        break;
      case weeksDifference > 261:
        // range - 5 years onwards
        this.chartConfiguration.dataLabelFormat = DATE_FORMAT.YYYY;
        this.setCenteredLabels({
          datesToShow: 6,
          amount: weeksDifference,
          unitStepSizeSetter: this.setUnitSetSize.bind(this),
        });
        break;
    }
  }

  private setLabelsFor3M(): void {
    this.chart.options.scales.xAxes[0].time.unit = 'week';
    this.chart.options.scales.xAxes[0].time.unitStepSize = 1;
    this.chart.options.scales.xAxes[0].ticks.callback = this.threeMonthFilterXAxisLabelTickCallBack();
  }
  private setLabelsByWeeks(initialDate: string | number, finalDate: string | number, datesToShow: number): void {
    const differenceWeeks = differenceInWeeks(new Date(initialDate), new Date(finalDate));
    this.chart.options.scales.xAxes[0].time.unitStepSize = 1;
    this.setCenteredLabels({
      datesToShow,
      amount: differenceWeeks,
      unitStepSizeSetter: this.setUnitSetSize.bind(this),
    });
  }
  private setCenteredLabels(context: JustifyChartLabelsContext): void {
    /**
     * this variable stores boolean value to check there will be a
     * label in the center of the chart and used as pivot to render
     * the rest of the labels
     */
    const centerAlignPivotalLabel: boolean = context.datesToShow % 2 !== 0;
    this.chart.options.scales.xAxes[0].ticks.callback = centerAlignPivotalLabel
      ? justifyChartLabelsCenter.bind(context)
      : justifyChartLabelsEvenly.bind(context);
  }

  /**
   * Sets 1 year filter X-axis labels to display ticks each 2 months.
   * @see {@link TOTAL_VALUE_CONFIGURATION} for Y1 configuration for this callback to work the time unit configuration
   * must be 'week'
   */
  private set1YearFilterXAxisLabels() {
    this.chart.options.scales.xAxes[0].time.unit = 'week';
    this.chart.options.scales.xAxes[0].time.unitStepSize = 7.5;
    this.chart.options.scales.xAxes[0].ticks.callback = (v: number) => v;
  }

  private threeMonthFilterXAxisLabelTickCallBack(): (
    v: number,
    index: number,
    groupOfLabels: Array<object>
  ) => number | string {
    return (value: number, index: number, groupOfLabels: Array<ProcessedLabel<number>>): number | string => {
      const labelsToShow = 5 as const;
      const middlePosition = Math.floor(groupOfLabels?.length / 2);
      const steps = Math.floor(groupOfLabels?.length / labelsToShow);
      const positionsForTheLabels = [
        middlePosition,
        middlePosition - steps,
        middlePosition + steps,
        middlePosition - steps * 2,
        middlePosition + steps * 2,
      ];
      return positionsForTheLabels.includes(index) ? value : '';
    };
  }

  private getDeltaMovement(): Delta {
    return this.displayColor;
  }

  private getColor() {
    switch (this.displayColor) {
      case Delta.Gain:
        return '#078761';
      case Delta.NoChange:
        return '#0062B3';
      case Delta.Loss:
        return '#D2272F';
    }
  }

  private setStepSizeYaxes(delimiter: number): number {
    return this.maxValueOfArray > 0 ? Math.ceil((this.maxValueOfArray - this.setBufferYAxis()) / delimiter) : 0.2;
  }

  private minimumValueOfArray(data: any[]) {
    return Math.min(...data.map(item => item.value ?? item));
  }
  private maximumValueOfArray(data: any[]) {
    return Math.max(...data.map(item => item.value ?? item));
  }

  private setBufferYAxis() {
    return this.minValueOfArray > 0 ? Math.ceil(this.minValueOfArray - this.minValueOfArray * 0.05) : 0;
  }

  private setYAxisChartSettings() {
    this.chart.options.scales.yAxes[0].ticks.beginAtZero =
      this.minValueOfArray > 0 && this.maxValueOfArray > 0 ? false : true;
    this.chart.options.scales.yAxes[0].ticks.min = this.setBufferYAxis();
    this.chart.options.scales.yAxes[0].ticks.max = this.maxValueOfArray > 0 ? Math.ceil(this.maxValueOfArray) : 1;
    this.chart.options.scales.yAxes[0].ticks.stepSize = this.setStepSizeYaxes(5);
    this.chart.update();
  }

  private setTooltips(tooltipModel) {
    let tooltipEl = document.getElementById('chartjs-tooltip');
    const position = this.chart.canvas.getBoundingClientRect();

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.className = 'chartjs-tooltip';
      document.body.appendChild(tooltipEl);
    }
    let marginLeftValue = null;
    if (this.tooltipFormatText === DATE_FORMAT.MoreThanThirtyOneYears) {
      switch (true) {
        case window.innerWidth < MOBILE_MAX_WIDTH - 1:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > SIZE_DIFFERENCE.ThreeHundredNinety
              ? `-${SIZE_DIFFERENCE.FortyOne}`
              : `-${SIZE_DIFFERENCE.TwentySeven}`;
          break;
        case window.innerWidth > MOBILE_MAX_WIDTH && window.innerWidth <= LAPTOP_MAX_WIDTH:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > SIZE_DIFFERENCE.SevenHundredTwenty
              ? `-${SIZE_DIFFERENCE.FortyOne}`
              : `-${SIZE_DIFFERENCE.TwentySeven}`;
          break;
        case window.innerWidth > DESKTOP_HD_MIN_WIDTH && window.innerWidth <= DESKTOP_HD_MAX_WIDTH:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > SIZE_DIFFERENCE.EightHundredEighty
              ? `-${SIZE_DIFFERENCE.FortyOne}`
              : `-${SIZE_DIFFERENCE.TwentySeven}`;
          break;
        case window.innerWidth >= DESKTOP_FULL_HD_MIN_WIDTH:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > window.innerWidth - SIZE_DIFFERENCE.SixHundredFifty
              ? `-${SIZE_DIFFERENCE.FortyOne}`
              : `-${SIZE_DIFFERENCE.TwentySeven}`;
          break;
      }
    } else {
      switch (true) {
        case window.innerWidth < MOBILE_MAX_WIDTH - 1:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > SIZE_DIFFERENCE.ThreeHundredNinety
              ? `-${SIZE_DIFFERENCE.NinetyEight}`
              : `-${SIZE_DIFFERENCE.FiftyFour}`;
          break;
        case window.innerWidth > MOBILE_MAX_WIDTH && window.innerWidth <= LAPTOP_MAX_WIDTH:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > SIZE_DIFFERENCE.SevenHundredTwenty
              ? `-${SIZE_DIFFERENCE.NinetyEight}`
              : `-${SIZE_DIFFERENCE.FiftyFour}`;
          break;
        case window.innerWidth >= DESKTOP_HD_MIN_WIDTH && window.innerWidth <= DESKTOP_HD_MAX_WIDTH:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > SIZE_DIFFERENCE.EightHundredEighty
              ? `-${SIZE_DIFFERENCE.NinetyEight}`
              : `-${SIZE_DIFFERENCE.FiftyFour}`;
          break;
        case window.innerWidth >= DESKTOP_FULL_HD_MIN_WIDTH:
          marginLeftValue =
            this.chart.tooltip._eventPosition.x < SIZE_DIFFERENCE.SeventyTwo
              ? `-${SIZE_DIFFERENCE.Eleven}`
              : this.chart.tooltip._eventPosition.x > window.innerWidth - SIZE_DIFFERENCE.SixHundredFifty
              ? `-${SIZE_DIFFERENCE.NinetyEight}`
              : `-${SIZE_DIFFERENCE.FiftyFour}`;
          break;
      }
    }
    tooltipEl.innerHTML = `<div style="margin-left: ${marginLeftValue}px; margin-top: -26px; width: 200px;"></div>`;

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0 as any;
      return;
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);
      let innerHtml = '';
      const tooltipText = this.tooltipFormatText;
      bodyLines.forEach(body => {
        const value = body[0];
        this.tooltipValue.emit({ value: value, date: titleLines[0], hover: true });
      });

      titleLines.forEach(function (title) {
        innerHtml += format(new Date(title), tooltipText);
      });

      const tableRoot = tooltipEl.querySelector('div');
      if (tableRoot) {
        tableRoot.innerHTML = innerHtml;
      }
    }
    const existBranBar = document.getElementById('brand-top-bar');
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    if (window.innerWidth > MOBILE_MAX_WIDTH) {
      tooltipEl.style.top =
        existBranBar === null ? `${SIZE_DIFFERENCE.FourHundredTwenty}px` : `${SIZE_DIFFERENCE.FourHundredNinetyTwo}px`;
    } else {
      tooltipEl.style.top =
        existBranBar === null ? `${SIZE_DIFFERENCE.ThreeHundredSixty}px` : `${SIZE_DIFFERENCE.FourHundredThirty}px`;
    }
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.zIndex = '1';
    tooltipEl.style.width = '0px';
    tooltipEl.style.height = '194px';
  }
}
