import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { TxFilter } from '@legacy/accounts/typings/TxFilter';

import { Uk2ButtonSizeEnum, Uk2Tier1UtilityEnum } from '@axos/uikit-v2-lib';
import { InitializeTransactionTileInput, TransactionFacade } from '../../facade';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Transaction } from '../../core/store/types/transactions-tile-state.type';
import { OrdersFacade } from '../../../orders/facade';
import { Order } from '../../../orders/core/store/types';
import { TransactionOrder } from '../../facade/types/transaction-order.type';
import { PdpFacade } from '../../../product-details-page/facade/pdp.facade';
import { AasAccountState } from '../../../product-details-page/core';
import { Filters, RangeFilter } from '@app/axos-invest/models';
import * as moment from 'moment';

@Component({
  selector: 'app-transactions-page',
  templateUrl: './transactions-page.component.html',
  styleUrls: ['./transactions-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsPageComponent implements OnInit, OnDestroy {
  accountId: string;
  transactionID: string;
  account: AasAccountState;
  isDownloading: boolean;
  displayFilters: boolean;
  filters: TxFilter;
  transactions: TransactionOrder[] = [];
  initialTransactions: TransactionOrder[] = [];
  transactionsFiltered: TransactionOrder[] = [];
  initialOrders: TransactionOrder[] = [];
  ordersFiltered: TransactionOrder[] = [];
  transactionsAndOrdersFiltered: TransactionOrder[] = [];
  private destroy$ = new Subject<void>();

  downloadIcon: Uk2Tier1UtilityEnum = Uk2Tier1UtilityEnum.download;
  uk2DownloadButtonSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.medium;
  reverse: boolean = true;
  orderBy: number = 0;
  private lastFilterApplied: Filters = {
    datePeriod: null,
    dateRange: null,
  };

  get utilityIcons() {
    return Uk2Tier1UtilityEnum;
  }

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(TransactionFacade) private transactionFacade: TransactionFacade,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private ordersFacade: OrdersFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private pdpFacade: PdpFacade
  ) {}

  ngOnInit(): void {
    this.accountId = this.params.id;
    this.transactionID = this.params.transactionId;
    this.transactionFacade.transactionTileAccountState$
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        map(t => this.mapTransactions(t.transactions))
      )
      .subscribe(transactions => {
        if (transactions) {
          this.transactions = [...this.transactions, ...transactions];
          this.transactionsAndOrdersFiltered = [...this.transactionsAndOrdersFiltered, ...transactions];
        }
        this.initialTransactions = transactions;
      });

    this.ordersFacade.ordersTileAccountState$
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        map(o => this.mapOrders(o.orders))
      )
      .subscribe(orders => {
        if (orders) {
          this.transactions = [...this.transactions, ...orders];
          this.transactionsAndOrdersFiltered = [...this.transactionsAndOrdersFiltered, ...orders];
        }
        this.initialOrders = orders;
      });

    this.pdpFacade.selectedAasAccount$
      .pipe(
        takeUntil(this.destroy$),
        tap(account => {
          this.account = account;
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadTransactionsFile(): void {
    this.isDownloading = true;

    const transactionDownloadFilters: IDownloadTransactionsRequest = {
      AccountId: Number(this.account.accountNumber),
      StartDate: this.lastFilterApplied.dateRange?.min ? this.lastFilterApplied.dateRange?.min : null,
      EndDate: this.lastFilterApplied.dateRange?.max ? this.lastFilterApplied.dateRange?.max : moment().toDate(),
    };

    this.transactionFacade
      .downloadTransaction(transactionDownloadFilters, this.account)
      .subscribe((isDownloaded: boolean) => {
        this.isDownloading = !isDownloaded;
        this.changeDetectorRef.detectChanges();
      });
  }

  goBack(): void {
    this.state.go('udb.productDetailsPage');
  }
  mapTransactions(transactions: Transaction[]): TransactionOrder[] {
    const transactionMap: TransactionOrder[] = [];
    transactions.forEach(t => {
      transactionMap.push({
        transactionId: t.transactionId,
        security: t.securitySymbol,
        state: t.state,
        model: t.modelDescription,
        date: new Date(t.postDate),
        quantity: t.quantity,
        pricePerShare: t.price,
        cash: t.cash,
        otherFees: t.feeAmount,
        amount: t.amount,
        costBasis: t.costBasis,
        description: t.activityDescription,
        accountId: t.accountId,
        isExpanded: this.transactionID === t.transactionId,
        activityCode: t.activityCode,
      });
    });
    return transactionMap;
  }

  mapOrders(orders: Order[]): TransactionOrder[] {
    const ordersMap: TransactionOrder[] = [];
    orders.forEach(o => {
      ordersMap.push({
        transactionId: o.transactionId,
        security: o.securitySymbol,
        state: o.state,
        model: o.modelDescription,
        date: new Date(o.postDate),
        quantity: o.quantity,
        pricePerShare: o.price,
        cash: o.cash,
        otherFees: o.tradeFee,
        amount: o.amount,
        costBasis: o.costBasis,
        description: o.activityDescription,
        accountId: o.accountId,
        isExpanded: this.transactionID === o.transactionId,
        activityCode: o.activityCode,
      });
    });
    return ordersMap;
  }

  getTransactions(filters?: Filters) {
    this.transactionsAndOrdersFiltered = [...this.initialTransactions, ...this.initialOrders];
    this.filterTransactions(filters);
    this.lastFilterApplied = filters;
  }

  private async filterTransactions(filters: Filters) {
    let transactionsAndOrdersFiltered = [];

    if (filters.datePeriod && filters.datePeriod !== this.lastFilterApplied.datePeriod)
      await this.filterTransactionByDatePeriod(filters.datePeriod);

    if (filters.dateRange && filters.dateRange !== this.lastFilterApplied.dateRange)
      await this.filterTransactionByDateRange(filters.dateRange);
    this.transactionsAndOrdersFiltered.forEach(transaction => {
      let isAValidTransaction = [true, true];

      isAValidTransaction[0] = filters.transaction
        ? this.filterTransactionByType(filters.transaction, transaction.activityCode)
        : true;

      isAValidTransaction[1] = filters.amountRange
        ? this.filterTransactionByAmount(filters.amountRange, transaction.amount)
        : true;

      if (!isAValidTransaction.some(isValid => isValid === false)) {
        transactionsAndOrdersFiltered.push(transaction);
      }
    });

    this.transactions = transactionsAndOrdersFiltered;
    if (filters.search) await this.filterTransactionBySearch(this.transactions, filters.search);
    this.changeDetectorRef.detectChanges();
  }

  private filterTransactionByType(filterType: number, transactionActivityCode: string): boolean {
    const startsWithPrefix = (prefix: string) => transactionActivityCode.startsWith(prefix);

    if (filterType === 1) {
      return startsWithPrefix('1');
    } else if (filterType === 2) {
      return startsWithPrefix('2') || transactionActivityCode === '304';
    }

    return false;
  }

  private filterTransactionByAmount(filterAmount: RangeFilter<number>, transactionAmount: number): boolean {
    const isMinValid = filterAmount.min ? transactionAmount >= filterAmount.min : true;
    const isMaxValid = filterAmount.max ? transactionAmount <= filterAmount.max : true;

    return isMinValid && isMaxValid;
  }

  private async filterTransactionByDatePeriod(filterDatePeriod: number) {
    const currentDate = new Date();
    const startDate = new Date(new Date().setDate(new Date().getDate() - filterDatePeriod));
    const request: InitializeTransactionTileInput = {
      accountNumber: this.accountId,
      startDate: startDate,
      endDate: currentDate,
    };
    await Promise.all([this.getTransactionsFiltered(request), this.getOrdersFiltered(request)]).then(() => {
      this.transactionsAndOrdersFiltered = [...this.transactionsFiltered, ...this.ordersFiltered];
    });
  }

  private async filterTransactionByDateRange(filterDateRange: RangeFilter<Date>) {
    const request: InitializeTransactionTileInput = {
      accountNumber: this.accountId,
      startDate: filterDateRange.min,
      endDate: filterDateRange.max,
    };
    await Promise.all([this.getTransactionsFiltered(request), this.getOrdersFiltered(request)]).then(() => {
      this.transactionsAndOrdersFiltered = [...this.transactionsFiltered, ...this.ordersFiltered];
    });
  }

  private async filterTransactionBySearch(transactionsFiltered: any, filterSearch: string) {
    this.transactions = transactionsFiltered.filter(t =>
      t?.description.toLowerCase().includes(filterSearch.toLowerCase())
    );
  }

  private async getTransactionsFiltered(request: InitializeTransactionTileInput) {
    await this.transactionFacade
      .transactionsTile(request)
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        map(t => this.mapTransactions(t))
      )
      .toPromise()
      .then((response: Transaction[]) => (this.transactionsFiltered = response));
  }

  private async getOrdersFiltered(request: InitializeTransactionTileInput) {
    await this.ordersFacade
      .ordersTile(request)
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        map(o => this.mapOrders(o))
      )
      .toPromise()
      .then((response: Transaction[]) => (this.ordersFiltered = response));
  }
}
