import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { AASAccountDataRequest, AccountDetailsRequest, AccountDetailsResponse } from './types';

@Injectable()
export class AccountDetailsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/accountDetails');
  }

  /**
   * Returns account details for AAS account.
   *
   * @param request AccountDetailsRequest
   * @returns WebApiResponse of AccountDetailsResponse
   */
  getAccountDetails(request: AccountDetailsRequest): WebApiResponse<AccountDetailsResponse> {
    return this.get<AccountDetailsResponse>(request.accountNumber);
  }

  updateAasAccountData(aasAccountDataRequest: AASAccountDataRequest): WebApiResponse<string> {
    return this.post<string>(null, aasAccountDataRequest, null);
  }
}
