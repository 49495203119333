import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  BILLPAYHELPER,
  BILLPAYSERVICE,
  SERVICEHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { PaymentFrequency } from '@app/bill-pay/typings/PaymentFrequency';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { PaymentMethod } from '@legacy/bill-pay/typings/PaymentMethod';
import { ScheduledPayment } from '@legacy/bill-pay/typings/ScheduledPayment';
import { ScheduledPaymentByAccount } from '@legacy/bill-pay/typings/ScheduledPaymentByAccount';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-scheduled-payments',
  templateUrl: './scheduled-payments.component.html',
  styleUrls: ['./scheduled-payments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [billPayServiceProvider],
})
export class ScheduledPaymentsComponent implements OnInit {
  isLoading: boolean = false;
  isLoading$ = new Subject();
  headers: GenericOption[];
  scheduledPayments: ScheduledPayment[];
  orderBy: string;
  headerIdOrderBy: number;
  reverse: boolean;
  constructor(
    @Inject(BILLPAYSERVICE) private _billPayService: IBillPayService,
    @Inject(BILLPAYHELPER) private _billPayHelper: BillPayHelper,
    @Inject(SERVICEHELPER) private _serviceHelper: IServiceHelper
  ) {}
  ngOnInit(): void {
    this.isLoading$.next(this.isLoading);

    this.headers = [
      { value: 0, label: 'Recipient', subvalue: 'payeeName' },
      { value: 1, label: 'Amount To Pay', subvalue: 'amount' },
      { value: 2, label: 'Pay Frequency', subvalue: 'paymentFrequency' },
      { value: 3, label: 'Pay From', subvalue: 'fromAccount' },
      { value: 4, label: 'Send Date', subvalue: 'processingDate' },
      { value: 5, label: 'Est. Delivery', subvalue: 'deliveryDate' },
      { value: 6, label: 'Recipient Type', subvalue: 'paymentMethod' },
      { value: 7, label: 'Confirmation #', subvalue: 'confirmationNumber' },
      { value: 8, label: '', subvalue: '' },
    ];

    this.getScheduledPayments();
  }

  /** Get the payments from api */
  getScheduledPayments(): void {
    this.isLoading = true;
    this.isLoading$.next(this.isLoading);

    this._billPayService
      .getScheduledPayments()
      .then(res => {
        this.loadPaymentsData(res.data);
      })
      .catch(this._serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
        this.isLoading$.next(this.isLoading);
      });
  }

  /** Fill out the array with the information to be displayed */
  private loadPaymentsData(paymentsData: ScheduledPaymentByAccount[]) {
    this.scheduledPayments = [];
    paymentsData.forEach(acct => {
      acct.scheduledPayments.forEach(pmt => {
        pmt.fromAccount = acct.displayName;
        this.scheduledPayments.push(pmt);
      });
    });
  }

  public getFrequencyString(frequency: PaymentFrequency): string {
    return this._billPayHelper.mapFrequency(frequency);
  }

  public getRemainingPaymentsString(payment: ScheduledPayment): string {
    if (payment.paymentFrequency !== 0 && payment.items.length > 1) {
      const remaining = payment.items[0];
      // payment.items[1] keeps the total payments

      return '(' + remaining + ' Remaining)';
    }
    return '';
  }

  public getRecipientType(paymentMethod: PaymentMethod): string {
    return this._billPayHelper.mapPaymentMethod(paymentMethod);
  }

  public getHeaderTooltip(header: string): string {
    return this._billPayHelper.getHeaderTooltip(header);
  }

  /**
   * Sorting function in the header table
   * @param orderBy header name to used to order by
   * @param headerId actual id taken for ordering
   */
  public sort(orderBy: string, headerId: number): void {
    if (headerId === 8) return;
    this.orderBy = orderBy;
    this.headerIdOrderBy = headerId;

    if (this.reverse) {
      this.scheduledPayments = this.scheduledPayments.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    } else {
      this.scheduledPayments = this.scheduledPayments.sort((a, b) => (a[orderBy] > b[orderBy] ? -1 : 1));
    }
    this.reverse = !this.reverse;
  }
}
