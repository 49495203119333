import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { NavigationIcons, UtilityIcons } from '@shared/enums';
import {
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogConfig,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DialogService } from '@core/services';
import { OverdraftTermsConditionsComponent } from '@app/Areas/AAS/features/overdraft-credit-terms-conditions-modal/view/overdraft-credit-terms-conditions-modal';
import { InvestorCheckingService } from '@app/Areas/AAS/aas-shared/services/investor-checking';
import { OdlModalData } from './types';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { AccountReviewModalComponent } from '../account-review-modal/account-review-modal.component';
import { OdlApplicationStatus } from '@app/accounts/enums/odl-application-status.enum';
import { CONFIRMATION_TEXT, DEFAULT_ERROR_MESSAGE } from './constants';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';
import { ServiceHelper } from '@legacy/services/service.helper';
import { ROOT_SCOPE, STATE } from '@core/tokens';

@Component({
  selector: 'app-overdraft-credit-line-modal',
  templateUrl: './overdraft-credit-line-modal.component.html',
  styleUrls: ['./overdraft-credit-line-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverdraftCreditLineModalComponent {
  successModal = false;

  disabledBtn = false;
  accountNickname = '';
  iconConfig = {
    header: {
      size: '2rem',
      icon: UtilityIcons.ThumbsUp,
    },
    close: {
      size: '1rem',
      icon: NavigationIcons.Ex,
    },
  };

  constructor(
    public dialogRef: MatDialogRef<OverdraftCreditLineModalComponent>,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    // private accountsService: accountsService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private dialogData: OdlModalData,
    private investorCheckingService: InvestorCheckingService,
    private modalService: ModalService,
    private agreementsService: AgreementService,
    private serviceHelper: ServiceHelper,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (dialogData) {
      this.accountNickname = this.dialogData.accountNickname;
    }
  }

  ngOnInit() {}

  onSuccess() {
    this.successModal = true;
  }

  close() {
    this.dialogRef.close();
    if (this.successModal) {
      this.rootScope['dashboardModalsCanBeDisplayed'] = true;
      this.rootScope.$broadcast('dashboardModalsCanBeDisplayed');
      this.rootScope['reloadInternalAccounts'] = true;
      this.rootScope.$broadcast('reloadInternalAccounts');
      this.state.go('udb.dashboard');
    }
  }

  showModalOverdraft(): void {
    const config: MatLegacyDialogConfig = {
      width: '1090px',
      panelClass: 'modal-overlay-dialog',
      disableClose: true,
    };

    this.dialogService.openByComponentRef(OverdraftTermsConditionsComponent, config);
  }

  addOdlToAccount(): void {
    this.disabledBtn = true;
    this.investorCheckingService.addOdlAccountToInvestorCheckingAccount(this.dialogData.accountNumber).subscribe({
      next: response => {
        if (response.statusCode == 200 && response.data) {
          this.responseApplicationStatusValidation(response);
        } else {
          this.showErrorModal();
        }
      },
      error: () => {
        this.showErrorModal();
      },
    });
  }

  responseApplicationStatusValidation(response: any): void {
    if (response.data.applicationStatus == OdlApplicationStatus.Approved) {
      this.updateAgreement(false);
      this.successModal = true;
      this.disabledBtn = false;
      this.changeDetectorRef.markForCheck();
    } else {
      if (response.data.applicationStatus != OdlApplicationStatus.Declined) {
        this.updateAgreement(false);
        this.dialogRef.close();
        this.dialogService.openByComponentRef(AccountReviewModalComponent, {
          data: response.data.applicationId,
        });
      } else {
        this.showErrorModal();
      }
    }
  }

  showErrorModal(): void {
    const modalSettings: ModalSettings = {
      icon: 'bofi-cancel',
      bodyText: DEFAULT_ERROR_MESSAGE,
      okText: CONFIRMATION_TEXT,
      hasCancelButton: false,
    };

    this.modalService
      .show({ windowClass: 'modal-service internal-error-modal' }, modalSettings)
      .then(() => {})
      .catch(() => {});

    this.disabledBtn = false;

    this.dialogRef.close();
  }

  updateAgreement(value: boolean) {
    this.agreementsService
      .updateAgreementsByValue(value, AasAgreementType.OverdraftLineOfCreditTermsAndConditions)
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }
}
