import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AppDefaultPreferences,
  SaveUserPreferencesRequest,
  UserPreference,
  UserPreferenceState,
  UserPreferencesService,
  UserPreferencesState,
  preferencesState,
  updateApplicationPreferencesAction,
  updateUserPreferencesAction,
} from '../core';
import { SaveUserPreferencesInput } from './types';
import { forkJoin } from 'rxjs';
import { ServiceResult } from '@shared/models';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class UserPreferencesFacade {
  readonly preferencesState$ = this.store.select(preferencesState);
  constructor(private store: Store<UserPreferencesState>, private userPreferencesService: UserPreferencesService) {}
  initializeUserPreferences(stateShareRef?: (stateRef$: UserPreferencesState) => void): void {
    forkJoin({
      userPreferences: this.userPreferencesService.getUserPreferences(),
      appPreferences: this.userPreferencesService.getAppPreferences(),
    }).subscribe({
      next: responses => {
        const userPreferences = this.handleGetUserPreferencesResponse(responses.userPreferences);
        const defaultAppPreferences = this.handleGetAppPreferencesResponse(responses.appPreferences);
        if (stateShareRef) {
          stateShareRef({ userPreferences, defaultAppPreferences });
        }
      },
    });
  }
  saveUserPreferences(input: SaveUserPreferencesInput) {
    const saveUserPreferencesRequest: SaveUserPreferencesRequest = { ...input };
    return this.userPreferencesService.saveUserPreferences(saveUserPreferencesRequest).pipe(
      tap(response => {
        if (response.statusCode !== 200 || response.data == null) return;
        this.store.dispatch(
          updateUserPreferencesAction({
            payload: {
              userPreferences: response.data,
            },
          })
        );
      })
    );
  }

  private handleGetUserPreferencesResponse(response: ServiceResult<UserPreference[]>): UserPreferenceState[] {
    if (response.statusCode !== 200 || response.data == null) return [];
    this.store.dispatch(
      updateUserPreferencesAction({
        payload: {
          userPreferences: response.data,
        },
      })
    );
    return response.data;
  }

  private handleGetAppPreferencesResponse(response: ServiceResult<AppDefaultPreferences[]>): AppDefaultPreferences[] {
    if (response.statusCode !== 200 || response.data == null) return [];
    this.store.dispatch(
      updateApplicationPreferencesAction({
        payload: {
          defaultAppPreferences: response.data,
        },
      })
    );
    return response.data;
  }

  static createUserSortingPreferences<T extends { sortDirection: number; sortType: number }>(
    option: T,
    udbUserId: number,
    accountNumber: string,
    appPreferencesList: ReadonlyArray<AppDefaultPreferences>,
    userPreferences: UserPreferenceState[],
    sortTypeUserPreferenceName: string,
    sortDirectionUserPreferenceName: string
  ): UserPreferenceState[] {
    const preferences: Array<UserPreferenceState> = [];
    const directionPreference = appPreferencesList.find(
      preference => preference.name === sortDirectionUserPreferenceName
    );
    const userDirectionPreference = userPreferences.find(
      preference =>
        preference.name === sortDirectionUserPreferenceName &&
        preference.accountNumber.toString() === accountNumber.toString()
    );
    if (directionPreference) {
      if (userDirectionPreference) {
        const mappedUserPreference = {
          ...userDirectionPreference,
          defaultValue: option.sortDirection.toString(),
          value: option.sortDirection.toString(),
        };
        preferences.push(mappedUserPreference);
      } else {
        const sortDirectionPreference: UserPreferenceState = {
          udbUserId,
          accountNumber,
          appPreferenceId: directionPreference.id,
          name: directionPreference.name,
          defaultValue: option.sortDirection.toString(),
          value: option.sortDirection.toString(),
        } as UserPreferenceState;
        preferences.push(sortDirectionPreference);
      }
    }
    const typePreference = appPreferencesList.find(preference => preference.name == sortTypeUserPreferenceName);
    const userTypePreference = userPreferences.find(
      preference =>
        preference.name == sortTypeUserPreferenceName &&
        preference.accountNumber.toString() === accountNumber.toString()
    );
    if (typePreference) {
      if (userTypePreference) {
        const userTypeSortPreference = {
          ...userTypePreference,
          defaultValue: option.sortType.toString(),
          value: option.sortType.toString(),
        };
        preferences.push(userTypeSortPreference);
      } else {
        const sortTypePreference: UserPreferenceState = {
          udbUserId,
          accountNumber,
          appPreferenceId: typePreference.id,
          name: typePreference.name,
          defaultValue: option.sortType.toString(),
          value: option.sortType.toString(),
        } as UserPreferenceState;
        preferences.push(sortTypePreference);
      }
    }
    return preferences;
  }
}
