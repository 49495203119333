import { axosColorPalette, nwColorPalette, ufbColorPalette } from 'typings/app/ColorPalettes';
export class ColorPaletteHelper {
  static getColorPaletteByBrand(brand) {
    let colors;
    switch (brand.toLowerCase()) {
      case 'axos':
        colors = axosColorPalette;
        break;
      case 'nationwide':
        colors = nwColorPalette;
        break;
      case 'ufb':
        colors = ufbColorPalette;
        break;
      default:
        colors = axosColorPalette;
        break;
    }
    return colors;
  }
}
