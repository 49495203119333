import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // TsLint rule disabled because the directive is needed for upgrading components.
  // tslint:disable-next-line: directive-selector
  selector: 'global-alert',
})
export class GlobalAlertDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('globalAlert', elementRef, injector);
  }
}
