import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { ModalFiltersApplied, TxFilter } from '../../types';
import { FiltersHelperService } from '../../services';

@Component({
  selector: 'app-tx-modal-filters',
  templateUrl: './tx-modal-filters.component.html',
  styleUrls: ['./tx-modal-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxModalFiltersComponent {
  // Bound properties
  @Input() account: OlbAccount;
  @Input() filters: TxFilter;
  @Input() isAggregated: boolean;
  @Input() isInitializing: boolean;
  @Output() onApplyFilters = new EventEmitter<ModalFiltersApplied>();
  @Output() onCloseModal = new EventEmitter<void>();

  filtersApplied: string[] = [];
  originalFilters: TxFilter;

  constructor(
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private _filtersHelperService: FiltersHelperService,
    private olbEventService: OlbEventService
  ) {}

  /** Initializes the controller. */
  ngOnInit() {
    this.originalFilters = JSON.parse(JSON.stringify(this.filters));
  }

  /** Resets the filters and closes the modal */
  clearFilterFields() {
    const categories = this.filters['categories'];
    this.filters = JSON.parse(JSON.stringify(this.originalFilters));
    this.filters['categories'] = categories;
    this.filtersApplied = [];
    this.root.$emit('filtersReset', []);
    this.olbEventService.emit(OlbEvents.TxFiltersReset, []);
    this.closeModal();
    this.root.$broadcast('reset-tx-filter');

    this.onApplyFilters.emit({ filters: this.filters, filtersApplied: this.filtersApplied });
  }

  /**
   * Stores internally filters set, and applies them until clicks the button
   * @param filters Filters set object
   */
  saveFilters(modalFilters: ModalFiltersApplied) {
    let { filters, filtersApplied } = modalFilters;
    filtersApplied = this.convertToArray(filtersApplied);
    if (filtersApplied.length > 0) {
      // it sets last applied filter
      this.filtersApplied = this._filtersHelperService.setFiltersApplied(
        filtersApplied[filtersApplied.length - 1],
        this.filtersApplied
      );
      this.filtersApplied = this.filtersApplied.filter(x => filtersApplied.find(y => y == x));
    } else {
      this.filtersApplied = filtersApplied;
    }
    this.filters = filters;
    this.onApplyFilters.emit({ filters: this.filters, filtersApplied: this.filtersApplied });
  }

  onClearFilter(filterName: string) {
    this.root.$emit('onClearModalFilters', filterName);
  }

  /** Applies the filters currently set */
  closeModal() {
    this.onCloseModal.emit();
  }

  private convertToArray(filtersObj: any) {
    if (Array.isArray(filtersObj)) return filtersObj;
    if (!filtersObj || JSON.stringify(filtersObj) == '{}') return [];
    const array: any[] = [];
    for (const item in filtersObj) {
      if (!isNaN(parseInt(item))) array.push(filtersObj[item]);
    }

    return array;
  }
}
