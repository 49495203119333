import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { olbSettings, STATE, STATE_PARAMS } from '@core/tokens';
import { LoadUserProfileHelper } from '@legacy/services/load-user-profile-helper';

@Component({
  selector: 'app-loan-confirmation-page',
  templateUrl: './loan-confirmation-page.component.html',
  styleUrls: ['./loan-confirmation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanConfirmationPageComponent implements OnInit {
  confirmationEmail: string;
  paymentInformation: { name: string; value: string }[] = [];
  isOneTime: boolean;

  constructor(
    @Inject(STATE) private readonly _stateService: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private readonly _stateParams: ng.ui.IStateParamsService,
    private readonly _loadUserProfileHelper: LoadUserProfileHelper,
    @Inject(olbSettings) private readonly _env: OlbSettings
  ) {}

  ngOnInit() {
    this.paymentInformation = this._stateParams['paymentData'];
    this.isOneTime = this._stateParams['isOneTime'];
    if (this.paymentInformation == null) {
      this._stateService.go('udb.transfers.transferFunds');
    } else {
      const userProfile = this._loadUserProfileHelper.getUserProfile();
      this.confirmationEmail = userProfile.primaryEmail;
    }
  }

  isAxos(): boolean {
    return this._env.brand === 'axos';
  }

  redirectToSchedulerTransfers() {
    this._stateService.go('udb.transfers.schedulerTransfers');
  }

  redirectToAccountsDashboard() {
    this._stateService.go('udb.accounts.dashboard');
  }

  redirectToDashboards() {
    this._stateService.go('udb.dashboard');
  }
}
