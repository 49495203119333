<ng-container *ngIf="orderInfo; else loader">
  <div class="container mt-4">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12">
        <h1 class="order-title text-center" id="titlePage" *ngIf="!isPending">Order Completed</h1>
        <h1 class="order-title text-center" id="titlePage" *ngIf="isPending">Order Placed</h1>
        <div class="image-container text-center">
          <img class="account-profile__icon" [src]="icon" alt="" aria-hidden="true" />
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-10 col-sm-8">
            <div class="col-12 mt-2 confirmation-label">Stock</div>
            <div class="col-12 mt-2 confirmation-value" id="confirmationStock">{{ order.stock }}</div>
            <div *ngIf="order.stockType !== stockType.MutualFund">
              <div class="col-12 mt-2 confirmation-label">Order Type</div>
              <div class="col-12 mt-2 confirmation-value" id="confirmationOrderType">
                {{ order.orderTypeDescription }}
              </div>
              <div class="col-12 mt-2 confirmation-label" *ngIf="order.limitPrice">Limit Price</div>
              <div class="col-12 mt-2 confirmation-value" *ngIf="order.limitPrice" id="confirmationLimitPrice">
                {{ order.limitPrice | currency }}
              </div>
              <div class="col-12 mt-2 confirmation-label">Amount of Share(s)</div>
              <div class="col-12 mt-2 confirmation-value" id="confirmationQuantity">{{ order.quantity }}</div>
              <div
                class="col-12 mt-2 confirmation-label"
                id="totalCostText"
                *ngIf="order.transaction === tradeTypes.buy"
              >
                Total Cost
                <app-tooltip
                  content="The full amount you pay for your transaction, including applicable fees"
                ></app-tooltip>
              </div>
              <div
                class="col-12 mt-2 confirmation-label"
                id="estimatedGainText"
                *ngIf="order.transaction === tradeTypes.sell"
              >
                Estimated Gain
                <app-tooltip
                  content="This is the net proceeds from the expected sale price of your stock"
                ></app-tooltip>
              </div>
              <div class="col-12 mt-2 confirmation-value" id="confirmationTotalCost">
                {{ order.totalCost | currency }}
              </div>
              <div class="col-12 mt-2 confirmation-label" *ngIf="order.goodUntilDescription">Good Until</div>
              <div class="col-12 mt-2 confirmation-value" *ngIf="order.goodUntilDescription" id="confirmationGoodUntil">
                {{ order.goodUntilDescription }}
              </div>
            </div>
            <div *ngIf="order.stockType === stockType.MutualFund">
              <div class="col-12 mt-2 confirmation-label">Amount</div>
              <div *ngIf="order.transaction === tradeTypes.buy">
                <div class="col-12 mt-2 confirmation-value" id="confirmationAmount">
                  ({{ order.value | currency }}) ordered
                </div>
              </div>
              <div *ngIf="order.transaction === tradeTypes.sell">
                <div class="col-12 mt-2 confirmation-value" id="confirmationAmount">
                  ({{ order.quantity }}) at ({{ order.value | currency }}) each
                </div>
              </div>
            </div>
            <div class="col-12 mt-2 confirmation-label">Status</div>
            <div class="col-12 mt-2 confirmation-value" id="confirmationTimeFilled">
              {{ orderInfo.status.description }}
              <app-tooltip
                *ngIf="isPending"
                content="This transaction is still being processed and is not yet complete and posted"
              ></app-tooltip>
            </div>
            <div class="col-12 text-center">
              <div class="mt-4">
                <button class="btn-ok button--lg" type="button" id="backBtn" (click)="goToOverview()">
                  Back to Overview
                </button>
              </div>
              <div class="mt-4">
                <button class="btn-cancel button--lg" type="button" id="activityBtn" (click)="goToActivity()">
                  View Activity
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
