import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalErrorComponent {
  
  constructor(
    private dialogRef: MatDialogRef<ModalErrorComponent>,
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
