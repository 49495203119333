import { createChartSegments, createRoundedDoughnutChart, mergeSegments } from './functions';
import { ChartView } from './types';
export function setAxosDoughnutChart(): string {
  const nameOfChart = 'AxosDoughnutChart';
  Chart.defaults[nameOfChart] = Chart.helpers.clone(Chart.defaults.doughnut);
  Chart.controllers[nameOfChart] = Chart.controllers.doughnut.extend({
    draw: function () {
      const { width, height }: { width: number; height: number } = this.chart;
      const ctx = this.chart.ctx;
      const [dataset] = this.chart.data.datasets;
      const data = dataset.data as Array<number>;
      const backgroundColors = dataset.backgroundColor as Array<string>;
      const segments = this.getMeta().data.map(data => data._view) as Array<ChartView>;
      const innerRadius: number | undefined | null = segments[0]?.innerRadius;
      const outerRadius: number | undefined | null = segments[0]?.outerRadius;
      const side = width >= height ? height : width;
      const fallbackRadius = (side / 2) * 0.9;
      const radius = innerRadius && outerRadius ? innerRadius + (outerRadius - innerRadius) / 2 : fallbackRadius;
      const processedSegments = createChartSegments(mergeSegments(data, backgroundColors));
      createRoundedDoughnutChart(
        ctx,
        width / 2,
        height / 2,
        radius,
        8, // arbitrary
        processedSegments,
        this.chart.canvas
      );
    },
    /**
     *
     * @param _reset used to reset state and reanimate, since we are doing manual handle of the canvas we do not animate incrementa
     */
    update: function (_reset: boolean) {
      const { width, height }: { width: number; height: number } = this.chart;
      const ctx = this.chart.ctx;
      const [dataset] = this.chart.data.datasets;
      const data = dataset.data as Array<number>;
      const backgroundColors = dataset.backgroundColor as Array<string>;
      const segments = this.getMeta().data.map(data => data._view) as Array<ChartView>;
      const innerRadius: number | undefined | null = segments[0]?.innerRadius;
      const outerRadius: number | undefined | null = segments[0]?.outerRadius;
      const side = width >= height ? height : width;
      const fallbackRadius = (side / 2) * 0.9;
      const radius = innerRadius && outerRadius ? innerRadius + (outerRadius - innerRadius) / 2 : fallbackRadius;
      const processedSegments = createChartSegments(mergeSegments(data, backgroundColors));
      createRoundedDoughnutChart(
        ctx,
        width / 2,
        height / 2,
        radius,
        8, // arbitrary
        processedSegments,
        this.chart.canvas
      );
    },
  });
  return nameOfChart;
}
