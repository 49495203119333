<div class="header-section-title text-center hide-responsive">
  <h1>Add A Recipient</h1>
</div>
<div class="frow center frow-mobile">
  <div class="fcol-md-10 hide-responsive">
    <a class="back" [href]="'billpay/search-recipient'">
      <img
        [src]="'/img/icons/billPay/back_icon.svg'"
        width="15"
        alt="Favorite Recipient"
        aria-hidden="true"
        class="back-arrow"
      />

      <span>Back</span>
    </a>
  </div>
  <div class="fcol-md-12 cancel-setup-responsive">
    <span (click)="CancelRecipient()">&#10005;</span>
  </div>
  <div class="fcol-md-10 text-center mobile-check-text">
    <p>
      Enter the recipient's mailing address. The address can typically be found on an invoice or billing statement.
    </p>
    <br />
    <form #checkRecipient="ngForm" novalidate (submit)="AddRecipient(checkRecipient.valid)">
      <div class="frow center">
        <div
          class="double-input-wrapper"
          [ngClass]="{ 'has-error': !name.valid && (name.touched || checkRecipient.submitted) }"
        >
          <label class="label-input">Recipient's Name</label>
          <input
            #name="ngModel"
            name="name"
            required
            maxlength="40"
            type="text"
            [(ngModel)]="payee.payeeName"
            class="uk-input check-input-half"
          />
          <label class="text-error label-input" *ngIf="!name.valid && (name.touched || checkRecipient.submitted)">
            {{ emptyRecipientMsg }}
          </label>
        </div>
        <div class="double-input-wrapper-left">
          <label class="label-input">Nickname (Optional)</label>
          <input
            name="nickName"
            type="text"
            maxlength="40"
            [(ngModel)]="payee.nickName"
            class="uk-input check-input-half"
          />
        </div>
      </div>
      <div class="frow center">
        <div
          class="input-wrapper"
          [ngClass]="{
            'has-error': address.errors && (address.touched || checkRecipient.submitted)
          }"
        >
          <label class="label-input">Street Address</label>
          <input
            #address="ngModel"
            required
            type="text"
            name="address"
            pattern="^[a-zA-Z0-9#\/.,\s]*$"
            maxlength="40"
            [(ngModel)]="payee.addressLine"
            class="uk-input check-input-fullscreen"
          />
          <label class="text-error label-input" *ngIf="address.errors && (address.touched || checkRecipient.submitted)">
            Invalid characters for address
          </label>
          <label
            class="text-error label-input"
            *ngIf="!payee.addressLine && (address.touched || checkRecipient.submitted)"
          >
            {{ emptyStreetAddresMsg }}
          </label>
        </div>
      </div>
      <div class="frow center">
        <div
          class="input-wrapper"
          [ngClass]="{ 'has-error': address2.errors && (address2.touched || checkRecipient.submitted) }"
        >
          <label class="label-input">Street Address 2 (Optional)</label>
          <input
            #address2="ngModel"
            type="text"
            name="address2"
            maxlength="40"
            [(ngModel)]="payee.addressLine2"
            class="uk-input check-input-fullscreen"
            pattern="^[a-zA-Z0-9#\/.,\s]*$"
          />

          <label class="text-error label-input" *ngIf="address2.errors && address2.touched">
            Invalid characters for address
          </label>
        </div>
      </div>
      <div class="frow center frow-check-responsive">
        <div
          class="input-wrapper zip-mobile-wrapper"
          [ngClass]="{
            'has-error': !zip.valid && (zip.touched || checkRecipient.submitted)
          }"
        >
          <label class="label-input">Zip</label>
          <input
            #zip="ngModel"
            required
            name="zip"
            type="text"
            pattern="^[0-9]*$"
            [(ngModel)]="payee.zipCode"
            class="uk-input zip-input"
            minlength="5"
            maxlength="5"
            (change)="GetCityAndStateByZip()"
          />

          <label class="text-error label-input" *ngIf="!zip.valid && (zip.touched || checkRecipient.submitted)">
            {{ zipCodeErrormsg }}
          </label>
        </div>
      </div>

      <div class="frow center frow-check-responsive">
        <div
          class="city-input-wrapper"
          [ngClass]="{
            'has-error': !city.valid && (city.touched || checkRecipient.submitted)
          }"
        >
          <label class="label-input">City</label>
          <input
            #city="ngModel"
            required
            type="text"
            name="city"
            maxlength="40"
            [(ngModel)]="payee.city"
            class="uk-input check-input-half"
          />
          <label class="text-error label-input" *ngIf="!city.valid && (city.touched || checkRecipient.submitted)">
            {{ cityErrorMsj }}
          </label>
        </div>
        <div
          class="state-input-wrapper-left"
          [ngClass]="{
            'has-error': !state.valid && (state.touched || checkRecipient.submitted)
          }"
        >
          <label class="label-input">State</label>
          <select
            #state="ngModel"
            required
            [(ngModel)]="payee.stateCode"
            name="state"
            class="uk-input check-input-half"
          >
            <option value="{{ state.key }}" *ngFor="let state of states | keyvalue">
              {{ state.value + ' (' + state.key + ')' }}
            </option>
          </select>
          <label class="text-error label-input" *ngIf="!state.valid && (state.touched || checkRecipient.submitted)">
            {{ stateErrorMsj }}
          </label>
        </div>
      </div>
      <div class="frow center">
        <div class="input-wrapper">
          <label class="label-input">Account/Reference Number/Memo (Optional)</label>
          <input
            type="text"
            name="account"
            maxlength="40"
            [(ngModel)]="payee.paymentAccount"
            class="uk-input check-input-fullscreen"
          />
        </div>
      </div>
      <div class="frow center hide-responsive">
        <app-dot-loader *ngIf="isBusy" class="frow wrap"></app-dot-loader>
        <div class="input-wrapper">
          <button
            *ngIf="!isBusy"
            type="button"
            class="uk-btn lg outline primary button-check-recipient"
            (click)="CancelRecipient()"
          >
            <i class="finline uk-btn__icon"></i>
            <span>Cancel Setup</span>
          </button>
          <button *ngIf="!isBusy" type="submit" class="uk-btn lg secondary solid button-check-recipient">
            <span>Add Recipient</span>
          </button>
        </div>
      </div>

      <div class="frow center show-responsive">
        <app-dot-loader *ngIf="isBusy" class="frow wrap"></app-dot-loader>
        <div class="input-wrapper">
          <button *ngIf="!isBusy" type="submit" class="uk-btn lg secondary solid button-check-recipient">
            <span>Add Recipient</span>
          </button>
          <button *ngIf="!isBusy" type="button" class="uk-btn lg outline primary button-check-recipient">
            <a class="back" [href]="'billpay/search-recipient'">
              <img
                [src]="'/img/icons/billPay/back_icon.svg'"
                width="15"
                alt="Favorite Recipient"
                aria-hidden="true"
                class="back-arrow"
              />
              <span>Back</span>
            </a>
            <!-- ui-sref="udb.billPay.searchRecipient({criteria: $ctrl.criteria})" -->
            <i class="finline uk-btn__icon"></i>
            <span>Back</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
