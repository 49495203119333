<div class="d-none d-sm-block dialog-header" [style.backgroundImage]="backgroundUrl">
  <app-svg-icon [iconName]="icon" [styles]="svgStyle"></app-svg-icon>
</div>

<h3 mat-dialog-title>Terms & Conditions</h3>

<div mat-dialog-content class="text-justify mat-dialog-content" [innerHtml]="htmlBody"></div>

<div class="d-flex flex-column">
  <div class="text-center">
    <button class="btn-ok button--sm" [mat-dialog-close]="true" cdkFocusInitial>Agree</button>
  </div>
  <div class="text-center p-3">
    <button class="btn-cancel button--sm" [mat-dialog-close]="false">Cancel</button>
  </div>
</div>
