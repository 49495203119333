import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import * as jsCookie from 'js-cookie';

import { AuthStatus } from '@app/authentication/enums';
import { AuthRequest, AuthResponse } from '@app/authentication/models';
import { Username, ValidUsername } from '@app/enrollment/models';
import { EnrollmentViewStateService } from '@app/enrollment/services';
import { EnrollmentStorage } from '@app/enrollment/utils/enrollment-storage.util';
import { facingBrandMapping } from '@core/enums/facing-brand.enum';
import { OlbSettings } from '@core/models';
import { AuthService, DialogService, EnrollmentService, MultifactorService } from '@core/services';
import { olbSettings } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { DecodedJwt, DialogData, ServiceResult } from '@shared/models';

@Component({
  selector: 'app-proxy-registration',
  templateUrl: './proxy-registration.component.html',
  styleUrls: ['./proxy-registration.component.scss'],
})
export class ProxyRegistrationComponent implements OnInit, OnDestroy {
  @Output() routeRedirect = new EventEmitter<string>();

  authResponse: AuthResponse = null;
  areQuestionsAnswered = false;
  isLoading = false;
  isInitiating = true;
  user = new ValidUsername();
  usernameHeading = {
    title: '',
    subtitle: 'Please confirm the username and password you would like to use',
    hideLogin: false,
  };
  brandName: string;
  private subSink = new SubSink();
  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    public state: EnrollmentViewStateService,
    private enrollmentService: EnrollmentService,
    private authService: AuthService,
    private multifactorService: MultifactorService,
    private dialogService: DialogService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.initHeading();
    this.setLoginFinishedListener();
    this.handleLiteRegistration();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  goToLogin() {
    this.routeRedirect.emit('auth.login');
  }

  usernameNext(username: Username) {
    Object.assign(this.user, username);

    this.state.currentStep = 2;
  }

  agreeTermsAndConditions() {
    this.isLoading = true;

    const { username, password } = this.user;
    const payload = {
      token: this.state.regId,
      id: 0,
      username,
      password,
    };

    this.enrollmentService
      .completeRegistrationProxy(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: this.handleCompleteRegistration.bind(this),
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  securityQuestionsSave() {
    this.setSessionStorageVariables();

    this.areQuestionsAnswered = true;

    this.state.loginFinished.next(true);
  }

  private setLoginFinishedListener() {
    this.subSink.sink = this.state.loginFinished.subscribe(isLoginFinished => {
      if (isLoginFinished && this.areQuestionsAnswered) {
        this.routeRedirect.emit('udb.dashboard');
      }
    });
  }

  private initHeading() {
    this.usernameHeading.title = `Welcome to the New ${this.settings.brandName}!`;
    this.brandName = this.settings.brand;
    Object.defineProperty(this.usernameHeading, 'name', {
      get: () => this.user.firstName,
    });
  }

  private handleLiteRegistration() {
    const concurrentSub = {
      next: this.handleLiteRegistrationNotComingFromEnrollment.bind(this),
      error: this.showErrorAndGoToLogin.bind(this),
    };

    if (this.state.comesFromFrg) {
      this.enrollmentService
        .getApproveRegistrationInformation(this.state.regId)
        .pipe(
          finalize(() => {
            this.isInitiating = false;
          })
        )
        .subscribe(concurrentSub);
    } else {
      this.enrollmentService
        .getPreregisteredUserInformation(this.state.regId)
        .pipe(
          finalize(() => {
            this.isInitiating = false;
          })
        )
        .subscribe(concurrentSub);
    }
  }

  private showErrorAndGoToLogin(res: ServiceResult) {
    const data = new DialogData({
      title: 'Error',
      content: res.data.message,
    });

    this.dialogService
      .open(data)
      .afterClosed()
      .subscribe({ next: () => this.routeRedirect.emit('auth.login') });
  }

  private handleLiteRegistrationNotComingFromEnrollment(res: ServiceResult<AuthResponse | ValidUsername>) {
    return res.data.hasOwnProperty('token')
      ? this.setAuthInfo(res.data as AuthResponse)
      : this.setEnrollment(res.data as ValidUsername);
  }

  private setAuthInfo(data: AuthResponse) {
    const xsrfToken = jsCookie.get('XSRF-TOKEN');
    const decodedJwt = new DecodedJwt(xsrfToken);

    this.user.username = decodedJwt.get('sub');
    this.authResponse = data;
    this.areQuestionsAnswered = this.authResponse.hasSecurityQuestions;

    if (this.authResponse.isOlbRegistered) {
      if (this.areQuestionsAnswered) {
        this.setSessionStorageVariables();
        this.state.loginFinished.next(true);
      } else {
        this.state.currentStep = 2.5;
      }
    } else {
      this.state.currentStep = 2;
    }

    this.isInitiating = false;
  }

  private setEnrollment(data: ValidUsername) {
    this.user = data;

    if (this.state.comesFromFrg) {
      this.usernameHeading.title = `Let's complete your registration`;
      this.usernameHeading.subtitle = 'Please confirm the username and password you would like to use';
      this.usernameHeading.hideLogin = true;
    }

    this.isInitiating = false;
  }

  private handleCompleteRegistration() {
    if (this.authResponse) {
      this.setSessionStorageVariables();

      this.state.loginFinished.next(true);
    } else {
      this.loginUser();
    }

    this.state.currentStep = 2.5;
  }

  private loginUser() {
    this.isLoading = true;

    const { username, password } = this.user;
    const payload: AuthRequest = {
      userName: username,
      password,
      brandCode: this.settings.brandCode,
    };

    this.authService
      .login(payload, this.state.regId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: this.handleLogin.bind(this),
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  private handleLogin(res: ServiceResult<AuthResponse>) {
    if (res.data.authStatus === AuthStatus.Allow) {
      this.authResponse = res.data;
      this.multifactorService.setUserInformation(this.user.username, res.data.telephoneNumber, res.data.email);
      this.multifactorService.updateDeviceInformation(res.data);

      const userFacingBrand = facingBrandMapping.find(({ brands }) => brands.includes(this.authResponse.brandId));

      EnrollmentStorage.username = this.user.username;
      EnrollmentStorage.token = res.data.token;
      EnrollmentStorage.expires = new Date(Date.now() + res.data.tokenExpires * 1e3);
      EnrollmentStorage.fundRemembered = false;
      EnrollmentStorage.userbrand = res.data.brandId;
      EnrollmentStorage.firstName = res.data.fullName;
      EnrollmentStorage.lastLogin = res.data.lastLogin;
      EnrollmentStorage.userFacingBrand = userFacingBrand.facingBrand;

      if (!!res.data.isSBBAvailable) {
        sessionStorage.setItem('isSBBAvailable', res.data.isSBBAvailable.toString());
      }

      this.state.loginFinished.next(true);
    }
  }

  private setSessionStorageVariables() {
    if (this.authResponse) {
      const userFacingBrand = facingBrandMapping.find(({ brands }) => brands.includes(this.authResponse.brandId));

      EnrollmentStorage.expires = new Date(Date.now() + this.authResponse.tokenExpires * 1e3);
      EnrollmentStorage.userbrand = this.authResponse.brandId;
      EnrollmentStorage.userSubType = this.authResponse.userSubType ?? 0;
      EnrollmentStorage.userFacingBrand = userFacingBrand.facingBrand;
    }
  }
}
