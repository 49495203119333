import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { supportPayItNowComponents } from './components';

@NgModule({
  declarations: [...supportPayItNowComponents],
  exports: [...supportPayItNowComponents],
  imports: [CommonModule],
})
export class SupportPayItNowModule {}
