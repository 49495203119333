<div class="d-flex justify-content-end mb-3 px-3">
  <mat-chip-list id="transaction-tag-list">
    <mat-chip *ngFor="let tag of tagsCollection" id="tag-{{ tag.subvalue }}">
      {{ tag.label }}
      <button
        id="close-{{ tag.subvalue }}"
        type="button"
        (click)="removeTag(tag.subvalue)"
        class="transaction-tags__close-button"
      >
        <app-svg-icon [iconName]="icon.name" [styles]="icon.styles"></app-svg-icon>
      </button>
    </mat-chip>
  </mat-chip-list>
</div>
