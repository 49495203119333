import { SpousalBeneficiary } from './spousal-beneficiary.model';

export class SpousalConsent {
  facingBrandId: number;
  cif: string;
  taxPlanType: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  spousalBeneficiaries: SpousalBeneficiary[];

  constructor(args?: Partial<SpousalConsent>) {
    Object.assign(this, args);
  }
}
