import { CategoryEvent } from './category-event.model';

export class SubcategoryEvent extends CategoryEvent {
  subcategoryId: number;
  description: string;
  url?: string;

  constructor(args: SubcategoryEvent) {
    super(args);
  }
}
