import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionsOrdersScheduledTabMatModule } from './transactions-orders-scheduled-tab-mat.module';
import { TransactionsOrdersScheduledTabUk2Module } from './transactions-orders-scheduled-tab-uk2.module';
import { TransactionsOrdersScheduledTabComponent } from './view/transactions-orders-scheduled-tab.component';
import { TransactionsModule } from '../transactions/transactions.module';
import { ScheduledTransfersModule } from '../scheduled-transfers/scheduled-transfers.module';
import { OrdersModule } from '../orders/orders.module';
@NgModule({
  declarations: [TransactionsOrdersScheduledTabComponent],
  imports: [
    CommonModule,
    TransactionsOrdersScheduledTabMatModule,
    TransactionsOrdersScheduledTabUk2Module,
    TransactionsModule,
    ScheduledTransfersModule,
    OrdersModule,
  ],
  exports: [TransactionsOrdersScheduledTabComponent],
})
export class TransactionsOrdersScheduledTabModule {}
