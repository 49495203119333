import { NgModule } from '@angular/core';

import {
  materialDateFnsAdapterProvider,
  materialDateFnsFormatProvider,
  materialDateFnsLocaleProvider,
} from './material-date-fns.provider';

@NgModule({
  providers: [
    materialDateFnsAdapterProvider,
    materialDateFnsFormatProvider,
    materialDateFnsLocaleProvider,
  ],
})
export class MaterialDateFnsModule {}
