import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SwitchStatus } from '@app/user-profile/enums';
import { SwitchDetail } from '@app/user-profile/models';

@Component({
  selector: 'app-click-switch-detail',
  templateUrl: './click-switch-detail.component.html',
  styleUrls: ['./click-switch-detail.component.scss'],
})
export class ClickSwitchDetailComponent implements OnInit {
  @Input() switch: SwitchDetail;
  @Output() goToConfirmation = new EventEmitter<SwitchDetail>();
  switchStatus: string;
  showViewBtn: boolean;
  statusMessage: string;

  constructor() {}

  ngOnInit(): void {
    this.checkIfViewBtnShouldShow();
    this.setStatusMessage();
    this.setSwitchStatus();
  }

  private checkIfViewBtnShouldShow() {
    const status = [SwitchStatus.Submitted, SwitchStatus.Printed].map(item => item.toString());
    this.showViewBtn = status.includes(this.switch.status);
  }

  private setStatusMessage() {
    const statusMessages = {
      [SwitchStatus.UnableToProcess]: this.switch.statusMessage,
      [SwitchStatus.Sent]: `Your request has been sent to ${this.switch.name} for processing.`,
      [SwitchStatus.Printed]: `To complete this Direct Deposit Request, the Print Switch PDF must be submitted to your employer.`,
    };
    this.statusMessage = statusMessages[this.switch.status];
  }

  private setSwitchStatus() {
    this.switchStatus =
      this.switch.status === SwitchStatus.UnableToProcess ? 'Rejected' : this.switch.status;
  }
}
