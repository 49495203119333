import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BrandingSettingsFacade, BrandingStateType } from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { AxosAdvisoryAccount } from '@core/models';
import { DefaultAdvisorLogo } from '@legacy/dashboard/account-aggregation/typings';
import { ModalService } from '@legacy/services/modal.service';
import { ProviderService } from '@legacy/services/provider.service';

@Component({
  selector: 'app-authorize-transfers-transfers-only',
  templateUrl: './authorize-transfers-transfers-only.component.html',
  styleUrls: ['./authorize-transfers-transfers-only.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizeTransfersTransfersOnlyComponent implements OnInit {
  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  axosAdvisoryAccounts: AxosAdvisoryAccount[] = [];
  providers: Provider[];
  constructor(
    private providerService: ProviderService,
    private readonly brandingSettingsFacade: BrandingSettingsFacade,
    private readonly riaFacade: RiaFacade,
    private readonly modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getProviders();
    this.brandingSettingsFacade.allBrandingSettings$.subscribe(brandings => {
      this.allBrandingSettings = brandings;
    });

    this.riaFacade.allRias$.subscribe(rias => {
      this.allRiaAccounts = rias;
    });
  }

  getProviders() {
    this.providerService.getProviders().then((res: OlbResponse<Provider[]>) => {
      this.providers = res.data;
    });
  }

  getAasImage(aasAccount: AxosAdvisoryAccount) {
    const regexValue = /url\('([^']+)'\)/;
    const secondaryLogoText = 'secondaryLogo';
    const brand = this.allBrandingSettings?.find(brand => brand.name === aasAccount.brandingName);
    const ria = this.allRiaAccounts?.find(ria => ria.riaId === aasAccount.riaId);
    const secondaryLogo = brand?.settings.find(settings => settings.name === secondaryLogoText);

    if (secondaryLogo && ria.useCustomSecondaryLogo) {
      return secondaryLogo.value.match(regexValue)[1];
    }

    return DefaultAdvisorLogo;
  }

  openModalFromComponent(event: any, aasAccount: AxosAdvisoryAccount) {
    let checkbox = event.target;
    if (!checkbox.checked) {
      this.modalService
        .show(
          {},
          {
            hasIcon: true,
            icon: 'bofi-warning',
            hasHeaderText: false,
            okText: 'Yes, Disable Transfers',
            cancelText: 'No, Nevermind',
            hasClose: false,
            hasCancelButton: true,
            bodyText: `<h3>Disable Transfers?</h3>
            <div class='funding__body'>Authorizing transfers grants
            your advisor the ability to move funds between this account and your advisor-managed account.
             Are you sure you want to disable transfers with ${aasAccount.displayName}?
            </div>`,
            okButtonClass: 'uk-btn solid secondary lg sharing-account-button',
            cancelButtonClass: 'uk-btn outline primary lg sharing-account-button',
          }
        )
        .then(() => {
          aasAccount.isAuthorizeTransfers = false;
          this.cd.markForCheck();
        })
        .catch(() => {
          aasAccount.isAuthorizeTransfers = true;
          this.cd.markForCheck();
        });
    }
  }
}
