import CategoryFilterController from './category-filter.controller';

export const CategoriesFilterComponent: ng.IComponentOptions = {
  bindings: {
    onValueChange: '&?',
  },
  templateUrl: 'accounts/transactions/categorization/category-filter/category-filter.tpl.html',
  controllerAs: '$ctrl',
  controller: CategoryFilterController,
};
