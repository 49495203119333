export class GlanceCobrowseService {
  src = '';

  // The domain to use for the Cobrowse session cookie.
  dataCookiedomain = '';
  // Maps input events to Glance Cobrowse’s actions. The data-inputevents format is:
  //      { “[modifier] - [keycode]” : “[Glance action]”, … }
  dataInputevents = '';
  // Your assigned Group ID number provided by Glance.
  dataGroupid = '20280';
  dataPresence = 'api';
  // This allows Glance to push newer versions of the script tag to your staging site for
  // testing before deploying to production.
  // Two site options: Staging & Production.
  dataSite = 'staging';

  constructor() {}

  generateScript(): void {
    if (this.src === '') {
      console.log('Cobrowse url source is not set.');

      return;
    }

    const cobrowseScript = document.createElement('script');
    cobrowseScript.id = 'cobrowsescript';
    cobrowseScript.async = true;
    cobrowseScript.src = this.src;
    cobrowseScript.dataset.inputevents = this.dataInputevents;
    cobrowseScript.dataset.groupid = this.dataGroupid;
    cobrowseScript.dataset.cookiedomain = this.dataCookiedomain;
    cobrowseScript.dataset.presence = this.dataPresence;
    cobrowseScript.dataset.site = this.dataSite;

    document.body.appendChild(cobrowseScript);
  }

  setVisitorId(visitorid: string): void {
    // When the identity of a visitor is known—whether they are logged in or because of a
    // unique cookie—and the agent has access to this id, it is more efficient to use this
    // unique id as a session code rather than a random number.
    //          Format: { visitorid: "[visitorid]"}
    window['GLANCE_COBROWSE'] = { visitorid };
  }

  startSessionWithRandomKey(): void {
    this.startSession('GLANCE_KEYTYPE_RANDOM');
  }

  startSession(key: string): void {
    if (!window['GLANCE']) {
      console.log('Cobrowse configuration is not set.');

      return;
    }
    window['GLANCE'].Cobrowse.Visitor.startSession({ sessionKey: key });
  }
}
