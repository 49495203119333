<div class="detail-row" [ngClass]="{'details-row-update' : isEditing}">
<p class="detail-row__label">{{title}}</p>
<p class="detail-row__content overflow-auto">
  <i
    class="bofi-edit pull-right edit-pen"
    (click)="editNickname()"
    *ngIf="!isEditing"
  ></i>
  <span class="detail-row-content" *ngIf="!isEditing">{{nickname}}</span>
</p>
</div>
<div class="update-row" *ngIf="isEditing">
  <p class="update-paragraph">
    <input
      class="uk-input sm nickname-input"
      id="nickname"
      #updatenickname
      name="nickname"
      type="text"
      maxlength="30"
      [(ngModel)]="nickname"
      (blur)="cancelNicknameUpdate()"
      aria-label="Account Nickname"
    />
  </p>
  <p class="save-paragraph">
    <span class="save-nickname" *ngIf="isEditing">
      <button
        class="uk-btn sm solid secondary"
        (mousedown)="mouseDown = true"
      >
        {{ isSaving ? "Saving..." : "Save"}}
      </button>
    </span>
  </p>
</div>