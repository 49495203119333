<td colspan="5">
  <div class="row pt-4">
    <div class="col-12 col-sm-3 col-md-3 col-lg-2 text-left text-sm-right">
      <div class="col-12 trade-header">
        Category
      </div>
      <div class="col-12 mt-2">
        Trade
      </div>
    </div>
    <div class="col-12 col-sm-8 col-lg-7 mt-3 mt-sm-0">
      <div class="row">
        <div class="col-12">
          <div class="col-12 trade-header">Description</div>
        </div>
      </div>
      <div class="row mt-2">
        <div
          class="col-12 col-sm-6 mb-4 trade-transaction"
          *ngFor="let tradeTransaction of transaction.data.tradeTransactions"
        >
          <div class="col-12 mb-2">
            <strong>Buy/Sell:</strong>
            {{ tradeTransaction.typeDescription | titlecase }}
          </div>
          <div class="col-12 mb-2">
            <strong>Asset:</strong>
            {{ tradeTransaction.name }} ({{ tradeTransaction.assetCode }})
          </div>
          <div class="col-12 mb-2">
            <strong>Asset Type:</strong>
            {{ tradeTransaction.assetType }}
          </div>
          <div class="col-12 mb-2">
            <strong>Asset Price:</strong>
            {{ tradeTransaction.price | currency }}
          </div>
          <div class="col-12 mb-2">
            <strong>{{ tradeTransaction.typeDescription | titlecase }} Amount:</strong>
            {{ tradeTransaction.value | currency }}
          </div>
          <div class="col-12 mb-2">
            <strong>Filled Quantity:</strong>
            {{ tradeTransaction.quantity }} shares at {{ tradeTransaction.price | currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</td>
