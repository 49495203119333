<div class="ssn-entry__input">
  <input
    id="ssnId"
    aria-label="Social Security Number"
    class="uk-input"
    type="text"
    placeholder="### ## ####"
    [(ngModel)]="ssn"
    [patterns]="pattern"
    [hiddenInput]="hidden"
    (blur)="checkSsnValidity()"
    mask="000 00 0000"
    required
  />
  <app-reveal-input-label
    target="ssnId"
    [switchEventOnly]="true"
    (switch)="hidden = !hidden"
    tooltip="If you are a Non-Resident Alien without a valid social security number, please enter zeros."
  >
    Social Security Number
  </app-reveal-input-label>
</div>
<div *ngIf="error">
  <span class="text-error">{{ error }}</span>
</div>
