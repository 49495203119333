import DateRangeFilterController from './date-range-filter.controller';

export const DateRangeFilterComponent: ng.IComponentOptions = {
  bindings: {
    description: '@',
    elementType: '@?',
    filterName: '@',
    buttonClasses: '@?',
    activeBgColor: '@?',
    elementActive: '=?',
    dateRangeModel: '<',
    isDisabled: '<',
    onFilterChange: '&',
    onClear: '&',
  },
  controllerAs: '$ctrl',
  controller: DateRangeFilterController,
  templateUrl: 'accounts/transactions/date-range-filter/date-range-filter.tpl.html',
};
