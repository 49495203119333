<uk2-brand-top-bar
  *ngIf="showBrandTopBar"
  [uk2LogoImgPath]="(topBarState$ | async)?.logoImgPath || ''"
  [uk2OrganizationName]="(topBarState$ | async)?.organizationName || ''"
  [uk2IsAxos]="(topBarState$ | async)?.isAxos"
  [uk2LogoRedirectionUrl]="{
    url:(topBarState$ | async)?.urlRedirectionConfig ?? '',
    openOnNewTab: true,
  }"
  id="brand-top-bar"
></uk2-brand-top-bar>
<mat-toolbar
  id="mat-toolbar-main-page"
  uk2ContextualTopBar
  [uk2IsLoading]="isContextualTopBarLoading"
  [isSticky]="isContextualTopBarSticky"
  [hasTopBar]="showBrandTopBar"
>
  <mat-toolbar-row
    class="uk2-contextual-top-bar-button-section"
  ></mat-toolbar-row>

  <mat-toolbar-row class="uk2-contextual-top-bar-header-section">
    <div class="uk2-contextual-top-bar-header-section-title-container">
      <h2 id="contextual-top-bar-title">
        {{ (contextualTopBarState$ | async)?.pageTitle || '' }}
      </h2>
    </div>
    <div class="uk2-contextual-top-bar-header-section-breadcrumb-container">
      <uk2-breadcrumbs
        #uk2Breadcrumbs
        [items]="(contextualTopBarState$ | async)?.breadcrumbItems || []"
        [uk2IsLoading]="isContextualTopBarLoading"
        (itemSelected)="onItemSelected($event)"
      ></uk2-breadcrumbs>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="udb-login-main-content">
  <ng-content></ng-content>
</div>
