import { TilesService } from 'services/tiles.service';

import { Inject } from '../../decorators/decorators';
import { ITransactionService } from '../../services/typings/ITransactionService';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';

@Inject(
  '$scope',
  '$rootScope',
  '$element',
  '$state',
  'tilesService',
  'transactionService',
  'serviceHelper',
  'popups'
)
export class RecentTransactionsController extends BaseTile<RecentTransactionsTileSettings> {
  title = 'Recent Transactions';
  quickActionText = 'All Transactions';
  isBusy = false;
  accounts: OlbAccount[] = [];
  transactions: Transaction[] = [];
  settings: number[] = [];
  originalSettings: number[] = [];
  hasNoTransactions: boolean;
  hasErrored = false;

  private listeners: Function[] = [];

  constructor(
    scope: ng.IScope,
    private rootScope: ng.IRootScopeService,
    elm: ng.IRootElementService,
    private state: ng.ui.IStateService,
    tilesService: TilesService,
    private transactionService: ITransactionService,
    serviceHelper: IServiceHelper,
    private popups: IPopups
  ) {
    super(scope, elm, tilesService, serviceHelper);
  }

  /** Initializes the controller. */
  $onInit(): void {
    // Refresh the tile after a transfer
    this.listeners.push(
      this.rootScope.$on('transferCompleted', () => {
        this.getRecentTransactions();
      })
    );
    this.listeners.push(
      this.rootScope.$on('updateTransactions', () => {
        this.getRecentTransactions();
      })
    );

    if (this.rootScope['accounts']) {
      this.getSettings(this.setupTile.bind(this));

      return;
    }

    this.listeners.push(
      this.scope.$on('accountsLiteLoaded', () => {
        this.getSettings(this.setupTile.bind(this));
      })
    );
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /** Redirects the user to the accounts page. */
  redirectToAccounts(): void {
    this.state.go('udb.accounts.dashboard');
  }

  /**
   * Toggle account's selection in settings section.
   * @param id ID of the account to toggle.
   */
  toggleSelection(id: number): void {
    if (this.settings.some(s => s === +id)) {
      this.settings = this.settings.filter(s => s !== +id);
    } else {
      this.settings.push(id);
    }
  }

  /** Saves the tile settings. */
  saveTileSettings(): void {
    this.tileSettings.Accounts.value = this.settings.join(',');
    if (this.settings.length == 0) {
      this.popups.showAlert(
        'Recent Transactions Tile',
        'At least 1 account should be selected.',
        'error',
        this.resetSettings.bind(this)
      );

      return;
    }
    this.saveSettings();
    this.getRecentTransactions();
  }

  /** Resets the tile settings to its normal/previous state. */
  resetSettings(): void {
    if (this.tileSettings.Accounts.value) {
      this.settings = this.resetAccountsSettings(this.tileSettings.Accounts.value, this.accounts);

      if (!this.settings.length) {
        this.settings = this.accounts.map(a => +a.id);
        this.saveTileSettings();
      }
    } else {
      if (this.originalSettings.length) {
        this.settings = this.originalSettings;
      } else {
        this.settings = this.accounts.map(a => +a.id);
      }
    }
  }

  /** Sets the tile up with its settings. */
  private setupTile(): void {
    const { depositAccounts, loanAccounts } = this.rootScope['accounts'];
    this.accounts = [...depositAccounts, ...loanAccounts];
    this.resetSettings();
    this.getRecentTransactions();
  }

  /** Gets the recent transactions for the tile, based on its settings.
   * (It will ask for the first 8 transactions, but at the end only shows 4;
   * this is to try to cover cases when some of the transactions are not eligible to display in OLB)
   */
  private getRecentTransactions(): void {
    this.isBusy = true;
    this.originalSettings = this.settings;
    this.transactionService
      .getRecentTransactions(this.settings, 30, 4)
      .then(res => {
        this.transactions = res.data.slice(0, 4);
        this.hasNoTransactions = !this.transactions.length;
      })
      .catch(() => {
        this.hasErrored = true;
      })
      .finally(() => {
        this.isInitialising = false;
        this.isBusy = false;
      });
  }
}

interface RecentTransactionsTileSettings extends BaseTileSettings {
  Accounts?: TileSetting;
}
