import { AnyAction } from 'redux';
import { FundingState } from 'typings/app/FundingState';
import { FUNDING } from '../../constants/funding';

const initialState: FundingState = {
  isRunning: false,
  depositAmount: 0,
  accountId: 0,
  accountsToBeFunded: [],
  fromAccountId: 0,
};

/**
 * Functions encharged of handling the transformations in the store
 * NOTE: This function never mutates the state directly, redux does
 */
export function FundingReducer(state: FundingState = initialState, action: AnyAction) {
  switch (action.type) {
    case FUNDING.TURN_ON:
      return Object.assign({}, state, {
        isRunning: true,
      });
    case FUNDING.TURN_OFF:
      return Object.assign({}, state, {
        isRunning: false,
      });
    case FUNDING.CHANGE_METHOD:
      return Object.assign({}, state, {
        method: action.payload,
      });
    case FUNDING.CHANGE_AMOUNT:
      return Object.assign({}, state, {
        depositAmount: action.payload,
      });
    case FUNDING.SETUP_ACCOUNT_ID:
      return Object.assign({}, state, {
        accountId: action.payload,
      });
    case FUNDING.SETUP_ACCOUNTS:
      return Object.assign({}, state, {
        accountsToBeFunded: action.payload,
      });
    case FUNDING.SETUP_FUNDING_ACCOUNTS:
      return Object.assign({}, state, {
        fundingAccounts: action.payload,
      });
    case FUNDING.SETUP_FROM_ACCOUNT_ID:
      return Object.assign({}, state, {
        fromAccountId: action.payload,
      });
    case FUNDING.ON_FUNDED_ACCOUNT:
      const justFundedAccountIndex = state.accountsToBeFunded.findIndex(
        i => i.id == state.accountId
      );
      const accountsToBeFunded = Array.from(state.accountsToBeFunded);
      accountsToBeFunded.splice(justFundedAccountIndex, 1);

      return Object.assign({}, state, {
        accountsToBeFunded,
        method: null,
        accountId: 0,
        depositAmount: 0,
      });
    default:
      return state;
  }
}
