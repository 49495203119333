<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Transfers How To's</h3>

  <ul class="how-to-list">
    <li>
      <button id="request-transfer" class="link" (click)="selectTopic(topics.RequestTransfer)">
        How do I transfer money between my accounts?
      </button>
    </li>
    <li *ngIf="showSBBExternalAccounts">
      <button id="request-external-transfer" class="link" (click)="selectTopic(topics.RequestTransferToExternal)">
        How do I request a transfer to an account outside of {{ settings.brandName }} (an external transfer)?
      </button>
    </li>
    <li>
      <button
        id="app-transfer-external-to-business"
        class="link"
        (click)="selectTopic(topics.TransferExternalToBusiness)"
      >
        For business transfers, why can’t I move money from an external account to my Axos business account?
      </button>
    </li>
    <li>
      <button id="app-request-wire" class="link" (click)="selectTopic(topics.RequestWire)">
        How can I send a wire?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-request-transfer *ngSwitchCase="topics.RequestTransfer"></how-to-request-transfer>
    <how-to-request-external-transfer
      *ngSwitchCase="topics.RequestTransferToExternal"
    ></how-to-request-external-transfer>
    <app-transfer-external-to-business
      *ngSwitchCase="topics.TransferExternalToBusiness"
    ></app-transfer-external-to-business>
    <app-request-wire
      *ngSwitchCase="topics.RequestWire"
    ></app-request-wire>
  </ng-container>
</ng-template>
