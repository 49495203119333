import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { ROOT_SCOPE } from '@core/tokens';
import { InvestAccount } from '@app/axos-invest/models';
import { loadInvestAccounts } from '@app/accounts/store/invest-accounts/invest-accounts.actions';
import { axosInvestServiceConstants } from './constants/constants-axos-invest-service';

import { MilestoneDetailVm } from '@legacy/typings/app/wise-banyan/Milestone';
import { WiseBanyanService } from '@legacy/services/wise-banyan.service';

@Injectable({
  providedIn: 'root',
})
export class AxosInvestHelperService {
  static MANAGED_PORTFOLIO = axosInvestServiceConstants.MANAGED_PORTFOLIO;
  static AXOS_INVEST = axosInvestServiceConstants.AXOS_INVEST;
  hasAxosInvest: boolean;
  investAccount: InvestAccount;

  constructor(
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    private store: Store,
    private wiseBanyanService: WiseBanyanService
  ) {
    this.hasAxosInvest = sessionStorage.getItem(axosInvestServiceConstants.HAS_AXOS_INVEST) === 'true';
    this.setInvestAccountObjectDefault();
  }

  async getMilestoneData(): Promise<MilestoneDetailVm> {
    if (this.rootScope[axosInvestServiceConstants.MILESTONE_DETAIL]) {
      const data: MilestoneDetailVm = this.rootScope[axosInvestServiceConstants.MILESTONE_DETAIL];

      this.addInvestAccount(
        data.milestones.filter(x => x.isClosed == false).length,
        data.totalBalance,
        data.hasError,
        data.isClosed
      );

      return of(data).toPromise();
    } else {
      const result = await this.wiseBanyanService.getMilestones();

      this.addInvestAccount(
        result.data.milestones.filter(x => x.isClosed == false).length,
        result.data.totalBalance,
        result.data.hasError,
        result.data.isClosed
      );

      this.rootScope[axosInvestServiceConstants.MILESTONE_DETAIL] = result.data;

      return of(result.data).toPromise();
    }
  }

  getInvestAccount(): any {
    return this.rootScope[axosInvestServiceConstants.AXOS_INVEST_ACCOUNTS];
  }

  addInvestAccount(numberOfMilestones: number, totalBalance: number, hasError = false, isClosed: boolean): void {
    if (!this.rootScope[axosInvestServiceConstants.AXOS_INVEST_ACCOUNTS]) {
      if (!isClosed) {
        this.setInvestAccountActive(numberOfMilestones, totalBalance, hasError);
      } else {
        this.setInvestAccountClosed();
      }

      this.rootScope[axosInvestServiceConstants.AXOS_INVEST_ACCOUNTS] = this.investAccount;
      this.store.dispatch(loadInvestAccounts({ payload: { ...this.investAccount } }));
    }
  }

  addInvestClosedAccounts(): any[] {
    this.setInvestAccountClosed();

    const investClosedAccountArray: any[] = [];
    investClosedAccountArray.push(this.investAccount);

    return investClosedAccountArray;
  }

  isAccountClosed(status: string): boolean {
    return status == axosInvestServiceConstants.ACCOUNT_CLOSED;
  }

  isInvestAccountEmpty(): boolean {
    if (this.investAccount) {
      return (
        this.investAccount.accountType === axosInvestServiceConstants.ACCOUNT_TYPE &&
        this.investAccount.availableBalance === 0 &&
        this.investAccount.bankName === '' &&
        this.investAccount.hasError === false &&
        this.investAccount.isAxosInvest === true &&
        this.investAccount.name === axosInvestServiceConstants.MANAGED_PORTFOLIO &&
        this.investAccount.nickname === axosInvestServiceConstants.MANAGED_PORTFOLIO &&
        this.investAccount.status === ''
      );
    }

    return true;
  }

  private setInvestAccountObjectDefault(): void {
    this.investAccount = {
      name: axosInvestServiceConstants.MANAGED_PORTFOLIO,
      isAxosInvest: true,
      bankName: '',
      availableBalance: 0,
      nickname: axosInvestServiceConstants.MANAGED_PORTFOLIO,
      accountType: axosInvestServiceConstants.ACCOUNT_TYPE,
      hasError: false,
      status: '',
      isClosed: false,
      isLoaded: false,
    };
  }

  private setInvestAccountActive(numberOfGoals: number, totalBalance: number, hasError = false): void {
    if (numberOfGoals == 0) {
      this.investAccount.bankName = `-- ${axosInvestServiceConstants.GOALS}`;
    } else if (numberOfGoals > 1) {
      this.investAccount.bankName = `${numberOfGoals} ${axosInvestServiceConstants.GOALS}`;
    } else {
      this.investAccount.bankName = `1 ${axosInvestServiceConstants.GOAL}`;
    }

    this.investAccount.availableBalance = totalBalance;
    this.investAccount.hasError = hasError;
    this.investAccount.status = axosInvestServiceConstants.ACCOUNT_ACTIVE;
  }

  private setInvestAccountClosed(): void {
    this.investAccount.bankName = `0 ${axosInvestServiceConstants.GOALS}`;
    this.investAccount.availableBalance = 0;
    this.investAccount.hasError = false;
    this.investAccount.status = axosInvestServiceConstants.ACCOUNT_CLOSED;
  }
}
