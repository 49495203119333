import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AxosAdvisoryTransferRequest } from '@app/transfers/models/axos-advisory-transfer-request.model';
import { AxosAdvisoryTransferResponse } from '@app/transfers/models/axos-advisory-transfer-response.model';
import { TotalContributions } from '@app/transfers/models/total-contributions.model';
import { AxosAdvisoryAccountDetail, OlbSettings } from '@core/models';
import { AccountInvestmentDetails } from '@core/models/advisory-account-invest-details.model';
import { AxosAdvisoryAccount } from '@core/models/axos-advisory-accounts.models';
import { AxosAdvisoryAccountsResponse } from '@core/models/axox-advisory-accounts-response.model';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

@Injectable()
export class AxosAdvisoryService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas');
  }

  getAxosAdvisoryAccounts() {
    return this.get<AxosAdvisoryAccount[]>('accounts');
  }

  getAxosAdvisoryAccountsWithRias() {
    return this.get<AxosAdvisoryAccountsResponse>('accounts/allowed');
  }

  getAccountInvestmentDetails(
    account: string
  ): WebApiResponse<AccountInvestmentDetails> {
    return this.get<AccountInvestmentDetails>(
      `accounts/${account}/InvestmentDetails`
    );
  }

  createAxosAdvisoryTransfer(request: AxosAdvisoryTransferRequest) {
    return this.post<AxosAdvisoryTransferResponse>(`transactions`, request);
  }

  getAxosAdvisoryAccountDetails(accountNumber: string) {
    return this.get<AxosAdvisoryAccountDetail>(
      `accounts/${accountNumber}/details`
    );
  }

  getHasAxosCheckingAccount() {
    return this.get<boolean>(`accounts/hasAxosCheckingAccount`);
  }

  getAxosAdvisoryContributions() {
    return this.get<TotalContributions[]>('accounts/contributions');
  }
}
