import * as angular from 'angular';
import { Inject } from '../decorators/decorators';
import { ILocationService } from 'angular';

@Inject('$uibModal', '$location')
export class ModalService {
  modalDefaults: ng.ui.bootstrap.IModalSettings;
  modalSettings: ModalSettings;

  constructor(
    private _uibModal: ng.ui.bootstrap.IModalService,
    private location: ILocationService
  ) {
    this.modalDefaults = {
      backdrop: 'static',
      keyboard: true,
      animation: true,
      windowClass: 'modal-service',
      templateUrl: 'shared/modal-service/modal.service.tpl.html',
    };

    this.modalSettings = {
      okText: 'Ok',
      hasCancelButton: true,
      cancelText: 'Cancel',
      hasIcon: true,
      hasClose: false,
      icon: 'bofi-information',
      hasHeaderText: false,
      headerText: '',
      bodyText: '<b>Are you sure you want to execute the action</b>',
      cancelButtonClass: 'uk-btn outline primary sm',
      okButtonClass: 'uk-btn solid secondary sm',
      hasRedirectLink: false,
      linkUrl: '',
      linkText: '',
    };
  }

  show(
    customModalDefaults: ng.ui.bootstrap.IModalSettings,
    customModalOpts: ModalSettings
  ): ng.IPromise<any> {
    if (!customModalDefaults) customModalDefaults = {};
    const tempModalDefaults: ng.ui.bootstrap.IModalSettings = {};
    const tempModalOptions: ModalSettings = {};

    angular.extend(tempModalDefaults, this.modalDefaults, customModalDefaults);
    angular.extend(tempModalOptions, this.modalSettings, customModalOpts);

    if (!tempModalDefaults.controller) {
      tempModalDefaults.controller = [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $modalInstance: ng.ui.bootstrap.IModalServiceInstance) => {
          $scope['modalOptions'] = tempModalOptions;
          $scope['modalOptions'].ok = () => {
            $modalInstance.close('ok');
          };
          $scope['modalOptions'].close = () => {
            $modalInstance.dismiss('cancel');
          };
          $scope['modalOptions'].redirectTo = (url: string) => {
            this.location.path(url);
            $modalInstance.close('ok');
          };
        },
      ];
    }

    return this._uibModal.open(tempModalDefaults).result;
  }
}

declare interface ModalSettings {
  /** Defines the text shown in the OK/Confirm button */
  okText?: string;
  /** Defines if a cancel buttons will be shown (e.g in confirmation modals) */
  hasCancelButton?: boolean;
  /**Defines the text shown in the cancel button */
  cancelText?: string;
  /** Defines if in the header an icon will be displayed */
  hasIcon?: boolean;
  /** Name of the icon class (e.g. 'bofi-information')  */
  icon?: string;
  /** Defines if a text will be shown as the header */
  hasHeaderText?: boolean;
  /** String containing the header content */
  headerText?: string;
  /** String | HTML to show as part of the modal's content  */
  bodyText?: string | HTMLElement;
  /** Defines if shows Close button */
  hasClose?: boolean;
  /** Defines Cancel button class */
  cancelButtonClass?: string;
  /** Defines Cancel ok class */
  okButtonClass?: string;
  /** Defines extra class buttons container */
  containerButtonsClass?: string;
  /** Defines if a link will be displayed to redirect to url */
  hasRedirectLink?: boolean;
  /** URL to redirect from Link */
  linkUrl?: string;
  /** Text to display with Link */
  linkText?: string;
}

export { ModalSettings };
