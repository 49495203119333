<div class="d-flex align-items-center justify-content-center mt-5 mb-3" *ngIf="exchangeAgreement; else loader">
  <div class="agreement-box">
    <div class="row">
      <div class="col-2 text-center mt-1">
        <app-svg-icon class="agreement-icon" [iconName]="svgIcon" size="32px"></app-svg-icon>
      </div>
      <div class="col-10">
        <div>
          <button
            id="openIframe"
            type="button"
            class="link d-flex align-items-center orbis-link"
            (click)="goToExchangeAgreementsIframe(exchangeAgreement[0].typeDescription, exchangeAgreement[0].url)"
          >
            Click here to review and accept your exchange agreements.
          </button>
        </div>
        <div class="agreement-text">
          To trade, you’ll be asked to enter your information and accept any new or updated exchange agreements.
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
