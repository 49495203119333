import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseISO } from 'date-fns';

import { ContributionYearsData } from '@app/transfers/models';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable()
export class TransferService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'transfers');
  }

  getContributionYears(): Observable<ContributionYearsData[]> {
    return this.get<ContributionYearsData[]>('contributionyears').pipe(
      map(result => result.data.map(this.fixContributionYear))
    );
  }

  private fixContributionYear(contributionYear: ContributionYearsData) {
    contributionYear.startDate = parseISO(contributionYear.startDate.toString());
    contributionYear.endDate = parseISO(contributionYear.endDate.toString());

    return contributionYear;
  }
}
