import { ErrorHandler, Injectable } from '@angular/core';

import { AngularError } from '@core/models';
import { ConfigService, LogService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class ExceptionHandlerService implements ErrorHandler {
  constructor(private configService: ConfigService, private logService: LogService) {}

  handleError(error: AngularError): void {
    if (this.configService.appConfig.webErrorsEnabled) {
      this.logService.logWebError(error.rejection ?? error);
    }

    // ! DO NOT REMOVE
    // ! console.error is used to see the errors in the local environment.
    // ! If we don't use this console.error all local and dev-time errors will be swallowed.
    console.error(error);
  }
}
