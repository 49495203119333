<div class="container">
  <app-click-switch-header step="2" subtitle="Step 2 of 3" (back)="goBack()"></app-click-switch-header>
  <div class="row center">
    <p class="account-title m-4">Select the account(s) to receive this deposit</p>
  </div>
  <app-click-switch-account-dropdown
    class="center-align"
    *ngFor="let acc of accounts; let i = index"
    [account]="acc"
    [index]="i"
    [showDelete]="accounts.length > 1"
    [allowSplit]="allowSplitAccount"
    (delete)="deleteAccount($event)"
    (validTotal)="validTotalPercentage($event)"
    (updateChoice)="updateAccountChoiced()"
  ></app-click-switch-account-dropdown>

  <div *ngIf="showAddAccount" class="add-button mt-4 col-6">
    <a class="add-button__link" (click)="addAccount()">
      <app-svg-icon [iconName]="addIcon" [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"></app-svg-icon>
      <span class="add-button__label">Add Another Account (Optional)</span>
    </a>
  </div>

  <div class="address__form-btns row justify-content-center">
    <button
      type="submit"
      class="uk-btn lg solid secondary m-4"
      (click)="goToReviewPage()"
      [disabled]="disabledContinue()"
    >
      Continue
    </button>
    <button id="cancelBtn" type="button" class="link col-12" (click)="goToStartPage()">
      Cancel
    </button>
  </div>
</div>
