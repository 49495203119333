import { Holding, THoldingSortingFamilies } from '../../store';

/**
 * Collection of sorting functions for different families.
 */
export const holdingSortingFunctionsFamilies: THoldingSortingFamilies = {
  /**
   * Sorting family for string values.
   *
   * @param accessKey - The key to access the string property.
   * @returns Object containing sorting functions for string family.
   */
  stringFamily: accessKey => ({
    Descending: (x: Holding, y: Holding) =>
      y[accessKey] ? (y[accessKey] as string).localeCompare(x[accessKey] as string) : 0,
    Ascending: (x: Holding, y: Holding) =>
      x[accessKey] ? (x[accessKey] as string).localeCompare(y[accessKey] as string) : 0,
  }),

  /**
   * Sorting family for numeric values.
   *
   * @param accessKey - The key to access the numeric property.
   * @param extraKey - The key to access an additional property (defaults to 'description').
   * @returns Object containing sorting functions for numeric family.
   */
  numericFamily: (accessKey = 'portfolioPercent', extraKey = 'symbol') => ({
    Ascending: (x: Holding, y: Holding) =>
      numberToNumberComparison(
        x[accessKey] as number,
        y[accessKey] as number,
        x[extraKey] as string,
        y[extraKey] as string
      ),
    Descending: (x: Holding, y: Holding) =>
      numberToNumberComparison(
        y[accessKey] as number,
        x[accessKey] as number,
        y[extraKey] as string,
        x[extraKey] as string
      ),
  }),

  /**
   * Default sorting family.
   *
   * @param _accessKey - The key (ignored in default case).
   * @returns Object containing default sorting functions.
   */
  default: _accessKey => ({
    Ascending: (_x: Holding, _y: Holding) => 0,
    Descending: (_x: Holding, _y: Holding) => 0,
  }),
} as const;

/**
 * Helper function to compare numbers for sorting.
 *
 * @param a {@link number} - First number.
 * @param b {@link number} - Second number.
 * @param x {@link string} - First string for tie-breaking.
 * @param y {@link string} - Second string for tie-breaking.
 * @returns Numeric comparison result.
 */
const numberToNumberComparison = (a: number, b: number, x: string, y: string): number => {
  const positionalDifference = a - b;
  if (positionalDifference === 0) {
    if (x) {
      return x.localeCompare(y, [], {
        numeric: true,
      });
    }
    return positionalDifference;
  }
  return positionalDifference;
};
