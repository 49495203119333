import { Inject } from 'decorators/decorators';
import * as jsCookie from 'js-cookie';

@Inject('env', 'Upload')
export class UploadService {
  protected reqConfig: ng.angularFileUpload.IFileUploadConfigFile;

  /**
   * Use the base service class to create your services.
   * @param env Variable set on startup that has the API endpoint.
   * @param uploadService The angularFileUpload service;
   */
  constructor(
    protected env: OlbSettings,
    protected uploadService: ng.angularFileUpload.IUploadService
  ) {
    this.reqConfig = {
      url: `${env.api}/`,
      headers: {
        Authorization: '',
        'X-FacingBrandId': env.facingBrandId,
      },
      method: 'POST',
      data: null,
    };
  }

  /**
   * Uploads one or more files to the specified endpoint
   * @param endpoint Final endpoint after api url (env/api)
   * @param data Any required data to be sent/uploaded
   */
  upload(endpoint: string, data: any): ng.angularFileUpload.IUploadPromise<any> {
    const token = jsCookie.get('XSRF-TOKEN');
    const req = { ...this.reqConfig };
    req.headers.Authorization = `Bearer ${token}`;
    req.data = data;
    req.url += endpoint;

    return this.uploadService.upload(req);
  }
}
