<div class="edit-beneficiaries">
  <div class="header-section-title text-center">
    <h1>Beneficiary Info</h1>
  </div>
  <div class="frow text-center">
    <div class="fcol-cs-12 hide-xs">
      <h2>Review and update your beneficiary information.</h2>
    </div>
  </div>
  <form
    name="beneficiaryForm"
    [formGroup]="formBeneficiaries"
    novalidate
    ngNativeValidate
    (ngSubmit)="editBeneficiary(formBeneficiaries)"
  >
    <div class="frow center">
      <div class="beneficiary-content fcol-lg-6 fcol-md-8 fcol-cs-12">
        <div class="pull-right">
          <button class="remove-beneficiary btn__link" [disabled]="isBusy" type="button" (click)="removeBeneficiary()">
            <app-svg-icon size="1em" [iconName]="icons.trash"></app-svg-icon>
            Delete Beneficiary
          </button>
        </div>
        <div class="beneficiary-content_info">
          <app-beneficiary-form
            [parentForm]="formBeneficiaries"
            [beneficiary]="beneficiary"
            [position]="0"
            [isOtherRelationship]="isOtherRelationship"
          ></app-beneficiary-form>
        </div>
        <div class="beneficiary-content_acknowledgment">
          <div class="frow">
            <div class="group--form fcol-cs-12">
              <span>Beneficiary Acknowledgment:</span>
              <span>
                Accounts held in the names of two or more persons (Account Owners) will become payable to the designated
                Payable on Death beneficiary or beneficiaries only upon the death of all account owners. Where there is
                more than one beneficiary, the funds in your account will be paid equally to each of the beneficiaries.
                <br />
                <br />
                The share of any beneficiary who predeceases the account owner(s) will be paid to the remaining
                beneficiary or beneficiaries, if there are any. You may change your designated beneficiary or
                beneficiaries at any time.
              </span>
            </div>
          </div>
        </div>
        <div class="frow text-center">
          <div class="fcol-cs-12" [hidden]="isBusy">
            <button id="accept-save" [disabled]="isBusy" class="uk-btn solid secondary lg" type="submit">
              Accept and Save
            </button>
            <br />
            <button type="button" class="back-btn btn__link" (click)="redirectToPage(accountId)">
              <span>Cancel</span>
            </button>
          </div>
          <div class="fcol-cs-12">
            <app-dot-loader [showWhen]="isBusy"></app-dot-loader>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
