import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
 } from '@angular/core';

import { FilesIcons } from '@shared/enums';

@Component({
  selector: 'up-profile-item',
  templateUrl: './profile-item.component.html',
  styleUrls: ['./profile-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileItemComponent {
  @Output() edit = new EventEmitter<null>();

  @Input()
  alertMessage: string;
  @Input()
  itemName: string;

  @HostBinding('class.item--editable')
  @Input()
  editable = true;

  editIcon = FilesIcons.Pencil;
}
