import { CurrencyPipe } from '@angular/common';

export function getCustomPositioner(): string {
  const nameOfPositioner = 'Custom';
  Chart.Tooltip.positioners[nameOfPositioner] = (_elements: any, eventPosition: any) => {
    return {
      x: eventPosition.x,
      y: eventPosition.y - 20,
    };
  };

  return nameOfPositioner;
}

export function getExtendedAxosLineChart(): string {
  const nameOfChart = 'AxosLine';
  Chart.defaults[nameOfChart] = Chart.defaults.line;
  Chart.controllers[nameOfChart] = Chart.controllers.line.extend({
    draw(ease: any) {
      Chart.controllers.line.prototype.draw.call(this, ease);
      const ctx = this.chart.ctx;

      if (this.chart.options.axos.valuesAreBelowZero) {
        const leftX = this.chart.scales['x-axis-0'].left;
        const rightX = this.chart.scales['x-axis-0'].right;
        const zeroValuePixel = this.chart.scales['y-axis-0'].getPixelForValue(0);

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(leftX, zeroValuePixel);
        ctx.lineTo(rightX, zeroValuePixel);
        ctx.lineWidth = 2;
        ctx.strokeStyle = this.chart.options.axos.zeroLineStyle;
        ctx.stroke();
        ctx.restore();
      }

      if (this.chart.options.axos.xScaleValue) {
        const leftX = this.chart.scales['x-axis-0'].left;
        const bottomY = this.chart.scales['y-axis-0'].bottom;

        ctx.save();
        ctx.font = this.chart.options.axos.xScaleFontStyle;
        ctx.fillStyle = this.chart.options.scales.xAxes[0].ticks.fontColor;
        ctx.textBaseline = 'top';
        const labelPositionX = leftX - 35;
        const labelPositionY = bottomY + 13;
        ctx.fillText(this.chart.options.axos.xScaleValue, labelPositionX, labelPositionY);
        ctx.restore();
      }

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const activePoint = this.chart.tooltip._active[0];
        const x = activePoint.tooltipPosition().x;
        const y = activePoint.tooltipPosition().y;
        const bottomY = this.chart.scales['y-axis-0'].bottom;

        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([10, 5]);
        ctx.moveTo(x, y);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = this.chart.options.axos.interactionLineStyle;
        ctx.stroke();
        ctx.restore();
      }

      ctx.restore();
    },
  });

  return nameOfChart;
}

export function getChartDataObject(
  labels: number[],
  isPfm2Active: boolean,
  facingBrandId: number,
  dataActualMonth: number[],
  dataPrevMonth: number[]
): object {
  const tension = 0.03;

  return {
    labels, // Array of strings representing the labels of X axis
    datasets: [
      {
        backgroundColor: 'white', // Dot color
        borderColor: isPfm2Active ? '#2374D7' : facingBrandId !== 3 ? '#53A8E2' : '#E18357', // Line color
        data: dataActualMonth, // Array of values
        fill: false,
        pointBackgroundColor: isPfm2Active ? '#2374D7' : undefined,
        pointHoverBackgroundColor: isPfm2Active ? 'white' : undefined,
        pointHoverBorderColor: isPfm2Active ? '#2374D7' : undefined,
        pointHoverBorderWidth: isPfm2Active ? 8 : undefined,
        pointRadius: 6, // Size of the dot
        pointHoverRadius: 12, // Size of the dot on hover
        lineTension: tension, // Bezier curve factor
        spanGaps: true, // When true does not draw a point when a null is present, when a NA is present it cuts the line
      },
      {
        backgroundColor: 'white', // Dot color
        borderColor: isPfm2Active ? '#5E6A74' : facingBrandId !== 3 ? '#FDAA4B' : '#60707A', // Border color of the line
        data: dataPrevMonth, // Array of data
        fill: false, // Fill the area below the curve
        borderDash: [8, 5], // Pointed line
        pointBackgroundColor: isPfm2Active ? '#5E6A74' : undefined,
        pointHoverBackgroundColor: isPfm2Active ? 'white' : undefined,
        pointHoverBorderColor: isPfm2Active ? '#5E6A74' : undefined,
        pointHoverBorderWidth: isPfm2Active ? 8 : undefined,
        pointRadius: 5.5, // Size of point
        pointHoverRadius: 12, // Size of point on hover
        lineTension: tension, // Curve factor of the lines
        spanGaps: true, // When true does not draw a point when a null is present, when a NA is present it cuts the line
      },
    ],
  };
}

export function getChartTooltipsObject(
  isPfm2Active: boolean,
  size55vh: number,
  previousMonthName: string,
  currentMonthName: string,
  cp: CurrencyPipe
): object {
  const tooltipStyles = getChartTooltipStylesObject(isPfm2Active, size55vh);

  return {
    // Tooltip styles
    ...tooltipStyles,

    // Positioner is in ChartHelper -> SetCustomPositioner()
    position: getCustomPositioner(),
    filter: (tooltip: TooltipItem, data: any) => {
      // If both previous and current month have the same value for the same day
      //    then only show 1 value in the tooltip (since it is the same we don't need to show it twice)
      return data.datasets[0]?.data[tooltip.index] === data.datasets[1]?.data[tooltip.index]
        ? tooltip.datasetIndex === 0
        : true;
    },
    callbacks: {
      title: (tooltipItems: TooltipItem[], data: any) => {
        if (!isPfm2Active) return '';

        // Title is composed of the month + day
        // If both previous and current month have the same value
        //    then show both months names separated by a line break
        const tooltip = tooltipItems[0];

        return data.datasets[0]?.data[tooltip.index] === data.datasets[1]?.data[tooltip.index]
          ? `${previousMonthName} ${tooltip?.xLabel}\n${currentMonthName} ${tooltip?.xLabel}`
          : `${
              data.datasets[0]?.data[tooltip.index]?.toString() === tooltip.value ? currentMonthName : previousMonthName
            } ${tooltip?.xLabel}`;
      },
      // Callback that returns a string formatted
      label: (tooltipItem: any) => {
        return cp.transform(tooltipItem.yLabel);
      },
    },
    mode: 'nearest',
    caretSize: 0,
  };
}

function getChartTooltipStylesObject(isPfm2Active: boolean, size55vh: number) {
  if (isPfm2Active) {
    return new TooltipStylePfm(size55vh);
  }

  return new TooltipStyle(size55vh);
}

class TooltipStylePfm {
  backgroundColor = 'white';
  borderColor = 'gray';
  borderWidth = 1;
  titleFontColor = '#333D46';
  titleFontStyle = 'normal';
  titleFontFamily = 'Roboto';
  bodyFontColor = '#333D46';
  bodyFontStyle = 'normal';
  bodyFontFamily = 'Roboto';
  displayColors = false;
  bodyFontSize: number;

  constructor(size55vh: number) {
    this.bodyFontSize = 6 + size55vh * 0.025;
  }
}

class TooltipStyle {
  backgroundColor = 'rgb(225,225,225)';
  titleFontColor = 'black';
  titleFontSize = 0;
  bodyFontColor = 'rgb(61,83,124)';
  bodyFontStyle = 'bold';
  bodyFontFamily = 'Roboto';
  displayColors = false;
  bodyFontSize: number;

  constructor(size55vh: number) {
    this.bodyFontSize = 6 + size55vh * 0.025;
  }
}
