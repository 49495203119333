import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import * as jsCookie from 'js-cookie';

import { createReactiveValidatorFromSchema } from '@app/utils';
import { EnrollmentService } from '@legacy/services/enrollment.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { SecurityQuestion } from '@shared/models';

import { securityQuestionsFormSchema } from '../security-questions/security-questions.schema';

@Component({
  selector: 'app-security-questions-proxy',
  templateUrl: './security-questions-proxy.component.html',
  styleUrls: ['./security-questions-proxy.component.scss'],
})
export class SecurityQuestionsProxyComponent implements OnInit {
  @Input() username: string;
  @Input() isFinished = false;
  @Input() resetPasswordToken = '';

  @Input() brandName: string;
  @Output() save = new EventEmitter<void>();

  questions: SecurityQuestion[] = [];
  firstQuestions: SecurityQuestion[];
  secondQuestions: SecurityQuestion[];
  thirdQuestions: SecurityQuestion[];
  isLoading = true;
  areQuestionsSent = false;
  securityQuestionsForm: UntypedFormGroup;

  get isBusy(): boolean {
    return (this.isLoading && this.securityQuestionsForm.valid) || this.isNotFinishedAndSent;
  }

  get isNotFinishedAndSent(): boolean {
    return this.areQuestionsSent && !this.isFinished;
  }

  get isUnavailable(): boolean {
    return this.isLoading || this.securityQuestionsForm.invalid || this.isNotFinishedAndSent;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private enrollmentService: EnrollmentService,
    private serviceHelper: ServiceHelper
  ) {}

  /** Initializes the component with default selected questions. */
  ngOnInit(): void {
    this.initializeFormBuilder();
    this.getSecurityQuestionsFromService();
  }

  securityQuestionsValidator(formControlName: string) {
    const { touched, errors } = this.securityQuestionsForm.get(formControlName);

    return touched && errors ? this.securityQuestionsForm.errors[formControlName] : null;
  }

  trackBySecurityQuestionId(selector: string, _: number, question: SecurityQuestion): string {
    return `${selector}-${question.questionId}`;
  }

  /**
   * Filters the questions for the 3 dropbox's to prevent selecting the same question.
   * @param combo The comboboxes that changed.
   */
  filterQuestions(combo: number): void {
    const securityQuestion1Id = this.securityQuestionsForm.controls.question1Id.value;
    const securityQuestion2Id = this.securityQuestionsForm.controls.question2Id.value;
    const securityQuestion3Id = this.securityQuestionsForm.controls.question3Id.value;

    // If combo value is 1 this will not be executed
    if ([2, 3, 0].includes(combo)) {
      this.firstQuestions = this.filterQuestionsByIDs([securityQuestion2Id, securityQuestion3Id]);
    }

    // If combo value is 2 this will not be executed
    if ([1, 3, 0].includes(combo)) {
      this.secondQuestions = this.filterQuestionsByIDs([securityQuestion1Id, securityQuestion3Id]);
    }

    // If combo value is 3 this will not be executed
    if ([1, 2, 0].includes(combo)) {
      this.thirdQuestions = this.filterQuestionsByIDs([securityQuestion1Id, securityQuestion2Id]);
    }
  }

  /**
   * Creates a clean SecurityQuestion array and filters it to exclude the given ids
   * @param idsToExclude The string array containing the ids to exclude.
   */
  filterQuestionsByIDs(idsToExclude: string[]): SecurityQuestion[] {
    let clonedQuestions = this.questions.map(q => ({ ...q }));
    clonedQuestions = clonedQuestions.filter(question => !idsToExclude.includes(question.questionId));

    return clonedQuestions;
  }

  /**
   * Call the api method for check response of security questions later we can save.
   */
  saveSecurityQuestions(): void {
    this.isLoading = true;
    this.areQuestionsSent = true;

    const token = jsCookie.get('XSRF-TOKEN');
    const questionsAndAnswers = this.setQuestionsAndAnswers();

    if (this.resetPasswordToken) {
      this.enrollmentService
        .saveSecurityQuestionsForgotPassword(questionsAndAnswers, this.username, this.resetPasswordToken, token)
        .then(() => {
          this.save.emit();
        })
        .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.enrollmentService
      .saveSecurityQuestions(questionsAndAnswers, this.username, token)
      .then(() => {
        this.save.emit();
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
        this.isLoading = false;
      });
    }
  }

  private initializeFormBuilder() {
    this.securityQuestionsForm = this.formBuilder.group(
      {
        question1Id: '',
        answer1: '',
        question2Id: '',
        answer2: '',
        question3Id: '',
        answer3: '',
      },
      {
        validators: createReactiveValidatorFromSchema(securityQuestionsFormSchema),
      }
    );
  }

  private getSecurityQuestionsFromService() {
    this.enrollmentService
      .getSecurityQuestions()
      .then(res => {
        this.populateQuestions(res.data);
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Populates the questions arrays for the dropdown.
   * @param questionArray A list of questions.
   */
  private populateQuestions(questionArray: string[]): void {
    questionArray.forEach((questionText, i) => {
      this.questions.push({ questionId: (i + 1).toString(), questionText, answer: '' });
    });
    /*this.securityQuestionsForm.controls.question1Id.setValue(this.questions[0].questionId);
    this.securityQuestionsForm.controls.question2Id.setValue(this.questions[1].questionId);
    this.securityQuestionsForm.controls.question3Id.setValue(this.questions[2].questionId);*/
    this.filterQuestions(0);
  }

  /**
   * Sets questions along with their answers.
   * @returns An array of questions and answers.
   */
  private setQuestionsAndAnswers(): SecurityQuestion[] {
    const securityQuestionControls = this.securityQuestionsForm.controls;
    const userQuestions: SecurityQuestion[] = [];
    userQuestions.push({
      questionId: '',
      questionText: this.questions[parseInt(securityQuestionControls.question1Id.value, 10) - 1].questionText,
      answer: securityQuestionControls.answer1.value,
    });
    userQuestions.push({
      questionId: '',
      questionText: this.questions[parseInt(securityQuestionControls.question2Id.value, 10) - 1].questionText,
      answer: securityQuestionControls.answer2.value,
    });
    userQuestions.push({
      questionId: '',
      questionText: this.questions[parseInt(securityQuestionControls.question3Id.value, 10) - 1].questionText,
      answer: securityQuestionControls.answer3.value,
    });

    return userQuestions;
  }
}
