<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Deposits How To's</h3>

  <ul class="how-to-list">
    <li>
      <button id="deposit-check" class="link" (click)="selectTopic(topics.DepositCheck)">
        How do I deposit a check from my laptop or desktop computer?
      </button>
    </li>
    <li>
      <button id="mobile-deposit" class="link" (click)="selectTopic(topics.MobileDeposit)">
        How do I use mobile deposit?
      </button>
    </li>
    <li>
      <button id="check-deposit" class="link" (click)="selectTopic(topics.CheckDeposit)">
        How many checks can I deposit?
      </button>
    </li>
    <li>
      <button id="deposit-accepted" class="link" (click)="selectTopic(topics.DepositAccepted)">
        How do I know if the deposit has been accepted?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-deposit-check *ngSwitchCase="topics.DepositCheck"></how-to-deposit-check>
    <how-to-mobile-deposit *ngSwitchCase="topics.MobileDeposit"></how-to-mobile-deposit>
    <how-to-check-deposit *ngSwitchCase="topics.CheckDeposit"></how-to-check-deposit>
    <how-to-deposit-accepted *ngSwitchCase="topics.DepositAccepted"></how-to-deposit-accepted>
  </ng-container>
</ng-template>
