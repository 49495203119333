import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Uk2ButtonSizeEnum,
  Uk2TooltipPlacementEnum,
  Uk2TooltipSizeEnum,
  Uk2TooltipStrategyEnum,
  Uk2TooltipTriggerEnum,
} from '@axos/uikit-v2-lib';
import { olbSettings } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ProviderService } from '@legacy/services/provider.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AnimationOptions } from 'ngx-lottie';
import {
  BrandingSettingsFacade,
  BrandingStateType,
} from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { DefaultAdvisorLogo } from '@legacy/dashboard/account-aggregation/typings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Uk2ToastComponent, Uk2ToastTypeEnum } from '@axos/uikit-v2-lib';
import { Subject } from 'rxjs';
import { SharedAccountsFacade } from '@app/Areas/AAS/features/account-details/facade/shared-accounts.facade';
import {
  Advisor,
  SharedAccount,
  SharedAccountsRequest,
} from '@app/Areas/AAS/features/account-details/core';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-share-account-ria',
  templateUrl: './share-account-ria.component.html',
  styleUrls: ['./share-account-ria.component.scss'],
})
export class ShareAccountRiaComponent implements OnInit, AfterViewInit {
  @Output() closeModal = new EventEmitter<void>();
  options: AnimationOptions = {
    path: '/assets/animations/welcome.json',
    loop: 0,
  };
  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  sizeIcon: Uk2TooltipSizeEnum = Uk2TooltipSizeEnum.large;
  svgIconName = 'uk2-info-circle';
  placement: Uk2TooltipPlacementEnum = Uk2TooltipPlacementEnum.rightEnd;
  desktopOpenMode: Uk2TooltipTriggerEnum = Uk2TooltipTriggerEnum.hover;
  strategy: Uk2TooltipStrategyEnum = Uk2TooltipStrategyEnum.absolute;
  accounts: SharedAccount[] = [];
  accountsAdded: SharedAccount[] = [];
  providers: Provider[];
  isAccountAggregationEnhancementsActive = false;
  btnShareAccount: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.large;
  formAccount: UntypedFormGroup;
  shouldDisplayTooltip = false;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    private readonly featureFlagService: FeatureFlagService,
    private providerService: ProviderService,
    private serviceHelper: ServiceHelper,
    private readonly brandingSettingsFacade: BrandingSettingsFacade,
    private readonly riaFacade: RiaFacade,
    private _snackBar: MatSnackBar,
    private sharedAccountsFacade: SharedAccountsFacade,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isAccountAggregationEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    this.getProviders();
    this.initializeForm();

    this.brandingSettingsFacade.allBrandingSettings$.subscribe(brandings => {
      this.allBrandingSettings = brandings;
    });

    this.riaFacade.allRias$.subscribe(rias => {
      this.allRiaAccounts = rias;
    });
  }

  ngAfterViewInit() {
    this.getSharedAccounts();
  }

  initializeForm() {
    this.formAccount = new UntypedFormGroup({
      accounts: new UntypedFormControl(null, [Validators.required]),
    });
  }

  getProductName(account: SharedAccount) {
    return `${account.accountSubType} - ${account.accountMask}`;
  }

  getProviders() {
    this.providerService
      .getProviders()
      .then((res: OlbResponse<Provider[]>) => {
        this.providers = res.data;
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }

  getImage(account: SharedAccount) {
    let bank: any;
    const provider = this.providers?.find(p =>
      this.compareNames(account.institutionName, p.providerName)
    );
    let logoPath = '/assets/';
    if (this.isAccountAggregationEnhancementsActive) {
      logoPath =
        logoPath +
        (!!provider
          ? `svg/rounded-logos/${provider.logoName}`
          : `svg/rounded-logos/${this.env.brand}_round_logo.svg`);
    } else {
      logoPath =
        logoPath +
        (bank
          ? `img/logos/${bank.imageSrc}`
          : `img/logos/${this.env.brand}_logo.png`);
    }

    return `${logoPath}`;
  }

  getAasImage(advisor: Advisor) {
    const regexValue = /url\('([^']+)'\)/;
    const secondaryLogoText = 'secondaryLogo';
    const brand = this.allBrandingSettings?.find(
      brand => brand.name === advisor.brandingName
    );
    const ria = this.allRiaAccounts?.find(
      ria => ria.riaId === advisor.advisorId.toString()
    );
    const secondaryLogo = brand?.settings.find(
      settings => settings.name === secondaryLogoText
    );
    if (secondaryLogo && ria.useCustomSecondaryLogo) {
      return secondaryLogo.value.match(regexValue)[1];
    }

    return DefaultAdvisorLogo;
  }

  selectedEntireAccount(account: SharedAccount, completed: boolean) {
    account.isChecked = completed;
    account.isDisplayed = completed;
    if (account.accountType.toUpperCase() === 'EXTERNAL') {
      account.advisors.forEach(advisor => {
        advisor.authorizedTransfer = completed;
        advisor.sharedAccount = false;
      });
    } else {
      account.advisors.forEach(advisor => {
        advisor.authorizedTransfer = completed;
        advisor.sharedAccount = completed;
      });
    }

    const arrayIndex = this.accounts.findIndex(
      x => x.accountId == account.accountId
    );
    if (completed) {
      this.accountsAdded.push(account);
    } else {
      const indexArray = this.accountsAdded.findIndex(
        x => x.accountId === account.accountId
      );
      this.accountsAdded.splice(indexArray, 1);
    }
    this.accounts[arrayIndex] = account;
    this.formAccount.controls.accounts.setValue(this.accountsAdded);
  }
  submit() {
    if (this.formAccount.invalid) return;

    this.formAccount.controls.accounts.setValue(this.accounts);
    const listSharedAccounts: SharedAccountsRequest = {
      accounts: this.accounts,
    };
    this.sharedAccountsFacade.addSharedAccounts(listSharedAccounts);
    this.sharedAccountsFacade.addSharedAccountsResult$.subscribe(result => {
      if (result) {
        this.closeModal.emit();
        this.successMessage();
      } else {
        this.closeModal.emit();
        this.errorMessage();
      }
    });
  }

  skipClick() {
    this.saveFirstDeposit();
    this.closeModal.emit();
  }

  someComplete(account: SharedAccount) {
    if (account.advisors == null) {
      return false;
    }
    if (account.accountType.toUpperCase() === 'EXTERNAL') {
      return (
        account.advisors.filter(x => x.authorizedTransfer).length > 0 &&
        !account.isDisplayed
      );
    }
    return (
      account.advisors.filter(x => x.sharedAccount || x.authorizedTransfer)
        .length > 0 && !account.isDisplayed
    );
  }

  updateShared(account: SharedAccount, index: number, checked: boolean) {
    account.advisors[index].sharedAccount = checked;
    if (checked) {
      this.accountsAdded.push(account);
      this.formAccount.controls.accounts.setValue(this.accountsAdded);
    }
    account.isDisplayed =
      account.advisors != null &&
      account.advisors.every(t => t.sharedAccount && t.authorizedTransfer);
    if (
      !checked &&
      !account.advisors[index].sharedAccount &&
      !account.advisors[index].authorizedTransfer
    ) {
      const indexArray = this.accountsAdded.findIndex(
        x => x.accountId === account.accountId
      );
      this.accountsAdded.splice(indexArray, 1);
      this.formAccount.controls.accounts.setValue(this.accountsAdded);
    }
  }

  updateAuthorized(account: SharedAccount, index: number, checked: boolean) {
    account.advisors[index].authorizedTransfer = checked;

    if (account.accountType.toUpperCase() === 'EXTERNAL') {
      account.isDisplayed =
        account.advisors != null &&
        account.advisors.every(t => t.authorizedTransfer);
    } else {
      account.isDisplayed =
        account.advisors != null &&
        account.advisors.every(t => t.sharedAccount && t.authorizedTransfer);
    }
    if (checked) {
      this.accountsAdded.push(account);
      this.formAccount.controls.accounts.setValue(this.accountsAdded);
    }

    if (
      !checked &&
      !account.advisors[index].sharedAccount &&
      !account.advisors[index].authorizedTransfer
    ) {
      const indexArray = this.accountsAdded.findIndex(
        x => x.accountId === account.accountId
      );
      this.accountsAdded.splice(indexArray, 1);
      this.formAccount.controls.accounts.setValue(this.accountsAdded);
    }
  }

  validateTransferOnly(accountType: string) {
    return accountType.toUpperCase() !== 'EXTERNAL';
  }

  isDifferentFromLoanAccount(accountSubType: string) {
    return accountSubType.toLowerCase() !== 'loan';
  }

  private successMessage() {
    this.sharedAccountsFacade.getSharedAccounts();
    this._snackBar.openFromComponent(Uk2ToastComponent, {
      duration: 5000,
      data: {
        message: 'Account(s) successfully shared with your advisor',
        type: Uk2ToastTypeEnum.success,
      },
    });
  }

  private errorMessage() {
    this._snackBar.openFromComponent(Uk2ToastComponent, {
      duration: 5000,
      data: {
        message:
          'Failed to share account(s) with your advisor. Please try again.',
        type: Uk2ToastTypeEnum.alert,
      },
    });
  }

  private compareNames(bankName: string, providerName: string) {
    return (
      providerName.localeCompare(bankName, 'en', { sensitivity: 'accent' }) ===
      0
    );
  }

  private validateIfHasLoanAccount() {
    this.shouldDisplayTooltip = this.accounts.some(x =>
      this.isDifferentFromLoanAccount(x.accountSubType)
    );
  }

  private getSharedAccounts() {
    this.sharedAccountsFacade.sharedAccountsPrompting$
      .pipe(
        filter(state => state !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(x => {
        this.accounts = x.sharedAccounts.accounts;
        this.validateIfHasLoanAccount();
        this.cd.detectChanges();
      });
  }

  private saveFirstDeposit() {
    this.accounts.forEach(account => {
      account.advisors.forEach(advisor => {
        advisor.authorizedTransfer = false;
        advisor.sharedAccount = false;
      });
    });
    const listSharedAccounts: SharedAccountsRequest = {
      accounts: this.accounts,
    };
    this.sharedAccountsFacade.addSharedAccounts(listSharedAccounts);
  }
}
