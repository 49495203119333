import { ModelsSortDirection } from '../../../const';
import { TSortDirectionTypeValues, TSortDirectionTypes } from '../../../types';
import { Model, TSortingFamilyFunctions } from '../../types';
import { sortingFamilyFunctions } from '../sorting-family-functions';
/**
 * Creates a dynamic sorting function for an array of models based on the specified criteria.
 *
 * @param sortBy keyof {@link Model} - The key to sort the models by.
 * @param sortDirection {@link TSortDirectionTypeValues} - The direction in which to sort the models (ascending or descending).
 * @param valueTypeComparison {@link Model}[keyof {@link Model}] - The type of comparison value (string or number) for sorting.
 * @returns A sorting function that can be used with the `Array.sort` method.
 *
 * @remarks
 * This function generates a sorting function based on the provided parameters, allowing dynamic sorting of an array of models.
 *
 * @example
 * ```typescript
 * const sortedModels = modelsArray.sort(dynamicSortModelsAccount('propertyName', SortDirection.Ascending, 'string'));
 * ```
 */
export function dynamicSortModelsAccount(
  sortBy: keyof Model & string,
  sortDirection: TSortDirectionTypeValues,
  valueTypeComparison: Model[keyof Model]
): (modelA: Model, modelB: Model) => number {
  const comparisonDirectionType: keyof TSortDirectionTypes =
    sortDirection === ModelsSortDirection.Ascending ? 'Ascending' : 'Descending';
  const family: keyof TSortingFamilyFunctions =
    valueTypeComparison === 'string' ? 'stringFamily' : valueTypeComparison === 'number' ? 'numericFamily' : 'default';
  return sortingFamilyFunctions[family](sortBy)[comparisonDirectionType];
}
