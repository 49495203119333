import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { environmentDefaultLink, environmentLinks } from '@app/investor-checking/enums';
import { UserProfileViews } from '@app/user-profile/enums';
import { olbSettings, STATE } from '@core/tokens';

@Inject('$window')
@Component({
  selector: 'app-investor-checking-header',
  templateUrl: './investor-checking-header.component.html',
  styleUrls: ['./investor-checking-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingHeaderComponent implements OnInit {
  displaySpeedBump = false;
  otherUrl: string;
  brandName: string;
  modalUrl: string;
  url: string;
  DisplayUrl: string;
  constructor(@Inject(STATE) private state: ng.ui.IStateService, @Inject(olbSettings) private settings: OlbSettings) {}

  ngOnInit(): void {
    this.DisplayUrl = environmentLinks[this.settings.environment.toLowerCase()] || environmentDefaultLink;
  }

  togglePageModal() {
    this.displaySpeedBump = !this.displaySpeedBump;
  }

  skippingAccount() {
    this.displaySpeedBump = !this.displaySpeedBump;
    this.otherUrl = this.url;
    sessionStorage.setItem('mAgreementDecision', 'true');
    this.state.go('udb.dashboard', { view: UserProfileViews.PersonalInfo });
  }
}
