import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import { AccountUnlockComponent } from '@app/authentication/components';
import { Inject } from '../decorators/decorators';

@Inject('$stateProvider')
export class AccountUnlockConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('auth.accountunlock', {
        url: '/Unlock/:token',
        views: {
          content: {
            template: '<app-account-unlock></app-account-unlock>',
          },
        },
        params: {
          v2: false,
        },
      })
      .state('auth.resetpassword', {
        url: '/ResetPassword/:token?ut=:hash',
        views: {
          content: {
            template: '<app-account-unlock></app-account-unlock>',
          },
        },
      })
      .state('auth.resetpasswordtc', {
        url: '/ResetPassword/:token?ut=:hash&rp=:flag',
        views: {
          content: {
            template: '<app-account-unlock></app-account-unlock>',
          },
        },
      });
  }
}

export const accountUnlockLegacyModule = angular
  .module('auth.accountunlock', ['ui.router'])
  .directive('appAccountUnlock', downgradeComponent({ component: AccountUnlockComponent }))
  .config(AccountUnlockConfig).name;
