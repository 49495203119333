import { NetWorthPoint } from '@app/pfm/models/net-worth-point.model';

import { NetWorthFilters } from './net-worth-filters';

export class NetWorthState {
  uncommittedFilters = new NetWorthFilters();
  committedFilters = new NetWorthFilters();

  netWorthSeries: NetWorthPoint[]; // null means not loaded yet
  netWorthSeriesLoading: boolean;

  constructor(args?: Partial<NetWorthState>) {
    Object.assign(this, args);
  }
}
