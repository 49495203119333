import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AccountsService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { StopPayment } from '@legacy/typings/app/StopPayment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-stop-payment-success',
  templateUrl: './stop-payment-success.component.html',
  styleUrls: ['./stop-payment-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopPaymentSuccessComponent implements OnInit {
  accountId: number;
  seqId: number;
  stopPayment: StopPayment;
  accountName: string;
  actionMsg: string;

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private serviceHelper: ServiceHelper,
    private accountService: AccountsService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.seqId = this.params['seq'];
    this.stopPayment = this.params['stopPayment'];
    this.actionMsg = this.params['actionMsg'];
    if (!this.stopPayment)
      this.state.go('udb.accounts.stop-payment', {
        id: this.accountId,
      });
    this.accountService
      .getAccount(this.accountId.toString())
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: res => {
          this.accountName = res.data.nickname || res.data.name;
        },
        error: this.serviceHelper.errorHandler,
      });
  }

  redirectToStopPaymentsPage(paramAccountId: any): void {
    this.state.go('udb.accounts.stop-payment', {
      id: paramAccountId,
    });
  }

  redirectToDetailsPage(paramAccountId: any): void {
    this.state.go('udb.accounts.details', {
      id: paramAccountId,
      tab: 1,
    });
  }
}
