import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinorAddressComponent, MinorInformationComponent, MinorMainComponent } from './components';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { MinorHelper } from '@legacy/services/minor.helper';
import { NgxMaskModule } from 'ngx-mask';
import { EnrollmentService } from '@core/services';

const exportComponents = [MinorMainComponent, MinorAddressComponent, MinorInformationComponent];

@NgModule({
  declarations: [...exportComponents],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, NgxMaskModule.forRoot()],
  exports: [...exportComponents],
  entryComponents: [...exportComponents],
  providers: [MinorHelper, EnrollmentService],
})
export class MinorsModule {}
