export type FirmContactAddressStateType = {
  address1: string | undefined;
  address2: string | undefined;
  address3: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
};

export const initialFirmContactAddressState: FirmContactAddressStateType = {
  address1: undefined,
  address2: undefined,
  address3: undefined,
  city: undefined,
  state: undefined,
  zipCode: undefined,
};
