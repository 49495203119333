import * as angular from 'angular';
import { Inject } from '../../../decorators/decorators';
import { ITransactionService } from '../../../services/typings/ITransactionService';
import * as moment from 'moment';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { IFilterService } from 'angular';
import { CustomerLimitsHelper } from 'services/customer-limits.helper';
@Inject(
  'dateHelper',
  '$uibModalInstance',
  'transactionService',
  'serviceHelper',
  '$filter',
  'customerLimitsHelper',
  'frequencies',
  'transferToEdit',
  'isSeries',
  'dateRangePickerOptions'
)
export class EditExternalTransferModal {
  isLoadingSetup: boolean = false;
  // Datepickers
  public datePickerOptions: any;
  public datePicker: any;
  public minDate: Date;
  public isTodayValidForExternal: boolean;

  notificationErrorMsg: string;
  customerLimits: CustomerLimits;

  constructor(
    private readonly dateHelper: DateHelper,
    private _uibModalInstance: any,
    private _transactionService: ITransactionService,
    private _serviceHelper: IServiceHelper,
    private readonly filterService: IFilterService,
    private readonly customerLimitsHelper: CustomerLimitsHelper,
    public frequencies: GenericOption[],
    public transferToEdit: ExternalAccountEditRequest,
    public isSeries: boolean,
    private _dateRangePickerOptions: any,
    public errorMsg: string
  ) {}

  /** Initializes the controller. */
  public $onInit(): void {
    this.checkCustomerLimits();
    this.isTodayValidForExternal = true;
    this.minDate = this.dateHelper.normalizeDate(moment()).toDate();
    let { isCutOffTime, externalTransferValidDate } = this.dateHelper.getDefaultPaymentInformation();

    this.minDate = externalTransferValidDate.toDate();
    if (isCutOffTime) this.isTodayValidForExternal = false;

    this.datePickerOptions = angular.extend(angular.copy(this._dateRangePickerOptions), {
      minDate: this.minDate,
      maxDate: moment().add(13, 'M'),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'hide.daterangepicker': (_evt: any) => {
          this.transferToEdit.ProcessingDate = moment(_evt.model).toDate();
          this.isTodayValidForExternal = true;
        },
        'show.daterangepicker': (_evt: any) => {
          this.setPropertiesForCalendar();
        },
      },
      isInvalidDate: (date: any) => {
        return !this.dateHelper.isValidDate(date);
      },
    });
    this.errorMsg = null;
  }
  /**
   * @deprecated this has been migrated
   */
  public saveTransfer() {
    if (this.validateAmount()) {
      this.isLoadingSetup = true;
      const serviceMethod = this.isSeries ? 'scheduleEditExternalTransfer' : 'scheduleEditNextExternalTransfer';

      this._transactionService[serviceMethod](this.transferToEdit)
        .then(_res => {
          this._uibModalInstance.close(true);
        })
        .catch(err => {
          if (err.data.message.includes('amount exceeds maximum customer limit')) {
            return this.showAmountExceededErrorMessage();
          }

          this._serviceHelper.errorHandler.bind(this._serviceHelper);
        })
        .finally(() => {
          this.isLoadingSetup = false;
        });
    }
  }
  /**
   * @deprecated this has been migrated
   */
  public closeModal() {
    this._uibModalInstance.close(false);
  }
  /**
   * @deprecated this has been migrated
   */
  /**
   * @deprecated
   */
  public showCalendar(): void {
    const dp = $('[date-range-picker]');
    if (!dp.data('daterangepicker').isShowing) dp.data('daterangepicker').show();
    else dp.data('daterangepicker').hide();
  }
  /**
   * @deprecated migrated as reactive form validation rule
   */
  public validateAmount(): boolean {
    if (this.transferToEdit.Amount > 0) {
      this.errorMsg = null;
      return true;
    }
    this.errorMsg = 'Please enter Amount';
    return false;
  }
  /**
   * @deprecated migrated already
   * Fetch customer limits
   */
  private checkCustomerLimits() {
    this.customerLimitsHelper
      .fetchCustomerLimits()
      .then(res => {
        this.customerLimits = res;
      })
      .catch(this._serviceHelper.errorHandler);
  }

  /**
   * @deprecated migrated already
   * Shows an error notification if transfer exceeded daily limits for the user
   * this message changes whether the transfer is incoming/outgoing
   */
  private showAmountExceededErrorMessage() {
    this.notificationErrorMsg =
      this.transferToEdit.TransferType === 1
        ? `Transfer amount exceeds daily outbound limit of
             ${this.filterService('currency')(this.customerLimits.extTransfersMaxOutgoingAmount)}`
        : `Transfer amount exceeds daily inbound limit of
             ${this.filterService('currency')(this.customerLimits.extTransfersMaxIncomingAmount)}`;
  }

  private setPropertiesForCalendar() {
    var input = $('#date').first();
    var daterangepicker = $('.daterangepicker');

    daterangepicker.css('left', input.offset().left + input.outerWidth() + 10);
    daterangepicker.css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    daterangepicker.append('<div id="arrow-left"></div>');
    daterangepicker.addClass('no-after');

    $('#arrow-left')
      .css('position', 'absolute')
      .css('left', '-8px')
      .css('width', '0')
      .css('height', '0')
      .css('border-top', '8px solid transparent')
      .css('border-bottom', '8px solid transparent')
      .css('border-right', '8px solid darkgray')
      .css('top', daterangepicker.outerHeight() / 2);

    $(document).on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });

    $('.modal-body').on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });
  }
}
