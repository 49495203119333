import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { format } from 'date-fns';

import { AccountCategory, ContainerType } from '@app/accounts/enums';
import { AggregatedAccount } from '@app/accounts/models';
import { mapStringToContainerType } from '@app/utils';

import { AggregateAccountDetailRow } from '../../models';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-aggregate-account-detail-view',
  templateUrl: './aggregate-account-detail-view.component.html',
  styleUrls: ['./aggregate-account-detail-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregateAccountDetailViewComponent implements OnInit {
  @Input() account: AggregatedAccount;
  @Output() updateNickName = new EventEmitter();

  container: ContainerType;
  isLoading = true;
  accountRows: AggregateAccountDetailRow[] = [];
  ownershipRows: AggregateAccountDetailRow[] = [];
  paymentRows: AggregateAccountDetailRow[] = [];
  isRiaShareAccountFlag = false;
  isRiaAuthorizeTransfersFlag = false;
  shouldDisplayShareAccount = false;
  shouldDisplayAuthorizeTransfer = false;
  accountId: string;
  private currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  private percent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  constructor(
    private cd: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.accountId = this.account.id.toString();
    this.container = mapStringToContainerType(this.account.container);

    this.setBaseAccountRows();
    this.setComplimentaryAccountRows();

    if (this.account.holders?.length > 0 || this.account.displayedName) {
      this.setOwnershipRows();
    }

    if (
      [ContainerType.CreditCard, ContainerType.Loan].includes(this.container)
    ) {
      this.setPaymentRows();
    }
    this.isRiaShareAccountFlag = this.featureFlagService.isRiaShareAccountActive();
    this.isRiaAuthorizeTransfersFlag = this.featureFlagService.isRiaAuthorizeTransfersActive();
    this.validateIfIsDepositAccount();
    this.isLoading = false;
    this.cd.markForCheck();
  }

  updateNickNameEvent() {
    this.updateNickName.emit();
  }

  private validateIfIsDepositAccount() {
    if (this.account.isExternal) {
      this.shouldDisplayAuthorizeTransfer = true;
      this.shouldDisplayShareAccount = true;
    }
  }
  private setBaseAccountRows() {
    this.accountRows.push({
      title:
        this.container === ContainerType.Loan
          ? 'Loan Nickname'
          : 'Account Nickname',
      content: this.getNickname(),
      accountId: this.account.id,
    });

    if (this.account.accountType) {
      this.accountRows.push({
        title:
          this.container === ContainerType.Loan ? 'Loan Type' : 'Account Type',
        content: this.account.accountType.toLowerCase().replace(/_/g, ' '),
      });
    }

    this.accountRows.push({
      title:
        this.container === ContainerType.Loan
          ? 'Loan Number'
          : 'Account Number',
      content: this.getAccountNumber(),
    });

    if (this.account.routingNumber) {
      this.accountRows.push({
        title: 'Routing Number',
        content: this.account.routingNumber,
      });
    }
  }

  private setComplimentaryAccountRows() {
    if (this.container === ContainerType.Loan) {
      if (this.validateDate(this.account.maturityDate)) {
        this.accountRows.push({
          title: 'Maturity Date',
          content: this.dateFormat(this.account.maturityDate),
        });
      }

      if (this.account.interestRate) {
        this.accountRows.push({
          title: 'Interest Rate',
          content: this.percentFormat(this.account.interestRate),
        });
      }

      if (this.account.outstandingBalance?.amount) {
        this.accountRows.push({
          title: 'Outstanding Balance',
          content: this.currencyFormat(this.account.outstandingBalance.amount),
        });
      }
    } else if (this.container === ContainerType.CreditCard) {
      if (this.account.displayBalance) {
        this.accountRows.push({
          title: 'Current balance',
          content: this.getDisplayBalance(),
        });
      }

      if (this.account.purchaseAPR) {
        this.accountRows.push({
          title: 'Purchase APR',
          content: this.percentFormat(this.account.purchaseAPR),
        });
      }

      if (this.account.availableCredit) {
        this.accountRows.push({
          title: 'Available Credit',
          content: this.currencyFormat(this.account.availableCredit),
        });
      }
    }
  }

  private setOwnershipRows() {
    if (!this.account.holders.length && this.account.displayedName) {
      this.ownershipRows.push({
        title: 'Account Holder',
        content: this.account.displayedName,
      });
    } else {
      this.account.holders.forEach(holder => {
        this.ownershipRows.push({
          title: this.parseOwnership(holder?.ownerShip),
          content: holder.name.fullName,
        });
      });
    }
  }

  private setPaymentRows() {
    this.paymentRows.push({
      title: 'Next Payment Due',
      content: this.dateFormat(this.account.nextPaymentDue),
    });

    if (this.account.minimumPaymentDue) {
      this.paymentRows.push({
        title: 'Minimum Payment Due',
        content: this.currencyFormat(this.account.minimumPaymentDue),
      });
    }

    if (this.account.amountDue) {
      this.paymentRows.push({
        title: 'Next Payment Amount',
        content: this.currencyFormat(this.account.amountDue),
      });
    }

    if (this.account.lastPaymentDate) {
      this.paymentRows.push({
        title: 'Last Payment Date',
        content: this.dateFormat(this.account.lastPaymentDate),
      });
    }

    if (this.account.lastPaymentAmount) {
      this.paymentRows.push({
        title: 'Last Payment Amount',
        content: this.currencyFormat(this.account.lastPaymentAmount),
      });
    }

    if (this.container === ContainerType.Loan) {
      if (this.account.interestPaidYTD) {
        this.paymentRows.push({
          title: 'Interest Paid YTD',
          content: this.currencyFormat(this.account.interestPaidYTD),
        });
      }

      if (this.account.interestPaidLastYear) {
        this.paymentRows.push({
          title: 'Previous Year Interest Paid',
          content: this.currencyFormat(this.account.interestPaidLastYear),
        });
      }
    }
  }

  private getNickname() {
    return this.account.nickname || this.account.name || this.account.bankName;
  }

  private getAccountNumber() {
    return this.account.accountMask && this.account.accountMask.length > 3
      ? `*${this.account.accountMask.substring(
          this.account.accountMask.length - 4
        )}`
      : this.account.accountNumber;
  }

  private getDisplayBalance() {
    let displayBalance = 0;

    if (
      this.container === ContainerType.Bank &&
      [AccountCategory.Cd, AccountCategory.Sav].includes(
        this.account.category
      ) &&
      this.account.currentBalance
    ) {
      displayBalance = this.account.currentBalance;
    } else if (this.container === ContainerType.CreditCard) {
      displayBalance = this.account.runningBalance;
    } else if (this.container === ContainerType.Investment) {
      displayBalance = this.account.balance;
    } else {
      displayBalance = this.account.availableBalance || 0;
    }

    return this.currencyFormat(displayBalance);
  }

  private parseOwnership(ownership: string) {
    let normalizedOwnership = '';

    if (ownership) {
      normalizedOwnership = ownership.replace(/\s/g, ' ');
    }

    return normalizedOwnership.toLowerCase() === 'primary'
      ? 'Account holder'
      : `${normalizedOwnership} Holder`;
  }

  private validateDate(date: Date) {
    const initialTime = new Date('0001-01-01T00:00:00').getTime();
    const timeToValidate = new Date(date).getTime();

    return timeToValidate === initialTime ? false : true;
  }

  private currencyFormat(amount: number) {
    return this.currency.format(amount);
  }

  private percentFormat(value: number) {
    return this.percent.format(value / 100);
  }

  private dateFormat(date: Date = null) {
    return format(new Date(date), 'MM/dd/yyyy');
  }
}
