import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { AccountDetailsMatModule } from './account-details-mat.module';
import { AccountDetailsUk2Module } from './account-details-uk2.module';
import {
  AccountDetailsService,
  initialAccountPeopleState,
  initialAccountDetailsState,
  initialAuthorizationsStateType,
} from './core';
import { accountPeopleReducer, accountDetailsReducer, authorizationsReducer } from './core/store/reducer';
import { AccountDetailsFacade } from './facade';
import {
  AccountDetailsPageComponent,
  AccountDetailsTileComponent,
  AccountFirmContactsTabComponent,
  AccountsTabComponent,
} from './view';
import { AasSharedModule } from '../../aas-shared/aas-shared.module';
import { CommonModule } from '@angular/common';
import { AuthorizationsTabModule } from './view/components/authorizations-tab/authorizations-tab.module';
import { PeopleTabModule } from './view/components/people-tab/people-tab.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  sharedAccountsByAdvisorReducer,
  sharedAccountsPromptingReducer,
  sharedAccountsReducer,
} from './core/store/reducer/shared-accounts.reducer';

@NgModule({
  declarations: [
    AccountDetailsPageComponent,
    AccountDetailsTileComponent,
    AccountFirmContactsTabComponent,
    AccountsTabComponent,
  ],
  providers: [AccountDetailsService, AccountDetailsFacade],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AccountDetailsMatModule,
    AccountDetailsUk2Module,
    StoreModule.forFeature(AasStoreFeatures.AccountDetails, accountDetailsReducer, {
      initialState: initialAccountDetailsState,
    }),
    StoreModule.forFeature(AasStoreFeatures.AccountAuthorizations, authorizationsReducer, {
      initialState: initialAuthorizationsStateType,
    }),
    StoreModule.forFeature(AasStoreFeatures.AccountPeople, accountPeopleReducer, {
      initialState: initialAccountPeopleState,
    }),
    StoreModule.forFeature(AasStoreFeatures.SharedAccounts, sharedAccountsReducer),
    StoreModule.forFeature(AasStoreFeatures.SharedAccountsByAdvisor, sharedAccountsByAdvisorReducer),
    StoreModule.forFeature(AasStoreFeatures.SharedAccountsPrompting, sharedAccountsPromptingReducer),
    AasSharedModule,
    AuthorizationsTabModule,
    PeopleTabModule,
  ],
  exports: [AccountDetailsTileComponent],
})
export class AccountDetailsModule {}
