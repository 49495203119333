export const CSS_VARIABLES = {
  udbLogin: {
    mainHeaderComponent: {
      titleColor: '--udb-login-main-header-title-color',
    },
  },
  aas: {
    global: {
      header: {
        fontColor: '--aas-header-title-color',
      },
    },
  },
  uk2Lib: {
    global: {
      banner: {
        topBarColor: '--uk2-brand-top-bar-color-bar-color',
      },
      header: {
        fontColor: '--uk2-header-font-color',
      },
      main: {
        primaryAccentHsl: {
          full: '--uk2-accent-color-hsl',
          hue: '--uk2-accent-color-hsl-h',
          saturation: '--uk2-accent-color-hsl-s',
          lightness: '--uk2-accent-color-hsl-l',
        },
      },
    },
    components: {
      formField: {
        generalActiveColor: '--uk2-form-field-general-active-color',
      },
      modal: {
        headerFontColor: '--uk2-modal-header-font-color',
      },
      tooltip: {
        labelFontColor: '--uk2-tooltip-label-color',
        iconFontColor: '--uk2-tooltip-icon-color',
      },
      button: {
        labeledIconColor: '--uk2-labeled-icon-button-color',
      },
    },
  },
};
