import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AccountDetail } from '@app/accounts/models';

@Component({
  selector: 'app-loan-payment-detail',
  templateUrl: './loan-payment-detail.component.html',
  styleUrls: ['../loan-account-detail/loan-account-detail.component.scss', './loan-payment-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanPaymentDetailComponent {
  @Input() details: AccountDetail;
  @Input() isPastDue: boolean;
}
