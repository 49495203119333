import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sm-item',
  templateUrl: './sm-item.component.html',
  styleUrls: ['./sm-item.component.scss'],
})
export class SmItemComponent {
  @Input()
  public name: string;
  @Input()
  public icon: string;
}
