import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { STATE, olbSettings } from '@core/tokens';

@Component({
  selector: 'app-funding-terms',
  templateUrl: './funding-terms.component.html',
  styleUrls: ['./funding-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundingTermsComponent {
  isLoading = false;
  brand: string;

  constructor(
    @Inject(olbSettings) private readonly env: OlbSettings,
    @Inject(STATE) private stateService: ng.ui.IStateService
  ) {}

  ngOnInit(): void {
    this.brand = String(this.env.brand);
  }

  acceptTerms() {
    this.isLoading = true;
    this.stateService.go('udb.dashboard');
  }
}
