<section class="container funding-receipt receipt center">
  <div class="center" *ngIf="hasFailed">
    <h1 class="receipt-title">
      Receipt not available.
    </h1>
  </div>

  <div class="header-section-title center" [hidden]="hasFailed">
    <div>
      <!-- icon -->
      <svg aria-hidden="true" class="checkmark green-stroke" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <circle
          *ngIf="env.brand !== 'axos'"
          class="checkmark__circle2"
          cx="30"
          cy="30"
          r="26"
          fill="none"
          stroke-linecap="round"
        />
        <path
          class="checkmark__check2"
          fill="none"
          d="M19.1 30.6l8.9  6.5 13-15.1 "
          stroke-linecap="round"
          stroke-corner="round"
          stroke-linejoin="round"
        />
      </svg>
      <div *ngIf="settings.isBrokerage">
        <h1 class="receipt-title">Funding Review</h1>
        <div class="frow center">
          <p>
            {{ settings.transactions[0].details[1].value }}
          </p>
        </div>
      </div>

      <h1 *ngIf="settings.isBrokerage" class="receipt-title">
        Account Funded
      </h1>
    </div>
  </div>
  <div class="center fcol-cs-12" [hidden]="hasFailed">
    <div class="funding-receipt-section">
      <!-- sub-title [optional] -->
      <h2 class="funding-receipt-section-title">
        {{
          isTradingAccount
            ? "You've successfully funded your " + settings.toAccount + ' account.'
            : "You've successfully requested funding for your account"
        }}
      </h2>
      <p class="funding-receipt-text space-top" [hidden]="!settings.confirmationEmail">
        We've sent a confirmation email to you at
        <span class="text-bold">{{ settings.confirmationEmail }}</span>
      </p>
    </div>
    <div class="frow center">
      <div class="fcol-cs-12 fcol-sm-7 fcol-md-5 m-t-lg">
        <!-- ------------------------------ -->
        <!-- details -->
        <table *ngFor="let tran of settings.transactions; index as i" class="table table-striped table--outer-border">
          <tbody>
            <ng-container *ngFor="let record of tran.details">
              <tr *ngIf="record.value">
                <td class="text-left">
                  {{ record.description }}
                </td>
                <td class="text-bold text-right truncate">
                  {{ record.value }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="receipt-section" *ngIf="settings.isFromExternalAccount || isTradingAccount">
      <p class="receipt-footnote">
        {{
          isTradingAccount
            ? 'You maybe contacted if additional information is needed during ' +
              'the funding review process. For approved funding accounts, incoming funds are typically ' +
              'available within ' +
              (settings.isBrokerage ? '7-10' : '4-5') +
              ' business days.'
            : 'Incoming funds will typically be available within 4 business days.'
        }}
      </p>
    </div>
  </div>
  <div class="frow center funding-receipt-nav">
    <button
      type="button"
      class="uk-btn lg outline primary"
      (click)="goToViewAccounts()"
      *ngIf="!settings.isBrokerage || moreAccountsToFund"
    >
      View Accounts
    </button>
    <button
      type="button"
      class="uk-btn lg solid secondary"
      *ngIf="moreAccountsToFund"
      [routerLink]="['/udb', 'funding']"
    >
      Fund Additional Account
    </button>
    <button
      type="button"
      class="uk-btn lg solid secondary"
      *ngIf="!moreAccountsToFund && !settings.isBrokerage"
      [routerLink]="['/udb', 'dashboard']"
    >
      Go to My Dashboard
    </button>
    <button
      type="button"
      class="uk-btn lg solid secondary"
      (click)="goToViewAccounts()"
      *ngIf="settings.isBrokerage && !moreAccountsToFund"
    >
      View Accounts
    </button>
    <div *ngIf="settings.isBrokerage"></div>
  </div>
</section>
