import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { getBrandProperty } from '@app/store/selectors';
import { AuthMethod, BrandProperty } from '@core/enums';
import { MultiFactorRequest } from '@core/models';
import { OlbSettings } from '@core/models';
import { AuthService, MultifactorService } from '@core/services';
import { olbSettings, STATE } from '@core/tokens';
import { BrandPropertyService } from '@legacy/services/brand-property.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  loading = false;
  forgotPasswordForm: UntypedFormGroup;
  serverErrorMessage = '';
  accessCodeError: string;
  iconProps = {
    iconSize: '82px',
    color: 'var(--primary)',
    icon: AlertsIcons.CheckCircle,
  };
  $supportPhone: Observable<string>;
  step = 1;
  otp: string;

  get userName() {
    return this.forgotPasswordForm.get('userName');
  }

  get isInvalid() {
    const { errors, touched, pristine } = this.userName;

    return !!errors && (touched || !pristine);
  }

  set token(code: string) {
    if (code) sessionStorage.setItem('AuthenticationToken', code);
    else sessionStorage.removeItem('AuthenticationToken');
  }

  get token(): string {
    return sessionStorage.getItem('AuthenticationToken');
  }

  get errorMessage() {
    const { errors } = this.userName;
    if (errors?.required) {
      return 'Enter username!';
    } else if (errors?.minlength) {
      return 'Username needs to be at least 6 characters long!';
    } else {
      return '';
    }
  }

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private formBuilder: UntypedFormBuilder,
    private serviceHelper: ServiceHelper,
    private authService: AuthService,
    private multifactorService: MultifactorService,
    private brandPropertyService: BrandPropertyService,
    @Inject(olbSettings) private settings: OlbSettings,
    private store: Store
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.loadSupportPhone();
  }

  // TODO: Replace this logic with routers when available
  backToLogin() {
    this.state.go('auth.login');
  }

  /**
   * Redirects user to forgot username
   */
  forgotUsername() {
    this.state.go('auth.forgotusername');
  }

  /**
   * Send the reset password url to the user and redirect to the confirmation page.
   */
  sendPassword() {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();

      return;
    }
    this.loading = true;
    this.serverErrorMessage = '';
    this.authService
      .resetPassword(this.userName.value)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          if (response.data?.maskedPhone) sessionStorage.setItem('maskedCellPhone', response.data?.maskedPhone);
          else sessionStorage.removeItem('maskedCellPhone');
          sessionStorage.removeItem('maskedEmail');
          sessionStorage.setItem('SignInFlowToken', response.data?.token);
          this.step = 2;
        },
        error: err => {
          this.serverErrorMessage = 'Connection error, please try again later';
          this.serviceHelper.errorHandler(err);
        },
      });
  }

  sendCode(authMethod: AuthMethod) {
    this.loading = true;
    const request: MultiFactorRequest = {
      username: this.userName.value,
      authenticationMethod: authMethod,
      challengeToken: sessionStorage.getItem('SignInFlowToken'),
    };
    this.multifactorService
      .requestOtp(request)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: res => {
          this.token = res.data.authenticationToken;
          this.otp = res.data.otp;
          this.step = 3;
          sessionStorage.setItem('authRequest', JSON.stringify(request));
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  validateOtp(code: string) {
    this.loading = true;
    const request: MultiFactorRequest = {
      username: this.userName.value,
      otp: code,
      authenticationToken: this.token,
      authenticationMethod: AuthMethod.Sms,
    };
    delete this.accessCodeError;
    this.multifactorService
      .validateOtp(request)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: ({ data }) => {
          this.state.go('auth.accountunlock', {
            token: data.userSecurityQuestionRequestToken,
            v2: true,
          });
        },
        error: (err: HttpErrorResponse) => {
          if (err.status >= 500) {
            this.serviceHelper.errorHandler(err);
          }
          this.accessCodeError =
            err.error?.message ||
            'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
        },
      });
  }

  private loadSupportPhone() {
    this.$supportPhone = this.store.select(getBrandProperty(BrandProperty.ContactPhoneNumber));
    this.brandPropertyService.getBrandProperties(this.settings.brandId, this.settings.brandName).subscribe();
  }
}
