import { FastlinkOauthMigrationStatus } from '@legacy/dashboard/account-aggregation/enums/fast-link-OAuth-Migration-Status-enum';
import { DropdownItem } from '@uikit/clickdropdown';
import { AccountCategory } from 'accounts/account-category.enum';
import { containerType } from 'accounts/container-type.enum';
import { IRootScopeService, IScope } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { AccountType } from 'common/enums/enums';
import { Inject } from 'decorators/decorators';
import { AccountAggregationService } from 'services/account-aggregation.service';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { CachedTradingAccountsService } from 'services/cached-trading-accounts.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { ModalService } from 'services/modal.service';
import { IAxosClearingService } from 'services/typings/IAxosClearingService';
import { IProviderService } from 'services/typings/IProviderService';
import { AggregatedAccount } from 'typings/app/account-aggregation/AggregatedAccount';

import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { AccountRouterHelper } from '../../account-router-helper';

@Inject(
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  'serviceHelper',
  'modalService',
  'cachedAccountsService',
  'featureFlagService',
  'env',
  'accountAggregationService',
  '$interval',
  'cachedTradingAccountsService',
  'axosClearingService',
  'axosInvestHelper',
  'accountRouterHelper',
  'providerService'
)
export class AccountAggDetailsIndexController {
  isLoading = false;
  accountDetails: AggregatedAccount;
  accountId: number;
  container: string;
  accountType: string;
  indexTabActive = 0;
  dropdownItems: DropdownItem[] = [];
  selectedItem: DropdownItem;
  isOrderCheckClicked = false;
  detailsRetrieved = false;
  displayBalanceMsgLeft = 'Available Balance';
  displayBalanceMsgRight = 'Total Balance';
  tooltipDescriptionLeft =
    'Amount that is currently available for transfers, withdrawals and POS purchases. Excludes any holds currently on the account.';
  tooltipDescriptionRight = 'Current balance in your account';
  amountDueToolTipDescription = '';
  brand: string;
  isRegisteredInPayveris = false;
  successMessage: string = null;
  externalAccountId: number;
  aggregatedAccount: AggregatedAccount;
  nickName: string;
  bankNameMask: string;
  displayBalanceLeft = 0;
  displayBalanceRight = 0;
  displayAmountDue = 0;
  messageOperation: string = null;
  statusOperation = false;
  showAmountDue = false;
  amountDueText: string;
  providers: Provider[];
  private containerTypeEnum = containerType;
  private accountCategoryEnum = AccountCategory;
  private listeners: Function[] = [];
  private investAccounts: OlbAccount[] = [];
  private readonly viewAllAccountText = 'View All Accounts';

  constructor(
    private readonly rootScope: IRootScopeService,
    private readonly scope: IScope,
    private readonly state: IStateService,
    private readonly params: IStateParamsService,
    private readonly serviceHelper: IServiceHelper,
    private readonly modalService: ModalService,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly env: OlbSettings,
    private readonly accountAggregationService: AccountAggregationService,
    private readonly interval: ng.IIntervalService,
    private readonly cachedTradingAccountsService: CachedTradingAccountsService,
    private readonly axosClearingService: IAxosClearingService,
    private readonly axosInvestHelper: AxosInvestHelper,
    private readonly accountRouterHelper: AccountRouterHelper,
    private readonly providerService: IProviderService
  ) {}

  /** Initializes required data once the module is fully loaded. */
  $onInit(): void {
    this.brand = String(this.env.brand);
    this.serviceHelper.scrollToTop();
    this.accountId = this.params['id'];
    this.container = this.params['container'];
    this.detailsRetrieved = false;
    this.indexTabActive = !!this.params['tab'] ? +this.params['tab'] : 0;

    this.isLoading = true;
    Promise.all([this.onInternalAccountsLoaded(), this.onAggregatedAccountsLoaded(), this.onInvestLoaded()]).then(
      this.processInformation.bind(this)
    );

    this.scope.$watch('vm.indexTabActive', (_oldVal: any, _newVal: any) => {
      if (_oldVal === 3) {
        this.scope.$broadcast('tabSelected', this.accountDetails);
      }
    });
  }

  processInformation(): void {
    this.aggregatedAccount = this.cachedAccountsService.aggregatedAccounts.find(
      (item: AggregatedAccount) => item.id == this.accountId
    );

    if (this.rootScope['providers']) {
      this.providers = this.rootScope['providers'];
      this.initAccountsDropdown();
    } else {
      this.getProviders();
    }

    this.accountAggregationService
      .getAccountDetails(this.accountId, this.container)
      .then(this.transformAccountAggregationName.bind(this))
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
      });
  }

  //#region Remove Account
  updateNickName() {
    // next event will update account dropdown
    this.scope.$emit('nicknameUpdated', this.initAccountsDropdown());
  }

  /**
   * Modal confirmation to remove an account
   */
  openExternalAccountDeletionModal(account: AggregatedAccount) {
    const accountName = account.nickname ?? account.name ?? account.bankName;
    this.modalService
      .show(
        {},
        {
          bodyText: `
						<h3>Remove?</h3></br>
						<h4 class="account-name">${accountName}</h4>
						<p>You will no longer be able to view the balance of this account through ${this.env.brandName} Online Banking</p>
					`,
          okText: 'Yes',
          cancelText: 'No',
        }
      )
      .then(() => {
        this.deleteAccount(account);
      });
  }

  /**
   * Removes an aggregated account
   * @param account Account to be removed.
   */
  deleteAccount(account: AggregatedAccount) {
    account['isRemoving'] = true;
    this.accountAggregationService
      .deleteAccount(account.id.toString(), this.getNickName(account))
      .then(() => {
        const externalsNotDeleted = this.cachedAccountsService?.externalAccounts?.filter(
          accountToDelete => accountToDelete.accountNumber !== account.accountNumber
        );
        this.cachedAccountsService.loadExternalAccounts(externalsNotDeleted);
        this.cachedAccountsService.removeAggregatedAccount(account.id);
        this.handleResult(account, 'removed');
      })
      .catch(error => {
        this.serviceHelper.errorHandler(error);
        account['isRemoving'] = false;
      });
  }
  /**
   * Shows a message of success with the information of an account
   * and refreshes the accounts
   * @param account The account updated/removed.
   * @param action If the account was updated or removed
   */
  handleResult(account: AggregatedAccount, action: string): void {
    const message =
      action === 'updated'
        ? `Your changes to ${account.name} have been saved`
        : `Success! ${account.name} has been removed. To add it again, you will need to reauthenticate.`;

    this.getAccountOperationMessage(message);
    // let the user see the message when deleting the account and redirec to all accounts page
    this.waitAndRedirect(2, 'udb.accounts.dashboard');
  }

  waitAndRedirect(seconds: number, redirecTo: string): void {
    const interval = this.interval(() => {
      this.interval.cancel(interval);
      this.state.go(redirecTo);
    }, seconds * 1000);
  }
  //#endregion

  /**
   * Take the customer to Move Money page
   */
  goToMoveMoney(accountId: number): void {
    // This account should be already pre-selected on the FROM account when the customer goes to the Move Money screen.
    this.state.go('udb.transfers.transferFunds', { id: accountId });
  }

  async goToAccountAggregationFlow(accountDetails: AggregatedAccount) {
    // it will take the customer to the account aggregation flow (for that particular bank XXXX)
    const isAccAggrEnhancementActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    const institution = await (await this.accountAggregationService.getInstitution(accountDetails.providerId)).data;
    this.state.go('udb.dashboard.account-aggregation', {
      bankId: accountDetails.providerId,
      container: accountDetails.container,
      accountId: accountDetails.id,
      isAccountAggregationFlow: isAccAggrEnhancementActive,
      flow: 'details',
      accountSelected: accountDetails,
      institution,
    });
  }

  checkMigrationStatus(accountDetails: AggregatedAccount) {
    if (accountDetails) {
      const isInProgress = accountDetails.oAuthMigrationStatus == FastlinkOauthMigrationStatus.In_Progress;
      const isToBeMigrated = accountDetails.oAuthMigrationStatus == FastlinkOauthMigrationStatus.To_Be_Migrated;
      if (isInProgress || isToBeMigrated) {
        return false;
      }
      return true;
    }
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  WantToSecton(): void {
    this.isRegisteredinPayveris();
  }

  isRegisteredinPayveris(): void {
    this.isRegisteredInPayveris = this.isEligibleForFunding();
  }

  getAccountOperationMessage(message: string) {
    this.messageOperation = message;
    this.statusOperation = true;
    this.successMessage = message;
    this.serviceHelper.scrollToTop();
  }

  getOperationMessage(message: string, status: boolean) {
    this.messageOperation = message;
    this.statusOperation = status;
    this.serviceHelper.scrollToTop();
  }

  /**
   * Get provider info to map with logo
   */
  getProviders() {
    this.providerService
      .getProviders()
      .then(res => {
        this.providers = res.data;
        this.initAccountsDropdown();
      })
      .catch(err => {
        if (err.status !== 404) {
          this.serviceHelper.errorHandler(err);

          return;
        }
      });
  }
  /**
   * Check valid accounts for funding
   * Same method to check if was added to Payveris (aggregation-success.controller)
   */
  isEligibleForFunding(): boolean {
    if (!this.accountDetails.accountNumber) return false;

    const aggregatedAccount = this.cachedAccountsService.externalAccounts.find(
      (item: AggregatedAccount) => item.accountNumber == this.accountDetails.accountNumber
    );

    if (!aggregatedAccount) return false;

    this.externalAccountId = aggregatedAccount.externalAccountId;

    return (
      (this.accountDetails.category == AccountCategory.Dda || this.accountDetails.category == AccountCategory.Sav) &&
      aggregatedAccount.externalAccountId > 0
    );
  }

  onSelect(data: DropdownItem): void {
    let accountType;
    const aggrAccnts = this.cachedAccountsService.aggregatedAccounts.find(a => a.id == data.id);
    if (aggrAccnts && aggrAccnts.isExternal) {
      this.state.go('udb.accounts.external-details', {
        id: data.id,
        container: aggrAccnts.container,
      });
    } else if (data.id === -1 && data.text === this.viewAllAccountText) {
      this.state.go('udb.accounts.dashboard');
    } else {
      accountType = this.getInvestAccountSelectedType(data);

      this.accountRouterHelper.goToAccountDetailPage(data.id, accountType);
    }
  }
  isValidCategory(accountDetails: AggregatedAccount): boolean {
    let isValidCategory = false;

    if (
      accountDetails.category === this.accountCategoryEnum.Sav ||
      accountDetails.category === this.accountCategoryEnum.Dda ||
      accountDetails.category === this.accountCategoryEnum.Loan ||
      accountDetails.category === this.accountCategoryEnum.Credit
    ) {
      isValidCategory = true;
    }

    return isValidCategory;
  }
  private onAggregatedAccountsLoaded() {
    return new Promise<void>(resolve => {
      if (this.cachedAccountsService.aggregatedAccounts) {
        resolve();
      } else {
        this.listeners.push(
          this.rootScope.$on('aggregatedaccountsloaded', () => {
            resolve();
          })
        );
      }
    });
  }

  private onInvestLoaded() {
    return new Promise<void>(resolve => {
      if (this.rootScope['isInvestAccountsLoaded']) {
        this.handleInvestAccounts();
        resolve();
      } else {
        this.listeners.push(
          this.scope.$on('investAccountsLoaded', () => {
            this.handleInvestAccounts();
            resolve();
          })
        );
      }
    });
  }

  private onInternalAccountsLoaded() {
    return new Promise<void>(resolve => {
      if (this.cachedAccountsService.internalAccounts) {
        resolve();
      } else {
        this.listeners.push(
          this.scope.$on('balancesAvailable', () => {
            resolve();
          })
        );
      }
    });
  }

  private transformAccountAggregationName(res: OlbResponse<AggregatedAccount[]>): void {
    res.data.forEach((item: AggregatedAccount) => {
      if (item.id == +this.accountId) this.accountDetails = item;
    });

    this.nickName = this.getNickName(this.accountDetails);
    this.setBankNameMask();
    this.accountType = this.accountDetails.accountType;

    this.scope.$broadcast('accountSelected', this.accountDetails);
    this.loadBalance();
    this.WantToSecton();
    this.detailsRetrieved = true;
  }
  /** Populates the accounts' dropdown  */
  // This will contain only the Aggregation accounts
  private initAccountsDropdown = () => {
    var depositAccounts: AggregatedAccount[] = [];
    var loanAccounts: AggregatedAccount[] = [];
    const aggrAccnts = this.cachedAccountsService.getAggregatedAccntsToDdl();

    if (this.rootScope['balancesAvailable']) {
      depositAccounts =
        this.rootScope['accounts'].depositAccounts != undefined ? this.rootScope['accounts'].depositAccounts : [];
      loanAccounts =
        this.rootScope['accounts'].loanAccounts != undefined ? this.rootScope['accounts'].loanAccounts : [];
    }

    const accountsInDropDown = depositAccounts
      .concat(loanAccounts, this.investAccounts, aggrAccnts)
      .sort((a1: AggregatedAccount, a2: AggregatedAccount) => {
        if (a1.category < a2.category) return -1;
        else if (a1.category > a2.category) return 1;
        else return 0;
      });

    this.dropdownItems = accountsInDropDown.map((a: AggregatedAccount) => {
      const bank = this.providers.find(provider => this.compareNames(a.bankName, provider.providerName));
      const externalIcon = !!bank ? bank.logoName : 'default.svg';
      const icon = a.isExternal
        ? externalIcon
        : `${
            a.accountType === 'invest' || a.accountType === 'trading' ? 'axos-invest' : this.env.brand
          }_round_logo.svg`;
      const accountName = a.isExternal ? (a.nickname ? a.nickname : a.name ? a.name : a.bankName) : a.nickname;
      const accountMask = a.accountMask !== null ? a.accountMask : a.accountNumber;

      return new DropdownItem(
        accountName,
        a.id,
        a.isExternal
          ? `${a.bankName} ${this.maskAccountNumber(accountMask)}`
          : a.accountType.toLocaleLowerCase() === 'invest'
          ? `${AxosInvestHelper.AXOS_INVEST} | ${a.bankName}`
          : a.bankName || `${this.env.brandName} Bank ${this.maskAccountNumber(accountMask)}`,
        false,
        `rounded-logos/${icon}`
      );
    });
    this.dropdownItems.push(this.getViewAllAccountItem());

    this.selectedItem = this.dropdownItems.filter((a: DropdownItem) => {
      return a.id == this.accountId;
    })[0];
  };

  private compareNames(bankName: string, providerName: string) {
    return providerName.localeCompare(bankName, 'en', { sensitivity: 'accent' }) === 0;
  }

  /** Populates the accounts' details, When details are retrived from GetAccountDetails from Details (account-details-agg.controller.ts) */
  private loadBalance(): void {
    switch (this.accountDetails.container) {
      case this.containerTypeEnum.Bank:
        switch (this.accountDetails.category) {
          case this.accountCategoryEnum.Sav: // savings
          case this.accountCategoryEnum.Dda: // checking
            this.displayBalanceLeft = this.accountDetails.availableBalance ?? 0;
            this.displayBalanceMsgLeft = 'Available Balance';
            this.displayBalanceRight = this.accountDetails.currentBalance ?? 0;
            this.displayBalanceMsgRight = 'Total Balance';
            break;
          default:
            this.displayBalanceLeft = this.accountDetails.availableBalance ?? 0;
            this.displayBalanceMsgLeft = 'Available Balance';
            break;
        }
        break;
      case this.containerTypeEnum.CreditCard:
        this.displayBalanceLeft = this.aggregatedAccount.balance;
        this.displayBalanceMsgLeft = 'Current Balance';
        this.tooltipDescriptionLeft = 'The amount you owe on your account, minus any pending purchases or payments.';
        this.displayBalanceRight = this.accountDetails.amountDue ?? 0;
        this.displayBalanceMsgRight = 'Amount Due';
        this.tooltipDescriptionRight = 'The amount payable on your credit card at the next due date.';
        break;
      case this.containerTypeEnum.Investment:
        this.displayBalanceLeft = this.aggregatedAccount.balance;
        this.displayBalanceMsgLeft = 'Total Balance';
        this.tooltipDescriptionLeft = 'Current balance in your account';
        break;
      case this.containerTypeEnum.Loan:
        this.displayBalanceLeft =
          this.aggregatedAccount.outstandingBalance != null
            ? this.aggregatedAccount.outstandingBalance.amount
            : this.aggregatedAccount.balance;
        this.displayBalanceMsgLeft = 'Outstanding Balance';
        this.tooltipDescriptionLeft = 'The amount remaining on your account to pay off your loan.';
        this.displayBalanceRight = this.aggregatedAccount.amountDue ?? 0;
        this.displayBalanceMsgRight = 'Amount Due';
        this.tooltipDescriptionRight = 'The amount payable on your loan at the next due date.';
        break;
    }
    this.detailsRetrieved = true;
  }

  private getNickName(account: AggregatedAccount): string {
    return account.nickname || account.name;
  }

  private setBankNameMask(): void {
    let lastDigits = !!this.accountDetails.accountMask
      ? this.accountDetails.accountMask
      : this.accountDetails.accountNumber;
    lastDigits = this.maskAccountNumber(lastDigits);
    this.bankNameMask = `${this.accountDetails.bankName} ${lastDigits}`;
  }

  private getViewAllAccountItem(): DropdownItem {
    return {
      id: -1,
      text: this.viewAllAccountText,
    };
  }

  private getInvestAccountSelectedType(data: DropdownItem): AccountType {
    let accountType;

    if (data.text === AxosInvestHelper.MANAGED_PORTFOLIO) {
      accountType = AccountType.AxosInvest;
    }

    if (this.investAccounts.some(a => a.id === data.id)) {
      accountType = AccountType.AxosTrading;
    }

    return accountType;
  }

  private maskAccountNumber(accountNumber: string): string {
    if (!accountNumber || accountNumber.length < 4) return '';

    return `*${accountNumber.substring(accountNumber.length - 4)}`;
  }

  private handleInvestAccounts(): void {
    const activeAxosInvest = this.featureFlagService.isAxosInvestActive();
    if (this.axosClearingService.isAxosTradingActiveForUser()) {
      this.handleAxosTradingAccounts();
    }

    if (activeAxosInvest && this.axosInvestHelper.hasAxosInvest) {
      this.handleAxosInvestAccount();
    }
  }

  private handleAxosTradingAccounts(): void {
    if (this.cachedTradingAccountsService.isTradingAccountsLoaded) {
      this.investAccounts.push(...this.cachedTradingAccountsService.tradingAccounts);
    }
  }

  private handleAxosInvestAccount(): void {
    if (this.rootScope['axosInvestAccounts']) {
      const investAccount: OlbAccount = this.rootScope['axosInvestAccounts'];
      this.investAccounts.push(investAccount);
    }
  }
}
