import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { MilestoneSummary } from '@app/axos-invest/models';
import { AxosInvestUrlsService } from '@app/axos-invest/services';
import { getGoalSummary } from '@app/axos-invest/store/selectors';
import { olbSettings } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  $accountSummary: Observable<MilestoneSummary>;
  iconPlusPrimary = UtilityIcons.Plus;
  isFeatureActive: boolean;

  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    private store: Store,
    private featureFlagService: FeatureFlagService,
    private axosInvestUrlHelper: AxosInvestUrlsService
  ) {}

  ngOnInit(): void {
    this.getMilestoneSummary();
    this.isFeatureActive = this.featureFlagService.isManagedPortfoliosEnabled();
  }

  addNewGoal() {
    const urlPromise: any = this.isFeatureActive
      ? of(`${this.settings.enrollmentInvestUrl}?products[]=MPO`).toPromise()
      : this.axosInvestUrlHelper.getCreateMilestoneUrl();
    urlPromise.then(url => {
      window.open(url, '_blank', 'noopener noreferrer');
    });
  }

  private getMilestoneSummary() {
    this.$accountSummary = this.store.select(getGoalSummary).pipe(
      filter(state => state.isLoaded),
      take(1)
    );
  }
}
