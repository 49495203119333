<div class="main">
  <div class="main-container">
    <div class="logos-container">
      <div>
        <ng-lottie
          [width]="'200px'"
          [height]="'200px'"
          [options]="options"
        ></ng-lottie>
      </div>
    </div>
    <div class="text-container">
      <div class="text-title">
        Share your accounts with your advisor
      </div>
      <div class="text">
        Share your account details with your advisor for a more personalized
        financial plan. You can also authorize your advisor to transfer funds on
        your behalf.
      </div>
      <div class="text authorizing-text" *ngIf="shouldDisplayTooltip">
        Authorizing fund transfers
        <uk2-tooltip
          [size]="sizeIcon"
          [displayCloseButton]="false"
          [arrowOffset]="0"
          [svgIconName]="svgIconName"
          [placement]="placement"
          [desktopOpenMode]="desktopOpenMode"
          [strategy]="strategy"
        >
          Authorizing transfers grants your advisor the ability to move funds
          between the selected account and your advisor-managed account.
          <br />
          <br />
          <strong>Note:</strong>
          This feature is exclusively available for deposit accounts like
          checking and savings.
        </uk2-tooltip>
      </div>
      <div class="row col-12 note-card">
        <mat-card uk2NoteCard>
          <mat-card-content>
            <label>
              <strong>
                Please Note:
              </strong>
              You can change these settings anytime within each account details
              page
            </label>
          </mat-card-content>
        </mat-card>
      </div>
      <form [formGroup]="formAccount" (ngSubmit)="submit()">
        <div class="principal-initial-container">
          <div *ngFor="let account of accounts">
            <div
              class="initial-container"
              [class.principal-initial-container-colored]="
                account?.isChecked === true
              "
            >
              <div class="row">
                <diV class="col-9 offset-1 container-text-middle">
                  <img
                    [src]="getImage(account)"
                    class="sm-item-container__image"
                    aria-hidden="true"
                  />
                  <div class="sm-item-container__container-text">
                    <div
                      class="sm-item-container__text"
                      [ngClass]="{
                        'account-color': account?.isChecked === true
                      }"
                    >
                      {{ account.institutionName }}
                    </div>
                    <div
                      class="sm-item-container__productname"
                      [ngClass]="{
                        'account-color': account?.isChecked === true
                      }"
                    >
                      {{ getProductName(account) }}
                    </div>
                  </div>
                </diV>
                <diV class="col-1">
                  <div class="uk2-checkbox-container">
                    <mat-checkbox
                      uk2Checkbox
                      [checked]="account.isDisplayed"
                      [indeterminate]="someComplete(account)"
                      (change)="selectedEntireAccount(account, $event.checked)"
                    ></mat-checkbox>
                  </div>
                </diV>
                <div
                  *ngIf="account?.isChecked"
                  class="col-10 dotted-line"
                ></div>
              </div>
              <div>
                <div *ngIf="account?.isChecked">
                  <div *ngFor="let advisor of account.advisors; index as i">
                    <div class="row">
                      <div class="col-9 offset-2">
                        <diV class="checkbox-aas-account aas-accounts-line">
                          <div class="uk2-checkbox-container">
                            <img
                              [src]="getAasImage(advisor)"
                              class="image-aas-account"
                              aria-hidden="true"
                            />
                            <span class="aasaccount-displayname">
                              {{ advisor.advisorName }}
                            </span>
                          </div>
                        </diV>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="validateTransferOnly(account.accountType)"
                    >
                      <diV class="col-8 offset-2 container-text-middle">
                        <div class="uk2-checkbox-container">
                          <span class="aas-container-text">
                            Share account details
                          </span>
                        </div>
                      </diV>
                      <div class="col-1 uk2-checkbox-container">
                        <mat-checkbox
                          uk2Checkbox
                          [checked]="advisor.sharedAccount"
                          (change)="updateShared(account, i, $event.checked)"
                        ></mat-checkbox>
                      </div>
                    </div>
                    <div
                      class="row mb-3"
                      *ngIf="isDifferentFromLoanAccount(account.accountSubType)"
                    >
                      <diV class="col-8 offset-2 container-text-middle">
                        <div class="uk2-checkbox-container">
                          <span class="aas-container-text">
                            Authorize transfers
                          </span>
                        </div>
                      </diV>
                      <div class="col-1 uk2-checkbox-container">
                        <mat-checkbox
                          uk2Checkbox
                          [checked]="advisor.authorizedTransfer"
                          (change)="
                            updateAuthorized(account, i, $event.checked)
                          "
                        ></mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="principal-buttons">
          <button
            type="submit"
            uk2PrimaryButton
            disableRipple
            mat-raised-button
            [uk2ButtonSize]="btnShareAccount"
            [disabled]="!formAccount.valid"
          >
            Share Accounts
          </button>
        </div>
        <div class="skip-button">
          <a
            uk2Anchor
            [ngClass]="formAccount.valid ? 'skip-now-disabled' : 'skip-now'"
            (click)="skipClick()"
          >
            Skip for now
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
