import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, retry } from 'rxjs/operators';

import {
  AASAccountDataRequest,
  AccountDetailsResponse,
  AccountDetailsService,
  AccountFirmContactsStateType,
  AccountInformationStateType,
  getSelectedAccountFirmContacts,
  getSelectedAccountInformation,
  getSelectedAccountTypeAndAccountNumber,
  loadAccountFirmContactsSuccessAction,
  loadAccountInformationAction,
  setSelectedAccountDetailsAction,
} from '../core';
import { RIAContactsService } from '@app/Areas/AAS/aas-shared/services/contacts-service';
import { EditNicknameInputType, EditNicknameOutputType, InitializeAccountDetailsInput } from './types';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { updateAasAccountDataAction } from '@app/axos-advisory/store/actions';
import { ServiceResult } from '@shared/models';
import { updateAasAccountNicknameAction } from '../../product-details-page/core';
import { updateContextualTopBarStatePageTitle } from '../../product-details-page/layout/core';


@Injectable({
  providedIn: 'root',
})
export class AccountDetailsFacade {
  accountInformation$ = this.store.select(getSelectedAccountInformation);
  accountFirmContacts$ = this.store.select(getSelectedAccountFirmContacts);
  defaultNicknameFormat: string;
  defaultPdpNicknameFormat: string;

  private selectedAccountTypeAndAccountNumber = this.store.select(getSelectedAccountTypeAndAccountNumber);

  private DEFAULT_ERROR_MESSAGE = 'Account Nickname failed to update. Please try again.';

  constructor(
    private store: Store,
    private accountDetailsService: AccountDetailsService,
    private contactsService: RIAContactsService,
    private featureFlagService: FeatureFlagService,
  ) {
    // Selector to get Account Type and Account Number and format default nicknames
    this.selectedAccountTypeAndAccountNumber.pipe(
      filter(({accountType, accountNumber}) => !!accountType &&!!accountNumber)
    ).subscribe(({accountType, accountNumber}) => {
      // Create default nicknames
      this.defaultNicknameFormat = `${accountType} **${this.getLast4Characters(accountNumber)}`;
      this.defaultPdpNicknameFormat = `${accountType} - ${this.getLast4Characters(accountNumber)}`
    });
  }

  initializeAccountDetails(input: InitializeAccountDetailsInput): void {
    this.loadAccountInformation(input.accountNumber);
    if (this.featureFlagService.isRiaPilotFirmContactsActive()) this.loadAccountFirmContacts(input.accountNumber);
    this.store.dispatch(setSelectedAccountDetailsAction({ payload: input.accountNumber }));
  }

  editNickname(editNicknameInputType: EditNicknameInputType): Observable<EditNicknameOutputType> {
    let aasAccountDataRequest: AASAccountDataRequest = {
      ...editNicknameInputType,
    };

    return this.accountDetailsService.updateAasAccountData(aasAccountDataRequest).pipe(
      map(response => {
        if (response.statusCode === 200) {

          // Create a sanitized nickname for contextual top bar title
          const nicknameSanitized = this.cleanNickname(aasAccountDataRequest.accountNickname, aasAccountDataRequest.accountNumber)

          let contextualTopBarTitle = `${nicknameSanitized} - ${this.getLast4Characters(aasAccountDataRequest.accountNumber)}`;
          let selectedAasAccountNickName = aasAccountDataRequest.accountNickname;

          // If the account nickname is empty, dispatch it to the state with default passwords to sync app state
          if (!aasAccountDataRequest.accountNickname) {
            aasAccountDataRequest.accountNickname = this.defaultNicknameFormat;
            selectedAasAccountNickName = this.defaultPdpNicknameFormat;
            contextualTopBarTitle = this.defaultPdpNicknameFormat;
          }

          this.store.dispatch(updateAasAccountDataAction({ payload: aasAccountDataRequest }));
          this.store.dispatch(updateAasAccountNicknameAction({ payload: { accountNumber: aasAccountDataRequest.accountNumber, accountNickname: selectedAasAccountNickName }}));
          this.store.dispatch(updateContextualTopBarStatePageTitle({ payload: contextualTopBarTitle }));
        }
        return this.handleSuccessEditNicknameResponse(response.statusCode, response.data);
      }),
      catchError((error: HttpErrorResponse) => this.handleFailedEditNicknameResponse(error))
    );
  }

  private loadAccountInformation(accountNumber: string): void {
    this.accountDetailsService
      .getAccountDetails({ accountNumber: accountNumber })
      .pipe(retry(3))
      .subscribe({
        next: (response: ServiceResult<AccountDetailsResponse>) => {
          const stateItem: AccountInformationStateType = {
            accountNumber: accountNumber,
            profile: response.data?.profile,
            contributions: response.data?.contributions,
            requiredMinimumDistributions: response.data?.requiredMinimumDistribution,
            error: undefined,
          };

          this.store.dispatch(loadAccountInformationAction({ payload: stateItem }));
        },
        error: error => {
          const stateItem: AccountInformationStateType = {
            accountNumber: accountNumber,
            profile: undefined,
            contributions: undefined,
            requiredMinimumDistributions: undefined,
            error: error,
          };

          this.store.dispatch(loadAccountInformationAction({ payload: stateItem }));
        },
      });
  }

  private loadAccountFirmContacts(accountNumber: string): void {
    this.contactsService
      .getFirmContactsByAccount({ accountNumber })
      .pipe(retry(3))
      .subscribe({
        next: response => {
          // if (response.statusCode == HttpStatusCode.Ok) {
          const accountFirmContacts: AccountFirmContactsStateType = {
            ...response.data,
            accountNumber,
            error: undefined,
          };
          this.store.dispatch(loadAccountFirmContactsSuccessAction({ payload: accountFirmContacts }));
          // } else {
          //   this.store.dispatch(loadAccountFirmContactsFailureAction());
          // }
        },
        error: error => {
          // this.store.dispatch(loadAccountFirmContactsFailureAction());
          const accountFirmContacts: AccountFirmContactsStateType = {
            accountNumber: accountNumber,
            primaryAdvisor: undefined,
            advisors: undefined,
            riaFirm: undefined,
            error: error,
          };
          this.store.dispatch(loadAccountFirmContactsSuccessAction({ payload: accountFirmContacts }));
        },
      });
  }

  private handleSuccessEditNicknameResponse(statusCode: number, message: string): EditNicknameOutputType {
    const editNickname: EditNicknameOutputType = {
      apiCallWasSuccessful: statusCode === 200,
      nicknameChangedSuccessfully: statusCode === 200 && message === 'Success! Your Account Nickname is updated.',
      messageToDisplay: message,
    };
    return editNickname;
  }

  private handleFailedEditNicknameResponse(errorResponse: HttpErrorResponse): Observable<EditNicknameOutputType> {
    let successCall = errorResponse.error.statusCode <= 500;

    const errorOutput: EditNicknameOutputType = {
      apiCallWasSuccessful: successCall,
      nicknameChangedSuccessfully: false,
      messageToDisplay: this.DEFAULT_ERROR_MESSAGE,
    };

    return of(errorOutput);
  }

  private getLast4Characters(s: string): string {
    if (s.length <= 4) return s;
    return s.substring(s.length - 4, s.length);
  }

  // Trim and remove last 4 account numbers and double asterisk from nickname
  private cleanNickname(nickname: string, accountNumber: string): string {
    nickname = nickname.replace(this.getLast4Characters(accountNumber), '');
    nickname = nickname.replace('**', '');
    nickname = nickname.trim();
    return nickname;
  }
}
