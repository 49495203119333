import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  MilestoneTransfer,
  MilestoneTransferResponse,
  ScheduledTransfer,
  ScheduledTransferRequest,
  Transfer,
  TransferResponse,
} from '@app/axos-invest/models';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable()
export class AxosInvestTransferService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'transfers/invest');
  }

  getScheduledTransfers() {
    return this.get<ScheduledTransfer[]>('schedule');
  }

  createScheduledTransfer(transfer: ScheduledTransferRequest, isExternal: boolean) {
    let params = new HttpParams();
    if (isExternal) {
      params = params.set('isExternal', 'true');
    }

    return this.post<ScheduledTransfer>('schedule', transfer, null, params);
  }

  createTransfer(transaction: Transfer, isExternal?: boolean) {
    let params = new HttpParams();
    if (isExternal) {
      params = params.set('isExternal', 'true');
    }

    return this.post<TransferResponse>(null, transaction, null, params);
  }

  createMilestoneTransfer(transfer: MilestoneTransfer) {
    return this.post<MilestoneTransferResponse>('milestone', transfer);
  }
}
