import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExchangeAgreement } from '@app/axos-trading/models';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class ExchangeAgreementService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'trading');
  }

  getExchangeAgreement(): Observable<ExchangeAgreement[]> {
    return this.get<ExchangeAgreement[]>(`agreements?options=true`).pipe(map(result => result.data));
  }
}
