import { Component, Input } from '@angular/core';

import { SendUntil } from '@app/axos-invest/enums';
import { ScheduledTransfer } from '@app/axos-invest/models';

@Component({
  selector: 'app-scheduled-transfer-card',
  templateUrl: './scheduled-transfer-card.component.html',
  styleUrls: ['./scheduled-transfer-card.component.scss'],
})
export class ScheduledTransferCardComponent {
  @Input() isRetirement: boolean;
  @Input() scheduledTransfer: ScheduledTransfer;
  @Input() depositName: string;
  sendUntil = SendUntil;
}
