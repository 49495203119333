<div class="account-unlock">
  <app-toolbar></app-toolbar>
  <banner-component></banner-component>
  <div class="container account-unlock__content">
    <div class="d-flex flex-wrap justify-content-center">
      <div class="col-12 col-md-7 pt-3">
        <app-dot-loader [showWhen]="isBusy">Loading</app-dot-loader>
        <h3 *ngIf="displayInvalidLinkMessage" class="mt-3 invalid-link-message">
          <ng-container *ngIf="stepMessage; else genericError">{{ stepMessage }}</ng-container>
          <ng-template #genericError>
            In order to login to online banking, we must first verify your contact information. Please call customer
            service at {{ $supportPhone | async }}
          </ng-template>
        </h3>

        <ng-container *ngIf="displayOTP">
          <app-multifactor
            *ngIf="loginState == 1"
            [supportPhone]="$supportPhone | async"
            (sendCode)="codeSent($event)"
          ></app-multifactor>
          <app-access-code
            *ngIf="loginState == 2"
            [errorMessage]="message"
            (continue)="codeValidated($event.code, $event.remember)"
            [otp]="otp"
            [displayRememberDevice]="false"
          ></app-access-code>
        </ng-container>

        <ng-container *ngIf="displayTermsAndConditions">
          <app-terms-and-conditions [isLoading]="isLoading" (agree)="agreeTerms()"></app-terms-and-conditions>
        </ng-container>

        <ng-container *ngIf="displayQuestion">
          <app-alert-notification *ngIf="showAlert" alertType="error" (dismiss)="hideAlert()">
            The answer you provided is incorrect. Please try again.
          </app-alert-notification>
          <form
            #formAccountUnlockQuestion="ngForm"
            name="form"
            (ngSubmit)="checkSecurityQuestionAnswer()"
            class="d-block"
          >
            <h3 class="mb-3 mt-3">Security Question</h3>
            <p class="mb-3" [innerText]="stepMessage"></p>
            <p class="mb-3">{{ question }}?</p>

            <label for="answer-txt">Answer</label>
            <input
              type="text"
              id="answer-txt"
              name="answer-txt"
              class="uk-input"
              aria-label="Answer"
              [(ngModel)]="answer"
              required
              #answerInput="ngModel"
            />
            <div class="text--error" *ngIf="answerInput.invalid && (answerInput.touched || questionSubmitted)">
              Security Answer is a required field.
            </div>
            <div class="d-flex flex-column align-items-center mt-3">
              <button type="submit" class="btn-ok button--lg mb-3" id="send-answer-sq">
                Continue
              </button>

              <app-dot-loader [showWhen]="isCheckingAnswer">Validating</app-dot-loader>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="displaySetNewPassword">
          <form #formAccountUnlockNewPassword="ngForm" name="form" (ngSubmit)="setNewPassword()" class="d-block">
            <h3 class="mb-3 mt-3">Please enter your new password.</h3>
            <p class="mb-3">Make sure your new password meets the strength requirements.</p>
            <app-password-field
              name="password"
              labelText="Password"
              [(value)]="password"
              (inputChange)="setNewPasswordValidity($event)"
              [maxLength]="25"
            ></app-password-field>
            <div class="d-flex flex-column align-items-center">
              <button
                type="submit"
                class="btn-ok button--lg mt-3 mb-3"
                [disabled]="!isNewPasswordValid"
                id="save-new-password"
              >
                Save Password
              </button>
              <app-dot-loader [showWhen]="isSavingNewPassword">Loading</app-dot-loader>
            </div>
          </form>
        </ng-container>
      </div>
      <div *ngIf="displayQuestions && !isBusy">
        <app-security-questions
          [username]="userName"
          [resetPasswordToken]="token"
          (save)="goToSetNewPassword()"
          (accountRequestToken)="handleSaveSecurityQuestionToken($event)"
        ></app-security-questions>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
