<section id="step-1">
  <div class="step-top-actions"></div>
  <div class="step-content">
    <div>
      <ng-lottie
        id="body-animation"
        [width]="'200px'"
        [height]="'200px'"
        [options]="checkingAccountAnimationOptions"
      ></ng-lottie>
      <h1 id="investorTitle" class="dialog-header-title">
        {{ accountModalTexts.CashManagementTitle }}
      </h1>
      <p id="investorBody" class="investor-body">
        {{ accountModalTexts.CashManagementBody }}
      </p>
      <p id="investorBody2" class="investor-body">
        {{ accountModalTexts.CashManagementBody2 }}
      </p>
    </div>
    <div class="dialog-accordion">
      <mat-icon
        class="mr-1 accordion-icon-2"
        [svgIcon]="uk2Tier1AlertsEnum.questionCircle"
        (click)="toggleChevron()"
      ></mat-icon>
      <p id="investor-accordion-title" class="accordion-title">
        {{ accountModalTexts.CashManagementAccordionTitle }}
      </p>
      <mat-icon
        class="accordion-icon"
        [svgIcon]="
          openedAccordion
            ? uk2Tier1NavigationEnum.chevronUp
            : uk2Tier1NavigationEnum.chevronDown
        "
        (click)="toggleChevron()"
      ></mat-icon>
      <span
        id="investor-accordion-body"
        class="accordion-body"
        *ngIf="openedAccordion"
      >
        {{ accountModalTexts.CashManagementAccordionBody }}
      </span>
    </div>
  </div>
  <div class="step-bottom-actions">
    <mat-divider></mat-divider>
    <button
      id="btnSubmit"
      uk2PrimaryButton
      disableRipple
      mat-raised-button
      cdkStepperNext
    >
      Complete Account Setup
    </button>
  </div>
</section>
