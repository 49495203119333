<div class="inbox container">
  <div class="header-section-title text-center">
    <div>
      <h1>Message Center</h1>
      <p class="hide-cs hide-xs" id="padding-top-account">
        {{ subheader }}
      </p>
    </div>
  </div>
  <hr class="hidden-md hidden-sm hidden-lg" />
  <div class="shortcuts" *ngIf="!isLoading">
    <a (click)="goComposeMessageView()" *ngIf="!showActions">
      <img src="/assets/inbox/message-center/Compose_Message.svg" alt="Compose a message" />
      Compose
      <span class="hidden-xs">a message</span>
    </a>
  </div>
  <div>
    <app-dot-loader [showWhen]="isLoading">Loading Messages</app-dot-loader>
  </div>

  <div class="messages-table" *ngIf="!isLoading && messagesResponse.length > 0">
    <!-- mobile -->
    <div class="mobile hidden-sm hidden-md hidden-lg">
      <div
        class="mobile-items"
        *ngFor="let message of filterInboxMessages(messagesResponse, filterRange)"
        [ngClass]="{ 'new-message': !message.hasBeenRead }"
      >
        <div *ngIf="isEditing" class="to-edit-checkbox">
          <input
            type="checkbox"
            [id]="'check-' + message.id"
            ng-model="message.checked"
            (click)="checkIfShowActions()"
          />
          <label [for]="'check-' + message.id" class="middle"></label>
        </div>
        <div class="content-message" [ngClass]="{ 'is-editing-text': !isEditing }">
          <p class="title">
            <span *ngIf="!message.hasBeenRead" class="circle-is-new"></span>
            {{ message.subject }}
          </p>
          <p class="date">
            {{ message.lastActivityDate | date: 'MM/dd/yyyy' }}
          </p>
          <p class="description">
            {{ message.summary }}
          </p>
        </div>
        <div class="arrow">
          <a (click)="messageDetail(message.id, message.createdDate, message.status, message.hasBeenRead)">
            <i class="bofi-arrow-chevron"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- tablet and desktop -->
    <table class="hidden-xs">
      <thead>
        <tr>
          <th class="select-checkbox"></th>
          <th *ngFor="let header of headers">
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="repeat-message"
          (click)="messageDetail(message.id, message.createdDate, message.status, message.hasBeenRead)"
          *ngFor="let message of filterInboxMessages(messagesResponse, filterRange)"
          [ngClass]="{ 'new-message': !message.hasBeenRead }"
        >
          <td class="select-checkbox text-center"></td>
          <td class="truncate">
            <span *ngIf="!message.hasBeenRead" class="circle-is-new"></span>
            <span class="title">
              {{ message.subject }}
            </span>
          </td>
          <td class="truncate">
            <span>
              {{ message.summary }}
            </span>
          </td>
          <td class="truncate">
            <span>
              {{ message.lastActivityDate | date: 'MM/dd/yyyy @ hh:mm a' }}
            </span>
          </td>
          <td class="truncate">
            <span>
              {{ message.status }}
            </span>
          </td>
        </tr>
        <tr *ngIf="messagesResponse.length === 0">
          <td class="text-center" colspan="7">No history information has been found.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center" *ngIf="!isLoading && messagesResponse.length === 0">
    <img
      alt="no messages"
      class="img-message"
      [src]="'/assets/inbox/message-center/' + brandName + '/No_Messages_Icon.svg'"
    />
    <h3>You have no messages.</h3>
  </div>
  <div class="text-center" *ngIf="messagesResponse.length > 0">
    <axos-legacy-paginator
      [itemsPerPage]="pageSize"
      [listLength]="messagesResponse.length"
      [visibleSelectablePages]="5"
      [(renderRange)]="filterRange"
    ></axos-legacy-paginator>
  </div>
</div>
