import { CommonModule } from '@angular/common';
import { StatementsAndDocumentsModule } from './../statements-and-documents/statements-and-documents.module';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { AasSharedModule } from '../../aas-shared/aas-shared.module';
import { AccountDetailsModule } from '../account-details/account-details.module';
import { TotalValueModule } from '../total-value/total-value.module';
import { ModelsHoldingsTabModule } from '../models-holdings-tab/models-holdings-tab.module';
import { initialPdpState, pdpReducer } from './core/store';
import { ProductDetailsPageMatModule } from './product-details-page-mat.module';
import { ProductDetailsPageUk2Module } from './product-details-page-uk2.module';
import { AvailableCashModule } from '../available-cash/available-cash.module';
import { ProductDetailsPageComponent, QuoteTimestampComponent } from './view';
import { TransactionsModule } from '../transactions/transactions.module';
import { DelayedQuotesModule } from '../delayed-quotes/delayed-quotes.module';
import { TransactionsOrdersScheduledTabModule } from '../transactions-orders-scheduled-tab/transactions-orders-scheduled-tab.module';
import { ScheduledTransfersModule } from '../scheduled-transfers/scheduled-transfers.module';
import { OrdersModule } from '../orders/orders.module';
import { PortfolioAllocationTileComponent } from '../portfolio-allocation/view/portfolio-allocation-tile/portfolio-allocation-tile.component';
import { DonutChartComponent } from '../portfolio-allocation/view/donut-chart/donut-chart.component';
import { SharedModule } from '@shared/shared.module';
import { MaintenanceInProgressModule } from '../maintenance-in-progress';

@NgModule({
  declarations: [
    ProductDetailsPageComponent,
    QuoteTimestampComponent,
    PortfolioAllocationTileComponent,
    DonutChartComponent,
  ],
  imports: [
    CommonModule,
    ModelsHoldingsTabModule,
    TransactionsOrdersScheduledTabModule,
    AvailableCashModule,
    ProductDetailsPageMatModule,
    ProductDetailsPageUk2Module,
    StoreModule.forFeature(AasStoreFeatures.ProductDetailsPage, pdpReducer, {
      initialState: initialPdpState,
    }),
    AasSharedModule,
    AccountDetailsModule,
    TotalValueModule,
    StatementsAndDocumentsModule,
    TransactionsModule,
    DelayedQuotesModule,
    ScheduledTransfersModule,
    OrdersModule,
    SharedModule,
    MaintenanceInProgressModule,
  ],
  exports: [ProductDetailsPageComponent],
})
export class ProductDetailsPageModule {}
