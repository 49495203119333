import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import {
  BrokerageFormComponent,
  FundingComponent,
  FundingMethodComponent,
  FundingReceiptComponent,
  FundingTermsComponent,
  MailCheckComponent,
  WireTransferComponent,
} from '@app/funding/components';
import { Store } from '@ngrx/store';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { turnOffFunding } from '@app/store/funding/funding.actions';
import NgRedux from 'ng-redux';
import { MakeDepositViewComponent } from '@app/make-deposit/views';
import { AddExternalAccountController } from '@legacy/accounts/external-accounts/add-external-account/add-external-account.controller';

export const fundingLegacyModule = angular
  .module('udb.funding', ['ui.router'])
  .directive('appBrokerageForm', downgradeComponent({ component: BrokerageFormComponent }))
  .directive('appFundingFunds', downgradeComponent({ component: FundingComponent }))
  .directive('appWireTransfer', downgradeComponent({ component: WireTransferComponent }))
  .directive('appMakeDepositView', downgradeComponent({ component: MakeDepositViewComponent }))
  .directive('appFundingMethod', downgradeComponent({ component: FundingMethodComponent }))
  .directive('appFundingReceipt', downgradeComponent({ component: FundingReceiptComponent }))
  .directive('appFundingTerms', downgradeComponent({ component: FundingTermsComponent }))
  .directive('appMailCheck', downgradeComponent({ component: MailCheckComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider
        .state('udb.funding', {
          url: '/Funding?flow',
          views: {
            layout: {
              template: '<div ui-view="funding-content"><app-funding-funds></app-funding-funds></div>',
            },
          },
          resolve: {
            accounts: [
              '$ngRedux',
              'appSettings',
              'ngrxStore',
              (ngRedux: NgRedux.INgRedux, appSettings: AppSetting[], store: Store): any => {
                const { accountsToBeFunded, fundingAccounts } = ngRedux.getState().funding;

                if (
                  (accountsToBeFunded && accountsToBeFunded.length) ||
                  (fundingAccounts &&
                    fundingAccounts.length &&
                    appSettings.find((ap: any) => ap.name === 'ActiveFunding').value === 'true')
                ) {
                  return accountsToBeFunded || fundingAccounts;
                }
                ngRedux.dispatch(FundingActions.turnOffFunding());
                store.dispatch(turnOffFunding());

                return undefined;
              },
            ],
          },
        })
        .state('udb.funding.wire-transfer', {
          url: '/WireTransfer',
          views: {
            'funding-content': {
              template: '<app-wire-transfer></app-wire-transfer>',
            },
          },
        })
        .state('udb.funding.receipt', {
          url: '/Receipt',
          params: {
            settings: null,
          },
          views: {
            'funding-content': {
              template: `<app-funding-receipt></app-funding-receipt>`,
            },
          },
        })
        .state('udb.funding.remote-deposit', {
          url: '/RemoteDeposit',
          views: {
            'funding-content': {
              template: '<app-make-deposit-view></app-make-deposit-view>',
            },
          },
        })
        .state('udb.funding.external-transfer', {
          url: '/ExternalTransfer',
          params: {
            isMoveMoneyFlow: false,
          },
          views: {
            'funding-content': {
              template: `
              <app-funding-shared-header></app-funding-shared-header>
                <div class="external-transfer">
                <div class="header-section-title text-center">
                    <div>
                      <h1 class="text-center">Review Deposit</h1>
                    </div>
                  </div>
                  <div class="frow center">
                    <div class="fcol-cs-12 fcol-sm-8 fcol-md-6">
                      <udb-transfer-fund></udb-transfer-fund>
                    </div>
                  </div>
                </div>`,
              controllerAs: '$et',
            },
          },
        })
        .state('udb.funding.add-external-account', {
          url: '/AddExternalAccount',
          views: {
            'funding-content': {
              templateUrl: 'accounts/external-accounts/add-external-account/add-external-account.tpl.html',
              controller: AddExternalAccountController,
              controllerAs: '$aea',
            },
          },
        })
        .state('udb.funding.mail-check', {
          url: '/MailCheck',
          views: {
            'funding-content': {
              template: '<app-mail-check></app-mail-check>',
            },
          },
        })
        .state('udb.funding.brokerage-form', {
          url: '/BrokerageForm',
          views: {
            'funding-content': {
              template: '<app-brokerage-form></app-brokerage-form>',
            },
          },
        });
    },
  ]).name;
