<div class="axos-info-container">
  <div class="d-flex align-items-center flex-column">
    <app-dot-loader class="info-loader" *ngIf="!goal; else content">
      Loading Account Details...
    </app-dot-loader>
    <ng-template #content>
      <h1 class="title" [innerText]="title"></h1>
      <p class="goal">Investment Goal: {{ goal.name }}</p>
    </ng-template>
  </div>
</div>
<app-modify-confirmation-banner
  *ngIf="showModifiedBanner"
  [bannerText]="modifiedBannerText"
  [duration]="10000"
  (dismissBannerEvent)="showModifiedBanner = false"
></app-modify-confirmation-banner>
<app-error-banner [bannerText]="textErrorBanner" *ngIf="displayErrorBanner"></app-error-banner>
<div class="row container mx-auto p-0">
  <ng-container *ngIf="!isLoading">
    <div class="col-lg-3 col-md-2">
      <div class="go-back">
        <button class="go-back-btn" id="goBack" (click)="goBack.emit()">
          <app-svg-icon class="go-back-btn-icon" [iconName]="backIcon" size="1.15em"></app-svg-icon>
          <span class="go-back-btn-label">BACK</span>
        </button>
      </div>
    </div>
    <div class="col-lg-6 col-md-8">
      <div class="instruction text-center">
        <p>{{ subtitle1 }}</p>
        <p class="subtext">{{ subtitle2 }}</p>
      </div>
    </div>
  </ng-container>
</div>
<app-dot-loader class="info-loader" *ngIf="isLoading"></app-dot-loader>
