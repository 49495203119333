export class UrlQueryParams extends Map<string, string> {
  constructor(urlQueryString: string = window.location.search) {
    super();

    urlQueryString
      .replace('?', '')
      .split('&')
      .forEach(current => {
        const [key, value] = current.split('=');

        this.set(key, value);
      });
  }
}
