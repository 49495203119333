import { Action, createReducer, on } from '@ngrx/store';

import { CategorizedAccounts } from '@app/accounts/models';
import { categorizeAccounts } from '@app/accounts/utils';
import { signOut } from '@app/store/actions';

import { loadAggregatedAccounts } from './aggregated-accounts.actions';

export const aggregatedAccountsInitialState = new CategorizedAccounts();

const _reducer = createReducer(
  aggregatedAccountsInitialState,
  on(loadAggregatedAccounts, (state, action) => {
    const categorisedAccounts = categorizeAccounts(state, action.payload);

    return { ...state, ...categorisedAccounts };
  }),
  on(signOut, () => {
    return new CategorizedAccounts();
  })
);

export function aggregatedAccountsReducer(state: CategorizedAccounts, action: Action) {
  return _reducer(state, action);
}
