import { AggregatedAccount } from 'typings/app/account-aggregation/AggregatedAccount';
import { FundingState } from 'typings/app/FundingState';

import { AccountsReducer } from './accounts/index';
import { FundingReducer } from './funding/funding.reducer';

import { combineReducers } from 'redux';

/**
 * Merges all the reducers into the root one.
 * This is the place for all the reducers like settings for instance
 */
export const RootReducer = combineReducers({
  accounts: AccountsReducer,
  funding: FundingReducer,
});

/** Defines the structure of our application state/store */
export interface ApplicationState {
  accounts?: StoredAccounts;
  funding?: FundingState;
}

/** Defines the structure of the accounts model in the store */
export interface StoredAccounts {
  internal?: TypesAccounts;
  external?: Array<ExternalAccount>;
  aggregated?: Array<AggregatedAccount>;
}
