import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aas-data-tile-section-list',
  templateUrl: './data-tile-section-list.component.html',
  styleUrls: ['./data-tile-section-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTileSectionListComponent {
  @Input() header: string = '';
}
