<div class="pay-confirmation">
  <div class="header-section-title text-center">
    <div>
      <h1>
        <img src="/img/icons/billPay/checkmark.svg" width="100" alt="Favorite Recipient" aria-hidden="true" />
      </h1>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-md-push-2 text-center">
        <h4>
          Payment
          <span *ngIf="payments && payments.length > 1">s</span>
          Scheduled.
        </h4>
        <div *ngFor="let payment of payments; let i = index">
          <p class="payments-counter">
            <span>{{ i + 1 }} of {{ payments ? payments.length : 0 }}</span>
          </p>
          <table class="table table-striped confirmation__table">
            <caption></caption>
            <tr [hidden]="true">
              <th class="text-left" scope="col"></th>
              <th class="text-right" scope="col">
                <span></span>
              </th>
            </tr>
            <tbody class="text-bold">
              <tr>
                <td class="text-left">Recipient</td>
                <td class="text-right">{{ payment.recipient.nickName || payment.recipient.displayName }}</td>
              </tr>
              <tr *ngIf="payment.recipient.paymentMethod == 0">
                <td class="text-left">Payment Type</td>
                <td class="text-right">Check</td>
              </tr>
              <tr *ngIf="payment.recipient.paymentMethod == 1">
                <td class="text-left">Account Number</td>
                <td class="text-right">{{ payment.recipient.paymentAccount }}</td>
              </tr>
              <tr *ngIf="payment.recipient.paymentMethod == 1">
                <td class="text-left">Payment Type</td>
                <td class="text-right">Electronic</td>
              </tr>
              <tr>
                <td class="text-left">Pay From</td>
                <td class="text-right">{{ accountFrom.nickName }}</td>
              </tr>
              <tr>
                <td class="text-left">Amount To Pay</td>
                <td class="text-right">{{ payment.amount | currency }}</td>
              </tr>
              <tr>
                <td class="text-left">Deliver By</td>
                <td class="text-right">{{ payment.selectedDate | date: 'MM/dd/yyyy' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          To modify or delete
          <span *ngIf="payments && payments.length > 1">these payments</span>
          <span *ngIf="payments && payments.length <= 1">this payment</span>
          , go to the Activity Tab in Bill Pay Dashboard.
        </p>
        <div class="text-center goto">
          <p>
            <a href="#" (click)="redirectToPay()">Go to Bill Pay Dashboard</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
