<div class="container">
  <app-funding-header
    [showSubtitle]="true"
    [showBack]="true"
    [headerTitle]="'Edit Goal'"
    [title]="'Edit your goal amount and date'"
    [subtitle]="goalEdit?.name"
    (back)="goBack()"
  ></app-funding-header>
  <div class="col-12 m-t-lg">
    <app-dot-loader *ngIf="isLoading">Loading goal...</app-dot-loader>
    <div class="row" *ngIf="!isLoading">
      <div class="col-lg-7 col-md-12 col-sm-12 edit-goal-container">
        <div class="row form-group">
          <div class="col-5">
            <h4 class="m-4">Your Goal</h4>
          </div>
          <div class="col-7">
            <button type="button" class="link m-4 right" (click)="deleteGoal()" *ngIf="canDelete">
              <app-svg-icon
                iconColor="var(--link)"
                class="delete-icon"
                [iconName]="trashIcon"
                [size]="'1.5rem'"
              ></app-svg-icon>
              Delete Goal
            </button>
            <div class="row load-deleting">
              <app-dot-loader *ngIf="isDeleted">Deleting goal...</app-dot-loader>
            </div>
          </div>
        </div>
        <form class="col-12" [formGroup]="dataForm" (submit)="submitGoalEdit()">
          <div class="form-group" *ngIf="isCustom">
            <label for="targetAmount" class="uk-label">Name</label>
            <input
              type="text"
              class="uk-input"
              formControlName="name"
              id="name"
              name="name"
              [value]="dataForm.get('name').value"
            />
            <div class="text--error" *ngIf="dataForm.get('name').invalid && dataForm.get('name').touched">
              Enter name
            </div>
          </div>
          <div class="form-group" *ngIf="isCustom || isEmergencyFund">
            <label for="targetAmount" class="uk-label">Target Amount</label>
            <app-reactive-transfer-amount [control]="dataForm.get('targetAmount')"></app-reactive-transfer-amount>
            <div class="text--error">
              <ng-container *ngIf="dataForm.get('targetAmount').errors?.required">
                Enter an amount
              </ng-container>
              <ng-container
                *ngIf="
                  (dataForm.get('targetAmount').errors?.min || dataForm.get('targetAmount').errors?.max) && isCustom
                "
              >
                $500 Min, $999,999 Max
              </ng-container>
              <ng-container
                *ngIf="
                  (dataForm.get('targetAmount').errors?.min || dataForm.get('targetAmount').errors?.max) &&
                  isEmergencyFund
                "
              >
                $500 Min, $99,999 Max
              </ng-container>
            </div>
          </div>
          <div class="form-group" *ngIf="isCustom">
            <div class="date-picker">
              <label for="targetDate" class="uk-label">Target Date</label>
              <i class="bofi-datepicker date-picker-icon" (click)="targetDateInput.click()"></i>
              <input
                #targetDateInput
                appDateRangePicker
                [options]="goalDatePickerOptions"
                (dateApplied)="onChangeTargetDate($event)"
                type="text"
                name="targetDate"
                id="targetDate"
                aria-label="date"
                class="uk-input date-picker-input"
                readonly
                [value]="dataForm.get('targetDate').value | date: dateFormat"
              />
            </div>
          </div>
          <div class="form-group monthsToGoalFG" *ngIf="isEmergencyFund">
            <label for="monthsToGoal" class="uk-label">Months to Goal</label>
            <input type="text" class="uk-input" formControlName="monthsToGoal" id="monthsToGoal" name="monthsToGoal" />
            <div class="text--error">
              <ng-container
                *ngIf="dataForm.get('monthsToGoal').errors?.min || dataForm.get('monthsToGoal').errors?.max"
              >
                1 Min, 48 Max
              </ng-container>
              <ng-container *ngIf="dataForm.get('monthsToGoal').errors?.pattern">
                Enter a Valid Numbers of Months
              </ng-container>
              <ng-container *ngIf="dataForm.get('monthsToGoal').errors?.required">
                Months to goal must be at minimum 1 month
              </ng-container>
            </div>
          </div>
          <div class="form-group" *ngIf="isRetirement">
            <label for="retirementAge" class="uk-label">Retirement Age</label>
            <input
              type="text"
              class="uk-input"
              formControlName="retirementAge"
              id="retirementAge"
              name="retirementAge"
            />
            <div class="text--error">
              <ng-container
                *ngIf="dataForm.get('retirementAge').errors?.min || dataForm.get('retirementAge').errors?.max"
              >
                An age of {{ goalEdit.age }} - 124 is more realistic
              </ng-container>
              <ng-container *ngIf="dataForm.get('retirementAge').errors?.required">
                Enter Retirement Age
              </ng-container>
              <ng-container *ngIf="dataForm.get('retirementAge').errors?.pattern">
                Enter a Valid Age
              </ng-container>
            </div>
          </div>
          <div class="form-group" *ngIf="isRetirement">
            <label for="spentPerYear" class="uk-label">Spend Per Year</label>
            <input
              id="spendPerYear"
              type="text"
              class="uk-input"
              aria-label="Amount To Transfer"
              prefix="$ "
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="10000000"
              [dropSpecialCharacters]="[',']"
              [allowNegativeNumbers]="false"
              [formControl]="dataForm.get('spendPerYear')"
              autocomplete="off"
            />
            <div class="text--error">
              <ng-container
                *ngIf="dataForm.get('spendPerYear').errors?.min || dataForm.get('spendPerYear').errors?.max"
              >
                $20,000 Min, $9,999,999 Max
              </ng-container>
              <ng-container *ngIf="dataForm.get('spendPerYear').errors?.required">
                Enter spend per year
              </ng-container>
            </div>
          </div>
          <div class="row center">
            <app-dot-loader *ngIf="isLoadingUpdate">Updating goal...</app-dot-loader>
          </div>
          <div class="row">
            <button id="submit-btn" type="submit" class="btn-ok form-btn m-4">
              Save Changes
            </button>
          </div>
        </form>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 offset-lg-1 mt-4">
        <div class="form-group">
          <p>Current Goal Value</p>
          <p>{{ goalEdit.currentValue | currency }}</p>
        </div>
        <div class="form-group">
          <app-dot-loader *ngIf="isLoadingProjection">Loading goal projection...</app-dot-loader>
          <div *ngIf="!isLoadingProjection">
            <p class="projection-value-title">Deposit Projection</p>
            <p class="projection-value-amount">
              {{ projectionValueLow | currency }} - {{ projectionValueHigh | currency }}* By
              {{ projectionValueDate | date: 'MMM YYYY' }}
            </p>
          </div>
          <app-projection-graph [projectionData]="forecastList" *ngIf="!isLoadingProjection"></app-projection-graph>
        </div>
        <div class="form-group">
          <p>
            * Projections are made based on the deposit amounts entered and your medium risk portfolio with 40% stocks
            and 60% bounds.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
