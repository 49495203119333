<div class="header-section-title text-center">
  <div class="mt-4">
    <span class="header__title">
      Request Pending
    </span>
  </div>
</div>
<div class="container shared-back">
  <a class="shared-back__link" (click)="goBack()">
    <app-svg-icon
      [iconName]="backIcon"
      [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
    ></app-svg-icon>
    <span class="shared-back__label">BACK</span>
  </a>
</div>

<div class="container">
  <div class="row d-flex justify-content-center">
    <p class="path-title m-4">
      {{ incomeSourceName }} requires you to complete your request on their website
    </p>
    <div class="col-md-8 text-center">
      <p class="path-subtitle">
        Use the link below to continue to their website
      </p>

      <div class="center p-5 m-2">
        <a (click)="goTheNewWebSite()" class="link info-text" id="incomeSourceWebPage">
          <app-svg-icon
            [iconName]="outDomain"
            [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
          ></app-svg-icon>
          Your {{ incomeSourceName }} Portal
        </a>
      </div>

      <app-dot-loader [showWhen]="isLoading"></app-dot-loader>

      <div class="d-flex flex-column justify-content-center text-justify">
        <div class="directions mb-3">
          Follow these steps to complete request:
        </div>
        <div class="directions">
          <ul>
            <li class="mb-1">
              <div class="row">
                <div
                  style="flex: 1;"
                  class="col-sm-11 col-md-11 col-lg-11 text-justify pr-0"
                  [innerHtml]="bodyHtml"
                  name="stepDescription"
                ></div>
              </div>
            </li>
          </ul>

          <div *ngIf="!bodyHtml">
            <div class="directions" id="steps">
              <ul>
                <li class="mb-1">
                  <div class="row">
                    <div
                      style="flex: 1;"
                      class="col-sm-11 col-md-11 col-lg-11 text-justify pr-0"
                      innerHtml="Go to the <strong>{{ incomeSourceName }}</strong> website and sign in to your account to continue your request. <br /><br />Login and navigate to your employee profile. <br /><br />Select the thing you need and input this information, then submit."
                      name="step1"
                    ></div>
                  </div>
                </li>                
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button
        id="requestStatus"
        type="button"
        class="uk-btn lg solid secondary m-4"
        (click)="checkRequestStatus()"
      >
        Check Request Status
      </button>
    </div>
  </div>
</div>
