<div class="no-longer-eligible-container">
  <div class="no-longer-eligible-header">
    <img src="../../../../assets/axos/logos/axos-logo.png" class="logo" alt="Axos" />
  </div>

  <div class="no-longer-eligible-body">
    <img src="../../../../assets/img/icons/sbloc/Illustration-Area-2.svg" class="Illustration" alt="Illustration" />

    <div class="body-text">
      <h1>SBLOC Status Update</h1>
      <p style="margin-bottom: 8px;">
        Due to recent changes in your investment portfolio, we regret to inform you that you no longer meet the
        qualifications for this account.
      </p>
      <p>
        We understand that financial circumstances can change, and we encourage you to explore other account options
        that may better align with your current situation.
      </p>
    </div>
    <div class="no-longer-eligible-footer">
      <button class="PrimaryButton" (click)="goDashboard()" type="button">
        Back to Dashboard
      </button>
    </div>
  </div>
</div>
