import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import { loadCompanyDetails } from '../actions';
import {
  updateCompanyAddresses,
  updateCompanyEmail,
  updateCompanyFax,
  updateCompanyPhone,
} from '../company-details/company-details.actions';
import {
  confirmInfoIsUpdated,
  loadBusinessInfo,
  loadCompanyProfiles,
  loadIsNewUser,
  loadOutdatedContactInfo,
  loadProfileDetails,
  updateProfileDetails,
  updateProfileIcon,
} from './profile-details.actions';
import { ProfileDetailsState } from './profile-details.state';

export const initialState = new ProfileDetailsState();

const _reducer = createReducer<ProfileDetailsState>(
  initialState,
  on(loadProfileDetails, (state, action) => {
    return { ...state, details: { ...state.details, ...action.payload } };
  }),
  on(loadCompanyDetails, (state, action) => {
    return { ...state, companyDetails: action.payload };
  }),
  on(updateProfileDetails, (state, action) => {
    return { ...state, details: action.payload };
  }),
  on(updateProfileIcon, (state, action) => {
    return { ...state, details: { ...state.details, ...action.payload } };
  }),
  on(loadOutdatedContactInfo, (state, action) => {
    return { ...state, userInfoIsOutdated: action.isOutdated };
  }),
  on(loadIsNewUser, (state, action) => {
    return { ...state, isNewUser: action.isNewUser };
  }),
  on(confirmInfoIsUpdated, state => {
    return { ...state, userInfoIsOutdated: false };
  }),
  on(updateCompanyEmail, (state, action) => {
    return {
      ...state,
      details: { ...state.details },
      companyDetails: { ...state.companyDetails, businessEmail: action.payload },
    };
  }),
  on(updateCompanyPhone, (state, action) => {
    return {
      ...state,
      details: { ...state.details },
      companyDetails: { ...state.companyDetails, businessPhone: action.payload },
    };
  }),
  on(updateCompanyAddresses, (state, action) => {
    return {
      ...state,
      companyDetails: { ...state.companyDetails, businessAddresses: action.payload },
    };
  }),
  on(updateCompanyFax, (state, action) => {
    return {
      ...state,
      details: { ...state.details },
      companyDetails: { ...state.companyDetails, businessFax: action.payload },
    };
  }),
  on(signOut, () => {
    return new ProfileDetailsState();
  }),
  on(loadBusinessInfo, (state, action) => {
    return { ...state, details: { ...state.details, businessInformation: action.businessData } };
  }),
  on(loadCompanyProfiles, (state, action) => {
    return { ...state, accountProfiles: action.accountProfiles };
  })
);

export function profileDetailsReducer(state: ProfileDetailsState, action: Action) {
  return _reducer(state, action);
}
