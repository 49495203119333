import { CamelCaseKeyName } from '../../store';

export function lowercaseFirstLetter<T extends string>(input: T): CamelCaseKeyName<T> {
  if (input.length > 0) {
    if (input.length > 2) {
      return (input.charAt(0).toLowerCase() + input.slice(1)) as CamelCaseKeyName<T>;
    }
    return input.toLowerCase() as CamelCaseKeyName<T>;
  }
  return '' as CamelCaseKeyName<T>;
}
