/**
 * Split the string into Words
 *
 * @param value The pascal case string.
 *
 * @returns String with the words correctly separated.
 */
export function pascalCaseToWords(value: string): string {
  if (value) {
    return value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  return '';
}

/**
 * Compares two strings (including accents) to determine if they are equal.
 *
 * @param string1 String on the left side.
 * @param string2 String on the right side.
 *
 * @returns True if the strings are equal
 */
export function stringsAreEqual(string1: string, string2: string): boolean {
  return string2.localeCompare(string1, 'en', { sensitivity: 'accent' }) === 0;
}

/**
 * Capitalizes a string.
 *
 * @param value String to capitalize.
 *
 * @returns Capitalizes the given string.
 */
export function capitalize(value: string): string {
  const lower = value.toLowerCase();

  return lower.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}
