<app-view-header titleText="Request Replacement Card">Debit Card {{ cardMask }}</app-view-header>

<div class="view-container">
  <div class="col-12 col-lg-8 mx-auto">
    <h2 class="text-center mb-5">You can request a new replacement card if your card has been damaged in any way.</h2>

    <app-alert-notification
      class="col-12 mb-5"
      *ngIf="apiErrorMessage"
      alertType="error"
      (dismiss)="apiErrorMessage = null"
    >
      {{ apiErrorMessage }}
    </app-alert-notification>

    <form [formGroup]="replacementForm" (ngSubmit)="submitInformation()" *ngIf="!loadingData">
      <div class="col-lg-6 form-group">
        <label class="uk-label" for="reason">Reason for Replacement</label>
        <select name="reason" id="reason" class="uk-input" formControlName="reason" autofocus>
          <option *ngFor="let item of possibleReasons" [value]="item" [innerText]="item"></option>
        </select>

        <div class="mt-1 text-error" ngxErrors="reason" #reasonField="ngxErrors">
          <span *ngFor="let error of errors.reason" [ngxError]="error.name" [innerText]="error.message"></span>
        </div>
      </div>

      <div class="col-md-12 form-group">
        <label class="uk-label" for="comments">Comments (Optional)</label>
        <textarea
          name="comments"
          id="comments"
          class="uk-input"
          formControlName="comments"
          rows="2"
          maxlength="120"
        ></textarea>

        <div id="characters-count" class="text-inactive text-right">{{ charactersCount }}/120</div>

        <div class="mt-1 text-error" ngxErrors="comments" #commentsField="ngxErrors">
          <span *ngFor="let error of errors.comments" [ngxError]="error.name" [innerText]="error.message"></span>
        </div>
      </div>

      <div class="form-group col-xl-6">
        <dc-debit-card-address
          [address]="replacementForm.get('deliveryAddress').value"
          (edit)="canSubmit = !$event"
          (changeAddress)="changeAddress($event)"
        ></dc-debit-card-address>
      </div>

      <app-card-replacement-fee
        class="d-block col-md-12"
        [replace]="true"
        [dueFee]="replacementForm.get('dueFee').value"
        (feeSelection)="setDueFee($event)"
      ></app-card-replacement-fee>

      <div class="form__actions" *ngIf="canSubmit">
        <app-dot-loader *ngIf="loading; else formActions">Verifying...</app-dot-loader>

        <ng-template #formActions>
          <button class="btn-ok">Submit</button>
          <button type="button" class="btn-cancel" (click)="returnToDebitCards()">Cancel</button>
        </ng-template>
      </div>
    </form>
  </div>
</div>
