export enum PfmIcons {
  Category_Purchases = 'pfm-category-purchases',
  Category_Food = 'pfm-category-food',
  Category_Bills = 'pfm-category-bills',
  Category_Transportation = 'pfm-category-transportation',
  Category_Personal_Care = 'pfm-category-personal',
  Category_Travel_Entertainment = 'pfm-category-travel',
  Category_Transfers = 'pfm-category-transfers',
  Category_Other = 'pfm-category-other',
  Category_Deposits = 'pfm-category-deposits',
}
