import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getProfileDetails } from '@app/user-profile/store/selectors';
import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { CommunicationIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-debit-card-confirmation-view',
  templateUrl: './debit-card-confirmation-view.component.html',
  styleUrls: ['./debit-card-confirmation-view.component.scss'],
})
export class DebitCardConfirmationViewComponent implements OnInit, OnDestroy {
  isLoading = true;
  isLoadingCards = true;
  accountId = 0;
  email = '';
  header = '';
  message = '';
  iconConfig = {
    calendar: {
      size: '1.2rem',
      icon: CommunicationIcons.Calendar,
    },
    check: {
      size: '3rem',
      icon: UtilityIcons.Checkmark,
      fill: 'var(--accent)',
    },
  };

  private subSink = new SubSink();

  constructor(
    private store: Store,
    private cachedAccountsService: CachedAccountsService,
    private olbEventService: OlbEventService,
    @Inject(STATE_PARAMS) private stateParams: ng.ui.IStateParamsService,
    @Inject(STATE) private stateService: ng.ui.IStateService
  ) {}

  ngOnInit() {
    this.setEmail();
    this.setDataFromParams();

    this.subSink.sink = this.olbEventService.on(OlbEvents.LinkedDebitCardsLoaded, this.markCardsLoaded.bind(this));
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  goToDebitCards() {
    this.stateService.go('udb.accounts.details', {
      id: this.accountId,
      tab: 4,
      debitCardId: this.stateParams.card.debitCardId,
    });
  }

  goToDashboard() {
    this.stateService.go('udb.accounts.dashboard');
  }

  private setDataFromParams() {
    this.accountId = this.stateParams.accountId;
    this.header = this.stateParams.header;
    this.message = this.stateParams.message;

    this.cachedAccountsService.getDebitCards(this.accountId);
  }

  private setEmail() {
    this.subSink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(profileDetails => profileDetails && profileDetails.hasOwnProperty('primaryEmail')))
      .subscribe({
        next: profileDetails => {
          this.email = profileDetails.primaryEmail;
          this.isLoading = false;
        },
      });
  }

  private async markCardsLoaded() {
    this.isLoadingCards = false;
  }
}
