import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { SubSink } from '@axos/subsink';

import { ManagedAccounts } from '@app/support/models/managed-accounts.model';
import { ContactFirmFacade } from '@app/support/store/contact-firms/contact-firms.facade';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { CommunicationIcons, FinancialIcons } from '@shared/enums';
import {
  BrandingSettingsFacade,
  BrandingStateType,
} from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { FirmsRepresentative } from '@app/support/models/firms-representative.models';
import { map, filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { BRANDING_SETTINGS_LABELS } from '@app/support/constants';
import { AxosAdvisoryAccount } from '@core/models';
import { ORGANIZATION_NAMES } from '@app/Areas/AAS/features/branding';

@Component({
  selector: 'app-contact-firm',
  templateUrl: './contact-firm.component.html',
  styleUrls: ['./contact-firm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFirmComponent implements OnInit, OnDestroy {
  isLoadingFirms$: Observable<boolean>;
  riaUserFlag$: Observable<boolean>;
  contactFirms$: Observable<FirmsRepresentative[]>;
  isRIAPilotActive$: Observable<boolean>;

  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  axosAdvisoryAccount$: Observable<AxosAdvisoryAccount[]>;

  icons = {
    user: CommunicationIcons.User,
    logo: FinancialIcons.Bank,
    phone: CommunicationIcons.MobilePhone,
    mail: CommunicationIcons.Envelope,
    calendar: CommunicationIcons.Calendar,
  };

  isMobileDevice = false;

  riaText = 'Get in touch with the right team so we can help.';

  private subsink = new SubSink();
  private destroy$ = new Subject<void>();

  constructor(
    private contactFirmsFacade: ContactFirmFacade,
    private breakpointObserver: BreakpointObserver,
    private supportFacade: SupportViewFacade,
    private brandingSettingsFacade: BrandingSettingsFacade,
    private riaFacade: RiaFacade
  ) {}

  ngOnInit(): void {
    this.isLoadingFirms$ = this.contactFirmsFacade.isLoading$;
    this.riaUserFlag$ = this.supportFacade.isRIAUser$;
    this.contactFirms$ = this.contactFirmsFacade.contactFirms$.pipe(
      map(firms => this.mapFirms(firms))
    );
    this.axosAdvisoryAccount$ = this.contactFirmsFacade.axosAdvisoryAccounts$;
    this.isRIAPilotActive$ = this.contactFirmsFacade.isRIAPilotActive$;

    this.subsink.sink = this.breakpointObserver
      .observe(['(max-width: 767.98px)'])
      .subscribe(result => {
        this.isMobileDevice = result.matches;
      });

    this.subsink.sink = this.riaFacade.allRias$
      .pipe(
        filter(state => state != undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(rias => {
        this.allRiaAccounts = rias;
        this.axosAdvisoryAccount$
          .pipe(filter(state => state != undefined))
          .subscribe(accounts => {
            this.isRIAPilotActive$
              .pipe(filter(state => state != undefined))
              .subscribe(isActive => {
                if (isActive)
                  this.contactFirmsFacade.getContactFirms(rias, accounts);
              });
          });
      });

    this.subsink.sink = this.brandingSettingsFacade.allBrandingSettings$.subscribe(
      brandings => {
        this.allBrandingSettings = brandings;
      }
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getInitials(value: string): string {
    let result = '';

    const splitWords = value.split(' ');
    splitWords.forEach((word, index) => {
      if (index + 1 <= 2) {
        result += word.charAt(0);
      } else {
        return;
      }
    });

    return result;
  }

  getAllAccountsList(managedAccounts: ManagedAccounts[]): string {
    let accountsHtml = '';

    const sortedManagedAccounts = this.sortedManagedAccounts(managedAccounts);

    sortedManagedAccounts.forEach(account => {
      accountsHtml += `<div style="padding: 5px">${account}</div>`;
    });

    return accountsHtml;
  }

  trimCommonName(name: string): string {
    if (name.length > 20) {
      return `${name.substring(0, 19)}...`;
    }

    return name;
  }

  sortedManagedAccounts(managedAccounts: ManagedAccounts[]): string[] {
    let sortedAccounts: string[] = [];

    managedAccounts.forEach(acc => {
      let accountNumber = acc.number
        .toString()
        .substring(acc.number.toString().length - 4);
      let typeIncludesAccountNumber = acc.type.includes(accountNumber);
      let managedAccount =
        acc.type + (!typeIncludesAccountNumber ? ' - ' + accountNumber : '');
      sortedAccounts.push(managedAccount);
    });

    sortedAccounts.sort(function (a, b) {
      if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) return -1;
      if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) return 1;
      return 0;
    });

    return sortedAccounts;
  }

  isFirmSectionVisible(firms: FirmsRepresentative) {
    return firms.representatives.some(x => x.managedAccounts.length > 0);
  }

  private mapFirms(firms: FirmsRepresentative[] | undefined) {
    if (firms) {
      firms = firms.map(firm => {
        //Replaces the contact logo based on the branding settings if available
        firm.logo = this.setContactFirmLogo(firm);
        return firm;
      });
    }
    return firms;
  }

  private setContactFirmLogo(contactFirm: FirmsRepresentative) {
    const ria = this.allRiaAccounts?.find(
      ria => ria.udbUserId === contactFirm.firmId
    );

    const brand = this.allBrandingSettings?.find(
      brand => brand.name === ria?.name
    );

    let logo = contactFirm.logo;

    if (brand) {
      logo = this.setLogo(brand, ria);
    }
    return logo;
  }

  private setLogo(brand: BrandingStateType, ria: RiaType) {
    const secondaryLogo = brand?.settings.find(
      settings => settings.name === BRANDING_SETTINGS_LABELS.SECONDARY_LOGO_TEXT
    );

    const axosBrand = this.allBrandingSettings?.find(
      brand => brand.name === ORGANIZATION_NAMES.Axos
    );
    const axosSecondaryLogo = axosBrand?.settings.find(
      settings => settings.name === BRANDING_SETTINGS_LABELS.SECONDARY_LOGO_TEXT
    );
    return secondaryLogo && ria.useCustomSecondaryLogo
      ? secondaryLogo.value
      : axosSecondaryLogo.value;
  }
}
