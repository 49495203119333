import { BrandingStateType } from '.';

export type BrandingSettingsStateType = {
  brands: BrandingStateType[];
  selectedBrandingName: string | undefined;
};

export const initialBrandingSettingsStateType: BrandingSettingsStateType = {
  brands: [],
  selectedBrandingName: undefined,
};
