import { FinancialIcons, UtilityIcons } from '@shared/enums';

import { Inject } from '../../../decorators/decorators';

@Inject()
export class TileTxDetailController {
  transaction: Transaction;
  isWithdrawal = false;
  glyph: string;

  constructor() {}

  /** Initializes the controller. */
  $onInit(): void {
    this.isWithdrawal = this.transaction.amount < 0;
    if (this.transaction.checkNumber !== '--' && !this.transaction.isPending) {
      this.glyph = FinancialIcons.CoinDollarSign;
    } else if (this.transaction.checkNumber === '--' && !this.transaction.isPending) {
      this.glyph = FinancialIcons.CreditCard;
    } else {
      this.glyph = UtilityIcons.Clock;
    }
  }
}
