import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { TransactionType } from '@app/axos-invest/enums';
import { Accounts, Filters, ScheduledTransfer, Transaction } from '@app/axos-invest/models';
import { AxosInvestService } from '@app/axos-invest/services';
import { getGoalSummary, getScheduledTransfers } from '@app/axos-invest/store/selectors';
import { STATE_PARAMS } from '@core/tokens';

@Component({
  selector: 'app-goal-transactions',
  templateUrl: './goal-transactions.component.html',
  styleUrls: ['./goal-transactions.component.scss'],
})
export class GoalTransactionsComponent implements OnInit, OnDestroy {
  transactions: Transaction[];
  accountsList: Accounts[];
  milestoneId: string;
  isRetirementMilestone = false;
  recurringDesposits: ScheduledTransfer[];
  isLoading = true;
  isLoadingRecurringDeposit = true;
  referrerTabId = 3;
  subSink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private axosInvestService: AxosInvestService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.milestoneId = this.params['id'];
    this.getTransactions();
    this.getRecurringDeposits();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  getRecurringDeposits() {
    this.subSink.sink = this.store.select(getScheduledTransfers).subscribe(transfers => {
      this.isLoadingRecurringDeposit = true;
      if (transfers) {
        transfers = JSON.parse(JSON.stringify(transfers));
        this.recurringDesposits = transfers.filter(
          transfer =>
            transfer.milestoneId === this.milestoneId &&
            transfer.transactionType === TransactionType.Deposit &&
            transfer.frequency >= 2
        );
        this.getAccounts();
      } else {
        this.isLoadingRecurringDeposit = false;
      }
    });
  }

  getAccounts() {
    this.isLoadingRecurringDeposit = true;
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(milestoneData => {
      this.isLoadingRecurringDeposit = false;
      this.accountsList = milestoneData.accounts;
      this.accountsList = this.accountsList.filter(account => account.milestoneId === this.milestoneId);
      this.recurringDesposits.forEach(deposit => {
        const account = this.accountsList.find(a => a.accountNumber === deposit.accountNumber);
        if (account) {
          deposit.accountName = account.name;
        }
      });
      this.isLoadingRecurringDeposit = false;
    });
  }

  getTransactions(filters: Filters = null) {
    this.isLoading = true;
    if (!filters || !filters.datePeriod) {
      filters = filters ? filters : {};
      filters.milestoneId = this.milestoneId;
      filters.datePeriod = 90;
    }
    filters.milestoneId = this.milestoneId;
    this.axosInvestService
      .getTransactions(filters)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(response => {
        this.transactions = response.data;
        this.isLoading = false;
      });
  }
}
