import { Component, Input, OnChanges } from '@angular/core';

import { NetWorthPoint } from '@app/pfm/models/net-worth-point.model';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-net-worth-header',
  templateUrl: './net-worth-header.component.html',
  styleUrls: ['./net-worth-header.component.scss'],
})
export class NetWorthHeaderComponent implements OnChanges {
  @Input() currentNetWorth: NetWorthPoint;
  @Input() previousNetWorth: NetWorthPoint;
  arrowUp = UtilityIcons.ArrowUp;
  arrowDown = UtilityIcons.ArrowDown;
  percentageChange: number;
  dollarChange: number;
  constructor() {}
  ngOnChanges(): void {
    if (!this.currentNetWorth?.worth || !this.previousNetWorth?.worth) return;
    this.percentageChange =
      this.previousNetWorth.worth.net !== 0
        ? (this.currentNetWorth.worth.net - this.previousNetWorth.worth.net) / this.previousNetWorth?.worth.net
        : 0;
    this.dollarChange = this.currentNetWorth.worth.net - this.previousNetWorth.worth.net;
  }

  showSignValue() {
    return this.dollarChange > 0 ? '+' : '';
  }

  getPercentageArrow() {
    if (this.percentageChange === 0 || !this.currentNetWorth?.worth || !this.previousNetWorth?.worth) return;

    return this.currentNetWorth.worth.net > this.previousNetWorth.worth.net ? this.arrowUp : this.arrowDown;
  }

  shouldShowCents() {
    return this.dollarChange < 0.9 && this.dollarChange > -0.9 && this.dollarChange !== 0;
  }
}
