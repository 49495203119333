import { PaymentsService } from 'services/payments.service';

import { Inject } from '../../../decorators/decorators';
import { BankDetailsModel, WireTransfer, WireTransferDestination } from '../../typings/WireTransfer';
@Inject('$stateParams', '$state', 'paymentsService', 'serviceHelper')
export class BankDetailsController {
  wiretransfer: WireTransfer;
  isLocalTransfer: boolean;
  zipCodeMaxLength: number;
  intermediaryBankOpt = false;
  abaNumberValid = true;
  abaNumberWrongFormat = false;
  accountNumberValid = true;
  accountNumberWrongFormat = false;
  bankValid = true;
  interBankNameValid = true;
  interBankAccValid = true;
  accShort = false;
  abaShort = false;
  interAccShort = false;
  foreignInvalidField: any = {};
  inputPattern = /^[0-9 a-zA-Z \-,.()"#%\+='<>\$!?;&@:_\\\/\r\n]*$/;
  formSetup = {
    headerSubTitle: 'Wire Transfer in the U.S.',
    accountNumber_Class: 'col-md-6',
    accountNumber_Label: `Recipient's Account Number`,
    accountNumber_MaxFieldLengh: 20,
    accn_DrawaField: true,
  };
  constructor(
    private params: ng.ui.IStateParamsService,
    private readonly state: ng.ui.IStateService,
    private readonly paymentsService: PaymentsService,
    private readonly serviceHelper: IServiceHelper
  ) {}

  /** Initializes the controller. */
  $onInit(): void {
    if (!this.params.wiretransfer) this.state.go('udb.transfers.wireTransfers');

    this.wiretransfer = this.params.wiretransfer;
    if (!this.wiretransfer.bankDetails) {
      this.wiretransfer.bankDetails = new BankDetailsModel();
    }
    this.intermediaryBankOpt = this.wiretransfer.bankDetails && this.wiretransfer.bankDetails.intermediary;
    this.isLocalTransfer = this.wiretransfer.destination === WireTransferDestination.us;

    if (!this.isLocalTransfer) {
      this.formSetup.headerSubTitle = 'Wire Transfer Outside the U.S.';
      this.formSetup.accountNumber_Class = 'col-md-12';
      this.formSetup.accountNumber_Label = "Recipient's Account Number / IBAN";
      this.formSetup.accountNumber_MaxFieldLengh = 50;
      this.formSetup.accn_DrawaField = false;
    }

    this.zipCodeMaxLength = this.isLocalTransfer ? 5 : 10;

    this.scrollToTop();
  }

  onAbaNumberChange(): void {
    if (!this.validateAbaNumber()) return;

    this.getBankName();
    this.clearValidationAbaNumb();
  }

  onKeyPress(Ev: KeyboardEvent): void {
    const isAllowed = this.isLocalTransfer
      ? (Ev.charCode >= 48 && Ev.charCode <= 57) || Ev.charCode === 46
      : (Ev.charCode >= 48 && Ev.charCode <= 57) ||
        (Ev.charCode >= 65 && Ev.charCode <= 90) ||
        (Ev.charCode >= 97 && Ev.charCode <= 122);

    if (!isAllowed) {
      Ev.preventDefault();
      Ev.stopPropagation();
    }
  }

  back(): void {
    const { wiretransfer } = this;
    this.state.go('udb.transfers.wireTransfers.recipientDetails', { wiretransfer });
  }

  bankDetailsSubmit(): void {
    this.ensureRequiredFieldsTouched();

    if ((this.isLocalTransfer && !this.validateAbaNumber()) || !this.validateAccountNumber()) {
      return;
    }

    const foreignValid = this.isLocalTransfer
      ? true
      : Object.keys(this.foreignInvalidField).every(key => !this.foreignInvalidField[key]);

    this.setValidations();

    if (!foreignValid) {
      return this.scrollToTop();
    } else if (
      this.abaNumberValid &&
      !this.abaNumberWrongFormat &&
      this.accountNumberValid &&
      !this.accountNumberWrongFormat &&
      this.bankValid &&
      !this.accShort &&
      !this.abaShort
    ) {
      // Intermediary Bank validation.
      if (
        !this.intermediaryBankOpt ||
        (this.intermediaryBankOpt && this.interBankNameValid && this.interBankAccValid && !this.interAccShort)
      ) {
        this.state.go('udb.transfers.wireTransfers.reviewTransfer', {
          wiretransfer: this.wiretransfer,
        });
      }
    }
  }

  /**
   * Gets the bank name based on the routing number.
   */
  getBankName(): void {
    if (!this.isLocalTransfer) return;

    const routingNumber = this.wiretransfer.bankDetails.abaNumber;
    if (!routingNumber || routingNumber.toString().length < 9) {
      this.wiretransfer.bankDetails.bank = '';
    } else {
      this.paymentsService
        .getBankName(routingNumber)
        .then(res => {
          if (this.wiretransfer.bankDetails.bank == null || this.wiretransfer.bankDetails.bank == '') {
            this.wiretransfer.bankDetails.bank = res.data;
          }
        })
        .catch(err => {
          if (err.status === 404) {
            return;
          }
          this.serviceHelper.errorHandler(err);
        })
        .finally(() => {
          this.setValidationBank();
        });
    }
  }

  clearValidationAbaNumb(): void {
    this.abaShort = false;
    this.bankValid = true;
    this.abaNumberValid = true;
  }
   
  clearValidationAccNumb(): void {

    const regex2=new RegExp(" ", 'g'); 
    this.wiretransfer.bankDetails.accountNumber=(this.wiretransfer.bankDetails?.accountNumber).replace(regex2, "")

    if (!this.validateAccountNumber()) return;

    this.accShort = false;
    this.accountNumberValid = true;
  }

  setValidationBank(): void {
    this.bankValid = !(
      !this.wiretransfer.bankDetails ||
      this.wiretransfer.bankDetails.bank == null ||
      this.wiretransfer.bankDetails.bank == ''
    );
  }

  setValidationInterBankName(): void {
    this.interBankNameValid = !(
      !this.wiretransfer.bankDetails ||
      this.wiretransfer.bankDetails.interBankName == null ||
      this.wiretransfer.bankDetails.interBankName === '' ||
      !this.inputPattern.test(this.wiretransfer.bankDetails.interBankName)
    );
  }

  setValidationInterBankAcc(): void {
    this.interBankAccValid = !(
      !this.wiretransfer.bankDetails ||
      this.wiretransfer.bankDetails.interBankAccount == null ||
      this.wiretransfer.bankDetails.interBankAccount == ''
    );
    if (this.interBankAccValid && this.wiretransfer.bankDetails.interBankAccount.toString().length < 9) {
      this.interAccShort = true;
    } else this.interAccShort = false;
  }

  onFieldChange(field: keyof BankDetailsModel): void {
    const value = this.wiretransfer.bankDetails[field] as string;
    let valid: boolean;

    if (field === 'swiftCode') {
      valid = value.length > 0 && value.length <= 16;
    } else if (field === 'address') {
      valid = value.length > 5;
    } else if (field === 'address1') {
      valid = value.length === 0 || value.length > 5;
    } else if (field === 'country') {
      valid = value.length > 3;
    } else if (field === 'city') {
      valid = value.length > 0 && value.length > 3;
    } else if (field === 'state') {
      valid = value.length >= 0 && value.length <= 50;
    }

    this.foreignInvalidField[field] = !valid;
  }

  getFieldClass(field: string) {
    const red = this.foreignInvalidField[field];

    return red ? { red } : null;
  }

  isNumberPressed(Ev: KeyboardEvent) {
    return (Ev.charCode >= 48 && Ev.charCode <= 57) || Ev.charCode == 46;
  }

  cleanIntermediaryFields() {
    this.wiretransfer.bankDetails.interBankName = '';
    this.wiretransfer.bankDetails.interBankAccount = '';
  }

  private validateAbaNumber(): boolean {
    this.abaNumberValid = true;
    this.abaNumberWrongFormat = false;

    const regex = /^\d+$/;
    const routingNumber = this.wiretransfer.bankDetails.abaNumber;

    if (!routingNumber) {
      this.abaNumberValid = false;

      return false;
    }

    if (routingNumber.length < 9) {
      this.abaShort = true;

      return false;
    }

    if (routingNumber && !regex.test(routingNumber)) {
      this.abaNumberWrongFormat = true;
      this.abaNumberValid = false;

      return false;
    }

    if (routingNumber.length < 9) {
      this.abaShort = true;

      return false;
    }

    return true;
  }

  private validateAccountNumber(): boolean {
    this.accountNumberValid = true;
    this.accountNumberWrongFormat = false;
    this.accShort = false;

    const regex = /^[\w\d]+$/;
    const accountNumber = this.wiretransfer.bankDetails?.accountNumber;

    if (!accountNumber) {
      this.accountNumberValid = false;
      this.accountNumberWrongFormat = false;

      return false;
    }

      if (accountNumber && !regex.test(accountNumber)) {
        this.accountNumberWrongFormat = true;
        this.accountNumberValid = false;
        
       return false;
    } 

    if (accountNumber.length < 5) {
      this.accShort = true;

      return false;
    }

    return true;
  }

  private setValidations(): void {
    this.setValidationBank();
    if (this.intermediaryBankOpt) {
      this.setValidationInterBankName();
      this.setValidationInterBankAcc();
      this.wiretransfer.bankDetails.intermediary = true;
    } else {
      this.interAccShort = false;
      this.wiretransfer.bankDetails.intermediary = false;
      this.wiretransfer.bankDetails.interBankName = '';
      this.wiretransfer.bankDetails.interBankAccount = '';
    }
  }

  private scrollToTop() {
    return document.querySelector('.header-section-title').scrollIntoView();
  }

  private ensureRequiredFieldsTouched(): void {
    const requiredFields = ['swiftCode', 'address', 'country', 'city'];

    requiredFields.forEach(field => this.onFieldChange(field as keyof BankDetailsModel));
  }
}
