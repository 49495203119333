<app-dot-loader
  *ngIf="isLoading; else aggregateAccountDetails"
></app-dot-loader>

<ng-template #aggregateAccountDetails>
  <h2>Account Details</h2>

  <div class="row">
    <app-aggregate-account-detail
      class="col-12 mt-3 account-detail"
      [title]="'Account'"
      [rows]="accountRows"
      (updateNickName)="updateNickNameEvent()"
    ></app-aggregate-account-detail>

    <app-aggregate-account-detail
      *ngIf="ownershipRows.length > 0"
      class="col-12 mt-3 ownership-detail"
      [title]="'Ownership'"
      [rows]="ownershipRows"
    ></app-aggregate-account-detail>

    <app-aggregate-account-detail
      *ngIf="paymentRows.length > 0"
      class="col-12 mt-3 payment-detail"
      [title]="'Payment'"
      [rows]="paymentRows"
    ></app-aggregate-account-detail>

    <app-share-account-details
      class="col-12 mt-3 shared-account-detail"
      [shareAccount]="isRiaShareAccountFlag"
      [authorizeTransfers]="isRiaAuthorizeTransfersFlag"
      [displayAuthorizeTransfer]="shouldDisplayAuthorizeTransfer"
      [displayShareAccount]="shouldDisplayShareAccount"
      [isRedesign]="true"
      [accountId]="accountId"
    ></app-share-account-details>
  </div>
</ng-template>
