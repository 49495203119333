export enum ClearingTransferStatus {
  Unmapped = 0,
  Unknown = 1,
  Dispatched = 2,
  Pending = 3,
  PendingAccount = 4,
  PendingSubAml = 5,
  PendingAml = 6,
  PendingOfac = 7,
  PendingLiquidation = 8,
  PendingInstructions = 9,
  PendingRoute = 10,
  PendingRouteApproval = 11,
  PendingPost = 12,
  PendingReview = 13,
  PendingBackOffice = 14,
  PendingDispatch = 15,
  PendingCancel = 16,
  PendingDndb = 17,
  PendingQueued = 18,
  Error = 19,
  Cancelled = 20,
  FailedAml = 21,
  FailedOfac = 22,
  Failed = 23,
  Passed = 24,
  PassedWithEscalation = 25,
  RouteApproved = 26,
  Rejected = 27,
  Complete = 28,
  AmlOfacComplete = 29,
}
