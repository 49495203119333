import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFilterComponent implements OnInit {
  @Input() options: GenericOption[] = [];
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() isDisabled = false;

  @HostListener('document:click', ['$event.target'])
  collapse(target: HTMLElement) {
    const element: HTMLElement = this.element.nativeElement;
    if (!element.contains(target) && this.dropdownVisible) {
      this.toggleDropdown();
    }
  }

  selectedOption: GenericOption;
  dropdownVisible = false;

  constructor(private element: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    const defaultOption = this.options.find(option => option.default);
    this.selectedOption = defaultOption;
    this.control.valueChanges.subscribe(value => {
      if (!value) {
        this.control.setValue(defaultOption?.value, { emitEvent: false });
        this.selectedOption = defaultOption;
      } else {
        this.selectedOption = this.options.find(option => option.value === value);
      }
    });
    this.control.setValue(defaultOption?.value, { emitEvent: false });
  }

  selectOption(option: GenericOption) {
    this.control.setValue(option.value);
    this.toggleDropdown();
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
    this.changeDetectorRef.markForCheck();
  }
}
