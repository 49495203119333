import { downgradeComponent } from '@angular/upgrade/static';
import { AccountDetailsPageComponent } from '@app/Areas/AAS/features/account-details/view';

export const AccountDetailsPageLegacyModule = angular
  .module('udb.aasaccountdetailspage', ['ui.router'])
  .directive('appAccountDetailsPage', downgradeComponent({ component: AccountDetailsPageComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: ng.ui.IStateProvider) => {
      $stateProvider.state('udb.accountDetailsPage', {
        url: '/Account/Details/:accountNumber',
        views: {
          content: {
            template: '<app-account-details-page></app-account-details-page>',
          },
        },
      });
    },
  ]).name;
