import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  Uk2TooltipPlacementEnum,
  Uk2TooltipTriggerEnum,
  Uk2TooltipStrategyEnum,
  Uk2TooltipSizeEnum,
  Uk2StretchTabsSizeEnum,
  Uk2Tier1AlertsEnum,
} from '@axos/uikit-v2-lib';
import { tooltipBodyText } from './constants';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { AasDataTileListRowTypeEnum } from '@app/Areas/AAS/aas-shared/components';
import { Observable } from 'rxjs';
import { AccountFirmContactsStateType } from '../../../core';
import { AccountDetailsFacade } from '../../../facade';

@Component({
  selector: 'app-account-details-page',
  templateUrl: './account-details-page.component.html',
  styleUrls: ['./account-details-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDetailsPageComponent implements OnInit, AfterViewInit {
  // Tooltip
  tooltipSize: Uk2TooltipSizeEnum = Uk2TooltipSizeEnum.large;
  tooltipText = tooltipBodyText;
  tooltipDisplayCloseButton = false;
  tooltipIcon = Uk2Tier1AlertsEnum.infoCircle;
  tooltipPlacement: Uk2TooltipPlacementEnum = Uk2TooltipPlacementEnum.top;
  tooltipTrigger: Uk2TooltipTriggerEnum = Uk2TooltipTriggerEnum.hover;
  tooltipStrategy: Uk2TooltipStrategyEnum = Uk2TooltipStrategyEnum.absolute;
  riaPilotFirmContactsActive: boolean = false;

  // Tabs
  tabsSize: Uk2StretchTabsSizeEnum = Uk2StretchTabsSizeEnum.small;
  selectedTabIndex: number = 0;
  tabs: string[] = ['Account', 'Authorizations', 'People'];

  aasDataTileListRowTypeEnum = AasDataTileListRowTypeEnum;

  accountFirmContacts$: Observable<AccountFirmContactsStateType>;

  constructor(private featureFlagService: FeatureFlagService, private accountDetailsFacade: AccountDetailsFacade) {}
  ngAfterViewInit(): void {
    this.setUpStickyComponents();
  }

  ngOnInit(): void {
    if (this.featureFlagService.isRiaPilotFirmContactsActive()) {
      this.riaPilotFirmContactsActive = true;
      this.tabs.push('Firm Contacts');
      this.accountFirmContacts$ = this.accountDetailsFacade.accountFirmContacts$;
    }
  }

  selectTab(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  setUpStickyComponents() {
    const defaultSpace: number = 16;
    const firstComponent = document.getElementById('brand-top-bar');
    const secondComponent = document.getElementById('mat-toolbar-main-page');
    const contactCardContainer = document.getElementById('contact-card-container');

    var firstHeight = firstComponent ? firstComponent.offsetHeight : 0;
    var secondHeight = secondComponent ? secondComponent.offsetHeight : 0;
    var topValue = defaultSpace + firstHeight + secondHeight;

    if (contactCardContainer) contactCardContainer.style.top = topValue + 'px';
  }
}
