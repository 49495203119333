export enum OrderStatus {
  ORDER_EXECUTED = 'E',
  WAITING_FOR_EXECUTION = 'W',
  AWAITING_CANCELLATION = 'A',
  CANCELLATION_OPERATION = 'C',
  ORDER_REJECTED = 'R',
  WAITING_TO_BE_SENT = 'B',
  PARTIALLY_EXECUTED = 'P',
  CANCEL_REPLACE_REQUEST_PLACED = 'G',
}
