<div class="axos-trading-statements container">
  <div class="row">
    <div class="main-container">
      <div class="header">
        <div class="back-button-container">
          <button
            class="back-button"
            (click)="goBack()"
            uk2LabeledIconButton
            mat-button
            disableRipple
            [uk2LabeledIconPosition]="uk2LabeledIconPosition"
            [uk2ButtonSize]="uk2LabeledIconSize"
          >
            <span class="back-button-label">Back</span>
            <mat-icon [svgIcon]="chevronLeftIcon"></mat-icon>
          </button>
        </div>
        <div class="main_title">
          <h3>Statements & Documents</h3>
        </div>
        <div class="header__filters">
          <div class="header__filter">
            <label class="select-label" for="yearFilterLabel">Choose Year</label>
            <select
              id="yearFilterDdl"
              class="uk-input select-input"
              [(ngModel)]="selectedYear"
              (ngModelChange)="filterByYear(selectedYear)"
            >
              <option value="All" selected>All</option>
              <option *ngFor="let year of getUniqueYears(statements, taxForms)" [value]="year">{{ year }}</option>
            </select>
          </div>
          <div class="header__filter">
            <label class="select-label" for="docTypeLabel">Document Type</label>
            <select
              id="documentType"
              class="uk-input select-input"
              [(ngModel)]="documentType"
              (ngModelChange)="filterByDocument()"
            >
              <option value="All">All</option>
              <option *ngFor="let type of documentTypes" [value]="type">{{ type }}</option>
            </select>
          </div>
          <div class="header__filter__jump">
            <button class="btn__link res">
              <a href="#documents">Jump to documents</a>
              <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="statements-and-documents-container">
        <div class="statements">
          <h4 class="title_statements">Statements</h4>
          <div *ngFor="let statement of filteredStatements">
            <h4 class="month-year-title">{{ statement.statementDate | date: 'MMMM yyyy' }}</h4>
            <div class="uk-list">
              <ul class="list">
                <li class="list-item">
                  <mat-icon [svgIcon]="pdfIcon"></mat-icon>
                  <span>
                    <p>{{ statement.statementDescription }}</p>
                  </span>
                  <a (click)="downloadDocument(statement, false)">View</a>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="filteredStatements.length === 0" class="empty_statements">
            You do not currently have any statements
          </div>
        </div>

        <div id="documents" class="documents">
          <h4 class="title_documents">Documents</h4>
          <div *ngFor="let document of filteredTaxForms; index as i; first as isFirst">
            <div>
              <h4 class="year_title" *ngIf="isFirst || filteredTaxForms[i - 1].taxYear != document.taxYear">
                {{ document.taxYear }}
              </h4>
              <div class="description-container">
                <a class="description" (click)="downloadDocument(document, false)">
                  {{ document.taxFormDescription }}
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="filteredTaxForms.length === 0" class="empty_documents">
            No Documents found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
