<div
  class="account-group"
  *ngFor="
    let accountGroup of accountGroups;
    trackBy: trackByGroupKey;
    last as isLast
  "
>
  <div class="header" (click)="toggleExpandedGroup(accountGroup[0].key)">
    <div>
      <div class="name">{{ accountGroup[0].key }}</div>
      <div class="count mt-1">
        <span>
          <span
            *ngIf="
              getAccountsSize(accountGroup, true) > 1 ||
              getSelectedAccountsSize(accountGroup, true) == 0
            "
          >
            {{ getSelectedAccountsSize(accountGroup, true) }} of
          </span>
          {{ getAccountsSize(accountGroup, true) }}
          <span>Account</span>
          <span *ngIf="getAccountsSize(accountGroup, true) > 1">s</span>
        </span>
      </div>
    </div>
    <app-svg-icon
      [iconName]="
        expandedGroups.has(accountGroup[0].key)
          ? icons.ChevronUp
          : icons.ChevronDown
      "
      [styles]="{ width: '1.1875rem' }"
    ></app-svg-icon>
  </div>

  <div
    [ngClass]="{ last: isLast }"
    [ngStyle]="
      expandedGroups.has(accountGroup[0].key) ? {} : { display: 'none' }
    "
  >
    <div
      class="accnt"
      *ngFor="let account of accountGroup; trackBy: trackByGlobalId"
    >
      <div class="d-flex align-items-center account-info">
        <img
          class="logo"
          *ngIf="
            providers && (hasImage(account.value) || !account.value.isExternal)
          "
          [src]="getImage(account.value)"
          [ngClass]="{ invest: account.value.isAxosInvest }"
          [class.red-circle]="account.value.credentialsHaveChanged"
        />
        <span
          class="logo"
          [class.red-circle]="account.value.credentialsHaveChanged"
          *ngIf="
            providers && !hasImage(account.value) && account.value.isExternal
          "
        >
          {{ getBankProfile(account.value) }}
        </span>

        <div style="flex: 1;">
          <div class="name">{{ getAccountName(account.value) }}</div>
          <div class="balance d-flex mt-1">
            {{ getBalance(account.value) | currency }}
            <acc-account-warning-tooltip
              [account]="account.value"
            ></acc-account-warning-tooltip>
          </div>
        </div>
      </div>

      <div class="d-flex uk2-checkbox-container">
        <div
          *ngIf="account.value.credentialsHaveChanged"
          class="last-updated mr-2"
        >
          {{ getTimePeriod(account.value.lastUpdated) }}
        </div>
        <mat-checkbox
          uk2Checkbox
          disableRipple
          (click)="toggleSelectedAccount(account.value.globalId)"
          [checked]="!filter.has(account.value.globalId)"
          class="account-selector"
        ></mat-checkbox>
      </div>
    </div>

    <div
      class="text-center mt-4"
      *ngIf="getAccountsSize(accountGroup, true) > 1"
    >
      <span
        *ngIf="
          getSelectedAccountsSize(accountGroup, true) ==
          getAccountsSize(accountGroup, true)
        "
        class="link"
        (click)="deselectWholeGroup(accountGroup)"
      >
        Deselect All
      </span>
      <span
        *ngIf="
          getSelectedAccountsSize(accountGroup, true) !=
          getAccountsSize(accountGroup, true)
        "
        class="link"
        (click)="selectWholeGroup(accountGroup)"
      >
        Select All
      </span>
    </div>
  </div>
</div>

<div class="cta-footer">
  <div
    class="btn primary"
    style="max-width: 15.625rem;"
    (click)="goToAddAccount()"
  >
    + Add Account
  </div>
  <div class="link mt-4" (click)="onReset()">Reset</div>
</div>
