<div class="axos-invest-transactions">
  <h3 class="transactions-title">Transactions</h3>
  <app-dot-loader *ngIf="isLoadingRecurringDeposit">
    Loading recurring deposits...
  </app-dot-loader>
  <app-recurring-deposit
    *ngIf="recurringDesposits?.length > 0 && !isLoadingRecurringDeposit"
    [recurringDesposits]="recurringDesposits"
    [referrerTabId]="referrerTabId"
  ></app-recurring-deposit>
  <app-filters (filtersUpdated)="getTransactions($event)"></app-filters>
  <app-table [hidden]="isLoading" [transactions]="transactions"></app-table>
  <app-dot-loader *ngIf="isLoading">Loading transactions...</app-dot-loader>
  <p class="transactions-disclaimer" *ngIf="!isLoading">
    Transactions shown represent a summary of the latest transactions in your account. For more specific details on your
    transactions, please review your account statement provided by our clearing firm.
  </p>
</div>
