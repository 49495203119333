import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { finalize } from 'rxjs/operators';

import { validPhoneRegex } from '@app/config/regexes';
import { ProfileType, UpdatePhoneStatus } from '@app/user-profile/enums';
import { UpdatePhonesRequest } from '@app/user-profile/models';
import { sanitizePhone } from '@app/user-profile/utils';
import { BusinessService, UserProfileService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AccessCodeModalComponent, SmsActivationModalComponent } from '@shared/components/modals';
import { AccessCodeModalData } from '@shared/components/modals/access-code-modal/access-code-modal.component';
import { SmsActivationModalData } from '@shared/components/modals/sms-activation-modal/sms-activation-modal.component';
import { PhoneType } from '@shared/enums';
import { CompanyDetail, CustomerDetail } from '@shared/models';
import { BusinessDetail } from '@shared/models/business-detail.model';

interface PhonesForm {
  cellPhone1: string;
  cellPhone2: string;
  homePhone: string;
  workPhone: string;
  workFax: string;
  workPhoneExtension: string;
}

@Component({
  selector: 'up-change-phone',
  templateUrl: './change-phone.component.html',
  styleUrls: ['../../scss/user-profile.common.scss', './change-phone.component.scss'],
})
export class ChangePhoneComponent implements OnInit {
  @Input() userProfile: Partial<CustomerDetail>;
  @Input() profileType = ProfileType.Personal;
  @Input() isFax: boolean;
  @Input() selectedBusinessId = 0;
  @Output() back = new EventEmitter<null>();
  @Output() updatePhones = new EventEmitter<Partial<UpdatePhonesRequest>>();
  @Output() updateBusinessPhone = new EventEmitter<Partial<BusinessDetail>>();

  public readonly NON_CLEANABLE_OBJECT_KEYS = [
    'otp',
    'selectedBusinessId'
  ];

  profileTypes = ProfileType;
  companyDataModel: CompanyDetail;

  phoneForm: UntypedFormGroup;
  isBusy: boolean;
  errors = {
    required: 'Please provide a primary phone number.',
    pattern: 'Please provide a valid 10-digit phone number.',
    sameNumber: 'Cannot be all same number',
    consecutiveNumber: 'Cannot be consecutive 5 digits in numerical order',
    invalidStartCharacter: 'Cannot start with “1” or “0”',
  };

  companyErrors = {
    required: 'Please provide a primary business phone number.',
    pattern: 'Please provide a valid 10-digit phone number.',
    sameNumber: 'Cannot be all same number',
    consecutiveNumber: 'Cannot be consecutive 5 digits in numerical order',
    invalidStartCharacter: 'Cannot start with “1” or “0”',
  };

  modalWidth = 576;
  maxExtensionLength = 4;
  errorMessage: string;

  businessDataModel: BusinessDetail;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogs: MatDialog,
    private userProfileService: UserProfileService,
    private businessService: BusinessService,
    private serviceHelper: ServiceHelper,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  openTermsModal(request: Partial<UpdatePhonesRequest>) {
    this.dialogs
      .open<SmsActivationModalComponent, SmsActivationModalData>(SmsActivationModalComponent, {
        data: {
          termsAndConditionsUrl: this.$rootScope['brandProperties']['SMSTermsAndConditions'],
          phonesRequest: request,
        },
        maxWidth: this.modalWidth,
      })
      .afterClosed()
      .subscribe(otpOrAction => {
        if (otpOrAction !== 'cancel') {
          this.openAccessCodeModal(request, otpOrAction);
        } else {
          this.back.emit();
        }
      });
  }

  openAccessCodeModal(request: Partial<UpdatePhonesRequest>, otp: string) {
    this.dialogs
      .open<AccessCodeModalComponent, AccessCodeModalData>(AccessCodeModalComponent, {
        data: {
          otp,
          updateRequest: request,
        },
        maxWidth: this.modalWidth,
      })
      .afterClosed()
      .subscribe({
        next: verified => {
          if (verified) {
            this.updatePhones.emit(request);
          }

          this.back.emit();
        },
      });
  }

  updatePhoneNumbers(): void {
    if (this.phoneForm.invalid) {
      this.phoneForm.markAllAsTouched();
    } else {
      switch (this.profileType) {
        case ProfileType.Business:
          this.isBusy = true;
          const { cellPhone1, workPhone, workPhoneExtension } = this.phoneForm.value;
          let businessUpdate = { ...this.userProfile.businessInformation };
          const businessCellPhone = { ...businessUpdate.cellPhone };
          const businessWorkPhone = { ...businessUpdate.workPhone };
          businessCellPhone.number = cellPhone1;
          businessWorkPhone.number = workPhone;
          businessWorkPhone.extension = workPhoneExtension;

          businessUpdate = { ...businessUpdate, cellPhone: businessCellPhone, workPhone: businessWorkPhone };
          this.businessService.updateBusinessInformationPhone(businessUpdate).subscribe({
            next: result => {
              this.updateBusinessPhone.emit(result.data);
              this.back.emit();
            },
            error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
          });

          break;
        case this.profileTypes.Company:
          this.companyPhoneUpdate();
          break;
        default:
          {
            this.isBusy = true;
            const form = this.phoneForm.value as PhonesForm;
            const updatePhonesRequest: Partial<UpdatePhonesRequest> = {
              cellPhone1: {
                phoneType: PhoneType.HomeCellPhone,
                number: form.cellPhone1,
                isPrimary: true,
              },
              cellPhone2: {
                phoneType: PhoneType.BusinessCellPhone,
                number: form.cellPhone2,
                isPrimary: false,
              },
              homePhone: {
                phoneType: PhoneType.HomePhone,
                number: form.homePhone,
                isPrimary: false,
              },
              workPhone: {
                phoneType: PhoneType.BusinessPhone,
                number: form.workPhone,
                extension: form.workPhoneExtension,
                isPrimary: false,
              },
            };

            Object.keys(updatePhonesRequest).forEach(key => {
              if (!form[key]) {
                updatePhonesRequest[key] = {};
              }
            });

            this.userProfileService
              .checkUpdatePhones(updatePhonesRequest)
              .pipe(
                finalize(() => {
                  this.isBusy = false;
                })
              )
              .subscribe({
                next: response => {
                  if (response.data.status === UpdatePhoneStatus.Challenge) {
                    this.openTermsModal(updatePhonesRequest);
                  } else if (response.data.status === UpdatePhoneStatus.Complete) {
                    this.updatePhones.emit(updatePhonesRequest);
                    this.back.emit();
                  } else {
                    this.errorMessage = response.message;
                  }
                },
                error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
              });
          }
          break;
      }
    }
  }

  private createForm(): void {
    switch (this.profileType) {
      case ProfileType.Business:
        this.businessDataModel = this.userProfile.businessInformation;
        this.phoneForm = this.fb.group({
          cellPhone1: this.fb.control(sanitizePhone(this.userProfile.businessInformation.cellPhone), [
            Validators.pattern(validPhoneRegex),
            this.invalidStartingCharacter,
            this.consecutiveNumbers,
            this.allSameNumbers,
          ]),
          workPhone: this.fb.control(sanitizePhone(this.userProfile.businessInformation.workPhone), [
            Validators.pattern(validPhoneRegex),
            this.invalidStartingCharacter,
            this.consecutiveNumbers,
            this.allSameNumbers,
          ]),
          workPhoneExtension: this.fb.control(this.userProfile.businessInformation.workPhone.extension, [
            this.invalidStartingCharacter,
            this.consecutiveNumbers,
            this.allSameNumbers,
          ]),
        });
        break;
      case this.profileTypes.Company:
        {
          if (!this.isFax) {
            this.phoneForm = this.fb.group({
              workPhone: this.fb.control(sanitizePhone(this.userProfile.workPhone), [
                Validators.required,
                Validators.pattern(validPhoneRegex),
                this.invalidStartingCharacter,
                this.consecutiveNumbers,
                this.allSameNumbers,
              ]),
              workPhoneExtension: this.fb.control(this.userProfile.workPhone.extension, [
                this.invalidStartingCharacter,
                this.consecutiveNumbers,
                this.allSameNumbers,
              ]),
            });
          } else {
            this.phoneForm = this.fb.group({
              workFax: this.fb.control(sanitizePhone(this.userProfile.workFax), [
                Validators.pattern(validPhoneRegex),
                this.invalidStartingCharacter,
                this.consecutiveNumbers,
                this.allSameNumbers,
              ]),
            });
          }
        }
        break;
      default:
        {
          this.phoneForm = this.fb.group({
            cellPhone1: this.fb.control(sanitizePhone(this.userProfile.cellPhone1), [
              Validators.required,
              Validators.pattern(validPhoneRegex),
              this.invalidStartingCharacter,
              this.consecutiveNumbers,
              this.allSameNumbers,
            ]),
            cellPhone2: this.fb.control(sanitizePhone(this.userProfile.cellPhone2), [
              Validators.pattern(validPhoneRegex),
              this.invalidStartingCharacter,
              this.consecutiveNumbers,
              this.allSameNumbers,
            ]),
            homePhone: this.fb.control(sanitizePhone(this.userProfile.homePhone), [
              Validators.pattern(validPhoneRegex),
              this.invalidStartingCharacter,
              this.consecutiveNumbers,
              this.allSameNumbers,
            ]),
            workPhone: this.fb.control(sanitizePhone(this.userProfile.workPhone), [
              Validators.pattern(validPhoneRegex),
              this.invalidStartingCharacter,
              this.consecutiveNumbers,
              this.allSameNumbers,
            ]),
            workPhoneExtension: this.fb.control(this.userProfile.workPhone.extension, [
              this.invalidStartingCharacter,
              this.consecutiveNumbers,
              this.allSameNumbers,
            ]),
          });
        }
        break;
    }
  }

  private companyPhoneUpdate(): void {
    this.isBusy = true;
    const form = this.phoneForm.value as PhonesForm;
    let updatePhonesRequest: Partial<UpdatePhonesRequest>;
    if (!this.isFax) {
      updatePhonesRequest = {
        workPhone: {
          phoneType: PhoneType.BusinessPhone,
          number: form.workPhone,
          extension: form.workPhoneExtension,
          isPrimary: false,
        },
        selectedBusinessId: this.selectedBusinessId,
      };
    } else {
      updatePhonesRequest = {
        workFax: {
          phoneType: PhoneType.BusinessFax,
          number: form.workFax,
          isPrimary: false,
        },
        selectedBusinessId: this.selectedBusinessId,
      };
    }

    Object.keys(updatePhonesRequest).forEach(key => {
      if (!form[key] && !this.NON_CLEANABLE_OBJECT_KEYS.includes(key)) {
        updatePhonesRequest[key] = {};
      }
    });

    this.businessService
      .updateCompanyPhone(updatePhonesRequest)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: () => {
          this.updatePhones.emit(updatePhonesRequest);
          this.back.emit();
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  private invalidStartingCharacter(control: AbstractControl): { [key: string]: any } | null {
    if (/^[01]/.test(control.value)) {
      return { invalidCharacter: { value: control.value } };
    }
  }

  private allSameNumbers(control: AbstractControl): { [key: string]: any } | null {
    if (/(\d)\1{9}/.test(control.value)) {
      return { allSame: { value: control.value } };
    }
  }

  private consecutiveNumbers(control: AbstractControl): { [key: string]: any } | null {
    const patternForward = '01234567890123456789';
    const patternBackward = '09876543210987654321';

    const sPhoneNumber = String(control.value);

    for (let i = 0; i <= sPhoneNumber.length - 5; i++) {
      if (
        patternForward.includes(sPhoneNumber.substring(i, i + 5)) ||
        patternBackward.includes(sPhoneNumber.substring(i, i + 5))
      ) {
        return { sequentialNumbers: { value: control.value } };
      }
    }
  }
}
