import { IScope } from 'angular';

import { Inject } from '../../../decorators/decorators';

@Inject('$uibModalInstance', '$scope', 'spouseConsentEmail')
export class SpousalConsentModalController {
  bodyText = `<h3>You cannot modify beneficiaries while spousal consent is pending</h3></br>
  <p>You can resend a consent request to your spouse or cancel the consent which reverts your beneficiaries</p>`;
  spouseEmail: string;
  resendSpousalConsent = false;
  resendButtonText = 'Resend Consent';
  cancelButtonText = 'Cancel Consent and Revert';
  cancelButtonClass = 'uk-btn outline primary lg small-text';
  constructor(
    private _uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private scope: IScope,
    readonly spouseConsentEmail: string
  ) {}

  $onInit(): void {
    this.spouseEmail = this.spouseConsentEmail;
  }

  close() {
    this._uibModalInstance.close('ok');
  }

  cancelResendSpousalConsent(resend: boolean) {
    if (this.resendSpousalConsent && !resend) {
      this.close();

      return;
    }
    this.close();
    this.scope.$emit('cancelResendSpousalConsent', resend, this.spouseEmail);
  }
  resendSpousalConsentEmail(resend: boolean) {
    if (this.resendSpousalConsent) {
      this.cancelResendSpousalConsent(resend);
    }
    this.bodyText = `<h3>We'll resend a consent link to your spouse's email below</h3>`;
    this.resendSpousalConsent = true;
    this.resendButtonText = 'Resend Email';
    this.cancelButtonText = 'Cancel';
    this.cancelButtonClass = 'uk-btn link';
  }
  isEmailValid(email: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  }
}
