<ng-container [ngSwitch]="currentView">
  <up-section-heading
    [section]="currentView"
    [canReturn]="currentView !== views.ContactInfo"
    [mobile]="isMobileDevice && currentView === views.ContactInfo"
    (return)="goBack()"
    (expand)="expanded = $event"
  ></up-section-heading>

  <div *ngSwitchDefault [@startStaticSlideFromLeft]="animationState">
    <div class="section__heading">
      <p class="mb-3" *ngIf="!hasOutdatedContactInfo; else outdatedInfoBanner">
        Ensure your address, phone number and email are up-to-date.
      </p>

      <ng-template #outdatedInfoBanner>
        <app-outdated-contact-info-banner></app-outdated-contact-info-banner>
      </ng-template>
    </div>

    <div *ngIf="!isMobileDevice || expanded" @openClose [@.disabled]="!isMobileDevice">
      <up-profile-item
        itemName="Email"
        [editable]="userCanEdit"
        (edit)="goTo(views.Email)"
        [alertMessage]="alertMessages.email"
        class="email"
      >
        <app-dot-loader *ngIf="profileLoading; else emailContent"></app-dot-loader>
        <ng-template #emailContent>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters">
            <p class="font-weight-bold col-12 col-lg-5">Primary:</p>
            <div class="col-12 col-lg-7 email-container">
              <p
                [attr.aria-label]="profileInfo.primaryEmail"
                class="font-weight-normal profile-email"
                [innerText]="profileInfo.primaryEmail"
              ></p>
              <div aria-hidden="true" class="uk-bottom-tip" [attr.data-uk-tooltip]="profileInfo.primaryEmail"></div>
            </div>
          </div>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters">
            <p class="font-weight-bold col-12 col-lg-5">Alternate:</p>
            <div class="col-12 col-lg-7 email-container">
              <p
                [attr.aria-label]="profileInfo.alternateEmail"
                class="font-weight-normal profile-email"
                [innerText]="profileInfo.alternateEmail"
              ></p>
              <div aria-hidden="true" class="uk-bottom-tip" [attr.data-uk-tooltip]="profileInfo.alternateEmail"></div>
            </div>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Phone"
        [editable]="userCanEdit"
        (edit)="goTo(views.Phone)"
        [alertMessage]="alertMessages.phone"
        class="phone"
      >
        <app-dot-loader *ngIf="profileLoading; else phoneContent"></app-dot-loader>
        <ng-template #phoneContent>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 cell-phone-1">
            <p class="col-12 col-lg-5 font-weight-bold">Primary Cell:</p>
            <p class="col-12 col-lg-7 phone invalid-field" *ngIf="!phones.cellPhone1; else primaryCell">
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Phone Number Needed
            </p>
            <ng-template #primaryCell>
              <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="phones.cellPhone1 | phone"></p>
            </ng-template>
          </div>
          <div *ngIf="phones.cellPhone2" class="d-flex flex-column flex-lg-row no-gutters mb-3 cell-phone-2">
            <p class="col-12 col-lg-5 font-weight-bold">Alternate Cell:</p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="phones.cellPhone2 | phone"></p>
          </div>
          <div *ngIf="phones.homePhone" class="d-flex flex-column flex-lg-row no-gutters mb-3 home-phone">
            <p class="col-12 col-lg-5 font-weight-bold">Home:</p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="phones.homePhone | phone"></p>
          </div>
          <div *ngIf="phones.workPhone" class="d-flex flex-column flex-lg-row no-gutters mb-3 work-phone">
            <p class="col-12 col-lg-5 font-weight-bold">Work:</p>
            <p class="col-12 col-lg-7 font-weight-normal value">
              {{ phones.workPhone | phone }}&nbsp;
              <span *ngIf="profileInfo.workPhone.extension" [innerText]="'x' + profileInfo.workPhone.extension"></span>
            </p>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Address"
        [editable]="canEditAddress"
        (edit)="goTo(views.Address)"
        [alertMessage]="alertMessages.address"
        class="address"
      >
        <app-dot-loader *ngIf="profileLoading; else addressContent"></app-dot-loader>
        <ng-template #addressContent>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 home-address">
            <p class="col-12 col-lg-5 font-weight-bold">Home Address:</p>
            <p class="col-12 col-lg-7 address invalid-field" *ngIf="primaryAddressIsIncomplete; else primaryAddress">
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Address Needed
            </p>
            <ng-template #primaryAddress>
              <p
                class="col-12 col-lg-7 font-weight-normal"
                [innerText]="profileInfo.primaryAddress.address | address"
              ></p>
            </ng-template>
          </div>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 mailing-address" *ngIf="mailingAddress">
            <p class="col-12 col-lg-5 font-weight-bold">Mailing Address:</p>
            <p
              class="col-12 col-lg-7 address invalid-field"
              *ngIf="mailingAddressIsIncomplete; else mailingAddressSection"
            >
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Address Needed
            </p>

            <ng-template #mailingAddressSection>
              <p class="col-12 col-lg-7 font-weight-normal" [innerText]="mailingAddress | address"></p>
            </ng-template>
          </div>
        </ng-template>
      </up-profile-item>

      <div class="d-flex justify-content-center my-4" *ngIf="hasOutdatedContactInfo">
        <button type="button" class="btn-ok col-12" (click)="confirmInfoIsUpdated()" [disabled]="!contactInfoIsValid">
          Everything is Correct
        </button>
      </div>
    </div>
  </div>

  <up-change-email
    @slideFromRight
    *ngSwitchCase="views.Email"
    [userProfile]="profileInfo"
    (updateEmails)="updateEmails($event)"
    (back)="goBack()"
  ></up-change-email>

  <up-change-phone
    @slideFromRight
    *ngSwitchCase="views.Phone"
    [userProfile]="profileInfo"
    (updatePhones)="updatePhones($event)"
    (back)="goBack()"
  ></up-change-phone>

  <up-change-address
    @slideFromRight
    *ngSwitchCase="views.Address"
    [userProfile]="profileInfo"
    (updateAddresses)="updateAddresses($event)"
    (back)="goBack()"
  ></up-change-address>
</ng-container>
