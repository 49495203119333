import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { allowedCheckImageMimeTypes, maxCheckImageFileSize } from '@app/config/constants';
import { DialogService } from '@core/services';
import { FilesIcons, UtilityIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

@Component({
  selector: 'md-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPhotoComponent {
  @ViewChild('pictureField')
  fileInput: ElementRef<HTMLInputElement>;

  @Input()
  imageDataUrl: string;

  @Output()
  imageLoad = new EventEmitter<string>();

  icon = UtilityIcons.UploadPhoto;
  dragOver = false;
  errors = {
    invalidFile: false,
    invalidSize: false,
  };
  validFileTypes = allowedCheckImageMimeTypes;

  constructor(private dialogService: DialogService) {}

  validateFile(fileList: FileList): void {
    const file = fileList[0];

    const fileIsValid = this.validFileTypes.includes(file.type);
    const fileHasRightSize = file.size <= maxCheckImageFileSize;

    this.errors = {
      invalidFile: !fileIsValid,
      invalidSize: !fileHasRightSize,
    };

    if (fileIsValid && fileHasRightSize) {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        this.imageLoad.emit(fileReader.result.toString());
        this.fileInput.nativeElement.value = null;
      };

      fileReader.readAsDataURL(file);
    }
  }

  showImageModal(): void {
    const data = new DialogData({
      title: '',
      content: `<img src="${this.imageDataUrl}" style="width:100%" />`,
      cancelText: null,
      okText: 'Close',
      icon: FilesIcons.Picture,
    });

    this.dialogService.open(data, true);
  }

  onDragFilesOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
  }

  onDragFilesLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
  }

  onDropFiles(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
    this.validateFile(event.dataTransfer.files);
  }
}
