export class FileHelperService {
  readonly ExceededMessage: string = 'The maximum file(s) size is 10MB.';

  FilesSizeExceeded(files: any[]): Boolean {
    var maxSize = 10485760; // 10 Mb
    var totalSize = 0;
    for (let index = 0; index < files.length; index++) {
      totalSize += files[index].Document.size;
    }

    return totalSize > maxSize;
  }
}

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blob: any, defaultName?: string) => boolean;
  }
}
