import { Action, createReducer, on } from '@ngrx/store';

import { loadEmploymentStasuses } from './employment-statuses.actions';

export const initialState: Record<string, string> = null;

const _reducer = createReducer<Record<string, string>>(
  initialState,
  on(loadEmploymentStasuses, (_state, action) => {
    return action.payload;
  })
);

export function employmentStatusesReducer(state: Record<string, string>, action: Action) {
  return _reducer(state, action);
}
