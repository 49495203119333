<app-funding-shared-header></app-funding-shared-header>
<app-view-header titleText="Make a Deposit"></app-view-header>

<div class="view-container">
  <form class="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto" [formGroup]="depositForm" (ngSubmit)="submitCheck()">
    <app-alert-notification
      id="api-error"
      class="mb-5"
      *ngIf="apiErrorMessage"
      alertType="error"
      [safeHtml]="apiErrorMessage"
      (dismiss)="clearErrorMessages()"
    ></app-alert-notification>

    <app-alert-notification
      class="mb-3 check-error"
      *ngFor="let error of errorMessages"
      alertType="error"
      (dismiss)="clearMessage(error)"
    >
      {{ error }}
    </app-alert-notification>

    <div class="form-group mt-5" [class.invalid-field]="toAccountField.hasError('*')">
      <label for="deposit-account" class="uk-label">Deposit To</label>

      <select
        id="deposit-account"
        name="deposit-account"
        class="uk-input"
        formControlName="depositToAccountNumberIndex"
      >
        <option value="">{{ accountsAreLoading ? 'Loading...' : '' }}</option>

        <option
          [value]="account.accountNumberIndex"
          *ngFor="let account of depositAccounts"
          [innerText]="account.description"
        ></option>
      </select>

      <div class="mt-1 text-error" ngxErrors="depositToAccountNumberIndex" #toAccountField="ngxErrors">
        <span
          *ngFor="let error of errors.depositToAccountNumberIndex"
          [ngxError]="error.name"
          [innerText]="error.message"
        ></span>
      </div>
    </div>

    <div class="form-group" [class.invalid-field]="amountField.hasError('*')">
      <label for="amount" class="uk-label">Check Amount</label>
      <input
        id="amount"
        type="text"
        inputmode="numeric"
        class="uk-input"
        formControlName="amount"
        mask="separator.2"
        thousandSeparator=","
        separatorLimit="100000"
        prefix="$"
      />
      <span class="limit-msg" *ngIf="closestLimit">
        The current limit for this account is ${{ closestLimit | number }}
      </span>

      <div class="mt-1 text-error" ngxErrors="amount" #amountField="ngxErrors">
        <span *ngFor="let error of errors.amount" [ngxError]="error.name" [innerText]="error.message"></span>
      </div>
    </div>

    <p class="text-center">Drag and drop your scanned check into the box below,</p>
    <p class="text-center mb-4">or click to browse to upload the photo.</p>

    <div class="form-group row justify-content-center">
      <div class="d-flex flex-column col-12 col-md-6 mb-3 mb-md-0">
        <p class="text-center check-text">Check Front</p>
        <md-upload-photo
          id="front-check"
          (imageLoad)="loadFrontCheck($event)"
          [imageDataUrl]="frontCheck"
        ></md-upload-photo>
      </div>
      <div class="d-flex flex-column col-12 col-md-6">
        <p class="text-center check-text">Check Back</p>
        <md-upload-photo
          id="back-check"
          (imageLoad)="loadBackCheck($event)"
          [imageDataUrl]="backCheck"
        ></md-upload-photo>
      </div>
    </div>

    <p class="text-center d-flex align-items-center justify-content-center mb-1">
      <app-svg-icon class="mr-2" [iconName]="icons.exclamationCircle"></app-svg-icon>
      Remember to sign the back of your check and write "For {{ settings.brandName }}
    </p>
    <p class="text-center mb-5">Mobile Deposit Only"</p>

    <div class="form__actions">
      <app-dot-loader *ngIf="depositInProgress; else formButtons"></app-dot-loader>

      <ng-template #formButtons>
        <button type="submit" class="btn-ok">Submit</button>
        <button type="button" class="btn-cancel" (click)="cancelOperation()">Cancel</button>
      </ng-template>
    </div>

    <p class="form__disclaimer">Deposits held up to 5 business days. Longer holds may apply.</p>
  </form>
</div>
