import { Pipe, PipeTransform } from '@angular/core';

import { maskEmail } from '@app/utils';

@Pipe({
  name: 'maskEmail',
})
export class MaskEmailPipe implements PipeTransform {
  transform(value: string): unknown {
    return maskEmail(value);
  }
}
