export const MULTIFACTOR = {
  title: 'We need to send you a verification code.',
  subtitle:
    "We're about done. To process your request, we are sending you a verification code to confirm your identity.",
};

export const P2P_MAIN = {
  subtitleStep1: 'Send money today with our person-to-person service.',
  subtitleStep2: 'Please follow the instructions below to confirm your identity and complete your ACH transfer.',
};
