import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { format } from 'date-fns';

import { AccountOrigin } from '@app/pfm/enums/account-origin.enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { AccountWorth, NetWorthPoint, Worth } from '@app/pfm/models/net-worth-point.model';
import { PfmAccountsService } from '@app/pfm/services/pfm-accounts.service';
import { getAccounts, getAccountsLoading } from '@app/pfm/store/accounts/accounts.selectors';
import { getNetWorthSeries, getNetWorthSeriesLoading } from '@app/pfm/store/net-worth/net-worth.selectors';
import { NavigationIcons, UtilityIcons } from '@shared/enums';

import { LineChartPoint } from '../line-chart/models/point.model';

@Component({
  selector: 'app-net-worth-module',
  templateUrl: './net-worth-module.component.html',
  styleUrls: ['./net-worth-module.component.scss'],
})
export class NetWorthModuleComponent implements OnInit, OnDestroy {
  netWorthPeriodSeries: NetWorthPoint[];
  netWorthLoading = this.store.select(getNetWorthSeriesLoading);
  accountsLoading = this.store.select(getAccountsLoading);
  currentNetWorth: NetWorthPoint;
  previousNetWorth: NetWorthPoint;
  lineChartPoints: LineChartPoint[] = [];
  percentageChange: number;
  dollarChange: number;
  lastMonth: Date;
  isEmpty = false;
  subsink = new SubSink();
  icons = {
    ArrowRight: NavigationIcons.ChevronRight,
    ArrowUp: UtilityIcons.ArrowUp,
    ArrowDown: UtilityIcons.ArrowDown,
  };
  accounts: AccountOverview[];

  constructor(private store: Store, private pfmAccountsService: PfmAccountsService) {}

  ngOnInit(): void {
    this.subsink.sink = this.store.select(getAccounts).subscribe(accounts => {
      this.accounts = accounts;
    });

    this.subsink.sink = this.store
      .select(getNetWorthSeries)
      .pipe(filter(series => series != null))
      .subscribe(series => {
        this.netWorthPeriodSeries = series.filter(x => x.worth !== null);

        this.netWorthPeriodSeries = this.addInternalDataToSeries(this.netWorthPeriodSeries);

        this.previousNetWorth = this.netWorthPeriodSeries[0];
        this.currentNetWorth = this.netWorthPeriodSeries[this.netWorthPeriodSeries.length - 1];
        if (this.currentNetWorth && this.previousNetWorth) {
          this.percentageChange =
            this.previousNetWorth.worth.net !== 0
              ? (this.currentNetWorth.worth.net - this.previousNetWorth.worth.net) / this.previousNetWorth.worth.net
              : 0;
          this.dollarChange = this.currentNetWorth.worth.net - this.previousNetWorth.worth.net;
        } else {
          this.isEmpty = true;
        }

        this.lineChartPoints = series.map(point => ({
          date: point.date,
          amount: point.worth?.net ?? 0,
          isDashed: point.worth == null,
        }));
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  lineChartXAxisTickFormatter(date: Date, index: number, dates: Date[]) {
    if (index === dates.length - 1) return 'Today';

    return `   ${format(date, 'M/d')}   `;
  }

  showSignValue() {
    return this.dollarChange > 0 ? '+' : '';
  }

  getArrow() {
    if (this.percentageChange === 0 || !this.currentNetWorth || !this.previousNetWorth) return;

    return this.currentNetWorth.worth.net > this.previousNetWorth.worth.net ? this.icons.ArrowUp : this.icons.ArrowDown;
  }

  getInternalAccounts(): AccountOverview[] {
    if (!this.accounts) return [];

    return this.accounts.filter(
      account =>
        account.origin === AccountOrigin.AxosInvest ||
        account.origin === AccountOrigin.Clearing
    );
  }

  private addInternalDataToSeries(netWorthSeries: NetWorthPoint[]) {
    netWorthSeries = netWorthSeries.map((series, index) => {
      if (index === netWorthSeries.length - 1) {
        const internalAccounts = this.getInternalAccounts();
        if (internalAccounts.length === 0) return series;
        const internaLAccountsTotalBalance = internalAccounts.reduce((sum, account) => {
          if (this.pfmAccountsService.getIsAsset(account.categoryName)) return sum + account.availableBalance;

          return sum - account.availableBalance;
        }, series.worth.asset);
        const netWithInternalAccountsBalance = internalAccounts.reduce((sum, account) => {
          if (this.pfmAccountsService.getIsAsset(account.categoryName)) return sum + account.availableBalance;

          return sum - account.availableBalance;
        }, series.worth.net);
        const internalAccountWorths: AccountWorth[] = internalAccounts.map(sdtAccount => {
          return {
            account: sdtAccount,
            isAsset: this.pfmAccountsService.getIsAsset(sdtAccount.categoryName),
            balance: sdtAccount.availableBalance,
          };
        });
        const worthWithInternalBalances: Worth = {
          ...series.worth,
          asset: internaLAccountsTotalBalance,
          net: netWithInternalAccountsBalance,
          accountWorths: series.worth.accountWorths.concat(...internalAccountWorths),
        };
        series = {
          ...series,
          worth: worthWithInternalBalances,
        };

        return series;
      }

      return series;
    });

    return netWorthSeries;
  }
}
