import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm, UntypedFormGroup, Validators } from '@angular/forms';
import { DATERANGEPICKEROPTIONS } from '@app/bill-pay/ajs-upgraded-provider';
import { AccountsService } from '@core/services';
import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { LoadUserProfileHelper } from '@legacy/services/load-user-profile-helper';
import { ModalService } from '@legacy/services/modal.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { StopPaymentService } from '@legacy/services/stop-payment.service';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { StopPayment, StopType } from '@legacy/typings/app/StopPayment';
import { DatePickerEvents, DatePickerLocaleOptions } from '@shared/models';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-stop-payment-add',
  templateUrl: './stop-payment-add.component.html',
  styleUrls: ['./stop-payment-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopPaymentAddComponent implements OnInit, OnDestroy {
  accountId: string;
  account: OlbAccount;
  stopPayment: StopPayment;
  stopType: StopType;
  isBusy = false;
  currentStep = 0;
  datePickerOptions: any;
  datePicker: any;
  displayAmount = '0.00';
  fee = 0;
  expirationTime = 6;
  stopPaymentForm: UntypedFormGroup;
  userEmail: string;
  businessEmail: string;
  formNumber: number;
  terms: string;
  readonly maxMinMonths: number = 12;
  isReadOnly: boolean;
  private listeners: Function[] = [];
  effectiveDate: moment.Moment;

  constructor(
    @Inject(STATE_PARAMS) private readonly params: ng.ui.IStateParamsService,
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private readonly root: ng.IRootScopeService,
    private readonly accountService: AccountsService,
    private readonly stopPaymentService: StopPaymentService,
    private readonly serviceHelper: ServiceHelper,
    @Inject(DATERANGEPICKEROPTIONS) private readonly dateRangePickerOptions: any,
    @Inject('$scope') private readonly scope: ng.IScope,
    private readonly dateHelper: DateHelper,
    private readonly modalService: ModalService,
    private readonly loadUserProfileHelper: LoadUserProfileHelper,
    private readonly featureFlagService: FeatureFlagService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /** Initializes any required data */
  ngOnInit(): void {
    this.stopPayment = {};
    this.stopPayment.amount = +'0.00';
    this.accountId = this.params['id'];
    this.getSettingsConfig();

    if (this.root['brandProperties']) {
      this.terms = this.root['brandProperties']['TermsStopPaymentURL'];
    }

    this.listeners.push(
      this.scope.$on('brandPropertiesLoaded', () => {
        this.terms = this.root['brandProperties']['TermsStopPaymentURL'];
      })
    );

    if (!this.root['accounts']) {
      this.isBusy = true;
      this.accountService
        .getAccount(this.accountId)
        .pipe(
          finalize(() => {
            this.isBusy = false;
            this.changeDetectorRef.detectChanges();
          })
        )
        .subscribe({
          next: res => {
            this.account = res.data;
          },
          error: this.serviceHelper.errorHandler,
        });
    } else {
      const { depositAccounts, loanAccounts } = this.root['accounts'];
      const accounts = [...depositAccounts, ...loanAccounts];
      this.account = accounts.find(a => a.id === +this.accountId);
    }

    this.datePickerOptions = angular.extend(angular.copy(this.dateRangePickerOptions), {
      minDate: moment(),
      maxDate: moment().add(this.maxMinMonths, 'months'),
      locale: {
        format: 'MM/DD/YY',
      } as DatePickerLocaleOptions,
      singleDatePicker: true,
      eventHandlers: {
        'show.daterangepicker': (_evt: any) => {
          if (this.stopType === StopType.ACH) {
            if (!document.getElementsByClassName('calendar-disclaimer').length) {
              $('.daterangepicker').append(`<div class="calendar-disclaimer">
                  <p>Please Note: For stop payments placed within <span>3 BUSINESS DAYS</span> from date of the expected debit,
                  please contact us at 877-247-8018 for assistance.</p></div>`);
            }
          }
        },
      },
    });

    this.datePicker = {
      startDate: moment(),
      endDate: null,
    };
    this.fee = this.account.stopPayFee;
    this.isReadOnly = this.featureFlagService.isSiteInReadOnly();
  }

  ngOnDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  onChangeDate(date, event: DatePickerEvents) {
    date.control.markAsDirty();
    date.control.setValue(event.picker.startDate.toDate());
    this.datePicker.startDate = event.picker.startDate;
  }

  /** Sets the minDate for ACH and passes to the next step */
  selectAch(): void {
    this.datePickerOptions.isInvalidDate = (date: any) => {
      return !this.dateHelper.isValidDate(date);
    };

    this.effectiveDate = this.datePicker = this.datePickerOptions.minDate = this.dateHelper.addWeekdays(moment(), 3);
    this.datePickerOptions.maxDate = moment(this.effectiveDate).add(this.maxMinMonths, 'months');
    this.currentStep = 1;
    this.stopType = StopType.ACH;
  }

  /** Sets the minDate for Checks and passes to the next step */
  selectCheck(): void {
    this.datePicker = moment();
    this.datePickerOptions.isInvalidDate = () => {
      return false;
    };

    this.effectiveDate = this.dateHelper.isValidDate(moment()) ? moment() : this.dateHelper.addWeekdays(moment(), 1);

    this.datePickerOptions.maxDate = moment(this.effectiveDate).add(this.maxMinMonths, 'months').toDate();
    this.datePickerOptions.minDate = moment(this.effectiveDate).subtract(this.maxMinMonths, 'months').toDate();

    this.currentStep = 1;
    this.stopType = StopType.Check;
  }

  /** Go one step back in the process of adding a Stop Payment */
  goBack(): void {
    if (this.currentStep === 0) {
      this.state.go('udb.accounts.stop-payment', { id: this.accountId });
    } else {
      this.currentStep--;
    }
  }

  /**
   * Validates the form according the stop type chosen
   * @param e Angular event when submitting the form
   */
  confirmStopPayment(stopPaymentForm: NgForm): void {
    if (stopPaymentForm.invalid) return;

    this.stopPayment.checkDate = this.datePicker.startDate
      ? this.datePicker.startDate.toDate()
      : this.datePicker.toDate();
    this.stopPayment.expirationDate = this.getExpirationDate();
    this.stopPayment.effectiveDate = this.effectiveDate.toDate();
    this.stopPayment.requestedDate = moment().toDate();
    this.stopPayment.stopCharge = this.fee;
    this.stopPayment.accountId = this.accountId;

    switch (this.stopType) {
      case StopType.Check:
        this.stopPayment.highCheckNumber = this.stopPayment.lowCheckNumber;
        break;
      case StopType.ACH:
        this.stopPayment.highCheckNumber = this.stopPayment.lowCheckNumber = null;
        break;
    }

    this.placeStopPayment(true, false, 2);
  }

  /** Places the Stop Payment by performing the call to the API */
  placeStopPayment(validate: boolean, stopClearedCheck: boolean, nextStep: number): void {
    this.isBusy = true;
    this.stopPayment.stopClearedCheck = stopClearedCheck;

    this.stopPaymentService
      .addStopPayment(this.stopPayment, validate)
      .then(res => {
        this.userEmail = this.loadUserProfileHelper.getUserProfile().primaryEmail;
        if (!validate) this.stopPayment.sequenceId = res.data;
        this.currentStep = nextStep;
      })
      .catch(err => {
        this.openStopPaymentModal(err, nextStep);
      })
      .finally(() => {
        this.isBusy = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  resetAmountToDefault(amount) {
    if (this.displayAmount === '0.00') {
      this.displayAmount = null;
    } else if (this.displayAmount === '' || this.displayAmount == null) {
      this.displayAmount = '0.00';
    }

    this.isInvalidAmount(amount);
  }
  /** Validates the amount */
  onValueAmount(amount) {
    this.stopPayment.amount = amount;
    this.displayAmount = amount;
  }

  isInvalidAmount(amount): boolean {
    if (
      !this.stopPayment ||
      !this.stopPayment.amount ||
      this.stopPayment.amount <= 0 ||
      isNaN(this.stopPayment.amount)
    ) {
      amount.control.setValidators(Validators.required);
      amount.control.updateValueAndValidity();
    }

    return (
      (amount.control.invalid || amount.control.value === '0.00') && (amount.control.dirty || amount.control.touched)
    );
  }

  redirectToSecureForm(): void {
    this.state.go('udb.secureForms');
  }

  redirectToAccountsDetails(paramAccountId: any): void {
    this.state.go('udb.accounts.details', {
      id: paramAccountId,
      tab: 1,
    });
  }

  redirectToStopPayment(paramAccountId: any): void {
    this.state.go('udb.accounts.stop-payment', {
      id: paramAccountId,
    });
  }

  private openStopPaymentModal(err: any, nextStep: number) {
    let bodyMsg = '';
    let okBtn = 'Continue';
    let cancelButton = false;
    const cancelTxt = 'No';
    let extendStopPayment = false;
    let stopClearedCheck = false;

    if (err.data.message.includes('Stop/hold exists')) {
      bodyMsg = `<h3>We're sorry, but the check number you've entered already
      has a 'stop payment' in place.</h3></br>
      <p>Would you like to extend the stop payment on this check?</p>`;
      okBtn = 'Yes';
      cancelButton = true;
      extendStopPayment = true;
    } else if (err.data.message.includes('Check number(s) found in history file')) {
      bodyMsg = ` It is likely that the check you are attempting to stop payment on has cleared. Would you like to continue?`;
      okBtn = 'Yes';
      cancelButton = true;
      stopClearedCheck = true;
    }

    if (bodyMsg === '') {
      this.serviceHelper.errorHandler(err);
    } else {
      this.modalService
        .show(
          {},
          {
            bodyText: bodyMsg,
            okText: okBtn,
            hasCancelButton: cancelButton,
            cancelText: cancelTxt,
          }
        )
        .then(() => {
          if (extendStopPayment) this.goToExtendStopPayment();
          if (stopClearedCheck) this.placeStopPayment(true, true, nextStep);
        })
        .catch(() => {
          if (stopClearedCheck) this.state.go('udb.accounts.details', { id: this.accountId, tab: 0 });

          return;
        });
    }
  }

  private goToExtendStopPayment() {
    this.stopPaymentService
      .getStopPayments(this.accountId)
      .then(res => {
        const stopPayments = res.data;
        const stopPaymentToExtend = stopPayments.find(sp => {
          return (
            moment(sp.checkDate).isSame(this.stopPayment.checkDate, 'day') &&
            +sp.lowCheckNumber === +this.stopPayment.lowCheckNumber &&
            +sp.highCheckNumber === +this.stopPayment.highCheckNumber
          );
        });
        if (stopPaymentToExtend) {
          this.state.go('udb.accounts.stop-payment-extend', {
            id: this.accountId,
            seq: stopPaymentToExtend.sequenceId,
            spExtend: stopPaymentToExtend,
          });
        }
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isBusy = false;
      });
  }

  /** Gets the expiration date depending on the stop type */
  private getExpirationDate(): Date {
    const date = this.stopType == StopType.ACH ? moment(this.datePickerOptions.minDate) : moment();
    date.add(this.expirationTime, 'months');

    return date.toDate();
  }

  /** Gets the app settings related to stop payments */
  private getSettingsConfig(): void {
    this.expirationTime = +this.root['appSettings'].find((item: any) => {
      return item.name === 'StopPaymentAddTime';
    }).value;

    this.formNumber = +this.root['appSettings'].find((item: any) => {
      return item.name === 'StopPaymentRequestFormNumber';
    }).value;
  }
}
