<div class="dialog-header" [style.backgroundImage]="backgroundUrl"></div>

<h3 mat-dialog-title>
  State withholding guidelines
</h3>

<div class="federal-taxes-modal-content" mat-dialog-content class="mat-dialog-content">
  <div class="m-4 text-left" *ngFor="let i of info">
    <h3 [innerText]="i.state"></h3>
    <ng-container *ngIf="i.conditions">
      <p class="federal-taxes-modal-content__label">Tax withholding:</p>
      <p [innerText]="i.conditions"></p>
    </ng-container>
    <ng-container *ngIf="i.option">
      <p class="federal-taxes-modal-content__label">Optional/Required:</p>
      <p [innerText]="i.option"></p>
    </ng-container>
  </div>
</div>

<div mat-dialog-actions>
  <button class="btn-ok" (click)="closeModal()" cdkFocusInitial>Close</button>
</div>
