import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { Transaction } from '@app/accounts/submodules/transactions/models/transaction.model';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionDetailsComponent implements AfterViewInit {
  @ViewChild('description') description: ElementRef;
  @ViewChild('merchant') merchant: ElementRef;
  @Input() transaction: Transaction;
  @Input() isPfmTransaction: boolean;
  tran: Transaction;
  offSetHeight: number;
  constructor() {}

  get showAdditionalInfo() {
    return this.transaction.merchantAddress && this.transaction.merchantName && this.transaction.isPhysical !== null;
  }

  get isPhysical() {
    if (this.transaction.isPhysical === null) return '';

    return this.transaction.isPhysical ? 'Physical' : 'Online';
  }

  get getType() {
    if (this.transaction.type === 'C') {
      return 'Deposit';
    } else if (this.transaction.type === 'D') {
      return 'Withdrawal';
    } else if (this.transaction.type === 'Payment') {
      return 'PURCHASE';
    } else {
      return '';
    }
  }

  get getDescription() {
    if (this.transaction.originalDescription !== null) return this.transaction.originalDescription;

    return this.transaction.description;
  }
  ngAfterViewInit(): void {
    if (this.showAdditionalInfo) {
      this.merchant.nativeElement.setAttribute('style', 'height:' + this.description.nativeElement.offsetHeight + 'px');
    }
  }
}
