import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { GoalType, TransactionType, TransferType } from '@app/axos-invest/enums';
import { Goal, GoalAccount, ScheduledTransfer, TransferForm } from '@app/axos-invest/models';
import { AxosInvestTransferService } from '@app/axos-invest/services';
import { loadScheduledTransfers } from '@app/axos-invest/store/scheduled-transfers/scheduled-transfers.actions';
import { getGoalSummary, getScheduledTransfers } from '@app/axos-invest/store/selectors';
import { getRetirementAccounts } from '@app/axos-invest/utils';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { FilesIcons, FinancialIcons, NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-transfer-funds-type-selector',
  templateUrl: './transfer-funds-type-selector.component.html',
  styleUrls: ['./transfer-funds-type-selector.component.scss'],
})
export class TransferFundsTypeSelectorComponent implements OnInit, OnDestroy {
  isLoading = true;
  transferTypeSelected = false;
  TRANSFER_TYPES = TransferType;
  icons = {
    back: NavigationIcons.ArrowBack,
    piggyBank: FinancialIcons.Piggy,
    coinDollarSign: FinancialIcons.DollarArrowForward,
    dollarStack: FinancialIcons.DollarBillStack,
    edit: FilesIcons.Pencil,
  };
  goalId: string;
  goal: Goal;
  goalType = GoalType;
  transferType: TransferType;
  transferData: TransferForm;
  goBackOnHistory: boolean;
  loadingRecurrentDeposits = true;
  scheduledDeposits: ScheduledTransfer[];
  retirementAccountsWithoutScheduledDeposits: GoalAccount[];
  retirementAccountsWithoutScheduledWithdrawals: GoalAccount[];
  scheduledWithdrawals: ScheduledTransfer[];
  canAddAnotherRecurrentDeposit: boolean;
  canAddAnotherRecurrentWithdrawal: boolean;
  subSink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private transferService: AxosInvestTransferService,
    private store: Store
  ) {}

  ngOnInit() {
    this.goalId = this.params['id'];
    this.loadTransferDataFromParameters();
    this.loadTransferTypeFromParameter();

    if (this.params['flow']) {
      const flowType = this.params['flow'];

      if (flowType === FlowType.ManagePortfoliosTransFun) {
        this.transferTypeSelected = true;
        const transType = sessionStorage.getItem('transferType');
        this.transferType = +transType;
        const milestoneId = sessionStorage.getItem('milestoneId');
        this.goalId = milestoneId;
      }
    }

    this.getGoalData();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  getGoalData() {
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(goalSummary => {
      this.goal = JSON.parse(JSON.stringify(goalSummary.milestones.find(milestone => milestone.id === this.goalId)));
      if (this.goal) {
        this.getScheduledTransfers();
        this.isLoading = false;
      }
    });
  }

  goBack() {
    this.state.go('udb.axosinvest', { id: this.goalId });
  }

  goBackWithHistory() {
    if (this.goBackOnHistory) {
      this.state.go('udb.axosinvest', {
        id: this.goalId,
        referrerTabId: this.params['referrerTabId'],
      });
    } else {
      this.transferTypeSelected = false;
    }
  }

  loadScheduledTransfer(transferType: TransferType, transferSeriesId: string) {
    this.transferData = new TransferForm({
      transferSeriesId,
    });
    this.selectTransferType(transferType);
  }

  createAnotherScheduledTransfer(transferType: TransferType, accountNumber: string) {
    this.transferData = new TransferForm({
      transferSeriesId: '0',
      goalAccount: accountNumber,
    });
    this.selectTransferType(transferType);
  }

  selectTransferType(transferType: TransferType) {
    this.transferType = transferType;
    this.transferTypeSelected = true;
  }

  private loadTransferTypeFromParameter() {
    if (this.params.transferType) {
      this.goBackOnHistory = true;
      this.selectTransferType(this.params.transferType);
    }
  }

  private loadTransferDataFromParameters() {
    const keys = Object.keys(this.params);
    const formProperties = ['amount', 'frequency', 'accountId', 'transferSeriesId', 'accountNumber'];
    if (keys.some(key => formProperties.includes(key) && this.params[key])) {
      this.transferData = new TransferForm();
      formProperties.forEach(property => {
        if (this.params[property]) {
          this.transferData[property] = this.params[property];
        }
      });
    }
  }

  private getScheduledTransfers() {
    this.subSink.sink = this.store
      .select(getScheduledTransfers)
      .subscribe(scheduledTransfers => this.mapScheduledTransfers(scheduledTransfers));
  }

  private mapScheduledTransfers(transfers: ScheduledTransfer[]) {
    this.loadingRecurrentDeposits = true;
    if (!transfers) {
      this.transferService.getScheduledTransfers().subscribe(response => {
        this.store.dispatch(loadScheduledTransfers({ payload: response.data }));
      });

      return;
    }
    transfers = JSON.parse(JSON.stringify(transfers));
    this.scheduledDeposits = transfers.filter(
      t => t.milestoneId === this.goal.id && t.transactionType === TransactionType.Deposit
    );

    this.scheduledWithdrawals = transfers.filter(
      x => x.milestoneId === this.goal.id && x.transactionType === TransactionType.Withdrawal
    );

    if (this.goal.type === GoalType.Retirement) {
      this.validateRetirementAccounts(this.scheduledDeposits, true);
      this.validateRetirementAccounts(this.scheduledWithdrawals, false);
    }

    this.loadingRecurrentDeposits = false;
  }

  private validateRetirementAccounts(transfers: ScheduledTransfer[], isRecurrentDeposit: boolean) {
    const accounts = getRetirementAccounts(this.goal.accounts);

    const transferAccountNumbers = transfers.map(transfer => transfer.accountNumber);

    if (isRecurrentDeposit) {
      this.retirementAccountsWithoutScheduledDeposits = accounts.filter(
        account => !transferAccountNumbers.includes(account.accountNumber)
      );

      this.canAddAnotherRecurrentDeposit =
        this.retirementAccountsWithoutScheduledDeposits.length > 0 &&
        this.retirementAccountsWithoutScheduledDeposits.length < accounts.length;
    } else {
      this.retirementAccountsWithoutScheduledWithdrawals = accounts.filter(
        account => !transferAccountNumbers.includes(account.accountNumber)
      );

      this.canAddAnotherRecurrentWithdrawal =
        this.retirementAccountsWithoutScheduledWithdrawals.length > 0 &&
        this.retirementAccountsWithoutScheduledWithdrawals.length < accounts.length;
    }

    transfers.forEach(transfer => {
      const account = accounts.find(a => a.accountNumber === transfer.accountNumber);
      if (account) {
        transfer.accountName = account.name;
      }
    });
  }
}
