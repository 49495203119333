import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  /**
   * Loads an script, appending it to the bottom of the body.
   * @param scriptSrc URL of the script.
   * @returns A Promise containing the result of the operation. False if failed.
   */
  load(scriptSrc: string): Promise<boolean | void> {
    if (!scriptSrc) return;

    return new Promise<boolean | void>((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptSrc;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = (error: Event) => {
        reject(error);
      };
      document.querySelector('body').appendChild(script);
    });
  }
}
