import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { format, subMonths } from 'date-fns';

import { AggregatedAccount } from '@app/accounts/models';
import { getChartDataObject, getChartTooltipsObject, getExtendedAxosLineChart } from '@app/utils';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

import { ExternalTransaction } from '../../models';
import { AccountAggregationChartFormatterService } from '../../services';

@Component({
  selector: 'app-insights-chart',
  templateUrl: './insights-chart.component.html',
  styleUrls: ['./insights-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() transactions: ExternalTransaction[];
  @Input() account: Partial<AggregatedAccount>;
  @Input() isPfm2Active: boolean;

  categoriesName: string;
  actualPeriodSum: number;
  previousPeriodSum: number;
  actualPeriodTime: string;

  // #region <getters>
  get filteredTransactions(): ExternalTransaction[] {
    return this.transactions.filter(
      transaction => !transaction.isPending && transaction.olbCategoryId !== this.pendingCategoryId
    );
  }

  /**
   * Returns true if any tx in this.transactions is of income category or transfer category and is income
   */
  private get areIncomeTransactions(): boolean {
    return this.filteredTransactions.some(
      (tx: ExternalTransaction) =>
        tx.isIncome && [this.incomeCategoryId, this.transferCategoryId].some(x => x === tx.olbCategoryId)
    );
  }

  /**
   * Returns true if any transaction in "this.transactions"
   * is of any category other than "income" or "transfer"
   * If it is "transfer" category it should not be an income
   */
  private get areExpenseTransactions(): boolean {
    return this.filteredTransactions.some(
      (tx: ExternalTransaction) =>
        ![this.incomeCategoryId, this.transferCategoryId].some(x => x === tx.olbCategoryId) ||
        (tx.olbCategoryId === this.transferCategoryId && !tx.isIncome)
    );
  }

  /**
   * Returns true when are transactions of type Income and Expense
   */
  private get isCashFlow(): boolean {
    return this.areIncomeTransactions && this.areExpenseTransactions;
  }

  /**
   * Gets the data object formatted
   */
  private get data(): object {
    return getChartDataObject(
      this.labels,
      this.isPfm2Active,
      this.env.facingBrandId,
      this.dataActualMonth,
      this.dataPrevMonth
    );
  }

  /**
   * Gets the options object formatted
   */
  private get options(): AxosLineChartOptions {
    const previousMonthName = this.accAggFormatter.getShortMonthName(subMonths(new Date(), 1));
    const currentMonthName = this.accAggFormatter.getShortMonthName();
    const tooltipsObject = getChartTooltipsObject(
      this.isPfm2Active,
      this.size55vh,
      previousMonthName,
      currentMonthName,
      this.cp
    );

    return {
      // Shows chart legends
      legend: {
        display: false,
      },
      tooltips: tooltipsObject,
      layout: {
        padding: this.chartPadding,
      },
      scales: {
        // Options of the scale
        yAxes: [
          {
            ticks: {
              // Styles of the label in axis Y
              display: true,
              // Color of the scale values
              fontColor: this.isPfm2Active ? '#5E6A74' : 'rgb(212,217,226)',
              // Size of the scale values
              fontSize: 20,
              // Callback to format the value of the label
              callback: (label: any, _index: any, _labels: any) => {
                return '$' + label;
              },
              // Min value in the axis
              min: this.roundedMin,
              // Max value in the axis
              max: this.roundedMax + this.roundedMax * 0.05,
              // Number of values to show in the axis
              maxTicksLimit: 6,
              // Size of the increment
              stepSize: this.stepSize,
              // Start the labels at zero
              beginAtZero: true,
              padding: 0,
            },
            gridLines: {},
            afterBuildTicks: (axis: any) => {
              axis.ticks = this.accAggFormatter.range(this.roundedMin, this.roundedMax, this.stepSize);
            },
            offset: true,
          },
        ],
        xAxes: [
          {
            ticks: {
              // Label styles of X axis
              display: true, // Shows the labels
              // Color of labels in the axis
              fontColor: this.isPfm2Active ? '#5E6A74' : 'rgb(212,217,226)',
              // Fontsize of labes in the axis
              fontSize: 20,
              // When the chart size decrease max value in deegres to rotate the labels to fit in
              maxRotation: 0,
              padding: 0,
              // Padding between labels, when is less than that amount, begin the autoskip
              autoSkipPadding: 10,
              beginAtZero: false,
            },

            // Do not display the lines of the grid for the axis
            gridLines: {
              display: false,
            },
            // Applies an offset for all the values be visible
            offset: true,
          },
        ],
      },
      // Selects only 1 point on hover instead of all in the axis
      hover: {
        mode: 'point',
      },
      responsive: true,
      maintainAspectRatio: false,
      onResize: (chart: any, size: any) => {
        // padding percentage of the size of the canvas on resize
        chart.options.layout.padding.top = size.height * this.paddingPercentage;
        // fontsize of the tooltips will be resized dynamic
        chart.options.tooltips.bodyFontSize = 8 + size.height * 0.025;
        if (this.isPfm2Active) chart.options.layout.padding = this.chartPadding;
        chart.update();
      },

      // Custom AxosLineChartOptions
      axos: {
        // boolean to determine when is the values below zero and render correctly
        valuesAreBelowZero: this.roundedMin < 0,
        // Color of line style
        zeroLineStyle: '#E7ECF7',
        // Color of interaction line
        interactionLineStyle: this.isPfm2Active ? '#333D46' : 'rgb(108,131,164)',
        // Style for XScale label
        xScaleFontStyle: '20px Roboto',
        // Value of actual period selected abbr
        xScaleValue: this.accAggFormatter.getShortMonthName(),
      },

      // Display or not the title of the chart
      title: {
        display: false,
      },
    };
  }

  /**
   * Since Chart.js only supports responsive sizing based on screen width (vw),
   * a workaround is to add padding on the right to visually fit it inside its container,
   * this has the drawback that you have to consider all css breakpoints that could affect
   * the container's width
   */
  private get chartPadding() {
    if (this.isPfm2Active) {
      return {
        right: window.innerWidth >= 1103 ? 237 : window.innerWidth >= 785 ? 230 : 30,
        top: 0,
        bottom: 0,
      };
    }

    return {
      // percentage size of 55vh
      top: this.size55vh * this.paddingPercentage,
      // percentage size of 55vh
      bottom: this.size55vh * this.paddingPercentage,
    };
  }

  // #endregion

  // #region <Private Properties>
  private incomeCategoryId = 8;
  private transferCategoryId = 7;
  private pendingCategoryId = 10;
  private lineChart: Chart;
  private labels: number[];
  private dataActualMonth: any[];
  private dataPrevMonth: any[];
  private roundedMin: number;
  private roundedMax: number;
  private stepSize: number;
  private size55vh: number = window.innerHeight * 0.55;
  private paddingPercentage = 0.1;
  private startPeriod: Date;

  // #endregion

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    private cp: CurrencyPipe,
    private accAggFormatter: AccountAggregationChartFormatterService
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (!this.lineChart) return;

    this.lineChart.destroy();
    this.formatData();
    this.displayChart();
  }

  ngOnInit(): void {
    this.formatData();
  }

  ngAfterViewInit(): void {
    this.formatData();
    this.displayChart();
  }

  getBankNameMask(): string {
    if (!this.account) return '';
    const account = this.account.nickname ?? this.account.name ?? this.account.bankName ?? '';

    return `${account} ${this.maskAccountNumber()}`;
  }

  private maskAccountNumber(): string {
    const accountNumber = this.account.accountNumber ?? this.account.accountMask;
    if (!accountNumber || accountNumber.length < 4) return '';

    return `*${accountNumber.substring(accountNumber.length - 4)}`;
  }

  /**
   * Based on the transactions categories determines the correct title for the header
   */
  private getChartName(): string {
    return this.isCashFlow
      ? 'Cash Flow'
      : this.areExpenseTransactions
      ? 'Expenses'
      : this.areIncomeTransactions
      ? 'Income'
      : 'Expenses';
  }

  /**
   * Process the transaction information to set the correct information to show the chart
   */
  private formatData(): void {
    this.categoriesName = this.getChartName();

    this.startPeriod = this.accAggFormatter.getFilterDate(0, 0);

    this.actualPeriodTime = format(this.startPeriod, 'MMM yyyy');

    const startPreviousPeriodDate = this.accAggFormatter.getFilterDate(-1, 0);

    const actualPeriodTx = this.accAggFormatter.filtertTransactionsByDate(this.filteredTransactions, this.startPeriod);

    const previousPeriodTx = this.accAggFormatter.filtertTransactionsByDate(
      this.filteredTransactions,
      startPreviousPeriodDate,
      this.startPeriod
    );

    const groupByDayCallback = (element: Transaction) => new Date(element.postedDate.toString()).getDate();

    const groupedActualTransactions = this.accAggFormatter.groupByTransactions(actualPeriodTx, groupByDayCallback);

    const groupedPreviousTransactions = this.accAggFormatter.groupByTransactions(previousPeriodTx, groupByDayCallback);

    const summedActualTransactions = this.isCashFlow
      ? this.accAggFormatter.sumSignedTransactionArray(groupedActualTransactions)
      : this.accAggFormatter.sumTransactionArray(groupedActualTransactions);

    const summedPreviousTransactions = this.isCashFlow
      ? this.accAggFormatter.sumSignedTransactionArray(groupedPreviousTransactions)
      : this.accAggFormatter.sumTransactionArray(groupedPreviousTransactions);

    this.actualPeriodSum = this.accAggFormatter.sumObjectValues(summedActualTransactions);

    this.previousPeriodSum = this.accAggFormatter.sumObjectValues(summedPreviousTransactions);

    const runningAmountActualTransactions = this.accAggFormatter.getRunningTransactionAmount(summedActualTransactions);

    const runningAmountPreviousTransactions = this.accAggFormatter.getRunningTransactionAmount(
      summedPreviousTransactions
    );

    const formattedActualPeriodData = this.accAggFormatter.getFormatDataForMonth(
      runningAmountActualTransactions,
      this.startPeriod
    );

    const formattedActualPreviousData = this.accAggFormatter.getFormatDataForMonth(
      runningAmountPreviousTransactions,
      startPreviousPeriodDate,
      true
    );

    const maxDaysInPeriod = Math.max(
      this.accAggFormatter.daysInMonthByDate(this.startPeriod),
      this.accAggFormatter.daysInMonthByDate(startPreviousPeriodDate)
    );

    this.labels = this.accAggFormatter.range(1, maxDaysInPeriod, 1);

    this.dataActualMonth = formattedActualPeriodData;
    this.dataPrevMonth = formattedActualPreviousData;

    const roundedMin = this.accAggFormatter.roundToNearest10(
      this.accAggFormatter.minValue([...this.dataPrevMonth, ...this.dataActualMonth])
    );

    this.roundedMin = roundedMin > 0 ? 0 : roundedMin;

    const roundedMax = this.accAggFormatter.roundToNearest10(
      this.accAggFormatter.maxValue([...this.dataPrevMonth, ...this.dataActualMonth])
    );
    this.roundedMax = this.roundedMin === roundedMax ? this.roundedMin + 10 : roundedMax;
    const stepSize = (this.roundedMax - this.roundedMin) / 5;
    this.stepSize = stepSize < 10 ? stepSize : this.accAggFormatter.roundToNearest10(stepSize);
  }

  /**
   * Executes the logic to show the chart
   * Extends the actual functionality of the chart and return its name, this happens in ChartHelper service
   * Custom line chart it extends the functionality of the original one
   * Overrides its draw function to draw custom elements, the interaction line in this case
   */
  private displayChart(): void {
    const ctx = document.querySelector<HTMLCanvasElement>('#line-chart-canvas2').getContext('2d');

    this.lineChart = new Chart(ctx, {
      type: getExtendedAxosLineChart(),
      data: this.data, // Data object
      options: this.options, // Chart options
    });
  }
}
