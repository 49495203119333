import { createReducer, on } from '@ngrx/store';

import { ContactFirmsState } from '@app/support/models/contact-firms-state.model';

import { getUserContactFirms, getUserContactFirmsSuccess } from './contact-firms.actions';

export const initialContactFirmState: ContactFirmsState = {
  isLoading: false,
  contactFirms: null,
};

export const contactFirmsReducer = createReducer(
  initialContactFirmState,
  on(getUserContactFirms, state => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(getUserContactFirmsSuccess, (state, { userContactFirms }) => {
    return {
      ...state,
      isLoading: false,
      contactFirms: userContactFirms,
    };
  })
);
