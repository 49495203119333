import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BalancesRequest, BalancesResponse } from './types';

@Injectable()
export class BalancesService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/balances');
  }

  /**
   * Returns the balances from account number from AAS customer.
   *
   * @param request BalancesRequest
   * @returns WebApiResponse of BalancesResponse
   *
   */
  getBalances(request: BalancesRequest): WebApiResponse<BalancesResponse> {
    let url = request.accountNumber;

    if (request.modelId) {
      url += '/' + request.modelId;
    }

    return this.get(url);
  }
}
