<div class="divider"></div>
<app-dot-loader *ngIf="isLoading">Loading...</app-dot-loader>
<ng-container *ngIf="!isLoading">
  <div class="main-container" *ngIf="transactionTile && transactionTile.apiCallWasSuccessful; else errorState">
    <ng-container *ngIf="transactionTile && transactionTile.transactions.length > 0; else emptyState">
      <div
        class="transactions-container divider"
        (click)="navigateToAllTransactions(transaction.transactionId)"
        [ngClass]="{ 'transactions-container--executed': transaction.state }"
        *ngFor="let transaction of transactionTile.transactions"
      >
        <div class="transactions-container--icon">
          <mat-icon
            class="icon"
            [svgIcon]="getTransactionCategory(transaction.activityCode, transaction.state).categoryIcon"
          ></mat-icon>
        </div>
        <div class="transaction-container-details" [ngClass]="{ 'description-status-pending': !transaction.state }">
          <div class="transaction-container-details__left">
            <div class="transaction-container-details__left__full-description">
              <div class="description-text">
                <p>
                  {{ transaction.activityDescription }}
                </p>
              </div>
              <div class="description-status">
                <p>
                  {{ getTransactionStatus(transaction) }}
                </p>
              </div>
            </div>
          </div>
          <div class="transaction-container-details__right">
            <div class="transaction-container-details__right__full-amount">
              <div class="amount-value">
                <p>
                  {{ transaction.amount | currency }}
                </p>
              </div>
              <div
                class="amount-shared-at-price"
                *ngIf="getTransactionCategory(transaction.activityCode, transaction.state).categoryName === 'Split'"
              >
                <p>{{ transaction.quantity }} shares at {{ transaction.price | currency }}</p>
              </div>
            </div>
            <div *ngIf="transaction.state" class="transaction-container-details__right__chevron">
              <mat-icon [svgIcon]="uk2NavigationIcons.chevronRight"></mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="view-all-transactions">
        <button
          (click)="navigateToAllTransactions()"
          uk2TextButton
          disableRipple
          [uk2ButtonSize]="uk2ViewAllButtonSize"
          mat-button
        >
          View All Transactions
        </button>
      </div>
    </ng-container>
    <ng-template #emptyState>
      <div class="no-transactions-container">
        <p>Once you have transactions related to this account, you'll see them here</p>
      </div>
    </ng-template>
  </div>
  <ng-template #errorState>
    <uk2-text-tile
      [uk2TextTileSvgIconName]="uk2UtilityIcons.cog"
      [uk2TextTileTitle]="labels.errorTitle"
      [uk2TextTileIsStandaloneTile]="false"
    >
      <p>
        {{ labels.errorContent }}
      </p>
    </uk2-text-tile>
  </ng-template>
</ng-container>
