import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Filters } from '@app/axos-trading/models';
import { UtilityIcons } from '@shared/enums';
import { Filter } from '@shared/models';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Output() filtersUpdated = new EventEmitter<Filters>();
  filters: UntypedFormGroup;
  get tagFilters(): Filter[] {
    const subFilters = ['dateRange', 'amountRange'];
    const filters = this.filtersApplied.filter(filter => subFilters.includes(filter.name));

    return filters;
  }

  displayFilters: boolean;
  filtersApplied: Filter[] = [];
  filterIcon = {
    iconName: UtilityIcons.FilterFunnel,
    width: '16px',
    height: '12px',
  };

  private emitEvent = true;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createForm();
    this.setEventsForFilters();
  }
  private createForm() {
    this.filters = this.formBuilder.group({
      search: [],
    });
  }

  private setEventsForFilters() {
    this.filters.controls.search.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(value => this.validateString({ name: 'search', value }));
  }

  private validateString(filter: Filter<string>) {
    this.emitFilterChange(filter, filter.value !== '');
  }

  private emitFilterChange(filter: Filter, add: boolean) {
    const action = add ? this.addFilter : this.removeFilter;
    action.bind(this)(filter);
  }

  private removeFilter(filter: Filter) {
    const indexOfFilter = this.filtersApplied.findIndex(f => f.name === filter.name);
    if (indexOfFilter >= 0) {
      this.filtersApplied.splice(indexOfFilter, 1);
    }
    if (this.emitEvent) this.emitEventOutput();
  }

  private addFilter(filter: Filter) {
    let indexOfFilter = this.filtersApplied.findIndex(f => f.name === filter.name);
    indexOfFilter = indexOfFilter >= 0 ? indexOfFilter : this.filtersApplied.length;
    this.filtersApplied[indexOfFilter] = filter;
    this.emitEventOutput();
  }

  private emitEventOutput() {
    const filters = {};
    const keys = Object.keys(this.filters.value);
    keys.forEach(key => {
      const value = this.filters.get(key).value;
      if (value !== null) {
        filters[key] = value;
      }
    });
    this.filtersUpdated.next(filters);
  }
}
