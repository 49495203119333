import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '@shared/shared.module';

import { transactionHistoryComponents } from './components';
import { transactionHistoryServiceProviders } from './providers';

const exportComponents = [...transactionHistoryComponents];

@NgModule({
    declarations: [...exportComponents],
    imports: [CommonModule, NgxMaskModule.forRoot(), FormsModule, ReactiveFormsModule, SharedModule],
    exports: [...exportComponents],
    providers: [...transactionHistoryServiceProviders]
})
export class TransactionHistoryModule {}
