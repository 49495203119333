<div class="tx-filter" [ngSwitch]="type">
  <div *ngSwitchCase="'input'">
    <app-input-filter
      [onlyNumbers]="onlyNumbers"
      [isDisabled]="isDisabled"
      [inputLabel]="inputLabel"
      [inputPlaceholder]="placeholder"
      [iconPlaceholder]="iconPlaceholder"
      [control]="filters.get(filterName)"
    ></app-input-filter>
  </div>

  <div *ngSwitchCase="'dropdown'">
    <app-dropdown-filter
      [control]="filters.get(filterName)"
      [isDisabled]="isDisabled"
      [options]="options"
    ></app-dropdown-filter>
  </div>
</div>
