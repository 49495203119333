import { Action, createReducer, on } from '@ngrx/store';
import { signOut } from '@app/store/actions';
import { PdpLayoutBreadcrumbItemState, PdpLayoutState, initialPdpLayoutState } from '../types';
import { AddBreadcrumbAction, selectBreadcrumbUrlAction, SetContextualTopBarStateAction, updateContextualTopBarStatePageTitle } from '../pdp-layout.actions';

const reducer = createReducer(
  initialPdpLayoutState,
  on(SetContextualTopBarStateAction, (_state, action) => {
    return {
      contextualTopBar: {
        pageTitle: action.payload.contextualTopBar.pageTitle,
        breadcrumbItems: action.payload.contextualTopBar.breadcrumbItems,
        accountNumber: action.payload.contextualTopBar.accountNumber,
      },
    };
  }),
  on(AddBreadcrumbAction, (_state, action) => {
    return {
      contextualTopBar: {
        pageTitle: _state.contextualTopBar.pageTitle,
        breadcrumbItems: action.payload,
        accountNumber: _state.contextualTopBar.accountNumber,
      },
    };
  }),
  on(selectBreadcrumbUrlAction, (_state, action) => {
    const updatedBreadcrumbItems = setUpdatedBreadcrumbItems(_state.contextualTopBar.breadcrumbItems, action.payload);

    return {
      contextualTopBar: {
        pageTitle: _state.contextualTopBar.pageTitle,
        breadcrumbItems: updatedBreadcrumbItems,
        accountNumber: _state.contextualTopBar.accountNumber,
      },
    };
  }),
  on(signOut, () => {
    return initialPdpLayoutState;
  }),
  on(updateContextualTopBarStatePageTitle, (_state, action) => {
    return {
      contextualTopBar: {
        ..._state.contextualTopBar,
        pageTitle: action.payload,
      },
    };
  })
);

export function pdpLayoutReducer(state: PdpLayoutState, action: Action) {
  return reducer(state, action);
}

function setUpdatedBreadcrumbItems(breadcrumbItems: PdpLayoutBreadcrumbItemState[], itemUrl: string) {
  const itemIndex = getItemIndex(breadcrumbItems, itemUrl);
  return itemIndex < 0 ? breadcrumbItems : breadcrumbItems.slice(0, itemIndex + 1);
}

function getItemIndex(breadcrumbItems: PdpLayoutBreadcrumbItemState[], itemUrl: string) {
  return breadcrumbItems.findIndex(item => item.url === itemUrl);
}
