import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { addDays, addMonths, format, isAfter, startOfToday } from 'date-fns';

import { DebitCard, TravelNotification } from '@app/accounts/submodules/debit-card/models';
import { getSetting } from '@app/store/selectors';
import { AppSettings } from '@core/enums';
import { DialogService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { CommunicationIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

import { DebitCardService } from '../../services';

@Component({
  selector: 'app-schedule-travel-view',
  templateUrl: './schedule-travel-view.component.html',
  styleUrls: ['./schedule-travel-view.component.scss'],
})
export class ScheduleTravelViewComponent implements OnInit, OnDestroy {
  busy: boolean;
  card: DebitCard;
  cardMask: string;
  endDate = {
    minDate: startOfToday(),
    maxDate: addMonths(startOfToday(), 11),
  };
  errors = {
    comments: 'Destinations/Comments is required and needs to be 10 characters min.',
  };
  iconConfig = {
    calendar: {
      size: '1.2rem',
      icon: CommunicationIcons.Calendar,
    },
  };
  loading: boolean;
  overLimit: boolean;
  scheduleTravelForm: UntypedFormGroup;
  startDate = {
    minDate: startOfToday(),
    maxDate: addMonths(startOfToday(), 11),
  };
  travelNotification: TravelNotification;

  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private $stateParams: ng.ui.IStateParamsService,
    @Inject(STATE) private $state: ng.ui.IStateService,
    private store: Store,
    private fb: UntypedFormBuilder,
    private debitCardService: DebitCardService,
    private dialogService: DialogService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit() {
    this.loading = true;
    const { card, id } = this.$stateParams;
    if (!card) {
      this.$state.go('udb.accounts.details', { id, tab: 1 });
    } else {
      this.card = card;
      this.cardMask = this.card.cardNumberMask.slice(this.card.cardNumberMask.length - 5);
      this.subsink.sink = combineLatest([
        this.debitCardService.getTravelNotifications(this.card),
        this.store.select(getSetting(AppSettings.TravelNotificationPeriod)),
        this.store.select(getSetting(AppSettings.TravelNotificationsLimit)),
      ]).subscribe({
        next: ([notifications, travelPeriod, travelLimit]) => {
          this.startDate = { ...this.endDate, maxDate: addMonths(this.startDate.minDate, +travelPeriod) };
          this.endDate = { ...this.endDate, maxDate: addMonths(this.startDate.minDate, +travelPeriod) };

          this.scheduleTravelForm = this.fb.group({
            comments: this.fb.control('', [Validators.required, Validators.minLength(10)]),
            startDate: this.fb.control(this.startDate.minDate, [Validators.required]),
            endDate: this.fb.control(addDays(this.startDate.minDate, 1), [Validators.required]),
          });

          this.validateOverLimit(notifications, +travelLimit);
          this.loading = false;
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  startDateChange(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    this.scheduleTravelForm.patchValue({
      endDate: selectedDate,
    });

    this.endDate = { ...this.endDate, minDate: selectedDate };
  }

  cancelScheduleTravel() {
    this.$state.go('udb.accounts.details', { id: this.card.accountId, tab: 4, debitCardId: this.card.debitCardId });
  }

  scheduleTravel() {
    if (this.scheduleTravelForm.invalid) {
      this.scheduleTravelForm.markAllAsTouched();
    } else {
      const formValue = this.scheduleTravelForm.value;
      this.travelNotification = new TravelNotification({
        ...formValue,
        startDate: format(formValue.startDate, 'yyyy-LL-dd'),
        endDate: format(formValue.endDate, 'yyyy-LL-dd'),
        cardMask: this.card.cardNumberMask,
        debitCardId: this.card.debitCardId,
      });
      this.busy = true;
      this.debitCardService
        .addTravelNotification(this.travelNotification, this.card.accountId, this.card.cardNumberMask)
        .pipe(
          finalize(() => {
            this.busy = false;
            this.$state.go('udb.accounts.debit-cards.confirmation', {
              message: `You have added your scheduled travel location(s).`,
              email: null,
              accountId: this.card.accountId,
              card: this.card,
              header: 'Scheduled Travel',
            });
          })
        )
        .subscribe({
          error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        });
    }
  }

  private validateOverLimit(notifications: TravelNotification[], travelLimit: number) {
    this.overLimit =
      notifications.filter(notification => {
        const dateToCompare = addDays(notification.endDate, 1);

        return notification.isDeleted === false && isAfter(dateToCompare, startOfToday());
      }).length >= travelLimit;
    if (this.overLimit) {
      const dialogData = new DialogData({
        title: 'Travel Notifications',
        content: '<p>You have reached the limit for active travel notifications</p>',
        cancelText: '',
        okText: 'Close',
      });

      this.dialogService.open(dialogData);
    }
  }
}
