import { ProcessedSegment } from '../types';
export function createRoundedDoughnutChart(
  ctx: CanvasRenderingContext2D,
  cx: number,
  cy: number,
  radius: number,
  arcWidth: number,
  segments: ReadonlyArray<ProcessedSegment>,
  canvasReference?: HTMLCanvasElement
) {
  if (canvasReference) {
    ctx.clearRect(0, 0, canvasReference.width, canvasReference.height);
  }
  ctx.lineWidth = arcWidth;
  ctx.lineCap = 'round';
  for (let index = 0; index < segments.length; index++) {
    const startAngle = segments[index].startAngle;
    const endAngle = segments[index].endAngle;

    ctx.beginPath();
    ctx.arc(cx, cy, radius, startAngle, endAngle, false);
    ctx.strokeStyle = segments[index].backgroundColor;
    ctx.stroke();
  }
}
