import { IRootScopeService } from 'angular';

import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ngRedux,
  ROOT_SCOPE,
  STATE,
  STATE_PARAMS,
  olbSettings,
  WINDOW,
} from '@core/tokens';
import {
  ACCOUNTAGGREGATIONSERVICE,
  SERVICEHELPER,
  CACHEDACCOUNTSSERVICE,
  FLOWSERVICE,
  FEATUREFLAGSERVICE,
  REDIRECT_STATE_SERVICE,
  accountAggregationServiceProvider,
} from '../../ajs-upgraded-provider';
import NgRedux from 'ng-redux';
import { IRedirectStateService } from '@legacy/services/typings/IRedirectStateService';
import { Institution } from '@legacy/typings/app/account-aggregation';
import { FundingState } from '@app/store/funding/funding.state';

import { openAccountImages } from '@app/accounts/models/open-account-image';
import { OpenAccountItem } from '@app/accounts/models/open-account-item.model';
import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';

import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { FastlinkFlow } from '@legacy/dashboard/account-aggregation/enums/fast-link-flow.enum';
import {
  Bank,
  Banks,
  fundingFlowBanks,
} from '@legacy/dashboard/account-aggregation/typings';
import { AccountsMenuItem } from './typings/AccountsMenuItem';
import { FacingBrand } from '@legacy/models';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FlowService } from '@legacy/services/flow.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { AxosAdvisoryService } from '@core/services';
import { SblocAccountService } from '@app/dashboard/services/sbloc-account.service';

@Component({
  selector: 'app-account-aggregation',
  templateUrl: './account-aggregation.component.html',
  styleUrls: ['./account-aggregation.component.scss'],
  providers: accountAggregationServiceProvider,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountAggregationComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  bankLogos: Bank[];
  results: Bank[] = [];
  visibleResults: Bank[] = [];
  currentResultChunk = 1;
  resultChunkSize = 10;
  showResults = false;
  bankName: string;
  canceler: Promise<void>;
  noResultsImgSrc: any;
  errorMessage: string;
  isAccountAggregationFlow: boolean;
  isMoveMoneyFlow: boolean;
  flow: string;
  showManually = false;
  showOnKey = false;
  toolTipMessage =
    'Only checking and savings accounts can be manually added for Move Money purposes';
  showAggregationValidation = false;
  clickLinkAccounts = false;
  clickMoveMoney = false;
  showFooter = true;
  selectedInstitution: Institution;
  /** funding flow */
  fundingState: FundingState;
  openAccItems: OpenAccountItem[];
  openMenuItems: OpenAccountItem[] = [] as OpenAccountItem[];

  openAccountsPrefix = 'OpenAccounts_';
  checkingSuffix = 'Checking';
  investorCheckingURL =
    'https://www.axosadvisor.com/Products/Investor-Checking';
  riaAcctsSuffix = ['Mortgage', 'Personal', 'Auto', 'Checking'];
  isPfm3Active: boolean;
  flowTypeEnum = FlowType;
  flowTypeSelected: FastlinkFlow;
  isSBBActive: boolean = false;
  openAccountImages = openAccountImages;
  isRIAUser: boolean;
  isUFB: boolean;
  accountMessage = '';
  isBusy = true;
  private hasAxosCheckingAccount = false;
  private openAccountsBusinessPrefix = 'Business';
  private excludeFromOpenAccountBusiness = 'BusinessOpenAccount';

  private resolverPromiseMethod: any;

  constructor(
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    @Inject(ROOT_SCOPE) private rootScope: IRootScopeService,
    @Inject(STATE_PARAMS) private stateParams: IStateParamsService,
    @Inject(SERVICEHELPER) private serviceHelper: IServiceHelper,
    @Inject(ACCOUNTAGGREGATIONSERVICE)
    private accountAggregationService: AccountAggregationService,
    @Inject(CACHEDACCOUNTSSERVICE)
    private cachedAccountsService: CachedAccountsService,
    @Inject(STATE) private state: IStateService,
    @Inject(FLOWSERVICE) private flowService: FlowService,
    @Inject(FEATUREFLAGSERVICE) private featureFlagService: FeatureFlagService,
    private readonly supportViewFacade: SupportViewFacade,
    private axosAdvisoryService: AxosAdvisoryService,
    @Inject(olbSettings) public env: OlbSettings,
    private _sblocAccountService: SblocAccountService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(WINDOW) private _window: Window,
    @Inject(REDIRECT_STATE_SERVICE)
    private stateRedirectService: IRedirectStateService
  ) {}

  ngOnInit(): void {
    this.getAccountsMenuItem();
    this.supportViewFacade.getRIAUserFlag();
    this.supportViewFacade.isRIAUser$.subscribe(isRIA => {
      this.isRIAUser = isRIA;
      this.checkingAccountValidation();
      this.changeDetectorRef.markForCheck();
    });
    this.checkFacingBrandId();

    this.accountAggregationService.logUserAction(
      new UserAction('SearchInstitution', [
        new UserActionField('Status', 'SearchInstitution', 'Started to search'),
      ])
    );

    this.initialize();
    this.isAccountAggregationFlow =
      this.stateParams.isAccountAggregationFlow || false;
    this.isPfm3Active = this.stateParams.isPfm3Active || false;
    this.isSBBActive = this.featureFlagService.isSBBActive();
    if (this.rootScope['brandProperties']) {
      this.setOpenAccountItems(this.rootScope['brandProperties']);
    } else {
      this.rootScope.$on('brandPropertiesLoaded', () => {
        this.setOpenAccountItems(this.rootScope['brandProperties']);
      });
    }
    this.isMoveMoneyFlow = this.stateParams.isMoveMoneyFlow || false;

    if (
      this.fundingState.isRunning ||
      this.isMoveMoneyFlow ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.Funding) ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.MoveMoney) ||
      this.flowService.isRunningFlow(
        this.flow,
        this.flowTypeEnum.ManagePortfoliosTransFun
      )
    ) {
      this.bankLogos = [...fundingFlowBanks];
    } else {
      this.bankLogos = [...Banks];
    }

    this.bankLogos.forEach(bank => {
      bank.onSelected = () => this.onClickedBankLogo(bank.name);
    });

    this.bankName = this.stateParams['bankName'];
    if (!!this.bankName) {
      setTimeout(() => {
        this.onClickedBankLogo(this.bankName);
      }, 0);
    }

    try {
      this.noResultsImgSrc = '/img/icons/tiles/no-results.svg';
    } catch {}
  }

  ngOnDestroy(): void {
    this.ngRedux.subscribe(this.initialize.bind(this));
  }

  /**
   * Gets the index of the last UI's visible search result
   */
  get lastResultIndex(): number {
    return this.currentResultChunk * this.resultChunkSize;
  }

  /**
   * Method used before searching to determine if it should be triggered
   */
  get isValidSearch(): boolean {
    if (this.bankName == undefined) {
      return false;
    }
    return (
      !this.isLoading &&
      this.bankName.trim() !== '' &&
      this.bankName.trim().length >= 3
    );
  }

  // Method to perform when check buttons were clicked
  checkItem(type: number) {
    switch (type) {
      case FastlinkFlow.VerificationOnly:
        this.clickMoveMoney = !this.clickMoveMoney;
        if (!this.clickMoveMoney) {
          this.flowTypeSelected = FastlinkFlow.AggregationOnly;
        } else {
          this.flowTypeSelected = type;
        }
        break;

      case FastlinkFlow.AggregationOnly:
        this.clickLinkAccounts = !this.clickLinkAccounts;
        if (!this.clickLinkAccounts) {
          this.flowTypeSelected = FastlinkFlow.VerificationOnly;
        } else {
          this.flowTypeSelected = type;
        }
        break;
    }

    if (this.clickLinkAccounts && this.clickMoveMoney) {
      this.flowTypeSelected = FastlinkFlow.VerificationAggregation;
      return;
    }

    if (!this.clickLinkAccounts && !this.clickMoveMoney) {
      this.flowTypeSelected = null;
      return;
    }
  }

  /**
   * Event that will be triggered when a user clicks a bank logo
   * @param bankName Bank that will be searched and displayed in search's text input
   */
  onClickedBankLogo = (bankName: string) => {
    this.showFooter = false;
    this.showOnKey = true;
    this.bankName = bankName;
    this.requestBanksSearch(bankName).then(() => {
      this.getMoreVisibleResults();
    });
  };

  /**
   * Binds user's selected bank from search so it will be ready to the next step
   * (authenticate the user and add the account)
   * @param item Selected item from angucomplete's type ahead suggestions
   */
  onSelectedBank = (bank: Institution) => {
    if (this.fundingState.isRunning && !bank.isLocal) {
      this.errorMessage = `International Institutions are not supported at this time.
        Please select a domestic institution or use another funding method`;

      return;
    }
    this.addInstitution(bank);
  };

  /**
   * Check if the flow is in the array enum of flows
   * this means the flow is available for the
   * selected financial institution
   * @param {fastlinkFlow} flow
   * @return {*}
   */
  selectedBankCan(flow: number) {
    let s = false;
    if (this.selectedInstitution)
      s = this.selectedInstitution.fastlinkFlow == flow ? true : false;

    return s;
  }

  /**
   *
   * Set the selected bank or financial institution
   * and change the value of some properties to hide or  show them
   * @param {Institution} bank selected bank or financial institution
   */
  setSelectedBank(bank: Institution) {
    try {
      if (bank.fastlinkFlow === FastlinkFlow.None) {
        throw new Error('');
      } else {
        this.selectedInstitution = bank;
        this.showAggregationValidation = true;
        this.isLoading = false;
        this.accountAggregationService.logUserAction(
          new UserAction('SearchInstitution', [
            new UserActionField('Bank selected', null, bank.name),
          ])
        );
      }
    } catch (error) {
      this.serviceHelper.errorHandler(error, true, () =>
        this.goToTriggerPoint()
      );
    }
  }

  /**
   * Go to FastLink auth page
   */
  continueToFastLink() {
    this.onSelectedBank(this.selectedInstitution);
  }

  /**
   * Event handler for search's text input
   * displays search results when enter key is pressed within search's text input
   * @param event ng-key up event
   */
  showResultsFromSearchBox(event: any) {
    if (event.keyCode === 13) {
      this.showFooter = false;
      this.showOnKey = true;
      this.triggerShowResults();
    }
  }

  /**
   * Performs search if certain criteria is met
   */
  triggerShowResults() {
    return (
      this.isValidSearch &&
      this.requestBanksSearch(this.bankName).then(this.getMoreVisibleResults)
    );
  }

  /**
   * Determines if there are enough cashed results to show
   * If not, goes to fetch the next page with results from AccountAggregation
   * If there are not more pages, it completes the search showing all available results
   */
  showMoreResults() {
    this.showManually = true;
    const nextChunkPosition = this.lastResultIndex + this.resultChunkSize;
    if (nextChunkPosition > this.results.length) {
      this.visibleResults = this.results;

      return;
    }
    this.getMoreVisibleResults();
  }

  /**
   * Performs a request to our service to search for the banks matched
   * with the query string
   * @param bankName String of a bank to search
   * @param page Page number to be requested
   */
  requestBanksSearch = (bankName: string, page = 1) => {
    this.isLoading = true;
    const promiseObj = this.createPromiseToStopSearch();
    this.canceler = promiseObj.promise;
    this.resolverPromiseMethod = promiseObj.resolve;
    return this.accountAggregationService
      .searchBanks(bankName, page, this.canceler)
      .then(({ data }) => {
        if (page === 1) {
          this.results = [];
          this.visibleResults = [];
        }

        this.currentResultChunk = 0;
        this.results = data.banks;
        this.showResults = true;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((err: any) => {
        if (err.xhrStatus === 'abort') return;

        this.serviceHelper.errorHandler(err);
      });
  };

  /**
   * Create an object with promise object and resolver function to stop search if it's in progress
   * @returns {promise: Promise<void>, resolve: function}
   */
  createPromiseToStopSearch() {
    let resolver;

    const promise = new Promise<void>(resolve => {
      resolver = resolve;
    });

    return {
      promise: promise,
      resolve: resolver,
    };
  }

  /**
   * Cleans search and stops it if in progress
   * or goes back in navigation history
   */
  goBack() {
    if (
      !this.showAggregationValidation &&
      (this.showResults || this.isLoading)
    ) {
      if (this.canceler !== null && this.canceler != undefined) {
        this.isLoading = false;
        this.resolverPromiseMethod();
        this.canceler = undefined;
        this.resolverPromiseMethod = undefined;
      }
      this.bankName = null;
      this.results = [];
      this.visibleResults = [];
      this.showResults = false;
      this.errorMessage = '';
      this.showOnKey = false;
      this.showFooter = true;

      return;
    }

    if (
      this.showAggregationValidation &&
      this.results.length &&
      this.visibleResults.length
    ) {
      this.showAggregationValidation = false;
      this.showManually = true;
      this.showOnKey = true;
      this.showResults = true;
      this.showFooter = false;
      this.isLoading = false;

      return;
    }
    if (this.flow === 'moveMoney') {
      this.state.go('udb.transfers.transferFunds');

      return;
    }
    if (this.flow === 'details') {
      this.state.go('udb.accounts.external-details', {
        id: this.stateParams.accountId,
        container: this.stateParams.container,
        tab: 1,
      });
      return;
    }

    if (sessionStorage.getItem('fundingAccounts')) {
      this.state.go('udb.managedportfolio', { source: 'account-aggregation' });

      return;
    }

    if (
      this.flowService.isRunningFlow(
        this.flow,
        this.flowTypeEnum.ManagePortfoliosTransFun
      )
    ) {
      this.state.go('udb.axosinvest.transferfunds', { flow: this.flow });

      return;
    }

    this.state.go(
      this.fundingState.isRunning ? 'udb.funding' : 'udb.accounts.dashboard',
      { flow: this.flow }
    );
  }

  goToTriggerPoint() {
    this.closeFastLink();

    let path: string;
    let params = {};

    switch (this.flow) {
      case 'aggregation':
        path = 'udb.accounts.dashboard';
        break;
      case 'moveMoney':
        path = 'udb.transfers.transferFunds';
        break;
      default:
        path = 'udb.accounts.account-aggregation';
        params = {
          isAccountAggregationFlow: false,
          isPfm3Active: true,
        };
        break;
    }

    this.closeFastLink();
    this.state.go(path, params);
  }

  closeFastLink() {
    this._window.fastlink.close();
  }

  /** Redirects to the original angular state defined previously */
  goToOriginalState() {
    this.stateRedirectService.goToOriginalState();
  }

  goToAddExternalAccount() {
    this.state.go(
      this.fundingState.isRunning
        ? 'udb.funding.add-external-account'
        : 'udb.accounts.add-external',
      {
        flow: this.flow,
      }
    );
  }

  /**
   * Verifies if the user has already accounts linked to the selected institution
   * and if the user needs to update the credentials, ask the user for it
   * otherwise just the authentication is needed
   * @param institution The institution selected for authetication
   */
  addInstitution(institution: Institution) {
    const { aggregatedAccounts } = this.cachedAccountsService;

    const needsUpdateCredentials =
      aggregatedAccounts &&
      !(aggregatedAccounts instanceof Error) &&
      !!aggregatedAccounts.filter(
        account =>
          account.routingNumber === institution.bankId.toString() &&
          account.credentialsHaveChanged
      ).length;

    this.state.go('udb.dashboard.account-aggregation.auth', {
      bankId: institution.bankId,
      updateCredentials: needsUpdateCredentials,
      isAccountAggregationFlow: this.isAccountAggregationFlow,
      isMoveMoneyFlow: this.isMoveMoneyFlow,
      institution,
      flowFromChecks: this.flowTypeSelected,
    });
  }

  getBankLogo(bankName: string): any {
    return `${bankName}`;
  }

  getAccountTypeLogo(iconName: string): any {
    return `assets/${iconName}`;
  }

  getChevronIcon(chevronName: string): any {
    return `assets/chevron-${chevronName}.svg`;
  }

  redirectToUrl(url: string) {
    window.open(url, '_blank');
  }

  getLogo(iconName: string): any {
    return `assets/${iconName}.svg`;
  }

  /** When init and every time the state changes this is executed reassingning the funding state */
  private initialize() {
    // Reading state param from url, losing state flow when refreshing
    this.flow = this.stateParams['flow'];
    this.fundingState = this.ngRedux.getState().funding;

    if (this.flow === 'details') {
      this.isLoading = false;
      this.selectedInstitution = this.stateParams['institution'];
      this.showAggregationValidation = true;
      this.showFooter = false;
    }
  }

  /**
   * Sets the next chunk of search results as visible
   * and updates the pointer of the current visible chunk
   */
  private getMoreVisibleResults = () => {
    const lastIndex = this.lastResultIndex;
    this.visibleResults = [
      ...this.visibleResults,
      ...this.results.slice(lastIndex, lastIndex + this.resultChunkSize),
    ];
    this.currentResultChunk++;
    this.changeDetectorRef.markForCheck();
  };

  /** Gets the open account property keys */
  private setOpenAccountItems(properties: any[]): void {
    let keys: string[] = [];

    if (this.featureFlagService.isSBBActive()) {
      keys = Object.keys(properties).filter(
        x =>
          x.startsWith(this.openAccountsBusinessPrefix) &&
          x !== this.excludeFromOpenAccountBusiness
      );
    } else {
      //
      if (this.isRIAUser) {
        keys = Object.keys(properties).filter(
          x =>
            x.startsWith(this.openAccountsPrefix) &&
            this.riaAcctsSuffix.some(s => x.endsWith(s)) &&
            (this.hasAxosCheckingAccount
              ? !x.includes(this.checkingSuffix)
              : true)
        );
      } else {
        keys = Object.keys(properties).filter(x =>
          x.startsWith(this.openAccountsPrefix)
        );
      }
    }

    const items = keys.map(key => {
      const value = this.rootScope['brandProperties'][key];
      const values = value.split('|');
      const imageName = values[1].split('.');
      const image = this.openAccountImages.find(x => x.name === imageName[0]);
      const imageFile = image ? image.imageFile : '';

      const item: OpenAccountItem = {
        description: values[0],
        icon: values[1],
        redirectionUrl:
          this.isRIAUser && values[0] === 'Checking Account'
            ? this.env.axosInvestorChecking
            : values[2],
        imageFile,
      };

      return item;
    });

    if (items && items.length > 0) {
      this.openAccItems = items;
    }
  }

  private checkingAccountValidation(): void {
    if (this.featureFlagService.isRiaPilotActive() && this.isRIAUser) {
      this.axosAdvisoryService
        .getHasAxosCheckingAccount()
        .subscribe(response => {
          if (response.data) {
            this.hasAxosCheckingAccount = true;
            if (this.openAccItems) {
              this.openAccItems = this.riaPilotOpenCheckingAccount();
            }
          }
          this.isBusy = false;
        });
    } else {
      this.isBusy = false;
    }
  }

  private riaPilotOpenCheckingAccount() {
    return this.featureFlagService.isRiaPilotOpenCheckingAccount()
      ? this.openAccItems
      : this.openAccItems.filter(
          x => !x.description.includes(this.checkingSuffix)
        );
  }

  getAccountsMenuItem() {
    this._sblocAccountService.getAccountsMenuItem().subscribe(result => {
      (result ?? []).forEach(item => {
        if (item == null) {
          return;
        }
        this.openMenuItems.push(this.mapMenuItems(item));
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  mapMenuItems(element: AccountsMenuItem) {
    const item: OpenAccountItem = {} as OpenAccountItem;
    item.description = element.fields.Name;
    item.imageFile = element.fields.Icon;
    item.icon = element.fields.Icon.split('-')[1] + '.svg';
    item.redirectionUrl = element.fields.Url;
    return item;
  }

  checkFacingBrandId() {
    if (this.env.facingBrandId === FacingBrand.Axos) {
      this.accountMessage = this.isRIAUser
        ? 'Open a new Account!'
        : 'Open a new Axos Account!';
    } else if (this.env.facingBrandId === FacingBrand.UFB) {
      this.isUFB = true;
      this.ufbItems();
      this.accountMessage = 'Open a new UFB Account!';
    }
  }

  ufbItems() {
    const ufbEntries: OpenAccountItem[] = [
      {
        description: 'Checking Account',
        imageFile: 'openaccount-checking',
        icon: 'checking.svg',
        redirectionUrl: 'https://www.ufbdirect.com/pages/checkingaccount',
      },
      {
        description: 'Savings Account',
        imageFile: 'openaccount-savings',
        icon: 'savings.svg',
        redirectionUrl:
          'https://www.ufbdirect.com/savings/high-yield-savings-account',
      },
      {
        description: 'Mortgage',
        imageFile: 'openaccount-mortgage',
        icon: 'mortgage.svg',
        redirectionUrl: 'https://www.ufbdirect.com/mortgages',
      },
    ];

    ufbEntries.forEach(item => {
      this.openMenuItems.push(item);
    });
  }

  showRightSidebar(): boolean {
    return this.isSBBActive || this.isRIAUser;
  }
}
