import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { isBirthDateValid } from '@app/accounts/utils';
import { ssnRegex, usernameRegex } from '@app/config/regexes';
import { User, ValidUsername } from '@app/enrollment/models';
import { EnrollmentService } from '@core/services';
import { ServiceResult } from '@shared/models';
import { createPasswordValidators } from '@shared/utils/validators';

@Component({
  selector: 'app-user-data-form',
  templateUrl: './user-data-form.component.html',
  styleUrls: ['./user-data-form.component.scss'],
})
export class UserDataFormComponent implements OnInit, OnDestroy {
  @Output() goTo = new EventEmitter<string>();
  @Output() next = new EventEmitter<ValidUsername>();

  title: string;
  userDataForm: UntypedFormGroup;
  usernameMessage: string = null;
  usernameMessageClass: 'text__success' | 'text__error' = 'text__error';
  usernameValidationStatus: 'valid' | 'taken' = null;
  isValidating = false;
  isNextDisabled = false;
  serviceError: SafeHtml = '';
  ssnOptions = {
    hidden: true,
    pattern: { 0: { pattern: new RegExp('\\d'), symbol: '*' } },
  };
  errors = {
    firstName: [{ name: 'required', message: 'First name is required.' }],
    lastName: [{ name: 'required', message: 'Last name is required.' }],
    dateOfBirth: [
      { name: 'required', message: 'Date of birth is required.' },
      { name: 'invalidDate', message: 'Date of birth is invalid.' },
    ],
    ssn: [
      { name: 'required', message: 'Social security number is required.' },
      { name: 'pattern', message: 'Entered social security number is invalid.' },
    ],
    username: [
      { name: 'required', message: 'Username is required.' },
      { name: 'pattern', message: 'Invalid characters.' },
      { name: 'maxlength', message: 'Username needs to be max 30 characters long.' },
      { name: 'minlength', message: 'Username needs to be at least 6 characters long.' },
    ],
    password: [{ name: 'required', message: 'Password is required.' }],
  };

  private subSink = new SubSink();

  constructor(
    private fb: UntypedFormBuilder,
    private enrollmentService: EnrollmentService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.title = this.enrollmentService.getBrandData().title;

    this.initForm();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  dismissError() {
    this.serviceError = null;
  }

  submitData() {
    if (this.userDataForm.invalid || this.usernameValidationStatus === 'taken') {
      this.userDataForm.markAllAsTouched();
    } else {
      this.isValidating = true;
      this.trimFormValues();

      const user = this.userDataForm.value as User;
      const next = this.validateInformationSubscriber.bind(this);
      const error = this.validateInformationErrorSubscriber.bind(this);

      this.enrollmentService
        .validateInformation(user)
        .pipe(
          finalize(() => {
            this.isValidating = false;
          }),
          catchError(res => throwError(res.error))
        )
        .subscribe({ next, error });
    }
  }

  private initForm() {
    this.userDataForm = this.fb.group({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      dateOfBirth: this.fb.control('', [Validators.required, isBirthDateValid()]),
      ssn: this.fb.control('', [Validators.required, Validators.pattern(ssnRegex)]),
      username: this.fb.control('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
        Validators.pattern(usernameRegex),
      ]),
      password: this.fb.control('', createPasswordValidators(25)),
    });

    this.subSink.sink = this.userDataForm
      .get('username')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(value => {
          this.usernameMessage = null;
          this.usernameMessageClass = null;

          return value.trim();
        }),
        filter(trimmed => usernameRegex.test(trimmed)),
        switchMap(value => {
          if (this.userDataForm.controls.username.errors) return;
          this.usernameMessage = 'Checking username validity...';

          return this.enrollmentService.validateUsernameInfo(value).pipe(catchError(res => of(res.error)));
        })
      )
      .subscribe(this.validateUsername.bind(this));
  }

  private validateUsername(res: ServiceResult<string>) {
    if (res.statusCode === 200) {
      this.usernameMessage = res.data;
      this.usernameValidationStatus = 'valid';
      this.usernameMessageClass = 'text__success';
    } else {
      this.usernameMessage = res.message;
      this.usernameValidationStatus = 'taken';
      this.usernameMessageClass = 'text__error';
    }
  }

  private trimFormValues() {
    const trimmed = Object.keys(this.userDataForm.value).reduce((acc, curr) => {
      const val = `${this.userDataForm.value[curr]}`.trim();

      acc[curr] = val;

      return acc;
    }, {});

    this.userDataForm.patchValue(trimmed);
  }

  private validateInformationSubscriber({ data }: ServiceResult<ValidUsername>) {
    if (data.maskedEmail) {
      window.sessionStorage.setItem('maskedEmail', data.maskedEmail);
    }
    if (data.maskedCellPhone) {
      window.sessionStorage.setItem('maskedCellPhone', `(***) ***-${data.maskedCellPhone}`);
    }

    data.password = this.userDataForm.value.password;
    this.next.emit(data);
  }

  private validateInformationErrorSubscriber(res: ServiceResult<string>) {
    const { brandName, marketingUrl } = this.enrollmentService.getBrandData();
    let str = res.message;

    if (str.includes('<a>')) {
      const { protocol, host } = window.location;
      str = str.replace('<a>', `<a href="${protocol}//${host}/auth/ForgotUsername">`);
    }

    /* Just to add a dot at the end of the message **/
    if (str.includes('Customer matching the SSN')) {
      str = `${str}.`;
    }

    if (str.includes('We were unable to find an account with')) {
      const connector = brandName === 'Nationwide' ? 'a' : 'an';
      str = `${str} If you are not yet ${connector} ${brandName} customer, <a href='${marketingUrl}'>Enroll now.</a>`;
    }

    if (str.includes('Customer is flagged as deceased')) {
      str =
        'We cannot register you at this time. Please contact Customer Service at (888) 502-2967 or chat with us through Evo.';
      this.isNextDisabled = true;
    }

    this.serviceError = this.domSanitizer.bypassSecurityTrustHtml(str);
  }
}
