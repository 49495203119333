import { ActivateAccountModalComponent } from './activate-account-modal/activate-account-modal.component';
import { AddInvestorCheckingSuccessComponent } from './add-investor-checking-success/add-investor-checking-success.component';
import { CardActivatedModalComponent } from './card-activated-modal/card-activated-modal.component';
import { SpousalConsentModalComponent } from './spousal-consent-modal/spousal-consent-modal.component';
import { OverdraftCreditLineModalComponent } from './overdraft-credit-line-modal/overdraft-credit-line-modal.component';
import { AccountReviewModalComponent } from './account-review-modal/account-review-modal.component';
import {
  InvestorCheckingEnrollmentModalComponent,
  InvestorCheckingEnrollmentModalComponentV2,
  SimpleMatStepperComponent,
  InformationStepComponent,
  FeaturesStepComponent,
  DisclosuresStepComponent,
} from './investor-checking-enrollment-modal';
import { TxCheckDetailsModalComponent } from './tx-check-details-modal';
import { GenerateQuoteErrorModalComponent } from './generate-quote-error-modal/generate-quote-error-modal.component';
import { InternalToExternalComponent } from './move-money-modal/internal-to-external/internal-to-external.component';

export const sharedModals = [
  ActivateAccountModalComponent,
  CardActivatedModalComponent,
  InvestorCheckingEnrollmentModalComponent,
  InvestorCheckingEnrollmentModalComponentV2,
  OverdraftCreditLineModalComponent,
  SpousalConsentModalComponent,
  AddInvestorCheckingSuccessComponent,
  AccountReviewModalComponent,
  TxCheckDetailsModalComponent,
  GenerateQuoteErrorModalComponent,
  InternalToExternalComponent,
];

export const modalsDependencies = [
  SimpleMatStepperComponent,
  InformationStepComponent,
  FeaturesStepComponent,
  DisclosuresStepComponent,
];

export {
  ActivateAccountModalComponent,
  CardActivatedModalComponent,
  InvestorCheckingEnrollmentModalComponent,
  InvestorCheckingEnrollmentModalComponentV2,
  OverdraftCreditLineModalComponent,
  SpousalConsentModalComponent,
  AddInvestorCheckingSuccessComponent,
  AccountReviewModalComponent,
  TxCheckDetailsModalComponent,
  GenerateQuoteErrorModalComponent,
  InternalToExternalComponent,
};
