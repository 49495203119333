import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Minor, States } from '@app/minors/models';
import { EnrollmentService } from '@core/services';

@Component({
  selector: 'app-minor-address',
  templateUrl: './minor-address.component.html',
  styleUrls: ['./minor-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinorAddressComponent implements OnInit {
  @Input() minor: Minor;
  @Input() parentForm: FormGroup;
  @Input() isSubmitted: boolean;
  states: States;
  acceptTerms: boolean;
  zipCodePattern: RegExp = new RegExp(/^\d{5}$/);

  statesAbbreviations: string[];
  statesFullNames: string[];

  constructor(private enrollmentService: EnrollmentService) {}

  ngOnInit() {
    this.setStates();
  }

  get minorAddressStreetControl(): FormControl {
    return this.parentForm.get('minorAddressStreet') as FormControl;
  }
  get minorAddressZipCodeControl(): FormControl {
    return this.parentForm.get('minorAddressZipCode') as FormControl;
  }

  get minorCityControl(): FormControl {
    return this.parentForm.get('minorCity') as FormControl;
  }
  get minorStateControl(): FormControl {
    return this.parentForm.get('minorState') as FormControl;
  }
  get sameAsOwnerControl(): FormControl {
    return this.parentForm?.get('sameAsOwner') as FormControl;
  }
  setStates() {
    const statesInstance = new States();
    this.statesAbbreviations = Object.keys(statesInstance.states);
    this.statesFullNames = Object.values(statesInstance.states);
  }

  getCityByZipCode(): void {
    const zipCode = this.minorAddressZipCodeControl.value;
    const address: Address = {
      city: null,
      stateCode: null,
      postalCode: zipCode,
    };
    this.minor.address = address;

    if (zipCode && zipCode.length === 5) {
      this.enrollmentService.getCityAndState(zipCode).subscribe(
        res => {
          const info = res.data;
          if (info) {
            this.minor.address.city = info?.city;
            this.minor.address.stateCode = info?.stateCode;
          }
        },
        err => {
          if (err.status === 404) {
            this.minor.address.city = null;
            this.minor.address.stateCode = null;
            alert('Could not find city and state by zip code.\nPlease enter city and state');
          } else {
            console.error(err);
          }
        }
      );
    }
  }
}
