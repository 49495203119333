<input
  [name]="name"
  price
  required
  [placeholder]="placeholder"
  class="uk-input"
  type="text"
  [ngClass]="{ red: hasError }"
  [value]="formattedAmount"
  [disabled]="disabled"
  (change)="convertFormattedAmountToNumber($event)"
/>
