import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // TsLint rule disabled because the directive is needed for upgrading components.
  // tslint:disable-next-line: directive-selector
  selector: 'sitecore-click-switch-component',
})
export class SitecoreClickSwitchDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('sitecoreClickSwitchComponent', elementRef, injector);
  }
}
