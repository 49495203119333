<!-- Transaction category icon -->
<td class="tx-table__row__category" (click)="showDetails(trxn)">
  <i *ngIf="trxn.isPending" style="display: flex;" class="bofi-pending" aria-hidden="true"></i>
  <span *ngIf="!trxn.isPending && transactionIsNotPending()" class="tx-table__row__category--icon">
    <div [innerHTML]="categoryIcon"></div>
    <!-- <div ng-include="getImagePath()"></div> -->
  </span>
</td>

<!-- Date -->
<td (click)="showDetails(trxn)">
  <span *ngIf="trxn.isPending">{{ trxn.pendingStatus | uppercase }}</span>
  <span *ngIf="trxn.isPending">{{ trxn.postedDate | date: 'MM/dd/yyyy' }}</span>
</td>

<!-- Description -->
<td class="tx-table__row__desc" (click)="showDetails(trxn)">
  <span [innerHTML]="trxn.description" [ngClass]="{ 'bofi-check': trxn.hasCheck }"></span>
  <!-- PFM3 new tooltip -->
  <span *ngIf="isPfm3ExternalTransActive" class="desc-tooltip">{{ trxn.originalDescription }}</span>
</td>

<!-- Amount -->
<td class="text-right" (click)="showDetails(trxn)">
  <span class="text-right">{{ getTransactionAmount(trxn) }}</span>
</td>

<!-- Total balance Account Aggregated -->
<td class="text-right" (click)="showDetails(trxn)" *ngIf="isExternalLoan">
  <span>{{ getAccountBalance(trxn) }}</span>
</td>
<!-- Options (recategorize a transaction) -->
<td>
  <app-tx-options
    [options]="accountOptions"
    [isDisabled]="false"
    [isExpanded]="trxn.isExpanded"
    [isDetailsExpanded]="trxn.isDetailsExpanded"
    (onOptionSelected)="selectOption($event)"
    (onCollapse)="onCollapse()"
    class="options-tooltip"
  ></app-tx-options>
</td>
