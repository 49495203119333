import { BaseService } from './base.service';
import { Inject } from '../decorators/decorators';
import { IBeneficiariesBaseService } from './typings/IBeneficiariesBaseService';
import { Beneficiary } from '@shared/models/Beneficiary';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class BeneficiariesBaseService extends BaseService implements IBeneficiariesBaseService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'accounts', _serviceHelper, q);
  }

  getRelationshipCatalog(): ApiResponse<object> {
    return this.get('beneficiaries-base/catalogs/relationship');
  }
  addBeneficiary(olbAccountId: number, beneficiary: Beneficiary): ApiResponse<string> {
    return this.post(`${olbAccountId}/beneficiaries-base`, beneficiary);
  }
  editBeneficiary(olbAccountId: number, beneficiary: Beneficiary): ApiResponse<undefined> {
    return this.put(`${olbAccountId}/beneficiaries-base`, beneficiary);
  }
  removeBeneficiary(olbAccountId: number, beneficiaryKey: string): ApiResponse<undefined> {
    return this.post(`${olbAccountId}/beneficiaries-base/delete`, { key: beneficiaryKey });
  }
}
