import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LEGACY_SERVICE_HELPER, LEGACY_TRANSACTION_SERVICE } from '@app/transfers/ajs-upgraded-providers';
import { EditInternalTransferData, TransferSchedule } from '@app/transfers/models';
import { ITransactionService } from '@legacy/services/typings/ITransactionService';

import { DateHelperService as DateHelper } from '@app/core/services/date.service';
// ! DO NOT DELETE THIS IS JUST FOR UNIT TESTING -> JQUERY RELATED
import * as moment from 'moment';
import { DatePickerEvents } from '@shared/models';
import { DatePickerReferenceHandler } from '@app/transfers/utils';
declare var $: any;

@Component({
  selector: 'app-edit-internal-transfer-modal',
  templateUrl: './edit-internal-transfer-modal.component.html',
  styleUrls: ['./edit-internal-transfer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditInternalTransferModalComponent implements OnInit {
  isLoadingSetup = false;
  datePickerOptions: any;
  datePicker: any;
  minDate: Date;
  errorMsg: string;
  transferToEdit!: TransferSchedule;
  isSeries!: boolean;
  frequencies!: GenericOption[];
  dateHandler!: DatePickerReferenceHandler;

  constructor(
    public dateHelper: DateHelper,
    @Inject(LEGACY_TRANSACTION_SERVICE) public _transactionService: ITransactionService,
    @Inject(LEGACY_SERVICE_HELPER) public _serviceHelper: IServiceHelper,
    public dialogRef: MatDialogRef<EditInternalTransferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogContext: EditInternalTransferData
  ) {
    this.transferToEdit = this.dialogContext.transferToEdit;
    this.isSeries = this.dialogContext.isSeries;
    this.frequencies = this.dialogContext.frequencies;
  }

  ngOnInit(): void {
    const { externalTransferValidDate } = this.dateHelper.getDefaultPaymentInformation();
    this.minDate = externalTransferValidDate.toDate();
    this.datePickerOptions = {
      ...this.dialogContext.dateRangePickerOptions,
      minDate: this.minDate,
      maxDate: moment().add(13, 'M'),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'hide.daterangepicker': (_evt: any) => {
          this.dialogContext.transferToEdit.newNextTransferDate = moment(_evt.model).toDate();
          this.dialogContext.transferToEdit.beginSendingOn = moment(_evt.model).toDate();
        },
        'show.daterangepicker': (_evt: any) => {
          this.setPropertiesForCalendar();
        },
      },
    };
    this.dialogContext.transferToEdit.newNextTransferDate = this.dialogContext.transferToEdit.nextTransferDate;
    this.dialogContext.transferToEdit.oldAmount = this.dialogContext.transferToEdit.amount;
    this.dialogContext.transferToEdit.oldFrequency = this.dialogContext.transferToEdit.frequency;
    this.dialogContext.transferToEdit.oldBeginSending = this.dialogContext.transferToEdit.beginSendingOn;
    this.dateHandler = new DatePickerReferenceHandler(this.transferToEdit.newNextTransferDate);
    this.errorMsg = null;
  }

  transformDateEvent($event: DatePickerEvents) {
    this.dialogContext.transferToEdit.newNextTransferDate = $event.picker.startDate.toDate();
    this.dialogContext.transferToEdit.beginSendingOn = $event.picker.startDate.toDate();
  }

  async saveTransfer(): Promise<void> {
    if (this.validateAmount()) {
      if (this.dialogContext.isSeries) {
        this.isLoadingSetup = true;

        await this._transactionService
          .scheduleEditInternalTransfer(this.dialogContext.transferToEdit)
          .then(_res => {
            this.dialogRef.close(true);
          })
          .catch(err => {
            this._serviceHelper.errorHandler(err);
          })
          .finally(() => {
            this.isLoadingSetup = false;
          });
      } else {
        this.isLoadingSetup = true;
        await this._transactionService
          .scheduleEditNextInternalTransfer(this.dialogContext.transferToEdit)
          .then(_res => {
            this.dialogRef.close(true);
          })
          .catch(err => {
            this._serviceHelper.errorHandler(err);
          })
          .finally(() => {
            this.isLoadingSetup = false;
          });
      }
    }
  }

  closeModal() {
    this.dialogRef.close(false);
  }
  /**
   * This method is used to show/hide the calendar icon in the date picker.
   * When the user clicks on the calendar icon, this will show/hide
   * the calendar in the date picker.
   *
   * The jQuery selector '#date' is used to get the date picker element
   * in the DOM. The method .data('daterangepicker') is used to get the
   * daterangepicker instance that is attached to the DOM element.
   *
   * The property isShowing is used to check if the calendar is currently
   * being displayed or not. If it is not, then the show() method is called
   * on the daterangepicker instance. If it is being displayed, then the
   * hide() method is called.
   *
   * The reason for ignoring this code is because it is not possible to test
   * this functionality in a unit test because it is dependent on DOM
   * interactions. Therefore, the code coverage tool should not flag this
   * as uncovered code.
   */
  /* istanbul ignore next */
  showCalendar(): void {
    const dp = $('#date');
    if (!dp.data('daterangepicker').isShowing) {
      // show the calendar if it is not being displayed
      dp.data('daterangepicker').show();
    } else {
      // hide the calendar if it is being displayed
      dp.data('daterangepicker').hide();
    }
  }

  validateAmount(): boolean {
    if (this.dialogContext.transferToEdit.amount > 0) {
      this.errorMsg = null;

      return true;
    }
    this.errorMsg = 'Please enter Amount';

    return false;
  }
  /* istanbul ignore next */
  setPropertiesForCalendar() {
    const input = $('#date').first();
    const daterangepicker = $('.daterangepicker');

    daterangepicker.css('left', input.offset().left + input.outerWidth() + 10);
    daterangepicker.css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    daterangepicker.append('<div id="arrow-left"></div>');
    daterangepicker.addClass('no-after');

    $('#arrow-left')
      .css('position', 'absolute')
      .css('left', '-8px')
      .css('width', '0')
      .css('height', '0')
      .css('border-top', '8px solid transparent')
      .css('border-bottom', '8px solid transparent')
      .css('border-right', '8px solid darkgray')
      .css('top', daterangepicker.outerHeight() / 2);

    $(document).on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });

    $('.modal-body').on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });
  }
}
