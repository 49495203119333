<ng-container [ngSwitch]="currentView">
  <up-section-heading
    [section]="currentView"
    [canReturn]="currentView !== views.PersonalInfo"
    [mobile]="isMobileDevice && currentView === views.PersonalInfo"
    (return)="goBack()"
    (expand)="expanded = $event"
  ></up-section-heading>

  <div *ngSwitchDefault [@startStaticSlideFromLeft]="animationState">
    <div class="section__heading">
      <p class="mb-3">
        Ensure your information is up-to-date for the best experience.
      </p>
    </div>

    <div *ngIf="!isMobileDevice || expanded" @openClose [@.disabled]="!isMobileDevice">
      <up-profile-item
        itemName="Financial Info"
        [editable]="userCanEdit"
        (edit)="goTo(views.Financial)"
        [alertMessage]="alertMessages.financial"
        class="financial-info"
      >
        <app-dot-loader *ngIf="profileLoading || catalogsLoading; else financialInfoSection"></app-dot-loader>
        <ng-template #financialInfoSection>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters annual-income">
            <p class="font-weight-bold col-12 col-lg-5">Annual Income</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="annualIncomes[financialInfo.annualIncome] || ''"
            ></p>
          </div>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters total-net-worth">
            <p class="font-weight-bold col-12 col-lg-5">Total Net Worth</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="totalNetWorths[financialInfo.totalNetWorth] || ''"
            ></p>
          </div>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters tax-bracket">
            <p class="font-weight-bold col-12 col-lg-5">Tax Bracket</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="taxBrackets[financialInfo.taxBracket] || ''"
            ></p>
          </div>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters liquid-net-worth">
            <p class="font-weight-bold col-12 col-lg-5">Liquid Net Worth</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="liquidNetWorths[financialInfo.liquidNetWorth] || ''"
            ></p>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Family Info"
        [editable]="userCanEdit"
        (edit)="goTo(views.Family)"
        [alertMessage]="alertMessages.family"
        class="family-info"
      >
        <app-dot-loader *ngIf="profileLoading || maritalStatusesLoading; else familyInfoSection"></app-dot-loader>
        <ng-template #familyInfoSection>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 marital-status">
            <p class="col-12 col-lg-5 font-weight-bold">Marital Status</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="maritalStatuses[familyInfo.maritalStatus] || ''"
            ></p>
          </div>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 number-of-dependents">
            <p class="col-12 col-lg-5 font-weight-bold">Number of Dependents</p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="familyInfo.numberOfDependents"></p>
          </div>
        </ng-template>
      </up-profile-item>
    </div>
  </div>

  <up-change-financial-info
    @slideFromRight
    *ngSwitchCase="views.Financial"
    [financialInfo]="financialInfo"
    (updateFinancialInfo)="updateFinancialInfo($event)"
    (back)="goBack()"
  ></up-change-financial-info>

  <up-change-family-info
    @slideFromRight
    *ngSwitchCase="views.Family"
    [familyInfo]="familyInfo"
    (updateFamilyInfo)="updateFamilyInfo($event)"
    (back)="goBack()"
  ></up-change-family-info>
</ng-container>
