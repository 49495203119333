import { WireAccessCodeController } from './wire-access-code.controller';

let wireAccessCode: ng.IComponentOptions = {
  controller: WireAccessCodeController,
  templateUrl: 'transfers/wire-transfers/access-code/wire-access-code.tpl.html',
  bindings: {
    onSuccess: '&',
    onBack: '&',
    isLoading: '<',
    errorMessage: '<',
    displayRememberDevice: '<',
    rememberDeviceHash: '<',
  },
};

export { wireAccessCode };
