import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppStateFeatures } from '@core/enums';

export const getSettings = createFeatureSelector<Record<string, string>>(AppStateFeatures.Settings);

/**
 * Tries to parse the setting value and return it.
 * Some settings are not compatible and will fail, such as hours stored in the database as "16:00".
 * If you need to get the string value, please use getSetting instead.
 *
 * @param settingName Name of the setting. Please use the AppSettings enum for setting names.
 *
 * @returns The parsed value for the setting.
 */
export function getParsedSetting<T>(settingName: string) {
  return createSelector(getSettings, state => {
    const setting = state[settingName];

    return JSON.parse(setting) as T;
  });
}

/**
 * Gets the value of the setting as-is.
 *
 * @param settingName Name of the setting. Please use the AppSettings enum for setting names.
 *
 * @returns The string value for the setting.
 */
export function getSetting(settingName: string) {
  return createSelector(getSettings, state => state[settingName]);
}
