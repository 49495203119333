export class FinancialInfo {
  annualIncome: string;
  totalNetWorth: string;
  liquidNetWorth: string;
  taxBracket: string;

  constructor(info?: Partial<FinancialInfo>) {
    Object.assign(this, info);
  }
}
