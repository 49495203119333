import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { TradingAccount } from '@app/axos-trading/models';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import { FinancialIcons } from '@shared/enums';

@Component({
  selector: 'app-margin-enabled-notification',
  templateUrl: './margin-enabled-notification.component.html',
  styleUrls: ['./margin-enabled-notification.component.scss'],
})
export class MarginEnabledNotificationComponent implements OnInit {
  @Input() account: TradingAccount;

  icon = FinancialIcons.MoneyHand;
  $orbisUrl: Observable<string>;

  constructor(private routeHelper: TradingRoutingHelperService) {}

  ngOnInit(): void {
    this.$orbisUrl = this.routeHelper.getOrbisUrl();
  }
}
