import { Component, Inject, OnInit } from '@angular/core';

import { FacingBrand } from '@core/enums';
import { OlbSettings } from '@core/models';
import { olbSettings, STATE } from '@core/tokens';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  brandName: string;

  get currentLogo(): string {
    return `assets/${this.settings.brand}/logos/toolbar-logo.svg`;
  }

  get isUfb(): boolean {
    return this.settings.facingBrandId === FacingBrand.UFB;
  }

  constructor(@Inject(olbSettings) private settings: OlbSettings, @Inject(STATE) private $state: ng.ui.IStateService) {}

  ngOnInit(): void {
    this.brandName = this.settings.brandName;
  }

  goTo(state: string) {
    this.$state.go(state);
  }
}
