import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { capitalize, truncate } from '@app/utils';
import { PortfolioAllocationFacade } from '../../facade/portfolio-allocation.facade';
import { PortfolioAllocationTileState } from '../../core';
import { Category } from '@app/pfm/models/category';
import { chartOptions } from './chart-options.type';
import { TAB_TITLE, STOCK_COLORS, BORDER_WIDTH, EMPTY_CATEGORY_NAME, STRING_CURRENCY_VALUE_MAX_LEN } from './constants';
import { BREAKPOINTS } from '@shared/constants';
import { AvailableCashFacade } from '../../../available-cash/facade';
import { HoldingFacade } from '../../../holdings/facade';

@Component({
  selector: 'app-portfolio-allocation-tile',
  templateUrl: './portfolio-allocation-tile.component.html',
  styleUrls: ['./portfolio-allocation-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioAllocationTileComponent implements OnInit, OnDestroy {
  tabTitle = TAB_TITLE;
  @ViewChild('chartCanvas', { static: true }) chartCanvasRef!: ElementRef<HTMLCanvasElement>;
  portfolioAllocationTile: PortfolioAllocationTileState | undefined;
  portfolioAllocationState$: Observable<PortfolioAllocationTileState>;
  emptyChart = false;
  readonly destroy$ = new Subject<void>();
  portfolioAllocationGrid: Category[] = [];
  chartReady = false;
  chartOptions = chartOptions;
  truncateValues = false;
  private currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  constructor(
    private portfolioAllocationFacade: PortfolioAllocationFacade,
    private availableCashFacade: AvailableCashFacade,
    private holdingFacade: HoldingFacade,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setInitPortfolioAllocationTile();
    this.setBreakpointConfig();
  }

  ngOnDestroy(): void {
    console.log('this component umounts');
    this.destroy$.next();
    this.destroy$.complete();
  }

  setInitPortfolioAllocationTile() {
    combineLatest([
      this.availableCashFacade.availableCashTileState$,
      this.holdingFacade.holdingTileAccountState$,
      this.portfolioAllocationFacade.portfolioAllocationTileAccountState$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([, , portfolioAllocation]) => portfolioAllocation !== undefined)
      )
      .subscribe({
        next: ([availableCashTileState, holdingTileAccountState, portfolioAllocation]) => {
          if (availableCashTileState?.availableCash === 0 || holdingTileAccountState?.holdings.length === 0) {
            this.createEmptyCategory();
            this.chartReady = true;
            return;
          }
          this.setPortfolioAllocation(portfolioAllocation);
          this.portfolioAllocationState$ = this.portfolioAllocationFacade.portfolioAllocationTileAccountState$;
          this.initPortfolioAllocationGrid(this.portfolioAllocationTile);
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  initPortfolioAllocationGrid(portfolioAllocationTile: PortfolioAllocationTileState): void {
    const totalPortfolioAssets = portfolioAllocationTile.portfolioAllocations.length;

    if (portfolioAllocationTile.portfolioAllocations && totalPortfolioAssets > 0) {
      chartOptions.elements.arc.borderWidth =
        totalPortfolioAssets > 1 ? BORDER_WIDTH.MULTIPLE_ASSETS : BORDER_WIDTH.SINGLE_ASSET;

      this.createCategory(portfolioAllocationTile);
    } else {
      this.createEmptyCategory();
    }
    this.chartReady = true;
  }

  setBreakpointConfig() {
    this.breakpointObserver
      .observe(['(max-width: ' + BREAKPOINTS.lg + 'px)', '(max-width: ' + BREAKPOINTS.xl + 'px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result.matches) {
          this.truncateValues = result.breakpoints['(max-width: ' + BREAKPOINTS.lg + 'px)'] ? true : false;
          this.initPortfolioAllocationGrid(this.portfolioAllocationTile);
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  percentFormat(value: number) {
    const formattedValue = (value * 100).toFixed(2);
    return formattedValue;
  }

  barChartPercentFormat(value: number) {
    const formattedValue = (value * 100).toFixed(4);
    return formattedValue;
  }

  descriptionFormat(description: string) {
    const formattedDescription = this.truncateValues ? truncate(capitalize(description), 21) : capitalize(description);

    return formattedDescription;
  }

  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }

  truncateCurrencyValue(value: number, maxLength: number): string {
    let stockValue = this.currency.format(value);
    return stockValue.length > maxLength ? stockValue.substring(0, maxLength) + '...' : stockValue;
  }

  formatValue(value: number) {
    return this.truncateValues
      ? this.truncateCurrencyValue(value, STRING_CURRENCY_VALUE_MAX_LEN)
      : this.currency.format(value);
  }

  getColorBox(index: number): string {
    const colorKeys = Object.keys(STOCK_COLORS);
    const colorKey = colorKeys[index % colorKeys.length];
    return STOCK_COLORS[colorKey];
  }

  createCategory(portfolioAllocationTile: PortfolioAllocationTileState) {
    this.portfolioAllocationGrid = portfolioAllocationTile.portfolioAllocations.map((item, i) => {
      const category: Category = {
        id: i,
        name: item.description,
        color: this.getColorBox(i),
        amount: Math.max(Number(this.barChartPercentFormat(item.percent)), 1) * 1000,
        percentage: item.percent,
      };
      return category;
    });
  }
  createEmptyCategory(): void {
    this.emptyChart = true;
    const category: Category = {
      id: 1,
      name: EMPTY_CATEGORY_NAME,
      color: STOCK_COLORS.GRAY_500,
      amount: 100,
    };
    this.portfolioAllocationGrid.push(category);
  }

  private setPortfolioAllocation(portfolioAllocation: PortfolioAllocationTileState) {
    this.portfolioAllocationTile = portfolioAllocation;
  }
}
