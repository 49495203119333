import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { STATE } from '@core/tokens';

import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-investor-checking',
  templateUrl: './investor-checking.component.html',
  styleUrls: ['./investor-checking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingComponent implements OnInit {
  constructor(@Inject(STATE) private state: ng.ui.IStateService, private featureFlagService: FeatureFlagService) {}
  ngOnInit(): void {
    if (!this.featureFlagService.isRiaPilotActive()) {
      this.state.go('udb.dashboard');
    }
  }
}
