//This helper is shared between Base Beneficiaries and Tax Plan Beneficiaries
//   it is used to inform the account details view if the changes were saved successfully
//   so that a toast is shown to the user

export class BeneficiariesIraEnhHelper {
  public toastMessage: string;
  public toastType: ToastType;

  public clearToast() {
    this.toastMessage = null;
    this.toastType = null;
  }

  public setToast(message: string, type: ToastType) {
    this.toastMessage = message;
    this.toastType = type;
  }
}

export enum ToastType {
  error = 'error',
  warning = 'warning',
  success = 'success',
}
