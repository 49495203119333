import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AlertsIcons, BiometricsIcons } from '@shared/enums';

@Component({
  selector: 'app-reveal-input-label',
  templateUrl: './reveal-input-label.component.html',
  styleUrls: ['./reveal-input-label.component.scss'],
})
export class RevealInputLabelComponent {
  @Input() target: string;
  @Input() tooltip: string;
  @Input() targetIsInvalid = false;
  @Input() switchEventOnly = false;
  @Output() switch = new EventEmitter();

  icons = {
    reveal: BiometricsIcons.Eye,
    hide: BiometricsIcons.EyeHide,
    tooltip: AlertsIcons.QuestionCircle,
  };
  isVisible = false;

  get revealIcon() {
    const { reveal, hide } = this.icons;

    return this.isVisible ? hide : reveal;
  }

  get revealText() {
    return this.isVisible ? 'Hide' : 'Show';
  }

  get iconColor() {
    return this.targetIsInvalid ? 'var(--danger)' : '#000';
  }

  constructor() {}

  switchView() {
    this.isVisible = !this.isVisible;
    if (this.switchEventOnly) {
      this.switch.emit();
    } else {
      document
        .querySelector(`#${this.target}`)
        .setAttribute('type', this.isVisible ? 'text' : 'password');
    }
  }
}
