import { HoldingSortType, HoldingsSortDirection } from '../../const';
import { TFilterOptionLabels } from './holding-filter-option-label.type';
import { THoldingSortDirectionValues } from './holding-sort-directions-values.type';
import { THoldingSortTypeValues } from './holding-sort-type-values.type';

export type Holding = {
  description: string | undefined;
  symbol: string | undefined;
  price: number | undefined;
  quantity: number | undefined;
  marketValue: number | undefined;
  valueLastDayChangeAmount: number | undefined;
  valueLastDayChangePercent: number | undefined;
  allTimeChangeValue: number | undefined;
  allTimeChangePercent: number | undefined;
  classCode: string | undefined;
  classDescription: string | undefined;
  portfolioPercent: number | undefined;
  cusip: string | undefined;
};

export type HoldingTileState = {
  accountNumber: string | undefined;
  includeHoldings: boolean | undefined;
  limit: number | undefined;
  holdings: Holding[];
  isSortingEnabled: boolean;
  sortOptions: TFilterOptionLabels[];
  sortDirection: THoldingSortDirectionValues | undefined;
  sortType: THoldingSortTypeValues | undefined;
  error?: Error | unknown | undefined;
};

export const initialHoldingTileState: HoldingTileState = {
  accountNumber: undefined,
  includeHoldings: undefined,
  limit: undefined,
  holdings: [],
  sortOptions: [],
  isSortingEnabled: false,
  sortDirection: HoldingsSortDirection.Descending,
  sortType: HoldingSortType.PortfolioPercent,
  error: undefined,
};
