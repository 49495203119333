<div class="container compose-message">
  <div class="how-section-title hero-message">
    <h1 class="title">Compose A Message</h1>
    <p class="hide-cs hide-xs" [innerHtml]="subheader"></p>
  </div>
  <div class="shared-back">
    <a class="hidden-xs hidden-sm link" (click)="goPrevious()">
      <i class="fa fa-chevron-left color-chevron"></i>
      Back
    </a>
  </div>
  <app-dot-loader [showWhen]="isLoading && !isDisplayMessageComponent">Loading</app-dot-loader>
  <div class="frow center" ui-view="cm-content">
    <div class="fcol-cs-12 fcol-sm-10 fcol-md-8" *ngIf="!isLoading && !isDisplayMessageComponent">
      <p>
        In order for us to forward your inquiry to the appropriate area of our company, please pick one of the following
        choices that best fits your situation:
      </p>
      <div ui-view="cm-content-menu">
        <app-sm-menu
          (selectItem)="navigateTo($event.categoryId, $event.categoryName)"
          *ngIf="!isLoadingSub && !isLoadingBusinessSelection"
        ></app-sm-menu>
        <app-cm-subcategories
          [category]="categoryEventData"
          (composeMessageEvent)="handleComposeMessageEvent($event)"
          (showSDTMessageEvent)="enableSDTMessage($event)"
          *ngIf="isLoadingSub && !isLoadingBusinessSelection"
        ></app-cm-subcategories>
        <app-cm-business-selection
          *ngIf="isLoadingBusinessSelection && !isLoadingSub"
          (businessSelectionHandler)="handleBusinessSelectionEvent($event)"
        ></app-cm-business-selection>
      </div>
      <p *ngIf="this.showSDTMessage">
        <b>Disclaimer :</b>
        Requests for orders, cancellations, changes will not be processed via message and may only be placed through the
        Axos Self Directed Trading Platform or a licensed representative if the platform is unavailable.
      </p>
    </div>
    <app-cm-message [subCategoryData]="subCategoryData" *ngIf="isDisplayMessageComponent"></app-cm-message>
  </div>
</div>
