<div class="e-statements">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <h1>Verify Your Identity</h1>
    </div>
  </div>

  <div class="frow center error-message">
    <app-alert-notification
      class="fcol-cs-12 fcol-md-6"
      *ngIf="errorMessage"
      message="errorMessage"
      type="'error'"
      on-close="errorMessage = null"
    ></app-alert-notification>
  </div>

  <div class="container e-statements-content" id="e-statements-content">
    <!--For Multi Factor Authentication-->
    <div class="cancel-responsive">
      <span (click)="cancelSetUp()">&#10005;</span>
    </div>
    <div>
      <div class="frow center">
        <p class="fcol-md-10 text-center">{{ recipient.displayName }} requires us to verify your identity.</p>
      </div>

      <div class="frow center mfa-form">
        <form #mfaForm="ngForm" (submit)="verifyEBillLogin(credentials)">
          <div class="form-group" *ngFor="let item of credentials">
            <div *ngIf="otpOptions">
              <label class="uk-label" [textContent]="otpOptions.label"></label>
              <div class="otp-option frow" *ngFor="let opt of otpOptions.deliveryMethod">
                <input
                  type="radio"
                  value="{{ opt.id }}"
                  id="opt-{{ opt.id }}"
                  name="opt-{{ opt.id }}"
                  [(ngModel)]="item.value"
                />
                <label for="opt-{{ opt.id }}" [ngClass]="{ selected: item.value == opt.id }">
                  {{ opt.value }}
                </label>
              </div>
            </div>
            <div *ngIf="!otpOptions">
              <div *ngIf="captcha" class="form-group text-center">
                <img [src]="captchaString" class="captcha" alt="captcha" />
              </div>
              <label *ngIf="(item.label | lowercase) != 'password'" class="uk-label" [textContent]="item.label"></label>
              <input
                *ngIf="(item.label | lowercase) != 'password'"
                class="uk-input"
                type="text"
                name="{{ item.label | lowercase }}"
                [(ngModel)]="item.value"
                required
              />
              <app-reveal-input-label
                *ngIf="(item.label | lowercase) == 'password'"
                [target]="item.label | lowercase"
                [(textContent)]="item.label"
              ></app-reveal-input-label>
              <input
                *ngIf="(item.label | lowercase) == 'password'"
                class="uk-input"
                type="password"
                name="{{ item.label | lowercase }}"
                id="{{ item.label | lowercase }}"
                ng-model="item.value"
                required
              />
            </div>
          </div>
          <div class="frow center form-buttons">
            <button
              class="uk-btn primary outline lg hide-responsive"
              type="button"
              (click)="cancelSetUp()"
              [disabled]="isLoading"
            >
              Cancel Setup
            </button>
            <button
              class="uk-btn primary outline lg show-responsive"
              type="button"
              (click)="cancelSetUp()"
              [disabled]="isLoading"
            >
              Back
            </button>
            <button class="uk-btn secondary solid lg" type="submit" [disabled]="!mfaForm.valid || isLoading">
              Continue
            </button>
          </div>

          <app-dot-loader *ngIf="isLoading">It may take a while</app-dot-loader>
        </form>
      </div>
    </div>
  </div>
</div>
