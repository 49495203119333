import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { FamilyInfo } from '@app/user-profile/models';
import { getMaritalStatuses } from '@app/user-profile/store/selectors';
import { mapObjectToKeyValuePair } from '@app/utils';
import { KeyValuePair } from '@core/models';
import { UserProfileService } from '@core/services';

@Component({
  selector: 'up-change-family-info',
  templateUrl: './change-family-info.component.html',
  styleUrls: ['../../scss/user-profile.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeFamilyInfoComponent implements OnInit {
  @Input() familyInfo: Partial<FamilyInfo>;

  @Output() back = new EventEmitter<null>();
  @Output() updateFamilyInfo = new EventEmitter<FamilyInfo>();

  isBusy = false;
  changeFamilyInfoForm: UntypedFormGroup;
  maritalStatuses$: Observable<KeyValuePair<string, string>[]>;

  constructor(private fb: UntypedFormBuilder, private userProfileService: UserProfileService, private store: Store) {}

  ngOnInit(): void {
    this.maritalStatuses$ = this.store
      .select(getMaritalStatuses)
      .pipe(map(statuses => mapObjectToKeyValuePair(statuses)));

    const { maritalStatus, numberOfDependents } = this.familyInfo;

    this.changeFamilyInfoForm = this.fb.group({
      maritalStatus: this.fb.control(maritalStatus),
      numberOfDependents: this.fb.control(numberOfDependents),
    });
  }

  restrictOnlyTwoCharacters(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;

    if (/[0-9]/.test(event.key) && target.value.length === 2) {
      event.preventDefault();
    }
  }

  submitFamilyInfo(): void {
    this.isBusy = true;
    const familyInfo = new FamilyInfo(this.changeFamilyInfoForm.value);
    this.userProfileService
      .updateFamilyInfo(familyInfo)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(() => {
        this.updateFamilyInfo.emit(familyInfo);
        this.back.emit();
      });
  }
}
