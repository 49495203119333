<div class="advisorDistribution__container">
  <div class="advisorDistribution__title">Advisor Distribution Authority</div>
  <div class="establishACH-container advisorDistribution__flex-container">
    <div class="establishACH-text txt">Establish Recurring ACH</div>
    <div class="establishACH-value val">
      {{
        authorizationsTabState?.establishRecurringACH
          ? 'Yes; Last Updated: ' + getFormattedDate(authorizationsTabState?.establishRecurringACH)
          : blankFormat
      }}
    </div>
  </div>
  <div class="advisorDistribution__divider"></div>
  <div class="changeACH-container advisorDistribution__flex-container">
    <div class="changeACH-text txt">Change / Terminate Recurring ACH</div>
    <div class="changeACH-value val">
      {{
        authorizationsTabState?.changeOrTerminateRecurringACH
          ? 'Yes; Last Updated: ' + getFormattedDate(authorizationsTabState?.changeOrTerminateRecurringACH)
          : blankFormat
      }}
    </div>
  </div>
  <div class="advisorDistribution__divider"></div>
  <div class="recurringDistributionsOnFile-container advisorDistribution__flex-container">
    <div class="recurringDistributionsOnFile-text txt">Recurring Distributions on File</div>
    <div class="recurringDistributionsOnFile-value val">
      {{ getFormattedYesNo(authorizationsTabState?.recurringDistributionsOnFile) ?? blankFormat }}
    </div>
  </div>
  <div class="advisorDistribution__divider"></div>
  <div class="adjustSystematicRmdReminder-container advisorDistribution__flex-container">
    <div class="adjustSystematicRmdReminder-text txt">Adjust Systematic RMD Reminder</div>
    <div class="adjustSystematicRmdReminder-value val">
      {{ getFormattedYesNo(authorizationsTabState?.adjustSystematicRMDReminder) ?? blankFormat }}
    </div>
  </div>
</div>
