<input
  #tooltip
  class="uk-input"
  name="amount"
  type="text"
  [ngModel]="amountDisplayed"
  (ngModelChange)="setAmntValue($event)"
  autocomplete="off"
  [readonly]="amountOpt !== 2"
  (blur)="amountChange($event)"
  (keypress)="keyPress($event)"
  (click)="$event.target.select()"
/>

<div #dropdown *ngIf="recipient.eBillsStatus == 0">
  <ul class="dropdown-menu" *ngIf="recipient.eBill != null">
    <li (click)="setAmount(0)" class="option-item" [ngClass]="{ selected: amountOpt === 0 }">
      Minimum Amount:
      <span>{{ recipient.eBill.amountDue | currency }}</span>
    </li>
    <li (click)="setAmount(1)" class="option-item" [ngClass]="{ selected: amountOpt === 1 }">
      Current Balance:
      <span>{{ recipient.eBill.outstandingBalance | currency }}</span>
    </li>
    <li (click)="setAmount(2)" class="option-item" [ngClass]="{ selected: amountOpt === 2 }">
      Other Amount
    </li>
  </ul>
</div>
