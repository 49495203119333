import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import {
  AccountBannerComponent,
  AccountBeneficiariesComponent,
  InvestorCheckingAccountFeaturesComponent,
  DepositInterestComponent,
  DisputeDetailsComponent,
  DisputeReviewComponent,
  DisputeSubmitComponent,
  DownloadTransactionsComponent,
  OpenAccountItemsContainerComponent,
  RewardsComponent,
  StatementTaxFormsComponent,
  AccountGroupComponent,
  LoanPayoffIframeComponent,
  TxAggregationFiltersComponent,
  TxFilterComponent,
  InsightsContainerComponent,
  InsightsLineChartComponent,
  DashboardAccountsComponent,
  TxModalFiltersComponent,
  StopPaymentRemoveComponent,
  StopPaymentSuccessComponent,
  StopPaymentComponent,
  StopPaymentExtendComponent,
  StopPaymentAddComponent,
  DownloadFormComponent,
  PeriodOptionsComponent,
  ModalErrorComponent,
  AccountTransactionsComponent,
} from '@app/accounts/components';
import { AccountSelectComponent } from '@app/accounts/components/account-select/account-select.component';
import { AccountWarningTooltipComponent } from '@app/accounts/components/account-warning-tooltip/account-warning-tooltip.component';
import { AccountActionComponent, PaymentInfoComponent } from '@app/accounts/submodules/accounts-shared/components';
import { CardReplacementFeeComponent, DebitCardAddressComponent } from '@app/accounts/submodules/debit-card/components';
import {
  DebitCardViewComponent,
  ReportLostStolenViewComponent,
  RequestLimitChangeViewComponent,
  RequestReplacementViewComponent,
  ScheduleTravelViewComponent,
} from '@app/accounts/submodules/debit-card/views';
import { AggregateAccountDetailComponent } from '@app/accounts/submodules/external-accounts/components/aggregate-account-detail/aggregate-account-detail.component';
import { UpdateNicknameComponent } from '@app/accounts/submodules/external-accounts/components/update-nickname/update-nickname.component';
import { AggregateAccountDetailViewComponent } from '@app/accounts/submodules/external-accounts/views';
import { LoanAccountDetailsViewComponent } from '@app/accounts/submodules/loans/views';
import { HelocAccountDetailsViewComponent } from '@app/accounts/submodules/loans/views/heloc-account-details-view/heloc-account-details-view.component';
import {
  SignatureCardOptionComponent,
  SignatureCardActionsComponent,
} from '@app/accounts/submodules/signature-card/components';
import {
  InsightsChartComponent,
  TransactionDisputeFormComponent,
  TransactionTagsComponent,
} from '@app/accounts/submodules/transactions/components';
import { TransactionDetailsComponent } from '@app/accounts/submodules/transactions/components';
import { TransactionsComponent } from '@app/axos-invest/components';
import { AccountsFilterComponent } from '@app/pfm/components/accounts-filter/accounts-filter.component';
import { AssetsLiabilitiesTableComponent } from '@app/pfm/components/assets-liabilities-table/assets-liabilities-table.component';
import { CategoriesFilterComponent as PFMCategoriesFilterComponent } from '@app/pfm/components/categories-filter/categories-filter.component';
import { CategoriesGridComponent } from '@app/pfm/components/categories-grid/categories-grid.component';
import { ChartSelectionComponent } from '@app/pfm/components/chart-selection/chart-selection.component';
import { DonutChartComponent } from '@app/pfm/components/donut-chart/donut-chart.component';
import { FilterDropdownComponent } from '@app/pfm/components/filter-dropdown/filter-dropdown.component';
import { IncomeComponent } from '@app/pfm/components/income/income.component';
import { InsightsExpensesGridComponent } from '@app/pfm/components/insights-expenses-grid/insights-expenses-grid.component';
import { InsightsOverviewComponent } from '@app/pfm/components/insights-overview/insights-overview.component';
import { SpendingModuleComponent } from '@app/pfm/components/spending-module/spending-module.component';
import { SpendingComponent } from '@app/pfm/components/spending/spending.component';
import { TimePeriodFilterComponent } from '@app/pfm/components/time-period-filter/time-period-filter.component';
import { NetWorthComponent } from '@app/pfm/components/net-worth/net-worth.component';
import { NetWorthHeaderComponent } from '@app/pfm/components/net-worth-header/net-worth-header.component';
import { NetWorthModuleComponent } from '@app/pfm/components/net-worth-module/net-worth-module.component';
import { TradingBeneficiariesComponent } from '@app/trading/components';
import {
  TransactionHistoryContainerComponent,
  TransactionHistoryOptionsComponent,
} from '@app/transaction-history/components';

import { AxosInvestAccountModule } from '../axos-invest/axos-invest-account.module';
import { AxosTradingModule } from '../axos-trading/axos-trading.module';
import { beneficiariesLegacyModule, minorsLegacyModule } from '../upgraded';
import { BeneficiaryFormComponent } from '@app/beneficiaries/components';
import { Default } from '../common/filters/default.filter';
import { MeatballMenuComponent } from '@app/shared/components/meatball-menu/meatball-menu.component';
import { AccountDebitCardManagementModule } from './account-debit-cards/debit-card-management/debit-card-management.module';
import { AccountDetailsIndexController } from './account-details-index/account-details-index.controller';
import { accountDetails, accountDetailsMortgage } from './account-details/account-details.component';
import { SpousalConsentModalController } from './account-details/spousal-consent-modal/spousal-consent-modal.controller';
import { AccountRouterHelper } from './account-router-helper';
import { AccountsConfig } from './accounts.config';
import { BeneficiariesIraEnhComponent } from './beneficiaries/beneficiaries.component';

import { AccountAggDetailsIndexComponent } from './external-accounts/account-details-index/account-details-index.component';

import { ExternalAccountsHelper } from './external-accounts/external-accounts.helper';
import { TransactionsAggComponent } from './external-accounts/transactions-details/transactions-details-agg.component';
import { CategoriesFilterComponent } from './transactions/categorization/category-filter/category-filter.component';
import { DateRangeFilterComponent } from './transactions/date-range-filter/date-range-filter.component';
/*
import FiltersHelperService from './transactions/filters-helper.service';
*/
import { FiltersHelperService } from '@app/accounts/components/account-transactions/services/filters-helper.service';
import { TxFooterMultipleComponent } from './transactions/tx-table/tx-footer-multiple/tx-footer-multiple.component';
import { TxOptionsComponent } from './transactions/tx-table/tx-options/tx-options.component';
import { txRowAggDirective } from './transactions/tx-table/tx-row-agg/tx-row-agg.directive';
import { txRowDirective } from './transactions/tx-table/tx-row/tx-row.directive';
import { TxTableComponent } from './transactions/tx-table/tx-table.component';
import { TransactionHistoryService } from '@app/transaction-history/services';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { StatementsAndDocumentsPageComponent } from '@app/Areas/AAS/features/statements-and-documents/view/statements-and-documents-page/statements-and-documents-page.component';
import { TransactionsPageComponent } from '@app/Areas/AAS/features/transactions/view/transactions-page/transactions-page.component';
import { BrandingSettingsFacade } from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade } from '@app/Areas/AAS/aas-core/rias';
import { ShareAccountDetailsComponent } from '@app/Areas/AAS/features/share-account-details/view';
import { TxCheckDetailsModalComponent } from '@app/accounts/components/modals';
import { CategoryElementComponent, CategoryFilterComponent, RecategorizeComponent } from '@shared/components';
import { AuthorizeTransfersTransfersOnlyComponent } from '@app/Areas/AAS/features/authorize-transfers-transfers-only/view';
import { StopPaymentModule } from './stop-payment/stop-payment.module';
import { AccountDetailsService } from '@app/Areas/AAS/features/account-details/core';

export const accountsModule = angular
  .module('udb.accounts', [
    'ui.tree',
    'ui.bootstrap',
    StopPaymentModule,
    AccountDebitCardManagementModule,
    beneficiariesLegacyModule,
    AxosInvestAccountModule,
    minorsLegacyModule,
    AxosTradingModule,
  ])
  .controller('AccountDetailsIndexController', AccountDetailsIndexController)
  .controller('SpousalConsentModalController', SpousalConsentModalController)
  .component('udbAccountDetails', accountDetails)
  .component('udbAccountDetailsMortgage', accountDetailsMortgage)
  .component('udbTxTable', TxTableComponent)
  .component('txOptions', TxOptionsComponent)
  .component('txFooterMultiple', TxFooterMultipleComponent)
  .component('udbAccountAggDetailsIndex', AccountAggDetailsIndexComponent)
  .component('udbTransactionsAgg', TransactionsAggComponent)
  .component('udbCategoriesFilter', CategoriesFilterComponent)
  .component('udbDateRangeFilter', DateRangeFilterComponent)
  .component('udbBeneficiariesIraEnh', BeneficiariesIraEnhComponent)
  // #region Downgraded components
  .directive('appAccountTransactions', downgradeComponent({ component: AccountTransactionsComponent }))
  .directive('appTxFilter', downgradeComponent({ component: TxFilterComponent }))
  .directive('txModalFilters', downgradeComponent({ component: TxModalFiltersComponent }))
  .directive('appCategoryElement', downgradeComponent({ component: CategoryElementComponent }))
  .directive('appCategoryFilter', downgradeComponent({ component: CategoryFilterComponent }))
  .directive('appRecategorizeTransactions', downgradeComponent({ component: RecategorizeComponent }))
  .directive('appTxAggregationFilters', downgradeComponent({ component: TxAggregationFiltersComponent }))
  .directive('appTxCheckDetailsModal', downgradeComponent({ component: TxCheckDetailsModalComponent }))
  .directive('appTxModalFilters', downgradeComponent({ component: TxModalFiltersComponent }))
  .directive('beneficiaryForm', downgradeComponent({ component: BeneficiaryFormComponent }))
  .directive('appAssetsLiabilitiesTable', downgradeComponent({ component: AssetsLiabilitiesTableComponent }))
  .directive('appRequestReplacementView', downgradeComponent({ component: RequestReplacementViewComponent }))
  .directive('appReportLostStolenView', downgradeComponent({ component: ReportLostStolenViewComponent }))
  .directive('appRequestLimitChangeView', downgradeComponent({ component: RequestLimitChangeViewComponent }))
  .directive('appScheduleTravelView', downgradeComponent({ component: ScheduleTravelViewComponent }))
  .directive('dcDebitCardAddress', downgradeComponent({ component: DebitCardAddressComponent }))
  .directive('appChartSelection', downgradeComponent({ component: ChartSelectionComponent }))
  .directive('appInsightsExpensesGrid', downgradeComponent({ component: InsightsExpensesGridComponent }))
  .directive('appInsightsChart', downgradeComponent({ component: InsightsChartComponent }))
  .directive('appTransactionDetails', downgradeComponent({ component: TransactionDetailsComponent }))
  .directive('appMeatballMenu', downgradeComponent({ component: MeatballMenuComponent }))
  .directive('appAggregateAccountDetail', downgradeComponent({ component: AggregateAccountDetailComponent }))
  .directive('appUpdateNickname', downgradeComponent({ component: UpdateNicknameComponent }))
  .directive('appIncome', downgradeComponent({ component: IncomeComponent }))
  .directive('appInsightsOverview', downgradeComponent({ component: InsightsOverviewComponent }))
  .directive('appSpending', downgradeComponent({ component: SpendingComponent }))
  .directive('appNetWorth', downgradeComponent({ component: NetWorthComponent }))
  .directive('appNetWorthHeader', downgradeComponent({ component: NetWorthHeaderComponent }))
  .directive('appNetworthModule', downgradeComponent({ component: NetWorthModuleComponent }))
  .directive('appSpendingModule', downgradeComponent({ component: SpendingModuleComponent }))
  .directive('appCategoriesGrid', downgradeComponent({ component: CategoriesGridComponent }))
  .directive('appFilterDropdown', downgradeComponent({ component: FilterDropdownComponent }))
  .directive('appTimePeriodFilter', downgradeComponent({ component: TimePeriodFilterComponent }))
  .directive('appCategoriesFilter', downgradeComponent({ component: PFMCategoriesFilterComponent }))
  .directive('appAccountsFilter', downgradeComponent({ component: AccountsFilterComponent }))
  .directive('appDonutChart', downgradeComponent({ component: DonutChartComponent }))
  .directive('appTradingBeneficiaries', downgradeComponent({ component: TradingBeneficiariesComponent }))
  .directive('appAccountBeneficiaries', downgradeComponent({ component: AccountBeneficiariesComponent }))
  .directive('axosInvestTransactions', downgradeComponent({ component: TransactionsComponent }))
  .directive('appPaymentInfo', downgradeComponent({ component: PaymentInfoComponent }))
  .directive('appAccountAction', downgradeComponent({ component: AccountActionComponent }))
  .directive('appStatementTaxForms', downgradeComponent({ component: StatementTaxFormsComponent }))
  .directive('loanPayoffIframe', downgradeComponent({ component: LoanPayoffIframeComponent }))
  .directive('appDebitCardView', downgradeComponent({ component: DebitCardViewComponent }))
  .directive('appDepositInterest', downgradeComponent({ component: DepositInterestComponent }))
  .directive('appOpenAccountItemsContainer', downgradeComponent({ component: OpenAccountItemsContainerComponent }))
  .directive('appCardReplacementFee', downgradeComponent({ component: CardReplacementFeeComponent }))
  .directive('accAccountSelect', downgradeComponent({ component: AccountSelectComponent }))
  .directive('accAccountWarningTooltip', downgradeComponent({ component: AccountWarningTooltipComponent }))
  .directive('accAccountBanner', downgradeComponent({ component: AccountBannerComponent }))
  .directive('appDisputeReview', downgradeComponent({ component: DisputeReviewComponent }))
  .directive('appLoanAccountDetailsView', downgradeComponent({ component: LoanAccountDetailsViewComponent }))
  .directive('appHelocAccountDetailsView', downgradeComponent({ component: HelocAccountDetailsViewComponent }))
  .directive('appSignatureCardOption', downgradeComponent({ component: SignatureCardOptionComponent }))
  .directive('appAggregateAccountDetailView', downgradeComponent({ component: AggregateAccountDetailViewComponent }))
  .directive('appTransactionDisputeForm', downgradeComponent({ component: TransactionDisputeFormComponent }))
  .directive('appDisputeDetails', downgradeComponent({ component: DisputeDetailsComponent }))
  .directive('appTransactionTags', downgradeComponent({ component: TransactionTagsComponent }))
  .directive('appDisputeSubmit', downgradeComponent({ component: DisputeSubmitComponent }))
  .directive('appRewards', downgradeComponent({ component: RewardsComponent }))
  .directive('appStatementsAndDocumentsPage', downgradeComponent({ component: StatementsAndDocumentsPageComponent }))
  .directive('appTransactionHistoryContainer', downgradeComponent({ component: TransactionHistoryContainerComponent }))
  .directive('appTransactionHistoryOptions', downgradeComponent({ component: TransactionHistoryOptionsComponent }))
  .directive('appTransactionsPage', downgradeComponent({ component: TransactionsPageComponent }))
  .directive(
    'investorCheckingAccountFeatures',
    downgradeComponent({ component: InvestorCheckingAccountFeaturesComponent })
  )
  .directive('appAccountGroup', downgradeComponent({ component: AccountGroupComponent }))
  .directive('appInsightsContainer', downgradeComponent({ component: InsightsContainerComponent }))
  .directive('appInsightsLineChart', downgradeComponent({ component: InsightsLineChartComponent }))
  .directive('appDashboardAccounts', downgradeComponent({ component: DashboardAccountsComponent }))
  .directive('appStopPayment', downgradeComponent({ component: StopPaymentComponent }))
  .directive('appStopPaymentRemove', downgradeComponent({ component: StopPaymentRemoveComponent }))
  .directive('appStopPaymentSuccess', downgradeComponent({ component: StopPaymentSuccessComponent }))
  .directive('appShareAccountDetails', downgradeComponent({ component: ShareAccountDetailsComponent }))
  .directive('appSignatureCardActions', downgradeComponent({ component: SignatureCardActionsComponent }))
  .directive('appStopPaymentExtend', downgradeComponent({ component: StopPaymentExtendComponent }))
  .directive('appStopPaymentAdd', downgradeComponent({ component: StopPaymentAddComponent }))
  .directive(
    'appAuthorizeTransfersTransfersOnly',
    downgradeComponent({ component: AuthorizeTransfersTransfersOnlyComponent })
  )
  .directive('appDownloadTransactions', downgradeComponent({ component: DownloadTransactionsComponent }))
  .directive('appDownloadForm', downgradeComponent({ component: DownloadFormComponent }))
  .directive('periodOptions', downgradeComponent({ component: PeriodOptionsComponent }))
  .directive('modalError', downgradeComponent({ component: ModalErrorComponent }))

  // #endregion Downgraded components

  // #region Downgraded injectables
  .service('transactionHistoryService', downgradeInjectable(TransactionHistoryService))
  .service('pdpFacade', downgradeInjectable(PdpFacade))
  .service('brandingSettingsFacade', downgradeInjectable(BrandingSettingsFacade))
  .service('riaFacade', downgradeInjectable(RiaFacade))
  .service('axosInvestHelper', downgradeInjectable(AxosInvestHelper))
  .service('accountDetailsService', downgradeInjectable(AccountDetailsService))
  // #endregion Downgraded injectables

  .directive('txRow', txRowDirective)
  .directive('txRowAgg', txRowAggDirective)
  .service('externalAccountsHelper', ExternalAccountsHelper)
  .service('accountRouterHelper', AccountRouterHelper)
  .service('filtersHelperService', downgradeInjectable(FiltersHelperService))
  .filter('default', () => Default.factory())
  .config(AccountsConfig).name;
