<div class="debit-card-options">
  <a
    *ngFor="let option of options"
    class="debit-card-options__item"
    [ngClass]="option.active ? 'link-color' : 'is-disabled'"
    (click)="navigate.emit(option.route)"
    href="#"
  >
    <app-svg-icon [iconName]="option.icon" [size]="iconConfig.size"></app-svg-icon>
    <span class="debit-card-options__label" [innerText]="option.label"></span>
    <app-svg-icon
      [iconName]="iconConfig.chevron.icon"
      [size]="iconConfig.chevron.size"
    ></app-svg-icon>
  </a>

  <a
    *ngIf="!isAtmCard"
    class="debit-card-options__item limit-change"
    [ngClass]="card.allowChangeLimits ? 'link-color' : 'is-disabled'"
    (click)="navigate.emit('udb.accounts.debit-cards.limits')"
    href="#"
  >
    <app-svg-icon [iconName]="iconConfig.coinDollar" [size]="iconConfig.size"></app-svg-icon>
    <span class="debit-card-options__label">Request Limit Change</span>
    <app-svg-icon
      [iconName]="iconConfig.chevron.icon"
      [size]="iconConfig.chevron.size"
    ></app-svg-icon>
  </a>

  <a
    class="debit-card-options__item schedule-travel"
    [ngClass]="{
      'link-color': allowTravel && card.allowTravelNotification,
      'primary-color': !allowTravel,
      'is-disabled': !card.allowTravelNotification
    }"
    (click)="validateTravel('udb.accounts.debit-cards.schedule-travel')"
    href="#"
  >
    <app-svg-icon [iconName]="iconConfig.paperAirplane" [size]="iconConfig.size"></app-svg-icon>
    <span class="debit-card-options__label">Schedule Travel</span>
    <app-svg-icon
      [iconName]="iconConfig.chevron.icon"
      [size]="iconConfig.chevron.size"
    ></app-svg-icon>
  </a>

  <div class="travel-notification">
    <div *ngFor="let notification of notifications">
      <div>
        <button
          type="button"
          class="travel-notification__delete"
          (click)="deleteNotification.emit(notification)"
        >
          <app-svg-icon
            [iconName]="iconConfig.trash.icon"
            [size]="iconConfig.trash.size"
            role="button"
          ></app-svg-icon>
        </button>
        <span class="travel-notification__description">Destination(s)/Comments:</span>
      </div>
      <p class="travel-notification__comments" [innerText]="notification.comments"></p>
      <p class="travel-notification__date">
        <span>Date:</span>
        {{ notification.startDate | date: 'MM/dd/yyyy' }} -
        {{ notification.endDate | date: 'MM/dd/yyyy' }}
      </p>
    </div>
  </div>
</div>
