<h3 class="mt-5 mb-4">How do I stop email and/or text alerts?</h3>
<ol>
  <li>
    click on
    <b>Alerts</b>
    at the top right of the screen.
  </li>
  <li>Click on the alert that you would like to remove.</li>
  <li>
    Click on the trash icon in order to remove a particular alert that you no longer want to
    receive.
  </li>
</ol>
