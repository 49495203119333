<div class="schedule-travel" *ngIf="!loading">
  <app-view-header titleText="Travel Notification">
    <p class="text-center">Debit Card {{ cardMask }}</p>
  </app-view-header>
  <div class="schedule-travel__content p-2 px-md-5">
    <h3 class="text-center mb-4">
      Please complete a travel notification for the card you will be using during your travel. You can enter one or more
      destinations.
    </h3>
    <form [formGroup]="scheduleTravelForm" (ngSubmit)="scheduleTravel()" class="py-3" *ngIf="!loading">
      <app-dot-loader [showWhen]="loading"></app-dot-loader>
      <div class="schedule-travel__form-group mb-2" [class.invalid-field]="scheduleComments.hasError('*')">
        <div class="col-12">
          <label for="comments" class="uk-label">Enter Destinations/Comments</label>
          <textarea
            name="comments"
            id="comments"
            class="uk-input"
            rows="5"
            maxlength="130"
            formControlName="comments"
            #comments
            [attr.disabled]="overLimit ? true : null"
          ></textarea>
        </div>

        <div class="col-12 col-md-10">
          <div aria-live="polite" role="alert" class="text-error" ngxErrors="comments" #scheduleComments="ngxErrors">
            <span ngxError="required" when="touched" [innerText]="errors.comments"></span>
            <span ngxError="minlength" when="touched" [innerText]="errors.comments"></span>
          </div>
        </div>
        <div class="col-12 col-md-2 text-right">
          <span>{{ comments.value.length }}/130</span>
        </div>
      </div>

      <div class="schedule-travel__form-group mb-2 mt-0 mt-sm-4">
        <div class="col-12 col-md-6">
          <label class="uk-label d-flex align-items-center" for="startDate">
            <button class="date-icon" type="button" (click)="startDatePicker.open()">
              <app-svg-icon [iconName]="iconConfig.calendar.icon" [size]="iconConfig.calendar.size"></app-svg-icon>
            </button>
            <span class="ml-1">Start Date</span>
          </label>
          <input
            [matDatepicker]="startDatePicker"
            [min]="startDate.minDate"
            [max]="startDate.maxDate"
            (click)="startDatePicker.open()"
            type="text"
            name="startDate"
            id="startDate"
            aria-label="startDate"
            class="uk-input date-input"
            readonly
            required
            formControlName="startDate"
            (dateChange)="startDateChange($event)"
            [attr.disabled]="overLimit ? true : null"
          />
          <mat-datepicker #startDatePicker></mat-datepicker>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0">
          <label class="uk-label d-flex align-items-center" for="endDate">
            <button type="button" class="date-icon" (click)="endDatePicker.open()">
              <app-svg-icon [iconName]="iconConfig.calendar.icon" [size]="iconConfig.calendar.size"></app-svg-icon>
            </button>
            <span class="ml-1">End Date</span>
          </label>
          <input
            [matDatepicker]="endDatePicker"
            (click)="endDatePicker.open()"
            [min]="endDate.minDate"
            [max]="endDate.maxDate"
            formControlName="endDate"
            type="text"
            name="endDate"
            id="endDate"
            aria-label="endDate"
            class="uk-input date-input"
            readonly
            required
            [attr.disabled]="overLimit ? true : null"
          />
          <mat-datepicker #endDatePicker></mat-datepicker>
        </div>
      </div>

      <div class="form__actions mt-4" *ngIf="!busy">
        <button type="submit" class="btn-ok button--lg ng-star-inserted" [attr.disabled]="overLimit ? true : null">
          Submit
        </button>
        <button (click)="cancelScheduleTravel()" type="button" class="btn-cancel button--lg ng-star-inserted">
          Cancel
        </button>
      </div>
      <div class="text-center" *ngIf="busy">
        <app-dot-loader></app-dot-loader>
      </div>
    </form>
  </div>
</div>
