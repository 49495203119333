import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { ScheduledTransfersRequest, ScheduledTransfersResponse } from './types';
import { WebApiResponse } from '@shared/models';

@Injectable()
export class ScheduledTransfersService extends BaseService {
  /**
   * ScheduledTransfersService constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/transactions/scheduledTransfers');
  }

  /**
   * Returns the scheduled transfers from an account.
   *
   * @param request ScheduledTransfersRequest with an Account number
   * @returns WebApiResponse of ScheduledTransfersResponse
   *
   */
  getScheduledTransfers(request: ScheduledTransfersRequest): WebApiResponse<ScheduledTransfersResponse[]> {
    return this.get(`${request.accountNumber}`);
  }
}
