import { UserAction, UserActionField } from 'typings/app/UserAction';

const eventName = 'LogSignatureCardAction';
const fieldName = 'Customer Selected';

export const SignatureCardAction = {
  RemindMeLater: new UserAction(eventName, [
    new UserActionField(fieldName, null, 'Remind me later'),
  ]),
  CustomerMailSigcard: new UserAction(eventName, [
    new UserActionField(fieldName, null, 'Customer will mail the SigCard'),
  ]),
};
