import { IStateService } from 'angular-ui-router';
import { BillPayHelper } from 'bill-pay/bill-pay.helper';
import { NextPaymentResponse } from 'bill-pay/typings/NextPaymentResponse';
import { Recipient } from 'bill-pay/typings/Recipient';
import { ScheduledPayment } from 'bill-pay/typings/ScheduledPayment';
import * as moment from 'moment';

import { Inject } from '../../../decorators/decorators';
import { BillPayRouterHelper } from '../../bill-pay-router.helper';

@Inject('$state', 'billPayRouterHelper', 'loadUserProfileHelper', 'billPayHelper')
export class UpdatedPaymentConfirmation {
  payment: NextPaymentResponse;
  detailsTooltip: string;
  email: string;
  scheduled: ScheduledPayment;
  frequency: string;
  reminder: string;
  stopOnNumberOfPayments: boolean;
  stopSeriesText: string;
  numberOfPayments: number;
  rescheduledDay: string;
  recipient: Recipient;
  isCheck: boolean;

  constructor(
    private readonly state: IStateService,
    private readonly billPayRouterHelper: BillPayRouterHelper,
    private readonly loadUserProfileHelper: ILoadUserProfileHelper,
    private readonly billpayHelper: BillPayHelper
  ) {}
  $onInit(): void {
    this.payment = this.billPayRouterHelper.getNextPayment();
    this.scheduled = this.billPayRouterHelper.getScheduledPayment();
    this.recipient = this.billPayRouterHelper.getRecipientDetails();
    const seriesFrequency = this.billPayRouterHelper.getPaymentFrequency();

    if (
      this.payment === null ||
      this.scheduled === null ||
      this.scheduled === undefined ||
      this.payment === undefined
    ) {
      this.state.go('udb.billPay.pay');
    }

    this.isCheck = this.recipient.paymentMethod !== 1;
    const profile = this.loadUserProfileHelper.getUserProfile();
    this.email = profile ? profile.primaryEmail : '';
    this.frequency = this.billpayHelper.mapFrequency(this.payment.paymentFrequency);
    this.reminder = this.scheduled.alert.daysBeforeProcessingSpecified
      ? this._getReminderText(this.scheduled.alert.daysBeforeProcessing)
      : this._getReminderText(0);
    this.getNumberOfPayments();
    if (this.payment.notifySeries) {
      if (seriesFrequency < 5) {
        this.rescheduledDay = this.billpayHelper.getWeekDay(moment(this.payment.notifyNewDate));
      } else {
        this.rescheduledDay =
          'the ' +
          this.buildStringNumber(moment(this.payment.notifyNewDate).format('D')) +
          ' of the month';
      }
    }
  }

  private getNumberOfPayments(): void {
    this.stopOnNumberOfPayments = this.payment.items.length !== 1;
    this.stopSeriesText = this.stopOnNumberOfPayments ? 'A Number of Payments' : 'I Choose to Stop';
    this.numberOfPayments = this.stopOnNumberOfPayments ? this.payment.items[0] : 0;
  }

  private _getReminderText(reminderDays: number): string {
    const dict = {
      [1]: '1 Day Before Payment is Sent',
      [2]: '2 Days Before Payment is Sent',
      [3]: '3 Days Before Payment is Sent',
      [4]: '4 Days Before Payment is Sent',
      [5]: '5 Days Before Payment is Sent',
      [7]: '1 Week Before Payment is Sent',
      [0]: 'Do Not Send',
    };

    return dict[reminderDays] ?? dict[0];
  }
  private buildStringNumber(number: string): string {
    switch (number) {
      case '1':
        return number + 'st';
      case '2':
        return number + 'nd';
      case '3':
        return number + 'rd';
      default:
        return number + 'th';
    }
  }
}
