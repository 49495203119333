<div id="goal-info" class="fcol-sm-12">
  <div class="frow center axos-info-container">
    <div class="row-flex frow center goal-info">
      <ng-template #loader>
        <app-dot-loader class="info-loader">
          Loading Account Details...
        </app-dot-loader>
      </ng-template>
      <ng-container *ngIf="$goal | async as goal; else loader">
        <div>
          <h1 class="goal-type">{{ goal.name }}</h1>
        </div>
        <div class="axos-invest-header-separator"></div>
        <div class="row-pad">
          <span class="total-value">{{ goal.currentValue | currency }}</span>
          <span class="total-value-text">
            <p>
              Total Value
            </p>
            <app-tooltip
              [white]="true"
              content="The combined value of all investments assigned to this goal based on stock prices obtained throughout the day."
            ></app-tooltip>
          </span>
        </div>
      </ng-container>
    </div>

    <div class="switch-tabs">
      <a class="goal-summary-tab" [ngClass]="indexTabActive == 0 ? 'active' : ''" (click)="changeTab(0)">
        Goal Summary
      </a>
      <a class="goal-projection-tab" [ngClass]="indexTabActive == 1 ? 'active' : ''" (click)="changeTab(1)">
        Goal Projection
      </a>
      <a class="goal-portfolio-tab" [ngClass]="indexTabActive == 2 ? 'active' : ''" (click)="changeTab(2)">
        Portfolio
      </a>
      <a class="goal-transactions-tab" [ngClass]="indexTabActive == 3 ? 'active' : ''" (click)="changeTab(3)">
        Transactions
      </a>
    </div>
  </div>
</div>
<ng-container *ngIf="$goal | async as goal">
  <app-modify-confirmation-banner
    *ngIf="showModifiedBanner"
    [bannerText]="modifiedBannerText"
    (dismissBannerEvent)="dismissBannerEvent($event)"
  ></app-modify-confirmation-banner>
  <app-error-banner [bannerText]="errorPrompt" *ngIf="displayErrorBanner"></app-error-banner>
  <div class="goal-content view-container">
    <div class="frow mt-4 overview">
      <button type="button" class="link" (click)="goBackMpOverview()">
        <app-svg-icon class="overview__icon" [iconName]="backIcon" size="1.3em"></app-svg-icon>
        <span class="overview__text">Managed Portfolios Overview</span>
      </button>
    </div>
    <app-goal-summary
      class="goal-summary"
      *ngIf="indexTabActive == 0"
      [milestoneData]="goal"
      (changeTab)="changeTab(3)"
    ></app-goal-summary>
    <app-goal-projection
      class="goal-projection"
      *ngIf="indexTabActive == 1"
      [milestoneData]="goal"
    ></app-goal-projection>
    <app-goal-portfolio class="goal-portfolio" *ngIf="indexTabActive == 2" [goalData]="goal"></app-goal-portfolio>
    <app-goal-transactions class="goal-transactions" *ngIf="indexTabActive == 3"></app-goal-transactions>
  </div>
</ng-container>
