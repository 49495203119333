<div class="dialog-backdrop d-flex align-items-center justify-content-center">
  <div class="dialog-box">
    <div class="d-flex">
      <div class="dialog-close ml-auto d-flex align-items-center" (click)="closeSpeedBump()">
        <span>CLOSE</span>
        <button>
          <app-svg-icon
            [iconName]="closeIcon"
            [styles]="{ width: '1em', height: '1em' }"
          ></app-svg-icon>
        </button>
      </div>
    </div>
    <div class="dialog-body px-4 py-2 d-flex flex-column">
      <app-svg-icon
        class="align-self-center"
        [iconName]="bellIcon"
        [styles]="{ width: '1.6em', height: '1.6em' }"
      ></app-svg-icon>
      <h3 class="mb-2">You're continuing to another website</h3>
      <p>
        Please be aware that {{ this.brandName }} does not endorse the information, content,
        presentation or accuracy of any other website and claims no responsibility for it.
      </p>
      <div class="py-2 center">
        <p>You will now be redirected to:</p>
        <p>{{ this.otherUrl || this.displayUrl }}</p>
      </div>
    </div>
    <div class="dialog-footer p-1 d-flex flex-column align-items-center">
      <button class="dialog-ok" (click)="onProceed()">Proceed</button>
      <button class="dialog-cancel" (click)="closeSpeedBump()">Cancel</button>
    </div>
  </div>
</div>
