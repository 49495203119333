/** Represents a security question with an ID, security question string, and answer. */
export class SecurityQuestion {
  /** Unique identifier for the security question. */
  questionId = '';
  /** The question text. */
  questionText: string;
  /** The answer for the security question. */
  answer?: string;

  constructor(data?: Partial<SecurityQuestion>) {
    Object.assign(this, data);
  }
}