import { Component, EventEmitter, Input } from '@angular/core';

import { Transaction } from '@app/axos-invest/models';

@Component({
  selector: 'app-recent-transactions',
  templateUrl: './recent-transactions.component.html',
  styleUrls: ['./recent-transactions.component.scss'],
})
export class RecentTransactionsComponent {
  @Input() transactions: Transaction[];

  unselectEvent = new EventEmitter<string>();

  onSelectRow(transactionUuid: string) {
    this.unselectEvent.emit(transactionUuid);
  }
}
