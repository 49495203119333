<div class="row">
  <button id="backToOverviewBtn" type="button" class="link d-flex align-items-center" (click)="goBackToStock()">
    <app-svg-icon class="overview__icon mr-2" [iconName]="icons.backIcon" size="1.3em"></app-svg-icon>
    <span class="overview__text">Back to {{ stock.stockName }}</span>
  </button>
</div>
<div class="row mt-4">
  <div class="col-6 mr-2 p-4 trade-form">
    <div class="form-title">{{ tradeType }} Order</div>
    <form novalidate [formGroup]="form" class="mt-4" (ngSubmit)="submitMutual()">
      <div class="form-group" id="mutualSell" *ngIf="tradeType === tradeTypes.Sell">
        <label
          [class.insufficient]="
            form.get('quantity').hasError('insufficient') ||
            ((form.get('quantity').errors?.required || form.get('quantity').errors?.min) && this.submitted)
          "
          for="quantity"
          class="uk-label amount-shares"
        >
          Amount of Shares
        </label>
        <input
          class="uk-input"
          type="number"
          min="0"
          name="quantity"
          id="quantity"
          formControlName="quantity"
          appNumeric
          [class.insufficient-input]="
            form.get('quantity').hasError('insufficient') ||
            ((form.get('quantity').errors?.required || form.get('quantity').errors?.min) && this.submitted)
          "
        />
        <div class="text--error quantity-fund" *ngIf="form.get('quantity').hasError('insufficient')">
          <span>Number entered cannot be greater than currently owned</span>
        </div>
        <div
          class="text--error"
          *ngIf="(form.get('quantity').errors?.required || form.get('quantity').errors?.min) && this.submitted"
        >
          Please complete this field
        </div>
      </div>
      <div class="form-group" id="mutualBuy" *ngIf="tradeType === tradeTypes.Buy">
        <label
          for="value"
          class="uk-label total-price"
          [class.insufficient]="
            form.get('value').hasError('insufficient') ||
            ((form.get('value').errors?.required || form.get('value').errors?.min) && this.submitted)
          "
        >
          Total Buy Price
        </label>
        <input
          id="value"
          type="text"
          class="uk-input"
          prefix="$ "
          mask="separator.2"
          thousandSeparator=","
          separatorLimit="10000000"
          [dropSpecialCharacters]="[',']"
          [allowNegativeNumbers]="false"
          [formControl]="form.get('value')"
          autocomplete="off"
          [class.insufficient-input]="
            form.get('value').hasError('insufficient') ||
            ((form.get('value').errors?.required || form.get('value').errors?.min) && this.submitted)
          "
        />
        <div class="text--error error-value-fund" *ngIf="form.get('value').hasError('insufficient')">
          <span>Not enough available funds</span>
        </div>
        <div
          class="text--error"
          *ngIf="(form.get('value').errors?.required || form.get('value').errors?.min) && this.submitted"
        >
          Please complete this field
        </div>
      </div>
      <div class="button-container mt-4 mb-4">
        <button class="btn-ok button--lg" type="submit" id="submitBtn" [disabled]="isValidMutualForm">Continue</button>
      </div>
    </form>
  </div>
  <div class="col-5 ml-4 stock-information">
    <div class="row">
      <div class="col-12 mt-4">
        <div class="stock-symbol stock-information-label">{{ stock.symbol }}</div>
        <div class="stock-name stock-information-value">{{ stock.stockName }}</div>
      </div>
      <div class="col-12 mt-4">
        <div class="stock-current-value-label stock-information-label" *ngIf="tradeType === tradeTypes.Buy">
          Current Price
        </div>
        <div class="stock-current-value-label stock-information-label" *ngIf="tradeType === tradeTypes.Sell">
          Current Market
        </div>
        <div class="stock-current-value stock-information-value">{{ stock.marketValue | currency }}</div>
      </div>
      <div
        class="col-12 mt-4"
        [class.insufficient]="
          form.get('quantity').hasError('insufficient') || form.get('value').hasError('insufficient')
        "
      >
        <div class="stock-buying-power-label stock-information-label" *ngIf="tradeType === tradeTypes.Sell">
          Cash Value
        </div>
        <div class="stock-buying-power-label stock-information-label" *ngIf="tradeType === tradeTypes.Buy">
          Buying Power
        </div>
        <div class="stock-buying-power stock-information-value">
          {{ portfolioOverview.buyingPower | currency }}
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loader><app-dot-loader></app-dot-loader></ng-template>
