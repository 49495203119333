import * as angular from 'angular';
import { IScope, IRootElementService, IRootScopeService, IFilterService, ITimeoutService } from 'angular';
import { TileInformation } from '@uikit/tiles';
import * as moment from 'moment';
import { TilesService } from 'services/tiles.service';

import { Inject } from '../../decorators/decorators';
import { BaseTile, TileSetting, BaseTileSettings } from '../base-tile';
import { ITransactionService } from '../../services/typings/ITransactionService';
import { ColorPaletteHelper } from '../../services/color-palette.helper';

@Inject(
  '$scope',
  '$element',
  'serviceHelper',
  'tilesService',
  '$rootScope',
  'popups',
  '$filter',
  'transactionService',
  '$timeout',
  'env'
)
export class AccountTrendsController extends BaseTile<AccountTrendsTileSettings> {
  showPlaceholder: boolean = false;
  title: string = 'Account Trends';
  accounts: ColoredAccount[] = [];
  settings: number[] = [];
  colors: string[];
  totalBalance: number = 0;
  hasErrored: boolean;
  isBusy: boolean = true;
  placeholderImg = 'assets/trends-placeholder.svg';
  placeholderText: string = '';

  private chartAccounts: ColoredAccount[] = [];
  private chart: Chart;
  private chartCanvas: CanvasRenderingContext2D;
  private monthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  private months: string[] = [];
  private listeners: Function[] = [];

  constructor(
    scope: IScope,
    elm: IRootElementService,
    serviceHelper: IServiceHelper,
    tilesService: TilesService,
    private rootScope: IRootScopeService,
    private popups: IPopups,
    private filter: IFilterService,
    private transactionService: ITransactionService,
    private timeout: ITimeoutService,
    private readonly env: OlbSettings
  ) {
    super(scope, elm, tilesService, serviceHelper);
    Chart.defaults.global.maintainAspectRatio = false;
    this.colors = ColorPaletteHelper.getColorPaletteByBrand(this.env.brand);
  }

  /** This tile is initialized here since it needs transclusion to be finished, first. */
  $postLink(): void {
    const elm = this.elm.find('.account-trends-chart')[0] as HTMLCanvasElement;
    if (elm) {
      this.chartCanvas = elm.getContext('2d');
    }

    if (this.rootScope['accounts'] && this.rootScope['balancesAvailable']) {
      this.getSettings(this.setupTile.bind(this));
    }

    this.listeners.push(
      this.scope.$on('balancesAvailable', () => {
        this.getSettings(this.setupTile.bind(this));
      })
    );

    // Refresh the tile after a quick transer
    this.listeners.push(
      this.rootScope.$on('transferCompleted', () => {
        this.updateChart();
      })
    );
  }

  /** Cleans up the controller. */
  $onDestroy(): void {
    this.chart && this.chart.destroy();
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /**
   * Toggles account's selection in settings section.
   * @param id ID of the account to toggle.
   */
  toggleSelection(id: number): void {
    if (this.settings.some(s => s === +id)) {
      this.settings = this.settings.filter(s => s !== +id);
    } else {
      this.settings.push(id);
    }
  }

  /** Saves the settings of the tile. */
  saveTileSettings(): void {
    const accounts: number[] = [];

    if (!this.settings.length) {
      this.popups.showAlert(
        'Account Trends Tile',
        'At least 1 account should be selected for the chart.',
        'error',
        this.resetSettings.bind(this)
      );
      return;
    }

    if (this.settings.length > 3) {
      this.popups.showAlert(
        'Account Trends Tile',
        'Up to 3 accounts should be selected for the chart.',
        'error',
        this.resetSettings.bind(this)
      );
      return;
    }

    this.settings.forEach((id, index) => {
      accounts[index] = id;
    });
    // Gets only the accounts and colors that should be saved
    const accountsInSettings = angular.copy(this.accounts).filter(a => {
      return accounts.indexOf(a.id) > -1;
    });
    const colors = angular.copy(accountsInSettings.map(a => a.color));

    // To preserve the order that belongs to every account, we take the accountsInSettings array
    this.tileSettings.Accounts.value = accountsInSettings.map(a => a.id).join();
    this.tileSettings.Colors.value = colors.join();
    this.saveSettings();
    this.updateChart();
  }

  /** Resets the tile settings to its normal/previous state. */
  resetSettings(): void {
    if (this.tileSettings.Accounts.value) {
      this.settings = angular
        .copy(this.tileSettings.Accounts.value)
        .split(',')
        .map(s => +s);
    } else {
      this.settings = [];
      for (let i = 0; i < this.accounts.length; i++) {
        this.settings.push(this.accounts[i].id);
        if (i === 2) break;
      }
    }
    this.attachColors(this.tileSettings.Colors.value);
  }

  /**
   * Picks the selected color to attach it to the account it belongs.
   * @param color Color in HEX string.
   */
  pickColor(color: string, accountId: number): void {
    const account = this.accounts.filter(a => a.id === accountId)[0];
    account.color = color;
    this.enableScroll();
  }

  /**
   * Closes the other opened colorpickers.
   */
  closeOtherPickers(): void {
    this.scope.$broadcast('$closeOtherPickers');
  }

  /** Sets the tile up with its settings. */
  private setupTile(): void {
    this.accounts = angular.copy(this.rootScope['accounts'].depositAccounts);
    this.validateExistingAccounts();
    this.resetSettings();
    this.listeners.push(
      this.scope.$on('$adTileStopScroll', e => {
        e.stopPropagation();
        this.elm.find('tile-settings').css('overflow-y', 'hidden');
      })
    );
    this.listeners.push(this.scope.$on('$adTileEnableScroll', this.enableScroll.bind(this)));
    this.calculateMonths();

    this.getBalances(false);
  }

  /**
   * Re-enables the scroll for the settings.
   * @param e Angular event passed from the scope.
   */
  private enableScroll(e?: ng.IAngularEvent): void {
    if (e) e.stopPropagation();
    this.elm.find('tile-settings').css('overflow-y', 'auto');
  }

  /**
   * Attaches a color to the account.
   * @param colors Colors to attach to the accounts.
   */
  private attachColors(colorsString?: string): void {
    if (!colorsString || !this.tileSettings.Accounts.value) {
      let i = 0;
      this.accounts.forEach(account => {
        if (i === this.colors.length) i = 0;
        account.color = this.colors[i];
        i++;
      });
      return;
    }

    const colors = colorsString.split(',');
    const unassignedColors = this.colors.filter(c => colors.indexOf(c) < 0);

    let index = 0;
    this.accounts.forEach(account => {
      const accountsIds = this.tileSettings.Accounts.value.split(',');
      const idx = accountsIds.indexOf(`${account.id}`);
      if (idx > -1) {
        account.color = colors[idx];
        return;
      }

      if (index === unassignedColors.length) index = 0;
      account.color = unassignedColors[index];
      index++;
    });
  }

  /** Validates if the accounts in the settings remains the same as in the last update otherwise they're removed from the settings*/
  private validateExistingAccounts(): void {
    if (!this.accounts || this.accounts.length <= 0) {
      var tileInfo: TileInformation = {
        tileId: this.tileId,
        instanceId: this.instanceId,
        template: '',
      };
      this.scope.$emit('$uikitTileRemove', tileInfo);
    }

    if (!this.tileSettings.Accounts.value) return;

    const accountsInSettings = angular.copy(this.tileSettings.Accounts.value).split(',');
    const colorsInSettings = angular.copy(this.tileSettings.Colors.value).split(',');
    const newAccounts: number[] = [];
    const newColors: string[] = [];
    const sett = this.accounts.map(a => a.id);

    accountsInSettings.forEach((a, idx) => {
      if (sett.indexOf(+a) > -1) {
        newAccounts.push(+a);
        newColors.push(colorsInSettings[idx]);
      }
    });
    this.tileSettings.Accounts.value = newAccounts.join();
    this.tileSettings.Colors.value = newColors.join();
    if (newAccounts.length > 0 && newColors.length > 0) {
      this.saveSettings();
    }
  }

  /**
   * Sets up the chart after loading all the needed component data.
   * @param balances
   */
  private setupChart(balances: number[][]): void {
    if (!this.chart) {
      const elm = this.elm.find('.account-trends-chart')[0] as HTMLCanvasElement;
      this.chartCanvas = elm.getContext('2d');
    }

    this.chart = new Chart(this.chartCanvas, {
      type: 'line',
      data: this.getChartData(balances),
      options: {
        layout: {
          padding: {
            top: 10,
          },
        },
        responsive: false,
        stacked: false,
        hover: {
          mode: 'point',
        },
        events: ['click'],
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: 'rgba(255,255,255,0.8)',
          titleFontFamily: "'Libre Franklin', 'Helvetica', sans-serif",
          titleFontColor: '#6C7780',
          bodyFontFamily: "'Libre Franklin', 'Helvetica', sans-serif",
          bodyFontSize: 18,
          bodyFontColor: '#000',
          bodySpacing: 5,
          displayColors: false,
          callbacks: {
            title: (tooltip: Array<TooltipItem>, data: any): string => {
              return data.datasets[tooltip[0].datasetIndex].label;
            },
            label: (tooltip: TooltipItem, data: any): string => {
              return this.filter('currency')(data.datasets[tooltip.datasetIndex].data[tooltip.index]);
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                callback: (value: string): string => {
                  return this.filter('currency')(+value);
                },
              },
            },
          ],
        },
      },
    });
  }

  /**
   * Sets the chart data object up.
   * @returns Chart data object with all needed values.
   */
  private getChartData(balances: Array<Array<number>>): ChartData<any> {
    const datasets = new Array<any>();

    this.chartAccounts
      .sort((a, b) => {
        const x = a.accountNumber.toLowerCase();
        const y = b.accountNumber.toLowerCase();
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      })
      .forEach((chartAccount: ColoredAccount, index: number) => {
        const dataset = {
          label: chartAccount.nickname || chartAccount.name,
          borderColor: chartAccount.color,
          lineTension: 0,
          fill: false,
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          pointRadius: [0, 5, 5, 5, 5, 5],
          pointHoverBackgroundColor: chartAccount.color,
          pointHoverRadius: [0, 5, 5, 5, 5, 5],
          data: balances[index],
        };

        datasets.push(dataset);
        this.totalBalance += chartAccount.availableBalance;
      });

    const data: ChartData<any> = {
      labels: this.months,
      datasets: datasets,
    };

    return data;
  }

  /**
   * Updates the chart with new data.
   */
  private updateChart(): void {
    this.accounts = angular.copy(this.rootScope['accounts'].depositAccounts);
    this.attachColors(this.tileSettings.Colors.value);
    this.getBalances(true);
  }

  /**
   * Get the monthly balances for the selected accounts.
   * @param updateData Flag that indicates if is the first time
   * the graph is set up or is an update of the data.
   */
  private getBalances(updateData: boolean): void {
    this.isBusy = true;
    this.totalBalance = 0;
    this.showPlaceholder = false;
    this.placeholderText = '';

    this.chartAccounts = this.accounts.filter(a => this.settings.some(s => a.id === s));
    const accountIds = this.chartAccounts.map(x => x.id);

    this.transactionService
      .getBalances(accountIds)
      .then(res => {
        if (updateData) this.chart.destroy();

        if (this.balancesAreZero(res.data)) {
          this.showPlaceholder = true;
          this.placeholderText = 'See how your accounts are doing over time.';
        }
        this.timeout(() => {
          this.setupChart(res.data);
          this.chart.resize();
        });
      })
      .catch(_ => {
        this.hasErrored = true;
      })
      .finally(() => {
        this.isInitialising = false;
        this.isBusy = false;
      });
  }

  /**
   * Set the months to show in the graph
   */
  private calculateMonths(): void {
    let current = moment().subtract(5, 'month');

    for (let i = 0; i < 6; i++) {
      this.months.push(this.monthNames[current.get('month')]);
      current = current.add(1, 'month');
    }
  }

  /**
   * Checks if all the accounts have balances of 0.
   * This is a scenario for new accounts that haven't been funded yet.
   * @param accounts Balances of the current accounts in settings.
   * @returns True if all balances are 0.
   */
  private balancesAreZero(accounts: number[][]): boolean {
    const totalZero = (balance: number) => !balance;

    return this.checkBalancesAre(totalZero, accounts);
  }

  /**
   * Checks that all the balances in all accounts in settings meet the criteria
   * of the given predicate.
   * @param predicate Function that will determine what to assert with the blaances.
   * @param accounts Balances of the current accounts in settings.
   * @returns True if the predicate applies to all balances in all accounts.
   */
  private checkBalancesAre(predicate: (balance: number) => boolean, accounts: number[][]): boolean {
    return accounts.map(balances => balances.every(predicate)).reduce((prev, next) => prev && next, true);
  }
}

interface AccountTrendsTileSettings extends BaseTileSettings {
  Accounts?: TileSetting;
  Colors?: TileSetting;
}

interface ColoredAccount extends OlbAccount {
  color: string;
}
