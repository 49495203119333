import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SubSink } from '@axos/subsink';

import { resetExcludedCategoriesFilter, setExcludedCategoriesFilter } from '@app/pfm/store/spending/spending.actions';

import { categories, Category } from '../../models/category';
@Component({
  selector: 'app-categories-filter',
  templateUrl: './categories-filter.component.html',
  styleUrls: ['./categories-filter.component.scss'],
})
export class CategoriesFilterComponent implements OnInit, OnDestroy {
  @Input() filter$: Observable<Set<number>>;
  excludedCategories: Set<number>;
  subs = new SubSink();
  categories = categories;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.sortCategoriesByName();
    this.subs.sink = this.filter$.subscribe(item => (this.excludedCategories = new Set(item)));
  }
  selectedCategory(category: Category) {
    if (this.excludedCategories.has(category.id)) this.excludedCategories.delete(category.id);
    else this.excludedCategories.add(category.id);

    this.store.dispatch(setExcludedCategoriesFilter({ payload: this.excludedCategories }));
  }
  sortCategoriesByName() {
    this.categories.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
  reset() {
    this.store.dispatch(resetExcludedCategoriesFilter());
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
