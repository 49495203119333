<td colspan="5">
  <div class="row pt-4 pb-4">
    <div class="col-12 col-sm-3 col-md-3 col-lg-2 text-left text-sm-right">
      <div class="col-12 transaction-header">
        Category
      </div>
      <div class="col-12 mt-2 transaction-category">
        {{transaction.description | titlecase }}
      </div>
    </div>
    <div class="col-12 col-sm-8 col-lg-7 mt-3 mt-sm-0">
      <div class="row">
        <div class="col-12 transaction-header">Description</div>
        <div class="col-12 mb-2 mt-2 trans-des" *ngIf="transaction.data">
          <strong>From Goal:</strong>
          {{transaction.data.fromMilestone.name }}
        </div>
        <div class="col-12 mb-2 trans-des" *ngIf="transaction.data">
          <strong>To Goal:</strong>
          {{transaction.data.toMilestone.name}}
        </div>
        <div class="col-12 mb-2 trans-des">
          <strong>Transfer Amount:</strong>
          {{transaction.amount | currency }}
        </div>
        <div class="col-12 mb-2 trans-des">
          <strong>Status:</strong>
          {{transaction.subStatusDescription}}
        </div>
      </div>
    </div>
  </div>
</td>
