import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';

import { TransfersTopics } from '@app/support/enums';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
@Component({
  selector: 'how-to-transfers-topics',
  templateUrl: './transfers-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TransfersTopicsComponent implements OnInit {
  selectedTopic: TransfersTopics;
  topics = TransfersTopics;

  showSBBExternalAccounts: boolean;

  constructor(@Inject(olbSettings) public settings: OlbSettings) {}

  ngOnInit(): void {}
  selectTopic(topic: TransfersTopics): void {
    this.selectedTopic = topic;
  }
}
