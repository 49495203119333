<app-send-money-sbb-container
  *ngIf="this.hasBusinessAccounts && !this.hasIndividualAccounts"
></app-send-money-sbb-container>

<div class="send-money frow center wrap" *ngIf="this.hasIndividualAccounts">
  <div class="fcol-md-12 side-margin">
    <app-dot-loader [showWhen]="this.isLoadingAccounts">Loading accounts</app-dot-loader>

    <div *ngIf="this.step === 1">
      <div class="frow title-containerSbb" *ngIf="this.showFormBusinessLink">
        <div>
          <h1 class="send-money-title fcol-cs-12">Send Money in One Easy Step</h1>
          <p class="sub-title fcol-cs-12">To get started, complete the form below.</p>
        </div>
        <div class="link-containerSbb">
          <a class="text-primary more-info fcol-cs-2 fcol-lg-2 link" (click)="this.showMoreInfo()">
            <i class="bofi-information info"></i>
            <span class="hide-cs hide-xs info-text">How does this work?</span>
          </a>
        </div>
        <div class="fcol-cs-12 fcol-lg-7 send-money-boxSbb">
          <h1>
            I want to...
            <br />
            <hr />
          </h1>
        </div>
      </div>
      <div fcol-cs-12 fcol-lg-7 send-money-box2Sbb *ngIf="this.showFormBusinessLink">
        <a (click)="this.payBillsFromBusinessAccounts()"><span>Send money from my business accounts</span></a>
      </div>

      <div
        class="frow no-wrap between middle title-container"
        *ngIf="!this.hasBusinessAccounts && this.hasIndividualAccounts"
      >
        <div>
          <h1 class="send-money-title fcol-cs-12">Send Money in One Easy Step</h1>
          <p class="sub-title fcol-cs-12">To get started, complete the form below.</p>
        </div>
        <div>
          <a class="text-primary more-info fcol-cs-2 fcol-lg-2 link" (click)="this.showMoreInfo()">
            <i class="bofi-information info"></i>
            <span class="hide-cs hide-xs info-text">How does this work?</span>
          </a>
        </div>
      </div>

      <div class="frow center">
        <div class="frow center">
          <div class="fcol-cs-12 fcol-md-6">
            <app-alert-notification
              *ngIf="this.p2PMaxPaymentAmount && this.p2PMaxPaymentAmount < this.amount"
              [alertType]="'error'"
            >
              {{ this.P2PMaxPaymentLimitExceededMessage }}
            </app-alert-notification>
            <app-alert-notification
              *ngIf="this.isErrorBalanceAmount"
              [alertType]="'error'"
              (dismiss)="this.isErrorBalanceAmount = false"
            >
              <span>
                Amount cannot be more than Available Balance
              </span>
            </app-alert-notification>
          </div>
        </div>
        <form
          [formGroup]="sendMoneyForm"
          name="sendMoneyForm"
          class="frow center send-money-form"
          (submit)="transferMoney(sendMoneyForm.valid)"
          novalidate
        >
          <section class="sec-1 fcol-md-5 frow">
            <div
              class="fcol-cs-12 group--form"
              [ngClass]="{ 'has-error': this.errorRecipient.hasError && isFormSubmitted }"
              id="recipientNameGroup"
            >
              <label class="uk-label" for="recipientName">Pay To</label>
              <mat-form-field>
                <input
                  class="uk-input"
                  id="recipientName"
                  name="recipientName"
                  formControlName="recipientName"
                  matInput
                  type="text"
                  maxlength="50"
                  placeholder="{{ placeholderRecipient }}"
                  [matAutocomplete]="auto"
                  [(ngModel)]="this.selectedRecipient.displayName"
                  [value]="this.selectedRecipient.displayName"
                  (ngModelChange)="selectRecipient(this.selectedRecipient, { isUserInput: true })"
                />
                <mat-autocomplete #auto="matAutocomplete" title="matRecipient">
                  <div class="matOption">
                    <mat-option
                      id="recipient"
                      *ngFor="let recipient of filteredRecipients"
                      [value]="recipient.displayName"
                      (onSelectionChange)="selectRecipient(recipient, $event)"
                    >
                      {{ recipient.displayName }}
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </mat-form-field>
              <span
                class="text-error"
                *ngIf="this.errorRecipient.hasError && isFormSubmitted"
                [innerHtml]="this.errorRecipient.errorDescription"
              ></span>
            </div>
            <div
              class="fcol-cs-12 group--form"
              [ngClass]="{ 'has-error': this.errorMethod.hasError && isFormSubmitted }"
              id="recipientMethodGroup"
            >
              <label class="uk-label" for="recipientMethod">
                Send Method
                <span class="normal">(Email or Phone)</span>
              </label>
              <mat-form-field>
                <input
                  type="text"
                  name="recipientMethod"
                  placeholder="Enter Recipient's Email or Phone #"
                  aria-label="Recipient's Email or Phone #"
                  matInput
                  [matAutocomplete]="auto1"
                  class="uk-input"
                  minlength="0"
                  maxlength="128"
                  formControlName="recipientMethod"
                  [(ngModel)]="selectedMethod.label"
                  [value]="selectedMethod.label"
                  (ngModelChange)="_validMethod()"
                  (focusout)="validateMethod()"
                />
                <mat-autocomplete #auto1="matAutocomplete">
                  <div class="matOption">
                    <mat-option
                      id="method"
                      *ngFor="let method of filteredMethods"
                      [value]="method.label"
                      (onSelectionChange)="selectMethod(method, $event)"
                    >
                      {{ method.label }}
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </mat-form-field>
              <span
                class="text-error"
                *ngIf="this.errorMethod.hasError && isFormSubmitted"
                [innerHtml]="this.errorMethod.errorDescription"
              ></span>
            </div>
            <div
              class="fcol-cs-12 group--form"
              [ngClass]="{ 'has-error': sendMoneyForm.get('from').invalid && isFormSubmitted }"
              id="fromGroup"
            >
              <label class="uk-label" for="from">From Account</label>
              <select
                formControlName="from"
                name="from"
                id="from"
                aria-label="fromAccount"
                class="uk-input"
                [(ngModel)]="this.account"
                (ngModelChange)="validateAccountFrom()"
                required
              >
                <option value="" *ngIf="this.isLoadingAccounts" selected>Loading...</option>
                <option *ngFor="let account of this.filteredAccountsFrom" [ngValue]="account">
                  {{ account.nickName + ' (Avail. Bal: ' + (account.balance | currency) + ')' }}
                </option>
              </select>
              <span class="text-error" *ngIf="!isValidAccount && isFormSubmitted">
                Please enter a From Account
              </span>
            </div>
            <div
              class="fcol-cs-12 group--form"
              [ngClass]="{ 'has-error': this.errorAmount.hasError && isFormSubmitted }"
              id="amountGroup"
            >
              <label class="uk-label" for="amount">Amount</label>
              <input
                formControlName="amount"
                type="text"
                name="amount"
                id="amount"
                aria-label="amount"
                class="uk-input"
                [(ngModel)]="displayAmount"
                maxlength="8"
                (ngModelChange)="onValueAmount($event)"
                required
                prefix="$"
                mask="separator.2"
                thousandSeparator=","
                (focusin)="resetAmountToDefault(amount)"
                (blur)="resetAmountToDefault(amount)"
              />
              <div *ngIf="this.p2PMaxPaymentAmount">
                <p class="text-left">
                  Amount may not exceed
                  <span [innerHtml]="this.p2PMaxPaymentAmount | currency"></span>
                </p>
              </div>
              <span
                class="text-error"
                *ngIf="this.errorAmount.hasError && isFormSubmitted"
                [innerHtml]="this.errorAmount.errorDescription"
              ></span>
            </div>

            <div class="fcol-cs-12 group--form">
              <label class="uk-label" for="message">
                Message
                <span class="normal">(optional)</span>
              </label>
              <textarea
                formControlName="message"
                name="message"
                id="message"
                aria-label="message"
                [(ngModel)]="this.message"
                class="uk-input"
                rows="3"
                maxlength="250"
              ></textarea>
            </div>
          </section>
          <section class="sec-2 fcol-md-6 frow">
            <div class="fcol-cs-12 group--form title-sec-2">
              <h4>Transaction Security Question</h4>
              <p>Choose a question only your recipient would know the answer to.</p>
            </div>
            <div
              class="fcol-cs-12 group--form"
              [ngClass]="{ 'has-error': this.errorQuestion.hasError && isFormSubmitted }"
              id="questionGroup"
            >
              <label class="uk-label" for="question">Question</label>
              <mat-form-field>
                <input
                  type="text"
                  name="question"
                  placeholder="{{ this.placeholderQuestion }}"
                  aria-label="question"
                  matInput
                  [matAutocomplete]="auto2"
                  class="uk-input"
                  formControlName="question"
                  [(ngModel)]="selectedQuestion.label"
                  [value]="selectedQuestion.label"
                  minlength="0"
                  maxlength="250"
                  (focus)="loadSecretQuestion()"
                />
                <mat-autocomplete #auto2="matAutocomplete" class="matAutocomplete">
                  <div class="matOption">
                    <mat-option
                      id="question"
                      *ngFor="let question of filteredSecurityQuestions"
                      [value]="question.label"
                      (onSelectionChange)="selectQuestion(question, $event)"
                      class="matOption1"
                    >
                      {{ question.label }}
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </mat-form-field>
              <span
                class="text-error"
                *ngIf="this.errorQuestion.hasError && isFormSubmitted"
                [innerHtml]="this.errorQuestion.errorDescription"
              ></span>
            </div>
            <div
              class="fcol-cs-12 group--form"
              [ngClass]="{ 'has-error': sendMoneyForm.get('answer').invalid && isFormSubmitted }"
              id="answerGroup"
            >
              <label class="uk-label" for="answer">Answer*</label>
              <input
                formControlName="answer"
                type="text"
                name="answer"
                id="answer"
                aria-label="answer"
                class="uk-input"
                [(ngModel)]="this.answer"
                maxlength="250"
                required
              />
              <span class="text-error" *ngIf="sendMoneyForm.get('answer').invalid && isFormSubmitted">
                Please enter an answer
              </span>
              <div class="caption">
                * The recipient will need to use this answer to complete the transaction and receive your funds.
              </div>
            </div>
            <div class="fcol-cs-12 submit-section">
              <button
                [disabled]="this.p2PMaxPaymentAmount && this.p2PMaxPaymentAmount < this.amount"
                type="submit"
                class="uk-btn solid secondary lg"
                *ngIf="!this.isSendingMoney"
              >
                Submit
              </button>
              <app-dot-loader [showWhen]="this.isSendingMoney">Sending money...</app-dot-loader>
            </div>
          </section>
        </form>
        <div class="caption fcol-cs-12 big-caption">
          Note: funds must be available or the transaction will be rejected. Funds will remain in your account until the
          recipient accepts the payment.
        </div>
      </div>
    </div>
    <div class="text-center" *ngIf="this.step === 2">
      <app-multifactor
        [supportPhone]="this.phoneNumber | async"
        (sendCode)="sendCode()"
        [subtitle]="this.multifactorSubtitle"
        [title]="this.multifactorTitle"
        (ly-phone)="(true)"
      ></app-multifactor>
    </div>
    <div class="text-center" *ngIf="this.step === 3">
      <app-access-code
        [errorMessage]="this.accessCodeError"
        (continue)="validateOtp($event.code)"
        [otp]="this.otp"
        [displayRememberDevice]="false"
        [resendOut]="true"
        (back)="this.step = 1"
        (resendBack)="sendCode()"
        [displayPersonalInfo]="false"
      ></app-access-code>
    </div>
  </div>
</div>
