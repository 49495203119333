import { A11yModule } from '@angular/cdk/a11y';
import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, UrlHandlingStrategy } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { StoreModule } from '@ngrx/store';

import { NgxMaskModule } from 'ngx-mask';
import { AccountsModule } from '@app/accounts/accounts.module';
import { appInitializerProvider, Ng1Ng2UrlHandlingStrategy } from '@app/config/providers';
import { AxosAdvisoryModule } from '@app/axos-advisory/axos-advisory.module';
import { AxosInvestModule } from '@app/axos-invest/axos-invest.module';
import { AxosTradingModule } from '@app/axos-trading/axos-trading.module';
import { BeneficiariesModule } from '@app/beneficiaries/beneficiaries.module';
import { bootsrapLegacyApp } from '@legacy/app';
import { BusinessModule } from '@app/business/business.module';
import { ClickSwitchModule } from '@app/click-switch/click-switch.module';
import { ConfigModule } from '@app/config/config.module';
import { ConfigService } from '@core/services';
import { CoreModule } from '@core/core.module';
import { CryptoModule } from '@app/crypto/crypto.module';
import { InvestorCheckingModule } from '@app/investor-checking/investor-checking.module';
import { PfmModule } from '@app/pfm/pfm.module';
import { SecureFormsModule } from '@app/secure-forms/secure-forms.module';
import { SupportModule } from '@app/support/support.module';
import { thirdPartyModules } from '@env/environment';
import { TradingModule } from '@app/trading/trading.module';
import { TransfersModule } from '@app/transfers/transfers.module';
import { UserProfileModule } from '@app/user-profile/user-profile.module';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { AasModule } from './Areas/AAS/aas.module';
import { EnrollmentModule } from './enrollment/enrollment.module';
import { FastLinkModule } from './fast-link/fast-link-module';
import { MakeDepositModule } from './make-deposit/make-deposit.module';
import { appReducers } from './store/reducers';
import { TransactionHistoryModule } from './transaction-history/transaction-history.module';
import { storageMetaReducer } from './Areas/AAS/storage.metareducer';
import { InterestitialPageModule } from './Areas/AAS/features/interestitial-page/initial-page.module';
import { BillPayModule } from './bill-pay/bill-pay.module';
import { SblocOfferModule } from './sbloc-offers/sbloc-offer.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { MessagesModule } from './messages/messages.module';
import { AlertsNotificationsModule } from './alerts-notifications/alerts-notifications.module';
import { FundingModule } from './funding/funding.module';
import { MinorsModule } from '@app/minors/minors.module';
import { InboxModule } from './inbox';
import { AuthorizeTransferPageModule } from './Areas/AAS/features/authorize-transfer-page/authorize-transfer-page.module';
import { MyAlertsModule } from './alerts-notifications/my-alerts/my-alerts.module';

@NgModule({
  imports: [
    FastLinkModule,
    BillPayModule,
    BrowserModule,
    BrowserAnimationsModule,
    ConfigModule,
    CoreModule,
    NgxMaskModule.forRoot(),
    A11yModule,
    [
      StoreModule.forRoot(appReducers, {
        metaReducers: [storageMetaReducer],
      }),
    ],
    thirdPartyModules,
    AccountsModule,
    AlertsNotificationsModule,
    AxosInvestModule,
    AxosTradingModule,
    BusinessModule,
    ClickSwitchModule,
    CryptoModule,
    EnrollmentModule,
    MakeDepositModule,
    MessagesModule,
    PfmModule,
    SecureFormsModule,
    SupportModule,
    TradingModule,
    TransfersModule,
    UpgradeModule,
    UserProfileModule,
    TransactionHistoryModule,
    AxosAdvisoryModule,
    AasModule,
    BeneficiariesModule,
    InvestorCheckingModule,
    RouterModule.forRoot(appRoutes),
    InterestitialPageModule,
    AuthorizeTransferPageModule,
    SblocOfferModule,
    DashboardModule,
    FundingModule,
    MinorsModule,
    InboxModule,
    MyAlertsModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [{ provide: UrlHandlingStrategy, useClass: Ng1Ng2UrlHandlingStrategy }, appInitializerProvider],
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule, private configService: ConfigService) {}

  ngDoBootstrap() {
    const { appConfig } = this.configService;
    this.upgrade.bootstrap(document.body, [bootsrapLegacyApp(appConfig)]);
  }
}
