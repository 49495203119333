import { Inject } from '../../decorators/decorators';

@Inject('$filter')
export class Default {
  public static factory(): Function {
    return (value: any, def: string) => {
      return value || def;
    };
  }
}
