import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { enUS } from 'date-fns/locale';

import {
  MaterialDateFnsAdapterProvider,
  MaterialDateFnsFormatProvider,
  MaterialDateFnsLocaleProvider,
} from './material-date-fns-locale.model';
import { MaterialDateFnsAdapter } from './material-date-fns.adapter';

export const materialDateFnsAdapterProvider: MaterialDateFnsAdapterProvider = {
  provide: DateAdapter,
  useClass: MaterialDateFnsAdapter,
  deps: [MAT_DATE_LOCALE],
};

export const materialDateFnsFormatProvider: MaterialDateFnsFormatProvider = {
  provide: MAT_DATE_FORMATS,
  useValue: {
    parse: {
      dateInput: 'MM/dd/yyyy',
    },
    display: {
      dateInput: 'MM/dd/yyyy',
      monthYearLabel: 'LLL y',
      dateA11yLabel: 'MMMM d, y',
      monthYearA11yLabel: 'MMMM y',
    },
  },
};

export const materialDateFnsLocaleProvider: MaterialDateFnsLocaleProvider = {
  provide: MAT_DATE_LOCALE,
  useValue: {
    localeKey: 'enUS',
    locale: enUS,
  },
};
