<app-funding-shared-header></app-funding-shared-header>

<div class="mail-check-funding">
  <div class="container">
    <div class="header-section-title text-center mail-check__header">
      <div>
        <h1>Mail a Check</h1>
        <p id="padding-top-account" class="mail-check__header-sub">
          {{
            isTradingAccount
              ? displayName
              : 'To fund your account with a check, provide
            your account number and mail a check to:'
          }}
        </p>
      </div>
    </div>

    <div class="frow center" *ngIf="isTradingAccount">
      <h4 class="text-center fcol-md-6">
        To fund your account with a check, provide your account number in the memo and send to the address below.
      </h4>
    </div>

    <div class="header-section-title__img text-center">
      <img src="{{ imageSource }}" width="105" height="50" />
    </div>

    <div class="frow center">
      <div class="fcol-md-5 text-center address-details">
        <div *ngIf="!isTradingAccount">
          <section class="address-details__field">
            <h6 class="address-details__field-name">Address:</h6>
            <p>Axos Bank</p>
            <p>Deposit Operations</p>
            <p>P.O. Box 911039</p>
            <p>San Diego, CA 92191-9878</p>
          </section>

          <section class="address-details__field">
            <h6 class="address-details__field-name">Your Account Number:</h6>
            <p>{{ accountNumber }}</p>
          </section>
        </div>

        <table class="table table-striped table--outer-border" *ngIf="isTradingAccount">
          <tr>
            <td class="text-left truncate">Make Check Payable To</td>
            <td class="text-bold text-right">Axos Clearing LLC</td>
          </tr>
          <tr>
            <td class="text-left truncate">Your account number</td>
            <td class="text-bold text-right">{{ accountNumber }}</td>
          </tr>
          <tr>
            <td class="text-left truncate">Send To</td>
            <td class="text-bold text-right">
              <p>Axos Clearing LLC</p>
              <p>
                1200 Landmark Center, Suite 800
              </p>
              <p>Omaha, NE 68102-1916</p>
            </td>
          </tr>
        </table>

        <div class="frow between">
          <button type="button" class="uk-btn lg outline primary" (click)="goBack()">
            Back
          </button>

          <button (click)="goToDashboard()" type="button" class="uk-btn solid secondary lg">
            {{ isTradingAccount ? 'View Accounts' : 'Continue' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
