import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { Store } from '@ngrx/store';

import { SitecoreComponents } from '@bofi/sitecore-components';
import { UIKitClickdropdown } from '@uikit/clickdropdown';
import { UIKitTiles } from '@uikit/tiles';
import * as angular from 'angular';
import * as moment from 'moment';
import NgRedux from 'ng-redux';
import 'vendors';

import { InvestorCheckingComponent } from '../FrontEnd/src/app/investor-checking/components';
import { ConfigurationService, OutdatedContactInfoService, ProviderService, UserProfileService } from '@core/services';
import {
  AccessCodeComponent,
  DotLoaderComponent,
  SecurityQuestionsComponent,
  ToolbarComponent,
} from '@shared/components';

import { accountsModule } from './accounts/accounts.module';
import { alertsNotifications } from './alerts-notifications/alerts-notifications';
import { AppConfig } from './app.config';
import { AppRun } from './app.run';
import { authModule } from './auth';
import { billPay } from './bill-pay/bill-pay.module';
import { common } from './common/common';
import { DashboardModule } from './dashboard/dashboard.module';
import { LayoutModule } from './layout/layout.module';
import { MessagesModule } from './messages/messages.module';

import { services } from './services/services';
import { SharedModule } from './shared/shared.module';
import { transfers } from './transfers/transfers';
import { udb2 } from './udb/udb';
import {
  clickSwitchLegacyModule,
  makeDepositLegacyModule,
  beneficiariesLegacyModule,
  managedPortfolioLegacyModule,
  supportLegacyModule,
  userProfileLegacyModule,
  InvestorCheckingModule,
  ProductDetailsPageLegacyModule,
  AccountDetailsPageLegacyModule,
  billPayLegacyModule,
  sblocOffersLegacyModule,
  stopPaymentLegacyModule,
  alertsNotificationsLegacyModule,
  messagesLegacyModule,
  fundingLegacyModule,
  secureForms,
  P2PLegacyModule,
  SendMoneyLegacyModule,
  HistoryLegacyModule,
  minorsLegacyModule,
  receiptLegacyModule,
} from './upgraded';
import { InterestitialPageLegacyModule } from './upgraded/interestital-page..legacy.module';
import { AuthorizeTransferPageLegacyModule } from './upgraded/authorize-transfer-page.legacy.module';

export function bootsrapLegacyApp(settings: OlbSettings) {
  const LegacyAppModule = angular
    .module('olb', [
      'ui.mask',
      'udb.tpls',
      billPayLegacyModule,
      sblocOffersLegacyModule,
      alertsNotificationsLegacyModule,
      UIKitTiles,
      UIKitClickdropdown,
      SharedModule,
      DashboardModule,
      LayoutModule,
      common,
      services,
      userProfileLegacyModule,
      InvestorCheckingModule,
      ProductDetailsPageLegacyModule,
      InterestitialPageLegacyModule,
      AccountDetailsPageLegacyModule,
      AuthorizeTransferPageLegacyModule,
      accountsModule,
      transfers,
      billPay,
      billPayLegacyModule,
      alertsNotifications,
      makeDepositLegacyModule,
      supportLegacyModule,
      MessagesModule,
      'ng-currency',
      'ngAnimate',
      'daterangepicker',
      'tw.directives.clickOutside',
      'ngAria',
      NgRedux,
      SitecoreComponents,
      authModule,
      clickSwitchLegacyModule,
      managedPortfolioLegacyModule,
      stopPaymentLegacyModule,
      udb2,
      beneficiariesLegacyModule,
      messagesLegacyModule,
      alertsNotificationsLegacyModule,
      fundingLegacyModule,
      secureForms,
      alertsNotificationsLegacyModule,
      P2PLegacyModule,
      SendMoneyLegacyModule,
      HistoryLegacyModule,
      minorsLegacyModule,
      receiptLegacyModule,
    ])
    .constant('env', settings)
    .run(AppRun)
    .config(AppConfig)
    .constant('dateRangePickerOptions', {
      locale: {
        format: 'MM/DD/YY',
        fromLabel: 'From',
        toLabel: 'To',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: moment.weekdaysShort(),
        monthNames: moment.months(),
      },
      opens: 'center',
      linkedCalendars: true,
      buttonClasses: 'btn-daterange',
      applyClass: 'uk-btn solid secondary lg col-xs-4',
      cancelClass: 'uk-btn outline primary lg col-xs-4',
    })
    .directive('appAccessCode', downgradeComponent({ component: AccessCodeComponent }))
    .directive('appSecurityQuestions', downgradeComponent({ component: SecurityQuestionsComponent }))
    .directive('appToolbar', downgradeComponent({ component: ToolbarComponent }))
    .directive('appDotLoader', downgradeComponent({ component: DotLoaderComponent }))
    .directive('appInvestorChecking', downgradeComponent({ component: InvestorCheckingComponent }))
    .factory('matDialog', downgradeInjectable(MatDialog))
    .factory(ConfigurationService.$token, downgradeInjectable(ConfigurationService))
    .factory(ProviderService.$token, downgradeInjectable(ProviderService))
    .factory('newUserProfileService', downgradeInjectable(UserProfileService))
    .factory('ngrxStore', downgradeInjectable(Store))
    .factory('outdatedContactInfoService', downgradeInjectable(OutdatedContactInfoService));

  return LegacyAppModule.name;
}
