<div class="container" *ngIf="loaded; else loader">
  <div class="row flex-row-reverse">
    <p class="last-updated">Last Updated {{ lastUpdatedDate | date: 'MM/dd/yy h:mmaa' }}</p>
  </div>
  <div class="row mt-4">
    <div class="col-12 overview-title">Overview</div>
  </div>
  <div class="row mt-2 portfolio-search-row">
    <div class="col-10 overview-subtitle">Your Portfolio</div>
    <button
      type="button"
      class="uk-btn sm solid secondary col-2 flex-row-reverse search-button"
      (click)="searchEquity()"
    >
      Research
    </button>
  </div>
  <div class="row mt-2">
    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
      <p class="d-block data-label mb-1">
        Total Value
        <app-tooltip
          symbol="?"
          content="The full sum of your account assets, including current positions and available buying power."
        ></app-tooltip>
      </p>
      <p class="d-block data-value total-value">{{ portfolioOverview.totalValue | currency }}</p>
    </div>
    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
      <p class="data-label mb-1">
        Day Change
        <app-tooltip
          symbol="?"
          content="The difference in dollar and percentage terms between your portfolio's total current value and its value at the close of the most recent trading day."
        ></app-tooltip>
      </p>
      <div
        class="day-change"
        [class.equal]="portfolioOverview.dayChangePercentage === 0 || !portfolioOverview.dayChangePercentage"
        [class.positive]="portfolioOverview.dayChangePercentage > 0"
        [class.negative]="portfolioOverview.dayChangePercentage < 0"
      >
        <div class="d-flex align-items-center">
          <app-svg-icon
            class="day-change-label-btn-icon"
            [iconName]="
              portfolioOverview.dayChangePercentage === 0 || !portfolioOverview.dayChangePercentage
                ? icons.equal
                : portfolioOverview.dayChangePercentage > 0
                ? icons.triangleUp
                : icons.triangleDown
            "
            width="1.15em"
            height="1.15em"
            [iconColor]="
              portfolioOverview.dayChangePercentage === 0 || !portfolioOverview.dayChangePercentage
                ? '#494949'
                : portfolioOverview.dayChangePercentage > 0
                ? '#00805B'
                : '#fd0000'
            "
          ></app-svg-icon>
          <span class="day-change-percentage ml-2">{{ portfolioOverview.dayChangePercentage | number: '1.0-2' }}%</span>
          <span class="day-change-amount ml-2">
            <span *ngIf="portfolioOverview.dayChange > 0">+</span>
            <span>{{ portfolioOverview.dayChange | currency }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="!isShowExchangeAgreement">
    <app-historic-overview class="d-block col-12" [account]="account"></app-historic-overview>
  </div>
  <div class="row mt-4" *ngIf="isShowExchangeAgreement">
    <app-exchange-agreement
      id="exchangeAgreement"
      class="col-12"
      [accountId]="account.id"
      [accountNumber]="account.accountNumber"
      (exchangeAgreementState)="exchangeAgreementState($event)"
    ></app-exchange-agreement>
  </div>
  <app-positions-list
    *ngIf="portfolioOverview.positions"
    [positions]="portfolioOverview.positions"
    [accountId]="account.id"
  ></app-positions-list>
  <div class="row align-items-end mt-4" *ngIf="!(portfolioOverview.availableMargin <= 0)">
    <div class="pl-3 overview-subtitle">
      Buying Power
      <app-tooltip
        class="data-label"
        symbol="?"
        content="This is how much money you have in your account available for investing."
      ></app-tooltip>
    </div>
    <button class="btn-text d-flex align-items-end" id="transferFundsBtn" (click)="goToMoveMoney()">
      Deposit Funds
      <app-svg-icon class="ml-2" [iconName]="icons.arrowForward"></app-svg-icon>
    </button>
  </div>
  <div class="row mt-2" *ngIf="!(portfolioOverview.availableMargin <= 0)">
    <app-margin-enabled-notification [account]="account" class="col-12"></app-margin-enabled-notification>
    <div
      class="col-12 buying-power"
      id="buyingPowerValue"
      *ngIf="!portfolioOverview.availableMargin || portfolioOverview.availableMargin <= 0"
    >
      {{ portfolioOverview.buyingPower | currency }}
    </div>
  </div>
  <div class="row mt-2">
    <div class="pl-3 overview-subtitle">
      Cash
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 buying-power" id="cashOnAccountValue">
      {{ portfolioOverview.cashOnAccount | currency }}
    </div>
  </div>
  <div *ngIf="portfolioOverview.availableMargin > 0" class="available-margin">
    <div class="row mt-2">
      <div class="pl-3 overview-subtitle">
        Available Margin
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 buying-power" id="availableMarginValue">
        {{ portfolioOverview.availableMargin | currency }}
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="news">
    <div class="col-12 overview-subtitle">Your news</div>
    <app-news class="col-12 mt-2" [articles]="news"></app-news>
  </div>
  <div class="mt-5">
    <app-feature-link></app-feature-link>
  </div>
</div>
<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
