<div *ngIf="isEmpty" class="empty-message">
  No Account Activity
</div>
<div *ngIf="!isEmpty">
  <div *ngIf="assets?.length">
    <div class="heder-line">
      <div class="row-item text-left header-line-title">Assets</div>
      <div class="row-item text-right header-line-other-title width-24">% Change</div>
      <div class="row-item text-right header-line-other-title width-24">Amt. Change</div>
      <div class="row-item text-right header-line-other-title width-27">Total</div>
    </div>
    <div class="row-content" *ngFor="let asset of assets">
      <span class="row-item row-account-type text-left">{{ asset.categoryName }}</span>
      <div class="row-item text-right row-change width-24">
        <div *ngIf="asset.percentageChange == null" class="row-item-change">--%</div>
        <div *ngIf="asset.percentageChange != null">
          <app-svg-icon
            *ngIf="asset.arrowIcon"
            [iconName]="asset.arrowIcon"
            [iconColor]="'#8F8F8F'"
            width="13px"
            height="10px"
          ></app-svg-icon>
          <span class="percentage-change row-item-change">{{ asset.percentageChange | percentageRound: true }}%</span>
        </div>
      </div>
      <div class="row-item text-right row-item-change font-italic width-24">
        <span *ngIf="asset.balanceChange != 0">{{ asset.balanceChange > 0 ? '+' : '-' }}</span>
        <span>{{ getDisplayBalance(asset.balanceChange) }}</span>
      </div>
      <span class="row-item text-right total-balance width-27">{{ asset.balance | currency }}</span>
    </div>
  </div>
  <div *ngIf="liabilities?.length">
    <div class="heder-line">
      <div class="row-item text-left header-line-title">Liabilities</div>
      <div class="row-item text-right header-line-other-title width-24">% Change</div>
      <div class="row-item text-right header-line-other-title width-24">Amt. Change</div>
      <div class="row-item text-right header-line-other-title width-27">Total</div>
    </div>
    <div class="row-content" *ngFor="let liability of liabilities">
      <span class="row-item row-account-type text-left">{{ liability.categoryName }}</span>
      <div class="row-item text-right row-change width-24">
        <div *ngIf="liability.percentageChange == null" class="row-item-change">--%</div>
        <div *ngIf="liability.percentageChange != null">
          <app-svg-icon
            *ngIf="liability.arrowIcon"
            [iconName]="liability.arrowIcon"
            [iconColor]="'#8F8F8F'"
            width="13px"
            height="10px"
          ></app-svg-icon>
          <span class="percentage-change row-item-change">
            {{ liability.percentageChange | percentageRound: true }}%
          </span>
        </div>
      </div>
      <div class="row-item text-right row-item-change font-italic width-24">
        <span *ngIf="liability.balanceChange != 0">{{ liability.balanceChange > 0 ? '+' : '-' }}</span>
        <span>{{ getDisplayBalance(liability.balanceChange) }}</span>
      </div>
      <span class="row-item text-right total-balance width-27">{{ liability.balance | currency }}</span>
    </div>
  </div>
</div>
