import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { RiaStateType } from '.';

const getRiaState = createFeatureSelector<RiaStateType>(AasStoreFeatures.Rias);

export const getSelectedRia = createSelector(getRiaState, state => {
  if (state.rias === undefined) {
    return undefined;
  }

  return state.rias.find(item => item.name === state.selectedRia) ?? undefined;
});

export const getSelectedGlobalRia = createSelector(getRiaState, state => {
  const { rias } = state;

  return rias.reduce(item => item, undefined);
});

export const getAllRias = createSelector(getRiaState, state => {
  return state.rias ?? undefined;
});

export const isLoadedRias = createSelector(getRiaState, state => state.isLoaded);
