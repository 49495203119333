import { TimePeriod } from '@app/pfm/enums/time-period.enum';

export class NetWorthFilters {
  timePeriod = TimePeriod.LastMonth;
  excludedAccounts = new Set<string>();

  constructor(args?: Partial<NetWorthFilters>) {
    Object.assign(this, args);
  }
}
