<div class="tx-footer-multiple">
  <div class="frow middle between dispute-buttons">
    <section class="tx-footer-multiple__selected">
      <div class="frow">
        <div class="badge-col">
          <span class="tx-footer-multiple__selected-badge">{{ selected }}</span>
        </div>
        <div class="badge-text">
          <span>
            Transactions Selected
          </span>
          <br />
          <a class="link" (click)="clearSelected()">Clear Selected</a>
        </div>
      </div>
    </section>
    <section class="tx-footer-multiple__title">
      <h4>Select the transactions above that you wish to dispute</h4>
    </section>
    <section class="tx-footer-multiple__button">
      <button type="button" class="uk-btn solid secondary lg" (click)="fileDispute()">
        File Disputes
      </button>
      <p class="text-error" *ngIf="hasNoSelection">
        Please, select at least a transaction in order to continue
      </p>
    </section>
  </div>
</div>
