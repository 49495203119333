import { Inject } from '../../decorators/decorators';

@Inject('loadUserProfileHelper')
export class FileUploaderController {
  files: SupportFile[] = [];
  errorMessage = '';

  constructor() {}

  /**onInit */
  $onInit() {}

  /**
   * Validate files added to the form
   * @param $file
   * @param $invalidFiles
   */
  validateFile($file: any, $invalidFiles: any) {
    if ($invalidFiles && $invalidFiles.length) {
      const error = $invalidFiles[0].$error;
      if (error === 'maxSize') this.errorMessage = 'The maximum file size is 10MB.';
      else if (error === 'pattern')
        this.errorMessage = 'Acceptable file types are: PDF, Word, JPG, PNG, XLS';
      return;
    }

    if (!$file) return;

    this.files.push({ Document: $file, Name: $file['name'] });
    this.errorMessage = '';
  }

  /**
   * Removes the file from the list of files added
   * @param file File object
   */
  removeFile(file: SupportFile) {
    this.files = this.files.filter(fl => fl.Name !== file.Name);
  }
}
