<h3 class="mt-5 mb-4">How do I edit a recurring bill payment?</h3>
<ol>
  <li>
    Click on the
    <b>Pay Bills tab.</b>
  </li>
  <li>
    Next click on
    <b>Scheduled Payments.</b>
    You will then see a list of the payments that you have scheduled.
  </li>
  <li>
    Click on
    <b>Edit</b>
    for the payment that you want to change.
  </li>
  <li>
    Make your changes and then click
    <b>Save Changes.</b>
  </li>
</ol>
