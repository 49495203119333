<div class="simple-mat-stepper-wrapper">
  <div class="simple-mat-content-container">
    <div
      class="simple-mat-stepper-content"
      *ngFor="let step of _steps; let i = index"
      [@stepTransition]="_getAnimationDirection(i)"
      [class.simple-mat-stepper-content-inactive]="selectedIndex !== i"
    >
      <div [ngTemplateOutlet]="step.content"></div>
    </div>
  </div>
</div>
