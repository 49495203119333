<form class="change-address col-12" [formGroup]="addressForm" (ngSubmit)="updateUserAddresses()">
  <p class="mb-4 text-center font-weight-normal">
    {{ centerText }}
  </p>
  <div [ngSwitch]="profileType">
    <div *ngSwitchCase="profileTypes.Personal">
      <div class="mb-2">
        <h3>Home Address (Legal)</h3>
        <p class="font-weight-normal">
          Only change your primary address for a permanent move to a new residence. Cannot be a P.O. box.
        </p>
      </div>

      <app-address [parentForm]="addressForm.at(0)" [addressType]="addressTypes.Physical"></app-address>

      <div class="form-group">
        <mat-checkbox
          name="mailing-as-primary"
          id="mailing-as-primary"
          [checked]="addressesAreTheSame"
          (change)="toggleSecondAddressForm($event.checked)"
        >
          <b>{{ checkboxText }}</b>
        </mat-checkbox>
      </div>

      <ng-container *ngIf="mailingAddressForm">
        <div class="mb-2" id="mailing-address-header">
          <h3>Mailing Address</h3>
          <p class="font-weight-normal">
            Address at which you wish to receive email.
          </p>
        </div>
        <app-address [parentForm]="mailingAddressForm" [addressType]="addressTypes.Mailing"></app-address>
      </ng-container>
    </div>
    <div *ngSwitchCase="profileTypes.Business">
      <ng-container *ngIf="businessMailingForm">
        <app-address [parentForm]="businessMailingForm" [addressType]="addressTypes.Business"></app-address>
      </ng-container>
    </div>
    <div *ngSwitchCase="profileTypes.Company">
      <b>Business Address</b>
      <label class="address-note">
        Only change your primary address for a permanent move to a new residence. Cannot be a P.O. box.
      </label>
      <ng-container *ngIf="businessMailingForm">
        <app-address [parentForm]="businessMailingForm" [addressType]="addressTypes.Physical"></app-address>
      </ng-container>
      <div class="form-group">
        <mat-checkbox
          name="mailing-as-primary"
          id="mailing-as-primary"
          [checked]="addressesAreTheSame"
          (change)="toggleSecondAddressForm($event.checked)"
        >
          <span>Use Business Address as Mailing Address</span>
        </mat-checkbox>
      </div>

      <ng-container *ngIf="mailingAddressForm">
        <div class="mb-2" id="mailing-address-header">
          <h3>Mailing Address</h3>
          <p class="font-weight-normal">
            Address at which you wish to receive email.
          </p>
        </div>
        <app-address [parentForm]="mailingAddressForm" [addressType]="addressTypes.Mailing"></app-address>
      </ng-container>
    </div>
  </div>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="addressForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
