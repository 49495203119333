<input
  #percentageInput
  type="text"
  class="uk-input"
  mask="percent"
  suffix="%"
  maxlength="7"
  [(ngModel)]="percentage"
  [disabled]="isDisabled"
  (ngModelChange)="emitModelChanges()"
  (blur)="validateAndEmit()"
  (focus)="validateMinAndMax()"
/>
