import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q')
class UserRecoveryService extends BaseService implements IUserRecoveryService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'authentication', _serviceHelper, q);
  }

  sendForgotUsername(email: string): ApiResponse<void> {
    return this.get(`recover/username/${encodeURIComponent(email)}`);
  }
}

export { UserRecoveryService };
