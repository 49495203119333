import { IStateService } from 'angular-ui-router';
import { IBillPayService } from 'services/typings/IBillPayService';

import { Inject } from '../../../decorators/decorators';
import { BillPayRouterHelper } from '../../bill-pay-router.helper';
import { Biller } from '../../typings/Biller';
import { Recipient } from '../../typings/Recipient';
import { RecipientsGroup } from '../../typings/RecipientsGroup';

@Inject('$state', '$rootScope', 'serviceHelper', 'billPayService', 'billPayRouterHelper')
export class SearchRecipientController {
  maxBillPayTopRecipients: number;
  maxBillPaySearchResults: number;
  criteria: string;
  isLoading: boolean;
  topRecipients: RecipientsGroup[];
  searchCriteria = '';
  isSearching = false;
  searchResults: Recipient[] = [];

  constructor(
    private readonly state: IStateService,
    private readonly rootScope: ng.IRootScopeService,
    private readonly serviceHelper: IServiceHelper,
    private readonly billPayService: IBillPayService,
    private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}

  $onInit(): void {
    this.maxBillPayTopRecipients = +this._getAppSettingsValue('MaxBillPayTopRecipients');
    this.maxBillPaySearchResults = +this._getAppSettingsValue('MaxBillPaySearchResults');

    this._getTopRecipients();
    this.criteria = this.billPayRouterHelper.getCriteria();
    if (this.criteria && this.criteria !== undefined) {
      this.searchCriteria = this.criteria;
      this.searchRecipient();
    }
  }

  searchRecipient() {
    if (this.searchCriteria.length > 2) {
      this.isSearching = true;
      this._searchRecipients(this.searchCriteria);
    } else {
      this.isSearching = false;
    }
  }

  selectRecipientGroup(recipient: RecipientsGroup) {
    const defaultBiller = this.createBillerFromRecipientsGroup(recipient);
    this.billPayRouterHelper.setCriteria(this.searchCriteria);
    this.billPayRouterHelper.setBiller(defaultBiller);
    this.billPayRouterHelper.setRecipient(null);
    this.state.go('udb.billPay.billingDetails');
  }

  changeToBillingDetails(biller: Biller) {
    this.billPayRouterHelper.setCriteria(this.searchCriteria);
    this.billPayRouterHelper.setBiller(biller);
    this.billPayRouterHelper.setRecipient(null);
    this.state.go('udb.billPay.billingDetails');
  }

  addRecipientManually() {
    this.billPayRouterHelper.clean();
    this.billPayRouterHelper.setCriteria(this.searchCriteria.substr(0, 40));
    this.state.go('udb.billPay.addCheckRecipient');
  }

  cancelSetup() {
    this.billPayRouterHelper.cancelSetup();
  }

  private createBillerFromRecipientsGroup(recipientsGroup: RecipientsGroup) {
    const defaultBiller = new Biller();
    defaultBiller.addressMatchRequired = recipientsGroup.addressMatchRequired;
    defaultBiller.isAddressMatchRequired = recipientsGroup.addressMatchRequired;
    defaultBiller.addressMatchRequiredSpecified = recipientsGroup.addressMatchRequiredSpecified;
    defaultBiller.billerGroupId = recipientsGroup.billerGroupId;
    defaultBiller.name = recipientsGroup.displayName;
    defaultBiller.paymentAccountHint = recipientsGroup.paymentAccountHint;
    defaultBiller.allBillers = recipientsGroup.billers.map(biller => ({
      name: biller.name,
      billerId: biller.billerId,
      paymentAccountHint: biller.paymentAccountHint,
    }));

    return defaultBiller;
  }

  private _getTopRecipients() {
    this.isLoading = true;
    this.billPayService
      .getTopRecipients()
      .then(response => {
        this.topRecipients = response.data.filter((billerGroup: RecipientsGroup) => {
          return billerGroup.billers.length > 0;
        });
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
      });
  }

  private _searchRecipients(criteria: string): void {
    this.billPayService
      .searchRecipients(criteria)
      .then(response => {
        this.searchResults = response.data;
      })
      .catch(this.serviceHelper.errorHandler);
  }

  private _getAppSettingsValue(key: string) {
    return this.rootScope['appSettings'].find((ap: any) => ap.name === key).value;
  }
}
