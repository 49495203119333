import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { PersonalDepositModal } from './view/personal-deposit-modal.component';

@NgModule({
  declarations: [PersonalDepositModal],
  imports: [SharedModule],
  exports: [PersonalDepositModal],
})
export class PersonalDepositModule {}
