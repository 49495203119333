import { AfterViewInit, Component, Input, OnChanges, ViewChild } from '@angular/core';

import { format } from 'date-fns';

import { ChartOptionsDonut } from '@app/pfm/models';
import { LineChartPlugin } from '@app/pfm/utils/chartjs-plugins/line';
import { absoluteRound } from '@shared/utils/math';

import { LineChartPoint } from './models/point.model';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit, OnChanges {
  @Input() chartjsOptions: any;
  @Input() width: number;
  @Input() height: number;

  @Input() points: LineChartPoint[] = [];
  @Input() xAxisTickFormatter: (date: Date, index: number, dates: Date[]) => string;

  chart: Chart;
  @ViewChild('chart') chartRef;

  lastWidth: number;
  lastHeight: number;
  lastGradient: any;

  constructor() {}

  ngAfterViewInit(): void {
    this.updateChart();
  }
  ngOnChanges() {
    this.updateChart();
  }

  getOptions(): ChartOptionsDonut {
    const self = this;

    const options = {
      title: { display: false },

      hover: {
        // disable animation on hover (workaround bug for dots blinking)
        animationDuration: 0,
        mode: 'index',
        intersect: false,
      },

      tooltips: {
        mode: 'index',
        intersect: false,

        backgroundColor: 'white',
        borderColor: '#D4D4D4',
        borderWidth: 1,
        cornerRadius: 4,
        xPadding: 15,
        yPadding: 7,
        displayColors: false,
        caretSize: 0, // hide tooltip arrow
        caretPadding: 22,

        titleAlign: 'center',
        titleFontColor: '#494949',
        titleFontStyle: '500',
        titleFontFamily: 'Roboto',
        titleFontSize: 16,
        titleMarginBottom: 4,

        padding: 20,

        bodyAlign: 'center',
        bodyFontColor: '#8F8F8F',
        bodyFontStyle: 'normal',
        bodyFontFamily: 'Roboto',
        bodyFontSize: 12,

        callbacks: {
          label(context) {
            return format(context.xLabel, 'M/d/yy');
          },
          title(context) {
            const amount = Number.parseFloat(context[0]?.value) || 0;

            return amount.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        },
      },

      elements: {
        point: {
          radius: 0, // dot size while not being hovered

          hoverRadius: 9.5,
          hoverBackgroundColor: 'white',
          hoverBorderWidth: 7.5,
          hoverBorderColor: '#494949',
        },
      },

      legend: { display: false },

      scales: {
        xAxes: [
          {
            gridLines: { display: false }, // hide vertical lines
            ticks: {
              fontFamily: 'Roboto',
              fontSize: 14,
              fontColor: '#8F8F8F',
              padding: 10,
              maxRotation: 0, // if the ticks don't fit DON'T tilt them,
              minRotation: 0, // ... just hide some of them
              maxTicksLimit: 7,

              callback(date: Date, index: number, dates: Date[]) {
                // return null to hide the tick
                return self.xAxisTickFormatter ? self.xAxisTickFormatter(date, index, dates) : date;
              },
            },
          },
        ],
        yAxes: [
          {
            position: 'right',
            scaleLabel: { display: false },

            gridLines: {
              zeroLineColor: '#ABABAB',
              zeroLineWidth: 1.5,
              color: '#CCCCCC',
              lineWidth: 0.5,
              tickMarkLength: 0,
            },

            ticks: {
              fontFamily: 'Roboto',
              fontSize: 14,
              fontColor: '#8F8F8F',
              padding: 10,
              beginAtZero: true,
              maxTicksLimit: 7,

              callback(value) {
                // if all points are 0, hide the ticks
                if (!self.points.find(p => p.amount > 0 || p.amount < 0)) return null;

                // don't render '$0' if all the points are positive/negative
                const amounts = self.points.map(p => p.amount);
                const min = Math.min(...amounts);
                const max = Math.max(...amounts);
                if (value === 0 && !(min < 0 && max > 0)) return null;

                let amount = value;
                let suffix = '';

                if (Math.abs(value) >= 1000000) {
                  amount = value / 1000000;
                  suffix = 'M';
                } else if (Math.abs(value) >= 1000) {
                  amount = value / 1000;
                  suffix = 'K';
                }

                return (
                  amount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                  }) + suffix
                );
              },
            },
          },
        ],
      },
    };

    return this.mergeDeep(options, this.chartjsOptions);
  }

  getData(): ChartData<any> {
    const self = this;

    const data = {
      labels: this.points.map(p => p.date),
      datasets: [
        {
          data: this.points.map(p => absoluteRound(p.amount)),

          borderColor: '#4D85B0',
          borderWidth: 2,
          lineTension: 0, // disable smoothing between points
          fill: true, // fill area under the curve

          dash: this.points.map(p => (p.isDashed ? 5 : 0)),

          backgroundColor(context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            // initial chart load
            if (!(chartArea?.top >= 0 && chartArea?.right >= 0 && chartArea?.bottom >= 0 && chartArea?.left >= 0)) {
              return null;
            }

            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (!self.lastGradient || self.lastWidth !== chartWidth || self.lastHeight !== chartHeight) {
              // Create the gradient because this is either the first render
              // or the size of the chart has changed
              self.lastWidth = chartWidth;
              self.lastHeight = chartHeight;

              try {
                self.lastGradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                self.lastGradient.addColorStop(1, 'rgba(135, 185, 215, .8)');
                self.lastGradient.addColorStop(0.33, 'rgba(135, 185, 215, 0.35)');
                self.lastGradient.addColorStop(0, 'rgba(135, 185, 215, .1)');
              } catch {
                // fallback to solid color
                return 'rgba(135, 185, 215, 0.35)';
              }
            }

            return self.lastGradient;
          },
        },
      ],
    };

    return data;
  }

  updateChart() {
    if (!this.chartRef) return;

    // defer Chart.js creation until a non-empty array is provided
    //   this is needed to preserve the initial animation since there is
    //   no animation on updates
    if (!this.chart && !this.points?.length) return;

    const data = this.getData();
    const options = this.getOptions();

    if (!this.chart) {
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'dashedLineSegment',
        plugins: [new LineChartPlugin()],
        data,
        options,
      });
    }

    this.chart.chart.config.data = data;
    this.chart.chart.config.options = options;
    this.chart.chart.update();
  }

  /**
   * Deep merge two objects, this does NOT handle circular references
   *   (similar to lodash merge)
   */
  mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else if (Array.isArray(target[key])) {
          for (let i = 0; i < Math.min(target[key].length, source[key].length); i++) {
            this.mergeDeep(target[key][i], source[key][i]);
          }
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }
}
