export enum NavigationIcons {
  ArrowBack = 'navigation-arrow-back',
  ArrowForward = 'navigation-arrow-forward',
  ArrowBack2 = 'navigation-arrow-back-2',
  ChevronLeft = 'navigation-chevron-left',
  ChevronRight = 'navigation-chevron-right',
  ChevronDown = 'navigation-chevron-down',
  ChevronUp = 'navigation-chevron-up',
  ArrowsHorizontal = 'navigation-arrows-horizontal',
  ArrowsCircular = 'navigation-arrows-circular',
  Ex = 'navigation-x',
  Hamburger = 'navigation-hamburger',
  EllipsesHorizontal = 'navigation-ellipses-horizontal',
  EllipsesVertical = 'navigation-ellipses-vertical',
  LogIn = 'navigation-log-in',
  LogOut = 'navigation-log-out',
  ExternalLink = 'navigation-external-link',
  TriangleUp = 'navigation-triangle-up',
  TriangleDown = 'navigation-triangle-down',
  Equal = 'navigation-equal',
}
