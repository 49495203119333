import { FileUploaderController } from './file-uploader.controller';
export const FileUploaderComponent: ng.IComponentOptions = {
  controller: FileUploaderController,
  templateUrl: 'shared/file-uploader/file-uploader.tpl.html',
  controllerAs: '$fu',
  bindings: {
    files: '=',
    errorMessage: '<',
  },
};
