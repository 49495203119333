export enum HowTo {
  Dashboard = 1,
  Accounts,
  Alerts,
  BillPay,
  Deposits,
  Transfers,
  PayItNow,
  Other,
}
