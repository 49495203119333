import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  AfterContentInit,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoanPayoffModalUk2Module } from './loan-payoff-modal-uk2.module';
import { Uk2ModalComponent } from '@axos/uikit-v2-lib';

@Component({
  selector: 'loan-payoff-iframe',
  templateUrl: './loan-payoff-iframe.component.html',
  styleUrls: ['./loan-payoff-iframe.component.scss'],
  standalone: true,
  imports: [LoanPayoffModalUk2Module],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanPayoffIframeComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {
  @ViewChild('loanPayoffModal') loanPayoffModal?: Uk2ModalComponent;
  @ViewChild('loanPayoffIframe', { static: false }) loanPayoffIframe?: ElementRef;
  @Input() url: string;
  @Input() jwt: string;

  urlSafe: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    this.loanPayoffModal?.openDialog();
  }

  ngAfterContentInit(): void {
    this.loanPayoffModal?.openDialog();
  }

  ngOnInit(): void {
    // Sanitize URL for Loan Payoff iframe
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.splitValue(this.url, true));
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update the URL for Loan Payoff iframe
    if (changes) {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.splitValue(this.url, true));
      this.loanPayoffModal?.closeDialog();
      this.loanPayoffModal?.openDialog();
    }
  }

  splitValue(value: string, getUrl: boolean) {
    // If getUrl = true then return URL part. Else return loanNumber
    let parts = value.split('|');
    return getUrl ? parts[0] : parts[1];
  }

  onLoad(loanPayoffIframe: HTMLIFrameElement) {
    const data = {
      type: 'UserData',
      JWT: this.jwt,
      LoanNumber: this.splitValue(this.url, false),
    };

    // Listen for 'readyForUserData' message from iframe
    window.addEventListener('message', function (event) {
      if (event.data === 'readyForUserData') {
        loanPayoffIframe.contentWindow.postMessage(data, '*');
      }
    });
  }
}
