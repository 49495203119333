declare var ca: any;

import { DeviceInformation, MultiFactorRequest, UserInformation } from '@core/models';

import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IMultifactorService } from './typings/IMultifactorService';

@Inject('$http', 'env', 'serviceHelper', '$q')
class MultifactorService extends BaseService implements IMultifactorService {
  private _deviceInformation: DeviceInformation = {};
  private _userInformation: UserInformation;
  private caClient: any;

  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'authentication', _serviceHelper, q);

    this._userInformation = {
      username: '',
      phoneNumber: '',
    };

    const client = new ca.rm.Client();
    client.setProperty('format', 'json');
    client.setProperty('store', 'localstorage');
    client.setProperty('didname', 'rmClient');
    client.setProperty('noFlash', true);
    this.caClient = client;
  }

  setUserInfo(
    username: string,
    phoneNumber: string,
    email: string,
    emailAvailable?: boolean
  ): void {
    this._userInformation = { username, phoneNumber, email, emailAvailable };
  }

  getUserInfo(): UserInformation {
    return this._userInformation;
  }

  getDeviceInfo(): DeviceInformation {
    return this._deviceInformation;
  }

  updateDeviceInformation(deviceInfo: DeviceInformation): void {
    this._deviceInformation.advice = deviceInfo.advice;
    this._deviceInformation.deviceId = deviceInfo.deviceId;
    this._deviceInformation.deviceType = deviceInfo.deviceType;
    this._deviceInformation.matchedRule = deviceInfo.matchedRule;
    this._deviceInformation.ruleAnnotation = deviceInfo.ruleAnnotation;
    this._deviceInformation.score = deviceInfo.score;
    this._deviceInformation.transactionId = deviceInfo.transactionId;
  }

  clearDeviceInformation(): void {
    this._deviceInformation = {};
  }

  challenge(request: MultiFactorRequest): ApiResponse<any> {
    return this.post('challenge', request);
  }

  requestMultifactorOtp(request: MultiFactorRequest): ApiResponse<any> {
    return this.post('otp', request);
  }

  evaluateCode(
    request: MultiFactorRequest,
    isRegistration: boolean = false
  ): ApiResponse<MultiFactorRequest> {
    request.advice = this._deviceInformation.advice;
    request.deviceId = this._deviceInformation.deviceId;
    request.deviceType = this._deviceInformation.deviceType;
    request.matchedRule = this._deviceInformation.matchedRule;
    request.ruleAnnotation = this._deviceInformation.ruleAnnotation;
    request.score = this._deviceInformation.score;
    request.transactionId = this._deviceInformation.transactionId;
    request.deviceSignature = this._deviceInformation.deviceSignature;

    return this.post(`authenticate/${isRegistration}`, request);
  }

  initializeDDNA(): void {
    this.caClient.processDNA();
    this._deviceInformation.deviceSignature = this.caClient.getDNA().replace(/\s/g, '');
    this._deviceInformation.deviceId = this.caClient.getDID();
  }

  setDID(did: string): void {
    this.caClient.setDID(did);
  }
}

export { MultifactorService };
