import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { UserProfileViewComponent } from '@app/user-profile/views';

export const userProfileLegacyModule = angular
  .module('udb.userProfile', ['ui.router'])
  .directive('appUserProfile', downgradeComponent({ component: UserProfileViewComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: ng.ui.IStateProvider) => {
      $stateProvider.state('udb.userProfile', {
        url: '/UserProfile/:view',
        views: {
          content: {
            template: '<app-user-profile></app-user-profile>',
          },
        },
      });
    },
  ]).name;
