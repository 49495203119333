import { ReceiptTransaction } from './receipt-transaction.model';

/** Represent the information displayed on transfer receipt. */
export class ReceiptTemplate {
  /** The template name. */
  type: string;
  /** The receipt title. */
  header: string;
  /** The receipt title. */
  title?: string;
  /** The receipt subtitle. */
  subtitle?: string;
  /** The receipt details template. */
  transactions: ReceiptTransaction[];
  /** The footnote text. */
  footnote = '';
  /** The table footnote text. */
  tableFootnote?: string;
  /** The navigation text. */
  navigation: string;
  /** The navigation back text. */
  navigationBack: string;
  /** Flag to show banner */
  showBanner: boolean;
  /** Flag to show buttons for IRAS */
  isIra: boolean;

  constructor(args?: Partial<ReceiptTemplate>) {
    Object.assign(this, args);
  }
}
