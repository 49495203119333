import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { sub } from 'date-fns';
import { format } from 'date-fns-tz';

import { FillTypeDescription, GoodUntilDescription, OrderType, TradeStockType } from '@app/axos-trading/enums';

import { Equity, OrderConfirmation, TradeOrderForm, TradingAccount } from '../models';

export function createTradeStockForm() {
  return new UntypedFormGroup({
    orderType: new UntypedFormControl('', [Validators.required]),
    shareAmount: new UntypedFormControl(0, [Validators.required, Validators.min(0.1)]),
    fillType: new UntypedFormControl(FillTypeDescription.Partial, [Validators.required]),
  });
}

export function changeToLimitStockForm(form: UntypedFormGroup) {
  form.addControl('limitPrice', new UntypedFormControl(0, [Validators.required, Validators.min(0.1)]));
  form.addControl('goodUntil', new UntypedFormControl(GoodUntilDescription.endOfDay, [Validators.required]));
}

export function changeToMarketStockForm(form: UntypedFormGroup) {
  form.removeControl('limitPrice');
  form.removeControl('goodUntil');
}
export function validateHourMarketPrice(today = new Date()) {
  const formatString = 'yyyy-MM-dd HH:mm:ss zzz';
  const timeZoneString = 'America/New_York';
  const firstHourEst = new Date(
    format(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 30, 0, 0), formatString, {
      timeZone: timeZoneString,
    })
  ).toISOString();
  const lastHourEst = new Date(
    format(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 16, 0, 0, 0), formatString, {
      timeZone: timeZoneString,
    })
  ).toISOString();
  const convertedHourToEst = new Date(
    format(new Date(today.toLocaleString('en-US', { timeZone: timeZoneString })), formatString, {
      timeZone: timeZoneString,
    })
  ).toISOString();

  return firstHourEst <= convertedHourToEst && lastHourEst >= convertedHourToEst;
}

export function createMutualFundForm(isBuy: boolean) {
  return new UntypedFormGroup({
    value: new UntypedFormControl(0, isBuy ? [Validators.required, Validators.min(0.1)] : null),
    quantity: new UntypedFormControl(0, !isBuy ? [Validators.required, Validators.min(0.1)] : null),
  });
}

export function equityFormToOrderConfirmation(
  formValue: TradeOrderForm,
  stock: Equity,
  tradeType: TradeStockType,
  account: TradingAccount
) {
  const order = new OrderConfirmation({
    stock: `${stock.stockName} (${stock.symbol.slice(0, 5)})`,
    orderTypeDescription: formValue.orderType,
    limitPrice: formValue.limitPrice,
    fillTypeDescription: formValue.fillType,
    quantity: formValue.shareAmount,
    marketPrice: stock.marketValue,
    goodUntilDescription: formValue.goodUntil,
    transactionTypeDescription: tradeType,
    accountNumber: account.accountNumber,
    symbol: stock.symbol,
    stockType: stock.type,
  });

  const totalCostStrategy = {
    [OrderType.Limit]: () => order.quantity * order.limitPrice,
    [OrderType.Market]: () => order.quantity * order.marketPrice,
  };

  order.totalCost = totalCostStrategy[order.orderType]();

  return order;
}

export function isWeekend(today: Date) {
  switch (today.getDay()) {
    case 6:
      today = sub(today, { days: 1 });
      break;
    case 0:
      today = sub(today, { days: 2 });
      break;
  }

  return today;
}
