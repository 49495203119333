import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RIAUserEffect } from '../support-view/support-view.effects';
import { selectRIAPilotFlag } from '../support-view/support-view.selectors';
import { ContactFirmsEffects } from './contact-firms.effects';
import { selectContactFirms, selectContactFirmsIsLoading } from './contact-firms.selectors';
import { RiaType } from '@app/Areas/AAS/aas-core/rias';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryAccount } from '@core/models';

@Injectable()
export class ContactFirmFacade {
  contactFirms$ = this.store.select(selectContactFirms);
  isLoading$ = this.store.select(selectContactFirmsIsLoading);
  isRIAPilotActive$ = this.store.select(selectRIAPilotFlag);
  axosAdvisoryAccounts$ = this.store.select(getAxosAdvisoryAccounts);

  constructor(private store: Store, private effects: ContactFirmsEffects, private riaUserEffects: RIAUserEffect) {}

  getContactFirms(rias: RiaType[], accounts: AxosAdvisoryAccount[]) {
    this.riaUserEffects.getRIAPilotFeatureFlag();
    this.effects.getUserContactFirms(rias, accounts);
  }
}
