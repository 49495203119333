import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';

export function accountAggregationChartFormatterServiceFactory($inject: any){
  return $inject.get('accAggChartFormatterService');
}

export const accountAggregationChartFormatterServiceProvider = {
  provide: AccountAggregationChartFormatterService,
  useFactory: accountAggregationChartFormatterServiceFactory,
  deps: ['$injector'],
};
