<div class="holding-list">
  <div class="header">
    <div class="title-container">
      <h2 class="title">
        Your Holdings
      </h2>
      <mat-icon *ngIf="holdingTileAccountState$?.holdings.length > 0" class="icon" [svgIcon]="alertsIcons.infoCircle"
        (click)="toggleUnderstandingHoldingsModal()"></mat-icon>
    </div>
    <uk2-menu-button
      *ngIf="holdingTileAccountState$?.sortOptions && holdingTileAccountState$.isSortingEnabled && holdingTileAccountState$?.holdings.length > 0"
      [uk2KeepLastSelection]="true" [uk2ButtonSize]="sortButtonSize" [uk2ButtonType]="singleSortButton"
      [uk2CSSPortalProperties]="customPortalCSSProperties$ | async"
      [uk2ItemList]="holdingTileAccountState$?.sortOptions" (uk2OnItemSelected)="selectSortingFilter($event)"
      [uk2SvgIconName]="icon" [uk2DefaultStateText]="defaultText" />
  </div>

  <div class="content" #contentSection (window:resize)="onResize()">
    <uk2-text-tile id="holdings-failed-message-container" *ngIf="holdingsFailedState$ | async" [uk2TextTileTitle]="uk2TextTileTitle"
      [uk2TextTileSvgIconName]="uk2TextTileSvgIconName" [uk2TextTileDisplayBackground]="uk2TextTileDisplayBackground"
      [uk2TextTileIsStandaloneTile]="uk2TextTileIsStandaloneTile">
      <p>We're sorry, but we're currently unable to update you with the latest information on your portfolio’s holdings.
        Please check back later. Thank you for your patience.</p>
    </uk2-text-tile>
    <div *ngIf="!(holdingsFailedState$ | async)">
      <ng-container *ngIf="holdingTileAccountState$?.holdings.length > 0; else emptyHoldingsState">
        <div *ngIf="holdingTileAccountState$">
          <ul class="items-list" *ngIf="holdingTileAccountState$.holdings">
            <li class="item" *ngFor="let holding of holdingTileAccountState$.holdings">
              <div>
                <div class="name-container">{{ holding.symbol || holding.cusip || '&nbsp;' }}</div>
                <div *ngIf="holding.symbol !== 'CASH'" class="shares-container">
                  <span class="shares-number">{{ getAmountFormat(holding.quantity) }}&nbsp;</span>
                  <span>{{ holding.quantity === 1 ? 'share' : 'shares' }}</span>
                </div>
              </div>
              <div *ngIf="!isMvp">
                <!-- graph -->
              </div>
              <div>
                <div class="price-marketValue_container">
                  ${{ getAmountFormat(getAmount(holding)) }}
                </div>
                <div class="value-movement-container">
                  <uk2-value-movement [amount]="toAbsolute(holding?.valueLastDayChangeAmount)"
                    [percentage]="toAbsolute(holding?.valueLastDayChangePercent)"
                    [valueMovementType]="getUk2ValueMovementTypeEnum(holding.valueLastDayChangePercent, holding.valueLastDayChangeAmount)">
                  </uk2-value-movement>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-template #emptyHoldingsState>
        <div class="no-holdings-banner">
          <div class="no-holdings-banner__img-wrapper">
            <img src="assets/img/icons/AAS/empty-holdings.svg" alt="empty holdings">
          </div>
          <h5>
            You currently don’t have any holdings in your portfolio. Once your advisor initiates your models, you’ll
            see them here.
          </h5>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<app-understanding-holdings-modal [showUnderstandingHoldingsModal]="showUnderstandingHoldingsModal"
  (hideUnderstandingHoldingsModal)="toggleUnderstandingHoldingsModal()"></app-understanding-holdings-modal>
