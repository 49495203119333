import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PortfolioAccountInformation } from '@app/axos-trading/models';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'trading/portfolio');
  }

  getPortfolioAccountInformation(accountNumber: string): Observable<PortfolioAccountInformation> {
    return this.get<PortfolioAccountInformation>(`information/${accountNumber}`).pipe(map(result => result.data));
  }
}
