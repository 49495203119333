import { SharedAccountsResponse } from '../..';

export type SharedAccountsStateType = {
  sharedAccounts: SharedAccountsResponse;
};
export const initialSharedAccountsStateType: SharedAccountsStateType = {
  sharedAccounts: {
    accounts: [],
    udbUserId: null,
  },
};
