import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

@Component({
  selector: 'how-to-request-external-transfer',
  templateUrl: './request-external-transfer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestExternalTransferComponent {
  constructor(@Inject(olbSettings) public settings: OlbSettings) {}
}
