import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { loadProviders } from '@app/accounts/store/actions';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { ExternalBankProvider } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService extends BaseService {
  static $token = 'newProviderService';

  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient, private store: Store) {
    super(http, settings, 'provider');
  }

  getProviders() {
    return this.get<ExternalBankProvider[]>().pipe(
      map(({ data }) => data),
      tap(payload => {
        this.store.dispatch(loadProviders({ payload }));

        return payload;
      })
    );
  }
}
