<div class="header-section-title text-center">
  <div class="mt-4">
    <span class="header__title">
      Direct Deposit
    </span>
    <p class="m-2 header__subtitle">{{ subtitle }}</p>
  </div>
  <div class="header-status" *ngIf="step!=='0'" [ngStyle]="{width: width}">
    <div
      class="header-status__bar"
      role="progressbar"
      aria-valuenow="5"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>
<div class="shared-back">
  <a class="shared-back__link" (click)="back.emit()">
    <app-svg-icon
      [iconName]="backIcon"
      [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
    ></app-svg-icon>
    <span class="shared-back__label">BACK</span>
  </a>
</div>
