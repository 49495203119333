import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

import { BrandPropertyService } from 'services/brand-property.service';
import { UtagService } from 'services/utag.service';

import { AuthStatus } from '@app/authentication/enums';
import { AuthRequest, AuthResponse, AuthSuccessResult } from '@app/authentication/models';
import { EnrollmentViewStateService } from '@app/enrollment/services';
import { AuthMethod } from '@core/enums';
import { MultiFactorRequest } from '@core/models';
import { MultifactorService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';

import { Inject } from '../decorators/decorators';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';

@Inject(
  '$state',
  '$window',
  '$location',
  'newmultifactorService',
  'serviceHelper',
  'env',
  '$scope',
  '$rootScope',
  'brandPropertyService',
  'cookieHelper',
  'utagService',
  'enrollmentViewStateService'
)
export class AuthController {
  public readonly USER_BUSINESSES = 'userBusinesses';
  credentials: AuthRequest;
  message = '';
  password: string;
  isNewPasswordValid = false;
  isBusy = false;
  loginState: number;
  showEmailBtn = true;
  showSmsBtn = true;
  brand: string;
  phoneNumber: string;
  redirectMobileApp: string;
  rememberDeviceHash: string;
  showRememberMyDevice = true;
  androidStoreLink: string;
  appleStoreLink: string;
  otp = '';
  loginFormSizeClass = 'fcol-lg-10';
  isOlbRegistered = false;
  private storage: Storage;
  private auth: AuthMethod;
  private authResponse: AuthResponse;

  userLastLogin: string;

  constructor(
    private state: ng.ui.IStateService,
    private window: ng.IWindowService,
    private location: ng.ILocationService,
    private multifactorService: MultifactorService,
    private serviceHelper: ServiceHelper,
    private env: any,
    private scope: ng.IScope,
    private root: ng.IRootScopeService,
    private brandPropertyService: BrandPropertyService,
    private cookieHelper: CookieHelper,
    private utagService: UtagService,
    private enrollmentViewStateService: EnrollmentViewStateService
  ) {
    this.rememberDeviceHash = null;

    this.scope.$on('$locationChangeStart', () => {
      clearStorage();
    });

    this.root.$on('$stateChangeStart', () => {
      clearStorage();
    });

    this.root.$on('$routeChangeStart', () => {
      clearStorage();
    });

    this.root.$on('stateChangeSuccess', () => {
      clearStorage();
    });

    this.window.onunload = () => {
      clearStorage();
    };

    this.window.onbeforeunload = () => {
      clearStorage();
    };

    const clearStorage = () => {
      localStorage.removeItem('username');
      localStorage.removeItem('attemps');
    };

    this.loginState = 1;
    this.isOlbRegistered = false;

    this.storage = window.sessionStorage;

    this.scope.$watch('vm.loginState', () => {
      this.serviceHelper.scrollToTop();
    });
  }

  /** Initializes any required data */
  $onInit(): void {
    this.credentials = {
      userName: '',
      password: '',
    };

    this.checkExperienceProfile();
    this.clearStorage();
    this.brand = String(this.env.brand);
    this.loadBrandProperties(this.env.brandId, this.brand);

    if (this.root['brandProperties']) {
      this.callTealium();
    } else {
      this.scope.$on('brandPropertiesLoaded', () => {
        this.callTealium();
      });
    }
    const { brand } = this.env;

    if (['axos', 'ufb'].includes(brand)) {
      this.loginFormSizeClass = 'fcol-lg-9';
    }
  }

  // this sets the agree terms key in local storage to true.

  checkExperienceProfile(): void {
    const skipFirstVisit = this.location.search().skipfv;

    const firstVisit = localStorage.getItem('firstVisit');

    if (skipFirstVisit === 'true') {
      if (!firstVisit) {
        localStorage.setItem('firstVisit', '0');
      }
      this.state.go('auth.login');

      return;
    }

    const agreeTerms = localStorage.getItem('agreeTerms');

    // if the agree terms has not been submitted user will go back to enrollment.
    // local storage key is set in the agree terms module as true once the user submits
    if (!agreeTerms) {
      this.state.go('auth.enrollment', { version: 'full' });
    }
  }

  loginResultsUpdater(loginResults: AuthSuccessResult) {
    this.loginState = loginResults.loginState;
    this.showEmailBtn = loginResults.showEmailBtn;
    this.showSmsBtn = loginResults.showSmsBtn;
    this.credentials.userName = loginResults.userName;
    this.isOlbRegistered = loginResults.isOlbRegistered;
    this.showRememberMyDevice = loginResults.isOlbRegistered && loginResults.hasSecurityQuestions;
    if (loginResults.hasGoogleAuth) {
      this.auth = AuthMethod.GoogleAuth;
    }
  }

  signUp(): void {
    this.state.go('auth.enrollment', { version: 'full' });
  }

  goBack(): void {
    if (this.auth === AuthMethod.GoogleAuth) {
      this.loginState -= 2;
    } else {
      this.loginState -= 1;
    }
  }

  /**
   * Gets new password validity
   * @param isValid Validity of new password.
   */
  getNewPasswordValidity(isValid: boolean) {
    this.isNewPasswordValid = isValid;
  }

  /**
   * Triggers the server to send an OTP.
   * @param type Specifies the way the user will receive the OTP.
   */
  codeSent(type: AuthMethod): void {
    this.isBusy = true;
    this.auth = type;

    this.message = '';

    const request: MultiFactorRequest = {
      username: this.credentials.userName,
      authenticationMethod: type,
      challengeToken: this.getSignInFlowToken(),
    };
    const isEmail = request.authenticationMethod === AuthMethod.Email;

    this.multifactorService
      .requestOtp(request)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: res => {
          this.rememberDeviceHash = res.data.userHash;
          this.setSignInFlowToken(res.data.authenticationToken);
          this.storage.setItem('authRequest', JSON.stringify(request));
          this.otp = !isEmail && res.data.otp ? res.data.otp : '';

          setTimeout(() => {
            $('#access-code-entry').focus();
          }, 200);
          this.loginState = 3;
        },
        error: () => {
          if (isEmail) {
            this.showEmailBtn = false;
          } else {
            this.serviceHelper.errorHandler.bind(this.serviceHelper);
          }
          this.serviceHelper.errorHandler.bind(this.serviceHelper);
        },
      });
  }

  /**
   * Validates the code against CA.
   * @param code OTP that the user entered.
   * @param remember Specifies if the device should be remembered.
   */
  codeValidated(code: string, remember: boolean): void {
    this.isBusy = true;
    this.message = '';
    const request: MultiFactorRequest = {
      username: this.credentials.userName,
      otp: code,
      authenticationToken: this.getAuthenticationToken(),
      rememberDevice: remember,
      authenticationMethod: this.auth,
    };

    if (remember) {
      this.multifactorService.setDID(this.multifactorService.getDeviceInformation().deviceId);
    }

    this.userLastLogin = this.storage.getItem('lastLogin');

    this.multifactorService.evaluateOtp(request).subscribe({
      next: res => {
        this.authResponse = res.data;
        const token =
          this.authResponse.authInfoLiteToken === '00000000-0000-0000-0000-000000000000'
            ? this.enrollmentViewStateService.authLiteToken
            : this.authResponse.authInfoLiteToken;
        const maskedCellPhone = this.storage.getItem('maskedCellPhone');
        const maskedEmail = this.storage.getItem('maskedEmail');

        this.storage.clear();
        this.storage.setItem('maskedCellPhone', maskedCellPhone);
        this.storage.setItem('maskedEmail', maskedEmail);

        sessionStorage.setItem('isSBBAvailable', res.data.isSBBAvailable.toString());
        if (res.data.accountProfiles && res.data.accountProfiles.length > 0) {
          sessionStorage.setItem(this.USER_BUSINESSES, JSON.stringify(res.data.accountProfiles));
        }
        if (!this.authResponse.isOlbRegistered) {
          this.redirectToTermsAndConditions(token);

          return;
        }

        if (this.authResponse.enrollmentCompleted) {
          this.setSessionStorageVariables();
        } else {
          this.loginState = 4;
          this.onLogin(res);
        }

        if (this.authResponse.enrollmentCompleted) {
          this.redirectToDashboard();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.isBusy = false;
        if (err.status >= 500) {
          this.serviceHelper.errorHandler(err, true);
        } else {
          this.message =
            err.error.message ||
            'Something went wrong.Something unexpected went wrong on our end. Please try again. Thank you.';
        }
      },
    });
  }

  /** Handles the security questions success event. */
  securityQuestionsAnswered() {
    this.setSessionStorageVariables();
    this.redirectToDashboard();
  }

  redirectToMobileApp(): void {
    window.location.href = this.redirectMobileApp;
  }

  isGoogleAuth(): boolean {
    return this.auth === AuthMethod.GoogleAuth;
  }

  /**
   * Handles the login attempt response.
   * @param res The response from the server.
   */
  private onLogin(res: OlbResponse<AuthResponse>): void {
    this.authResponse = res.data;
    this.setSessionStorageVariables();
    if (res.data.authStatus === AuthStatus.Temporary) {
      $('#temporaryPasswordChange').modal('show');
    }
  }

  /** Sets the variables into the session storage. */
  private setSessionStorageVariables(): void {
    let expirationToken = new Date();
    expirationToken = new Date(expirationToken.getTime() + this.authResponse.tokenExpires * 1000);
    this.storage.setItem('username', this.credentials.userName);
    this.storage.setItem('expires', expirationToken.getTime().toString());
    sessionStorage.setItem('fundRemembered', 'false');
    sessionStorage.setItem('userbrand', this.authResponse.brandId.toString());
    sessionStorage.setItem(
      'userFacingBrand',
      this.cookieHelper.getUserFacingBrand(this.authResponse.brandId).toString()
    );
    // Add hasAxosInvest flag to root scope so we can use it in the dashboard
    sessionStorage.setItem('hasAxosInvest', this.authResponse.hasAxosInvest.toString());
    // Add userSubType so we can use it
    sessionStorage.setItem(
      'userSubType',
      this.authResponse.userSubType ? this.authResponse.userSubType.toString() : '0'
    );
  }

  /** Sets the href to the root url. */
  private redirectToDashboard(): void {
    this.state.go('udb.dashboard');
  }

  private getSignInFlowToken(): string {
    return this.storage.getItem('SignInFlowToken');
  }

  private getAuthenticationToken(): string {
    return this.storage.getItem('AuthenticationToken');
  }

  private setSignInFlowToken(token: string): void {
    this.storage.setItem('AuthenticationToken', token);
  }

  /** Get the brand-specific values for phones, footer links, among others */
  private loadBrandProperties(brandId: number, brand: string) {
    this.brandPropertyService.getBrandProperties(brandId, brand).subscribe(() => {
      this.phoneNumber = this.root['brandProperties']['Contact_PhoneNumber'];
      this.androidStoreLink = this.root['brandProperties']['AndroidStoreLink'];
      this.appleStoreLink = this.root['brandProperties']['AppleStoreLink'];
      this.root.$broadcast('brandPropertiesLoaded');
      this.mobileCheck();
    });
  }

  private mobileCheck(): void {
    const checkisMobile = {
      Android() {
        return navigator.userAgent.match(/Android/i);
      },
      iOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      isMobile() {
        return checkisMobile.Android() || checkisMobile.iOS();
      },
    };
    if (checkisMobile.isMobile()) {
      if (checkisMobile.iOS()) this.redirectMobileApp = this.appleStoreLink;
      else if (checkisMobile.Android()) this.redirectMobileApp = this.androidStoreLink;
      $(document).ready(function () {
        $('#mobileDialog').modal('show');
      });
    }
  }

  private clearStorage(): void {
    localStorage.removeItem('username');
    localStorage.removeItem('attemps');
  }

  private redirectToTermsAndConditions(authLiteToken: string): void {
    this.state.go('auth.enrollment', {
      version: 'lite',
      id: authLiteToken,
      redirect: true,
    });
  }

  private callTealium(): void {
    this.utagService.script_src = this.root['brandProperties'].TealiumURL;
    this.utagService.generateScript('Tealium');
    this.generateInteractionStudioScript();
  }

  private generateInteractionStudioScript(): void {
    const interactionStudioUrls = this.env.interactionStudioUrls;
    const baseISurl = interactionStudioUrls.split(',')[0];
    const isSiteKeyUrl = interactionStudioUrls.split(',')[1];

    const d = document;
    const f = d.getElementsByTagName('script')[0];
    const p = f.parentNode;

    if (baseISurl != null) {
      const x = d.createElement('link');

      x.rel = 'preconnect';
      x.href = `${baseISurl}`;

      p.insertBefore(x, f);
    }

    if (isSiteKeyUrl != null) {
      const y = d.createElement('link');
      const z = d.createElement('script');

      y.rel = 'preload';
      y.href = `${isSiteKeyUrl}`;
      y.as = 'script';
      z.id = 'thxTag';
      z.src = `${isSiteKeyUrl}`;

      p.insertBefore(y, f);
      p.insertBefore(z, f);
    }
  }
}
