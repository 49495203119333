import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

import { STATE } from '@core/tokens';

import { BILLPAYROUTERHELPER, billPayServiceProvider } from '@app/bill-pay/ajs-upgraded-provider';
import { Recipient } from '@app/bill-pay/typings';
import { BillPayRouterHelper } from '@app/bill-pay/utils';

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: ['./details-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class DetailsTabComponent implements OnInit {
  @Input() recipient: Recipient;
  showAccount: boolean;
  detailsTooltip: string;
  constructor(
    @Inject(BILLPAYROUTERHELPER) private readonly _billPayRouterHelper: BillPayRouterHelper,
    @Inject(STATE) private readonly _stateService: ng.ui.IStateService
  ) {}

  ngOnInit(): void {
    this.detailsTooltip = `
    <strong>Electronic recipients</strong>
    are paid instantly. We'll withdraw  the payment the day before your payment is due. Your recipient will receive your payment on its due date.
    <br><br>
    <strong>Check recipients</strong>
    are paid with paper checks. We mail checks 5 business days before your payment's due date. Your money will leave your account when the recipient deposits the check.
    `;
  }

  toggleAccount() {
    this.showAccount = !this.showAccount;
  }

  goToEdit() {
    this._billPayRouterHelper.setRecipientDetails(this.recipient);
    this._stateService.go('udb.billPay.editRecipient');
  }
}
