import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { IStateParamsService, IStateService } from 'angular-ui-router';
import { PopupsHelper } from 'services/popups.helper';
import { UploadService } from 'services/upload.service';
import { FileHelperService as FileHelper } from '@app/core/services/file.service';

import { FacingBrand } from '@core/enums';
import { OlbSettings } from '@core/models';

import { MessagesSharedService } from '@legacy/messages/messages.shared.service';
import { STATE, STATE_PARAMS, olbSettings } from '@core/tokens';
import { DatePipe } from '@angular/common';
import { MessagesService } from '@legacy/services/messages.service';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MessageDetailComponent implements OnInit {
  title: string;
  status = this.stateParamsService['status'];
  id = this.stateParamsService['caseId'];
  read = this.stateParamsService['hasread'];
  case: string;
  loading = false;
  messages: string[] = [];
  attachments: string[] = [];
  url: string;
  createDate = localStorage.getItem('cdate');
  avatar: string;
  reopen_answer: string;
  messageText: string;
  counter: number;
  counterCss: string;
  dropboxVisible: boolean;
  documents: SupportFile[] = [];
  errorMessage: string;
  validMessage: boolean;
  isSending: boolean;
  isClosing: boolean;
  alert: string;
  downloading = false;
  brand: string;
  popups: IPopups;
  fileHelper: FileHelper;
  subheader = 'All of your communication with the bank lives here.';

  private avatarPath = '/img/icons/avatars';

  isCollapsed: boolean = true;

  constructor(
    @Inject(STATE) private stateService: IStateService,
    @Inject(STATE_PARAMS) private stateParamsService: IStateParamsService,
    public dp: DatePipe,
    public messagesService: MessagesService,
    public messagesSharedService: MessagesSharedService,
    public readonly serviceHelper: ServiceHelper,
    public uploadService: UploadService,
    @Inject(olbSettings) private env: OlbSettings
  ) {
    this.popups = new PopupsHelper();
    this.fileHelper = new FileHelper();
  }

  ngOnInit(): void {
    this.brand = String(this.env.brand);
    this.loading = true;
    this.messagesService
      .getMessageDetail(this.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(response => {
        this.case = response.data.caseNumber;
        this.title = response.data.subject;
        this.messages = response.data.messages;
        this.attachments = response.data.attachments;
      }, this.serviceHelper.errorHandler);

    this.url = `${this.env.api}/Messages/attachment?caseId=`;
    this.avatar = this.getAvatarPath(this.env.brand);
    this.counter = 0;
    this.counterCss = 'counter';
    this.dropboxVisible = false;
    this.validMessage = true;
    this.isSending = false;
    this.isClosing = false;
    if (this.read === 'false') {
      this.setDate();
    }

    if (this.env.facingBrandId === FacingBrand.Axos) {
      this.subheader = 'A secure location within your Axos account for communications with us.';
    }
  }

  /** Sets the filename to show in the template. */
  setName(file: string, size: string): string {
    if (file.length >= 20) {
      file = `${file.substr(0, 7)}...${file.substr(file.length - 6)}`;
    }

    return `${file} | ${size}K`;
  }

  /** Sets the avatar's image to show in the template.  */
  setImage(index: boolean): string {
    return `${this.avatar}${index ? 'Dark' : 'Light'}.svg`;
  }

  /** Sets the message to show in the template. */
  setCollapse(msg: string): string {
    if (msg.length >= 40) {
      msg = `${msg.substr(0, 40)}...`;
    }

    return msg;
  }

  /** Returns to the messages page. */
  goMessages(): void {
    this.stateService.go('udb.messages.inbox');
  }

  /** Get the icon in SVG format depending on the fileExtension */
  getSvgIcon(fileExtension: number): string {
    // TODO: add all missing icon formats when we have the svg
    switch (fileExtension) {
      case 2:
      case 8:
        return 'icn_document_word.svg';
      case 3:
      case 7:
        return 'icn_document_excel.svg';
      case 4:
      case 5:
        return 'icn_document_jpg_png.svg';
      case 9:
        return 'icn_document_web.svg';
      default:
        return 'icn_document_pdf.svg';
    }
  }

  /** Get the number of characters typed in the message box */
  getLength() {
    if (this.messageText != null) {
      this.counter = this.messageText.length;
      this.validMessage = true;

      return;
    }

    this.counter = 0;
    this.validMessage = false;
  }

  /** Hides the error message */
  closeAlert() {
    this.errorMessage = '';
  }

  /** Closes the alert message */
  close(): void {
    this.alert = null;
  }

  /** Hides/shows the area to ulpoad files */
  toggleDropbox() {
    this.dropboxVisible = !this.dropboxVisible;
    this.errorMessage = '';
  }

  /** Submits a new message. */
  submit() {
    if (!this.messageText) {
      this.validMessage = false;
      this.isSending = false;

      return;
    }

    if (this.fileHelper.FilesSizeExceeded(this.documents)) {
      this.popups.showAlert('', this.fileHelper.ExceededMessage, 'warning');

      return;
    }

    if (this.status == 'Closed') {
      this.messagesService.updateMessageStatus(this.id, 'ReOpened').subscribe(() => {
        this.status = 'Re-opened';
        this.isClosing = false;
      });
      this.reopen_answer = 'close';
    }

    this.isSending = true;

    const docs = [];
    const docNames = [];
    for (const doc of this.documents) {
      docs.push(doc.Document);
      docNames.push(doc.Name);
    }

    const data = {
      caseId: this.id,
      message: this.messageText,
      caseNumber: this.case,
      file: docs,
      fileFormDataName: docNames,
    };

    this.uploadService
      .upload('messages/addTask', data)
      .then(() => {
        this.alert = 'Your message has been sent.';
        this.messageText = '';
        this.messagesService
          .getMessageDetail(this.id)
          .pipe(
            finalize(() => {
              this.serviceHelper.scrollToTop();
            })
          )
          .subscribe(response => {
            this.messages = response.data.messages;
            this.attachments = response.data.attachments;
          }, this.serviceHelper.errorHandler);
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isSending = false;
        this.documents = [];
      });
  }

  /**
   * Gets the attachment from the server.
   * @param fileName The name of the file.
   * @param fileId Id of the attachment.
   */
  downloadAttachments(fileName: string, fileId: string) {
    this.downloading = true;

    const onFinally = () => {
      this.downloading = false;
    };

    this.messagesService.getAttachment(this.id, fileName, fileId, onFinally);
  }

  /** Sets the user's activity date. */
  public setDate(): void {
    const act: UserActivity = {
      caseId: this.id,
      timeStamp: this.dp.transform(new Date(), 'MM/dd/yyyy hh:mm:ss'),
    };
    this.messagesService.userActivity(act).subscribe(() => {
      this.messagesSharedService.callUnreadMessagesCount(); // Update the counter after marking the message as read
    }, this.serviceHelper.errorHandler);
  }

  public getAvatarPath(envBrand: string): string {
    let brand = '';
    switch (envBrand.toLowerCase()) {
      case 'axos':
        brand = 'axos';
        break;
      case 'nationwide':
        brand = 'nationwide';
        break;
      case 'ufb':
        brand = 'ufb';
        break;
      default:
        brand = 'axos';
        break;
    }

    return `${this.avatarPath}/${brand}_`;
  }
  public matPanelOpened(index: number, last) {
    if (last) {
      angular.element((document.getElementById('matDesc' + index).style.visibility = 'hidden'));
    }
    angular.element('#matExpansionPanel' + index).removeClass('back-color');
  }
  public matPanelClosed(index: number, last) {
    if (last) {
      document.getElementById('matDesc' + index).removeAttribute('hidden');
      angular.element((document.getElementById('matDesc' + index).style.visibility = 'visible'));
    }
    angular.element('#matExpansionPanel' + index).addClass('back-color');
  }
  public checkFormStatus(): void {}
}
