import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { openClose } from '@app/user-profile/animations';
import { UserProfileIcons, UserProfileViews } from '@app/user-profile/enums';
import { updateProfileIcon } from '@app/user-profile/store/actions';
import { getProfileIconDetails } from '@app/user-profile/store/selectors';
import { UserProfileService } from '@core/services';
import { STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-change-icon-view',
  templateUrl: './change-icon-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './change-icon-view.component.scss'],
  animations: [openClose],
})
export class ChangeIconViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  expanded = false;
  isMobileDevice = false;
  isBusy = false;
  // Colour names got from https://chir.ag/projects/name-that-color
  availableColors = [
    { color: '#f44337', name: 'Pomegranate' },
    { color: '#f13182', name: 'Violet Red' },
    { color: '#ab42bd', name: 'Amethyst' },
    { color: '#7442d1', name: 'Purple Heart' },
    { color: '#4052b5', name: 'San Marino' },
    { color: '#2297f5', name: 'Dodger Blue' },
    { color: '#02bdd6', name: "Robin's Egg Blue" },
    { color: '#03a0a1', name: 'Persian Green' },
    { color: '#00ae75', name: 'Jade' },
    { color: '#94c01e', name: 'Citron' },
    { color: '#dfa918', name: 'Dixie' },
    { color: '#eb7225', name: 'Tango' },
  ];
  availableIcons = [
    { icon: UserProfileIcons.Volleyball, label: 'Voleyball' },
    { icon: UserProfileIcons.Football, label: 'Football' },
    { icon: UserProfileIcons.Basketball, label: 'Basketball' },
    { icon: UserProfileIcons.Baseball, label: 'Baseball' },
    { icon: UserProfileIcons.Bowling, label: 'Bowling ball' },
    { icon: UserProfileIcons.Soccer, label: 'Soccer ball' },
    { icon: UserProfileIcons.Golf, label: 'Golf clubs' },
    { icon: UserProfileIcons.Dance, label: 'Dance shoes' },
    { icon: UserProfileIcons.Scuba, label: 'Snorkel' },
    { icon: UserProfileIcons.Bike, label: 'Bike' },
    { icon: UserProfileIcons.Guitar, label: 'Guitar' },
    { icon: UserProfileIcons.Island, label: 'Island' },
    { icon: UserProfileIcons.Beer, label: 'Beer' },
    { icon: UserProfileIcons.Headphones, label: 'Headphones' },
    { icon: UserProfileIcons.Videogames, label: 'Videogames controller' },
    { icon: UserProfileIcons.SmileyFace, label: 'Smiley face' },
    { icon: UserProfileIcons.Windsurf, label: 'Windsurf' },
    { icon: UserProfileIcons.Dog, label: 'Dog' },
    { icon: UserProfileIcons.Cat, label: 'Cat' },
    { icon: UserProfileIcons.Elephant, label: 'Elephant' },
    { icon: UserProfileIcons.Toucan, label: 'Toucan' },
    { icon: UserProfileIcons.Horse, label: 'Horse' },
    { icon: UserProfileIcons.Monkey, label: 'Monkey' },
  ];

  selectedColor = this.availableColors[0].color;
  selectedIcon: UserProfileIcons | 'initials' = 'initials';
  initials = '';
  showAlert = false;

  private subsink = new SubSink();
  private originalColour: string;
  private originalIcon: UserProfileIcons | 'initials';

  constructor(
    private store: Store,
    @Inject(STATE) public $state: ng.ui.IStateService,
    private breakpointObserver: BreakpointObserver,
    private userProfileService: UserProfileService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileIconDetails)
      .pipe(filter(details => details !== null))
      .subscribe(details => {
        this.initials = details.initials;
        this.selectedColor = this.originalColour = details.selectedColor;
        this.selectedIcon = this.originalIcon = details.selectedIcon;
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goToSignInAndSecurity(): void {
    this.$state.go('udb.userProfile', { view: UserProfileViews.SignInAndSecurity });
    this.expanded = false;
    this.selectedColor = this.originalColour;
    this.selectedIcon = this.originalIcon;
  }

  changeIcon(newIcon: UserProfileIcons | 'initials'): void {
    this.selectedIcon = newIcon;
  }

  changeColor(newColor: string): void {
    this.selectedColor = newColor;
  }

  submitInformation(): void {
    this.isBusy = true;
    forkJoin([
      this.userProfileService.changeBackgroundIcon(this.selectedIcon),
      this.userProfileService.changeBackgroundColor(this.selectedColor),
    ])
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: () => {
          this.showAlert = true;

          const payload = {
            backgroundColor: this.selectedColor,
            backgroundIcon: this.selectedIcon,
          };

          window.scrollTo(0, 0);
          this.store.dispatch(updateProfileIcon({ payload }));
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }
}
