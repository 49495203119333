import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { finalize, map } from 'rxjs/operators';

import { ForecastItem, ForecastParams, ForecastProjection, Goal } from '@app/axos-invest/models';
import { AxosInvestService } from '@app/axos-invest/services';
import { calculateProjectionValues, getRiskScoreDescription } from '@app/axos-invest/utils';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss'],
})
export class ForecastComponent implements OnInit, OnChanges {
  @Input() goal: Goal;
  @Input() forecastParameters: ForecastParams;

  forecast: ForecastItem[];
  projection: ForecastProjection;
  portfolios: {
    stocks: number;
    bonds: number;
    cash: number;
  };
  riskDescription: string;
  isForecastLoading = true;
  isPortfolioLoading = true;

  constructor(private axosInvestService: AxosInvestService) {}

  ngOnInit() {
    this.getForecast();
    this.getPortfolio();
    this.getRiskScore();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes.forecastParameters;
    if (change && change.currentValue && !change.firstChange) {
      this.getForecast();
    }
  }

  private getForecast() {
    this.isForecastLoading = true;
    this.forecast = null;
    this.projection = null;
    if (this.forecastParameters.autoDepositAmount > 0) {
      this.axosInvestService
        .getMilestoneForecast(this.goal.id, this.forecastParameters)
        .pipe(
          finalize(() => (this.isForecastLoading = false)),
          map(response => response.data)
        )
        .subscribe(result => {
          this.forecast = result.total.totals;
          this.projection = calculateProjectionValues(this.forecast);
        });
    } else {
      this.isForecastLoading = false;
    }
  }

  private getPortfolio() {
    this.axosInvestService
      .getPortfolio(this.goal.id)
      .pipe(
        map(response => response.data),
        finalize(() => (this.isPortfolioLoading = false))
      )
      .subscribe(result => {
        this.portfolios = {
          stocks: Math.floor(result.client.stocks.target),
          cash: Math.floor(result.client.cash.target),
          bonds: Math.floor(result.client.bonds.target),
        };
      });
  }

  private getRiskScore() {
    const riskScore = parseFloat(this.goal.allocation.allocationModel);
    this.riskDescription = getRiskScoreDescription(riskScore);
  }
}
