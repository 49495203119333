import { ButtonsRange } from '@app/inbox/types';

export class SelectableButtonsRange {
  static readonly defaultSelectableButtonRange: ButtonsRange = {
    start: 0,
    end: 0,
  };
  static calculateSelectableButtonsRange(size: number, currentPosition: number, buttonsToRender: number): ButtonsRange {
    if (
      SelectableButtonsRange.isNotANumber(size) ||
      size <= 0 ||
      SelectableButtonsRange.isNotANumber(currentPosition) ||
      currentPosition > size - 1 ||
      SelectableButtonsRange.isNotANumber(buttonsToRender) ||
      buttonsToRender <= 0
    ) {
      return SelectableButtonsRange.defaultSelectableButtonRange;
    }
    return SelectableButtonsRange.rangeDetector(size, currentPosition, buttonsToRender);
  }

  static rangeDetector(size: number, currentPosition: number, buttonsToRender: number): ButtonsRange {
    // check for odds so we can calculate the offset
    const isOdd = SelectableButtonsRange.isOdd(buttonsToRender);
    // if the number is odd the number of buttons on each side of the current selected button is even
    const missingButtonsToRender = buttonsToRender - 1;
    const offset = Math.floor(buttonsToRender / 2);
    const preProcessedStartWithOffset: number = currentPosition - offset;
    const preProcessedEndWithOffset: number = currentPosition + offset;
    const preProcessedStartWithoutOffset: number = currentPosition - missingButtonsToRender;
    const preProcessedEndWithoutOffset: number = currentPosition + missingButtonsToRender;
    const fitsLeft: boolean = preProcessedStartWithOffset >= 0; // offset must not go over 0
    const fitsRight: boolean = preProcessedEndWithOffset <= size - 1; // offset must not go over the total size of the list
    const fitsAllButtonsLeft: boolean = preProcessedStartWithoutOffset >= 0; // check if all buttons fit on the left of the current selected item
    const fitsAllButtonsRight: boolean = preProcessedEndWithoutOffset <= size - 1; // check if all buttons fit on the right of the current selected item
    if (fitsLeft && fitsRight) {
      return {
        start: currentPosition - offset,
        end: currentPosition + offset + (isOdd ? 1 : 0), // adding + 1 since slice excludes end index depending if the number is odd or not
      };
    }
    // for user when it's on the start of the range of buttons
    else if (!fitsLeft && fitsAllButtonsRight) {
      const extraOffsetToRight = Math.abs(preProcessedStartWithOffset);
      const start: number = 0;
      const end: number = currentPosition + offset + extraOffsetToRight + (isOdd ? 1 : 0); // adding + 1 since slice excludes end index depending if the number is odd or not
      return {
        start,
        end,
      };
    }
    // for user when it's on the end of the range of buttons
    else if (fitsAllButtonsLeft && !fitsRight) {
      const extraOffsetToLeft = preProcessedEndWithOffset - size;
      const start: number = currentPosition - Math.abs(offset) - Math.abs(extraOffsetToLeft) - (isOdd ? 1 : 0); // adding - 1 since slice excludes end index depending if the number is odd or not
      const end: number = size;
      return {
        start,
        end,
      };
    } else {
      return {
        start: 0,
        end: size,
      };
    }
  }
  static isNotANumber(possibleNumber: unknown): boolean {
    return !(typeof possibleNumber === 'number');
  }

  static isOdd(number: number): boolean {
    return !!(number & 1);
  }
}
