import { of } from 'rxjs';

import { Inject } from '../decorators/decorators';
import { IAxosClearingService } from './typings/IAxosClearingService';

@Inject('axosClearingService', 'env')
export class AxosClearingUrlHelper {
  private orbisUrl: string;
  private orbisUrlMobile: string;

  constructor(private axosClearingService: IAxosClearingService, private env: OlbSettings) {
    this.orbisUrl = this.env.orbisUrl;
    this.orbisUrlMobile = this.env.orbisUrlMobile;
  }

  async getOrbisUrl(accountNumber: string, responsive = false): Promise<any> {
    const referrerToken = await this.getReferrerToken();
    const url = responsive ? this.orbisUrlMobile : this.orbisUrl;
    return `${url}/axos.sso.action?referrer=axos_olb&referrer_token=${referrerToken}&accountNumber=${accountNumber}`;
  }

  private async getReferrerToken(): Promise<string> {
    const result = await this.axosClearingService.getSsoToken();

    return of(result.data).toPromise();
  }
}
