import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import {
  AddBeneficiariesComponent,
  BeneficiariesComponent,
  BeneficiaryFormComponent,
  EditBeneficiariesComponent,
  ListBeneficiariesComponent,
} from '@app/beneficiaries/components';

export const beneficiariesLegacyModule = angular
  .module('udb.beneficiaries', ['ui.router'])
  .directive('appBeneficiaries', downgradeComponent({ component: BeneficiariesComponent }))
  .directive('appAddBeneficiaries', downgradeComponent({ component: AddBeneficiariesComponent }))
  .directive('appBeneficiaryForm', downgradeComponent({ component: BeneficiaryFormComponent }))
  .directive('appEditBeneficiaries', downgradeComponent({ component: EditBeneficiariesComponent }))
  .directive('appListBeneficiaries', downgradeComponent({ component: ListBeneficiariesComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider
        .state('udb.beneficiaries', {
          url: '/Beneficiaries',
          views: {
            content: {
              template: '<app-beneficiaries></app-beneficiaries>',
            },
          },
        })
        .state('udb.beneficiaries.add', {
          url: '/AddBeneficiaries/{accountId}',
          views: {
            content: {
              template: '<app-add-beneficiaries></app-add-beneficiaries>',
            },
          },
        })
        .state('udb.beneficiaries.form', {
          url: 'Beneficiaries/beneficiary-form',
          views: {
            content: {
              template: '<app-beneficiary-form></app-beneficiary-form>',
            },
          },
        })
        .state('udb.beneficiaries.edit', {
          url: '/EditBeneficiary',
          views: {
            content: {
              template: '<app-edit-beneficiaries></app-edit-beneficiaries>',
            },
          },
        })
        .state('udb.beneficiaries.list', {
          url: 'Beneficiaries/list-beneficiaries',
          views: {
            content: {
              template: '<app-list-beneficiaries></app-list-beneficiaries>',
            },
          },
        });
    },
  ]).name;
