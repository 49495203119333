import * as angular from 'angular';
import { WireMultiFactorConfig } from './wire-multi-factor.config';
import { WireMultiFactorController } from './wire-multi-factor.controller';
import { WireAccessCodeController } from '../access-code/wire-access-code.controller';
import { wireAccessCode } from '../access-code/wire-access-code';

export const WireMultiFactor = angular
  .module('udb.WireMultiFactor', [])
  .controller('WireMultiFactorController', WireMultiFactorController)
  .controller('WireAccessCodeController', WireAccessCodeController)
  .component('udbWireAccessCode', wireAccessCode)
  .config(WireMultiFactorConfig)
  .name;
