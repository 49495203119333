import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { CategoryEventData, SubCategoryEventData } from '@app/messages/models';
import { STATE_PARAMS } from '@core/tokens';
import { IStateParamsService } from 'angular-ui-router';

@Component({
  selector: 'app-cm-subcategories',
  templateUrl: './cm-subcategories.component.html',
  styleUrls: ['./cm-subcategories.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CmSubcategoriesComponent implements OnInit, OnDestroy {
  public categoryId: number;
  public categoryName: string;

  @Input() category: CategoryEventData;
  @Output()
  public composeMessageEvent = new EventEmitter<SubCategoryEventData>();

  @Output()
  public showSDTMessageEvent = new EventEmitter<boolean>();
  public businessId: string = '';

  constructor(@Inject(STATE_PARAMS) private stateParams: IStateParamsService) {}
  ngOnDestroy(): void {
    this.showSDTMessageEvent.emit(false);
  }
  ngOnInit(): void {
    if (this.stateParams !== undefined) {
      this.categoryId = this.stateParams['categoryId'];
      this.categoryName = this.stateParams['categoryName'];
      this.businessId = this.stateParams['businessId'];
    } else {
      this.categoryId = this.category.categoryId;
      this.categoryName = this.category.categoryName;
      this.businessId = this.category.businessId;
    }

    if (this.stateParams.cName === 'Self-Directed Trading') {
      this.showSDTMessageEvent.emit(true);
    }
  }

  /**
   * Goes to the compose message section
   * @param subCategoryId SubCategory Id
   * @param subCategoryName SubCategory Name
   */
  public navigateTo(subCategoryId: number, subCategoryName: string): void {
    let subcategory: SubCategoryEventData = {
      subCategoryName: subCategoryName,
      subCategoryId: subCategoryId,
      categoryId: this.categoryId,
      categoryName: this.categoryName,
      businessId: this.businessId,
    };
    this.composeMessageEvent.emit(subcategory);
  }
}
