/**
 * Account type enum
 *
 * @export
 * @enum {number}
 */
export enum AccountType {
  DemandDeposit,
  Savings,
  TimeDeposit,
  LineOfCredit,
  Loan,
  GeneralLedger,
  ChristmasClub,
  SafeDeposit,
  AxosInvest,
  AxosTrading,
}
