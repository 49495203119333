export const ColorPickerComponent: ng.IComponentOptions = {
  controller: 'ColorPickerController',
  controllerAs: '$colorPicker',
  templateUrl: 'tiles/account-distribution/color-picker/color-picker.tpl.html',
  bindings: {
    color: '<',
    onColorChange: '&',
    onOpenPicker: '&',
    accountId: '<',
  },
};
