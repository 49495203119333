<div class="transfer-funds">
  <app-dot-loader [showWhen]="!errorMessage && !isBalancesAvailable">
    Loading accounts
  </app-dot-loader>
  <app-alert-notification
    class="transfer-funds__alert"
    *ngIf="errorMessage"
    [safeHtml]="sanitizedHtmlError"
    (dismiss)="errorMessage = null"
    alertType="error"
  ></app-alert-notification>

  <form name="transferForm" #transferForm="ngForm" role="form" id="transferForm" *ngIf="step === 1">
    <div class="frow center">
      <span class="text-error" [textContent]="errorAmount"></span>
    </div>

    <!-- FROM ACCOUNT -->
    <div class="row form-group">
      <div class="col-md-12">
        <label for="fromAcc" class="uk-label">Transfer From</label>
        <div class="fix-dropdown">
          <select
            name="fromAcc"
            class="uk-input"
            aria-label="From Account"
            id="fromAcc"
            [(ngModel)]="selectedFromAccount"
            (ngModelChange)="fromAccountChange()"
            [ngClass]="{ red: !selectedFromAccountValid }"
            [disabled]="!isManagedPortfoliosEnabled && selectedToAccount?.isAxosInvest"
          >
            <option [value]="null" selected>
              {{ isLoadingFromAcc ? 'Loading...' : 'Select an account' }}
            </option>
            <optgroup [attr.label]="key" *ngFor="let key of fromAccountsUniqueTypes">
              <option *ngFor="let acc of fromAccountsDictionary[key]" [ngValue]="acc">
                {{ acc.displayName }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="text--error" [hidden]="selectedFromAccountValid">
          Please select From Account
        </div>
        <div class="text--warning" [hidden]="!(selectedFromAccount?.isExternal && !fundingState?.isRunning)">
          Money from this account can only be transferred to Axos Personal Account.
        </div>
        <div class="ira-info" *ngIf="showIRAWithdrawalDisclaimer">
          <div class="ira-info__header">
            There may be a penalty for withdrawing from this account.
            <a class="link" (click)="toggleIRAPenaltyInfo = !toggleIRAPenaltyInfo">
              Learn more
              <i class="bofi-arrow-chevron display-more-arrow" [ngClass]="{ after: toggleIRAPenaltyInfo }"></i>
            </a>
          </div>

          <div class="ira-info__body" [ngClass]="{ hidden: !toggleIRAPenaltyInfo }">
            Your withdrawal may be subject to a 10% additional tax because you are under age 59&frac12;. Consult with
            your tax professional to understand your specific tax obligations.
          </div>
        </div>
        <div class="space-top offset-md-1" *ngIf="transferFromRetirementMp?.length > 0">
          <div>
            <label class="iraMpDisclosure">Retirement Account Type</label>
          </div>
          <div *ngIf="transferFromRetirementMp.length === 1">
            <span class="uk-label space-top">
              {{ transferFromRetirementMp[0].name }} (Total Val:
              {{ transferFromRetirementMp[0].accountValue | currency }})
            </span>
          </div>
          <div *ngIf="transferFromRetirementMp.length > 1">
            <div *ngFor="let transferMp of transferFromRetirementMp">
              <input
                type="radio"
                id="{{ transferMp.accountNumber }}"
                class="radio"
                name="{{ transferMp.milestoneId }}"
                [value]="transferMp"
                [(ngModel)]="transferMpValue"
                (ngModelChange)="validateRetirementAccount()"
              />
              <label for="{{ transferMp.accountNumber }}" class="uk-label">
                {{ transferMp.name }} (Total Val: {{ transferMp.accountValue | currency }})
              </label>
            </div>
            <div class="space-top" *ngIf="displayWithdrawalPenaltyDisclaimerMp">
              <h5>Withdrawal Penalty Disclaimer</h5>
              <div class="space-top">
                <label>
                  There may be a penalty for withdrawal from this account.
                </label>
                <a class="link svg-icon-link" (click)="displayWithdrawalPdMore = !displayWithdrawalPdMore">
                  Learn More
                  <app-svg-icon *ngIf="displayWithdrawalPdMore" [iconName]="icons.arrowUp"></app-svg-icon>
                  <app-svg-icon *ngIf="!displayWithdrawalPdMore" [iconName]="icons.arrowDown"></app-svg-icon>
                </a>
              </div>
              <div *ngIf="displayWithdrawalPdMore" class="disclosureMp__text col-md-12">
                Your withdrawal may be subject to a 10% additional tax if you are under the age of 59½. Consult with
                your tax professional to understand your specific tax obligations.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showWithdrawals" class="col-md-12">
        <span class="uk-label space-top">{{ withdrawalMsg }}</span>
      </div>
    </div>

    <!-- TO ACCOUNT -->
    <div class="row form-group" [hidden]="!isManagedPortfoliosEnabled && selectedFromAccount?.isAxosInvest">
      <div class="col-md-12">
        <label for="toAcc" class="uk-label">Transfer To</label>
        <div class="fix-dropdown">
          <select
            name="toAcc"
            class="uk-input"
            id="toAcc"
            aria-label="To Account"
            [(ngModel)]="selectedToAccount"
            (ngModelChange)="toAccountChange()"
            [ngClass]="{ red: !selectedToAccountValid }"
            [disabled]="!selectedFromAccount"
          >
            <option [value]="null" selected>
              {{ isLoadingFromAcc ? 'Loading...' : 'Select an account' }}
            </option>
            <optgroup [attr.label]="key" *ngFor="let key of toAccountsUniqueTypes">
              <option *ngFor="let acc of toAccountsDictionary[key]" [ngValue]="acc">
                {{ acc.displayName }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="text--error" [hidden]="selectedToAccountValid">
          Please select To Account
        </div>
        <span *ngIf="TransferFromErrors?.hasError">
          {{ TransferFromErrors.errorDescription }}
        </span>
      </div>
      <div *ngIf="showWithdrawalsTo" class="col-md-12">
        <span class="uk-label space-top">{{ withdrawalMsgTo }}</span>
      </div>
      <div class="space-top offset-md-1" *ngIf="transferToRetirementMp?.length > 0">
        <div>
          <label class="iraMpDisclosure">Retirement Account Type</label>
        </div>
        <div *ngIf="transferToRetirementMp?.length === 1">
          <span class="uk-label space-top">
            {{ transferToRetirementMp[0].name }} (Total Val: {{ transferToRetirementMp[0].accountValue | currency }})
          </span>
        </div>
        <div *ngIf="transferToRetirementMp?.length > 1">
          <div *ngFor="let transferMp of transferToRetirementMp">
            <input
              type="radio"
              id="{{ transferMp.accountNumber }}"
              class="radio"
              name="{{ transferMp.milestoneId }}"
              [value]="transferMp"
              [(ngModel)]="transferMpValue"
              (ngModelChange)="validateRetirementAccount()"
            />
            <label for="{{ transferMp.accountNumber }}" class="uk-label">
              {{ transferMp.name }} (Total Val: {{ transferMp.accountValue | currency }})
            </label>
          </div>
          <div class="space-top" *ngIf="displayWithdrawalPenaltyDisclaimerMp">
            <h5>Withdrawal Penalty Disclaimer</h5>
            <div class="space-top">
              <label>
                There may be a penalty for withdrawal from this account.
              </label>
              <a class="link svg-icon-link" (click)="displayWithdrawalPdMore = !displayWithdrawalPdMore">
                Learn More
                <app-svg-icon *ngIf="displayWithdrawalPdMore" [iconName]="icons.arrowUp"></app-svg-icon>
                <app-svg-icon *ngIf="!displayWithdrawalPdMore" [iconName]="icons.arrowDown"></app-svg-icon>
              </a>
            </div>
            <div *ngIf="displayWithdrawalPdMore" class="disclosureMp__text col-md-12">
              Your withdrawal may be subject to a 10% additional tax if you are under the age of 59½. Consult with your
              tax professional to understand your specific tax obligations.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Contribution details hyperlink -->
    <div class="row form-group" *ngIf="selectedToAccount?.isIra">
      <div class="col-md-6">
        <div class="text-left" (click)="showContributionDetailsModal()">
          <button type="button" class="link contribution-details">
            <app-svg-icon class="mr-1" size="1rem" [iconName]="icons.question"></app-svg-icon>
            Contribution Details
          </button>
        </div>
      </div>
    </div>
    <!-- AMOUNT TO TRANSFER-->
    <div *ngIf="showAmount && !selectedToAccount?.isLoan" class="row form-group">
      <!-- Contribution Year-->
      <div class="col-md-6 form-group" *ngIf="isTransferToIraTrading()">
        <label for="cyear" class="uk-label">Contribution Year</label>
        <select
          name="cyear"
          class="uk-input"
          aria-label="Contribution Year"
          id="cyear"
          (ngModelChange)="setContributionYearChange()"
          [(ngModel)]="cyear"
        >
          <option *ngFor="let cy of contributionsYears" [value]="cy">
            {{ cy.year }}
          </option>
        </select>

        <p class="text-left" *ngIf="cyear">
          Contributions for this year ends on {{ cyear.endDate | date: 'MM/dd/yyyy' }}
        </p>
        <p class="text-left" *ngIf="isTransferToIraSupportedAccounts()">
          You have contributed {{ haveContributed | currency }}
        </p>
      </div>

      <div class="col-md-6">
        <app-transfer-amount
          (money)="setAmount($event)"
          [isRunning]="fundingState?.isRunning"
          [isInvalid]="!amountValid || amountErrors?.hasError"
          [initAmount]="fundingState?.depositAmount"
          [isContributedAmountReached]="isContributedAmountReached"
        ></app-transfer-amount>

        <p class="text-left" *ngIf="isTransferToIraTrading()">
          Maximum contribution amount for {{ cyear.year }} is
          {{ getMaxAmounttradingIra() | currency }}
        </p>
        <p class="text-left" *ngIf="isTransferToIraSupportedAccounts()">
          {{ contributionReachedMessage }}
        </p>
        <p class="uk-label" [ngClass]="{ 'text-error': !amountValid }" *ngIf="showTradingTransferLimit()">
          Maximum one time Transfer limit is {{ showTradingTransferLimit() | currency }}
        </p>

        <p class="text--error text-left" *ngIf="amountErrors?.hasError">
          {{ amountErrors.errorDescription }}
        </p>
        <div
          *ngIf="
            settingUpIncomingTransfer &&
            customerLimits.extTransfersMaxIncomingPerPayment &&
            !selectedToAccount?.isAxosInvest &&
            !selectedToAccount?.isTrading &&
            !selectedToAccount?.isRia
          "
        >
          <p class="text-center">
            Amount may not exceed
            <span [textContent]="customerLimits.extTransfersMaxIncomingPerPayment | currency"></span>
          </p>
        </div>
      </div>
    </div>

    <div
      class="row form-group"
      [hidden]="
        selectedToAccount?.isLoan ||
        (!isManagedPortfoliosEnabled && (selectedFromAccount?.isAxosInvest || selectedToAccount?.isAxosInvest))
      "
    >
      <!-- FREQUENCY -->
      <div class="col-md-6">
        <label for="frequency" class="uk-label">Frequency</label>
        <select
          name="frequency"
          class="uk-input"
          id="frequency"
          aria-label="frequency"
          [(ngModel)]="selectedFrequency"
          (ngModelChange)="frequencyChange()"
          [ngClass]="{ red: !selectedFrequencyValid }"
          [disabled]="
            transferIsBetweenMilestones ||
            isTransferToIraTrading() ||
            isTransferToIra() ||
            isTransferToRiaNonIra() ||
            frequencySelectDisabled
          "
        >
          <option value="" disabled selected></option>
          <option *ngFor="let freq of frequencies" [value]="freq">
            {{ freq.label }}
          </option>
        </select>
        <div class="text--error" [hidden]="selectedFrequencyValid">
          Please select Frequency
        </div>
        <p class="text-left" *ngIf="isTransferToIraTrading() || isTransferToIra()">
          Frequency not available with IRA transfers.
        </p>
      </div>

      <!-- DATE-->
      <div class="col-md-6 mt-4 mt-md-0">
        <label for="date" class="uk-label" *ngIf="isRecurrent">Date</label>
        <label for="date" class="uk-label" *ngIf="!isRecurrent">Send Request On</label>
        <i
          class="bofi-datepicker icon-input--inner"
          (click)="
            selectedFromAccount?.Type === 'Investment Accounts' ||
              selectedToAccount?.Type === 'Investment Accounts' ||
              dateInput.click()
          "
        ></i>
        <input
          #dateInput
          appDateRangePicker
          [options]="datePickerOptions"
          (dateApplied)="datePickerDateSelected($event)"
          type="text"
          name="date"
          id="date"
          aria-label="date"
          class="uk-input icon-input--inner"
          readonly
          [value]="datePickerDate | date: 'MM/dd/yy'"
          [disabled]="transferIsBetweenMilestones || isTransferToIraTrading() || isTransferToIra()"
        />
        <span
          class="error-message"
          *ngIf="
            (selectedFromAccount?.isExternal || selectedToAccount?.isExternal) &&
            !isValidIraTransaction &&
            displayCutOffDisclaimer
          "
          [textContent]="externalMessage"
        >
          {{ externalMessage }}
        </span>
      </div>
    </div>

    <!-- CONTRIBUTION YEAR-->
    <div class="row form-group" *ngIf="retirementIraSelected && selectedToAccount?.isAxosInvest">
      <div class="col-md-12">
        <label for="contributionYear" class="uk-label">Contribution Year</label>
        <select
          name="contributionYear"
          class="uk-input"
          id="contributionYear"
          aria-label="contributionYear"
          [(ngModel)]="selectedContributionYear"
          (ngModelChange)="contributionYearChange()"
          *ngIf="previousContributionYear"
          [ngClass]="{ red: !contributionYearIsValid }"
        >
          <option [ngValue]="" disabled selected>Select Contribution Year</option>
          <option [ngValue]="currentContributionYear.year">
            {{ currentContributionYear.year }} (Total Contribution: ${{ currentContributionYearLimit }})
          </option>
          <option [ngValue]="previousContributionYear.year">
            {{ previousContributionYear.year }} (Total Contribution: ${{ previousContributionYearLimit }})
          </option>
        </select>
        <div class="text--error" *ngIf="previousContributionYear && !contributionYearIsValid">
          Select a tax contribution year
        </div>
        <p class="contribution-year-readonly" *ngIf="!previousContributionYear">
          {{ currentContributionYear.year }} (Total Contribution: ${{ currentContributionYearLimit }})
        </p>
      </div>
    </div>

    <div class="ira-info ira-distribution" *ngIf="showIRADistributionDisclaimer">
      <div class="ira-info__header">
        <h5 class="ira-info__header-title">IRA Distribution</h5>
        Estimated {{ currentYear }} Required Minimum Distribution amount
        {{ selectedFromAccount?.requiredMinimumDistributionAmount | currency }}.
        <a class="link" (click)="toggleIRADistributionInfo = !toggleIRADistributionInfo">
          Learn more
          <i class="bofi-arrow-chevron display-more-arrow" [ngClass]="{ after: toggleIRADistributionInfo }"></i>
        </a>
      </div>
      <div class="ira-info__body" [ngClass]="{ hidden: !toggleIRADistributionInfo }">
        Once you reach age 70&frac12;, withdrawals from a non-Roth IRA account are required by the IRS. We have provided
        an estimated Required Minimum Distribution (RMD) amount based on your Axos account balances. Consult with your
        tax professional to understand your specific tax obligations.
      </div>
    </div>

    <div class="row form-group" [hidden]="!isRecurrent || selectedToAccount?.isLoan">
      <!-- SEND UNTIL -->
      <div class="col-md-6">
        <label for="sendUntil" class="uk-label">Send Until</label>
        <select
          name="sendUntil"
          id="sendUntil"
          aria-label="sendUntil"
          [(ngModel)]="selectedSendUntil"
          (ngModelChange)="sendUntilChange()"
          [ngClass]="{ red: sendUntilErrors?.hasError }"
          [disabled]="sendUntilDisabled"
          class="uk-input"
        >
          <option value="" disabled selected></option>
          <option *ngFor="let opt of sendUntilOptions" [value]="opt">
            {{ opt.label }}
          </option>
        </select>
        <span class="text--error" *ngIf="sendUntilErrors?.hasError">
          {{ sendUntilErrors?.errorDescription }}
        </span>
      </div>

      <!-- NUMBER OF TRANSFERS -->
      <div class="col-md-6" *ngIf="showNumberTransfers">
        <label for="numberTransfers" class="uk-label">Number Of Transfers</label>
        <input
          type="text"
          name="numberTransfers"
          aria-label="numberTransfers"
          id="numberTransfers"
          class="uk-input"
          [(ngModel)]="numberTransfers"
          (ngModelChange)="validateNumberOfTransfers()"
          [ngClass]="{ red: numberTransfersErrors?.hasError }"
          maxlength="3"
        />
        <span class="text--error" *ngIf="numberTransfersErrors?.hasError">
          {{ numberTransfersErrors?.errorDescription }}
        </span>
      </div>

      <div class="col-md-6 mt-4 mt-md-0" *ngIf="isLastTransferDate">
        <label for="date" class="uk-label">End on</label>
        <i class="bofi-datepicker icon-input--inner"></i>
        <input
          #endDateInput
          appDateRangePicker
          [options]="datePickerEndDateOptions"
          (dateApplied)="datePickerEndDateSelected($event)"
          type="text"
          name="date"
          id="date"
          aria-label="date"
          class="uk-input icon-input--inner"
          readonly
          [value]="datePickerEndDate | date: 'MM/dd/yyyy'"
        />
      </div>
    </div>

    <div class="form-group" *ngIf="displayTaxImplicationsDisclaimer">
      <input type="checkbox" id="chkMpWithdrawal" name="acceptDisclosureMp" [(ngModel)]="acceptDisclosureMp" />
      <label for="chkMpWithdrawal">
        I have read and understand the potential tax implications of this withdrawal.
      </label>
      <a class="link svg-icon-link" (click)="readDisclosureMp = !readDisclosureMp">
        Read Disclosure
        <app-svg-icon *ngIf="readDisclosureMp" [iconName]="icons.arrowUp"></app-svg-icon>
        <app-svg-icon *ngIf="!readDisclosureMp" [iconName]="icons.arrowDown"></app-svg-icon>
      </a>

      <div *ngIf="readDisclosureMp" class="disclosureMp__text col-md-12">
        <h5>Withholding From Pensions and Annuities</h5>
        <br />
        Withholding From Pensions and Annuities Generally, federal income tax withholding applies to the taxable part of
        payments made from pension, profit-sharing, stock bonus, annuity, and certain deferred compensation plans; from
        individual retirement arrangements (IRAs); and from commercial annuities. The method and rate of withholding
        depend on (a) the kind of payment you receive; (b) whether the payments are delivered outside the United States
        or its commonwealths and possessions; and (c) whether the recipient is a nonresident alien individual, a
        nonresident alien beneficiary, or a foreign estate. Qualified distributions from a Roth IRA are nontaxable and,
        therefore, not subject to withholding.
        <br />
        <br />

        Because your tax situation may change from year to year, you may want to refigure your withholding each year.
        <br />
        <br />

        <b>Choosing not to have income tax withheld.</b>
        You (or in the event of death, your beneficiary or estate) can choose not to have federal income tax withheld
        from your payments. For an estate, the election to have no income tax withheld may be made by the executor or
        personal representative of the decedent.
        <br />
        <br />

        <b>Caution:</b>
        There are penalties for not paying enough federal income tax during the year, either through withholding or
        estimated tax payments. New retirees, especially, should see Pub. 505. It explains your estimated tax
        requirements and describes penalties in detail. You may be able to avoid quarterly estimated tax payments by
        having enough tax withheld from your pension or annuity using Form W-4P.
        <br />
        <br />
        <b>Nonperiodic payments—10% withholding.</b>
        Your payer must withhold at a flat 10% rate from nonperiodic payments unless you choose not to have federal
        income tax withheld. Distributions from an IRA that are payable on demand are treated as nonperiodic payments.
        Generally, your choice not to have federal income tax withheld will apply to any later payment from the same
        plan.
      </div>
    </div>

    <div class="form-group" *ngIf="displayIraDisclosure || isTransferToIraTrading() || isTransferToIra()">
      <label class="iraMpDisclosure">IRA Disclosure</label>
      <a class="link" (click)="readIRaDisclosureMp = !readIRaDisclosureMp">
        Read Disclosure
        <i class="bofi-arrow-chevron display-more-arrow" [ngClass]="{ after: readIRaDisclosureMp }"></i>
      </a>
      <div *ngIf="readIRaDisclosureMp" class="disclosureMp__text col-md-12">
        I certify that all information provided by me is true and accurate and that Axos and/or any of its agents have
        not provided me with tax advice. I understand that Axos cannot determine my contribution eligibility and that
        only I can determine my contribution eligibility. I confirm to Axos that I am eligible to make the contribution
        as documented on this form. I hereby direct Axos to accept my contribution as documented on this form and
        understand that I am solely responsible for the consequences of this action.
        <br />
        <br />
      </div>
      <div class="space-top">
        <input type="checkbox" id="chkMpIra" name="acceptIraDisclosureMp" [(ngModel)]="acceptIraDisclosureMp" />
        <label for="chkMpIra">
          I certify that all information provided is accurate and that I understand the potential implications of this
          deposit.
        </label>
      </div>
    </div>

    <div class="frow form-group" *ngIf="isValidIraTransaction && showAmount">
      <h4>Federal Income Tax Withholding.</h4>

      <div (click)="showFITW()" class="transfer-funds-terms-fitw__link">
        <label class="cursor-pointer">Learn more</label>
        <i class="bofi-arrow-chevron display-more-arrow" [ngClass]="{ after: isShowFITW }"></i>
      </div>
      <div *ngIf="isShowFITW && !isTransferFromIraTrading()" class="transfer-funds-terms-fitw__label">
        Because a withdrawal from this account may be taxable, we recommend federal income tax be withheld. The
        withholding amount will be subtracted from your transaction amount and sent to the IRS. The remaining (net)
        amount will be transferred to your designated account.
      </div>
      <div *ngIf="isShowFITW && isTransferFromIraTrading()" class="transfer-funds-terms-fitw__label">
        Because a withdrawal from this account may be taxable, if you elect to withhold federal income tax, please
        specify the amount to withhold from this transaction below. The withholding amount will be subtracted from your
        transaction amount and sent to the IRS. The remaining (net) amount will be transferred to your designated
        account.
      </div>
      <div class="fcol-md-12 transfer-funds__taxes-options">
        <div>
          <input
            type="radio"
            id="withholdPercentage"
            name="withholding"
            [(ngModel)]="withholding"
            [value]="0"
            (ngModelChange)="calculateWithholdValue()"
          />
          <label class="transfer-funds__taxes-options__radio-label" for="withholdPercentage">
            Percentage amount to withhold for taxes.
          </label>
          <app-tooltip content="Must be at least 10% of withdrawal amount"></app-tooltip>
          <div class="fcol-md-6">
            <app-percentage-amount
              [name]="'withholdPercentageValue'"
              [minValue]="10"
              [maxValue]="99"
              [percentage]="withholdPercentageValue"
              [isDisabled]="withholding != 0"
              (percentageChange)="setPercentage($event)"
              (percentageBlur)="calculateWithholdValue()"
            ></app-percentage-amount>
            <div
              class="text--error transfer-funds__taxes-options__tab"
              [hidden]="!(withholdPercentageValue < 10 && withholding === 0 && amount > 0)"
            >
              Must be at least 10% of withdrawal amount
            </div>
            <div
              class="text--error transfer-funds__taxes-options__tab"
              [hidden]="!(meetsMinValueAmount() && amount > 0)"
            >
              Minimum value for the dollar amount is $10
            </div>
          </div>
        </div>
        <div>
          <input
            type="radio"
            id="withholdAmount"
            name="withholding"
            [(ngModel)]="withholding"
            [value]="1"
            (ngModelChange)="calculateWithholdValue()"
          />
          <label class="transfer-funds__taxes-options__radio-label" for="withholdAmount">
            Dollar amount to withhold for taxes.
          </label>
          <app-tooltip content="Must be a minimum of $10"></app-tooltip>
          <div class="fcol-md-6">
            <input
              type="text"
              id="withholdAmountValue"
              class="uk-input"
              name="withholdAmountValue"
              [(ngModel)]="withholdAmountValue"
              [disabled]="withholding != 1"
              (blur)="calculateWithholdValue()"
              prefix="$"
              mask="separator.2"
              thousandSeparator=","
              separatorLimit="100000"
              [dropSpecialCharacters]="[',']"
              [allowNegativeNumbers]="false"
              minValue="10"
              [attr.maxValue]="amount"
            />
            <div
              class="text--error transfer-funds__taxes-options__tab"
              [hidden]="!(withholdAmountValue < 10 && withholding === 1 && amount > 0)"
            >
              Must be a minimum of $10
            </div>
          </div>
        </div>
        <div>
          <input
            type="radio"
            id="withholdFree"
            name="withholding"
            [(ngModel)]="withholding"
            [value]="2"
            (ngModelChange)="calculateWithholdValue()"
          />
          <label class="transfer-funds__taxes-options__radio-label" for="withholdFree">
            Do NOT withhold Federal Income Tax
          </label>
        </div>

        <!-- TRADING -->
        <div *ngIf="isTransferFromIraTrading() || isTransferFromRetirementRia()" class="form-group frow mt-4">
          <h4>State Income Tax Withholding.</h4>
          <div (click)="showSITW()" class="transfer-funds-terms-fitw__link">
            <label class="cursor-pointer">Learn more</label>
            <i class="bofi-arrow-chevron display-more-arrow" [ngClass]="{ after: isShowSITW }"></i>
          </div>
          <div *ngIf="isShowSITW" class="transfer-funds-terms-fitw__label">
            Your state may require you to withhold a portion of your withdrawal from this account. The withholding
            amount will be subtracted from the remaining (net) amount from above and sent to your state. The remaining
            (net) amount will be transferred to your designated account.
            <div class="mt-4">
              <button (click)="showStateModal()" class="link">
                Click here to view state withholding guidelines
              </button>
            </div>
          </div>

          <div class="fcol-md-12 transfer-funds__taxes-options">
            <div>
              <input
                type="radio"
                id="stateWithholdPercentage"
                name="stateWithhold"
                [(ngModel)]="stateWithhold"
                [value]="0"
                (ngModelChange)="calculateStateWithholdValue()"
              />
              <label class="transfer-funds__taxes-options__radio-label" for="stateWithholdPercentage">
                Percentage amount to withhold for taxes.
              </label>
              <app-tooltip content="Must be at least 1% of withdrawal amount"></app-tooltip>
              <div class="fcol-md-6">
                <app-percentage-amount
                  [name]="'stateWithholdPercentageValue'"
                  [minValue]="1"
                  [maxValue]="100"
                  [percentage]="stateWithholdPercentageValue"
                  [isDisabled]="stateWithhold != 0"
                  (percentageChange)="setStatePercentage($event)"
                  (percentageBlur)="calculateStateWithholdValue()"
                ></app-percentage-amount>
                <div
                  class="text--error"
                  [hidden]="!(stateWithholdPercentageValue < 1 && stateWithhold === 0 && amount > 0)"
                >
                  Must be at least 1% of withdrawal amount
                </div>
                <div
                  class="text--error transfer-funds__taxes-options__tab"
                  [hidden]="!(meetsMinValueStateAmount() && amount > 0)"
                >
                  Minimum value for the dollar amount is $1
                </div>
              </div>
            </div>
            <div>
              <input
                type="radio"
                id="statewithholdAmount"
                name="stateWithhold"
                [(ngModel)]="stateWithhold"
                [value]="1"
                (ngModelChange)="calculateStateWithholdValue()"
              />
              <label class="transfer-funds__taxes-options__radio-label" for="statewithholdAmount">
                Dollar amount to withhold for taxes.
              </label>
              <app-tooltip content="Must be a minimum of $1"></app-tooltip>
              <div class="fcol-md-6">
                <input
                  type="text"
                  id="stateWithholdAmountValue"
                  name="stateWithholdAmountValue"
                  class="uk-input"
                  [(ngModel)]="stateWithholdAmountValue"
                  [disabled]="stateWithhold != 1"
                  (blur)="calculateStateWithholdValue()"
                  prefix="$"
                  mask="separator.2"
                  thousandSeparator=","
                  separatorLimit="100000"
                  [dropSpecialCharacters]="[',']"
                  [allowNegativeNumbers]="false"
                  minValue="1"
                  [attr.maxValue]="amount"
                />
                <div
                  class="text--error"
                  [hidden]="!(stateWithholdAmountValue < 1 && stateWithhold === 1 && amount > 0)"
                >
                  Must be a minimum of $1
                </div>
              </div>
            </div>
            <div>
              <input
                type="radio"
                id="stateWithholdFree"
                name="stateWithhold"
                [(ngModel)]="stateWithhold"
                [value]="2"
                (ngModelChange)="calculateStateWithholdValue()"
              />
              <label class="transfer-funds__taxes-options__radio-label" for="stateWithholdFree">
                Do NOT withhold State Income Tax
              </label>
            </div>
          </div>
        </div>
        <hr />
        <label class="uk-label">Net Transfer Amount</label>
        <div class="transfer-funds__taxes-options__net-amount">
          <label>{{ withholdNetValue | currency }}</label>
        </div>
        <div class="text--error" [hidden]="!(withholdNetValue == 0)">
          Net amount may not be $0.
        </div>
        <div class="frow m-t-lg">
          <div class="fcol-md-12">
            <input type="checkbox" id="acceptDisclosure" name="acceptDisclosure" [(ngModel)]="acceptDisclosure" />
            <label for="acceptDisclosure">
              I have read and understand the potential tax implications of this withdrawal.
            </label>
          </div>
          <div (click)="showDisclosure()" class="fcol-md-12 transfer-funds-terms-fitw__link">
            <div>
              <label class="cursor-pointer">Read Disclosure.</label>
              <i class="bofi-arrow-chevron" [ngClass]="{ after: isDisclosure }"></i>
            </div>
          </div>
          <div *ngIf="isDisclosure" class="transfer-funds-terms-fitw__label">
            <h5>Withholding From Pensions and Annuities</h5>
            <br />

            Withholding From Pensions and Annuities Generally, federal income tax withholding applies to the taxable
            part of payments made from pension, profit-sharing, stock bonus, annuity, and certain deferred compensation
            plans; from individual retirement arrangements (IRAs); and from commercial annuities. The method and rate of
            withholding depend on (a) the kind of payment you receive; (b) whether the payments are delivered outside
            the United States or its commonwealths and possessions; and (c) whether the recipient is a nonresident alien
            individual, a nonresident alien beneficiary, or a foreign estate. Qualified distributions from a Roth IRA
            are nontaxable and, therefore, not subject to withholding.
            <br />
            <br />

            Because your tax situation may change from year to year, you may want to refigure your withholding each
            year.
            <br />
            <br />

            <b>Choosing not to have income tax withheld.</b>
            You (or in the event of death, your beneficiary or estate) can choose not to have federal income tax
            withheld from your payments. For an estate, the election to have no income tax withheld may be made by the
            executor or personal representative of the decedent.
            <br />
            <br />

            <b>Caution:</b>
            There are penalties for not paying enough federal income tax during the year, either through withholding or
            estimated tax payments. New retirees, especially, should see Pub. 505. It explains your estimated tax
            requirements and describes penalties in detail. You may be able to avoid quarterly estimated tax payments by
            having enough tax withheld from your pension or annuity using Form W-4P.
            <br />
            <br />
            <b>Nonperiodic payments—10% withholding.</b>
            Your payer must withhold at a flat 10% rate from nonperiodic payments unless you choose not to have federal
            income tax withheld. Distributions from an IRA that are payable on demand are treated as nonperiodic
            payments. Generally, your choice not to have federal income tax withheld will apply to any later payment
            from the same plan.
          </div>
        </div>
      </div>
    </div>
    <!-- AMOUNT OPTIONS WHEN TRANSFER TO OVERDRAFT LINE OF CREDIT -->
    <div class="row form-group" *ngIf="showAmountOptions">
      <!-- AMOUNT -->
      <div class="col-md-4">
        <div class="padding-boxes">
          <input
            type="radio"
            [value]="{ value: 3 }"
            [(ngModel)]="selectedAmountOption"
            (ngModelChange)="amountOptionsChange()"
            id="amount"
          />
          <label for="amount">Amount</label>
        </div>
        <input
          type="text"
          name="amount"
          id="amount"
          aria-label="amount"
          class="uk-input"
          (ngModelChange)="setValidationAmount()"
          [(ngModel)]="amount"
          [ngClass]="{ red: !amountValid || amountErrors?.hasError }"
          [disabled]="!amountEditable"
          prefix="$"
          mask="separator.2"
          thousandSeparator=","
          separatorLimit="100000"
          [dropSpecialCharacters]="[',']"
          [allowNegativeNumbers]="false"
        />
        <span class="text--error" *ngIf="amountErrors?.hasError">
          {{ amountErrors.errorDescription }}
        </span>
      </div>

      <!-- MINIMUM AMOUNT -->
      <div class="col-md-4" *ngIf="showMinimumAmount">
        <div class="padding-boxes">
          <input
            type="radio"
            [value]="{ value: 1 }"
            [(ngModel)]="selectedAmountOption"
            (ngModelChange)="amountOptionsChange()"
            id="minimumAmount"
          />
          <label for="minimumAmount">Minimum Amount</label>
        </div>

        <div class="text-center">
          {{ selectedToAccount?.minimumAmountDue | currency }}
          <span class="caption-small">
            ({{
              selectedToAccount?.principalAndInterest == null
                ? (0 | currency)
                : (selectedToAccount?.principalAndInterest | currency)
            }}
            Prin and Int)
          </span>
        </div>
      </div>

      <!-- OUTSTANDING BALANCE -->
      <div class="col-md-4" *ngIf="showOutstandingBalance">
        <div class="padding-boxes">
          <input
            type="radio"
            [value]="{ value: 2 }"
            [(ngModel)]="selectedAmountOption"
            (ngModelChange)="amountOptionsChange()"
            id="outstandingBalance"
          />
          <label for="outstandingBalance" class="label-form-upper">Outstanding Balance</label>
        </div>
        <div [textContent]="selectedToAccount?.outstandingBalance | currency" class="text-center"></div>
      </div>
    </div>

    <!-- AMOUNT OPTIONS WHEN TRANSFER TO LOAN -->
    <div class="row form-group" *ngIf="showLoanSection">
      <!-- REGULAR MONTHLY PAYMENT -->
      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="input-title">
          <div class="checkbox-wrapper">
            <input
              id="regMonthly"
              name="isRegularMonthly"
              type="checkbox"
              [(ngModel)]="isRegularMonthly"
              [disabled]="!enableRegularMonthly"
              (checked)="cleanupInput()"
            />
            <label for="regMonthly"></label>
          </div>
          <label for="regularMonthlyPayment" class="label-form-upper regular-label">
            Regular Monthly Payment
            <app-tooltip
              content="Your regular monthly payment includes principal and interest + escrow and insurance payment."
            ></app-tooltip>
          </label>
        </div>
        <div class="input-body">
          <input
            type="text"
            name="regularMonthlyPayment"
            aria-label="regularMonthlyPayment"
            id="regularMonthlyPayment"
            class="uk-input txt-space"
            [(ngModel)]="regularMonthlyPayment"
            [disabled]="true"
            ng-currency
          />
          <span class="symbol-mortgage">+</span>
        </div>
        <span class="regular-monthly">Due on 1st of month</span>
      </div>

      <!-- ADDITIONAL PRINCIPAL -->
      <div class="col-xs-6 col-sm-6 col-md-4">
        <div class="input-title">
          <label for="additionalPrincipal" class="label-form-upper">
            Additional Principal (Optional)
          </label>
          <app-tooltip
            content="Any amount entered here will be applied directly to your
            principal balance and will not be applied towards your Regular
            Payment."
          ></app-tooltip>
        </div>
        <div class="input-body">
          <input
            type="text"
            name="additionalPrincipal"
            id="additionalPrincipal"
            aria-label="additionalPrincipal"
            class="uk-input txt-space"
            [(ngModel)]="additionalPrincipal"
            [disabled]="(!isInternalSameDay || isRecurrent) && !isFromExternal"
            prefix="$"
            mask="separator.2"
            thousandSeparator=","
            separatorLimit="100000"
            [dropSpecialCharacters]="[',']"
            [allowNegativeNumbers]="false"
          />
          <span class="error-message" *ngIf="amountAdditionalErrors?.hasError">
            {{ amountAdditionalErrors.errorDescription }}
          </span>
          <span class="symbol-mortgage hidden-xs hidden-sm">=</span>
        </div>
      </div>

      <!-- TOTAL AMOUNT -->
      <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="input-title">
          <label for="outstandingBalance" class="label-form-upper center-on-sm">
            Total Amount
            <span class="hidden-md hidden-lg total-amount" [textContent]="': ' + (totalAmount | currency)"></span>
          </label>
        </div>
        <input
          type="text"
          name="additionalPrincipal"
          aria-label="additionalPrincipal"
          id="additionalPrincipal"
          class="hidden-xs hidden-sm uk-input txt-space"
          [ngModel]="totalAmount"
          disabled
          ng-currency
        />
        <span class="error-message" *ngIf="regularMonthlyErrors?.hasError">
          {{ regularMonthlyErrors.errorDescription }}
        </span>
      </div>
    </div>

    <!-- AMOUNT OPTIONS WHEN TRANSFER TO MORTGAGE -->
    <div class="row form-group" *ngIf="showMortgageSection">
      <!-- REGULAR MONTHLY PAYMENT -->
      <div class="col-xs-4 col-sm-4 col-md-3">
        <div class="input-title">
          <div class="checkbox-wrapper">
            <input
              id="regMonthly"
              name="isRegularMonthly"
              type="checkbox"
              [(ngModel)]="isRegularMonthly"
              [disabled]="!enableRegularMonthly"
              (checked)="cleanupInput()"
            />
            <label for="regMonthly"></label>
          </div>
          <label for="regularMonthlyPayment" class="label-form-upper regular-label">
            Regular Monthly Payment
            <app-tooltip
              content="Your regular monthly payment includes principal and
              interest + escrow and insurance payment."
            ></app-tooltip>
          </label>
        </div>
        <div class="input-body">
          <input
            type="text"
            name="regularMonthlyPayment"
            aria-label="regularMonthlyPayment"
            id="regularMonthlyPayment"
            class="uk-input txt-space"
            [(ngModel)]="regularMonthlyPayment"
            [disabled]="true"
            ng-currency
          />
          <span class="symbol-mortgage">+</span>
        </div>
        <span class="regular-monthly">Due on 1st of month</span>
      </div>

      <!-- ADDITIONAL PRINCIPAL -->
      <div class="col-xs-4 col-sm-4 col-md-3">
        <div class="input-title">
          <label for="additionalPrincipal" class="label-form-upper">
            Additional Principal (Optional)
          </label>
          <app-tooltip
            content="Any amount entered here will be applied directly to your
            principal balance and will not be applied towards your Regular
            Payment."
          ></app-tooltip>
        </div>
        <div class="input-body">
          <input
            type="text"
            name="additionalPrincipal"
            id="additionalPrincipal"
            aria-label="additionalPrincipal"
            class="uk-input txt-space"
            [(ngModel)]="additionalPrincipal"
            [disabled]="(!isInternalSameDay || isRecurrent) && !isFromExternal"
            prefix="$"
            mask="separator.2"
            thousandSeparator=","
            separatorLimit="100000"
            [dropSpecialCharacters]="[',']"
            [allowNegativeNumbers]="false"
          />
          <span class="error-message" *ngIf="amountAdditionalErrors?.hasError">
            {{ amountAdditionalErrors.errorDescription }}
          </span>
          <span class="symbol-mortgage">+</span>
        </div>
      </div>

      <!-- ESCROW -->
      <div class="col-xs-4 col-sm-4 col-md-3">
        <div class="input-title">
          <label for="escrow" class="label-form-upper">Escrow (Optional)</label>
          <app-tooltip
            content="Any amount entered here will be applied directly to your
            escrow balance and will not be applied towards your Regular
            Payment."
          ></app-tooltip>
        </div>
        <div class="input-body">
          <input
            type="text"
            name="escrow"
            id="escrow"
            aria-label="escrow"
            class="uk-input txt-space"
            [(ngModel)]="escrow"
            [disabled]="!isInternalSameDay || isRecurrent || isFromExternal"
            prefix="$"
            mask="separator.2"
            thousandSeparator=","
            separatorLimit="100000"
            [dropSpecialCharacters]="[',']"
            [allowNegativeNumbers]="false"
          />
          <span class="symbol-mortgage hidden-xs hidden-sm">=</span>
        </div>
      </div>

      <!-- TOTAL AMOUNT -->
      <div class="col-xs-12 col-sm-12 col-md-3">
        <div class="input-title">
          <label for="outstandingBalance" class="label-form-upper center-on-sm">
            Total Amount
            <span class="hidden-md hidden-lg total-amount" [textContent]="': ' + (totalAmount | currency)"></span>
          </label>
        </div>
        <input
          type="text"
          name="additionalPrincipal"
          aria-label="additionalPrincipal"
          id="additionalPrincipal"
          class="hidden-xs hidden-sm uk-input txt-space"
          [ngModel]="totalAmount"
          disabled
          ng-currency
        />
        <span class="error-message" *ngIf="regularMonthlyErrors?.hasError">
          {{ regularMonthlyErrors.errorDescription }}
        </span>
      </div>
    </div>
    <div class="form-group" *ngIf="isReadOnly">
      <span class="text-error">
        Current day transfers are not available while the system is in maintenance. Please schedule your transfer for a
        future date or try again later.
      </span>
    </div>
    <div class="row form-group frow center">
      <div class="fcol-cs-12 text-center" [ngClass]="{ 'funding-nav': fundingState?.isRunning }">
        <app-dot-loader [showWhen]="isBusy"></app-dot-loader>
        <button
          *ngIf="fundingState?.isRunning && !selectedFromAccount?.isAxosInvest"
          type="button"
          class="uk-btn lg outline primary"
          ui-sref="udb.funding"
        >
          Cancel Transfer
        </button>
        <button
          type="submit"
          class="uk-btn solid secondary lg"
          [disabled]="
            isBusy ||
            isReadOnly ||
            !amountValid ||
            isFromRIA ||
            isContributedAmountReached ||
            (isToRIA && isProductTypeNotCheckingOrSaving)
          "
          (click)="submit()"
        >
          <span
            *ngIf="
              !isBusy &&
              (isManagedPortfoliosEnabled || (!selectedToAccount?.isAxosInvest && !selectedFromAccount?.isAxosInvest))
            "
            [textContent]="buttonText"
          ></span>
          <span
            *ngIf="
              !isBusy &&
              !isManagedPortfoliosEnabled &&
              (selectedFromAccount?.isAxosInvest || selectedToAccount?.isAxosInvest)
            "
          >
            Continue
          </span>
          <span *ngIf="isBusy">Posting Transfer...</span>
        </button>
      </div>
    </div>
  </form>

  <div class="text-center" *ngIf="step == 2">
    <app-multifactor
      *ngIf="hasUserPhone"
      [supportPhone]="phoneNumber"
      [phone]="maskedCellPhone"
      (sendCode)="sendCode()"
      subtitle="We're about done.  To process your request, we are sending you a verification code to confirm your identity."
      title="We need to send you a verification code."
      [onlyPhone]="true"
    ></app-multifactor>
    <span *ngIf="hasUserPhone">
      Need Help? Call 1-888-502-2967
    </span>
    <p class="mt-3 text-danger" *ngIf="!hasUserPhone">
      For Security, we required that a phone number be listed on your account. Please add a phone number to your account
      in order to transfer funds.
    </p>
  </div>

  <div class="text-center" *ngIf="step == 3">
    <app-access-code
      [otp]="otp"
      [displayRememberDevice]="false"
      [resendOut]="true"
      [errorMessage]="accessCodeError"
      (back)="step = 1"
      (continue)="validateOtp($event.code)"
      (resendBack)="sendCode()"
      [displayPersonalInfo]="false"
    ></app-access-code>
  </div>

  <div class="text-center want-auto-deposit" *ngIf="isManagedPortfolioAccount" (click)="autoDepositMp = !autoDepositMp">
    <label class="iraMpDisclosure">
      Want to set up a recurring auto-deposit?
      <app-svg-icon class="want-auto-deposit__icon-top" *ngIf="autoDepositMp" [iconName]="icons.arrowUp"></app-svg-icon>
      <app-svg-icon
        class="want-auto-deposit__icon-top"
        *ngIf="!autoDepositMp"
        [iconName]="icons.arrowDown"
      ></app-svg-icon>
    </label>
    <div *ngIf="autoDepositMp" class="disclosureMp__text col-md-12">
      For managed portfolios accounts, you can set up recurring deposits by funding your goal from the Goal Summary
      page.
      <br />
      <br />
      <button class="link" (click)="redirectToRecurringDepositMp()">
        Set Up Recurring Deposit
        <app-svg-icon
          class="want-auto-deposit__icon-down"
          *ngIf="autoDepositMp"
          [iconName]="icons.arrowForward"
        ></app-svg-icon>
      </button>
    </div>
  </div>

  <div class="trading-disclaimer" *ngIf="isFromTrading || isFromRIA">
    <p>
      †Available to Withdrawal amount is based upon an approximation of the total available balance minus the total
      amount of all open purchase orders. This projected balance may or may not reflect the actual balances after each
      order execution due to price fluctuations and differences in amounts between the approximate total price of the
      original purchase order and the actual total amount due upon the execution of the purchase order. Before entering
      your request, please confirm the available to withdrawal amount by reviewing any purchase orders which may be open
      for your account.
    </p>
  </div>
</div>
