import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatLegacyDialogConfig } from '@angular/material/legacy-dialog';
import { OverdraftTermsConditionsComponent } from '@app/Areas/AAS/features/overdraft-credit-terms-conditions-modal/view/overdraft-credit-terms-conditions-modal';
import { Uk2Tier1FinancialEnum } from '@axos/uikit-v2-lib';
import { DialogService } from '@core/services';
import { DOCS_MODAL_WIDTH, DOCS_PANEL_CLASS } from '../../constants';
import { MatSelectionList } from '@angular/material/list';
import { InvestorCheckingAccountFeatures } from './enums';

@Component({
  selector: 'app-features-step',
  templateUrl: './features-step.component.html',
  styleUrls: ['./features-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturesStepComponent implements AfterViewInit {
  uk2Tier1FinancialIcons: any;
  @ViewChild('featuresSelectionList') featuresSelectionList: MatSelectionList;
  @Output() selectFeatures = new EventEmitter<InvestorCheckingAccountFeatures[]>();
  investorCheckingAccountFeatures = InvestorCheckingAccountFeatures;

  constructor(private dialogService: DialogService) {
    this.uk2Tier1FinancialIcons = Uk2Tier1FinancialEnum;
  }
  ngAfterViewInit(): void {
    this.featuresSelectionList.selectionChange.subscribe(_ => {
      this.selectFeatures.emit(this.featuresSelectionList.selectedOptions.selected.map(x => x.value));
    });
  }

  public openODLTCModal(): void {
    const config: MatLegacyDialogConfig = {
      width: DOCS_MODAL_WIDTH,
      panelClass: DOCS_PANEL_CLASS,
      disableClose: true,
    };

    this.dialogService.openByComponentRef(OverdraftTermsConditionsComponent, config);
  }
}
