import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum ActiveLink {
  Login = 'login',
  Register = 'register',
}

@Component({
  selector: 'app-auth-tabs',
  templateUrl: './auth-tabs.component.html',
  styleUrls: ['./auth-tabs.component.scss'],
})
export class AuthTabsComponent {
  @Input() hideLogin = false;
  @Input() set activeLink(value: ActiveLink) {
    if (value === ActiveLink.Login) {
      this.loginIsActive = true;
      this.registerIsActive = false;
    } else {
      this.loginIsActive = false;
      this.registerIsActive = true;
    }
  }

  @Output() login = new EventEmitter<void>();
  @Output() register = new EventEmitter<void>();

  loginIsActive = false;
  registerIsActive = true;

  goToLogin() {
    localStorage.setItem('agreeTerms', 'true');

    this.login.emit();

    this.activeLink = ActiveLink.Login;
  }

  goToRegister() {
    this.register.emit();

    this.activeLink = ActiveLink.Register;
  }
}
