import { Component, Inject, OnInit } from '@angular/core';

import NgRedux from 'ng-redux';

import { AccountChoice, Switch, SwitchAccountOption, SwitchAccountValue } from '@app/click-switch/models';
import { DialogService, DirectDepositService } from '@core/services';
import { ngRedux, STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { UtilityIcons } from '@shared/enums';

import { ClickSwitchBaseComponent } from '../click-switch-base/click-switch-base.component';

@Component({
  selector: 'app-click-switch-accounts-selection',
  templateUrl: './click-switch-accounts-selection.component.html',
  styleUrls: ['./click-switch-accounts-selection.component.scss'],
})
export class ClickSwitchAccountsSelectionComponent extends ClickSwitchBaseComponent implements OnInit {
  addIcon = UtilityIcons.Plus;
  accounts: SwitchAccountOption[] = [];
  allowSplitAccount = false;
  selectionIncomplete = true;
  previousSwitch: Partial<Switch>;

  get showAddAccount(): boolean {
    return this.allowSplitAccount && this.accounts.length < this.userSwitch.ux.accounts.choices.length;
  }
  private hasError = false;

  constructor(
    @Inject(STATE) state: ng.ui.IStateService,
    @Inject(ngRedux) private ngredux: NgRedux.INgRedux,
    serviceHelper: ServiceHelper,
    directDepositService: DirectDepositService,
    dialogService: DialogService
  ) {
    super(state, directDepositService, serviceHelper, dialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    window.scrollTo(0, 0);

    if (!this.userSwitch.index) {
      this.state.go('udb.clickswitch.search-target');
    }

    this.previousSwitch = this.state.params.userSwitch as Partial<Switch>;
    this.allowSplitAccount = this.userSwitch.ux.accounts.limit > 1 && this.userSwitch.ux.accounts.choices.length > 1;

    this.setupSelectedAccounts();
  }

  goBack(): void {
    this.state.go('udb.clickswitch.submittable-form', { switchInfo: this.previousSwitch });
  }

  goToStartPage(): void {
    this.showLeaveModal(() => {
      this.cancelAndDeleteSwitch(() => {
        this.state.go('udb.clickswitch');
      });
    });
  }

  addAccount(): void {
    const account: Partial<SwitchAccountOption> = {
      id: -1,
      isTotal: true,
      percentage: '100',
      choices: this.setChoiceAccounts(this.accounts.length),
    };

    this.accounts.push(account as SwitchAccountOption);

    if (this.accounts.length > 1) {
      this.accounts.forEach(a => {
        a.percentage = null;
        a.isTotal = false;

        return a;
      });
    }
  }

  deleteAccount(index: number): void {
    this.accounts.splice(index, 1);
    this.updateAccountChoiced();

    this.validTotalPercentage(0);

    if (this.accounts.length === 1) {
      this.accounts[0].percentage = '100';
      this.accounts[0].isTotal = true;
    }
  }

  validTotalPercentage(index: number): void {
    const total = this.accounts.reduce((sum, acc) => sum + (parseInt(acc.percentage, 10) || 0), 0);
    if (total > 100) {
      this.accounts[index].errorMessage = 'Combined total cannot exceed 100%';
      this.hasError = true;

      return;
    }
    this.accounts.map(a => (a.errorMessage = null));
    this.hasError = false;
  }

  updateAccountChoiced(): void {
    for (let acc = 0; acc < this.accounts.length; acc++) {
      this.accounts[acc].choices = this.setChoiceAccounts(acc);
    }
  }

  goToReviewPage(): void {
    const valueAccounts: SwitchAccountValue[] = [];

    this.accounts.forEach(account => {
      account.percentage = account.isTotal ? '100' : account.percentage;
      valueAccounts.push({
        index: account.id,
        fields: { splitType: 'percentage', splitAmount: account.percentage },
      });
    });

    this.userSwitch.values.accounts = valueAccounts;

    this.updateSwitch(() => {
      this.state.go('udb.clickswitch.review-edit', { userSwitch: this.userSwitch });
    });
  }

  disabledContinue(): boolean {
    return this.hasError || this.accounts.some(a => a.id.toString() === '-1' || a.percentage == null || a.errorMessage);
  }

  private setupSelectedAccounts(): void {
    if (this.userSwitch.values?.accounts?.length > 0) {
      const selectedAccounts: SwitchAccountOption[] = [];

      this.userSwitch.values.accounts.forEach(valueAccount => {
        selectedAccounts.push({
          id: valueAccount.index,
          percentage: valueAccount.fields.splitAmount,
          isTotal: valueAccount.fields.splitAmount === '100',
        } as SwitchAccountOption);
      });

      this.accounts = selectedAccounts;

      this.updateAccountChoiced();
    } else {
      this.addAccount();
      const { accountsToBeFunded, isRunning } = this.ngredux.getState().funding;

      if (isRunning) {
        const choice = this.userSwitch.ux?.accounts?.choices.find(c =>
          c.label.endsWith(accountsToBeFunded[0].accountMask)
        );
        this.accounts[0].id = choice.index;
      }
    }
  }

  private setChoiceAccounts(index: number): AccountChoice[] {
    const otherAccounts = [...this.accounts];
    otherAccounts.splice(index, 1);

    const availables =
      this.userSwitch.ux?.accounts?.choices.filter(c => !otherAccounts.some(a => +a.id === c.index)) || [];

    return availables;
  }
}
