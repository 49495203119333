import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { OrdersRequest, OrdersResponse } from './types';
import { WebApiResponse } from '@shared/models';

@Injectable()
export class OrdersService extends BaseService {
  /**
   * OrdersService constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/orders');
  }

  /**
   * Returns the orders of account number from AAS customer.
   *
   * @param request OrdersRequest
   * @returns WebApiResponse of OrdersResponse
   *
   */
  getOrders(request: OrdersRequest): WebApiResponse<OrdersResponse[]> {
    return this.get(request.accountNumber, {
      startDate: request.endDate ? request.endDate.toISOString() : '',
      endDate: request.startDate ? request.startDate.toISOString() : '',
      limit: request.limit ? request.limit.toString() : '',
    });
  }
}
