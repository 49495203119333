import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getTradingAccount } from '@app/accounts/store/selectors';
import { EquityType, OrderTypeDescription, TradeStockStep, TradeStockType } from '@app/axos-trading/enums';
import {
  Equity,
  OrderConfirmation,
  OrderPreview,
  PortfolioOverview,
  TradeStockFormEvent,
  TradingAccount,
} from '@app/axos-trading/models';
import { ClearingService } from '@app/axos-trading/services';
import { createMutualFundForm, createTradeStockForm } from '@app/axos-trading/utils';
import { STATE_PARAMS } from '@core/tokens';

@Component({
  selector: 'app-trade-stock',
  templateUrl: './trade-stock.component.html',
  styleUrls: ['./trade-stock.component.scss'],
})
export class TradeStockComponent implements OnInit {
  stock: Equity;
  step = TradeStockStep.Form; // indicates step of the flow
  steps = TradeStockStep;
  account: TradingAccount;
  bannerTitle: string;
  form: UntypedFormGroup;
  portfolioOverview: PortfolioOverview;
  orderConfirmation: OrderConfirmation;
  orderPreview: OrderPreview;
  displayConfirmation: boolean;

  // indicates if its a sell or buy
  get tradeType(): TradeStockType {
    return this.params['tradeType'];
  }
  get stockId(): string {
    return this.params['stockId'];
  }
  get accountId(): number {
    return this.params['accountId'];
  }

  private subSink = new SubSink();

  constructor(
    private clearingService: ClearingService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.getStock();
    this.getTradingAccount();
  }

  loadStep(step: TradeStockStep) {
    const titles = {
      [TradeStockStep.Form]: this.buildFormTitle,
      [TradeStockStep.MutualFund]: this.buildFormTitle,
      [TradeStockStep.Review]: this.buildReviewTitle,
      [TradeStockStep.MutualReview]: this.buildReviewMutualTile,
    };
    titles[step].bind(this)();
    this.step = step;
  }

  onFormSubmitted(event: TradeStockFormEvent) {
    this.orderConfirmation = event.order;
    this.orderPreview = event.preview;
    this.loadStep(TradeStockStep.Review);
  }

  private buildFormTitle() {
    this.bannerTitle = `${this.tradeType}: ${this.stock.stockName}`;
  }

  private buildReviewTitle() {
    const orderType = this.form.get('orderType').value as OrderTypeDescription;
    this.bannerTitle = `Review ${orderType} ${this.tradeType}`;
  }

  private buildReviewMutualTile() {
    this.bannerTitle = `Review Mutual Fund ${this.tradeType} `;
  }

  private getStock() {
    this.subSink.sink = this.clearingService.getEquity(this.stockId).subscribe(result => {
      this.stock = result;
      const isMutual = this.stock.type === EquityType.MutualFund;
      this.form = isMutual ? createMutualFundForm(this.tradeType === TradeStockType.Buy) : createTradeStockForm();
      if (isMutual) this.step = TradeStockStep.MutualFund;
      this.buildFormTitle();
    });
  }

  private getTradingAccount(): void {
    this.subSink.sink = this.store
      .select(getTradingAccount(this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.account = account;
        this.getPortfolioOverview();
      });
  }

  private getPortfolioOverview() {
    this.subSink.sink = this.clearingService.getPortfolioOverview(this.account.accountNumber).subscribe(result => {
      this.portfolioOverview = result;
    });
  }
}
