import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { DebitCard, MarkAsHotRequest } from '@app/accounts/submodules/debit-card/models';
import { DebitCardService } from '@app/accounts/submodules/debit-card/services';
import { AddressType } from '@app/user-profile/enums';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { areAddressesEqual, createAddressForm } from '@app/user-profile/utils';
import { extractServerError } from '@app/utils';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { Address, CustomerDetail } from '@shared/models';

@Component({
  selector: 'app-report-lost-stolen-view',
  templateUrl: './report-lost-stolen-view.component.html',
  styleUrls: ['./report-lost-stolen-view.component.scss'],
})
export class ReportLostStolenViewComponent implements OnInit, OnDestroy {
  card: DebitCard;
  cardMask: string;
  apiErrorMessage: string;
  reportForm: UntypedFormGroup;
  loading = false;
  loadingData = true;
  canSubmit = true;

  private profile: Partial<CustomerDetail>;
  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private $stateParams: ng.ui.IStateParamsService,
    @Inject(STATE) private $state: ng.ui.IStateService,
    private store: Store,
    private fb: UntypedFormBuilder,
    private debitCardService: DebitCardService,
    private cachedAccountsService: CachedAccountsService
  ) {}

  ngOnInit(): void {
    const { card, id } = this.$stateParams;

    if (!card) {
      this.$state.go('udb.accounts.details', { id, tab: 1 });
    } else {
      this.card = card;
      this.cardMask = this.card.cardNumberMask.slice(this.card.cardNumberMask.length - 5);
      this.subsink.sink = this.store
        .select(getProfileDetails)
        .pipe(filter(data => data !== null))
        .subscribe(profile => {
          this.profile = profile;
          this.reportForm = this.fb.group({
            deliveryAddress: createAddressForm(profile.primaryAddress.address, AddressType.None),
            replaceCard: this.fb.control(false),
            dueFee: this.fb.control(null),
          });

          this.subsink.sink = this.reportForm.get('replaceCard').valueChanges.subscribe(replace => {
            this.reportForm.patchValue({
              deliveryAddress: profile.primaryAddress.address,
              // If the user unchecks the replace checkbox, disappear the selection of the radio.
              dueFee: !replace ? null : false,
            });
          });

          this.loadingData = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  changeAddress(address: Address): void {
    this.reportForm.get('deliveryAddress').patchValue(address);
  }

  setDueFee(value: boolean): void {
    this.reportForm.patchValue({ dueFee: value });
  }

  submitInformation(): void {
    this.loading = true;
    const formValue = this.reportForm.value as MarkAsHotRequest;
    const request = new MarkAsHotRequest({
      ...formValue,
      cardMask: this.card.cardNumberMask,
      addressChanged: !areAddressesEqual(this.profile.primaryAddress.address, formValue.deliveryAddress),
    });

    this.debitCardService
      .markAsHotCard(this.card.accountId, request)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: res => {
          let confirmationMessage = 'We have completed your request to shut off your card(s).';
          if (request.replaceCard) {
            confirmationMessage = `We have completed your request to shut off your card. A new card will be mailed to the address you provided in ${
              request.dueFee ? '3' : '7-10'
            } business days.`;
          }
          this.cachedAccountsService.updateLinkedDebitCards(res);

          this.$state.go('udb.accounts.debit-cards.confirmation', {
            message: confirmationMessage,
            email: this.profile.primaryEmail,
            accountId: this.card.accountId,
            card: this.card,
            header: 'Success',
          });
        },
        error: res => {
          this.apiErrorMessage = extractServerError(res);
        },
      });
  }

  returnToDebitCards(): void {
    this.$state.go('udb.accounts.details', { id: this.card.accountId, tab: 4, debitCardId: this.card.debitCardId });
  }
}
