import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { GetOrganizationSettingsRequestType, GetOrganizationSettingsResponseType } from './types';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/branding');
  }

  getOrganizationSettings(
    request: GetOrganizationSettingsRequestType
  ): WebApiResponse<GetOrganizationSettingsResponseType> {
    const params = new HttpParams().set('includeSettings', request.includeSettings.toString());
    return this.get(`${request.riaId}`, params);
  }
}
