import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  Uk2ButtonSizeEnum,
  Uk2ModalActionsGridEnum,
  Uk2ModalComponent,
  Uk2ModalFontWeightEnum,
  Uk2ModalTitleAlignEnum,
} from '@axos/uikit-v2-lib';
import { ModalPersonType } from '../../enums';
import { REMOVE_TRUSTED_INTERESTED_MODAL_LABELS } from '../../constants';
import { BrandingFacade } from '@app/Areas/AAS/features/branding';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-remove-person-modal',
  templateUrl: './remove-person-modal.component.html',
  styleUrls: ['./remove-person-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemovePersonConfirmationModalComponent implements OnChanges {
  @ViewChild('confirmationRemoveModal') confirmationRemoveModal?: Uk2ModalComponent;

  @Input() personType: ModalPersonType;
  @Output() modalClose = new EventEmitter();
  @Output() removePerson = new EventEmitter();

  removeTrustedInterestedModalHeader: string;
  removeTrustedInterestedModalBody: string;
  removeTrustedInterestedModalActionsGrid = Uk2ModalActionsGridEnum.side;
  removeTrustedInterestedModalFontWeight = Uk2ModalFontWeightEnum.bold400;
  removeTrustedInterestedModalTitleAlign = Uk2ModalTitleAlignEnum.center;
  removeTrustedInterestedModalAlign = Uk2ModalTitleAlignEnum.center;
  removeTrustedInterestedModalId = 'remove-trusted-interested-modal';
  uk2ButtonSize = Uk2ButtonSizeEnum;
  trustedContactPerson = ModalPersonType.TrustedContact;
  isLoading = false;
  private loadingSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private brandingFacade: BrandingFacade,
    private peopleFacade: PeopleFacade
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.personType) {
      this.removeTrustedInterestedModalBody = REMOVE_TRUSTED_INTERESTED_MODAL_LABELS[this.personType].bodyContent;
      this.removeTrustedInterestedModalHeader = REMOVE_TRUSTED_INTERESTED_MODAL_LABELS[this.personType].headerText;
      this.changeDetectorRef.detectChanges();
    }
  }

  openModal() {
    this.confirmationRemoveModal.openDialog();
    this.initializeSubscriptions();
    this.brandingFacade.loadBranding('.uk2-custom-submodal-white-label');
    this.changeDetectorRef.detectChanges();
  }

  closeModal(emit = true) {
    this.confirmationRemoveModal.closeDialog();
    this.loadingSubscription.unsubscribe(); // Unsubscribe from all subscriptions added to loadingSubscription
    if (emit) this.modalClose.emit();
  }

  removeTrustedInterestedPerson() {
    this.removePerson.emit();
  }

  private initializeSubscriptions() {
    this.loadingSubscription = new Subscription(); // Reset the subscription
    const newSubscription = this.peopleFacade.isLoading$
      .pipe(
        tap(isLoading => {
          this.isLoading = isLoading;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();

    this.loadingSubscription.add(newSubscription); // Add the subscription to loadingSubscription property
  }
}
