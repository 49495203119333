<div class="main-container">
  <div class="tab-header">
    <div class="tab-title">
      <h3>{{ tabTitle }}</h3>
    </div>
    <div class="tab-elements">
      <mat-tab-group
        class="custom-tab"
        uk2StretchTabs
        disableRipple
        uk2StretchTabsSize="large"
        mat-stretch-tabs
        animationDuration="0ms"
        (selectedTabChange)="onSelectedTab($event)"
      >
        <mat-tab *ngFor="let tab of tabs" [label]="tab"></mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="tab-container">
    <ng-container [ngSwitch]="selectedTabIndex">
      <!-- Transaction Tile -->
      <app-transactions-tile *ngSwitchCase="0"></app-transactions-tile>
      <!-- Orders Tile -->
      <app-orders-tile *ngSwitchCase="1"></app-orders-tile>
      <!-- Scheduled Tile -->
      <app-scheduled-transfers-tile *ngSwitchCase="2"></app-scheduled-transfers-tile>
    </ng-container>
  </div>
</div>
