import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Company,
  Equity,
  EquityHistory,
  Filters,
  Order,
  OrderConfirmation,
  OrderFilter,
  OrderPreview,
  PortfolioBalanceHistoryResponse,
  PortfolioOverview,
  Transaction,
} from '@app/axos-trading/models';
import { removeTimeFromIsoDate } from '@app/utils';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class ClearingService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'trading');
  }


  getPortfolioOverview(accountNumber: string): Observable<PortfolioOverview> {
    return this.get<PortfolioOverview>(`portfolio/overview/${accountNumber}`).pipe(map(result => result.data));
  }

  getEquity(symbol: string): Observable<Equity> {
    return this.get<Equity>(`equities/${symbol}`).pipe(map(result => result.data));
  }

  previewOrder(order: OrderConfirmation): Observable<OrderPreview> {
    return this.post<OrderPreview>('orders/preview', order).pipe(map(result => result.data));
  }

  submitOrder(order: OrderConfirmation): Observable<{ orderReference: string }> {
    return this.post<{ orderReference: string }>('orders', order).pipe(map(result => result.data));
  }

  getEquityHistory(symbol: string, from: string, to: string): Observable<EquityHistory[]> {
    return this.get<EquityHistory[]>(`equities/history/${symbol}?from=${from}&to=${to}`).pipe(
      map(result => result.data)
    );
  }

  getCompany(symbol: string): Observable<Company> {
    return this.get<Company>(`equities/company/${symbol}`).pipe(map(result => result.data));
  }
  getOrders(accountNumber: string, filters?: OrderFilter): Observable<Order[]> {
    let params = new HttpParams();
    if (filters) {
      params = this.setHttpParams(params, filters);
    }

    return this.get<Order[]>(`orders/${accountNumber}`, params).pipe(map(result => result.data));
  }

  searchEquities(keyword: string): Observable<Equity[]> {
    return this.get<Equity[]>(`equities/search/${keyword}`).pipe(map(result => result.data));
  }

  getPortfolioActivity(accountNumber: string, filters?: Filters): Observable<Transaction[]> {
    let params = new HttpParams();
    if (filters) {
      params = this.setHttpParams(params, filters);
    }

    return this.get<Transaction[]>(`portfolio/activity/${accountNumber}`, params).pipe(map(result => result.data));
  }

  getPortfolioBalanceHistory(
    accountNumber: string,
    from: string,
    to: string
  ): Observable<PortfolioBalanceHistoryResponse[]> {
    return this.get<{ date: string; balance: number }[]>(
      `portfolio/balance-history/${accountNumber}?from=${from}&to=${to}`
    ).pipe(map(result => result.data.map(item => ({ date: removeTimeFromIsoDate(item.date), balance: item.balance }))));
  }

  private setHttpParams(params: HttpParams, obj: any, prefix?: string) {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      const objValue = obj[key];
      if (objValue !== null) {
        const name = prefix ? prefix + '.' + key : key;
        let value = '';
        if (objValue instanceof Date) {
          value = obj[key].toISOString();
          params = params.set(name, value);
        } else if (objValue instanceof Array) {
          const array = objValue as any[];
          array.forEach(itemValue => {
            params = params.append(name, itemValue);
          });
        } else if (typeof objValue === 'object') {
          params = this.setHttpParams(params, objValue, key);
        } else {
          value = objValue;
          params = params.set(name, value);
        }
      }
    });

    return params;
  }
}
