/**
 * calculates the percentage of a given to color to be mapped
 * as edge in gradient positioning
 * arr {@link Array}
 * currentIndex number
 * @returns number
 */
export function calculateGradientPercentage<T>(arr: Readonly<T[]>, currentIndex: number): number {
  const arrLength: number = arr.length;
  if (currentIndex >= 0 && currentIndex < arrLength) {
    const percentage = currentIndex / (arrLength - 1);

    return parseFloat(percentage.toFixed(2));
  }
  throw new Error('Invalid current index');
}
