<td class="transaction-column description-column">
  <div>
    {{ transaction.createdDate | date: 'MMMM d, yyyy' }}
  </div>
  <div>
    <div>
      {{ transaction.category.description | titlecase }}: {{ transaction.symbol }} -
      {{ transaction.orderType | titlecase }}
      {{ '- ' + transaction.status.description }}
    </div>
  </div>
</td>
<td class="transaction-column amount-column">
  {{ transaction.expectedPrice | currency }}
</td>
<td class="transaction-column action-column">
  <a id="goDetails" (click)="goDetails(transaction.reference)">
    <app-svg-icon
      [iconName]="icons.Kebab"
      [styles]="{ height: '14px' }"
      [iconColor]="'#8F8F8F'"
    ></app-svg-icon>
  </a>
</td>
