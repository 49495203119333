import { PaymentsTabController } from './payments-tab.controller';

export const PaymentsTabComponent: ng.IComponentOptions = {
  controller: PaymentsTabController,
  controllerAs: '$ctrl',
  templateUrl: 'bill-pay/pay/recipients/payments-tab/payments-tab.tpl.html',
  bindings: {
    recipient: '<?',
    paymentsHistory: '<?',
    scheduledPayments: '<?',
    loadScheduledPayments: '&',
  },
};
