<div class="categories">
  <div class="d-none d-sm-block">
    <div class="total-section">
      <span>Total</span>
    </div>
    <div class="top-section">
      <h4 class="title">Spending Categories</h4>
      <h5 class="total-spent">{{ totalSpent | currencyExtended: '':'':true }}</h5>
    </div>
    <div class="category-border"></div>
    <div class="categories-grid">
      <ng-container *ngFor="let category of categoriesGrid; trackBy: categoryTrackBy">
        <div class="category-row-item row">
          <div class="col-9 first-section">
            <div class="image-section">
              <app-svg-icon
                class="category-image"
                [iconName]="category.imageFile"
                [width]="'32px'"
                [height]="'22px'"
                [iconColor]="category.fill"
                [ngStyle]="{ 'background-color': category.color }"
              ></app-svg-icon>
              <span class="category-name">{{ category.name }}</span>
            </div>
            <div class="category-percentage">
              <span>{{ category.percentage | percentageRound }}%</span>
            </div>
          </div>
          <div class="col-3 second-section">
            <span>{{ category.amount | currencyExtended: '':'':true }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="categories-responsive d-sm-none">
    <div class="top-section" *ngIf="!categoriesFiltered">
      <h4 class="title">TOP SPENDING CATEGORIES</h4>
      <div (click)="clickAccordion()">
        <app-svg-icon
          [iconName]="showCategories ? icons.chevronUp : icons.chevronDown"
          [iconColor]="'#2F5B88'"
          [width]="'1.5rem'"
          [height]="'1.5rem'"
        ></app-svg-icon>
      </div>
    </div>
    <div class="categories-grid" *ngIf="showCategories && categoriesGrid && !isEmpty">
      <ng-container *ngFor="let category of categoriesGrid; let i = index">
        <div
          class="category-row-item row"
          *ngIf="(ctaShow ? i >= 0 : i <= maxCategoriesToShow && category.amount != 0) || categoriesFiltered"
        >
          <div class="col-9 first-section">
            <div class="image-section">
              <app-svg-icon
                class="category-image"
                [iconName]="category.imageFile"
                [width]="'32px'"
                [height]="'22px'"
                [iconColor]="category.fill"
                [ngStyle]="{ 'background-color': category.color }"
              ></app-svg-icon>
              <span class="category-name">{{ category.name }}</span>
            </div>
          </div>
          <div class="col-3 second-section-responsive">
            <span>{{ category.amount | currencyExtended: '':'':true }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="cta-text" *ngIf="showCategories && categoriesGrid && !isEmpty && !categoriesFiltered">
      <span (click)="ctaShow = !ctaShow">{{ ctaShow ? 'Show Less' : 'Show All' }}</span>
    </div>
    <div class="empty-grid font-weight-normal" *ngIf="showCategories && isEmpty">
      <span>No account activity</span>
    </div>
  </div>
</div>
