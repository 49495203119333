import { createAction, props} from '@ngrx/store';

import { Order, Position } from '@app/axos-trading/models';

export const loadOrdersSuccess = createAction(
  '[Order] Load Success',
  props<{ orders: Order[]}>()
);

export const setCurrentOrderReference = createAction(
  '[Order] Set Current Order',
  props<{ currentOrderReference: string}>()
);

export const loadPositionsSuccess = createAction(
  '[Position] Load Success',
  props<{ positions: Position[]}>()
);
