import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

import { TotalValueState } from '../types';

const getTotalValueState = createFeatureSelector<TotalValueState>(AasStoreFeatures.TotalValue);

export const getSelectedAccountTotalValueTile = createSelector(getTotalValueState, state => {
  const { accountsTotalValues, selectedAccountTotalValues } = state;
  if (selectedAccountTotalValues === undefined) {
    return undefined;
  }

  const matchingAccount =
    accountsTotalValues.find(account => account.accountNumber === selectedAccountTotalValues) ?? undefined;

  return matchingAccount;
});
