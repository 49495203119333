<div class="ach-one-time__container" *ngFor="let item of authorizationsTabState">
  <div class="ach-one-time__title">ACH One Time Authority</div>
  <div class="accountNumber-container ach-one-time__flex-container">
    <div class="accountNumber-text txt">Bank Account Number</div>
    <div class="accountNumber-value val">{{ getFormattedAccNum(item.bankAccountNumber) ?? blankFormat }}</div>
  </div>
  <div class="ach-one-time__divider"></div>
  <div class="routingNumber-container ach-one-time__flex-container">
    <div class="routingNumber-text txt">ABA Routing Number</div>
    <div class="routingNumber-value val">{{ abaRoutingFormat(item.abaRoutingNumber) ?? blankFormat }}</div>
  </div>
  <div class="ach-one-time__divider"></div>
  <div class="accountType-container ach-one-time__flex-container">
    <div class="accountType-text txt">Account Type</div>
    <div class="accountType-value val">{{ item.accountType ?? blankFormat }}</div>
  </div>
  <div class="ach-one-time__divider"></div>
  <div class="status-container ach-one-time__flex-container">
    <div class="status-text txt">Status</div>
    <div class="status-value val">{{ item.status ?? blankFormat }}</div>
  </div>
  <div class="ach-one-time__divider"></div>
  <div class="dateAdded-container ach-one-time__flex-container">
    <div class="dateAdded-text txt">Date Added</div>
    <div class="dateAdded-value val">{{ getFormattedDate(item.dateAdded) ?? blankFormat }}</div>
  </div>
</div>
