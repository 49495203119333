<app-alert-notification
  class="d-block mb-3"
  [duration]="3000"
  (dismiss)="alertMessage = null"
  *ngIf="alertMessage"
  >
    {{ alertMessage }}
</app-alert-notification>

<div class="item no-gutters">
  <h4 class="item__title col-8 col-sm-10 col-lg-3" [innerText]="itemName"></h4>

  <div class="item__content col-12 col-lg-7"><ng-content></ng-content></div>

  <div class="item__actions col-4 col-sm-2">
    <button
      type="button"
      class="link item__action"
      *ngIf="editable"
      (click)="edit.emit()"
      [attr.aria-label]="'Edit ' + itemName"
    >
      <app-svg-icon [iconName]="editIcon" iconColor="var(--link)"></app-svg-icon>
      <span class="item__action-text">Edit</span>
    </button>
  </div>
</div>
