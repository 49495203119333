import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface PdfOptions {
  page: number;
  scrollbar: number;
  toolbar: number;
  zoom: number;
}

@Component({
  selector: 'app-pdf-renderer',
  templateUrl: './pdf-renderer.component.html',
  styleUrls: ['./pdf-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfRendererComponent {
  data: SafeResourceUrl;

  private pdfOptions: PdfOptions = {
    page: 1,
    scrollbar: 1,
    toolbar: 0,
    zoom: 85,
  };

  constructor(private domSanitizer: DomSanitizer) {}

  @Input() set resourceUrl(url: string) {
    const optsStr = Object.keys(this.pdfOptions).reduce(
      (accumulator, optionKey) => `${accumulator}&${optionKey}=${this.pdfOptions[optionKey]}`,
      ''
    );

    this.data = this.domSanitizer.bypassSecurityTrustResourceUrl(`${url}#${optsStr}`);
  }

  @Input() set options(options: Partial<PdfOptions>) {
    Object.assign(this.pdfOptions, options);
  }
}
