// Use this file to export all your pipes.
// Then they can be imported on other files like this. E.g.
// import { MyPipe } from '@shared/pipes';

import { AbbreviateCurrencyPipe } from './abbreviate-currency.pipe';
import { AddressPipe } from './address.pipe';
import { CurrencyExtendedPipe } from './currency-extended.pipe';
import { MaskEmailPipe } from './mask-email.pipe';
import { MaskPhonePipe } from './mask-phone.pipe';
import { NumberAbsPipe } from './number-abs.pipe';
import { OrderByPipe } from './order-by.pipe';
import { PercentageRoundPipe } from './percentage-round.pipe';
import { PhoneToStringPipe } from './phone-to-string.pipe';
import { PhonePipe } from './phone.pipe';
import { PossessivePipe } from './possessive.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';

export const sharedPipes = [
  AbbreviateCurrencyPipe,
  AddressPipe,
  CurrencyExtendedPipe,
  MaskEmailPipe,
  MaskPhonePipe,
  NumberAbsPipe,
  PercentageRoundPipe,
  PhonePipe,
  PhoneToStringPipe,
  PossessivePipe,
  SafePipe,
  TruncatePipe,
  OrderByPipe,
];

export {
  AbbreviateCurrencyPipe,
  AddressPipe,
  CurrencyExtendedPipe,
  MaskEmailPipe,
  MaskPhonePipe,
  NumberAbsPipe,
  PercentageRoundPipe,
  PhonePipe,
  PhoneToStringPipe,
  PossessivePipe,
  SafePipe,
  TruncatePipe,
  OrderByPipe,
};
