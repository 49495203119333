import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  exports: [MatIconModule, MatButtonModule, MatExpansionModule, MatCheckboxModule],
})
export class AuthorizeTransferPageMatModule {}
