import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AccountsService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { StopPaymentService } from '@legacy/services/stop-payment.service';
import { StopPayment } from '@legacy/typings/app/StopPayment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-stop-payment-remove',
  templateUrl: './stop-payment-remove.component.html',
  styleUrls: ['./stop-payment-remove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopPaymentRemoveComponent implements OnInit {
  accountId: number;
  seqId: number;
  spCancel: StopPayment;
  accountName: string;
  isBusy: boolean = false;

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private stopPaymentService: StopPaymentService,
    private serviceHelper: ServiceHelper,
    private accountService: AccountsService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.seqId = this.params['seq'];
    this.spCancel = this.params['spCancel'];
    if (!this.spCancel)
      this.state.go('udb.accounts.stop-payment', {
        id: this.accountId,
      });
    this.accountService
      .getAccount(this.accountId.toString())
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: res => {
          this.accountName = res.data.nickname || res.data.name;
        },
        error: this.serviceHelper.errorHandler,
      });
  }

  remove(): void {
    this.isBusy = true;
    this.stopPaymentService
      .cancelStopPayment(+this.accountId, this.seqId)
      .then(() => {
        this.state.go('udb.accounts.stop-payment-success', {
          id: this.accountId,
          seq: this.seqId,
          stopPayment: this.spCancel,
          actionMsg: 'removed',
        });
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isBusy = false;
      });
  }

  redirectToPage(paramAccountId: any): void {
    this.state.go('udb.accounts.stop-payment', {
      id: paramAccountId,
    });
  }
}
