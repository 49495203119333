import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { olbSettings } from '@core/tokens';

import { AxosInvestService } from './axos-invest.service';

@Injectable()
export class AxosInvestUrlsService {
  private token: string;
  constructor(private axosInvestService: AxosInvestService, @Inject(olbSettings) private settings: OlbSettings) {}

  getGlidePathUrl(milestoneId: string): Observable<string> {
    return this.createAxosInvestUrl(`dashboard/milestone/${milestoneId}/update/portfolio-simple-type`);
  }

  getFormulasUrl(): Observable<string> {
    return this.createAxosInvestUrl('dashboard/formulas');
  }

  getEditGoalUrl(milestoneId: string): Observable<string> {
    return this.createAxosInvestUrl(`dashboard/milestone/${milestoneId}/update/simple`);
  }

  getEditProfileUrl(milestoneId: string): Observable<string> {
    return this.createAxosInvestUrl(`dashboard/milestone/${milestoneId}/update/portfolio-simple-type`);
  }

  getPreviousDocuments(): Observable<string> {
    return this.createAxosInvestUrl('dashboard/profile/documents/');
  }

  getPreviousTaxForms(): Observable<string> {
    return this.createAxosInvestUrl('dashboard/profile/documents/taxes');
  }

  getManageWalletUrl(): Observable<string> {
    return this.createAxosInvestUrl('dashboard/formulas');
  }

  getOverviewUrl(): Observable<string> {
    return this.createAxosInvestUrl('dashboard/overview');
  }

  getCreateMilestoneUrl(): Observable<string> {
    return this.createAxosInvestUrl('dashboard/milestone/create');
  }

  private createAxosInvestUrl(path: string = ''): Observable<string> {
    return this.getUserToken().pipe(
      map(token => `${this.settings.wiseBanyanUrl}/${path}?referrer=axos_olb&referrer_token=${token}`)
    );
  }

  private getUserToken(): Observable<string> {
    if (!this.token) {
      return this.axosInvestService.getSsoToken().pipe(map(response => (this.token = response.data)));
    }

    return of(this.token);
  }
}
