import { NgModule } from '@angular/core';
import { InterestitialPageComponent } from './view/initial-page/initial-page.component';
import { AasSharedModule } from '../../aas-shared/aas-shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { InterestitialHeaderPage } from './view/initial-page-header/initial-page-header';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [InterestitialPageComponent, InterestitialHeaderPage],
  exports: [InterestitialPageComponent, InterestitialHeaderPage],
  imports: [AasSharedModule, BrowserModule, CommonModule, LottieModule.forRoot({ player: playerFactory })],
})
export class InterestitialPageModule {}
