import { createSelector } from '@ngrx/store';

import { UserProfileIcons } from '@app/user-profile/enums';
import { IconDetails } from '@app/user-profile/models';

import { getUserProfile } from '../user-profile.state';

export const getProfileDetails = createSelector(getUserProfile, state => state.profileDetails.details);

export const getUserInfoIsOutdated = createSelector(getUserProfile, state => state.profileDetails.userInfoIsOutdated);

export const getIsNewUser = createSelector(getUserProfile, state => state.profileDetails.isNewUser);
export const getAccountProfiles = createSelector(getUserProfile, state => state.profileDetails.accountProfiles);

export const getProfileIconDetails = createSelector(getProfileDetails, details => {
  if (!details) {
    return null;
  }

  let initials = '';
  if (details) {
    initials = `${details.firstName?.substr(0, 1)} ${details.lastName?.substr(0, 1)}`;
  }

  const selectedColor = details.backgroundColor;

  let { backgroundIcon } = details;

  if (backgroundIcon !== 'initials') {
    backgroundIcon = backgroundIcon?.includes('profile-')
      ? backgroundIcon
      : (`profile-${backgroundIcon}` as UserProfileIcons);
  }

  const selectedIcon = backgroundIcon;

  return new IconDetails({ initials, selectedColor, selectedIcon });
});
