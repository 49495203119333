import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

import tippy, { animateFill, Props, roundArrow } from 'tippy.js';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent implements AfterViewInit {
  char: '?' | '!' | 'i' = '?';
  @Input() useExclamation = false;
  @ViewChild('tooltip') tooltip: ElementRef;

  @Input() white = false;

  @Input() set placement(placement: Props['placement']) {
    this.options.placement = placement;
  }

  @Input() set content(content: Props['content']) {
    this.options.content = content;
  }

  @Input() set theme(theme: Props['theme']) {
    this.options.theme = theme;
  }

  @Input() set symbol(symbol: '?' | '!' | 'i') {
    if (['!', 'i'].includes(symbol)) {
      this.char = symbol;
    }
  }

  options: Partial<Props> = {
    allowHTML: true,
    animateFill: false,
    arrow: roundArrow,
    placement: 'bottom',
    plugins: [animateFill],
    theme: 'olb',
    interactive: true,
    appendTo: 'parent',
  };

  ngAfterViewInit(): void {
    tippy(this.tooltip.nativeElement, this.options);
  }
}
