import { AccountController } from 'accounts/accounts.controller';
import { IStateService } from 'angular-ui-router';
import { ReceiptTemplateController } from 'common/templates/receipt/receipt-template.controller';
import { Inject } from 'decorators/decorators';
import { FeatureFlagService } from 'services/feature-flag.service';

// import { AddBeneficiariesController } from '../beneficiaries/add-beneficiaries/add-beneficiaries.controller';
// import { EditBeneficiariesController } from '../beneficiaries/edit-beneficiaries/edit-beneficiaries.controller';
// import { BeneficiariesListController } from '../beneficiaries/list-beneficiaries/list-beneficiaries.controller';
//import { MinorsController } from '../minors/minors.controller';
import { AccountContainerController } from './account-container/account-container.controller';
import { AccountDetailsIndexController } from './account-details-index/account-details-index.controller';
import { AccountAggDetailsIndexController } from './external-accounts/account-details-index/account-details-index.controller';
import { AddExternalAccountController } from './external-accounts/add-external-account/add-external-account.controller';

@Inject('$stateProvider')
export class AccountsConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.accounts', {
        views: {
          content: {
            controller: AccountController,
            controllerAs: 'vm',
            templateUrl: 'accounts/accounts.tpl.html',
          },
        },
      })
      .state('udb.accounts.dashboard', {
        url: '/Dashboard/Accounts?p=:param',
        params: {
          authStatus: 0,
          authErrorMsg: null,
          accountNickName: null,
        },
        views: {
          'acc-content': {
            template: '<app-dashboard-accounts></app-dashboard-accounts>',
          },
        },
      })
      .state('udb.accounts.account-aggregation', {
        url: '/AccountAggregation',
        views: {
          'acc-content': {
            template: `
            <div class="account-aggregation frow center">
              <div class="fcol-cs-12">
                <div ui-view="aggregation-content">
                  <app-account-aggregation>
                  </app-account-aggregation>
                </div>
              </div>
            </div>
            `,
          },
        },
        resolve: {
          activeAccountAggregation: [
            'featureFlagService',
            '$state',
            (featureFlagService: FeatureFlagService, state: IStateService): any => {
              !featureFlagService.isAccountAggregationActive() && state.go('udb.dashboard');
            },
          ],
        },
        params: {
          isAccountAggregationFlow: false,
          isPfm3Active: false,
        },
      })
      .state('udb.accounts.external-details', {
        url: '/Dashboard/Accounts/ExternalAccount/:id/:container',
        params: {
          tab: 0,
          isTransDisputeRedirection: false,
          refreshTransactions: false,
          authError: null,
        },
        views: {
          'acc-content': {
            controller: AccountAggDetailsIndexController,
            templateUrl: 'accounts/external-accounts/account-details-index/account-details-index.tpl.html',
            controllerAs: 'vm',
          },
        },
      })
      .state('udb.accounts.add-external', {
        url: '/AddExternalAccount?flow',
        params: {
          accounts: [],
          flow: '',
        },
        views: {
          'acc-content': {
            templateUrl: 'accounts/external-accounts/add-external-account/add-external-account.tpl.html',
            controller: AddExternalAccountController,
            controllerAs: '$aea',
          },
        },
      })
      .state('udb.accounts.details', {
        url: '/Account/:id',
        params: {
          tab: 0,
          isTransDisputeRedirection: false,
          debitCardId: 0,
        },
        views: {
          'acc-content': {
            controller: AccountDetailsIndexController,
            controllerAs: 'vm',
            templateUrl: 'accounts/account-details-index/account-details-index.tpl.html',
          },
        },
        resolve: {
          accounts: LoadAccounts,
        },
      })
      .state('udb.accounts.download', {
        url: '/Account/:id/TransactionsDownload',
        views: {
          'acc-content': {        
            template: '<app-download-transactions></app-download-transactions>',
          },          
        },
        resolve: {
          accounts: LoadAccounts,
        },
      })
      .state('udb.accounts.dispute-review', {
        url: '/DisputeReview',
        views: {
          'acc-content': {
            template: '<app-dispute-review></app-dispute-review>',
          },
        },
        params: {
          transactions: null,
          accountId: 0,
          accountDetail: null,
        },
      })
      .state('udb.accounts.dispute-review-submit', {
        url: '/DisputeReview/Submit',
        params: {
          accountId: 0,
          transactions: null,
          account: null,
          disputeFormData: null,
          isMultiTransaction: false,
          previousTrx: null,
        },
        views: {
          'acc-content': {
            template: '<app-dispute-submit></app-dispute-submit>',
          },
        },
      })
      .state('udb.accounts.dispute-review-confirm', {
        url: '/DisputeReview/Confirm',
        params: {
          settings: null,
          template: null,
        },
        views: {
          'acc-content': {
            templateUrl: 'common/templates/receipt/receipt-template.tpl.html',
            controller: ReceiptTemplateController,
            controllerAs: '$ctrl',
          },
        },
      })
      .state('udb.accounts.dispute-details', {
        url: '/DisputeDetails',
        params: {
          isTransDisputeRedirection: false,
          transactions: null,
          accountId: 0,
          accountDetail: null,
          previousTrx: null,
        },
        views: {
          'acc-content': {
            template: '<app-dispute-details></app-dispute-details>',
          },
        },
      })
      .state('udb.accounts.container', {
        url: '/Accounts/:type/:id',
        params: {
          tab: 0,
          updateAccounts: false,
          modifiedState: null,
          modifiedBannerText: null,
          subTab: 0,
          transactionId: '',
        },
        views: {
          'acc-content': {
            controller: AccountContainerController,
            controllerAs: 'vm',
            templateUrl: 'accounts/account-container/account-container.tpl.html',
          },
        },
        resolve: {
          accounts: LoadAccounts,
        },
      })
      // IRA Trading beneficiaries
      .state('udb.accounts.container.beneficiaries', {
        url: '/Beneficiaries?contingent',
        views: {
          'account-container': {
            template: '<app-trading-beneficiaries></app-trading-beneficiaries>',
          },
        },
        params: {
          contingent: null,
          tradingAccount: null,
          subtitle: '',
        },
      })
      // Beneficiaries
      // .state('udb.beneficiaries', {
      //   abstract: true,
      //   url: '/Beneficiaries',
      //   views: {
      //     content: {
      //       templateUrl: 'beneficiaries/beneficiaries.tpl.html',
      //     },
      //   },
      // })
      // .state('udb.beneficiaries.list', {
      //   url: '',
      //   params: {
      //     accountId: 0,
      //     beneficiaries: [],
      //   },
      //   views: {
      //     'beneficiaries-content': {
      //       templateUrl: 'beneficiaries/list-beneficiaries/list-beneficiaries.tpl.html',
      //       controller: BeneficiariesListController,
      //       controllerAs: '$ben',
      //     },
      //   },
      // })
      // .state('udb.beneficiaries.add', {
      //   url: '/AddBeneficiaries/{accountId}',
      //   params: {
      //     beneficiaries: [],
      //   },
      //   views: {
      //     'beneficiaries-content': {
      //       templateUrl: 'beneficiaries/add-beneficiaries/add-beneficiaries.tpl.html',
      //       controller: AddBeneficiariesController,
      //       controllerAs: '$bene',
      //     },
      //   },
      // })
      // .state('udb.minors', {
      //   url: '/AddMinors/{accountId}',
      //   views: {
      //     content: {
      //       controller: MinorsController,
      //       controllerAs: 'vm',
      //       templateUrl: 'minors/minors.tpl.html',
      //     },
      //   },
      // })
      // .state('udb.beneficiaries.edit', {
      //   url: '/EditBeneficiary',
      //   params: {
      //     accountId: 0,
      //     beneficiary: null,
      //   },
      //   resolve: {
      //     pageRefresh: [
      //       '$state',
      //       '$stateParams',
      //       '$q',
      //       ($state: ng.ui.IStateService, $stateParams: IStateParamsService, $q: ng.IQService): any => {
      //         const defer = $q.defer();
      //         const beneficiary = $stateParams['beneficiary'];
      //         const accountId = $stateParams['accountId'];

      //         if (beneficiary && accountId > 0) {
      //           defer.resolve();
      //         } else {
      //           defer.reject();
      //         }

      //         return defer.promise.catch(function () {
      //           $state.go('udb.accounts.dashboard');
      //         });
      //       },
      //     ],
      //   },
      //   views: {
      //     'beneficiaries-content': {
      //       templateUrl: 'beneficiaries/edit-beneficiaries/edit-beneficiaries.tpl.html',
      //       controller: EditBeneficiariesController,
      //       controllerAs: '$bene',
      //     },
      //   },
      // })

      // IRA enhancements beneficiaries
      .state('udb.accounts.beneficiaries', {
        url: '/Account/:id/Beneficiaries?contingent',
        views: {
          'acc-content': {
            template: '<app-account-beneficiaries></app-account-beneficiaries>',
          },
        },
        params: {
          isDeposit: false,
        },
        resolve: {
          accounts: LoadAccounts,
        },
      })
      .state('udb.accounts.insights', {
        url: '/Dashboard/Insights',
        views: {
          'acc-content': {
            template: '<app-insights-overview></app-insights-overview>',
          },
        },
      })
      .state('udb.accounts.spending', {
        url: '/Dashboard/Insights/Spending',
        views: {
          'acc-content': {
            template: '<app-spending></app-spending>',
          },
        },
      })
      .state('udb.accounts.net-worth', {
        url: '/Dashboard/Insights/NetWorth',
        views: {
          'acc-content': {
            template: '<app-net-worth></app-net-worth>',
          },
        },
      })
      .state('udb.accounts.rewards', {
        url: '/rewards/rewards.tpl.html',
        views: {
          'acc-content': {
            template: '<app-rewards></app-rewards>',
          },
        },
      });
  }
}

LoadAccounts.$inject = ['$rootScope'];
function LoadAccounts(_root: ng.IRootScopeService) {
  if (!_root['accounts']) {
    _root.$on('accountsLoaded', (_e: ng.IAngularEvent) => {
      return _root['accounts'];
    });
  }
}
