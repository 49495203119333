import { createAction, props } from '@ngrx/store';
import { AccountFirmContactsStateType, AccountInformationStateType } from './types';

export const loadAccountInformationAction = createAction(
  '[Account Details] LoadAccountInformationAction',
  props<{ payload: AccountInformationStateType }>()
);

export const loadAccountFirmContactsSuccessAction = createAction(
  '[Account Details] LoadAccountFirmContactsSuccessAction',
  props<{ payload: AccountFirmContactsStateType }>()
);

export const setSelectedAccountDetailsAction = createAction(
  '[Account Details] SetSelectedAccountDetailsAction',
  props<{ payload: string }>()
);
