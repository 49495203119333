import { STATE_PARAMS } from '@core/tokens';

export function stateParamsServiceFactory($inject: any) {
  return $inject.get('$stateParams');
}

export const stateParamsServiceProvider = {
  provide: STATE_PARAMS,
  useFactory: stateParamsServiceFactory,
  deps: ['$injector'],
};
