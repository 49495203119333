import { Action, createReducer, on } from '@ngrx/store';
import { addUpdateScheduledTransferAction } from '../scheduledTransfers.actions';
import { ScheduledTransferState, ScheduledTransferTileState, intialScheduledTransferState } from '../types';

const reducer = createReducer(
  intialScheduledTransferState,
  on(addUpdateScheduledTransferAction, (_state, action) => {
    let scheduledTransferTiles: ScheduledTransferTileState[] = [];
    const accountIndex = _state.scheduledTransfers.findIndex(
      item => item.accountNumber === action.payload.accountNumber
    );

    if (accountIndex < 0) {
      scheduledTransferTiles = [..._state.scheduledTransfers, action.payload];
    } else {
      scheduledTransferTiles = _state.scheduledTransfers.map(item =>
        item.accountNumber === action.payload.accountNumber ? action.payload : item
      );
    }
    return {
      scheduledTransfers: scheduledTransferTiles,
      selectedAccountScheduledTransfers: action.payload.accountNumber,
    };
  })
);

export function scheduledTransfersReducer(state: ScheduledTransferState, action: Action) {
  return reducer(state, action);
}
