import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { supportTransfersComponents } from './components';

@NgModule({
  declarations: [...supportTransfersComponents],
  exports: [...supportTransfersComponents],
  imports: [CommonModule],
})
export class SupportTransfersModule {}
