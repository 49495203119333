import * as angular from 'angular';

import { ComposeMessageConfig } from './compose-message.config';
import { ComposeMessageComponent } from '@app/messages/components';
import { CmBusinessSelectionComponent } from '@app/messages/components';
import { CmMessageComponent } from '@app/messages/components';
import { CmSubcategoriesComponent } from '@app/messages/components';
import { CmConfirmationComponent } from '@app/messages/components';
import { downgradeComponent } from '@angular/upgrade/static';

export const ComposeMessageModule = angular
  .module('udb.composeMessage', ['ui.router'])
  .directive('appComposeMessage', downgradeComponent({ component: ComposeMessageComponent }))
  .directive('appCmBusinessSelection', downgradeComponent({ component: CmBusinessSelectionComponent }))
  .directive('appCmMessage', downgradeComponent({ component: CmMessageComponent }))
  .directive('appCmSubcategories', downgradeComponent({ component: CmSubcategoriesComponent }))
  .directive('appCmConfirmation', downgradeComponent({ component: CmConfirmationComponent }))
  .config(ComposeMessageConfig).name;
