import { PaymentOptionsController } from './payment-options.controller';

export const PaymentOptionsComponent: ng.IComponentOptions = {
  controller: PaymentOptionsController,
  controllerAs: 'vm',
  templateUrl: 'bill-pay/activity/scheduled-payments/payment-options/payment-options.tpl.html',
  bindings: {
    payment: '<',
    getScheduledPayments: '&',
  },
};
