import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { TransferReceiptComponent } from '@app/transfers/components';

export const receiptLegacyModule = angular
  .module('udb.receipt', ['ui.router'])
  .directive('appTransferReceipt', downgradeComponent({ component: TransferReceiptComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider.state('udb.transfers.receipt', {
        url: '/receipt',
        views: {
          'transfer-content': {
            template: '<app-transfer-receipt></app-transfer-receipt>',
          },
        },
        params: {
          settings: null,
          template: null,
        },
      });
    },
  ]).name;
