import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import { InvestorCheckingRequest, InvestorCheckingResponse, Order } from './types';
import { CookieHelperService } from '@app/core/services/cookie.service';
import {
  consumerPrivacyDisclosure,
  eDisclosure,
  personalDepositAccountDisclosure,
  odlDisclosure,
  fairTradeDisclosure,
  tinDisclosure,
} from './constants/disclosures.constants';

import { productCodeDebitCard, orderTypeNameDebitCard } from './constants/investor-checking.constants';

@Injectable({
  providedIn: 'root',
})
export class InvestorCheckingService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient, private cookieHelper: CookieHelperService) {
    super(http, settings, 'aas/investorChecking');
  }

  addInvestorCheckingAccount(isDebitCardSelected: boolean): WebApiResponse<InvestorCheckingResponse> {
    const request: Partial<InvestorCheckingRequest> = {
      identityId: this.cookieHelper.getUserId(),
      isOnlyODL: false,
      disclosures: [
        consumerPrivacyDisclosure,
        eDisclosure,
        personalDepositAccountDisclosure,
        fairTradeDisclosure,
        tinDisclosure,
      ],
      orders: isDebitCardSelected ? [this.addDebitCardOrder()] : null,
      source: 'Web',
    };
    return this.post(null, request);
  }

  addOdlAccountToInvestorCheckingAccount(accountNumber: string): WebApiResponse<InvestorCheckingResponse> {
    const request: Partial<InvestorCheckingRequest> = {
      identityId: this.cookieHelper.getUserId(),
      isOnlyODL: true,
      accountNumber: accountNumber,
      disclosures: [odlDisclosure],
      source: 'Web',
    };
    return this.post(null, request);
  }

  addInvestorCheckingAndOdlAccounts(isDebitCardSelected: boolean): WebApiResponse<InvestorCheckingResponse> {
    const request: Partial<InvestorCheckingRequest> = {
      identityId: this.cookieHelper.getUserId(),
      isOnlyODL: false,
      disclosures: [
        consumerPrivacyDisclosure,
        eDisclosure,
        personalDepositAccountDisclosure,
        odlDisclosure,
        fairTradeDisclosure,
        tinDisclosure,
      ],
      orders: isDebitCardSelected ? [this.addDebitCardOrder()] : null,
      source: 'Web',
    };
    return this.post(null, request);
  }

  addDebitCardOrder(): Order {
    const order: Order = {
      productCode: productCodeDebitCard,
      orderTypeName: orderTypeNameDebitCard,
      selected: true,
      order: true,
    };
    return order;
  }
}
