import * as angular from 'angular';
import { IScope } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { BillPayHelper } from 'bill-pay/bill-pay.helper';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { CustomerLimitsHelper } from 'services/customer-limits.helper';
import { ModalService } from 'services/modal.service';
import { IBillPayService } from 'services/typings/IBillPayService';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { UserAccountType } from 'typings/app/bills/UserAccountType';

import { Inject } from '../../decorators/decorators';
import { PaymentsService } from '../../services/payments.service';
import { BillPayRouterHelper } from '../bill-pay-router.helper';
import { Frequency } from '../typings/Frequency';
import { Payment } from '../typings/Payment';
import { PaymentInfo } from '../typings/PaymentInfo';
import { Recipient } from '../typings/Recipient';
import { mapOption, ReminderOption } from '../typings/ReminderOption';
import { mapStopOption, StopSeriesOption } from '../typings/StopSeriesOption';
@Inject(
  '$location',
  '$state',
  '$rootScope',
  '$stateParams',
  '$scope',
  '$filter',
  'modalService',
  'paymentsService',
  'billPayHelper',
  'serviceHelper',
  'cachedAccountsService',
  'dateHelper',
  'dateRangePickerOptions',
  'billPayService',
  'billPayRouterHelper',
  'customerLimitsHelper'
)
export class SchedulePaymentController {
  recipient: Recipient = {};
  payment: Payment = {};
  paymentForm: ng.IFormController;

  accountsFrom: UserAccountType[];
  accountFrom: UserAccountType;
  isLoadingAccounts: boolean;
  accountTypes: string[];
  defaultAccount: UserAccountType;
  defaultAccountCode: string;

  frequencies: GenericOption[];
  selectedFrequency: GenericOption;
  reminderOptions: GenericOption[];
  selectedReminder: GenericOption;
  selectedStopSeriesOption: GenericOption;
  stopSeriesOptions: GenericOption[];
  tableRecords: GenericOption[] = [];

  isRecurrent = false;
  numberOfPayments = 1;
  isValidNumberOfPayments = true;
  displayNumberOfPayments = false;
  displayReminderOptions = false;
  showReminderLabel = false;
  isLoading = false;
  showConfirmationPage = false;
  // shows the label informing reminder will be applied in the nex payment
  isInvalidReminderOption = false;
  isLoadingRecipient = true;

  datePickerOptions: any;
  datePicker: moment.Moment;
  selectedDate: moment.Moment;
  // shows the label with withdrawal or check information
  processingPaymentLabel: string;
  emailAddress: string;
  recipientDisplayName = '';
  // decides where to callback when cancel tranfer
  callback: string;
  confirmationPageTitle = '';
  customerLimits: CustomerLimits;
  messageTooltip: string;
  private readonly electronicRecipientLabel = 'Payment will be withdrawn on';
  private readonly checkRecipientLabel = 'Your check will be mailed on';
  private datePickerLabel = 'Deliver By';

  constructor(
    private location: angular.ILocationService,
    private state: IStateService,
    private rootScope: ng.IRootScopeService,
    private params: IStateParamsService,
    private scope: IScope,
    private readonly filter: ng.IFilterService,
    private modalService: ModalService,
    private paymentsService: PaymentsService,
    private _billPayHelper: BillPayHelper,
    private serviceHelper: IServiceHelper,
    private cachedAccountsService: CachedAccountsService,
    private dateHelper: DateHelper,
    private dateRangePickerOptions: any,
    private billPayService: IBillPayService,
    private billPayRouterHelper: BillPayRouterHelper,
    private customerLimitsHelper: CustomerLimitsHelper
  ) {}

  $onInit(): void {
    this.recipient = this.params['recipient'];
    const recipientId = this.params['recipientId'];
    if (this.recipient == null && recipientId == null) {
      this.location.path('/billpay/pay');
      return;
    }

    if (this.recipient == null) {
      this.getRecipientDetails(recipientId);
    } else {
      this.initValues();
    }
  }

  initValues() {
    this.callback = this.params['callback'];
    this.accountsFrom = this.cachedAccountsService.paymentAccounts?.filter(
      acc => !acc.isSBB
    );
    this.getDefaultAccount();
    this.accountTypes = this.setAccountTypes();
    this.frequencies = this._billPayHelper.getFrequencies();
    this.reminderOptions = this.getReminderOptions();
    this.filterReminderOptions();
    this.stopSeriesOptions = this.getStopSeriesOptions();

    this.selectedDate = moment(
      this.recipient.nextAvailablePaymentDeliveryDate
    ).clone();
    this.datePicker = this.selectedDate;
    if (!this.accountsFrom) {
      this.isLoadingAccounts = true;
    } else {
      this.selectFromAccount();
    }

    this.datePickerOptions = angular.extend(
      angular.copy(this.dateRangePickerOptions),
      {
        // startDate: this.selectedDate,
        minDate: this.selectedDate,
        maxDate: this.selectedDate.clone().add(13, 'M'),
        singleDatePicker: true,
        autoUpdateInput: true,
        eventHandlers: {
          'hide.daterangepicker': () => {
            // only if date changed process the event
            if (
              !moment(this.datePicker.toDate()).isSame(
                this.payment.deliveryDate
              )
            ) {
              this.deliverOnChange();
              if (!this.isRecurrent) {
                this.filterReminderOptions();
              }
              this.checkReminderOptionsDisplay();
              this.shouldDisplayInvalidReminderLabel();
            }
          },
        },
        isInvalidDate: (date: any) => {
          return !this.dateHelper.isValidDate(date);
        },
      }
    );

    this.payment.processingDate = this.recipient.nextAvailablePaymentProcessingDate;
    this.payment.deliveryDate = this.datePicker.toDate();

    this.rootScope['profileInfo']
      ? (this.emailAddress = this.rootScope['profileInfo'].primaryEmail)
      : this.rootScope.$on(
          'profileInfoLoaded',
          () => (this.emailAddress = this.rootScope['profileInfo'].primaryEmail)
        );
    this.scope.$on('accountsLoaded', () => {
      this.accountsFrom = this.cachedAccountsService.paymentAccounts?.filter(
        acc => !acc.isSBB
      );
      this.selectFromAccount();
      this.isLoadingAccounts = false;
    });

    this.accountFrom = this.accountsFrom[0];
    this.selectedFrequency = this.frequencies[0];
    this.selectedReminder = this.reminderOptions[0];
    this.processingPaymentLabel =
      this.recipient.paymentMethod == 1
        ? this.electronicRecipientLabel
        : this.checkRecipientLabel;
    this.recipientDisplayName = this.recipient.nickName
      ? this.recipient.nickName
      : this.recipient.displayName;
    this.setupOnetimeValues();
    this.isLoadingRecipient = false;
    this.getCustomerLimits();
  }

  /**
   *
   * @param recipientId
   * Gets the recipient if is not passed as a paremeter.
   */
  getRecipientDetails(recipientId: number) {
    this.isLoadingRecipient = true;
    this.billPayService
      .getRecipient(recipientId)
      .then(res => {
        this.recipient = res.data;
        this.initValues();
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoadingRecipient = false;
      });
  }

  /**Shows a modal asking for confirmation */
  cancel(): void {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          hasCancelButton: true,
          cancelText: 'No',
          bodyText: `<h3>Are you sure you want to cancel this setup?</h3>`,
          hasRedirectLink: true,
        }
      )
      .then(() => {
        if (this.callback && this.callback != null) {
          this.billPayRouterHelper.setActiveRecipient(
            this.recipient,
            this.callback
          );
        }
        this.state.go('udb.billPay.pay');
      });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  hasInsufficientFunds() {
    if (
      this.formatDate(this.payment.processingDate).slice(0, 10) ===
      new Date().toISOString().slice(0, 10)
    ) {
      const accountCode = this.payment.accountCode
        ? this.payment.accountCode
        : this.accountFrom.accountCode;
      const account = this.accountsFrom.find(
        a => accountCode === a.accountCode
      );

      return this.payment.amount > account.balance;
    }

    return false;
  }
  /**Validates and send the payment when userclicks on Schedue Payment button */
  submitPayment(e: any) {
    e.preventDefault();
    if (
      this.isInvalidAmount() ||
      this.hasInsufficientFunds() ||
      (this.isRecurrent && !this.isValidNumberOfPayments)
    ) {
      return;
    }

    this.isLoading = true;
    this.payment.isRecurring = this.isRecurrent;
    this.payment.numberOfPayments = this.numberOfPayments;
    this.payment.accountCode = this.accountFrom.accountCode;
    this.payment.accountLabel = this._billPayHelper.getAccountLabel(
      this.accountFrom
    );
    this.payment.payeeId = this.recipient.payeeId;

    this.billPayService
      .addScheduledPayment(this.payment, true)
      .then(res => {
        if (res.statusCode == 202) {
          this.duplicatePaymentDetected(res.data);
        } else {
          this.fillTableRecords();
          this.confirmationPageTitle = this.isRecurrent
            ? 'Recurring payments scheduled.'
            : 'Payment scheduled.';
          this.showConfirmationPage = true;
          this.paymentsService.clearCache();
          this.rootScope.$broadcast('updateTransactions');
        }
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
      });
  }

  createDuplicateRecords(payments: PaymentInfo[]): string {
    let records = '';
    for (const payment of payments) {
      const row = `<tr class="text-left">
      <td class="text-bold truncate-recipient">${this.recipientDisplayName}</td>
      <td>$ ${this.parseAmount(payment.amount.valueOf())}</td>
      <td>${moment(payment.date).format('MM/DD/YYYY')}</td>
      </tr>`;
      records += row;
    }

    return records;
  }

  duplicatePaymentDetected(payments: any[]) {
    const last = payments[0].paymentId;
    payments.forEach(item => {
      this.modalService
        .show(
          { windowClass: 'modal-service' },
          {
            bodyText: `
        <div>
          <h3 class="duplicate-title">
          You may be scheduling a duplicate payment.</h3>
          <p class="existing-payment">Existing payment: </p>
          <div class="responsive-table-container">
            <table class="table--primary">
              <thead>
                <tr>
                  <th>Recipient</th>
                  <th>Amount To Pay</th>
                  <th>Deliver On</th>
                </tr>
              </thead>
              <tbody>
                ${this.createDuplicateRecords([item])}
              </tbody>
            </table>
          </div>
          <p class="duplicate-continue"> Would you like to continue?</p>
        </div>`,
            okText: 'Continue Scheduling Payment',
            cancelText: 'Do not Schedule Payment',
            okButtonClass:
              'uk-btn solid secondary sm dup-payments__buttons-button',
            cancelButtonClass:
              'uk-btn outline primary icon-right sm cancel-duplicate',
            containerButtonsClass: 'dup-payments__buttons',
            hasRedirectLink: false,
            hasClose: true,
            hasIcon: true,
            icon: 'bofi-warning',
          }
        )
        .then(() => {
          this.payment.HasDuplicateWarning = true;

          if (last === item.paymentId) {
            this.submitDuplicatePayment();
          }
        })
        .catch(() => {
          if (last === item.paymentId && this.payment.HasDuplicateWarning) {
            this.submitDuplicatePayment();
          } else {
            return;
          }
        });
    });
  }

  submitDuplicatePayment() {
    this.isLoading = true;
    this.billPayService
      .addScheduledPayment(this.payment, false)
      .then(() => {
        this.fillTableRecords();
        this.confirmationPageTitle = this.isRecurrent
          ? 'Recurring payments scheduled.'
          : 'Payment scheduled.';
        this.showConfirmationPage = true;
        this.paymentsService.clearCache();
        this.rootScope.$broadcast('updateTransactions');
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**Parse the decimal amount into a currency string */
  parseAmount(amount: any): string {
    return Number.parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  /**Fills the receipt table after the payment was sent succesffuly  */
  fillTableRecords() {
    const amountString = this.parseAmount(this.payment.amount.valueOf());

    this.tableRecords.push({
      label: 'Recipient',
      subvalue: this.recipientDisplayName,
    });
    if (this.recipient.paymentMethod == 1) {
      this.tableRecords.push({
        label: 'Account Number',
        subvalue: this.recipient.paymentAccount,
      });
    }
    this.tableRecords.push({
      label: 'Payment Type',
      subvalue: this.recipient.paymentMethod == 1 ? 'Electronic' : 'Check',
    });
    this.tableRecords.push({
      label: 'Pay From',
      subvalue: this.accountFrom.nickName,
    });
    this.tableRecords.push({
      label: 'Amount To Pay',
      subvalue: '$' + amountString,
    });
    this.tableRecords.push({
      label: 'Frequency',
      subvalue: this.selectedFrequency.label,
    });
    this.tableRecords.push({
      label: this.datePickerLabel,
      subvalue: moment(this.payment.deliveryDate).format('MM/DD/YYYY'),
    });
    if (this.isRecurrent) {
      this.tableRecords.push({
        label: 'Stop Series After',
        subvalue:
          this.selectedStopSeriesOption.value ==
          StopSeriesOption.NumberOfPayments
            ? 'A Number of Payments'
            : 'I Choose to Stop',
      });
      if (
        this.selectedStopSeriesOption.value == StopSeriesOption.NumberOfPayments
      ) {
        this.tableRecords.push({
          label: 'Number of Payments',
          subvalue: this.numberOfPayments.toString(),
        });
      }
    }
    this.tableRecords.push({
      label: 'Memo',
      subvalue: this.payment.memo ? this.payment.memo : '',
    });
    if (this.selectedReminder.value != ReminderOption.DontSend) {
      this.tableRecords.push({
        label: 'Payment Reminder',
        subvalue: this.selectedReminder.label,
      });
    }
  }

  /** Changes the selected and delivery date */
  deliverOnChange(): void {
    if (!this.recipient || !this.recipient.businessDaysToDeliver) return;

    this.payment.deliveryDate = this.datePicker.toDate();

    this.payment.processingDate = this.dateHelper
      .getProcessingDate(
        moment(this.payment.deliveryDate),
        -this.recipient.businessDaysToDeliver
      )
      .toDate();
  }

  /** Returns true if the Send On field is invalid false otherwise */
  isInvalidStartOn(): boolean {
    return (
      this.paymentForm.date.$invalid &&
      (this.paymentForm.$submitted || this.paymentForm.date.$touched)
    );
  }

  /** Returns true if the From Account field is invalid false otherwise */
  isInvalidFromAccount(): boolean {
    return (
      this.paymentForm.payFrom.$invalid &&
      (this.paymentForm.$submitted || this.paymentForm.payFrom.$touched)
    );
  }

  /**
   * Changes the value of the selected 'Payment Reminder' option
   */
  reminderChanged() {
    const isInvalidReminder = this.isInvalidReminder();
    if (!isInvalidReminder) {
      // payment has alert if something different to not send is selected.
      const paymentHasAlert =
        this.selectedReminder.value != ReminderOption.DontSend;
      if (paymentHasAlert) {
        this.payment.daysBeforeProcessingSpecified = true;
        this.payment.daysBeforeProcessingAlert = this.selectedReminder.value;
        this.payment.alertOnCompletion = true;
        this.shouldDisplayInvalidReminderLabel();
      } else {
        this.isInvalidReminderOption = false;
      }
      // if payment has alert show label at side.
      this.showReminderLabel = paymentHasAlert;
    }
  }

  shouldDisplayInvalidReminderLabel() {
    if (this.isRecurrent) {
      const daysBeetween = moment(this.payment.processingDate).diff(
        moment(),
        'days'
      );
      const availableOptions = this.getReminderOptions().filter(
        r => r.value <= daysBeetween
      );
      const result = availableOptions.find(
        r => r.value == this.selectedReminder.value
      );
      this.isInvalidReminderOption = result ? false : true;
    } else {
      this.isInvalidReminderOption = false;
    }
  }

  /**Handles payment logic depending on the stop series option selected */
  stopSeriesChanged() {
    if (this.selectedStopSeriesOption.value == StopSeriesOption.IChooseToStop) {
      this.payment.repeatUntilCanceled = true;
      this.payment.endOnNumberOfPayments = false;
      this.payment.numberOfPayments = 0;
      this.numberOfPayments = 0;
      this.displayNumberOfPayments = false;
    } else {
      this.payment.repeatUntilCanceled = false;
      this.payment.endOnNumberOfPayments = true;
      this.displayNumberOfPayments = true;
      this.numberOfPayments = 2;
      this.isAValidNumberOfPayments();
    }
  }

  /**Check and active flag to display error if Number of Payments is invalid */
  isAValidNumberOfPayments() {
    this.isValidNumberOfPayments =
      this.numberOfPayments > 1 && this.numberOfPayments < 100;
  }

  /**Handles payment logic depending on the frecuency selected */
  frequencyChanged() {
    const isInvalidFrequency = this.isInvalidFrequency();
    if (!isInvalidFrequency) {
      // if the payment is already recurrent don't recalculate the ReminderOptions
      const shouldChangeReminderOptions = this.isRecurrent ? false : true;

      // isRecurrent handle hide-show logic for additional inputs in the form
      this.isRecurrent = this.selectedFrequency.value != Frequency.OneTime;
      this.datePickerLabel = this.isRecurrent
        ? 'Start Series On'
        : 'Deliver By';
      this.payment.paymentFrequency = this.selectedFrequency.value;
      this.showReminderLabel = false;

      if (this.isRecurrent) {
        this.payment.isRecurring = true;
        this.numberOfPayments = 2;
        if (shouldChangeReminderOptions) {
          this.reminderOptions = this.getReminderOptions();
          this.checkReminderOptionsDisplay();
          this.shouldDisplayInvalidReminderLabel();
        }
      } else {
        this.isRecurrent = false;
        this.numberOfPayments = 1;
        this.isInvalidReminderOption = false;
        this.setupOnetimeValues();
        this.filterReminderOptions();
        this.checkReminderOptionsDisplay();
      }
      this.selectedReminder = this.reminderOptions[0];
    }
  }

  getDeliveryDateTooltip(): string {
    return this._billPayHelper.getHeaderTooltip('deliveryDate');
  }

  /** Checks if is necessay to show the Payment Reminder section
   * if no, reset all the payment fields related to payment reminder
   */
  checkReminderOptionsDisplay() {
    this.displayReminderOptions =
      this.reminderOptions.length > 1 ? true : false;
    if (!this.displayNumberOfPayments) {
      // reset all reminder fields from payment object
      this.payment.daysBeforeProcessingSpecified = false;
      this.payment.daysBeforeProcessingAlert = 0;
      this.payment.alertOnCompletion = false;
    }
  }

  /**Validates the reminder option selected */
  isInvalidReminder(): boolean {
    const result =
      this.paymentForm.reminder.$invalid &&
      (this.paymentForm.$submitted || this.paymentForm.reminder.$touched);

    return result;
  }

  /** Returns true if the Frequency field is invalid false otherwise */
  isInvalidFrequency(): boolean {
    return (
      this.paymentForm.frequency.$invalid &&
      (this.paymentForm.$submitted || this.paymentForm.frequency.$touched)
    );
  }

  /**Returns the list of available reminder options based on the delivery date */
  filterReminderOptions() {
    if (
      moment(this.recipient.nextAvailablePaymentProcessingDate).isSame(
        this.payment.processingDate
      )
    ) {
      this.reminderOptions = this.getReminderOptions().filter(
        r => r.value == ReminderOption.DontSend
      );
    } else {
      const daysBeetween = moment(this.payment.processingDate).diff(
        moment(),
        'days'
      );
      this.reminderOptions = this.getReminderOptions().filter(
        r => r.value <= daysBeetween
      );
    }
    this.selectedReminder = this.reminderOptions[0]; // .find(r => r.value == ReminderOption.DontSend);
  }
  /** Returns true if the Amount field is invalid false otherwise */
  isInvalidAmount(): boolean {
    if (
      !this.payment.amount ||
      this.payment.amount <= 0 ||
      isNaN(this.payment.amount)
    ) {
      this.paymentForm.amount.$setValidity('required', false);
    }

    const result =
      this.paymentForm.amount.$invalid &&
      (this.paymentForm.$submitted || this.paymentForm.amount.$touched);

    return result;
  }

  payFromChanged(): void {
    if (!this.accountFrom) {
      this.payment.accountCode = null;

      return;
    }

    this.payment.accountCode = this.accountFrom.accountCode;

    this.payment.accountLabel = this._billPayHelper.getAccountLabel(
      this.accountFrom
    );

    // uncomment this when money marketwarning modal its a requirement
    // const accountType = this.accountFrom.accountType.trim().toLowerCase();
    // if (this.accountTypes.indexOf(accountType) !== -1) {
    //   this.modalService.show({}, {
    //     icon: 'bofi-information',
    //     hasCancelButton: false,
    //     bodyText: `<h3>Note that only six transactions are allowed per month from this account.
    //                   Each transaction over six will occur a charge.</h3>`
    //   });
    // }
  }

  showScheduledPaymentsLimits(): void {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: null,
          hasCancelButton: true,
          cancelText: 'Back',
          bodyText: this.messageTooltip,
          hasRedirectLink: true,
        }
      )
      .then(() => {});
  }

  /**Sets the payment with one time values */
  private setupOnetimeValues() {
    this.payment.numberOfPayments = 1;
    this.payment.endOnNumberOfPayments = true;
    this.payment.notifyMeNumberOfPayments = true;
    // in case frequency change again to One Time setup local var to 1 for UI propouses
    this.numberOfPayments = 1;
  }

  /**
   * Returns the types of accounts which could be charge per frequency
   */
  private setAccountTypes(): string[] {
    return ['money market', 'savings'];
  }

  /**
   * Returns the complete list of available options for stop series
   */
  private getStopSeriesOptions(): GenericOption[] {
    return [
      {
        value: StopSeriesOption.NumberOfPayments,
        label: mapStopOption(StopSeriesOption.NumberOfPayments),
      },
      {
        value: StopSeriesOption.IChooseToStop,
        label: mapStopOption(StopSeriesOption.IChooseToStop),
      },
    ];
  }

  /**
   * Returns the complete list of available options for payment reminder
   */
  private getReminderOptions(): GenericOption[] {
    return [
      {
        value: ReminderOption.DontSend,
        label: mapOption(ReminderOption.DontSend),
      },
      {
        value: ReminderOption.OneDayBefore,
        label: mapOption(ReminderOption.OneDayBefore),
      },
      {
        value: ReminderOption.TwoDaysBefore,
        label: mapOption(ReminderOption.TwoDaysBefore),
      },
      {
        value: ReminderOption.ThreeDaysBefore,
        label: mapOption(ReminderOption.ThreeDaysBefore),
      },
      {
        value: ReminderOption.FiveDaysBefore,
        label: mapOption(ReminderOption.FiveDaysBefore),
      },
      {
        value: ReminderOption.OneWeekBefore,
        label: mapOption(ReminderOption.OneWeekBefore),
      },
    ];
  }

  /*** Gets the default account for payments */
  private getDefaultAccount(): void {
    this.paymentsService
      .getDefaultAccount()
      .then(res => {
        this.defaultAccount = res.data;
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }
  /** Loads the from accounts to fill the dropdown */
  private selectFromAccount(): void {
    const accountCode = this.defaultAccountCode;
    this.accountFrom = this._billPayHelper.getSelectedAccount(
      this.accountsFrom,
      accountCode
    );
    this.payFromChanged();
  }

  private getCustomerLimits() {
    this.customerLimitsHelper
      .fetchCustomerLimits()
      .then(res => {
        this.customerLimits = res;
        this.messageTooltip = `<div class='schedule-limits-text'>
        <div class='hidden-lg visible-xs visible-md visible-sm'><strong>Axos limits transfers to external accounts.</strong><br><br></div>
        Transfer limits are determined individually, based on the account type and customer history.
        Each funds transfer cannot exceed the lowest remaining amount of the following transfer limits:
        <br><br>
          <div class='hidden-sm visible-lg fcol-md-12'>
            <ul>
              <li> ${this.filter('currency')(
                this.customerLimits.billPayMaxPaymentAmount
              )} limit per transaction</li>
              <li> Daily Limit: ${this.filter('currency')(
                this.customerLimits.billPayMaxAmountPerDay
              )}</li>
              <li> 30-day Limit: ${this.filter('currency')(
                this.customerLimits.billPayMaxAmountPerMonth
              )}</li>
            </ul>
          </div>
        <div class='hidden-lg visible-xs visible-md visible-sm'>
        <i class="fa fa-chevron-right limit-icon"></i> ${this.filter(
          'currency'
        )(this.customerLimits.billPayMaxPaymentAmount)} limit per transaction
         <br><br>
        <i class="fa fa-chevron-right limit-icon"></i> Daily Limit: ${this.filter(
          'currency'
        )(this.customerLimits.billPayMaxAmountPerDay)}<br><br>
        <i class="fa fa-chevron-right limit-icon"></i> 30-day Limit: ${this.filter(
          'currency'
        )(this.customerLimits.billPayMaxAmountPerMonth)}</div></div>`;
      })
      .catch(error => {
        this.serviceHelper.errorHandler(error);
        this.isLoading = false;
      });
  }
}
