import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasSharedModule } from '../../aas-shared/aas-shared.module';
import { initialStatementsAndDocumentsState, statementsAndDocumentsReducer } from './core';
import { AasStoreFeatures } from '../../aas-core';
import { SharedModule } from '../../../../shared/shared.module';
import { StatementsAndDocumentsMatModule } from './statements-and-documents-mat.module';
import { StatementsAndDocumentsUk2Module } from './statements-and-documents-uk2.module';
import { StatementsAndDocumentsTileComponent } from './view/statements-and-documents-tile/statements-and-documents-tile.component';
import { StatementsService } from './core/services/statements-service';
import { StatementsAndDocumentsPageComponent } from './view/statements-and-documents-page/statements-and-documents-page.component';

@NgModule({
  declarations: [StatementsAndDocumentsTileComponent, StatementsAndDocumentsPageComponent],
  providers: [StatementsService],

  imports: [
    StatementsAndDocumentsMatModule,
    StatementsAndDocumentsUk2Module,
    AasSharedModule,
    SharedModule,
    StoreModule.forFeature(AasStoreFeatures.StatementsAndDocuments, statementsAndDocumentsReducer, {
      initialState: initialStatementsAndDocumentsState,
    }),
  ],
  exports: [StatementsAndDocumentsTileComponent, StatementsAndDocumentsPageComponent],
})
export class StatementsAndDocumentsModule {}
