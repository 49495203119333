import { LogLevel } from '@core/enums';

export class WebLog {
  stack: string;

  constructor(
    public logLevel: LogLevel,
    public message: string,
    public jsonData: Record<string, string> = null
  ) {}
}
