import * as angular from 'angular';
import { P2PInfoModalComponent } from '@app/transfers/components/modals/p2p-info-modal/p2p-info-modal.component';
import { SendMoneyComponent } from '@app/transfers/components';
import { downgradeComponent } from '@angular/upgrade/static';

export const SendMoneyLegacyModule = angular
  .module('udb.sendMoney', ['ui.router', 'angucomplete-alt'])
  .directive('appP2pInfoModal', downgradeComponent({ component: P2PInfoModalComponent }))
  .directive('appSendMoney', downgradeComponent({ component: SendMoneyComponent }))
  .config([
    '$stateProvider',
    (stateProvider: angular.ui.IStateProvider) => {
      stateProvider.state('udb.transfers.p2p.sendMoney', {
        url: '/send-money',
        views: {
          'p2p-content': {
            template: `<app-send-money></app-send-money>`,
          },
        },
      });
    },
  ]).name;
