import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberAbs',
})
export class NumberAbsPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | string, digitsInfo?: string, locale?: string) {
    if ((!value && value !== 0) || isNaN(value as number)) return '--';

    return this.decimalPipe.transform(Math.abs(value as number), digitsInfo, locale);
  }
}
