<div
  *ngFor="let option of timePeriods"
  class="option"
  [class.selected]="(filter$ | async) == option"
  (click)="selectTimePeriod(option)"
>
  {{ option }}
  <app-svg-icon
    class="checkmark"
    [iconName]="'utility-checkmark'"
    [styles]="{ width: '1rem', height: '1rem', fill: '#2F5B88' }"
  ></app-svg-icon>
</div>

<div class="text-center mt-4">
  <span class="link" (click)="reset()">Reset</span>
</div>
