import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { Equity } from '@app/axos-trading/models/equity.model';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import { ClearingService } from '@app/axos-trading/services/clearing.service';

@Component({
  selector: 'app-search-equities',
  templateUrl: './search-equities.component.html',
  styleUrls: ['./search-equities.component.scss'],
})
export class SearchEquitiesComponent implements OnInit, OnDestroy {
  @Input() accountId: number;
  searchStock: UntypedFormControl;
  subsink = new SubSink();
  isSearchingInProgress = false;
  equities: Equity[];
  selectedEquity: Equity;
  isOpenDropdown = false;
  isOpenTable = false;
  constructor(
    private fb: UntypedFormBuilder,
    private clearingService: ClearingService,
    private routeHelper: TradingRoutingHelperService
  ) {}

  ngOnInit(): void {
    this.searchStock = this.fb.control('');
    this.subsink.sink = this.searchStock.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(value => {
        if (value) this.searchEquities(value);
      });
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
  searchEquities(value: string) {
    this.isSearchingInProgress = true;
    this.subsink.sink = this.clearingService.searchEquities(value).subscribe(equities => {
      this.equities = equities;
      if (this.equities?.length > 0) {
        this.isOpenDropdown = true;
        this.isOpenTable = false;
      }
      this.isSearchingInProgress = false;
    });
  }

  resultClicked(symbol: string) {
    this.searchStock.setValue('');
    this.selectedEquity = this.equities.find(item => item.symbol === symbol);
    this.equities = [];
    this.isOpenDropdown = false;
    this.routeHelper.goToPositionStockDetail(this.accountId, symbol);
  }

  showAllSearchResult() {
    this.isOpenTable = true;
    this.isOpenDropdown = false;
  }

  @HostListener('document:click')
  collapse() {
    if (!this.isOpenDropdown) return;
    this.isOpenDropdown = false;
  }
}
