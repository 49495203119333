<div class="check-confirmation">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <h1>
        <img [src]="'/img/icons/billPay/checkmark.svg'" width="100" alt="Favorite Recipient" aria-hidden="true" />
      </h1>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-md-push-2 text-center">
        <h4>
          <span [textContent]="recipient.payeeName"></span>
          Added.
        </h4>
        <p>We've sent an email with this information to {{ email }}</p>

        <table class="table table-striped confirmation__table">
          <tbody class="text-bold">
            <tr [hidden]="false">
              <td class="text-left"></td>
              <td class="text-right">
                <span></span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Recipient
                <br class="show-responsive" />
                <span class="text-right show-responsive" [textContent]="recipient.payeeName"></span>
              </td>
              <td class="text-right hide-responsive">
                <span [textContent]="recipient.payeeName"></span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Account/Reference Number/Memo
                <br class="show-responsive" />
                <span class="text-right show-responsive" [textContent]="recipient.paymentAccount"></span>
              </td>
              <td class="text-right hide-responsive">
                <span [textContent]="recipient.paymentAccount"></span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Recipient Type
                <br class="show-responsive" />
                <span class="text-right show-responsive">Check</span>
                <app-tooltip-shadowdom tooltip="{{ detailsTooltip }}"></app-tooltip-shadowdom>
              </td>
              <td class="text-right hide-responsive">
                <span>Check</span>
                <app-tooltip-shadowdom tooltip="{{ detailsTooltip }}"></app-tooltip-shadowdom>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Recipient’s Address
                <span class="text-right show-responsive">
                  <br />
                  {{ recipient.addressLine }}
                  <br />
                  {{ recipient.addressLine2 }}
                  <br *ngIf="recipient.addressLine2" />
                  {{ recipient.city }}, {{ recipient.stateCode }},
                  {{ recipient.zipCode }}
                </span>
              </td>
              <td class="text-right hide-responsive">
                <span>
                  {{ recipient.addressLine }}
                  <br />
                  {{ recipient.addressLine2 }}
                  <br *ngIf="recipient.addressLine2" />
                  {{ recipient.city }}, {{ recipient.stateCode }},
                  {{ recipient.zipCode }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Nickname
                <br show-responsive />
                <span class="text-right show-responsive" [textContent]="recipient.nickName"></span>
              </td>
              <td class="text-right hide-responsive">
                <span [textContent]="recipient.nickName"></span>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          To modify or delete this recipient, go to
          <span class="recipient-name-confirmation">
            {{ recipient.payeeName | possessive }}
          </span>
          Details tab in the Bill Pay Dashboard.
        </p>
        <div class="text-center">
          <button (click)="redirectToSchedule()" class="uk-btn solid secondary lg mobile-button">
            <span>
              Schedule A Payment
            </span>
          </button>
        </div>
        <div class="text-center">
          <a [href]="'billpay/pay'">Go to Bill Pay Dashboard</a>
        </div>
      </div>
    </div>
  </div>
</div>
<span class="question-icon hidden-xs">
  ?
  <!---->
</span>
