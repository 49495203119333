import { createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import {
  loadAccountFirmContactsSuccessAction,
  loadAccountInformationAction,
  setSelectedAccountDetailsAction,
} from '../account-details.actions';
import { initialAccountDetailsState } from '../types';

export const accountDetailsReducer = createReducer(
  initialAccountDetailsState,

  on(loadAccountInformationAction, (state, action) => {
    const indexOfUpdate = state.accountInformationList?.findIndex(
      x => x.accountNumber === action.payload.accountNumber
    );
    if (indexOfUpdate >= 0) {
      return {
        ...state,
        accountInformationList: state.accountInformationList.map(item => {
          return item.accountNumber === action.payload.accountNumber ? (item = action.payload) : item;
        }),
      };
    } else {
      const accountInformationList = state.accountInformationList ? state.accountInformationList : [];
      return {
        ...state,
        accountInformationList: [...accountInformationList, action.payload],
      };
    }
  }),

  on(setSelectedAccountDetailsAction, (state, action) => {
    return {
      ...state,
      selectedAccountDetails: action.payload,
    };
  }),

  on(loadAccountFirmContactsSuccessAction, (state, action) => {
    const updateRequired =
      state.accountFirmContactsList.findIndex(x => x.accountNumber === action.payload.accountNumber) >= 0;

    if (updateRequired) {
      return {
        ...state,
        accountFirmContactsList: state.accountFirmContactsList.map(accountFirmContact =>
          accountFirmContact.accountNumber === action.payload.accountNumber ? { ...action.payload } : accountFirmContact
        ),
      };
    }

    return {
      ...state,
      accountFirmContactsList: [...state.accountFirmContactsList, action.payload],
    };
  }),

  on(signOut, () => {
    return initialAccountDetailsState;
  })
);
