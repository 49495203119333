export enum CommunicationIcons {
  User = 'communication-user',
  UserCheck = 'communication-user-check',
  UserAdd = 'communication-user-add',
  UserCog = 'communication-user-cog',
  Users = 'communication-users',
  AddressBook = 'communication-address-book',
  Home = 'communication-home',
  HomeCheck = 'communication-home-check',
  HomeEx = 'communication-home-x',
  Headset = 'communication-headset',
  Envelope = 'communication-envelope',
  EnvelopeCheck = 'communication-envelope-check',
  EnvelopeEx = 'communication-envelope-x',
  SpeechBubble = 'communication-speech-bubble',
  SpeechBubble2 = 'communication-speech-bubble-2',
  SpeechBubbles = 'communication-speech-bubbles',
  MobilePhone = 'communication-mobile-phone',
  MobilePhoneCheck = 'communication-mobile-phone-check',
  MobilePhoneEx = 'communication-mobile-phone-x',
  Phone = 'communication-phone',
  PhoneRinging = 'communication-phone-ringing',
  PhoneCheck = 'communication-phone-check',
  PhoneEx = 'communication-phone-x',
  Calendar = 'communication-calendar',
  CalendarCheck = 'communication-calendar-check',
}
