import { stringsAreEqual } from '@app/utils';
import { OlbSettings } from '@core/models';
import { AxosInvestHelperService } from '@app/core/services/axos-invest.service';
import { ExternalBankProvider } from '@shared/models';

import { AccountSelectItem, AggregatedAccount, OlbAccount } from '../models';

/**
 * Generates an account description for an AccountSelectItem.
 *
 * @param account Account to determine the information.
 * @param brandName Current brand name. Such as UFB Direct.
 *
 * @returns A string representing the account description.
 */
function getDropdownItemDescription(account: AggregatedAccount, brandName: string): string {
  const accountType = account.accountType.toLowerCase();
  let description: string;

  if (account.isExternal) {
    description = `${account.bankName} ${account.accountMask}`;
  } else {
    switch (accountType) {
      case 'invest':
        description = `${AxosInvestHelperService.AXOS_INVEST} | ${account.bankName}`;
        break;
      default:
        description = account.bankName || `${brandName} Bank ${account.accountMask}`;
        break;
    }
  }

  return description;
}

/**
 * Gets the path of the image that corresponds to the account.
 *
 * @param providers External bank providers available.
 * @param account Account used to determine the image.
 * @param brand Current brand. Such as ufb.
 *
 * @returns A string with the URL of the correct image to display.
 */
function getDropdownItemImage(providers: ExternalBankProvider[], account: OlbAccount, brand: string): string {
  if (account.isExternal) {
    const bank = providers.find(provider => stringsAreEqual(account.bankName, provider.providerName));
    const externalIcon = bank?.logoName ?? 'default.svg';

    return externalIcon;
  }

  switch (account.accountType) {
    case 'invest':
    case 'trading':
      return 'axos-invest_round_logo.svg';
    default:
      return brand + '_round_logo.svg';
  }
}

/**
 * Generates a list of the items to display inside an AccountSelect Component.
 *
 * @param accounts Accounts list.
 * @param providers External bank provifers available.
 * @param settings Application wide settings.
 *
 * @returns A list of AccountSelectItem objects to populate the AccountSelectComponent.
 */
export function createDropdownItems(
  accounts: AggregatedAccount[],
  providers: ExternalBankProvider[],
  settings: OlbSettings
): AccountSelectItem[] {
  return accounts.map(account => {
    let accountName = account.nickname;

    if (account.isExternal) {
      accountName = account.name ?? account.nickname ?? account.bankName;
    }

    return new AccountSelectItem({
      id: account.id,
      name: accountName,
      details: getDropdownItemDescription(account, settings.brandName),
      icon: `assets/img/icons/accounts/bank-logos/${getDropdownItemImage(providers, account, settings.brand)}`,
    });
  });
}
