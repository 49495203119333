import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { sub } from 'date-fns';

import { GOAL_ICONS } from '@app/axos-invest/constants';
import { TransactionType } from '@app/axos-invest/enums';
import {
  DateFilter,
  Goal,
  MilestoneSummary,
  PastPerformanceHistoricalItem,
  ScheduledTransfer,
} from '@app/axos-invest/models';
import { AxosInvestService, AxosInvestTransferService } from '@app/axos-invest/services';
import { getScheduledTransfers } from '@app/axos-invest/store/selectors';
import { STATE } from '@core/tokens';
import { AxosInvestUrlHelper } from '@legacy/services/axos-invest-urls.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { NavigationIcons } from '@shared/enums/svg-icons';

@Component({
  selector: 'app-goal-card',
  templateUrl: './goal-card.component.html',
  styleUrls: ['./goal-card.component.scss'],
})
export class GoalCardComponent implements OnInit, OnDestroy {
  @Input() milestoneSummary: MilestoneSummary;
  milestones: Goal[];
  isFeatureActive: boolean;
  icons = {
    triangleUp: NavigationIcons.TriangleUp,
    triangleDown: NavigationIcons.TriangleDown,
    equal: NavigationIcons.Equal,
    iconChevronRight: NavigationIcons.ChevronRight,
  };
  private subsink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private featureFlagService: FeatureFlagService,
    private axosInvestUrlHelper: AxosInvestUrlHelper,
    private transferService: AxosInvestTransferService,
    private axosInvestService: AxosInvestService,
    private store: Store
  ) {}

  ngOnInit() {
    this.milestones = this.milestoneSummary.milestones.filter(x => !x.isClosed).map(milestone => ({ ...milestone }));
    this.isFeatureActive = this.featureFlagService.isManagedPortfoliosEnabled();
    this.getRecurringDeposits();
    this.getHistoricOverview();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  getGoalIcon(type: number) {
    return GOAL_ICONS[type];
  }

  goToGoalSummary(milestoneId: string) {
    if (this.isFeatureActive) {
      this.state.go('udb.axosinvest', { id: milestoneId });
    } else {
      this.axosInvestUrlHelper.getEditMilestoneUrl(milestoneId).then(url => {
        window.open(url, '_blank', 'noopener noreferrer');
      });
    }
  }

  getProgressBar(completion: number) {
    return {
      width: `${completion}%`,
    };
  }

  private getRecurringDeposits() {
    this.subsink.sink = this.store.select(getScheduledTransfers).subscribe(transfers => {
      if (transfers) {
        transfers = JSON.parse(JSON.stringify(transfers));
        this.setTransfers(transfers);
      } else {
        this.transferService.getScheduledTransfers().subscribe(response => {
          this.setTransfers(response.data);
        });
      }
    });
  }

  private setTransfers(transfers: ScheduledTransfer[]) {
    this.milestones.forEach(milestone => {
      const recurringDeposits = transfers.filter(
        x => x.milestoneId === milestone.id && x.transactionType === TransactionType.Deposit
      );
      if (recurringDeposits.length > 1) {
        recurringDeposits.forEach(recurringDeposit => {
          const account = milestone.accounts.find(a => a.accountNumber === recurringDeposit.accountNumber);
          if (account) {
            recurringDeposit.accountName = account.name;
          }
        });
      }
      milestone.recurringDeposits = recurringDeposits;
    });
  }

  private getHistoricOverview() {
    const filter: DateFilter = {};
    const today = new Date();
    filter.end = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 23, 59, 59, 999));
    filter.start = sub(filter.end, { days: 1, hours: 23, minutes: 59, seconds: 59 });
    const requests = this.milestones.map(milestone =>
      this.axosInvestService.getPastPerformance(milestone.id, filter).pipe(
        map(response => {
          const historicalResponseLength = response.data.client.historicalResponse.length;
          let previousDay: PastPerformanceHistoricalItem =
            response.data.client.historicalResponse[historicalResponseLength - 2];
          const currentDay: PastPerformanceHistoricalItem =
            response.data.client.historicalResponse[historicalResponseLength - 1];
          if (!previousDay) {
            previousDay = { close: 0, contributions: 0 };
          }
          milestone.dayChangeAmount =
            currentDay.close - currentDay.contributions - (previousDay.close - previousDay.contributions);
          milestone.dayChangePercentage =
            (currentDay.close - currentDay.contributions - (previousDay.close - previousDay.contributions)) /
            previousDay.close;
          milestone.isLoadingChange = true;
        }),
        catchError(() => of(null))
      )
    );

    forkJoin(requests).subscribe();
  }
}
