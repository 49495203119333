import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PdpLayoutState } from '../types';

const getPdpLayoutState = createFeatureSelector<PdpLayoutState>(AasStoreFeatures.PdpLayout);

export const getContextualTopBarState = createSelector(
  getPdpLayoutState,
  (pdpLayoutState) => {
    return pdpLayoutState.contextualTopBar
  }
);
