<ng-container *ngIf="accountFirmContacts$ | async as accountFirmContactsState">
  <ng-container *ngIf="accountFirmContactsState && !accountFirmContactsState.error; else error">
    <aas-data-tile-section-list
      *ngIf="accountFirmContactsState.primaryAdvisor as primaryAdvisor"
      header="Primary Advisor"
    >
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.name"
        [type]="aasDataTileListRowTypeEnum.Text"
        [title]="'Name'"
        [value]="primaryAdvisor.name"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.mobilePhone"
        [type]="aasDataTileListRowTypeEnum.MobilePhone"
        [title]="'Phone'"
        [value]="primaryAdvisor.mobilePhone"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.email"
        [type]="aasDataTileListRowTypeEnum.Email"
        [title]="'Email'"
        [value]="primaryAdvisor.email"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.address"
        [type]="aasDataTileListRowTypeEnum.Address"
        [title]="'Address'"
        [value]="primaryAdvisor.address"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.firmName"
        [type]="aasDataTileListRowTypeEnum.Text"
        [title]="'Office Name'"
        [value]="primaryAdvisor.firmName"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.firmAddress"
        [type]="aasDataTileListRowTypeEnum.Address"
        [title]="'Office Address'"
        [value]="primaryAdvisor.firmAddress"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.firmPhone"
        [type]="aasDataTileListRowTypeEnum.MobilePhone"
        [title]="'Office Phone'"
        [value]="primaryAdvisor.firmPhone"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="primaryAdvisor.receivesStatement != null && primaryAdvisor.receivesStatement != undefined"
        [type]="aasDataTileListRowTypeEnum.Text"
        [title]="'Receives Statement'"
        [value]="primaryAdvisor.receivesStatement ? 'Yes' : 'No'"
      ></aas-data-tile-list-row>
    </aas-data-tile-section-list>

    <ng-container *ngIf="accountFirmContactsState.advisors as advisors">
      <ng-container *ngFor="let advisor of advisors">
        <aas-data-tile-section-list *ngIf="advisor" header="Advisor">
          <aas-data-tile-list-row
            *ngIf="advisor.name"
            [type]="aasDataTileListRowTypeEnum.Text"
            [title]="'Name'"
            [value]="advisor.name"
          ></aas-data-tile-list-row>
          <aas-data-tile-list-row
            *ngIf="advisor.mobilePhone"
            [type]="aasDataTileListRowTypeEnum.MobilePhone"
            [title]="'Phone'"
            [value]="advisor.mobilePhone"
          ></aas-data-tile-list-row>
          <aas-data-tile-list-row
            *ngIf="advisor.email"
            [type]="aasDataTileListRowTypeEnum.Email"
            [title]="'Email'"
            [value]="advisor.email"
          ></aas-data-tile-list-row>
          <aas-data-tile-list-row
            *ngIf="advisor.address"
            [type]="aasDataTileListRowTypeEnum.Address"
            [title]="'Address'"
            [value]="advisor.address"
          ></aas-data-tile-list-row>
          <aas-data-tile-list-row
            *ngIf="advisor.firmName"
            [type]="aasDataTileListRowTypeEnum.Text"
            [title]="'Office Name'"
            [value]="advisor.firmName"
          ></aas-data-tile-list-row>
          <aas-data-tile-list-row
            *ngIf="advisor.firmAddress"
            [type]="aasDataTileListRowTypeEnum.Address"
            [title]="'Office Address'"
            [value]="advisor.firmAddress"
          ></aas-data-tile-list-row>
          <aas-data-tile-list-row
            *ngIf="advisor.firmPhone"
            [type]="aasDataTileListRowTypeEnum.MobilePhone"
            [title]="'Office Phone'"
            [value]="advisor.firmPhone"
          ></aas-data-tile-list-row>
          <aas-data-tile-list-row
            *ngIf="advisor.receivesStatement != null && advisor.receivesStatement != undefined"
            [type]="aasDataTileListRowTypeEnum.Text"
            [title]="'Receives Statement'"
            [value]="advisor.receivesStatement ? 'Yes' : 'No'"
          ></aas-data-tile-list-row>
        </aas-data-tile-section-list>
      </ng-container>
    </ng-container>

    <aas-data-tile-section-list *ngIf="accountFirmContactsState.riaFirm as riaFirm" header="Firm">
      <aas-data-tile-list-row
        *ngIf="riaFirm.name"
        [type]="aasDataTileListRowTypeEnum.Text"
        [title]="'Name'"
        [value]="riaFirm.name"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="riaFirm.mobilePhone"
        [type]="aasDataTileListRowTypeEnum.MobilePhone"
        [title]="'Phone'"
        [value]="riaFirm.mobilePhone"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="riaFirm.email"
        [type]="aasDataTileListRowTypeEnum.Email"
        [title]="'Email'"
        [value]="riaFirm.email"
      ></aas-data-tile-list-row>
      <aas-data-tile-list-row
        *ngIf="riaFirm.address"
        [type]="aasDataTileListRowTypeEnum.Address"
        [title]="'Address'"
        [value]="riaFirm.address"
      ></aas-data-tile-list-row>
    </aas-data-tile-section-list>
  </ng-container>
  <ng-template #error>
    <mat-divider class="failed-state-divider"></mat-divider>
    <uk2-text-tile [uk2TextTileTitle]="'Firm Contacts Temporarily Unavailable'" [uk2TextTileIsStandaloneTile]="false">
      We're sorry, but we're currently unable to provide you with your latest firm contacts. This includes your
      <span class="uk2-font__bold--75">Advisor(s)</span>
      and
      <span class="uk2-font__bold--75">Firm</span>
      contacts. Please check back later. Thank you for your patience.
    </uk2-text-tile>
  </ng-template>
</ng-container>
