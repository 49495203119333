import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { CompanyDetail, CustomerDetail } from '@shared/models';

export class ProfileDetailsState {
  isNewUser: boolean | null = null;
  userInfoIsOutdated: boolean | null = null;
  details: Partial<CustomerDetail> = null;
  companyDetails: Partial<CompanyDetail> = null;
  accountProfiles: Partial<AccountProfile[]> = null;
  constructor(data?: Partial<ProfileDetailsState>) {
    Object.assign(this, data);
  }
}
