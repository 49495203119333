import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DebitCardStatus } from '../../enums';
import { DebitCard } from '../../models';

@Component({
  selector: 'app-debit-card-overview',
  templateUrl: './debit-card-overview.component.html',
  styleUrls: ['./debit-card-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebitCardOverviewComponent {
  @Input() isUpdating = false;
  @Input() debitCard: Partial<DebitCard>;
  @Output() activateCard = new EventEmitter<void>();
  @Output() toggleCard = new EventEmitter<void>();

  get isCardInProcess() {
    return this.debitCard?.status === DebitCardStatus.InProcess;
  }

  get embosName() {
    return this.debitCard?.embosName ?? '';
  }

  get secondEmbosName() {
    return this.debitCard?.secondEmbosName ?? '';
  }

  get debitCardTypeDescription() {
    return this.debitCard?.debitCardTypeDescription ?? '';
  }

  get statusDescription() {
    return this.debitCard?.statusDescription ?? '';
  }

  get maskedCardNumber() {
    return this.debitCard?.cardNumberMask
      ? this.debitCard.cardNumberMask.slice(this.debitCard.cardNumberMask.length - 5)
      : '';
  }

  get expirationDate() {
    return this.debitCard?.expirationDate ? this.dp.transform(this.debitCard.expirationDate, 'MM/yy') : 'N/A';
  }

  get issuedDate() {
    return this.debitCard?.originalIssueDate ? this.dp.transform(this.debitCard.originalIssueDate, 'MM/yy') : 'N/A';
  }

  constructor(private dp: DatePipe) {}

  activateCardConfirmation() {
    this.activateCard.emit();
  }

  toggleCardStatus() {
    this.toggleCard.emit();
  }
}
