<div class="panel-alert fcol-sm-4">
  <div class="frow unselectable fix-position">
    <div class="alert-icon-box fcol-xs-2">
      <img src="/img/icons/alerts/{{ alert.icon }}.svg" alt="{{ alert.title }} icon" />
    </div>
    <div class="alert-icon-title fcol-md-10 fcol-xs-9">
      <p class="title">{{ alert.title }}</p>
      <p class="subtitle">{{ alert.subTitle }}</p>
    </div>
    <div class="alert-icon-add fcol-xs-1">
      <a data-nodrag class="add-link link" (click)="addAlert(alert)">
        <span class="d-lg-none">+</span>
        <span class="d-none d-lg-inline-block">+Add</span>
      </a>
    </div>
  </div>
</div>
