import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { isSaturday, isSunday, sub } from 'date-fns';

import { getTradingAccount } from '@app/accounts/store/selectors';
import { TradeStockType } from '@app/axos-trading/enums';
import { EquityType } from '@app/axos-trading/enums/equity-type.enum';
import {
  Company,
  Equity,
  EquityHistory,
  Order,
  OrderFilter,
  PortfolioOverview,
  TradingAccount,
} from '@app/axos-trading/models';
import { ClearingService, TradingRoutingHelperService } from '@app/axos-trading/services';
import { STATE_PARAMS } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-stock-detail',
  templateUrl: './stock-detail.component.html',
  styleUrls: ['./stock-detail.component.scss'],
})
export class StockDetailComponent implements OnInit, OnDestroy {
  get accountId() {
    return this.params['accountId'];
  }
  get stockSymbol() {
    return this.params['stockId'];
  }
  stock: Equity;
  company: Company;
  icons = {
    backIcon: NavigationIcons.ArrowBack,
    triangleUp: NavigationIcons.TriangleUp,
    triangleDown: NavigationIcons.TriangleDown,
    equal: NavigationIcons.Equal,
  };
  account: TradingAccount;
  portfolioOverview: PortfolioOverview;
  tradeTypes = TradeStockType;
  todayLessOne = this.isWeekend(sub(new Date(), { days: 1 }));
  filterDates = [
    { sortable: 0, date: sub(this.todayLessOne, { days: 7 }) },
    { sortable: 1, date: sub(this.todayLessOne, { months: 1 }) },
    { sortable: 2, date: sub(this.todayLessOne, { months: 3 }) },
    { sortable: 3, date: sub(this.todayLessOne, { years: 1 }) },
    { sortable: 4, date: sub(this.todayLessOne, { years: 5 }) },
  ];
  sortableByDates: GenericOption[] = [
    { value: 0, label: '1W' },
    { value: 1, label: '1M' },
    { value: 2, label: '3M' },
    { value: 3, label: '1Y' },
    { value: 4, label: '5Y' },
  ];
  dayChangeConfig: { cssClass: string; iconName: string; iconColor: string };
  stocks: EquityHistory[];
  isActive = 0;
  isLoadingStocks = true;
  isLoadingCompany = true;
  isLoadingOrders = true;
  isShowExchangeAgreement = true;
  orders: Order[];
  updateData = new EventEmitter<void>();
  lastUpdatedDate = new Date();
  equityType = EquityType;
  isSDTMutualFundsActive: boolean;
  hasPositions = false;

  private subSink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private routingHelper: TradingRoutingHelperService,
    private store: Store,
    private clearingService: ClearingService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.isSDTMutualFundsActive = this.featureFlagService.isSDTMutualFundsActive();
    this.getTradingAccount();
    this.getEquity();
    this.getHistoricStocks();
    this.getCompany();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  goBackToSDT(): void {
    this.routingHelper.goToSDTOverview(this.accountId);
  }

  goToTradeStock(type: TradeStockType) {
    this.routingHelper.goToTradeStock(this.accountId, this.stockSymbol, type);
  }

  changeGraph(sortable: number) {
    this.isActive = sortable;
    let toDateList: Date = new Date();

    toDateList = this.filterDates.find(x => x.sortable === sortable).date;
    this.isLoadingStocks = true;
    this.getHistoricStocks(toDateList);
  }

  isWeekend(date: Date) {
    if (isSaturday(date)) {
      date = sub(date, { days: 1 });
    }
    if (isSunday(date)) {
      date = sub(date, { days: 2 });
    }

    return date;
  }

  exchangeAgreementState(event: boolean) {
    this.isShowExchangeAgreement = event;
  }

  private getEquity() {
    this.subSink.sink = this.clearingService.getEquity(this.stockSymbol).subscribe(result => {
      this.stock = result;
      this.setupDayChangeConfig();
    });
  }

  private getOrders() {
    const filters = new OrderFilter({
      symbol: this.stockSymbol,
    });
    this.subSink.sink = this.clearingService
      .getOrders(this.account.accountNumber, filters)
      .pipe(
        finalize(() => {
          this.isLoadingOrders = false;
        })
      )
      .subscribe(result => {
        this.orders = result;
        this.orders.sort((a: Order, b: Order) => {
          return Date.parse(b.createdDate) - Date.parse(a.createdDate);
        });
      });
  }

  private getTradingAccount(): void {
    this.subSink.sink = this.store
      .select(getTradingAccount(this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.account = account;
        this.getPortfolioOverview();
        this.getOrders();
        this.lastUpdatedDate = new Date();
      });
  }

  private getCompany(): void {
    this.subSink.sink = this.clearingService.getCompany(this.stockSymbol).subscribe(result => {
      this.company = result;
      this.isLoadingCompany = false;
    });
  }

  private setupDayChangeConfig() {
    const isEqual = this.stock.dayChangePercent === 0;
    const isPositive = this.stock.dayChangePercent >= 0;
    this.dayChangeConfig = {
      cssClass: isEqual ? 'equal' : isPositive ? 'positive' : 'negative',
      iconColor: isEqual ? '#494949' : isPositive ? '#00805B' : '#fd0000',
      iconName: isEqual ? this.icons.equal : isPositive ? this.icons.triangleUp : this.icons.triangleDown,
    };
  }

  private getPortfolioOverview() {
    this.subSink.sink = this.clearingService
      .getPortfolioOverview(this.account.accountNumber)
      .pipe(filter(account => !!account))
      .subscribe(result => {
        this.portfolioOverview = result;
        this.validatePositions();
      });
  }

  private validatePositions() {
    this.portfolioOverview.positions = this.portfolioOverview.positions?.filter(
      position => position.symbol === this.stockSymbol
    );
    this.hasPositions = this.portfolioOverview?.positions?.length > 0;
  }

  private getHistoricStocks(toDateList?: Date) {
    const today = this.todayLessOne;
    const toDate = toDateList ? this.isWeekend(toDateList) : this.isWeekend(sub(today, { weeks: 1 }));
    this.clearingService
      .getEquityHistory(this.stockSymbol, toDate.toISOString(), today.toISOString())
      .pipe(
        finalize(() => {
          this.updateData.emit();
          this.isLoadingStocks = false;
        })
      )
      .subscribe(response => {
        this.stocks = response.map(x => {
          x.date = x.date.replace(/-/g, '/').replace(/T.+/, '');

          return x;
        });
      });
  }
}
