export const SUCCESS_DIALOG = {
  WITH_OVERDRAFT:
    'account is now available and benefits from $5,000.00 in overdraft protection. To access the details of your accounts, simply head over to your Accounts page.',
  WITH_OVERDRAFT_ICODL:
    'account is now available, complete with $5,000.00 in overdraft protection. Visit your Accounts page to view all your account details and get started.',
  WITHOUT_OVERDRAFT:
    'account is now available. Visit your Accounts page to view all your account details and get started.',
};

export const ACCOUNT_NAME = 'Cash Management Checking Account';
