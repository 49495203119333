import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { AccountPeopleState } from '../types';

const getAccountPeopleState = createFeatureSelector<AccountPeopleState>(AasStoreFeatures.AccountPeople);

export const getSelectedAccountPeopleTab = createSelector(getAccountPeopleState, state => {
  const { accountsPeople, selectedAccountPeople } = state;

  if (accountsPeople === undefined || selectedAccountPeople === undefined) {
    return undefined;
  }

  return accountsPeople.find(accountPeople => accountPeople.accountNumber === selectedAccountPeople);
});
