import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { securityAnswerRegex } from '@app/config/regexes';
import { UserProfileService } from '@core/services';
import { SecurityQuestion } from '@shared/models';

@Component({
  selector: 'up-change-security-questions',
  templateUrl: './change-security-questions.component.html',
  styleUrls: ['../../scss/user-profile.common.scss', './change-security-questions.component.scss'],
})
export class ChangeSecurityQuestionsComponent implements OnInit, OnDestroy {
  @Input()
  questionsCatalog: string[];
  @Input()
  userQuestions: string[];

  @Output()
  back = new EventEmitter<null>();
  @Output()
  updateQuestions = new EventEmitter<string[]>();

  questionsForm: UntypedFormGroup;
  errorMessage: string = null;
  isBusy = false;
  firstQuestions: string[];
  secondQuestions: string[];
  thirdQuestions: string[];
  errors = {
    answer1: this.generateErrorsForAnswer(1),
    answer2: this.generateErrorsForAnswer(2),
    answer3: this.generateErrorsForAnswer(3),
  };

  private subsink = new SubSink();

  constructor(private fb: UntypedFormBuilder, private userProfileService: UserProfileService) {}

  ngOnInit(): void {
    const question1 = this.userQuestions[0] ?? this.questionsCatalog[0];
    const question2 = this.userQuestions[1] ?? this.questionsCatalog[1];
    const question3 = this.userQuestions[2] ?? this.questionsCatalog[2];

    this.questionsForm = this.fb.group({
      question1: this.fb.control(question1, [Validators.required]),
      answer1: this.fb.control('', [Validators.required, Validators.pattern(securityAnswerRegex)]),
      question2: this.fb.control(question2, [Validators.required]),
      answer2: this.fb.control('', [Validators.required, Validators.pattern(securityAnswerRegex)]),
      question3: this.fb.control(question3, [Validators.required]),
      answer3: this.fb.control('', [Validators.required, Validators.pattern(securityAnswerRegex)]),
    });

    this.firstQuestions = this.filterQuestions([question2, question3]);
    this.secondQuestions = this.filterQuestions([question1, question3]);
    this.thirdQuestions = this.filterQuestions([question1, question2]);

    this.setValueChanges();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  changeQuestions(): void {
    const answers = [
      this.questionsForm.get('answer1').value,
      this.questionsForm.get('answer2').value,
      this.questionsForm.get('answer3').value,
    ];
    const questions = [
      this.questionsForm.get('question1').value,
      this.questionsForm.get('question2').value,
      this.questionsForm.get('question3').value,
    ];
    const filteredAnswers = new Set(answers);

    if (this.questionsForm.invalid) {
      this.questionsForm.markAllAsTouched();
    } else if (filteredAnswers.size !== 3) {
      this.errorMessage = 'You cannot have the same answer for multiple security questions.';
    } else {
      this.isBusy = true;
      const payload = questions.map(
        (questionText, idx) => new SecurityQuestion({ questionText, answer: answers[idx] })
      );

      this.userProfileService
        .saveSecurityQuestions(payload)
        .pipe(
          finalize(() => {
            this.isBusy = false;
          })
        )
        .subscribe({
          next: () => {
            this.updateQuestions.emit(questions);
            this.back.emit();
          },
          error: res => {
            this.errorMessage = res.error.message;
          },
        });
    }
  }

  private setValueChanges(): void {
    const firstQuestion = this.questionsForm.get('question1');
    const secondQuestion = this.questionsForm.get('question2');
    const thirdQuestion = this.questionsForm.get('question3');

    this.subsink.sink = firstQuestion.valueChanges.subscribe(value => {
      this.secondQuestions = this.filterQuestions([value, thirdQuestion.value]);
      this.thirdQuestions = this.filterQuestions([value, secondQuestion.value]);
    });
    this.subsink.sink = secondQuestion.valueChanges.subscribe(value => {
      this.firstQuestions = this.filterQuestions([value, thirdQuestion.value]);
      this.thirdQuestions = this.filterQuestions([value, firstQuestion.value]);
    });
    this.subsink.sink = thirdQuestion.valueChanges.subscribe(value => {
      this.firstQuestions = this.filterQuestions([value, secondQuestion.value]);
      this.secondQuestions = this.filterQuestions([value, firstQuestion.value]);
    });
  }

  private filterQuestions(toExclude: string[]): string[] {
    return this.questionsCatalog.filter(question => !toExclude.includes(question));
  }

  private generateErrorsForAnswer(id: number): { name: string; message: string }[] {
    return [
      { name: 'required', message: `Answer for security question ${id} is required.` },
      {
        name: 'pattern',
        message: `The answer to security question ${id} is not valid, please enter a valid answer.`,
      },
    ];
  }
}
