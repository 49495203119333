import { Component, Input } from '@angular/core';

export type Face = 'sad' | 'happy';

@Component({
  selector: 'app-face',
  templateUrl: './face.component.html',
  styleUrls: ['./face.component.scss'],
})
export class FaceComponent {
  @Input() title = '';
  @Input() message = '';
  @Input() set face(value: Face) {
    this.happyFace = 'happy' === value;
  }

  happyFace = false;
}
