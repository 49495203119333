import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { UserProfileViews } from '@app/user-profile/enums';
import { STATE } from '@core/tokens';

@Component({
  selector: 'app-spousal-consent-modal',
  templateUrl: './spousal-consent-modal.component.html',
  styleUrls: ['./spousal-consent-modal.component.scss'],
})
export class SpousalConsentModalComponent {
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private dialogRef: MatDialogRef<SpousalConsentModalComponent>
  ) {}

  goToUserProfile() {
    this.state.go('udb.userProfile', { view: UserProfileViews.PersonalInformation });
    this.dialogRef.close();
  }
}
