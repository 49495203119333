<app-dot-loader [showWhen]="isLoading">Loading rewards...</app-dot-loader>
<div class="row wrap mt-5 rewards" [hidden]="isLoading">
  <div class="col-sm-3 col-md-3 rewards-content">
    <div class="ml-5">
      <div>
        <span class="rewards-content__title">Rewards</span>
        <span class="rewards-content__tooltip">
          <app-tooltip [white]="false" content="Rewards are aggregated at the end of every month."></app-tooltip>
        </span>
      </div>
    </div>
  </div>
  <div class="col-sm-8 col-md-8"></div>
  <div class="col-sm-1 col-md-1"></div>
  <div class="col-sm-9 col-md-9 ml-5" *ngIf="hasError">
    <br />
    <app-alert-notification *ngIf="errorMessage" alertType="error" (dismiss)="cleanErrorMessage()">
      {{ errorMessage }}
    </app-alert-notification>
  </div>
  <div class="col-sm-3 col-md-3 rewards-content" *ngIf="!hasError">
    <div class="ml-5">
      <p class="rewards-content__sub-title mt-3">
        {{ this.overallAPYTitle }}
        <b>{{ this.overallEarnedAPY | number: '1.1-2' }}%</b>
      </p>
    </div>
    <div class="rewards-content ml-1 mt-3">
      <canvas id="doughnut-chart" height="220"></canvas>
      <div class="rewards-info text-center">
        <div class="percentage">{{ this.overallAPYValue }}</div>
        <span class="percentage__apy">APY</span>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-6 rewards-content mt-3" *ngIf="!hasError">
    <app-rewards-criteria
      [rewardsCheckingCriterias]="this.rewardsCheckingCriterias"
      [rewardsCheckingCharts]="this.rewardsCheckingCharts"
      [requiredComplete]="this.requiredComplete"
    ></app-rewards-criteria>
  </div>
</div>
