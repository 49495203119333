import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  MainLayoutState,
  setupTopBarBrandingAction,
  TopBarState,
  topBarState,
} from '../core/store';
import { SetupTopBarBrandingInputType } from './types';

@Injectable({
  providedIn: 'root',
})
export class MainLayoutFacade {
  topBarState$ = this.store.select(topBarState);

  constructor(private store: Store<MainLayoutState>) {}
  setupTopBarBranding(input: SetupTopBarBrandingInputType) {
    const aasAccountState: TopBarState = {
      logoImgPath: input.logoImgPath,
      organizationName: input.organizationName,
      isAxos: input.isAxos,
      urlRedirectionConfig: input.urlRedirectionConfig,
    };
    this.store.dispatch(
      setupTopBarBrandingAction({ payload: aasAccountState })
    );
  }
}
