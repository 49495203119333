import { ExternalAccountsHelper } from '@legacy/accounts/external-accounts/external-accounts.helper';

export function externalAccountsHelperFactory($injector: any) {
  return $injector.get('externalAccountsHelper');
}

export const externalAccountsHelperProvider = {
  provide: ExternalAccountsHelper,
  useFactory: externalAccountsHelperFactory,
  deps: ['$injector'],
};
