<app-dot-loader *ngIf="isLoading"></app-dot-loader>
<div class="container" *ngIf="!isLoading">
  <app-click-switch-header (back)="goBack()"></app-click-switch-header>
  <div class="row center back-color">
    <h1 class="text-bold m-4">Enjoy the Convenience of Direct Deposit</h1>
    <div class="col-12 col-sm-8 col-md-6 text-center">
      <p>
        Fast, easy, and secure. It takes just a few minutes to set up a direct deposit to your Axos account.
      </p>
      <button type="button" class="uk-btn lg solid secondary m-4" (click)="goSearchTarget()">
        Get Started
      </button>
    </div>
    <div class="sitecore-width">
      <sitecore-click-switch-component></sitecore-click-switch-component>
    </div>
  </div>
</div>
