import { NgModule } from '@angular/core';
import { OverdraftTermsConditionsComponent } from './view/overdraft-credit-terms-conditions-modal';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [OverdraftTermsConditionsComponent],
  imports: [SharedModule],
  exports: [OverdraftTermsConditionsComponent],
})
export class OverdraftTermsConditionsModule {}
