<div class="experience-levels">
  <div class="deposit-interest-container">
    <p *ngIf="!!investment.investmentKnowledge">
      Investment Experience&nbsp;
      <span>{{ investment.investmentKnowledge }}</span>
    </p>
    <div class="showExpContainer">
      <div class="show-levels-experience" [ngClass]="{ expanded: isExpanded }" (click)="isExpanded = !isExpanded">
        <i class="chevron"></i>
        <span class="exp-message">{{ isExpanded ? 'Hide' : 'Show' }} specific levels of experience</span>
      </div>
    </div>
    <div *ngIf="isExpanded" class="expand-container">
      <p *ngIf="!!investment.marginsExperience">
        Margin experience&nbsp;
        <span>{{ investment.marginsExperience }}</span>
      </p>
      <p *ngIf="!!investment.stocksExperience">
        Stock experience&nbsp;
        <span>{{ investment.stocksExperience }}</span>
      </p>
      <p *ngIf="!!investment.annuitiesExperience">
        Annuities experience&nbsp;
        <span>{{ investment.annuitiesExperience }}</span>
      </p>
      <p *ngIf="!!investment.bondsExperience">
        Bond experience&nbsp;
        <span>{{ investment.bondsExperience }}</span>
      </p>
      <p *ngIf="!!investment.mutualFundsExperience">
        Mutual Fund experience&nbsp;
        <span>{{ investment.mutualFundsExperience }}</span>
      </p>
      <p *ngIf="!!investment.optionsExperience">
        Options experience&nbsp;
        <span>{{ investment.optionsExperience }}</span>
      </p>
      <p *ngIf="!!investment.optionsTranExperience">
        Options Transactions experience&nbsp;
        <span>{{ investment.optionsTranExperience }}</span>
      </p>
    </div>
  </div>
</div>
