import { Inject } from '../decorators/decorators';

@Inject()
export class MessagesController {
  constructor() {}

  /** Initializes the controller. */
  $onInit(): void {}

  /** Cleans up the controller. */
  $onDestroy(): void {}
}

export enum MessagesType {
  INBOX = 0,
  ARCHIVED = 1,
}

export function mapTypes(type: number): string {
  switch (type) {
    case MessagesType.INBOX:
      return 'Inbox';
    case MessagesType.ARCHIVED:
      return 'Archived';
    default:
      return '';
  }
}
