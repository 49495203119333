export function AbbreviateNumber(inputNumber: number, decimalPrecision?: number) {
  const suffixes = ['k', 'm', 'b', 't', 'p', 'e'];

  if (isNaN(inputNumber)) {
    return null;
  }

  if (inputNumber < 1000) {
    return inputNumber;
  }

  const exp = Math.floor(Math.log(inputNumber) / Math.log(1000));

  return (inputNumber / Math.pow(1000, exp)).toFixed(decimalPrecision) + suffixes[exp - 1];
}
