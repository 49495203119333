import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { DatePickerEvent, DatePickerEvents, DatePickerOptions } from '@shared/models';

@Directive({
  selector: '[appDateRangePicker]',
})
export class DateRangePickerDirective implements OnInit, OnChanges {
  @Input() options: DatePickerOptions;
  @Output() dateRangePickerHidden = new EventEmitter<DatePickerEvents>(); // Triggered when the picker is shown
  @Output() dateRangePickerShown = new EventEmitter<DatePickerEvents>(); // Triggered when the picker is hidden
  @Output() calendarShown = new EventEmitter<DatePickerEvents>(); // Triggered when the calendar(s) are shown
  @Output() calendarHidden = new EventEmitter<DatePickerEvents>(); // Triggered when the calendar(s) are hidden
  @Output() dateApplied = new EventEmitter<DatePickerEvents>(); // Triggered when the apply button is clicked, or when a predefined range is clicked
  @Output() canceled = new EventEmitter<DatePickerEvents>(); // Triggered when the cancel button is clicked

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.createPicker();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.options && !changes.options.firstChange) {
      this.createPicker();
    }
  }

  private createPicker() {
    const dp = $(this.element.nativeElement) as any;
    dp.daterangepicker({
      ...this.options,
    });
    dp.on('cancel.daterangepicker', (event: Event, picker: DatePickerEvent) => {
      this.canceled.emit({ event, picker });
    });
    dp.on('hideCalendar.daterangepicker', (event: Event, picker: DatePickerEvent) => {
      this.calendarHidden.emit({ event, picker });
    });
    dp.on('showCalendar.daterangepicker', (event: Event, picker: DatePickerEvent) => {
      this.calendarShown.emit({ event, picker });
    });
    dp.on('show.daterangepicker', (event: Event, picker: DatePickerEvent) => {
      this.dateRangePickerShown.emit({ event, picker });
    });
    dp.on('hide.daterangepicker', (event: Event, picker: DatePickerEvent) => {
      this.dateRangePickerHidden.emit({ event, picker });
    });
    dp.on('apply.daterangepicker', (event: Event, picker: DatePickerEvent) => {
      this.dateApplied.emit({ event, picker });
    });
  }
}
