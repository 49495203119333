import { Injectable } from '@angular/core';
import { Subject, SubscriptionLike } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { OlbEvents } from '@core/enums';
import { OlbEvent } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class OlbEventService {
  private eventEmitter$ = new Subject<OlbEvent<any>>();

  /**
   * Emit an OLB event that will be received by all its subscribers.
   * @param eventType Type of the emitted event.
   * @param payload Any payload needed.
   */
  emit<T>(eventType: OlbEvents, payload: T = null): void {
    this.eventEmitter$.next(new OlbEvent(eventType, payload));
  }

  /**
   * Listens to a particular OLB event.
   * @param eventType Event to listen to.
   * @param action What the event listener should do when the event is triggered.
   */
  on<T>(eventType: OlbEvents, action: (value: T) => void): SubscriptionLike {
    return this.eventEmitter$
      .pipe(
        filter(event => event.eventType === eventType),
        map(event => event.payload)
      )
      .subscribe(action);
  }
}
