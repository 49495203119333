import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { IncomeComponent } from '@app/pfm/components';
import { SmComposeComponent, SmItemComponent, SmMenuComponent, SmSubmenuComponent, SmBusinessAccountComponent } from '@app/support/components';
import { GlanceComponent } from '@core/components';
import {
  AlertNotificationComponent,
  ChatbotComponent,
  ChatbotHelpLabelComponent,
  ChatSwitcherComponent,
  FaceComponent,
  OutdatedContactInfoBannerComponent,
  PasswordFieldComponent,
  ProfileImageComponent,
  RevealInputLabelComponent,
  ShowMoreComponent,
  SideMenuComponent,
  SsnEntryComponent,
  SvgIconComponent,
  TooltipComponent as AppTooltipComponent,
} from '@shared/components';
import { PossessivePipe } from '@shared/pipes/possessive.pipe';

import { GlobalAlertComponent } from './components/global-alert/global-alert.component';
import { LocalAlertComponent } from './components/local-alert/local-alert.component';
import { modalComponent } from './components/modal/modal.component';
import { popoverComparer } from './components/popover-comparer/popover-comparer.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { priceDirective } from './directives/price.directive';

export const common = angular
  .module('udb.common', ['ngSanitize', 'ui.bootstrap'])
  .component('modalComponent', modalComponent)
  .component('popoverComparer', popoverComparer)
  .component('tooltip', TooltipComponent)
  .component('globalAlert', GlobalAlertComponent)
  .component('localAlert', LocalAlertComponent)
  .directive('price', priceDirective)
  .directive('appChatbot', downgradeComponent({ component: ChatbotComponent }))
  .directive('appChatbotHelpLabel', downgradeComponent({ component: ChatbotHelpLabelComponent }))
  .directive('svgComponents', downgradeComponent({ component: SvgIconComponent }))
  .directive('appRevealInputLabel', downgradeComponent({ component: RevealInputLabelComponent }))
  .directive('appPasswordField', downgradeComponent({ component: PasswordFieldComponent }))
  .directive('appFace', downgradeComponent({ component: FaceComponent }))
  .directive('appChatSwitcher', downgradeComponent({ component: ChatSwitcherComponent }))
  .directive('appSmItem', downgradeComponent({ component: SmItemComponent }))
  .directive('appSmCompose', downgradeComponent({ component: SmComposeComponent }))
  .directive('appSmMenu', downgradeComponent({ component: SmMenuComponent }))
  .directive('appSmSubmenu', downgradeComponent({ component: SmSubmenuComponent }))
  .directive('appSmBusinessAccount', downgradeComponent({ component: SmBusinessAccountComponent }))
  .directive('appGlance', downgradeComponent({ component: GlanceComponent }))
  .directive('appTooltip', downgradeComponent({ component: AppTooltipComponent }))
  .directive('appSsnEntry', downgradeComponent({ component: SsnEntryComponent }))
  .directive('appOutdatedContactInfoBanner', downgradeComponent({ component: OutdatedContactInfoBannerComponent }))
  .directive('appProfileImage', downgradeComponent({ component: ProfileImageComponent }))
  .directive('appShowMore', downgradeComponent({ component: ShowMoreComponent }))
  .directive('appSideMenu', downgradeComponent({ component: SideMenuComponent }))
  .directive('appAlertNotification', downgradeComponent({ component: AlertNotificationComponent }))
  .directive('appIncome', downgradeComponent({ component: IncomeComponent }))
  .filter('possessive', () => (input: string) => new PossessivePipe().transform(input)).name;
