import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-tx-filter',
  templateUrl: './tx-filter.component.html',
  styleUrls: ['./tx-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxFilterComponent implements OnInit, OnDestroy {
  constructor(private formBuilder: UntypedFormBuilder) {}
  // Bound properties
  filters: UntypedFormGroup;
  @Input() type: string;
  @Input() value: GenericOption;
  @Input() options: GenericOption[];
  @Input() isDisabled: boolean;
  @Input() onlyNumbers: boolean;
  @Input() inputLabel: string;
  @Input() filterName: string;
  @Input() isInsightsActive: boolean;
  @Input() placeholder: string;
  @Input() iconPlaceholder: string;
  @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>();
  // Internal properties to manage controller state
  selectedOption: GenericOption | string;
  private destroy$ = new Subject<void>();

  /** Initializes the controller. */
  ngOnInit() {
    this.filters = this.formBuilder.group({
      query: '',
      transactionType: [],
      days: [],
    });
    this.setFilterEvent();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setFilterEvent() {
    if (this.filters.controls[this.filterName]) {
      this.filters.controls[this.filterName].valueChanges
        .pipe(
          takeUntil(this.destroy$),
          distinctUntilChanged(),
          tap(value => {
            this.onValueChange.emit({value, filterName: this.filterName});
          })
        )
        .subscribe();
    }
  }
}
