import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { IRootScopeService, IScope } from 'angular';
import * as ng from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { ClearingAccountStatus, FeatureFlagType } from 'common/enums/enums';
import { Inject } from 'decorators/decorators';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { CachedTradingAccountsService } from 'services/cached-trading-accounts.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { IAxosClearingService } from 'services/typings/IAxosClearingService';

import { AccountTab } from '@app/accounts/enums';
import { getProviders } from '@app/accounts/store/providers/providers.selectors';
import { getTradingAccountsList } from '@app/accounts/store/selectors';
import { getInvestAccount } from '@app/axos-invest/store/selectors';
import { PortfolioOverview } from '@app/axos-trading/models';
import { ClearingService } from '@app/axos-trading/services';
import { AxosAdvisoryAccount, LegacyRoute } from '@core/models';
import { ProviderService } from '@core/services';

import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { getSelectedTab } from '@app/Areas/AAS/features/product-details-page/core';

@Inject(
  '$rootScope',
  '$scope',
  '$stateParams',
  '$state',
  'featureFlagService',
  'axosInvestHelper',
  'axosClearingService',
  'cachedAccountsService',
  'cachedTradingAccountsService',
  'ngrxStore',
  ProviderService.$token,
  'clearingService',
  'serviceHelper'
)
export class AccountContainerController {
  totalValue = 0;
  activeTabs: AccountTab[] = [];
  accounts: OlbAccount[] = [];
  accountType: string;
  activeTab = AccountTab.Transactions;
  isAccountInfoLoaded = false;
  isPortfolioLoaded = true;
  hasError = false;
  tabs = AccountTab;
  tooltipMessages: string;
  activeInvestAccount = false;
  accountId: number;
  isAccountClosed = false;
  isMPOverviewActive: boolean;
  isSDTFeatureActive: boolean;
  portfolioOverview: PortfolioOverview;

  private isAxosInvestActive = false;
  private investAccounts: OlbAccount[] = [];
  private listeners: (() => void)[] = [];
  private subsink = new SubSink();
  private riaAccount: AxosAdvisoryAccount | null = null;

  constructor(
    private rootScope: IRootScopeService,
    private scope: IScope,
    private params: IStateParamsService,
    private state: IStateService,
    private featureFlagService: FeatureFlagService,
    private axosInvestHelper: AxosInvestHelper,
    private axosClearingService: IAxosClearingService,
    private cachedAccountsService: CachedAccountsService,
    private cachedTradingAccountsService: CachedTradingAccountsService,
    private store: Store,
    private providersService: ProviderService,
    private clearingService: ClearingService,
    private readonly serviceHelper: IServiceHelper
  ) {}

  $onInit(): void {
    const type: string = 'advisor';
    this.accountType = this.params['type'].toLowerCase();
    this.accountId = +this.params['id'];
    this.tooltipMessages =
      this.accountType === 'invest'
        ? 'The combined value of your goal(s) and wallet balances.'
        : 'Total market value of all holdings in your account as of the previous market close.';
    this.isAxosInvestActive = this.featureFlagService.isAxosInvestActive() && this.axosInvestHelper.hasAxosInvest;
    this.isMPOverviewActive = this.featureFlagService.isFeatureFlagActive(FeatureFlagType.MPOverview);
    this.isSDTFeatureActive = this.featureFlagService.isFeatureFlagActive(FeatureFlagType.SDTFeature);
    this.setupTabs();
    this.handleListeners();

    if (this.accountType != type) {
      this.handleDefaultTab();
    } else {
      this.store.select(getSelectedTab).subscribe(tab => {
        this.activeTab = this.selectTab(tab);
      });
    }

    this.subsink.sink = this.store
      .select(getProviders)
      .pipe(
        switchMap(data => {
          if (data) {
            return of(data);
          }

          return this.providersService.getProviders();
        })
      )
      .subscribe();

    this.subsink.sink = this.store.select(getTradingAccountsList).subscribe(accounts => {
      const account = accounts.find(a => a.id === this.accountId);
      this.isAccountClosed = +account?.status === ClearingAccountStatus.Closed;
    });

    this.subsink.sink = this.store
      .select(getAxosAdvisoryAccounts)
      .pipe(filter(riaAccounts => !!riaAccounts))
      .subscribe((accounts: AxosAdvisoryAccount[]) => {
        this.riaAccount = accounts.find(a => a.accountNumber === this.accountId.toString());
      });
  }

  $onDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
    this.subsink.unsubscribe();
  }

  goTo(route: LegacyRoute): void {
    this.state.go(route.state, route.params);
  }

  changeTab(tab: AccountTab): void {
    this.activeTab = tab;
    this.rootScope['accountContainer-tab'] = this.activeTabs.findIndex(item => item === tab);
    window.scrollTo(0, 0);
  }

  changeToStatements() {
    this.changeTab(AccountTab.StatementTaxForms);
  }

  getTotalValue() {
    const account = this.accounts.find(t => +t.id === this.accountId);

    if (this.accountType === 'trading' && this.isSDTFeatureActive) {
      return this.portfolioOverview.totalValue + this.portfolioOverview.buyingPower;
    }

    if (this.accountType === 'advisor') {
      return this.riaAccount?.accountBalance;
    }
    // when the account isn't found it means that Axos Invest is selected, so fallback to its total value
    return account ? account.totalValue : this.totalValue;
  }
  loadTradingTabsByFeatureFlag() {
    if (this.isSDTFeatureActive) this.activeTabs.splice(1, 0, AccountTab.Search);
  }

  private handleListeners(): void {
    this.listeners.push(
      this.scope.$on('balancesAvailable', this.concatUserAccounts.bind(this)), // <- Until internal user accounts are loaded
      this.scope.$on('investAccountsLoaded', this.handleTradingAccounts.bind(this)),
      this.scope.$on('changeTab', (_e: ng.IAngularEvent, tab: AccountTab) => {
        this.changeTab(tab);
      })
    );

    // Internal user accounts loaded
    if (this.rootScope['balancesAvailable']) {
      this.concatUserAccounts();
    }

    if (this.rootScope['isInvestAccountsLoaded']) {
      this.handleTradingAccounts();
    }
  }

  private setupTabs(): void {
    this.activeTab = AccountTab.Overview;

    switch (this.accountType) {
      case 'invest':
        this.activeInvestAccount = true;
        this.activeTabs = [AccountTab.Overview, AccountTab.Transactions, AccountTab.StatementTaxForms];
        break;
      case 'trading':
        this.activeTabs = [
          AccountTab.Overview,
          AccountTab.Transactions,
          AccountTab.AccountDetails,
          AccountTab.StatementTaxForms,
        ];
        this.loadTradingTabsByFeatureFlag();
        break;
      case 'advisor':
        this.activeTabs = [AccountTab.StatementTaxForms, AccountTab.Transactions];
        break;
      default:
        this.goTo(new LegacyRoute({ state: 'udb.dashboard' }));
        break;
    }
  }

  private handleTradingAccounts() {
    this.investAccounts = [];

    // Trading Accounts
    if (this.axosClearingService.isAxosTradingActiveForUser()) {
      this.handleAxosTradingAccount();
    }

    if (this.isAxosInvestActive) {
      this.handleInvestAccounts();
    }

    this.concatUserAccounts();

    if (this.accountType === 'trading' && this.isSDTFeatureActive) {
      this.isPortfolioLoaded = false;
      const tradingAccount = this.accounts.find((x: OlbAccount) => x.id == this.accountId);
      this.subsink.sink = this.clearingService.getPortfolioOverview(tradingAccount.accountNumber).subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: result => {
          this.portfolioOverview = result;
          this.isPortfolioLoaded = true;
        },
      });
    }
    this.isAccountInfoLoaded = true;
  }

  private handleInvestAccounts() {
    const notifier = new Subject();

    this.store
      .select(getInvestAccount)
      .pipe(takeUntil(notifier))
      .subscribe(account => {
        if (!account.isLoaded) return;

        this.investAccounts.push(account as any);

        this.concatUserAccounts();
        this.axosInvestAccountsLoaded(account.availableBalance, account.hasError);
        notifier.next();
        notifier.complete();
      });
  }

  private concatUserAccounts(): void {
    this.accounts = [
      ...this.cachedAccountsService.internalAccounts.depositAccounts,
      ...this.cachedAccountsService.internalAccounts.loanAccounts,
      ...this.investAccounts,
      ...this.cachedAccountsService.getAggregatedAccntsToDdl(),
    ];
  }

  private handleAxosTradingAccount(): void {
    this.investAccounts.push(
      ...this.cachedTradingAccountsService.tradingAccounts.filter(t => +t.status !== ClearingAccountStatus.Closed)
    );
  }

  private handleDefaultTab(): void {
    if (this.params['tab']) {
      const tabId = this.params['tab'];
      this.activeTab = this.activeTabs.find(t => t === tabId) ?? AccountTab.Overview;
    } else if (this.rootScope['accountContainer-tab']) {
      const tabId = this.rootScope['accountContainer-tab'];
      this.activeTab = this.activeTabs[tabId] ?? AccountTab.Overview;
      this.rootScope['accountContainer-tab'] = tabId;
    } else if (this.params['tab']) {
      const tabId = this.params['tab'];
      this.activeTab = this.activeTabs.find(t => t === tabId) ?? AccountTab.Overview;
    }
  }

  private selectTab(tab: string): AccountTab {
    switch (tab) {
      case AccountTab.Transactions:
        return AccountTab.Transactions;
      case AccountTab.StatementTaxForms:
        return AccountTab.StatementTaxForms;
      default:
        return AccountTab.Overview;
    }
  }

  //#region LISTENERS

  private axosInvestAccountsLoaded(totalBalance: number, hasError: boolean) {
    this.totalValue = totalBalance;
    this.isAccountInfoLoaded = true;
    this.hasError = hasError;
  }
  //#endregion LISTENERS
}
