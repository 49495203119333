<div class="container">
  <app-funding-header
    [showSubtitle]="false"
    [showBack]="false"
    [headerTitle]="'Fund Goal: ' + milestoneAccount?.name"
    [title]="showFundingForm ? '' : 'Select the account to transfer funds into your investment goal'"
  ></app-funding-header>
  <div class="row justify-content-center">
    <div class="col-12 col-sm-8 col-md-6" *ngIf="!showFundingForm">
      <app-funding-account-group
        [axosAccounts]="axosAccounts"
        [nonAxosAccounts]="nonAxosAccounts"
        [milestoneAccount]="milestoneAccount"
        (selectAccount)="showFunding($event)"
      ></app-funding-account-group>
    </div>
    <div class="col-12 col-sm-12 col-md-12 mb-4" *ngIf="showFundingForm">
      <app-transfer-funds
        *ngIf="milestoneAccount"
        [transferType]="transferType"
        [goal]="milestoneAccount"
        [transferData]="transferFormData"
        (goBackEmitter)="showFundingForm = false"
        (transferCompleted)="fundingCompleted = true"
      ></app-transfer-funds>
      <app-dot-loader *ngIf="!milestoneAccount"></app-dot-loader>
    </div>
    <div class="col-12 text-center" *ngIf="!fundingCompleted">
      <a class="link" (click)="skipFund()">Skip and fund later</a>
    </div>
  </div>
</div>
