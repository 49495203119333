<uk2-modal
  #modal
  class="understanding-holdings-modal"
  [showCloseButton]="showCloseButton"
  [titleAlign]="titleAlign"
  [title]="title"
  [disabledCloseOverlayBackdrop]="disabledCloseOverlayBackdrop"
  [titleFontWeight]="titleFontWeight"
  [actionsGrid]="actionsGrid"
  [innerPanelClass]="innerPanelClass"
  (notifyCloseParent)="closeModal()"
>
  <ng-template #uk2ModalContent>
    <div class="understanding-content">
      <img src="assets/img/others/understanding-holdings-image.png" />
    </div>
  </ng-template>

  <ng-template #uk2ModalActions>
    <div class="understanding-actions">
      <ol class="understanding-holdings-modal__list">
        <ng-container *ngFor="let indication of indicationsList">
          <li>{{ indication }}</li>
        </ng-container>
      </ol>
    </div>
  </ng-template>
</uk2-modal>
