<div class="pagination">
  <button
    class="previous-page"
    type="button"
    (click)="selectPage(currentSelectedPage - 1)"
    [disabled]="currentSelectedPage === 0"
  >
    &lsaquo;
  </button>
  <div>
    <button
      [class.active]="currentSelectedPage === page"
      class="page-button"
      *ngFor="let page of filterPagesButtonOnView(buttonPages, currentSelectedPage, visibleSelectablePages)"
      type="button"
      (click)="selectPage(page)"
    >
      {{ page + 1 }}
    </button>
  </div>
  <button
    type="button"
    class="next-page"
    (click)="selectPage(currentSelectedPage + 1)"
    [disabled]="buttonPages.length - 1 === currentSelectedPage"
  >
    &rsaquo;
  </button>
</div>
