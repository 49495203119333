import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { InterestedParties, Address } from '@app/Areas/AAS/features/account-details/core/store/types';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ModalAction, ModalPersonType } from '../../trusted-interested-modal';
import {
  Uk2ButtonSizeEnum,
  Uk2LabeledIconPositionEnum,
  Uk2Tier1FilesEnum,
  Uk2Tier1UtilityEnum,
} from '@axos/uikit-v2-lib';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';

@Component({
  selector: 'app-interested-party',
  templateUrl: './interested-party.component.html',
  styleUrls: ['./interested-party.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterestedPartyComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject<void>();
  readonly modalActionType = ModalAction;
  interestedParties: InterestedParties[];
  interestedPartiesFormatted: Array<{ name: string; address: string[]; documentsReceived: string }> = [];
  interestedParty: InterestedParties;
  accountNumber: string;
  uk2ButtonSize = Uk2ButtonSizeEnum.small;
  labeledIconPosition = Uk2LabeledIconPositionEnum.left;
  editSvgIconName = Uk2Tier1FilesEnum.pencil;
  plusIcon = Uk2Tier1UtilityEnum.plusCircle;
  personType = ModalPersonType.InterestedParty;
  action: ModalAction;
  showModal = false;
  userCanAddEditInterestedParty = false;

  constructor(
    private peopleFacade: PeopleFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
    private pdpFacade: PdpFacade
  ) {}

  ngOnInit(): void {
    this.peopleFacade.accountPeopleTabState$
      .pipe(
        takeUntil(this.destroy$),
        filter(accountPeopleTabState => !!accountPeopleTabState?.accountPeople?.interestedParties),
        map(accountPeopleTabState => {
          this.accountNumber = accountPeopleTabState.accountNumber;

          return accountPeopleTabState.accountPeople.interestedParties.sort((a, b) => a.name.localeCompare(b.name));
        })
      )
      .subscribe(data => {
        this.interestedPartiesFormatted = []; // Reset variable
        this.interestedParties = data;
        data.forEach(a => {
          this.interestedPartiesFormatted.push({
            name: a.name,
            address: this.formatAddress(a.address),
            documentsReceived: this.formatDocumentsReceived(a),
          });
        });
        this.changeDetectorRef.markForCheck();
      });

    this.pdpFacade.selectedAasAccount$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(account => {
        this.userCanAddEditInterestedParty =
          this.featureFlagService.isRiaPilotAddEditInterestedPartyActive() &&
          account.dateCloseInitiated == null &&
          account.dateTerminated == null;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatAddress(addressObject: Address): string[] {
    let addressToDisplayLine1 = [];
    let addressToDisplayLine2 = '';
    const addressHasCity = addressObject.city && addressObject.city.length > 0;
    const addressHasState = addressObject.state && addressObject.state.length > 0;
    const addressHasZipCode = addressObject.zipCode && addressObject.zipCode.length > 0;

    for (const address in addressObject) {
      if (address.includes('address') && addressObject[address]) addressToDisplayLine1.push(addressObject[address]);
    }

    if (addressHasCity) {
      addressToDisplayLine2 += addressObject.city;
    }
    if (addressHasState) {
      addressToDisplayLine2 += (addressHasCity ? ', ' : '') + addressObject.state;
    }
    if (addressHasZipCode) {
      addressToDisplayLine2 +=
        (addressHasCity && !addressHasState ? ',' : '') +
        (addressHasCity || addressHasState ? ' ' : '') +
        addressObject.zipCode;
    }

    return [...addressToDisplayLine1, addressToDisplayLine2];
  }

  formatDocumentsReceived(interestedParty: InterestedParties): string {
    let documentsReceived = '';
    documentsReceived += interestedParty.taxForms ? 'Tax Forms' : '';
    documentsReceived += interestedParty.statementForms
      ? `${documentsReceived.length > 0 ? ' and ' : ''}Statements`
      : '';
    return documentsReceived;
  }

  modalClosed() {
    this.showModal = false;
  }

  openInterestedPartyModal(modalAction: ModalAction, interestedParty?: InterestedParties) {
    this.action = modalAction;
    this.interestedParty = interestedParty;
    this.showModal = true;
  }
}
