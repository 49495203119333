<div class="reveal-label" [class.reveal-label--invalid]="targetIsInvalid">
  <div class="reveal-label__items">
    <label [for]="target" class="reveal-label__text">
      <ng-content></ng-content>
    </label>

    <app-tooltip *ngIf="tooltip" [content]="tooltip"></app-tooltip>
  </div>

  <button class="reveal-label__btn" type="button" (click)="switchView()">
    <app-svg-icon
      class="reveal-label__btn-icon mr-1"
      [iconName]="revealIcon"
      [iconColor]="iconColor"
      width="1.15em"
      height="1.15em"
    ></app-svg-icon>
    {{ revealText }}
  </button>
</div>
