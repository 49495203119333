import { Component, Inject, OnInit } from '@angular/core';

import { getBrandPropertyValue } from '@app/utils';
import { olbSettings } from '@core/tokens';
import { BrandPropertyService } from '@legacy/services/brand-property.service';
import { LiveChatService } from '@legacy/services/live-chat.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { LiveChatSettings } from '@shared/models';

@Component({
  selector: 'app-live-chat',
  template: '',
})
export class LiveChatComponent implements OnInit {
  liveChatSettings = new LiveChatSettings();
  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    private liveChatService: LiveChatService,
    private brandPropertyService: BrandPropertyService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.loadLiveChatProperties(this.settings.brandId);
  }

  private loadLiveChatProperties(brandId: number): void {
    this.brandPropertyService
      .getLiveChatProperties(brandId)
      .then(res => {
        this.setLiveChatSettings(res.data);
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }

  private setLiveChatSettings(brandProperties: BrandProperty[]) {
    this.liveChatSettings.isActive =
      getBrandPropertyValue(brandProperties, 'IsActive').toLowerCase() === 'true';
    if (this.liveChatSettings.isActive) {
      this.liveChatSettings.eswScriptUrl = getBrandPropertyValue(brandProperties, 'ESWScriptUrl');
      this.liveChatSettings.salesforceUrl = getBrandPropertyValue(brandProperties, 'SalesforceUrl');
      this.liveChatSettings.liveAgentUrl = getBrandPropertyValue(brandProperties, 'LiveAgentUrl');
      this.liveChatSettings.orgId = getBrandPropertyValue(brandProperties, 'OrgId');
      this.liveChatSettings.site = getBrandPropertyValue(brandProperties, 'Site');
      this.liveChatSettings.siteUrl = getBrandPropertyValue(brandProperties, 'SiteUrl');
      this.liveChatSettings.devName = getBrandPropertyValue(brandProperties, 'DevName');
      this.liveChatSettings.deploymentId = getBrandPropertyValue(brandProperties, 'DeploymentId');
      this.liveChatSettings.isUserAuthenticated = this.serviceHelper.isUserAuthenticated();
      // Replace this.liveChatSettings.domain with 'localhost' for dev test
      this.liveChatSettings.domain = getBrandPropertyValue(brandProperties, 'SnapInDomain');

      if (this.serviceHelper.isUserAuthenticated()) {
        this.liveChatSettings.routingButtons = JSON.parse(
          getBrandPropertyValue(brandProperties, 'RoutingButtons')
        );
        this.liveChatSettings.buttonId = getBrandPropertyValue(brandProperties, 'ButtonId');
      } else {
        this.liveChatSettings.routingButtons = JSON.parse(
          getBrandPropertyValue(brandProperties, 'LoginRoutingButtons')
        );
        this.liveChatSettings.buttonId = getBrandPropertyValue(brandProperties, 'LoginButtonId');
      }

      this.liveChatService.settings = this.liveChatSettings;
      this.liveChatService.generateScript();
    }
  }
}
