/** Represents a mailing address. */
export class Address {
  /** AddressType value from Identity */
  addressType: number;
  /** Street address 1. */
  streetAddress1: string;
  /** Optional street address 2. */
  streetAddress2?: string;
  /** City. */
  city: string;
  /** Postal code (Zip code). */
  postalCode: string;
  /** State Code. Eg. CA for California. */
  stateCode?: string;
  /** State. */
  state: string;
  id?: string;

  constructor(data: Partial<Address> = {}) {
    Object.assign(this, data);
  }
}

export class AddressAccountAssociation {
  address?: Address;
  accountIds?: number[];

  constructor(address?: Partial<AddressAccountAssociation>) {
    Object.assign(this, address);
  }
}
