import { IRootScopeService, ITimeoutService } from 'angular';
import { BillPayRouterHelper } from 'bill-pay/bill-pay-router.helper';
import { BillPayHelper } from 'bill-pay/bill-pay.helper';
import { Captcha } from 'bill-pay/typings/Captcha';
import { Credential } from 'bill-pay/typings/Credential';
import { EBillLoginResponse } from 'bill-pay/typings/EBillLoginResponse';
import { OneTimePasscode } from 'bill-pay/typings/OneTimePasscode';
import { Recipient } from 'bill-pay/typings/Recipient';
import { VerifyEBillLogin } from 'bill-pay/typings/VerifyEBillLogin';
import { IBillPayService } from 'services/typings/IBillPayService';

import { Inject } from '../../../decorators/decorators';

@Inject(
  'billPayService',
  '$timeout',
  'serviceHelper',
  'billPayRouterHelper',
  'billPayHelper',
  '$rootScope'
)
export class EStatementsAuthenticationController {
  recipient: Recipient;
  recipientId: number;
  sessionId: string;
  credentials: Credential[];
  errorMessage: string;
  captchaString: string;
  otpOptions: OneTimePasscode;

  isLoading: boolean;

  private verifyLoginInfo: VerifyEBillLogin = {};
  private verifyLoginResponse: EBillLoginResponse;
  private captcha: Captcha;

  constructor(
    private readonly _billPayService: IBillPayService,
    private readonly _timeoutService: ITimeoutService,
    private readonly _serviceHelper: IServiceHelper,
    private readonly _billPayRouterHelper: BillPayRouterHelper,
    private readonly _billPayHelper: BillPayHelper,
    private readonly _root: IRootScopeService
  ) {}

  $onInit() {
    this.isLoading = true;
    this.recipient = this._billPayRouterHelper.getRecipientDetails();
    this.sessionId = this._billPayRouterHelper.getSessionId();

    this.setCredentialsInfo();

    this._root.$on('credentialsUpdated', this.setCredentialsInfo.bind(this));
    this._serviceHelper.scrollToTop();
  }

  /**
   * Verifies if the credentials were provided correctly - Step 1
   */
  verifyEBillLogin(credentials: Credential[]): void {
    this.isLoading = true;
    this.verifyLoginInfo.credentials = credentials;
    this.verifyLoginInfo.sessionId = this.sessionId;
    this._billPayService
      .verifyEBillLogin(this.verifyLoginInfo)
      .then(response => {
        this.verifyLoginResponse = response.data;
        const timeout =
          new Date(this.verifyLoginResponse.callBackTime).getTime() -
          new Date(this.verifyLoginResponse.timestamp).getTime();
        this._timeoutService(() => {
          this._billPayHelper
            .checkForFurtherActions(this.verifyLoginResponse)
            .then(() => {
              this.isLoading = false;
            })
            .catch(err => {
              this.errorMessage = !err.data && err.message ? err.message : err.data.message;
              this.isLoading = false;
            });
        }, timeout);
      })
      .catch(err => {
        this.errorMessage = err.data.message;
        this.isLoading = false;
      });
  }

  goBack(): void {
    history.back();
  }

  cancelSetUp() {
    this._billPayRouterHelper.cancelSetup();
  }

  private setCredentialsInfo() {
    this.credentials = this._billPayRouterHelper.getCredentials();
    this.captcha = this._billPayRouterHelper.getCaptcha();
    if (this.captcha) {
      this.captchaString = `data:${this.captcha.mimeType};base64,${this.captcha.image}`;
    }
    this.otpOptions = this._billPayRouterHelper.getOtp();

    this.isLoading = false;
  }
}
