import { createFeatureSelector } from '@ngrx/store';

import { InvestAccount } from '@app/axos-invest/models';
import { TradingAccount } from '@app/axos-trading/models';
import { AppStateFeatures } from '@core/enums';
import { ExternalBankProvider } from '@shared/models';

import { AccountStatePieces } from '../enums';
import { CategorizedAccounts, ExternalAccount, OlbAccount } from '../models';
import { InternalAccountsState } from './internal-accounts/internal-accounts.state';

export class AccountsState {
  [AccountStatePieces.Aggregated]: CategorizedAccounts;
  [AccountStatePieces.Closed]: Record<number, OlbAccount>;
  [AccountStatePieces.External]: Record<number, ExternalAccount>;
  [AccountStatePieces.InternalAccounts]: InternalAccountsState;
  [AccountStatePieces.Invest]: InvestAccount;
  [AccountStatePieces.Providers]: ExternalBankProvider[];
  [AccountStatePieces.Trading]: Record<number, TradingAccount>;

  constructor(args?: Partial<AccountsState>) {
    Object.assign(this, args);
  }
}

export const getAccountsState = createFeatureSelector<AccountsState>(AppStateFeatures.Accounts);
