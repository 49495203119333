import { Inject } from '../decorators/decorators';
import { IOmnichannelService } from './typings/IOmnichannelService';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class OmnichannelService extends BaseService implements IOmnichannelService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'omnichannel', _serviceHelper, q);
  }

  isFeatureActive(): ApiResponse<boolean> {
    return this.get('IsFeatureEnabled');
  }
}
