import { AccountFirmContactsStateType } from './account-firm-contacts/account-firm-contacts-state.type';
import { AccountInformationStateType } from './account-information-state.type';

export type AccountDetailsStateType = {
  accountInformationList: AccountInformationStateType[];
  selectedAccountDetails: string | undefined;
  accountFirmContactsList: AccountFirmContactsStateType[];
};

export const initialAccountDetailsState: AccountDetailsStateType = {
  accountInformationList: [],
  selectedAccountDetails: undefined,
  accountFirmContactsList: [],
};
