import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { FacingBrand } from '@core/enums';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

import { DebitCard } from '../../models';
import { SubSink } from '@axos/subsink';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { getInternalDepositAccountsList } from '@app/accounts/store/selectors';

@Component({
  selector: 'app-debit-card-image',
  templateUrl: './debit-card-image.component.html',
  styleUrls: ['./debit-card-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebitCardImageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() debitCard: Partial<DebitCard>;
  cardType: string;
  isHorizontalCard: boolean;
  isDebitCardAtm: boolean;
  debitCardImage: string;
  cardLastDigits: string;
  account: any;
  isSBB: boolean;
  showNewCardImg: boolean;
  cardStatus: string = 'active';
  private subsink = new SubSink();
  numberValue: string = '12%';
  dateValueRight: string = '8%';
  dateValueBottom: string = '44%';
  nameValue: string = '10%';
  secondNameValue: string = '10%';

  constructor(@Inject(olbSettings) private env: OlbSettings, private store: Store<any>) {}

  ngOnInit(): void {
    this.isHorizontalCard = this.env.facingBrandId === FacingBrand.UFB;
    this.showNewCardImg = this.env.facingBrandId !== FacingBrand.Axos ? false : true;
    this.loadCardInfo();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.loadCardInfo();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  private loadCardInfo() {
    this.isDebitCardAtm = this.debitCard.debitCardTypeDescription.toUpperCase() === 'ATM';
    this.cardType = this.getCardType();
    this.cardStatus = this.debitCard.isActive ? 'active' : 'inactive';
    this.subsink.sink = this.store
      .select(getInternalDepositAccountsList)
      .pipe(filter(data => data.length > 0))
      .subscribe(accounts => {
        this.account = accounts.find(acc => acc.id == this.debitCard.accountId);
        this.isSBB = this.account?.isSBB ?? false;
      });

    this.debitCardImage = this.getDebitCardImage();
    this.cardLastDigits = this.debitCard.cardNumberMask.slice(this.debitCard.cardNumberMask.length - 4);
    this.getStyles();
  }

  private getDebitCardImage(): string {
    if (this.env.facingBrandId === FacingBrand.Nationwide && this.isDebitCardAtm) {
      return 'assets/axos/img/debit-card.png';
    }
    if (this.isSBB) {
      return `assets/sbb/img/debit-card-${this.cardStatus}.png`;
    }
    if (this.env.facingBrandId === FacingBrand.Axos) {
      return `assets/axos/img/debit-card-${this.cardStatus}.png`;
    }
    return `assets/${this.env.brand}/img/debit-card.png`;
  }

  private getCardType(): string {
    switch (this.env.facingBrandId) {
      case 2:
        if (this.isDebitCardAtm) return '';
        return 'card__nationwide';
      case 3:
        return 'card__horizontal';
      default:
        return '';
    }
  }

  private getStyles(): void {
    if (this.showNewCardImg) {
      this.numberValue = '21%';
      this.dateValueRight = '21%';
      this.dateValueBottom = '42%';
      this.nameValue = '18%';
      this.secondNameValue = '18%';
    }
  }
}
