import { downgradeComponent } from '@angular/upgrade/static';
import { AlertHistoryComponent } from '@app/alerts-notifications/components';
import { PrivacyPreferencesComponent } from '@app/alerts-notifications/components';

import { Inject } from '@legacy/decorators/decorators';

@Inject('$stateProvider')
export class alertsNotificationsLegacyConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.alertsNotifications.preferences', {
        url: '/Preferences',
        views: {
          'alerts-notifications': {
            template: '<app-privacy-preferences></app-privacy-preferences>',
          },
        },
      })
      .state('udb.alertsNotifications.history', {
        url: '/AlertsHistory',
        views: {
          'alerts-notifications': {
            template: '<app-alert-history><app-alert-history>',
          },
        },
      });
  }
}

export const alertsNotificationsLegacyModule = angular
  .module('udb.alertsNotificationsLegacy', ['ui.router'])
  .directive('appPrivacyPreferences', downgradeComponent({ component: PrivacyPreferencesComponent }))
  .directive('appAlertHistory', downgradeComponent({ component: AlertHistoryComponent }))
  .config(alertsNotificationsLegacyConfig).name;
