export class TravelNotification {
  travelId: number;
  comments: string;
  startDate: Date;
  endDate: Date;
  isDeleted?: boolean;
  debitCardId?: number;

  constructor(args: Partial<TravelNotification>) {
    const startDate = new Date(args.startDate);
    const endDate = new Date(args.endDate);

    Object.assign(this, args, { startDate, endDate });
  }
}
