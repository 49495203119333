import { Pipe, PipeTransform } from '@angular/core';

import { AbbreviateNumber } from '@app/utils';

@Pipe({
  name: 'abbreviateCurrency',
})
export class AbbreviateCurrencyPipe implements PipeTransform {
  transform(inputNumber: any, decimalPrecision?: any): any {
    return AbbreviateNumber(inputNumber, decimalPrecision);
  }
}
