import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TaxPlanBeneficiary } from '@app/accounts/models';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class TaxPlanBeneficiariesService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'tax-plan/beneficiaries');
  }

  getRelationshipCatalog(): WebApiResponse<any> {
    return this.get('catalogs/relationship');
  }

  addBeneficiary(beneficiary: TaxPlanBeneficiary): WebApiResponse<string> {
    return this.post(``, beneficiary);
  }

  editBeneficiary(beneficiary: TaxPlanBeneficiary): WebApiResponse<void> {
    return this.put(``, beneficiary);
  }

  removeBeneficiary(taxPlanCif: string, taxPlanType: number, beneficiaryKey: string): WebApiResponse<void> {
    return this.delete(`${taxPlanCif}/${taxPlanType}/${beneficiaryKey}`);
  }
}
