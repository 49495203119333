import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { AddressType } from '@app/user-profile/enums';
import { addressIsEmpty, createAddressForm } from '@app/user-profile/utils';
import { FilesIcons } from '@shared/enums';
import { Address } from '@shared/models';

@Component({
  selector: 'dc-debit-card-address',
  templateUrl: './debit-card-address.component.html',
})
export class DebitCardAddressComponent implements OnInit {
  @Input() address: Address;
  @Input() enabled = true;

  @Output() changeAddress = new EventEmitter<Address>();
  @Output() edit = new EventEmitter<boolean>();

  pencilIcon = FilesIcons.Pencil;
  showForm = false;
  addressIsEmpty: boolean;
  addressForm: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.addressForm = createAddressForm(new Address(), AddressType.None);
    this.addressIsEmpty = addressIsEmpty(this.address);
    this.showForm = this.addressIsEmpty;
  }

  emitAddress(): void {
    if (this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
    } else {
      this.changeAddress.emit(this.addressForm.value);
      this.address = { ...this.addressForm.value };
      this.addressIsEmpty = false;
      this.showForm = false;
      this.edit.emit(false);
    }
  }

  editAddress(): void {
    this.edit.emit(true);
    this.showForm = true;
  }

  cancel(): void {
    this.edit.emit(false);
    this.showForm = false;
    this.addressForm.reset();
  }
}
