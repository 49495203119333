import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { SitecoreComponents } from '@bofi/sitecore-components';
import * as angular from 'angular';

import { ForgotPasswordComponent, ForgotUsernameComponent, LoginComponent } from '@app/authentication/components';
import { EnrollmentViewStateService } from '@app/enrollment/services';

import { AuthConfig } from './auth/auth.config';
import { AuthController } from './auth/auth.controller';
import { AuthRun } from './auth/auth.run';
import { common } from './common/common';
import { services } from './services/services';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { SharedModule } from './shared/shared.module';
import { accountUnlockLegacyModule, enrollmentLegacyModule } from './upgraded';
import { RIAContactsService } from '@app/Areas/AAS/aas-shared/services/contacts-service/contacts.service';
import { accountsService } from '@app/Areas/AAS/aas-shared/services/accounts.service';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';

export const authModule = angular
  .module('auth', [
    'ui.mask',
    'ui.router',
    'udb.tpls',
    common,
    enrollmentLegacyModule,
    services,
    SharedModule,
    SitecoreComponents,
    accountUnlockLegacyModule,
  ])
  .run(AuthRun)
  .config(AuthConfig)
  .service('cookieHelper', downgradeInjectable(CookieHelper))
  .controller('AuthController', AuthController)
  .directive('appLogin', downgradeComponent({ component: LoginComponent }))
  .directive('appForgotUsername', downgradeComponent({ component: ForgotUsernameComponent }))
  .directive('appForgotPassword', downgradeComponent({ component: ForgotPasswordComponent }))
  .factory('enrollmentViewStateService', downgradeInjectable(EnrollmentViewStateService))
  .factory('riaContactService', downgradeInjectable(RIAContactsService))
  .factory('agreementService', downgradeInjectable(AgreementService))
  .factory('riaAccountService', downgradeInjectable(accountsService)).name;
