import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberValidatorFn(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    const PHONE_NUMBER_REGEX = /^[0-9]*$/;
    if (PHONE_NUMBER_REGEX.test(controlValue)) return null;

    return { phoneNumberValidation: true };
  };
}
