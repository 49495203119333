import { _fixedSizeVirtualScrollStrategyFactory } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

import { GOAL_ICONS } from '@app/axos-invest/constants';
import { PortfolioAllocation, PortfolioType } from '@app/axos-invest/enums';
import { Goal, GoalAllocation, PortfolioDetails } from '@app/axos-invest/models';
import { createEditPortfolioForm } from '@app/axos-invest/utils';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-edit-portfolio-form',
  templateUrl: './edit-portfolio-form.component.html',
  styleUrls: ['./edit-portfolio-form.component.scss'],
})
export class EditPortfolioFormComponent implements OnInit {
  @Input() goal: Goal;
  @Input() allocation: PortfolioDetails;
  @Input() type: PortfolioAllocation;
  @Input() portfolioType: PortfolioType;
  @Input() previousPortfolioType: PortfolioType;
  @Input() goalAllocation: GoalAllocation;

  @Output() saveChanges = new EventEmitter<UntypedFormGroup>();
  @Output() deleteEndTarget = new EventEmitter();

  icons = {
    goalForecastIcon: UtilityIcons.Ribbon,
    trash: UtilityIcons.Trash,
  };
  form: UntypedFormGroup;
  mask: string;
  PORTFOLIO_ALLOCATIONS = PortfolioAllocation;
  PORTFOLIO_TYPES = PortfolioType;

  get typeName() {
    return this.type === PortfolioAllocation.End ? 'End' : 'Current';
  }

  get goalIcon() {
    return GOAL_ICONS[this.goal.type];
  }

  ngOnInit(): void {
    this.buildForm();
  }

  submitForm() {
    if (this.portfolioType === PortfolioType.GlidepathEnabled && this.validateIfAreEqualAllocations(this.form.value)) {
      this.form.get('cashAllocation').setErrors({ invalid: true });
    }

    this.saveChanges.emit(this.form);
  }

  onFocusCashAllocation(): void {
    const control = this.form.get('cashAllocation');
    if (control.value === 0) control.setValue('', { emitEvent: false });
  }

  onBlurCashAllocation(): void {
    const control = this.form.get('cashAllocation');
    if (control.value === '') control.setValue(0);
  }

  private onChangeCashAllocation(cashAllocation: number): void {
    const cashControl = this.form.get('cashAllocation');
    if (cashControl.invalid) return;
    cashControl.setValue(cashAllocation, { emitEvent: false });
  }

  private onChangeSlider() {
    this.form.controls.cashAllocation.setErrors(null);
  }

  private buildForm() {
    this.form = createEditPortfolioForm(this.allocation, this.portfolioType);
    this.mask = this.portfolioType === PortfolioType.Basic ? '000' : '00';
    this.setFormEvents();
    this.setFormEventSlider();
  }

  private setFormEvents() {
    this.form
      .get('cashAllocation')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => this.onChangeCashAllocation(parseFloat(value)));
  }

  private setFormEventSlider() {
    this.form
      .get('stockAllocation')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => this.onChangeSlider());
  }

  private validateIfAreEqualAllocations(details: PortfolioDetails) {
    let allocationScore;
    let cashScore;

    if (this.type === PortfolioAllocation.End) {
      allocationScore = this.goalAllocation.allocationStart;
      cashScore = this.goalAllocation.cashStart;
    } else {
      allocationScore = this.goalAllocation.allocationEnd;
      cashScore = this.goalAllocation.cashEnd;
    }
    const cashModified = details.cashAllocation / 100;

    return details.allocationScore === allocationScore && cashModified === cashScore;
  }
}
