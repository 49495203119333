import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import * as angular from 'angular';

import { PercentageAmountComponent, TransferAmountComponent } from '@app/transfers/components';

import { EditSingleTransfer } from './edit-transfer/edit-single-transfer.component';
import { P2PLegacyModule } from '@legacy/upgraded';
import { EditExternalTransferModal } from './scheduler-transfers/edit-external-transfer-modal/edit-external-transfer-modal.controller';
import { EditInternalTransferModal } from './scheduler-transfers/edit-internal-transfer-modal/edit-internal-transfer-modal.controller';
import { EditLoanPaymentModal } from './scheduler-transfers/edit-loan-payment-modal/edit-loan-payment-modal.controller';
import { SchedulerTransfers } from './scheduler-transfers/scheduler-transfers';
import { PreTransferConfirmationModal } from './transfer-funds/pre-transfer-confirmation-modal/pre-transfer-confirmation-modal.controller';
import { TransferFunds } from './transfer-funds/transfer-funds';
import { TransfersConfig } from './transfers.config';
import { TransfersController } from './transfers.controller';
import { UpcomingTransfers } from './upcoming-transfers/upcoming-transfers';
import { BankDetails } from './wire-transfers/bank-details/bank-details.module';
import { WireMultiFactor } from './wire-transfers/multi-factor/wire-multi-factor.module';
import { RecipientDetails } from './wire-transfers/recipient-details/recipient-details.module';
import { ReviewTransfer } from './wire-transfers/review-transfer/review-transfer.module';
import { WireTransfers } from './wire-transfers/wire-transfers.module';
import { SendMoneySbbContainerComponent } from './p2p/send-money/send-money-sbb-container/send-money-sbb-container.component';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';

export const transfers = angular
  .module('udb.transfers', [
    TransferFunds,
    UpcomingTransfers,
    SchedulerTransfers,
    P2PLegacyModule,
    WireTransfers,
    RecipientDetails,
    BankDetails,
    ReviewTransfer,
    WireMultiFactor,
  ])
  .component('udbEditSingleTransfer', EditSingleTransfer)
  .controller('TransfersController', TransfersController)
  .controller('EditExternalTransferModal', EditExternalTransferModal)
  .controller('EditInternalTransferModal', EditInternalTransferModal)
  .controller('PreTransferConfirmationModal', PreTransferConfirmationModal)
  .controller('EditLoanPaymentModal', EditLoanPaymentModal)
  .directive('appTransferAmount', downgradeComponent({ component: TransferAmountComponent }))
  .directive('appPercentageAmount', downgradeComponent({ component: PercentageAmountComponent }))
  .directive('appSendMoneySbbContainer', downgradeComponent({ component: SendMoneySbbContainerComponent }))
  .service('dateHelper', downgradeInjectable(DateHelper))
  .config(TransfersConfig).name;
