export enum UserProfileViews {
  ProfileIcon = 'ProfileIcon',
  SignInAndSecurity = 'SignInAndSecurity',
  ContactInfo = 'ContactInfo',
  PersonalInfo = 'PersonalInfo',
  BusinessInformation = 'BusinessInformation',
  EmploymentInfo = 'EmploymentInfo',
  PersonalInformation = 'PersonalInformation',
  CompanyInformation = 'CompanyInformation',
  DirectDeposit = 'DirectDeposit',
  GoogleAuthenticator = 'GoogleAuthenticator',
}
