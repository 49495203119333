import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { DebitCardConfirmationViewComponent } from '@app/accounts/submodules/debit-card/views/debit-card-confirmation-view/debit-card-confirmation-view.component';

import { AccountDebitCardManagementConfig } from './debit-card-management.config';

export const AccountDebitCardManagementModule = angular
  .module('udb.AccountDebitCardManagement', ['ui.router'])
  .directive('appDebitCardConfirmationView', downgradeComponent({ component: DebitCardConfirmationViewComponent }))
  .config(AccountDebitCardManagementConfig).name;
