import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { EDIT_PORTFOLIO_CONTENT } from '@app/axos-invest/constants';
import { EditPortfolioStep, PortfolioAllocation, PortfolioType } from '@app/axos-invest/enums';
import { Goal, GoalAllocation, PortfolioDetails, PortfolioInfo } from '@app/axos-invest/models';
import { AxosInvestService } from '@app/axos-invest/services';
import { updateGoal } from '@app/axos-invest/store/actions';
import { getGoalSummary } from '@app/axos-invest/store/selectors';
import { calculatePortfolioType } from '@app/axos-invest/utils';
import { DialogService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AlertsIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

@Component({
  selector: 'app-edit-portfolio',
  templateUrl: './edit-portfolio.component.html',
  styleUrls: ['./edit-portfolio.component.scss'],
})
export class EditPortfolioComponent implements OnInit, OnDestroy {
  isLoading = true;
  goal: Goal;
  currentStep: EditPortfolioStep;
  steps = EditPortfolioStep;
  allocation: PortfolioDetails;
  score: PortfolioInfo;
  isUpdatingAllocation: boolean;
  portfolioType: PortfolioType;
  portfolioAllocationType = PortfolioAllocation.Start;
  goalAllocation: GoalAllocation;
  content: { title?: string; subtitle?: string } = {};
  currentAllocation: PortfolioDetails;
  endAllocation: PortfolioDetails;
  displayModifiedBanner = false;
  displayModifiedErrorBanner = false;
  bannerText: string;
  previousPortfolioType: PortfolioType;

  private riskModel: PortfolioInfo[];
  private goalId: string;
  private subSink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private store: Store,
    private investService: AxosInvestService,
    private serviceHelper: ServiceHelper,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.goalId = this.params['id'];
    forkJoin([this.getMilestones(), this.getRiskScoreModel()]).subscribe(() => {
      this.buildAllocations();
      this.initFirstStep();
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  goBack() {
    this.displayModifiedErrorBanner = false;
    this.displayModifiedBanner = false;
    const backStrategy = {
      [EditPortfolioStep.AllocationSelector]: this.redirectToPortfolio,
      [EditPortfolioStep.Form]: this.formBack,
      [EditPortfolioStep.ReviewCurrentAllocation]: () => this.loadStep(EditPortfolioStep.Form),
      [EditPortfolioStep.AllocationUpdatedConfirmation]: this.redirectToPortfolio,
    };
    backStrategy[this.currentStep].bind(this)();
  }

  onSubmitForm(form: UntypedFormGroup) {
    const position = this.portfolioAllocationType === PortfolioAllocation.End ? 'end' : 'current';
    const otherPosition = this.portfolioAllocationType !== PortfolioAllocation.End ? 'end' : 'current';
    if (form.invalid) {
      this.displayErrorBanner(position, otherPosition);

      return;
    }

    this.displayModifiedErrorBanner = false;
    this.displayModifiedBanner = false;
    this.currentStep = EditPortfolioStep.ReviewCurrentAllocation;
    this.allocation = form.value;
    this.score = this.riskModel.find(s => s.allocationScore === form.value.allocationScore);

    const cash = this.allocation.cashAllocation / 100;
    if (this.portfolioAllocationType === PortfolioAllocation.Start) {
      this.goalAllocation.allocationStart = this.score.allocationScore;
      this.goalAllocation.cashStart = cash;
    }
    if (this.portfolioAllocationType === PortfolioAllocation.End || this.portfolioType === PortfolioType.Basic) {
      this.goalAllocation.allocationEnd = this.score.allocationScore;
      this.goalAllocation.cashEnd = cash;
    }

    this.content.title = `Review your ${position} asset breakdown`;
    this.content.subtitle = null;
  }

  updateAllocation() {
    this.isUpdatingAllocation = true;
    if (this.validateAllocation()) return;

    this.investService
      .updateAllocation(this.goal.id, this.goalAllocation)
      .pipe(finalize(() => (this.isUpdatingAllocation = false)))
      .subscribe(
        () => {
          this.currentStep = EditPortfolioStep.AllocationUpdatedConfirmation;
          this.goal.allocation = {
            ...this.goal.allocation,
            ...this.goalAllocation,
            allocationModel: this.goalAllocation.allocationStart,
          };
          this.store.dispatch(updateGoal({ payload: this.goal }));
        },
        error => this.serviceHelper.errorHandler(error)
      );
  }

  chooseAllocation(type: PortfolioAllocation) {
    this.displayModifiedErrorBanner = false;
    this.displayModifiedBanner = false;
    this.portfolioAllocationType = type;
    this.allocation = type === PortfolioAllocation.Start ? this.currentAllocation : this.endAllocation;
    this.loadStep(EditPortfolioStep.Form);
  }

  addEndingAllocation() {
    this.previousPortfolioType = PortfolioType.Basic;
    this.portfolioType = PortfolioType.GlidepathEnabled;
    this.buildAllocations();
    this.chooseAllocation(PortfolioAllocation.End);
  }

  displayDeleteEndTargetDialog() {
    const dialogData = new DialogData({
      title: 'Delete end target allocation?',
      content: 'By deleting your end target, your portfolio will no longer automatically change over time.',
      cancelText: 'Cancel',
      okText: 'Delete End Target',
      hasCloseButton: true,
      icon: AlertsIcons.QuestionCircle,
    });

    const dialog = this.dialogService.open(dialogData);

    this.subSink.sink = dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(confirm => {
        if (confirm) this.deleteEndingAllocation();
      });
  }

  private deleteEndingAllocation() {
    this.goalAllocation.allocationEnd = this.goalAllocation.allocationStart;
    this.goalAllocation.cashEnd = this.goalAllocation.cashStart;
    this.portfolioType = PortfolioType.Basic;
    this.previousPortfolioType = PortfolioType.GlidepathEnabled;
    this.updateAllocation();
  }

  private initFirstStep() {
    const initialStrategy = {
      [PortfolioType.Basic]: this.loadBasicPortfolio,
      [PortfolioType.GlidepathEnabled]: () => this.loadStep(EditPortfolioStep.AllocationSelector),
    };
    initialStrategy[this.portfolioType].bind(this)();
  }

  private loadBasicPortfolio() {
    const setUpEndAllocation = this.params['isSetUpEndTarget'];
    const strategy = setUpEndAllocation
      ? () => this.addEndingAllocation()
      : () => this.chooseAllocation(PortfolioAllocation.Start);
    strategy.bind(this)();
  }

  private formBack() {
    if (this.portfolioType === PortfolioType.Basic) this.redirectToPortfolio();
    else this.loadStep(EditPortfolioStep.AllocationSelector);
  }

  private redirectToPortfolio() {
    this.state.go('udb.axosinvest', { id: this.goalId, referrerTabId: 2 });
  }

  private loadStep(step: EditPortfolioStep) {
    this.content = JSON.parse(JSON.stringify(EDIT_PORTFOLIO_CONTENT[step]));
    this.currentStep = step;
  }

  private getMilestones() {
    return this.store.select(getGoalSummary).pipe(
      filter(data => data.isLoaded),
      take(1),
      map(summary => {
        this.goal = JSON.parse(JSON.stringify(summary.milestones.find(m => m.id === this.goalId)));
        this.portfolioType = calculatePortfolioType(this.goal.allocation);
        this.goalAllocation = new GoalAllocation({
          allocationStart: this.goal.allocation.allocationStart,
          allocationEnd: this.goal.allocation.allocationEnd,
          cashStart: this.goal.allocation.cashStart,
          cashEnd: this.goal.allocation.cashEnd,
        });
      })
    );
  }

  private getRiskScoreModel() {
    return this.investService.getRiskScore().pipe(
      take(1),
      map(result => (this.riskModel = Object.entries(result.data.portfolios).map(key => key[1])))
    );
  }

  private buildAllocations() {
    if (!this.goalAllocation || !this.riskModel) return;

    const riskStart = this.riskModel.find(risk => risk.allocationScore === this.goalAllocation.allocationStart);
    this.currentAllocation = this.getPortfolioDetails(
      riskStart,
      this.goalAllocation.cashStart,
      this.goalAllocation.allocationStart
    );

    const riskEnd = this.riskModel.find(risk => risk.allocationScore === this.goalAllocation.allocationEnd);
    this.endAllocation = this.getPortfolioDetails(
      riskEnd,
      this.goalAllocation.cashEnd,
      this.goalAllocation.allocationEnd
    );
  }

  private getPortfolioDetails(risk: PortfolioInfo, cash: number, allocationScore: string) {
    cash = Number((cash * 100).toFixed());
    const totalAvailable = 100 - cash;

    return new PortfolioDetails({
      cashAllocation: cash,
      bondAllocation: Number((totalAvailable * risk.targetBondRate).toFixed()),
      stockAllocation: Number((totalAvailable * risk.targetStockRate).toFixed()),
      allocationScore,
    });
  }

  private validateAllocation() {
    if (
      this.portfolioType === PortfolioType.GlidepathEnabled &&
      this.currentStep === EditPortfolioStep.ReviewCurrentAllocation
    ) {
      const position = this.portfolioAllocationType === PortfolioAllocation.End ? 'end' : 'current';
      this.currentStep = EditPortfolioStep.AllocationSelector;
      this.displayModifiedBanner = true;
      this.bannerText = `Your ${position} target allocation has been updated.`;
      this.isUpdatingAllocation = false;
      this.buildAllocations();

      return true;
    }
  }

  private displayErrorBanner(position: string, otherPosition: string) {
    this.displayModifiedErrorBanner = true;
    this.bannerText = `Your ${position} target cannot be the same as your ${otherPosition} target. Please change your allocation.`;
  }
}
