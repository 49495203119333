import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { parseISO } from 'date-fns';

import { isMarketOpened } from '@app/Areas/AAS/features/utils';
import { TransactionService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class TradeMarketService {
  constructor(private transactionService: TransactionService) {}

  isMarketOpened(currentDate: Date) {
    return this.transactionService.getHolidayDates().pipe(
      map(response => response.data.dates.map(holiday => parseISO(holiday))),
      map(holidays => isMarketOpened(currentDate, holidays))
    );
  }
}
