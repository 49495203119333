import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ChangePasswordRequest } from '@app/authentication/models';
import {
  EmploymentInfo,
  FamilyInfo,
  FinancialInfo,
  SecretWordInfo,
  UpdateEmailsRequest,
  UpdatePhone,
  UpdatePhonesRequest,
} from '@app/user-profile/models';
import { FinancialCatalogsState } from '@app/user-profile/store';
import {
  loadEmploymentStasuses,
  loadFinancialCatalogs,
  loadIsNewUser,
  loadMaritalStatuses,
  loadProfileDetails,
} from '@app/user-profile/store/actions';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { AddressAccountAssociation, CustomerDetail, SecurityQuestion } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BaseService {
  constructor(http: HttpClient, @Inject(olbSettings) settings: OlbSettings, private store: Store) {
    super(http, settings, 'profile');
  }

  /**
   * Gets the details of the user profile.
   *
   * @returns An Observable with all the user profile data.
   */
  getUserProfileDetails() {
    return this.get<CustomerDetail>('details').pipe(
      tap(({ data }) => {
        this.store.dispatch(loadProfileDetails({ payload: data }));
      })
    );
  }

  /**
   * Changes the user's employment information.
   *
   * @param info Updated user's employment information.
   *
   * @returns An Observable containing the result of the operation.
   */
  updateEmploymentInfo(info: Partial<EmploymentInfo>) {
    return this.post<null>('employmentInfo', info);
  }

  /**
   * Checks the CIF date of the user to know if it has been created recently.
   *
   * @returns An Observable containing the result of the opertaion.
   */
  checkCifDate() {
    return this.get<boolean>('cifdate').pipe(
      map(({ data }) => data),
      tap(data => {
        this.store.dispatch(loadIsNewUser({ isNewUser: !data }));
      })
    );
  }

  /**
   * Checks if the phones can be updated directly or if they need an additional verification step.
   *
   * @param request Update phone numbers information.
   *
   * @returns An Observable containing the status of the API call
   */
  checkUpdatePhones(request: Partial<UpdatePhonesRequest>) {
    return this.post<UpdatePhone>('phones/update/check', request);
  }

  /**
   * Requests an OTP to the primary phone (CellPhone1) in the request.
   *
   * @param request Update phone numbers information.
   *
   * @returns An Observable containing the OTP code in lower environments.
   */
  requestOtpToUpdatePhones(request: Partial<UpdatePhonesRequest>) {
    return this.post<string>('phones/update/resend', request);
  }

  /**
   * Verifies that the primary phone (CellPhone1) is using OTP service and then updates it.
   *
   * @param request Update phone numbers information.
   *
   * @returns An Observable containing the result of the operation.
   */
  verifyUpdatePhones(request: Partial<UpdatePhonesRequest>) {
    return this.post<null>('phones/update/verify', request);
  }

  /**
   * Updates the user's email addresses.
   *
   * @param request Updated email addresses.
   *
   * @returns An Observable containing a string with the response from the serve.
   */
  updateEmailAddresses(request: UpdateEmailsRequest) {
    return this.post<string>('emails/update', request);
  }

  /**
   * Updates user's addresses.
   *
   * @param addresses An array of addresses.
   *
   * @returns An Observable containing the result of the operation..
   */
  updateAddresses(addresses: AddressAccountAssociation[]) {
    return this.put<null>('address/update', addresses);
  }

  /**
   * Updates the user's family information.
   *
   * @param request Updated family info.
   *
   * @returns An Observable containing the result of the operation.
   */
  updateFamilyInfo(request: FamilyInfo) {
    return this.post<null>('familyInfo', request);
  }

  /**
   * Updates the user's financial information.
   *
   * @param request Updated financial info.
   *
   * @returns An Observable containing the result of the operation.
   */
  updateFinancialInfo(request: FinancialInfo) {
    return this.post<null>('financialInfo', request);
  }

  /**
   * Updates the user's secret word information.
   *
   * @param request Updated secret word info.
   *
   * @returns An Observable containing the result of the operation.
   */
  updateSecretWordInfo(request: SecretWordInfo) {
    return this.post<null>('userword', request);
  }

  /**
   * Gets a catalog of the possible annual incomes.
   *
   * @returns An Observable containing an array of NameValue pairs.
   */
  getPossibleAnnualIncomes() {
    return this.get<Record<string, string>>('catalogs/financial/annualIncome').pipe(map(response => response.data));
  }

  /**
   * Gets a catalog of the possible total net worths.
   *
   * @returns An Observable containing an array of NameValue pairs.
   */
  getPossibleTotalNetWorths() {
    return this.get<Record<string, string>>('catalogs/financial/totalNetWorths').pipe(map(response => response.data));
  }

  /**
   * Gets a catalog of the possible liquit net worths.
   *
   * @returns An Observable containing an array of NameValue pairs.
   */
  getPossibleLiquidNetWorths() {
    return this.get<Record<string, string>>('catalogs/financial/liquidNetWorths').pipe(map(response => response.data));
  }

  /**
   * Gets a catalog of the possible tax brackets.
   *
   * @returns An Observable containing an array of NameValue pairs.
   */
  getPossibleTaxBrackets() {
    return this.get<Record<string, string>>('catalogs/financial/taxBrackets').pipe(map(response => response.data));
  }

  getFinancialCatalogs() {
    return forkJoin([
      this.getPossibleAnnualIncomes(),
      this.getPossibleTotalNetWorths(),
      this.getPossibleLiquidNetWorths(),
      this.getPossibleTaxBrackets(),
    ]).pipe(
      map(([annualIncomes, totalNetWorths, liquidNetWorths, taxBrackets]) => {
        return new FinancialCatalogsState(annualIncomes, totalNetWorths, liquidNetWorths, taxBrackets);
      }),
      tap((payload: FinancialCatalogsState) => {
        this.store.dispatch(loadFinancialCatalogs({ payload }));
      })
    );
  }

  /**
   * Gets a catalog of the possible marital statuses.
   *
   * @returns An Observable containing an array of NameValue pairs.
   */
  getPossibleMaritalStatuses() {
    return this.get<Record<string, string>>('catalogs/family/maritalStatus').pipe(
      map(response => response.data),
      tap(payload => {
        this.store.dispatch(loadMaritalStatuses({ payload }));
      })
    );
  }

  /**
   * Gets the possible employment statuses.
   *
   * @returns An Observable containing key-value pairs with the employment statuses.
   */
  getPossibleEmploymentStatuses() {
    return this.get<Record<string, string>>('catalogs/employment-status').pipe(
      map(response => response.data),
      tap(payload => {
        this.store.dispatch(loadEmploymentStasuses({ payload }));
      })
    );
  }

  /**
   * Changes the profile icon.
   *
   * @param value Icon ID.
   *
   * @returns An Observable containing the result of the operation.
   */
  changeBackgroundColor(value: string) {
    return this.put<null>('preferences/ProfileImageColor', `'${value}'`, {
      'Content-Type': 'application/json',
    });
  }

  /**
   * Changes the background colour for the profile icon.
   *
   * @param value HEX code of the new colour.
   *
   * @returns An Observable containing the result of the operation.
   */
  changeBackgroundIcon(value: string) {
    return this.put<null>('preferences/ProfileImageIcon', `'${value}'`, {
      'Content-Type': 'application/json',
    });
  }
  /**
   *
   * @param request Request to change the password.
   *
   * @returns An Observable containing the result of the operation.
   */
  changePassword(request: ChangePasswordRequest) {
    const information = {
      oldPassword: request.currentpassword,
      password: request.password,
    };

    return this.post<null>('password/change', information);
  }

  /**
   * Gets the user's set security questions.
   *
   * @returns An Observable containing the user's security questions in CA.
   */
  getUserSecurityQuestions() {
    return this.get<string[]>('questions');
  }

  /**
   * Updates security questions and answers for the current user.
   *
   * @param questions A list of security questions and answers.
   *
   * @returns An Observable containing the result of the operation.
   */
  saveSecurityQuestions(questions: SecurityQuestion[]) {
    return this.post<null>('questions', questions);
  }

  getUserProfileInfo() {
    return this.get<UserProfileInfo>('details');
  }

  getOccuppactionList() {
    return this.get<string[]>('occupations');
  }

  getHasAxosAccounts() {
    return this.get<boolean>('hasNoAxosAccounts');
  }
}
