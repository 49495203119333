export enum SupportIcons {
  Accounts = 'support-accounts',
  Alerts = 'support-alerts',
  BillPay = 'support-bill-pay',
  Dashboard = 'support-dashboard',
  Deposits = 'support-deposits',
  Loans = 'support-loans',
  PayItNow = 'support-pay-it-now',
  Other = 'support-other',
  Transfers = 'support-transfers',
  SecureForms = 'support-secure-forms',
  SelfDirectedTrading = 'self-directed-trading',
}
