import { Inject } from '../../decorators/decorators';
import { TransferFundsController } from './transfer-funds.controller';
import { RestrictionType } from '../../common/enums/enums';

@Inject('$stateProvider')
export class TransferFundsConfig {
  constructor($stateProvider: ng.ui.IStateProvider) {
    $stateProvider
      .state('udb.transfers.transferFunds', {
        url: '/TransferFunds/:id',
        views: {
          'transfer-content': {
            templateUrl: 'transfers/transfer-funds/transfer-funds.tpl.html',
            controller: TransferFundsController,
            controllerAs: '$ctrl',
          },
        },
        resolve: {
          isRestricted: [
            'authHelper',
            (authHelper: IAuthHelper): any => {
              return authHelper.hasCustomerServiceRestriction(RestrictionType.InternalTransfer);
            },
          ],
        },
        params: {
          fromAccountId: null,
          toAccountId: null,
          isMoveMoneyFlow: false,
        },
      })
      .state('udb.transfers.loanPayment', {
        url: '/LoanPayment',
        views: {
          'transfer-content': {
            template: `<div ui-view="payment-content"><app-loan-payment></app-loan-payment></div>`,
          },
        },
        params: {
          fromAccountId: null,
          toAccountId: null,
          isPayoff: false,
          tooltip: null,
          payoffCalculated: null,
        },
      })
      .state('udb.transfers.loanPayment.review', {
        url: '/Review',
        views: {
          'payment-content': {
            template: `<app-loan-review-and-submit></app-loan-review-and-submit>`,
          },
        },
        params: {
          loanPaymentData: null,
          isPayoff: false,
          payoffCalculated: null,
        },
      })
      .state('udb.transfers.loanPayment.confirmation', {
        url: '/Confirmation',
        views: {
          'payment-content': {
            template: `<app-loan-confirmation-page></app-loan-confirmation-page>`,
          },
        },
        params: {
          paymentData: [],
          isOneTime: false,
        },
      });
  }
}
