<div class="recategorize">
    <div>Select Category:</div>
    <app-dot-loader [showWhen]="isLoading">
        Loading
    </app-dot-loader>

    <div [hidden]="isLoading">
        <app-category-element
                *ngFor="let category of categories; trackBy: categoryTrackBy"
                [category]="category"
                (click)="markAsActive(category.name)"
        ></app-category-element>

        <div class="recategorize-switch">
            <label class="uk-switch">
                <input type="checkbox" [(ngModel)]="everySimilarTransaction" [checked]="everySimilarTransaction" />
                <span class="slider">
          <i class="slider--icon" [ngClass]="everySimilarTransaction ? 'bofi-check-mark' : 'bofi-close'"></i>
        </span>
            </label>
            <span class="rulemessage">Update this and all future transactions like this one</span>
        </div>
        <div class="recategorize-actions">
            <button class="uk-btn sm solid secondary buttonActions" (click)="saveAction()">
                {{ isLoading ? 'Saving...' : 'Save' }}
            </button>
        </div>
    </div>
</div>
