<app-view-header class="mb-3" [titleText]="header" flexDirection="column-reverse">
  <app-svg-icon
    [iconName]="iconConfig.check.icon"
    [size]="iconConfig.check.size"
    [iconColor]="iconConfig.check.fill"
  ></app-svg-icon>
</app-view-header>

<div class="view-container flex-wrap pt-3 row justify-content-center">
  <div class="col-10 col-md-8">
    <app-dot-loader id="mail-loader" *ngIf="isLoading; else confirmationContent">
      Loading...
    </app-dot-loader>
  </div>
</div>

<ng-template #confirmationContent>
  <h3>{{ message }}</h3>

  <p class="mt-3 mb-5">
    A confirmation email has been sent to
    <b [innerText]="email"></b>
  </p>

  <app-dot-loader id="buttons-loader" *ngIf="isLoadingCards; else actionButtons">
    Loading...
  </app-dot-loader>
</ng-template>

<ng-template #actionButtons>
  <div class="button-box">
    <a href="#" type="button" class="btn-ok" (click)="goToDebitCards()">
      Go To Debit Cards
    </a>
    <a href="#" class="btn btn-link" (click)="goToDashboard()">
      Go To My Accounts
    </a>
  </div>
</ng-template>
