import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { securityAnswerRegex } from '@app/config/regexes';
import { UserProfileService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'up-change-security-word',
  templateUrl: './change-security-word.component.html',
  styleUrls: ['./change-security-word.component.scss'],
})
export class ChangeSecurityWordComponent implements OnInit {
  @Output() updateSecretInfo = new EventEmitter<null>();
  @Output() back = new EventEmitter<null>();

  secretWordForm: UntypedFormGroup;
  isBusy = false;
  errorMessage: string = null;

  constructor(
    private fb: UntypedFormBuilder,
    private userProfileService: UserProfileService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.secretWordForm = this.fb.group({
      secretWord: this.fb.control('', [
        Validators.required,
        Validators.minLength(4),
        Validators.pattern(securityAnswerRegex),
      ]),
      secretHint: this.fb.control('', [
        Validators.required,
        Validators.minLength(4),
        Validators.pattern(securityAnswerRegex),
      ]),
    });
  }

  changeSecretWord(): void {
    const secretWord = this.secretWordForm.get('secretWord').value;
    const secretWordHint = this.secretWordForm.get('secretHint').value;

    if (this.secretWordForm.invalid) {
      this.secretWordForm.markAllAsTouched();
    } else {
      this.isBusy = true;

      this.userProfileService
        .updateSecretWordInfo({ secretWord, secretWordHint })
        .pipe(
          finalize(() => {
            this.isBusy = false;
          })
        )
        .subscribe({
          next: () => {
            this.updateSecretInfo.emit();
            this.back.emit();
          },
          error: res => {
            this.errorMessage = res.error.message;
            this.serviceHelper.errorHandler.bind(this.serviceHelper);
          },
        });
    }
  }
}
