import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  addUpdateAvailableCashAccountAction,
  AvailableCashService,
  AvailableCashState,
  AvailableCashTileState,
  getSelectedAccountAvailableCashTile,
} from '../core';
import { InitializeAvailableCashTileInput } from './types';

@Injectable({
  providedIn: 'root',
})
export class AvailableCashFacade {
  availableCashTileState$ = this.store.select(getSelectedAccountAvailableCashTile);

  constructor(private store: Store<AvailableCashState>, private availableCashService: AvailableCashService) {}

  initializeAvailableCashTile(input: InitializeAvailableCashTileInput): void {
    const payload: AvailableCashTileState = {
      accountNumber: input.accountNumber,
      availableCash: undefined,
      accountTypeCode: input.accountTypeCode,
      dateCloseInitiated: input.dateCloseInitiated,
      error: undefined,
    };

    this.availableCashService.getAvailableCash(input).subscribe({
      next: serviceResult => {
        payload.availableCash = serviceResult.data?.availableCash;
        this.store.dispatch(addUpdateAvailableCashAccountAction({ payload }));
      },
      error: error => {
        payload.error = error;
        this.store.dispatch(addUpdateAvailableCashAccountAction({ payload }));
      },
    });
  }
}
