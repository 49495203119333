import { HttpErrorResponse } from '@angular/common/http';

import * as jsCookie from 'js-cookie';
import * as moment from 'moment';

import { LogService } from '@core/services';

import { Inject } from '../decorators/decorators';
import { BaseService } from '../services/base.service';
import { ModalService, ModalSettings } from '../services/modal.service';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';

@Inject(
  '$anchorScroll',
  '$window',
  'modalService',
  'env',
  'popups',
  'cookieHelper',
  'logService'
)
class ServiceHelper implements IServiceHelper {
  constructor(
    private anchorScroll: ng.IAnchorScrollService,
    private $window: ng.IWindowService,
    private modalService: ModalService,
    private env: OlbSettings,
    private popups: IPopups,
    private cookieHelper: CookieHelper,
    private logService: LogService
  ) {
    this.errorHandler = this.errorHandler.bind(this);
    this.serviceError = this.serviceError.bind(this);
  }

  serviceError(error: ApiError): void {
    if (error.status === 401 && this.isUserAuthenticated()) {
      this.signOut();

      return;
    }

    throw error;
  }

  errorHandler(
    err: ApiError | HttpErrorResponse,
    showPopup = true,
    callback: Function = null
  ): string {
    let defaultMessage: string;

    switch (err.status) {
      // Angular puts a status code -1 for connection refused.
      case -1:
        defaultMessage = this.connectionRefused(showPopup);
        break;
      // Angular puts a status code 0 for timeouts.
      case 0:
        defaultMessage = this.timeout(showPopup);
        break;
      case 400:
        defaultMessage = this.badRequest(err, showPopup);
        break;
      case 401:
      case 403:
        if (!this.cookieHelper.tokenExists()) {
          this.signOut();
        }
        break;
      case 404:
        defaultMessage = this.notFound(err, showPopup);
        break;
      case 409:
        defaultMessage = this.conflict(err, showPopup);
        break;
      case 500:
      case 502:
        if (this.cookieHelper.getUserCIF() === undefined) {
          showPopup = false;
        }
        defaultMessage = this.internalServerError(showPopup);
        break;
      case 503:
        if (showPopup) {
          const message =
            err instanceof HttpErrorResponse
              ? err.error.message
              : err.data.message;
          this.popups.showAlert('Error', message, 'error', callback);
        }
        break;
      default:
        defaultMessage =
          'Something unexpected went wrong on our end. Please try again. Thank you.';
        if (showPopup) {
          this.popups.showAlert('Error', defaultMessage, 'error', callback);
        }
        break;
    }

    let response;

    if (err instanceof HttpErrorResponse) {
      response = err.error?.message;
    } else {
      response = err.data?.data ?? err.data;
    }

    return response ? response.message : defaultMessage;
  }
  fastLinkerrorHandler(
    err,
    showPopup = true,
    callback: Function = null
  ): string {
    let defaultMessage: string;

    switch (err.additionalStatus) {
      case 'ACCOUNT_LOCKED':
      case 'INCORRECT_CREDENTIALS':
      case 'INVALID_ADDL_INFO_PROVIDED':
      case 'USER_ACTION_NEEDED_AT_SITE':
      case 'ADDL_AUTHENTICATION_REQUIRED':
      case 'CREDENTIALS_UPDATE_NEEDED':
      case 'DATA_NOT_AVAILABLE':
      case 'SITE_NOT_SUPPORTED':
      case 'NO_DATA_RETURNED':
      case 'TECH_ERROR':
      case 'DATA_RETRIEVAL_FAILED':
      case 'SITE_UNAVAILABLE':
      case 'SITE_BLOCKING_ERROR':
      case 'SITE_SESSION_INVALIDATED':
      case 'REQUEST_TIMEOUT':
      case 'NO_ACCOUNTS_FOR_VERIFICATION':
      case 'NO_ACCOUNTS_FOR_AGGREGATION':
        return;

      default:
        defaultMessage =
          'We are experiencing an issue connecting to your financial institution and could not process the request at this time. Please verify your information and try again later.';
        if (showPopup) {
          this.popups.showAlert('Error', defaultMessage, 'error', callback);
        }
        break;
    }

    let response;

    if (err instanceof HttpErrorResponse) {
      response = err.error?.message;
    } else {
      response = err.data?.data ?? err.data;
    }

    return response ? response.message : defaultMessage;
  }

  scrollToTop(): void {
    this.anchorScroll();
  }

  scrollTo(hash: string): void {
    this.anchorScroll(hash);
  }

  signOut(): void {
    this.logWebInformation('SignOut');
    BaseService.pendingRequests.forEach(request => {
      if (request.timeout.reject) {
        request.timeout.reject();
      }
    });
    BaseService.pendingRequests = [];
    jsCookie.remove('XSRF-TOKEN', { domain: this.env.antiforgeryCookieDomain });
    sessionStorage.clear();
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastLogin');
    this.$window.location.href = `${this.$window.location.protocol}//${this.$window.location.host}/auth/Login`;
  }

  isUserAuthenticated(): boolean {
    return this.cookieHelper.tokenExists();
  }

  logWebInformation(message: string): void {
    const userId = this.cookieHelper.getUserId();
    const username = this.cookieHelper.getUserUsername();
    const nbf = moment.unix(this.cookieHelper.getAuthTime()).utc().format();
    const expiration = moment(this.cookieHelper.getTokenExpirationDate())
      .utc()
      .format();

    const jsonData = { userId, username, nbf, expiration };

    this.logService.logWebInformation(message, jsonData);
  }

  /**
   * Handles a refused request.
   */
  private connectionRefused(
    showPopup: boolean,
    callback: Function = null
  ): string {
    const defaultMessage =
      'It appears that our servers are not responding. Please try again later.';
    if (showPopup) {
      this.popups.showAlert('Whoops!', defaultMessage, 'error', callback);
    }

    return defaultMessage;
  }

  /**
   * Handles a timedout request.
   */
  private timeout(showPopup: boolean, callback: Function = null): string {
    const defaultMessage = 'The operation has timed out, please try again.';
    if (showPopup) {
      this.popups.showAlert('Timeout', defaultMessage, 'error', callback);
    }

    return defaultMessage;
  }

  /**
   * Handles a Bad Request (400).
   * @param err The server's response object with details of the request.
   */
  private badRequest(
    err: ApiError | HttpErrorResponse,
    showPopup: boolean,
    callback: Function = null
  ): string {
    const defaultMessage =
      'Your request cannot be processed, please check it and try again later.';
    const message =
      err instanceof HttpErrorResponse ? err.error.message : err.data.message;
    if (showPopup) {
      this.popups.showAlert(null, message || defaultMessage, 'error', callback);
    }
    return defaultMessage;
  }

  /**
   * Handles a Not Found (404) request.
   * @param {ApiResponse} err The server's response object with details of the request.
   */
  private notFound(
    err: ApiError | HttpErrorResponse,
    showPopup: boolean,
    callback: Function = null
  ): string {
    const defaultMessage = 'The resource you requested was not found.';
    const message =
      err instanceof HttpErrorResponse ? err.error.message : err.data.message;
    if (message && message.indexOf('layout') > -1) return '';
    if (showPopup) {
      this.popups.showAlert(
        'Not found',
        message || defaultMessage,
        'error',
        callback
      );
    }

    return defaultMessage;
  }

  /**
   * Handles a Conflict with the request (409).
   * @param {ApiResponse} err The server's response object with details of the request.
   */
  private conflict(
    err: ApiError | HttpErrorResponse,
    showPopup: boolean
  ): string {
    const defaultMessage = 'We could not authenticate you. Please log back in.';
    const message =
      err instanceof HttpErrorResponse ? err.error.message : err.data.message;
    if (showPopup) {
      this.popups.showAlert(
        'Authentication error',
        message || defaultMessage,
        'error',
        this.signOut
      );
    }

    return defaultMessage;
  }

  /**
   * Handles an Internal Server Error (500).
   * @param {ApiResponse} err The server's response object with details of the request.
   */
  private internalServerError(showPopup: boolean): string {
    const defaultMessage = showPopup
      ? `<div><img src="/img/icons/illustration-area.svg" /></div>
      It seems like we've stumbled upon a little glitch. But we’re on it! We apologize for the inconvenience. Please try again shortly.`
      : 'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
    const modalSettings: ModalSettings = {
      headerText: 'Oops! A Tiny Hiccup.',
      bodyText: defaultMessage,
      okText: 'Okay',
      hasCancelButton: false,
      hasIcon: false,
      hasHeaderText: true,
      okButtonClass: 'new-button-okay',
    };
    if (showPopup) {
      this.modalService
        .show(
          { windowClass: 'modal-service internal-error-modal' },
          modalSettings
        )
        .then(() => {})
        .catch(() => {
          return defaultMessage;
        });
    }

    return defaultMessage;
  }
}

export { ServiceHelper };
