import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';
import { accountsService } from '@app/Areas/AAS/aas-shared/services/accounts.service';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { RIAContactsService } from '@app/Areas/AAS/aas-shared/services/contacts-service';
import { InvestorCheckingEnrollmentModalComponent } from '@app/accounts/components/modals/investor-checking-enrollment-modal/investor-checking-enrollment-modal.component';
import { AgreementsTypeResponse } from '@core/enums/agreements-type-response.enum';
import { DialogService } from '@core/services';
import { STATE, ROOT_SCOPE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { AnimationOptions } from 'ngx-lottie';
import { Subject, combineLatest } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { BrandingSettingsFacade } from '@app/Areas/AAS/aas-core/branding-settings';
import { Store } from '@ngrx/store';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { GetSetRiaWelcomePageRequestType } from '@app/Areas/AAS/aas-shared/services/contacts-service/types';
import { RiaType } from '@app/Areas/AAS/aas-core/rias';
import { InvestorCheckingEnrollmentModalComponentV2 } from '@app/accounts/components/modals';

@Inject('$window')
@Component({
  selector: 'app-interestitial-page',
  templateUrl: './intial-page.component.html',
  styleUrls: ['./initial-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterestitialPageComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Working Together for You';
  text =
    "Through your advisor's relationship with Axos, you can access your investment accounts and enjoy the benefits of premium digital banking all in one secure portal. Make faster financial moves with a unified team supporting you.";
  logo: string;
  brandLogoComponent: HTMLDivElement;
  options: AnimationOptions = {
    path: '/assets/animations/welcome.json',
    loop: 0,
  };
  isUsingCustomBranding = true;
  udbUserId: number;
  welcomePageToShow: RiaType[] = [];
  private destroy$ = new Subject<void>();

  isRiaPilotOdlActive: boolean;
  constructor(
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private accountsService: accountsService,
    private agreementService: AgreementService,
    private dialogService: DialogService,
    private riaContactsService: RIAContactsService,
    private featureFlagService: FeatureFlagService,
    private brandingSettingsFacade: BrandingSettingsFacade,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.isRiaPilotOdlActive = this.featureFlagService.isRiaPilotODLActive();
    this.welcomePageToShow = this.determineAllWelcomePageToShow(this.state.params.organizationData);
    if (this.welcomePageToShow[0].useCustomBranding) {
      // Case all welcomePageToShow have logo
      this.welcomePageShowed(this.welcomePageToShow[0].riaId);
      this.getBrandingValues(this.welcomePageToShow[0].name);
    } else {
      this.isUsingCustomBranding = false;
      // Case where existing at least welcomePageToShow without logo, need to create WP showed in DB
      this.state.params.organizationData.forEach(organizationData => {
        this.welcomePageShowed(organizationData.riaId);
      });
    }
    localStorage.setItem('onboardingStatus', 'true');
  }

  ngAfterViewInit() {
    localStorage.setItem('organizationLogo', `${this.logo}`);
    sessionStorage.setItem('viewedWelcomePage', 'true');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClick() {
    if (this.welcomePageToShow.length > 1) {
      this.welcomePageToShow.shift();
      this.welcomePageShowed(this.welcomePageToShow[0].riaId);
      this.getBrandingValues(this.welcomePageToShow[0].name);
    } else {
      combineLatest([
        this.accountsService.hasAxosInvestorCheckingAccounts(),
        this.store.select(getAxosAdvisoryAccounts),
      ])
        .pipe(
          takeUntil(this.destroy$),
          filter(
            ([hasAxosInvestorCheckingAccountsResponse, aasAccounts]) =>
              hasAxosInvestorCheckingAccountsResponse != null && aasAccounts != null
          ),
          first()
        )
        .subscribe(([hasAxosInvestorCheckingAccountsResponse, aasAccounts]) => {
          if (hasAxosInvestorCheckingAccountsResponse.data.hasInvestorCheckingAccount) {
            this.rootScope['welcomePageDisplay'] = false;
            this.rootScope.$broadcast('welcomePageDisplay');
            this.state.go('udb.dashboard');
          } else {
            this.agreementService
              .validateAgreements(AasAgreementType.MultiAccountAgreementInvestorChecking)
              .subscribe(agreementsValidationResponse => {
                this.rootScope['welcomePageDisplay'] = false;
                this.rootScope.$broadcast('welcomePageDisplay');
                switch (agreementsValidationResponse.data.agreementResult) {
                  case AgreementsTypeResponse.Refused:
                    this.state.go('udb.dashboard');
                    break;
                  case AgreementsTypeResponse.Accepted:
                    if (this.isRiaPilotOdlActive) {
                      if (this.featureFlagService.isRiaPilotICODLDebitCardActive()) {
                        this.dialogService.openByComponentRef(InvestorCheckingEnrollmentModalComponentV2, {
                          disableClose: true,
                        });
                      } else {
                        this.dialogService.openByComponentRef(InvestorCheckingEnrollmentModalComponent, {
                          disableClose: true,
                        });
                      }
                    } else {
                      this.state.go('udb.dashboard');
                    }
                    break;
                  case AgreementsTypeResponse.AgreementNotFound:
                    if (aasAccounts.length > 0) {
                      // Only show cross sell page if user has at least 1 active ria with at least 1 active account
                      this.state.go('udb.InvestorChecking');
                    } else {
                      this.state.go('udb.dashboard');
                    }
                    break;
                }
              });
          }
        });
    }
  }

  determineAllWelcomePageToShow(rias: RiaType[]) {
    var welcomePageToShow: RiaType[] = [];
    for (var ria of rias) {
      if (!ria.useCustomBranding) {
        // There is riaOrganizationActive without logo, so we will just show one WP, redirect and break stop logic here
        welcomePageToShow = [
          {
            riaId: null,
            name: '',
            udbUserId: 0,
            clientPortalStatus: 0,
            useCustomBranding: false,
            useCustomSecondaryLogo: false,
          },
        ];
        this.isUsingCustomBranding = false;
        break;
      } else {
        // Or we add it to riaOrganizationActive list with logo
        welcomePageToShow.push(ria);
      }
    }
    return welcomePageToShow;
  }

  welcomePageShowed(riaId: string) {
    var request: GetSetRiaWelcomePageRequestType = {
      riaId: riaId,
    };
    this.riaContactsService.setRiaWelcomePage(request).subscribe();
  }

  getBrandingValues(brandName: string) {
    this.brandingSettingsFacade.allBrandingSettings$
      .pipe(
        takeUntil(this.destroy$),
        filter(brandings => !!brandings)
      )
      .subscribe(brandings => {
        this.logo = '';
        const brand = brandings?.find(brand => brand.name === brandName);
        const brandLogoText = 'brandLogo';
        const brandLogo = brand?.settings.find(settings => settings.name === brandLogoText);
        const regexValue = /url\('([^']+)'\)/;
        if (brandLogo && brandLogo.value.includes('url(')) {
          this.logo = brandLogo.value.match(regexValue)[1];
        } else if (brandLogo) {
          this.logo = brandLogo.value;
        }
      });
  }
}
