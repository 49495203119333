import { Component, Input } from '@angular/core';

import { Transaction } from '@app/axos-trading/models';
import { MiscIcons } from '@shared/enums';

@Component({
  selector: 'tr[app-transaction-activity-row]',
  templateUrl: './transaction-activity-row.component.html',
  styleUrls: ['./transaction-activity-row.component.scss'],
})
export class TransactionActivityRowComponent {
  @Input() transaction: Transaction;
  iconName: string;
  icons = {
    other: MiscIcons.PuzzlePiece,
  };
}
