<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Pay It Now Payments How To's</h3>

  <ul class="how-to-list">
    <li>
      <button id="pay-to-individual" class="link" (click)="selectTopic(topics.PayToIndividual)">
        How do I make a payment to an individual?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-pay-to-individual *ngSwitchCase="topics.PayToIndividual"></how-to-pay-to-individual>
  </ng-container>
</ng-template>
