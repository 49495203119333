import { IStateService } from 'angular-ui-router';
import { SettingsService } from 'services/settings.service';
import { IAxosClearingService } from 'services/typings/IAxosClearingService';

import { Inject } from '../decorators/decorators';
import { FundingTermsController } from '../funding/funding-terms/funding-terms.controller';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { AuthController } from './auth.controller';

@Inject('$stateProvider', '$urlRouterProvider', '$compileProvider', '$qProvider', '$httpProvider', '$locationProvider')
export class AuthConfig {
  constructor(
    stateProvider: ng.ui.IStateProvider,
    urlRouterProvider: ng.ui.IUrlRouterProvider,
    compileProvider: ng.ICompileProvider,
    qProvider: any,
    http: ng.IHttpService,
    _location: ng.ILocationProvider
  ) {
    qProvider.errorOnUnhandledRejections(false);
    compileProvider.debugInfoEnabled(false);
    stateProvider
      .state('auth', {
        abstract: true,
        url: '/auth',
        views: {
          root: {
            templateUrl: 'auth/public.tpl.html',
          },
        },
        resolve: {
          isComingFromOrbis: [
            '$location',
            'axosClearingService',
            'serviceHelper',
            (
              location: ng.ILocationService,
              axosClearingService: IAxosClearingService,
              serviceHelper: IServiceHelper
            ): any => {
              const referrer = location.search().referrer;
              if (referrer === 'axos_trading') {
                const reference = location.search().referrer_token;
                axosClearingService
                  .handleRedirectFromOrbis(reference)
                  .then(() => {
                    window.location.href = '/';
                  })
                  .catch(() => {
                    event.preventDefault();
                    serviceHelper.signOut();
                  });
              }
            },
          ],
          isAuthenticated: [
            '$state',
            '$q',
            'cookieHelper',
            '$location',
            (
              state: IStateService,
              $q: ng.IQService,
              cookieHelper: CookieHelper,
              location: ng.ILocationService
            ): any => {
              if (cookieHelper.tokenExists() && sessionStorage.getItem('fundingTerms') !== 'enrollment') {
                const cleanParams = location.search().referrer ? false : true;
                setTimeout(() => {
                  state.go('udb.dashboard', {}, { location: cleanParams });
                }, 50);

                return $q.reject();
              } else {
                return $q.when();
              }
            },
          ],
        },
      })
      .state('auth.login', {
        url: '/Login', // auth/Login
        views: {
          content: {
            templateUrl: 'auth/login.tpl.html',
            controller: AuthController,
            controllerAs: 'vm',
          },
        },
        resolve: {
          featureFlagsPublic: [
            '$q',
            'settingsService',
            ($q: ng.IQService, settingsService: SettingsService): any => {
              const deferred = $q.defer();

              settingsService.getPublicFeatureFlags().subscribe(
                () => deferred.resolve(),
                () => deferred.resolve()
              );

              return deferred.promise;
            },
          ],
        },
      })
      .state('auth.terms', {
        url: '/TermsAndConditions',
        views: {
          content: {
            templateUrl: 'funding/funding-terms/funding-terms.tpl.html',
            controller: FundingTermsController,
            controllerAs: '$ctrl',
          },
        },
        resolve: {
          isFromEnrollment: [
            '$q',
            'cookieHelper',
            '$state',
            ($q: ng.IQService, cookieHelper: CookieHelper, $state: ng.ui.IStateService): any => {
              if (!cookieHelper.tokenExists()) {
                setTimeout(() => {
                  $state.go('auth.login');
                }, 50);

                return $q.reject();
              } else {
                return $q.when();
              }
            },
          ],
        },
      })
      .state('auth.forgotusername', {
        url: '/ForgotUsername',
        views: {
          content: {
            template: '<app-forgot-username></app-forgot-username>',
          },
        },
      })
      .state('auth.forgotpassword', {
        url: '/ForgotPassword',
        views: {
          content: {
            template: '<app-forgot-password></app-forgot-password>',
          },
        },
      });

    _location.html5Mode({
      enabled: true,
      requireBase: false,
    });

    urlRouterProvider.otherwise(($injector: any, _location: any) => {
      const $state = $injector.get('$state');
      $state.go('auth.login');
    });

    http.defaults.withCredentials = true;
  }
}
