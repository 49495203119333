import * as angular from 'angular';
import { SchedulerTransfersConfig } from './scheduler-transfers.config';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { TransactionsService } from '@app/Areas/AAS/features/transactions/core/services';
import { SchedulerTransfersComponent } from '@app/transfers/components';

export const SchedulerTransfers = angular
  .module('udb.SchedulerTransfers', [])
  .directive(
    'appSchedulerTransfers',
    downgradeComponent({
      component: SchedulerTransfersComponent,
    })
  )
  .service('TransactionsService', downgradeInjectable(TransactionsService))
  .config(SchedulerTransfersConfig).name;
