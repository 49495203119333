import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { SubSink } from '@axos/subsink';
import { Uk2ModalComponent, Uk2ModalTitleAlignEnum } from '@axos/uikit-v2-lib';

import { BrandingFacade } from '@app/Areas/AAS/features/branding';
import { STATE } from '@core/tokens';
import { GlanceCobrowseService } from '@legacy/services/cobrowse.service';

@Component({
  selector: 'app-new-v2-footer',
  templateUrl: './new-v2-footer.component.html',
  styleUrls: ['./new-v2-footer.component.scss'],
})
export class NewV2FooterComponent implements OnInit, OnDestroy {
  @Input() isLoggedUser: boolean;
  @ViewChild('urlRedirectModal') urlRedirectModal?: Uk2ModalComponent;
  @ViewChild('coBrowseModal') coBrowseModal?: Uk2ModalComponent;

  date: string | Date;
  otherUrl: string;
  titleAlign = Uk2ModalTitleAlignEnum.center;
  contentAlign = Uk2ModalTitleAlignEnum.center;
  confirmCoBrowseModalBodyText = `Your privacy is our priority. The agent will only be able to see this specific web page. They can't see any other open websites or anything on your computer.`;
  confirmCoBrowseModalBodyTitle = 'Show your Browser?';

  private subsink = new SubSink();

  constructor(
    @Inject(STATE) private $state: ng.ui.IStateService,
    private glanceCobrowseService: GlanceCobrowseService,
    private brandingFacade: BrandingFacade
  ) {}

  ngOnInit() {
    this.date = new Date();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  changeState(state: string) {
    this.$state.go(state);
  }

  openContinueToPageModal(url?: string) {
    this.urlRedirectModal.openDialog();
    this.brandingFacade.loadBranding('.uk2-custom-modal-white-label');
    this.otherUrl = url;
  }

  openCobrowseSession() {
    if (localStorage.getItem('glance_cobrowse_session')) return;
    this.coBrowseModal.openDialog();
    this.brandingFacade.loadBranding('.uk2-custom-modal-white-label');
  }

  startCoBrowse() {
    this.glanceCobrowseService.startSessionWithRandomKey();
    this.coBrowseModal.closeDialog();
  }

  openUrl() {
    window.open(this.otherUrl, '_blank');
    this.urlRedirectModal.closeDialog();
    this.otherUrl = '';
  }
}
