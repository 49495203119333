import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

import { AvailableCashState } from '../types';

const getAvailableCashState = createFeatureSelector<AvailableCashState>(AasStoreFeatures.AvailableCash);

export const getSelectedAccountAvailableCashTile = createSelector(getAvailableCashState, state => {
  const { accountsAvailableCash, selectedAccountAvailableCash } = state;

  if (selectedAccountAvailableCash === undefined) {
    return undefined;
  }

  const matchingAccount =
    accountsAvailableCash.find(account => account.accountNumber === selectedAccountAvailableCash) ?? undefined;

  return matchingAccount;
});
