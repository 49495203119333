<!-- First Name -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>First Name</mat-label>
  <input
    name="firstName"
    #inputFirstName
    matInput
    uk2Input
    type="text"
    [formControl]="firstName"
    maxlength="12"
    minlength="1"
  />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!firstName.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear first name"
    (mousedown)="clearFirstName()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="firstName.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ firstNameErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- Last Name -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Last Name</mat-label>
  <input
    name="lastName"
    #inputLastName
    matInput
    uk2Input
    type="text"
    [formControl]="lastName"
    maxlength="20"
    minlength="1"
  />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!lastName.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear last name"
    (mousedown)="clearLastName()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="lastName.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ lastNameErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- Suffix (optional) -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Suffix (optional)</mat-label>
  <mat-select name="suffix" uk2Dropdown [formControl]="suffix">
    <ng-container *ngFor="let option of suffixList">
      <mat-option [value]="option.value">{{ option.label }}</mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
<!-- Relation Ship -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Relationship</mat-label>
  <mat-select name="relationShip" uk2Dropdown [formControl]="relationShip">
    <ng-container *ngFor="let option of relationShipList">
      <mat-option [value]="option.value">{{ option.label }}</mat-option>
    </ng-container>
  </mat-select>
  <mat-error>Relationship is required</mat-error>
</mat-form-field>
<!-- Mobile Phone -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always" (click)="setCursorPhone()">
  <mat-label>Phone Number</mat-label>
  <input
    name="phoneNumber"
    #inputPhone
    matInput
    uk2Input
    [formControl]="phoneNumber"
    type="tel"
    placeholder="phoneMaskConfig.placeHolder"
    [mask]="phoneMaskConfig.inputMask"
    [placeHolderCharacter]="phoneMaskConfig.placeHolderCharacter"
    [showMaskTyped]="phoneMaskConfig.showMaskTyped"
    [dropSpecialCharacters]="phoneMaskConfig.dropSpecialCharacters"
    prefix="+1 ("
    (keydown)="setCursorPhone()"
    (keyup)="setCursorPhone()"
    (input)="handleSelectPhone($event)"
    (mousedown)="handleSelectPhone($event)"
  />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!phoneNumber.value && showClearFieldButton"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (mousedown)="clearPhoneNumber()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="phoneNumber.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ phoneErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- Email -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Email</mat-label>
  <input name="email" #inputEmail matInput uk2Input type="email" [formControl]="email" autocomplete="off" />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!email.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear Email"
    (mousedown)="clearEmail()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="email.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ emailErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
