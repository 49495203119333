<div class="stats-view-all">
  <div class="my-stats">Stats</div>
  <button
    *ngIf="!loadingStats && stats"
    type="button"
    class="btn-link view-all row no-underline"
    (click)="isExpanded = !isExpanded"
  >
    <div class="view-all view-all-des">View {{ isExpanded ? 'Fewer' : 'All' }}</div>
    <app-svg-icon
      (click)="isExpanded = !isExpanded"
      [iconName]="isExpanded ? icons.ChevronUp : icons.ChevronDown"
      [styles]="{ width: '14px' }"
      [iconColor]="'#2F5B88'"
    ></app-svg-icon>
  </button>
</div>
<app-dot-loader [showWhen]="loadingStats">Loading</app-dot-loader>
<div *ngIf="!loadingStats && stats">
  <div *ngIf="stats.type !== equityTypeEnum.MutualFund">
    <div class="header-row">
      <div class="column-header-content">
        <div>Open</div>
        <app-tooltip symbol="?" content="This is the price of this stock when trading last opened."></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>Previous Close</div>
        <app-tooltip symbol="?" content="This is the price of this stock when trading last closed."></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>Today's High</div>
        <app-tooltip
          symbol="?"
          content="This is the high price this stock attained during the last trading period."
        ></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>Today's Low</div>
        <app-tooltip
          symbol="?"
          content="This is the low price this stock attained during the last trading period."
        ></app-tooltip>
      </div>
    </div>
    <div class="stats-row">
      <div class="stats-row-content">
        {{ stats.open | currency: 'USD':'symbol':'2.2-2' }}
      </div>
      <div class="stats-row-content">
        {{ stats.previousClose | currency: 'USD':'symbol':'2.2-2' }}
      </div>
      <div class="stats-row-content">
        {{ stats.todayHigh | currency: 'USD':'symbol':'2.2-2' }}
      </div>
      <div class="stats-row-content">
        {{ stats.todayLow | currency: 'USD':'symbol':'2.2-2' }}
      </div>
    </div>
    <div class="header-row" *ngIf="isExpanded">
      <div class="column-header-content">
        <div>52 Wk High</div>
        <app-tooltip
          symbol="?"
          content="This is the highest price this stock attained during the past 52 weeks."
        ></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>52 Wk Low</div>
        <app-tooltip
          symbol="?"
          content="This is the lowest price this stock attained during the past 52 weeks."
        ></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>Volume</div>
        <app-tooltip
          symbol="?"
          content="This is the number of shares in the given stock traded during the most recent trading period."
        ></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>Average Volume</div>
        <app-tooltip
          symbol="?"
          content="This is the mean number of shares in the given stock traded over a specified period."
        ></app-tooltip>
      </div>
    </div>
    <div class="stats-row" *ngIf="isExpanded">
      <div class="stats-row-content">{{ stats.high52Week | currency: 'USD':'symbol':'1.2-2' }}</div>
      <div class="stats-row-content">{{ stats.low52Week | currency: 'USD':'symbol':'1.2-2' }}</div>
      <div class="stats-row-content">{{ stats.volume | number: '1.2-2' }}</div>
      <div class="stats-row-content">{{ stats.averageVolume30 | number: '1.2-2' }}</div>
    </div>
    <div class="header-row" *ngIf="isExpanded">
      <div class="column-header-content">
        <div>P/E Ratio</div>
        <app-tooltip
          symbol="?"
          content="The price-to-earnings ratio shows the current share price divided by the annual earnings per share."
        ></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>Market Cap</div>
        <app-tooltip
          symbol="?"
          content="The total value of all outstanding shares in the company based on the most recent price."
        ></app-tooltip>
      </div>
      <div class="column-header-content">
        <div>Div/ Yield</div>
        <app-tooltip
          symbol="?"
          content="The cash per share the company pays investors annually, divided by the current price per share."
        ></app-tooltip>
      </div>
    </div>
    <div class="stats-row" *ngIf="isExpanded">
      <div class="stats-row-content">{{ stats.priceEarningRatio | currency: 'USD':'symbol':'1.2-2' }}</div>
      <div class="stats-row-content">{{ stats.marketCap | currency: 'USD':'symbol':'1.2-2' }}</div>
      <div class="stats-row-content" *ngIf="stats.dividendYield > 0">
        {{ stats.dividendYield | currency: 'USD':'symbol':'2.2-2' }}
      </div>
      <div class="stats-row-content" *ngIf="stats.dividendYield == 0 && !stats.dividendYield">
        {{ 'N/A' }}
      </div>
    </div>
  </div>
  <div *ngIf="stats.type === equityTypeEnum.MutualFund">
    <div class="header-row">
      <div class="column-header-content">
        <div>Previous NAV</div>
      </div>
      <div class="column-header-content">
        <div>52 Week High</div>
      </div>
      <div class="column-header-content">
        <div>52 Week Low</div>
      </div>
      <div class="column-header-content">
        <div>Dividend Yield</div>
      </div>
    </div>
    <div class="stats-row">
      <div class="stats-row-content">
        {{ stats.previousClose | currency: 'USD':'symbol':'2.2-2' }}
      </div>
      <div class="stats-row-content">
        {{ stats.high52Week | currency: 'USD':'symbol':'1.2-2' }}
      </div>
      <div class="stats-row-content">
        {{ stats.low52Week | currency: 'USD':'symbol':'1.2-2' }}
      </div>
      <div class="stats-row-content" *ngIf="stats.dividendYield > 0">
        {{ stats.dividendYield | currency: 'USD':'symbol':'2.2-2' }}
      </div>
      <div class="stats-row-content" *ngIf="stats.dividendYield == 0 && !stats.dividendYield">
        {{ 'N/A' }}
      </div>
    </div>
  </div>
</div>
