import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  BILLPAYHELPER,
  BILLPAYROUTERHELPER,
  BILLPAYSERVICE,
  SERVICEHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { ROOT_SCOPE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { Captcha } from '@legacy/bill-pay/typings/Captcha';
import { OneTimePasscode } from '@legacy/bill-pay/typings/OneTimePasscode';
import { Recipient } from '@legacy/bill-pay/typings/Recipient';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { IRootScopeService } from 'angular';
import { Credential } from '@legacy/bill-pay/typings/Credential';
import { EBillLoginResponse } from '@legacy/bill-pay/typings/EBillLoginResponse';
import { VerifyEBillLogin } from '@legacy/bill-pay/typings/VerifyEBillLogin';

@Component({
  selector: 'app-e-statements-authentication',
  templateUrl: './e-statements-authentication.component.html',
  styleUrls: ['./e-statements-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class EStatementsAuthenticationComponent implements OnInit {
  recipient: Recipient;
  recipientId: number;
  sessionId: string;
  credentials: Credential[];
  errorMessage: string;
  captchaString: string;
  otpOptions: OneTimePasscode;

  isLoading: boolean;

  private verifyLoginInfo: VerifyEBillLogin = {};
  private verifyLoginResponse: EBillLoginResponse;
  captcha: Captcha;

  constructor(
    @Inject(BILLPAYSERVICE) private readonly _billPayService: IBillPayService,
    @Inject(SERVICEHELPER) private readonly _serviceHelper: IServiceHelper,
    @Inject(BILLPAYROUTERHELPER) private readonly _billPayRouterHelper: BillPayRouterHelper,
    @Inject(BILLPAYHELPER) private readonly _billPayHelper: BillPayHelper,
    @Inject(ROOT_SCOPE) private readonly _root: IRootScopeService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.isLoading = true;
    this.recipient = this._billPayRouterHelper.getRecipientDetails();
    this.sessionId = this._billPayRouterHelper.getSessionId();

    this.setCredentialsInfo();

    this._root.$on('credentialsUpdated', this.setCredentialsInfo.bind(this));
    this._serviceHelper.scrollToTop();
  }

  /**
   * Verifies if the credentials were provided correctly - Step 1
   */
  verifyEBillLogin(credentials: Credential[]): void {
    this.isLoading = true;
    this.verifyLoginInfo.credentials = credentials;
    this.verifyLoginInfo.sessionId = this.sessionId;
    this._billPayService
      .verifyEBillLogin(this.verifyLoginInfo)
      .then(response => {
        this.verifyLoginResponse = response.data;
        const timeout =
          new Date(this.verifyLoginResponse.callBackTime).getTime() -
          new Date(this.verifyLoginResponse.timestamp).getTime();
        setTimeout(() => {
          this._billPayHelper
            .checkForFurtherActions(this.verifyLoginResponse)
            .then(() => {
              this.isLoading = false;
              this.changeDetectorRef.markForCheck();
            })
            .catch(err => {
              this.errorMessage = !err.data && err.message ? err.message : err.data.message;
              this.isLoading = false;
              this.changeDetectorRef.markForCheck();
            });
        }, timeout);
      })
      .catch(err => {
        this.errorMessage = err.data.message;
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  goBack(): void {
    history.back();
  }

  cancelSetUp() {
    this._billPayRouterHelper.cancelSetup();
  }

  private setCredentialsInfo() {
    this.credentials = this._billPayRouterHelper.getCredentials();
    this.captcha = this._billPayRouterHelper.getCaptcha();
    if (this.captcha) {
      this.captchaString = `data:${this.captcha.mimeType};base64,${this.captcha.image}`;
    }
    this.otpOptions = this._billPayRouterHelper.getOtp();

    this.isLoading = false;
  }
}
