import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import {
  SharedAccountsByAdvisorRequest,
  SharedAccountsByAdvisorResponse,
  SharedAccountsRequest,
  SharedAccountsResponse,
} from '.';

@Injectable({
  providedIn: 'root',
})
export class SharedAccountsService extends BaseService {
  /**
   * SharedAccountsService constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */

  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/shared-accounts');
  }

  getSharedAccounts(): WebApiResponse<SharedAccountsResponse> {
    return this.get<SharedAccountsResponse>();
  }

  getSharedAccountsPrompting(): WebApiResponse<SharedAccountsResponse> {
    return this.get<SharedAccountsResponse>(`prompting`);
  }

  addSharedAccounts(sharedAccountsRequest: SharedAccountsRequest): WebApiResponse<SharedAccountsResponse> {
    return this.post<SharedAccountsResponse>(null, sharedAccountsRequest);
  }

  updateSharedAccounts(sharedAccountsRequest: SharedAccountsRequest): WebApiResponse<SharedAccountsResponse> {
    return this.put<SharedAccountsResponse>(null, sharedAccountsRequest);
  }

  getSharedAccountsByAdvisorId(userId: number, advisorId: number): WebApiResponse<SharedAccountsByAdvisorResponse> {
    return this.get<SharedAccountsByAdvisorResponse>(`${userId}/advisor/${advisorId}`);
  }

  addSharedAccountsByAdvisorId(
    sharedAccountsByAdvisorRequest: SharedAccountsByAdvisorRequest
  ): WebApiResponse<SharedAccountsByAdvisorResponse> {
    return this.post<SharedAccountsByAdvisorResponse>(`advisor`, sharedAccountsByAdvisorRequest);
  }

  updateSharedAccountsByAdvisorId(
    sharedAccountsByAdvisorRequest: SharedAccountsByAdvisorRequest
  ): WebApiResponse<SharedAccountsByAdvisorResponse> {
    return this.put<SharedAccountsByAdvisorResponse>(`advisor`, sharedAccountsByAdvisorRequest);
  }
}
