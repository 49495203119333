import * as angular from 'angular';
import { Inject } from '../../../../decorators/decorators';
import { Recipient } from 'bill-pay/typings/Recipient';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import * as moment from 'moment';
import { PaymentHistory } from 'bill-pay/typings/PaymentHistory';
import { ScheduledPayment } from 'bill-pay/typings/ScheduledPayment';
import { BillPayRouterHelper } from 'bill-pay/bill-pay-router.helper';

@Inject('$scope', 'dateRangePickerOptions', 'dateHelper', 'billPayRouterHelper')
export class RecipientInformationController {
  recipient: Recipient;
  paymentsHistory: PaymentHistory[];
  scheduledPayments: ScheduledPayment[];
  tabSelected: number;
  amount: number;
  datePickerOptions: any;
  amountOpt: number;
  processingPaymentLabel: string;
  processingDate: Date;
  selectedDate: moment.Moment;
  isFavorite: boolean;
  loadScheduledPayments: any;
  private listeners: Function[] = [];
  private readonly electronicRecipientLabel = 'Payment will be withdrawn on';
  private readonly checkRecipientLabel = 'Your check will be mailed on';
  constructor(
    private scope: ng.IScope,
    private readonly dateRangePickerOptions: any,
    private readonly dateHelper: DateHelper,
    private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}

  $onInit(): void {
    this.setDetaults();
    this.handleListeners();
    this.datePickerOptions = angular.extend(angular.copy(this.dateRangePickerOptions), {
      minDate: this.selectedDate,
      maxDate: this.selectedDate.clone().add(13, 'M'),
      singleDatePicker: true,
      eventHandlers: {
        'hide.daterangepicker': () => {
          this.deliverOnChange();
        },
      },
      isInvalidDate: (date: any) => {
        return !this.dateHelper.isValidDate(date);
      },
    });

    this.isFavorite = this.recipient.userTags && this.recipient.userTags.includes('Favorite');
  }

  $onDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  setDetaults() {
    this.amount = 0;
    // enable input for recipients that are not electronic and estatements enabled
    if (this.recipient.eBillsStatus !== 0) {
      this.amountOpt = 2;
    }

    this.processingPaymentLabel =
      this.recipient.paymentMethod == 1 ? this.electronicRecipientLabel : this.checkRecipientLabel;
    this.processingDate = this.recipient.nextAvailablePaymentProcessingDate;

    // validate if is holliday
    let nextAvailable = moment(this.recipient.nextAvailablePaymentDeliveryDate).clone();
    if (!this.dateHelper.isValidDate(nextAvailable)) {
      while (this.dateHelper.isValidDate(nextAvailable) === false) {
        nextAvailable = nextAvailable.add(1, 'day');
      }
    }
    this.selectedDate = nextAvailable;
    this.sendDashboardUpdate();
  }

  toggleTabs() {
    if (!this.isToggled()) {
      this.tabSelected = 1;
    } else {
      this.tabSelected = null;
    }
  }

  isToggled() {
    //Check is there was a recipient active
    if (this.isAnActiveRecipient()) {
      return true;
    } else {
      const temp = this.tabSelected && this.tabSelected !== undefined && this.tabSelected !== 0;
      return temp;
    }
  }

  /**
   * Checks in RouteHelper if there is an active recipient
   * Active recipients are set in scheduled payments only right now
   * this handles the cancel button returning to the selected recipient in Pay screen.
   */
  isAnActiveRecipient(): boolean {
    if (
      this.billPayRouterHelper.activeRecipient != null &&
      this.billPayRouterHelper.activeRecipient.payeeId == this.recipient.payeeId
    ) {
      //activate tab
      this.tabSelected = this.billPayRouterHelper.activeTab;
      //clean the recipient active at this point
      this.billPayRouterHelper.cleanActiveRecipient();
      return true;
    } else {
      return false;
    }
  }

  setAmmount(type: number) {
    this.amountOpt = type;
    switch (type) {
      case 0:
        this.amount = this.recipient.eBill.amountDue;
        break;
      case 1:
        this.amount = this.recipient.eBill.outstandingBalance;
        break;
      default:
        this.amount = 0;
    }

    this.sendDashboardUpdate();
  }

  deliverOnChange(): void {
    this.processingDate = this.dateHelper
      .getProcessingDate(moment(this.selectedDate), -this.recipient.businessDaysToDeliver)
      .toDate();
    this.sendDashboardUpdate();
  }

  amountChange() {
    this.sendDashboardUpdate();
  }

  sendDashboardUpdate() {
    this.scope.$emit('paymentUpdated', {
      recipient: this.recipient,
      amount: this.amount,
      selectedDate: this.selectedDate,
      processingDate: moment(this.processingDate),
      deliveryDate: this.selectedDate,
      payeeId: this.recipient.payeeId,
    });
  }

  private handleListeners(): void {
    this.listeners.push(this.scope.$on('paymentCancelled', this.onCancelPayment));
  }

  private onCancelPayment = (_e: ng.IAngularEvent): void => {
    this.setDetaults();
  };
}
