import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'popover-comparer',
})
export class PopoverComparerDirective extends UpgradeComponent {
  @Input() model: any;
  @Input() title: string;
  @Input() filterTypeName: string;
  @Input() elementType: string;
  @Input() elementDescription: string;
  @Input() placement: string;
  @Input() placeholder: string;
  @Input() minAllowed: string;
  @Input() maxAllowed: string;
  @Input() inputClasses: string;
  @Input() buttonClasses: string;
  @Input() clearText: string;
  @Input() activeBgColor: string;
  @Input() elementActive: boolean;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onOk: EventEmitter<any>;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCancel: EventEmitter<any>;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClear: EventEmitter<any>;
  constructor(elementRef: ElementRef, injector: Injector) {
    super('popoverComparer', elementRef, injector);
  }
}
