import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as jsCookie from 'js-cookie';

import { OlbSettings } from '@core/models';
import { ServiceResult, WebApiResponse } from '@shared/models';

type ValidHeaders = HttpHeaders | { [header: string]: string | string[] };

export abstract class BaseService {
  protected url: string;

  /**
   * Use the base service class to create your services.
   * @param http Angular HTTP service.
   * @param settings Application settings.
   * @param resource Prefix to use with the service. For example: 'auth';
   */
  constructor(protected readonly http: HttpClient, protected settings: OlbSettings, resource: string = null) {
    // Use this property to populate your API base URL
    this.url = resource ? `${settings.api}/${resource}` : `${settings.api}`;
  }

  /**
   * Triggers a HTTP GET to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to GET from. Defaults to empty string.
   * @param [params] Any data to be sent to the server. Keys must match query parameter name.
   * @param [headers=null] Additional headers to pass to the request.
   * @returns An observable in the format of ApiResponse<T>.
   */
  protected get<T>(
    endpoint: string = '',
    params?: HttpParams | { [param: string]: string | string[] },
    headers: ValidHeaders = null
  ): WebApiResponse<T> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.get<ServiceResult<T>>(requestUrl, { params, headers, withCredentials: true });
  }

  /**
   * Triggers a HTTP GET to the specified URL and query parameters.
   * @param url URL of the service to call.
   * @param [params] Any data to be sent to the server. Keys must match query parameter name.
   * @param [headers=null] Additional headers to pass to the request.
   * @returns An observable in the format of ApiResponse<T>.
   */
  protected getExternal<T>(
    url: string,
    params?: HttpParams | { [param: string]: string | string[] },
    headers: ValidHeaders = null
  ): Observable<T> {
    return this.http.get<T>(url, { params, headers });
  }

  /**
   * Triggers a HTTP POST to the server with the specified endpoint and data.
   * @param [endpoint=''] The endpoint to POST to. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @returns An observable in the format of ApiResponse<T>.
   */
  protected post<T>(
    endpoint: string = '',
    data: any = null,
    headers: ValidHeaders = null,
    params: HttpParams = null
  ): WebApiResponse<T> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.post<ServiceResult<T>>(requestUrl, data, {
      headers,
      withCredentials: true,
      params,
    });
  }

  /**
   * Triggers a HTTP PUT to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to PUT at. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @returns An observable in the format of ApiResponse<T>.
   */
  protected put<T>(endpoint: string = '', data: any = null, headers: ValidHeaders = null): WebApiResponse<T> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.put<ServiceResult<T>>(requestUrl, data, { headers, withCredentials: true });
  }

  /**
   * Triggers a HTTP PATCH to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to PATCH at. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @returns An observable in the format of ApiResponse<T>.
   */
  protected patch<T>(endpoint: string = '', data: any = null, headers: ValidHeaders = null): WebApiResponse<T> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.patch<ServiceResult<T>>(requestUrl, data, { headers, withCredentials: true });
  }

  /**
   * Triggers a HTTP DELETE to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to DELETE from. Defaults to empty string.
   * @param [params] Any data to be sent to the server. Keys must match query parameter name.
   * @param [headers=null] Additional headers to pass to the request.
   * @returns An observable in the format of ApiResponse<T>.
   */
  protected delete<T>(
    endpoint: string = '',
    params?: HttpParams | { [param: string]: string | string[] },
    headers: ValidHeaders = null
  ): WebApiResponse<T> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.delete<ServiceResult<T>>(requestUrl, {
      params,
      headers,
      withCredentials: true,
    });
  }

  /**
   * Triggers a HTTP GET to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to GET from. Defaults to empty string.
   * @param [data] Any data to be sent to the server. Keys must match query parameter name.
   * @param [headers=null] Additional headers to pass to the request.
   * @returns An observable Blob.
   */
  protected download(
    endpoint: string = '',
    params?: HttpParams | { [param: string]: string | string[] },
    headers: ValidHeaders = null
  ): Observable<Blob> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.get(requestUrl, {
      params,
      headers,
      responseType: 'blob',
      withCredentials: true,
    });
  }

  /**
   * Triggers a HTTP POST to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to GET from. Defaults to empty string.
   * @param [params] Any data to be sent to the server. Keys must match query parameter name.
   * @param [headers=null] Additional headers to pass to the request.
   * @returns An observable Blob.
   */
  protected downloadPost(endpoint: string = '', data: any = null, headers: ValidHeaders = null): Observable<Blob> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.post(requestUrl, data, {
      headers,
      responseType: 'blob',
      withCredentials: true,
    });
  }

  /**
   * Triggers a HTTP POST to the server with the specified endpoint and data.
   * @param [endpoint=''] The endpoint to GET from. Defaults to empty string.
   * @param [params] Any data to be sent to the server. Keys must match query parameter name.
   * @param [headers=null] Additional headers to pass to the request.
   * @returns An observable event, it will report progress.
   */

  protected upload(endpoint: string = '', data: any = null, headers: ValidHeaders = null): Observable<any> {
    const requestUrl = endpoint ? `${this.url}/${endpoint}` : this.url;
    headers = this.getFacingBrandHeader(headers);

    return this.http.post(requestUrl, data, {
      headers,
      withCredentials: true,
      reportProgress: true,
      observe: 'events',
    });
  }

  /**
   * Appends necessary headers for the app to send in a request.
   * @param headers Base headers.
   * @returns The base headers merged with the necessary ones.
   */
  private getFacingBrandHeader(headers: ValidHeaders = {}): ValidHeaders {
    headers = { ...headers, 'X-FacingBrandId': this.settings.facingBrandId.toString() };
    const token = jsCookie.get('XSRF-TOKEN');

    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    return headers;
  }
}
