import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import tippy, { animateFill, Props, roundArrow } from 'tippy.js';

import { buttonsInfo } from '@app/transaction-history/models/buttonsData';
import { FileFormats } from '@app/transaction-history/models/fileFormats';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-transaction-history-container',
  templateUrl: './transaction-history-container.component.html',
  styleUrls: ['./transaction-history-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionHistoryContainerComponent implements AfterViewInit {
  @ViewChild('parent') parent: ElementRef;

  @ViewChild('content') content: ElementRef;

  fileFormats: FileFormats[] = buttonsInfo;

  plusIconConfig = {
    icon: UtilityIcons.Plus,
    styles: {
      width: '1rem',
      height: '1rem',
    },
  };

  options: Partial<Props> = {
    allowHTML: true,
    animateFill: true,
    arrow: roundArrow,
    placement: 'bottom-start',
    plugins: [animateFill],
    theme: 'account-items',
    interactive: true,
    appendTo: 'parent',
    trigger: 'click',
    popperOptions: {
      modifiers: [
        { name: 'flip', enabled: false },
        { name: 'hideOnClick', enabled: true, phase: 'afterWrite' },
      ],
    },
  };

  constructor() {}

  ngAfterViewInit() {
    this.options.content = this.content.nativeElement;
    tippy(this.parent.nativeElement, this.options);
  }
}
