import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { tz } from 'moment-timezone';
import { ROOT_SCOPE } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  constructor(@Inject(ROOT_SCOPE) private root: ng.IRootScopeService) {}
  /**
   * Returns true if the date is not weekend or holiday, false otherwise
   * @param dateToValidate the date to be validated
   * @returns false if weekend or holiday, true otherwise
   */
  isValidDate(dateToValidate: moment.Moment): boolean {
    const isWeekend = this.isWeekend(dateToValidate);
    const isHoliday = this.isHoliday(dateToValidate);

    return !isWeekend && !isHoliday;
  }

  /**
   * Checks if the given date is in the weekend.
   * @param date Date to validate.
   * @returns True if the date is weekend.
   */
  isWeekend(date: moment.Moment): boolean {
    const normalizedDate = this.normalizeDate(date);
    const weekday = normalizedDate.isoWeekday();

    return weekday > 5;
  }

  /**
   * Validates a date to check if it's a holiday.
   * @param date Date to validate.
   * @returns True if the given date is a holiday.
   */
  isHoliday(date: moment.Moment): boolean {
    const normalizedDate = this.normalizeDate(date);

    if (!this.root['holidays']) return false;

    return this.root['holidays'].indexOf(normalizedDate.valueOf()) > -1;
  }

  /**
   * Adds weekdays to a given date excluding weekends and holidays
   * @param dateToValidate The date to add weekdays
   * @param days The days' number to be added
   * @returns a moment object with the date
   */
  addWeekdays(dateToValidate: moment.Moment, days: number): moment.Moment {
    let date = dateToValidate.clone();

    while (days > 0) {
      date = this.normalizeDate(date.add(1, 'days'));

      // In case of weekend or holiday, exclude it
      if (this.isValidDate(date)) {
        days -= 1;
      }
    }

    return date;
  }

  getDefaultPaymentDate(): moment.Moment {
    const selectedDate = moment();

    const californiaMoment = tz().tz('America/Los_Angeles');
    const californiaDate = moment(californiaMoment.format(`YYYY-MM-DD HH:mm`));

    //Cutoff time: To set or change the cutoff time manually, update the object accordingly
    const limitDate = selectedDate.clone().set({
      hour: 13,
      minute: 45,
      second: 0,
      millisecond: 0,
    });

    if (californiaDate.isSameOrBefore(limitDate) && this.isValidDate(selectedDate)) {
      return selectedDate;
    }

    return this.addWeekdays(selectedDate, 1);
  }

  getDefaultPaymentInformation(isTransferToNonRetirementRia: boolean = false, isIraAccout: boolean = false) {
    const selectedDate = moment();
    let limitDate;

    const californiaMoment = tz().tz('America/Los_Angeles');
    const californiaDate = moment(californiaMoment.format(`YYYY-MM-DD HH:mm`));

    //Cutoff time: To set or change the cutoff time manually, update the object accordingly
    if (!isTransferToNonRetirementRia) {
      limitDate = selectedDate.clone().set({
        hour: 13,
        minute: 45,
        second: 0,
        millisecond: 0,
      });
    } else {
      limitDate = selectedDate.clone().set({
        hour: 17,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }

    return this.getPaymentDateOnCutoffTime(
      isTransferToNonRetirementRia || isIraAccout,
      californiaDate,
      limitDate,
      selectedDate
    );
  }

  //Note: Deposit Operations / Loss Prevention Uni, have asked to change the earliest available date to the next business day,
  //      before cutoff (1:45pm PST), and adding an additional business day (+2) if it is after cutoff.
  //Note: Loss Prevention Unit is asking for more time, due to not having enough time to analyze these transactions for fraud/risk
  //      and address them if needed.
  getPaymentDateOnCutoffTime(
    isIraOrTransferToNonRetirementRia: boolean,
    dateToValidateAgainst: moment.Moment,
    dateToValidate: moment.Moment,
    selectedDate: moment.Moment
  ) {
    let isCutOffTime = false;
    let externalTransferValidDate = moment();

    if (dateToValidateAgainst.isSameOrBefore(dateToValidate) && this.isValidDate(selectedDate)) {
      if (!isIraOrTransferToNonRetirementRia) {
        externalTransferValidDate = this.addWeekdays(selectedDate, 1);
      } else {
        externalTransferValidDate = this.addWeekdays(selectedDate, 0);
      }
    } else {
      isCutOffTime = true;
      if (!isIraOrTransferToNonRetirementRia) {
        externalTransferValidDate = this.addWeekdays(selectedDate, 2);
      } else {
        externalTransferValidDate = this.addWeekdays(selectedDate, 1);
      }
    }
    return { isCutOffTime, externalTransferValidDate };
  }
  /**
   * Normalizes a date to have hour set to 00:00:00.00.
   * @param date Date to be normalized.
   * @returns A date with hour set to 00:00:00.00.
   */
  normalizeDate(date: moment.Moment): moment.Moment {
    const normalizedDate = date.clone();

    normalizedDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return normalizedDate;
  }

  /**
   * Gets the processing payment date based on the delivery payment date
   *  minus the bussiness days to deliver
   * @param paymentDate date the payment will be delivered
   * @param bussinessDaysToDeliver days the payment takes to be delivered once was processed
   * @returns result from (PaymentDate - bussinessDaysToDeliver)
   */
  getProcessingDate(paymentDate: moment.Moment, bussinessDaysToDeliver: number) {
    // make sure bussiness days to deliver is negative
    if (bussinessDaysToDeliver >= 0) {
      bussinessDaysToDeliver *= -1;
    }

    let date = paymentDate.clone();

    while (bussinessDaysToDeliver < 0) {
      date = this.normalizeDate(date.add(-1, 'days'));

      // In case of weekend or holiday, exclude it
      if (this.isValidDate(date)) {
        bussinessDaysToDeliver += 1;
      }
    }

    return date;
  }

  /**
   * Convert RAW date without taking in consideration its original timezone to "Los Angeles" time
   */
  convertRawDateToAxosLocalTime(date: moment.Moment): moment.Moment {
    const formattedDate = date.format('YYYY-MM-DD HH:mm');
    const californiaMoment = tz(formattedDate, 'America/Los_Angeles');
    const result = moment(californiaMoment.format());

    return result;
  }

  /**
   * Get local axos time
   */
  getAxosLocalDate(): moment.Moment {
    const californiaMoment = tz().tz('America/Los_Angeles');
    const californiaDate = moment(californiaMoment.format());

    return californiaDate;
  }
}
