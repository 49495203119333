import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { openClose, slideFromRight, startStaticSlideFromLeft } from '@app/user-profile/animations';
import { FamilyInfo, FinancialInfo } from '@app/user-profile/models';
import { updateProfileDetails } from '@app/user-profile/store/actions';
import { getFinancialCatalogs, getMaritalStatuses, getProfileDetails } from '@app/user-profile/store/selectors';
import { UserProfileService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { CustomerDetail } from '@shared/models';

@Component({
  selector: 'app-personal-info-view',
  templateUrl: './personal-info-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './personal-info-view.component.scss'],
  animations: [openClose, startStaticSlideFromLeft, slideFromRight],
})
export class PersonalInfoViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  expanded = false;

  isMobileDevice = false;
  profileLoading = true;
  userCanEdit = false;
  maritalStatusesLoading = true;
  catalogsLoading = true;
  currentView = PersonalInfoView.PersonalInfo;
  alertMessages = {
    financial: '',
    family: '',
  };
  maritalStatuses: Record<string, string>;
  annualIncomes: Record<string, string>;
  totalNetWorths: Record<string, string>;
  liquidNetWorths: Record<string, string>;
  taxBrackets: Record<string, string>;
  views = PersonalInfoView;
  financialInfo: FinancialInfo;
  familyInfo: FamilyInfo;
  animationState: 'initial' | 'in' = 'initial';

  private profileInfo: Partial<CustomerDetail>;
  private subsink = new SubSink();

  constructor(
    private store: Store,
    private userProfileService: UserProfileService,
    private breakpointObserver: BreakpointObserver,
    private featureFlagService: FeatureFlagService,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit(): void {
    this.loadCatalogs();

    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.profileInfo = data;
        this.userCanEdit = !this.featureFlagService.isSiteInReadOnly();
        this.recomputeViewValues(data);
        this.profileLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  updateFamilyInfo(data: FamilyInfo): void {
    const payload: Partial<CustomerDetail> = { ...this.profileInfo, ...data };
    this.alertMessages.family = 'You have successfully saved your family information.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
  }

  updateFinancialInfo(data: FinancialInfo): void {
    const payload: Partial<CustomerDetail> = { ...this.profileInfo, ...data };
    this.alertMessages.financial = 'You have successfully saved your financial information.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
  }

  goTo(view: PersonalInfoView): void {
    this.currentView = view;
    this.alertMessages = { family: '', financial: '' };
    this.animationState = 'initial';
  }

  goBack(): void {
    this.currentView = PersonalInfoView.PersonalInfo;
    this.animationState = 'in';
  }

  private loadCatalogs(): void {
    this.subsink.sink = this.store
      .select(getMaritalStatuses)
      .pipe(
        switchMap(data => {
          if (!data) {
            return this.userProfileService.getPossibleMaritalStatuses();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.maritalStatuses = data;
        this.maritalStatusesLoading = false;
      });

    this.subsink.sink = this.store
      .select(getFinancialCatalogs)
      .pipe(
        switchMap(data => {
          if (!data) {
            return this.userProfileService.getFinancialCatalogs();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.annualIncomes = data.annualIncomes;
        this.totalNetWorths = data.totalNetWorths;
        this.liquidNetWorths = data.liquidNetWorths;
        this.taxBrackets = data.taxBrackets;

        this.catalogsLoading = false;
      });
  }

  private recomputeViewValues(data: Partial<CustomerDetail>): void {
    this.financialInfo = {
      annualIncome: data.annualIncome,
      totalNetWorth: data.totalNetWorth,
      taxBracket: data.taxBracket,
      liquidNetWorth: data.liquidNetWorth,
    };

    this.familyInfo = {
      maritalStatus: data.maritalStatus ?? '',
      numberOfDependents: data.numberOfDependents,
    };
  }
}

export enum PersonalInfoView {
  PersonalInfo = 'Personal Info',
  Financial = 'Financial Information',
  Family = 'Family Information',
}
