import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { AccountsMenuItem } from '../components/account-aggregation/typings/AccountsMenuItem';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { OlbSettings } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class SblocAccountService extends BaseService {
  private PublicProductPage = new Subject<AccountsMenuItem[]>();
  private angularJSServices: any;

  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, '');
  }

  getAccountsMenuItem(): Observable<AccountsMenuItem[]> {
    this.angularJSServices = angular.element(document.body).injector().get('personalizedContentService');
    this.angularJSServices.hasContent
      .then(() => {
        this.angularJSServices
          .GetPersonalizedContent('OLB', '/Accounts')
          .then(data => {
            this.PublicProductPage.next(data?.data?.data?.route?.placeholders?.main[0]?.placeholders?.AccountsMenuItem);
          })
          .catch(err => console.warn(err));
      })
      .catch(err => console.warn(err));

    return this.PublicProductPage.asObservable();
  }
}
