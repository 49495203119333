import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';
import { add, differenceInDays, format, isBefore, sub } from 'date-fns';

import { PORTFOLIO_CHART_CONFIGURATION } from '@app/axos-trading/constants';
import {
  PortfolioBalanceHistoryFilter,
  PortfolioBalanceHistoryResponse,
  PortfolioChartConfiguration,
  TradingAccount,
} from '@app/axos-trading/models';
import { isWeekend } from '@app/axos-trading/utils';
import { AbbreviateNumber, isSameOrBefore } from '@app/utils';

@Component({
  selector: 'app-historic-performance-chart',
  templateUrl: './historic-performance-chart.component.html',
  styleUrls: ['./historic-performance-chart.component.scss'],
})
export class HistoricPerformanceChartComponent implements OnChanges, AfterViewInit {
  @Input() data: PortfolioBalanceHistoryResponse[];
  @Input() filter: PortfolioBalanceHistoryFilter;
  @Input() account: TradingAccount;

  @ViewChild('chartCanvas') chartCanvas: ElementRef;

  chart: Chart;
  chartConfiguration: PortfolioChartConfiguration;
  fromDate: Date;
  toDate: Date;
  displayCustomLabels = false;
  copyData: PortfolioBalanceHistoryResponse[];

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && !changes.filter.firstChange) {
      this.calculateTimelapse();
    }
  }

  ngAfterViewInit() {
    this.calculateTimelapse();
  }

  private getFromDateFilter(toDate: Date) {
    return this.filter.duration ? sub(toDate, this.filter.duration) : new Date(this.account.openedDate);
  }

  private calculateTimelapse() {
    this.copyData = [...this.data];
    this.toDate = this.copyData.slice(-1)[0].date;
    const firstAvailableDate = this.copyData[0].date;
    this.fromDate = this.getFromDateFilter(this.toDate);
    const difference = differenceInDays(this.toDate, this.fromDate);
    this.chartConfiguration = PORTFOLIO_CHART_CONFIGURATION.find(
      config => difference >= config.min && (difference < config.max || config.max === -1)
    );
    if (this.filter.id === 3 || this.filter.id === 4) {
      const today = new Date();
      const yesterday = isWeekend(sub(today, { days: 1 }));
      this.copyData = this.copyData.filter(x => x.date.getDay() === yesterday.getDay());
    }
    if (this.filter.id === 3) {
      while (!this.copyData.find(x => x.date.toISOString() === this.fromDate.toISOString())) {
        this.fromDate = add(this.fromDate, { days: 1 });
      }
    }
    this.displayCustomLabels = !this.chartConfiguration.dataLabelFormat;
    const dataLabels: string[] = [];
    const tooltipLabels: string[] = [];
    const data: number[] = [];

    let itemDate = this.toDate;

    while (isSameOrBefore(this.fromDate, itemDate)) {
      const dataElement = this.copyData.find(t => t.date.toISOString() === itemDate.toISOString());

      if (dataElement || isBefore(itemDate, firstAvailableDate)) {
        const labels = this.setupLabels(itemDate);
        dataLabels.push(labels.data);
        tooltipLabels.push(labels.tooltip);

        data.push(dataElement?.balance || 0);
      }

      itemDate = sub(itemDate, { days: 1 });
    }

    const operation = this.chart ? this.updateChart : this.createChart;
    operation.bind(this)(data.reverse(), dataLabels.reverse(), tooltipLabels.reverse());
  }

  private setupLabels(day: Date): { data: string; tooltip: string } {
    return {
      data: this.chartConfiguration.dataLabelFormat ? format(day, this.chartConfiguration.dataLabelFormat) : '',
      tooltip: this.chartConfiguration.tooltipLabel.replace(
        '${date}',
        format(day, this.chartConfiguration.timeLabelFormat)
      ),
    };
  }

  private createChart(data: number[], dataLabels: string[], tooltipLabels: string[]) {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, '#60a3ccc9');
    gradient.addColorStop(1, '#FFFFFFa3');

    this.chart = new Chart(this.chartCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: dataLabels,
        datasets: [
          {
            data,
            borderColor: '#4d85b0',
            borderWidth: 2,
            fill: true,
            tension: 0,
            pointRadius: 0,
            pointHitRadius: 9.5,
            pointHoverBackgroundColor: '#4d85b0',
            pointHoverBorderWidth: 0,
            pointHoverRadius: 12,
            backgroundColor: gradient,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 10,
          },
        },
        responsive: true,
        stacked: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                callback: (value: string): string => {
                  return '$' + AbbreviateNumber(Number(value), 2);
                },
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#ffffff',
          xPadding: 25,
          yPadding: 20,
          caretPadding: 20,
          caretSize: 10,
          borderColor: '#cac9c9',
          borderWidth: 1,
          titleFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          titleFontColor: '#000',
          titleFontStyle: 'bold',
          titleMarginBottom: 15,
          titleFontSize: 14,
          bodyFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          bodyFontSize: 12,
          bodyFontColor: '#000',
          footerFontSize: 20,
          footerFontColor: '#466d95',
          footerFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          footerMarginTop: 15,
          displayColors: false,
          callbacks: this.setupTooltipCallback(tooltipLabels),
        },
      },
    });
  }

  private updateChart(data: number[], dataLabels: string[], tooltipLabels: string[]) {
    this.chart.data.labels = dataLabels;
    this.chart.data.datasets[0].data = data;
    this.chart.options.tooltips.callbacks = this.setupTooltipCallback(tooltipLabels);
    this.chart.update();
  }

  private setupTooltipCallback(tooltipLabels: string[]) {
    return {
      title: (tooltip: TooltipItem[], tooltipData: any): string => {
        return tooltipData.labels[tooltip[0].index];
      },
      label: (tooltip: TooltipItem): string => {
        return tooltipLabels[tooltip.index];
      },
      footer: (tooltip: TooltipItem[], tooltipData: any): string => {
        return this.currencyPipe.transform(tooltipData.datasets[tooltip[0].datasetIndex].data[tooltip[0].index]);
      },
    };
  }
}
