import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { IconDetails } from '@app/user-profile/models';
import { getProfileDetails, getProfileIconDetails } from '@app/user-profile/store/selectors';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileImageComponent implements OnInit, OnDestroy {
  @Input() businessProfile: boolean;
  iconDetails$: Observable<IconDetails>;
  initials: string;

  private subsink = new SubSink();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.iconDetails$ = this.store.select(getProfileIconDetails);

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.initials = `${data.firstName.charAt(0)} ${data.lastName.charAt(0)}`;
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
