import { RecipientsController } from './recipients.controller';

export const RecipientsComponent: ng.IComponentOptions = {
  controller: RecipientsController,
  controllerAs: '$ctrl',
  templateUrl: 'bill-pay/pay/recipients/recipients.tpl.html',
  bindings: {
    recipients: '<?',
    paymentsHistory: '<?',
    scheduledPayments: '<?',
    cancelText: '<?',
    loadScheduledPayments: '&',
    paymentUpdated: '&',
  },
};
