import { Inject } from '../../decorators/decorators';

@Inject('$stateProvider')
export class WireTransfersConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.transfers.wireTransfers', {
      url: '/wire-transfers',
      views: {
        'transfer-content': {
          templateUrl: 'transfers/wire-transfers/wire-transfers.tpl.html',
          controller: 'WireTransfersController',
          controllerAs: '$wt',
        },
      },
      params: { needsReset: false },
    });
  }
}
