<div class="models-list">
  <div class="header">
    <div class="title-container">
      <h2 class="title">
        Your Models
      </h2>
      <mat-icon *ngIf="modelsTileAccountState$?.models.length > 0" class="icon" [svgIcon]="alertsIcons.infoCircle"
        (click)="toggleUnderstandingModelsModal()"></mat-icon>
    </div>
    <uk2-menu-button
      *ngIf="modelsTileAccountState$?.sortOptions && modelsTileAccountState$.isSortingEnabled && modelsTileAccountState$.models?.length > 0"
      [uk2KeepLastSelection]="true" [uk2ButtonSize]="sortButtonSize" [uk2ButtonType]="singleSortButton"
      [uk2CSSPortalProperties]="customPortalCSSProperties | async" [uk2ItemList]="modelsTileAccountState$?.sortOptions"
      (uk2OnItemSelected)="selectSortingFilter($event)" [uk2SvgIconName]="icon" [uk2DefaultStateText]="defaultText" />

  </div>
  <div class="content" #contentSection (window:resize)="onResize()">
    <uk2-text-tile
      id="holdings-failed-message-container"
      *ngIf="modelsFailedState$ | async"
      [uk2TextTileTitle]="uk2TextTileTitle"
      [uk2TextTileSvgIconName]="uk2TextTileSvgIconName"
      [uk2TextTileDisplayBackground]="uk2TextTileDisplayBackground"
      [uk2TextTileIsStandaloneTile]="uk2TextTileIsStandaloneTile"
    >
      <p>We're sorry, but we're currently unable to update you with the latest information on your portfolio’s models. Please check back later. Thank you for your patience.</p>
    </uk2-text-tile>
    <div *ngIf="!(modelsFailedState$ | async)">
      <div *ngIf="modelsTileAccountState$">
        <ng-container *ngIf="modelsTileAccountState$.models?.length > 0 && isDataLoaded; else hasNoAccountsTemplate">
          <ul class="items-list" *ngIf="modelsTileAccountState$.models">
            <li class="item" *ngFor="let model of modelsTileAccountState$.models">
              <div class="portfolio-percentage-value">
                <span class="portfolio-percentage-value-container">
                  {{ getFormattedPercentageValue(model.portfolioPercent * 100) }}% of Portfolio
                </span>
              </div>
              <div class="description-container">
                <span class="text">{{ model.description }}</span>
              </div>
              <div class="market-value-container">{{ getFormattedMarketValue(model.marketValue) }}</div>
              <div>
                <uk2-value-movement [amount]="isDataLoaded ? getAbsoluteAmount(model.pastDayChangeValue) : 0.0"
                  [percentage]="isDataLoaded ? getAbsoluteAmount(model.pastDayChangePercent) : 0.0"
                  [description]="isMarkedOpened$ ? 'Today&emsp;&emsp;' : closedMarketLabel"
                  [valueMovementType]="getUk2ValueMovementTypeEnum(model.pastDayChangePercent)"></uk2-value-movement>
              </div>
              <div class="portfolio-all-time" *ngIf="!hideAllTimePortfolio">
                <uk2-value-movement [amount]="isDataLoaded ? getAbsoluteAmount(model.allTimeChangeValue) : 0.0"
                  [percentage]="isDataLoaded ? getAbsoluteAmount(model.allTimeChangePercent) : 0.0"
                  [description]="'All Time&emsp;&emsp;'"
                  [valueMovementType]="getUk2ValueMovementTypeEnum(model.allTimeChangeValue)"></uk2-value-movement>
              </div>
            </li>
          </ul>
        </ng-container>
        <ng-template #hasNoAccountsTemplate>
          <div class="no-models-banner">
            <div class="no-models-banner__img-wrapper">
              <img src="assets/img/icons/AAS/empty-models-icon.svg" alt="empty models">
            </div>
            <h5>You currently don’t have any models in your portfolio. Once your advisor initiates your models, you
              will
              see them here.</h5>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<app-understanding-model-modal [showUnderstandingModelsModal]="showUnderstandingModelsModal"
  (hideUnderstandingModelsModal)="toggleUnderstandingModelsModal()"></app-understanding-model-modal>