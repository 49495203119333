<h4 class="completed-transfer-title">{{ title }}</h4>
<p class="completed-transfer-description">
  A {{ completedTransfer.frequencyDescription }} {{ completedTransfer.transactionTypeDescription }} of
  {{ completedTransfer.amount | currency }} has been submitted! It will start on
  {{ completedTransfer.startDate | date: dateFormat }}
  <span *ngIf="completedTransfer.sendUntil === sendUntil.UntilIChooseToStop">
    and will be ongoing until cancelled
  </span>
  <span *ngIf="completedTransfer.sendUntil === sendUntil.LastTransferDate">
    and end on {{ completedTransfer.endDate | date: dateFormat }}
  </span>
  . Please allow up to 5-7 business days for the funds to become available.
</p>
<div class="image-container">
  <img class="account-profile__icon" [src]="icon" alt="" aria-hidden="true" />
</div>
<button class="btn-ok" type="button" id="okBtn" (click)="goToSummary()">
  Go to goal summary
</button>
