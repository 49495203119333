import * as angular from 'angular';
import { Inject } from '../../../../decorators/decorators';
import { AlertsUI } from '../../typings/AlertsUI';
import * as moment from 'moment';

@Inject('$scope', 'dateRangePickerOptions')
export class FrequencySelectorController {
  public frequencyOptions: Array<any>;
  public dayOptions: Array<any>;
  public frequencySelected: any;
  public daySelected: any;
  public datePickerOptions: any;
  public datePicker: any;
  public frequencyText: string;
  public weekDays: Array<string> = [];

  public showDropdown: boolean = false;
  private alertUI: AlertsUI;
  private alertObj: Alerts;

  constructor(private _scope: any, private _dateRangePickerOptions: any) {
    _scope.$on('undoFrequencyChanges', (_event: any, a: Alerts) => {
      this._loadFrequencyData(a, true);
    });
  }
  /** Initializes the controller. */
  $onInit(): void {
    this.alertUI = this._scope.$parent.alert;
    this.alertObj = this.alertUI.alert;

    this._setDropdowns();
    this._setDatePickerOptions();
    this._loadFrequencyData();
  }

  /** Display the dropdown */
  toggleDropdown(forceClose: boolean = false): void {
    this.showDropdown = forceClose ? false : !this.showDropdown;
  }

  public onFrequencySelected = () => {
    this.datePicker = moment();
  };

  public saveChanges = () => {
    this._scope.$parent.alert.editMode = true;
    this._scope.$parent.alert.alert.frequency = this.frequencySelected.frequency;
    this._scope.$parent.alert.alert.startDate = this.datePicker.toDate();

    if (this.frequencySelected.type === 'day' && this.frequencySelected.frequency != 'Daily') {
      this._scope.$parent.alert.alert.dayofWeek = this.daySelected.value;
    } else {
      this._scope.$parent.alert.alert.dayofWeek = null;
    }

    this._displayFrequencyText();

    this.showDropdown = false;
  };

  public cancelChanges(): void {
    this.showDropdown = false;
    this._loadFrequencyData(this._scope.$parent.alert.alert, true);
  }

  private _loadFrequencyData = (a?: Alerts, saveWeekday?: boolean) => {
    // get alert data from cancelled edited alert or normal alert data value.
    a ? (this.alertUI.alert = a) : this.alertUI.alert;

    //renders default day time
    this.datePicker.format ? this.datePicker : (this.datePicker = moment());

    this.frequencyOptions.forEach((item: any) => {
      // set Frequency type
      if (item.frequency === this.alertUI.alert.frequency) {
        this.frequencySelected = item;

        if (this.frequencySelected.type === 'day') {
          //set Frequency day value
          let date = new Date(this.alertUI.alert.startDate);

          this.dayOptions.forEach((day: any) => {
            if (day.title === this.weekDays[date.getDay()]) {
              this.daySelected = day;
            }
          });

          if (saveWeekday) {
            this.alertUI.alert.dayofWeek = this.weekDays[date.getDay()];
          }
        } else {
          this.datePicker = moment(this.alertUI.alert.startDate);
        }
      }
    });

    if (!this.alertUI.isNew) {
      this._displayFrequencyText();
      this._scope.$parent.alert.editMode = false;
    }
  };

  private _displayFrequencyText() {
    let frequencyDay = this.alertObj.dayofWeek ? this.alertObj.dayofWeek : this.daySelected.title;

    let freq: string =
      this.frequencySelected.type === 'date'
        ? `starting <a>${this.datePicker.format('LL')}</a>`
        : `on <a>${frequencyDay}</a>`;

    if (this.frequencySelected.frequency === 'Daily') {
      freq = '';
    }

    this.frequencyText = `<a>${this.frequencySelected.title}</a> ${freq}`;
  }

  private _setDropdowns = () => {
    this.frequencyOptions = [
      { title: 'One Time', type: 'date', frequency: 'None' },
      { title: 'Daily', type: 'day', frequency: 'Daily' },
      { title: 'Once a week', type: 'day', frequency: 'Weekly' },
      { title: 'Once every 2 weeks', type: 'day', frequency: 'BiWeekly' },
      { title: 'Once a month', type: 'date', frequency: 'Monthly' },
      { title: 'Once every 3 months', type: 'date', frequency: 'Quarterly' },
      { title: 'Once every 6 months', type: 'date', frequency: 'SemiAnnually' },
      { title: 'Once a year', type: 'date', frequency: 'Yearly' },
    ];

    this.dayOptions = [
      { id: 1, title: 'Sunday', type: 'weekend', value: 'Sunday' },
      { id: 2, title: 'Monday', type: 'weekday', value: 'Monday' },
      { id: 3, title: 'Tuesday', type: 'weekday', value: 'Tuesday' },
      { id: 4, title: 'Wednesday', type: 'weekday', value: 'Wednesday' },
      { id: 5, title: 'Thursday', type: 'weekday', value: 'Thursday' },
      { id: 6, title: 'Friday', type: 'weekday', value: 'Friday' },
      { id: 7, title: 'Saturday', type: 'weekend', value: 'Saturday' },
    ];

    this.weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    this.frequencySelected = this.frequencyOptions[0];
    this.daySelected = this.dayOptions[0];
  };

  private _setDatePickerOptions = () => {
    this.datePickerOptions = angular.extend(angular.copy(this._dateRangePickerOptions), {
      minDate: moment(),
      singleDatePicker: true,
    });

    this.datePicker = {
      startDate: null,
      endDate: null,
    };
  };
}
