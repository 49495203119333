import { AccountAggregationService } from '@legacy/services/account-aggregation.service';

export function accountAggregationServiceFactory($inject: any){
  return $inject.get('accountAggregationService');
}

export const accountAggregationServiceProvider = {
  provide: AccountAggregationService,
  useFactory: accountAggregationServiceFactory,
  deps: ['$injector'],
};
