import { AxosInvestHelper } from '@legacy/shared/helpers/axos-invest.helper';

export function axosInvestHelperFactory($injector: any) {
  return $injector.get('axosInvestHelper');
}

export const axosInvestHelperProvider = {
  provide: AxosInvestHelper,
  useFactory: axosInvestHelperFactory,
  deps: ['$injector'],
};
