import { CookieHelper } from '@legacy/shared/helpers/cookie.helper';

export function cookieHelperFactory($injector: any) {
  return $injector.get('cookieHelper');
}

export const cookieHelperProvider = {
  provide: CookieHelper,
  useFactory: cookieHelperFactory,
  deps: ['$injector'],
};
