export type TopBarState = {
  logoImgPath: string | undefined;
  organizationName: string | undefined;
  isAxos: boolean;
  urlRedirectionConfig: string | undefined;
};

export const initialTopBarState: TopBarState = {
  logoImgPath: undefined,
  organizationName: undefined,
  isAxos: false,
  urlRedirectionConfig: undefined,
};
