/** A generic option for dropdowns */
export class GenericOption {
  /** The value of the option */
  value: number;
  /** Text/label shown in the option */
  label: string;
  /** Optional label with different format shown in the option */
  subvalue: string;
  /** Pipe separated string with the values that determine when this option apperas. */
  displayWhen: string;
  /** CSS class to style the element */
  cssClass: string;
  default: boolean;

  constructor(args?: Partial<GenericOption>) {
    Object.assign(this, args);
  }
}
