import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountsModalTexts } from '@app/accounts/enums';
import { AlertsIcons, NavigationIcons } from '@shared/enums';
import { AnimationOptions } from 'ngx-lottie';
import { Uk2Tier1AlertsEnum, Uk2Tier1NavigationEnum } from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-information-step',
  templateUrl: './information-step.component.html',
  styleUrls: ['./information-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationStepComponent {
  accountModalTexts = AccountsModalTexts;
  iconColor = 'var(--primary)';
  questionIcon = AlertsIcons.QuestionCircle;
  openIcon = NavigationIcons.ChevronDown;
  closeIcon = NavigationIcons.ChevronUp;
  uk2Tier1AlertsEnum = Uk2Tier1AlertsEnum;

  uk2Tier1NavigationEnum = Uk2Tier1NavigationEnum;
  openedAccordion = false;

  checkingAccountAnimationOptions: AnimationOptions = {
    path: '/assets/axos/animations/checking_account.json',
    loop: false,
  };

  toggleChevron() {
    this.openedAccordion = !this.openedAccordion;
  }
}
