export class DatePickerReferenceHandler {
  date: Date;
  dateWithFormat: string;
  constructor(date: Date) {
    this.setDatePickerFormatForDate(date);
  }

  setDatePickerFormatForDate(date: Date | string): string {
    const isDateType = date instanceof Date;
    if (isDateType) {
      this.date = date;
      const formattedDate = this.getDateFormat(date);
      this.dateWithFormat = formattedDate;
      return formattedDate;
    }
    const dateInstance = this.parseDateFromString(date);
    this.date = dateInstance;
    const formattedDate = this.getDateFormat(dateInstance);
    this.dateWithFormat = formattedDate;
    return formattedDate;
  }

  getDateFormat(date: Date): string {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${month}/${day}/${year}`;
  }

  parseDateFromString(date: string): Date {
    return new Date(date);
  }
}
