import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankWireComponent } from './View/bank-wire.component';

@NgModule({
  declarations: [BankWireComponent],
  imports: [CommonModule],
  exports: [BankWireComponent],
})
export class BankWireModule {}
