import * as angular from 'angular';
import { AlertsNotificationsConfig } from './alerts-notifications.config';
import { AlertsNotificationsController } from './alerts-notifications.controller';
import { common } from '../common/common';
import { services } from '../services/services';
import { myAlerts } from './my-alerts/my-alerts';

export const alertsNotifications = angular
  .module('udb.alertsNotifications', [myAlerts, 'ui.router', 'ngMessages', 'ui.tree', 'ui.mask', services, common])
  .controller('AlertsNotificationsController', AlertsNotificationsController)
  .config(AlertsNotificationsConfig).name;
