<div class="beneficiary-form">
  <hr [hidden]="!(position + 1 > 1)" />
  <div class="beneficiary-form_title">
    <h3>
      Beneficiary Information
      <span [hidden]="!(position + 1 > 1)">
        - {{ position + 1 }}
        <a class="pull-right remove-beneficiary link" (click)="removeBeneficiary(position)">
          <app-svg-icon size="1em" [iconName]="icons.trash"></app-svg-icon>
          Remove
        </a>
      </span>
    </h3>
  </div>

  <div class="frow">
    <div
      class="group--form fcol-md-12 fcol-cs-12"
      ngClass="{'has-error':
      (parentForm.get('fullName_{{ position }}').$invalid ||
      parentForm.get('fullName_{{ position }}').hasError('required') ) &&
      parentForm.$submitted}"
    >
      <label for="fullname_{{ position }}" class="uk-label">Full Name</label>
      <input
        id="fullName_{{ position }}"
        name="fullName_{{ position }}"
        class="uk-input"
        placeholder="Enter First and Last Name"
        type="text"
        [value]="beneficiary.name"
        required
        (blur)="checkBeneficiary()"
      />

      <div *ngIf="parentForm.$$controls[indexControl].hasError('*')">
        <p *ngIf="parentForm.$$controls[indexControl].hasError('*')" class="text-error">
          Duplicate Beneficiary
        </p>
      </div>

      <div
        [hidden]="
          !(
            (parentForm.get('fullName_' + position).$touched && parentForm.get('fullName_' + position).$invalid) ||
            parentForm.$submitted
          )
        "
      >
        <span class="text-error" [hidden]="!parentForm.get('fullName_' + position).hasError('required')">
          Full Name is required.
        </span>
      </div>
    </div>
  </div>
  <div class="frow">
    <div
      class="group--form fcol-md-6 fcol-cs-12"
      ngClass="{'has-error':
      (parentForm.dob_{{ position }}.$invalid
      || !isDobValid) &&
      parentForm.$submitted}"
    >
      <label for="dob_{{ position }}" class="uk-label">Date of Birth</label>
      <input
        id="dob_{{ position }}"
        name="dob_{{ position }}"
        class="uk-input"
        placeholder="MM/DD/YYYY"
        type="text"
        [value]="beneficiary.identity"
        ui-mask="99/99/9999"
        required
        (input)="formatDoB()"
      />
      <div
        [hidden]="
          !(
            (parentForm.get('dob_' + position).$touched &&
              (parentForm.get('dob_' + position).$invalid || !isDobValid)) ||
            parentForm.$submitted
          )
        "
      >
        <span class="text-error" [hidden]="!parentForm.get('dob_' + position).hasError('required')">
          Date of Birth is required.
        </span>
        <br />
        <span class="text-error" [hidden]="!(!parentForm.get('dob_' + position).hasError('required') && !isDobValid)">
          Date of Birth is invalid.
        </span>
      </div>
    </div>
    <div
      class="group--form fcol-md-6 fcol-cs-12"
      ngClass="{'has-error':
      (parentForm.get('relationship_{{ position }}').$invalid
      || parentForm.get('relationship_{{ position }}').hasError('required')) &&
      parentForm.$submitted }"
    >
      <label for="relationship_{{ position }}" class="uk-label">Relationship</label>
      <select
        id="relationship_{{ position }}"
        name="relationship_{{ position }}"
        class="uk-input"
        required
        (change)="onRelationshipChange(beneficiaryRelationshipSelect)"
        [value]="beneficiaryRelationshipSelect"
      >
        <option *ngIf="!beneficiary.relationship" value="" disabled selected>Select</option>
        <option value="{{ item.value }}" *ngFor="let item of relationshipType">
          {{ item.value }}
        </option>
      </select>
      <div
        [hidden]="
          !(
            (parentForm.get('relationship_' + position).$touched &&
              parentForm.get('relationship_' + position).$invalid) ||
            parentForm.$submitted
          )
        "
      >
        <span class="text-error" [hidden]="!parentForm.get('relationship_' + position).hasError('required')">
          Relationship is required.
        </span>
      </div>
      <div
        class="group--form other-relationship"
        [ngClass]="getPositionClass(position)"
        *ngIf="beneficiaryRelationshipSelect == 'Other'"
      >
        <label for="other_{{ position }}" class="uk-label">Other Relationship</label>
        <input
          id="other_{{ position }}"
          name="other_{{ position }}"
          class="uk-input o"
          type="text"
          required
          maxlength="15"
          (input)="onRelationshipChange(otherRelationship)"
          [value]="otherRelationship"
        />
        <div
          [hidden]="
            !(
              (parentForm.get('other_' + position).$touched && parentForm.get('other_' + position).$invalid) ||
              parentForm.$submitted
            )
          "
        >
          <span class="text-error" [hidden]="!parentForm.get('other_' + position).hasError('required')">
            Other Relationship is required.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
