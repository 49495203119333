import { createAction, props } from '@ngrx/store';

import { ModelsTileState, TSortModelAccountsActionPayload } from './types';

export const addUpdateModelAccountAction = createAction(
  '[Models] AddUpdateModelAccount',
  props<{ payload: ModelsTileState }>()
);

export const selectSortingOption = createAction(
  '[Models] select sorting option',
  props<TSortModelAccountsActionPayload>()
);
