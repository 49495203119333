import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent implements OnInit, OnDestroy {
  @Output() search = new EventEmitter<string>();
  searchControl: UntypedFormControl;
  subsink = new SubSink();
  constructor(private formBuilder: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.searchControl = this.formBuilder.control('');
    this.subsink.sink = this.searchControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(value => {
        this.search.emit(value);
      });
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }
}
