<div class="open-account-container">
  <button
    type="button"
    class="open-account-item"
    [disabled]="fileFormat.disabled"
    (click)="transactionInfo.emitClickOnDownloadFile(fileFormat)"
  >
    {{ fileFormat.displayText }}
    <app-svg-icon [iconName]="chevronIconConfig.icon" [styles]="chevronIconConfig.styles"></app-svg-icon>
  </button>
</div>
