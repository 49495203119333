<app-main-layout>
  <div
    class="main-layout"
    [ngClass]="{
      'main-layout__background-v1': !isPdpEnhancementOn,
      'main-layout__background-v2': isPdpEnhancementOn
    }"
  >
    <div id="quote-timestamp-container">
      <app-quote-timestamp></app-quote-timestamp>
    </div>
    <div class="main-container">
      <!-- Total Value Failed state-->
      <ng-container *ngIf="totalValueFailedState$ | async">
        <uk2-text-tile
          id="total-value-failed-message-container"
          [uk2TextTileTitle]="totalValueFailedTileTitle"
          [uk2TextTileSvgIconName]="uk2TextTileSvgIconName"
          [uk2TextTileDisplayBackground]="uk2TextTileDisplayBackground"
          [uk2TextTileIsStandaloneTile]="uk2TextTileIsStandaloneTile"
        >
          <p>
            We're sorry, but we're currently unable to update you with the
            latest information on your portfolio’s total value. Please check
            back later. Thank you for your patience.
          </p>
        </uk2-text-tile>
      </ng-container>
      <!-- Total Value -->
      <ng-container *ngIf="!(totalValueFailedState$ | async)">
        <div
          class="stretched-item-container__chart side-left"
          *ngIf="isZeroStateForTotalValue"
        >
          <app-total-value-tile></app-total-value-tile>
        </div>
      </ng-container>

      <!-- Available Cash -->
      <div
        class="available-cash-container stretched-item-container side-left available-cash-container-hide-on-desktop"
      >
        <app-available-cash-tile></app-available-cash-tile>
      </div>

      <!-- Portfolio allocation-->
      <ng-container
        *ngIf="
          !(portfolioAllocationTileAccountState$ | async)?.error;
          else portfolioAllocationTileFailedState
        "
      >
        <div
          class="item-container side-left portfolioAllocation-container"
          *ngIf="
            isRiaPilotPortfolioAllocationFlagActive &&
            !isZeroStatePortfolioAllocationTileAccount &&
            !(isLoadingPortfolioAllocationTile$ | async)
          "
        >
          <app-portfolio-allocation-tile></app-portfolio-allocation-tile>
        </div>
      </ng-container>

      <!-- Portfolio allocation Failed State-->
      <ng-template #portfolioAllocationTileFailedState>
        <div id="portfolio-allocation-failed-container" class="side-left">
          <uk2-text-tile
            [uk2TextTileTitle]="portfolioAllocationFailedTileTitle"
            [uk2TextTileSvgIconName]="uk2TextTileSvgIconName"
            [uk2TextTileDisplayBackground]="uk2TextTileDisplayBackground"
            [uk2TextTileIsStandaloneTile]="uk2TextTileIsStandaloneTile"
          >
            <p>
              We're sorry, but we're currently unable to update you with the
              latest information on your portfolio allocation. Please check back
              later. Thank you for your patience.
            </p>
          </uk2-text-tile>
        </div>
      </ng-template>

      <!-- Transactions, Orders & Scheduled -->
      <div
        *ngIf="
          isRiaPilotTransactionOrderScheduledActive &&
          !isLoadingTransactionOrderScheduledTiles &&
          !isZeroStateTransactionOrderScheduled
        "
        class="item-container side-left"
      >
        <app-transactions-orders-scheduled-tab></app-transactions-orders-scheduled-tab>
      </div>

      <!-- Statements & Documents -->
      <div
        *ngIf="
          !isZeroStateStatementsAndDocuments && !isLoadingStatementAndDocuments
        "
        class="item-container side-left"
      >
        <app-statements-and-documents-tile></app-statements-and-documents-tile>
      </div>

      <!-- Accounts Details -->
      <div class="side-left">
        <app-account-details-tile
          class="account-details-container"
        ></app-account-details-tile>
      </div>

      <div class="side-right">
        <!-- Available Cash -->
        <div
          class="available-cash-container item-container available-cash-container-hide-on-mobile"
        >
          <app-available-cash-tile></app-available-cash-tile>
        </div>
        <div
          #appModelsHoldingsTabContainer
          *ngIf="
            !isZeroStateModelsTileAccount && !isZeroStateHoldingTileAccount
          "
        >
          <div class="models-holdings-container item-container">
            <!-- Holdings & Models -->
            <app-models-holdings-tab
              [heightScrollArea]="modelsHoldingsTabContainerHeightArea"
            ></app-models-holdings-tab>
          </div>
          <div
            class="holdings-quotes-container item-container"
            *ngIf="!showAboutHoldingQuoteTile"
          >
            <!-- About holding quotes -->
            <app-about-holding-quotes-tile
              (onHoldingQuoteToggle)="onToggleHoldingsQuote($event)"
            ></app-about-holding-quotes-tile>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-main-layout>
