import { createAction, props } from '@ngrx/store';

import { TimePeriod } from '@app/pfm/enums/time-period.enum';
import { PfmTransaction } from '@app/pfm/models/pfm-transaction.model';
import { PfmTransactionsRequest } from '@app/pfm/models/pfm-transactions-request.model';
import { RecategorizeTransactionRequest } from '@legacy/typings/app/account-aggregation/RecategorizeTransactionRequest';

import { Category } from '../../models/category';

export const setTransactionsLoading = createAction(
  '[Insights.Spending] Set transactions loading',
  props<{ payload: boolean }>()
);

export const setLastFiltersRequest = createAction(
  '[Insights.Spending] Set last filters request',
  props<{ payload: PfmTransactionsRequest }>()
);

export const setTransactions = createAction(
  '[Insights.Spending] Set transactions',
  props<{ payload: PfmTransaction[] }>()
);

export const setSpentByCategories = createAction(
  '[Insights.Spending] Set spent by categories',
  props<{ payload: Category[] }>()
);

export const commitFilters = createAction('[Insights.Spending] Commit filters');
export const resetUncommittedFilters = createAction('[Insights.Spending] Reset uncommitted filters');

// ---------- Time period filter ----------
export const setTimePeriodFilter = createAction(
  '[Insights.Spending] Change time period filter',
  props<{ payload: TimePeriod }>()
);
export const resetTimePeriodFilter = createAction('[Insights.Spending] Reset time period filter');

// ---------- Excluded accounts filter ----------
export const setExcludedAccountsFilter = createAction(
  '[Insights.Spending] Change excluded accounts filter',
  props<{ payload: Set<string> }>()
);
export const resetExcludedAccountsFilter = createAction('[Insights.Spending] Reset excluded accounts filter');

// ---------- Categories accounts filter ----------
export const setExcludedCategoriesFilter = createAction(
  '[Insights.Spending] Change excluded categories filter',
  props<{ payload: Set<number> }>()
);
export const resetExcludedCategoriesFilter = createAction('[Insights.Spending] Reset excluded categories filter');

export const recategorizeTransaction = createAction(
  '[Insights.Spending] Re-categorize transaction',
  props<{ payload: RecategorizeTransactionRequest }>()
);
