import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { interval, Subscription } from 'rxjs';

import { SubSink } from '@axos/subsink';

import { ExchangeAgreement } from '@app/axos-trading/models';
import { ExchangeAgreementService, TradingRoutingHelperService } from '@app/axos-trading/services';
import { STATE_PARAMS } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-exchange-agreement-iframe',
  templateUrl: './exchange-agreement-iframe.component.html',
  styleUrls: ['./exchange-agreement-iframe.component.scss'],
})
export class ExchangeAgreementIframeComponent implements OnInit, OnDestroy {
  get accountId() {
    return this.params['accountId'];
  }
  get from() {
    return this.params['from'];
  }

  get url() {
    return this.params['url'];
  }

  get stockId() {
    return this.params['stockId'];
  }

  exchangeAgreement: ExchangeAgreement[];
  isNotYetComplete = true;

  urlSafe: SafeResourceUrl;

  icons = {
    backIcon: NavigationIcons.ArrowBack,
  };

  interval: Subscription;
  title = 'Exchange Agreement(s)';
  private subSink = new SubSink();
  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private routingHelper: TradingRoutingHelperService,
    private sanitizer: DomSanitizer,
    private exchangeAgreementService: ExchangeAgreementService
  ) {}
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
    this.stopTimer();
  }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.startTimer();
  }

  goBack(): void {
    this.stopTimer();

    this.from === 'Portfolio'
      ? this.routingHelper.goToSDTOverview(this.accountId)
      : this.routingHelper.goToPositionStockDetail(this.accountId, this.stockId);
  }

  startTimer() {
    this.interval = interval(10000).subscribe(() => this.getExchangeAgreement());
  }

  stopTimer() {
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

  private getExchangeAgreement() {
    this.subSink.sink = this.exchangeAgreementService.getExchangeAgreement().subscribe(result => {
      this.exchangeAgreement = result.filter(e => !e.signed);
      this.isNotYetComplete = this.exchangeAgreement.length > 0;

      if (!this.isNotYetComplete) {
        this.goBack();
      }
    });
  }
}
