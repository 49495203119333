export const AlertNotificationComponent: ng.IComponentOptions = {
  controller: 'AlertNotificationController',
  controllerAs: '$an',
  templateUrl: 'shared/alert-notification/alert-notification.tpl.html',
  transclude: { message: '?message' },
  bindings: {
    message: '<',
    onClose: '&',
    type: '<?',
  },
};
