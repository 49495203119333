import { MyAlertsController } from './my-alerts.controller';
import { Inject } from '../../decorators/decorators';

@Inject('$stateProvider', 'treeConfig')
export class MyAlertsConfig {
  constructor(stateProvider: ng.ui.IStateProvider, treeConfig: any) {
    stateProvider.state('udb.alertsNotifications2.myAlerts', {
      url: '/MyAlerts',
      views: {
        'alerts-notifications': {
          templateUrl: 'alerts-notifications/my-alerts/my-alerts.tpl.html',
          controller: MyAlertsController,
          controllerAs: '$mac',
        },
      },
    });

    // Added custom class for dragged alert component
    treeConfig.dragClass = 'angular-ui-tree-drag alert-drag-element';
    treeConfig.placeholderClass = 'angular-ui-tree-placeholder alert-drag-placeholder';
  }
}
