import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartSelectionItem } from '@app/pfm/models';
import { FileFormats } from '@app/transaction-history/models/fileFormats';
import { SubSink } from '@axos/subsink';
import { IScope } from 'angular';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import {
  DialogService,
  OlbEventService,
  SvgIconHelperService,
  TransactionService,
} from '@core/services';
import { TransactionHistoryService } from '@app/transaction-history/services';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';
import { OlbEvents } from '@core/enums';
import { ApiFileExtensions } from '@app/transaction-history/enums/apiFileExtensions.enum';
import { FileExtensions } from '@app/transaction-history/enums/fileExtensions.enum';
import { pfmChartItemsConst } from '@app/pfm/constants';
import { TxCheckDetailsModalComponent } from '../modals';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { FiltersHelperService } from './services';
import { DisputeRedirectionDataTx, TxFilter } from './types';
import { ServiceHelper } from '@legacy/services/service.helper';
import { Transaction } from './types';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { AccountDetail } from '@app/accounts/models';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-account-transactions',
  templateUrl: './account-transactions.component.html',
  styleUrls: ['./account-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountTransactionsComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() account: OlbAccount;
  @Input() accountDetails: AccountDetail;
  isLoading = true;
  isDownloading = false;
  reverse: boolean;
  orderBy: number;
  filters: TxFilter;
  // property used to preserve initial filters property/values, then used to trigger filters changes
  showModal = false;
  boundFilters: TxFilter;
  showMoreInfo = false;
  tagFilters: GenericOption[];
  listeners: Function[] = [];
  filtersApplied: string[] = [];
  filteredTx: Transaction[];
  filteredTxWithAllCategories: Transaction[];
  isAccountAggregationForInternalsActive = false;
  displayFilters = false;
  isPfm2Enabled = false;
  insightsChartTypes: ChartSelectionItem[];
  isInsightsOpen = false;

  // SBB functionality
  isBusinessAccount = false;
  isSBBAccountsInPlanVerticalEnabled: boolean = false;
  isSBBActive: boolean = false;
  shouldHideInsights: boolean = false;

  insightsIcon: SafeHtml = '';
  filtersIcon: SafeHtml = '';
  isNinthWaveWebConnectActive: boolean = false;
  private fileFormatInfo: FileFormats;

  private destroy$ = new Subject<void>();
  private subsink = new SubSink();

  constructor(
    @Inject('$scope') private $scope: IScope,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private transactionService: TransactionService,
    private serviceHelper: ServiceHelper,
    private _filtersHelperService: FiltersHelperService,
    private _featureFlagService: FeatureFlagService,
    private olbEventService: OlbEventService,
    private transactionConf: TransactionHistoryService,
    private dialogService: DialogService,
    private svgIconHelper: SvgIconHelperService,
    @Inject(STATE) private readonly state: IStateService
  ) {}

  /** Initializes any controller required data. */
  ngOnInit(): void {
    const accountProfile = JSON.parse(
      sessionStorage.getItem('currentAccountProfile')
    ) as AccountProfile;

    if (accountProfile?.profileType === AccountProfileType.Business)
      this.isBusinessAccount = true;

    this.isAccountAggregationForInternalsActive = this._featureFlagService.isAccountAggregationForInternalsActive();
    this.isPfm2Enabled = this._featureFlagService.isPFM2FlagActive();
    this.isSBBAccountsInPlanVerticalEnabled = this._featureFlagService.isSBBAccountsInPlanVerticalActive();
    this.isSBBActive = this._featureFlagService.isSBBActive();

    // initialized filters
    if (this.isAccountAggregationForInternalsActive) {
      this.filters = {
        categories: [],
        query: '',
        transactionType: { value: 0, subvalue: '', label: 'All transactions' },
        days: { value: 90, label: 'Past 90 days' },
        dateRange: { start: null, end: null },
        amountRange: { min: null, max: null },
        checkRange: { min: null, max: null },
        advancedOption: { value: -1, label: 'Advanced Options' },
      };
    } else {
      this.filters = {
        query: '',
        amount: null,
        check: null,
        transactionType: { value: 0, subvalue: '', label: 'All transactions' },
        days: { value: 90, label: 'Past 90 days' },
        amountRange: { min: null, max: null },
        checkRange: { min: null, max: null },
        dateRange: { start: null, end: null },
        advancedOption: { value: -1, label: 'Advanced Options' },
      };
    }

    this.boundFilters = { ...this.filters };

    this.tagFilters = [];
    this.isLoading = true;

    this.setupPfm();

    this.subsink.sink = this.olbEventService.on(
      OlbEvents.TxRedirectionEvent,
      () => {
        if (!!this.root['txDisputeData']) {
          this.root['txDisputeData'].filters = this.filters;
          this.root['txDisputeData'].tagFilters = this.tagFilters;
        }
      }
    );

    /*listening the information that is sent from download file menu */
    this.transactionConf.clickOnDownload$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.fileFormatInfo = data;
        this.isDownloading = true;
        this.selectTransactionsFileToDownload();
      });
    this.loadIcons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isBusinessAccount = changes.account?.currentValue?.isSBB;
    this.setShouldHideInsight();
  }

  /** Cleans up the controller. */
  ngOnDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
    this.subsink.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSaveFiltersLoaded(disputeData: DisputeRedirectionDataTx) {
    this.filters = disputeData.filters;
    this.tagFilters = disputeData.tagFilters;
  }

  onOver2000(showMore: boolean) {
    this.showMoreInfo = showMore;
  }

  onRecategorizeEvent() {
    // shallow clone transaction arrays, the new references will trigger onChanges
    //   in components that depend on them
    this.filteredTx = [...this.filteredTx];
    this.filteredTxWithAllCategories = [...this.filteredTxWithAllCategories];
  }

  /** Sets the filters when they are modified in the filters component */
  changeFilters(filters: TxFilter, filtersApplied: string[]) {
    this.filtersApplied = this.convertToArray(filtersApplied);
    this.filters = { ...filters };
    this.boundFilters = JSON.parse(JSON.stringify(this.filters));
  }

  /**
   * This methods responds to 'close button on Tag element'
   * removes the filter then triggers the filter update fn
   * @param filterName Filter to be removed from the tags
   */
  clearFilter(filterName: string) {
    this.filters = {
      ...this._filtersHelperService.resetFilter(filterName, this.filters),
    };
    this.boundFilters = {
      ...this._filtersHelperService.resetFilter(filterName, this.boundFilters),
    };
    this.removeFilterApplied(filterName);
    this.changeFilters(this.filters, this.filtersApplied);
  }

  convertToArray(filtersObj: any) {
    if (Array.isArray(filtersObj)) return filtersObj;
    if (!filtersObj || Object.keys(filtersObj).length === 0) return [];
    const array: any[] = [];
    for (const item in filtersObj) {
      if (!isNaN(parseInt(item))) array.push(filtersObj[item]);
    }

    return array;
  }

  /**
   * Delegate that reflects changes on transactions
   * @param transactions Filtered transactions
   */
  onUpdateTransactions(
    transactions: Transaction[],
    transactionsWithAllCategories: Transaction[]
  ) {
    this.isLoading = false;
    this.filteredTx = transactions;
    this.filteredTxWithAllCategories = transactionsWithAllCategories;
  }

  toggleCategoryFilter(event) {
    this.$scope.$broadcast('toggleCategoryFilter', event.categoryId);
  }

  /**
   * Invokes the modal to see the details of the check
   * @param trxn Transaction object
   */
  getCheck(trxn: Transaction) {
    if (!trxn) return;
    this.dialogService.openByComponentRef(TxCheckDetailsModalComponent, {
      disableClose: true,
      panelClass: ['modal-dialog', 'check-details-modal'],
      maxWidth: 700,
      width: '700px',
      position: {
        top: '0',
      },
      data: {
        transaction: trxn,
        accountId: this.account.id,
      },
    });
  }

  setFilterValue(value: any, propertyName: string) {
    // recreate filters to trigger onChanges event on transactions-tag controller
    this.filters = this._filtersHelperService.setFilterValue(
      this.filters,
      value,
      propertyName
    );
    this.boundFilters = JSON.parse(JSON.stringify(this.filters));
  }

  /**
   * Sets the sorted var used in the download action
   * @param orderBy column selected in the order by
   * @param reverse If the sorting is reverse
   */
  setSortValues(orderBy: number, reverse: boolean) {
    this.orderBy = orderBy;
    this.reverse = reverse;
  }

  /** Emits an event to notify a tab for next payment should be selected */
  notifyNextPaymentTabIsActivated(): void {
    this.$scope.$emit('nextPaymentTabSelected', 2);
  }

  /** Handles the download button click */
  downloadTransactionsFile(): void {
    this.isNinthWaveWebConnectActive = this._featureFlagService.isNinthWaveWebConnectActive();
    if (this.isNinthWaveWebConnectActive) {
      this.state.go('udb.accounts.download', {
        id: this.account.id,
      });
      return;
    }
    this.isDownloading = true;
    const transaction: IDownloadTransactionsRequest = {
      AccountId: this.account.id,
      TransactionType: this.filters.transactionType.subvalue,
      StartDate: this.filters.dateRange.start
        ? this.filters.dateRange.start
        : moment().subtract(this.filters.days.value, 'days').toDate(),
      EndDate: this.filters.dateRange.end
        ? this.filters.dateRange.end
        : moment().toDate(),
      ColumnOrder: this.orderBy ? this.orderBy : 0,
      Reverse: this.reverse,
      Query: this.filters.query,
      AmountRangeMin: this.filters.amountRange.min,
      AmountRangeMax: this.filters.amountRange.max,
      CheckRangeMin: this.filters.checkRange.min,
      CheckRangeMax: this.filters.checkRange.max,
      AmountValue: this.filters.amount,
      CheckValue: this.filters.check ? this.filters.check.toString() : '',
    };

    this.transactionService
      .downloadTransactionsFile(transaction, ApiFileExtensions.Xlsx)
      .pipe(
        tap(blob => {
          const accountNumber = this.account.accountNumber.toString();
          FileSaver.saveAs(
            blob,
            `${this.account.productType}_${accountNumber.substr(
              accountNumber.length - 4
            )}_txns.${FileExtensions.Xlsx}`
          );
        }),
        catchError(err => {
          this.serviceHelper.errorHandler(err);
          return of(err);
        }),
        finalize(() => {
          this.isDownloading = false;
        })
      )
      .subscribe();
  }

  /* shows  the selection file format download menu */
  selectTransactionsFileToDownload(): void {
    const transaction: IDownloadTransactionsRequest = {
      AccountId: this.account.id,
      TransactionType: this.filters.transactionType.subvalue,
      StartDate: this.filters.dateRange.start
        ? this.filters.dateRange.start
        : moment().subtract(this.filters.days.value, 'days').toDate(),
      EndDate: this.filters.dateRange.end
        ? this.filters.dateRange.end
        : moment().toDate(),
      ColumnOrder: this.orderBy ? this.orderBy : 0,
      Reverse: this.reverse,
      Query: this.filters.query,
      AmountRangeMin: this.filters.amountRange.min,
      AmountRangeMax: this.filters.amountRange.max,
      CheckRangeMin: this.filters.checkRange.min,
      CheckRangeMax: this.filters.checkRange.max,
      AmountValue: this.filters.amount,
      CheckValue: this.filters.check ? this.filters.check.toString() : '',
    };

    this.transactionService
      .downloadTransactionsFile(
        transaction,
        this.fileFormatInfo.apiFileExtension
      )
      .pipe(
        tap(blob => {
          if (this.fileFormatInfo) {
            const accountNumber = this.account.accountNumber.toString();
            FileSaver.saveAs(
              blob,
              `${this.account.productType}_${accountNumber.substr(
                accountNumber.length - 4
              )}_txns.${this.fileFormatInfo.extension}`
            );
          }
        }),
        catchError(err => {
          this.serviceHelper.errorHandler(err);
          return of(err);
        }),
        finalize(() => {
          this.isDownloading = false;
        })
      )
      .subscribe();
  }

  setShouldHideInsight() {
    if (this.isBusinessAccount !== undefined) {
      if (this.isBusinessAccount === false) {
        this.shouldHideInsights = false;
      } else if (
        this.isBusinessAccount === true &&
        this.isSBBActive === true &&
        this.isSBBAccountsInPlanVerticalEnabled === true
      ) {
        this.shouldHideInsights = false;
      } else {
        this.shouldHideInsights = true;
      }
    }
  }

  toggleFilterModal() {
    this.showModal = !this.showModal;
  }

  /**
   * Re,pve the filter from the applied filters and notify other components subscribed to this
   * @param filterName The filter to be removed
   */
  private removeFilterApplied(filterName: string) {
    this.filtersApplied = this.convertToArray(this.filtersApplied);
    const index = this.filtersApplied.indexOf(filterName);
    if (index >= 0) {
      this.filtersApplied.splice(index, 1);
    }
    this.root.$emit('filtersAppliedChange', filterName);
  }

  private setupPfm() {
    this.insightsChartTypes = pfmChartItemsConst;
  }

  private loadIcons() {
    this.svgIconHelper
      .loadSvgIcon('filters', 'insights-white')
      .subscribe(icon => (this.insightsIcon = icon));
    this.svgIconHelper
      .loadSvgIcon('filters', 'filter-white')
      .subscribe(icon => (this.filtersIcon = icon));
  }
}
