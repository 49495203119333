<app-dot-loader *ngIf="isLoadingPortfolioBalance">Loading Portfolio</app-dot-loader>
<div class="flex" *ngIf="!isLoadingPortfolioBalance">
  <ng-container *ngIf="portfolioBalance && portfolioBalance.length !== 0; else emptyState">
    <app-historic-performance-chart
      [data]="portfolioBalance"
      [filter]="activeFilter"
      [account]="account"
    ></app-historic-performance-chart>
    <div class="text-right mt-4">
      <ul>
        <li *ngFor="let filter of filters" (click)="changeGraph(filter)">
          <span class="menu" [class.active]="activeFilter.id === filter.id">
            {{ filter.label }}
          </span>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-template #emptyState>
    <div class="container mt-5 mb-5">
      <div class="text-center">Fund your account and start investing to see your performance here.</div>
      <div class="d-flex justify-content-center">
        <button type="button" class="emptyState mt-2 btn-link view-all row no-underline" (click)="goToFundPage()">
          Add Funds
          <app-svg-icon class="ml-2" [iconName]="icons.arrowForward"></app-svg-icon>
        </button>
      </div>
    </div>
  </ng-template>
</div>
