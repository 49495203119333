import { AccountDetailsController } from './account-details.controller';

export const accountDetails: ng.IComponentOptions = {
  controller: AccountDetailsController,
  templateUrl: 'accounts/account-details/account-details-deposits.tpl.html',
  bindings: {
    account: '<',
    accountId: '@',
    category: '<',
  },
};

export const accountDetailsMortgage: ng.IComponentOptions = {
  controller: AccountDetailsController,
  templateUrl: 'accounts/account-details/account-details-mortgage.tpl.html',
  bindings: {
    account: '<',
    accountId: '@',
    category: '<',
  },
};
