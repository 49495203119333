<footer>
  <div class="footer__content">
    <div *ngIf="brand === 'axos'; then udbRights; else actions"></div>
    <div *ngIf="brand === 'axos'; then udbBodyPart1; else bodyPart1"></div>
    <div *ngIf="brand === 'axos'; then udbBodyPart2; else bodyPart2"></div>
  </div>
  <div class="footer__content">
    <div *ngIf="brand === 'axos'; then actions; else nmlButton"></div>
    <div *ngIf="brand === 'axos'; then udbImages; else rights"></div>
  </div>
  <div *ngIf="brand === 'axos'; then speedBump; else speedBump"></div>
</footer>

<!--Templates-->

<ng-template #actions>
  <div class="col menu">
    <button (click)="changeState('udb.support')" *ngIf="showButton" class="support-btn">
      SUPPORT
    </button>
    <a rel="noferrer noopener" target="_blank" [href]="privacyUrl">
      PRIVACY & SECURITY
    </a>

    <a rel="noferrer noopener" target="_blank" [href]="disclosuresUrl">
      DISCLOSURES
    </a>

    <button type="button" (click)="openCobrowseSession()" class="cobrowse-btn">
      START A COBROWSE SESSION
    </button>
  </div>
</ng-template>

<ng-template #bodyPart1>
  <div class="col" *ngIf="isAxosInvestActive || isAxosTradingActive || isProxyRegistration">
    <p>
      Advisory accounts are offered by Axos Invest, Inc., a registered investment advisor. Brokerage services are
      provided by Axos Invest LLC, member FINRA/SIPC.
      <span class="remark">
        Please Remember, investment products are NOT INSURED by the FDIC or any government agency; NOT a deposit or
        obligation of, or guaranteed by Axos Bank, N.A, or any affiliate, and MAY be subject to investment risks
        including the possible loss of the principal amount you invested.
      </span>
      All cash and securities held in Axos Invest client accounts are protected by SIPC up to $500,000, with a limit of
      $250,000 for cash. For more information
      <button (click)="openContinueToPageModal('https://www.sipc.org/for-investors/')" class="links">
        click here.
      </button>
    </p>
    <p class="dagger-axos-invest" *ngIf="!isProxyRegistration">
      <sup>&dagger;</sup>
      The target date projection is based on a hypothetical tool that demonstrates how recurring investments and changes
      in market performance may affect the long-term value of your account(s). The projections are calculated from a
      combination of an annualized auto deposit increase of 2%, a reinvestment of all dividends earned, an inflation
      rate of 2%, and historical performance of the ETFs selected during the previous 10-year period, but does not
      reflect the impact that material economic or market factors might have on the portfolio. Please keep in mind, the
      information provided by these projections is for illustrative purposes only and does not represent actual or
      future performance of any investment option, nor is it intended to predict or project the future investment
      performance of any security, index or portfolio.
    </p>
  </div>
</ng-template>

<ng-template #bodyPart2>
  <div class="col">
    <p *ngIf="brand !== 'nationwide'">
      Bank products and services are offered by
      <button (click)="openContinueToPageModal('https://www.axosbank.com/')" class="links">
        Axos Bank
      </button>
      <span class="footer__trademark" (click)="openContinueToPageModal('https://www.axosbank.com/')">&reg;</span>
      <span>.</span>
      All deposit accounts through Axos Bank brands are FDIC insured through Axos Bank. All deposit accounts of the same
      ownership and/or vesting held at Axos Bank are combined and insured under the same
      <button (click)="openContinueToPageModal('https://edie.fdic.gov/')" class="links">
        FDIC Certificate 35546.
      </button>
      All deposit accounts through Axos Bank brands are not separately insured by the FDIC from other deposit accounts
      held with the same ownership and/or vesting at Axos Bank. For more information
      <button (click)="openContinueToPageModal('https://www.axosbank.com/Legal/FDIC-Notice')" class="links">
        {{ clickHereOrReadOurFDICNotice }}
      </button>
    </p>

    <p *ngIf="brand === 'nationwide'">
      Nationwide’s relationship with {{ poweredByBrandName }} gives Nationwide members access to
      {{ poweredByBrandName }}’s banking products and services. All banking products and services are solely offered and
      provided by {{ poweredByBrandName }}&reg;. {{ poweredByBrandName }} is a federally chartered savings bank, and all
      deposit accounts opened with {{ poweredByBrandName }}, including those of the same ownership and / or vesting held
      at {{ poweredByBrandName }}, are combined and insured by the FDIC under the same
      <button (click)="openContinueToPageModal('https://edie.fdic.gov/')" class="links">
        <span>FDIC Certificate 35546.</span>
      </button>
      For more information
      <a rel="noferrer noopener" target="_blank" [href]="fdic" class="links" aria-label="More information">
        read our FDIC Notice.
      </a>
      <br />
      <br />
      Nationwide and Axos have engaged in a limited marketing relationship, where
      {{ poweredByBrandName }} may make special offers to Nationwide members. Nationwide is not a bank or a lender and
      is not affiliated with {{ poweredByBrandName }} or any other bank, except through this limited arrangement.
      Nationwide does not take applications, offer, negotiate, arrange or make loans or accept deposits from the public.
      Nationwide does not guarantee any products or services offered by {{ poweredByBrandName }}. Nationwide does not
      endorse and is not responsible for any of the activities of {{ poweredByBrandName }}. Nationwide receives a fee
      from {{ poweredByBrandName }} for its marketing and advertising efforts arising from this relationship with
      {{ poweredByBrandName }}, although for certain of these products (such as any deposits or CDs you may open with
      Axos) Nationwide will not receive any such fees. Any fees paid to Nationwide by {{ poweredByBrandName }} for these
      marketing activities will not result in increased fees for the products or services Nationwide members receive
      from {{ poweredByBrandName }}.
      <br />
      <br />
      Programs (including, without limit, fees, rates and features) are subject to change without notice. Specific costs
      or fees for a banking product or service received from
      {{ poweredByBrandName }} may apply and will be provided in a separate disclosure to you.
      <br />
      <br />
      ©{{ date | date: 'yyyy' }} Nationwide, the Nationwide N and Eagle and Nationwide is on your side are service marks
      of Nationwide Mutual Insurance Company.
    </p>
  </div>
</ng-template>

<ng-template #nmlButton>
  <div class="col nmls">
    <p>
      {{ poweredByBrandName }}
      <button (click)="openContinueToPageModal()" class="links">
        NMLS# 524995
      </button>
    </p>
  </div>
</ng-template>

<ng-template #rights>
  <div class="footer__copyright col">
    <span class="footer__copyright-text">
      © {{ date | date: 'yyyy' }} {{ umbrellaBrand }}, All Rights Reserved. #{{ appVersion }}
    </span>
    <div class="footer__links">
      <a
        [href]="ehl"
        rel="noferrer noopener"
        target="_blank"
        class="footer__link"
        aria-label="Equal housing lender"
        rel="noopener"
      >
        <img class="ehl" src="assets/img/logos/ehl-w-logo.png" alt="Equal housing lender" />
      </a>
      <a
        [href]="fdic"
        rel="noferrer noopener"
        target="_blank"
        class="footer__link"
        aria-label="FDIC Notice"
        rel="noopener"
      >
        <img class="fdic" src="assets/img/logos/fdic-w-logo.png" alt="FDIC notice" />
      </a>
    </div>
  </div>
</ng-template>

<ng-template #udbRights>
  <div class="footer__copyright col">
    <span class="footer__copyright-text">
      <p>
        © {{ date | date: 'yyyy' }} Axos Financial, Inc., All Rights Reserved. © {{ date | date: 'yyyy' }} Axos Bank,
        All Rights Reserved. © {{ date | date: 'yyyy' }} Axos Invest LLC. Member FINRA & SIPC. All Rights Reserved. ©
        {{ date | date: 'yyyy' }} Axos Invest, Inc. All Rights Reserved.
      </p>
    </span>
  </div>
</ng-template>

<ng-template #udbImages>
  <div class="footer__copyright col">
    <span class="footer__copyright-text">
      © {{ date | date: 'yyyy' }} {{ umbrellaBrand }}, All Rights Reserved. #{{ appVersion }}
    </span>
    <div class="footer__udbLinks">
      <a
        [href]="ehl"
        rel="noferrer noopener"
        target="_blank"
        class="footer__link"
        aria-label="Equal housing lender"
        rel="noopener"
      >
        <img class="ehl images_ehl" src="assets/img/logos/ehl-w-logo.png" alt="Equal housing lender" />
      </a>
      <a
        [href]="fdic"
        rel="noferrer noopener"
        target="_blank"
        class="footer__link"
        aria-label="FDIC Notice"
        rel="noopener"
      >
        <img class="fdic images_fdic" src="assets/img/logos/fdic-w-logo.png" alt="FDIC notice" />
      </a>
    </div>
  </div>
</ng-template>

<ng-template #udbBodyPart1>
  <div class="col">
    <p>
      <b>Bank products and services.</b>
      Axos Bank offers bank products and services. All deposit accounts through Axos Bank brands are FDIC insured
      through Axos Bank. All deposit accounts of the same ownership and/or vesting held at Axos Bank are combined and
      insured under the same
      <button (click)="openContinueToPageModal('https://edie.fdic.gov/')" class="links">
        FDIC Certificate 35546.
      </button>
      All deposit accounts through Axos Bank brands are not separately insured by the FDIC from other deposit accounts
      held with the same ownership and/or vesting at Axos Bank. For more information read
      <button (click)="openContinueToPageModal(fdic)" class="links">
        Axos Bank’s FDIC Notice.
      </button>
      Axos Bank
      <button (click)="openContinueToPageModal('https://nmlsconsumeraccess.org/')" class="links">
        NMLS #524995.
      </button>
    </p>
    <p>
      <b>For customers of Axos Invest, Inc. and Axos Invest LLC.</b>
      Your advisory services are offered by Axos Invest, Inc., an investment advisor registered with the Securities and
      Exchange Commission. Your brokerage services are offered by Axos Invest LLC, member FINRA & SIPC.
    </p>
    <p>
      <b>For customers of other advisory or brokerage firms.</b>
      Your advisory services are provided by a third party registered investment advisor. Your brokerage services are
      offered by a third party broker-dealer. These services are not offered by Axos Invest, Inc. or Axos Invest LLC.
      Please contact your advisor or broker if you have questions concerning the investment advisory or brokerage
      services that you receive from them.
    </p>
    <p>
      <b>Clearing and custody services.&nbsp;</b>
      <button (click)="openContinueToPageModal('https://axosclearing.com/')" class="links">
        Axos Clearing LLC
      </button>
      provides clearing and custody services to both affiliated and third party registered investment advisors and
      broker-dealers. Axos Advisor Services is a division within Axos Clearing LLC that provides custody and related
      services to registered investment advisors. Axos Clearing LLC and its Axos Advisor Services division do not
      provide investment advice or make investment recommendations in any capacity. Axos Clearing LLC is a member of
      FINRA and SIPC.
    </p>
    <p>
      Securities and other non-deposit investment products and services are not deposits, obligations of or guaranteed
      by Axos Bank, are not insured by the FDIC or any governmental agency, and are subject to investment risk including
      possible loss of the principal invested.
    </p>
    <p>
      Axos Bank, Axos Invest, Inc., Axos Invest LLC, and Axos Clearing LLC are separate companies commonly owned by Axos
      Financial, Inc.
    </p>
  </div>
</ng-template>

<ng-template #udbBodyPart2>
  <div class="col">
    <p></p>
  </div>
</ng-template>

<ng-template #speedBump>
  <app-speedbump
    (displaySpeedBump)="openContinueToPageModal()"
    [url]="nmls"
    [brandName]="brandName"
    [displayUrl]="nmlsDisplayUrl"
    [otherUrl]="otherUrl"
    *ngIf="displaySpeedBump"
  ></app-speedbump>
</ng-template>
