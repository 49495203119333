<app-funding-shared-header></app-funding-shared-header>

<div class="wire-transfer">
  <div class="container">
    <div class="wire-transfer__header header-section-title text-center">
      <div>
        <h1>Wire Transfer</h1>
        <div id="padding-top-account" class="frow">
          <p class="wire-transfer__header-sub">
            {{
              isTradingAccount
                ? displayName
                : 'To wire funds into your account, go to your
            financial institution and provide the following information:'
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="frow center" *ngIf="isTradingAccount">
      <h4 class="text-center fcol-md-6">
        To wire funds into your account, provide your financial institution with the following instructions.
      </h4>
    </div>

    <div class="header-section-title__img text-center">
      <img [src]="imageSource" width="200" height="100" />
    </div>

    <div class="frow center" *ngIf="!isTradingAccount">
      <div class="fcol-md-4">
        <div class="address-details text-center">
          <section class="address-details__field">
            <h6 class="address-details__field-name">
              ABA/Routing Transit Number:
            </h6>
            <p>122287251</p>
          </section>

          <section class="address-details__field">
            <h6 class="address-details__field-name">Your Account Number:</h6>
            <p>{{ accountNumber }}</p>
          </section>

          <section class="address-details__field">
            <h6 class="address-details__field-name">Address:</h6>
            <p>Axos Bank</p>
            <p>P.O. Box 509127</p>
            <p>San Diego, CA 92150-9948</p>
          </section>
        </div>
      </div>
    </div>

    <div class="frow center" *ngIf="isTradingAccount">
      <table class="table table-striped table--outer-border fcol-md-5">
        <tr>
          <td class="text-left truncate">Full Name:</td>
          <td class="text-bold text-right">{{ fullName }}</td>
        </tr>
        <tr>
          <td class="text-left truncate">Your Account Number:</td>
          <td class="text-bold text-right">{{ accountNumber }}</td>
        </tr>
        <tr>
          <td class="text-left truncate">Wiring Address:</td>
          <td class="text-bold text-right">
            <p>BMO Harris Bank</p>
            <p>111 West Monroe Stret</p>
            <p>Chicago, IL 60690</p>
          </td>
        </tr>
        <tr>
          <td class="text-left truncate">ABA/Routing Transit Number:</td>
          <td class="text-bold text-right">071000288</td>
        </tr>
        <tr>
          <td class="text-left truncate">Swift:</td>
          <td class="text-bold text-right">HATRUS44</td>
        </tr>
        <tr>
          <td class="text-left truncate">Account Name:</td>
          <td class="text-bold text-right">Axos Clearing</td>
        </tr>
        <tr>
          <td class="text-left truncate">Account Number:</td>
          <td class="text-bold text-right">3174109</td>
        </tr>
      </table>
    </div>

    <div class="frow center">
      <div class="fcol-md-8 wire-transfer__note" *ngIf="!isTradingAccount">
        <span class="text-bold">Note:</span>
        Ensure the wire is to the benefit of your name and your account number. Wire cut-off time for same day
        processing is 1:00 p.m. (PST).
      </div>
      <div class="center wire-transfer__note" *ngIf="isTradingAccount">
        Incoming funds will typically be available as soon as the wire is received.
      </div>
    </div>

    <div class="frow center wire-transfer__footer">
      <div class="frow col-lg-5 centerButtons">
        <button type="button" class="uk-btn lg outline primary" (click)="redirectToFunding()">
          Back
        </button>
        <button (click)="goToDashboard()" type="button" class="uk-btn solid secondary lg">
          {{ isTradingAccount ? 'View Accounts' : 'Continue' }}
        </button>
      </div>
    </div>
  </div>
</div>
