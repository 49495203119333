import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { concat, EMPTY } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import {
  createBeneficiaryForm,
  createSpousalConsentForm,
  mapBaseBeneficiaryToCommon,
  mapCommonToBaseBeneficiary,
  mapCommonToSpousalBeneficiary,
  mapCommonToTaxPlanBeneficiary,
  mapTaxPlanBeneficiaryToCommon,
} from '@app/accounts/utils';
import { UserProfileViews } from '@app/user-profile/enums';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { OlbResponse } from '@core/models';
import { AccountsService, DialogService, PropertyBagService } from '@core/services';
import { TaxPlanBeneficiariesService } from '@core/services/tax-plan-beneficiaries.service';
import { olbSettings, STATE, STATE_PARAMS } from '@core/tokens';
import { BeneficiariesIraEnhHelper, ToastType } from '@legacy/services/beneficiaries-iraenh.helper';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AlertsIcons, BeneficiaryType, NavigationIcons, UtilityIcons } from '@shared/enums';
import { BeneficiaryCommon, dialogConfig, DialogData } from '@shared/models';

import { BeneficiaryAccountType } from '../../enums';
import { AccountDetail, Beneficiary, SpousalConsent, TaxPlanBeneficiary } from '../../models';
import { SpousalConsentModalComponent } from '../modals';

@Component({
  selector: 'app-account-beneficiaries',
  templateUrl: './account-beneficiaries.component.html',
  styleUrls: ['./account-beneficiaries.component.scss'],
})
export class AccountBeneficiariesComponent implements OnInit, OnDestroy {
  // #region <Properties>
  isLoadingAccount: boolean;
  isLoadingRelationshipCatalog: boolean;
  account: AccountDetail;
  accountId: number;
  isDeposit = false;
  accountType: BeneficiaryAccountType;
  beneficiariesType: BeneficiaryType;
  prefix: string;
  isCustomerMarried: boolean;
  beneficiaries: BeneficiaryCommon[] = [];
  beneficiariesBackup: BeneficiaryCommon[];
  contingencyBeneficiariesBackup: BeneficiaryCommon[];
  relationshipCatalog: Record<string, string>;
  beneficiariesForm: UntypedFormGroup;
  spousalConsentForm: UntypedFormGroup;
  beneficiariesFormArray: UntypedFormArray;
  isSpousalConsentNeeded: boolean;
  isSpousalConsentMayNeeded: boolean;
  tooltip = 'The total of all ${type}beneficiaries must equal 100%.';
  toastSuccessMsg = 'You have successfully modified the beneficiaries on your account.';
  toastErrorMsg = 'Some of the beneficiaries could not be saved, please verify the beneficiaries on your account.';
  totalShare = 0;
  hasMultipleSpousesError = false;
  spousesCount = 0;
  executeRelationshipChangeFlag = true;
  spouseRelationshipId = 'Spouse';
  isEditingCurrentBeneficiaries = false;
  changedRelationship: {
    index: number;
    previous: string;
  };
  submitAttempted = false;
  isSaving = false;
  otherOptionsOpen = false;
  errors = {
    spousalConsent: {
      firstName: [{ name: 'required', message: 'Spouse First Name is required.' }],
      lastName: [{ name: 'required', message: 'Spouse Last Name is required.' }],
      email: [
        { name: 'required', message: 'Spouse Email is required.' },
        { name: 'pattern', message: 'Spouse Email is invalid.' },
      ],
    },
  };
  icons = {
    add: UtilityIcons.Plus,
    check: AlertsIcons.CheckCircle,
    ex: AlertsIcons.ExCircle,
    arrow: NavigationIcons.ChevronLeft,
    up: NavigationIcons.ChevronUp,
    down: NavigationIcons.ChevronDown,
  };
  currentShareState = { icon: AlertsIcons.CheckCircle, color: 'var(--success)' };
  private subsink = new SubSink();
  private shareStates = {
    ok: { icon: AlertsIcons.CheckCircle, color: 'var(--success)' },
    error: { icon: AlertsIcons.ExCircle, color: 'var(--danger)' },
  };

  // #endregion <Properties>

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private store: Store,
    private fb: UntypedFormBuilder,
    private beneficiariesIraEnhHelper: BeneficiariesIraEnhHelper,
    private accountsService: AccountsService,
    private dialogService: DialogService,
    private serviceHelper: ServiceHelper,
    private taxPlanBeneficiariesService: TaxPlanBeneficiariesService,
    private spousalConsentService: PropertyBagService
  ) {}

  ngOnInit(): void {
    this.accountId = this.params.id;
    this.isDeposit = this.params.isDeposit;
    this.beneficiariesType = this.state.params?.contingent ? BeneficiaryType.Contingent : BeneficiaryType.Primary;

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.isCustomerMarried = data.maritalStatus.toLowerCase() === 'married';
      });

    this.formInitializer();

    this.isLoadingAccount = true;
    this.accountsService
      .getAccountDetails(this.accountId)
      .pipe(
        finalize(() => {
          this.isLoadingAccount = false;
        })
      )
      .subscribe({
        next: (resp: OlbResponse<AccountDetail>) => {
          this.account = resp.data;
          this.setAccountParameters();
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  /**
   * If customer is married or it has a beneficiary with Spouse relationship
   * and isSpousalConsentNeeded is false then the prompt will be always displayed
   */
  addBeneficiaryFromUI() {
    if (
      (this.isCustomerMarried || (this.spousesCount && this.isSpousalConsentMayNeeded)) &&
      !this.isSpousalConsentNeeded &&
      this.beneficiariesType !== BeneficiaryType.Contingent &&
      !this.isDeposit
    ) {
      this.spousalConsentPrompt(null, true);
    } else {
      this.addBeneficiary();
    }
  }

  goBackToAccountDetailsPrompt() {
    const observer = (result: boolean) => (result ? this.goBackToAccountDetails() : null);
    const dialogData = new DialogData({
      title: '',
      okText: 'Ok',
      cancelText: 'Cancel',
      icon: AlertsIcons.QuestionCircle,
      content: `
        <h3>Back to Account Details?</h3></br>
        <p>Your changes won't be saved if you leave</p>
      `,
    });

    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;
    this.subsink.sink = this.dialogService.openCustom(dialogConfig).afterClosed().subscribe(observer);
  }

  goBackToAccountDetails() {
    this.state.go('udb.accounts.details', { id: this.accountId, tab: 1 });
  }

  goToPersonalInformation() {
    this.state.go('udb.userProfile', { view: UserProfileViews.PersonalInformation });
  }

  removeBeneficiaryByIndex(index: number) {
    if (this.beneficiariesFormArray.length === 1) {
      this.removeLastBeneficiary(index);
    } else {
      this.beneficiariesFormArray.removeAt(index);
    }
  }

  getSpousalConsentObject(): SpousalConsent {
    return new SpousalConsent({
      facingBrandId: this.env.facingBrandId,
      cif: this.account.cif,
      taxPlanType: this.account.taxPlanType,
      firstName: this.spousalConsentForm.get('firstName').value,
      lastName: this.spousalConsentForm.get('lastName').value,
      fullName: `${this.spousalConsentForm.get('firstName').value} ${this.spousalConsentForm.get('lastName').value}`,
      email: this.spousalConsentForm.get('email').value,
      spousalBeneficiaries: this.beneficiariesFormArray.value.map((b: BeneficiaryCommon) =>
        mapCommonToSpousalBeneficiary(b)
      ),
    });
  }

  submit() {
    this.submitAttempted = true;

    if (!this.isSpousalConsentNeeded && this.isEditingCurrentBeneficiaries) {
      const isSpousalConsentNeeded =
        this.isCustomerMarried && this.beneficiariesType !== BeneficiaryType.Contingent && !this.isDeposit;
      if (isSpousalConsentNeeded) {
        this.spousalConsentPrompt();

        return;
      }
    }

    if (!this.isSpousalConsentNeeded) {
      this.beneficiariesForm.removeControl('spousalConsent');
    }
    if (this.totalShare !== 100 || this.beneficiariesForm.invalid || this.hasMultipleSpousesError) {
      this.beneficiariesForm.markAllAsTouched();

      return;
    }

    this.save();
  }

  setEditingCurrentBeneficiaries() {
    this.isEditingCurrentBeneficiaries = true;
  }

  private formInitializer(): void {
    this.beneficiariesForm = this.fb.group({
      beneficiaries: this.fb.array([]),
    });
    this.beneficiariesFormArray = this.beneficiariesForm.get('beneficiaries') as UntypedFormArray;
    this.subsink.sink = this.beneficiariesFormArray.valueChanges.subscribe((controls: BeneficiaryCommon[]) => {
      this.totalShare = controls.reduce((sum, beneficiary) => {
        return sum + +beneficiary.percent;
      }, 0);

      this.currentShareState = this.totalShare === 100 ? this.shareStates.ok : this.shareStates.error;

      if (this.changedRelationship) {
        this.onRelationshipChange(this.changedRelationship);
      }

      const spouses: number[] = [];
      controls.forEach((item, index) => {
        const isSpouse = item.relationship.toLowerCase() === 'spouse';
        if (isSpouse) {
          spouses.push(index);
        }
      });

      this.spousesCount = spouses.length;
      this.hasMultipleSpousesError = spouses.length > 1;
    });
  }

  private onRelationshipChange(data: { index: number; previous: string }) {
    if (this.executeRelationshipChangeFlag) {
      const isSpousalConsentNeeded = this.getIsSpousalConsentNeeded();
      if (isSpousalConsentNeeded && !this.isSpousalConsentNeeded) {
        const ben = this.beneficiariesFormArray.at(data.index);
        const previous = data.previous;
        this.spousalConsentPrompt({ ben, previous });
      }
    } else {
      this.executeRelationshipChangeFlag = true;
    }
  }

  private getIsSpousalConsentNeeded(): boolean {
    if (this.beneficiariesType === BeneficiaryType.Contingent || this.isDeposit) {
      return false;
    }

    const firstBeneficiary = this.beneficiariesFormArray.at(0).value as BeneficiaryCommon;
    if (this.isSpousalConsentMayNeeded) {
      if (this.isCustomerMarried) {
        if (this.beneficiariesFormArray.length > 1) return true;
        if (this.beneficiariesFormArray.length === 1 && firstBeneficiary.relationship !== this.spouseRelationshipId) {
          return true;
        }
      } else if (
        this.beneficiariesFormArray.value.find((b: BeneficiaryCommon) => b.relationship === this.spouseRelationshipId)
      ) {
        return true;
      }
    }

    if (
      this.isSpousalConsentNeeded &&
      this.beneficiariesFormArray.length === 1 &&
      [this.spouseRelationshipId, ''].includes(firstBeneficiary.relationship)
    ) {
      this.isSpousalConsentNeeded = false;
    }

    return false;
  }

  private addBeneficiary(beneficiary?: BeneficiaryCommon) {
    const percent = !this.beneficiariesFormArray.length ? 100 : null;
    beneficiary = beneficiary ?? new BeneficiaryCommon({ level: this.beneficiariesType, percent });
    this.beneficiariesFormArray.push(createBeneficiaryForm(beneficiary, this.accountType));
  }

  // Only show the modal when going from a 'not needed' to a 'needed' state.
  //  Since this keeps track of the 'needed' state you need to call this function
  //  every time you add/remove beneficiaries or change the relationshipId
  private spousalConsentPrompt(
    dataFromChange?: { ben: AbstractControl; previous: string },
    addNewBeneficiary?: boolean
  ) {
    const dialogData = new DialogData({
      title: 'Request spousal consent?',
      icon: AlertsIcons.QuestionCircle,
      component: new ComponentPortal(SpousalConsentModalComponent),
    });
    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;
    this.subsink.sink = this.dialogService
      .openCustom(dialogConfig)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.addSpousalConsentForm();
          this.isSpousalConsentNeeded = true;
          if (addNewBeneficiary) {
            this.addBeneficiary();
          }
        } else {
          this.executeRelationshipChangeFlag = false;
          if (dataFromChange) {
            dataFromChange.ben.patchValue({ relationship: dataFromChange.previous });
          }
        }
      });
  }

  private addSpousalConsentForm() {
    const newSpousalConsentForm = createSpousalConsentForm();
    this.beneficiariesForm.addControl('spousalConsent', newSpousalConsentForm);
    this.spousalConsentForm = this.beneficiariesForm.get('spousalConsent') as UntypedFormGroup;
  }

  private setAccountParameters() {
    this.accountType = this.account.taxPlanType == null ? BeneficiaryAccountType.Base : BeneficiaryAccountType.Dira;
    let text = '';

    if (this.accountType === BeneficiaryAccountType.Dira) {
      text = `${BeneficiaryType[this.beneficiariesType].toString().toLowerCase()} `;
    }

    this.tooltip = this.tooltip.replace('${type}', text);

    this.isSpousalConsentMayNeeded =
      this.accountType === BeneficiaryAccountType.Dira && this.beneficiariesType === BeneficiaryType.Primary;
    this.account.nickname = this.account.nickname.replace(/\*{2}(?=\d{4})/, '*');

    // Map to common beneficiary interface
    if (this.accountType === BeneficiaryAccountType.Base) {
      this.beneficiariesBackup = this.account.beneficiaries.map((b: Beneficiary) => mapBaseBeneficiaryToCommon(b));
    } else if (this.accountType === BeneficiaryAccountType.Dira) {
      this.processDiraAccountType();
    }

    this.beneficiaries = [...this.beneficiariesBackup];

    if (this.beneficiaries.length === 0) {
      this.addBeneficiary();
    } else {
      this.beneficiaries.forEach(item => {
        this.addBeneficiary(item);
      });
    }

    this.accountType === BeneficiaryAccountType.Base
      ? this.getBeneficiariesBaseRelationshipCatalog()
      : this.getBeneficiariesTaxPlanRelationshipCatalog();
  }

  private processDiraAccountType() {
    this.prefix = this.beneficiariesType === BeneficiaryType.Primary ? 'Primary' : 'Contingent';

    // Map to common beneficiary interface
    this.beneficiariesBackup = this.account.taxPlanBeneficiaries
      .filter(b => b.level === this.beneficiariesType)
      .map((b: TaxPlanBeneficiary) => mapTaxPlanBeneficiaryToCommon(b));

    if (this.beneficiariesType === BeneficiaryType.Primary) {
      this.contingencyBeneficiariesBackup = this.account.taxPlanBeneficiaries
        .filter(b => b.level === BeneficiaryType.Contingent)
        .map((b: TaxPlanBeneficiary) => mapTaxPlanBeneficiaryToCommon(b));
    }
  }

  private getBeneficiariesBaseRelationshipCatalog() {
    this.isLoadingRelationshipCatalog = true;
    this.accountsService
      .getRelationshipCatalog()
      .pipe(
        finalize(() => {
          this.isLoadingRelationshipCatalog = false;
        })
      )
      .subscribe({
        next: response => {
          this.relationshipCatalog = response.data;
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  private getBeneficiariesTaxPlanRelationshipCatalog() {
    this.isLoadingRelationshipCatalog = true;
    this.taxPlanBeneficiariesService
      .getRelationshipCatalog()
      .pipe(
        catchError(err => {
          this.serviceHelper.errorHandler(err);

          return EMPTY;
        }),
        finalize(() => {
          this.isLoadingRelationshipCatalog = false;
        })
      )
      .subscribe({
        next: response => {
          this.relationshipCatalog = response.data;
        },
      });
  }

  private removeLastBeneficiary(index: number) {
    const dialogData = new DialogData({
      title: '',
      okText: 'Remove',
      cancelText: 'Cancel',
      icon: AlertsIcons.QuestionCircle,
      content: `
        <h3>Remove All Your Beneficiaries?</h3>
        <p>Any contingent beneficiaries will also be removed. If no beneficiaries are named, the assets in your account will go to your estate.</p>
      `,
    });

    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;
    this.subsink.sink = this.dialogService
      .openCustom(dialogConfig)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.beneficiariesFormArray.removeAt(index);
          this.save();
        }
      });
  }

  //#region Save
  private save() {
    const toAdd: Partial<BeneficiaryCommon>[] = [];
    let toEdit: [BeneficiaryCommon, number][] = [];

    const beneficiariesToProcess: string[] = this.beneficiariesFormArray.controls.map(beneficiary => {
      const ben = beneficiary.value as BeneficiaryCommon;
      if (!ben.key) {
        toAdd.push(ben);
      } else {
        if (beneficiary.dirty) {
          const original = this.beneficiaries.find(item => item.key === ben.key);
          toEdit.push([ben, ben.percent - original.percent]);
        }

        return ben.key;
      }
    });

    // Find beneficiaries to be deleted
    const toDelete: Partial<BeneficiaryCommon>[] = this.beneficiaries.filter(beneficiary => {
      return beneficiariesToProcess.indexOf(beneficiary.key) === -1;
    });

    // Since there is a validation that the share percentage never exceeds 100% within an account,
    // sort the updates so that all percentage decrements are executed first
    toEdit = toEdit.sort(([_, percentageDifference]) => percentageDifference);

    /* Save modifications sequentially since API isn't threadsafe within an account
    (because it validates that total percentage never exceeds 100%) */
    if (this.accountType === BeneficiaryAccountType.Base) {
      this.saveBeneficiaryAccountTypeBase(toDelete, toEdit, toAdd);
    } else if (this.accountType === BeneficiaryAccountType.Dira) {
      if (this.isSpousalConsentNeeded) {
        this.callSpousalConsentService();
      } else {
        /**
         * For Contingent benefs., and Primary benefs. if the account owner isn't married
         * Save modifications sequentially since API isn't threadsafe within an account
         * (because it validates that total percentage never exceeds 100%)
         */
        this.saveTaxPlanBeneficiaries(toDelete, toEdit, toAdd);
      }
    }
  }

  /**
   * For Contingent benefs., and Primary benefs. if the account owner isn't married
   * Save modifications sequentially since API isn't threadsafe within an account
   * (because it validates that total percentage never exceeds 100%)
   */
  private saveTaxPlanBeneficiaries(
    toDelete: Partial<BeneficiaryCommon>[],
    toUpdate: [Partial<BeneficiaryCommon>, number][],
    toAdd: Partial<BeneficiaryCommon>[]
  ) {
    if (this.prefix === 'Primary' && toDelete.length === 1 && this.contingencyBeneficiariesBackup.length > 0) {
      toDelete = [...toDelete, ...this.contingencyBeneficiariesBackup];
    }

    const removeBeneficiaryObsArray = [];
    const editBeneficiaryObsArray = [];
    const addBeneficiaryObsArray = [];

    toDelete.map((b: Partial<BeneficiaryCommon>) =>
      removeBeneficiaryObsArray.push(
        this.taxPlanBeneficiariesService.removeBeneficiary(this.account.cif, this.account.taxPlanType, b.key)
      )
    );

    toUpdate.map(([b, _]) =>
      editBeneficiaryObsArray.push(
        this.taxPlanBeneficiariesService.editBeneficiary(
          mapCommonToTaxPlanBeneficiary(b, this.account.cif, this.account.taxPlanType)
        )
      )
    );

    toAdd.map((b: Partial<BeneficiaryCommon>) =>
      addBeneficiaryObsArray.push(
        this.taxPlanBeneficiariesService.addBeneficiary(
          mapCommonToTaxPlanBeneficiary(b, this.account.cif, this.account.taxPlanType)
        )
      )
    );

    this.isSaving = true;
    let executedWithoutErrors = true;
    concat(...removeBeneficiaryObsArray, ...editBeneficiaryObsArray, ...addBeneficiaryObsArray)
      .pipe(
        catchError(err => {
          executedWithoutErrors = false;
          this.serviceHelper.errorHandler(err);
          this.beneficiariesIraEnhHelper.setToast(this.toastErrorMsg, ToastType.error);

          return EMPTY;
        }),
        finalize(() => {
          if (executedWithoutErrors) {
            this.beneficiariesIraEnhHelper.setToast(this.toastSuccessMsg, ToastType.success);
          }
          this.isSaving = false;
          this.goBackToAccountDetails();
        })
      )
      .subscribe();
  }

  private callSpousalConsentService() {
    const spousalConsent: SpousalConsent = this.getSpousalConsentObject();

    this.isSaving = true;
    this.spousalConsentService
      .create(spousalConsent)
      .pipe(
        catchError(err => {
          this.serviceHelper.errorHandler(err);
          this.beneficiariesIraEnhHelper.setToast(this.toastErrorMsg, ToastType.error);

          return EMPTY;
        }),
        finalize(() => {
          this.isSaving = false;
          this.goBackToAccountDetails();
        })
      )
      .subscribe({
        next: () => {
          this.beneficiariesIraEnhHelper.setToast(this.toastSuccessMsg, ToastType.success);
        },
      });
  }

  /**
   * Save modifications sequentially since API isn't threadsafe within an account
   * (because it validates that total percentage never exceeds 100%)
   */
  private saveBeneficiaryAccountTypeBase(
    toDelete: Partial<BeneficiaryCommon>[],
    toUpdate: [Partial<BeneficiaryCommon>, number][],
    toAdd: Partial<BeneficiaryCommon>[]
  ) {
    const removeBeneficiaryObsArray = [];
    const editBeneficiaryObsArray = [];
    const addBeneficiaryObsArray = [];

    toDelete.map((b: Partial<BeneficiaryCommon>) =>
      removeBeneficiaryObsArray.push(this.accountsService.removeBeneficiary(this.accountId, b.key))
    );

    toUpdate.map(([b, _]) => {
      editBeneficiaryObsArray.push(this.accountsService.editBeneficiary(this.accountId, mapCommonToBaseBeneficiary(b)));
    });

    toAdd.map((b: Partial<BeneficiaryCommon>) => {
      addBeneficiaryObsArray.push(this.accountsService.addBeneficiary(this.accountId, mapCommonToBaseBeneficiary(b)));
    });

    this.isSaving = true;
    let executedWithoutErrors = true;
    concat(...removeBeneficiaryObsArray, ...editBeneficiaryObsArray, ...addBeneficiaryObsArray)
      .pipe(
        catchError(err => {
          executedWithoutErrors = false;
          this.serviceHelper.errorHandler(err);
          this.beneficiariesIraEnhHelper.setToast(this.toastErrorMsg, ToastType.error);

          return EMPTY;
        }),
        finalize(() => {
          if (executedWithoutErrors) {
            this.beneficiariesIraEnhHelper.setToast(this.toastSuccessMsg, ToastType.success);
          }
          this.isSaving = false;
          this.goBackToAccountDetails();
        })
      )
      .subscribe();
  }
  //#endregion Save
}
