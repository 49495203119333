<mat-dialog-content class="mat-dialog-content">
  <div class="image"></div>
  <div class="titles">
    <h2>
      Download Transactions
      <br />
      Unavailable
    </h2>
    <p>
      We’re experiencing an issue and are unable to provide your downloaded
      <br />
      transactions at this time. Please try again later.
    </p>
  </div>
  <div class="button-bar">
    <button class="uk-btn solid secondary lg" (click)="closeModal()">Okay</button>
  </div>
</mat-dialog-content>
