import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import { addUpdateHoldingAccountAction, selectHoldingSortingOption } from '../holdings.actions';
import { HoldingState, initialHoldingState } from '../types';
import { getKeyByValueFromHolding, lowercaseFirstLetter } from '../../functions';
import { HoldingSortType } from '../../const';
import { dynamicSortHoldingsAccount } from '../../functions/dynamic-sort-holdings-account';
import { holdingSortingFunctionsFamilies } from '../../functions/holding-sorting-functions-family';
const reducer = createReducer(
  initialHoldingState,
  on(addUpdateHoldingAccountAction, (_state, action) => {
    const indexOfUpdated = _state.accountsHoldings.findIndex(m => m.accountNumber === action.payload.accountNumber);
    const sortBy = getKeyByValueFromHolding(
      action.payload.sortType,
      HoldingSortType,
      key => lowercaseFirstLetter(key),
      'portfolioPercent'
    );
    const sortFn =
      action.payload.isSortingEnabled && action.payload.holdings.length > 0
        ? dynamicSortHoldingsAccount(sortBy, action.payload.sortDirection, typeof action.payload.holdings[0][sortBy])
        : holdingSortingFunctionsFamilies.numericFamily('portfolioPercent').Descending;
    if (indexOfUpdated >= 0) {
      return {
        accountsHoldings: _state.accountsHoldings.map(item => {
          return item.accountNumber === action.payload.accountNumber
            ? {
                ...action.payload,
                holdings: [...action.payload.holdings].sort(sortFn),
              }
            : item;
        }),
        selectedAccountHoldings: action.payload.accountNumber,
      };
    } else {
      return {
        accountsHoldings: [
          ..._state.accountsHoldings,
          {
            ...action.payload,
            holdings: [...action.payload.holdings].sort(sortFn),
          },
        ],
        selectedAccountHoldings: action.payload.accountNumber,
      };
    }
  }),
  on(selectHoldingSortingOption, (state, action) => {
    const sortFn = dynamicSortHoldingsAccount(
      action.payload.option.sortByKey,
      action.payload.option.sortDirection,
      typeof state.accountsHoldings[0].holdings[0][action.payload.option.sortByKey]
    );
    return {
      ...state,
      accountsHoldings: [...state.accountsHoldings].map(holdingsAccount => {
        if (action.payload.accountNumber === holdingsAccount.accountNumber) {
          return {
            ...holdingsAccount,
            sortOptions: holdingsAccount.sortOptions.map(option => ({
              ...option,
              isSelected: option.value === action.payload.option.value,
            })),
            holdings: [...holdingsAccount.holdings].sort(sortFn),
            sortDirection: action.payload.option.sortDirection,
            sortType: action.payload.option.sortType,
          };
        }
        return {
          ...holdingsAccount,
        };
      }),
    };
  }),
  on(signOut, () => {
    return initialHoldingState;
  })
);

export function holdingsReducer(state: HoldingState, action: Action) {
  return reducer(state, action);
}
