<div class="payments-history frow wrap">
  <div class="fcol-md-4 left-padding">
    <h1 class="header">Payment History</h1>
  </div>
  <div *ngIf="hasAtLeastOnePayment" class="fcol-md-8 frow filters hidden-xs hidden-sm">
    <div class="fcol-2 finline middle">
      <label style="margin-right: 5px;">Date Range:</label>
      <span class="flex-row--flex-end f-input-date">
        <input
          [matDatepicker]="startFromDatePicker"
          [matDatepickerFilter]="dateAllowed"
          [min]="minFromDate"
          [max]="maxFromDate"
          (click)="startFromDatePicker.open()"
          type="text"
          name="payoffDate"
          id="payoffDate"
          aria-label="payoffDate"
          class="uk-input date-input"
          readonly
          required
          (dateInput)="getPaymentHistory(fromDate, toDate, recipient)"
          [(ngModel)]="fromDate"
        />
        <button type="button" class="date-icon" (click)="startFromDatePicker.open()">
          <app-svg-icon [iconName]="iconConfig.calendar.icon" [size]="iconConfig.calendar.size"></app-svg-icon>
        </button>
        <mat-datepicker #startFromDatePicker></mat-datepicker>
      </span>
    </div>
    <div class="fcol-2 finline middle" style="margin-left: 5px;">
      <label style="margin-right: 5px;">To:</label>
      <span class="flex-row--flex-end f-input-date">
        <input
          [matDatepicker]="startDatePicker"
          [matDatepickerFilter]="dateAllowed"
          [min]="fromDate"
          [max]="maxToDate"
          (click)="startDatePicker.open()"
          type="text"
          name="payoffDate"
          id="payoffDate"
          aria-label="payoffDate"
          class="uk-input date-input"
          readonly
          required
          (dateInput)="getPaymentHistory(fromDate, toDate, recipient)"
          [(ngModel)]="toDate"
        />
        <button type="button" class="date-icon" (click)="startDatePicker.open()">
          <app-svg-icon [iconName]="iconConfig.calendar.icon" [size]="iconConfig.calendar.size"></app-svg-icon>
        </button>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </span>
    </div>
    <div class="fcol-2 finline middle">
      <span class="f-input-search finline middle">
        <i class="bofi-search" aria-hidden="true"></i>
        <input
          [(ngModel)]="recipient"
          type="text"
          placeholder="Search by Recipient..."
          class="uk-input"
          (ngModelChange)="getPaymentHistory(fromDate, toDate, recipient)"
          aria-label="Recipient"
        />
      </span>
    </div>
  </div>
  <div *ngIf="(!hasAtLeastOnePayment || payments.length < 1) && !isLoading" class="empty frow empty-state">
    <p class="light">No payment history.</p>
  </div>
  <div style="text-align: center; width: 100%;" *ngIf="(isLoading$ | async) || isLoading">
    <app-dot-loader *ngIf="payments.length == 0" class="frow wrap">Loading</app-dot-loader>
  </div>

  <div *ngIf="payments.length > 0" class="frow wrap hidden-xs hidden-sm">
    <div class="payments-history__table fcol">
      <table class="table--primary">
        <thead>
          <tr>
            <th *ngFor="let header of headers" (click)="sort(header.subvalue, header.value)">
              {{ header.label }}
              <i *ngIf="headerIdOrderBy === header.value && reverse" class="fa fa-caret-down" aria-hidden="true"></i>
              <i *ngIf="headerIdOrderBy === header.value && !reverse" class="fa fa-caret-up" aria-hidden="true"></i>
              <app-tooltip
                *ngIf="header.subvalue == 'paymentMethod'"
                [content]="getHeaderTooltip(header.subvalue)"
                theme="olb2"
              ></app-tooltip>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="!isLoading">
          <tr *ngFor="let payment of paymentsInTable">
            <td class="recipient-in-table">
              <span [textContent]="payment.payeeName"></span>
              <p class="nickname" *ngIf="payment.nickName">({{ payment.nickName }})</p>
            </td>
            <td>
              <span [textContent]="payment.amount | currency"></span>
            </td>
            <td>
              <img *ngIf="payment.frequency == 0" [src]="'/img/icons/mybills/onetime-icon.svg'" />
              <img *ngIf="payment.frequency != 0" [src]="'/img/icons/mybills/recurring-icon.svg'" />
              <span class="frequency-icon" [textContent]="payment.paymentFrequency"></span>
            </td>
            <td>
              <span [textContent]="payment.accountName"></span>
            </td>
            <td>
              <span [textContent]="payment.deliveryDate | date: 'MM/dd/yyyy'"></span>
            </td>
            <td>
              <span [textContent]="payment.paymentMethod + payment.checkNumber"></span>
            </td>
            <td>
              <div class="status">
                <span class="status-tag" [ngClass]="getClassName(payment.currentState.state)"></span>
                <span [textContent]="payment.status"></span>
              </div>
            </td>
            <td>
              <span [textContent]="payment.confirmationNumber"></span>
            </td>
            <td></td>
          </tr>
          <tr *ngIf="paymentsInTable.length < payments.length">
            <td class="text-center" colspan="8">
              <a class="link" (click)="ShowMore()">Show more</a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-dot-loader *ngIf="isLoading" class="frow wrap">Loading</app-dot-loader>
    </div>
  </div>
  <div *ngIf="payments.length > 0 && !isLoading" class="payments-mobile hidden-md hidden-lg">
    <div *ngFor="let payment of paymentsInTable" class="wrapper-item-details">
      <div class="item">
        <p class="title"><span [textContent]="payment.payeeName"></span></p>
        <p class="nickname" *ngIf="payment.nickName" [textContent]="payment.nickName"></p>
        <p class="details">
          <span [textContent]="payment.amount | currency"></span>
          <span [textContent]="' on '"></span>
          <span [textContent]="payment.deliveryDate | date: 'MM/dd'"></span>
        </p>
        <p class="details">
          <img *ngIf="payment.frequency == 0" [src]="'/img/icons/mybills/onetime-icon.svg'" />
          <img *ngIf="payment.frequency != 0" [src]="'/img/icons/mybills/recurring-icon.svg'" />
          <span [textContent]="payment.paymentFrequency"></span>
        </p>
        <span class="show-details" (click)="toggle(payment)">
          <i class="fa arrow" [ngClass]="payment.isToggled ? 'fa-angle-up' : 'fa-angle-down'"></i>
        </span>
      </div>
      <div class="item-details" *ngIf="payment.isToggled">
        <p class="title">Pay From</p>
        <p class="data">
          <span>{{ payment.accountName }} (Avail. Bal: {{ getAccountBalance(payment.accountCode) | currency }})</span>
        </p>
        <p class="title">
          Recipient Type
          <app-tooltip [content]="getHeaderTooltip('paymentMethod')" theme="olb2"></app-tooltip>
        </p>
        <p class="data"><span [textContent]="payment.paymentMethod + payment.checkNumber"></span></p>
        <p class="title">Status</p>
        <div class="status data">
          <span class="status-tag" [ngClass]="getClassName(payment.currentState.state)"></span>
          <span [textContent]="payment.status"></span>
        </div>
        <p class="title">Confirmation Number</p>
        <p class="data"><span [textContent]="payment.confirmationNumber"></span></p>
      </div>
    </div>
    <div *ngIf="paymentsInTable.length < payments.length" class="show-more">
      <a class="link" (click)="ShowMore()">Show more</a>
    </div>
  </div>
</div>
