import { Component, Inject } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

/**
 * Displays the banner for outdated info.
 */
@Component({
  selector: 'app-outdated-contact-info-banner',
  templateUrl: './outdated-contact-info-banner.component.html',
  styleUrls: ['./outdated-contact-info-banner.component.scss'],
})
export class OutdatedContactInfoBannerComponent {
  get currentLogo(): string {
    return `./assets/${this.settings.brand}/img/icons/alerts/cogwheel.svg`;
  }

  constructor(@Inject(olbSettings) private settings: OlbSettings) {}
}
