import { IFilterService, IRootScopeService, IScope } from 'angular';
import { Inject } from 'decorators/decorators';
import { FeatureFlagService } from 'services/feature-flag.service';

@Inject('$scope', '$filter', 'featureFlagService', '$rootScope')
class TxRowAggController {
  // Bound properties
  trxn: Transaction;
  isAccountActive: boolean;
  amount = 0;
  isExternalLoan: boolean;

  // Internal props to manage directive
  accountOptions: any[] = [];
  isPfm3ExternalTransActive = false;

  constructor(
    private readonly scope: IScope,
    private readonly filterService: IFilterService,
    private readonly featureFlagService: FeatureFlagService,
    private root: IRootScopeService
  ) {
    this.trxn = this.scope['trxn'];
  }

  $onInit() {
    console.log(this.root['isExternalLoan']);
    this.isExternalLoan = this.root['isExternalLoan'];
    this.isPfm3ExternalTransActive = this.featureFlagService.isPFM3ExternalTrans();

    this.isAccountActive = this.scope['isAccountActive'];
    if (!this.trxn.isPending && this.transactionIsNotPending()) {
      this.accountOptions.push({
        label: 'Recategorize transaction',
        isCollapsible: true,
      });
    }
  }

  getAccountBalance(transaction: Transaction): string {
    return this.filterService('currency')(transaction.balance);
  }
  /**
   * If it was a deposit, it will be a positive amount but if it was a withdrawal it will be a negative one
   */
  getTransactionAmount(transaction: Transaction): string {
    const amount = transaction.type == 'DEBIT' ? -Math.abs(transaction.amount) : transaction.amount;

    return this.filterService('currency')(amount);
  }

  /** Triggers the on select options defined */
  selectOption(option: any) {
    this.scope['onOptionSelected']({ option });
  }

  transactionIsNotPending(): boolean {
    const categoryName = this.trxn.olbCategoryName.toLowerCase();

    return categoryName !== 'pending';
  }

  getImagePath() {
    let categoryName = this.trxn.olbCategoryName.toLowerCase();
    const idx = categoryName.indexOf(' ');
    categoryName = idx < 0 ? categoryName : categoryName.substring(0, idx);

    const logoPath = `/img/icons/categories/${categoryName}_white.svg`.toLowerCase();

    return logoPath;
  }

  /**Displays transactions details tray */
  showDetails(trxn: Transaction) {
    if (!this.isPfm3ExternalTransActive) return;
    if (!trxn.isPending) trxn.isDetailsExpanded = !trxn.isDetailsExpanded;
  }
}

const txRowAggOptions: ng.IDirective = {
  restrict: 'A',
  scope: {
    id: '@',
    trxn: '=txRowAgg',
    onOptionSelected: '&',
    onCollapse: '&',
  },
  controller: TxRowAggController,
  controllerAs: '$tr',
  templateUrl: 'accounts/transactions/tx-table/tx-row-agg/tx-row-agg.tpl.html',
};
export const txRowAggDirective = () => {
  return txRowAggOptions;
};
