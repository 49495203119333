import { Injectable } from '@angular/core';
import {
  AddBreadcrumbAction,
  PdpLayoutBreadcrumbItemState,
  PdpLayoutContextualTopBarState,
  PdpLayoutState,
  SetContextualTopBarStateAction,
  getContextualTopBarState,
  selectBreadcrumbUrlAction,
} from '../core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PdpLayoutFacade {
  contextualTopBarState$ = this.store.select(getContextualTopBarState);
  constructor(private store: Store<PdpLayoutState>) {}

  setPdpLayoutContextualTopBarState(contextualTopBarState: PdpLayoutContextualTopBarState): void {
    this.store.dispatch(
      SetContextualTopBarStateAction({
        payload: {
          contextualTopBar: contextualTopBarState,
        },
      })
    );
  }

  addBreadcrumbItem(newBreadcrumbsState: PdpLayoutBreadcrumbItemState[]) {
    this.store.dispatch(
      AddBreadcrumbAction({
        payload: newBreadcrumbsState,
      })
    );
  }

  selectBreadcrumbUrl(breadcrumbUrl: string) {
    this.store.dispatch(
      selectBreadcrumbUrlAction({
        payload: breadcrumbUrl,
      })
    );
  }
}
