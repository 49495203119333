<app-dot-loader [showWhen]="isLoading"></app-dot-loader>
<ng-container *ngFor="let switch of switches">
  <div [ngSwitch]="switch.status">
    <app-click-switch-online-path-detail
      *ngSwitchCase="readySwitchStatus"
      [switch]="switch"
    ></app-click-switch-online-path-detail>
    <app-click-switch-detail
      *ngSwitchDefault
      [switch]="switch"
      (goToConfirmation)="showConfirmation($event)"
    ></app-click-switch-detail>
  </div>
</ng-container>
