<ng-container *ngIf="!accountsTabAccountState?.error; else failedStateContent">
  <div class="section-header">Profile</div>
  <div class="list">
    <div class="list-row">
      <span class="title">Account Nickname</span>
      <span class="value">
        {{ getNickname() || '--' }}
        <a
          *ngIf="displayEditNicknameIcon"
          class="colored-container"
          (click)="editNickname()"
        >
          <mat-icon [svgIcon]="uk2Pencil"></mat-icon>
        </a>
      </span>
    </div>
    <div class="list-row">
      <span class="title">Account Type</span>
      <span class="value">
        {{ accountsTabAccountState?.profile?.typeDescription ?? '--' }}
      </span>
    </div>
    <div class="list-row">
      <span class="title">Account Number</span>
      <span class="value">
        {{ accountsTabAccountState?.profile?.number ?? '--' }}
      </span>
    </div>
    <div class="list-row">
      <span class="title">Name for Mail</span>
      <span class="value">
        {{ accountsTabAccountState?.profile?.mailingName ?? '--' }}
      </span>
    </div>
    <div class="list-row">
      <span class="title">Date Opened</span>
      <span class="value">{{ getFormattedDate() ?? '--' }}</span>
    </div>
    <div class="list-row">
      <span class="title">Statement Family</span>
      <span class="value">
        {{ accountsTabAccountState?.profile?.statementFamily ?? '--' }}
      </span>
    </div>
    <div class="list-row">
      <span class="title">Statement Frequency</span>
      <span class="value">{{ getStatementFrequencyText() ?? '--' }}</span>
    </div>
  </div>
  <div class="section-header">Contributions</div>
  <div class="list">
    <div class="list-row">
      <span class="title">Year to Date</span>
      <span class="value">
        {{ getAsCurrencyFormat(accountsTabAccountState?.contributions?.contributionYearToDate) ?? '--' }}
      </span>
    </div>
    <div class="list-row">
      <span class="title">Total</span>
      <span class="value">
        {{ getAsCurrencyFormat(accountsTabAccountState?.contributions?.contributionTotal) ?? '--' }}
      </span>
    </div>
  </div>
  <ng-container *ngIf="shouldShowRMD()">
    <div class="section-header">Required Minimum Distribution (RMD)</div>
    <div class="list">
      <div class="list-row">
        <span class="title">
          {{ accountsTabAccountState?.requiredMinimumDistributions?.year ?? '--' }}
          RMD (Withdrawn / Total Amount)
        </span>
        <span class="value">
          {{ getAsCurrencyFormat(accountsTabAccountState?.requiredMinimumDistributions?.withdrawn) ?? '--' }}
          /
          {{ getAsCurrencyFormat(accountsTabAccountState?.requiredMinimumDistributions?.totalAmount) ?? '--'}}
        </span>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #failedStateContent>
  <mat-divider class="failed-state-divider"></mat-divider>
  <uk2-text-tile
    [uk2TextTileTitle]="'Account Information Temporarily Unavailable'"
    [uk2TextTileIsStandaloneTile]="false"
  >
    We're sorry, but we're currently unable to provide you with your latest
    account information. This includes your
    <span class="uk2-font__bold--75">Profile and Contribution</span>
    information. Please check back later. Thank you for your patience.
  </uk2-text-tile>
</ng-template>

<uk2-modal
  [title]="'Account Nickname'"
  [showTitleDivider]="true"
  [showActionsDivider]="true"
  [titleAlign]="'center'"
  [innerPanelClass]="['uk2-custom-modal-white-label', 'modal-medium-size']"
  [bodyContentAlignment]="'center'"
  #modal
>
  <form [formGroup]="form">
    <ng-template #uk2ModalContent>
      <mat-form-field
        class="form-field"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Nickname</mat-label>
        <input
          matInput
          uk2Input
          formControlName="accountNickname"
          maxlength="30"
          #inputNickname
          id="inputNickname"
        />
      </mat-form-field>
    </ng-template>
    <ng-template #uk2ModalActions>
      <button
        uk2SecondaryButton
        mat-stroked-button
        disableRipple
        [uk2ButtonSize]="uk2ButtonSize"
        (click)="closeModal()"
      >
        Cancel
      </button>
      <button
        type="submit"
        uk2PrimaryButton
        mat-raised-button
        disableRipple
        [uk2ButtonSize]="uk2ButtonSize"
        [disabled]="isDisabled"
        (click)="submitNickname()"
      >
        Save
      </button>
    </ng-template>
  </form>
</uk2-modal>
