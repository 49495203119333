import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../aas-core';
import { AasSharedMatModule } from './aas-shared-mat.module';
import { AasSharedUk2Module } from './aas-shared-uk2.module';
import { mainLayoutReducer } from './core/store/reducer';
import { initialMainLayoutState } from './core/store/types';
import { MainLayoutComponent } from './layout';
import { DataTileListComponent, DataTileListRowComponent } from './components';
import { DataTileSectionListComponent } from './components/data-tile/data-tile-section-list/data-tile-section-list.component';
import { SharedModule } from '@shared/shared.module';
import { initialUserPreferencesState, userPreferencesReducer } from './features/user-preferences';

const sharedComponents = [
  MainLayoutComponent,
  DataTileListComponent,
  DataTileListRowComponent,
  DataTileSectionListComponent,
];

@NgModule({
  declarations: [...sharedComponents],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AasSharedMatModule,
    AasSharedUk2Module,
    StoreModule.forFeature(AasStoreFeatures.MainLayout, mainLayoutReducer, {
      initialState: initialMainLayoutState,
    }),
    StoreModule.forFeature(AasStoreFeatures.UserPreferences, userPreferencesReducer, {
      initialState: initialUserPreferencesState,
    }),
    SharedModule,
  ],
  exports: [...sharedComponents],
})
export class AasSharedModule {}
