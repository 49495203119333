import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutHoldingQuotesComponent } from './view';
import { DelayedQuotesMatModule } from './delayed-quotes-mat.module';
import { DelayedQuotesUk2Module } from './delayed-quotes-uk2.module';

@NgModule({
  declarations: [AboutHoldingQuotesComponent],
  imports: [CommonModule, DelayedQuotesMatModule, DelayedQuotesUk2Module],
  exports: [AboutHoldingQuotesComponent],
})
export class DelayedQuotesModule {}
