import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { downgradeComponent } from '@angular/upgrade/static';
import { StoreModule } from '@ngrx/store';

import { TransactionsModule } from '@app/accounts/submodules/transactions/transactions.module';
import { AccountWarningTooltipComponent } from '@app/accounts/components/account-warning-tooltip/account-warning-tooltip.component';
import {
  AccountGroupComponent,
  AccountsFilterComponent,
  AccountsListComponent,
  AssetsLiabilitiesTableComponent,
  CategoriesFilterComponent,
  CategoriesGridComponent,
  ChartSelectionComponent,
  DonutChartComponent,
  FilterDropdownComponent,
  FiltersModalComponent,
  FiltersModalNetWorthComponent,
  IncomeComponent,
  InsightsExpensesGridComponent,
  InsightsOverviewComponent,
  NetWorthComponent,
  NetWorthHeaderComponent,
  NetWorthModuleComponent,
  RecategorizeComponent,
  SearchFilterComponent,
  SpendingComponent,
  SpendingModuleComponent,
  TimePeriodFilterComponent,
  TransactionListComponent,
} from '@app/pfm/components';
import { PfmAccountsService } from '@app/pfm/services/pfm-accounts.service';
import { PfmService } from '@app/pfm/services/pfm.service';
import { SharedModule } from '@app/shared/shared.module';
import { AppStateFeatures } from '@core/enums';
import {
  accountAggregationChartFormatterServiceProvider,
  accountAggregationServiceProvider,
  serviceHelperProvider,
  wiseBanyanServiceProvider,
} from '@core/providers';
import { balanceServiceProvider } from '@core/providers/balance-service.provider';

import { LineChartComponent } from './components/line-chart/line-chart.component';
import { AccountsEffects } from './store/accounts/accounts.effects';
import { accountsReducer } from './store/accounts/accounts.reducer';
import { NetWorthEffects } from './store/net-worth/net-worth.effects';
import { netWorthReducer } from './store/net-worth/net-worth.reducer';
import { SpendingEffects } from './store/spending/spending.effects';
import { spendingReducer } from './store/spending/spending.reducer';
import { Uk2DirectivesModule } from '@axos/uikit-v2-lib';

const exportedComponents = [
  AssetsLiabilitiesTableComponent,
  AccountGroupComponent,
  AccountsListComponent,
  CategoriesFilterComponent,
  ChartSelectionComponent,
  InsightsExpensesGridComponent,
  IncomeComponent,
  DonutChartComponent,
  LineChartComponent,
  InsightsOverviewComponent,
  SpendingComponent,
  SpendingModuleComponent,
  CategoriesGridComponent,
  FilterDropdownComponent,
  AccountWarningTooltipComponent,
  TimePeriodFilterComponent,
  AccountsFilterComponent,
  FiltersModalComponent,
  FiltersModalNetWorthComponent,
  TransactionListComponent,
  SearchFilterComponent,
  NetWorthComponent,
  NetWorthHeaderComponent,
  NetWorthModuleComponent,
  RecategorizeComponent,
];

const uk2Modules = [Uk2DirectivesModule];

const importedModules = [
  CommonModule,
  SharedModule,
  FormsModule,
  MatCheckboxModule,
  TransactionsModule,
];

const importedProviders = [
  accountAggregationChartFormatterServiceProvider,
  accountAggregationServiceProvider,
  serviceHelperProvider,
  balanceServiceProvider,
  wiseBanyanServiceProvider,
  PfmService,
  PfmAccountsService,
  AccountsEffects,
  SpendingEffects,
  NetWorthEffects,
  DatePipe,
];
@NgModule({
  declarations: [...exportedComponents],
  imports: [
    ...importedModules,
    ...uk2Modules,
    StoreModule.forFeature(AppStateFeatures.Pfm, {
      spendingState: spendingReducer,
      accountsState: accountsReducer,
      netWorthState: netWorthReducer,
    }),
  ],
  exports: [...importedModules, ...exportedComponents],
  providers: [...importedProviders],
})
export class PfmModule {}
export const pfmModule = angular
  .module('udb.pfm', [])
  .directive(
    'appChartSelection',
    downgradeComponent({ component: ChartSelectionComponent })
  );
