import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { MilestoneFilter, RecommendedAutoDeposit } from '@app/axos-invest/models';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { MilestoneType } from '@legacy/common/enums/milestoneType.enum';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ManagedPortfolioFundingService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'axosinvest');
  }

  getRecommendationAutoDeposit(
    type: MilestoneType,
    milestoneFilter: Partial<MilestoneFilter>
  ): WebApiResponse<RecommendedAutoDeposit> {
    return this.post(`${type}/recommendedautodeposit`, milestoneFilter);
  }
}
