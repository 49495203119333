import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import {
  OrganizationSettingsListStateType,
  OrganizationStateType,
} from '@app/Areas/AAS/aas-core/organization-settings/core';
import { OrganizationSettingsFacade } from '@app/Areas/AAS/aas-core/organization-settings/facade/organization-settings.facade';
import { MainLayoutFacade } from '@app/Areas/AAS/aas-shared/facade/main-layout.facade';

import { CSS_VARIABLES, ORGANIZATION_NAMES } from '../core/constants';
import { BrandingSettingsFacade } from '@app/Areas/AAS/aas-core/branding-settings';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
type MemoizedCSSVariables = {
  name: string;
  value: string;
};
@Injectable({
  providedIn: 'root',
})
export class BrandingFacade implements OnDestroy {
  useCustomBranding: boolean = false;
  private destroy$ = new Subject<void>();
  private organizationSettings: OrganizationStateType;
  private targetStyleElement: HTMLElement;
  private _cssBrandingProperties: BehaviorSubject<MemoizedCSSVariables[]> = new BehaviorSubject<MemoizedCSSVariables[]>(
    []
  );
  constructor(
    private organizationSettingsFacade: OrganizationSettingsFacade,
    private mainLayoutFacade: MainLayoutFacade,
    private brandingSettingsFacade: BrandingSettingsFacade,
    private featureFlagService: FeatureFlagService,
    private riaFacade: RiaFacade
  ) { }

  public get cssBrandingProperties(): Observable<MemoizedCSSVariables[]> {
    return this._cssBrandingProperties.asObservable();
  }

  loadBranding(targetStyleElementQuerySelector: string): void {
    this.riaFacade.selectedRia$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined),
        tap((selectedRia: RiaType) => {
          this._cssBrandingProperties.next([]);

          let isAxos = selectedRia.name === ORGANIZATION_NAMES.Axos;
          this.useCustomBranding = isAxos || (!isAxos && selectedRia.useCustomBranding);
        })
      )
      .subscribe();

    if (this.featureFlagService.isRiaPilotAllowedAccountsActive() && this.useCustomBranding) {
      this.brandingSettingsFacade.selectedBranding$
        .pipe(
          takeUntil(this.destroy$),
          filter(state => !!state),
          tap(state => this.initializeBranding(state, targetStyleElementQuerySelector))
        )
        .subscribe();
    } else {
      this.organizationSettingsFacade.selectedOrganization$
        .pipe(
          takeUntil(this.destroy$),
          filter(state => state !== undefined),
          tap(state => this.initializeBranding(state, targetStyleElementQuerySelector))
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeBranding(
    organizationSettings: OrganizationStateType,
    targetStyleElementQuerySelector: string
  ): void {
    this.organizationSettings = organizationSettings;
    const targetElement: HTMLElement = document.querySelector(targetStyleElementQuerySelector);

    if (targetElement !== null) {
      this.targetStyleElement = targetElement;
      this.continueInitializeBranding();
    } else {
      this.waitUntilTargetStyleElementExists(targetStyleElementQuerySelector);
    }
  }

  private waitUntilTargetStyleElementExists(targetStyleElementQuerySelector: string): void {
    const bodyElement = document.querySelector('body');
    const config = { childList: true };
    // Callback function to execute when mutations are observed
    const callback = (_, observer) => {
      const targetElement: HTMLElement = bodyElement.querySelector(targetStyleElementQuerySelector);
      if (targetElement !== null) {
        this.targetStyleElement = targetElement;
        observer.disconnect();
        this.continueInitializeBranding();
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(bodyElement, config);
  }

  private continueInitializeBranding(): void {
    // TODO implement when PdpLayoutFacade is ready
    // this.setTopBarProperties();
    this.setMainLayoutProperties();
    this.setBannerColorProperties();
    this.setPrimaryFontColorProperties();
    this.setAccentColorProperties();
  }

  // TODO implement when PdpLayoutFacade is ready
  // private setTopBarProperties(): void {
  //   const displayName = this.getSettingByName('displayName');
  //   if (!displayName) return;
  //   const brandHeaderLogo = this.getSettingByName('brandHeaderLogo');
  //   if (!brandHeaderLogo) return;
  //   this.pdpLayoutFacade.setPdpLayoutTopBarState(displayName, brandHeaderLogo);
  // }

  private setMainLayoutProperties(): void {
    const brandLogo = this.getSettingByName('brandLogo');
    if (!brandLogo) return;
    this.mainLayoutFacade.setupTopBarBranding({
      organizationName: this.organizationSettings.name,
      logoImgPath: this.replaceCharacters(brandLogo.value),
      isAxos: !this.useCustomBranding,
      urlRedirectionConfig: this.getMarketingUrl(),
    });
    // TODO implement when PdpLayoutFacade is ready
    // this.pdpLayoutFacade.setOrganizationNameAndLogo(
    //   this.organizationSettings.displayName,
    //   this.replaceCharacters(brandLogo.value)
    // );
  }

  private getMarketingUrl(): string | undefined {
    const marketingUrl = this.organizationSettings.settings.find((setting) => setting.name === "marketingUrl")?.value; 
    return marketingUrl ? `http://${marketingUrl}` : undefined;
  }
  private replaceCharacters(value: string): string {
    return value.replace('url("', '').replace('")', '').replace("url('", '').replace("')", '');
  }

  private setPrimaryFontColorProperties(): void {
    const primaryFontColor = this.getSettingByName('primaryFontColor');
    if (!primaryFontColor) return;
    // Used by Product Details Page (PDP) to display colored titles
    this.targetStyleElement?.style.setProperty(
      CSS_VARIABLES.udbLogin.mainHeaderComponent.titleColor,
      primaryFontColor.value
    );
    // Used by UI KIT
    this.targetStyleElement?.style.setProperty(CSS_VARIABLES.uk2Lib.global.header.fontColor, primaryFontColor.value);
    this.targetStyleElement?.style.setProperty(
      CSS_VARIABLES.uk2Lib.components.modal.headerFontColor,
      primaryFontColor.value
    );
    // Used by AAS pages to display colored titles
    this.targetStyleElement?.style.setProperty(CSS_VARIABLES.aas.global.header.fontColor, primaryFontColor.value);
  }

  private setBannerColorProperties(): void {
    const bannerColor = this.getSettingByName('bannerColor');
    if (!bannerColor) return;

    if (this.useCustomBranding) {
      this.targetStyleElement?.style.setProperty(CSS_VARIABLES.uk2Lib.global.banner.topBarColor, bannerColor.value);
    } else {
      this.removePropertyFromTargetHtmlElement(CSS_VARIABLES.uk2Lib.global.banner.topBarColor);
    }
  }

  private setAccentColorProperties(): void {
    const accentColor = this.getSettingByName('accentColor');
    if (!accentColor) return;
    const primaryAccentHsl = this.hexToHsl(accentColor.value);
    this.setPrimaryAccentHsl(primaryAccentHsl.h, primaryAccentHsl.s, primaryAccentHsl.l);
  }

  private getSettingByName(settingName: string): OrganizationSettingsListStateType | undefined {
    return this.organizationSettings.settings.find(settings => settings.name === settingName);
  }

  private setPrimaryAccentHsl(hue: number, saturation: number, lightness: number): void {
    this.addPropertyToTargetHtmlElement(
      CSS_VARIABLES.uk2Lib.global.main.primaryAccentHsl.full,
      'hsl(' + hue + ',' + saturation + '%,' + lightness + '%)'
    );
    this.addPropertyToTargetHtmlElement(CSS_VARIABLES.uk2Lib.global.main.primaryAccentHsl.hue, '' + hue + '');
    this.addPropertyToTargetHtmlElement(
      CSS_VARIABLES.uk2Lib.global.main.primaryAccentHsl.saturation,
      '' + saturation + '%'
    );
    this.addPropertyToTargetHtmlElement(
      CSS_VARIABLES.uk2Lib.global.main.primaryAccentHsl.lightness,
      '' + lightness + '%'
    );

    this.removePropertyFromTargetHtmlElement(CSS_VARIABLES.uk2Lib.components.formField.generalActiveColor);

    if (this.organizationSettings.name === 'axos') {
      // override for active color on form-field for Axos only
      this.targetStyleElement?.style.setProperty(
        CSS_VARIABLES.uk2Lib.components.formField.generalActiveColor,
        'var(--uk2-accent-color-hsl)'
      );
    } else {
      this.targetStyleElement?.style.setProperty(
        CSS_VARIABLES.uk2Lib.components.formField.generalActiveColor,
        'var(--uk2-neutral-slate-400)'
      );
      this.targetStyleElement?.style.setProperty(
        CSS_VARIABLES.uk2Lib.components.button.labeledIconColor,
        'var(--uk2-neutral-slate-400)'
      );
      this.targetStyleElement?.style.setProperty(
        CSS_VARIABLES.uk2Lib.components.tooltip.iconFontColor,
        'var(--uk2-neutral-slate-400)'
      );
      this.targetStyleElement?.style.setProperty(
        CSS_VARIABLES.uk2Lib.components.tooltip.labelFontColor,
        'var(--uk2-neutral-slate-400)'
      );
    }
  }

  private addPropertyToTargetHtmlElement(propertyName: string, propertyValue: string): void {
    this._cssBrandingProperties.next([
      ...this._cssBrandingProperties.getValue(),
      { name: propertyName, value: propertyValue },
    ]);
    this.targetStyleElement?.style.setProperty(propertyName, propertyValue);
  }

  private removePropertyFromTargetHtmlElement(propertyName: string): void {
    this._cssBrandingProperties.next([
      ...this._cssBrandingProperties.getValue().filter(property => property.name !== propertyName),
    ]);
    this.targetStyleElement?.style.removeProperty(propertyName);
  }

  private hexToHsl(
    hex: string
  ): {
    h: number;
    s: number;
    l: number;
  } {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return {
      h: Math.round(h * 360),
      s: Math.round(s * 100),
      l: Math.round(l * 100),
    };
  }
}
