import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { PdfOptions } from '@shared/components/pdf-renderer/pdf-renderer.component';
import { olbSettings } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-personal-deposit-modal',
  templateUrl: './personal-deposit-modal.html',
  styleUrls: ['./personal-deposit-modal.scss'],
})
export class PersonalDepositModal implements OnInit {
  fileUrl: string;
  options: PdfOptions;
  icons = {
    close: NavigationIcons.Ex,
  };
  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    private dialogRef: MatDialogRef<PersonalDepositModal>
  ) {}

  ngOnInit(): void {
    this.fileUrl = this.setFileUrl();
    this.options = {
      page: 1,
      scrollbar: 1,
      toolbar: 1,
      zoom: 85,
    };
  }

  setFileUrl() {
    const { personalDepositAccountAgreementAndScheduleOfFeesAxos } = this.settings;

    return personalDepositAccountAgreementAndScheduleOfFeesAxos;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
