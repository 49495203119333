import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { FacingBrand } from '@core/enums';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { ServiceResult } from '@shared/models';

import {
  DebitCard,
  DebitCardHistory,
  LimitRequest,
  MarkAsHotRequest,
  ReplacementRequest,
  TravelNotification,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class DebitCardService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'debit-cards');
  }

  /**
   * indicates if card image must use portrait or landscape CSS classes.
   * @returns boolean Flag
   */
  isHorizontalCard() {
    return FacingBrand.UFB === this.settings.facingBrandId;
  }

  /**
   * Marks a card as Active.
   * @param debitCard DebitCard to Activate.
   * @returns debitCard DebitCard.
   */
  activateCard({ accountId, cardNumberMask }: DebitCard) {
    const endpoint = `${accountId}/card/${cardNumberMask}/activate`;

    return this.put<DebitCard>(endpoint).pipe(map(this.mapResToCard(accountId, cardNumberMask)));
  }

  /**
   * Marks a card as Blocked.
   * @param debitCard DebitCard to Block.
   * @returns debitCard DebitCard.
   */
  blockCard({ accountId, cardNumberMask }: DebitCard) {
    const endpoint = `${accountId}/card/${cardNumberMask}/false`;

    return this.put<DebitCard>(endpoint).pipe(map(this.mapResToCard(accountId, cardNumberMask)));
  }

  /**
   * Toggle debitCard status by reversing isBlocked property.
   * @param debitCard DebitCard to Toggle status.
   * @returns debitCard DebitCard
   */
  toggleCardStatus({ accountId, cardNumberMask, isBlocked }: DebitCard) {
    const status = !isBlocked;
    const endpoint = `${accountId}/card/${cardNumberMask}/${status}`;

    return this.put<DebitCard>(endpoint).pipe(map(this.mapResToCard(accountId, cardNumberMask)));
  }

  /**
   * Gets debitCard image url according FacingBrand.
   * @param isDebitCardAtm Flag param to force dummy image (UFB brand only).
   * @returns boolean Flag
   */
  getDebitCardImage(isDebitCardAtm: boolean = false): string {
    return this.settings.facingBrandId === FacingBrand.Nationwide && isDebitCardAtm
      ? 'assets/axos/img/debit-card.png'
      : `assets/${this.settings.brand}/img/debit-card.png`;
  }

  /**
   * Gets Debit card recent activity.
   * @param debitCard DebitCard to get History.
   * @returns Array of DebitCardHistory.
   */
  getHistory({ accountId, cardNumberMask }: DebitCard) {
    const lastFourDigits = cardNumberMask.split('*').pop();
    const endpoint = `history/${accountId}/${lastFourDigits}`;

    return this.get<DebitCardHistory[]>(endpoint);
  }

  /**
   * Gets travel notifications list.
   * @param debitCard DebitCard to get Travel Notifications.
   * @returns Array of TravelNotification.
   */
  getTravelNotifications({ debitCardId }: DebitCard) {
    const endpoint = `travelNotifications/${debitCardId}`;

    return this.get<TravelNotification[]>(endpoint).pipe(map(res => res.data.map(row => new TravelNotification(row))));
  }

  /**
   * Delete Travel Notification on Debit Card.
   * @param travelNotification TravelNotification to Delete.
   * @param debitCard DebitCard related to notification to Delete.
   * @returns boolean Flag.
   */
  deleteTravelNotification(travelNotification: TravelNotification, { accountId, cardNumberMask }: DebitCard) {
    const endpoint = `${accountId}/deleteTravelNotification`;
    const payload = {
      travelNotification,
      cardMask: cardNumberMask,
    };

    return this.put<boolean>(endpoint, payload);
  }

  /**
   * Posts a request to increase temporary the current card limit
   * @param accountId Unique account's identifier
   * @param limitRequest Object containing the limit request
   * @return An string indicating if the request was successful or not
   */
  requestTemporaryLimit(accountId: number, limitRequest: LimitRequest) {
    return this.post(`${accountId}/requestLimit`, limitRequest);
  }

  /**
   * Removes a record from Debit Card history.
   * @param recordId recordId to Delete from  History.
   * @returns boolean Flag.
   */
  removeHistoryRecord(recordId: number) {
    const endpoint = `history/${recordId}`;

    return this.delete<boolean>(endpoint);
  }

  /**
   * Mark a card as Hot and indicates if it should be replaced.
   *
   * @param accountId ID of the account to which the card belongs to.
   * @param request Request object containing all of the details of the card.
   *
   * @returns An Observable containing an upated Debit Card with its new status.
   */
  markAsHotCard(accountId: number, request: MarkAsHotRequest) {
    return this.put<DebitCard>(`${accountId}/card/${request.cardMask}/mark-as-hot-card`, request).pipe(
      map(this.mapResToCard(accountId, request.cardMask))
    );
  }

  /**
   * Add travel Notification on Debit Card
   *
   * @param travelNotification travel notification object with the information of the travel
   * @param accountId ID of the account to which the card belongs to.
   * @param cardMask mask of the card belongs to.
   *
   * @returns An Observable containing an updated Debit Card.
   */
  addTravelNotification(travelNotification: TravelNotification, accountId: number, cardMask: string) {
    return this.put(`${accountId}/addTravelNotification`, {
      travelNotification,
      cardMask,
    });
  }

  /**
   * Requests a replacement of a card
   *
   * @param accountId ID of the account to which the card belongs to.
   * @param request Request object containing all of the details of the card.
   *
   * @return An ApiResponse indicating if the request was successful or not
   */
  requestReplacement(accountId: number, request: ReplacementRequest) {
    return this.put<DebitCard>(`${accountId}/replace`, request).pipe(
      map(this.mapResToCard(accountId, request.cardMask))
    );
  }

  private mapResToCard(accountId: number, cardNumberMask: string) {
    return (res: ServiceResult<DebitCard>) => {
      return new DebitCard({ ...res.data, accountId, cardNumberMask });
    };
  }
}
