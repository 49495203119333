import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';

import { DirectDepositUserActions, OnlinePath } from '@app/click-switch/enums';
import { DirectDepositInterceptAction, SubmitTarget, Switch } from '@app/click-switch/models';
import { getInstructions, openWebsiteWarningModal } from '@app/click-switch/utils';
import { UserProfileViews } from '@app/user-profile/enums';
import { OlbSettings } from '@core/models';
import { DialogService, DirectDepositService } from '@core/services';
import { STATE } from '@core/tokens';
import { olbSettings } from '@core/tokens';
import { NavigationIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch-online-path',
  templateUrl: './click-switch-online-path.component.html',
  styleUrls: ['./click-switch-online-path.component.scss'],
})
export class ClickSwitchOnlinePathComponent implements OnInit, AfterViewInit {
  bodyHtml = '';
  incomeSourceName = '';
  incomeSourceUrl = '';
  brandName = '';
  webSite = '';
  steps = [];

  isAcceptedProceed = false;
  isLoading = false;

  result: Partial<Switch>;
  submitTarget: Partial<SubmitTarget>;

  backIcon = NavigationIcons.ArrowBack;
  outDomain = UtilityIcons.NewWindow;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(olbSettings) private settings: OlbSettings,
    private dialogService: DialogService,
    private directDepositService: DirectDepositService
  ) {}

  ngOnInit(): void {
    if (Object.keys(this.state.params.userSwitch).length === 0) {
      this.state.go('udb.clickswitch.search-target');
    } else {
      this.result = this.state.params.userSwitch;
      this.incomeSourceName = this.result.name;
      this.brandName = this.settings.brandName + OnlinePath.bankText;

      const instructions = getInstructions(this.result.ux?.fields);
      Object.assign(this, instructions);
    }
  }

  ngAfterViewInit() {
    let index = document.getElementsByName('stepDescription').length - 1;
    const span = [].slice.call(document.getElementsByName('stepDescription')).reverse();
    span.forEach(s => {
      if (!s.innerText) {
        document.getElementById(s.parentElement.parentElement.id).remove();
        this.steps.splice(index, 1);
        index--;
      }
    });
  }

  goBack(): void {
    this.state.go('udb.clickswitch.search-target');
  }

  checkRequestStatus(): void {
    this.directDepositService
      .logUserAction(DirectDepositInterceptAction(DirectDepositUserActions.CheckRequestStatus))
      .subscribe();
    this.state.go('udb.userProfile', { view: UserProfileViews.DirectDeposit });
  }

  goTheNewWebSite(): void {
    if (this.incomeSourceUrl.length === 0) {
      return;
    }

    this.directDepositService
      .logUserAction(DirectDepositInterceptAction(DirectDepositUserActions.IncomeSource))
      .subscribe();

    openWebsiteWarningModal(this.dialogService, this.webSite)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.directDepositService
            .logUserAction(DirectDepositInterceptAction(DirectDepositUserActions.IncomeSourceSpeedbump))
            .subscribe();

          window.open(this.incomeSourceUrl, '_blank', 'noopener noreferrer');
        }

        this.isAcceptedProceed = result;
      });
  }
}
