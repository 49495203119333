import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { NgxErrorsModule } from '@axos/ngx-errors';
import { NgxMaskModule } from 'ngx-mask';
import { AxosInvestModule } from '@app/axos-invest/axos-invest.module';
import { AppStateFeatures } from '@core/enums';
import {
  dashboardBalanceServiceProvider,
  iWindowServiceProvider,
  materialDialogProvider,
  redirectStateServiceProvider,
  stopPaymentServiceProvider,
  userSubtypeHelperProvider,
  dateRangePickerOptionsProvider,
  filtersHelperServiceProvider,
  loadUserProfileHelperProvider,
  wiseBanyanServiceProvider,
} from '@core/providers';
import { SharedModule } from '@shared/shared.module';
import { sharedComponents, standaloneComponents } from './components';
import { materialImports } from './config';
import { accountReducers } from './store/reducers';
import { accountSubmodules } from './submodules';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TransactionHistoryModule } from '@app/transaction-history/transaction-history.module';
import { PfmModule } from '@app/pfm/pfm.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthorizeTransfersTransferOnlyModule } from '@app/Areas/AAS/features/authorize-transfers-transfers-only/authorize-transfers-transfer-only.module';
import { ShareAccountModule } from './components/modals/share-account-ria/shared-module.module';

export function playerFactory() {
  return player;
}

const providers = [
  stopPaymentServiceProvider,
  iWindowServiceProvider,
  redirectStateServiceProvider,
  dashboardBalanceServiceProvider,
  userSubtypeHelperProvider,
  { provide: MatDialogRef, useValue: {} },
  dateRangePickerOptionsProvider,
  filtersHelperServiceProvider,
  iWindowServiceProvider,
  loadUserProfileHelperProvider,
  wiseBanyanServiceProvider,
];

@NgModule({
  declarations: [...sharedComponents],
  imports: [
    ...accountSubmodules,
    ...materialImports,
    ...standaloneComponents,
    AxosInvestModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgxErrorsModule,
    StoreModule.forFeature(AppStateFeatures.Accounts, accountReducers),
    RouterModule,
    LottieModule.forRoot({ player: playerFactory }),
    RouterModule,
    DragDropModule,
    TransactionHistoryModule,
    PfmModule,
    AuthorizeTransfersTransferOnlyModule,
    ShareAccountModule,
  ],
  providers: [materialDialogProvider, CurrencyPipe, DatePipe, ...providers],
})
export class AccountsModule {}
