import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { signatureCardComponents } from './components';

@NgModule({
  imports: [SharedModule],
  declarations: [...signatureCardComponents],
  exports: [...signatureCardComponents],
})
export class SignatureCardModule {}
