import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { HistoricalBalanceCalculation } from '@app/pfm/models/historical-balance-calculation.model';
import { NetWorthPoint } from '@app/pfm/models/net-worth-point.model';
import { BalanceService } from '@legacy/services/balance.service';
import { CategoryName } from '@legacy/tiles/account-overview/typings/CategoryName.enum';
import { AggregatedAccount } from '@legacy/typings/app/account-aggregation';
import { ExternalBankProvider } from '@shared/models';
@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountsListComponent implements OnChanges {
  @Input() currentNetWorth: NetWorthPoint;
  @Input() previousNetWorth: NetWorthPoint;
  @Input() providers: ExternalBankProvider[];
  @Input() accounts: AccountOverview[];
  @Input() excludedAccountsId: Set<string>;
  historicBalanceCalculated: HistoricalBalanceCalculation[];
  accountsToDisplay = {
    checking: new Array<AccountOverview>(),
    saving: new Array<AccountOverview>(),
    investing: new Array<AccountOverview>(),
    creditCard: new Array<AccountOverview>(),
    loan: new Array<AccountOverview>(),
    other: new Array<AccountOverview>(),
    closedAccounts: Array<AccountOverview>(),
  };
  categoryTypeEnum = CategoryName;
  historicalBalancesSubject = new BehaviorSubject<boolean>(true);
  calculatingHistoricalBal$ = this.historicalBalancesSubject.asObservable();
  headersToDisplayByCategory = CategoryName.Default;
  constructor(private readonly _balanceService: BalanceService) {}

  getHistoricBalancesByAccountType(categoryTypeEnum: CategoryName): HistoricalBalanceCalculation[] {
    const historicalBalances = this.historicBalanceCalculated.filter(
      (history: HistoricalBalanceCalculation) => history.categoryName === categoryTypeEnum
    );

    return historicalBalances;
  }
  ngOnChanges(): void {
    if (!this.accounts || !this.providers) return;
    this.processBalances();
  }
  processBalances() {
    this.historicBalanceCalculated = this.calculateBalance(this.accounts, this.previousNetWorth, this.currentNetWorth);
    this.loadByAccountType(this.accounts);
  }
  loadByAccountType(accounts: AccountOverview[]) {
    this.accountsToDisplay.checking = accounts.filter(
      (item: AccountOverview) => item.categoryName === CategoryName.Checking
    );
    this.displayHeaders([], this.accountsToDisplay.checking, CategoryName.Checking);

    this.accountsToDisplay.saving = accounts.filter(
      (item: AccountOverview) => item.categoryName === CategoryName.Savings
    );
    this.displayHeaders(this.accountsToDisplay.checking, this.accountsToDisplay.saving, CategoryName.Savings);

    this.accountsToDisplay.investing = this.sortAccounts(
      accounts.filter((item: AccountOverview) => item.categoryName === CategoryName.Investment)
    );
    this.displayHeaders(this.accountsToDisplay.saving, this.accountsToDisplay.investing, CategoryName.Investment);

    this.accountsToDisplay.creditCard = accounts.filter(
      (item: AccountOverview) => item.categoryName === CategoryName.CreditCards
    );
    this.displayHeaders(this.accountsToDisplay.investing, this.accountsToDisplay.creditCard, CategoryName.CreditCards);

    this.accountsToDisplay.loan = accounts.filter((item: AccountOverview) => item.categoryName === CategoryName.Loans);
    this.displayHeaders(this.accountsToDisplay.creditCard, this.accountsToDisplay.loan, CategoryName.Loans);

    this.accountsToDisplay.other = accounts.filter((item: AccountOverview) => item.categoryName === CategoryName.Other);
    this.displayHeaders(this.accountsToDisplay.loan, this.accountsToDisplay.other, CategoryName.Other);

    this.historicalBalancesSubject.next(false);
  }
  displayHeaders(
    previousAccountsByCategory: AccountOverview[],
    accountsByCategory: AccountOverview[],
    category: CategoryName
  ): CategoryName {
    if (accountsByCategory.length > 0) {
      if (previousAccountsByCategory.length > 0) {
        if (this.headersToDisplayByCategory === CategoryName.Default) {
          this.headersToDisplayByCategory = category;
        }
      } else {
        if (this.headersToDisplayByCategory === CategoryName.Default) {
          this.headersToDisplayByCategory = category;
        }
      }
    }

    return this.headersToDisplayByCategory;
  }
  calculateBalance(
    accounts: AccountOverview[],
    previousNetWorth: NetWorthPoint,
    currentNetWorth: NetWorthPoint
  ): HistoricalBalanceCalculation[] {
    const historicalResult: HistoricalBalanceCalculation[] = [];
    accounts.forEach(account => {
      const previousBalance = previousNetWorth?.worth.accountWorths.find(balance => balance.account.id === account.id);
      const currentBalance = currentNetWorth?.worth.accountWorths.find(balance => balance.account.id === account.id);

      // previous is null AND current is null
      //   percentage change: --%
      //   amt. change: $--
      //   acct. balance: $--

      // previous is null AND current w/value
      //   percentage change: --%
      //   amt. change: current.balance
      //   acct. balance: current.balance

      // previous w/value AND current is null
      //   percentage change: --%
      //   amt. change: [negative]current.balance
      //   acct. balance: $--

      const newHistorical = new HistoricalBalanceCalculation();
      newHistorical.accountId = account.id;
      newHistorical.categoryName = account.categoryName;

      // Formula; (today's total balance - previous total balance) / (previous total balance)
      if (currentBalance && previousBalance && previousBalance.balance !== 0) {
        newHistorical.percentageChange = (currentBalance.balance - previousBalance.balance) / previousBalance.balance;
      } else {
        newHistorical.percentageChange = null;
      }
      newHistorical.arrowPosition = this.arrowPercentagePosition(newHistorical.percentageChange);

      // Formula; (today's total balance - previous total balance)
      if (!currentBalance && !previousBalance) {
        newHistorical.amountChange = null;
      } else {
        newHistorical.amountChange = (currentBalance?.balance || 0) - (previousBalance?.balance || 0);
      }

      // Get balance from the account if there is no current historical balance
      newHistorical.currentBalance =
        currentBalance?.balance === undefined ? this._balanceService.getBalance(account) : currentBalance?.balance;

      historicalResult.push(newHistorical);
    });

    return historicalResult;
  }
  sortAccounts(accounts: AccountOverview[]): AccountOverview[] {
    return accounts.sort((a: AggregatedAccount, b: AggregatedAccount) => (a?.isExternal ? -1 : b?.isExternal ? -1 : 1));
  }
  private arrowPercentagePosition(percentageChange: number): string {
    let arrowPosition = '';
    // round to the nearest whole % will be performed by percentageRound pipe
    // if the % Change is positive = upward arrow
    if (percentageChange > 0) {
      arrowPosition = 'upward arrow';
    }
    // if the % Change is negative = downward arrow
    if (percentageChange < 0) {
      arrowPosition = 'downward arrow';
    }
    // will be performed by percentageRound pipe
    //  if the % Change is zero = 0%
    if (percentageChange === 0 || percentageChange === null) {
      arrowPosition = 'no arrow';
    }

    return arrowPosition;
  }
}
