import { Component, Input } from '@angular/core';

import { PortfolioDetails } from '@app/axos-invest/models';

@Component({
  selector: 'app-main-portfolio-details',
  templateUrl: './main-portfolio-details.component.html',
  styleUrls: ['./main-portfolio-details.component.scss'],
})
export class MainPortfolioDetailsComponent {
  @Input() hasError: boolean;
  @Input() details: PortfolioDetails;
}
