import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';

@Component({
  selector: 'app-portfolio-graph',
  templateUrl: './portfolio-graph.component.html',
  styleUrls: ['./portfolio-graph.component.scss'],
})
export class PortfolioGraphComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() portfolioData: any;
  @ViewChild('chart') chartRef;

  chart: Chart;
  options: any;
  errorData = {
    datasets: [
      {
        data: Array.from({ length: 8 }).fill('12.5'),
        backgroundColor: Array.from({ length: 8 }).fill('rgb(255 225 232)'),
        fill: true,
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.chart) {
      this.chart.chart.destroy();
    }
    if (this.chartRef) {
      this.initialize();
    }
  }

  ngAfterViewInit(): void {
    this.initialize();
  }

  initialize() {
    this.options = this.portfolioData.isError ? this.getErrorOptions() : this.getOptions();

    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'doughnut',
      data: this.portfolioData.isError ? this.errorData : this.portfolioData,
      options: this.options,
    });
  }

  getOptions() {
    const options = {
      legend: {
        display: false,
      },
      rotation: Math.PI,
      tooltips: {
        enabled: true,
        backgroundColor: '#333D46',
        titleFontColor: 'white',
        titleFontSize: 0,
        bodyFontColor: 'white',
        displayColors: false,
        callbacks: {
          label: (tooltipItem, data) => {
            return this.getLabel(tooltipItem, data);
          },
        },
      },
    };

    return options;
  }

  getErrorOptions() {
    const options = {
      legend: {
        display: false,
      },
      rotation: Math.PI,
      tooltips: false,
      hover: false,
    };

    return options;
  }

  getLabel(tooltipItem, data) {
    const label = data.labels[tooltipItem['index']];
    const val = data.datasets[0].data[tooltipItem['index']];

    return label + ': ' + val + '%';
  }
}
