import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ScheduledTransferRequest } from '@app/axos-trading/models/scheduled-transfer-request.model';
import { ScheduledTransferResponse } from '@app/axos-trading/models/scheduled-transfer-response.model';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class TradingTransferScheduleService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'trading');
  }

  getTransferTradingSchedule(): Observable<ScheduledTransferResponse> {
    return this.get<ScheduledTransferResponse>('transfers/schedule').pipe(map(result => result.data));
  }

  transferTradingSchedule(
    transaction: ScheduledTransferRequest,
    isExternal?: boolean
  ): Observable<ScheduledTransferResponse> {
    return this.post<ScheduledTransferResponse>(`transfers/schedule?isExternal=${isExternal}`, transaction).pipe(
      map(result => result.data)
    );
  }
}
