<div class="tile-modal not-movable" [ngClass]="[modifier, isActive ? 'isActive' : '', statusIcon ? 'hasStatus' : '']">
  <div class="tile-modal__heading frow middle center">
    <i class="finline tile-modal__icon" *ngIf="statusIcon" [ngClass]="statusIcon"></i>

    <h1 class="tile-modal__title tile-modal__title--clear" *ngIf="statusIcon" [textContent]="config.title"></h1>
  </div>

  <div class="tile-modal__content frow between">
    <h1 class="tile-modal__title fcol-cs-12" *ngIf="statusIcon" [textContent]="config.title"></h1>

    <p class="tile-modal__message fcol-cs-12" [textContent]="config.message"></p>

    <div
      class="tile-modal__actions fcol-cs-12 frow self-end"
      ng-hide="config.okText === 'Transferring'"
      [ngClass]="{
        center: !config.cancelAction,
        between: !!config.cancelAction,
        'dual-btn-container': !!config.cancelAction
      }"
    >
      <button
        class="uk-btn secondary outline sm tile-modal__action tile-modal__action--cancel"
        (click)="config.cancelAction()"
        [textContent]="config.cancelText || 'Cancel'"
        *ngIf="!!config.cancelAction"
      ></button>

      <button
        class="uk-btn primary solid sm tile-modal__action tile-modal__action --confirm"
        (click)="config.okAction()"
        [textContent]="config.okText || 'Ok'"
      ></button>
    </div>

    <div class="fcol-cs-12">
      <app-dot-loader [showWhen]="config.okText === 'Transferring'">Saving</app-dot-loader>
    </div>
  </div>
</div>
