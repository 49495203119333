import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-ssn-entry',
  templateUrl: './ssn-entry.component.html',
  styleUrls: ['./ssn-entry.component.scss'],
})
export class SsnEntryComponent {
  @Output() validSsn = new EventEmitter();
  error = '';
  pattern = { 0: { pattern: new RegExp('\\d'), symbol: '*' } };
  hidden = true;
  ssn = '';

  constructor() {}

  /**
   * Checks SSN validity
   */
  checkSsnValidity(): void {
    if (!this.ssn) {
      this.error = 'Social security number is required.';
    } else if (/^(?!000|666|9\d{2}|0{8}|1{8}|3{8}|123456789)\d{9}$/.test(this.ssn)) {
      this.error = '';
      this.validSsn.emit(this.ssn);
    } else {
      this.error = 'Entered social security number is invalid.';
    }
  }
}
