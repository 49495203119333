export enum PaymentStates {
  Canceled,
  Cleared,
  Created,
  Delivered,
  FailedFunding,
  InProcess,
  Mailed,
  Pending,
  PostalReturn,
  Returned,
  ReturnedMoneyOwed,
  ReturnedRefunded,
  PaymentStopped,
  Undeliverable,
}
