import { DepositType, TransactionType } from '@app/axos-invest/enums';

export class Transfer {
  accountNumber: string;
  milestoneId: string;
  amount: number;
  transactionType: TransactionType;
  depositType: DepositType;
  accountId: number;
  contributionYear?: number;
}
