export enum UtilityIcons {
  ArrowDown = 'utility-arrow-down',
  ArrowUp = 'utility-arrow-up',
  Equal = 'utility-equal',
  Backspace = 'utility-backspace',
  Bell = 'utility-bell',
  Box = 'utility-box',
  BoxArrowDown = 'utility-box-arrow-down',
  Calculator = 'utility-calculator',
  CalculatorCheck = 'utility-calculator-check',
  Camera = 'utility-camera',
  Checkmark = 'utility-checkmark',
  CircleCheck = 'utility-circle-check',
  Clock = 'utility-clock',
  ClockArrowBack = 'utility-clock-arrow-back',
  ClockArrowForward = 'utility-clock-arrow-forward',
  Cog = 'utility-cog',
  Download = 'utility-download',
  ExpandColumn = 'utility-expand-column',
  ExpandRow = 'utility-expand-row',
  FilterFunnel = 'utility-filter-funnel',
  FilterSlider = 'utility-filter-slider',
  Flag = 'utility-flag',
  Folder = 'utility-folder',
  Heart = 'utility-heart',
  Hourglass = 'utility-hourglass',
  LocationPin = 'utility-location-pin',
  Lock = 'utility-lock',
  MagnifyingGlass = 'utility-magnifying-glass',
  MagnifyMinus = 'utility-magnify-minus',
  MagnifyPlus = 'utility-magnify-plus',
  Minus = 'utility-minus',
  MinusCircle = 'utility-minus-circle',
  NewWindow = 'utility-new-window',
  PaperAirplane = 'utility-paper-airplane',
  PaperAirplane2 = 'utility-paper-airplane-2',
  PaperClip = 'utility-paper-clip',
  Plus = 'utility-plus',
  PlusCircle = 'utility-plus-circle',
  Redo = 'utility-redo',
  Ribbon = 'utility-ribbon',
  Star = 'utility-star',
  Tag = 'utility-tag',
  ThumbsDown = 'utility-thumbs-down',
  ThumbsUp = 'utility-thumbs-up',
  Tile = 'utility-tile',
  Tile2 = 'utility-tile-2',
  Tile3 = 'utility-tile-3',
  Trash = 'utility-trash',
  Undo = 'utility-undo',
  Unlock = 'utility-unlock',
  Upload = 'utility-upload',
  UploadPhoto = 'utility-upload-photo',
  Video = 'utility-video',
  VideoChat = 'utility-video-chat',
  Meatball = 'utility-menu-meatball',
  Kebab = 'utility-menu-kebab',
}
