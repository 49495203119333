/** Represents the BofiProductCategory. */
export enum AccountCategory {
  Dda = 1,
  Sav,
  Loan,
  Cd,
  Credit,
  Other,
  Unknown,
}
