import { Component, Inject, OnInit } from '@angular/core';

import { getBrandPropertyValue } from '@app/utils';
import { olbSettings, ROOT_SCOPE } from '@core/tokens';
import { BrandPropertyService } from '@legacy/services/brand-property.service';
import { GlanceCobrowseService } from '@legacy/services/cobrowse.service';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-glance',
  template: '',
})
export class GlanceComponent implements OnInit {
  glanceProperties: BrandProperty[];

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private glanceCobrowseService: GlanceCobrowseService,
    private brandPropertyService: BrandPropertyService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit() {
    this.loadGlanceProperties(this.settings.brandId);
  }

  private loadGlanceProperties(brandId: number) {
    this.brandPropertyService
      .getSecureSupportProperties(brandId)
      .then(res => {
        this.glanceProperties = res.data;
        this.setGlanceProperties();
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }

  private setGlanceProperties() {
    if (this.serviceHelper.isUserAuthenticated()) {
      this.loadUserInfo();
    } else {
      this.setupGlance();
    }
  }

  private loadUserInfo() {
    if (this.root['profileInfo']) {
      this.setupGlance();
    } else {
      this.root.$on('profileInfoLoaded', () => this.setupGlance());
    }
  }

  private setupGlance() {
    const profileInfo = this.root['profileInfo'];
    this.glanceCobrowseService.src = getBrandPropertyValue(this.glanceProperties, 'ScriptUrl');
    this.glanceCobrowseService.dataSite = getBrandPropertyValue(this.glanceProperties, 'DataSite');

    if (profileInfo?.cif) {
      this.glanceCobrowseService.setVisitorId(profileInfo.cif);
    }
    this.glanceCobrowseService.generateScript();
  }
}
