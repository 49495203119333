import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { validEmailRegex } from '@app/config/regexes';
import { ProfileType } from '@app/user-profile/enums';
import { UpdateEmailsRequest } from '@app/user-profile/models';
import { BusinessService, UserProfileService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { PayBillsHelper } from '@legacy/bill-pay/pay-bills.helper';
import { ServiceHelper } from '@legacy/services/service.helper';
import { CompanyDetail, CustomerDetail } from '@shared/models';
import { BusinessDetail } from '@shared/models/business-detail.model';

@Component({
  selector: 'up-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['../../scss/user-profile.common.scss'],
})
export class ChangeEmailComponent implements OnInit {
  @Input() userProfile: Partial<CustomerDetail>;
  @Input() profileType = ProfileType.Personal;
  @Input() selectedBusinessId = 0;
  @Output() back = new EventEmitter<null>();
  @Output() updateEmails = new EventEmitter<UpdateEmailsRequest>();
  @Output() updateBusinessMail = new EventEmitter<Partial<BusinessDetail>>();

  changeEmailsForm: UntypedFormGroup;
  isBusy = false;
  businessDataModel: BusinessDetail;
  companyDataModel: CompanyDetail;
  emailMaxLength = 68;

  profileTypes = ProfileType;

  constructor(
    private fb: UntypedFormBuilder,
    private userProfileService: UserProfileService,
    private businessService: BusinessService,
    private serviceHelper: ServiceHelper,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit(): void {
    this.companyDataModel = null;
    this.createForm();
  }

  updateEmailAddresses() {
    if (this.changeEmailsForm.invalid) {
      this.changeEmailsForm.markAllAsTouched();
    } else {
      switch (this.profileType) {
        case ProfileType.Business:
          this.updateBusinessEmail();
          break;
        case this.profileTypes.Company:
          this.updateCompanyEmail();
          break;
        default:
          {
            this.isBusy = true;
            const { primaryEmail, alternateEmail } = this.changeEmailsForm.value as UpdateEmailsRequest;
            const request: UpdateEmailsRequest = {
              primaryEmail: primaryEmail.toLowerCase(),
              alternateEmail: alternateEmail ? alternateEmail.toLowerCase() : '',
              selectedBusinessId: 0,
            };

            this.userProfileService
              .updateEmailAddresses(request)
              .pipe(
                finalize(() => {
                  this.isBusy = false;
                })
              )
              .subscribe({
                next: () => {
                  this.$rootScope['userInfo'].email = request.primaryEmail;
                  sessionStorage.setItem('isEnrolled', 'true');
                  sessionStorage.removeItem(PayBillsHelper.invalidEmail);
                  this.updateEmails.emit(request);
                  this.back.emit();
                },
                error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
              });
          }
          break;
      }
    }
  }

  private createForm(): void {
    switch (this.profileType) {
      case ProfileType.Business:
        this.businessDataModel = this.userProfile.businessInformation;

        this.changeEmailsForm = this.fb.group({
          primaryEmail: this.fb.control(this.userProfile.businessInformation.workEmail, [
            Validators.maxLength(this.emailMaxLength),
            Validators.pattern(validEmailRegex),
          ]),
        });
        break;
      case this.profileTypes.Company:
        {
          this.changeEmailsForm = this.fb.group({
            primaryEmail: this.fb.control(this.userProfile.primaryEmail, [
              Validators.required,
              Validators.pattern(validEmailRegex),
            ]),
          });
        }
        break;
      default:
        this.changeEmailsForm = this.fb.group({
          primaryEmail: this.fb.control(this.userProfile.primaryEmail, [
            Validators.maxLength(this.emailMaxLength),
            Validators.required,
            Validators.pattern(validEmailRegex),
          ]),
          alternateEmail: this.fb.control(this.userProfile.alternateEmail ?? '', [
            Validators.pattern(validEmailRegex),
            Validators.maxLength(this.emailMaxLength),
          ]),
        });

        this.emailControlMaxLengthValidation('alternateEmail');
        break;
    }

    this.emailControlMaxLengthValidation('primaryEmail');
  }

  private updateBusinessEmail() {
    this.isBusy = true;
    const businessUpdate = { ...this.businessDataModel };
    const { primaryEmail } = this.changeEmailsForm.value;
    businessUpdate.workEmail = primaryEmail;

    this.businessService.updateBusinessInformationEmail(businessUpdate).subscribe({
      next: result => {
        this.updateBusinessMail.emit(result.data);
        this.back.emit();
      },
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
    });
  }

  private updateCompanyEmail(): void {
    this.isBusy = true;
    const { primaryEmail } = this.changeEmailsForm.value;
    const alternateEmail = '';
    const businessId = this.selectedBusinessId;

    this.businessService.updateCompanyEmailAddresses(primaryEmail, alternateEmail, businessId).subscribe({
      next: () => {
        const request: UpdateEmailsRequest = {
          primaryEmail: primaryEmail.toLowerCase(),
          alternateEmail: alternateEmail.toLowerCase(),
          selectedBusinessId: businessId,
        };
        this.updateEmails.emit(request);
        this.back.emit();
      },
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
    });
  }

  private emailControlMaxLengthValidation(controlId: string) {
    const emailControl = this.changeEmailsForm.get(controlId);

    if (emailControl.value.length > this.emailMaxLength) {
      emailControl.markAsTouched();
    }
  }
}
