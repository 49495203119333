import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizedPartiesComponent } from './view/authorized-parties.component';

@NgModule({
  declarations: [AuthorizedPartiesComponent],
  imports: [CommonModule],
  exports: [AuthorizedPartiesComponent],
})
export class AuthorizedPartiesModule {}
