export enum AccountCategory {
  Dda = 1,
  Sav,
  Loan,
  Cd,
  Credit,
  Other,
  Unknown,
}

export const categoryMap = {
  [AccountCategory.Dda]: 'checking',
  [AccountCategory.Sav]: 'savings',
  [AccountCategory.Loan]: 'loan',
  [AccountCategory.Cd]: 'cd',
  [AccountCategory.Credit]: 'credit',
  [AccountCategory.Other]: 'other',
  [AccountCategory.Unknown]: 'unknown',
};
