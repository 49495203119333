<div class="dialog-backdrop d-flex align-items-center justify-content-center" (click)="closeModal()">
  <form novalidate [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="dialog-box" (click)="$event.stopPropagation()">
      <div class="dialog-body px-5 py-4 d-flex flex-column">
        <h3 class="mb-3 text-center font-family">Let's Get Started on Crypto</h3>
        <div class="center text-center">
          Before you get started, please link the bank account you want to use for your Crypto account.
        </div>
        <div class="mt-4 account-modal">
          Account
          <select name="account" id="account" class="uk-input" formControlName="accountId">
            <option value="" [innerText]="accountsLoaded ? 'Select Account' : 'Loading...'" disabled></option>
            <optgroup label="Internal Accounts" *ngIf="internalAccounts && internalAccounts.length > 0">
              <option *ngFor="let internalAccount of internalAccounts" [value]="internalAccount.id">
                {{ internalAccount.nickname }} (Avail. Bal: {{ internalAccount.availableBalance | currency }})
              </option>
            </optgroup>
          </select>
          <div class="text--error" *ngIf="form.get('accountId').errors?.required && this.submitted">
            Please complete this field
          </div>
        </div>
      </div>
      <div class="dialog-footer p-1 d-flex flex-column align-items-center">
        <app-dot-loader *ngIf="isLoadingUpdate">Saving...</app-dot-loader>
        <div *ngIf="!isLoadingUpdate">
          <button
            class="btn-ok mt-3 mb-3 btn-outline"
            type="submit"
            [disabled]="form.get('accountId').errors?.required && this.submitted"
          >
            Apply and Continue
          </button>
          <button class="btn-cancel btn-outline" (click)="closeModal()">Cancel</button>
        </div>
        <div class="box-message mt-4 px-4 py-4">
          <strong>NOTE:</strong>
          By clicking the “Apply” button, the Customer gives consent to receive text messages from Axos Bank. Standard
          text messaging rates may apply.
        </div>
      </div>
    </div>
  </form>
</div>
