<div class="frequency-picker">
  <div style="display: inline;" (click)="toggleDropdown()">
    <p *ngIf="frequencyText" [innerHTML]="frequencyText"></p>
    <a class="link" *ngIf="!frequencyText">[Frequency]</a>
  </div>
  <div class="dropdown-alert" *ngIf="showDropdown">
    <div class="list-inner-container">
      <div class="arrow"></div>
      <div class="list-item">
        <p>Select when you like your balance snapshot.</p>
        <div class="inline-elem">
          <div class="frow row row-pad">
            <!-- [ngClass]="{
            'dropdowns-align': frequencySelected.frequency === 'Weekly' || frequencySelected.frequency === 'BiWeekly'
          }" -->
            <div class="fcol-md-5">
              <select
                name="frequency"
                title="frequency"
                class="uk-input"
                id="frequency"
                [(ngModel)]="frequencySelected"
                (change)="onFrequencySelected()"
              >
                <!-- ng-options="freq as freq.title for freq in $fsc.frequencyOptions" -->

                <option [ngValue]="freq" *ngFor="let freq of frequencyOptions">{{ freq.title }}</option>
              </select>
            </div>
            <div class="middle">
              <p style="display: block;" class="condition-text" *ngIf="frequencySelected.type === 'date'">Starting</p>
              <p
                style="display: block;"
                class="condition-text"
                *ngIf="frequencySelected.type === 'day' && frequencySelected.frequency != 'Daily'"
              >
                On
              </p>
            </div>
            <div class="fcol-md-5 date-box" *ngIf="frequencySelected.type === 'date'">
              <p class="payment-calculator-item">
                <span class="flex-row--flex-end">
                  <input
                    [matDatepicker]="startDatePicker"
                    [min]="minDate"
                    (click)="startDatePicker.open()"
                    type="text"
                    name="date"
                    id="date"
                    aria-label="Date range"
                    class="uk-input date-input"
                    readonly
                    required
                    [(ngModel)]="fromDatePicker"
                  />
                  <button type="button" class="date-icon" (click)="startDatePicker.open()">
                    <app-svg-icon
                      [iconName]="iconConfig.calendar.icon"
                      [size]="iconConfig.calendar.size"
                    ></app-svg-icon>
                  </button>
                  <mat-datepicker #startDatePicker></mat-datepicker>
                </span>
              </p>
            </div>
            <div class="fcol-md-5" *ngIf="frequencySelected.type === 'day' && frequencySelected.frequency != 'Daily'">
              <select name="frequency" aria-label="Frequency" class="uk-input" id="frequency" [(ngModel)]="daySelected">
                <!-- (change)="change()" -->

                <!-- ng-options="day as day.title for day in $fsc.dayOptions" -->

                <option [ngValue]="day" *ngFor="let day of dayOptions">{{ day.title }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="inline-but">
          <button class="uk-btn outline primary sm" type="button" (click)="cancelChanges()">
            Cancel
          </button>
          <button class="uk-btn solid secondary sm" type="button" (click)="saveChanges()">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
