<div class="header-section-title text-center">
  <div>
    <h1>Privacy Preferences</h1>
    <p class="hide-cs hide-xs" id="padding-top-account">We respect your privacy and information.</p>
  </div>
</div>
<div class="hr-line"></div>
<div class="privacy-preferences-container" id="container">
  <div class="privacy-preferences-text-container">
    <p class="privacy-preferences-text">
      Thank you for your continued business. We are fully committed to protecting your privacy and personal information.
      Your previously selected privacy choice(s) remain in effect until you state otherwise. You may make your privacy
      choice(s) at any time.
      <label *ngIf="!isLoading" class="privacy-preferences-text">
        The full Privacy Notice is available
      </label>
      <a
        *ngIf="!isLoading"
        title="Click to view the full privacy notice in detail"
        href="{{ privacyUrl }}"
        target="_blank"
        class="privacy-link"
      >
        here.
      </a>
    </p>
    <p class="privacy-preferences-text">
      To limit our sharing, select all Privacy preferences that apply below.
    </p>
    <div class="error-message-div" *ngIf="isError">
      <i class="bofi-close red-icon" (click)="isError = false"></i>
      <p>
        <i class="bofi-warning red-icon"></i>
        Changes have not been saved. Make sure to save your changes before leaving the page.
      </p>
    </div>
    <div class="error-success-div" *ngIf="isSuccess">
      <i class="bofi-close green-icon" (click)="isSuccess = false"></i>
      <p>
        <i class="bofi-check-mark green-icon"></i>
        Success! Changes to your Privacy Preferences have been saved.
      </p>
    </div>
  </div>
  <app-dot-loader [showWhen]="isLoading">{{ loadingText }}</app-dot-loader>
    <div class="privacy-preferences-checkbox-container fcol-md-9 fcol-md-offset-1" [ngClass]="{'isHiden': isLoading,'isShown':!isLoading}">
      <form (ngSubmit)="SavePreferences(checkboxesForm)" #checkboxesForm="ngForm" id="chkFrm">
        <div class="checkbox-container">
          <input
            type="checkbox"
            class="checkbox"
            id="personal"
            name="personal"
            [(ngModel)]="preferences.privacyDoNotShareCreditPersonalInfo"
            ngModel
          />
          <label for="personal" class="">
            Do not share information about my credit worthiness with your affiliates for their
            everyday business purposes.
          </label>
        </div>
        <div class="checkbox-container">
            <input
              type="checkbox"
              class="checkbox"
              id="allow"
              [(ngModel)]="preferences.privacyDoNotMarketPersonalInfo"
              name="allow"
              ngModel
            />
            <label for="allow" class="">
              Do not allow your affiliates to use my personal information to market to me.
            </label>
          </div>
          <div class="checkbox-container">
            <input
              type="checkbox"
              class="checkbox"
              id="share"
              [(ngModel)]="preferences.privacyDoNotSharePersonalInfoNonAffiliates"
              name="share"
              ngModel
            />
            <label for="share" class="checkbox">
              Do not share my personal information with nonaffiliates to market their products and
              services to me.
            </label>
          </div>
      </form>
    </div>
    <div class="privacy-preferences-notes-container">
        <p class="privacy-preferences-notes-text">
          <span class="">Please note:</span>
          It may take up to 5 days for your updated privacy preferences to take effect. If you share an
          account with others, your preferences will apply to everyone on the account.
        </p>
        <p class="privacy-preferences-notes-text">
          If you are a new customer, we can begin sharing your information 30 days from the date we sent
          this notice. When you are no longer our customer, we continue to share your information as
          described in this notice. However, you can contact us at any time to limit our sharing.
        </p>
      </div>
      <div class="privacy-preferences-button-container">
        <button
          class="uk-btn outline primary lg privacy-preferences-button"
          type="button"
          form="chkFrm"
          (click)="goToMyAlerts(checkboxesForm.pristine)"
        >
          Back To Alerts
        </button>
        <button
          class="uk-btn lg solid secondary privacy-preferences-button"
          form="chkFrm"
          (click)="checkboxesForm.ngSubmit.emit()"
          [disabled]="!checkboxesForm.dirty || isLoading"
        >
          Save My Preferences
        </button>
      </div>
</div>
