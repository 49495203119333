<div class="dialog-header" [style.backgroundImage]="">
    <app-svg-icon
    class="dialog-header__icon"
    [iconName]="icon.name"
    [size]="icon.size"
    ></app-svg-icon>
</div>
<h3 mat-dialog-title class="header-title">
    Please Contact Your Advisor
</h3>
<div id="ria-distribution-content" mat-dialog-content class="mat-dialog-content">
    <p class="text-center">
        Action is required by your advisor to complete distributions from an advisor-managed account.
    </p>
</div>
<div mat-dialog-actions>
    <button id="ria-distribution-close" class="btn-ok" [mat-dialog-close]="true" cdfFocusInitial>Close</button>
</div>
