import { createSelector } from '@ngrx/store';

import { TransactionType } from '@app/axos-invest/enums';

import { getInvestState } from '../invest.state';

export const getScheduledTransfers = createSelector(getInvestState, state => state.scheduledTransfers);

/**
 * Gets all the scheduled transfers that match with the search criteria
 *
 * @param milestoneId Milestone ID.
 * @param transactionType Deposit/Withdrawal.
 *
 * @returns All the transfers that match the milestone id and the transaction type
 */
export const getScheduledTransfersByMilestoneIdAndTransferType = (
  milestoneId: string,
  transactionType: TransactionType
) =>
  createSelector(getScheduledTransfers, state =>
    state.filter(m => m.milestoneId === milestoneId && m.transactionType === transactionType)
  );
