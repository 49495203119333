import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MessagesComponent implements OnInit, OnDestroy {
  constructor() {}

  /** Initializes the controller. */
  ngOnInit(): void {
    console.log('Message Init');
  }

  /** Cleans up the controller. */
  ngOnDestroy(): void {}
}
export enum MessagesType {
  INBOX = 0,
  ARCHIVED = 1,
}
export function mapTypes(type: number): string {
  switch (type) {
    case MessagesType.INBOX:
      return 'Inbox';
    case MessagesType.ARCHIVED:
      return 'Archived';
    default:
      return '';
  }
}
