<div class="document-section">
  <h4 [innerText]="label" class="document-section__title"></h4>
  <div class="document-section__document" *ngFor="let document of documents">
    <img
      class="document-section__icon"
      src="assets/img/icons/secure-forms/icn_document_pdf.svg"
      [alt]="document.displayName"
    />
    <button
      class="document-section__download"
      [innerText]="document.displayName"
      (click)="downloadFile(document)"
    ></button>
  </div>
</div>
