<div class="aggregation-success">
  <div class="header-section-title aggregation-success__header text-center frow">
    <div class="fcol-cs-12">
      <i class="bofi-check-mark"></i>
    </div>
    <div>
      <h2 *ngIf="!isPayverisProcessedError" class="fcol-cs-12 aggregation-success__header-text">
        External Account Added
      </h2>

      <h2 *ngIf="isPayverisProcessedError" class="fcol-cs-12 aggregation-success__header-text">
        Please Add Your Move Money Accounts Manually.
      </h2>
    </div>
  </div>

  <div>
    <div class="frow center">
      <div class="fcol-cs-12">
        <div class="text-center aggregation-success__accounts_aggregation-flow-title">
          <h2 class="aggregation-success__title">
            Success
            <br />
            <br />
          </h2>
          <p *ngIf="!isPayverisProcessedError && fastlinkResponseData.length">
            Your requested {{ bankName }} accounts have been added.
          </p>

          <p></p>
        </div>

        <div *ngIf="isPayverisProcessedError && fastlinkResponseData.length" class="text-center">
          <p>
            Your {{ bankName }} accounts have been linked, but some could not be set up for Move Money. We apologize for
            the inconvenience and recommend adding the impacted accounts manually.
          </p>

          <p></p>
          <h6 class="aggregation-success__accounts-title"></h6>

          <img class="" src="{{ getLogo('illustrationArea') }}" aria-hidden="true" />
        </div>

        <div class="frow center aggregation-success__accounts" *ngIf="fastlinkResponseData.length > 0">
          <ul class="aggregation-success__accounts-list" *ngIf="fastlinkResponseData.length > 0">
            <li *ngFor="let account of fastlinkResponseData" class="aggregation-success__accounts-list-table">
              <label
                *ngIf="account.payverisProcessed != 2"
                for="{{ account.nickName }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                <p>
                  <strong>
                    {{ getNameToDisplay(account) }}
                  </strong>
                </p>
              </label>

              <label
                *ngIf="!account.isTransferOnly && account.payverisProcessed != 2"
                for="{{ account.availableBalanceAmmount }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                {{ getAmountForFastLink(account) | currency }}
              </label>

              <label
                *ngIf="account.isTransferOnly && account.payverisProcessed != 2"
                for="{{ account.aggAccountId }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                Transfer Only
              </label>

              <label
                *ngIf="account.payverisProcessed == 2"
                for="{{ account.payverisProcessed }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                <div class="frow">
                  <div class="fcol-2">
                    <img style="margin: 1rem;" src="{{ getLogo('primary') }}" aria-hidden="true" />
                  </div>

                  <div class="fcol-10" style="margin-top: 4%;">
                    <p>
                      <strong>
                        {{ getNameToDisplay(account) }}
                      </strong>
                    </p>
                    <span>Add Manually to Move Money</span>
                  </div>
                </div>
              </label>

              <label
                style="margin-top: 4%;"
                *ngIf="account.payverisProcessed == 2 && isPayverisProcessedError && !account.isTransferOnly"
              >
                <span>
                  {{ getAmountForFastLink(account) | currency }}
                </span>
              </label>
            </li>
          </ul>
          <p *ngIf="fastlinkResponseData.length == 0">
            Account added but not account returned (AggregationOnly process)
          </p>
        </div>
      </div>

      <div class="fcol-cs-12" *ngIf="isAnyAccountUpdatedOrDeleted">
        <div *ngIf="!isPayverisProcessedError" class="text-center aggregation-success__accounts_aggregation-flow-title">
          <br *ngIf="fastlinkResponseData.length" />
          <br *ngIf="fastlinkResponseData.length" />
          <p>Your existing {{ bankName }} accounts have been updated.</p>

          <p></p>
        </div>

        <div *ngIf="isPayverisProcessedError" class="text-center">
          <p>
            Your {{ bankName }} accounts have been linked, but some could not be set up for Move Money. We apologize for
            the inconvenience and recommend adding the impacted accounts manually.
          </p>

          <p></p>
          <h6 class="aggregation-success__accounts-title"></h6>

          <img class="" src="{{ getLogo('illustrationArea') }}" aria-hidden="true" />
        </div>

        <div class="frow center aggregation-success__accounts">
          <ul class="aggregation-success__accounts-list" *ngIf="fastlinkUpdatedAccounts.length > 0">
            <li *ngFor="let account of fastlinkUpdatedAccounts" class="aggregation-success__accounts-list-table">
              <label
                *ngIf="account.payverisProcessed != 2"
                for="{{ account.nickName }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                <p>
                  <strong>
                    {{ getNameToDisplay(account) }}
                  </strong>
                </p>
              </label>

              <label
                *ngIf="!account.isTransferOnly && account.payverisProcessed != 2"
                for="{{ account.availableBalanceAmmount }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                {{ getAmountForFastLink(account) | currency }}
              </label>

              <label
                *ngIf="account.isTransferOnly && account.payverisProcessed != 2"
                for="{{ account.aggAccountId }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                Transfer Only
              </label>

              <label
                *ngIf="account.payverisProcessed == 2"
                for="{{ account.payverisProcessed }}"
                class="aggregation-success__accounts-list-item-name-flow"
              >
                <div class="frow">
                  <div class="fcol-2">
                    <img style="margin: 1rem;" src="{{ getLogo('primary') }}" aria-hidden="true" />
                  </div>

                  <div class="fcol-10" style="margin-top: 4%;">
                    <p>
                      <strong>
                        {{ getNameToDisplay(account) }}
                      </strong>
                    </p>
                    <span>Add Manually to Move Money</span>
                  </div>
                </div>
              </label>

              <label
                style="margin-top: 4%;"
                *ngIf="account.payverisProcessed == 2 && isPayverisProcessedError && !account.isTransferOnly"
              >
                <span>
                  {{ getAmountForFastLink(account) | currency }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="frow center text-center">
  <div class="fcol-cs-12">
    <button
      type="button"
      class="uk-btn lg solid secondary aggregation-success__button"
      [disabled]="isLoading"
      (click)="finish()"
      *ngIf="flow === 'moveMoney' && !isPayverisProcessedError"
    >
      Continue Transfer Set Up
    </button>

    <button
      type="button"
      class="uk-btn lg solid secondary aggregation-success__button"
      [disabled]="isLoading"
      (click)="back()"
      *ngIf="flow === 'aggregation' && !isPayverisProcessedError"
    >
      Back to Accounts
    </button>

    <button
      type="button"
      class="uk-btn lg solid secondary aggregation-success__button"
      [disabled]="isLoading"
      (click)="finish()"
      *ngIf="flow != 'moveMoney' && flow != 'aggregation' && !isPayverisProcessedError"
    >
      Continue
    </button>

    <button
      type="button"
      class="uk-btn lg solid secondary aggregation-success__button"
      [disabled]="isLoading"
      (click)="goToManually()"
      *ngIf="isPayverisProcessedError"
    >
      Add Accounts Manually
    </button>
  </div>

  <div class="fcol-cs-12">
    <button
      type="button"
      class="uk-btn lg outline primary aggregation-success__button"
      [disabled]="isLoading"
      *ngIf="!isPayverisProcessedError"
      (click)="goToFI()"
    >
      Add More Accounts
    </button>
  </div>

  <div class="fcol-cs-12">
    <button
      type="button"
      class="uk-btn lg outline primary aggregation-success__button"
      [disabled]="isLoading"
      (click)="finish()"
      *ngIf="isPayverisProcessedError && flow !== 'moveMoney'"
    >
      Back to Accounts
    </button>
  </div>

  <div class="fcol-cs-12">
    <button
      type="button"
      class="uk-btn lg outline primary aggregation-success__button"
      [disabled]="isLoading"
      (click)="finish()"
      *ngIf="isPayverisProcessedError && flow === 'moveMoney'"
    >
      Continue Transfer Setup
    </button>
  </div>
</div>
