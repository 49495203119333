import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { serviceHelperProvider, stateServiceProvider } from '@core/providers';
import { SharedModule } from '@shared/shared.module';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { sendMoneyBusinessComponents } from './modals';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMaskModule } from 'ngx-mask';
import { IPayService } from '@core/services/ipay.service';

const servicesProviders = [stateServiceProvider, serviceHelperProvider];

@NgModule({
  declarations: [...sendMoneyBusinessComponents],
  imports: [
    SharedModule,
    CommonModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [...sendMoneyBusinessComponents],
  providers: [servicesProviders, IPayService, { provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class BusinessModule {}
