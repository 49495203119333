import { Phone } from '@shared/models';
/**
 * Formats a phone number based on the number of characters it has.
 *
 * @param phone Phone to format.
 * @returns A formated phone string.
 *
 * @example
 * For 10 characters: ########## -> C ###-###-####
 * For 10 characters + extension : -> C ###-###-#### x{extension}
 */
export function phoneToString(phone: Partial<Phone>) {
  if (!phone?.number) {
    return '';
  }

  let retval = '';
  if (phone?.number && phone?.number.length === 10) {
    retval = phone.number.replace(/(\w{3})(\w{3})(\w{4})/, '$1-$2-$3');

    if (phone.extension && phone.extension.length > 0) {
      retval = `${retval} x${phone.extension}`;
    }
  }

  return retval;
}
