import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class CryptoService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'crypto');
  }

  enrollCryptoAccount(internalAccountId?: number, externalAccountId?: number) {
    return this.post<string>(
      `enrollCryptoAccount?internalAccountId=${internalAccountId}&externalAccountId=${externalAccountId}`
    ).pipe(map(result => result.data));
  }
}
