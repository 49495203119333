import { Recipient } from 'bill-pay/typings/Recipient';
import { RecipientsGroup } from 'bill-pay/typings/RecipientsGroup';
import { PartialRecipient } from 'bill-pay/typings/PartialRecipient';
import { AddRecipientResult } from 'bill-pay/typings/AddRecipientResult';
import { ScheduledPaymentByAccount } from 'bill-pay/typings/ScheduledPaymentByAccount';
import { PaymentHistory } from 'bill-pay/typings/PaymentHistory';
import { EBillsHistory } from 'bill-pay/typings/EBillHistory';
import { SelectBillerSite } from 'bill-pay/typings/SelectBillerSite';
import { VerifyEBillLogin } from 'bill-pay/typings/VerifyEBillLogin';
import { EBillLoginResponse } from 'bill-pay/typings/EBillLoginResponse';
import { EBillStatusResponse } from 'bill-pay/typings/EBillStatusResponse';
import { SelectBillerAccount } from 'bill-pay/typings/SelectBillerAccount';
import { Payment } from 'bill-pay/typings/Payment';
import { ScheduledPayment } from 'bill-pay/typings/ScheduledPayment';
import { RecipientUpdateVm } from 'bill-pay/typings/RecipientUpdateVm';
import { DeleteRecipientResponse } from 'bill-pay/typings/DeleteRecipientResponse';
import { RecurrentPaymentInfo } from 'typings/app/bills/RecurrentPaymentInfo';
import { RescheduledPayment } from 'bill-pay/typings/RescheduledPayment';
import { NextPaymentResponse } from 'bill-pay/typings/NextPaymentResponse';
import { PaymentInfo } from 'bill-pay/typings/PaymentInfo';
import { ResolveEBillErrorResponse } from 'bill-pay/typings/ResolveEBillErrorResponse';

import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IBillPayService } from './typings/IBillPayService';

@Inject('$http', 'env', 'serviceHelper', '$q', '$cacheFactory')
export class BillPayService extends BaseService implements IBillPayService {
  private readonly paymentsCacheName: string = 'paymentsCache';
  private readonly paymentsCache: ng.ICacheObject;
  constructor(
    http: ng.IHttpService,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    q: ng.IQService,
    private readonly cacheFactory: ng.ICacheFactoryService
  ) {
    super(http, env, 'payment', serviceHelper, q);
    let cache = cacheFactory.get('paymentsCache');
    if (!cache) cache = this.cacheFactory(this.paymentsCacheName);
    this.paymentsCache = cache;
  }

  validateDuplicatePayments(payments: RecurrentPaymentInfo[]): ApiResponse<PaymentInfo[]> {
    return this.post('duplicate', payments);
  }

  /**
   * Get the user Recipients from Payveris.
   * @returns A promise containing a list of top billers.
   */
  getRecipients(): ApiResponse<Recipient[]> {
    return this.get('payees');
  }

  /**
   * Get the top Recipients from Payveris.
   * @returns A promise containing a list of top billers.
   */
  getTopRecipients(): ApiResponse<RecipientsGroup[]> {
    return this.get('billers');
  }

  /**
   * Get recipient list.
   * @param query Param to perform a search in Payveris to get recipients.
   * @returns A promise containing a list of the recipients that matched the search string.
   */
  searchRecipients(query: string): ApiResponse<Recipient[]> {
    return this.get(`billers/${query}`);
  }

  /**
   * Validates if a account number is valid for a biller.
   * @returns A promise containing the result and a list of addresses for the biller.
   */
  validateBillerAccountNumber(
    accountNumber: string,
    id: number,
    ids?: number[]
  ): ApiResponse<BillerAccountValidation> {
    return this.post('billers/validate', { accountNumber, id, ids });
  }

  /**
   * Saves an electronic recipient
   * @param recipient An electronic recipient.
   * @returns A promise containing a list of addresses for the payee.
   */
  saveElectronicRecipient(recipient: PartialRecipient): ApiResponse<AddRecipientResult> {
    return this.post('payee/electronic', recipient);
  }

  clearCache(): void {
    if (this.paymentsCache) {
      this.paymentsCache.removeAll();
    }
  }

  getScheduledPayments(): ApiResponse<ScheduledPaymentByAccount[]> {
    return this.get();
  }

  getPaymentHistory(
    startDate: string,
    endDate: string,
    recipient: string = ''
  ): ApiResponse<PaymentHistory[]> {
    return this.get('payee/history', { startDate, endDate, recipient });
  }
  getEBillHistory(
    startDate: string,
    endDate: string,
    payeeId: number = 0
  ): ApiResponse<EBillsHistory[]> {
    return this.get('eBill/history', { startDate, endDate, payeeId });
  }

  /**
   * Retrieves the details of a Recipient (payee)
   * @param recipientId Id of the recipient
   */
  getRecipient(recipientId: number): ApiResponse<Recipient> {
    return this.get(`payee/${recipientId}`);
  }

  /**
   * Add eBills to a recipient as the first step to enable eBills
   * for the specific recipient
   * @param recipientId Id of the recipient
   */
  addEBill(recipientId: number): ApiResponse<VerifyEBillResponse> {
    return this.put(`payee/${recipientId}/ebill`);
  }

  /**
   * Select a biller site as the next step (not a common case)
   * @param billerSite Object with the site data
   */
  selectEBillSite(billerSite: SelectBillerSite): ApiResponse<VerifyEBillResponse> {
    return this.post('payees/ebill/site', billerSite);
  }

  /**
   * Provide the login information for the biller site
   * @param loginInfo The credentials used to log in
   */
  verifyEBillLogin(loginInfo: VerifyEBillLogin): ApiResponse<EBillLoginResponse> {
    return this.post('payee/eBill/login', loginInfo);
  }

  /**
   * Determine what further action is required for eStatements enrollment
   * after the login succeeds, or if the enrollment is complete
   * @param sessionId The session for this request
   */
  getEBillStatus(sessionId: string): ApiResponse<EBillStatusResponse> {
    return this.get(`eBill/status/${sessionId}`);
  }

  /**
   * Select or Confirm an Account for eStatements usage
   * @param billerAccount Object with the selected account data
   */
  selectEBillAccount(billerAccount: SelectBillerAccount): ApiResponse<EBillStatusResponse> {
    return this.post('eBill/account', billerAccount);
  }
  /**
   * Mark as paid an eBill from a payee.
   * @param payeeId the recipient id to Update
   * @param eBillId the eBillId that needs to mark as paid
   * @returns A promise containing the recipient updated
   */
  markEBillAsPaid(payeeId: number, eBillId: number): ApiResponse<Recipient> {
    return this.put(`eBill/${payeeId}/markAsPaid/${eBillId}`);
  }

  /**
   * Gets the most recent EBills detailed statement for the payee.
   * @returns A promise containing the pdf with the statement.
   */
  getEBillStatement(eBillId: number): any {
    return this.get(`eBill/${eBillId}/statement`, {}, false, null, 'arraybuffer');
  }

  /**
   * Selects an account to link it to the EBill
   * @returns A promise hte result of the selection.
   */
  deactivateEBill(payeeId: number): ApiResponse<EBillResponseBase> {
    return this.delete(`payee/${payeeId}/eBill`);
  }
  /**
   * Delete a scheduled payment series.
   */
  deleteScheduledPayment(ids: number[]): ApiResponse<ScheduledPayment> {
    return this.delete('schedule', { ids });
  }
  /**
   * Adds a recurring payment to a recipient;
   * @returns A promise with the result of the scheduled payment.
   */
  addScheduledPayment(
    payment: Payment,
    validateDuplicate: boolean = false
  ): ApiResponse<ScheduledPayment[]> {
    return this.post(`schedule?validateDuplicate=${validateDuplicate}`, payment);
  }

  /**
   * Update the recipient's data
   * @param recipient The object with the edited values for the recipient
   */
  updateRecipient(recipient: RecipientUpdateVm): ApiResponse<Recipient> {
    return this.put('payee', recipient);
  }

  /**
   * Delete a single recipient (including its scheduled payments)
   * @param id The identifier of the recipient to be deleted
   */
  deleteRecipient(id: number): ApiResponse<DeleteRecipientResponse> {
    return this.delete(`payee/${id}`);
  }

  updateScheduledPayment(payment: RecurrentPaymentInfo): ApiResponse<ScheduledPayment[]> {
    return this.put('', payment);
  }

  cancelNextPayment(payment: RecurrentPaymentInfo): ApiResponse<RescheduledPayment> {
    return this.post('scheduled/cancelNextPayment', payment);
  }

  /**
   * Schedule multiple non recurrent payments
   * @param payments Set of payments
   * @returns A promise with the result of the scheduled payments.
   */
  addMultipleScheduledPayments(payments: RecurrentPaymentInfo[]): ApiResponse<ScheduledPayment[]> {
    return this.post('multipleSchedule', payments);
  }
  editNextPayment(payment: RecurrentPaymentInfo): ApiResponse<NextPaymentResponse> {
    return this.post('scheduled/editNextPayment', payment);
  }

  /**
   * Initiates a flow to fix the status of the eBills for a particular recipient
   * @param recipientId The id of the recipient(payee) we want to fix
   * @returns Whether or not credentials are needed
   */
  resolveEBillsError(recipientId: number): ApiResponse<ResolveEBillErrorResponse> {
    return this.get(`payee/${recipientId}/ebill/resolve`);
  }
}
