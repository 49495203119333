import { LimitRequest, ReplacementRequest, TravelNotification } from '@app/accounts/submodules/debit-card/models';

import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IDebitCardService } from './typings/IDebitCardService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class DebitCardService extends BaseService implements IDebitCardService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'debit-cards', _serviceHelper, q);
  }

  /**
   * Get the debit cards linked and visible in OLB by account.
   * @param accountId Account's unique identifier.
   * @return A list of Debit Cards.
   */
  getCards(accountId: number, teens: string = ''): ApiResponse<DebitCard[]> {
    return this.get(`${accountId}?teens=${teens}`);
  }

  /**
   * Gets the card information.
   * @param accountId Account's unique identifier.
   * @param cardNumber Card number (masked).
   * @return A Debit Card with it's details.
   */
  getCardInfo(accountId: number, cardNumber: string): ApiResponse<DebitCard> {
    return this.get(`${accountId}/card/${cardNumber}`);
  }

  /**
   * Marks a card as Active.
   * @param accountId Account's unique identifier.
   * @param cardNumber Card number (masked).
   * @return A Debit Card with it's details updated.
   */
  activateCard(accountId: number, cardNumber: string): ApiResponse<DebitCard> {
    return this.put(`${accountId}/card/${cardNumber}/activate`);
  }

  /**
   * Marks a card as Blocked.
   * @param accountId Account's unique identifier.
   * @param cardNumber Card number (masked).
   * @param status Indicator if the card should be blocked or not.
   * @return An updated Debit Card with its new status.
   */
  blockCard(accountId: number, cardNumber: string, status: boolean): ApiResponse<DebitCard> {
    return this.put(`${accountId}/card/${cardNumber}/${status}`);
  }

  /**
   * Mark a card as Hot and indicates if should be replaced
   * @param accountId Account's unique identifier
   * @param cardMask Card number (masked)
   * @param replaceCard Indicator to state if the card should be replaced
   * @param dueFee Indicator if a fee must be charged
   * @param deliveryAddress The delivery address
   * @return An upated Debit Card with its new status
   */
  markAsHotCard(
    accountId: number,
    cardMask: string,
    replaceCard: boolean,
    dueFee: boolean,
    deliveryAddress: Address,
    addressChanged: boolean
  ): ApiResponse<DebitCard> {
    return this.put(`${accountId}/card/${cardMask}/mark-as-hot-card`, {
      cardMask,
      deliveryAddress,
      replaceCard,
      dueFee,
      addressChanged,
    });
  }

  /**
   * Requests a replacemnt of a card
   * @param accountId Account's unique identifier
   * @param The VM containing the required data to make a replacement
   * @return An ApiResponse indicating if the request was successful or not
   */
  requestReplacement(accountId: number, replacementReq: ReplacementRequest): ApiResponse<DebitCard> {
    return this.put(`${accountId}/replace`, replacementReq);
  }

  /**
   * Posts a request to increase temporary the current card limit
   * @param accountId Unique account's identifier
   * @param limitRequest Object containing the limit request
   * @return An string indicating if the request was successful or not
   */
  requestTemporaryLimit(accountId: number, limitRequest: LimitRequest): ApiResponse<string> {
    return this.post(`${accountId}/requestLimit`, limitRequest);
  }

  /**
   * Gets travel notifications list
   * @param debitCardId debit card id
   */
  getTravelNotifications(debitCardId: number): ApiResponse<TravelNotification[]> {
    return this.get(`travelNotifications/${debitCardId}`);
  }

  /**
   * Add travel Notification on Debit Card
   * @param travelNotification
   * @param accountId
   * @param cardMask
   */
  addTravelNotification(
    travelNotification: TravelNotification,
    accountId: number,
    cardMask: string
  ): ApiResponse<number> {
    return this.put(`${accountId}/addTravelNotification`, {
      travelNotification,
      cardMask,
    });
  }

  /**
   * Delete Travel Notification on Debit Card
   * @param travelNotification
   * @param accountId
   * @param cardMask
   */
  delTravelNotification(
    travelNotification: TravelNotification,
    accountId: number,
    cardMask: string
  ): ApiResponse<boolean> {
    return this.put(`${accountId}/deleteTravelNotification`, {
      travelNotification,
      cardMask,
    });
  }

  /**
   * Gets Debit card recent activity.
   * @param accountId
   * @param cardNumber
   */
  getHistory(accountId: number, cardNumber: string): ApiResponse<DebitCardHistory[]> {
    return this.get(`history/${accountId}/${cardNumber}`);
  }

  /**
   * Removes a record from Debit Card history.
   * @param recordId
   */
  removeHistoryRecord(recordId: number): ApiResponse<boolean> {
    return this.delete(`history/${recordId}`);
  }
}
