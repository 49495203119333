import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { usStates } from '@app/config/constants';
import { openClose, slideFromRight, startStaticSlideFromLeft } from '@app/user-profile/animations';
import { ProfileType } from '@app/user-profile/enums';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { BusinessService } from '@core/services';
import { Address, CustomerDetail } from '@shared/models';
import { BusinessDetail } from '@shared/models/business-detail.model';

@Component({
  selector: 'app-business-information-view',
  templateUrl: './business-information-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss'],
  animations: [openClose, startStaticSlideFromLeft, slideFromRight],
})
export class BusinessInformationViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  expanded = false;
  isMobileDevice = false;
  businessInfoLoading = true;
  isBusinessEmail = true;
  businessInfo: BusinessDetail;
  profileInfo: Partial<CustomerDetail>;
  userCanEdit = true;
  canEditAddress = true;
  mailingAddressIsIncomplete: boolean;
  currentView: BusinessInformationView = null;
  alertMessages = {
    email: '',
    phone: '',
    address: '',
  };
  views = BusinessInformationView;
  profileTypes = ProfileType;
  animationState: 'initial' | 'in' = 'initial';
  mailingAddress: Address;

  private subsink = new SubSink();

  constructor(
    private store: Store,
    private businessService: BusinessService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.getProfileState();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goTo(view: BusinessInformationView): void {
    this.currentView = view;
    this.alertMessages = {
      email: '',
      phone: '',
      address: '',
    };
    this.animationState = 'initial';
  }

  goBack(): void {
    this.currentView = null;
    this.animationState = 'in';
  }

  updateBusinessMail(): void {
    this.alertMessages.email = 'You have successfully saved your email.';
    this.goBack();
  }

  updateBusinessPhone(): void {
    this.alertMessages.phone = 'You have successfully saved your phone.';
    this.goBack();
  }

  updateBusinessAddresses(): void {
    this.alertMessages.address = 'You have successfully saved your address.';
    this.goBack();
  }

  private getProfileState(): void {
    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(
        filter(data => data !== null),
        switchMap(profileInfo => {
          this.profileInfo = profileInfo;
          if (profileInfo.businessInformation) {
            return of(profileInfo.businessInformation);
          }

          return this.businessService.getBusinessProfileDetails().pipe(map(res => res.data));
        }))
      .subscribe(data => {
        this.businessInfo = data;
        this.recomputeViewValues(data);
      });
  }
  private recomputeViewValues(data: BusinessDetail): void {
    this.businessInfo = { ...data };
    const businessWithStateCode = { ...this.businessInfo.businessAddress };
    const address = { ...businessWithStateCode.address };

    if (
      this.businessInfo.businessAddress.address.state !== '' &&
      this.businessInfo.businessAddress.address.stateCode === ''
    ) {
      const stateCode = Object.keys(usStates).find(
        key => usStates[key].toLocaleLowerCase() === this.businessInfo.businessAddress.address.state.toLocaleLowerCase()
      );

      address.stateCode = stateCode;
      address.state = address.state.toLocaleUpperCase();
      businessWithStateCode.address = address;
      this.businessInfo = { ...this.businessInfo, businessAddress: businessWithStateCode };
    }

    this.mailingAddress = this.businessInfo.businessAddress.address;
    this.mailingAddressIsIncomplete =
      !this.mailingAddress.city ||
      !this.mailingAddress.stateCode ||
      !this.mailingAddress.postalCode ||
      !this.mailingAddress.streetAddress1;

    this.profileInfo = { ...this.profileInfo, businessInformation: this.businessInfo };
    this.businessInfoLoading = false;

  }
}

export enum BusinessInformationView {
  Email = 'Email',
  Phone = 'Phone Number',
  Address = 'Address',
}
