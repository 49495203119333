import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { NgxMaskModule } from 'ngx-mask';

import { sharedComponents } from './components';
import { transfersModals } from './components/modals';
import { TransferService } from './services/transfer.service';
import { InboxModule } from '@app/inbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ACCOUNTS_SERVICE_PROVIDERS } from './components/injection tokens/injection-tokens';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { loadUserProfileHelperProvider, serviceHelperProvider } from '@core/providers';
import { DatePipe } from '@angular/common';
import { LEGACY_SCHEDULER_TRANSFER_PROVIDERS } from './ajs-upgraded-providers';
import { SharedModule } from '@shared/shared.module';
import { ngReduxServiceProvider } from '@core/providers';
import { billPayServiceProvider } from '@app/bill-pay/ajs-upgraded-provider';

const providers = [
  TransferService,
  ...LEGACY_SCHEDULER_TRANSFER_PROVIDERS,
  serviceHelperProvider,
  DatePipe,
  loadUserProfileHelperProvider,
  { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: {} },
  ngReduxServiceProvider,
  billPayServiceProvider,
];

const materialModules = [
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatLegacyDialogModule,
];

@NgModule({
  declarations: [...sharedComponents, ...transfersModals],
  imports: [NgxMaskModule.forRoot(), SharedModule, ...materialModules, InboxModule, FormsModule, ReactiveFormsModule],
  exports: [...sharedComponents],
  providers: [...providers, ...ACCOUNTS_SERVICE_PROVIDERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TransfersModule {}
