<section class="investor-checking-enrollment">
  <ng-container
    *ngIf="!riaPilotICODLDebitCardFlagActive; else ICODLDebitCardSection"
  >
    <div class="dialog-content">
      <img
        id="image-illustration"
        src="assets\axos\img\icons\accounts\application-review.svg"
        class="dialog-content_logo"
        alt="investor checking enrollment logo."
      />
      <h1 id="title-review">Your Application Is In Review</h1>
      <br />
      <p id="message-review" class="dialog-content_description">
        Your application needs further review, so we'll contact you within 2
        business days to discuss the next steps in opening your account.
      </p>
      <div class="divider">{{ this.productName }} - {{ this.vestingName }}</div>
      <div class="card">
        <div
          style="
            border: solid 3px #f6c737;
            margin-right: 1em;
            border-radius: 50px;
            height: 40px;
          "
        ></div>
        <div>
          <div>
            <h2>Application Status</h2>
            <p>Pending Review</p>
          </div>
        </div>
      </div>

      <div style="color: #1e3860;" *ngIf="confirmationNumber">
        <p>Application Confirmation Number</p>
        <p id="confirmation-number">{{ confirmationNumber }}</p>
      </div>
    </div>
    <div class="dialog-footer">
      <hr />
      <button
        id="btn-ok"
        type="button"
        class="dialog-footer-button"
        (click)="this.close()"
      >
        Done
      </button>
    </div>
  </ng-container>
</section>

<ng-template #ICODLDebitCardSection>
  <div id="empty-top-actions"></div>
  <div id="body">
    <ng-lottie
      id="body-animation"
      [width]="'200px'"
      [height]="'200px'"
      [options]="pendingAnimationOptions"
    ></ng-lottie>
    <h1 id="title-review">Your application is in review</h1>
    <br />
    <p id="message-review">
      Your application needs further review, so we'll contact you within 2
      business days to discuss the next steps in opening your account.
    </p>
    <div id="body-details">
      <div class="details-row">
        <mat-icon
          [svgIcon]="uk2Tier1CommunicationEnum.identification"
        ></mat-icon>
        <span class="details-row-title">Confirmation Number</span>
        <span id="confirmation-number" class="details-row-value">
          {{ confirmationNumber }}
        </span>
      </div>
      <div class="details-row">
        <mat-icon [svgIcon]="uk2Tier1FinancialEnum.piggy"></mat-icon>
        <span class="details-row-title">Account</span>
        <span id="account-title" class="details-row-value">
          Cash Management Checking
        </span>
      </div>
      <div class="details-row">
        <mat-icon [svgIcon]="uk2Tier1FinancialEnum.bank"></mat-icon>
        <span class="details-row-title">Account Type</span>
        <span id="account-type" class="details-row-value">Individual</span>
      </div>
      <mat-card id="email-row" uk2NoteCard>
        <mat-card-content>
          <label id="email-label">
            An email including this information will be sent to {{ email }}
          </label>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div id="footer-buttons" class="dialog-footer">
    <mat-divider></mat-divider>
    <button
      id="btn-ok"
      uk2PrimaryButton
      disableRipple
      mat-raised-button
      (click)="this.close()"
    >
      Done
    </button>
  </div>
</ng-template>
