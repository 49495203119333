import { Action, createReducer, on } from '@ngrx/store';

import { categorizeAccounts } from '@app/accounts/utils';
import { signOut } from '@app/store/actions';

import { loadInternalAccounts } from './internal-accounts.actions';
import { InternalAccountsState } from './internal-accounts.state';

export const internalAccountsInitialState = new InternalAccountsState();

const _reducer = createReducer(
  internalAccountsInitialState,
  on(loadInternalAccounts, (state, action) => {
    const { totalOutstandingBalanceLoan, totalAvailableBalanceDeposit, source } = action.payload;
    const allAccounts = [...action.payload.depositAccounts, ...action.payload.loanAccounts];
    const categorisedAccounts = categorizeAccounts(state, allAccounts);

    return {
      ...state,
      ...categorisedAccounts,
      totalAvailableBalanceDeposit,
      totalOutstandingBalanceLoan,
      source,
    };
  }),
  on(signOut, () => {
    return new InternalAccountsState();
  })
);

export function internalAccountsReducer(state: InternalAccountsState, action: Action) {
  return _reducer(state, action);
}
