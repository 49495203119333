import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'axos-legacy-input-formatted-amount',
  templateUrl: './axos-legacy-input-formatted-amount.component.html',
  styleUrls: ['./axos-legacy-input-formatted-amount.component.scss'],
})
export class AxosLegacyInputFormattedAmountComponent implements OnChanges {
  @Input() amount: number = 0;
  @Input() hasError: boolean = false;
  @Input() name: string = '';
  @Input() disabled = false;
  @Input() placeholder: string = '';
  @Output() amountChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() change: EventEmitter<void> = new EventEmitter<void>();
  public formattedAmount: string = '';
  constructor(private currencyPipe: CurrencyPipe) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.amount.currentValue) {
      this.formattedAmount = this.currencyPipe.transform(this.amount, 'USD', 'symbol', '1.2-2');
    }
  }
  convertFormattedAmountToNumber($event: Event): void {
    const formattedAmount = ($event.target as HTMLInputElement).value;
    const amountWithoutCurrency = formattedAmount.replace(/[^0-9.-]+/g, '');
    const amount = parseFloat(amountWithoutCurrency);
    this.amountChange.emit(amount);
    this.change.emit();
  }
}
