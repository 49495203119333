import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { olbSettings, STATE } from '@core/tokens';
import { AlertBanner, MoneyTransfer_From_Trading_Ira } from '@legacy/transfers/receipt/typings/ReceiptConstants';
import { AlertsIcons, UtilityIcons } from '@shared/enums';

import { ReceiptAlertBanner } from '../../models/receipt-alert-banner.model';
import { ReceiptSettings } from '../../models/receipt-settings.model';
import { ReceiptTemplate } from '../../models/receipt-template.model';

@Component({
  selector: 'app-transfer-receipt',
  templateUrl: './transfer-receipt.component.html',
  styleUrls: ['./transfer-receipt.component.scss'],
})
export class TransferReceiptComponent implements OnInit {
  template: Partial<ReceiptTemplate>;
  settings: Partial<ReceiptSettings>;
  hasFailed = false;
  footNote: SafeHtml;
  additionalFootNote: SafeHtml;
  alertBanner: ReceiptAlertBanner = AlertBanner;
  bannerImage: string;
  brand: string;
  icons = {
    check: UtilityIcons.Checkmark,
    checkcircle: AlertsIcons.CheckCircle,
  };

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (!this.state.params?.settings || !this.state.params?.template) {
      this.hasFailed = true;
      this.state.go('udb.transfers.transferFunds');

      return;
    } else {
      this.settings = new ReceiptSettings(this.state.params.settings);
      this.template = new ReceiptTemplate(this.state.params.template);

      this.brand = this.env.brand;
      this.bannerImage = `assets/img/icons/transfers/${this.env.brand}/${this.alertBanner.icon}`;
      this.processStateParams();
    }
  }

  navigateTo() {
    this.state.go(this.settings.navigateTo);
  }

  navigateBack() {
    this.state.go(this.settings.navigateBack);
  }

  alertBannerNavigation() {
    this.state.go(this.alertBanner.navigation);
  }

  private processStateParams() {
    if (!this.template.header) {
      this.template.header = MoneyTransfer_From_Trading_Ira.header;
    }

    this.template.header = this.template.header
      .replace('{amount}', this.settings.amount)
      .replace('{toAccount}', this.settings.toAccount);

    if (this.template.type === 'P2P') {
      this.template.header = this.template.header.replace(
        '{recipientName}',
        this.template.transactions[0].details[1].value
      );
    }

    this.footNote = this.domSanitizer.bypassSecurityTrustHtml(this.template.footnote);
    this.additionalFootNote = this.domSanitizer.bypassSecurityTrustHtml(this.settings.additionalFootnote);
  }
}
