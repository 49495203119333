import { Component, Inject, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { LibrarySection, SecureFormsLibrary } from '@app/secure-forms/models';
import { SecureFormsService } from '@core/services';
import { STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
@Component({
  selector: 'app-secure-forms',
  templateUrl: './secure-forms.component.html',
  styleUrls: ['./secure-forms.component.scss'],
})
export class SecureFormsComponent implements OnInit {
  errorMessage: string;
  loading: boolean;
  library: SecureFormsLibrary;
  sections: LibrarySection[];

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private secureFormsService: SecureFormsService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.setSecureFormSections();
    this.loadLibrary();
  }

  loadLibrary() {
    this.loading = true;
    this.secureFormsService
      .getLibrary()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: result => {
          this.library = result.data;
          this.library.lossDisputeForms = this.library.lossDisputeForms.filter(
            document => document.formNumber !== 320
          );
        },
        error: err => {
          this.errorMessage = this.serviceHelper.errorHandler(err);
        },
      });
  }

  changeState(state: string) {
    this.state.go(state);
  }

  dismissError() {
    this.errorMessage = '';
  }

  private setSecureFormSections() {
    this.sections = [
      { key: 'depositForms', label: 'Deposit / Withdrawal Forms' },
      { key: 'accountOwnerShipForms', label: 'Account Ownership Forms' },
      { key: 'debitATMForms', label: 'Debit / ATM Card Forms' },
      { key: 'lossDisputeForms', label: 'Loss Dispute Forms' },
      { key: 'iraForms', label: 'IRA Forms' },
      { key: 'portfolioManagementForms', label: 'Portfolio Management Forms' },
      { key: 'otherForms', label: 'Other Forms' },
    ];
  }
}
