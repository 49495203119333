import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { SwitchActions } from '@app/click-switch/enums';
import { SubmitTarget, Switch } from '@app/click-switch/models';
import { DialogService, DirectDepositService } from '@core/services';
import { STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { DialogComponent } from '@shared/components';
import { AlertsIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

@Component({
  selector: 'app-click-switch-base',
  template: `
    <p>
      click-switch-base works!
    </p>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickSwitchBaseComponent implements OnInit {
  userSwitch: Partial<Switch>;
  errorMessage: string;
  subsink = new SubSink();
  private dialog: MatDialogRef<DialogComponent>;

  constructor(
    @Inject(STATE) protected state: ng.ui.IStateService,
    protected directDepositService: DirectDepositService,
    protected serviceHelper: ServiceHelper,
    protected dialogService: DialogService
  ) {}

  ngOnInit(): void {
    const { userSwitch } = this.state.params;
    this.userSwitch = userSwitch;
  }

  cleanErrorMessage(): void {
    this.errorMessage = '';
  }

  createSwitch(target: Partial<SubmitTarget>, sucessFunction: () => void) {
    this.directDepositService.createSwitch(target).subscribe({
      next: result => {
        this.userSwitch = result.data;
        sucessFunction();
      },
      error: exception => {
        this.handleException(exception);
      },
    });
  }

  updateSwitch(sucessFunction: () => void) {
    this.directDepositService.updateSwitch(this.userSwitch).subscribe({
      next: result => {
        this.userSwitch = result.data;
        sucessFunction();
      },
      error: exception => {
        this.handleException(exception);
      },
    });
  }

  confirmSwitch(action: SwitchActions, sucessFunction: () => void) {
    this.directDepositService.confirmSwitch(this.userSwitch.index, action).subscribe({
      next: result => {
        this.userSwitch = result.data;
        sucessFunction();
      },
      error: exception => {
        this.handleException(exception);
      },
    });
  }

  cancelAndDeleteSwitch(sucessFunction: () => void): void {
    const cancelAction = this.userSwitch.state.actions.find(a => a.toLowerCase() === 'cancel');

    if (cancelAction) {
      this.directDepositService
        .confirmSwitch(this.userSwitch.index, SwitchActions.Cancel)
        .pipe(
          switchMap(result => {
            this.userSwitch = result.data;

            return this.directDepositService.deleteSwitch(this.userSwitch.index);
          })
        )
        .subscribe({
          next: () => {
            sucessFunction();
          },
          error: exception => {
            this.handleException(exception);
          },
        });
    } else {
      this.errorMessage = 'The Direct Deposit cannot be canceled.';
    }
  }

  showLeaveModal(sucessFunction: () => void) {
    if (this.dialog) return;

    const dialogData = new DialogData({
      title: '',
      content: 'Are you sure you want to cancel?',
      cancelText: 'No',
      okText: 'Yes',
      icon: AlertsIcons.QuestionCircle,
    });

    this.dialog = this.dialogService.open(dialogData);

    this.subsink.sink = this.dialog.afterClosed().subscribe(leave => {
      if (leave) {
        sucessFunction();
      }

      this.dialog = null;
    });
  }

  private handleException(exception: HttpErrorResponse) {
    if (exception?.error.statusCode === 400) {
      this.errorMessage = exception.error.message;
    } else {
      this.serviceHelper.errorHandler(exception);
    }
  }
}
