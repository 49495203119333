import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AppStateFeatures } from '@core/enums';
import { AxosAdvisoryService } from '@core/services';

import { axosAdvisoryReducers } from './store/reducers';

const providers = [AxosAdvisoryService];

@NgModule({
  imports: [StoreModule.forFeature(AppStateFeatures.AxosAdvisory, axosAdvisoryReducers)],
  providers: [...providers],
})
export class AxosAdvisoryModule {}
