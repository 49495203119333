import { Component, Input, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { NavigationIcons } from '@shared/enums';
import { SideMenuItem } from '@shared/models';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  @ViewChild(MatDrawer) sideMenu: MatDrawer;

  @Input() expanded = false;
  @Input() items: SideMenuItem[];
  @Input() side: string;

  get position() {
    return this.side?.toLowerCase() === 'right' ? 'end' : 'start';
  }

  toggled = false;
  closeIcon = NavigationIcons.Ex;

  toggleMainMenu() {
    this.toggled = !this.toggled;
    this.sideMenu.toggle();
  }
}
