import { Inject } from '../../../decorators/decorators';
import { IScope } from 'angular';
import { UserAccountType } from 'typings/app/bills/UserAccountType';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { PaymentHistory } from 'bill-pay/typings/PaymentHistory';
import { ScheduledPayment } from 'bill-pay/typings/ScheduledPayment';
import { Recipient } from 'bill-pay/typings/Recipient';
import { BillPayRouterHelper } from 'bill-pay/bill-pay-router.helper';

@Inject('$scope', 'cachedAccountsService', 'billPayRouterHelper')
export class RecipientsController {
  recipients: Recipient[];
  isLoadingAccounts: boolean;
  accountsFrom: UserAccountType[];
  accountFrom: UserAccountType;
  isLoading: boolean;
  paymentsHistory: PaymentHistory[];
  historyByRecipient: Map<Recipient, PaymentHistory[]>;
  scheduledPayments: ScheduledPayment[];
  scheduledByRecipient: Map<Recipient, ScheduledPayment[]>;
  loadScheduledPayments: any;
  paymentUpdated: any;
  cancelText: boolean = true;

  constructor(
    private readonly scope: IScope,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}
  $onInit(): void {
    this.isLoading = true;
    this.scope.$on('accountsLoaded', () => {
      this._loadAccounts();
      this.isLoadingAccounts = false;
    });
    this._loadAccounts();
    this._groupScheduledPaymentsByRecipient();
    this._groupHistoryByRecipient();
    this.isLoading = false;
  }
  public paymentUpdatedCaller(value: any): any {
    this.paymentUpdated({ event: value });
  }

  private _loadAccounts(): void {
    this.accountsFrom = this.cachedAccountsService.paymentAccounts.filter(acc => !acc.isSBB);
    if (!this.accountsFrom) this.isLoadingAccounts = true;
    else {
      this.accountFrom = this.accountsFrom[0];
      this.changeAccount();
    }
  }

  public _loadScheduledPayments() {
    this.loadScheduledPayments();
  }

  changeAccount() {
    this.billPayRouterHelper.setAccountFrom(this.accountFrom);
    this.scope.$emit('billPayAccountChange');
  }

  private _groupHistoryByRecipient() {
    this.historyByRecipient = new Map<Recipient, PaymentHistory[]>();
    this.recipients.forEach(recipient => {
      this.historyByRecipient.set(
        recipient,
        this.paymentsHistory.filter(p => p.payeeInformation.payeeId == recipient.payeeId).slice(0, 4)
      );
    });
  }

  private _groupScheduledPaymentsByRecipient() {
    this.scheduledByRecipient = new Map<Recipient, ScheduledPayment[]>();
    this.recipients.forEach(recipient => {
      this.scheduledByRecipient.set(
        recipient,
        this.scheduledPayments.filter(s => s.payeeId == recipient.payeeId).slice(0, 4)
      );
    });
  }
}
