import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ScheduledTransferState,
  ScheduledTransferTileState,
  ScheduledTransfersResponse,
  ScheduledTransfersService,
  addUpdateScheduledTransferAction,
  getSelectedAccountScheduledTransferTile,
} from '../core';
import { InitializeScheduledTransfersInputTile } from './types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduledTransfersFacade {
  scheduledTransferTileAccountState$ = this.store.select(getSelectedAccountScheduledTransferTile);
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store<ScheduledTransferState>, private service: ScheduledTransfersService) {}

  initializeScheduledTransferTile(input: InitializeScheduledTransfersInputTile) {
    this.isLoading$.next(true);

    const request = {
      accountNumber: input.accountNumber,
    };

    this.service.getScheduledTransfers(request).subscribe(
      resp => this.handleSuccessResponse(request.accountNumber, resp.data),
      () => this.handleFailResponse(request.accountNumber)
    );
  }

  private handleSuccessResponse(accountNumber: string, response: ScheduledTransfersResponse[]) {
    this.isLoading$.next(false);
    const actionPayload: ScheduledTransferTileState = {
      apiCallWasSuccessful: true,
      accountNumber: accountNumber,
      scheduledTransfers: response,
    };

    this.store.dispatch(addUpdateScheduledTransferAction({ payload: actionPayload }));
  }

  private handleFailResponse(accountNumber: string) {
    this.isLoading$.next(false);
    const actionPayload: ScheduledTransferTileState = {
      apiCallWasSuccessful: false,
      accountNumber: accountNumber,
      scheduledTransfers: [],
    };

    this.store.dispatch(addUpdateScheduledTransferAction({ payload: actionPayload }));
  }
}
