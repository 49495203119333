<app-confirmation-table [fields]="fields"></app-confirmation-table>
<app-dot-loader *ngIf="isLoading"></app-dot-loader>
<div class="ira-disclosure-container" *ngIf="transferData.contributionYear">
  <p class="ira-disclosure-title">IRA Disclosure</p>
  <button class="ira-disclosure-label-btn" id="iraDisclosure" type="button" (click)="toggleIraDisclosure()">
    <span class="ira-disclosure-label">Read Disclosure</span>
    <app-svg-icon
      class="ira-disclosure-label-btn-icon"
      [iconName]="icons.arrow"
      width="1.15em"
      height="1.15em"
      iconColor="#1c7fdd"
    ></app-svg-icon>
  </button>
</div>
<p class="ira-disclosure" *ngIf="showIraDisclosure">
  I certify that all information provided by me is true and accurate and that Axos and/or any of its agents have not
  provided me with tax advice. I understand that Axos cannot determine my contribution eligibility and that only I can
  determine my contribution eligibility. I confirm to Axos that I am eligible to make the contribution as documented on
  this form. I hereby direct Axos to accept my contribution as documented on this form and understand that I am solely
  responsible for the consequences of this action.
</p>
<div class="form-group ira-disclosure-group">
  <input
    id="iraDisclosureAccepted"
    class="checkbox"
    name="iraDisclosureAccepted"
    type="checkbox"
    [(ngModel)]="iraDisclosureAccepted"
    (click)="acceptIraDisclosure(iraDisclosureAccepted ? false : true)"
  />
  <label for="iraDisclosureAccepted" class="ira-checkbox-label" *ngIf="transferData.contributionYear">
    I certify that all information provided is accurate and that I understand the potential implications of this
    deposit.
  </label>
  <div class="text--error" *ngIf="!iraDisclosureAccepted && iraDisclosureTouched">
    Please confirm before proceeding
  </div>
</div>
<div class="confirm-button-container">
  <button class="btn-ok" type="button" id="submitBtn" (click)="createTransfer()" [disabled]="isLoading">
    {{ isLoading ? 'Posting Transfer' : 'Submit' }}
  </button>
  <button class="btn-cancel button--lg" type="button" (click)="goBack.emit()">Back</button>
</div>
<div class="disclaimer text-center mt-4">
  <p>Transferring funds may take as long as 5-7 business days to become available.</p>
</div>
