import { Inject } from '../decorators/decorators';
import { ObservableBaseService } from 'services/observable-base.service';
import { IMessagesService } from './typings/IMessagesService';
import { ObservableResponse } from 'models';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class MessagesService extends ObservableBaseService implements IMessagesService {
  constructor(
    http: ng.IHttpService,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    q: ng.IQService
  ) {
    super(http, env, 'messages', serviceHelper, q);
  }

  getMessages(messageType: string): ObservableResponse<Message[]> {
    return this.get(messageType);
  }

  getUnreadMessagesCount(): ObservableResponse<number> {
    return this.get('unread');
  }

  getMessageDetail(caseId: string): ObservableResponse<any> {
    return this.get(`details`, { caseId: caseId });
  }

  userActivity(activity: UserActivity): ObservableResponse<any> {
    return this.post('useractivity', activity);
  }

  updateMessageStatus(caseId: string, newStatus: string): ObservableResponse<string> {
    return this.put(`${caseId}/status/${newStatus}`);
  }

  getAttachment(caseId: string, fileName: string, fileId: string, onFinally?: any): Promise<void> {
    return this.download(`attachment`, { caseId, fileName, fileId }, onFinally);
  }
}
