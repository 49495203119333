import * as angular from 'angular';
import { IFilterService } from 'angular';
import * as moment from 'moment';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { CustomerLimitsHelper } from 'services/customer-limits.helper';
import { FeatureFlagService } from 'services/feature-flag.service';
import { LoanAccountHelper } from 'services/loan-account.helper';

import { Inject } from '../../../decorators/decorators';
import { ILoanService } from '../../../services/typings/ILoanService';
import { ITransactionService } from '../../../services/typings/ITransactionService';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { Freq, mapSendUntil, SendUntilOption } from '../../transfers.controller';
import { TransferSchedule } from '../../typings/TransferSchedule';

@Inject(
  '$rootScope',
  '$uibModalInstance',
  'transactionService',
  'loanService',
  'serviceHelper',
  'frequencies',
  'transferToEdit',
  'isSeries',
  'dateRangePickerOptions',
  'dateHelper',
  'customerLimitsHelper',
  'featureFlagService',
  '$filter',
  'cachedAccountsService',
  'loanAccountHelper'
)
export class EditLoanPaymentModal {
  isLoadingSetup = false;
  // Datepickers
  datePickerOptions: any;
  expirationDatePickerOptions: any;
  datePicker: any;
  title: string;
  sendUntilOptions: GenericOption[];
  showNumberOfTransfers: boolean;
  showExpirationDate: boolean;
  isNumberOfTransfersValid: boolean;
  loanDetails: OlbAccount;
  notificationErrorMsg: string;
  isTodayValidForExternal: boolean;
  isPayoff: boolean;
  isEndDateValid: boolean;
  payoffMessage: string;
  disableAdditionalPrincipal = false;

  private _customerLimits: CustomerLimits;
  private _isLoanFeatureEnabled: boolean;

  get totalAmount(): number {
    return +this.transferToEdit.amount + +this.transferToEdit.additionalPrincipal;
  }

  constructor(
    private readonly _rootScope: ng.IRootScopeService,
    private _uibModalInstance: any,
    private _transactionService: ITransactionService,
    private _loanService: ILoanService,
    private _serviceHelper: IServiceHelper,
    public frequencies: GenericOption[],
    public transferToEdit: TransferSchedule,
    public isSeries: boolean,
    private _dateRangePickerOptions: any,
    private _dateHelper: DateHelper,
    private readonly customerLimitsHelper: CustomerLimitsHelper,
    private readonly _featureFlagService: FeatureFlagService,
    private readonly filterService: IFilterService,
    private readonly _cachedService: CachedAccountsService,
    private readonly loanAccountHelper: LoanAccountHelper,
    public errorMsg: string
  ) {}

  /** Initializes the controller. */
  $onInit(): void {
    this._checkCustomerLimits();
    this.LoadDatePickerOptions();
    this.transferToEdit.newNextTransferDate = this.transferToEdit.nextTransferDate;
    this.transferToEdit.oldLastTransferDate = this.transferToEdit.lastTransferDate;
    this.transferToEdit.oldAmount = this.transferToEdit.amount;
    this.transferToEdit.oldFrequency = this.transferToEdit.frequency;
    this.transferToEdit.oldBeginSending = this.transferToEdit.beginSendingOn;
    this.transferToEdit.oldAdditionalPrincipal = this.transferToEdit.additionalPrincipal;
    this.transferToEdit.oldNumberOfTransfers = this.transferToEdit.numberOfTransfers;
    this.transferToEdit.oldSendUntil = this.transferToEdit.sendUntilOptions;
    this.errorMsg = null;
    this.isEndDateValid = true;
    this.validateEndDate();
    this.title = this.isSeries
      ? 'Edit series'
      : this.transferToEdit.frequency == Freq.ONETIME
      ? 'Edit scheduled transfer'
      : 'Edit next transfer';

    if (this._rootScope['loanFlagAvailable']) {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
      this.setCalendarOptions();
    }
    this._rootScope.$on('loanFlagAvailable', () => {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
      this.setCalendarOptions();
    });
    this._loanFieldsInitialization();
    ({ payOff: this.isPayoff, payOffMessage: this.payoffMessage } = this.loanAccountHelper.isPayoff(
      this.loanDetails,
      this.totalAmount
    ));
  }

  saveTransfer() {
    if (this.validateAmount() && this.validateNumberOfTransfers() && this.isEndDateValidated()) {
      const { isLate, lateMessage } = this.loanAccountHelper.isPaymentLate(this.loanDetails.loanDueDate);
      const { isMature, matureMessage } = this.loanAccountHelper.isAccountMature(this.loanDetails);
      const { payOff, payOffMessage } = this.loanAccountHelper.isPayoff(this.loanDetails, this.totalAmount);
      if (isLate || isMature || payOff) {
        this.notificationErrorMsg = isLate ? lateMessage : isMature ? matureMessage : payOffMessage;

        return;
      }
      this.transferToEdit.numberOfTransfers |= 0;
      this.isLoadingSetup = true;
      switch (this.transferToEdit.scheduledTransferType) {
        case 0: // Internal
          this._editInternalTransfer();
          break;
        case 1: // ACH
          this._editExternalTransferFromAxosScheduler();
          break;
        default:
          // Payveris
          if (!this._isLoanFeatureEnabled) this._editExternalTransferFromPayveris();
          // Do conversion
          else this._convertToAxosScheduler();
          break;
      }
    }
  }

  closeModal() {
    this._uibModalInstance.close(false);
  }

  showCalendar(element: string): void {
    const dp = $('#' + element);
    if (!dp.data('daterangepicker').isShowing) {
      dp.data('daterangepicker');
      dp.data('daterangepicker').show();
    } else {
      dp.data('daterangepicker').hide();
    }
  }

  validateAmount(): boolean {
    if (this.totalAmount > 0) {
      this.errorMsg = null;

      return true;
    }
    this.errorMsg = 'Please enter Amount';

    return false;
  }

  sendUntilChange(): void {
    this.showNumberOfTransfers = this.transferToEdit.sendUntilOptions === SendUntilOption.NumberOfTransfers;
    this.showExpirationDate = this.transferToEdit.sendUntilOptions === SendUntilOption.LastTransferDate;
    if (!this.showNumberOfTransfers) this.transferToEdit.numberOfTransfers = null;
  }

  validateNumberOfTransfers(): boolean {
    if (
      this.showNumberOfTransfers &&
      (!this.transferToEdit.numberOfTransfers || this.transferToEdit.numberOfTransfers <= 0)
    ) {
      this.isNumberOfTransfersValid = false;
    } else {
      this.isNumberOfTransfersValid = true;
    }

    return this.isNumberOfTransfersValid;
  }

  //#region Private methods

  private _loanFieldsInitialization() {
    this.isLoadingSetup = true;
    this.sendUntilOptions = [
      { value: SendUntilOption.IChooseToStop, label: mapSendUntil(SendUntilOption.IChooseToStop) },
      {
        value: SendUntilOption.NumberOfTransfers,
        label: mapSendUntil(SendUntilOption.NumberOfTransfers),
      },
      {
        value: SendUntilOption.LastTransferDate,
        label: 'Expiration Date',
      },
    ];
    this.sendUntilChange();
    this.isNumberOfTransfersValid = true;
    this._getLoanDetails();
  }

  private _getLoanDetails(): void {
    this.loanDetails = this._cachedService.getLoanAccount(this.transferToEdit.accountIdTo);
    // If it comes from Payveris, we need to split the total amount
    // We try to cover the monthly payment and, if any diference, will be sent to additional principal
    if (this.transferToEdit.scheduledTransferType == 2) {
      if (this.transferToEdit.totalAmount < this.loanDetails.paymentAmount) {
        // This is for the cases when the old payveris transfer was made to pay only principal
        this.transferToEdit.additionalPrincipal = this.transferToEdit.totalAmount;
        this.transferToEdit.amount = 0;
      } else {
        this.transferToEdit.additionalPrincipal = +(
          this.transferToEdit.totalAmount - this.loanDetails.paymentAmount
        ).toFixed(2);
        this.transferToEdit.amount = this.loanDetails.paymentAmount;
      }
      this.transferToEdit.oldAmount = this.transferToEdit.amount;
      this.transferToEdit.oldAdditionalPrincipal = this.transferToEdit.additionalPrincipal;
    }
    // If it's a regular payment, disable the additional principal
    if (this.transferToEdit.totalAmount == this.loanDetails.paymentAmount) {
      this.disableAdditionalPrincipal = true;
    }
    this.isLoadingSetup = false;
  }
  /**
   * Fetch customer limits
   */
  private _checkCustomerLimits() {
    this.customerLimitsHelper
      .fetchCustomerLimits()
      .then(res => {
        this._customerLimits = res;
      })
      .catch(this._serviceHelper.errorHandler);
  }

  /**
   * Shows an error notification if transfer exceeded daily limits for the user
   * this message changes whether the transfer is incoming/outgoing
   */
  private _showAmountExceededErrorMessage() {
    this.notificationErrorMsg = `Transfer amount exceeds daily inbound limit of
                                ${this.filterService('currency')(this._customerLimits.extTransfersMaxIncomingAmount)}`;
  }

  /**
   * This should return the model used for external transfers in payveris scheduler
   * @param trans The transfer object to be converted
   */
  private _toExternalTransferRequest(trans: TransferSchedule): ExternalAccountEditRequest {
    return {
      Amount: trans.amount + +trans.additionalPrincipal,
      ExternalAccountId: trans.externalAccountId,
      Frequency: trans.frequency,
      InternalAccountCode: trans.internalAccountCode,
      ScheduledTransferId: trans.scheduledTransferId,
      AccountCode: trans.internalAccountCode,
      ConfirmationNumber: 0,
      ConfirmationNumberspecified: 0,
      InternalAccountId: 0,
      NumberOfTransfers: trans.numberOfTransfers,
      RemainingTransfers: trans.numberOfTransfers,
      TransferType: trans.externalTransferType,
      FromNickName: trans.accountNicknameFrom,
      ToNickName: trans.accountNicknameTo,
      ProcessingDate: trans.newNextTransferDate,
      AccountNumberFrom: trans.accountNumberFrom.toString(),
      AccountNumberTo: trans.accountNumberTo.toString(),
    };
  }

  private _editExternalTransferFromPayveris() {
    const serviceMethod = this.isSeries ? 'scheduleEditExternalTransfer' : 'scheduleEditNextExternalTransfer';
    const externalTransfer = this._toExternalTransferRequest(this.transferToEdit);
    this._transactionService[serviceMethod](externalTransfer)
      .then(() => {
        this._uibModalInstance.close(true);
      })
      .catch(err => {
        if (err.data.message.includes('amount exceeds maximum customer limit')) {
          return this._showAmountExceededErrorMessage();
        }

        this._serviceHelper.errorHandler.bind(this._serviceHelper);
      })
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  private _editExternalTransferFromAxosScheduler() {
    const serviceMethod = this.isSeries ? 'editACHTransferSeries' : 'editNextACHTransfer';
    this._loanService[serviceMethod](this.transferToEdit)
      .then(() => {
        this._uibModalInstance.close(true);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper))
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  private _editInternalTransfer() {
    const serviceMethod = this.isSeries ? 'scheduleEditInternalTransfer' : 'scheduleEditNextInternalTransfer';
    this._transactionService[serviceMethod](this.transferToEdit)
      .then(() => {
        this._uibModalInstance.close(true);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper))
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  private _convertToAxosScheduler() {
    const serviceMethod = this.isSeries ? 'convertExternalSeries' : 'convertNextExternalTransfer';
    this._loanService[serviceMethod](this.transferToEdit)
      .then(() => {
        this._uibModalInstance.close(true);
      })
      .catch(err => {
        if (err.data.message.includes('amount exceeds maximum customer limit')) {
          return this._showAmountExceededErrorMessage();
        }

        this._serviceHelper.errorHandler.bind(this._serviceHelper);
      })
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  /** Changes the calendar options depending on the loan feature flag
   */
  private setCalendarOptions(): void {
    this.isTodayValidForExternal = true;

    let today = this._dateHelper.normalizeDate(moment());
    const externalTransferValidDate = this._dateHelper.getDefaultPaymentDate();

    if (externalTransferValidDate.diff(today, 'days') > 0) {
      today = externalTransferValidDate;
    }

    this.datePickerOptions.minDate = today;
    // Allow selection 13 months in advance
    this.datePickerOptions.maxDate = angular.copy(today).add(13, 'M');
  }

  private LoadDatePickerOptions(): void {
    this.datePickerOptions = angular.extend(angular.copy(this._dateRangePickerOptions), {
      minDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'hide.daterangepicker': (_evt: any) => {
          $('.daterangepicker').first().css('position', 'absolute');
          this.transferToEdit.newNextTransferDate = moment(_evt.model).toDate();
          this.transferToEdit.beginSendingOn = moment(_evt.model).toDate();
          this.isTodayValidForExternal = true;
          this.validateEndDate();
        },
        'show.daterangepicker': (_evt: any) => {
          this.setPropertiesForCalendar();
        },
      },
      isInvalidDate: (date: any) => {
        return !this._dateHelper.isValidDate(date); // Disable weekends and Holidays when paying a Loan
      },
    });

    this.expirationDatePickerOptions = angular.extend(angular.copy(this._dateRangePickerOptions), {
      minDate: moment().add(1, 'days').toDate(),
      maxDate: this.getMaxDate(),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'hide.daterangepicker': (_evt: any) => {
          this.transferToEdit.lastTransferDate = moment(_evt.model).utc().toDate();
          this.validateEndDate();
        },
        'show.daterangepicker': (_evt: any) => {
          this.setPropertiesForCalendar();
        },
      },
      isInvalidDate: (date: any) => {
        return !this._dateHelper.isValidDate(date); // Disable weekends and Holidays when paying a Loan
      },
    });
  }

  private getMaxDate(): moment.Moment {
    return moment().add(13, 'M');
  }

  private validateEndDate(): void {
    if (this.transferToEdit.lastTransferDate !== null && this.transferToEdit.newNextTransferDate !== null) {
      this.isEndDateValid = moment(this.transferToEdit.lastTransferDate).isAfter(
        this.transferToEdit.newNextTransferDate
      );
    }
  }

  private isEndDateValidated(): boolean {
    return this.isEndDateValid || !this.showExpirationDate;
  }

  private setPropertiesForCalendar() {
    var input = $('#date').first();
    var daterangepicker = $('.daterangepicker');

    daterangepicker.css('left', input.offset().left + input.outerWidth() + 10);
    daterangepicker.css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    daterangepicker.append('<div id="arrow-left"></div>');
    daterangepicker.addClass('no-after');

    $('#arrow-left')
      .css('position', 'absolute')
      .css('left', '-8px')
      .css('width', '0')
      .css('height', '0')
      .css('border-top', '8px solid transparent')
      .css('border-bottom', '8px solid transparent')
      .css('border-right', '8px solid darkgray')
      .css('top', daterangepicker.outerHeight() / 2);

    $(document).on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });

    $('.modal-body').on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });
  }

  //#endregion

  //#region Validations

  //#endregion
}
