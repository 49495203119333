import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(value: any[], args?: any, reverse: boolean = false): any {
    if (!value) return null;

    let orderArray = [];
    if (args === 'reverse' || reverse) {
      orderArray = value.reverse();
    } else {
      orderArray = value.sort((a: any, b: any) => {
        if (a[args] < b[args]) {
          return -1;
        } else if (a[args] > b[args]) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return orderArray;
  }
}
