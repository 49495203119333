export class TransactionRequest {
  /**
   * User name
   */
  username?: string;
  /**
   * bank/creditCard/investment/insurance/loan
   */
  container?: string;
  /**
   * DEBIT/CREDIT
   */
  baseType?: string;
  /**
   * Transaction search text
   */
  keyword?: string;
  /**
   * Account Id
   */
  accountId?: number;
  /**
   * Transaction from date
   */
  fromDate?: Date;
  /**
   * Transaction end date
   */
  toDate?: Date;
  /**
   * Category Id
   */
  categoryId?: string;
  /**
   * Transaction Type(Supported containers: bank/card/investment)
   */
  type?: string;
  /**
   * Transaction Category Type(UNCATEGORIZE; INCOME; TRANSFER; EXPENSE or DEFERRED_COMPENSATION)
   */
  categoryType?: string;
  /**
   * skip (Min 0)
   */
  skip?: number;
  /**
   * top (Max 500)
   */
  top?: number;
  /**
   * DetailCategory Id
   */
  detailCategoryId?: string;

  columnOrder: number;

  reverse: boolean;

  amountRangeMin: number;

  amountRangeMax: number;

  checkRangeMin: number;

  checkRangeMax: number;

  amountValue: number;

  checkValue: string;

  olbCategoryName?: string;

  olbCategoryId?: number[];
}
