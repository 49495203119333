import { finalize } from 'rxjs/operators';

import { UserProfileViews } from '@app/user-profile/enums';
import { formatAddress, phoneToString } from '@app/utils';
import { OutdatedContactInfoService } from '@core/services';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { Phone } from '@shared/models';

import { Inject } from '../../decorators/decorators';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';

@Inject(
  '$scope',
  '$rootScope',
  '$uibModalInstance',
  '$state',
  'outdatedContactInfoService',
  'featureFlagService',
  'env',
  'supportViewFacade'
)
export class OutdatedContactInfoModalController {
  ComponentName = 'OutdatedContactInfoModalComponent';
  logo: string;
  contactInfo: ContactInfo;
  contactInfoIsValid: boolean;
  headerTitle: string;
  loading = true;
  brandName: string;
  isRIAUser$: boolean;
  isLoadingRIAUser$ = this.supportViewFacade.isLoading$;

  constructor(
    private _scope: ng.IScope,
    private readonly _root: ng.IRootScopeService,
    private _uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private $state: ng.ui.IStateService,
    private _outdatedContactInfoModalService: OutdatedContactInfoService,
    private featureFlagService: FeatureFlagService,
    private env: OlbSettings,
    private supportViewFacade: SupportViewFacade
  ) {}

  $onInit(): void {
    this.supportViewFacade.getRIAUserFlag();

    this.supportViewFacade.isLoading$.subscribe(isLoading => {
      if (!isLoading) {
        this.supportViewFacade.isRIAUser$.subscribe(isRiaUser => {
          if (isRiaUser) {
            this.headerTitle = `Confirm your personal information`;
          } else {
            this.headerTitle = `Welcome to ${this.env.brandName} Online Banking`;
          }
        });
      }
    });

    this.brandName = this.env.brandName.toLocaleLowerCase();
    this._root['profileInfo'] ? this.loadInfo() : this._scope.$on('profileInfoLoaded', () => this.loadInfo());
  }

  formatAddress(address: Address) {
    return formatAddress(address);
  }

  phoneToString(phone: Phone) {
    return phoneToString(phone);
  }

  /**
   * Closes the modal.
   */
  close() {
    this._outdatedContactInfoModalService.remindMeLater().subscribe(() => {
      this._uibModalInstance.close();
    });
  }

  /**
   * Closes the modal and redirects to the user profile page.
   */
  editInformation() {
    this._uibModalInstance.close();
    this._outdatedContactInfoModalService.logUserIsEditing().subscribe();

    if (!this.featureFlagService.isSBBActive()) {
      this.$state.go('udb.userProfile', { view: UserProfileViews.PersonalInformation });
    } else {
      this.$state.go('udb.userProfile', { view: UserProfileViews.ContactInfo });
    }
  }

  /**
   * Closes the modal and notifies that the user info is up to date.
   */
  ok() {
    this._outdatedContactInfoModalService
      .confirmContactInfoIsUpdated()
      .pipe(
        finalize(() => {
          this._uibModalInstance.close();
        })
      )
      .subscribe();
  }

  /**
   * Validates if an object have empty strings.
   * @param obj The object to validate
   */
  private isContactInfoValid(contact: ContactInfo): boolean {
    let isValid = false;
    if (!!contact) {
      const {
        address: { homeAddress },
        email: { primaryEmail },
        phone,
      } = contact;
      const validPhone =
        !!phone && !!phone['primaryCell'] && !!phone['primaryCell'].number && phone['primaryCell'].number !== '0';
      const validHomeAddress =
        !!homeAddress &&
        !!homeAddress.city &&
        homeAddress.stateCode &&
        homeAddress.postalCode &&
        homeAddress.streetAddress1;
      isValid = !!validHomeAddress && !!primaryEmail && validPhone;
    }

    return isValid;
  }

  /**
   * Loads the user info from the root scope.
   */
  private loadInfo() {
    const userProfileInfo = this._root['profileInfo'] as UserProfileInfo;
    this.contactInfo = this.formatInfo(userProfileInfo);
    this.contactInfoIsValid = this.isContactInfoValid(this.contactInfo);
    this.loading = false;
  }

  /**
   * Maps the root scope user to the ContactInfo format.
   */
  private formatInfo(userProfileInfo: UserProfileInfo): ContactInfo {
    const contactInfo = {
      address: {
        homeAddress: userProfileInfo.primaryAddress.address,
        mailingAddress: userProfileInfo.mailingAddress.address,
      },

      email: {
        alternateEmail: userProfileInfo.alternateEmail,
        primaryEmail: userProfileInfo.primaryEmail,
      },

      phone: {
        alternateCell: userProfileInfo.cellPhone2,
        homePhone: userProfileInfo.homePhone,
        primaryCell: userProfileInfo.cellPhone1,
        workPhone: userProfileInfo.workPhone,
      },
    };

    return contactInfo;
  }
}
