import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { TxFilter } from '@legacy/accounts/typings/TxFilter';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { AggregatedAccount } from '@legacy/typings/app/account-aggregation/AggregatedAccount';
import { UtilityIcons } from '@shared/enums';

import { Category } from '../../models/category';

@Component({
  selector: 'app-insights-expenses-grid',
  templateUrl: './insights-expenses-grid.component.html',
  styleUrls: ['./insights-expenses-grid.component.scss'],
})
export class InsightsExpensesGridComponent implements OnInit, OnChanges {
  @Input() transactionsWithAllCategories: ExternalTransaction[];
  @Input() account: AggregatedAccount;
  @Input() filters: TxFilter;
  @Output() toggleCategoryFilter = new EventEmitter();

  percentageChange: number;
  arrowPosition = '';
  totalValueThisMonth: number;
  totalValueLastMonth: number;
  currentPeriod: Date;
  lastTimePeriod: string;
  accountNickName: string;
  isEmpty: boolean;
  expensesGrid: Category[] = [];

  categories: Category[] = [
    {
      id: 1,
      name: 'Purchases',
      color: '#2268C0',
      imageFile: 'purchases.svg',
    },
    {
      id: 2,
      name: 'Food',
      color: '#572F8E',
      imageFile: 'food.svg',
    },
    {
      id: 3,
      name: 'Bills',
      color: '#1F3E6C',
      imageFile: 'bills.svg',
    },
    {
      id: 4,
      name: 'Transportation',
      color: '#9D1949',
      imageFile: 'transportation.svg',
    },
    {
      id: 5,
      name: 'Personal Care',
      color: '#00805C',
      imageFile: 'personal.svg',
    },
    {
      id: 6,
      name: 'Travel & Entertainment',
      color: '#16A5BF',
      imageFile: 'travel.svg',
    },
    {
      id: 7,
      name: 'Transfers',
      color: '#711F8A',
      imageFile: 'transfers.svg',
    },
    {
      id: 9,
      name: 'Other',
      color: '#0C9C96',
      imageFile: 'other.svg',
    },
  ];

  getPercentageChangeFontSize = this.accAggFormatter.getPercentageChangeFontSize;

  constructor(public accAggFormatter: AccountAggregationChartFormatterService) {}

  ngOnInit(): void {
    this.calculateInformation();
  }

  ngOnChanges() {
    this.calculateInformation();
  }

  toggleSelectedCategory(categoryId: number) {
    this.toggleCategoryFilter.emit({ categoryId });
  }

  filterTransactions(transactions: ExternalTransaction[], filterCategories: boolean = false) {
    return transactions.filter(
      transaction =>
        !transaction.isIncome &&
        !transaction.isPending &&
        transaction.olbCategoryId !== 10 && // exclude pending category
        (filterCategories
          ? this.filters.categories.length
            ? this.filters.categories.some(id => id === transaction.olbCategoryId)
            : true
          : true)
    );
  }

  calculateInformation() {
    const pfmData = this.accAggFormatter.getPfmNumbers(
      this.filterTransactions(this.transactionsWithAllCategories, true)
    );
    this.percentageChange = pfmData.percentageChange;
    this.totalValueThisMonth = pfmData.thisPeriodTransactionsSum;
    this.totalValueLastMonth = pfmData.lastPeriodTransactionsSum;
    this.arrowPosition =
      pfmData.arrow === 'up' ? UtilityIcons.ArrowUp : pfmData.arrow === 'down' ? UtilityIcons.ArrowDown : '';

    this.initExpensesGrid(this.filterTransactions(this.transactionsWithAllCategories));

    this.accountNickName = this.accAggFormatter.getAccountToDisplay(this.account);
    this.currentPeriod = this.accAggFormatter.GetFilterDate(0, 0);
    this.lastTimePeriod = 'Last Month';
    this.isEmpty = this.totalValueThisMonth === 0;
  }

  initExpensesGrid(transactions: ExternalTransaction[]): void {
    this.expensesGrid = this.categories.map(category => ({
      ...category,
      percentage: this.accAggFormatter.getCategoryPercentage(transactions, category.id),
      amount: this.accAggFormatter.getCategoryAmount(transactions, category.id),
    }));

    this.accAggFormatter.roundCategoriesPercentages(this.expensesGrid);
    this.accAggFormatter.SortCategories(this.expensesGrid);
  }

  categoryTrackBy(_, category: Category) {
    return category.id;
  }
}
