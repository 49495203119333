import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { TxFilter } from '@legacy/accounts/typings/TxFilter';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { UtilityIcons } from '@shared/enums';

import { Category } from '../../models/category';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss'],
})
export class IncomeComponent implements OnChanges {
  @Input() transactionsWithAllCategories: ExternalTransaction[];
  @Input() filters: TxFilter;
  @Output() toggleCategoryFilter = new EventEmitter();
  arrow = '';
  totalValueThisMonth: number;
  totalValueLastMonth: number;
  percentageChange: number;
  currentMonthYear: Date;
  isEmpty: boolean;

  incomeGrid: Category[] = [];
  categories: Category[] = [
    {
      id: 7,
      name: 'Transfers',
      color: '#711f8a',
      imageFile: 'transfers.svg',
    },
    {
      id: 8,
      name: 'Deposits',
      color: '#1c8fc9',
      imageFile: 'deposits.svg',
    },
  ];

  getPercentageChangeFontSize = this.accAggFormatter.getPercentageChangeFontSize;

  constructor(public accAggFormatter: AccountAggregationChartFormatterService) {}

  ngOnChanges(): void {
    this.calculateInformation();
  }

  toggleSelectedCategory(categoryId: number) {
    this.toggleCategoryFilter.emit({ categoryId });
  }

  categoryTrackBy(_, category: any) {
    return category.id;
  }

  filterTransactions(transactions: ExternalTransaction[], filterCategories: boolean = false) {
    return transactions.filter(
      transaction =>
        transaction.isIncome &&
        !transaction.isPending &&
        transaction.olbCategoryId !== 10 && // exclude pending category
        (filterCategories
          ? this.filters.categories.length
            ? this.filters.categories.some(id => id === transaction.olbCategoryId)
            : true
          : true)
    );
  }

  calculateInformation() {
    const pfmData = this.accAggFormatter.getPfmNumbers(
      this.filterTransactions(this.transactionsWithAllCategories, true)
    );
    this.percentageChange = pfmData.percentageChange;
    this.totalValueThisMonth = pfmData.thisPeriodTransactionsSum;
    this.totalValueLastMonth = pfmData.lastPeriodTransactionsSum;
    this.arrow = pfmData.arrow === 'up' ? UtilityIcons.ArrowUp : pfmData.arrow === 'down' ? UtilityIcons.ArrowDown : '';
    this.isEmpty = this.totalValueThisMonth === 0;

    this.initIncomeGrid(this.filterTransactions(this.transactionsWithAllCategories));

    this.currentMonthYear = this.accAggFormatter.GetFilterDate(0, 0);
  }

  initIncomeGrid(transactions: ExternalTransaction[]): void {
    this.incomeGrid = this.categories.map(category => ({
      ...category,
      percentage: this.accAggFormatter.getCategoryPercentage(transactions, category.id),
      amount: this.accAggFormatter.getCategoryAmount(transactions, category.id),
    }));

    this.accAggFormatter.roundCategoriesPercentages(this.incomeGrid);
    this.accAggFormatter.SortCategories(this.incomeGrid);
  }
}
