import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import * as angular from 'angular';

import {
  AxosInvestAccountDocumentsComponent,
  AxosInvestGoalComponent,
  DocumentsComponent,
  EditPortfolioComponent,
  GoalEditComponent,
  OverviewComponent,
  TransferFundsTypeSelectorComponent,
} from '@app/axos-invest/components';

import { AxosInvestAccountOverviewComponent } from '../axos-invest/overview/axos-invest-account-overview.component';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { TilesModule } from '../tiles/tiles.module';
import { AxosInvestMilestonesComponent } from './overview/milestones/axos-invest-milestones.component';
import { AxosInvestPortfolioComponent } from './overview/portfolio/axos-invest-portfolio.component';
import { AxosInvestWalletComponent } from './overview/wallet/axos-invest-wallet.component';
import { AxosInvestTransferService } from '@app/axos-invest/services';

export const AxosInvestAccountModule = angular
  .module('udb.axosinvest', [TilesModule])
  .component('axosInvestAccountOverview', AxosInvestAccountOverviewComponent)
  .component('axosInvestMilestones', AxosInvestMilestonesComponent)
  .component('axosInvestWallet', AxosInvestWalletComponent)
  .component('axosInvestPorfolio', AxosInvestPortfolioComponent)
  .directive('axosInvestGoal', downgradeComponent({ component: AxosInvestGoalComponent }))
  .directive('axosInvestEditPortfolio', downgradeComponent({ component: EditPortfolioComponent }))
  .directive('axosInvestTransferFunds', downgradeComponent({ component: TransferFundsTypeSelectorComponent }))
  .directive('appDocuments', downgradeComponent({ component: DocumentsComponent }))
  .directive('axosInvestGoalEdit', downgradeComponent({ component: GoalEditComponent }))
  .directive('axosInvestOverview', downgradeComponent({ component: OverviewComponent }))
  .directive('appAxosInvestAccountDocuments', downgradeComponent({ component: AxosInvestAccountDocumentsComponent }))
  .service('axosInvestTransferService', downgradeInjectable(AxosInvestTransferService))
  .service('axosInvestHelper', downgradeInjectable(AxosInvestHelper))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider
        .state('udb.axosinvest', {
          url: '/Accounts/Invest/:id/Goal',
          views: {
            content: {
              template: '<div ui-view="goal-content"><axos-invest-goal></axos-invest-goal></div>',
            },
          },
          params: {
            referrerTabId: null,
            modifiedState: null,
            modifiedBannerText: null,
          },
        })
        .state('udb.axosinvest.transferfunds', {
          url: '/Transfer-Funds',
          views: {
            'goal-content': {
              template: '<div ui-view="transfer-funds"><axos-invest-transfer-funds></axos-invest-transfer-funds></div>',
            },
          },
          params: {
            accountId: null,
            transferType: null,
            amount: null,
            frequency: null,
            transferSeriesId: null,
            accountNumber: null,
            referrerTabId: null,
            flow: null,
          },
        })
        .state('udb.axosinvest.editgoal', {
          url: '/Goal-Edit',
          views: {
            'goal-content': {
              template: '<div ui-view="goal-edit"><axos-invest-goal-edit></axos-invest-goal-edit></div>',
            },
          },
          params: {
            milestoneData: null,
            referrerTabId: null,
          },
        })
        .state('udb.axosinvest.editportfolio', {
          url: '/Edit-Portfolio',
          views: {
            'goal-content': {
              template: '<div ui-view="edit-portfolio"><axos-invest-edit-portfolio></axos-invest-edit-portfolio></div>',
            },
          },
          params: {
            isSetUpEndTarget: false,
          },
        });
    },
  ]).name;
