import { createFeatureSelector } from '@ngrx/store';

import { AppStateFeatures } from '@core/enums';
import { AxosAdvisoryAccount } from '@core/models';

import { AxosAdvisoryStatePieces } from './enums';
import { TotalContributions } from '@app/transfers/models/total-contributions.model';

export class AxosAdvisoryState {
  [AxosAdvisoryStatePieces.AxosAdvisoryAccounts]: AxosAdvisoryAccount[];
  [AxosAdvisoryStatePieces.AxosAdvisoryContributions]: TotalContributions[];
  constructor(args?: Partial<AxosAdvisoryState>) {
    Object.assign(this, args);
  }
}

export const getAxosAdvisoryState = createFeatureSelector<AxosAdvisoryState>(AppStateFeatures.AxosAdvisory);
