import { Inject } from '../../decorators/decorators';

@Inject('env', '$state')
export class FundingTermsController {
  isLoading = false;
  brand: string;

  constructor(private readonly env: any, private stateService: ng.ui.IStateService) {}

  $onInit(): void {
    this.brand = String(this.env.brand);
  }

  acceptTerms() {
    this.isLoading = true;
    this.stateService.go('udb.dashboard');
  }
}
