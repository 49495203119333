import { CategoryEvent } from './category-event.model';

export class SupportCategory {
  id: number;
  name: string;
  imageUrl: string;
  additionalDescription?: string;
  description: string;

  constructor({ id, name, imageUrl, description }: Partial<SupportCategory>) {
    imageUrl = `support-${imageUrl.replace('.svg', '')}`;

    Object.assign(this, { id, name, imageUrl, description });
  }

  mapToEvent() {
    const name = this.name ?? this.description;

    return new CategoryEvent({ categoryId: this.id, categoryName: name });
  }
}
