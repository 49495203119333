import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiFileExtensions } from '@app/transaction-history/enums/apiFileExtensions.enum';
import { HolidaysResponse, OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { ExternalAccountScheduleRequest, TransferSchedule } from '@app/transfers/models';
import { P2PRecipient } from '@legacy/typings/app/bills/P2PRecipient';
import { P2PPayment } from '@legacy/typings/app/bills/P2PPayment';
import { P2PTransferHistory } from '@legacy/typings/app/bills/P2PTransferHistory';
import { UserAction } from '@legacy/typings/app/UserAction';
import { TradingTransferResponse } from '@core/models/trading-transfer-response.model';
import { Transfer } from '@core/models/transfer.model';
import { MilestoneTransaction } from '@core/models/milestone-transaction.model';
import { ContributionYear } from '@core/models/contribution-year.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionService extends BaseService {
  private readonly transactionsCache: ng.ICacheObject;
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'transfers');
  }

  getHolidayDates(): WebApiResponse<HolidaysResponse> {
    return this.get<HolidaysResponse>('holidays');
  }

  getCheckFiles(accountId: number, checkNumber: string, checkId: string): WebApiResponse<CheckFiles> {
    return this.get(`${accountId}/check/${checkNumber}/${checkId}`);
  }

  /*P2P Transfers */
  getP2PRecipients(): WebApiResponse<P2PRecipient[]> {
    return this.get('recipients');
  }

  transferMoneyP2P(payment: P2PPayment): WebApiResponse<any> {
    return this.post('transfer/p2p', payment);
  }

  getTransfersP2P(): WebApiResponse<P2PTransferHistory[]> {
    return this.get('p2p');
  }

  cancelTransferP2P(payverisTransferId: number): WebApiResponse<any> {
    return this.put(`p2p/${payverisTransferId}/cancel`);
  }

  downloadTransactionsFile(
    transaction: IDownloadTransactionsRequest,
    extension: number = ApiFileExtensions.Xlsx
  ): Observable<Blob> {
    let params = new HttpParams();
    for (const key in transaction) {
      if (transaction.hasOwnProperty(key)) {
        params = params.set(key, transaction[key] ?? '');
      }
    }
    return this.download(`download/${extension}`, params);
  }

  getTransactionsByAccount(accountId: number, startDate: Date, endDate: Date): WebApiResponse<Transaction[]> {
    let params = new HttpParams();
    const endDateNew = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);
    params = params.set('startDate', startDate.toJSON());
    params = params.set('endDate', endDateNew.toJSON());
    return this.get(`${accountId}`, params);
  }

  scheduleExternalTransfer(
    transaction: ExternalAccountScheduleRequest,
    validateDuplicate?: Boolean,
    acceptWarning?: Boolean
  ): WebApiResponse<string> {
    return this.post(
      `schedule/external?validateDuplicate=${validateDuplicate}&acceptWarning=${acceptWarning}`,
      transaction
    );
  }

  scheduleACHTransfer(transaction: TransferSchedule): WebApiResponse<string> {
    return this.post('ach/schedule', transaction);
  }

  scheduleTransfer(
    transaction: TransferSchedule,
    validateDuplicate: boolean = false,
    acceptWarning: boolean = false
  ): WebApiResponse<string> {
    return this.post(`schedule?validateDuplicate=${validateDuplicate}&acceptWarning=${acceptWarning}`, transaction);
  }

  clearCache(): void {
    if (this.transactionsCache) {
      this.transactionsCache.removeAll();
    }
  }

  transfer(
    transaction: TransferSchedule,
    validateDuplicate: boolean = false,
    acceptWarning: boolean = false
  ): WebApiResponse<string> {
    return this.post(`transfer?validateDuplicate=${validateDuplicate}&acceptWarning=${acceptWarning}`, transaction);
  }

  logUserAction(action: UserAction): WebApiResponse<void> {
    return this.post('audit', action);
  }

  transferTrading(transaction: TransferSchedule, isFunding?: boolean): WebApiResponse<TradingTransferResponse> {
    return this.post(`trading?confirmation=true&isFunding=${isFunding}`, transaction);
  }

  transferInvest(transaction: Transfer, isExternal?: boolean): WebApiResponse<any> {
    return this.post(`invest?isExternal=${isExternal}`, transaction);
  }

  transferInvestMilestoneToMilestone(milestoneTransaction: MilestoneTransaction): WebApiResponse<any> {
    return this.post(`invest/milestone`, milestoneTransaction);
  }

  getContributionYears(): WebApiResponse<ContributionYear[]> {
    return this.get('contributionyears');
  }
}
