import { Inject } from '../../../decorators/decorators';

@Inject('$uibModalInstance')
export class WireTransfersAboutModalController {
  constructor(private modalInstance: ng.ui.bootstrap.IModalServiceInstance) {}

  $onInit(): void {}

  public close() {
    this.modalInstance.close('ok');
  }
}
