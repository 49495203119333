<div class="networth-module">
  <div class="networth-title">
    <h3>Net Worth</h3>
    <app-svg-icon [iconName]="icons.ArrowRight" width="20.5px" height="29px" [iconColor]="'#4A5560'"></app-svg-icon>
  </div>
  <app-dot-loader [showWhen]="(netWorthLoading | async) || (accountsLoading | async)">
    Loading
  </app-dot-loader>
  <div [class.invisible]="(netWorthLoading | async) || (accountsLoading | async)">
    <div class="networth-subtitle">
      <span>
        Your current total is
        <span *ngIf="!isEmpty" class="font-weight-bold">{{ currentNetWorth?.worth.net | currency }}</span>
        <span *ngIf="isEmpty" class="font-weight-bold">$--</span>
      </span>
    </div>
    <div class="networth-border d-md-none"></div>
    <div class="container-fluid">
      <div class="networth-body row mt-5">
        <div class="col-md-8 col-12">
          <app-line-chart
            [height]="280"
            [points]="lineChartPoints"
            [xAxisTickFormatter]="lineChartXAxisTickFormatter"
            [chartjsOptions]="{
              scales: {
                yAxes: [{ ticks: { display: false } }],
                xAxes: [{ ticks: { maxTicksLimit: 5 } }]
              }
            }"
          ></app-line-chart>
        </div>
        <div *ngIf="isEmpty" class="col-md-4 d-none d-md-flex networth-header">
          <h5 class="mb-2 mt-5">No Account Activity</h5>
        </div>
        <div *ngIf="!isEmpty" class="col-md-4 d-none d-md-flex networth-header">
          <h5 class="mb-2">Since {{ previousNetWorth?.date | date: 'MMMM yyyy' }}</h5>
          <div class="section">
            <span>% Change</span>
            <div class="values">
              <app-svg-icon
                *ngIf="percentageChange != 0"
                [iconName]="getArrow()"
                [styles]="{ width: '15px', height: '12px', fill: '#494949' }"
              ></app-svg-icon>
              {{ percentageChange | percentageRound }}%
            </div>
          </div>
          <div class="section">
            <span>Amount Change</span>
            <div class="values">{{ showSignValue() }}{{ dollarChange | currency: 'USD':'symbol':'1.0-0' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isEmpty" class="d-md-none d-flex justify-content-around align-items-center">
      <span class="m-3">No account activity</span>
    </div>
    <div *ngIf="!isEmpty" class="d-md-none d-flex justify-content-around align-items-center">
      <div class="values-responsive">
        <app-svg-icon
          *ngIf="percentageChange != 0"
          [iconName]="getArrow()"
          [styles]="{ width: '10.6px', height: '8px', fill: '#494949' }"
        ></app-svg-icon>
        {{ percentageChange | percentageRound }}%
      </div>
      <div class="values-responsive">{{ showSignValue() }}{{ dollarChange | currency: 'USD':'symbol':'1.0-0' }}</div>
      <span>Since {{ previousNetWorth?.date | date: 'MMMM yyyy' }}</span>
    </div>
  </div>
</div>
