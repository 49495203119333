import { Inject } from 'decorators/Inject';

@Inject('$state', 'env')
export class UnableDownloadCompleteController {
  env: OlbSettings;

  constructor(private state: ng.ui.IStateService, _env: OlbSettings) {
    this.env = _env;
  }

  goToDashboard(): void {
    this.state.go('udb.dashboard');
  }
}
