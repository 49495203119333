import { BaseService } from './base.service';
import { Inject } from '../decorators/decorators';
import { ITaxPlanBeneficiariesService } from './typings/ITaxPlanBeneficiariesService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class TaxPlanBeneficiariesService extends BaseService
  implements ITaxPlanBeneficiariesService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'tax-plan/beneficiaries', _serviceHelper, q);
  }

  getRelationshipCatalog(): ApiResponse<object> {
    return this.get('catalogs/relationship');
  }
  addBeneficiary(beneficiary: TaxPlanBeneficiary): ApiResponse<string> {
    return this.post(``, beneficiary);
  }
  editBeneficiary(beneficiary: TaxPlanBeneficiary): ApiResponse<void> {
    return this.put(``, beneficiary);
  }
  removeBeneficiary(
    taxPlanCif: string,
    taxPlanType: number,
    beneficiaryKey: string
  ): ApiResponse<void> {
    return this.delete(`${taxPlanCif}/${taxPlanType}/${beneficiaryKey}`);
  }
}
