<div class="e-statements-confirmation">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <img src="/img/icons/billPay/checkmark.svg" width="87" aria-hidden="true" />
    </div>
  </div>

  <div class="confirmation-section frow center">
    <div class="confirmation-subtitle frow center">
      <p>eBills activated for {{ recipient.nickName || recipient.displayName }}.</p>
    </div>

    <table class="table table-striped confirmation-table fcol-xs-12 fcol-sm-6">
      <tbody>
        <tr></tr>
        <tr>
          <td class="text-left">
            <span>Recipient</span>
            <br class="show-responsive" />
            <span class="text-right show-responsive" [textContent]="recipient.nickName || recipient.displayName"></span>
          </td>
          <td class="text-right hide-responsive">
            <p>{{ recipient.nickName || recipient.displayName }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Account Number</span>
            <br class="show-responsive" />
            <span class="text-right show-responsive" [textContent]="recipient.paymentAccount"></span>
          </td>
          <td class="text-right hide-responsive">
            <p>{{ recipient.paymentAccount }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>eBills</span>
            <br class="show-responsive" />
            <p class="show-responsive">
              <span class="green-bullet"></span>
              On
            </p>
          </td>
          <td class="text-right hide-responsive">
            <p>
              <span class="green-bullet"></span>
              On
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <!--Below would be the Autopay Details, once available-->

    <div class="confirmation-buttons frow no-wrap-xs center text-center">
      <span>
        To turn off eBills, go to {{ recipient.nickName || recipient.displayName | possessive }}
        <span class="italics">eBills</span>
        tab in the Bill Pay Dashboard.
      </span>
    </div>

    <div class="confirmation-buttons frow no-wrap-xs center">
      <button type="button" class="uk-btn lg solid secondary" (click)="redirectToSchedule()">
        Schedule A Payment
      </button>
    </div>
    <div class="confirmation-buttons frow no-wrap-xs center">
      <a class="btn-link" (click)="goToBillPayDashboard()">
        Go to Bill Pay Dashboard
      </a>
    </div>
  </div>
</div>
