<div class="col-12 col-md-6 ml-5 mt-5">
  <div class="row">
    <div class="col-8 col-md-10">
      <label for="account" class="uk-label">Account</label>
      <select name="account" class="uk-input" [(ngModel)]="account.id" (change)="updateChoice.emit()">
        <option value="-1">Select Account</option>
        <option *ngFor="let acc of account.choices" [value]="acc.index">
          {{ acc.label }}
        </option>
      </select>
    </div>
    <div *ngIf="showDelete" class="delete-button">
      <a class="delete-button__link" (click)="delete.emit(index)">
        <app-svg-icon
          [iconName]="deleteIcon"
          [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
        ></app-svg-icon>
        <span class="delete-button__label">DELETE</span>
      </a>
    </div>
    <span *ngIf="!allowSplit" class="row ml-3 mt-2 mb-4 font-italic">
      Your employer allows direct deposit to one account only.
    </span>
  </div>
  <span class="mt-4 uk-label">Distribution</span>
  <div class="ml-4">
    <input
      type="radio"
      id="switchTotal_{{ index }}"
      [(ngModel)]="account.isTotal"
      name="switchAmount_{{ index }}"
      [value]="true"
      [disabled]="showDelete"
    />
    <label for="switchTotal_{{ index }}">Total Amount</label>
  </div>
  <div class="ml-4">
    <input
      type="radio"
      id="switchPartial_{{ index }}"
      [(ngModel)]="account.isTotal"
      name="switchAmount_{{ index }}"
      [value]="false"
    />
    <label for="switchPartial_{{ index }}">Percentage</label>
  </div>
  <div *ngIf="!account.isTotal" class="ml-lg-5 mt-2">
    <input
      id="percentage_{{ index }}"
      name="percentage_{{ index }}"
      [(ngModel)]="account.percentage"
      class="col-6 uk-input mb-2"
      mask="000"
      suffix="%"
      placeholder="Deposit%"
      (ngModelChange)="checkPercentage(index)"
    />
    <span *ngIf="account.errorMessage" class="text-error">
      {{ account.errorMessage }}
    </span>
  </div>
</div>
