import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { PrivacyNoticeModal } from './view/privacy-notice-modal.component';

@NgModule({
  declarations: [PrivacyNoticeModal],
  imports: [SharedModule],
  exports: [PrivacyNoticeModal],
})
export class PrivacyNoticeModule {}
