import { Action, createReducer, on } from '@ngrx/store';

import { InvestAccount } from '@app/axos-invest/models';
import { signOut } from '@app/store/actions';

import { loadInvestAccounts } from './invest-accounts.actions';

export const investAccountsInitialState = new InvestAccount();

const _reducer = createReducer(
  investAccountsInitialState,
  on(loadInvestAccounts, (_state, action) => {
    return action.payload;
  }),
  on(signOut, () => {
    return {};
  })
);

export function investAccountsReducer(state: InvestAccount, action: Action) {
  return _reducer(state, action);
}
