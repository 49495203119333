import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { IStateService } from 'angular-ui-router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Uk2BreadcrumbsItem, Uk2Tier1NavigationEnum, Uk2Tier1UtilityEnum } from '@axos/uikit-v2-lib';

import { STATE } from '@core/tokens';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { PdpLayoutFacade } from '@app/Areas/AAS/features/product-details-page/layout/facade';

@Component({
  selector: 'app-account-details-tile',
  templateUrl: './account-details-tile.component.html',
  styleUrls: ['./account-details-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDetailsTileComponent implements OnInit, OnDestroy {
  uk2NavigationIcons = Uk2Tier1NavigationEnum;
  uk2UtilityIcons = Uk2Tier1UtilityEnum;
  breadcrumbItems: Uk2BreadcrumbsItem[];
  accountNumber: string;
  private _destroy$ = new Subject<void>()

  constructor(
    @Inject(STATE) private stateService: IStateService,
    private pdpFacade: PdpFacade,
    private pdpLayoutFacade: PdpLayoutFacade
  ) {}

  ngOnInit(): void {
    ​this.pdpLayoutFacade.contextualTopBarState$.pipe(takeUntil(this._destroy$)).subscribe(breadcrumbs => {
      this.breadcrumbItems = breadcrumbs.breadcrumbItems;
    });
    this.pdpFacade.selectedAasAccount$.pipe(takeUntil(this._destroy$)).subscribe(account => {
      this.accountNumber = account.accountNumber
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  goToAccountDetailsPage(): void {
    const pdpBreadcrumbItem = {
      label: 'Account Details',
      url: `/Account/Details/${this.accountNumber}`,
    };

    this.stateService.go('udb.accountDetailsPage', {
      accountNumber: this.accountNumber,
    });

    if (this.breadcrumbItems) {
      this.breadcrumbItems.push(pdpBreadcrumbItem);

      this.pdpLayoutFacade.addBreadcrumbItem(this.breadcrumbItems);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  getLast4Characters(s: string): string {
    if (s.length <= 4) return s;
    return s.substring(s.length - 4, s.length);
  }
}
