import { STATE } from '@core/tokens';

export function stateServiceFactory($inject: any) {
  return $inject.get('$state');
}

export const stateServiceProvider = {
  provide: STATE,
  useFactory: stateServiceFactory,
  deps: ['$injector'],
};
