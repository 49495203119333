<form class="form-container" [formGroup]="form" novalidate (submit)="submitForm()">
  <div class="form-group goal-account-form-group" *ngIf="retirementAccounts && retirementAccounts.length > 0">
    <label for="retirementAccountType" class="uk-label" for="retirementAccountType">Retirement Account Type</label>
    <ng-container *ngIf="transferType != TRANSFER_TYPES.FundingFlow; else fundingGoalAccountReadOnly">
      <select name="retirementAccountType" id="retirementAccountType" class="uk-input" formControlName="goalAccount">
        <option value="" disabled>Select Retirement Account Type</option>
        <option *ngFor="let account of retirementAccounts" [value]="account.accountNumber">{{ account.name }}</option>
      </select>
      <div class="text--error" *ngIf="form.get('goalAccount').invalid && form.get('account').touched">
        Please select Retirement Account Type
      </div>
    </ng-container>
    <ng-template #fundingGoalAccountReadOnly>
      <div class="retirementAccountTypeReadOnly">{{ retirementAccounts[0].name }}</div>
    </ng-template>
  </div>
  <div
    class="form-group"
    *ngIf="iraAccountSelected && (transferType === 0 || transferType === 1) && contributionYearsLoaded"
  >
    <label for="contributionYear" class="uk-label">Contribution Year</label>
    <ng-container *ngIf="previousContributionYear; else contributionYearReadOnly">
      <select name="contributionYear" id="contributionYear" class="uk-input" formControlName="contributionYear">
        <option disabled [ngValue]="null">Select Contribution Year</option>
        <option [ngValue]="currentContributionYear.year">
          {{ currentContributionYear.year }} (Total Contribution: {{ currentContributionYearLimit | currency }})
        </option>
        <option [ngValue]="previousContributionYear.year">
          {{ previousContributionYear.year }} (Total Contribution: {{ previousContributionYearLimit | currency }})
        </option>
      </select>
      <div class="text--error" *ngIf="form.get('contributionYear').invalid && form.get('contributionYear').touched">
        Select a tax contribution year
      </div>
    </ng-container>
    <ng-template #contributionYearReadOnly>
      <select name="contributionYearReadOnly" id="contributionYearReadOnly" class="uk-input" disabled>
        <option [ngValue]="currentContributionYear.year">
          {{ currentContributionYear.year }} (Total Contribution: {{ currentContributionYearLimit | currency }})
        </option>
      </select>
    </ng-template>
    <span class="tax-contribution-tooltip">
      <app-tooltip
        content="Your total contribution is the amount you’ve contributed to this specific IRA account and is subject to IRS annual limits."
      ></app-tooltip>
      <p class="tax-contribution-tooltip-label">Learn More About Contribution Amounts</p>
    </span>
  </div>
  <div class="form-group account-form-group">
    <label for="fundingAccount" class="uk-label">{{ labels.account }}</label>
    <ng-container *ngIf="transferType != TRANSFER_TYPES.FundingFlow; else fundingAccountReadOnly">
      <select name="fundingAccount" id="fundingAccount" class="uk-input" formControlName="accountId">
        <option value="" [innerText]="accountsLoaded ? 'Select an account' : 'Loading...'" disabled></option>
        <optgroup label="Internal Accounts" *ngIf="internalAccounts && internalAccounts.length > 0">
          <option *ngFor="let internalAccount of internalAccounts" [value]="internalAccount.id">
            {{ internalAccount.nickname }} (Avail. Bal: {{ internalAccount.availableBalance | currency }})
          </option>
        </optgroup>
        <optgroup label="Non-Axos Accounts" *ngIf="externalAccounts && externalAccounts.length > 0">
          <option *ngFor="let externalAccount of externalAccounts" [value]="externalAccount.id">
            {{ externalAccount.nickname }}
            <ng-container *ngIf="externalAccount.availableBalance">
              (Avail. Bal: {{ externalAccount.availableBalance | currency }})
            </ng-container>
          </option>
        </optgroup>
        <optgroup label="Investment Accounts" *ngIf="goalAccounts && goalAccounts.length > 0">
          <option *ngFor="let goalAccount of goalAccounts" [value]="goalAccount.routingNumber">
            {{ goalAccount.nickname }} (Total Value: {{ goalAccount.availableBalance | currency }})
          </option>
        </optgroup>
      </select>
      <div class="text--error" *ngIf="form.get('account').invalid && form.get('account').touched">
        Please select Funding Account
      </div>
    </ng-container>
    <ng-template #fundingAccountReadOnly>
      <div class="fundingAccountReadOnly">{{ form.get('account').value?.nickname }}</div>
    </ng-template>
  </div>
  <div class="form-group amount-form-group">
    <label class="uk-label" for="amountTransfer">{{ labels.amount }}</label>
    <app-reactive-transfer-amount [control]="form.get('amount')"></app-reactive-transfer-amount>
    <div class="text--error" *ngIf="form.get('amount').invalid && form.get('amount').touched">
      <ng-container *ngIf="form.get('amount').errors.max; else noAmountError">
        <ng-container *ngIf="form.get('amount').errors.max.max > 0; else noFundsError">
          Amount may not exceed {{ form.get('amount').errors.max.max | currency }}
        </ng-container>
      </ng-container>
      <ng-template #noFundsError>
        Insufficient funds available
      </ng-template>
      <ng-template #noAmountError>
        Enter an amount
      </ng-template>
      <ng-template *ngIf="form.get('amount').errors.min">
        Min Amount {{ form.get('amount').errors.min.min | currency }}
      </ng-template>
    </div>
  </div>
  <div class="form-group frequency-form-group" *ngIf="!transferTypesWithoutFrequency.includes(transferType)">
    <label for="frequency" class="uk-label">{{ labels.frequency }}</label>
    <select name="frequency" id="frequency" class="uk-input" formControlName="frequency">
      <option *ngFor="let frequency of frequencies" [value]="frequency.value">
        {{ frequency.label }}
      </option>
    </select>
    <div class="text--error" *ngIf="form.get('frequency').invalid && form.get('frequency').touched">
      Please select Frequency
    </div>
  </div>
  <div class="form-group date-picker start-date-form-group">
    <label for="startDate" class="uk-label">{{ labels.startDate }}</label>
    <i class="bofi-datepicker date-picker-icon" (click)="startDateInput.click()"></i>
    <input
      #startDateInput
      appDateRangePicker
      [options]="startDatePickerOptions"
      (dateApplied)="onChangeStartDate($event)"
      type="text"
      name="startDate"
      id="startDate"
      aria-label="date"
      class="uk-input date-picker-input"
      readonly
      [value]="form.get('startDate').value | date: dateFormat"
      [disabled]="transferTypesWithDisabledStartDate.includes(transferType)"
    />
  </div>
  <div class="form-group date-picker end-date-form-group" *ngIf="form.get('frequency').value !== FREQUENCIES.ONE_TIME">
    <label for="endDate" class="uk-label">{{ labels.endDate }} (Optional)</label>
    <i class="bofi-datepicker date-picker-icon" (click)="endDateInput.click()"></i>
    <input
      #endDateInput
      appDateRangePicker
      [options]="endDatePickerOptions"
      (dateApplied)="onChangeEndDate($event)"
      type="text"
      name="endDate"
      id="endDate"
      aria-label="date"
      class="uk-input date-picker-input"
      readonly
      [value]="form.get('endDate').value | date: dateFormat"
    />
  </div>
  <div class="button-container">
    <button class="btn-ok button--lg" type="submit" id="submitBtn">Continue</button>
    <button class="btn-cancel button--lg" type="button" (click)="goBack.emit()">Back</button>
  </div>
</form>
