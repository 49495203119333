import { RiskFactor } from './risk-factor.model';

/** Represents start session. */
export class RemoteSessionRequest {
  /** User Id */
  userId: number;
  /** Depositor IP Address */
  depositorIpAddress: string;
  /** Session Id */
  sessionId: string;
  /** Local dateTime (User DateTime) */
  localDateTime: Date;
  /** TimeZone */
  timezone: string;
  /** Device Identifier */
  deviceIdentifier: string;
  sessionToken: string;
  depositToAccountNumberIndex?: number;
  amount?: number;
  frontBase64Image?: string;
  backBase64Image?: string;
  batchId?: string;
  accountNickname?: string;
  accountId?: string;
  warnings?: RiskFactor[];

  constructor(args: Partial<RemoteSessionRequest>) {
    Object.assign(this, args);
  }
}
