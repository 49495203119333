import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { AccountCategory, ContainerType } from '@app/accounts/enums';
import { FundingState } from '@app/store/funding/funding.state';
import { AuthenticatedInstitution } from '@legacy/typings/app/account-aggregation';
import { AggregatedAccount } from '@app/accounts/models';
import { RewardUnits } from '@legacy/accounts/rewardUnits.enum';
import { accountFundingStatus } from '@legacy/dashboard/account-aggregation/typings/account-funding-status';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import NgRedux from 'ng-redux';
import { STATE, STATE_PARAMS, ngRedux } from '@core/tokens';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { BalanceService } from '@legacy/services/balance.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FlowService } from '@legacy/services/flow.service';
import { Store } from '@ngrx/store';
import { AggregatedSuccess } from '@legacy/typings/app/account-aggregation/AggregatedSuccess';
import { AddBulkVm } from '@legacy/dashboard/account-aggregation/typings/AddBulkVm';
import { setupFundingFromAccountId } from '@app/store/funding/funding.actions';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { AddExternalAccount } from '@legacy/dashboard/account-aggregation/typings/AddExternalAccount';
import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';
import { PayverisProcessedStatus } from '@legacy/dashboard/account-aggregation/enums/fast-link-Pay-Verys-Processed-Status-enum';
import { ExternalAccountsProcessStatus } from '@legacy/dashboard/account-aggregation/enums/fast-link-external-accounts-process-status-enum';
import { AggregatedAccountsFastLink } from '@legacy/typings/app/account-aggregation/AggregatedAccountsFastLink';
import { AccountsService } from '@core/services';
import {
  addSharedAccountsPrompting,
  SharedAccountsService,
  SharedAccountsStateType,
} from '@app/Areas/AAS/features/account-details/core';
import { MatDialog } from '@angular/material/dialog';
import { ShareAccountRiaComponent } from '@app/accounts/components/modals/share-account-ria/view';

@Component({
  selector: 'app-aggregation-success',
  templateUrl: './aggregation-success.component.html',
  styleUrls: ['./aggregation-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregationSuccessComponent implements OnInit {
  /**
   * returns true if there are none accounts into newPayverisAccounts array
   * Update: With new Funding Flow Aggregated Accounts are not added to payveris at this point
   */
  get accountsAlreadyAdded() {
    const result = !this.newPayverisAccounts.length;

    if (result && !this.isFundingOrMoveMoneyFlow) {
      this.errorMessage = `All accounts eligible for Instant Account Verification have already been linked.
                                 If your account is still unavailable for external transfers, you can try to
                                 <b><a href="/AddExternalAccount">add it manually</a></b>`;
    }

    return result;
  }

  get allArePending() {
    return (
      this.isValidateExternal() &&
      this.payverisAccounts.every(a => a.isPendingVerification)
    );
  }

  get anyPending() {
    return (
      this.isValidateExternal() &&
      this.payverisAccounts.some(a => a.isPendingVerification)
    );
  }
  get pendingTooltip() {
    return 'Accounts that are pending verification could take up to 24 hours to clear.';
  }
  get notEligibleTooltip() {
    return 'Only a checking or savings account can be used for transfers.';
  }
  institution: AuthenticatedInstitution;
  providerAccountId: number;
  rememberCheck = true;
  isPayverisProcessedError = false;
  /** funding flow */
  fundingState: FundingState;
  selectedPayverisAccId = 0;
  selectedAggregatedAccId = 0;

  isLoading = false;
  appendTextFunding = '';
  isAccountAggregationFlow: Boolean = false;
  brandDisplayName: string;
  errorMessage: string;
  isAccAggrEnhancementActive = false;
  fastlinkResponseData: AggregatedAccountsFastLink[];
  isAnyAccountUpdatedOrDeleted = false;
  fastlinkUpdatedAccounts: AggregatedAccountsFastLink[] = [];
  bankName: string;
  isMoveMoneyFlow = false;
  isFundingOrMoveMoneyFlow = false;
  flow: string;
  payverisAccounts: ExternalAccount[] = [];
  newPayverisAccounts: ExternalAccount[] = [];
  aggregatedAccounts: AggregatedAccount[] = [];
  pendingVerificationAccounts: AggregatedAccount[] = [];
  containerType = ContainerType;
  categories = [
    AccountCategory.Dda,
    AccountCategory.Sav,
    AccountCategory.Cd,
    AccountCategory.Loan,
    AccountCategory.Credit,
    AccountCategory.Other,
    AccountCategory.Unknown,
  ];
  successMessage: string;
  messageType = 'success';
  fundingStatus = accountFundingStatus;
  rewardUnits = RewardUnits;
  flowTypeEnum = FlowType;
  public accounts: AggregatedAccount[];
  private popups: IPopups;
  constructor(
    @Inject(STATE) private stateService: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private stateParams: ng.ui.IStateParamsService,
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    private accountAggregationService: AccountAggregationService,
    private serviceHelper: ServiceHelper,
    private cachedAccountsService: CachedAccountsService,
    private featureFlagService: FeatureFlagService,
    private _balanceService: BalanceService,
    private flowService: FlowService,
    private store: Store,
    private accountsService: AccountsService,
    private sharedAccountsService: SharedAccountsService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fastlinkResponseData = this.checkForPayverisError();
    this.bankName = this.fastlinkResponseData[0].providerName;
    this.flow = this.stateParams['flow'];
    this.getUpdatedAccounts();

    this.accountAggregationService
      .getAccounts()
      .then(aggregated => {
        this.cachedAccountsService.loadAggregatedAccounts(aggregated.data);
      })
      .catch(err => this.serviceHelper.fastLinkerrorHandler(err));

    this.accountsService.getExternalAccounts().subscribe({
      next: external => {
        this.cachedAccountsService.loadExternalAccounts(external.data);
      },
      error: err => this.serviceHelper.fastLinkerrorHandler(err),
    });
  }

  getAmountForFastLink(acc: AggregatedAccountsFastLink) {
    if (
      acc.availableBalanceAmount !== 0 &&
      acc.availableBalanceAmount !== null
    ) {
      return acc.availableBalanceAmount;
    } else {
      return acc.balanceAmount;
    }
  }

  getUpdatedAccounts() {
    this.fastlinkResponseData.forEach(account => {
      if (
        account.accountProcessStatus ===
        ExternalAccountsProcessStatus.AccountUpdated
      ) {
        this.isAnyAccountUpdatedOrDeleted = true;
        this.fastlinkUpdatedAccounts.push(account);
      }
    });

    this.fastlinkResponseData = this.fastlinkResponseData.filter(
      account =>
        account.accountProcessStatus ===
        ExternalAccountsProcessStatus.AccountAdded
    );
  }

  checkForPayverisError() {
    const accounts = this.stateParams[
      'accounts'
    ] as AggregatedAccountsFastLink[];

    if (accounts.length) {
      const response = accounts.findIndex(
        account => account.payverisProcessed == PayverisProcessedStatus.Fail
      );
      this.isPayverisProcessedError = response > -1 ? true : false;
      return accounts;
    } else {
      this.finish();
    }
  }

  getNameToDisplay(account: AggregatedAccount) {
    let lastFourDigits = '';
    if (account.fullAccountNumber != null) {
      lastFourDigits = account.fullAccountNumber.substring(
        account.fullAccountNumber.length - 4
      );
    } else {
      if (account.accountNumber != null) {
        lastFourDigits = account.accountNumber.substring(
          account.accountNumber.length - 4
        );
      }
    }

    const name: string = account.nickName
      ? account.nickName
      : account.accountName;

    if (name.includes(lastFourDigits)) {
      return name;
    } else {
      return `${name} - ${lastFourDigits}`;
    }
  }

  getLogo(iconName: string): any {
    //return require(`../assets/${iconName}.svg`).default;
    return `../assets/${iconName}.svg`;
  }

  /**
   * Handles the redirect to another page when the customer
   * is not in the funding flow.
   * @param state The state name to go for the next step.
   * @param reload Indicates if the state should be reloaded.
   */
  continue(state: string, needsReload: boolean, params: any = {}) {
    const stateOptions: ng.ui.IStateOptions = {
      reload: needsReload ? state : false,
    };
    if (this.rememberCheck) {
      const userActionField = new UserActionField(
        'Remember accounts',
        null,
        'YES'
      );
      this.accountAggregationService.logUserAction(
        new UserAction('DeleteBankAccounts', [userActionField])
      );
      this.stateService.go(state, params, stateOptions);

      return;
    }

    this.isLoading = true;
    const distinctProviderAccountIds: number[] = [];

    this.aggregatedAccounts.forEach((account: AggregatedAccount) => {
      if (
        !distinctProviderAccountIds.find(x => x == account.providerAccountId)
      ) {
        distinctProviderAccountIds.push(account.providerAccountId);
      }
    });

    this.accountAggregationService
      .deleteBankAccounts(
        this.providerAccountId,
        +this.accounts[0].providerId,
        distinctProviderAccountIds
      )
      .then(({ data }) => {
        this.deleteExcludedAccounts({
          deletedExternalAccountsIds: [],
          deletedAggregatedAccountsIds: data,
        });
        this.stateService.go(state, params, stateOptions);
      })
      .catch(err => {
        this.serviceHelper.errorHandler(err);
        this.isLoading = false;
      });
  }

  /**
   * If continue is true, keeps accounts added in previous step in cache
   * (If remember check is checked) and continues the funding flow
   * flush them otherwise and goes back in funding flow
   * Only local accounts should be registered on Payveris
   */
  continueFunding(continueAccountAggregation: boolean) {
    if (!continueAccountAggregation) {
      // Set accounts as deleted true and go back to choose financial institutions
      this.cancelOperation();

      return;
    }

    if (
      ((this.fundingState.isRunning || this.isMoveMoneyFlow) &&
        continueAccountAggregation &&
        !this.isSelected(this.selectedAggregatedAccId)) ||
      !this.institution.isLocal
    ) {
      return;
    }

    this.isLoading = true;

    // Try to add aggregated account to Payveris
    const selectedAccount = this.aggregatedAccounts.find(
      (x: AggregatedAccount) => x.id === this.selectedAggregatedAccId
    );

    const addExternalAccount: AddExternalAccount = {
      bankAuthRequest: {
        providerAccountId: this.institution.providerAccountId,
        requestId: this.institution.requestId,
        authParameter: this.institution,
      },
      account: selectedAccount,
    };

    this.accountAggregationService
      .addExternalAccount(addExternalAccount)
      .then(({ data }) => {
        if (
          data.externalAccountId !== 0 &&
          !isNaN(data.externalAccountId) &&
          data.isPendingVerification === false
        ) {
          this.addAccounts([data]);
          this.ngRedux.dispatch(
            FundingActions.setupFromAccountId(data.externalAccountId)
          );
          this.store.dispatch(
            setupFundingFromAccountId({ payload: data.externalAccountId })
          );
          // go to funding Axos Invest if is coming from Axos Invest
          if (sessionStorage.getItem('fundingAccounts')) {
            this.stateService.go('udb.managedportfolio');

            return;
          }

          if (
            this.flowService.isRunningFlow(
              this.flow,
              this.flowTypeEnum.ManagePortfoliosTransFun
            )
          ) {
            this.stateService.go('udb.axosinvest.transferfunds', {
              flow: this.flow,
            });
            return;
          }

          const toState = this.fundingState.isRunning
            ? 'udb.funding.external-transfer'
            : 'udb.transfers.transferFunds';
          this.stateService.go(toState, {
            isMoveMoneyFlow: this.isMoveMoneyFlow,
            id: data.externalAccountId,
          });
        } else {
          this.handleStatusAndMessages(data);
        }

        return;
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
      });
  }

  closeFastLink() {
    window.fastlink.close();
  }

  back() {
    this.closeFastLink();

    let path: string;
    let params = {};

    switch (this.flow) {
      case 'aggregation':
      case 'edit':
        path = 'udb.accounts.dashboard';
        break;

      case 'moveMoney':
        path = 'udb.transfers.transferFunds';
        break;

      case 'details':
        path = 'udb.accounts.external-details';
        params = {
          id: this.stateParams.accountId,
          container: this.stateParams.container,
          tab: 1,
        };
        break;

      case 'funding':
        path = 'udb.funding.external-transfer';
        break;

      default:
        path = 'udb.accounts.account-aggregation';
        params = {
          isAccountAggregationFlow: false,
          isPfm3Active: true,
        };
        break;
    }
    this.getAllSharedAccounts();
    this.stateService.go(path, params);
  }

  finish() {
    this.closeFastLink();

    let path: string;
    let params = {};

    switch (this.flow) {
      case 'aggregation':
      case 'edit':
      case 'details':
        path = 'udb.accounts.dashboard';
        break;

      case 'moveMoney':
        path = 'udb.transfers.transferFunds';
        break;

      case 'funding':
        path = 'udb.funding.external-transfer';
        break;

      default:
        path = 'udb.accounts.account-aggregation';
        params = {
          isAccountAggregationFlow: false,
          isPfm3Active: true,
        };
        break;
    }
    this.getAllSharedAccounts();
    this.stateService.go(path, params);
  }

  goToFI() {
    this.closeFastLink();
    this.stateService.go('udb.dashboard.account-aggregation', {
      flow: this.flow,
    });
  }

  goToManually() {
    this.closeFastLink();
    let path: string;
    let params = {};
    if (this.isPayverisProcessedError) {
      path = 'udb.accounts.add-external';
      params = {
        flow: this.flow,
        accounts: this.fastlinkResponseData,
      };
    }

    this.stateService.go(path, params);
  }

  cancelOperation() {
    const vm: AddBulkVm = {
      bankAuthRequest: {
        providerAccountId: this.institution.providerAccountId,
        requestId: this.institution.requestId,
        authParameter: this.institution,
      },
      accounts: [],
    };
    this.accountAggregationService
      .addSelectedAccounts(vm)
      .then(() => {
        this.stateService.go('udb.dashboard.account-aggregation', {
          flow: this.flow,
        });

        return;
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => (this.isLoading = false));
  }

  handleStatusAndMessages(data: ExternalAccount) {
    if (data.externalAccountId === 0 || data.externalAccountId === null) {
      this.aggregatedAccounts = this.aggregatedAccounts.map(
        (account: AggregatedAccount) =>
          ({
            ...account,
            isPendingVerification:
              account.id === this.selectedAggregatedAccId
                ? data.isPendingVerification
                : account.isPendingVerification,
            fundingStatus:
              account.id === this.selectedAggregatedAccId
                ? accountFundingStatus.IncompleteInformation
                : account.fundingStatus,
            ['isSelectedAccount']: false,
          } as ExternalAccount)
      );
      const additionalInfo = `<br><a class="link" href="/AddExternalAccount?flow=${this.flow}"> Manually add</a>`;
      this.errorMessage =
        'Additional information is needed for the account previously selected.' +
        additionalInfo;
    }

    if (data.isPendingVerification === true) {
      this.aggregatedAccounts = this.aggregatedAccounts.map(
        (account: AggregatedAccount) =>
          ({
            ...account,
            isPendingVerification:
              account.id === this.selectedAggregatedAccId
                ? data.isPendingVerification
                : account.isPendingVerification,
            fundingStatus:
              account.id === this.selectedAggregatedAccId
                ? accountFundingStatus.PendingVerification
                : account.fundingStatus,
            ['isSelectedAccount']: false,
          } as ExternalAccount)
      );
      const pendingAccount = this.aggregatedAccounts.find(
        (account: AggregatedAccount) =>
          account.id === this.selectedAggregatedAccId
      );
      const pendingIndex = this.aggregatedAccounts.findIndex(
        (account: AggregatedAccount) =>
          account.id === this.selectedAggregatedAccId
      );
      this.pendingVerificationAccounts.push(pendingAccount);
      this.aggregatedAccounts.splice(pendingIndex, 1);
      this.errorMessage =
        'The account you selected is pending verification and can take up to 24 hours to clear. Please select another account to continue.';
    }

    this.messageType = 'error';
    this.selectedAggregatedAccId = 0;
  }
  /**
   * Check valid accounts for funding
   */
  isEligibleForFunding(account: AggregatedAccount) {
    return (
      (account.category === AccountCategory.Dda ||
        account.category === AccountCategory.Sav) &&
      account.externalAccountId > 0
    );
  }
  getFundingStatus(account: AggregatedAccount): accountFundingStatus {
    if (
      !(
        account.category === AccountCategory.Dda ||
        account.category === AccountCategory.Sav
      )
    ) {
      return accountFundingStatus.NotEligibleForFunding;
    }
    if (this.isAggAccEligibleForFunding(account)) {
      return accountFundingStatus.EligibleForFounding;
    } else {
      return accountFundingStatus.IncompleteInformation;
    }
  }

  isAggAccEligibleForFunding(account: AggregatedAccount): boolean {
    return (
      (account.category === AccountCategory.Dda ||
        account.category === AccountCategory.Sav) &&
      !!account.routingNumber &&
      this.isValidAccountNumber(account.accountNumber)
    );
  }
  isValidAccountNumber(accountNumber: string) {
    return (
      !!accountNumber &&
      accountNumber.length >= 1 &&
      accountNumber.length <= 17 &&
      +accountNumber !== 0
    );
  }
  isValidateExternal() {
    return this.featureFlagService.isValidateExternalAccounts();
  }

  /** Changes the state to the add external accounts through payveris. */
  goToAddManually() {
    const nextState =
      this.fundingState.isRunning ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.Funding)
        ? 'udb.funding.add-external-account'
        : 'udb.accounts.add-external';
    this.stateService.go(nextState, { flow: this.flow });
  }

  /**
   * Returns the string formatted to be shown in the success page
   * @param account
   */
  formatAccountName(account: AggregatedAccount): string {
    let formattedAcc = '';
    if (account.nickname) {
      formattedAcc += account.nickname + ' - ';
    } else if (account.name) {
      formattedAcc += account.name + ' - ';
    } else {
      formattedAcc += account.accountType + ' - ';
    }

    if (account.accountNumber) {
      formattedAcc += account.accountNumber.substring(
        account.accountNumber.length - 4
      );
    } else if (account.accountMask) {
      formattedAcc += account.accountMask.substring(
        account.accountMask.length - 4
      );
    }

    return formattedAcc;
  }
  orderAccountsByType(accounts: AggregatedAccount[]): AggregatedAccount[] {
    const accountOrder = Object.values(this.categories);

    return accounts.sort(
      (a, b) =>
        accountOrder.indexOf(a.category) - accountOrder.indexOf(b.category)
    );
  }

  sortAccountsByFundingStatus(
    accounts: AggregatedAccount[]
  ): AggregatedAccount[] {
    const accountOrder = Object.values(this.fundingStatus);

    return accounts.sort(
      (a, b) =>
        accountOrder.indexOf(a.fundingStatus) -
        accountOrder.indexOf(b.fundingStatus)
    );
  }

  getBalance(account: AggregatedAccount): number {
    return this._balanceService.getBalance(account);
  }

  getRewardUnits(account: AggregatedAccount): string {
    return this._balanceService.getRewardUnits(account);
  }

  aggregationSuccessBack() {
    this.stateService.go('udb.accounts.dashboard');
  }

  selectAccount(selectedAccount: AggregatedAccount) {
    if (
      selectedAccount.fundingStatus == this.fundingStatus.EligibleForFounding
    ) {
      this.selectedAggregatedAccId = selectedAccount.id;
      this.aggregatedAccounts = this.aggregatedAccounts.map(
        (account: AggregatedAccount) =>
          ({
            ...account,
            ['isSelectedAccount']:
              account.id === this.selectedAggregatedAccId ? true : false,
          } as ExternalAccount)
      );
    }
  }

  private getAllSharedAccounts() {
    if (this.featureFlagService.IsRiaPilotAccountSharing()) {
      this.sharedAccountsService
        .getSharedAccountsPrompting()
        .subscribe(response => {
          if (response.data.accounts.length !== 0) {
            response.data.accounts = response.data.accounts.filter(
              x => x.accountType.toUpperCase() !== 'Internal'
            );
            const sharedAccountsStateTypeState: SharedAccountsStateType = {
              sharedAccounts: response.data,
            };
            this.store.dispatch(
              addSharedAccountsPrompting({
                payload: sharedAccountsStateTypeState,
              })
            );
            this.showSharedAccountsModalModal();
          }
        });
    }
  }

  private showSharedAccountsModalModal() {
    const modalSharedAccounts = this.matDialog.open(ShareAccountRiaComponent, {
      maxWidth: 1440,
      width: '1440px',
      height: '900px',
      maxHeight: 900,
      disableClose: true,
    });
    modalSharedAccounts.componentInstance.closeModal.subscribe(() => {
      modalSharedAccounts.close();
    });
  }

  private addAccounts(payverisAccounts: ExternalAccount[]) {
    if (payverisAccounts && payverisAccounts.length > 0) {
      this.cachedAccountsService.addExternalAccounts(payverisAccounts);
    }
  }

  /**
   * Validation account selected
   * @param accountId
   */
  private isSelected(accountId: number): boolean {
    if (accountId != 0) return true;

    this.popups?.showAlert(
      'Error',
      'Please, select an account in order to continue',
      'error'
    );

    return false;
  }

  /**
   * Remove excluded payveris and yodlee accounts from cache
   */
  private deleteExcludedAccounts(deletedAccounts: AggregatedSuccess) {
    deletedAccounts.deletedExternalAccountsIds.forEach(externalAccId =>
      this.cachedAccountsService.removeExternalAccount(externalAccId)
    );

    deletedAccounts.deletedAggregatedAccountsIds.forEach(aggregatedAccId =>
      this.cachedAccountsService.removeAggregatedAccount(aggregatedAccId)
    );
  }
}
