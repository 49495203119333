import * as angular from 'angular';
import { Inject } from '../../../decorators/decorators';
import { DropdownItem } from '@uikit/clickdropdown';
import { MyAlertsController } from '../my-alerts.controller';
import { AlertsUI } from '../typings/AlertsUI';
import { FeatureFlagService } from 'services/feature-flag.service';
import { DevicesService } from 'services/devices.service';
import { ModalService } from 'services/modal.service';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';

@Inject(
  '$rootScope',
  '$scope',
  '$filter',
  'alertsNotificationsService',
  'featureFlagService',
  'devicesService',
  'modalService',
  'userSubtypeHelper',
  'cachedAccountsService'
)
export class CreateAlertController {
  $mac: MyAlertsController;
  editAlert: Alerts;
  ddlAccounts: DropdownItem[];
  selectedItem: DropdownItem;
  errorMsg: string;

  /** Default boolean values. */
  hasOtherAccounts = false;
  savingAlert: boolean = false;
  isInvalid: boolean = false;
  deleteMsg: boolean = false;
  smsEnabled: boolean = false;
  updateSMS: boolean = false;
  isSmsCheckBoxDisabled: boolean = false;
  isPushCheckBoxDisabled: boolean = false;
  showPNs: boolean = false;
  hasDevices: boolean;
  private isAxosTradingActive: boolean = false;
  private isInvalidAccount: boolean = false;
  private isCancelingAlertChanges: boolean = false;
  private activeManualFocus = false;

  private cdAlertsEnabled = [
    'balance snapshot',
    'large withdrawal',
    'large deposit',
    'low balance',
    'high balance',
    'featureFlagService',
  ];

  readonly alertsObj = {
    statement: { title: 'Statements' },
    taxForms: { title: 'Tax Forms' },
    documents: { title: 'Documents' },
  };

  constructor(
    private root: ng.IRootScopeService,
    private scope: any,
    private filter: ng.IFilterService,
    private alertsService: IAlertsNotificationsService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly devicesService: DevicesService,
    private readonly modalService: ModalService,
    private userSubtypeHelper: UserSubtypeHelper,
    private cachedAccountsService: CachedAccountsService
  ) {
    scope.$on('enableSMSNotifications', (_event: any, enabled: boolean) => {
      if (this.updateSMS && enabled) {
        this.scope.alert.alert.sendConfirmationText = true;
        this.smsEnabled = true;
      }
      this.updateSMS = false;
    });

    scope.$on('errorDeleteAlert', (_event: any, alertId: string) => {
      if (this.scope.alert.alert.alertId == alertId) {
        this.errorMsg = 'Fail! Changes to your custom alert have not been saved, try again.';
        this.isInvalid = true;
      }
    });
  }

  /** Initializes the controller. */
  $onInit(): void {
    this.showPNs = this.featureFlagService.isPushNotificationsActive();

    this.devicesService.getAllDevices().then(result => {
      this.hasDevices = result.data.length > 0;
      if (angular.equals(this.scope.alert.alert.alertId, '')) {
        this.scope.alert.alert.pushNotification = result.data.length > 0;
      }
    });

    this.fillAccountsDropdown();

    /** Watch the alert for changes and create temporaly cached Alert. */
    this.scope.$watch(
      'alert.alert',
      (_newValue: Alerts, oldValue: Alerts) => {
        let alertHasChanged =
          (JSON.stringify(_newValue) !== JSON.stringify(oldValue) &&
            JSON.stringify(this.scope.alert.alert) !== JSON.stringify(oldValue) &&
            !this.editAlert) ||
          this.scope.alert.isNew;
        if (alertHasChanged) {
          this.editAlert = { ...oldValue };
          this.scope.alert.editMode = true;
          this.isCancelingAlertChanges = false;
        } else if (this.isCancelingAlertChanges) {
          this.editAlert = undefined;
          this.isCancelingAlertChanges = false;
        }
      },
      true
    );

    this.smsEnabled = this.scope.alert.alert.sendConfirmationText;
    this.isAxosTradingActive = this.featureFlagService.isAxosTradingActive();

    this.disableCheckBoxForAxosTradingUser(this.scope.alert);
    this.checkAccounts();
  }

  /** Save user Alert. */
  saveAlert(alertModified: AlertsUI) {
    alertModified.alert.alertId ? (this.$mac.oldAlert = true) : (this.$mac.oldAlert = false);

    this.displayErrorDiv(alertModified);

    if (!this.isInvalid && this.scope.alertBox.$valid && !this.isInvalidAccount) {
      this.savingAlert = true;
      this.$mac.savingAlert = true;

      /** Call alert service. */
      this.alertsService
        .saveAlert(alertModified.alert, alertModified.url)
        .then(
          (response: OlbResponse<any>) => {
            if (response.status === 'Success') {
              this.$mac.saveSuccess.push('Success! Changes to your custom alert have been saved.');

              alertModified.alert.alertId = response.data.data ? response.data.data : response.data;

              alertModified.editMode = false;

              alertModified.isNew = false;
              this.$mac.isNewAlert = false;

              this.$mac.checkAvailableAlerts(alertModified);
              this.editAlert = null;
            }
          },
          () => {
            this.errorMsg = 'Fail! Changes to your custom alert have not been saved, try again';
            this.isInvalid = true;
          }
        )
        .finally(() => {
          this.savingAlert = false;
          this.$mac.hideCustomizeBox();
          this.$mac.savingAlert = false;
          this.$mac.oldAlert = false;
        });

      //Enable Master Switch
      if (alertModified.alert.sendConfirmationEmail) {
        this.root.$broadcast('enableMasterSwitch', 'alert');
      }
    }
  }

  displayErrorDiv(a: AlertsUI): void {
    const { alert } = a;
    let accountValid: boolean = false;
    accountValid = !alert.accountNumber;

    let selectAccount: boolean = false;
    selectAccount = this.isInvalidAccount;

    switch (alert.alertType) {
      case 'Balance Snapshot':
        if (!alert.frequency && selectAccount) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your preferred Frequency and Account before saving your Alert.';
        } else if (!alert.frequency) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your preferred Frequency before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your preferred Account before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Check Cleared':
        if (!alert.threshold && selectAccount) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your Check number and Account before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your preferred Account before saving your Alert.';
        } else if (!alert.threshold) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your Check number before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Maturity Date':
      case 'Loan Payment Due Date':
        if (!alert.threshold && selectAccount) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your number of days and Account before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your preferred Account before saving your Alert.';
        } else if (!alert.threshold) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your number of days before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Large Withdrawal':
      case 'Low Balance':
      case 'Large Deposit':
      case 'High Balance':
        if (!alert.threshold && selectAccount) {
          this.isInvalid = true;
          this.errorMsg = 'Please select a valid Amount and Account before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your preferred Account before saving your Alert.';
        } else if (!alert.threshold) {
          this.isInvalid = true;
          this.errorMsg = 'Please select a valid Amount before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Loan Overdue':
        if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg = 'Please select your preferred Account before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      default:
        this.errorMsg = 'Unexpected error occurred';
        break;
    }
  }

  isInvalidFrequencyAlert(a: Alerts): boolean {
    return a.startDate ? false : true;
  }

  /** Show Delete message if Alert was already saved/created. */
  removeAlert(a: AlertsUI) {
    if (!a.isNew) {
      this.deleteMsg = true;
    }
  }

  /** Cancel edit changes on Alert, remove from array if not saved/created. */
  cancelAlert(index: number, a: AlertsUI) {
    if (!a.isNew) {
      // verify if local variable 'this.editAlert' has values, otherwise preserve current value on 'a' variable
      if (this.editAlert) a.alert = { ...this.editAlert }; // set cached alert value

      // set default values
      this.selectedItem = this.ddlAccounts.filter((acc: DropdownItem) => {
        return acc.id === `${a.alert.accountNumber}|${a.alert.accountType}`;
      })[0];
      a.editMode = false;
      this.scope.$broadcast('undoFrequencyChanges', a.alert);
      this.isInvalid = false;
      this.isCancelingAlertChanges = true;
      this.smsEnabled = a.alert.sendConfirmationText;
    } else {
      this.$mac.userAlerts.splice(index, 1);
      this.$mac.editingAlert = false;
      this.$mac.isNewAlert = false;
      if (a.title === this.alertsObj.taxForms.title) {
        this.$mac.availableAlerts.push(this.$mac.getTaxAlert());
      } else if (a.title === this.alertsObj.statement.title) {
        this.$mac.availableAlerts.push(this.$mac.getStatementAlert());
      } else if (a.title === this.alertsObj.documents.title) {
        this.$mac.availableAlerts.push(this.$mac.getDocumentsAlert());
      }
    }
  }

  /** Select Account Dropdown Item and update Alert account number. */
  onSelect(data: DropdownItem) {
    this.selectedItem = data;
    this.isInvalidAccount = this.selectedItem.id === null || this.selectedItem.id === undefined;

    let accountNumber = '';
    let accountType = '';
    if (!this.isInvalidAccount) {
      let account: string[] = this.selectedItem.id.toString().split('|');
      accountNumber = account[0];
      accountType = account[1];
    }

    this.scope.alert.editMode = this.scope.alert.alert.accountNumber !== accountNumber;

    if (!this.isInvalidAccount && this.scope.alert.editMode) {
      this.scope.alert.alert.accountNumber = accountNumber;
      this.scope.alert.alert.accountType = accountType;
      this.isInvalid = false;
    } else if (this.isInvalidAccount && this.scope.alert.editMode) {
      this.scope.alert.alert.accountNumber = '';
      this.scope.alert.alert.accountType = '';
    }
  }

  setUpSMS(enabled: boolean) {
    this.smsEnabled = false;
    this.updateSMS = true;
    this.scope.alert.editMode = true;
    if (enabled) {
      this.root.$broadcast('setUpSMSMasterSwitch', 'alert');
    } else {
      this.scope.alert.alert.sendConfirmationText = false;
    }
  }

  popupPushNotification(): void {
    if (this.hasDevices || !this.scope.alert.alert.pushNotification) return;

    this.modalService
      .show(
        { windowClass: 'modal-service-md' },
        {
          hasIcon: false,
          hasHeaderText: true,
          headerText: 'No Associated Mobile Devices',
          okText: 'Ok',
          hasCancelButton: true,
          bodyText: `<div>
                        <b>
                          Your account does not have any mobile devices configured to receive push notifications via the
                          ${this.root['brandProperties'].BrandDisplayName} mobile app.
                          In order to receive push notifications you will need to download the app and allow notifications.
                        </b>
                      </div>`,
        }
      )
      .catch(() => {
        this.scope.alert.alert.pushNotification = false;
      })
      .finally(() => {});
  }

  setUpEmail() {
    this.scope.alert.editMode = true;
  }

  private checkAccounts() {
    const externals = this.cachedAccountsService.allAccounts?.externalAccounts?.length > 0;
    const internals = this.cachedAccountsService.allAccounts?.internalAccounts?.length > 0;
    this.hasOtherAccounts = externals || internals;
  }

  showTradingDisclaimer(alert: AlertsUI): boolean {
    return (
      (alert.alert.alertType === 'Statement Notification Account' || alert.alert.alertType === 'Taxform') &&
      this.isAxosTradingActive &&
      this.hasOtherAccounts
    );
  }

  isFocusActive(): boolean {
    if (!this.activeManualFocus && this.scope.alert.isNew && this.scope.alert.editMode) this.activeManualFocus = true;

    return this.activeManualFocus;
  }

  private disableCheckBoxForAxosTradingUser(alert: AlertsUI): void {
    this.isSmsCheckBoxDisabled =
      (alert.alert.alertType === 'Statement Notification Account' || alert.alert.alertType === 'Taxform') &&
      this.userSubtypeHelper.isAxosTradingOnly() &&
      this.isAxosTradingActive;
    this.isPushCheckBoxDisabled = this.isSmsCheckBoxDisabled;
  }

  private fillAccountsDropdown() {
    this.$mac = this.scope.$parent.$mac;
    let accounts: OlbAccount[] = this.$mac.accounts;
    let alertObj: Alerts = this.scope.alert.alert;

    //Fill Account DropDown List
    this.ddlAccounts = accounts
      //Filter Dropdown by Account category type
      .filter((a: OlbAccount) => {
        //Quick fix to match the new categories (admin sections)
        const category = a.category === 2 ? 1 : a.category;

        return (
          category === this.scope.alert.category ||
          (category === 4 && this.cdAlertsEnabled.includes(alertObj.alertType.toLowerCase()))
        );
      })
      .map(
        (a: OlbAccount) =>
          new DropdownItem(
            a.nickname,
            `${a.accountNumber}|${a.accountType}`,
            `Avail Bal: ${this.filter('currency')(a.availableBalance ? a.availableBalance : 0)}`
          )
      );

    this.ddlAccounts.unshift(new DropdownItem('[Select Account]'));

    //Select Current Alert Account
    this.selectedItem = this.ddlAccounts.filter((a: DropdownItem) => {
      return a.id === `${alertObj.accountNumber}|${alertObj.accountType}`;
    })[0];

    // Set "Select Account" if is new Alert
    if (!this.selectedItem) {
      this.selectedItem = this.ddlAccounts[0];
    }
  }
}
