<h3 class="mt-5 mb-4">
  How can I send a wire?
</h3>

<ol>
  <li>
    From your Axos dashboard, choose “Move Money” and select “Wire Transfers”. From there, you can complete your wire
    transfer online.
  </li>
</ol>
