import { AbstractControl, Validators } from '@angular/forms';

import {
  hasLowercaseRegex,
  hasNumberNotZeroRegex,
  hasNumberRegex,
  hasUppercaseRegex,
  validSymbolsRegex,
} from '@app/config/regexes';

/**
 * Creates an array of Validators for a password field.
 *
 * A valid password has the following defaults:
 * - It is required.
 * - It has at least 8 characters.
 * - It has at least an Uppercase letter.
 * - It has at least one lowercase letter.
 * - It has at least one number.
 * - It has at least one symbol: #?!@$%^&*+-
 *
 * @param maxLength Optional maximum length to add to the default validations.
 *
 * @returns An array of Validators for a password field.
 */
export function createPasswordValidators(maxLength?: number) {
  const validators = [
    Validators.required,
    Validators.minLength(8),
    hasLowercaseValidator,
    hasUppercaseValidator,
    hasNumberValidator,
    hasSymbolValidator,
  ];

  if (maxLength) {
    validators.push(Validators.maxLength(25));
  }

  return validators;
}

/**
 * Validates if the given form control has at least one lowercase letter.
 *
 * @param control Form control to validate.
 *
 * @returns An object with the key "lowercase" and control's value if there's an error. Null otherwise.
 */
export function hasLowercaseValidator(control: AbstractControl): { [key: string]: any } | null {
  const isValid = hasLowercaseRegex.test(control.value);

  return !isValid ? { lowercase: { value: control.value } } : null;
}

/**
 * Validates if the given form control has at least one uppercase letter.
 *
 * @param control Form control to validate.
 *
 * @returns An object with the key "uppercase" and control's value if there's an error. Null otherwise.
 */
export function hasUppercaseValidator(control: AbstractControl): { [key: string]: any } | null {
  const isValid = hasUppercaseRegex.test(control.value);

  return !isValid ? { uppercase: { value: control.value } } : null;
}

/**
 * Validates if the given form control has at least one number.
 *
 * @param control Form control to validate.
 *
 * @returns An object with the key "number" and control's value if there's an error. Null otherwise.
 */
export function hasNumberValidator(control: AbstractControl): { [key: string]: any } | null {
  const isValid = hasNumberRegex.test(control.value);

  return !isValid ? { number: { value: control.value } } : null;
}

/**
 * Validates if the given form control has at least one of the following symbols: #?!@$%^&*+-
 *
 * @param control Form control to validate.
 *
 * @returns An object with the key "symbol" and control's value if there's an error. Null otherwise.
 */
export function hasSymbolValidator(control: AbstractControl): { [key: string]: any } | null {
  const isValid = validSymbolsRegex.test(control.value);

  return !isValid ? { symbol: { value: control.value } } : null;
}

/**
 * Validates if the given form control has a number that is not zero
 *
 * @param control Form control to validate.
 *
 * @returns An object with the key "symbol" and control's value if there's an error. Null otherwise.
 */
export function hasNumberNotZeroValidator(control: AbstractControl): { [key: string]: any } | null {
  const isValid = hasNumberNotZeroRegex.test(control.value);

  return !isValid ? { number: { value: control.value } } : null;
}
