import { IComponentOptions } from 'angular';
import { TxOptionsController } from './tx-options.controller';

export const TxOptionsComponent: IComponentOptions = {
  controller: TxOptionsController,
  controllerAs: '$to',
  templateUrl: 'accounts/transactions/tx-table/tx-options/tx-options.tpl.html',
  bindings: {
    options: '<',
    isDisabled: '<',
    isExpanded: '<',
    onOptionSelected: '&',
    onCollapse: '&',
    isDetailsExpanded: '<',
  },
};
