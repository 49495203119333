import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-generate-quote-error-modal',
  templateUrl: './generate-quote-error-modal.component.html',
  styleUrls: ['./generate-quote-error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateQuoteErrorModalComponent {
  @Input() errorMessage = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GenerateQuoteErrorModalComponent>
  ) {}

  ngOnInit(): void {
    this.errorMessage = this.data?.params;
  }

  cancelFunction() {
    this.dialogRef.close();
  }
}
