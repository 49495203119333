import { ColorPaletteHelper } from '../../../services/color-palette.helper';
import { Inject } from '../../../decorators/decorators';

@Inject('$scope', 'env')
export class ColorPickerController {
  accountId: number;
  color: string;
  colors: string[];
  onOpenPicker: Function;
  onColorChange: Function;
  isShown: boolean = false;
  top: number;

  private _listeners: Function[] = [];

  constructor(private _scope: ng.IScope, private readonly env: OlbSettings) {
    this.colors = ColorPaletteHelper.getColorPaletteByBrand(this.env.brand);
  }

  /** Initializes the controller. */
  $onInit(): void {
    this._initializeEvents();
  }

  /** Cleans up the controller. */
  $onDestroy(): void {
    this._listeners.forEach(l => l());
  }

  /**
   * Toggles the color picker.
   * @param e Click event.
   */
  togglePicker(e: JQueryEventObject): void {
    if (!this.isShown) {
      this.onOpenPicker();
      var el = e.target as HTMLElement;
      this.top = $(el).position().top + 18;
      this._scope.$emit('$adTileStopScroll');
    } else {
      this._scope.$emit('$adTileEnableScroll');
    }
    this.isShown = !this.isShown;
  }

  /**
   * Emits the color to the parent using the component.
   * @param color Color in HEX string.
   */
  emitColor(color: string): void {
    this.color = color;
    this.isShown = false;
    this.onColorChange({ color, accountId: this.accountId });
  }

  /**
   * Initializes all needed events.
   */
  private _initializeEvents(): void {
    this._listeners.push(
      this._scope.$on('$closeOtherPickers', () => {
        if (this.isShown) {
          this.isShown = false;
        }
      })
    );
  }
}
