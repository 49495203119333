import { InjectionToken, Provider } from '@angular/core';
import { MessagesSharedService } from '@legacy/messages/messages.shared.service';
import { IMessagesService } from '@legacy/services/typings/IMessagesService';
import { IFilterService } from 'angular';

class AxosCustomLegacyInjectionToken<T = any> extends InjectionToken<T> {
  readonly legacyInjectionId: string;
  constructor(desc: string) {
    super(desc);
    this.legacyInjectionId = desc;
  }
}
export const LEGACY_SERVICE_HELPER_TOKEN = new AxosCustomLegacyInjectionToken<IServiceHelper>('serviceHelper');
export const LEGACY_MESSAGES_SERVICE_TOKEN = new AxosCustomLegacyInjectionToken<IMessagesService>('messagesService');
export const LEGACY_MESSAGES_SHARED_SERVICES_TOKEN = new AxosCustomLegacyInjectionToken<MessagesSharedService>(
  'messagesSharedService'
);
export const LEGACY_SCOPE_TOKEN = new AxosCustomLegacyInjectionToken<ng.IScope>('$scope');

export const LEGACY_STATE_TOKEN = new AxosCustomLegacyInjectionToken<ng.ui.IStateService>('$state');
export const LEGACY_FILTER_TOKEN = new AxosCustomLegacyInjectionToken<IFilterService>('$filter');
export const LEGACY_ENV_TOKEN = new AxosCustomLegacyInjectionToken<OlbSettings>('env');

type LegacyInjector = {
  get: <T = any>(id: string) => T;
};

export const LEGACY_MESSAGES_PROVIDERS: Provider[] = [
  {
    provide: LEGACY_SERVICE_HELPER_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) =>
      legacyInjector.get<IServiceHelper>(LEGACY_SERVICE_HELPER_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_MESSAGES_SERVICE_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) =>
      legacyInjector.get<IMessagesService>(LEGACY_MESSAGES_SERVICE_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_MESSAGES_SHARED_SERVICES_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) =>
      legacyInjector.get<MessagesSharedService>(LEGACY_MESSAGES_SHARED_SERVICES_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_SCOPE_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) => legacyInjector.get<ng.IScope>(LEGACY_SCOPE_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_STATE_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) =>
      legacyInjector.get<ng.ui.IStateService>(LEGACY_STATE_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_FILTER_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) =>
      legacyInjector.get<IFilterService>(LEGACY_FILTER_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_ENV_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) => legacyInjector.get<OlbSettings>(LEGACY_ENV_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_ENV_TOKEN,
    useFactory: (legacyInjector: LegacyInjector) => legacyInjector.get<OlbSettings>(LEGACY_ENV_TOKEN.legacyInjectionId),
    deps: ['$injector'],
  },
];
