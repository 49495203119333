import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { STATE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { ModalService } from '@legacy/services/modal.service';
import { PaymentsService } from '@legacy/services/payments.service';
import { IEnrollmentService } from '@legacy/services/typings/IEnrollmentService';
import { IStateService } from 'angular-ui-router';
import { Inject } from '@angular/core';
import {
  BILLPAYROUTERHELPER,
  ENROLLMENTSERVICE,
  MODALSERVICE,
  PAYMENTSSERVICE,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { Biller } from '@legacy/bill-pay/typings/Biller';
import { PartialRecipient } from '@legacy/bill-pay/typings/PartialRecipient';
import { Recipient } from '@legacy/bill-pay/typings/Recipient';

@Component({
  selector: 'app-add-check-recipient',
  templateUrl: './add-check-recipient.component.html',
  styleUrls: ['./add-check-recipient.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class AddCheckRecipientComponent implements OnInit {
  biller: Biller;
  payee: PartialRecipient;
  states: any;
  isBusy: boolean;

  emptyRecipientMsg: string = "Please enter Recipient's Name";
  emptyStreetAddresMsg: string = 'Please enter Street Address';
  zipCodeErrormsg: string = 'Please enter 5 digit Zip Code';
  stateErrorMsj: string = 'Please enter State';
  cityErrorMsj: string = 'Please enter City';

  private readonly popups: IPopups;

  constructor(
    @Inject(MODALSERVICE) private modalService: ModalService,
    @Inject(STATE) private readonly state: IStateService,
    @Inject(ENROLLMENTSERVICE) private readonly enrollmentService: IEnrollmentService,
    @Inject(PAYMENTSSERVICE) private readonly paymentsService: PaymentsService,
    @Inject(BILLPAYROUTERHELPER) private readonly routerHelper: BillPayRouterHelper
  ) {}
  ngOnInit(): void {
    this.loadStates();
    this.biller = this.routerHelper.getBiller();
    if (!this.biller) {
      this.payee = {};
      this.payee.payeeName = this.routerHelper.getCriteria();
      if (!this.payee.payeeName) {
        this.state.go('udb.billPay.searchRecipient');
      }
      this.biller = { name: this.payee.payeeName };
    } else {
      this.payee = this.routerHelper.getRecipient();
      this.payee.payeeName = this.payee.billerName;
    }

    this.GetCityAndStateByZip();

    this.payee.isElectronic = false;
    this.payee.country = 'US';
  }

  GetCityAndStateByZip() {
    if (this.payee.zipCode && this.payee.zipCode.length === 5) {
      this.enrollmentService
        .getCityAndState(this.payee.zipCode)

        .then(res => {
          let info = res.data as CityAndStateInfo;
          this.payee.city = info.city;
          this.payee.stateCode = info.stateCode;
        });
    }
  }
  private loadStates(): void {
    this.states = {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FM: 'Federated States Of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
    };
  }
  AddRecipient(isValidForm: boolean): void {
    if (!isValidForm) return;
    this.isBusy = true;
    this.payee.zipCode = this.payee.zipCode;

    this.paymentsService
      .saveCheckPayee(this.payee)
      .then(res => {
        this.payee.payeeId = res.data.payee.payeeId;
        let recipientDetails: Recipient = {};
        recipientDetails = Object.assign(recipientDetails, res.data.payee);
        recipientDetails.status = res.data.payee.status === 0 ? 0 : 1;
        this.routerHelper.setRecipientDetails(recipientDetails);
        this.routerHelper.setRecipient(this.payee);
        this.routerHelper.setBiller(this.biller);
        this.state.go('udb.billPay.checkConfirmation');
      })
      .catch(response => {
        this.popups.showAlert('Adding a payee error', response.data.message, 'error');
      })
      .finally(() => {
        this.isBusy = false;
      });
  }
  CancelRecipient(): void {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          hasCancelButton: true,
          cancelText: 'No',
          bodyText: `<h3>Are you sure you want to cancel this setup?</h3>`,
          hasRedirectLink: true,
        }
      )
      .then(() => {
        this.state.go('udb.billPay.pay');
      });
  }
}
