import { IRootScopeService } from 'angular';

export class RewardsCheckingHelper {
  isRewardsCheckingAccount(root: IRootScopeService, accountProductCode: string): boolean {
    const rewardsCheckingProductCodes: string = root['appSettings'].find(
      (ap: any) => ap.name === 'RewardsCheckingProductCodes'
    ).value;

    if (rewardsCheckingProductCodes && rewardsCheckingProductCodes.includes(accountProductCode)) {
      return true;
    }

    return false;
  }

  getMaximumInterestRate(root: IRootScopeService): number {
    const interestRate: number = Number.parseFloat(
      root['appSettings'].find((ap: any) => ap.name === 'RewardsCheckingMaximumInterestRate').value
    );

    if (interestRate) {
      return interestRate;
    }

    return 0;
  }
}
