import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';
import { DropdownItem } from '@uikit/clickdropdown';
import { Inject } from 'decorators/decorators';

import { MilestoneSummary, ScheduledTransfer } from '@app/axos-invest/models';
import { getGoalSummary, getScheduledTransfers } from '@app/axos-invest/store/selectors';
import { AxosInvestTransferService } from '@app/axos-invest/services';
import { TransactionType } from '@app/axos-invest/enums';

@Inject('ngrxStore', 'axosInvestTransferService')
export class AxosInvestAccountOverviewController {
  milestonesDetail: MilestoneSummary;
  accountId: number;
  dropdownItems: DropdownItem[] = [];
  selectedItem: DropdownItem;
  formulaSummary: string;
  subSink = new SubSink();

  constructor(private store: Store, private transferService: AxosInvestTransferService) {}

  $onInit() {
    this.getMilestones();
  }

  $onDestroy() {
    this.subSink.unsubscribe();
  }

  private getMilestones() {
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(milestones => {
      if (milestones.milestones.length > 0) {
        this.milestonesDetail = JSON.parse(JSON.stringify(milestones));
        if (!this.milestonesDetail.isClosed) this.setFormulaSummary();
        this.getRecurringDeposits();
      }
    });
  }

  private setFormulaSummary(): void {
    const uniqueFormulas = [];
    const activeMilestones = this.milestonesDetail.milestones.filter(m => !m.isClosed);
    activeMilestones?.forEach(milestone => {
      milestone.formulas?.forEach(formula => {
        if (!uniqueFormulas.includes(formula.title)) {
          uniqueFormulas.push(formula.title);
        }
      });
    });
    this.formulaSummary = uniqueFormulas.join('|');
  }

  private getRecurringDeposits() {
    this.subSink.sink = this.store.select(getScheduledTransfers).subscribe(transfers => {
      if (transfers) {
        transfers = JSON.parse(JSON.stringify(transfers));
        this.setTransfers(transfers);
      } else {
        this.transferService.getScheduledTransfers().subscribe(response => {
          this.setTransfers(response.data);
        });
      }
    });
  }

  private setTransfers(transfers: ScheduledTransfer[]) {
    const activeMilestones = this.milestonesDetail.milestones.filter(m => !m.isClosed);
    activeMilestones?.forEach(milestone => {
      const recurringDeposits = transfers.filter(
        x => x.milestoneId === milestone.id && x.transactionType === TransactionType.Deposit
      );
      if (recurringDeposits.length > 1) {
        recurringDeposits.forEach(recurringDeposit => {
          const account = milestone.accounts.find(a => a.accountNumber === recurringDeposit.accountNumber);
          if (account) {
            recurringDeposit.accountName = account.name;
          }
        });
      }
      this.milestonesDetail.milestones.find(x => x.id === milestone.id).recurringDeposits = recurringDeposits;
    });
  }
}
