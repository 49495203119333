import { BeneficiaryRelationshipType } from '@app/shared/enums';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isValid, parseISO } from 'date-fns';
import { isYoungerThan } from '@app/utils';
import { Minor } from '@app/minors/models';

@Component({
  selector: 'app-minor-information',
  templateUrl: './minor-information.component.html',
  styleUrls: ['./minor-information.component.scss'],
})
export class MinorInformationComponent implements OnInit {
  @Input() minor: Minor;
  @Input() parentForm: FormGroup;
  @Input() isSubmitted: boolean;
  ssn: string;
  relationshipType: any = [];
  isDobValid: boolean;
  minorRelationshipSelect: string;
  otherRelationship: string;

  ngOnInit() {
    this.buildRelationshipOptions();
  }

  get firstNameControl(): FormControl {
    return this.parentForm.get('firstName') as FormControl;
  }

  get lastNameControl(): FormControl {
    return this.parentForm.get('lastName') as FormControl;
  }

  get socialSecurityNumberControl(): FormControl {
    return this.parentForm.get('socialSecureNumber') as FormControl;
  }

  get dateOfBirthControl(): FormControl {
    return this.parentForm.get('dateOfBirth') as FormControl;
  }

  get relationShipControl(): FormControl {
    return this.parentForm.get('relationShip') as FormControl;
  }
  get otherRelationShipControl(): FormControl {
    return this.parentForm.get('otherRelationShip') as FormControl;
  }

  getSsn(ssn: string): void {
    this.minor.ssn = ssn ?? '';
    this.socialSecurityNumberControl?.setValue(ssn);
  }

  isDateOfBirthValid(): void {
    const dateOfBirth = this.dateOfBirthControl.value;
    console.log('dateOfBirth', dateOfBirth);

    if (dateOfBirth) {
      const month = dateOfBirth.slice(0, 2);
      const day = dateOfBirth.slice(2, 4);
      const year = dateOfBirth.slice(4, dateOfBirth.length);
      const dob = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));

      const dobISOFormatted = `${year}-${month}-${day}`;

      // if value is a valid MM/DD/YYYY date, check for minor age (< 18)
      if (isValid(parseISO(dobISOFormatted)) && isYoungerThan(18, dob)) {
        this.isDobValid = true;
        this.dateOfBirthControl.setValidators(Validators.required);
        this.minor.dateOfBirth = `${month}/${day}/${year}` ?? '';

        return;
      }
    }

    this.isDobValid = false;
    this.dateOfBirthControl.setValidators(null);
  }

  onRelationshipChange(value: string): void {
    this.minor.relationship = value;
  }

  private buildRelationshipOptions(): void {
    Object.keys(BeneficiaryRelationshipType).forEach(item => {
      const value = BeneficiaryRelationshipType[item];
      if (BeneficiaryRelationshipType.hasOwnProperty(value) && isNaN(Number(BeneficiaryRelationshipType[item]))) {
        this.relationshipType.push({ key: item, value });
      }
    });
  }
}
