import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { TransferType } from '@app/axos-invest/enums/transfer-type.enum';
import { ScheduledTransfer } from '@app/axos-invest/models';
import { STATE } from '@core/tokens';
import { FilesIcons } from '@shared/enums';

@Component({
  selector: 'app-recurring-deposit',
  templateUrl: './recurring-deposit.component.html',
  styleUrls: ['./recurring-deposit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurringDepositComponent {
  @Input() recurringDesposits: ScheduledTransfer[];
  @Input() isRetirementMilestone: boolean;
  @Input() referrerTabId: number;

  icons = {
    edit: FilesIcons.Pencil,
  };

  constructor(@Inject(STATE) private state: ng.ui.IStateService) {}

  editRecurringDeposit(transferSeriesId: string) {
    this.state.go('udb.axosinvest.transferfunds', {
      transferSeriesId,
      transferType: TransferType.RecurrentDeposit,
      referrerTabId: this.referrerTabId,
    });
  }
}
