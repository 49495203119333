import { Inject } from '../../../decorators/decorators';

@Inject()
export class TooltipController {
  public message: string;
  public symbol: string;
  public isVisible: boolean = false;
  constructor() {}

  public $onInit(): void {}

  public mouseOverHandler(): void {
    this.isVisible = true;
  }
  public mouseLeaveHandler(): void {
    this.isVisible = false;
  }
  public clickHandler(): void {
    this.isVisible = !this.isVisible;
  }
}
