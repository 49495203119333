let priceDirective = (): ng.IDirective => {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      ngModel: '=',
    },
    link: (
      scope: ng.IScope,
      el: ng.IRootElementService,
      attrs: ng.IAttributes,
      ngModel: ng.INgModelController
    ) => {
      if (attrs.price === 'false') return;
      const allowBlankValue = el.attr('allow-blank') || false;
      let defaultValue = allowBlankValue ? '' : '$0.00';
      const emptyValue = '$';

      const allowNegativeValues = el.attr('allow-negative') || false;
      const isOnlyDecimal = el.attr('only-decimal') || false;
      // Includes the '$ . ,' symbols into the count. '-' is excluded.
      const maxlength = el.attr('maxlength') || 11;

      const minValue = el.attr('minValue') || 0;

      const signRegex = allowNegativeValues ? '(?:-)?' : '';
      // The max length is reduced by 5 because of the counting of the '$ . ,' symbols and the 2 decimal numbers.
      const validFormatRegex = new RegExp(
        `^${signRegex}[0-9]{0,${+maxlength - 5}}(?:\\.[0-9]{0,2})?$`
      );
      const validCharactersRegex = allowNegativeValues ? /[^-0-9.]/g : /[^0-9.]/g;
      const milesRegex = /(\d)(?=(\d\d\d)+(?!\d))/g;

      attrs.$set('ngTrim', 'false');
      if (allowNegativeValues) attrs.$set('maxlength', +maxlength + 1);
      else attrs.$set('maxlength', +maxlength);

      if (minValue > 0) defaultValue = `${emptyValue}${minValue}`;

      let getSign = (val: string) => {
        if (allowNegativeValues && val.length > 0 && val[0] === '-') return '-';
        return '';
      };

      let getFormatted = (val: string) => {
        val = String(val).replace(validCharactersRegex, '') || '';
        let sign = getSign(val);
        let valueArray = val.replace(sign, '').split('.');

        if (isOnlyDecimal) {
          valueArray[1] = valueArray.length > 1 ? valueArray[1] : valueArray[0];
          valueArray[0] = '0';
        }

        let result =
          valueArray.length > 1
            ? `${emptyValue}${valueArray[0].replace(milesRegex, '$1,')}.${valueArray[1]}`
            : `${emptyValue}${valueArray[0].replace(milesRegex, '$1,')}`;
        return sign + result;
      };

      let formatAndUpdateView = (val: string, pos?: number) => {
        let result = getFormatted(val);
        let previous = ngModel.$viewValue || '';
        //Check if the new formatted value added a ',' for miles (compared with the previous one);
        //if so, then we move the cursor 1 position to the right
        if (
          pos <= 1 ||
          (result.indexOf(',') != -1 && (previous.indexOf(',') == -1 || previous.indexOf(',') == 4))
        ) {
          pos += 1;
        }
        //If the new formatted value removed a ',', then we move the cursor 1 position to the left
        if (result.indexOf(',') == -1 && previous.indexOf(',') != -1) {
          pos -= 1;
        }
        scope.$applyAsync(() => {
          ngModel.$setViewValue(result || '');
          ngModel.$render();
          if (pos) {
            (<HTMLInputElement>el.get(0)).focus();
            (<HTMLInputElement>el.get(0)).setSelectionRange(pos, pos);
          }
        });
        return result;
      };

      let isValidInput = (val: string) => {
        return validFormatRegex.test(val);
      };

      ngModel.$formatters.unshift(val => {
        return val == 0 ? defaultValue : formatAndUpdateView(Number(val).toFixed(2));
      });

      ngModel.$parsers.unshift(val => {
        let cursorInit = (<HTMLInputElement>el.get(0)).selectionStart;
        if (val === '' || val === emptyValue || (val === `${emptyValue}0` && !isOnlyDecimal)) {
          formatAndUpdateView(emptyValue, 1);
          return 0;
        }

        let cleaned = String(val).replace(validCharactersRegex, '');
        if (!isValidInput(cleaned)) {
          formatAndUpdateView(ngModel.$modelValue, cursorInit - 1);
          return ngModel.$modelValue;
        }

        let previous = getFormatted(ngModel.$modelValue);
        let current = getFormatted(cleaned);
        if (previous === current) {
          formatAndUpdateView(cleaned);
          return cleaned;
        }
        formatAndUpdateView(cleaned, cursorInit);
        return cleaned;
      });

      el.bind('focus, click', function () {
        const modelValue = Number(String(ngModel.$modelValue).replace(validCharactersRegex, ''));
        if (
          ngModel.$modelValue === 0 ||
          el.val() === emptyValue ||
          modelValue === 0 ||
          isNaN(modelValue)
        )
          formatAndUpdateView(emptyValue, 1);
      });

      el.bind('blur', function () {
        const modelValue = Number(String(ngModel.$modelValue).replace(validCharactersRegex, ''));
        if (
          (el.val() === emptyValue ||
            modelValue === 0 ||
            modelValue < minValue ||
            isNaN(modelValue)) &&
          !allowBlankValue
        ) {
          el.val(defaultValue);
          scope['ngModel'] = minValue;
        }
        if (el.val().indexOf('.') === el.val().length - 1) el.val(el.val().replace('.', ''));
      });

      scope.$applyAsync(() => {
        if (!scope['ngModel'] && !allowBlankValue) {
          scope['ngModel'] = 0;
        }
      });
    },
  };
};

export { priceDirective };
