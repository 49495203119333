<div class="aggregation-success">
  <div class="header-section-title aggregation-success__header text-center frow">
    <div>
      <h2 class="fcol-cs-12 aggregation-success__header-text">
        Add Account
      </h2>
    </div>
  </div>
  <div>
    <div class="frow center">
      <div class="fcol-cs-10">
        <app-alert-notification *ngIf="unableToConnectAccounts" alertType="error">
          There was a problem setting up your linked accounts.
        </app-alert-notification>
        <div class="frow center">
          <h3 class="fcol-cs-12 text-center aggregation-selection__title">
            Select the account(s) you'd like to add so that you can keep track of your transactions all in one place.
          </h3>
        </div>
        <div class="frow center aggregation-success__accounts">
          <ul class="aggregation-success__accounts-list">
            <li
              *ngFor="let account of accounts | orderBy: ['isPendingVerification', '-externalAccountId']"
              class="aggregation-success__accounts-list-item"
            >
              <input type="checkbox" id="{{ account.id }}" [(ngModel)]="accountsCheckbox[account.id]" />
              <label for="{{ account.id }}" class="aggregation-success__accounts-list-item-name">
                {{ formatAccountName(account) }}
              </label>
              <span
                *ngIf="account.container != containerType.Reward"
                class="aggregation-success__accounts-list-item-balance"
              >
                Balance: {{ getBalance(account) | currency }}
              </span>
              <div
                *ngIf="account.container == containerType.Reward"
                class="aggregation-success__accounts-list-item-balance"
              >
                <span *ngIf="getRewardUnits(account) == rewardUnits.Dollars">
                  Reward {{ getRewardUnits(account) }} : {{ getBalance(account) | currency }}
                </span>
                <span *ngIf="getRewardUnits(account) != rewardUnits.Dollars">
                  Reward {{ getRewardUnits(account) }} : {{ getBalance(account) | number }}
                </span>
              </div>
            </li>
          </ul>
          <p class="fcol-cs-12 aggregation-selection__accounts-disclaimer">
            Not all of your accounts with this financial institution may be displayed.
          </p>
          <p class="fcol-cs-12 aggregation-selection__accounts-disclaimer">
            Account balances are estimates only. They are based on prior business day information and may not reflect
            current-day activity and pending transactions.
          </p>

          <div *ngIf="unableToConnectAccounts" class="unable-connect frow text-center center">
            <h3 class="fcol-cs-12 unable-connect__title">
              We were unable to connect any of your accounts.
            </h3>

            <div>
              <h3 class="fcol-cs-12 unable-connect__subtitle">
                Please go back.
              </h3>
            </div>
          </div>
        </div>

        <app-dot-loader [showWhen]="isLoading">Loading</app-dot-loader>

        <div class="frow center aggregation-success__nav">
          <button
            type="button"
            class="uk-btn lg outline primary aggregation-success__button"
            [disabled]="isLoading"
            (click)="cancel()"
          >
            Back
          </button>
          <button
            type="button"
            class="uk-btn lg solid secondary aggregation-success__button"
            [disabled]="isLoading"
            *ngIf="!unableToConnectAccounts"
            (click)="continue()"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
