<app-dot-loader *ngIf="isLoading"></app-dot-loader>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="!availableCashTileState?.error; else failedStateContent">
    <uk2-suggested-action
      *ngIf="!isLoading"
      [headerSvgIconName]="Uk2Tier2ColoredWithAccentEnum.bagHand"
      [panelTitle]="'Cash'"
      [tooltipConfig]="tooltipConfig"
      [panelSubtitle]="availableCashTileState.availableCash || 0 | currency"
      [buttonText]="displayButtonText"
      (buttonClickEvent)="goToTransferFunds()"
      [hideButton]="hideMoveMoneyButton"
      [isExpandable]="false"
      [isExpanded]="false"
    ></uk2-suggested-action>
  </ng-container>

  <ng-template #failedStateContent>
    <uk2-text-tile
      [uk2TextTileTitle]="'Cash Temporarily Unavailable'"
      [uk2TextTileIsStandaloneTile]="false"
      [uk2TextTileDisplayBackground]="false"
    >
      We're sorry, but we're currently unable to update you with the latest information on your portfolio’s available
      cash. Please check back later. Thank you for your patience.
    </uk2-text-tile>
  </ng-template>
</ng-container>
