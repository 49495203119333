import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { maskPhone } from '@app/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sms-modals',
  templateUrl: './sms-modals.component.html',
  styleUrls: ['./sms-modals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsModalsComponent implements OnInit, OnChanges {
  @ViewChild('smsAlerts') smsAlerts: TemplateRef<any>;
  @ViewChild('accessCodeModal') accessCodeModal: TemplateRef<any>;
  @ViewChild('successModal') successModal: TemplateRef<any>;
  @Input() phonenumber: string = '';
  @Input() smstermsandconditionsbrand: string = '';
  @Input() brandname: string = '';
  @Input() userphonenumber: string = '';

  @Input() otpvalidationmessage: string = '';

  @Input() smsobject = {
    title: '<b>Mobile Number</b> to receive text messages',
    successMsg: 'You have now opted in to receive text messages for your selected alerts.',
  };
  @Input() multifactorserviceevent: boolean = false;
  @Input() sendingcode: boolean = false;
  @Input() gettingcode: boolean = false;
  @Input() errorcodeevent: boolean = false;
  @Output() openaccesscodemodal = new EventEmitter();
  @Output() accesscodemodal: EventEmitter<NgForm> = new EventEmitter();
  @Output() resendcodeevent = new EventEmitter();
  @Output() closemodal = new EventEmitter();

  userInfo: UserBasicInfo = {} as UserBasicInfo;
  phoneError = false;
  gettingCode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  accessCode: number;
  errorCode: boolean = false;
  sendingCode$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private dialog: MatDialog) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.multifactorserviceevent) {
      this.gettingCode$.next(false);
      this.dialog.closeAll();
      this.dialog.open(this.accessCodeModal);
    }

    if (changes.gettingcode) {
      if (!this.gettingcode) {
        this.sendingCode$.next(false);
      }
    }

    if (changes.sendingcode) {
      if (this.sendingcode) {
        this.sendingCode$.next(false);
        this.dialog.closeAll();
        this.dialog.open(this.successModal);
      }
    }

    if (changes.errorcodeevent) {
      if (this.errorcodeevent) {
        this.errorCode = this.errorcodeevent;
        this.sendingCode$.next(false);
      }
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.dialog.open(this.smsAlerts);
    }, 1);
  }

  openModal() {
    this.dialog.closeAll();
    this.dialog.open(this.smsAlerts);
  }

  closeModal() {
    this.dialog.closeAll();
    this.closemodal.emit();
  }

  openAccessCodeModal() {
    this.gettingCode$.next(true);
    this.openaccesscodemodal.emit();
  }

  cancelSMSChanges() {
    this.dialog.closeAll();
    this.closemodal.emit();
  }

  cancelSMSOTP() {
    this.dialog.closeAll();
    this.closemodal.emit();
  }
  submitAccessCode(form: NgForm) {
    this.errorCode = false;
    this.errorcodeevent = false;
    if (form.valid) {
      this.sendingCode$.next(true);
      this.accesscodemodal.emit(form);
    }
  }

  maskPhone(phone: string) {
    return maskPhone(phone?.replace('-', ''));
  }

  allowOnlyNumbers($event: any): void {
    const isModifierkeyPressed = $event.metaKey || $event.ctrlKey || $event.shiftKey;
    const isCursorMoveOrDeleteAction = [46, 8, 37, 38, 39, 40].indexOf($event.keyCode) !== -1;
    const isNumKeyPressed =
      ($event.keyCode >= 48 && $event.keyCode <= 58) || ($event.keyCode >= 96 && $event.keyCode <= 105);
    const vKey = 86;
    const cKey = 67;
    const aKey = 65;
    const isEnterPressed = $event.keyCode === 13 ? true : false;
    const isTabPressed = $event.keyCode === 9 ? true : false;
    switch (true) {
      case isCursorMoveOrDeleteAction:
      case isModifierkeyPressed === false && isNumKeyPressed:
      case ($event.metaKey || $event.ctrlKey) && [vKey, cKey, aKey].indexOf($event.keyCode) !== -1:
        break;
      case isEnterPressed:
        break;
      case isTabPressed: {
        const active = document.activeElement as HTMLElement;
        if (active.classList[0] === 'modal-access-input') {
          const elem = document.getElementById('helper');
          elem.focus();
          $event.preventDefault();
        }
        break;
      }
      default:
        $event.preventDefault();
    }
  }

  resendKeyPress($event: any) {
    console.log($event);
  }
  resendCode() {
    this.sendingCode$.next(true);
    this.resendcodeevent.emit();
  }
  closeSuccessModal() {
    this.dialog.closeAll();
  }
}
