import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { STATE } from '@core/tokens';

@Component({
  selector: 'app-feature-flag-off',
  templateUrl: './feature-flag-off.component.html',
  styleUrls: ['./feature-flag-off.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFlagOffComponent {
  constructor(@Inject(STATE) private state: ng.ui.IStateService, private bodyRef: ElementRef) {}
  ngOnInit(): void {
    this.bodyRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    this.bodyRef.nativeElement.ownerDocument.body.style.overflow = 'scroll';
  }
  goDashboard() {
    this.state.go('udb.dashboard');
  }
}
