<div [ngSwitch]="!showBusinessAccounts || !showSubCategories">
  <div *ngSwitchCase="showBusinessAccounts" class="d-flex flex-wrap support-menu__subcategories">
    <app-sm-item
      class="col-12 col-sm-6"
      *ngFor="let item of businessAccounts"
      [name]="item.name"
      (click)="emitBusinessAccountInfo(item)"
    ></app-sm-item>
  </div>
  <div *ngSwitchCase="showSubCategories" class="d-flex flex-wrap support-menu__subcategories">
    <app-sm-item
      class="col-12 col-sm-6"
      *ngFor="let item of subCategories$ | async"
      [name]="item.description"
      (click)="emitSubcategoryInfo(item)"
    ></app-sm-item>
    <p *ngIf="showSDTMessage">
      <b>Disclaimer :</b>
      Requests for orders, cancellations, changes will not be processed via message and may only be placed through the
      Axos Self Directed Trading Platform or a licensed representative if the platform is unavailable.
    </p>
  </div>
  <div *ngSwitchDefault class="support-menu__categories support_menu">
    <app-sm-item
      class="col-12 d-block"
      *ngFor="let item of categories"
      [icon]="item.imageUrl"
      [name]="item.description"
      (click)="loadSubMenuOptions(item)"
    ></app-sm-item>
  </div>
</div>
<app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>
