import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, filter, first } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { CachedAccountsService } from 'services/cached-accounts.service';

import { AccountCategory } from '@app/accounts/enums';
import { InvestAccount } from '@app/axos-invest/models';
import { getInvestAccount } from '@app/axos-invest/store/selectors';
import { AccountOrigin } from '@app/pfm/enums/account-origin.enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import {
  accountsLoaded,
  providersLoaded,
  setAccountsLoading,
  setProvidersLoading,
} from '@app/pfm/store/accounts/accounts.actions';
import { OlbEvents, UserSubType } from '@core/enums';
import { OlbEventService, ProviderService } from '@core/services';
import { containerType } from '@legacy/accounts/container-type.enum';
import { TradingAccount } from '@legacy/accounts/typings/TradingAccount';
import { ClearingAccountStatus } from '@legacy/common/enums/enums';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { AxosClearingService } from '@legacy/services/axos-clearing.service';
import { BalanceService } from '@legacy/services/balance.service';
import { CachedTradingAccountsService } from '@legacy/services/cached-trading-accounts.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AxosInvestHelperService } from '@app/core/services/axos-invest.service';
import { CategoryName } from '@legacy/tiles/account-overview/typings/CategoryName.enum';
import { AggregatedAccount } from '@legacy/typings/app/account-aggregation';

import { NetWorthEffects } from '../net-worth/net-worth.effects';
import { SpendingEffects } from '../spending/spending.effects';
import { OlbAccount } from '@app/accounts/models';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryAccount } from '@core/models';

@Injectable()
export class AccountsEffects {
  // todo once ngrx/effects is available, convert this service into a side effect

  constructor(
    public accAggFormatter: AccountAggregationChartFormatterService,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly olbEventService: OlbEventService,

    private readonly axosClearingService: AxosClearingService,
    private readonly cachedTradingAccountsService: CachedTradingAccountsService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly axosInvestHelper: AxosInvestHelperService,
    private readonly balanceService: BalanceService,
    private store: Store,
    private spendingEffects: SpendingEffects,
    private netWorthEffects: NetWorthEffects,
    private providerService: ProviderService,
    private serviceHelper: ServiceHelper
  ) {}

  loadAccounts(subsink: SubSink): void {
    // todo once ngrx/effects is available, this method should be listening to action: [Insights.Accounts] Load accounts
    //   and the action should be dispatched in all sites that call this method

    this.spendingEffects.startListening(subsink);
    this.netWorthEffects.startListening(subsink);
    this.store.dispatch(setAccountsLoading({ payload: true }));

    const getInternalAccounts = new Promise<[OlbAccount[], OlbAccount[]]>(resolve => {
      const complete = () =>
        resolve([
          this.cachedAccountsService.internalAccounts?.depositAccounts || [],
          this.cachedAccountsService.internalAccounts?.loanAccounts || [],
        ]);
      if (this.cachedAccountsService.internalAccounts) complete();
      else {
        const subscription = this.olbEventService.on(OlbEvents.BalancesAvailable, () => {
          complete();
          subscription.unsubscribe();
        });
      }
    });

    const getAggAccounts = new Promise<AggregatedAccount[]>(resolve => {
      const complete = () =>
        resolve(
          this.cachedAccountsService.aggregatedAccounts instanceof Error
            ? []
            : this.cachedAccountsService.aggregatedAccounts || []
        );

      if (this.cachedAccountsService.aggregatedAccounts) complete();
      else {
        const subscription = this.olbEventService.on(OlbEvents.AggregatedAccountsLoaded, () => {
          complete();
          subscription.unsubscribe();
        });
      }
    });

    const getClearingAccounts = new Promise<TradingAccount[]>(resolve => {
      const complete = () => resolve(this.cachedTradingAccountsService.tradingAccounts || []);
      if (
        !this.axosClearingService.isAxosTradingActiveForUser() ||
        this.cachedTradingAccountsService.isTradingAccountsLoaded
      ) {
        complete();
      } else {
        const subscription = this.olbEventService.on(OlbEvents.InvestAccountsLoaded, () => {
          complete();
          subscription.unsubscribe();
        });
      }
    });

    const getAxosInvests = new Promise<InvestAccount[]>(resolve => {
      if (!this.featureFlagService.isAxosInvestActive() || !this.axosInvestHelper.hasAxosInvest) resolve([]);
      else {
        this.store
          .select(getInvestAccount)
          .pipe(
            filter(account => account.isLoaded),
            first()
          )
          .subscribe(account => {
            resolve([account]);
          });
      }
    });

    const getRIAAccounts = new Promise<AxosAdvisoryAccount[]>(resolve => {
      const userSubType = parseInt(sessionStorage.getItem('userSubType'));
      const hasAxosAdvisoryService = (userSubType & UserSubType.AxosAdvisoryService) > 0;
      if (!hasAxosAdvisoryService) {
        resolve([]);
      } else {
        this.store
          .select(getAxosAdvisoryAccounts)
          .pipe(
            filter(riaAccounts => riaAccounts !== null),
            first()
          )
          .subscribe((accounts: AxosAdvisoryAccount[]) => {
            resolve(accounts ? [...accounts] : []);
          });
      }
    });

    Promise.all([getInternalAccounts, getAggAccounts, getClearingAccounts, getAxosInvests, getRIAAccounts]).then(
      ([[internalDeposits, internalLoans], aggregated, tradingsClearing, axosInvests, riaAccounts]) => {
        const isSBBAccountsInPlanVerticalActive = this.featureFlagService.isSBBAccountsInPlanVerticalActive();
        // merge and map all accounts
        this.featureFlagService.isSBBAccountsInPlanVerticalActive();
        const accounts = [
          //if 'SBBAccountsInPlanVertical' feature flag is OFF, then exclude SBB accounts.
          ...(!isSBBAccountsInPlanVerticalActive
            ? internalDeposits.filter(acc => acc.isSBB === false)
            : internalDeposits
          ).map(acc => this.fromInternalToAccountOverview(acc as Required<OlbAccount>)),
          ...internalLoans.map(acc => this.fromInternalToAccountOverview(acc as Required<OlbAccount>)),
          //if ' SBBAccountsInPlanVertical' feature flag is OFF, then exclude SBB accounts.
          ...(!isSBBAccountsInPlanVerticalActive ? aggregated.filter(acc => acc.isSBB === false) : aggregated)
            .filter(agg => agg.container !== containerType.Reward)
            .map(acc => this.fromAggToAccountOverview(acc as Required<AggregatedAccount>)),
          ...tradingsClearing
            .filter(trading => +trading.status !== ClearingAccountStatus.Closed)
            .map(acc => this.fromClearingToAccountOverview(acc as Required<TradingAccount>)),
          ...axosInvests.filter(inv => inv && !inv.isClosed).map(acc => this.fromAxosInvestToAccountOverview(acc)),
          ...riaAccounts.map(acc => this.fromRIAToAccountOverview(acc)),
        ];

        // sort and filter by categories
        const relevantAccounts = [
          ...accounts.filter(acc => acc.categoryName === CategoryName.Checking),
          ...accounts.filter(acc => acc.categoryName === CategoryName.Savings),
          ...accounts.filter(acc => acc.categoryName === CategoryName.Investment),
          ...accounts.filter(acc => acc.categoryName === CategoryName.CreditCards),
          ...accounts.filter(acc => acc.categoryName === CategoryName.Loans),
          ...accounts.filter(acc => acc.categoryName === CategoryName.Other),
        ];

        this.store.dispatch(accountsLoaded({ payload: relevantAccounts }));
        this.store.dispatch(setAccountsLoading({ payload: false }));
      }
    );

    this.store.dispatch(setProvidersLoading({ payload: true }));
    this.providerService
      .getProviders()
      .pipe(
        catchError(err => {
          // show error popup
          this.serviceHelper.errorHandler(err, true);

          return [];
        })
      )
      .subscribe(providers => {
        this.store.dispatch(providersLoaded({ payload: providers }));
        this.store.dispatch(setProvidersLoading({ payload: false }));
      });
  }

  fromInternalToAccountOverview(account: Required<OlbAccount>): AccountOverview {
    return {
      ...account,
      nickname: account.nickname ? account.nickname.split('**')[0] : account.name || account.bankName,
      globalId: account.id.toString(),
      availableBalance: this.balanceService.getBalance(account),
      categoryName: this.getCategoryName(account.category, null),

      origin: AccountOrigin.Internal,
    };
  }

  fromAggToAccountOverview(account: Required<AggregatedAccount>): AccountOverview {
    return {
      ...account,
      globalId: `aggregated:${account.id}`, // to prevent key conflicts
      nickname: account.nickname ? account.nickname.split('**')[0] : account.name || account.bankName,
      availableBalance: this.balanceService.getBalance(account),
      categoryName: this.getCategoryName(account.category, account.container),

      origin: AccountOrigin.Yodlee,
      credentialsHaveChanged: account.credentialsHaveChanged,
      lastUpdated: account.lastUpdated,
    };
  }

  fromClearingToAccountOverview(account: Required<TradingAccount>): AccountOverview {
    return {
      ...account,
      globalId: `clearing:${account.id}`, // to prevent key conflicts
      accountMask: account.accountNumber,
      availableBalance: account.totalValue,
      categoryName: CategoryName.Investment,

      origin: AccountOrigin.Clearing,
    };
  }

  fromAxosInvestToAccountOverview(account: InvestAccount): AccountOverview {
    return {
      ...account,
      globalId: 'axosinvest',
      categoryName: CategoryName.Investment,

      id: null,
      origin: AccountOrigin.AxosInvest,
    };
  }

  fromRIAToAccountOverview(account: AxosAdvisoryAccount): AccountOverview {
    return {
      ...account,
      globalId: `AAS:${account.accountNumber}`,
      categoryName: CategoryName.Investment,
      nickname: account.accountNickname,
      id: +account.riaId,
      accountNumberAas: Number(account.accountNumber),
      origin: AccountOrigin.AAS,
      availableBalance: account.accountBalance,
      bankName: account.bankName,
      accountType: account.accountType,
      container: 'RIA',
      accountMask: account.accountNumber.substr(account.accountNumber.length - 4),
      isAxosAdvisory: true,
    };
  }

  getCategoryName(category: AccountCategory, container: string): CategoryName {
    if (category === AccountCategory.Dda) return CategoryName.Checking;
    if (category === AccountCategory.Loan) return CategoryName.Loans;
    if (category === AccountCategory.Credit) return CategoryName.CreditCards;
    if (container === containerType.Investment) return CategoryName.Investment;
    if (category === AccountCategory.Other) return CategoryName.Other;

    if (category === AccountCategory.Sav || category === AccountCategory.Cd) {
      return CategoryName.Savings;
    }

    return CategoryName.Default;
  }
}
