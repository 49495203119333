<h3 class="mt-5 mb-4">How do I make a payment to an individual?</h3>

<ol>
  <li>
    Click on the
    <b>Move Money tab.</b>
  </li>
  <li>
    Select the
    <b>Pay It Now</b>
    tab on the right-hand side.
  </li>
  <li>
    Fill out the required information and click
    <b>Submit.</b>
  </li>
</ol>
