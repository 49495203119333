import { AggregatedAccount } from 'typings/app/account-aggregation';

import { AccountOrigin } from '@app/pfm/enums/account-origin.enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { Category } from '@app/pfm/models/category';
import {
  Banks,
  DefaultAdvisorLogo,
} from '@legacy/dashboard/account-aggregation/typings';
import { ExternalBankProvider } from '@shared/models';

import { IAccountAggregationChartFormatterService } from './typings/IAccountAggregationChartFormatterService';
import { BrandingStateType } from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaType } from '@app/Areas/AAS/aas-core/rias';
export class AccountAggregationChartFormatterService
  implements IAccountAggregationChartFormatterService {
  SortCategories(categoryGrid: Category[]) {
    categoryGrid.sort((a, b) =>
      a.percentage > b.percentage
        ? -1
        : a.percentage === b.percentage
        ? a.name > b.name
          ? 1
          : -1
        : 1
    );
  }

  /**Sum values in a numeric array */
  SumValues(array: number[]): number {
    const result = array.reduce(
      (sum: number, curr: number) => (sum += curr | 0),
      0
    );

    return result;
  }
  /**Return the min value in an array except for null */
  MaxValue(array: number[]): number {
    return Math.max(...array.filter(v => v !== null));
  }
  /**Return the min value in an array except for null */
  MinValue(array: number[]): number {
    return Math.min(...array.filter(v => v !== null));
  }
  /**
   * Return the nearest decene of the number
   * @param n Value
   */
  RoundToNearest10(n: number): number {
    return Math.round(n / 10) * 10;
  }

  /**
   * Returns an array generated from "start" to "end" with steps of size "step"
   * @param start : number
   * @param end : number
   * @param step : number
   */
  Range(start: number, end: number, step: number = 1) {
    const len = Math.round((end - start) / step) + 1;

    return Array(len)
      .fill(null)
      .map((_: any, idx: number) => start + idx * step);
  }

  /**
   * Returns transactions between the period of time
   * @param txArray
   * @param startDate
   */
  FiltertTransactionsByDate(
    txArray: Transaction[],
    startDate: Date,
    endDate: Date = new Date()
  ): Transaction[] {
    const resultArray = txArray.filter(tx => {
      const txDate = new Date(tx.postedDate.toString());

      return startDate <= txDate && txDate < endDate;
    });

    return resultArray;
  }

  /**
   * Function that returns now first date of month + months + years
   * Can pass negative values
   * @param months
   * @param years
   */
  GetFilterDate(months: number, years: number): Date {
    const date = new Date();
    const resultDate = new Date(
      date.getFullYear() + years,
      date.getMonth() + months,
      1
    );

    return resultDate;
  }

  /**
   * Return number of days in given month
   * JS months Date is 0 index array
   * @param month
   * @param year
   */
  DaysInMonth(month: number, year: number) {
    return new Date(year, month + 1, 0).getDate();
  }

  /**
   * Return number of days in given month by Date
   * @param date
   */
  DaysInMonthByDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  /**
   * Group transactions when key is the result of the callback cb
   * @param arr
   * @param cb
   */
  GroupByTransactions(
    arr: Transaction[],
    cb: (element: Transaction) => number
  ): { [key: number]: Transaction[] } {
    const res: any = {};
    for (const el of arr) {
      const key: number = cb(el);
      (res[key] || (res[key] = [])).push(el);
    }

    return res;
  }

  /**
   * Sum values in each array of transactions inside the object
   * @param obj
   */
  SumTransactionArray(obj: {
    [key: number]: Transaction[];
  }): { [key: number]: number } {
    const res: any = {};
    for (const prop in obj) {
      const array: Transaction[] = obj[prop];
      const sum = array.reduce(
        (accumulator, current) =>
          accumulator +
          Math.abs(typeof current.amount !== 'undefined' ? current.amount : 0),
        0
      );

      res[prop] = sum;
    }

    return res;
  }

  /**
   * Sum values in each array of transactions inside the object
   * @param obj
   */
  SumSignedTransactionArray(obj: {
    [key: number]: ExternalTransaction[];
  }): { [key: number]: number } {
    const res: any = {};
    for (const prop in obj) {
      const array: ExternalTransaction[] = obj[prop];
      const sum = array.reduce(
        (accumulator, current) =>
          accumulator +
          (typeof current.signedAmount !== 'undefined'
            ? current.signedAmount
            : current.amount),
        0
      );
      res[prop] = sum;
    }

    return res;
  }

  /**
   * Sum every amount with the previous one and so to calculate the running amount of the transactions in that period
   * @param obj
   */
  GetRunningTransactionAmount(obj: { [key: number]: number }) {
    const res: any = {};
    let runningAmount = 0;
    for (const prop in obj) {
      runningAmount += obj[prop];
      res[prop] = runningAmount;
    }

    return res;
  }

  /**
   * Gets formatted data to show in the chart line
   * @param data
   * @param startDate
   * @param fillFullMonth
   */
  GetFormatDataForMonth(
    data: { [key: number]: number },
    startDate: Date,
    fillFullMonth: boolean = false
  ): number[] {
    const daysInMonth = this.DaysInMonth(
      startDate.getMonth(),
      startDate.getFullYear()
    );
    const resultData = Array(daysInMonth).fill(null);
    // Initialize the first element to 0
    resultData[0] = 0;

    for (const prop in data) {
      resultData[+prop - 1] = data[prop];
    }

    let lastValidValue = resultData[0];
    let prevWasNull = false;
    resultData.forEach((element: number, index: number, array: number[]) => {
      if (element !== null) {
        if (prevWasNull) {
          array[index - 1] = lastValidValue;
        }
        lastValidValue = element;
      }

      prevWasNull = element === null;
    });

    const lastDayIndex =
      (fillFullMonth ? daysInMonth : new Date().getDate()) - 1;
    resultData[lastDayIndex] = lastValidValue;

    return resultData;
  }

  /**
   * Gets the sum of all the values in the object
   * @param obj
   */
  SumObjectValues(obj: { [key: number]: number }) {
    let sum = 0;
    for (const prop in obj) {
      sum += obj[prop];
    }

    return sum;
  }

  /**
   * Gets the abbreviated name month of the date passed
   * @param date Pass the date to take the month if not actual date is taken
   */
  GetShortMonthName(date?: Date) {
    if (!date) {
      date = new Date();
    }

    return date.toLocaleDateString(undefined, { month: 'short' });
  }

  // ------------ PFM -------------
  getThisAndLastTimePeriodTransactions(
    transactions: ExternalTransaction[]
  ): number[] {
    const startActualPeriodDate = this.GetFilterDate(0, 0);

    const startPreviousPeriodDate = this.GetFilterDate(-1, 0);

    const actualPeriodTx = this.FiltertTransactionsByDate(
      transactions,
      startActualPeriodDate
    );

    const previousPeriodTx = this.FiltertTransactionsByDate(
      transactions,
      startPreviousPeriodDate,
      startActualPeriodDate
    );

    const groupByDayCallback = (element: Transaction) =>
      new Date(element.postedDate.toString()).getDate();

    const groupedActualTransactions = this.GroupByTransactions(
      actualPeriodTx,
      groupByDayCallback
    );

    const groupedPreviousTransactions = this.GroupByTransactions(
      previousPeriodTx,
      groupByDayCallback
    );

    const summedActualTransactions = this.SumTransactionArray(
      groupedActualTransactions
    );
    const summedPreviousTransactions = this.SumTransactionArray(
      groupedPreviousTransactions
    );

    const thisTimePeriodTotalTransactions = this.SumObjectValues(
      summedActualTransactions
    );

    const lastTimePeriodTotalTransactions = this.SumObjectValues(
      summedPreviousTransactions
    );

    return [thisTimePeriodTotalTransactions, lastTimePeriodTotalTransactions];
  }

  getPfmNumbers(
    transactions: ExternalTransaction[]
  ): {
    percentageChange: number;
    thisPeriodTransactionsSum: number;
    lastPeriodTransactionsSum: number;
    arrow: string;
  } {
    const [
      thisPeriodTransactionsSum,
      lastPeriodTransactionsSum,
    ] = this.getThisAndLastTimePeriodTransactions(transactions);

    // if the percentage is smaller than 1% then put a less than arrow sign before the 1 (i.e. <1%)
    // this will be handled by pipe
    let percentageChange =
      (thisPeriodTransactionsSum - lastPeriodTransactionsSum) /
      lastPeriodTransactionsSum;

    let arrow = '';
    // Display an up arrow if the percentage change is greater compared to last time period
    if (thisPeriodTransactionsSum > lastPeriodTransactionsSum) {
      arrow = 'up';
    }
    // Display a down arrow if the percentage change is less compared to last time period
    if (thisPeriodTransactionsSum < lastPeriodTransactionsSum) {
      arrow = 'down';
    }

    // Display "--"" if last period transactions is $0 (prevent division by 0)
    if (lastPeriodTransactionsSum === 0) {
      percentageChange = null;
    }

    return {
      percentageChange: isFinite(percentageChange) ? percentageChange : 0,
      thisPeriodTransactionsSum,
      lastPeriodTransactionsSum,
      arrow,
    };
  }

  getCategoryPercentage(
    transactions: ExternalTransaction[],
    categoryId: number
  ): number {
    const [
      thisPeriodTransactionsSum,
    ] = this.getThisAndLastTimePeriodTransactions(transactions);

    const transactionsByCategory = transactions.filter(
      x => x.olbCategoryId === categoryId
    );
    const [
      thisPeriodCategoryTransactionsSum,
    ] = this.getThisAndLastTimePeriodTransactions(transactionsByCategory);
    let categoryPercentage =
      (thisPeriodCategoryTransactionsSum / thisPeriodTransactionsSum) * 100;
    categoryPercentage = isFinite(categoryPercentage) ? categoryPercentage : 0;

    return categoryPercentage;
  }
  getCategoryAmount(
    transactions: ExternalTransaction[],
    categoryId: number
  ): number {
    const transactionsByCategory = transactions.filter(
      x => x.olbCategoryId === categoryId
    );
    const [
      currentTimePeriodTotalTransaction,
    ] = this.getThisAndLastTimePeriodTransactions(transactionsByCategory);

    return currentTimePeriodTotalTransaction;
  }

  /**
   * Given the percentage change text (comparing previous month to current month)
   * this returns the font size in rems so that the text fits within the doughnut charts
   */
  getPercentageChangeFontSize(percentageChangeStr: string) {
    const max = 3;
    const min = 0.7;
    if (!percentageChangeStr) return max;
    const length = percentageChangeStr.length;

    return `${Math.max(min, max - length * 0.31)}rem`;
  }

  /**
   * Given an array of categories this mutates each category to apply rounding percentage logic
   * this will not mutate the enclosing array.
   */
  roundCategoriesPercentages(categories: { percentage?: number }[]) {
    // If a category has percentage greater than 0 but less than 1 then round to 1
    // otherwise use normal rounding

    categories = (categories || []).filter(category => category.percentage);
    if (!categories.length) return;

    categories.forEach(
      category =>
        (category.percentage =
          category.percentage > 0 && category.percentage < 1
            ? 1
            : Math.round(category.percentage))
    );
  }

  /**
   * Returns account name to be displayed.
   *
   * @param Account to be displayed.
   * @returns A bank name mask.
   *
   * @example
   * For an account with account number: 789456124 it should return Dag Site 6124
   */
  getAccountToDisplay(account: AggregatedAccount): string {
    return this.getBankNameMask(account);
  }

  /**
   * Verifies if the account has a preloaded image for the institution
   * @param bankName Name of the institution
   */
  hasImage(
    providers: ExternalBankProvider[],
    account?: AggregatedAccount
  ): boolean {
    // If is RIA account must return custom logo or axos default logo depending on the ria configuration
    if (account.isAxosAdvisory) {
      return true;
    } else if (!account.isExternal) {
      return true;
    } else if (providers) {
      return !!providers.find(provider =>
        this.compareNames(account.bankName, provider.providerName)
      );
    } else {
      return false;
    }
  }

  /**
   * Returns the institution image
   * @param account
   */
  getImage(
    isAccountAggregationEnhancementsActive: boolean,
    providers: ExternalBankProvider[],
    env: OlbSettings,
    account: AggregatedAccount,
    allBrandingSettings?: BrandingStateType[],
    allRiaAccounts?: RiaType[]
  ): any {
    let bank;
    const ria = 'RIA';
    const riaLogo = 'ria-logo.svg';

    if (
      !account.isAxosAdvisory &&
      (account.isAxosInvest ||
        account.isTrading ||
        account.origin === AccountOrigin.AxosInvest ||
        account.origin === AccountOrigin.Clearing)
    ) {
      if (isAccountAggregationEnhancementsActive) {
        return 'rounded-logos/axos-invest_round_logo.svg';
      }

      return 'axos-invest_logo.svg';
    }

    if (account.isAxosAdvisory) {
      return this.getAxosAdvisoryLogo(
        allBrandingSettings,
        allRiaAccounts,
        account
      );
    }

    if (account.isRia || account.container === ria) {
      return riaLogo;
    }

    if (account.isExternal) {
      bank = Banks.find(bank => bank.name.includes(account.bankName));
    }
    try {
      const provider = providers.find(provider =>
        this.compareNames(account.bankName, provider.providerName)
      );
      let logoPath = '';
      const roundedLogoPath = 'rounded-logos/';
      if (isAccountAggregationEnhancementsActive) {
        logoPath =
          roundedLogoPath +
          (!!provider ? `${provider.logoName}` : `${env.brand}_round_logo.svg`);
      } else logoPath = `${bank ? bank.imageSrc : `${env.brand}_logo.png`}`;

      return `${logoPath}`;
    } catch {}
  }

  /**
   * Returns a placeholder (initials) in case there is no image for the institution
   * @param bankName Name of the institution
   */
  getBankProfile(bankName: string): string {
    const bankNameSeparator = ' ';
    const regexExpressions = /[^\w\s]/gi;
    const regexReplacer = '';
    const splitStr = bankName.split(bankNameSeparator).splice(0, 2);

    let initials = '';
    for (const word of splitStr) {
      initials += word.charAt(0);
    }

    return initials.replace(regexExpressions, regexReplacer);
  }

  getSelectedAccountsSize(
    accounts: AccountOverview[],
    excludedAccounts: Set<string>,
    includeAxosInvestAndClearing = false
  ) {
    return (
      this.getAccountsSize(accounts, includeAxosInvestAndClearing) -
      accounts.filter(
        a =>
          excludedAccounts.has(a.globalId) &&
          (includeAxosInvestAndClearing ||
            (a.origin !== AccountOrigin.AxosInvest &&
              a.origin !== AccountOrigin.Clearing))
      ).length
    );
  }

  getAccountsSize(
    accounts: AccountOverview[],
    includeAxosInvestAndClearing = false
  ) {
    return accounts.filter(
      a =>
        includeAxosInvestAndClearing ||
        (a.origin !== AccountOrigin.AxosInvest &&
          a.origin !== AccountOrigin.Clearing)
    ).length;
  }

  /**
   * Returns bank name mask to be displayed.
   *
   * @param Account to be displayed.
   * @returns A bank name mask string.
   *
   * @example
   * For an account with account number: 789456124 it should return Dag Site 6124
   */
  private getBankNameMask(account: AggregatedAccount): string {
    if (!account) return '';
    if (account.nickname) return account.nickname;

    let lastDigits = account.accountNumber ?? account.accountMask;
    lastDigits = this.getMaskAccountNumber(lastDigits);

    return `${account.bankName} ${lastDigits}`;
  }

  /**
   * Returns account name to be displayed.
   *
   * @param Account number to be used for mask.
   * @returns last 4 digits of account number.
   *
   * @example
   * For an account with account number: 789456124 it should return Dag Site 6124
   */
  private getMaskAccountNumber(accountNumber: string): string {
    if (!accountNumber || accountNumber.length < 4) return '';

    return `*${accountNumber.substring(accountNumber.length - 4)}`;
  }

  private compareNames(bankName: string, providerName: string) {
    return (
      providerName.localeCompare(bankName, 'en', { sensitivity: 'accent' }) ===
      0
    );
  }

  private getAxosAdvisoryLogo(
    allBrandingSettings: BrandingStateType[],
    allRiaAccounts: RiaType[],
    account: AggregatedAccount
  ) {
    const regexValue = /url\('([^']+)'\)/;
    const secondaryLogoText = 'secondaryLogo';
    const brand = allBrandingSettings?.find(
      brand => brand.name === account.brandingName
    );
    const ria = allRiaAccounts?.find(ria => ria.riaId === account.riaId);
    const secondaryLogo = brand?.settings.find(
      settings => settings.name === secondaryLogoText
    );

    if (secondaryLogo && ria.useCustomSecondaryLogo) {
      return secondaryLogo.value.match(regexValue)[1];
    }

    return DefaultAdvisorLogo;
  }
}
