import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { PayItNowTopics } from '@app/support/enums';

@Component({
  selector: 'how-to-pay-it-now-topics',
  templateUrl: './pay-it-now-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PayItNowTopicsComponent {
  selectedTopic: PayItNowTopics;
  topics = PayItNowTopics;

  selectTopic(topic: PayItNowTopics): void {
    this.selectedTopic = topic;
  }
}
