import { ACCOUNTS } from '../../constants/accounts';
import { TradingAccount } from 'accounts/typings/TradingAccount';

export const TradingAccountActions = {
  loadTradingAccounts(accounts: Array<TradingAccount>) {
    return {
      type: ACCOUNTS.TRADING.LOAD,
      payload: accounts,
    };
  },
  updateAccount: (account: TradingAccount) => {
    return {
      type: ACCOUNTS.TRADING.UPDATE,
      payload: account,
    };
  },
};
