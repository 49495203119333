import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { UserTile, UserTileSetting } from 'models';
import { TilesService } from 'services/tiles.service';

export abstract class BaseTile<T extends BaseTileSettings> {
  protected tileId: number;
  protected instanceId: number;
  protected tileInfo: UserTile;
  protected sId: string;
  protected tileSettings: T & any = {};
  protected isInitialising = true;
  protected settingsSubscription: Subscription;
  protected maxAccounts = 3;

  constructor(
    protected scope: ng.IScope,
    protected elm: ng.IRootElementService,
    protected tilesService: TilesService,
    protected serviceHelper: IServiceHelper
  ) {
    this.updateSettings = this.updateSettings.bind(this);
    this.updateInternalSettings = this.updateInternalSettings.bind(this);
  }

  /**
   * Gets the settings of the tile.
   * Settings are stored in the settings property.
   * @param cb Function to call after settings have been loaded.
   */
  protected getSettings(cb?: Function): void {
    this.sId = `ts${this.tileId}x${this.instanceId}`;
    this.tileInfo = {
      instanceId: isNaN(+this.instanceId) ? 0 : +this.instanceId,
      tileId: +this.tileId,
    };

    this.settingsSubscription = this.tilesService.userTiles$.pipe(take(1)).subscribe(tiles => {
      const currentTile = tiles.find(
        t => t.tileId == this.tileId && t.instanceId == this.instanceId
      );

      this.updateInternalSettings(currentTile.settings, currentTile.isNew);
      if (cb) cb();
    });
  }

  /** Saves the tile settings. */
  protected saveSettings(): void {
    const settings: UserTileSetting[] = [];

    for (const key in this.tileSettings) {
      settings.push(
        new UserTileSetting(
          this.tileSettings[key].id,
          key,
          this.tileSettings[key].value,
          this.instanceId
        )
      );
    }

    this.tilesService
      .saveTileSettings(this.tileId, settings)
      .pipe(take(1))
      .subscribe(settings => {
        this.updateInternalSettings(settings, !Number(this.instanceId));
      }, this.serviceHelper.errorHandler);
  }

  /**
   * Emits an event to the dashboard to delete this tile.
   */
  protected removeThisTile(): void {
    this.scope.$emit('$uikitTileRemove', this.tileInfo);
  }

  private updateInternalSettings(settings: UserTileSetting[], isNewTile: boolean = false): void {
    if (!settings.length) return;

    settings.forEach(setting => {
      this.tileSettings[setting.settingCode] = {
        id: setting.id,
        value: setting.value,
      };
    });

    this.tileInfo.instanceId = settings[0].userTileId;
    this.instanceId = settings[0].userTileId;
    this.elm.attr('instance-id', this.instanceId);
    if (isNewTile) this.scope.$emit('olbTileCreated', this.tileInfo);
  }

  /**
   * Updates the tile settings based on the information from the server.
   * @param res Tile settings retrieved from the server.
   */
  private updateSettings(res: OlbResponse<UserTileSetting[]>): void {
    for (const setting of res.data) {
      this.tileSettings[setting.settingCode] = {
        id: setting.id,
        value: setting.value,
      };
    }

    this.tileInfo.instanceId = res.data[0].userTileId;
    this.instanceId = res.data[0].userTileId;
    this.elm.attr('instance-id', this.instanceId);
  }

  protected resetAccountsSettings(tileValues: string, accounts: OlbAccount[]): number[] {
    const settingsIds = tileValues.split(',').map(s => +s);

    const settings: number[] = [];
    settingsIds.forEach(id => {
      if (accounts.some(account => account.id == id)) {
        settings.push(id);
      }
    });

    return settings;
  }
}

export interface BaseTileSettings {
  [key: string]: TileSetting;
}

export type TileSetting = {
  id: number;
  value: string;
};
