export class DropdownItem {
  constructor(
    /** Text to be displayed in the item. */
    public text: string,
    /** Optional item */
    public id?: number | string,
    /** Optional description to be displayed under the text */
    public description?: string,
    /** Optional property to indicate if the item is focused */
    public focus?: boolean,
    /** Optional property to add an icon in the item */
    public icon?: string
  ) {}
}
