import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { ModelsSummaryRequest, ModelsSummaryResponse } from './types';

@Injectable()
export class ModelsSummaryService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/models');
  }

  /**
   * Returns the models summary of account number from AAS customer.
   * todo: remove params from request
   * @param request ModelsSummaryRequest
   * @returns WebApiResponse of ModelsSummaryResponse
   *
   */
  getModelsSummary(request: ModelsSummaryRequest): WebApiResponse<ModelsSummaryResponse[]> {
    return this.get(request.accountNumber + '/summary');
  }
}
