import * as angular from 'angular';

import { SharedModule } from '@legacy/shared/shared.module';

import { AccountAggregationComponent } from './account-aggregation/account-aggregation.component';
import { AccountAggregationController } from './account-aggregation/account-aggregation.controller';
import { AccountDistributionComponent } from './account-distribution/account-distribution.component';
import { AccountDistributionController } from './account-distribution/account-distribution.controller';
import { ColorPickerComponent } from './account-distribution/color-picker/color-picker.component';
import { ColorPickerController } from './account-distribution/color-picker/color-picker.controller';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { AccountOverviewController } from './account-overview/account-overview.controller';
import { TAccountDetailComponent } from './account-overview/t-account-detail/t-account-detail.component';
import { AccountTrendsComponent } from './account-trends/account-trends.component';
import { AccountTrendsController } from './account-trends/account-trends.controller';
import { InvestMilestoneComponent } from './invest-milestone/invest-milestone.component';
import { InvestMilestoneController } from './invest-milestone/invest-milestone.controller';
import { MilestoneDetailComponent } from './invest-milestone/milestone-detail/milestone-detail.component';
import { PercentLoaderComponent } from './invest-milestone/percent-loader/percent-loader.component';
import { PercentLoaderController } from './invest-milestone/percent-loader/percent-loader.controller';
import { InvestmentOverviewComponent } from './investment-overview/investment-overview.component';
import { InvestmentOverviewController } from './investment-overview/investment-overview.controller';
import { NextLoanPaymentComponent } from './next-loan-payment/next-loan-payment.component';
import { NextLoanPaymentController } from './next-loan-payment/next-loan-payment.controller';
import { QuickTransferComponent } from './quick-transfer/quick-transfer.component';
import { QuickTransferController } from './quick-transfer/quick-transfer.controller';
import { RecentTransactionsComponent } from './recent-transactions/recent-transactions.component';
import { RecentTransactionsController } from './recent-transactions/recent-transactions.controller';
import { TileTxDetailComponent } from './recent-transactions/tile-tx-detail/tile-tx-detail.component';
import { TileTxDetailController } from './recent-transactions/tile-tx-detail/tile-tx-detail.controller';
import { ScheduledPaymentsComponent } from './scheduled-payments/scheduled-payments.component';
import { ScheduledPaymentsController } from './scheduled-payments/scheduled-payments.controller';
import { TilePmntDetailComponent } from './scheduled-payments/tile-pmnt-detail/tile-pmnt-detail.component';
import { TilePmntDetailController } from './scheduled-payments/tile-pmnt-detail/tile-pmnt-detail.controller';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';

export const TilesModule = angular
  .module('udb.tiles', ['ui.carousel', SharedModule])
  .component('accountOverview', AccountOverviewComponent)
  .component('tAccountDetail', TAccountDetailComponent)
  .component('recentTransactions', RecentTransactionsComponent)
  .component('tileTxDetail', TileTxDetailComponent)
  .component('accountDistribution', AccountDistributionComponent)
  .component('adColorPicker', ColorPickerComponent)
  .component('quickTransfer', QuickTransferComponent)

  .component('investmentOverview', InvestmentOverviewComponent)

  .component('investMilestone', InvestMilestoneComponent)
  .component('milestoneDetail', MilestoneDetailComponent)
  .component('nextLoanPayment', NextLoanPaymentComponent)
  .component('accountTrends', AccountTrendsComponent)
  .component('tilePmntDetail', TilePmntDetailComponent)
  .component('scheduledPayments', ScheduledPaymentsComponent)
  .component('accountAggregation', AccountAggregationComponent)
  .component('percentLoader', PercentLoaderComponent)

  .controller('NextLoanPaymentController', NextLoanPaymentController)
  .controller('QuickTransferController', QuickTransferController)

  .controller('InvestmentOverviewController', InvestmentOverviewController)

  .controller('InvestMilestoneController', InvestMilestoneController)
  .controller('ColorPickerController', ColorPickerController)
  .controller('AccountDistributionController', AccountDistributionController)
  .controller('RecentTransactionsController', RecentTransactionsController)
  .controller('AccountOverviewController', AccountOverviewController)
  .controller('TileTxDetailController', TileTxDetailController)
  .controller('TilePmntDetailController', TilePmntDetailController)
  .controller('AccountTrendsController', AccountTrendsController)
  .controller('ScheduledPaymentsTileController', ScheduledPaymentsController)
  .controller('AccountAggregationController', AccountAggregationController)
  .controller('PercentLoaderController', PercentLoaderController)
  .service('axosInvestHelper', downgradeInjectable(AxosInvestHelper))
  .service('dateHelper', downgradeInjectable(DateHelper)).name;
