import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AasStoreFeatures } from '../../aas-core';
import { ScheduledTransfersService, intialScheduledTransferState, scheduledTransfersReducer } from './core';
import { ScheduledTransfersTileComponent } from './view/scheduled-transfers/scheduled-transfers-tile.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { ScheduledTransfersMatModule } from './scheduled-transfers-mat.module';
import { ScheduledTransfersUk2Module } from './scheduled-transfers-uk2.module';

@NgModule({
  declarations: [ScheduledTransfersTileComponent],
  providers: [ScheduledTransfersService],
  imports: [
    ScheduledTransfersMatModule,
    ScheduledTransfersUk2Module,
    CommonModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forFeature(AasStoreFeatures.ScheduledTransfers, scheduledTransfersReducer, {
      initialState: intialScheduledTransferState,
    }),
  ],
  exports: [ScheduledTransfersTileComponent],
  schemas: [],
})
export class ScheduledTransfersModule {}
