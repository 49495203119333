import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreComponent implements AfterContentInit {
  @Input() itemSelector: string;
  @Input() shownItems = 2;

  get elementsList(): NodeListOf<HTMLElement> {
    return this.elm.nativeElement.querySelectorAll(this.itemSelector);
  }

  get showMoreText() {
    return `Show ${this.isShowingMore ? 'less' : 'more'} options`;
  }

  get containsExtraElements() {
    return this.elementsList.length > this.shownItems;
  }

  private isShowingMore = true;

  constructor(private elm: ElementRef<HTMLElement>) {}

  ngAfterContentInit(): void {
    this.toggleExtraItems();
  }

  toggleExtraItems(): void {
    this.isShowingMore = !this.isShowingMore;

    const start = this.shownItems;
    const end = this.elementsList.length;
    for (let i = start; i < end; i++) {
      this.elementsList[i].classList.toggle('hidden');
    }
  }
}
