import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { AuthorizationsFacade } from '@app/Areas/AAS/features/account-details/facade/authorizations.facade';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AchOneTimeAuthoritiesType } from '@app/Areas/AAS/features/account-details/core';
import { format, parseISO } from 'date-fns';
import { DATE_FORMAT, BLANK_FORMAT, ABA_NUM_SEPARATOR_FORMAT } from './constants';

@Component({
  selector: 'app-ach-one-time',
  templateUrl: './ach-one-time.component.html',
  styleUrls: ['./ach-one-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ACHOneTimeComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  authorizationsTabState: AchOneTimeAuthoritiesType[];
  blankFormat = BLANK_FORMAT;

  constructor(private authorizationsFacade: AuthorizationsFacade, private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.authorizationsFacade.authorizationsTabAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(data => {
        this.authorizationsTabState = data?.achOneTimeAuthorities;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormattedAccNum(value): string {
    if (value) {
      if (value.length > 4) {
        let newStr: string = '';
        for (let i = 0; i < value.length - 4; i++) {
          newStr += '*';
        }
        newStr += value.substring(value.length - 4, value.length);
        return newStr;
      } else {
        return value;
      }
    }
    return null;
  }

  getFormattedDate(value): string {
    return value || value != '' ? format(parseISO(value as any), DATE_FORMAT) : null;
  }

  abaRoutingFormat(value): string {
    if (value) {
      value = value.split(ABA_NUM_SEPARATOR_FORMAT)[0];
    }
    return value;
  }
}
