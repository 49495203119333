import { AggregationAuthComponent } from './aggregation-auth/aggregation-auth.component';
import { AggregationFlowSuccessComponent } from './aggregation-flow-success/aggregation-flow-success.component';
import { AggregationSelectionComponent } from './aggregation-selection/aggregation-selection.component';
import { AggregationSuccessComponent } from './aggregation-success/aggregation-success.component';
import { AccountAggregationComponent } from './account-aggregation/account-aggregation.component';
import { OpenAccountPopoverComponent } from './open-account-popover/open-account-popover.component';
export {
  AggregationAuthComponent,
  AggregationSelectionComponent,
  AggregationSuccessComponent,
  AggregationFlowSuccessComponent,
  AccountAggregationComponent,
  OpenAccountPopoverComponent,
};

export const dashboardComponents = [
  AggregationAuthComponent,
  AggregationSelectionComponent,
  AggregationSuccessComponent,
  AggregationFlowSuccessComponent,
  AccountAggregationComponent,
  OpenAccountPopoverComponent,
];
