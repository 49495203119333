import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import {
  ClickSwitchAccountsSelectionComponent,
  ClickSwitchComponent,
  ClickSwitchConfirmationComponent,
  ClickSwitchConfirmationPageComponent,
  ClickSwitchOnlinePathComponent,
  ClickSwitchReviewEditComponent,
  ClickSwitchSearchTargetComponent,
  ClickSwitchSubmittableFormComponent,
} from '@app/click-switch/components';

export const clickSwitchLegacyModule = angular
  .module('udb.clickswitch', ['ui.router'])
  .directive('appClickSwitch', downgradeComponent({ component: ClickSwitchComponent }))
  .directive('appClickSwitchSearchTarget', downgradeComponent({ component: ClickSwitchSearchTargetComponent }))
  .directive('appClickSwitchSubmittableForm', downgradeComponent({ component: ClickSwitchSubmittableFormComponent }))
  .directive(
    'appClickSwitchAccountsSelection',
    downgradeComponent({ component: ClickSwitchAccountsSelectionComponent })
  )
  .directive('appClickSwitchOnlinePath', downgradeComponent({ component: ClickSwitchOnlinePathComponent }))
  .directive('appClickSwitchReviewEdit', downgradeComponent({ component: ClickSwitchReviewEditComponent }))
  .directive('appClickSwitchConfirmation', downgradeComponent({ component: ClickSwitchConfirmationComponent }))
  .directive('appClickSwitchConfirmationPage', downgradeComponent({ component: ClickSwitchConfirmationPageComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider
        .state('udb.clickswitch', {
          url: '/DirectDeposit',
          views: {
            content: {
              template: '<div ui-view="clickswitch-content"><app-click-switch></app-click-switch></div>',
            },
          },
          params: { backstate: '', id: 0, backStateParams: {} },
        })
        .state('udb.clickswitch.search-target', {
          url: '/SearchTarget',
          views: {
            'clickswitch-content': {
              template: '<app-click-switch-search-target></app-click-switch-search-target>',
            },
          },
        })
        .state('udb.clickswitch.submittable-form', {
          url: '/SubmitTable',
          views: {
            'clickswitch-content': {
              template: '<app-click-switch-submittable-form></app-click-switch-submittable-form>',
            },
          },
          params: { switchInfo: {}, target: '' },
        })
        .state('udb.clickswitch.accounts', {
          url: '/Accounts',
          views: {
            'clickswitch-content': {
              template: '<app-click-switch-accounts-selection></app-click-switch-accounts-selection>',
            },
          },
          params: { userSwitch: {} },
        })
        .state('udb.clickswitch.online-path', {
          url: '/OnlinePath',
          params: { userSwitch: {} },
          views: {
            'clickswitch-content': {
              template: '<app-click-switch-online-path></app-click-switch-online-path>',
            },
          },
        })
        .state('udb.clickswitch.review-edit', {
          url: '/Review',
          params: { userSwitch: {} },
          views: {
            'clickswitch-content': {
              template: '<app-click-switch-review-edit></app-click-switch-review-edit>',
            },
          },
        })
        .state('udb.clickswitch.confirmation', {
          url: '/Confirmation',
          params: { userSwitch: null },
          views: {
            'clickswitch-content': {
              template: '<app-click-switch-confirmation></app-click-switch-confirmation>',
            },
          },
        })
        .state('udb.clickswitch.confirmation-print', {
          url: '/RequestPending',
          params: { userSwitch: null },
          views: {
            'clickswitch-content': {
              template: '<app-click-switch-confirmation-page></app-click-switch-confirmation-page>',
            },
          },
        });
    },
  ]).name;
