<div class="row">
  <button id="backToOverviewBtn" type="button" class="link d-flex align-items-center" (click)="goBackToStock()">
    <app-svg-icon class="overview__icon mr-2" [iconName]="icons.backIcon" size="1.3em"></app-svg-icon>
    <span class="overview__text">Back to {{ stock.stockName }}</span>
  </button>
</div>
<div class="row mt-4">
  <div class="col-6 mr-2 p-4 trade-form">
    <div class="form-title">{{ tradeType }} Order</div>
    <form novalidate [formGroup]="form" class="mt-4" (ngSubmit)="submit()">
      <div class="form-group" id="orderTypeGroup">
        <label
          for="orderType"
          class="uk-label"
          [class.insufficient]="form.get('orderType').errors?.required && this.submitted"
        >
          Order Type
        </label>
        <select name="orderType" id="orderType" class="uk-input" formControlName="orderType">
          <option *ngFor="let option of orderTypeOptions" [value]="option">
            {{ option }}
          </option>
        </select>
        <div class="text--error" *ngIf="form.get('orderType').errors?.required && this.submitted">
          Please complete this field
        </div>
        <div class="text--error closed-market" *ngIf="displayMarketValidation">
          The market is currently closed. Due to after market price changes, please consider a limit transaction rather
          than market.
        </div>
      </div>
      <div class="form-group" id="limitPriceGroup" *ngIf="form.get('orderType').value === orderTypes.Limit">
        <label
          for="limitPrice"
          class="uk-label"
          [class.insufficient]="
            (form.get('limitPrice').errors?.required || form.get('limitPrice').errors?.min) && this.submitted
          "
        >
          Limit Price
        </label>
        <app-reactive-transfer-amount [control]="form.get('limitPrice')"></app-reactive-transfer-amount>
        <div
          class="text--error"
          *ngIf="(form.get('limitPrice').errors?.required || form.get('limitPrice').errors?.min) && this.submitted"
        >
          Please complete this field
        </div>
      </div>
      <div class="form-group" id="shareAmountGroup">
        <label
          for="shareAmount"
          class="uk-label"
          [class.insufficient]="
            form.get('shareAmount').hasError('insufficient') ||
            form.get('shareAmount').hasError('pendingShares') ||
            ((form.get('shareAmount').errors?.required || form.get('shareAmount').errors?.min) && this.submitted)
          "
        >
          Amount of Shares
        </label>
        <input
          class="uk-input"
          type="number"
          min="0"
          name="shareAmount"
          id="shareAmount"
          formControlName="shareAmount"
          appNumeric
        />
        <div class="text--error" *ngIf="form.get('shareAmount').hasError('insufficient')">
          <ng-container [ngSwitch]="tradeType">
            <span *ngSwitchCase="tradeTypes.Buy">Insufficient Funds</span>
            <span *ngSwitchCase="tradeTypes.Sell">Insufficient Shares</span>
          </ng-container>
        </div>
        <div
          class="text--error pending-shares"
          *ngIf="form.get('shareAmount').hasError('pendingShares') && ordersWithPendingStatus"
        >
          You currently have a pending sale of these shares. In order to make this transaction, please cancel the sale
          that is currently pending using advanced functionality.
        </div>
        <div
          class="text--error"
          *ngIf="(form.get('shareAmount').errors?.required || form.get('shareAmount').errors?.min) && this.submitted"
        >
          Please complete this field
        </div>
      </div>
      <div class="form-group" id="goodUntilGroup" *ngIf="form.get('orderType').value === orderTypes.Limit">
        <label for="goodUntil" class="uk-label">Good Until</label>
        <select name="goodUntil" id="goodUntil" class="uk-input" formControlName="goodUntil">
          <option *ngFor="let option of goodUntilOptions" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="form-group" id="fillTypeGroup">
        <label for="fillType" class="uk-label">Fill Type</label>
        <select name="fillType" id="fillType" class="uk-input" formControlName="fillType">
          <option *ngFor="let option of fillTypeOptions" [value]="option">{{ option }}</option>
        </select>
      </div>
      <div class="form-group">
        <label class="uk-label estimated-label">
          Estimated
          <ng-container [ngSwitch]="tradeType">
            <ng-container *ngSwitchCase="tradeTypes.Buy">Cost</ng-container>
            <ng-container *ngSwitchCase="tradeTypes.Sell">Gain</ng-container>
          </ng-container>
        </label>
        <ng-container [ngSwitch]="tradeType">
          <ng-container *ngSwitchCase="tradeTypes.Buy">
            <app-tooltip
              content="This is the projected amount needed to make your trade, subject to fees and other charges"
            ></app-tooltip>
          </ng-container>
          <ng-container *ngSwitchCase="tradeTypes.Sell">
            <app-tooltip content="This is the net proceeds from the expected sale price of your stock"></app-tooltip>
          </ng-container>
        </ng-container>

        <p class="estimated-cost">
          <ng-container *ngIf="estimatedCost; else emptyEstimatedCost">{{ estimatedCost | currency }}</ng-container>
          <ng-template #emptyEstimatedCost>$ --.--</ng-template>
        </p>
      </div>
      <div class="button-container mt-4 mb-4">
        <button
          class="btn-ok button--lg"
          type="submit"
          id="submitBtn"
          [disabled]="form.get('shareAmount').hasError('pendingShares')"
          *ngIf="!previewingOrder"
        >
          Continue
        </button>
        <app-dot-loader *ngIf="previewingOrder"></app-dot-loader>
      </div>
    </form>
  </div>
  <div class="col-5 ml-4 stock-information">
    <div class="row">
      <div class="col-12 mt-4">
        <div class="stock-symbol stock-information-label">{{ stock.symbol }}</div>
        <div class="stock-name stock-information-value">{{ stock.stockName }}</div>
      </div>
      <div class="col-12 mt-4">
        <div class="stock-current-value-label stock-information-label">Current Value</div>
        <div class="stock-current-value stock-information-value">{{ stock.marketValue | currency }}</div>
      </div>
      <div
        class="col-12 mt-4"
        [class.insufficient]="form.get('shareAmount').hasError('insufficient') && tradeType === tradeTypes.Buy"
      >
        <div class="stock-buying-power-label stock-information-label">
          Buying Power
          <app-tooltip content="This is how much money in your account is available for investing"></app-tooltip>
        </div>
        <div class="stock-buying-power stock-information-value">
          {{ portfolioOverview.buyingPower | currency }}
        </div>
      </div>
      <div
        class="col-12 mt-4"
        *ngIf="tradeType === tradeTypes.Sell"
        [class.insufficient]="form.get('shareAmount').hasError('insufficient')"
      >
        <div class="stock-shares-owned-label stock-information-label">
          Shares Owned
          <app-tooltip content="This is the number of shares in the given stock that you currently own"></app-tooltip>
        </div>
        <div class="stock-shares-owned-value stock-information-value">{{ sharesOwned }}</div>
      </div>
    </div>
  </div>
</div>
<ng-template #loader><app-dot-loader></app-dot-loader></ng-template>
