import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  BILLPAYROUTERHELPER,
  billPayServiceProvider,
  LOAD_USER_PROFILE_HELPER,
} from '@app/bill-pay/ajs-upgraded-provider';
import { STATE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { PartialRecipient } from '@legacy/bill-pay/typings/PartialRecipient';
import { Recipient } from '@legacy/bill-pay/typings/Recipient';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-check-confirmation',
  templateUrl: './check-confirmation.component.html',
  styleUrls: ['./check-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class CheckConfirmationComponent implements OnInit {
  htmlContent = '<h1>HelloWorld</h1>';
  recipient: PartialRecipient;
  detailsTooltip: string = `
  <strong>Electronic recipients</strong>
  are paid instantly, We'll withdraw  the payment the day before your payment is due. Your recipient will receive your payment on its due date.
  <br><br>
  <strong>Check recipients</strong>
  are paid with paper checks. We mail checks 5 business days before your payment's due date. Your money will leave your account when the recipient deposits the check.
  `;
  email: string;
  recipientDetails: Recipient;

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper,
    @Inject(LOAD_USER_PROFILE_HELPER) private readonly loadUserProfileHelper: ILoadUserProfileHelper
  ) {}

  ngOnInit(): void {
    this.recipient = this.billPayRouterHelper.getRecipient();
    this.recipientDetails = this.billPayRouterHelper.getRecipientDetails();
    if (!this.recipientDetails) this.state.go('udb.billPay.searchRecipient');

    this.billPayRouterHelper.clean();
    const profile = this.loadUserProfileHelper.getUserProfile();
    this.email = profile ? profile.primaryEmail : '';
  }

  redirectToSchedule(): void {
    this.state.go('udb.billPay.schedulePayment', { recipient: this.recipientDetails });
  }
}
