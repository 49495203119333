import { AuthorizationsTabState } from './authorization-state.type';

export type AuthorizationsStateType = {
  authorizationsTabState: AuthorizationsTabState[];
  selectedAccountAuthorizations: string | undefined;
};

export const initialAuthorizationsStateType: AuthorizationsStateType = {
  authorizationsTabState: [],
  selectedAccountAuthorizations: undefined,
};
