import { ACCOUNTS } from '../../constants/accounts';

export const InternalAccountActions = {
  loadInternalAccounts(accounts: Array<AccountsPage>) {
    return {
      type: ACCOUNTS.INTERNAL.LOAD,
      payload: accounts,
    };
  },

  addInternalAccounts(accounts: Array<AccountsPage>) {
    return {
      type: ACCOUNTS.INTERNAL.ADD,
      payload: accounts,
    };
  },

  updateInternalAccounts(accounts: Array<AccountsPage>) {
    return {
      type: ACCOUNTS.INTERNAL.UPDATE,
      payload: accounts,
    };
  },

  removeInternalAccount(accountId: number) {
    return {
      type: ACCOUNTS.INTERNAL.REMOVE,
      payload: accountId,
    };
  },

  flushExternalAccounts() {
    return {
      type: ACCOUNTS.INTERNAL.FLUSH,
    };
  },

  accountsLoaded() {
    return {
      type: ACCOUNTS.INTERNAL.ACCOUNTS_LOADED,
    };
  },

  accountsBalanceLoaded() {
    return {
      type: ACCOUNTS.INTERNAL.BALANCE_LOADED,
    };
  },

  accountsInterceptorsLoaded() {
    return {
      type: ACCOUNTS.INTERNAL.INTERCEPTORS_LOADED,
    };
  },
};
