import { AbstractControl } from '@angular/forms';

import { poBoxRegex } from '@app/config/regexes';

/**
 * Validates if the given control's value is a P.O. Box, based on the poBoxRegex.
 *
 * @param control Reactive FormControl.
 *
 * @returns An object containing the value with the error or null.
 */
export function notPoBoxValidator(control: AbstractControl): { [key: string]: any } | null {
  const isPoBox = poBoxRegex.test(control.value);

  return isPoBox ? { pobox: { value: control.value } } : null;
}
