import { ModelSortTypes, ModelsSortDirection } from '../../const';
import { TModelSortType, TSortDirection } from '../../types';
import { TFilterOptionLabels } from './labeled-sort-option.type';
import { HoldingsState } from './models-holding-state.type';

export type Model = {
  id: number | undefined;
  description: string | undefined;
  price: number | undefined;
  quantity: number | undefined;
  marketValue: number | undefined;
  pastDayChangeValue: number | undefined;
  pastDayChangePercent: number | undefined;
  allTimeChangeValue: number | undefined;
  allTimeChangePercent: number | undefined;
  portfolioPercent: number | undefined;
  holdings: HoldingsState[] | undefined;
};

export type ModelsTileState = {
  isSortingEnabled: boolean;
  error: Error | unknown | undefined;
  accountNumber: string | undefined;
  includeHoldings: boolean | undefined;
  limit: number | undefined;
  sortOptions: TFilterOptionLabels[];
  models: Model[];
  sortDirection: TSortDirection | undefined;
  sortType: TModelSortType | undefined;
};

export const initialModelTileState: ModelsTileState = {
  error: undefined,
  accountNumber: undefined,
  isSortingEnabled: false,
  includeHoldings: undefined,
  sortDirection: ModelsSortDirection.Descending,
  sortType: ModelSortTypes.PortfolioPercent,
  limit: undefined,
  models: [],
  sortOptions: [],
};
