import { NgModule } from '@angular/core';
import { ACHOneTimeComponent } from './view/ach-one-time.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ACHOneTimeComponent],
  imports: [CommonModule],
  exports: [ACHOneTimeComponent],
})
export class ACHOneTimeModule {}
