import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { AuthorizationsFacade } from '@app/Areas/AAS/features/account-details/facade/authorizations.facade';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OtherType } from '@app/Areas/AAS/features/account-details/core';
import { BLANK_FORMAT } from './constants';

@Component({
  selector: 'app-authorizations-other',
  templateUrl: './authorizations-other.component.html',
  styleUrls: ['./authorizations-other.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationsOtherComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  authorizationsTabState: OtherType;
  blankFormat = BLANK_FORMAT;

  constructor(private authorizationsFacade: AuthorizationsFacade, private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.authorizationsFacade.authorizationsTabAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(data => {
        this.authorizationsTabState = data?.other;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormattedYesNo(value): string {
    return value != undefined ? (value ? 'Yes' : 'No') : null;
  }
}
