import { InjectionToken } from '@angular/core';

export const BILLPAYSERVICE = new InjectionToken('billPayService');
export const SERVICEHELPER = new InjectionToken<IServiceHelper>('serviceHelper');
export const BILLPAYHELPER = new InjectionToken('billPayHelper');
export const DATERANGEPICKEROPTIONS = new InjectionToken('dateRangePickerOptions');
export const BILLPAYROUTERHELPER = new InjectionToken('billPayRouterHelper');
export const CACHEDACCOUNTSSERVICE = new InjectionToken('cachedAccountsService');

export const MODALSERVICE = new InjectionToken('modalService');
export const ENROLLMENTSERVICE = new InjectionToken('enrollmentService');
export const PAYMENTSSERVICE = new InjectionToken('paymentsService');
export const TIMEOUTSERVICE = new InjectionToken('ITimeoutService');
export const LOAD_USER_PROFILE_HELPER = new InjectionToken('loadUserProfileHelper');

export const billPayServiceProvider = [
  {
    provide: TIMEOUTSERVICE,
    useFactory: (i: any) => i.get('TimeoutService'),
    deps: ['$injector'],
  },
  {
    provide: BILLPAYSERVICE,
    useFactory: (i: any) => i.get('billPayService'),
    deps: ['$injector'],
  },
  {
    provide: SERVICEHELPER,
    useFactory: (i: any) => i.get('serviceHelper'),
    deps: ['$injector'],
  },
  {
    provide: BILLPAYHELPER,
    useFactory: (i: any) => i.get('billPayHelper'),
    deps: ['$injector'],
  },
  {
    provide: DATERANGEPICKEROPTIONS,
    useFactory: (i: any) => i.get('dateRangePickerOptions'),
    deps: ['$injector'],
  },
  {
    provide: BILLPAYROUTERHELPER,
    useFactory: (i: any) => i.get('billPayRouterHelper'),
    deps: ['$injector'],
  },
  {
    provide: CACHEDACCOUNTSSERVICE,
    useFactory: (i: any) => i.get('cachedAccountsService'),
    deps: ['$injector'],
  },
  {
    provide: MODALSERVICE,
    useFactory: (i: any) => i.get('modalService'),
    deps: ['$injector'],
  },
  {
    provide: ENROLLMENTSERVICE,
    useFactory: (i: any) => i.get('enrollmentService'),
    deps: ['$injector'],
  },
  {
    provide: PAYMENTSSERVICE,
    useFactory: (i: any) => i.get('paymentsService'),
    deps: ['$injector'],
  },
  {
    provide: LOAD_USER_PROFILE_HELPER,
    useFactory: (i: any) => i.get('loadUserProfileHelper'),
    deps: ['$injector'],
  },
];
