<div class="request-limit-change" [ngSwitch]="step">
  <div class="step1" *ngSwitchCase="1">
    <app-view-header titleText="Request Limit Change">
      <p class="text-center">Debit Card {{ cardMask }}</p>
    </app-view-header>
    <div class="request-limit-change__content p-2 px-md-5">
      <h3 class="text-center">
        You can request a temporary change to the daily limits for your card.
      </h3>
      <form [formGroup]="requestLimitChangeForm" (ngSubmit)="requestLimitChange()" class="py-3" *ngIf="!loading">
        <app-dot-loader [showWhen]="loading"></app-dot-loader>

        <div class="request-limit-change__form-group my-2" [class.invalid-field]="requestType.hasError('*')">
          <div class="col-12">
            <span class="uk-label">Type of Limit Change</span>
            <app-tooltip [content]="tooltip"></app-tooltip>
          </div>
          <div class="col-12 col-md-6">
            <input type="radio" value="Point Of Sale Limit" formControlName="limitType" id="posType" name="limitType" />
            <label for="posType" class="radio-type">
              Purchase
              <span class="limit">
                (Current Limit:
                <b [innerText]="card.posLimit || 0 | currency"></b>
                )
              </span>
            </label>
          </div>
          <div class="col-12 col-md-6">
            <input
              type="radio"
              value="ATM Withdrawal Limit"
              formControlName="limitType"
              id="atmType"
              name="limitType"
            />
            <label for="atmType" class="radio-type">
              ATM
              <span class="limit">
                (Current Limit:
                <b [innerText]="card.withdrawalLimit || 0 | currency"></b>
                )
              </span>
            </label>
          </div>
          <div aria-live="polite" role="alert" class="text-error col-12" ngxErrors="limitType" #requestType="ngxErrors">
            <span ngxError="required" when="touched" [innerText]="errors.type"></span>
          </div>
        </div>

        <div class="request-limit-change__form-group mb-2" [class.invalid-field]="requestAmount.hasError('*')">
          <div class="col-12 col-md-6">
            <label for="limitAmount" class="uk-label">New Limit Amount</label>
            <input
              type="text"
              id="limitAmount"
              name="limitAmount"
              maxlength="11"
              class="uk-input"
              prefix="$"
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="100000"
              suffix=".00"
              formControlName="limitAmount"
            />
          </div>
          <div
            aria-live="polite"
            role="alert"
            class="text-error col-12"
            ngxErrors="limitAmount"
            #requestAmount="ngxErrors"
          >
            <span ngxError="required" when="touched" [innerText]="errors.amount"></span>
            <span ngxError="number" when="touched" [innerText]="errors.amount"></span>
          </div>
        </div>

        <div class="request-limit-change__form-group mb-2" [class.invalid-field]="requestExplanation.hasError('*')">
          <div class="col-12">
            <label for="explanation" class="uk-label">Reason for Change</label>
            <textarea
              name="explanation"
              id="explanation"
              class="uk-input"
              rows="5"
              maxlength="120"
              formControlName="explanation"
              #explanation
            ></textarea>
          </div>
          <div class="col-12 col-md-6">
            <div
              aria-live="polite"
              role="alert"
              class="text-error"
              ngxErrors="explanation"
              #requestExplanation="ngxErrors"
            >
              <span ngxError="required" when="touched" [innerText]="errors.explanation"></span>
              <span ngxError="minlength" when="touched" [innerText]="errors.explanation"></span>
            </div>
          </div>
          <div class="col-12 col-md-6 text-right">
            <span>{{ explanation.value.length }}/120</span>
          </div>
        </div>

        <div class="request-limit-change__form-group mb-2">
          <div class="col-12 col-md-6">
            <label class="uk-label d-flex align-items-center" for="startDate">
              <button type="button" class="date-icon" (click)="startDatePicker.open()">
                <app-svg-icon [iconName]="iconConfig.calendar.icon" [size]="iconConfig.calendar.size"></app-svg-icon>
              </button>
              <span class="ml-1">Start Date</span>
            </label>
            <input
              [matDatepicker]="startDatePicker"
              [min]="startDate.minDate"
              [max]="startDate.maxDate"
              (click)="startDatePicker.open()"
              type="text"
              name="startDate"
              id="startDate"
              aria-label="startDate"
              class="uk-input date-input"
              readonly
              required
              formControlName="startDate"
              (dateChange)="startDateChange($event)"
            />
            <mat-datepicker #startDatePicker></mat-datepicker>
          </div>
          <div class="col-12 col-md-6 mt-3 mt-md-0">
            <label class="uk-label d-flex align-items-center" for="endDate">
              <button type="button" class="date-icon" (click)="endDatePicker.open()">
                <app-svg-icon [iconName]="iconConfig.calendar.icon" [size]="iconConfig.calendar.size"></app-svg-icon>
              </button>
              <span class="ml-1">End Date</span>
            </label>
            <input
              [matDatepicker]="endDatePicker"
              (click)="endDatePicker.open()"
              [min]="endDate.minDate"
              [max]="endDate.maxDate"
              formControlName="endDate"
              type="text"
              name="endDate"
              id="endDate"
              aria-label="endDate"
              class="uk-input date-input"
              readonly
              required
            />
            <mat-datepicker #endDatePicker></mat-datepicker>
          </div>
        </div>
        <div class="col-12 mt-3 mt-md-0">
          <p class="request-limit-change__note mt-4 p-3">
            <b>Note:</b>
            Approval can take up to
            <b>2 business days</b>.
          </p>
        </div>
        <div class="form__actions mt-4" *ngIf="!busy">
          <button type="submit" class="btn-ok button--lg ng-star-inserted" id="save">Submit</button>
          <button (click)="cancelRequestLimitChange()" type="button" class="btn-cancel button--lg ng-star-inserted">
            Cancel
          </button>
        </div>
        <div class="text-center" *ngIf="busy">
          <app-dot-loader></app-dot-loader>
        </div>
      </form>
    </div>
  </div>
  <div class="step2" *ngSwitchCase="2">
    <app-view-header title="">
      <app-svg-icon
        [iconName]="iconConfig.check.icon"
        [size]="iconConfig.check.size"
        [iconColor]="iconConfig.check.fill"
      ></app-svg-icon>
      <h2>Success</h2>
    </app-view-header>
    <div class="request-limit-change__content">
      <p class="text-center mt-3 mb-5">
        <span>
          A confirmation email has been sent to
          <b>{{ profile.primaryEmail }}</b>
        </span>
      </p>
      <div class="col-12 col-md-6 text-center request-limit-change__details">
        <p class="mb-2"><b>Limit Change Request Details</b></p>
        <table class="table table-striped request-limit-change__table">
          <tbody class="text-bold">
            <tr>
              <td class="text-left">Debit Card</td>
              <td class="text-right">{{ card.debitCardTypeDescription }} {{ cardMask }}</td>
            </tr>
            <tr>
              <td class="text-left">Limit Type</td>
              <td class="text-right">
                <span [innerText]="limitRequest.limitType === 'Point Of Sale Limit' ? 'Purchase' : 'ATM'"></span>
              </td>
            </tr>
            <tr>
              <td class="text-left">Limit Amount</td>
              <td class="text-right">
                <span [innerText]="limitRequest.limitAmount | currency">Limit Amount</span>
              </td>
            </tr>
            <tr>
              <td class="text-left">Effective Dates</td>
              <td class="text-right">
                <span>
                  {{ limitRequest.startDate | date: 'MM/dd/yyyy' }} - {{ limitRequest.endDate | date: 'MM/dd/yyyy' }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p>Approval can take up to 2 business days</p>
      </div>

      <div class="button-box mt-4">
        <a href="#" type="button" class="btn-ok" (click)="cancelRequestLimitChange()">
          Go To Debit Cards
        </a>
        <a id="accounts-link" href="#" class="btn btn-link" (click)="gotoMyAccounts()">
          Go To My Accounts
        </a>
      </div>
    </div>
  </div>
</div>
