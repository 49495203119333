import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ScheduledTransfersFacade } from '../../facade';
import { ScheduledTransferTileState } from '../../core';
import { Uk2Tier1NavigationEnum, Uk2Tier1UtilityEnum } from '@axos/uikit-v2-lib';
import { STATE } from '@core/tokens';
import { getUnixTime } from 'date-fns';
import { SCHEDULED_TRANSFER_LABELS } from './constants';

@Component({
  selector: 'app-scheduled-transfers-tile',
  templateUrl: './scheduled-transfers-tile.component.html',
  styleUrls: ['./scheduled-transfers-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduledTransfersTileComponent implements OnInit, OnDestroy {
  scheduledTransfersTile: ScheduledTransferTileState | undefined;
  isLoading = true;
  labels = SCHEDULED_TRANSFER_LABELS;

  uk2UtilityIcons = Uk2Tier1UtilityEnum;
  uk2NavigationIcons = Uk2Tier1NavigationEnum;

  private destroy$ = new Subject<void>();
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private scheduledTransfersFacade: ScheduledTransfersFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.scheduledTransfersFacade.scheduledTransferTileAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined),
        tap(scheduledTransfers => {
          if (scheduledTransfers.apiCallWasSuccessful) {
            this.setScheduledTransfers(scheduledTransfers);
          }
        })
      )
      .subscribe();

    this.scheduledTransfersFacade.isLoading$
      .pipe(
        takeUntil(this.destroy$),
        tap(isLoading => {
          this.isLoading = isLoading;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToScheduledTransfers() {
    this.state.go('udb.transfers.schedulerTransfers');
  }

  private setScheduledTransfers(scheduledTransfersTile: ScheduledTransferTileState) {
    this.scheduledTransfersTile = scheduledTransfersTile;
    this.scheduledTransfersTile.scheduledTransfers = scheduledTransfersTile.scheduledTransfers
      .sort((a, b) => getUnixTime(a.nextDate) - getUnixTime(b.nextDate))
      .slice(0, 8);
    this.changeDetectorRef.markForCheck();
  }
}
