import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { template } from './date-range-picker-custom.component.html';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() label: string = 'Date Range';
  @Input() isDisabled: boolean;

  hasFilterActive: boolean;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.hasFilterActive = !!this.control.value;
    this.createDatePicker();
    // Will be called by the 'Clean filters' button
    (window as any).cleanCalendarFilters = () => {
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent('cleanFilters', false, false, undefined);
      window.dispatchEvent(evt);
    };
    window.addEventListener('cleanFilters', () => {
      if (this.control.value) {
        this.control.reset();
      } else {
        this.destroyDatePicker();
        this.createDatePicker();
      }
      this.hasFilterActive = false;
    });
    this.control.valueChanges.subscribe(value => {
      if (!value) {
        this.destroyDatePicker();
        this.createDatePicker();
      }
      this.hasFilterActive = !!value;
    });
  }

  private createDatePicker() {
    if (!window.jQuery) return;
    const pickerContainer = $(this.element.nativeElement).find('#date-range-picker') as any;
    pickerContainer.daterangepicker(this.setupDateRange());
    pickerContainer.on('apply.daterangepicker', (_event: any, picker: any) => this.applyFilter(picker));
  }

  private destroyDatePicker() {
    if (!window.jQuery) return;
    const pickerContainer = $(this.element.nativeElement).find('#date-range-picker') as any;
    pickerContainer.data('daterangepicker').remove();
  }

  private setupDateRange() {
    return {
      locale: {
        format: 'MM/DD/YY',
        fromLabel: 'From',
        toLabel: 'To',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: moment.weekdaysShort(),
        monthNames: moment.months(),
      },
      opens: 'center',
      linkedCalendars: true,
      buttonClasses: 'btn-daterange',
      applyClass: 'uk-btn solid secondary lg col-xs-4',
      cancelClass: 'uk-btn outline primary lg col-xs-4',
      ...this.getDateRange(),
      template,
    };
  }

  private applyFilter(picker: any) {
    const modelValue = { min: picker.startDate.toDate(), max: picker.endDate.toDate() };
    this.control.setValue(modelValue);
  }

  private getDateRange(): any {
    const today = new Date();

    const dateRange = {
      startDate: moment(today),
      endDate: moment(today),
      minDate: moment(today).subtract(2, 'years'),
      maxDate: moment(today),
    };

    return dateRange;
  }
}
