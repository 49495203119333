import { Delta } from '@app/Areas/AAS/features/total-value/core/store/enums';
import { ChartColorContext, RiaMovementsColorsPalette } from '../../types';
import { createGradientBackground } from '../create-gradient-background/create-gradient-background.function';

export function createChartColorContext(
  canvasElement: HTMLCanvasElement,
  movementDelta: Delta,
  palettes: {
    [Delta.Gain]: RiaMovementsColorsPalette;
    [Delta.Loss]: RiaMovementsColorsPalette;
    [Delta.NoChange]: RiaMovementsColorsPalette;
  }
): ChartColorContext {
  return {
    backgroundColor: createGradientBackground(canvasElement, palettes[movementDelta].gradient),
    borderColor: palettes[movementDelta].line,
  };
}
