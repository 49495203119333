<div class="date-range-filter" id="date-range-filter-container">
  <button
    id="date-range-picker"
    class="uk-btn lg"
    name="txf-date-range"
    aria-label="Date Range"
    [disabled]="isDisabled"
    [ngClass]="{ active: hasFilterActive }"
  >
    {{ label }}
    <i class="bofi-add"></i>
  </button>
</div>
