import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

import { TransactionsState } from '../types';

const getTransactionsState = createFeatureSelector<TransactionsState>(AasStoreFeatures.Transactions);

export const getSelectedAccountTransactionTile = createSelector(getTransactionsState, state => {
  const { accountsTransactions, selectedAccountTransactions } = state;

  if (selectedAccountTransactions === undefined) {
    return undefined;
  }

  const matchingAccount =
    accountsTransactions.find(account => account.accountNumber === selectedAccountTransactions) ?? undefined;

  return matchingAccount;
});
