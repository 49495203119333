export enum FillTypeDescription {
  AllOrNone = 'All or none',
  Partial = 'Partial Fill',
}

export enum FillType {
  Any = 1,
  Aon = 2,
}

export const FILL_TYPE_MAPPING = {
  [FillTypeDescription.AllOrNone]: FillType.Aon,
  [FillTypeDescription.Partial]: FillType.Any,
};
