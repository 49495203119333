<div class="brand-title">
  <h2 [innerText]="title"></h2>
  <p>
    Already have an account?
    <a id="sign-in" href="#" (click)="goTo.emit('auth.login')">Sign In</a>
  </p>
  <p class="sub-text">
    Business account owners, please enter your Social Security number, not your EIN.
  </p>
</div>

<div class="form-group mt-5">
  <app-alert-notification
    *ngIf="serviceError"
    alertType="error"
    [safeHtml]="serviceError"
    (dismiss)="dismissError()"
  ></app-alert-notification>
</div>

<form class="mt-5 d-flex flex-wrap" [formGroup]="userDataForm" (ngSubmit)="submitData()">
  <!-- 1st Row: Name & Last Name -->
  <div class="col-12 col-md-6 form-group" [class.invalid-field]="firstName.hasError('*')">
    <label class="uk-label" for="firstName">First Name</label>
    <input id="firstName" class="uk-input" type="text" name="firstName" formControlName="firstName" />

    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="firstName" #firstName="ngxErrors">
      <span *ngFor="let error of errors.firstName" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>
  </div>

  <div class="col-12 col-md-6 form-group" [class.invalid-field]="lastName.hasError('*')">
    <label class="uk-label" for="lastName">Last Name</label>
    <input id="lastName" class="uk-input" type="text" name="lastName" formControlName="lastName" />

    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="lastName" #lastName="ngxErrors">
      <span *ngFor="let error of errors.lastName" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>
  </div>
  <!-- 1st Row: Name & Last Name -->

  <!-- 2nd Row:Date of Birth & SSN -->
  <div class="col-12 col-md-6 form-group" [class.invalid-field]="dateOfBirth.hasError('*')">
    <label class="uk-label" for="dateOfBirth">Date of Birth</label>
    <input
      id="dateOfBirth"
      formControlName="dateOfBirth"
      name="dateOfBirth"
      class="uk-input"
      placeholder="MM/DD/YYYY"
      mask="00/00/0000"
      [dropSpecialCharacters]="false"
    />

    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="dateOfBirth" #dateOfBirth="ngxErrors">
      <span *ngFor="let error of errors.dateOfBirth" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>
  </div>

  <div class="col-12 col-md-6 form-group ssn-entry reverse" [class.invalid-field]="ssn.hasError('*')">
    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="ssn" #ssn="ngxErrors">
      <span *ngFor="let error of errors.ssn" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>

    <input
      id="ssnId"
      class="uk-input"
      type="text"
      placeholder="### ## ####"
      formControlName="ssn"
      [hiddenInput]="ssnOptions.hidden"
      [patterns]="ssnOptions.pattern"
      mask="000 00 0000"
    />

    <app-reveal-input-label
      target="ssnId"
      [switchEventOnly]="true"
      (switch)="ssnOptions.hidden = !ssnOptions.hidden"
      tooltip="Please use your personal SSN to register. Business account owners, 
      please enter your Social Security number, not your EIN. 
      If you are a Non-Resident Alien without a valid social security number, please enter zeros."
    >
      Social Security Number
    </app-reveal-input-label>
  </div>
  <!-- 2nd Row:Date of Birth & SSN -->

  <!-- 3rd Row:: Username & Password -->
  <div
    class="col-12 col-md-6 form-group"
    [class.invalid-field]="username.hasError('*') || usernameValidationStatus === 'taken'"
  >
    <label class="uk-label" for="username">Create Username</label>
    <input id="username" class="uk-input" type="text" name="username" formControlName="username" />
    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="username" #username="ngxErrors">
      <span *ngFor="let error of errors.username" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>

    <div
      id="username-message"
      *ngIf="usernameMessage"
      class="mt-1"
      aria-live="polite"
      role="alert"
      style="font-size: 0.875rem;"
      [ngClass]="usernameMessageClass"
      [innerText]="usernameMessage"
    ></div>
  </div>

  <div class="col-12 col-md-6 form-group" [class.invalid-field]="passwordField.hasError('*')">
    <div class="reverse">
      <input class="uk-input" type="password" name="password" id="password" formControlName="password" />
      <app-reveal-input-label target="password">Create Password</app-reveal-input-label>
    </div>

    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="password" #passwordField="ngxErrors">
      <span ngxError="maxlength">
        The password cannot have more than 25 characters.
      </span>
    </div>
    <app-password-strength [passwordField]="userDataForm.get('password')"></app-password-strength>
  </div>
  <!-- 3rd Row:: Username & Password -->

  <!-- Last row: button row -->
  <div class="col-12">
    <button
      *ngIf="!isValidating; else loader"
      type="submit"
      class="btn-ok button--lg"
      title="Next"
      [disabled]="isNextDisabled"
    >
      Next
    </button>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Validating</app-dot-loader>
    </ng-template>
  </div>
  <!-- Last row: button row -->
</form>
