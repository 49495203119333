import {
  AppDefaultPreferences,
  UserPreferenceState,
  UserPreferencesState,
} from '@app/Areas/AAS/aas-shared/features/user-preferences';

export function queryHoldingPreference(
  accountNumber: string,
  preferences: UserPreferencesState,
  name: string
): undefined | AppDefaultPreferences | UserPreferenceState {
  return (
    preferences.userPreferences.find(
      preference => preference.accountNumber.toString() === accountNumber && preference.name === name
    ) ?? preferences.defaultAppPreferences.find(preference => preference.name === name)
  );
}
