import { IQService, IPromise } from 'angular';
import { Inject } from 'decorators/decorators';
import { ModalService } from 'services/modal.service';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { IAccountsService } from 'services/typings/IAccountsService';
import { SharedAccountsFacade } from '@app/Areas/AAS/features/account-details/facade/shared-accounts.facade';
import { filter, map, take, tap } from 'rxjs/operators';

@Inject(
  '$q',
  'accountsService',
  'cachedAccountsService',
  'modalService',
  'env',
  'sharedAccountsFacade'
)
export class ExternalAccountsHelper {
  constructor(
    private readonly qService: IQService,
    private readonly accountsService: IAccountsService,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly modalService: ModalService,
    private readonly env: OlbSettings,
    private readonly sharedAccountsFacade: SharedAccountsFacade
  ) {}

  /** Displays a confirmation modal when trying to remove the account */
  showRemovalConfirmation(account: ExternalAccount): IPromise<any> {
    const deferred = this.qService.defer();

    const warning =
      account.status.toLowerCase() === 'active'
        ? `<br> You will no longer be able to transfer funds to this account through ${this.env.brandName} Online Banking.</p>`
        : '';

    this.modalService
      .show(
        {},
        {
          icon: 'bofi-information',
          bodyText: `<h3>Remove account?</h3><p class="hidden-sm ms-secondary-text">Remove your ${account.bankName} account (${account.accountCategory} * ${account.accountMask}) from ${this.env.brandName} Online Banking?${warning}`,
          cancelText: 'No',
          okText: 'Yes',
        }
      )
      .then(() => {
        account.isRemoving = true;
        if (account.isAuthorizeTransfer) {
          this.sharedAccountsFacade.sharedAccounts$
            .pipe(
              filter(state => !!state),
              take(1),
              map(state => state.sharedAccounts.accounts),
              map(accounts =>
                accounts.find(
                  sharedAccount =>
                    sharedAccount.accountId === account.accountNumber
                )
              ),
              filter(account => !!account),
              map(account => {
                account.advisors.forEach(
                  advisor => (advisor.authorizedTransfer = false)
                );
                return account;
              }),
              map(sharedAccount => ({
                accounts: [sharedAccount],
              })),
              tap(sharedAccountsRequest => {
                this.sharedAccountsFacade.updateSharedAccounts(
                  sharedAccountsRequest
                );
                this.cachedAccountsService.removeExternalAccount(
                  account.externalAccountId
                );
                deferred.resolve(true);
              })
            )
            .subscribe();
        } else {
          this.removeAccount(account.externalAccountId)
            .then(() => {
              this.cachedAccountsService.removeExternalAccount(
                account.externalAccountId
              );
              deferred.resolve(true);
            })
            .catch(e => {
              deferred.reject(e);
              account.isRemoving = false;
            });
        }
      })
      .catch(() => deferred.resolve(false));

    return deferred.promise;
  }

  /** Removes the account currently in edition */
  private removeAccount(externalAccountId: number): IPromise<any> {
    return this.accountsService.deleteExternalAccount(externalAccountId);
  }
}
