<div class="spending-module">
  <div class="spending-header">
    <h3>Monthly Spending</h3>
    <app-svg-icon [iconName]="icons.ArrowRight" width="20.5px" height="29px" [iconColor]="'#4A5560'"></app-svg-icon>
  </div>
  <app-dot-loader [showWhen]="(transactionsLoading | async) || (accountsLoading | async)">
    Loading
  </app-dot-loader>
  <div [class.d-none]="(transactionsLoading | async) || (accountsLoading | async)">
    <div class="spending-subheader">
      <span>
        Your month-to-date total is
        <span class="font-weight-bold">{{ totalSpent | async | currency }}</span>
      </span>
    </div>
    <div class="spending-border d-md-none"></div>
    <div class="container-fluid">
      <div class="spending-body row">
        <div #donutChart class="col-md-8 donut-chart">
          <div class="inside-donut">
            <span>{{ currentMonthYear | date: 'MMMM yyyy' }}</span>
          </div>
          <div class="d-none d-md-block">
            <div [class.d-none]="isEmpty">
              <app-donut-chart
                [height]="300"
                [width]="300"
                [chartjsOptions]="{
                  cutoutPercentage: 63,
                  elements: { arc: { borderWidth: 1 } },
                  plugins: { highlight: { shadowRadius: 12, shadowColor: 'rgba(0, 0, 0, 0.3)' } },
                  responsive: false
                }"
                [categories]="categories | async"
                [showTooltips]="false"
              ></app-donut-chart>
            </div>
          </div>
          <div class="d-md-none">
            <div [class.d-none]="isEmpty">
              <app-donut-chart
                [height]="200"
                [width]="200"
                [chartjsOptions]="{
                  cutoutPercentage: 63,
                  responsive: false
                }"
                [categories]="categories | async"
                [showTooltips]="false"
              ></app-donut-chart>
            </div>
          </div>
          <div [class.d-none]="!isEmpty">
            <img class="empty-donut" src="assets/img/logos/charts/empty-expenses.svg" alt="" />
          </div>
        </div>
        <div class="col-md-4 spending-grid">
          <h5>Top expenses: this month</h5>
          <div class="categories-mini-grid" [class.d-none]="isEmpty">
            <ng-container *ngFor="let category of categories | async; let i = index">
              <div class="category-row row" *ngIf="i <= 3 && category.amount > 0">
                <div class="col-1">
                  <div class="color-circle" [ngStyle]="{ background: category.color }"></div>
                </div>
                <span class="col-9 category-name">{{ category.name }}</span>
              </div>
            </ng-container>
          </div>
          <div class="empty-grid" [class.d-none]="!isEmpty">
            <span>No Account Activity</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
