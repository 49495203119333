import { StatementsAndDocumentsPageComponent } from './../../FrontEnd/src/app/Areas/AAS/features/statements-and-documents/view/statements-and-documents-page/statements-and-documents-page.component';
import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import {
  AxosTradingTransactionsComponent,
  AxosTradingStatementsComponent,
  AxosTradingOverviewComponent,
  ExchangeAgreementIframeComponent,
  ExperienceLevelsComponent,
  FeatureLinkComponent,
  OverviewComponent,
  PositionsResponsiveComponent,
  RightNavigationComponent,
  SearchEquitiesComponent,
  StockDetailComponent,
  StockTransactionDetailsComponent,
  TradeStockComponent,
  TransactionsComponent,
} from '@app/axos-trading/components';
import { TradeStockType } from '@app/axos-trading/enums';

import { AccountDetailsTradingComponent } from './account-details-trading/account-details-trading.component';
import { ExperienceLevels } from './experience-levels/experience-levels.component';
import { TransactionsPageComponent } from '@app/Areas/AAS/features/transactions/view/transactions-page/transactions-page.component';

export const AxosTradingModule = angular
  .module('udb.axosTrading', [])
  .component('accountDetailTrading', AccountDetailsTradingComponent)
  .component('udbExperienceLevels', ExperienceLevels)
  .directive('appAxosTradingTransactions', downgradeComponent({ component: AxosTradingTransactionsComponent }))
  .directive('appAxosTradingStatements', downgradeComponent({ component: AxosTradingStatementsComponent }))
  .directive('appExperienceLevels', downgradeComponent({ component: ExperienceLevelsComponent }))
  .directive('axosTradingOverviewComponent', downgradeComponent({ component: OverviewComponent }))
  .directive('appSearchEquities', downgradeComponent({ component: SearchEquitiesComponent }))
  .directive('appStockDetail', downgradeComponent({ component: StockDetailComponent }))
  .directive('appStockTransactionDetails', downgradeComponent({ component: StockTransactionDetailsComponent }))
  .directive('appTradeStock', downgradeComponent({ component: TradeStockComponent }))
  .directive('appTransactions', downgradeComponent({ component: TransactionsComponent }))
  .directive('appFeatureLink', downgradeComponent({ component: FeatureLinkComponent }))
  .directive('appRightNavigation', downgradeComponent({ component: RightNavigationComponent }))
  .directive('appExchangeAgreementIframe', downgradeComponent({ component: ExchangeAgreementIframeComponent }))
  .directive('appStatementsAndDocumentsPage', downgradeComponent({ component: StatementsAndDocumentsPageComponent }))
  .directive('appTransactionsPage', downgradeComponent({ component: TransactionsPageComponent }))
  .directive('appAxosTradingOverview', downgradeComponent({ component: AxosTradingOverviewComponent }))
  .directive('appPositionsResponsive', downgradeComponent({ component: PositionsResponsiveComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider
        .state('udb.axosTrading', {
          url: '/Accounts/Trading',
          views: {
            content: {
              template: '<div ui-view="trading-content"></div>',
            },
          },
        })
        .state('udb.axosTrading.stocks', {
          url: '/Stocks/:stockId',
          views: {
            'trading-content': {
              template: '<app-stock-detail></app-stock-detail>',
            },
          },
        })
        .state('udb.axosTrading.position', {
          url: '/:accountId/Stocks/:stockId',
          views: {
            'trading-content': {
              template: '<app-stock-detail></app-stock-detail>',
            },
          },
        })
        .state('udb.axosTrading.trade', {
          url: '/:accountId/Stocks/:stockId/Trade',
          views: {
            'trading-content': {
              template: '<app-trade-stock></app-trade-stock>',
            },
          },
          params: {
            tradeType: TradeStockType.Buy,
          },
        })
        .state('udb.axosTrading.trandetails', {
          url: '/transactions/:id',
          views: {
            'trading-content': {
              template: '<app-stock-transaction-details></app-stock-transaction-details>',
            },
          },
          params: {
            reference: '',
          },
        })
        .state('udb.axosTrading.exchangeAgreementPortfolio', {
          url: '/:accountId/Agreement/:type',
          views: {
            'trading-content': {
              template: '<app-exchange-agreement-iframe></app-exchange-agreement-iframe>',
            },
          },
          params: {
            from: 'Portfolio',
            url: '',
          },
        })
        .state('udb.axosTrading.exchangeAgreementIndividual', {
          url: '/:accountId/Stocks/:stockId/Agreement/:type',
          views: {
            'trading-content': {
              template: '<app-exchange-agreement-iframe></app-exchange-agreement-iframe>',
            },
          },
          params: {
            from: 'Individual',
            url: '',
          },
        });
    },
  ]).name;
