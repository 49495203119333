<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-7 col-lg-5 col-xl-4">
      <app-main-portfolio-details [details]="details"></app-main-portfolio-details>
    </div>
  </div>
  <div class="row justify-content-center mt-2">
    <app-portfolio-graph
      class="d-block col-sm-12 col-md-7 col-lg-6 col-xl-6"
      [portfolioData]="chartData"
    ></app-portfolio-graph>
  </div>
  <div class="row justify-content-center mt-2">
    <app-detailed-portfolio
      class="col-sm-12 col-md-10 col-lg-8 col-xl-6"
      [assets]="assets"
      [portfolioDetails]="details"
    ></app-detailed-portfolio>
  </div>
  <div class="row justify-content-center mt-2 risk-description">
    <div class="row col-sm-12 col-md-10 col-lg-8 col-xl-6">
      <div class="col-12">
        <p class="title">Portfolio Risk Type</p>
      </div>
      <div class="col-12">
        <p class="description">{{ riskScoreDescription }}</p>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-4">
    <button class="btn-ok" type="button" id="submitBtn" (click)="save.emit()" *ngIf="!isLoading">
      {{ buttonText }}
    </button>
    <app-dot-loader *ngIf="isLoading"></app-dot-loader>
  </div>
</div>
