import { Component, Input } from '@angular/core';

import { NewsArticle } from '@app/axos-trading/models';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
  @Input() articles: NewsArticle[];

  icons = {
    chevronDown: NavigationIcons.ChevronDown,
  };
}
