<div *ngIf="todContingentBeneficiaries$ | async" class="contingent-beneficiary__container">
  <ng-container *ngIf="(todContingentBeneficiaries$ | async).length > 0; else emptyState">
    <ng-container *ngFor="let contingentBeneficiary of todContingentBeneficiaries$ | async; let i = index">
      <div class="contingent-beneficiary__title">
        Transfer on Death Contingent Beneficiary
        {{ (todContingentBeneficiaries$ | async).length > 1 ? '#' + (i + 1) : '' }}
      </div>
      <div class="accountNumber-container contingent-beneficiary__flex-container">
        <div class="accountNumber-text">Name</div>
        <div class="accountNumber-value">{{ contingentBeneficiary.name ?? blankFormat }}</div>
      </div>
      <div class="contingent-beneficiary__divider"></div>
      <div class="accountType-container contingent-beneficiary__flex-container">
        <div class="accountType-text">Percentage</div>
        <div class="accountNumber-value">{{ contingentBeneficiary.ipPercent + '%' ?? blankFormat }}</div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #emptyState>
  <div class="contingent-beneficiary__title">
    Transfer on Death Contingent Beneficiary
  </div>
  <div class="accountNumber-container contingent-beneficiary__flex-container">
    <div class="accountNumber-text">Not Yet Assigned</div>
  </div>
</ng-template>
