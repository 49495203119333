import { AxosClearingUrlHelper } from '@legacy/services/axos-clearing-urls.service';

export function axosClearingUrlHelperFactory($injector: any) {
  return $injector.get('axosClearingUrlHelper');
}

export const axosClearingUrlHelperProvider = {
  provide: AxosClearingUrlHelper,
  useFactory: axosClearingUrlHelperFactory,
  deps: ['$injector'],
};
