import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { PortfolioAllocationMatModule } from './portfolio-allocation-mat.module';
import { PortfolioAllocationUk2Module } from './portfolio-allocation-uk2.module';

import { portfolioAllocationReducer, initialPortfolioAllocationState } from './core';

@NgModule({
  imports: [
    PortfolioAllocationMatModule,
    PortfolioAllocationUk2Module,
    StoreModule.forFeature(AasStoreFeatures.PortfolioAllocation, portfolioAllocationReducer, {
      initialState: initialPortfolioAllocationState,
    }),
  ],
})
export class PortfolioAllocationModule {}
