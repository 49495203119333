<div class="modal-header hide-cs">
  <div class="icon-background">
    <app-svg-icon
      class="icon"
      [iconName]="icons.exclamationCircle"
    ></app-svg-icon>
  </div>
</div>
<h2>Transfer Request Failed</h2>
<div class="modal-content">
  <p>
    Same-day external transfers are not allowed.
    <br />
    Please refresh your page and retry.
  </p>
</div>
<div class="modal-actions">
  <button class="uk-btn solid secondary lg" (click)="onTryAgain()">
    Try Again
  </button>
</div>
