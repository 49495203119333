import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q')
class AlertsNotificationsService extends BaseService implements IAlertsNotificationsService {
  constructor(
    http: ng.IHttpService,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    q: ng.IQService
  ) {
    super(http, env, 'alert', serviceHelper, q);
  }

  saveAlert(a: Alerts, url: string): ApiResponse<string> {
    if (a.alertId) {
      return this.put(url, a);
    } else {
      return this.post(url, a);
    }
  }

  saveLowBalance(a: Alerts): ApiResponse<string> {
    return this.post('lowbalancealerts', a);
  }

  saveHighBalance(a: Alerts): ApiResponse<string> {
    return this.post('highbalancealerts', a);
  }

  saveLargeWithdrawal(a: Alerts): ApiResponse<any> {
    return this.post('largewithdrawals', a);
  }

  saveLargeDeposit(a: Alerts): ApiResponse<any> {
    return this.post('largedeposits', a);
  }

  saveMaturityDate(a: Alerts): ApiResponse<any> {
    return this.post('maturitydatealerts', a);
  }

  saveCheckCleared(a: Alerts): ApiResponse<any> {
    return this.post('clearedcheckalerts', a);
  }

  saveLoanDueDate(a: Alerts): ApiResponse<any> {
    return this.post('loanduedatealerts', a);
  }

  saveOverDueDate(a: Alerts): ApiResponse<any> {
    return this.post('overduedatealerts', a);
  }

  deleteAlert(alertId: string): ApiResponse<any> {
    return this.delete(alertId);
  }

  getAlerts(): ApiResponse<Alerts[]> {
    return this.get('alerts');
  }

  getAlertsHistory(startDate?: string, endDate?: string): ApiResponse<AlertHistory[]> {
    if (startDate.length < 1) return this.get('alertshistorylogs');
    else return this.get(`alertshistorylogs/${startDate}/${endDate}`);
  }

  configureAlertsNotification(
    alertsNotificationSetting: AlertsNotificationSetting
  ): ApiResponse<any> {
    return this.put('configurenotifications', alertsNotificationSetting);
  }

  getToAccounts(accountId: number): ApiResponse<TypesAccounts> {
    return this.get(`accounts/elegible/to/${accountId}`);
  }

  getSmsTermsPdf(): ApiResponse<any> {
    return this.get('smsTermsPdf');
  }

  getBrandContactInfo(): ApiResponse<any> {
    return this.get('contactInformation');
  }
}

export { AlertsNotificationsService };
