import { Injectable } from '@angular/core';

import { ExternalTransaction, Transaction } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccountAggregationChartFormatterService {
  /** Return the min value in an array except for null */
  maxValue(array: number[]): number {
    return Math.max(...array.filter(v => v !== null));
  }

  /** Return the min value in an array except for null */
  minValue(array: number[]): number {
    return Math.min(...array.filter(v => v !== null));
  }

  /**
   * Return the nearest decene of the number
   * @param n Value
   */
  roundToNearest10(n: number): number {
    return Math.round(n / 10) * 10;
  }

  /**
   * Returns an array generated from "start" to "end" with steps of size "step"
   * @param start : number
   * @param end : number
   * @param step : number
   */
  range(start: number, end: number, step: number = 1) {
    let len = Math.round((end - start) / step) + 1;
    if (!len) len = 0;

    return Array(len)
      .fill(null)
      .map((_: any, idx: number) => start + idx * step);
  }

  /**
   * Returns transactions between the period of time
   * txArray
   * startDate
   */
  filtertTransactionsByDate(txArray: Transaction[], startDate: Date, endDate: Date = new Date()): Transaction[] {
    const resultArray = txArray.filter(tx => {
      const txDate = new Date(tx.postedDate.toString());

      return startDate <= txDate && txDate < endDate;
    });

    return resultArray;
  }

  /**
   * Function that returns now first date of month + months + years
   * Can pass negative values
   * months
   * years
   */
  getFilterDate(months: number, years: number): Date {
    const date = new Date();
    const resultDate = new Date(date.getFullYear() + years, date.getMonth() + months, 1);

    return resultDate;
  }

  /**
   * Return number of days in given month
   * JS months Date is 0 index array
   * month
   * year
   */
  daysInMonth(month: number, year: number) {
    return new Date(year, month + 1, 0).getDate();
  }

  /**
   * Return number of days in given month by Date
   * date
   */
  daysInMonthByDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  /**
   * Group transactions when key is the result of the callback cb
   * arr
   * cb
   */
  groupByTransactions(arr: Transaction[], cb: (element: Transaction) => number): { [key: number]: Transaction[] } {
    const res: any = {};
    for (const el of arr) {
      const key: number = cb(el);
      (res[key] || (res[key] = [])).push(el);
    }

    return res;
  }
  /**
   * Sum values in each array of transactions inside the object
   * obj
   */
  sumTransactionArray(obj: { [key: number]: Transaction[] }): { [key: number]: number } {
    const res: any = {};
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const array: Transaction[] = obj[prop];
        const sum = array.reduce(
          (accumulator, current) => accumulator + Math.abs(typeof current.amount !== 'undefined' ? current.amount : 0),
          0
        );
        res[prop] = sum;
      }
    }

    return res;
  }

  /**
   * Sum values in each array of transactions inside the object
   * obj
   */
  sumSignedTransactionArray(obj: { [key: number]: ExternalTransaction[] }): { [key: number]: number } {
    const res: any = {};
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const array: ExternalTransaction[] = obj[prop];
        const sum = array.reduce(
          (accumulator, current) =>
            accumulator + (typeof current.signedAmount !== 'undefined' ? current.signedAmount : current.amount),
          0
        );
        res[prop] = sum;
      }
    }

    return res;
  }

  /**
   * Sum every amount with the previous one and so to calculate the running amount of the transactions in that period
   * obj
   */
  getRunningTransactionAmount(obj: { [key: number]: number }) {
    const res: any = {};
    let runningAmount = 0;
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        runningAmount += obj[prop];
        res[prop] = runningAmount;
      }
    }

    return res;
  }

  /**
   * Gets formatted data to show in the chart line
   * data
   * startDate
   * fillFullMonth
   */
  getFormatDataForMonth(data: { [key: number]: number }, startDate: Date, fillFullMonth: boolean = false): number[] {
    const daysInMonth = this.daysInMonth(startDate.getMonth(), startDate.getFullYear());
    const resultData = Array(daysInMonth).fill(null);
    // Initialize the first element to 0
    resultData[0] = 0;

    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        resultData[+prop - 1] = data[prop];
      }
    }

    let lastValidValue = resultData[0];
    let prevWasNull = false;
    resultData.forEach((element: number, index: number, array: number[]) => {
      if (element !== null) {
        if (prevWasNull) {
          array[index - 1] = lastValidValue;
        }
        lastValidValue = element;
      }

      prevWasNull = element === null;
    });

    const lastDayIndex = (fillFullMonth ? daysInMonth : new Date().getDate()) - 1;
    resultData[lastDayIndex] = lastValidValue;

    return resultData;
  }

  /**
   * Gets the sum of all the values in the object
   * obj
   */
  sumObjectValues(obj: { [key: number]: number }) {
    let sum = 0;
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        sum += obj[prop];
      }
    }

    return sum;
  }

  /**
   * Gets the abbreviated name month of the date passed
   * @param date Pass the date to take the month if not actual date is taken
   */
  getShortMonthName(date?: Date) {
    if (!date) {
      date = new Date();
    }

    return date.toLocaleDateString(undefined, { month: 'short' });
  }
}
