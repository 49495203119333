export class PartialRecipient {
  paymentAccount?: string;
  billerName?: string;
  billerId?: number;
  billerGroupId?: number;
  addressLine?: string;
  addressLine2?: string;
  city?: string = '';
  stateCode?: string = '';
  zipCode?: string = '';
  country?: string = '';
  nickName?: string;
  paymentAccountRepeated?: string;
  email?: string;
  areaCode?: string;
  payeeName?: string;
  number?: string;
  prefix?: string;
  payeeId?: number;
  isElectronic?: boolean;
  ebillStatus?: number;
  eStatementsStatus?: boolean;
}
