import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

import { SharedModule } from '@shared/shared.module';

import { transactionsComponents } from './components';
import { AccountAggregationChartFormatterService } from './services';
@NgModule({
  declarations: [...transactionsComponents],
  imports: [CommonModule, SharedModule, MatChipsModule],
  providers: [CurrencyPipe, DatePipe, AccountAggregationChartFormatterService],
  exports: [...transactionsComponents],
})
export class TransactionsModule {}
