import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LEGACY_CACHED_ACCOUNTS_SERVICE,
  LEGACY_CUSTOMER_LIMITS_HELPER,
  LEGACY_FEATURE_FLAG_SERVICE,
  LEGACY_FILTER,
  LEGACY_LOAN_ACCOUNT_HELPER,
  LEGACY_LOAN_SERVICE,
  LEGACY_ROOT_SCOPE,
  LEGACY_SERVICE_HELPER,
  LEGACY_TRANSACTION_SERVICE,
} from '@app/transfers/ajs-upgraded-providers';
import { FrequencyEnum, SendUntilOption } from '@app/transfers/enums';
import { EditLoanPaymentModalData, TransferSchedule } from '@app/transfers/models';
import { SchedulerTransferMappings } from '@app/transfers/utils/mappings';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { CustomerLimitsHelper } from '@legacy/services/customer-limits.helper';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { LoanAccountHelper } from '@legacy/services/loan-account.helper';
import { ILoanService } from '@legacy/services/typings/ILoanService';
import { ITransactionService } from '@legacy/services/typings/ITransactionService';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { IFilterService } from 'angular';
// ! DO NOT DELETE THIS IS JUST FOR UNIT TESTING -> JQUERY RELATED
import * as moment from 'moment';
import { DatePickerReferenceHandler } from '@app/transfers/utils';
import { DatePickerEvents } from '@shared/models';
declare var $: any;
/* istanbul ignore next */
@Component({
  selector: 'app-edit-loan-payment-modal',
  templateUrl: './edit-loan-payment-modal.component.html',
  styleUrls: ['./edit-loan-payment-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditLoanPaymentModalComponent implements OnInit {
  isLoadingSetup = false;
  datePickerOptions: any;
  expirationDatePickerOptions: any;
  datePicker: any;
  title: string;
  sendUntilOptions: GenericOption[];
  showNumberOfTransfers: boolean;
  showExpirationDate: boolean;
  isNumberOfTransfersValid: boolean;
  loanDetails: OlbAccount;
  notificationErrorMsg: string;
  isTodayValidForExternal: boolean;
  isPayoff: boolean;
  isEndDateValid: boolean;
  payoffMessage: string;
  disableAdditionalPrincipal = false;
  private _customerLimits: CustomerLimits;
  private _isLoanFeatureEnabled: boolean;
  dateStartHandler!: DatePickerReferenceHandler;
  dateEndHandler!: DatePickerReferenceHandler;
  get totalAmount(): number {
    return +this.transferToEdit.amount + +this.transferToEdit.additionalPrincipal;
  }
  errorMsg: string;
  constructor(
    @Inject(LEGACY_ROOT_SCOPE) public readonly _rootScope: ng.IRootScopeService,
    @Inject(LEGACY_TRANSACTION_SERVICE) public _transactionService: ITransactionService,
    @Inject(LEGACY_LOAN_SERVICE) public _loanService: ILoanService,
    @Inject(LEGACY_SERVICE_HELPER) public _serviceHelper: IServiceHelper,
    public dateHelper: DateHelper,
    @Inject(LEGACY_CUSTOMER_LIMITS_HELPER) public customerLimitsHelper: CustomerLimitsHelper,
    @Inject(LEGACY_FEATURE_FLAG_SERVICE) public _featureFlagService: FeatureFlagService,
    @Inject(LEGACY_FILTER) public filterService: IFilterService,
    @Inject(LEGACY_CACHED_ACCOUNTS_SERVICE) public _cachedService: CachedAccountsService,
    @Inject(LEGACY_LOAN_ACCOUNT_HELPER) public loanAccountHelper: LoanAccountHelper,
    public dialogRef: MatDialogRef<EditLoanPaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogContext: EditLoanPaymentModalData
  ) {}

  public get transferToEdit(): TransferSchedule {
    return this.dialogContext.transferToEdit;
  }

  public get isSeries(): boolean {
    return this.dialogContext.isSeries;
  }

  public get frequencies(): GenericOption[] {
    return this.dialogContext.frequencies;
  }

  public get _dateRangePickerOptions() {
    return this.dialogContext.dateRangePickerOptions;
  }

  ngOnInit(): void {
    this._checkCustomerLimits();
    this.LoadDatePickerOptions();
    this.transferToEdit.newNextTransferDate = this.transferToEdit.nextTransferDate;
    this.transferToEdit.oldLastTransferDate = this.transferToEdit.lastTransferDate;
    this.transferToEdit.oldAmount = this.transferToEdit.amount;
    this.transferToEdit.oldFrequency = this.transferToEdit.frequency;
    this.transferToEdit.oldBeginSending = this.transferToEdit.beginSendingOn;
    this.transferToEdit.oldAdditionalPrincipal = this.transferToEdit.additionalPrincipal;
    this.transferToEdit.oldNumberOfTransfers = this.transferToEdit.numberOfTransfers;
    this.transferToEdit.oldSendUntil = this.transferToEdit.sendUntilOptions;
    this.dateStartHandler = new DatePickerReferenceHandler(this.transferToEdit.newNextTransferDate);
    this.dateEndHandler = new DatePickerReferenceHandler(this.transferToEdit.lastTransferDate);

    this.errorMsg = null;
    this.isEndDateValid = true;
    this.validateEndDate();
    this.title = this.isSeries
      ? 'Edit series'
      : this.transferToEdit.frequency == FrequencyEnum.ONETIME
      ? 'Edit scheduled transfer'
      : 'Edit next transfer';

    if (this._rootScope['loanFlagAvailable']) {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
      this.setCalendarOptions();
    }
    this._rootScope.$on('loanFlagAvailable', () => {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
      this.setCalendarOptions();
    });
    this._loanFieldsInitialization();
    ({ payOff: this.isPayoff, payOffMessage: this.payoffMessage } = this.loanAccountHelper.isPayoff(
      this.loanDetails,
      this.totalAmount
    ));
  }
  saveTransfer() {
    if (this.validateAmount() && this.validateNumberOfTransfers() && this.isEndDateValidated()) {
      const { isLate, lateMessage } = this.loanAccountHelper.isPaymentLate(this.loanDetails.loanDueDate);
      const { isMature, matureMessage } = this.loanAccountHelper.isAccountMature(this.loanDetails);
      const { payOff, payOffMessage } = this.loanAccountHelper.isPayoff(this.loanDetails, this.totalAmount);
      if (isLate || isMature || payOff) {
        this.notificationErrorMsg = isLate ? lateMessage : isMature ? matureMessage : payOffMessage;

        return;
      }
      this.transferToEdit.numberOfTransfers |= 0;
      this.isLoadingSetup = true;
      switch (this.transferToEdit.scheduledTransferType) {
        case 0: // Internal
          this._editInternalTransfer();
          break;
        case 1: // ACH
          this._editExternalTransferFromAxosScheduler();
          break;
        default:
          // Payveris
          if (!this._isLoanFeatureEnabled) this._editExternalTransferFromPayveris();
          // Do conversion
          else this._convertToAxosScheduler();
          break;
      }
    }
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  showCalendar(element: string): void {
    const dp = $('#' + element);
    if (!dp.data('daterangepicker').isShowing) {
      dp.data('daterangepicker');
      dp.data('daterangepicker').show();
    } else {
      dp.data('daterangepicker').hide();
    }
  }

  validateAmount(): boolean {
    if (this.totalAmount > 0) {
      this.errorMsg = null;

      return true;
    }
    this.errorMsg = 'Please enter Amount';

    return false;
  }

  sendUntilChange(): void {
    this.showNumberOfTransfers = this.transferToEdit.sendUntilOptions === SendUntilOption.NumberOfTransfers;
    this.showExpirationDate = this.transferToEdit.sendUntilOptions === SendUntilOption.LastTransferDate;
    if (!this.showNumberOfTransfers) this.transferToEdit.numberOfTransfers = null;
  }

  validateNumberOfTransfers(): boolean {
    if (
      this.showNumberOfTransfers &&
      (!this.transferToEdit.numberOfTransfers || this.transferToEdit.numberOfTransfers <= 0)
    ) {
      this.isNumberOfTransfersValid = false;
    } else {
      this.isNumberOfTransfersValid = true;
    }

    return this.isNumberOfTransfersValid;
  }

  //#region Private methods

  private _loanFieldsInitialization() {
    this.isLoadingSetup = true;
    this.sendUntilOptions = [
      {
        value: SendUntilOption.IChooseToStop,
        label: SchedulerTransferMappings.mapSendUntil(SendUntilOption.IChooseToStop),
      },
      {
        value: SendUntilOption.NumberOfTransfers,
        label: SchedulerTransferMappings.mapSendUntil(SendUntilOption.NumberOfTransfers),
      },
      {
        value: SendUntilOption.LastTransferDate,
        label: 'Expiration Date',
      },
    ];
    this.sendUntilChange();
    this.isNumberOfTransfersValid = true;
    this._getLoanDetails();
  }

  private _getLoanDetails(): void {
    this.loanDetails = this._cachedService.getLoanAccount(this.transferToEdit.accountIdTo);
    // If it comes from Payveris, we need to split the total amount
    // We try to cover the monthly payment and, if any diference, will be sent to additional principal
    if (this.transferToEdit.scheduledTransferType == 2) {
      if (this.transferToEdit.totalAmount < this.loanDetails.paymentAmount) {
        // This is for the cases when the old payveris transfer was made to pay only principal
        this.transferToEdit.additionalPrincipal = this.transferToEdit.totalAmount;
        this.transferToEdit.amount = 0;
      } else {
        this.transferToEdit.additionalPrincipal = +(
          this.transferToEdit.totalAmount - this.loanDetails.paymentAmount
        ).toFixed(2);
        this.transferToEdit.amount = this.loanDetails.paymentAmount;
      }
      this.transferToEdit.oldAmount = this.transferToEdit.amount;
      this.transferToEdit.oldAdditionalPrincipal = this.transferToEdit.additionalPrincipal;
    }
    // If it's a regular payment, disable the additional principal
    if (this.transferToEdit.totalAmount == this.loanDetails.paymentAmount) {
      this.disableAdditionalPrincipal = true;
    }
    this.isLoadingSetup = false;
  }
  /**
   * Fetch customer limits
   */
  private async _checkCustomerLimits(): Promise<void> {
    await this.customerLimitsHelper
      .fetchCustomerLimits()
      .then(res => {
        this._customerLimits = res;
      })
      .catch(err => {
        this._serviceHelper.errorHandler(err);
      });
  }

  /**
   * Shows an error notification if transfer exceeded daily limits for the user
   * this message changes whether the transfer is incoming/outgoing
   */
  private _showAmountExceededErrorMessage() {
    this.notificationErrorMsg = `Transfer amount exceeds daily inbound limit of
                                ${this.filterService('currency')(this._customerLimits.extTransfersMaxIncomingAmount)}`;
  }

  /**
   * This should return the model used for external transfers in payveris scheduler
   * @param trans The transfer object to be converted
   */
  private _toExternalTransferRequest(trans: TransferSchedule): ExternalAccountEditRequest {
    return {
      Amount: trans.amount + +trans.additionalPrincipal,
      ExternalAccountId: trans.externalAccountId,
      Frequency: trans.frequency,
      InternalAccountCode: trans.internalAccountCode,
      ScheduledTransferId: trans.scheduledTransferId,
      AccountCode: trans.internalAccountCode,
      ConfirmationNumber: 0,
      ConfirmationNumberspecified: 0,
      InternalAccountId: 0,
      NumberOfTransfers: trans.numberOfTransfers,
      RemainingTransfers: trans.numberOfTransfers,
      TransferType: trans.externalTransferType,
      FromNickName: trans.accountNicknameFrom,
      ToNickName: trans.accountNicknameTo,
      ProcessingDate: trans.newNextTransferDate,
      AccountNumberFrom: trans.accountNumberFrom.toString(),
      AccountNumberTo: trans.accountNumberTo.toString(),
    };
  }

  private async _editExternalTransferFromPayveris(): Promise<void> {
    const serviceMethod = this.isSeries ? 'scheduleEditExternalTransfer' : 'scheduleEditNextExternalTransfer';
    const externalTransfer = this._toExternalTransferRequest(this.transferToEdit);
    await this._transactionService[serviceMethod](externalTransfer)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        if (err.data.message.includes('amount exceeds maximum customer limit')) {
          return this._showAmountExceededErrorMessage();
        }

        this._serviceHelper.errorHandler(err);
      })
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  private async _editExternalTransferFromAxosScheduler(): Promise<void> {
    const serviceMethod = this.isSeries ? 'editACHTransferSeries' : 'editNextACHTransfer';
    await this._loanService[serviceMethod](this.transferToEdit)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this._serviceHelper.errorHandler(err);
      })
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  private async _editInternalTransfer(): Promise<void> {
    const serviceMethod = this.isSeries ? 'scheduleEditInternalTransfer' : 'scheduleEditNextInternalTransfer';
    await this._transactionService[serviceMethod](this.transferToEdit)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        this._serviceHelper.errorHandler(err);
      })
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  private async _convertToAxosScheduler(): Promise<void> {
    const serviceMethod = this.isSeries ? 'convertExternalSeries' : 'convertNextExternalTransfer';
    await this._loanService[serviceMethod](this.transferToEdit)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        if (err.data.message.includes('amount exceeds maximum customer limit')) {
          return this._showAmountExceededErrorMessage();
        }

        this._serviceHelper.errorHandler(err);
      })
      .finally(() => {
        this.isLoadingSetup = false;
      });
  }

  /** Changes the calendar options depending on the loan feature flag
   */
  private setCalendarOptions(): void {
    this.isTodayValidForExternal = true;

    let today = this.dateHelper.normalizeDate(moment());
    const externalTransferValidDate = this.dateHelper.getDefaultPaymentDate();

    if (externalTransferValidDate.diff(today, 'days') > 0) {
      today = externalTransferValidDate;
    }

    this.datePickerOptions.minDate = today;
    // Allow selection 13 months in advance
    this.datePickerOptions.maxDate = this.dateHelper.normalizeDate(moment()).add(13, 'M');
  }

  setDateStart($event: DatePickerEvents): void {
    this.transferToEdit.newNextTransferDate = $event.picker.startDate.toDate();
    this.transferToEdit.beginSendingOn = $event.picker.startDate.toDate();
    this.isTodayValidForExternal = true;
    this.validateEndDate();
  }

  setDateEnd($event: DatePickerEvents): void {
    this.transferToEdit.lastTransferDate = $event.picker.startDate.utc().toDate();
    this.validateEndDate();
  }

  private LoadDatePickerOptions(): void {
    this.datePickerOptions = {
      ...this._dateRangePickerOptions,
      minDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'show.daterangepicker': (_evt: any) => {
          this.setPropertiesForCalendar();
        },
      },
      isInvalidDate: (date: any) => {
        return !this.dateHelper.isValidDate(date); // Disable weekends and Holidays when paying a Loan
      },
    };

    this.expirationDatePickerOptions = {
      ...this._dateRangePickerOptions,
      minDate: moment().add(1, 'days').toDate(),
      maxDate: this.getMaxDate(),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'show.daterangepicker': (_evt: any) => {
          this.setPropertiesForCalendar();
        },
      },
      isInvalidDate: (date: any) => {
        return !this.dateHelper.isValidDate(date); // Disable weekends and Holidays when paying a Loan
      },
    };
  }

  private getMaxDate(): moment.Moment {
    return moment().add(13, 'M');
  }

  private validateEndDate(): void {
    if (this.transferToEdit.lastTransferDate !== null && this.transferToEdit.newNextTransferDate !== null) {
      this.isEndDateValid = moment(this.transferToEdit.lastTransferDate).isAfter(
        this.transferToEdit.newNextTransferDate
      );
    }
  }

  private isEndDateValidated(): boolean {
    return this.isEndDateValid || !this.showExpirationDate;
  }

  setPropertiesForCalendar() {
    const input = $('#date').first();
    const daterangepicker = $('.daterangepicker');

    daterangepicker.css('left', input.offset().left + input.outerWidth() + 10);
    daterangepicker.css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    daterangepicker.append('<div id="arrow-left"></div>');
    daterangepicker.addClass('no-after');

    $('#arrow-left')
      .css('position', 'absolute')
      .css('left', '-8px')
      .css('width', '0')
      .css('height', '0')
      .css('border-top', '8px solid transparent')
      .css('border-bottom', '8px solid transparent')
      .css('border-right', '8px solid darkgray')
      .css('top', daterangepicker.outerHeight() / 2);

    $(document).on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });

    $('.modal-body').on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });
  }
}
