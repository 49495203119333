import { ITimeoutService } from 'angular';
import { Inject } from 'decorators/decorators';
import { MilestoneType } from '../../../common/enums/milestoneType.enum';

interface MilestoneSetting {
  type: number;
  image: string;
  color: string;
}

@Inject('$timeout')
export class PercentLoaderController {
  percent: number = 0;
  milestoneType: number;

  image: any;
  color: string;
  circumference: number;
  progress: number = 0;
  isWallet: boolean;

  // This is the 100% of the loader
  private readonly maxCircumference: number = 210;
  private readonly radius: number = 41;
  private readonly waitInSeconds: number = 1500;

  private readonly milestoneSettings: MilestoneSetting[] = [
    { type: 0, image: 'assets/milestone-retirement.svg', color: '#FAA74A' },
    { type: 1, image: 'assets/milestone-rainyday.svg', color: '#9B61CC' },
    { type: 2, image: 'assets/milestone-save-cash.svg', color: '#43BDA5' },
    { type: 3, image: 'assets/milestone-newhome.svg', color: '#F05F5B' },
    { type: 4, image: 'assets/milestone-newcar.svg', color: '#4BA9D6' },
    { type: 5, image: 'assets/milestone-build_wealth_r.svg', color: '#614244' },
    { type: 6, image: 'assets/milestone-custom.svg', color: '#1D376B' },
    { type: 7, image: 'assets/milestone-wallet.svg', color: '#1E3860' },
  ];

  constructor(private readonly timeout: ITimeoutService) {
    this.circumference = this.radius * 2 * Math.PI;
  }

  $onChanges(changes: any): void {
    if (changes.percent && changes.percent.currentValue) {
      this.timeout(() => this.calculateProgress(changes.percent.currentValue), this.waitInSeconds);
    }
    this.setMilestoneType();
  }

  private calculateProgress(currentProgress: number): void {
    this.progress = (Math.round(currentProgress) * this.maxCircumference) / 100;
  }

  private setMilestoneType(): void {
    let milestone = this.milestoneSettings.find((m: MilestoneSetting) => m.type == this.milestoneType);
    this.image = milestone.image;
    this.color = milestone.color;
    this.percent = Math.round(this.percent);
    this.isWallet = this.milestoneType == MilestoneType.Wallet;
  }
}
