<div class="frow center margin" ui-view="beneficiares-list">
  <div class="fcol-cs-12 fcol-sm-10 fcol-lg-8 beneficiaries">
    <div class="header-section-title text-center">
      <div>
        <h1>Beneficiaries</h1>
        <p class="hide-cs hide-xs">Modify current beneficiaries or add new beneficiary.</p>
      </div>
    </div>
    <div class="fcol-cs-12">
      <a class="back-btn link" (click)="goBack()">
        <i class="fa fa-chevron-left"></i>
        <span>Back</span>
      </a>
    </div>
    <div class="frow accounts">
      <p class="account-nickname nickname" *ngIf="account.statusCode == '2'">{{ account.nickname }}</p>
      <app-uk-dropdown
        [dropdownItems]="accountItems"
        class="frow"
        (onSelect)="onSelectAccount($event)"
        [selectedItem]="selectedAccount"
        *ngIf="account.statusCode != '2'"
      ></app-uk-dropdown>
    </div>
    <div class="frow no-wrap">
      <table class="table ut-table">
        <thead class="">
          <tr>
            <th>Name</th>
            <th>Date of Birth</th>
            <th>Relationship</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!beneficiaries || beneficiaries.length == 0">
            <td class="no-beneficiaries" colspan="4">
              There are no Beneficiaries for the selected Account
            </td>
          </tr>
          <tr *ngFor="let beneficiary; in: beneficiaries">
            <td>{{ beneficiary.name }}</td>
            <td>{{ beneficiary.identity }}</td>
            <td>{{ beneficiary.relationship ? beneficiary.relationship : 'Not defined' }}</td>
            <td>
              <button type="button" class="btn__link" (click)="editBeneficiary(beneficiary)">
                <i class="bofi-edit edit"></i>
                EDIT
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="frow center">
      <div class="fcol-md-4">
        <div class="text-center">
          <button (click)="redirectToPage(accountId, beneficiaries)" type="button" class="uk-btn solid secondary sm">
            Add a Beneficiary
          </button>

          <div class="fcol-md-12">
            <a class="finline middle link" (click)="goBack()">
              <span>Cancel</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="beneficiaries__acknowledgment">
      <div class="frow">
        <div class="group--form fcol-md-12 fcol-cs-12">
          <span>Beneficiary Acknowledgment:</span>
          <span>
            Accounts held in the names of two or more persons (Account Owners) will become payable to the designated
            Payable on Death beneficiary or beneficiaries only upon the death of all account owners. Where there is more
            than one beneficiary, the funds in your account will be paid equally to each of the beneficiaries.
            <br />
            <br />
            The share of any beneficiary who predeceases the account owner(s) will be paid to the remaining beneficiary
            or beneficiaries, if there are any. You may change your designated beneficiary or beneficiaries at any time.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
