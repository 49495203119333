<form [formGroup]="downloadForm" (ngSubmit)="onSubmit()">
  <!-- Account dropdown -->
  <div class="row form-group">
    <div class="col-md-12">
      <label for="accounts" class="uk-label accounts-label">Accounts</label>
      <div class="dropdown-wrapper">
        <select formControlName="accountId" class="uk-input">
          <option *ngFor="let account of accounts" [value]="account.id">
            {{ account.nickname.replace('**', ' - ') }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <!-- Transaction Period dropdown -->
  <div class="row form-group">
    <div class="col-md-12">
      <label for="transactionPeriod" class="uk-label">Transaction Period</label>
      <div class="dropdown-wrapper">
        <select formControlName="transactionPeriod" class="uk-input">
          <ng-container *ngFor="let option of transactionPeriodOptions">
            <option value="" disabled selected>Select a transaction period</option>
            <option [value]="option.value">{{ option.label }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>

  <app-period-options
    *ngIf="downloadForm.get('transactionPeriod').value"
    formControlName="optionSelected"
    [selectedPeriod]="downloadForm.get('transactionPeriod').value"
    [isDisabled]="submittingForm"
    (updateOptionSelected)="onOptionSelected($event)"
  ></app-period-options>

  <!-- File format dropdown -->
  <div class="row form-group">
    <div class="col-md-12">
      <label for="fileFormats" class="uk-label">File Format</label>
      <div class="dropdown-wrapper">
        <select formControlName="fileFormat" class="uk-input">
          <ng-container *ngFor="let file of nwFileFormats">
            <option value="" disabled selected>Select a file format</option>
            <option [value]="file.fileFormat">{{ file.description }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>

  <div class="container-button">
    <button type="submit" class="uk-btn solid secondary lg" [disabled]="submittingForm || downloadForm.invalid">
      <span>{{ submittingForm ? 'Downloading...' : 'Download Transactions' }}</span>
    </button>
  </div>
</form>
