import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { confirmInfoIsUpdated, loadOutdatedContactInfo } from '@app/user-profile/store/actions';
import { OlbSettings, UDBServiceResult } from '@core/models';
import { olbSettings } from '@core/tokens';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class OutdatedContactInfoService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient, private store: Store) {
    super(http, settings, 'outdatedContactInfo');
  }

  /**
   * Checks if the user information is outdated and needs further action.
   *
   * @returns An Observable containing the result of the operation.
   */
  isUserInfoOutdated() {
    return this.get<UDBServiceResult<boolean>>('isUserInfoOutdated').pipe(
      map(({ data }) => data.value),
      tap(isOutdated => {
        this.store.dispatch(loadOutdatedContactInfo({ isOutdated }));
      })
    );
  }

  /**
   * Notifies that the current user information is correct and up-to-date.
   *
   * @returns An Observable containing the result of the operation.
   */
  confirmContactInfoIsUpdated() {
    return this.put<UDBServiceResult<null>>('infoIsUpdated').pipe(
      tap(() => {
        this.store.dispatch(confirmInfoIsUpdated());
      })
    );
  }

  /**
   * Logs that the user is editing the information.
   *
   * @returns An Observable containing the result of the operation.
   */
  logUserIsEditing() {
    return this.post('userIsEditing');
  }

  /**
   * Logs that the user skipped updating the information.
   *
   * @returns An Observable containing the result of the operation.
   */
  remindMeLater() {
    return this.post<null>('remindMeLater');
  }
}
