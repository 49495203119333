<uk2-modal
  [title]="removeTrustedInterestedModalHeader"
  [showTitleDivider]="true"
  [showActionsDivider]="true"
  [actionsGrid]="removeTrustedInterestedModalActionsGrid"
  [titleFontWeight]="removeTrustedInterestedModalFontWeight"
  [titleAlign]="removeTrustedInterestedModalAlign"
  [innerPanelClass]="['uk2-custom-submodal-white-label', 'udb-modal-small']"
  [bodyContentAlignment]="removeTrustedInterestedModalAlign"
  [modalId]="removeTrustedInterestedModalId"
  #confirmationRemoveModal
>
  <ng-template #uk2ModalContent>
    <p *ngIf="!isLoading; else loading" class="modal-body-content">
      {{ removeTrustedInterestedModalBody }}
    </p>
    <ng-template #loading>
      <app-dot-loader>Loading...</app-dot-loader>
    </ng-template>
  </ng-template>
  <ng-template #uk2ModalActions>
    <!-- Trusted Contact Actions -->
    <ng-container *ngIf="personType === trustedContactPerson; else interestedPartyActions">
      <button
        uk2SecondaryButton
        mat-stroked-button
        disableRipple
        [uk2ButtonSize]="uk2ButtonSize.medium"
        [disabled]="isLoading"
        (click)="closeModal()"
      >
        No, Keep
      </button>
      <button
        uk2PrimaryButton
        mat-raised-button
        disableRipple
        [disabled]="isLoading"
        [uk2ButtonSize]="uk2ButtonSize.medium"
        (click)="removeTrustedInterestedPerson()"
      >
        Yes, Remove
      </button>
    </ng-container>
    <!-- Interested Party Actions -->
    <ng-template #interestedPartyActions>
      <button
        uk2PrimaryButton
        mat-raised-button
        disableRipple
        [uk2ButtonSize]="uk2ButtonSize.medium"
        [disabled]="isLoading"
        (click)="closeModal()"
      >
        Close
      </button>
    </ng-template>
  </ng-template>
</uk2-modal>
