import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  addOrganizationAction,
  getSelectedOrganization,
  initialOrganizationStateType,
  OrganizationStateType,
} from '../core';
import { GetOrganizationSettingsRequestType, OrganizationService } from '../core/services';
import { LoadOrganizationSettingsInput } from './types';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSettingsFacade {
  selectedOrganization$ = this.store.select(getSelectedOrganization);
  constructor(private organizationService: OrganizationService, private store: Store<OrganizationStateType>) {}
  loadOrganizationSettings(input: LoadOrganizationSettingsInput): void {
    const apiRequest: GetOrganizationSettingsRequestType = {
      riaId: input.riaId,
      includeSettings: true,
    };
    this.organizationService.getOrganizationSettings(apiRequest).subscribe(
      response => {
        this.store.dispatch(addOrganizationAction({ payload: response.data }));
      },
      () => {
        this.store.dispatch(addOrganizationAction({ payload: initialOrganizationStateType }));
      }
    );
  }
}
