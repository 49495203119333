import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { AccountCategory, ContainerType } from '@app/accounts/enums';
import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { RewardUnits } from '@legacy/accounts/rewardUnits.enum';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { BalanceService } from '@legacy/services/balance.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AuthenticatedInstitution } from '@legacy/typings/app/account-aggregation';
import { AggregatedAccount } from '@app/accounts/models';
import { AddBulkVm } from '@legacy/dashboard/account-aggregation/typings/AddBulkVm';

@Component({
  selector: 'app-aggregation-selection',
  templateUrl: './aggregation-selection.component.html',
  styleUrls: ['./aggregation-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregationSelectionComponent implements OnInit {
  accountsCheckbox: { [id: number]: boolean } = {};
  unableToConnectAccounts = false;
  isLoading = false;
  institution: AuthenticatedInstitution;
  newPayverisAccounts: ExternalAccount[];
  providerAccountId: any;
  brandDisplayName: string;
  containerType = ContainerType;
  rewardUnits = RewardUnits;
  categories = [
    AccountCategory.Dda,
    AccountCategory.Sav,
    AccountCategory.Cd,
    AccountCategory.Loan,
    AccountCategory.Credit,
    AccountCategory.Other,
    AccountCategory.Unknown,
  ];
  private successStateName = 'udb.dashboard.account-aggregation.auth-success-flow';
  private cancelStateName = 'udb.dashboard.account-aggregation.auth';
  public accounts: AggregatedAccount[];
  private popups: IPopups;
  constructor(
    private accountAggregationService: AccountAggregationService,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private stateParams: ng.ui.IStateParamsService,
    private cachedAccountsService: CachedAccountsService,
    private serviceHelper: ServiceHelper,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private _balanceService: BalanceService
  ) {
    this.institution = this.stateParams['institution'];
    this.newPayverisAccounts = this.stateParams['newPayverisAccounts'];

    if (this.accounts && this.accounts.length > 0) {
      this.accounts = this.orderAccountsByType(this.accounts);
      this.mapAccntsToCheckboxs(this.accounts);
    } else {
      this.unableToConnectAccounts = true;
    }
  }

  ngOnInit(): void {
    if (this.root['brandProperties']) {
      this.brandDisplayName = this.root['brandProperties'].BrandDisplayName;
    } else {
      this.root.$on(
        'brandPropertiesLoaded',
        () => (this.brandDisplayName = this.root['brandProperties'].BrandDisplayName)
      );
    }

    this.serviceHelper.scrollToTop();
  }

  formatAccountName(account: AggregatedAccount): string {
    let formattedAcc = '';
    if (account.nickname) {
      formattedAcc += account.nickname + ' - ';
    } else if (account.name) {
      formattedAcc += account.name + ' - ';
    } else {
      formattedAcc += account.accountType + ' - ';
    }

    if (account.accountNumber) {
      formattedAcc += account.accountNumber.substring(account.accountNumber.length - 4);
    } else if (account.accountMask) {
      formattedAcc += account.accountMask.substring(account.accountMask.length - 4);
    }

    return formattedAcc;
  }

  orderAccountsByType(accounts: AggregatedAccount[]): AggregatedAccount[] {
    return accounts.sort((a, b) => this.categories.indexOf(a.category) - this.categories.indexOf(b.category));
  }

  getBalance(account: AggregatedAccount): number {
    return this._balanceService.getBalance(account);
  }

  getRewardUnits(account: AggregatedAccount): string {
    return this._balanceService.getRewardUnits(account);
  }

  continue() {
    this.isLoading = true;
    // Map accounts to checkbox vm.
    const checkBoxAccounts = this.mapAccountsFromCheckboxes();
    // If all accounts are unchecked,
    // we can not move to the next state
    if (checkBoxAccounts.every(x => !x.selected)) {
      this.isLoading = false;
      this.popups?.showAlert('Error', 'Please, select an account in order to continue', 'error');

      return;
    }
    // if users unchecked accounts, they need to be removed.
    // from providers and cache
    // Accounts checked in vm.
    const checkBoxIncludedAccounts = checkBoxAccounts.filter(x => x.selected);

    const vm: AddBulkVm = {
      bankAuthRequest: {
        providerAccountId: this.institution.providerAccountId,
        requestId: this.institution.requestId,
        authParameter: this.institution,
      },
      accounts: checkBoxIncludedAccounts,
    };
    // Calls API to add selected accounts.
    this.addSelectedAccounts(vm, checkBoxIncludedAccounts, checkBoxAccounts);
  }

  // if user cancels action, account will be removed
  cancel() {
    const checkBoxAccounts = this.cancelAggregation();
    const checkBoxIncludedAccounts = checkBoxAccounts.filter(x => x.selected);
    const vm: AddBulkVm = {
      bankAuthRequest: {
        providerAccountId: this.institution.providerAccountId,
        requestId: this.institution.requestId,
        authParameter: this.institution,
      },
      accounts: checkBoxIncludedAccounts,
    };
    // Calls API to add selected accounts.
    this.accountAggregationService
      .addSelectedAccounts(vm)
      .then(() => {
        this.state.go(this.cancelStateName, {
          bankId: this.institution.bankId,
          updateCredentials: false,
          isAccountAggregationFlow: true,
        });
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => (this.isLoading = false));
  }

  private addSelectedAccounts(
    vm: AddBulkVm,
    checkBoxIncludedAccounts: AggregatedAccount[],
    checkBoxAccounts: AggregatedAccount[]
  ) {
    this.accountAggregationService
      .addSelectedAccounts(vm)
      .then(result => {
        // Accounts checked in vm.
        const includedAccounts = this.accounts.filter(x =>
          checkBoxIncludedAccounts.some(y => y.aggregatedAccntId === x.id)
        );
        // Accounts not checked in vm.
        const excludedAccounts = this.accounts.filter(
          x => !checkBoxIncludedAccounts.some(y => y.aggregatedAccntId === x.id)
        );
        // Delete not selected accounts from cache.
        this.deleteExcludedAccounts(excludedAccounts);
        // Get External Accounts Added
        if (result?.data?.data?.externalAccounts) {
          this.newPayverisAccounts = result.data.data.externalAccounts;
        } else {
          this.newPayverisAccounts = [];
        }

        // Add selected accounts to cache.
        this.addIncludedAccounts(includedAccounts, this.newPayverisAccounts);

        const params = {
          accounts: this.accounts,
          institution: this.institution,
          bankId: this.institution.bankId,
          providerAccountId: this.providerAccountId,
          updateCredentials: false,
          isAccountAggregationFlow: false,
        };

        params.accounts = this.accounts.filter(
          (v, _i, _a) => checkBoxAccounts.find(x => x.aggregatedAccntId === v.id && x.selected) !== undefined
        );
        this.state.go(this.successStateName, params);
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => (this.isLoading = false));
  }

  private deleteExcludedAccounts(deletedAccounts: AggregatedAccount[]) {
    deletedAccounts.forEach((account: AggregatedAccount) => {
      this.cachedAccountsService.removeExternalAccount(account.externalAccountId);
      this.cachedAccountsService.removeAggregatedAccount(account.id);
    });
  }
  private addIncludedAccounts(includedAccounts: AggregatedAccount[], includedExternalAccounts: ExternalAccount[]) {
    if (!!includedAccounts && includedAccounts.length > 0) {
      this.cachedAccountsService.addAggregatedAccounts(includedAccounts);
    }
    if (includedExternalAccounts && includedExternalAccounts.length > 0) {
      this.cachedAccountsService.addExternalAccounts(includedExternalAccounts);
    }
  }
  private mapAccntsToCheckboxs(accounts: AggregatedAccount[]) {
    accounts.forEach((v, _i, _array) => {
      // If account is new or visible
      this.accountsCheckbox[v.id] = v.authenticatedStatus === 1 || v.authenticatedStatus === 2;
    });
  }

  private cancelAggregation(): AggregatedAccount[] {
    const accounts = this.accounts.map(x => ({
      aggregatedAccntId: x.id,
      externalAccntId: x.externalAccountId,
      // only visible
      selected: x.authenticatedStatus == 2,
    }));

    return accounts;
  }

  private mapAccountsFromCheckboxes(): AggregatedAccount[] {
    const accounts = this.accounts.map(x => ({
      aggregatedAccntId: x.id,
      externalAccntId: x.externalAccountId,
      selected: this.accountsCheckbox[x.id],
    }));

    return accounts;
  }
}
