import { AppSettingsService } from '@legacy/services/app-settings.service';

export function appSettingsServiceFactory($inject: any) {
  return $inject.get('appSettingsService');
}

export const appSettingsServiceProvider = {
  provide: AppSettingsService,
  useFactory: appSettingsServiceFactory,
  deps: ['$injector'],
};
