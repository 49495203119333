import { OrdersTileState } from './orders-tile-state.type';

export type OrdersState = {
  accountsOrders: OrdersTileState[];
  selectedAccountOrders: string | undefined;
};

export const initialOrdersState: OrdersState = {
  accountsOrders: [],
  selectedAccountOrders: undefined,
};
