import { SupportFile } from '@shared/models/support-file.model';
import { NewOlbAccount } from './new-olbAccount.model';
export type WTLocalDestiny = 'In the U.S.';
export type WTForeignDestiny = 'Outside the U.S.';
export type WtDestinyType = WTLocalDestiny | WTForeignDestiny;

export class WireTransferDestination {
  static readonly us: WTLocalDestiny = 'In the U.S.';
  static readonly out: WTForeignDestiny = 'Outside the U.S.';
}

export class WireRequest {
  AccountFrom: string;
  Amount: number;
  Body: string;
  RecipientBank: string;
  RecipientName: string;
  Subject: string;
  wireTransferDetails: WireTransfer;
}

export class WireTransfer {
  amount?: number;
  amountCheck: boolean;
  bankDetails?: BankDetailsModel;
  destination: WtDestinyType = WireTransferDestination.us;
  documents: SupportFile[] = [];
  purpose?: string;
  recipientDetails?: RecipientDetailsModel;
  selectedFromAccount?: NewOlbAccount;
  wireFee?: number;
  closeAccount?: boolean;
}

export class RecipientDetailsModel {
  name = '';
  joinName = '';
  address = '';
  address1 = '';
  postalCode = '';
  city = '';
  state = '';
  country = '';
  furtherInstructions = '';
}

export class BankDetailsModel {
  abaNumber = '';
  accountNumber = '';
  address? = '';
  address1 = '';
  bank = '';
  city = '';
  country = '';
  interBankAccount = '';
  interBankName = '';
  postalCode = '';
  state = '';
  swiftCode = '';
  intermediary = false;
}
