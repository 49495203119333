import { Component, Input, OnInit } from '@angular/core';

import { getInstructions, openWebsiteWarningModal } from '@app/click-switch/utils';
import { SwitchDetail } from '@app/user-profile/models';
import { DialogService } from '@core/services';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch-online-path-detail',
  templateUrl: './click-switch-online-path-detail.component.html',
  styleUrls: ['./click-switch-online-path-detail.component.scss'],
})
export class ClickSwitchOnlinePathDetailComponent implements OnInit {
  @Input() switch: SwitchDetail;
  incomeSourceUrl = '';
  webSite = '';
  outDomain = UtilityIcons.NewWindow;

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {
    const instructions = getInstructions(this.switch.uxFields);
    Object.assign(this, instructions);
  }

  goToIncomeSourceUrl(): void {
    if (this.incomeSourceUrl.length === 0) {
      return;
    }

    openWebsiteWarningModal(this.dialogService, this.webSite)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          window.open(this.incomeSourceUrl, '_blank', 'noopener noreferrer');
        }
      });
  }
}
