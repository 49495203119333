import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { filter, finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { AccountDetail, GenericOption, Transaction } from '@app/accounts/models';
import { DisputeDataTransaction } from '@app/accounts/submodules/transactions/models';
import { SupportFile } from '@app/shared/models';
import { getBrandProperty } from '@app/store/selectors';
import { ReceiptTemplate } from '@app/transfers/models';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { BrandProperty } from '@core/enums';
import { TransferService } from '@core/services';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons } from '@shared/enums';

import { objectToFormData } from '../../utils';

@Component({
  selector: 'app-dispute-submit',
  templateUrl: './dispute-submit.component.html',
  styleUrls: ['./dispute-submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisputeSubmitComponent implements OnInit, OnDestroy {
  //#region <Properties>
  transactions: Transaction[] = [];
  accountId: number;
  account: AccountDetail;
  disputeData: DisputeDataTransaction;
  disputableHeaders: GenericOption[];
  documents: SupportFile[] = [];
  disputeReason: SafeHtml;
  acceptedTerms = false;
  termsError = false;
  unauthorizedChargesText = '';
  callTime: string;
  isBusy: boolean;
  disputeSuccesSubmitted: ReceiptTemplate;
  primaryEmail: string;
  icon = {
    name: NavigationIcons.Ex,
    styles: {
      width: '12px',
      height: '12px',
      fill: 'gray',
    },
  };

  private subsink = new SubSink();
  //#endregion <Properties>

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    private serviceHelper: ServiceHelper,
    private transferService: TransferService,
    private cachedAccountsService: CachedAccountsService,
    private domSanitizer: DomSanitizer,
    private cp: CurrencyPipe,
    private dp: DatePipe,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (!this.state.params?.accountId || !this.state.params?.account || !this.state.params?.transactions) {
      this.state.go('udb.dashboard');

      return;
    } else {
      this.initializeProperties();

      this.subsink.sink = this.store.select(getBrandProperty(BrandProperty.UnauthorizedCharges)).subscribe(value => {
        this.unauthorizedChargesText = value;
      });

      this.subsink.sink = this.store
        .select(getProfileDetails)
        .pipe(filter(profileDetails => profileDetails && profileDetails.hasOwnProperty('primaryEmail')))
        .subscribe({
          next: profileDetails => {
            this.primaryEmail = profileDetails.primaryEmail;
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  removeFile(file: SupportFile) {
    this.documents = this.documents.filter(fileObject => fileObject.name !== file.name);
  }

  submitReview() {
    if (!this.acceptedTerms) {
      this.termsError = true;

      return;
    }

    this.isBusy = true;
    const formData = new FormData();
    objectToFormData(this.account, 'account', formData);
    objectToFormData(this.transactions, 'transactions', formData);
    objectToFormData(this.disputeData.cardsLost, 'lostCards', formData);
    formData.append('disputeReason', this.disputeData.disputeReason);

    if (this.documents.length) {
      for (const [i, fileModel] of this.documents.entries()) {
        formData.append(`file[${i}]`, fileModel.document);
        formData.append(`fileFormDataName[${i}]`, fileModel.name);
      }
    }

    let getDebitCardsServiceHasBeenCalled = false;
    this.transferService
      .uploadDocuments(`dispute/${this.accountId}`, formData)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: (res: HttpEvent<object>) => {
          if (this.disputeData.cardsLost.length && !getDebitCardsServiceHasBeenCalled) {
            getDebitCardsServiceHasBeenCalled = true;
            this.cachedAccountsService.getDebitCards(this.accountId);
          }

          if (res.type === HttpEventType.Response) {
            this.showDisputeConfirmationReceipt(res.body['data']);
          }
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  redirectToEdit() {
    if (this.state.params['isMultiTransaction']) {
      this.state.go('udb.accounts.dispute-details', {
        isTransDisputeRedirection: true,
        transactions: this.transactions,
        accountId: this.accountId,
        accountDetail: this.account,
        previousTrx: this.state.params.previousTrx,
      });
    } else {
      this.state.go('udb.accounts.details', {
        id: this.accountId,
        isTransDisputeRedirection: true,
      });
    }
  }

  // handles the go back page for single/multi dispute cases
  goBack() {
    if (this.transactions.length > 1) {
      this.state.go('udb.accounts.dispute-details', {
        transactions: this.transactions,
        accountDetail: this.account,
        accountId: this.accountId,
        previousTrx: this.state.params.previousTrx,
      });
    } else {
      this.state.go('udb.accounts.details', { id: this.accountId, isTransDisputeRedirection: true });
    }
  }

  // Sum all transactions amounts
  getTotal(): number {
    return this.transactions.reduce((acc, next) => acc + next.amount, 0);
  }

  private initializeProperties() {
    this.account = this.state.params.account;
    this.accountId = this.state.params.accountId;
    this.transactions = this.state.params.transactions;
    this.disputeData = this.state.params.disputeFormData;

    this.documents = this.disputeData.documents;
    this.disputeReason = this.domSanitizer.bypassSecurityTrustHtml(this.disputeData.disputeReason);

    this.disputableHeaders = [
      new GenericOption({
        value: 0,
        subvalue: 'transaction',
        label: 'Description',
        cssClass: 'transaction-name-header',
      }),
      new GenericOption({
        value: 1,
        subvalue: 'amount',
        label: 'Amount',
        cssClass: 'header-amount-table',
      }),
    ];

    this.disputeSuccesSubmitted = new ReceiptTemplate({
      type: 'DisputeSuccesSubmitted',
      header: 'Claim Submitted',
      title: "You've successfully submitted your claim ",
      subtitle: "We've sent a confirmation email to you at <b>{email}</b>.",
      transactions: [
        {
          details: [
            { description: 'Account' },
            { description: 'Amount total' },
            { description: 'Date Submitted' },
            { description: 'Confirmation Number' },
          ],
        },
      ],
      tableFootnote: 'The initial evaluation of your claim may take up to 10 business days.',
      navigationBack: 'Back to Transactions',
      navigation: 'View in Message Center',
    });
  }

  // After dispute successfully submitted, build template and show confirmation page
  private showDisputeConfirmationReceipt(confirmationNumber: any): void {
    this.rootScope['txDisputeData'] = null;
    let formattedConfirmationNumber: string;

    if (confirmationNumber) {
      formattedConfirmationNumber = Object.keys(confirmationNumber)
        .map(key => confirmationNumber[key])
        .filter(confirmationNumberObject => !!confirmationNumberObject)
        .join(', ');
    }

    const details = this.disputeSuccesSubmitted.transactions[0].details;
    const amount = this.transactions.reduce((acc, next) => {
      return acc + next.amount;
    }, 0);

    details[0].value = this.account.nickname;
    details[1].value = this.cp.transform(amount);
    details[2].value = this.dp.transform(new Date(), 'MM/dd/YYYY');
    details[3].value = formattedConfirmationNumber;

    this.disputeSuccesSubmitted.subtitle = this.disputeSuccesSubmitted.subtitle.replace('{email}', this.primaryEmail);

    const settings = {
      navigateBack: `udb.accounts.details({id: ${this.accountId}})`,
      navigateTo: 'udb.messages.inbox',
    };

    this.state.go('udb.accounts.dispute-review-confirm', {
      settings,
      template: this.disputeSuccesSubmitted,
    });
  }
}
