import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Transaction } from '@app/axos-invest/models';

@Component({
  selector: 'app-transaction-description',
  templateUrl: './transaction-description.component.html',
  styleUrls: ['./transaction-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionDescriptionComponent implements OnInit {
  @Input() transaction: Transaction;

  ngOnInit(): void {}
}
