import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SubSink } from '@axos/subsink';

import { AccountDetail } from '@app/accounts/models/account-detail.model';
import { Transaction } from '@app/accounts/submodules/transactions/models/transaction.model';
import { SupportFile } from '@app/shared/models/support-file.model';
import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';

import { CanHaveDebitCardsType } from '../../../../enums/can-have-debit-cards-type.enum';
import { CardType, DebitCardStatus } from '../../../debit-card/enums';
import { DebitCard } from '../../../debit-card/models/debit-card.model';
import { DisputeDataTransaction } from '../../models/dispute-data-transaction.model';
import { DisputeRedirectionDataTransaction } from '../../models/dispute-redirection-data-transaction.model';

@Component({
  selector: 'app-transaction-dispute-form',
  templateUrl: './transaction-dispute-form.component.html',
  styleUrls: ['./transaction-dispute-form.component.scss'],
})
export class TransactionDisputeFormComponent implements OnInit, OnDestroy {
  @Input() transactions: Transaction[];
  @Input() accountId: number;
  @Input() accountDetail: AccountDetail;
  @Input() isMultiTransaction: boolean;
  @Input() previousTransactions: Transaction[];
  @Output() cancel = new EventEmitter();

  cards: Partial<DebitCard>[] = [];
  cardsDisplay: any[] = [];
  disputeDataTx = new DisputeDataTransaction();
  txDisputeRedirectionData: DisputeRedirectionDataTransaction;
  totalMaxSize = 10485760;

  private subsink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private cachedAccountsService: CachedAccountsService,
    private olbEventService: OlbEventService
  ) {}

  ngOnInit(): void {
    if (this.state.params.isTransDisputeRedirection) {
      this.fillDisputeDataFromRoot();
    }

    this.disputeDataTx.transaction = this.transactions[0];

    this.getLinkedCards();

    if (!this.cards?.length) {
      this.subsink.sink = this.olbEventService.on(OlbEvents.LinkedDebitCardsLoaded, () => {
        this.getLinkedCards();
      });
    } else {
      this.createCardArray();
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  goNextPage(formIsValid: boolean) {
    if (!formIsValid) return;

    this.disputeDataTx.cardsLost = this.cardsDisplay.filter(c => c.selected);
    this.disputeDataTx.disputeReason = this.disputeDataTx.disputeReason.replace(/\n/g, '<br/>');
    this.fillDisputeDataForRedirection();

    this.state.go('udb.accounts.dispute-review-submit', {
      accountId: this.accountId,
      account: this.accountDetail,
      transactions: this.transactions,
      disputeFormData: this.disputeDataTx,
      isMultiTransaction: this.isMultiTransaction,
      previousTrx: this.previousTransactions,
    });
  }

  filesUpdate(supportFiles: SupportFile[]) {
    this.disputeDataTx.documents = supportFiles;
  }

  onCancel() {
    this.cancel.emit();
  }

  /** Get linked cards of account */
  private getLinkedCards() {
    if (!this.cards?.length) {
      this.cards = this.cachedAccountsService.linkedDebitCards(this.accountId) as DebitCard[];
    } else {
      this.createCardArray();
    }
  }

  private createCardArray() {
    if (!this.cardsDisplay.length && this.cards?.length) {
      this.cards = this.cards
        .filter(show => show.status === DebitCardStatus.Active || show.isBlocked)
        .filter(
          card =>
            (card.debitCardType === CardType.VISA &&
              this.accountDetail.canHaveDebitCards === CanHaveDebitCardsType.VISA) ||
            (card.debitCardType === CardType.DISCOVER &&
              this.accountDetail.canHaveDebitCards === CanHaveDebitCardsType.DISCOVER)
        );

      this.cardsDisplay = this.cards.map(c => {
        return {
          debitCardId: c.debitCardId,
          prodDesc: `${c.prodDesc} ${c.cardNumberMask.slice(c.cardNumberMask.length - 5)}`,
          cardNumberMask: c.cardNumberMask,
          selected: false,
        };
      });
    }
  }

  private fillDisputeDataForRedirection() {
    this.txDisputeRedirectionData = new DisputeRedirectionDataTransaction({
      transaction: this.transactions,
      accountNumber: this.accountId,
      accountDetail: this.accountDetail,
      disputeData: this.disputeDataTx,
      cardsDisplay: this.cardsDisplay,
    });

    this.root['txDisputeData'] = this.txDisputeRedirectionData;
    this.olbEventService.emit(OlbEvents.TxRedirectionEvent);
  }

  private fillDisputeDataFromRoot() {
    if (this.root['txDisputeData'] !== undefined && this.root['txDisputeData'] !== null) {
      this.txDisputeRedirectionData = this.root['txDisputeData'];

      this.transactions = this.txDisputeRedirectionData.transaction;
      this.accountId = this.txDisputeRedirectionData.accountNumber;
      this.accountDetail = this.txDisputeRedirectionData.accountDetail;
      this.disputeDataTx = this.txDisputeRedirectionData.disputeData;
      this.cardsDisplay = this.txDisputeRedirectionData.cardsDisplay;
    }
  }
}
