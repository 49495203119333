import { createSelector } from '@ngrx/store';

import { getAxosAdvisoryState } from '../axos-advisory.state';

export const getAxosAdvisoryAccounts = createSelector(getAxosAdvisoryState, state => state.axosAdvisoryAccounts);

export const getAxosSelectedAdvisoryAccount = (accountNumber: string) =>
  createSelector(getAxosAdvisoryState, state =>
    state.axosAdvisoryAccounts.find(account => account.accountNumber === accountNumber)
  );
