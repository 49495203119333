<up-section-heading
  section="Direct Deposit Requests"
  [mobile]="isMobileDevice"
  (expand)="expanded = $event"
></up-section-heading>

<p class="mb-3 col-12">
  Submit a new request to have your paycheck or other recurring income deposited directly to your account. Or check the
  status of any requests you've done in the last 90 days.
</p>

<div class="direct-deposit__content">
  <app-click-switch-list></app-click-switch-list>

  <div class="actions">
    <button type="button" class="btn-ok button--lg" (click)="goNewRequest()">
      New Request
    </button>
  </div>
</div>
