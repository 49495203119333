<h3 class="mt-5 mb-4">How do I change my profile icon?</h3>

<p class="mb-3">Have some fun with different colors and images for your profile icon!</p>

<ol>
  <li>
    Click on the icon on the upper right-hand corner of the page and select
    <b>Profile & Settings.</b>
  </li>
  <li>
    Select your name to the left of the page. From here, you can personalize the color and icon for
    your profile.
  </li>
  <li>
    Click on
    <b>Save</b>
    when you have the color and icon combination you like.
  </li>
</ol>
