import { Institution } from './Institution';
import { AggregatedAccount } from 'typings/app/account-aggregation/AggregatedAccount';

export interface AuthenticatedInstitution extends Institution {
  accounts?: AggregatedAccount[];
  aggregationSource?: string;
  formType?: string;
  status?: AggregationStatus;
  providerAccountId?: number;
  requestId?: string;
}

export enum AggregationStatus {
  LOGIN_IN_PROGRESS,
  USER_INPUT_REQUIRED,
  IN_PROGRESS,
  PARTIAL_SUCCESS,
  SUCCESS,
  FAILED,
}
