<div class="dialog-header" [style.backgroundImage]="backgroundUrl">
  <h3 class="text-white font-weight-normal">Text Message Activation</h3>
</div>

<div mat-dialog-content>
  <form id="activation-form" class="text-left">
    <label for="phone" class="uk-label">
      <b>Mobile Number</b>
      to receive text messages
    </label>

    <input
      type="text"
      name="phone"
      id="phone"
      class="uk-input"
      [value]="data.phonesRequest.cellPhone1.number | phone"
      disabled
    />
  </form>

  <p class="disclaimer mt-2 text-justify">
    By providing your phone number and selecting consent, you have agreed to these
    <a id="terms-and-conditions" [href]="data.termsAndConditionsUrl" target="_blank" rel="noopener noreferrer">
      Terms and Conditions,
    </a>
    and you have agreed to receive automated text messages, calls, and emails for any purpose including but not limited
    to marketing of products and services by {{ brandName }}. You understand and agree that such messages may be sent
    via Automatic Telephone Dialing System and/or artificial or pre-recorded voice, and that such consent is not a
    condition of receipt of any good or service. Mobile carrier messages and data rates may apply. You may opt out at
    any time.
  </p>
</div>

<div mat-dialog-actions *ngIf="!isBusy; else loader">
  <button type="submit" class="btn-ok button--lg" (click)="requestOtp()" cdkFocusInitial>
    Accept &amp; Continue
  </button>
  <button type="button" class="btn-cancel button--lg" mat-dialog-close="cancel">Cancel</button>
</div>

<ng-template #loader>
  <app-dot-loader mat-dialog-actions></app-dot-loader>
</ng-template>
