import { NgModule } from '@angular/core';

import { AasCoreModule } from './aas-core/aas-core.module';
import { AccountDetailsModule } from './features/account-details/account-details.module';
import { AvailableCashModule } from './features/available-cash/available-cash.module';
import { HoldingsModule } from './features/holdings/holdings.module';
import { ModelsHoldingsTabModule } from './features/models-holdings-tab/models-holdings-tab.module';
import { ModelsModule } from './features/models/models.module';
import { PortfolioAllocationModule } from './features/portfolio-allocation/portfolio-allocation.module';
import { ProductDetailsPageModule } from './features/product-details-page/product-details-page.module';
import { StatementsAndDocumentsModule } from './features/statements-and-documents/statements-and-documents.module';
import { TotalValueModule } from './features/total-value/total-value.module';
import { TransactionsModule } from './features/transactions/transactions.module';
import { LayoutModule } from './features/product-details-page/layout/layout.module';
import { OverdraftTermsConditionsModule } from './features/overdraft-credit-terms-conditions-modal/overdraft-credit-terms-conditions-modal.module';
import { TransactionsOrdersScheduledTabModule } from './features/transactions-orders-scheduled-tab/transactions-orders-scheduled-tab.module';
import { ScheduledTransfersModule } from './features/scheduled-transfers/scheduled-transfers.module';
import { OrdersModule } from './features/orders/orders.module';
import { EDisclosureModule } from '@app/accounts/components/modals/modal-documents/e-disclosure/e-disclosure-modal.module';
import { PersonalDepositModule } from '@app/accounts/components/modals/modal-documents/personal-deposit/personal-deposit-modal.module';
import { PrivacyNoticeModule } from '@app/accounts/components/modals/modal-documents/privacy-notice/privacy-notice.module';
import { MaintenanceInProgressModule } from './features/maintenance-in-progress';
import { ShareAccountDetailsModule } from './features/share-account-details/share-account-details.module';
import { AuthorizeTransfersTransferOnlyModule } from './features/authorize-transfers-transfers-only/authorize-transfers-transfer-only.module';

const exportedModules = [
  ModelsHoldingsTabModule,
  TransactionsOrdersScheduledTabModule,
  AccountDetailsModule,
  AvailableCashModule,
  HoldingsModule,
  ModelsModule,
  PortfolioAllocationModule,
  ProductDetailsPageModule,
  StatementsAndDocumentsModule,
  TotalValueModule,
  TransactionsModule,
  LayoutModule,
  OverdraftTermsConditionsModule,
  ScheduledTransfersModule,
  OrdersModule,
  EDisclosureModule,
  PersonalDepositModule,
  PrivacyNoticeModule,
  MaintenanceInProgressModule,
  ShareAccountDetailsModule,
  AuthorizeTransfersTransferOnlyModule,
];

// * Use this module to export all modules under Areas/AAS. This will be imported on AppModule.ts
@NgModule({
  imports: [AasCoreModule, ...exportedModules],
  exports: [...exportedModules],
})
export class AasModule {}
