import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';

import { PaymentData, UserAccountType, RecurrentPaymentInfo } from '@app/bill-pay/typings';
import { BillPayRouterHelper } from '@app/bill-pay/utils';

import { IBillPayService } from 'services/typings/IBillPayService';
import * as moment from 'moment';
import { STATE } from '@core/tokens';
import {
  BILLPAYROUTERHELPER,
  BILLPAYSERVICE,
  SERVICEHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { IStateService } from 'angular-ui-router';
import { formatDate } from '@angular/common';

@Inject('billPayRouterHelper')
@Component({
  selector: 'app-pay-review',
  templateUrl: './pay-review.component.html',
  styleUrls: ['./pay-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class PayReviewComponent implements OnInit {
  @Input() isCancelling: boolean;
  @Input() isScheduling: boolean;
  isBusy: boolean;
  headers: GenericOption[];
  detailsTooltip: string;
  payments: PaymentData[] = [];
  accountFrom: UserAccountType;
  severalPayments: boolean;
  electronicRecipientLabel = 'Payment will be withdrawn on';
  checkRecipientLabel = 'Your check will be mailed on';
  pays: RecurrentPaymentInfo[];
  totalAmount: number = 0;

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(SERVICEHELPER) private readonly serviceHelper: IServiceHelper,
    @Inject(BILLPAYSERVICE) private readonly billPayService: IBillPayService,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}

  ngOnInit(): void {
    this.payments = this.billPayRouterHelper.getPaymentData();
    this.accountFrom = this.billPayRouterHelper.getAccountFrom();
    if (!this.payments) {
      this.state.go('udb.billPay.pay');
    } else {
      this.preparePayments();
    }
  }

  public submitPayments(): void {
    this.isBusy = true;
    this.pays = this.payments.map(
      (payment): RecurrentPaymentInfo => {
        const pay: RecurrentPaymentInfo = {
          payeeId: payment.recipient.payeeId,
          accountCode: this.accountFrom.accountCode,
          amount: payment.amount,
          daysBeforeDeliveryAlert: 0,
          daysBeforeDeliverySpecified: false,
          daysBeforeProcessingAlert: 0,
          daysBeforeProcessingSpecified: false,
          alertOnCompletion: false,
          repeatUntilCanceled: false,
          processingDate: moment(payment.processingDate).toDate(),
          deliveryDate: moment(payment.selectedDate).toDate(),
          numberOfPayments: 1,
          endOnNumberOfPayments: true,
          isSent: true,
          isRecurring: false,
          notifyMeNumberOfPayments: false,
          isExpedited: false,
          hasDuplicateWarning: payment.hasDuplicateWarning,
        };
        return pay;
      }
    );

    this.billPayService
      .addMultipleScheduledPayments(this.pays)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.state.go('udb.billPay.payConfirmation');
      })
      .catch(this.serviceHelper.errorHandler);
  }

  public formatDate(date: Date): string {
    return formatDate(date, 'MM/dd/YYYY', 'en-US');
  }
  public redirectToPay(): void {
    this.state.go('udb.billPay.pay');
  }

  private preparePayments() {
    this.severalPayments = this.payments.length > 1;
    this.headers = [
      { value: 0, label: 'Recipient' },
      { value: 1, label: 'Amount To Pay' },
      { value: 2, label: 'Deliver By' },
      { value: 3, label: 'Payment Type' },
    ];
    this.payments.forEach(p => {
      this.totalAmount += parseFloat(p.amount.toString());
    });

    this.detailsTooltip = `This is our estimate of when your recipient will receive your payment. Your recipient may receive the payment before this date.`;
  }
}
