<div class="axos-invest-transactions">
  <h3 class="transactions-title">Transactions</h3>
  <ng-container *ngIf="isFeatureActive; else featureInactive">
    <app-filters (filtersUpdated)="getTransactions($event)"></app-filters>
    <app-table [hidden]="isLoading" [transactions]="transactions"></app-table>
    <app-dot-loader [showWhen]="isLoading">Loading transactions...</app-dot-loader>
    <p class="transactions-disclaimer" *ngIf="!isLoading">
      Transactions shown represent a summary of the latest transactions in your account. For more specific details on
      your transactions, please review your account statement provided by our clearing firm.
    </p>
  </ng-container>
  <ng-template #featureInactive>
    <div class="old-content">
      <div>Transaction information is currently only available on Axos Invest.</div>
      <div>Please click below to view your transactions.</div>
      <button class="uk-btn solid primary lg" (click)="redirectToAxosInvest()">
        View Transactions
        <i class="fa fa-fw fa-external-link" aria-hidden="true"></i>
      </button>
    </div>
  </ng-template>
</div>
