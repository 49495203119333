import { TotalContributions } from "@app/transfers/models/total-contributions.model";
import { Action, createReducer, on } from "@ngrx/store";
import  * as ContributionActions  from "./contributions.actions";

export const _reducer = createReducer(
  null,
  on(ContributionActions.loadContributionsSuccess, (_state, action) => {
    return [...action.payload];
  })
);
export function axosAdvisoryContributionsReducer(state: TotalContributions[], action: Action){
  return _reducer(state, action);
}