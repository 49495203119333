import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { openClose, slideFromRight, startStaticSlideFromLeft } from '@app/user-profile/animations';
import { AddressType, ProfileType } from '@app/user-profile/enums';
import { UpdateEmailsRequest, UpdatePhonesRequest } from '@app/user-profile/models';
import {
  updateCompanyAddresses,
  updateCompanyEmail,
  updateCompanyFax,
  updateCompanyPhone,
} from '@app/user-profile/store/actions';
import { getCompanyDetail, getIsNewUser, getProfileDetails } from '@app/user-profile/store/selectors';
import { areAddressesEqual } from '@app/user-profile/utils';
import { BusinessService } from '@core/services';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { AlertsIcons } from '@shared/enums';
import { Address, AddressAccountAssociation, CompanyDetail, CustomerDetail } from '@shared/models';
import { SectionHeadingComponent } from '@app/user-profile/components';

@Component({
  selector: 'app-company-information-view',
  templateUrl: './company-information-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './company-information-view.component.scss'],
  animations: [startStaticSlideFromLeft, slideFromRight, openClose],
})
export class CompanyInformationViewComponent implements OnInit, OnDestroy {
  @ViewChild('heading') headerComponent: SectionHeadingComponent;
  @HostBinding('class.expanded')
  expanded = false;
  @Input()
  set setControlActive(value: boolean) {
    this.controlActive = value;
    this.toggleExpand(value);
  }
  @Input()
  set setActiveProfile(value: number) {
    this.loadProfileDetails(value);
  }
  @Input()
  set mobileMenuTitle(title: string) {
    this.setMenuTitle(title);
  }
  @Output() getActiveProfile = new EventEmitter<number>();
  controlActive = false;
  activeProfile: number = -1;
  isMobileDevice = false;
  profileLoading = true;
  catalogsLoading = true;
  userCanEdit = false;
  canEditAddress = false;
  primaryAddressIsIncomplete: boolean;
  mailingAddressIsIncomplete: boolean;
  currentView: CompanyInformationView = null;
  errorIcon = AlertsIcons.ExclamationCircle;
  profileInfo: Partial<CustomerDetail>;
  companyInfo: Partial<CompanyDetail>;
  currentCompanyDetails: CompanyDetail;
  profileTypes = ProfileType;
  isMailingAsPrimary = false;

  alertMessages = {
    email: '',
    phone: '',
    address: '',
    fax: '',
  };
  views = CompanyInformationView;
  menuTitle: string = this.views.BusinessInformation;
  animationState: 'initial' | 'in' = 'initial';
  mailingAddress: Address;
  hasOutdatedContactInfo$: Observable<boolean> = of(false);
  currentViewText = '';

  private subsink = new SubSink();

  constructor(
    private store: Store,
    private breakpointObserver: BreakpointObserver,
    private featureFlagService: FeatureFlagService,
    private businessService: BusinessService
  ) {}

  ngOnInit(): void {
    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.profileInfo = data;
        this.userCanEdit = !this.featureFlagService.isSiteInReadOnly();
        this.profileLoading = false;
      });

    this.getCompanyDetails();

    this.subsink.sink = this.store.select(getIsNewUser).subscribe({
      next: isNewUser => {
        const readOnly = this.featureFlagService.isSiteInReadOnly();
        const canEditAddress = this.featureFlagService.isAddressModificationEnabled();
        this.userCanEdit = !isNewUser && !readOnly;
        this.canEditAddress = this.userCanEdit && canEditAddress;
      },
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
  goTo(view: CompanyInformationView): void {
    switch (view) {
      case this.views.Email:
        this.profileInfo = { ...this.profileInfo, primaryEmail: this.companyInfo.businessEmail };
        break;
      case this.views.Phone:
        this.profileInfo = { ...this.profileInfo, workPhone: this.companyInfo.businessPhone || {} };
        break;
      case this.views.Address:
        this.profileInfo = {
          ...this.profileInfo,
          primaryAddress: this.companyInfo.businessAddress || { address: new Address() },
          mailingAddress: this.companyInfo.mailingAddress || { address: new Address() },
        };
        break;
      case this.views.Fax:
        this.profileInfo = { ...this.profileInfo, workFax: this.companyInfo.businessFax || {} };
        break;
    }

    this.currentView = view;
    this.currentViewText = `${!this.isMobileDevice && this.companyInfo.companyName.length > 35 ? ':' : ''}${
      this.currentView
    }`;
    this.alertMessages = {
      email: '',
      phone: '',
      address: '',
      fax: '',
    };
    this.animationState = 'initial';
  }

  goBack(): void {
    this.currentView = null;
    this.currentViewText = '';
    this.animationState = 'in';
  }

  updateEmails(email: Partial<UpdateEmailsRequest>): void {
    this.alertMessages.email = 'You have successfully saved your email.';

    this.store.dispatch(updateCompanyEmail({ payload: email.primaryEmail }));
    this.getCompanyDetails();
    this.goBack();
  }

  updatePhones(phone: Partial<UpdatePhonesRequest>): void {
    if (this.currentView === this.views.Phone) {
      this.alertMessages.phone = 'You have successfully saved your phone.';

      this.store.dispatch(updateCompanyPhone({ payload: phone.workPhone }));
    } else if (this.currentView === this.views.Fax) {
      this.alertMessages.fax = 'You have successfully saved your fax.';

      this.store.dispatch(updateCompanyFax({ payload: phone.workFax }));
    }
    this.getCompanyDetails();
    this.goBack();
  }

  updateAddresses(addresses: Partial<AddressAccountAssociation>[]): void {
    this.alertMessages.address = 'You have successfully saved your address.';

    this.store.dispatch(updateCompanyAddresses({ payload: addresses }));
    this.getCompanyDetails();
    this.goBack();
  }

  expandingMenu(expanding: boolean): void {
    this.expanded = expanding;
    if (expanding) {
      this.loadProfileDetails(this.activeProfile);
    }
  }

  private getCompanyDetails(): void {
    this.subsink.sink = this.store
      .select(getCompanyDetail)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.companyInfo = data;
        this.recomputeViewValues(data);
      });
  }

  private recomputeViewValues(data: Partial<CompanyDetail>): void {
    const businessAddress = data.businessAddresses.find(a => a.address.addressType === AddressType.Physical);
    const mailingAddress = data.businessAddresses.find(a => a.address.addressType === AddressType.Mailing);

    this.companyInfo = { ...this.companyInfo, businessAddress, mailingAddress };

    this.primaryAddressIsIncomplete = this.companyInfo.businessAddress
      ? !this.companyInfo.businessAddress.address.city ||
        !this.companyInfo.businessAddress.address.stateCode ||
        !this.companyInfo.businessAddress.address.postalCode ||
        !this.companyInfo.businessAddress.address.streetAddress1
      : true;
    this.mailingAddressIsIncomplete =
      !this.companyInfo.mailingAddress.address.city ||
      !this.companyInfo.mailingAddress.address.stateCode ||
      !this.companyInfo.mailingAddress.address.postalCode ||
      !this.companyInfo.mailingAddress.address.streetAddress1;

    if (
      (this.companyInfo.businessAddress?.address != null && 
      this.companyInfo.mailingAddress?.address != null) &&
      areAddressesEqual(
        this.companyInfo.businessAddress.address,
        this.companyInfo.mailingAddress.address
      )
    ) {
      this.isMailingAsPrimary = true;
    } else {
      this.isMailingAsPrimary = false;
    }
  }

  private toggleExpand(value: boolean): void {
    if (this.headerComponent && !value && this.headerComponent.expanded) {
      this.headerComponent.toggleExpand();
    }
  }

  private loadProfileDetails(profileId: number): void {
    this.activeProfile = profileId;
    if (profileId <= 0) {
      return;
    }

    this.businessService.getCompanyDetails(profileId).subscribe({
      next: data => {
        this.currentCompanyDetails = data.data;
      },
      error: _ => {
        this.currentCompanyDetails = null;
        this.companyInfo = null;
      },
    });

    this.getActiveProfile.emit(this.activeProfile);

    this.goBack();
  }

  private setMenuTitle(title: string): void {
    this.menuTitle = title;
  }
}

export enum CompanyInformationView {
  Email = 'Email',
  Phone = 'Phone Number',
  Fax = 'Fax Number',
  Address = 'Address',
  Employment = 'Employment Information',
  Financial = 'Financial Information',
  Family = 'Family Information',
  BusinessInformation = 'Business Information',
}
