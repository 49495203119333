export const validPhoneRegex = /[0-9]{10}/;
export const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const poBoxRegex = /(?:P(?:ost(?:al)?)?[\.\-\s]*?((?:[O|0](?:ffice)?[\.\-\s]*?)?B(?:ox|in|\b|\d*?)|[o|0](?:ffice|\b|\.)(?:[-\s]*?\d*?)|code)|box[-\s\b]*?\d)/i;
export const zipCodeRegex = /^\d{5}$/;
export const allowedAddressCharactersRegex = /^[a-zA-Z0-9#\/.,\s]*$/;
export const ssnRegex = /\d{9}/;
export const usernameRegex = /^[A-Za-z0-9_\-+.@]*$/;
export const hasLowercaseRegex = /^(?=.*[a-z]).*$/;
export const hasUppercaseRegex = /^(?=.*[A-Z]).*$/;
export const hasNumberRegex = /^(?=.*[0-9]).*$/;
export const validSymbolsRegex = /^(?=.*[a-zA-Z \d#?!@$%^&*+-])(?=.*[#?!@$%^&*+-])[a-zA-Z\d#?!@$%^&*+-]+$/;
export const validSecurityAnswerRegex = /^[a-zA-Z0-9\u00F1\u00D1 '-]+$/;
export const securityAnswerRegex = /^[a-zA-Z0-9\u00F1\u00D1 '-]+$/;
export const hasNumberNotZeroRegex = /^(?=.*[1-9]).*$/;
export const hasOnlyNumbersRegex = /^[0-9]\d*$/;
