import { AccountDocumentsTileStateType } from './account-documents-tile-state.type';

export type StatementsAndDocumentsStateType = {
  accountsDocuments: AccountDocumentsTileStateType[];
  selectedAccountDocuments: string | undefined;
};

export const initialStatementsAndDocumentsState: StatementsAndDocumentsStateType = {
  accountsDocuments: [],
  selectedAccountDocuments: undefined,
};
