import { ServiceHelper } from '@legacy/services/service.helper';

export function serviceHelperFactory($inject: any) {
  return $inject.get('serviceHelper');
}

export const serviceHelperProvider = {
  provide: ServiceHelper,
  useFactory: serviceHelperFactory,
  deps: ['$injector'],
};
