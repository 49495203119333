import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthResponse, PreRegistrationRequest } from '@app/authentication/models';
import { User, Username, ValidUsername } from '@app/enrollment/models';
import { FacingBrand } from '@core/enums';
import { CityAndStateInfo, OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

interface BrandData {
  brandName: string;
  marketingUrl: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'enrollment');
  }

  autoRegister(token: string) {
    const endpoint = `autoRegister/${token}`;

    return this.post<AuthResponse>(endpoint);
  }

  completeRegistrationPasswordReset(preRegistrationRequest: PreRegistrationRequest): WebApiResponse<null> {
    return this.post('completeOlbRegistration', preRegistrationRequest);
  }

  completeRegistration(username: Username) {
    return this.post(`preregistry/complete`, username);
  }

  completeRegistrationProxy(username: PreRegistrationRequest) {
    return this.post(`preregistry/complete`, username);
  }

  createUser(user: User | ValidUsername) {
    const { brandCode } = this.settings;
    const userPayload: (User | ValidUsername) & Pick<OlbSettings, 'brandCode'> = {
      ...user,
      brandCode,
    };

    return this.post('SignUp', userPayload);
  }

  getApproveRegistrationInformation(id: string) {
    const endpoint = `frq/approve/${id}`;

    return this.get<ValidUsername | AuthResponse>(endpoint);
  }

  getPreregisteredUserInformation(id: string) {
    const endpoint = `preregistry/info/${id}`;

    return this.get<ValidUsername | AuthResponse>(endpoint);
  }

  validateUsernameInfo(username: string): WebApiResponse<string> {
    return this.post('validate/usernameInfo', { username });
  }

  validateInformation(user: User) {
    const headers = { brandId: `${this.settings.brandId}` };
    const endpoint = 'validate/customer';

    return this.post(endpoint, user, headers);
  }

  getBrandData(): BrandData {
    const { facingBrandId, brandName } = this.settings;
    let title: string;
    let marketingUrl: string;

    switch (facingBrandId) {
      case FacingBrand.Axos:
        title = 'Axos';
        marketingUrl = this.settings.axosMarketingUrl;
        break;
      case FacingBrand.Nationwide:
        title = 'Nationwide Banking Services by Axos Bank';
        marketingUrl = this.settings.nationwideMarketingUrl;
        break;
      case FacingBrand.UFB:
        title = 'UFB Direct';
        marketingUrl = this.settings.ufbMarketingUrl;
        break;
    }

    return {
      marketingUrl,
      title,
      brandName,
    };
  }

  /**
   * Gets the city and state information based on the given zip code.
   *
   * @param zipCode Zip code to check.
   *
   * @returns City and state information that matches the given zip code.
   */
  getCityAndState(zipCode: string): WebApiResponse<CityAndStateInfo> {
    return this.get(`zip/${zipCode}`);
  }

  /**
   * Gets the catalog of available security questions.
   *
   * @returns An Observable containing a list of the security questions.
   */
  getSecurityQuestions() {
    return this.get<string[]>('questions');
  }
}
