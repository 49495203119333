import { AnyAction } from 'redux';
import { ACCOUNTS } from '../../constants/accounts';

import { InternalAccountState } from 'typings/app/InternalAccountState';

const initialState: InternalAccountState = {
  accountsLoaded: false,
  balanceLoaded: false,
  interceptorsLoaded: false,
};

/**
 * Functions encharged of handling the transformations in the store
 * NOTE: This function never mutates the state directly, redux does
 */
export function InternalAccountReducer(
  state: InternalAccountState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case ACCOUNTS.INTERNAL.ACCOUNTS_LOADED:
      return Object.assign({}, state, {
        accountsLoaded: true,
      });
    case ACCOUNTS.INTERNAL.BALANCE_LOADED:
      return Object.assign({}, state, {
        balanceLoaded: true,
      });
    case ACCOUNTS.INTERNAL.INTERCEPTORS_LOADED:
      return Object.assign({}, state, {
        interceptorsLoaded: true,
      });

    default:
      return state;
  }
}
