export enum AlertsTopics {
  SetupEmailAlert = 'SetupEmailAlert',
  SetupTextAlert = 'SetUpTextAlert',
  StopAlerts = 'StopAlerts',
}

export enum BillPayTopics {
  CancelAutomaticRecurringPayment = 'CancelAutomaticRecurringPayment',
  EditAutomaticRecurringPayment = 'EditAutomaticRecurringPayment',
  SetupAutomaticPayments = 'SetUpAutomaticPayments',
  SetupEbills = 'SetUpEBills',
  SetupPayee = 'SetUpPayee',
  SetupRecurringPayments = 'SetUpRecurringPayments',
  FixBillPay = 'FixBillPay',
}
export enum DashboardTopics {
  MoveTile = 'MoveTile',
  AddDeleteTile = 'AddDeleteTile',
  ChangeContentTile = 'ChangeContentTile',
}

export enum DepositsLoansTopics {
  TransactionHistory = 'TransactionHistory',
  RoutingNumber = 'RoutingNumber',
  AccountNumber = 'AccountNumber',
  OrderChecks = 'OrderChecks',
  Statements = 'Statements',
  TaxForms = 'TaxForms',
  MakePayment = 'MakePayment',
  EscrowInformation = 'EscrowInformation',
  DormantAccounts = 'DormantAccounts',
}

export enum DepositsTopics {
  DepositCheck = 'DepositACheck',
  MobileDeposit = 'MobileDeposit',
  CheckDeposit = 'CheckDeposit',
  DepositAccepted = 'DepositAccepted',
}

export enum OtherTopics {
  ProfileIcon = 'ProfileIcon',
  UpdateContactInfo = 'UpdateContactInfo',
  ChangePassword = 'ChangePassword',
  SignerChanges = 'SignerChanges',
}

export enum PayItNowTopics {
  PayToIndividual = 'PayToIndividual',
}

export enum TransfersTopics {
  RequestTransfer = 'RequestTransfer',
  RequestTransferToExternal = 'RequestTransferToExternal',
  TransferExternalToBusiness = 'TransferExternalToBusiness',
  RequestWire = 'RequestWire',
}

export type HowToTopics =
  | AlertsTopics
  | BillPayTopics
  | DashboardTopics
  | DepositsLoansTopics
  | DepositsTopics
  | OtherTopics
  | PayItNowTopics
  | TransfersTopics;
