<h3 class="mt-5 mb-4">How do I cancel a recurring bill payment?</h3>
<ol>
  <li>
    Click on the
    <b>Pay Bills tab.</b>
  </li>
  <li>
    Next click on
    <b>Scheduled Payments.</b>
    You will then see a list of the payments that you have scheduled.
  </li>
  <li>
    Click on
    <b>Remove</b>
    for the payment that you want to cancel.
  </li>
  <li>
    You will get a prompt asking if you are sure that you want to remove the scheduled payment. If
    you want to continue with the cancellation, click
    <b>Remove.</b>
  </li>
</ol>
