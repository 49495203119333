import { GroupedDocumentsByMonth } from './grouped-documents-by-month.model';

export class GroupedDocuments {
  year: string;
  documentsByMonth: GroupedDocumentsByMonth[];

  constructor(args?: Partial<GroupedDocuments>) {
    Object.assign(this, args);
  }
}
