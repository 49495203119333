import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { ServiceHelper } from '@legacy/services/service.helper';

import { NavigationIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-investor-checking-modal',
  templateUrl: './investor-checking-modal.component.html',
  styleUrls: ['./investor-checking-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingModalComponent {
  @Output() displaySpeedBump = new EventEmitter<void>();
  @Output() skipFunction = new EventEmitter<void>();
  @Output() proceedFunction = new EventEmitter<void>();
  @Input() displayUrl: string;
  @Input() otherUrl: string;
  @Input() brandName: string;

  closeIcon = NavigationIcons.Ex;
  bellIcon = UtilityIcons.Bell;
  constructor(private agreementsService: AgreementService, private serviceHelper: ServiceHelper) {}

  closeSpeedBump() {
    this.displaySpeedBump.emit();
  }

  skipSpeedBump() {
    this.updateAgreement(false);
  }

  onProceed() {
    this.updateAgreement(false);
    window.open(this.displayUrl, '_blank');
  }

  updateAgreement(value: boolean) {
    this.agreementsService
      .updateAgreementsByValue(value, AasAgreementType.MultiAccountAgreementInvestorChecking)
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: response => {
          if (response.data) {
            if (value) {
              this.proceedFunction.emit();
            } else {
              this.skipFunction.emit();
            }
          }
        },
      });
  }
}
