<div class="header-section-title text-center">
  <div>
    <h1>Alerts &amp; Notifications</h1>
    <p class="hide-cs hide-xs" id="padding-top-account">
      Receive customized text and email alerts for your key account activities.
    </p>
  </div>
</div>
<div class="hr-line"></div>

<app-dot-loader *ngIf="isLoading || noAxosAccountsIsLoading || (isLoading$ | async)">
  Loading alerts &amp; notifications
</app-dot-loader>
<div class="my-alerts" *ngIf="!(isLoading && (isLoading$ | async)) || !noAxosAccountsIsLoading">
  <header>
    <h3 class="alerts__title">
      My Alerts
      <a class="link" [href]="'AlertsNotifications/AlertsHistory'">View Alert History</a>
    </h3>
    <p class="description">
      Get notified when changes occur. To set up a new alert, select from the list below.
    </p>
    <div class="th-noPadding" *ngIf="noAxosProducts">
      <br />
      <p>
        There are currently no alerts available for your accounts.
      </p>
    </div>
  </header>

  <div *ngIf="!noAxosProducts">
    <!--My Alerts Section -->
    <section>
      <div class="div-alerts">
        <div class="alert-content">
          <app-alert
            *ngFor="let alert of availableAlerts"
            [alert]="alert"
            (alertSelected)="addAlert($event)"
          ></app-alert>
        </div>
      </div>
      <div *ngFor="let saves of saveSuccess">
        <!-- track by $index -->
        <div class="error-success-div" *ngIf="saveSuccess != null">
          <i class="bofi-close green-icon" (click)="deletemsgBox()"></i>
          <p>
            <i class="bofi-check-mark green-icon"></i>
            {{ saves }}
          </p>
        </div>
      </div>
      <div class="add-alert-box" *ngIf="!loadingUserAlerts && !loadingUpdateUserAlerts">
        <div>
          <span *ngIf="removeAlert$ | async" class="loading"></span>
          <div *ngFor="let alert of userAlerts; index as indexAlert">
            <app-create-alert
              [alert]="alert"
              [indexAlert]="indexAlert"
              [accounts]="accounts"
              [deletingAlert]="deletingAlert"
              [editingAlert]="editingAlert"
              (cancelAlertEvent)="cancelAlertEvent($event)"
              (saveSuccess)="addmsgBox($event)"
              (deleteAlert)="removeAlert($event)"
            ></app-create-alert>
          </div>
          <!-- <create-alert ng-repeat="alert in $mac.userAlerts track by alert.id"></create-alert> -->
        </div>
      </div>
      <div class="alerts-updates" *ngIf="loadingUpdateUserAlerts && !loadingUserAlerts">
        <app-dot-loader *ngIf="loadingUpdateUserAlerts && !loadingUserAlerts && (loadingUserAlerts$ | async)">
          Updating Alerts Settings...
        </app-dot-loader>
      </div>
      <div class="alerts-updates" *ngIf="loadingUserAlerts && !loadingUpdateUserAlerts">
        <app-dot-loader *ngIf="loadingUserAlerts && !loadingUpdateUserAlerts && (loadingUserAlerts$ | async)">
          Loading your alerts...
        </app-dot-loader>
      </div>
    </section>

    <div class="hr-line" *ngIf="!noAxosProducts"></div>

    <!--Delivery Section Method-->
    <!-- ng-controller="DeliveryMethodController as $dmc" -->
    <section class="delivery-method" [ngClass]="{ expanded: !expanded }" *ngIf="!noAxosProducts">
      <div class="section-title-container">
        <i class="chevron" (click)="toggleExpand()"></i>
        <h3 class="sections">Delivery Method</h3>
        <p class="description">
          Configure the delivery of your custom alerts.
        </p>
      </div>
      <!-- <div class="error-success-div" *ngIf="$dmc.isEmailSave">
      <i class="bofi-close green-icon" ng-click="$dmc.hideEmailSucces()"></i>
      <p>
        <i class="bofi-check-mark green-icon"></i>
        Changes Saved. Your email address has been changed.
      </p>
    </div> -->
      <div class="error-success-div" *ngIf="isDisableSMS">
        <i class="bofi-close green-icon" (click)="hideDisableSMS()"></i>
        <p>
          <i class="bofi-check-mark green-icon"></i>
          Changes saved. You will no longer receive custom alert text messages.
        </p>
      </div>
      <div *ngIf="!expanded" class="delivery-container">
        <form #myForm="ngForm">
          <div class="frow">
            <div class="fcol-sm-6">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <i class="bofi-messages delivery-icon"></i>
                  <p class="panel-heading-text">Email Alerts</p>
                </div>
                <div class="body" *ngIf="!savingEmailSetting && !(savingEmailSetting$ | async)">
                  <div class="checkbox-phone">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="email"
                      [(ngModel)]="deliveryMethodSettings.emailAlertEnabled"
                      (change)="setEmailNotificationSetting('delivery', true)"
                    />

                    <label for="email" class="emailModal deliverBig">
                      Enable email alert notifications. Send to:
                    </label>
                    <input
                      type="checkbox"
                      class="checkbox checkSmall"
                      id="email"
                      [(ngModel)]="deliveryMethodSettings.emailAlertEnabled"
                      (change)="setEmailNotificationSetting('delivery', true)"
                    />
                    <!-- ng-change="$dmc.setEmailNotificationSetting('delivery')" -->

                    <label for="email" class="emailModal deliverSmall">
                      Enable email alert notifications.
                    </label>
                  </div>
                  <div class="inlineBottom text-centered">
                    <label for="email" class="emailModal deliverSmall">Send to:</label>
                    <p *ngIf="!showEmailTooltip" class="deliveryEmail gray"></p>
                    <!-- [textContent]="userEmail" -->
                    <div>
                      <!-- class="tooltip-container" -->
                      <span *ngIf="!showEmailTooltip" class="deliveryEmail gray" [textContent]="truncatedEmail"></span>
                      <span class="deliveryEmail gray" *ngIf="showEmailTooltip">{{ userInfo.email }}</span>
                    </div>
                  </div>
                </div>
                <div class="body" *ngIf="savingEmailSetting || (savingEmailSetting$ | async)">
                  <app-dot-loader>Updating...</app-dot-loader>
                </div>
              </div>
            </div>

            <div class="fcol-sm-6">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <i class="bofi-text-message delivery-icon"></i>
                  <p class="panel-heading-text">SMS Alerts</p>
                </div>
                <div class="body" *ngIf="!savingSMSSetting">
                  <div class="checkbox-phone">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="sms"
                      name="sms"
                      #smsInput="ngModel"
                      (change)="
                        setSMSNotificationSetting('delivery', deliveryMethodSettings.smsAlertEnabled, smsInput.value)
                      "
                      [(ngModel)]="deliveryMethodSettings.smsAlertEnabled"
                      [ngClass]="deliveryMethodWasCanceled ? 'displayCheckboxBorder' : ''"
                    />
                    <label class="deliverBig" for="sms">
                      Enable text alert notifications. Send to:
                    </label>
                    <input
                      type="checkbox"
                      class="checkbox checkSmall"
                      id="sms"
                      name="sms"
                      #smsInput="ngModel"
                      (change)="
                        setSMSNotificationSetting('delivery', deliveryMethodSettings.smsAlertEnabled, smsInput.value)
                      "
                      [(ngModel)]="deliveryMethodSettings.smsAlertEnabled"
                      [ngClass]="deliveryMethodWasCanceled ? 'displayCheckboxBorder' : ''"
                    />
                    <label class="deliverSmall" for="sms">Enable text alert notifications.</label>
                  </div>
                  <div class="inlineBottom">
                    <p class="gray" *ngIf="deliveryMethodSettings.smsAlertEnabled && (userPhoneNumber$ | async)">
                      {{ userPhoneNumber }}
                  </p>
                    <p class="gray" *ngIf="!deliveryMethodSettings.smsAlertEnabled && (userPhoneNumber$ | async)">
                    (xxx) xxx - xxxx
                  </p>
                  </div>
                  <!-- <div ng-if="$dmc.isPhoneEditing" class="email-changes-section">
                  <div align="center">
                    <input
                      class="uk-input sm emailAlerts"
                      name="emailInput"
                      type="text"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      value="{{$dmc.userInfo.phoneNumber}}"
                      ng-model="$dmc.userInfo.phoneNumber"
                      aria-label="Phone number"
                    />
                  </div>
                  <div align="right" class="buttons-container">
                    <button class="uk-btn outline primary sm" ng-click="$dmc.editPhone()" type="button">
                      Cancel
                    </button>
                    <button class="uk-btn sm solid secondary" ng-click="$dmc.savePhone()">
                      Save
                    </button>
                  </div>
                </div> -->
                </div>
                <div class="body" *ngIf="savingSMSSetting && (savingSMSSetting$ | async)">
                  <app-dot-loader>Updating...</app-dot-loader>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="openPopup$ | async">
        <app-sms-modals
          [phonenumber]="userInfo.phoneNumber"
          [smstermsandconditionsbrand]="smsTermsAndConditionsBrand"
          [brandname]="brandName"
          [userphonenumber]="userPhoneNumber"
          [otpvalidationmessage]="otpValidationMessage"
          [multifactorserviceevent]="multifactorServiceEvent"
          [sendingcode]="sendingCode"
          [errorcodeevent]="errorCode"
          [gettingcode]="gettingCode"
          (openaccesscodemodal)="openAccessCodeModal()"
          (accesscodemodal)="submitAccessCode($event)"
          (closemodal)="closeModal()"
          (resendcodeevent)="resendCode()"
        ></app-sms-modals>
      </div>
      <div *ngIf="smsModalLoading$ | async" class="app-sms-modals"></div>
    </section>
    <!-- 2 Way SMS Section -->

    <section id="sms-section" *ngIf="!noAxosProducts">
      <hr class="hr-bold-line" />
      <div class="row flex-column align-items-center">
        <div class="col-lg-10 row">
          <div class="col-lg-1 text-center">
            <img src="/img/icons/alerts/sms.svg" alt="sms" />
          </div>
          <div class="col-lg-11 smsTitle">
            <div id="title">Text Commands Cheat Sheet</div>
            <div>
              Text the following commands to
              <strong>23232</strong>
              and get a response within seconds.
            </div>
          </div>
          <div class="error-success-div errordiv col-lg-12" *ngIf="saveSmsSuccess$ | async">
            <i class="bofi-close green-icon" (click)="saveSmsSuccess$.next(false)"></i>
            <p>
              <i class="bofi-check-mark green-icon"></i>
              Success! Changes to your settings have been saved.
            </p>
          </div>
        </div>
      </div>
      <div class="row flex-column align-items-center">
        <div class="col-lg-10">
          <div class="main-container">
            <div class="row-container">
              <div class="left-container">
                <span>BAL</span>
              </div>
              <div class="right-container">Get balances for all of your accounts.</div>
            </div>
            <div class="row-container">
              <div class="left-container">
                <span>LAST</span>
              </div>
              <div class="right-container">
                <span>
                  Get the last
                  <select
                    name="lastTransactions"
                    aria-label="select number of transactions"
                    class="type-dropdown transaction-select"
                    [(ngModel)]="lastTrans"
                    (change)="editMode = true"
                  >
                    <option [ngValue]="numberOption" *ngFor="let numberOption of transNumberOptions">
                      {{ numberOption }}
                    </option>
                  </select>
                  transactions from all accounts.
                </span>
              </div>
              <div *ngIf="editMode">
                <div class="left-container">
                  <!-- an empty div to preserve layout flow -->
                </div>
                <div class="right-container">
                  <div class="save-changes-container" [ngClass]="{ 'open-box': editMode, 'close-box': !editMode }">
                    <div class="half-container">
                      <strong>Would you like to save your changes?</strong>
                    </div>
                    <div class="half-container">
                      <button class="uk-btn outline primary sm" type="button" (click)="cancelTransNumber()">
                        Cancel
                      </button>
                      <button class="uk-btn sm solid secondary" type="button" (click)="chngTransNumber()">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <app-dot-loader *ngIf="savingTrans && (savingTrans$ | async)"></app-dot-loader>
            </div>
            <div class="row-container">
              <div class="left-container">
                <span>
                  TRANS + Amount
                  <app-tooltip class="smsTooltip" [content]="smsTooltip"></app-tooltip>
                </span>
              </div>
              <div class="right-container">
                <span>Transfer funds from &nbsp;</span>
                <app-click-dropdown
                  *ngIf="dropFrom.length > 0"
                  [dropdownItems]="dropFrom"
                  [selectedItem]="fromAccount"
                  (item)="fromChange($event)"
                ></app-click-dropdown>
                <app-dot-loader *ngIf="dropFrom.length === 0" class="label-loading"></app-dot-loader>
                <span>&nbsp; to &nbsp;</span>
                <app-click-dropdown
                  *ngIf="dropFrom.length > 0 && !(dropToLoading$ | async)"
                  [dropdownItems]="dropTo"
                  [selectedItem]="toAccount"
                  (item)="toChange($event)"
                ></app-click-dropdown>
                <app-dot-loader *ngIf="dropFrom.length === 0" class="label-loading"></app-dot-loader>
                <app-dot-loader *ngIf="dropToLoading$ | async" class="label-loading"></app-dot-loader>
              </div>
              <div *ngIf="editAccMode">
                <div class="left-container">
                  <!-- an empty div to preserve layout flow -->
                </div>
                <div class="right-container">
                  <div class="save-changes-container" [ngClass]="{ 'open-box': editMode, 'close-box': !editAccMode }">
                    <div class="half-container">
                      <strong>Would you like to save your changes?</strong>
                    </div>
                    <div class="half-container">
                      <button class="uk-btn outline primary sm" type="button" (click)="cancelTransAccounts()">
                        Cancel
                      </button>
                      <button class="uk-btn sm solid secondary" type="button" (click)="changeSmsConfiguration()">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <app-dot-loader *ngIf=""></app-dot-loader>
            </div>
            <div class="row-container">
              <div class="left-container">
                <span>
                  ACT + Last 4 digits of the debit card + Last 4 digits of your SSN
                  <app-tooltip class="smsTooltip fixed-position-tooltip" [content]="actTooltip"></app-tooltip>
                </span>
              </div>
              <div class="right-container">
                <div class="description-position">
                  <span class="fixed-position">Activates your debit card</span>
                </div>
              </div>
            </div>
            <div class="row-container">
              <div class="left-container">
                <span>MENU</span>
              </div>
              <div class="right-container">Get help keywords.</div>
            </div>
            <div class="row-container">
              <div class="left-container">
                <span>HELP</span>
              </div>
              <div class="right-container">Get contact information.</div>
            </div>
            <div class="row-container">
              <div class="left-container">
                <span>STOP</span>
              </div>
              <div class="right-container">Unsubscribe from all {{ brandName }} text messages.</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="hr-line"></div>

    <!--Manage Privacy & Preferences -->
    <section class="information">
      <div>
        <i class="bofi-information info"></i>
      </div>
      <div class="th-padding">
        <h3 class="pading-info">Alert &amp; Notification Information</h3>
        <div *ngIf="!noAxosProducts">
          <br />
          <p>
            To stop receiving email or text messages for a custom alert, please uncheck the box for each custom alert
            you no longer wish to receive.
          </p>
          <br />
          <p>
            To stop all alert notifications, you can deselect Email or SMS Alerts in the Delivery Method section above.
            Note: you can also text
            <span class="stop">STOP</span>
            following receipt of a text alert to deactivate all text messages, including marketing related texts.
          </p>
        </div>
        <br />
        <p>
          All important bank notifications will be automatically sent to your email to protect your account's security.
          Important bank notifications include any changes to your personal information, notifications of password
          updates and others. These notifications are not optional and cannot be turned off.
        </p>
        <br />
        <a class="bofi-link" [href]="'AlertsNotifications/Preferences'">
          Manage Your Privacy Preferences
        </a>
      </div>
    </section>
  </div>
</div>
