import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TxOptionType } from '@app/accounts/components/account-transactions/types';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-tx-options',
  templateUrl: './tx-options.component.html',
  styleUrls: ['./tx-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxOptionsComponent implements OnChanges {
  @Input() options: string[];
  @Input() isDisabled: boolean;
  @Input() isExpanded: boolean = false;
  @Input() isDetailsExpanded: boolean = false;
  @Output() onOptionSelected = new EventEmitter<TxOptionType>();
  @Output() onCollapse = new EventEmitter<void>();

  @ViewChild('txOptionsTrigger') txOptionsTrigger: MatMenuTrigger;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled) {
      if (this.isDisabled == false) {
        this.isExpanded = false;
        this.isDetailsExpanded = false;
      }
    }
  }

  /**
   * Triggers the function specified for the option selected
   * @param option Option selected
   */
  executeAction(option: TxOptionType) {
    this.isExpanded = option.isCollapsible;
    this.isDetailsExpanded = !this.isDetailsExpanded;
    this.onOptionSelected.emit(option);
  }

  /** Triggers the collapse function */
  collapse() {
    this.isExpanded = false;
    this.isDetailsExpanded = !this.isDetailsExpanded;
    this.onCollapse.emit();
    if (this.txOptionsTrigger) {
      this.txOptionsTrigger.closeMenu();
    }
  }

  moreOptionsClicked() {
    this.isDetailsExpanded = false;
  }
}
