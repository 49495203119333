import { ProviderService } from '@legacy/services/provider.service';

export function providerServiceFactory($injector: any) {
  return $injector.get('providerService');
}

export const providerServiceProvider = {
  provide: ProviderService,
  useFactory: providerServiceFactory,
  deps: ['$injector'],
};
