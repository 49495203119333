import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportModule } from '@app/support/support.module';
import { messageComponents } from './components';
import { SharedModule } from '@shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [...messageComponents],
  imports: [CommonModule, SupportModule, SharedModule, FormsModule, MatExpansionModule, RouterModule],
  exports: [...messageComponents, RouterModule],
})
export class MessagesModule {}
