export enum SendUntilOption {
  IChooseToStop = 0,
  LastTransferDate = 1,
  NumberOfTransfers = 2,
}

//TODO: Move this to a pipetransform
export function mapSendUntil(option: number): string {
  switch (option) {
    case SendUntilOption.IChooseToStop:
      return 'I choose to stop';
    case SendUntilOption.NumberOfTransfers:
      return 'Number of transfers';
    case SendUntilOption.LastTransferDate:
      return 'Last Transfer Date';
    default:
      return '';
  }
}
