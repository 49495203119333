import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { IStateParamsService } from 'angular-ui-router';


import { AggregatedAccount } from '@app/accounts/models';
import { DialogService } from '@core/services';
import { STATE, STATE_PARAMS, WINDOW } from '@core/tokens';
import { FastlinkFlow } from '@legacy/dashboard/account-aggregation/enums/fast-link-flow.enum';
import { FastlinkOauthMigrationStatus } from '@legacy/dashboard/account-aggregation/enums/fast-link-OAuth-Migration-Status-enum';
import { FastlinkResponseEnum } from '@legacy/dashboard/account-aggregation/enums/fast-link-response-enum';
import { FastlinkUpdateEligibility } from '@legacy/dashboard/account-aggregation/enums/fast-link-Update-Eligibility-enum';
import { AddBulkVm } from '@legacy/dashboard/account-aggregation/typings/AddBulkVm';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { Institution } from '@legacy/typings/app/account-aggregation';
import { FastlinkConfiguration } from '@legacy/typings/app/account-aggregation/FastlinkConfiguration';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { DialogComponent } from '@shared/components';
import { DialogData } from '@shared/models';


export const window: any = new InjectionToken('Window');

@Component({
  selector: 'app-fast-link',
  templateUrl: './fast-link.component.html',
  styleUrls: ['./fast-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FastLinkComponent implements OnInit, OnDestroy {

  isLoading = true;
  fastlinkConfiguration: FastlinkConfiguration;
  selectedBank: Institution;
  flowFromChecks: FastlinkFlow;
  isAnErrorActivated = false;

  dialog: MatDialogRef<DialogComponent>;

  private flowFromURL: string;
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private stateParams: IStateParamsService,
    @Inject(WINDOW) private windowGlobal: Window,
    private accountAggregationService: AccountAggregationService,
    private serviceHelper: ServiceHelper,
    private changeDetector: ChangeDetectorRef,
    private cachedAccountsService: CachedAccountsService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getFastlinkConfiguration();
  }

  ngOnDestroy() {
    this.closeFastLink();
  }

  updateLoadBar() {
    this.isLoading = !this.isLoading;
    this.changeDetector.detectChanges();
  }

  checkCachedAccountsForEdit(err) {
    if (err.additionalStatus === 'INCORRECT_CREDENTIALS' && this.flowFromURL === 'edit') {
      this.cachedAccountsService.aggregatedAccounts.forEach((item) => {
        item.credentialsHaveChanged = true;
        item.updateEligibility = FastlinkUpdateEligibility.ALLOW_UPDATE_WITH_CREDENTIALS;
        item.oAuthMigrationStatus = FastlinkOauthMigrationStatus.None;
      });
    }
  }

  show() {
    this.windowGlobal.fastlink.open(
      {
        forceIframe: true,
        fastLinkURL: `${this.fastlinkConfiguration.fastLinkUrl}`,
        accessToken: `Bearer ${this.fastlinkConfiguration.accessToken}`,
        params: this.getFastlinkParams(),
        onSuccess: () => {
        },
        onError: (err) => {
          this.checkCachedAccountsForEdit(err);
          this.syncAccounts();
          this.throwFastLinkError(err);

          return;
        },
        onClose: async (data) => {

          if (!this.isAnErrorActivated) {
            try {
              this.updateLoadBar();
              let success: any[];
              if (data.action === 'exit' && data.sites.length > 0) {

                success = data.sites.filter(element => element.status === FastlinkResponseEnum.SUCCESS || element.additionalStatus === FastlinkResponseEnum.NO_ACCOUNTS_VERIFICATION);
                if (success.length > 0) {
                  const dataFromApi = (await this.addSelectedAccounts(data)).data;
                  this.updateLoadBar();

                  if (dataFromApi.length === 0) {
                    this.goBack();
                  } else {
                    this.goSuccess(dataFromApi);
                  }

                  return;
                }

                // Used to close the component if the user cancels the process without finish
                if (data.sites.filter(element => element.status === FastlinkResponseEnum.ACTION_ABANDONED)) {
                  this.goBack();

                  return;
                }

              } else {
                this.goBack();

                return;
              }
            } catch (error) {
              this.throwFastLinkError(error);

              return;
            }
          } else {
            this.goBack();

            return;
          }

        },
        onEvent: (data) => {
          if (data.action === 'linkAnotherSite' && data.fnToCall === 'accountStatus') {
            this.goSearchFI();
          }
        },
      },
      'container-fastlink'
    );
  }

  goSearchFI() {
    this.closeFastLink();
    this.state.go('udb.accounts.dashboard', { flow: this.flowFromURL });
  }

  closeFastLink() {
    this.windowGlobal.fastlink.close();
  }


  goToManually() {
    this.state.go('udb.accounts.add-external', { flow: this.flowFromURL });
  }


  goBack() {

    let path: string;
    let params = {};

    switch (this.flowFromURL) {
      case 'aggregation': case 'edit':
        path = 'udb.accounts.dashboard';
        params = { flow: FlowType.Aggregation };
        break;
      case 'funding':
        path = 'udb.funding';
        params = { flow: FlowType.Funding };
        break;
      case 'moveMoney':
        path = 'udb.transfers.transferFunds';
        params = { flow: FlowType.MoveMoney };
        break;
      case 'details':
        path = 'udb.accounts.external-details';
        params = {
          id: this.stateParams.accountId,
          container: this.stateParams.container,
          tab: 1,
        };
        break;
      default:
        path = 'udb.accounts.account-aggregation';
        params = {
          isAccountAggregationFlow: false,
          isPfm3Active: true,
        };
        break;
    }

    this.closeFastLink();
    this.state.go(path, params);
  }

  goSuccess(data: any) {
    this.state.go('udb.dashboard.account-aggregation.auth-success', {
      accounts: data,
      newPayverisAccounts: data,
      institution: this.selectedBank,
      isAccountAggregationFlow: false,
      isMoveMoneyFlow: false
    });
  }

  async getFastlinkConfiguration() {
    try {
      this.flowFromChecks = this.stateParams.flowFromChecks;
      this.flowFromURL = this.stateParams.flow;
      this.selectedBank = this.stateParams.institution as Institution;

      if (!this.selectedBank) {
        const accountSelected = this.stateParams.accountSelected as AggregatedAccount;

        if (accountSelected.accountNumber) {
          this.selectedBank = { fastlinkFlow: FastlinkFlow.VerificationAggregation, bankId: Number(accountSelected.providerId) };
        }
        else {
          this.selectedBank = { fastlinkFlow: FastlinkFlow.AggregationOnly, bankId: Number(accountSelected.providerId) };
        }
        this.flowFromChecks = this.selectedBank.fastlinkFlow;

      } else {
        this.selectedBank.fastlinkFlow = this.flowFromChecks ? this.flowFromChecks : this.selectedBank.fastlinkFlow;
      }

      const infoResponse = await this.accountAggregationService.getFastlinkConfiguration(this.selectedBank.fastlinkFlow);
      this.fastlinkConfiguration = infoResponse.data;
      this.show();
      this.updateLoadBar();

    } catch (error) {
      this.throwFastLinkError(error);
    }
  }

  throwError(error: any) {
    this.serviceHelper.errorHandler(error, true, () => this.goBack());
  }

  throwFastLinkError(error: any) {
    this.isAnErrorActivated = true;
    if (error.data.message === 'No accounts for Move Money added') {
      const message = `Your ${this.selectedBank.name} accounts could not be set up for Move Money. We apologize for the inconvenience and recommend adding the impacted accounts manually`;
      const dialogData = new DialogData({
        title: 'Please Add Your Move Money Accounts Manually',
        content:
          `<p class="ms-secondary-text">${message}</p>`,
        cancelText: this.flowFromURL === 'moveMoney' ? 'Continue Transfer Set Up' : 'Back',
        okText: 'Add Manually',
        hasCloseButton: false,
      });
      this.dialog = this.dialogService.open(dialogData);
      this.dialog.afterClosed().subscribe((data) =>
        data ? this.goToManually() : this.goBack()
      );
    } else {
      this.serviceHelper.fastLinkerrorHandler(error, true, () => this.goBack());
    }
  }

  async addSelectedAccounts(data: any) {
    const vm: AddBulkVm = {
      accounts: [],
      bankAuthRequest:
      {
        authParameter: {
          bankId: data.sites[0].providerId,
          countryISOCode: this.selectedBank.countryISOCode,
          fastlinkFlow: this.selectedBank.fastlinkFlow,
          forgetPasswordUrl: this.selectedBank.forgetPasswordUrl,
          forms: this.selectedBank.forms,
          isLocal: this.selectedBank.isLocal,
          logo: this.selectedBank.logo,
          name: data.sites[0].providerName,
          providerId: data.sites[0].providerId,
          url: this.selectedBank.url
        },
        providerAccountId: data.sites[0].providerAccountId,
        requestId: data.sites[0].requestId
      }
    };

    data.sites.forEach(site => {
      vm.accounts.push(
        {
          AggregatedAccntId: site.accountId,
          externalAccountId: 0,
          selected: true
        });
    });

    return await this.accountAggregationService.syncFastLinkAccounts(vm, this.flowFromChecks || this.selectedBank.fastlinkFlow);
  }
  getFastlinkParams() {
    const fastlinkOpenConfig = {
      configName: this.fastlinkConfiguration.flowName,
      flow: this.stateParams.flow === 'edit' ? this.stateParams.flow : 'add',
    };
    switch (this.flowFromURL) {
      case 'edit':
        fastlinkOpenConfig['providerAccountId'] = this.stateParams.providerAccountId;
        break;

      default:
        fastlinkOpenConfig['providerId'] = this.selectedBank.bankId;
        break;
    }

    return fastlinkOpenConfig;
  }
  async syncAccounts() {
    return await this.accountAggregationService.syncFastLink();
  }
}
