<div class="security-questions">
  <div class="col-sm-12 center security-questions__title">
    <h1>
      Security Questions
    </h1>
    <p>For additional account protection, please set up three security questions that only you will know.</p>
  </div>

  <form
    class="col-sm-12 center security-questions__form"
    [formGroup]="securityQuestionsForm"
    (ngSubmit)="saveSecurityQuestions()"
  >
    <!-- Question 1 -->
    <div class="group--form col-sm-12">
      <label for="question1Id" class="uk-label">Security Question 1</label>
      <select
        name="question1Id"
        id="question1Id"
        class="uk-input bofi-col-100--lg"
        formControlName="question1Id"
        (change)="filterQuestions(1)"
        required
      >
        <option value="" disabled selected>Question</option>
        <option
          *ngFor="let question of firstQuestions; trackBy: trackBySecurityQuestionId.bind(this,'question1')"
          [value]="question.questionId"
        >
          {{ question.questionText }}?
        </option>
      </select>
    </div>

    <!-- Answer 1 -->
    <div class="group--form col-sm-12">
      <div class="d-flex flex-column-reverse">
        <input
          id="answer1"
          name="answer1"
          title=""
          formControlName="answer1"
          type="password"
          class="uk-input"
          required
          placeholder="Answer"
        />
        <app-reveal-input-label
          [target]="'answer1'"
          [targetIsInvalid]="
            securityQuestionsForm.get('answer1').invalid && securityQuestionsForm.get('answer1').touched
          "
        ></app-reveal-input-label>
      </div>

      <div *ngIf="securityQuestionsValidator('answer1') as error">
        <span class="text-error">
          {{ error }}
        </span>
      </div>
    </div>

    <!-- Question 2 -->
    <div class="group--form col-sm-12">
      <label for="question2Id" class="uk-label">Security Question 2</label>
      <select
        name="question2Id"
        id="question2Id"
        class="uk-input bofi-col-100--lg"
        formControlName="question2Id"
        (change)="filterQuestions(2)"
        required
      >
        <option value="" disabled selected>Question</option>
        <option
          *ngFor="let question of secondQuestions; trackBy: trackBySecurityQuestionId.bind(this,'question2')"
          [value]="question.questionId"
        >
          {{ question.questionText }}?
        </option>
      </select>
    </div>

    <!-- Answer 2 -->
    <div class="group--form col-sm-12">
      <div class="d-flex flex-column-reverse">
        <input
          id="answer2"
          name="answer2"
          title=""
          formControlName="answer2"
          type="password"
          class="uk-input"
          required
          placeholder="Answer"
        />
        <app-reveal-input-label
          [target]="'answer2'"
          [targetIsInvalid]="
            securityQuestionsForm.get('answer2').invalid && securityQuestionsForm.get('answer2').touched
          "
        ></app-reveal-input-label>
      </div>

      <div *ngIf="securityQuestionsValidator('answer2') as error">
        <span class="text-error">
          {{ error }}
        </span>
      </div>
    </div>

    <!-- Question 3 -->
    <div class="group--form col-sm-12">
      <label for="question3Id" class="uk-label">Security Question 3</label>
      <select
        name="question3Id"
        id="question3Id"
        class="uk-input bofi-col-100--lg"
        formControlName="question3Id"
        (change)="filterQuestions(3)"
        required
      >
        <option value="" disabled selected>Question</option>
        <option
          *ngFor="let question of thirdQuestions; trackBy: trackBySecurityQuestionId.bind(this,'question3')"
          [value]="question.questionId"
        >
          {{ question.questionText }}?
        </option>
      </select>
    </div>

    <!-- Answer 3 -->
    <div class="group--form col-sm-12">
      <div class="d-flex flex-column-reverse">
        <input
          id="answer3"
          name="answer3"
          title=""
          formControlName="answer3"
          type="password"
          class="uk-input"
          required
          placeholder="Answer"
        />
        <app-reveal-input-label
          [target]="'answer3'"
          [targetIsInvalid]="
            securityQuestionsForm.get('answer3').invalid && securityQuestionsForm.get('answer3').touched
          "
        ></app-reveal-input-label>
      </div>

      <div *ngIf="securityQuestionsValidator('answer3') as error">
        <span class="text-error">
          {{ error }}
        </span>
      </div>
    </div>

    <!-- Submit button -->
    <div class="col-12 col-lg-8 form-group">
      <button
        id="btn-finish"
        type="submit"
        class="btn-ok"
        [ngClass]="{ 'btn-finish': brandName === 'axos' }"
        [disabled]="isUnavailable"
      >
        Finish
      </button>

      <app-dot-loader [showWhen]="isBusy">
        Processing
      </app-dot-loader>
    </div>
  </form>
</div>
