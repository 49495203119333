<h3 class="mt-5 mb-4">How do I find the routing number?</h3>

<ol>
  <li>
    Click on the
    <b>Accounts tab</b>
    or the
    <b>Recent Transactions</b>
    tile.
  </li>
  <li>Click on the blue name of the account you want to see.</li>
  <li>
    Select the
    <b>Details tab</b>
    and your account details, including the routing number, are shown on this page.
  </li>
</ol>
