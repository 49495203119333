import { Action, createReducer, on } from '@ngrx/store';

import { accountsLoaded, providersLoaded, setAccountsLoading, setProvidersLoading } from './accounts.actions';
import { AccountsState } from './accounts.state';

export const initialState: AccountsState = new AccountsState();

const _reducer = createReducer(
  initialState,

  on(accountsLoaded, (state, action) => {
    return {
      ...state,
      accounts: action.payload,
    };
  }),
  on(setAccountsLoading, (state, action) => {
    return {
      ...state,
      accountsLoading: action.payload,
    };
  }),

  on(providersLoaded, (state, action) => {
    return {
      ...state,
      providers: action.payload,
    };
  }),
  on(setProvidersLoading, (state, action) => {
    return {
      ...state,
      providersLoading: action.payload,
    };
  })
);

export function accountsReducer(state: AccountsState, action: Action) {
  return _reducer(state, action);
}
