import { downgradeComponent } from '@angular/upgrade/static';
import { InterestitialPageComponent } from '@app/Areas/AAS/features/interestitial-page/view';

export const InterestitialPageLegacyModule = angular
  .module('udb.interestitialpage', ['ui.router'])
  .directive('appInterestitialPage', downgradeComponent({ component: InterestitialPageComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: ng.ui.IStateProvider) => {
      $stateProvider.state('udb.interestitialPage', {
        url: '/Welcome',
        views: {
          layout: {
            template: '<app-interestitial-page></app-interestitial-page>',
          },
        },
        params: {
          organizationData: null,
        },
      });
    },
  ]).name;
