import { RiaType } from '.';

export type RiaStateType = {
  rias: RiaType[];
  isLoaded: boolean;
  selectedRia: string | undefined;
};

export const initialRiaStateType: RiaStateType = {
  rias: [],
  isLoaded: false,
  selectedRia: undefined,
};
