import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { SubSink } from '@axos/subsink';

import { STATE } from '@core/tokens';

import { AccountDetail } from '../../models/account-detail.model';
import { GenericOption } from '../../models/generic-options.model';
import { Transaction } from '../../models/transaction.model';

@Component({
  selector: 'app-dispute-review',
  templateUrl: './dispute-review.component.html',
  styleUrls: ['./dispute-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisputeReviewComponent implements OnInit, OnDestroy {
  disputableHeaders: GenericOption[];
  nonDisputableHeaders: GenericOption[];
  transactions: Transaction[];
  disputableTransactions: Transaction[] = [];
  nonDisputableTransactions: Transaction[] = [];
  accountId: number;
  accountDetail: AccountDetail;
  total: number;
  private subsink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService
  ) {}

  ngOnInit(): void {
    if (!this.state.params?.accountId || !this.state.params?.transactions) {
      this.state.go('udb.dashboard');

      return;
    }

    this.accountId = this.state.params.accountId;
    this.transactions = this.state.params.transactions;
    this.accountDetail = new AccountDetail(this.state.params.accountDetail);

    this.disputableHeaders = [
      new GenericOption({
        value: 0,
        subvalue: 'transaction',
        label: 'Transaction',
        cssClass: 'transaction-name-header',
      }),
      new GenericOption({ value: 1, subvalue: 'amount', label: 'Amount' }),
    ];

    this.nonDisputableHeaders = [
      new GenericOption({
        value: 0,
        subvalue: 'transaction',
        label: 'Transaction',
        cssClass: 'transaction-name-header',
      }),
      new GenericOption({
        value: 1,
        subvalue: 'amount',
        label: 'Amount',
        cssClass: 'transaction-amount-header',
      }),
      new GenericOption({ value: 2, subvalue: 'reason', label: 'Reason' }),
    ];

    for (const transaction of this.transactions) {
      transaction.description = transaction.description.replace('<br/>', '');
      if (transaction.hasCheck || transaction.isPending) {
        this.nonDisputableTransactions.push(transaction);
      } else {
        this.disputableTransactions.push(transaction);
      }
    }

    /**
     * Sum all transactions amounts
     */
    if (this.disputableTransactions.length > 1) {
      this.total = this.disputableTransactions.reduce((acc, next) => acc + next.amount, 0);
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goToAccountDetails() {
    this.state.go('udb.accounts.details', { id: this.accountId });
  }

  goToAccountsDisputeDetails() {
    this.state.go('udb.accounts.dispute-details', {
      transactions: this.disputableTransactions,
      accountId: this.accountId,
      accountDetail: this.accountDetail,
      previousTrx: this.transactions,
    });
  }
}
