/**
 * Truncates the given string by the given length, adding the given end string.
 *
 * By default the length is of 10 characters and end will be '...'
 *
 * @param text Text to be truncated.
 * @param length Length at which the text will be truncated. Defaults to 10.
 * @param end String that will be appended at the end when the text is truncated. Defaults to '...'
 */
export function truncate(text: string, length = 10, end = '...') {
  if (!text || text.length <= length || text.length - end.length <= length) {
    return text;
  }

  const truncatedText = text.substring(0, length - end.length);

  return `${truncatedText}${end}`;
}
