import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { AccountTab } from '@app/accounts/enums';
import { getTradingAccount } from '@app/accounts/store/selectors';
import { NewsArticle, PortfolioOverview, TradingAccount } from '@app/axos-trading/models';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import * as OrderActions from '@app/axos-trading/store/orders/orders.actions';
import { STATE_PARAMS } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  @Input() portfolioOverview: PortfolioOverview;
  @Output() changeTab = new EventEmitter<AccountTab>();

  account: TradingAccount;
  news: NewsArticle[];
  lastUpdatedDate = new Date();
  loaded = false;
  icons = {
    triangleUp: NavigationIcons.TriangleUp,
    triangleDown: NavigationIcons.TriangleDown,
    equal: NavigationIcons.Equal,
    arrowForward: NavigationIcons.ArrowForward,
  };
  isShowExchangeAgreement = true;

  private accountId: number;
  private subSink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private store: Store,
    private routeHelper: TradingRoutingHelperService
  ) {}

  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.getTradingAccount();
    if (this.portfolioOverview.positions) {
      this.store.dispatch(OrderActions.loadPositionsSuccess({ positions: this.portfolioOverview.positions }));
    }
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  searchEquity() {
    this.changeTab.emit(AccountTab.Search);
  }

  goToMoveMoney() {
    this.routeHelper.goToTransferFunds();
  }

  exchangeAgreementState(event: boolean) {
    this.isShowExchangeAgreement = event;
  }

  private getTradingAccount() {
    this.subSink.sink = this.store
      .select(getTradingAccount(this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.account = account;
        this.loaded = true;
        this.lastUpdatedDate = new Date();
      });
  }
}
