import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import * as angular from 'angular';
import { AxosInvestService } from '@app/axos-invest/services';
import { AxosAdvisoryService, BusinessService, FundingService, SessionService } from '@core/services';

import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';
import { LayoutConfig } from './layout.config';
import { LayoutController } from './layout.controller';
import { LayoutRun } from './layout.run';
import { LearnMoreModalDelayedController } from './learn-more-modal/learn-more-modal.controller';
import { OutdatedContactInfoModalController } from './outdated-contact-info-modal/outdated-contact-info-modal.controller';
import { TimeOutModalController } from './timeout-modal/timeout-modal.controller';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { accountsService } from '@app/Areas/AAS/aas-shared/services/accounts.service';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { PendoTrackerService } from '@app/Areas/AAS/aas-shared/services/pendo-tracker/pendo-tracker.service';
import { BrandingSettingsFacade } from '@app/Areas/AAS/aas-core/branding-settings/facade';
import { FundingHeaderComponent } from '@shared/components';
import { SharedAccountsFacade } from '@app/Areas/AAS/features/account-details/facade/shared-accounts.facade';
import { SharedAccountsService } from '@app/Areas/AAS/features/account-details/core';

export const LayoutModule = angular
  .module('udb.layout', ['ui.router', 'ui.bootstrap'])
  .service('brandingSettingsFacade', downgradeInjectable(BrandingSettingsFacade))
  .factory('sessionService', downgradeInjectable(SessionService))
  .factory('fundingService', downgradeInjectable(FundingService))
  .factory('businessService', downgradeInjectable(BusinessService))
  .controller('LayoutController', LayoutController)
  .controller('LearnMoreModalDelayedController', LearnMoreModalDelayedController)
  .controller('TimeOutModalController', TimeOutModalController)
  .controller('OutdatedContactInfoModalController', OutdatedContactInfoModalController)
  .directive('appFundingHeader', downgradeComponent({ component: FundingHeaderComponent }))
  .service('cookieHelper', downgradeInjectable(CookieHelper))
  .service('axosInvestHelper', downgradeInjectable(AxosInvestHelper))
  .service('userSubtypeHelper', UserSubtypeHelper)
  .service('axosInvestService', downgradeInjectable(AxosInvestService))
  .service('axosAdvisoryService', downgradeInjectable(AxosAdvisoryService))
  .service('supportViewFacade', downgradeInjectable(SupportViewFacade))
  .service('sharedAccountsFacade', downgradeInjectable(SharedAccountsFacade))
  .service('sharedAccountsService', downgradeInjectable(SharedAccountsService))
  .service('aasAccountsService', downgradeInjectable(accountsService))
  .service('aasAgreementService', downgradeInjectable(AgreementService))
  .service('pendoTracker', downgradeInjectable(PendoTrackerService))
  .config(LayoutConfig)
  .run(LayoutRun).name;
