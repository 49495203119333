export type Bank = {
  id?: number;
  name?: string;
  imageSrc?: string;
  secondaryImageSrc?: string;
  description?: string;
};

export const banks: Bank[] = [
  { name: 'Chase', imageSrc: 'chase_logo.png', secondaryImageSrc: 'chase_round_logo.svg' },
  {
    name: 'Capital One',
    imageSrc: 'capital-one_logo.png',
    secondaryImageSrc: 'capital-one_round_logo.svg',
  },
  { name: 'Citibank', imageSrc: 'citi_logo.png', secondaryImageSrc: 'citi_round_logo.svg' },
  {
    name: 'Bank of America',
    imageSrc: 'bank-of-america_logo.png',
    secondaryImageSrc: 'bank-of-america_round_logo.svg',
  },
  {
    name: 'Wells Fargo',
    imageSrc: 'wells-fargo_logo.png',
    secondaryImageSrc: 'wells-fargo_round_logo.svg',
  },
  { name: 'US bank', imageSrc: 'us-bank_logo.png', secondaryImageSrc: 'us-bank_round_logo.svg' },
  { name: 'PNC', imageSrc: 'pnc_logo.png', secondaryImageSrc: 'pnc_round_logo.svg' },
  {
    name: 'Regions Bank',
    imageSrc: 'regions-bank_logo.png',
    secondaryImageSrc: 'regions_round_logo.svg',
  },
  { name: 'USAA', imageSrc: 'usaa_logo.png', secondaryImageSrc: 'usaa_round_logo.svg' },
  {
    name: 'Citizens Bank',
    imageSrc: 'citizens_bank_logo.png',
    secondaryImageSrc: 'citizens_round_logo.svg',
  },
  { name: 'TD Bank', imageSrc: 'td-bank_logo.png', secondaryImageSrc: 'td_round_logo.svg' },
  { name: 'Ally', imageSrc: 'ally_logo.png', secondaryImageSrc: 'ally_round_logo.svg' },
  {
    name: 'SunTrust',
    imageSrc: 'sun-trust_logo.png',
    secondaryImageSrc: 'sun-trust_round_logo.svg',
  },
  { name: 'BB&T', imageSrc: 'bbt_logo.png', secondaryImageSrc: 'bbt_round_logo.svg' },
  {
    name: 'Charles Schwab',
    imageSrc: 'charles-schwab_logo.png',
    secondaryImageSrc: 'charles-schwab_round_logo.svg',
  },
  { name: 'Fidelity', imageSrc: 'fidelity_logo.png', secondaryImageSrc: 'fidelity_round_logo.svg' },
  {
    name: 'Fifth Third Bank',
    imageSrc: 'fifth-third-bank_logo.png',
    secondaryImageSrc: 'fifth-third-bank_round_logo.svg',
  },
  {
    name: 'Union Bank',
    imageSrc: 'union-bank_logo.png',
    secondaryImageSrc: 'union-bank_round_logo.svg',
  },
];
