import { DialogService } from '@core/services';
import { AlertsIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

/**
 * Open a dialog with the Warning comment before continuing to another site
 * @param dialogService The dialog service used to open the dialog
 * @param url The Url where the user will be redirected for text purposes only.
 */
export function openWebsiteWarningModal(dialogService: DialogService, url: string) {
  const dialogData = new DialogData({
    title: '',
    okText: 'Proceed',
    cancelText: 'Cancel',
    icon: AlertsIcons.ExclamationCircle,
    content: `
              <h3>You're continuing to another site</h3></br>
              <p>Please be aware that Axos does not endorse the information, content, presentation, or accuracy of any other website and claims no responsibility for it.
              You will now be directed to <a style="pointer-events: none; cursor: default;">${url}</a> </p>
            `,
  });

  return dialogService.open(dialogData);
}
