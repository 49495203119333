<div class="click-switch-op-detail">
  <div class="click-switch-op-name">
    {{ switch.name }}
  </div>
  <div class="click-switch-op-text">
    Direct Deposit Requests from {{ switch.name }}
    are managed via your online portal.
  </div>
  <div class="click-switch-op-external-link">
    <a (click)="goToIncomeSourceUrl()" class="link info-text" id="incomeSourceWebPage">
      <app-svg-icon
        [iconName]="outDomain"
        [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
      ></app-svg-icon>
      {{ switch.name }} Portal
    </a>
  </div>
</div>
