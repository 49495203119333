import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IScope } from 'angular';

@Component({
  selector: 'app-positions-responsive',
  templateUrl: './positions-responsive.component.html',
  styleUrls: ['./positions-responsive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionsResponsiveComponent {
  @Input() positions: TradingPosition[];
  @Input() cashInvest: number;
  position: TradingPosition = {};
  showDetail: boolean = false;

  constructor(@Inject('$scope') private readonly scope: IScope) {}

  showPositionDetail(index: number): void {
    this.scope.$emit('onPositionDetailResponsiveClick');

    this.showDetail = true;
    this.position = this.positions[index];
  }

  goBack(): void {
    this.scope.$emit('onPositionDetailResponsiveBack');

    this.showDetail = false;
    this.position = {};
  }
}
