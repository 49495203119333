<div class="investor-checking-enrollment">
  <div class="dialog-header pb-0">
    <h1 id="investorTitle" class="dialog-header-title">
      {{ accountModalTexts.CashManagementTitle }}
    </h1>
    <hr style="width: 100%;" />
  </div>
  <div class="dialog-content pt-0 pb-0">
    <div>
      <p>
        <img
          src="assets\img\icons\accounts\investor-checking-enrollment.svg"
          class="dialog-content__logo"
          alt="investor checking enrollment logo."
        />
      </p>
      <p id="investorBody" class="investor-body">
        {{ accountModalTexts.CashManagementBody }}
      </p>
      <p id="investorBody2" class="investor-body">
        {{ accountModalTexts.CashManagementBody2 }}
      </p>
    </div>
    <hr />
    <div
      *ngFor="let item of acknowledgementItems"
      style="text-align: left; margin: 10px 0px;"
    >
      <input
        class="m-0"
        type="checkbox"
        [id]="item.id"
        [name]="item.id"
        [(ngModel)]="item.acceptedTerms"
        (change)="validateAcceptedAgreements(item.id)"
      />
      <label for="{{ item.id }}" style="font-size: 15px; margin: 0px;">
        I confirm that I have read, understand, and agree to the
        <a (click)="showDocsModal(item.id)">{{ item.title }}</a>
      </label>
    </div>

    <mat-card uk2NoteCard id="fair-credit-container">
      <mat-card-content>
        <label>
          <span class="strong-text">Fair Credit Reporting Act</span>
          : By accepting and submitting this application, you agree to the Terms
          and Conditions and to receiving information about additional Axos
          products and services that may be available to you. You understand
          that by submitting this application, you are providing "written
          instructions" to Axos under the Fair Credit Reporting Act to obtain
          information from your personal credit profile or other information
          from a credit reporting agency chosen by Axos.
        </label>
        <label class="fair-credit-text-separation">
          You authorize Axos to obtain such information solely to confirm your
          identity to avoid fraudulent transactions in your name and to
          prequalify you for the purpose of offering available banking products
          and services.
        </label>
      </mat-card-content>
    </mat-card>

    <div class="dialog-accordion">
      <app-svg-icon
        class="mr-1"
        [width]="24"
        [height]="24"
        [iconColor]="iconColor"
        [iconName]="questionIcon"
      ></app-svg-icon>
      <p id="investor-accordion-title" class="accordion-title">
        {{ accountModalTexts.CashManagementAccordionTitle }}
      </p>
      <app-svg-icon
        id="investor-chevron"
        class="mr-1 accordion-icon"
        [width]="24"
        [height]="24"
        [iconColor]="iconColor"
        [iconName]="openedAccordion ? closeIcon : openIcon"
        (click)="toggleChevron()"
      ></app-svg-icon>
      <span
        id="investor-accordion-body"
        class="accordion-body"
        *ngIf="openedAccordion"
        [@fadeInOut]="animationState"
      >
        {{ accountModalTexts.CashManagementAccordionBody }}
      </span>
    </div>
  </div>
  <div class="dialog-footer pt-0">
    <hr />
    <!--Footer and action buttons-->
    <button
      id="btnSubmit"
      class="btn btn-primary modal-submit-button"
      disabled
      (click)="submitAgreements()"
    >
      Submit
    </button>
  </div>
</div>
