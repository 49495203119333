// Use this file to export all your tokens.
// Then they can be imported on other files like this. E.g.
// import { myToken } from '@core/tokens';
import { InjectionToken } from '@angular/core';

import NgRedux from 'ng-redux';

import { OlbSettings } from '@core/models';
import { ITransactionService } from '@legacy/services/typings/ITransactionService';

export const WINDOW = new InjectionToken<Window>('Browser window object');
export const olbSettings = new InjectionToken<OlbSettings>('OLB Settings');
export const ROOT_SCOPE = new InjectionToken<ng.IRootScopeService>('AngularJS Root Scope');
export const STATE = new InjectionToken<ng.ui.IStateService>('$state');
export const STATE_PARAMS = new InjectionToken<ng.ui.IStateParamsService>('$stateParams');
export const ngRedux = new InjectionToken<NgRedux.INgRedux>('$ngRedux');
export const I_TRANSACTION_SERVICE = new InjectionToken<ITransactionService>('transactionService');
export const SERVICE_HELPER = new InjectionToken<IServiceHelper>('service Helper');
export const I_WINDOW = new InjectionToken<ng.IWindowService>('$window');
export const FILTER = new InjectionToken<angular.IFilterService>('$filter');
