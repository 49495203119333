import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';

import { axosClearingServiceProvider, stateServiceProvider } from '@core/providers';
import { SharedModule } from '@shared/shared.module';

import { TradingBeneficiariesComponent } from './components';

const exportComponents = [TradingBeneficiariesComponent];

@NgModule({
    declarations: [...exportComponents],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, NgxMaskModule.forRoot()],
    exports: [...exportComponents],
    providers: [axosClearingServiceProvider, stateServiceProvider]
})
export class TradingModule {}
