import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';

import {
  addUpdateHoldingAccountAction,
  getSelectedAccountHoldingTile,
  HoldingsSummaryService,
  HoldingState,
  HoldingTileState,
  selectHoldingSortingOption,
  TFilterOptionLabels,
} from '../core';
import { InitializeHoldingTileInput } from './types';
import { createHoldingsLabeledSortOptions, findHoldingSortTypePreference } from '../core/functions';
import {
  HoldingSortDirectionName,
  HoldingSortType,
  HoldingSortTypePreferenceName,
  HoldingsSortDirection,
} from '../core/const';
import { CookieHelperService } from '@app/core/services/cookie.service';
import { UserPreferencesFacade } from '@app/Areas/AAS/aas-shared/features/user-preferences';
import { first, map, catchError, switchMap } from 'rxjs/operators';
import { BrandingFacade } from '../../branding';

@Injectable({
  providedIn: 'root',
})
export class HoldingFacade {
  isLoading$ = new Subject<boolean>();
  holdingTileAccountState$ = this.store.select(getSelectedAccountHoldingTile);

  readonly portalCSSProperties = this.brandingFacade.cssBrandingProperties;

  constructor(
    private store: Store<HoldingState>,
    private holdingSummaryService: HoldingsSummaryService,
    private readonly cookieHelper: CookieHelperService,
    private readonly userPreferencesFacade: UserPreferencesFacade,
    private readonly brandingFacade: BrandingFacade
  ) {}

  initializeHoldingTile(input: InitializeHoldingTileInput) {
    this.isLoading$.next(true);
    const preferences = { userPreferences: input.userPreferences, defaultAppPreferences: input.defaultAppPreferences };
    const accountHoldingSortingPreferences = findHoldingSortTypePreference(input.accountNumber, preferences);

    const payload: HoldingTileState = {
      isSortingEnabled: input.isSortingEnabled,
      accountNumber: input.accountNumber,
      includeHoldings: input.includeHoldings,
      limit: input.limit,
      holdings: [],
      sortOptions: createHoldingsLabeledSortOptions(
        HoldingSortType,
        HoldingsSortDirection,
        createHoldingsLabeledSortOptions.defaultOptionSeed,
        accountHoldingSortingPreferences.holdingsSortTypePreference,
        accountHoldingSortingPreferences.holdingsSortDirectionPreference
      ),
      sortDirection: accountHoldingSortingPreferences.holdingsSortDirectionPreference,
      sortType: accountHoldingSortingPreferences.holdingsSortTypePreference,
    };

    this.holdingSummaryService.getHoldingsSummary({ accountNumber: input.accountNumber }).subscribe(
      serviceResult => {
        payload.holdings = serviceResult.data ?? [];
        this.store.dispatch(addUpdateHoldingAccountAction({ payload }));
        this.isLoading$.next(false);
      },
      err => {
        payload.error = err;
        this.store.dispatch(addUpdateHoldingAccountAction({ payload }));
        this.isLoading$.next(false);
      }
    );
  }

  public selectOption(option: TFilterOptionLabels): void {
    this.createUpdateHoldingsUserSortingPreferences(option);
  }

  private createUpdateHoldingsUserSortingPreferences(option: TFilterOptionLabels): void {
    this.holdingTileAccountState$
      .pipe(
        first(),
        map(holdingState => {
          return holdingState?.accountNumber;
        }),
        switchMap(accountNumber => {
          if (!accountNumber) {
            return of(accountNumber);
          }
          const udbUserId = this.cookieHelper.getUserId();
          return this.userPreferencesFacade.preferencesState$.pipe(first()).pipe(
            map(preferences => {
              return UserPreferencesFacade.createUserSortingPreferences(
                option,
                udbUserId,
                accountNumber,
                preferences.defaultAppPreferences,
                preferences.userPreferences,
                HoldingSortTypePreferenceName,
                HoldingSortDirectionName
              );
            }),
            switchMap(userPreferences => this.userPreferencesFacade.saveUserPreferences({ userPreferences })),
            map(() => accountNumber),
            catchError(() => of(accountNumber))
          );
        })
      )
      .subscribe({
        next: accountNumber => {
          this.store.dispatch(selectHoldingSortingOption({ payload: { option, accountNumber } }));
        },
      });
  }
}
