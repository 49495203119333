import { add, differenceInYears, isBefore, isEqual, isWeekend, set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const AXOS_TIMEZONE = 'America/Los_Angeles';

/**
 * Validates if the given date is younger than the given age.
 *
 * @param age Number of years to campare with.
 * @param birthDate Date that will be compared.
 *
 * @returns True if the birthDate is before the given age.
 */
export function isYoungerThan(age: number, birthDate: Date): boolean {
  const currentDate = new Date();
  const actualYears = Math.abs(differenceInYears(birthDate, currentDate));

  return actualYears < age;
}

/**
 * Checks if the given date is valid based on the date not being a holiday or in a weekend.
 *
 * @param date Date to check.
 * @param holidays An array of dates that represents the holidays.
 *
 * @returns True if the date is not a holiday nor in a weekend.
 */
export function isDateValid(date: Date | number, holidays: Date[]): boolean {
  return !isHoliday(date, holidays) && !isWeekend(date);
}

/**
 * Checks if the given date is a holiday based on the given holiday dates.
 *
 * @param date Date to check.
 * @param holidays An array of dates that represents the holidays.
 *
 * @returns True if the given date is a holiday.
 */
export function isHoliday(date: Date | number, holidays: Date[]): boolean {
  const normalizedDate = normalizeDate(date);

  return holidays.some(holiday => Date.parse(holiday.toString()) === Date.parse(normalizedDate.toString()));
}

/**
 * Removes hours, minutes, seconds and milliseconds from the given date object.
 *
 * @param date Date to normalise.
 *
 * @returns A normalised date with no hours, minutes, seconds or milliseconds.
 */
export function normalizeDate(date: Date | number): Date {
  const normalizedDate = set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return normalizedDate;
}

/**
 * Adds the specified days to the given date, excluding weekends and holidays.
 *
 * @param date Date to add weekdays.
 * @param days Number of days to add.
 * @param holidays An array of dates that represents the holidays.
 *
 * @returns A date object with the new date.
 */
export function addWeekdays(date: Date, days: number, holidays: Date[]): Date {
  date = normalizeDate(date);

  while (days > 0) {
    date = add(date, { days: 1 });

    if (isDateValid(date, holidays)) {
      days -= 1;
    }
  }

  return date;
}

/**
 * Gets the default payment date time.
 *
 * @param holidays An array of dates that represents the holidays.
 * @param compareDate Date to compare to. Defaults to now. This param is mostly used for unit testing.
 *
 * @returns A Date object that corresponds to the default payment time.
 */
export function getDefaultPaymentDateTime(holidays: Date[], compareDate = new Date(Date.now())): Date {
  const californiaTime = utcToZonedTime(compareDate, AXOS_TIMEZONE);

  const limitDate = set(compareDate, {
    hours: 13,
    minutes: 45,
    seconds: 0,
    milliseconds: 0,
  });

  if (isSameOrBefore(californiaTime, limitDate) && isDateValid(compareDate, holidays)) {
    return compareDate;
  }

  return addWeekdays(compareDate, 1, holidays);
}

/**
 * Gets the processing payment date based on the delivery payment date minus the business days to deliver.
 *
 * @param paymentDeliveryDate Date in which the payment will be delivered.
 * @param businessDaysToDeliver Days the payment takes to be delivered once processed.
 * @param holidays An array of dates that represents the holidays.
 *
 * @returns The payment delivery date minus business days to deliver.
 */
export function getProcessingDate(paymentDeliveryDate: Date, businessDaysToDeliver: number, holidays: Date[]) {
  let date = normalizeDate(paymentDeliveryDate);
  if (businessDaysToDeliver >= 0) {
    businessDaysToDeliver *= -1;
  }

  while (businessDaysToDeliver < 0) {
    date = add(date, { days: -1 });

    if (isDateValid(date, holidays)) {
      businessDaysToDeliver += 1;
    }
  }

  return date;
}

/**
 * Gets the local time at Axos.
 *
 * Uses the AXOS_TIMEZONE constant.
 *
 * @param date Optinal date object to convert to axos local time.
 *
 * @returns A date object with the local Axos time.
 */
export function getAxosLocalDateTime(date: Date = new Date(Date.now())): Date {
  const utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );

  return utcToZonedTime(utcDate, AXOS_TIMEZONE);
}

/**
 * Compares the given dates to determine if the left is before the right or if they are equal.
 *
 * @param dateLeft Date that wil be used as the left operand in the compare.
 * @param dateRight Date that wil be used as the right operand in the compare.
 *
 * @returns True if the left date is before or equal to the right date.
 */
export function isSameOrBefore(dateLeft: Date | number, dateRight: Date | number) {
  return isBefore(dateLeft, dateRight) || isEqual(dateLeft, dateRight);
}

export function removeTimeFromIsoDate(isoDate: string): Date {
  const array = isoDate.split('T')[0].split('-');

  return new Date(Number(array[0]), Number(array[1]) - 1, Number(array[2]));
}
