import { DebitCardStatusType } from '../enums/debitCardStatusType.enum';
/**
 * Gets description from a DebitCardType enum
 *
 * @export {function} mapDebitCardType
 * @param {DebitCardType} debitType
 * @returns {string} Description of an Debit Card Type
 */
export function mapDebitCardStatusType(
  debitCardStatus: DebitCardStatusType,
  isBlocked = false
): string {
  switch (debitCardStatus) {
    case DebitCardStatusType.Active:
      return 'Active';
    case DebitCardStatusType.Closed:
    case DebitCardStatusType.Expired:
    case DebitCardStatusType.HotCard:
      return 'Closed';
    case DebitCardStatusType.InProcess:
    case DebitCardStatusType.OrderNew:
      return 'In process';
    case DebitCardStatusType.Reorder:
      return 'Requested';
    case DebitCardStatusType.Warm:
      return isBlocked ? 'Frozen by customer' : 'Suspended';
    default:
      return '--';
  }
}
