import { Pipe, PipeTransform } from '@angular/core';

const possessiveEndings = ['’s', "'s", '’S', "'S"];

@Pipe({
  name: 'possessive',
})
export class PossessivePipe implements PipeTransform {
  transform(value: string): string {
    const trimmed = value.trim();
    const isUpperCase = trimmed === trimmed.toUpperCase();
    const isPossessiveForm = possessiveEndings.some(v => trimmed.endsWith(v));

    if (isPossessiveForm) {
      return `${trimmed}`;
    }

    if (trimmed.endsWith('s') || trimmed.endsWith('S')) {
      return `${trimmed.slice(0, -1)}${isUpperCase ? '’S' : `’s`}`;
    }

    return `${trimmed}${isUpperCase ? '’S' : '’s'}`;
  }
}
