import { ChartConfiguration } from '@app/axos-invest/models';

export const CHART_CONFIGURATION: ChartConfiguration[] = [
  {
    min: 2,
    max: 14,
    jumpDays: 1,
    dataLabelFormat: 'MMM do, yyyy',
    timeLabelFormat: null,
    timeLabel: 'Value at the End of the Day',
  },
  {
    min: 14,
    max: 61,
    jumpDays: 7,
    dataLabelFormat: 'MMMM do, yyyy',
    timeLabelFormat: 'MMM do, yyyy',
    timeLabel: 'Value on ${date}',
  },
  {
    min: 61,
    max: 1095,
    jumpDays: 30,
    dataLabelFormat: 'MMMM',
    timeLabelFormat: 'MMMM do, yyyy',
    timeLabel: 'Value on ${date}',
  },
  {
    min: 1095,
    max: -1,
    jumpDays: 365,
    dataLabelFormat: 'yyyy',
    timeLabelFormat: 'MMMM do, yyyy',
    timeLabel: 'Value on ${date}',
  },
];
