import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { addUpdateTransactionAction, getSelectedAccountTransactionTile } from '../core';
import { TransactionsState, TransactionTileState } from '../core/store/types';
import { TransactionsResponse, TransactionsService } from '../core/services';
import { InitializeTransactionTileInput } from './types';
import { catchError, map } from 'rxjs/operators';
import { AasAccountState } from '../../product-details-page/core';
import { ApplicationTypes } from '@app/transaction-history/enums/applicationTypes.enum';
import { FileExtensions } from '@app/transaction-history/enums/fileExtensions.enum';
import { ServiceHelper } from '@legacy/services/service.helper';

@Injectable({
  providedIn: 'root',
})
export class TransactionFacade {
  isLoading$ = new BehaviorSubject<boolean>(false);
  transactionTileAccountState$ = this.store.select(getSelectedAccountTransactionTile);

  constructor(
    private store: Store<TransactionsState>,
    private transactionsService: TransactionsService,
    private serviceHelper: ServiceHelper
  ) {}

  initializeTransactionTile(input: InitializeTransactionTileInput) {
    this.isLoading$.next(true);

    const payload: TransactionTileState = {
      apiCallWasSuccessful: false,
      accountNumber: input.accountNumber,
      limit: input.limit,
      startDate: input.startDate,
      endDate: input.endDate,
      transactions: [],
    };

    this.transactionsService.getTransactions(input).subscribe(
      serviceResult => this.handleSuccessResponse(payload, serviceResult.data),
      () => this.handleFailedResponse(payload)
    );
  }

  transactionsTile(input: InitializeTransactionTileInput): Observable<TransactionsResponse[]> {
    this.isLoading$.next(true);

    return this.transactionsService.getTransactions(input).pipe(
      map(response => this.handleTransactionsTileResponse(response.data)),
      catchError(() => this.handleTransactionsTileFailedResponse())
    );
  }

  downloadTransaction(
    transactionDownloadFilters: IDownloadTransactionsRequest,
    account: AasAccountState
  ): Observable<boolean> {
    return this.transactionsService.downloadTransactionsFile(transactionDownloadFilters, account.accountNumber).pipe(
      map(res => this.handleDownloadTransactionSuccessResponse(res, account)),
      catchError(err => this.handleDownloadTransactionFailedResponse(err))
    );
  }

  private handleSuccessResponse(payload: TransactionTileState, response: TransactionsResponse[]) {
    this.isLoading$.next(false);
    payload.transactions = response ?? [];
    payload.apiCallWasSuccessful = true;

    this.store.dispatch(addUpdateTransactionAction({ payload }));
  }

  private handleFailedResponse(payload: TransactionTileState) {
    this.isLoading$.next(false);
    this.store.dispatch(addUpdateTransactionAction({ payload }));
  }

  private handleTransactionsTileResponse(apiResponse: TransactionsResponse[]) {
    this.isLoading$.next(false);

    return apiResponse;
  }

  private handleTransactionsTileFailedResponse() {
    this.isLoading$.next(false);

    return of([]);
  }

  private handleDownloadTransactionSuccessResponse(res: any, account: AasAccountState) {
    const blob = new Blob([res], {
      type: ApplicationTypes.Xlsx,
    });
    saveAs(blob, `${account.accountNickname}_txns.${FileExtensions.Xlsx}`);
    return true;
  }

  private handleDownloadTransactionFailedResponse(err: any) {
    this.serviceHelper.errorHandler(err);
    return of(true);
  }
}
