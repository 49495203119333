import { ForecastFrequency } from '@app/axos-invest/enums';

export class ForecastParams {
  autoDepositFrequency: ForecastFrequency;
  autoDepositAmount: number;

  constructor(parameters?: Partial<ForecastParams>) {
    Object.assign(this, parameters);
  }
}
