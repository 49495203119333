import { Inject } from '../../../decorators/decorators';
import { IStateService } from 'angular-ui-router';
import { IBillPayService } from 'services/typings/IBillPayService';
import { Recipient } from 'bill-pay/typings/Recipient';
import { BillerAccount } from '../../typings/BillerAccount';
import { SelectBillerAccount } from '../../typings/SelectBillerAccount';
import { ITimeoutService } from 'angular';
import { BillPayRouterHelper } from 'bill-pay/bill-pay-router.helper';
import { BillPayHelper } from 'bill-pay/bill-pay.helper';

@Inject(
  'billPayService',
  '$state',
  '$timeout',
  'serviceHelper',
  'billPayRouterHelper',
  'billPayHelper'
)
export class EStatementsAccountsController {
  recipient: Recipient;
  recipientId: number;
  sessionId: string;
  accounts: BillerAccount[];
  errorMessage: string;

  isLoading: boolean;

  private selectBillerAccount: SelectBillerAccount = {};

  constructor(
    private readonly _billPayService: IBillPayService,
    private readonly _stateService: IStateService,
    private readonly _timeoutService: ITimeoutService,
    private readonly _serviceHelper: IServiceHelper,
    private readonly _billPayRouterHelper: BillPayRouterHelper,
    private readonly _billPayHelper: BillPayHelper
  ) {}

  $onInit() {
    this.recipient = this._billPayRouterHelper.getRecipientDetails();
    this.accounts = this._billPayRouterHelper.getAccounts();
    this.sessionId = this._billPayRouterHelper.getSessionId();

    this._serviceHelper.scrollToTop();
  }

  /**
   * Select an account for the eBill - Step 3
   */
  selectEBillAccount(account: BillerAccount): void {
    this.isLoading = true;
    this.selectBillerAccount.sessionId = this.sessionId;
    this.selectBillerAccount.account = account;
    this._billPayService
      .selectEBillAccount(this.selectBillerAccount)
      .then(response => {
        if (response.data.furtherActionRequired) {
          //check status again
          let timeout =
            new Date(response.data.callBackTime).getTime() -
            new Date(response.data.timestamp).getTime();
          this._timeoutService(() => {
            this._billPayHelper.checkForFurtherActions(response.data);
          }, timeout);
        } else {
          this.isLoading = false;
          //If we are following the add recipient flow, got to the recipient confirmation page
          let partialRecipient = this._billPayRouterHelper.getRecipient();
          if (partialRecipient) {
            partialRecipient.eStatementsStatus = true;
            this._stateService.go('udb.billPay.electronicConfirmation');
          } //go to confirmation page
          else this._stateService.go('udb.billPay.eStatements.confirmation');
        }
      })
      .catch(err => {
        this.errorMessage = err.data.message;
        this.isLoading = false;
      });
  }

  public goBack(): void {
    history.back();
  }

  public cancelSetUp() {
    this._billPayRouterHelper.cancelSetup();
  }
}
