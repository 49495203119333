import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dot-loader',
  templateUrl: './dot-loader.component.html',
  styleUrls: ['./dot-loader.component.scss'],
})
export class DotLoaderComponent {
  @Input() showWhen = true;

  @Input() inline: boolean;
}
