import { Component } from '@angular/core';

import { TransactionDetailComponent } from '@app/axos-invest/components';
import { TradeTransaction } from '@app/axos-invest/models';

@Component({
  selector: 'app-trade-information',
  templateUrl: './trade-information.component.html',
  styleUrls: ['./trade-information.component.scss'],
})
export class TradeInformationComponent implements TransactionDetailComponent {
  transaction: TradeTransaction;
}
