import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { BeneficiariesHelper } from 'services/beneficiaries.helper';

import { AccountsService } from '@core/services';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { DropdownItem } from '@shared/components/uk-dropdown/typings/dropdown-items';
import { Beneficiary } from '@shared/models';

@Component({
  selector: 'app-list-beneficiaries',
  templateUrl: './list-beneficiaries.component.html',
  styleUrls: ['./list-beneficiaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBeneficiariesComponent implements OnInit {
  accountId: number;
  beneficiaries: Beneficiary[];
  accountItems: DropdownItem[] = [];
  selectedAccount: DropdownItem;
  accounts: OlbAccount[];
  account: OlbAccount;
  constructor(
    @Inject(ROOT_SCOPE) private readonly rootScope: ng.IRootScopeService,
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject('beneficiariesHelper') private readonly beneficiariesHelper: BeneficiariesHelper,
    private readonly accountsService: AccountsService
  ) {
    this.accountId = this.state.params.accountId;
    this.beneficiaries = this.state.params.beneficiaries;
    this.initAccountsDropdown();
  }

  ngOnInit(): void {}

  goBack(): void {
    this.state.go('udb.accounts.details', { id: this.accountId, tab: 1 });
  }

  /** Populates the accounts' dropdown  */
  initAccountsDropdown() {
    this.accounts = this.rootScope['accounts'].depositAccounts.filter((x: OlbAccount) =>
      this.beneficiariesHelper.isAllowingBeneficiaries(x)
    ) as OlbAccount[];

    this.accountItems = this.accounts.map((a: OlbAccount) => new DropdownItem(a.nickname, a.id, ''));
    if (this.accountItems.length > 0) {
      this.selectedAccount = this.accountItems.filter((a: DropdownItem) => {
        return a.id === this.accountId;
      })[0];
    }

    this.account = this.accounts.find(a => a.id === this.accountId);
  }

  onSelectAccount(accountItem: DropdownItem): void {
    this.accountId = +accountItem.id;

    this.account = this.accounts.find(a => a.id === this.accountId);

    this.accountsService.getAccountDetails(this.accountId).subscribe({
      next: res => {
        this.beneficiaries = res.data.beneficiaries;
      },
    });
  }

  editBeneficiary(bene: Beneficiary): void {
    this.state.go('udb.beneficiaries.edit', {
      accountId: this.accountId,
      beneficiary: bene,
    });
  }

  redirectToPage(paramAccountId: any, paramBeneficiaries: any): void {
    this.state.go('udb.beneficiaries.add', {
      accountId: paramAccountId,
      beneficiaries: paramBeneficiaries,
    });
  }
}
