import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OpenAccountItem } from '@app/accounts/models';
import { CommunicationIcons, FinancialIcons, MiscIcons, NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-open-account-item',
  templateUrl: './open-account-item.component.html',
  styleUrls: ['./open-account-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenAccountItemComponent {
  @Input() data: Partial<OpenAccountItem>;

  get iconName() {
    return this.getAccountItemIcon();
  }

  chevronIconConfig = {
    icon: NavigationIcons.ChevronRight,
    styles: {
      width: '0.5rem',
      height: '0.5rem',
      strokeWidth: 3,
    },
  };

  constructor() {}

  private getAccountItemIcon(): string {
    let icon: string;

    switch (this.data.icon) {
      case 'checking.svg':
        icon = FinancialIcons.BankCheck;
        break;
      case 'savings.svg':
        icon = FinancialIcons.Piggy;
        break;
      case 'cd.svg':
        icon = FinancialIcons.CoinDollarSign;
        break;
      case 'investment.svg':
        icon = FinancialIcons.PieChart;
        break;
      case 'mortgage.svg':
        icon = CommunicationIcons.Home;
        break;
      case 'personal.svg':
        icon = CommunicationIcons.User;
        break;
      case 'auto.svg':
        icon = MiscIcons.Car;
        break;
    }

    return icon;
  }
}
