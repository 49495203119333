/**
 * Creates a string for an address only taking in consideration the steet line 1 and 2
 * (if exists), city, state, stateCode, postalCode.
 *
 * @param address Address object.
 *
 * @returns A stringified version of the address object. Useful for comparisons.
 */
function addressToString(address: Address): string {
  const { streetAddress1, streetAddress2, city, state, stateCode, postalCode } = address;

  const returningStr = `${streetAddress1 ?? ''}${streetAddress2 ?? ''}${city ?? ''}${state ?? ''}${
    stateCode ?? ''
  }${postalCode ?? ''}`;

  return returningStr.trim().toLowerCase();
}

/**
 * Checks if both addresses are equal.
 *
 * @param leftAddress Address to compare on the left.
 * @param rightAddress Address to compare on the right.
 *
 * @returns A boolean specifying if both addresses are equal.
 */
export function areAddressesEqual(leftAddress: Address, rightAddress: Address): boolean {
  const pAddressStr = addressToString(leftAddress);
  const mAddressStr = addressToString(rightAddress);

  return pAddressStr === mAddressStr;
}

/**
 * Checks if the given address is empty.
 *
 * @param address Address to check.
 *
 * @returns A boolean specifying if the address is empty.
 */
export function addressIsEmpty(address: Address): boolean {
  return (
    !address.streetAddress1 &&
    !address.city &&
    !address.stateCode &&
    !address.postalCode &&
    !address.state
  );
}
