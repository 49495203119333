<h3 class="mt-5 mb-4">How do I change the contents of a tile?</h3>

<ol>
  <li>
    Click on the
    <b>Settings</b>
    wheel next to the title of the tile.
  </li>
  <li>From here, you can make changes to the tile contents and to the icon colors.</li>
  <li>
    When you're finished, click
    <b>Save.</b>
  </li>
</ol>
