import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { SubSink } from '@axos/subsink';
import { compareAsc, format, isAfter, isSameDay, startOfToday } from 'date-fns';

import { CardActivatedModalComponent } from '@app/accounts/components/modals';
import { getSetting } from '@app/store/selectors';
import { AppSettings } from '@core/enums';
import { DialogService } from '@core/services';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AlertsIcons, UtilityIcons } from '@shared/enums';
import { dialogConfig, DialogData } from '@shared/models';

import { DebitCard, DebitCardActivity, DebitCardHistory, TravelNotification } from '../../models';
import { DebitCardService } from '../../services/debit-card.service';

@Component({
  selector: 'app-debit-card-details',
  templateUrl: './debit-card-details.component.html',
  styleUrls: ['./debit-card-details.component.scss'],
})
export class DebitCardDetailsComponent implements OnInit, OnDestroy {
  @Input() set debitCard(value: DebitCard) {
    this.handleDebitCardChange(value);
  }

  @Output() goTo = new EventEmitter<string>();
  @Output() updatedCard = new EventEmitter<DebitCard>();

  debitCard$ = new BehaviorSubject<DebitCard>(null);
  debitCardHistory$ = new BehaviorSubject<DebitCardHistory[]>(null);
  travelNotifications$ = new BehaviorSubject<TravelNotification[]>([]);
  cardViewClass = '';
  travelNotificationsLimit: number = null;
  isMobile: boolean;

  private subSink = new SubSink();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef,
    private store: Store,
    private debitCardService: DebitCardService,
    private serviceHelper: ServiceHelper,
    private dialogService: DialogService,
    private cachedAccountsService: CachedAccountsService
  ) {}

  ngOnInit(): void {
    const selector = getSetting(AppSettings.TravelNotificationsLimit);
    const mediaQuery = '(max-width: 991px)';

    this.setCardViewClassName();

    this.subSink.sink = this.store.select(selector).subscribe({
      next: value => {
        this.travelNotificationsLimit = Number(value);
      },
    });

    this.subSink.sink = this.breakpointObserver.observe(mediaQuery).subscribe({
      next: value => {
        this.isMobile = value.matches;
        this.cd.markForCheck();
      },
    });
  }

  ngOnDestroy(): void {
    this.debitCard$.complete();
    this.debitCardHistory$.complete();
    this.travelNotifications$.complete();
    this.subSink.unsubscribe();
  }

  activateCardPopup() {
    const dialogData = new DialogData({
      title: 'If this card is a replacement card, the previous card will be closed.',
      icon: AlertsIcons.InfoCircle,
      okText: 'Continue',
      cancelText: 'Cancel',
    });

    this.dialogService
      .open(dialogData)
      .afterClosed()
      .subscribe({
        next: confirm => {
          if (confirm) this.activateCard();
        },
      });
  }

  activateCard() {
    const debitCard = this.debitCard$.getValue();

    this.debitCard$.next(null);
    this.debitCardService.activateCard(debitCard).subscribe({
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      next: card => {
        this.updateLinkedCardsAndHistory(card);

        const dialogData = new DialogData({
          title: '',
          headerTitle: 'Success',
          icon: UtilityIcons.Checkmark,
          okText: 'Close',
          cancelText: '',
          component: new ComponentPortal(CardActivatedModalComponent),
        });

        dialogConfig.data = dialogData;
        dialogConfig.disableClose = true;
        this.dialogService
          .openCustom(dialogConfig)
          .afterClosed()
          .subscribe({ next: () => this.updatedCard.emit(card) });
      },
    });
  }

  toggleCardStatus() {
    const debitCard = this.debitCard$.getValue();

    this.debitCard$.next(null);
    this.debitCardService.toggleCardStatus(debitCard).subscribe({
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      next: card => {
        const dialogData = new DialogData({
          title: 'Card status changed successfully',
          headerTitle: '',
          icon: AlertsIcons.InfoCircle,
          okText: 'Ok',
          cancelText: '',
        });

        dialogConfig.data = dialogData;
        dialogConfig.disableClose = true;

        this.dialogService
          .openCustom(dialogConfig)
          .afterClosed()
          .subscribe({
            next: () => {
              this.updatedCard.emit(card);
              this.updateLinkedCardsAndHistory(card);
            },
          });
      },
    });
  }

  askDeleteTravelNotification(notification: TravelNotification) {
    const formatStr = 'MM/dd/yyyy';
    const content =
      '<h4>Do you want to delete this travel notification?</h4>' +
      '<b>Destination(s)/Comments:</b> ' +
      notification.comments +
      '<br><b>Date:</b> ' +
      format(notification.startDate, formatStr) +
      ' - ' +
      format(notification.endDate, formatStr);
    const dialogData = new DialogData({
      content,
      okText: 'Delete',
      title: 'Delete Travel Notification',
    });

    this.dialogService
      .open(dialogData)
      .afterClosed()
      .subscribe({
        next: deleteBtn => {
          if (deleteBtn) this.deleteTravelNotification(notification);
        },
      });
  }

  removeHistoryRecord(id: DebitCardActivity['id']) {
    this.debitCardService.removeHistoryRecord(id).subscribe({
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      next: () => {
        const newHistory = this.debitCardHistory$.getValue().filter(el => el.id !== id);

        this.debitCardHistory$.next(newHistory);
      },
    });
  }

  private handleDebitCardChange(debitCard: DebitCard) {
    this.debitCard$.next(debitCard);
    this.debitCardHistory$.next(null);
    this.travelNotifications$.next([]);

    forkJoin({
      history: this.debitCardService.getHistory(debitCard),
      travelNotifications: this.debitCardService.getTravelNotifications(debitCard),
    }).subscribe({
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      next: ({ history, travelNotifications }) => {
        this.debitCardHistory$.next(history.data);

        this.handleGetTravelNotifications(travelNotifications);
      },
    });
  }

  private setCardViewClassName() {
    const isHorizontalCard = this.debitCardService.isHorizontalCard();
    const trail = isHorizontalCard ? 'horizontal' : 'vertical';

    this.cardViewClass = `card-section-${trail}`;
  }

  private handleGetTravelNotifications(travelNotification: TravelNotification[]) {
    const notifications = travelNotification
      .reduce((acc, tn) => {
        if (
          acc.length < this.travelNotificationsLimit &&
          (isSameDay(startOfToday(), tn.endDate) || isAfter(tn.endDate, startOfToday()))
        ) {
          acc.push(tn);
        }

        return acc;
      }, [] as TravelNotification[])
      .sort((left, right) => compareAsc(left.endDate, right.endDate));

    this.travelNotifications$.next(notifications);
  }

  private updateLinkedCardsAndHistory(debitCard: DebitCard) {
    this.debitCardHistory$.next(null);

    this.cachedAccountsService.updateLinkedDebitCards(debitCard);

    this.debitCardService.getHistory(debitCard).subscribe({
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      next: res => {
        this.debitCardHistory$.next(res.data);
      },
    });
  }

  private deleteTravelNotification(notification: TravelNotification) {
    return this.debitCardService.deleteTravelNotification(notification, this.debitCard$.getValue()).subscribe({
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      next: () => {
        const travelNotifications = this.travelNotifications$
          .getValue()
          .filter(el => el.travelId !== notification.travelId);

        this.travelNotifications$.next(travelNotifications);
      },
    });
  }
}
