import { BrandingSettingsListStateType } from '.';

export type BrandingStateType = {
  name: string | undefined;
  displayName: string;
  email: string;
  phone: string;
  url: string;
  settings: BrandingSettingsListStateType[];
};

export const initialBrandingStateType: BrandingStateType = {
  name: undefined,
  displayName: '',
  email: '',
  phone: '',
  url: '',
  settings: [],
};
