import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { enrollmentComponents } from './components';
import { enrollmentModuleImports } from './config/config';
import { enrollmentViews } from './views';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ...enrollmentModuleImports],
  declarations: [...enrollmentComponents, ...enrollmentViews],
  exports: [...enrollmentComponents, ...enrollmentViews],
})
export class EnrollmentModule {}
