import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BILLPAYHELPER, BILLPAYROUTERHELPER, BILLPAYSERVICE, MODALSERVICE } from '@app/bill-pay/ajs-upgraded-provider';
import { PaymentFrequency } from '@app/bill-pay/typings/PaymentFrequency';
import { RecurrentPaymentInfo } from '@app/bill-pay/typings/RecurrentPaymentInfo';
import { ScheduledPayment } from '@app/bill-pay/typings/ScheduledPayment';
import { BillPayRouterHelper } from '@app/bill-pay/utils/bill-pay-router.helper';
import { STATE } from '@core/tokens';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { ModalService } from '@legacy/services/modal.service';
import { PopupsHelper } from '@legacy/services/popups.helper';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOptionsComponent implements OnInit {
  @Input() payment: ScheduledPayment = {} as ScheduledPayment;
  fullDeletionWarning: string;
  fullDeletionConfirmation: string;
  deleteOptionText: string;
  editOptionText: string = 'Edit Payment Series';
  @Output() getScheduledPayments = new EventEmitter<void>();
  deletePaymentHeader: string;
  public popups: PopupsHelper;

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(BILLPAYSERVICE) private readonly billPayService: IBillPayService,
    @Inject(MODALSERVICE) private readonly modalService: ModalService,
    @Inject(BILLPAYROUTERHELPER) private readonly routerHelper: BillPayRouterHelper,
    @Inject(BILLPAYHELPER) private readonly billPayHelper: BillPayHelper
  ) {}

  ngOnInit(): void {
    this._buildStrings(this.payment.paymentFrequency === PaymentFrequency.OneTime);
  }

  private _buildStrings(isOneTime: boolean): void {
    this.fullDeletionWarning = isOneTime
      ? 'Are you sure you want to cancel your upcoming payment?'
      : 'Are you sure you want to remove your payment series?';
    this.fullDeletionConfirmation = isOneTime
      ? 'This payment has been cancelled'
      : `Payment Series Removed</h3> <br> <span>Your ${this._getFrequencyDescription(
          this.payment.paymentFrequency
        )} payment series from ${this.payment.fromAccount} to ${this.payment.payeeName} has been removed </span> <h3>`;
    this.deleteOptionText = isOneTime ? 'Cancel Payment' : 'Remove Payment Series';
    if (isOneTime) this.editOptionText = this.editOptionText.substr(0, 12);
    this.deletePaymentHeader = isOneTime ? 'Cancel Upcoming Payment' : 'Remove Payment Series';
  }

  private _getFrequencyDescription(paymentFreq: PaymentFrequency): string {
    switch (paymentFreq) {
      case PaymentFrequency.Weekly:
        return 'Weekly';
      case PaymentFrequency.EveryTwoWeeks:
        return 'Every two weeks';
      case PaymentFrequency.EveryFourWeeks:
        return 'Every four weeks';
      case PaymentFrequency.Monthly:
        return 'Monthly';
      case PaymentFrequency.SemiMonthly:
        return 'Semi-monthly';
      case PaymentFrequency.EveryTwoMonths:
        return 'Every two months';
      case PaymentFrequency.Quarterly:
        return 'Quarterly';
      case PaymentFrequency.EveryFourMonths:
        return 'Every four months';
      case PaymentFrequency.BiAnnual:
        return 'Biannual';
      case PaymentFrequency.Annually:
        return 'Anunaly';
      default:
        return '';
    }
  }
  deleteNextPayment(): void {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          hasCancelButton: true,
          cancelText: 'No',
          bodyText:
            '<h3>Cancel Payment</h3> <br> <span style="font-size:14px"> Are you sure you want to cancel your next payment? This will not affect other payments in this series.</span>',
          hasRedirectLink: false,
        }
      )
      .then(() => {
        this.billPayService
          .cancelNextPayment(this.syncPayment(this.payment))
          .then(r => {
            let message: string = '';
            if (r.data.notifyChange) {
              message =
                '<h3 style="font-weight: bold;">Payment Cancelled</h3><br><strong style=" font-weight:bold; font-size: 15px; margin-right:3px;">NOTE: ' +
                '</strong><span style="font-size: 14px;"> Your next payment fell on weekend or holiday, so we adjusted your payment series. ' +
                'Future payments in this series will now be sent on ';

              if (this.payment.paymentFrequency < 5) {
                message += this.billPayHelper.getWeekDay(moment(r.data.processingDate)) + '.</span>';
              } else {
                message +=
                  'the ' + this.buildStringNumber(moment(r.data.processingDate).format('D')) + ' of the month.</span>';
              }
            } else {
              message = 'This payment has been cancelled';
            }

            const okText = r.data.notifyChange ? 'Done' : 'Ok';
            this.displayDeleteConfirmation(message, okText);
          })
          .catch(response => {
            this.popups.showAlert('Error during deletion', response.data.message, 'error');
          });
      });
  }
  deletePayment() {
    const ids = [this.payment.scheduledPaymentId];
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          hasCancelButton: true,
          cancelText: 'No',
          bodyText:
            '<h3>' +
            this.deletePaymentHeader +
            '</h3><br><span style="font-size=14px">' +
            this.fullDeletionWarning +
            '</span>',
          hasRedirectLink: false,
        }
      )
      .then(() => {
        this.billPayService
          .deleteScheduledPayment(ids)
          .then(() => this.displayDeleteConfirmation(this.fullDeletionConfirmation))
          .catch(response => {
            this.popups.showAlert('Error during deletion', response.data.message, 'error');
          });
      });
  }

  displayDeleteConfirmation(confirmationMessage: string, okText: string = 'Ok') {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-success',
          okText: okText,
          hasCancelButton: false,
          bodyText: '<h3>' + confirmationMessage + '</h3>',
        }
      )
      .then(() => {
        this.getScheduledPayments.emit();
      });
  }

  editPayment(isRecurrent: boolean) {
    this.routerHelper.setScheduledPayment(this.payment);
    if (isRecurrent) this.state.go('udb.billPay.updateRecurrent');
    else this.state.go('udb.billPay.updateSingle');
  }

  /** Synces the payment which is being set up */
  private syncPayment(payment: ScheduledPayment): RecurrentPaymentInfo {
    const recurrent: RecurrentPaymentInfo = {};
    recurrent.payeeId = payment.payeeId;
    recurrent.accountCode = payment.accountCode;
    recurrent.amount = payment.amount;
    recurrent.daysBeforeDeliveryAlert = payment.alert ? payment.alert.daysBeforeDelivery : 0;
    recurrent.daysBeforeDeliverySpecified = payment.alert ? payment.alert.daysBeforeDeliverySpecified : false;
    recurrent.daysBeforeProcessingAlert = payment.alert ? payment.alert.daysBeforeProcessing : 0;
    recurrent.daysBeforeProcessingSpecified = payment.alert ? payment.alert.daysBeforeProcessingSpecified : false;
    recurrent.alertOnCompletion = payment.alert ? payment.alert.onCompletion : false;
    recurrent.isSent = payment.alert
      ? payment.alert.daysBeforeProcessingSpecified && payment.alert.daysBeforeProcessing == 0
      : false;
    recurrent.repeatUntilCanceled = payment.items.length === 1 ? payment.items[0] : false;
    recurrent.paymentFrequency = payment.paymentFrequency;
    recurrent.memo = payment.memo;
    recurrent.numberOfPayments = !recurrent.repeatUntilCanceled ? payment.items[1] : 1; //
    recurrent.endOn = recurrent.repeatUntilCanceled ? 0 : 1; //
    recurrent.endOnNumberOfPayments = !recurrent.repeatUntilCanceled; //
    recurrent.notifyMeNumberOfPayments = payment.alert
      ? payment.alert.daysBeforeProcessingSpecified && payment.alert.daysBeforeProcessing > 0
      : false; //
    recurrent.isRecurring = true; //always is recurring in this modal
    recurrent.scheduledPaymentId = payment.scheduledPaymentId;
    recurrent.processingDate = moment(payment.processingDate).toDate();
    recurrent.deliveryDate = moment(payment.deliveryDate).toDate();
    return recurrent;
  }
  buildStringNumber(number: string): string {
    switch (number) {
      case '1':
        return number + 'st';
      case '2':
        return number + 'nd';
      case '3':
        return number + 'rd';
      default:
        return number + 'th';
    }
  }
}
