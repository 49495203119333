import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { createAction, props } from '@ngrx/store';

import { CustomerDetail } from '@shared/models';
import { BusinessDetail } from '@shared/models/business-detail.model';

export const loadProfileDetails = createAction(
  '[UserProfile] Load profile details',
  props<{ payload: Partial<CustomerDetail> }>()
);

export const updateProfileDetails = createAction(
  '[UserProfile] Update profile details',
  props<{ payload: Partial<CustomerDetail> }>()
);

export const updateProfileIcon = createAction(
  '[UserProfile] Update profile icon',
  props<{ payload: Pick<CustomerDetail, 'backgroundColor' | 'backgroundIcon'> }>()
);

export const loadOutdatedContactInfo = createAction(
  '[UserProfile] Load outdated contact info',
  props<{ isOutdated: boolean }>()
);

export const loadBusinessInfo = createAction(
  '[UserProfile] Load Business Information',
  props<{ businessData: BusinessDetail }>()
);

export const loadCompanyProfiles = createAction(
  '[UserProfile] Load Company Profiles',
  props<{ accountProfiles: Partial<AccountProfile[]> }>()
);

export const confirmInfoIsUpdated = createAction('[UserProfile] Confirm User Info Is Updated');
export const loadIsNewUser = createAction('[UserProfile] Load is user new', props<{ isNewUser: boolean }>());
