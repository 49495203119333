<ng-container [ngSwitch]="currentView">
  <up-section-heading
    section="Personal Information"
    [subsection]="currentView"
    [mobile]="isMobileDevice"
    (return)="goBack()"
    (expand)="expanded = $event"
  ></up-section-heading>

  <div *ngSwitchDefault [@startStaticSlideFromLeft]="animationState">
    <app-outdated-contact-info-banner *ngIf="hasOutdatedContactInfo$ | async"></app-outdated-contact-info-banner>
    <div *ngIf="!isMobileDevice || expanded" @openClose [@.disabled]="!isMobileDevice">
      <up-profile-item
        itemName="Email"
        [editable]="userCanEdit"
        (edit)="goTo(views.Email)"
        [alertMessage]="alertMessages.email"
        class="email"
      >
        <app-dot-loader *ngIf="profileLoading; else emailContent"></app-dot-loader>
        <ng-template #emailContent>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters">
            <p class="font-weight-bold col-12 col-lg-5">Primary:</p>
            <div *ngIf="profileInfo.primaryEmail" class="col-12 col-lg-7 email-container">
              <p
                [attr.aria-label]="profileInfo.primaryEmail"
                class="font-weight-normal profile-email"
                [innerText]="profileInfo.primaryEmail"
              ></p>
              <div aria-hidden="true" class="uk-bottom-tip" [attr.data-uk-tooltip]="profileInfo.primaryEmail"></div>
            </div>
          </div>
          <div *ngIf="profileInfo.alternateEmail" class="mb-3 d-flex flex-column flex-lg-row no-gutters">
            <p class="font-weight-bold col-12 col-lg-5">Alternate:</p>
            <div class="col-12 col-lg-7 email-container">
              <p
                [attr.aria-label]="profileInfo.alternateEmail"
                class="font-weight-normal profile-email"
                [innerText]="profileInfo.alternateEmail"
              ></p>
              <div aria-hidden="true" class="uk-bottom-tip" [attr.data-uk-tooltip]="profileInfo.alternateEmail"></div>
            </div>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Phone"
        [editable]="userCanEdit"
        (edit)="goTo(views.Phone)"
        [alertMessage]="alertMessages.phone"
        class="phone"
      >
        <app-dot-loader *ngIf="profileLoading; else phoneContent"></app-dot-loader>
        <ng-template #phoneContent>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 cell-phone-1">
            <p class="col-12 col-lg-5 font-weight-bold">Primary Cell:</p>
            <p class="col-12 col-lg-7 invalid-address" *ngIf="!profileInfo.cellPhone1.number">
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Phone Number Needed
            </p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              *ngIf="profileInfo.cellPhone1.number"
              [innerText]="profileInfo.cellPhone1.number | phone"
            ></p>
          </div>
          <div
            *ngIf="profileInfo.cellPhone2.number && profileInfo.cellPhone2.number !== '0'"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 cell-phone-2"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Alternate Cell:</p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="profileInfo.cellPhone2.number | phone"></p>
          </div>
          <div
            *ngIf="profileInfo.homePhone.number && profileInfo.homePhone.number !== '0'"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 home-phone"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Home:</p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="profileInfo.homePhone.number | phone"></p>
          </div>
          <div
            *ngIf="profileInfo.workPhone.number && profileInfo.workPhone.number !== '0'"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 work-phone"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Work:</p>
            <p class="col-12 col-lg-7 font-weight-normal value">
              {{ profileInfo.workPhone.number | phone }}&nbsp;
              <span *ngIf="profileInfo.workPhone.extension" [innerText]="'x' + profileInfo.workPhone.extension"></span>
            </p>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Address"
        [editable]="canEditAddress"
        (edit)="goTo(views.Address)"
        [alertMessage]="alertMessages.address"
        class="address"
      >
        <app-dot-loader *ngIf="profileLoading; else addressContent"></app-dot-loader>
        <ng-template #addressContent>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 home-address">
            <p class="col-12 col-lg-5 font-weight-bold">Home Address:</p>
            <p class="col-12 col-lg-7 invalid-address" *ngIf="primaryAddressIsIncomplete; else primaryAddress">
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Address Needed
            </p>
            <ng-template #primaryAddress>
              <p
                class="col-12 col-lg-7 font-weight-normal"
                [innerText]="profileInfo.primaryAddress.address | address"
              ></p>
            </ng-template>
          </div>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 mailing-address" *ngIf="mailingAddress">
            <p class="col-12 col-lg-5 font-weight-bold">Mailing Address:</p>
            <p class="col-12 col-lg-7 invalid-address" *ngIf="mailingAddressIsIncomplete; else mailingAddressSection">
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Address Needed
            </p>

            <ng-template #mailingAddressSection>
              <p class="col-12 col-lg-7 font-weight-normal" [innerText]="mailingAddress | address"></p>
            </ng-template>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        *ngIf="isIraEnhBaseFlagActive; else liteEmploymentInfo"
        itemName="Employment Information"
        [editable]="userCanEdit"
        (edit)="goTo(views.Employment)"
        [alertMessage]="alertMessages.employment"
        class="employment-info-section"
      >
        <app-dot-loader *ngIf="profileLoading || catalogsLoading; else info"></app-dot-loader>
        <ng-template #info>
          <div
            *ngIf="employmentInfo.employmentStatus"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 employment-status"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Employment Status</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="employmentStatuses[employmentInfo.employmentStatus] || ''"
            ></p>
          </div>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 occupation" *ngIf="hasOccupation">
            <p class="col-12 col-lg-5 font-weight-bold" [innerText]="occupationLabel"></p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.occupation"></p>
          </div>

          <ng-container *ngIf="isEmployed">
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 years-employed">
              <p class="col-12 col-lg-5 font-weight-bold">Years Employed</p>
              <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.yearsEmployed"></p>
            </div>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 employer-name">
              <p class="col-12 col-lg-5 font-weight-bold">Employer Name</p>
              <p class="col-12 col-lg-7 font-weight-normal value" *ngIf="!!employerName" [innerText]="employerName"></p>
            </div>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 employer-address">
              <p class="col-12 col-lg-5 font-weight-bold">Employer Address</p>
              <p
                class="col-12 col-lg-7 font-weight-normal value"
                [innerText]="employmentInfo.employerAddress | address"
              ></p>
            </div>
          </ng-container>
        </ng-template>
      </up-profile-item>
      <ng-template #liteEmploymentInfo>
        <up-profile-item
          itemName="Employment Information"
          [editable]="userCanEdit"
          (edit)="goTo(views.Employment)"
          [alertMessage]="alertMessages.employment"
          class="lite-employment-info-section"
        >
          <app-dot-loader *ngIf="profileLoading || catalogsLoading; else info"></app-dot-loader>

          <ng-template #info>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 occupation" *ngIf="employmentInfo.occupation">
              <p class="col-12 col-lg-5 font-weight-bold">Occupation</p>
              <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.occupation"></p>
            </div>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 employer-name" *ngIf="employmentInfo.employer">
              <p class="col-12 col-lg-5 font-weight-bold">Employer Name</p>
              <p class="col-12 col-lg-7 font-weight-normal value" *ngIf="!!employerName" [innerText]="employerName"></p>
            </div>
          </ng-template>
        </up-profile-item>
      </ng-template>
      <up-profile-item
        itemName="Financial Information"
        [editable]="userCanEdit"
        (edit)="goTo(views.Financial)"
        [alertMessage]="alertMessages.financial"
        class="financial-info"
      >
        <app-dot-loader *ngIf="profileLoading || catalogsLoading; else financialInfoSection"></app-dot-loader>
        <ng-template #financialInfoSection>
          <div
            *ngIf="financialInfo.annualIncome && !financialCatalogsLoading"
            class="mb-3 d-flex flex-column flex-lg-row no-gutters annual-income"
          >
            <p class="font-weight-bold col-12 col-lg-5">Annual Income</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="annualIncomes[financialInfo.annualIncome] || ''"
            ></p>
          </div>
          <div
            *ngIf="financialInfo.totalNetWorth && !financialCatalogsLoading"
            class="mb-3 d-flex flex-column flex-lg-row no-gutters total-net-worth"
          >
            <p class="font-weight-bold col-12 col-lg-5">Total Net Worth</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="totalNetWorths[financialInfo.totalNetWorth] || ''"
            ></p>
          </div>
          <div
            *ngIf="financialInfo.taxBracket && !financialCatalogsLoading"
            class="mb-3 d-flex flex-column flex-lg-row no-gutters tax-bracket"
          >
            <p class="font-weight-bold col-12 col-lg-5">Tax Bracket</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="taxBrackets[financialInfo.taxBracket] || ''"
            ></p>
          </div>
          <div
            *ngIf="financialInfo.liquidNetWorth && !financialCatalogsLoading"
            class="mb-3 d-flex flex-column flex-lg-row no-gutters liquid-net-worth"
          >
            <p class="font-weight-bold col-12 col-lg-5">Liquid Net Worth</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="liquidNetWorths[financialInfo.liquidNetWorth] || ''"
            ></p>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Family Information"
        [editable]="userCanEdit"
        (edit)="goTo(views.Family)"
        [alertMessage]="alertMessages.family"
        class="family-info"
      >
        <app-dot-loader *ngIf="profileLoading || maritalStatusesLoading; else familyInfoSection"></app-dot-loader>
        <ng-template #familyInfoSection>
          <div *ngIf="familyInfo.maritalStatus" class="d-flex flex-column flex-lg-row no-gutters mb-3 marital-status">
            <p class="col-12 col-lg-5 font-weight-bold">Marital Status</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="maritalStatuses[familyInfo.maritalStatus] || ''"
            ></p>
          </div>
          <div
            *ngIf="familyInfo.numberOfDependents"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 number-of-dependents"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Number of Dependents</p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="familyInfo.numberOfDependents"></p>
          </div>
        </ng-template>
      </up-profile-item>

      <div class="d-flex justify-content-center my-4" *ngIf="hasOutdatedContactInfo$ | async">
        <button type="button" class="btn-ok col-12" (click)="confirmInfoIsUpdated()" [disabled]="!contactInfoIsValid">
          Everything is Correct
        </button>
      </div>
    </div>
  </div>

  <up-change-email
    @slideFromRight
    *ngSwitchCase="views.Email"
    [userProfile]="profileInfo"
    (updateEmails)="updateEmails($event)"
    (back)="goBack()"
  ></up-change-email>

  <up-change-phone
    @slideFromRight
    *ngSwitchCase="views.Phone"
    [userProfile]="profileInfo"
    (updatePhones)="updatePhones($event)"
    (back)="goBack()"
  ></up-change-phone>

  <up-change-address
    @slideFromRight
    *ngSwitchCase="views.Address"
    [userProfile]="profileInfo"
    (updateAddresses)="updateAddresses($event)"
    (back)="goBack()"
  ></up-change-address>

  <up-change-employment-info
    @slideFromRight
    *ngSwitchCase="views.Employment"
    [employmentInfo]="employmentInfo"
    (updateEmploymentInfo)="updateEmploymentInfo($event)"
    (back)="goBack()"
  ></up-change-employment-info>

  <up-change-financial-info
    @slideFromRight
    *ngSwitchCase="views.Financial"
    [financialInfo]="financialInfo"
    (updateFinancialInfo)="updateFinancialInfo($event)"
    (back)="goBack()"
  ></up-change-financial-info>

  <up-change-family-info
    @slideFromRight
    *ngSwitchCase="views.Family"
    [familyInfo]="familyInfo"
    (updateFamilyInfo)="updateFamilyInfo($event)"
    (back)="goBack()"
  ></up-change-family-info>
</ng-container>
