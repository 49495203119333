import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { ShareAccountDetailsMatModule } from './share-account-details-mat.module';
import { ShareAccountDetailsUK2Module } from './share-account-details-uk2.module';
import { ShareAccountDetailsComponent } from './view/share-account-details/share-account-details.component';

@NgModule({
  declarations: [ShareAccountDetailsComponent],
  imports: [ShareAccountDetailsMatModule, ShareAccountDetailsUK2Module, SharedModule, CommonModule],
  exports: [ShareAccountDetailsComponent],
})
export class ShareAccountDetailsModule {}
