<div class="authorizations-other__container">
  <div class="authorizations-other__title">Other</div>
  <div class="taxLien-container authorizations-other__flex-container">
    <div class="taxLien-text txt">Tax Lien</div>
    <div class="taxLien-value val">{{ getFormattedYesNo(authorizationsTabState?.taxLien) ?? blankFormat }}</div>
  </div>
  <div class="authorizations-other__divider"></div>
  <div class="powerAttorney-container authorizations-other__flex-container">
    <div class="powerAttorney-text txt">Power of Attorney</div>
    <div class="powerAttorney-value val">
      {{ getFormattedYesNo(authorizationsTabState?.powerAttorney) ?? blankFormat }}
    </div>
  </div>
  <div class="authorizations-other__divider"></div>
  <div class="courtOrder-container authorizations-other__flex-container">
    <div class="courtOrder-text txt">Court Order Required</div>
    <div class="courtOrder-value val">
      {{ getFormattedYesNo(authorizationsTabState?.courtOrderRequired) ?? blankFormat }}
    </div>
  </div>
  <div class="authorizations-other__divider"></div>
  <div class="pledgedCollateral-container authorizations-other__flex-container">
    <div class="pledgedCollateral-text txt">Pledged Collateral</div>
    <div class="pledgedCollateral-value val">
      {{ getFormattedYesNo(authorizationsTabState?.pledgedCollateral) ?? blankFormat }}
    </div>
  </div>
</div>
