import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q')
class HarlandClarkeService extends BaseService implements IHarlandClarkeService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'HarlandClarke', _serviceHelper, q);
  }

  public encryptAndRedirect(accountId: string): ApiResponse<any> {
    //return this.post(`redirect/${accountId}`);
    return this.post(`redirect/${accountId}`);
  }

  public OrderChecks(redirected: boolean): ApiResponse<any> {
    return this.post(`?redirected=${redirected}`);
  }
}

export { HarlandClarkeService };
