import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { GetAccountsAuthorizationRequest, GetAccountsAuthorizationResponse } from './types';
@Injectable({
  providedIn: 'root',
})
export class AccountAuthorizationsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/accountauthorizations');
  }

  /**
   * Returns Account Authorizations response
   *
   * @param request - The request object of type {@link GetAccountsAuthorizationRequest}
   * @returns Account Authorizations response Observable
   */
  getAccountsAuthorization(request: GetAccountsAuthorizationRequest): WebApiResponse<GetAccountsAuthorizationResponse> {
    return this.get<GetAccountsAuthorizationResponse>(request.accountNumber);
  }
}
