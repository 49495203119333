import { createAction, props } from '@ngrx/store';

import { ScheduledTransfer } from '@app/axos-invest/models';

export const loadScheduledTransfers = createAction(
  '[Invest] Load scheduled transfers',
  props<{ payload: ScheduledTransfer[] }>()
);

export const addScheduledTransfer = createAction(
  '[Invest] Add scheduled transfer',
  props<{ payload: ScheduledTransfer }>()
);

export const deleteScheduledTransfer = createAction(
  '[Invest] Delete scheduled transfer',
  props<{ transferSeriesId: string }>()
);
