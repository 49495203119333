import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { PaymentInfo } from '@app/accounts/models';
import { STATE } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInfoComponent {
  @Input() info: PaymentInfo;
  @Input() canMakePayment: boolean;

  @Output() makePayment = new EventEmitter<void>();

  iconProps = {
    iconSize: '0.75rem',
    color: 'var(--primary)',
    icon: NavigationIcons.ChevronRight,
  };

  constructor(@Inject(STATE) private state: ng.ui.IStateService) {}

  // TODO: This function should be deleted once we implement routing
  goToLoanPayment() {
    sessionStorage.setItem('isPayoff', 'false');
    this.state.go('udb.transfers.loanPayment', { toAccountId: this.info.account.id });
  }
}
