import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Representatives } from '@app/support/models/representatives.model';
import { RIAServices } from '@app/support/services/ria.service';

import { getUserContactFirms, getUserContactFirmsSuccess } from './contact-firms.actions';
import { FirmsRepresentative } from '@app/support/models/firms-representative.models';
import { RiaType } from '@app/Areas/AAS/aas-core/rias';
import { AxosAdvisoryAccount } from '@core/models';

@Injectable()
export class ContactFirmsEffects {
  constructor(private riaService: RIAServices, private store: Store) {}

  getUserContactFirms(rias: RiaType[], accounts: AxosAdvisoryAccount[]) {
    this.store.dispatch(getUserContactFirms());

    this.riaService.getUserContactsFirms().subscribe(serviceResult => {
      // Validate if there is un-managed accounts
      const riaDefaultName = 'Advisor Managed Account';
      const riaDefaultLogo = 'url("rounded-logos/axos_round_logo.svg?thn=1/")';
      serviceResult.data.forEach(firm => {
        firm.commonName = firm.commonName || riaDefaultName;
        firm.logo = firm.logo || riaDefaultLogo;
        if (firm.managedAccounts?.length > 0) {
          const unmanagedAccountRep: Representatives = {
            commonName: '',
            email: firm.email,
            isPrimary: false,
            managedAccounts: firm.managedAccounts,
            phone: firm.phone,
            repId: 0,
          };

          firm.representatives.push(unmanagedAccountRep);
        }
      });

      this.validateActiveAccount(rias, accounts, serviceResult.data);
    });
  }
  private validateActiveAccount(rias: RiaType[], accounts: AxosAdvisoryAccount[], firms: FirmsRepresentative[]) {
    const accountAllowedStatus: string[] = ['active', 'open'];
    let userContactFirmsFilter: FirmsRepresentative[] = [];

    rias.forEach(ria => {
      if (ria.clientPortalStatus != 0) {
        let accountsAdvisory = accounts.filter(acc => acc.riaId == ria.riaId);
        let firmToFilter = firms.find(firm => firm.firmId === ria.udbUserId);

        if (firmToFilter) {
          firmToFilter.representatives.forEach(rep => {
            rep.managedAccounts = rep.managedAccounts.filter(managedAccount =>
              accountsAdvisory.some(
                account =>
                  account.accountNumber === managedAccount.number.toString() &&
                  accountAllowedStatus.includes(account.status)
              )
            );
          });

          userContactFirmsFilter.push(firmToFilter);
        }
      }
    });
    this.store.dispatch(getUserContactFirmsSuccess({ userContactFirms: userContactFirmsFilter }));
  }
}
