import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { DropdownItem } from './typings/dropdown-items';

@Component({
  selector: 'app-uk-dropdown',
  templateUrl: './uk-dropdown.component.html',
  styleUrls: ['./uk-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UkDropdownComponent implements OnInit, OnChanges {
  @Input() dropdownItems: DropdownItem[];
  @Input() selectedItem: DropdownItem = new DropdownItem('Select an Option');
  @Input() showItemsAlways = false;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  showDropdown = false;
  hasDropdownItemsIcon: boolean;

  private index = 0;
  private isHoverActive = false;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dropdownItems && changes.dropdownItems.currentValue) {
      this.hasDropdownItemsIcon = this.checkDropdownItemsIcon(
        changes.dropdownItems.currentValue
      );
    }
  }

  toggleDropdown(forceClose: boolean = false) {
    const showItems = this.showItemsAlways
      ? true
      : this.dropdownItems.length > 1;
    this.showDropdown = forceClose ? false : !this.showDropdown && showItems;
    this.index = this.dropdownItems.indexOf(this.selectedItem);
    this.focusDropdownItem();
  }

  select(index: number) {
    this.selectedItem = this.dropdownItems[index];
    this.toggleDropdown(true);
    this.onSelect.emit(this.selectedItem);
  }

  listBoxKeyPress(event: KeyboardEvent) {
    const keyResult = event.key;
    if (this.isHoverActive && keyResult !== 'Escape') return;
    switch (keyResult) {
      case 'Enter': // Enter
        if (!this.showDropdown) {
          this.toggleDropdown();

          return;
        }
        this.select(this.index);
        this.toggleDropdown(true);
        break;
      case 'Escape': // Escape
        this.toggleDropdown(true);
        break;
      case 'ArrowUp': // Up arrow
        if (this.index <= 0) return;
        this.index--;

        if (this.showDropdown) {
          this.focusDropdownItem();
          this.scrollToItemInView();
        } else this.select(this.index);

        break;
      case 'ArrowDown': // Down arrow
        if (this.index >= this.dropdownItems.length - 1) return;
        this.index++;

        if (this.showDropdown) {
          this.focusDropdownItem();
          this.scrollToItemInView();
        } else this.select(this.index);

        break;
    }
  }

  mouseTriggeredFocus(focusedItem: DropdownItem) {
    this.isHoverActive = true;
    this.index = this.dropdownItems.indexOf(focusedItem);
    this.focusDropdownItem();
  }
  mouseLeave() {
    this.isHoverActive = false;
  }

  private focusDropdownItem() {
    this.dropdownItems.map((item: DropdownItem) => {
      item.focus =
        this.dropdownItems[this.index] &&
        this.dropdownItems[this.index].id === item.id &&
        this.dropdownItems[this.index].text === item.text &&
        this.dropdownItems[this.index].description === item.description;
    });
  }

  private scrollToItemInView() {
    const element = document.getElementById(`item-${this.index}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private checkDropdownItemsIcon(dropdownItems: DropdownItem[]): boolean {
    return dropdownItems.some(d => !!d.icon);
  }
}
