import { UIKitModalConfiguration } from '@uikit/tiles';
import * as angular from 'angular';
import { Bank, Banks } from 'dashboard/account-aggregation/typings/Bank';
import NgRedux from 'ng-redux';
import { FeatureFlagService } from 'services/feature-flag.service';
import { TilesService } from 'services/tiles.service';
import { AggregatedAccount } from 'typings/app/account-aggregation';

import { Inject } from '../../decorators/decorators';
import { IRedirectStateService } from '../../services/typings/IRedirectStateService';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';

@Inject(
  '$state',
  '$ngRedux',
  '$scope',
  '$element',
  'serviceHelper',
  'tilesService',
  'redirectStateService',
  'featureFlagService'
)
export class AccountAggregationController extends BaseTile<AccountAggregationTileSettings> {
  title = 'See All of Your Accounts';
  quickActionText = 'Add accounts';
  readonly defaultMessage = 'Add another account';
  isBusy = true;
  syncError = false;
  hasNoAccounts = true;
  bankSlides: Bank[];
  shownAccounts: AggregatedAccount[] = [];
  accounts: AggregatedAccount[];
  settings: number[] = [];
  modalConfig: UIKitModalConfiguration;
  showModal = false;
  gotoExternal = false;
  isAccAggrEnhancementsActive: boolean;
  private unsubscribe: Function;

  constructor(
    private readonly state: ng.ui.IStateService,
    private readonly ngRedux: NgRedux.INgRedux,
    scope: ng.IScope,
    elm: ng.IRootElementService,
    serviceHelper: IServiceHelper,
    tilesService: TilesService,
    private readonly redirectService: IRedirectStateService,
    private readonly featureFlagService: FeatureFlagService
  ) {
    super(scope, elm, tilesService, serviceHelper);
  }

  /** Initializes the controller. */
  $onInit(): void {
    this.isAccAggrEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    this.intialize();
    this.unsubscribe = this.ngRedux.subscribe(this.intialize.bind(this));
  }

  /** Clean controller's memory objects. */
  $onDestroy(): void {
    this.unsubscribe();
  }

  /**
   * Initializes the accounts model and also
   * it is executed every time a changes is made in the store
   */
  intialize() {
    const { aggregated } = this.ngRedux.getState().accounts;

    if (!aggregated) {
      this.getSettings();

      return;
    }
    this.accounts = aggregated;
    this.getSettings(this.setupTile.bind(this));
  }

  /** Save Tile settings. */
  saveTileSettings(): void {
    if (this.tilesService.validateDuplicateAccounts(this.settings)) {
      this.modalConfig = {
        title: 'Error',
        message: "The same account can't be selected more than once.",
        status: 'error',
        okAction: () => {
          this.setDefaultAccounts();
          this.showModal = false;
        },
      };

      this.showModal = true;

      return;
    }

    this.settings.forEach((id, idx) => {
      this.shownAccounts[idx] = this.accounts.find(a => a.id === id);
    });
    this.tileSettings.Accounts.value = this.settings.filter(acc => acc).join(',');
    this.saveSettings();
  }

  /** Set default accounts. */
  setDefaultAccounts(): void {
    if (!this.accounts.length) return;

    this.settings = angular.copy(this.shownAccounts.map(a => (a ? a.id : null)));
    if (this.settings[2]) this.quickActionText = '';

    if (!this.settings[1] || !this.settings[2]) {
      this.gotoExternal = true;
      this.quickActionText = 'See all accounts';
    }
  }

  /** Redirects to the account aggregation view */
  goAddAdditionalAccounts() {
    this.redirectService.setOriginalState(this.state.current.name);
    this.state.go('udb.dashboard.account-aggregation', {
      isAccountAggregationFlow: this.isAccAggrEnhancementsActive,
    });
  }

  quickAction() {
    this.state.go('udb.accounts.dashboard');
  }

  /** Sets up the tile with its needed configurations. */
  private setupTile(): void {
    this.isBusy = false;
    this.tileSettings.Accounts;

    if (this.accounts instanceof Error) {
      this.syncError = true;

      return;
    }

    if (this.accounts.length) {
      this.setExternalAccounts();

      return;
    }

    this.bankSlides = [...Banks];
    this.bankSlides.forEach(item => {
      item.onSelected = () => {
        this.redirectService.setOriginalState(this.state.current.name);
        this.state.go('udb.dashboard.account-aggregation', {
          bankName: item.name,
          isAccountAggregationFlow: this.isAccAggrEnhancementsActive,
        });
      };
    });
  }

  /** Set external accounts on controller. */
  private setExternalAccounts() {
    let saveSettings = false;
    this.title = 'External Accounts';
    this.hasNoAccounts = false;

    if (!this.tileSettings.Accounts.value) {
      this.shownAccounts[0] = this.accounts[0];
      this.shownAccounts[1] = this.accounts[1];
      this.shownAccounts[2] = this.accounts[2];
    } else {
      const ids = this.tileSettings.Accounts.value.split(',').map(i => +i);
      ids.forEach((id, idx) => {
        if (!id) return;
        this.shownAccounts[idx] = this.accounts.find(a => a.id === id);
      });

      for (let idx = 0; idx < this.maxAccounts; idx++) {
        if (!this.shownAccounts[idx]) {
          const shownAccounts = this.shownAccounts.filter(a => !!a);
          this.shownAccounts[idx] = this.accounts.find(x => shownAccounts.every(n => n.id !== x.id));
          saveSettings = true;
        }
      }
    }
    this.setDefaultAccounts();
    if (saveSettings) {
      this.saveTileSettings();
    }
  }
}

interface AccountAggregationTileSettings extends BaseTileSettings {
  Accounts?: TileSetting;
}
