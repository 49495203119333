import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { StoreModule } from '@ngrx/store';

import { NgxMaskModule } from 'ngx-mask';

import { AppStateFeatures } from '@core/enums';
import { MaterialDateFnsModule } from '@core/modules';
import { SharedModule } from '@shared/shared.module';

import { debitCardComponents } from './components';
import { DebitCardService } from './services/debit-card.service';
import { debitCardReducers } from './store';
import { debitCardViews } from './views';

@NgModule({
  declarations: [...debitCardViews, ...debitCardComponents],
  imports: [
    SharedModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MaterialDateFnsModule,
    NgxMaskModule.forRoot(),
    StoreModule.forFeature(AppStateFeatures.DebitCard, debitCardReducers),
  ],
  exports: [...debitCardViews, ...debitCardComponents],
  providers: [
    DatePipe,
    DebitCardService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
})
export class DebitCardModule {}
