import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { BrandPropertyService } from 'services/brand-property.service';
import { LoadUserProfileHelper } from 'services/load-user-profile-helper';

import { formatPhone, maskPhone } from '@app/utils';
import { FacingBrand } from '@core/enums/facing-brand.enum';
import { MultiFactorRequest } from '@core/models';

import { Inject } from '../../decorators/decorators';
import { IDeliveryMethodService } from '../../services/typings/IDeliveryMethodService';
import { IMultifactorService } from '../../services/typings/IMultifactorService';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { filter } from 'rxjs/operators';

@Inject(
  'env',
  '$rootScope',
  '$scope',
  'alertsNotificationsService',
  'deliveryMethodService',
  'multifactorService',
  'loadUserProfileHelper',
  'brandPropertyService',
  '$q',
  'ngrxStore'
)
export class DeliveryMethodController {
  userPhoneNumber: string;
  flagtest: boolean = false;
  userInfo: UserBasicInfo;
  emailEnabled: boolean;
  userObj = {
    EmailAddress: '',
    PhoneNumber: '',
  };
  smsObject = {
    title: '',
    successMsg: '',
  };
  accessCode: number;
  regex: Object;
  email: string;

  isLoadingEmail: boolean;
  phoneError = false;
  gettingCode = false;
  sendingCode = false;
  errorCode = false;
  updateAlertSMS = false;
  expanded = false;
  isDisableSMS = false;
  otpValidationMessage = '';
  onlinePrivacyPolicyLink = '';
  smsOptInBrandInfo: ISmsOptInBrandInfo = {};
  enrollmentBrandTradeMark = '';
  deliveryMethodSettings: AlertsDeliveryMethodSetting = {
    id: '',
    emailAlertEnabled: false,
    smsAlertEnabled: false,
  };
  smsTermsAndConditionsBrand = '';
  savingEmailSetting = false;
  savingSMSSetting = false;
  truncatedEmail: string;
  showEmailTooltip = false;
  // flag for IE explorer to be able to display border for input[type=checkbox] element
  deliveryMethodWasCanceled = false;
  brandName: string;
  smsBusiness: string;

  private deliveryMethodSettingsCopy: AlertsDeliveryMethodSetting;
  private smsEnabledBy: string;
  private maxLenghtEmailTooltip = 30;
  private subsink = new SubSink();

  get userEmail() {
    return this.userInfo.email;
  }

  constructor(
    private readonly env: OlbSettings,
    private _root: ng.IRootScopeService,
    private _scope: ng.IScope,
    private _alertsNotificationsService: IAlertsNotificationsService,
    private _deliveryMethodService: IDeliveryMethodService,
    private _multifactorService: IMultifactorService,
    private readonly loadUserProfileHelper: LoadUserProfileHelper,
    private readonly brandPropertyService: BrandPropertyService,
    private _q: ng.IQService,
    private store: Store
  ) {
    this.regex = {
      email: new RegExp('^[_a-z0-9-]+(\\.[_a-z0-9-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,15})$'),
    };
  }

  $onInit(): void {
    this._alertsNotificationsService.getBrandContactInfo().then(x => {
      this.smsOptInBrandInfo.customerServiceMail = x.data.email;
      this.smsOptInBrandInfo.customerServicePhone = x.data.phoneNumber;
      this.smsOptInBrandInfo.displayName = x.data.name;
      this.onlinePrivacyPolicyLink = x.data.onlinePrivacyPolicyLink;
    });

    this.otpValidationMessage = 'Please enter 6 digits';

    switch (this.env.facingBrandId) {
      case FacingBrand.Axos:
        this.brandName = 'Axos Bank';
        break;
      case FacingBrand.Nationwide:
        this.brandName = 'Nationwide by Axos Bank';
        break;
      case FacingBrand.UFB:
        this.brandName = 'UFB Direct';
        break;
    }

    this.loadBrandProperties(this.env.brandId, this.env.brand);
    this._root['userInfo']
      ? this._setUserInfo()
      : this._scope.$on('userInfoLoaded', () => {
          this._setUserInfo();
        });

    this._getDeliveryMethodSettings();

    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.expanded = true;
    } else {
      this.expanded = false;
    }

    this._scope.$on('enableMasterSwitch', (_event: any, type: string) => {
      this.setEmailNotificationSetting(type, true);
    });

    this._scope.$on('setUpSMSMasterSwitch', (_event: any, type: string) => {
      this.smsEnabledBy = type;

      if (!this.deliveryMethodSettings.smsAlertEnabled) {
        this.updateAlertSMS = true;
        this.openTextModal(type);
      } else {
        this._scope.$emit('enableSMS', true);
      }
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(profileInfo => {
        this.smsBusiness = profileInfo.businessInformation ? profileInfo.businessInformation.cellPhone.number : '';
        this._setUserInfo();
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  maskPhone(phone: string) {
    return maskPhone(phone?.replace('-', ''));
  }

  /** Constrains the inputs to allow only numbers
   * @param $event The event triggered by the input
   */
  allowOnlyNumbers($event: any): void {
    const isModifierkeyPressed = $event.metaKey || $event.ctrlKey || $event.shiftKey;
    const isCursorMoveOrDeleteAction = [46, 8, 37, 38, 39, 40].indexOf($event.keyCode) !== -1;
    const isNumKeyPressed =
      ($event.keyCode >= 48 && $event.keyCode <= 58) || ($event.keyCode >= 96 && $event.keyCode <= 105);
    const vKey = 86;
    const cKey = 67;
    const aKey = 65;
    const isEnterPressed = $event.keyCode === 13 ? true : false;
    const isTabPressed = $event.keyCode === 9 ? true : false;
    switch (true) {
      case isCursorMoveOrDeleteAction:
      case isModifierkeyPressed === false && isNumKeyPressed:
      case ($event.metaKey || $event.ctrlKey) && [vKey, cKey, aKey].indexOf($event.keyCode) !== -1:
        break;
      case isEnterPressed:
        break;
      case isTabPressed: {
        const active = document.activeElement as HTMLElement;
        if (active.classList[0] === 'modal-access-input') {
          const elem = document.getElementById('helper');
          elem.focus();
          $event.preventDefault();
        }
        break;
      }
      default:
        $event.preventDefault();
    }
  }
  /** Catches the keypress event on the RESEND button
   * @param $event The event triggered by pressing a key when the focus is in the RESEND label
   */
  resendKeyPress($event: any): void {
    if ($event.keyCode === 13 || $event.keyCode === 32) {
      // If enter or space is pressed the method should be trigger
      this.resendCode();
    } else if ($event.shiftKey && $event.keyCode === 9) {
      // Reverse tabing over resend should give focus to the input
      const elem = document.getElementsByClassName('modal-access-input')[0] as HTMLElement;
      elem.focus();
      $event.preventDefault();
    }
  }

  setEmailNotificationSetting = (type: string, enabled: boolean) => {
    if (type === 'alert') this.deliveryMethodSettings.emailAlertEnabled = enabled;

    // prevent make request when deliveryMethodSettings object has not changes
    const hasChanges =
      this.deliveryMethodSettingsCopy.emailAlertEnabled !== this.deliveryMethodSettings.emailAlertEnabled;
    if (hasChanges) this.deliveryMethodSettingsCopy = { ...this.deliveryMethodSettings };

    const deliveryPromise = new Promise(resolve => {
      if (hasChanges) {
        resolve(this._deliveryMethodService.setDeliveryMethodSettings(this.deliveryMethodSettings, false));
      } else resolve('update not required for delivery method settings');
    });

    const alertsPromise = new Promise(resolve => {
      const alertsEmailNotification: AlertsNotificationSetting = {
        notificationType: 'Email',
        enable: false,
      };

      if (!enabled) {
        resolve(this._alertsNotificationsService.configureAlertsNotification(alertsEmailNotification));
      } else resolve('no alerts update required');
    });

    if (type === 'delivery') {
      this.savingEmailSetting = true;
      if (!this.deliveryMethodSettings.emailAlertEnabled) {
        this._scope.$emit('showLoadingAlert', true);
      }

      Promise.all([deliveryPromise, alertsPromise])
        .then(() => {
          this._scope.$emit('showEditingAlert', false);
          this._scope.$emit('restoreAlerts', this.deliveryMethodSettings.emailAlertEnabled);
          this.savingEmailSetting = false;
          if (!this.deliveryMethodSettings.emailAlertEnabled) this._scope.$emit('loadUserAlerts');
          this._scope.$digest();
        })
        .catch(() => {
          this.savingEmailSetting = false;
          this._scope.$emit('showLoadingAlert', false);
          this._scope.$digest();
        });
    }
  };

  setSMSNotificationSetting = (type: string, enabled: boolean, openPopup: boolean) => {
    this.flagtest = true;
    if (this._root['brandProperties']) {
      this.enrollmentBrandTradeMark = this._root['brandProperties']['EnrollmentBrandTradeMark'];
    } else {
      this._root.$on('brandPropertiesLoaded', function () {
        this.enrollmentBrandTradeMark = this._root['brandProperties']['EnrollmentBrandTradeMark'];
      });
    }
    this.deliveryMethodWasCanceled = false;
    this.deliveryMethodSettings.smsAlertEnabled = enabled;
    if (!this.deliveryMethodSettings.smsAlertEnabled) {
      this.savingSMSSetting = true;
      this.updateAlerts(type);
    } else if (openPopup && this.deliveryMethodSettings.smsAlertEnabled) {
      this.openTextModal(type);
    }
  };

  hideDisableSMS(): void {
    this.isDisableSMS = false;
  }

  /** Open text message modal */
  openTextModal(type: string): void {
    this.smsEnabledBy = type;
    this.smsObject.title = '<b>Mobile Number</b> to receive text messages';
    this.smsObject.successMsg = 'You have now opted in to receive text messages for your selected alerts.';

    $('#smsAlerts').modal({
      backdrop: 'static',
    });
    $('#smsAlerts')['modal']('show');
  }

  /** Close text message modal */
  closeTextModal(): void {
    $('#smsAlerts')['modal']('hide');
  }

  /** Close text message modal */
  cancelSMSChanges(): void {
    this.userInfo.phoneNumber = this._root['userInfo']['phoneNumber'];
    this.deliveryMethodWasCanceled = true;
    this.deliveryMethodSettings.smsAlertEnabled = false;
    this.updateAlertSMS = false;
    this._scope.$emit('enableSMS', false);
    this.closeTextModal();
    this.closeAccessCodeModal();
  }

  cancelSMSOTP(): void {
    this.cancelSMSChanges();
    this.setSMSNotificationSetting('none', this.deliveryMethodSettings.smsAlertEnabled, false);
  }

  closeAccessCodeModal(): void {
    $('#accessCodeModal')['modal']('hide');
  }

  openAccessCodeModal(): void {
    if (!this.userInfo.phoneNumber || this.userInfo.phoneNumber.length < 10) {
      this.phoneError = true;
    } else {
      const request: MultiFactorRequest = {
        username: sessionStorage.getItem('username'),
        authenticationMethod: 1,
      };

      this.gettingCode = true;

      this._multifactorService
        .challenge(request)
        .then(() => {
          $('#accessCodeModal').modal({
            backdrop: 'static',
          });
          $('#accessCodeModal')['modal']('show');
          this.errorCode = false;
          this.closeTextModal();
        })
        .finally(() => {
          this.gettingCode = false;
        });
    }
  }

  resendCode(): void {
    const request: MultiFactorRequest = {
      username: sessionStorage.getItem('username'),
      authenticationMethod: 1,
    };

    this.gettingCode = true;

    this._multifactorService.challenge(request).finally(() => {
      this.gettingCode = false;
    });
  }

  submitAccessCode(form: ng.IFormController): void {
    console.log(this.smsEnabledBy);
    this.errorCode = false;
    console.log(form);
    this.accessCode = null;
    $('#successModal').modal({
      backdrop: 'static',
    });
    $('#successModal')['modal']('show');
    this.closeAccessCodeModal();

    // if (form.$valid) {
    //   this.sendingCode = true;

    //   const request: MultiFactorRequest = {
    //     otp: this.accessCode.toString(),
    //     rememberDevice: false,
    //     username: sessionStorage.getItem('username'),
    //     authenticationMethod: 1,
    //   };

    //   this._deliveryMethodService
    //     .validateDeliveryOTP(request)
    //     .then((_response: any) => {
    //       this.accessCode = null;
    //       $('#successModal').modal({
    //         backdrop: 'static',
    //       });
    //       $('#successModal')['modal']('show');
    //       this.closeAccessCodeModal();

    //       this.updateAlerts(this.smsEnabledBy);

    //       if (this.updateAlertSMS) {
    //         this._scope.$emit('enableSMS', true);
    //       }
    //     })
    //     .catch(() => {
    //       this.errorCode = true;
    //     })
    //     .finally(() => {
    //       this.sendingCode = false;
    //       form.$submitted = false;
    //     });
    // }
  }

  closeSuccessModal(): void {
    $('#successModal')['modal']('hide');
    this.clearObject();
  }

  clearObject(): void {
    this.smsObject.title = '';
    this.smsObject.successMsg = '';
    // this.savingSMSSetting = false;
  }

  /** Toggles the expansion of the section. */
  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  printConditions(): void {
    this._alertsNotificationsService
      .getSmsTermsPdf()
      .then((response: any) => {
        const bArray = this._base64ToArrayBuffer(response.data);
        const file = new Blob([bArray], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(file, 'TermsAndConditions.pdf');
        } else {
          window.open(fileURL);
        }
      })
      .catch((_: ApiError) => {
        console.error(_);
      });
  }

  private updateAlerts(type: string): void {
    if (type === 'alert') {
      this.deliveryMethodSettings.smsAlertEnabled = !this.deliveryMethodSettings.smsAlertEnabled;
    }

    const deliveryPromise = this._deliveryMethodService.setDeliveryMethodSettings(
      this.deliveryMethodSettings,
      type === 'none'
    );

    let alertsPromise: ng.IPromise<any>;

    const alertsSMSNotification: AlertsNotificationSetting = {
      notificationType: 'Sms',
      enable: false,
    };

    if (!this.deliveryMethodSettings.smsAlertEnabled && type === 'delivery') {
      alertsPromise = this._alertsNotificationsService.configureAlertsNotification(alertsSMSNotification);
    }
    alertsPromise = this._q.resolve('no alerts update required');

    if (type === 'delivery') {
      if (!this.deliveryMethodSettings.smsAlertEnabled) this._scope.$emit('showLoadingAlert', true);

      this._q
        .all([deliveryPromise, alertsPromise])
        .then(() => {
          this._scope.$emit('showEditingAlert', false);
          this._scope.$emit('restoreAlerts', this.deliveryMethodSettings.smsAlertEnabled);
          if (!this.deliveryMethodSettings.smsAlertEnabled) this._scope.$emit('loadUserAlerts');
          else this._scope.$emit('showLoadingAlert', false);

          this.savingSMSSetting = false;
          this.smsEnabledBy = '';
        })
        .catch(() => {
          this.restoreSavingSMSSettings();
        });
    } else {
      deliveryPromise
        .catch(() => {})
        .finally(() => {
          this.restoreSavingSMSSettings();
        });
    }
  }

  private restoreSavingSMSSettings(): void {
    this._scope.$emit('showLoadingAlert', false);
    this.savingSMSSetting = false;
    this.smsEnabledBy = '';
  }

  /**set cached user info */
  private _setUserInfo() {
    this.userInfo = this._root['userInfo'];
    if (!this.userInfo) return;
    this.userInfo.phoneNumber = this.loadUserProfileHelper.formatPhone(
      this.loadUserProfileHelper.getUserProfile().cellPhone1
    );
    this.userPhoneNumber = formatPhone(this.getSmsPhoneNumber());
    this._validatePhoneNumber(this.userInfo);
    this._validateEmail(this.userInfo.email);
  }

  private _getDeliveryMethodSettings() {
    this.savingEmailSetting = true;
    this.savingSMSSetting = true;
    this._deliveryMethodService
      .getDeliveryMethodSettings()
      .then((response: OlbResponse<AlertsDeliveryMethodSetting>) => {
        if (response.status === 'Success') {
          this.deliveryMethodSettings = response.data;
          this.deliveryMethodSettingsCopy = { ...response.data };
          this.savingEmailSetting = false;
          this.savingSMSSetting = false;
        }
      });
  }

  private _validatePhoneNumber = (userInfo: UserBasicInfo) => {
    if (userInfo.phoneNumber) {
      const regex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);

      this._scope.$parent['$mac'].phoneNumberEnabled = regex.test(userInfo.phoneNumber);
    }
  };

  private _base64ToArrayBuffer(base64: any): Uint8Array {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    return bytes;
  }

  private _validateEmail(email: string) {
    if (email && email.length > this.maxLenghtEmailTooltip) {
      this.truncatedEmail = email.substring(0, this.maxLenghtEmailTooltip) + '...';
      this.showEmailTooltip = true;
    }
  }

  private loadBrandProperties(brandId: number, brand: string): void {
    this.brandPropertyService.getBrandProperties(brandId, brand).subscribe(() => {
      this.smsTermsAndConditionsBrand = this._root['brandProperties']['SMSTermsAndConditions'];
    });
  }

  private getSmsPhoneNumber(): string {
    this.userInfo.phoneNumber = this.loadUserProfileHelper.formatPhone(
      this.loadUserProfileHelper.getUserProfile().cellPhone1
    );

    return this.userInfo.phoneNumber;
  }
}
