<div class="container">
  <div class="d-flex flex-column flex align-items-center">
    <div class="d-flex align-items-center title-container">
      <div class="d-flex flex-grow-1">
        <span class="title-text">Privacy Notice</span>
      </div>
      <div class="flex-shrink-1">
        <a class="modal-close__icon" mat-dialog-close="cancel" (click)="closeDialog()">
          <app-svg-icon [iconName]="icons.close" size="1.3rem"></app-svg-icon>
        </a>
      </div>
    </div>
    <div style="width: 100%;">
      <div class="pdf-container">
        <app-pdf-renderer [resourceUrl]="fileUrl" [options]="options"></app-pdf-renderer>
      </div>
    </div>
  </div>
</div>
