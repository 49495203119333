<div class="axos-trading-overview">
  <div class="summary" [hidden]="!showSummary">
    <div class="summary_actions">
      <div class="summary_text">
        <h3>Overview</h3>
      </div>
      <div *ngIf="!isClosedAccount()">
        <button class="btn__link d-none d-sm-block" (click)="goToOrbis(false)">
          <span>Trade Securities</span>
          <i class="fa fa-fw fa-external-link" aria-hidden="true"></i>
        </button>
        <button class="btn__link d-block d-sm-none" (click)="goToOrbis(true)">
          <span>Trade Securities</span>
          <i class="fa fa-fw fa-external-link" aria-hidden="true"></i>
        </button>
      </div>
      <div>
        <button
          *ngIf="!showChart"
          class="summary_insights uk-btn sm solid"
          (click)="showChart = !showChart"
          [ngClass]="{ 'summary_insights--active': showChart }"
        >
          <img
            src="{{ showChart ? '/img/icons/filters/insights-blue.svg' : '/img/icons/filters/insights-white.svg' }}"
          />
          <span>Insights</span>
        </button>
      </div>
    </div>
    <div class="values">
      <div>
        <span class="values_money">{{ summary?.totalValue | currency }}</span>
        <div>
          Total Value
        </div>
      </div>
      <div>
        <span class="values_money">{{ summary?.cashInvest | currency }}</span>
        <div>
          Cash & Cash Invest.
        </div>
      </div>
      <div>
        <span class="values_money">{{ summary?.marketValue | currency }}</span>
        <div>
          Market Value
        </div>
      </div>
      <div>
        <span class="values_money" [ngClass]="summary?.dayChange >= 0 ? 'values_money--gain' : 'values_money--loss'">
          {{ summary?.dayChange < 0 ? (summary?.dayChange | currency) : '+' + (summary?.dayChange | currency) }}
        </span>
        <span
          class="values_money"
          [ngClass]="summary?.dayChangePercent >= 0 ? 'values_money--gain' : 'values_money--loss'"
        >
          {{
            summary?.dayChangePercent < 0
              ? '(' + (summary?.dayChangePercent | percentageRound) + '%)'
              : '(+' + (summary?.dayChangePercent | percentageRound) + '%)'
          }}
        </span>
        <div>
          Day Change
        </div>
      </div>
      <div>
        <span class="values_money">{{ summary?.costBasis | currency }}</span>
        <div>
          Cost Basis
        </div>
      </div>
      <div>
        <span class="values_money" [ngClass]="summary?.gainLoss >= 0 ? 'values_money--gain' : 'values_money--loss'">
          {{ summary?.gainLoss < 0 ? (summary?.gainLoss | currency) : '+' + (summary?.gainLoss | currency) }}
        </span>
        <span
          class="values_money"
          [ngClass]="summary?.gainLossPercent >= 0 ? 'values_money--gain' : 'values_money--loss'"
        >
          {{
            summary?.gainLossPercent < 0
              ? '(' + (summary?.gainLossPercent | percentageRound) + '%)'
              : '(+' + (summary?.gainLossPercent | percentageRound) + '%)'
          }}
        </span>
        <div>
          Gain/Loss
        </div>
      </div>
    </div>
  </div>
  <app-dot-loader [showWhen]="isLoading">
    Loading Positions
  </app-dot-loader>
  <div class="positions" [hidden]="isLoading">
    <div class="positions_title" [hidden]="!showSummary">
      <h4>Positions</h4>
    </div>
    <div>
      <table class="table ut-table hide-xs hide-cs">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Description</th>
            <th class="a-right">Quantity / Price</th>
            <th class="a-right">Market Value</th>
            <th class="a-right">Day Change</th>
            <th class="a-right">Cost Basis</th>
            <th class="a-right">Gain/Loss</th>
            <th class="a-right">% of Account</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!positions || positions.length == 0">
            <td colspan="4">There are no positions to display</td>
          </tr>
          <tr *ngFor="let position of positions">
            <td>{{ position.symbol }}</td>
            <td>{{ position.description }}</td>
            <td class="a-right">{{ position.quantity }} / {{ position.price | currency }}</td>
            <td class="a-right">{{ position.marketValue | currency }}</td>
            <td class="a-right">
              <span [ngClass]="position.dayChange >= 0 ? 'positions--gain' : 'positions--loss'">
                {{ position.dayChange < 0 ? (position.dayChange | currency) : '+' + (position.dayChange | currency) }}
              </span>
              <span [ngClass]="position.dayChangePercent >= 0 ? 'positions--gain' : 'positions--loss'">
                {{
                  position.dayChangePercent < 0
                    ? '(' + (position.dayChangePercent | percentageRound) + '%)'
                    : '(+' + (position.dayChangePercent | percentageRound) + '%)'
                }}
              </span>
            </td>
            <td class="a-right">{{ position.costBasis | currency }}</td>
            <td class="a-right">
              <span [ngClass]="position.gainLoss >= 0 ? 'positions--gain' : 'positions--loss'">
                {{ position.gainLoss < 0 ? (position.gainLoss | currency) : '+' + (position.gainLoss | currency) }}
              </span>
              <span [ngClass]="position.gainLossPercent >= 0 ? 'positions--gain' : 'positions--loss'">
                {{
                  position.gainLossPercent < 0
                    ? '(' + (position.gainLossPercent | percentageRound) + '%)'
                    : '(+' + (position.gainLossPercent | percentageRound) + '%)'
                }}
              </span>
            </td>
            <td class="a-right">{{ position.percentageOfAccount | percentageRound }}%</td>
          </tr>
          <tr>
            <td></td>
            <td>Cash & Cash Investments</td>
            <td></td>
            <td class="a-right">{{ summary?.cashInvest | currency }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="a-right">{{ marketValuePercent | percentageRound }}%</td>
          </tr>
        </tbody>
      </table>
      <app-positions-responsive [positions]="positions" [cashInvest]="summary?.cashInvest"></app-positions-responsive>
    </div>
  </div>

  <div class="compliance_disclaimer">
    <p>
      For Mutual funds, the NAV ("Market Value") is a daily calculation occurring after market close. This process may
      take 2-to-4 hours before a final NAV is made available to the public. The prices displayed are generally the
      previous business day’s closing price.
    </p>
    <p>
      All information presented by a third party and is subject to delays. Intraday prices are generally provided for
      stocks, ETFs, and options on a real-time basis. Delays may occur during market hours. If delayed, and an intraday
      price is not available, the price displayed will be the last quote & quantity received from the vendor, or will
      reflect the previous business day’s close,whichever is most recent. For mutual funds, the prices displayed are
      generally the previous business day’s closing price.
    </p>
    <p>
      A money market mutual fund investment, if shown, is not insured or guaranteed by the Federal Deposit Insurance
      Corporation or any government agency. Although a money market mutual fund seeks to preserve the value of your
      investment, at $1 per share, it is possible to lose money by investing in such a fund.
    </p>
    <p>
      Insured Deposit Account offers FDIC asset protection for customer up to $2.5 million.
    </p>
    <p>
      If shown, a non-money market fund’s SEC yield is based upon a formula mandated by the Securities & Exchange
      Commission (SEC) that calculates a fund’s hypothetical annualized income as a percentage of its assets. A
      securities income, for the purposes of this calculation, is based upon the current market yield to maturity (in
      the case of bonds) or projected dividend yield (for stocks) of the fund’s holdings over a trailing 30 day period.
      This hypothetical income will differ (at times, significantly) from the funds actual experience. As a result,
      income distributions from the fund may be higher or lower than implied by the SEC yield. The SEC yield for a money
      market is calculated by annualizing its daily income distributions for the previous 7 days.
    </p>
  </div>
</div>
