import * as CryptoJS from 'crypto-js';

export class LoanPayoffUrlHelper {
  // Encryption parameters
  private key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000');
  private iv = CryptoJS.enc.Utf8.parse('1601011111001101');

  private encryptValue(value: string) {
    // Encrypting method
    let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  }

  encryptUrl(baseUrl: string, loanNumber: string, jwt: string): string {
    // Encrypt parameters to be used in the URL
    let loanNumberEncrypted: string = this.encryptValue(loanNumber);

    return `${baseUrl}?LoanNumber=${loanNumberEncrypted}&JWT=${jwt}`;
  }
}
