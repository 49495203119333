import { Component, Inject, Input, OnInit } from '@angular/core';

import { ROOT_SCOPE, WINDOW } from '@core/tokens';

import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  loggedUser = false;

  @Input()
  set isLoggedUser(loggedUser: string | undefined) {
    this.loggedUser = loggedUser !== undefined;
  }

  public isWeb2FooterActive = false;

  constructor(
    private readonly _featureFlagService: FeatureFlagService,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService,
    @Inject(WINDOW) private _window: Window
  ) {}

  ngOnInit() {
    this.isWeb2FooterActive = this.isWeb2FooterActivate(this._window.location.href);
    this.$rootScope.$on('$locationChangeStart', (_: any, next: string) => {
      this.isWeb2FooterActive = this.isWeb2FooterActivate(next);
    });
  }

  isWeb2FooterActivate(url: string): boolean {
    const urlSection = this.extractURL(url);
    const pdpUrlRegex = /^\/account\/(advisor|details).*/i;
    return (this.isWeb2FooterActive =
      urlSection.match(pdpUrlRegex) !== null && this._featureFlagService.isWeb2FooterActive());
  }

  private extractURL(url: string): string {
    const urlRegex = /^.*\/\/([^\/]+)(\/.*)/;
    const results = url.match(urlRegex);
    if (results && results.length > 2) {
      return results[2];
    }
    return '';
  }
}
