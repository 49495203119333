import { downgradeComponent } from '@angular/upgrade/static';
import {
  AddCheckRecipientComponent,
  CheckConfirmationComponent,
  EStatementsAccountsComponent,
  EStatementsAuthenticationComponent,
  EditRecipientComponent,
  ScheduledPaymentsComponent,
  PayConfirmationComponent,
  PayReviewComponent,
  EstatementsTabComponent,
  DetailsTabComponent,
  PaymentsTabComponent,
  EStatementsTileComponent,
  EditRecipientConfirmationComponent,
  PaymentsHistoryComponent,
  RecipientInformationComponent,
  EStatementsComponent,
  ActivityComponent,
  BillingDetailsComponent,
  ElectronicAddressComponent,
  ElectronicCheckpointComponent,
  ElectronicConfirmationComponent,
} from '@app/bill-pay/components/Index';
import { Inject } from '@legacy/decorators/decorators';

@Inject('$stateProvider')
export class billPayLegacyConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.billPay.addCheckRecipient', {
        url: '/check-recipient',
        views: {
          billpay: {
            template: '<app-add-check-recipient></app-add-check-recipient>',
          },
        },
      })
      .state('udb.billPay.checkConfirmation', {
        url: '/check-recipient-confirmation',
        views: {
          billpay: {
            template: '<app-check-confirmation></app-check-confirmation>',
          },
        },
      })
      .state('udb.billPay.eStatements.accounts', {
        url: '/accounts',
        views: {
          eStatements: {
            template: '<app-e-statements-accounts></app-e-statements-accounts>',
          },
        },
      })
      //.state('udb.billPay.addCheckRecipient', {
      //  url: '/check-recipient',
      //  views: {
      //    billpay: {
      //      template: '<app-add-check-recipient></app-add-check-recipient>',
      //    },
      //  },
      //})
      .state('udb.billPay.eStatements.authentication', {
        url: '/authentication',
        views: {
          eStatements: {
            template: '<app-e-statements-authentication></app-e-statements-authentication>',
          },
        },
      })
      .state('udb.billPay.editRecipient', {
        url: '/edit-recipient',
        views: {
          billpay: {
            template: '<app-edit-recipient></app-edit-recipient>',
          },
        },
      })
      .state('udb.billPay.editRecipientConfirmation', {
        url: '/edit-recipient-confirmation',
        views: {
          billpay: {
            template: '<app-edit-recipient-confirmation></app-edit-recipient-confirmation>',
          },
        },
      })
      .state('udb.billPay.payReview', {
        url: '/pay-review',
        views: {
          billpay: {
            template: '<app-pay-review></app-pay-review>',
          },
        },
      })
      .state('udb.billPay.payConfirmation', {
        url: '/pay-confirmation',
        views: {
          billpay: {
            template: '<app-pay-confirmation></app-pay-confirmation>',
          },
        },
      })
      .state('udb.billPay.activity', {
        url: '/activity',
        views: {
          billpay: {
            template: '<app-activity></app-activity>',
          },
        },
      })
      .state('udb.billPay.eStatements', {
        url: '/eBills',
        views: {
          billpay: {
            template: '<div ui-view="eStatements"><app-e-statements></app-e-statements></div>',
          },
        },
        params: {
          recipientId: null,
        },
      })
      .state('udb.billPay.billingDetails', {
        url: '/billing-details',
        views: {
          billpay: {
            template: '<app-billing-details></app-billing-details>',
          },
        },
      })
      .state('udb.billPay.electronicAddress', {
        url: '/electronic-address',
        views: {
          billpay: {
            template: '<app-electronic-address></app-electronic-address>',
          },
        },
      })
      .state('udb.billPay.electronicCheckpoint', {
        url: '/electronic-checkpoint',
        views: {
          billpay: {
            template: '<app-electronic-checkpoint></app-electronic-checkpoint>',
          },
        },
      })
      .state('udb.billPay.electronicConfirmation', {
        url: '/electronic-confirmation',
        views: {
          billpay: {
            template: '<app-electronic-confirmation></app-electronic-confirmation>',
          },
        },
      });
  }
}

export const billPayLegacyModule = angular
  .module('udb.billPayLegacy', ['ui.router'])
  .directive('appElectronicCheckpoint', downgradeComponent({ component: ElectronicCheckpointComponent }))
  .directive('appElectronicConfirmation', downgradeComponent({ component: ElectronicConfirmationComponent }))
  .directive('appPaymentsHistory', downgradeComponent({ component: PaymentsHistoryComponent }))
  .directive('appScheduledPayments', downgradeComponent({ component: ScheduledPaymentsComponent }))
  .directive('appAddCheckRecipient', downgradeComponent({ component: AddCheckRecipientComponent }))
  .directive('appCheckConfirmation', downgradeComponent({ component: CheckConfirmationComponent }))
  .directive('appEStatementsAccounts', downgradeComponent({ component: EStatementsAccountsComponent }))
  .directive('appEStatementsAuthentication', downgradeComponent({ component: EStatementsAuthenticationComponent }))
  .directive('appEditRecipient', downgradeComponent({ component: EditRecipientComponent }))
  .directive('appEditRecipientConfirmation', downgradeComponent({ component: EditRecipientConfirmationComponent }))
  .directive('appPayConfirmation', downgradeComponent({ component: PayConfirmationComponent }))
  .directive('appPayReview', downgradeComponent({ component: PayReviewComponent }))
  .directive('appEstatementsTab', downgradeComponent({ component: EstatementsTabComponent }))
  .directive('appDetailsTab', downgradeComponent({ component: DetailsTabComponent }))
  .directive('appPaymentsTab', downgradeComponent({ component: PaymentsTabComponent }))
  .directive('appRecipientInformation', downgradeComponent({ component: RecipientInformationComponent }))
  .directive('appEStatementsTile', downgradeComponent({ component: EStatementsTileComponent }))
  .directive('appEStatements', downgradeComponent({ component: EStatementsComponent }))
  .directive('appActivity', downgradeComponent({ component: ActivityComponent }))
  .directive('appBillingDetails', downgradeComponent({ component: BillingDetailsComponent }))
  .directive('appElectronicAddress', downgradeComponent({ component: ElectronicAddressComponent }))

  .config(billPayLegacyConfig).name;
