export enum ScheduleFrequency {
  None = 0,
  OneTimeOnly = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
  BiWeekly = 5,
  Quarterly = 6,
  SemiAnnually = 7,
  Yearly = 8,
}
