export const AMOUNT_FORMAT = '1.2-2';
export const PX = 'px';
export const IS_BOTTOM_OVERFLOWING_CLASS = 'is-bottom-overflowing';
export const DASH_PLACEHOLDER = '--';
export const MVP_MIN_THRESHOLD = 953;
export const MVP_MAX_THRESHOLD = 1550;
export const MVP_GRID_SELECTOR = 'item';
export const MVP_PRICE_ROW_SELECTOR = 'price-container';
export const MVP_VALUE_ROW_SELECTOR = 'value-movement-container';
export const MVP_GRID_CLASS = 'grid-mvp';
export const MVP_ROW_CLASS = 'row-mvp';
