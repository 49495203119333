<app-main-layout>
  <div id="main-layout">
    <div id="main-container" [ngClass]="{ 'people-main-container': selectedTabIndex === 2 }">
      <div id="header">
        <span id="title">Account Details</span>
        <uk2-tooltip
          [size]="tooltipSize"
          [bodyText]="tooltipText"
          [displayCloseButton]="tooltipDisplayCloseButton"
          [svgIconName]="tooltipIcon"
          [placement]="tooltipPlacement"
          [desktopOpenMode]="tooltipTrigger"
          [strategy]="tooltipStrategy"
          id="tooltip"
        ></uk2-tooltip>

        <mat-tab-group
          uk2StretchTabs
          disableRipple
          [uk2StretchTabsSize]="tabsSize"
          mat-stretch-tabs
          animationDuration="0ms"
          (selectedTabChange)="selectTab($event)"
          id="tabs"
        >
          <mat-tab *ngFor="let tab of tabs" [label]="tab"></mat-tab>
        </mat-tab-group>
      </div>
      <div>
        <!--Content-->
        <ng-container [ngSwitch]="selectedTabIndex">
          <app-accounts-tab *ngSwitchCase="0"></app-accounts-tab>
          <app-authorizations-tab *ngSwitchCase="1"></app-authorizations-tab>
          <app-people-tab *ngSwitchCase="2"></app-people-tab>
        </ng-container>
        <aas-account-firm-contacts-tab
          *ngIf="selectedTabIndex === 3 && riaPilotFirmContactsActive"
        ></aas-account-firm-contacts-tab>
      </div>
    </div>
    <!-- Contact Card -->
    <div *ngIf="riaPilotFirmContactsActive" id="contact-card-container">
      <ng-container *ngIf="accountFirmContacts$ | async as accountFirmContactsState">
        <ng-container
          *ngIf="accountFirmContactsState && !accountFirmContactsState.error; else contactCardFailedStateContent"
        >
          <aas-data-tile-list
            *ngIf="accountFirmContactsState.primaryAdvisor as primaryAdvisorFirm"
            header="Your Advisor"
            data-testid="primaryAdvisorTestId"
          >
            <aas-data-tile-list-row
              *ngIf="primaryAdvisorFirm.name"
              [type]="aasDataTileListRowTypeEnum.Text"
              [title]="'Name'"
              [value]="primaryAdvisorFirm.name"
            ></aas-data-tile-list-row>
            <aas-data-tile-list-row
              *ngIf="primaryAdvisorFirm.mobilePhone"
              [type]="aasDataTileListRowTypeEnum.MobilePhone"
              [title]="'Phone'"
              [value]="primaryAdvisorFirm.mobilePhone"
            ></aas-data-tile-list-row>
            <aas-data-tile-list-row
              *ngIf="primaryAdvisorFirm.email"
              [type]="aasDataTileListRowTypeEnum.Email"
              [title]="'Email'"
              [value]="primaryAdvisorFirm.email"
            ></aas-data-tile-list-row>
            <aas-data-tile-list-row
              *ngIf="primaryAdvisorFirm.address"
              [type]="aasDataTileListRowTypeEnum.Address"
              [title]="'Address'"
              [value]="primaryAdvisorFirm.address"
            ></aas-data-tile-list-row>
          </aas-data-tile-list>
          <aas-data-tile-list
            header="Your RIA Firm"
            *ngIf="!accountFirmContactsState.primaryAdvisor && accountFirmContactsState.riaFirm as riaFirm"
            data-testid="riaFirmTestId"
          >
            <aas-data-tile-list-row
              *ngIf="riaFirm.name"
              [type]="aasDataTileListRowTypeEnum.Text"
              [title]="'Name'"
              [value]="riaFirm.name"
            ></aas-data-tile-list-row>
            <aas-data-tile-list-row
              *ngIf="riaFirm.mobilePhone"
              [type]="aasDataTileListRowTypeEnum.MobilePhone"
              [title]="'Phone'"
              [value]="riaFirm.mobilePhone"
            ></aas-data-tile-list-row>
            <aas-data-tile-list-row
              *ngIf="riaFirm.email"
              [type]="aasDataTileListRowTypeEnum.Email"
              [title]="'Email'"
              [value]="riaFirm.email"
            ></aas-data-tile-list-row>
            <aas-data-tile-list-row
              *ngIf="riaFirm.address"
              [type]="aasDataTileListRowTypeEnum.Address"
              [title]="'Firm Address'"
              [value]="riaFirm.address"
            ></aas-data-tile-list-row>
          </aas-data-tile-list>
        </ng-container>

        <ng-template #contactCardFailedStateContent>
          <uk2-text-tile
            [uk2TextTileTitle]="'Advisor / Firm Contact Temporarily Unavailable'"
            [uk2TextTileIsStandaloneTile]="false"
            [uk2TextTileDisplayBackground]="false"
          >
            We're sorry, but we're currently unable to provide you with your latest advisor / firm contacts. This
            includes their
            <span class="uk2-font__bold--75">Name, Mobile Phone, Email, and Address</span>
            information. Please check back later. Thank you for your patience.
          </uk2-text-tile>
        </ng-template>
      </ng-container>
    </div>
  </div>
</app-main-layout>
