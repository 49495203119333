import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Uk2Tier1NavigationEnum } from '@axos/uikit-v2-lib';
import { DialogService } from '@core/services';
import { EDisclosureModal } from '../../../modal-documents/e-disclosure/view/e-disclosure-modal.component';
import { PersonalDepositModal } from '../../../modal-documents/personal-deposit/view/personal-deposit-modal.component';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DOCS_MODAL_WIDTH, DOCS_PANEL_CLASS } from '../../constants';
import { PrivacyNoticeModal } from '../../../modal-documents/privacy-notice/view/privacy-notice-modal.component';

@Component({
  selector: 'app-disclosures-step',
  templateUrl: './disclosures-step.component.html',
  styleUrls: ['./disclosures-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclosuresStepComponent {
  @Output() acceptDisclosures: EventEmitter<void>;
  uk2Tier1NavigationEnum: any;
  disclosuresFormGroup: FormGroup;
  dialogsConfig: MatDialogConfig;
  hasAcceptedDisclosures: boolean;

  constructor(private formBuilder: FormBuilder, private dialogService: DialogService) {
    this.acceptDisclosures = new EventEmitter<void>();
    this.uk2Tier1NavigationEnum = Uk2Tier1NavigationEnum;
    this.disclosuresFormGroup = this.formBuilder.group({
      eDisclosure: [false, [Validators.requiredTrue]],
      personalDepositAccountDisclosure: [false, [Validators.requiredTrue]],
      consumerPrivacyDisclosure: [false, [Validators.requiredTrue]],
    });
    this.dialogsConfig = {
      width: DOCS_MODAL_WIDTH,
      panelClass: DOCS_PANEL_CLASS,
      disableClose: true,
    };
    this.hasAcceptedDisclosures = false;
  }

  openEDisclosureStatement(): void {
    this.dialogService.openByComponentRef(EDisclosureModal, this.dialogsConfig);
  }

  openPersonalDepositAccountAgreement(): void {
    this.dialogService.openByComponentRef(PersonalDepositModal, this.dialogsConfig);
  }

  openPrivacyNotice(): void {
    this.dialogService.openByComponentRef(PrivacyNoticeModal, this.dialogsConfig);
  }

  updateAcceptanceState(): void {
    this.acceptDisclosures.next();
    this.hasAcceptedDisclosures = true;
  }
}
