export class GoalAllocation {
  allocationStart?: string;
  allocationEnd?: string;
  cashStart?: number;
  cashEnd?: number;

  constructor(args?: Partial<GoalAllocation>) {
    Object.assign(this, args);
  }
}
