import { CmBusinessSelectionComponent } from './compose-message/cm-business-selection/cm-business-selection.component';
import { CmMessageComponent } from './compose-message/cm-message/cm-message.component';
import { CmConfirmationComponent } from './compose-message/cm-confirmation/cm-confirmation.component';
import { CmSubcategoriesComponent } from './compose-message/cm-subcategories/cm-subcategories.component';
import { ComposeMessageComponent } from './compose-message/compose-message/compose-message.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { MessagesComponent } from './messages/messages.component';

export {
  CmBusinessSelectionComponent,
  CmMessageComponent,
  CmConfirmationComponent,
  CmSubcategoriesComponent,
  ComposeMessageComponent,
  MessageDetailComponent,
  MessagesComponent,
};

export const messageComponents = [
  CmBusinessSelectionComponent,
  CmMessageComponent,
  CmConfirmationComponent,
  CmSubcategoriesComponent,
  ComposeMessageComponent,
  MessageDetailComponent,
  MessagesComponent,
];
