import { employmentStatusesReducer as employmentStatuses } from './employment-statuses/employment-statuses.reducer';
import { financialCatalogsReducer as financialCatalogs } from './financial-catalogs/financial-catalogs.reducer';
import { maritalStatusesReducer as maritalStatuses } from './marital-statuses/marital-statuses.reducer';
import { profileDetailsReducer as profileDetails } from './profile-details/profile-details.reducer';

export * from './financial-catalogs/financial-catalogs.state';
export * from './user-profile.state';

export const userProfileReducers = {
  maritalStatuses,
  financialCatalogs,
  employmentStatuses,
  profileDetails,
};
