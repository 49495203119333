import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { SharedMatModule } from './share-account-mat.module';
import { ShareAccountUk2Module } from './share-account-uk2.module';
import { ShareAccountRiaComponent } from './view';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [ShareAccountRiaComponent],
  imports: [
    SharedMatModule,
    ShareAccountUk2Module,
    SharedModule,
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [ShareAccountRiaComponent],
})
export class ShareAccountModule {}
