import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PortfolioAllocation } from '@app/axos-invest/enums';
import { PortfolioDetails } from '@app/axos-invest/models';
import { FilesIcons } from '@shared/enums';

@Component({
  selector: 'app-portfolio-allocation-selector',
  templateUrl: './portfolio-allocation-selector.component.html',
  styleUrls: ['./portfolio-allocation-selector.component.scss'],
})
export class PortfolioAllocationSelectorComponent {
  @Input() currentAllocation: PortfolioDetails;
  @Input() endAllocation: PortfolioDetails;
  @Input() saveAllocation = false;
  @Output() savePortfolio = new EventEmitter();

  @Output() loadAllocation = new EventEmitter<PortfolioAllocation>();

  portfolioAllocations = PortfolioAllocation;
  editIcon = FilesIcons.Pencil;

  selectAllocation(type: PortfolioAllocation) {
    this.loadAllocation.emit(type);
  }
}
