import { ScheduledTransferTileState } from './scheduled-transfers-tile-state.type';

export type ScheduledTransferState = {
  scheduledTransfers: ScheduledTransferTileState[];
  selectedAccountScheduledTransfers: string | undefined;
};

export const intialScheduledTransferState: ScheduledTransferState = {
  scheduledTransfers: [],
  selectedAccountScheduledTransfers: undefined,
};
