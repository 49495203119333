<h5>Payment</h5>

<div>
  <label>Payment Amount</label>
  <p class="amount" [class.past-due]="isPastDue">{{ details.accountPayment.amount | currency }}</p>
</div>

<div>
  <label>Last Payment Date</label>
  <p class="last-payment-date">{{ details.accountPayment.lastPaymentDate | date: 'MM/dd/yyyy' }}</p>
</div>

<div>
  <label>Last Payment Amount</label>
  <p class="last-payment-amount">{{ details.accountPayment.lastPaymentAmount | currency }}</p>
</div>

<div>
  <label>Interest Paid YTD</label>
  <p class="interest-earned-ytd">{{ details.accountInterest.interestEarnedYtd | currency }}</p>
</div>

<div>
  <label>Previous Year Interest Paid</label>
  <p class="previous-year-interest">{{ details.accountInterest.previousYearInterest | currency }}</p>
</div>
