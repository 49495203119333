<footer>
  <div class="mobile-container-copyright">
    <p>
      © {{ date | date: 'yyyy' }} Axos Financial, Inc., All Rights Reserved. ©
      {{ date | date: 'yyyy' }} Axos Bank, All Rights Reserved. ©
      {{ date | date: 'yyyy' }} Axos Invest LLC. Member FINRA & SIPC. All Rights
      Reserved. © {{ date | date: 'yyyy' }} Axos Invest, Inc. All Rights
      Reserved.
    </p>
  </div>
  <div class="container-text">
    <p class="container-copyright">
      © {{ date | date: 'yyyy' }} Axos Financial, Inc., All Rights Reserved. ©
      {{ date | date: 'yyyy' }} Axos Bank, All Rights Reserved. ©
      {{ date | date: 'yyyy' }} Axos Invest LLC. Member FINRA & SIPC. All Rights
      Reserved. © {{ date | date: 'yyyy' }} Axos Invest, Inc. All Rights
      Reserved.
    </p>

    <p>
      <span class="bold">Bank products and services.</span>
      Axos Bank offers bank products and services. All deposit accounts through
      Axos Bank brands are FDIC insured through Axos Bank. All deposit accounts
      of the same ownership and/or vesting held at Axos Bank are combined and
      insured under the same
      <button
        class="bold"
        data-test-id="fdic-cert-link"
        (click)="openContinueToPageModal('https://edie.fdic.gov/')"
      >
        FDIC Certificate 35546.
      </button>
      All deposit accounts through Axos Bank brands are not separately insured
      by the FDIC from other deposit accounts held with the same ownership
      and/or vesting at Axos Bank. For more information read
      <button
        class="bold"
        (click)="
          openContinueToPageModal('https://www.axosbank.com/Legal/FDIC-Notice')
        "
      >
        Axos Bank's FDIC Notice.
      </button>
      Axos Bank
      <button
        (click)="openContinueToPageModal('https://nmlsconsumeraccess.org/')"
        class="bold"
      >
        NMLS #524995.
      </button>
    </p>
    <p>
      <span class="bold">
        For customers of Axos Invest, Inc. and Axos Invest LLC.
      </span>
      Your advisory services are offered by Axos Invest, Inc., an investment
      advisor registered with the Securities and Exchange Commission. Your
      brokerage services are offered by Axos Invest LLC, member FINRA & SIPC.
    </p>
    <p>
      <span class="bold">
        For customers of other advisory or brokerage firms.
      </span>
      Your advisory services are provided by a third party registered investment
      advisor. Your brokerage services are offered by a third party
      broker-dealer. These services are not offered by Axos Invest, Inc. or Axos
      Invest LLC. Please contact your advisor or broker if you have questions
      concerning the investment advisory or brokerage services that you receive
      from them.
    </p>
    <p>
      <span class="bold">Clearing and custody services.&nbsp;</span>
      <button
        (click)="openContinueToPageModal('https://axosclearing.com/')"
        class="bold"
      >
        Axos Clearing LLC
      </button>
      provides clearing and custody services to both affiliated and third party
      registered investment advisors and broker-dealers. Axos Advisor Services
      is a division within Axos Clearing LLC that provides custody and related
      services to registered investment advisors. Axos Clearing LLC and its Axos
      Advisor Services division do not provide investment advice or make
      investment recommendations in any capacity. Axos Clearing LLC is a member
      of FINRA and SIPC.
    </p>
    <p>
      Securities and other non-deposit investment products and services are not
      deposits, obligations of or guaranteed by Axos Bank, are not insured by
      the FDIC or any governmental agency, and are subject to investment risk
      including possible loss of the principal invested.
    </p>
    <p>
      Axos Bank, Axos Invest, Inc., Axos Invest LLC, and Axos Clearing LLC are
      separate companies commonly owned by Axos Financial, Inc.
    </p>
    <p>
      Quote Details. Intraday prices are generally provided for stocks, ETFs,
      and options on a delayed basis during market hours. Prices are delayed at
      least 20 minutes. If an intraday price is not available, the price
      displayed will reflect the previous business day's close. For mutual funds
      and fixed income holdings, the prices displayed are generally the previous
      business day's closing price.
    </p>
  </div>
  <div class="container-links">
    <button
      class="support-btn"
      *ngIf="isLoggedUser"
      (click)="changeState('udb.support')"
    >
      SUPPORT
    </button>
    <a
      rel="noferrer noopener"
      target="_blank"
      href="https://www.axosbank.com/en/Legal/Privacy"
    >
      PRIVACY & SECURITY
    </a>

    <a
      rel="noferrer noopener"
      target="_blank"
      href="https://www.axosbank.com/Legal/Disclosures"
    >
      DISCLOSURES
    </a>

    <button
      class="cobrowse-btn"
      type="button"
      *ngIf="isLoggedUser"
      (click)="openCobrowseSession()"
    >
      START A COBROWSE SESSION
    </button>
  </div>
  <div class="container-logos">
    <a
      href="https://www.axosbank.com/legal/EHL-notice"
      rel="noferrer noopener"
      target="_blank"
      class="footer__link"
      aria-label="Equal housing lender"
      rel="noopener"
    >
      <img
        class="ehl images_ehl"
        src="assets/img/logos/ehl-w-logo.svg"
        alt="Equal housing lender"
      />
    </a>
    <a
      href="https://www.axosbank.com/legal/fdic-notice"
      rel="noferrer noopener"
      target="_blank"
      class="footer__link"
      aria-label="FDIC Notice"
      rel="noopener"
    >
      <img
        class="fdic images_fdic"
        src="assets/img/logos/fdic-w-logo.svg"
        alt="FDIC notice"
      />
    </a>
  </div>
  <uk2-modal
    title="You're continuing to another website"
    [disabledCloseOverlayBackdrop]="true"
    [showCloseButton]="false"
    [showTitleDivider]="true"
    [showActionsDivider]="true"
    [titleAlign]="titleAlign"
    [bodyContentAlignment]="contentAlign"
    [innerPanelClass]="['uk2-custom-modal-white-label']"
    #urlRedirectModal
  >
    <ng-template #uk2ModalContent>
      <p>
        To complete your request, you are being directed away
        <br />
        from this platform. Would you like to continue?
      </p>
    </ng-template>
    <ng-template #uk2ModalActions>
      <button
        uk2SecondaryButton
        mat-stroked-button
        disableRipple
        (click)="urlRedirectModal.closeDialog()"
      >
        Cancel
      </button>
      <button
        data-test-id="url-redirect-modal-confirm-btn"
        uk2PrimaryButton
        mat-raised-button
        disableRipple
        (click)="openUrl()"
      >
        Yes, Continue
      </button>
    </ng-template>
  </uk2-modal>

  <uk2-modal
    [title]="confirmCoBrowseModalBodyTitle"
    [disabledCloseOverlayBackdrop]="true"
    [showCloseButton]="false"
    [showTitleDivider]="true"
    [showActionsDivider]="true"
    [titleAlign]="titleAlign"
    [bodyContentAlignment]="contentAlign"
    [innerPanelClass]="['uk2-custom-modal-white-label']"
    #coBrowseModal
  >
    <ng-template #uk2ModalContent>
      <p data-test-id="co-browse-modal-body-text">
        {{ confirmCoBrowseModalBodyText }}
      </p>
    </ng-template>
    <ng-template #uk2ModalActions>
      <button
        uk2SecondaryButton
        mat-stroked-button
        disableRipple
        (click)="coBrowseModal.closeDialog()"
      >
        Decline
      </button>
      <button
        data-test-id="co-browse-modal-accept-btn"
        uk2PrimaryButton
        mat-raised-button
        disableRipple
        (click)="startCoBrowse()"
      >
        Accept
      </button>
    </ng-template>
  </uk2-modal>
</footer>
