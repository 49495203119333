import { Inject } from '../../decorators/decorators';

@Inject('$stateProvider')
export class MessageDetailConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.messages.messageDetail', {
      url: '/message-detail/:caseId/:status/:hasread/message',
      views: {
        messagesContent: {
          template: `<app-message-detail></app-message-detail>`,
          //templateUrl: 'messages/message-detail/message-detail.tpl.html',
          //controller: 'MessageDetailController',
          //controllerAs: '$mdm',
        },
      },
    });
  }
}
