import { TradingAccount } from 'accounts/typings/TradingAccount';
import { AnyAction } from 'redux';

import { ACCOUNTS } from '../../constants/accounts';

export function TradingReducer(state: TradingAccount[] = null, action: AnyAction) {
  switch (action.type) {
    case ACCOUNTS.TRADING.LOAD:
      return action.payload;
    case ACCOUNTS.TRADING.UPDATE:
      return state.map(account => {
        if (account.accountNumber === action.payload.accountNumber) {
          account.nickname = action.payload.nickname;
          account.pendingSpousalConsent = action.payload.pendingSpousalConsent;
        }

        return account;
      });
    default:
      return state;
  }
}
