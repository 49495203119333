import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { AvailableCashMatModule } from './available-cash-mat.module';
import { AvailableCashUk2Module } from './available-cash-uk2.module';
import { SharedModule } from '@shared/shared.module';
import { AvailableCashService, availableCashReducer, initialAvailableCashState } from './core';
import { AvailableCashTileComponent } from './view';
import { CommonModule } from '@angular/common';
import { AvailableCashFacade } from './facade';

@NgModule({
  declarations: [AvailableCashTileComponent],
  providers: [AvailableCashService, AvailableCashFacade],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    AvailableCashMatModule,
    AvailableCashUk2Module,
    StoreModule.forFeature(AasStoreFeatures.AvailableCash, availableCashReducer, {
      initialState: initialAvailableCashState,
    }),
  ],
  exports: [AvailableCashTileComponent],
})
export class AvailableCashModule {}
