import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import { GetFirmContactsByAccountRequestType } from './types/get-firm-contacts-by-account-request.type';
import { GetSetRiaWelcomePageRequestType } from './types/get-set-ria-welcome-page-request.type';
import { GetFirmContactsByAccountResponseType } from './types/get-firm-contacts-by-account-response.type';
import { OrganizationContactInfo } from '@app/Areas/AAS/features/interestitial-page/core/store/models/organization-contact-info';
import { WelcomeScreenSetResponse } from '@app/Areas/AAS/features/interestitial-page/core/store/models/welcome-screen-set-response';

@Injectable({
  providedIn: 'root',
})
export class RIAContactsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/contacts');
  }

  getLibertyAccountOrgInfo(): WebApiResponse<OrganizationContactInfo[]> {
    return this.get(`libertyAccountOrgInfo`);
  }

  setRiaWelcomePage(request: GetSetRiaWelcomePageRequestType): WebApiResponse<WelcomeScreenSetResponse[]> {
    return this.post(`WelcomePageTriggered/${request.riaId}`);
  }

  getFirmContactsByAccount(
    request: GetFirmContactsByAccountRequestType
  ): WebApiResponse<GetFirmContactsByAccountResponseType> {
    return this.get<GetFirmContactsByAccountResponseType>(`firms/${request.accountNumber}`);
  }
}
