import {
  Holding,
  TFilterOptionLabels,
  THoldingSortDirection,
  THoldingSortDirectionValues,
  THoldingSortOptionLabel,
  THoldingSortType,
  THoldingSortTypeValues,
} from '../../store';
import { lowercaseFirstLetter } from '../lower-case-first-letter/lower-case-first-letter.function';

const defaultHoldingsSortSeed: THoldingSortOptionLabel = {
  PortfolioPercent: {
    typeLabel: '% of Portfolio',
    sortByKey: 'portfolioPercent',
    directionLabels: {
      Descending: 'H-L',
      Ascending: 'L-H',
    },
  },
  ValueLastDayChangePercent: {
    typeLabel: '% Change Today',
    sortByKey: 'valueLastDayChangePercent',
    directionLabels: {
      Descending: 'H-L',
      Ascending: 'L-H',
    },
  },
  Symbol: {
    typeLabel: 'Symbol',
    sortByKey: 'symbol',
    directionLabels: {
      Descending: 'Z-A',
      Ascending: 'A-Z',
    },
  },
};

function createHoldingsLabeledSortOptions(
  filterTypeOptions: THoldingSortType,
  filterDirectionOptions: THoldingSortDirection,
  labelsSeed: THoldingSortOptionLabel,
  selectedSortType: THoldingSortTypeValues,
  selectedSortDirection: THoldingSortDirectionValues
): TFilterOptionLabels[] {
  const filterOptions: TFilterOptionLabels[] = [];

  for (const [key, typeLabel] of Object.entries(labelsSeed)) {
    if (typeLabel) {
      for (const [directionLabel] of Object.entries(
        typeLabel.directionLabels
      )) {
        const option: TFilterOptionLabels = {
          isSelected:
            selectedSortDirection === filterDirectionOptions[directionLabel] &&
            selectedSortType === filterTypeOptions[key],
          text: `${typeLabel.typeLabel} ${typeLabel.directionLabels[directionLabel]}`,
          sortByKey: lowercaseFirstLetter<keyof Holding>(typeLabel.sortByKey),
          value: (filterOptions.length + 1).toString(),
          sortDirection: filterDirectionOptions[directionLabel],
          sortDirectionLabel: typeLabel.directionLabels[directionLabel],
          sortType: filterTypeOptions[key],
          sortTypeLabel: typeLabel.typeLabel,
        };
        filterOptions.push(option);
      }
    }
  }

  return filterOptions;
}

createHoldingsLabeledSortOptions['defaultOptionSeed'] = defaultHoldingsSortSeed;

export default createHoldingsLabeledSortOptions;
