import { IComponentOptions } from 'angular';
import { ExperienceLevelsController } from './experience-levels.controller';

let ExperienceLevels: IComponentOptions = {
  controller: ExperienceLevelsController,
  controllerAs: '$ctrl',
  templateUrl: 'axos-trading/experience-levels/experience-levels.tpl.html',
  bindings: {
    investment: '<?',
  },
};

export { ExperienceLevels };
