<div class="order-history">Order History</div>
<table class="table">
  <thead>
    <tr class="table-head-row">
      <th class="description-column">
        Description
      </th>
      <th class="amount-column">
        Amount
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="orders?.length > 0; else noOrders">
      <tr *ngFor="let order of orders" [class]="order.eventDescription">
        <td class="order-column date-column">
          <div *ngIf="order.status.code === orderStatus.ORDER_EXECUTED">
            {{ order.executedDate | date: 'MMMM d, yyyy' }}
          </div>
          <div *ngIf="order.status.code !== orderStatus.ORDER_EXECUTED">
            {{ order.createdDate | date: 'MMMM d, yyyy' }}
          </div>
          <div>
            <div class="order-description">
              {{ order.category.description | titlecase }}: {{ order.symbol }} - {{ order.orderType | titlecase }}
              {{ hasPendingStatus(order) ? getStatusDescription(order) : '' }}
              {{ hasPendingStatus(order) ? '- PENDING' : '' }}
            </div>
          </div>
        </td>
        <td class="order-column amount-column">
          <div *ngIf="order.status.code === orderStatus.ORDER_EXECUTED">
            <div>{{ order.executedPrice | currency: 'USD':'symbol':'2.2-2' }}</div>
          </div>
          <div *ngIf="order.status.code !== orderStatus.ORDER_EXECUTED">
            <div>{{ order.expectedPrice | currency: 'USD':'symbol':'2.2-2' }}</div>
          </div>
        </td>
      </tr>
    </ng-container>
    <ng-template #noOrders>
      <tr>
        <td colspan="5">
          <h3 class="text-center m-t-lg">
            No orders meet your search criteria
          </h3>
        </td>
      </tr>
    </ng-template>
  </tbody>
</table>
<button id="goToAllActivityBtn" type="button" class="link d-flex" (click)="goToAllActivity()">
  <span>View All Activity</span>
  <app-svg-icon class="ml-2" [iconName]="icons.rightIcon" size="1.3em"></app-svg-icon>
</button>
