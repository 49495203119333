import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-send-money-sbb-container',
  templateUrl: './send-money-sbb-container.component.html',
  styleUrls: ['./send-money-sbb-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMoneySbbContainerComponent {
  private readonly OPEN_ACCOUNT_URL = 'https://www.axosbank.com/Personal';

  public onOpenAccount(): void {
    window.open(this.OPEN_ACCOUNT_URL, '_blank', 'noopener noreferrer');
  }
}
