<app-toolbar></app-toolbar>
<banner-component *ngIf="!state.isProxyVersion" placeholder="content-header" class="hero"></banner-component>

<app-auth-tabs
  *ngIf="!state.isProxyVersion"
  [hideLogin]="state.hideLogin | async"
  (login)="goToLogin()"
></app-auth-tabs>

<app-enrollment-lite *ngIf="state.isLite" (routeRedirect)="routeRedirect($event)"></app-enrollment-lite>

<app-enrollment-full *ngIf="state.isFull" (routeRedirect)="routeRedirect($event)"></app-enrollment-full>

<app-proxy-registration *ngIf="state.isProxyVersion" (routeRedirect)="routeRedirect($event)"></app-proxy-registration>

<div class="frow center text-center top-pad mt-3" *ngIf="!state.isProxyVersion">
  <app-chatbot-help-label></app-chatbot-help-label>
</div>
<app-footer></app-footer>
