<p class="mb-4 text-center font-weight-normal col-12">
  Ensure your information is up-to-date for the best experience.
</p>

<form class="change-financial-info" [formGroup]="financialInfoForm" (ngSubmit)="submitFinancialInfo()">
  <div class="form-group">
    <label for="annual-income" class="uk-label">Annual Income</label>
    <select name="annual-income" id="annual-income" class="uk-input" formControlName="annualIncome">
      <option value="">Select Annual Income</option>
      <option [value]="item.key" *ngFor="let item of annualIncomes$ | async">
        {{ item.value }}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="total-net-worth" class="uk-label">Total Net Worth</label>
    <select name="total-net-worth" id="total-net-worth" class="uk-input" formControlName="totalNetWorth">
      <option value="">Select Total Net Worth</option>
      <option [value]="item.key" *ngFor="let item of totalNetWorths$ | async">
        {{ item.value }}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="tax-bracket" class="uk-label">Tax Bracket</label>
    <select name="tax-bracket" id="tax-bracket" class="uk-input" formControlName="taxBracket">
      <option value="">Select Tax Bracket</option>
      <option [value]="item.key" *ngFor="let item of taxBrackets$ | async">
        {{ item.value }}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="liquid-net-worth" class="uk-label">Liquid Net Worth</label>
    <select name="liquid-net-worth" id="liquid-net-worth" class="uk-input" formControlName="liquidNetWorth">
      <option value="">Select Liquid Net Worth</option>
      <option [value]="item.key" *ngFor="let item of liquidNetWorths$ | async">
        {{ item.value }}
      </option>
    </select>
  </div>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="financialInfoForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
