import { ScheduleFrequency } from '@app/axos-trading/enums/schedule-frequency.enum';
import { Freq } from '@legacy/transfers/transfers.controller';

export const SCHEDULED_FREQUENCIES_MAPPING = {
  [Freq.ONETIME]: ScheduleFrequency.OneTimeOnly,
  [Freq.WEEKLY]: ScheduleFrequency.Weekly,
  [Freq.EVERYTWOWEEKS]: ScheduleFrequency.BiWeekly,
  [Freq.MONTHLY]: ScheduleFrequency.Monthly,
  [Freq.QUARTERLY]: ScheduleFrequency.Quarterly,
  [Freq.SEMIANNUALLY]: ScheduleFrequency.SemiAnnually,
  [Freq.ANNUALLY]: ScheduleFrequency.Yearly,
  [Freq.NOTSET]: ScheduleFrequency.None,
};
