import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import NgRedux from 'ng-redux';

import { DirectDepositUserActions } from '@app/click-switch/enums';
import { DirectDepositInterceptAction, Switch } from '@app/click-switch/models';
import { getKeyValueIncomeDetails, getKeyValueSelectedAccounts } from '@app/click-switch/utils';
import { turnOffFunding } from '@app/store/funding/funding.actions';
import { UserProfileViews } from '@app/user-profile/enums';
import { KeyValuePair } from '@core/models';
import { DirectDepositService } from '@core/services';
import { ngRedux, STATE } from '@core/tokens';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch-confirmation-page',
  templateUrl: './click-switch-confirmation-page.component.html',
  styleUrls: ['./click-switch-confirmation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickSwitchConfirmationPageComponent implements OnInit {
  userSwitch: Switch;
  exclamationIcon = AlertsIcons.ExclamationTriangle;
  sourceInformation: KeyValuePair<string, string>[] = [];
  depositInformation: KeyValuePair<string, string>[] = [];

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ngRedux) private ngredux: NgRedux.INgRedux,
    private directDepositService: DirectDepositService,
    private store: Store
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    if (this.state.params.userSwitch) {
      this.userSwitch = this.state.params.userSwitch;

      this.sourceInformation = this.getSubmitTarget(this.userSwitch);
      this.depositInformation = this.getAccounts(this.userSwitch);
    }

    if (!this.userSwitch || !this.userSwitch.index) {
      this.state.go('udb.clickswitch');
    }
  }

  printForm(): void {
    this.directDepositService
      .logUserAction(DirectDepositInterceptAction(DirectDepositUserActions.PrintedForm))
      .subscribe();

    this.directDepositService.getSwitchAsPDF(this.userSwitch.index).subscribe(res => {
      const binary = atob(res.data.replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);

      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      const blob = new Blob([view], { type: 'application/pdf' });
      saveAs(blob, this.userSwitch.name);
    });

    const { isRunning } = this.ngredux.getState().funding;
    if (isRunning) {
      this.ngredux.dispatch(FundingActions.turnOffFunding());
      this.store.dispatch(turnOffFunding());
    }
  }

  goToStatusPage(): void {
    const { isRunning } = this.ngredux.getState().funding;
    if (isRunning) {
      this.ngredux.dispatch(FundingActions.turnOffFunding());
    }

    this.state.go('udb.userProfile', { view: UserProfileViews.DirectDeposit });
  }

  private getSubmitTarget(userSwitch: Switch) {
    return getKeyValueIncomeDetails(userSwitch);
  }

  private getAccounts(userSwitch: Switch) {
    return getKeyValueSelectedAccounts(userSwitch);
  }
}
