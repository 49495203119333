import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { Holding, HoldingTileState, TFilterOptionLabels } from '../core';
import { HoldingFacade } from '../facade';
import { DecimalPipe } from '@angular/common';
import {
  Uk2ButtonSizeEnum,
  Uk2MenuButtonSelectionTypeEnum,
  Uk2Tier1NavigationEnum,
  Uk2Tier1UtilityEnum,
  Uk2ValueMovementTypeEnum,
} from '@axos/uikit-v2-lib';
import {
  AMOUNT_FORMAT,
  DASH_PLACEHOLDER,
  IS_BOTTOM_OVERFLOWING_CLASS,
  MVP_GRID_CLASS,
  MVP_GRID_SELECTOR,
  MVP_MAX_THRESHOLD,
  MVP_MIN_THRESHOLD,
  MVP_PRICE_ROW_SELECTOR,
  MVP_ROW_CLASS,
  MVP_VALUE_ROW_SELECTOR,
  PX,
} from './constants';
import { isUndefined } from 'angular';
import { isNull } from 'lodash';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { Uk2Tier1AlertsEnum } from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-holdings-tile',
  templateUrl: './holdings-tile.component.html',
  styleUrls: ['./holdings-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingsTileComponent implements OnInit, AfterViewChecked {
  @Input() heightScrollArea = 0;
  @ViewChild('contentSection') content: ElementRef<
    HTMLDivElement
  > = new ElementRef(null);

  holdingTileAccountState$: HoldingTileState;
  readonly icon = Uk2Tier1NavigationEnum.arrowsVertical;
  readonly singleSortButton: Uk2MenuButtonSelectionTypeEnum =
    Uk2MenuButtonSelectionTypeEnum.single;
  readonly sortButtonSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.small;
  readonly customPortalCSSProperties$ = this.holdingFacade.portalCSSProperties;
  destroy$ = new Subject<void>();
  isLoading = true;
  defaultText = null;
  public isMvp: boolean = false;

  isRiaHoldingsRealTimeActive = false;
  alertsIcons = Uk2Tier1AlertsEnum;
  showUnderstandingHoldingsModal = false;

  uk2TextTileTitle = 'Holdings Temporarily Unavailable';
  uk2TextTileSvgIconName = Uk2Tier1UtilityEnum.cog;
  uk2TextTileDisplayBackground = false;
  uk2TextTileIsStandaloneTile = false;

  holdingsFailedState$: Observable<boolean>;

  constructor(
    private holdingFacade: HoldingFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.holdingsFailedState$ = this.holdingFacade.holdingTileAccountState$.pipe(
      map(holdingTileAccountState => !!holdingTileAccountState.error)
    );

    this.isRiaHoldingsRealTimeActive = this.featureFlagService.isRiaHoldingsRealTimeActive();
    this.holdingFacade.holdingTileAccountState$
      .pipe(
        filter(state => state !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: state => {
          this.holdingTileAccountState$ = state;
          this.defaultText = state.sortOptions.find(x => x.isSelected)?.text;

          // Notifies the UI that the value has changed and should re-render the html
          this.changeDetectorRef.markForCheck();
        },
      });

    this.checkMvp();
  }

  getUk2ValueMovementTypeEnum(
    percentage: number,
    amount: number
  ): Uk2ValueMovementTypeEnum {
    switch (true) {
      case percentage === 0 && amount === 0:
        return Uk2ValueMovementTypeEnum.none;
      case percentage > 0:
        return Uk2ValueMovementTypeEnum.increasePositive;
      default:
        return Uk2ValueMovementTypeEnum.decreaseNegative;
    }
  }

  getAmountFormat(amount: number | undefined): string {
    if (amount === undefined) return DASH_PLACEHOLDER;
    const result = this.decimalPipe.transform(amount.toFixed(2), AMOUNT_FORMAT);

    return result;
  }

  getPercentage(portfolioPercent: number): number {
    return portfolioPercent * 100;
  }

  toAbsolute(amount: number | undefined | null): number {
    if (isUndefined(amount) || isNull(amount)) {
      return 0;
    }
    return Math.abs(amount);
  }

  ngAfterViewChecked(): void {
    this.setHeightScrollArea();
    this.setFade();
    this.checkMvp();
  }

  selectSortingFilter([$event]: TFilterOptionLabels[]): void {
    this.holdingFacade.selectOption($event);
  }

  onResize(): void {
    this.setHeightScrollArea();
    this.setFade();
    this.checkMvp();
  }

  setHeightScrollArea(): void {
    if (this.heightScrollArea !== 0) {
      this.content.nativeElement.style.height =
        this.heightScrollArea.toString() + PX;
    }
  }

  setFade(): void {
    const el = this.content.nativeElement;
    if (el !== null) {
      const isScrollable = el.scrollHeight > el.clientHeight;
      if (!isScrollable) {
        el.classList.remove(IS_BOTTOM_OVERFLOWING_CLASS);
      } else {
        const isScrolledToBottom =
          el.scrollHeight <= el.clientHeight + el.scrollTop;
        el.classList.toggle(IS_BOTTOM_OVERFLOWING_CLASS, !isScrolledToBottom);
      }
    }
  }

  checkMvp() {
    if (
      window.innerWidth > MVP_MIN_THRESHOLD &&
      window.innerWidth < MVP_MAX_THRESHOLD
    ) {
      this.isMvp = true;
    } else {
      this.isMvp = false;
    }
    this.handleMvpRendering();
  }

  handleMvpRendering() {
    let mvpGrids = Array.from(
      document.getElementsByClassName(MVP_GRID_SELECTOR)
    );
    let mvpPriceRows = Array.from(
      document.getElementsByClassName(MVP_PRICE_ROW_SELECTOR)
    );
    let mvpValueMovementRows = Array.from(
      document.getElementsByClassName(MVP_VALUE_ROW_SELECTOR)
    );
    if (this.isMvp) {
      mvpGrids.forEach((element: Element) => {
        element.classList.add(MVP_GRID_CLASS);
      });
      mvpPriceRows.forEach((element: Element) => {
        element.classList.add(MVP_ROW_CLASS);
      });
      mvpValueMovementRows.forEach((element: Element) => {
        element.classList.add(MVP_ROW_CLASS);
      });
    } else {
      mvpGrids.forEach((element: Element) => {
        element.classList.remove(MVP_GRID_CLASS);
      });
      mvpPriceRows.forEach((element: Element) => {
        element.classList.remove(MVP_ROW_CLASS);
      });
      mvpValueMovementRows.forEach((element: Element) => {
        element.classList.remove(MVP_ROW_CLASS);
      });
    }
  }

  toggleUnderstandingHoldingsModal(): void {
    this.showUnderstandingHoldingsModal = !this.showUnderstandingHoldingsModal;
  }

  getAmount(holding: Holding): number {
    if (this.isRiaHoldingsRealTimeActive) {
      return holding.marketValue;
    } else {
      return holding.classCode === 'C' ? 1 : holding.price;
    }
  }
}
