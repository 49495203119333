import * as angular from 'angular';
// import { accountAggregation } from 'dashboard/account-aggregation/account-aggregation.component';

import { DashboardConfig } from './dashboard.config';
import { DashboardController } from './dashboard.controller';
import { services } from '../services/services';
import { TilesModule } from '../tiles/tiles.module';
import { SignatureCardModule } from '../signature-card/signature-card.module';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { SvgIconComponent } from '@shared/components';
import { FastLinkComponent } from '@app/fast-link/components/fast-link.component';
import { PendoTrackerService } from '@app/Areas/AAS/aas-shared/services/pendo-tracker/pendo-tracker.service';
import {
  AggregationAuthComponent,
  AggregationFlowSuccessComponent,
  AggregationSelectionComponent,
  AggregationSuccessComponent,
  AccountAggregationComponent,
} from '@app/dashboard/components';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
export const DashboardModule = angular
  .module('udb.dashboard', ['ui.router', 'ngAnimate', services, TilesModule, SignatureCardModule])
  .controller('DashboardController', DashboardController)
  .directive('appSvgIcon', downgradeComponent({ component: SvgIconComponent }))
  .directive('appFastLink', downgradeComponent({ component: FastLinkComponent }))
  .directive('appAggregationAuth', downgradeComponent({ component: AggregationAuthComponent }))
  .directive('appAggregationFlowSuccess', downgradeComponent({ component: AggregationFlowSuccessComponent }))
  .directive('appAggregationSelection', downgradeComponent({ component: AggregationSelectionComponent }))
  .directive('appAggregationSuccess', downgradeComponent({ component: AggregationSuccessComponent }))
  .directive('appAccountAggregation', downgradeComponent({ component: AccountAggregationComponent }))
  .service('pendoTracker', downgradeInjectable(PendoTrackerService))
  .service('axosInvestHelper', downgradeInjectable(AxosInvestHelper))
  .config(DashboardConfig).name;
