import { Action, createReducer, on } from '@ngrx/store';

import { loadFinancialCatalogs } from './financial-catalogs.actions';
import { FinancialCatalogsState } from './financial-catalogs.state';

export const initialState: FinancialCatalogsState = null;

const _reducer = createReducer<FinancialCatalogsState>(
  initialState,
  on(loadFinancialCatalogs, (_state, action) => {
    return action.payload;
  })
);

export function financialCatalogsReducer(state: FinancialCatalogsState, action: Action) {
  return _reducer(state, action);
}
