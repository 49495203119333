import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { AuthorizedParties } from '@app/Areas/AAS/features/account-details/core/services/account-people/types/authorized-parties.type';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-authorized-parties',
  templateUrl: './authorized-parties.component.html',
  styleUrls: ['./authorized-parties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizedPartiesComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject<void>();
  authorizedParties: AuthorizedParties;

  constructor(private peopleFacade: PeopleFacade, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.peopleFacade.accountPeopleTabState$
      .pipe(filter(accountPeopleTabState$ => !!accountPeopleTabState$?.accountPeople?.authorizedParties))
      .subscribe(accountPeopleTabState => {
        this.authorizedParties = accountPeopleTabState.accountPeople.authorizedParties;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
