import { createSelector } from '@ngrx/store';

import { Order } from '@app/axos-trading/models';

import { getTradingState } from '../trading.state';

export const getCurrentOrderReference = createSelector(
  getTradingState,
  state => state.ordersState.currentOrderReference
);

export const getCurrentOrder = createSelector(
  getTradingState,
  getCurrentOrderReference,
  (state, currentOrderReference) => {
    if (currentOrderReference === undefined){
      return null;
    }
    else{
      return state.ordersState.orders.find((o: Order) => o.reference === currentOrderReference);
    }
  }
);

export function getPositionsBySymbol(symbol: string) {
  return createSelector(getTradingState, state => state.ordersState.positions.find(p => p.symbol === symbol));
}


