export class ReplacementRequest {
  cardMask: string;
  deliveryAddress: Address;
  dueFee: boolean;
  reason: string;
  comments: string;
  isDefaultAddress: boolean;

  constructor(args?: Partial<ReplacementRequest>) {
    Object.assign(this, args);
  }
}
