import { MilestoneType } from '../../../common/enums/milestoneType.enum';

export interface Milestone {
  /** Milestone unique identifier. */
  id?: string;
  /** Milestone name. */
  name?: string;
  /** Milestone type. */
  type?: MilestoneType;
  /** Current target value of milestone. */
  targetAmount?: number;
  /** User deposit information. */
  autoDeposit?: AutoDeposit;
  /** Milestone current value. */
  currentValue?: number;
  /** Milestone target date value. */
  targetDate?: Date;
  /** Milestone deposits. */
  deposits?: number;
  /** Milestone withdrawals */
  withdrawals?: number;
  /** Milestone dividends. */
  dividends?: number;
  /** Milestone earnings. */
  earnings?: number;
  /** Milestone estimated achievement date. */
  estimatedAchievement?: Date;

  /** Milestone completion percent. */
  completion?: number;
  /** Is milestone closed */
  isClosed?: boolean;
  /** the status of the milestone */
  status?: number;
  /** the accounts of the milestone */
  accounts?: MilestoneAccount[];
}

export class MilestoneAccount {
  annualDepositLimit?: number;
  autoDeposit?: number;
  accountNumber?: string;
  status?: number;
  type?: string;
  name?: string;
  accountValue?: number;
  currentYearContributions?: number;
  previousYearContributions?: number;
  milestoneId: string;
  milestoneName: string;
}

export class MilestoneDetailVm {
  /** Milestones for the user */
  milestones?: Milestone[];

  /** Milestone accounts */
  accounts?: MilestoneAccount[];

  /** Wallet for the user */
  wallet?: Milestone;
  /** Total balance for all milestones for the user */
  totalBalance?: number;
  hasError: boolean;
  /** Is account closed */
  isClosed?: boolean;
}
