import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { AccountDetail, OlbAccount } from '@app/accounts/models';
import { OlbEvents } from '@core/enums';
import { AccountsService, OlbEventService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FilesIcons } from '@shared/enums';
import { AccountNickname } from '@app/models/account-nickname.model';

@Component({
  selector: 'app-loan-account-detail',
  templateUrl: './loan-account-detail.component.html',
  styleUrls: ['./loan-account-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanAccountDetailComponent {
  @Input() account: OlbAccount;
  @Input() details: AccountDetail;
  @Input() hasSignatureCard: boolean;
  @Output() updatedNickname = new EventEmitter<string>();

  dateFormat = 'MM/dd/yyyy';
  editMode = false;
  isSaving = false;
  isMouseDown = false;
  iconName = FilesIcons.Pencil;
  accountNickname: AccountNickname;

  constructor(
    private cd: ChangeDetectorRef,
    private accountsService: AccountsService,
    private serviceHelper: ServiceHelper,
    private olbEventService: OlbEventService
  ) {}

  inputBlur() {
    if (!this.isMouseDown) {
      this.editMode = false;

      this.cd.markForCheck();
    }
  }

  pencilClick() {
    this.editMode = true;

    this.cd.markForCheck();
  }

  updateNickname(value: string) {
    this.isSaving = true;

    const { accountNumber } = this.account;
    const nickname =
      value || `${this.account.name} **${accountNumber.substring(accountNumber.length - 4, accountNumber.length)}`;

    const accountNickname: AccountNickname = { id: +this.account.id, nickname };

    this.accountsService
      .updateNickname(this.account.id, nickname)
      .pipe(
        finalize(() => {
          this.isSaving = false;
          this.editMode = false;

          this.cd.markForCheck();
        })
      )
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: () => this.updatedNickname.emit(nickname),
        complete: () => this.olbEventService.emit(OlbEvents.NickName, accountNickname),
      });
  }
}
