<div class="transaction-main-container">
  <div style="width: 70%;">
    <div class="row">
      <div class="col-sm-12">
        <div class="header">
          <div class="back-button">
            <button class="btn__link" (click)="goBack()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
          <div class="title-section">
            <h3 class="main_title">Transactions</h3>
            <div class="download-button-container">
              <button
                class="download-button-button"
                (click)="downloadTransactionsFile()"
                mat-button
                uk2TextButton
                disableRipple
                [uk2ButtonSize]="uk2DownloadButtonSize"
              >
                <mat-icon class="download-button-icon" [svgIcon]="downloadIcon"></mat-icon>
                <span class="download-button-label">
                  {{ isDownloading ? 'Downloading...' : 'Download Transactions' }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-filters (filtersUpdated)="getTransactions($event)" [hideCategoriesFilter]="true" [isRia]="true"></app-filters>
    <div class="div-table">
      <app-table [transactions]="transactions" [hideCategory]="true" [hideOptions]="false"></app-table>
    </div>
  </div>
</div>
