export const NO_CONTRIBUTION_ACCOUNT_TYPE_CODES = {
  accountType403: '403',
  accountType403R: '403R',
  accountType4PS: '4PS',
  accountTypeOQP: 'OQP',
  accountTypeQP: 'QP',
  accountTypeSEP: 'SEP',
  accountTypeSimpleIRA: 'SM',
  accountTypeSK: 'SK',
  accountTypeSKR: 'SKR',
  accountTypeSUB: 'SUB',
  accountTypeK: 'K',
  accountTypeMPP: 'MPP',
  accountTypePSP: 'PSP',
  accountTypeRoth401: 'Roth 401(k)',
};
