import { NgModule } from '@angular/core';
import { PeopleTabComponent } from './view/people-tab.component';
import { CommonModule } from '@angular/common';
import { TodContingentBeneficiaryModule } from './tod-contingent-beneficiary/tod-contingent-beneficiary.module';
import { TodPrimaryBeneficiaryModule } from './tod-primary-beneficiary/tod-primary-beneficiary.module';
import { PrimaryBeneficiaryModule } from './primary-beneficiary/primary-beneficiary.module';
import { TrustedContactModule } from './trusted-contact/trusted-contact.module';
import { InterestedPartyModule } from './interested-party/interested-party.module';
import { AuthorizedPartiesModule } from './authorized-parties/authorized-parties.module';
import { ContingentBeneficiaryModule } from './contingent-beneficiary/contingent-beneficiary.module';
import { PeopleTabMatModule } from './people-tab-mat.module';
import { PeopleTabUk2Module } from './people-tab-uk2.module';

@NgModule({
  declarations: [PeopleTabComponent],
  imports: [
    AuthorizedPartiesModule,
    CommonModule,
    ContingentBeneficiaryModule,
    InterestedPartyModule,
    PrimaryBeneficiaryModule,
    TodContingentBeneficiaryModule,
    TodPrimaryBeneficiaryModule,
    TrustedContactModule,
    PeopleTabMatModule,
    PeopleTabUk2Module,
  ],
  exports: [PeopleTabComponent],
})
export class PeopleTabModule {}
