import { Inject } from '../../decorators/decorators';

@Inject('$uibModalInstance')
export class LearnMoreModalDelayedController {
  constructor(private _uibModalInstance: ng.ui.bootstrap.IModalServiceInstance) {}

  /** Initializes the controller. */
  public $onInit(): void {}

  public cancel() {
    this._uibModalInstance.close();
  }
}
