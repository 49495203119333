import { of } from 'rxjs';

import { Inject } from 'decorators/decorators';
import { AxosInvestUrlHelper } from 'services/axos-invest-urls.service';

import { Goal, MilestoneSummary } from '@app/axos-invest/models';
import { OlbSettings } from '@core/models';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { FinancialIcons } from '@shared/enums';

@Inject('axosInvestUrlHelper', 'featureFlagService', '$state', 'env')
export class AxosInvestMilestonesController {
  milestonesDetail: MilestoneSummary;
  milestones: Goal[];
  glyph = FinancialIcons.DollarArrowForward;
  isFeatureActive: boolean;

  constructor(
    private readonly axosInvestUrlHelper: AxosInvestUrlHelper,
    private featureFlagService: FeatureFlagService,
    private state: ng.ui.IStateService,
    private env: OlbSettings
  ) {}

  $onInit() {
    this.isFeatureActive = this.featureFlagService.isManagedPortfoliosEnabled();
  }

  $onChanges(changes: any): void {
    if (changes.milestonesDetail.currentValue) {
      this.milestones = changes.milestonesDetail.currentValue.milestones.filter((m: Goal) => !m.isClosed);
    }
  }

  goToGoalSummary(milestoneId: string): void {
    if (this.isFeatureActive) {
      this.state.go('udb.axosinvest', { id: milestoneId });
    } else {
      this.axosInvestUrlHelper.getEditMilestoneUrl(milestoneId).then(url => {
        const win = window.open(url, '_blank');
        win.focus();
      });
    }
  }

  addNewGoal(): void {
    const urlPromise = this.isFeatureActive
      ? of(`${this.env.enrollmentInvestUrl}?products[]=MPO`).toPromise()
      : this.axosInvestUrlHelper.getCreateMilestoneUrl();
    urlPromise.then(url => {
      window.open(url, '_blank', 'noopener noreferrer');
    });
  }

  transferFunds(milestone: Goal): void {
    this.state.go('udb.axosinvest.transferfunds', { id: milestone.id });
  }

  isTargetAvailable(milestone: Goal): boolean {
    return milestone.targetAmount !== null && milestone.targetAmount !== undefined && !!milestone.estimatedAchievement;
  }
}
