export enum OrderTypeDescription {
  Market = 'Market',
  Limit = 'Limit',
}

export enum OrderType {
  Market = 1,
  Limit = 2,
  Stop = 3,
  StopLimit = 4,
  TrailingStop = 5,
}

export const ORDER_TYPE_MAPPING = {
  [OrderTypeDescription.Market]: OrderType.Market,
  [OrderTypeDescription.Limit]: OrderType.Limit,
};
