export enum AccountClassification {
  Undefined = 0,
  Cash = 1,
  Margin = 2,
  DayTrader = 3,
  Dvp = 4,
  Lpma = 5,
  IraRoth = 6,
  Inventory = 7,
  IraSep = 8,
  IraTraditional = 9,
  IraRollOverAccount = 10,
}
