<div class="alert-notification" [ngClass]="alertClass" role="alert" [attr.aria-live]="politeness">
  <div class="alert-notification__message">
    <span *ngIf="!safeHtml">
      <ng-content></ng-content>
    </span>
    <span *ngIf="safeHtml" [innerHtml]="safeHtml"></span>
  </div>
  <app-svg-icon
    *ngIf="dismissable"
    class="alert-notification__close"
    (click)="dismiss.emit()"
    [iconName]="closeIcon"
    iconColor="var(--alert-color)"
    ariaLabel="Close"
    width="12px"
    height="12px"
    role="button"
  ></app-svg-icon>

  <div *ngIf="duration" class="alert-notification__progressbar" [ngStyle]="progressBarStyles"></div>
</div>
