export const TAccountDetailComponent: ng.IComponentOptions = {
  controllerAs: '$tacTile',
  templateUrl: 'tiles/account-overview/t-account-detail/t-account-detail.tpl.html',
  bindings: {
    account: '<',
    enrollmentUrl: '<',
    isEmpty: '<?',
    defaultMessage: '<?',
    isBusinessAccount: '<?',
    hasOpenAccount: '<?',
    action: '&',
    actionDetail: '&',
    isTeen: '<',
    isAasVisibleOpenAccount: '<',
  },
};
