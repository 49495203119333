import { Component, Inject, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { addDays, differenceInDays, format, isAfter } from 'date-fns';

import { AccountDetail, IncomingTransaction, OlbAccount, PaymentInfo } from '@app/accounts/models';
import { loanGracePeriodInDays } from '@app/config/constants';
import { FacingBrand, OlbEvents } from '@core/enums';
import { OlbSettings } from '@core/models';
import { AccountsService, DialogService, HarlandClarkeService, LoanService, OlbEventService } from '@core/services';
import { olbSettings, STATE, WINDOW } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { DialogData } from '@shared/models';

import { PaymentStatus } from '../../enums';

@Component({
  selector: 'app-heloc-account-details-view',
  templateUrl: './heloc-account-details-view.component.html',
  styleUrls: ['./heloc-account-details-view.component.scss'],
})
export class HelocAccountDetailsViewComponent implements OnInit {
  @Input() account: OlbAccount;

  //#region Uninitialised properties
  details: AccountDetail;
  paymentInfo: PaymentInfo;
  //#endregion Uninitialised properties

  //#region Initialised properties
  isLoading = true;
  isPastDue = false;
  showPaymentButton = false;
  loadingChecksUrl = false;
  incomingTransaction: IncomingTransaction = null;
  icons = {
    stopPayment: '',
    orderChecks: '',
  };
  //#endregion Initialised properties

  private currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    @Inject(WINDOW) private window: Window,
    private accountsService: AccountsService,
    private loanService: LoanService,
    private dialogService: DialogService,
    private harlandClarkeService: HarlandClarkeService,
    private serviceHelper: ServiceHelper,
    private olbEventService: OlbEventService,
    @Inject(STATE) private $state: ng.ui.IStateService
  ) {}

  ngOnInit() {
    this.icons = {
      stopPayment: `assets/${this.settings.brand}/img/icons/accounts/stop_payment.svg`,
      orderChecks: `assets/${this.settings.brand}/img/icons/support/account_checks.svg`,
    };
    forkJoin({
      accountDetails: this.accountsService.getAccountDetails(this.account.id),
      pendingPayments: this.loanService.getPendingPayments([this.account.id]),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.olbEventService.emit(OlbEvents.AccountDetailsRetrieved, this.details);
        })
      )
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: ({ accountDetails, pendingPayments }) => {
          this.details = new AccountDetail(accountDetails.data);
          this.incomingTransaction = pendingPayments.shift() || null;

          if (!this.details.accountPayment.dueDate) {
            this.isPastDue = false;
          } else {
            const dueDate = new Date(this.details.accountPayment.dueDate);

            this.isPastDue = isAfter(
              new Date(),
              addDays(dueDate, loanGracePeriodInDays) // <- legacy hardcoded grace period, instead of the JH field.
            );
          }

          this.setPaymentsData();
        },
      });
  }

  goToTransferFunds() {
    const isLate = differenceInDays(Date.now(), this.account.loanDueDate) > 89;
    const isMature = this.account.statusCode === '3';
    const lateMessage =
      'Your loan is more than 90 days past due and is not eligible for online payments.\n\n' +
      'Please call Collection Department at 858-350-6200 ext. 1241.';
    const matureMessage =
      'Your loan is matured and is not eligible for online payments. \n\n' +
      'Please call Loan Servicing at 866-923-7112.';

    if (!isLate && !isMature) {
      sessionStorage.setItem('isPayoff', 'false');
      this.$state.go('udb.transfers.loanPayment', {
        toAccountId: this.account.id,
      });
    } else {
      this.dialogService.open({
        okText: 'Close',
        content: isLate ? lateMessage : matureMessage,
      });
    }
  }

  stopPayments(accountId: number) {
    this.$state.go('udb.accounts.stop-payment', { id: accountId });
  }

  orderCheck(accountId: number) {
    this.loadingChecksUrl = true;
    this.harlandClarkeService
      .encryptAndRedirect(accountId.toString())
      .pipe(
        finalize(() => {
          this.loadingChecksUrl = false;
        })
      )
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: ({ data: url }) => {
          const { facingBrandId, brandName } = this.settings;
          const dialogData = new DialogData({
            content: `<h3>You're continuing to another website</h3>
          <p>You're heading to the check ordering site operated by Harland Clarke,
          ${
            facingBrandId === FacingBrand.Axos ? 'an' : 'a'
          } ${brandName} partner that offers checks and check-related products and services.</p><br>
          <p>Because you're leaving the ${brandName} website, we'd like you to know that the Harland
          Clarke site may have a different privacy policy and level of security.
          Please refer to Harland Clarke's policies for the privacy and security practices for this site</p>`,
            okText: 'Continue',
          });

          this.dialogService
            .open(dialogData, true)
            .afterClosed()
            .subscribe(accepted => {
              if (accepted) {
                this.window.open(url, '_blank', 'noreferrer noopener');
              }
            });
        },
      });
  }

  private setPaymentsData() {
    // Pending Payment
    if (this.incomingTransaction) {
      this.paymentInfo = {
        title: 'Loan Payment Pending',
        account: this.account,
        status: PaymentStatus.PaymentConfirmed,
        items: [
          {
            name: 'Payment Amount',
            value: this.currencyFormat(this.incomingTransaction.paymentAmout),
          },
          {
            name: 'Scheduled Date',
            value: this.dateFormat(this.incomingTransaction.scheduledDate),
          },
          {
            name: 'Post Date',
            value: this.dateFormat(this.incomingTransaction.deliveryDate),
          },
          {
            name: 'Payment Account',
            value: this.incomingTransaction.externalBankName,
          },
        ],
      };

      return this.setPaymentButtonFlag(PaymentStatus.PaymentPending);
    }

    // Past Due Payment
    if (!this.incomingTransaction && this.details.totalAmountDue > 0) {
      const keyNameMap = {
        billedPrincipal: 'Billed Principal',
        billedInterest: 'Billed Interest',
        billedEscrow: 'Billed Escrow',
        billedLateCharges: 'Billed Late Charges',
        billedOtherCharges: 'Billed Other Charges',
      };

      this.paymentInfo = {
        title: this.isPastDue ? 'Past Due Loan Payment' : 'Next Loan Payment',
        isPastDue: isAfter(Date.now(), new Date(this.details.accountPayment.dueDate || null)),
        dueDate: this.details.accountPayment.dueDate,
        items: [],
      };

      Object.keys(keyNameMap).forEach(key => {
        if (this.details[key]) {
          this.paymentInfo.items.push({
            name: keyNameMap[key],
            value: this.currencyFormat(this.details[key]),
          });
        }
      });

      this.paymentInfo.items.push({
        name: 'Total Amount Due',
        value: this.currencyFormat(this.details.totalAmountDue),
        bold: true,
      });

      return this.setPaymentButtonFlag(PaymentStatus.NextDuePayment);
    }

    // Confirmed Payment
    if (differenceInDays(Date.now(), new Date(this.details.accountPayment.lastPaymentDate)) <= 3) {
      this.paymentInfo = {
        title: 'Payment Confirmed',
        account: this.account,
        status: PaymentStatus.PaymentConfirmed,
        items: [
          {
            name: 'Payment Date',
            value: this.dateFormat(this.details.accountPayment.lastPaymentDate),
          },
          {
            name: 'Payment Amount',
            value: this.currencyFormat(this.details.accountPayment.lastPaymentAmount),
          },
        ],
      };

      return this.setPaymentButtonFlag(PaymentStatus.PaymentConfirmed);
    }

    // Next Payment
    if (this.details.accountPayment.dueDate) {
      this.paymentInfo = {
        title: 'Next Payment',
        items: [
          {
            name: 'Due Date',
            value: this.dateFormat(this.details.accountPayment.dueDate),
          },
          {
            name: 'Payment Amount',
            value: this.currencyFormat(this.details.accountPayment.amount),
          },
        ],
      };

      return this.setPaymentButtonFlag(PaymentStatus.NextPayment);
    }

    // Default; no payment due
    this.paymentInfo = {
      title: 'No Payment Due at this time',
    };

    return this.setPaymentButtonFlag(PaymentStatus.Default);
  }

  private setPaymentButtonFlag(status: PaymentStatus) {
    this.showPaymentButton =
      (status === PaymentStatus.NextDuePayment || status === PaymentStatus.NextPayment) && this.account.canBeToAccount;
  }

  private currencyFormat(amount: number) {
    return this.currency.format(amount);
  }

  private dateFormat(date: Date = null) {
    return format(new Date(date), 'MM/dd/yyyy');
  }
}
