import { TransferType } from '@app/axos-invest/enums';

export const TRANSFER_FUNDS_CONTENT = {
  [TransferType.OneTimeDeposit]: {
    title: 'Enter your one-time deposit information',
    subtitle: 'One-Time Deposit',
    confirmationTitle: 'Review your one-time deposit information',
    labels: {
      account: 'Source Account',
      amount: 'Deposit Amount',
      frequency: '',
      startDate: 'Deposit Withdrawn On',
      endDate: '',
    },
  },
  [TransferType.RecurrentDeposit]: {
    title: 'Set up your recurring deposit by providing the following information',
    editTitle: 'Edit the following information for your recurring deposit',
    subtitle: 'Set Up Recurring Deposit',
    editSubtitle: 'Edit Recurring Deposit',
    confirmationTitle: 'Review your recurring deposit information',
    labels: {
      account: 'Funding Account',
      amount: 'Deposit Amount',
      frequency: 'Frequency',
      startDate: 'Start Transfer On',
      endDate: 'End On',
    },
  },
  [TransferType.OneTimeWithdrawal]: {
    title: 'Enter your one-time withdrawal information',
    subtitle: 'One-Time Withdrawal',
    confirmationTitle: 'Review your one-time withdrawal information',
    labels: {
      account: 'Destination Account',
      amount: 'Withdrawal Amount',
      frequency: '',
      startDate: 'Amount Withdrawal On',
      endDate: '',
    },
  },
  [TransferType.RecurrentWithdrawal]: {
    title: 'Setup your recurring withdrawal',
    editTitle: 'Edit the following information for your recurring withdrawal',
    subtitle: 'Recurring Withdrawal',
    editSubtitle: 'Edit Recurring Withdrawal',
    confirmationTitle: 'Review your recurring withdrawal information',
    labels: {
      account: 'Destination Account',
      amount: 'Withdrawal Amount',
      frequency: 'Frequency',
      startDate: 'Start Withdrawal On',
      endDate: 'End Date',
    },
  },
  [TransferType.FundingFlow]: {
    title: 'Enter your deposit amount',
    subtitle: 'Funding Information',
    confirmationTitle: 'Review your funding information',
    labels: {
      account: 'Source Account',
      amount: 'Amount',
      frequency: 'Frequency',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
  },
};
