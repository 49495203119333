import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrustedContactComponent } from './view/trusted-contact.component';
import { SharedModule } from '@shared/shared.module';
import { TrustedInterestedModalModule } from '../trusted-interested-modal';
import { TrustedContactMatModule } from './trusted-contact-mat.module';
import { TrustedContactUk2Module } from './trusted-contact-uk2.module';

@NgModule({
  declarations: [TrustedContactComponent],
  exports: [TrustedContactComponent],
  imports: [CommonModule, SharedModule, TrustedContactMatModule, TrustedContactUk2Module, TrustedInterestedModalModule],
})
export class TrustedContactModule {}
