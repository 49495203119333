import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { emailRegex } from '@app/utils';
import { UserRecoveryService } from '@core/services';
import { STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss'],
})
export class ForgotUsernameComponent implements OnInit {
  loading = false;
  submitted = false;
  forgotUserNameForm: UntypedFormGroup;
  iconProps = {
    iconSize: '82px',
    color: 'var(--primary)',
    icon: AlertsIcons.CheckCircle,
  };

  get email() {
    return this.forgotUserNameForm.get('email');
  }

  get isInvalid() {
    const { errors, touched, pristine } = this.email;

    return !!errors && (touched || !pristine);
  }

  get errorMessage() {
    const { errors } = this.email;
    if (errors?.required) return 'Enter email address!';
    else if (errors?.pattern) return 'Enter valid email address!';
    else return '';
  }

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private formBuilder: UntypedFormBuilder,
    private userRecoveryService: UserRecoveryService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit() {
    this.forgotUserNameForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    });
  }

  // TODO: Replace this logic with routers when available
  backToLogin() {
    this.state.go('auth.login');
  }

  /**
   * Send the username to the email and redirect to the confirmation page.
   */
  sendUsername() {
    if (this.forgotUserNameForm.invalid) {
      this.forgotUserNameForm.markAllAsTouched();

      return;
    }
    this.loading = true;
    this.userRecoveryService
      .sendForgotUsername(this.email.value)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.submitted = true;
        },
        error: err => this.serviceHelper.errorHandler(err),
      });
  }

  backToMail() {
    this.email.reset();
    this.submitted = false;
  }
}
