import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import {
  MatCheckboxDefaultOptions,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from '@angular/material/checkbox';
import { Observable, Subject } from 'rxjs';

import { SubSink } from '@axos/subsink';
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  isValid,
  parseISO,
} from 'date-fns';

import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { olbSettings, STATE } from '@core/tokens';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { CategoryName } from '@legacy/tiles/account-overview/typings/CategoryName.enum';
import { AggregatedAccount } from '@legacy/typings/app/account-aggregation';
import { AlertsIcons, NavigationIcons, UtilityIcons } from '@shared/enums';
import { ExternalBankProvider } from '@shared/models';

import { BalanceOverride } from './models/balance-override.model';
import {
  BrandingSettingsFacade,
  BrandingStateType,
} from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { takeUntil } from 'rxjs/operators';
import { AAS } from '../../constants/account.constants';

export type AccountGroup = { key: string; value: AccountOverview };

@Component({
  selector: 'app-accounts-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './accounts-filter.component.html',
  styleUrls: ['./accounts-filter.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class AccountsFilterComponent implements OnChanges, OnInit, OnDestroy {
  @Input() filter$: Observable<Set<string>>;
  @Input() accounts: AccountOverview[];
  @Input() providers: ExternalBankProvider[];

  // The balances provided here take precedence over the ones returned by
  //   the accounts endpoint
  // This is needed for example in the Net Worth page, where Yodlee uses
  //   the 'cash' instead of the 'balance' to calculate investment accounts
  //   so this is a workaround to show the same amount that Yodlee shows
  // TODO: remove this once the accounts endpoint returns the 'cash' property
  //   or when/if Yodlee uses 'balance' instead of 'cash'
  @Input() balanceOverrides: {
    [accountGlobalId: string]: BalanceOverride;
  } = {};

  @Output() changeFilter = new EventEmitter<Set<string>>();
  @Output() resetFilter = new EventEmitter();

  filter = new Set<string>();
  accountGroups: AccountGroup[][];
  expandedGroups: Set<CategoryName> = new Set();
  subsink = new SubSink();
  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  destroy$ = new Subject<void>();
  icons = {
    ChevronUp: NavigationIcons.ChevronUp,
    ChevronDown: NavigationIcons.ChevronDown,
    Plus: UtilityIcons.Plus,
    InfoCircle: AlertsIcons.InfoCircle,
  };

  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(olbSettings) private readonly env: OlbSettings,
    readonly accAggFormatter: AccountAggregationChartFormatterService,
    private cd: ChangeDetectorRef,
    private brandingSettingsFacade: BrandingSettingsFacade,
    private riaFacade: RiaFacade
  ) {}

  hasImage = (account: AggregatedAccount) =>
    this.accAggFormatter.hasImage(this.providers, account);
  getImage = (account: AggregatedAccount) =>
    this.accAggFormatter.getImage(
      true,
      this.providers,
      this.env,
      account,
      this.allBrandingSettings,
      this.allRiaAccounts
    );
  getBankProfile = (account: AggregatedAccount) =>
    this.accAggFormatter.getBankProfile(account.bankName);

  ngOnInit() {
    this.subsink.sink = this.filter$.subscribe(excludedAccounts => {
      this.filter = new Set(excludedAccounts);
      this.cd.markForCheck(); // remove this if async pipe is introduced for this.filter$
    });

    this.brandingSettingsFacade.allBrandingSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe(brandings => {
        this.allBrandingSettings = brandings;
      });

    this.riaFacade.allRias$.pipe(takeUntil(this.destroy$)).subscribe(rias => {
      this.allRiaAccounts = rias;
    });
  }

  ngOnChanges() {
    if (!this.accounts) return;
    this.accounts = this.accounts.filter(
      a => a.categoryName !== CategoryName.Other
    );
    this.accountGroups = this.groupBy(this.accounts, 'categoryName');
    if (!this.expandedGroups.size && this.accounts.length < 10) {
      // if user has few accounts show all groups already expanded
      this.expandedGroups = new Set(
        this.accountGroups.map(grp => grp[0].key as CategoryName)
      );
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  toggleExpandedGroup(group: CategoryName) {
    if (this.expandedGroups.has(group)) this.expandedGroups.delete(group);
    else this.expandedGroups.add(group);
  }

  toggleSelectedAccount(id: string) {
    if (this.filter.has(id)) this.filter.delete(id);
    else this.filter.add(id);

    this.changeFilter.emit(this.filter);
  }

  selectWholeGroup(accountGroup: AccountGroup[]) {
    accountGroup
      .filter(a => this.filter.has(a.value.globalId))
      .forEach(a => this.filter.delete(a.value.globalId));

    this.changeFilter.emit(this.filter);
  }

  deselectWholeGroup(accountGroup: AccountGroup[]) {
    accountGroup
      .filter(a => !this.filter.has(a.value.globalId))
      .forEach(a => this.filter.add(a.value.globalId));

    this.changeFilter.emit(this.filter);
  }

  getSelectedAccountsSize(
    accountGroup: AccountGroup[],
    includeAxosInvestAndClearing = false
  ) {
    return this.accAggFormatter.getSelectedAccountsSize(
      accountGroup.map(grp => grp.value),
      this.filter,
      includeAxosInvestAndClearing
    );
  }

  getAccountsSize(
    accountGroup: AccountGroup[],
    includeAxosInvestAndClearing = false
  ) {
    return this.accAggFormatter.getAccountsSize(
      accountGroup.map(grp => grp.value),
      includeAxosInvestAndClearing
    );
  }

  getAccountName(account: AccountOverview) {
    if (!account) return '';

    let accountType = account.globalId.includes(AAS)
      ? account.nickname
      : account.accountType;
    if (accountType.length > 17) {
      accountType = accountType.substring(0, 14) + '...';
    }

    const mask =
      account.accountMask && account.accountMask.length >= 4
        ? account.accountMask.substr(account.accountMask.length - 4)
        : '';

    return `${accountType}${mask ? ` - ${mask}` : ''}`;
  }

  getBalance(account: AccountOverview) {
    if (!account) return '';

    if (account.globalId.includes(AAS)) {
      return account.accountBalance;
    }

    return this.balanceOverrides[account.globalId]
      ? this.balanceOverrides[account.globalId].balance
      : account.availableBalance;
  }

  trackByGroupKey(_, accountGroup: AccountGroup[]) {
    if (!accountGroup) return null;

    return accountGroup[0].key;
  }

  trackByGlobalId(_, account: AccountGroup) {
    return account?.value?.globalId;
  }

  goToAddAccount() {
    this.state.go('udb.accounts.account-aggregation', {
      isAccountAggregationFlow: true,
      isPfm3Active: true,
    });
  }

  onReset() {
    this.resetFilter.emit();
  }

  goToRestoreConnection(account: AggregatedAccount) {
    this.state.go('udb.dashboard.account-aggregation.auth', {
      bankId: account.providerId,
      updateCredentials: true,
      providerAccountId: account.providerAccountId,
      accountNickName: this.getNickName(account),
    });
  }

  getNickName(account: AggregatedAccount) {
    if (account.nickname) {
      return account.nickname.split('**')[0];
    } else {
      return account.name || account.bankName;
    }
  }

  getTimePeriod(date: string) {
    const givenDate = this.parseLastUpdate(date);
    const today = new Date();
    let period = '';

    const daysDiff = differenceInDays(today, givenDate);
    const weekDiff = differenceInWeeks(today, givenDate);
    const monthDiff = differenceInMonths(today, givenDate);
    const yearDiff = differenceInYears(today, givenDate);

    if (yearDiff >= 1)
      period = `${yearDiff} year${yearDiff > 1 ? 's' : ''} ago`;
    if (monthDiff >= 1)
      period = `${monthDiff} month${monthDiff > 1 ? 's' : ''} ago`;
    if (weekDiff >= 1)
      period = `${weekDiff} week${weekDiff > 1 ? 's' : ''} ago`;
    if (daysDiff >= 1) period = `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
    if (daysDiff === 0) period = 'Today';

    return period;
  }

  parseLastUpdate(date: string) {
    const lastUpdateInLocalTimeZone = parseISO(
      date + (date.endsWith('Z') ? '' : 'Z')
    );

    if (!isValid(lastUpdateInLocalTimeZone)) return null;

    return lastUpdateInLocalTimeZone;
  }

  groupBy<T>(arr: T[], by: keyof T): { key: string; value: T }[][] {
    // similar to LINQ GroupBy

    return arr.reduce((storage, value) => {
      const key = value[by].toString();
      let group = storage.find(grp => grp[0].key === key);

      if (!group) {
        group = [];
        storage.push(group);
      }

      group.push({ key, value });

      return storage;
    }, []);
  }
}
