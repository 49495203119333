<div class="dropdown-filter">
  <button
    type="button"
    class="uk-input tx-filter-dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [disabled]="isDisabled"
    (click)="toggleDropdown()"
  >
    {{ selectedOption?.label }}
  </button>
  <ul
    class="dropdown-menu dropdown-list"
    [ngClass]="{ visible: dropdownVisible }"
    role="menu"
    aria-labelledby="more options"
  >
    <li *ngFor="let option of options" role="menuitem">
      <a class="link" (click)="selectOption(option)">
        {{ option.label }}
      </a>
    </li>
  </ul>
</div>
