import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

import { AuthorizationsStateType } from '../types';

const getAuthorizationsState = createFeatureSelector<AuthorizationsStateType>(AasStoreFeatures.AccountAuthorizations);

const selectedAccountAuthorizations = createSelector(getAuthorizationsState, state => {
  if (!state.selectedAccountAuthorizations) {
    return undefined;
  }

  return state.authorizationsTabState.find(
    authorizationsTabState => authorizationsTabState.accountNumber === state.selectedAccountAuthorizations
  );
});

export const accountsSelectors = {
  selectedAccountAuthorizations,
};
