import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';

import { ParentPage } from '@app/pfm/enums/parent-page.enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import {
  resetExcludedAccountsFilter,
  resetUncommittedFilters,
  setExcludedAccountsFilter,
} from '@app/pfm/store/net-worth/net-worth.actions';
import {
  getUncommittedExcludedAccountsFilter,
  getUncommittedFiltersInDefaultState,
  getUncommittedTimePeriodFilter,
} from '@app/pfm/store/net-worth/net-worth.selectors';
import { ROOT_SCOPE } from '@core/tokens';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { NavigationIcons } from '@shared/enums';
import { ExternalBankProvider } from '@shared/models';

import { FiltersModalScreen } from '../../enums/filters-modal-screen.enum';
import { BalanceOverride } from '../accounts-filter/models/balance-override.model';
@Component({
  templateUrl: './filters-modal-net-worth.component.html',
  styleUrls: ['./filters-modal-net-worth.component.scss'],
})
export class FiltersModalNetWorthComponent implements OnInit, OnDestroy {
  readonly FiltersModalScreen = FiltersModalScreen;
  readonly ParentPage = ParentPage;
  readonly icons = {
    ChevronLeft: NavigationIcons.ChevronLeft,
    ChevronRight: NavigationIcons.ChevronRight,
  };

  readonly timePeriodFilter = this.store.select(getUncommittedTimePeriodFilter);
  readonly excludedAccountsFilter = this.store.select(getUncommittedExcludedAccountsFilter);
  readonly uncommittedFiltersInDefaultState = this.store.select(getUncommittedFiltersInDefaultState);
  readonly listeners: (() => void)[] = [];

  currentScreen = FiltersModalScreen.Overview;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly config: {
      accounts: AccountOverview[];
      providers: ExternalBankProvider[];
      accountsFilterBalanceOverrides: { [accountGlobalId: string]: BalanceOverride };
    },
    private readonly store: Store,
    readonly dialogRef: MatDialogRef<FiltersModalNetWorthComponent>,
    readonly accAggFormatter: AccountAggregationChartFormatterService,
    @Inject(ROOT_SCOPE)
    private rootScope: ng.IRootScopeService
  ) {}

  ngOnInit() {
    // close the modal if there is a route change
    this.listeners.push(this.rootScope.$on('$stateChangeStart', () => this.dialogRef.close(null)));
  }

  ngOnDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  goTo(screen: FiltersModalScreen) {
    this.currentScreen = screen;
  }
  goBack() {
    if (this.currentScreen === FiltersModalScreen.Overview) this.dialogRef.close(null);
    else this.currentScreen = FiltersModalScreen.Overview;
  }
  resetAllFilters() {
    this.store.dispatch(resetUncommittedFilters());
  }

  onAccountsFilterReset() {
    this.store.dispatch(resetExcludedAccountsFilter());
  }
  onAccountsFilterChange(filter) {
    this.store.dispatch(setExcludedAccountsFilter({ payload: filter }));
  }
}
