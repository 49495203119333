<div class="wrapper" [class.open]="isOpen" [class.highlight]="isHighlighted" (click)="isOpen ? emitCollapse() : open()">
  <span class="header">
    <ng-content select="[header]"></ng-content>
  </span>

  <app-svg-icon
    [iconName]="icons.ChevronDown"
    [styles]="{ width: '1rem', height: '1rem', fill: '#2F5B88' }"
    class="ml-2"
  ></app-svg-icon>

  <div
    [@slideDown]="isOpen"
    (@slideDown.start)="onAnimationStart($event)"
    (@slideDown.done)="onAnimationEnd($event)"
    (click)="onClickInside($event)"
    class="body"
    style="display: none;"
  >
    <ng-content select="[body]"></ng-content>
  </div>
</div>
