import { BeneficiariesIraEnhHelper } from '@legacy/services/beneficiaries-iraenh.helper';

export function beneficiariesIraEnhHelperFactory($injector: any) {
  return $injector.get('beneficiariesIraEnhHelper');
}

export const beneficiariesIraEnhHelperProvider = {
  provide: BeneficiariesIraEnhHelper,
  useFactory: beneficiariesIraEnhHelperFactory,
  deps: ['$injector'],
};
