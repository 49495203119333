import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Uk2ValueMovementTypeEnum } from '@axos/uikit-v2-lib';
import { differenceInMonths } from 'date-fns';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { TotalValueIntervalPeriodDateState, TotalValueIntervalPeriodState, TotalValueTileState } from '../../core';
import { Delta } from '../../core/store/enums';
import { TotalValueFacade } from '../../facade/total-value.facade';
import { TIME_PERIOD_CHANGE_TICKER } from './constants';
import { FilterDates } from './enums';
import { TotalValueFilter, TOTAL_VALUE_FILTERS_CONFIGURATION } from './total-value-graph';

@Component({
  selector: 'app-total-value-tile',
  templateUrl: './total-value-tile.component.html',
  styleUrls: ['./total-value-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalValueTileComponent implements OnInit, OnDestroy {
  isLoading = true;
  totalValueTileAccountState$: TotalValueTileState;
  totalValueIntervalPeriodDateState: TotalValueIntervalPeriodDateState[];
  filters: TotalValueFilter[] = TOTAL_VALUE_FILTERS_CONFIGURATION;
  activeFilter: TotalValueFilter;
  isMarketOpened: boolean;
  differenceAllTime: number;
  percentageAllTime: number;
  differenceAllTimeFromGraph: number;
  percentageAllTimeFromGraph: number;
  displayColor: Delta;
  timePeriodChangeTicker: string;
  filterDatesEnum = FilterDates;
  firstDepositLessThan3Months: boolean;
  displayTotalValueFromGraph = false;
  totalValueFromGraph: number;
  totalValueIntervalFirstData: number;
  firstDepositDate: Date;
  private destroy$ = new Subject<void>();
  public getAbsValue = Math.abs;

  constructor(private totalValueFacade: TotalValueFacade, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.totalValueFacade.totalValueTileAccountState$
      .pipe(
        filter(state => state !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(totalValue => {
        this.totalValueTileAccountState$ = totalValue;
        this.isLoading = false;
        this.getInitialValue();
        this.cd.markForCheck();
      });

    this.totalValueFacade.isLoading$.pipe(takeUntil(this.destroy$)).subscribe({
      next: isLoading => {
        this.isLoading = isLoading;
        this.cd.markForCheck();
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();

    this.destroy$.complete();
  }

  changeGraph(value: string) {
    if (this.totalValueTileAccountState$.hasError) value = FilterDates.M3;
    this.activeFilter = this.filters.find(x => x.label === value);
    this.timePeriodChangeTicker = this.getDescriptionTimePeriodAll();
    const interval = this.totalValueTileAccountState$.interval[this.activeFilter.label];
    this.totalValueIntervalPeriodDateState = [...interval.list];
    this.percentageAllTime = interval.percentageAllTime;
    this.differenceAllTime = interval.differenceAllTime;
    this.displayColor = interval.delta;
    this.validateFirstDepositChangeGraph();
    this.cd.markForCheck();
    this.cd.detectChanges();
    if (this.activeFilter.label === FilterDates.All) {
      this.getFirstDepositForAllTime(interval);
    } else {
      this.getInitialValueGraph(interval);
    }
  }

  getInitialValue() {
    this.activeFilter = this.filters[0];
    this.timePeriodChangeTicker = TIME_PERIOD_CHANGE_TICKER.P3M;
    const interval = this.totalValueTileAccountState$.interval[this.activeFilter.label];
    this.totalValueIntervalPeriodDateState = [...interval.list];
    this.percentageAllTime = interval.percentageAllTime;
    this.differenceAllTime = interval.differenceAllTime;
    this.displayColor = interval.delta;
    const allTimeInterval = this.totalValueTileAccountState$.interval[FilterDates.All];
    this.getFirstDeposit([...allTimeInterval.list]);
    this.cd.markForCheck();
    this.getInitialValueGraph(interval);
  }

  getUk2ValueMovementTypeEnum(value: number): Uk2ValueMovementTypeEnum {
    switch (true) {
      case value === undefined:
        return Uk2ValueMovementTypeEnum.increasePositive;
      case value === 0:
        return Uk2ValueMovementTypeEnum.none;
      case value > 0:
        return Uk2ValueMovementTypeEnum.increasePositive;
      default:
        return Uk2ValueMovementTypeEnum.decreaseNegative;
    }
  }

  getDescriptionToday(value: number) {
    switch (true) {
      case value === undefined:
        return 'Today';
      case value !== undefined && this.isMarketOpened:
        return 'Today';
      case value !== undefined && !this.isMarketOpened:
        return 'Previous Close';
    }
  }

  getDescriptionTimePeriodAll() {
    switch (this.activeFilter.label) {
      case FilterDates.M3:
        return TIME_PERIOD_CHANGE_TICKER.P3M;
      case FilterDates.M6:
        return TIME_PERIOD_CHANGE_TICKER.P6M;
      case FilterDates.YTD:
        return TIME_PERIOD_CHANGE_TICKER.YTD;
      case FilterDates.Y1:
        return TIME_PERIOD_CHANGE_TICKER.PY;
      case FilterDates.Y3:
        return TIME_PERIOD_CHANGE_TICKER.PY3;
      case FilterDates.Y5:
        return TIME_PERIOD_CHANGE_TICKER.PY5;
      case FilterDates.All:
        return TIME_PERIOD_CHANGE_TICKER.All;
    }
  }

  displayTotalValueFromTile(dataFromGraph: any) {
    this.totalValueFromGraph = dataFromGraph.value;
    this.displayTotalValueFromGraph = dataFromGraph.hover;
    if (dataFromGraph.value === null) return;
    const change = dataFromGraph.value - this.totalValueIntervalFirstData;
    this.differenceAllTimeFromGraph = this.totalValueIntervalFirstData - dataFromGraph.value;
    if (+dataFromGraph.value === 0 && this.totalValueIntervalFirstData === 0) {
      this.percentageAllTimeFromGraph = 0;
      return;
    }
    if (
      this.totalValueIntervalFirstData === 0 ||
      (this.activeFilter.label === FilterDates.All &&
        new Date(dataFromGraph.date).toDateString() === new Date(this.firstDepositDate).toDateString())
    ) {
      this.percentageAllTimeFromGraph = 100;
      return;
    }
    this.percentageAllTimeFromGraph = change / Math.abs(this.totalValueIntervalFirstData);
  }

  private getFirstDeposit(totalValueIntervalSeries: TotalValueIntervalPeriodDateState[]) {
    if (
      totalValueIntervalSeries.length === 0 ||
      (totalValueIntervalSeries.length === 1 && totalValueIntervalSeries[0].value === 0)
    )
      return;
    const depositDates = totalValueIntervalSeries.filter(x => x.value !== 0);

    if (depositDates.length === 0) {
      this.firstDepositLessThan3Months = true;
      return;
    }
    const firstDeposit = depositDates[depositDates.length - 1]?.date;
    const differenceOfMonths = differenceInMonths(new Date(), new Date(firstDeposit));
    this.firstDepositLessThan3Months = differenceOfMonths < 3;
  }

  private getInitialValueGraph(interval: TotalValueIntervalPeriodState) {
    this.totalValueIntervalFirstData = interval.list[interval.list.length - 1].value;
  }

  private getFirstDepositForAllTime(interval: TotalValueIntervalPeriodState) {
    const differentFromZero = interval.list.filter(x => x.value !== 0);
    this.totalValueIntervalFirstData = differentFromZero[differentFromZero.length - 1].value;
    this.firstDepositDate = differentFromZero[differentFromZero.length - 1].date;
  }

  private validateFirstDepositChangeGraph() {
    if (
      (this.activeFilter.label === FilterDates.YTD && this.firstDepositLessThan3Months) ||
      (this.activeFilter.label === FilterDates.All && this.firstDepositLessThan3Months)
    ) {
      const interval = this.totalValueTileAccountState$.interval[FilterDates.M3];
      this.totalValueIntervalPeriodDateState = [...interval.list];
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }
}
