import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { supportDepositsComponents } from './components';

@NgModule({
  declarations: [...supportDepositsComponents],
  exports: [...supportDepositsComponents],
  imports: [CommonModule],
})
export class SupportDepositsModule {}
