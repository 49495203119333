import { createSelector } from '@ngrx/store';

import { CategoryName } from '@legacy/tiles/account-overview/typings/CategoryName.enum';

import { getPfmState } from '../pfm.state';
export const getProviders = createSelector(getPfmState, state => state.accountsState.providers);
export const getAccounts = createSelector(getPfmState, state => state.accountsState.accounts);
export const getAccountsExcludingOthers = createSelector(getAccounts, accounts =>
  accounts?.filter(a => a.categoryName !== CategoryName.Other)
);
export const getAccountsLoading = createSelector(getPfmState, state => state.accountsState.accountsLoading);
export const getOverallLoading = createSelector(
  getPfmState,
  state => state.accountsState.providersLoading || state.accountsState.accountsLoading
);
