import { ModelsTileState } from './models-tile-state.type';

export type ModelsState = {
  accountsModels: ModelsTileState[];
  selectedAccountModels: string | undefined;
};

export const initialModelState: ModelsState = {
  accountsModels: [],
  selectedAccountModels: undefined,
};
