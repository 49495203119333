import { Inject } from '../../../decorators/decorators';

@Inject('$stateProvider')
export class ReviewTransferConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.transfers.wireTransfers.reviewTransfer', {
      url: '/review-transfer',
      params: { wiretransfer: null },
      views: {
        'wire-content': {
          templateUrl: 'transfers/wire-transfers/review-transfer/review-transfer.tpl.html',
          controller: 'ReviewTransferController',
          controllerAs: '$rt',
        },
      },
    });
  }
}
