export enum SwitchActions {
  Submit,
  Cancel,
  Complete,
  PrintForMailing,
  Print,
  Uncancel,
  StartProcessing,
  Reassign,
  MailReturned,
  Reject,
}
