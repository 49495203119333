import { IStateParamsService, IStateService } from 'angular-ui-router';
import { FeatureFlagService } from 'services/feature-flag.service';

import { Inject } from '../decorators/decorators';
import { AccountAggregationService } from './../services/account-aggregation.service';
import { DashboardController } from './dashboard.controller';

@Inject('$stateProvider')
export class DashboardConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.dashboard', {
        url: '/Dashboard?teaserTemplate',
        views: {
          content: {
            templateUrl: 'dashboard/dashboard.tpl.html',
            controller: DashboardController,
            controllerAs: 'vm',
          },
        },
        params: {
          teaserTemplate: { squash: true, value: null },
        },
      })
      .state('udb.dashboard.account-aggregation', {
        url: '/AccountAggregation?flow',
        views: {
          'dashboard-content': {
            template: `
            <div class="account-aggregation frow center">
              <div class="fcol-cs-12">
                <div ui-view="aggregation-content">
                  <app-account-aggregation>
                  </app-account-aggregation>
                </div>
              </div>
            </div>
            `,
          },
        },
        resolve: {
          activeAccountAggregation: [
            'featureFlagService',
            '$state',
            (featureFlagService: FeatureFlagService, state: IStateService): any => {
              !featureFlagService.isAccountAggregationActive() && state.go('udb.dashboard');
            },
          ],
        },
        params: {
          isAccountAggregationFlow: false,
          isPfm3Active: false,
          isMoveMoneyFlow: false,
          institution: null,
          accountSelected: {},
          accountId: 0,
          container: null,
        },
      })
      .state('udb.dashboard.account-aggregation.auth', {
        url: '/:bankId/auth?updateCredentials',
        params: {
          institution: null,
          isAccountAggregationFlow: false,
          providerAccountId: 0,
          container: null,
          accountId: 0,
          accountNickName: null,
          onCompleteRedirectAction: null,
          onBackRedirectAction: null,
          isMoveMoneyFlow: false,
          accountSelected: {},
          flow: '',
          flowFromChecks: '',
        },
        views: {
          'aggregation-content': {
            template: '<app-aggregation-auth></app-aggregation-auth>',
          },
        },
      })
      .state('udb.dashboard.account-aggregation.selection', {
        params: {
          accounts: null,
          institution: null,
          providerAccountId: null,
          newPayverisAccounts: null,
          isAccountAggregationFlow: false,
        },
        views: {
          'aggregation-content': {
            template: '<app-aggregation-selection></app-aggregation-selection>',
          },
        },
        resolve: {
          accounts: [
            '$stateParams',
            (stateParams: any) => {
              return stateParams['accounts'];
            },
          ],
        },
      })
      .state('udb.dashboard.account-aggregation.auth-success', {
        url: '/:bankId/success',
        params: {
          accounts: null,
          newPayverisAccounts: null,
          institution: null,
          providerAccountId: null,
          isAccountAggregationFlow: false,
          isMoveMoneyFlow: false,
        },
        views: {
          'aggregation-content': {
            template: '<app-aggregation-success></app-aggregation-success>',
          },
        },
        resolve: {
          accounts: [
            '$stateParams',
            'accountAggregationService',
            (stateParams: IStateParamsService, accountAggregationService: AccountAggregationService) => {
              return (
                stateParams['accounts'] ||
                accountAggregationService.getAccounts().then(res => {
                  return res.data;
                })
              );
            },
          ],
        },
      })
      .state('udb.dashboard.account-aggregation.auth-success-flow', {
        params: {
          accounts: null,
          institution: null,
          providerAccountId: null,
          isAccountAggregationFlow: false,
        },
        views: {
          'aggregation-content': {
            template: '<app-aggregation-flow-success></app-aggregation-flow-success>',
          },
        },
        resolve: {
          accounts: [
            '$stateParams',
            'accountAggregationService',
            (stateParams: IStateParamsService, accountAggregationService: AccountAggregationService) => {
              return (
                stateParams['accounts'] ||
                accountAggregationService.getAccounts().then(res => {
                  return res.data;
                })
              );
            },
          ],
        },
      })
      .state('udb.dashboard.account-aggregation.update-success', {
        url: '/updateSuccess',
        views: {
          'aggregation-content': {
            template: `
              <div class="aggregation-success">
	              <div class="header-section-title text-center frow">
		              <h1 class="fcol-cs-12">You've reconnected</h1>
		              <p class="fcol-cs-12 ">
			              You have successfully updated your external account for tracking and reporting.</p>
                  <button type="button" class="uk-btn lg solid secondary" ui-sref="udb.accounts.dashboard">
                    Return to Accounts</button>
                </div>
              </div>`,
          },
        },
      });
  }
}
