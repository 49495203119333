import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { Filters, Transaction } from '@app/axos-invest/models';
import { AxosInvestService } from '@app/axos-invest/services';
import { AxosInvestUrlHelper } from '@legacy/services/axos-invest-urls.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  transactions: Transaction[];
  isFeatureActive: boolean;
  isLoading = true;

  constructor(
    private featureFlagService: FeatureFlagService,
    private axosInvestUrlHelper: AxosInvestUrlHelper,
    private axosInvestService: AxosInvestService
  ) {}

  ngOnInit(): void {
    this.isFeatureActive = this.featureFlagService.isManagedPortfoliosEnabled();
    if (this.isFeatureActive) this.getTransactions();
  }

  getTransactions(filters?: Filters) {
    this.isLoading = true;
    if (!filters || !filters.datePeriod) {
      filters = filters ? filters : {};
      filters.datePeriod = 90;
    }
    this.axosInvestService
      .getTransactions(filters)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(response => {
        this.transactions = response.data;
      });
  }

  redirectToAxosInvest() {
    this.axosInvestUrlHelper.getTransactionsUrl().then(url => {
      window.open(url, '_blank', 'noopener noreferrer');
    });
  }
}
