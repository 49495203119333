import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { Observable, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { InterestedParties } from '@app/Areas/AAS/features/account-details/core';
import { BLANK_FORMAT, DATE_FORMAT, ALLOWED_ACCOUNTS_TYPES } from '@app/Areas/AAS/features/account-details/view';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';

@Component({
  selector: 'app-tod-primary-beneficiary',
  templateUrl: './tod-primary-beneficiary.component.html',
  styleUrls: ['./tod-primary-beneficiary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodPrimaryBeneficiaryComponent implements OnInit {
  todPrimaryBeneficiaries$: Observable<Array<InterestedParties>>;

  blankFormat = BLANK_FORMAT;
  dateFormat = DATE_FORMAT;

  constructor(private peopleFacade: PeopleFacade, private pdpFacade: PdpFacade) {}

  ngOnInit(): void {
    this.todPrimaryBeneficiaries$ = combineLatest([
      this.peopleFacade.accountPeopleTabState$,
      this.pdpFacade.selectedAasAccount$,
    ]).pipe(
      filter(
        ([accountPeopleTabState, selectedAasAccount]) =>
          !!accountPeopleTabState?.accountPeople?.todPrimaryBeneficiaries && !!selectedAasAccount
      ),
      filter(([, selectedAasAccount]) =>
        ALLOWED_ACCOUNTS_TYPES.some(allowedAccountsType => allowedAccountsType === selectedAasAccount.type)
      ),
      map(([accountPeopleTabState]) => accountPeopleTabState.accountPeople.todPrimaryBeneficiaries),
      map(todPrimaryBeneficiaries => todPrimaryBeneficiaries.sort((a, b) => a.name.localeCompare(b.name))),
      map(todPrimaryBeneficiaries => todPrimaryBeneficiaries.sort((a, b) => b.ipPercent - a.ipPercent))
    );
  }
}
