<div class="recurring-deposit">
  <h3 class="recurring-deposit-title">Recurring Deposit</h3>
  <div class="d-inline-flex">
    <ul class="ul-style">
      <li *ngFor="let item of recurringDesposits">
        <span class="recurring-deposit-details">
          {{ item.amount | currency }} {{ item.frequencyDescription }}
          <ng-container *ngIf="recurringDesposits.length > 1">
            ({{ item.accountName }})
          </ng-container>
        </span>
        <div class="edit-button">
          <button
            class="edit-label-btn"
            id="editRecurringDeposit"
            type="button"
            (click)="editRecurringDeposit(item.transferSeriesId)"
          >
            <app-svg-icon
              class="edit-label-btn-icon"
              [iconName]="icons.edit"
              width="1.15em"
              height="1.15em"
              iconColor="#1c7fdd"
            ></app-svg-icon>
            <span class="edit-deposit-label">Edit Deposit</span>
          </button>
        </div>
      </li>
    </ul>
  </div>
</div>
