<table class="table">
    <thead>
        <tr class="table-head-row">
            <th class="symbol-column" (click)="sortBy('symbol')">
                Symbol
                <i
                    class="fa"
                    *ngIf="lastSortedColumn === 'symbol'"
                    [class.fa-caret-down]="!isAscendent"
                    [class.fa-caret-up]="isAscendent"
                ></i>
            </th>
            <th class="security-name-column" (click)="sortBy('companyName')">
                Security Name
                <i
                    class="fa"
                    *ngIf="lastSortedColumn === 'companyName'"
                    [class.fa-caret-down]="!isAscendent"
                    [class.fa-caret-up]="isAscendent"
                ></i>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr 
            app-research-row
            *ngFor="let equity of equities"
            [accountId]="accountId"
            [equity]="equity"
        ></tr>
    </tbody>
</table>