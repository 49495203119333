import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

import { Frequency, SendUntil, TransactionType } from '@app/axos-invest/enums';
import { CompletedTransfer } from '@app/axos-invest/models';
import { STATE } from '@core/tokens';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletedComponent implements OnInit {
  @Input() completedTransfer: Partial<CompletedTransfer>;

  isScheduledTransfer: boolean;
  sendUntil = SendUntil;
  frequencies = Frequency;
  title = 'Transfer Submitted';
  icon = 'assets/img/icons/axos-invest/transfer-completed.svg';
  dateFormat = 'MM/dd/yy';
  transactionTypes = TransactionType;

  constructor(@Inject(STATE) private state: ng.ui.IStateService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  goToSummary() {
    this.state.go('udb.axosinvest', { id: this.completedTransfer.milestoneId });
  }
}
