import { createFeatureSelector, createSelector } from '@ngrx/store';

import { HasAxosAccounts } from '@shared/models/hasAxosAccounts.model';
import { RIAPilotFlagState } from '@shared/models/ria-pilot-state';
import { IsRiaUserState } from '@shared/models/ria-user-state.model';

export const selectRIAUser = createFeatureSelector<IsRiaUserState>('IsRIAUser');
export const selectRIAUserFlag = createSelector(selectRIAUser, state => state.isRIAUser);
export const selectRIAUserLoading = createSelector(selectRIAUser, state => state.isLoading);
export const selectHasAxosAccounts = createFeatureSelector<HasAxosAccounts>('userHasNoAxosAccounts');
export const selectNoAxosAccountsFlag = createSelector(selectHasAxosAccounts, state => state.userHasNoAxosAccounts);
export const selectNoAxosAccountsIsLoading = createSelector(selectHasAxosAccounts, state => state.isLoading);
export const selectRIAPilot = createFeatureSelector<RIAPilotFlagState>('RIAPilotFlag');
export const selectRIAPilotFlag = createSelector(selectRIAPilot, state => state.isRIAPilotActive);
