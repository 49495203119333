<div class="container" *ngIf="$accountSummary | async; let accountSummary; else: loader">
  <div class="row mt-4">
    <app-historic-overview class="d-block col-12" [summary]="accountSummary"></app-historic-overview>
  </div>
  <div class="row mt-4">
    <p class="overview-title">Goals</p>
    <button type="button" class="link btn-add-goal" (click)="addNewGoal()">
      <app-svg-icon [iconName]="iconPlusPrimary" size="1rem"></app-svg-icon>
      Add Goal
    </button>
  </div>
  <div class="mt-2 mb-2">
    <app-goal-card class="row" [milestoneSummary]="accountSummary"></app-goal-card>
  </div>
  <div class="row mt-4">
    <p class="overview-title">Features</p>
  </div>
  <div class="row mt-2 align-items-stretch">
    <app-wallet
      class="feature-tile col-xs-12 col-sm-12 mt-2 d-flex justify-content-center"
      *ngIf="accountSummary.wallet"
      [wallet]="accountSummary.wallet"
    ></app-wallet>
    <app-manage-account
      class="feature-tile col-xs-12 col-sm-12 mt-2 d-flex justify-content-center"
    ></app-manage-account>
  </div>
</div>
<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
