<div class="confirmation">
  <div class="confirmation-header">
    <div class="confirmation-city-left">
      <img [src]="leftCity" alt="city background image" />
    </div>
    <div class="confirmation-message">
      <img [src]="messageImage" alt="message sent image" />

      <h3>Your Secure Message has been sent!</h3>
    </div>
    <div class="confirmation-city-right">
      <img [src]="rightCity" alt="city background image" />
    </div>
  </div>
  <div class="confirmation-footer">
    <div>
      It’s our goal to get back to you within two business days, but in times of high volume it may take up to four
      business days for us to reply.
      <br />
      <br />
      When we do reply, you will receive an email notification at
      <b>{{ userEmail }}</b>
      informing you that you have a Secure Message waiting for you. When you get the notification, log in to your
      <label class="brand-label">{{ userBrand }}</label>
      account to retrieve the Secure Message.
      <div class="pt-2">
        <ul>
          <li>Your Team @{{ brandTeamName }}.</li>
        </ul>
      </div>
      <div class="center-align pt-2">
        <a id="back-to-support-link" href="#" (click)="backToSupport()">
          Return to Support
        </a>
      </div>
    </div>
  </div>
</div>
