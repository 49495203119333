<div class="d-none d-sm-block upload-header" [style.backgroundImage]="backgroundUrl">
  <h2 class="upload-header__title">Upload a Form</h2>
</div>

<div class="upload-content" mat-dialog-content>
  <form #submitDocumentForm="ngForm" name="form" (ngSubmit)="submitFormDocument()">
    <p class="upload-content__instructions">
      Select the type of the document you are uploading and add an optional description or message. Document type
    </p>

    <div class="upload-content__form-group">
      <label for="documentType">Document type</label>
      <select
        id="documentType"
        class="cs-select cs-skin-blue border-rounded"
        name="documentType"
        #documentType="ngModel"
        [(ngModel)]="documentModel"
      >
        <optgroup [label]="section.label" *ngFor="let section of data.sections">
          <option
            *ngFor="let document of data.library[section.key]"
            [ngValue]="document"
            [innerText]="document.displayName"
          ></option>
        </optgroup>
      </select>
    </div>

    <div class="upload-content__form-group">
      <label for="description">Description (Optional)</label>
      <textarea
        id="description"
        rows="4"
        cols="50"
        class="form-control border-rounded"
        name="description"
        #description="ngModel"
        [(ngModel)]="descriptionModel"
      ></textarea>
    </div>
    <div class="upload-content__progress-bar" *ngIf="uploading">
      <div
        class="upload-content__progress-bar-fill"
        [ngStyle]="{
          width: progress + '%'
        }"
      ></div>
    </div>
    <div mat-dialog-actions class="upload-content__actions">
      <button type="submit" class="btn-ok button--sm mb-3" [disabled]="!documentModel || uploading" cdkFocusInitial>
        Submit
      </button>
      <button type="button" class="btn-cancel button--sm mb-3" (click)="closeModal()">
        Cancel
      </button>
    </div>

    <p class="upload-content__instructions">
      Uploading this form will automatically create a new case. You'll be able to track the case's progress in the
      message center.
    </p>
  </form>
</div>
