export class IncomingTransaction {
  /** Name of the bank origin. */
  externalBankName: string;
  /** Amount of money for the transaction. */
  paymentAmout: number;
  /** Specifies when is estimated the arrival of the transaction. */
  deliveryDate?: Date;
  /** Date when the payment was initiated */
  scheduledDate?: Date;

  constructor(args: Partial<IncomingTransaction>) {
    args.deliveryDate = args.deliveryDate ? new Date(args.deliveryDate) : undefined;
    args.scheduledDate = args.scheduledDate ? new Date(args.scheduledDate) : undefined;

    Object.assign(this, args);
  }
}
