import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SendMoneyBusinessModalComponent } from '@app/business/modals';
import { RedirectToIpayModalComponent } from '@app/business/modals/redirect-to-ipay-modal/redirect-to-ipay-modal.component';
import { IPayService } from '@core/services/ipay.service';
import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { IPaySSOResult } from '@legacy/typings/app/IPaySSOResult';
import { solePropHelpers } from '@legacy/shared/helpers'

@Component({
  selector: 'app-sbb-tile',
  templateUrl: './sbb-tile.component.html',
  styleUrls: ['./sbb-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbbTileComponent implements OnInit {
  userProfiles: AccountProfile[] = [];
  businessId: number;
  soleBusinessData: solePropHelpers.SolePropData;

  constructor(
    private matDialog: MatDialog,
    private iPayService: IPayService,
    private serviceHelper: ServiceHelper,
    private cookieHelper: CookieHelper,
    private cachedAccountsService: CachedAccountsService
  ) {}

  ngOnInit(): void {
    this.userProfiles = JSON.parse(
      sessionStorage.getItem(solePropHelpers.USER_BUSINESSES_KEY)
    ) as AccountProfile[];
  }

  payBillsFromBusinessAccounts(): void {
    const businessProfiles = this.userProfiles?.filter(
      acc => acc.profileType === AccountProfileType.Business
    );

    this.soleBusinessData = solePropHelpers.computeSolePropData(
      this.cookieHelper.getUserCIF(),
      this.cookieHelper.getUserId(),
      this.cachedAccountsService.paymentAccounts ?? [],
      this.userProfiles
    );
    this.businessId = this.soleBusinessData.businessId;

    let entryLength = businessProfiles?.length ?? 0;
    if (this.soleBusinessData.isSoleProp) {
      entryLength++;
    }

    if (entryLength > 1) {
      this.showBusinessModal();
      return;
    }

    this.showConfirmationModal();
  }

  showConfirmationModal(): void {
    this.matDialog.open(RedirectToIpayModalComponent, {
      data: { id: this.businessId, isAccountId: false, isBillPay: true },
    });
  }

  showBusinessModal(): void {
    this.matDialog.open(SendMoneyBusinessModalComponent, {
      data: { isBillPay: true, soleBusinessData: this.soleBusinessData },
    });
  }

  redirectToIPay(): void {
    this.iPayService.getSsoUrlByBusinessId(this.businessId).subscribe({
      next: (resp: OlbResponse<IPaySSOResult>) => {
        // Open iPay in a new tab/window
        window.open(resp.data.ssoUrl, '_blank');
      },
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
    });
  }
}
