import { Inject } from "../decorators/decorators";
import { BaseService } from './base.service';
import { IPropertyBagService } from 'services/typings/IPropertyBagService';


@Inject('$http', 'env', 'serviceHelper', '$q')
export class PropertyBagService extends BaseService implements IPropertyBagService{
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService){
    super(http, env, 'propertybag', _serviceHelper, q);
  }

  cancelResendSpousalConsent(spousalConsentUpdate: SpousalConsentUpdate): ApiResponse<any> {
    return this.put('spousalconsentbytaxplan', spousalConsentUpdate);
  }

  hasPendingSpousalConsent(taxPlanType: number): ApiResponse<any> {
    return this.get(`spousalconsent/pending/${taxPlanType}`);
  }

}


