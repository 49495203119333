import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NavigationIcons } from '@shared/enums';
@Component({
  selector: 'app-funding-header',
  templateUrl: './funding-header.component.html',
  styleUrls: ['./funding-header.component.scss'],
})
export class FundingHeaderComponent {
  @Input() headerTitle: string;
  @Input() subtitle: string;
  @Input() title: string;
  @Input() showSubtitle = true;
  @Input() showBack = true;
  @Output() back = new EventEmitter<void>();
  backIcon = NavigationIcons.ArrowBack;
  constructor() {}
}
