import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

// * Use this module to export all Angular Material modules required for this feature
@NgModule({
  exports: [MatToolbarModule, MatIconModule, MatButtonModule],
})
export class AasSharedMatModule {}
