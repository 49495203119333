import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  AggregationStatus,
  AuthenticatedInstitution,
} from '@legacy/typings/app/account-aggregation/AuthenticatedInstitution';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { IAngularEvent, IFormController, IRootScopeService, ITimeoutService } from 'angular';
import { AggregatedError } from 'typings/app/account-aggregation/AggregatedError';
import { ngRedux, ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import NgRedux from 'ng-redux';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { AppSettingsService } from '@legacy/services/app-settings.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { FlowService } from '@legacy/services/flow.service';
import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';
import { AggregatedAccount } from '@app/accounts/models';
import { AuthenticationRequest } from '@legacy/typings/app/account-aggregation/AuthenticationRequest';
import { AuthenticationResponse } from '@legacy/typings/app/account-aggregation/AuthenticationResponse';

@Component({
  selector: 'app-aggregation-auth',
  templateUrl: './aggregation-auth.component.html',
  styleUrls: ['./aggregation-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregationAuthComponent implements OnInit {
  isLoading = true;
  institution: AuthenticatedInstitution;
  aggregationForm: IFormController;
  _updateCredentials = false;
  requiresMfa = false;
  errorMessage: string;
  errorType: string;
  captcha: string;
  privacyUrl = '';
  isManual = true;
  logoWidth = '';
  logoGenerated = false;
  isFundingFlow = false;
  isMoveMoneyFlow = false;
  flow: string;
  isAccountAggregationFlow = false;
  institutionName = '';
  accountNickName: string;
  flowTypeEnum = FlowType;
  container: string;
  accountId: number;
  onBackRedirectAction: () => void;
  private delay: number;
  private messages: string[] = [
    `Sign in to your ${
      this.isAccountAggregationFlow ? this.institutionName + ' account ' : 'financial institution '
    } so we can verify your account.`,
    'A secure connection with your bank is being established.',
    'Your bank is being asked to verify your account.',
    'The verification process could take a few moments.',
    'The speed of this process varies by institution.',
    'Your patience during this crucial step is appreciated.',
    'The verification process is almost complete.',
  ];
  private currentMessageIndex = 0;
  private timer: any;
  private notificationInterval: number;
  private onCompleteRedirectAction: (error: AggregatedError) => void;

  private readonly timeout: ITimeoutService;

  constructor(
    @Inject(ROOT_SCOPE) private rootScope: IRootScopeService,
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private stateParams: ng.ui.IStateParamsService,
    private accountAggregationService: AccountAggregationService,
    private cachedAccountsService: CachedAccountsService,
    private appSettingsService: AppSettingsService,
    private serviceHelper: ServiceHelper,
    private featureFlagService: FeatureFlagService,
    private flowService: FlowService
  ) {}

  ngOnInit(): void {
    const {
      bankId,
      isAccountAggregationFlow,
      providerAccountId,
      container,
      accountId,
      accountNickName,
      isMoveMoneyFlow,
      flow,
    } = this.stateParams;
    this.onCompleteRedirectAction = this.stateParams['onCompleteRedirectAction'];
    this.onBackRedirectAction = this.stateParams['onBackRedirectAction'];

    this.container = container;
    this.accountId = accountId;
    this.accountNickName = accountNickName;
    this.appSettingsService.getSettingParsed<number>('AggAuthInterval').subscribe(value => (this.delay = value * 1000));
    this.appSettingsService.getSettingParsed<number>('AggUpdateStatusInterval').subscribe(value => {
      this.notificationInterval = value * 1000;
    });

    this.isFundingFlow = this.ngRedux.getState().funding.isRunning;
    this.isAccountAggregationFlow = isAccountAggregationFlow;
    this.isMoveMoneyFlow = isMoveMoneyFlow;
    // Reading state param from url, losing state flow when refreshing
    this.flow = flow;
    this.accountAggregationService.logUserAction(this.createUserActionLogin());
    this.accountAggregationService
      .getInstitution(bankId)
      .then(({ data }) => {
        this.institution = data;
        this.institution.providerAccountId = providerAccountId;
        this.institutionName = data.name;
        const imageLogo = new Image();
        imageLogo.src = this.institution.logo;
        imageLogo.onload = () => {
          if (imageLogo.naturalWidth > 200) this.logoWidth = '310px';
          this.logoGenerated = true;
        };

        this._updateCredentials = this.stateParams['updateCredentials'] === 'true';
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
      });

    if (this.rootScope['brandProperties']) {
      this.privacyUrl = this.rootScope['brandProperties']['PrivacyURL'];
    }

    this.serviceHelper.scrollToTop();
  }

  /**
   * Handles the customer's submit request
   * @param e Angular submit event
   */
  authenticate(e: IAngularEvent) {
    e.stopPropagation;
    if (this.aggregationForm.$invalid) return;
    this.executeAuthType();
  }

  /**
   * Validates the param input
   * @param param Parameter input
   */
  isParamInvalid(param: string): boolean {
    return (
      this.aggregationForm[param] &&
      this.aggregationForm[param].$invalid &&
      (this.aggregationForm.$submitted || this.aggregationForm[param].$dirty)
    );
  }

  /** Redirect to add account through Payveris */

  goToAddExternalAccount() {
    this.state.go(
      this.ngRedux.getState().funding.isRunning ? 'udb.funding.add-external-account' : 'udb.accounts.add-external',
      { flow: this.flow }
    );
  }

  goTo() {
    this.state.go('udb.dashboard.account-aggregation', {
      isAccountAggregationFlow: false,
      isPfm3Active: false,
      isMoveMoneyFlow: false,
    });
  }

  // * Account Aggregation flow
  // * Move Money flow (add account DDL Item)
  // * Funding flow (Intercept Modal in dashboard, just after login)
  // * When losing the state check state read from Url
  isFlowRunning(): boolean {
    if (
      this.isAccountAggregationFlow ||
      this.isFundingFlow ||
      this.isMoveMoneyFlow ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.Funding) ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.Aggregation) ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.MoveMoney) ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.AxosInvest) ||
      this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.ManagePortfoliosTransFun)
    ) {
      return true;
    }

    return false;
  }

  /**
   * Method used to determine which type of authentication will be executed:
   * Login into financial instituion or update user credentials
   * if already had a financial institution registered.
   */
  private executeAuthType() {
    this.setNotificationMessageInterval();
    if (!this._updateCredentials) this.auth();
    else this.updateCredentials();
  }

  private hasRequestId(): boolean {
    let exist = false;
    exist = !!this.institution.requestId;

    return exist;
  }

  /**
   * Handling request when a user want to Login into a financial institution for the first time.
   */
  private auth() {
    // Three entry points converge in this authentication process:
    // * Account Aggregation flow
    // * Move Money flow (add account DDL Item)
    // * Funding flow (Intercept Modal in dashboard, just after login)
    const authFunction = (this.isFlowRunning()
      ? this.accountAggregationService.accountAggregationAuth
      : this.accountAggregationService.auth
    ).bind(this.accountAggregationService);

    // When justGetAuthStatus is true, the ProviderAccountId and the RequestId must be
    // provided in the AuthenticationRequest object
    const justGetAuthStatus = this.hasRequestId();
    this.makeInstitutionRequest(
      // this.accountAggregationService.auth(justGetAuthStatus, this.prepareRequest()),
      authFunction(justGetAuthStatus, this.prepareRequest()),
      (response: OlbResponse<AuthenticationResponse>) =>
        this.checkCallState(response, this.auth.bind(this), () => this.redirectToSuccessAccountsAdded(response.data))
    );
  }

  /**
   *   * Handling request when a user credentials has changed
   */
  private updateCredentials() {
    this.makeInstitutionRequest(
      this.accountAggregationService.updateProviderCredentials(this.prepareRequest()),
      (response: OlbResponse<AuthenticationResponse>) =>
        this.checkCallState(response, this.updateCredentials.bind(this), this.redirectToAllPage.bind(this))
    );
  }

  /** Prepares the request getting the info to be sent */
  private prepareRequest(): AuthenticationRequest {
    return {
      authParameter: this.institution,
      isMfa: this.requiresMfa,
      providerAccountId: this.institution.providerAccountId,
      requestId: this.institution.requestId,
    };
  }

  private makeInstitutionRequest(promise: ApiResponse<AuthenticationResponse>, handleResponse: Function) {
    this.errorMessage = null;
    this.isLoading = true;
    this.isManual = false;
    promise
      .then((result: OlbResponse<AuthenticationResponse>) => {
        this.setInstitutionInfo(result);
        handleResponse(result);
      })
      .catch((error: ApiError) => {
        this.setErrorState(error.data);
      });
  }

  /**
   * This method is used to verify the Status got from yodlee and determine which action must be took
   * @param {OlbResponse<AuthenticationResponse>} response
   * @param {Function} callAction
   * @param {Function} redirectAction
   */
  private checkCallState(
    response: OlbResponse<AuthenticationResponse>,
    callAction: Function,
    redirectAction: Function
  ) {
    const { data } = response;
    switch (data.authInstitution.status) {
      case AggregationStatus.FAILED:
        this.setErrorState(response);
        break;
      case AggregationStatus.SUCCESS:
      case AggregationStatus.PARTIAL_SUCCESS:
        this.isLoading = false;
        redirectAction();
        break;
      case AggregationStatus.LOGIN_IN_PROGRESS:
      case AggregationStatus.IN_PROGRESS:
        this.timeout(() => {
          callAction();
        }, this.delay);
        break;
      case AggregationStatus.USER_INPUT_REQUIRED:
        if (data.authInstitution.forms && data.authInstitution.forms.length) {
          this.updateMFAForm(data.authInstitution);
        } else {
          this.timeout(() => {
            // call initial function again to get correct authentication form
            callAction();
          }, this.delay);
        }
        break;
    }
  }

  private setErrorState(data: OlbResponse<any>) {
    this.isLoading = false;
    this.isManual = true;
    this.errorType = 'error';
    this.errorMessage =
      data?.data?.error?.displayMessage ||
      data?.data?.authInstitution?.error?.displayMessage ||
      data?.message ||
      this.accountAggregationService.defaultErrorMsg;

    if (this.onCompleteRedirectAction) {
      this.onCompleteRedirectAction({
        displayMessage: this.errorMessage,
        isFixableByReauthentication: data?.data?.authInstitution?.error?.isFixableByReauthentication || false,
      });

      return;
    } else if (this._updateCredentials) {
      const nickName = this.accountNickName;
      this.state.go('udb.accounts.dashboard', {
        authStatus: AggregationStatus.FAILED,
        accountNickName: nickName,
        authErrorMsg: this.errorMessage,
      });

      return;
    }

    if (!data?.data || data?.data?.error) {
      // data.data = null means olb api unhandled error
      // data.data.error != null means yodlee api error
      return;
    }

    this.requiresMfa = false;
    this.updateMFAForm(data.data.authInstitution);
  }

  /**
   * When Multifactor Authentication (MFA) is active, this method will set the proper input form to be used during each authentication step
   * @param {AuthenticatedInstitution} institutionData
   */
  private updateMFAForm(institutionData: AuthenticatedInstitution) {
    const param = institutionData.forms[0].parameters[0];
    if (param.key == 'image') {
      this.captcha = `data:image/png;base64,${param.value}`;
    }

    institutionData.forms.forEach(form => {
      form.parameters.forEach(param => (param.value = ''));
    });

    this.institution.forms = institutionData.forms;

    // for each MFA step, requestId property must be reseted to get the correct MFA form
    this.institution.requestId = null;

    this.aggregationForm.$setUntouched();
    this.aggregationForm.$setPristine();

    this.isLoading = false;
  }

  /**
   * Updates the accounts in the rootScope and redirects to the proper page
   * @param data the Info returned when the authentication is done
   */
  private redirectToSuccessAccountsAdded(data: AuthenticationResponse) {
    const { accounts } = data.authInstitution;
    // For the funding flow, aggregation flow and move money flow the accounts wont be added to Payveris
    const payverisAccounts = data.externalAccounts;
    const isAccountAggregationEnhancementActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    let nextState: string;
    // Account Aggregation Flow.
    if (
      (isAccountAggregationEnhancementActive && this.isAccountAggregationFlow) ||
      (isAccountAggregationEnhancementActive &&
        this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.Aggregation))
    ) {
      nextState = 'udb.dashboard.account-aggregation.selection';
    }
    // Funding Flow and Move Money flow
    else {
      nextState = 'udb.dashboard.account-aggregation.auth-success';
      if (
        !(
          this.isFundingFlow ||
          this.isMoveMoneyFlow ||
          this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.Funding) ||
          this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.MoveMoney) ||
          this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.ManagePortfoliosTransFun)
        )
      ) {
        this.addAccounts(accounts, payverisAccounts);
      }
    }
    clearInterval(this.timer);

    this.state.go(nextState, {
      accounts,
      newPayverisAccounts: payverisAccounts,
      institution: this.institution,
      bankId: this.institution.bankId,
      providerAccountId: this.institution.providerAccountId,
      isAccountAggregationFlow: this.isAccountAggregationFlow,
      isMoveMoneyFlow: this.isMoveMoneyFlow,
      flow: this.flow,
    });
  }

  private addAccounts(accounts: AggregatedAccount[], payverisAccounts: ExternalAccount[]) {
    if (!!accounts && accounts.length > 0) {
      this.cachedAccountsService.addAggregatedAccounts(accounts);
    }
    if (payverisAccounts && payverisAccounts.length > 0) {
      this.cachedAccountsService.addExternalAccounts(payverisAccounts);
    }
  }

  private redirectToAllPage() {
    this.accountAggregationService.getAccounts().then((response: OlbResponse<AggregatedAccount[]>) => {
      this.cachedAccountsService.loadAggregatedAccounts(response.data);
      clearInterval(this.timer);
      if (this.onCompleteRedirectAction) {
        this.onCompleteRedirectAction(null);
      } else if (this.isAccAggEnhancementsActive() && this.isAccountAggregationFlow === false) {
        const nickName = this.accountNickName;
        this.state.go('udb.accounts.dashboard', {
          authStatus: AggregationStatus.SUCCESS,
          accountNickName: nickName,
        });
      } else {
        this.state.go('udb.accounts.dashboard');
      }
    });
  }

  private createUserActionLogin() {
    return new UserAction('AccAggregationAdded', [
      new UserActionField(`${this.isFundingFlow ? 'Funding Flow' : 'Acc. Aggregation Flow'}`, null, ''),
    ]);
  }

  private setInstitutionInfo(response: OlbResponse<AuthenticationResponse>) {
    const institution = response.data.authInstitution;
    this.institution.providerAccountId = institution.providerAccountId;
    this.institution.requestId = institution.requestId;
    this.requiresMfa = response.data.mfaRequired;
  }

  /**
   * Sets the interval to change the message any given time
   */
  private setNotificationMessageInterval() {
    this.currentMessageIndex = 1;
    this.timer = setInterval(() => {
      if (this.currentMessageIndex < this.messages.length - 1) this.currentMessageIndex++;
      else clearInterval(this.timer);
    }, this.notificationInterval);
  }

  private isAccAggEnhancementsActive(): boolean {
    return this.featureFlagService.isAccountAggregationEnhancementsActive();
  }
}
