import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { addUpdateOrdersAccountAction, getSelectedAccountOrdersTile } from '../core';
import { Order, OrdersState, OrdersTileState } from '../core/store/types';
import { OrdersResponse, OrdersService } from '../core/services';
import { InitializeOrdersTileInput } from './types';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrdersFacade {
  isLoading$ = new BehaviorSubject<boolean>(false);
  ordersTileAccountState$ = this.store.select(getSelectedAccountOrdersTile);

  constructor(private store: Store<OrdersState>, private ordersService: OrdersService) {}

  initializeOrdersTile(input: InitializeOrdersTileInput): void {
    this.isLoading$.next(true);

    this.ordersService.getOrders(input).subscribe(
      response => this.handleSuccessResponse(input, response.data),
      () => this.handleFailResponse(input)
    );
  }

  ordersTile(input: InitializeOrdersTileInput): Observable<OrdersResponse[]> {
    this.isLoading$.next(true);

    return this.ordersService.getOrders(input).pipe(
      map(response => this.handleOrdersTileSuccessResponse(response.data)),
      catchError(() => this.handleOrdersTileFailedResponse())
    );
  }

  private handleOrdersTileSuccessResponse(apiResponse: OrdersResponse[]) {
    this.isLoading$.next(false);

    return apiResponse;
  }

  private handleOrdersTileFailedResponse() {
    this.isLoading$.next(false);

    return of([]);
  }

  private handleSuccessResponse(initializeOrdersTileInput: InitializeOrdersTileInput, orders: Order[]) {
    this.isLoading$.next(false);

    const payload: OrdersTileState = this.getOrdersTilePayload(initializeOrdersTileInput, orders, true);
    this.store.dispatch(addUpdateOrdersAccountAction({ payload }));
  }

  private handleFailResponse(initializeOrdersTileInput: InitializeOrdersTileInput) {
    this.isLoading$.next(false);

    const payload: OrdersTileState = this.getOrdersTilePayload(initializeOrdersTileInput, [], false);
    this.store.dispatch(addUpdateOrdersAccountAction({ payload }));
  }

  private getOrdersTilePayload(
    initializeOrdersTileInput: InitializeOrdersTileInput,
    orders: Order[],
    apiCallWasSuccessful: boolean
  ) {
    return {
      apiCallWasSuccessful,
      accountNumber: initializeOrdersTileInput.accountNumber,
      limit: initializeOrdersTileInput.limit,
      startDate: initializeOrdersTileInput.startDate,
      endDate: initializeOrdersTileInput.endDate,
      orders: orders as Order[],
    };
  }
}
