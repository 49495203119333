import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { HistoricalBalance } from '../models/historical-balance.model';
import { PfmNetWorthProfile } from '../models/pfm-net-worth-profile.model';
import { PfmNetWorth } from '../models/pfm-net-worth.model';
import { PfmNetWorthRequest } from '../models/pfm-networth-request.model';
import { PfmTransactionsRequest } from '../models/pfm-transactions-request.model';
import { PfmTransactionsResponse } from '../models/pfm-transactions-response.model';
@Injectable()
export class PfmService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'accountAggregation/pfm');
  }
  getTransactions(filters: PfmTransactionsRequest): WebApiResponse<PfmTransactionsResponse> {
    return this.post(`transactions`, filters);
  }
  downloadTransactions(filters: PfmTransactionsRequest): Observable<Blob> {
    return this.downloadPost(`download`, filters);
  }
  getNetWorth(filters: PfmNetWorthRequest): WebApiResponse<PfmNetWorth[]> {
    return this.post(`networth`, filters);
  }
  getHistoricalBalance(filters: PfmNetWorthRequest): WebApiResponse<HistoricalBalance[]> {
    return this.post('historicalbalance', filters);
  }

  geNetWorthProfile(): WebApiResponse<PfmNetWorthProfile> {
    return this.get('networth/profile');
  }
}
