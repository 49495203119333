import { createAction, props } from '@ngrx/store';

import { HoldingTileState, TFilterOptionLabels } from './types';

export const addUpdateHoldingAccountAction = createAction(
  '[Holdings] AddUpdateHoldingAccount',
  props<{ payload: HoldingTileState }>()
);

export const selectHoldingSortingOption = createAction(
  '[Holdings] select sorting option',
  props<THoldingSelectSortOptionPayload>()
);

type THoldingSelectSortOptionPayload = {
  payload: {
    option: TFilterOptionLabels;
    accountNumber: string;
  };
};
