<div class="article pb-4 row" *ngFor="let article of articles">
  <div class="col-2 article-image-container">
    <div class="article-image"></div>
  </div>
  <div class="col-10 article-body">
    <div class="article-title">{{ article.title }}</div>
    <div class="article-description">{{ article.description }}</div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <button class="btn-text d-flex align-items-center">
      View More News
      <app-svg-icon class="ml-2" [iconName]="icons.chevronDown"></app-svg-icon>
    </button>
  </div>
</div>
