import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { TransferType } from '@app/axos-invest/enums/transfer-type.enum';
import { mapFrequencyToCode } from '@app/axos-invest/utils';
import { STATE } from '@core/tokens';

@Component({
  selector: 'app-simulate-deposit',
  templateUrl: './simulate-deposit.component.html',
  styleUrls: ['./simulate-deposit.component.scss'],
})
export class SimulateDepositComponent {
  @Input() recommendedDepositAmount: number;
  @Input() milestoneId: string;
  @Input() referrerTabId: number;
  @Output() simulateDepositEvent = new EventEmitter<any>();

  recommendedDepositFrequency = 'monthly';

  constructor(@Inject(STATE) private state: ng.ui.IStateService) {}

  simulateDeposit(amount: string | number, frequency: string) {
    if (typeof amount === 'string') {
      amount = parseFloat(amount.replace(/,/g, ''));
    }
    frequency = frequency.toLowerCase();
    this.simulateDepositEvent.emit({
      amount,
      frequency,
    });
  }

  makeDeposit(amount: string, frequency: string) {
    const amountOfDeposit = parseFloat(amount.replace(/,/g, ''));
    const frequencyCode = mapFrequencyToCode(frequency);
    this.state.go('udb.axosinvest.transferfunds', {
      id: this.milestoneId,
      transferType: TransferType.RecurrentDeposit,
      amount: amountOfDeposit,
      frequency: frequencyCode,
      referrerTabId: this.referrerTabId,
    });
  }
}
