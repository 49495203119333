import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { AvailableCashRequest } from './types/get-available-cash-request.types';
import { AvailableCashResponse } from './types/get-available-cash-response.types';

@Injectable()
export class AvailableCashService extends BaseService {
  /**
   * AvailableCashService constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/availableCash');
  }

  /**
   * Returns the available cash of account number from AAS customer.
   *
   * @param request AvailableCashRequest
   * @returns WebApiResponse of AvailableCashResponse
   *
   */
  getAvailableCash(request: AvailableCashRequest): WebApiResponse<AvailableCashResponse> {
    return this.get<AvailableCashResponse>(request.accountNumber + '/availableCash');
  }
}
