import { createSelector } from "@ngrx/store";
import { getAxosAdvisoryState } from "../axos-advisory.state";

export const getContributions = createSelector(
  getAxosAdvisoryState,
  state => state.axosAdvisoryContributions
);

export function getContributionByYear(year: number){
  return createSelector(
    getAxosAdvisoryState,
    state => state.axosAdvisoryContributions.find(p => p.year === year))
}