import { IStateService } from 'angular-ui-router';

import { IRedirectStateService } from './typings/IRedirectStateService';
import { RedirectState } from '../dashboard/account-aggregation/typings/RedirectState';
import { Inject } from '../decorators/Inject';

@Inject('$state')
export class RedirectStateService implements IRedirectStateService {
  originalState: RedirectState;

  constructor(private readonly state: IStateService) {}

  /**
   * Sets returning angular state with its params
   */
  setOriginalState(stateName: string, stateParams?: any): void {
    const state: RedirectState = { StateName: stateName, StateParams: stateParams };

    this.originalState = state;
  }

  /**
   * Redirects to the angular state previously registered
   * or dashboard if nothing is registered
   */
  goToOriginalState(): void {
    if (!this.originalState) this.originalState = { StateName: 'udb.dashboard' };

    this.state.go(this.originalState.StateName, this.originalState.StateParams);
  }
}
