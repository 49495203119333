import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SecureFormsLibrary } from '@app/secure-forms/models';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SecureFormsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'secureForms');
  }

  getLibrary(): WebApiResponse<SecureFormsLibrary> {
    return this.get<SecureFormsLibrary>('library');
  }

  getForm(fileId: number, isFormNumber?: boolean): Observable<Blob> {
    const params = new HttpParams().set('isFormNumber', isFormNumber.toString());

    return this.download(`formtype/${fileId}`, params);
  }

  sendDocumentForms(document: FormData): Observable<HttpEvent<object>> {
    return this.upload('save/document', document);
  }
}
