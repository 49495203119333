<div class="container compose-message-confirmation">
  <div class="how-section-title text-center">
    <svg aria-hidden="true" class="checkmark green-stroke" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <circle
        *ngIf="this.brandName !== 'axos'"
        class="checkmark__circle"
        cx="30"
        cy="30"
        r="26"
        fill="none"
        stroke-linecap="round"
      />
      <path
        class="checkmark__check"
        fill="none"
        d="M19.1 30.6l8.9  6.5 13-15.1 "
        stroke-linecap="round"
        stroke-corner="round"
        stroke-linejoin="round"
      />
    </svg>
    <h2>
      Your Secure Message has been sent!
    </h2>
  </div>
  <div class="text-justify m-t-lg">
    It’s our goal to get back to you within two business days, but in times of high volume it may take up to four
    business days for us to reply.
    <br />
    <br />
    When we do reply, you will receive an email notification at
    <b>{{ userInfo.email }}</b>
    informing you that you have a Secure Message waiting for you. When you get the notification, log in to your
    <label class="brand-label">{{ brandName }}</label>
    account to retrieve the Secure Message.
    <div class="pt-2 text-left">
      <ul>
        <li>Your Team @{{ brandTeamName }}.</li>
      </ul>
    </div>
    <div class="m-t-lg text-center">
      <a (click)="returnToMessageCenter('udb.messages.inbox')" href="#">
        RETURN TO MESSAGE CENTER
        <i class="fa fa-chevron-right"></i>
      </a>
    </div>
  </div>
</div>
