import { AxosInvestUrlHelper } from '@legacy/services/axos-invest-urls.service';

export function axosInvestUrlHelperFactory($injector: any) {
  return $injector.get('axosInvestUrlHelper');
}

export const axosInvestUrlHelperProvider = {
  provide: AxosInvestUrlHelper,
  useFactory: axosInvestUrlHelperFactory,
  deps: ['$injector'],
};
