import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IDevices } from './typings/IDevices';
import { Devices } from 'typings/app/Devices';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class DevicesService extends BaseService implements IDevices {
  constructor(
    http: ng.IHttpService,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    q: ng.IQService
  ) {
    super(http, env, 'devices', serviceHelper, q);
  }

  getAllDevices(): ApiResponse<Devices[]> {
    return this.get();
  }
}
