import { AnyAction } from 'redux';
import { ACCOUNTS } from '../../constants/accounts';

/**
 * Functions in charge of handling the transformations in the store
 * NOTE: This function never mutates the state directly, redux does
 */
export function ExternalReducer(state: ExternalAccount[] = null, action: AnyAction) {
  switch (action.type) {
    case ACCOUNTS.EXTERNAL.LOAD:
      return action.payload;
    case ACCOUNTS.EXTERNAL.ADD:
      return [
        ...state,
        ...action.payload.filter(
          (newAccount: ExternalAccount) =>
            !state.find(account => account.externalAccountId == newAccount.externalAccountId)
        ),
      ];
    case ACCOUNTS.EXTERNAL.UPDATE:
      return state.map(account => {
        const updatedAccount = action.payload.find(
          (newAccount: ExternalAccount) =>
            newAccount.externalAccountId === account.externalAccountId
        );
        return updatedAccount || account;
      });
    case ACCOUNTS.EXTERNAL.REMOVE:
      return state.filter(account => account.externalAccountId !== action.payload);
    case ACCOUNTS.EXTERNAL.FLUSH:
      return [];
    default:
      return state;
  }
}
