<app-view-header>
  <h1>Dispute Details</h1>
</app-view-header>

<div class="view-container">
  <div class="m-auto col-10 col-md-8" *ngIf="transactions && accountId">
    <app-transaction-dispute-form
      [transactions]="transactions"
      [accountId]="accountId"
      [accountDetail]="accountDetail"
      [isMultiTransaction]="true"
      [previousTransactions]="previousTrx"
      (cancel)="redirectTo()"
    ></app-transaction-dispute-form>
  </div>
</div>
