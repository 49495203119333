<div class="signature-card-option" *ngIf="showSignatureCard">
  <app-svg-icon
    class="signature-card-option__icon px-0 mr-2"
    [iconName]="icon"
    [styles]="{ strokeWidth: 1, width: '1rem', heigth: '1rem', fill: 'orange' }"
  ></app-svg-icon>

  <div class="signature-card-option__text-container">
    <span class="signature-card-option__description d-sm-none">
      Signature Card Status
    </span>
    <span class="signature-card-option__description d-none d-sm-block">
      Signature Card Status:
    </span>
    <span class="signature-card-option__status">{{ signatureCardStatusDescription }}</span>
    <a
      href="#"
      id="signature-card-redirection-link"
      class="signature-card-option__action link ml-xl-auto"
      *ngIf="showAddSignatureCard"
      (click)="goToSignatureCard()"
    >
      +Add card
    </a>
  </div>
</div>
