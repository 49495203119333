import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { ISupportService } from './typings/ISupportService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class SupportService extends BaseService implements ISupportService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'messages', _serviceHelper, q);
  }

  getCategories(): ApiResponse<Category[]> {
    return this.get(`questions`, null);
  }

  getSubCategories(categoryId: number): ApiResponse<SubCategory[]> {
    return this.get(`questions`, { parentId: categoryId });
  }

  createCase(caseData: CaseData): ApiResponse<string> {
    return this.post(``, { caseCreationDto: caseData });
  }
}
