<app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>

<div class="feature-container row text-center justify-content-center" *ngIf="!isLoading">
  <div class="col-12">
    <img src="assets/img/logos/axos-invest-logo.svg" width="120px" alt="Axos Invest" aria-hidden="true" />
  </div>
  <div class="col-12">
    <p class="feature-description">
      For advanced research and trading functionality,
      <a id="click-here-button" [href]="axosOrbisUrl" class="click-here" target="_blank">
        click here.
        <app-svg-icon class="redirect-icon" [iconName]="redirectIcon" size="18px"></app-svg-icon>
      </a>
    </p>
  </div>
</div>
