import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PortfolioType } from '@app/axos-invest/enums';
import { Goal, GoalAllocation } from '@app/axos-invest/models';

@Component({
  selector: 'app-portfolio-updated-confirmation',
  templateUrl: './portfolio-updated-confirmation.component.html',
  styleUrls: ['./portfolio-updated-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioUpdatedConfirmationComponent implements OnInit {
  @Input() portfolioType: PortfolioType;
  @Input() goalAllocation: GoalAllocation;
  @Input() previousPortfolioType: PortfolioType;
  @Input() goal: Goal;

  @Output() endingAllocation = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();

  showAddEndAllocation = false;
  wasGlidepathEnabled: boolean;

  ngOnInit() {
    this.wasGlidepathEnabled = this.previousPortfolioType === PortfolioType.GlidepathEnabled;
    this.showAddEndAllocation =
      this.portfolioType === PortfolioType.Basic && !this.wasGlidepathEnabled && this.goalAllocation.cashStart !== 1;
  }
}
