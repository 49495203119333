import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import tippy, { animateFill, Props, roundArrow } from 'tippy.js';

import { OpenAccountItem } from '@app/accounts/models';
import { UtilityIcons } from '@shared/enums';
@Component({
  selector: 'app-open-account-items-container',
  templateUrl: './open-account-items-container.component.html',
  styleUrls: ['./open-account-items-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenAccountItemsContainerComponent implements AfterViewInit {
  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef;

  @Input() accountItems: Partial<OpenAccountItem>[];

  plusIconConfig = {
    icon: UtilityIcons.Plus,
    styles: {
      width: '1rem',
      height: '1rem',
    },
  };

  private options: Partial<Props> = {
    allowHTML: true,
    animateFill: true,
    arrow: roundArrow,
    placement: 'bottom-start',
    plugins: [animateFill],
    theme: 'account-items',
    interactive: true,
    appendTo: 'parent',
    trigger: 'click',
    popperOptions: {
      modifiers: [
        { name: 'flip', enabled: false },
        { name: 'hideOnClick', enabled: true, phase: 'afterWrite' },
      ],
    },
  };

  ngAfterViewInit(): void {
    this.options.content = this.content.nativeElement.innerHTML;
    tippy(this.tooltip.nativeElement, this.options);
  }
}
