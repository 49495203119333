import { InjectionToken } from '@angular/core';
export const ALERTSSMSSERVICE = new InjectionToken('IAlertsSmsService');
export const ALERTSNOTIFICATIONSSERVICE = new InjectionToken('alertsNotificationsService');
export const DELIVERYMETHODSERVICE = new InjectionToken('IDeliveryMethodService');
export const MULTIFACTORSERVICE = new InjectionToken('IMultifactorService');
export const LOADUSERPROFILEHELPER = new InjectionToken('ILoadUserProfileHelper');
export const BRANDPROPERTYSERVICE = new InjectionToken('BrandPropertyService');
export const CACHEDACCOUNTSSERVICE = new InjectionToken('cachedAccountsService');
export const DEVICESSERVICE = new InjectionToken('DevicesService');
export const MODALSERVICE = new InjectionToken('ModalService');
export const USERSUBTYPEHELPER = new InjectionToken('UserSubtypeHelper');
export const FILTER = new InjectionToken('filter');
export const DATERANGEPICKEROPTIONS = new InjectionToken('dateRangePickerOptions');

export const myAlertsServiceProvider = [
  {
    provide: ALERTSNOTIFICATIONSSERVICE,
    useFactory: (i: any) => i.get('alertsNotificationsService'),
    deps: ['$injector'],
  },
  {
    provide: ALERTSSMSSERVICE,
    useFactory: (i: any) => i.get('alertsSmsService'),
    deps: ['$injector'],
  },
  {
    provide: DELIVERYMETHODSERVICE,
    useFactory: (i: any) => i.get('deliveryMethodService'),
    deps: ['$injector'],
  },
  {
    provide: DEVICESSERVICE,
    useFactory: (i: any) => i.get('devicesService'),
    deps: ['$injector'],
  },
  {
    provide: CACHEDACCOUNTSSERVICE,
    useFactory: (i: any) => i.get('cachedAccountsService'),
    deps: ['$injector'],
  },
  {
    provide: MODALSERVICE,
    useFactory: (i: any) => i.get('modalService'),
    deps: ['$injector'],
  },
  {
    provide: MULTIFACTORSERVICE,
    useFactory: (i: any) => i.get('multifactorService'),
    deps: ['$injector'],
  },
  {
    provide: LOADUSERPROFILEHELPER,
    useFactory: (i: any) => i.get('loadUserProfileHelper'),
    deps: ['$injector'],
  },
  {
    provide: BRANDPROPERTYSERVICE,
    useFactory: (i: any) => i.get('brandPropertyService'),
    deps: ['$injector'],
  },
  {
    provide: USERSUBTYPEHELPER,
    useFactory: (i: any) => i.get('userSubtypeHelper'),
    deps: ['$injector'],
  },
  { provide: FILTER, useFactory: ($injector: any) => $injector.get('$filter'), deps: ['$injector'] },
  {
    provide: DATERANGEPICKEROPTIONS,
    useFactory: (i: any) => i.get('dateRangePickerOptions'),
    deps: ['$injector'],
  },
];
