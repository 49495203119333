import { AssetCategory } from '@app/axos-invest/enums';

export const COLORS_BONDS: string[] = [
  '#4A1F85',
  '#55289E',
  '#5F34B6',
  '#6842CB',
  '#7453DC',
  '#8268E8',
  '#9580F2',
  '#AC9DF8',
  '#C7BDFD',
  '#E3DEFF',
];

export const COLORS_STOCKS: string[] = [
  '#051A3F',
  '#0C3268',
  '#17508F',
  '#2670B0',
  '#398DCB',
  '#50A7DF',
  '#6BBEED',
  '#8AD1F6',
  '#ACE1FC',
  '#CDEEFF',
];

export const COLOR_CASH: string[] = ['#13AEBA'];

export const PORTFOLIO_COLORS = {
  [AssetCategory.Bonds]: COLORS_BONDS,
  [AssetCategory.Cash]: COLOR_CASH,
  [AssetCategory.Stocks]: COLORS_STOCKS,
};
