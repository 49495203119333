import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EDisclosureModal } from './view/e-disclosure-modal.component';

@NgModule({
  declarations: [EDisclosureModal],
  imports: [SharedModule],
  exports: [EDisclosureModal],
})
export class EDisclosureModule {}
