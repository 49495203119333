import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';

import * as moment from 'moment';

import { BeneficiaryRelationshipType, UtilityIcons } from '@app/shared/enums';
import { Beneficiary } from '@shared/models';

@Component({
  selector: 'app-beneficiary-form',
  templateUrl: './beneficiary-form.component.html',
  styleUrls: ['./beneficiary-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BeneficiaryFormComponent implements OnInit {
  /** Input parameter for the component */
  @Input() beneficiary: Beneficiary;
  @Input() isOtherRelationship: boolean;
  @Input() beneficiaries?: Beneficiary[]; // Using for avoid new beneficiaries duplicated
  @Input() allBeneficiaries: Beneficiary[]; // Using for avoid new beneficiaries duplicated vs beneficiaries come from DB
  @Input() position: number;
  @Input() parentForm: any;

  /** Output parameter for the component */
  onRemoveBeneficiary: EventEmitter<any> = new EventEmitter<any>();

  beneficiaryRelationshipSelect: string;
  otherRelationship: string;
  relationshipType: any[] = [];
  isDobValid = false;
  indexControl = 0;
  controlName: string;
  icons = {
    trash: UtilityIcons.Trash,
  };
  constructor() {
    this.createDropdownOptions();
  }

  ngOnInit(): void {
    this.isDobValid = this.isValidDoB(this.beneficiary.identity);
    this.handleRelationship();
    this.controlName = `fullName_${this.position}`;
  }

  checkBeneficiary() {
    this.indexControl = this.parentForm.$$controls.findIndex((a: { $name: string }) => a.$name === this.controlName);
    this.parentForm.$$controls[this.indexControl].$setValidity('isDuplicated', true);

    if (this.beneficiary.name === undefined) {
      return;
    }
    const name = this.beneficiary.name.replace(/\s/g, '').toUpperCase();
    const searchDuplicated = [
      ...this.allBeneficiaries,
      ...this.beneficiaries.filter((_b, index) => index !== this.position && _b.name),
    ];

    const found = searchDuplicated.find(a => {
      return a.name.replace(/\s/g, '').toUpperCase() === name;
    });
    this.parentForm.$$controls[this.indexControl].$setValidity('isDuplicated', found === undefined);
  }

  isValidDoB(date: string): boolean {
    return moment(date, 'MM/DD/YYYY').isValid();
  }

  formatDoB(): void {
    const dob = this.beneficiary.identity;

    if (dob) {
      const month = dob.slice(0, 2);
      const day = dob.slice(2, 4);
      const year = dob.slice(4, dob.length);

      const dobFormatted = `${month}/${day}/${year}`;

      if (this.isValidDoB(dobFormatted)) {
        this.isDobValid = true;
        this.beneficiary.identity = dobFormatted;

        return;
      }
    }

    this.isDobValid = false;
  }

  removeBeneficiary(position: number) {
    this.onRemoveBeneficiary.emit({ position });
  }

  onRelationshipChange(value: string): void {
    this.beneficiary.relationship = value;
  }

  getPositionClass(position: number): string {
    const control = this.parentForm.get(`other_${position}`);

    return (control.invalid || control.hasError('required')) && this.parentForm.submitted ? 'has-error' : '';
  }

  private handleRelationship(): void {
    if (this.isOtherRelationship) {
      this.otherRelationship = this.beneficiary.relationship;
      this.beneficiaryRelationshipSelect = 'Other';
    } else {
      this.beneficiaryRelationshipSelect = this.beneficiary.relationship;
    }
  }

  private createDropdownOptions(): void {
    for (const item in Object.keys(BeneficiaryRelationshipType)) {
      if (BeneficiaryRelationshipType.hasOwnProperty(item)) {
        const value = BeneficiaryRelationshipType[item];
        this.relationshipType.push({ key: item, value });
      }
    }
  }
}
