import { Inject } from '../decorators/decorators';
// import { MessagesController } from './messages.controller';

@Inject('$stateProvider')
export class MessagesConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.messages', {
      url: '/Messages',
      views: {
        content: {
          template: '<app-messages><div ui-view="messagesContent" class="load-views"></div></app-messages>',
        },
      },
    });
  }
}
