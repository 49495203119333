import { NO_CONTRIBUTION_ACCOUNT_TYPE_CODES } from '@app/Areas/AAS/aas-shared/constants';

export const NO_CONTRIBUTION_ACCOUNT_TYPES_CONST = [
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountType403,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountType403R,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountType4PS,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeK,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeMPP,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeOQP,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypePSP,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeQP,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeRoth401,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSEP,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSK,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSKR,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSUB,
  NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSimpleIRA,
];
