import { Component } from '@angular/core';

@Component({
  selector: 'app-card-activated-modal',
  templateUrl: './card-activated-modal.component.html',
  styleUrls: ['./card-activated-modal.component.scss'],
})
export class CardActivatedModalComponent {
  isViewMoreVisible = false;

  toggleContent() {
    this.isViewMoreVisible = !this.isViewMoreVisible;
  }
}
