import { Component, Inject, OnInit } from '@angular/core';

import { SwitchStatus } from '@app/user-profile/enums';
import { SwitchDetail } from '@app/user-profile/models';
import { DirectDepositService } from '@core/services';
import { STATE } from '@core/tokens';
@Component({
  selector: 'app-click-switch-list',
  templateUrl: './click-switch-list.component.html',
  styleUrls: ['./click-switch-list.component.scss'],
})
export class ClickSwitchListComponent implements OnInit {
  switches: SwitchDetail[];
  isLoading = false;
  readySwitchStatus = SwitchStatus.Ready.toString();

  constructor(private directDepositService: DirectDepositService, @Inject(STATE) private state: ng.ui.IStateService) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getCustomerSwitches();
  }

  showConfirmation(switchInfo: SwitchDetail) {
    let redirect = '';

    switch (switchInfo.status) {
      case SwitchStatus.Submitted.toString():
        redirect = 'udb.clickswitch.confirmation';
        break;
      case SwitchStatus.Printed.toString():
        redirect = 'udb.clickswitch.confirmation-print';
        break;
    }

    if (redirect) {
      this.directDepositService.getSwitch(switchInfo.index).subscribe({
        next: result => {
          this.state.go(redirect, { userSwitch: result.data });
        },
      });
    }
  }
  private async getCustomerSwitches() {
    this.isLoading = true;
    this.directDepositService.getCustomerSwitches().subscribe(result => {
      this.switches = result.data;
      this.isLoading = false;
    });
  }
}
