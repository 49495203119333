<div class="dialog-header" [style.backgroundImage]="backgroundUrl"></div>

<h3 id="learn-more-transfers-modal-header" t-dialog-title>
  Learn More About Transferring Money
</h3>

<div id="learn-more-transfers-content" mat-dialog-content class="mat-dialog-content">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">What transfers can I make?</mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          You can make one-time or recurring transfer. You can also schedule multiple transfers at once to save time.
        </p>
        <ul>
          <li>
            <strong>Internal Transfers:</strong>
            Transfer funds to or from your eligible {{ settings.brandName }} accounts.
          </li>
          <li>
            <strong>External Transfers:</strong>
            External transfers to and from checking, savings or money market accounts that you have at other financial
            institutions.
          </li>
        </ul>
        <p>
          You may also transfer funds to another person whose account is at {{ settings.brandName }} or at other
          financial institutions through our
          <i>Pay It Now service.</i>
          To learn about
          <i>Pay it Now,</i>
          click
          <strong>Support</strong>
          at the top of Online Banking and then select
          <strong>Person to Person</strong>
          under our
          <strong>How To</strong>
          section.
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          What accounts are available for internal and external transfers?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          Accounts eligible to transfer money are shown in the drop down menus
          <i>From Account</i>
          and
          <i>To Account.</i>
          Once you select an eligible
          <i>From Account,</i>
          only eligible accounts will show in the
          <i>To Account</i>
          drop down menu. If one of your accounts doesn't appear, it may be because it is not eligible to send or
          receive money, or that the account is unavailable based on its account status
        </p>
        <p>Examples of ineligible accounts:</p>
        <ul>
          <li>
            <i>From Account</i>
            cannot be an IRA, Certificates of Deposit, Loans, or an account in a status of closed, dormant, or
            restricted.
          </li>
          <li>
            <i>To Account</i>
            cannot be a Certificate of Deposit, the same account as the
            <i>From Account,</i>
            an external account if the
            <i>From Account</i>
            is external, and an account in a status of closed, dormant, or restricted.
          </li>
          <li>
            Accounts must be with domestic banks located in the United States with funds transferred in US dollars.
            Transfers to foreign banks are not permitted through Online Banking. If you need to transfer
            internationally, please contact us about a Wire Transfer.
          </li>
        </ul>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          Can I make a transfer using a non-{{ settings.brandName }} external account?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          To transfer money between your {{ settings.brandName }} account and an external account at another financial
          institution, we first need you to verify that you are the account owner of the external account. You have two
          options:
        </p>
        <ol>
          <li>
            <strong>
              Provide your username and password to the other financial institution.
            </strong>
            Once verified by your other financial institution, the transfers can begin immediately.
          </li>
          <li>
            <strong>
              Deposit two small amounts into your account at the other financial institution.
            </strong>
            You will see these deposits post to your external account within 1-3 business days. Once you’ve confirmed
            these two deposit amounts, your external account will be considered ACTIVE and will appear on the
            <i>From Account</i>
            and
            <i>To Account</i>
            drop down menus in order to make transfers.
          </li>
        </ol>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          What frequency options are available?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          You can make a one-time same day transaction, a one-time future dated transaction, or create a recurring
          transfer series.
        </p>
        <ul>
          <li>
            <strong>One-time same day transfers</strong>
            <p>
              For a
              <strong>one-time same day internal transfer,</strong>
              the Send On date is preset to today's date. Transactions between your {{ settings.brandName }} accounts
              will be initiated and posted the day you make them. You cannot cancel a one-time same day transfer once
              you submit it as the transfer occurs immediately.
            </p>
            <p>
              <strong>One-time external account transfers</strong>
              will take additional time to process. The transaction will still be initiated the same day, if entered
              before the cut off time of 1:45 p.m. Pacific Time on a business day, but will take 3-4 business days to
              complete. If requested after the cut off time, or on a weekend or holiday, the 3-4 business days will not
              start until the next business day. If transferring from a {{ settings.brandName }} account, your available
              balance will be decreased on the day the transfer process begins. If transferring to a
              {{ settings.brandName }} account, your available balance will not be increased until the transfer process
              is complete 3-4 business days later.
            </p>
          </li>
          <li>
            <strong>One-time future dated transfers</strong>
            <p>
              You can set up a future dated transfer between your internal {{ settings.brandName }} accounts as well as
              with your external checking and savings accounts at another financial institution.
            </p>
            <p>
              Future dated transfers will take place on the scheduled date, or on the next business day if the scheduled
              date falls on a weekend or holiday.
            </p>
          </li>
          <li>
            <strong>Recurring transfers</strong>
            <p>
              You can set up recurring transfers between your checking, savings*, and money market* accounts by simply
              choosing the day you want to start and the frequency you would like the transfers to occur – Weekly, Every
              Two Weeks, Monthly, Quarterly, Semi-annually or Annually. To pay a loan, you can only select the monthly
              option.
            </p>
            <p>
              If any of the future recurring transfer dates fall on a weekend or a holiday, the transfer will be
              scheduled for the following business day.
            </p>
            <p>
              *Be careful not to use a savings account or money market as the “From Account” for recurring transfers
              unless you are sure this won’t take you over 6 withdrawals per statement cycle. Savings and Money Market
              accounts have a limit on the number of withdrawals you can make before you are assessed a fee. To learn
              more read “What are my transfer limits?” located further down on this page.
            </p>
          </li>
        </ul>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          When will my transferred funds be available?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          Funds transferred to your {{ settings.brandName }} account from another one of your {{ settings.brandName }}
          internal accounts will be available immediately for purchases and withdrawals.
        </p>
        <p>
          Funds transferred in from an external account usually take 3-4 business days to complete.
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          What if I need to make an external transfer that is faster than 3-4 business day?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          If you need to make an external transfer to another financial institution faster, please contact us about a
          wire transfer or submit the
          <strong>Wire Transfer Request Form,</strong>
          located in the
          <strong>Secure Forms</strong>
          section under
          <strong>Support.</strong>
          Wire transfers can be submitted daily up to 12:00 noon Pacific Time. If submitted and approved before the
          deadline, the wire will be submitted same day and should be credited to your account at the other financial
          institution by the end of the same day, depending on the processing rules of that financial institution. Note:
          A wire transfer fee will be assessed by {{ settings.brandName }} and there may also be a wire transfer fee
          assessed by the other financial institution.
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          Is there a cut off time for transfers?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p><strong>One-Time Transfers:</strong></p>
        <ul>
          <li>
            Internal transfers between {{ settings.brandName }} accounts can be submitted 24 hours a day, seven days a
            week.
          </li>
          <li>
            Transfers to an external account are scheduled the next business day if submitted before 1:45 p.m. Pacific Time.
            Any requests after this cut-off time will be scheduled for the second business day.
          </li>
        </ul>
        <p><strong>Recurring Internal Transfers:</strong></p>
        <p>
          The Bank only processes recurring transfers on business days (no weekends or holidays).
        </p>
        <ul>
          <li>
            An recurring transfer series requested between two {{ settings.brandName }} internal accounts can begin on
            the same day if requested before 11:59 p.m. Pacific Time on a business day. Recurring internal transfers
            requested for a non-business day (week-end or holiday) will be posted the next business day.
          </li>
        </ul>
        <p><strong>Recurring External Transfers:</strong></p>
        <p>
          The Bank only processes recurring transfers on business days (no weekends or holidays).
        </p>
        <ul>
          <li>
            Recurring transfers from a {{ settings.brandName }} account to an external account, must be submitted prior
            to 1:45 p.m. Pacific Time on a business day and will be posted the same day. Those submitted after 1:45 p.m.
            will be posted the next business day.
          </li>
          <li>
            Recurring transfers from an external account to a {{ settings.brandName }} account take 3-4 business days to
            receive the funds from the other financial institution to deposit into your
            {{ settings.brandName }} account. Recurring transfers submitted prior to 1:45 p.m. Pacific Time on a
            business day will begin the transfer process that same day. Those submitted after 1:45 p.m. Pacific Time
            will begin the process on the next business day.
          </li>
        </ul>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          Can I edit or delete a scheduled transfer?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <ul>
          <li>
            <strong>Internal One-Time Same Day Transfers:</strong>
            you cannot make any changes after it has been requested as the transfer will take place immediately.
          </li>
          <li>
            <strong>Internal One-Time Future Dated Transfers:</strong>
            you have until 11:59 p.m. Pacific Time the day before the scheduled transfer date to edit or delete the
            transfer. After that time, the transfer cannot be changed or cancelled.
          </li>
          <li>
            <strong>Internal Recurring Transfer:</strong>
            you have until 11:59 p.m. Pacific Time the day before the next scheduled transfer date to edit or delete the
            next scheduled transfer. After that time, the next transfer cannot be changed, but you can edit or delete
            any remaining transfers in the recurring transfer series.
          </li>
          <li>
            <strong>External One-Time Same Day Transfers:</strong>
            you have until 1:45 p.m. Pacific Time the business day of that transfer to edit or delete the transfer.
          </li>
          <li>
            <strong>External One-Time Future Dated Transfer:</strong>
            you have until 1:45 p.m. Pacific Time the business day of that transfer to edit or delete the transfer.
          </li>
          <li>
            <strong>External Recurring Transfers:</strong>
            you have until 1:45 p.m. Pacific Time the business day of the next transfer to edit or delete the next
            scheduled transfer. After that time, the next transfer cannot be changed, but you can still edit or delete
            any remaining transfers in the recurring transfer series.
          </li>
        </ul>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          Will my overdraft funds be used to make a transfer?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          On the scheduled transaction date, we will check your available balance, including any available Overdraft
          Protection funds, to ensure your account has sufficient funds to complete the transfer. If your account does
          not have sufficient available funds or Overdraft Protection funds, we will not process the transfer and we
          will email you a notification. Transactions completed using Overdraft Protection funds may result in fees.
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          What are my transfer limits - number of times and dollar amount?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          We do not limit the number of funds transfers you may make. Please refer to your Deposit Account Agreement &
          Schedule of Fees.
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z" id="transfer-wires">
      <mat-expansion-panel-header>
        <mat-panel-title class="accordion-title">
          What if I need to make a transfer above these dollar limits?
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          If you need to make an external transfer that is over these dollar limits, please submit a
          <a id="wires-link" href="#" (click)="redirectToWireTransfers()">
            <b>Wire Transfer.</b>
          </a>
          There is no limit to the amount of money you can wire to an external account in your name as long as the funds
          are available in your account. Note: A wire transfer fee will be assessed by {{ settings.brandName }} and
          there may also be a wire transfer fee assessed by the other financial institution.
        </p>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <p class="text-center mt-4">
    For questions about these transfer rules, please contact Customer Service at
    <br />
    <a href="tel:+{{ propertyValues[properties.ContactPhoneNumber] }}">
      {{ propertyValues[properties.ContactPhoneNumber] }}.
    </a>
  </p>
</div>

<div id="learn-more-transfers-modal-action-container" mat-dialog-actions>
  <button class="btn-ok" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</div>
