import { PortfolioAllocationTileState } from './portfolio-allocation-tile.state.type';

export type PortfolioAllocationState = {
  porfolioAllocations: PortfolioAllocationTileState[];
  selectedAccountPorfolioAllocations: string | undefined;
};

export const initialPortfolioAllocationState: PortfolioAllocationState = {
  porfolioAllocations: [],
  selectedAccountPorfolioAllocations: undefined,
};
// Mock fix end
