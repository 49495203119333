import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFilterComponent implements OnInit, OnChanges {
  @Input() onlyNumbers: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() inputLabel: string;
  @Input() inputPlaceholder: string;
  @Input() iconPlaceholder: string;
  @Input() control: UntypedFormControl;

  constructor() {}

  ngOnInit() {
    if (!this.control) this.control = new UntypedFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.isDisabled) {
      changes.isDisabled.currentValue ? this.control.disable() : this.control.enable();
    }
  }
}
