<app-view-header [titleText]="title">
  <p class="account-name-banner">
    Please review and fill out all the necessary information in the agreement(s) below before you accept.
  </p>
</app-view-header>
<div class="container">
  <div class="row">
    <button id="backToBtn" type="button" class="link d-flex align-items-center" (click)="goBack()">
      <app-svg-icon class="overview__icon mr-2" [iconName]="icons.backIcon" size="1.3em"></app-svg-icon>
      <span class="overview__text">Back</span>
    </button>
  </div>
  <div class="row mt-4">
    <iframe width="100%" height="100%" [src]="urlSafe"></iframe>
  </div>
</div>
