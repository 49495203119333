import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { AccountTab } from '@app/accounts/enums';

@Component({
  selector: 'app-axos-trading-transactions',
  templateUrl: './axos-trading-transactions.component.html',
  styleUrls: ['./axos-trading-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AxosTradingTransactionsComponent {
  @Output() changeTab = new EventEmitter<AccountTab>();

  goToStatements(): void {
    this.changeTab.emit(AccountTab.StatementTaxForms);
  }
}
