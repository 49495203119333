import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { RecategorizeTransactionRequest } from '@legacy/typings/app/account-aggregation/RecategorizeTransactionRequest';
import Category from 'typings/app/account-aggregation/Category';

@Component({
  selector: 'app-recategorize-transactions',
  templateUrl: './recategorize.component.html',
  styleUrls: ['./recategorize.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecategorizeComponent implements OnInit {
  @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();
  @Input() transaction: Transaction;
  isLoading: boolean;
  private category: string;
  everySimilarTransaction = true;
  categories: Category[];

  constructor(private accountAggregationService: AccountAggregationService) {}

  ngOnInit() {
    this.isLoading = true;
    this.accountAggregationService
      .getCategories()
      .then(d => {
        this.categories = this.setupCategory(d.data.filter(c => c.name.toLowerCase() !== 'pending'));
        this.category = this.transaction.olbCategoryName;
        this.markAsActive(this.category);
      })
      .finally(() => (this.isLoading = false));
  }

  saveAction() {
    const newCategory = this.categories.find(x => x.active);
    if (newCategory.name?.toLowerCase() === this.category?.toLowerCase()) {
      this.afterCancel.emit();

      return;
    }
    this.isLoading = true;
    const request = new RecategorizeTransactionRequest();
    request.id = !!this.transaction.transactionIdentifier
      ? this.transaction.transactionIdentifier
      : this.transaction.aggregatedTransactionId;
    request.transactionId = !!this.transaction.id ? this.transaction.id : this.transaction.yodleeTransactionId;
    request.olbCategory = newCategory.name;
    request.matchingTransactions = this.everySimilarTransaction;
    request.transactionDesc = this.transaction.simpleDescription
      ? this.transaction.simpleDescription
      : this.transaction.originalDescription
      ? this.transaction.originalDescription
      : this.transaction.description;
    request.previousOlbCategory = this.category;
    this.accountAggregationService
      .recategorizeTransaction(request)
      .then(() => {
        this.category = newCategory.name;
        this.transaction.olbCategoryName = newCategory.name;
        this.transaction.olbCategoryId = newCategory.id;
        this.afterSave.emit();
      })
      .catch(() => this.onError.emit())
      .finally(() => (this.isLoading = false));
  }

  markAsActive(category: string) {
    this.categories.forEach((c: Category) => {
      // just one category can be active at the time
      c.active = c.name.toLowerCase() === category.toLowerCase();
    });
  }

  categoryTrackBy(_, category: Category) {
    return category.id;
  }

  private setupCategory(categories: Category[]): Category[] {
    // svg name should come from server
    categories.forEach(c => {
      const name = c.name.toLowerCase();
      const idx = name.indexOf(' ');
      c.logoName = idx < 0 ? name : name.substring(0, idx);
      c.active = false;
    });

    return categories;
  }
}
