import { ISpousalConsentService } from './typings/ISpousalConsentService'
import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { SpousalConsent } from 'accounts/beneficiaries/typings/spousal-consent'

@Inject('$http', 'env', 'serviceHelper', '$q')
export class SpousalConsentService extends BaseService implements ISpousalConsentService {
    constructor(
        http: ng.IHttpService,
        env: OlbSettings,
        serviceHelper: IServiceHelper,
        q: ng.IQService
    ) {
        super(http, env, 'propertybag', serviceHelper, q);
    }

    create(request: SpousalConsent): ApiResponse<void> {
        return this.post('spousalconsent', request);
    }
}