<div class="stop-payment frow center">
  <div class="fcol-cs-12 fcol-sm-10" *ngIf="currentStep < 3">
    <div class="frow fcol-cs-12 container__btn-back">
      <a class="link finline middle" (click)="goBack()">
        <i class="bofi-arrow-chevron" aria-hidden="true"></i>
        <span>Back</span>
      </a>
    </div>
    <div class="frow fcol-cs-12">
      <img src="/img/icons/accounts/stop_payment.svg" width="100" alt="Stop Payment" aria-hidden="true" />
      <div class="fcol-cs-8 fcol-sm-10">
        <h2>Stop Payment</h2>
        <p>Stop a check or an ACH payment</p>
      </div>
      <hr class="fcol-cs-12" />
    </div>
    <!-- Start the stop payments' steps -->
    <div *ngIf="!isReadOnly" [ngSwitch]="currentStep" class="steps">
      <!-- Step 1: Choose Stop Type -->
      <div *ngSwitchCase="0">
        <h4 class="fcol-cs-12">What type of payment do you want to stop?</h4>
        <div class="frow fcol-cs-12 steps__content">
          <div class="frow" *ngIf="account?.canPlaceStops && [1, 2].indexOf(account.canPlaceStops) >= 0">
            <input type="radio" id="singleCheck" name="stopType" (click)="selectCheck()" />
            <label for="singleCheck">A single check</label>
          </div>
          <div class="frow" *ngIf="account?.canPlaceStops && [1, 3].indexOf(account.canPlaceStops) >= 0">
            <input type="radio" id="ach" name="stopType" (click)="selectAch()" />
            <label for="ach">An ACH Payment</label>
          </div>
        </div>
      </div>
      <!-- Step 2: Enter Stop info -->
      <div *ngSwitchCase="1">
        <!-- Series of checks -->
        <div *ngIf="stopType === 2">
          <h4 class="fcol-cs-12">
            Stop Series of Checks.
          </h4>
          <div class="steps__content">
            <p class="fcol-cs-12">
              If you need to put a stop on a series of checks. Please contact us and go to secure forms to download
              <a class="link text-bold">Stop Payment Request Form (#{{ formNumber }})</a>
              . Once you fill out the form you can securely send it to us and we will contact you shortly.
            </p>
            <div class="frow fcol-cs-12">
              <hr class="fcol-cs-12" />
            </div>
            <div class="frow end fcol-cs-12" *ngIf="!isBusy">
              <button type="button" class="uk-btn sm outline primary btn-cancel" (click)="goBack()">
                Cancel
              </button>
              <button type="button" class="uk-btn sm solid secondary" (click)="redirectToSecureForm()">
                Go To Secure Forms
              </button>
            </div>
          </div>
        </div>
        <!-- Single check & ACH -->
        <div *ngIf="stopType != 2">
          <h4 class="fcol-cs-12" *ngIf="stopType === 0">
            Enter the information about the check you want to place a stop payment on.
          </h4>
          <h4 class="fcol-cs-12" *ngIf="stopType === 1">
            Enter the information on the ACH payment you would like to stop. The Stop Payment is active for 6 months
            from the day you place the stop or until the first hit.
          </h4>
          <div class="steps__content">
            <form
              #stopPaymentForm="ngForm"
              (ngSubmit)="confirmStopPayment(stopPaymentForm)"
              name="stopPaymentForm"
              novalidate
            >
              <div class="frow fcol-cs-12 sp-group">
                <div
                  class="check-number fcol-cs-12 fcol-md-4"
                  *ngIf="stopType === 0"
                  [ngClass]="
                    (checkNumber.invalid || stopPayment.lowCheckNumber <= 0) &&
                    (stopPaymentForm.submitted || checkNumber.dirty)
                      ? 'has-error'
                      : ''
                  "
                >
                  <label for="check-number" class="uk-label">Check Number</label>
                  <input
                    #checkNumber="ngModel"
                    autofocus
                    type="text"
                    id="check-number"
                    name="checkNumber"
                    class="uk-input"
                    [(ngModel)]="stopPayment.lowCheckNumber"
                    maxlength="10"
                    pattern="^[0-9]*$"
                    required
                  />
                  <p
                    class="text-error"
                    *ngIf="
                      (checkNumber.invalid || stopPayment.lowCheckNumber <= 0) &&
                      (stopPaymentForm.submitted || checkNumber.dirty)
                    "
                  >
                    Please enter a valid check number
                  </p>
                </div>
                <div
                  class="amount fcol-cs-12 fcol-md-4"
                  [ngClass]="[stopType === 1 ? 'ach' : '', isInvalidAmount(amount) ? 'has-error' : '']"
                >
                  <label for="amount" class="uk-label">Amount</label>
                  <input
                    #amount="ngModel"
                    type="text"
                    id="amount"
                    name="amount"
                    class="uk-input"
                    maxlength="7"
                    required
                    prefix="$"
                    mask="separator.2"
                    thousandSeparator=","
                    [(ngModel)]="displayAmount"
                    (ngModelChange)="onValueAmount($event)"
                    (focusin)="resetAmountToDefault(amount)"
                    (blur)="resetAmountToDefault(amount)"
                  />
                  <p class="text-error" *ngIf="isInvalidAmount(amount)">
                    Please enter a valid amount
                  </p>
                </div>
                <div
                  class="date-input fcol-cs-12 fcol-md-4"
                  [ngClass]="[
                    stopType === 1 ? 'ach' : '',
                    date.invalid && (stopPaymentForm.submitted || date.dirty) ? 'has-error' : ''
                  ]"
                >
                  <i class="bofi-datepicker icon-inner"></i>
                  <label for="date" class="uk-label">
                    Date
                    <span *ngIf="stopType === 0">Written</span>
                    <span *ngIf="stopType === 1">of Expected Debit</span>
                  </label>
                  <input
                    #date="ngModel"
                    appDateRangePicker
                    type="text"
                    id="date"
                    name="date"
                    class="uk-input icon-inner"
                    [ngModel]="datePicker | date: 'MM/dd/YY'"
                    (ngModelChange)="datePicker = $event"
                    [options]="datePickerOptions"
                    (dateApplied)="onChangeDate(date, $event)"
                    required
                    readonly
                  />

                  <p class="text-error" *ngIf="date.invalid && (stopPaymentForm.submitted || date.dirty)">
                    Please enter a valid date
                  </p>
                </div>
                <div
                  class="payee fcol-cs-12"
                  [ngClass]="[
                    stopType === 1 ? 'ach' : '',
                    payeeName.invalid && (stopPaymentForm.submitted || payeeName.dirty) ? 'has-error' : ''
                  ]"
                >
                  <label for="payee" class="uk-label">
                    Payee
                    <span *ngIf="stopType === 1">/Debiting Company</span>
                  </label>
                  <input
                    autofocus
                    #payeeName="ngModel"
                    type="text"
                    id="payee"
                    name="payeeName"
                    class="uk-input"
                    [(ngModel)]="stopPayment.payeeDebitingCompany"
                    required
                  />
                  <p class="text-error" *ngIf="payeeName.invalid && (stopPaymentForm.submitted || payeeName.dirty)">
                    Please enter a valid payee
                  </p>
                </div>
              </div>
              <div class="frow between no-wrap middle-cs fcol-cs-12 sp-group">
                <i class="bofi-flag"></i>
                <p>
                  Keep in mind that we can't stop payment on
                  <span *ngIf="stopType === 0">
                    a check that's already been cashed or deposited
                  </span>
                  <span *ngIf="stopType === 1">
                    an ACH Payment that's already been debited
                  </span>
                  or is currently in process. Additionally, we may
                  <b>charge a fee</b>
                  for your stop payment request. We will inform you of the charges before you process the stop payment.
                </p>
              </div>
              <div class="frow fcol-cs-12">
                <hr class="fcol-cs-12" />
              </div>
              <div class="frow end fcol-cs-12" *ngIf="!isBusy">
                <button type="button" class="uk-btn sm outline primary" (click)="goBack()">
                  Cancel
                </button>
                <button type="submit" class="uk-btn sm solid secondary">Next</button>
              </div>
              <app-dot-loader [showWhen]="isBusy">Verifying...</app-dot-loader>
            </form>
          </div>
        </div>
      </div>
      <!-- Step 3: Confirmation -->
      <div *ngSwitchCase="2" class="steps__content">
        <div class="fcol-cs-12">
          <div class="frow item-list" *ngIf="stopType === 0">
            <label class="text-bold">Check Number:</label>
            <span>{{ stopPayment.lowCheckNumber }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">Amount:</label>
            <span>{{ stopPayment.amount | currency }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">
              Date
              <span *ngIf="stopType === 0">Written</span>
              <span *ngIf="stopType === 1">of Expected Debit</span>
              :
            </label>
            <span>{{ stopPayment.checkDate | date: 'MM/dd/yyyy' }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">Stop Payment will extend until:</label>
            <span class="text-warning">{{ stopPayment.expirationDate | date: 'MM/dd/yyyy' }}</span>
            <span class="hit-label" *ngIf="stopType === 1">(or until first hit)</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">
              Payee
              <span *ngIf="stopType === 1">/Debiting Company</span>
              :
            </label>
            <span>{{ stopPayment.payeeDebitingCompany }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">Account:</label>
            <span>{{ account.nickname }}</span>
          </div>
          <div class="frow item-list">
            <a class="link" (click)="currentStep = 1">
              <i class="bofi-edit"></i>
              Edit
              <span *ngIf="stopType === 0">Check</span>
              <span *ngIf="stopType === 1">ACH</span>
              Details
            </a>
          </div>
          <div class="frow no-wrap fee">
            <p class="fee__note">
              <span class="text-bold">NOTE:</span>
              There is a fee for
              <span>{{ stopType === 1 ? 'an ACH' : 'a' }}</span>
              Stop Payment. Click 'Confirm' to accept this fee and continue with the stop payment.
              <a href="{{ terms }}" target="_blank">View Terms</a>
            </p>
            <div class="finline middle fee__amount">
              <span class="text-bold">Stop Payment Fee:</span>
              <span class="fee__amount-amt">{{ fee | currency }}</span>
            </div>
          </div>
          <div class="frow">
            <hr class="fcol-cs-12" />
          </div>
          <div class="frow end" *ngIf="!isBusy">
            <button type="button" class="uk-btn sm outline primary" (click)="redirectToAccountsDetails(accountId)">
              Cancel
            </button>
            <button type="button" class="uk-btn sm solid secondary" (click)="placeStopPayment(false, true, 3)">
              Confirm
            </button>
          </div>
          <app-dot-loader [showWhen]="isBusy">Loading...</app-dot-loader>
        </div>
      </div>
    </div>
    <div *ngIf="isReadOnly" class="read-only">
      <h3>
        While our system is under maintenance you will be unable to create a new Stop Payment.
      </h3>
    </div>
  </div>
  <!-- Step 4: Success page -->
  <div *ngIf="currentStep == 3">
    <div class="sp-success">
      <div class="text-center sp-success__header">
        <i class="bofi-success"></i>
        <h2 class="sp-success__header-title">
          Success! Your stop payment has been successfully posted.
        </h2>
        <p [ngSwitch] on="isBusiness()">
          A confirmation email has been sent to
          <b *ngSwitchCase="true">{{ businessEmail }}</b>

          <b *ngSwitchDefault>{{ userEmail }}</b>
        </p>
      </div>
      <div class="frow sp-success__content">
        <div class="fcol-cs-12 fcol-sm-6">
          <div class="frow item-list">
            <label class="text-bold">Confirmation Number:</label>
            <span class="text-bold text-primary">{{ stopPayment.sequenceId }}</span>
          </div>
          <div class="frow item-list" *ngIf="stopType === 0">
            <label class="text-bold">Check Number:</label>
            <span>{{ stopPayment.lowCheckNumber }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">Amount:</label>
            <span>{{ stopPayment.amount | currency }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">
              Date
              <span *ngIf="stopType === 0">Written</span>
              <span *ngIf="stopType === 1">of Expected Debit</span>
              :
            </label>
            <span>{{ stopPayment.checkDate | date: 'MM/dd/yyyy' }}</span>
          </div>
        </div>
        <div class="fcol-cs-12 fcol-sm-6">
          <div class="frow item-list">
            <label class="text-bold">
              Payee
              <span *ngIf="stopType === 1">/Debiting Company</span>
              :
            </label>
            <span>{{ stopPayment.payeeDebitingCompany }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">Account:</label>
            <span>{{ account.nickname }}</span>
          </div>
          <div class="frow item-list">
            <label class="text-bold">Date Requested for Stop Payment:</label>
            <span>{{ stopPayment.requestedDate | date: 'MM/dd/yyyy' }}</span>
          </div>
        </div>
      </div>
      <div class="frow text-center sp-success__footer">
        <div class="fcol-cs-12">
          <button
            type="button"
            class="uk-btn lg solid secondary sp-success__footer-add"
            (click)="redirectToStopPayment(accountId)"
          >
            Return to Current Stop Payments
          </button>
        </div>
        <div class="fcol-cs-12">
          <a class="sp-success__footer-accounts" (click)="redirectToAccountsDetails(accountId)">
            Go to My Accounts
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
