<div class="income-grid container-shadow">
  <div class="income-title">
    <h2>Income</h2>
  </div>
  <div class="income-content">
    <div class="categories-grid-wrapper">
      <div class="categories-grid">
        <div
          class="category-row-item"
          *ngFor="let category of incomeGrid; trackBy: categoryTrackBy"
          [class.selected]="filters.categories.indexOf(category.id) >= 0"
        >
          <div class="category-tooltip font-weight-normal">
            <img
              alt=""
              style="filter: brightness(200);"
              src="assets/img/logos/categories/{{ category.imageFile }}"
            />
            : {{ category.amount | currency }}
            <div class="arrow">
              <app-svg-icon
                [iconName]="'utility-arrow-down'"
                [iconColor]="'#4c4c4c'"
                width="16px"
                height="13px"
              ></app-svg-icon>
            </div>
          </div>
          <div class="category-border" [ngStyle]="{ 'background-color': category.color }"></div>
          <div class="category-item" (click)="toggleSelectedCategory(category.id)">
            <div class="col-10">
              <div class="row category-text">
                <img
                  alt=""
                  class="category-image"
                  src="assets/img/logos/categories/{{
                    filters.categories.indexOf(category.id) >= 0
                      ? 'checked.svg'
                      : category.imageFile
                  }}"
                />
                <div class="category-name">{{ category.name }}</div>
              </div>
            </div>
            <div class="col-2">
              <div class="row category-percentage">
                <span>
                  {{ category.percentage != 0 ? category.percentage.toString() + '%' : '--' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="donut-chart-wrapper">
      <div class="donut-chart">
        <div class="absolute-center inside-donut">
          <span class="total-text">
            {{
              filters.categories.length ? 'CATEGORIES (' + filters.categories.length + ')' : 'TOTAL'
            }}
          </span>
          <div class="percentage mt-3 text-center">
            <app-svg-icon
              [iconName]="arrow"
              [iconColor]="'#2F5B88'"
              width="16px"
              height="13px"
            ></app-svg-icon>
            <span
              [style.font-size]="getPercentageChangeFontSize(percentageChange | percentageRound)"
              class="encode-sans"
              [textContent]="percentageChange | percentageRound"
            ></span>
            <span class="percentage-sign">%</span>
          </div>
          <span class="comparison mt-2">compared with last month</span>
        </div>
        <div class="absolute-center">
          <div class="canvas-chart" [class.d-none]="isEmpty">
            <app-donut-chart
              [height]="200"
              [width]="200"
              [chartjsOptions]="{ cutoutPercentage: 80 }"
              [filters]="filters"
              [categories]="incomeGrid"
              (toggleCategoryFilter)="toggleSelectedCategory($event.categoryId)"
            ></app-donut-chart>
          </div>
          <div class="canvas-chart" [class.d-none]="!isEmpty">
            <img src="assets/img/logos/charts/empty-income.svg" alt="" />
          </div>
          <div id="chart-tooltip">
            <table></table>
          </div>
        </div>
      </div>
      <div class="flex-space">
        <div class="income-flex income-flex-upper">
          <h2>{{ totalValueThisMonth | currency }}</h2>
          <h5>{{ currentMonthYear | date: 'MMM yyyy' }}</h5>
        </div>
        <div class="income-flex income-flex-upper">
          <h2>{{ totalValueLastMonth | currency }}</h2>
          <h5>Last Month</h5>
        </div>
      </div>
    </div>
  </div>
</div>
