import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-payment-detail-item',
  templateUrl: './payment-detail-item.component.html',
  styleUrls: ['./payment-detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentDetailItemComponent implements OnInit {
  constructor() {}
  
  ngOnInit() {}
}
