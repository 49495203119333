import { createAction, props } from '@ngrx/store';
import { UserPreferencesState } from './types';

export const updateUserPreferencesAction = createAction(
  '[User Preferences] UpdateUserPreferences',
  props<{ payload: Pick<UserPreferencesState, 'userPreferences'> }>()
);

export const updateApplicationPreferencesAction = createAction(
  '[Application Default Preferences] Update Application default preferences',
  props<{ payload: Pick<UserPreferencesState, 'defaultAppPreferences'> }>()
);
