import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { LibrarySection, SecureFormsLibrary } from '@app/secure-forms/models';
import { olbSettings } from '@core/tokens';
import { FileUploadComponent } from '@shared/components';
import { SupportFile } from '@shared/models';

import { UploadDocumentModalComponent } from '../upload-document-modal/upload-document-modal.component';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
})
export class UploadFormComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: FileUploadComponent;

  @Input() library: SecureFormsLibrary;
  @Input() sections: LibrarySection[];

  documents: SupportFile[] = [];
  totalMaxSize = 10485760; // 10mb
  uploadImage: string;
  submitSuccess: boolean;

  constructor(@Inject(olbSettings) private env: OlbSettings, private dialog: MatDialog) {}

  ngOnInit(): void {
    const { brand } = this.env;
    this.uploadImage = `assets/${brand}/secure-forms/icn_uploadphoto.svg`;
  }

  dismissSuccess() {
    this.submitSuccess = false;
  }

  prepareDocument(files: SupportFile[]) {
    this.submitSuccess = false;
    if (files.length > 0) {
      const dialogRef = this.dialog.open(UploadDocumentModalComponent, {
        data: {
          library: this.library,
          sections: this.sections,
          file: files[0],
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        this.fileUpload.cleanFiles();
        this.submitSuccess = result;
      });
    }
  }
}
