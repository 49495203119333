import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import {
  BrandingSettingsType,
  GetBrandingSettingsByOrganizationNameRequest,
  GetBrandingSettingsByOrganizationsNamesRequest,
  GetBrandingSettingsByOrganizationsNamesResponse,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class BrandingSettingsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/branding');
  }

  getBrandingSettingsByOrganizationName(
    request: GetBrandingSettingsByOrganizationNameRequest
  ): WebApiResponse<BrandingSettingsType> {
    const params = new HttpParams().set('includeSettings', request.includeSettings.toString());
    return this.get(`${request.organizationName}`, params);
  }

  getBrandsByOrganizationsNames(
    request: GetBrandingSettingsByOrganizationsNamesRequest
  ): WebApiResponse<GetBrandingSettingsByOrganizationsNamesResponse> {
    return this.post('names', request);
  }
}
