import { Inject } from '../../decorators/decorators';

@Inject('$uibModalInstance', '$interval', 'userInactivityTimeout')
export class TimeOutModalController {
  private _inactivityInterval: ng.IPromise<void>;

  constructor(
    private _uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private _interval: ng.IIntervalService,
    public userInactivityTimeout: number
  ) {}

  /** Initializes the controller. */
  $onInit(): void {
    this._inactivityInterval = this._interval(
      () => {
        this.userInactivityTimeout--;
      },
      1000,
      this.userInactivityTimeout
    );

    this._inactivityInterval
      .then(() => {
        this._uibModalInstance.dismiss('timeout');
      })
      .catch(reason => {
        if (reason !== 'canceled') this._uibModalInstance.dismiss('timeout');
      });
  }

  /** Stops the interval and closes the modal to continue browsing the site */
  ok(): void {
    this._interval.cancel(this._inactivityInterval);
    this._inactivityInterval = undefined;
    this._uibModalInstance.close('ok');
  }
}
