import { FrequencyEnum } from '@app/transfers/enums/axos-advisory-transfer-frequency.enum';
import { SchedulerTransferMappings } from '@app/transfers/utils/mappings';
import { GenericOption } from '@app/transfers/models';
export class SchedulerTransferUtils {
  static defaultOptions(): {
    fromOptions: GenericOption[];
    toOptions: GenericOption[];
    amountOptions: GenericOption[];
    frequencyOptions: GenericOption[];
    headerOptions: GenericOption[];
    daysOptions: GenericOption[];
  } {
    return {
      fromOptions: [{ value: 0, label: 'From: All accounts' }],
      toOptions: [{ value: 0, label: 'To: All accounts' }],
      amountOptions: [{ value: 0, label: 'All recurring amounts' }],
      frequencyOptions: [{ value: 0, label: 'All frequencies' }],
      headerOptions: [
        { value: 1, subValue: 'accountNameFrom', label: 'From account' },
        { value: 2, subValue: 'accountNameTo', label: 'To account' },
        { value: 3, subValue: 'frequency', label: 'Frequency' },
        { value: 4, subValue: 'sendUntil', label: 'Send until' },
        { value: 6, subValue: 'nextTransferDate', label: 'Next Transfer' },
        { value: 7, subValue: 'recurringAmount', label: 'Amount' },
      ],
      daysOptions: [
        { value: 1, label: 'Next Day' },
        { value: 7, label: 'Next Week' },
        { value: 14, label: 'Next 2 Weeks' },
        { value: 30, label: 'Next 30 Days' },
      ],
    };
  }
  static getCurrentDateTime(): string {
    let date = new Date();
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();
    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString();
    let second = date.getSeconds().toString();

    if (day.length == 1) {
      day = '0' + day;
    }
    if (month.length == 1) {
      month = '0' + month;
    }
    if (hour.length == 1) {
      hour = '0' + hour;
    }
    if (minute.length == 1) {
      minute = '0' + minute;
    }
    if (second.length == 1) {
      second = '0' + second;
    }

    // After this construct a string with the above results as below
    const time = day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second;

    return time;
  }

  static createAllFrequenciesGenericOptions(): GenericOption[] {
    return [
      { value: FrequencyEnum.ONETIME, label: SchedulerTransferMappings.mapFrequency(FrequencyEnum.ONETIME) },
      { value: FrequencyEnum.WEEKLY, label: SchedulerTransferMappings.mapFrequency(FrequencyEnum.WEEKLY) },
      {
        value: FrequencyEnum.EVERYTWOWEEKS,
        label: SchedulerTransferMappings.mapFrequency(FrequencyEnum.EVERYTWOWEEKS),
      },
      { value: FrequencyEnum.MONTHLY, label: SchedulerTransferMappings.mapFrequency(FrequencyEnum.MONTHLY) },
      { value: FrequencyEnum.QUARTERLY, label: SchedulerTransferMappings.mapFrequency(FrequencyEnum.QUARTERLY) },
      {
        value: FrequencyEnum.SEMIANNUALLY,
        label: SchedulerTransferMappings.mapFrequency(FrequencyEnum.SEMIANNUALLY),
      },
      { value: FrequencyEnum.ANNUALLY, label: SchedulerTransferMappings.mapFrequency(FrequencyEnum.ANNUALLY) },
    ];
  }
}
