import { Inject } from '../../../decorators/decorators';
import { Biller } from '../../typings/Biller';
import { IStateService } from 'angular-ui-router';
import { BillPayRouterHelper } from '../../bill-pay-router.helper';
import { PartialRecipient } from '../../typings/PartialRecipient';

@Inject('$state', 'billPayRouterHelper', 'loadUserProfileHelper')
export class ElectronicConfirmationController {
  biller: Biller;
  recipient: PartialRecipient;
  detailsTooltip: string;
  email: string;
  constructor(
    private readonly state: IStateService,
    private readonly billPayRouterHelper: BillPayRouterHelper,
    private readonly loadUserProfileHelper: ILoadUserProfileHelper
  ) {}
  $onInit(): void {
    this.biller = this.billPayRouterHelper.getBiller();
    this.recipient = this.billPayRouterHelper.getRecipient();
    const profile = this.loadUserProfileHelper.getUserProfile();
    this.email = profile ? profile.primaryEmail : '';
    if (!this.biller) this.state.go('udb.billPay.searchRecipient');

    this.detailsTooltip = `
        <strong>Electronic recipients</strong> 
        are paid instantly, We'll withdraw  the payment the day before your payment is due. Your recipient will receive your payment on its due date. 
        <br><br>
        <strong>Check recipients</strong>
        are paid with paper checks. We mail checks 5 business days before your payment's due date. Your money will leave your account when the recipient deposits the check.
        `;
  }
}
