import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TrustedInterestedModalUk2Module } from './trusted-interested-modal-uk2.module';
import { TrustedInterestedModalMatModule } from './trusted-interested-modal-mat.module';
import {
  InterestedPartyFormModalComponent,
  RemovePersonConfirmationModalComponent,
  TrustedContactFormModalComponent,
  TrustedInterestedModalComponent,
} from './view';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '@shared/shared.module';
@NgModule({
  declarations: [
    TrustedInterestedModalComponent,
    RemovePersonConfirmationModalComponent,
    TrustedContactFormModalComponent,
    InterestedPartyFormModalComponent,
  ],
  exports: [TrustedInterestedModalComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    TrustedInterestedModalUk2Module,
    TrustedInterestedModalMatModule,
    BrowserAnimationsModule,
    NgxMaskModule,
  ],
})
export class TrustedInterestedModalModule {}
