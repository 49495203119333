import { NgModule } from '@angular/core';
import { TodContingentBeneficiaryComponent } from './view/tod-contingent-beneficiary.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TodContingentBeneficiaryComponent],
  imports: [CommonModule],
  exports: [TodContingentBeneficiaryComponent],
})
export class TodContingentBeneficiaryModule {}
