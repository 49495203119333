import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { STATE_PARAMS } from '@core/tokens';
import { UrlQueryParams } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentViewStateService {
  currentStep = 1;
  hideLogin = new BehaviorSubject(false);
  isFullVersion: boolean;
  isLiteVersion: boolean;
  isProxyVersion = false;
  loginFinished = new BehaviorSubject(false);
  urlQueryParams = new UrlQueryParams();
  steps: number[];

  private liteToken: string;

  constructor(@Inject(STATE_PARAMS) public routeParams: ng.ui.IStateParamsService) {
    this.isLiteVersion = this.routeParams.version === 'lite';
    this.isProxyVersion = this.routeParams.version === 'proxy';
    this.isFullVersion = !this.isLiteVersion && !this.isProxyVersion;
    this.steps = this.isLiteVersion || this.isProxyVersion ? [1, 2, 2.5, 3] : [1, 2, 2.5, 3, 4];
  }

  get comesFromEnrollment() {
    return !this.urlQueryParams.has('freg') && !this.urlQueryParams.has('redirect');
  }

  get comesFromFrg() {
    return this.urlQueryParams.has('freg');
  }

  get regId() {
    return `${this.routeParams.id}`;
  }

  get isLite() {
    return this.routeParams.version === 'lite';
  }

  get isProxy() {
    return this.routeParams.version === 'proxy' || this.urlQueryParams.has('proxy');
  }

  get isFull() {
    return !this.isLite && !this.isProxy;
  }

  get viewSteps() {
    return this.isLite || this.isProxy ? [1, 2, 2.5, 3] : [1, 2, 2.5, 3, 4];
  }

  get authLiteToken() {
    return this.liteToken;
  }

  set authLiteToken(token: string) {
    this.liteToken = token;
  }
}
