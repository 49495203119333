import { Action, createReducer, on } from '@ngrx/store';

import { PortfolioAllocationState, initialPortfolioAllocationState } from '../../state/types';
import { addUpdatePortfolioAllocationAccountAction } from '../actions';

const reducer = createReducer(
  initialPortfolioAllocationState,
  on(addUpdatePortfolioAllocationAccountAction, (_state, action) => {
    const indexOfUpdated = _state.porfolioAllocations.findIndex(m => m.accountNumber === action.payload.accountNumber);

    if (indexOfUpdated >= 0) {
      return {
        porfolioAllocations: _state.porfolioAllocations.map(item => {
          return item.accountNumber === action.payload.accountNumber ? (item = action.payload) : item;
        }),
        selectedAccountPorfolioAllocations: action.payload.accountNumber,
      };
    } else {
      return {
        porfolioAllocations: [..._state.porfolioAllocations, action.payload],
        selectedAccountPorfolioAllocations: action.payload.accountNumber,
      };
    }
  })
);

export function portfolioAllocationReducer(state: PortfolioAllocationState, action: Action) {
  return reducer(state, action);
}
