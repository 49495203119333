import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthorizeTransferAccount } from '../../core/types';

@Component({
  selector: 'app-authorize-transfer-toggle',
  templateUrl: './authorize-transfer-toggle.component.html',
  styleUrls: ['./authorize-transfer-toggle.component.scss'],
})
export class AuthorizeTransferToggleComponent {
  @Input() account: AuthorizeTransferAccount;

  @Output() accountChanges = new EventEmitter<AuthorizeTransferAccount>();

  authorizeAllAdvisors = false;
  panelOpenState = false;

  updateAllAuthorized() {
    this.authorizeAllAdvisors = this.account.advisors.every(t => t.authorizeTransfer);
    this.accountChanges.emit(this.account);
  }

  authorizeSome(): boolean {
    return this.account.advisors.filter(t => t.authorizeTransfer).length > 0 && !this.authorizeAllAdvisors;
  }

  authorizeAll(authorizeTransfer: boolean) {
    this.authorizeAllAdvisors = authorizeTransfer;
    this.account.authorizeTransfer = this.authorizeAllAdvisors;
    this.account.advisors.forEach(t => (t.authorizeTransfer = authorizeTransfer));
    this.accountChanges.emit(this.account);
  }

  openPanel($event: any) {
    if (this.panelOpenState) {
      $event.stopPropagation();
    } else {
      this.authorizeAll(true);
    }
  }
}
