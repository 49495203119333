import * as angular from 'angular';
import 'angular-ui-bootstrap';
import { downgradeComponent } from '@angular/upgrade/static';
import { HistoryComponent } from '@app/transfers/components';

export const HistoryLegacyModule = angular
  .module('udb.history', ['ui.router', 'ui.bootstrap'])
  .directive('appHistory', downgradeComponent({ component: HistoryComponent }))
  .config([
    '$stateProvider',
    (stateProvider: angular.ui.IStateProvider) => {
      stateProvider.state('udb.transfers.p2p.history', {
        url: '/history',
        views: {
          'p2p-content': {
            template: '<app-history></app-history>',
          },
        },
      });
    },
  ]).name;
