import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, take } from 'rxjs/operators';

import { PortfolioDetails, PortfolioInfo } from '@app/axos-invest/models';
import { AxosInvestService } from '@app/axos-invest/services';

@Component({
  selector: 'app-portfolio-allocation-slider',
  templateUrl: './portfolio-allocation-slider.component.html',
  styleUrls: ['./portfolio-allocation-slider.component.scss'],
})
export class PortfolioAllocationSliderComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  control = new UntypedFormControl();

  get currentStep(): number {
    return Number(this.control.value);
  }
  get allocationSliderInput(): HTMLInputElement {
    // fixes some issues with the input:range
    return this.element.nativeElement.querySelector<HTMLInputElement>('.allocation-slider');
  }

  riskModel: PortfolioInfo[];
  relativeRiskModel: PortfolioInfo[];
  availableSteps: number;
  percentage: string;
  buildingSlider = true;
  isAllCash: boolean;

  constructor(private investService: AxosInvestService, private element: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.form
      .get('cashAllocation')
      .valueChanges.pipe(debounceTime(750))
      .subscribe(() => this.onChangeCashAllocation());
    this.control.valueChanges.subscribe(() => this.onChangeSliderInput());
    this.investService
      .getRiskScore()
      .pipe(take(1))
      .subscribe(result => {
        this.riskModel = Object.entries(result.data.portfolios).map(key => key[1]);
        this.buildModelIntoSlider();
        this.setSliderPercentage();
        const timeout = this.isAllCash ? 100 : 0;
        setTimeout(() => {
          this.buildingSlider = false;
          this.allocationSliderInput.disabled = this.isAllCash;
        }, timeout);
      });
  }

  private onChangeSliderInput() {
    this.changeSelectedAllocation();
  }

  private onChangeCashAllocation() {
    this.buildingSlider = true;
    this.buildModelIntoSlider();
    setTimeout(() => {
      this.allocationSliderInput.value = this.currentStep.toString();
      this.allocationSliderInput.disabled = this.isAllCash;
      this.buildingSlider = false;
    }, 500);
  }

  private changeSelectedAllocation(): void {
    this.setSliderPercentage();
    const score = this.relativeRiskModel[this.currentStep - 1];
    this.form.get('bondAllocation').setValue(score.targetBondRate);
    this.form.get('stockAllocation').setValue(score.targetStockRate);
    this.form.get('allocationScore').setValue(score.allocationScore);
  }

  private setSliderPercentage(): void {
    const percentage = Math.round(((this.currentStep - 1) / (this.availableSteps - 1)) * 100);
    this.percentage = (isNaN(percentage) ? 0 : percentage) + '%';
  }

  private fixNumber(value: number): number {
    return Number(value.toFixed());
  }

  private buildModelIntoSlider(): void {
    this.createRelativeRiskModel();
    this.availableSteps = this.relativeRiskModel.length;
    const currentAllocationScore = this.form.get('allocationScore').value;
    let index = this.relativeRiskModel.findIndex(r => r.allocationScore === currentAllocationScore);
    if (index < 0) {
      // if score does not exist in the relative model, find one that has the same percentages
      index = this.findSimilarScore();
      if (index < 0) {
        // if no score has the same percentages, then find the nearest score
        index = this.findNearestScore(currentAllocationScore);
      }
    }
    this.control.setValue(index + 1);
  }

  private createRelativeRiskModel() {
    const cashAllocation = this.form.get('cashAllocation').value;

    const allCashScore = this.riskModel.find(t => t.allocationScore === '0.0');
    this.isAllCash = cashAllocation >= 100;

    if (this.isAllCash) {
      this.relativeRiskModel = [{}, allCashScore, {}];
    } else {
      const totalAllocationValue = 100 - cashAllocation;
      this.relativeRiskModel = [];

      const indexOfAllCashScore = this.riskModel.indexOf(allCashScore);

      const copyOfRiskModel = [...this.riskModel];
      copyOfRiskModel.splice(indexOfAllCashScore, 1);

      copyOfRiskModel.forEach(score => {
        const relativeScore: PortfolioInfo = {
          targetBondRate: this.fixNumber(totalAllocationValue * score.targetBondRate),
          targetStockRate: this.fixNumber(totalAllocationValue * score.targetStockRate),
          allocationScore: score.allocationScore,
          assetClasses: null,
        };

        const existsInModel = this.relativeRiskModel.some(
          s => s.targetBondRate === relativeScore.targetBondRate && s.targetStockRate === relativeScore.targetStockRate
        );

        if (!existsInModel) this.relativeRiskModel.push(relativeScore);
      });
    }
  }

  private findSimilarScore() {
    const details = this.form.value as PortfolioDetails;

    return this.relativeRiskModel.findIndex(
      score => score.targetBondRate === details.bondAllocation && score.targetStockRate === details.stockAllocation
    );
  }

  private findNearestScore(allocationScore: string) {
    const allocationScoreDifference = this.relativeRiskModel.map(
      score => Number(score.allocationScore) - Number(allocationScore)
    );
    const minorPositiveNumber = Math.min(...allocationScoreDifference.filter(n => n >= 0));
    const majorNegativeNumber = Math.max(...allocationScoreDifference.filter(n => n < 0));
    const closestNumber =
      minorPositiveNumber < Math.abs(majorNegativeNumber) ? minorPositiveNumber : majorNegativeNumber;

    return allocationScoreDifference.findIndex(t => t === closestNumber);
  }
}
