import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceHelper } from '@legacy/services/service.helper';

import { FundingMethod } from 'typings/app/FundingMethod';

@Component({
  selector: 'app-funding-method',
  templateUrl: './funding-method.component.html',
  styleUrls: ['./funding-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundingMethodComponent implements OnInit {
  @Input() imgSrc: string;
  @Input() btnText: string;
  @Input() name: string;
  @Input() description: string;
  @Input() directions: string;
  @Input() isBusy: boolean;
  @Output() onFunding = new EventEmitter<any>();

  imageSource: any;
  fundingMethod: FundingMethod;
  constructor(private serviceHelper: ServiceHelper) {}

  ngOnInit() {
    !this.btnText && (this.btnText = 'Continue');
    try {
      this.imageSource = `/img/icons/tiles/${this.imgSrc}`;
    } catch {}
  }

  onClick() {
    this.onFunding.emit(this.fundingMethod);
    this.serviceHelper.scrollToTop();
  }
}
