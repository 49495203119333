import * as angular from 'angular';
import { MessagesConfig } from '../messages/messages.config';
import { MessagesController } from '../messages/messages.controller';
import { inboxLegacyModule } from './inbox.legacy.module';
import { composeMessageLegacyModule } from './compose-message.legacy.module';
import { messageDetailsLegacyModule } from './message-details.legacy.module';
import { MessagesComponent } from '@app/messages/components';
import { downgradeComponent } from '@angular/upgrade/static';
export const messagesLegacyModule = angular
  .module('udb.messages', ['ui.router', inboxLegacyModule, composeMessageLegacyModule, messageDetailsLegacyModule])
  .directive('appMessagesComponent', downgradeComponent({ component: MessagesComponent }))
  .controller('MessagesController', MessagesController)
  .config(MessagesConfig).name;
