<table class="table">
  <thead>
    <tr class="table-head-row">
      <th class="description-column" (click)="sortBy('description')">
        Description
        <i
          class="fa"
          *ngIf="lastSortedColumn === 'description-goal'"
          [class.fa-caret-down]="!isAscendent"
          [class.fa-caret-up]="isAscendent"
        ></i>
      </th>
      <th class="amount-column" (click)="sortBy('amount')">
        Amount
        <i
          class="fa"
          *ngIf="lastSortedColumn === 'amount'"
          [class.fa-caret-down]="!isAscendent"
          [class.fa-caret-up]="isAscendent"
        ></i>
      </th>
      <th class="action-column" >
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="transactions?.length > 0; else noTransactions">
      <tr
        app-transactions-orders-row
        *ngFor="let transaction of transactions"
        [transaction]="transaction"
        [class]="transaction.category"
        [accountId]="accountId"
      ></tr>
    </ng-container>
    <ng-template #noTransactions>
      <tr class="no-transactions-row">
        <td colspan="5">
          <h3 class="text-center m-t-lg">
            No transactions meet your search criteria
          </h3>
        </td>
      </tr>
    </ng-template>
  </tbody>
</table>
