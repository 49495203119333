<div>
  <div *ngIf="transaction.security" class="detail-container-column">
    <b>Security</b>
    {{ transaction.security }}
  </div>
  <div *ngIf="transaction.category" class="detail-container-column">
    <b>Model</b>
    {{ transaction.category }}
  </div>
  <div *ngIf="transaction.date" class="detail-container-column">
    <b>Trade Date</b>
    {{ transaction.date | date: 'MM/dd/yyyy' }}
  </div>
  <div *ngIf="transaction.transactionId" class="detail-container-column">
    <b>Transaction ID</b>
    {{ transaction.transactionId }}
  </div>
  <div *ngIf="transaction.quantity" class="detail-container-column">
    <b>Quantity</b>
    {{ transaction.quantity }}
  </div>
  <div *ngIf="transaction.pricePerShare" class="detail-container-column">
    <b>Price Per Share</b>
    {{ transaction.pricePerShare | currency }}
  </div>
  <div *ngIf="transaction.cash" class="detail-container-column">
    <b>Cash</b>
    ({{ transaction.cash | currency }})
  </div>
  <div *ngIf="transaction.otherFees" class="detail-container-column">
    <b>Other Fees*</b>
    {{ transaction.otherFees | currency }}
  </div>
  <div *ngIf="transaction.amount" class="detail-container-column">
    <b>Total Amount</b>
    ({{ transaction.amount | currency }})
  </div>
  <div *ngIf="transaction.costBasis" class="detail-container-column">
    <b>Cost Basis</b>
    {{ transaction.costBasis | currency }}
  </div>
  <div *ngIf="transaction.accountId" class="detail-container-column">
    <b>Account ID</b>
    {{ transaction.accountId }}
  </div>
  <div *ngIf="transaction.comments" class="detail-container-column">
    <b>Comments</b>
    {{ transaction.comments }}
  </div>
  <div class="footer-message">*Section 31 SEC or short-term redemption fee</div>
</div>
