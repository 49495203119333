<h3 class="mt-5 mb-4">How do I set up automatic e-Bill payments?</h3>
<ol>
  <li>
    Click on the
    <b>Pay Bills tab.</b>
  </li>
  <li>
    After you set up the payee, click on
    <b>Enroll in eBills</b>
    for this payee.
  </li>
  <li>
    Select the Biller Site and click
    <b>Continue.</b>
  </li>
  <li>
    Enter your Login ID and password for your account with the payee and click
    <b>Login.</b>
  </li>
  <li>
    Select the Account that you want to use and click
    <b>Continue.</b>
  </li>
  <li>You can then select to make automatic payments.</li>
  <li>
    Select the account that you want to pay from. You need to select the payment amount which can be
    either the minimum payment or current balance.
  </li>
  <li>
    You can also select an optional
    <b>Amount Limit</b>
    .
  </li>
  <li>
    You then need to select the payment date which can be either the date that the bill arrives or
    on the payment due date.
  </li>
  <li>
    Select any alerts or notifications that you want to receive for the payment. When complete,
    select Set Up.
  </li>
</ol>
