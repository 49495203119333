/**
 * Formats a phone number based on the number of characters it has.
 *
 * @param phone Phone number to format.
 * @param allowAnyCharacter Specifies if any digit should be allowed.
 * @returns A formated phone string.
 *
 * @example
 * For 10 characters: +1PPP####### -> C (PPP) ###-####
 * For 11 characters: +CPPP######## -> C (PPP) ###-####
 * For 12 characters: +CCCPP######## -> CCC (PP) ###-####
 * Otherwise: phone as-is
 */
export function formatPhone(phone: string | null, allowAnyCharacter = false) {
  if (!phone || phone === '0') {
    return '';
  }

  const trimmedPhone = phone.trim().replace(/^\+/, '');

  if (/[^0-9]/.test(trimmedPhone) && !allowAnyCharacter) {
    return trimmedPhone;
  }

  let country = '';
  let city = '';
  let phoneNumber = '';

  switch (trimmedPhone.length) {
    case 10:
      [country, city, phoneNumber] = splitPhoneNumber(trimmedPhone);
      break;
    case 11:
      [country, city, phoneNumber] = splitPhoneNumber(trimmedPhone, 1);
      break;
    case 12:
      [country, city, phoneNumber] = splitPhoneNumber(trimmedPhone, 3, 2);
      break;
    default:
      return trimmedPhone;
  }
  phoneNumber = `${phoneNumber.slice(0, 3)} - ${phoneNumber.slice(3)}`;

  return `${country} (${city}) ${phoneNumber}`.trim();
}

/**
 * Splits a phone number based on the given parameters.
 * @param phone Phone number string to use.
 * @param countryCodeDigits Specifies how many digits are for coutnry code.
 * @param cityDigits Specifies how many digits are for city code.
 * @returns An array containing country, city and phone number splitted.
 */
function splitPhoneNumber(phone: string, countryCodeDigits = 0, cityDigits = 3): [string, string, string] {
  const cityCodeEndIndex = countryCodeDigits + cityDigits;
  let country = '';
  let city = '';
  let phoneNumber = '';

  city = phone.slice(countryCodeDigits, cityCodeEndIndex);
  phoneNumber = phone.slice(cityCodeEndIndex);

  if (countryCodeDigits > 0) {
    country = phone.slice(0, countryCodeDigits);
  }

  return [country, city, phoneNumber];
}
