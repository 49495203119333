import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  Uk2ModalActionsGridEnum,
  Uk2ModalComponent,
  Uk2ModalFontWeightEnum,
  Uk2ModalTitleAlignEnum,
} from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-understanding-model-modal',
  templateUrl: './understanding-models-modal.component.html',
  styleUrls: ['./understanding-models-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnderstandingModelsModalComponent implements OnChanges {
  @Input() showUnderstandingModelsModal = false;
  @Output() hideUnderstandingModelsModal = new EventEmitter();

  @ViewChild('modal') modal?: Uk2ModalComponent;

  showCloseButton = true;
  titleAlign = Uk2ModalTitleAlignEnum.center;
  title = 'Understanding Models';
  disabledCloseOverlayBackdrop = false;
  titleFontWeight = Uk2ModalFontWeightEnum.bold300;
  actionsGrid = Uk2ModalActionsGridEnum.side;
  innerPanelClass = 'uk2-modal-dialog-container';

  indicationsList = [
    `Percentage of portfolio allocated to a model. Excludes cash if unallocated to a model.`,
    `Model name and its total value`,
    `Today’s value movement of a model`,
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.showUnderstandingModelsModal.firstChange &&
      changes.showUnderstandingModelsModal.currentValue === true
    ) {
      this.modal?.openDialog();
    }
  }

  closeModal() {
    this.hideUnderstandingModelsModal.emit();
  }
}
