import { IQService } from 'angular';
import { FacingBrand } from 'models';
import { SettingsService } from 'services/settings.service';
import { IAxosClearingService } from 'services/typings/IAxosClearingService';

import {
  AuthService,
  ConfigurationService,
  FundingService,
  SessionService,
} from '@core/services';

import { Inject } from '../decorators/decorators';
import { AppSettingsService } from '../services/app-settings.service';
import { IEnrollmentService } from '../services/typings/IEnrollmentService';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { LayoutController } from './layout.controller';

@Inject('$stateProvider', '$urlRouterProvider', '$locationProvider')
export class LayoutConfig {
  constructor(
    stateProvider: ng.ui.IStateProvider,
    urlRouterProvider: ng.ui.IUrlRouterProvider,
    _location: ng.ILocationProvider
  ) {
    stateProvider.state('udb', {
      abstract: true,
      views: {
        root: {
          templateUrl: 'layout/layout.tpl.html',
          controller: LayoutController,
          controllerAs: 'vm',
        },
      },
      resolve: {
        appSettings: [
          '$q',
          '$rootScope',
          ConfigurationService.$token,
          (
            $q: IQService,
            $rootScope: ng.IRootScopeService,
            configurationService: ConfigurationService
          ) => {
            const deferred = $q.defer();

            configurationService.getSettings().subscribe(
              settings => {
                $rootScope['appSettings'] = settings;
                deferred.resolve(settings);
              },
              () => {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
        ],
        featureFlags: [
          '$q',
          'settingsService',
          ($q: IQService, settingService: SettingsService) => {
            const deferred = $q.defer();

            settingService.getFeatureFlags().subscribe({
              next: () => deferred.resolve(),
              error: () => deferred.resolve(),
            });
          },
        ],
        lastLoginDate: [
          '$q',
          'authService',
          ($q: IQService, authService: AuthService) => {
            const deferred = $q.defer();
            const loginDate = localStorage.getItem('lastLogin');

            if (!loginDate || loginDate === 'null') {
              authService.getUserLastLoginDate().subscribe({
                next: res => {
                  localStorage.setItem('lastLogin', res.data);
                  deferred.resolve([]);
                },
                error: () => {
                  deferred.resolve([]);
                },
              });
            } else {
              deferred.resolve([]);
            }

            return deferred.promise;
          },
        ],
        isCustomer: [
          '$q',
          '$window',
          'cookieHelper',
          'sessionService',
          'enrollmentService',
          'serviceHelper',
          '$location',
          'authService',
          'env',
          'axosClearingService',
          'fundingService',
          (
            $q: IQService,
            $window: ng.IWindowService,
            cookieHelper: CookieHelper,
            sessionService: SessionService,
            enrollmentService: IEnrollmentService,
            serviceHelper: IServiceHelper,
            location: ng.ILocationService,
            authService: AuthService,
            env: OlbSettings,
            axosClearingService: IAxosClearingService,
            fundingService: FundingService
          ): any => {
            const deferred = $q.defer();

            // 1. Check for source query string
            const source = location.search().source
              ? location.search().source.toLowerCase()
              : null;

            const referrer = location.search().referrer;

            if (source || referrer) {
              // 2. If user is coming from another application (e.g. Enrollment) with a valid token, refresh cookie
              authService.refreshToken().subscribe(() => {
                if (source) {
                  IsUserEnrolledOnOLB(
                    source,
                    cookieHelper,
                    enrollmentService,
                    serviceHelper,
                    sessionService,
                    $window,
                    deferred,
                    env,
                    axosClearingService,
                    fundingService
                  );
                } else {
                  handleSsoAutoRegister(
                    source,
                    cookieHelper,
                    enrollmentService,
                    serviceHelper,
                    $window,
                    deferred,
                    env,
                    axosClearingService,
                    fundingService
                  );
                }
              });
            } else {
              if (sessionStorage.getItem('fundingTerms') === 'enrollment') {
                handleSsoAutoRegister(
                  source,
                  cookieHelper,
                  enrollmentService,
                  serviceHelper,
                  $window,
                  deferred,
                  env,
                  axosClearingService,
                  fundingService
                );
              } else {
                IsUserEnrolledOnOLB(
                  source,
                  cookieHelper,
                  enrollmentService,
                  serviceHelper,
                  sessionService,
                  $window,
                  deferred,
                  env,
                  axosClearingService,
                  fundingService
                );
              }
            }

            return deferred.promise;
          },
        ],
        isDebitCardsFeatureActive: [
          '$q',
          '$rootScope',
          'appSettingsService',
          (
            $q: IQService,
            root: ng.IRootScopeService,
            appSettingsService: AppSettingsService
          ) => {
            const deferred = $q.defer();
            if (!root['DebitCardsFeatureFlag']) {
              appSettingsService
                .isFeatureEnabled('DebitCardsFeatureFlag')
                .subscribe(
                  res => {
                    root['DebitCardsFeatureFlag'] = res;
                    deferred.resolve();
                  },
                  () => {
                    root['DebitCardsFeatureFlag'] = false;
                    deferred.resolve();
                  }
                );
            }

            return deferred.promise;
          },
        ],
      },
    });

    const accountsToBeFunded: OlbAccount[] = JSON.parse(
      sessionStorage.getItem('accountsToBeFunded')
    );

    const route =
      accountsToBeFunded && accountsToBeFunded.length
        ? 'udb.funding'
        : 'udb.dashboard';

    function goAheadFunction($injector: any) {
      const $state = $injector.get('$state');
      localStorage.setItem('goAhead', 'AxosInvest');
      $state.go('udb.dashboard');
    }
    goAheadFunction.$inject = ['$injector'];

    function otherwiseFunction($injector: any) {
      const $state = $injector.get('$state');
      $state.go(route);
    }
    otherwiseFunction.$inject = ['$injector'];

    urlRouterProvider
      .when('/Redirect/Invest', goAheadFunction)
      .otherwise(otherwiseFunction);

    function IsUserEnrolledOnOLB(
      source: string,
      cookieHelper: CookieHelper,
      enrollmentService: IEnrollmentService,
      serviceHelper: IServiceHelper,
      sessionService: SessionService,
      $window: ng.IWindowService,
      deferred: ng.IDeferred<any>,
      env: OlbSettings,
      axosClearingService: IAxosClearingService,
      fundingService: FundingService
    ): void {
      enrollmentService
        .hasUserAcceptedTermsAndConditions()
        .then(({ data }) => {
          if (data) {
            handleSsoAutoRegister(
              source,
              cookieHelper,
              enrollmentService,
              serviceHelper,
              $window,
              deferred,
              env,
              axosClearingService,
              fundingService
            );
          } else {
            sessionStorage.setItem('fundingTerms', 'enrollment');
            $window.location.href = '/auth/TermsAndConditions';
          }
        })
        .catch(() => sessionService.endSession(true, false));
    }

    function handleSsoAutoRegister(
      source: string,
      cookieHelper: CookieHelper,
      enrollmentService: IEnrollmentService,
      serviceHelper: IServiceHelper,
      $window: ng.IWindowService,
      deferred: ng.IDeferred<any>,
      env: OlbSettings,
      axosClearingService: IAxosClearingService,
      fundingService: FundingService
    ): void {
      const userCIF = cookieHelper.getUserCIF();
      const isFromEnrollment = source === 'enrollment' ? true : false;
      let hasAccountsToBeFunded = false;
      source = source || sessionStorage.getItem('fundingTerms');
      if (userCIF) {
        // Register the user, sync accounts, and save them to session storage (if funding)
        enrollmentService
          .ssoAutoRegister(cookieHelper.getUserId(), !!source, isFromEnrollment)
          .then(async ({ data }) => {
            sessionStorage.setItem('username', cookieHelper.getUserUsername());
            if (data.telephoneNumber) {
              sessionStorage.setItem(
                'maskedCellPhone',
                `(***) ***-${data.telephoneNumber}`
              );
            }
            if (data.email) {
              sessionStorage.setItem('maskedEmail', data.email);
            }

            const fundingAccountsRequest = await fundingService
              .getAccounts()
              .toPromise();
            const accounts = fundingAccountsRequest.data;

            // TODO: Move accountsToBeFunded to new controller
            const tradingAccounts: OlbAccount[] = axosClearingService.isAxosTradingActiveForUser()
              ? (await axosClearingService.getAccounts()).data
              : [];

            let accountsToBeFundedFilter =
              data.accountSyncSummary &&
              data.accountSyncSummary.accountsAdded &&
              data.accountSyncSummary.accountsAdded.filter(
                account =>
                  account.isDeposit &&
                  (!account.isIra ||
                    (account.productType.toLowerCase() === 'cd' &&
                      account.needFundsValidation &&
                      account.amount === 0)) // cd account type are considered as IsIra
              );

            const cdAccountsType =
              accountsToBeFundedFilter &&
              accountsToBeFundedFilter.filter(
                a => a.productType.toLowerCase() === 'cd'
              );

            if (cdAccountsType && cdAccountsType.length) {
              const acc = cdAccountsType[0];
              accountsToBeFundedFilter = [acc];
            }

            accountsToBeFundedFilter =
              accountsToBeFundedFilter && tradingAccounts
                ? accountsToBeFundedFilter.concat(
                    tradingAccounts
                      .filter(f => f.needFundsValidation && f.withoutFunds)
                      .map(t => {
                        if (t.accountNumber) {
                          t.nickname = `${
                            t.nickname
                          } **${t.accountNumber.substr(
                            t.accountNumber.length - 4
                          )}`;
                        }

                        return t;
                      })
                  )
                : accountsToBeFundedFilter;
            if (
              (cdAccountsType && cdAccountsType.length) ||
              (accountsToBeFundedFilter && accountsToBeFundedFilter.length)
            ) {
              hasAccountsToBeFunded = true;
              sessionStorage.setItem(
                'accountsToBeFunded',
                JSON.stringify(accountsToBeFundedFilter)
              );
            }

            // Set user specific features settings
            sessionStorage.setItem(
              'hasAxosInvest',
              data.hasAxosInvest.toString()
            );

            // 4. Redirect user to corresponding page (funding or dashboard)
            if (source && source === 'enrollment') {
              if (
                hasAccountsToBeFunded &&
                env.facingBrandId !== FacingBrand.UFB
              ) {
                sessionStorage.removeItem('fundingTerms');
                sessionStorage.setItem('fromEnrollment', 'true');
                $window.location.href = '/Funding';
              }
              if (accounts && accounts.length > 0) {
                sessionStorage.removeItem('fundingTerms');
                sessionStorage.setItem('fromEnrollment', 'true');
                sessionStorage.setItem('fundingRemembered', 'true');
                sessionStorage.setItem(
                  'fundingAccounts',
                  JSON.stringify(accounts)
                );
                $window.location.href = '/FundingGoal'; // TODO: implement different routes
              }
            }

            deferred.resolve([]);
          })
          .catch(err => {
            serviceHelper.errorHandler(err);
            deferred.resolve([]);
          });
      } else {
        deferred.resolve([]);
      }
    }
  }
}
