<div class="input-filter">
  <label class="uk-label" *ngIf="inputLabel" for="input-filter">{{ inputLabel }}</label>
  <input
    [type]="onlyNumbers ? 'number' : 'text'"
    [placeholder]="inputPlaceholder"
    aria-label="filter"
    [class]="'uk-input input-filter-input ' + iconPlaceholder"
    name="input-filter"
    [formControl]="control"
  />
</div>
