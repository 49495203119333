<h3 class="mt-5 mb-4">How do I set up a payee?</h3>
<h5 class="mb-4">Paying online is easy. First you’ll need to set up Bill Pay.</h5>
<ol>
  <li>
    Click on the
    <b>Pay Bills tab</b>
  </li>
  <li>
    For your first bill payment, click on
    <b>Set Up Your First Payment.</b>
    To set up subsequent payees, click on
    <b>Add New Payee.</b>
  </li>
  <li>
    Add a Payee by entering the company or person you wish to pay or select from a list of our most
    popular payees.
  </li>
  <li>
    Add your account number and any other required payee information. You can also add a nickname
    for the account.
  </li>
  <li>
    Click
    <b>Continue.</b>
  </li>
  <li>
    Please select the correct address for the payee you are setting up and then click
    <b>Finish.</b>
  </li>
</ol>
