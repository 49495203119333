<h3 class="mt-5 mb-4">How do I access my transaction history?</h3>

<ol>
  <li>
    Click on the
    <b>Accounts tab</b>
    or the
    <b>Recent Transactions</b>
    tile.
  </li>
  <li>Click on the blue name of the account you want to see.</li>
  <li>
    Make sure the
    <b>Transactions tab</b>
    is selected and you will see a list of transactions.
  </li>
</ol>

<p>You will also be able to search transactions on this page.</p>
