import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-ira-contribution',
  templateUrl: './ira-contribution.component.html',
  styleUrls: ['./ira-contribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IraContributionComponent implements OnInit {
  backgroundUrl: string;
  closeIcon = NavigationIcons.Ex;
  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    private dialogRef: MatDialogRef<IraContributionComponent>
  ) { }

  ngOnInit(): void {
    const { brand } = this.settings;

    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
