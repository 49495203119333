import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { IRootScopeService } from 'angular';
import { MilestoneDetailVm } from 'typings/app/wise-banyan/Milestone';

import { loadInvestAccounts } from '@app/accounts/store/invest-accounts/invest-accounts.actions';
import { InvestAccount } from '@app/axos-invest/models';

import { Inject } from '../../decorators/decorators';
import { WiseBanyanService } from '../../services/wise-banyan.service';

@Inject('$rootScope', 'wiseBanyanService', 'ngrxStore')
export class AxosInvestHelper {
  static MANAGED_PORTFOLIO = 'Managed Portfolios';
  static AXOS_INVEST = 'Axos Invest';
  hasAxosInvest: boolean;
  investAccount: InvestAccount;

  constructor(
    private rootScope: IRootScopeService,
    private wiseBanyanService: WiseBanyanService,
    private store: Store
  ) {
    this.hasAxosInvest = sessionStorage.getItem('hasAxosInvest') === 'true';
    this.setInvestAccountObjectDefault();
  }

  async getMilestoneData(): Promise<MilestoneDetailVm> {
    if (this.rootScope['milestonesDetail']) {
      const data: MilestoneDetailVm = this.rootScope['milestonesDetail'];

      this.addInvestAccount(
        data.milestones.filter(x => x.isClosed == false).length,
        data.totalBalance,
        data.hasError,
        data.isClosed
      );

      return of(data).toPromise();
    } else {
      const result = await this.wiseBanyanService.getMilestones();

      this.addInvestAccount(
        result.data.milestones.filter(x => x.isClosed == false).length,
        result.data.totalBalance,
        result.data.hasError,
        result.data.isClosed
      );

      this.rootScope['milestonesDetail'] = result.data;

      return of(result.data).toPromise();
    }
  }

  getInvestAccount(): any {
    return this.rootScope['axosInvestAccounts'];
  }

  addInvestAccount(numberOfMilestones: number, totalBalance: number, hasError = false, isClosed: boolean): void {
    if (!this.rootScope['axosInvestAccounts']) {
      if (!isClosed) {
        this.setInvestAccountActive(numberOfMilestones, totalBalance, hasError);
      } else {
        this.setInvestAccountClosed();
      }

      this.rootScope['axosInvestAccounts'] = this.investAccount;
      this.store.dispatch(loadInvestAccounts({ payload: { ...this.investAccount } }));
    }
  }

  addInvestClosedAccounts(): any[] {
    this.setInvestAccountClosed();

    const investClosedAccountArray: any[] = [];
    investClosedAccountArray.push(this.investAccount);

    return investClosedAccountArray;
  }

  isAccountClosed(status: string): boolean {
    return status == 'Closed';
  }

  isInvestAccountEmpty(): boolean {
    if (this.investAccount) {
      return (
        this.investAccount.accountType === 'invest' &&
        this.investAccount.availableBalance === 0 &&
        this.investAccount.bankName === '' &&
        this.investAccount.hasError === false &&
        this.investAccount.isAxosInvest === true &&
        this.investAccount.name === AxosInvestHelper.MANAGED_PORTFOLIO &&
        this.investAccount.nickname === AxosInvestHelper.MANAGED_PORTFOLIO &&
        this.investAccount.status === ''
      );
    }

    return true;
  }

  private setInvestAccountObjectDefault(): void {
    this.investAccount = {
      name: AxosInvestHelper.MANAGED_PORTFOLIO,
      isAxosInvest: true,
      bankName: '',
      availableBalance: 0,
      nickname: AxosInvestHelper.MANAGED_PORTFOLIO,
      accountType: 'invest',
      hasError: false,
      status: '',
      isClosed: false,
      isLoaded: false,
    };
  }

  private setInvestAccountActive(numberOfGoals: number, totalBalance: number, hasError = false): void {
    if (numberOfGoals == 0) {
      this.investAccount.bankName = '-- Goals';
    } else if (numberOfGoals > 1) {
      this.investAccount.bankName = `${numberOfGoals} Goals`;
    } else {
      this.investAccount.bankName = '1 Goal';
    }

    this.investAccount.availableBalance = totalBalance;
    this.investAccount.hasError = hasError;
    this.investAccount.status = 'Active';
  }

  private setInvestAccountClosed(): void {
    this.investAccount.bankName = '0 Goals';
    this.investAccount.availableBalance = 0;
    this.investAccount.hasError = false;
    this.investAccount.status = 'Closed';
  }
}
