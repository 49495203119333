import { Routes } from '@angular/router';

import { appRoutesNames } from './app.routes.names';
import { HideOnProdCanActivateGuard } from './udb/udb-core/guards/hide-on-prod.can-activate.guard';

export const appRoutes: Routes = [
  {
    path: appRoutesNames.Udb,
    canActivate: [HideOnProdCanActivateGuard], // Temporary change until there is something for the end-user to see from this route in production
    loadChildren: () => import('./udb/udb.module').then(m => m.UdbModule),
  },
];
