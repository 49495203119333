import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { Uk2ModalModule } from '@axos/uikit-v2-lib';
import { Uk2ServicesModule } from '@axos/uikit-v2-lib';
import { Uk2DirectivesModule } from '@axos/uikit-v2-lib/src/lib/uk2-directives';

@NgModule({
  exports: [
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    Uk2ModalModule,
    Uk2ServicesModule,
    Uk2DirectivesModule,
  ],
})
export class SharedUk2Module {}
