import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { AlertsTopics } from '@app/support/enums';

@Component({
  selector: 'how-to-alerts-topics',
  templateUrl: './alerts-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AlertsTopicsComponent {
  selectedTopic: AlertsTopics;

  topics = AlertsTopics;

  constructor() {}

  selectTopic(topic: AlertsTopics): void {
    this.selectedTopic = topic;
  }
}
