import { Dialogs } from '@uikit/dialogs';

class PopupsHelper implements IPopups {
  constructor() {}

  showAlert(
    title: string,
    description: string,
    type: 'warning' | 'success' | 'error' | 'info' = 'success',
    callback?: Function,
    okButtonText?: string
  ): void {
    Dialogs.show({
      title,
      message: description,
      type,
      onConfirm: callback || null,
      confirmBtnText: okButtonText || null,
    });
  }

  showConfirm(title: string, description: string, callback?: Function, confirmText?: string): void {
    Dialogs.showConfirm(title, description, confirmText, 'Cancel', true, true, callback);
  }

  showError(message: string, okButtonText?: string, callback?: Function): void {
    Dialogs.showError(message, okButtonText, callback);
  }
}

export { PopupsHelper };
