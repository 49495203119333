import { Component, Inject, OnInit } from '@angular/core';
import { MessagesType } from '@app/inbox/enums';
import {
  LEGACY_ENV_TOKEN,
  LEGACY_FILTER_TOKEN,
  LEGACY_MESSAGES_SERVICE_TOKEN,
  LEGACY_MESSAGES_SHARED_SERVICES_TOKEN,
  LEGACY_SERVICE_HELPER_TOKEN,
  LEGACY_STATE_TOKEN,
} from '@app/inbox/injection-tokens';
import { IAxosLegacyPagination } from '@app/inbox/models';
import { InboxMapper } from '@app/inbox/utils/classes/inbox-mapper.class';
import { FacingBrand } from '@core/enums';
import { MessagesSharedService } from '@legacy/messages/messages.shared.service';
import { IMessagesService } from '@legacy/services/typings/IMessagesService';
import { IFilterService } from 'angular';
@Component({
  selector: 'app-inbox-component',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  types: GenericOption[];
  selectedType: any;
  headers: GenericOption[];
  messagesResponse: Message[] = [];
  showActions = false;
  isLoading = false;
  checkedAll = false;
  currentPage: number;
  pageSize: number;
  isEditing = false;
  brandName: string;
  subheader = 'All of your communication with the bank lives here.';
  filterRange: IAxosLegacyPagination['renderRange'] = [0, 0];
  constructor(
    @Inject(LEGACY_SERVICE_HELPER_TOKEN) public readonly serviceHelper: IServiceHelper,
    @Inject(LEGACY_MESSAGES_SERVICE_TOKEN) public readonly messagesService: IMessagesService,
    @Inject(LEGACY_MESSAGES_SHARED_SERVICES_TOKEN) public readonly messagesSharedService: MessagesSharedService,
    @Inject(LEGACY_STATE_TOKEN) public readonly stateService: ng.ui.IStateService,
    @Inject(LEGACY_FILTER_TOKEN) public readonly filterService: IFilterService,
    @Inject(LEGACY_ENV_TOKEN) public readonly env: OlbSettings
  ) {}

  ngOnInit(): void {
    this.brandName = this.env.brand;
    this.setDefaultValues();
    this.getMessages();
    this.messagesSharedService.callUnreadMessagesCount();
    this.isLoading = true;
    if (this.env.facingBrandId === FacingBrand.Axos) {
      this.subheader = 'A secure location within your Axos account for communications with us.';
    }
  }

  filterInboxMessages(messages: Message[], [start, end]: IAxosLegacyPagination['renderRange']): Message[] {
    return messages.slice(start, end);
  }

  checkAll(): void {
    this.messagesResponse.forEach(element => {
      element.checked = this.checkedAll;
    });
    this.checkIfShowActions();
  }

  checkIfShowActions(): void {
    let enable = false;
    this.messagesResponse.forEach(element => {
      if (element.checked) enable = true;
    });
    this.showActions = enable;
    this.checkIfAllChecked();
  }

  messageDetail(id: string, caseDate: Date, status: string, read: boolean): void {
    const dateL = this.filterService('date')(caseDate, 'MMM d, y @ h:mm a');
    localStorage.setItem('cdate', dateL);
    this.stateService.go('udb.messages.messageDetail', {
      caseId: id,
      status,
      hasread: read,
    });
  }

  showEdit(): void {
    this.isEditing = true;
  }

  hideEdit(): void {
    this.unCheckAll();
    this.isEditing = false;
  }

  private unCheckAll(): void {
    this.messagesResponse.forEach(element => {
      element.checked = false;
    });
    this.checkIfShowActions();
  }

  private checkIfAllChecked(): void {
    let allCheked = true;
    this.messagesResponse.forEach(element => {
      if (!element.checked) allCheked = false;
    });
    this.checkedAll = allCheked;
  }

  goComposeMessageView(): void {
    this.stateService.go('udb.messages.compose-message');
  }

  private setDefaultValues(): void {
    this.types = [
      { value: MessagesType.INBOX, label: InboxMapper.mapMessageTypes(MessagesType.INBOX) },
      { value: MessagesType.ARCHIVED, label: InboxMapper.mapMessageTypes(MessagesType.ARCHIVED) },
    ];
    this.selectedType = this.types[0];

    this.headers = [
      { value: 1, subvalue: 'subject', label: 'Subject' },
      { value: 2, subvalue: 'summary', label: 'Summary' },
      { value: 3, subvalue: 'lastActivity', label: 'Last Activity' },
      { value: 4, subvalue: 'status', label: 'Case Status' },
    ];
    this.currentPage = 1;
    this.pageSize = 10;
  }

  private getMessages(): void {
    this.messagesService.getMessages('Inbox').subscribe({
      next: res => {
        this.messagesResponse = res.data;
        this.messagesResponse = [...res?.data].sort((a, b) =>
          a.lastActivityDate < b.lastActivityDate ? 1 : b.lastActivityDate < a.lastActivityDate ? -1 : 0
        );
        this.isLoading = false;
      },
      error: err => {
        this.serviceHelper.errorHandler(err);
        this.isLoading = false;
      },
    });
  }
}
