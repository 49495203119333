import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { dashboardComponents } from './components';
import { SharedModule } from '@shared/shared.module';
import { FastLinkModule } from '@app/fast-link/fast-link-module';
import { SignatureCardModule } from '@app/accounts/submodules';
import { enrollmentModuleImports } from '@app/enrollment/config/config';
import {
  accountAggregationServiceProvider,
  appSettingsServiceProvider,
  cachedAccountsServiceProvider,
  featureFlagServiceProvider,
  flowServiceProvider,
  ngReduxServiceProvider,
  serviceHelperProvider,
  stateServiceProvider,
} from '@core/providers';

const provider = [
  accountAggregationServiceProvider,
  appSettingsServiceProvider,
  cachedAccountsServiceProvider,
  featureFlagServiceProvider,
  flowServiceProvider,
  ngReduxServiceProvider,
  serviceHelperProvider,
  stateServiceProvider,
];
@NgModule({
  declarations: [...dashboardComponents],
  imports: [CommonModule, SharedModule, FastLinkModule, SignatureCardModule, ...enrollmentModuleImports],
  providers: [provider],
})
export class DashboardModule {}
