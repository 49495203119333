import { DocumentDetail } from './document-detail.model';

export class GroupedDocumentsByMonth {
  month: string;
  documents: DocumentDetail[];

  constructor(args?: Partial<GroupedDocumentsByMonth>) {
    Object.assign(this, args);
  }
}
