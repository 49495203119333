<div class="stop-payment frow center">
  <div class="fcol-cs-12 fcol-sm-10">
    <div class="frow fcol-cs-12 container__btn-back">
      <a class="finline middle" (click)="redirectBack(accountId)">
        <i class="bofi-arrow-chevron" aria-hidden="true"></i>
        <span>Back</span>
      </a>
    </div>
    <div class="frow fcol-cs-12">
      <img src="/img/icons/accounts/stop_payment.svg" width="100" alt="Stop Payment" />
      <div class="fcol-cs-8 fcol-sm-10">
        <h2>Stop Payment</h2>
        <p>Stop a check or an ACH payment</p>
      </div>
      <hr class="fcol-cs-12" />
    </div>
    <div class="frow between middle fcol-cs-12">
      <h4>Current Stop Payments</h4>
      <button type="button" (click)="redirectTo(accountId)" class="uk-btn solid secondary sm">
        Add A Stop Payment
      </button>
    </div>
    <app-dot-loader [showWhen]="isBusy">Loading</app-dot-loader>
    <div class="frow fcol-cs-12" *ngIf="!isBusy">
      <div class="sp-table-container table-responsive">
        <table class="table sp-table">
          <thead>
            <tr>
              <th *ngFor="let header of headers" (click)="sort(header.subvalue)">
                {{ header.label }}
                <i
                  class="fa"
                  *ngIf="orderBy === header.subvalue"
                  [ngClass]="orderBy === header.subvalue && !reverse ? 'fa-caret-up' : 'fa-caret-down'"
                  aria-hidden="true"
                ></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let stopPmnt of stopPayments | orderBy: reverse">
              <td>{{ stopPmnt.stopType }}</td>
              <td>
                <span
                  *ngIf="
                    (stopPmnt.highCheckNumber && stopPmnt.lowCheckNumber === stopPmnt.highCheckNumber) ||
                    stopPmnt.stopType === 'ACH'
                  "
                >
                  {{ stopPmnt.payeeDebitingCompany }}
                </span>
                <span *ngIf="stopPmnt.highCheckNumber && stopPmnt.lowCheckNumber !== stopPmnt.highCheckNumber">
                  N/A
                </span>
              </td>
              <td>
                <div *ngIf="stopPmnt.stopType === 'Check'">
                  <span>{{ '#' + stopPmnt.lowCheckNumber }}</span>
                  <span *ngIf="stopPmnt.highCheckNumber && stopPmnt.lowCheckNumber !== stopPmnt.highCheckNumber">
                    {{ ' - #' + stopPmnt.highCheckNumber }}
                  </span>
                </div>
                <span *ngIf="stopPmnt.stopType === 'ACH'">N/A</span>
              </td>
              <td>
                <span
                  *ngIf="
                    (stopPmnt.highCheckNumber && stopPmnt.lowCheckNumber === stopPmnt.highCheckNumber) ||
                    stopPmnt.stopType === 'ACH'
                  "
                >
                  {{ stopPmnt.amount | currency }}
                </span>
                <span *ngIf="stopPmnt.highCheckNumber && stopPmnt.lowCheckNumber !== stopPmnt.highCheckNumber">
                  N/A
                </span>
              </td>
              <td>{{ stopPmnt.requestedDate | date: 'MM/dd/yyyy' }}</td>
              <td>{{ stopPmnt.expirationDate | date: 'MM/dd/yyyy' }}</td>
              <td>
                <a class="link" (click)="extendStopPayment(stopPmnt)">Extend</a>
                |
                <a class="link" (click)="removeStopPayment(stopPmnt)">Remove</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 *ngIf="!stopPayments.length">There are currently no stop payments on this account</h3>
    </div>
  </div>
</div>
