import { SecureFormsLibrary, SecureFormsUnauthorizedRequest, UserFileUploaded } from '@app/secure-forms/models';
import { Inject } from '../decorators/decorators';
import { ObservableBaseService } from './observable-base.service';
import { ObservableResponse } from 'models';
import { ISecureFormsService } from 'services/typings/ISecureFormsService';

@Inject('$http', 'env', 'serviceHelper', '$q')
class SecureFormsService extends ObservableBaseService implements ISecureFormsService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'SecureForms', _serviceHelper, q);
  }

  public getSecureFormsLibrary(): ObservableResponse<SecureFormsLibrary> {
    return this.get('library');
  }

  public sendDocument(fileId: string): ObservableResponse<any> {
    return this.get(`userfile/${fileId}`);
  }

  public getForm(fileId: string, isFormNumber?: boolean): Promise<void> {
    return this.download(`formtype/${fileId}`, { isFormNumber });
  }

  public getUserFiles(): ObservableResponse<UserFileUploaded[]> {
    return this.get(`userFiles`);
  }

  public sendUnauthorizedStatement(vm: SecureFormsUnauthorizedRequest): ObservableResponse<any> {
    return this.post('statementUnauthorizedDebit', vm);
  }
}

export { SecureFormsService };
