import { ModalPersonType } from '../enums';

export const REMOVE_TRUSTED_INTERESTED_MODAL_LABELS = {
  [ModalPersonType.TrustedContact]: {
    headerText: 'Remove Trusted Contact?',
    bodyContent: 'You are about to remove your trusted contact. Do you want to continue?',
  },
  [ModalPersonType.InterestedParty]: {
    headerText: 'Please Contact Your Advisor',
    bodyContent: 'Action is required by your advisor to remove interested parties.',
  },
};
