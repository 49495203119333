import { Component, Inject, OnInit } from '@angular/core';

import { FacingBrand } from '@core/enums';
import { OlbSettings } from '@core/models';
import { olbSettings, ROOT_SCOPE, STATE } from '@core/tokens';

@Component({
  selector: 'app-contact-us-confirmation',
  templateUrl: './contact-us-confirmation.component.html',
  styleUrls: ['./contact-us-confirmation.component.scss'],
})
export class ContactUsConfirmationComponent implements OnInit {
  userEmail: string;
  userBrand: string;
  brandTeamName: string;

  get leftCity() {
    return `assets/img/icons/support/${this.userBrand}/left-city.svg`;
  }

  get rightCity() {
    return `assets/img/icons/support/${this.userBrand}/right-city.svg`;
  }

  get messageImage() {
    return `assets/img/icons/support/${this.userBrand}/message.svg`;
  }

  constructor(
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(olbSettings) private settings: OlbSettings
  ) {}

  ngOnInit(): void {
    this.userEmail = this.root['userInfo']?.email ?? '';
    this.userBrand = this.settings.brand;

    switch (this.settings.facingBrandId) {
      case FacingBrand.Nationwide:
          this.brandTeamName = 'Nationwide Banking Provided by Axos Bank';
          break;
      case FacingBrand.UFB:
          this.brandTeamName = 'UFB Direct Bank';
          break;
      default:
        this.brandTeamName = 'Axos Bank';
        break;
    }
  }

  // TODO: This will be refactorized once we implement routing
  backToSupport() {
    this.state.go('udb.support');
  }
}
