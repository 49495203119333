<h3 class="mt-5 mb-4">
  For business transfers, why can’t I move money from an external account to my Axos business account?
</h3>

<ol>
  <li>
    We are working to provide that service, but our current system does not allow that type of transfer. In the
    meantime, you can move the money to an Axos personal account, then transfer it to your business account.
  </li>
</ol>
