<div [ngClass]="isPfm2Active ? 'pfm2' : 'not-pfm2'" class="insights-container container-shadow">
  <!-- This div will display a blue background when PFM2 is not active (legacy) -->
  <div class="insights-header-container" *ngIf="!isPfm2Active">
    <div class="insights-selectors insights-content">
      <div class="d-flex flex-wrap"></div>
    </div>
  </div>

  <div class="insights-gradient">
    <div class="insights-header">
      <!-- If there are no transactions then hide the categories, use visibility to keep the size (UX) -->
      <div class="insights-content" [hidden]="filteredTransactions.length === 0">
        <div *ngIf="!isPfm2Active" class="d-flex flex-wrap">
          <div class="col-12 col-sm-2">
            <div class="insights-flex insights-flex-bottom">
              <div class="insights-header-title period-box">
                <h2>{{ categoriesName }}</h2>
              </div>
            </div>
          </div>
          <div class="period-box-actual col-5 col-sm-2">
            <div class="period-box">
              <h5>This Month</h5>
              <h2 class="amount">
                {{ actualPeriodSum | currency }}
              </h2>
            </div>
          </div>
          <div class="period-box-previous col-5 col-sm-2">
            <div class="period-box">
              <h5>Last Month</h5>
              <h2 class="amount">
                {{ previousPeriodSum | currency }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="insights-content">
      <div *ngIf="isPfm2Active" class="line-chart-header-container" [hidden]="filteredTransactions.length === 0">
        <div class="font-primary" style="flex: 1;">
          <h2 class="encode-sans font-weight-normal">{{ categoriesName }}</h2>
          <div class="account-nickname font-weight-normal">{{ getBankNameMask() }}</div>
        </div>
        <div class="line-chart-header">
          <div class="text-center">
            <h2 class="encode-sans font-weight-normal font-primary font-size-md">
              {{ actualPeriodSum | currency }}
            </h2>
            <div class="mt-1 font-weight-normal c-black">{{ actualPeriodTime }}</div>
          </div>
          <div class="text-center ml-4">
            <h2 class="encode-sans font-weight-normal font-primary font-size-md">
              {{ previousPeriodSum | currency }}
            </h2>
            <div class="mt-1 font-weight-normal c-black">Last Month</div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div class="insights-chart-container_no_transactions_msg" [hidden]="filteredTransactions.length !== 0">
          <h3>There are no transactions available for Insights</h3>
        </div>
        <div class="d-flex flex-column">
          <div class="insights-chart-container insights-chart-container_slide">
            <canvas [hidden]="filteredTransactions.length === 0" id="line-chart-canvas2"></canvas>
          </div>
        </div>
      </div>
      <div *ngIf="isPfm2Active" class="line-chart-header-mobile m-4" [hidden]="filteredTransactions.length === 0">
        <div class="text-center">
          <h2 class="encode-sans font-weight-normal font-primary font-size-md">
            {{ actualPeriodSum | currency }}
          </h2>
          <div class="mt-1 font-weight-normal c-black">{{ actualPeriodTime }}</div>
        </div>

        <div class="text-center">
          <h2 class="encode-sans font-weight-normal font-primary font-size-md">
            {{ previousPeriodSum | currency }}
          </h2>
          <div class="mt-1 font-weight-normal c-black">Last Month</div>
        </div>
      </div>
    </div>
  </div>
</div>
