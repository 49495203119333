<ng-container *ngIf="!authorizationTabAccountState$.error; else failedStateContent">
  <section>
    <app-advisor-contribution></app-advisor-contribution>
    <app-advisor-distribution></app-advisor-distribution>
    <app-bank-wire></app-bank-wire>
    <app-ach-one-time></app-ach-one-time>
    <app-authorizations-other></app-authorizations-other>
  </section>
</ng-container>

<ng-template #failedStateContent>
  <mat-divider class="failed-state-divider"></mat-divider>
  <uk2-text-tile [uk2TextTileTitle]="'Authorizations Temporarily Unavailable'" [uk2TextTileIsStandaloneTile]="false">
    We're sorry, but we're currently unable to provide you with your latest authorizations. This includes your
    <span class="uk2-font__bold--75">
      Advisor Contribution, Advisor Distribution, Bank Wire, ACH One Time, and Other authorizations.
    </span>
    Please check back later. Thank you for your patience.
  </uk2-text-tile>
</ng-template>
