import { Inject } from '../decorators/decorators';
import { ObservableBaseService } from './observable-base.service';
import { ObservableResponse } from 'models';
import { IStatementsService } from './typings/IStatementsService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class StatementsService extends ObservableBaseService implements IStatementsService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'documents', _serviceHelper, q);
  }

  getStatements(accountId: string, docTypes: string): ObservableResponse<Statement> {
    //let docType = 'docTypes=d&docTypes=s&docTypes=t&docTypes=l&docTypes=f';
    return this.get(`${accountId}/types?${docTypes}`, false);
  }

  getStatement(
    accountId: string,
    documentId: string,
    key: string,
    fileName: string,
    onFinally?: any,
    isTrading?: boolean
  ): Promise<void> {
    return this.download(
      `statement/${accountId}/${documentId}/${key}/${fileName}`,
      { isTrading: isTrading },
      onFinally
    );
  }
}
