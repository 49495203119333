import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, shareReplay, tap } from 'rxjs/operators';

import { loadSettings } from '@app/store/actions';
import { OlbSettings, Setting } from '@core/models';
import { olbSettings } from '@core/tokens';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends BaseService {
  static $token = 'configurationService';

  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient, private store: Store) {
    super(http, settings, 'configuration');
  }

  /**
   * Gets the catalog of security questions.
   *
   * @returns An Observable containing a catalog of security questions.
   */
  getSecurityQuestions() {
    return this.get<string[]>('availableQuestions').pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  getSettings() {
    return this.get<Setting[]>('appsettings').pipe(
      map(({ data }) => data),
      tap(payload => {
        this.store.dispatch(loadSettings({ payload }));

        return payload;
      })
    );
  }
}
