import { createAction, props } from '@ngrx/store';
import { RiaType } from '.';

const actionPrefix = '[RIA]';

export const addRiaAction = createAction(`${actionPrefix} addRiaAction`, props<{ payload: RiaType }>());

export const loadRiasAction = createAction(`${actionPrefix} loadRiasAction`, props<{ payload: Array<RiaType> }>());

export const selectRiaAction = createAction(`${actionPrefix} selectRiaAction`, props<{ payload: string }>());

export const selectGlobalRiaAction = createAction(
  `${actionPrefix} selectGlobalRiaAction`,
  props<{ payload: number }>()
);

export const isLoadedAction = createAction(`${actionPrefix} isLoadedAction`, props<{ payload: boolean }>());
