<div class="create-username-header mb-5 text-center">
  <h1 class="create-username-header__title">Hello {{ header.name }}! {{ header.title }}</h1>
  <p class="create-username-header__subtitle">
    {{ header.subtitle }}
    <a id="login-link" *ngIf="!header.hideLogin" href="#" (click)="login.emit()">Login</a>
  </p>
</div>

<form class="no-gutters" [formGroup]="userNameForm" (ngSubmit)="submitData()">
  <div
    class="col-12 col-lg-8 form-group"
    [class.invalid-field]="username.hasError('*') || usernameValidationStatus === 'taken'"
  >
    <label class="uk-label" for="username">Create Username</label>
    <input id="username" class="uk-input" type="text" name="username" formControlName="username" />

    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="username" #username="ngxErrors">
      <span *ngFor="let error of errors.username" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>

    <span
      id="username-message"
      role="alert"
      aria-live="polite"
      *ngIf="usernameMessage"
      style="font-size: 0.875rem;"
      [ngClass]="usernameMessageClass"
      [innerText]="usernameMessage"
    ></span>
  </div>

  <div class="col-12 col-lg-8 form-group" [class.invalid-field]="passwordField.hasError('*')">
    <div class="reverse">
      <input type="password" class="uk-input" name="password" id="password" formControlName="password" />
      <app-reveal-input-label target="password">Create Password</app-reveal-input-label>
    </div>

    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="password" #passwordField="ngxErrors">
      <span ngxError="maxlength">
        The password cannot have more than 25 characters.
      </span>
    </div>

    <app-password-strength [passwordField]="userNameForm.get('password')"></app-password-strength>
  </div>

  <button id="submit-button" type="submit" class="btn-ok button--lg">
    Next
  </button>
</form>
