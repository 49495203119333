import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { sub } from 'date-fns';

import { HISTORIC_OVERVIEW_TIME_FILTERS } from '@app/axos-invest/constants';
import {
  DateFilter,
  HistoricMetrics,
  HistoricOverviewGoal,
  MilestoneSummary,
  PastPerformance,
  PerformanceChange,
  TimeFilterOption,
} from '@app/axos-invest/models';
import { AxosInvestService } from '@app/axos-invest/services';
import { calculateDayChangeAmount, calculateDayChangePercentage } from '@app/axos-invest/utils';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-historic-overview',
  templateUrl: './historic-overview.component.html',
  styleUrls: ['./historic-overview.component.scss'],
})
export class HistoricOverviewComponent implements OnInit {
  @Input() summary: MilestoneSummary;

  isLoading = true;
  isChartUpdating = true;

  icons = {
    triangleUp: NavigationIcons.TriangleUp,
    triangleDown: NavigationIcons.TriangleDown,
    equal: NavigationIcons.Equal,
  };
  metrics: HistoricMetrics;
  goals: HistoricOverviewGoal[];
  updateData = new EventEmitter<void>();
  filterOptions: TimeFilterOption[] = HISTORIC_OVERVIEW_TIME_FILTERS;
  selectedFilter: TimeFilterOption;
  timeFilter = new UntypedFormControl();

  constructor(private investService: AxosInvestService) {}

  ngOnInit(): void {
    this.setupFilterOptions();
    this.setGoals();
    this.getHistoricOverview();
  }

  toggleGoal() {
    this.calculateMetrics();
    this.updateData.emit();
  }

  private setupFilterOptions() {
    this.selectedFilter = this.filterOptions.find(option => option.id === 4);
    this.timeFilter.setValue(4, { emitEvent: false }); // default 1 year
    this.timeFilter.valueChanges.subscribe(value => {
      this.selectedFilter = this.filterOptions.find(option => option.id === Number(value));
      this.getHistoricOverview(true);
    });
  }

  private setGoals() {
    const goals = this.summary.milestones.filter(goal => !goal.isClosed);
    if (this.summary.wallet && !this.summary.wallet.isClosed) {
      goals.push(this.summary.wallet);
    }

    this.goals = goals.map(goal => {
      return { id: goal.id, name: goal.name, checked: true };
    });
  }

  private getHistoricOverview(notifyEvent: boolean = false) {
    this.isChartUpdating = true;
    const filter: DateFilter = {};
    const today = new Date();
    filter.end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
    filter.start = sub(filter.end, this.selectedFilter.duration);
    filter.start.setHours(0, 0, 0, 0);
    const checkedGoals = this.goals.filter(t => t.checked);

    if (checkedGoals.length === 0) {
      // if no goals selected, just display empty
      this.calculateMetrics();
      this.updateData.emit();
      this.isLoading = false;
      this.isChartUpdating = false;

      return;
    }

    const requests = checkedGoals.map(goal =>
      this.investService.getPastPerformance(goal.id, filter).pipe(
        map(response => response.data.client),
        catchError(() => of(null))
      )
    );

    forkJoin(requests).subscribe((responses: PastPerformance[]) => {
      responses.forEach((pastPerformance, index) => {
        checkedGoals[index].historicData = pastPerformance;
        checkedGoals[index].checked = !!pastPerformance;
      });
      this.calculateMetrics();
      if (notifyEvent) this.updateData.emit();
      this.isLoading = false;
      this.isChartUpdating = false;
    });
  }

  private calculateMetrics() {
    const historicData = this.goals.filter(goal => goal.checked).map(goal => goal.historicData);
    this.metrics = new HistoricMetrics({
      dividends: 0,
      withdrawals: 0,
      deposits: 0,
      totalValue: 0,
      dayChangeAmount: 0,
      dayChangePercentage: 0,
    });
    const lastDay: PerformanceChange = {
      close: 0,
      contributions: 0,
    };
    const firstDay: PerformanceChange = {
      close: 0,
      contributions: 0,
    };
    historicData.forEach(item => {
      this.metrics.dividends += item.dividends;
      this.metrics.withdrawals += item.withdrawals;
      this.metrics.deposits += item.totalDeposits;
      this.metrics.totalValue += item.marketValue;

      const lastElement = item.historicalResponse.slice(-1).pop();
      lastDay.close += lastElement.close;
      lastDay.contributions += lastElement.contributions;

      const firstElement = item.historicalResponse.slice(0, 1).shift();
      firstDay.close += firstElement.close;
      firstDay.contributions += firstElement.contributions;
    });

    this.metrics.dayChangeAmount = calculateDayChangeAmount(lastDay, firstDay);
    this.metrics.dayChangePercentage = calculateDayChangePercentage(lastDay, firstDay);
  }
}
