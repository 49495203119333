import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TransactionDetailComponent } from '@app/axos-invest/components';
import { TradeTransaction } from '@app/axos-invest/models';

@Component({
  selector: 'app-dividend-information',
  templateUrl: './dividend-information.component.html',
  styleUrls: ['./dividend-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividendInformationComponent implements TransactionDetailComponent {
  transaction: TradeTransaction;
}
