import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { transactionsReducer } from './core';
import { TransactionsMatModule } from './transactions-mat.module';
import { TransactionsUk2Module } from './transactions-uk2.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { initialTransactionsState } from './core/store/types';
import { TransactionsService } from './core/services';
import { TransactionFacade } from './facade';
import { TransactionsTileComponent } from './view/transactions-tile/transactions-tile.component';
import { SharedModule } from '@shared/shared.module';
import { TransactionsPageComponent } from './view/transactions-page/transactions-page.component';
import { AxosInvestModule } from '@app/axos-invest/axos-invest.module';

@NgModule({
  declarations: [TransactionsTileComponent, TransactionsPageComponent],
  providers: [TransactionsService, TransactionFacade],
  imports: [
    CommonModule,
    HttpClientModule,
    TransactionsMatModule,
    TransactionsUk2Module,
    SharedModule,
    StoreModule.forFeature(AasStoreFeatures.Transactions, transactionsReducer, {
      initialState: initialTransactionsState,
    }),
    AxosInvestModule,
  ],
  exports: [TransactionsTileComponent, TransactionsPageComponent],
})
export class TransactionsModule {}
