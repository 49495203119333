import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function characterEmailValidatorFn(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    if (!invalidCharacter.reduce((acc: boolean, current: string) => acc || controlValue.includes(current), false))
      return null;

    return { invalidCharactersEmail: true };
  };
}

const invalidCharacter = [
  '!',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '=',
  '{',
  '}',
  '[',
  ']',
  ';',
  ':',
  "'",
  '<',
  '>',
  '?',
  '/',
  '\\',
  '|',
  '`',
  ',',
  '"',
];
