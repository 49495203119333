<ng-container *ngIf="contingentBeneficiaries && contingentBeneficiaries.length > 0; else emptyState">
  <ng-container *ngFor="let contingentBeneficiary of contingentBeneficiaries; let i = index">
    <div class="section-header">
      Contingent Beneficiary {{ contingentBeneficiaries.length > 1 ? '#' + (i + 1) : '' }}
    </div>
    <div class="list">
      <div class="list-row">
        <span class="title">Name</span>
        <span class="value">{{ contingentBeneficiary.name }}</span>
      </div>
      <div class="list-row">
        <span class="title">Date of Birth</span>
        <span class="value">{{ contingentBeneficiary.birthDate }}</span>
      </div>
      <div class="list-row">
        <span class="title">Percentage</span>
        <span class="value">{{ contingentBeneficiary.contingentPercentage + '%' }}</span>
      </div>
      <div class="list-row">
        <span class="title">Relationship</span>
        <span class="value">{{ contingentBeneficiary.spouse ? 'Spouse' : 'Other' }}</span>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyState>
  <div class="section-header">
    Contingent Beneficiary
  </div>
  <div class="list">
    <div class="list-row">Not Yet Assigned</div>
  </div>
</ng-template>
