import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountTab } from '@app/accounts/enums';
import { MenuOverview, TradeStockType } from '@app/axos-trading/enums';
import { olbSettings, STATE } from '@core/tokens';

import { ClearingSsoService } from './clearing-sso.service';

@Injectable({
  providedIn: 'root',
})
export class TradingRoutingHelperService {
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(olbSettings) private env: OlbSettings,

    private clearingSsoService: ClearingSsoService
  ) { }

  getOrbisUrl(): Observable<string> {
    return this.clearingSsoService.getSsoToken().pipe(
      map(referrerToken => {
        const url = this.env.orbisUrl;

        return `${url}/axos.sso.action?referrer=axos_olb&referrer_token=${referrerToken}`;
      })
    );
  }

  getOrbisUrlWithAccount(accountNumber: string): Observable<string> {
    return this.clearingSsoService.getSsoToken().pipe(
      map(referrerToken => {
        const url = this.env.orbisUrl;

        return `${url}/axos.sso.action?referrer=axos_olb&referrer_token=${referrerToken}&accountNumber=${accountNumber}`;
      })
    );
  }

  goToStockDetail(stockId: string) {
    return this.state.go('udb.axosTrading.stocks', {
      stockId,
    });
  }

  goToPositionStockDetail(accountId: number, stockId: string) {
    return this.state.go('udb.axosTrading.position', {
      stockId,
      accountId,
    });
  }
  goToTransactionDetails(accountId: number) {
    return this.state.go('udb.axosTrading.trandetails', {
      id: accountId
    });
  }

  goToSDTOverview(accountId: number) {
    return this.state.go('udb.accounts.container', {
      id: accountId,
      type: 'trading',
      tab: AccountTab.Overview,
    });
  }

  goToTradeStock(accountId: number, stockId: string, tradeType: TradeStockType = TradeStockType.Buy) {
    return this.state.go('udb.axosTrading.trade', {
      accountId,
      stockId,
      tradeType,
    });
  }

  goToTransactions(accountId: number) {
    return this.state.go('udb.accounts.container', {
      tab: AccountTab.Transactions,
      id: accountId,
      type: 'trading',
    });
  }
  goToTransactionsSubTab(accountId: number, subTab: MenuOverview) {
    return this.state.go('udb.accounts.container', {
      tab: AccountTab.Transactions,
      subTab,
      id: accountId,
      type: 'trading',
    });
  }

  goToTransferFunds() {
    return this.state.go('udb.transfers.transferFunds');
  }

  goToExchangeAgreementsPortfolio(accountId: number, type: string, url: string) {
    return this.state.go('udb.axosTrading.exchangeAgreementPortfolio', {
      accountId,
      type,
      url,
    });
  }

  goToExchangeAgreementsIndividual(accountId: number, stockId: string, type: string, url: string) {
    return this.state.go('udb.axosTrading.exchangeAgreementIndividual', {
      accountId,
      stockId,
      type,
      url,
    });
  }
}
