import { Action, createReducer, on } from '@ngrx/store';
import { signOut } from '@app/store/actions';
import { RiaStateType, initialRiaStateType } from '../types';
import { addRiaAction, loadRiasAction, selectRiaAction, selectGlobalRiaAction } from '../ria.actions';

const reducer = createReducer(
  initialRiaStateType,
  on(addRiaAction, (_state, _action) => {
    return {
      rias: [..._state.rias, _action.payload],
      isLoaded: _state.isLoaded,
      selectedRia: _action.payload.name,
    };
  }),
  on(selectRiaAction, (_state, _action) => {
    return {
      rias: [..._state.rias],
      isLoaded: _state.isLoaded,
      selectedRia: _action.payload,
    };
  }),
  on(loadRiasAction, (_state, _action) => {
    return {
      rias: [..._action.payload],
      isLoaded: true,
      selectedRia: _state.selectedRia ?? undefined,
    };
  }),
  on(selectGlobalRiaAction, (_state, _action) => {
    const globalRia = _state.rias.filter(ria => ria.udbUserId === _action.payload);
    return {
      rias: globalRia,
      isLoaded: _state.isLoaded,
      selectedRia: undefined,
    };
  }),
  on(signOut, () => {
    return initialRiaStateType;
  })
);

export function riaReducer(state: RiaStateType, action: Action): RiaStateType {
  return reducer(state, action);
}
