import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { AggregatedAccount } from 'typings/app/account-aggregation';
import { STATE } from '@core/tokens';
import { FastlinkActions } from '@legacy/dashboard/account-aggregation/enums/fast-link-actions.enum';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { AggregatedError } from '@legacy/typings/app/account-aggregation/AggregatedError';

@Component({
  selector: 'acc-account-warning-tooltip',
  templateUrl: './account-warning-tooltip.component.html',
  styleUrls: ['./account-warning-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountWarningTooltipComponent implements OnInit {
  ngOnInit(): void {
    this.getErrorMessage();
  }

  @Input()
  public account: AggregatedAccount = null;
  public description: string = '';
  public action: string = '';
  public isVisible: boolean = false;

  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    private readonly accountAggregationService: AccountAggregationService
  ) {}

  public actionClick(): void {
    switch (this.action) {
      case FastlinkActions.RESTORE_CONNECTION:
        this.goToRestoreConnection();
        break;
      case FastlinkActions.ADD_ANOTHER_ACCOUNT:
        this.goToAddAccount();
        break;
      default:
        this.goToRestoreConnection();
        break;
    }
  }

  /**
   * Gets the error message for the given status and financial institution name.
   * @param status The status code.
   * @param fiName The name of the financial institution.
   */
  private getErrorMessage(): void {
    const status: string = this.account.oAuthMigrationAdditionalStatuses?.toString()?.split(',')[0];
    const fiName: string = this.account.bankName ?? 'your financial institution';
    this.isVisible = false;
    this.description = '';
    this.action = '';

    if (status !== null && status !== undefined) {
      if (!this.accountAggregationService.isValidStatus(status)) {
        const result: AggregatedError = this.accountAggregationService.getErrorMessage(status, fiName);
        this.description = result.displayMessage;
        this.action = result.actionMessage;
        this.isVisible = true;
      }
    }
  }

  private goToAddAccount(): void {
    this.state.go('udb.accounts.account-aggregation', {
      isAccountAggregationFlow: true,
      isPfm3Active: true,
    });
  }

  private goToRestoreConnection(): void {
    this.state.go('udb.dashboard.account-aggregation.auth', {
      bankId: this.account.providerId,
      updateCredentials: true,
      providerAccountId: this.account.providerAccountId,
      accountNickName: this.account.nickname,
    });
  }
}
