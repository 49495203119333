import { FrequencyEnum, ProductType, SendUntilOption } from '@app/transfers/enums';
export class SchedulerTransferMappings {
  static getProductTypeDescription(productType: ProductType): string {
    switch (productType) {
      case ProductType.AutoLoan:
        return 'Auto Loan';
      case ProductType.CD:
        return 'CD';
      case ProductType.Checking:
        return 'Checking';
      case ProductType.HELOC:
        return 'Heloc';
      case ProductType.IRACD:
        return 'IRA - CD';
      case ProductType.IRASavings:
        return 'IRA - Savings';
      case ProductType.MoneyMarket:
        return 'Money Market';
      case ProductType.Mortgage:
        return 'Mortgage';
      case ProductType.OverdraftlineOfCredit:
        return 'Overdraft Line of Credit';
      case ProductType.PersonalLoan:
        return 'Personal Loan';
      case ProductType.Savings:
        return 'Savings';
      case ProductType.CAndI:
        return 'C&I';
      case ProductType.PaymentProtectionProgram:
        return 'Payment Protection Program';
      default:
        return '';
    }
  }
  static mapFrequency(frequency: number): string {
    switch (frequency) {
      case FrequencyEnum.ONETIME:
        return 'One Time';
      case FrequencyEnum.WEEKLY:
        return 'Weekly';
      case FrequencyEnum.EVERYTWOWEEKS:
        return 'Every Two Weeks';
      case FrequencyEnum.MONTHLY:
        return 'Monthly';
      case FrequencyEnum.QUARTERLY:
        return 'Quarterly';
      case FrequencyEnum.SEMIANNUALLY:
        return 'Semiannually';
      case FrequencyEnum.ANNUALLY:
        return 'Annually';
      default:
        return '';
    }
  }
  static mapSendUntil(option: number): string {
    switch (option) {
      case SendUntilOption.IChooseToStop:
        return 'I choose to stop';
      case SendUntilOption.NumberOfTransfers:
        return 'Number of transfers';
      case SendUntilOption.LastTransferDate:
        return 'Last Transfer Date';
      default:
        return '';
    }
  }
}
