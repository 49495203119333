import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

import { BaseService } from './base.service';
import { WebApiResponse } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class TransferService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'transfers');
  }

  uploadDocuments(endpoint: string = '', data: any = null): Observable<HttpEvent<object>> {
    return this.upload(endpoint, data);
  }

  getPendingTransfers(accounts: number[], limitTo = 1): WebApiResponse<IncomingTransaction[]> {
    const params = {
      accounts: `${accounts}`,
      limitTo: `${limitTo}`,
    };
    const endpoint = `incoming/loan`;
    return this.get<IncomingTransaction[]>(endpoint, params);
  }
}
