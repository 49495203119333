import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getTradingAccount } from '@app/accounts/store/selectors';
import { STATE_PARAMS } from '@core/tokens';
import { UtilityIcons } from '@shared/enums';

import { TradingRoutingHelperService } from '../services/trading-routing-helper.service';

@Component({
  selector: 'app-feature-link',
  templateUrl: './feature-link.component.html',
  styleUrls: ['./feature-link.component.scss'],
})
export class FeatureLinkComponent implements OnInit, OnDestroy {
  axosOrbisUrl: string;
  isLoading = true;
  redirectIcon = UtilityIcons.NewWindow;
  accountId: number;
  accountNumber: string;
  private subSink = new SubSink();

  constructor(private tradingRoutingHelperService: TradingRoutingHelperService,
              @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
              private store: Store) {}

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.getAccountNumber();
    this.getUrl();
  }

  private getUrl() {
    this.tradingRoutingHelperService.getOrbisUrlWithAccount(this.accountNumber).subscribe(url => {
      this.axosOrbisUrl = url;
      this.isLoading = false;
    });
  }

  private getAccountNumber() {
    this.subSink.sink = this.store
      .select(getTradingAccount(+this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.accountNumber = account.accountNumber;
      });
  }
}
