export class MilestoneTransfer {
  toMilestoneId: string;
  fromMilestoneId: string;
  amount: number;
  accountNumber: string;

  constructor(args?: Partial<MilestoneTransfer>) {
    Object.assign(this, args);
  }
}
