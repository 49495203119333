export class LimitRequest {
  cardMask: string;
  limitType: string;
  limitAmount: string;
  startDate: string;
  endDate: string;
  explanation: string;

  constructor(args?: Partial<LimitRequest>) {
    Object.assign(this, args);
  }
}
