import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { ModifyBannerType } from '@app/axos-invest/enums';
import { Goal } from '@app/axos-invest/models';
import { AxosInvestTransferService } from '@app/axos-invest/services';
import { loadScheduledTransfers } from '@app/axos-invest/store/actions';
import { getGoalSummary, getScheduledTransfers } from '@app/axos-invest/store/selectors';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-axos-invest-goal',
  templateUrl: './axos-invest-goal.component.html',
  styleUrls: ['./axos-invest-goal.component.scss'],
})
export class AxosInvestGoalComponent implements OnInit, OnDestroy {
  indexTabActive = 0;
  $goal: Observable<Goal>;
  backIcon = NavigationIcons.ArrowBack;
  milestoneID = '';
  referrerTabId: number;
  showModifiedBanner = false;
  modifiedBannerText: string;
  modifiedState: ModifyBannerType;
  displayErrorBanner = false;
  errorPrompt =
    "Your portfolio can't be accessed at this time. We apologize for the inconvenience. Please try again later.";
  subSink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private transferService: AxosInvestTransferService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.referrerTabId = this.params['referrerTabId'];
    this.modifiedState = this.params['modifiedState'];
    this.modifiedBannerText = this.params['modifiedBannerText'];

    if (this.referrerTabId) {
      this.indexTabActive = this.referrerTabId;
    }

    if (this.modifiedState !== ModifyBannerType.None && this.modifiedBannerText != null) {
      this.showModifiedBanner = true;
    }

    this.milestoneID = this.params['id'];

    this.getGoalData();
    this.getScheduledTransfers();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  getGoalData() {
    this.$goal = this.store
      .select(getGoalSummary)
      .pipe(map(milestoneSummary => milestoneSummary.milestones.find(milestone => milestone.id === this.milestoneID)));
  }

  changeTab(tabIndex: number): void {
    this.showModifiedBanner = false;
    this.displayErrorBanner = false;
    this.params['modifiedState'] = ModifyBannerType.None;
    this.indexTabActive = tabIndex;
  }

  goBackMpOverview() {
    this.state.go('udb.accounts.container', {
      id: '',
      type: 'Invest',
      container: '',
      tab: '',
    });
  }

  dismissBannerEvent(params: any) {
    this.showModifiedBanner = params.modified;
    this.params['modifiedState'] = ModifyBannerType.None;
  }

  showErrorBanner(event) {
    this.displayErrorBanner = event;
  }
  private getScheduledTransfers() {
    this.subSink.sink = this.store.select(getScheduledTransfers).subscribe(transfers => {
      if (!transfers) {
        this.subSink.sink = this.transferService.getScheduledTransfers().subscribe(response => {
          this.store.dispatch(loadScheduledTransfers({ payload: response.data }));
        });
      }
    });
  }
}
