import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { SharedAccountsByAdvisorResponse } from '../../services/ria-account-for-sharing-response';
import { SharedAccountsStateType } from '..';

const getSharedAccountsState = createFeatureSelector<SharedAccountsStateType>(AasStoreFeatures.SharedAccounts);

const getSharedAccountsPromptingState = createFeatureSelector<SharedAccountsStateType>(
  AasStoreFeatures.SharedAccountsPrompting
);

const getSharedAccountsByAdvisorState = createFeatureSelector<SharedAccountsByAdvisorResponse>(
  AasStoreFeatures.SharedAccountsByAdvisor
);

export const getSharedAccounts = createSelector(getSharedAccountsState, state => state);

export const getSharedAccountsPrompting = createSelector(getSharedAccountsPromptingState, state => state);

export const getSharedAccountsByAdvisor = createSelector(getSharedAccountsByAdvisorState, state => state);
