import { AuthService } from '@core/services';

import { Inject } from '../decorators/decorators';

@Inject('$q', '$window', 'authService')
class AuthHelper implements IAuthHelper {
  constructor(
    private q: ng.IQService,
    private $window: ng.IWindowService,
    private authService: AuthService
  ) {}

  hasCustomerServiceRestriction(restrictionType: number): any {
    return this.authService.hasUserRestriction(restrictionType).subscribe(res => {
      if (res.data) {
        this.$window.location.href = '/Dashboard';

        return this.q.reject();
      } else {
        return this.q.resolve();
      }
    });
  }
}

export { AuthHelper };
