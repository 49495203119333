import { Inject } from 'decorators/decorators';

@Inject('$stateProvider')
export class ComposeMessageConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.messages.compose-message', {
        url: '/compose-message',
        views: {
          messagesContent: {
            template: `<app-compose-message></app-compose-message>`,
          },
        },
      })
      .state('udb.messages.compose-message.business-selection', {
        url: '/business-selection/:categoryId?categoryName',
        views: {
          'cm-content-menu': {
            template: `<app-cm-business-selection></app-cm-business-selection>`,
          },
        },
      })
      .state('udb.messages.compose-message.subcategories', {
        url:
          '/subcategories/:categoryId?categoryName?businessId?isSingleAccount',
        views: {
          'cm-content-menu': {
            template: `<app-cm-subcategories></app-cm-subcategories>`,
          },
        },
      })
      .state('udb.messages.compose-message.message', {
        url:
          '/message/:subcategoryName?categoryId?subcategoryId?categoryName?businessId',
        views: {
          'cm-content': {
            template: `<app-cm-message></app-cm-message>`,
          },
        },
      })
      .state('udb.messages.confirmation', {
        url: '/confirmation',
        views: {
          messagesContent: {
            template: `<app-cm-confirmation></app-cm-confirmation>`,
          },
        },
      });
  }
}
