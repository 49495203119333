import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideFromRight = trigger('slideFromRight', [
  transition(':enter', [
    style({ transform: 'translateX(100%)', left: 0, right: 0 }),
    animate('500ms ease-in-out', style({ transform: 'translateX(0%)' })),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0%)', left: 0, right: 0 }),
    animate('500ms ease-in-out', style({ transform: 'translateX(100%)' })),
  ]),
]);

export const openClose = trigger('openClose', [
  transition(':enter', [
    style({ maxHeight: 0, left: 0, right: 0 }),
    animate('300ms ease-in', style({ maxHeight: '125rem' })),
  ]),
  transition(':leave', [
    style({ maxHeight: '125rem', left: 0, right: 0 }),
    animate('300ms ease-in', style({ maxHeight: 0 })),
  ]),
]);

export const startStaticSlideFromLeft = trigger('startStaticSlideFromLeft', [
  state('initial', style({ transform: 'translateX(0%)' })),
  state('in', style({ transform: 'translateX(0%)' })),
  transition('* => in', [
    style({ transform: 'translateX(-100%)', left: 0, right: 0 }),
    animate('500ms ease-in-out'),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0%)', left: 0, right: 0 }),
    animate('500ms ease-in-out', style({ transform: 'translateX(-100%)' })),
  ]),
]);
