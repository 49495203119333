<div class="positions-responsive">
  <table class="table ut-table" *ngIf="!showDetail">
    <thead>
      <tr>
        <th>Description</th>
        <th class="positions-responsive__a-right">Mkt Value / Cost Basis</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!positions || positions.length == 0">
        <td colspan="4">There are no positions to display</td>
      </tr>
      <tr *ngFor="let position of positions; let i = index" (click)="showPositionDetail(i)">
        <td class="positions-responsive__width">
          <div class="positions-responsive__bold">
            {{ position.description }}
          </div>
          <div>
            <span>Qty:</span>
            {{ position.quantity }} /
            <span>Price:</span>
            {{ position.price | currency }}
          </div>
        </td>
        <td class="positions-responsive__a-right">
          <div class="positions-responsive__bold">
            {{ position.marketValue | currency }}
          </div>
          <div>
            {{ position.costBasis | currency }}
          </div>
        </td>
        <td class="positions-responsive__a-right vert-middle">
          >
        </td>
      </tr>
      <tr>
        <td>Cash & Cash Investments</td>
        <td class="positions-responsive__a-right">{{ cashInvest | currency }}</td>
      </tr>
    </tbody>
  </table>
  <div class="detail" *ngIf="showDetail">
    <div class="detail__element">
      <div class="detail__title">
        Symbol
      </div>
      <div class="detail__value">
        {{ position.symbol }}
      </div>
    </div>
    <div class="detail__element">
      <div class="detail__title">
        Description
      </div>
      <div class="detail__value">
        {{ position.description }}
      </div>
    </div>
    <div class="detail__element">
      <div class="detail__title">
        Quantity / Price
      </div>
      <div class="detail__value">{{ position.quantity }} / {{ position.price | currency }}</div>
    </div>
    <div class="detail__element">
      <div class="detail__title">
        Market Value
      </div>
      <div class="detail__value">
        {{ position.marketValue | currency }}
      </div>
    </div>
    <div class="detail__element">
      <div class="detail__title">
        Day Change
      </div>
      <div class="detail__value">
        <span [ngClass]="position.dayChange >= 0 ? 'positions--gain' : 'positions--loss'">
          {{ position.dayChange < 0 ? (position.dayChange | currency) : '+' + (position.dayChange | currency) }}
        </span>
        <span [ngClass]="position.dayChangePercent >= 0 ? 'positions--gain' : 'positions--loss'">
          {{
            position.dayChangePercent < 0
              ? '(' + (position.dayChangePercent | percentageRound) + '%)'
              : '(+' + (position.dayChangePercent | percentageRound) + '%)'
          }}
        </span>
      </div>
    </div>
    <div class="detail__element">
      <div class="detail__title">
        Cost Basis
      </div>
      <div class="detail__value">
        {{ position.costBasis }}
      </div>
    </div>
    <div class="detail__element">
      <div class="detail__title">
        Gain / Loss
      </div>
      <div class="detail__value">
        <span [ngClass]="position.gainLoss >= 0 ? 'positions--gain' : 'positions--loss'">
          {{ position.gainLoss < 0 ? (position.gainLoss | currency) : '+' + (position.gainLoss | currency) }}
        </span>
        <span [ngClass]="position.gainLossPercent >= 0 ? 'positions--gain' : 'positions--loss'">
          {{
            position.gainLossPercent < 0
              ? '(' + (position.gainLossPercent | percentageRound) + '%)'
              : '(+' + (position.gainLossPercent | percentageRound) + '%)'
          }}
        </span>
      </div>
    </div>
    <div class="detail__element">
      <div class="detail__title">
        % of Account
      </div>
      <div class="detail__value">{{ position.percentageOfAccount | percentageRound }}%</div>
    </div>
    <div>
      <button class="uk-btn outline primary" type="button" (click)="goBack()">
        Back
      </button>
    </div>
  </div>
</div>
