<div class="account-group">
  <div class="account-group__header frow">
    <div class="fcol-xs-6 fcol-cs-6">
      <span class="account-group__header--title">{{ groupTitle }}</span>
      <app-tooltip
        class="account-group__header-tooltip"
        *ngIf="trasferSection"
        content="{{ trasferMesgtool }}"
      ></app-tooltip>
    </div>
    <div class="fcol-xs-6 fcol-cs-6 text-right">
      <div *ngIf="groupTitle != groupTitleEnum.Other">
        <span
          class="account-group__header--action"
          [hidden]="balanceLoading"
          *ngIf="!areClosedAccounts && isAccountAggregationEnhancementsActive && displayAmount"
        >
          <span class="account-group__header--action">Total:&nbsp;</span>
          <span>{{ totalAmount | currency }}</span>
        </span>
      </div>
      <!-- REMOVE ANCHOR TAG ONCE ACCOUNT AGGREGATION FEATURE HAD BEEN RELEASED -->
      <a
        *ngIf="actionText && !isAccountAggregationEnhancementsActive"
        [routerLink]="actionLink"
        target="_blank"
        class="finline middle account-group__header--action"
      >
        <i class="bofi-add" aria-hidden="true"></i>
        <span>{{ actionText }}</span>
      </a>
    </div>
  </div>
  <div class="account-group__body" cdkDropList (cdkDropListDropped)="onDrop($event)" [cdkDropListData]="accounts">
    <ul class="account-group__body-list">
      <li
        class="account-item frow between bottom"
        *ngFor="let acc of accounts; let index = index"
        id="{{ groupTitle?.split(' ')[0].toLowerCase() + '-' + index }}"
        cdkDrag
        [cdkDragDisabled]="acc.isExternal || acc.isAxosInvest || acc.isAxosAdvisory || acc.isTrading"
      >
        <div
          class="finline fcol-cs-12 fcol-xs-4 cursor-move"
          cdkDragHandle
          [ngClass]="acc.isExternal || acc.isAxosInvest || acc.isAxosAdvisory || acc.isTrading ? 'external' : ''"
        >
          <div>
            <img
              *ngIf="hasImage(acc) || (!acc.isExternal && providers)"
              [src]="getImage(acc)"
              class="account-item__logo"
              [ngClass]="{
              'account-item__logo--new': isAccountAggregationEnhancementsActive,
              'invest': acc.isAxosInvest,
            }"
              aria-hidden="true"
            />
            <div *cdkDragPlaceholder></div>
          </div>
          <span
            class="account-item__logo"
            [ngClass]="{ 'account-item__logo--new': isAccountAggregationEnhancementsActive }"
            *ngIf="!hasImage(acc) && acc.isExternal"
          >
            {{ getBankProfile(acc.bankName) }}
          </span>
          <span class="account-item__logo--new" *ngIf="!providers"></span>
          <div class="fcol">
            <div *ngIf="!acc.transferOnly && acc.category != accountCategory.Other">
              <a (click)="goToDetails(acc)" class="account-item__name link">{{ getNickName(acc) }}</a>
            </div>

            <!-- edit account name, only enable for Transfer Only accounts -->
            <div *ngIf="acc.transferOnly">
              <p class="account-item__name" *ngIf="!acc.isEditing">{{ acc.nickname || acc.name }}</p>
              <div class="frow" *ngIf="acc.isEditing">
                <div class="fcol-xs-8">
                  <input
                    class="uk-input sm account-item__nickname-input"
                    type="text"
                    maxlength="30"
                    [(ngModel)]="acc.nickname"
                    (blur)="cancelNicknameUpdate()"
                    id="nickname"
                    name="nickname"
                    aria-label="Account Nickname"
                  />
                </div>
                <div class="fcol-xs-4">
                  <button
                    id="saveAccountNickname"
                    class="form-control uk-btn sm solid secondary account-item__savebutton"
                    (click)="saveAccountNickname(acc)"
                    (mousedown)="mouseDown = true"
                    (mouseup)="mouseDown = false"
                  >
                    {{ isSaving ? 'Saving...' : 'Save' }}
                  </button>
                </div>
              </div>
            </div>

            <!-- Other section: the account display will not be clickable-->
            <div *ngIf="acc.category == accountCategory.Other && isAccountAggregationEnhancementsActive">
              <span class="frow account-item__name">{{ acc.nickname || acc.name }}</span>

              <span class="frow account-item__bank">{{ getFinancialInstitution(acc) }}</span>
            </div>

            <!--All the other accounts-->
            <div *ngIf="acc && acc.category != accountCategory.Other">
              <span class="account-item__bank">{{ getBankName(acc) }}</span>
            </div>
          </div>
        </div>

        <div class="fcol-cs-12 fcol-xs-4">
          <span
            *ngIf="acc.displayNonActiveMessage && acc.statusCode !== '2' && acc.statusCode !== '8'"
            class="account-item__action text-error"
            title="{{ acc.nonActiveMessage }}"
          >
            {{ acc.nonActiveMessage }}
          </span>
        </div>

        <!-- internal, aggregation and invest accounts balance -->
        <div class="text-right fcol-cs-12 fcol-xs-4">
          <div *ngIf="displayAmount">
            <div class="account-item__balance">
              <div class="account-item__update-credentials">
                <p>
                  <!-- tooltip shown when an aggregation account credentials are outdated -->
                </p>

                <acc-account-warning-tooltip [account]="acc"></acc-account-warning-tooltip>

                <div *ngIf="acc.category == accountCategory.Other && isAccountAggregationEnhancementsActive">
                  <div class="account-item__balance--balanceInfo" *ngIf="acc.container != containerType.Reward">
                    <span
                      [ngClass]="{
                        'text-gray': acc.credentialsHaveChanged,
                        'account-item__balance--invest': acc.isAxosInvest
                      }"
                    >
                      {{ getBalance(acc) | currency }}
                    </span>
                  </div>
                  <div class="account-item__balance--balanceInfo" *ngIf="acc.container == containerType.Reward">
                    <div *ngIf="getRewardUnits(acc) == rewardUnits.Dollars">
                      <span
                        [ngClass]="{
                          'text-gray': acc.credentialsHaveChanged,
                          'account-item__balance--invest': acc.isAxosInvest
                        }"
                      >
                        {{ getBalance(acc) | currency }}
                      </span>
                    </div>
                    <div class="rewardInfo" *ngIf="getRewardUnits(acc) != rewardUnits.Dollars">
                      <div
                        class="balanceAmount"
                        [ngClass]="{
                          'text-gray': acc.credentialsHaveChanged,
                          'account-item__balance--invest': acc.isAxosInvest
                        }"
                      >
                        {{ getBalance(acc) | number }}
                      </div>
                      <div class="rewardUnits">Reward {{ getRewardUnits(acc) }}</div>
                    </div>
                  </div>
                  <div class="account-item__balance--balanceInfo">
                    <i
                      aria-hidden="true"
                      class="bofi-remove"
                      title="Remove account"
                      *ngIf="!external.isRemoving"
                      (click)="removeAggregatedAccount(acc)"
                    ></i>
                  </div>
                </div>
                <div *ngIf="acc.category != accountCategory.Other">
                  <div class="account-item__balance--balanceInfo">
                    <app-dot-loader [showWhen]="balanceLoading"></app-dot-loader>
                    <div
                      [ngClass]="{
                        'text-gray': acc.credentialsHaveChanged,
                        'account-item__balance--invest': acc.isAxosInvest
                      }"
                    >
                      <span *ngIf="!balanceLoading">
                        {{ !acc.hasError ? (getBalance(acc) | currency) : '$--' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <p class="account-item__updated" *ngIf="acc.isExternal && acc.lastUpdated && acc.credentialsHaveChanged">
                <span>{{ getTimePeriod(acc.lastUpdated) }}</span>
              </p>
            </div>
          </div>

          <!-- transfer only section -->
          <div *ngIf="trasferSection" class="centering">
            <div *ngIf="acc.isPendingVerification == false">
              <a
                class="account-item__bofi-activation link"
                *ngIf="acc.status === extAccPendingActivation || acc.status === extAccountPendingActivation"
                (click)="raiseActivationPopup(acc)"
              >
                Activation Required
              </a>
              <span
                class="account-item__bofi-activation"
                *ngIf="acc.status === extAccInProcess || acc.status === extAccountInProcess"
              >
                In Process
              </span>
              <span
                class="account-item__bofi-activation"
                *ngIf="acc.status === extAccFailedVerification || acc.status === extAccountFailedVerification"
              >
                Failed Verification
              </span>
              <i
                [hidden]="
                  acc.status == extAccPendingActivation ||
                  acc.status == extAccountPendingActivation ||
                  acc.status == extAccInProcess ||
                  acc.status == extAccountInProcess ||
                  acc.status === extAccFailedVerification ||
                  acc.status === extAccountFailedVerification
                "
                class="bofi-transfer"
                title="Transfer money to/from account"
                (click)="goToMoveMoney(acc)"
              ></i>
              <i
                class="bofi-edit"
                title="Edit account name"
                [hidden]="
                  acc.status == extAccPendingActivation ||
                  acc.status == extAccountPendingActivation ||
                  acc.status == extAccInProcess ||
                  acc.status == extAccountInProcess ||
                  acc.status === extAccFailedVerification ||
                  acc.status === extAccountFailedVerification
                "
                (click)="editNickname(acc)"
              ></i>
              <i aria-hidden="true" class="bofi-remove" title="Remove account" (click)="removeAccount(acc)"></i>
            </div>
            <div *ngIf="acc.isPendingVerification == true">
              Pending verification...
            </div>
          </div>
        </div>
        <ul class="authorize-transfers-transfers-only-list" *ngIf="trasferSection && authorizeTransfersAAS">
          <li>
            <div>
              <app-authorize-transfers-transfers-only></app-authorize-transfers-transfers-only>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <!-- REMOVE ANCHOR FOOTER DIV ONCE ACCOUNT AGGREGATION FEATURE HAD BEEN RELEASED -->
  <div *ngIf="displayAmount && !isAccountAggregationEnhancementsActive" class="frow between account-group__footer">
    <span>Total</span>
    <span>{{ totalAmount | currency }}</span>
  </div>
</div>
