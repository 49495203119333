import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

import { HoldingState } from '../types';

const getHoldingState = createFeatureSelector<HoldingState>(AasStoreFeatures.Holdings);

export const getSelectedAccountHoldingTile = createSelector(getHoldingState, state => {
  const { accountsHoldings, selectedAccountHoldings } = state;

  if (selectedAccountHoldings === undefined) {
    return undefined;
  }

  const matchingAccount =
    accountsHoldings.find(account => account.accountNumber === selectedAccountHoldings) ?? undefined;

  return matchingAccount;
});
