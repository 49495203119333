import { InjectionToken } from '@angular/core';
import { RedirectStateService } from '@legacy/services/RedirectStateService';
import { FlowService } from '@legacy/services/flow.service';
export const ACCOUNTAGGREGATIONSERVICE = new InjectionToken('accountAggregationService');
export const SERVICEHELPER = new InjectionToken('serviceHelper');
export const CACHEDACCOUNTSSERVICE = new InjectionToken('cachedAccountsService');
export const FLOWSERVICE = new InjectionToken<FlowService>('flowService');
export const FEATUREFLAGSERVICE = new InjectionToken('featureFlagService');
export const REDIRECT_STATE_SERVICE = new InjectionToken<RedirectStateService>('redirectStateService');

export const accountAggregationServiceProvider = [
  {
    provide: ACCOUNTAGGREGATIONSERVICE,
    useFactory: (i: any) => i.get('accountAggregationService'),
    deps: ['$injector'],
  },
  {
    provide: SERVICEHELPER,
    useFactory: (i: any) => i.get('serviceHelper'),
    deps: ['$injector'],
  },
  {
    provide: CACHEDACCOUNTSSERVICE,
    useFactory: (i: any) => i.get('cachedAccountsService'),
    deps: ['$injector'],
  },
  {
    provide: FLOWSERVICE,
    useFactory: (i: any) => i.get('flowService'),
    deps: ['$injector'],
  },
  {
    provide: FEATUREFLAGSERVICE,
    useFactory: (i: any) => i.get('featureFlagService'),
    deps: ['$injector'],
  },
  {
    provide: REDIRECT_STATE_SERVICE,
    useFactory: (i: any) => i.get('redirectStateService'),
    deps: ['$injector'],
  },
];
