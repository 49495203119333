class AlertBox implements ng.IDirective {
  restrict = 'E';
  templateUrl = 'alerts-notifications/my-alerts/directives/alert.html';
  replace = true;

  constructor() {}

  static factory(): ng.IDirectiveFactory {
    const directive = () => new AlertBox();
    directive.$inject = [];
    return directive;
  }
}

export { AlertBox };
