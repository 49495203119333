import { Component, Inject, OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { ActivateAccountModalData, ExternalAccountActivation } from '@app/accounts/models';
import { OlbSettings } from '@core/models';
import { AccountsService } from '@core/services';
import { olbSettings } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-dialog',
  templateUrl: './activate-account-modal.component.html',
  styleUrls: ['./activate-account-modal.component.scss'],
})
export class ActivateAccountModalComponent implements OnInit {
  activateForm: UntypedFormGroup;
  activationPayload: ExternalAccountActivation;

  busy: boolean;
  backgroundUrl: string;
  deleteAccount = new EventEmitter();
  hideCancel: boolean;
  maskedAmount = '00';
  iconConfig = {
    header: {
      size: '2rem',
      icon: UtilityIcons.ThumbsUp,
    },
    close: {
      size: '1rem',
      icon: NavigationIcons.Ex,
    },
  };

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: ActivateAccountModalData,
    public dialogRef: MatDialogRef<ActivateAccountModalComponent>,
    private accountsService: AccountsService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit() {
    const { brand } = this.env;
    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
    this.activateForm = new UntypedFormGroup({
      firstAmount: new UntypedFormControl('00'),
      secondAmount: new UntypedFormControl('00'),
    });
  }

  activateExternalAccount() {
    this.buildActivationPayload();
    this.busy = true;
    this.accountsService
      .activateExternalAccount(this.activationPayload)
      .pipe(
        finalize(() => {
          this.busy = false;
        })
      )
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  onFocus(event) {
    const id = event.currentTarget.id;
    const amount = this.activateForm.get(id).value;
    if (amount === this.maskedAmount) {
      this.activateForm.get(id).setValue('.', { emitEvent: false });
    }
  }
  onBlur(event) {
    const id = event.currentTarget.id;
    const amount = this.activateForm.get(id).value;
    if (amount === '.' || amount === '') {
      this.activateForm.get(id).setValue(this.maskedAmount, { emitEvent: false });
    }
  }
  private buildActivationPayload() {
    this.activationPayload = {
      accountMask: this.data.account.accountMask,
      externalAccountId: this.data.account.externalAccountId,
      firstAmount: +this.activateForm.get('firstAmount').value / 100,
      secondAmount: +this.activateForm.get('secondAmount').value / 100,
    };
  }
}
