import { Inject } from 'decorators/decorators';
import { IRootScopeService } from 'angular';
import { PaymentsService } from 'services/payments.service';

@Inject('$rootScope', 'paymentsService')
export class CustomerLimitsHelper {
  constructor(
    private readonly rootScope: IRootScopeService,
    private readonly paymentsService: PaymentsService
  ) {}

  /**
   * Tries to get and return "customerLimits" setting from appSettings
   * if already available, if it's not, waits until it is and then returns it.
   * @returns A promise containing "customerLimits" info from appSettings
   */
  fetchCustomerLimits(): Promise<CustomerLimits> {
    return new Promise(resolve => {
      if (this.rootScope['customerLimits']) {
        resolve(this.getCustomerLimits());
      } else {
        this.paymentsService.getCustomerLimits().then(res => {
          this.rootScope['customerLimits'] = res.data;
          resolve(this.getCustomerLimits());
        });
      }
    });
  }

  /**
   * Gets "customerLimits" setting from appSettings
   * @returns An object containing "customerLimits" info from appSettings
   */
  private getCustomerLimits(): CustomerLimits {
    return this.rootScope['customerLimits'];
  }
}
