<div class="container">
  <app-click-switch-header step="1" subtitle="Step 1 of 3" (back)="goBack()"></app-click-switch-header>
  <div class="row justify-content-center">
    <div class="row justify-content-center">
      <p class="submittable-title">{{ headerText }}</p>
      <div class="col-12 col-sm-8 text-center mt-1">
        <p class="submittable-subtitle">{{ subHeaderText }}</p>
      </div>
    </div>
    <form [formGroup]="targetDataForm" class="address__form col-6 mt-5" (ngSubmit)="saveTarget()" novalidate>
      <div class="form-group" [class.has-error]="submittableValidator('targetname')">
        <label for="targetname" class="uk-label">Income Source</label>
        <div id="incomeSourceName" *ngIf="existingTarget">
          <ul class="list-unstyled">
            <li class="income-Source__label">{{ userSwitch.name }}</li>
            <li class="income-Source__label">{{ userSwitch.location }}</li>
          </ul>
        </div>
        <div *ngIf="!partialDataCompleted">
          <div *ngIf="!existingTarget">
            <input
              id="targetname"
              type="text"
              name="targetname"
              class="uk-input"
              placeholder="Income Source"
              formControlName="targetname"
            />
            <div *ngIf="submittableValidator('targetname') as error">
              <span class="text-error">
                {{ error }}
              </span>
            </div>
          </div>
          <div [class.has-error]="submittableValidator('street')">
            <label for="street" class="uk-label">Mailing Address</label>
            <input id="street" name="street" class="uk-input" placeholder="Street Address" formControlName="street" />
            <div *ngIf="submittableValidator('street') as error">
              <span class="text-error">
                {{ error }}
              </span>
            </div>
          </div>
          <div [class.has-error]="submittableValidator('street2')">
            <input
              id="street2"
              name="street2"
              class="uk-input mb-0"
              placeholder="Apt/Suite #"
              formControlName="street2"
            />
          </div>
          <div class="form-group pl-3 pr-3">
            <div class="row">
              <div class="col-md-7 no-padding-left pr-1">
                <input name="city" class="uk-input mb-0" placeholder="City" formControlName="city" />
              </div>
              <div class="col-md-5 no-padding">
                <input name="stateCode" class="uk-input mb-0" placeholder="State Code" formControlName="stateCode" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 no-padding-left pr-1" [class.has-error]="submittableValidator('zipcode')">
                <input
                  id="zipcode"
                  name="zipcode"
                  class="uk-input"
                  placeholder="Zip Code"
                  maxlength="5"
                  formControlName="zipcode"
                />
                <p class="loading" *ngIf="isValidatingZipCode">Validating zip code...</p>
              </div>
              <div class="col-md-7 no-padding">
                <select name="country" class="uk-input" formControlName="country">
                  <option *ngFor="let country of countriesKeys" [value]="country">
                    {{ countries[country] }}
                  </option>
                </select>
              </div>
              <div class="col-12" *ngIf="submittableValidator('zipcode') as error">
                <span class="text-error">
                  {{ error }}
                </span>
              </div>
              <div class="col-12" *ngIf="submittableValidator('city') as error">
                <span class="text-error">
                  {{ error }}
                </span>
              </div>
              <div class="col-12" *ngIf="submittableValidator('stateCode') as error">
                <span class="text-error">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" [class.has-error]="submittableValidator('ssn')">
          <label for="targetname" class="uk-label">SSN (last4)</label>
          <input
            id="ssn"
            type="password"
            name="ssn"
            class="uk-input"
            placeholder="SSN (last4)"
            maxlength="4"
            formControlName="ssn"
          />
          <div *ngIf="submittableValidator('ssn') as error">
            <span class="text-error">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="targetname" class="uk-label">Income Type</label>
          <select name="incometype" class="uk-input" formControlName="incometype">
            <option *ngFor="let type of incomeTypeKeys" [value]="type">
              {{ incomeTypes[type] }}
            </option>
          </select>
          <span class="pl-3 text--small">Payroll, Pension, Annuity, etc.</span>
        </div>
        <app-dot-loader [showWhen]="isLoading"></app-dot-loader>
        <div *ngIf="!isLoading" class="address__form-btns row justify-content-center">
          <button type="submit" class="uk-btn lg solid secondary m-4" [disabled]="isUnavailable">
            Continue
          </button>
          <button type="button" class="link col-12" (click)="goToStartPage()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
