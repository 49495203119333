import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { UserProfileService } from '@core/services';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

import {
  getRIAHasNoAxosAccounts,
  getRIAHasNoAxosAccountsSuccess,
  getRIAPilotFlagSuccess,
  getRIAUserFlag,
  getRIAUserFlagSuccess,
} from './support-view.actions';

@Injectable()
export class RIAUserEffect {
  constructor(
    private userProfileService: UserProfileService,
    private store: Store,
    private featureFlagService: FeatureFlagService
  ) {}

  getRIAUserFlag() {
    this.store.dispatch(getRIAUserFlag());

    this.userProfileService.getUserProfileDetails().subscribe(serviceResult => {
      this.store.dispatch(getRIAUserFlagSuccess({ isRIAUserflag: serviceResult.data.isRIAUser }));
    });
  }

  getRIAPilotFeatureFlag() {
    this.store.dispatch(getRIAPilotFlagSuccess({ isRIAPilotActive: this.featureFlagService.isRiaPilotActive() }));
  }

  getHasAxosAccounts() {
    this.store.dispatch(getRIAHasNoAxosAccounts());

    this.userProfileService.getHasAxosAccounts().subscribe(serviceResult => {
      this.store.dispatch(getRIAHasNoAxosAccountsSuccess({ userHasNoAxosAccounts: serviceResult.data }));
    });
  }
}
