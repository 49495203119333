<div class="funding-method no-wrap">
  <div class="frow center method-img">
    <div class="funding-method__icon">
      <img [src]="imageSource" [alt]="name" />
    </div>
  </div>
  <div class="method-info">
    <div>
      <h3 class="method-info__name" [textContent]="name"></h3>
      <p class="method-info__description" [textContent]="description"></p>
    </div>
    <div class="method-info__directions" [innerHtml]="directions"></div>
    <div class="frow center method-info__btn">
      <button type="button" class="uk-btn lg solid secondary" (click)="onClick()" [disabled]="isBusy">
        {{ isBusy ? 'Loading...' : btnText }}
      </button>
    </div>
  </div>
</div>
