<div class="ria-documents-container">
  <ng-container *ngIf="tileState != tileStates.TotalError; else totalErrorState">
    <ng-container>
      <div class="statement-header">
        <h4 class="title">Statements & Documents</h4>
      </div>
      <hr />
    </ng-container>
    <ng-container *ngIf="tileState == tileStates.PartialError">
      <div class="ria-documents-error-state">
        <uk2-text-tile
          [uk2TextTileSvgIconName]="uk2UtilityIcons.cog"
          [uk2TextTileTitle]="errorStateLabels.title"
          [uk2TextTileIsStandaloneTile]="documents.length === 0"
          [uk2TextTileDisplayBackground]="documents.length === 0"
        >
          <p>
            {{ errorStateLabels.body }}
          </p>
        </uk2-text-tile>
      </div>
    </ng-container>

    <ng-container *ngIf="documents && documents.length > 0; else noDocuments">
      <div class="statement-container" *ngFor="let document of documents">
        <div class="document-info-container">
          <div class="document-icon-container start" (click)="showOrDownloadDocument(document, true)">
            <app-svg-icon
              [iconName]="statementsIcon"
              iconColor="var(--primary)"
              width="18px"
              height="25px"
            ></app-svg-icon>
          </div>
          <div class="document-info" (click)="showOrDownloadDocument(document, true)">
            <div class="document-date-container">
              <p>
                {{ document?.statementDate ? (document?.statementDate | date: 'MMMM d, YYYY') : document?.taxFormDate }}
              </p>
            </div>
            <div class="document-name-container">
              <p>{{ document?.statementDescription || document?.taxFormDescription }}</p>
            </div>
          </div>
          <div class="download-icon-container end">
            <app-svg-icon
              [iconName]="downloadIcon"
              iconColor="var(--primary)"
              width="18px"
              height="18px"
              (click)="showOrDownloadDocument(document, false)"
            ></app-svg-icon>
          </div>
        </div>
        <hr />
      </div>
    </ng-container>

    <ng-template #noDocuments>
      <div class="no-documents-container" *ngIf="tileState == tileStates.Success">
        <p>Once you have statements and documents available for this account, you'll see them here</p>
      </div>
    </ng-template>

    <div *ngIf="documents?.length > 0" class="view-all-container">
      <button (click)="goToDetails()" uk2TextButton disableRipple [uk2ButtonSize]="viewAllButtonSize" mat-button>
        View All Statements & Documents
      </button>
    </div>
  </ng-container>
  <ng-template #totalErrorState>
    <div class="ria-documents-error-state">
      <uk2-text-tile
        [uk2TextTileSvgIconName]="uk2UtilityIcons.cog"
        [uk2TextTileTitle]="errorStateLabels.title"
        [uk2TextTileIsStandaloneTile]="true"
      >
        <p>
          {{ errorStateLabels.body }}
        </p>
      </uk2-text-tile>
    </div>
  </ng-template>
</div>
