import { TileState } from '../enums';

export const STATEMENTS_AND_DOCUMENTS_LABELS = {
  [TileState.PartialError]: {
    errorTitle: 'Certain Statements or Documents Temporarily Unavailable',
    errorContent:
      "We're sorry, but we're currently unable to update you with the latest information on certain portfolio’s statements and documents. Please check back later. Thank you for your patience.",
  },
  [TileState.TotalError]: {
    errorTitle: 'Statements and Documents Temporarily Unavailable',
    errorContent:
      "We're sorry, but we're currently unable to update you with the latest information on your portfolio's statements and documents. Please check back later. Thank you for your patience.",
  },
};
