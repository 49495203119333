import { Inject } from '../../../../decorators/decorators';
import { IStateService } from 'angular-ui-router';
import { BillPayRouterHelper } from '../../../bill-pay-router.helper';
import { PaymentData } from 'bill-pay/typings/PaymentData';
import { UserAccountType } from 'typings/app/bills/UserAccountType';

@Inject('$state', 'billPayRouterHelper', 'loadUserProfileHelper')
export class PayConfirmationController {
  payments: PaymentData[] = [];
  email: string;
  accountFrom: UserAccountType;

  constructor(
    private readonly state: IStateService,
    private readonly billPayRouterHelper: BillPayRouterHelper,
    private readonly loadUserProfileHelper: ILoadUserProfileHelper
  ) {}

  $onInit(): void {
    this.payments = this.billPayRouterHelper.getPaymentData();
    this.accountFrom = this.billPayRouterHelper.getAccountFrom();
    if (!this.payments) {
      this.state.go('udb.billPay.pay');
    }
    const profile = this.loadUserProfileHelper.getUserProfile();
    this.email = profile ? profile.primaryEmail : '';
  }
}
