import { AggregatedAccount } from './aggregated-account.model';

export class CategorizedAccounts {
  checking: Record<number, AggregatedAccount> = {};
  savings: Record<number, AggregatedAccount> = {};
  loan: Record<number, AggregatedAccount> = {};
  cd: Record<number, AggregatedAccount> = {};
  credit: Record<number, AggregatedAccount> = {};
  other: Record<number, AggregatedAccount> = {};
  unknown: Record<number, AggregatedAccount> = {};

  constructor(args?: Partial<CategorizedAccounts>) {
    Object.assign(this, args);
  }
}
