import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { format } from 'date-fns';

import { ContainerType } from '@app/accounts/enums';
import { AccountOrigin } from '@app/pfm/enums/account-origin.enum';
import { TimePeriod } from '@app/pfm/enums/time-period.enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { AccountWorth, NetWorthPoint, Worth } from '@app/pfm/models/net-worth-point.model';
import { PfmAccountsService } from '@app/pfm/services/pfm-accounts.service';
import { AccountsEffects } from '@app/pfm/store/accounts/accounts.effects';
import { getAccounts, getAccountsLoading, getProviders } from '@app/pfm/store/accounts/accounts.selectors';
import { NetWorthFilters } from '@app/pfm/store/net-worth/net-worth-filters';
import {
  commitFilters,
  resetExcludedAccountsFilter,
  resetUncommittedFilters,
  setExcludedAccountsFilter,
} from '@app/pfm/store/net-worth/net-worth.actions';
import {
  getCommittedFilters,
  getCommittedFiltersInDefaultState,
  getNetWorthSeries,
  getNetWorthSeriesLoading,
  getUncommittedExcludedAccountsFilter,
  getUncommittedTimePeriodFilter,
} from '@app/pfm/store/net-worth/net-worth.selectors';
import { STATE } from '@core/tokens';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { NavigationIcons, UtilityIcons } from '@shared/enums';
import { ExternalBankProvider } from '@shared/models';

import { ParentPage } from '../../enums/parent-page.enum';
import { BalanceOverride } from '../accounts-filter/models/balance-override.model';
import { FiltersModalNetWorthComponent } from '../filters-modal-net-worth/filters-modal-net-worth.component';
import { LineChartPoint } from '../line-chart/models/point.model';

@Component({
  selector: 'app-net-worth',
  templateUrl: './net-worth.component.html',
  styleUrls: ['./net-worth.component.scss'],
})
export class NetWorthComponent implements OnInit, OnDestroy {
  netWorthSeriesLoading = this.store.select(getNetWorthSeriesLoading);
  accountsLoading = this.store.select(getAccountsLoading);
  timePeriodFilter = this.store.select(getUncommittedTimePeriodFilter);
  excludedAccountsFilter = this.store.select(getUncommittedExcludedAccountsFilter);
  committedFiltersInDefaultState = this.store.select(getCommittedFiltersInDefaultState);

  icons = {
    arrowBack: NavigationIcons.ArrowBack,
    FilterSlider: UtilityIcons.FilterSlider,
    Checkmark: UtilityIcons.Checkmark,
  };
  subsink = new SubSink();

  providers: ExternalBankProvider[];
  accounts: AccountOverview[];
  accountsFilterBalanceOverrides: { [accountGlobalId: string]: BalanceOverride } = {};
  netWorthPeriodSeries: NetWorthPoint[];
  currentNetWorth: NetWorthPoint;
  previousNetWorth: NetWorthPoint;

  lineChartPoints: LineChartPoint[] = [];
  committedFilters = new NetWorthFilters();
  xAxisMaxTicks = 7;
  formatAxisTick;

  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(DOCUMENT) private readonly document: Document,
    readonly dialog: MatDialog,
    private store: Store,
    public accountsEffects: AccountsEffects,
    public accAggFormatter: AccountAggregationChartFormatterService,
    private pfmAccountsService: PfmAccountsService
  ) {
    this.formatAxisTick = this.lineChartXAxisTickFormatter.bind(this);
  }
  commitFilters() {
    this.store.dispatch(commitFilters());
  }

  ngOnInit(): void {
    this.document.body.style.backgroundColor = '#F4F5F7';

    this.store.dispatch(resetUncommittedFilters());
    this.store.dispatch(commitFilters());

    this.accountsEffects.loadAccounts(this.subsink);

    this.subsink.sink = this.store.select(getCommittedFilters).subscribe(committedFilters => {
      this.committedFilters = committedFilters;
    });

    this.subsink.sink = this.store.select(getProviders).subscribe(providers => {
      this.providers = providers;
    });

    this.subsink.sink = this.store.select(getAccounts).subscribe(accounts => {
      this.accounts = accounts;
    });

    this.subsink.sink = this.store
      .select(getNetWorthSeries)
      .pipe(filter(series => series != null))
      .subscribe(series => {
        let seriesWithoutEmpty = series.filter(x => x.worth !== null);

        const yodleeData = seriesWithoutEmpty.length !== 0;

        this.netWorthPeriodSeries = seriesWithoutEmpty;
        seriesWithoutEmpty = this.addInternalDataToSeries(seriesWithoutEmpty);
        this.previousNetWorth = seriesWithoutEmpty[0];
        this.currentNetWorth = seriesWithoutEmpty[seriesWithoutEmpty.length - 1];

        this.accountsFilterBalanceOverrides = (this.currentNetWorth?.worth?.accountWorths || [])
          .filter(a => a.account.origin === AccountOrigin.Yodlee && a.account.container === ContainerType.Investment)
          .reduce(
            (dict, a) => {
              dict[a.account.globalId] = { accountGlobalId: a.account.globalId, balance: a.balance };

              return dict;
            },
            { ...this.accountsFilterBalanceOverrides }
          );

        if (
          this.committedFilters.timePeriod === TimePeriod.Last12Months ||
          this.committedFilters.timePeriod === TimePeriod.AllTime
        ) {
          this.xAxisMaxTicks = 13;
        }

        if (!yodleeData) series = this.addInternalDataToSeries(series);

        this.lineChartPoints = series.map(point => ({
          date: point.date,
          amount: point.worth?.net ?? 0,
          isDashed: point.worth === null,
        }));
      });
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goBack() {
    this.state.go('udb.accounts.insights');
  }

  openFiltersModal() {
    this.dialog
      .open(FiltersModalNetWorthComponent, {
        data: {
          providers: this.providers,
          accounts: this.accounts,
          accountsFilterBalanceOverrides: this.accountsFilterBalanceOverrides,
          parentPage: ParentPage.NetWorth,
        },
        disableClose: true,
        panelClass: ['full-screen-modal'],
      })
      .afterClosed()
      .subscribe(_ => this.commitFilters());
  }

  lineChartXAxisTickFormatter(date: Date, index: number, dates: Date[]) {
    if (index === dates.length - 1) return 'Today';
    else if (
      !!this.committedFilters &&
      (this.committedFilters.timePeriod === TimePeriod.Last6Months ||
        this.committedFilters.timePeriod === TimePeriod.Last12Months)
    ) {
      return `   ${format(date, 'MMM')}   `;
    } else return `   ${format(date, 'M/d')}   `;
  }

  onAccountsFilterReset() {
    this.store.dispatch(resetExcludedAccountsFilter());
  }
  onAccountsFilterChange(newFilter) {
    this.store.dispatch(setExcludedAccountsFilter({ payload: newFilter }));
  }

  private getInternalAccounts(): AccountOverview[] {
    if (!this.accounts) return [];

    return this.accounts.filter(
      account =>
        (account.origin === AccountOrigin.AxosInvest || account.origin === AccountOrigin.Clearing) &&
        !this.committedFilters.excludedAccounts.has(account.globalId)
    );
  }

  private addInternalDataToSeries(seriesWithoutEmpty: NetWorthPoint[]) {
    let internalAccountsTotalBalance = 0;
    let netWithInternalAccountsBalance = 0;
    let internalAccountWorths: AccountWorth[] = [];
    const internalAccounts = this.getInternalAccounts();

    if (internalAccounts.length !== 0) {
      internalAccountsTotalBalance = internalAccounts.reduce((sum, account) => {
        if (this.pfmAccountsService.getIsAsset(account.categoryName)) return sum + account.availableBalance;

        return sum - account.availableBalance;
      }, 0);

      netWithInternalAccountsBalance = internalAccounts.reduce((sum, account) => {
        if (this.pfmAccountsService.getIsAsset(account.categoryName)) return sum + account.availableBalance;

        return sum - account.availableBalance;
      }, 0);

      internalAccountWorths = internalAccounts.map(internalAccount => {
        return {
          account: internalAccount,
          isAsset: this.pfmAccountsService.getIsAsset(internalAccount.categoryName),
          balance: internalAccount.availableBalance,
        };
      });
    }

    if (seriesWithoutEmpty.length !== 0) {
      seriesWithoutEmpty = seriesWithoutEmpty.map((series, index) => {
        if (index === seriesWithoutEmpty.length - 1) {
          const worthWithInternalBalances: Worth = {
            ...series.worth,
            asset: series.worth ? series.worth.asset + internalAccountsTotalBalance : internalAccountsTotalBalance,
            net: series.worth ? series.worth.net + netWithInternalAccountsBalance : netWithInternalAccountsBalance,
            accountWorths: series.worth
              ? series.worth.accountWorths.concat(...internalAccountWorths)
              : internalAccountWorths,
          };
          series = {
            ...series,
            worth: worthWithInternalBalances,
          };

          return series;
        }

        return series;
      });

      return seriesWithoutEmpty;
    } else {
      const newSeries: NetWorthPoint[] = [
        {
          date: new Date(),
          worth: {
            asset: internalAccountsTotalBalance,
            liability: 0,
            net: netWithInternalAccountsBalance,
            accountWorths: internalAccountWorths,
          },
        },
      ];

      return newSeries;
    }
  }
}
