import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import {
  AccountPeopleRequest,
  AccountPeopleResponse,
  AddInterestedPartyRequest,
  AddTrustedContactRequest,
  AddTrustedContactResponse,
  DeleteTrustedContactRequest,
  EditInterestedPartyRequest,
  EditTrustedContactRequest,
  EditTrustedContactResponse,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class AccountPeopleService extends BaseService {
  /**
   * AccountPeople constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */

  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/accountpeople');
  }

  /**
   * Returns the account people of account number from AAS customer.
   *
   * @param request AccountPeopleRequest
   * @returns WebApiResponse of AccountPeopleResponse
   *
   */

  getAccountPeople(request: AccountPeopleRequest): WebApiResponse<AccountPeopleResponse> {
    return this.get<AccountPeopleResponse>(request.accountNumber);
  }

  /**
   * @param {AddTrustedContactRequest} request
   * @return {AddTrustedContactResponse}  {WebApiResponse<AddTrustedContactResponse>}
   */
  addTrustedContact(request: AddTrustedContactRequest): WebApiResponse<AddTrustedContactResponse> {
    return this.post(`${request.accountNumber}/trustedcontact`, request.trustedContact);
  }

  /**
   * @param {EditTrustedContactRequest} request
   * @return {EditTrustedContactResponse}  {WebApiResponse<EditTrustedContactResponse>}
   */
  editTrustedContact(request: EditTrustedContactRequest): WebApiResponse<EditTrustedContactResponse> {
    return this.put(`${request.accountNumber}/trustedcontact`, request.trustedContact);
  }

  /**
   * @param {AccountPeopleRequest} request
   * @return {boolean}  {WebApiResponse<boolean>}
   */
  deleteTrustedContact(request: DeleteTrustedContactRequest): WebApiResponse<boolean> {
    return this.delete(`${request.accountNumber}/trustedcontact`);
  }

  /**
   * @param {AddInterestedPartyRequest} request
   * @return {null}  {WebApiResponse<null>}
   */
  addInterestedParty(request: AddInterestedPartyRequest): WebApiResponse<null> {
    return this.post(`${request.accountNumber}/interestedparty`, request.interestedParty);
  }

  /**
   * @param {EditInterestedPartyRequest} request
   * @return {null}  {WebApiResponse<null>}
   */
  editInterestedParty(request: EditInterestedPartyRequest): WebApiResponse<null> {
    return this.put(`${request.accountNumber}/interestedparty`, request.interestedParty);
  }
}
