import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-internal-to-external',
  templateUrl: './internal-to-external.component.html',
  styleUrls: ['./internal-to-external.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalToExternalComponent {
  icons = {
    exclamationCircle: AlertsIcons.ExclamationCircle,
  };
  exclamation_icon = AlertsIcons.ExclamationCircle;
  constructor(
    private dialogRef: MatLegacyDialogRef<InternalToExternalComponent>
  ) {}

  onTryAgain(): void {
    this.dialogRef.close();
    this.reloadWindow();
  }
  reloadWindow() {
    window.location.reload();
  }
}
