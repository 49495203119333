import { Component } from '@angular/core';

import { TransactionDetailComponent } from '@app/axos-invest/components';
import { FeeTransfer } from '@app/axos-invest/models';

@Component({
  selector: 'app-fee-transaction-information',
  templateUrl: './fee-transaction-information.component.html',
  styleUrls: ['./fee-transaction-information.component.scss'],
})
export class FeeTransactionInformationComponent implements TransactionDetailComponent {
  transaction: FeeTransfer;
}
