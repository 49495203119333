import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxErrorsModule } from '@axos/ngx-errors';
import { NgxMaskModule } from 'ngx-mask';

import { enrollmentServiceProvider, materialDialogProvider, serviceHelperProvider } from '@core/providers';

import { sharedComponents } from './components';
import { materialImports } from './config';
import { sharedDirectives } from './directives';
import { sharedPipes } from './pipes';
import { SharedUk2Module } from './shared-uk2.module';

const sharedModules = [CommonModule, FormsModule, ReactiveFormsModule, NgxErrorsModule, NgxMaskModule];

@NgModule({
  declarations: [...sharedComponents, ...sharedDirectives, ...sharedPipes],
  imports: [BrowserAnimationsModule, ...sharedModules, ...materialImports, SharedUk2Module],
  exports: [...sharedModules, ...sharedComponents, ...sharedDirectives, ...sharedPipes, materialImports],
  providers: [serviceHelperProvider, enrollmentServiceProvider, materialDialogProvider],
})
export class SharedModule {}
