import { of, from, throwError } from 'rxjs';
import { tap, map, catchError, finalize } from 'rxjs/operators';

import { ObservableResponse } from 'models';
import * as jsCookie from 'js-cookie';

/** Base class for all UDB services. */
export abstract class ObservableBaseService {
  static pendingRequests: any[] = [];
  protected serviceUrl: string;
  protected headers: ng.IHttpRequestConfigHeaders = {};
  private reqConfig: ng.IRequestShortcutConfig;
  private facingBrandId: number;

  /**
   * Use the base service class to create your services.
   * @param http Angular HTTP service.
   * @param env Variable set on startup that has the API endpoint.
   * @param prefix Prefix to use with the service. For example: 'auth';
   */
  constructor(
    protected http: ng.IHttpService,
    env: any,
    prefix: string,
    protected serviceHelper: IServiceHelper,
    private q: ng.IQService
  ) {
    this.serviceUrl = `${env.api}/${prefix}`;
    this.facingBrandId = env.facingBrandId;
    this.errorHandler = this.errorHandler.bind(this);
  }

  /**
   * Triggers a HTTP POST to the server with the specified endpoint and data.
   * @param [endpoint=''] The endpoint to POST to. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected post(endpoint: string = '', data: any = null): ObservableResponse<any> {
    this.setRequestHeaders();
    const postConfig = this.cloneReqConfig();

    ObservableBaseService.pendingRequests.push(postConfig);

    return from(
      this.http.post(
        `${this.serviceUrl}${endpoint ? `/${endpoint}` : ''}`,
        data,
        postConfig
      ) as PromiseLike<any>
    ).pipe(
      tap(() => this.removeFromPending(postConfig)),
      map(res => res.data),
      catchError(this.errorHandler)
    );
  }

  /**
   * Triggers a HTTP GET to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to GET from. Defaults to empty string.
   * @param [queryParams] Any data to be sent to the server. Keys must match query parameter name.
   * @param [cache=false] Specifies if the request should be cached.
   * @param [serviceUrl] The serviceUrl to override the default.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected get<T>(
    endpoint: string = '',
    queryParams?: Object,
    cache: boolean = false,
    serviceUrl?: string,
    responseType?: string
  ): ObservableResponse<T> {
    let service = serviceUrl || this.serviceUrl;
    this.setRequestHeaders();
    let getConfig = this.cloneReqConfig();
    getConfig.params = queryParams;
    getConfig.cache = cache;
    getConfig.responseType = responseType;

    return from(
      this.http.get(`${service}${endpoint ? `/${endpoint}` : ''}`, getConfig) as PromiseLike<any>
    ).pipe(
      tap(() => {
        this.removeFromPending(getConfig);
      }),
      map(res => res.data),
      catchError(this.errorHandler)
    );
  }

  /**
   * Triggers a HTTP DELETE to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to DELETE from. Defaults to empty string.
   * @param [queryParams] Any data to be sent to the server. Keys must match query parameter name.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected delete(endpoint: string = '', queryParams?: Object): ObservableResponse<any> {
    this.setRequestHeaders();
    let deleteConfig = this.cloneReqConfig();
    deleteConfig.params = queryParams;

    return from(
      this.http.delete(
        `${this.serviceUrl}${endpoint ? `/${endpoint}` : ''}`,
        deleteConfig
      ) as PromiseLike<any>
    ).pipe(
      tap(() => this.removeFromPending(deleteConfig)),
      map(res => res.data),
      catchError(this.errorHandler)
    );
  }

  /**
   * Triggers a HTTP PUT to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to PUT at. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected put(endpoint: string = '', data: any = null): ObservableResponse<any> | any {
    this.setRequestHeaders();
    const putConfig = this.cloneReqConfig();

    return from(
      this.http.put(
        `${this.serviceUrl}${endpoint ? `/${endpoint}` : ''}`,
        data,
        putConfig
      ) as PromiseLike<any>
    ).pipe(
      tap(() => this.removeFromPending(putConfig)),
      map(res => res.data),
      catchError(this.errorHandler)
    );
  }

  /**
   * Downloads a file from the server.
   * @param endpoint API endpoint from which the file should be retrieved.
   * @returns A promise that can be resolved when the file has downloaded.
   */
  protected download(
    endpoint: string,
    queryParams?: any,
    onFinally?: any,
    cache: boolean = false
  ): Promise<any> {
    // Search is the property name that it's used to send queryParams.
    let search = this.transformParameters(queryParams);
    this.setRequestHeaders();
    let getConfig = this.cloneReqConfig();
    getConfig.params = queryParams;
    getConfig.cache = cache;
    getConfig.responseType = 'blob';
    getConfig['search'] = search;

    return from(
      this.http.get(
        `${this.serviceUrl}${endpoint ? `/${endpoint}` : ''}`,
        getConfig
      ) as PromiseLike<any>
    )
      .pipe(
        map(res => {
          const contentDisposition = res.headers('content-disposition');
          const filename = this.getFileNameFromHeader(contentDisposition);
          const contentType = this.getContentType(filename);
          const file = new Blob([res.data], { type: contentType });
          return { file, filename };
        }),
        finalize(onFinally)
      )
      .toPromise()
      .then(res => {
        saveAs(res.file, res.filename);
      });
  }

  /**
   * Clones the request configuration to prevent overriding the original one.
   * @returns A cloned configuration file based on the private one.
   */
  private cloneReqConfig(): ng.IRequestShortcutConfig {
    let canceller = this.q.defer();
    let newConfig: ng.IRequestShortcutConfig = {};

    newConfig = Object.assign({}, this.reqConfig);

    newConfig.timeout = canceller.promise;

    return newConfig;
  }

  private removeFromPending(config: ng.IRequestShortcutConfig): void {
    ObservableBaseService.pendingRequests = ObservableBaseService.pendingRequests.filter(
      item => item !== config
    );
  }

  private errorHandler(error: ApiError): ObservableResponse<null> {
    if (error.status === 401) {
      this.serviceHelper.signOut();
      return of(null);
    }

    return throwError(error);
  }

  /**
   * Gets the filename from the Content-Disposition header.
   * @param header Content-Disposition header string.
   * @returns File name as string.
   */
  private getFileNameFromHeader(header: string): string {
    if (!header) return null;

    let result: string = header.split(';')[1].trim().split('=')[1];

    return result.replace(/"/g, '');
  }

  /**
   * Transforms the given object into a URLSearchparams object.
   * This is intended to be executed before a GET request.
   * @param params The data to be transformed (doesn't allow objects).
   * @returns URLSearchParams object containing the data to send.
   */
  private transformParameters(params: any): URLSearchParams {
    try {
      let urlSearchParams = new URLSearchParams();
      for (let property in params) {
        let parameter = params[property];

        if (parameter instanceof Date) {
          let dateParam = parameter.toDateString();
          urlSearchParams.set(property, dateParam);
        } else if (Array.isArray(parameter)) {
          parameter.forEach(value => urlSearchParams.append(property, value));
        } else {
          urlSearchParams.set(property, parameter);
        }
      }

      return urlSearchParams;
    } catch {
      return params;
    }
  }

  /**
   * Extracts the file extension from the file name and returns the corresponding MIME type
   * (This is needed when using Safari browser, because it's not able to interpretate the octet-stream type properly,
   * instead it requieres the specific MIME type for each file )
   * @param filename Full file name with extension
   * @returns A string with the MIME type for the file
   */
  private getContentType(filename: string): string {
    let type: string;
    let extension = filename.split('.')[1];
    switch (extension) {
      case 'pdf':
        type = 'application/pdf';
        break;
      case 'jpg':
        type = 'image/jpeg';
        break;
      case 'png':
        type = 'image/png';
        break;
      case 'doc':
        type = 'application/msword';
        break;
      case 'docx':
        type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'xls':
        type = 'application/vnd.ms-excel';
        break;
      case 'xlsx':
        type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      default:
        type = 'application/octet-stream';
        break;
    }
    return type;
  }

  private setRequestHeaders(): void {
    let token = jsCookie.get('XSRF-TOKEN');
    this.headers['Authorization'] = `Bearer ${token}`;
    this.headers['X-FacingBrandId'] = this.facingBrandId;

    this.reqConfig = {
      headers: this.headers,
      timeout: 45000,
    };
  }
}
