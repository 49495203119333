<div [ngSwitch]="screenCase">
  <app-send-money-sbb-container *ngSwitchCase="'openAccount'"></app-send-money-sbb-container>
  <div class="history frow center wrap" *ngSwitchCase="'history'">
    <app-dot-loader *ngIf="this.isLoadingHistory">Loading Payment History</app-dot-loader>
    <app-dot-loader *ngIf="isCancelingTrxn">Cancelling Transfer</app-dot-loader>
    <div class="fcol-md-12">
      <div class="history-filters" *ngIf="!isLoadingHistory">
        <div>
          <h1 class="history-title">Payment History</h1>
        </div>
      </div>
      <div class="history-table">
        <table class="table--primary transfer-table" *ngIf="!isLoadingHistory && !isCancelingTrxn">
          <thead>
            <tr *ngIf="filteredHistory.length > 0">
              <th *ngFor="let header of headers">
                {{ header.label }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="!isLoadingHistory">
            <tr *ngFor="let transfer of filterHistoryDetails(filteredHistory, filterRange)">
              <td class="date-wrap" [ngSwitch]="transfer.status">
                {{ transfer.creationDate | date: 'MM/dd/yyyy' }}
                <span
                  *ngSwitchCase="0"
                  class="hide--md hide--lg hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  Pending
                </span>
                <span
                  *ngSwitchCase="1"
                  class="hide--md hide--lg hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  Canceled
                </span>
                <span
                  *ngSwitchCase="2"
                  class="hide--md hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  Expired
                </span>
                <span
                  *ngSwitchCase="3"
                  class="hide--md hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  Failed Authentication
                </span>
                <span
                  *ngSwitchCase="4"
                  class="hide--md hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  In Process
                </span>
                <span
                  *ngSwitchCase="5"
                  class="hide--md hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  Failed
                </span>
                <span
                  *ngSwitchCase="6"
                  class="hide--md hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  Sent
                </span>
                <span
                  *ngSwitchCase="7"
                  class="hide--md hidden-lg"
                  [ngClass]="{
                    'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                    'color-gray': transfer.status === 0,
                    'color-green': transfer.status === 4
                  }"
                >
                  Returned
                </span>
              </td>
              <td>{{ transfer.recipient.displayName }}</td>
              <td>
                <span *ngIf="transfer.method === 0">
                  {{ transfer.recipient.emailAddress }}
                </span>
                <span *ngIf="transfer.method === 1">
                  {{
                    '(' +
                      transfer.recipient.phone.areaCode +
                      ') ' +
                      transfer.recipient.phone.prefix +
                      '-' +
                      transfer.recipient.phone.number
                  }}
                </span>
              </td>
              <td>{{ transfer.amount.toFixed(2) | currency }}</td>
              <td
                [ngSwitch]="transfer.status"
                class="hide--cs hide--xs"
                [ngClass]="{
                  'color-red': transfer.status === 2 || transfer.status === 3 || transfer.status === 5,
                  'color-gray': transfer.status === 0,
                  'color-green': transfer.status === 4
                }"
              >
                <span *ngSwitchCase="0">Pending</span>
                <span *ngSwitchCase="1">Canceled</span>
                <span *ngSwitchCase="2">Expired</span>
                <span *ngSwitchCase="3">Failed Authentication</span>
                <span *ngSwitchCase="4">In Process</span>
                <span *ngSwitchCase="5">Failed</span>
                <span *ngSwitchCase="6">Sent</span>
                <span *ngSwitchCase="7">Returned</span>
              </td>
              <td>
                <label
                  *ngIf="transfer.status === 0"
                  class="transfer-cancel"
                  aria-haspopup="true"
                  (click)="openCancelTransferModal(transfer)"
                >
                  <a>
                    <i class="bofi-close"></i>
                    Stop Payment
                  </a>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-md-12 text-center" *ngIf="filteredHistory.length > 0">
          <app-history-paginator
            [itemsPerPage]="pageSize"
            [listLength]="filteredHistory.length"
            [visibleSelectablePages]="5"
            [(renderRange)]="filterRange"
          ></app-history-paginator>
        </div>
      </div>
      <h3 class="text-center" *ngIf="transferHistory && transferHistory.length === 0">
        No Historical Payments
      </h3>
      <div class="caption" *ngIf="transferHistory && transferHistory.length > 0">
        Note: money will remain in your account until the answer has been verified.
      </div>
    </div>
  </div>
</div>
