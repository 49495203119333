import { MergedSegment } from '../types';
export function mergeSegments(
  amounts: ReadonlyArray<number>,
  backgroundColors: ReadonlyArray<string>
): ReadonlyArray<MergedSegment> {
  let TOTAL_PERCENT_LEFT = 100;
  let unAdjustedSegments = 0;
  let adjustedSegments = 0;
  const segments = [];
  const totalAmount = amounts.reduce((acc, curr) => acc + curr, 0);
  for (let k = 0; k < amounts.length; k++) {
    const amount = amounts[k];
    if (amount !== 0) {
      const preCalculatedPercent = (amount / totalAmount) * 100;
      const wasAdjusted = preCalculatedPercent < 2 && preCalculatedPercent > 0;
      const percentage = wasAdjusted ? 2 : preCalculatedPercent;
      TOTAL_PERCENT_LEFT -= percentage;
      if (wasAdjusted) {
        adjustedSegments++;
      } else {
        unAdjustedSegments++;
      }
      const adjustedPercentContext = {
        amount,
        percentage,
        backgroundColor: backgroundColors[k],
        wasAdjusted,
      };
      segments.push(adjustedPercentContext);
    }
  }
  TOTAL_PERCENT_LEFT = Math.abs(TOTAL_PERCENT_LEFT);
  if (adjustedSegments >= 1 && unAdjustedSegments >= 1) {
    const stepsPerUnadjustedSegment = TOTAL_PERCENT_LEFT / unAdjustedSegments;
    const adjustedSegments = segments.map(segment => {
      if (segment.wasAdjusted) {
        return segment;
      }
      const percentage = segment.percentage - stepsPerUnadjustedSegment;
      return { ...segment, percentage };
    });
    // * FOR CONSIDERATION WE MAY NEED TO VALIDATE THE LAST currentTotalPercent just to make sure that it is around 100%
    return adjustedSegments;
  }
  return segments;
}
