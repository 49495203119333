<ng-container [ngSwitch]="currentView">
  <up-section-heading
    [section]="currentView"
    [canReturn]="currentView !== views.LoginAndSecurity"
    [mobile]="isMobileDevice && currentView === views.LoginAndSecurity"
    (return)="goBack()"
    (expand)="expanded = $event"
  ></up-section-heading>

  <div *ngSwitchDefault [@startStaticSlideFromLeft]="animationState">
    <div class="section__heading">
      <p class="mb-3">
        Protect your Online Banking account by reviewing and changing your password and security answers periodically.
      </p>
    </div>

    <div *ngIf="!isMobileDevice || expanded" @openClose [@.disabled]="!isMobileDevice">
      <up-profile-item itemName="Username" [editable]="false" class="username-info">
        <app-dot-loader *ngIf="profileLoading; else username"></app-dot-loader>

        <ng-template #username>
          <p [innerText]="profileInfo.username"></p>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        [itemName]="views.Password"
        [editable]="!profileLoading"
        [alertMessage]="alertMessages.password"
        (edit)="goTo(views.Password)"
        class="password-info"
      >
        <app-dot-loader *ngIf="profileLoading; else passwordInfo"></app-dot-loader>

        <ng-template #passwordInfo>
          <p>********</p>
          <small>Updated on: {{ profileInfo.lastPasswordChange | date: 'medium' }}</small>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        [itemName]="views.SecurityQuestions"
        [alertMessage]="alertMessages.questions"
        [editable]="!questionsLoading"
        (edit)="goTo(views.SecurityQuestions)"
        class="questions-info"
      >
        <div *ngIf="!questionsLoading && !hasUserSecurityQuestions">Not setup yet</div>

        <app-dot-loader *ngIf="questionsLoading; else questionsInfo"></app-dot-loader>

        <ng-template #questionsInfo>
          <div *ngIf="hasUserSecurityQuestions">
            <ng-container *ngFor="let question of userQuestions">
              <p [innerText]="question + '?'"></p>
              <p class="mb-3">********</p>
            </ng-container>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        [itemName]="views.SecretWord"
        [alertMessage]="alertMessages.secretWord"
        [editable]="!profileLoading"
        (edit)="goTo(views.SecretWord)"
        class="secret-info"
      >
        <div *ngIf="!questionsLoading && !hasUserSecurityQuestions">Not setup yet</div>

        <app-dot-loader *ngIf="profileLoading; else secretInfo"></app-dot-loader>

        <ng-template #secretInfo>
          <p>Security Word</p>
          <p class="mb-3">********</p>

          <p>Security Hint</p>
          <p class="mb-3">********</p>
        </ng-template>
      </up-profile-item>
    </div>
  </div>

  <up-change-password
    @slideFromRight
    *ngSwitchCase="views.Password"
    (updatePassword)="updatePassword()"
    (back)="goBack()"
  ></up-change-password>

  <up-change-security-questions
    @slideFromRight
    *ngSwitchCase="views.SecurityQuestions"
    [questionsCatalog]="questionsCatalog"
    [userQuestions]="userQuestions"
    (updateQuestions)="updateQuestions($event)"
    (back)="goBack()"
  ></up-change-security-questions>

  <up-change-security-word
    @slideFromRight
    *ngSwitchCase="views.SecretWord"
    (updateSecretInfo)="updateSecretInfo()"
    (back)="goBack()"
  ></up-change-security-word>
</ng-container>
