import { Component } from '@angular/core';

import { Uk2IconRegistryService } from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private uk2IconRegistryService: Uk2IconRegistryService) {
    // Register icons from UI Kit
    this.uk2IconRegistryService.registerAllCategories();
  }
}
