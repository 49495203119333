<div class="insights-container container-shadow">
  <div class="insights-header-container">
    <div class="insights-selectors insights-content" *ngIf="!isPfm2Active">
      <div class="frow">
        <div class="fcol-cs-12"></div>
      </div>
    </div>
  </div>
  <div class="insights-gradient">
    <div class="insights-header">
      <!-- If there are no transactions then hide the categories, use visibility to keep the size (UX) -->
      <div
        class="insights-content"
        [ngStyle]="{ visibility: transactions && transactions.length == 0 ? 'hidden' : 'visible' }"
      >
        <div *ngIf="!isPfm2Active" class="frow">
          <!-- <div class="fcol-cs-12 fcol-cs-offset-1 fcol-md-2 fcol-md-offset-6"> -->
          <div class="fcol-cs-12 fcol-md-2 fcol-md-offset-6">
            <div class="insights-flex insights-flex-bottom">
              <div class="insights-header-title period-box">
                <h2>{{ categoriesName }}</h2>
              </div>
            </div>
          </div>
          <!-- fcol-md-offset-0 does not exist used bootstrap instead -->
          <div class="period-box-actual fcol-cs-5 fcol-cs-offset-2 fcol-md-2 fcol-md-offset-0">
            <div class="period-box">
              <h5>This {{ periodName }}</h5>
              <h2 class="amount">
                {{ actualPeriodSum | currency }}
              </h2>
            </div>
          </div>
          <div class="period-box-previous fcol-cs-5 fcol-md-2">
            <div class="period-box">
              <h5>Last {{ periodName }}</h5>
              <h2 class="amount">
                {{ previousPeriodSum | currency }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="insights-content">
      <div
        *ngIf="isPfm2Active"
        class="line-chart-header-container"
        [ngStyle]="{ visibility: transactions && transactions.length == 0 ? 'hidden' : 'visible' }"
      >
        <div class="font-primary" style="flex: 1;">
          <h2 class="encode-sans font-weight-normal">{{ categoriesName }}</h2>
          <div class="account-nickname font-weight-normal">{{ getBankNameMask() }}</div>
        </div>
        <div class="line-chart-header">
          <div class="text-center">
            <h2 class="encode-sans font-weight-normal font-primary font-size-md">
              {{ actualPeriodSum | currency }}
            </h2>
            <div class="mt-1 font-weight-normal c-black">{{ getActualPeriodName() }}</div>
          </div>
          <div class="text-center ml-4">
            <h2 class="encode-sans font-weight-normal font-primary font-size-md">
              {{ previousPeriodSum | currency }}
            </h2>
            <div class="mt-1 font-weight-normal c-black">Last {{ periodName }}</div>
          </div>
        </div>
      </div>
      <div class="frow">
        <div class="insights-chart-container_no_transactions_msg" [hidden]="transactions && transactions.length != 0">
          <h3>There are no transactions available for Insights</h3>
        </div>
        <div class="fcol">
          <div class="insights-chart-container insights-chart-container_slide">
            <canvas [hidden]="transactions && transactions.length == 0" id="line-chart-canvas"></canvas>
          </div>
        </div>
      </div>
      <div
        *ngIf="isPfm2Active"
        class="line-chart-header-mobile m-4"
        [ngStyle]="{ visibility: (transactions && transactions.length) == 0 ? 'hidden' : 'visible' }"
      >
        <div class="text-center">
          <h2 class="encode-sans font-weight-normal font-primary font-size-md">
            {{ actualPeriodSum | currency }}
          </h2>
          <div class="mt-1 font-weight-normal c-black">{{ getActualPeriodName() }}</div>
        </div>

        <div class="text-center">
          <h2 class="encode-sans font-weight-normal font-primary font-size-md">
            {{ previousPeriodSum | currency }}
          </h2>
          <div class="mt-1 font-weight-normal c-black">Last {{ periodName }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
