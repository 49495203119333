import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class HarlandClarkeService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'HarlandClarke');
  }

  encryptAndRedirect(accountId: string) {
    return this.post<string>(`redirect/${accountId}`);
  }
}
