<div id="edit-portfolio">
  <div class="edit-portfolio-content container">
    <div class="portfolio-assets-container d-sm-none">
      <h5 class="portfolio-assets-subtitle">{{ typeName }} Target Allocations</h5>
      <app-main-portfolio-details [details]="form.value"></app-main-portfolio-details>
    </div>

    <div class="row portfolio-container">
      <div class="portfolio-allocation-container">
        <div class="subtitle-container d-flex justify-content-between align-items-center mb-4">
          <h5 class="portfolio-allocation-subtitle">Edit {{ typeName }} Target Allocation</h5>
          <div
            class="delete-button"
            *ngIf="type == PORTFOLIO_ALLOCATIONS.End && previousPortfolioType !== PORTFOLIO_TYPES.Basic"
          >
            <button class="delete-btn d-flex align-items-center" type="button" (click)="deleteEndTarget.emit()">
              <app-svg-icon
                class="delete-btn-icon"
                [iconName]="icons.trash"
                width="1.15em"
                height="1.15em"
                iconColor="#1c7fdd"
              ></app-svg-icon>
              <span class="delete-btn-label">Delete</span>
            </button>
          </div>
        </div>
        <div class="portfolio-allocation-form-container">
          <form class="form-container" [formGroup]="form" novalidate (submit)="submitForm()">
            <div class="cash-input">
              <p class="input-label">Cash Allocation</p>
              <input
                name="cashAllocation"
                id="cashAllocation"
                class="cash-input-value"
                pattern="[0-9]*"
                [mask]="mask"
                [formControl]="form.get('cashAllocation')"
                autocomplete="off"
                suffix="%"
                [validation]="false"
                (focus)="onFocusCashAllocation()"
                (blur)="onBlurCashAllocation()"
              />
              <p class="input-helper-text" *ngIf="form.get('cashAllocation').hasError('max')">
                Must be between 0-{{ form.get('cashAllocation').errors.max.max }}
              </p>
              <p class="input-helper-text" *ngIf="form.get('cashAllocation').hasError('required')">Enter an amount</p>
            </div>
            <div class="portfolio-allocation-slider">
              <app-portfolio-allocation-slider [form]="form"></app-portfolio-allocation-slider>
            </div>
            <div class="button-container">
              <button class="btn-ok button--lg" type="submit" [disabled]="form.invalid" id="submitBtn">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="portfolio-info-container">
        <div class="portfolio-assets-container d-none d-sm-block">
          <h5 class="portfolio-assets-subtitle">{{ typeName }} Target Allocations</h5>
          <app-main-portfolio-details [details]="form.value"></app-main-portfolio-details>
        </div>
        <div class="portfolio-value-container">
          <p class="portfolio-value-title">Current Value</p>
          <p class="portfolio-value">{{ goal.currentValue | currency }}</p>
        </div>
        <div class="portfolio-details">
          <p class="portfolio-details-title">Your Investment Goal</p>
          <div class="portfolio-details-value">
            <div class="portfolio-type">
              <div class="portfolio-type-icon">
                <app-svg-icon [iconName]="goalIcon" size="2rem"></app-svg-icon>
              </div>
              <p class="portfolio-type-description">{{ goal.typeDescription }}</p>
            </div>
            <div class="portfolio-forecast" *ngIf="goal.targetAmount && goal.targetDate">
              <div class="portfolio-forecast-icon">
                <app-svg-icon [iconName]="icons.goalForecastIcon" size="2rem"></app-svg-icon>
              </div>
              <p class="portfolio-forecast-description">
                {{ goal.targetAmount | currency }} by {{ goal.targetDate | date: 'yyyy' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
