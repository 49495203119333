import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';

import { HowTo, HowToTopics, SupportIcons } from '@app/support/enums';
import { LegacyRoute } from '@core/models';
import { STATE } from '@core/tokens';

import { SupportViewFacade } from '../../store/support-view/support-view-facade';

interface ActiveHowTo {
  category: HowTo;
  topic: HowToTopics | null;
}

@Component({
  selector: 'app-support-view',
  templateUrl: './support-view.component.html',
  styleUrls: ['./support-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportViewComponent implements OnInit {
  activeHowTo: ActiveHowTo = null;
  categories = HowTo;
  riaUserIsLoading$ = this.riaUserFacade.isLoading$;
  riaUserFlag$ = this.riaUserFacade.isRIAUser$;
  riaPilotFlag$ = this.riaUserFacade.isRIAPilotActive$;
  noAxosAccounts$ = this.riaUserFacade.noAxosAccounts$;
  noAxosAccountsIsLoading$ = this.riaUserFacade.axosAccountsLoading$;
  howTos = [];

  constructor(@Inject(STATE) private $state: ng.ui.IStateService, private riaUserFacade: SupportViewFacade) {}

  goTo(route: LegacyRoute) {
    this.$state.go(route.state, route.params);
  }

  selectHowToCategory(category: HowTo) {
    this.activeHowTo = { category, topic: null };
  }

  ngOnInit(): void {
    this.riaUserFacade.getRIAUserFlag();
    this.noAxosAccounts$.subscribe(result => {
      if (result) {
        this.howTos = [
          {
            label: 'Dashboard',
            icon: SupportIcons.Dashboard,
            category: HowTo.Dashboard,
          },
          {
            label: 'Alerts',
            icon: SupportIcons.Alerts,
            category: HowTo.Alerts,
          },
          {
            label: 'Transfers',
            icon: SupportIcons.Transfers,
            category: HowTo.Transfers,
          },
          {
            label: 'Profile & Settings',
            icon: SupportIcons.Other,
            category: HowTo.Other,
          },
        ];
      } else {
        this.howTos = [
          {
            label: 'Dashboard',
            icon: SupportIcons.Dashboard,
            category: HowTo.Dashboard,
          },
          {
            label: 'Deposit & Loan',
            icon: SupportIcons.Accounts,
            category: HowTo.Accounts,
          },
          {
            label: 'Alerts',
            icon: SupportIcons.Alerts,
            category: HowTo.Alerts,
          },
          {
            label: 'Bill Pay',
            icon: SupportIcons.BillPay,
            category: HowTo.BillPay,
          },
          {
            label: 'Deposits',
            icon: SupportIcons.Deposits,
            category: HowTo.Deposits,
          },
          {
            label: 'Transfers',
            icon: SupportIcons.Transfers,
            category: HowTo.Transfers,
          },
          {
            label: 'Pay It Now',
            icon: SupportIcons.PayItNow,
            category: HowTo.PayItNow,
          },
          {
            label: 'Profile & Settings',
            icon: SupportIcons.Other,
            category: HowTo.Other,
          },
        ];
      }
    });
  }
}
