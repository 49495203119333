<div class="advisorContribution__container">
  <div class="advisorContribution__title">Advisor Contribution Authority</div>
  <div class="establishACH-container advisorContribution__flex-container">
    <div class="establishACH-text txt">Establish Recurring ACH</div>
    <div class="establishACH-value val">
      {{
        authorizationsTabState?.establishRecurringACH
          ? 'Yes; Last Updated: ' + getFormattedDate(authorizationsTabState?.establishRecurringACH)
          : '--'
      }}
    </div>
  </div>
  <div class="advisorContribution__divider"></div>
  <div class="changeACH-container advisorContribution__flex-container">
    <div class="changeACH-text txt">Change / Terminate Recurring ACH</div>
    <div class="changeACH-value val">
      {{
        authorizationsTabState?.changeOrTerminateRecurringACH
          ? 'Yes; Last Updated: ' + getFormattedDate(authorizationsTabState?.changeOrTerminateRecurringACH)
          : '--'
      }}
    </div>
  </div>
  <div class="advisorContribution__divider"></div>
  <div class="onetimeACH-container advisorContribution__flex-container">
    <div class="onetimeACH-text txt">One Time ACH</div>
    <div class="onetimeACH-value val">
      {{ getFormattedYesNo(authorizationsTabState?.oneTimeACH) ?? '--' }}
    </div>
  </div>
</div>
