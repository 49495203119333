import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'how-to-update-contact-info',
  templateUrl: './update-contact-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
// TODO SBB: Navigation to update contact info will be different.
export class UpdateContactInfoComponent implements OnInit {
  ngOnInit() {
  }
}
