import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { facingBrandMapping, FacingBrand } from '@legacy/models';
import * as jsCookie from 'js-cookie';
import { JwtHelperService } from '@auth0/angular-jwt';
import { cookieServiceConstants } from './constants/constants-cookie-service';
@Injectable({
  providedIn: 'root',
})
export class CookieHelperService {
  private jwtHelper: JwtHelperService = new JwtHelperService();
  constructor() {}

  getToken(): string {
    try {
      let cookie = jsCookie.get(cookieServiceConstants.XSRF_TOKEN);
      return cookie;
    } catch {
      return null;
    }
  }

  tokenExists(): boolean {
    return !!this.getToken();
  }

  getUserBrandId(): number {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return +decodedToken.brandId;
    }

    return null;
  }

  getAuthTime(): number {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return +decodedToken.auth_time;
    }

    return null;
  }

  getUserState(): number {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return +decodedToken.userState;
    }

    return null;
  }

  getUserUsername(): string {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return decodedToken.sub;
    }

    return null;
  }

  getUserId(): number {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return +decodedToken.id;
    }

    return null;
  }

  getUserCIF(): string {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return decodedToken.cif;
    }

    return null;
  }

  //ToDo: Move this method to another helper/service because its not related to cookies
  getUserFacingBrand(brandId: number): FacingBrand {
    const userFacingBrand = facingBrandMapping.find(fb => fb.brands.includes(brandId));

    return userFacingBrand.facingBrand;
  }

  getUserFirstName(): any {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return decodedToken.name;
    }

    return null;
  }

  getUserSubType(): number {
    const decodedToken = this.getDecodedToken();

    if (decodedToken != null) {
      return +decodedToken.userSubType;
    }

    return 0;
  }

  getTokenExpirationDate(): Date {
    const token = this.getToken();

    if (token) {
      return this.jwtHelper.getTokenExpirationDate(token);
    }

    return null;
  }

  getTokenLifetimeInMinutes(): number {
    const tokenExp = this.getTokenExpirationDate();
    const authTime = moment.unix(this.getAuthTime());

    const tokenLifetime = Math.abs(moment.duration(authTime.diff(tokenExp)).asMinutes());

    return tokenLifetime;
  }

  getTokenConsumedLifeInMinutes(): number {
    const authTime = moment.unix(this.getAuthTime());
    const currentDate = moment();

    const tokenConsumedLifetime = Math.abs(moment.duration(currentDate.diff(authTime)).asMinutes());

    return tokenConsumedLifetime;
  }

  getTokenRemainingLifetimeInMinutes(): number {
    const tokenExp = this.getTokenExpirationDate();
    const currentDate = moment();

    const tokenRemainingLifetime = Math.abs(moment.duration(currentDate.diff(tokenExp)).asMinutes());

    return tokenRemainingLifetime;
  }

  tokenRefreshedRecently(originalTokenExp: Date): boolean {
    let tokenRefreshedRecently: boolean;
    tokenRefreshedRecently = moment(this.getTokenExpirationDate()).isAfter(moment(originalTokenExp));

    return tokenRefreshedRecently;
  }

  tokenHasEnoughLifetime(): boolean {
    let tokenHasEnoughLifetime: boolean;

    const tokenLifetime = this.getTokenLifetimeInMinutes();
    const tokenRemainingLifetime = this.getTokenRemainingLifetimeInMinutes();
    //Adding 1 extra minute to give enough time for the token to be refreshed in the next auto-refresh timer checkup
    const tokenAutoRefreshTime = tokenLifetime / 2 + 1;

    tokenHasEnoughLifetime = tokenRemainingLifetime > tokenAutoRefreshTime;

    return tokenHasEnoughLifetime;
  }

  private getDecodedToken(): any {
    let decodedToken;
    const token = this.getToken();

    if (token) {
      decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken;
    }

    return null;
  }
}
