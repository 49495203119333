<ng-container *ngIf="trustedContact; else emptyState">
  <div class="section-header">
    <div class="section-header__title">
      Trusted Contact
    </div>
    <div
      class="section-header__edit-button"
      *ngIf="userCanAddEditTrustedContact"
    >
      <button
        uk2LabeledIconButton
        mat-button
        disableRipple
        (click)="openTrustedContactModal(modalActionType.Edit)"
        [uk2ButtonSize]="uk2ButtonSize"
        [uk2LabeledIconPosition]="labeledIconPosition"
      >
        Edit
        <mat-icon [svgIcon]="editSvgIconName"></mat-icon>
      </button>
    </div>
  </div>
  <div class="list">
    <div class="list-row" id="name">
      <span class="title">Name</span>
      <span class="value">
        {{ this.trustedContactFullName }}
      </span>
    </div>
    <div class="list-row" id="relationship">
      <span class="title">Relationship</span>
      <span class="value">{{ trustedContact.relationship }}</span>
    </div>
    <div class="list-row" id="phone-number">
      <span class="title">Phone Number</span>
      <span class="value">{{ trustedContact.phoneNumber | phone }}</span>
    </div>
    <div class="list-row" id="email">
      <span class="title">Email</span>
      <span class="value">{{ trustedContact.email }}</span>
    </div>
  </div>
</ng-container>

<ng-template #emptyState>
  <div class="section-header">
    Trusted Contact
  </div>
  <div class="list">
    <div
      class="list-row"
      *ngIf="userCanAddEditTrustedContact; else notYetAssigned"
    >
      <div
        class="item-container"
        id="btn-nonAxos"
        (click)="openTrustedContactModal(modalActionType.Add)"
      >
        <div class="item-container__icon-plus">
          <mat-icon [svgIcon]="plusIcon"></mat-icon>
        </div>
        <div>Add Trusted Contact</div>
      </div>
    </div>
    <ng-template #notYetAssigned>
      <div class="list-row">Not Yet Assigned</div>
    </ng-template>
  </div>
</ng-template>

<app-trusted-interested-modal
  [personType]="personType"
  [data]="trustedContact"
  [accountNumber]="accountNumber"
  [modalAction]="action"
  [showModal]="showModal"
  (modalClosed)="modalClosed()"
></app-trusted-interested-modal>
