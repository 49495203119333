import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { AggregatedAccount } from '@app/accounts/models';
import { getCustomPositioner, getExtendedAxosLineChart } from '@app/utils';
import { AccountAggregationChartFormatterService } from '@app/accounts/submodules/transactions/services';
import { format, subMonths } from 'date-fns';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { I_WINDOW, olbSettings } from '@core/tokens';

@Component({
  selector: 'app-insights-line-chart',
  templateUrl: './insights-line-chart.component.html',
  styleUrls: ['./insights-line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsLineChartComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * Returns true if any tx in this.transactions is of income category or transfer category and is income
   */
  get areIncomeTransactions(): boolean {
    return this.transactions?.some(
      (tx: ExternalTransaction) =>
        tx.isIncome && [this.IncomeCategoryId, this.TransferCategoryId]?.some(x => x === tx.olbCategoryId)
    );
  }

  /**
   * Returns true if any transaction in "this.transactions" is of any category other than "income" or "transfer"
   * If it is "transfer" category it should not be an income
   */
  get areExpenseTransactions(): boolean {
    return this.transactions?.some(
      (tx: ExternalTransaction) =>
        ![this.IncomeCategoryId, this.TransferCategoryId]?.some(x => x === tx.olbCategoryId) ||
        (tx.olbCategoryId === this.TransferCategoryId && !tx.isIncome)
    );
  }

  get convert(): ng.IFilterCurrency {
    return this.filter;
  }
  /**
   * Returns true when are transactions of type Income and Expense
   */
  get isCashFlow(): boolean {
    return this.areIncomeTransactions && this.areExpenseTransactions;
  }

  /**
   * Gets the data object formatted
   */
  get Data(): object {
    const tension = 0.03;

    return {
      labels: this.labels, // Array of strings representing the labels of X axis
      datasets: [
        {
          backgroundColor: 'white', // Dot color
          borderColor: this.isPfm2Active ? '#2374D7' : this.env.facingBrandId != 3 ? '#53A8E2' : '#E18357', // Line color
          data: this.dataActualMonth, // Array of values
          fill: false,
          pointBackgroundColor: this.isPfm2Active ? '#2374D7' : undefined,
          pointHoverBackgroundColor: this.isPfm2Active ? 'white' : undefined,
          pointHoverBorderColor: this.isPfm2Active ? '#2374D7' : undefined,
          pointHoverBorderWidth: this.isPfm2Active ? 8 : undefined,
          pointRadius: 6, // Size of the dot
          pointHoverRadius: 12, // Size of the dot on hover
          lineTension: tension, // Bezier curve factor
          spanGaps: true, // When true does not draw a point when a null is present, when a NA is present it cuts the line
        },
        {
          backgroundColor: 'white', // Dot color
          borderColor: this.isPfm2Active ? '#5E6A74' : this.env.facingBrandId != 3 ? '#FDAA4B' : '#60707A', // Border color of the line
          data: this.dataPrevMonth, // Array of data
          fill: false, // Fill the area below the curve
          borderDash: [8, 5], // Pointed line
          pointBackgroundColor: this.isPfm2Active ? '#5E6A74' : undefined,
          pointHoverBackgroundColor: this.isPfm2Active ? 'white' : undefined,
          pointHoverBorderColor: this.isPfm2Active ? '#5E6A74' : undefined,
          pointHoverBorderWidth: this.isPfm2Active ? 8 : undefined,
          pointRadius: 5.5, // Size of point
          pointHoverRadius: 12, // Size of point on hover
          lineTension: tension, // Curve factor of the lines
          spanGaps: true, // When true does not draw a point when a null is present, when a NA is present it cuts the line
        },
      ],
    };
  }

  /**
   * Gets the options object formatted
   */
  get Options(): AxosLineChartOptions {
    return {
      legend: {
        display: false, // Shows chart legends
      },
      tooltips: {
        // Tooltip styles
        backgroundColor: this.isPfm2Active ? 'white' : 'rgb(225,225,225)',
        borderColor: this.isPfm2Active ? 'gray' : undefined,
        borderWidth: this.isPfm2Active ? 1 : undefined,
        titleFontColor: this.isPfm2Active ? '#333D46' : 'black',
        titleFontSize: this.isPfm2Active ? undefined : 0,
        titleFontStyle: this.isPfm2Active ? 'normal' : undefined,
        titleFontFamily: this.isPfm2Active ? 'Roboto' : undefined,
        bodyFontColor: this.isPfm2Active ? '#333D46' : 'rgb(61,83,124)',
        bodyFontSize: 6 + this.size55vh * 0.025,
        bodyFontStyle: this.isPfm2Active ? 'normal' : 'bold',
        bodyFontFamily: 'Roboto',
        displayColors: false,
        position: getCustomPositioner(), // Positioner is in ChartHelper -> SetCustomPositioner()
        filter: (tooltip: TooltipItem, data) => {
          // If both previous and current month have the same value for the same day
          //    then only show 1 value in the tooltip (since it is the same we don't need to show it twice)
          return data.datasets[0]?.data[tooltip.index] === data.datasets[1]?.data[tooltip.index]
            ? tooltip.datasetIndex === 0
            : true;
        },
        callbacks: {
          title: function (tooltipItems: TooltipItem[], data) {
            if (!this.isPfm2Active) return '';

            // Title is composed of the month + day
            // If both previous and current month have the same value
            //    then show both months names separated by a line break

            const previousMonthName = this.accAggFormatter.GetShortMonthName(subMonths(new Date(), 1));
            const currentMonthName = this.accAggFormatter.GetShortMonthName();
            const tooltip = tooltipItems[0];

            return data.datasets[0]?.data[tooltip.index] === data.datasets[1]?.data[tooltip.index]
              ? `${previousMonthName} ${tooltip?.xLabel}\n${currentMonthName} ${tooltip?.xLabel}`
              : `${
                  data.datasets[0]?.data[tooltip.index]?.toString() === tooltip.value
                    ? currentMonthName
                    : previousMonthName
                } ${tooltip?.xLabel}`;
          }.bind(this),
          // Callback that returns a string formatted
          label: function (tooltipItem: any) {
            return this.convert('currency')(tooltipItem.yLabel);
          }.bind(this),
        },
        mode: 'nearest',
        caretSize: 0,
      },
      layout: {
        padding: this.chartPadding,
      },
      scales: {
        // Options of the scale
        yAxes: [
          {
            ticks: {
              // Styles of the label in axis Y
              display: true,
              fontColor: this.isPfm2Active ? '#5E6A74' : 'rgb(212,217,226)', // Color of the scale values
              fontSize: 20, // Size of the scale values
              callback: (label: any, _index: any, _labels: any) => {
                return '$' + label;
              }, // Callback to format the value of the label
              min: this.roundedMin, // Min value in the axis
              max: this.roundedMax + this.roundedMax * 0.05, // Max value in the axis
              maxTicksLimit: 6, // Number of values to show in the axis
              stepSize: this.stepSize, // Size of the increment
              beginAtZero: true, // Start the labels at zero
              padding: 0,
            },
            gridLines: {},
            afterBuildTicks: (axis: any) => {
              axis.ticks = this.accAggFormatter.range(this.roundedMin, this.roundedMax, this.stepSize);
            },
            offset: true,
          },
        ],
        xAxes: [
          {
            ticks: {
              // Label styles of X axis
              display: true, // Shows the labels
              fontColor: this.isPfm2Active ? '#5E6A74' : 'rgb(212,217,226)', // Color of labels in the axis
              fontSize: 20, // Fontsize of labes in the axis
              maxRotation: 0, // When the chart size decrease max value in deegres to rotate the labels to fit in
              beginAtZero: false,
              padding: 0,
              autoSkipPadding: 10, // Padding between labels, when is less than that amount, begin the autoskip
            },
            gridLines: {
              display: false, // Do not display the lines of the grid for the axis
            },
            offset: true, // Applies an offset for all the values be visible
          },
        ],
      },
      hover: {
        mode: 'point', // Selects only 1 point on hover instead of all in the axis
      },
      responsive: true,
      maintainAspectRatio: false,
      onResize: function (chart: any, size: any) {
        chart.options.layout.padding.top = size.height * this.paddingPercentage; // padding percentage of the size of the canvas on resize
        chart.options.tooltips.bodyFontSize = 8 + size.height * 0.025; // fontsize of the tooltips will be resized dynamic
        if (this.isPfm2Active) chart.options.layout.padding = this.chartPadding;
        chart.update();
      }.bind(this),
      axos: {
        // Custom AxosLineChartOptions
        valuesAreBelowZero: this.roundedMin < 0, // boolean to determine when is the values below zero and render correctly
        zeroLineStyle: '#E7ECF7', // Color of line style
        interactionLineStyle: this.isPfm2Active ? '#333D46' : 'rgb(108,131,164)', // Color of interaction line
        xScaleFontStyle: '20px Roboto', // Style for XScale label
        xScaleValue: this.accAggFormatter.getShortMonthName(), // Value of actual period selected abbr
      },
      title: {
        display: false, // Display or not the title of the chart
      },
    };
  }

  private get chartPadding() {
    return this.isPfm2Active
      ? {
          // Since Chart.js only supports responsive sizing based on screen width (vw), a workaround is to add padding on the right
          //    to visually fit it inside its container, this has the drawback that you have to consider all css breakpoints that could affect
          //    the container's width
          right: this.window.innerWidth >= 1103 ? 237 : this.window.innerWidth >= 785 ? 230 : 30,
          top: 0,
          bottom: 0,
        }
      : {
          top: this.size55vh * this.paddingPercentage, // percentage size of 55vh
          bottom: this.size55vh * this.paddingPercentage, // percentage size of 55vh
        };
  }
  @Input() account: AggregatedAccount;
  @Input() transactions: ExternalTransaction[];
  categoriesName = 'Expenses';
  periodName = 'Month';
  actualPeriodSum: number;
  previousPeriodSum: number;
  isPfm2Active: boolean;

  private IncomeCategoryId = 8;
  private TransferCategoryId = 7;
  private PendingCategoryId = 10;
  private lineChart: Chart;
  private labels: any[];
  private dataActualMonth: any[];
  private dataPrevMonth: any[];
  private roundedMin: number;
  private roundedMax: number;
  private stepSize: number;
  private size55vh: number = this.window.innerHeight * 0.55;
  private paddingPercentage = 0.1;

  private actualPeriodSpan = { months: 0, years: 0 };
  private previousPeriodSpan = { months: -1, years: 0 };
  private filter: ng.IFilterCurrency;
  constructor(
    @Inject(I_WINDOW) private window: ng.IWindowService,
    private accAggFormatter: AccountAggregationChartFormatterService,
    @Inject(olbSettings) private env: OlbSettings,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.isPfm2Active = this.featureFlagService.isPFM2FlagActive();
  }

  /**
   * The chart needs transclusion
   */
  ngAfterViewInit(): void {
    this.formatData();
    this.displayChart();
  }

  /**
   * This will run when some variable changes its value
   * @param _changes
   */
  ngOnChanges(_changes: ng.IOnChangesObject) {
    if (!this.lineChart) return;

    this.lineChart.destroy();
    this.formatData();
    this.displayChart();
  }

  /**
   * Based on the transactions categories determines the correct title for the header
   */
  setChartName(): void {
    switch (true) {
      case this.isCashFlow:
        this.categoriesName = 'Cash Flow';
        break;
      case this.areExpenseTransactions:
        this.categoriesName = 'Expenses';
        break;
      case this.areIncomeTransactions:
        this.categoriesName = 'Income';
        break;
    }
  }

  getBankNameMask(): string {
    let account = '';
    if (!this.account) return '';
    else if (this.account.nickname) account = this.account.nickname;
    else if (this.account.name) account = this.account.name;
    else if (this.account.bankName) account = this.account.bankName;
    let lastDigits = !!this.account.accountNumber ? this.account.accountNumber : this.account.accountMask;
    lastDigits = this.maskAccountNumber(lastDigits);

    return `${account} ${lastDigits}`;
  }

  maskAccountNumber(accountNumber: string): string {
    if (!accountNumber || accountNumber?.length < 4) return '';

    return `*${accountNumber.substring(accountNumber?.length - 4)}`;
  }

  getActualPeriodName() {
    const startPeriod = this.accAggFormatter.getFilterDate(this.actualPeriodSpan.months, this.actualPeriodSpan.years);

    return format(startPeriod, 'MMM yyyy');
  }

  /**
   * Process the transaction information to set the correct information to show the chart
   */
  private formatData(): void {
    this.setChartName();
    const startActualPeriodDate = this.accAggFormatter.getFilterDate(
      this.actualPeriodSpan.months,
      this.actualPeriodSpan.years
    );
    const startPreviousPeriodDate = this.accAggFormatter.getFilterDate(
      this.previousPeriodSpan.months,
      this.previousPeriodSpan.years
    );

    this.transactions = this.transactions?.filter(
      transaction => !transaction.isPending && transaction.olbCategoryId !== this.PendingCategoryId
    );

    const actualPeriodTx = this.accAggFormatter.filtertTransactionsByDate(this.transactions, startActualPeriodDate);
    const previousPeriodTx = this.accAggFormatter.filtertTransactionsByDate(
      this.transactions,
      startPreviousPeriodDate,
      startActualPeriodDate
    );

    const groupByDayCallback = (element: Transaction) => new Date(element.postedDate.toString()).getDate();

    const groupedActualTransactions = this.accAggFormatter.groupByTransactions(actualPeriodTx, groupByDayCallback);
    const groupedPreviousTransactions = this.accAggFormatter.groupByTransactions(previousPeriodTx, groupByDayCallback);

    const summedActualTransactions = this.isCashFlow
      ? this.accAggFormatter.sumSignedTransactionArray(groupedActualTransactions)
      : this.accAggFormatter.sumTransactionArray(groupedActualTransactions);
    const summedPreviousTransactions = this.isCashFlow
      ? this.accAggFormatter.sumSignedTransactionArray(groupedPreviousTransactions)
      : this.accAggFormatter.sumTransactionArray(groupedPreviousTransactions);

    this.actualPeriodSum = this.accAggFormatter.sumObjectValues(summedActualTransactions);
    this.previousPeriodSum = this.accAggFormatter.sumObjectValues(summedPreviousTransactions);

    const runningAmountActualTransactions = this.accAggFormatter.getRunningTransactionAmount(summedActualTransactions);
    const runningAmountPreviousTransactions = this.accAggFormatter.getRunningTransactionAmount(
      summedPreviousTransactions
    );

    const formattedActualPeriodData = this.accAggFormatter.getFormatDataForMonth(
      runningAmountActualTransactions,
      startActualPeriodDate
    );
    const formattedActualPreviousData = this.accAggFormatter.getFormatDataForMonth(
      runningAmountPreviousTransactions,
      startPreviousPeriodDate,
      true
    );

    const maxDaysInPeriod = Math.max(
      this.accAggFormatter.daysInMonthByDate(startActualPeriodDate),
      this.accAggFormatter.daysInMonthByDate(startPreviousPeriodDate)
    );
    this.labels = this.accAggFormatter.range(1, maxDaysInPeriod, 1);

    this.dataActualMonth = formattedActualPeriodData;
    this.dataPrevMonth = formattedActualPreviousData;

    const roundedMin = this.accAggFormatter.roundToNearest10(
      this.accAggFormatter.minValue([...this.dataPrevMonth, ...this.dataActualMonth])
    );
    this.roundedMin = roundedMin > 0 ? 0 : roundedMin;
    const roundedMax = this.accAggFormatter.roundToNearest10(
      this.accAggFormatter.maxValue([...this.dataPrevMonth, ...this.dataActualMonth])
    );
    this.roundedMax = this.roundedMin === roundedMax ? this.roundedMin + 10 : roundedMax;
    const stepSize = (this.roundedMax - this.roundedMin) / 5;
    this.stepSize = stepSize < 10 ? stepSize : this.accAggFormatter.roundToNearest10(stepSize);
  }

  /**
   * Executes the logic to show the chart
   */
  private displayChart(): void {
    const canvas = document.getElementById('line-chart-canvas') as HTMLCanvasElement;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    this.lineChart = new Chart(ctx, {
      type: getExtendedAxosLineChart(),
      // Extends the actual functionality of the chart and return its name, this happens in ChartHelper service
      // Custom line chart it extends the functionality of the original one and overrides its draw function to
      // draw custom elements, the interaction line in this case

      data: this.Data, // Data object
      options: this.Options, // Chart options
    });
  }
}
