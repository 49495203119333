import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { getParsedSetting } from '@app/store/selectors';
import { AppSettings } from '@core/enums';
import { DialogService } from '@core/services';
import { NavigationIcons } from '@shared/enums';
import { dialogConfig, DialogData } from '@shared/models';

@Component({
  selector: 'app-card-replacement-fee',
  templateUrl: './card-replacement-fee.component.html',
  styleUrls: ['./card-replacement-fee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardReplacementFeeComponent implements OnInit, OnDestroy {
  @Input() dueFee = false;
  @Input() replace?: boolean;
  @Output() feeSelection = new EventEmitter<boolean>();

  feeAmount = 0;
  chevronIconConfig = {
    icon: NavigationIcons.ChevronRight,
    styles: {
      width: '11px',
      height: '11px',
    },
  };

  private subsink = new SubSink();

  constructor(private store: Store, private dialogService: DialogService, private cp: CurrencyPipe) {}

  ngOnInit(): void {
    this.subsink.sink = this.store.select(getParsedSetting<number>(AppSettings.MakeHotCardFee)).subscribe(value => {
      this.feeAmount = value;
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  /** Calls dialog service and displays it */
  showTerms() {
    const dialogData = new DialogData({
      hasIcon: false,
      cancelText: null,
      okText: 'Close',
      title: '',
      headerTitle: 'Terms & Conditions',
      content: `
      <div class='d-flex flex-column' style='color:var(--primary)'>
        <h3>Lost or Stolen Card/Unauthorized Transactions</h3>
        <div class='disclaimer-terms-body text-justify'>
          <p class='mt-4'>
            Notify us immediately if your ATM Card, VISA Debit Card or PIN has been lost,
            stolen, used or compromised without your permission.
          </p>
          <p class='mt-4'>
            Estimated delivery for a replacement card is 7-10 business days.
            To rush the delivery of your replacement card, the fee is
            ${this.cp.transform(this.feeAmount)} based on the cost of producing
            out of cycle and mailing overnight delivery.
          </p>
          <p class='mt-4'>
            By requesting a rush replacement card, you agree to this fee.
          </p>
        </div>
      </div>`,
    });

    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;
    this.dialogService.openCustom(dialogConfig);
  }
}
