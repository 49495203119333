import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { turnOffFunding } from '@app/store/funding/funding.actions';
import { STATE, ngRedux } from '@core/tokens';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { Store } from '@ngrx/store';
import { IStateService } from 'angular-ui-router';
import NgRedux from 'ng-redux';

@Component({
  selector: 'app-wire-transfer',
  templateUrl: './wire-transfer.component.html',
  styleUrls: ['./wire-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WireTransferComponent {
  accountNumber: string;
  imageSource: any;
  displayName: string;
  fullName: string;
  isTradingAccount = false;

  constructor(
    @Inject(ngRedux) private readonly ngRedux: NgRedux.INgRedux,
    @Inject(STATE) private state: IStateService,
    private store: Store
  ) {}

  ngOnInit() {
    const { funding } = this.ngRedux.getState();

    const account = funding.accountsToBeFunded.find((account: OlbAccount) => account.id === funding.accountId);
    this.accountNumber = account.accountNumber;
    if (account.accountType === 'trading') {
      this.isTradingAccount = true;
      this.displayName = account.nickname;

      this.fullName = account.ownerName;
    }

    try {
      this.imageSource = '/img/icons/tiles/wire-transfer.svg';
    } catch {}
  }

  redirectToFunding() {
    this.state.go('udb.funding');
  }

  /** terminates funding flow and redirects */
  goToDashboard() {
    this.ngRedux.dispatch(FundingActions.turnOffFunding());
    this.store.dispatch(turnOffFunding());
    this.state.go('udb.dashboard');
  }
}
