import { createSelector } from '@ngrx/store';

import { getUserProfile } from '../user-profile.state';

export const getFinancialCatalogs = createSelector(
  getUserProfile,
  state => state.financialCatalogs
);
export const getTotalNetWorths = createSelector(
  getUserProfile,
  state => state.financialCatalogs.totalNetWorths
);
export const getLiquidNetWorths = createSelector(
  getUserProfile,
  state => state.financialCatalogs.liquidNetWorths
);
export const getTaxBrackets = createSelector(
  getUserProfile,
  state => state.financialCatalogs.taxBrackets
);
export const getAnnualIncomes = createSelector(
  getUserProfile,
  state => state.financialCatalogs.annualIncomes
);
