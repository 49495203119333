import { IPrivacyPreferencesService } from '@legacy/services/typings/IPrivacyPreferencesService';
import { Inject } from '../../decorators/decorators';

@Inject('$scope', '$state', 'privacyPreferencesService', 'env', '$rootScope')
export class PreferencesController {
  public isSuccess: boolean = false;
  public isError: boolean;
  public share: boolean;
  public allow: boolean;
  public personal: boolean;
  public checkboxes: ng.IFormController;
  public isLoading: boolean = true;
  public isSavingLoader: boolean = false;
  public propetiesLodaded: boolean = false;
  public preferences: PrivacyPreference = {};
  public privacyUrl: string = '';
  constructor(
    private _scope: any,
    private _state: ng.ui.IStateService,
    private _privacy: IPrivacyPreferencesService,
    private env: any,
    private readonly rootScope: ng.IRootScopeService
  ) {}

  $onInit(): void {
    this.getPreferences();
    this._privacy
      .getPrivacyUrl(this.env.facingBrandId)
      .then(res => {
        this.privacyUrl = res.data.value;
        if (this.privacyUrl) this.propetiesLodaded = true;
      })
      .catch(_ => {
        console.error(_);
        this.propetiesLodaded = true;
      });

    if (this.rootScope['brandProperties']) {
      this.privacyUrl = this.rootScope['brandProperties']['PrivacyURL'];
    }
  }

  public showSuccess(): void {
    this.isSuccess = true;
    this.isSavingLoader = false;
    let element = document.getElementsByClassName('privacy-preferences-text')[0] as HTMLElement;
    element.scrollIntoView();
  }

  public showError(): void {
    this.isError = true;
    this.isSuccess = false;
    this.isSavingLoader = false;
    let element = document.getElementsByClassName('privacy-preferences-text')[0] as HTMLElement;
    element.scrollIntoView();
  }

  public isDirty(): void {
    if (this._scope.checkboxes.$dirty) {
      this.showError();
    } else {
      this._state.go('udb.alertsNotifications.myAlerts');
    }
  }

  public SavePreferences(): void {
    this._scope.checkboxes.$dirty = false;
    this.isSuccess = false;
    this.isSavingLoader = true;
    this.isError = false;
    this._privacy
      .savePrivacyPreferences(
        this.preferences.privacyDoNotShareCreditPersonalInfo,
        this.preferences.privacyDoNotMarketPersonalInfo,
        this.preferences.privacyDoNotSharePersonalInfoNonAffiliates
      )
      .then((_response: OlbResponse<any>) => {
        this.showSuccess();
      })
      .catch(() => {
        this.showError();
      });
  }

  public getPreferences(): void {
    this._privacy
      .getPrivacyPreferences()
      .then((response: OlbResponse<any>) => {
        this.preferences.privacyDoNotShareCreditPersonalInfo =
          response.data.privacyDoNotShareCreditPersonalInfo;
        this.preferences.privacyDoNotMarketPersonalInfo =
          response.data.privacyDoNotMarketPersonalInfo;
        this.preferences.privacyDoNotSharePersonalInfoNonAffiliates =
          response.data.privacyDoNotSharePersonalInfoNonAffiliates;
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
