export const TRUSTED_INTERESTED_MODAL_FORM_LABELS = {
  EMAIL_ERROR: {
    required: 'Email is required',
    validEmail: 'A valid email is required',
    enterValidEmail: 'Please enter a valid email address',
  },
  FIRST_NAME_ERROR: {
    required: 'First Name is required',
    invalidCharacter: 'Invalid characters. Only letters are allowed.',
  },
  LAST_NAME_ERROR: {
    required: 'Last Name is required',
    invalidCharacter: 'Invalid characters. Only letters are allowed.',
  },
  NAME_ERROR: {
    required: 'Name is required',
    invalidCharacter: 'Invalid characters. Only letters are allowed.',
  },
  PHONE_NUMBER_ERROR: {
    required: 'Mobile Phone Number is required',
    invalidCharacter: 'Invalid characters. Only numbers are allowed.',
    invalidPhone: 'A valid mobile phone number is required',
  },
  STREET_ADDRESS_ERROR: {
    required: 'Street Address is required',
    invalidCharacter: 'Invalid symbol. Only # / . , are allowed',
  },
  ZIP_CODE_ERROR: {
    required: 'Zip code is required',
    invalidCharacter: 'Invalid characters. Only numbers are allowed.',
    minLength: 'Zip code must contain 5 digits',
  },
  CITY_ERROR: {
    required: 'City is required',
    invalidCharacter: 'Invalid characters. Only alphanumeric allowed.',
  },
};
