import { MultiFactorRequest } from '@core/models';

import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IDeliveryMethodService } from './typings/IDeliveryMethodService';

@Inject('$http', 'env', 'serviceHelper', '$q')
class DeliveryMethodService extends BaseService implements IDeliveryMethodService {
  constructor(http: ng.IHttpService, env: any, serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'deliverymethodsettings', serviceHelper, q);
  }

  getDeliveryMethodSettings(): ApiResponse<string> {
    return this.get();
  }

  setDeliveryMethodSettings(
    alertsDeliveryMethodSetting: AlertsDeliveryMethodSetting,
    cancel: boolean = false
  ): ApiResponse<string> {
    return this.put(`${cancel}`, alertsDeliveryMethodSetting);
  }

  validateDeliveryOTP(request: MultiFactorRequest): ApiResponse<any> {
    return this.post('otp/validate', request);
  }
}

export { DeliveryMethodService };
