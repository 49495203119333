<ng-container [ngSwitch]="currentView">
  <up-section-heading
    section="Business Information"
    [subsection]="currentView"
    [mobile]="isMobileDevice"
    (return)="goBack()"
    (expand)="expanded = $event"
  ></up-section-heading>

  <div *ngSwitchDefault [@startStaticSlideFromLeft]="animationState">
    <div *ngIf="!isMobileDevice || expanded" @openClose [@.disabled]="!isMobileDevice">
      <up-profile-item
        itemName="Email"
        [editable]="userCanEdit"
        (edit)="goTo(views.Email)"
        [alertMessage]="alertMessages.email"
        class="email"
      >
        <app-dot-loader *ngIf="businessInfoLoading; else emailContent"></app-dot-loader>
        <ng-template #emailContent>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters" *ngIf="businessInfo.workEmail">
            <p class="font-weight-bold col-12 col-lg-5">Work Email:</p>
            <div *ngIf="isBusinessEmail" class="col-12 col-lg-7 email-container">
              <p
                [attr.aria-label]="businessInfo.workEmail"
                class="font-weight-normal profile-email"
                [innerText]="businessInfo.workEmail"
              ></p>
              <div aria-hidden="true" class="uk-bottom-tip" [attr.data-uk-tooltip]="businessInfo.workEmail"></div>
            </div>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Phone"
        [editable]="userCanEdit"
        (edit)="goTo(views.Phone)"
        [alertMessage]="alertMessages.phone"
        class="phone"
      >
        <app-dot-loader *ngIf="businessInfoLoading; else phoneContent"></app-dot-loader>
        <ng-template #phoneContent>
          <div
            *ngIf="businessInfo.cellPhone.number && businessInfo.cellPhone.number !== '0'"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 cell-phone-1"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Work Cell:</p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="businessInfo.cellPhone.number | phone"></p>
          </div>
          <div
            *ngIf="businessInfo.workPhone.number && businessInfo.workPhone.number !== '0'"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 cell-phone-2"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Desk Phone:</p>
            <p class="col-12 col-lg-7 font-weight-normal value">
              {{ businessInfo.workPhone.number | phone }}&nbsp;
              <span
                *ngIf="businessInfo.workPhone.extension && businessInfo.workPhone.extension !== '0'"
                [innerText]="'x' + businessInfo?.workPhone?.extension"
              ></span>
            </p>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName="Address"
        [editable]="canEditAddress"
        (edit)="goTo(views.Address)"
        [alertMessage]="alertMessages.address"
        class="address"
      >
        <app-dot-loader *ngIf="businessInfoLoading; else addressContent"></app-dot-loader>
        <ng-template #addressContent>
          <div
            class="d-flex flex-column flex-lg-row no-gutters mb-3 mailing-address"
            *ngIf="!mailingAddressIsIncomplete"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Mailing Address:</p>
            <p
              class="col-12 col-lg-7 invalid-address"
              *ngIf="mailingAddressIsIncomplete; else mailingAddressSection"
            ></p>
            <ng-template #mailingAddressSection>
              <p class="col-12 col-lg-7 font-weight-normal" [innerText]="mailingAddress | address"></p>
            </ng-template>
          </div>
        </ng-template>
      </up-profile-item>
    </div>
  </div>

  <up-change-email
    @slideFromRight
    *ngSwitchCase="views.Email"
    [userProfile]="profileInfo"
    [profileType]="profileTypes.Business"
    (updateEmails)="(null)"
    (updateBusinessMail)="updateBusinessMail()"
    (back)="goBack()"
  ></up-change-email>

  <up-change-phone
    @slideFromRight
    *ngSwitchCase="views.Phone"
    [userProfile]="profileInfo"
    [profileType]="profileTypes.Business"
    (updatePhones)="(null)"
    (updateBusinessPhone)="updateBusinessPhone()"
    (back)="goBack()"
  ></up-change-phone>

  <up-change-address
    @slideFromRight
    *ngSwitchCase="views.Address"
    [userProfile]="profileInfo"
    [profileType]="profileTypes.Business"
    (updateAddresses)="(null)"
    (updateBusinessAddresses)="updateBusinessAddresses()"
    (back)="goBack()"
  ></up-change-address>
</ng-container>
