import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BeneficiariesHelper } from 'services/beneficiaries.helper';
import { IBeneficiariesService } from 'services/typings/IBeneficiariesService';

import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { UtilityIcons } from '@shared/enums';
import { Beneficiary } from '@shared/models/Beneficiary';

@Inject('beneficiariesHelper')
@Component({
  selector: 'app-add-beneficiaries',
  templateUrl: './add-beneficiaries.component.html',
  styleUrls: ['./add-beneficiaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBeneficiariesComponent implements OnInit {
  icons = {
    trash: UtilityIcons.Trash,
  };
  beneficiaries: Beneficiary[] = [{}];
  accounts: OlbAccount[] = [];
  availableAccounts: OlbAccount[] = [];
  selectedAccounts: OlbAccount[] = [];
  accountId: number;
  isBusy = false;
  allBeneficiaries?: Beneficiary[];
  formBeneficiaries: FormGroup;
  constructor(
    @Inject(ROOT_SCOPE) private readonly rootScope: ng.IRootScopeService,
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private readonly params: ng.ui.IStateParamsService,
    @Inject('beneficiariesService') private readonly beneficiariesService: IBeneficiariesService,
    @Inject('serviceHelper') private readonly serviceHelper: IServiceHelper,
    private readonly beneficiariesHelper: BeneficiariesHelper,
    private fb: FormBuilder
  ) {
    this.accountId = this.params['accountId'];
    this.allBeneficiaries = this.params['beneficiaries'];
    this.getUserAccounts();
    this.addSelectedAccount(this.accountId);
  }

  ngOnInit(): void {
    this.formBeneficiaries = this.fb.group({ currentAccountId: 0 });
  }

  addNewBeneficiary(): void {
    this.beneficiaries.push({});
  }

  removeBeneficiary(position: number) {
    this.beneficiaries = this.beneficiaries.filter((_b, index) => index !== position);
  }

  addNewAccount(): void {
    this.addSelectedAccount(this.formBeneficiaries.value.currentAccountId);
  }
  redirectToPage(paramAccountId: any): void {
    this.state.go('udb.accounts.details', {
      id: paramAccountId,
      tab: 1,
    });
  }

  removeAccountSelected(position: number): void {
    this.selectedAccounts = this.selectedAccounts.filter((_a, index) => index !== position);
    this.updateAvailableAccounts();
  }

  acceptAndSave(form: any): void {
    if (!form.$valid) {
      return;
    }

    this.isBusy = true;
    this.addAccountsToBeneficiaries();
    this.beneficiariesService
      .addBeneficiaries(this.beneficiaries)
      .then(res => {
        this.beneficiariesHelper.saveBeneficiaryResponse(res.data);
        this.state.go('udb.accounts.details', { id: this.accountId, tab: 1 });
      })
      .catch(_err => {
        this.isBusy = false;
        this.serviceHelper.errorHandler.bind(this.serviceHelper);
      });
  }

  private getUserAccounts(): void {
    this.accounts = this.rootScope['accounts'].depositAccounts.filter(
      (a: OlbAccount) => a.displayAddBeneficiaries || a.id === this.accountId
    );
    this.availableAccounts = [...this.accounts];
  }

  private addSelectedAccount(accountId: number): void {
    const accountToAdd = { ...this.accounts.find(a => a.id === accountId) };
    this.selectedAccounts.push(accountToAdd);
    this.updateAvailableAccounts();
  }

  private addAccountsToBeneficiaries(): void {
    const accountIds = this.selectedAccounts.map(a => a.id);

    this.beneficiaries.forEach(b => (b.accounts = this.accounts.filter(a => accountIds.some(id => id === a.id))));
  }

  private updateAvailableAccounts(): void {
    this.availableAccounts = [...this.accounts.filter(a => this.selectedAccounts.map(ac => ac.id).indexOf(a.id) < 0)];

    if (this.availableAccounts.length > 0) {
      this.formBeneficiaries.patchValue({ currentAccountId: this.availableAccounts[0].id });
    }
  }
}
