<div class="group-item" *ngIf="axosAccounts.length > 0">
  <span class="display_text">Axos Accounts</span>
  <div *ngFor="let axosAcc of axosAccounts">
    <button class="sm-item" (click)="goToSettingFund(axosAcc)">
      <div class="sm-item-container">
        <img *ngIf="getImage(axosAcc)" class="sm-item-container__image" alt="" [src]="getImage(axosAcc)" />
        <span [innerText]="axosAcc.nickname" class="sm-item-container__text"></span>
      </div>
      <div class="sm-item-container-right d-flex align-items-end flex-column bd-highlight">
        <span [innerText]="axosAcc.availableBalance | currency"></span>
        <span class="sm-item-container-right__text-right">Total Balance</span>
      </div>
    </button>
  </div>
</div>
<div class="group-item" *ngIf="nonAxosAccounts.length > 0">
  <span class="display_text">Non-Axos Accounts</span>
  <div *ngFor="let nonAxos of nonAxosAccounts; let i = index">
    <button class="sm-item" (click)="goToSettingFund(nonAxos)">
      <div class="sm-item-container">
        <img
          *ngIf="hasImage(nonAxos.bankName) || !nonAxos.isExternal"
          class="sm-item-container__image"
          [src]="getImage(nonAxos)"
          alt=""
        />
        <span
          class="sm-item-container__logo"
          [class.sm-item-container__logo--new]="isAccountAggregationEnhancementsActive"
          *ngIf="!hasImage(nonAxos.bankName) && nonAxos.isExternal"
          [innerText]="getBankProfile(nonAxos.bankName)"
        ></span>
        <span [innerText]="nonAxos.nickname" class="sm-item-container__text"></span>
      </div>
      <div
        class="sm-item-container__right d-flex align-items-end flex-column bd-highlight"
        *ngIf="nonAxos.availableBalance"
      >
        <span [innerText]="nonAxos.availableBalance | currency"></span>
        <span class="sm-item-container-right__text-right">Total Balance</span>
      </div>
    </button>
  </div>
</div>
<div>
  <button class="sm-item" (click)="redirectToAccAggr()" id="btn-nonAxos">
    <div class="sm-item-container">
      <app-svg-icon class="sm-item-container__icon-plus" [iconName]="plusIcon"></app-svg-icon>
      <span>Add Non-Axos Account</span>
    </div>
  </button>
</div>
