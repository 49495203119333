import { createAction, props } from '@ngrx/store';

import { AccountDocumentsTileStateType } from '../types';

export const addUpdateStatementsToAccountAction = createAction(
  '[Statements And Documents] Add/Update Statements to Account',
  props<AccountDocumentsTileStateType>()
);

export const addUpdateTaxFormsToAccountAction = createAction(
  '[Statements And Documents] Add/Update Tax Forms to Account',
  props<AccountDocumentsTileStateType>()
);

export const setSelectedAccountDocumentsAction = createAction(
  '[Statements And Documents] Set Selected Account Documents',
  props<{ accountNumber: string }>()
);

export const getAllDocumentsAction = createAction(
  '[Statements And Documents] Fetch All Documents For Account',
  props<AccountDocumentsTileStateType>()
);
