import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {AccountAggregationService} from '@legacy/services/account-aggregation.service';
import Category from 'typings/app/account-aggregation/Category';

@Component({
    selector: 'app-category-filter',
    templateUrl: './category-filter.component.html',
    styleUrls: ['./category-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryFilterComponent implements OnInit, OnDestroy {
    showPopover = false;
    categoriesSelected = 0;
    loadingCategories = false;
    popup: boolean = false;
    @Output() onValueChange = new EventEmitter<any>();

    categories: Category[];
    private listeners: (() => void)[] = [];
    
    constructor(
        private accountAggregationService: AccountAggregationService,
        @Inject('$scope') private $scope: ng.IScope
    ) {}

    ngOnInit() {
        this.categories = [];
        this.loadingCategories = true;
        this.accountAggregationService
            .getCategories()
            .then((response: OlbResponse<Category[]>) => {
                let categories = response.data;
                categories = this.setupCategory(categories.filter(c => c.name.toLowerCase() !== 'pending'));
                this.categories = categories;
                this.loadingCategories = false;
            })
            .catch(() => {
                this.categories = [];
                this.loadingCategories = false;
            });

        this.listeners.push(
            this.$scope.$on('toggleCategoryFilter', (_, categoryId: number) => {
                const category = this.categories.find(c => c.id === categoryId);
                if (!category) return;

                category.active = !category.active;
                this.checkCategoriesSelected();
            })
        );
    }

    ngOnDestroy() {
        this.listeners.forEach(unsubscribe => unsubscribe());
    }

    isFilterActive() {
        return this.categories.filter(c => c.active).length;
    }

    checkCategoriesSelected() {
        this.categoriesSelected = this.categories.filter(c => c.active).length;
        this.triggerCategoriesChanges();
    }
    
    categoryChanged(data: any) {
        this.categories = data;
        this.checkCategoriesSelected();
    }

    clearCategories() {
        this.categories.forEach(a => (a.active = false));
        this.categoriesSelected = 0;
        this.triggerCategoriesChanges();
        this.showPopover = false;
        this.popup = false;
    }

    onClick() {
        this.popup = !this.popup;
    }

    setupCategory(categories: Category[]): Category[] {
        categories.forEach(c => {
            const name = c.name.toLowerCase();
            const idx = name.indexOf(' ');
            c.logoName = idx < 0 ? name : name.substring(0, idx);
            c.active = false;
        });

        return categories;
    }

    categoryTrackBy(_, category: Category) {
        return category.id;
    }

    private triggerCategoriesChanges() {
        const activeCategories = this.categories.filter(ca => ca.active).map(c => c.id);
        this.onValueChange.emit({ value: [...activeCategories] });
    }
}
