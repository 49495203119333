import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getInternalDepositAccountsList } from '@app/accounts/store/selectors';
import { UserProfileViews } from '@app/user-profile/enums';
import { getCompanyDetail, getIsNewUser, getProfileDetails } from '@app/user-profile/store/selectors';
import { UserProfileService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { NavigationIcons } from '@shared/enums';
import { CompanyDetail, CustomerDetail } from '@shared/models';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { CompanyInformationViewComponent } from '../company-information-view/company-information-view.component';
import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';

@Component({
  selector: 'app-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss'],
})
export class UserProfileViewComponent implements OnInit, OnDestroy {
  public readonly ACTIVE_PROFILE_ID = 'activeProfileId';
  companyInformationView: CompanyInformationViewComponent;
  loading = true;
  isMobileDevice = false;
  directDepositEnabled = false;
  googleAuthEnabled = false;
  sbbActive = false;
  iraEnhBaseFlagActive = false;
  sbbBusinessInfoActive = false;
  displayNewUserMessage: boolean;
  icons = {
    chevron: NavigationIcons.ChevronRight,
  };
  fullName: string;
  activeIndex: number = -1;
  activeProfileId: number = -1;
  profileInfo: Partial<CustomerDetail>;
  companyInfo: Partial<CompanyDetail>;
  accountProfiles: Partial<AccountProfile[]>;
  haveAccountProfiles: boolean = false;
  views = UserProfileViews;
  currentView: UserProfileViews;
  viewName = {
    [UserProfileViews.ProfileIcon]: 'Profile Icon',
    [UserProfileViews.SignInAndSecurity]: 'Login & Security',
    [UserProfileViews.ContactInfo]: 'Contact Info',
    [UserProfileViews.PersonalInfo]: 'Personal Info',
    [UserProfileViews.EmploymentInfo]: 'Employment Info',
    [UserProfileViews.PersonalInformation]: 'Personal Information',
    [UserProfileViews.BusinessInformation]: 'Business Information',
    [UserProfileViews.DirectDeposit]: 'Direct Deposit',
    [UserProfileViews.GoogleAuthenticator]: 'Google Authenticator',
  };

  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private $stateParams: ng.ui.IStateParamsService,
    @Inject(STATE) private $state: ng.ui.IStateService,
    private store: Store,
    private featureFlagService: FeatureFlagService,
    private breakpointObserver: BreakpointObserver,
    private userProfileService: UserProfileService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.currentView = this.$stateParams.view as UserProfileViews;
    if (this.activeProfileId === -1 && sessionStorage.getItem('activeProfileId') !== '') {
      this.activeProfileId = +sessionStorage.getItem('activeProfileId');
    }

    this.sbbActive = this.featureFlagService.isSBBActive();
    this.googleAuthEnabled = this.featureFlagService.isGoogleAuthenticatorActive();
    this.iraEnhBaseFlagActive = this.featureFlagService.isIraEnhBaseFlagActive();
    //TODO SBB: there is no concept of current Profile Active. Info related should be shown within Company Information.
    this.sbbBusinessInfoActive = true;

    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getIsNewUser)
      .pipe(
        switchMap(data => {
          if (data !== null) {
            return of(data);
          }

          return this.userProfileService.checkCifDate();
        })
      )
      .subscribe(isNewUser => {
        const viewsWithNewUserWarning = [UserProfileViews.ContactInfo, UserProfileViews.PersonalInformation];
        this.displayNewUserMessage = isNewUser && viewsWithNewUserWarning.includes(this.currentView);
      });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.profileInfo = data;
        this.fullName = `${data.firstName} ${data.lastName}`;
        this.loading = false;
      });

    this.subsink.sink = this.store
      .select(getCompanyDetail)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.companyInfo = data;
      });

    const accountProfilesArray = JSON.parse(
      sessionStorage.getItem('userBusinesses')
    ) as AccountProfile[] ?? [];
    this.accountProfiles = accountProfilesArray.filter(
      item => item.profileType === AccountProfileType.Business
    );
    this.haveAccountProfiles = !!this.accountProfiles;

    this.subsink.sink = this.store
      .select(getInternalDepositAccountsList)
      .pipe(filter(data => data.length > 0))
      .subscribe(accounts => {
        this.directDepositEnabled =
          this.featureFlagService.isDirectDepositEnabled() &&
          accounts.some(acc => acc.canMakeDepositSwitch && !acc.isSBB);
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goTo(view: UserProfileViews, index?: any, acc?: any): void {
    if (acc != null) {
      this.activeProfileId = acc;
      sessionStorage.setItem(this.ACTIVE_PROFILE_ID, this.activeProfileId.toString());
    }
    this.activeIndex = index ?? -1;

    this.$state.go('udb.userProfile', { view });
    this.currentView = view;
    this.cdr.detectChanges();
  }

  goToDirectDeposit(): void {
    this.$state.go('udb.clickswitch', {
      backstate: 'udb.userProfile',
      backStateParams: { view: UserProfileViews.DirectDeposit },
    });
  }

  gettingActiveProfileNum(profileId: number): void {
    if (profileId !== this.activeProfileId) {
      this.activeProfileId = profileId;
    }
  }

  isThisOptionActive(requestedView: UserProfileViews, profileId?: number): boolean {
    return profileId !== undefined
      ? this.currentView === requestedView && profileId === this.activeProfileId
      : this.currentView === requestedView;
  }
}
