<div class="p2p center wrap">
  <div class="axos-banner">
    <h1 class="text-center hidden-sm">
      {{ step === 1 ? 'Pay It Now' : 'Confirm your identity' }}
    </h1>
    <h1 class="text-center shown-sm">
      {{ step === 1 ? 'Pay It Now' : 'Confirm your identity' }}
    </h1>
    <p class="text-center hidden-sm" id="padding-top-account">
      {{ mainSubtitle }}
    </p>
    <app-dot-loader *ngIf="isVerifyingEnrollment">
      Verifying enrollment
    </app-dot-loader>
    <nav class="p2p-nav-axos">
      <ul>
        <li ui-sref-active="active" [ngClass]="{ active: isSendMoney }">
          <a href="#" (click)="goToSendMoneyTab('udb.transfers.p2p.sendMoney')">
            Send Money
          </a>
        </li>
        <li ui-sref-active="active" [ngClass]="{ active: isHistory }">
          <a href="#" (click)="goToHistoryTab('udb.transfers.p2p.history')">
            History
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="frow center wrap view-container" *ngIf="isEnrolled">
    <app-alert-notification
      *ngIf="showEmailError"
      class="m-t-lg fcol-cs-12 fcol-md-8"
      [alertType]="'error'"
      (dismiss)="showEmailError = false"
    >
      <span>
        The primary email address we have for you on file is not valid. Please
        go to the
        <a href="#" (click)="goToUserProfile()">User Profile</a>
        section to update it.
      </span>
    </app-alert-notification>
    <div class="fcol-md-12">
      <app-send-money *ngIf="isSendMoney"></app-send-money>
      <app-history *ngIf="isHistory"></app-history>
    </div>
  </div>
</div>
