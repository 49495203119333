<svg
  class="svg-icon"
  xmlns="http://www.w3.org/2000/svg"
  xml:space="preserve"
  version="1.1"
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 10416 10416"
  [ngStyle]="templateStyles"
  [attr.aria-hidden]="hidden"
  [attr.aria-label]="ariaLabel"
  [attr.stroke]="templateStyles.fill"
>
  <use [attr.xlink:href]="svgUrl" />
</svg>
