import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgStyles } from '@shared/models';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() ariaLabel: string;
  @Input() set size(value: string) {
    this.templateStyles.height = value;
    this.templateStyles.width = value;
  }

  @Input() set iconName(value: string) {
    if (!value) {
      this.svgUrl = '';
    } else {
      const [category] = value.split('-');
      this.svgUrl = `assets/svg/icons/${category}.svg#${value}`;
    }
  }

  @Input() set height(value: string) {
    this.templateStyles.height = value;
  }

  @Input() set width(value: string) {
    this.templateStyles.width = value;
  }

  @Input() set iconColor(value: string) {
    this.templateStyles.fill = value;
  }

  @Input() set styles(args: Partial<SvgStyles>) {
    this.templateStyles = { ...this.templateStyles, ...args };
  }

  templateStyles: SvgStyles = {
    width: '1.25em',
    height: '1.25em',
    fill: 'currentColor',
    strokeWidth: 0,
  };

  svgUrl: string;

  get hidden(): boolean {
    return !this.ariaLabel;
  }
}
