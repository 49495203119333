import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

@Component({
  selector: 'app-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewHeaderComponent implements OnInit {
  @Input() titleText: string;
  @HostBinding('style.backgroundImage')
  backgroundUrl: string;

  @HostBinding('style.flexDirection')
  @Input()
  flexDirection: 'column' | 'column-reverse' = 'column';

  constructor(@Inject(olbSettings) private settings: OlbSettings) {}

  ngOnInit(): void {
    this.backgroundUrl = `url('/assets/${this.settings.brand}/bg_pattern.jpg')`;
  }
}
