import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NavigationIcons, UtilityIcons } from '@shared/enums';
// import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
@Component({
  selector: 'app-meatball-menu',
  templateUrl: './meatball-menu.component.html',
  styleUrls: ['./meatball-menu.component.scss'],
})
export class MeatballMenuComponent implements OnInit {
  @Input() options: any[];
  @Input() isRecategorizeExpanded: boolean;
  @Input() isDetailsExpanded: boolean;

  @Output() optionSelected = new EventEmitter();
  @Output() collapse = new EventEmitter();

  icons = {
    ChevronUp: NavigationIcons.ChevronUp,
    Meatball: UtilityIcons.Meatball,
    Kebab: UtilityIcons.Kebab,
  };
  constructor() {}
  ngOnInit(): void {
    this.isRecategorizeExpanded = false;
  }
  /**
   * Triggers the function specified for the option selected
   * @param option Option selected
   */
  executeAction() {
    this.isRecategorizeExpanded = !this.isRecategorizeExpanded;
    this.optionSelected.emit();
  }

  /** Triggers the collapse function */
  collapseRow() {
    this.isRecategorizeExpanded = false;
    this.isDetailsExpanded = false;
    this.collapse.emit();
  }

  moreOptionsClicked() {
    this.isRecategorizeExpanded = false;
    this.isDetailsExpanded = false;
  }
}
