import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { usStates } from '@app/config/constants';
import { zipCodeRegex } from '@app/config/regexes';
import { AddressType } from '@app/user-profile/enums';
import { addressIsEmpty } from '@app/user-profile/utils';
import { DialogService, EnrollmentService } from '@core/services';
import { AlertsIcons } from '@shared/enums';
import { Address } from '@shared/models';

const defaultLabels = {
  streetAddress1: 'Address Line 1',
  streetAddress2: 'Address Line 2',
  postalCode: 'Zip Code',
  city: 'City / Town',
  state: 'State / U.S. Territory',
};

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() addressType: AddressType;
  @Input() formLabels: Partial<Address> = {};

  states = usStates;
  statesKeys = Object.keys(this.states);
  fieldsPrefix = '';
  labels: typeof defaultLabels;
  postalCodeValidLength = 5;
  businessAddressIsEmpty: boolean;
  isBusiness: boolean;

  private subsink = new SubSink();

  constructor(private enrollmentService: EnrollmentService, private dialogService: DialogService) {}

  ngOnInit(): void {
    const postalCodeControl = this.parentForm.get('postalCode');

    this.labels = { ...defaultLabels, ...this.formLabels };
    if (this.addressType) {
      this.fieldsPrefix = `${AddressType[this.addressType].toLowerCase()}-`;
    }

    // Check if postal code length is valid on component load
    if (postalCodeControl.value?.length > this.postalCodeValidLength) {
      postalCodeControl.markAsTouched();
    }

    this.subsink.sink = this.parentForm.get('stateCode').valueChanges.subscribe(stateCode => {
      this.parentForm.get('state').patchValue(this.states[stateCode]);
    });

    this.subsink.sink = postalCodeControl.valueChanges
      .pipe(
        tap(() => {
          this.parentForm.patchValue({
            city: '',
            stateCode: '',
            state: '',
          });
        }),
        distinctUntilChanged(),
        filter((zipCode: string) => zipCodeRegex.test(zipCode)),
        switchMap((zipCode: string) => {
          return this.enrollmentService.getCityAndState(zipCode).pipe(
            catchError(() => {
              this.dialogService.open({
                icon: AlertsIcons.ExclamationCircle,
                cancelText: null,
                okText: 'Ok',
                title: 'Not Found',
                content: 'Could not find city and state by zip code.\nPlease enter city and state manually.',
              });

              return EMPTY;
            })
          );
        })
      )
      .subscribe({
        next: ({ data }) => {
          this.parentForm.patchValue({
            city: data.city,
            stateCode: data.stateCode,
            state: data.stateName,
          });
        },
      });

    this.businessAddressIsEmpty = this.addressType === AddressType.Business && addressIsEmpty(this.parentForm.value);
    this.isBusiness = this.addressType === AddressType.Business;

    this.subsink.sink = this.parentForm.valueChanges.subscribe(formValue => {
      this.businessAddressIsEmpty = this.addressType === AddressType.Business && addressIsEmpty(formValue);
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
