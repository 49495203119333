import { TotalValueFilter } from '..';
import { FilterDates } from '../../enums';
export const TOTAL_VALUE_FILTERS_CONFIGURATION: TotalValueFilter[] = [
  { label: FilterDates.M3 },
  { label: FilterDates.M6 },
  { label: FilterDates.YTD },
  { label: FilterDates.Y1 },
  { label: FilterDates.Y3 },
  { label: FilterDates.Y5 },
  { label: FilterDates.All },
];
