import {
  TModelSortTypeValues,
  TModelSortTypes,
  TSortDirectionTypeValues,
  TSortDirectionTypes,
} from '../../../types';
import { Model, TFilterOptionLabels, TSortOptionLabels } from '../../types';
import { lowercaseFirstLetter } from '../lowercase-first-letter/lowercase-first-letter.function';

const defaultOptions: TSortOptionLabels = {
  PortfolioPercent: {
    typeLabel: '% of Portfolio',
    sortByKey: 'portfolioPercent',
    directionLabels: {
      Descending: 'H-L',
      Ascending: 'L-H',
    },
  },
  AllTimeChangePercent: {
    typeLabel: '% Change All Time',
    sortByKey: 'allTimeChangePercent',
    directionLabels: {
      Descending: 'H-L',
      Ascending: 'L-H',
    },
  },
  PastDayChangePercent: {
    typeLabel: '% Change Today',
    sortByKey: 'pastDayChangePercent',
    directionLabels: {
      Ascending: 'L-H',
      Descending: 'H-L',
    },
  },
  Description: {
    typeLabel: 'Name',
    sortByKey: 'description',
    directionLabels: {
      Ascending: 'A-Z',
      Descending: 'Z-A',
    },
  },
};

/**
 *
 * Creates an array that combines all the options available from {@link ModelSortTypes} and {@link TSortDirectionTypes}.
 * This can be used to create a base list using the default base state from ModelSortTypes and SortDirection.
 * This function can be replaced with ngrx populate actions, but remember to replace it with any function
 * that complies with the {@link TSortOptionConstructor} signature. This signature must be used to ensure that the current function
 * replacing {@link createLabeledSortingOptions} will have type safety.
 *
 * @param filterTypeOptions - {@link TModelSortTypes}.
 * @param filterDirectionOptions - {@link TSortDirectionTypes}.
 * @param labelsSeed - {@link TSortOptionLabels}.
 * @returns Array of {@link TFilterOptionLabels}.
 */

function createLabeledSortingOptions(
  filterTypeOptions: TModelSortTypes,
  filterDirectionOptions: TSortDirectionTypes,
  labelsSeed: TSortOptionLabels,
  selectedSortType: TModelSortTypeValues,
  selectedSortDirection: TSortDirectionTypeValues
): TFilterOptionLabels[] {
  const filterOptions: TFilterOptionLabels[] = [];

  for (const [key, typeLabel] of Object.entries(labelsSeed)) {
    if (typeLabel) {
      for (const [directionLabel] of Object.entries(
        typeLabel.directionLabels
      )) {
        const option: TFilterOptionLabels = {
          isSelected:
            selectedSortDirection === filterDirectionOptions[directionLabel] &&
            selectedSortType === filterTypeOptions[key],
          text: `${typeLabel.typeLabel} ${typeLabel.directionLabels[directionLabel]}`,
          sortByKey: lowercaseFirstLetter<keyof Model>(typeLabel.sortByKey),
          value: (filterOptions.length + 1).toString(),
          sortDirection: filterDirectionOptions[directionLabel],
          sortDirectionLabel: typeLabel.directionLabels[directionLabel],
          sortType: filterTypeOptions[key],
          sortTypeLabel: typeLabel.typeLabel,
        };
        filterOptions.push(option);
      }
    }
  }

  return filterOptions;
}

createLabeledSortingOptions['defaultOptionSeed'] = defaultOptions;

export default createLabeledSortingOptions;
