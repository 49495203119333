export class TaxPlanBeneficiary {
  cif: string;
  planCode: number;
  key: string;
  level: number;
  name: string;
  percent: number;
  birthDate: string;
  relationshipId: string;

  constructor(args?: Partial<TaxPlanBeneficiary>) {
    Object.assign(this, args);
  }
}
