<div *ngIf="!isForecastLoading && forecast">
  <p class="projection-value-title">Value Projection</p>
  <span class="projection-value-amount">
    {{ projection.low | currency: 'USD':'symbol':'1.0-0' }} - {{ projection.high | currency: 'USD':'symbol':'1.0-0' }}*
  </span>
  <span class="projection-value-date">By {{ projection.date | date: 'MMM YYYY' }}</span>
  <app-projection-graph [projectionData]="forecast"></app-projection-graph>
</div>
<app-dot-loader *ngIf="isForecastLoading || isPortfolioLoading"></app-dot-loader>
<p class="disclaimer" *ngIf="!isPortfolioLoading && forecast && portfolios">
  *Projections are made based on the deposit amounts entered and your {{ riskDescription }} risk portfolio with
  {{ portfolios.stocks }}% stocks, {{ portfolios.bonds }}% bonds and {{ portfolios.cash }}% cash.
</p>
