import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';

import { Uk2Tier1AlertsEnum, Uk2Tier1NavigationEnum } from '@axos/uikit-v2-lib';

import { TrustedContactsType } from '@app/Areas/AAS/features/account-details/core';
import { ModalAction } from '../../enums';
import { TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS } from '../../../validators';
import { INPUT_EVENT_TYPES, TRUSTED_INTERESTED_MODAL_FORM_LABELS } from '../../constants';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-trusted-contact-form-modal',
  templateUrl: './trusted-contact-form-modal.component.html',
  styleUrls: ['./trusted-contact-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustedContactFormModalComponent implements OnInit, OnDestroy {
  @Input() parentFormModal: FormGroup;
  @Input() data: TrustedContactsType;
  @Input() modalAction: ModalAction;
  @Output() isFormDirty = new EventEmitter();
  @ViewChild('inputFirstName') inputFirstName!: ElementRef<HTMLInputElement>;
  @ViewChild('inputLastName') inputLastName!: ElementRef<HTMLInputElement>;
  @ViewChild('inputPhone') inputPhone!: ElementRef<HTMLInputElement>;
  @ViewChild('inputEmail') inputEmail!: ElementRef<HTMLInputElement>;

  relationShipList = [
    { value: 'Spouse', label: 'Spouse' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Sibling', label: 'Sibling' },
    { value: 'Child', label: 'Child' },
    { value: 'Grandchild', label: 'Grandchild' },
    { value: 'Grandparent', label: 'Grandparent' },
    { value: 'Relative', label: 'Relative' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Other', label: 'Other' },
  ];
  phoneMaskConfig = {
    inputMask: 'AAA) AAA - AAAA',
    showMaskTyped: true,
    dropSpecialCharacters: true,
    placeHolder: ' (XXX) XXX - XXXX',
    placeHolderCharacter: 'X',
  };
  phoneInputBlurFlag = false;
  emailInputBlurFlag = false;
  showClearFieldButton = true;
  clearButtonSvgIconName = Uk2Tier1NavigationEnum.x;
  exclamationTriangleSvgIconName = Uk2Tier1AlertsEnum.exclamationTriangle;
  firstNameErrorMessageSubject = new BehaviorSubject('');
  lastNameErrorMessageSubject = new BehaviorSubject('');
  emailErrorMessageSubject = new BehaviorSubject('');
  phoneErrorMessageSubject = new BehaviorSubject('');
  suffixList = [
    { value: 'Sr', label: 'Sr' },
    { value: 'Jr', label: 'Jr' },
    { value: 'I', label: 'I' },
    { value: 'II', label: 'II' },
    { value: 'III', label: 'III' },
    { value: 'IV', label: 'IV' },
    { value: 'V', label: 'V' },
  ];

  private destroy$ = new Subject<void>();

  trustedContactFormModal: FormGroup = this.fb.group({
    firstName: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.firstName]),
    lastName: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.lastName]),
    suffix: new FormControl(''),
    relationShip: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.relationShip]),
    phoneNumber: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.phoneNumberDefault]),
    email: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.emailDefault]),
  });
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.parentFormModal.addControl('trustedContactFormModal', this.trustedContactFormModal);
    this.trustedContactFormModal.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isFormDirty.emit(this.trustedContactFormModal.dirty);
    });
    this.listenInputs();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get firstName(): any {
    return this.trustedContactFormModal.get('firstName');
  }

  get lastName(): any {
    return this.trustedContactFormModal.get('lastName');
  }

  get suffix(): any {
    return this.trustedContactFormModal.get('suffix');
  }

  get phoneNumber(): any {
    return this.trustedContactFormModal.get('phoneNumber');
  }

  get relationShip(): any {
    return this.trustedContactFormModal.get('relationShip');
  }

  get email(): any {
    return this.trustedContactFormModal.get('email');
  }

  clearFirstName() {
    this.trustedContactFormModal.patchValue({ firstName: '' });
  }

  clearLastName() {
    this.trustedContactFormModal.patchValue({ lastName: '' });
  }

  clearPhoneNumber() {
    this.trustedContactFormModal.patchValue({ phoneNumber: '' });
  }

  clearEmail() {
    this.trustedContactFormModal.patchValue({ email: '' });
  }

  handleSelectPhone(event: any) {
    event.preventDefault();
    this.setCursorPhone();
  }

  setCursorPhone() {
    let value = this.inputPhone?.nativeElement.value;
    let lastDigitIndex = -1;

    if (this.phoneMaskConfig.placeHolderCharacter == 'X') {
      if (value.indexOf('X') > 4) {
        this.phoneMaskConfig.placeHolderCharacter = '_';
        this.trustedContactFormModal.patchValue({
          phoneNumber: this.inputPhone?.nativeElement.value.replace(/X/g, '_'),
        });
      }
    }

    if (value.indexOf('_') != -1) {
      lastDigitIndex = value.indexOf('_');
    } else {
      lastDigitIndex = value.indexOf('X');
    }

    if (lastDigitIndex != 15 && lastDigitIndex != 9) {
      this.inputPhone?.nativeElement.setSelectionRange(lastDigitIndex, lastDigitIndex);
    }
  }

  private listenInputs() {
    const targetElement = this.inputEmail?.nativeElement;
    if (targetElement) {
      this.fillFields();
      // Events for First Name
      this.listenInputFirstNameEvents(INPUT_EVENT_TYPES.input);
      this.listenInputFirstNameEvents(INPUT_EVENT_TYPES.blur);
      // Events for Last Name
      this.listenInputLastNameEvents(INPUT_EVENT_TYPES.input);
      this.listenInputLastNameEvents(INPUT_EVENT_TYPES.blur);
      // Events for Phone Number
      this.listenInputPhoneEvents(INPUT_EVENT_TYPES.input);
      this.listenInputPhoneEvents(INPUT_EVENT_TYPES.blur);
      // Events for Email
      this.listenInputEmailEvents(INPUT_EVENT_TYPES.input);
      this.listenInputEmailEvents(INPUT_EVENT_TYPES.blur);
    } else {
      setTimeout(() => {
        this.listenInputs();
      }, 100);
    }
  }

  private fillFields() {
    // Set values to the form fields
    if (this.modalAction === ModalAction.Edit) {
      this.firstName.setValue(this.data.firstName);
      this.lastName.setValue(this.data.lastName);
      this.suffix.setValue(this.formatSuffix(this.data.suffixType));
      this.relationShip.setValue(this.data.relationship);
      this.phoneNumber.setValue(this.data.phoneNumber);
      this.email.setValue(this.data.email);
    }
  }

  private hasInvalidFirstName() {
    return (
      this.trustedContactFormModal.dirty &&
      this.trustedContactFormModal.get('firstName')?.hasError('textOnlyValidation')
    );
  }

  private getFirstNameErrorMessage(typeEvent?: string) {
    let errorMessage: string | null = null;

    if (this.hasInvalidFirstName() && (typeEvent === INPUT_EVENT_TYPES.input || typeEvent === INPUT_EVENT_TYPES.blur)) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.FIRST_NAME_ERROR.invalidCharacter;
    }

    if ((!this.hasInvalidFirstName() && !this.firstName.value) || this.firstName.value === '') {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.FIRST_NAME_ERROR.required;
    }

    if (errorMessage) {
      this.firstName?.markAsTouched();
      this.firstNameErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputFirstNameEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.firstName];

    fromEvent(this.inputFirstName?.nativeElement, eventType).subscribe(event => {
      this.firstName?.setValidators(validations);
      this.firstName?.updateValueAndValidity();
      this.getFirstNameErrorMessage(event.type);
    });
  }

  private hasInvalidLastName() {
    return (
      this.trustedContactFormModal.dirty && this.trustedContactFormModal.get('lastName')?.hasError('textOnlyValidation')
    );
  }

  private getLastNameErrorMessage(typeEvent?: string) {
    let errorMessage: string | null = null;

    if (this.hasInvalidLastName() && (typeEvent === INPUT_EVENT_TYPES.input || typeEvent === INPUT_EVENT_TYPES.blur)) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.LAST_NAME_ERROR.invalidCharacter;
    }

    if ((!this.hasInvalidLastName() && !this.lastName.value) || this.lastName.value === '') {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.LAST_NAME_ERROR.required;
    }

    if (errorMessage) {
      this.lastName?.markAsTouched();
      this.lastNameErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputLastNameEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.lastName];

    fromEvent(this.inputLastName?.nativeElement, eventType).subscribe(event => {
      this.lastName?.setValidators(validations);
      this.lastName?.updateValueAndValidity();
      this.getLastNameErrorMessage(event.type);
    });
  }

  private hasInvalidEmail() {
    return this.trustedContactFormModal.dirty && this.trustedContactFormModal.get('email')?.hasError('emailValidation');
  }

  private hasInvalidCharEmail() {
    return (
      this.trustedContactFormModal.dirty &&
      this.trustedContactFormModal.get('email')?.hasError('invalidCharactersEmail')
    );
  }

  private getEmailErrorMessage(typeEvent?: string) {
    let errorMessage: string | null = null;
    if (typeEvent === INPUT_EVENT_TYPES.blur) this.emailInputBlurFlag = true;

    if (
      (this.hasInvalidEmail() && typeEvent === INPUT_EVENT_TYPES.blur) ||
      (this.hasInvalidEmail() && typeEvent === INPUT_EVENT_TYPES.input && this.emailInputBlurFlag)
    ) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.EMAIL_ERROR.validEmail;
    }

    if (this.hasInvalidCharEmail() && typeEvent === INPUT_EVENT_TYPES.input) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.EMAIL_ERROR.enterValidEmail;
    }

    if ((!this.hasInvalidEmail() && !this.email.value) || this.email.value === '') {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.EMAIL_ERROR.required;
    }

    if (errorMessage) {
      this.email?.markAsTouched();
      this.emailErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputEmailEvents(eventType: string) {
    let validations: any =
      eventType === INPUT_EVENT_TYPES.blur
        ? [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.emailOnBlur]
        : [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.emailOnChange];

    fromEvent(this.inputEmail?.nativeElement, eventType).subscribe(event => {
      this.email?.setValidators(validations);
      this.email?.updateValueAndValidity();
      this.getEmailErrorMessage(event.type);
    });
  }

  private hasInvalidCharacterPhone() {
    return (
      this.trustedContactFormModal.dirty &&
      this.trustedContactFormModal.get('phoneNumber')?.hasError('phoneNumberValidation')
    );
  }

  private hasMaskErrorPhone() {
    return this.trustedContactFormModal.dirty && this.trustedContactFormModal.value.length != 10;
  }

  private phoneOnBlurValidation() {
    let errorMessage: string | null = null;
    this.phoneInputBlurFlag = true;

    if (this.hasMaskErrorPhone() && this.phoneNumber.value != '') {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.PHONE_NUMBER_ERROR.invalidPhone;
    }

    if (this.hasInvalidCharacterPhone()) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.PHONE_NUMBER_ERROR.invalidCharacter;
    }

    if (this.phoneNumber.value === '') {
      this.phoneMaskConfig.placeHolderCharacter = 'X';
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.PHONE_NUMBER_ERROR.required;
    }

    if (errorMessage) {
      this.phoneNumber?.markAsTouched();
      this.phoneErrorMessageSubject.next(errorMessage);
    }
  }

  private phoneOnChangeValidation() {
    let errorMessage: string | null = null;

    if (this.hasMaskErrorPhone() && this.phoneInputBlurFlag) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.PHONE_NUMBER_ERROR.invalidPhone;
    }

    if (this.hasInvalidCharacterPhone()) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.PHONE_NUMBER_ERROR.invalidCharacter;
    }

    if (this.phoneNumber.value === '' && this.phoneInputBlurFlag) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.PHONE_NUMBER_ERROR.required;
    }

    if (errorMessage) {
      this.phoneNumber?.markAsTouched();
      this.phoneErrorMessageSubject.next(errorMessage);
    }

    if (this.phoneNumber.value === '') {
      this.phoneMaskConfig.placeHolderCharacter = 'X';
    }
  }

  private getPhoneErrorMessage(typeEvent?: string) {
    if (typeEvent === INPUT_EVENT_TYPES.input) {
      this.phoneOnChangeValidation();
    }

    if (typeEvent === INPUT_EVENT_TYPES.blur) {
      this.phoneOnBlurValidation();
    }
  }

  private listenInputPhoneEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.phoneNumber];

    fromEvent(this.inputPhone?.nativeElement, eventType).subscribe(event => {
      this.phoneNumber?.setValidators(validations);
      this.phoneNumber?.updateValueAndValidity();
      this.getPhoneErrorMessage(event.type);
    });
  }

  private formatSuffix(suffix: string): string {
    if (suffix === 'SR' || suffix === 'JR') {
      const transformedSuffix = suffix.slice(0, 1) + suffix.slice(1).toLowerCase();

      return transformedSuffix;
    }

    return suffix;
  }
}
