import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';
import { AxosInvestUrlHelper } from 'services/axos-invest-urls.service';

import { Goal, MilestoneSummary } from '@app/axos-invest/models';
import { getGoalSummary } from '@app/axos-invest/store/selectors';

import { Inject } from '../../../decorators/decorators';

@Inject('ngrxStore', 'axosInvestUrlHelper')
export class AxosInvestPortfolioController {
  accountIcon: any = 'axos-invest_logo.svg';
  milestoneDetail: MilestoneSummary;
  openMilestones: Goal[];
  combinedAutoDeposit: number;
  frequencyAutoDeposit: string;
  milestoneGroupTitle: string;
  milestoneLength: number;
  formulaSummary: string;
  subSink = new SubSink();

  constructor(private readonly store: Store, private readonly axosInvestUrlHelper: AxosInvestUrlHelper) {}

  $onInit() {
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(goalSummary => {
      if (goalSummary.milestones.length > 0) {
        this.milestoneDetail = goalSummary;
        this.openMilestones = this.milestoneDetail.milestones.filter(x => !x.isClosed);
        this.getMilestoneValues();

        if (!this.milestoneDetail.hasError) {
          this.getTotalBalance();
          this.getAutoDepositValues();
          this.getMilestoneLength();
        }
      }
    });
  }

  redirectToPortfolio(): void {
    this.axosInvestUrlHelper.getOverviewUrl().then(url => {
      const win = window.open(url, '_blank');
      win.focus();
    });
  }

  private getTotalBalance(): void {
    if (this.milestoneDetail.isClosed) {
      this.milestoneDetail.totalBalance = 0;
    }
  }

  private getAutoDepositValues(): void {
    if (this.milestoneDetail.milestones.length === 0) {
      this.combinedAutoDeposit = 0;

      return;
    }

    let combinedAd = 0;
    this.openMilestones.forEach(milestone => {
      if (!milestone.isClosed) {
        combinedAd += milestone.autoDeposit.amount;
      }
    });

    if (
      this.milestoneDetail.wallet &&
      !this.milestoneDetail.wallet.isClosed &&
      this.milestoneDetail.wallet.autoDeposit
    ) {
      combinedAd += this.milestoneDetail.wallet.autoDeposit.amount;
    }

    if (!this.milestoneDetail.isClosed) {
      this.combinedAutoDeposit = combinedAd;
    } else {
      this.combinedAutoDeposit = 0;
    }

    this.frequencyAutoDeposit = this.milestoneDetail.milestones[0].autoDeposit.frequency;
  }

  private getMilestoneValues(): void {
    this.milestoneGroupTitle = this.openMilestones.length <= 1 && !this.milestoneDetail.hasError ? 'Goal' : 'Goals';
  }

  private getMilestoneLength(): void {
    if (!this.milestoneDetail.isClosed) {
      this.milestoneLength = this.milestoneDetail.milestones.filter(x => !x.isClosed).length;
    } else {
      this.milestoneLength = 0;
    }
  }
}
