import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { ToolTipType } from '@app/pfm/enums/tooltip-type-enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { AccountsEffects } from '@app/pfm/store/accounts/accounts.effects';
import {
  getAccountsExcludingOthers,
  getOverallLoading,
  getProviders,
} from '@app/pfm/store/accounts/accounts.selectors';
import { SpendingFilters } from '@app/pfm/store/spending/spending-filters';
import {
  commitFilters,
  resetExcludedAccountsFilter,
  resetUncommittedFilters,
  setExcludedAccountsFilter,
} from '@app/pfm/store/spending/spending.actions';
import {
  getCommittedFilters,
  getCommittedFiltersInDefaultState,
  getLastFiltersRequest,
  getSpentByCategories,
  getTotalSpent,
  getTransactions,
  getTransactionsLoading,
  getUncommittedExcludedAccountsFilter,
  getUncommittedExcludedCategoriesFilter,
  getUncommittedTimePeriodFilter,
} from '@app/pfm/store/spending/spending.selectors';
import { STATE } from '@core/tokens';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { NavigationIcons, UtilityIcons } from '@shared/enums';
import { ExternalBankProvider } from '@shared/models';

import { categories } from '../../models/category';
import { Category } from '../../models/category';
import { FiltersModalComponent } from '../filters-modal/filters-modal.component';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-spending',
  templateUrl: './spending.component.html',
  styleUrls: ['./spending.component.scss'],
})
export class SpendingComponent implements OnInit, OnDestroy {
  // Uncommitted filters (dropdown/modal hasn't been closed)
  timePeriodFilter = this.store.select(getUncommittedTimePeriodFilter);
  excludedAccountsFilter = this.store.select(getUncommittedExcludedAccountsFilter);
  categoriesFilter = this.store.select(getUncommittedExcludedCategoriesFilter);

  transactions = this.store.select(getTransactions);
  transactionsLoading = this.store.select(getTransactionsLoading);
  accountsLoading = this.store.select(getOverallLoading);
  lastFiltersRequest = this.store.select(getLastFiltersRequest);
  committedFiltersInDefaultState = this.store.select(getCommittedFiltersInDefaultState);

  Math = Math;
  categories = categories;
  spentByCategories: Category[] = [];
  committedFilters = new SpendingFilters();
  accounts: AccountOverview[];
  totalSpent = 0;
  isEmpty = false;
  explode = { categories: [] };
  providers: ExternalBankProvider[];
  subsink = new SubSink();
  toolTipType = ToolTipType;
  @ViewChild('donutChartCont') donutChartCont: ElementRef;

  icons = {
    ArrowBack: NavigationIcons.ArrowBack,
    ChevronUp: NavigationIcons.ChevronUp,
    ChevronDown: NavigationIcons.ChevronDown,
    FilterSlider: UtilityIcons.FilterSlider,
    Checkmark: UtilityIcons.Checkmark,
  };

  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(DOCUMENT) private readonly document: Document,

    readonly accAggFormatter: AccountAggregationChartFormatterService,
    readonly dialog: MatDialog,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    public accountsEffects: AccountsEffects
  ) {}

  commitFilters = () => this.store.dispatch(commitFilters());

  ngOnInit(): void {
    this.document.body.style.backgroundColor = '#F4F5F7';

    // reset committed and uncommitted filters
    this.store.dispatch(resetUncommittedFilters());
    this.store.dispatch(commitFilters());

    this.accountsEffects.loadAccounts(this.subsink);

    this.subsink.sink = this.store.select(getSpentByCategories).subscribe(spentByCategories => {
      this.spentByCategories = this.filterCategories(spentByCategories, this.committedFilters);
    });

    this.subsink.sink = this.store.select(getCommittedFilters).subscribe(committedFilters => {
      this.committedFilters = committedFilters;
    });

    this.subsink.sink = this.store.select(getAccountsExcludingOthers).subscribe(accounts => {
      if (!this.featureFlagService.isRiaPilotInsightsActive()) {
        accounts = accounts.filter(account => !account.isAxosAdvisory);
      }
      this.accounts = accounts;
    });

    this.subsink.sink = this.store.select(getTotalSpent).subscribe(totalSpent => {
      this.totalSpent = totalSpent;
      this.isEmpty = totalSpent === 0;
    });

    this.subsink.sink = this.store.select(getProviders).subscribe(providers => {
      this.providers = providers;
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goBack() {
    this.state.go('udb.accounts.insights');
  }

  openFiltersModal() {
    this.dialog
      .open(FiltersModalComponent, {
        data: {
          providers: this.providers,
          accounts: this.accounts,
        },
        disableClose: true,
        panelClass: ['full-screen-modal'],
      })
      .afterClosed()
      .subscribe(_ => this.commitFilters());
  }

  toggleExplodedCategory(categoryId: number) {
    const oldExplodedCategory = this.explode.categories[0];

    if (oldExplodedCategory === categoryId) this.explode = { ...this.explode, categories: [] };
    else this.explode = { ...this.explode, categories: [categoryId] };
  }

  getExplodedCategoryName() {
    // Donut wedge expanded, return expanded category name
    if (this.explode.categories.length) {
      return this.spentByCategories.find(c => c.id === this.explode.categories[0])?.name;
    }

    // 1 Category filtered, return its name
    const includedCategories = categories.filter(c => !this.committedFilters.excludedCategories.has(c.id));
    if (includedCategories.length === 1) {
      return includedCategories[0].name;
    }

    // No categories filtered and no wedge is expended
    if (this.committedFilters.excludedCategories.size === 0) return 'All Categories';

    // Multiple categories filtered, return count
    return `Categories (${includedCategories.length})`;
  }

  getExplodedCategoryAmount() {
    if (!this.explode.categories.length) return this.totalSpent;

    return this.spentByCategories.find(categ => categ.id === this.explode.categories[0])?.amount;
  }

  getExplodedCategoryPercentage() {
    if (!this.explode.categories.length) return 100;

    return this.spentByCategories.find(categ => categ.id === this.explode.categories[0])?.percentage;
  }

  getInsideDonutFontSize(text: string, seed: number, max: number) {
    // Returns the font size so that the text fits within the doughnut's inner circle
    //   it considers the doughnut's container size and the length of the text
    const donutChartContWidth = this.donutChartCont?.nativeElement.getBoundingClientRect().width;
    if (!donutChartContWidth || !text) return `${max}px`;

    const base = seed + donutChartContWidth * 0.02;
    const length = text.length;

    return `${Math.min(max, base - length * 0.7)}px`;
  }

  filterCategories(categ: Category[], committedFilters: SpendingFilters) {
    return categ.filter(c => !committedFilters.excludedCategories.has(c.id));
  }

  onAccountsFilterReset() {
    this.store.dispatch(resetExcludedAccountsFilter());
  }
  onAccountsFilterChange(filter) {
    this.store.dispatch(setExcludedAccountsFilter({ payload: filter }));
  }
}
