import { GoalAccount } from './goal-account.model';
import { Goal } from './goal.model';
export class MilestoneSummary {
  /** Milestones for the user */
  milestones?: Goal[];

  /** Milestone accounts */
  accounts?: GoalAccount[];

  /** Wallet for the user */
  wallet?: Goal;
  /** Total balance for all milestones for the user */
  totalBalance?: number;

  hasError?: boolean;

  /** Is account closed */
  isClosed?: boolean;

  isLoaded?: boolean;

  constructor(summary?: Partial<MilestoneSummary>) {
    Object.assign(this, summary);
  }
}
