import { CategorizedAccounts } from '@app/accounts/models';

export class InternalAccountsState extends CategorizedAccounts {
  source: number;
  totalAvailableBalanceDeposit: number;
  totalOutstandingBalanceLoan: number;

  constructor(args?: Partial<InternalAccountsState>) {
    super();
    Object.assign(this, args);
  }
}
