import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { SubSink } from '@axos/subsink';
import { IScope } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { FeatureFlagType } from 'common/enums/enums';
import { AxosClearingService } from 'services/axos-clearing.service';
import { CachedTradingAccountsService } from 'services/cached-trading-accounts.service';
import { FeatureFlagService } from 'services/feature-flag.service';

import { AccountTab } from '@app/accounts/enums';
import { ResendConsentModalComponent } from '@app/axos-trading/modals';
import {
  AlertsIcons,
  BeneficiaryType,
  ClearingAccountType,
} from '@app/shared/enums';
import { BeneficiaryTrading, DialogData } from '@app/shared/models';
import { pascalCaseToWords } from '@app/utils';
import { OlbEvents } from '@core/enums';
import { DialogService, OlbEventService } from '@core/services';
import { BeneficiariesIraEnhHelper } from '@legacy/services/beneficiaries-iraenh.helper';

import { TradingAccount } from '../../accounts/typings/TradingAccount';
import { Inject } from '../../decorators/decorators';
import { AxosClearingUrlHelper } from '../../services/axos-clearing-urls.service';

@Inject(
  '$scope',
  '$stateParams',
  'axosClearingUrlHelper',
  'axosClearingService',
  'cachedTradingAccountsService',
  'serviceHelper',
  'featureFlagService',
  '$state',
  'dialogService',
  'matDialog',
  'olbEventService',
  'beneficiariesIraEnhHelper'
)
export class AccountDetailsTradingController {
  accountId: number;
  tradingAccounts: TradingAccount[] = [];
  tradingAccount: TradingAccount;
  hasAccountFeatures = false;
  hasTrusteeInformation = false;
  isTrustType = false;
  /** Indicates if it's saving the nickname */
  isSaving = false;
  /** Indicates if it's editing the nickname */
  isEditing = false;
  /** Flag to validate if the user has pressed the Save button */
  mouseDown = false;
  isUpdating = false;
  isSDTFeatureActive: boolean;

  accountNumber: string;
  nickname: string;
  accountType: string;
  maskTrustTaxPayerId: string;
  isIraEnhBaseFlagActive = false;
  activeIraEnhDira = false;
  activeIraEnhTrad = false;

  /** Beneficiaries flags */
  canShowBeneficiaries = false;
  pendingSpousalConsent = false;
  primaryBeneficiaries: BeneficiaryTrading[];
  contingentBeneficiaries: BeneficiaryTrading[];
  primaryToolTipMesage = `Beneficiaries who are first to receive the assets in your account.
  If no beneficiaries are named, the assets will go to your estate.`;
  contingentToolTipMessage = `Beneficiaries who receive the assets in your account only when
  there are no living primary beneficiaries.`;
  pendingConsentToolTipMessage = `Your change of beneficiaries is pending until your spouse
  provides their consent.`;
  isIraAccount: boolean;
  toastStatus = 'success';
  toastMessage: string;
  displayAdditionalOwners = false;
  isRiaShareAccountFlag = false;
  isRiaAuthorizeTransfersFlag = false;
  shouldDisplayShareAccount = true;
  shouldDisplayAuthorizeTransfer = true;
  private listeners: Function[] = [];

  private subsink = new SubSink();

  constructor(
    private scope: IScope,
    private params: IStateParamsService,
    private axosClearingUrlHelper: AxosClearingUrlHelper,
    private axosClearingService: AxosClearingService,
    private cachedTradingAccountsService: CachedTradingAccountsService,
    private serviceHelper: IServiceHelper,
    private featureFlagService: FeatureFlagService,
    private state: IStateService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private olbEventService: OlbEventService,
    private beneficiariesIraEnhHelper: BeneficiariesIraEnhHelper
  ) {
    this.accountId = +this.params['id'];
  }
  $onInit() {
    this.isIraEnhBaseFlagActive = this.featureFlagService.isIraEnhBaseFlagActive();
    this.activeIraEnhDira = this.featureFlagService.isIraEnhDiraFlagActive();
    this.activeIraEnhTrad = this.featureFlagService.isIraEnhTradFlagActive();
    this.isRiaShareAccountFlag = this.featureFlagService.isRiaShareAccountActive();
    this.isRiaAuthorizeTransfersFlag = this.featureFlagService.isRiaAuthorizeTransfersActive();
    this.isSDTFeatureActive = this.featureFlagService.isFeatureFlagActive(
      FeatureFlagType.SDTFeature
    );

    if (this.state.params.updateAccounts) {
      this.isUpdating = true;
      this.updateAccounts();
    } else {
      this.setTradingAccountData();
    }

    this.subsink.sink = this.olbEventService.on(
      OlbEvents.CancelSpousalConsent,
      () => {
        this.tradingAccount.pendingSpousalConsent = false;
        this.pendingSpousalConsent = false;
        this.cachedTradingAccountsService.updateTradingAccount(
          this.tradingAccount
        );
        this.scope.$emit('updatePendingConsent', this.pendingSpousalConsent);
      }
    );
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
    this.subsink.unsubscribe();
  }

  $onChanges(change: any) {
    if (
      change.tradingAccounts &&
      change.tradingAccounts.currentValue.length > 0 &&
      !this.state.params.updateAccounts
    ) {
      this.getTradingAccountData();
    }
  }

  setInitialData(): void {
    this.nickname = this.getNickname();
    this.accountType = this.getAccountType();
    this.maskTrustTaxPayerId = this.getMaskTrustTaxPayerId();
    this.pendingSpousalConsent = this.tradingAccount.pendingSpousalConsent;
    // TODO: Uncomment the following line once we pull the right information from Orbis/Enrollment PBI 653444.
    // this.hasAccountFeatures = this.tradingAccount.accountFeatures.length > 0;
    this.validateTrustAccount();

    if (this.tradingAccount.beneficiaries?.length > 0) {
      this.primaryBeneficiaries = this.tradingAccount.beneficiaries.filter(
        b => b.type === BeneficiaryType.Primary
      );
      this.contingentBeneficiaries = this.tradingAccount.beneficiaries.filter(
        b => b.type === BeneficiaryType.Contingent
      );
    } else {
      this.primaryBeneficiaries = [];
      this.contingentBeneficiaries = [];
    }

    this.isIraAccount =
      this.tradingAccount.type === ClearingAccountType.IraTraditional ||
      this.tradingAccount.type === ClearingAccountType.IraRoth;

    this.canShowBeneficiaries = this.isBeneficiariesActive();
    this.handleBeneficiariesIraEhn();
  }

  isBeneficiariesActive(): boolean {
    return (
      this.tradingAccount.type !== ClearingAccountType.Individual &&
      this.tradingAccount.type !== ClearingAccountType.TrustRevocable &&
      this.tradingAccount.type !== ClearingAccountType.TrustIrrevocable &&
      this.tradingAccount.type !== ClearingAccountType.JointCommunityProperty &&
      this.tradingAccount.type !==
        ClearingAccountType.JointCommunityPropertyWithRos &&
      this.tradingAccount.type !== ClearingAccountType.JointTenantsByEntirety &&
      this.tradingAccount.type !== ClearingAccountType.JointTenantsInCommon &&
      this.tradingAccount.type !== ClearingAccountType.JointTenantsWithRos &&
      this.featureFlagService.isBeneficiariesActive()
    );
  }

  validateTrustAccount() {
    this.isTrustType =
      this.tradingAccount.type === ClearingAccountType.TrustIrrevocable ||
      this.tradingAccount.type === ClearingAccountType.TrustRevocable;

    if (
      this.tradingAccount.trustName ||
      this.tradingAccount.trustFormationDate ||
      this.maskTrustTaxPayerId
    ) {
      this.hasTrusteeInformation = true;
    }
  }

  getMaskTrustTaxPayerId(): string {
    if (this.tradingAccount.trustTaxPayerIdNumber) {
      const lastDigits = this.tradingAccount.trustTaxPayerIdNumber.substr(
        this.tradingAccount.trustTaxPayerIdNumber.length - 4
      );

      return `*** - ** - ${lastDigits}`;
    }

    return '';
  }

  getAccountType(): string {
    if (this.tradingAccount.type === ClearingAccountType.IraTraditional) {
      return 'Traditional IRA';
    }

    if (this.tradingAccount.type === ClearingAccountType.IraRoth) {
      return 'Roth IRA';
    }

    if (
      this.tradingAccount.type === ClearingAccountType.Individual ||
      this.tradingAccount.type === ClearingAccountType.TrustRevocable ||
      this.tradingAccount.type === ClearingAccountType.TrustIrrevocable ||
      this.tradingAccount.type === ClearingAccountType.JointCommunityProperty ||
      this.tradingAccount.type ===
        ClearingAccountType.JointCommunityPropertyWithRos ||
      this.tradingAccount.type === ClearingAccountType.JointTenantsByEntirety ||
      this.tradingAccount.type === ClearingAccountType.JointTenantsInCommon ||
      this.tradingAccount.type === ClearingAccountType.JointTenantsWithRos
    ) {
      return 'Self-Directed Trading';
    }

    return pascalCaseToWords(this.tradingAccount.typeName);
  }

  getNickname(): string {
    if (
      this.tradingAccount.nickname === 'Self-Directed Trading' &&
      (this.tradingAccount.type === ClearingAccountType.IraTraditional ||
        this.tradingAccount.type === ClearingAccountType.IraRoth)
    ) {
      return 'Self-Directed IRA';
    }

    return this.tradingAccount.nickname;
  }

  /**
   * Take the customer to Orbis landing page
   */
  goTradeSecurities(responsive: boolean): void {
    this.axosClearingUrlHelper
      .getOrbisUrl(this.tradingAccount.accountNumber, responsive)
      .then(url => {
        const win = window.open(url, '_blank', 'noreferrer noopener');
        win.focus();
      });
  }

  /**  Enables the nickname's entry box for editing */
  editNickname(): void {
    this.isEditing = !this.isEditing;
    setTimeout(() => {
      $('#nickname').focus();
    }, 200);
  }

  /**
   * Updates the account nickname, performs a call to the service and emit an event
   * If hte update is successful we update cached accounts
   */
  saveAccountNickname(): void {
    this.isSaving = true;
    const oldNickname = this.tradingAccount.nickname;
    this.nickname = this.nickname || this.getNickname();
    this.tradingAccount.nickname = this.nickname;

    this.axosClearingService
      .updateNickname(this.tradingAccount)
      .then(acc => {
        if (acc.data?.nickname === this.nickname) {
          this.cachedTradingAccountsService.updateTradingAccount(
            this.tradingAccount
          );
          this.scope.$emit('nicknameUpdated', this.nickname);
        } else {
          const dialogData = new DialogData({
            icon: AlertsIcons.ExclamationCircle,
            title: 'Error updating account nickname',
            cancelText: null,
          });
          this.dialogService.open(dialogData);
          this.nickname = oldNickname;
          this.tradingAccount.nickname = oldNickname;
        }
        this.isEditing = false;
      })
      .catch(err => {
        this.nickname = oldNickname;
        this.tradingAccount.nickname = oldNickname;
        this.serviceHelper.errorHandler(err);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  /**
   * Triggered when the user clicks outside the nickname's entry box and has not clicked
   * the Save button, then the nickname editing is cancelled
   */
  cancelNicknameUpdate(): void {
    if (!this.mouseDown) {
      this.nickname = this.getNickname();
      this.isEditing = !this.isEditing;
    }
  }

  editBeneficiaries(event: Event, contingent: boolean): void {
    if (this.isIraAccount && this.pendingSpousalConsent) {
      this.matDialog
        .open(ResendConsentModalComponent, { data: this.tradingAccount })
        .afterClosed()
        .subscribe(answer => {
          if (answer === 'cancel') {
            this.tradingAccount.pendingSpousalConsent = false;
            this.pendingSpousalConsent = false;
            this.cachedTradingAccountsService.updateTradingAccount(
              this.tradingAccount
            );
          } else if (answer) {
            this.showConfirmationEmailModal(answer);
          }
        });
    } else {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.state.go('.beneficiaries', {
        contingent,
        tradingAccount: this.tradingAccount,
        subtitle: this.getNickname(),
      });
    }
  }

  isClosedAccount(): boolean {
    return this.tradingAccount?.statusName?.toLowerCase() !== 'active';
  }

  goToTab(tab: AccountTab): void {
    this.scope.$emit('changeTab', tab);
  }

  private showConfirmationEmailModal(spouseEmail: string) {
    const dialogData = new DialogData({
      icon: AlertsIcons.CheckCircle,
      title: 'Email Sent!',
      content: `<p class="mb-3">The consent email was sent to your spouse's address below.</p><p class="text-underline">${spouseEmail}</p>`,
      okText: 'Done',
      cancelText: null,
    });
    this.dialogService.open(dialogData);
  }

  private updateAccounts() {
    this.axosClearingService
      .getAccounts()
      .then((res: OlbResponse<TradingAccount[]>) => {
        this.cachedTradingAccountsService.loadTradingAccounts(res.data || []);
        this.getTradingAccountData();
        this.state.params.updateAccounts = false;
        this.isUpdating = false;
      });
  }

  private handleBeneficiariesIraEhn() {
    if (this.beneficiariesIraEnhHelper.toastMessage) {
      this.toastStatus = this.beneficiariesIraEnhHelper.toastType.toString();
      this.toastMessage = this.beneficiariesIraEnhHelper.toastMessage;

      this.beneficiariesIraEnhHelper.clearToast();
    }
  }

  private setTradingAccountData() {
    if (!this.cachedTradingAccountsService.isTradingAccountsLoaded) {
      this.subsink.sink = this.olbEventService.on(
        OlbEvents.InvestAccountsLoaded,
        () => {
          this.getTradingAccountData();
        }
      );
    } else {
      this.getTradingAccountData();
    }
  }

  private getTradingAccountData() {
    this.tradingAccount = this.cachedTradingAccountsService.tradingAccounts.find(
      account => account.id === this.accountId
    );
    this.displayAdditionalOwnerNamesValidation();
    this.setInitialData();
  }

  private displayAdditionalOwnerNamesValidation() {
    const allowedClearingAccountTypes: ClearingAccountType[] = [
      ClearingAccountType.JointCommunityProperty,
      ClearingAccountType.JointCommunityPropertyWithRos,
      ClearingAccountType.JointTenantsInCommon,
      ClearingAccountType.JointTenantsByEntirety,
      ClearingAccountType.JointTenantsWithRos,
    ];

    this.displayAdditionalOwners = allowedClearingAccountTypes.includes(
      this.tradingAccount?.type
    );
  }
}
