import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PORTFOLIO_COLORS } from '@app/axos-invest/constants';
import { AssetCategory, PortfolioType } from '@app/axos-invest/enums';
import { Asset, PortfolioDetails, PortfolioInfo } from '@app/axos-invest/models';
import { AssetDisplay } from '@app/axos-invest/models/asset-display.model';
import { getRiskScoreDescription } from '@app/axos-invest/utils';

@Component({
  selector: 'app-review-target-allocation',
  templateUrl: './review-target-allocation.component.html',
  styleUrls: ['./review-target-allocation.component.scss'],
})
export class ReviewTargetAllocationComponent implements OnInit {
  @Input() details: PortfolioDetails;
  @Input() score: PortfolioInfo;
  @Input() isLoading: boolean;
  @Input() portfolioType: PortfolioType;
  @Output() save = new EventEmitter();

  chartData: any;
  assets: AssetDisplay[];
  riskScoreDescription: string;

  buttonText: string;

  ngOnInit() {
    this.buildAssets();
    this.initChartData();
    this.riskScoreDescription = getRiskScoreDescription(Number(this.score.allocationScore));
    this.buttonText = this.getTextButton();
  }

  private buildAssets() {
    const stocks = this.score.assetClasses
      .filter(asset => asset.category === AssetCategory.Stocks)
      .map((asset, index) => this.mapAsset(asset, index, this.score.targetStockRate, this.details.stockAllocation));
    const bonds = this.score.assetClasses
      .filter(asset => asset.category === AssetCategory.Bonds)
      .map((asset, index) => this.mapAsset(asset, index, this.score.targetBondRate, this.details.bondAllocation));
    const cash = this.createCashAsset();
    this.assets = [...stocks, ...bonds, cash];
  }

  private mapAsset(asset: Asset, index: number, targetRate: number, selectedPercentage: number) {
    const category = asset.category as AssetCategory;
    const colors = PORTFOLIO_COLORS[category];
    let target = Number(asset.targetAllocationRate);
    if (target > 0) {
      const percentage = target / targetRate;
      target = selectedPercentage * percentage;
    }

    return new AssetDisplay({
      category: asset.category as AssetCategory,
      name: asset.name,
      code: asset.code,
      target: target.toFixed(),
      background: colors[index],
    });
  }

  private createCashAsset() {
    return new AssetDisplay({
      category: AssetCategory.Cash,
      code: 'USD',
      name: 'USD',
      target: this.details.cashAllocation.toFixed(),
      background: PORTFOLIO_COLORS[AssetCategory.Cash][0],
    });
  }

  private initChartData() {
    const labels = this.assets.map(asset => asset.name);
    const percentages = this.assets.map(asset => Number(asset.target).toFixed(2));
    const backgrounds = this.assets.map(asset => asset.background);
    this.chartData = {
      labels,
      datasets: [
        {
          data: percentages,
          backgroundColor: backgrounds,
          fill: true,
        },
      ],
    };
  }

  private getTextButton() {
    const portfolioTypes = {
      [PortfolioType.Basic]: 'Save Portfolio',
      [PortfolioType.GlidepathEnabled]: 'Confirm Portfolio Assets',
    };

    return portfolioTypes[this.portfolioType];
  }
}
