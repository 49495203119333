import FiltersHelperService from 'accounts/transactions/filters-helper.service';
import { TxFilter } from 'accounts/typings/TxFilter';
import * as angular from 'angular';
import { IOnChangesObject, IQService, ITimeoutService } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  isValid,
  minTime,
  parseISO,
} from 'date-fns';
import * as moment from 'moment';
import { ServiceHelper } from 'services/service.helper';
import { AggregatedAccount } from 'typings/app/account-aggregation/AggregatedAccount';
import { AggregatedApiError } from 'typings/app/account-aggregation/AggregatedApiError';
import { AggregatedError } from 'typings/app/account-aggregation/AggregatedError';

import { pfmChartItemsConst } from '@app/pfm/constants';
import { ChartSelectionItem } from '@app/pfm/models';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

import { Inject } from '../../../decorators/Inject';
import { AccountAggregationService } from '../../../services/account-aggregation.service';
import { TransactionRequest } from '../../../typings/app/account-aggregation/TransactionRequest';
import { LastRefreshTransactionsAttempt } from './typings/LastRefreshTransactionsAttempt';
import { FastlinkActions } from '@legacy/dashboard/account-aggregation/enums/fast-link-actions.enum';

@Inject(
  'accountAggregationService',
  'serviceHelper',
  'filtersHelperService',
  '$rootScope',
  'featureFlagService',
  '$state',
  '$stateParams',
  '$q',
  '$timeout',
  '$scope',
  'cachedAccountsService'
)
export class TransactionsAggController {
  isLoading = false;
  /** Parameter to pass to  udb-tx-table*/
  aggregatedAccount: AggregatedAccount;
  isDownloading = false;
  reverse: boolean;
  orderBy: number;
  filters: TxFilter;
  showTransactionTags: boolean;
  // property used to preserve initial filters property/values, then used to trigger filters changes
  boundFilters: TxFilter;
  tagFilters: GenericOption[];
  showModal = false;
  filteredTx: Transaction[];
  filteredTxWithAllCategories: Transaction[];
  isInsightsOpen: boolean;
  filtersApplied: string[] = [];
  onTransactionOperation: Function;
  displayFilters = false;
  isPfm2Enabled: boolean;
  insightsChartTypes: ChartSelectionItem[];

  isRefreshingTransactions: boolean;
  errorRefreshingTransactions: AggregatedError;
  lastRefreshTransactionsAttempt: Date;
  tooManyRefreshesError: Date;
  listeners: Function[] = [];

  constructor(
    private readonly accountAggregationService: AccountAggregationService,
    private readonly serviceHelper: ServiceHelper,
    private readonly _filtersHelperService: FiltersHelperService,
    private readonly root: ng.IRootScopeService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly state: IStateService,
    private readonly stateParams: IStateParamsService,
    private readonly qService: IQService,
    private readonly timeoutService: ITimeoutService,
    private readonly scope: ng.IScope,
    private readonly cacheAccountAggService: CachedAccountsService
  ) {}

  $onInit(): void {
    // Initialized filters
    this.filters = {
      categories: [],
      query: '',
      transactionType: { value: 0, subvalue: '', label: 'All transactions' },
      days: { value: 90, label: 'Past 90 days' },
      dateRange: { start: null, end: null },
      amountRange: { min: null, max: null },
      checkRange: { min: null, max: null },
    };
    this.boundFilters = { ...this.filters };
    this.tagFilters = [];
    this.isLoading = true;
    this.showTransactionTags = true;
    this.root.$on('onClearModalFilters', (_event: any, filterName: string) => {
      console.log('onClearModalFilters received!');
      this.clearFilter(filterName);
    });
    this.isPfm2Enabled = this.featureFlagService.isPFM2FlagActive();
    if (this.isPfm2Enabled) {
      this.setupPfm();
    }

    this.errorRefreshingTransactions = this.stateParams['authError'];

    this.listeners.push(
      this.scope.$on('recategorizeEvent', () => {
        // shallow clone transaction arrays, the new references will trigger onChanges
        //   in components that depend on them
        this.filteredTx = [...this.filteredTx];
        this.filteredTxWithAllCategories = [...this.filteredTxWithAllCategories];
      })
    );
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }
  $onChanges(changes: IOnChangesObject): void {
    const { aggregatedAccount } = changes;

    if (!aggregatedAccount.previousValue && aggregatedAccount.currentValue) {
      // aggregatedAccount was just initialized ...

      // came back from restore connection page, trigger refresh
      if (this.stateParams['refreshTransactions'] === true) this.refreshTransactions();
    }
  }

  //#region Refresh transactions
  private parseLastUpdated(): Date {
    if (!this.aggregatedAccount?.lastUpdated) return null;

    const lastUpdateInLocalTimeZone = parseISO(
      // lastUpdated is in UTC, however, the date string returned by the API
      //   doesn't end with Z to indicate it (as of now), so add it
      this.aggregatedAccount.lastUpdated + (this.aggregatedAccount.lastUpdated.endsWith('Z') ? '' : 'Z')
    );

    if (!isValid(lastUpdateInLocalTimeZone)) return null;

    return lastUpdateInLocalTimeZone;
  }

  public getFriendlyLastUpdated(): string {
    const lastSuccessfulUpdate = this.parseLastUpdated();
    if (!lastSuccessfulUpdate) return '';

    const now = new Date();
    const months = differenceInMonths(now, lastSuccessfulUpdate);
    const days = differenceInDays(now, lastSuccessfulUpdate);
    const hours = differenceInHours(now, lastSuccessfulUpdate);
    const minutes = differenceInMinutes(now, lastSuccessfulUpdate);

    if (months >= 12) return 'Over 1 year ago';
    else if (months >= 2) return `About ${months} months ago`;
    else if (months >= 1) return `About 1 month ago`;
    else if (days >= 2) return `About ${days} days ago`;
    else if (days >= 1) return `About 1 day ago`;
    else if (hours >= 2) return `${hours} hours ago`;
    else if (hours >= 1) return `1 hour ago`;
    else if (minutes >= 2) return `${minutes} minutes ago`;
    else return `Just now`;
  }

  /**
   * Triggers a refresh from Yodlee to the Financial Institution
   */
  public refreshTransactions(): void {
    /**
     * IMPORTANT CONSIDERATIONS:
     * The refresh consists of 2 steps:
     *  1) Yodlee goes to the financial institution and retrieves the latest information
     *      (executed by BeginPollableRefresh endpoint)
     *  2) OLB goes to Yodlee and retrieves the latest information
     *      (executed by PollRefresh endpoint once Yodlee finishes, or by sync-accounts)
     *
     * - If sync-accounts is running this will throw an error in the database since multiple processes will
     *      modifying the same tables (and there is no table locking)
     *
     * - If step 2) takes more than ~90 segs it will timeout
     *
     * - LocalStorage is used to know when was the last sync attempt, there can only be 1 attempt each 15 mins
     *      however, the user can still receive the error if he uses a different device
     */

    this.isRefreshingTransactions = true;
    this.errorRefreshingTransactions = null;

    this.accountAggregationService
      .beginPollableRefresh(this.aggregatedAccount?.id)
      .then(resp => {
        // handle ProviderAccount errors
        if (resp.data.error) throw { error: resp.data.error } as AggregatedApiError;

        const requestId = resp.data.requestId;

        // save last refresh attempt
        const now = new Date();
        this.lastRefreshTransactionsAttempt = now;
        const lastRefreshTransactionsAttempt: LastRefreshTransactionsAttempt = {
          requestId,
          utcTimeStamp: now.toISOString(),
        };
        localStorage.setItem(
          this.getLocalStorageKeyLastRefreshAttempt(this.aggregatedAccount.id),
          JSON.stringify(lastRefreshTransactionsAttempt)
        );

        return this.pollRefreshTransactions(requestId);
      })
      .then(_ => {
        // changing the reference of 'aggregatedAccount' will trigger a transaction update
        //   since transactionDetailsAgg component watches it in the scope
        this.aggregatedAccount = { ...this.aggregatedAccount };

        return this.accountAggregationService.getAccounts();
      })
      .then(acc => {
        // update 'lastUpdated' and cache
        if (!acc.data || acc.data.length > 0) {
          const updatedAccount = acc.data.find(accnt => accnt.id === this.aggregatedAccount.id);
          this.aggregatedAccount.lastUpdated = updatedAccount.lastUpdated;
          this.cacheAccountAggService.updateAggregatedAccounts(acc.data);
        }
      })
      .catch((ex: any) => {
        // Got here either by an api error or an ProviderAccount error, show banner
        // - Olb's unhandled errors will come as null, show generic error msg
        // - Yodlee API errors, and ProviderAccount errors will come with 'error' prop that contains the displayMessage which can be shown to the user
        const errorResult = this.accountAggregationService.getErrorMessage(ex, this.aggregatedAccount.bankName);
        this.errorRefreshingTransactions = ex?.error || errorResult;

        if (ex?.errorCode === 'Y827') {
          // You have reached the daily refresh limit. Please try again tomorrow.
          const now = new Date();
          this.tooManyRefreshesError = now;
          localStorage.setItem(
            this.getLocalStorageKeyTooManyRefreshesError(this.aggregatedAccount.id),
            now.toISOString()
          );
        }
      })
      .finally(() => {
        this.isRefreshingTransactions = false;
      });
  }

  /**
   * Given a requestId, this will poll until the refresh is finished,
   * returns a promise which is resolved/rejected accordingly
   */
  private pollRefreshTransactions(requestId: string): any {
    const pollIntervalMs = 3000;
    const deferred = this.qService.defer();

    const poll = () => {
      this.accountAggregationService
        .pollRefresh(this.aggregatedAccount.id, requestId)
        .then(resp => {
          // handle ProviderAccount errors
          if (resp.data.error) throw { error: resp.data.error } as AggregatedApiError;

          if (resp.data.isComplete) deferred.resolve();
          else this.timeoutService(poll, pollIntervalMs);
        })
        .catch(e => deferred.reject(e));
    };

    poll();

    return deferred.promise;
  }

  /**
   * Re-directs to the restore connection page
   */
  public actionClick(): void {
    switch (this.errorRefreshingTransactions.actionMessage) {
      case FastlinkActions.RESTORE_CONNECTION:
        this.fixConnection();
        break;
      case FastlinkActions.ADD_ANOTHER_ACCOUNT:
        this.addAccount();
        break;
      default:
        this.fixConnection();
        break;
    }
  }
  private addAccount(): void {
    this.state.go('udb.accounts.account-aggregation', {
      isAccountAggregationFlow: true,
      isPfm3Active: true,
    });
  }
  private fixConnection(): void {
    this.state.go('udb.dashboard.account-aggregation.auth', {
      bankId: this.aggregatedAccount.providerId,
      updateCredentials: true,
      providerAccountId: this.aggregatedAccount.providerAccountId,

      onCompleteRedirectAction: (error: AggregatedError) => {
        this.state.go('udb.accounts.external-details', {
          id: this.aggregatedAccount.id,
          container: this.aggregatedAccount.container,
          tab: 0,
          refreshTransactions: !error,
          authError: error,
        });
      },

      onBackRedirectAction: () => {
        this.state.go('udb.accounts.external-details', {
          id: this.aggregatedAccount.id,
          container: this.aggregatedAccount.container,
          tab: 0,
        });
      },
    });
  }

  private getLocalStorageKeyLastRefreshAttempt(accountId: number): string {
    return `accountAggregation.transactions.lastRefreshAttempt.${accountId}`;
  }

  private getLocalStorageKeyTooManyRefreshesError(accountId: number): string {
    return `accountAggregation.transactions.tooManyRefreshesError.${accountId}`;
  }

  public canRefreshTransactions(): boolean {
    // Checks:
    // - Yodlee allows a refresh once every ~15 mins
    // - If user received tooManyRefreshes error then wait 24 hours

    if (!this.lastRefreshTransactionsAttempt) {
      // Cache isn't initialized, look it up in the localStorage
      //   if it isn't found there either then set a dummy date way in the past
      //   to prevent cache misses in every angular digest
      const ls = localStorage.getItem(this.getLocalStorageKeyLastRefreshAttempt(this.aggregatedAccount.id));
      this.lastRefreshTransactionsAttempt = ls
        ? parseISO((JSON.parse(ls) as LastRefreshTransactionsAttempt).utcTimeStamp)
        : new Date(minTime);
    }

    if (!this.tooManyRefreshesError) {
      const ls = localStorage.getItem(this.getLocalStorageKeyTooManyRefreshesError(this.aggregatedAccount.id));
      this.tooManyRefreshesError = ls ? parseISO(ls) : new Date(minTime);
    }

    // Yodlee allows to refresh account once every 15min
    const cooldownMinutes = 15.3;
    const now = new Date();
    const lastSuccessfulUpdate = this.parseLastUpdated() || new Date(minTime);

    return (
      differenceInMinutes(now, lastSuccessfulUpdate) >= cooldownMinutes &&
      differenceInMinutes(now, this.lastRefreshTransactionsAttempt) >= cooldownMinutes &&
      differenceInHours(now, this.tooManyRefreshesError) >= 25
    );
  }
  //#endregion Refresh transactions

  public setFilterValue(value: any, propertyName: string): void {
    // recreate filters to trigger onChanges event on transactions-tag controller
    this.filters = this._filtersHelperService.setFilterValue(this.filters, value, propertyName);
    this.boundFilters = angular.copy(this.filters);
  }

  /** Sets the filters when they are modified in the filters component */
  public changeFilters(filters: TxFilter, filtersApplied: string[]): void {
    this.filtersApplied = this.convertToArray(filtersApplied);
    this.filters = { ...filters };
  }

  /**
   * This methods responds to 'close button on Tag element'
   * removes the filter then triggers the filter update fn
   * @param filterName Filter to be removed from the tags
   */
  public clearFilter(filterName: string): void {
    this.filters = { ...this._filtersHelperService.resetFilter(filterName, this.filters) };
    this.boundFilters = {
      ...this._filtersHelperService.resetFilter(filterName, this.boundFilters),
    };
    this.removeFilterApplied(filterName);
    this.changeFilters(this.filters, this.filtersApplied);
  }

  convertToArray(filtersObj: any) {
    if (angular.isArray(filtersObj)) return filtersObj;
    if (!filtersObj || angular.equals(filtersObj, {})) return [];
    const array: any[] = [];
    for (const item in filtersObj) {
      if (!isNaN(parseInt(item))) array.push(filtersObj[item]);
    }

    return array;
  }

  displayTransactionTags() {
    this.showTransactionTags = !this.displayFilters;
    this.displayFilters = !this.displayFilters;
  }
  /**
   * Sets the sorted var used in the download action
   * @param orderBy column selected in the order by
   * @param reverse If the sorting is reverse
   */
  setSortValues(orderBy: number, reverse: boolean) {
    this.orderBy = orderBy;
    this.reverse = reverse;
  }

  /** Handles the download button click */
  downloadTransactionsFile(): void {
    this.isDownloading = true;
    const request = new TransactionRequest();

    const {
      categories,
      query,
      transactionType,
      amountRange,
      amount,
      checkRange,
      dateRange,
      check,
      days,
    } = this.filters;

    request.accountId = this.aggregatedAccount.id;
    request.container = this.aggregatedAccount.container;

    if (days.value != null && days.value > 0) {
      const fromDate = moment().subtract(days.value, 'days').toDate();
      request.fromDate = fromDate;
      const toDate = moment().toDate();
      request.toDate = toDate;
    }

    if (dateRange.start != null && dateRange.end != null) {
      const startDate = moment(dateRange.start).toDate();
      const endDate = moment(dateRange.end).toDate();
      request.fromDate = startDate;
      request.toDate = endDate;
    }

    request.columnOrder = this.orderBy ? this.orderBy : 0;
    request.reverse = this.reverse;
    request.keyword = query;
    request.amountRangeMin = amountRange.min;
    request.amountRangeMax = amountRange.max;
    request.checkRangeMin = checkRange.min;
    request.checkRangeMax = checkRange.max;
    request.amountValue = amount;
    request.checkValue = check ? check.toString() : '';
    request.olbCategoryName = transactionType.subvalue;
    request.olbCategoryId = categories;

    const accountNumber = this.aggregatedAccount.accountMask;
    const accountType = this.aggregatedAccount.accountType;
    this.accountAggregationService
      .downloadTransactionsFile(request)
      .then((res: any) => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `${accountType}_${accountNumber.substr(accountNumber.length - 4)}_txns.xlsx`);
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isDownloading = false;
      });
  }

  onUpdateTransactions(transactions: Transaction[], transactionsWithAllCategories: Transaction[]) {
    this.isLoading = false;
    this.filteredTx = transactions;
    this.filteredTxWithAllCategories = transactionsWithAllCategories;
  }
  toggleCategoryFilter(event) {
    this.scope.$broadcast('toggleCategoryFilter', event.categoryId);
  }
  onTableRowOperation(message: string, status: boolean) {
    this.onTransactionOperation({ message, status });
  }
  /**
   * Re,pve the filter from the applied filters and notify other components subscribed to this
   * @param filterName The filter to be removed
   */
  private removeFilterApplied(filterName: string) {
    this.filtersApplied = this.convertToArray(this.filtersApplied);
    const index = this.filtersApplied.indexOf(filterName);
    if (index >= 0) {
      this.filtersApplied.splice(index, 1);
    }
    this.root.$emit('filtersAppliedChange', filterName);
  }

  private setupPfm() {
    this.insightsChartTypes = pfmChartItemsConst;
  }
}
