import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { getAccountsLoading } from '@app/pfm/store/accounts/accounts.selectors';
import {
  getSpentByCategories,
  getTotalSpent,
  getTransactionsLoading,
} from '@app/pfm/store/spending/spending.selectors';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-spending-module',
  templateUrl: './spending-module.component.html',
  styleUrls: ['./spending-module.component.scss'],
})
export class SpendingModuleComponent implements OnInit, OnDestroy {
  categories = this.store.select(getSpentByCategories);
  totalSpent = this.store.select(getTotalSpent);
  transactionsLoading = this.store.select(getTransactionsLoading);
  accountsLoading = this.store.select(getAccountsLoading);
  currentMonthYear: Date;
  isEmpty = false;

  subsink = new SubSink();
  icons = { ArrowRight: NavigationIcons.ChevronRight };
  @ViewChild('donutChart') donutChart: ElementRef;

  constructor(public accAggFormatter: AccountAggregationChartFormatterService, private store: Store) {}

  ngOnInit(): void {
    this.currentMonthYear = this.accAggFormatter.GetFilterDate(0, 0);

    this.subsink.sink = this.totalSpent.subscribe(totalSpent => {
      this.isEmpty = totalSpent === 0;
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
