import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { openClose, slideFromRight, startStaticSlideFromLeft } from '@app/user-profile/animations';
import { UpdateEmailsRequest, UpdatePhonesRequest } from '@app/user-profile/models';
import { updateProfileDetails } from '@app/user-profile/store/actions';
import { getIsNewUser, getProfileDetails, getUserInfoIsOutdated } from '@app/user-profile/store/selectors';
import { areAddressesEqual, sanitizePhone } from '@app/user-profile/utils';
import { OutdatedContactInfoService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { AlertsIcons } from '@shared/enums';
import { Address, AddressAccountAssociation, CustomerDetail } from '@shared/models';

interface ContactInfoToastMessages {
  email: string;
  phone: string;
  address: string;
}

@Component({
  selector: 'app-contact-info-view',
  templateUrl: './contact-info-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './contact-info-view.component.scss'],
  animations: [startStaticSlideFromLeft, slideFromRight, openClose],
})
export class ContactInfoViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  expanded = false;

  currentView = ContactInfoView.ContactInfo;
  profileLoading = true;
  userCanEdit = false;
  canEditAddress = false;
  hasOutdatedContactInfo = false;
  profileInfo: Partial<CustomerDetail>;
  primaryAddressIsIncomplete = false;
  mailingAddressIsIncomplete = false;
  mailingAddress: Address = null;
  errorIcon = AlertsIcons.ExclamationCircle;
  views = ContactInfoView;
  alertMessages: ContactInfoToastMessages = {
    email: null,
    phone: null,
    address: null,
  };
  isMobileDevice = false;
  animationState: 'initial' | 'in' = 'initial';
  phones = {
    cellPhone1: '',
    cellPhone2: '',
    homePhone: '',
    workPhone: '',
  };
  contactInfoIsValid: boolean;

  private subsink = new SubSink();

  constructor(
    private store: Store,
    private outdatedContactInfoService: OutdatedContactInfoService,
    private featureFlagService: FeatureFlagService,
    private breakpointObserver: BreakpointObserver,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit(): void {
    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.profileInfo = data;
        this.recomputeViewValues(data);

        this.profileLoading = false;
      });

    if (this.featureFlagService.isOutdatedContactInfoFlagActive()) {
      this.subsink.sink = this.store
        .select(getUserInfoIsOutdated)
        .pipe(filter(isOutdated => isOutdated !== null))
        .subscribe(isOutdated => {
          this.hasOutdatedContactInfo = isOutdated;
        });
    }

    this.subsink.sink = this.store.select(getIsNewUser).subscribe({
      next: isNewUser => {
        const readOnly = this.featureFlagService.isSiteInReadOnly();
        const canEditAddress = this.featureFlagService.isAddressModificationEnabled();

        this.userCanEdit = !isNewUser && !readOnly;
        this.canEditAddress = this.userCanEdit && canEditAddress;
      },
    });

    this.contactInfoIsValid = this.isContactInfoValid();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  confirmInfoIsUpdated(): void {
    this.outdatedContactInfoService.confirmContactInfoIsUpdated().subscribe();
  }

  updateEmails(data: UpdateEmailsRequest): void {
    const payload: Partial<CustomerDetail> = {
      ...this.profileInfo,
      primaryEmail: data.primaryEmail,
      alternateEmail: data.alternateEmail,
    };
    this.alertMessages.email = 'You have successfully saved your email.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
    this.goBack();
  }

  updatePhones(data: UpdatePhonesRequest): void {
    const payload: Partial<CustomerDetail> = {
      ...this.profileInfo,
      workPhone: data.workPhone,
      homePhone: data.homePhone,
      cellPhone1: data.cellPhone1,
      cellPhone2: data.cellPhone2,
    };
    this.alertMessages.phone = 'You have successfully saved your phone.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
    this.goBack();
  }

  updateAddresses(data: AddressAccountAssociation[]): void {
    const payload: Partial<CustomerDetail> = {
      ...this.profileInfo,
      primaryAddress: data[0],
      mailingAddress: data[1],
    };
    this.alertMessages.address = 'You have successfully saved your address.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
    this.goBack();
  }

  goTo(view: ContactInfoView): void {
    this.currentView = view;
    this.alertMessages = { address: null, phone: null, email: null };
    this.animationState = 'initial';
  }

  goBack(): void {
    this.currentView = ContactInfoView.ContactInfo;
    this.animationState = 'in';
  }

  private recomputeViewValues(data: Partial<CustomerDetail>): void {
    this.primaryAddressIsIncomplete =
      !data.primaryAddress.address.city ||
      !data.primaryAddress.address.stateCode ||
      !data.primaryAddress.address.postalCode ||
      !data.primaryAddress.address.streetAddress1;

    this.mailingAddressIsIncomplete =
      !data.mailingAddress.address.city ||
      !data.mailingAddress.address.stateCode ||
      !data.mailingAddress.address.postalCode ||
      !data.mailingAddress.address.streetAddress1;

    if (!areAddressesEqual(data.primaryAddress.address, data.mailingAddress.address)) {
      this.mailingAddress = data.mailingAddress.address;
    } else {
      this.mailingAddress = null;
    }

    this.phones = {
      cellPhone1: sanitizePhone(data.cellPhone1),
      cellPhone2: sanitizePhone(data.cellPhone2),
      homePhone: sanitizePhone(data.homePhone),
      workPhone: sanitizePhone(data.workPhone),
    };
  }

  private isContactInfoValid(): boolean {
    const validEmail = !!this.profileInfo && !!this.profileInfo.primaryEmail;
    const validPhone = !!this.phones && !!this.phones.cellPhone1;
    const validHomeAddress = !this.primaryAddressIsIncomplete && !this.mailingAddressIsIncomplete;

    const isValid = validHomeAddress && validPhone && validEmail;

    return isValid;
  }
}

export enum ContactInfoView {
  ContactInfo = 'Contact Info',
  Email = 'Email',
  Phone = 'Phone Number',
  Address = 'Address',
}
