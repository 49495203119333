export enum Frequency {
  OneTime = 0,
  Weekly = 1,
  EveryTwoWeeks = 2,
  EveryFourWeeks = 4,
  Monthly = 5,
  SemiMonthly = 3,
  EveryTwoMonths = 6,
  Quarterly = 7,
  EveryFourMonths = 8,
  BiAnnual = 9,
  Annually = 10,
}

export function mapFrequency(frequency: number): string {
  switch (frequency) {
    case Frequency.OneTime:
      return 'One Time';
    case Frequency.Weekly:
      return 'Weekly';
    case Frequency.EveryTwoWeeks:
      return 'Every 2 Weeks';
    case Frequency.EveryFourWeeks:
      return 'Every 4 Weeks';
    case Frequency.Monthly:
      return 'Monthly';
    case Frequency.SemiMonthly:
      return 'Twice a month (Semi Monthly - 1st and the 15th)';
    case Frequency.EveryTwoMonths:
      return 'Every 2 Months';
    case Frequency.Quarterly:
      return 'Quarterly';
    case Frequency.EveryFourMonths:
      return 'Every 4 Months';
    case Frequency.BiAnnual:
      return 'Every 6 Months';
    case Frequency.Annually:
      return 'Yearly';
    default:
      return '';
  }
}
