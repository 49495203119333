import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { AccountTab } from '@app/accounts/enums';
import { getTradingAccount } from '@app/accounts/store/selectors';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import { STATE_PARAMS } from '@core/tokens';

@Component({
  selector: 'app-right-navigation',
  templateUrl: './right-navigation.component.html',
  styleUrls: ['./right-navigation.component.scss'],
})
export class RightNavigationComponent implements OnInit, OnDestroy {
  @Input() isHideResearchTab: boolean;
  @Output() changeTab = new EventEmitter<AccountTab>();
  axosOrbisUrl: string;
  isLoading = true;
  accountId: number;
  accountNumber: string;
  private subSink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private routeHelper: TradingRoutingHelperService,
    private store: Store
    ) { }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.getTradingAccount();

    this.getOrbisUrlWithAccount(this.accountNumber);
  }
  goToMoveMoney() {
    this.routeHelper.goToTransferFunds();
  }
  goToResearch() {
    this.changeTab.emit(AccountTab.Search);
  }
  private getOrbisUrlWithAccount(accountNumber: string) {
    this.subSink.sink = this.routeHelper.getOrbisUrlWithAccount(accountNumber).subscribe(url => {
      this.axosOrbisUrl = url;
      this.isLoading = false;
    });
  }

  private getTradingAccount() {
    this.subSink.sink = this.store
      .select(getTradingAccount(+this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.accountNumber = account.accountNumber;
      });
  }

}
