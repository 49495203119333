import { downgradeComponent } from '@angular/upgrade/static';
import { MinorMainComponent } from '@app/minors/components';
import * as angular from 'angular';

export const minorsLegacyModule = angular
  .module('udb.minors', ['ui.router'])
  .directive('appMinorMain', downgradeComponent({ component: MinorMainComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider.state('udb.minors', {
        url: '/AddMinors/{accountId}',
        views: {
          content: {
            template: '<app-minor-main></app-minor-main>',
          },
        },
      });
    },
  ]).name;
