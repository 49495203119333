import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import {
  compareAsc,
  differenceInDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  isEqual,
  min,
  parseISO,
  startOfMonth,
  sub,
  subDays,
  subMonths,
} from 'date-fns';

import { AccountOrigin } from '@app/pfm/enums/account-origin.enum';
import { NetWorthInterval } from '@app/pfm/enums/networth-interval.enum';
import { TimePeriod } from '@app/pfm/enums/time-period.enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { HistoricalBalance } from '@app/pfm/models/historical-balance.model';
import { NetWorthPoint } from '@app/pfm/models/net-worth-point.model';
import { PfmNetWorthProfile } from '@app/pfm/models/pfm-net-worth-profile.model';
import { PfmService } from '@app/pfm/services/pfm.service';
import { setNetWorthSeries, setNetWorthSeriesLoading } from '@app/pfm/store/net-worth/net-worth.actions';
import { getCommittedFilters } from '@app/pfm/store/net-worth/net-worth.selectors';
import { ServiceHelper } from '@legacy/services/service.helper';
import { CategoryName } from '@legacy/tiles/account-overview/typings/CategoryName.enum';

import { getAccounts } from '../accounts/accounts.selectors';
import { NetWorthFilters } from './net-worth-filters';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Injectable()
export class NetWorthEffects {
  constructor(
    private store: Store,
    private readonly pfmService: PfmService,
    private serviceHelper: ServiceHelper,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  startListening(subsink: SubSink): void {
    // todo once ngrx/effects is available, remove this method
    // todo implement accounts filter to get specific net worth

    // this.createGaps();
    const getDistinctCommittedFilters = this.store
      .select(getCommittedFilters)
      .pipe(
        distinctUntilChanged(
          (previousFilters, filters) =>
            previousFilters.timePeriod === filters.timePeriod &&
            this.arrayEquals(previousFilters.excludedAccounts, filters.excludedAccounts)
        )
      );

    subsink.sink = combineLatest([
      getDistinctCommittedFilters,
      this.store.select(getAccounts),
      this.pfmService.geNetWorthProfile(),
    ])
      .pipe(
        filter(
          ([committedFilters, accounts, profile]) => committedFilters != null && accounts != null && profile !== null
        )
      )
      .subscribe(([committedFilters, accounts, profile]) =>
        this.loadNetWorth(committedFilters, accounts, profile?.data)
      );
  }

  loadNetWorth(committedFilters: NetWorthFilters, accounts: AccountOverview[], profile: PfmNetWorthProfile) {
    // todo once ngrx/effects is available

    // date that user started to use olb
    const userRegistrationDate = startOfMonth(parseISO(profile.aggregatedSince));

    const now = new Date();
    let fromDate: Date;
    let fromDateFilter: Date;
    let toDate: Date;
    let customStep = 1;
    let interval = NetWorthInterval.Daily;
    let includeToday = false;

    if (committedFilters.timePeriod === TimePeriod.LastMonth) {
      fromDateFilter = subMonths(now, 1);
      toDate = now;
    } else if (committedFilters.timePeriod === TimePeriod.Last3Months) {
      fromDateFilter = subDays(now, 90);
      interval = NetWorthInterval.BiWeekly;
      toDate = now;
    } else if (committedFilters.timePeriod === TimePeriod.Last6Months) {
      fromDateFilter = startOfMonth(subMonths(now, 6));
      interval = NetWorthInterval.Monthly;
      toDate = now;
      // Monthly interval will always return the start of the month, so we need to add today data point
      includeToday = true;
    } else if (committedFilters.timePeriod === TimePeriod.Last12Months) {
      fromDateFilter = startOfMonth(subMonths(now, 12));
      interval = NetWorthInterval.Monthly;
      toDate = now;
      includeToday = true;
    } else if (committedFilters.timePeriod === TimePeriod.AllTime) {
      toDate = now;
      interval = NetWorthInterval.Custom;
      const numIntervals = 13;
      const diffDays = differenceInDays(now, userRegistrationDate);
      if (diffDays < numIntervals) {
        interval = NetWorthInterval.Daily;
        fromDateFilter = sub(userRegistrationDate, { days: numIntervals - diffDays });
      } else {
        interval = NetWorthInterval.Custom;
        customStep = Math.round(diffDays / numIntervals);
        fromDateFilter = userRegistrationDate;
      }
    }

    fromDate = this.getFromDateRequest(fromDateFilter, userRegistrationDate);

    const accountIds = accounts
      .filter(
        // could be the case some internal accounts does not have a match in Yodlee
        a => a.origin !== AccountOrigin.Internal || (a.aggregatedAccountId !== 0 && a.aggregatedAccountId !== undefined)
      )
      .filter(a => !committedFilters.excludedAccounts.has(a.globalId))
      // Exclude other accounts pbi: 804711
      .filter(a => a.categoryName !== CategoryName.Other)
      // Axos Invest and Clearing Trading transactions aren't supported yet
      .filter(a => a.origin !== AccountOrigin.AxosInvest && a.origin !== AccountOrigin.Clearing)
      .filter(a => {
        if (!this.featureFlagService.isRiaPilotInsightsActive()) {
          return a.origin !== AccountOrigin.AAS;
        }
        return true;
      })
      .map(a => {
        if (a.origin === AccountOrigin.Internal) {
          return a.aggregatedAccountId;
        } else if (a.origin === AccountOrigin.AAS) {
          return a.accountNumberAas;
        }

        return a.id;
      });

    const filters = {
      accountIds,
      fromDate: format(fromDate, 'yyyy-MM-dd'),
      toDate: format(toDate, 'yyyy-MM-dd'),
      interval,
      includeToday,
      intervalStep: customStep,
    };

    this.store.dispatch(setNetWorthSeriesLoading({ payload: true }));
    (filters.accountIds.length
      ? this.pfmService.getHistoricalBalance(filters).pipe(
          map(resp => resp.data),
          catchError(err => {
            this.serviceHelper.errorHandler(err, true);

            return [];
          })
        )
      : of([])
    ).subscribe((balances: HistoricalBalance[]) => {
      const balancesByDate = this.groupBy(balances, 'date');

      let points: NetWorthPoint[] = balancesByDate.map(accountsBalance => {
        const asset = accountsBalance
          .filter(balance => balance.value.isAsset)
          .reduce((sum, balance) => sum + balance.value.balance, 0);

        const liability = accountsBalance
          .filter(balance => !balance.value.isAsset)
          .reduce((sum, balance) => sum + balance.value.balance, 0);

        return {
          date: parseISO(accountsBalance[0].value.date),
          worth: {
            asset,
            liability,
            net: asset - liability,
            accountWorths: accountsBalance.map(accountBalance => ({
              isAsset: accountBalance.value.isAsset,
              balance: accountBalance.value.balance,
              account: accounts.find(
                a =>
                  accountBalance.value.accountId ===
                  (a.origin === AccountOrigin.Internal ? a.aggregatedAccountId : a.id)
              ),
            })),
          },
        };
      });

      // if there isn't enough information, empty values should be displayed
      const hasHistoricalBalance = !!points && points.length > 0;
      const earliest = hasHistoricalBalance ? min(points.map(x => x.date)) : now;
      if (fromDateFilter < earliest || !hasHistoricalBalance) {
        const intervals = this.createIntervalSeries(fromDateFilter, earliest, interval, customStep);
        let emptyDataPoints = intervals.map(x => ({ date: x, worth: null }));
        // It removes any interval that overlaps
        emptyDataPoints = emptyDataPoints.filter(dp => !points.some(p => isEqual(p.date, dp.date)));
        points = [...emptyDataPoints, ...points];
      }

      points.sort((first, second) => compareAsc(first.date, second.date));

      this.store.dispatch(setNetWorthSeriesLoading({ payload: false }));
      this.store.dispatch(setNetWorthSeries({ payload: points }));
    });
  }

  getFromDateRequest(fromDateFilter: Date, since: Date): Date {
    const isBeforePfmLaunched = fromDateFilter < since;
    const fromDate = isBeforePfmLaunched ? since : fromDateFilter;

    return fromDate;
  }

  createIntervalSeries(start: Date, end: Date, interval: NetWorthInterval, customStep: number): Date[] {
    let result: Date[];
    switch (interval) {
      case NetWorthInterval.Custom:
        result = eachDayOfInterval({ start, end }, { step: customStep });
        break;
      case NetWorthInterval.Monthly:
        result = eachMonthOfInterval({ start, end });
        break;
      case NetWorthInterval.BiWeekly:
        result = eachDayOfInterval({ start, end }, { step: 15 });
        break;
      case NetWorthInterval.Daily:
        result = eachDayOfInterval({ start, end }, { step: 1 });
        break;
    }

    return result;
  }

  // todo move these helper functions to a dedicated file (also remove them from spending.effects)
  groupBy<T>(arr: T[], by: keyof T): { key: string; value: T }[][] {
    // similar to LINQ GroupBy

    return arr.reduce((storage, value) => {
      const key = value[by].toString();
      let group = storage.find(grp => grp[0].key === key);

      if (!group) {
        group = [];
        storage.push(group);
      }

      group.push({ key, value });

      return storage;
    }, []);
  }

  arrayEquals(array1: Set<string> | number[], array2: Set<string> | number[]): boolean {
    const array1Sorted = [...array1].sort();
    const array2Sorted = [...array2].sort();

    return (
      array1Sorted.length === array2Sorted.length &&
      array1Sorted.every((v, i) => {
        return v === array2Sorted[i];
      })
    );
  }
}
