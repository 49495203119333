<div class="row">
  <div class="col-3">
    <button id="backToOverviewBtn" type="button" class="link d-flex align-items-center" (click)="goBack.emit()">
      <app-svg-icon class="overview__icon mr-2" [iconName]="icons.backIcon" size="1.3em"></app-svg-icon>
      <span class="overview__text">Back</span>
    </button>
  </div>
  <div class="col-6 text-center">
    <p>Review your order information</p>
  </div>
</div>
<div class="mt-4">
  <app-confirmation-table [fields]="fields"></app-confirmation-table>
</div>
<div class="row mt-4 mb-4 justify-content-center">
  <button *ngIf="!submittingOrder" class="btn-ok button--lg" type="button" id="submitBtn" (click)="sendOrder()">
    Submit
  </button>
  <app-dot-loader *ngIf="submittingOrder"></app-dot-loader>
</div>
