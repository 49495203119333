import { UrlHandlingStrategy, UrlTree } from '@angular/router';

export class Ng1Ng2UrlHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url: UrlTree) {
    return url.toString().startsWith('/udb');
  }

  extract(url: UrlTree) {
    return url;
  }

  merge(url: UrlTree, _whole: UrlTree) {
    return url;
  }
}
