import { Injectable } from '@angular/core';
import {
  AccountPeopleState,
  AccountPeopleTabState,
  TrustedContactsType,
  addUpdateAccountPeopleAction,
  addUpdateInterestedPartyAction,
  addUpdateTrustedContactAction,
  deleteTrustedContactAction,
  getSelectedAccountPeopleTab,
} from '../core';
import { Store } from '@ngrx/store';
import { InitializePeopleInputType, InterestedPartyOutput, TrustedContactOutput } from '.';
import { AccountPeopleService } from '../../account-details/core/services/account-people/account-people.service';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import {
  AddInterestedPartyRequest,
  AddTrustedContactRequest,
  DeleteTrustedContactRequest,
  EditInterestedPartyRequest,
  EditTrustedContactRequest,
} from '../../account-details/core/services/account-people';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, asyncScheduler, scheduled } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PeopleFacade {
  /**
   * holds the state of people accounts
   */
  accountPeopleTabState$ = this.store.select(getSelectedAccountPeopleTab);
  axosAdvisoryAccounts$ = this.store.select(getAxosAdvisoryAccounts);
  isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly store: Store<AccountPeopleState>,
    private readonly peopleProvider: AccountPeopleService
  ) {}

  /**
   * Schedules a http request that will add/update the state with the given response from
   * the request done
   * input {@link InitializePeopleInputType}
   */
  initializePeopleTab(input: InitializePeopleInputType): void {
    this.peopleProvider
      .getAccountPeople(input)
      .pipe(retry(3))
      .subscribe({
        next: response => {
          const payload: AccountPeopleTabState = {
            accountNumber: input.accountNumber,
            accountPeople: { accountNumber: input.accountNumber, ...response.data },
            error: undefined,
          };
          this.store.dispatch(addUpdateAccountPeopleAction({ payload }));
        },
        error: error => {
          const payload: AccountPeopleTabState = {
            accountNumber: input.accountNumber,
            accountPeople: undefined,
            error: error,
          };
          this.store.dispatch(addUpdateAccountPeopleAction({ payload }));
        },
      });
  }

  addTrustedContact(input: AddTrustedContactRequest): Observable<TrustedContactOutput> {
    const accountNumber = input.accountNumber;
    this.isLoading$.next(true);

    return this.peopleProvider.addTrustedContact(input).pipe(
      map(res => this.handleAddTrustedContactSuccessResponse(accountNumber, res.data)),
      catchError(err => this.handleTrustedContactFailedResponse(err))
    );
  }

  editTrustedContact(input: EditTrustedContactRequest): Observable<TrustedContactOutput> {
    const accountNumber = input.accountNumber;
    this.isLoading$.next(true);

    return this.peopleProvider.editTrustedContact(input).pipe(
      map(res => this.handleEditTrustedContactSuccessResponse(accountNumber, res.data)),
      catchError(err => this.handleTrustedContactFailedResponse(err))
    );
  }

  deleteTrustedContact(input: DeleteTrustedContactRequest): Observable<TrustedContactOutput> {
    const accountNumber = input.accountNumber;
    this.isLoading$.next(true);

    return this.peopleProvider.deleteTrustedContact(input).pipe(
      map(res => this.handleDeleteTrustedContactSuccessResponse(accountNumber, res.data)),
      catchError(err => this.handleTrustedContactFailedResponse(err))
    );
  }

  addInterestedParty(input: AddInterestedPartyRequest): Observable<InterestedPartyOutput> {
    this.isLoading$.next(true);
    const inputGetAccountPeople = {
      accountNumber: input.accountNumber,
    };

    return this.peopleProvider.addInterestedParty(input).pipe(
      switchMap(() => this.getAccountPeople(inputGetAccountPeople)),
      map(() => this.handleAddInterestedPartySuccessResponse()),
      catchError(err => this.handleInterestedPartyFailedResponse(err))
    );
  }

  editInterestedParty(input: EditInterestedPartyRequest): Observable<InterestedPartyOutput> {
    this.isLoading$.next(true);

    return this.peopleProvider.editInterestedParty(input).pipe(
      map(() => this.handleEditInterestedPartySuccessResponse(input)),
      catchError(err => this.handleInterestedPartyFailedResponse(err))
    );
  }

  private getAccountPeople(input: InitializePeopleInputType) {
    return this.peopleProvider.getAccountPeople(input).pipe(
      retry(3),
      tap({
        next: response => {
          const payload: AccountPeopleTabState = {
            accountNumber: input.accountNumber,
            accountPeople: { accountNumber: input.accountNumber, ...response.data },
            error: undefined,
          };
          this.store.dispatch(addUpdateAccountPeopleAction({ payload }));
        },
        error: error => {
          const payload: AccountPeopleTabState = {
            accountNumber: input.accountNumber,
            accountPeople: undefined,
            error: error,
          };
          this.store.dispatch(addUpdateAccountPeopleAction({ payload }));
        },
      })
    );
  }

  private handleDeleteTrustedContactSuccessResponse(accountNumber: string, apiResponse: boolean): TrustedContactOutput {
    const payload = {
      accountNumber,
      isDeleted: apiResponse,
    };
    const output = {
      apiCallWasSuccessful: true,
    };
    this.store.dispatch(deleteTrustedContactAction({ payload }));
    this.isLoading$.next(false);

    return output;
  }

  private handleTrustedContactFailedResponse(err: HttpErrorResponse): Observable<TrustedContactOutput> {
    const output = {
      apiCallWasSuccessful: err.status === HttpStatusCode.Ok,
    };
    this.isLoading$.next(false);

    return scheduled([output], asyncScheduler);
  }

  private handleEditTrustedContactSuccessResponse(
    accountNumber: string,
    apiResponse: TrustedContactsType
  ): TrustedContactOutput {
    const payload = {
      accountNumber,
      trustedContact: apiResponse,
    };
    const output = {
      apiCallWasSuccessful: true,
    };
    this.store.dispatch(addUpdateTrustedContactAction({ payload }));
    this.isLoading$.next(false);

    return output;
  }

  private handleAddTrustedContactSuccessResponse(
    accountNumber: string,
    apiResponse: TrustedContactsType
  ): TrustedContactOutput {
    const payload = {
      accountNumber,
      trustedContact: apiResponse,
    };
    const output = {
      apiCallWasSuccessful: true,
    };
    this.store.dispatch(addUpdateTrustedContactAction({ payload }));
    this.isLoading$.next(false);

    return output;
  }

  private handleAddInterestedPartySuccessResponse(): InterestedPartyOutput {
    const output: InterestedPartyOutput = {
      apiCallWasSuccessful: true,
    };
    this.isLoading$.next(false);

    return output;
  }

  private handleEditInterestedPartySuccessResponse(input: EditInterestedPartyRequest) {
    const payload = {
      accountNumber: input.accountNumber,
      interestedParty: input.interestedParty,
    };
    const output = {
      apiCallWasSuccessful: true,
    };

    this.store.dispatch(addUpdateInterestedPartyAction({ payload }));
    this.isLoading$.next(false);

    return output;
  }

  private handleInterestedPartyFailedResponse(err: HttpErrorResponse): Observable<TrustedContactOutput> {
    const output = {
      apiCallWasSuccessful: err.status === HttpStatusCode.Ok,
    };
    this.isLoading$.next(false);

    return scheduled([output], asyncScheduler);
  }
}
