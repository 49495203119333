import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { IStateService } from 'angular-ui-router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SimilarBiller } from '@app/bill-pay/typings';
import { BillPayService } from '@legacy/services/bill-pay.service';
import { BillPayRouterHelper } from '@app/bill-pay/utils';
import { AddressValidation } from '@app/bill-pay/typings';
import { Biller } from '@app/bill-pay/typings';
import { PartialRecipient } from '@app/bill-pay/typings';
import { Recipient } from '@app/bill-pay/typings';

import { STATE } from '@core/tokens';
import {
  BILLPAYSERVICE,
  SERVICEHELPER,
  BILLPAYROUTERHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
  providers: [billPayServiceProvider],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BillingDetailsComponent implements OnInit {
  recipients: Recipient[];
  isBusy: boolean;
  criteria: string;
  biller: Biller;
  errorMessage: string;
  // addRecipientForm: FormGroup;
  addRecipientForm: FormGroup;
  hasError: boolean;
  recipient: PartialRecipient = new PartialRecipient();
  isRetry: boolean;
  hintMessage: string;

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(BILLPAYSERVICE) private readonly billPayService: BillPayService,
    @Inject(SERVICEHELPER) private readonly serviceHelper: IServiceHelper,
    @Inject(BILLPAYROUTERHELPER)
    private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}

  ngOnInit(): void {
    this.criteria = this.billPayRouterHelper.getCriteria();
    this.biller = this.billPayRouterHelper.getBiller();
    this.billPayRouterHelper.getRecipient() != undefined
      ? (this.recipient = this.billPayRouterHelper.getRecipient())
      : (this.recipient = new PartialRecipient());
    if (!this.biller || this.biller == undefined) {
      this.state.go('udb.billPay.searchRecipient');
    } else {
      this.generateHints();
    }
    this.createFormGroup();
  }

  createFormGroup(): void {
    this.addRecipientForm = new FormGroup({
      account: new FormControl(this.recipient.paymentAccount, [
        Validators.required,
      ]),
      nickname: new FormControl(this.recipient.nickName),
      zip: new FormControl(this.recipient.zipCode),
    });
  }

  removeCustomValidation() {
    if (this.addRecipientForm.controls['account'].value != '') {
      this.addRecipientForm.controls['account'].setErrors(null);
    }
  }

  submitRecipient(e: ng.IAngularEvent): void {
    e.stopPropagation;
    this.addRecipientForm.markAllAsTouched();
    if (!this.addRecipientForm.valid) return;
    this.setValuesInRecipientObject();
    this.isBusy = true;
    this.hasError = false;
    this.billPayService
      .getRecipients()
      .then(response => {
        this.recipients = response.data;
        const exist = this.recipients.find((recipient: Recipient) => {
          return (
            recipient.displayName === this.biller.name.trim() &&
            recipient.paymentAccount === this.recipient.paymentAccount.trim()
          );
        });
        if (exist !== null && exist !== undefined) {
          this.hasError = true;
          this.isBusy = false;

          return;
        }
        this._validateBillingAccountNumber();
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        return false;
      });
  }

  cancelSetup() {
    this.billPayRouterHelper.cancelSetup();
  }

  private _validateBillingAccountNumber() {
    let ids = null;
    if (this.biller.allBillers !== null) {
      ids = this.biller.allBillers.map((b: SimilarBiller): number => {
        return b.billerId;
      });
    }

    this.billPayService
      .validateBillerAccountNumber(
        this.recipient.paymentAccount,
        this.biller.billerId,
        ids
      )
      .then(response => {
        this.biller.billerId = response.data.billerId;
        this.billPayRouterHelper.setBiller(this.biller);
        this.removeCustomValidation();
        this._saveRecipient();
      })
      .catch(response => {
        this.errorMessage = response.data.message;
        this.addRecipientForm.controls['account'].setErrors({
          invalidAccount: true,
        });
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  private _saveRecipient(): void {
    this.isBusy = true;
    this._setPartialRecipient();
    this.billPayService
      .saveElectronicRecipient(this.recipient)
      .then(response => {
        if (response.data.accountValidation != null) {
          this._filterBillerAddresses(response.data.accountValidation);
        } else {
          this.recipient.ebillStatus = response.data.payee.eBillsStatus;
          this.recipient.payeeId = response.data.payee.payeeId;

          this.recipient.addressLine =
            response.data.payee.billingAddress.addressLine1;
          this.recipient.zipCode = response.data.payee.billingAddress.zipCode;
          this.recipient.city = response.data.payee.billingAddress.city;
          this.recipient.stateCode =
            response.data.payee.billingAddress.stateCode;
          this.recipient.country = response.data.payee.billingAddress.country;

          this.billPayRouterHelper.setRecipient(this.recipient);
          if (this.recipient.ebillStatus === 1)
            this.state.go('udb.billPay.electronicCheckpoint');
          else this.state.go('udb.billPay.electronicConfirmation');
        }
      })
      .catch(() => {
        this.hasError = true;
        this.serviceHelper.scrollToTop();
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  private _setPartialRecipient() {
    if (!this.isRetry) {
      this.recipient.billerName = this.biller.name;
      this.recipient.billerId = this.biller.billerId;
      this.recipient.billerGroupId = this.biller.billerId
        ? null
        : this.biller.billerGroupId; // only send billerGroupId when there is no billerId
      this.recipient.isElectronic = true;
      this.recipient.addressLine = '';
      this.recipient.city = '';
      this.recipient.country = '';
      this.recipient.stateCode = '';
      this.billPayRouterHelper.setRecipient(this.recipient);
    }
  }

  private _filterBillerAddresses(addresses: AddressValidation[]) {
    const billersAddress = addresses.find(
      (addressValidation: AddressValidation) => {
        return addressValidation.billerId == this.biller.billerId.toString();
      }
    );

    if (billersAddress !== null && billersAddress !== undefined) {
      this.billPayRouterHelper.setBillerAddresses(billersAddress.addresses);
      this.billPayRouterHelper.setRecipient(this.recipient);
      if (billersAddress.addresses.length === 1) {
        this.recipient.addressLine = billersAddress.addresses[0].addressLine1;
        this.recipient.zipCode = billersAddress.addresses[0].zipCode;
        this.recipient.city = billersAddress.addresses[0].city;
        this.recipient.stateCode = billersAddress.addresses[0].stateCode;
        this.recipient.country = billersAddress.addresses[0].country;
        this.isRetry = true;
        this._saveRecipient();
      } else {
        this.state.go('udb.billPay.electronicAddress');
      }
    } else {
      this.state.go('udb.billPay.searchRecipient');
    }
  }

  private generateHints(): void {
    this.hintMessage =
      'Check your invoice or billing statement to find your account number. ';
    if (this.biller.allBillers !== null) {
      this.hintMessage += `<strong>Hint${
        this.biller.allBillers.length > 1 ? 's' : ''
      }:</strong> `;
      if (this.biller.allBillers.length == 1) {
        this.hintMessage += this.biller.paymentAccountHint;
      } else {
        this.hintMessage +=
          '<ul style="margin-left: 20px;margin-top: 5px; display: block;">';
        // repeated hints
        const hints: string[] = [];
        this.biller.allBillers.forEach(biller => {
          const existIndex = hints.findIndex(
            h =>
              h.trim().toLowerCase() ==
              biller.paymentAccountHint.trim().toLowerCase()
          );
          if (existIndex === -1) {
            this.hintMessage += `<li>${biller.paymentAccountHint}</li>`;
            hints.push(biller.paymentAccountHint.trim().toLowerCase());
          }
        });
        this.hintMessage += '</ul>';
      }
    } else {
      this.hintMessage += `<strong>Hint:</strong> ${this.biller.paymentAccountHint}`;
    }
  }

  goToSearchRecipient(): void {
    this.state.go('udb.billPay.searchRecipient', { criteria: this.criteria });
  }

  setValuesInRecipientObject(): void {
    this.recipient.paymentAccount = this.addRecipientForm.controls[
      'account'
    ].value;
    this.recipient.nickName = this.addRecipientForm.controls['nickname'].value;
    this.recipient.zipCode = this.addRecipientForm.controls['zip'].value;
  }

  get formProperties() {
    return this.addRecipientForm.controls;
  }
}
