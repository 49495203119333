import { Injectable } from '@angular/core';
import { Pendo, PendoEventPayload, PendoEventRequiredDefaultPayload, PendoInitializePayload } from './types';
import { PENDO_EVENT } from './enums';
import { BROWSER_AGENT_VERSION_REGEX } from './utils';

@Injectable({
  providedIn: 'root',
})
export class PendoTrackerService {
  private pendoInstance: Pendo;
  defaultPendoTrackEventPayload: PendoEventRequiredDefaultPayload = {
    Visitor: '',
    Date: new Date().toString(),
    Username: '',
    DeviceType: '',
    OperatingSystem: '',
    AgentVersion: '',
  };

  /**
   * Initializes pendo instance
   * @param pendoInitializePayload
   */
  initializePendo(pendoInitializePayload: PendoInitializePayload): void {
    const windowPendoReference = (window as any).pendo as Pendo;
    if (!windowPendoReference) {
      throw new Error(
        'No Pendo window reference was found to initialize please check that the library is being imported'
      );
    }
    if (!this.pendoInstance) {
      windowPendoReference.initialize(pendoInitializePayload);
      this.pendoInstance = windowPendoReference;
      this.setPendoDefaultTrackEventPayload(pendoInitializePayload);
    }
  }
  /**
   * registers a event to be sent to the pendo api for user behavior tracking
   * @param eventName string
   * @param eventPayload {@link Partial} {@link PendoEventPayload}
   */
  registerTrackingEvent(eventName: PENDO_EVENT, eventPayload: PendoEventPayload = { Account: '' }): void {
    const date = new Date();
    const eventDate = date.toString();
    this.defaultPendoTrackEventPayload.Date = eventDate;
    this.pendoInstance.track(eventName, { ...this.defaultPendoTrackEventPayload, ...eventPayload });
    // todo undo comment on 48 in case track events are not being register
    // this.pendoInstance.flushNow();
  }
  /**
   * Sets part of the default payload to be sent to pendo
   * @param pendoInitialPayload {@link PendoInitializePayload}
   */
  private setPendoDefaultTrackEventPayload(pendoInitialPayload: PendoInitializePayload): void {
    this.defaultPendoTrackEventPayload.Visitor = pendoInitialPayload.visitor.id.toString();
    this.defaultPendoTrackEventPayload.Username = pendoInitialPayload.visitor.username;
    this.defaultPendoTrackEventPayload = {
      ...this.defaultPendoTrackEventPayload,
      ...this.registerDeviceInformation(),
    };
  }
  private registerDeviceInformation(): Partial<PendoEventRequiredDefaultPayload> {
    const { OperatingSystem } = this.getDeviceOS();
    const devicePendoData: Partial<PendoEventRequiredDefaultPayload> = {
      DeviceType: this.getDeviceType(),
      OperatingSystem,
      AgentVersion: this.getUserAgentVersion(),
    };
    return devicePendoData;
  }
  private getDeviceType(): string {
    const userAgent = navigator.userAgent;
    let deviceType = 'Unknown';
    switch (true) {
      case /Mobile/.test(userAgent):
        deviceType = 'Mobile';
        break;
      case /Tablet/.test(userAgent):
        deviceType = 'Tablet';
        break;
      case /Desktop/.test(userAgent):
        deviceType = 'Desktop';
        break;
      default:
        deviceType = 'Unknown';
        break;
    }
    return deviceType;
  }
  private getDeviceOS(): { OperatingSystem: string } {
    const userAgent = navigator.userAgent;
    switch (true) {
      case /Windows/.test(userAgent):
        const windowsVersionMatch = /Windows NT/.exec(userAgent);
        if (windowsVersionMatch) {
          return {
            OperatingSystem: 'Windows',
          };
        }
      case /Mac OS X/.test(userAgent):
        const macVersionMatch = /Mac OS X/.exec(userAgent);
        if (macVersionMatch) {
          return {
            OperatingSystem: 'MacOS',
          };
        }
      case /Android/.test(userAgent):
        const androidVersionMatch = /Android/.exec(userAgent);
        if (androidVersionMatch) {
          return {
            OperatingSystem: 'Android',
          };
        }
      case /Linux/.test(userAgent):
        return {
          OperatingSystem: 'Linux',
        };
      case /iOS|iPhone|iPad|iPod/.test(userAgent):
        const iosVersionMatch = /OS/.exec(userAgent);
        if (iosVersionMatch) {
          return {
            OperatingSystem: 'iOS',
          };
        }
      default:
        return {
          OperatingSystem: 'Unknown',
        };
    }
  }
  private getUserAgentVersion(): string {
    const userAgent = navigator.userAgent;
    switch (true) {
      case /Chrome/.test(userAgent):
        return userAgent.match(BROWSER_AGENT_VERSION_REGEX.CHROME)[1];
      case /Firefox/.test(userAgent):
        return userAgent.match(BROWSER_AGENT_VERSION_REGEX.FIREFOX)[1];
      case /Safari/.test(userAgent):
        return userAgent.match(BROWSER_AGENT_VERSION_REGEX.SAFARI)[1];
      case /Edg/.test(userAgent):
        return userAgent.match(BROWSER_AGENT_VERSION_REGEX.EDGE)[1];
      case /MSIE/.test(userAgent):
        return userAgent.match(BROWSER_AGENT_VERSION_REGEX.INTERNET_EXPLORER)[1];
      default:
        return 'Unknown';
    }
  }
}
