<div class="dashboard-accounts fcol-cs-12">
  <div class="header-section-title text-center">
    <section
      class="frow center header"
      *ngIf="isAccountAggregationEnhancementsActive"
    >
      <div
        class="header__distribution header--separator hide-sm hide-xs hide-cs"
      >
        <div class="header__title header__amount">
          <span class="header__title">Accounts</span>
        </div>
      </div>
      <div class="header__distribution">
        <app-dot-loader [showWhen]="!balancesAvailable"></app-dot-loader>
        <div class="header__amount" *ngIf="balancesAvailable">
          <span class="header__amount--total">
            {{
              totalAssets || totalAssets === 0
                ? (totalAssets | currency)
                : '...'
            }}
          </span>
          <span class="header__amount--subtitle">
            Total Assets
            <app-tooltip
              class="question-tooltip"
              [white]="true"
              [content]="toolTipContent"
            ></app-tooltip>
          </span>
        </div>
        <div class="header__amount" *ngIf="balancesAvailable">
          <span class="header__amount--total">
            {{ totalDebt || totalDebt === 0 ? (totalDebt | currency) : '...' }}
          </span>
          <span class="header__amount--subtitle">
            Total Debt
            <app-tooltip
              class="question-tooltip"
              [white]="true"
              [content]="questionToolTipContent"
            ></app-tooltip>
          </span>
        </div>
      </div>
    </section>

    <!-- MAKE SURE TO REMOVE THE FOLLOWING SECTION HTML TAG ONCE AGGREGATION ACCOUNT FEATURE HAD BEEN RELEASED  -->
    <section
      class="frow center old-header"
      *ngIf="!isAccountAggregationEnhancementsActive"
    >
      <div class="old-header__amount">
        <h5 class="old-header__amount-title">Total Assets</h5>
        <span class="old-header__amount-total">
          {{
            totalAssets || totalAssets === 0 ? (totalAssets | currency) : '...'
          }}
        </span>
      </div>
      <span class="old-header__separator hide-cs"></span>
      <div class="old-header__amount">
        <h5 class="old-header__amount-title">Total Debt</h5>
        <span class="old-header__amount-total">
          {{ totalDebt || totalDebt === 0 ? (totalDebt | currency) : '...' }}
        </span>
      </div>
    </section>
  </div>

  <app-dot-loader [showWhen]="hasOpenAccounts && !balancesAvailable">
    Loading accounts
  </app-dot-loader>

  <div class="view-container">
    <!--Message center-->
    <app-alert-notification
      class="fcol-md-10 fcol-lg-7"
      *ngIf="successMessage"
      [alertType]="messageType"
      (dismiss)="successMessage = null"
    >
      {{ successMessage }}
    </app-alert-notification>

    <div class="frow" *ngIf="isAccountAggregationEnhancementsActive">
      <div class="fcol-lg-9 fcol-xs-12">
        <!-- Header -->
        <div class="sections-header frow">
          <div class="fcol-sm-8 fcol-xs-12">
            <!-- Title -->
            <div class="sections-header__element">
              <p class="sections-header--description">All Accounts</p>
            </div>
            <div class="sections-header__element" *ngIf="!isTeen">
              <div class="sections-header--btn">
                <app-dot-loader [showWhen]="!isOpenMenuItemsLoaded">
                  Loading
                </app-dot-loader>
                <app-open-account-items-container
                  [accountItems]="openMenuItems"
                  *ngIf="isOpenMenuItemsLoaded"
                ></app-open-account-items-container>
              </div>
            </div>
          </div>
          <!-- Only axos accounts switch -->
          <div class="fcol-sm-4 fcol-xs-12 sections-header__switch">
            <div class="sections-header__element">
              <div class="sections-header--only-axos-accounts">
                <span>
                  <label class="uk-switch">
                    <input
                      type="checkbox"
                      [(ngModel)]="showAxosAccounts"
                      [checked]="showAxosAccounts"
                      [disabled]="!hasOpenAccounts"
                      (change)="displayOnlyAxosAccounts(showAxosAccounts)"
                    />
                    <span class="slider">
                      <i
                        class="slider--icon"
                        [ngClass]="
                          showAxosAccounts ? 'bofi-check-mark' : 'bofi-close'
                        "
                      ></i>
                    </span>
                  </label>
                </span>
                <span class="bold-description">
                  &nbsp;&nbsp;Show Only {{ toggleText }} Accounts
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="frow center" *ngIf="!providersLoading">
      <div
        class="fcol-xs-12 dashboard-container"
        [ngClass]="
          isAccountAggregationEnhancementsActive
            ? 'fcol-lg-9'
            : 'fcol-md-10 fcol-lg-7'
        "
      >
        <h2
          class="text-center"
          *ngIf="!hasAccounts && !isLoadingClosedAccounts && !hasClosedAccounts"
        >
          You do not have any accounts
        </h2>

        <!-- Checking -->
        <app-account-list-group
          *ngIf="arrayHasAccounts(accountsToDisplay.checkingAccounts)"
          [accounts]="accountsToDisplay.checkingAccounts"
          [groupTitle]="'Checking'"
          [actionText]="'Open Account'"
          [actionLink]="env.depositsUrl"
          [displayAmount]="true"
          (saveSorting)="saveSortedAccounts($event)"
        ></app-account-list-group>

        <!-- Saving -->
        <app-account-list-group
          *ngIf="arrayHasAccounts(accountsToDisplay.savingAccounts)"
          [accounts]="accountsToDisplay.savingAccounts"
          [groupTitle]="'Savings'"
          [actionText]="'Open Account'"
          [actionLink]="env.depositsUrl"
          [displayAmount]="true"
          (saveSorting)="saveSortedAccounts($event)"
        ></app-account-list-group>

        <!-- Investing -->
        <app-account-list-group
          *ngIf="arrayHasAccounts(accountsToDisplay.investingAccounts)"
          [accounts]="accountsToDisplay.investingAccounts"
          [groupTitle]="'Investment'"
          [displayAmount]="true"
          (saveSorting)="saveSortedAccounts($event)"
        ></app-account-list-group>
        <!-- add crypto link -->
        <!-- <app-crypto-link></app-crypto-link> -->
        <app-dot-loader
          [showWhen]="!areTradingAccountsLoaded && !isLoadingClosedAccounts"
        >
          Loading accounts
        </app-dot-loader>

        <!-- Loan -->
        <app-account-list-group
          *ngIf="arrayHasAccounts(accountsToDisplay.loanAccounts)"
          [accounts]="accountsToDisplay.loanAccounts"
          [groupTitle]="'Loans'"
          [actionText]="'Apply for a Loan'"
          [actionLink]="env.loansUrl"
          [displayAmount]="true"
          [defaultTab]="1"
          (saveSorting)="saveSortedAccounts($event)"
        ></app-account-list-group>

        <!-- Credit cards -->
        <app-account-list-group
          *ngIf="arrayHasAccounts(accountsToDisplay.creditCards)"
          [accounts]="accountsToDisplay.creditCards"
          [groupTitle]="'Credit Cards'"
          [displayAmount]="true"
        ></app-account-list-group>

        <!-- Other -->
        <app-account-list-group
          *ngIf="arrayHasAccounts(accountsToDisplay.others)"
          [accounts]="accountsToDisplay.others"
          [groupTitle]="'Other'"
          [displayAmount]="true"
          (onAccountOperation)="getRemovedAccountOperationMessage($event)"
        ></app-account-list-group>

        <app-dot-loader [showWhen]="isLoadingClosedAccounts">
          Loading accounts
        </app-dot-loader>

        <!-- Trasfer Only-->
        <app-account-list-group
          id="transferOnly"
          *ngIf="
            arrayHasAccounts(externalAccounts) &&
            isAccountAggregationEnhancementsActive &&
            !showAxosAccounts
          "
          [accounts]="externalAccounts"
          [groupTitle]="'Transfer Only'"
          [displayAmount]="false"
          (saveSorting)="saveSortedAccounts($event)"
          [trasferSection]="true"
          [trasferMesgtool]="messageTrasferTooltip"
          (onAccountOperation)="getAccountOperationMessage($event)"
          (onHandleNicknameUpdate)="handleNicknameUpdate($event)"
          [pendingActivation]="pendingActivation"
        ></app-account-list-group>

        <!-- closed accounts  -->
        <app-account-list-group
          *ngIf="arrayHasAccounts(accountsToDisplay.closedAccounts)"
          [accounts]="accountsToDisplay.closedAccounts"
          [groupTitle]="
            getGroupTitleForClosedAccounts(accountsToDisplay.closedAccounts)
          "
          [displayAmount]="false"
          (saveSorting)="saveSortedAccounts($event)"
        ></app-account-list-group>

        <div
          *ngIf="isAxosInvestActive || isAxosTradingActive"
          class="compliance_disclaimer"
        >
          <p>
            All balances for deposit accounts provided through Axos Bank brands
            are based on your current available balance.
          </p>
          <div *ngIf="!isBusinessProfile">
            <p>
              For Axos Invest Brokerage Accounts and Axos Digital Assets, all
              information presented by a third party and is subject to delays.
              Intraday prices are generally provided for stocks, ETFs, and
              options on a real-time basis. Delays may occur during market
              hours. If delayed, and an intraday price is not available, the
              price displayed will be the last quote & quantity received from
              the vendor, or will reflect the previous business day’s close,
              whichever is most recent. For mutual funds, the prices displayed
              are generally the previous business day’s closing price.
            </p>
            <p>
              For all other accounts, the prices displayed are generally the
              previous business day’s closing price unless otherwise denoted.
            </p>
          </div>
          <div *ngIf="isCryptoPilotActive">
            <p>
              Digital asset accounts offered by Axos Digital Assets LLC, a
              division of Axos Financial.
            </p>
          </div>
        </div>
      </div>

      <div
        class="fcol-lg-3 fcol-xs-12"
        *ngIf="isAccountAggregationEnhancementsActive"
      >
        <aside class="frow external-section">
          <div class="fcol-xs-12">
            <h3 class="external-section__title">
              See all your accounts in one place!
            </h3>
            <p>
              Never lose sight of your money again. See it all here. It’s fast,
              secure, and efficient!
            </p>
            <div class="logos">
              <div class="logos__element" *ngFor="let bank of banks">
                <img
                  class="logos__element--size"
                  src="{{ getBankLogo(bank.imageSrc) }}"
                />
              </div>
            </div>
            <div class="text-center">
              <button
                class="uk-btn sm solid secondary"
                (click)="redirectToAccAggr()"
              >
                <i class="bofi-add"></i>
                <span>&nbsp;&nbsp;Add an Account</span>
              </button>
            </div>
          </div>
        </aside>
      </div>
    </section>
  </div>
</div>
