import { DebitCardConfirmationViewComponent } from './debit-card-confirmation-view/debit-card-confirmation-view.component';
import { DebitCardViewComponent } from './debit-card-view/debit-card-view.component';
import { ReportLostStolenViewComponent } from './report-lost-stolen-view/report-lost-stolen-view.component';
import { RequestLimitChangeViewComponent } from './request-limit-change-view/request-limit-change-view.component';
import { RequestReplacementViewComponent } from './request-replacement-view/request-replacement-view.component';
import { ScheduleTravelViewComponent } from './schedule-travel-view/schedule-travel-view.component';
export {
  DebitCardConfirmationViewComponent,
  DebitCardViewComponent,
  ReportLostStolenViewComponent,
  RequestLimitChangeViewComponent,
  RequestReplacementViewComponent,
  ScheduleTravelViewComponent,
};

export const debitCardViews = [
  DebitCardConfirmationViewComponent,
  DebitCardViewComponent,
  ReportLostStolenViewComponent,
  RequestLimitChangeViewComponent,
  RequestReplacementViewComponent,
  ScheduleTravelViewComponent,
];
