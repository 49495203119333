import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() currentStep: number;
  @Input() set steps(steps: number[]) {
    this.printableSteps = steps.filter(step => Number.isInteger(step));
  }
  printableSteps: number[];

  setStep(step: number): boolean {
    let _step = this.currentStep;
    if (!this.printableSteps.filter(s => s === _step).length) {
      _step = Math.ceil(_step);
    }

    return step <= _step;
  }
}
