import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { finalize } from 'rxjs/operators';

import { UpdatePhonesRequest } from '@app/user-profile/models';
import { FacingBrand } from '@core/enums/facing-brand.enum';
import { OlbSettings } from '@core/models';
import { UserProfileService } from '@core/services';
import { olbSettings } from '@core/tokens';

export interface SmsActivationModalData {
  termsAndConditionsUrl: string;
  phonesRequest: Partial<UpdatePhonesRequest>;
}

@Component({
  selector: 'app-sms-activation-modal',
  templateUrl: './sms-activation-modal.component.html',
  styleUrls: ['./sms-activation-modal.component.scss'],
})
export class SmsActivationModalComponent implements OnInit {
  backgroundUrl: string;
  svgStyle = {
    width: '5rem',
    height: '5rem',
    fill: 'white',
  };
  isBusy = false;
  brandName: string;

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: SmsActivationModalData,
    private dialogRef: MatDialogRef<SmsActivationModalComponent>,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    const { brand, facingBrandId } = this.env;

    switch (facingBrandId)
    {
      case FacingBrand.Axos:
        this.brandName = 'Axos Bank';
        break;
      case FacingBrand.Nationwide:
        this.brandName = 'Nationwide by Axos Bank';
        break;
      case FacingBrand.UFB:
        this.brandName = 'UFB Direct';
        break;
    }


    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
  }

  requestOtp() {
    this.isBusy = true;
    this.userProfileService
      .requestOtpToUpdatePhones(this.data.phonesRequest)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(response => {
        this.dialogRef.close(response.data);
      });
  }
}
