import { Address } from '@shared/models';

/**
 * Formats an address object and returns it as string
 *
 * @param address Address type to format.
 * @returns A formated address string.
 */
export function formatAddress(address: Partial<Address>) {
  let addressStr = '';
  if (address?.city && address?.stateCode && address?.postalCode && address?.streetAddress1) {
    const line3 = `${address.city}, ${address.stateCode} ${editPostalCode(address.postalCode)}`;
    addressStr = `${address.streetAddress1}\n${address.streetAddress2 ? address.streetAddress2 + '\n' : ''}${line3}`;
  }

  return addressStr;
}

function editPostalCode(postalCode: string): string {
  const defaultSplitLength = 5;

  return postalCode.length <= defaultSplitLength
    ? postalCode
    : `${postalCode.slice(0, defaultSplitLength)}-${postalCode.slice(defaultSplitLength)}`;
}
