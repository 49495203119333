<div class="container axos-invest-goal-summary">
  <app-dot-loader *ngIf="isLoading">Loading goal summary...</app-dot-loader>
  <div class="row" *ngIf="!isLoading">
    <div class="col-12">
      <h2 class="goal-summary-title">Goal Summary</h2>
    </div>
  </div>
  <div class="goal-summary-content" *ngIf="!isLoading">
    <div class="row goal-summary-info">
      <div class="col-md-9 col-12">
        <div class="d-inline-block goal-total-value">
          <span class="title-sm">Total Value</span>
          <br />
          <span class="current-value-amount">{{ milestoneData.currentValue | currency }}</span>
        </div>
        <div class="d-inline-block goal-day-change">
          <span class="title-sm">Day Change</span>
          <br />
          <div
            class="goal-day-change-content d-inline-flex"
            [class.equal]="dayChangePercentage === 0 || !dayChangePercentage"
            [class.positive]="dayChangePercentage > 0"
            [class.negative]="dayChangePercentage < 0"
          >
            <app-svg-icon
              class="day-change-label-btn-icon"
              [iconName]="
                dayChangePercentage === 0 || !dayChangePercentage
                  ? icons.equal
                  : dayChangePercentage > 0
                  ? icons.triangleUp
                  : icons.triangleDown
              "
              width="1.15em"
              height="1.15em"
              [iconColor]="
                dayChangePercentage === 0 || !dayChangePercentage
                  ? '#494949'
                  : dayChangePercentage > 0
                  ? '#00805B'
                  : '#fd0000'
              "
            ></app-svg-icon>
            <span class="goal-day-change-percentage">{{ dayChangePercentage | number: '1.0-2' }}%</span>
            <span class="goal-day-change-amount">{{ dayChangeAmount | currency }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
        <button class="button btn-ok btn-width" (click)="transferFunds()">Transfer Funds</button>
      </div>
    </div>
    <div class="row goal-info">
      <div class="col-12">
        <h3 class="goal-info-title d-inline-block">Your Goal</h3>
        <div class="edit-button d-inline-block" *ngIf="milestoneData.type !== 5">
          <button class="edit-label-btn" type="button" (click)="redirectToEditGoal(milestoneData.type)">
            <app-svg-icon
              class="edit-label-btn-icon"
              [iconName]="icons.edit"
              width="1.15em"
              height="1.15em"
              iconColor="#1c7fdd"
            ></app-svg-icon>
            <span class="edit-goal-label">Edit Goal</span>
          </button>
        </div>
      </div>
    </div>
    <div class="goal-info-section d-inline-flex">
      <app-svg-icon [iconName]="icons.goalType" width="1.5em" height="1.5em" iconColor="#4A5560"></app-svg-icon>
      <h3 class="goal-title">{{ milestoneData.name }}</h3>
      <app-svg-icon
        *ngIf="milestoneData.targetAmount"
        [iconName]="icons.badge"
        width="1.5em"
        height="1.5em"
        iconColor="#4A5560"
      ></app-svg-icon>
      <h3 class="target-amount-title" *ngIf="milestoneData.targetAmount">
        {{ milestoneData.targetAmount | currency }}
        <span *ngIf="milestoneData.targetDate">by {{ milestoneData.targetDate | date: 'yyyy' }}</span>
      </h3>
    </div>
  </div>
  <div class="past-performance-graph-content">
    <div class="row justify-content-center">
      <div class="col">
        <div class="past-performance-title" *ngIf="!isLoading"><h3>Performance</h3></div>
        <app-past-performance-graph
          [pastPerformanceData]="data"
          [pastPerformanceDataLabel]="dataLabels"
          [pastPerformanceTimeLabel]="timeLabel"
          *ngIf="!isLoading"
        ></app-past-performance-graph>
        <div class="graph-info d-flex justify-content-between" *ngIf="!isLoading && data.length != 0">
          <div class="graph-info-component">
            <div class="graph-info-title">
              <h5>Total Deposits</h5>
            </div>
            <div class="graph-info-content">
              <p>{{ pastPerformance.totalDeposits | currency }}</p>
            </div>
          </div>
          <div class="graph-info-component">
            <div class="graph-info-title">
              <h5>Total Withdrawals</h5>
            </div>
            <div class="graph-info-content">
              <p>{{ pastPerformance.withdrawals | currency }}</p>
            </div>
          </div>
          <div class="graph-info-component">
            <div class="graph-info-title">
              <h5>Dividends</h5>
            </div>
            <div class="graph-info-content">
              <p>{{ pastPerformance.dividends | currency }}</p>
            </div>
          </div>
          <div class="graph-info-component text-right">
            <div class="graph-info-title">
              <h5>Total Gain/Loss</h5>
            </div>
            <div class="graph-info-content">
              <p>
                {{ pastPerformance.marketValue - pastPerformance.totalDeposits > 0 ? '+' : '' }}
                {{ pastPerformance.marketValue - pastPerformance.totalDeposits | currency }} (
                {{ pastPerformance.marketValue - pastPerformance.totalDeposits > 0 ? ' +' : '' }}
                {{ calculatePercentagePerformance(pastPerformance) | percent: '.2' }}
                {{ (pastPerformance.totalDeposits === 0 ? 0 : '') | percent }})
              </p>
            </div>
          </div>
        </div>
        <div class="performance-disclaimer" (click)="togglePerformanceDisclaimer()" *ngIf="!isLoading">
          <div class="performance-disclaimer-header">
            <p>How is performance calculated?</p>
            <app-svg-icon class="performance-disclaimer-header-icon" [iconName]="icons.down" size="1em"></app-svg-icon>
          </div>
          <div class="performance-disclaimer-content" *ngIf="viewPerformanceDisclaimer">
            <p>
              Calculations are based upon actual performance of the investments in a your portfolio since the time of
              your account opening, and are calculated as net of any advisory fees. Both simple and time weighted
              returns are calculated as of the value of your investments as of the previous market close. This
              performance chart only reflects the performance history of your Managed Portfolio account and does not
              include any other investments with any other firm. Performance presented are informational and are not
              intended to provide specific advice or recommendations for any individual. Asset allocation and
              diversification does not ensure a profit or protect against a loss. For details on your cost basis
              information, please review your account statement provided by our clearing firm. Investors should not use
              the figures presented as proof or documentation of any gains and losses to be used in conjunction with
              their tax return, and should consult with their tax advisor before undertaking any strategy. Past
              performance is not a guarantee of future results, and future market conditions may vary. Advisory Fees and
              expenses will reduce your portfolio’s rate of return.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-recent-transactions *ngIf="!isLoadingTransactions" [transactions]="transactions"></app-recent-transactions>
  <button class="view-transactions-btn" id="goBack" (click)="goToTransactions()" *ngIf="!isLoadingTransactions">
    <span class="view-transactions-btn-label">View All Transactions</span>
    <app-svg-icon class="view-transactions-btn-icon" [iconName]="icons.forward" size="1.15em"></app-svg-icon>
  </button>
  <p class="transactions-disclaimer" *ngIf="!isLoadingTransactions">
    Transactions shown represent a summary of the latest transactions in your account. For more specific details on your
    transactions, please review your account statement provided by our clearing firm.
  </p>
</div>
