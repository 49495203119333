<div class="row form-group">
  <div class="col-md-12">
    <label class="uk-label" [attr.for]="selectedPeriod">{{ selectedPeriod }}</label>
    <div class="dropdown-wrapper">
      <select class="uk-input" (change)="onSelect($event.target.value)" [ngSwitch]="selectedPeriod" [disabled]="isDisabled">
        <ng-container *ngSwitchCase="monthPeriod">
          <option value="" disabled selected>Select a Month</option>
          <option *ngFor="let month of months" [value]="month">{{ month }}</option>
        </ng-container>
        <ng-container *ngSwitchCase="quarterPeriod">
          <option value="" disabled selected>Select a quarter</option>
          <option *ngFor="let quarter of quarters" [value]="quarter">{{ quarter }}</option>
        </ng-container>
        <ng-container *ngSwitchCase="yearPeriod">
          <option value="" disabled selected>Select a year</option>
          <option *ngFor="let year of years" [value]="year">{{ year }}</option>
        </ng-container>
      </select>
    </div>
  </div>
</div>
