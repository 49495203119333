/** Represents an alert UI Object for my alerts UX/UI. */
export class AlertsUI {
  /** Set the Alert Id */
  id: string;
  /** Display the type of the alert. */
  title: string;
  /** Display subtitle for the alert. */
  subTitle: string;
  /** Icon for the alert. */
  icon: string;
  /** Alert notification type. */
  type: string;
  /** Display Alert description. */
  description: string;
  /** Display Alert type second part of description. (optional) */
  description2?: string;
  /** Data type Alert received. */
  dataType: string;
  /** Flag to display or not save/cancel buttons. */
  editMode: boolean;
  /** Object for frequency options. */
  frequency: any;
  /** Account category. */
  category: number;
  /** Alert save service url . */
  url: string;
  /** Alert Object  **/
  alert: Alerts;
  /** Flag to indicate if alert is alredy created or new  **/
  isNew: boolean;

  deletingAlert: boolean = false;
  constructor(alertObj: Alerts, editMode: boolean = false) {
    this.title = alertObj.alertType.toUpperCase();
    this.alert = alertObj;
    /*Set UI values for each Alert*/
    this.setAlert(alertObj.alertType, editMode);
  }

  private setAlert: (alertType: string, editMode: boolean) => void = (
    alertType: string,
    editMode: boolean
  ) => {
    this.id = 'alert-' + Math.random();
    this.editMode = editMode;

    switch (alertType.toLocaleUpperCase().replace(/\s+/g, '')) {
      case 'DOCUMENTS':
        this.title = 'Documents';
        this.subTitle = 'Get notified for trade confirmations, proxies, prospectuses, and other documents';
        this.icon = "files-file";
        this.description = 'Notify me when trade confirmations, proxies, prospectuses, and other documents are ready for all my accounts';
        this.type = 'text';
        this.dataType = '';
        this.category = 7; // 7 = means unknown, NO CURRENT CATEGORY ASSIGNED FOR RIA ACCOUNTS
        this.url = 'documentsalerts';
        break;
      case 'BALANCESNAPSHOT':
        this.title = 'Balance Snapshot';
        this.subTitle = 'Stay up-to-date with your available balance through a daily alert.';
        this.icon = 'balance-snapshot';
        this.description = 'send me the available balance of ';
        this.type = 'frequency';
        this.dataType = 'account';
        this.frequency = {};
        this.category = 1;
        this.url = 'accountsnapshotalerts';
        break;

      case 'LARGEWITHDRAWAL':
        this.title = 'Large Withdrawal';
        this.subTitle = 'Know immediately when a large sum has been withdrawn.';
        this.icon = 'large-withdrawal';
        this.description = 'If a withdrawal from  ';
        this.description2 = 'exceeds ';
        this.type = 'money';
        this.dataType = 'account';
        this.category = 1;
        this.url = 'largewithdrawals';
        break;

      case 'LARGEDEPOSIT':
        this.title = 'Large Deposit';
        this.subTitle = 'Get alerted when a large sum of money is deposited into your account.';
        this.icon = 'large-deposit';
        this.description = 'If a deposit to ';
        this.description2 = 'exceeds ';
        this.type = 'money';
        this.dataType = 'account';
        this.category = 1;
        this.url = 'largedeposits';
        break;

      case 'CHECKCLEARED':
        this.title = 'Check Cleared';
        this.subTitle = 'Know when a check you have written gets cashed.';
        this.icon = 'check-cleared';
        this.description = 'If this check clears in ';
        this.type = 'number';
        this.dataType = 'account';
        this.category = 1;
        this.url = 'clearedcheckalerts';
        break;

      case 'LOWBALANCE':
        this.title = 'Low Balance';
        this.subTitle = 'Avoid overdrawing from your account with low balance alerts.';
        this.icon = 'low-balance';
        this.description = 'If the available balance of ';
        this.description2 = 'falls below ';
        this.type = 'money';
        this.dataType = 'account';
        this.category = 1;
        this.url = 'lowbalancealerts';
        break;

      case 'HIGHBALANCE':
        this.title = 'High Balance';
        this.subTitle = 'Get notified when your balance reaches a financial milestone.';
        this.icon = 'high-balance';
        this.description = 'If the available balance of ';
        this.description2 = 'goes above ';
        this.type = 'money';
        this.dataType = 'account';
        this.category = 1;
        this.url = 'highbalancealerts';
        break;

      case 'MATURITYDATE':
        this.title = 'CD Maturity';
        this.subTitle = 'Know when your CD is available to renew or withdraw funds.';
        this.icon = 'cd-maturity';
        this.description = 'If ';
        this.description2 = 'is maturing in ';
        this.type = 'date';
        this.dataType = 'cd';
        this.category = 4;
        this.url = 'maturitydatealerts';
        break;

      case 'LOANPAYMENTDUEDATE':
        this.title = 'Loan Payment Due';
        this.subTitle = 'Get notified when your loan payment is coming due.';
        this.icon = 'loan-payment-due';
        this.description = 'If ';
        this.description2 = 'has a payment due within ';
        this.type = 'date';
        this.dataType = 'loan';
        this.category = 3;
        this.url = 'loanduedatealerts';
        break;

      case 'LOANOVERDUE':
        this.title = 'Loan Payment Overdue';
        this.subTitle = 'Get alerted quickly when a payment is overdue.';
        this.icon = 'loan-payment-overdue';
        this.description = 'Every day ';
        this.description2 = 'has a payment that is overdue. ';
        this.type = '';
        this.dataType = 'loan';
        this.category = 3;
        this.url = 'overduedatealerts';
        break;

      case 'STATEMENTNOTIFICATIONACCOUNT':
        this.title = 'Statements';
        this.subTitle = 'Get notified when your statements are ready.';
        this.icon = 'statements';
        this.description = 'Notify me when statements are ready for all my accounts';
        this.type = 'text';
        this.dataType = '';
        this.category = 1;
        this.url = 'statementsalerts';
        break;

      case 'TAXFORM':
        this.title = 'Tax Forms';
        this.subTitle = 'Get notified when your tax forms are ready.';
        this.icon = 'taxforms';
        this.description = 'Notify me when tax forms are ready for all my accounts';
        this.type = 'text';
        this.dataType = '';
        this.category = 1;
        this.url = 'taxformsalerts';
        break;
    }
  };
}
