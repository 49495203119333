import { ModalComponentController } from './modal.controller';

let modalComponent: ng.IComponentOptions = {
  controller: ModalComponentController,
  templateUrl: 'common/components/modal/modal.tpl.html',
  transclude: true,
  bindings: {
    modalName: '@',
    icon: '@',
    theme: '@',
    header: '@',
    content: '@',
    okText: '@',
    cancelText: '@',
    okFunction: '&',
    cancelFunction: '&',
    canGoBack: '<?',
    closeButton: '@?',
    size: '@?',
    closeText: '@?',
    okDisabled: '<?',
  },
};

export { modalComponent };
