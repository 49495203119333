import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FundingInterceptAction, SourceFromHistroricalAction } from '@app/funding/models';
import { changeFundingAmount, changeFundingMethod, setupFundingAccountId } from '@app/store/funding/funding.actions';
import { AccountsService } from '@core/services';
import { ROOT_SCOPE, STATE, STATE_PARAMS, ngRedux, olbSettings } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { CustomerLimitsHelper } from '@legacy/services/customer-limits.helper';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { Store } from '@ngrx/store';
import { FundingMethod } from '@shared/enums';
import { IRootScopeService } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import NgRedux from 'ng-redux';

@Component({
  selector: 'app-funding-funds',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundingComponent implements OnInit, OnDestroy {
  isBusy = false;
  isSbbAccount = false;
  isCdAccountType = false;
  isDirectDeposit = false;
  isTradingAccount = false;
  brandName: string;
  step = 0;
  depositAmount = 0;
  maskedAmount = '0.00';
  customerLimits: CustomerLimits;
  isLoading = false;
  balancesLoaded = false;
  errorAmount = '';
  selectedAccount: OlbAccount;
  flow: string;
  toolTipContent: string =
    'While our system is under maintenance you will be unable to immediately move funds between your accounts, manage your Debit Card, create a Stop Payment, modify your beneficiaries or update your contact information. Our system maintenance generally ends by 3 am.';
  get accounts(): OlbAccount[] {
    return this.ngRedux.getState().funding.accountsToBeFunded;
  }

  get accountBalancesLoaded(): boolean {
    return this.ngRedux.getState().accounts.internal.balanceLoaded;
  }

  get isSiteInReadOnly(): boolean {
    return this.featureFlagService.isSiteInReadOnly();
  }

  get fundingText(): string {
    if (this.isTradingAccount) {
      return `Transfer funds from an existing bank account. Use your existing ${this.env.brandName} account or sign in to another institution to transfer funds to your new account.<br>Daily transfer limit from non-${this.env.brandName} account: $50,000<br>Daily transfer limit from ${this.env.brandName} account: $100,000`;
    } else {
      return this.internalAccounts.length > 0
        ? `Transfer funds from your existing ${this.env.brandName} account to your new ${this.env.brandName} account.`
        : `Sign in to another institution and transfer funds from an existing account to your new ${this.env.brandName} account.`;
    }
  }

  get showBankTransfer(): boolean {
    return (
      this.featureFlagService.isSBBActive() &&
      !!this.customerLimits &&
      this.depositAmount < this.customerLimits.extTransfersMaxIncomingPerPayment &&
      !this.isCdAccountType
    );
  }

  get mailCheckInstructions(): string {
    return this.isTradingAccount
      ? 'Write a check to Axos Invest. Make sure to include your Account Number in the check’s memo section.'
      : 'Write a check to yourself using another account, print a deposit slip from our website and send to our mailing address.';
  }

  get wireTransferDirections(): string {
    return `Using your name and a new account number, arrange for a wire transfer from another financial institution to your new ${this.env.brandName} account.`;
  }

  public get showDirectDeposit(): boolean {
    return this.isDirectDeposit && !this.isCdAccountType && !this.isTradingAccount && !this.isSbbAccount;
  }

  fundingMethod = FundingMethod;
  // TODO: Retrieve this limit from backend
  readonly remoteDepositLimit = 10000;

  readonly productTypeCd = 'cd';
  readonly productTypeBusinessCd = 'business cd';

  externalAccounts: ExternalAccount[];
  internalAccounts: OlbAccount[];
  private unsubscribe: Function;

  constructor(
    @Inject(olbSettings) readonly env: OlbSettings,
    @Inject(ROOT_SCOPE) private rootScope: IRootScopeService,
    @Inject(STATE) private stateService: IStateService,
    private cachedAccountsService: CachedAccountsService,
    private accountsService: AccountsService,
    private customerLimitsHelper: CustomerLimitsHelper,
    private serviceHelper: ServiceHelper,
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    private featureFlagService: FeatureFlagService,
    @Inject(STATE_PARAMS) private stateParams: IStateParamsService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /** Initializes any required data */
  ngOnInit() {
    const { accountId, depositAmount, isRunning } = this.ngRedux.getState().funding;
    this.flow = this.stateParams['flow'];
    if (!isRunning) {
      this.stateService.go('udb.dashboard');
    }

    if (accountId && depositAmount) {
      this.setAccountTypeFlags(this.accounts.find(account => account.id === accountId));
      this.depositAmount = depositAmount;
      this.step = 2;
    } else {
      this.onStateChanges();
    }
    this.brandName = String(this.env.brandName);
    this.unsubscribe = this.ngRedux.subscribe(this.onStateChanges.bind(this));
    this.checkAccountsBalancesLoaded();

    sessionStorage.setItem('fundRemembered', 'false');

    this.getCustomerLimits();

    /* callback in case balances are not available at controller init load */
    this.rootScope.$on('balancesAvailable', () => {
      this.checkAccountsBalancesLoaded();
    });

    if (sessionStorage.getItem('addExternalAccountState') == null) {
      this.accountsService.logUserAction(
        SourceFromHistroricalAction(sessionStorage.getItem('fromEnrollment') == 'true')
      );
      sessionStorage.removeItem('fromEnrollment');
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  resetDepositAmountToDefault() {
    if (this.maskedAmount === '0.00') {
      this.maskedAmount = '';
    } else if (this.maskedAmount === '') {
      this.maskedAmount = '0.00';
    }
  }

  /**
   * Event handler that is triggered when an account is selected
   * from the list of accounts to be founded
   */
  onAccountSelected(account: OlbAccount) {
    if (!account) return;

    this.setAccountTypeFlags(account);
    this.depositAmount = 0;
    if (this.isCdAccountType) this.step = 2;
    // to skip amount validation
    else this.step = 1;

    this.ngRedux.dispatch(FundingActions.setupAccountId(this.selectedAccount.id));
    this.store.dispatch(setupFundingAccountId({ payload: this.selectedAccount.id }));
  }

  /**
   * Event handler for continue button
   */
  onClickContinue() {
    if (this.step === 1) {
      if (!this.isValidAmount(true)) return;
      this.ngRedux.dispatch(FundingActions.changeAmount(this.depositAmount));
      this.store.dispatch(changeFundingAmount({ payload: this.depositAmount }));
    }
    this.step++;
  }

  /**
   * Logs the user action, changes the method selected in the store
   * and redirects to the proper page depending on the method
   * @param fundingMethod The method selected for funding
   */
  toFundingMethod(fundingMethod: FundingMethod) {
    this.ngRedux.dispatch(FundingActions.changeMethod(fundingMethod));
    this.store.dispatch(changeFundingMethod({ payload: fundingMethod }));
    this.accountsService.logUserAction(FundingInterceptAction(fundingMethod));
    switch (fundingMethod) {
      case FundingMethod.ExternalTransfer:
        if (
          (this.externalAccounts && this.externalAccounts.length) ||
          (this.internalAccounts && this.internalAccounts.length)
        ) {
          this.stateService.go('udb.funding.external-transfer');

          return;
        }
        const addExternalAccountState = this.featureFlagService.isYodleeForFundingActive()
          ? 'udb.dashboard.account-aggregation'
          : 'udb.funding.add-external-account';
        sessionStorage.setItem('addExternalAccountState', 'true');
        this.stateService.go(addExternalAccountState, { flow: this.flow });
        break;
      case FundingMethod.MailCheck:
        this.stateService.go('udb.funding.mail-check');
        break;
      case FundingMethod.RemoteDeposit:
        this.stateService.go('udb.funding.remote-deposit');
        break;
      case FundingMethod.WireTransfer:
        this.stateService.go('udb.funding.wire-transfer');
        break;
      case FundingMethod.BrokerageTransfer:
        this.stateService.go('udb.funding.brokerage-form');
        break;
      case FundingMethod.DirectDeposit:
        this.stateService.go('udb.clickswitch', { backstate: 'udb.funding' });
        break;
    }
  }

  /** Handles the action for the buttons to go back. */
  goBack() {
    if (this.step == 0 || (this.step == 1 && this.accounts.length === 1) || this.isCdAccountType) {
      this.stateService.go('udb.dashboard');

      return;
    }

    this.step--;
  }

  identifyAccountNickName(_index, account) {
    return account.nickname;
  }

  /** When init and every time the state changes this is executed reassigning the funding state */
  private onStateChanges() {
    this.resetValues();
    this.filterExternalAccounts();

    if (this.accounts.length > 1 || this.ngRedux.getState().funding.accountId) return;

    this.onAccountSelected(this.accounts[0]);
  }

  /** Validates the amount */
  isValidAmount(onSave: boolean): boolean {
    if (!this.maskedAmount || this.maskedAmount != '0.00' || onSave) {
      this.depositAmount = +this.maskedAmount;
      if (!this.depositAmount || this.depositAmount <= 0) {
        this.errorAmount = 'Please enter an amount';

        return false;
      }

      this.errorAmount = '';

      return true;
    }
  }

  /** Gets the costumer limits from the back end */
  private getCustomerLimits() {
    this.isLoading = true;
    this.customerLimitsHelper
      .fetchCustomerLimits()
      .then(res => {
        this.customerLimits = res;
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      })
      .catch(error => {
        this.serviceHelper.errorHandler(error);
        this.isLoading = false;
      });
  }

  /** Filter the active external accounts  */
  private filterExternalAccounts() {
    this.isBusy = true;
    const { externalAccounts } = this.cachedAccountsService;

    if (!externalAccounts) return;

    this.externalAccounts = externalAccounts.filter(account => account.status.toLowerCase() === 'active');
    this.isBusy = false;
  }

  /* validate that internal accounts balances have loaded to filter internal accounts for funding */
  private checkAccountsBalancesLoaded() {
    this.balancesLoaded = this.accountBalancesLoaded;

    if (this.balancesLoaded) {
      this.filterInternalAccounts();
    }
  }

  /** Filter the active internal accounts with positive balance amount  */
  private filterInternalAccounts() {
    this.isBusy = true;
    const { internalAccounts } = this.cachedAccountsService;

    if (!internalAccounts && !internalAccounts.depositAccounts) return;

    this.internalAccounts = internalAccounts.depositAccounts.filter(
      x => x.availableBalance > 0 && x.isDeposit && !x.isIra
    );

    this.isBusy = false;
  }

  /**
   * Turn on flags depending account type
   */
  private setAccountTypeFlags(account: OlbAccount) {
    if (!account) return;

    this.selectedAccount = account;
    this.isTradingAccount = account.accountType === 'trading';

    if (!this.isTradingAccount) {
      this.isCdAccountType = this.getCdAccountType(account);
    }

    this.isDirectDeposit = account.canMakeDepositSwitch && this.featureFlagService.isDirectDepositEnabled();

    this.isSbbAccount = account.isSBB;
  }

  private getCdAccountType(account: OlbAccount): boolean {
    return (
      (account.productType && account.productType.toLowerCase() === this.productTypeCd) ||
      account.productType.toLowerCase() === this.productTypeBusinessCd
    );
  }

  private resetValues() {
    if (
      this.step == 2 &&
      this.depositAmount &&
      !this.ngRedux.getState().funding.accountId &&
      this.accounts.length > 1
    ) {
      this.step = 0;
    }
  }
}
