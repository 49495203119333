import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceInProgressMatModule } from './maintenance-in-progress-mat.module';
import { MaintenanceInProgressUk2Module } from './maintenance-in-progress-uk2.module';
import { MaintenanceInProgressComponent } from './view/components/maintenance-in-progress';

@NgModule({
  declarations: [MaintenanceInProgressComponent],
  imports: [CommonModule, MaintenanceInProgressMatModule, MaintenanceInProgressUk2Module],
  exports: [MaintenanceInProgressComponent],
})
export class MaintenanceInProgressModule {}
