<div class="pre-transfer-confirmation-modal">
  <div class="modal-header hide-cs">
    <div class="frow middle center text-center">
      <i id="pre-transfer-confirmation-modal-id" class="ms-icon bofi-modal-send"></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="frow center">
      <p class="m-header">{{ headerStr }}</p>
    </div>
    <div class="frow center">
      <p class="m-amount">{{ amount }}</p>
    </div>
    <div class="field-separator"></div>
    <div class="frow m-title">
      <div class="fcol-cs-7">From Account</div>
      <div class="fcol-cs-5">To Account</div>
    </div>
    <div class="frow modal-account">
      <div class="bold" [textContent]="selectedFrom"></div>
      <div><i class="fa fa-arrow-right" aria-hidden="true"></i></div>
      <div class="bold" [textContent]="selectedTo"></div>
    </div>
    <div class="field-separator" *ngIf="footerStr"></div>
    <div class="frow center" *ngIf="footerStr">{{ footerStr }}</div>
  </div>
  <div class="modal-footer">
    <div class="ms-footer-btns frow no-wrap-sm center">
      <button
        id="pre-transfer-confirmation-modal-cancel-button"
        type="button"
        class="uk-btn outline primary sm mb-3"
        (click)="close()"
      >
        Cancel
      </button>
      <button
        id="pre-transfer-confirmation-modal-confirm-button"
        type="button"
        class="uk-btn solid secondary sm"
        (click)="confirm()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
