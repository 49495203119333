import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { initialModelState, modelsReducer, ModelsSummaryService } from './core';
import { ModelsFacade } from './facade';
import { ModelsMatModule } from './models-mat.module';
import { ModelsUk2Module } from './models-uk2.module';
import { ModelsTileComponent } from './view/models-tile.component';

import { UnderstandingModelsModalComponent } from './view/components/understanding-models-modal/understanding-models-modal.component';

@NgModule({
  declarations: [ModelsTileComponent, UnderstandingModelsModalComponent],
  providers: [ModelsSummaryService, ModelsFacade],
  imports: [
    CommonModule,
    HttpClientModule,
    ModelsMatModule,
    ModelsUk2Module,
    StoreModule.forFeature(AasStoreFeatures.Models, modelsReducer, {
      initialState: initialModelState,
    }),
  ],
  exports: [ModelsTileComponent],
})
export class ModelsModule {}
