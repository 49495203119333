<div class="frow text-center">
  <div class="fcol-cs-12 signature-card-actions">
    <p>
      Please download and sign this signature card. Each applicant must sign the signature card.
    </p>
    <p>
      <img
        src="/img/fileicons/icn_document_pdf.svg"
        alt=""
        src="/img/fileicons/icn_document_pdf.svg"
      />
      <a class="link" (click)="getSigCardTemplate()">Download Signature Card</a>
    </p>
  </div>
  <div class="fcol-cs-12 signature-card-actions">
    <p>How would you like to submit your signed signature card?</p>
  </div>
  <div class="signature-card fcol-cs-12 signature-card-actions">
    <button id="btn-upload-card" type="button" (click)="goToUploadCard()" class="uk-btn outline primary lg">
      Upload Card
    </button>
    <button id="btn-send-by-mail" type="button" (click)="goToSendMail()" class="uk-btn outline primary lg">
      Send by Mail
    </button>
    <button id="btn-unable-to-download" type="button" (click)="goToUnableDownload()" class="uk-btn outline primary lg">
      Unable to download
    </button>
  </div>
</div>