<div class="bill-pay-options">
  <h3>Pay bills with business accounts</h3>
  <p>
    Set up your first payment to pay bills with your Business accounts.
  </p>
  <div class="tile-actions">
    <button 
      type="button" 
      class="uk-btn solid secondary lg" 
      (click)="payBillsFromBusinessAccounts()">
      <span>Pay Bills</span>
    </button>
  </div>
</div>
