import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { IStateParamsService, IStateService } from 'angular-ui-router';
import { Inject } from 'decorators/decorators';

import { LearnMoreTransfersModalComponent } from '@app/transfers/components/modals';
import { AlertsIcons } from '@shared/enums';

@Inject('$scope', '$state', '$stateParams', 'matDialog')
export class TransferFundsController {
  showError = false;
  badRequestError: string;
  fromAccountId: number;
  toAccountId: number;
  isMoveMoneyFlow: false;
  step = 1;
  questionIcon = AlertsIcons.QuestionCircle;

  private _toParams: any;
  private _toState: string;
  private _allowRedirection: boolean;

  constructor(
    private $scope: ng.IScope,
    private state: IStateService,
    private stateParams: IStateParamsService,
    private matDialog: MatDialog
  ) {}

  /** Initializes any controller required data. */
  $onInit(): void {
    this._allowRedirection = false;
    this._toParams = {};
    this._toState = '';
    this.fromAccountId = this.stateParams['fromAccountId'];
    this.toAccountId = this.stateParams['toAccountId'];
    this.isMoveMoneyFlow = this.stateParams['isMoveMoneyFlow'];

    this.$scope.$on('$stateChangeStart', (event, _toState, _toParams, _fromState) => {
      this._toState = _toState.name;
      this._toParams = _toParams;
      if (_fromState.name == 'udb.transfers.transferFunds' && !this._allowRedirection) {
        event.preventDefault();
        this.$scope.$broadcast('transferFundsHasChanges');
      }
    });

    this.$scope.$on('changeStateFromTransfers', () => {
      this._allowRedirection = true;
      this.state.go(this._toState, this._toParams);
    });
  }

  showLearnMoreModal(): void {
    this.matDialog.open(LearnMoreTransfersModalComponent);
  }
}
