<app-dot-loader *ngIf="buildingSlider"></app-dot-loader>
<div [hidden]="buildingSlider">
  <div class="row allocation-values">
    <div class="col-6">
      <p>Stocks</p>
    </div>
    <div class="col-6 text-right">
      <p>Bonds</p>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <p>{{ form.get('stockAllocation').value }} %</p>
    </div>
    <div class="col-6 text-right">
      <p>{{ form.get('bondAllocation').value }} %</p>
    </div>
  </div>
  <input
    class="allocation-slider"
    [formControl]="control"
    type="range"
    min="1"
    [max]="availableSteps"
    style="--webkitProgressPercent: {{ percentage }}"
    step="1"
    id="allocationInputSlider"
  />
</div>
