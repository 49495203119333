import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q')
class UserPreferenceService extends BaseService implements IUserPreferenceService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'preferences', _serviceHelper, q);
  }
}

export { UserPreferenceService };
