import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IPrivacyPreferencesService } from './typings/IPrivacyPreferencesService';

@Inject('$http', 'env', 'serviceHelper', '$q')
class PrivacyPreferencesService extends BaseService implements IPrivacyPreferencesService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'privacy', _serviceHelper, q);
  }

  public getPrivacyPreferences(): ApiResponse<any> {
    return this.get('');
  }

  public savePrivacyPreferences(
    creditInfo: boolean,
    marketInfo: boolean,
    nonAffiliates: boolean
  ): ApiResponse<any> {
    return this.put(creditInfo + '/' + marketInfo + '/' + nonAffiliates);
  }

  public getPrivacyUrl(brandId: number) {
    return this.get(`${brandId}/privacyUrl`);
  }
}

export { PrivacyPreferencesService };
