import { AccountsSharedModule } from './accounts-shared/accounts-shared.module';
import { DebitCardModule } from './debit-card/debit-card.module';
import { ExternalAccountsModule } from './external-accounts/external-accounts.module';
import { LoansModule } from './loans/loans.module';
import { SignatureCardModule } from './signature-card/signature-card.module';
import { TransactionsModule } from './transactions/transactions.module';

export {
  AccountsSharedModule,
  DebitCardModule,
  ExternalAccountsModule,
  LoansModule,
  SignatureCardModule,
  TransactionsModule,
};

export const accountSubmodules = [
  AccountsSharedModule,
  DebitCardModule,
  ExternalAccountsModule,
  LoansModule,
  SignatureCardModule,
  TransactionsModule,
];
