import { TransactionsAggController } from './transactions-details-agg.controller';

export const TransactionsAggComponent: ng.IComponentOptions = {
  controller: TransactionsAggController,
  controllerAs: '$ctrl',
  templateUrl: 'accounts/external-accounts/transactions-details/transactions-details-agg.tpl.html',
  bindings: {
    aggregatedAccount: '<',
    onTransactionOperation: '&?',
  },
};
