import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AasDataTileListRowTypeEnum } from '@app/Areas/AAS/aas-shared/components';
import { AccountFirmContactsStateType } from '../../../core';
import { AccountDetailsFacade } from '../../../facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'aas-account-firm-contacts-tab',
  templateUrl: './account-firm-contacts-tab.component.html',
  styleUrls: ['./account-firm-contacts-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountFirmContactsTabComponent implements OnInit {
  accountFirmContacts$: Observable<AccountFirmContactsStateType>;
  aasDataTileListRowTypeEnum = AasDataTileListRowTypeEnum;

  constructor(private accountDetailsFacade: AccountDetailsFacade) {}

  ngOnInit(): void {
    this.accountFirmContacts$ = this.accountDetailsFacade.accountFirmContacts$;
  }
}
