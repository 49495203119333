<!-- Checking -->
<app-account-group
  *ngIf="accountsToDisplay.checking.length > 0 && !(calculatingHistoricalBal$ | async)"
  [groupTitle]="'CHECKING'"
  [accounts]="accountsToDisplay.checking"
  [providers]="providers"
  [historicalBalances]="getHistoricBalancesByAccountType(categoryTypeEnum.Checking)"
  [displayHeader]="headersToDisplayByCategory === categoryTypeEnum.Checking"
  [excludedAccountsId]="excludedAccountsId"
></app-account-group>
<!-- Saving -->
<app-account-group
  *ngIf="accountsToDisplay.saving.length > 0 && !(calculatingHistoricalBal$ | async)"
  [groupTitle]="'SAVINGS'"
  [accounts]="accountsToDisplay.saving"
  [providers]="providers"
  [historicalBalances]="getHistoricBalancesByAccountType(categoryTypeEnum.Savings)"
  [displayHeader]="headersToDisplayByCategory === categoryTypeEnum.Savings"
  [excludedAccountsId]="excludedAccountsId"
></app-account-group>
<!-- Investing -->
<app-account-group
  *ngIf="accountsToDisplay.investing.length > 0 && !(calculatingHistoricalBal$ | async)"
  [groupTitle]="'INVESTMENTS'"
  [accounts]="accountsToDisplay.investing"
  [providers]="providers"
  [historicalBalances]="getHistoricBalancesByAccountType(categoryTypeEnum.Investment)"
  [displayHeader]="headersToDisplayByCategory === categoryTypeEnum.Investment"
  [excludedAccountsId]="excludedAccountsId"
></app-account-group>
<!-- Credit cards -->
<app-account-group
  *ngIf="accountsToDisplay.creditCard.length > 0 && !(calculatingHistoricalBal$ | async)"
  [groupTitle]="'CREDIT CARDS'"
  [accounts]="accountsToDisplay.creditCard"
  [providers]="providers"
  [historicalBalances]="getHistoricBalancesByAccountType(categoryTypeEnum.CreditCards)"
  [displayHeader]="headersToDisplayByCategory === categoryTypeEnum.CreditCards"
  [excludedAccountsId]="excludedAccountsId"
></app-account-group>
<!-- Loan -->
<app-account-group
  *ngIf="accountsToDisplay.loan.length > 0 && !(calculatingHistoricalBal$ | async)"
  [groupTitle]="'LOANS'"
  [accounts]="accountsToDisplay.loan"
  [providers]="providers"
  [historicalBalances]="getHistoricBalancesByAccountType(categoryTypeEnum.Loans)"
  [displayHeader]="headersToDisplayByCategory === categoryTypeEnum.Loans"
  [excludedAccountsId]="excludedAccountsId"
></app-account-group>
<!-- Other: category removed by pbi: 804711 -->
