import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';

import { STATE, STATE_PARAMS } from '@core/tokens';

import { DebitCard } from '../../models';

@Component({
  selector: 'app-debit-card-view',
  templateUrl: './debit-card-view.component.html',
  styleUrls: ['./debit-card-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebitCardViewComponent implements OnChanges {
  @Input() cards = [];

  debitCards: DebitCard[] = [];
  selectedCardIndex = 0;
  isLoading = true;

  constructor(
    @Inject(STATE_PARAMS) private stateParams: ng.ui.IStateParamsService,
    @Inject(STATE) private stateService: ng.ui.IStateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('cards')) {
      this.parseInputCards(changes.cards.currentValue);
    }
  }

  handleUpdatedCard(card: DebitCard) {
    this.debitCards = this.debitCards.map(el => (el.cardNumberMask === card.cardNumberMask ? card : el));
  }

  selectCard(debitCardIndex: number) {
    this.selectedCardIndex = debitCardIndex;
  }

  /*   TODO: Once Migrated router
   *   update this method to use angular router instead IStateService
   */
  routeRedirect(path: string) {
    const card = this.cards[this.selectedCardIndex];

    return this.stateService.go(path, {
      id: card.accountId,
      cardId: card.debitCardId,
      card,
    });
  }

  private parseInputCards(cards: any[]) {
    const { debitCardId } = this.stateParams;
    const initialValue: DebitCard[] = [];
    const debitCards = cards.reduce((acc, card, i) => {
      const debitCard = new DebitCard(card);

      if (`${debitCard.debitCardId}` === `${debitCardId}`) this.selectCard(i);

      acc.push(debitCard);

      return acc;
    }, initialValue);

    this.debitCards = debitCards;
    this.isLoading = false;
  }
}
