import { createAction, props } from '@ngrx/store';
import {
  AccountPeopleTabState,
  AddEditInterestedPartyState,
  AddEditTrustedContactState,
  DeleteTrustedContactState,
} from './types';

export const addUpdateAccountPeopleAction = createAction(
  '[ACCOUNT-PEOPLE-TAB] Add - Update or add a account people tab',
  props<{ payload: AccountPeopleTabState }>()
);

export const addUpdateTrustedContactAction = createAction(
  '[ACCOUNT-PEOPLE-TAB-TRUSTED-CONTACT] Add/Update Trusted Contact',
  props<{ payload: AddEditTrustedContactState }>()
);

export const deleteTrustedContactAction = createAction(
  '[ACCOUNT-PEOPLE-TAB-TRUSTED-CONTACT] Delete Trusted Contact',
  props<{ payload: DeleteTrustedContactState }>()
);

export const addUpdateInterestedPartyAction = createAction(
  '[ACCOUNT-PEOPLE-TAB-INTERESTED-PARTY] Add/Update Interested Party',
  props<{ payload: AddEditInterestedPartyState }>()
);
