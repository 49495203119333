<div class="step-top-actions">
  <button
    uk2LabeledIconButton
    mat-button
    disableRipple
    cdkStepperPrevious
    [disabled]="hasAcceptedDisclosures"
  >
    Back
    <mat-icon [svgIcon]="uk2Tier1NavigationEnum.chevronLeft"></mat-icon>
  </button>
</div>
<div class="step-content">
  <div id="header">
    <img src="../../../../../../../assets/axos/img/icons/x_icon.svg" />
    <span>Cash Management Checking Account - Individual</span>
  </div>
  <h1 id="title">
    Please review the following disclosures
  </h1>
  <p id="text">
    Please take a moment to read and agree to the necessary disclosures for
    opening your new account. This step ensures you are fully informed about
    your account details and terms.
  </p>

  <!-- prettier-ignore -->
  <form id="disclosures-container" [formGroup]="disclosuresFormGroup">
    <div id="e-disclosure-container" class="uk2-checkbox-container">
      <mat-checkbox uk2Checkbox disableRipple formControlName="eDisclosure"></mat-checkbox>
      <label>
        I confirm that I have read, understand, and agree to the <a uk2Anchor (click)="openEDisclosureStatement()">E-Disclosure Statement</a>.
      </label>
    </div>
    <div id="personal-deposit-account-disclosure-container" class="uk2-checkbox-container">
      <mat-checkbox uk2Checkbox disableRipple formControlName="personalDepositAccountDisclosure"></mat-checkbox>
      <label>
        I confirm that I have read, understand, and agree to the <a uk2Anchor (click)="openPersonalDepositAccountAgreement()">Personal Deposit Account Agreement</a>.
      </label>
    </div>
    <div id="consumer-privacy-disclosure-container" class="uk2-checkbox-container">
      <mat-checkbox uk2Checkbox disableRipple formControlName="consumerPrivacyDisclosure"></mat-checkbox>
      <label>
        I confirm that I have read, understand, and agree to the <a uk2Anchor (click)="openPrivacyNotice()">Privacy Notice</a>.
      </label>
    </div>
  </form>

  <mat-card uk2NoteCard id="fair-credit-container">
    <mat-card-content>
      <label>
        <span class="strong-text">Fair Credit Reporting Act</span>
        : By accepting and submitting this application, you agree to the Terms
        and Conditions and to receiving information about additional Axos
        products and services that may be available to you. You understand that
        by submitting this application, you are providing "written instructions"
        to Axos under the Fair Credit Reporting Act to obtain information from
        your personal credit profile or other information from a credit
        reporting agency chosen by Axos.
      </label>
      <label class="fair-credit-text-separation">
        You authorize Axos to obtain such information solely to confirm your
        identity to avoid fraudulent transactions in your name and to prequalify
        you for the purpose of offering available banking products and services.
      </label>
    </mat-card-content>
  </mat-card>
</div>
<div class="step-bottom-actions">
  <mat-divider></mat-divider>
  <button
    uk2PrimaryButton
    disableRipple
    mat-raised-button
    [disabled]="disclosuresFormGroup.invalid || hasAcceptedDisclosures"
    [uk2IsLoading]="hasAcceptedDisclosures"
    (click)="updateAcceptanceState()"
  >
    Submit
  </button>
</div>
