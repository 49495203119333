<form class="modal-container">
  <div class="modal-title"><h1>Wait, before you go...</h1></div>

  <img
    mat-button
    [mat-dialog-close]="true"
    src="../../../../assets/img/icons/sbloc/primary.png"
    class="primary-close"
    alt="close"
  />
  <hr />
  <div class="modal-body">
    <p class="modal-body-tittle">Open a Securities Backed Line of Credit account and receive these benefits.</p>
    <div class="modal-content">
      <div>
        <img src="../../../../../assets/img/icons/sbloc/line-graph.png" class="logo" alt="line" />
        <img src="../../../../../assets/img/icons/sbloc/line-graph.png" class="logo-responsive" alt="line" />
        <h2>Keep your Investment Strategy</h2>
        <p>Liquidity without selling your investment assets.</p>
      </div>

      <div>
        <img src="../../../../../assets/img/icons/sbloc/bank.png" class="logo" alt="line" />
        <img src="../../../../../assets/img/icons/sbloc/bank.png" class="logo-responsive" alt="line" />
        <h2>Avoid Capital Gain Tax</h2>
        <p>Defer the tax liabilty until you sell your securities.</p>
      </div>

      <div>
        <img src="../../../../../assets/img/icons/sbloc/coin-stack.png" class="logo" alt="line" />
        <img src="../../../../../assets/img/icons/sbloc/coin-stack.png" class="logo-responsive" alt="line" />
        <h2>Cost Effective</h2>
        <p>Receive lower rates than you normally would on a loan.</p>
      </div>
    </div>
  </div>
  <hr />
  <div class="modal-footer">
    <button class="PrimaryButton responsive">Email me Enrollment Documents</button>
    <button mat-button mat-dialog-close routerLink="/Dashboard" class="TextButton" (click)="NotRightNow()">
      Not Right Now
    </button>

    <button
      class="PrimaryButton full"
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="emailMeEnrollmentDocuments()"
      id="btnSendEmail"
    >
      Email me Enrollment Documents
    </button>
  </div>
</form>
