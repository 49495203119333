import { Pipe, PipeTransform } from '@angular/core';

import { phoneToString } from '@app/utils';

import { Phone } from '../models';

@Pipe({
  name: 'phoneToString',
})
export class PhoneToStringPipe implements PipeTransform {
  transform(phone: Partial<Phone>): string {
    return phoneToString(phone);
  }
}
