import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';
import { differenceInDays, format, isAfter, isBefore, parseISO } from 'date-fns';

import { CHART_CONFIGURATION } from '@app/axos-invest/constants';
import { ChartConfiguration, HistoricOverviewGoal, PastPerformanceHistoricalItem } from '@app/axos-invest/models';
import { AbbreviateNumber } from '@app/utils';

@Component({
  selector: 'app-historic-performance-chart',
  templateUrl: './historic-performance-chart.component.html',
  styleUrls: ['./historic-performance-chart.component.scss'],
  providers: [CurrencyPipe],
})
export class HistoricPerformanceChartComponent implements OnInit, AfterViewInit {
  @Input() data: HistoricOverviewGoal[];
  @Input() updateData: EventEmitter<void>;

  @ViewChild('chartCanvas') chartCanvas: ElementRef;

  chart: Chart;

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit() {
    this.updateData?.subscribe(() => this.calculateTimelapse());
  }

  ngAfterViewInit(): void {
    this.calculateTimelapse();
  }

  private calculateTimelapse() {
    const historicData = this.data.filter(goal => goal.checked).map(goal => goal.historicData);
    let oldestDate = new Date();
    let newestDate: Date = null;
    let largestDataSet: PastPerformanceHistoricalItem[] = [];

    historicData.forEach(item => {
      const firstItemDate = parseISO(item.historicalResponse[0].date);
      oldestDate = isBefore(firstItemDate, oldestDate) ? firstItemDate : oldestDate;

      const lastItemDate = parseISO(item.historicalResponse.slice(-1).pop().date);
      newestDate = !newestDate || isAfter(lastItemDate, newestDate) ? lastItemDate : newestDate;

      largestDataSet =
        item.historicalResponse.length > largestDataSet.length ? item.historicalResponse : largestDataSet;
    });

    const difference = differenceInDays(newestDate, oldestDate);

    const configuration = CHART_CONFIGURATION.find(
      config => difference >= config.min && (difference < config.max || config.max === -1)
    );
    const dataLabels: string[] = [];
    const tooltipLabels: string[] = [];
    const data: number[] = [];

    for (let i = largestDataSet.length - 1; i >= 0; i -= configuration.jumpDays) {
      const day = largestDataSet[i];
      const labels = this.setupLabels(day, configuration);
      dataLabels.push(labels.data);
      tooltipLabels.push(labels.time);

      const totalValue = this.calculateTotalValueForDate(day.date);
      data.push(totalValue);
    }

    const operation = this.chart ? this.updateChart : this.createChart;
    operation.bind(this)(data.reverse(), dataLabels.reverse(), tooltipLabels.reverse());
  }

  private setupLabels(
    day: PastPerformanceHistoricalItem,
    configuration: ChartConfiguration
  ): { data: string; time: string } {
    return {
      data: format(parseISO(day.date), configuration.dataLabelFormat),
      time: configuration.timeLabelFormat
        ? configuration.timeLabel.replace('${date}', format(parseISO(day.date), configuration.timeLabelFormat))
        : configuration.timeLabel,
    };
  }

  private calculateTotalValueForDate(date: string) {
    const historicData = this.data.filter(goal => goal.checked).map(goal => goal.historicData);
    let totalValue = 0;
    historicData.forEach(item => {
      const dataSet = item.historicalResponse.find(t => t.date === date);
      if (dataSet) totalValue += dataSet.close;
    });

    return totalValue;
  }

  private createChart(data: number[], dataLabels: string[], tooltipLabels: string[]) {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, '#60a3ccc9');
    gradient.addColorStop(1, '#FFFFFFa3');

    this.chart = new Chart(this.chartCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: dataLabels,
        datasets: [
          {
            data,
            borderColor: '#4d85b0',
            borderWidth: 2,
            fill: true,
            pointRadius: 0,
            pointHitRadius: 9.5,
            pointHoverBackgroundColor: '#4d85b0',
            pointHoverBorderWidth: 0,
            pointHoverRadius: 12,
            backgroundColor: gradient,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 10,
          },
        },
        responsive: true,
        stacked: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                callback: (value: string): string => {
                  return '$' + AbbreviateNumber(Number(value), 2);
                },
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#ffffff',
          xPadding: 25,
          yPadding: 20,
          caretPadding: 20,
          caretSize: 10,
          borderColor: '#cac9c9',
          borderWidth: 1,
          titleFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          titleFontColor: '#000',
          titleFontStyle: 'bold',
          titleMarginBottom: 15,
          titleFontSize: 14,
          bodyFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          bodyFontSize: 12,
          bodyFontColor: '#000',
          footerFontSize: 20,
          footerFontColor: '#466d95',
          footerFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          footerMarginTop: 15,
          displayColors: false,
          callbacks: this.setupTooltipCallback(tooltipLabels),
        },
      },
    });
  }

  private updateChart(data: number[], dataLabels: string[], tooltipLabels: string[]) {
    this.chart.data.labels = dataLabels;
    this.chart.data.datasets[0].data = data;
    this.chart.options.tooltips.callbacks = this.setupTooltipCallback(tooltipLabels);
    this.chart.update();
  }

  private setupTooltipCallback(tooltipLabels: string[]) {
    return {
      title: (tooltip: TooltipItem[], tooltipData: any): string => {
        return tooltipData.labels[tooltip[0].index];
      },
      label: (tooltip: TooltipItem): string => {
        return tooltipLabels[tooltip.index];
      },
      footer: (tooltip: TooltipItem[], tooltipData: any): string => {
        return this.currencyPipe.transform(tooltipData.datasets[tooltip[0].datasetIndex].data[tooltip[0].index]);
      },
    };
  }
}
