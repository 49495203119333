import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { SwitchActions } from '@app/click-switch/enums';
import { SubmitTarget, Switch, TargetSearchResponse } from '@app/click-switch/models';
import { SwitchDetail } from '@app/user-profile/models';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { UserAction } from '@legacy/typings/app/UserAction';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class DirectDepositService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'directdeposit');
  }

  getSearchTargets(target: string): WebApiResponse<TargetSearchResponse> {
    const params = new HttpParams().set('target', target);

    return this.get('target/search', params);
  }

  createSwitch(target: Partial<SubmitTarget>): WebApiResponse<Switch> {
    return this.post('createswitch', target);
  }

  updateSwitch(depositSwitch: Partial<Switch>): WebApiResponse<Switch> {
    return this.post('updateswitch', depositSwitch);
  }

  confirmSwitch(index: number, action: SwitchActions): WebApiResponse<Switch> {
    return this.put(`confirmswitch/${index}?action=${action}`);
  }

  getSwitch(index: number): WebApiResponse<Switch> {
    return this.get<Switch>(`getswitch/${index}`);
  }

  getCustomerSwitches() {
    return this.get<SwitchDetail[]>('switches/summary');
  }

  deleteSwitch(index: number): WebApiResponse<Switch> {
    return this.put(`deleteswitch/${index}`);
  }

  getSwitchAsPDF(index: number) {
    return this.get<string>(`switch/pdf/${index}`);
  }

  logUserAction(action: UserAction): WebApiResponse<void> {
    return this.post('audit', action);
  }
}
