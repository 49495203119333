<div>
  <div class="error-message-div" *ngIf="isInvalid">
    <i class="bofi-close red-icon" (click)="isInvalid = false"></i>
    <p class="error-paragraph">
      <i class="bofi-warning red-icon"></i>
      {{ errorMsg }}
    </p>
  </div>

  <!-- displayed when responsive mode is active  -->
  <div id="responsive" class="panel hide-sm hide-md hide-lg">
    <!-- *ngIf="alert"
  [ngClass]="{'isnew': alert.isNew}" -->
    <form
      name="alertBox"
      #alertBox="ngForm"
      class="alertBox"
      novalidate
      (ngSubmit)="submitAlert(alert, alertBox)"
      *ngIf="!deleteMsg"
    >
      <div class="frow addAlert middle header">
        <div class="icon-box">
          <img [src]="'/img/icons/alerts/' + alert.icon + '.svg'" [alt]="alert.title + 'icon'" />
        </div>
        <div>
          <p class="title">{{ alert.title }}</p>
        </div>
        <div>
          <div class="bofi-remove" (click)="removeAlert(alert)"></div>
        </div>
      </div>
      <div class="frow addAlert middle">
        <div class="description-box">
          <div class="alert-content">
            <div
              [ngClass]="alert.type === 'number' || alert.type === 'money' ? 'description-container' : 'alert-content'"
            >
              <div class="text-description-left">
                <app-frequency-selector
                  (click)="footerButtons = true"
                  *ngIf="alert.type === 'frequency'"
                  [alertUI]="alert"
                ></app-frequency-selector>
                <span>
                  {{ alert.description }}
                  <span *ngIf="alert.type === 'text'" tabindex="0" class="no-focus">
                    .
                  </span>
                </span>
                <span class="accounts">
                  <!--  nested-elem-class="uikit-click-dropdown__selected-item" -->
                  <app-click-dropdown
                    (click)="footerButtons = true"
                    [dropdownItems]="ddlAccounts"
                    (item)="onSelect($event)"
                    [selectedItem]="selectedItem"
                  ></app-click-dropdown>
                </span>
                <span>{{ alert.description2 }}</span>
                <!-- input shown threshold as dates -->
                <div class="date-option" *ngIf="alert.type === 'date'">
                  <div class="input--primary">
                    <input
                      type="text"
                      [(ngModel)]="alert.alert.threshold"
                      pattern="^[0-9]*$"
                      placeholder="0"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      maxlength="2"
                      required
                    />
                  </div>
                  <span>days.</span>
                  <p class="input-error-msg" *ngIf="alertBox.submitted && alertBox.invalid">
                    Invalid number of days
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="alert.type === 'number' || alert.type === 'money'" class="amount-container">
              <!-- input shown for amount -->
              <div class="input-value" *ngIf="alert.type === 'money'">
                <div class="input--primary">
                  <input
                    type="text"
                    placeholder="0.00"
                    amount
                    name="money"
                    [(ngModel)]="alert.alert.threshold"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
                    maxlength="10"
                    required
                  />
                  <p class="input-error-msg" *ngIf="alertBox.submitted && alertBox.invalid">
                    Invalid Amount
                  </p>
                  <span class="placeholder-text">$</span>
                </div>
              </div>
              <!-- input shown for check number -->
              <div class="input-value" *ngIf="alert.type === 'number'">
                <div class="input--primary">
                  <input
                    type="text"
                    placeholder="#"
                    [(ngModel)]="alert.alert.threshold"
                    pattern="^[0-9]*$"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    maxlength="6"
                    required
                  />
                  <p class="input-error-msg" *ngIf="alertBox.submitted && alertBox.invalid">
                    Invalid check number
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="frow addAlert middle">
        <div [ngClass]="{ boxes50: !showPNs, boxes: showPNs }">
          <input
            type="checkbox"
            class="checkbox"
            id="emailalert{{ alert.id }}"
            [(ngModel)]="alert.alert.sendConfirmationEmail"
            name="sendConfirmationEmail"
            (change)="setUpEmail()"
          />
          <label for="emailalert{{ alert.id }}">Email</label>
        </div>
        <div [ngClass]="{ boxes50: !showPNs, boxes: showPNs }">
          <input
            type="checkbox"
            class="checkbox"
            id="textalert{{ alert.id }}"
            [(ngModel)]="smsEnabled"
            name="smsEnabled"
            (change)="setUpSMS(smsEnabled)"
            [disabled]="isSmsCheckBoxDisabled"
          />
          <label for="textalert{{ alert.id }}">Text</label>
        </div>
        <div class="boxes" *ngIf="showPNs">
          <input
            type="checkbox"
            class="checkbox"
            [(ngModel)]="alert.alert.pushNotification"
            name="pushNotification"
            (change)="popupPushNotification()"
            [disabled]="isPushCheckBoxDisabled"
            id="pushalert{{ indexAlert }}"
          />

          <label for="pushalert{{ indexAlert }}">Push</label>
        </div>
      </div>
      <div *ngIf="showTradingDisclaimer(alert)" class="trading-disclaimer">
        <span>Text alerts are not currently available for Self-Directed Trading Accounts.</span>
      </div>
      <div class="addAlert-footer middle" [ngClass]="{ 'open-box': alert.editMode, 'close-box': !alert.editMode }">
        <div class="footer-buttons-container frow" *ngIf="!savingAlert && !(savingAlert$ | async)">
          <button class="uk-btn outline primary sm" type="button" (click)="cancelAlert()">
            Cancel
          </button>
          <button class="uk-btn sm solid secondary" type="submit">Save</button>
        </div>
        <app-dot-loader *ngIf="savingAlert$ | async"></app-dot-loader>
      </div>
    </form>
    <div class="delete-message-box" *ngIf="!deleteMsg">
      <div *ngIf="!alert.deletingAlert">
        <p>
          Are you sure you want to remove this
          <span>{{ alert.title }}</span>
          alert?
        </p>
        <div class="buttons-container">
          <button type="button" class="uk-btn sm solid secondary" (click)="removeAlertEmit(alert)">
            Yes
          </button>
          <button class="uk-btn outline primary sm" (click)="deleteMsg = false" type="button">
            No
          </button>
        </div>
      </div>
      <app-dot-loader *ngIf="alert.deletingAlert"></app-dot-loader>
    </div>
  </div>

  <!-- displayed when responsive mode is inactive  -->
  <div id="nonresponsive" class="panel hide-cs hide-xs" *ngIf="alert" [ngClass]="{ isnew: alert.isNew }">
    <form
      #alertBox="ngForm"
      name="alertBox"
      class="alertBox"
      novalidate
      (ngSubmit)="submitAlert(alert, alertBox)"
      *ngIf="!deleteMsg"
    >
      <div class="frow addAlert middle">
        <div class="alert-title fcol-md-12">
          <div class="icon-box">
            <img [src]="'/img/icons/alerts/' + alert.icon + '.svg'" [alt]="alert.title + 'icon'" />
          </div>
          <div class="description-box">
            <div class="alert-content">
              <p class="title">{{ alert.title }}</p>
              <div
                [ngClass]="
                  alert.type === 'number' || alert.type === 'money' ? 'description-container' : 'alert-content'
                "
              >
                <span class="text-description-left">
                  <span>
                    <app-frequency-selector
                      (click)="footerButtons = true"
                      *ngIf="alert.type === 'frequency'"
                      [alertUI]="alert"
                    ></app-frequency-selector>
                  </span>
                  <p class="fluid-element">
                    {{ alert.description }}
                    <span *ngIf="alert.type === 'text'" tabindex="0" class="no-focus">
                      .
                    </span>
                  </p>
                  <span nested-elem-class="uikit-click-dropdown__selected-item">
                    <app-click-dropdown
                      (click)="footerButtons = true"
                      *ngIf="alert.type !== 'text'"
                      [dropdownItems]="ddlAccounts"
                      [selectedItem]="selectedItem"
                      (item)="onSelect($event)"
                    ></app-click-dropdown>
                  </span>
                  <p class="fluid-element">&nbsp;{{ alert.description2 }}</p>
                  <!-- input shown threshold as dates -->
                  <div class="date-option" *ngIf="alert.type === 'date'">
                    <div class="input--primary">
                      <input
                        type="text"
                        name="inputDate"
                        aria-label="Days"
                        [(ngModel)]="alert.alert.threshold"
                        pattern="^[0-9]*$"
                        placeholder="0"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        maxlength="2"
                        required
                      />
                    </div>
                    <span>days.</span>
                    <p class="input-error-msg" *ngIf="alertBox.submitted && alertBox.invalid">
                      Invalid number of days
                    </p>
                  </div>
                </span>
              </div>
              <div *ngIf="alert.type === 'number' || alert.type === 'money'" class="amount-container">
                <!-- input shown for check number -->
                <div class="input-value" *ngIf="alert.type === 'number'">
                  <div class="input--primary">
                    <input
                      aria-label="check number"
                      name="inputCheckNumber"
                      type="text"
                      placeholder="#"
                      [(ngModel)]="alert.alert.threshold"
                      pattern="^[0-9]*$"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      maxlength="6"
                      required
                    />
                    <p class="input-error-msg" *ngIf="alertBox.submitted && alertBox.invalid">
                      Invalid check number
                    </p>
                  </div>
                </div>
                <!-- input shown for amount -->
                <div class="input-value" *ngIf="alert.type === 'money'">
                  <div class="input--primary">
                    <input
                      aria-label="Amount"
                      type="text"
                      placeholder="0.00"
                      amount
                      value="{{ threshold | currency: 'USD':'' }}"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
                      maxlength="10"
                      required
                      (change)="setAmount($event.target.value)"
                    />
                    <p class="input-error-msg" *ngIf="alertBox.submitted && alertBox.invalid">
                      Invalid Amount
                    </p>
                    <span class="placeholder-text">$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            type="checkbox"
            class="checkbox"
            id="emailalert{{ indexAlert }}"
            [(ngModel)]="alert.alert.sendConfirmationEmail"
            name="sendConfirmationEmail"
            (change)="setUpEmail()"
          />
          <label for="emailalert{{ indexAlert }}">Email</label>
          <input
            type="checkbox"
            class="checkbox"
            id="textalert{{ indexAlert }}"
            name="smsEnabled"
            [(ngModel)]="smsEnabled"
            (change)="setUpSMS(smsEnabled)"
            [disabled]="isSmsCheckBoxDisabled"
          />
          <label for="textalert{{ indexAlert }}">Text</label>
          <input
            type="checkbox"
            class="checkbox"
            id="pushalert{{ indexAlert }}"
            [(ngModel)]="alert.alert.pushNotification"
            (change)="popupPushNotification()"
            name="pushNotification"
            *ngIf="showPNs"
            [disabled]="isPushCheckBoxDisabled"
          />
          <label for="pushalert{{ indexAlert }}" *ngIf="showPNs">Push</label>
          <div class="bofi-remove" (click)="removeAlert(alert)"></div>
        </div>
        <div *ngIf="showTradingDisclaimer(alert)" class="trading-disclaimer">
          <span>Text alerts are not currently available for Self-Directed Trading Accounts.</span>
        </div>
      </div>
      <div class="addAlert-footer">
        <div class="footer-buttons-container" *ngIf="footerButtons">
          <button
            class="uk-btn outline primary sm"
            type="button"
            (click)="cancelAlert()"
          >
            <!-- [ngClass]="$mac.env.brand == 'netbank' ? 'outline-netbank' : 'outline'" -->

            Cancel
          </button>
          <button class="uk-btn sm solid secondary" type="submit">Save</button>
        </div>
        <app-dot-loader *ngIf="savingAlert$ | async"></app-dot-loader>
      </div>
    </form>
    <div class="delete-message-box" *ngIf="deleteMsg">
      <div *ngIf="!alert.deletingAlert">
        <p>
          Are you sure you want to remove this
          <span>{{ alert.title }}</span>
          alert?
        </p>
        <div class="buttons-container">
          <button type="button" class="uk-btn sm solid secondary" (click)="removeAlertEmit(alert)">
            Yes
          </button>
          <button class="uk-btn outline primary sm" (click)="deleteMsg = false" type="button">
            No
          </button>
        </div>
      </div>
      <app-dot-loader *ngIf="alert.deletingAlert"></app-dot-loader>
    </div>
  </div>
</div>
