export class LegacyRoute {
  /** AngularJS UI Router state. */
  state: string;
  // ? Params marked as any because you can pass literally anything.
  /** Any additional parameters for the AngularJS route. */
  params: any;

  constructor(args?: Partial<LegacyRoute>) {
    Object.assign(this, args);
  }
}
