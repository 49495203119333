import { Pipe, PipeTransform } from '@angular/core';

import { formatAddress } from '@app/utils';

import { Address } from '../models';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: Partial<Address>): string {
    return formatAddress(address);
  }
}
