import { KeyValuePair } from '@core/models';

/**
 * Maps the given object to an array of KeyValue pairs.
 *
 * @param data An object of items.
 *
 * @returns An array of KeyValue pairs.
 */
export function mapObjectToKeyValuePair(data: Record<string, string>): KeyValuePair<string, string>[] {
  return Object.keys(data).reduce((prev, key) => {
    if (key) {
      return [...prev, { key, value: data[key] }];
    }

    return prev;
  }, [] as KeyValuePair<string, string>[]);
}
