<form class="change-phone col-12" [formGroup]="phoneForm" (ngSubmit)="updatePhoneNumbers()">
  <p class="mb-4 text-center font-weight-normal">
    The primary number is the first phone we will use to contact you regarding your account(s) with us. You may add
    alternate numbers in case we cannot reach you on your primary phone.
  </p>
  <div [ngSwitch]="profileType">
    <div *ngSwitchCase="profileTypes.Personal">
      <div class="form-group" [class.invalid-field]="cellPhone1.hasError('*', 'touched')">
        <label for="cellPhone1" class="uk-label">Primary Cell:</label>
        <input
          type="tel"
          class="uk-input"
          formControlName="cellPhone1"
          id="cellPhone1"
          placeholder="(###) ###-####"
          mask="(000) 000-0000"
        />
        <small>This phone can receive text messages from the bank.</small>

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="cellPhone1" #cellPhone1="ngxErrors">
          <span ngxError="required" when="touched" [innerText]="errors.required"></span>
          <span ngxError="pattern" when="touched" [innerText]="errors.pattern"></span>
          <span ngxError="invalidCharacter" when="touched" [innerText]="errors.invalidStartCharacter"></span>
          <span ngxError="allSame" when="touched" [innerText]="errors.sameNumber"></span>
          <span ngxError="sequentialNumbers" when="touched" [innerText]="errors.consecutiveNumber"></span>
        </div>
      </div>

      <fieldset>
        <legend class="font-weight-bold">Alternate Number:</legend>

        <div class="form-group" [class.invalid-field]="cellPhone2.hasError('*', 'touched')">
          <label for="cellPhone2" class="uk-label">Alternate Cell</label>
          <input
            type="tel"
            formControlName="cellPhone2"
            id="cellPhone2"
            class="uk-input"
            placeholder="(###) ###-####"
            mask="(000) 000-0000"
          />
          <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="cellPhone2" #cellPhone2="ngxErrors">
            <span ngxError="pattern" when="touched" [innerText]="errors.pattern"></span>
            <span ngxError="invalidCharacter" when="touched" [innerText]="errors.invalidStartCharacter"></span>
            <span ngxError="allSame" when="touched" [innerText]="errors.sameNumber"></span>
            <span ngxError="sequentialNumbers" when="touched" [innerText]="errors.consecutiveNumber"></span>
          </div>
        </div>

        <div class="form-group" [class.invalid-field]="homePhone.hasError('*', 'touched')">
          <label for="homePhone" class="uk-label">Home Phone</label>
          <input
            type="tel"
            formControlName="homePhone"
            id="homePhone"
            class="uk-input"
            placeholder="(###) ###-####"
            mask="(000) 000-0000"
          />
          <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="homePhone" #homePhone="ngxErrors">
            <span ngxError="pattern" when="touched" [innerText]="errors.pattern"></span>
            <span ngxError="invalidCharacter" when="touched" [innerText]="errors.invalidStartCharacter"></span>
            <span ngxError="allSame" when="touched" [innerText]="errors.sameNumber"></span>
            <span ngxError="sequentialNumbers" when="touched" [innerText]="errors.consecutiveNumber"></span>
          </div>
        </div>

        <div class="work-phone">
          <div class="form-group" [class.invalid-field]="workPhone.hasError('*', 'touched')">
            <label for="workPhone" class="uk-label">Work Phone</label>
            <input
              type="tel"
              formControlName="workPhone"
              id="workPhone"
              class="uk-input"
              placeholder="(###) ###-####"
              mask="(000) 000-0000"
            />
            <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="workPhone" #workPhone="ngxErrors">
              <span ngxError="pattern" when="touched" [innerText]="errors.pattern"></span>
              <span ngxError="invalidCharacter" when="touched" [innerText]="errors.invalidStartCharacter"></span>
              <span ngxError="allSame" when="touched" [innerText]="errors.sameNumber"></span>
              <span ngxError="sequentialNumbers" when="touched" [innerText]="errors.consecutiveNumber"></span>
            </div>
          </div>

          <div class="form-group">
            <label for="workPhoneExtension" class="uk-label">Work Phone Extension</label>
            <input
              type="tel"
              formControlName="workPhoneExtension"
              id="workPhoneExtension"
              class="uk-input"
              placeholder="####"
              mask="9999"
              [maxlength]="maxExtensionLength"
            />
          </div>
        </div>
      </fieldset>
    </div>
    <div *ngSwitchCase="profileTypes.Business">
      <div class="form-group" [class.invalid]="cellPhone1.hasError('*', 'touched')">
        <label for="cellPhone1" class="uk-label">Work Cell:</label>
        <input
          type="tel"
          class="uk-input"
          formControlName="cellPhone1"
          id="cellPhone1"
          placeholder="(###) ###-####"
          mask="(000) 000-0000"
        />
        <small>This phone can receive text messages from the bank.</small>

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="cellPhone1" #cellPhone1="ngxErrors">
          <span ngxError="required" when="touched" [innerText]="errors.required"></span>
          <span ngxError="pattern" when="touched" [innerText]="errors.pattern"></span>
          <span ngxError="forbiddenPhone" when="touched" [innerText]="errors.pattern"></span>
        </div>
      </div>

      <fieldset>
        <legend class="font-weight-bold">Alternate Number:</legend>

        <div class="work-phone">
          <div class="form-group" [class.invalid]="workPhone.hasError('*', 'touched')">
            <label for="workPhone" class="uk-label">Desk Phone:</label>
            <input
              type="tel"
              formControlName="workPhone"
              id="workPhone"
              class="uk-input"
              placeholder="(###) ###-####"
              mask="(000) 000-0000"
            />
            <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="workPhone" #workPhone="ngxErrors">
              <span ngxError="pattern" when="touched" [innerText]="errors.pattern"></span>
              <span ngxError="forbiddenPhone" when="touched" [innerText]="errors.pattern"></span>
            </div>
          </div>

          <div class="form-group">
            <label for="workPhoneExtension" class="uk-label">Desk Phone Extension</label>
            <input
              type="tel"
              formControlName="workPhoneExtension"
              id="workPhoneExtension"
              class="uk-input"
              placeholder="####"
              mask="9999"
              [maxlength]="maxExtensionLength"
            />
          </div>
        </div>
      </fieldset>
    </div>
    <div *ngSwitchCase="profileTypes.Company">
      <div *ngIf="!isFax">
        <div class="form-group" [class.invalid-field]="workPhone.hasError('*', 'touched')">
          <label for="workPhone" class="uk-label">Business Phone</label>
          <input
            type="tel"
            formControlName="workPhone"
            id="workPhone"
            class="uk-input"
            placeholder="(###) ###-####"
            mask="(000) 000-0000"
          />
          <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="workPhone" #workPhone="ngxErrors">
            <span ngxError="required" when="touched" [innerText]="companyErrors.required"></span>
            <span ngxError="pattern" when="touched" [innerText]="companyErrors.pattern"></span>
            <span ngxError="invalidCharacter" when="touched" [innerText]="errors.invalidStartCharacter"></span>
            <span ngxError="allSame" when="touched" [innerText]="errors.sameNumber"></span>
            <span ngxError="sequentialNumbers" when="touched" [innerText]="errors.consecutiveNumber"></span>
          </div>
        </div>

        <div class="form-group">
          <label for="workPhoneExtension" class="uk-label">Business Phone Extension</label>
          <input
            type="tel"
            formControlName="workPhoneExtension"
            id="workPhoneExtension"
            class="uk-input"
            placeholder="####"
            mask="9999"
            [maxlength]="maxExtensionLength"
          />
        </div>
      </div>
      <div class="form-group" [class.invalid-field]="workFax.hasError('*', 'touched')" *ngIf="isFax">
        <label for="workFax" class="uk-label">Business Fax</label>
        <input
          type="tel"
          formControlName="workFax"
          id="workFax"
          class="uk-input"
          placeholder="(###) ###-####"
          mask="(000) 000-0000"
        />
        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="workFax" #workFax="ngxErrors">
          <span ngxError="required" when="touched" [innerText]="companyErrors.required"></span>
          <span ngxError="pattern" when="touched" [innerText]="companyErrors.pattern"></span>
          <span ngxError="invalidCharacter" when="touched" [innerText]="errors.invalidStartCharacter"></span>
          <span ngxError="allSame" when="touched" [innerText]="errors.sameNumber"></span>
          <span ngxError="sequentialNumbers" when="touched" [innerText]="errors.consecutiveNumber"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="form__actions mt-4">
    <div class="text-error text-center" *ngIf="errorMessage" [innerText]="errorMessage"></div>
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="phoneForm.pristine">Save</button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
