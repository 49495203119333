import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { BalanceChangeByAccountCategory } from '@app/pfm/components/assets-liabilities-table/models/balance-change-by-account-category.model';
import { NetWorthPoint } from '@app/pfm/models/net-worth-point.model';
import { CategoryName } from '@legacy/tiles/account-overview/typings/CategoryName.enum';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-assets-liabilities-table',
  templateUrl: './assets-liabilities-table.component.html',
  styleUrls: ['./assets-liabilities-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsLiabilitiesTableComponent implements OnChanges {
  @Input() currentNetWorth: NetWorthPoint;
  @Input() previousNetWorth: NetWorthPoint;

  icons = {
    arrowUp: UtilityIcons.ArrowUp,
    arrowDown: UtilityIcons.ArrowDown,
  };

  assets: BalanceChangeByAccountCategory[] = [];
  liabilities: BalanceChangeByAccountCategory[] = [];
  isEmpty = true;
  constructor() {}

  ngOnChanges(): void {
    this.loadData();
  }
  loadData() {
    this.isEmpty = !this.currentNetWorth || !this.previousNetWorth;
    if (this.isEmpty) return;

    this.assets = [
      this.getGroupBalanceChange(CategoryName.Checking),
      this.getGroupBalanceChange(CategoryName.Savings),
      this.getGroupBalanceChange(CategoryName.Investment),
      this.getGroupBalanceChange(CategoryName.Other),
    ].filter(a => a != null);

    this.liabilities = [
      this.getGroupBalanceChange(CategoryName.CreditCards),
      this.getGroupBalanceChange(CategoryName.Loans),
    ].filter(a => a != null);
  }

  getGroupBalanceChange(category: CategoryName): BalanceChangeByAccountCategory {
    const currentAccounts = this.currentNetWorth.worth.accountWorths.filter(a => a.account?.categoryName === category);
    if (!currentAccounts.length) return null; // user doesn't have accounts for this group
    const currentBalance = this.roundFloatingPointErrors(
      currentAccounts.reduce((sum, account) => sum + account.balance, 0)
    );

    const previousBalance = this.roundFloatingPointErrors(
      this.previousNetWorth.worth.accountWorths
        .filter(a => a.account.categoryName === category)
        .reduce((sum, account) => sum + account.balance, 0)
    );

    const balanceChange = currentBalance - previousBalance;

    return {
      percentageChange: previousBalance === 0 ? null : balanceChange / previousBalance,
      categoryName: category,
      balanceChange,
      balance: currentBalance,
      arrowIcon: balanceChange === 0 ? null : balanceChange > 0 ? this.icons.arrowUp : this.icons.arrowDown,
    };
  }

  roundFloatingPointErrors(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  getDisplayBalance(balance: number) {
    balance = Math.abs(balance);
    if (balance === 0) return '$0';
    if (balance < 1) return `$${balance.toFixed(2).slice(1)}`;

    return Math.round(balance).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
}
