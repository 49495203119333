<ng-container
  *ngIf="{
    categories: categories$ | async
  } as data"
>
  <div class="sm-menu">
    <app-dot-loader [showWhen]="data.categories?.length <= 0">Loading</app-dot-loader>
    <ng-container *ngIf="data.categories?.length > 0">
      <app-sm-item
        class="sm-menu__item"
        *ngFor="let category of data.categories"
        [icon]="category.imageUrl"
        [name]="category.description"
        (click)="onSelectItem(category)"
      ></app-sm-item>
    </ng-container>
  </div>
</ng-container>
