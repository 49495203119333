<div class="aggregation-success">
  <div class="header-section-title aggregation-success__header text-center frow">
    <div class="fcol-cs-12">
      <i class="bofi-check-mark"></i>
    </div>
    <div>
      <h2 class="fcol-cs-12 aggregation-success__header-text">
        Success
      </h2>
    </div>
  </div>

  <div>
    <div class="frow center">
      <div class="fcol-cs-10">
        <div class="text-center aggregation-success__accounts_aggregation-flow-title">
          <h4 class="aggregation-success__title">
            We have added the following account(s) to your Account:
          </h4>
          <h6 class="aggregation-success__accounts-title">Details</h6>
        </div>
        <div class="frow center aggregation-success__accounts">
          <ul class="aggregation-success__accounts-list">
            <li *ngFor="let account of accounts" class="aggregation-success__accounts-list-table">
              <label for="{{ account.externalAccountId }}" class="aggregation-success__accounts-list-item-name-flow">
                {{ account | json }}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
