import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { SubSink } from '@axos/subsink';

import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';
import { NavigationIcons } from '@shared/enums';

import { GenericOption } from '../../../../models/generic-options.model';
import { TransactionFilter } from '../../models';

@Component({
  selector: 'app-transaction-tags',
  templateUrl: './transaction-tags.component.html',
  styleUrls: ['./transaction-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionTagsComponent implements OnInit, OnDestroy {
  @Input() set filters(value: TransactionFilter) {
    this.generateTags(value);
  }

  @Output() tagRemoved = new EventEmitter<{ filterName: string }>();

  tagsCollection: GenericOption[] = [];
  icon = {
    name: NavigationIcons.Ex,
    styles: {
      width: '0.625rem',
      height: '0.625rem',
      strokeWidth: 1,
    },
  };

  private subsink = new SubSink();

  constructor(
    private cp: CurrencyPipe,
    private dp: DatePipe,
    private olbEventService: OlbEventService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subsink.sink = this.olbEventService.on(OlbEvents.TxFiltersReset, () => {
      this.tagsCollection.map(tag => this.removeTag(tag.subvalue));
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  generateTags(value: TransactionFilter) {
    Object.keys(value).forEach(filterName => {
      const { amount, amountRange, check, checkRange, dateRange } = value;
      switch (filterName) {
        case 'amount':
          this.handleAmountFilter(amount, filterName);
          break;
        case 'amountRange':
          this.handleAmountRangeFilter(amountRange, filterName);
          break;
        case 'check':
          this.handleCheckFilter(check, filterName);
          break;
        case 'checkRange':
          this.handleCheckRangeFilter(checkRange, filterName);
          break;
        case 'dateRange':
          this.handleDateRangeFilter(dateRange, filterName);
          break;
      }
    });
  }

  removeTag(filterName: string) {
    const previousTagsAmount = this.tagsCollection.length;
    this.tagsCollection = this.tagsCollection.filter(tag => tag.subvalue !== filterName);

    if (previousTagsAmount !== this.tagsCollection.length) {
      this.tagRemoved.emit({ filterName });
    }
  }

  private addOrRemoveTag(condition: boolean, filterName: string, tagItem: GenericOption = null) {
    if (condition) {
      this.addOrUpdateTag(tagItem);
    } else {
      this.removeTag(filterName);
    }
  }

  private handleAmountFilter(amount: number, filterName: string) {
    const hasChanged = !!amount;
    const tagItem = this.setupGenericOption(0, this.cp.transform(amount).toString(), filterName);
    this.addOrRemoveTag(hasChanged, filterName, tagItem);
  }

  private handleAmountRangeFilter(amountRange: { min?: number; max?: number }, filterName: string) {
    const hasChanged = !!(amountRange.max || amountRange.min);
    const tagItem = this.setupGenericOption(
      1,
      `${amountRange?.min ? this.cp.transform(amountRange.min) : 'Min'} - ${
        amountRange?.max ? this.cp.transform(amountRange.max) : 'Max'
      }`,
      filterName
    );
    this.addOrRemoveTag(hasChanged, filterName, tagItem);
  }

  private handleCheckFilter(check: number, filterName: string) {
    const hasChanged = !!check;
    const tagItem = this.setupGenericOption(2, `Check #${check}`, filterName);
    this.addOrRemoveTag(hasChanged, filterName, tagItem);
  }

  private handleCheckRangeFilter(checkRange: { min?: number; max?: number }, filterName: string) {
    const hasChanged = !!(checkRange.max && !!checkRange.min);
    const tagItem = this.setupGenericOption(3, `Check #${checkRange.min} - Check #${checkRange.max}`, filterName);
    this.addOrRemoveTag(hasChanged, filterName, tagItem);
  }

  private handleDateRangeFilter(dateRange: { start?: Date | any; end?: Date | any }, filterName: string) {
    const hasChanged = !!(dateRange.start && dateRange.end);
    const tagItem = this.setupGenericOption(
      4,
      `${this.dp.transform(dateRange.start, 'MM/dd/yyyy')} - ${this.dp.transform(dateRange.end, 'MM/dd/yyyy')}`,
      filterName
    );
    this.addOrRemoveTag(hasChanged, filterName, tagItem);
  }

  private setupGenericOption(value: number, label: string, propertyName: string): GenericOption {
    return new GenericOption({
      value,
      label,
      subvalue: propertyName,
    });
  }

  private addOrUpdateTag(filter: GenericOption) {
    const idx = this.tagsCollection.findIndex(f => f.value === filter.value);
    // If filter is not added yet, it is added to the tagFilters array
    if (idx < 0) {
      this.tagsCollection.push(filter);
    } else {
      this.tagsCollection[idx] = filter;
    }
  }
}
