import { Component, Inject, OnInit } from '@angular/core';
import { FacingBrand } from '@core/enums';
import { olbSettings } from '@core/tokens';

@Component({
  selector: 'app-interestitial-header',
  templateUrl: './initial-page-header.html',
  styleUrls: ['./initial-page-header.scss'],
})
export class InterestitialHeaderPage implements OnInit {
  brandName: string;

  constructor(@Inject(olbSettings) private settings: OlbSettings) {}

  ngOnInit(): void {
    this.brandName = this.settings.brandName;
  }

  get currentLogo(): string {
    return `./img/logos/${this.brandName}-w-logo.svg`;
  }

  get isUfb(): boolean {
    return this.settings.facingBrandId === FacingBrand.UFB;
  }
}
