import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { OrganizationSettingsFacade } from '@app/Areas/AAS/aas-core/organization-settings/facade/organization-settings.facade';
import { AuthorizationsFacade } from '@app/Areas/AAS/features/account-details/facade/authorizations.facade';

import { AvailableCashFacade } from '../../available-cash/facade';

import { TotalValueFacade } from '../../total-value/facade/total-value.facade';
import { HoldingFacade } from '../../holdings/facade';
import { ModelsFacade } from '../../models/facade';
import {
  AasAccountState,
  addOrUpdateAasAccountAction,
  addTransactionSelectedTab,
  getSelectedAasAccount,
  PdpState,
} from '../core';
import { InitializePdpInputType } from './types';
import { PeopleFacade } from '../../account-details/facade';
import { PdpLayoutFacade } from '../layout/facade';
import { StatementsAndDocumentsFacade } from '../../statements-and-documents/facade';
import { TransactionFacade } from '../../transactions/facade';
import { DOUBLE_ASTERISK, EMPTY_STRING } from './constants';
import { ScheduledTransfersFacade } from '../../scheduled-transfers/facade/scheduledTransfers.facade';
import { AccountDetailsFacade } from '../../account-details/facade/account-details.facade';
import { OrdersFacade } from '../../orders/facade/orders.facade';
import { BrandingSettingsFacade } from '@app/Areas/AAS/aas-core/branding-settings';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { PortfolioAllocationFacade } from '../../portfolio-allocation/facade/portfolio-allocation.facade';
import { RiaFacade } from '@app/Areas/AAS/aas-core/rias';
import { UserPreferencesFacade } from '@app/Areas/AAS/aas-shared/features/user-preferences';

@Injectable({
  providedIn: 'root',
})
export class PdpFacade {
  selectedAasAccount$ = this.store.select(getSelectedAasAccount);

  constructor(
    private accountAuthorizationsFacade: AuthorizationsFacade,
    private organizationSettingsFacade: OrganizationSettingsFacade,
    private store: Store<PdpState>,
    private modelsFacade: ModelsFacade,
    private holdingFacade: HoldingFacade,
    private accountDetailsFacade: AccountDetailsFacade,
    private pdpLayoutFacade: PdpLayoutFacade,
    private availableCashFacade: AvailableCashFacade,
    private totalValueFacade: TotalValueFacade,
    private statementsAndDocumentsFacade: StatementsAndDocumentsFacade,
    private peopleFacade: PeopleFacade,
    private transactionFacade: TransactionFacade,
    private scheduledTransfersFacade: ScheduledTransfersFacade,
    private ordersFacade: OrdersFacade,
    private brandingSettingsFacade: BrandingSettingsFacade,
    private featureFlagService: FeatureFlagService,
    private portfolioAllocationFacade: PortfolioAllocationFacade,
    private riaFacade: RiaFacade,
    private userPreferencesFacade: UserPreferencesFacade
  ) {}

  //getting called when user selects an account
  initializePdp(input: InitializePdpInputType) {
    if (this.featureFlagService.isRiaPilotAllowedAccountsActive()) {
      const { brandingName } = input.axosAdvisoryAccount;
      this.brandingSettingsFacade.loadBrandingSettings(brandingName);
      this.riaFacade.selectRia(brandingName);
    } else {
      this.organizationSettingsFacade.loadOrganizationSettings({
        riaId: input.axosAdvisoryAccount.riaId,
        aasAccountNumber: input.axosAdvisoryAccount.accountNumber,
      });
    }

    const aasAccountState: AasAccountState = {
      ...input.axosAdvisoryAccount,
    };

    this.store.dispatch(addOrUpdateAasAccountAction({ payload: aasAccountState }));
    this.initializeTiles(input.axosAdvisoryAccount);
  }

  initializeTiles(input: AasAccountState) {
    const aasInputInitialize = {
      accountNumber: input.accountNumber,
    };
    const aasInputInitializeCashTile = {
      accountNumber: input.accountNumber,
      accountTypeCode: input.accountTypeCode,
      dateCloseInitiated: input.dateCloseInitiated,
    };
    const riaDefaultName = 'Advisor Managed Account';
    const pdpLayoutContextualTopBar = {
      pageTitle: this.getPageTitle(input.accountNickname, input.type, input.accountNumber),
      breadcrumbItems: [
        {
          label: input.displayName || riaDefaultName,
          url: `/Account/Advisor/${input.accountNumber}`,
        },
      ],
      accountNumber: input.accountNumber,
    };

    this.totalValueFacade.initializeTotalValueTile(aasInputInitialize);
    this.accountAuthorizationsFacade.initializeAuthorizationsTab(aasInputInitialize);
    this.accountDetailsFacade.initializeAccountDetails(aasInputInitialize);
    this.pdpLayoutFacade.setPdpLayoutContextualTopBarState(pdpLayoutContextualTopBar);
    this.availableCashFacade.initializeAvailableCashTile(aasInputInitializeCashTile);
    this.statementsAndDocumentsFacade.initializeStatementsAndDocuments(aasInputInitialize);
    this.peopleFacade.initializePeopleTab(aasInputInitialize);
    this.transactionFacade.initializeTransactionTile(aasInputInitialize);
    this.scheduledTransfersFacade.initializeScheduledTransferTile(aasInputInitialize);
    this.ordersFacade.initializeOrdersTile(aasInputInitialize);
    this.portfolioAllocationFacade.initializePortfolioAllocationTile(aasInputInitialize);
    this.initializeUserPreferenceDependantFeatures(
      input.accountNumber,
      this.featureFlagService.isRiaModelsAndHoldingsSortActive()
    );
  }

  /**
   * @description
   * Initializes all features depending on user preferences and app preferences
   * such as models sort feature and holdings sort, uses callback to provide state context after both
   * app default preferences and user preferences are requested and responses via api to avoid state manager event
   * filtering.
   * @note
   * this method is required to set initial state from features that require on app / user preferences for initial values without
   * merging state from user / app preferences into other state slices
   * @param accountNumber string
   */
  private initializeUserPreferenceDependantFeatures(accountNumber: string, isSortingEnabled: boolean = false): void {
    this.userPreferencesFacade.initializeUserPreferences(userPreferencesState => {
      const initialPayload = {
        accountNumber,
        isSortingEnabled,
        ...userPreferencesState,
      };
      this.modelsFacade.initializeModelsTile(initialPayload);
      this.holdingFacade.initializeHoldingTile(initialPayload);
    });
  }

  /**
   * The page title has the following format:
   * <XXXX - ####> where:
   * XXXX is the account nickname, or the account type if it doesn't have a nickname
   * #### is the last 4 characters of the account number
   * Example: IRA - 3523
   */
  getPageTitle(accountNickname: string, accountType: string, accountNumber: string): string {
    let pageTitle: string = '';
    let lastFourCharacters = this.getLast4Characters(accountNumber);

    if (accountNumber == null) return pageTitle;
    if (accountNickname != null) {
      accountNickname = this.accountNicknameSettings(accountNickname, lastFourCharacters);
      pageTitle = `${accountNickname} - ${lastFourCharacters}`;
    } else if (accountType != null) {
      accountType = this.accountNicknameSettings(accountType, lastFourCharacters);
      pageTitle = `${accountType} - ${lastFourCharacters}`;
    }

    return this.replaceCharacters(pageTitle, DOUBLE_ASTERISK);
  }

  getLast4Characters(s: string): string {
    if (s.length <= 4) return s;
    return s.substring(s.length - 4, s.length);
  }

  replaceCharacters(word: string, characterToReplace: string, newCharacter = EMPTY_STRING): string {
    return word.replace(characterToReplace, newCharacter);
  }

  accountNicknameSettings(accountTypeOrNickname: string, lastFourCharacters: string): string {
    return this.replaceCharacters(accountTypeOrNickname, lastFourCharacters);
  }

  setTransactionSelectedTab(input: string) {
    this.store.dispatch(addTransactionSelectedTab({ payload: input }));
  }
}
