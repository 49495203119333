import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { PayoffDetailsResponse } from '@app/accounts/models';
import { LoanPaymentData } from 'transfers/typings/LoanPaymentData';
import { ModalService } from 'services/modal.service';
import { mapFrequency } from '@app/axos-invest/utils';
import { mapSendUntil } from 'transfers/transfers.controller';
import { Frequency } from '@app/axos-invest/enums';
import { TransferSchedule } from 'transfers/typings/TransferSchedule';
import { ExternalAccountScheduleRequest } from 'transfers/typings/ExternalAccountScheduleRequest';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { AccountsService, TransactionService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-loan-review-and-submit',
  templateUrl: './loan-review-and-submit.component.html',
  styleUrls: ['./loan-review-and-submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanReviewAndSubmitComponent implements OnInit {
  isBusy: boolean;
  isOneTime = false;
  hasNumberOfTransfers = false;
  // Review and Submit layout
  totalAmount: string;
  accountInformation: { name: string; value: string }[] = [];
  scheduleInformation: { name: string; value: string }[] = [];
  paymentInformation: { name: string; value: string }[] = [];
  paymentDetails: { [key: string]: string } = {};
  // Parameters
  loanPayment: LoanPaymentData;
  payoffCalculated: PayoffDetailsResponse;
  isPayoff: boolean;
  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    @Inject(ROOT_SCOPE) private readonly _root: ng.IRootScopeService,
    @Inject(STATE) private readonly _stateService: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private readonly _stateParams: ng.ui.IStateParamsService,
    private readonly _accountsService: AccountsService,
    private readonly _modalService: ModalService,
    private readonly _serviceHelper: ServiceHelper,
    private readonly _transactionService: TransactionService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isPayoff = this._stateParams['isPayoff'];
    this.payoffCalculated = this._stateParams['payoffCalculated'];
    this.loanPayment = this._stateParams['loanPaymentData'];
    if (this.loanPayment == null) {
      this._stateService.go('udb.transfers.transferFunds');
    } else {
      this.initPaymentDetails();
    }
  }

  goBack(): void {
    this._stateService.go('udb.transfers.loanPayment');
    this._serviceHelper.scrollTo('udb-transfer');
  }

  submitPayment(): void {
    if (this.loanPayment.isExternal) {
      this.submitExternalToLoan();
    } else {
      this.submitInternalToLoan();
    }
  }

  private mapPaymentDetails(): void {
    this.accountInformation = [{ name: 'From Account', value: this.paymentDetails['FromAccount'] }];
    if (this.isPayoff) {
      this.scheduleInformation = [{ name: 'Payoff Date', value: this.paymentDetails['StartOnDate'] }];
    } else {
      this.scheduleInformation = [
        { name: 'Start on date', value: this.paymentDetails['StartOnDate'] },
        { name: 'Frequency', value: this.paymentDetails['Frequency'] },
      ];
      if (!this.isOneTime) {
        this.scheduleInformation.push({
          name: 'Send Until',
          value: this.paymentDetails['SendUntil'],
        });
      }
      if (this.hasNumberOfTransfers) {
        this.scheduleInformation.push({
          name: 'Number of transfers',
          value: this.paymentDetails['NumberOfTransfers'],
        });
      }
    }

    this.paymentInformation = this.isPayoff
      ? [
          {
            name: 'Outstanding Balance',
            value: this.currencyPipe.transform(this.payoffCalculated['currentBalance']),
          },
          {
            name: 'Accrued Interest',
            value: this.currencyPipe.transform(this.payoffCalculated['accruedInterest']),
          },
          {
            name: 'Secondary Accrued Interest',
            value: this.currencyPipe.transform(this.payoffCalculated['secondaryAccruedInterest']),
          },
          {
            name: 'Late Charge Due Amount',
            value: this.currencyPipe.transform(this.payoffCalculated['lateChargeDueAmount']),
          },
        ]
      : this.paymentsData();
    this.totalAmount = this.paymentDetails['TotalAmount'];
  }

  private paymentsData() {
    return [
      { name: 'Amount', value: this.paymentDetails['Amount'] },
      { name: 'Additional Principal', value: this.paymentDetails['AdditionalPrincipal'] },
    ];
  }

  private initPaymentDetails(): void {
    if (this.loanPayment.isExternal && !this.loanPayment.isLoanFeatureEnabled) {
      const externalTransaction = this.loanPayment.externalTransaction;

      this.paymentDetails['FromAccount'] = externalTransaction.externalAccountNickName;
      this.paymentDetails['StartOnDate'] = this.datePipe.transform(externalTransaction.processingDate, 'MM/dd/yyyy');
      this.paymentDetails['Frequency'] = mapFrequency(externalTransaction.frequency);
      this.paymentDetails['NumberOfTransfers'] = '' + externalTransaction.numberOfTransfers;
      this.paymentDetails['TotalAmount'] = this.currencyPipe.transform(this.loanPayment.totalAmount);
      this.isOneTime = externalTransaction.frequency === Frequency.ONE_TIME;
      this.hasNumberOfTransfers = externalTransaction.numberOfTransfers > 0;
    } else {
      const transaction = this.loanPayment.transaction;

      this.paymentDetails['FromAccount'] = transaction.accountNicknameFrom;
      this.paymentDetails['StartOnDate'] = this.datePipe.transform(transaction.beginSendingOn, 'MM/dd/yyyy');
      this.paymentDetails['Frequency'] = mapFrequency(transaction.frequency);
      this.paymentDetails['NumberOfTransfers'] = '' + transaction.numberOfTransfers;
      this.paymentDetails['TotalAmount'] = this.currencyPipe.transform(this.loanPayment.totalAmount);
      this.isOneTime = transaction.frequency === Frequency.ONE_TIME;
      this.hasNumberOfTransfers = transaction.numberOfTransfers > 0;
    }
    this.paymentDetails['ToAccount'] = this.loanPayment.accountNickname;
    this.paymentDetails['SendUntil'] = mapSendUntil(this.loanPayment.sendUntil);

    this.paymentDetails['Amount'] = this.isPayoff
      ? this.currencyPipe.transform(this.payoffCalculated.payoffAmount)
      : this.currencyPipe.transform(this.loanPayment.amount);
    this.paymentDetails['AdditionalPrincipal'] = this.currencyPipe.transform(this.loanPayment.additionalPrincipal);
    this.mapPaymentDetails();
  }

  private submitInternalToLoan(): void {
    const transaction = this.loanPayment.transaction;
    if (transaction.frequency === Frequency.ONE_TIME && moment(transaction.beginSendingOn).isSame(moment(), 'd')) {
      this.submitOneTime(transaction, true);
    } else {
      this.submitOneTimeFutureOrSchedule(transaction, true);
    }
  }

  private submitExternalToLoan(): void {
    if (this.loanPayment.isLoanFeatureEnabled) {
      this.submitExternalTransferToAxosScheduler(this.loanPayment.transaction);
    } else {
      this.submitExternalTransfer(this.loanPayment.externalTransaction, true);
    }
  }

  private submitOneTime(
    transaction: TransferSchedule,
    validateDuplicate: boolean = false,
    acceptDuplicateWarning: boolean = false
  ): void {
    this.isBusy = true;
    this._transactionService
      .transfer(transaction, validateDuplicate, acceptDuplicateWarning)
      .pipe(
        finalize(() => {
          this.isBusy = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: response => {
          if (response.statusCode == 202) {
            this.DuplicateTransferWarning(transaction, true);
          } else {
            this._accountsService.getSortedAccountsBalance().subscribe(accounts => {
              this.updateAccountsAmounts(accounts.data);
              this._transactionService.clearCache();
              this._root.$broadcast('transferCompleted');
              this._root.$broadcast('updateTransactions');
            });
            this.goToReceipt();
          }
        },
        error: (err: ApiError) => {
          this._serviceHelper.errorHandler(err, true);
        },
      });
  }

  private submitOneTimeFutureOrSchedule(
    transaction: TransferSchedule,
    validateDuplicate: boolean = false,
    acceptDuplicateWarning?: boolean
  ): void {
    this.isBusy = true;
    this._transactionService
      .scheduleTransfer(transaction, validateDuplicate, acceptDuplicateWarning)
      .pipe(
        finalize(() => {
          this.isBusy = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: response => {
          if (response.statusCode == 202) {
            this.DuplicateTransferWarning(transaction, true);
          } else {
            this.goToReceipt();
          }
        },
        error: (err: ApiError) => {
          this._serviceHelper.errorHandler(err, true);
        },
      });
  }

  private submitExternalTransferToAxosScheduler(transaction: TransferSchedule): void {
    this.isBusy = true;
    this._transactionService
      .scheduleACHTransfer(transaction)
      .pipe(
        finalize(() => {
          this.isBusy = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.goToReceipt();
        },
        error: (err: ApiError) => {
          this._serviceHelper.errorHandler(err, true);
        },
      });
  }

  private submitExternalTransfer(
    transaction: ExternalAccountScheduleRequest,
    validateDuplicate: boolean = false,
    acceptDuplicateWarning?: boolean
  ): void {
    this.isBusy = true;
    this._transactionService
      .scheduleExternalTransfer(transaction, validateDuplicate, acceptDuplicateWarning)
      .pipe(
        finalize(() => {
          this.isBusy = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: response => {
          if (response.statusCode == 202) {
            this.DuplicateTransferWarning();
          } else {
            this.goToReceipt();
          }
        },
        error: (err: ApiError) => {
          this._serviceHelper.errorHandler(err, true);
        },
      });
  }

  private DuplicateTransferWarning(transaction?: TransferSchedule, isFromInternalAccount: boolean = false): void {
    this._modalService
      .show(
        {},
        {
          bodyText: `<h3>Potential Duplicate Payment</h3>
                                    <p>There is arleady a scheduled payment to this loan account. Are you sure you would like to continue with this payment?</p>`,
          okText: 'Continue',
          cancelText: 'Cancel',
        }
      )
      .then(() => {
        if (isFromInternalAccount) {
          if (
            transaction.frequency === Frequency.ONE_TIME &&
            moment(transaction.beginSendingOn).isSame(moment(), 'd')
          ) {
            this.submitOneTime(transaction, false, true);
          } else {
            this.submitOneTimeFutureOrSchedule(transaction, false, true);
          }
        } else {
          this.submitExternalTransfer(this.loanPayment.externalTransaction, false, true);
        }
      })
      .catch(() => {
        return;
      });
  }

  private updateAccountsAmounts(accounts: AccountsPage): void {
    if (!this._root['accounts']) return;
    if (accounts.depositAccounts) {
      this._root['accounts'].depositAccounts = accounts.depositAccounts;
    }
    if (accounts.loanAccounts) {
      this._root['accounts'].loanAccounts = accounts.loanAccounts;
    }
  }

  private goToReceipt(): void {
    let numberOfTransfersLabel = '';
    if (this.hasNumberOfTransfers) {
      const transfers = +this.paymentDetails['NumberOfTransfers'];
      numberOfTransfersLabel = transfers + ' transfer' + (transfers > 1 ? 's' : '');
    }

    const confirmationData: { name: string; value: string }[] = this.isPayoff
      ? [
          { name: 'From Account', value: this.paymentDetails['FromAccount'] },
          { name: 'To Account', value: this.paymentDetails['ToAccount'] },
          {
            name: 'Payoff Amount',
            value: this.payoffCalculated.payoffAmount > 0 ? this.paymentDetails['Amount'] : '',
          },
          { name: 'Payoff Date', value: this.paymentDetails['StartOnDate'] },
        ]
      : [
          { name: 'From Account', value: this.paymentDetails['FromAccount'] },
          { name: 'To Account', value: this.paymentDetails['ToAccount'] },
          {
            name: 'Amount',
            value: this.loanPayment.amount > 0 ? this.paymentDetails['Amount'] : '',
          },
          {
            name: 'Additional Principal',
            value: this.loanPayment.additionalPrincipal > 0 ? this.paymentDetails['AdditionalPrincipal'] : '',
          },
          { name: 'Start on date', value: this.paymentDetails['StartOnDate'] },
          { name: 'Frequency', value: this.paymentDetails['Frequency'] },
          {
            name: 'Send Until',
            value: !this.isOneTime ? this.paymentDetails['SendUntil'] : '',
          },
          { name: 'Number of transfers', value: numberOfTransfersLabel },
        ];

    this._stateService.go('udb.transfers.loanPayment.confirmation', {
      paymentData: confirmationData,
      isOneTime: this.isOneTime && moment(this.paymentDetails['StartOnDate']).isSame(moment(), 'd'),
    });
  }
}
