import { MinorResponse } from '../minors/typing/minorResponse';

export class MinorHelper {
  private minorResponse: MinorResponse;

  saveMinorResponse(response: MinorResponse): void {
    this.minorResponse = response;
  }

  getMinorResponse(): MinorResponse {
    return this.minorResponse;
  }

  clearMinorResponse(): void {
    this.minorResponse = null;
  }
}
