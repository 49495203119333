import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { supportBillPayComponents } from './components';

@NgModule({
  declarations: [...supportBillPayComponents],
  exports: [...supportBillPayComponents],
  imports: [CommonModule],
})
export class SupportBillPayModule {}
