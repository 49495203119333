import { ReceiptTransaction } from './receipt-transaction.model';

export class ReceiptSettings {
  /** Amount tranfered. */
  amount: string;
  /** The source account. */
  fromAccount: string;
  /** The destination account. */
  toAccount: string;
  /** The confirmation email. */
  confirmationEmail = '';
  /** The confirmation identifier. */
  confirmationNumber = '';
  /** List of receipt details. */
  transactions: ReceiptTransaction[];
  /** Additional text to add on footnote. */
  additionalFootnote = '';
  /** The state to redirect on navigation. */
  navigateTo: string;
  /** The state to redirect specific back navigation. */
  navigateBack: string;
  /** Flag to show banner */
  showBanner: boolean;
  /** True when FromAccount is an External Accout */
  isFromExternalAccount: boolean;

  sendOn?: string;

  constructor(args?: Partial<ReceiptSettings>) {
    Object.assign(this, args);
  }
}
