import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  DebitCard,
  DebitCardOptionItem,
  TravelNotification,
} from '@app/accounts/submodules/debit-card/models';
import { DialogService } from '@core/services';
import { AlertsIcons, FinancialIcons, NavigationIcons, UtilityIcons } from '@shared/enums';
import { dialogConfig, DialogData } from '@shared/models';

@Component({
  selector: 'app-debit-card-options',
  templateUrl: './debit-card-options.component.html',
  styleUrls: ['./debit-card-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebitCardOptionsComponent implements OnChanges {
  @Output() deleteNotification = new EventEmitter<TravelNotification>();
  @Output() navigate = new EventEmitter<string>();
  @Input() card: Partial<DebitCard>;
  @Input() notifications: TravelNotification[] = [];
  @Input() travelLimit: number;

  get isAtmCard() {
    return this.card.debitCardTypeDescription === 'ATM';
  }

  get allowTravel() {
    return this.notifications.length < this.travelLimit;
  }

  iconConfig = {
    size: '1.7em',
    paperAirplane: UtilityIcons.PaperAirplane,
    trash: {
      icon: UtilityIcons.Trash,
      size: '1em',
    },
    coinDollar: FinancialIcons.CoinDollarSign,
    chevron: {
      icon: NavigationIcons.ChevronRight,
      size: '.8em',
    },
  };
  options: DebitCardOptionItem[];

  constructor(private dialogService: DialogService) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.setOptions();
  }

  validateTravel(value: string) {
    if (this.allowTravel) {
      this.navigate.emit(value);
    } else {
      const dialogData = new DialogData({
        icon: AlertsIcons.ExclamationCircle,
        cancelText: null,
        okText: 'Close',
        title: 'Travel Notifications',
        content: '<p>You have reached the limit for active travel notifications</p>.',
      });

      dialogConfig.data = dialogData;
      dialogConfig.disableClose = true;
      this.dialogService.openCustom(dialogConfig);
    }
  }

  private setOptions(): void {
    this.options = [
      {
        active: this.card.allowReportLostOrStolen,
        icon: AlertsIcons.InfoCircle,
        label: 'Report Lost or Stolen',
        route: 'udb.accounts.debit-cards.report-lost-or-stolen',
      },
      {
        active: this.card.allowReplacementCard,
        icon: FinancialIcons.CreditCard,
        label: 'Request Replacement Card',
        route: 'udb.accounts.debit-cards.replacement',
      },
    ];
  }
}
