import { Inject } from '../../../decorators/decorators';

@Inject('$uibModalInstance', 'headerStr', 'footerStr', 'amount', 'selectedFrom', 'selectedTo')
export class PreTransferConfirmationModal {
  constructor(
    private _uibModalInstance: any,
    public headerStr: string,
    public footerStr: string,
    public amount: string,
    public selectedFrom: string,
    public selectedTo: string
  ) {}

  public $onInit(): void {}

  public close() {
    this._uibModalInstance.close(false);
  }

  public maskAccount(account: string): string {
    if (account.length > 20) {
      let split = account.split('-');
      let stringOne = split[0].substring(0, 3);
      let stringTwo = split[1].substring(0, 14);
      return (account = stringOne + stringTwo + '...');
    } else return account;
  }

  public confirm() {
    this._uibModalInstance.close(true);
  }
}
