export const E_DISCLOSURE_STATEMENT_TITLE = 'E-Disclosure Statement.';
export const PERSONAL_DEPOSIT_ACCOUNT_AGREEMENT_TITLE = 'Personal Deposit Account Agreement.';
export const PRIVACY_NOTICE_TITLE = 'Privacy Notice.';
export const ODL_TERMS_AND_CONDITIONS_TITLE = 'Overdraft Line of Credit Terms and Conditions.';
export const DISCLOSURE1_ID = 'disc-01';
export const DISCLOSURE2_ID = 'disc-02';
export const DISCLOSURE3_ID = 'disc-03';
export const DISCLOSURE4_ID = 'disc-04';
export const SUBMIT_BUTTON_TEXT = 'Submit';
export const SUBMIT_BUTTON_TEXT_CONTEXT = 'Processing...';
export const MODAL_WINDOW_CLASS = 'modal-service internal-error-modal';
export const DOCS_MODAL_WIDTH = '1090px';
export const DOCS_PANEL_CLASS = 'modal-overlay-dialog';
