import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support-view.component.html',
  styleUrls: ['./customer-support-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSupportViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
