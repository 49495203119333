import { createAction, props } from '@ngrx/store';
import { BrandingStateType } from '.';

const actionPrefix = '[Branding Settings]';

export const addBrandingAction = createAction(
  `${actionPrefix} addBrandingAction`,
  props<{ payload: BrandingStateType }>()
);

export const selectBrandingAction = createAction(`${actionPrefix} selectBrandingAction`, props<{ payload: string }>());

export const loadBrandsAction = createAction(
  `${actionPrefix} Load Brands`,
  props<{ payload: Array<BrandingStateType> }>()
);
