<div class="face">
  <div class="face__animated">
    <span *ngIf="happyFace; then happy; else sad"></span>
  </div>

  <div *ngIf="title" class="face__text">
    <h3 class="face__text__title">{{ title }}</h3>
    <br />
    <span *ngIf="message" class="face__text__message">{{ message }}</span>
  </div>

  <ng-template #happy>
    <svg
      class="face__svg-happy"
      viewBox="0 0 83 61"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Sparkle-2">
        <circle id="circle3" fill="#22986C" cx="69.5" cy="32.5" r="1.5"></circle>
        <circle id="circle2" fill="#F68035" cx="55.5" cy="1.5" r="1.5"></circle>
        <g
          id="Group"
          transform="translate(0.000000, 9.000000)"
          stroke="#F68035"
          stroke-linecap="square"
        >
          <path d="M2.5,0 L2.5,7" id="Line-3-Copy-3"></path>
          <path d="M5,3.5 L0,3.5" id="Line-3-Copy-2"></path>
        </g>
      </g>
      <g id="Sparkle-1" transform="translate(18.000000, 0.000000)">
        <circle id="circle1" fill="#2CC88E" cx="2.5" cy="2.5" r="2.5"></circle>
        <g
          id="Group-4"
          transform="translate(45.000000, 53.000000)"
          stroke="#CB2D0E"
          stroke-linecap="square"
        >
          <path d="M5,2.5 L0,2.5" id="Line-3-Copy-4"></path>
          <path d="M1.5,0 L1.5,7" id="Line-3-Copy-5"></path>
        </g>
      </g>

      <g id="Sparkle-3" transform="translate(0.000000, 5.000000)">
        <g
          id="Group-3"
          transform="translate(74.000000, 0.000000)"
          stroke="#1662D2"
          stroke-linecap="square"
        >
          <path d="M8,4.5 L0,4.5" id="Line-3-Copy"></path>
          <path d="M3.5,0 L3.5,10" id="Line-3"></path>
        </g>
        <circle id="circle4" fill="#CB2D0E" cx="1.5" cy="37.5" r="1.5"></circle>
        <circle id="circle5" fill="#1662D2" cx="27.5" cy="48.5" r="2.5"></circle>
      </g>
      <path
        d="M41,48.2708322 C29.9720833,48.2708322 21,39.4623027 21,28.6354161 C21,17.8085295 29.9720833,9 41,9 C52.0279167,9 61,17.8085295 61,28.6354161 C61,39.4623027 52.0279167,48.2708322 41,48.2708322 Z M41,10.6141948 C30.8783333,10.6141948 22.6441667,18.6986683 22.6441667,28.6354161 C22.6441667,38.5721638 30.8783333,46.6566373 41,46.6566373 C51.1216667,46.6566373 59.3558333,38.5721638 59.3558333,28.6354161 C59.3558333,18.6986683 51.1216667,10.6141948 41,10.6141948 L41,10.6141948 Z"
        id="Face-Full"
        fill="#22986C"
        fill-rule="nonzero"
      ></path>
      <g id="Eyes" transform="translate(30.000000, 20.000000)" fill-rule="nonzero" fill="#22986C">
        <path
          d="M5.54666667,3.05691255 C5.54666667,4.54236133 4.32011343,5.74655549 2.80708333,5.74655549 C1.29405324,5.74655549 0.0675,4.54236133 0.0675,3.05691255 C0.0675,1.57146378 1.29405324,0.367269623 2.80708333,0.367269623 C4.32011343,0.367269623 5.54666667,1.57146378 5.54666667,3.05691255 Z"
          id="Shape"
        ></path>
        <ellipse id="Oval" cx="19.1925" cy="3.05691255" rx="2.73958333" ry="2.68964293"></ellipse>
      </g>
      <path
        d="M41,42.8911372 C32.9933333,42.8911372 26.4795833,36.4957186 26.4795833,28.6354161 L26.4795833,27.8283187 L55.52125,27.8283187 L55.52125,28.6354161 C55.5204167,36.4961276 49.0066667,42.8911372 41,42.8911372 Z M28.1495833,29.4425135 C28.5745833,36.0396042 34.1758333,41.2757152 41,41.2757152 C47.8241667,41.2757152 53.4254167,36.0396042 53.8504167,29.4425135 L28.1495833,29.4425135 Z"
        id="Mouth"
        fill="#22986C"
        fill-rule="nonzero"
      ></path>
    </svg>
  </ng-template>
  <ng-template #sad>
    <svg
      class="face__svg-sad"
      viewBox="0 0 83 61"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Ohh-No" transform="translate(-5.000000, 0.000000)">
          <g
            id="face"
            transform="translate(28.000000, 9.000000)"
            fill-rule="nonzero"
            fill="#1662D2"
          >
            <path
              d="M20,39.2708322 C8.97208333,39.2708322 0,30.4623027 0,19.6354161 C0,8.80852947 8.97208333,0 20,0 C31.0279167,0 40,8.80812039 40,19.6354161 C40,30.4627118 31.0279167,39.2708322 20,39.2708322 Z M20,1.61378576 C9.87875,1.61419483 1.64416667,9.69825926 1.64416667,19.6354161 C1.64416667,29.5725729 9.87875,37.6566373 20,37.6566373 C30.12125,37.6566373 38.3558333,29.5721638 38.3558333,19.6354161 C38.3558333,9.69866833 30.12125,1.61419483 20,1.61419483 L20,1.61378576 Z"
              id="Shape"
            ></path>
            <path
              d="M27.75,31.2014943 C27.5314171,31.2028543 27.3213651,31.1183028 27.1666667,30.9666874 C23.2082899,27.0815952 16.7917101,27.0815952 12.8333333,30.9666874 C12.5124329,31.2817381 11.9921505,31.2817381 11.67125,30.9666874 C11.3503496,30.6516367 11.3503496,30.1408386 11.67125,29.8257879 C13.88036,27.6563859 16.8768999,26.4375812 20.0014583,26.4375812 C23.1260167,26.4375812 26.1225567,27.6563859 28.3316667,29.8257879 C28.5657311,30.0567122 28.6353421,30.4031927 28.5081231,30.7040797 C28.3809042,31.0049667 28.0818294,31.2011936 27.75,31.2014943 L27.75,31.2014943 Z"
              id="mouth"
            ></path>
            <path
              d="M14.5466667,14.0565035 C14.5466667,15.5419523 13.3201134,16.7461464 11.8070833,16.7461464 C10.2940532,16.7461464 9.0675,15.5419523 9.0675,14.0565035 C9.0675,12.5710547 10.2940532,11.3668606 11.8070833,11.3668606 C13.3200181,11.3670864 14.5464366,12.5711483 14.5466667,14.0565035 Z"
              id="Shape"
            ></path>
            <path
              d="M30.9320833,14.0565035 C30.9320833,15.5419523 29.7055301,16.7461464 28.1925,16.7461464 C26.6794699,16.7461464 25.4529167,15.5419523 25.4529167,14.0565035 C25.4529167,12.5710547 26.6794699,11.3668606 28.1925,11.3668606 C29.7054348,11.3670864 30.9318533,12.5711483 30.9320833,14.0565035 Z"
              id="Shape"
            ></path>
          </g>
          <path
            d="M83.5,37 C84.3284271,37 85,36.3684828 85,35.5894669 C85,34.810451 84.3284271,31 83.5,31 C82.6715729,31 82,34.810451 82,35.5894669 C82,36.3684828 82.6715729,37 83.5,37 Z"
            id="raindrop4"
            fill="#1662D2"
          ></path>
          <path
            d="M21,11 C22.1045695,11 23,10.0527242 23,8.88420038 C23,7.71567651 22.1045695,2 21,2 C19.8954305,2 19,7.71567651 19,8.88420038 C19,10.0527242 19.8954305,11 21,11 Z"
            id="raindrop1"
            fill="#1662D2"
          ></path>
          <path
            d="M75.5,12 C76.8807119,12 78,10.8422185 78,9.41402268 C78,7.98582685 76.8807119,1 75.5,1 C74.1192881,1 73,7.98582685 73,9.41402268 C73,10.8422185 74.1192881,12 75.5,12 Z"
            id="raindrop2"
            fill="#6495F6"
          ></path>
          <path
            d="M18.5,48 C19.8807119,48 21,46.8422185 21,45.4140227 C21,43.9858268 19.8807119,37 18.5,37 C17.1192881,37 16,43.9858268 16,45.4140227 C16,46.8422185 17.1192881,48 18.5,48 Z"
            id="raindrop3"
            fill="#6495F6"
          ></path>
          <circle id="dot3" fill="#6495F6" cx="72.5" cy="43.5" r="1.5"></circle>
          <circle id="dot2" fill="#1662D2" cx="6.5" cy="28.5" r="1.5"></circle>
          <circle id="dot1" fill="#6495F6" cx="59.5" cy="1.5" r="1.5"></circle>
        </g>
      </g>
    </svg>
  </ng-template>
</div>
