import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';

import { Category, SubCategory } from '@app/support/models';
import { SupportService } from '@app/support/services';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { LegacyRoute } from '@core/models';

@Component({
  selector: 'support-contact-us',
  templateUrl: './contact-us-section.component.html',
  styleUrls: ['./contact-us-section.component.scss'],
})
export class ContactUsSectionComponent implements OnInit {
  get subtitle(): string {
    const { selected, initial } = this.hints;
    return this.selectedCategory ? selected : initial;
  }

  get showCategories(): boolean {
    return !this.selectedCategory && !this.isLoading;
  }
  @Output() navigate = new EventEmitter<LegacyRoute>();
  @Input() riaUserFlag: boolean;
  @Input() riaUserIsLoading: boolean;
  @Input() riaPilotActive: boolean;

  items$: Observable<Category[]>;
  isLoading = true;
  riaUserFlag$ = this.supportViewFacade.isRIAUser$;

  private selectedCategory: Category = null;

  private hints = {
    initial: 'Help us direct you to the right place by choosing from below.',
    selected: 'Choose a topic so our representative is ready to help you.',
    ria: 'Get in touch with the right team so we can help.',
  };

  constructor(private supportService: SupportService, private supportViewFacade: SupportViewFacade) {}

  ngOnInit() {
    this.items$ = this.supportService.getCategories().pipe(
      map(categories => this.setMessageCategoriesImages(categories.data)),
      take(1),
      finalize(() => {
        this.isLoading = false;
      })
    );
  }
  private goTo(state: string, params: {} = null): void {
    this.navigate.emit({ state, params });
  }
  private setMessageCategoriesImages(categories: Array<Category>): Category[] {
    return categories.map(item => ({
      ...item,
      imageUrl: `support-${item.imageUrl.replace('.svg', '')}`,
    }));
  }
  public goToMessageCenter(data: SubCategory): void {
    const stateParams = {
      categoryId: data.categoryId,
      categoryName: data.categoryName,
      subCategoryId: data.id,
      subCategoryName: data.name,
      businessId: data.businessId,
    };

    this.goTo('udb.support.message', stateParams);
  }
  public selectCategory(category: Category): void {
    this.selectedCategory = category;
  }
  public selectNoRiaSubtitle(selected: string, initial: string): string {
    return this.selectedCategory ? selected : initial;
  }
}
