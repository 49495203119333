import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';

import { environmentDefaultLink, environmentLinks } from '@app/investor-checking/enums';
import { UserProfileViews } from '@app/user-profile/enums';
import { olbSettings, STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-investor-checking-footer',
  templateUrl: './investor-checking-footer.component.html',
  styleUrls: ['./investor-checking-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingFooterComponent implements OnInit {
  redirectUrl: string;
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(olbSettings) private settings: OlbSettings,
    private agreementsService: AgreementService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.redirectUrl = environmentLinks[this.settings.environment.toLowerCase()] || environmentDefaultLink;
  }

  openAccount() {
    this.agreementsService
      .updateAgreementsByValue(false, AasAgreementType.MultiAccountAgreementInvestorChecking)
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: response => {
          if (response.data) {
            sessionStorage.setItem('mAgreementDecision', 'true');

            this.state.go('udb.dashboard', { view: UserProfileViews.PersonalInfo });
          }
        },
      });

    window.open(this.redirectUrl, '_blank');
  }
}
