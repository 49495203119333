import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { AlertsIcons, NavigationIcons } from '@shared/enums';

type AlertNotificationType = 'success' | 'warning' | 'error' | 'info';

@Component({
  selector: 'app-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertNotificationComponent implements OnInit {
  @Input() alertType: AlertNotificationType = 'success';
  @Input() duration: number;
  @Input() politeness = 'polite';
  @Input() safeHtml: SafeHtml = null;
  @Input() dismissable = true;

  @Output() dismiss = new EventEmitter<never>();

  icons = {
    success: AlertsIcons.CheckCircle,
    warning: AlertsIcons.ExclamationCircle,
    info: AlertsIcons.InfoCircle,
    error: AlertsIcons.ExCircle,
  };
  closeIcon = NavigationIcons.Ex;

  private subSink = new SubSink();

  get alertIcon(): AlertsIcons {
    return this.icons[this.alertType];
  }

  get alertClass(): string {
    return `alert-notification--${this.alertType}`;
  }

  get progressBarStyles() {
    return {
      'animation-duration': `${this.duration}ms`,
    };
  }

  ngOnInit(): void {
    if (this.duration) {
      this.subSink.sink = timer(this.duration)
        .pipe(takeUntil(this.dismiss))
        .subscribe(() => {
          this.dismiss.emit();
        });
    }
  }
}
