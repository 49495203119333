export class StopPayment {
  sequenceId?: string;
  type?: string;
  stopType?: string;
  payeeDebitingCompany?: string;
  lowCheckNumber?: number;
  highCheckNumber?: number | string;
  amount?: number;
  stopCharge?: number;
  requestedDate?: Date;
  expirationDate?: Date;
  effectiveDate?: Date;
  checkDate?: Date;
  attempts?: number;
  accountId?: number | string;
  stopClearedCheck?: boolean;
}

export enum StopType {
  Check,
  ACH,
  Series,
}
