import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BILLPAYROUTERHELPER, billPayServiceProvider } from '@app/bill-pay/ajs-upgraded-provider';
import { Biller, PartialRecipient } from '@app/bill-pay/typings';
import { BillPayRouterHelper } from '@app/bill-pay/utils';
import { STATE } from '@core/tokens';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-electronic-checkpoint',
  templateUrl: './electronic-checkpoint.component.html',
  styleUrls: ['./electronic-checkpoint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class ElectronicCheckpointComponent implements OnInit {
  biller: Biller = {} as Biller;
  recipient: PartialRecipient = {} as PartialRecipient;
  showingMobileDetails: boolean = false;
  tabSelected: number = 0;

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}
  ngOnInit(): void {
    this.showingMobileDetails = false;
    this.biller = this.billPayRouterHelper.getBiller();
    console.log('biller', this.biller);
    this.recipient = this.billPayRouterHelper.getRecipient();
    if (!this.biller) this.state.go('udb.billPay.searchRecipient');
  }

  showEBillDetail() {
    this.showingMobileDetails = true;
  }
  hideEBillDetails() {
    this.showingMobileDetails = false;
  }
  goElectronicConfirmation() {
    this.state.go('udb.billPay.electronicConfirmation');
  }
  goeStatements() {
    this.state.go('udb.billPay.eStatements', { recipientId: this.recipient.payeeId });
  }

  toggleTab() {
    this.tabSelected = this.tabSelected === 0 ? 1 : 0;
  }
}
