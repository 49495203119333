import * as angular from 'angular';
import { services } from '../services/services';
import { SignatureCardConfig } from 'signature-card/signature-card.config';
import { UploadCardComponent } from './upload-card/upload-card.component';
import { UploadCardCompleteComponent } from './upload-card/upload-card-complete/upload-card-complete.component';
import { SendMailComponent } from './send-mail/send-mail.component';
import { UnableDownloadComponent } from './unable-download/unable-download.component';

export const SignatureCardModule = angular
  .module('udb.signaturecard', [services, 'ngFileUpload'])
  .component('uploadCard', UploadCardComponent)
  .component('uploadCardComplete', UploadCardCompleteComponent)
  .component('sendMail', SendMailComponent)
  .component('unableDownload', UnableDownloadComponent)
  .config(SignatureCardConfig).name;
