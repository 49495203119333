import { NgModule } from '@angular/core';

import { ModelsModule } from '../models/models.module';
import { ModelsHoldingsTabMatModule } from './models-holdings-tab-mat.module';
import { ModelsHoldingsTabUk2Module } from './models-holdings-tab-uk2.module';
import { ModelsHoldingsTabComponent } from './view/models-holdings-tab.component';
import { HoldingsModule } from '../holdings/holdings.module';

@NgModule({
  declarations: [ModelsHoldingsTabComponent],
  imports: [ModelsHoldingsTabMatModule, ModelsHoldingsTabUk2Module, ModelsModule, HoldingsModule],
  exports: [ModelsHoldingsTabComponent],
})
export class ModelsHoldingsTabModule {}
