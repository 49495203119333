import { PfmTransaction } from '@app/pfm/models/pfm-transaction.model';
import { PfmTransactionsRequest } from '@app/pfm/models/pfm-transactions-request.model';

import { Category } from '../../models/category';
import { SpendingFilters } from './spending-filters';

export class SpendingState {
  uncommittedFilters = new SpendingFilters(); // consumed by dropdown/modal while they're open
  committedFilters = new SpendingFilters(); // after the dropdown/modal is closed and filters applied
  lastFiltersRequest: PfmTransactionsRequest;
  transactions: PfmTransaction[] = [];
  transactionsLoading: boolean;
  spentByCategories: Category[] = [];

  constructor(args?: Partial<SpendingState>) {
    Object.assign(this, args);
  }
}
