<div class="fcol-sm-12 axos-tabs">
  <ul class="tabs-line">
    <li class="tab" [ngClass]="activeTab == 0 ? 'active' : ''">
      <a (click)="onLeftTabClick()">{{ leftTabText }}</a>
    </li>
    <li class="tab" [ngClass]="activeTab == 1 ? 'active' : ''">
      <a (click)="onRightTabClick()">{{ rightTabText }}</a>
    </li>
  </ul>
</div>
