<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Bill Pay How To's</h3>
  <ul class="how-to-list">
    <li>
      <button id="setup-payee" (click)="selectTopic(topics.SetupPayee)" class="link">
        How do I set up a payee?
      </button>
    </li>
    <li>
      <button id="setup-ebills" (click)="selectTopic(topics.SetupEbills)" class="link">
        How do I set up e-Bills?
      </button>
    </li>
    <li>
      <button id="setup-automatic-payments" (click)="selectTopic(topics.SetupAutomaticPayments)" class="link">
        How do I set up automatic e-Bill payments?
      </button>
    </li>
    <li>
      <button id="setup-recurring-payments" (click)="selectTopic(topics.SetupRecurringPayments)" class="link">
        How do I set up recurring bill payments?
      </button>
    </li>
    <li>
      <button id="cancel-recurring-payment" (click)="selectTopic(topics.CancelAutomaticRecurringPayment)" class="link">
        How do I cancel an automatic or recurring bill payment?
      </button>
    </li>
    <li>
      <button id="edit-recurring-payment" (click)="selectTopic(topics.EditAutomaticRecurringPayment)" class="link">
        How do I edit an automatic or recurring bill payment?
      </button>
    </li>
    <li>
      <button id="fix-bill-pay" (click)="selectTopic(topics.FixBillPay)" class="link">
        How can I fix my Bill Pay?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-setup-payee *ngSwitchCase="topics.SetupPayee"></how-to-setup-payee>
    <how-to-setup-ebills *ngSwitchCase="topics.SetupEbills"></how-to-setup-ebills>
    <how-to-setup-automatic-payments *ngSwitchCase="topics.SetupAutomaticPayments"></how-to-setup-automatic-payments>
    <how-to-setup-recurring-payments *ngSwitchCase="topics.SetupRecurringPayments"></how-to-setup-recurring-payments>
    <how-to-cancel-automatic-recurring-payment
      *ngSwitchCase="topics.CancelAutomaticRecurringPayment"
    ></how-to-cancel-automatic-recurring-payment>
    <how-to-edit-automatic-recurring-payment
      *ngSwitchCase="topics.EditAutomaticRecurringPayment"
    ></how-to-edit-automatic-recurring-payment>
    <fix-bill-pay *ngSwitchCase="topics.FixBillPay"></fix-bill-pay>
  </ng-container>
</ng-template>
