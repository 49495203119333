import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { SubSink } from '@axos/subsink';

import { OnlinePath } from '@app/click-switch/enums';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

import { ClickSwitchTermsModalComponent } from '../click-switch-terms-modal/click-switch-terms-modal.component';

@Component({
  selector: 'app-click-switch-terms-conditions',
  templateUrl: './click-switch-terms-conditions.component.html',
  styleUrls: ['./click-switch-terms-conditions.component.scss'],
})
export class ClickSwitchTermsConditionsComponent implements OnInit, OnDestroy {
  @Output() agree = new EventEmitter<boolean>();

  accepted = false;

  private bankName = '';
  private phone = '';
  private subsink = new SubSink();

  constructor(
    private dialogService: MatDialog,
    @Inject(olbSettings) private settings: OlbSettings
  ) {}

  ngOnInit(): void {
    this.bankName = this.settings.brandName + OnlinePath.bankText;
    if (this.settings.brand === 'axos') {
      this.phone = OnlinePath.axosPhoneNumber;
    } else if (this.settings.brand === 'nationwide') {
      this.phone = OnlinePath.nwPhoneNumber;
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  showTermsConditions(checked: boolean) {
    this.accepted = checked;

    if (this.accepted) {
      this.subsink.sink = this.dialogService
        .open(ClickSwitchTermsModalComponent, {
          data: {
            phoneNumber: this.phone,
            bankName: this.bankName,
          },
        })
        .afterClosed()
        .subscribe(userResponse => {
          this.accepted = userResponse;
          this.agree.emit(userResponse);
        });
    } else {
      this.agree.emit(false);
    }
  }
}
