export interface LineChartPluginOptions {
  shadowRadius: number;
  shadowColor: string;

  hoverLineWidth: number;
  hoverLineColor: string;
  hoverLineDash: number[];
}

const defaultOptions: LineChartPluginOptions = {
  shadowRadius: 4,
  shadowColor: 'rgba(45, 75, 98, 0.28)',

  hoverLineWidth: 1,
  hoverLineColor: '#494949',
  hoverLineDash: [10],
};

export class LineChartPlugin {
  // Chart.js plugin for line charts
  id: 'line';

  beforeInit(chartInstance) {
    // Initialize plugin options, use default(s) if no value(s) provided
    chartInstance.options.plugins.line = {
      ...defaultOptions,
      ...chartInstance.options.plugins.line,
    };
  }

  afterDatasetsDraw(chartInstance) {
    const pluginOptions: LineChartPluginOptions = chartInstance.options.plugins.line;
    const ctx = chartInstance.chart.ctx;

    if (pluginOptions.shadowRadius && pluginOptions.shadowColor) {
      // Draw a shadow on the line(s), vertical line that appears on hover and tooltip

      const _stroke = ctx.stroke;
      ctx.stroke = function() {
        ctx.save();
        ctx.shadowColor = pluginOptions.shadowColor;
        ctx.shadowBlur = pluginOptions.shadowRadius;
        _stroke.apply(this, arguments);
        ctx.restore();
      };
    }

    if (
      pluginOptions.hoverLineWidth &&
      pluginOptions.hoverLineColor &&
      pluginOptions.hoverLineDash &&
      chartInstance.tooltip._active &&
      chartInstance.tooltip._active.length
    ) {
      // Draw a vertical line on the hovered point

      const activePoint = chartInstance.tooltip._active[0];
      const tooltipPosition = activePoint.tooltipPosition();
      const topY = chartInstance.scales['y-axis-0'].top;
      const bottomY = chartInstance.scales['y-axis-0'].bottom;

      // Since the line is drawn on top of the hovered point draw 2 lines instead,
      //   to simulate that the line goes behind the dot

      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = pluginOptions.hoverLineWidth;
      ctx.strokeStyle = pluginOptions.hoverLineColor;
      ctx.setLineDash(pluginOptions.hoverLineDash);

      if (tooltipPosition.y - tooltipPosition.padding > 0) {
        // don't draw top half if the point is on the top corner
        ctx.moveTo(tooltipPosition.x, topY);
        ctx.lineTo(tooltipPosition.x, tooltipPosition.y - tooltipPosition.padding);
      }

      ctx.moveTo(tooltipPosition.x, tooltipPosition.y + tooltipPosition.padding);
      ctx.lineTo(tooltipPosition.x, bottomY);

      ctx.stroke();
      ctx.restore();
    }
  }
}

// todo once Chart.js is updated to >= 3.1.1 use line segments instead
//   more info: https://www.chartjs.org/docs/latest/samples/line/segments.html
// this workaround for v2.9 was based on: https://github.com/chartjs/Chart.js/issues/7523#issuecomment-648009716
Chart.defaults.dashedLineSegment = Chart.defaults.line;
Chart.controllers.dashedLineSegment = Chart.controllers.line.extend({
  draw() {
    let startIndex = 0;
    const meta = this.getMeta();
    const points = meta.data || [];
    const borderDash = this.getDataset().dash;
    const area = this.chart.chartArea;
    const originalDatasets = meta.dataset._children.filter(data => !isNaN(data._view.y));

    for (let i = 2; i <= borderDash.length; i++) {
      if (borderDash[i - 1] === borderDash[i]) continue;
      meta.dataset._view.borderDash = [borderDash[i - 1], borderDash[i - 1]];
      meta.dataset._children = originalDatasets.slice(startIndex, i);
      meta.dataset.draw();
      startIndex = i - 1;
    }

    meta.dataset._children = originalDatasets.slice(startIndex);
    meta.dataset.draw();
    meta.dataset._children = originalDatasets;

    points.forEach(point => point.draw(area));
  },
});
