<div class="d-flex flex-column">
  <ng-container *ngIf="!checkRia(); else riaContent">
    <button type="button" class="menu__trigger" [matMenuTriggerFor]="accountsMenu" (click)="expanded = !expanded">
      <span class="item--selected" [innerText]="selectedItem?.name"></span>
      <app-svg-icon class="chevron-icon ml-3" size="0.6em" [iconName]="openIcon"></app-svg-icon>
    </button>
  </ng-container>
  <ng-template #riaContent>
    <p class="ria-product-name">{{ selectedItem?.name }}</p>
  </ng-template>
  <p class="text-right font-weight-normal">{{ selectedItem?.details }}</p>
</div>

<mat-menu #accountsMenu (closed)="expanded = false" class="menu__account-select">
  <button type="button" class="item" mat-menu-item *ngFor="let item of filteredItems" (click)="selectItem.emit(item)">
    <img class="item__logo" [src]="item.icon" alt="Bank Logo" />

    <div class="item__details">
      <p class="font-weight-normal" [innerText]="item.name"></p>
      <p class="item__details--small" [innerText]="item.details"></p>
    </div>
  </button>
  <button type="button" class="item justify-content-center" mat-menu-item (click)="selectItem.emit({ id: -1 })">
    View All Accounts
  </button>
</mat-menu>
