import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Document } from '@app/accounts/models';
import { OlbSettings } from '@core/models';
import { DeliveryPreferences } from '@core/models/delivery-preferences.model';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'documents');
  }

  getDocuments(accountId: number, docTypes: string): WebApiResponse<Document> {
    return this.get(`${accountId}/types${docTypes}`);
  }

  getStatement(
    accountId: number,
    documentId: string,
    key: string,
    fileName: string,
    isTrading: boolean
  ): Observable<Blob> {
    return this.download(`statement/${accountId}/${documentId}/${key}/${fileName}`, {
      isTrading: isTrading.toString(),
    });
  }

  getTaxForm(documentId: string, key: string, fileName: string): Observable<Blob> {
    return this.download(`tax-form/${documentId}/${key}/${fileName}`, {});
  }

  getDeliveryPreferences(accountId: number): WebApiResponse<DeliveryPreferences> {
    return this.get(`delivery-preferences/${accountId}`);
  }

  updateDeliveryPreferences(deliveryPreferences: DeliveryPreferences): WebApiResponse<DeliveryPreferences> {
    return this.post(`delivery-preferences`, deliveryPreferences);
  }
}
