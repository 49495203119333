import { ACCOUNTS } from '../../constants/accounts';

export const ExternalAccountActions = {
  loadExternalAccounts(accounts: Array<ExternalAccount>) {
    return {
      type: ACCOUNTS.EXTERNAL.LOAD,
      payload: accounts,
    };
  },

  addExternalAccounts(accounts: Array<ExternalAccount>) {
    return {
      type: ACCOUNTS.EXTERNAL.ADD,
      payload: accounts,
    };
  },

  updateExternalAccounts(accounts: Array<ExternalAccount>) {
    return {
      type: ACCOUNTS.EXTERNAL.UPDATE,
      payload: accounts,
    };
  },

  removeExternalAccount(accountId: number) {
    return {
      type: ACCOUNTS.EXTERNAL.REMOVE,
      payload: accountId,
    };
  },

  flushExternalAccounts() {
    return {
      type: ACCOUNTS.EXTERNAL.FLUSH,
    };
  },
};
