import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tx-footer-multiple',
  templateUrl: './tx-footer-multiple.component.html',
  styleUrls: ['./tx-footer-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxFooterMultipleComponent {
  @Input() selected: number;
  @Output() onFileDispute = new EventEmitter<void>();
  @Output() onClearSelected = new EventEmitter<void>();

  constructor() {}

  hasNoSelection: Boolean;

  fileDispute() {
    this.hasNoSelection = !this.selected;
    if (this.hasNoSelection) {
      return;
    }

    this.onFileDispute.emit();
  }

  clearSelected() {
    this.onClearSelected.emit();
  }
}
