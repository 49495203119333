<div class="col-lg-12 disclaimer row">
  <div class="disclaimer__note col-lg-6 row">
    <p>
      <b>NOTE:</b>
      Estimated delivery for replacement card(s) is 7-10 business days. To rush the delivery of your replacement cards
      would be an additional fee.
    </p>
    <button type="button" id="show-terms" class="link" (click)="showTerms()">
      View Terms
      <app-svg-icon [iconName]="chevronIconConfig.icon" [styles]="chevronIconConfig.styles"></app-svg-icon>
    </button>
  </div>
  <div class="fee col-lg-6">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="dueFee" (ngModelChange)="feeSelection.emit($event)">
      <div class="col-lg-12 fee__option">
        <mat-radio-button id="without-fee" class="mat-radio--align-start" [value]="false" [disabled]="!replace">
          <span class="fee__description">FREE Replacement Card Delivery:</span>
          <p class="mt-2">(7-10 business days)</p>
        </mat-radio-button>
      </div>

      <div class="col-lg-12 fee__option">
        <mat-radio-button id="with-fee" class="mat-radio--align-start" [value]="true" [disabled]="!replace">
          <span class="fee__description">Rush Replacement Card Delivery:</span>
          <p class="my-2">(3 business days)</p>
          <span class="fee__amount">{{ feeAmount | currency }}</span>
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
</div>
