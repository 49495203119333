import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import {
  AuthRequest,
  AuthResponse,
  ChangePasswordRequest,
  UnlockRequest,
  UnlockUserStatusResponse,
} from '@app/authentication/models';
import { getBrandProperty } from '@app/store/selectors';
import { AuthMethod, BrandProperty } from '@core/enums';
import { MultiFactorRequest } from '@core/models';
import { AuthService, DialogService, EnrollmentService, MultifactorService } from '@core/services';
import { olbSettings, STATE, STATE_PARAMS } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AlertsIcons } from '@shared/enums';
import { DialogData, SecurityQuestion } from '@shared/models';

@Component({
  selector: 'app-account-unlock',
  templateUrl: './account-unlock.component.html',
  styleUrls: ['./account-unlock.component.scss'],
})
export class AccountUnlockComponent implements OnInit, OnDestroy {
  answer: string;
  credentials: AuthRequest;
  displayOTP: boolean;
  displayInvalidLinkMessage: boolean;
  displayQuestion: boolean;
  displayQuestions: boolean;
  displaySetNewPassword: boolean;
  displayTermsAndConditions: boolean;
  isBusy: boolean;
  isCheckingAnswer: boolean;
  isLoading: boolean;
  isNewPasswordValid: boolean;
  isResetPassword: boolean;
  isSavingNewPassword: boolean;
  password: string;
  question: string;
  randomQuestionId: string;
  securityQuestions: SecurityQuestion[];
  showAlert: boolean;
  stepMessage: string;
  token: string;
  unlockRequest: UnlockRequest;
  unlockUserStatusResponse: UnlockUserStatusResponse;
  userCompletedRegistration: boolean;
  userId: string;
  nonCIFUser: boolean;
  userName: string;
  $supportPhone: Observable<string>;
  phoneNumber: string;
  userPhoneNumber: string;
  message = '';
  otp = '';
  loginState = 1;
  showEmailBtn = false;
  questionsToken: string;
  rememberDeviceHash: string;
  questionSubmitted: boolean;
  unlockAccountRequestToken: string;
  securityQuestionsToken: string;

  private subsink = new SubSink();
  private storage: Storage;
  private auth: AuthMethod;

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private multifactorService: MultifactorService,
    private authService: AuthService,
    private serviceHelper: ServiceHelper,
    private enrollmentService: EnrollmentService,
    private dialogService: DialogService,
    private store: Store
  ) {
    this.storage = window.sessionStorage;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    const token = this.params['token'];
    const resetPasswordToken = this.params['ut'];
    if (resetPasswordToken) {
      this.displayOTP = false;
      this.getUserStatus(token, resetPasswordToken);
    } else {
      this.getSecurityQuestion(token);
    }
    this.$supportPhone = this.store.select(getBrandProperty(BrandProperty.ContactPhoneNumber));
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  agreeTerms(): void {
    this.isLoading = true;

    const request = {
      token: this.token,
      id: 0,
      username: this.userName,
      password: '',
      nonCIFUser: this.nonCIFUser,
    };

    this.enrollmentService.completeRegistrationPasswordReset(request).subscribe({
      next: () => {
        this.isLoading = false;
        this.userCompletedRegistration = true;
        this.displayTermsAndConditions = false;
        this.displayQuestions = true;
      },
    });
  }

  checkSecurityQuestionAnswer(): void {
    this.questionSubmitted = true;
    if (!this.answer) return;
    this.isCheckingAnswer = true;

    const answeredQuestion = this.securityQuestions.find(c => c.questionId === this.randomQuestionId);

    answeredQuestion.answer = this.answer;
    answeredQuestion.questionText = '';

    this.unlockRequest = {
      token: this.token,
      challengeQuestion: this.securityQuestions,
    };

    this.authService.checkSecurityQuestionAnswer(this.userName, this.unlockRequest).subscribe({
      next: res => {
        if (res.data.success) {
          this.displayQuestion = false;
          this.stepMessage = res.data.stepMessage;
          this.displaySetNewPassword = true;
          this.unlockAccountRequestToken = res.data.unlockAccountRequestToken;
          this.token = res.data.unlockAccountRequestToken;
        } else {
          if (res.data.exceededAllowedLimit) {
            this.displayQuestion = false;
            this.stepMessage = res.data.stepMessage;
            this.displayInvalidLinkMessage = true;
            this.dialogService.open(
              new DialogData({
                icon: AlertsIcons.Circle,
                title: res.data.errorTitle,
                content: res.data.errorBody,
                cancelText: '',
                okText: 'Ok',
              })
            );
          } else {
            this.showAlert = true;
          }
        }
        this.isCheckingAnswer = false;
      },
      error: err => {
        this.serviceHelper.errorHandler(err);
        this.isCheckingAnswer = false;
      },
    });
  }

  hideAlert() {
    this.showAlert = false;
  }

  setNewPasswordValidity(isValid: boolean): void {
    this.isNewPasswordValid = isValid;
  }

  loginUser(comesFromResetPassword: boolean): void {
    this.isBusy = true;

    this.credentials = {
      userName: this.userName,
      password: this.password,
      brandCode: this.env.brandCode,
      deviceId: '',
      deviceSignature: '',
    };

    const token = this.token;

    this.authService.login(this.credentials, token).subscribe({
      next: res => {
        this.loginHandler(res.data, comesFromResetPassword);
      },
      error: () => {
        this.dialogService
          .open(
            new DialogData({
              icon: AlertsIcons.Circle,
              title: 'Error',
              content: `<p>An error occurred while trying to login, please try again.</p>`,
              cancelText: '',
              okText: 'Ok',
            })
          )
          .afterClosed()
          .subscribe(() => {
            this.goToDashboard();
          });
      },
    });
  }

  goToDashboard(): void {
    this.state.go('udb.dashboard');
  }

  goToSetNewPassword(): void {
    this.displayQuestions = false;
    this.stepMessage = 'Please enter a new password';
    this.displaySetNewPassword = true;
    this.isBusy = false;
  }

  setNewPassword(): void {
    this.isSavingNewPassword = true;

    let token = this.isResetPassword ? this.securityQuestionsToken : this.unlockAccountRequestToken;
    const changePasswordRequest: ChangePasswordRequest = {
      password: this.password,
      token,
    };

    this.authService.setNewPassword(this.userName, changePasswordRequest).subscribe({
      next: res => {
        if (res.data !== 'Success') {
          this.dialogService.open(
            new DialogData({
              icon: AlertsIcons.CheckCircle,
              title: 'Error',
              content: `<p>${res.data}</p>`,
              cancelText: '',
              okText: 'Ok',
            })
          );
        } else {
          this.displaySetNewPassword = false;
          this.stepMessage = '';

          let content = `<p>Your password has been updated. For your security, if you have biometric authentication activated, we have de-activated your biometric authentication.  Please re-enroll for continued biometric logins.</p>`;

          if (this.nonCIFUser) {
            content = `<p>Your password has been reset. For your security, if you have biometric authentication activated, we have de-activated your biometric authentication.  You can now continue to use the mobile app.</p>`;
          } else if (this.isResetPassword) {
            this.goToDashboard();
          }
          this.dialogService
            .open(
              new DialogData({
                icon: AlertsIcons.CheckCircle,
                title: 'Success',
                content,
                cancelText: '',
                okText: 'Ok',
              })
            )
            .afterClosed()
            .subscribe(() => {
              if (!this.isResetPassword && !this.nonCIFUser) {
                this.loginUser(false);
              }
            });
          this.isSavingNewPassword = false;
        }
      },
      error: err => {
        if (err.error?.statusCode === 400) {
          this.dialogService.open(
            new DialogData({
              icon: AlertsIcons.ExclamationCircle,
              title: 'Error',
              content: `<p>${err.error.message}</p>`,
              cancelText: '',
              okText: 'Ok',
            })
          );
        } else {
          this.serviceHelper.errorHandler(err);
        }
        this.isSavingNewPassword = false;
      },
    });
  }

  resetPassword(token: string, resetPasswordToken: string) {
    this.authService.checkResetPasswordLinkValidity(token, resetPasswordToken).subscribe({
      next: ({ data }) => {
        const { userId, username, phoneNumber, maskedEmail, token: newToken, nonCIFUser } = data;
        this.userId = userId;
        this.userName = username;
        this.userPhoneNumber = phoneNumber;
        this.token = newToken;
        this.displayOTP = true;
        this.nonCIFUser = nonCIFUser;
        if (maskedEmail) sessionStorage.setItem('maskedEmail', maskedEmail);
        if (phoneNumber) {
          sessionStorage.setItem('maskedCellPhone', `(***) ***-${phoneNumber.substr(phoneNumber.length - 4)}`);
        }
      },
      error: exception => {
        if (exception.error.statusCode === 400) {
          this.displayInvalidLinkMessage = true;
          this.stepMessage = exception.error.message;
        } else {
          this.serviceHelper.errorHandler(exception);
        }
        this.isBusy = false;
      },
    });
  }

  getUserStatus(token: string, resetPasswordToken: string) {
    this.isResetPassword = true;

    this.authService.getUserStatus(token).subscribe({
      next: res => {
        this.isBusy = false;
        this.unlockUserStatusResponse = res.data;
        this.resetPassword(token, resetPasswordToken);
      },
      error: exception => {
        this.isBusy = false;
        if (exception.error.statusCode === 400) {
          this.stepMessage = exception.error.message;
          this.displayInvalidLinkMessage = true;
        } else {
          this.serviceHelper.errorHandler(exception);
        }
      },
    });
  }

  loginHandler(res: AuthResponse, comesFromResetPassword: boolean) {
    this.multifactorService.setUserInformation(this.credentials.userName, res.telephoneNumber, res.email);

    this.multifactorService.updateDeviceInformation(res);

    sessionStorage.setItem('username', this.credentials.userName);
    sessionStorage.setItem('token', res.token);
    sessionStorage.setItem('maskedCellPhone', `(***) ***-${res.telephoneNumber}`);
    sessionStorage.setItem('maskedEmail', res.email);

    localStorage.setItem('firstName', res.fullName);
    localStorage.setItem('lastLogin', res.lastLogin);

    let expirationToken = new Date();
    expirationToken = new Date(expirationToken.getTime() + res.tokenExpires * 1000);
    sessionStorage.setItem('expires', expirationToken.getTime().toString());

    sessionStorage.setItem('userbrand', res.brandId.toString());

    sessionStorage.setItem('userFacingBrand', this.env.brandId.toString());

    if (!comesFromResetPassword) {
      this.goToDashboard();
    } else {
      this.isBusy = false;
      this.displayQuestions = true;
    }
  }

  codeSent(type: AuthMethod): void {
    this.isBusy = true;
    this.auth = type;
    this.message = '';

    const request: MultiFactorRequest = {
      userId: this.userId,
      username: this.userName,
      authenticationMethod: type,
      challengeToken: this.token,
    };
    const isEmail = request.authenticationMethod === AuthMethod.Email;

    this.multifactorService
      .requestOtp(request)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: res => {
          this.rememberDeviceHash = res.data.userHash;
          this.setSignInFlowToken(res.data.authenticationToken);
          this.storage.setItem('authRequest', JSON.stringify(request));
          this.otp = !isEmail && res.data.otp ? res.data.otp : '';

          setTimeout(() => {
            document.getElementById('access-code-entry')?.focus();
          }, 200);
          this.loginState = 2;
        },
        error: () => {
          if (isEmail) {
            this.showEmailBtn = false;
          } else {
            this.serviceHelper.errorHandler.bind(this.serviceHelper);
          }
          this.serviceHelper.errorHandler.bind(this.serviceHelper);
        },
      });
  }

  codeValidated(code: string, remember: boolean): void {
    this.isBusy = true;
    this.message = '';
    const request: MultiFactorRequest = {
      username: this.userName,
      otp: code,
      authenticationToken: this.getSignInFlowToken(),
      rememberDevice: remember,
      authenticationMethod: this.auth,
    };

    if (remember) {
      this.multifactorService.setDID(this.multifactorService.getDeviceInformation().deviceId);
    }

    this.multifactorService.evaluateOtp(request).subscribe({
      next: res => {
        sessionStorage.setItem('isSBBAvailable', res.data.isSBBAvailable.toString());

        this.isBusy = false;
        this.displayOTP = false;

        if (!this.unlockUserStatusResponse.isUserInOlb) {
          this.displayTermsAndConditions = true;
        } else {
          this.displayQuestions = true;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.isBusy = false;
        if (err.status >= 500) {
          this.serviceHelper.errorHandler(err, true);
        } else {
          this.message =
            err.error.message ||
            'Something went wrong.Something unexpected went wrong on our end. Please try again. Thank you.';
        }
      },
    });
  }

  handleSaveSecurityQuestionToken(token: string): void {
    this.securityQuestionsToken = token;
  }

  private getSecurityQuestion(token: string) {
    const v2 = !!this.params['v2'];
    this.authService.getSecurityQuestion(token, v2).subscribe({
      next: res => {
        this.userName = res.data.username;
        this.token = res.data.token;
        this.securityQuestions = res.data.challengeQuestions;
        const randomQuestion = res.data.challengeQuestions[0];

        this.randomQuestionId = randomQuestion.questionId;
        this.question = randomQuestion.questionText;
        this.stepMessage =
          'For your protection before resetting your password, please answer one of your security questions.';
        this.displayQuestion = true;
        this.isBusy = false;
      },
      error: exception => {
        this.displayInvalidLinkMessage = true;
        this.stepMessage = exception.error?.message;
        if (this.stepMessage !== 'Please call customer support at 1-888-509-9951 to help recover your password.') {
          this.stepMessage =
            'In order to login to online banking, we must first verify your contact information. Please call customer service at 1-888-509-9951';
        }
        if (exception.error?.statusCode === 400) {
          this.dialogService.open(
            new DialogData({
              icon: AlertsIcons.Circle,
              title: 'Error',
              content: "<p>Oops. You haven't set up your security questions.</p>",
              cancelText: '',
              okText: 'Ok',
            })
          );
        }
        this.isBusy = false;
      },
    });
  }

  private getSignInFlowToken(): string {
    return this.storage.getItem('SignInFlowToken');
  }

  private setSignInFlowToken(token: string, authInfoLiteToken?: string): void {
    this.storage.setItem('SignInFlowToken', token);
    if (authInfoLiteToken) {
      this.storage.setItem('AuthInfoLiteToken', authInfoLiteToken);
    }
  }
}
