import { IWindowService } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import NgRedux from 'ng-redux';
import { FlowService } from 'services/flow.service';
import { PaymentsService } from 'services/payments.service';
import { IAccountsService } from 'services/typings/IAccountsService';
import { FundingState } from 'typings/app/FundingState';

import { Inject } from '../../../decorators/decorators';
import { FlowType } from '../../../typings/app/flow-type.enum';
import { CachedAccountsService } from './../../../services/cached-accounts.service';
@Inject(
  'accountsService',
  'cachedAccountsService',
  '$window',
  'serviceHelper',
  '$state',
  'paymentsService',
  '$ngRedux',
  'env',
  '$stateParams',
  'flowService'
)
export class AddExternalAccountController {
  /** funding flow */
  fundingState: FundingState;

  //Models
  externalAccount: OlbAccount = { payverisCategory: 0, routingNumber: null };
  reenterAccountNumber: string;
  routingNumberFocus = false;
  isAccountAdded = false;
  showExample = true;

  //Variable to show when is processing
  isSaving = false;
  inlineError: string;
  facingBrandId: number;
  flow: string;
  flowTypeEnum = FlowType;

  private unsubscribe: Function;

  constructor(
    private readonly accountsService: IAccountsService,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly window: IWindowService,
    private readonly serviceHelper: IServiceHelper,
    private readonly stateService: IStateService,
    private readonly paymentsService: PaymentsService,
    private readonly ngRedux: NgRedux.INgRedux,
    private readonly env: OlbSettings,
    private readonly stateParams: IStateParamsService,
    private readonly flowService: FlowService
  ) {}

  $onInit(): void {
    this.initialize();
    this.unsubscribe = this.ngRedux.subscribe(this.initialize.bind(this));
    this.facingBrandId = this.env.facingBrandId;
  }

  /**
   * When the controller is destroyed,
   * unsubscription to the state changes is performed
   */
  $onDestroy() {
    this.unsubscribe();
  }

  /** Close alert */
  closeErrorMessage(): void {
    this.inlineError = null;
  }

  /** Constrains the inputs to allow only numbers
   * @param $event The event triggered by the input
   */
  allowOnlyNumbers($event: KeyboardEvent): void {
    const { charCode, keyCode } = $event;
    if (!((charCode >= 48 && charCode <= 57) || keyCode === 8)) {
      $event.preventDefault();
    }
  }

  /**
   * Gets the bank name based on the routing number.
   */
  getBankName(): void {
    this.closeErrorMessage();
    this.routingNumberFocus = false;
    this.externalAccount.bankName = null;
    const { routingNumber } = this.externalAccount;

    if (!routingNumber) return;

    this.paymentsService
      .getBankName(routingNumber)
      .then(res => {
        this.externalAccount.bankName = res.data;
      })
      .catch(err => {
        //If the router number is not present in the database, no error message should be shown to the user
        if (err.status === 404 && err.data.message === 'Unknown Routing Number') {
          this.externalAccount.bankName = '';
          return;
        }
        this.showErrorMessage(err);
      });
  }

  /** Manage the functionality of the "back" button */
  goBack(): void {
    const accounts = this.stateParams['accounts'];
    this.closeErrorMessage();
    if (
      (this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.Funding) && accounts?.length <= 0) ||
      (this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.MoveMoney) && accounts?.length <= 0) ||
      (this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.AxosInvest) && accounts?.length <= 0) ||
      (this.flowService.isRunningFlow(this.flow, this.flowTypeEnum.ManagePortfoliosTransFun) && accounts?.length <= 0)
    ) {
      this.stateService.go('udb.dashboard.account-aggregation', { flow: this.flow });
    } else {
      if (accounts.length > 0) {
        this.stateService.go('udb.dashboard.account-aggregation.auth-success', { flow: this.flow, accounts });
      } else {
        this.window.history.back();
      }
    }
  }
  /** Depending on from which states comes, redirects to a different view */
  goToNextStep() {
    if (this.fundingState.isRunning) {
      //ToDo: Change this part to work with the new redirect process.
      this.window.sessionStorage.removeItem('accountsToBeFunded');
      this.window.location.href = '/';
      return;
    }
    this.stateService.go('udb.accounts.dashboard');
  }

  /** Calls to the account service to add the external account */
  addExternalAccountByMicrodeposits(): void {
    this.isSaving = true;

    this.accountsService
      .addExternalAccount(this.externalAccount)
      .then(({ data }) => {
        this.isSaving = false;
        this.isAccountAdded = true;
        this.externalAccount = data;

        const recentlyAddedAccount: ExternalAccount = {
          accountCategory: +(data as ExternalAccount).accountCategory == 0 ? 'Checking' : 'Savings',
          bankName: data.bankName,
          externalAccountId: (data as ExternalAccount).externalAccountId,
          status: this.mapStatus(+data.status),
          nickname: (<any>data).nickName,
          accountMask: (data as ExternalAccount).accountMask,
          isPendingVerification: +data.status == 2 ? true : false,
          accountNumber: (data as any).account,
          isSBB: (data as any).isSBB,
        };
        recentlyAddedAccount.displayName = this.cachedAccountsService.buildDisplayName(recentlyAddedAccount);
        this.cachedAccountsService.addExternalAccounts([recentlyAddedAccount]);
      })
      .catch(this.showErrorMessage.bind(this));
  }

  /** When init and every time the state changes this is executed reassingning the funding state */
  private initialize() {
    this.fundingState = this.ngRedux.getState().funding;
    this.flow = this.stateParams['flow'];
  }

  /**
   * Shows an error modal with the message provided
   * @param err Error message
   */
  private showErrorMessage(err: ApiError) {
    this.isSaving = false;
    this.inlineError = err.data.message;
    this.serviceHelper.scrollToTop();
  }

  private mapStatus(status: number): string {
    switch (status) {
      case 0:
        return 'Active';
      case 1:
        return 'In Process';
      case 2:
        return 'Pending Activation';
    }
    return '';
  }
}
