import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Goal } from '@app/axos-invest/models';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-goal-header',
  templateUrl: './goal-header.component.html',
  styleUrls: ['./goal-header.component.scss'],
})
export class GoalHeaderComponent {
  @Input() goal: Goal;
  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() subtitle1: string;
  @Input() subtitle2: string;
  @Input() showModifiedBanner = false;
  @Input() displayErrorBanner = false;
  @Input() textErrorBanner: string;
  @Input() modifiedBannerText: string;
  @Output() goBack = new EventEmitter<void>();
  backIcon = NavigationIcons.ArrowBack;
}
