<div class="section-header">
  Authorized Parties / Trustees
</div>
<ng-container *ngIf="authorizedParties && authorizedParties.authorizedParties.length > 0; else emptyState">
  <div class="list">
    <div class="list-row divider">
      <span class="title">Name(s)</span>
      <div class="value-group">
        <span *ngFor="let name of authorizedParties.authorizedParties" class="value">{{ name }}</span>
      </div>
    </div>
    <div class="list-row">
      <span class="title">Can Act Independently</span>
      <span class="value">{{ authorizedParties.canActIndependently ? 'Yes' : 'No' }}</span>
    </div>
  </div>
</ng-container>

<ng-template #emptyState>
  <div class="list">
    <div class="list-row">Not Yet Assigned</div>
  </div>
</ng-template>
