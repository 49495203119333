import { createAction, props } from '@ngrx/store';
import { SharedAccountsByAdvisorResponse, SharedAccountsResponse, SharedAccountsStateType } from '..';

const actionPrefix = '[Shared Accounts]';
const actionPrefixByAdvisor = '[Shared Accounts By Advisor]';

export const addSharedAccounts = createAction(
  `${actionPrefix} Add Shared Accounts`,
  props<{ payload: SharedAccountsStateType }>()
);

export const addSharedAccountsPrompting = createAction(
  `${actionPrefix} Add Shared Accounts Prompting`,
  props<{ payload: SharedAccountsStateType }>()
);

export const addUpdateSharedAccounts = createAction(
  `${actionPrefix} Add or Update Shared Accounts`,
  props<{ payload: SharedAccountsResponse }>()
);

export const addSharedAccountsByAdvisor = createAction(
  `${actionPrefixByAdvisor} Add Shared Accounts By Advisor`,
  props<{ payload: SharedAccountsByAdvisorResponse }>()
);

export const addUpdateSharedAccountsByAdvisor = createAction(
  `${actionPrefixByAdvisor} Add or Update Shared Accounts By Advisor`,
  props<{ payload: SharedAccountsByAdvisorResponse }>()
);
