import { RecipientInformationController } from './recipient-information.controller';

export const RecipientInformationComponent: ng.IComponentOptions = {
  controller: RecipientInformationController,
  controllerAs: '$ctrl',
  templateUrl: 'bill-pay/pay/recipients/recipient-information/recipient-information.tpl.html',
  bindings: {
    recipient: '<?',
    paymentsHistory: '<?',
    scheduledPayments: '<?',
    loadScheduledPayments: '&',
  },
};
