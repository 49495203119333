import { Inject } from '../../decorators/decorators';
import { UpcomingTransfersController } from './upcoming-transfers.controller';

@Inject('$stateProvider')
export class UpcomingTransfersConfig {
  constructor($stateProvider: ng.ui.IStateProvider) {
    $stateProvider.state('udb.transfers.upcomingTransfers', {
      url: '/UpcomingTransfers',
      views: {
        'transfer-content': {
          templateUrl: 'transfers/upcoming-transfers/upcoming-transfers.tpl.html',
          controller: UpcomingTransfersController,
          controllerAs: '$ctrl',
        },
      },
    });
  }
}
