export class SpousalBeneficiary {
  fullName: string;
  relationship: string;
  birthday: string;
  percentage: number;

  constructor(args?: Partial<SpousalBeneficiary>) {
    Object.assign(this, args);
  }
}
