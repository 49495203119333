import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { openClose } from '@app/user-profile/animations';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  animations: [openClose],
})
export class PasswordStrengthComponent implements OnInit, OnDestroy {
  @Input() passwordField: UntypedFormControl;

  uppercase: { icon: AlertsIcons; colour: string };
  number: { icon: AlertsIcons; colour: string };
  lowercase: { icon: AlertsIcons; colour: string };
  minlength: { icon: AlertsIcons; colour: string };
  symbol: { icon: AlertsIcons; colour: string };
  progressValue = 0;
  showChecker: boolean;
  strength = 'Weak';
  iconSize = '1.125rem';

  private errorState = {
    icon: AlertsIcons.Circle,
    colour: 'var(--danger)',
  };
  private okState = {
    icon: AlertsIcons.CheckCircle,
    colour: 'var(--success)',
  };
  private subscription: Subscription;

  ngOnInit(): void {
    this.uppercase = this.errorState;
    this.number = this.errorState;
    this.lowercase = this.errorState;
    this.minlength = this.errorState;
    this.symbol = this.errorState;

    this.subscription = this.passwordField.valueChanges.subscribe(() => {
      const { dirty, value, errors } = this.passwordField;
      this.showChecker = dirty || value;
      let errorCount = 0;
      if (errors) {
        errorCount = Object.keys(errors).filter(key => key !== 'maxlength').length;
      }
      this.strength = this.calculateStrength(errorCount);
      this.progressValue = 5 - errorCount;

      this.determineIcons();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private calculateStrength(errorCount: number): string {
    if (errorCount < 2) {
      return 'Strong';
    } else if (errorCount < 4) {
      return 'Medium';
    } else {
      return 'Weak';
    }
  }

  private determineIcons(): void {
    this.uppercase = this.passwordField.hasError('uppercase') ? this.errorState : this.okState;
    this.number = this.passwordField.hasError('number') ? this.errorState : this.okState;
    this.lowercase = this.passwordField.hasError('lowercase') ? this.errorState : this.okState;
    this.minlength =
      this.passwordField.hasError('minlength') || this.passwordField.hasError('required')
        ? this.errorState
        : this.okState;
    this.symbol = this.passwordField.hasError('symbol') ? this.errorState : this.okState;
  }
}
