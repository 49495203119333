import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';

import { compareAsc, compareDesc, parseISO } from 'date-fns';

import { ColumnOrder } from '@app/pfm/enums/column-order.enums';
import { SortOrder } from '@app/pfm/enums/sort-order.enum';
import { PfmTransaction } from '@app/pfm/models/pfm-transaction.model';
import { PfmTransactionsRequest } from '@app/pfm/models/pfm-transactions-request.model';
import { PfmService } from '@app/pfm/services/pfm.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FilesIcons, NavigationIcons, UtilityIcons } from '@shared/enums';
import { PfmIcons } from '@shared/enums/svg-icons/pfm-icons.enum';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionListComponent implements OnChanges {
  get downloadStyle() {
    const styles = {
      width: '2rem',
      height: '2rem',
      position: 'relative',
      top: '0.6rem',
      fill: '#8f8f8f',
    };
    if (this.transactionCount > 0) {
      styles.fill = '#0070D9';
    }

    return styles;
  }

  get transactionCount(): number {
    return this.filteredTransactions?.length ?? 0;
  }
  @Input()
  transactions: PfmTransaction[];

  @Input()
  loadingTransactions = true;
  @Input()
  filters: PfmTransactionsRequest;
  filteredTransactions: PfmTransaction[] = [];
  searchText = '';
  sortedBy: keyof PfmTransaction = 'date';
  sortedOrder = SortOrder.Descending;
  sortOrderEnum = SortOrder;
  isCollapsed: boolean;
  downloadingTransactions = false;

  accountOptions: any[] = [{ label: 'Recategorize transaction', isCollapsible: true }];
  expandedDetailTransactions = new Set<PfmTransaction>();
  expandedRecategorizeTransactions = new Set<PfmTransaction>();

  icons = {
    ChevronUp: NavigationIcons.ChevronUp,
    ChevronDown: NavigationIcons.ChevronDown,
    Download: FilesIcons.DownloadFile,
    SortDescArrow: NavigationIcons.TriangleUp,
    SortAscArrow: NavigationIcons.TriangleDown,
    Meatball: UtilityIcons.Meatball,
    Kebab: UtilityIcons.Kebab,
    Pending: UtilityIcons.Clock,
  };
  constructor(
    private readonly pfmService: PfmService,
    private readonly ref: ChangeDetectorRef,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnChanges(): void {
    if (!!this.transactions) {
      this.filterTransaction(this.searchText);

      this.expandedDetailTransactions.clear();
      this.expandedRecategorizeTransactions.clear();
    }
  }
  formatText(text: string, maxLength: number): string {
    const ending = '...';
    const endingLength = ending.length;
    if (maxLength <= endingLength) return ending;
    if (!text) return '';
    if (text.length >= maxLength) return text.substr(0, maxLength - endingLength).trimRight() + ending;

    return text;
  }
  searchTransaction(value: string) {
    this.searchText = value;
    this.filterTransaction(value);
  }
  categoryIcon(category: string) {
    switch (category) {
      case 'Bills':
        return PfmIcons.Category_Bills;
      case 'Deposits':
        return PfmIcons.Category_Deposits;
      case 'Food':
        return PfmIcons.Category_Food;
      case 'Personal Care':
        return PfmIcons.Category_Personal_Care;
      case 'Purchases':
        return PfmIcons.Category_Purchases;
      case 'Transfers':
        return PfmIcons.Category_Transfers;
      case 'Transportation':
        return PfmIcons.Category_Transportation;
      case 'Travel & Entertainment':
        return PfmIcons.Category_Travel_Entertainment;
      case 'Other':
        return PfmIcons.Category_Other;
    }
  }
  toggleIsCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  sortBy(column: keyof PfmTransaction, sortOrder: SortOrder) {
    this.sortedBy = column;
    this.sortedOrder = sortOrder;
    switch (column) {
      case 'date':
        this.filteredTransactions = this.filteredTransactions.sort((x, y) =>
          this.compareDateBy(parseISO(x.date), parseISO(y.date), sortOrder)
        );
        break;
      case 'category':
      case 'displayDescription':
      case 'displayName':
      case 'amount':
        this.filteredTransactions = this.filteredTransactions.sort((x, y) =>
          this.compareStrNumberBy(x[column], y[column], sortOrder)
        );
        break;
    }
  }

  downloadTransactions() {
    if (this.transactionCount === 0) return;
    this.downloadingTransactions = true;
    const filters = {
      ...this.filters,
      description: this.searchText,
      columnOrder: this.MapColumnOrder(this.sortedBy),
      descendingOrder: this.sortedOrder === SortOrder.Descending,
    };
    this.pfmService
      .downloadTransactions(filters)
      .pipe(
        catchError(err => {
          // show error popup
          this.serviceHelper.errorHandler(err, true);
          throw err;
        }),
        finalize(() => {
          this.downloadingTransactions = false;
          this.ref.markForCheck();
        })
      )
      .subscribe({
        next: response => {
          saveAs(response, `transactions.xlsx`);
        },
      });
  }

  getNextSortOrder(column: keyof PfmTransaction) {
    let sortOrder = SortOrder.Descending;
    if (column === this.sortedBy && this.sortedOrder === SortOrder.Descending) sortOrder = SortOrder.Ascending;

    return sortOrder;
  }

  selectOption(transaction: PfmTransaction) {
    this.expandedRecategorizeTransactions.add(transaction);
    this.expandedDetailTransactions.delete(transaction);
  }

  collapseRow(transaction: PfmTransaction) {
    this.expandedRecategorizeTransactions.delete(transaction);
    this.expandedDetailTransactions.delete(transaction);
  }

  toggleExpandedDetail(transaction: PfmTransaction) {
    if (!this.canExpandDetails(transaction)) return;
    if (this.expandedDetailTransactions.has(transaction)) this.expandedDetailTransactions.delete(transaction);
    else this.expandedDetailTransactions.add(transaction);
  }

  canExpandDetails(transaction: PfmTransaction) {
    return !transaction.isPending;
  }

  private compareDateBy(x: Date, y: Date, sortOrder: SortOrder) {
    if (sortOrder === SortOrder.Ascending) {
      return compareAsc(x, y);
    } else {
      return compareDesc(x, y);
    }
  }

  private compareStrNumberBy(x: string | number, y: string | number, sortOrder: SortOrder) {
    if (sortOrder === SortOrder.Ascending) {
      if (x < y) return -1;
      if (x > y) return 1;

      return 0;
    } else {
      if (x > y) return -1;
      if (x < y) return 1;

      return 0;
    }
  }

  private MapColumnOrder(sortedBy: keyof PfmTransaction): ColumnOrder {
    let result = ColumnOrder.Account;
    switch (sortedBy) {
      case 'bankName':
        result = ColumnOrder.Account;
        break;
      case 'amount':
        result = ColumnOrder.Amount;
        break;
      case 'category':
        result = ColumnOrder.Category;
        break;
      case 'date':
        result = ColumnOrder.Date;
        break;
      case 'displayDescription':
        result = ColumnOrder.Description;
        break;
    }

    return result;
  }

  private filterTransaction(value: string) {
    // income transactions don't show.
    this.filteredTransactions = this.transactions.filter(
      x => !!x.displayDescription && !x.isIncome && x.displayDescription.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    this.sortBy(this.sortedBy, this.sortedOrder);
  }
}
