import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch-header',
  templateUrl: './click-switch-header.component.html',
  styleUrls: ['./click-switch-header.component.scss'],
})
export class ClickSwitchHeaderComponent implements OnInit {
  @Input()
  subtitle: string;
  @Input()
  step = '0';
  @Output()
  back = new EventEmitter<void>();
  backIcon = NavigationIcons.ArrowBack;
  width: string;

  constructor()
  {}
  ngOnInit(): void {
    switch (this.step) {
      case '2':
        this.width = '66%';
        break;
      case '3':
        this.width = '100%';
        break;
      default:
        this.width = '33%';
        break;
    }
  }
}
