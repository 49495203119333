import { formatPhone } from '@app/utils';
import { KeyValuePair } from '@core/models';

import { Switch } from '../models';

/**
 * Get Income Information from Switch element.
 * @param userSwitch The user switch element with all the information
 */
export function getIncomeDetails(userSwitch: Partial<Switch>) {
  return getIncomeDetailsFromSwitch(userSwitch);
}

/**
 * Get Income Information in <Key,Value> format from Switch element.
 * @param userSwitch The user switch element with all the information
 */
export function getKeyValueIncomeDetails(
  userSwitch: Partial<Switch>
): KeyValuePair<string, string>[] {
  const incomeDetails = getIncomeDetailsFromSwitch(userSwitch);

  return [
    {
      key: 'Income Source',
      value: incomeDetails.targetName,
    },
    {
      key: 'Mailing Address',
      value: getFormattedAdress(incomeDetails.mailingAddress),
    },
    {
      key: 'Phone Number',
      value: formatPhone(incomeDetails.phoneNumber),
    },
    {
      key: 'Website',
      value: incomeDetails.website,
    },
    {
      key: 'Income Type',
      value: incomeDetails.incomeType,
    },
    {
      key: 'SSN (last 4)',
      value: '****',
    },
  ].filter(item => item.value);
}

function getFormattedAdress(address) {
  let addressStr = '';

  if (typeof address === 'string') address = JSON.parse(address);

  if (address) {
    const line2Aux = address.line2.split('|');
    const line2 = line2Aux.length > 0 ? line2Aux[0] : '';
    const country = line2Aux.length > 1 ? line2Aux[1] : '';

    if (line2) {
      addressStr = `${address.line1}, ${line2} \n${address.city}, ${address.state}, ${country} ${address.zip}`;
    } else {
      addressStr = `${address.line1} \n${address.city}, ${address.state}, ${country} ${address.zip}`;
    }
  }

  return addressStr;
}

function getIncomeDetailsFromSwitch(userSwitch: Partial<Switch>) {
  const submitTarget = {
    targetName: '',
    phoneNumber: '',
    website: '',
    mailingAddress: {},
    ssnLast4Digits: '',
    incomeType: '',
  };

  if (userSwitch.values) {
    const fields = userSwitch.values.fields;

    submitTarget.targetName = fields['name'] ?? userSwitch.name;
    submitTarget.phoneNumber = fields['phoneNumber'] ?? '';
    submitTarget.website = fields['website'] ?? '';
    submitTarget.mailingAddress = fields['mailingAddress'] ?? fields['Custom_Company/MailingAddress'];
    submitTarget.ssnLast4Digits = fields['Custom_SSN4'];

    const incomeCategory = fields['category'];
    if (incomeCategory) {
      const uxFields = userSwitch.ux.fields;
      if (uxFields) {
        const categoriesField = uxFields.find(f => f.key.toLowerCase() === 'category');
        const selectedCategory = categoriesField.selections.find(
          c => c.key === incomeCategory.toString()
        );
        if (selectedCategory) {
          submitTarget.incomeType = selectedCategory.label;
        }
      }
    }
  }

  return submitTarget;
}

/**
 * Get User Accounts Selected Information from Switch element.
 * @param userSwitch The user switch element with all the information
 */
export function getSelectedAccounts(userSwitch: Partial<Switch>) {
  return getSelectedAccountFromSwitch(userSwitch);
}

/**
 * Get User Accounts Selected Information in <Key,Value> format from Switch element.
 * @param userSwitch The user switch element with all the information
 */
export function getKeyValueSelectedAccounts(
  userSwitch: Partial<Switch>
): KeyValuePair<string, string>[] {
  const accounts = getSelectedAccountFromSwitch(userSwitch);

  return accounts
    .map((account, i) => {
      return [
        { key: `Account #${i + 1}`, value: account.label },
        { key: 'Distribution', value: `${account.splitAmount}%` },
      ];
    })
    .reduce((prev, next) => prev.concat(next), []);
}

function getSelectedAccountFromSwitch(userSwitch: Partial<Switch>) {
  if (userSwitch.values) {
    const valuesAccounts = userSwitch.values.accounts;
    const uxChoiceAccounts = userSwitch.ux.accounts.choices;

    return valuesAccounts.map(value => {
      let label = '';

      const choiceAccount = uxChoiceAccounts.find(ca => ca.index === value.index);
      if (choiceAccount) {
        label = choiceAccount.label;
      }

      return {
        label,
        splitAmount: value.fields.splitAmount ?? '',
      };
    });
  }

  return [];
}
