<div class="add-beneficiaries">
  <div class="header-section-title text-center">
    <h1>Add Beneficiary</h1>
  </div>
  <div class="frow text-center">
    <div class="fcol-cs-12 hide-xs">
      <h2>Select account(s) and add a new beneficiary.</h2>
    </div>
  </div>
  <form
    name="beneficiaryForm"
    [formGroup]="formBeneficiaries"
    novalidate
    ngNativeValidate
    (ngSubmit)="acceptAndSave(formBeneficiaries)"
  >
    <div class="frow center">
      <div class="beneficiary-content fcol-lg-6 fcol-md-8 fcol-cs-12">
        <div class="beneficiary-content_accounts">
          <h3>Assign Account(s)</h3>
          <div class="frow">
            <div class="fcol-md-12 fcol-cs-12">
              <select class="uk-input accounts-select" formControlName="currentAccountId">
                <option *ngFor="let account of availableAccounts" [value]="account.id">
                  {{ account.nickname }}
                </option>
              </select>
              <button
                type="button"
                class="add-accounts"
                (click)="addNewAccount()"
                [disabled]="availableAccounts.length == 0"
              >
                Add account
              </button>
            </div>
          </div>
          <div class="frow selected-accounts">
            <div *ngFor="let account of selectedAccounts; let i = index" class="fcol-md-12 fcol-cs-12">
              <div class="frow accounts-option">
                <span>{{ account.nickname }}</span>
                <button
                  type="button"
                  (click)="removeAccountSelected(i)"
                  class="pull-right remove-account btn__link"
                  *ngIf="i > 0"
                >
                  <app-svg-icon size="1em" [iconName]="icons.trash"></app-svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="beneficiary-content_info">
          <div *ngFor="let beneficiary of beneficiaries; index as i">
            <app-beneficiary-form
              [parentForm]="formBeneficiaries"
              [beneficiary]="beneficiary"
              [position]="i"
              [beneficiaries]="beneficiaries"
              [allBeneficiaries]="allBeneficiaries"
              (onRemoveBeneficiary)="removeBeneficiary($event)"
            ></app-beneficiary-form>
          </div>
          <div class="frow">
            <button type="button" class="group--form add-beneficiary btn__link" (click)="addNewBeneficiary()">
              Add Another Beneficiary
            </button>
          </div>
        </div>
        <div class="beneficiary-content_acknowledgment">
          <div class="frow">
            <div class="group--form fcol-md-12 fcol-cs-12">
              <span>Beneficiary Acknowledgment:</span>
              <span>
                Accounts held in the names of two or more persons (Account Owners) will become payable to the designated
                Payable on Death beneficiary or beneficiaries only upon the death of all account owners. Where there is
                more than one beneficiary, the funds in your account will be paid equally to each of the beneficiaries.
                <br />
                <br />
                The share of any beneficiary who predeceases the account owner(s) will be paid to the remaining
                beneficiary or beneficiaries, if there are any. You may change your designated beneficiary or
                beneficiaries at any time.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="frow text-center">
      <div class="fcol-cs-12" [hidden]="isBusy">
        <button id="accept-save" class="uk-btn solid secondary lg" type="submit">
          Accept and Save
        </button>
        <br />
        <button type="button" class="back-btn btn__link" (click)="redirectToPage(accountId)">
          <span>Cancel</span>
        </button>
      </div>
      <div class="fcol-cs-12">
        <app-dot-loader [showWhen]="isBusy"></app-dot-loader>
      </div>
    </div>
  </form>
</div>
