export function getMakePaymentDisclaimer(brandName: string) {
  let makePaymentHeader: string;
  switch (brandName.toLowerCase()) {
    case 'axos':
      makePaymentHeader = 'Axos Bank';
      break;
    case 'ufb direct':
      makePaymentHeader = 'UFB Direct';
      break;
    case 'nationwide':
      makePaymentHeader = 'Nationwide by Axos Bank';
      break;
    default:
      makePaymentHeader = 'Axos Bank';
      break;
  }

  return [
    makePaymentHeader +
      " will withdraw your payments on the dates you've chosen.  If your chosen date falls on a Saturday, Sunday, federal holiday, or if you choose the 29th through the 31st and the date does not occur in a month, we will withdraw your payment the following business day.",
    'As your monthly payment may vary, we will withdraw the amount in accordance with the current balance due. If you have requested to pay additional principal each month, your total payment amount will also increase accordingly.',
  ];
}
