import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';

export function userSubtypeHelperFactory($injector: any) {
  return $injector.get('userSubtypeHelper');
}

export const userSubtypeHelperProvider = {
  provide: UserSubtypeHelper,
  useFactory: userSubtypeHelperFactory,
  deps: ['$injector'],
};
