<div
  class="uikit-tile"
  [ngClass]="{
    flipped: isFlipped,
    'uikit-tile-confirm-active': isRemoveModalActive || showModal,
    'is-loading not-movable': isLoading
  }"
>
  <!-- Tile container -->
  <div class="uikit-tile-container">
    <div class="uikit-tile-front" [ngClass]="{ 'has-teaser': teaserText, 'no-header': hideHeader }">
      <app-tile-modal config="modalConfig" isActive="showModal" *ngIf="modalConfig"></app-tile-modal>
      <!-- Tile header -->
      <div class="uikit-tile-header frow between middle" *ngIf="!hideHeader">
        <div
          [ngClass]="
            !withSettings || hasErrored
              ? 'uikit-tile-title-left-full'
              : !preventTitleOverlap
              ? 'uikit-tile-title-left'
              : 'uikit-tile-title-left-overflowed'
          "
        >
          <h4 class="uikit-tile-title" [textContent]="tileName"></h4>
        </div>
        <div class="uikit-tile-title-right">
          <ng-content select="[asideTitle]"></ng-content>
        </div>
        <div
          *ngIf="withSettings && !hasErrored"
          [ngClass]="!preventTitleOverlap ? 'uikit-tile-title-right' : 'uikit-tile-title-right-overflowed'"
        >
          <div
            class="uikit-tile-open-settings finline middle not-movable"
            (click)="flip()"
            *ngIf="!isLoading && withSettings && !hasErrored"
            aria-label="Tile settings"
          >
            <i class="bofi-gear settings-icon" aria-hidden="true"></i>
          </div>
        </div>

        <div class="uikit-bubble-position" *ngIf="!isLoading">
          <div class="uikit-bubble" *ngIf="showBubble">
            <span class="uikit-bubble-number" [textContent]="bubbleNumber"></span>
          </div>
        </div>
      </div>

      <div class="uikit-tile__teaser not-movable" *ngIf="teaserText">
        <h5 class="uikit-tile__teaser-text" [textContent]="teaserText"></h5>
      </div>

      <!-- Tile content -->
      <div
        class="uikit-tile-fill"
        [ngClass]="{ 'has-action': !!quickActionText && !hasErrored, 'not-movable': !canMoveFromContent }"
      >
        <div class="uikit-tile-front-content" *ngIf="!hasErrored">
          <ng-content select="[content]"></ng-content>
        </div>

        <div class="fcol center" *ngIf="hasErrored">
          <p class="tile__message">
            We are currently experiencing trouble receiving data for this tile. Please try back later.
          </p>
        </div>
      </div>

      <!-- Tile action bar -->
      <div class="uikit-tile-action-bar frow middle center not-movable" *ngIf="!!quickActionText && !hasErrored">
        <a class="uikit-tile-action finline middle not-movable" (click)="quickAction()">
          <span class="uikit-tile-action-title" [innerHTML]="quickActionText"></span>
          <i class="bofi-arrow-chevron"></i>
        </a>
      </div>
    </div>

    <div class="uikit-tile-back not-movable" *ngIf="!hasHeader && withSettings">
      <app-tile-modal config="removeTileModalConfig" isActive="isRemoveModalActive"></app-tile-modal>
      <!-- Tile settings header -->
      <div class="uikit-tile-header frow between middle">
        <h4 class="uikit-tile-title">Settings</h4>
        <div class="uikit-tile-settings-buttons" aria-label="Tile settings">
          <a (click)="resetSettings()">Cancel</a>
          <button (click)="saveSettings()">SAVE</button>
        </div>
      </div>

      <!-- Tile settings content -->
      <div class="uikit-tile-settings not-movable">
        <ng-content select="[settings]"></ng-content>
      </div>

      <!-- Tile action bar -->
      <div class="uikit-tile-action-bar frow middle center">
        <a class="uikit-tile-action finline middle not-movable" (click)="showConfirm()">
          <i class="fa fa-trash-o delete"></i>
          <i class="fa fa-trash delete-forever"></i>
          <span class="uikit-tile-action-title">Remove tile</span>
        </a>
      </div>
    </div>
  </div>

  <!-- Skeleton loader -->
  <div class="tile__loader" *ngIf="isLoading"></div>
</div>
