import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { STATE } from '@core/tokens';
import { AlertsIcons } from '@shared/enums';

import { SignatureCardStatusType } from '../../../../enums/signature-card-status.enum';

@Component({
  selector: 'app-signature-card-option',
  templateUrl: './signature-card-option.component.html',
  styleUrls: ['./signature-card-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureCardOptionComponent {
  @Input() accountId: number;
  @Input() accountNumber: string;
  @Input() signatureCardStatus = SignatureCardStatusType.Removed;
  @Input() signatureCardStatusDescription: string;

  icon = AlertsIcons.InfoCircle;

  get showSignatureCard() {
    return !!this.signatureCardStatus && this.signatureCardStatus !== SignatureCardStatusType.Removed;
  }

  get showAddSignatureCard() {
    return !(
      this.signatureCardStatus === SignatureCardStatusType.Accepted ||
      this.signatureCardStatus === SignatureCardStatusType.Received
    );
  }

  constructor(@Inject(STATE) private state: ng.ui.IStateService) {}

  goToSignatureCard(): void {
    this.state.go('udb.signaturecard', {
      accountNumber: this.accountNumber,
      accountId: this.accountId,
    });
  }
}
