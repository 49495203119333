import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { OlbSettings } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private olbSettings: OlbSettings;

  constructor(private http: HttpClient) {}

  get appConfig(): OlbSettings {
    return this.olbSettings;
  }

  /**
   * Gets the app properties from the server.
   * These properties are the ones loaded in the Properties Service.
   * @returns A Promsie containing the settings for the application.
   */
  loadConfig(): Promise<OlbSettings> {
    return this.http
      .get<OlbSettings>('/api/settings')
      .pipe(
        tap(res => {
          this.olbSettings = res;
        })
      )
      .toPromise();
  }
}
