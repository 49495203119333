import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { openClose, slideFromRight, startStaticSlideFromLeft } from '@app/user-profile/animations';
import { updateProfileDetails } from '@app/user-profile/store/actions';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { ConfigurationService, UserProfileService } from '@core/services';
import { CustomerDetail } from '@shared/models';

@Component({
  selector: 'app-login-and-security-view',
  templateUrl: './login-and-security-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './login-and-security-view.component.scss'],
  animations: [startStaticSlideFromLeft, slideFromRight, openClose],
})
export class LoginAndSecurityViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  expanded = false;
  profileLoading = true;
  questionsLoading = true;
  userCanEdit = false;
  isMobileDevice = false;
  animationState: 'initial' | 'in' = 'initial';
  currentView = LoginAndSecurityView.LoginAndSecurity;
  views = LoginAndSecurityView;
  alertMessages = {
    password: '',
    questions: '',
    secretWord: '',
  };
  profileInfo: Partial<CustomerDetail>;
  questionsCatalog: string[];
  userQuestions: string[];
  hasUserSecurityQuestions = false;

  private subsink = new SubSink();

  constructor(
    private store: Store,
    private breakpointObserver: BreakpointObserver,
    private userProfileService: UserProfileService,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.userProfileService.getUserSecurityQuestions(),
      this.configurationService.getSecurityQuestions(),
    ]).subscribe(([userquestions, catalog]) => {
      this.questionsCatalog = catalog.data;
      this.userQuestions = userquestions.data;
      this.hasUserSecurityQuestions = !!userquestions.data?.length;
      this.questionsLoading = false;
    });

    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.profileInfo = data;
        this.profileLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  updatePassword(): void {
    this.alertMessages.password =
      'You have successfully saved your password. For your security, if you have biometric authentication activated, we have de-activated your biometric authentication.  Please re-enroll for continued biometric logins.';
    const payload = { ...this.profileInfo, lastPasswordChange: new Date() };
    this.store.dispatch(updateProfileDetails({ payload }));
  }

  updateQuestions(newQuestions: string[]): void {
    if (newQuestions) this.hasUserSecurityQuestions = true;
    this.userQuestions = newQuestions;
    this.alertMessages.questions = 'You have successfully saved your security questions & answers.';
  }

  updateSecretInfo(): void {
    this.alertMessages.secretWord = 'You have successfully saved your Security Word and Hint.';
  }

  goTo(view: LoginAndSecurityView): void {
    this.currentView = view;

    this.alertMessages = {
      questions: '',
      password: '',
      secretWord: '',
    };
  }

  goBack(): void {
    this.currentView = LoginAndSecurityView.LoginAndSecurity;
    this.animationState = 'in';
  }
}

export enum LoginAndSecurityView {
  LoginAndSecurity = 'Login & Security',
  Password = 'Password',
  SecurityQuestions = 'Security Questions',
  SecretWord = ' Security Word',
}
