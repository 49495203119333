import { createSelector } from '@ngrx/store';

import { TimePeriod } from '@app/pfm/enums/time-period.enum';

import { getPfmState } from '../pfm.state';
import { SpendingFilters } from './spending-filters';

export const getTransactions = createSelector(getPfmState, state => state.spendingState.transactions);
export const getTransactionsLoading = createSelector(getPfmState, state => state.spendingState.transactionsLoading);
export const getLastFiltersRequest = createSelector(getPfmState, state => state.spendingState.lastFiltersRequest);
export const getSpentByCategories = createSelector(getPfmState, state => state.spendingState.spentByCategories);
export const getTotalSpent = createSelector(getPfmState, state =>
  state.spendingState.spentByCategories.reduce((a, b) => a + b.amount, 0)
);

export const getUncommittedTimePeriodFilter = createSelector(
  getPfmState,
  state => state.spendingState.uncommittedFilters.timePeriod
);

export const getUncommittedExcludedAccountsFilter = createSelector(
  getPfmState,
  state => state.spendingState.uncommittedFilters.excludedAccounts
);

export const getUncommittedExcludedCategoriesFilter = createSelector(
  getPfmState,
  state => state.spendingState.uncommittedFilters.excludedCategories
);

function getFiltersInDefaultState(filters: SpendingFilters) {
  // returns wether the filters are in their default state

  const result = {
    timePeriod: filters.timePeriod === TimePeriod.ThisMonth,
    accounts: filters.excludedAccounts.size === 0,
    categories: filters.excludedCategories.size === 0,
  };

  return { ...result, all: result.categories && result.accounts && result.timePeriod };
}
export const getCommittedFiltersInDefaultState = createSelector(getPfmState, state =>
  getFiltersInDefaultState(state.spendingState.committedFilters)
);
export const getUncommittedFiltersInDefaultState = createSelector(getPfmState, state =>
  getFiltersInDefaultState(state.spendingState.uncommittedFilters)
);
export const getCommittedFilters = createSelector(getPfmState, state => state.spendingState.committedFilters);
