<input
  id="amountTransfer"
  type="text"
  class="uk-input"
  aria-label="Amount To Transfer"
  prefix="$ "
  mask="separator.2"
  thousandSeparator=","
  separatorLimit="100000"
  [dropSpecialCharacters]="[',']"
  [allowNegativeNumbers]="false"
  [formControl]="control"
  autocomplete="off"
  (focus)="onFocus()"
  (blur)="onBlur()"
/>
