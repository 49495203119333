<form class="no-gutters" [formGroup]="userNameForm" (ngSubmit)="submitData()">
  <div class="col-12 col-lg-8 form-group">
    <h1 class="create-username-header__title">Create Your Login</h1>
    <p class="create-username-header__subtitle">
      Enter a username and password for your new account.
    </p>
  </div>
  <div
    class="col-12 col-lg-8 form-group"
    [class.invalid]="username.hasError('*') || usernameValidationStatus === 'taken'"
  >
    <input
      id="username"
      class="uk-input"
      type="text"
      name="username"
      formControlName="username"
      placeholder="Username"
      (keyup)="validateFields()"
    />

    <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="username" #username="ngxErrors">
      <span *ngFor="let error of errors.username" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>

    <span
      id="username-message"
      role="alert"
      aria-live="polite"
      *ngIf="usernameMessage"
      style="font-size: 0.875rem;"
      [ngClass]="usernameMessageClass"
      [innerText]="usernameMessage"
    ></span>
  </div>

  <div class="col-12 col-lg-8 form-group" [class.invalid]="passwordError || passwordField.hasError('*')">
    <app-password-field
      name="password"
      formControlName="password"
      [maxLength]="25"
      [value]=""
      (valueChange)="password = $event"
      (inputChange)="passwordError = !$event"
      placeholder="Password"
      labelText=""
    >
      <div class="mt-1 text-error" aria-live="polite" role="alert" ngxErrors="password" #passwordField="ngxErrors">
        <span *ngFor="let error of errors.password" [ngxError]="error.name" [innerText]="error.message"></span>
      </div>
    </app-password-field>
    <button
      id="submit-button"
      type="submit"
      [disabled]="isDisabled"
      class="btn-ok button--lg"
      [ngClass]="{ 'btn-create-user': brandName === 'axos' }"
    >
      Continue
    </button>
  </div>
</form>
