<app-goal-header
  *ngIf="currentStep !== steps.AllocationUpdatedConfirmation"
  [goal]="goal"
  [isLoading]="isLoading"
  title="Edit Portfolio"
  [subtitle1]="content?.title"
  [subtitle2]="content?.subtitle"
  [showModifiedBanner]="displayModifiedBanner"
  [modifiedBannerText]="bannerText"
  [textErrorBanner]="bannerText"
  [displayErrorBanner]="displayModifiedErrorBanner"
  (goBack)="goBack()"
></app-goal-header>
<ng-container *ngIf="!isLoading">
  <ng-container [ngSwitch]="currentStep">
    <app-portfolio-allocation-selector
      *ngSwitchCase="steps.AllocationSelector"
      [currentAllocation]="currentAllocation"
      [endAllocation]="endAllocation"
      [saveAllocation]="isUpdatingAllocation"
      (savePortfolio)="updateAllocation()"
      (loadAllocation)="chooseAllocation($event)"
    ></app-portfolio-allocation-selector>
    <app-edit-portfolio-form
      *ngSwitchCase="steps.Form"
      [goal]="goal"
      [type]="portfolioAllocationType"
      [previousPortfolioType]="previousPortfolioType"
      [portfolioType]="portfolioType"
      [allocation]="allocation"
      [goalAllocation]="goalAllocation"
      (saveChanges)="onSubmitForm($event)"
      (deleteEndTarget)="displayDeleteEndTargetDialog()"
    ></app-edit-portfolio-form>
    <app-review-target-allocation
      *ngSwitchCase="steps.ReviewCurrentAllocation"
      [details]="allocation"
      [score]="score"
      [isLoading]="isUpdatingAllocation"
      [portfolioType]="portfolioType"
      (save)="updateAllocation()"
    ></app-review-target-allocation>
    <app-portfolio-updated-confirmation
      *ngSwitchCase="steps.AllocationUpdatedConfirmation"
      [portfolioType]="portfolioType"
      [previousPortfolioType]="previousPortfolioType"
      [goalAllocation]="goalAllocation"
      [goal]="goal"
      (endingAllocation)="addEndingAllocation()"
      (goBack)="goBack()"
    ></app-portfolio-updated-confirmation>
  </ng-container>
</ng-container>
