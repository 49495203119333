<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Alerts How To's</h3>
  <ul class="how-to-list">
    <li>
      <button id="setup-text-alert" (click)="selectTopic(topics.SetupTextAlert)" class="link">
        How do I set up text alerts?
      </button>
    </li>
    <li>
      <button id="setup-email-alert" (click)="selectTopic(topics.SetupEmailAlert)" class="link">
        How do I set up email alerts?
      </button>
    </li>
    <li>
      <button id="stop-alerts" (click)="selectTopic(topics.StopAlerts)" class="link">
        How do I stop email and/or text alerts?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-setup-text-alerts *ngSwitchCase="topics.SetupTextAlert"></how-to-setup-text-alerts>
    <how-to-setup-email-alerts *ngSwitchCase="topics.SetupEmailAlert"></how-to-setup-email-alerts>
    <how-to-stop-alerts *ngSwitchCase="topics.StopAlerts"></how-to-stop-alerts>
  </ng-container>
</ng-template>
