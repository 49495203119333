import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ConsentStatus, IraPlanCode } from '@app/accounts/enums';
import { SpousalConsent } from '@app/accounts/models';
import { SpousalConsentRequest } from '@app/axos-trading/models';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyBagService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'propertybag');
  }

  cancelSpousalConsent(request: SpousalConsentRequest) {
    request.consentRequestStatus = ConsentStatus.Cancelled;

    return this.put('spousalConsentByTaxPlan', request);
  }

  resendSpousalConsent(request: SpousalConsentRequest) {
    request.consentRequestStatus = ConsentStatus.Resend;

    return this.put('spousalConsentByTaxPlan', request);
  }

  hasPendingSpousalConsent(taxPlanType: IraPlanCode) {
    return this.get(`spousalconsent/pending/${taxPlanType}`);
  }

  create(request: SpousalConsent) {
    return this.post('spousalconsent', request);
  }
}
