import { createReducer, on } from '@ngrx/store';

import { HasAxosAccounts } from '@shared/models/hasAxosAccounts.model';
import { RIAPilotFlagState } from '@shared/models/ria-pilot-state';
import { IsRiaUserState } from '@shared/models/ria-user-state.model';

import {
  getRIAHasNoAxosAccounts,
  getRIAHasNoAxosAccountsSuccess,
  getRIAPilotFlagSuccess,
  getRIAUserFlag,
  getRIAUserFlagSuccess,
} from './support-view.actions';

export const initialState: IsRiaUserState = {
  isRIAUser: false,
  isLoading: false,
};

export const isRIAUserReducer = createReducer(
  initialState,
  on(getRIAUserFlag, state => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(getRIAUserFlagSuccess, (state, { isRIAUserflag }) => {
    return {
      ...state,
      isLoading: false,
      isRIAUser: isRIAUserflag,
    };
  })
);

export const initialRIAPilotState: RIAPilotFlagState = {
  isRIAPilotActive: false,
};

export const isRIAPilotReducer = createReducer(
  initialRIAPilotState,
  on(getRIAPilotFlagSuccess, (state, { isRIAPilotActive }) => {
    return {
      ...state,
      isRIAPilotActive,
    };
  })
);

export const initialAxosAccountsState: HasAxosAccounts = {
  userHasNoAxosAccounts: false,
  isLoading: false,
};

export const hasAxosAccountsReducer = createReducer(
  initialAxosAccountsState,
  on(getRIAHasNoAxosAccounts, state => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(getRIAHasNoAxosAccountsSuccess, (state, { userHasNoAxosAccounts }) => {
    return {
      ...state,
      userHasNoAxosAccounts,
      isLoading: false,
    };
  })
);
