import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { catchError, first, map, retry, switchMap } from 'rxjs/operators';

import {
  addUpdateModelAccountAction,
  getCurrentAccountId,
  ModelsSortDirection,
  ModelsState,
  ModelsSummaryService,
  ModelsTileState,
  TFilterOptionLabels,
  selectSortingOption,
  ModelSortTypes,
  getSelectedAccountModelsTile,
  ModelSortTypeName,
  ModelSortDirectionName,
  ModelsSummaryResponse,
} from '../core';
import { InitializeModelTileInput } from './types';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { UserPreferencesFacade } from '@app/Areas/AAS/aas-shared/features/user-preferences';
import { createLabeledSortingOptions, findModelSortTypePreference } from '../core/store/functions';
import { BrandingFacade } from '../../branding';
import { ServiceResult } from '@shared/models';
@Injectable({
  providedIn: 'root',
})
export class ModelsFacade {
  readonly isLoading$ = new Subject<boolean>();

  readonly modelsTileAccountState$ = this.store.select(getSelectedAccountModelsTile);

  readonly portalCSSProperties = this.brandingFacade.cssBrandingProperties;

  private readonly currentAccountNumber$ = this.store.select(getCurrentAccountId);

  constructor(
    private readonly store: Store<ModelsState>,
    private readonly modelsSummaryService: ModelsSummaryService,
    private readonly cookieHelper: CookieHelper,
    private readonly userPreferenceFacade: UserPreferencesFacade,
    private readonly brandingFacade: BrandingFacade
  ) {}

  initializeModelsTile(input: InitializeModelTileInput) {
    this.isLoading$.next(true);
    const preferences = { userPreferences: input.userPreferences, defaultAppPreferences: input.defaultAppPreferences };
    const accountPreference = findModelSortTypePreference(input.accountNumber, preferences);
    const payload: ModelsTileState = {
      error: undefined,
      isSortingEnabled: input.isSortingEnabled,
      accountNumber: input.accountNumber,
      sortOptions: createLabeledSortingOptions(
        ModelSortTypes,
        ModelsSortDirection,
        createLabeledSortingOptions.defaultOptionSeed,
        accountPreference.typePreference,
        accountPreference.directionPreference
      ),
      includeHoldings: input.includeHoldings,
      limit: input.limit,
      models: [],
      sortDirection: accountPreference.directionPreference,
      sortType: accountPreference.typePreference,
    };
    const requestInput: InitializeModelTileInput = {
      ...input,
    };
    this.modelsSummaryService
      .getModelsSummary(requestInput)
      .pipe(retry(3))
      .subscribe({
        next: (serviceResult: ServiceResult<ModelsSummaryResponse[]>) => {
          payload.models = serviceResult.data ?? [];
          this.store.dispatch(addUpdateModelAccountAction({ payload: { ...payload, error: undefined } }));
          this.isLoading$.next(false);
        },
        error: error => {
          this.store.dispatch(addUpdateModelAccountAction({ payload: { ...payload, error } }));
          this.isLoading$.next(false);
        },
      });
  }

  selectSortingFilter(option: TFilterOptionLabels): void {
    this.createUpdateUserSortingPreferences(option);
  }
  private createUpdateUserSortingPreferences(option: TFilterOptionLabels): void {
    this.currentAccountNumber$
      .pipe(
        first(),
        switchMap((accountNumber: string) => {
          if (!accountNumber) {
            return of(accountNumber);
          }
          const udbUserId = this.cookieHelper.getUserId();
          return this.userPreferenceFacade.preferencesState$.pipe(first()).pipe(
            map(preferences => {
              return UserPreferencesFacade.createUserSortingPreferences(
                option,
                udbUserId,
                accountNumber,
                preferences.defaultAppPreferences,
                preferences.userPreferences,
                ModelSortTypeName,
                ModelSortDirectionName
              );
            }),
            switchMap(userPreferences => this.userPreferenceFacade.saveUserPreferences({ userPreferences })),
            map(() => accountNumber),
            catchError(() => of(accountNumber))
          );
        })
      )
      .subscribe({
        next: accountNumber => {
          this.store.dispatch(selectSortingOption({ payload: { option, accountNumber } }));
        },
      });
  }
}
