/**
 * Represents a type of OLB event.
 * These should be self-descriptive.
 */
export enum OlbEvents {
  EndChatbotSession = 1,
  BrandPropertiesLoaded = 2,
  OpenEvoChat = 3,
  UserInfoLoaded = 4,
  DebitCardSelected = 6,
  BalancesAvailable = 7,
  CancelSpousalConsent = 8,
  AggregatedAccountsLoaded = 9,
  InvestAccountsLoaded = 10,
  LinkedDebitCardsLoaded = 11,
  TxRedirectionEvent = 12,
  AccountDetailsRetrieved = 13,
  TxFiltersReset = 14,
  NickName = 15,
}
