<div class="form-group password" [class.has-error]="hasError">
  <div class="password__input">
    <input
      #password
      [id]="name"
      class="uk-input"
      [name]="name"
      [type]="hidePassword ? 'password' : 'text'"
      [disabled]="disabled"
      [value]="value"
      [placeholder]="placeholder"
      (input)="validatePasswordComplexity(password.value)"
    />

    <app-reveal-input-label [target]="name">
      {{ labelText }}
    </app-reveal-input-label>
  </div>

  <ng-content></ng-content>

  <span class="text-error" *ngIf="exceedsMaxLength">
    The password cannot have more than {{ maxLength }} characters.
  </span>

  <div *ngIf="showChecker" class="password__strength">
    <div class="password__progress">
      <div class="progressbar" aria-hidden="true">
        <span class="progressbar__progress" [attr.data-progress]="progressValue"></span>
      </div>
      <span role="alert" aria-live="polite">{{ strength }}</span>
    </div>

    <div class="password__hints" [ngClass]="passwordHintsClasses">
      <div class="password__hint">
        <app-svg-icon
          [iconName]="uppercaseIconName.icon"
          [iconColor]="uppercaseIconName.color"
          [height]="iconSize"
          [width]="iconSize"
        ></app-svg-icon>
        <span>One uppercase letter</span>
      </div>

      <div class="password__hint">
        <app-svg-icon
          [iconName]="numberIconName.icon"
          [iconColor]="numberIconName.color"
          [height]="iconSize"
          [width]="iconSize"
        ></app-svg-icon>
        <span>One number</span>
      </div>

      <div class="password__hint">
        <app-svg-icon
          [iconName]="lowercaseIconName.icon"
          [iconColor]="lowercaseIconName.color"
          [height]="iconSize"
          [width]="iconSize"
        ></app-svg-icon>
        <span>One lowercase letter</span>
      </div>

      <div class="password__hint">
        <app-svg-icon
          [iconName]="lengthCharIconName.icon"
          [iconColor]="lengthCharIconName.color"
          [height]="iconSize"
          [width]="iconSize"
        ></app-svg-icon>
        <span>8 characters</span>
      </div>

      <div class="password__hint">
        <app-svg-icon
          [iconName]="specialCharIconName.icon"
          [iconColor]="specialCharIconName.color"
          [height]="iconSize"
          [width]="iconSize"
        ></app-svg-icon>
        <span>One symbol (#?!@$%^&amp;*+-)</span>
      </div>
    </div>
  </div>
</div>
