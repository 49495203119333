<div class="stop-payment-remove frow center">
  <div class="fcol-cs-12 fcol-sm-10">
    <div class="frow fcol-cs-12 container__btn-back">
      <a class="finline middle" (click)="redirectToPage(accountId)">
        <i class="bofi-arrow-chevron" aria-hidden="true"></i>
        <span>Back</span>
      </a>
    </div>

    <div class="frow fcol-cs-12">
      <img src="/img/icons/accounts/stop_payment.svg" width="100" aria-hidden="true" />
      <div class="fcol-cs-8 fcol-sm-10">
        <h2>Remove Stop Payment</h2>
        <p>Remove a stop payment to a check or an ACH payment</p>
      </div>
    </div>
    <div class="remove-contain">
      <div class="frow between middle fcol-cs-12 question">
        <p>
          <b>Are you sure you would like to remove the stop payment?</b>
        </p>
      </div>
      <div class="remove-item frow" *ngIf="spCancel">
        <div class="fcol-xs-3">
          <b>Type:</b>
          <span>{{ spCancel.stopType }}</span>
        </div>
        <div class="fcol-xs-3">
          <b>Requested Date:</b>
          <span>{{ spCancel.requestedDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>

      <div class="remove-item frow" *ngIf="spCancel">
        <div class="fcol-xs-3">
          <b>Check Number:</b>
          <span *ngIf="spCancel.stopType === 'Check'">
            <span>{{ '#' + spCancel.lowCheckNumber }}</span>
            <span *ngIf="spCancel.highCheckNumber && spCancel.lowCheckNumber !== spCancel.highCheckNumber">
              {{ ' - #' + spCancel.highCheckNumber }}
            </span>
          </span>
          <span *ngIf="spCancel.stopType === 'ACH'">N/A</span>
        </div>
        <div class="fcol-xs-3">
          <b>Expiration Date:</b>
          <span>{{ spCancel.expirationDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>

      <div class="remove-item frow" *ngIf="spCancel">
        <div class="fcol-xs-3">
          <b>Account:</b>
          <span>{{ accountName }}</span>
        </div>
        <div class="fcol-xs-3">
          <b>Status:</b>
          <i class="bofi-pending">
            <span>In Process</span>
          </i>
        </div>
      </div>

      <div
        *ngIf="
          spCancel &&
          ((spCancel.highCheckNumber && spCancel.lowCheckNumber === spCancel.highCheckNumber) ||
            spCancel.stopType === 'ACH')
        "
        class="remove-item frow"
      >
        <div class="fcol-xs-8">
          <b>Amount:</b>
          <span>{{ spCancel.amount | currency }}</span>
        </div>
      </div>

      <div
        *ngIf="
          spCancel &&
          ((spCancel.highCheckNumber && spCancel.lowCheckNumber === spCancel.highCheckNumber) ||
            spCancel.stopType === 'ACH')
        "
        class="remove-item frow"
      >
        <div class="fcol-xs-8">
          <b *ngIf="spCancel.stopType === 'Check'">Date Written:</b>
          <b *ngIf="spCancel.stopType === 'ACH'">Date of Expected Debit:</b>
          <span>{{ spCancel.checkDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>

      <div class="remove-item frow" *ngIf="spCancel">
        <div
          *ngIf="
            (spCancel.highCheckNumber && spCancel.lowCheckNumber === spCancel.highCheckNumber) ||
            spCancel.stopType === 'ACH'
          "
          class="fcol-xs-8"
        >
          <b>Payee:</b>
          <span>{{ spCancel.payeeDebitingCompany }}</span>
        </div>
      </div>
      <div class="note fcol-xs-12">
        <p>
          <b>NOTE:</b>
          Once you remove a Stop Payment, the payment can be processed inmediately. There is no fee for a remove on a
          Stop Payment.
        </p>
      </div>
    </div>
    <div class="frow end fcol-cs-12" *ngIf="!isBusy">
      <button type="button" (click)="redirectToPage(accountId)" class="uk-btn sm outline primary btn--cancel">
        Cancel
      </button>
      <button type="button" (click)="remove()" class="uk-btn solid secondary lg">
        Remove Stop Payment
      </button>
    </div>
    <app-dot-loader [showWhen]="isBusy">Verifying...</app-dot-loader>
  </div>
</div>
