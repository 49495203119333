import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';

import { AddressType, ProfileType } from '@app/user-profile/enums';
import { addressIsEmpty, areAddressesEqual as addressesAreEqual, createAddressForm } from '@app/user-profile/utils';
import { BusinessService, UserProfileService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AddressAccountAssociation, CustomerDetail } from '@shared/models';
import { BusinessDetail } from '@shared/models/business-detail.model';
import { Subject } from 'rxjs';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { labelTextConstants } from './constants';

@Component({
  selector: 'up-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['../../scss/user-profile.common.scss', './change-address.component.scss'],
})
export class ChangeAddressComponent implements OnInit, OnDestroy {
  @Input() userProfile: Partial<CustomerDetail>;
  @Input() profileType = ProfileType.Personal;
  @Input() selectedBusinessId = 0;
  @Output() back = new EventEmitter<null>();
  @Output() updateAddresses = new EventEmitter<AddressAccountAssociation[]>();
  @Output() updateBusinessAddresses = new EventEmitter<BusinessDetail>();

  addressForm: UntypedFormArray;
  addressTypes = AddressType;
  profileTypes = ProfileType;
  businessDataModel: BusinessDetail;
  isBusy = false;
  addressesAreTheSame = true;
  businessId: number;
  centerText: string;
  checkboxText: string;
  labelTexts = labelTextConstants;
  private destroy$ = new Subject<void>();

  get mailingAddressForm(): UntypedFormGroup {
    return this.addressForm.at(1) as UntypedFormGroup;
  }

  get businessMailingForm(): UntypedFormGroup {
    return this.addressForm.at(0) as UntypedFormGroup;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private userProfileService: UserProfileService,
    private businessService: BusinessService,
    private serviceHelper: ServiceHelper,
    private supportViewFacade: SupportViewFacade
  ) {}

  ngOnInit(): void {
    this.setCenterAndCheckboxText();
    this.createForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  toggleSecondAddressForm(checked: boolean): void {
    this.addressesAreTheSame = checked;
    this.addressForm.markAsDirty({ onlySelf: true });

    if (!checked) {
      this.addressForm.push(createAddressForm({}, AddressType.Mailing));
    } else {
      // Position 1 corresponds to the Mailing Address form.
      this.addressForm.removeAt(1);
    }
  }

  updateUserAddresses(): void {
    switch (this.profileType) {
      case ProfileType.Business:
        this.updateBusinessAddress();
        break;
      case ProfileType.Company:
        this.updateCompanyAddress();
        break;
      default:
        this.updatePersonalAddress();
        break;
    }
  }

  private updatePersonalAddress(): void {
    if (this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
    } else {
      // Create a copy of the current value. User may uncheck the box while loading.
      const sameAddresses = this.addressesAreTheSame;
      const addresses: AddressAccountAssociation[] = this.addressForm.value.map(
        (address: AddressAccountAssociation) => {
          return { address: { ...address } };
        }
      );

      this.isBusy = true;
      this.userProfileService
        .updateAddresses(addresses)
        .pipe(
          finalize(() => {
            this.isBusy = false;
          })
        )
        .subscribe({
          next: () => {
            if (sameAddresses) {
              addresses.push({ ...addresses[0] });
            }
            this.updateAddresses.emit(addresses);
            this.back.emit();
          },
          error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        });
    }
  }

  private createForm() {
    const { primaryAddress, mailingAddress } = this.userProfile;

    switch (this.profileType) {
      case ProfileType.Business:
        this.businessDataModel = this.userProfile.businessInformation;
        this.addressForm = this.fb.array([
          createAddressForm(this.businessDataModel.businessAddress.address, AddressType.Business),
        ]);
        break;
      case ProfileType.Company:
        this.addressForm = this.fb.array([createAddressForm(primaryAddress.address, AddressType.Physical)]);

        this.addressesAreTheSame =
          !addressIsEmpty(primaryAddress.address) && addressesAreEqual(primaryAddress.address, mailingAddress.address);

        if (!this.addressesAreTheSame) {
          this.addressForm.push(createAddressForm(mailingAddress.address, AddressType.Mailing));
        }
        break;
      default:
        this.addressForm = this.fb.array([createAddressForm(primaryAddress.address, AddressType.Physical)]);

        this.addressesAreTheSame =
          !addressIsEmpty(primaryAddress.address) && addressesAreEqual(primaryAddress.address, mailingAddress.address);

        if (!this.addressesAreTheSame) {
          this.addressForm.push(createAddressForm(mailingAddress.address, AddressType.Mailing));
        }
        break;
    }
  }

  private updateBusinessAddress() {
    if (addressIsEmpty(this.addressForm.value[0]) || this.addressForm.valid) {
      const addresses: AddressAccountAssociation[] = this.addressForm.value.map(
        (address: AddressAccountAssociation) => {
          return { address: { ...address } };
        }
      );
      const businessAddress = addresses[0] as AddressAccountAssociation;
      const businessUpdate = { ...this.userProfile.businessInformation };
      businessUpdate.businessAddress = businessAddress;
      this.isBusy = true;

      this.businessService.updateBusinessInformationAddress(businessUpdate).subscribe({
        next: result => {
          this.isBusy = false;
          this.updateBusinessAddresses.emit(result.data);
          this.back.emit();
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
    } else {
      this.addressForm.markAllAsTouched();
    }
  }

  private updateCompanyAddress(): void {
    if (this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
    } else {
      const businessId = this.selectedBusinessId;
      const sameAddresses = this.addressesAreTheSame;
      const addresses: AddressAccountAssociation[] = this.addressForm.value.map(
        (address: AddressAccountAssociation) => {
          return { address: { ...address } };
        }
      );

      if (sameAddresses) {
        addresses.push({ ...addresses[0], address: { ...addresses[0].address, addressType: AddressType.Mailing } });
      }

      this.isBusy = true;
      this.businessService
        .updateCompanyAddresses(businessId, addresses)
        .pipe(
          finalize(() => {
            this.isBusy = false;
          })
        )
        .subscribe({
          next: () => {
            this.updateAddresses.emit(addresses);
            this.back.emit();
          },
          error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        });
    }
  }

  private setCenterAndCheckboxText() {
    this.supportViewFacade.isRIAUser$.pipe(takeUntil(this.destroy$)).subscribe(isRIAUser => {
      this.centerText = isRIAUser
        ? this.labelTexts.isRIAUserCenterTextValue
        : this.labelTexts.nonRIAUserCenterTextValue;
      this.checkboxText = isRIAUser
        ? this.labelTexts.isRIAUserCheckBoxTextValue
        : this.labelTexts.nonRIAUserCheckBoxTextValue;
    });
  }
}
