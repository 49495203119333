import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { IAxosLegacyPagination } from '@app/inbox/models';
import { P2PTransferHistory, P2PTransferStatus } from '@app/transfers/typings';
import { DialogService, TransactionService } from '@core/services';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { AlertsIcons } from '@shared/enums';
import { DialogData } from '@shared/models';
import { Subject, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HistoryComponent implements OnInit, OnDestroy {
  public isLoadingHistory: boolean;
  public transferHistory: P2PTransferHistory[];
  public selectedP2PTransfer: P2PTransferHistory;
  public transferStatus: P2PTransferStatus;
  public headers: GenericOption[];
  public byTransfer: boolean;
  public transferId: number;
  public totalItems: number = 0;
  public currentPage: number = 0;
  public pageSize: number = 10;
  public tableData: P2PTransferHistory[] = [];
  public test: string;
  public isCancelingTrxn: boolean = false;
  public query: string;
  public screenCase: 'openAccount' | 'history' = 'history';

  isSBBActive: boolean;
  hasBusiness: boolean;
  hasIndividual: boolean;
  sbbAccounts: AccountProfile[];
  filteredHistory: P2PTransferHistory[] = [];

  destroy$ = new Subject<void>();
  filterRange: IAxosLegacyPagination['renderRange'] = [0, 0];

  constructor(
    public cachedAccountsService: CachedAccountsService,
    private _transactionService: TransactionService,
    private _serviceHelper: ServiceHelper,
    public dialogService: DialogService,
    public modalService: ModalService,
    public cp: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.byTransfer = !!this.transferId;
    this.isLoadingHistory = false;
    this.query = '';
    this.headers = [
      { value: 0, label: 'Date Sent' },
      { value: 1, label: 'Pay To' },
      { value: 2, label: 'Sent To' },
      { value: 3, label: 'Amount' },
      { value: 4, label: 'Status' },
    ];

    // SBB Logic
    this.isSBBActive = false;
    this.hasBusiness = false;
    this.hasIndividual = false;
    this.checkSbbAccounts();

    this._getTransferHistory();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  /**
   * Opens a modal to begin the cancel of a p2p transfer
   * @param p2pTransfer item in the input selected
   */
  public openCancelTransferModal(p2pTransfer: P2PTransferHistory): void {
    this.selectedP2PTransfer = { ...p2pTransfer };
    var modalOpts: ModalSettings = {
      okText: 'Confirm',
      icon: 'bofi-warning',
      bodyText: `<h3>You are about to cancel ${this.cp.transform(this.selectedP2PTransfer.amount)} to ${
        this.selectedP2PTransfer.recipient.displayName
      } </h3>
                       <p class="hidden-sm ms-secondary-text">To confirm this cancellation, press the confirm button below.</p>`,
    };
    this.modalService
      .show({ appendTo: $('.history') }, modalOpts)
      .then(_res => {
        this.cancelTransferRequest();
      })
      .catch(_reason => {});
  }

  /** Cancel an external p2p transfer */
  public cancelTransferRequest(): void {
    this.isCancelingTrxn = true;

    this._transactionService
      .cancelTransferP2P(this.selectedP2PTransfer.transferId)
      .pipe(
        tap(() => {
          const currentID = this.selectedP2PTransfer.transferId;
          this.tableData.forEach(item => {
            if (item.transferId === currentID) {
              item.status = 1;
            }
          });
          this.filteredHistory = this.filterPaymentHistory();
        }),
        finalize(() => {
          this.isCancelingTrxn = false;
        }),
        catchError((err: ApiError) => {
          const dialogData = new DialogData({
            icon: AlertsIcons.CheckCircle,
            content: err.data.message,
            hasCloseButton: false,
          });
          this.dialogService.open(dialogData);
          return of(err);
        })
      )
      .subscribe();
  }

  /** Filters the payment history taking as criteria the search box field */
  filterPaymentHistory() {
    const rows = this.tableData;
    return rows.filter((row: P2PTransferHistory) => {
      return (
        (this._isInteger(row.amount)
          ? `${row.amount}.00`.indexOf(this.query) >= 0
          : row.amount.toString().indexOf(this.query) >= 0) ||
        (row.recipient.displayName != null
          ? row.recipient.displayName.toLowerCase().indexOf(this.query.toLowerCase()) >= 0
          : row.recipient.displayName.toLowerCase().indexOf(this.query.toLowerCase()) >= 0) ||
        this.query == ''
      );
    });
  }

  filterHistoryDetails(
    _history: P2PTransferHistory[],
    [start, end]: IAxosLegacyPagination['renderRange']
  ): P2PTransferHistory[] {
    this._setTableData();
    if (start !== 0) start += 1;
    return this.filteredHistory.slice(start, end);
  }

  /** Sets number of records in pagination */
  _setTableData(): void {
    if (this.transferHistory) {
      this.tableData = this.transferHistory;
      this.filteredHistory = this.filterPaymentHistory();
    }
  }

  /** Load and fill the payment List */
  _getTransferHistory(): void {
    this.isLoadingHistory = true;
    this._transactionService
      .getTransfersP2P()
      .pipe(
        tap(res => {
          this.transferHistory = res.data;
          this.totalItems = this.transferHistory.length;
          this.currentPage = 1;
          this._setTableData();
        }),
        finalize(() => {
          this.isLoadingHistory = false;
        }),
        catchError(err => {
          this._serviceHelper.errorHandler(err);
          return of(err);
        })
      )
      .subscribe();
  }

  /**
   * Check how many individual and businesss accounts are from that aaccount
   * Depending on this logic it will show or not the new SBB container
   */
  checkSbbAccounts(): void {
    this.hasIndividual = (this.cachedAccountsService.paymentAccounts ?? []).some(acc => !acc.isSBB);
    this.hasBusiness = (this.cachedAccountsService.paymentAccounts ?? []).some(acc => acc.isSBB);

    if (this.hasBusiness && !this.hasIndividual) {
      this.screenCase = 'openAccount';
    } else if (this.hasIndividual) {
      this.screenCase = 'history';
    }
  }

  /**
   * Validates if the amount passed as argument is an integer number
   * @param value The value to evaluate
   */
  private _isInteger(value: number): boolean {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }
}
