import { createAction, props } from '@ngrx/store';

import { ExternalBankProvider } from '@shared/models';

import { AccountOverview } from '../../models/account-overview.model';

export const setAccountsLoading = createAction(
  '[Insights.Accounts] Set accounts loading',
  props<{ payload: boolean }>()
);

export const accountsLoaded = createAction('[Insights.Accounts] Loaded', props<{ payload: AccountOverview[] }>());

export const setProvidersLoading = createAction(
  '[Insights.Accounts] Set providers loading',
  props<{ payload: boolean }>()
);

export const providersLoaded = createAction(
  '[Insights.Accounts] Providers loaded',
  props<{ payload: ExternalBankProvider[] }>()
);
