<app-view-header titleText="Download Transactions"></app-view-header>
<app-dot-loader *ngIf="isLoadingFileFormats; else content">
  Loading
</app-dot-loader>

<ng-template #content>
  <ng-container *ngIf="showDropdown">
    <div class="fcol-md-4 container">
      <div class="instruction">
        Choose an account, then activity and file format that you’d like to download.
      </div>
      <app-download-form
        [accounts]="combinedAccounts"
        [defaultAccount]="accountId"
        [nwFileFormats]="nwFileFormats"
      ></app-download-form>
    </div>
  </ng-container>
</ng-template>
