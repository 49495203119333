import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { validEmailRegex } from '@app/config/regexes';

/**
 * Creates a FormGroup with all the necessary controls for Spousal Consent form.
 *
 *
 * @returns A FormGroup to create Spousal consent form
 */
export function createSpousalConsentForm() {
  const form = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(validEmailRegex)]),
  });

  return form;
}
