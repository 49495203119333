import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import {
  BILLPAYHELPER,
  BILLPAYROUTERHELPER,
  LOAD_USER_PROFILE_HELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { STATE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { Recipient } from '@legacy/bill-pay/typings/Recipient';
import { LoadUserProfileHelper } from '@legacy/services/load-user-profile-helper';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-e-statements-confirmation',
  templateUrl: './e-statements-confirmation.component.html',
  styleUrls: ['./e-statements-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class EStatementsConfirmationComponent implements OnInit{
  recipient: Recipient;
  confirmationEmail: string;
  paymentMethod: string;
  tooltip: string;

  constructor(
    @Inject(STATE) private readonly _stateService: IStateService,

    @Inject(BILLPAYROUTERHELPER) private readonly _billPayRouterHelper: BillPayRouterHelper,
    @Inject(BILLPAYHELPER) private readonly _billPayHelper: BillPayHelper,
    @Inject(LOAD_USER_PROFILE_HELPER) private readonly _loadUserProfileHelper: LoadUserProfileHelper

  ) {}

  ngOnInit(): void {
    this.recipient = this._billPayRouterHelper.getRecipientDetails();
    if (!this.recipient) this._stateService.go('udb.billPay.pay');

    const userProfile = this._loadUserProfileHelper.getUserProfile();
    this.confirmationEmail = userProfile.primaryEmail;
    this.paymentMethod = this._billPayHelper.mapPaymentMethod(this.recipient.paymentMethod);
    this.tooltip =
      `<b>Electronic recipients</b> are paid instantly. We’ll withdraw the payment the day before your payment is due. Your recipient will receive your payment on its due date.` +
      `<br/><br/>` +
      `<b>Check recipients</b> are paid with paper checks. We mail checks 5 business days before your payment’s due date. Your money will leave your account when the recipient deposits the check.`;
  }
  goToBillPayDashboard(): void {
    this._stateService.go('udb.billPay.pay');
  }
  redirectToSchedule(): void {
    this._stateService.go('udb.billPay.schedulePayment', { recipient: this.recipient });
  }

}
