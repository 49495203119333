<div class="manage-account-container row text-center justify-content-center">
  <div class="col-12 image-container">
    <img src="assets/img/logos/axos-invest-logo.svg" width="120px" alt="Axos Invest" aria-hidden="true" />
  </div>
  <div class="col-md-9 col-lg-6 col-sm-8">
    <p class="manage-account-description">
      For more functionality and features, visit
      <a id="overview-button" *ngIf="$axosInvestUrl | async; let axosInvestUrl" [href]="axosInvestUrl" target="_blank">
        Axos Invest
        <app-svg-icon class="redirect-icon" [iconName]="redirectIcon" size="12px"></app-svg-icon>
      </a>
    </p>
  </div>
</div>
