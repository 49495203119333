import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { MakeDepositViewComponent } from '@app/make-deposit/views';
import { RestrictionType } from '@legacy/common/enums/enums';
import { Inject } from '@legacy/decorators/Inject';

import { common } from '../common/common';
import { services } from '../services/services';

@Inject('$stateProvider')
class MakeDepositConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.makedeposit', {
        url: '/MakeDeposit',
        views: {
          content: {
            template: '<app-make-deposit-view-component></app-make-deposit-view-component>',
          },
        },
        resolve: {
          isRestricted: [
            'authHelper',
            (authHelper: IAuthHelper): any => {
              return authHelper.hasCustomerServiceRestriction(RestrictionType.MobileDeposit);
            },
          ],
        },
      })
      .state('udb.makedepositReceipt', {
        url: '/Receipt',
        views: {
          content: {
            template: '<app-transfer-receipt></app-transfer-receipt>',
          },
        },
        params: {
          settings: null,
          template: null,
        },
      });
  }
}

export const makeDepositLegacyModule = angular
  .module('udb.makedeposit', ['ui.router', 'ngMessages', services, common])
  .directive('appMakeDepositViewComponent', downgradeComponent({ component: MakeDepositViewComponent }))
  .config(MakeDepositConfig).name;
