import * as jsCookie from 'js-cookie';
import { IPromise } from 'angular';

/** Base class for all UDB services. */
abstract class BaseService {
  static pendingRequests: any[] = [];
  protected serviceUrl: string;
  protected headers: ng.IHttpRequestConfigHeaders = {};
  private reqConfig: ng.IRequestShortcutConfig;
  private facingBrandId: number;

  /**
   * Use the base service class to create your services.
   * @param http Angular HTTP service.
   * @param env Variable set on startup that has the API endpoint.
   * @param prefix Prefix to use with the service. For example: 'auth';
   */
  constructor(
    protected http: ng.IHttpService,
    protected env: OlbSettings,
    prefix: string,
    protected serviceHelper: IServiceHelper,
    private q: ng.IQService
  ) {
    this.serviceUrl = `${env.api}/${prefix}`;
    this.facingBrandId = env.facingBrandId;
  }

  /**
   * Triggers a HTTP POST to the server with the specified endpoint and data.
   * @param [endpoint=''] The endpoint to POST to. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @param [headers=null] Headers append.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected post(
    endpoint: string = '',
    data: any = null,
    headers: ng.IHttpRequestConfigHeaders = null
  ): ApiResponse<any> | any {
    this.setRequestHeaders();
    let postConfig = this.cloneReqConfig();

    if (headers) {
      Object.assign(postConfig.headers, headers);
    }

    BaseService.pendingRequests.push(postConfig);

    return this.http
      .post(`${this.serviceUrl}${endpoint ? `/${endpoint}` : ''}`, data, postConfig)
      .then(res => {
        this.removeFromPending(postConfig);
        return res.data;
      })
      .catch(this.serviceHelper.serviceError);
  }

  /**
   * Triggers a HTTP POST to the server (with BrandId appended as a header) with the specified endpoint and data.
   * @param [endpoint=''] The endpoint to POST to. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected postBranded(endpoint: string = '', data: any = null): ApiResponse<any> | any {
    let headers = { brandId: this.env.brandId };
    return this.post(endpoint, data, headers);
  }

  /**
   * Triggers a HTTP GET to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to GET from. Defaults to empty string.
   * @param [queryParams] Any data to be sent to the server. Keys must match query parameter name.
   * @param [cache=false] Specifies if the request should be cached.
   * @param [serviceUrl] The serviceUrl to override the default.
   * @param [headers=null] Headers append.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected get(
    endpoint: string = '',
    queryParams?: Object,
    cache: ng.ICacheObject | boolean = false,
    serviceUrl?: string,
    responseType?: string,
    headers: ng.IHttpRequestConfigHeaders = null,
    timeout: IPromise<any> = null
  ): ApiResponse<any> | any {
    let service = serviceUrl || this.serviceUrl;
    this.setRequestHeaders();
    let getConfig = this.cloneReqConfig();

    if (headers) {
      Object.assign(getConfig.headers, headers);
    }

    getConfig.params = queryParams;
    getConfig.cache = cache;
    getConfig.responseType = responseType;

    if (timeout) {
      getConfig.timeout = timeout;
    }

    return this.http
      .get(`${service}${endpoint ? `/${endpoint}` : ''}`, getConfig)
      .then(res => {
        this.removeFromPending(getConfig);
        return res.data;
      })
      .catch(this.serviceHelper.serviceError);
  }

  /**
   * Triggers a HTTP GET to the server with the BrandId (appended as a header) specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to GET from. Defaults to empty string.
   * @param [queryParams] Any data to be sent to the server. Keys must match query parameter name.
   * @param [cache=false] Specifies if the request should be cached.
   * @param [serviceUrl] The serviceUrl to override the default.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected getBranded(
    endpoint: string = '',
    queryParams?: Object,
    cache: boolean = false,
    serviceUrl?: string,
    responseType?: string
  ): ApiResponse<any> | any {
    let headers = { brandId: this.env.brandId };
    return this.get(endpoint, queryParams, cache, serviceUrl, responseType, headers);
  }

  /**
   * Triggers a HTTP DELETE to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to DELETE from. Defaults to empty string.
   * @param [queryParams] Any data to be sent to the server. Keys must match query parameter name.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected delete(endpoint: string = '', queryParams?: Object): ApiResponse<any> | any {
    this.setRequestHeaders();
    let deleteConfig = this.cloneReqConfig();
    deleteConfig.params = queryParams;
    return this.http
      .delete(`${this.serviceUrl}${endpoint ? `/${endpoint}` : ''}`, deleteConfig)
      .then(res => {
        this.removeFromPending(deleteConfig);
        return res.data;
      })
      .catch(this.serviceHelper.serviceError);
  }

  /**
   * Triggers a HTTP PUT to the server with the specified endpoint and query parameters.
   * @param [endpoint=''] The endpoint to PUT at. Defaults to empty string.
   * @param [data=null] Any data to be sent to the server.
   * @returns A promise in the format of ApiResponse<any>.
   */
  protected put(endpoint: string = '', data: any = null): ApiResponse<any> | any {
    this.setRequestHeaders();
    let putConfig = this.cloneReqConfig();
    return this.http
      .put(`${this.serviceUrl}${endpoint ? `/${endpoint}` : ''}`, data, putConfig)
      .then(res => {
        this.removeFromPending(putConfig);
        return res.data;
      })
      .catch(this.serviceHelper.serviceError);
  }

  /**
   * Clones the request configuration to prevent overriding the original one.
   * @returns A cloned configuration file based on the private one.
   */
  private cloneReqConfig(): ng.IRequestShortcutConfig {
    let canceller = this.q.defer();
    let newConfig: ng.IRequestShortcutConfig = {};

    for (let key in this.reqConfig) {
      newConfig[key] = this.reqConfig[key];
    }

    newConfig.timeout = canceller.promise;

    return newConfig;
  }

  private removeFromPending(config: ng.IRequestShortcutConfig): void {
    BaseService.pendingRequests = BaseService.pendingRequests.filter(item => item !== config);
  }

  private setRequestHeaders(): void {
    let token = jsCookie.get('XSRF-TOKEN');
    this.headers['Authorization'] = `Bearer ${token}`;
    this.headers['X-FacingBrandId'] = this.facingBrandId;

    this.reqConfig = {
      headers: this.headers,
      timeout: 45000,
    };
  }
}

export { BaseService };
