import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { FundingComponent } from '@app/axos-invest/components';

export const managedPortfolioLegacyModule = angular
  .module('udb.managedportfolio', ['ui.router'])
  .directive('appFunding', downgradeComponent({ component: FundingComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider.state('udb.managedportfolio', {
        url: '/FundingGoal',
        views: {
          content: {
            template: '<div ui-view="funding-content"><app-funding></app-funding></div>',
          },
        },
        params: {
          source: null, // used to verify origin
        },
      });
    },
  ]).name;
