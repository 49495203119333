import { AccountsService } from '@core/services';
import { finalize } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Minor } from '@app/minors/models';

import { ServiceHelper } from '@legacy/services/service.helper';
import { STATE_PARAMS, STATE } from '@core/tokens';
import { MinorHelper } from '@legacy/services/minor.helper';

@Component({
  selector: 'app-minor-main',
  templateUrl: './minor-main.component.html',
  styleUrls: ['./minor-main.component.scss'],
})
export class MinorMainComponent implements OnInit {
  minorForm = new FormGroup({
    minorAcceptTerms: new FormControl(false, [Validators.requiredTrue]),
    sameAsOwner: new FormControl(false, []),
    minorAddressStreet: new FormControl('', [Validators.required]),
    minorAddressZipCode: new FormControl('', [Validators.required]),
    minorCity: new FormControl('', [Validators.required]),
    minorState: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    socialSecureNumber: new FormControl('', [Validators.required]),
    dateOfBirth: new FormControl('', [Validators.required]),
    relationShip: new FormControl('', [Validators.required]),
    otherRelationShip: new FormControl('', [Validators.required]),
  });
  submitted = false;
  minor: Minor = {};
  selectedAccount: OlbAccount;
  accounts: OlbAccount[] = [];
  accountId: string;
  acceptTerms: boolean = false;
  result: string;
  showMore: boolean;
  isBusy = false;
  private readonly popups: IPopups;

  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private readonly params: ng.ui.IStateParamsService,
    private serviceHelper: ServiceHelper,
    private readonly minorHelper: MinorHelper,
    private readonly accountsService: AccountsService
  ) {}

  ngOnInit() {
    this.accountId = this.params['accountId'];
    this.getCurrentAccount();
    localStorage.removeItem('minorHelperResponse');
  }

  getCurrentAccount() {
    this.accountsService.getAccount(this.accountId).subscribe({
      next: ({ data }) => {
        this.selectedAccount = data;
      },
      error: error => this.serviceHelper.errorHandler(error),
    });
  }

  get minorAcceptTermsControl(): FormControl {
    return this.minorForm.get('minorAcceptTerms') as FormControl;
  }

  get sameAsOwnerControl(): FormControl {
    return this.minorForm.get('sameAsOwner') as FormControl;
  }

  setMinorsValues() {
    if (!this.sameAsOwnerControl.value) {
      this.minor.address.streetAddress1 = this.minorForm.get('minorAddressStreet')?.value;
      this.minor.address.postalCode = this.minorForm.get('minorAddressZipCode')?.value;
      this.minor.address.city = this.minorForm.get('minorCity')?.value;
    }

    this.minor.firstName = this.minorForm.get('firstName').value;
    this.minor.lastName = this.minorForm.get('lastName').value;
    this.minor.ssn = this.minorForm.get('socialSecureNumber').value;
    this.minor.relationship = this.minorForm.get('relationShip').value ?? this.minorForm.get('otherRelationShip').value;
  }

  acceptAndSave(): void {
    this.submitted = true;
    const agreementsSelected = this.minorAcceptTermsControl.value;
    if (this.minor.ssn === undefined || this.minor.ssn.length === 0) {
      this.popups?.showAlert(null, 'Missing Social Security Number', 'error');
      return;
    }

    if (agreementsSelected) {
      this.isBusy = true;
      this.minor.ownerAccountNumber = this.selectedAccount.accountNumber;
      this.minor.ownerAccountType = this.selectedAccount.accountType;
      this.minor.ownerCif = this.selectedAccount.cif;
      this.minor.accountId = +this.accountId;
      this.setMinorsValues();
      this.accountsService
        .addMinor(this.minor)
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe({
          next: resp => {
            this.minorHelper.saveMinorResponse(resp.data);
            localStorage.setItem('minorHelperResponse', JSON.stringify(resp.data));
            this.state.go('udb.accounts.details', { id: this.accountId, tab: 1 });
          },
          error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        });
    }
  }

  redirectToPage(paramAccountId: any): void {
    this.state.go('udb.accounts.details', {
      id: paramAccountId,
      tab: 1,
    });
  }
}
