import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { InteractionStudioService } from '@core/services/interaction-studio.service';

@Inject('loadUserProfileHelper')
@Component({
  selector: 'app-investor-checking-body',
  templateUrl: './investor-checking-body.component.html',
  styleUrls: ['./investor-checking-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingBodyComponent {
  public safeUrl: string;
  constructor(private InteractionStudioService: InteractionStudioService) {}
  ngOnInit() {
    this.InteractionStudioService.getInvestorCheckingScreen().subscribe(result => {
      this.safeUrl = result;
      const iframe = document.getElementById('InvestorCheckingIFrame') as HTMLIFrameElement;
      iframe.src = this.safeUrl;
    });
  }
}
