<div id="transfer-funds">
  <div class="axos-info-container">
    <div class="transfer-funds-header">
      <app-dot-loader class="info-loader" *ngIf="isLoading">Loading Account Details...</app-dot-loader>
      <div class="d-flex align-items-center flex-column" *ngIf="!isLoading">
        <h1 class="title">Transfer Funds</h1>
        <p class="goal">Investment Goal: {{ goal?.name }}</p>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!transferTypeSelected">
      <div class="row container transfer-funds-nav-header">
        <div class="col-lg-3 col-md-2">
          <div class="go-back">
            <button class="go-back-btn" id="goBack" (click)="goBack()" type="button">
              <app-svg-icon class="go-back-btn-icon" [iconName]="icons.back" size="1.15em"></app-svg-icon>
              <span class="go-back-btn-label">BACK</span>
            </button>
          </div>
        </div>
        <div class="col-lg-6 col-md-8">
          <div class="transfer-funds-selection">
            <p class="transfer-funds-instruction">Select the action you want to complete</p>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="transfer-funds-content container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-sm-12" id="transferFundComponent" *ngIf="!transferTypeSelected">
          <div class="deposit d-flex align-items-start flex-column">
            <h3 class="transfer-type-title">Deposit</h3>
            <button
              class="transfer-type"
              id="oneTimeDeposit"
              (click)="selectTransferType(TRANSFER_TYPES.OneTimeDeposit)"
              type="button"
            >
              <app-svg-icon class="transfer-type-icon" [iconName]="icons.piggyBank" size="1.8em"></app-svg-icon>
              <span class="transfer-type-label">Make One-Time Deposit</span>
            </button>
            <app-dot-loader *ngIf="loadingRecurrentDeposits">Loading recurrent deposits...</app-dot-loader>
            <button
              class="transfer-type edit-recurrent-deposit"
              *ngFor="let scheduledDeposit of scheduledDeposits"
              (click)="loadScheduledTransfer(TRANSFER_TYPES.RecurrentDeposit, scheduledDeposit.transferSeriesId)"
              type="button"
            >
              <div>
                <app-svg-icon class="transfer-type-icon" [iconName]="icons.edit" size="1.8em"></app-svg-icon>
              </div>
              <app-scheduled-transfer-card
                [isRetirement]="goal.type == goalType.Retirement"
                [scheduledTransfer]="scheduledDeposit"
                [depositName]="'Deposit'"
              ></app-scheduled-transfer-card>
            </button>
            <ng-container *ngIf="canAddAnotherRecurrentDeposit">
              <button
                class="transfer-type another-recurrent-deposit"
                *ngFor="let account of retirementAccountsWithoutScheduledDeposits"
                (click)="createAnotherScheduledTransfer(TRANSFER_TYPES.RecurrentDeposit, account.accountNumber)"
                type="button"
              >
                <div>
                  <app-svg-icon
                    class="transfer-type-icon"
                    [iconName]="icons.coinDollarSign"
                    size="1.8em"
                  ></app-svg-icon>
                </div>
                <div>
                  <span class="transfer-type-label">
                    Setup Recurring Deposit
                    <span class="d-none d-md-inline">- {{ account.name }}</span>
                  </span>
                  <span class="transfer-type-description d-block d-md-none">
                    {{ account.name }}
                  </span>
                </div>
              </button>
            </ng-container>
            <button
              *ngIf="scheduledDeposits?.length === 0 && !canAddAnotherRecurrentDeposit"
              class="transfer-type create-recurrent-deposit"
              (click)="selectTransferType(TRANSFER_TYPES.RecurrentDeposit)"
              type="button"
            >
              <app-svg-icon class="transfer-type-icon" [iconName]="icons.coinDollarSign" size="1.8em"></app-svg-icon>
              <span class="transfer-type-label">Setup Recurring Deposit</span>
            </button>
          </div>
          <div class="withdrawal d-flex align-items-start flex-column">
            <h3 class="transfer-type-title">Withdrawal</h3>
            <button
              class="transfer-type"
              id="oneTimeWithdrawl"
              (click)="selectTransferType(TRANSFER_TYPES.OneTimeWithdrawal)"
              type="button"
            >
              <app-svg-icon class="transfer-type-icon" [iconName]="icons.dollarStack" size="1.8em"></app-svg-icon>
              <span class="transfer-type-label">Make One-Time Withdrawal</span>
            </button>

            <button
              class="transfer-type edit-recurrent-withdrawal"
              *ngFor="let scheduledWithdrawal of scheduledWithdrawals"
              (click)="loadScheduledTransfer(TRANSFER_TYPES.RecurrentWithdrawal, scheduledWithdrawal.transferSeriesId)"
              type="button"
            >
              <div>
                <app-svg-icon class="transfer-type-icon" [iconName]="icons.edit" size="1.8em"></app-svg-icon>
              </div>
              <app-scheduled-transfer-card
                [isRetirement]="goal.type == goalType.Retirement"
                [scheduledTransfer]="scheduledWithdrawal"
                [depositName]="'Withdrawal'"
              ></app-scheduled-transfer-card>
            </button>
            <ng-container *ngIf="canAddAnotherRecurrentWithdrawal">
              <button
                class="transfer-type another-recurrent-withdrawal"
                *ngFor="let account of retirementAccountsWithoutScheduledWithdrawals"
                (click)="createAnotherScheduledTransfer(TRANSFER_TYPES.RecurrentWithdrawal, account.accountNumber)"
                type="button"
              >
                <div>
                  <app-svg-icon
                    class="transfer-type-icon"
                    [iconName]="icons.coinDollarSign"
                    size="1.8em"
                  ></app-svg-icon>
                </div>
                <div>
                  <span class="transfer-type-label">
                    Setup Recurring Withdrawal
                    <span class="d-none d-md-inline">- {{ account.name }}</span>
                  </span>
                  <span class="transfer-type-description d-block d-md-none">
                    {{ account.name }}
                  </span>
                </div>
              </button>
            </ng-container>
            <button
              *ngIf="scheduledWithdrawals?.length === 0 && !canAddAnotherRecurrentWithdrawal"
              class="transfer-type"
              id="recurringWithdrawal"
              (click)="selectTransferType(TRANSFER_TYPES.RecurrentWithdrawal)"
              type="button"
            >
              <app-svg-icon class="transfer-type-icon" [iconName]="icons.dollarStack" size="1.8em"></app-svg-icon>
              <span class="transfer-type-label">Setup Recurring Withdrawal</span>
            </button>
          </div>
        </div>

        <div class="transfer-funds-container" *ngIf="transferTypeSelected">
          <app-transfer-funds
            [goal]="goal"
            [transferType]="transferType"
            [transferData]="transferData"
            (goBackEmitter)="goBackWithHistory()"
          ></app-transfer-funds>
        </div>
      </div>
    </div>
  </ng-container>
  <app-dot-loader class="info-loader" *ngIf="isLoading"></app-dot-loader>
</div>
