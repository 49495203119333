export class AddressHelper {
  homeAddressEqualToMailingAddress(primaryAddress: Address, mailingAddress: Address): boolean {
    const pAddressStr = this.addressToString(primaryAddress);
    const mAddressStr = this.addressToString(mailingAddress);
    return pAddressStr === mAddressStr;
  }

  isPrimaryAddressEmpty(address: Address) {
    return (
      !address.streetAddress1 &&
      !address.city &&
      !address.stateCode &&
      !address.postalCode &&
      !address.state
    );
  }

  private emptyStringIfNull = (str: string) => (str ? `${str} ` : '');

  /**
   * Creates a string for an address only taking in consideration the steet line 1 and 2
   * (if exists), city, state, stateCode, postalCode.
   */
  private addressToString(address: Address): string {
    const { streetAddress1, streetAddress2, city, state, stateCode, postalCode } = address;

    const returningStr = `${this.emptyStringIfNull(streetAddress1)}${this.emptyStringIfNull(
      streetAddress2
    )}${this.emptyStringIfNull(city)}${this.emptyStringIfNull(state)}${this.emptyStringIfNull(
      stateCode
    )}${this.emptyStringIfNull(postalCode)}`;

    return returningStr.trim().toLowerCase();
  }
}
