import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodPrimaryBeneficiaryComponent } from './view/tod-primary-beneficiary.component';

@NgModule({
  declarations: [TodPrimaryBeneficiaryComponent],
  imports: [CommonModule],
  exports: [TodPrimaryBeneficiaryComponent],
})
export class TodPrimaryBeneficiaryModule {}
