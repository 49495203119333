import { AggregatedAccount } from 'typings/app/account-aggregation/AggregatedAccount';
import { containerType } from '../accounts/container-type.enum';
import { accountType } from '../accounts/account-type.enum';
import { AccountCategory } from '../accounts/account-category.enum';

export class DashboardBalanceService {
  private containerType = containerType;
  private accountType = accountType;
  private accountCategory = AccountCategory;

  getBalanceAsset(account: AggregatedAccount): number {
    let balance = 0;

    switch (account.container) {
      // case this.containerType.Reward:
      //   if the container is 'reward', exclude the account
      //   from both total assets and total debt calculations
      //   break;
      case this.containerType.Bill:
        balance = this.getBillBalance(account);
        break;
      case this.containerType.Insurance:
        balance = this.getInsuranceBalanceAsset(account);
        break;
      case this.containerType.RealEstate:
        balance = this.getRealEstateBalanceAsset(account);
        break;
      case this.containerType.Bank:
        balance = this.getBankBalanceAsset(account);
        break;
      case this.containerType.Investment:
      case this.containerType.CreditCard:
        balance = this.getInvestmentCreditCarBalanceAsset(account);
        break;
      case this.containerType.Loan:
        // outstandingBalance (entity loanPayoffDetails) is not currently enabled by Yodlee
        balance = this.getLoanBalanceAsset(account);
        break;
      default:
        // Internal accounts do not have container
        balance = this.getDefaultBalanceAsset(account);
        break;
    }
    return balance;
  }

  getBalanceDebt(account: AggregatedAccount): number {
    let balance = 0;

    switch (account.container) {
      // case this.containerType.Reward:
      // if the container is 'reward', exclude the account
      // from both total assets and total debt calculations
      // break;
      case this.containerType.Bill:
        balance = this.getBillBalanceDebt(account);
        break;
      case this.containerType.Insurance:
        balance = this.getInsuranceBalanceDebt(account);
        break;
      case this.containerType.RealEstate:
        balance = this.getRealEstateBalanceDebt(account);
        break;
      case this.containerType.Bank:
        balance = this.getBankBalanceDebt(account);
        break;
      case this.containerType.Investment:
      case this.containerType.CreditCard:
        balance = this.getInvestmentCreditCardBalanceDebt(account);
        break;
      case this.containerType.Loan:
        balance = this.getLoanBalanceDebt(account);
        break;
      default:
        balance = this.getDefaultBalanceDebt(account);
        break;
    }
    return balance;
  }
  private getBillBalance(account: AggregatedAccount) {
    let balance = 0;
    if (account.isAsset) balance = account.balance;
    return balance;
  }

  private getRealEstateBalanceAsset(account: AggregatedAccount) {
    let homeValue = 0;
    if (account.isAsset) homeValue = account.homeValue != null ? account.homevalue.amount : -1;
    if (homeValue === -1) homeValue = account.balance != null ? account.balance : 0;
    return homeValue;
  }

  private getDefaultBalanceAsset(account: AggregatedAccount) {
    return account.availableBalance === undefined ? 0 : account.availableBalance;
  }

  private getLoanBalanceAsset(account: AggregatedAccount) {
    let balance = 0;
    if (account.isAsset) balance = account.balance != null ? account.balance : 0;
    return balance;
  }

  private getInvestmentCreditCarBalanceAsset(account: AggregatedAccount) {
    let balance = 0;
    if (account.isAsset) balance = account.balance != null ? account.balance : 0;
    return balance;
  }

  private getBankBalanceAsset(account: AggregatedAccount) {
    let balance = 0;
    switch (account.category) {
      case this.accountCategory.Sav: // savings
      case this.accountCategory.Dda: // checking
        balance = account.availableBalance != null ? account.availableBalance : 0;
        break;
      default:
        balance = account.balance != null ? account.balance : 0;
        break;
    }
    return balance;
  }

  private getInsuranceBalanceAsset(account: AggregatedAccount) {
    let balance = 0;
    if (account.isAsset) {
      if (account.remainingBalance != null) balance = account.remainingBalance.amount;
      else {
        if (
          account.balance != null &&
          (account.accountType !== this.accountType.HomeInsurance ||
            account.accountType !== this.accountType.AutoInsurance)
        ) {
          balance = account.balance;
        }
      }
    }
    return balance;
  }

  private getBillBalanceDebt(account: AggregatedAccount) {
    let balance = 0;
    if (!account.isAsset) balance = account.balance;
    return balance;
  }

  private getInsuranceBalanceDebt(account: AggregatedAccount): number {
    let balance = 0;
    if (!account.isAsset) {
      if (account.remainingBalance != null) balance = account.remainingBalance.amount;
      else {
        if (
          account.balance != null &&
          (account.accountType !== this.accountType.HomeInsurance ||
            account.accountType !== this.accountType.AutoInsurance)
        ) {
          balance = account.balance;
        }
      }
    }
    return balance;
  }

  private getRealEstateBalanceDebt(account: AggregatedAccount): number {
    let balance = 0;
    let homeValue = 0;
    if (!account.isAsset) homeValue = account.homeValue != null ? account.homevalue.amount : -1;
    if (homeValue === -1) homeValue = account.balance != null ? account.balance : 0;
    balance = homeValue;
    return balance;
  }

  private getBankBalanceDebt(account: AggregatedAccount): number {
    let balance = 0;
    if (!account.isAsset) {
      switch (account.category) {
        case this.accountCategory.Sav: // savings
        case this.accountCategory.Dda: // checking
          balance = account.availableBalance != null ? account.availableBalance : 0;
          break;
        default:
          balance = account.balance != null ? account.balance : 0;
          break;
      }
    }
    return balance;
  }

  private getInvestmentCreditCardBalanceDebt(account: AggregatedAccount): number {
    let balance = 0;
    if (!account.isAsset) balance = account.balance != null ? account.balance : 0;
    return balance;
  }
  private getLoanBalanceDebt(account: AggregatedAccount): number {
    let balance = 0;
    // outstandingBalance (entity loanPayoffDetails) is not currently enabled by Yodlee
    if (!account.isAsset) balance = account.balance;
    return balance;
  }
  private getDefaultBalanceDebt(account: AggregatedAccount) {
    let balance = 0;
    balance = account.availableBalance === undefined ? 0 : account.availableBalance;
    return balance;
  }
}
