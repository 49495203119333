export enum Freq {
  NOTSET = 0,
  ONETIME = 1,
  WEEKLY = 2,
  EVERYTWOWEEKS = 3,
  MONTHLY = 4,
  QUARTERLY = 5,
  SEMIANNUALLY = 6,
  ANNUALLY = 7,
}

//TODO: Move to a pipetransform
export function mapFrequency(frequency: number): string {
  switch (frequency) {
    case Freq.ONETIME:
      return 'One Time';
    case Freq.WEEKLY:
      return 'Weekly';
    case Freq.EVERYTWOWEEKS:
      return 'Every Two Weeks';
    case Freq.MONTHLY:
      return 'Monthly';
    case Freq.QUARTERLY:
      return 'Quarterly';
    case Freq.SEMIANNUALLY:
      return 'Semiannually';
    case Freq.ANNUALLY:
      return 'Annually';
    default:
      return '';
  }
}
