import { HoldingTileState } from './holdings-tile-state.type';

export type HoldingState = {
  accountsHoldings: HoldingTileState[];
  selectedAccountHoldings: string | undefined;
};

export const initialHoldingState: HoldingState = {
  accountsHoldings: [],
  selectedAccountHoldings: undefined,
};
