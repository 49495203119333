import { Component, EventEmitter, Input } from '@angular/core';

import { Transaction } from '@app/axos-trading/models';

@Component({
  selector: 'app-table-orders',
  templateUrl: './table-orders.component.html',
  styleUrls: ['./table-orders.component.scss'],
})
export class TableOrdersComponent {
  @Input() transactions: Transaction[] = [];
  @Input() accountId: number;
  unselectEvent = new EventEmitter<string>();
  lastSortedColumn = 'date';
  get isAscendent() {
    return this.counter % 2 === 0;
  }
  private counter = 0;

  sortByDate() {
    const column = 'date';
    this.counter = this.lastSortedColumn === column ? this.counter + 1 : 0;
    this.lastSortedColumn = column;
    const order = this.isAscendent ? 'asc' : 'desc';
    if (!this.transactions || this.transactions.length <= 1) return;
    this.transactions = this.transactions.sort(this.compareValues([column], order));
  }

  sortBy(...columns: string[]) {
    const column = columns.join('-');
    this.counter = this.lastSortedColumn === column ? this.counter + 1 : 0;
    this.lastSortedColumn = column;
    const order = this.isAscendent ? 'asc' : 'desc';
    if (!this.transactions || this.transactions.length <= 1) return;
    this.transactions = this.transactions.sort(this.compareValues(columns, order));
  }

  private compareValues(keys: string[], order = 'asc') {
    return (a: any, b: any) => {
      const key = keys[0];
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      } else if (keys.length > 1) {
        return this.compareValues(keys.slice(1, keys.length), order)(a, b);
      }

      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
}
