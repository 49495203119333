import { createFeatureSelector } from '@ngrx/store';

import { AppStateFeatures } from '@core/enums';

import { AccountsState } from './accounts/accounts.state';
import { NetWorthState } from './net-worth/net-worth.state';
import { SpendingState } from './spending/spending.state';

export class PfmState {
  accountsState = new AccountsState();
  spendingState = new SpendingState();
  netWorthState = new NetWorthState();

  constructor(args?: Partial<PfmState>) {
    Object.assign(this, args);
  }
}

export const getPfmState = createFeatureSelector<PfmState>(AppStateFeatures.Pfm);
