export type StatementStateType = {
  id: number | undefined;
  statementDate: Date | undefined | string;
  resourceUrl: string | undefined;
  resourceExpiration: Date | undefined;
  statementName: string | undefined;
  statementDescription: string | undefined;
};

export const initialStatementsState = {
  id: undefined,
  statementDate: undefined,
  resourceUrl: undefined,
  resourceExpiration: undefined,
  statementName: undefined,
  statementDescription: undefined,
};
