import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { NgxMaskModule } from 'ngx-mask';

import {
  cachedAccountsServiceProvider,
  enrollmentServiceProvider,
  featureFlagServiceProvider,
  ngReduxServiceProvider,
  serviceHelperProvider,
  stateServiceProvider,
} from '@core/providers';
import { SharedModule } from '@shared/shared.module';

import {
  ClickSwitchAccountDropdownComponent,
  ClickSwitchAccountsSelectionComponent,
  ClickSwitchComponent,
  ClickSwitchConfirmationComponent,
  ClickSwitchConfirmationPageComponent,
  ClickSwitchDetailsComponent,
  ClickSwitchHeaderComponent,
  ClickSwitchOnlinePathComponent,
  ClickSwitchReviewEditComponent,
  ClickSwitchSearchTargetComponent,
  ClickSwitchSubmittableFormComponent,
  ClickSwitchTermsConditionsComponent,
  ClickSwitchTermsModalComponent,
} from './components';

const exportComponents = [
  ClickSwitchAccountDropdownComponent,
  ClickSwitchAccountsSelectionComponent,
  ClickSwitchComponent,
  ClickSwitchConfirmationPageComponent,
  ClickSwitchHeaderComponent,
  ClickSwitchOnlinePathComponent,
  ClickSwitchSearchTargetComponent,
  ClickSwitchSubmittableFormComponent,
  ClickSwitchReviewEditComponent,
  ClickSwitchConfirmationComponent,
  ClickSwitchDetailsComponent,
  ClickSwitchTermsConditionsComponent,
  ClickSwitchTermsModalComponent,
];
const externalProviders = [
  cachedAccountsServiceProvider,
  enrollmentServiceProvider,
  featureFlagServiceProvider,
  ngReduxServiceProvider,
  stateServiceProvider,
  serviceHelperProvider,
];

@NgModule({
    declarations: [...exportComponents],
    imports: [
        SharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [...exportComponents],
    providers: [...externalProviders]
})
export class ClickSwitchModule {}
