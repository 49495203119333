import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { BillPayTopics } from '@app/support/enums';

@Component({
  selector: 'how-to-bill-pay-topics',
  templateUrl: './bill-pay-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BillPayTopicsComponent {
  selectedTopic: BillPayTopics;
  topics = BillPayTopics;

  selectTopic(topic: BillPayTopics): void {
    this.selectedTopic = topic;
  }
}
