import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { DATE_FORMAT } from '../../constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-contingent-beneficiary',
  templateUrl: './contingent-beneficiary.component.html',
  styleUrls: ['./contingent-beneficiary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class ContingentBeneficiaryComponent implements OnInit, OnDestroy {
  contingentBeneficiaries: Array<{
    name: string;
    birthDate: string;
    contingentPercentage: number;
    spouse: boolean;
  }>;

  readonly destroy$ = new Subject<void>();

  constructor(
    private peopleFacade: PeopleFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.peopleFacade.accountPeopleTabState$
      .pipe(
        takeUntil(this.destroy$),
        filter(accountPeopleTabState => !!accountPeopleTabState?.accountPeople?.contingentBeneficiaries),
        map(accountPeopleTabState => {
          return accountPeopleTabState.accountPeople.contingentBeneficiaries
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => b.contingentPercentage - a.contingentPercentage);
        })
      )
      .subscribe(contingentBeneficiaries => {
        this.contingentBeneficiaries = contingentBeneficiaries.map(cb => ({
          name: cb.name,
          birthDate: this.formatBirthDate(cb.birthDate),
          contingentPercentage: cb.contingentPercentage,
          spouse: cb.spouse,
        }));
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatBirthDate(birthDate: Date): string {
    const defaultDateTimeValue = new Date('0001-01-01T00:00:00');
    const birthDateTimeValue = new Date(birthDate);
    return birthDateTimeValue > defaultDateTimeValue ? this.datePipe.transform(birthDate, DATE_FORMAT) : '';
  }
}
