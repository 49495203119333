import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { format, parseISO } from 'date-fns';

import { SpousalConsentModalComponent } from '@app/accounts/components/modals';
import { getTradingAccount } from '@app/accounts/store/selectors';
import { UserProfileViews } from '@app/user-profile/enums';
import { emailRegex } from '@app/utils';
import { DialogService } from '@core/services';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { TradingAccount } from '@legacy/accounts/typings/TradingAccount';
import { AxosClearingService } from '@legacy/services/axos-clearing.service';
import { BeneficiariesIraEnhHelper, ToastType } from '@legacy/services/beneficiaries-iraenh.helper';
import { ServiceHelper } from '@legacy/services/service.helper';
import { DialogComponent } from '@shared/components';
import {
  AlertsIcons,
  BeneficiaryType,
  ClearingAccountType,
  ClearingBeneficiaryRelationshipType,
  NavigationIcons,
  UtilityIcons,
} from '@shared/enums';
import { BeneficiariesRequest, BeneficiaryCommon, BeneficiaryTrading, dialogConfig, DialogData } from '@shared/models';

const percentageRegExp = new RegExp('^[1-9][0-9]?$|^100$');
const birthdayRegExp = new RegExp(/^(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d{2}/);
const fieldTolabel = {
  firstname: 'First Name',
  lastname: 'Last Name',
  birthDate: 'Date of Birth [MM/DD/YYYY]',
  percentage: 'Percentage',
  spouseName: 'Spouse First Name',
  spouseLastName: 'Spouse Last Name',
  spouseEmail: 'Spouse Email',
};

export interface SvgIconProps {
  icon: AlertsIcons.ExCircle | AlertsIcons.CheckCircle | NavigationIcons.ChevronUp | NavigationIcons.ChevronDown;
  color: 'var(--danger)' | 'var(--success)' | '#0070d9';
}

@Component({
  selector: 'app-trading-beneficiaries',
  templateUrl: './trading-beneficiaries.component.html',
  styleUrls: ['./trading-beneficiaries.component.scss'],
})
export class TradingBeneficiariesComponent implements OnInit, OnDestroy {
  backIcon = NavigationIcons.ArrowBack;
  deleteIcon = UtilityIcons.Trash;
  addIcon = UtilityIcons.Plus;
  percentageIconStyles = {
    width: '1.27rem',
    height: '1.27rem',
    strokeWidth: 1,
  };
  subtitle: string;

  isSaving = false;
  isCustomerMarried = false;
  otherOptionsOpen = false;
  totalPercentage = 100;
  accountId: string;
  accountBeneficiaries: BeneficiaryTrading[];
  beneficiariesType: BeneficiaryType;
  beneficiariesDataForm: UntypedFormGroup = null;
  relationships = ClearingBeneficiaryRelationshipType;
  beneficiaryTypes = BeneficiaryType;
  beneficiariesModel: BeneficiaryCommon[];
  relationshipKeys = Object.values(ClearingBeneficiaryRelationshipType).filter(value => typeof value !== 'string');
  isSpousalConsentNeeded = false;
  showSaveMessage = false;
  tradingAccount: TradingAccount;
  isEditingCurrentBeneficiaries = false;

  get percentageStatus(): SvgIconProps {
    const icon = this.isValidPercentage() ? AlertsIcons.CheckCircle : AlertsIcons.ExCircle;
    const color = this.isValidPercentage() ? 'var(--success)' : 'var(--danger)';

    return { icon, color };
  }

  get otherOptionsIcon(): SvgIconProps {
    const icon = this.otherOptionsOpen ? NavigationIcons.ChevronUp : NavigationIcons.ChevronDown;
    const color = '#0070d9';

    return { icon, color };
  }

  private dialog: MatDialogRef<DialogComponent>;
  private subsink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogService,
    private axosClearingService: AxosClearingService,
    private serviceHelper: ServiceHelper,
    private beneficiariesIraEnhHelper: BeneficiariesIraEnhHelper,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.beneficiariesType = this.state.params.contingent ? BeneficiaryType.Contingent : BeneficiaryType.Primary;
    this.accountId = this.state.params.id;
    this.tradingAccount = this.state.params.tradingAccount;

    if (this.tradingAccount) {
      this.accountBeneficiaries = this.tradingAccount.beneficiaries;
      const subtitle = this.getNickname();
      this.initialData(subtitle);
    } else {
      this.subsink.sink = this.store
        .select(getTradingAccount(+this.accountId))
        .pipe(filter(account => !!account))
        .subscribe(account => {
          this.tradingAccount = account;
          this.accountBeneficiaries = account.beneficiaries;
          const subtitle = this.getNickname();
          this.initialData(subtitle);
        });
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  trackByBeneficiaryName(beneficiary: BeneficiaryTrading): string {
    return beneficiary.firstName;
  }

  beneficiaries(): UntypedFormArray {
    return this.beneficiariesDataForm.get('beneficiaries') as UntypedFormArray;
  }

  addBeneficiary(): void {
    this.beneficiaries().push(this.buidlBeneficiary(null));
    this.verifySpousalConsentNeeded(true);
  }

  deleteBeneficiary(index: number): void {
    if (this.beneficiaries().length === 1) {
      this.showLeaveModal();
    } else {
      this.beneficiaries().removeAt(index);
    }
    this.verifySpousalConsentNeeded();
  }

  back(updateAccounts: boolean = false): void {
    if (this.dialog) return;
    const dialogData = new DialogData({
      title: 'Back to Account Details?',
      content: '<p class="ms-secondary-text">Your changes won\'t be saved if you leave</p>',
      cancelText: 'Cancel',
      okText: 'Ok',
      icon: AlertsIcons.QuestionCircle,
    });

    this.dialog = this.dialogService.open(dialogData);

    this.subsink.sink = this.dialog.afterClosed().subscribe(leave => {
      if (leave) {
        this.state.go('^', { updateAccounts });
      }

      this.dialog = null;
    });
  }

  gotoProfile(): void {
    this.state.go('udb.userProfile', { view: UserProfileViews.PersonalInformation });
  }

  beneficiaryValidator(index: number, formControlName: string) {
    const { touched, errors } = this.beneficiaries().at(index).get(formControlName);

    const keys = errors ? Object.keys(errors) : [];

    return touched && this.commonValidationMessages(fieldTolabel[formControlName], keys[0]);
  }

  spouseValidator(formControlName: string) {
    const { touched, errors } = this.beneficiariesDataForm.get(formControlName);

    const keys = errors ? Object.keys(errors) : [];

    return touched && this.commonValidationMessages(fieldTolabel[formControlName], keys[0]);
  }

  save() {
    if (this.isEditingCurrentBeneficiaries) {
      this.verifySpousalConsentNeeded();
    }

    if (this.beneficiariesDataForm.invalid || !this.isValidPercentage() || this.hasMultipleSpousalSelected()) {
      this.showSaveMessage = true;
      this.beneficiariesDataForm.markAllAsTouched();

      return;
    }

    this.saveRequest();
  }

  isValidPercentage(): boolean {
    const beneficiariesIncluded = [...this.beneficiaries().controls];
    this.totalPercentage = 0;

    for (const bene of beneficiariesIncluded) {
      this.totalPercentage += bene.get('percentage').value ? +bene.get('percentage').value : 0;
    }

    return this.totalPercentage > 0 && this.totalPercentage === 100;
  }

  showMultipleSpousalError(index: number): boolean {
    const { value } = this.beneficiaries().at(index).get('relationship');

    return +value === ClearingBeneficiaryRelationshipType.Spouse && this.hasMultipleSpousalSelected();
  }

  hasMultipleSpousalSelected(): boolean {
    const beneficiariesRelationship = this.beneficiaries().controls.map(c => c.get('relationship'));

    return beneficiariesRelationship.filter(r => +r.value === ClearingBeneficiaryRelationshipType.Spouse).length > 1;
  }

  private verifySpousalConsentNeeded(fromAdd: boolean = false) {
    if (this.beneficiariesType !== BeneficiaryType.Primary) {
      this.removeSpousalFields();

      return false;
    }

    const beneficiariesIncluded = [...this.beneficiaries().controls];

    if (
      this.isCustomerMarried &&
      (beneficiariesIncluded?.length > 1 ||
        (beneficiariesIncluded?.length === 1 &&
          +beneficiariesIncluded[0].get('relationship').value !== ClearingBeneficiaryRelationshipType.Spouse))
    ) {
      if (!this.isSpousalConsentNeeded) {
        this.addSpousalFields(fromAdd);
      }

      this.isSpousalConsentNeeded = true;

      return;
    }

    if (!this.isCustomerMarried && beneficiariesIncluded?.length > 1) {
      for (const bene of beneficiariesIncluded) {
        if (+bene.get('relationship').value === ClearingBeneficiaryRelationshipType.Spouse) {
          if (!this.isSpousalConsentNeeded) {
            this.addSpousalFields(fromAdd);
          }

          this.isSpousalConsentNeeded = true;

          return;
        }
      }
    }

    this.isSpousalConsentNeeded = false;
    this.removeSpousalFields();
  }

  private initializeFormBuilder() {
    let beneficiaries: UntypedFormGroup[];
    if (this.accountBeneficiaries?.length > 0) {
      beneficiaries = this.loadUserBeneficiaries();
    } else {
      beneficiaries = [this.buidlBeneficiary('100')];
    }

    this.beneficiariesDataForm = this.formBuilder.group({
      beneficiaries: this.formBuilder.array([...beneficiaries], [Validators.min(1)]),
      spouseName: new UntypedFormControl('', [Validators.required]),
      spouseLastName: new UntypedFormControl('', [Validators.required]),
      spouseEmail: new UntypedFormControl('', [Validators.required, Validators.pattern(emailRegex)]),
    });

    beneficiaries.forEach(beneControl => {
      this.subsink.sink = beneControl.get('firstname').valueChanges.subscribe(() => {
        this.isEditingCurrentBeneficiaries = true;
      });
      this.subsink.sink = beneControl.get('lastname').valueChanges.subscribe(() => {
        this.isEditingCurrentBeneficiaries = true;
      });
      this.subsink.sink = beneControl.get('birthDate').valueChanges.subscribe(() => {
        this.isEditingCurrentBeneficiaries = true;
      });
      this.subsink.sink = beneControl.get('relationship').valueChanges.subscribe(() => {
        this.isEditingCurrentBeneficiaries = true;
      });
    });
  }

  private loadUserBeneficiaries(): UntypedFormGroup[] {
    const beneficiaries = this.accountBeneficiaries.filter(b => b.type === this.beneficiariesType);
    if (beneficiaries?.length === 0) {
      return [this.buidlBeneficiary('100')];
    }

    const formBeneficiaries: UntypedFormGroup[] = [];
    for (const b of beneficiaries) {
      formBeneficiaries.push(this.buidlBeneficiary('0', b));
    }

    return formBeneficiaries;
  }

  private buidlBeneficiary(percentage: string = '', beneficiary: BeneficiaryTrading = null): UntypedFormGroup {
    const birthDate = beneficiary?.birthDate ? format(parseISO(beneficiary.birthDate), 'MM/dd/yyyy') : '';
    const relationship = this.beneficiariesDataForm ? 4 : 6;

    const beneControl = this.formBuilder.group({
      firstname: new UntypedFormControl(beneficiary?.firstName ?? '', [Validators.required]),
      lastname: new UntypedFormControl(beneficiary?.lastName ?? '', [Validators.required]),
      birthDate: new UntypedFormControl(birthDate, [Validators.required, Validators.pattern(birthdayRegExp)]),
      relationship: new UntypedFormControl(beneficiary?.relationship ?? relationship, [Validators.required]),
      percentage: new UntypedFormControl(beneficiary?.percentage ?? percentage, [
        Validators.required,
        Validators.pattern(percentageRegExp),
      ]),
      type: new UntypedFormControl(this.beneficiariesType, [Validators.required]),
    });

    return beneControl;
  }

  private showLeaveModal() {
    if (this.dialog) return;

    const dialogData = new DialogData({
      title: 'Remove All Your Beneficiaries?',
      content:
        '<p class="ms-secondary-text">Any contingent beneficiaries will also be removed. If no beneficiaries are named, the assets in your account will go to your estate.</p>',
      cancelText: 'Cancel',
      okText: 'Remove',
      icon: AlertsIcons.QuestionCircle,
    });

    this.dialog = this.dialogService.open(dialogData);

    this.subsink.sink = this.dialog.afterClosed().subscribe(leave => {
      if (leave) {
        this.saveRequest(true);
      }

      this.dialog = null;
    });
  }

  private showSpousalModal(fromAdd: boolean) {
    if (this.dialog) return;

    const dialogData = new DialogData({
      title: 'Request spousal consent?',
      icon: AlertsIcons.QuestionCircle,
      component: new ComponentPortal(SpousalConsentModalComponent),
    });
    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;

    this.subsink.sink = this.dialogService
      .openCustom(dialogConfig)
      .afterClosed()
      .subscribe(leave => {
        if (!leave) {
          if (fromAdd) {
            this.deleteBeneficiary(this.beneficiaries().length - 1);
          }
          this.removeSpousalFields();
          this.isSpousalConsentNeeded = false;
        }

        this.dialog = null;
      });
  }

  private commonValidationMessages(field: string, type: string) {
    switch (type) {
      case 'required':
        return `${field} is required.`;
      case 'pattern':
        return `${field} is not valid, please enter a valid answer.`;
    }
  }

  private addSpousalFields(fromAdd: boolean): void {
    this.beneficiariesDataForm.addControl('spouseName', new UntypedFormControl('', [Validators.required]));
    this.beneficiariesDataForm.addControl('spouseLastName', new UntypedFormControl('', [Validators.required]));
    this.beneficiariesDataForm.addControl(
      'spouseEmail',
      new UntypedFormControl('', [Validators.required, Validators.pattern(emailRegex)])
    );

    this.showSpousalModal(fromAdd);
  }

  private removeSpousalFields(): void {
    this.beneficiariesDataForm.removeControl('spouseName');
    this.beneficiariesDataForm.removeControl('spouseLastName');
    this.beneficiariesDataForm.removeControl('spouseEmail');
  }

  private saveRequest(removeAll: boolean = false) {
    const toastSuccessMsg = 'You have successfully modified the beneficiaries on your account.';
    const toastErrorMsg =
      'Some of the beneficiaries could not be saved, please verify the beneficiaries on your account.';

    this.isSaving = true;
    const request = this.beneficiariesDataForm.value as BeneficiariesRequest;
    request.requestConsent = this.isSpousalConsentNeeded;
    request.type = this.beneficiariesType;

    if (removeAll) {
      request.beneficiaries = [];
      request.requestConsent = false;
    }

    this.axosClearingService
      .addBeneficiaries(this.tradingAccount.accountNumber, request)
      .then(() => {
        this.beneficiariesIraEnhHelper.setToast(toastSuccessMsg, ToastType.success);
        this.state.go('^', { updateAccounts: true });
      })
      .catch(() => {
        this.beneficiariesIraEnhHelper.setToast(toastErrorMsg, ToastType.error);
        this.serviceHelper.errorHandler.bind(this.serviceHelper);
      });
  }

  private getNickname(): string {
    if (
      this.tradingAccount.nickname === 'Self-Directed Trading' &&
      (this.tradingAccount.type === ClearingAccountType.IraTraditional ||
        this.tradingAccount.type === ClearingAccountType.IraRoth)
    ) {
      return 'Self-Directed IRA';
    }

    return this.tradingAccount.nickname;
  }

  private initialData(subtitle: string): void {
    this.subtitle = `${subtitle} **${this.tradingAccount.accountNumber.substring(
      this.tradingAccount.accountNumber.length - 4
    )}`;
    this.isCustomerMarried = this.rootScope['profileInfo']?.maritalStatus === 'Married';
    this.initializeFormBuilder();
    this.removeSpousalFields();
  }
}
