import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FileFormats } from '../models/fileFormats';

@Injectable({
  providedIn: 'root',
})
export class TransactionHistoryService {
  private clickOnDownloadFileSource = new Subject<FileFormats>();

  get clickOnDownload$(): Observable<FileFormats> {
    return this.clickOnDownloadFileSource.asObservable();
  }

  constructor() {}

  emitClickOnDownloadFile(fileInfo: FileFormats) {
    this.clickOnDownloadFileSource.next(fileInfo);
  }
}
