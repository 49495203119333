import { formatCurrency } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID } from '@angular/core';

import { Position } from '@app/axos-trading/models';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-positions-list',
  templateUrl: './positions-list.component.html',
  styleUrls: ['./positions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionsListComponent {
  @Input() positions: Position[];
  @Input() accountId: number;
  constructor(@Inject(LOCALE_ID) public locale: string, private routeHelper: TradingRoutingHelperService) {}

  getDayChangeColor(position: Position): string {
    return position.dayChange === 0 ? 'day-change-equal' : position.dayChange > 0 ? 'day-change-up' : 'day-change-down';
  }

  getArrowPosition(position: Position): string {
    const positionIcon =
      position.dayChange === 0
        ? UtilityIcons.Equal
        : position.dayChange > 0
        ? UtilityIcons.ArrowUp
        : UtilityIcons.ArrowDown;

    return positionIcon;
  }

  getFormattedAmount(amount: number): string {
    const amountFormatted =
      amount > 1000000
        ? formatCurrency(amount, this.locale, '$', 'USA', '1.0-0')
        : formatCurrency(amount, this.locale, '$', 'USA', '1.2-2');

    return amountFormatted;
  }

  goDetails(position: Position) {
    this.routeHelper.goToPositionStockDetail(this.accountId, position.symbol);
  }

  getShares(shareNumber: number): string {
    return shareNumber <= 1 ? 'share' : 'shares';
  }
}
