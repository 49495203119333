import { TotalValueTileState } from './total-value-tile-state.type';

export type TotalValueState = {
  accountsTotalValues: TotalValueTileState[];
  selectedAccountTotalValues: string | undefined;
};

export const initialTotalValueState: TotalValueState = {
  accountsTotalValues: [],
  selectedAccountTotalValues: undefined,
};
