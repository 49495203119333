import { Inject, Injectable } from '@angular/core';
import { ApiModel } from '../Models/ApiModel';
import { AprModel } from '../Models/AprModel';
import { UserAction } from '@legacy/typings/app/UserAction';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { OlbSettings } from '@core/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WebApiResponse } from '@shared/models';
import { PortfolioRateSheetResponse } from '../Models/portfolio-ratesheet.response.model';
import { SblocApplicationResponse } from '../Models/SblocApplicationResponse.model';
import { SBlocApplicationRequest, UtmCodesRequest } from '../Models';

@Injectable({
  providedIn: 'root',
})
export class SblocService extends BaseService {
  private apiValues: ApiModel = {} as ApiModel;
  private apr: number = 0;
  private amount: number = 0;
  private PublicProductPage = new Subject<string>();
  private angularJSServices: any;
  private libertyAccount: string = '';
  private margin: number = 0;
  private utmCodesInfo: UtmCodesRequest;
  processingApplication: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, '');
  }

  getInvestorCheckingScreen(): Observable<string> {
    this.angularJSServices = angular.element(document.body).injector().get('personalizedContentService');
    this.angularJSServices.hasContent.then(() => {
      this.angularJSServices.GetPersonalizedContent('UDB2', '/Products/Borrow/SBLOC').then(data => {
        this.PublicProductPage.next(
          data?.data?.data?.route?.placeholders?.main[0]?.placeholders?.ProductDetailItem[0]?.fields?.PublicProductPage
            ?.Url
        );
      });
    });
    return this.PublicProductPage.asObservable();
  }

  getApiValues() {
    this.apiValues = { Account: '', CIF: '', Max: 100000, Min: 50000 };
    return this.apiValues;
  }

  getApr() {
    return [
      { R1: 50000, R2: 60000, APR: 6.82 },
      { R1: 60000, R2: 70000, APR: 6.51 },
      { R1: 70000, R2: 100000, APR: 5.25 },
    ] as AprModel[];
  }

  sendValuesToAcceptanceCriteria(apr: number, amount: string, account: string, margin: number) {
    this.apr = apr;
    this.amount = Number(amount.replace(/[^0-9\.]+/g, ''));
    this.libertyAccount = account;
    this.margin = margin;
  }
  getSubmittedValues() {
    return { apr: this.apr, amount: this.amount, account: this.libertyAccount, margin: this.margin };
  }

  logUserAction(action: UserAction): Observable<any> {
    return this.post('sbloc/audit', action);
  }

  getRateSheetInfo(): WebApiResponse<PortfolioRateSheetResponse> {
    return this.get(`sbloc/eligibleaccountbycif?guest=false`);
  }

  submitOffer(
    sBlocApplicationRequest: SBlocApplicationRequest,
    utmCodesRequest: UtmCodesRequest
  ): WebApiResponse<SblocApplicationResponse> {
    let params = new HttpParams();
    for (const key in utmCodesRequest) {
      if (utmCodesRequest.hasOwnProperty(key)) {
        params = params.set(key, utmCodesRequest[key] ?? '');
      }
    }
    return this.post(`documents/sblocApplication`, sBlocApplicationRequest, null, params);
  }

  getJointEmail(libertyAccount: string): WebApiResponse<string> {
    return this.get(`sbloc/jointemail?libertyAccount=${libertyAccount}`);
  }

  setUtmCodes(request: UtmCodesRequest): void {
    this.utmCodesInfo = request;
  }

  getUtmCodes(): UtmCodesRequest {
    console.log('returning', this.utmCodesInfo);
    return this.utmCodesInfo;
  }
}
