import { Inject } from '../../decorators/decorators';
/**
 * @deprecated needs to be migrated to angular 2 for usage
 */
@Inject()
export class AlertNotificationController {
  public type: string;

  constructor() {}

  /** Initializes the controller. */
  public $onInit(): void {
    if (!this.type) {
      this.type = 'success';
    }
  }
}
