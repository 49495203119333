import { AccountSyncSummary } from '@app/authentication/models';
import { MultiFactorRequest } from '@core/models';

export class EnrollmentStorage {
  static set signInFlowToken(token: string) {
    localStorage.setItem('SignInFlowToken', token);
  }

  static get signInFlowToken() {
    return localStorage.getItem('SignInFlowToken');
  }

  static set authenticationToken(token: string) {
    localStorage.setItem('AuthenticationToken', token);
  }

  static get authenticationToken() {
    return localStorage.getItem('AuthenticationToken');
  }

  static set authRequest(arg: MultiFactorRequest) {
    localStorage.setItem('authRequest', JSON.stringify(arg));
  }

  static get authRequest() {
    return JSON.parse(localStorage.getItem('authRequest'));
  }

  static set firstName(value: string) {
    localStorage.setItem('firstName', value);
  }

  static get firstName() {
    return localStorage.getItem('firstName');
  }
  static set lastLogin(value: string) {
    localStorage.setItem('lastLogin', value);
  }

  static get lastLogin() {
    return localStorage.getItem('lastLogin');
  }

  static set token(val: string) {
    localStorage.setItem('token', val);
  }

  static get token() {
    return localStorage.getItem('token');
  }
  static set expires(date: Date) {
    sessionStorage.setItem('expires', `${date.getTime()}`);
  }

  static get expires() {
    const time = Number(sessionStorage.getItem('expires'));

    return new Date(time);
  }
  static set userbrand(val: number) {
    sessionStorage.setItem('userbrand', `${val}`);
  }

  static get userbrand() {
    return Number(sessionStorage.getItem('userbrand'));
  }

  static set userFacingBrand(value: number) {
    sessionStorage.setItem('userFacingBrand', `${value}`);
  }

  static get userFacingBrand() {
    return Number(sessionStorage.getItem('userFacingBrand'));
  }

  static set userSubType(value: number) {
    sessionStorage.setItem('userSubType', `${value}`);
  }
  static get userSubType() {
    return Number(sessionStorage.getItem('userSubType'));
  }

  static set accountsToBeFunded(val: AccountSyncSummary['accountsAdded']) {
    sessionStorage.setItem('accountsToBeFunded', JSON.stringify(val));
  }
  static get accountsToBeFunded() {
    return JSON.parse(sessionStorage.getItem('accountsToBeFunded'));
  }

  static set username(val: string) {
    sessionStorage.setItem('username', val);
  }

  static get username() {
    return sessionStorage.getItem('username');
  }

  static set fundRemembered(val: boolean) {
    sessionStorage.setItem('fundRemembered', `${val}`);
  }

  static get fundRemembered() {
    return 'true' === sessionStorage.getItem('fundRemembered');
  }
}
