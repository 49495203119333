<div class="dialog-header" [style.backgroundImage]="backgroundUrl"></div>
<h3 mat-dialog-title class="header-title">
    IRA Contributions
</h3>
<div mat-dialog-content class="mat-dialog-content">
    <p class="text-center">
        IRA contribution limits apply to all of your combined Traditional and Roth accounts. 
        We provide a running total of the combined contributions you make to IRAs held or aggregated here. 
        If you have IRAs at other financial institutions, be sure to check those accounts before contributing.
    </p>
</div>
<div mat-dialog-actions>
    <button id="ira-contribution-close" class="btn-ok" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
  </div>