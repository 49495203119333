import * as angular from 'angular';

import { SharedModule } from '@legacy/shared/shared.module';

import { MessageDetailConfig } from '../messages/message-detail/message-detail.config';
import { MessageDetailComponent } from '@app/messages/components';
import { downgradeComponent } from '@angular/upgrade/static';

export const messageDetailsLegacyModule = angular
  .module('udb.messageDetail', ['ui.router', 'ui.bootstrap', SharedModule])
  .directive('appMessageDetail', downgradeComponent({ component: MessageDetailComponent }))
  .config(MessageDetailConfig).name;
