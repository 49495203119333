<div [formGroup]="parentForm">
  <legend *ngIf="isBusiness" class="font-weight-bold">Mailing Address</legend>
  <div class="form-group" [class.invalid-field]="streetAddr1?.hasError('*', 'touched') && !businessAddressIsEmpty">
    <label for="{{ fieldsPrefix }}address-1" class="uk-label">{{ labels.streetAddress1 }}</label>
    <input
      type="text"
      formControlName="streetAddress1"
      id="{{ fieldsPrefix }}address-1"
      class="uk-input"
      placeholder="Address 1"
      cdkFocusInitial
      [maxlength]="40"
    />

    <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="streetAddress1" #streetAddr1="ngxErrors">
      <ng-container *ngIf="!businessAddressIsEmpty">
        <span ngxError="required" when="touched">Address 1 is required.</span>
        <span ngxError="pattern" when="touched">
          Address 1 can only contain letters, numbers and the following symbols: #/.,
        </span>
        <span ngxError="pobox" when="touched">Address 1 cannot be a P.O. Box.</span>
      </ng-container>
    </div>
  </div>

  <div class="form-group" [class.invalid-field]="streetAddr2?.hasError('*', 'touched') && !businessAddressIsEmpty">
    <label for="{{ fieldsPrefix }}address-2" class="uk-label">{{ labels.streetAddress2 }} (Optional)</label>
    <input
      type="text"
      formControlName="streetAddress2"
      id="{{ fieldsPrefix }}address-2"
      class="uk-input"
      placeholder="Address 2 (Optional)"
      [maxlength]="40"
    />

    <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="streetAddress2" #streetAddr2="ngxErrors">
      <ng-container *ngIf="!businessAddressIsEmpty">
        <span ngxError="pattern" when="touched">
          Address 2 can only contain letters, numbers and the following symbols: #/.,
        </span>
      </ng-container>
    </div>
  </div>

  <div class="form-group row">
    <div
      class="col-12 col-sm-6"
      [class.invalid-field]="postalCode?.hasError('*', 'touched') && !businessAddressIsEmpty"
    >
      <label for="{{ fieldsPrefix }}postal-code" class="uk-label">{{ labels.postalCode }}</label>
      <input
        type="tel"
        formControlName="postalCode"
        id="{{ fieldsPrefix }}postal-code"
        class="uk-input"
        placeholder="Zip Code"
        [maxlength]="postalCodeValidLength"
      />

      <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="postalCode" #postalCode="ngxErrors">
        <ng-container *ngIf="!businessAddressIsEmpty">
          <span ngxError="required" when="touched">Zip Code is required.</span>
          <span ngxError="pattern" when="touched">
            Zip Code is not valid. Please enter a valid 5-digit zip code.
          </span>
        </ng-container>
      </div>
    </div>

    <div class="col-12 col-sm-6" [class.invalid-field]="city?.hasError('*', 'touched') && !businessAddressIsEmpty">
      <label for="{{ fieldsPrefix }}city" class="uk-label">{{ labels.city }}</label>
      <input type="text" formControlName="city" id="{{ fieldsPrefix }}city" class="uk-input" placeholder="City" />
      <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="city" #city="ngxErrors">
        <ng-container *ngIf="!businessAddressIsEmpty">
          <span ngxError="required" when="touched">City is required.</span>
          <span ngxError="pattern" when="touched">City is not valid.</span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="form-group" [class.invalid-field]="state?.hasError('*', 'touched') && !businessAddressIsEmpty">
    <label for="{{ fieldsPrefix }}state" class="uk-label">{{ labels.state }}</label>
    <select formControlName="stateCode" class="uk-input" id="{{ fieldsPrefix }}state">
      <option value="">Select State</option>
      <option *ngFor="let stateCode of statesKeys" [value]="stateCode">
        {{ states[stateCode] }} ({{ stateCode }})
      </option>
    </select>

    <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="stateCode" #state="ngxErrors">
      <ng-container *ngIf="!businessAddressIsEmpty">
        <span ngxError="required" when="touched">State is required.</span>
        <span ngxError="pattern" when="touched">State is not valid.</span>
      </ng-container>
    </div>
  </div>
</div>
