<div class="dispute-form">
  <h3 class="text-center text-normal mb-5">
    Please provide the following information and we will contact you about your disputed transaction(s).
  </h3>

  <form #txDisputeForm="ngForm" name="form" novalidate (ngSubmit)="goNextPage(txDisputeForm.valid)">
    <div class="col-12 p-0">
      <label for="disputeReason" class="uk-label">Reason for disputing transaction(s)</label>
      <textarea
        name="disputeReason"
        #disputeReason="ngModel"
        [(ngModel)]="disputeDataTx.disputeReason"
        required
        placeholder="Provide a detail description of what happened, including any actions you've taken, the police report number and any additional details that would be helpful to us."
        rows="13"
        minlength="20"
        maxlength="1000"
      ></textarea>
    </div>

    <div class="d-flex col-12 mb-3 p-0">
      <span
        id="dispute-reason-error"
        class="text-error mr-auto"
        *ngIf="disputeReason.invalid && (disputeReason.dirty || txDisputeForm.submitted)"
      >
        Enter at least 20 characters
      </span>
      <span class="ml-auto text-inactive">{{ disputeDataTx.disputeReason.length }}/1000</span>
    </div>

    <app-file-upload
      class="col-12"
      [files]="disputeDataTx.documents"
      [totalMaxSize]="totalMaxSize"
      (filesChange)="filesUpdate($event)"
    ></app-file-upload>

    <ng-container *ngIf="cardsDisplay.length">
      <div class="row no-gutters mt-3">
        <h3 class="text-normal">
          Lost your card? Not to worry, we can send you a new card.
        </h3>
        <span>
          Keep in mind that your card/(s) will be immediately and permanently canceled and a new card(s) will be issued.
          Choose the card(s) you want to replace:
        </span>
      </div>

      <div id="cards-container" class="row mt-3 no-gutters">
        <div class="col-lg-6 mb-2" *ngFor="let card of cardsDisplay">
          <input
            class="focus-checkbox"
            type="checkbox"
            [id]="card.debitCardId"
            [(ngModel)]="card.selected"
            [name]="card.debitCardId"
          />
          <label [for]="card.debitCardId">{{ card.prodDesc }}</label>
        </div>
      </div>
    </ng-container>

    <div class="form__actions mt-5">
      <button id="submit-btn" class="btn-ok" type="submit">
        Continue
      </button>
      <button id="back-btn" class="btn-cancel" type="button" (click)="onCancel()">
        Cancel
      </button>
    </div>
  </form>
</div>
