<h1 class="mb-3">Debit Card</h1>

<div class="d-flex flex-wrap card-image-overview" *ngIf="isMobile; else lgDisplay">
  <app-dot-loader *ngIf="!debitCard$.getValue(); else debitCardImage" class="col-6 col-sm-4"></app-dot-loader>

  <span *ngIf="isMobile; then debitCardOverview"></span>

  <span *ngIf="isMobile; then debitCardLimits"></span>

  <span *ngIf="isMobile; then debitCardOptions"></span>
</div>

<div class="row mt-4">
  <app-dot-loader *ngIf="!debitCard$.getValue(); else debitCardActivity" class="col-12"></app-dot-loader>
</div>

<ng-template #lgDisplay>
  <div class="d-flex flex-wrap card-image-overview">
    <div class="col-5 no-gutters">
      <app-dot-loader *ngIf="!debitCard$.getValue(); else debitCardImage" class="col-6 col-sm-4"></app-dot-loader>

      <span *ngIf="!isMobile; then debitCardLimits"></span>
    </div>

    <div class="col-sm-7 no-gutters">
      <span *ngIf="!isMobile; then debitCardOverview"></span>
      <span *ngIf="!isMobile; then debitCardOptions"></span>
    </div>
  </div>
</ng-template>

<ng-template #debitCardImage>
  <app-debit-card-image
    *ngIf="debitCard$ | async as card"
    class="col-5 col-sm-4"
    [ngClass]="cardViewClass"
    [debitCard]="card"
  ></app-debit-card-image>
</ng-template>

<ng-template #debitCardOverview>
  <app-debit-card-overview
    class="col-7 col-sm-8"
    [isUpdating]="!debitCard$.getValue()"
    [debitCard]="debitCard$.getValue()"
    (activateCard)="activateCardPopup()"
    (toggleCard)="toggleCardStatus()"
  ></app-debit-card-overview>
</ng-template>

<ng-template #debitCardOptions>
  <app-debit-card-options
    class="col-12"
    *ngIf="debitCard$.getValue()?.allowManagement"
    [card]="debitCard$ | async"
    [travelLimit]="travelNotificationsLimit"
    [notifications]="travelNotifications$ | async"
    (deleteNotification)="askDeleteTravelNotification($event)"
    (navigate)="goTo.emit($event)"
  ></app-debit-card-options>
</ng-template>

<ng-template #debitCardLimits>
  <mat-accordion *ngIf="debitCard$.getValue()?.isNotInProcess" class="col-12 col-lg-4 mt-3 card-limits">
    <mat-expansion-panel expanded class="mt-3 mat-elevation-z">
      <mat-expansion-panel-header>
        <mat-panel-title class="daily-limits-panel">
          Daily Limits:

          <span class="card-limits__header">
            <!-- displayPOSLimit -->
            {{ debitCard$.getValue()?.displayPOSLimit | currencyExtended: '':'$0':false:'USD':'symbol':'1.0-0' }}
            <!-- displayPOSLimit -->
            |
            <!-- displayWithdrawalLimit -->
            {{ debitCard$.getValue()?.displayWithdrawalLimit | currencyExtended: '':'':false:'USD':'symbol':'1.0-0' }}
            <!-- displayWithdrawalLimit -->
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- accordion content -->
      <div class="limit-group">
        <!-- Purchase Limit< -->
        <div class="d-flex flex-wrap no-gutters align-items-center my-3">
          <div class="col-12 limit-group__title">Purchase Limit</div>
          <div class="col-8 mr-2">
            <progress
              class="progress purchase-limit"
              [value]="debitCard$.getValue()?.posLimit - debitCard$.getValue()?.displayPOSLimit || 0"
              [max]="debitCard$.getValue()?.displayPOSLimit || 0"
            ></progress>
          </div>
          <div class="col-2">
            {{ debitCard$.getValue()?.displayPOSLimit | currencyExtended: '':'$0':false:'USD':'symbol':'1.0-0' }}
          </div>
          <div class="col-12">{{ debitCard$.getValue()?.displayPOSLimit | currencyExtended: 'remaining' }}</div>
        </div>

        <!-- ATM Limit -->
        <div class="d-flex flex-wrap no-gutters align-items-center">
          <div class="col-12 limit-group__title">ATM Limit</div>
          <div class="col-8 mr-2">
            <progress
              class="progress atm-limit"
              [value]="debitCard$.getValue()?.withdrawalLimitUsed || 0"
              [max]="debitCard$.getValue()?.displayWithdrawalLimit || 0"
            ></progress>
          </div>
          <div class="col-2">
            {{ debitCard$.getValue()?.displayWithdrawalLimit | currencyExtended: '':'':false:'USD':'symbol':'1.0-0' }}
          </div>
          <div class="col-12">
            {{ debitCard$.getValue()?.displayWithdrawalLimit | currencyExtended: 'remaining' }}
          </div>
        </div>
      </div>
      <!-- END accordion content -->
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #debitCardActivity>
  <app-debit-card-activity
    class="col-12"
    [activities]="(debitCardHistory$ | async) || []"
    [loading]="!debitCardHistory$.getValue()"
    (delete)="removeHistoryRecord($event)"
  ></app-debit-card-activity>
</ng-template>
