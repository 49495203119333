<div class="tx-modal-filters">
  <div class="tx-modal-backdrop">
    <div class="tx-modal-filter">
      <!-- tx-modal--header -->
      <div class="tx-modal__header">
        <button class="tx-btn" (click)="clearFilterFields()">
          Reset
        </button>
        <span>Filter Transactions</span>
        <button class="tx-btn" (click)="closeModal()">Cancel</button>
      </div>
      <!-- Content -->
      <div class="tx-modal__content">
        <app-tx-aggregation-filters
          *ngIf="!isInitializing && isAggregated"
          [isLoading]="isInitializing"
          [account]="account"
          [filters]="filters"
          [inModal]="true"
          (onModalFiltersChange)="saveFilters($event)"
        ></app-tx-aggregation-filters>
      </div>
      <!-- Footer -->
      <div class="tx-modal__footer">
        <button class="uk-btn solid secondary lg" (click)="closeModal()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
</div>
