declare var ca: any;
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthResponse } from '@app/authentication/models';
import {
  ChallengeResponse,
  DeviceInformation,
  MultiFactorRequest,
  OlbSettings,
  UserInformation,
  VerificationResponse,
} from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class MultifactorService extends BaseService {
  private userInformation: UserInformation = {
    username: '',
    phoneNumber: '',
  };
  private deviceInformation: DeviceInformation = {};
  private caClient: any;

  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'authentication');

    const client = new ca.rm.Client();
    client.setProperty('format', 'json');
    client.setProperty('store', 'localstorage');
    client.setProperty('didname', 'rmClient');
    client.setProperty('noFlash', true);
    this.caClient = client;
  }

  //#region CA Client Methods
  /**
   * Sets the user information for the CA Client.
   * @param username Username.
   * @param phoneNumber Phone number for the logged in user.
   * @param email E-mail for the logged in user.
   * @param emailAvailable A flag checking if e-mail is available.
   */
  setUserInformation(username: string, phoneNumber: string, email: string, emailAvailable?: boolean): void {
    this.userInformation = { username, phoneNumber, email, emailAvailable };
  }

  /**
   * Gets the current user information used by the CA Client.
   * @returns User information.
   */
  getUserInformation(): UserInformation {
    return this.userInformation;
  }

  /**
   * Gets the current device information used by the CA Client.
   * @returns Device information.
   */
  getDeviceInformation(): DeviceInformation {
    return this.deviceInformation;
  }

  /**
   * Sets the device information that will be used by the CA Client.
   * @param deviceInfo Device information for the CA Client.
   */
  updateDeviceInformation(deviceInfo: DeviceInformation): void {
    this.deviceInformation = deviceInfo;
  }

  /**
   * Clears the device information for the CA client.
   */
  clearDeviceInformation(): void {
    this.deviceInformation = {};
  }

  /**
   * Intitializes Device DNA with the CA Client.
   */
  initializeDDNA(): void {
    this.caClient.processDNA();
    this.deviceInformation.deviceSignature = this.caClient.getDNA().replace(/\s/g, '');
    this.deviceInformation.deviceId = this.caClient.getDID();
  }

  /**
   * Sets the device ID for the CA Client.
   * @param did Device ID.
   */
  setDID(did: string): void {
    this.caClient.setDID(did);
  }
  //#endregion

  challenge(request: MultiFactorRequest): WebApiResponse<ChallengeResponse> {
    return this.post('challenge', request);
  }

  requestOtp(request: MultiFactorRequest) {
    return this.post<ChallengeResponse>('otp', request);
  }

  evaluateOtp(request: MultiFactorRequest, isRegistration = false) {
    const payload = { ...request, ...this.deviceInformation };

    return this.post<AuthResponse>(`authenticate/${isRegistration}`, payload);
  }

  validateOtp(request: MultiFactorRequest) {
    return this.post<VerificationResponse>('otp/validate', request);
  }
}
