import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { SharedModule } from '@shared/shared.module';

import { AasStoreFeatures } from '../../aas-core';
import { BalancesService } from '../balances/core/services/balances/balances.service';
import { initialTotalValueState, totalValueReducer } from './core';
import { TotalValueMatModule } from './total-value-mat.module';
import { TotalValueUk2Module } from './total-value-uk2.module';
import { TotalValueGraphComponent, TotalValueTileComponent } from './view';

@NgModule({
  declarations: [TotalValueTileComponent, TotalValueGraphComponent],
  imports: [
    TotalValueMatModule,
    TotalValueUk2Module,
    StoreModule.forFeature(AasStoreFeatures.TotalValue, totalValueReducer, {
      initialState: initialTotalValueState,
    }),
    SharedModule,
    CommonModule,
  ],
  providers: [BalancesService],
  exports: [TotalValueTileComponent],
})
export class TotalValueModule {}
