import { PercentLoaderController } from './percent-loader.controller';

export const PercentLoaderComponent: ng.IComponentOptions = {
  controller: PercentLoaderController,
  templateUrl: 'tiles/invest-milestone/percent-loader/percent-loader.tpl.html',
  controllerAs: 'pl',
  bindings: {
    milestoneType: '<',
    percent: '<',
  },
};
