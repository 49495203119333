import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { AuthResponse } from '@app/authentication/models';
import { ValidUsername } from '@app/enrollment/models';
import { EnrollmentViewStateService } from '@app/enrollment/services';
import { EnrollmentStorage } from '@app/enrollment/utils/enrollment-storage.util';
import { AuthMethod } from '@core/enums';
import { facingBrandMapping } from '@core/enums/facing-brand.enum';
import { ChallengeResponse, MultiFactorRequest } from '@core/models';
import { EnrollmentService, MultifactorService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { ServiceResult } from '@shared/models';

@Component({
  selector: 'app-enrollment-full',
  templateUrl: './enrollment-full.component.html',
})
export class EnrollmentFullComponent {
  @Output() routeRedirect = new EventEmitter<string>();

  authMethod: AuthMethod = null;
  authResponse: AuthResponse = null;
  errorMessage: string = null;
  isLoading = false;
  user: ValidUsername = null;
  termsAndConditions = {
    alertText: '',
  };

  accessCode = { otp: '' };

  constructor(
    public state: EnrollmentViewStateService,
    private enrollmentService: EnrollmentService,
    private multifactorService: MultifactorService,
    private serviceHelper: ServiceHelper
  ) {}

  userDataForm(validUsername: ValidUsername) {
    this.user = validUsername;
    this.state.currentStep = 2;
  }

  agreeTermsAndConditions() {
    this.isLoading = true;
    this.termsAndConditions.alertText = '';

    this.enrollmentService
      .createUser(this.user)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: this.handleCreateUserResponse.bind(this),
        error: this.handleCreateUserResponseError.bind(this),
      });
  }

  multifactorSendCode(authMethod: AuthMethod) {
    const request: MultiFactorRequest = {
      username: this.user.username,
      authenticationMethod: authMethod,
      challengeToken: EnrollmentStorage.signInFlowToken,
    };

    this.isLoading = true;
    this.authMethod = authMethod;
    this.errorMessage = null;

    this.multifactorService
      .requestOtp(request)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: this.handleRequestMultifactorOtp.bind(this, request),
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  accessCodeBack() {
    this.state.currentStep = 2.5;
  }

  accessCodeContinue(event: { code: string; remember: boolean }) {
    this.isLoading = true;

    const request: MultiFactorRequest = {
      username: this.user.username,
      otp: event.code,
      rememberDevice: event.remember,
      authenticationMethod: this.authMethod,
      authenticationToken: EnrollmentStorage.authenticationToken,
    };

    if (event.remember) {
      const { deviceId } = this.multifactorService.getDeviceInformation();
      this.multifactorService.setDID(deviceId);
    }

    this.multifactorService
      .evaluateOtp(request, true)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: this.handleEvaluateOtp.bind(this),
        error: this.handleEvaluateOtpError.bind(this),
      });
  }

  securityQuestionsSave() {
    const userFacingBrand = facingBrandMapping.find(({ brands }) => brands.includes(this.authResponse.brandId));
    EnrollmentStorage.expires = new Date(Date.now() + this.authResponse.tokenExpires * 1e3);
    EnrollmentStorage.userbrand = this.authResponse.brandId;
    EnrollmentStorage.userSubType = this.authResponse.userSubType ?? 0;
    EnrollmentStorage.userFacingBrand = userFacingBrand.facingBrand;
    this.routeRedirect.emit('udb.dashboard');
  }

  private handleCreateUserResponse(res: ServiceResult<any>) {
    EnrollmentStorage.signInFlowToken = res.data.challengeToken;
    this.state.currentStep = 2.5;
  }

  private handleCreateUserResponseError(res: HttpErrorResponse) {
    this.termsAndConditions.alertText = res.error.message;
  }

  private handleRequestMultifactorOtp(request: MultiFactorRequest, res: ServiceResult<ChallengeResponse>) {
    sessionStorage.setItem('authRequest', JSON.stringify(request));
    EnrollmentStorage.authenticationToken = res.data.authenticationToken;
    EnrollmentStorage.authRequest = request;

    this.accessCode.otp = res.data.otp;
    this.state.currentStep = 3;
  }

  private handleEvaluateOtp(res: ServiceResult<AuthResponse>) {
    const userFacingBrand = facingBrandMapping.find(({ brands }) => brands.includes(res.data.brandId));

    this.authResponse = res.data;

    EnrollmentStorage.firstName = res.data.firstName;
    EnrollmentStorage.lastLogin = res.data.lastLogin;
    EnrollmentStorage.token = res.data.token;
    EnrollmentStorage.expires = new Date(Date.now() + res.data.tokenExpires * 1e3);
    EnrollmentStorage.userbrand = res.data.brandId;
    EnrollmentStorage.userFacingBrand = userFacingBrand.facingBrand;

    this.state.currentStep = 4;
  }

  private handleEvaluateOtpError(res: HttpErrorResponse) {
    if (res.status >= 500) {
      this.serviceHelper.errorHandler(res.error, true);
    } else {
      this.errorMessage =
        res.error.message ||
        'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
    }
  }
}
