<div class="d-flex flex-column">
  <p class="mb-3 text-center">
    When your spouse is not selected as your sole primary beneficiary, we need to email them to
    obtain their consent.
  </p>

  <p class="text-center">
    If you are not, or no longer married, update your
  </p>

  <p class="mb-5 text-center">
    <a id="account-link" class="link font-weight-bold" href="#" (click)="goToUserProfile()">
      Account Profile.
    </a>
  </p>
</div>
