<div class="pay-review">
  <!-- HEADER -->
  <div class="header-section-title text-center">
    <div>
      <h1 class="header">{{ severalPayments ? ' Schedule Payments' : 'Schedule A Payment' }}</h1>
    </div>
  </div>
  <div class="frow center">
    <h3 class="text-center instructions">
      {{ severalPayments ? 'Please verify your payments below.' : 'Please verify your payment below.' }}
    </h3>
    <table class="table--primary">
      <caption></caption>
      <thead>
        <tr>
          <th *ngFor="let header of headers">
            {{ header.label }}
            <app-tooltip class="details-help" *ngIf="header.value === 2" [content]="detailsTooltip"></app-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of payments">
          <td>
            <div class="main-data">
              <h4>{{ payment?.recipient?.nickName || payment?.recipient?.displayName }}</h4>
            </div>
          </td>
          <td>
            <div class="main-data">
              <span>{{ payment.amount | currency }}</span>
            </div>
          </td>
          <td>
            <div class="main-data">
              <p>{{ formatDate(payment.selectedDate) }}</p>
              <span>
                {{ payment.recipient.paymentMethod == 1 ? electronicRecipientLabel : checkRecipientLabel }}
                {{ formatDate(payment.processingDate) }}
              </span>
            </div>
          </td>
          <td>
            <div class="main-data">
              <span>{{ payment.recipient.paymentMethod === 0 ? 'Check' : 'Electronic' }}</span>
            </div>
          </td>
        </tr>
        <tr class="table-footer">
          <td class="total-description">
            Total:
          </td>
          <td colspan="2">
            <span class="amount-summary">{{ totalAmount | currency }}</span>
            <span class="account-description">
              from {{ accountFrom?.nickName }} (Avail. Bal: {{ accountFrom?.balance | currency }})
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="section">
    <div *ngIf="!isCancelling && !isScheduling" class="frow center">
      <div class="text-center fcol-xs-10 fcol-md-6 fcol-lg-6">
        <button
          type="button"
          class="uk-btn outline primary lg btn-footer"
          (click)="redirectToPay()"
          [disabled]="isBusy"
        >
          <span>Back</span>
        </button>
        <button
          type="button"
          class="uk-btn solid secondary lg btn-footer schedule"
          (click)="submitPayments()"
          [disabled]="isBusy"
        >
          <span>{{ severalPayments ? 'Schedule Payments' : 'Schedule Payment' }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="isBusy">
    <app-dot-loader [showWhen]="isBusy">Scheduling payments</app-dot-loader>
  </div>
</div>
