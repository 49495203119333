import { EBillsStatus } from './EBillStatus';
import { PaymentMethod } from './PaymentMethod';
import { RecipientTypeStatus } from './RecipientTypeStatus';
import { EBillAutoPay } from './EBillAutoPay';
import { Address } from './Address';

export class Recipient {
  displayName?: string;
  billerId?: number;
  billerIdSpecified?: boolean;
  businessDaysToDeliver?: number;
  canExpeditePayments?: boolean;
  emailAddress?: string;
  nickName?: string;
  shortList?: boolean;
  shortListSpecified?: boolean;
  paymentAccountMask?: string;
  paymentAccount?: string;
  userTags?: string[];
  status?: RecipientTypeStatus;
  lastReturnDate?: Date;
  lastReturnDateSpecified?: boolean;
  nextAvailablePaymentProcessingDate?: Date;
  nextAvailablePaymentDeliveryDate?: Date;
  billingAddress?: Address;
  phone?: Phone;
  eBill?: EBill;
  eBillDeliveryNotification?: boolean;
  eBillDeliveryNotificationSpecified?: boolean;
  eBillAutoPay?: EBillAutoPay;
  eBillsStatus?: EBillsStatus;
  eBillsStatusSpecified?: boolean;
  payeeId?: number;
  paymentMethod?: PaymentMethod;
}
