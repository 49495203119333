<td colspan="5">
  <div class="row pt-4">
    <div class="col-12 col-sm-3 col-md-3 col-lg-2 text-left text-sm-right">
      <div class="col-12 trade-header">
        Category
      </div>
      <div class="col-12 mt-2">
        Dividend
      </div>
    </div>
    <div class="col-12 col-sm-8 col-lg-7 mt-3 mt-sm-0">
      <div class="row">
        <div class="col-12">
          <div class="col-12 trade-header">Description</div>
        </div>
      </div>
      <div class="row mt-2">
        <div
          class="col-12 col-sm-6 mb-4 trade-transaction"
          *ngFor="let tradeTransaction of transaction.data.tradeTransactions"
        >
          <div class="col-12 mb-2 trade-transaction-goal-name">
            <strong>Goal Name:</strong>
            {{ transaction.data.milestoneName | titlecase }}
          </div>
          <div class="col-12 mb-2 trade-transaction-asset-name">
            <strong>Asset:</strong>
            {{ tradeTransaction.name }} ({{ tradeTransaction.assetCode }})
          </div>
          <div class="col-12 mb-2 trade-transaction-asset-type">
            <strong>Asset Type:</strong>
            {{ tradeTransaction.assetType }}
          </div>
          <div class="col-12 mb-2 trade-transaction-value">
            <strong>Value:</strong>
            {{ tradeTransaction.value | currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</td>
