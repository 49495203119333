import { CancelScheduledTransferModalTypes } from '@app/transfers/enums';
import { AlertsIcons } from '@shared/enums';

export const SCHEDULED_TRANSFER_MODAL_LABELS = {
  icon: {
    [CancelScheduledTransferModalTypes.SeriesCancelConfirm]: AlertsIcons.ExclamationCircle,
    [CancelScheduledTransferModalTypes.SeriesCancelSuccess]: AlertsIcons.CheckCircle,
    [CancelScheduledTransferModalTypes.TransferCancelError]: AlertsIcons.ExclamationCircle,
  },
  header: {
    [CancelScheduledTransferModalTypes.SeriesCancelConfirm]: 'Cancel Transfer Series',
    [CancelScheduledTransferModalTypes.SeriesCancelSuccess]: 'Transfer Series Cancellation Confirmed',
    [CancelScheduledTransferModalTypes.TransferCancelError]: 'Advisor Assistance Needed',
  },
  content: {
    [CancelScheduledTransferModalTypes.SeriesCancelConfirm]: [
      'You can cancel this transfer series, but please note that the upcoming transfer within 3 business days cannot be canceled and will be processed as scheduled. Would you like to proceed with canceling the remaining transfers in the series?',
    ],
    [CancelScheduledTransferModalTypes.SeriesCancelSuccess]: [
      'Your transfer series has been successfully canceled, except for the upcoming transfer within 3 business days, which will proceed as scheduled. You will not be charged for any future transfers in this series.',
    ],
    [CancelScheduledTransferModalTypes.TransferCancelError]: [
      'We’re currently unable to cancel your upcoming scheduled transaction directly through the app. To proceed with the cancellation, please contact your advisor.',
      'They’re equipped to assist you with this request and ensure that your transaction series is canceled promptly and accurately.',
    ],
  },
};
