import { BeneficiaryType } from '@app/shared/enums/beneficiary-type';

export class BeneficiaryCommon {
  // Use a common class for this component
  //   base and taxPlan beneficiaries will be converted to this type
  key = '';
  level: BeneficiaryType = null;
  name = '';
  percent: number = null;
  identity: string = null;
  relationship = '';

  constructor(args?: Partial<BeneficiaryCommon>) {
    Object.assign(this, args);
  }
}
