import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

@Component({
  selector: 'how-to-mobile-deposit',
  templateUrl: './mobile-deposit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MobileDepositComponent {
  constructor(@Inject(olbSettings) public settings: OlbSettings) {}
}
