import { TileInformation } from '@uikit/tiles';
import * as angular from 'angular';
import { IRootElementService, IRootScopeService, IScope, ITimeoutService } from 'angular';
import { TilesService } from 'services/tiles.service';

import { Inject } from '../../decorators/decorators';
import { ColorPaletteHelper } from '../../services/color-palette.helper';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';
@Inject('$scope', '$element', 'serviceHelper', 'tilesService', '$rootScope', '$timeout', 'popups', 'env')
export class AccountDistributionController extends BaseTile<AccountDistributionTileSettings> {
  title = 'Account Distribution';
  isBusy = true;
  accounts: ColoredAccount[] = [];
  settings: number[] = [];
  colors: string[];
  totalAvailableBalance: number;
  totalAbsBalance: number;
  selectedAccount: OlbAccount;
  showPlaceholder = false;
  placeholderImg = 'assets/distribution-placeholder.svg';

  private clicked: boolean;
  private chartAccounts: ColoredAccount[] = [];
  private chart: Chart;
  private chartCanvas: CanvasRenderingContext2D;
  private listeners: Function[] = [];

  constructor(
    scope: IScope,
    elm: IRootElementService,
    serviceHelper: IServiceHelper,
    tilesService: TilesService,
    private readonly rootScope: IRootScopeService,
    private readonly timeout: ITimeoutService,
    private readonly popups: IPopups,
    private readonly env: OlbSettings
  ) {
    super(scope, elm, tilesService, serviceHelper);
    Chart.defaults.global.maintainAspectRatio = false;
    this.colors = ColorPaletteHelper.getColorPaletteByBrand(this.env.brand);
  }

  /** This tile is initialized here since it needs transclusion to be finished, first. */
  $postLink(): void {
    const elm = this.elm.find('.account-distribution-chart')[0] as HTMLCanvasElement;
    if (elm) {
      this.chartCanvas = elm.getContext('2d');
    }

    if (this.rootScope['accounts'] && this.rootScope['balancesAvailable']) {
      this.timeout(() => {
        this.getSettings(this.setupTile.bind(this));
      });
    }

    this.listeners.push(
      this.scope.$on('balancesAvailable', () => {
        this.getSettings(this.setupTile.bind(this));
      })
    );

    // Refresh the tile after a quick transfer
    this.listeners.push(
      this.rootScope.$on('transferCompleted', () => {
        this.isBusy = true;
        this.setupTile();
      })
    );
  }

  /** Cleans up the controller. */
  $onDestroy(): void {
    this.chart && this.chart.destroy();
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /**
   * Toggles account's selection in settings section.
   * @param id ID of the account to toggle.
   */
  toggleSelection(id: number): void {
    if (this.settings.some(s => s === +id)) {
      this.settings = this.settings.filter(s => s !== +id);
    } else {
      this.settings.push(id);
    }
  }

  /** Saves the settings of the tile. */
  saveTileSettings(): void {
    const accounts: number[] = [];
    let colors: string[] = [];
    this.tileSettings.Accounts.value = '';
    this.tileSettings.Colors.value = '';

    if (this.settings.length < 2) {
      this.popups.showAlert(
        'Account Distribution Tile',
        'At least 2 accounts should be selected for the chart.',
        'error',
        this.resetSettings.bind(this)
      );

      return;
    }

    this.settings.forEach((id, index) => {
      accounts[index] = id;
    });

    // Gets only the accounts and colors that should be saved
    const accountsInSettings = angular.copy(this.accounts).filter(a => {
      return accounts.indexOf(a.id) > -1;
    });
    colors = angular.copy(accountsInSettings.map(a => a.color));

    // To preserve the order that belongs to every account, we take the accountsInSettings array
    this.tileSettings.Accounts.value = accountsInSettings.map(a => a.id).join();
    this.tileSettings.Colors.value = colors.join();
    this.saveSettings();
    this.attachColors(this.tileSettings.Colors.value);
    this.updateChart(colors);
    this.totalAvailableBalance = this.chartAccounts.reduce((result, a) => result + a.availableBalance, 0);
    this.totalAbsBalance = this.chartAccounts.reduce((result, a) => result + Math.abs(a.availableBalance), 0);
    this.selectedAccount = null;
  }

  /** Resets the tile settings to its normal/previous state. */
  resetSettings(): void {
    if (this.tileSettings.Accounts.value) {
      this.settings = angular
        .copy(this.tileSettings.Accounts.value)
        .split(',')
        .map(s => +s);
    } else {
      this.settings = this.accounts.map(a => a.id);
    }
    this.attachColors(this.tileSettings.Colors.value);
    if (this.chart) this.updateChart();
    this.selectedAccount = null;
  }

  /**
   * Picks the selected color to attach it to the account it belongs.
   * @param color Color in HEX string.
   */
  pickColor(color: string, accountId: number): void {
    const account = this.accounts.filter(a => a.id === accountId)[0];
    account.color = color;
    this.enableScroll();
  }

  /**
   * Closes the other opened colorpickers.
   */
  closeOtherPickers(): void {
    this.scope.$broadcast('$closeOtherPickers');
  }

  /** Sets the tile up with its settings. */
  private setupTile(): void {
    this.accounts = angular.copy(this.rootScope['accounts'].depositAccounts);
    this.validateExistingAccounts();
    this.resetSettings();
    this.chartAccounts = this.accounts.filter(a => this.settings.some(s => a.id === s));
    this.listeners.push(
      this.scope.$on('$adTileStopScroll', e => {
        e.stopPropagation();
        this.elm.find('tile-settings').css('overflow-y', 'hidden');
      })
    );
    this.listeners.push(this.scope.$on('$adTileEnableScroll', this.enableScroll.bind(this)));
    this.totalAvailableBalance = this.chartAccounts.reduce((total, account) => total + account.availableBalance, 0);
    this.totalAbsBalance = this.chartAccounts.reduce((total, account) => total + Math.abs(account.availableBalance), 0);

    this.isBusy = false;
    this.isInitialising = false;

    if (!this.totalAvailableBalance) {
      this.showPlaceholder = true;
    } else {
      this.showPlaceholder = false;
      this.setupChart();
    }
  }

  /**
   * Re-enables the scroll for the settings.
   * @param e Angular event passed from the scope.
   */
  private enableScroll(e?: ng.IAngularEvent): void {
    if (e) e.stopPropagation();
    this.elm.find('tile-settings').css('overflow-y', 'auto');
  }

  /**
   * Attaches a color to the account.
   * @param colors Colors to attach to the accounts.
   */
  private attachColors(colorsString?: string): void {
    if (colorsString) {
      const colors = colorsString.split(',');
      const unassignedColors = angular.copy(this.colors).filter(c => {
        return colors.indexOf(c) < 0;
      });

      this.accounts.forEach((a, index) => {
        if (this.tileSettings.Accounts.value) {
          const accountsIds: string[] = this.tileSettings.Accounts.value.split(',');
          const idx = accountsIds.indexOf('' + a.id);
          if (idx > -1) {
            a.color = colors[idx];
          } else {
            a.color = unassignedColors[index];
          }
        } else {
          a.color = colors[index];
        }
      });
    } else {
      let i = 0;
      this.accounts.forEach(a => {
        if (i === 12) i = 0;
        a.color = this.colors[i];
        i++;
      });
    }
  }

  /** Validates if the accounts in the settings remains the same as in the last update otherwise they're removed from the settings*/
  private validateExistingAccounts(): void {
    if (!this.accounts || this.accounts.length <= 0) {
      const tileInfo: TileInformation = {
        tileId: this.tileId,
        instanceId: this.instanceId,
        template: '',
      };
      this.scope.$emit('$uikitTileRemove', tileInfo);
    }

    if (!this.tileSettings.Accounts.value) return;

    const accountsInSettings = angular.copy(this.tileSettings.Accounts.value).split(',');
    const colorsInSettings = angular.copy(this.tileSettings.Colors.value).split(',');
    const newAccounts: number[] = [];
    let newColors: string[] = [];

    if (this.accounts.length < accountsInSettings.length) {
      this.accounts.forEach(a => {
        const idx = accountsInSettings.indexOf('' + a.id);
        if (idx >= 0) {
          newColors.push(colorsInSettings[idx]);
        }
        newAccounts.push(a.id);
      });

      if (newAccounts.length > newColors.length) {
        newColors = this.colorValidation(newColors, colorsInSettings, newAccounts.length);
      }
      this.tileSettings.Accounts.value = newAccounts.join();
      this.tileSettings.Colors.value = newColors.join();
      this.saveSettings();
    }
  }

  private colorValidation(newColors: string[], colorsInSettings: string[], accLength: number): string[] {
    const missedColors = colorsInSettings.filter(color => !newColors.includes(color));
    if (missedColors) {
      newColors.push(missedColors[0]);
      if (accLength > newColors.length) {
        return this.colorValidation(newColors, colorsInSettings, accLength);
      }
    }
    return newColors;
  }

  /** Sets up the chart after loading all the needed component data. */
  private setupChart(): void {
    if (!this.chart) {
      const elm = this.elm.find('.account-distribution-chart')[0] as HTMLCanvasElement;
      this.chartCanvas = elm.getContext('2d');
    }

    this.chart = new Chart(this.chartCanvas, {
      type: 'doughnut',
      data: this.getChartData(),
      options: {
        elements: {
          arc: { borderWidth: 2 },
        },
        animation: false,
        onClick: (e: any) => {
          const chartElm = this.chart.getElementAtEvent(e)[0];
          this.selectAccount(chartElm, true);
        },
        hover: {
          onHover: (e: any) => {
            const chartElm = this.chart.getElementAtEvent(e)[0];
            this.selectAccount(chartElm);
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: '#FFF',
          bodyFontSize: 18,
          bodyFontColor: '#000',
          bodySpacing: 5,
          displayColors: false,
          borderWidth: 1,
          borderColor: '#000',
          callbacks: {
            label: (tooltip: TooltipItem, data: any) => {
              const i = tooltip.index;
              const dataset = data.datasets[0];
              const label = Math.round((Math.abs(dataset.data[i]) / this.totalAbsBalance) * 100);

              return `${label}%`;
            },
          },
        },
        cutoutPercentage: 68,
      },
    });
  }

  /**
   * Selects an account to display in the center of the chart.
   * @param clicked Specifies if the chart piece has been clicked.
   * @param chartElm Chart element that will be used to retrieve account information.
   */
  private selectAccount(chartElm: ChartElement, clicked?: boolean): void {
    if (!chartElm && this.clicked && clicked) {
      this.clicked = false;
      this.resetChart();

      return;
    } else if (!chartElm && clicked) {
      this.resetChart();

      return;
    } else if (!chartElm) {
      this.resetChart();

      return;
    }
    const id = this.chart.chart.config.data.datasets[0].ids[chartElm._index];
    if (this.clicked && id && !clicked) return;

    this.clicked = clicked;

    if (this.selectedAccount && id === this.selectedAccount.id) return;

    const account = this.accounts.filter(a => a.id === id)[0];

    this.selectedAccount = account;
    this.scope.$digest();

    const rgbaColors = this.chartAccounts.map(a => {
      if (a.id === id) return a.color;
      const rgbColor = this.hexToRgb(a.color);
      const rgbaColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`;

      return rgbaColor;
    });

    this.updateChart(rgbaColors);
  }

  /** Resets the chart to its original state. */
  private resetChart(): void {
    const colors = this.chartAccounts.map(a => a.color);
    this.updateChart(colors);
    this.selectedAccount = null;
    this.scope.$digest();
  }

  /**
   * Sets the chart data object up.
   * @param colours An array of colors that will be used as background colors.
   * @returns Chart data object with all needed values.
   */
  private getChartData(colours?: string[]): ChartData<any> {
    let data: ChartData<any> = {};
    this.chartAccounts = this.accounts.filter(a => this.settings.some(s => a.id === s));
    colours = colours || this.chartAccounts.map(a => a.color);
    const accountNames = this.chartAccounts.map(a => a.nickname || a.name);

    data = {
      labels: accountNames,
      datasets: [
        {
          data: this.chartAccounts.map(a => Math.abs(a.amount)),
          names: accountNames,
          backgroundColor: colours,
          ids: this.chartAccounts.map(a => a.id),
        },
      ],
    };

    return data;
  }

  /**
   * Updates the chart with new data.
   * @param colors An array of colors that will be used as background colors.
   */
  private updateChart(colors?: string[]): void {
    this.chart.chart.config.data = this.getChartData(colors);
    this.chart.update();
  }

  /**
   * Converts a color in hex string to rgb.
   * @param hex Color in hex string format.
   * @returns Color in rgb format.
   */
  private hexToRgb(hex: string): RgbColor {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (_m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
}

interface AccountDistributionTileSettings extends BaseTileSettings {
  Accounts?: TileSetting;
  Colors?: TileSetting;
}

interface ColoredAccount extends OlbAccount {
  color: string;
}

type RgbColor = { r: number; g: number; b: number };
