import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumeric]',
})
export class NumericDirective {
  private regExpString = /^\d+$/;
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '.' || event.code === 'Period') {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const data = event.clipboardData.getData('text');
    if (data && !new RegExp(this.regExpString).test(data)) {
      event.preventDefault();
    }
  }
}
