import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'up-section-heading',
  templateUrl: './section-heading.component.html',
  styleUrls: ['./section-heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionHeadingComponent implements OnChanges {
  @HostBinding('class.expanded')
  @Input()
  expanded = false;
  @Input() section = '';
  @Input() mobile: boolean;
  @Input() subsection = '';
  @Input() canReturn: boolean;
  @Input() titleEllipsis?: boolean;

  @Output() return = new EventEmitter<null>();
  @Output() expand = new EventEmitter<boolean>();

  @HostBinding('class.bottom-line')
  get hasBorderBottom(): boolean {
    return !!this.subsection || this.canReturn;
  }

  get inSubSection() {
    return this.subsection || this.canReturn;
  }

  title = '';
  icon = {
    size: '0.6em',
    glyph: NavigationIcons.ChevronLeft,
  };

  openIcon = {
    icon: NavigationIcons.ChevronDown,
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.title = changes.subsection?.currentValue ? `${this.section}:` : this.section;
  }

  toggleExpand(): void {
    if (this.inSubSection) return this.return.emit();

    if (this.mobile) this.expanded = !this.expanded;
    this.expand.emit(this.expanded);
  }
}
