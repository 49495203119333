import { P2PRecipient } from './P2PRecipient';

export class P2PPayment {
  recipient?: P2PRecipient;
  amount?: number;
  accountCode?: string;
  method?: P2PMethod;
  message?: string;
  question?: string;
  answer?: string;
  accountId?: number;
}

export enum P2PMethod {
  Email,
  MobilePhone,
}
