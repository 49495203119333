import { AuthResponse } from '@app/authentication/models';
import { SaveSecurityQuestionsResponse, SecurityQuestion } from '@shared/models';

import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IEnrollmentService } from './typings/IEnrollmentService';

@Inject('$http', 'env', 'serviceHelper', '$q')
class EnrollmentService extends BaseService implements IEnrollmentService {
  constructor(http: ng.IHttpService, _env: OlbSettings, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, _env, 'enrollment', _serviceHelper, q);
  }

  getSecurityQuestions(): ApiResponse<string[]> {
    return this.get(`questions`);
  }

  saveSecurityQuestions(
    questions: SecurityQuestion[],
    username?: string,
    token?: string
  ): ApiResponse<boolean> {
    let service = `${username}/questions`;

    return this.post(service, questions, { Authorization: `Bearer ${token}` });
  }

  saveSecurityQuestionsForgotPassword(
    questions: SecurityQuestion[],
    username?: string,
    resetPasswordToken?: string,
    token?: string
  ): ApiResponse<SaveSecurityQuestionsResponse> {
    let service = `${username}/questions/${resetPasswordToken}`;

    return this.post(service, questions, { Authorization: `Bearer ${token}` });
  }

  hasUserAcceptedTermsAndConditions(): ApiResponse<boolean> {
    return this.get(`termsandconditions/accepted`);
  }

  ssoAutoRegister(userId: number, syncAccounts: boolean, isFromEnrollment: boolean): ApiResponse<AuthResponse> {
    return this.post(`ssoautoregister`, { syncAccounts, userId, isFromEnrollment });
  }

  getCityAndState(zipCode: string): ApiResponse<CityAndStateInfo> {
    return this.get(`zip/${zipCode}`);
  }
}

export { EnrollmentService };
