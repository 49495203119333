import { IReceiptAlertBanner } from './ReceiptAlertBanner';
import { ReceiptTemplate } from './ReceiptTemplate';

export const MoneyTransfer_In_OneTime: ReceiptTemplate = {
  type: 'MoneyTransfer_In_OneTime',
  header: 'You transferred {amount} to {toAccount}',
  transactions: [
    {
      details: [
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Amount' },
        { description: 'Frequency' },
      ],
    },
  ],
  navigationBack: 'Account Dashboard',
  showBanner: true,
};

export const MoneyTransfer_Ex_OneTime: ReceiptTemplate = {
  type: 'MoneyTransfer_Ex_OneTime',
  header: 'You scheduled a {amount} transfer to {toAccount}',
  transactions: [
    {
      details: [
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Amount' },
        { description: 'Send On' },
        { description: 'Frequency' },
      ],
    },
  ],
  navigation: 'View Scheduled Transfers',
  navigationBack: 'Account Dashboard',
  showBanner: true,
};

export const MoneyTransfer_Trading_Ira: ReceiptTemplate = {
  type: 'MoneyTransfer_Trading_Ira',
  header: '',
  transactions: [
    {
      details: [
        { description: 'Amount' },
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Contribution Year' },
        { description: 'Submit Request On' },
        { description: 'Confirmation Number' },
      ],
    },
  ],
  navigation: 'View Accounts',
  navigationBack: 'Move More Money',
};

export const MoneyTransfer_RIA_Contribution: ReceiptTemplate = {
  type: 'MoneyTransfer_RIA_Contribution',
  header: '',
  transactions: [
    {
      details: [
        { description: 'Amount' },
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Frequency' },
        { description: 'Submit Request On' },
        { description: 'Confirmation Number' },
      ],
    },
  ],
  navigation: 'View Accounts',
  navigationBack: 'Move More Money',
};

export const MoneyTransfer_From_Trading_Ira: ReceiptTemplate = {
  type: 'MoneyTransfer_From_Trading_Ira',
  header: 'You scheduled a {amount} transfer to {toAccount}',
  transactions: [
    {
      details: [
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Amount' },
        { description: 'Federal Amount to withhold' },
        { description: 'State Amount to withhold' },
        { description: 'Net Amount' },
        { description: 'Frequency' },
        { description: 'Send Request On' },
        { description: 'Confirmation Number' },
      ],
    },
  ],
  navigation: 'View Accounts',
  navigationBack: 'Move More Money',
};

export const MoneyTransfer_Recurring: ReceiptTemplate = {
  type: 'MoneyTransfer_Recurring',
  header: 'You scheduled a transfer to {toAccount}',
  transactions: [
    {
      details: [
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Amount' },
        { description: 'Sending On' },
        { description: 'Last Transfer Date' },
        { description: 'Frequency' },
        { description: 'Send Until' },
      ],
    },
  ],
  navigation: 'View Scheduled Transfers',
  navigationBack: 'Account Dashboard',
  showBanner: true,
};

export const MoneyTransfer_AxosInvest_In_OneTime: ReceiptTemplate = {
  type: 'MoneyTransfer_AxosInvest_In_OneTime',
  header: 'You transferred {amount} to {toAccount}',
  transactions: [
    {
      details: [
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Retirement Account Type' },
        { description: 'Contribution Year' },
        { description: 'Amount' },
        { description: 'Frequency' },
      ],
    },
  ],
  navigationBack: 'Account Dashboard',
  showBanner: true,
};

export const MoneyTransfer_AxosInvest_Recurring: ReceiptTemplate = {
  type: 'MoneyTransfer_AxosInvest_Recurring',
  header: 'You scheduled a transfer to {toAccount}',
  transactions: [
    {
      details: [
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Retirement Account Type' },
        { description: 'Contribution Year' },
        { description: 'Amount' },
        { description: 'Sending On' },
        { description: 'Last Transfer Date' },
        { description: 'Frequency' },
        { description: 'Send Until' },
      ],
    },
  ],
  navigation: 'View Scheduled Transfers',
  navigationBack: 'Account Dashboard',
  showBanner: true,
};

export const RemoteDeposit: ReceiptTemplate = {
  header: 'You initiated a deposit of {amount} to {toAccount}',
  transactions: [
    {
      details: [{ description: 'To Account' }, { description: 'Amount' }, { description: 'Deposit Date' }],
    },
  ],
  footnote: 'Depending on risk factors, remote deposits can take as long as 5 business days to become available.',
  navigation: 'Make Another Deposit',
  showBanner: true,
};

export const P2PTransferTemplate: ReceiptTemplate = {
  type: 'P2P',
  header: `You initiated a {amount} payment to {recipientName}`,
  footnote: `The funds transfer will not be initiated until the recipient answers the security question successfully and accepts the payment. Then, if it is before 1:45 pm PST it will be processed on the same business day. If it is after 1:45 pm PST, it will process on the next business day.
    <br/><br/>
    You can delete the payment until the recipient accepts it on the <a href="/Transfers/payitnow/history">Pay It Now History</a> page.`,
  transactions: [
    {
      details: [
        { description: 'From Account', value: '' },
        { description: 'Recipient Name', value: '' },
        { description: 'Recipient Email or Phone', value: '' },
        { description: 'Amount', value: '' },
        { description: 'Security Question', value: '' },
        { description: 'Security Answer', value: '' },
        { description: 'Message', value: '' },
      ],
    },
  ],
  navigation: 'Make Another Payment',
  showBanner: true,
};

export const AlertBanner: IReceiptAlertBanner = {
  header: 'Stay alert with text notifications',
  details: ['Want instant updates on recent transactions? Visit ', ' and view our available list of text commands.'],
  icon: 'sms-icon.svg',
  navigation: 'udb.alertsNotifications.myAlerts',
  linkText: 'Alerts & Notifications',
  hide: true,
};

export const MakeAPayment: ReceiptTemplate = {
  type: 'MakeAPayment',
  header: 'You {frequencyMsg} {toAccount}',
  transactions: [
    {
      details: [
        { description: 'From Account' },
        { description: 'To Account' },
        { description: 'Amount' },
        { description: 'Send On' },
        { description: 'End On' },
        { description: 'Frequency' },
        { description: 'Memo' },
      ],
    },
  ],
  footnote: "To edit or cancel this payment, go to <a href='/MyBills/scheduled-payments'>Scheduled Payments</a> page.",
  navigation: 'Make Another Payment',
  showBanner: true,
};
