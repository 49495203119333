import { downgradeComponent } from '@angular/upgrade/static';
import { AuthorizeTransferPageComponent } from '@app/Areas/AAS/features/authorize-transfer-page/view/authorize-transfer-page/authorize-transfer-page.component';

export const AuthorizeTransferPageLegacyModule = angular
  .module('udb.authorizeTransferPage', ['ui.router'])
  .directive('appAuthorizeTransferPage', downgradeComponent({ component: AuthorizeTransferPageComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: ng.ui.IStateProvider) => {
      $stateProvider.state('udb.authorizeTransferPage', {
        url: '/Authorize-transfers',
        views: {
          layout: {
            template: '<app-authorize-transfer-page></app-authorize-transfer-page>',
          },
        },
        params: {
          data: null,
        },
      });
    },
  ]).name;
