import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { investorCheckingComponents } from './components';

@NgModule({
  declarations: [...investorCheckingComponents],
  imports: [SharedModule],
  providers: [],
  exports: [...investorCheckingComponents],
})
export class InvestorCheckingModule {}
