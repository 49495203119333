<div class="stop-payment-success fcol middle">
  <div class="fcol center">
    <div class="fcol-cs-12 success-container text-center">
      <div class="check-icon">
        <i class="bofi-success" aria-hidden="true"></i>
      </div>

      <div class="success-content">
        <h2>Success! Your stop payment has been successfully {{ actionMsg }}</h2>
      </div>

      <div class="success-item between frow" *ngIf="stopPayment">
        <div class="fcol-xs-6">
          <b>Check Number:</b>
          <span *ngIf="stopPayment.stopType === 'Check'">
            <span>{{ '#' + stopPayment.lowCheckNumber }}</span>
            <span *ngIf="stopPayment.highCheckNumber && stopPayment.lowCheckNumber !== stopPayment.highCheckNumber">
              {{ ' - #' + stopPayment.highCheckNumber }}
            </span>
          </span>
          <span *ngIf="stopPayment.stopType === 'ACH'">N/A</span>
        </div>
        <div
          *ngIf="
            (stopPayment.highCheckNumber && stopPayment.lowCheckNumber === stopPayment.highCheckNumber) ||
            stopPayment.stopType === 'ACH'
          "
          class="fcol-xs-6"
        >
          <b>Payee:</b>
          <span>{{ stopPayment.payeeDebitingCompany }}</span>
        </div>
      </div>

      <div class="success-item between frow" *ngIf="stopPayment">
        <div
          *ngIf="
            (stopPayment.highCheckNumber && stopPayment.lowCheckNumber === stopPayment.highCheckNumber) ||
            stopPayment.stopType === 'ACH'
          "
          class="fcol-xs-6"
        >
          <b>Amount:</b>
          <span>{{ stopPayment.amount | currency }}</span>
        </div>
        <div class="fcol-xs-6">
          <b>Account:</b>
          <span>{{ accountName }}</span>
        </div>
      </div>

      <div class="success-item between frow" *ngIf="stopPayment">
        <div
          *ngIf="
            (stopPayment.highCheckNumber && stopPayment.lowCheckNumber === stopPayment.highCheckNumber) ||
            stopPayment.stopType === 'ACH'
          "
          class="fcol-xs-6"
        >
          <b *ngIf="stopPayment.stopType == 'Check'">Date Written:</b>
          <b *ngIf="stopPayment.stopType == 'ACH'">Date of Expected Debit:</b>
          <span>{{ stopPayment.checkDate | date: 'MM/dd/yyyy' }}</span>
        </div>
        <div class="fcol-xs-6">
          <b>Date Requested for Stop Payment:</b>
          <span>{{ stopPayment.requestedDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="fcol middle buttons-axos">
    <button type="button" (click)="redirectToStopPaymentsPage(accountId)" class="uk-btn solid secondary lg">
      Return to Current Stop Payments
    </button>
    <a class="sp-success__footer-accounts" (click)="redirectToDetailsPage(accountId)">
      Go to My Accounts
    </a>
  </div>
</div>
