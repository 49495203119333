import { TransferFundsController } from './transfer-funds.controller';

const transferFundsComponent: ng.IComponentOptions = {
  controller: TransferFundsController,
  controllerAs: '$tf',
  templateUrl: 'transfers/transfer-funds/transfer-funds-component/transfer-funds.tpl.html',
  bindings: {
    fromAccountId: '<?',
    toAccountId: '<?',
    isMoveMoneyFlow: '@?',
    step: '=?',
  },
};

export { transferFundsComponent };
