<form (ngSubmit)="submit()">
  <div class="acceptance-criteria-container" *ngIf="!(isLoading$ | async)">
    <div class="acceptance-criteria-header">
      <div class="header-nav">
        <img src="../../../../assets/axos/logos/axos-logo.png" class="logo" alt="Axos" />

        <div class="navigation-full">
          <svg (click)="goPreApprovedOffer()" class="svg-icon" aria-hidden="true" stroke="#fff">
            <use xlink:href="assets/svg/icons/navigation.svg#navigation-chevron-left"></use>
          </svg>
          <span (click)="goPreApprovedOffer()">Back to Credit Amount</span>
        </div>
      </div>
    </div>

    <div class="acceptance-criteria-body">
      <div class="acceptance-criteria-content">
        <div>
          <img src="../../../../assets/img/icons/sbloc/Illustration-Area.svg" class="logo" alt="sbloc" />
        </div>

        <div class="pre-approved-container">
          <h1>
            Your line of credit account has been pre-approved!
          </h1>
          <p>
            We require additional signatures before the opening of this account. Selecting "Yes, Email Me the Documents"
            will send the enrollment documents to the email associated with your account which needs to be completed for
            account set-up.
          </p>
        </div>

        <div class="sbloc-details-container">
          <div class="sbloc-details-title">
            <hr />
            <h2 style="width: 80em;">Securities Backed Line of Credit Detail</h2>
            <hr />
          </div>

          <div class="sbloc-details">
            <div style="border: solid 3px #f6c737; margin-right: 1em; border-radius: 50px; height: 104px;"></div>
            <div class="sbloc-details-table">
              <div>
                <h2>Line of Credit Amount</h2>
                <p>{{ amount | currency }}</p>
              </div>

              <div>
                <h2>Interest Rate</h2>
                <p>{{ apr }}% APR as of today</p>
              </div>
            </div>
          </div>
        </div>
        <div class="sbloc-account-set-up">
          <h2>Do you want the enrollment documents emailed to you to complete account set-up?</h2>
          <div *ngIf="emails[1] != ''">
            <p style="margin-bottom: 1em;">
              An email including this information will be sent to
              <span>{{ emails[0] }}</span>
              and
              <span>{{ emails[1] }}</span>
            </p>
          </div>
        </div>
        <div *ngIf="emails[1] == ''">
          <p style="margin-bottom: 1em; padding: 0px 24px;">
            An email including this information will be sent to
            <span>{{ emails[0] }}</span>
          </p>
        </div>
      </div>
      <button class="PrimaryButton" type="submit" id="btnSendEmail" [disabled]="processing$ | async">
        {{ (processing$ | async) ? 'Processing' : 'Yes, Email Me the Documents' }}
      </button>
      <button type="button" class="TextButton" (click)="openModal()">No, I don't want this offer</button>
    </div>
  </div>
</form>
