import { IRootScopeService } from 'angular';
import { UserSubTypeBitFlag } from 'common/enums/enums';

import { Inject } from '../../decorators/decorators';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';

@Inject('cookieHelper', '$rootScope')
export class UserSubtypeHelper {
  private userSubtype: number;
  constructor(private cookieHelper: CookieHelper, private readonly rootScope: IRootScopeService) {}

  hasFlag(serviceFlag: UserSubTypeBitFlag) {
    this.getUserSubType();
    return (this.userSubtype & serviceFlag) > 0;
  }

  hasAxosInvest() {
    this.getUserSubType();
    return (this.userSubtype & UserSubTypeBitFlag.AxosInvest) > 0;
  }

  hasAxosTrading() {
    this.getUserSubType();
    return (this.userSubtype & UserSubTypeBitFlag.AxosTrading) > 0;
  }

  isAxosTradingOnly(): boolean {
    this.getUserSubType();
    return (
      (this.userSubtype == UserSubTypeBitFlag.AxosTrading ||
        this.userSubtype == (UserSubTypeBitFlag.AxosBank | UserSubTypeBitFlag.AxosTrading)) &&
      !this.userHasAxosBankAccounts()
    );
  }

  userHasAxosBankAccounts(): boolean {
    let accounts = this.rootScope['accounts'] as AccountsPage;

    if (accounts) {
      return accounts.depositAccounts.length > 0 || accounts.loanAccounts.length > 0;
    }

    return false;
  }
  private getUserSubType(): void {
    this.userSubtype = this.cookieHelper.getUserSubType();
  }
}
