<div class="electronic-checkpoint" *ngIf="!showingMobileDetails">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <h1>
        <img [src]="'/img/icons/billPay/checkmark.svg'" width="100" alt="Favorite Recipient" aria-hidden="true" />
      </h1>
      <p>Next Up: eBills</p>
    </div>
  </div>
  <div class="text-center show-responsive mobile-header">
    <div>
      <h1>
        <img [src]="'/img/icons/billPay/checkmark.svg'" width="100" alt="Favorite Recipient" aria-hidden="true" />
      </h1>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-md-push-2 text-center">
        <h4>
          You Added
          <span [textContent]="biller.name"></span>
          as a recipient. Next, we’ll connect eBills to this recipient.
        </h4>
      </div>
    </div>
    <div class="frow center wrap electroni-address-content">
      <div class="fcol-cs-12 fcol-md-10">
        <div class="row hide-responsive">
          <div class="col-md-12 text-center">
            <a class="tab link" type="button" (click)="toggleTab()" value="tabSelected" uib-btn-radio="1" uncheckable>
              <i class="fa arrow" [ngClass]="tabSelected == 1 ? 'fa-angle-up' : 'fa-angle-down'"></i>
              <span>What are eBills?</span>
            </a>
          </div>
          <div class="col-md-12">
            <div class="information" *ngIf="tabSelected == 1">
              <p class="title">
                eBills are your electronic account bills. When you connect your eBills to Bill Pay, you can:
              </p>
              <p>
                <i class="fa fa-angle-right"></i>
                Get reminders about upcoming payments.
              </p>
              <p>
                <i class="fa fa-angle-right"></i>
                View PDFs of your past three bills.
              </p>
            </div>
          </div>
        </div>
        <div class="row show-responsive">
          <div class="col-md-12 text-center">
            <a class="tab link" type="button" (click)="showEBillDetail()">
              <span class="circle-icon">?</span>
              <span>What are eBills?</span>
            </a>
          </div>
        </div>
        <div class="row actions">
          <div class="col-md-12 actions text-center hide-responsive">
            <button type="button" class="uk-btn outline primary lg custom-button" (click)="goElectronicConfirmation()">
              Skip This Step
            </button>
            <button type="button" class="uk-btn solid secondary lg" (click)="goeStatements()">
              Continue
            </button>
          </div>
          <div class="col-md-12 text-center show-responsive buttons-container">
            <button type="button" class="uk-btn solid secondary lg mobile-button" (click)="goeStatements()">
              Continue
            </button>
            <button type="button" class="uk-btn outline primary lg mobile-button" (click)="goElectronicConfirmation()">
              Skip This Step
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="electronic-checkpoint" *ngIf="showingMobileDetails">
  <div class="frow">
    <div class="container mobile-container">
      <div class="row">
        <div class="col-md-12">
          <h4 class="mobile-title">
            What are eBills?
          </h4>
        </div>
        <div class="col-md-12 information mobile-information">
          <p>
            eBills are your electronic account bills. When you connect your eBills to Bill Pay, you can:
          </p>
          <p>
            <i class="fa fa-angle-right"></i>
            Get reminders about upcoming payments.
          </p>
          <p>
            <i class="fa fa-angle-right"></i>
            View PDFs of your past three bills.
          </p>
        </div>
        <div class="row actions">
          <div class="col-md-12 text-center buttons-container back-button">
            <button type="button" (click)="hideEBillDetails()" class="uk-btn outline primary lg mobile-button">
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>Electronic checkpoint</div>
</div>
