import { Inject } from '../decorators/decorators';

@Inject('$rootScope', 'env', '$http')
export class AuthRun {
  constructor(root: ng.IRootScopeService, env: any, http: ng.IHttpProvider) {
    http.defaults.headers.common = Object.assign(
      { 'X-FacingBrandId': `${env.facingBrandId}` },
      http.defaults.headers.common
    );
    // when the url changes, call sitecore to register the page
    root.$on('$stateChangeSuccess', _event => {});
  }
}
