<div class="scheduled-payments frow wrap">
  <h1 class="header">Payments Scheduled</h1>
  <div *ngIf="(isLoading$ | async) || isLoading" style="text-align: center; width: 100%;">
    <app-dot-loader class="frow wrap">Loading</app-dot-loader>
  </div>

  <div *ngIf="!isLoading && scheduledPayments.length <= 0" class="empty frow empty-state">
    <p class="light">No payments scheduled.</p>
  </div>
  <div *ngIf="!isLoading && scheduledPayments.length > 0" class="frow wrap hidden-xs hidden-sm">
    <div class="scheduled-payments__table fcol">
      <table class="table--primary">
        <thead>
          <tr>
            <th *ngFor="let header of headers" (click)="sort(header.subvalue, header.value)">
              {{ header.label }}
              <i *ngIf="headerIdOrderBy === header.value && reverse" class="fa fa-caret-down" aria-hidden="true"></i>
              <i *ngIf="headerIdOrderBy === header.value && !reverse" class="fa fa-caret-up" aria-hidden="true"></i>
              <app-tooltip
                *ngIf="getHeaderTooltip(header.subvalue)"
                [content]="getHeaderTooltip(header.subvalue)"
                theme="olb2"
              ></app-tooltip>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let payment of scheduledPayments">
            <td class="recipient-in-table">
              <span [textContent]="payment.payeeName"></span>
            </td>
            <td>
              <span [textContent]="payment.amount | currency"></span>
            </td>
            <td>
              <img
                class="frequency-icon"
                *ngIf="payment.paymentFrequency == 0"
                [src]="'/img/icons/mybills/onetime-icon.svg'"
              />
              <img
                class="frequency-icon"
                *ngIf="payment.paymentFrequency != 0"
                [src]="'/img/icons/mybills/recurring-icon.svg'"
              />
              <span [textContent]="getFrequencyString(payment.paymentFrequency)"></span>
              <span
                *ngIf="payment.paymentFrequency !== 0 && payment.items.length > 1"
                [textContent]="getRemainingPaymentsString(payment)"
              ></span>
            </td>
            <td>
              <span [textContent]="payment.fromAccount"></span>
            </td>
            <td>
              <span [textContent]="payment.processingDate | date: 'MM/dd/yyyy'"></span>
            </td>
            <td>
              <span [textContent]="payment.deliveryDate | date: 'MM/dd/yyyy'"></span>
            </td>
            <td>
              <span [textContent]="getRecipientType(payment.paymentMethod)"></span>
            </td>
            <td>
              <span [textContent]="payment.confirmationNumber"></span>
            </td>
            <td>
              <app-payment-options
                [payment]="payment"
                (getScheduledPayments)="getScheduledPayments()"
              ></app-payment-options>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!isLoading" class="mobile-section">
    <div *ngIf="scheduledPayments.length > 0" class="scheduled-mobile hidden-md hidden-lg">
      <div class="item" *ngFor="let payment of scheduledPayments">
        <p class="title"><span [textContent]="payment.payeeName"></span></p>
        <p class="details">
          <span>Sending</span>
          <span [textContent]="payment.amount | currency"></span>
          <span>on</span>
          <span [textContent]="payment.processingDate | date: 'MM/dd'"></span>
        </p>
        <p class="details">
          <img class="frequency-icon" *ngIf="payment.frequency == 0" [src]="'/img/icons/mybills/onetime-icon.svg'" />
          <img class="frequency-icon" *ngIf="payment.frequency != 0" [src]="'/img/icons/mybills/recurring-icon.svg'" />
          <span [textContent]="getFrequencyString(payment.paymentFrequency)"></span>
          <span
            *ngIf="payment.paymentFrequency !== 0 && payment.items.length > 1"
            [textContent]="getRemainingPaymentsString(payment)"
          ></span>
          <i class="circle-separator"></i>
          <span>Deliver By:</span>
          <span [textContent]="payment.deliveryDate | date: 'MM/dd'"></span>
        </p>
        <app-payment-options
          [payment]="payment"
          (getScheduledPayments)="getScheduledPayments()"
          class="gear-options"
        ></app-payment-options>
      </div>
    </div>
  </div>
</div>
