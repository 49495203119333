<div class="view-container">
  <h1 class="text-center">Profile & Settings</h1>

  <div class="d-flex flex-wrap">
    <div class="col-md-4 d-none d-md-block nav-list__container">
      <div class="nav-list">
        <button
          type="button"
          id="btn-image"
          class="item"
          [class.item--active]="isThisOptionActive(views.ProfileIcon)"
          (click)="goTo(views.ProfileIcon)"
        >
          <app-profile-image [businessProfile]="true" class="mr-2"></app-profile-image>
          <app-dot-loader *ngIf="loading; else userFullName"></app-dot-loader>
          <ng-template #userFullName>
            <p class="full-name" [innerText]="fullName | titlecase"></p>
          </ng-template>
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <button
          id="btn-sign-in"
          type="button"
          class="item"
          [class.item--active]="isThisOptionActive(views.SignInAndSecurity)"
          (click)="goTo(views.SignInAndSecurity)"
        >
          {{ viewName[views.SignInAndSecurity] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <button
          id="btn-personal-information"
          type="button"
          class="item"
          [class.item--active]="isThisOptionActive(views.PersonalInformation)"
          (click)="goTo(views.PersonalInformation)"
        >
          {{ viewName[views.PersonalInformation] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <button
          [hidden]="true"
          id="btn-business-information"
          type="button"
          class="item"
          *ngIf="sbbBusinessInfoActive"
          [class.item--active]="isThisOptionActive(views.BusinessInformation)"
          (click)="goTo(views.BusinessInformation)"
        >
          {{ viewName[views.BusinessInformation] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <button
          id="btn-contact-info"
          type="button"
          class="item"
          [class.item--active]="isThisOptionActive(views.ContactInfo)"
          (click)="goTo(views.ContactInfo)"
        >
          {{ viewName[views.ContactInfo] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <button
          id="btn-personal-info"
          type="button"
          class="item"
          *ngIf="iraEnhBaseFlagActive"
          [class.item--active]="isThisOptionActive(views.PersonalInfo)"
          (click)="goTo(views.PersonalInfo)"
        >
          {{ viewName[views.PersonalInfo] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <button
          id="btn-employment-info"
          type="button"
          class="item"
          [class.item--active]="isThisOptionActive(views.EmploymentInfo)"
          (click)="goTo(views.EmploymentInfo)"
        >
          {{ viewName[views.EmploymentInfo] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <app-dot-loader *ngIf="!haveAccountProfiles"></app-dot-loader>
        <ng-container *ngIf="haveAccountProfiles">
          <div *ngFor="let acc of accountProfiles; let i = index">
            <ng-container *ngIf="!acc.restrictQRelationshipAccounts || acc.restrictQRelationshipAccounts === null">
              <button
                id="btn-profile-information"
                type="button"
                class="item"
                *ngIf="sbbActive"
                [class.item--active]="isThisOptionActive(views.CompanyInformation, acc.id)"
                (click)="goTo(views.CompanyInformation, i, acc.id)"
              >
                <span class="title_ellipsis">{{ acc.name }}</span>
                <app-svg-icon
                  class="item__arrow"
                  [iconName]="icons.chevron"
                  [styles]="{ strokeWidth: 2 }"
                  size="0.9em"
                ></app-svg-icon>
              </button>
            </ng-container>
          </div>
        </ng-container>

        <button
          id="btn-direct-deposit"
          type="button"
          class="item"
          [class.item--active]="isThisOptionActive(views.DirectDeposit)"
          (click)="goTo(views.DirectDeposit)"
          *ngIf="directDepositEnabled"
        >
          {{ viewName[views.DirectDeposit] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>

        <button
          id="btn-google-auth"
          type="button"
          class="item"
          [class.item--active]="isThisOptionActive(views.GoogleAuthenticator)"
          (click)="goTo(views.GoogleAuthenticator)"
          *ngIf="googleAuthEnabled"
        >
          {{ viewName[views.GoogleAuthenticator] }}
          <app-svg-icon
            class="item__arrow"
            [iconName]="icons.chevron"
            [styles]="{ strokeWidth: 2 }"
            size="0.9em"
          ></app-svg-icon>
        </button>
      </div>
    </div>

    <div class="col-md-8">
      <app-alert-notification
        id="new-user-message"
        class="mb-3"
        [dismissable]="false"
        alertType="warning"
        *ngIf="displayNewUserMessage"
      >
        As a new customer, your contact information cannot be edited for 30 days. Contact us to request a change.
      </app-alert-notification>

      <ng-container *ngIf="!isMobileDevice; else mobileContent" [ngSwitch]="currentView">
        <app-change-icon-view *ngSwitchCase="views.ProfileIcon"></app-change-icon-view>
        <app-login-and-security-view *ngSwitchCase="views.SignInAndSecurity"></app-login-and-security-view>
        <app-personal-information-view *ngSwitchCase="views.PersonalInformation"></app-personal-information-view>
        <app-business-information-view *ngSwitchCase="views.BusinessInformation"></app-business-information-view>
        <app-company-information-view
          *ngSwitchCase="views.CompanyInformation"
          [setActiveProfile]="activeProfileId"
          (getActiveProfile)="gettingActiveProfileNum($event)"
          [setControlActive]="true"
        ></app-company-information-view>
        <app-contact-info-view *ngSwitchCase="views.ContactInfo"></app-contact-info-view>
        <app-personal-info-view *ngSwitchCase="views.PersonalInfo"></app-personal-info-view>
        <app-employment-info-view *ngSwitchCase="views.EmploymentInfo"></app-employment-info-view>
        <app-direct-deposit-view
          *ngSwitchCase="views.DirectDeposit"
          (createRequest)="goToDirectDeposit()"
        ></app-direct-deposit-view>
        <app-google-authentication-view *ngSwitchCase="views.GoogleAuthenticator"></app-google-authentication-view>
      </ng-container>

      <ng-template #mobileContent>
        <app-change-icon-view></app-change-icon-view>
        <app-login-and-security-view></app-login-and-security-view>
        <app-personal-information-view></app-personal-information-view>
        <app-contact-info-view *ngIf="sbbBusinessInfoActive"></app-contact-info-view>
        <app-personal-info-view *ngIf="iraEnhBaseFlagActive"></app-personal-info-view>
        <app-employment-info-view></app-employment-info-view>
        <app-dot-loader *ngIf="!haveAccountProfiles"></app-dot-loader>
        <ng-container *ngIf="haveAccountProfiles">
          <div *ngFor="let acc of accountProfiles; let i = index">
            <app-company-information-view
              *ngIf="haveAccountProfiles"
              [setActiveProfile]="acc.id"
              [mobileMenuTitle]="acc.name"
              (getActiveProfile)="gettingActiveProfileNum($event)"
              [setControlActive]="isThisOptionActive(views.CompanyInformation, acc.id)"
              (click)="goTo(views.CompanyInformation, i, acc.id)"
            ></app-company-information-view>
          </div>
        </ng-container>

        <app-direct-deposit-view
          [expanded]="isThisOptionActive(views.DirectDeposit)"
          *ngIf="directDepositEnabled"
          (createRequest)="goToDirectDeposit()"
        ></app-direct-deposit-view>
        <app-google-authentication-view
          [expanded]="currentView === views.GoogleAuthenticator"
          *ngIf="googleAuthEnabled"
        ></app-google-authentication-view>
      </ng-template>
    </div>
  </div>
</div>
