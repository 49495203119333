import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AggregateAccountDetailRow } from '../../models';

@Component({
  selector: 'app-aggregate-account-detail',
  templateUrl: './aggregate-account-detail.component.html',
  styleUrls: ['./aggregate-account-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregateAccountDetailComponent implements OnInit {
  @Input() title: string;
  @Input() rows: AggregateAccountDetailRow[];
  @Output() updateNickName = new EventEmitter();

  nicknameRow = {} as AggregateAccountDetailRow;
  otherRows: any;
  constructor(){}

  ngOnInit(): void {
    this.nicknameRow = this.rows.find((x: AggregateAccountDetailRow) => x.title.includes('Nickname'));
    if (this.nicknameRow)
    {
      this.otherRows =  this.rows.filter((x: AggregateAccountDetailRow) => x.title !== this.nicknameRow.title);
    }
    else{
      this.otherRows =  this.rows;
    }
  }

  updateNickNameEvent(){
    this.updateNickName.emit();
  }
}
