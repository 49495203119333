export enum StopSeriesOption {
  NumberOfPayments = 0,
  IChooseToStop = 1,
}

export function mapStopOption(option: number): string {
  switch (option) {
    case StopSeriesOption.NumberOfPayments:
      return 'Number of Payments';
    case StopSeriesOption.IChooseToStop:
      return 'I Choose to Stop';
    default:
      return '';
  }
}
