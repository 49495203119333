import { BaseService } from './base.service';
import { Inject } from '../decorators/decorators';
import { MilestoneDetailVm } from 'typings/app/wise-banyan/Milestone';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class WiseBanyanService extends BaseService {
  constructor(http: ng.IHttpService, env: any, serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'axosinvest', serviceHelper, q);
  }

  getMilestones(): ApiResponse<MilestoneDetailVm> {
    return this.get('milestones').catch((error: ApiError) => {
      this.serviceHelper.errorHandler(error, false);

      const milestoneErrorDetail = new MilestoneDetailVm();
      milestoneErrorDetail.milestones = [];
      milestoneErrorDetail.totalBalance = 0;
      milestoneErrorDetail.hasError = true;
      return { data: milestoneErrorDetail };
    });
  }

  getUserFormulas(): ApiResponse<Formula[]> {
    return this.get('formulas').catch(error => {
      this.serviceHelper.errorHandler(error, false);
      return { data: [] };
    });
  }

  getSsoToken(): ApiResponse<string> {
    return this.get('sso/token');
  }
}
