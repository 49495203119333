import { FileHelperService as FileHelper } from '@app/core/services/file.service';

import { SupportFile } from '@shared/models/support-file.model';

import { Inject } from '../../../decorators/decorators';
import { PopupsHelper } from '../../../services/popups.helper';
import { IEnrollmentService } from '../../../services/typings/IEnrollmentService';
import { RecipientDetailsModel, WireTransfer, WireTransferDestination } from '../../typings/WireTransfer';
@Inject('$stateParams', '$state', 'enrollmentService', 'serviceHelper')
export class RecipientDetailsController {
  wiretransfer: WireTransfer;
  isLocalTransfer: boolean;
  zipCodeMaxLength: number;
  zipElementClass: string;
  recipientTransfer: RecipientTransfer;
  zipCodeValidating = false;
  rnameValid = true;
  rjointnameValid = true;
  rfurtherValid = true;
  raddressValid = true;
  zcodeValid = true;
  zcodeValidUnFocused = true;
  cityValid = true;
  rstateValid = true;
  /**Attachments */
  document: any;
  isFileAdded: boolean;
  errorMessage: string;
  popups: IPopups = new PopupsHelper();
  fileHelper = new FileHelper();
  headerSubTitle: string;
  foreignInvalidField: any = {};
  totalMaxSize = 10485760; // 10mb
  inputPattern = /^[0-9 a-zA-Z \-,.()"#%\+='<>\$!?;&@:_\\\/\r\n]*$/;

  constructor(
    private params: ng.ui.IStateParamsService,
    private readonly state: ng.ui.IStateService,
    private enrollmentService: IEnrollmentService,
    private serviceHelper: IServiceHelper
  ) {}

  get states() {
    return {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FM: 'Federated States Of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
    };
  }

  get zipRegExp(): RegExp {
    return /^\d{5}$/;
  }

  get furtherInstructionsLength(): number {
    return this.wiretransfer &&
      this.wiretransfer.recipientDetails &&
      this.wiretransfer.recipientDetails.furtherInstructions
      ? this.wiretransfer.recipientDetails.furtherInstructions.length
      : 0;
  }

  /** Initializes the controller. */
  $onInit(): void {
    if (!this.params.wiretransfer) this.state.go('udb.transfers.wireTransfers');

    this.wiretransfer = this.params.wiretransfer;
    if (!this.wiretransfer.recipientDetails) {
      this.wiretransfer.recipientDetails = new RecipientDetailsModel();
    }
    this.isLocalTransfer = this.wiretransfer.destination === WireTransferDestination.us;
    this.headerSubTitle = this.isLocalTransfer ? 'Wire Transfer in the U.S.' : 'Wire Transfer Outside the U.S.';

    this.zipCodeMaxLength = this.isLocalTransfer ? 5 : 10;

    this.scrollToTop();
  }

  /** Gets the city and state for the provided zip code */
  getCityByZipCode(): void {
    if (!this.isLocalTransfer) return this.setValidationCode();

    const { postalcode = '' } = this.wiretransfer.recipientDetails;

    if (postalcode && postalcode.length == 5) {
      this.zipCodeValidating = true;

      this.enrollmentService
        .getCityAndState(postalcode)
        .then(res => {
          const info = res.data as CityAndStateInfo;
          if (!this.wiretransfer.recipientDetails.city) {
            this.wiretransfer.recipientDetails.city = info.city;
          }
          if (!this.wiretransfer.recipientDetails.state) {
            this.wiretransfer.recipientDetails.state = info.stateCode;
          }
        })
        .catch(err => {
          if (err.status == 404) {
            this.wiretransfer.recipientDetails.city = null;
            this.wiretransfer.recipientDetails.state = null;
          } else {
            this.serviceHelper.errorHandler(err);
          }
        })
        .finally(() => {
          this.zipCodeValidating = false;
          this.setValidationCity();
          this.setValidationCode();
          this.setValidationState();
          this.setValidationCodeUnFocused();
        });
    } else {
      this.wiretransfer.recipientDetails.city = null;
      this.wiretransfer.recipientDetails.state = null;
    }
  }

  setValidationName(): void {
    this.rnameValid = !(
      !this.wiretransfer.recipientDetails ||
      this.wiretransfer.recipientDetails.name == null ||
      this.wiretransfer.recipientDetails.name === '' ||
      !this.inputPattern.test(this.wiretransfer.recipientDetails.name)
    );
  }

  setValidationJointName(): void {
    this.rjointnameValid = !(
      !this.wiretransfer.recipientDetails ||
      this.wiretransfer.recipientDetails.joinName == null ||
      this.wiretransfer.recipientDetails.joinName === '' ||
      !this.inputPattern.test(this.wiretransfer.recipientDetails.joinName)
    );
  }

  setValidationFurther(): void {
    this.rfurtherValid = !(
      !this.wiretransfer.recipientDetails ||
      !this.inputPattern.test(this.wiretransfer.recipientDetails.furtherInstructions)
    );
  }

  setValidationAddress(): void {
    this.raddressValid = !(
      !this.wiretransfer.recipientDetails ||
      this.wiretransfer.recipientDetails.address == null ||
      this.wiretransfer.recipientDetails.address == '' ||
      !this.inputPattern.test(this.wiretransfer.recipientDetails.address)
    );
  }

  setValidationCity(): void {
    this.cityValid = !(
      !this.wiretransfer.recipientDetails ||
      this.wiretransfer.recipientDetails.city == null ||
      this.wiretransfer.recipientDetails.city == '' ||
      !this.inputPattern.test(this.wiretransfer.recipientDetails.city)
    );
  }

  setValidationCode(): void {
    if (this.isLocalTransfer) {
      const postalcode = this.wiretransfer.recipientDetails.postalcode || '';

      this.zcodeValid = this.zipRegExp.test(postalcode);
    } else {
      this.zcodeValid = true;
    }
  }

  setValidationCodeUnFocused(): void {
    if (this.wiretransfer && this.wiretransfer.recipientDetails) {
      this.zcodeValidUnFocused = this.zipRegExp.test(this.wiretransfer.recipientDetails.postalcode);
    } else this.zcodeValidUnFocused = false;
  }

  setValidationState(): void {
    if (!this.isLocalTransfer) {
      this.rstateValid = true;
    } else {
      this.rstateValid = !(
        !this.wiretransfer.recipientDetails || this.wiretransfer.recipientDetails.state === undefined
      );
    }
  }

  recipientSubmit(): void {
    this.ensureRequiredFieldsTouched();
    const foreignValid = this.isLocalTransfer
      ? true
      : Object.keys(this.foreignInvalidField).every(key => !this.foreignInvalidField[key]);

    this.setValidations();

    if (!foreignValid) {
      return this.scrollToTop();
    } else if (this.rnameValid && this.raddressValid && this.zcodeValid && this.cityValid && this.rstateValid) {
      if (
        this.wiretransfer.recipientDetails.furtherInstructions != undefined &&
        this.wiretransfer.recipientDetails.furtherInstructions.trim().length == 0
      ) {
        this.wiretransfer.recipientDetails.furtherInstructions = undefined;
      }
      this.state.go('udb.transfers.wireTransfers.bankDetails', {
        wiretransfer: this.wiretransfer,
      });
    }
  }

  onFieldChange(field: keyof RecipientDetailsModel): void {
    const value = `${this.wiretransfer.recipientDetails[field]}`;
    let valid: boolean;
    const foreignFields = ['country', 'city'];

    if (foreignFields.includes(field)) {
      valid = value.length > 0 && value.length <= 100;
    }

    this.foreignInvalidField[field] = !valid;
  }

  getFieldClass(field: keyof RecipientDetailsModel) {
    const red = this.foreignInvalidField[field];

    return red ? { red } : null;
  }

  filesUpdate(supportFiles: SupportFile[]) {
    this.wiretransfer.documents = supportFiles;
  }

  private setValidations(): void {
    this.setValidationName();
    this.setValidationAddress();
    this.setValidationCity();
    this.setValidationCode();
    this.setValidationState();
  }

  private ensureRequiredFieldsTouched() {
    const requiredFields = ['country', 'city'];

    requiredFields.forEach(field => this.onFieldChange(field as keyof RecipientDetailsModel));
  }

  private scrollToTop() {
    return document.querySelector('.header-section-title').scrollIntoView();
  }
}
