<div
  class="uikit-click-dropdown"
  role="combobox"
  tabindex="0"
  (blur)="toggleDropdown(true)"
  (keydown)="listBoxKeyPress($event)"
  [attr.aria-expanded]="showDropdown"
  [attr.aria-autocomplete]="false"
  attr.aria-owns="dropdown-list"
  attr.aria-labelledby="click-dropdown-selected-item"
>
  <a class="uikit-click-dropdown__selected-item selected-item" (click)="toggleDropdown()">
    <div class="title text-center" title="{{ selectedItem.text }}" id="click-dropdown-selecteditem-text">
      {{ selectedItem.text }}
    </div>
    <div
      class="arrow"
      *ngIf="showItemsAlways || dropdownItems.length > 1"
      [style]="{ open: showDropdown, closed: !showDropdown }"
    ></div>
    <span
      class="description text-center"
      [hidden]="!selectedItem.description"
      id="click-dropdown-selecteditem-description"
    >
      {{ selectedItem.description || null }}
    </span>
  </a>
  <div class="dropdown-list" role="listbox" [hidden]="!showDropdown">
    <ul id="list-inner-container">
      <li
        class="list-item text-center"
        role="option"
        [style]="{ focus: item.focus }"
        focus-element="item.focus"
        (mouseenter)="mouseTriggeredFocus(item)"
        (mouseleave)="mouseLeave()"
        *ngFor="let item of dropdownItems; let i = index"
        id="item-{{ i }}"
      >
        <a [hidden]="item == selectedItem" (click)="select(i)" [style]="{ 'item-content-icon': hasDropdownItemsIcon }">
          <span class="item-icon" *ngIf="hasDropdownItemsIcon">
            <img [src]="item.icon" />
          </span>
          <div [style]="{ 'list-item-content': hasDropdownItemsIcon }">
            <span class="item-text text-left">{{ item.text }}</span>
            <span class="item-complementary-text text-center">{{ item.description }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
