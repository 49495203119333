import { createReducer, on } from '@ngrx/store';
import { UserPreferencesState, initialUserPreferencesState } from '../types';
import { updateApplicationPreferencesAction, updateUserPreferencesAction } from '../user-preferences.actions';

export const userPreferencesReducer = createReducer(
  initialUserPreferencesState,
  on(
    updateUserPreferencesAction,
    (state: UserPreferencesState, action: { payload: Pick<UserPreferencesState, 'userPreferences'> }) => {
      if (state.userPreferences.length > 0) {
        return {
          defaultAppPreferences: state.defaultAppPreferences,
          userPreferences: action.payload.userPreferences
            ? updateAndAddItems(state.userPreferences, action.payload.userPreferences)
            : state.userPreferences,
        };
      }
      return {
        defaultAppPreferences: state.defaultAppPreferences,
        userPreferences: action.payload.userPreferences ?? [],
      };
    }
  ),
  on(
    updateApplicationPreferencesAction,
    (state: UserPreferencesState, action: { payload: Pick<UserPreferencesState, 'defaultAppPreferences'> }) => {
      if (state.defaultAppPreferences.length > 0) {
        return {
          defaultAppPreferences: action.payload.defaultAppPreferences
            ? updateAndAddItems(state.defaultAppPreferences, action.payload.defaultAppPreferences)
            : state.defaultAppPreferences,
          userPreferences: state.userPreferences,
        };
      }
      return {
        defaultAppPreferences: action.payload.defaultAppPreferences ?? [],
        userPreferences: state.userPreferences,
      };
    }
  )
);

export function updateAndAddItems<T extends { id: number }>(listA: T[], listB: T[]): T[] {
  const newState = [...listA];
  listB.forEach(preferenceB => {
    const existingPreferenceIndex = newState.findIndex(preferenceA => preferenceA.id === preferenceB.id);
    if (existingPreferenceIndex !== -1) {
      newState[existingPreferenceIndex] = {
        ...newState[existingPreferenceIndex],
        ...preferenceB,
      };
    } else {
      newState.push(preferenceB);
    }
  });
  return newState;
}
