import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { isBefore, isValid } from 'date-fns';

import { BeneficiaryCommon } from '@shared/models';

import { BeneficiaryAccountType } from '../enums';

/**
 * Creates a FormGroup with all the necessary controls for a Beneficiary form.
 *
 * @param beneficiary BeneficiaryCommon object for initial values.
 *
 * @returns A FormGroup to create an account beneficiary.
 */
export function createBeneficiaryForm(beneficiary: Partial<BeneficiaryCommon>, accountType?: BeneficiaryAccountType) {
  const form = new UntypedFormGroup({
    key: new UntypedFormControl(beneficiary.key),
    name: new UntypedFormControl(beneficiary.name, [Validators.required]),
    percent: new UntypedFormControl(beneficiary.percent, [Validators.required, Validators.min(1), Validators.max(100)]),
    identity: new UntypedFormControl(beneficiary.identity, [
      Validators.required,
      Validators.pattern(/^$|^\d{2}\/\d{2}\/\d{4}$/),
      isBirthDateValid(),
    ]),
    relationship: new UntypedFormControl(beneficiary.relationship, [Validators.required]),
  });

  if (accountType === BeneficiaryAccountType.Dira) {
    form.addControl('level', new UntypedFormControl(beneficiary.level));
  }

  return form;
}

export function isBirthDateValid(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value || control.value?.length !== 10) {
      return null;
    }

    let isValidBirthday = false;
    if (isValid(new Date(control.value))) {
      isValidBirthday = isBefore(new Date(control.value), new Date());
    }

    return isValidBirthday ? null : { invalidDate: true };
  };
}
