<div class="debit-card-overview col-12">
  <div class="d-flex between card--row">
    <span class="font-weight-bold text-capitalize">{{ embosName | lowercase }}</span>
    <span>Issued {{ issuedDate }}</span>
  </div>

  <div class="d-flex between card--row">
    <span class="font-weight-bold text-capitalize">{{ secondEmbosName | lowercase }}</span>
  </div>

  <div class="d-flex pt-2 pb-2 between card--row">
    <span class="text-uppercase">{{ debitCardTypeDescription }} {{ maskedCardNumber }}</span>
    <span>Exp {{ expirationDate }}</span>
  </div>

  <app-dot-loader *ngIf="isUpdating; else options"></app-dot-loader>

  <ng-template #options>
    <div class="d-flex pt-2 pb-2 between card--row">
      <span class="card--status">
        Status:
        <b class="text-uppercase">{{ statusDescription }}</b>
      </span>

      <button
        *ngIf="isCardInProcess"
        id="btn-activate"
        type="button"
        class="btn-ok button--sm"
        (click)="activateCardConfirmation()"
      >
        Activate
      </button>

      <span *ngIf="!isCardInProcess && debitCard?.allowManagement">
        <label class="uk-switch">
          <input id="toggle-card" type="checkbox" [checked]="debitCard.isActive" (change)="toggleCardStatus()" />
          <span class="slider"></span>
          <span class="label-switch">{{ debitCard.isActive ? 'ON' : 'OFF' }}</span>
        </label>
      </span>
    </div>
  </ng-template>
</div>
