import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import {
  FillTypeDescription,
  GoodUntilDescription,
  OrderTypeDescription,
  TradeStockType,
} from '@app/axos-trading/enums';
import { OrderStatus } from '@app/axos-trading/enums/order-status';
import {
  Equity,
  Order,
  OrderFilter,
  PortfolioOverview,
  TradeStockFormEvent,
  TradingAccount,
} from '@app/axos-trading/models';
import { ClearingService, TradingRoutingHelperService } from '@app/axos-trading/services';
import {
  changeToLimitStockForm,
  changeToMarketStockForm,
  equityFormToOrderConfirmation,
  validateHourMarketPrice,
} from '@app/axos-trading/utils';
import { DialogService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons } from '@shared/enums';
import { AlertsIcons } from '@shared/enums';
import { DialogData } from '@shared/models';
@Component({
  selector: 'app-trade-stock-form',
  templateUrl: './trade-stock-form.component.html',
  styleUrls: ['./trade-stock-form.component.scss'],
})
export class TradeStockFormComponent implements OnInit, OnDestroy {
  @Input() stock: Equity;
  @Input() tradeType: TradeStockType;
  @Input() form: UntypedFormGroup;
  @Input() account: TradingAccount;
  @Input() portfolioOverview: PortfolioOverview;

  @Output() formSubmitted = new EventEmitter<TradeStockFormEvent>();

  tradeTypes = TradeStockType;
  orderTypes = OrderTypeDescription;
  orderTypeOptions: OrderTypeDescription[] = [OrderTypeDescription.Limit];
  fillTypeOptions: FillTypeDescription[] = [FillTypeDescription.Partial];
  goodUntilOptions: GoodUntilDescription[] = [GoodUntilDescription.endOfDay, GoodUntilDescription.canceled];
  pendingOrders = [OrderStatus.ORDER_EXECUTED, OrderStatus.CANCELLATION_OPERATION, OrderStatus.ORDER_REJECTED];
  ordersWithPendingStatus: number;
  icons = {
    backIcon: NavigationIcons.ArrowBack,
  };
  estimatedCost: number;
  sharesOwned: number;
  submitted: boolean;
  displayMarketValidation = false;
  previewingOrder: boolean;

  orders: Order[];
  dialogData = new DialogData({
    title: 'Discard Changes?',
    icon: AlertsIcons.ExclamationCircle,
    hasCloseButton: true,
    content: '<p> If you go back without saving, your changes will be </p> <p> discarded. </p>',
    okText: 'Discard Changes',
    cancelText: 'Cancel',
  });

  dialogLimitData = new DialogData({
    icon: AlertsIcons.ExclamationCircle,
    hasCloseButton: false,
    content: '<p> A limit sell order must be at least 70% of the current market value</p>',
  });

  private subSink = new SubSink();

  constructor(
    private routerHelper: TradingRoutingHelperService,
    private dialogService: DialogService,
    private clearingService: ClearingService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.checkIfIsHourMarketPrice();
    this.getOrders();
    this.setFormEvents();
    this.calculateNumberOfShares();
    this.onChangeShareAmount();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  goBackToStock() {
    if (this.form.dirty) {
      this.subSink.sink = this.dialogService
        .open(this.dialogData)
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.routerHelper.goToPositionStockDetail(this.account.id, this.stock.symbol);
          }
        });
    } else {
      this.routerHelper.goToPositionStockDetail(this.account.id, this.stock.symbol);
    }
  }

  submit() {
    this.submitted = true;
    this.validateIfHavePendingSales();

    if (this.form.invalid) return;
    if (this.form.get('orderType').value === this.orderTypes.Limit && this.tradeType === TradeStockType.Sell) {
      if (this.validateLimitPriceLower()) return;
    }

    this.previewOrder();
  }

  private previewOrder() {
    this.previewingOrder = true;
    const order = equityFormToOrderConfirmation(this.form.value, this.stock, this.tradeType, this.account);
    this.clearingService
      .previewOrder(order)
      .toPromise()
      .then(preview => this.formSubmitted.emit({ order, preview }))
      .catch(errors => this.serviceHelper.errorHandler(errors))
      .finally(() => (this.previewingOrder = false));
  }

  private setFormEvents() {
    this.subSink.sink = this.form.get('orderType').valueChanges.subscribe(value => this.onChangeOrderType(value));
    this.subSink.sink = this.form.get('shareAmount').valueChanges.subscribe(() => this.onChangeShareAmount());
  }

  private onChangeShareAmount() {
    const orderType = this.form.get('orderType').value;
    if (!orderType) return;
    const limitPrice = this.form.get('limitPrice') ? this.form.get('limitPrice').value : 0;
    const orderTypeEnu = orderType as OrderTypeDescription;
    const shareAmount = this.form.get('shareAmount').value;

    const strategy = {
      [OrderTypeDescription.Limit]: () => shareAmount * limitPrice,
      [OrderTypeDescription.Market]: () => shareAmount * this.stock.marketValue,
    };

    this.estimatedCost = strategy[orderTypeEnu]();

    const validators = {
      [TradeStockType.Sell]: this.validateShareSell,
      [TradeStockType.Buy]: this.validateShareBuy,
    };

    const valid = validators[this.tradeType].bind(this)();
    if (!valid) this.form.get('shareAmount').setErrors({ insufficient: true });

    this.validateAllOrNone(shareAmount);
  }

  private validateAllOrNone(shareAmount: number) {
    this.fillTypeOptions =
      shareAmount >= 100 ? [FillTypeDescription.AllOrNone, FillTypeDescription.Partial] : [FillTypeDescription.Partial];
    const fillTypeControl = this.form.get('fillType');
    if (fillTypeControl.value === FillTypeDescription.AllOrNone && shareAmount < 100) {
      fillTypeControl.setValue(FillTypeDescription.Partial);
    }
  }

  private validateShareSell() {
    return this.form.get('shareAmount').value <= this.sharesOwned;
  }

  private validateShareBuy() {
    return this.estimatedCost <= this.portfolioOverview.buyingPower;
  }

  private calculateNumberOfShares() {
    const positions = this.portfolioOverview.positions?.filter(position => position.symbol === this.stock.symbol);
    this.sharesOwned = positions?.length > 1 ? positions.map(position => position.shares).reduce((a, b) => a + b) : 0;
    this.sharesOwned = positions?.length === 1 ? positions[0].shares : this.sharesOwned;
  }

  private onChangeOrderType(orderType: OrderTypeDescription) {
    if (orderType === OrderTypeDescription.Limit) {
      this.displayMarketValidation = false;
    }
    const actions = {
      [OrderTypeDescription.Limit]: changeToLimitStockForm,
      [OrderTypeDescription.Market]: changeToMarketStockForm,
    };
    actions[orderType](this.form);

    this.setLimitPriceEvent(orderType);
    this.onChangeShareAmount();
  }

  private checkIfIsHourMarketPrice() {
    this.displayMarketValidation = !validateHourMarketPrice();
    if (!this.displayMarketValidation) this.orderTypeOptions.push(OrderTypeDescription.Market);
  }
  private setLimitPriceEvent(orderType: OrderTypeDescription) {
    if (orderType === OrderTypeDescription.Limit) {
      this.subSink.sink = this.form
        .get('limitPrice')
        .valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(() => this.onChangeShareAmount());
    }
  }

  private validateLimitPriceLower() {
    const validLimitPrice = Number.parseFloat((this.stock.marketValue * 0.7).toFixed(2));
    const limitPrice = Number.parseFloat(this.form.get('limitPrice').value);

    if (validLimitPrice > limitPrice) {
      this.subSink.sink = this.dialogService.open(this.dialogLimitData).afterClosed().subscribe();

      return true;
    }
  }

  private getOrders() {
    const filters = new OrderFilter({
      symbol: this.stock.symbol,
    });
    this.subSink.sink = this.clearingService.getOrders(this.account.accountNumber, filters).subscribe(result => {
      this.orders = result;
      this.orders.sort((a: Order, b: Order) => {
        return Date.parse(b.createdDate) - Date.parse(a.createdDate);
      });
    });
  }

  private validateIfHavePendingSales() {
    if (this.tradeType !== TradeStockType.Sell) return;

    const shareAmount = this.form.get('shareAmount').value;
    this.ordersWithPendingStatus = this.orders
      .filter(
        x =>
          !this.pendingOrders.includes(x.status.code as OrderStatus) &&
          x.category.description.toUpperCase() === TradeStockType.Sell.toUpperCase()
      )
      .reduce((a, b) => a + b.quantity, 0);

    if (this.ordersWithPendingStatus <= 0) return;

    const totalSharesOwnedLessPedingOrders = shareAmount + this.ordersWithPendingStatus;
    if (this.sharesOwned < totalSharesOwnedLessPedingOrders) {
      this.form.get('shareAmount').setErrors({ pendingShares: true });
    }
  }
}
