import { PortfolioAllocation } from './portfolio-allocation.type';

export type PortfolioAllocationTileState = {
  accountNumber: string | undefined;
  portfolioAllocations: PortfolioAllocation[] | undefined;
  error?: Error | unknown | undefined;
};

export const initialPortfolioAllocationTileState: PortfolioAllocationTileState = {
  accountNumber: undefined,
  portfolioAllocations: [],
  error: undefined,
};
