import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

import { ModelsState } from '../types';

const getModelsState = createFeatureSelector<ModelsState>(AasStoreFeatures.Models);

export const getSelectedAccountModelsTile = createSelector(getModelsState, state => {
  return state.accountsModels.find(account => account.accountNumber === state.selectedAccountModels);
});

export const getCurrentAccountId = createSelector(getModelsState, state => state.selectedAccountModels);
