import { TModelSortTypeValues, TModelSortTypes } from '../../../types';
import { CamelCaseKeyName, Model } from '../../types';
/**
 * @param value {@link TModelSortTypeValues}
 * @param object {@link TModelSortTypes}
 * @param stringMapperFn - mapper function to transform object key into a given format if wanted
 * @param defaultKey keyof {@link Model}
 * @returns a keyof {@link Model}
 */
export function getKeyByValueFromModel(
  value: TModelSortTypeValues,
  object: TModelSortTypes,
  stringMapperFn: (key: string) => CamelCaseKeyName,
  defaultKey: keyof Model
): keyof Model {
  for (const [key, keyValue] of Object.entries(object)) {
    if (keyValue === value) {
      return stringMapperFn(key) as keyof Model;
    }
  }
  return defaultKey;
}
