export enum UserBrand {
  BankOfInternet = 1,
  BofiFederalBank = 2,
  UFB = 3,
  BofiAdvisor = 4,
  AdvisorBusiness = 5,
  NetBank = 6,
  BankX = 7,
  //Bank Social = 8,
  SpecialtyDeposits = 9,
  VirtusBank = 10,
  NationwidePremier = 30,
  NationwideStandard = 31,
  NationwideSmallBusiness = 32,
  NationwideSecondChance = 36,
}

export enum FacingBrand {
  Axos = 1,
  Nationwide = 2,
  UFB = 3,
}

export const facingBrandMapping = [
  {
    facingBrand: FacingBrand.Axos,
    brands: [
      UserBrand.BankOfInternet,
      UserBrand.BofiFederalBank,
      UserBrand.BankX,
      UserBrand.NetBank,
      UserBrand.BofiAdvisor,
      UserBrand.SpecialtyDeposits,
      UserBrand.VirtusBank,
      UserBrand.AdvisorBusiness
    ],
  },
  {
    facingBrand: FacingBrand.Nationwide,
    brands: [
      UserBrand.NationwidePremier,
      UserBrand.NationwideStandard,
      UserBrand.NationwideSmallBusiness,
      UserBrand.NationwideSecondChance,
    ],
  },
  {
    facingBrand: FacingBrand.UFB,
    brands: [UserBrand.UFB],
  },
];
