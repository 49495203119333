<div style="border-radius: 20px;">
  <div class="dialog-header pb-0">
    <button type="button" class="dialog-header-close" (click)="dialogRef.close()" *ngIf="!this.successModal">
      <app-svg-icon [iconName]="iconConfig.close.icon" [size]="iconConfig.close.size"></app-svg-icon>
    </button>
    <h1>{{ this.successModal ? 'Overdraft Protection Successfully Added' : 'Why Have Overdraft Protection?' }}</h1>
  </div>
  <div class="dialog-content pt-0 pb-0">
    <hr />
    <div *ngIf="!this.successModal">
      <img src="../../../../../assets/axos/img/icons/Overdraft/cross-sell.svg" class="dialog-content__logo" />
      <p>
        An Overdraft Line of Credit is a cost-effective, convenient way to protect against situations where you have a
        low account balance. Your
        <b>{{ accountNickname }}</b>
        account is eligible for up to $5,000 in protection, with no credit check required.
      </p>
      <br />
      <a class="link" (click)="showModalOverdraft()">Overdraft Line of Credit Terms and Conditions</a>
    </div>
    <div *ngIf="this.successModal">
      <img src="../../../../../assets/axos/img/icons/Overdraft/Overdraft Success.svg" class="dialog-content__logo" />
      <p>
        Great news! Your
        <b>{{ accountNickname }}</b>
        account now benefits from $5,000.00 in overdraft protection. To access the details of your overdraft protection,
        simply head over to your Account Details page.
      </p>
    </div>
    <hr />
  </div>
  <div class="dialog-footer pt-0">
    <button
      type="button"
      class="dialog-footer-button"
      [disabled]="disabledBtn"
      (click)="this.successModal ? close() : this.addOdlToAccount()"
    >
      {{ this.successModal ? 'Done' : 'Add Overdraft Protection' }}
    </button>
  </div>
</div>
