import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IHttpService, IQService, IRootScopeService } from 'angular';

import { Inject } from '../decorators/decorators';
import { ObservableBaseService } from './observable-base.service';

@Inject('$http', '$q', 'env', 'serviceHelper', '$rootScope')
export class AppSettingsService extends ObservableBaseService {
  constructor(
    http: IHttpService,
    q: IQService,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    private readonly rootScope: IRootScopeService
  ) {
    super(http, env, 'configuration', serviceHelper, q);
  }

  getSetting(settingName: string): Observable<AppSetting> {
    const appSettings = this.rootScope['appSettings'];

    if (appSettings) {
      return of(appSettings.find((appSetting: AppSetting) => appSetting.name === settingName));
    }

    return this.get<AppSetting>(`appsetting/${settingName}`).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }

  getSettingParsed<T>(settingName: string): Observable<T> {
    return this.getSetting(settingName).pipe(
      map(({ value }) => {
        return JSON.parse(value) as T;
      })
    );
  }

  isFeatureEnabled(name: string): Observable<boolean> {
    return this.get<boolean>('isFeatureEnabled', { name }).pipe(
      map(({ data }) => {
        return data;
      })
    );
  }
}
