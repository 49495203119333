import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  BILLPAYHELPER,
  BILLPAYROUTERHELPER,
  BILLPAYSERVICE,
  ENROLLMENTSERVICE,
  MODALSERVICE,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { STATE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { Recipient } from '@legacy/bill-pay/typings/Recipient';
import { RecipientUpdateVm } from '@legacy/bill-pay/typings/RecipientUpdateVm';
import { ModalService } from '@legacy/services/modal.service';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { IEnrollmentService } from '@legacy/services/typings/IEnrollmentService';
import { Biller } from '@legacy/typings/app/bills/Biller';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-edit-recipient',
  templateUrl: './edit-recipient.component.html',
  styleUrls: ['./edit-recipient.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [billPayServiceProvider],
})
export class EditRecipientComponent implements OnInit {
  recipient: Recipient = new Recipient();
  address: string;
  isElectronic: boolean;
  isTooltipReady: boolean;
  isFavorite: boolean;
  states: any;
  isLoading: boolean;
  favoriteTooltip: string;
  accountTooltip: string;
  invalidAccountMessage: string;
  errorMessage: string;
  constructor(
    @Inject(BILLPAYSERVICE) private readonly _billPayService: IBillPayService,
    @Inject(STATE) private readonly _stateService: IStateService,
    @Inject(ENROLLMENTSERVICE) private readonly _enrollmentService: IEnrollmentService,
    @Inject(MODALSERVICE) private readonly _modalService: ModalService,
    @Inject(BILLPAYROUTERHELPER) private readonly _billPayRouterHelper: BillPayRouterHelper,
    @Inject(BILLPAYHELPER) private readonly _billPayHelper: BillPayHelper,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.isLoading = true;
    this.startAddress();
    const recipientResponse = this._billPayRouterHelper.getRecipientDetails();
    if (!recipientResponse) this._stateService.go('udb.billPay.pay');
    else {
      this.recipient = recipientResponse;
    }
    this.states = this._billPayHelper.getStates();
    this.isElectronic = this.recipient?.paymentMethod === 1;
    this.isFavorite = this.recipient?.userTags?.includes('Favorite');
    this.address = `${this.recipient?.billingAddress?.addressLine1}<br>
                        ${this.recipient?.billingAddress?.city}, ${this.recipient?.billingAddress?.stateCode} ${this.recipient?.billingAddress?.zipCode}`;
    this.favoriteTooltip = `Favorite Recipients are pinned to the top of your recipient list in Bill Pay.
                               <br/>Mark recipients as your favorites when you need to use them often.`;
    if (this.isElectronic) {
      this.getPaymentAccountHint(this.recipient);
    }
    this.isLoading = false;
  }

  fillCityAndState(): void {
    if (this.recipient.billingAddress.zipCode && this.recipient.billingAddress.zipCode.length === 5) {
      this._enrollmentService
        .getCityAndState(this.recipient.billingAddress.zipCode)
        .then(res => {
          let info = res.data;
          this.recipient.billingAddress.city = info.city;
          this.recipient.billingAddress.stateCode = info.stateCode;
        })
        .catch(() => {});
    }
  }

  validateAccount(): void {
    this.invalidAccountMessage = null;
    this.isLoading = true;
    if (this.isElectronic) {
      this._billPayService
        .validateBillerAccountNumber(this.recipient.paymentAccount, this.recipient.billerId)
        .then(() => {
          this.updateRecipient();
        })
        .catch(err => {
          this.invalidAccountMessage = err.data?.message;
          this.isLoading = false;
        });
    } else this.updateRecipient();
  }

  deleteRecipient(): void {
    this._modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Remove',
          cancelText: 'Cancel',
          bodyText: `<h3>Are you sure you want to delete ${
            this.recipient?.nickName || this.recipient?.displayName
          }?</h3>
                    <p class="hidden-sm ms-secondary-text">You can add this recipient again from the Pay tab.
                    Any scheduled payments you have will be cancelled when you remove this recipient</p>`,
        }
      )
      .then(() => {
        this.removeRecipient();
      })
      .catch(() => {
        return;
      });
  }

  editCancel() {
    this._stateService.go('udb.billPay.pay');
  }

  private updateRecipient(): void {
    this.isLoading = true;
    this.errorMessage = null;
    let recipientVm = this.toRecipientVm(this.recipient);
    this._billPayService
      .updateRecipient(recipientVm)
      .then(res => {
        this._billPayRouterHelper.setRecipient(res.data);
        this._stateService.go('udb.billPay.editRecipientConfirmation');
      })
      .catch(() => {
        this.errorMessage =
          'Failed to update recipient information. Please try again. ' +
          'If you are still having problems, please contact customer service.';
      })
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  private removeRecipient(): void {
    this.isLoading = true;
    this.errorMessage = null;
    this._billPayService
      .deleteRecipient(this.recipient.payeeId)
      .then(() => {
        this._stateService.go('udb.billPay.pay');
      })
      .catch(err => {
        this.errorMessage = err.data?.message;
      })
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  private getPaymentAccountHint(recipient: Recipient): void {
    let hint: string;
    this.accountTooltip = `Check your invoice or billing statement to find your account number.`;
    this._billPayService
      .searchRecipients(recipient.displayName)
      .then(res => {
        let biller = res.data.find(b => b.billerId == recipient.billerId) as Biller;
        hint = biller ? biller.paymentAccountHint : '';
      })
      .catch(() => {
        hint = null;
      })
      .finally(() => {
        this.accountTooltip += hint ? `<b> Hint:</b> ${hint}` : '';
        this.isTooltipReady = this.isElectronic;
        this.changeDetectorRef.markForCheck();
      });
  }

  private toRecipientVm(recipient: Recipient): RecipientUpdateVm {
    let vm = recipient as RecipientUpdateVm;
    let index = vm.userTags.indexOf('Favorite');

    vm.billerName = recipient.displayName;
    if (this.isFavorite && index < 0) {
      vm.userTags.push('Favorite');
    } else if (!this.isFavorite && index >= 0) {
      vm.userTags.splice(index, 1);
    }
    return vm;
  }

  private startAddress(): void {
    this.recipient.billingAddress = {
      billerAddressId: 0,
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateCode: '',
      zipCode: '',
      country: '',
    };
  }
}
