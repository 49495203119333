<app-view-header [titleText]="stock?.stockName">
  <app-dot-loader *ngIf="!stock || (accountId && !account)"></app-dot-loader>
  <p class="account-name-banner" *ngIf="account">Self-Directed Trading - {{ account.accountNumber | slice: -4 }}</p>
</app-view-header>
<div class="container" *ngIf="stock">
  <div class="row flex-row-reverse">
    <p class="last-updated">Last Updated {{ lastUpdatedDate | date: 'MM/dd/yy h:mmaa' }}</p>
  </div>
  <div class="row">
    <button id="backToOverviewBtn" type="button" class="link d-flex align-items-center" (click)="goBackToSDT()">
      <app-svg-icon class="overview__icon mr-2" [iconName]="icons.backIcon" size="1.3em"></app-svg-icon>
      <span class="overview__text">Self-Directed Trading Overview</span>
    </button>
  </div>
  <div class="row mt-4">
    <div class="col-12 stock-symbol">{{ stock.symbol }}</div>
    <div class="col-6 stock-name">{{ stock.stockName }}</div>
    <div class="col-6 stock-trade-buttons" *ngIf="stock.type !== equityType.MutualFund || isSDTMutualFundsActive">
      <div class="row justify-content-end">
        <button
          *ngIf="portfolioOverview?.positions?.length > 0"
          class="col-5 mr-4 btn-cancel"
          id="btnSell"
          (click)="goToTradeStock(tradeTypes.Sell)"
          [ngClass]="{ disabled: isShowExchangeAgreement }"
          [disabled]="isShowExchangeAgreement"
        >
          Sell
        </button>
        <button
          class="col-5 btn-ok"
          id="btnBuy"
          (click)="goToTradeStock(tradeTypes.Buy)"
          [ngClass]="{ disabled: isShowExchangeAgreement }"
          [disabled]="isShowExchangeAgreement"
        >
          Buy
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
      <p class="d-block data-label mb-1">Total Value</p>
      <p class="d-block data-value total-value">{{ stock.marketValue | currency }}</p>
    </div>
    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
      <p class="data-label mb-1">Day Change</p>
      <div class="day-change" [ngClass]="dayChangeConfig?.cssClass">
        <div class="d-flex align-items-center">
          <app-svg-icon
            class="day-change-label-btn-icon"
            [iconName]="dayChangeConfig?.iconName"
            [iconColor]="dayChangeConfig?.iconColor"
            width="1.15em"
            height="1.15em"
          ></app-svg-icon>
          <span class="day-change-percentage ml-2">{{ stock.dayChangePercent | numberAbs: '1.0-2' }}%</span>
          <span class="day-change-amount ml-2">
            <span *ngIf="stock.dayChange > 0">+</span>
            <span>{{ stock.dayChange | currency }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-dot-loader *ngIf="isLoadingStocks && !isShowExchangeAgreement">Loading Historic Stocks</app-dot-loader>
  <div class="row mt-4" *ngIf="!isLoadingStocks && !isShowExchangeAgreement">
    <app-stock-graph id="stockGraph" [data]="stocks" [updateData]="updateData" [sortable]="isActive"></app-stock-graph>
  </div>
  <div class="text-right mt-3" *ngIf="!isShowExchangeAgreement">
    <ul>
      <li *ngFor="let sortable of sortableByDates" (click)="changeGraph(sortable.value)">
        <span class="menu" [class.active]="isActive === sortable.value">
          {{ sortable.label }}
        </span>
      </li>
    </ul>
  </div>
  <div class="row mt-4" *ngIf="isShowExchangeAgreement">
    <app-exchange-agreement
      id="exchangeAgreement"
      class="col-12"
      [accountNumber]="account.accountNumber"
      [accountId]="accountId"
      [stockId]="stockSymbol"
      (exchangeAgreementState)="exchangeAgreementState($event)"
    ></app-exchange-agreement>
  </div>
  <div class="row" *ngIf="hasPositions">
    <div class="col-12">
      <app-position [accountId]="accountId" [stockId]="stockSymbol"></app-position>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-stats [stats]="stock"></app-stats>
    </div>
  </div>
  <div class="row">
    <app-dot-loader [showWhen]="isLoadingCompany"></app-dot-loader>
    <div class="col-12" *ngIf="!isLoadingCompany">
      <app-stock-company [data]="company"></app-stock-company>
    </div>
  </div>
  <div class="mt-5">
    <app-dot-loader *ngIf="isLoadingOrders">Loading Orders</app-dot-loader>
    <app-recent-orders [orders]="orders" [hidden]="isLoadingOrders" [accountId]="accountId"></app-recent-orders>
  </div>
  <div class="mt-5">
    <app-feature-link></app-feature-link>
  </div>
</div>
