<div class="edit-recipient">
  <div class="header-section-title text-center hide-responsive">
    <h1>Edit {{ recipient?.displayName }}</h1>
  </div>

  <div class="container mobile-container">
    <div class="frow center">
      <h4 class="text-center">Use the form below to make changes to this recipient.</h4>
    </div>
    <hr *ngIf="isElectronic" class="show-responsive horizontal-line-top" />
    <div class="frow center">
      <app-alert-notification
        *ngIf="errorMessage"
        message="errorMessage"
        type="'error'"
        on-close="errorMessage = null"
        class="error-message"
      ></app-alert-notification>

      <form #checkRecipientForm="ngForm" class="fcol-sm-6" (ngSubmit)="validateAccount()">
        <div class="form-group frow">
          <div
            [ngClass]="{
            'has-error-pv': !isElectronic && !recipient?.displayName,
            'fcol-sm-6': !isElectronic,
            'padding-left': !isElectronic,
            'form-group': isElectronic,}"
          >
            <label class="uk-label">Recipient</label>
            <span *ngIf="isElectronic" [textContent]="recipient?.displayName" class="read-only"></span>
            <input
              *ngIf="!isElectronic"
              type="text"
              class="uk-input input-mobile-recipient"
              #displayName="ngModel"
              name="displayName"
              [(ngModel)]="recipient.displayName"
              [ngClass]="{ red: !displayName.valid }"
              maxlength="40"
              required
            />
            <span class="text-error" *ngIf="!isElectronic && !recipient?.displayName">
              Please enter Recipient's Name
            </span>
          </div>
          <div *ngIf="isElectronic" class="fcol-sm-12 show-responsive">
            <hr class="horizontal-line-bottom" />
          </div>
          <div
            [ngClass]="{
              'fcol-sm-6': !isElectronic,
              'padding-right': !isElectronic,
              frow: isElectronic
            }"
          >
            <label class="uk-label">Nickname (Optional)</label>
            <input type="text" class="uk-input" name="nickName" [(ngModel)]="recipient.nickName" maxlength="40" />
          </div>
        </div>

        <div class="form-group">
          <input type="checkbox" id="favorite" name="favorite" [(ngModel)]="isFavorite" />
          <label for="favorite">Mark as Favorite Recipient</label>
          <app-tooltip [content]="favoriteTooltip" theme="olb2"></app-tooltip>
        </div>

        <div *ngIf="!isElectronic" class="edit-address">
          <div
            class="form-group"
            [ngClass]="{ 'has-error-pv': addressLine1.errors || !recipient?.billingAddress?.addressLine1 }"
          >
            <label class="uk-label">Street Address</label>
            <input
              type="text"
              class="uk-input"
              #addressLine1="ngModel"
              name="addressLine1"
              [(ngModel)]="recipient.billingAddress.addressLine1"
              [ngClass]="{ red: !addressLine1.valid }"
              maxlength="40"
              pattern="^[a-zA-Z0-9#\/.,\s]*$"
              required
            />

            <span class="text-error" *ngIf="!recipient?.billingAddress?.addressLine1">
              Please enter Street Address
            </span>
            <span class="text-error" *ngIf="addressLine1.errors && recipient?.billingAddress?.addressLine1">
              The Street Address has some invalid characters
            </span>
          </div>

          <div class="form-group" [ngClass]="{ 'has-error-pv': addressLine2.errors }">
            <label class="uk-label">Street Address 2 (Optional)</label>
            <input
              type="text"
              class="uk-input"
              #addressLine2="ngModel"
              name="addressLine2"
              [(ngModel)]="recipient.billingAddress.addressLine2"
              maxlength="40"
              pattern="^[a-zA-Z0-9#\/.,\s]*$"
            />
            <span class="text-error" *ngIf="addressLine2.errors && recipient?.billingAddress.addressLine2">
              The Street Address has some invalid characters
            </span>
          </div>

          <div class="form-group fcol-cs-6 padding-left" [ngClass]="{ 'has-error-pv': zipCode.errors }">
            <label class="uk-label">Zip Code</label>
            <input
              type="text"
              class="uk-input"
              [(ngModel)]="recipient.billingAddress.zipCode"
              id="zipCode"
              name="zipCode"
              #zipCode="ngModel"
              (change)="fillCityAndState()"
              maxlength="5"
              pattern="\d{5}"
              [ngClass]="{ red: zipCode.errors }"
              required
            />
            <span class="text-error" *ngIf="zipCode.errors">
              Please enter 5 digit Zip Code
            </span>
          </div>

          <div class="form-group frow">
            <div class="fcol-sm-8 input-mobile-city padding-left" [ngClass]="{ 'has-error-pv': city.errors }">
              <label class="uk-label">City</label>
              <input
                type="text"
                class="uk-input"
                #city="ngModel"
                name="city"
                [(ngModel)]="recipient.billingAddress.city"
                [ngClass]="{ red: city.errors }"
                maxlength="40"
                required
              />
              <span class="text-error" *ngIf="city.errors">
                Please enter City
              </span>
            </div>

            <div class="fcol-sm-4 input-mobile-state padding-right" [ngClass]="{ 'has-error-pv': state.errors }">
              <label class="uk-label">State</label>
              <select
                class="uk-input"
                [(ngModel)]="recipient.billingAddress.stateCode"
                name="state"
                #state="ngModel"
                [ngClass]="{ red: state.errors }"
                required
              >
                <option value="{{ state.key }}" *ngFor="let state of states | keyvalue">
                  {{ state.value + ' (' + state.key + ')' }}
                </option>
              </select>
              <span class="text-error" *ngIf="state.errors">
                Please enter State
              </span>
            </div>
          </div>
        </div>

        <label *ngIf="isElectronic" class="uk-label">Account Number</label>
        <app-tooltip *ngIf="isTooltipReady" [content]="accountTooltip" theme="olb2"></app-tooltip>
        <div class="form-group" [ngClass]="{ 'has-error': isElectronic && invalidAccountMessage }" *ngIf="isElectronic">
          <input
            type="text"
            class="uk-input"
            [(ngModel)]="recipient.paymentAccount"
            maxlength="50"
            name="paymentAccount"
          />
          <span
            class="text-error"
            *ngIf="isElectronic && invalidAccountMessage"
            [textContent]="invalidAccountMessage"
          ></span>
        </div>
        <div class="form-group" *ngIf="!isElectronic">
          <label class="uk-label">Account/Reference Number/Memo (Optional)</label>
          <input
            type="text"
            class="uk-input"
            [(ngModel)]="recipient.paymentAccount"
            maxlength="40"
            name="paymentAccount2"
          />
        </div>

        <div *ngIf="isElectronic" class="form-group">
          <hr class="show-responsive" />
          <label class="uk-label">Address</label>
          <span [innerHTML]="address" class="read-only"></span>
          <hr class="show-responsive" />
        </div>

        <app-dot-loader *ngIf="isLoading">Processing</app-dot-loader>

        <div class="frow center form-buttons hide-responsive">
          <button (click)="editCancel()" class="uk-btn primary outline lg" type="button" [disabled]="isLoading">
            Cancel
          </button>
          <button class="uk-btn secondary solid lg" type="submit" [disabled]="checkRecipientForm.invalid || isLoading">
            Save Changes
          </button>
        </div>
        <div class="frow center form-mobile-buttons show-responsive">
          <button
            class="uk-btn secondary solid lg responsive-button"
            type="submit"
            [disabled]="checkRecipientForm.invalid || isLoading"
          >
            Save Changes
          </button>
          <button
            (click)="editCancel()"
            class="uk-btn primary outline lg responsive-button"
            type="button"
            [disabled]="isLoading"
          >
            Cancel
          </button>
        </div>
        <div class="fcol-sm-12 show-responsive">
          <hr class="horizontal-line-bottom" />
        </div>
        <div class="frow center form-buttons">
          <a class="delete link" (click)="deleteRecipient()">Delete Recipient</a>
        </div>
      </form>
    </div>
  </div>
</div>
