import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { OlbSettings } from '@core/models';
import { Observable } from 'rxjs';
import { DocumentDetail } from '@app/accounts/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AxosClearingDocumentsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'axosclearing');
  }

  getDocuments(accountNumber: string): Observable<DocumentDetail[]> {
    return this.get<DocumentDetail[]>(`accounts/${accountNumber}/documents`).pipe(map(result => result.data));
  }
}
