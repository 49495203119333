import { createAction, props } from '@ngrx/store';

export const getRIAUserFlag = createAction('[RIAUser] Get Flag');
export const getRIAUserFlagSuccess = createAction('[RIAUser] Get Flag Success', props<{ isRIAUserflag: boolean }>());
export const getRIAPilotFlagSuccess = createAction(
  '[RIAUser] Get RIA Pilot Flag Success',
  props<{ isRIAPilotActive: boolean }>()
);
export const getRIAHasNoAxosAccounts = createAction('[RIAUser] Validate if no axos accounts for user');
export const getRIAHasNoAxosAccountsSuccess = createAction(
  '[RIAUser] Get RIA Has Axos Accounts Success',
  props<{ userHasNoAxosAccounts: boolean }>()
);
