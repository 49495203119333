import { InjectionToken, Provider } from '@angular/core';
import {IAccountsService} from "services/typings/IAccountsService";

class CustomInjectionToken<T=any> extends InjectionToken<T> {
    readonly injectionId: string;
    constructor(data: string) {
        super(data);
        this.injectionId = data;
    }
}

export const ACCOUNTS_SERVICE_HELPER_TOKEN = new CustomInjectionToken<IAccountsService>('accountsService');
export const SERVICE_HELPER_TOKEN = new CustomInjectionToken<IServiceHelper>('serviceHelper');

type Injector = {
    get: <T= any>(id: string) => T;
};

export const ACCOUNTS_SERVICE_PROVIDERS: Provider[] = [
    {
        provide: ACCOUNTS_SERVICE_HELPER_TOKEN,
        useFactory: (injector: Injector) => injector.get<IAccountsService>(ACCOUNTS_SERVICE_HELPER_TOKEN.injectionId),
        deps: ['$injector'],
    },
    {
        provide: SERVICE_HELPER_TOKEN,
        useFactory: (injector: Injector) => injector.get<IServiceHelper>(SERVICE_HELPER_TOKEN.injectionId),
        deps: ['$injector'],
    },
];