import { calculateGradientPercentage } from '../';
/**
 * creates a {@link CanvasGradient} based on a given
 * {@link HTMLCanvasElement} using its context and height
 * requires an {@link Array} of type string with at least 2 items
 * to build the gradient if not will fall back to transparent black color in string type
 * canvasElement {@link HTMLCanvasElement}
 * gradientSteps {@link Array} of strings
 * @returns an instance of {@link CanvasGradient} or string
 */
export function createGradientBackground(
  canvasElement: HTMLCanvasElement,
  gradientSteps: Readonly<string[]> = []
): CanvasGradient | string {
  if (gradientSteps.length < 2) {
    return 'rgba(0,0,0,0)';
  }
  const fixedHeight = 270;
  const ctx = canvasElement.getContext('2d');
  ctx.canvas.height = fixedHeight;
  const gradient = ctx.createLinearGradient(0, 0, 0, canvasElement.height);
  gradientSteps.forEach((color, index, arr) => {
    gradient.addColorStop(calculateGradientPercentage(arr, index), color);
  });

  return gradient;
}
