import { NgModule } from '@angular/core';
import { ShareAccountDetailsModule } from '@app/Areas/AAS/features/share-account-details/share-account-details.module';

import { AccountsSharedModule } from '../accounts-shared/accounts-shared.module';
import { SignatureCardModule } from '../signature-card/signature-card.module';
import { loanComponents } from './components';
import { loanViews } from './views';

@NgModule({
  imports: [AccountsSharedModule, SignatureCardModule, ShareAccountDetailsModule],
  declarations: [...loanComponents, ...loanViews],
  exports: [...loanComponents, ...loanViews],
})
export class LoansModule {}
