import { Inject } from '../../decorators/decorators';

@Inject('$stateProvider')
export class SchedulerTransfersConfig {
  constructor($stateProvider: ng.ui.IStateProvider) {
    $stateProvider.state('udb.transfers.schedulerTransfers', {
      url: '/SchedulerTransfers',
      views: {
        'transfer-content': {
          template: `<app-scheduler-transfers> </app-scheduler-transfers>`,
        },
        // 'transfer-content': {
        //   templateUrl: 'transfers/scheduler-transfers/scheduler-transfers.tpl.html',
        //   controller: SchedulerTransfersController,
        //   controllerAs: '$ctrl',
        // },
      },
    });
  }
}
