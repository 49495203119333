import * as angular from 'angular';
import { Inject } from '../../../decorators/decorators';

@Inject('$sanitize')
export class ModalComponentController {
  public modalName: string;
  public icon: string;
  public theme: string;

  public header: string;
  public content: string;
  public okText: string;
  public cancelText: string;
  public size: string;
  public okFunction: Function;
  public cancelFunction: Function;
  public closeText: string;
  public closeButton: boolean;

  public hasHeader: boolean;
  public hasOkBtn: boolean;
  public hasCancelBtn: boolean;
  public hasIcon: boolean;
  public hasCloseText: boolean;
  public canGoBack: boolean;
  public okDisabled: boolean;
  constructor() {
    this.icon = 'check';
    this.theme = 'success';
    this.size = 'md';
  }

  /** Initializes the controller. */
  public $onInit(): void {
    this.theme = angular.isUndefined(this.theme) ? 'success' : this.theme;

    this.closeButton = !angular.isUndefined(this.closeButton);

    this.hasHeader = !angular.isUndefined(this.header) || this.header == '';

    this.hasOkBtn = !angular.isUndefined(this.okText) || this.okText == '';

    this.hasCancelBtn = !angular.isUndefined(this.cancelText) || this.cancelText == '';

    this.hasIcon = !angular.isUndefined(this.icon) || this.icon == '';

    this.hasCloseText = !angular.isUndefined(this.closeText) || this.closeText == '';
    this.okDisabled = !angular.isUndefined(this.okDisabled) || false;
  }

  public ok() {
    if (this.okFunction) {
      this.okFunction();
    }
    //workaround TODO: inject modal Service
    $('#' + this.modalName)['modal']('hide');
  }

  public cancel() {
    if (this.cancelFunction) {
      this.cancelFunction();
    }
    $('#' + this.modalName)['modal']('hide');
  }
}
