import * as Joi from 'joi';
const zipCodeRegExp = new RegExp(/\b\d{5}\b/);
const stateCodeRegExp = new RegExp(/\b[A-Z]{2}\b/);
const ssnRegExp = new RegExp(/\b\d{4}\b/);

function commonValidationMessages(field: string) {
  return {
    'string.empty': `${field} is required.`,
    'string.pattern.base': `${field} is not valid, please enter a valid answer.`,
  };
}

export const submittableFormSchema = Joi.object({
  id: Joi.string().allow(''),
  targetname: Joi.string().required().messages(commonValidationMessages('Income Source')),
  street: Joi.string().required().messages(commonValidationMessages('Address')),
  street2: Joi.string().allow(''),
  city: Joi.string().required().messages(commonValidationMessages('City')),
  stateCode: Joi.string()
    .required()
    .regex(stateCodeRegExp)
    .messages(commonValidationMessages('State Code')),
  zipcode: Joi.string()
    .required()
    .regex(zipCodeRegExp)
    .messages(commonValidationMessages('Zip Code')),
  ssn: Joi.string().length(4).regex(ssnRegExp).required().messages(commonValidationMessages('SSN')),
  country: Joi.string().required().messages(commonValidationMessages('Country')),
  incometype: Joi.number().required()
});

export const submittablePartialFormSchema = Joi.object({
  id: Joi.string().allow(''),
  targetname: Joi.string().required().messages(commonValidationMessages('Income Source')),
  ssn: Joi.string().length(4).regex(ssnRegExp).required().messages(commonValidationMessages('SSN')),
  incometype: Joi.number().required()
});
