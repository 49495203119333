import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageRound',
})
export class PercentageRoundPipe implements PipeTransform {
  transform(value: number, applyFormat: boolean = false): string {
    if (value !== null && value !== 0 && value < 0.01 && value > -0.01) {
      return '<1';
    }

    if (value === 0) {
      return '0';
    }

    if (value === null || isNaN(value)) {
      return '--';
    }

    const result = Math.abs(Math.round(value * 100));

    return applyFormat ? result.toLocaleString('en-US') : result.toString();
  }
}
