<h5>Account</h5>

<ng-template #nicknameEditor>
  <div class="loan-nickname-editor">
    <input
      #inputNickName
      id="input-nickname"
      class="uk-input nickname-input"
      type="text"
      maxlength="30"
      autofocus
      [value]="details.nickname"
      (blur)="inputBlur()"
      (keydown.enter)="updateNickname(inputNickName.value)"
    />

    <button
      id="edit-nickname"
      class="btn-ok button--sm ml-auto mt-3"
      (mousedown)="isMouseDown = true"
      (mouseup)="isMouseDown = false"
      (click)="updateNickname(inputNickName.value)"
    >
      {{ isSaving ? 'Saving...' : 'Save' }}
    </button>
  </div>
</ng-template>

<div>
  <label *ngIf="!editMode">Loan Nickname</label>
  <p class="loan-nickname" *ngIf="!editMode; else nicknameEditor">
    {{ details.nickname }}
    <button type="button" class="edit-icon" (click)="pencilClick()">
      <app-svg-icon [iconName]="iconName"></app-svg-icon>
    </button>
  </p>
</div>

<div>
  <label>Loan Type</label>
  <p class="statement-description">{{ details.statementDescription }}</p>
</div>

<div>
  <label>Loan Number</label>
  <p class="account-number">{{ details.accountNumber }}</p>
</div>

<div>
  <label>Date Opened</label>
  <p class="date-opened">{{ details.dateOpened | date: dateFormat }}</p>
</div>

<div>
  <label>Maturity Date</label>
  <p class="maturity-date">{{ details.maturityDate | date: dateFormat }}</p>
</div>

<div>
  <label>Interest Rate</label>
  <p class="interest-rate">{{ details.accountInterest.interestRate | percent: '1.3' }}</p>
</div>

<div *ngIf="account.productType.toLowerCase() == 'sbloc'">
  <label>Outstanding Balance</label>
  <p class="outstanding-balance">
    {{ details.accountPayment.outstandingBalance | currency }}
  </p>
</div>

<div *ngIf="account.productType.toLowerCase() == 'heloc' || account.productType.toLowerCase() == 'sbloc'">
  <label>Total Line Of Credit</label>
  <p class="line-of-credit">
    {{ details.accountLineOfCreditDetails.lineOfCreditLimit | currency }}
  </p>
</div>

<div *ngIf="account.productType.toLowerCase() == 'sbloc'">
  <label>Principal Balance</label>
  <p class="outstanding-balance">
    {{ details.accountPayment.outstandingBalance | currency }}
  </p>
</div>

<div *ngIf="account.productType.toLowerCase() == 'heloc' || account.productType.toLowerCase() == 'sbloc'">
  <label>Available Credit</label>
  <p class="line-of-credit-remaining">
    {{ details.accountLineOfCreditDetails.lineOfCreditRemaining | currency }}
  </p>
</div>

<app-signature-card-option
  *ngIf="hasSignatureCard"
  [accountId]="account.id"
  [accountNumber]="account.accountNumber"
  [signatureCardStatus]="details.signatureCardStatus"
  [signatureCardStatusDescription]="details.signatureCardStatusDescription"
></app-signature-card-option>
