<div class="feature-flag-off-container">
  <div class="feature-flag-off-header">
    <img src="../../../../assets/axos/logos/axos-logo.png" class="logo" alt="Axos" />

    <div class="navigation">
      <svg (click)="goDashboard()" class="svg-icon" aria-hidden="true" stroke="#fff">
        <use xlink:href="assets/svg/icons/navigation.svg#navigation-chevron-left"></use>
      </svg>
      <span (click)="goDashboard()">Back to Securities-Backed Line of Credit Page</span>
    </div>
  </div>

  <div class="feature-flag-off-body">
    <img src="../../../../assets/img/icons/sbloc/Illustration-Area-2.svg" class="Illustration" alt="Illustration" />

    <div class="body-text">
      <h1>SBLOC Unavailable</h1>
      <p>
        We’ve run into an issue and are unable to provide your offer at this time. We’re sorry for the inconvenience.
        Please try again later.
      </p>
    </div>
    <div class="feature-flag-off-footer">
      <button class="PrimaryButton" (click)="goDashboard()" type="button">
        Back to Dashboard
      </button>
    </div>
  </div>
</div>
