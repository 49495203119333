import { OmnichannelService } from '@legacy/services/omnichannel.service';

export function omnichannelServiceFactory($injector: any) {
  return $injector.get('omnichannelService');
}

export const omnichannelServiceProvider = {
  provide: OmnichannelService,
  useFactory: omnichannelServiceFactory,
  deps: ['$injector'],
};
