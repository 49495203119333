import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { supportDepositsLoansComponents } from './components';

@NgModule({
  declarations: [...supportDepositsLoansComponents],
  exports: [...supportDepositsLoansComponents],
  imports: [CommonModule],
})
export class SupportDepositsLoansModule {}
