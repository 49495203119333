import { createSelector } from '@ngrx/store';

import { getUserProfile } from '../user-profile.state';

export const getCompanyDetail = createSelector(
  getUserProfile,
  state => state.profileDetails.companyDetails
  );
export const getCompanyProfiles = createSelector(
  getUserProfile,
  state => state.profileDetails.accountProfiles
  );
