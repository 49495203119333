import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';
import { IStateParamsService, IStateService } from 'angular-ui-router';

import { getProfileDetails } from '@app/user-profile/store/selectors';
import { maskEmail, maskPhone } from '@app/utils';
import { AuthMethod } from '@core/enums';
import { MultiFactorRequest } from '@core/models';
import { IMultifactorService } from '@legacy/services/typings/IMultifactorService';
import { UploadService } from '@legacy/services/upload.service';
import { WireTransfer } from '@legacy/transfers/typings/WireTransfer';

import { Inject } from '../../../decorators/decorators';

@Inject(
  '$rootScope',
  '$state',
  'multifactorService',
  'serviceHelper',
  'popups',
  '$stateParams',
  '$uibModal',
  'uploadService',
  'env',
  'ngrxStore'
)
export class WireMultiFactorController {
  phone: string;
  email: string;
  isLoading: boolean;
  wiretransferData: any;
  wiretransfer: WireTransfer;
  emailAvailable = false;
  smsAvailable = false;
  isSendingCode = false;
  onCodeSent: Function;
  authMethod = AuthMethod;
  phoneNumber: string;
  showAlert = false;
  isCodeSent = false;
  errorMessage = '';
  showEmailBtn = true;
  showSuccessView = false;
  formatedPurpose = '';
  formatedBank = '';
  brand: string;
  brandName: string;
  time: string;

  private _defaultSmsBtnMsg = '- N/A';
  private _defaultEmailBtnMsg = '- N/A';
  private dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
  };

  private timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Los_Angeles',
  };
  private subsink = new SubSink();

  constructor(
    private _root: ng.IRootScopeService,
    private state: IStateService,
    private multifactorService: IMultifactorService,
    private serviceHelper: IServiceHelper,
    private popups: IPopups,
    private stateParams: IStateParamsService,
    private uibModal: ng.ui.bootstrap.IModalService,
    private uploadService: UploadService,
    private env: OlbSettings,
    private store: Store
  ) {}

  $onInit(): void {
    this.wiretransferData = this.stateParams['wiretransfer'];
    this.wiretransfer = this.wiretransferData.transfer.wireTransferDetails;

    this.phone = this._defaultSmsBtnMsg;
    this.email = this._defaultEmailBtnMsg;
    this.brand = this.env.brand;
    this.brandName = this.env.brandName.indexOf('Bank') >= 0 ? this.env.brandName : this.env.brandName + ' Bank';

    this.subsink.sink = this.store.select(getProfileDetails).subscribe(data => {
      const maskedCellPhone = maskPhone(data.cellPhone1.number);
      if (maskedCellPhone) {
        this.phone = 'to ' + maskedCellPhone;
        this.smsAvailable = true;
      }

      const maskedEmail = maskEmail(data.primaryEmail);
      if (maskedEmail) {
        this.email = 'to ' + maskedEmail;
        this.emailAvailable = true;
      }
      this.phoneNumber = this._root['brandProperties']['Contact_PhoneNumber'];
      if (!this.emailAvailable && !this.smsAvailable) {
        this.showAlert = true;
      }
    });

    document.querySelector('.header-section-title').scrollIntoView();
  }

  $onDestroy(): void {
    this.subsink.unsubscribe();
  }

  continue(type: AuthMethod): void {
    this.isLoading = true;

    this.errorMessage = '';

    const request: MultiFactorRequest = {
      username: window.sessionStorage.getItem('username'),
      authenticationMethod: type,
    };
    const isEmail = request.authenticationMethod == AuthMethod.Email;

    this.multifactorService
      .challenge(request)
      .then(res => {
        this.isCodeSent = true;
        window.sessionStorage.setItem('wireAuthRequest', JSON.stringify(request));
        if (!isEmail && res.data.otp) {
          this.popups.showAlert('', `Your OTP is ${res.data.otp} (Disclaimer: Testing purposes only!!!)`, 'info');
        }
      })
      .catch(() => {
        if (isEmail) {
          this.showEmailBtn = false;
        }

        this.serviceHelper.errorHandler.bind(this.serviceHelper);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  goBack() {
    this.isSendingCode = false;
    this.isCodeSent = false;
  }

  codeValidated() {
    this.isLoading = true;
    this.uploadService
      .upload('transfers/wire', this.wiretransferData)
      .then(() => {
        this.formatedPurpose =
          this.wiretransfer.purpose.length > 25
            ? this.wiretransfer.purpose.slice(0, 25) + '...'
            : this.wiretransfer.purpose;
        this.formatedBank =
          this.wiretransfer.bankDetails.bank.length > 23
            ? this.wiretransfer.bankDetails.bank.slice(0, 23) + '...'
            : this.wiretransfer.bankDetails.bank;
        const now = new Date();
        const date = new Intl.DateTimeFormat('en-US', this.dateOptions).format(now);
        const time = new Intl.DateTimeFormat('en-US', this.timeOptions).format(now);
        this.time = `${date} at ${time} PT`;

        this.showSuccessView = true;
        const element = document.getElementsByClassName('header-section-title')[0] as HTMLElement;
        element.scrollIntoView();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  displayAboutModal() {
    this.uibModal.open({
      controllerAs: '$ctrl',
      controller: 'WireTransfersAboutController',
      templateUrl: 'transfers/wire-transfers/wire-transfers-about-modal/wire-transfers-about-modal.tpl.html',
      windowClass: 'modal-service',
    });
  }

  /** Redirects the user to the recipient page */
  goToRecipientDetails() {
    this.state.go('udb.transfers.wireTransfers.recipientDetails', {
      wiretransfer: this.wiretransfer,
    });
  }

  /** Redirects the user to the Wire Transfer page */
  redirectMainWire() {
    this.state.go('udb.transfers.wireTransfers', { needsReset: true });
  }

  /** Redirects the user to the Inbox page in Message Center */
  redirectMsgCenter() {
    this.state.go('udb.messages.inbox', {});
  }
}
