const alwaysAllowed = ['Alt', 'Backspace', 'Control', 'Delete', 'Enter', 'Shift', 'Tab'];
const arrowKeyNames = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
const clipboardLetters = ['a', 'c', 'v', 'x'];
const defaultModifierKeys = { arrows: true, clipboard: true };
type Options = Partial<typeof defaultModifierKeys>;

/**
 *  @description function to simplify input filter in constrained fields
 *  @param KeyboardEvent event
 *  @param RegExp matcher
 *  @param allowClipboardKeys default to true
 *  @example
 *  ```@HostListener('keydown', ['$event'])
 *  validateNumbersOnly(keyboardEvent: KeyboardEvent) {
 *    return keyDownValidate(keyboardEvent, /^\d+$/, true);
 *  }```
 */
export function keyDownValidate(event: KeyboardEvent, matcher: RegExp, opts: Options = {}) {
  if (alwaysAllowed.includes(event.key)) {
    return undefined;
  }

  if (arrowKeyNames.includes(event.key)) {
    return allowArrow(event, opts);
  }

  if (event.ctrlKey && clipboardLetters.includes(event.key.toLowerCase())) {
    return allowClipboard(event, opts);
  }

  if (!matcher.test(event.key)) {
    return event.preventDefault();
  }
}

function allowArrow(event: KeyboardEvent, opts: Record<string, boolean>) {
  const allow = opts.hasOwnProperty('arrows') ? opts.arrows : defaultModifierKeys.arrows;

  return !allow ? event.preventDefault() : undefined;
}

function allowClipboard(event: KeyboardEvent, opts: Record<string, boolean>) {
  const allow = opts.hasOwnProperty('clipboard') ? opts.clipboard : defaultModifierKeys.clipboard;

  return !allow ? event.preventDefault() : undefined;
}
