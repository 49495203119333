import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import {
  AccountAggregationService
} from '@legacy/services/account-aggregation.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-update-nickname',
  templateUrl: './update-nickname.component.html',
  styleUrls: ['./update-nickname.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateNicknameComponent implements OnInit {
  @ViewChild('updatenickname') nicknameElement: ElementRef;
  @Input() title: string;
  @Input() content: string;
  @Input() accountId: number;
  @Output() updateNickName = new EventEmitter();

  /** Nickname linked to the view */
  nickname: string;
  /** Indicates if it's editing the nickname */
  isEditing = false;
  /** Flag to validate if the user has pressed the Save button */
  mouseDown = false;
  isSaving = false;

  constructor(
    private accountAggregationService: AccountAggregationService,
    private serviceHelper: ServiceHelper,
    private cachedAccountsService: CachedAccountsService,
    private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.nickname = this.content;
  }

  editNickname(): void {
    this.isEditing = !this.isEditing;
    if (this.nickname?.length > 30) {
      this.nickname = this.nickname.substring(0, 30);
    }
    this.ref.detectChanges();
    this.nicknameElement.nativeElement.focus();
  }
  cancelNicknameUpdate(){
    if (!this.mouseDown) {
      this.nickname = this.content;
      this.isEditing = !this.isEditing;

      return;
    }

    if (this.mouseDown)
    {
      this.mouseDown = false;
      this.saveAccountNickname();
    }
  }
  saveAccountNickname(){
    if (!this.nickname || this.nickname === this.content) {
      this.nickname = this.content;
      this.isEditing = false;

      return;
    }

    this.isSaving = true;
    this.accountAggregationService
      .updateAccountNickname(this.accountId.toString(), this.nickname)
      .then(() => {
        this.cachedAccountsService.updateAggregatedAccountNickname(this.accountId, this.nickname);
        this.content = this.nickname;
        // next event will update account dropdown
        this.updateNickName.emit();
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
          this.isEditing = false;
          this.isSaving = false;
          this.ref.markForCheck();
      });
  }
}
