import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
  Inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { add, startOfToday } from 'date-fns';
import * as moment from 'moment';

import { PaymentInfo, PayoffDetailsResponse } from '@app/accounts/models';
import { getSettings } from '@app/store/selectors';
import { AppSettings } from '@core/enums';
import { AlertsIcons, CommunicationIcons, UtilityIcons } from '@shared/enums';
import { FeatureFlagService } from 'services/feature-flag.service';
import { GenerateQuoteErrorModalComponent } from '@app/accounts/components/modals';
import { DialogData, dialogConfig } from '@shared/models';
import { DialogService } from '@core/services';
import { ComponentPortal } from '@angular/cdk/portal';
import { SubSink } from '@axos/subsink';
import { TransactionService } from '@legacy/services/transaction.service';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';


@Inject('featureFlagService')
@Component({
  selector: 'app-payment-calculator',
  templateUrl: './payment-calculator.component.html',
  styleUrls: ['./payment-calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCalculatorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() info: PaymentInfo;
  @Input() payoffCalculated: PayoffDetailsResponse;
  @Input() isPayoffFlowActive: boolean;
  @Input() hasPendingTransactions: boolean;
  @Output() calculatePayoff = new EventEmitter<Date>();
  @Output() generateLoanQuote = new EventEmitter<Date>();
  @Output() makePayment = new EventEmitter<void>();

  iconConfig = {
    calendar: {
      size: '1.2rem',
      icon: CommunicationIcons.Calendar,
    },
    check: {
      size: '3rem',
      icon: UtilityIcons.Checkmark,
      fill: 'var(--accent)',
    },
  };
  minDate = new Date();
  maxDate = add(startOfToday(), { days: 10 });
  payoffDate: Date;
  payoffAmount = 0.0;
  appSettings: Record<string, string>;
  isBusinessDays = false;
  isCalculateDisabled = false;
  isGenerateQuoteDisabled = true;
  isPaymentDisabled = false;
  isSchedulePaymentDisabled = true;
  payoffDetail = null;
  isLoading: boolean;
  isLoadingPayLoan = false;
  private currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  private subsink = new SubSink();

  isLoanPayOffFlagActive = false;

  constructor(
    private store: Store,
    private dateHelper: DateHelper,
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private featureFlagService: FeatureFlagService,
    private transactionService: TransactionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.payoffCalculated?.currentValue) {
      this.isLoading = false;

      this.payoffDetail = `<div>
                            <b>Outstanding Balance</b>
                          <div>
                          <div>${this.currency.format(changes.payoffCalculated.currentValue.currentBalance)}</div>
                          <div>
                            <b>Accrued Interest</b>
                          <div>
                          <div>${this.currency.format(changes.payoffCalculated.currentValue.accruedInterest)}</div>
                          <div>
                            <b>Secondary Accrued Interest</b>
                          <div>
                          <div>${this.currency.format(
                            changes.payoffCalculated.currentValue.secondaryAccruedInterest
                          )}</div>
                          <div>
                            <b>Late Charge Due Amount</b>
                          <div>
                          <div>${this.currency.format(changes.payoffCalculated.currentValue.lateChargeDueAmount)}</div>
                          `;
      this.changeDetector.markForCheck();
    }
  }

  ngOnInit(): void {
    this.payoffDate = startOfToday();
    this.getValidDate(this.minDate);
    this.getAppSettings();
    this.isLoanPayOffFlagActive = this.featureFlagService.isLoanPayOffFlagActive();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  dateAllowed = (d: Date): boolean => {
    return this.dateHelper.isValidDate(moment(d));
  };

  daysOff = (minDate: Date, maxDate: Date): number => {
    const totalDays = moment(maxDate).diff(moment(minDate), 'days');
    let daysToAdd = 0;

    for (let i = 1; i <= totalDays; i++) {
      const d = moment(minDate).add(i, 'days');
      if (!this.dateHelper.isValidDate(d)) daysToAdd += 1;
    }

    return daysToAdd;
  };

  calculateLoan(): void {
    if (this.loanDueDateValidation(this.payoffDate)) {
      this.isLoading = true;
      this.isCalculateDisabled = true;
      this.isGenerateQuoteDisabled = false;
      this.isPaymentDisabled = false;
      this.calculatePayoff.emit(this.payoffDate);
    } else {
      this.showDateErrorMessage();
    }
  }

  payLoan(): void {
    this.isPaymentDisabled = true;
    this.isLoadingPayLoan = true;
    this.makePayment.emit(this.payoffDetail);
  }

  generateQuote(): void {
    this.isGenerateQuoteDisabled = true;
    let transDays = 90;
    this.transactionService
      .getTransactionsByAccount(
        this.info?.account?.id,
        moment().subtract(transDays, 'days').toDate(),
        moment().toDate()
      )
      .then(response => {
        if (response?.data?.some(tx => tx?.isPending)) {
          this.showErrorMessage();
        } else {
          this.generateLoanQuote.emit(this.payoffDate);
          this.isSchedulePaymentDisabled = false;
          this.changeDetector.markForCheck();
        }
      });
  }

  showErrorMessage(): void {
    const dialogData = new DialogData({
      title: '',
      icon: AlertsIcons.ExclamationCircle,
      hasOkButton: false,
      component: new ComponentPortal(GenerateQuoteErrorModalComponent),
      params: `You currently have one or more pending transactions for ${this.info?.account?.nickname}. We cannot generate a quote or
      schedule your loan payoff at this time, please try again once the transactions have cleared.`, // this.info?.account?.nickname,
    });
    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;
    dialogConfig.width = '550px';
    this.dialogService.openCustom(dialogConfig);
  }

  showDateErrorMessage(): void {
    const dialogData = new DialogData({
      title: '',
      icon: AlertsIcons.ExclamationCircle,
      hasOkButton: true,
      cancelText: '',
      component: new ComponentPortal(GenerateQuoteErrorModalComponent),
      params: `Your Loan will be more that 90 days past due and will not be eligible for online payments. Please select a previous date.`,
    });
    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;
    dialogConfig.width = '550px';
    this.dialogService.openCustom(dialogConfig);
  }

  startDateChange(): void {
    if (!this.loanDueDateValidation(this.payoffDate)) {
      this.showDateErrorMessage();
    }
    this.isCalculateDisabled = false;
    this.isSchedulePaymentDisabled = true;
    this.isGenerateQuoteDisabled = true;
  }

  private getValidDate(dateToValidate: Date): void {
    const isTodayDateValid = this.dateHelper.isValidDate(moment(dateToValidate)) && this.isPSTvalid(dateToValidate);

    if (isTodayDateValid) {
      this.minDate = dateToValidate;
      this.payoffDate = this.minDate;
      this.changeDetector.markForCheck();
    } else {
      this.getValidDate(moment(dateToValidate).add(1, 'days').toDate());
    }
  }

  private isPSTvalid(dateToValidate: Date): boolean {
    const oneFortyFivePST = moment.tz(dateToValidate, 'America/Los_Angeles').hour(13).minute(45).second(0);
    const dateToValidatePST = moment.tz(dateToValidate, 'America/Los_Angeles');
    return (
      !moment().isSame(dateToValidate, 'day') ||
      (moment().isSame(dateToValidate, 'day') && dateToValidatePST.isBefore(oneFortyFivePST))
    );
  }

  private getAppSettings() {
    this.store
      .select(getSettings)
      .pipe(take(1))
      .subscribe(settings => {
        this.appSettings = settings;
        const payoffDays = this.appSettings[AppSettings.PayoffDays]
          ? Number(this.appSettings[AppSettings.PayoffDays])
          : 10;
        this.maxDate = add(this.payoffDate, { days: payoffDays });
        this.isBusinessDays = this.appSettings[AppSettings.PayoffCalcAllowBusinessDays]
          ? this.appSettings[AppSettings.PayoffCalcAllowBusinessDays].toLowerCase() === 'true'
          : false;

        if (this.isBusinessDays) {
          let startDate = this.minDate;
          let endDate = this.maxDate;
          let daysOffInPeriod = this.daysOff(startDate, endDate);
          let daysToConsider = daysOffInPeriod;
          while (daysOffInPeriod > 0) {
            startDate = endDate;
            endDate = add(endDate, { days: daysOffInPeriod });
            daysOffInPeriod = this.daysOff(startDate, endDate);
            daysToConsider = daysToConsider + daysOffInPeriod;
          }
          this.maxDate = add(this.payoffDate, { days: payoffDays + daysToConsider });
        }
      });
  }

  private loanDueDateValidation(date: Date): boolean {
    const limitDate = moment.utc().subtract(90, 'days');
    return moment(date).isAfter(limitDate);
  }
}
