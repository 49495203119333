<td class="transaction-column date-column" (click)="selectTransaction()">
  <ng-container
    *ngIf="
      transaction.event === TRANSACTION_EVENT.Created || transaction.event === TRANSACTION_EVENT.Canceled;
      else transactionDate
    "
  >
    {{ transaction.subStatusDescription | uppercase }}
  </ng-container>
  <ng-template #transactionDate>
    <ng-container *ngIf="isExecuted; else transactionState">
      {{ transaction.date | date: 'MM/dd/yyyy':'+0000' }}
    </ng-container>
  </ng-template>
  <ng-template #transactionState>
    <div class="transaction-state">
      <mat-icon
        class="icon"
        [svgIcon]="getTransactionCategory(transaction.activityCode, transaction.state).categoryIcon"
      ></mat-icon>
      <p>{{ getTransactionStatus(transaction) }}</p>
    </div>
  </ng-template>
</td>
<td class="transaction-column category-icon-column" (click)="selectTransaction()" hidden="hideCategory">
  <app-svg-icon [iconName]="iconName" width="17.5px" height="14px" iconColor="#1E3860"></app-svg-icon>
</td>
<td class="transaction-column description-column" (click)="selectTransaction()">
  <ng-container *ngIf="!hideCategory; else descriptionNew">
    <ng-container *ngIf="transaction.description">{{ transaction.description | titlecase }}:</ng-container>
    {{ transaction.goal }}
  </ng-container>
  <ng-template #descriptionNew>{{ transaction.description | titlecase }}</ng-template>
</td>
<td class="transaction-column amount-column" (click)="selectTransaction()">
  {{ transaction.amount | currency }}
</td>
<td class="transaction-column options-column">
  <button *ngIf="!hideOptions && isExecuted" class="transactions-arrow" (click)="expand(transaction)">
    <mat-icon
      [svgIcon]="Uk2Tier1NavigationEnum.chevronDown"
      [class]="isExpanded ? 'arrow-open' : 'arrow-close'"
    ></mat-icon>
  </button>
</td>
