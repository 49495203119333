<div class="hero--wrapper">
  <app-toolbar></app-toolbar>
  <banner-component [placeholder]="'content-header'" class="hero"></banner-component>
</div>

<div class="forgot-password">
  <div class="forgot-password__container">
    <div [ngSwitch]="step">
      <form [formGroup]="forgotPasswordForm" (submit)="sendPassword()" *ngSwitchCase="1">
        <div class="forgot-password__form-group">
          <label for="userName" class="uk-label">Username</label>
          <input
            type="text"
            id="userName"
            name="userName"
            aria-label="username"
            class="uk-input"
            formControlName="userName"
            [ngClass]="{ 'invalid-input': isInvalid }"
            required
          />

          <div class="text-error">
            <span *ngIf="isInvalid">{{ errorMessage }}</span>
            <span *ngIf="serverErrorMessage">{{ serverErrorMessage }}</span>
          </div>
        </div>

        <div class="forgot-password__legend">
          <h3>Forgot your password?</h3>
          <p>
            Just enter the username associated with your account and we'll send you a code to reset your password.
            <a id="forgot-username-link" (click)="forgotUsername()" class="cursor-pointer link" href="#">
              Forgot your username?
            </a>
          </p>
        </div>

        <div class="forgot-password__button-container" [hidden]="loading">
          <button id="submit-btn" class="btn-ok button--sm" type="submit">
            Continue
          </button>

          <button id="back-btn" class="btn-cancel button--sm" type="button" (click)="backToLogin()">
            Back
          </button>
        </div>
      </form>

      <div class="text-center" *ngSwitchCase="2">
        <app-multifactor
          [supportPhone]="$supportPhone | async"
          (sendCode)="sendCode($event)"
          subtitle="We're about done. To process your request, we are sending you a verification code to confirm your identity."
        ></app-multifactor>
      </div>
      <div class="text-center" *ngSwitchCase="3">
        <app-access-code
          [otp]="otp"
          [displayRememberDevice]="false"
          [errorMessage]="accessCodeError"
          (back)="step = 2"
          (continue)="validateOtp($event.code)"
          [displayPersonalInfo]="false"
        ></app-access-code>
      </div>
    </div>
  </div>

  <div class="text-center">
    <app-dot-loader [showWhen]="loading"></app-dot-loader>
    <app-chatbot-help-label></app-chatbot-help-label>
  </div>
</div>

<app-footer></app-footer>
