import { UIKitModalConfiguration } from '@uikit/tiles';
import * as angular from 'angular';
import { TilesService } from 'services/tiles.service';
import { ILoanService } from 'services/typings/ILoanService';

import { truncate } from '@app/utils';

import { ProductType } from '../../common/enums/productType.enum';
import { Inject } from '../../decorators/decorators';
import { LoanAccountHelper } from '../../services/loan-account.helper';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';

@Inject(
  '$element',
  '$scope',
  'tilesService',
  'serviceHelper',
  '$rootScope',
  '$state',
  '$filter',
  'loanService',
  'loanAccountHelper'
)
export class NextLoanPaymentController extends BaseTile<NextLoanPaymentTileSettings> {
  title: string;
  loanImgSrc: any;
  hasLoanImg = true;
  labelClass: string;
  dueAmountLabel: string;
  dueDateLabel: string;

  settings: { account?: OlbAccount } = {};
  accounts: OlbAccount[] = [];
  account: OlbAccount = {};

  hasErrored = false;
  isBusy = true;
  isPayed: boolean;
  preventTileTitleOverlap: boolean;

  transactions: IncomingTransaction[] = [];
  modalConfig: UIKitModalConfiguration;
  modalText: string;
  showModal = false;
  quickActionText = '';

  private listeners: Function[] = [];

  constructor(
    elm: ng.IRootElementService,
    scope: ng.IScope,
    tilesService: TilesService,
    serviceHelper: IServiceHelper,
    private rootScope: ng.IRootScopeService,
    private state: ng.ui.IStateService,
    private filter: ng.IFilterService,
    private loanService: ILoanService,
    private loanAccountHelper: LoanAccountHelper
  ) {
    super(scope, elm, tilesService, serviceHelper);
  }

  /**
   * Initializes the controller.
   */
  $onInit(): void {
    if (this.rootScope['accounts']) this.getSettings(this.setupTile.bind(this));

    this.listeners.push(
      this.scope.$on('accountsLoaded', () => {
        this.getSettings(this.setupTile.bind(this));
      })
    );

    // Refresh the tile after a transfer.
    this.listeners.push(
      this.rootScope.$on('transferCompleted', () => {
        this.getLoanPendingPayments();
      })
    );

    this.listeners.push(
      this.rootScope.$on('updateTransactions', () => {
        this.getLoanPendingPayments();
      })
    );
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /**
   * Stores the settings set.
   */
  saveTileSettings(): void {
    this.tileSettings.Account.value = this.settings.account.id.toString();
    this.saveSettings();
    this.account = this.settings.account;
    this.loadLoanDetails();
  }

  /**
   * Reverts the changes made and flip back the tile.
   */
  resetSettings(): void {
    this.settings.account = this.accounts.find(a => a.id === +this.tileSettings.Account.value);
    this.account = this.settings.account;
  }

  /**
   * Redirects the user to the transfers view.
   */
  redirectToTransfers(): void {
    const { isLate, lateMessage } = this.loanAccountHelper.isPaymentLate(this.account.loanDueDate);
    const { isMature, matureMessage } = this.loanAccountHelper.isAccountMature(this.account);
    if (!isLate && !isMature) {
      sessionStorage.setItem('isPayoff', 'false');
      this.state.go('udb.transfers.loanPayment', { toAccountId: this.account.id });
    } else {
      this.modalText = isLate ? lateMessage : matureMessage;
      this.displayLoanWarning(this.modalText);
    }
  }

  /**
   *   Redirect to account details
   */

  redirectToAccount() {
    this.state.go('udb.accounts.details', { id: this.account.id, tab: 1 });
  }

  /**
   * Sets the tile up with its settings.
   */
  private setupTile(): void {
    // Exclude the Charged off accounts (statusCode = 8).
    this.accounts = angular.copy(
      this.rootScope['accounts'].loanAccounts.filter((a: OlbAccount) => {
        return +a.statusCode !== 8;
      })
    );

    if (!this.accounts || !this.accounts.length) {
      this.removeThisTile();

      return;
    }

    this.setDisplayNames();

    if (!this.tileSettings.Account.value) {
      this.tileSettings.Account.value = this.accounts[0].id.toString();
    }
    this.resetSettings();
    this.loadLoanDetails();
  }

  /**
   * Sets the display names of the given accounts.
   * @param accounts Accounts to set display names. Includes both internal and external.
   */
  private setDisplayNames(): void {
    this.accounts.forEach(account => {
      const name = truncate(account.name, 10);
      const accountNumber: string = account.accountNumber.toString();
      const numberMask: string = accountNumber.substring(accountNumber.length - 4, accountNumber.length);
      account.nickname = account.nickname || `${name} *${numberMask}`;
    });
  }
  /**
   * Loads the number of withdrawals for the specified account.
   */
  private loadLoanDetails(): void {
    if (!this.account) {
      this.removeThisTile();

      return;
    }
    this.getLoanPendingPayments();
  }

  /**
   * Gets the adequate tile image depending on the account's product type
   */
  private setLoanImgSrc(): void {
    try {
      switch (this.account.productTypeValue.valueOf()) {
        case ProductType.HELOC:
        case ProductType.Mortgage:
          this.loanImgSrc = '/img/icons/tiles/home-loan.svg';
          break;
        case ProductType.AutoLoan:
          this.loanImgSrc = '/img/icons/tiles/car-loan.svg';
          break;
        default:
          this.loanImgSrc = '/img/icons/tiles/general-loan.svg';
      }
    } catch {}
  }

  /**
   * Sets title, subtitle, image, date and amount to display on tile.
   */
  private setTitles(): void {
    const today = moment();
    const {
      paymentAmount,
      loanLastPaymentAmount,
      loanPastDueAmount,
      loanDueDate,
      loanLastPaymentDate,
      totalAmountDue,
    } = this.account;

    this.setDefaultValues();
    this.setLoanImgSrc();

    const pastDueAmount = this.filter('currency')(loanPastDueAmount);
    const amountDue = this.filter('currency')(totalAmountDue);

    // Loan Payment Pending.
    if (this.transactions.length !== 0) {
      this.setLoanPaymentPendingState();

      return;
    }

    // Loan Payment Confirmed.
    if (
      loanLastPaymentDate &&
      Math.abs(today.startOf('day').diff(loanLastPaymentDate, 'days')) <= 3 // Payed.
    ) {
      const lastPaymentDate = this.filter('date')(loanLastPaymentDate, 'MM/dd/yyyy');
      const lastPaymentAmount = this.filter('currency')(loanLastPaymentAmount);
      this.setLoanPaymentConfirmedState(lastPaymentAmount, lastPaymentDate);

      return;
    }

    // No Payment Due.
    if (!loanDueDate) {
      this.setNoPaymentDue();

      return;
    }

    const dueDate = this.filter('date')(loanDueDate, 'MM/dd/yyyy');
    const diffDays = Math.abs(today.startOf('day').diff(loanDueDate, 'days'));

    // Next Loan Payment.
    if (
      diffDays > 4 &&
      today.isBefore(loanDueDate, 'date') // Before of date payee.
    ) {
      this.setNextLoanPaymentState(amountDue, this.filter('currency')(paymentAmount), dueDate);

      return;
    }

    // Loan Payment Due.
    if (
      diffDays <= 4 &&
      today.isSameOrBefore(loanDueDate, 'date') // After of date payee.
    ) {
      this.setLoanPaymentDueState(amountDue, this.filter('currency')(paymentAmount), diffDays);

      return;
    }

    const dueDateWithGracePeriod = moment(loanDueDate).add(15, 'days').toDate();
    // Past Due Loan Payment, but inside grace period.
    if (today.isAfter(loanDueDate) && today.isSameOrBefore(dueDateWithGracePeriod)) {
      this.setDueLoanPaymentState(pastDueAmount, amountDue, dueDate, diffDays);

      return;
    }

    // Past Due Loan Payment, but outside grace period.
    if (today.isAfter(dueDateWithGracePeriod)) {
      this.setPastDueLoanPaymentState(pastDueAmount, amountDue, dueDate, diffDays);

      return;
    }
  }

  /**
   * Loan Payment Pending.
   */
  private setLoanPaymentPendingState(): void {
    const transaction = this.transactions[0];

    this.title = 'Loan Payment Pending';
    this.dueDateLabel = this.filter('date')(transaction.deliveryDate, 'MM/dd/yyyy');
    this.dueAmountLabel = `We expect your payment of ${this.filter('currency')(transaction.paymentAmout)} from ${
      transaction.externalBankName
    } to post on ${this.dueDateLabel}`;
    this.isPayed = true;
    this.quickActionText = 'View Loan Details';
  }

  /**
   * Loan Payment Confirmed.
   */
  private setLoanPaymentConfirmedState(amount: string, date: string): void {
    this.title = 'Loan Payment Confirmed';
    this.dueDateLabel = date;
    this.dueAmountLabel = `Payment received of ${amount}`;
    this.isPayed = true;
    this.hasLoanImg = false;
    this.quickActionText = 'View Loan Details';
  }

  /**
   * Next Loan Payment.
   */
  private setNextLoanPaymentState(amountDue: string, paymentAmount: string, date: string): void {
    this.title = 'Next Loan Payment';
    this.dueDateLabel = date;
    this.dueAmountLabel = `Next payment of ${!this.isZero(amountDue) ? amountDue : paymentAmount}`;
    this.quickActionText = '';
  }

  /**
   * Loan Payment Due.
   */
  private setLoanPaymentDueState(amountDue: string, paymentAmount: string, diffDays: number): void {
    this.title = `Loan Payment Due ${diffDays == 0 ? 'Today' : 'Soon'}`;
    this.labelClass = 'error';
    this.dueDateLabel = `${diffDays} Day${diffDays != 1 ? 's' : ''} Left`;
    this.dueAmountLabel = `Payment of ${!this.isZero(amountDue) ? amountDue : paymentAmount} is due ${
      diffDays == 0 ? 'today' : 'soon'
    }`;
    this.quickActionText = '';
  }

  /**
   * No Payment Due.
   */
  private setNoPaymentDue(): void {
    this.title = 'Next Loan Payment';
    this.dueAmountLabel = 'No payment due at this time';
  }

  /**
   * Past Due Loan Payment, but inside grace period
   */
  private setDueLoanPaymentState(pastAmountDue: string, amountDue: string, date: string, diffDays: number): void {
    this.title = 'Loan Payment Due';
    this.labelClass = 'error';
    this.dueDateLabel = `${diffDays} Day${diffDays != 1 ? 's' : ''} Ago`;
    this.dueAmountLabel = `Payment of ${!this.isZero(pastAmountDue) ? pastAmountDue : amountDue} was due on ${date}`;
  }

  /**
   * Past Due Loan Payment, but outside grace period
   */
  private setPastDueLoanPaymentState(pastAmountDue: string, amountDue: string, date: string, diffDays: number): void {
    this.title = 'Past Due Loan Payment';
    this.labelClass = 'error';
    this.dueDateLabel = `${diffDays} Day${diffDays != 1 ? 's' : ''} Ago`;
    this.dueAmountLabel = `Payment of ${
      !this.isZero(pastAmountDue) ? pastAmountDue : amountDue
    } is past due since ${date}`;
    this.quickActionText = '';
  }

  private isZero(amount: string): boolean {
    if (amount == '$0' || amount == '$0.0' || amount == '$0.00' || amount == '$00.00') {
      return true;
    }

    return false;
  }

  /**
   Sets default labels values.
   */
  private setDefaultValues() {
    this.title = 'Next Loan Payment';
    this.hasLoanImg = true;
    this.labelClass = 'success';
    this.dueAmountLabel = '';
    this.isPayed = false;
    this.dueDateLabel = '';
    this.quickActionText = '';
  }

  /**
   * Gets the recent transactions for the tile, based on its settings.
   */
  private getLoanPendingPayments(): void {
    this.quickActionText = '';
    this.isBusy = true;
    this.loanService
      .getPendingPayments([this.settings.account.id], 1)
      .then(result => {
        this.transactions = result.data;
      })
      .catch(() => {
        this.hasErrored = true;
      })
      .finally(() => {
        this.setTitles();
        this.preventTileTitleOverlap = this.titleIsOverlaped();
        this.isInitialising = false;
        this.isBusy = false;
      });
  }

  private titleIsOverlaped() {
    return this.title.length > 20;
  }

  //#region Loan Validations
  private displayLoanWarning(modalText: string) {
    this.modalConfig = {
      title: '',
      message: modalText,
      okText: 'Close',
      okAction: () => {
        this.showModal = false;
      },
      status: 'info',
    };
    this.showModal = true;
  }
  //#endregion
}

interface NextLoanPaymentTileSettings extends BaseTileSettings {
  Account?: TileSetting;
}
