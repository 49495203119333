<div class="icontainer">
  <div class="insights-container icontainer_gradient"></div>

  <ng-content></ng-content>
</div>
<div class="insights-container">
  <div class="icontainer_footer">
    <div class="icontainer_trapezoid--background">
      <div class="icontainer_trapezoid--left"></div>
    </div>
    <div class="icontainer_trapezoid icontainer_trapezoid--text" (click)="closeChart()">
      Close chart
    </div>
    <div class="icontainer_trapezoid--background">
      <div class="icontainer_trapezoid--right"></div>
    </div>
  </div>
  <div class="icontainer_trapezoid--base"></div>
</div>
