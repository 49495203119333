import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';

@Component({
  selector: 'app-past-performance-graph',
  templateUrl: './past-performance-graph.component.html',
  styleUrls: ['./past-performance-graph.component.scss'],
})
export class PastPerformanceGraphComponent implements AfterViewInit {
  @Input() pastPerformanceData: number[];
  @Input() pastPerformanceDataLabel: string[];
  @Input() pastPerformanceTimeLabel: string[];

  @ViewChild('chart') chartRef;

  chart: Chart;

  ngAfterViewInit(): void {
    this.initialize();
  }

  initialize() {
    const options = this.getOptions();

    const ctx = this.chartRef.nativeElement.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, '#60a3ccc9');
    gradient.addColorStop(1, '#FFFFFFa3');

    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        labels: this.pastPerformanceDataLabel,
        datasets: [
          {
            data: this.pastPerformanceData,
            borderColor: '#4d85b0',
            pointBackgroundColor: '#4d85b0',
            fill: true,
            lineTension: 0,
            backgroundColor: gradient,
          },
        ],
      },
      plugins: [
        {
          beforeDraw: chart => {
            if (this.pastPerformanceData.length === 0) {
              // Get ctx from string
              const chartProps = chart.chart.ctx;

              // Set font settings to draw it correctly.
              chartProps.textAlign = 'center';
              chartProps.textBaseline = 'middle';
              const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
              const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
              chartProps.font = '30px Arial';
              chartProps.fillStyle = '#8b949d';

              chartProps.fillText('No Graph Data', centerX, centerY);
            }
          },
        },
      ],
      options,
    });
  }

  getOptions() {
    const options = {
      layout: {
        padding: {
          top: 10,
        },
      },
      responsive: false,
      stacked: false,
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: '#ffffff',
        xPadding: 25,
        yPadding: 20,
        caretPadding: 20,
        caretSize: 10,
        borderColor: '#cac9c9',
        borderWidth: 1,
        titleFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
        titleFontColor: '#000',
        titleFontStyle: 'bold',
        titleMarginBottom: 15,
        titleFontSize: 14,
        bodyFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
        bodyFontSize: 12,
        bodyFontColor: '#000',
        footerFontSize: 20,
        footerFontColor: '#466d95',
        footerFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
        footerMarginTop: 15,
        displayColors: false,
        callbacks: {
          title: (tooltip: TooltipItem[], data: any): string => {
            return data.labels[tooltip[0].index];
          },
          label: (tooltip: TooltipItem): string => {
            return this.pastPerformanceTimeLabel[tooltip.index];
          },
          footer: (tooltip: TooltipItem[], data: any): string => {
            return '$' + data.datasets[tooltip[0].datasetIndex].data[tooltip[0].index].toFixed(2);
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: true,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
              callback: (value: string): string => {
                return '$' + value;
              },
            },
          },
        ],
      },
    };

    return options;
  }
}
