<div class="modal-content">
  <!-- HEADER -->
  <div class="modal-header"></div>

  <!-- CONTENT -->
  <div class="modal-body">
    <div class="frow between middle">
      <h3 class="check-title">CHECK DETAILS</h3>
      <button type="button" title="close" class="close" aria-label="close" (click)="closeDialog()">
        <i class="bofi-close" aria-hidden="true"></i>
      </button>
    </div>

    <div>
      <div class="check-bar">
        <span class="check-bar__item">
          <strong>Check Number:</strong>
          {{ transaction.checkNumber }}
        </span>
        <span class="check-bar__item">
          <strong>Date Posted:</strong>
          {{ transaction.postedDate | date: 'MM/dd/yyyy' }}
        </span>
        <span class="check-bar__item">
          <strong>Type:</strong>
          {{ transaction.type }}
        </span>
        <span class="check-bar__item">
          <strong>Amount:</strong>
          {{ transaction.amount | currency }}
        </span>
      </div>

      <div class="check-view" [ngClass]="{ 'is-loading': isLoading }">
        <div class="check-view__content" [ngClass]="{ 'has-failed': hasFailed }" *ngIf="!isLoading">
          <h4 class="check-view__content-headers bold">
            Front View
          </h4>
          <img
            *ngIf="!hasFailed"
            id="front-img"
            alt="Front View"
            src="data:image/{{ frontImgExt }};base64, {{ checkFiles.frontImage }}"
          />
        </div>
      </div>
      <div class="check-view" [ngClass]="{ 'is-loading': isLoading }">
        <div class="check-view__content" [ngClass]="{ 'has-failed': hasFailed }" *ngIf="!isLoading">
          <h4 class="check-view__content-headers bold">
            Back View
          </h4>
          <img
            *ngIf="!hasFailed"
            id="back-img"
            alt="Back View"
            src="data:image/{{ backImgExt }};base64, {{ checkFiles.backImage }}"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- FOOTER -->
  <div class="modal-footer">
    <div class="check-tools frow middle between">
      <button type="button" class="btn btn-link" (click)="downloadCheck()">
        <i class="bofi-download"></i>
        Download
      </button>
      <button type="button" (click)="printCheck()" class="btn btn-link">
        <i class="bofi-print"></i>
        Print
      </button>
    </div>
  </div>
</div>
