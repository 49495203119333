<ng-container *ngIf="!showTransferCompleted; else transferCompletedTemplate">
  <div class="transfer-funds-header">
    <p id="transfer-funds-title" *ngIf="!transferData?.transferSeriesId" class="transfer-funds-title">
      {{ showConfirmation ? content.confirmationTitle : content.title }}
    </p>
    <p id="transfer-funds-title" *ngIf="transferData?.transferSeriesId" class="transfer-funds-title">
      {{ showConfirmation ? content.confirmationTitle : content.editTitle }}
    </p>
    <div class="back-button-container">
      <a class="back-button" (click)="goBack()">
        <app-svg-icon
          [iconName]="backIcon"
          [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
        ></app-svg-icon>
        <span class="back-button-label">BACK</span>
      </a>
    </div>
  </div>
  <div class="transfer-container" [class.hidden]="showConfirmation">
    <div class="transfer-form-container">
      <div class="subtitle-container">
        <h5
          class="transfer-funds-subtitle"
          *ngIf="!content?.editSubtitle || (content?.editSubtitle && !transferData?.transferSeriesId)"
        >
          {{ content.subtitle }}
        </h5>
        <h5 class="transfer-funds-subtitle" *ngIf="transferData?.transferSeriesId">{{ content.editSubtitle }}</h5>
        <div class="delete-button" *ngIf="content?.editSubtitle && transferData?.transferSeriesId">
          <button
            class="delete-label-btn"
            id="deleteRecurringDeposit"
            type="button"
            (click)="deleteRecurringDeposit(transferData.transferSeriesId)"
          >
            <app-svg-icon
              class="delete-label-btn-icon"
              [iconName]="icons.trash"
              width="1.15em"
              height="1.15em"
              iconColor="#1c7fdd"
            ></app-svg-icon>
            <span class="delete-deposit-label">Delete Deposit</span>
            <span class="delete-deposit-label-sm">Delete</span>
          </button>
        </div>
      </div>
      <ng-container *ngIf="isRecommendationSupported">
        <app-recommendation
          *ngIf="recommendation"
          [recommendation]="recommendation"
          [hideButton]="hideRecommendationButton"
          (useRecommendation)="useRecommendation()"
        ></app-recommendation>
        <app-dot-loader *ngIf="isRecommendationLoading"></app-dot-loader>
      </ng-container>
      <ng-container *ngIf="transferType === TRANSFER_TYPES.RecurrentWithdrawal">
        <div class="form-group">
          The Withdrawal minimum is $100
        </div>
      </ng-container>
      <app-transfer-funds-form
        [goal]="goal"
        [form]="form"
        [transferType]="transferType"
        (formSubmitted)="onFormSubmitted($event)"
        (goBack)="goBack()"
      ></app-transfer-funds-form>
    </div>
    <div class="forecast-container">
      <div class="goal-value-container" *ngIf="transferType !== TRANSFER_TYPES.FundingFlow">
        <p class="goal-value-title">Current Value</p>
        <p class="goal-value">{{ goal.currentValue | currency }}</p>
      </div>
      <div class="goal-details">
        <p class="goal-details-title">Your Investment Goal</p>
        <div class="goal-details-value">
          <div class="goal-type">
            <div class="goal-type-icon">
              <app-svg-icon [iconName]="goalIcon" size="2rem"></app-svg-icon>
            </div>
            <p class="goal-type-description">{{ goal.typeDescription }}</p>
          </div>
          <div class="goal-forecast" *ngIf="goal.targetAmount && goal.targetDate">
            <div class="goal-forecast-icon">
              <app-svg-icon [iconName]="goalForecastIcon" size="2rem"></app-svg-icon>
            </div>
            <p class="goal-forecast-description">
              {{ goal.targetAmount | currency }} by {{ goal.targetDate | date: 'yyyy' }}
            </p>
          </div>
        </div>
      </div>
      <app-forecast [goal]="goal" [forecastParameters]="forecastParameters" *ngIf="forecastParameters"></app-forecast>
    </div>
  </div>
  <div class="confirmation-container" *ngIf="showConfirmation">
    <app-confirmation
      [transferData]="transferData"
      [transferType]="transferType"
      [goal]="goal"
      (transferCompleted)="onTransferCompleted($event)"
      (goBack)="goBack()"
    ></app-confirmation>
  </div>
</ng-container>
<ng-template #transferCompletedTemplate>
  <div class="transfer-completed-container">
    <app-completed [completedTransfer]="completedTransfer"></app-completed>
  </div>
</ng-template>
