<div class="bankWire__container" *ngFor="let item of authorizationsTabState">
  <div class="bankWire__title">Bank Wire Authority</div>
  <div class="accountNumber-container bankWire__flex-container">
    <div class="accountNumber-text txt">Bank Account Number</div>
    <div class="accountNumber-value val">{{ getFormattedAccNum(item.bankAccountNumber) ?? blankFormat }}</div>
  </div>
  <div class="bankWire__divider"></div>
  <div class="routingNumber-container bankWire__flex-container">
    <div class="routingNumber-text txt">ABA Routing Number</div>
    <div class="routingNumber-value val">{{ abaRoutingFormat(item.abaRoutingNumber) ?? blankFormat }}</div>
  </div>
  <div class="bankWire__divider"></div>
  <div class="accountName-container bankWire__flex-container">
    <div class="accountName-text txt">Bank Account Name</div>
    <div class="accountName-value val">{{ item.bankAccountName ?? blankFormat }}</div>
  </div>
</div>
