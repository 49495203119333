import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserRecoveryService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'authentication');
  }

  sendForgotUsername(email: string): WebApiResponse<null> {
    return this.get(`recover/username/${encodeURIComponent(email)}`);
  }
}
