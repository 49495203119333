<div *ngIf="showChecker" class="password__strength" @openClose>
  <div class="password__progress">
    <div class="progressbar" aria-hidden="true">
      <span class="progressbar__progress" [attr.data-progress]="progressValue"></span>
    </div>
    <span role="alert" aria-live="polite">{{ strength }}</span>
  </div>

  <div class="password__hints">
    <div class="password__hint uppercase-validation">
      <app-svg-icon
        [iconName]="uppercase.icon"
        [iconColor]="uppercase.colour"
        [size]="iconSize"
      ></app-svg-icon>
      <span>One uppercase letter</span>
    </div>

    <div class="password__hint number-validation">
      <app-svg-icon
        [iconName]="number.icon"
        [iconColor]="number.colour"
        [size]="iconSize"
      ></app-svg-icon>
      <span>One number</span>
    </div>

    <div class="password__hint lowercase-validation">
      <app-svg-icon
        [iconName]="lowercase.icon"
        [iconColor]="lowercase.colour"
        [size]="iconSize"
      ></app-svg-icon>
      <span>One lowercase letter</span>
    </div>

    <div class="password__hint minlength-validation">
      <app-svg-icon
        [iconName]="minlength.icon"
        [iconColor]="minlength.colour"
        [size]="iconSize"
      ></app-svg-icon>
      <span>8 characters</span>
    </div>

    <div class="password__hint symbol-validation">
      <app-svg-icon
        [iconName]="symbol.icon"
        [iconColor]="symbol.colour"
        [size]="iconSize"
      ></app-svg-icon>
      <span>One symbol (#?!@$%^&amp;*+-)</span>
    </div>
  </div>
</div>
