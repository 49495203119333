import { Component, Input, Output, OnInit, EventEmitter, Inject, OnDestroy, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { PaymentHistory, Recipient, ScheduledPayment } from '@app/bill-pay/typings';
import { DatePickerOptions, DatePickerLocaleOptions, DatePickerEvents } from '@shared/models';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { BillPayRouterHelper } from '@app/bill-pay/utils';
import { BILLPAYROUTERHELPER, billPayServiceProvider } from '@app/bill-pay/ajs-upgraded-provider';
import { STATE } from '@core/tokens';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-recipient-information',
  templateUrl: './recipient-information.component.html',
  styleUrls: ['./recipient-information.component.scss'],
  providers: billPayServiceProvider,
})
export class RecipientInformationComponent implements OnInit, OnDestroy {
  @Input()
  recipient: Recipient = {} as Recipient;
  @Input()
  paymentsHistory: PaymentHistory[];
  @Input()
  scheduledPayments: ScheduledPayment[];
  @Input()
  cancelPayment: boolean = true;
  tabSelected: number;
  amount: number;
  datePickerOptions: DatePickerOptions;
  amountOpt: number;
  processingPaymentLabel: string;
  processingDate: Date;
  selectedDate: Date;
  isFavorite: boolean;
  @Output()
  loadScheduledPayments = new EventEmitter<any>();
  @Output()
  paymentUpdated = new EventEmitter<any>();
  private listeners: Function[] = [];
  private readonly electronicRecipientLabel = 'Payment will be withdrawn on';
  private readonly checkRecipientLabel = 'Your check will be mailed on';
  constructor(
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper,
    @Inject(STATE) private readonly stateService: IStateService,
    private readonly dateHelper: DateHelper
  ) {}

  ngOnInit(): void {
    this.setDetaults();
    this.setDatepickerOptions();

    this.isFavorite = this.recipient.userTags && this.recipient.userTags.includes('Favorite');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cancelPayment) {
      this.setDetaults();
    }
  }
  ngOnDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  _loadScheduledPayments() {
    this.loadScheduledPayments.emit();
  }

  setDetaults(): void {
    this.amount = 0;
    // enable input for recipients that are not electronic and statements enabled
    if (this.recipient.eBillsStatus !== 0) {
      this.amountOpt = 2;
    }

    this.processingPaymentLabel =
      this.recipient.paymentMethod == 1 ? this.electronicRecipientLabel : this.checkRecipientLabel;
    this.processingDate = this.recipient.nextAvailablePaymentProcessingDate;

    // validate if is holiday
    let nextAvailable = moment(this.recipient.nextAvailablePaymentDeliveryDate).clone();
    if (!this.dateHelper.isValidDate(nextAvailable)) {
      while (this.dateHelper.isValidDate(nextAvailable) === false) {
        nextAvailable = nextAvailable.add(1, 'day');
      }
    }
    this.selectedDate = nextAvailable.toDate();
  }
  setAmountOpt() {
    this.amountOpt = 2;
  }

  setDatepickerOptions(): void {
    this.datePickerOptions = {
      minDate: this.selectedDate,
      maxDate: moment(this.selectedDate).clone().add(13, 'M').toDate(),
      locale: {
        format: 'MM/DD/YY',
      } as DatePickerLocaleOptions,
      singleDatePicker: true,
    };
  }

  toggleTabs() {
    if (!this.isToggled()) {
      this.tabSelected = 1;
    } else {
      this.tabSelected = null;
    }
  }

  goToScheduledPayment(recipient): void {
    this.stateService.go('udb.billPay.schedulePayment', { recipient });
  }
  isToggled() {
    //Check is there was a recipient active
    if (this.isAnActiveRecipient()) {
      return true;
    } else {
      const temp = this.tabSelected && this.tabSelected !== undefined && this.tabSelected !== 0;
      return temp;
    }
  }

  /**
   * Checks in RouteHelper if there is an active recipient
   * Active recipients are set in scheduled payments only right now
   * this handles the cancel button returning to the selected recipient in Pay screen.
   */
  isAnActiveRecipient(): boolean {
    if (
      this.billPayRouterHelper.activeRecipient != null &&
      this.billPayRouterHelper.activeRecipient.payeeId == this.recipient.payeeId
    ) {
      //activate tab
      this.tabSelected = this.billPayRouterHelper.activeTab;
      //clean the recipient active at this point
      this.billPayRouterHelper.cleanActiveRecipient();
      return true;
    } else {
      return false;
    }
  }

  setAmmount(type: number) {
    this.amountOpt = type;
    switch (type) {
      case 0:
        this.amount = this.recipient.eBill.amountDue;
        break;
      case 1:
        this.amount = this.recipient.eBill.outstandingBalance;
        break;
      default:
        this.amount = 0;
    }

    this.sendDashboardUpdate();
  }

  deliverOnChange(): void {
    this.processingDate = this.dateHelper
      .getProcessingDate(moment(this.selectedDate), -this.recipient.businessDaysToDeliver)
      .toDate();
    this.sendDashboardUpdate();
  }

  amountChange(event: any) {
    this.amount = event;
    this.sendDashboardUpdate();
  }

  setAmount(value: number) {
    this.amount = value;
  }

  sendDashboardUpdate() {
    let currentAmount: string = this.amount.toString().replace('$', '');

    this.paymentUpdated.emit({
      recipient: this.recipient,
      amount: +currentAmount,
      selectedDate: this.selectedDate,
      processingDate: this.processingDate,
      deliveryDate: this.selectedDate,
      payeeId: this.recipient.payeeId,
      hasDuplicateWarning: false,
    });
  }

  dateChangeEvent(event: DatePickerEvents) {
    this.selectedDate = new Date(event.picker.startDate.format('MM/DD/YYYY'));
    this.deliverOnChange();
    this.sendDashboardUpdate();
  }
}
