<div *ngIf="!isRewardsAccount" class="deposit-interest mt-2">
  <div class="deposit-interest-container mb-4">
    <div class="col-12 col-xl-6 deposit-interest-name">Interest Rate</div>
    <div class="col-12 col-xl-6 deposit-interest-item">{{ interestAPY }} APY</div>
  </div>

  <div class="deposit-interest-earnings">
    <button
      id="toggle-expand"
      type="button"
      class="link deposit-interest-earnings__toggle"
      (click)="isExpanded = !isExpanded"
    >
      {{ isExpanded ? 'Hide' : 'Show' }} Earnings
    </button>

    <app-svg-icon
      class="cursor-pointer"
      (click)="isExpanded = !isExpanded"
      [iconName]="isExpanded ? iconProps.chevronUpIcon : iconProps.chevronDownIcon"
      [iconColor]="iconProps.color"
      [styles]="iconProps.style"
    ></app-svg-icon>
  </div>

  <div *ngIf="isExpanded" class="expand-container">
    <div class="deposit-interest-container mb-3">
      <div class="col-12 col-xl-6 deposit-interest-name">Accrued Interest</div>
      <div class="col-12 col-xl-6 deposit-interest-item">
        {{ accruedInterest | currency }}
      </div>
    </div>

    <div class="deposit-interest-container mb-3">
      <div class="col-12 col-xl-6 deposit-interest-name">Interest Earned YTD</div>
      <div class="col-12 col-xl-6 mb-3 deposit-interest-item">
        {{ interestEarnedYtd | currency }}
      </div>
    </div>

    <div class="deposit-interest-container mb-3">
      <div class="col-12 col-xl-6 deposit-interest-name">Previous Year Interest Earned</div>
      <div class="col-12 col-xl-6 mb-3 deposit-interest-item">
        {{ previousYearInterest | currency }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="isRewardsAccount" class="rewards hidden-xs hidden-sm hidden-md">
  <h5>Interest</h5>
  <p>
    Interest Rate&nbsp;
    <span>
      <a *ngIf="showRewardsLink" (click)="openRewards.emit()">Learn how to get up to {{ interestMaximumRate }}%</a>
      {{ interestRewardsAPY }} APY
    </span>
  </p>
  <p>
    Accrued Interest&nbsp;
    <span>{{ accruedInterest | currency }}</span>
  </p>
  <p>
    Interest Earned YTD&nbsp;
    <span>{{ interestEarnedYtd | currency }}</span>
  </p>
  <p>
    Previous Year Interest Earned&nbsp;
    <span>{{ previousYearInterest | currency }}</span>
  </p>
</div>

<div *ngIf="isRewardsAccount" class="hide-lg">
  <h5 class="col-12">Interest</h5>
  <div class="account-item fcol-cs-12">
    <div class="account-item-name">Interest Rate</div>
    <div class="account-item-value">{{ interestRewardsAPY }} APY</div>
    <br />
    <a *ngIf="showRewardsLink" (click)="openRewards.emit()">Learn how to get up to {{ interestMaximumRate }}%</a>
  </div>
  <div class="account-item fcol-cs-12">
    <div class="account-item-name">Accrued Interest</div>
    <div class="account-item-value">{{ accruedInterest | currency }}</div>
  </div>
  <div class="account-item fcol-cs-12">
    <div class="account-item-name">Interest Earned YTD</div>
    <div class="account-item-value">{{ interestEarnedYtd | currency }}</div>
  </div>
  <div class="account-item fcol-cs-12">
    <div class="account-item-name">Previous Year Interest Earned</div>
    <div class="account-item-value">{{ previousYearInterest | currency }}</div>
  </div>
</div>
