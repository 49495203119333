<div class="goal-recent-transactions">
  <h3 class="recent-transactions-title">Recent Transactions</h3>
  <table class="table">
    <thead>
      <tr class="table-head-row">
        <th class="date-column"></th>
        <th class="category-icon-column">
          Category
        </th>
        <th class="description-column">
          Description
        </th>
        <th class="amount-column"></th>
        <th class="options-column"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="transactions?.length > 0; else noTransactions">
        <tr
          app-transaction-row
          *ngFor="let transaction of transactions"
          [transaction]="transaction"
          [class]="transaction.eventDescription"
          [unselectEvent]="unselectEvent"
          (rowSelected)="onSelectRow($event)"
        ></tr>
      </ng-container>
      <ng-template #noTransactions>
        <tr class="no-transactions-row">
          <td colspan="5">
            <h3 class="text-center m-t-lg">
              No recent transactions
            </h3>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
