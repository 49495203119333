import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '../../../enums';
import { OrganizationSettingsStateType } from './types';

const getOrganizationSettingsState = createFeatureSelector<OrganizationSettingsStateType>(
  AasStoreFeatures.OrganizationSettings
);

export const getSelectedOrganization = createSelector(getOrganizationSettingsState, state => {
  if (state.organizations === undefined) {
    return undefined;
  }

  return state.organizations.find(item => item.name === state.selectedOrganizationName) ?? undefined;
});
