import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { format } from 'date-fns';

import { Filters } from '@app/axos-invest/models';
import { UtilityIcons } from '@shared/enums';
import { Filter } from '@shared/models';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Output() filtersUpdated = new EventEmitter<Filters>();
  @Input() isRia: boolean = false;
  @Input() hideCategoriesFilter: boolean;

  get isFilterBtnActive(): boolean {
    const subFilters = ['dateRange', 'datePeriod', 'amountRange', 'transaction'];

    return !!this.filtersApplied.find(filter => subFilters.includes(filter.name));
  }
  get tagFilters(): Filter[] {
    const subFilters = ['dateRange', 'amountRange'];
    const filters = this.filtersApplied.filter(filter => subFilters.includes(filter.name));

    return filters;
  }

  filters: UntypedFormGroup;
  periodFilterOptions: GenericOption[];
  transactionFilterOptions: GenericOption[];
  displayFilters: boolean;
  filtersApplied: Filter[] = [];
  filterIcon = {
    iconName: UtilityIcons.FilterFunnel,
    width: '16px',
    height: '12px',
  };

  private dateFormat = 'MM/dd/yyyy';
  private emitEvent = true;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.createForm();
    this.setEventsForFilters();
    this.setOptionsForFilters();
  }

  onChangeCategoryFilter(value: any) {
    this.filters.controls.categories.setValue(value.value);
  }

  onChangeAmountRangeFilter(event: { filterType: string; rangeValues: { min: number; max: number } }) {
    this.filters.controls.amountRange.setValue(event.rangeValues);
  }

  clearAmountRangeFilter() {
    this.filters.controls.amountRange.reset();
  }

  toggleDisplayFilters() {
    this.displayFilters = !this.displayFilters;
  }

  removeFilterTag(filter: Filter) {
    this.filters.get(filter.name).reset();
  }

  resetSubFilters() {
    const subFilters = ['dateRange', 'datePeriod', 'amountRange', 'transaction'];
    this.emitEvent = false;
    subFilters.forEach(filter => this.filters.get(filter).reset());
    this.emitEventOutput();
    this.emitEvent = true;
    this.toggleDisplayFilters();
  }

  private createForm() {
    this.filters = this.formBuilder.group({
      search: [],
      datePeriod: [],
      dateRange: [],
      categories: [],
      amountRange: [],
      transaction: [],
    });
  }

  private setOptionsForFilters() {
    const depositLabel = this.isRia ? 'Contributions' : 'Deposits';
    const withdrawalLabel = this.isRia ? 'Distributions' : 'Withdrawals';
    this.periodFilterOptions = [
      { value: 30, label: 'Past 30 days' },
      { value: 60, label: 'Past 60 days' },
      { value: null, label: 'Past 90 days', default: true },
      { value: 180, label: 'Past 180 days' },
      { value: 365, label: 'Past 12 months' },
      { value: 730, label: 'Past 24 months' },
    ];
    this.transactionFilterOptions = [
      { value: null, label: 'All transactions', default: true },
      { value: 1, label: depositLabel },
      { value: 2, label: withdrawalLabel },
    ];
  }

  private setEventsForFilters() {
    this.filters.controls.search.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(value => this.validateString({ name: 'search', value }));

    this.filters.controls.datePeriod.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((selectedOption: number) => this.validateNumber({ name: 'datePeriod', value: selectedOption }));

    this.filters.controls.dateRange.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => this.onChangeDateRange(value));

    this.filters.controls.categories.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => this.validateArray({ name: 'categories', value }));

    this.filters.controls.amountRange.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => this.onChangeAmountRange(value));

    this.filters.controls.transaction.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((selectedOption: number) => this.validateNumber({ name: 'transaction', value: selectedOption }));
  }

  private onChangeDateRange(value: { min: Date; max: Date }) {
    const filter = new Filter();
    filter.value = value;
    filter.name = 'dateRange';
    filter.label = value ? this.formatLabel(value.min, value.max, this.dateFormat) : null;
    this.emitFilterChange(filter, !!filter.value);
  }

  private onChangeAmountRange(value: { min: number; max: number }) {
    const filter = new Filter();
    filter.value = value;
    filter.name = 'amountRange';
    filter.label = value ? this.formatLabel(value.min, value.max) : null;
    this.emitFilterChange(filter, !!filter.value);
  }

  private formatAmount(value: any) {
    return parseFloat(value).toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
    });
  }

  private validateNumber(filter: Filter<number>) {
    this.emitFilterChange(filter, filter.value !== null);
  }

  private validateString(filter: Filter<string>) {
    this.emitFilterChange(filter, filter.value !== '');
  }

  private validateArray(filter: Filter<any[]>) {
    this.emitFilterChange(filter, filter.value && filter.value.length > 0);
  }

  private emitFilterChange(filter: Filter, add: boolean) {
    const action = add ? this.addFilter : this.removeFilter;
    action.bind(this)(filter);
  }

  private addFilter(filter: Filter) {
    let indexOfFilter = this.filtersApplied.findIndex(f => f.name === filter.name);
    indexOfFilter = indexOfFilter >= 0 ? indexOfFilter : this.filtersApplied.length;
    this.filtersApplied[indexOfFilter] = filter;
    this.emitEventOutput();
  }

  private removeFilter(filter: Filter) {
    const indexOfFilter = this.filtersApplied.findIndex(f => f.name === filter.name);
    if (indexOfFilter >= 0) {
      this.filtersApplied.splice(indexOfFilter, 1);
    }
    if (this.emitEvent) this.emitEventOutput();
  }

  private emitEventOutput() {
    const filters = {};
    const keys = Object.keys(this.filters.value);
    keys.forEach(key => {
      const value = this.filters.get(key).value;
      if (value !== null) {
        filters[key] = value;
      }
    });
    this.filtersUpdated.next(filters);
  }
  private formatLabel = (min: number | Date, max: number | Date, dateFormat: string = null): string => {
    return dateFormat
      ? `${format(min, this.dateFormat)} - ${format(max, this.dateFormat)}`
      : `${min ? '$' + this.formatAmount(min) : 'Min'} - ${max ? '$' + this.formatAmount(max) : 'Max'}`;
  };
}
