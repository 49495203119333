import { createSelector } from '@ngrx/store';

import { TimePeriod } from '@app/pfm/enums/time-period.enum';

import { getPfmState } from '../pfm.state';
import { NetWorthFilters } from './net-worth-filters';

export const getNetWorthSeries = createSelector(getPfmState, state => state.netWorthState.netWorthSeries);
export const getNetWorthSeriesLoading = createSelector(getPfmState, state => state.netWorthState.netWorthSeriesLoading);

// ---------- Filters ----------
export const getUncommittedExcludedAccountsFilter = createSelector(
  getPfmState,
  state => state.netWorthState.uncommittedFilters.excludedAccounts
);
export const getUncommittedTimePeriodFilter = createSelector(
  getPfmState,
  state => state.netWorthState.uncommittedFilters.timePeriod
);

function getFiltersInDefaultState(filters: NetWorthFilters) {
  const result = {
    timePeriod: filters.timePeriod === TimePeriod.LastMonth,
    accounts: filters.excludedAccounts.size === 0,
  };

  return { ...result, all: result.timePeriod && result.accounts };
}

export const getCommittedFiltersInDefaultState = createSelector(getPfmState, state =>
  getFiltersInDefaultState(state.netWorthState.committedFilters)
);

export const getUncommittedFiltersInDefaultState = createSelector(getPfmState, state =>
  getFiltersInDefaultState(state.netWorthState.uncommittedFilters)
);
export const getCommittedFilters = createSelector(getPfmState, state => state.netWorthState.committedFilters);
