import { TradingAccount } from 'accounts/typings/TradingAccount';
import NgRedux from 'ng-redux';

import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';

import { Inject } from '../decorators/decorators';
import { TradingAccountActions } from '../state-store/actions/accounts/trading.actions';

@Inject('$ngRedux', 'olbEventService')
export class CachedTradingAccountsService {
  get isTradingAccountsLoaded(): boolean {
    return this.isAccountsLoaded;
  }

  get tradingAccounts(): TradingAccount[] {
    return this.ngRedux.getState().accounts.trading
      ? this.ngRedux.getState().accounts.trading.map((a: TradingAccount) => ({ ...a }))
      : [];
  }
  private isAccountsLoaded = false;
  constructor(private readonly ngRedux: NgRedux.INgRedux, private olbEventService: OlbEventService) {}

  loadTradingAccounts(data: TradingAccount[]) {
    this.isAccountsLoaded = true;
    this.ngRedux.dispatch(TradingAccountActions.loadTradingAccounts(data));
    this.olbEventService.emit(OlbEvents.InvestAccountsLoaded);
  }

  updateTradingAccount(account: TradingAccount) {
    this.ngRedux.dispatch(TradingAccountActions.updateAccount(account));
  }
}
