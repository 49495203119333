import { StopPaymentService } from '@legacy/services/stop-payment.service';

export function stopPaymentServiceFactory($injector: any) {
  return $injector.get('stopPaymentService');
}

export const stopPaymentServiceProvider = {
  provide: StopPaymentService,
  useFactory: stopPaymentServiceFactory,
  deps: ['$injector'],
};
