import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PortfolioAllocationState } from '../../state/types';


const getPortfolioAllocationState = createFeatureSelector<PortfolioAllocationState>(
  AasStoreFeatures.PortfolioAllocation
);

export const getSelectedAccountPortfolioAllocationTile = createSelector(getPortfolioAllocationState, state => {
  const { porfolioAllocations, selectedAccountPorfolioAllocations } = state;

  if (selectedAccountPorfolioAllocations === undefined) {
    return undefined;
  }

  const matchingAccount =
    porfolioAllocations.find(account => account.accountNumber === selectedAccountPorfolioAllocations) ?? undefined;

  return matchingAccount;
});
