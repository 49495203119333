<div class="view-container pt-3">
  <app-dot-loader class="mx-auto" *ngIf="isLoading; else accountDetailsView"></app-dot-loader>
</div>

<ng-template #accountDetailsView>
  <h2 class="mb-4">Loan Account Details</h2>

  <div class="row">
    <div *ngIf="!account.displayAsClosedAccount" class="col-12 col-lg-4 payment-info">
      <app-payment-info
        [info]="paymentInfo"
        [canMakePayment]="showPaymentButton"
        (makePayment)="goToTransferFunds()"
      ></app-payment-info>
    </div>

    <app-loan-account-detail
      class="col-12 col-lg-8 order-lg-first mt-5 mt-lg-0"
      [account]="account"
      [details]="details"
      (updatedNickname)="details.nickname = $event"
      [hasSignatureCard]="true"
    ></app-loan-account-detail>

    <app-loan-payment-detail
      class="col-12 col-lg-8 mt-5"
      [details]="details"
      [isPastDue]="isPastDue"
    ></app-loan-payment-detail>
  </div>
</ng-template>
