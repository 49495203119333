import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TxTableOptionActions } from '@app/accounts/components/account-transactions/enums';
import { TxOptionType } from '@app/accounts/components/account-transactions/types';
import { SvgIconHelperService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { FeatureFlagService } from 'services/feature-flag.service';

@Component({
  selector: 'app-tx-row-agg',
  templateUrl: './tx-row-agg.component.html',
  styleUrls: ['../tx-row/tx-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxRowAggComponent {
  // Bound properties
  @Input() trxn: Transaction;
  @Input() isAccountActive: boolean;
  @Input() isExternalLoan: boolean;
  @Output() onCollapseEvent = new EventEmitter<void>();
  @Output() onOptionSelected = new EventEmitter<any>();

  // Internal props to manage directive
  isPfm3ExternalTransActive = false;
  accountOptions: TxOptionType[] = [];
  categoryIcon: SafeHtml = '';

  constructor(
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private currencyPipe: CurrencyPipe,
    private featureFlagService: FeatureFlagService,
    private svgIconHelper: SvgIconHelperService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log(this.root['isExternalLoan']);
    this.isExternalLoan = this.root['isExternalLoan'];
    this.isPfm3ExternalTransActive = this.featureFlagService.isPFM3ExternalTrans();

    if (!this.trxn.isPending && this.transactionIsNotPending()) {
      this.accountOptions.push({
        label: TxTableOptionActions.recategorizeTransaction,
        isCollapsible: true,
      });
    }
    this.getImageIcon();
  }

  getAccountBalance(transaction: Transaction): string {
    return this.currencyPipe.transform(transaction.balance);
  }

  //If it was a deposit, it will be a positive amount but if it was a withdrawal it will be a negative one
  getTransactionAmount(transaction: Transaction): string {
    const amount = transaction.type == 'DEBIT' ? -Math.abs(transaction.amount) : transaction.amount;
    return this.currencyPipe.transform(amount);
  }

  //Triggers the on select options defined
  selectOption(option: TxOptionType) {
    this.onOptionSelected.emit(option);
  }

  transactionIsNotPending(): boolean {
    const categoryName = this.trxn.olbCategoryName.toLowerCase();
    return categoryName !== 'pending';
  }

  onCollapse() {
    this.onCollapseEvent.emit();
  }

  //Displays transactions details tray
  showDetails(trxn: Transaction) {
    if (!this.isPfm3ExternalTransActive) return;
    if (!trxn.isPending) trxn.isDetailsExpanded = !trxn.isDetailsExpanded;
  }

  // getImagePath() {
  private getImageIcon() {
    let categoryName = this.trxn.olbCategoryName.toLowerCase();
    const idx = categoryName.indexOf(' ');
    categoryName = idx < 0 ? categoryName : categoryName.substring(0, idx);

    const icon = `${categoryName}_white`;
    this.svgIconHelper.loadSvgIcon('categories', icon).subscribe(icon => {
      this.categoryIcon = icon;
      this.changeDetector.detectChanges();
    });
  }
}
