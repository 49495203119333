import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aas-data-tile-list',
  templateUrl: './data-tile-list.component.html',
  styleUrls: ['./data-tile-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTileListComponent {
  @Input() header: string = '';
}
