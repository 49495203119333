import { FrequencySelectorController } from './frequency-selector.controller';

let frequencySelector: ng.IComponentOptions = {
  controller: FrequencySelectorController,
  controllerAs: '$fsc',
  templateUrl:
    'alerts-notifications/my-alerts/components/frequency-selector/frequency-selector.html',
  bindings: {
    focusActive: '@',
  },
};

export { frequencySelector };
