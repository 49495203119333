<div class="secure-forms">
  <app-view-header titleText="Secure Forms">
    <p>
      Use the forms below for account-related activities. Upload your document to submit it.
    </p>
  </app-view-header>
  <app-dot-loader [showWhen]="loading"></app-dot-loader>
  <app-alert-notification *ngIf="errorMessage" alertType="error" (dismiss)="dismissError()" class="secure-forms__error">
    {{ errorMessage }}
  </app-alert-notification>
  <div class="secure-forms__container view-container" *ngIf="!loading">
    <div class="col-12 col-md-6 mb-4">
      <p class="secure-forms__section-title">
        Available Forms
      </p>
      <app-document-library [library]="library" [sections]="sections"></app-document-library>
    </div>
    <div class="col-12 col-md-6">
      <p class="secure-forms__section-title">
        Upload Your Forms
      </p>
      <app-upload-form [library]="library" [sections]="sections"></app-upload-form>
      <p class="secure-forms__instructions">
        Prompted by one of our agents to submit a form(s) and looking for the fastest service? Attach the completed form
        to your existing conversation in the
        <a class="link" (click)="changeState('udb.messages.inbox')" href="#" id="message-center">
          message center.
        </a>
      </p>
    </div>
  </div>
</div>
