import { createAction, props } from '@ngrx/store';

import { AccountInvestmentDetails, AxosAdvisoryAccount } from '@core/models';
import { AASAccountDataRequest } from '@app/Areas/AAS/features/account-details/core';

const actionPrefix = '[Axos Advisory]';
export const loadAxosAdvisoryAccounts = createAction(
  `${actionPrefix} Load Axos Advisory Accounts`,
  props<{ payload: AxosAdvisoryAccount[] }>()
);

export const getAccountInvestmentDetails = createAction(`${actionPrefix} Get Account Investment Details`);
export const getAccountInvestmentDetailsSuccess = createAction(
  `${actionPrefix} Get Account Investment Details Success`,
  props<{ payload: AccountInvestmentDetails }>()
);

export const updateAasAccountDataAction = createAction(
  '[Account] UpdateAasAccountDataAction',
  props<{ payload: AASAccountDataRequest }>()
);
