import { ErrorHandler, NgModule } from '@angular/core';

import { ConfigService } from '@core/services';
import { olbSettings } from '@core/tokens';

import { ExceptionHandlerService } from './handlers/exception-handler.service';

@NgModule({
  providers: [
    {
      provide: olbSettings,
      useFactory: (config: ConfigService) => {
        return config.appConfig;
      },
      deps: [ConfigService],
    },
    { provide: ErrorHandler, useClass: ExceptionHandlerService },
  ],
})
export class ConfigModule {}
