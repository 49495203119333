import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FileFormats } from '@app/transaction-history/models/fileFormats';
import { TransactionHistoryService } from '@app/transaction-history/services';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-transaction-history-options',
  templateUrl: './transaction-history-options.component.html',
  styleUrls: ['./transaction-history-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionHistoryOptionsComponent {
  @Input() fileFormat: FileFormats;
  chevronIconConfig = {
    icon: NavigationIcons.ChevronRight,
    styles: {
      width: '0.5rem',
      height: '0.5rem',
      strokeWidth: 3,
    },
  };

  constructor(public transactionInfo: TransactionHistoryService) {}
}
