import { Action, createReducer, on } from '@ngrx/store';

import { loadBrandProperties } from './brand-properties.actions';

export const initialState: Record<string, string> = null;

const _reducer = createReducer<Record<string, string>>(
  initialState,
  on(loadBrandProperties, (_state, action) => {
    return action.payload.reduce((accumulator, item) => {
      const property = {
        [item.name]: item.value,
      };

      return { ...accumulator, ...property };
    }, {});
  })
);

export function brandPropertiesReducer(state: Record<string, string>, action: Action) {
  return _reducer(state, action);
}
