import { IComponentOptions } from 'angular';
import { TxTableController } from './tx-table.controller';

export const TxTableComponent: IComponentOptions = {
  controller: TxTableController,
  controllerAs: '$tt',
  templateUrl: 'accounts/transactions/tx-table/tx-table.tpl.html',
  bindings: {
    account: '<?',
    filters: '<',
    onLoaded: '&',
    onGetCheck: '&',
    onSorted: '&',
    aggregatedAccount: '<?',
    isAggregatedAccount: '<?',
    onTableRowOperation: '&?',
  },
};
