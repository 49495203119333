import { InjectionToken } from '@angular/core';
export const ALERTSNOTIFICATIONSSERVICE = new InjectionToken('alertsNotificationsService');

export const alertsNotificationsServiceProvider = [
  {
    provide: ALERTSNOTIFICATIONSSERVICE,
    useFactory: (i: any) => i.get('alertsNotificationsService'),
    deps: ['$injector'],
  },
];
