<div class="container" [ngClass]="{ 'loading-background': isSearchingInProgress }">
  <div class="row">
    <div class="col-sm-9">
      <div class="row">
        <app-dot-loader [showWhen]="isSearchingInProgress" [ngClass]="{ loading: isSearchingInProgress }">
          Loading
        </app-dot-loader>
      </div>
      <div class="row mt-4">
        <div class="col-12 search-title">Research</div>
      </div>
      <div class="row mt-4 search-box">
        <div class="col-12">
          <input
            type="text"
            placeholder="Search stocks, companies, ETFs, symbols, etc."
            class="uk-input search-stuck"
            name="search"
            autocomplete="off"
            [formControl]="searchStock"
          />
        </div>
      </div>
      <div class="results-wrapper" *ngIf="isOpenDropdown">
        <div *ngIf="equities && equities.length > 0">
          <ul class="results" *ngIf="equities.length > 0">
            <li *ngFor="let equity of equities | slice: 0:10" class="result" (click)="resultClicked(equity.symbol)">
              <div class="row-content">
                <div class="description">
                  <div class="symbol">{{ equity.symbol }}</div>
                  <div class="companyName">{{ equity.companyName }}</div>
                </div>
              </div>
            </li>
            <li class="more-than" *ngIf="equities.length > 10">
              <a (click)="showAllSearchResult()" class="link">
                Displaying 10 results out of ({{ equities.length }}). Click here for more results
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-4" *ngIf="isOpenTable">
        <app-table-research [accountId]="accountId" [equities]="equities"></app-table-research>
      </div>
    </div>
    <div class="col-sm-3 account-information mt-4">
      <app-right-navigation [isHideResearchTab]="true"></app-right-navigation>
    </div>
  </div>
  <div class="mt-5">
    <app-feature-link></app-feature-link>
  </div>
</div>
