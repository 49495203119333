import { Action, createReducer, on } from '@ngrx/store';
import { signOut } from '@app/store/actions';
import { TransactionsState, initialTransactionsState } from '../types';
import { addUpdateTransactionAction } from '../transactions-tile.actions';

const reducer = createReducer(
  initialTransactionsState,
  on(addUpdateTransactionAction, (_state, action) => {
    const indexOfUpdated = _state.accountsTransactions.findIndex(m => m.accountNumber === action.payload.accountNumber);
    if (indexOfUpdated >= 0) {
      return {
        accountsTransactions: _state.accountsTransactions.map(item => {
          return item.accountNumber === action.payload.accountNumber ? (item = action.payload) : item;
        }),
        selectedAccountTransactions: action.payload.accountNumber,
      };
    } else {
      return {
        accountsTransactions: [..._state.accountsTransactions, action.payload],
        selectedAccountTransactions: action.payload.accountNumber,
      };
    }
  }),
  on(signOut, () => {
    return initialTransactionsState;
  })
);

export function transactionsReducer(state: TransactionsState, action: Action) {
  return reducer(state, action);
}
