import { Biller } from 'typings/app/bills/Biller';
import { PartialPayee } from 'typings/app/bills/PartialPayee';

export class BillersService {
  payee: PartialPayee;
  biller: Biller;
  billerAddresses: BillerAccountValidationAddress[];

  /**
   * Gets the biller currently selected
   * @returns Biller a biller object
   */
  getSelected(): Biller {
    return this.biller;
  }

  /**
   * Sets the biller as currently selected
   * @param biller a Biller object to be stored
   */
  setSelected(biller: Biller): void {
    this.biller = biller;
  }

  /**
   * Gets the payee currently stored
   * @returns PartialPayee a payee object
   */
  getPayee(): PartialPayee {
    return this.payee;
  }

  /**
   * Sets the payee as currently edited
   * @param payee a PartialPayee object to be stored
   */
  setPayee(payee: PartialPayee): void {
    this.payee = payee;
  }

  /**
   * Sets the biller addresses
   * @param addresses an array of BillerAccountValidationAddress objects
   */
  setBillerAddresses(addresses: BillerAccountValidationAddress[]): void {
    this.billerAddresses = addresses;
  }

  /**
   * Gets the biller addresses
   * @return An array of BillerAccountValidationAddress objects
   */
  getBillerAddresses(): BillerAccountValidationAddress[] {
    return this.billerAddresses;
  }
}
