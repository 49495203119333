import { Inject } from '../decorators/decorators';
import { SignatureCardController } from 'signature-card/signature-card.controller';
import { UploadCardController } from 'signature-card/upload-card/upload-card.controller';
import { UploadCardCompleteController } from 'signature-card/upload-card/upload-card-complete/upload-card-complete.controller';
import { SendMailController } from 'signature-card/send-mail/send-mail.controller';
import { UnableDownloadController } from 'signature-card/unable-download/unable-download.controller';
import { UnableDownloadCompleteController } from 'signature-card/unable-download/unable-download-complete/unable-download-complete.controller';

@Inject('$stateProvider')
export class SignatureCardConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.signaturecard', {
        url: '/SignatureCard',
        params: { accountNumber: null, accountId: null },
        views: {
          content: {
            templateUrl: 'signature-card/signature-card.tpl.html',
            controller: SignatureCardController,
            controllerAs: 'vm',
          },
        },
      })
      .state('udb.signaturecard.signature-card-upload', {
        url: '/UploadCard',
        params: { accountNumber: null },
        views: {
          'sig-card-content': {
            templateUrl: 'signature-card/upload-card/upload-card.tpl.html',
            controller: UploadCardController,
            controllerAs: 'vm',
          },
        },
      })
      .state('udb.signaturecard.send-mail', {
        url: '/SendMail',
        views: {
          'sig-card-content': {
            templateUrl: 'signature-card/send-mail/send-mail.tpl.html',
            controller: SendMailController,
            controllerAs: 'vm',
          },
        },
      })
      .state('udb.signaturecard.unable-download', {
        url: '/UnableDownload',
        views: {
          'sig-card-content': {
            templateUrl: 'signature-card/unable-download/unable-download.tpl.html',
            controller: UnableDownloadController,
            controllerAs: 'vm',
          },
        },
      })
      .state('udb.signaturecard.unable-download-complete', {
        url: '/UnableDownloadComplete',
        views: {
          'sig-card-main': {
            templateUrl:
              'signature-card/unable-download/unable-download-complete/unable-download-complete.tpl.html',
            controller: UnableDownloadCompleteController,
            controllerAs: 'vm',
          },
        },
      })
      .state('udb.signaturecard.signature-card-upload-complete', {
        url: '/UploadCardComplete',
        views: {
          'sig-card-main': {
            templateUrl:
              'signature-card/upload-card/upload-card-complete/upload-card-complete.tpl.html',
            controller: UploadCardCompleteController,
            controllerAs: 'vm',
          },
        },
      });
  }
}
