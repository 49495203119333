import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { DepositsLoansTopics } from '@app/support/enums';

@Component({
  selector: 'how-to-deposit-loans-topics',
  templateUrl: './deposit-loans-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DepositLoansTopicsComponent {
  selectedTopic: DepositsLoansTopics;
  topics = DepositsLoansTopics;

  selectTopic(topic: DepositsLoansTopics): void {
    this.selectedTopic = topic;
  }
}
