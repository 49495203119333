<div class="container">
  <app-click-switch-header
    step="3"
    subtitle="Step 3 of 3"
    (back)="gotoAccountSelectionPage()"
  ></app-click-switch-header>
  <div class="row justify-content-center mt-2">
    <p class="review-title">Please review the information you have provided</p>
    <div class="col-12 col-sm-8 text-center mt-1">
      <p class="review-subtitle">Select edit to make any necessary changes.</p>
    </div>
  </div>
  <br />
  <app-alert-notification *ngIf="errorMessage" alertType="error" (dismiss)="cleanErrorMessage()">
    {{ errorMessage }}
  </app-alert-notification>
  <br />
  <div class="d-flex align-items-center information-container">
    <app-click-switch-details
      class="row justify-content-center col-12 col-md-10 col-lg-8"
      title="Source Information"
      [details]="sourceInformation"
      [canEdit]="true"
      (edit)="gotoIncomeSourcePage()"
    ></app-click-switch-details>

    <hr class="mt-5 d-sm-none" />

    <app-click-switch-details
      class="row justify-content-center col-12 col-md-10 col-lg-8 mt-5"
      title="Deposit Information"
      [details]="depositInformation"
      [canEdit]="true"
      (edit)="gotoAccountSelectionPage()"
    ></app-click-switch-details>

    <app-click-switch-terms-conditions
      class="col-12 col-md-10 col-lg-8 mt-5"
      (agree)="acceptedTermsAndConditions($event)"
    ></app-click-switch-terms-conditions>

    <div class="address__form-btns row justify-content-center mt-5">
      <button
        type="submit"
        class="uk-btn lg solid secondary m-4"
        (click)="goToConfirmationPage()"
        [disabled]="isContinueButtonDisabled()"
      >
        Continue
      </button>
      <button type="button" class="link col-12" (click)="goToSwitchStartPage()">
        Cancel
      </button>
    </div>
  </div>
</div>
