export enum GoodUntilDescription {
  endOfDay = 'End of day',
  canceled = 'Good until canceled',
}

export enum GoodUntil {
  day = 1,
  gtc = 2,
}

export const GOOD_UNTIL_MAPPING = {
  [GoodUntilDescription.canceled]: GoodUntil.gtc,
  [GoodUntilDescription.endOfDay]: GoodUntil.day,
};
