import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IAxosLegacyPagination } from '@app/inbox/models';
import { TAxosLegacyPaginationChanges } from '@app/inbox/types';
import { SelectableButtonsRange } from '@app/inbox/utils';

@Component({
  selector: 'axos-legacy-paginator',
  templateUrl: './inbox-paginator.component.html',
  styleUrls: ['./inbox-paginator.component.scss'],
})
export class InboxPaginatorComponent implements IAxosLegacyPagination, OnChanges {
  /**
   * tracks size of the list to be rendered
   * trackable via {@link OnChanges}
   */
  @Input() listLength: number = 0;
  /**
   * tracks the size of elements to be rendered on the current view
   * trackable via {@link OnChanges}
   */
  @Input() itemsPerPage: number = 0;
  /**
   * tracks the amount of pages that we want to be visible on the selection area view
   * trackable via {@link OnChanges}
   */
  @Input() visibleSelectablePages: number = 0;
  @Output() visibleSelectablePagesChanges: EventEmitter<number> = new EventEmitter();
  /**
   * holds the state of the current indexes where the list must be sliced
   * do not track this via {@link OnChanges}
   */
  @Input() renderRange: IAxosLegacyPagination['renderRange'] = [0, 0];
  /**
   *
   */
  @Output() renderRangeChange: EventEmitter<IAxosLegacyPagination['renderRange']> = new EventEmitter<
    IAxosLegacyPagination['renderRange']
  >();

  currentSelectedPage: number = 0;
  currentSelectedPageChanges: EventEmitter<number> = new EventEmitter<number>();
  buttonPages: Array<number> = [];

  ngOnChanges(changes: TAxosLegacyPaginationChanges): void {
    if (changes.itemsPerPage?.currentValue) {
      this.calculatePages(changes.itemsPerPage.currentValue);
    }
  }

  selectPage(newSelectedPage: number): void {
    this.currentSelectedPage = newSelectedPage;
    this.currentSelectedPageChanges.next(this.currentSelectedPage);
    this.calculateCurrentRange(this.currentSelectedPage);
  }

  public calculatePages(itemsPerPage: number): void {
    let requiredPages: number = 0;
    if (this.listLength <= 0 || Number.isNaN(itemsPerPage)) {
      this.buttonPages = [];
      return;
    }

    requiredPages = Math.ceil(this.listLength / itemsPerPage);
    this.buttonPages = this.createPagesArray(requiredPages);
    // re-build the rendered list by calling selectPage
    this.selectPage(this.currentSelectedPage);
  }

  public calculateCurrentRange(currentPage: number): void {
    const offset = this.itemsPerPage * (currentPage + 1);
    this.renderRange = [offset - this.itemsPerPage, offset];
    // * this emit event is wrapped on a setTimeOut to perform ui change on following changeDetection cycle
    // * and allow angular to correctly sync the model and the view
    setTimeout(() => {
      this.renderRangeChange.next(this.renderRange);
    }, 0);
  }

  private createPagesArray(requiredPages: number): Array<number> {
    const newPageArray: Array<number> = [];
    if (requiredPages <= 0) {
      return newPageArray;
    }
    for (let k = 0; k < requiredPages; k++) {
      newPageArray.push(k);
    }
    return newPageArray;
  }

  public filterPagesButtonOnView(
    buttonsForPages: Array<number>,
    currentButtonSelectedIndex: number,
    buttonsOnView: number
  ): Array<number> {
    const { start, end } = SelectableButtonsRange.rangeDetector(
      buttonsForPages.length,
      currentButtonSelectedIndex,
      buttonsOnView
    );
    return buttonsForPages.slice(start, end);
  }
}
