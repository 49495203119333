export class DownloadOptionsConstants {
  public static MONTH_PERIOD = { value: 'Month', label: 'Month' };
  public static QUARTER_PERIOD = { value: 'Quarter', label: 'Quarter' };
  public static YEAR_PERIOD = { value: 'Year', label: 'Year' };
  public static TRANSACTION_PERIOD_OPTIONS = [
    DownloadOptionsConstants.MONTH_PERIOD,
    DownloadOptionsConstants.QUARTER_PERIOD,
    DownloadOptionsConstants.YEAR_PERIOD,
  ];

  public static MonthMap = class {
    public static MONTH_MAP = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };
  };
}
