<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Other How To's</h3>

  <ul class="how-to-list">
    <li>
      <button id="profile-icon" class="link" (click)="selectTopic(topics.ProfileIcon)">
        How do I change my profile icon?
      </button>
    </li>
    <li>
      <button id="update-contact-info" class="link" (click)="selectTopic(topics.UpdateContactInfo)">
        How do I update my contact information for my account?
      </button>
    </li>
    <li>
      <button id="change-password" class="link" (click)="selectTopic(topics.ChangePassword)">
        How do I change my password?
      </button>
    </li>
    <li>
      <button id="app-signer-changes" class="link" (click)="selectTopic(topics.SignerChanges)">
        What if I have signer changes?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-profile-icon *ngSwitchCase="topics.ProfileIcon"></how-to-profile-icon>
    <how-to-update-contact-info *ngSwitchCase="topics.UpdateContactInfo"></how-to-update-contact-info>
    <how-to-change-password *ngSwitchCase="topics.ChangePassword"></how-to-change-password>
    <app-signer-changes *ngSwitchCase="topics.SignerChanges"></app-signer-changes>
  </ng-container>
</ng-template>
