<div class="sm-submenu frow">
  <app-sm-item
    *ngFor="let profile of userBusinessProfiles"
    class="col-12 col-sm-6"
    [name]="profile.name"
    (click)="handleItemClick(profile.id, false)"
  ></app-sm-item>
</div>

<app-dot-loader [showWhen]="isLoading">Loading</app-dot-loader>
