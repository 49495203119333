import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { IStateService } from 'angular-ui-router';
import NgRedux from 'ng-redux';
import { Store } from '@ngrx/store';
import { IRootScopeService } from 'angular';
import { ReceiptSettings } from 'transfers/receipt/typings/ReceiptSettings';
import { FundingActions } from 'state-store/actions/funding.actions';
import { fundAccount, turnOffFunding } from '@app/store/funding/funding.actions';
import { olbSettings, STATE, ngRedux, ROOT_SCOPE } from '@core/tokens';

@Component({
  selector: 'app-funding-receipt',
  templateUrl: './funding-receipt.component.html',
  styleUrls: ['./funding-receipt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundingReceiptComponent implements OnInit {
  hasFailed = false;
  settings: ReceiptSettings;
  isTradingAccount = false;

  constructor(
    @Inject(olbSettings) public env: OlbSettings,
    @Inject(STATE) private state: IStateService,
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    @Inject(ROOT_SCOPE) private rootScope: IRootScopeService,
    private store: Store
  ) {}

  // returns if there are more accounts to fund and is used to render some buttons in the template
  get moreAccountsToFund(): boolean {
    return this.ngRedux.getState().funding.accountsToBeFunded.length > 0;
  }

  ngOnInit() {
    const { funding } = this.ngRedux.getState();
    const account = funding.accountsToBeFunded.find((account: OlbAccount) => account.id === funding.accountId);
    this.isTradingAccount = account.accountType === 'trading';

    this.settings = this.state.params.settings;
    if (!this.settings) {
      this.hasFailed = true;
      history.back();

      return;
    }

    this.removeFundedAccount();
  }

  // terminates funding flow and redirects to external accounts list
  goToViewAccounts() {
    this.ngRedux.dispatch(FundingActions.turnOffFunding());
    this.store.dispatch(turnOffFunding());
    sessionStorage.removeItem('accountsToBeFunded');
    const fundingPromise = this.rootScope['accountsWithOutFundsPromise'];
    fundingPromise.resolve();

    this.state.go('udb.accounts.dashboard');
  }

  // Removes just funded account freom the redux store and from sessionStoage too
  private removeFundedAccount() {
    const accountsToBeFunded: OlbAccount[] = JSON.parse(sessionStorage.getItem('accountsToBeFunded'));

    const fundedAccountId = this.ngRedux.getState().funding.accountId;
    this.ngRedux.dispatch(FundingActions.onFundedAccount());
    this.store.dispatch(fundAccount());

    // this entry is set up when coming from enrollment, it won't have a value if funding comes from modal
    if (!accountsToBeFunded) return;

    const justFundedAccountIndex = accountsToBeFunded.findIndex(i => i.id == fundedAccountId);

    accountsToBeFunded.splice(justFundedAccountIndex, 1);
    sessionStorage.setItem('accountsToBeFunded', JSON.stringify(accountsToBeFunded));
  }
}
