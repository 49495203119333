import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { Subject } from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators';
import { DATE_FORMAT } from '@app/Areas/AAS/features/account-details/view/components/people-tab/constants/constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-primary-beneficiary',
  templateUrl: './primary-beneficiary.component.html',
  styleUrls: ['./primary-beneficiary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class PrimaryBeneficiaryComponent implements OnInit, OnDestroy {
  primaryBeneficiaries: Array<{
    name: string;
    birthDate: string;
    primaryPercentage: number;
    spouse: boolean;
  }>;
  readonly destroy$ = new Subject<void>();

  constructor(
    private peopleFacade: PeopleFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.peopleFacade.accountPeopleTabState$
      .pipe(
        takeUntil(this.destroy$),
        filter(accountPeopleTabState => !!accountPeopleTabState?.accountPeople?.primaryBeneficiaries),
        map(accountPeopleTabState => {
          return accountPeopleTabState.accountPeople.primaryBeneficiaries
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => b.primaryPercentage - a.primaryPercentage);
        })
      )
      .subscribe(primaryBeneficiaries => {
        this.primaryBeneficiaries = primaryBeneficiaries.map(pb => ({
          name: pb.name,
          birthDate: this.formatBirthDate(pb.birthDate),
          primaryPercentage: pb.primaryPercentage,
          spouse: pb.spouse,
        }));
        this.changeDetectorRef.markForCheck();
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatBirthDate(birthDate: Date): string {
    const defaultDateTimeValue = new Date('0001-01-01T00:00:00');
    const birthDateTimeValue = new Date(birthDate);
    return birthDateTimeValue > defaultDateTimeValue ? this.datePipe.transform(birthDate, DATE_FORMAT) : '';
  }
}
