import { validPhoneRegex } from '@app/config/regexes';
import { Phone } from '@shared/models';

/**
 * Sanitizes a phone number by making it empty if it is all zeroes or not 10 digits long.
 *
 * @param phone Phone object that has the number to sanitize.
 *
 * @returns A string with the sanitized phone number.
 */
export function sanitizePhone(phone: Partial<Phone>): string {
  if (validPhoneRegex.test(phone.number) && phone.number !== '0000000000') {
    return phone.number;
  }

  return '';
}
