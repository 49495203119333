import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q')
class AlertsSmsService extends BaseService implements IAlertsSmsService {
  constructor(http: ng.IHttpService, env: any, serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'usersmsconfiguration', serviceHelper, q);
  }

  getConfiguration(): ApiResponse<any> {
    return this.get();
  }

  saveConfiguration(transactions: number): ApiResponse<any> {
    return this.put(transactions.toString());
  }

  saveAccountsConfiguration(accounts: SmsConfiguration): ApiResponse<any> {
    return this.put('transferAccounts', accounts);
  }
}

export { AlertsSmsService };
