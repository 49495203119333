export enum ProductType {
  NotSet,
  AutoLoan,
  CD,
  Checking,
  HELOC,
  IRACD,
  IRASavings,
  MoneyMarket,
  Mortgage,
  OverdraftlineOfCredit,
  PersonalLoan,
  Savings,
  CAndI,
  PaymentProtectionProgram,
}
