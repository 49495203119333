import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AccountCategory } from '@app/accounts/enums';
import { AggregatedAccount, OpenAccountItem } from '@app/accounts/models';
import { Bank } from '@shared/models';
import { accountType } from '@legacy/accounts/account-type.enum';
import { containerType } from '@legacy/accounts/container-type.enum';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { SubSink } from '@axos/subsink';
import NgRedux from 'ng-redux';
import { IRootScopeService } from 'angular';
import {
  AccountsService,
  AxosAdvisoryService,
  ProviderService,
} from '@core/services';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { AxosInvestHelperService } from '@app/core/services/axos-invest.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { DashboardBalanceService } from '@legacy/services/dashboard-balance.service';
import { AxosClearingService } from '@app/axos-invest/services';
import { CachedTradingAccountsService } from '@legacy/services/cached-trading-accounts.service';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { IStateParamsService } from 'angular-ui-router';
import { Store } from '@ngrx/store';
import { getProviders } from '@app/accounts/store/selectors';
import { finalize, skip, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { Banks } from '@legacy/dashboard/account-aggregation/typings/Bank';
import { AggregationStatus } from '@legacy/typings/app/account-aggregation/AuthenticatedInstitution';
import { TradingAccount } from '@app/axos-trading/models';
import { ClearingAccountStatus } from '@legacy/common/enums/clearingAccountStatus.enum';
import { getInvestAccount } from '@app/axos-invest/store/selectors';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryAccount } from '@core/models';
import {
  ROOT_SCOPE,
  olbSettings,
  STATE,
  STATE_PARAMS,
  ngRedux,
} from '@core/tokens';
import { AccountsMenuItem } from '@legacy/dashboard/account-aggregation/typings/AccountsMenuItem';
import { RedirectStateService } from '@legacy/services/RedirectStateService';

@Component({
  selector: 'app-dashboard-accounts',
  templateUrl: './dashboard-accounts.component.html',
  styleUrls: ['./dashboard-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardAccountsComponent implements OnInit, OnDestroy {
  isLoadingClosedAccounts = true;
  hasAccounts = false;
  hasOpenAccounts = false;
  hasClosedAccounts = false;
  totalAssets: number;
  totalDebt: number;
  banks: Bank[];
  isAccountAggregationActive = false;
  isAccountAggregationEnhancementsActive = false;
  openAccountsPrefix = 'OpenAccounts_';
  riaAcctsSuffix = ['Mortgage', 'Personal', 'Auto', 'Checking'];
  toolTipContent =
    'Total of the balances listed below for checking, savings, investments, and other asset accounts.';
  questionToolTipContent =
    'Total of the balances listed below for mortgages, loans, credit cards, and other debt accounts.';
  openAccountItems: OpenAccountItem[];
  isOpenAccountsItemsLoaded = false;
  showAxosAccounts = false;
  messageTrasferTooltip: string;
  successMessage: string;
  externalAccounts: AggregatedAccount[] = [];

  allAccounts = {
    checkingAccounts: new Array<AggregatedAccount>(),
    savingAccounts: new Array<AggregatedAccount>(),
    investingAccounts: new Array<AggregatedAccount>(),
    loanAccounts: new Array<AggregatedAccount>(),
    creditCards: new Array<AggregatedAccount>(),
    others: new Array<AggregatedAccount>(),
    closedAccounts: new Array<OlbAccount>(),
  };
  onlyAxosAccounts = { ...this.allAccounts };
  accountsToDisplay = { ...this.allAccounts };
  areTradingAccountsLoaded: boolean;
  isAxosInvestActive: boolean;
  isAxosTradingActive: boolean;
  hash: string;
  messageType = 'success';
  balancesAvailable: boolean;
  accountType = accountType;
  containerType = containerType;
  accountCategory = AccountCategory;
  toggleText = '';
  isSBBActive = false;
  providersLoading = true;
  flowTypeEnum = FlowType;
  isBusinessProfile = false;
  pendingActivation = false;
  NO_LONGER_TEEN = 18;
  isTeen = false;
  isRiaUser = false;
  isBusy = true;
  riaAccounts: AxosAdvisoryAccount[];
  checkingSuffix = 'Checking';
  hasAxosAdvisory = false;
  hasRiaPilotOpenCheckingAccount: boolean;
  private hasAxosCheckingAccount = false;
  private subsink = new SubSink();
  private listeners: Function[] = [];
  private unsubscribe: Function;
  private closedTradingAccounts: AggregatedAccount[] = [];

  private openAccountsBusinessPrefix = 'Business';
  private excludeFromOpenAccountBusiness = 'BusinessOpenAccount';

  private PublicProductPage = new Subject<AccountsMenuItem[]>();
  private angularJSServices: any;
  openMenuItems: OpenAccountItem[] = [] as OpenAccountItem[];
  isOpenMenuItemsLoaded: boolean = false;
  isCryptoPilotActive: boolean;
  accountDetails: any;
  newAccountDetails: any;

  constructor(
    @Inject(ROOT_SCOPE) private rootScope: IRootScopeService,
    @Inject('$scope') private scope: ng.IScope,
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    private accountsService: AccountsService,
    private serviceHelper: ServiceHelper,
    private cachedAccountsService: CachedAccountsService,
    @Inject(olbSettings) public env: OlbSettings,
    private featureFlagService: FeatureFlagService,
    private redirectService: RedirectStateService,
    @Inject(STATE) private stateService: ng.ui.IStateService,
    private axosInvestHelper: AxosInvestHelperService,
    private dashboardBalanceService: DashboardBalanceService,
    private axosClearingService: AxosClearingService,
    private cachedClearingAccounts: CachedTradingAccountsService,
    private accountAggregationService: AccountAggregationService,
    @Inject(STATE_PARAMS) private params: IStateParamsService,
    private store: Store,
    private axosAdvisoryService: AxosAdvisoryService,
    private providersService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /** Initializes the controller. */
  ngOnInit(): void {
    this.getAccountsMenuItem();
    this.hasRiaPilotOpenCheckingAccount = this.featureFlagService.isRiaPilotOpenCheckingAccount();

    this.subsink.sink = this.store
      .select(getProviders)
      .pipe(
        switchMap(data => {
          if (data === null) {
            return this.providersService.getProviders();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.accountDetails = data;
        this.providersLoading = false;
        this.changeDetectorRef.markForCheck();
      });

    // Comming from Aggregated accounts authentication
    this.setAggregatedAutehticationStatus();

    this.isAccountAggregationActive = this.featureFlagService.isAccountAggregationActive();
    this.isAccountAggregationEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    this.isAxosInvestActive = this.featureFlagService.isAxosInvestActive();
    this.isAxosTradingActive = this.featureFlagService.isAxosTradingActive();
    this.isSBBActive = this.featureFlagService.isSBBActive();

    if (this.isAccountAggregationEnhancementsActive)
      this.banks = [...Banks].slice(0, 4);
    else this.banks = [...Banks].slice(0, 6);

    this.isAccountAggregationActive &&
      (this.unsubscribe = this.ngRedux.subscribe(
        this.loadAccountsInformation.bind(this)
      ));

    if (this.rootScope['balancesAvailable']) {
      setTimeout(() => {
        this.hash = this.params['param'];
        if (this.hash != null) this.serviceHelper.scrollTo(this.hash);

        if (this.hash === 'transferOnly') this.pendingActivation = true;
      }, 0);
    }

    if (this.rootScope['hasAxosAdvisory']) {
      this.hasAxosAdvisory = this.rootScope['hasAxosAdvisory'];
    }

    this.listeners.push(
      this.scope.$on(
        'accountsLiteLoaded',
        this.loadAccountsInformation.bind(this)
      ),
      this.scope.$on(
        'balancesAvailable',
        this.loadAccountsInformation.bind(this)
      ),
      this.scope.$on(
        'investAccountsLoaded',
        this.loadInvestingAccounts.bind(this)
      ),
      this.scope.$on('balancesAvailable', () => {
        setTimeout(() => {
          this.hash = this.params['param'];
          if (this.hash != null) this.serviceHelper.scrollTo(this.hash);
        }, 0);
      }),
      this.scope.$on('closedAccountsLoaded', this.loadClosedAccounts.bind(this))
    );

    if (this.rootScope['isInvestAccountsLoaded']) {
      this.loadInvestingAccounts();
    }

    // after loading view it will refresh Investing accounts list
    this.store
      .select(getAxosAdvisoryAccounts)
      .pipe(skip(1), take(1))
      .subscribe(() => {
        this.accountsToDisplay.investingAccounts = [];
        if (this.rootScope['isInvestAccountsLoaded']) {
          this.loadInvestingAccounts();
        }
      });

    if (this.rootScope['accounts']) {
      this.loadAccountsInformation();
    }

    this.loadOpenAccountsButtonsData();
    if (this.rootScope['brandProperties']) {
      this.toggleText = this.rootScope['brandProperties']['AccountToggleText'];
    } else {
      this.rootScope.$on('brandPropertiesLoaded', () => {
        this.toggleText = this.rootScope['brandProperties'][
          'AccountToggleText'
        ];
      });
    }

    if (this.rootScope['closedAccounts']) {
      this.loadClosedAccounts();
    }

    if (this.rootScope['profileInfo']) {
      this.isTeen =
        moment().diff(this.rootScope['profileInfo'].dateOfBirth, 'year') <
        this.NO_LONGER_TEEN;
    } else {
      this.rootScope.$on('profileInfo', () => {
        if (this.rootScope['profileInfo']) {
          this.isTeen =
            moment().diff(this.rootScope['profileInfo'].dateOfBirth, 'year') <
            this.NO_LONGER_TEEN;
        }
      });
    }
  }

  /** Destroys the controller's listeners  */
  ngOnDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
    this.unsubscribe();
  }

  setAggregatedAutehticationStatus(): void {
    const aggAuthStatus = +this.params['authStatus'];
    const authErrorMsg = this.params['authErrorMsg'];
    const accountNickName = this.params['accountNickName'];
    if (aggAuthStatus == null && accountNickName == null) {
      return;
    }
    if (!isNaN(aggAuthStatus)) {
      switch (aggAuthStatus) {
        case AggregationStatus.SUCCESS:
          this.successMessage = `Success! Your ${accountNickName} Account has been updated.`;
          break;
        case AggregationStatus.FAILED:
          this.successMessage = `There was a problem accessing your ${
            accountNickName || ''
          } Account. ${
            authErrorMsg ? '<br>' + authErrorMsg : 'Please try again.'
          }`;
          this.messageType = 'error';
          break;
      }
      this.serviceHelper.scrollToTop();
    }
  }
  /**
   * Stores the new order making a call to the responsible service
   * @param order
   * @param sortedAccounts
   */
  saveSortedAccounts(sortedAccounts: AggregatedAccount[]): void {
    const account = sortedAccounts[0];
    const closedAccounts = account.statusCode === '2';
    let isDeposit = true;
    let internalAccountsFiltered = [...sortedAccounts].filter(
      account => !account.isExternal
    );

    if (!closedAccounts) {
      // depending on which category was sorted, other categories can be added at the beginning or ending of 'accountsOrdered' array
      switch (account.category) {
        case AccountCategory.Dda:
          if (this.showAxosAccounts) {
            this.allAccounts.checkingAccounts = this.matchAllAccountsOrdered(
              internalAccountsFiltered,
              this.allAccounts.checkingAccounts
            );
          } else
            this.onlyAxosAccounts.checkingAccounts = internalAccountsFiltered;

          internalAccountsFiltered = [
            ...internalAccountsFiltered,
            ...this.onlyAxosAccounts.savingAccounts,
          ];
          break;
        case AccountCategory.Sav:
        case AccountCategory.Cd:
          if (this.showAxosAccounts) {
            this.allAccounts.savingAccounts = this.matchAllAccountsOrdered(
              internalAccountsFiltered,
              this.allAccounts.savingAccounts
            );
          } else
            this.onlyAxosAccounts.savingAccounts = internalAccountsFiltered;

          internalAccountsFiltered = [
            ...this.onlyAxosAccounts.checkingAccounts,
            ...internalAccountsFiltered,
          ];
          break;
        case AccountCategory.Loan:
          if (this.showAxosAccounts) {
            this.allAccounts.loanAccounts = this.matchAllAccountsOrdered(
              internalAccountsFiltered,
              this.allAccounts.loanAccounts
            );
          } else this.onlyAxosAccounts.loanAccounts = internalAccountsFiltered;

          isDeposit = false;
          break;
      }
    }

    internalAccountsFiltered = closedAccounts
      ? sortedAccounts
      : internalAccountsFiltered.filter(account => !account.isAxosInvest);

    const order: any = {};
    angular.forEach(internalAccountsFiltered, (acc, idx) => {
      order[acc.id] = idx;
    });

    this.accountsService.saveSortedAccounts(order).subscribe({
      next: () => {
        if (closedAccounts) return;
        const accountType = isDeposit ? 'depositAccounts' : 'loanAccounts';

        this.rootScope['accounts'][accountType] = internalAccountsFiltered;
      },
    });
  }

  getBankLogo(bankName: string): any {
    return `${bankName}`;
  }

  /**
   * Save the angular state to return if the account aggregation
   * is canceled and redirects to accont aggregation */
  redirectToAccAggr(): void {
    this.redirectService.setOriginalState(
      this.stateService.current.name,
      this.stateService.current.params
    );
    const isAccAggrEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    this.stateService.go('udb.dashboard.account-aggregation', {
      flow: this.flowTypeEnum.Aggregation,
      isAccountAggregationFlow: isAccAggrEnhancementsActive,
    });
  }

  /**Load open accounts data from root prperties, if not loaded register a listener and wait for the data,
   * after loaded pass it to the parser */
  loadOpenAccountsButtonsData() {
    if (this.rootScope['brandProperties']) {
      this.parseOpenAccountsObjects(this.rootScope['brandProperties']);
    } else {
      this.rootScope.$on('brandPropertiesLoaded', () => {
        this.parseOpenAccountsObjects(this.rootScope['brandProperties']);
      });
    }
  }

  arrayHasAccounts(accountsArray: AggregatedAccount[]) {
    return !!accountsArray && !!accountsArray.length;
  }

  getGroupTitleForClosedAccounts(accountsArray: AggregatedAccount[]): string {
    return 'Closed (' + accountsArray.length + ')';
  }

  getAccountOperationMessage(messageText) {
    this.successMessage = messageText.message;
    this.loadExternalAccounts();
    this.serviceHelper.scrollToTop();
  }
  getRemovedAccountOperationMessage(messageText) {
    this.successMessage = messageText.message;
    this.serviceHelper.scrollToTop();
    this.loadOtherAccountsInformation();
  }
  handleNicknameUpdate(account) {
    const accountId: number = account.accountId;
    const isAnUpdate: boolean = account.isAnUpdate;
    this.externalAccounts.forEach(item => {
      if (item.id === accountId && isAnUpdate) {
        item.isEditing = true;

        return;
      } else {
        item.isEditing = false;
        item.nickname = this.cachedAccountsService.externalAccounts.find(
          exItem => exItem.externalAccountId === item.id
        ).nickname;
      }
    });
  }

  private matchAllAccountsOrdered(
    sortedAccounts: AggregatedAccount[],
    accounts: AggregatedAccount[]
  ) {
    const accountsMatched = sortedAccounts.filter(ac =>
      accounts.find(sa => sa.id === ac.id)
    );

    const accountsNotMatched = accounts.filter(
      ac => !accountsMatched.find(am => am.id === ac.id)
    );

    return [...accountsMatched, ...accountsNotMatched];
  }

  /**Gets the open account property keys, extracts from rootScope and parses into items*/
  private parseOpenAccountsObjects(properties: any[]) {
    if (!this.isBusy || !this.featureFlagService.isRiaPilotActive()) {
      let keys: string[] = [];
      if (this.isSBBActive) {
        keys = Object.keys(properties).filter(
          x =>
            x.startsWith(this.openAccountsBusinessPrefix) &&
            x !== this.excludeFromOpenAccountBusiness
        );
      } else if (this.isRiaUser) {
        keys = this.parseOpenAccountsObjectsForRiaUser(properties);
      } else {
        keys = Object.keys(properties).filter(x =>
          x.startsWith(this.openAccountsPrefix)
        );
      }

      const items = keys.map(key => {
        const value = this.rootScope['brandProperties'][key];
        const values = value.split('|');

        const item: OpenAccountItem = {
          description: values[0],
          icon: values[1],
          redirectionUrl:
            this.isRiaUser && values[0].includes(this.checkingSuffix)
              ? this.env.axosInvestorChecking
              : values[2],
        };

        return item;
      });

      if (items && items.length > 0) {
        this.openAccountItems = items;
        this.isOpenAccountsItemsLoaded = true;
      }
    }
  }

  private parseOpenAccountsObjectsForRiaUser(properties: any[]) {
    return this.hasRiaPilotOpenCheckingAccount
      ? Object.keys(properties).filter(
          x =>
            x.startsWith(this.openAccountsPrefix) &&
            this.riaAcctsSuffix.some(s => x.endsWith(s))
        )
      : Object.keys(properties).filter(
          x =>
            x.startsWith(this.openAccountsPrefix) &&
            this.riaAcctsSuffix.some(s => x.endsWith(s)) &&
            (this.hasAxosCheckingAccount
              ? !x.includes(this.checkingSuffix)
              : true)
        );
  }

  /** Loads and handles all the accounts information to display it correctly */
  private loadAccountsInformation(): void {
    const depositAccounts = this.cachedAccountsService.internalAccounts
      ? this.cachedAccountsService.internalAccounts.depositAccounts
      : [];
    const loanAccounts = this.cachedAccountsService.internalAccounts
      ? this.cachedAccountsService.internalAccounts.loanAccounts
      : [];

    let externalAccounts = this.isAccountAggregationActive
      ? this.cachedAccountsService.aggregatedAccounts || []
      : [];
    externalAccounts =
      externalAccounts instanceof Error ? [] : externalAccounts;

    // checking accounts
    let accounts = this.filterAccounts(
      depositAccounts,
      externalAccounts,
      (account: AggregatedAccount) => account.category === AccountCategory.Dda
    );
    this.onlyAxosAccounts.checkingAccounts = accounts.onlyAxosAccounts;
    this.allAccounts.checkingAccounts = accounts.allAccounts;

    // saving accounts
    accounts = this.filterAccounts(
      depositAccounts,
      externalAccounts,
      (account: AggregatedAccount) =>
        account.container !== this.containerType.Investment &&
        (account.category === AccountCategory.Sav ||
          account.category === AccountCategory.Cd)
    );
    this.onlyAxosAccounts.savingAccounts = accounts.onlyAxosAccounts;
    this.allAccounts.savingAccounts = accounts.allAccounts;

    // loan accounts
    accounts = this.filterAccounts(
      loanAccounts,
      externalAccounts,
      (account: AggregatedAccount) => account.category === AccountCategory.Loan
    );
    this.onlyAxosAccounts.loanAccounts = accounts.onlyAxosAccounts;
    this.allAccounts.loanAccounts = accounts.allAccounts;

    // creadit cards
    const creditCards = this.filterAccounts(
      depositAccounts,
      externalAccounts,
      (account: AggregatedAccount) =>
        account.category === AccountCategory.Credit
    );
    this.onlyAxosAccounts.creditCards = creditCards.onlyAxosAccounts;
    this.allAccounts.creditCards = creditCards.allAccounts;

    // investment dag accounts
    const investmentDagAccounts = externalAccounts.filter(
      (x: AggregatedAccount) => x.container === this.containerType.Investment
    );
    this.allAccounts.investingAccounts = Array.from(
      new Set(
        this.allAccounts.investingAccounts.concat([...investmentDagAccounts])
      )
    );

    // other accounts
    const others = this.filterAccounts(
      depositAccounts,
      externalAccounts,
      (account: AggregatedAccount) => account.category === AccountCategory.Other
    );
    this.onlyAxosAccounts.others = others.onlyAxosAccounts;
    this.allAccounts.others = others.allAccounts;

    this.hasOpenAccounts = !!(
      this.hasOpenAccounts ||
      (this.allAccounts.checkingAccounts &&
        this.allAccounts.checkingAccounts.length) ||
      (this.allAccounts.savingAccounts &&
        this.allAccounts.savingAccounts.length) ||
      (this.allAccounts.loanAccounts && this.allAccounts.loanAccounts.length)
    );

    this.displayOnlyAxosAccounts(this.showAxosAccounts);

    if ((this.balancesAvailable = this.rootScope['balancesAvailable'])) {
      this.calculateTotalBalances(this.accountsToDisplay);
    }

    // External Accounts
    this.loadExternalAccounts();

    this.checkForAccounts();
  }
  private loadOtherAccountsInformation(): void {
    const { depositAccounts } = this.cachedAccountsService.internalAccounts;

    let externalAccounts = this.isAccountAggregationActive
      ? this.cachedAccountsService.aggregatedAccounts || []
      : [];
    externalAccounts =
      externalAccounts instanceof Error ? [] : externalAccounts;

    // other accounts
    const others = this.filterAccounts(
      depositAccounts,
      externalAccounts,
      (account: AggregatedAccount) => account.category === AccountCategory.Other
    );
    this.onlyAxosAccounts.others = others.onlyAxosAccounts;
    this.allAccounts.others = others.allAccounts;

    if ((this.balancesAvailable = this.rootScope['balancesAvailable'])) {
      this.calculateTotalBalances(this.accountsToDisplay);
    }
  }
  private async loadInvestingAccounts(): Promise<void> {
    let tradingAccounts: AggregatedAccount[] = [];
    let investingAccount: AggregatedAccount = null;
    let accounts: AggregatedAccount[] = [];
    let axosAdvisoryAccounts: AggregatedAccount[] = [];

    if (this.axosClearingService.isAxosTradingActiveForUser()) {
      tradingAccounts = this.setAxosTradingAccounts();
    } else {
      this.areTradingAccountsLoaded = true;
    }

    if (
      this.featureFlagService.isAxosInvestActive() &&
      this.axosInvestHelper.hasAxosInvest
    ) {
      investingAccount = await this.setAxosInvestAccount();
    }

    if (this.featureFlagService.isRiaPilotActive() && this.hasAxosAdvisory) {
      axosAdvisoryAccounts = await this.setAxosAdvisoryAccount();
    } else {
      this.isBusy = false;
      this.changeDetectorRef.detectChanges();
    }

    this.closedTradingAccounts = tradingAccounts.filter(t => !t.active);
    accounts = accounts.concat(...tradingAccounts.filter(t => t.active));

    const investingAccounts = [];
    let tempAllAccounts = [];

    if (
      this.featureFlagService.isAxosInvestActive() &&
      this.axosInvestHelper.hasAxosInvest &&
      investingAccount?.status !== 'Closed'
    ) {
      tempAllAccounts = tempAllAccounts.concat([...accounts, investingAccount]);
      investingAccounts.push(investingAccount);
      this.hasOpenAccounts = !!(
        this.hasOpenAccounts ||
        (tempAllAccounts && tempAllAccounts.length)
      );
    } else {
      // add invest account to closed section
      if (
        this.accountsToDisplay.closedAccounts.length === 0 &&
        investingAccount
      ) {
        this.allAccounts.closedAccounts.push(investingAccount);
        this.onlyAxosAccounts.closedAccounts.push(investingAccount);
      }
    }
    if (axosAdvisoryAccounts.length !== 0) {
      this.hasOpenAccounts = !!(
        this.hasOpenAccounts || axosAdvisoryAccounts.length
      );
    }

    this.loadAggregatedInvestmentAccounts();

    this.allAccounts.investingAccounts = [
      ...axosAdvisoryAccounts,
      ...accounts,
      ...investingAccounts,
      ...this.allAccounts.investingAccounts,
    ];
    this.onlyAxosAccounts.investingAccounts = [
      ...axosAdvisoryAccounts,
      ...accounts,
      ...investingAccounts,
    ];

    this.allAccounts.closedAccounts = this.allAccounts.closedAccounts.concat(
      ...this.closedTradingAccounts
    );
    this.onlyAxosAccounts.closedAccounts = this.onlyAxosAccounts.closedAccounts.concat(
      ...this.closedTradingAccounts
    );

    this.displayOnlyAxosAccounts(this.showAxosAccounts);
    this.checkForAccounts();
    this.changeDetectorRef.detectChanges();
  }

  private filterAccounts(
    internalAccounts: AggregatedAccount[],
    externalAccounts: AggregatedAccount[],
    condition: Function
  ): any {
    let onlyAxosAccounts: AggregatedAccount[];
    let allAccounts: AggregatedAccount[];

    onlyAxosAccounts = [...internalAccounts].filter(account =>
      condition(account)
    );
    allAccounts = [...internalAccounts, ...externalAccounts].filter(account =>
      condition(account)
    );

    const accounts = {
      onlyAxosAccounts,
      allAccounts,
    };

    return accounts;
  }

  private calculateTotalBalances(accounts: any) {
    this.totalAssets = [
      ...accounts.checkingAccounts,
      ...accounts.savingAccounts,
      ...accounts.others,
      ...accounts.creditCards,
      ...accounts.investingAccounts,
    ].reduce((prev: number, next: AggregatedAccount) => {
      if (next != null)
        return prev + this.dashboardBalanceService.getBalanceAsset(next);
      else return prev;
    }, 0);

    this.totalDebt = [
      ...accounts.loanAccounts,
      ...accounts.creditCards,
      ...accounts.others,
    ].reduce((prev: number, next: AggregatedAccount) => {
      if (next != null)
        return prev + this.dashboardBalanceService.getBalanceDebt(next);
      else return prev;
    }, 0);
  }

  private loadClosedAccounts() {
    this.isLoadingClosedAccounts = true;
    if (this.rootScope['closedAccounts']) {
      this.allAccounts.closedAccounts = this.rootScope['closedAccounts'];
      this.onlyAxosAccounts.closedAccounts = [
        ...this.allAccounts.closedAccounts,
      ];
      this.hasClosedAccounts = !!(
        this.hasOpenAccounts ||
        (this.allAccounts.closedAccounts &&
          this.allAccounts.closedAccounts.length)
      );
      this.isLoadingClosedAccounts = false;
    } else {
      this.accountsService
        .getClosedAccounts()
        .pipe(
          finalize(() => {
            this.isLoadingClosedAccounts = false;
          })
        )
        .subscribe({
          next: ({ data }) => {
            this.allAccounts.closedAccounts = data;
            this.onlyAxosAccounts.closedAccounts = [
              ...this.allAccounts.closedAccounts,
            ];
            this.hasClosedAccounts = !!(
              this.hasOpenAccounts ||
              (this.allAccounts.closedAccounts &&
                this.allAccounts.closedAccounts.length)
            );
          },
          error: this.serviceHelper.errorHandler,
        });
    }

    this.allAccounts.closedAccounts = this.allAccounts.closedAccounts.concat(
      ...this.closedTradingAccounts
    );
    this.onlyAxosAccounts.closedAccounts = this.onlyAxosAccounts.closedAccounts.concat(
      ...this.closedTradingAccounts
    );

    this.displayOnlyAxosAccounts(this.showAxosAccounts);
  }

  private setAxosTradingAccounts(): AggregatedAccount[] {
    if (this.cachedClearingAccounts.tradingAccounts) {
      this.areTradingAccountsLoaded = true;

      return this.cachedClearingAccounts.tradingAccounts.map(
        (a: TradingAccount) =>
          ({
            id: a.id,
            name: a.nickname,
            bankName: a.bankName,
            availableBalance: a.amountAvailableToWithdraw,
            isTrading: true,
            active: +a.status !== ClearingAccountStatus.Closed,
            status:
              +a.status !== ClearingAccountStatus.Closed ? 'active' : 'closed',
          } as AggregatedAccount)
      );
    }

    return [];
  }

  private async setAxosInvestAccount() {
    const notifier = new Subject();

    return new Promise(resolve => {
      this.subsink.sink = this.store
        .select(getInvestAccount)
        .pipe(takeUntil(notifier))
        .subscribe(account => {
          if (!account.isLoaded) return;

          notifier.next();
          notifier.complete();
          resolve({
            ...account,
          });
        });
    });
  }

  private async setAxosAdvisoryAccount(): Promise<AggregatedAccount[]> {
    const notifier = new Subject();

    return new Promise(resolve => {
      this.subsink.sink = this.store
        .select(getAxosAdvisoryAccounts)
        .pipe(takeUntil(notifier))
        .subscribe((account: AxosAdvisoryAccount[]) => {
          if (!account) {
            this.isBusy = false;
            this.checkingAccountValidation();
            return;
          }
          this.isRiaUser = true;
          this.checkingAccountValidation();
          notifier.next();
          notifier.complete();
          resolve(
            [
              ...account.map(
                (a: AxosAdvisoryAccount) =>
                  ({
                    id: +a.riaId,
                    name: a.type,
                    nickname: a.accountNickname,
                    bankName: a.displayName,
                    availableBalance: a.accountBalance,
                    accountNumber: a.accountNumber,
                    accountMask: a.accountNumber.substr(
                      a.accountNumber.length - 4
                    ),
                    active: a.status === 'active',
                    status: a.status,
                    isAxosAdvisory: true,
                    accountType: a.accountType,
                    accountTypeCode: a.accountTypeCode,
                    advisoryName: a.advisorName,
                    brandingName: a.brandingName,
                    displayedName: a.accountDisplayName,
                    isRetirement: a.isRetirement,
                    firstDepositDate: a.firstDepositDate,
                    type: a.type,
                    riaId: a.riaId,
                    clientPortalStatus: a.clientPortalStatus,
                  } as AggregatedAccount)
              ),
            ].sort((a, b) => +a.accountMask - +b.accountMask)
          );
        });
    });
  }

  displayOnlyAxosAccounts(onlyAxosAccounts: boolean) {
    this.accountsToDisplay = onlyAxosAccounts
      ? this.onlyAxosAccounts
      : this.allAccounts;
    this.calculateTotalBalances(this.accountsToDisplay);
  }

  //region Transfer Only
  // To add externalAccounts

  /** Gets all of the users external accounts*/
  private loadExternalAccounts(): void {
    const editAccount = false;
    let externalAccounts = [...this.cachedAccountsService.externalAccounts];
    if (!externalAccounts) return;
    // if the external account is also in yodle accounts then it is not shown in 'transfer only' section,
    // unless that account is pending of activation or in progress
    if (this.cachedAccountsService.aggregatedAccounts) {
      externalAccounts = externalAccounts.filter(
        x =>
          !x.accountNumber ||
          x.status === 'PendingActivation' ||
          x.status === 'Pending Activation' ||
          x.status === 'InProcess' ||
          x.status === 'In Process' ||
          !this.cachedAccountsService.aggregatedAccounts.some(
            a => x.accountNumber === a.accountNumber
          )
      );
    }

    this.externalAccounts = [];

    this.messageTrasferTooltip =
      'Transfer-only accounts can only be used to send and receive money. Balance and transaction information is not available.';

    this.externalAccounts = this.toAggregatedAccount(
      externalAccounts,
      editAccount
    );

    // Pending verification accounts go with external accounts in Transfer only section
    this.loadPendigVerificationAccounts();
  }
  /**
   * Include the pending verification accounts in the Trnasfer Only section
   */
  private loadPendigVerificationAccounts(): void {
    this.accountAggregationService
      .getPendigVerificationAccounts()
      .then((response: OlbResponse<ExternalAccount[]>) => {
        const accountsWithPendingVer = response.data;
        const pendingAccounts = this.toAggregatedAccount(
          accountsWithPendingVer,
          false
        );
        const noRepeatedPendingAccounts = pendingAccounts.filter(
          x =>
            !this.externalAccounts.some(
              e => e.accountNumber === x.accountNumber
            )
        );
        this.externalAccounts = this.externalAccounts.concat(
          noRepeatedPendingAccounts
        );
      });
  }
  private toAggregatedAccount(
    externalAccounts: ExternalAccount[],
    editAccount: boolean
  ): AggregatedAccount[] {
    const aggregatedAccounts = externalAccounts.map(
      (obj: ExternalAccount) =>
        ({
          bankName: obj.bankName,
          status: obj.status,
          externalAccountId: obj.externalAccountId,
          nickname: obj.nickname,
          isExternal: true,
          transferOnly: true,
          id: obj.externalAccountId,
          name: obj.nickname,
          accountNumber: obj.accountNumber,
          accountMask: obj.accountMask,
          productType: obj.accountCategory,
          isEditing: editAccount,
          routingNumber: obj.routingNumber,
          isPendingVerification: obj.isPendingVerification,
          isSBB: obj.isSBB,
          isAuthorizeTransfer: obj.isAuthorizeTransfer,
        } as AggregatedAccount)
    );

    return aggregatedAccounts;
  }

  private checkForAccounts() {
    const accounts = Object.keys(this.allAccounts)
      .map(e => (this.allAccounts as any)[e])
      .filter(a => a.length);
    this.hasAccounts = accounts.length > 0;
  }

  private checkingAccountValidation(): void {
    if (this.featureFlagService.isRiaPilotActive() && this.isRiaUser) {
      this.axosAdvisoryService
        .getHasAxosCheckingAccount()
        .subscribe(response => {
          if (response.data) {
            this.hasAxosCheckingAccount = true;
            if (this.openAccountItems) {
              this.openAccountItems = this.hasOpenAccountsRiaOpenAccount();
            }
          }
          this.isBusy = false;
          this.parseOpenAccountsObjects(this.rootScope['brandProperties']);
        });
    } else {
      this.isBusy = false;
      this.parseOpenAccountsObjects(this.rootScope['brandProperties']);
    }
  }

  private hasOpenAccountsRiaOpenAccount() {
    return this.hasRiaPilotOpenCheckingAccount
      ? this.openAccountItems
      : this.openAccountItems.filter(
          x => !x.description.includes(this.checkingSuffix)
        );
  }

  //endregion

  getAccountsMenuItem() {
    this.angularJSServices = angular
      .element(document.body)
      .injector()
      .get('personalizedContentService');
    this.angularJSServices.hasContent
      .then(() => {
        this.angularJSServices
          .GetPersonalizedContent('OLB', '/Accounts')
          .then(data => {
            this.PublicProductPage.next(
              data?.data?.data?.route?.placeholders?.main[0]?.placeholders
                ?.AccountsMenuItem
            );
          })
          .catch(err => console.warn(err))
          .finally(() => this.changeDetectorRef.detectChanges());
      })
      .catch(err => console.warn(err));

    this.PublicProductPage.subscribe(result => {
      result.forEach(item => {
        this.openMenuItems.push(this.mapMenuItems(item));
      });
      this.isOpenMenuItemsLoaded = true;
    });
  }

  mapMenuItems(element: AccountsMenuItem) {
    const item: OpenAccountItem = {} as OpenAccountItem;
    item.description = element.fields.Name;
    item.imageFile = element.fields.Icon;
    item.icon = element.fields.Icon.split('-')[1] + '.svg';
    item.redirectionUrl = element.fields.Url;
    return item;
  }

  private loadAggregatedInvestmentAccounts(): void {
    if (!this.isAccountAggregationActive) return;

    let externalAccounts = this.cachedAccountsService.aggregatedAccounts;
    if (!externalAccounts) return;

    const investmentDagAccounts = externalAccounts.filter(
      (x: AggregatedAccount) => x.container === this.containerType.Investment
    );
    this.allAccounts.investingAccounts = Array.from(
      new Set(
        this.allAccounts.investingAccounts.concat([...investmentDagAccounts])
      )
    );
  }
}
