import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AuthorizationsTabState } from '@app/Areas/AAS/features/account-details/core/store/types';
import { AuthorizationsFacade } from '@app/Areas/AAS/features/account-details/facade/authorizations.facade';
@Component({
  selector: 'app-authorizations-tab',
  templateUrl: './authorizations-tab.component.html',
  styleUrls: ['./authorizations-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorizationsTabComponent implements OnInit, OnDestroy {
  authorizationTabAccountState$: AuthorizationsTabState;
  readonly destroy$: Subject<void> = new Subject<void>();
  constructor(
    private readonly authorizationsFacade: AuthorizationsFacade,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.authorizationsFacade.authorizationsTabAccountState$
      .pipe(
        filter(state => state !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: tabState => {
          this.authorizationTabAccountState$ = tabState;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
