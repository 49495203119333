import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  RiaType,
  addRiaAction,
  getSelectedRia,
  loadRiasAction,
  selectRiaAction,
  getSelectedGlobalRia,
  selectGlobalRiaAction,
  getAllRias,
  isLoadedAction,
  isLoadedRias,
} from '../core';

@Injectable({
  providedIn: 'root',
})
export class RiaFacade {
  selectedRia$ = this.store.select(getSelectedRia);
  selectedGlobalRia$ = this.store.select(getSelectedGlobalRia);
  allRias$ = this.store.select(getAllRias);
  isLoading$ = this.store.select(isLoadedRias);

  constructor(private store: Store<RiaType>) {}

  loadRias(rias: Array<RiaType>): void {
    if (rias?.length > 0) {
      this.store.dispatch(
        loadRiasAction({
          payload: rias,
        })
      );
    } else {
      this.store.dispatch(
        loadRiasAction({
          payload: [],
        })
      );
    }
  }

  loadRia(ria: RiaType): void {
    this.store.dispatch(
      addRiaAction({
        payload: ria,
      })
    );
  }

  selectRia(riaName: string) {
    this.store.dispatch(
      selectRiaAction({
        payload: riaName,
      })
    );
  }

  selectGlobalRia(udbUserId: number) {
    this.store.dispatch(
      selectGlobalRiaAction({
        payload: udbUserId,
      })
    );
  }

  isLoaded(isLoaded: boolean) {
    this.store.dispatch(
      isLoadedAction({
        payload: isLoaded,
      })
    );
  }
}
