export class InvestAccount {
  id?: number | string;
  accountType = 'invest';
  availableBalance: number;
  bankName = '';
  hasError: boolean;
  isAxosInvest = true;
  name = 'Managed Portfolios';
  nickname = 'Managed Portfolios';
  status: string;
  isClosed: boolean;
  isLoaded: boolean;

  constructor(args?: Partial<InvestAccount>) {
    Object.assign(this, args);
  }
}
