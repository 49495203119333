import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import { setupTopBarBrandingAction } from '../main-layout.actions';
import { initialMainLayoutState, MainLayoutState } from '../types';

const reducer = createReducer(
  initialMainLayoutState,
  on(setupTopBarBrandingAction, (_state, action) => {
    return {
      topBar: {
        logoImgPath: action.payload.logoImgPath,
        organizationName: action.payload.organizationName,
        isAxos: action.payload.isAxos,
        urlRedirectionConfig: action.payload.urlRedirectionConfig,
      },
    };
  }),
  on(signOut, () => {
    return initialMainLayoutState;
  })
);

export function mainLayoutReducer(state: MainLayoutState, action: Action) {
  return reducer(state, action);
}
