import { Beneficiary } from '@shared/models/Beneficiary';

import { CanHaveDebitCardsType } from '../enums/can-have-debit-cards-type.enum';
import { AccountInterest } from '../models/account-interest.model';
import { AccountPayment } from '../models/account-payment.model';
import { DebitCard } from '../submodules/debit-card/models/debit-card.model';
import { AccountLineOfCreditDetail } from './account-line-of-credit-detail.model';

export class AccountDetail {
  cif: string;
  /** Account nickname */
  nickname: string;
  /** Account type */
  productType: number;
  relationshipType: string;
  /** Account number */
  accountNumber: string;
  /** Routing number */
  routingNumber: string;
  /** The date this account was opened (mm/dd/yyyy) */
  dateOpened: Date;
  /**
   * If the Account is a checking, Savings, or Money Market:
   * Cards linked to this account
   */
  linkedDebitCards: DebitCard[];
  /**
   * If the Account is a CD or IRA – CD
   * This is the date that the CD will become unlocked
   * and funds are available to be drawn against [mm/dd/yyyy]
   */
  maturityDate: Date;
  /** Check the age of the individual associated with the Account CIF */
  mandatoryDistribution: boolean;
  /** Account type defined for BofI (Checking, money market, etc.) */
  bofiAccountType: string;
  /** Bofi Product Category */
  category: number;
  /** Account Interest Details */
  accountInterest: AccountInterest;
  /** Account Payment Details */
  accountPayment: AccountPayment;
  collectedBalance: number;
  availableBalance: number;
  /** Current balance for TimeDeposit accounts */
  currentBalance: number;
  billedEscrow: number;
  billedPrincipal: number;
  totalAmountDue: number;
  canHaveDebitCards: CanHaveDebitCardsType;
  source: number;
  /** Signature Card properties */
  signatureCardStatus: number;
  signatureDescription: string;
  /** Determine whether to display the "IRA Minimum Distribution Required"
   * field on the Account Details page
   */
  displayMandatoryDistribution: boolean;
  billedInterest: number;
  billedLateCharges: number;
  billedOtherCharges: number;
  hasPrepaymentPenalty: boolean;
  prePaymentPenaltyCode: string;
  /** Ownership section */
  hasMinor: boolean;
  primaryOwnerName: string;
  associatedName: string;
  beneficiaries: Beneficiary[];
  statusCode: string;
  statementDescription: string;
  taxPlanBeneficiaries: TaxPlanBeneficiary[];
  taxPlanType: number;
  pendingSpousalConsent: boolean;
  additionalOwnerNames: string[];
  spousalConsentEmail: string;
  signatureCardStatusDescription: string;
  hasPrePaymentPenalty: boolean;
  /*HELOC details */
  accountLineOfCreditDetails: AccountLineOfCreditDetail;

  constructor(args: Partial<AccountDetail>) {
    Object.assign(this, args);
  }
}
