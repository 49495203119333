import * as angular from 'angular';
import { SendMoneyLegacyModule } from './send-money.legacy.module';
import { HistoryLegacyModule } from './history.legacy.module';
import { downgradeComponent } from '@angular/upgrade/static';
import { P2PComponent } from '@app/transfers/components';
import { RestrictionType } from '@legacy/common/enums/RestrictionType.enum';

export const P2PLegacyModule = angular
  .module('udb.p2p', ['ui.router', SendMoneyLegacyModule, HistoryLegacyModule])
  .directive('appP2p', downgradeComponent({ component: P2PComponent }))
  .config([
    '$stateProvider',
    (stateProvider: angular.ui.IStateProvider) => {
      stateProvider.state('udb.transfers.p2p', {
        url: '/payitnow',
        abstract: true,
        views: {
          'transfer-content': {
            template: `<app-p2p></app-p2p>`,
          },
        },
        resolve: {
          isRestricted: [
            'authHelper',
            (authHelper: IAuthHelper): any => {
              return authHelper.hasCustomerServiceRestriction(
                RestrictionType.P2P
              );
            },
          ],
        },
      });
    },
  ]).name;
