import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-pre-transfer-confirmation-modal',
  templateUrl: './pre-transfer-confirmation-modal.component.html',
  styleUrls: ['./pre-transfer-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreTransferConfirmationModalComponent {
  headerStr: string;
  footerStr: string;
  amount: string;
  selectedFrom: string;
  selectedTo: string;

  constructor(
    private dialogRef: MatDialogRef<PreTransferConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.headerStr = this.data.headerStr;
    this.footerStr = this.data.footerStr;
    this.amount = this.data.amount;
    this.selectedFrom = this.data.selectedFrom;
    this.selectedTo = this.data.selectedTo;
  }

  public close() {
    this.dialogRef.close(false);
  }

  public maskAccount(account: string): string {
    if (account.length > 20) {
      let split = account.split('-');
      let stringOne = split[0].substring(0, 3);
      let stringTwo = split[1].substring(0, 14);
      return (account = stringOne + stringTwo + '...');
    } else return account;
  }

  public confirm() {
    this.dialogRef.close(true);
  }
}
