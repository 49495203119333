export enum FinancialIcons {
  Bank = 'financial-bank',
  CoinCents = 'financial-coin-cents',
  CoinDollarSign = 'financial-coin-dollar-sign',
  DollarArrowForward = 'financial-dollar-arrow-forward',
  DollarArrowBack = 'financial-dollar-arrow-back',
  BankCheck = 'financial-bank-check',
  BankCheckStack = 'financial-bank-check-stack',
  BankCheckPencil = 'financial-bank-check-pencil',
  BankCheckArrowUp = 'financial-bank-check-arrow-up',
  Coins = 'financial-coins',
  Piggy = 'financial-piggy',
  Vault = 'financial-vault',
  CardHolder = 'financial-card-holder',
  CreditCard = 'financial-credit-card',
  Briefcase = 'financial-briefcase',
  AxosCard = 'financial-axos-card',
  DollarBill = 'financial-dollar-bill',
  DollarBillStack = 'financial-dollar-bill-stack',
  DollarBillArrowForward = 'financial-dollar-bill-arrow-forward',
  DollarBillCut = 'financial-dollar-bill-cut',
  MoneyHand = 'financial-money-hand',
  BarGraph = 'financial-bar-graph',
  LineGraph = 'financial-line-graph',
  PieChart = 'financial-pie-chart',
  Odometer = 'financial-odometer',
  ActivityGraph = 'financial-activity-graph',
  Percent = 'financial-percent',
  Cart = 'financial-cart',
}
