export enum OnlinePath {
  axosPhoneNumber = '(888) 502-2967',
  nwPhoneNumber = '(877) 541-2634',
  bankText = ' Bank',
  keyInstructions = 'instructions',
  htmlType = 'html',
  hrefTag = 'href',
  hrefSearch = 'href="',
  targetSearch = '" target="',
}
