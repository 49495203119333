import { Inject } from '../../../decorators/decorators';

@Inject('$stateProvider')
export class WireMultiFactorConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.transfers.wireTransfers.multiFactor', {
      url: '/multi-factor',
      params: { wiretransfer: null },
      views: {
        'wire-content': {
          templateUrl: 'transfers/wire-transfers/multi-factor/wire-multi-factor.tpl.html',
          controller: 'WireMultiFactorController',
          controllerAs: '$mf',
        },
      }
    });
  }
}
