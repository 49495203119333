import { AggregatedAccount } from 'typings/app/account-aggregation';

import { ACCOUNTS } from '../../constants/accounts';

export const AggregatedAccountActions = {
  loadAggregatedAccounts: (accounts: AggregatedAccount[] | Error) => {
    return {
      type: ACCOUNTS.AGGREGATED.LOAD,
      payload: accounts,
    };
  },

  addAggregatedAccounts: (accounts: AggregatedAccount[]) => {
    return {
      type: ACCOUNTS.AGGREGATED.ADD,
      payload: accounts,
    };
  },

  updateAggregatedAccounts: (accounts: AggregatedAccount[]) => {
    return {
      type: ACCOUNTS.AGGREGATED.UPDATE,
      payload: accounts,
    };
  },

  updateAggregatedAccount: (account: AggregatedAccount) => {
    return {
      type: ACCOUNTS.AGGREGATED.UPDATE_ACCOUNT,
      payload: account,
    };
  },

  removeAggregatedAccount: (accountId: number) => {
    return {
      type: ACCOUNTS.AGGREGATED.REMOVE,
      payload: accountId,
    };
  },

  flushAggregatedAccounts: () => {
    return {
      type: ACCOUNTS.AGGREGATED.FLUSH,
    };
  },

  updateNickname: (id: number, nickname: string) => {
    return {
      type: ACCOUNTS.AGGREGATED.NICKNAME_UPDATE,
      payload: { id, nickname },
    };
  },
};
