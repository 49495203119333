import { Inject } from '../../decorators/decorators';
import { IBillPayService } from 'services/typings/IBillPayService';
import { Recipient } from '../typings/Recipient';
import { IStateService } from 'angular-ui-router';
import { BillPayRouterHelper } from '../bill-pay-router.helper';
import { PaymentHistory } from '../typings/PaymentHistory';
import { ScheduledPayment } from '../typings/ScheduledPayment';
import { PaymentData } from '../typings/PaymentData';
import * as moment from 'moment';
import { ModalService } from 'services/modal.service';
import { PaymentInfo } from '../typings/PaymentInfo';
import { UserAccountType } from 'typings/app/bills/UserAccountType';
import { PayBillsHelper } from '../pay-bills.helper';
import { IRootScopeService } from 'angular';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
@Inject(
  '$scope',
  '$state',
  'serviceHelper',
  'billPayService',
  'billPayRouterHelper',
  'modalService',
  'payBillsHelper',
  '$rootScope',
  'cachedAccountsService'
)
export class PayController {
  isLoading: boolean;
  recipients: Recipient[];
  paymentsHistory: PaymentHistory[];
  scheduledPayments: ScheduledPayment[];
  payments: PaymentData[] = [];
  total: number;
  totalForToday: number;
  suficientFunds: boolean;
  hasDuplicatePayment: boolean = false;
  acceptedPayments: PaymentData[] = [];
  noDuplicatePayments: PaymentData[] = [];
  isValidating: boolean = false;
  accountFrom: UserAccountType;
  hasBusinessAccounts: boolean = false;
  pymCancelled: boolean = false;
  private listeners: Function[] = [];

  constructor(
    private scope: ng.IScope,
    private readonly state: IStateService,
    private readonly serviceHelper: IServiceHelper,
    private readonly billPayService: IBillPayService,
    private readonly billPayRouterHelper: BillPayRouterHelper,
    private readonly modalService: ModalService,
    private readonly payBillsHelper: PayBillsHelper,
    private rootScope: IRootScopeService,
    private cachedAccountsService: CachedAccountsService
  ) {}

  $onInit(): void {
    this.isLoading = true;
    this.handleListeners();
    this.billPayRouterHelper.clean();
    Promise.all([
      this._loadRecipients(),
      this._loadPaymentsHistory(),
      this._loadScheduledPayments(),
      this._onPaymentAccountsLoaded(),
    ])
      .then(() => {
        this.isLoading = false;
        this._setHasBusinessAccounts();
      })
      .catch(this.serviceHelper.errorHandler);
  }

  onPaymentUpdatedCaller(event: any) {
    this.onPaymentUpdated(null, event);
  }

  onPaymentUpdated = (_e: ng.IAngularEvent, paymentData: PaymentData) => {
    const index = this.payments.findIndex(payment => payment.recipient.payeeId === paymentData.recipient.payeeId);
    let amount = parseFloat(paymentData.amount.toString());
    if (index === -1) {
      if (amount !== 0 && !isNaN(amount)) this.payments.push(paymentData);
    } else {
      if (amount === 0 || isNaN(amount)) this.payments.splice(index, 1);
      else this.payments[index] = paymentData;
    }

    this.total = this.payments.reduce(function (previous, current) {
      return previous + +current.amount;
    }, 0);
    this.validateFunds();
  };

  onbillPayAccountChange = (_e: ng.IAngularEvent) => {
    this.validateFunds();
  };

  validateFunds() {
    this.accountFrom = this.billPayRouterHelper.getAccountFrom();
    this.totalForToday = this.payments.reduce(function (previous, current) {
      if (moment(current.processingDate).isSame(moment(), 'day')) return previous + +current.amount;
      else return previous;
    }, 0);

    this.suficientFunds = this.accountFrom.balance >= this.totalForToday;
  }

  cancelPayments() {
    this.total = 0;
    this.pymCancelled = !this.pymCancelled;
    this.scope.$broadcast('paymentCancelled');
  }

  reviewPayments() {
    this.isValidating = true;
    this.validateDuplicatePayment();
  }

  goToPayReview(userPayments: PaymentData[]) {
    this.isValidating = false;
    this.billPayRouterHelper.setPaymentsData(userPayments);
    this.state.go('udb.billPay.payReview');
  }

  validateDuplicatePayment() {
    this.billPayService
      .validateDuplicatePayments(this.payments)
      .then(res => {
        if (res.data.length > 0) {
          this.duplicatePaymentDetected(res.data);
        } else {
          this.goToPayReview(this.payments);
        }
      })
      .catch(() => {
        this.goToPayReview(this.payments);
      });
  }

  excludeNoDuplicatePayments(duplicatePayments: PaymentInfo[]) {
    this.payments.forEach(payment => {
      let indexOf = duplicatePayments.findIndex(p => p.recipientId == payment.payeeId);
      if (indexOf < 0) {
        this.noDuplicatePayments.push(payment);
      }
    });
  }

  duplicatePaymentDetected(duplicatePayments: PaymentInfo[]) {
    this.excludeNoDuplicatePayments(duplicatePayments);
    //last payment to be displayed
    var last = duplicatePayments[0].paymentId;
    duplicatePayments.forEach(item => {
      this.modalService
        .show(
          { windowClass: 'modal-service' },
          {
            bodyText: `
            <div>
              <h3 class="duplicate-title">
              You may be scheduling a duplicate payment.</h3>
              <p class="existing-payment">Existing payment: </p>
              <table class="table--primary">
                <thead>
                  <tr>
                    <th>Recipient</th>
                    <th>Amount To Pay</th>
                    <th>Deliver On</th>
                  </tr>
                </thead>
                <tbody>
                  ${this.createDuplicateRecords([item])}
                </tbody>
              </table>
              <p class="duplicate-continue"> Would you like to continue?</p>
            </div>`,
            okText: 'Continue Scheduling Payment',
            cancelText: 'Do not Schedule Payment',
            okButtonClass: 'uk-btn solid secondary sm dup-payments__buttons-button',
            cancelButtonClass: 'uk-btn outline primary icon-right sm cancel-duplicate',
            containerButtonsClass: 'dup-payments__buttons',
            hasRedirectLink: false,
            hasClose: true,
            hasIcon: true,
            icon: 'bofi-warning',
          }
        )
        .then(() => {
          //only if payment does not exist add it to accepted ones list
          if (!this.doPaymentExists(item)) {
            this.addPayment(item);
          }
          //if is the last modal displayed
          if (last == item.paymentId) {
            this.submitFilteredPayments();
          }
        })
        .catch(() => {
          if (last == item.paymentId) {
            this.submitFilteredPayments();
          }
        });
    });
  }

  showFormBusinessLink(): boolean {
    return this.hasBusinessAccounts && this.payBillsHelper.isSBBActive() && this.payBillsHelper.isIPaySsoActive();
  }

  $onDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  private submitFilteredPayments(): void {
    this.isValidating = false;
    let filteredPayments = this.acceptedPayments.concat(this.noDuplicatePayments);
    if (filteredPayments.length > 0) {
      this.goToPayReview(filteredPayments);
    }
  }

  private doPaymentExists(paymentToCheck: PaymentInfo): boolean {
    let indexOf = this.acceptedPayments.findIndex(p => p.payeeId == paymentToCheck.recipientId);
    return indexOf > -1 ? true : false;
  }

  private addPayment(paymentToAdd: PaymentInfo): void {
    let payment = this.payments.find(r => r.payeeId == paymentToAdd.recipientId);
    payment.hasDuplicateWarning = true;
    this.acceptedPayments.push(payment);
  }

  private createDuplicateRecords(payments: PaymentInfo[]): string {
    var records = '';
    payments.forEach(pymt => {
      var row = `<tr class="text-left">
            <td class="text-bold truncate-recipient">${
              pymt.recipientNickname ? pymt.recipientNickname : pymt.recipientName
            }</td>
            <td>$ ${this.parseAmount(pymt.amount.valueOf())}</td>
            <td>${moment(pymt.date).format('MM/DD/YYYY')}</td>
            </tr>`;
      records += row;
    });
    return records;
  }

  /**Parse the decimal amount into a currency string */
  private parseAmount(amount: any): string {
    return Number.parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  private _loadRecipients(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.billPayService
        .getRecipients()
        .then(response => {
          this.recipients = response.data;
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private handleListeners(): void {
    this.listeners.push(
      this.scope.$on('paymentUpdated', this.onPaymentUpdated),
      this.scope.$on('billPayAccountChange', this.onbillPayAccountChange)
    );
  }

  private _loadPaymentsHistory(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.billPayService
        .getPaymentHistory('', '', '')
        .then(res => {
          this.paymentsHistory = res.data;
          resolve();
        })
        .catch(err => {
          this.paymentsHistory = [];
          reject(err);
        });
    });
  }

  private _loadScheduledPayments(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.billPayService
        .getScheduledPayments()
        .then(res => {
          this.scheduledPayments = [];
          res.data.forEach(acct => {
            acct.scheduledPayments.forEach(pmt => {
              pmt.fromAccount = acct.displayName;
              this.scheduledPayments.push(pmt);
            });
          });
          resolve();
        })
        .catch(err => {
          this.scheduledPayments = [];
          reject(err);
        });
    });
  }

  // This function returns a promise that will be resolved once we have balancesAvailable.
  private _onPaymentAccountsLoaded(): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.rootScope['balancesAvailable']) {
        resolve();
      } else {
        this.listeners.push(
          this.scope.$on('balancesAvailable', () => {
            resolve();
          })
        );
      }
    });
  }

  private _setHasBusinessAccounts(): void {
    if (this.payBillsHelper.isSBBActive()) {
      //Using cachedAccountsService is a more reliable way to know if a user has business accounts.
      this.hasBusinessAccounts = this.cachedAccountsService.paymentAccounts?.some(acc => acc.isSBB) ?? false;

      //According to business requirements, users with only individuals accounts
      // should experience the actual workflow.
      if (this.recipients.length === 0 && !this.hasBusinessAccounts) this.state.go('udb.billPay.welcome');
    } else if (this.recipients.length === 0) {
      this.state.go('udb.billPay.welcome');
    }
  }
}
