import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-transfer-amount',
  templateUrl: './transfer-amount.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferAmountComponent implements OnInit {
  @Input() isRunning = false;
  @Input() isInvalid = false;
  @Input() isContributedAmountReached = false;
  @Input() initAmount: string;
  @Output() money = new EventEmitter();

  maskedAmount = '0.00';

  get labelText() {
    if (this.isRunning) {
      return 'Please confirm your funding details before we initiate the transfer.';
    } else {
      return 'Amount To Transfer';
    }
  }
  constructor() {}

  ngOnInit(): void {
    if (this.initAmount) {
      this.maskedAmount = this.initAmount;
    }
  }

  resetMoneyToDefault() {
    if (this.maskedAmount === '0.00') {
      this.maskedAmount = '';
    } else if (this.maskedAmount === '') {
      this.maskedAmount = '0.00';
    }
  }

  emitModelChanges() {
    this.money.emit(this.maskedAmount);
  }
}
