import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { AccountDetail, AggregatedAccount } from '@app/accounts/models';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { RecategorizeEventData } from '@legacy/accounts/transactions/categorization/recategorize/typings/recategorizeEventData';
import { FiltersHelperService } from '../../services';
import {
  DisputeRedirectionDataTx,
  Transaction,
  TxFilter,
  TxTableDataSorted,
  TxTableUpdateType,
} from '../../types';
import { TransactionService } from '@core/services';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AccountAggregationService } from '@core/services/account-aggregation.service';
import { AggregatedTransaction, TransactionRequest } from '@core/models';
import { TxTableOptionActions } from '../../enums';
import { TxOptionType } from '../../types/tx-table-option.type';

@Component({
  selector: 'app-tx-table',
  templateUrl: './tx-table.component.html',
  styleUrls: ['./tx-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() account: OlbAccount;
  @Input() filters: TxFilter;
  @Input() aggregatedAccount: AggregatedAccount;
  @Input() isAggregatedAccount = false;
  // @Output() onSelected: Function; //Unused?
  @Output() onLoaded = new EventEmitter<TxTableUpdateType>();
  @Output() onSorted = new EventEmitter<TxTableDataSorted>();
  @Output() onTableRowOperation = new EventEmitter<RecategorizeEventData>();
  @Output() onGetCheck = new EventEmitter<any>();
  @Output() isOver2000 = new EventEmitter<boolean>();
  @Output() recategorizeEvent = new EventEmitter<RecategorizeEventData>();
  @Output() savedFiltersLoaded = new EventEmitter<DisputeRedirectionDataTx>();
  @Input() accountDetails: AccountDetail;
  accountDetail: AccountDetail;
  isLoading = true;
  isMultipleDispute = false;
  isTransactionDisputeActive: boolean;
  isAccountAggregationForInternalsActive = false;
  allSelected = false;
  selected: Transaction[] = [];

  // Table related attributes
  allColumns: GenericOption[] = [];
  columns: GenericOption[] = [];
  transactions: Transaction[] = [];
  filtered: Transaction[] = [];
  orderBy = 'date';
  reverse = false;
  isPfm3ExternalTransActive = false;
  isPfm3InternalTransActive = false;
  isExternalLoan: boolean = false;
  private listeners: Function[] = [];

  constructor(
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private _filtersHelperService: FiltersHelperService,
    private transactionService: TransactionService,
    private featureFlagService: FeatureFlagService,
    private serviceHelper: ServiceHelper,
    private accountAggregationService: AccountAggregationService,
    private dateHelper: DateHelper
  ) {}

  /** Initializes the required data */
  ngOnInit() {
    this.isTransactionDisputeActive = this.featureFlagService.isTransactionDisputeActive();
    this.isAccountAggregationForInternalsActive = this.featureFlagService.isAccountAggregationForInternalsActive();
    this.isPfm3ExternalTransActive = this.featureFlagService.isPFM3ExternalTrans();
    this.isPfm3InternalTransActive = this.featureFlagService.isPFM3InternalTrans();

    this.listeners.push(
      this.root.$on(
        'detailsRetrieved',
        (_e: ng.IAngularEvent, detail: AccountDetail) => {
          this.accountDetail = detail;
        }
      ),
      this.root.$on(
        'collapseDisputePanels',
        this.collapseDisputePanels.bind(this)
      )
    );

    this.accountDetail = this.accountDetail
      ? this.accountDetail
      : this.accountDetails;

    if (!!this.account) {
      this.onAccountChange(this.account);
    }
  }

  /**
   * Updates the transactions every time the account and/or filter is changed
   * @param changes Object containing the changes in the bound properties
   */
  ngOnChanges(changes: SimpleChanges): void {
    const { account, filters, aggregatedAccount } = changes;
    if (account && account.firstChange) {
      return;
    }

    if (this.aggregatedAccount) {
      this.isAggregatedAccount = true;
      this.root['isExternalLoan'] = this.aggregatedAccount.container == 'loan';
    }

    switch (true) {
      case !!account:
        this.onAccountChange(account.currentValue);
        break;
      case !!aggregatedAccount:
        this.onAggregatedAccountChange.bind(this)(
          aggregatedAccount.currentValue
        );
        break;
      case !!filters:
        if (this.account) {
          this.evaluateFiltersChanges(
            filters,
            this.account,
            this.loadInternalAccountTransactions.bind(this),
            this.isAccountAggregationForInternalsActive
              ? this.applyEnhanceFilters.bind(this)
              : this.applyFilters.bind(this)
          );
        }
        if (this.aggregatedAccount) {
          this.evaluateFiltersChanges(
            filters,
            this.aggregatedAccount,
            this.LoadAggregatedAccountTransactions.bind(this),
            this.applyEnhanceFilters.bind(this)
          );
        }
        break;
    }
  }

  /** Cleans up the controller. */
  ngOnDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /**
   * Sorts the records according to the parameters sent
   * @param orderBy header name to used to order by
   */
  sort(orderBy: string, headerId: number) {
    this.orderBy = orderBy;
    this.reverse = !this.reverse;
    this.onSorted.emit({ orderBy: headerId, reverse: this.reverse });
  }

  /** Toggles the selection of all rows */
  toggleSelection(allSelected: boolean) {
    this.filtered.forEach(trxn => (trxn.isSelected = allSelected));
    this.selected = allSelected ? [...this.filtered] : [];
  }

  /** Triggers the function when multiple selection is deactivated */
  onClearSelected() {
    this.allSelected = false;
    this.toggleSelection(false);
    this.isMultipleDispute = false;
  }

  /**
   * Selects the transaction/row when multiple is activated
   * @param trxn Transaction
   */
  selectTransaction(trxn: Transaction) {
    trxn.isSelected = !trxn.isSelected;
    this.selected = this.filtered.filter(trxn => trxn.isSelected);
    this.allSelected = this.selected.length === this.filtered.length;
  }

  /** Redirects to the review disputes page */
  fileDispute() {
    this.state.go('udb.accounts.dispute-review', {
      transactions: this.selected,
      accountId: this.account.id,
      accountDetail: this.accountDetails,
    });
  }

  performAction(option: TxOptionType, trxn: Transaction) {
    switch (option.label) {
      case TxTableOptionActions.disputeTransaction:
        this.transactions.forEach(trxn => (trxn.isExpanded = false));
        trxn.isDisputable = !trxn.isPending && !trxn.hasCheck;
        trxn.isExpanded = true;
        if (trxn.isDisputable) this.selected[0] = trxn;
        break;
      case TxTableOptionActions.dismuteMultipleTransactions:
        this.transactions.forEach(trxn => (trxn.isExpanded = false));
        this.isMultipleDispute = true;
        this.root['txDisputeData'] = null;
        this.selected = [];
        // Enable auto-select-transaction
        trxn.isSelected = true;
        this.selected[0] = trxn;
        break;
      case TxTableOptionActions.viewDetails:
        trxn.show = true;
        break;
      case TxTableOptionActions.recategorizeTransaction:
        trxn.isRecategorizeExpanded = true;
        trxn.isDetailsExpanded = false;
        break;
    }
  }

  /** Collapses any row that could be expanded */
  collapse(trxn: Transaction) {
    this.selected = [];
    trxn.isExpanded = false;
    trxn.isRecategorizeExpanded = false;
    trxn.show = false;
    trxn.isDetailsExpanded = false;
  }

  onRecategoriziationSuccess(trnx: any) {
    const data: RecategorizeEventData = {
      message: 'The transaction category was updated successfully',
      status: true,
    };
    if (!!this.onTableRowOperation) this.onTableRowOperation.emit(data);
    this.emitRecategorizeEvent(data);
    this.collapse(trnx);
  }

  errorMesage() {
    const data: RecategorizeEventData = {
      message: 'Not able to update transaction category',
      status: false,
    };
    if (!!this.onTableRowOperation) this.onTableRowOperation.emit(data);
    this.emitRecategorizeEvent(data);
  }

  getCheck(transaction: Transaction) {
    this.onGetCheck.emit(transaction);
  }

  private evaluateFiltersChanges(
    filters: SimpleChange,
    account: OlbAccount | AggregatedAccount,
    serviceCall: Function,
    filtersAction: Function
  ) {
    if (filters && filters.currentValue && !filters.isFirstChange()) {
      let prevFilters: TxFilter, newFilters: TxFilter;
      prevFilters = filters.previousValue;
      newFilters = filters.currentValue;

      switch (true) {
        case prevFilters.days.value !== newFilters.days.value:
          this.getTransactionsByTimePeriod(
            newFilters.days.value,
            account,
            serviceCall
          );
          break;
        case prevFilters.dateRange.start !== newFilters.dateRange.start &&
          prevFilters.dateRange.end !== newFilters.dateRange.end:
          this.getTransactionsByDateRange(
            newFilters.dateRange,
            account,
            serviceCall
          );
          break;
        default:
          filtersAction();
      }
    }
  }

  /**
   * When the accounts is changed, a new request for transaction is performed
   * @param account New account value
   */
  private onAccountChange(account: OlbAccount) {
    this.account = account;
    this.isAccountAggregationForInternalsActive
      ? this.loadInternalAggregatedTranColumns()
      : this.loadInternalTranColumns();
    this.filterInternalColumns();

    let transDays = 90;

    if (
      this.isTxRedirection() &&
      this.root['txDisputeData'].accountNumber == this.account.id
    ) {
      this.loadSavedFilters();
      transDays = this.filters.days.value;
    }

    this.loadInternalAccountTransactions(
      this.account.id,
      moment().subtract(transDays, 'days').toDate(),
      moment().toDate()
    );
    this.allSelected = false;
    this.selected = [];
  }

  private onAggregatedAccountChange(aggregatedAccount: AggregatedAccount) {
    if (aggregatedAccount.container === 'loan') {
      this.loadAggregatedLoanTranColumns();
    } else {
      this.loadAggregatedTranColumns();
    }

    this.filterAggregatedColumns();
    this.aggregatedAccount = aggregatedAccount;

    this.LoadAggregatedAccountTransactions(this.aggregatedAccount.id);
  }

  /** Filter the default columns depending on the account type. */
  private filterInternalColumns() {
    if (!this.allColumns) return;

    const accountType = this.account.isLoan ? 'loan' : 'savings';
    this.columns = this.allColumns.filter(col =>
      col.displayWhen.includes(accountType)
    );
  }

  /** Filter the default columns depending on the account type. */
  private filterAggregatedColumns() {
    if (!this.allColumns) return;

    this.columns = this.allColumns.filter(col =>
      col.displayWhen.includes('AggregatedTrans')
    );
  }

  private loadInternalTranColumns() {
    this.allColumns = [
      { value: 0, subvalue: 'type', label: '', displayWhen: 'savings|loan' },
      {
        value: 1,
        subvalue: 'date',
        label: 'Date',
        displayWhen: 'savings|loan',
      },
      {
        value: 2,
        subvalue: 'description',
        label: 'Description',
        displayWhen: 'savings|loan',
      },
      {
        value: 4,
        subvalue: 'amount',
        label: 'Deposits',
        displayWhen: 'savings',
      },
      {
        value: 5,
        subvalue: 'amount',
        label: 'Withdrawals',
        displayWhen: 'savings',
      },
      { value: 4, subvalue: 'amount', label: 'Credits', displayWhen: 'loan' },
      { value: 5, subvalue: 'amount', label: 'Debits', displayWhen: 'loan' },
      {
        value: 6,
        subvalue: 'balance',
        label: 'Total Balance',
        displayWhen: 'savings',
      },
    ];
  }

  private loadInternalAggregatedTranColumns() {
    this.allColumns = [
      { value: 0, subvalue: 'type', label: '', displayWhen: 'savings|loan' },
      {
        value: 1,
        subvalue: 'date',
        label: 'Date',
        displayWhen: 'savings|loan',
      },
      {
        value: 2,
        subvalue: 'description',
        label: 'Description',
        displayWhen: 'savings|loan',
      },
      {
        value: 4,
        subvalue: 'amount',
        label: 'Amount',
        displayWhen: 'savings|loan',
      },
      {
        value: 6,
        subvalue: 'balance',
        label: 'Total Balance',
        displayWhen: 'savings',
      },
    ];
  }

  private loadAggregatedTranColumns() {
    this.allColumns = [
      {
        value: 0,
        subvalue: 'category',
        label: '',
        displayWhen: 'AggregatedTrans',
      },
      {
        value: 1,
        subvalue: 'date',
        label: 'Date',
        displayWhen: 'AggregatedTrans',
      },
      {
        value: 2,
        subvalue: 'description',
        label: 'Description',
        displayWhen: 'AggregatedTrans',
      },
      {
        value: 4,
        subvalue: 'amount',
        label: 'Amount',
        displayWhen: 'AggregatedTrans',
      },
      {
        value: 4,
        subvalue: 'balance',
        label: 'Total Balance',
        displayWhen: 'AggregatedTrans',
      },
    ];
  }

  private loadAggregatedLoanTranColumns() {
    this.allColumns = [
      {
        value: 0,
        subvalue: 'category',
        label: '',
        displayWhen: 'AggregatedTrans',
      },
      {
        value: 1,
        subvalue: 'date',
        label: 'Date',
        displayWhen: 'AggregatedTrans',
      },
      {
        value: 2,
        subvalue: 'description',
        label: 'Description',
        displayWhen: 'AggregatedTrans',
      },
      {
        value: 4,
        subvalue: 'amount',
        label: 'Amount',
        displayWhen: 'AggregatedTrans',
      },
    ];
  }

  private getTransactionsByTimePeriod(
    days: number,
    account: OlbAccount | AggregatedAccount,
    action: Function
  ) {
    const fromDate = this.dateHelper
      .getAxosLocalDate()
      .subtract(days, 'days')
      .toDate();
    const toDate = this.dateHelper.getAxosLocalDate().toDate();
    this.allSelected = false;
    this.selected = [];
    action(account.id, fromDate, toDate);
  }

  private getTransactionsByDateRange(
    dateRange: any,
    account: OlbAccount | AggregatedAccount,
    action: Function
  ) {
    const { start, end } = dateRange;
    const startMoment = moment(start);
    const endMoment = moment(end);

    const axosStartMoment = this.dateHelper.convertRawDateToAxosLocalTime(
      startMoment
    );
    const axosEndMoment = this.dateHelper.convertRawDateToAxosLocalTime(
      endMoment
    );

    let startDate = axosStartMoment.toDate();
    let endDate = axosEndMoment.toDate();

    if (!start || !end) {
      startDate = this.dateHelper
        .getAxosLocalDate()
        .subtract(this.filters.days.value, 'days')
        .toDate();
      endDate = this.dateHelper.getAxosLocalDate().toDate();
    }
    this.allSelected = false;
    this.selected = [];
    action(account.id, startDate, endDate);
  }

  /**
   * Load the transactions receiving as argument the start date & end date
   * @param accountId Unique identifier of the account
   * @param startDate From what date retrieve the transactions
   * @param endDate Until what date retrieve the transactions
   */
  private loadInternalAccountTransactions(
    accountId: number,
    startDate: Date,
    endDate: Date
  ) {
    this.isLoading = true;
    this.isOver2000.emit(false);
    this.transactionService
      .getTransactionsByAccount(accountId, startDate, endDate)
      .pipe(
        tap(res => {
          const transactions = res.data;
          this.isOver2000.emit(transactions.length >= 2000);

          this.transactions = transactions;
          this.transactions.forEach(transaction => {
            if (transaction.accountName?.includes('SBLOC')) {
              if (transaction.description?.includes('HELOC')) {
                transaction.description = transaction.description.replace(
                  'HELOC',
                  'SBLOC'
                );
              }
            }
          });

          if (this.isAccountAggregationForInternalsActive)
            this.applyEnhanceFilters();
          else this.applyFilters();
        }),
        catchError(err => {
          this.serviceHelper.errorHandler(err);
          return of(err);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(() => {
        if (!!this.accountDetails) {
          this.expandTxDispute();
        } else {
          this.listeners.push(
            this.root.$on('detailsRetrieved', this.expandTxDispute.bind(this))
          );
        }
      });
  }

  private LoadAggregatedAccountTransactions(
    accountId: number,
    startDate?: Date,
    endDate?: Date
  ) {
    const supportedContainers = [
      'bank',
      'creditCard',
      'investment',
      'insurance',
      'loan',
    ];
    if (!supportedContainers.some(v => v == this.aggregatedAccount.container)) {
      this.transactions = [] as Transaction[];
      this.isLoading = false;

      return;
    }

    const transDaysDefault = 90;
    this.isLoading = true;
    // this.scope.$emit('isOver2000', false);
    this.isOver2000.emit(false);

    let request = new TransactionRequest();

    request.accountId = accountId;
    request.container = this.aggregatedAccount.container;

    request.fromDate = !!startDate
      ? startDate
      : this.dateHelper
          .getAxosLocalDate()
          .subtract(transDaysDefault, 'days')
          .toDate();
    request.toDate = !!endDate
      ? endDate
      : this.dateHelper.getAxosLocalDate().toDate();

    this.accountAggregationService
      .getTransactions(request)
      .pipe(
        tap(response => {
          const data: AggregatedTransaction[] = response.data;

          if (data != null) {
            // this.scope.$emit('isOver2000', data.length >= 2000);
            this.isOver2000.emit(data.length >= 2000);
            this.transactions = this.converToTransactions(data);
            this.applyEnhanceFilters();
          } else {
            const newTransactions = [] as Transaction[];
            this.transactions = newTransactions;
            this.onLoaded.emit({
              transactions: this.filtered,
              transactionsWithAllCategories: this.filtered,
            });
          }
        }),
        catchError(err => {
          this.serviceHelper.errorHandler(err);
          return of(err);
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  private converToTransactions(
    aggregatedTransfers: AggregatedTransaction[]
  ): ExternalTransaction[] {
    const transactions = aggregatedTransfers.map(
      (item: AggregatedTransaction) =>
        ({
          // Yodlee ID
          id: item.transactionId,
          // BC ID
          isPending: item.status == 'PENDING' ? true : false,
          pendingStatus: item.status,
          transactionIdentifier: item.id,
          postedDate: item.date,
          type: item.baseType,
          subtype: item.type,
          description: item.description.simple
            ? item.description.simple
            : item.description.original,
          originalDescription: item.description.original,
          amount: item.amount.amount,
          balance:
            (this.aggregatedAccount.container == 'bank' ||
              this.aggregatedAccount.container == 'creditCard' ||
              this.aggregatedAccount.container == 'loan') &&
            item.runningBalance != null
              ? item.runningBalance.amount
              : null, // Total Balance: of the account after that transaction was considered.
          category: item.category,
          categoryId: item.categoryId,
          olbCategoryId: item.olbCategoryId,
          olbCategoryName: item.olbCategoryName,
          // In the case of the check related transactions, we are going to display the image of the check when possible.
          // If the image is available, we will display an icon for the customer to click on and view the check.
          // If the check is not available the check image won't be displayed.
          hasCheck: item.categoryId == 33 ? false : false,
          checkNumber: '',
          isIncome: item.isIncome,
          signedAmount: item.signedAmount,
          simpleDescription: item.description.simple,
          merchantName: item.merchantName,
          merchantAddress: item.merchantAddress,
          isPhysical: item.isPhysical,
        } as ExternalTransaction)
    );

    return transactions;
  }

  /** Applies the specified filters to the transactions list*/
  private applyFilters() {
    this.isOver2000.emit(false);
    const {
      query,
      transactionType,
      amount,
      check,
      amountRange,
      checkRange,
    } = this.filters;

    this.filtered = this.transactions
      .filter((tx: Transaction) => {
        return (
          (transactionType.subvalue === 'Check'
            ? tx.hasCheck
            : tx.type === transactionType.subvalue ||
              transactionType.value == 0) &&
          (Math.abs(tx.amount) === +amount || !amount) &&
          this._filtersHelperService.amountRangeFilter(tx, amountRange) &&
          this._filtersHelperService.checkFilter(tx, check) &&
          this._filtersHelperService.checkRangeFilter(tx, checkRange) &&
          (this._filtersHelperService.amountPatternFilter(tx.amount, query) ||
            tx.checkNumber.toString().includes(query) ||
            tx.description.toLowerCase().includes(query.toLowerCase()) ||
            this._filtersHelperService.dateFilter(tx.postedDate, query) ||
            tx.type.toLowerCase().includes(query.toLowerCase()) ||
            !query)
        );
      })
      .map(transaction => {
        return {
          ...transaction,
          isDetailsExpanded:
            transaction.isDetailsExpanded == undefined
              ? false
              : transaction.isDetailsExpanded,
          isRecategorizeExpanded:
            transaction.isRecategorizeExpanded == undefined
              ? false
              : transaction.isRecategorizeExpanded,
          show: transaction.show == undefined ? false : transaction.show,
        };
      });

    this.onLoaded.emit({ transactions: this.filtered });
    this.isOver2000.emit(this.filtered.length >= 2000);
  }

  private applyEnhanceFilters() {
    this.isOver2000.emit(false);
    const {
      categories,
      query,
      transactionType,
      amountRange,
      checkRange,
    } = this.filters;

    const filteredWithAllCategories = this.transactions.filter(
      (tx: Transaction) => {
        return (
          (transactionType.subvalue
            ? (tx.type || ' ')[0].toUpperCase() === transactionType.subvalue
            : true) &&
          this._filtersHelperService.amountRangeFilter(tx, amountRange) &&
          this._filtersHelperService.checkRangeFilter(tx, checkRange) &&
          (this._filtersHelperService.amountPatternFilter(tx.amount, query) ||
            tx.description.toLowerCase().includes(query.toLowerCase()) ||
            this._filtersHelperService.dateFilter(tx.postedDate, query) ||
            !query)
        );
      }
    );

    this.filtered = filteredWithAllCategories
      .filter(tx =>
        !categories.length
          ? true
          : categories.some((c: number) => c === tx.olbCategoryId) &&
            !tx.isPending
      )
      .map(transaction => {
        return {
          ...transaction,
          isDetailsExpanded:
            transaction.isDetailsExpanded == undefined
              ? false
              : transaction.isDetailsExpanded,
          isRecategorizeExpanded:
            transaction.isRecategorizeExpanded == undefined
              ? false
              : transaction.isRecategorizeExpanded,
          show: transaction.show == undefined ? false : transaction.show,
        };
      });

    this.onLoaded.emit({
      transactions: this.filtered,
      transactionsWithAllCategories: filteredWithAllCategories,
    });

    // this.scope.$emit('isOver2000', this.filtered.length >= 2000);
    this.isOver2000.emit(this.filtered.length >= 2000);
  }

  /** After return from add new phone, expand the txDispute that was being processed*/
  private expandTxDispute() {
    let disputeData;
    if (!(disputeData = this.root['txDisputeData'] as DisputeRedirectionDataTx))
      return;

    this.loadSavedFilters();

    const trx = disputeData.transaction as Transaction[];
    const transaction = this.transactions.filter(
      t => t.sequenceNumber === trx[0].sequenceNumber
    )[0];
    if (!transaction) return;
    transaction.isDisputable = transaction.isExpanded = true;
    this.selected.push(transaction);
  }

  /** Assign previously saved data for filters in the filters */
  private loadSavedFilters(): void {
    if (this.isTxRedirection()) {
      const disputeData = this.root[
        'txDisputeData'
      ] as DisputeRedirectionDataTx;
      this.filters = disputeData.filters;
      this.savedFiltersLoaded.emit(disputeData);
    }
  }
  private isTxRedirection(): boolean {
    return (
      this.state.params.isTransDisputeRedirection && this.root['txDisputeData']
    );
  }

  /**Close any open dispute window when tab lost focus */
  private collapseDisputePanels(): void {
    if (!this.isTransactionDisputeActive) return;

    this.transactions.forEach(
      trxn => ((trxn.isExpanded = false), (trxn.show = false))
    );
    this.onClearSelected();
  }

  private emitRecategorizeEvent(eventData: RecategorizeEventData) {
    this.recategorizeEvent.emit(eventData);
  }
}
