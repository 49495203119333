import { ActionReducer, Action } from '@ngrx/store';
import { merge, pick } from 'lodash';
import { AasStoreFeatures } from './aas-core';

function setSavedState(state: any, localStorageKey: string) {
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  } catch (err) {
    localStorage.removeItem(localStorageKey);
  }
}
function getSavedState(localStorageKey: string): any {
  return JSON.parse(localStorage.getItem(localStorageKey));
}

// the keys from state which we'd like to save.
const stateKeys = [
  AasStoreFeatures.AvailableCash,
  AasStoreFeatures.TotalValue,
  AasStoreFeatures.StatementsAndDocuments,
  AasStoreFeatures.Holdings,
  AasStoreFeatures.Models,
  AasStoreFeatures.ProductDetailsPage,
  AasStoreFeatures.AccountDetails,
  AasStoreFeatures.OrganizationSettings,
  AasStoreFeatures.BrandingSettings,
  AasStoreFeatures.MainLayout,
  AasStoreFeatures.PdpLayout,
  AasStoreFeatures.AccountAuthorizations,
  AasStoreFeatures.AccountPeople,
  AasStoreFeatures.Orders,
  AasStoreFeatures.Transactions,
  AasStoreFeatures.Rias,
  AasStoreFeatures.PortfolioAllocation,
  AasStoreFeatures.ScheduledTransfers,
  AasStoreFeatures.SharedAccounts,
];
// the key for the local storage.
const localStorageKey = '__app_storage__';

export function storageMetaReducer<S, A extends Action = Action>(
  reducer: ActionReducer<S, A>
) {
  let onInit = true; // after load/refresh…
  return function (state: S, action: A): S {
    // reduce the nextState.
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      onInit = false;
      const savedState = getSavedState(localStorageKey);
      return merge(nextState, savedState);
    }
    // save the next state to the application storage.
    const stateToSave = pick(nextState, stateKeys);
    setSavedState(stateToSave, localStorageKey);
    return nextState;
  };
}
