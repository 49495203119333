import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IUserProfileService } from './typings/IUserProfileService';

@Inject('$http', 'env', 'serviceHelper', '$q')
class UserProfileService extends BaseService implements IUserProfileService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'profile', _serviceHelper, q);
  }

  getUserProfileInfo(): ApiResponse<UserProfileInfo> {
    return this.get('details');
  }

  updateEligibleForRmd(value: boolean): ApiResponse<void> {
    return this.put('eligibleForRmd', value);
  }

  updateTradingAcknowledged(): ApiResponse<void> {
    return this.put('tradingAcknowledged');
  }
}

export { UserProfileService };
