import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { AuthMethod } from '@core/enums';
import { CommunicationIcons } from '@shared/enums';

@Component({
  selector: 'app-multifactor',
  templateUrl: './multifactor.component.html',
  styleUrls: ['./multifactor.component.scss'],
})
export class MultifactorComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean;
  @Input() supportPhone: string;
  @Input() subtitle =
    'In order to confirm your identity and ensure your account is secure, please select a method to deliver your one-time verification code.';
  @Input() title = 'We need to send you a verification code.';
  @Input() phone = '';
  @Input() onlyPhone = false;

  @Output() sendCode = new EventEmitter<AuthMethod>();

  private readonly defaultSupportPhone = '1-888-502-2967';
  authMethod = AuthMethod;
  isSendingCode = false;

  email: string;
  smsAvailable: boolean;
  emailAvailable: boolean;
  icons = {
    message: CommunicationIcons.SpeechBubble2,
    email: CommunicationIcons.Envelope,
  };

  get noMethodAvailable(): boolean {
    return !this.emailAvailable && !this.smsAvailable;
  }

  constructor() {}

  ngOnInit(): void {
    const defaultButtonMessage = '- N/A';
    this.email = defaultButtonMessage;

    this.supportPhone = this.supportPhone || this.defaultSupportPhone;
    const maskedPhone = sessionStorage.getItem('maskedCellPhone');
    const maskedEmail = sessionStorage.getItem('maskedEmail');

    if (maskedPhone) {
      this.phone = `to ${maskedPhone}`;
      this.smsAvailable = true;
    } else if (this.phone) {
      this.phone = `to ${this.phone}`;
      this.smsAvailable = true;
    }

    if (maskedEmail && !this.onlyPhone) {
      this.email = `to ${maskedEmail}`;
      this.emailAvailable = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phone) {
      const { currentValue: inputPhone } = changes.phone;
      this.phone = `(***) ***-${inputPhone.substring(inputPhone.length - 4, inputPhone.length)}`;
    }
  }

  continue(authMethod: AuthMethod): void {
    this.isSendingCode = true;

    this.sendCode.emit(authMethod);
  }
}
