<div class="header-section-title text-center">
  <div>
    <h1>{{ step === 1 ? 'Move Money' : 'Confirm your identity' }}</h1>
    <p class="" id="padding-top-account">
      {{
        step === 1
          ? 'Setup and manage your transfers.'
          : 'Please follow the instructions below to confirm your identity and complete your ACH transfer.'
      }}
    </p>
  </div>
</div>
<div class="container udb-transfer-funds">
  <div class="frow center">
    <div>
      <p>Use the fields below to setup your money transfer.</p>
      <div class="text-center">
        <button type="button" (click)="showLearnMoreModal()" class="link about-move-money">
          <app-svg-icon class="mr-1" size="1em" [iconName]="questionIcon"></app-svg-icon>
          About Move Money
        </button>
      </div>
    </div>
  </div>
  <div class="frow">
    <div class="fcol-md-8 fcol-md-offset-2">
      <app-transfer-funds
        [fromAccountId]="fromAccountId"
        [toAccountId]="toAccountId"
        [step]="step"
      ></app-transfer-funds>
    </div>
  </div>
</div>
