import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { format } from 'date-fns';

import { GOAL_ICONS, TRANSFER_CONFIGURATION, TRANSFER_FUNDS_CONTENT } from '@app/axos-invest/constants';
import { Frequency, ModifyBannerType, SendUntil } from '@app/axos-invest/enums';
import { TransferType } from '@app/axos-invest/enums/transfer-type.enum';
import {
  CompletedTransfer,
  ForecastParams,
  Goal,
  RecommendedAutoDeposit,
  ScheduledTransfer,
  TransferForm,
} from '@app/axos-invest/models';
import { AxosInvestDeleteTransferService, AxosInvestService } from '@app/axos-invest/services';
import { deleteScheduledTransfer } from '@app/axos-invest/store/scheduled-transfers/scheduled-transfers.actions';
import { getScheduledTransfers } from '@app/axos-invest/store/selectors';
import {
  createTransferFundsForm,
  getRecommendedAutoDepositFilter,
  mapFrequencyToForecastFrequency,
} from '@app/axos-invest/utils';
import { DialogService } from '@core/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { DialogComponent } from '@shared/components';
import { NavigationIcons, UtilityIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

@Component({
  selector: 'app-transfer-funds',
  templateUrl: './transfer-funds.component.html',
  styleUrls: ['./transfer-funds.component.scss'],
})
export class TransferFundsComponent implements OnInit, OnDestroy {
  @Input() goal: Goal;
  @Input() transferType: TransferType;
  @Input() transferData: Partial<TransferForm>;

  @Output() goBackEmitter = new EventEmitter();
  @Output() transferCompleted = new EventEmitter();
  scheduledTransfers: ScheduledTransfer[];

  icons = {
    trash: UtilityIcons.Trash,
  };

  get content(): {
    title: string;
    editTitle?: string;
    subtitle: string;
    editSubtitle?: string;
    confirmationTitle: string;
  } {
    return TRANSFER_FUNDS_CONTENT[this.transferType];
  }
  get goalIcon() {
    return GOAL_ICONS[this.goal.type];
  }
  get hideRecommendationButton() {
    return this.recommendation && this.recommendation.amount === this.form.controls.amount.value;
  }
  get isRecommendationSupported() {
    return this.recommendationSupportedTransferTypes.includes(this.transferType);
  }

  recommendationSupportedTransferTypes = [TransferType.RecurrentDeposit, TransferType.FundingFlow];
  scheduledTransferSupportedTypes = [TransferType.RecurrentDeposit, TransferType.RecurrentWithdrawal];
  showConfirmation: boolean;
  showTransferCompleted: boolean;
  isRecommendationLoading: boolean;
  isScheduledTransferLoading: boolean;
  backIcon = NavigationIcons.ArrowBack;
  TRANSFER_TYPES = TransferType;
  form: UntypedFormGroup;
  completedTransfer: CompletedTransfer;
  goalForecastIcon = UtilityIcons.Ribbon;
  recommendation: RecommendedAutoDeposit;
  forecastParameters: ForecastParams;
  subsink = new SubSink();
  dialog: MatDialogRef<DialogComponent>;
  specifiedTransfer: ScheduledTransfer;
  referrerTabId: number;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private store: Store,
    private axosInvestDeleteTransferService: AxosInvestDeleteTransferService,
    private axosInvestService: AxosInvestService,
    private dialogService: DialogService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.form = createTransferFundsForm(this.transferData || {}, this.transferType, this.goal);
    this.referrerTabId = this.params['referrerTabId'];
    this.getScheduledTransfers();
    this.getRecommendation();
    this.createForecastEvents();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  onFormSubmitted(formData: TransferForm) {
    this.showConfirmation = true;
    this.transferData = formData;
  }

  goBack() {
    if (this.showConfirmation) this.showConfirmation = false;
    else this.goBackEmitter.emit();
  }

  useRecommendation() {
    this.form.controls.amount.setValue(this.recommendation.amount);
    this.form.controls.frequency.setValue(Frequency.MONTHLY);
  }

  onTransferCompleted(transfer: CompletedTransfer) {
    this.completedTransfer = transfer;
    this.showConfirmation = false;
    if (this.transferType === TransferType.FundingFlow) {
      this.showTransferCompleted = true;
      this.transferCompleted.emit(transfer);
    } else {
      this.showTransferCompleted = false;
      let endingText = '';
      if (transfer.sendUntil === SendUntil.UntilIChooseToStop) {
        endingText = ' and will be ongoing until cancelled';
      } else if (transfer.sendUntil === SendUntil.LastTransferDate) {
        endingText = ` and end on ${format(new Date(transfer.endDate), 'MM/dd/yy')}`;
      }
      const amount = this.currencyPipe.transform(transfer.amount);
      this.state.go('udb.axosinvest', {
        id: this.goal.id,
        referrerTabId: this.referrerTabId ? this.referrerTabId : 0,
        modifiedState: ModifyBannerType.TransferCreated,
        modifiedBannerText: `A ${transfer.frequencyDescription} ${
          transfer.transactionTypeDescription
        } of ${amount} has been submitted! It will start on ${format(
          new Date(transfer.startDate),
          'MM/dd/yy'
        )}${endingText}.`,
      });
    }
  }

  deleteRecurringDeposit(transferSeriesId?: string) {
    let dialogData = new DialogData({
      title: 'Delete Recurring Deposit?',
      content:
        '<p class="ms-secondary-text">All future transfers scheduled by this recurring deposit will be canceled.</p>',
      cancelText: 'Cancel',
      okText: 'Delete Deposit',
      hasCloseButton: true,
      icon: UtilityIcons.CircleCheck,
    });

    this.dialog = this.dialogService.open(dialogData);

    this.subsink.sink = this.dialog.afterClosed().subscribe(leave => {
      if (leave) {
        this.axosInvestDeleteTransferService.deleteScheduledTransfers(transferSeriesId).subscribe(response => {
          const status = response.statusCode;
          if (status === 200) {
            this.state.go('udb.axosinvest', {
              id: this.goal.id,
              referrerTabId: this.referrerTabId ? this.referrerTabId : 0,
              modifiedState: ModifyBannerType.RecurringDepositDeleted,
              modifiedBannerText: `Your ${this.currencyPipe.transform(
                this.specifiedTransfer.amount,
                'USD'
              )} ${this.specifiedTransfer.frequencyDescription.toLowerCase()} recurring deposit has been deleted.`,
            });
            this.store.dispatch(deleteScheduledTransfer({ transferSeriesId }));
          } else {
            dialogData = new DialogData({
              title: 'Error',
              content:
                '<p class="ms-secondary-text">There was an error deleting your reccuring transfers. Please try again.</p>',
              cancelText: 'Cancel',
              okText: 'Retry',
              hasCloseButton: true,
            });

            this.dialog = this.dialogService.open(dialogData);
          }
        });
      }
    });

    this.dialog = null;
  }

  private createEventForGoalAccount() {
    this.form.get('goalAccount').valueChanges.subscribe((goalAccount: string) => {
      if (!this.scheduledTransfers) return;

      const transfer = this.scheduledTransfers.find(st => st.accountNumber === goalAccount);
      const transferSeriesId = transfer ? transfer.transferSeriesId : null;
      this.form.get('transferSeriesId').setValue(transferSeriesId);
    });
  }

  private getScheduledTransfers() {
    this.isScheduledTransferLoading = true;
    this.subsink.sink = this.store
      .select(getScheduledTransfers)
      .pipe(map(response => this.mapScheduledTransfers(response)))
      .subscribe(transferData => this.applyExistingScheduledTransfer(transferData));
  }

  private mapScheduledTransfers(transfers: ScheduledTransfer[]) {
    if (!transfers || transfers.length === 0) return;

    const config = TRANSFER_CONFIGURATION[this.transferType];
    transfers = transfers.filter(t => t.milestoneId === this.goal.id && t.transactionType === config.transactionType);
    this.scheduledTransfers = transfers;
    if (transfers.length > 0) {
      let transfer = transfers[transfers.length - 1];
      if (this.transferData && this.transferData.transferSeriesId) {
        this.specifiedTransfer = transfer = transfers.find(
          t => t.transferSeriesId === this.transferData.transferSeriesId
        );
        if (!this.specifiedTransfer) {
          delete this.transferData.transferSeriesId;
          this.form.get('transferSeriesId').reset();

          return;
        }
      }

      return new TransferForm({
        amount: transfer.amount,
        frequency: transfer.frequency,
        transferSeriesId: transfer.transferSeriesId,
        startDate: new Date(transfer.startSendingDate),
        endDate: transfer.lastTransferDate ? new Date(transfer.lastTransferDate) : null,
        accountNumber: transfer.bankAccountNumber,
        goalAccount: transfer.accountNumber,
        contributionYear: transfer.contributionYear, // Add validation for non-ira accounts
      });
    }
  }

  private applyExistingScheduledTransfer(transfer: TransferForm) {
    if (transfer) {
      if (transfer.frequency !== Frequency.ONE_TIME) {
        this.createForecastParameters(transfer.amount, transfer.frequency);
      }
      if (this.scheduledTransferSupportedTypes.includes(this.transferType)) {
        transfer = this.overrideTransferData(transfer);
        this.form.patchValue(transfer);
      }
    }
    this.createEventForGoalAccount();
    this.isScheduledTransferLoading = false;
  }

  private overrideTransferData(transfer: TransferForm) {
    if (this.transferData) {
      transfer.amount = this.transferData.amount ?? transfer.amount;
      transfer.frequency = this.transferData.frequency ?? transfer.frequency;
      if (this.transferData.accountId) {
        transfer.accountId = this.transferData.accountId;
      }
      transfer.accountNumber = this.transferData.accountId ? null : transfer.accountNumber;
      if (!this.transferData.accountId && this.transferData.accountNumber) {
        transfer.accountNumber = this.transferData.accountNumber;
      }
    }

    return transfer;
  }

  private getRecommendation() {
    if (!this.isRecommendationSupported) return;

    this.isRecommendationLoading = true;
    const filter = getRecommendedAutoDepositFilter(this.goal);
    this.axosInvestService
      .getRecommendedAutoDeposit(this.goal.type, filter)
      .pipe(finalize(() => (this.isRecommendationLoading = false)))
      .subscribe(response => {
        this.recommendation = response.data;
      });
  }

  private createForecastEvents() {
    this.subsink.sink = this.form.controls.amount.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(() => this.getForecastParametersFromForm());
    this.subsink.sink = this.form.controls.frequency.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => this.getForecastParametersFromForm());
  }

  private getForecastParametersFromForm() {
    if (+this.form.controls.frequency.value === Frequency.ONE_TIME) {
      this.forecastParameters = null;

      return;
    }

    this.createForecastParameters(this.form.controls.amount.value, this.form.controls.frequency.value);
  }

  private createForecastParameters(amount: number, frequency: Frequency) {
    this.forecastParameters = new ForecastParams({
      autoDepositAmount: amount,
      autoDepositFrequency: mapFrequencyToForecastFrequency(frequency),
    });
  }
}
