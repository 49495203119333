import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { OtherTopics } from '@app/support/enums';

@Component({
  selector: 'how-to-profile-settings-topics',
  templateUrl: './profile-settings-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProfileSettingsTopicsComponent {
  selectedTopic: OtherTopics;
  topics = OtherTopics;

  selectTopic(topic: OtherTopics): void {
    this.selectedTopic = topic;
  }
}
