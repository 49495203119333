import { createFeatureSelector } from '@ngrx/store';

import { AppStateFeatures } from '@core/enums';

import { OrdersState } from './orders/orders.state';

export class TradingState {
  ordersState = new OrdersState();
  constructor(args?: Partial<TradingState>){
    Object.assign(this, args);
  }
}

export const getTradingState = createFeatureSelector<TradingState>(AppStateFeatures.AxosTrading);
