import { ChangeDetectionStrategy, Component, 
  Inject, 
  Input } from '@angular/core';
import { STATE } from '@core/tokens';
import { SignatureCardAction } from '@legacy/models/signature-card-action.model';
import { ServiceHelper } from '@legacy/services/service.helper';
import { SignatureCardService } from '@legacy/services/signature-card.service';

@Component({
  selector: 'app-signature-card-actions',
  templateUrl: './signature-card-actions.component.html',
  styleUrls: ['./signature-card-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureCardActionsComponent {
  @Input() accountNumber: string;
  @Input() accountId: number; 

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private signatureCardService: SignatureCardService,
    private serviceHelper: ServiceHelper
  ) {
  }

  goToSendMail(): void {
    this.signatureCardService
      .logUserAction(SignatureCardAction.CustomerMailSigcard)
      .subscribe(() => {
        this.state.go('udb.signaturecard.send-mail');
      });
  }

  goToUnableDownload(): void {
    this.state.go('udb.signaturecard.unable-download', {
      accountNumber: this.accountNumber,
      accountId: this.accountId,
    });
  }

  goToUploadCard(): void {
    this.state.go('udb.signaturecard.signature-card-upload', { accountNumber: this.accountNumber });
  }

  getSigCardTemplate(): void {
    this.signatureCardService.getSigCardTemplate().catch(err => {
      this.serviceHelper.errorHandler(err);
      console.log(err);
    });
  }
}
