import { format, isValid } from 'date-fns';

import { BeneficiaryType } from '@shared/enums';
import { BeneficiaryCommon } from '@shared/models';

import { Beneficiary, SpousalBeneficiary, TaxPlanBeneficiary } from '../models';

const commonDateFormat = 'MM/dd/yyyy';

export function mapBaseBeneficiaryToCommon(source: Beneficiary): BeneficiaryCommon {
  // BirthDate is stored in 'Identity' field as a plain string
  // parse it but check and react to invalid dates (nullify them)
  let identity = null;

  if (source.identity && isValid(new Date(source.identity))) {
    identity = format(new Date(source.identity), commonDateFormat);
  }

  return new BeneficiaryCommon({
    key: source.key,
    level: BeneficiaryType.Primary, // Base beneficiaries are always primary
    name: source.name,
    percent: source.percent,
    identity,
    relationship: source.relationship,
  });
}

export function mapTaxPlanBeneficiaryToCommon(source: TaxPlanBeneficiary): BeneficiaryCommon {
  let identity = null;

  if (isValid(new Date(source.birthDate))) {
    identity = format(new Date(source.birthDate), commonDateFormat);
  }

  return new BeneficiaryCommon({
    key: source.key,
    level: source.level,
    name: source.name,
    percent: source.percent,
    identity,
    relationship: source.relationshipId,
  });
}

export function mapCommonToBaseBeneficiary(source: Partial<BeneficiaryCommon>): Beneficiary {
  let identity = '';

  if (isValid(new Date(source.identity))) {
    identity = format(new Date(source.identity), commonDateFormat);
  }

  return new Beneficiary({
    key: source.key,
    name: source.name,
    percent: source.percent,
    identity,
    relationship: source.relationship,
  });
}

export function mapCommonToTaxPlanBeneficiary(
  source: Partial<BeneficiaryCommon>,
  cif: string,
  taxPlanType: number
): TaxPlanBeneficiary {
  let birthDate = '';

  if (isValid(new Date(source.identity))) {
    birthDate = new Date(source.identity).toISOString();
  }

  return new TaxPlanBeneficiary({
    key: source.key,
    level: source.level,
    name: source.name,
    percent: source.percent,
    birthDate,
    relationshipId: source.relationship,
    cif,
    planCode: taxPlanType,
  });
}

export function mapCommonToSpousalBeneficiary(source: Partial<BeneficiaryCommon>): SpousalBeneficiary {
  let birthday = '';

  if (isValid(new Date(source.identity))) {
    birthday = new Date(source.identity).toISOString();
  }

  return new SpousalBeneficiary({
    fullName: source.name,
    relationship: source.relationship,
    birthday,
    percentage: source.percent,
  });
}
