import { createAction, props } from '@ngrx/store';

import { OlbAccount } from '@app/accounts/models';
import { FundingAccount } from '@core/models';
import { FundingMethod } from '@shared/enums';

export const turnOnFunding = createAction('[Funding] Turn funding on');
export const turnOffFunding = createAction('[Funding] Turn funding off');
export const changeFundingMethod = createAction('[Funding] Change funding method', props<{ payload: FundingMethod }>());
export const changeFundingAmount = createAction('[Funding] Change funding amount', props<{ payload: number }>());
export const setupFundingAccountId = createAction('[Funding] Setup funding account ID', props<{ payload: number }>());
export const setupAccountsToBeFunded = createAction(
  '[Funding] (Legacy) Setup accounts to be funded',
  props<{ payload: OlbAccount[] }>()
);
export const setupFundingAccounts = createAction(
  '[Funding] Setup funding accounts',
  props<{ payload: FundingAccount[] }>()
);
export const setupFundingFromAccountId = createAction(
  '[Funding] Setup "from" funding account ID',
  props<{ payload: number }>()
);
export const fundAccount = createAction('[Funding] Fund account');
