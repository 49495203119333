import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { DepositsTopics } from '@app/support/enums';

@Component({
  selector: 'how-to-deposits-topics',
  templateUrl: './deposits-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DepositsTopicsComponent {
  selectedTopic: DepositsTopics;
  topics = DepositsTopics;

  selectTopic(topic: DepositsTopics): void {
    this.selectedTopic = topic;
  }
}
