<div class="container">
  <div class="header-section-title text-center mb-0">
    <div>
      <span class="header__title">
        Request Pending
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-center warning" style="height: 4rem; background-color: rgba(222, 244, 255, 1);">
    <div class="align-self-center mr-4">
      <app-svg-icon
        [iconName]="exclamationIcon"
        [styles]="{ width: '1.5625rem', height: '1.5625rem', fill: '#CD4241' }"
      ></app-svg-icon>
    </div>
    <div class="align-self-center">
      In order to complete this Direct Deposit Request, the attached Print Switch PDF must be submitted to your
      employer.
    </div>
  </div>

  <div class="row justify-content-center mt-4">
    <p class="review-title">
      Print this form and deliver to your employer to complete Direct Deposit Enrollment
    </p>
    <div class="col-12 col-sm-8 text-center mt-1">
      <p class="review-subtitle">
        It can take up to 2 pay periods before your direct deposit takes effect.
      </p>
    </div>
  </div>

  <div class="d-flex align-items-center information-container">
    <app-click-switch-details
      class="row justify-content-center col-12 col-md-10 col-lg-8"
      title="Source Information"
      [details]="sourceInformation"
      [canEdit]="false"
    ></app-click-switch-details>

    <hr class="mt-5 d-sm-none" />

    <app-click-switch-details
      class="row justify-content-center col-12 col-md-10 col-lg-8 mt-5"
      title="Deposit Information"
      [details]="depositInformation"
      [canEdit]="false"
    ></app-click-switch-details>

    <div class="address__form-btns row justify-content-center mt-5">
      <button type="button" class="uk-btn lg solid secondary m-4" id="print-form" (click)="printForm()">
        Print Form
      </button>
      <button type="button" class="link col-12" (click)="goToStatusPage()">
        View Request Status
      </button>
    </div>
  </div>
</div>
