import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { SharedModule } from '@shared/shared.module';

import { makeDepositComponents } from './components';
import { makeDepositModals } from './components/modals';
import { makeDepositServices } from './services';
import { makeDepositViews } from './views';

@NgModule({
  declarations: [...makeDepositViews, ...makeDepositComponents, ...makeDepositModals],
  imports: [SharedModule, MatCheckboxModule, MatDialogModule],
  providers: makeDepositServices,
})
export class MakeDepositModule {}
