import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewContainerRef,
} from '@angular/core';

import { SubSink } from '@axos/subsink';

import {
  CashTransactionInformationComponent,
  DividendInformationComponent,
  FeeTransactionInformationComponent,
  TradeInformationComponent,
  TransactionDetailComponent,
  TransactionInformationComponent,
} from '@app/axos-invest/components';
import { InvestTransactionSubType, InvestTransactionType, TransactionEvent } from '@app/axos-invest/enums';
import { Transaction } from '@app/axos-invest/models';
import { FinancialIcons, MiscIcons, NavigationIcons, UtilityIcons } from '@shared/enums';
import {
  Uk2Tier1FinancialEnum,
  Uk2Tier1InvestEnum,
  Uk2Tier1NavigationEnum,
  Uk2Tier1UtilityEnum,
} from '@axos/uikit-v2-lib';
import { TransactionsState } from '@app/Areas/AAS/features/transactions/core/store/enums';
import { TransactionCategory } from '@app/Areas/AAS/features/transactions/view/transactions-tile/types/transaction-category.types';

@Component({
  selector: 'tr[app-transaction-row]',
  templateUrl: './transaction-row.component.html',
  styleUrls: ['./transaction-row.component.scss'],
})
export class TransactionRowComponent implements OnInit, OnDestroy {
  @Input() transaction: Transaction;
  @Input() unselectEvent: EventEmitter<string>;
  @Input() hideCategory: boolean;
  @Input() hideOptions: boolean = true;
  @Input() isExpanded: boolean = false;
  @Output() rowSelected = new EventEmitter<string>();
  @Output() onClickArrow = new EventEmitter<string>();

  TRANSACTION_EVENT = TransactionEvent;
  iconName: string;
  Uk2Tier1NavigationEnum = Uk2Tier1NavigationEnum;

  isExecuted: boolean = false;
  private selected = false;
  private componentRef: ComponentRef<TransactionDetailComponent>;

  private icons = {
    deposit: FinancialIcons.BankCheckArrowUp,
    transfer: FinancialIcons.DollarArrowForward,
    other: MiscIcons.PuzzlePiece,
    income: FinancialIcons.BankCheckArrowUp,
  };
  private eventIcons = {
    [TransactionEvent.Created]: UtilityIcons.Clock,
    [TransactionEvent.Canceled]: NavigationIcons.Ex,
  };
  private transactionActions = {
    [InvestTransactionSubType.Trade]: () => this.toggleDropdown(TradeInformationComponent),
    [InvestTransactionSubType.Deposit]: () => this.toggleDropdown(CashTransactionInformationComponent),
    [InvestTransactionSubType.Withdrawal]: () => this.toggleDropdown(CashTransactionInformationComponent),
    [InvestTransactionType.MilestoneTransfer]: () => this.toggleDropdown(TransactionInformationComponent),
    [InvestTransactionSubType.Fee]: () => this.toggleDropdown(FeeTransactionInformationComponent),
    [InvestTransactionSubType.Dividend]: () => this.toggleDropdown(DividendInformationComponent),
  };

  transactionCategory = {
    contribution: { categoryName: 'Contribution', categoryIcon: Uk2Tier1FinancialEnum.dollarArrowBack },
    distribution: { categoryName: 'Distribution', categoryIcon: Uk2Tier1FinancialEnum.dollarArrowForward },
    transfer: { categoryName: 'Transfer', categoryIcon: Uk2Tier1NavigationEnum.arrowsHorizontal },
    split: { categoryName: 'Split', categoryIcon: Uk2Tier1InvestEnum.split },
    fee: { categoryName: 'Fee', categoryIcon: Uk2Tier1FinancialEnum.moneyHand },
    dividendInterest: { categoryName: 'Dividend/Interest', categoryIcon: Uk2Tier1InvestEnum.shortInterest },
    other: { categoryName: 'Other', categoryIcon: '' },
    pending: { categoryName: 'Pending', categoryIcon: Uk2Tier1UtilityEnum.clock },
  };

  private subsink = new SubSink();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef<HTMLTableRowElement>
  ) {}

  ngOnInit(): void {
    this.isExecuted = this.transaction.state === TransactionsState.Processed;
    this.iconName = this.getIconName(this.transaction);
    const hasAction = this.getTransactionAction();
    if (!!hasAction) {
      this.elementRef.nativeElement.classList.add('selectable');
    }
    if (this.unselectEvent) {
      this.subsink.sink = this.unselectEvent.subscribe(transactionUuid => {
        if (this.selected && this.transaction.uuid !== transactionUuid) this.selectTransaction(false);
      });
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  selectTransaction(emitEvent: boolean = true) {
    const transactionAction = this.getTransactionAction();

    if (transactionAction) {
      transactionAction.bind(this)();
      this.elementRef.nativeElement.classList.toggle('selected');
      this.selected = !this.selected;
      if (emitEvent && this.selected) this.rowSelected.emit(this.transaction.uuid);
    }
  }

  expand(transaction: any) {
    this.isExpanded = !this.isExpanded;
    this.onClickArrow.emit(transaction);
  }

  canExpandDetails(transaction: any) {
    return !transaction.isPending;
  }

  getTransactionCategory(activityCode: string, transactionState: TransactionsState): TransactionCategory {
    if (transactionState === TransactionsState.Pending) {
      return this.transactionCategory.pending;
    }
    if (activityCode.startsWith('1')) {
      return this.transactionCategory.contribution;
    }
    if (activityCode.startsWith('2') || activityCode === '304') {
      return this.transactionCategory.distribution;
    }
    if (activityCode.startsWith('6') && !['605', '606', '611', '617', '618', '666', '667'].includes(activityCode)) {
      return this.transactionCategory.transfer;
    }
    if (['605', '606', '617', '618'].includes(activityCode)) {
      return this.transactionCategory.split;
    }
    if (activityCode.startsWith('3') && activityCode !== '304') {
      return this.transactionCategory.fee;
    }
    if (activityCode.startsWith('4')) {
      return this.transactionCategory.dividendInterest;
    }

    return this.transactionCategory.other;
  }

  getTransactionStatus(transaction: Transaction): string {
    return transaction.state == TransactionsState.Pending
      ? 'Pending'.toUpperCase()
      : moment(transaction.date).utc().format('MM/DD/YYYY');
  }

  private getTransactionAction() {
    return (
      this.transactionActions[this.transaction.axosInvestSubType] ??
      this.transactionActions[this.transaction.axosInvestType]
    );
  }

  private toggleDropdown(component: Type<TransactionDetailComponent>) {
    if (this.selected) {
      this.destroyComponent();
    } else {
      this.loadComponent(component);
    }
  }

  private getIconName(transaction: Transaction) {
    const eventIcon = this.eventIcons[transaction.event];
    if (eventIcon) return eventIcon;

    const icon = this.icons[transaction.category];

    return icon ?? this.icons.other;
  }

  private loadComponent(component: Type<TransactionDetailComponent>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.viewContainerRef.clear();

    this.componentRef = this.viewContainerRef.createComponent<TransactionDetailComponent>(componentFactory);
    this.componentRef.instance.transaction = this.transaction;
  }

  private destroyComponent() {
    this.componentRef.destroy();
  }
}
