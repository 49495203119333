<div class="confirmation-page">
  <div class="header-section-title text-center">
    <div>
      <svg aria-hidden="true" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <circle
          *ngIf="!isAxos()"
          class="checkmark__circle2"
          cx="30"
          cy="30"
          r="26"
          fill="none"
          stroke-linecap="round"
        />
        <path
          class="checkmark__check2"
          fill="none"
          d="M19.1 30.6l8.9  6.5 13-15.1"
          stroke-linecap="round"
          stroke-corner="round"
          stroke-linejoin="round"
        />
      </svg>
      <h1>Payment Scheduled</h1>
    </div>
  </div>

  <div class="confirmation-section fcol-xs-12 fcol-sm-10 fcol-sm-offset-1 d-flex flex-column middle">
    <div class="confirmation-subtitle">
      <span>
        You've successfully scheduled your loan payment
      </span>
    </div>

    <div class="confirmation-table col-12 col-sm-8">
      <ng-container *ngFor="let record of paymentInformation">
        <div class="confirmation-detail-item" *ngIf="record.value">
          <div class="detail-item">
            <p>{{ record.name }}</p>
          </div>
          <div class="detail-item">
            <p>{{ record.value }}</p>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="confirmation-buttons frow no-wrap-xs center" *ngIf="!isOneTime">
      <p>
        To modify or delete this transfer, go to the
        <a (click)="redirectToSchedulerTransfers()">Scheduled Transfers</a>
        page.
      </p>
    </div>

    <div class="confirmation-buttons frow no-wrap-xs center">
      <button type="button" class="uk-btn lg outline primary" (click)="redirectToAccountsDashboard()">
        View Accounts
      </button>
      <button type="submit" class="uk-btn lg solid secondary" (click)="redirectToDashboards()">
        Go to Dashboard
      </button>
    </div>
  </div>
</div>
