<div class="dialog-backdrop d-flex align-items-center justify-content-center">
  <div class="dialog-box">
    <div class="d-flex">
      <div class="dialog-close ml-auto d-flex align-items-center">
        <div class="flex-grow-1" style="padding-left: 70px; padding-top: 7px;">
          <b>
            <h3 class="mb-2 title_text" style="text-align: center;">
              Wait, before you go...
            </h3>
          </b>
        </div>
        <div class="flex-shrink-1">
          <button (click)="closeSpeedBump()">
            <app-svg-icon
              [iconName]="closeIcon"
              [styles]="{ width: '18px', height: '18px' }"
            ></app-svg-icon>
          </button>
        </div>
      </div>
    </div>
    <hr style="margin: 15px;" />
    <div class="dialog-body px-4 py-2 d-flex flex-column">
      <p class="dialog-body-subtitle">
        Open an account to enjoy these premium Cash Management Checking
        benefits.
      </p>
      <div class="d-flex just just justify-content-center">
        <div
          class="col col-sm-12 d-flex flex-fill flex-column align-items-center dialog-card"
        >
          <div>
            <img src="assets/img/icons/AAS/bag_hand.png" alt="bag hand icon" />
          </div>
          <div>
            <p>$5,000 Overdraft Line of Credit</p>
          </div>
          <div class="card-text">
            <span>
              No credit check required.
            </span>
          </div>
        </div>
        <div
          class="col col-sm-12 d-flex flex-fill flex-column align-items-center dialog-card"
        >
          <div>
            <img src="assets/img/icons/AAS/global.png" alt="global icon" />
          </div>
          <div>
            <p>Airport Lounge Access</p>
          </div>
          <div class="card-text">
            <span>
              Access 1,300 lounges with a free Priority Pass membership.
            </span>
          </div>
        </div>
        <div
          class="col col-sm-12 d-flex flex-fill flex-column align-items-center dialog-card"
        >
          <div>
            <img
              src="assets/img/icons/AAS/user_group.png"
              alt="user group icon"
            />
          </div>
          <div>
            <p>Priority Service</p>
          </div>
          <div class="card-text">
            <span>
              Enjoy expedited support from a dedicated concierge team.
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin: 30px 30px 15px 30px;" />
    <div class="dialog-footer p-1 d-flex align-items-center">
      <div class="flex-fill">
        <button class="dialog-cancel" (click)="skipSpeedBump()">
          Not Right Now
        </button>
      </div>
      <div class="flex-fill">
        <button class="dialog-ok btn-primary" (click)="onProceed()">
          Open an Account
        </button>
      </div>
    </div>
  </div>
</div>
