import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { getBrandProperty } from '@app/store/selectors';
import { BrandProperty } from '@core/enums';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch-terms-modal',
  templateUrl: './click-switch-terms-modal.component.html',
  styleUrls: ['./click-switch-terms-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClickSwitchTermsModalComponent implements OnInit, OnDestroy {
  backgroundUrl: string;
  htmlBody: string;
  icon = AlertsIcons.ExclamationCircle;
  svgStyle = {
    width: '5rem',
    height: '5rem',
    fill: 'white',
  };

  private subsink = new SubSink();

  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: ClickSwitchTermsModalData,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.backgroundUrl = `url('/assets/${this.settings.brand}/bg_pattern.jpg')`;
    this.subsink.sink = this.store.select(getBrandProperty(BrandProperty.TermsAndConditions)).subscribe(value => {
      this.htmlBody = value;
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}

export interface ClickSwitchTermsModalData {
  phoneNumber: string;
  bankName: string;
}
