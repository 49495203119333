<div class="company-view-all">
  <div class="my-company">Details</div>
  <button *ngIf="data" type="button" class="btn-link view-all row no-underline" (click)="isExpanded = !isExpanded">
    <div class="view-all">View {{ isExpanded ? 'Fewer' : 'All' }}</div>
    <app-svg-icon
      (click)="isExpanded = !isExpanded"
      [iconName]="isExpanded ? icons.ChevronUp : icons.ChevronDown"
      [styles]="{ width: '14px' }"
      [iconColor]="'#2F5B88'"
    ></app-svg-icon>
  </button>
</div>
<div *ngIf="data">
  <div class="header-row">
    <div class="column-header-content">
      <div>Full Name</div>
    </div>
    <div class="column-header-content">
      <div>Headquarters</div>
    </div>
    <div class="column-header-content">
      <div>Founded</div>
    </div>
    <div class="column-header-content">
      <div>Employees</div>
    </div>
  </div>
</div>
<div class="company-row">
  <div class="company-row-content full-name">{{ data.fullName }}</div>
  <div class="company-row-content address">{{ data.address.city }}, {{ data.address.state }}</div>
  <div class="company-row-content founded-date">{{ data.foundedDate | date: 'MM/dd/YYYY' }}</div>
  <div class="company-row-content employees">{{ data.employees | number }}</div>
</div>
<div class="header-row" *ngIf="isExpanded">
  <div class="column-header-content">
    <div>Description</div>
  </div>
</div>
<div class="company-row" *ngIf="isExpanded">
  <div class="company-row-content description-content description">
    {{ data.description }}
  </div>
</div>
