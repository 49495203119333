<ng-container [ngSwitch]="currentView">
  <up-section-heading
    [section]="currentView"
    [canReturn]="currentView !== views.EmploymentInfo"
    [mobile]="isMobileDevice && currentView === views.EmploymentInfo"
    (return)="goBack()"
    (expand)="expanded = $event"
  ></up-section-heading>

  <div *ngSwitchDefault [@startStaticSlideFromLeft]="animationState">
    <div class="section__heading">
      <p class="mb-3">
        Verify your Employment Information. This information is helpful when qualifying for a loan or opening a new
        account.
      </p>
    </div>

    <div *ngIf="!isMobileDevice || expanded" @openClose [@.disabled]="!isMobileDevice">
      <up-profile-item
        *ngIf="isIraEnhBaseFlagActive; else liteEmploymentInfo"
        itemName="Employment Info"
        [editable]="userCanEdit"
        (edit)="goTo(views.ChangeEmploymentInfo)"
        [alertMessage]="alertMessages.employment"
        class="employment-info-section"
      >
        <app-dot-loader *ngIf="profileLoading || catalogsLoading; else info"></app-dot-loader>

        <ng-template #info>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 employment-status">
            <p class="col-12 col-lg-5 font-weight-bold">Employment Status</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              [innerText]="employmentStatuses[employmentInfo.employmentStatus] || ''"
            ></p>
          </div>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 occupation" *ngIf="hasOccupation">
            <p class="col-12 col-lg-5 font-weight-bold" [innerText]="occupationLabel"></p>
            <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.occupation"></p>
          </div>

          <ng-container *ngIf="isEmployed">
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 years-employed">
              <p class="col-12 col-lg-5 font-weight-bold">Years Employed</p>
              <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.yearsEmployed"></p>
            </div>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 employer-name">
              <p class="col-12 col-lg-5 font-weight-bold">Employer Name</p>
              <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.employer"></p>
            </div>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 employer-address">
              <p class="col-12 col-lg-5 font-weight-bold">Employer Address</p>
              <p
                class="col-12 col-lg-7 font-weight-normal value"
                [innerText]="employmentInfo.employerAddress | address"
              ></p>
            </div>
          </ng-container>
        </ng-template>
      </up-profile-item>

      <ng-template #liteEmploymentInfo>
        <up-profile-item
          itemName="Employment Info"
          [editable]="userCanEdit"
          (edit)="goTo(views.ChangeEmploymentInfo)"
          [alertMessage]="alertMessages.employment"
          class="lite-employment-info-section"
        >
          <app-dot-loader *ngIf="profileLoading || catalogsLoading; else info"></app-dot-loader>

          <ng-template #info>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 occupation" *ngIf="employmentInfo.occupation">
              <p class="col-12 col-lg-5 font-weight-bold">Occupation</p>
              <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.occupation"></p>
            </div>
            <div class="d-flex flex-column flex-lg-row no-gutters mb-3 employer-name" *ngIf="employmentInfo.employer">
              <p class="col-12 col-lg-5 font-weight-bold">Employer Name</p>
              <p class="col-12 col-lg-7 font-weight-normal value" [innerText]="employmentInfo.employer"></p>
            </div>
          </ng-template>
        </up-profile-item>
      </ng-template>
    </div>
  </div>

  <up-change-employment-info
    @slideFromRight
    *ngSwitchCase="views.ChangeEmploymentInfo"
    [employmentInfo]="employmentInfo"
    (updateEmploymentInfo)="updateEmploymentInfo($event)"
    (back)="goBack()"
  ></up-change-employment-info>
</ng-container>
