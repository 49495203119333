import { IRootScopeService } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { Inject } from 'decorators/decorators';
import * as moment from 'moment';
import { tz } from 'moment-timezone';

import { SupportFile } from '@shared/models/support-file.model';

import { WireRequest, WireTransfer, WireTransferDestination } from '../../typings/WireTransfer';
@Inject('$rootScope', '$state', '$stateParams', '$uibModal', 'env')
export class ReviewTransferController {
  wiretransfer: WireTransfer;
  wireRequest = new WireRequest();
  acceptedTerms = false;
  termsError = false;
  showSuccessView = false;
  time: string;
  formatedAmount: string;
  formatedPurpose: string;
  formatedBank: string;
  isBusy = false;
  brandName: string;
  /**Attachments */
  errorMessage: string;
  transferDestiny = WireTransferDestination;
  isLocalTransfer: boolean;
  brand: string;
  totalMaxSize = 10485760; // 10mb

  constructor(
    private readonly rootScope: IRootScopeService,
    private readonly state: IStateService,
    private readonly stateParams: IStateParamsService,
    private readonly uibModal: ng.ui.bootstrap.IModalService,
    private readonly env: OlbSettings
  ) {}

  /** Initialize all required fields */
  $onInit() {
    if (!this.stateParams['wiretransfer']) this.state.go('udb.transfers.wireTransfers');
    this.brand = this.env.brand;
    const element = document.getElementsByClassName('header-section-title')[0] as HTMLElement;
    element.scrollIntoView();

    this.wiretransfer = this.stateParams['wiretransfer'];
    if (this.wiretransfer == null) {
      this.state.go('udb.transfers.wireTransfers');
    }

    if (this.rootScope.hasOwnProperty('brandProperties')) {
      this.brandName = this.rootScope['brandProperties']['WireTransfersBrandName'];
    } else {
      this.rootScope.$on('brandPropertiesLoaded', () => {
        this.brandName = this.rootScope['brandProperties']['WireTransfersBrandName'];
      });
    }

    this.isLocalTransfer = this.wiretransfer.destination === WireTransferDestination.us;

    document.querySelector('.header-section-title').scrollIntoView();
  }

  /** Submits the form and uploads the documents added */
  continueWire() {
    if (!this.acceptedTerms) {
      this.termsError = true;

      return;
    }

    this.wireRequest.AccountFrom = this.wiretransfer.selectedFromAccount.accountNumber.toString();
    this.wireRequest.RecipientBank = this.wiretransfer.bankDetails.accountNumber;
    this.wireRequest.RecipientName = this.wiretransfer.recipientDetails.name;
    this.wireRequest.Amount = this.wiretransfer.amount;
    this.wireRequest.Subject = this.wiretransfer.purpose;
    const template = document.getElementById('templateTable');
    this.wireRequest.Body = template.innerHTML;

    const wireDetails = this.wiretransfer;
    this.wireRequest.wireTransferDetails = { ...wireDetails };
    // remove the documents from details object since API get it duplicates
    this.wireRequest.wireTransferDetails.documents = [];

    // PST is not always 8 hours behind, for part of the year it is 7.
    // This constructor is DST (daylight savinconstime) aware, and will use the correct offset when parsing.
    const localTime = tz(moment.utc().format('YYYY-MM-DD HH:mm'));
    this.time = localTime.tz('America/Los_Angeles').format('M/DD/YYYY [at] HH:mm A [PST]');

    const data = {
      file: this.wiretransfer.documents, // here should go the attached archive, this functionality was not required at the moment of this pbi
      transfer: new WireRequest(),
      description: 'transfer',
    };
    data.transfer = this.wireRequest;

    this.state.go('udb.transfers.wireTransfers.multiFactor', { wiretransfer: data });
  }

  /** Displays the About modal */
  displayAboutModal() {
    this.uibModal.open({
      controllerAs: '$ctrl',
      controller: 'WireTransfersAboutController',
      templateUrl:
        'transfers/wire-transfers/wire-transfers-about-modal/wire-transfers-about-modal.tpl.html',
      windowClass: 'modal-service',
    });
  }

  /** Redirects the user to the recipient page */
  goToRecipientDetails() {
    this.state.go('udb.transfers.wireTransfers.recipientDetails', {
      wiretransfer: this.wiretransfer,
    });
  }

  /** Redirects the user to the Wire Transfers page */
  goToMainDetails() {
    this.state.go('udb.transfers.wireTransfers', {
      wiretransfer: this.wiretransfer,
    });
  }

  /** Redirects the user to the Bank Details page */
  goToBankDetails() {
    this.state.go('udb.transfers.wireTransfers.bankDetails', {
      wiretransfer: this.wiretransfer,
    });
  }

  filesUpdate(supportFiles: SupportFile[]) {
    this.wiretransfer.documents = supportFiles;
  }
}
