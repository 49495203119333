<div class="access-code">
  <h2 class="form-group access-code__title">
    {{ infoBoxText }}
  </h2>

  <form class="text-center" name="validateSMS" [formGroup]="accessCodeForm" (ngSubmit)="onSubmit()">
    <h3 class="form-group access-code__subtitle">
      Enter your verification code and click continue
    </h3>

    <span aria-live="polite" role="alert" *ngIf="errorMessage || getFieldError('code') as error" class="text--error">
      {{ error }}
    </span>

    <div class="form-group">
      <input
        id="access-code-entry"
        class="uk-input access-code__entry"
        type="text"
        aria-label="Verification Code"
        formControlName="code"
        [class.errorValidation]="errorMessage || getFieldError('code')"
        required
        autofocus
      />
    </div>

    <p class="form-group">
      Didn’t receive your verification code?
      <button
        id="btn-resend-otp"
        type="button"
        class="link"
        (click)="resendCode()"
        aria-label="Resend verification code"
      >
        RESEND
      </button>
    </p>

    <div class="form-group" *ngIf="displayRememberDevice">
      <input id="trustedCheck" class="checkbox" name="trustedCheck" type="checkbox" formControlName="remember" />
      <label for="trustedCheck">
        Remember this device to make future logins easier.
      </label>
    </div>

    <div *ngIf="!isLoading" class="form-group access-code__actions">
      <button id="btn-back" class="btn-cancel button--sm access-code__action" type="button" (click)="clickedBack()">
        Back
      </button>
      <button
        id="btn-continue"
        class="btn-ok button--sm access-code__action"
        type="submit"
        [class.disabled]="accessCodeForm.invalid"
      >
        Continue
      </button>
    </div>

    <app-dot-loader [showWhen]="isLoading"></app-dot-loader>
  </form>
</div>
