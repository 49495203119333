import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';
import { DropdownItem } from '@uikit/clickdropdown';
import * as angular from 'angular';

import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { AxosAdvisoryAccount } from '@core/models';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

import { AccountCategory } from '../../accounts/account-category.enum';
import { Inject } from '../../decorators/decorators';
import { CachedAccountsService } from './../../services/cached-accounts.service';
import { AlertsUI } from './typings/AlertsUI';

@Inject(
  '$scope',
  'alertsNotificationsService',
  'alertsSmsService',
  'cachedAccountsService',
  '$filter',
  '$q',
  '$rootScope',
  'featureFlagService',
  'ngrxStore',
  'supportViewFacade'
)
export class MyAlertsController {
  alerts: Alerts[];
  accounts: OlbAccount[] = [];
  riaAccounts: AxosAdvisoryAccount[] = [];
  availableAlerts: AlertsUI[] = [];
  userAlerts: AlertsUI[] = [];
  accountCategories: number[] = [];
  draggableAlerts: AlertsUI[] = [];
  noAxosProducts = false;
  noAxosAccountsIsLoading = true;
  isLoading = true;
  isDragging = false;

  // array used to pass success/fail messages from  create-alert.controller to this controller
  saveSuccess: string[] = [];
  loadingUserAlerts = false;
  loadingUpdateUserAlerts = false;
  editingAlert = false;
  isNewAlert = false;
  savingAlert = false;
  oldAlert = false;
  closeAccountCodes: string[];
  brandName: string;
  lastTrans = 5;
  editMode = false;
  savingTrans = false;
  editAccMode = false;
  savingAccTrans = true;
  saveSmsSuccess = false;
  showPNs = false;
  dropFrom: DropdownItem[] = new Array<DropdownItem>();
  dropTo: DropdownItem[] = new Array<DropdownItem>();
  fromAccount: DropdownItem = new DropdownItem('[Select Account]');
  toAccount: DropdownItem = new DropdownItem('[Select Account]');
  readonly transNumberOptions: number[] = [5, 4, 3, 2, 1];
  readonly alertsTitles: string[] = ['Statements', 'Tax Forms', 'Documents'];
  readonly alertsObj = {
    statement: { title: 'Statements' },
    taxForms: { title: 'Tax Forms' },
    documents: { title: 'Documents' },
  };

  // Settings to drag and drop alerts.
  alertOptions = {
    beforeDrop: (event: any) => {
      if (this.isDragging) {
        const alertUI: AlertsUI = event.source.nodeScope.$modelValue;
        this.addAlert(alertUI);
      }

      this.isDragging = false;

      return false;
    },
  };

  dragOptions = {
    accept: () => {
      this.isDragging = true;
    },
  };

  private menuAlertAvailable = false;
  private lastSmsConfiguration: SmsConfiguration;
  private subsink = new SubSink();

  constructor(
    private readonly scope: ng.IScope,
    private readonly alertsNotificationsService: IAlertsNotificationsService,
    private readonly alertsSmsService: IAlertsSmsService,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly filter: ng.IFilterService,
    private readonly q: ng.IQService,
    private readonly rootScope: ng.IRootScopeService,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    private readonly supportViewFacade: SupportViewFacade
  ) {}

  $onInit(): void {
    this.supportViewFacade.getRIAUserFlag();
    this.supportViewFacade.axosAccountsLoading$.subscribe(isLoading => {
      this.noAxosAccountsIsLoading = isLoading;
      if (!isLoading) {
        this.supportViewFacade.noAxosAccounts$.subscribe(result => (this.noAxosProducts = result));
      }
    });
    this.closeAccountCodes = ['2', '3', '9', '12', '24', '19'];

    // SUBSCRIBES TO getRiaAccounts Selector
    this.subsink.sink = this.store.select(getAxosAdvisoryAccounts).subscribe((riaAccounts: AxosAdvisoryAccount[]) => {
      this.riaAccounts = riaAccounts;
    });

    const { internalAccounts } = angular.copy(this.cachedAccountsService.allAccounts);

    if (internalAccounts) {
      this.configureAccountsAlerts(internalAccounts);
      this.setUpSmsTable();
    }

    this.rootScope.$on('balancesAvailable', () => {
      this.configureAccountsAlerts(angular.copy(this.cachedAccountsService.allAccounts.internalAccounts));
      this.setUpSmsTable();
    });
    this.scope.$on('loadUserAlerts', () => {
      this.loadUserAlerts();
    });

    this.scope.$on('showLoadingAlert', (_event: any, show: boolean) => {
      this.showLoadingUpdateAlertAnimation(show);
    });

    this.scope.$on('showEditingAlert', (_event: any, show: boolean) => {
      this.editingAlert = show;
    });

    this.scope.$on('restoreAlerts', (_event: any, active: boolean) => {
      if (!this.availableAlerts.find(x => x.url === 'taxformsalerts') && !active) {
        this.restoreTaxtStatementAlert(this.getTaxAlert());
      }
      if (!this.availableAlerts.find(x => x.url === 'statementsalerts') && !active) {
        this.restoreTaxtStatementAlert(this.getStatementAlert());
      }
    });

    this.scope.$on('enableSMS', (_event: any, enabled: boolean) => {
      this.scope.$broadcast('enableSMSNotifications', enabled);
    });

    $('.alerts-notifications').removeClass('alert-history-padding');
  }

  $onDestroy(): void {
    this.subsink.unsubscribe();
  }
  addAlert(alertWidget: AlertsUI) {
    if (!this.loadingUserAlerts && !this.loadingUpdateUserAlerts) {
      if (this.getAlertTitleExists(alertWidget.title)) {
        this.availableAlerts = this.availableAlerts.filter(item => item.title !== alertWidget.title);
      }

      if (this.userAlerts.length > 0) {
        const firstAlert = this.userAlerts[0];

        if (firstAlert.title === this.alertsObj.statement.title && firstAlert.isNew) {
          this.availableAlerts.push(this.getStatementAlert());
        } else if (firstAlert.title === this.alertsObj.taxForms.title && firstAlert.isNew) {
          this.availableAlerts.push(this.getTaxAlert());
        }
      }

      // set id for alert ui on ng-repeat loop
      alertWidget.id = 'alert-' + Math.random();
      alertWidget.isNew = true;
      // position alert on top of the list
      this.userAlerts.unshift(angular.copy(alertWidget));
      const verify = this.getArrayLength();
      if (verify >= 2 && !this.savingAlert) {
        this.userAlerts.splice(1, 1);
      } else if (this.savingAlert && verify >= 2 && this.oldAlert) {
        this.userAlerts.splice(1, 1);
      } else if (this.savingAlert && verify >= 3) {
        this.userAlerts.splice(1, 1);
      }
      this.editingAlert = true;
      this.saveSuccess = [];
      this.isNewAlert = true;
    }
  }

  removeAlert(a: AlertsUI) {
    this.alertsNotificationsService
      .deleteAlert(a.alert.alertId)
      .then(() => {
        const indexToDelete = this.userAlerts.indexOf(a);
        this.userAlerts.splice(indexToDelete, 1);

        if (this.isStatementAlert(a.alert.alertType) || this.isTaxAlert(a.alert.alertType)) {
          this.restoreTaxtStatementAlert(
            a.alert.alertType === this.getStatementDescription() ? this.getStatementAlert() : this.getTaxAlert()
          );
        }
      })
      .catch(() => {
        this.scope.$root.$broadcast('errorDeleteAlert', a.alert.alertId);
      })
      .finally(() => {
        a.deletingAlert = false;
      });
  }

  dragOut() {
    this.isDragging = false;
  }

  // hide Alets success message
  deletemsgBox(): void {
    this.saveSuccess.pop();
  }

  hideCustomizeBox(): void {
    const verify = this.getArrayLength();
    if (verify === 0) {
      this.editingAlert = false;
    }
  }

  getStatementAlert(): AlertsUI {
    return new AlertsUI(
      {
        alertId: '',
        alertType: 'Statement Notification Account',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      true
    );
  }

  getDocumentsAlert(): AlertsUI {
    return new AlertsUI(
      {
        alertId: '',
        alertType: 'Documents',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      true
    );
  }

  getTaxAlert(): AlertsUI {
    return new AlertsUI(
      {
        alertId: '',
        alertType: 'Taxform',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      true
    );
  }

  showLoadingAlertAnimation(showLoading: boolean): void {
    this.loadingUserAlerts = showLoading;
  }

  showLoadingUpdateAlertAnimation(showLoading: boolean): void {
    this.loadingUpdateUserAlerts = showLoading;
  }

  fromChange(data: any): void {
    this.dropTo = [];
    this.fromAccount = data;
    this.savingAccTrans = true;
    this.toAccount = new DropdownItem('[Select Account]');
    this.fillToAccounts(data.id);
  }

  toChange(data: any): void {
    this.toAccount = data;
    this.editAccMode = true;
  }

  chngTransNumber(): void {
    this.editMode = false;
    this.savingTrans = true;
    this.alertsSmsService.saveConfiguration(this.lastTrans).then(res => {
      if (res.status === 'Success') {
        this.savingTrans = false;
        this.saveSmsSuccess = true;
        this.lastSmsConfiguration = res.data as SmsConfiguration;
      }
    });
  }

  cancelTransNumber(): void {
    this.lastTrans = this.lastSmsConfiguration.transactionsNumber;
    this.editMode = false;
  }

  cancelTransAccounts(): void {
    this.editAccMode = false;
    this.savingAccTrans = true;
    this.dropTo = [];
    this.toAccount.id = this.lastSmsConfiguration.toAccountId;
    this.fromAccount.id = this.lastSmsConfiguration.fromAccountId;
    this.fillFromAccounts();
    if (this.toAccount.id === 0) this.toAccount = new DropdownItem('[Select Account]');
    if (this.fromAccount.id === 0) this.fromAccount = new DropdownItem('[Select Account]');
  }

  changeSmsConfiguration(): void {
    const currentConfig = {} as SmsConfiguration;
    currentConfig.transactionsNumber = this.lastTrans;
    currentConfig.toAccountId = parseInt(this.toAccount.id.toString(), 10);
    currentConfig.fromAccountId = parseInt(this.fromAccount.id.toString(), 10);
    this.editAccMode = false;
    this.savingAccTrans = true;
    this.alertsSmsService.saveAccountsConfiguration(currentConfig).then(res => {
      if (res.status === 'Success') {
        this.savingAccTrans = false;
        this.saveSmsSuccess = true;
        this.lastSmsConfiguration.toAccountId = currentConfig.toAccountId;
        this.lastSmsConfiguration.fromAccountId = currentConfig.fromAccountId;
      }
    });
  }

  isStatementAlert(alertType: string): boolean {
    return alertType === this.getStatementDescription();
  }

  isTaxAlert(alertType: string): boolean {
    return alertType === this.getTaxDescription();
  }

  // Checks if it's a RIA account
  // TRUE: Displays DocumentsAlert - FALSE: Don't displays Documents Alert
  isDocumentAlert(alertType: string): boolean {
    if (this.riaAccounts !== null && this.riaAccounts.length > 0) {
      return alertType === this.getDocumentDescription();
    } else {
      return false;
    }
  }

  getDocumentDescription(): string {
    return 'Documents';
  }

  getStatementDescription(): string {
    return 'Statement Notification Account';
  }

  getTaxDescription(): string {
    return 'Taxform';
  }

  checkAvailableAlerts(newAlertUI: AlertsUI): void {
    // regenerate collection to avoid to trigger watch() method, but just regenerate saved alerts
    // it is needed to keep also the alerts that are in edit mode.
    let alertsRegistered = [...this.userAlerts].filter(a => a.alert.alertId && !a.editMode);
    alertsRegistered = alertsRegistered.map(a => new AlertsUI(a.alert));

    this.userAlerts = this.userAlerts.map(a => {
      const al = alertsRegistered.find(ab => ab.alert.alertId === a.alert.alertId);

      return al ? al : a;
    });

    if (this.isStatementAlert(newAlertUI.alert.alertType) || this.isTaxAlert(newAlertUI.alert.alertType)) {
      this.availableAlerts = [...this.availableAlerts].filter(av => av.alert.alertType !== newAlertUI.alert.alertType);
    }
  }

  private getArrayLength(): number {
    const alertsArray: string[] = [];
    this.userAlerts.forEach(item => {
      if (item.alert.alertId === '') {
        alertsArray.push('1');
      }
    });

    return alertsArray.length;
  }

  // Sets the default available alerts.
  private configureInitialAlertTypes(): AlertsUI[] {
    const alertsArray: Alerts[] = [
      {
        alertId: '',
        alertType: 'Balance Snapshot',
        frequency: '',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Large Withdrawal',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Large Deposit',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Check Cleared',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Low Balance',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'High Balance',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Maturity Date',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Loan Payment Due Date',
        accountNumber: '',
        accountType: 'Loan',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Loan Overdue',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
    ];
    const availableAlertsArray: AlertsUI[] = [];

    alertsArray.forEach((obj: Alerts) => {
      const alertUI = new AlertsUI(obj, true);
      availableAlertsArray.push(alertUI);
    });

    availableAlertsArray.push(this.getStatementAlert());
    availableAlertsArray.push(this.getTaxAlert());
    if (this.featureFlagService.isRiaPilotDocumentAlert()) {
      availableAlertsArray.push(this.getDocumentsAlert());
    }

    return availableAlertsArray;
  }

  private restoreTaxtStatementAlert(alert: AlertsUI) {
    // check if at exist one of them
    if (
      this.availableAlerts.findIndex(
        av => this.isStatementAlert(av.alert.alertType) || this.isTaxAlert(av.alert.alertType)
      ) !== -1
    ) {
      // preserve original order
      if (this.isTaxAlert(alert.alert.alertType)) this.availableAlerts.push(alert);
      else this.availableAlerts.splice(this.availableAlerts.length - 1, 0, alert);
    } else this.availableAlerts.push(alert);
  }

  /**
   * Get User Accounts, get user accounts categories and fill alert dropdowns list.
   */
  private configureAccounts(olbAccounts: OlbAccount[]): void {
    this.accounts = [];
    // If the status code is one of the defined close status
    // do not add it to accounts list
    olbAccounts.forEach((activeAccount: OlbAccount) => {
      let isActive = true;

      this.closeAccountCodes.forEach(closeCode => {
        if (activeAccount.statusCode === closeCode) {
          isActive = false;

          return false;
        }

        return false;
      });

      if (isActive) this.accounts.push(activeAccount);
    });
  }

  private GetAccountCatetories(): number[] {
    let accountCategories: number[] = [];

    // Get the user accounts categories
    this.accounts.forEach((account: OlbAccount) => {
      // Quick fix to match the new categories (admin sections)
      accountCategories.push(account.category === 2 ? 1 : account.category);
    });

    // Filter available alerts for user Account types
    accountCategories = [...accountCategories].filter((item: number, pos: number) => {
      return accountCategories.indexOf(item) === pos;
    });

    return accountCategories;
  }

  private ConfigureAvailableAlerts(alertsRegistered: Alerts[]): void {
    // set all alert types
    let availableAlerts = this.configureInitialAlertTypes();

    availableAlerts = this.SetAvailableAlertsByAccountCatetory(availableAlerts);
    this.availableAlerts = this.SetAvailableAlertsByAlertsRegistered(availableAlerts, alertsRegistered);
    this.menuAlertAvailable = true;
  }

  private SetAvailableAlertsByAccountCatetory(availableAlerts: AlertsUI[]): AlertsUI[] {
    this.accountCategories = this.GetAccountCatetories();
    const alerts: AlertsUI[] = [];
    availableAlerts = [...availableAlerts].filter((alert: AlertsUI) => {
      if (
        this.isStatementAlert(alert.alert.alertType) ||
        this.isTaxAlert(alert.alert.alertType) ||
        this.isDocumentAlert(alert.alert.alertType) ||
        this.accountCategories.indexOf(alert.category) !== -1
      ) {
        alerts.push(alert);
      }
    });

    return alerts;
  }

  private SetAvailableAlertsByAlertsRegistered(availableAlerts: AlertsUI[], alertsRegistered: Alerts[]): AlertsUI[] {
    let taxtStatementAlerts: Alerts[] = [];

    taxtStatementAlerts = alertsRegistered.filter(
      a => this.isStatementAlert(a.alertType) || this.isTaxAlert(a.alertType)
    );

    // removing Statement notification or Taxt Form if already exist
    availableAlerts = [...availableAlerts].filter(
      a => !taxtStatementAlerts.length || taxtStatementAlerts.findIndex(tx => tx.alertType === a.alert.alertType) === -1
    );

    return availableAlerts;
  }

  /**
   * set alerts created
   */
  private configureAlerts(alertsRegistered: Alerts[]): void {
    // reset this.saveSuccess array
    this.saveSuccess = [];

    // reset this.userAlerts property, to set user alerts correctly
    if (this.userAlerts && this.userAlerts.length) this.userAlerts = [];

    alertsRegistered.forEach(a => this.userAlerts.push(new AlertsUI(a)));
  }

  //#region User sms configuration section
  private setUpSmsTable(): void {
    this.brandName = '';

    if (this.rootScope['brandProperties']) {
      this.brandName = this.rootScope['brandProperties'].BrandDisplayName;
    } else {
      this.rootScope.$on('brandPropertiesLoaded', () => {
        this.brandName = this.rootScope['brandProperties'].BrandDisplayName;
      });
    }

    // Set default values before loading current user configuration
    this.alertsSmsService.getConfiguration().then(response => {
      const data = response.data as SmsConfiguration;
      this.lastTrans = data.transactionsNumber;
      this.toAccount.id = data.toAccountId;
      this.fromAccount.id = data.fromAccountId;
      this.lastSmsConfiguration = data;
      this.fillFromAccounts();
    });
  }

  private fillFromAccounts() {
    const { internalAccounts } = this.cachedAccountsService.getFromAccounts();
    // Load the drop down items
    this.dropFrom = internalAccounts.map(
      (account: OlbAccount) =>
        new DropdownItem(
          account.nickname,
          account.id,
          `Avail Bal: ${this.filter('currency')(account.availableBalance ? account.availableBalance : 0)}`,
          false
        )
    );

    // Select Current FROM Alert Account in case the user already has one selected
    if (this.fromAccount.id !== null && this.fromAccount.id !== 0) {
      const aux = this.fromAccount;
      this.fromAccount = this.dropFrom.filter((a: DropdownItem) => {
        return a.id === this.fromAccount.id;
      })[0];
      if (this.fromAccount !== undefined) this.fillToAccounts(this.fromAccount.id);
      // Load TO accounts depending on the FROM account currently selected by the user
      else {
        this.fromAccount = aux;
        this.editAccMode = false;
        this.savingAccTrans = false;
      }
    } else {
      this.editAccMode = false;
      this.savingAccTrans = false;
      this.fromAccount = new DropdownItem('[Select Account]');
    }
  }

  private fillToAccounts(accountID: any): void {
    this.alertsNotificationsService.getToAccounts(accountID).then(res => {
      const internalToAccountsArray = res.data.internalAccounts as OlbAccount[];
      if (internalToAccountsArray !== null) {
        this.dropTo = internalToAccountsArray
          .filter(a => a.category !== AccountCategory.Loan)
          .map((a: OlbAccount) => {
            return new DropdownItem(
              a.nickname,
              a.id,
              `Avail Bal: ${this.filter('currency')(a.availableBalance)}`,
              false
            );
          });

        // Select Current TO Alert Account in case the user already has one selected
        if (this.toAccount.id !== null && this.toAccount.id !== 0 && this.toAccount.id !== undefined) {
          this.toAccount = this.dropTo.filter((a: DropdownItem) => {
            return a.id === this.toAccount.id;
          })[0];
        }

        this.editAccMode = false;
        this.savingAccTrans = false;
      }
    });
  }
  //#endregion

  private configureAccountsAlerts(olbAccounts: OlbAccount[], configAlerts: boolean = true): void {
    this.showLoadingAlertAnimation(true);

    const alertsPromise = this.q.resolve(this.alertsNotificationsService.getAlerts());
    console.log(alertsPromise);

    this.q
      .all([alertsPromise])
      .then((result: any) => {
        this.configureAccounts(olbAccounts);

        const alerts = result[0].data.data ? result[0].data.data : result[0].data;
        if (!this.menuAlertAvailable) {
          this.ConfigureAvailableAlerts(alerts);
        }

        if (configAlerts) {
          this.configureAlerts(alerts);
          this.removeAlertForClosedAccounts();
        }

        // this loader is used for alert menu
        this.isLoading = false;
        // hide loading master switch animation
        if (configAlerts) {
          this.showLoadingAlertAnimation(false);
        }
      })
      .catch(() => {
        // hide loading master switch animation
        this.showLoadingAlertAnimation(false);
        // this loader is used for alert menu
        this.isLoading = false;
      });
  }

  private loadUserAlerts(): void {
    this.showLoadingUpdateAlertAnimation(true);
    this.alertsNotificationsService
      .getAlerts()
      .then((result: any) => {
        const alerts = result.data.data ? result.data.data : result.data;

        this.configureAlerts(alerts);
        this.removeAlertForClosedAccounts();
        this.showLoadingUpdateAlertAnimation(false);
      })
      .catch(() => {
        this.showLoadingUpdateAlertAnimation(false);
      });
  }

  private removeAlertForClosedAccounts(): void {
    if (this.accounts.length === 0 || this.userAlerts.length === 0) return;

    this.userAlerts.forEach((userAlert, inx) => {
      if (userAlert.alert.accountNumber && userAlert.alert.accountType) {
        const openAccount = this.accounts.filter(account => {
          return userAlert.alert.accountNumber === account.accountNumber;
        });
        if (openAccount.length === 0) this.userAlerts.splice(inx, 1);
      }
    });
  }

  private getAlertTitleExists(alertTitle: string): boolean {
    return this.alertsTitles.includes(alertTitle);
  }
}
