import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import Category from 'typings/app/account-aggregation/Category';

@Component({
    selector: 'app-category-element',
    templateUrl: './category-element.component.html',
    styleUrls: ['./category-element.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryElementComponent {
    @Input() category: Category;
    @Input() transaction: string;
    @Output() onChangeSelectedCategory: EventEmitter<any> = new EventEmitter<any>();

    getImagePath() {
        let logoPath = `/img/icons/categories/${this.category.logoName}_white.svg`.toLowerCase();

        return logoPath;
    }

    selectCategory() {
        this.category.active = !this.category.active;
    }
}
