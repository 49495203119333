<div class="axos-trading-statements">
  <div class="row">
    <div class="col-sm-9">
      <div class="frow center wrap">
        <app-dot-loader *ngIf="isBusy">Loading Statements/Tax Forms</app-dot-loader>
      </div>
      <div class="header" *ngIf="!isBusy">
        <div class="header__title">
          <h3>Statements and Tax Forms</h3>
        </div>
        <div class="header__filters">
          <div class="header__filter">
            <label for="yearFilterLabel" class="year-title">Choose Year</label>
            <select
              id="yearFilterDdl"
              name="yearFilterDdl"
              class="uk-input yearFilterDdl"
              [(ngModel)]="selectedYear"
              (ngModelChange)="filter()"
            >
              <option *ngFor="let year of years" [ngValue]="year" [innerText]="year.label"></option>
            </select>
          </div>
          <div class="header__filter">
            <label for="docTypeLabel" class="document-title">Document Type</label>
            <select
              id="docTypeFilterDdl"
              name="docTypeFilterDdl"
              class="uk-input docTypeFilterDdl"
              [(ngModel)]="selectedDocType"
              (ngModelChange)="filter()"
            >
              <option
                *ngFor="let docType of documentTypeFilter"
                [ngValue]="docType"
                [innerText]="docType.label"
              ></option>
            </select>
          </div>
          <div class="header__filter__jump">
            <button class="btn__link res">
              <a href="#tax-forms">Jump to tax forms</a>
              <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div class="docs-container">
        <div class="statements" *ngIf="!isBusy">
          <h4 class="section-title">Documents</h4>
          <ng-container *ngFor="let main of groupedStatements">
            <div *ngFor="let sub of main.documentsByMonth">
              <h4 class="month-year-title">{{ sub.month }} {{ main.year }}</h4>
              <div class="uk-list">
                <ul class="list" *ngIf="groupedStatements.length > 0">
                  <li class="list-item" *ngFor="let statement of sub.documents">
                    <i class="bofi-pdf"></i>
                    <span
                      (click)="
                        downloadDocument(statement.acct_type, statement.doc_id, statement.key, statement.doc_date)
                      "
                    >
                      {{ getDocumentTypeName(statement.sub_type_name) }} - {{ statement.doc_date | date: 'MM/dd/yyyy' }}
                    </span>
                    <a
                      (click)="
                        downloadDocument(statement.acct_type, statement.doc_id, statement.key, statement.doc_date)
                      "
                    >
                      View
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
          <div *ngIf="groupedStatements.length == 0">
            <span class="no-statements">No Statements found</span>
          </div>
        </div>
        <div id="tax-forms" class="tax-forms">
          <h4 class="section-title__tax">Tax Forms</h4>
          <ng-container *ngFor="let main of groupedTaxForms">
            <h4 class="tax-forms__year">{{ main.year }}</h4>
            <div *ngFor="let sub of main.documentsByMonth">
              <a
                class="tax-link"
                *ngFor="let document of sub.documents"
                (click)="downloadDocument(document.acct_type, document.doc_id, document.key, document.doc_date)"
              >
                {{ document.displayname }}
              </a>
            </div>
          </ng-container>
          <div *ngIf="groupedTaxForms.length == 0">
            <span class="no-taxforms">You do not currently have any documents available</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 account-information mt-4">
      <app-right-navigation (change-tab)="goToTab($event)"></app-right-navigation>
    </div>
  </div>
  <div class="mt-5">
    <app-feature-link></app-feature-link>
  </div>
</div>
>
