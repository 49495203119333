import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { STATE } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';
import { SideMenuItem } from '@shared/models';

@Component({
  selector: 'app-side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
})
export class SideMenuItemComponent {
  @Input() isSubMenu: boolean;
  @Input() menuItem: SideMenuItem;
  @Output() toggleMainMenu = new EventEmitter();

  expanded = false;
  expandIcon = NavigationIcons.ChevronDown;
  contractIcon = NavigationIcons.ChevronUp;

  constructor(@Inject(STATE) private $state: ng.ui.IStateService) {}

  triggerMenuAction(item: SideMenuItem, event: Event) {
    this.toggleMain();
    if (item.externalUrl) {
      window.open(item.externalUrl, '_blank ', 'noopener');
    } else if (item.action) {
      item.action(event);
    } else {
      this.$state.go(item.route);
    }
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  toggleMain() {
    this.toggleMainMenu.emit();
  }
}
