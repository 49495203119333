import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TransactionService } from '@core/services';
import { WINDOW } from '@core/tokens';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { CheckDetailsModalData } from './types';
import { Subject, of } from 'rxjs';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-tx-check-details-modal',
  templateUrl: './tx-check-details-modal.component.html',
  styleUrls: ['./tx-check-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxCheckDetailsModalComponent implements OnInit, OnDestroy {
  checkNo: string;
  checkFiles: any;
  isLoading: boolean;
  hasFailed = false;
  frontImgExt: string;
  backImgExt: string;
  transaction: Transaction;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CheckDetailsModalData,
    @Inject(WINDOW) private window: Window,
    public dialogRef: MatDialogRef<TxCheckDetailsModalComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly transactionService: TransactionService
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.transaction = this.data.transaction;
    const { checkNumber, checkImageId } = this.transaction;

    if (this.checkNo === '--') {
      this.hasFailed = true;
      return;
    }

    this.getCheckFiles(checkNumber, checkImageId);
  }

  getCheckFiles(checkNumber: string, checkImageId: string) {
    this.isLoading = true;
    this.transactionService
      .getCheckFiles(this.data.accountId, checkNumber, checkImageId)
      .pipe(
        takeUntil(this.destroy$),
        tap(response => {
          const data = response.data;
          this.frontImgExt = this.getExtension(data?.frontImage.charAt(0));
          this.backImgExt = this.getExtension(data?.backImage.charAt(0));
          this.checkFiles = data;
        }),
        finalize(() => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        }),
        catchError(err => {
          this.hasFailed = true;
          return of(err);
        })
      )
      .subscribe();
  }

  /** Displays the window to save the file locally */
  downloadCheck() {
    FileSaver.saveAs(this.getCheckAsBlob(), `check-${this.checkNo}.pdf`);
  }

  /** Opens a new window with the image ready for print as pdf */
  printCheck(): void {
    if (this.window.navigator && this.window.navigator.msSaveOrOpenBlob) {
      this.downloadCheck();
      return;
    }

    const src = `data:application/pdf;base64, ${this.checkFiles.checkPdf}`;
    const iframe = `
    <iframe src="${src}"
      frameborder="0"
      style="top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        padding: 0;
        position: absolute;"
      allowfullscreen>
    </iframe>`;
    const window = this.window.open(null, '_blank');
    window.document.open();
    window.document.write(iframe);
    window.document.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /** Gets the check as a blob object */
  private getCheckAsBlob(): Blob {
    // decode base64 string, remove space for IE compatibility
    const binary = atob(this.checkFiles.checkPdf.replace(/\s/g, ''));

    // get binary length
    const len = binary.length;

    // create ArrayBuffer with binary length
    const buffer = new ArrayBuffer(len);

    // create 8-bit Array
    const view = new Uint8Array(buffer);

    // save unicode of binary data into 8-bit Array
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    return new Blob([view], { type: 'application/pdf' });
  }

  private getExtension(char: string): string {
    switch (char) {
      case '/':
        return 'jpg';
      case 'i':
        return 'png';
      case 'R':
        return 'gif';
      case 'U':
        return 'webp';
    }
    return 'jpg';
  }
}
