import { Component, Input, OnInit } from '@angular/core';

import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss'],
})
export class ErrorBannerComponent implements OnInit {
  @Input() bannerText: string;

  errorIcon = AlertsIcons.ExclamationTriangle;
  showBanner = true;

  constructor() {}
  ngOnInit(): void {}
}
