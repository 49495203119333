import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
} from '@angular/core';
import { DropdownItem } from '@uikit/clickdropdown';
import { SubSink } from '@axos/subsink';
import {
  AccountDetail,
  AggregatedAccount,
  PayoffDetailsResponse,
} from '@app/accounts/models';
import { LoanPaymentType } from 'common/enums/loanPaymentType.enum';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { ModalService } from 'services/modal.service';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import {
  AccountsService,
  DialogService,
  LoanService,
  MessageService,
} from '@core/services';
import { Store } from '@ngrx/store';
import { CustomerLimitsHelper } from 'services/customer-limits.helper';
import { getMakePaymentDisclaimer } from 'state-store/constants/disclaimers';
import { Frequency } from '@app/axos-invest/enums';
import {
  mapFrequency,
  mapSendUntil,
  SendUntilOption,
} from 'transfers/transfers.controller';
import { ExternalAccountScheduleRequest } from 'transfers/typings/ExternalAccountScheduleRequest';
import { LoanPaymentData } from 'transfers/typings/LoanPaymentData';
import { getSettings } from '@app/store/settings/settings.selectors';
import { finalize, take } from 'rxjs/operators';
import { AppSettings } from '@core/enums';
import { TransferSchedule } from 'transfers/typings/TransferSchedule';
import { olbSettings, ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import * as moment from 'moment';
import { IFilterService } from 'angular';
import { DATERANGEPICKEROPTIONS } from '@app/bill-pay/ajs-upgraded-provider';
import { ServiceHelper } from '@legacy/services/service.helper';
import { LoanImpedimentType } from '@app/accounts/enums';
import {
  DatePickerEvents,
  DatePickerLocaleOptions,
  DialogData,
  dialogConfig,
} from '@shared/models';
import { AlertsIcons } from '@shared/enums';
import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';
import { LEGACY_FILTER } from '@app/transfers/ajs-upgraded-providers';
import { TransactionService } from '@legacy/services/transaction.service';
import { DatePipe, formatDate } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loan-payment',
  templateUrl: './loan-payment.component.html',
  styleUrls: ['./loan-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanPaymentComponent implements OnInit {
  get totalAmount(): number {
    return this.paymentAmount + +this.additionalPrincipal;
  }
  selectedFromAccount: NewOlbAccount;
  selectedToAccount: DropdownItem;
  fromAccountId: number;
  isExpanded = false;
  toAccountId: number;
  fromAccounts: NewOlbAccount[] = [];
  toAccounts: NewOlbAccount[] = [];
  dropdownAccounts: DropdownItem[] = [];
  accountDetails: AccountDetail;
  loanAccount: NewOlbAccount;

  ShowBalance: boolean;
  amountDue = 0;
  paymentAmount = 0;
  payAdditionalPrincipal = '0.00';
  LabelPay: string;
  additionalPrincipal: number;
  submitted: boolean;
  isPayoff: boolean;
  isSubmitting = false;
  date: Date;
  endDate: Date;

  startDatePickerOptions: any;
  startDatePicker: any;

  endDatePickerOptions: any;
  endDatePicker: any;
  amountDueTooltip: string;
  totalAmountLabel: string;
  icon: string;
  frequencies: GenericOption[];
  filteredFrequencies: GenericOption[];
  selectedFrequency: GenericOption;
  sendUntilOptions: GenericOption[];
  selectedSendUntil: GenericOption;
  numberTransfers: number;
  isRecurrent: boolean;
  showNumberOfTransfers: boolean;
  loanPaymentType: LoanPaymentType;
  loadReviewSubmit: boolean = false;
  isExternal: boolean;
  isBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isValid: boolean;
  isNumberOfTransfersValid: boolean;
  isTodayValidForExternal: boolean;
  isEndDateValid: boolean;
  detailsLoaded: boolean;
  amountValid: boolean;
  amountError: string;
  disclaimerMakePayment: string[];
  modalText: string;
  disableAdditionalPrincipal: boolean;
  showExpirationDate: boolean;
  isSendUntilValid: boolean;
  isReadOnly: boolean;
  useAmountDue: boolean;
  tooltip = null;
  payoffCalculated: PayoffDetailsResponse;
  isBusinessDays = false;
  appSettings: Record<string, string>;
  minDate = moment();
  maxDate = moment().add(10, 'd');
  payoffDays: number;
  isLoading = false;
  showPayoffDemandButton = false;
  priorityImpediment: any;
  paymentOptionTooltip: string =
    'This account does not have a regular payment option. The amount entered here is applied towards your principal.';
  private impedimentsLoaded = false;
  private loanPayoffImpediments: any;
  private _brand: string;
  private _axosLoanUrl =
    'https://www.axosbank.com/Customer-Support/Personal-Support/Manage-My-Loan';
  private _customerLimits: CustomerLimits;
  _isLoanFeatureEnabled: boolean;
  private subSink = new SubSink();
  private oldDate: string;
  linkExternalAccountArray: any[] = [];
  internalAccountsArray: any[] = [];
  externalAccountArray: any[] = [];
  public send = new EventEmitter();

  constructor(
    @Inject(ROOT_SCOPE) private _root: ng.IRootScopeService,
    @Inject('$scope') private _scope: ng.IScope,
    @Inject(STATE) private _state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private _params: ng.ui.IStateParamsService,
    @Inject(LEGACY_FILTER) private _filter: IFilterService,
    private _cachedAccountsService: CachedAccountsService,
    private _modalService: ModalService,
    @Inject(DATERANGEPICKEROPTIONS) private dateRangePickerOptions: any,
    private dateHelper: DateHelper,
    private _accountsService: AccountsService,
    private _featureFlagService: FeatureFlagService,
    private _serviceHelper: ServiceHelper,
    private _customerLimitsHelper: CustomerLimitsHelper,
    @Inject(olbSettings) private _env: OlbSettings,
    private readonly store: Store,
    private loanService: LoanService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private readonly transactionService: TransactionService,
    private messageService: MessageService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.disclaimerMakePayment = getMakePaymentDisclaimer(this._env.brandName);
    this._brand = this._env.brand;
    if (this._root['loanFlagAvailable']) {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
    }
    this._scope.$on('loanFlagAvailable', () => {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
    });
    this.date = moment().toDate();
    this.endDate = moment().add(1, 'days').toDate();
    this.setDatePicker();
    this.setEndDatePicker();
    this.loadFrequencies();
    this.loadSendUntilOptions();
    this.initializeFields();
    this.checkCustomerLimits();

    if (this._root['balancesAvailable']) {
      this.reloadData();
    }

    this._root.$on('balancesAvailable', () => {
      this.reloadData();
    });

    this._scope.$on('detailsLoaded', () => {
      this.detailsLoaded = true;
      this.setCalendarOptions(
        this.isPayoff ? moment(this.payoffCalculated.effectiveDate) : moment()
      );
      this.setTotalAmountLabel();
      if (
        !this._isLoanFeatureEnabled &&
        this.isExternal &&
        this.dueGraceDateWillExpire(this.loanAccount.maxGraceDate)
      ) {
        this.paymentDueModal();
      }
      this.cd.detectChanges();
    });

    let sbloc = this.selectedToAccount?.text;
    this.LabelPay = 'Pay Outstanding Balance';
    if (sbloc?.includes('SBLOC')) {
      this.ShowBalance = true;
    } else {
      this.ShowBalance = false;
    }
  }

  reloadData() {
    this.loadFromAccounts();
    this.loadToAccounts();
    this.setAccountSelection();
    this.getPayoffData();
    this.getImpediments();
  }

  setAdditionalPrincipal(principalAmount) {
    this.additionalPrincipal = principalAmount;
    this.payAdditionalPrincipal = principalAmount;
  }

  resetAdditionalPrincipalToDefault() {
    if (this.payAdditionalPrincipal === '0.00') {
      this.payAdditionalPrincipal = null;
    } else if (
      this.payAdditionalPrincipal === '' ||
      this.payAdditionalPrincipal == null
    ) {
      this.payAdditionalPrincipal = '0.00';
    }
  }

  setDatePicker() {
    this.startDatePickerOptions = angular.extend(
      angular.copy(this.dateRangePickerOptions),
      {
        minDate: moment(),
        maxDate: this.getMaxDate(),
        locale: {
          format: 'MM/DD/YY',
        } as DatePickerLocaleOptions,
        singleDatePicker: true,
        eventHandlers: {
          'hide.daterangepicker': (_evt: any) => {
            this.isLoading = this.isPayoff ? true : false;
            this.date = moment(_evt.model).toDate();
            this.calculatePayoff();
            this.oldDate = this.date.toLocaleDateString();
            this.isTodayValidForExternal = true;
            this.validateEndDate();
          },
        },
        isInvalidDate: (date: any) => {
          if (!this.isBusinessDays && this.isPayoff) {
            return null;
          } else {
            return !this.dateHelper.isValidDate(date);
          }
        },
      }
    );

    this.startDatePicker = {
      startDate: moment(),
      endDate: null,
    };
  }

  setEndDatePicker() {
    this.endDatePickerOptions = angular.extend(
      angular.copy(this.dateRangePickerOptions),
      {
        minDate: moment().add(1, 'days').toDate(),
        maxDate: this.getMaxDate(),
        singleDatePicker: true,
        eventHandlers: {
          'hide.daterangepicker': (_evt: any) => {
            this.isLoading = this.isPayoff ? true : false;
            this.endDate = moment(_evt.model).utc().toDate();
            this.validateEndDate();
          },
        },
        isInvalidDate: (date: any) => {
          if (!this.isBusinessDays && this.isPayoff) {
            return null;
          } else {
            return !this.dateHelper.isValidDate(date);
          }
        },
      }
    );

    this.endDatePicker = {
      startDate: moment().add(1, 'days'),
      endDate: null,
    };
  }

  calculatePayoff(): void {
    const oldDate = this.isPayoff ? this.oldDate : this.date.toString();
    const currentDate = this.startDatePicker.startDate._d.toLocaleDateString();
    if (oldDate !== currentDate) {
      const accountNumber = this.accountDetails.accountNumber;
      this.calculatePayoffServiceCall(accountNumber, currentDate);
    } else {
      this.setTotalAmountLabel();
    }
  }

  onChangeDate(date, event: DatePickerEvents) {
    date.control.markAsDirty();
    date.control.setValue(event.picker.startDate.toDate());
    this.startDatePicker.startDate = event.picker.startDate;
    this.date = this.startDatePicker.startDate;

    this.setTotalAmountLabel();
    this.validateEndDate();
  }

  onChangeEndDate(date, event: DatePickerEvents) {
    date.control.markAsDirty();
    date.control.setValue(event.picker.startDate.toDate());
    this.endDatePicker.startDate = event.picker.startDate;
    this.endDate = this.endDatePicker.startDate;

    this.validateEndDate();
  }

  areAmountPanelsVisible(): boolean {
    if (this.accountDetails && this.accountDetails.accountPayment) {
      return this.amountDue != this.accountDetails.accountPayment.amount;
    }

    return false;
  }

  setAmount(loanPaymentType: string): void {
    this.paymentAmount = this.amountDue;
    this.changeLoanPaymentType(LoanPaymentType[loanPaymentType]);
    this.filterFrequencies();
    this.frequencyChange();
    if (this.accountDetails && this.accountDetails.accountPayment) {
      this.paymentAmount = this.getPaymentAmount();
    }

    // Disable additional principal when paying Regular amount
    this.disableAdditionalPrincipal =
      this.loanPaymentType !== LoanPaymentType.AmountDue &&
      this.amountDue > this.accountDetails.accountPayment.amount;
    if (this.disableAdditionalPrincipal) this.additionalPrincipal = 0;
    this.setValidationAmount();

    if (this.useAmountDue) {
      this.selectedFrequency = this.frequencies[0];
      this.frequencyChange();
    }
  }

  /**
   * Fetch customer limits
   */
  checkCustomerLimits() {
    this._customerLimitsHelper
      .fetchCustomerLimits()
      .then(res => {
        this._customerLimits = res;
      })
      .catch(this._serviceHelper.errorHandler);
  }

  //#endregion
  daysOff = (minDate: Date, maxDate: Date): number => {
    const totalDays = moment(maxDate).diff(moment(minDate), 'days');
    let daysToAdd = 0;

    for (let i = 0; i <= totalDays; i++) {
      const d = moment(minDate).add(i + 1, 'days');
      if (!this.dateHelper.isValidDate(d)) daysToAdd += 1;
    }

    return daysToAdd;
  };

  showExternalModal() {
    this.isSubmitting = true;
    if (this.isExternal) {
      this._modalService
        .show(
          { size: 'md' },
          {
            hasCancelButton: true,
            hasIcon: true,
            hasRedirectLink: false,
            icon: 'bofi-warning',
            cancelText: 'No',
            okText: 'Yes',
            okButtonClass: 'uk-btn solid secondary sm',
            bodyText: `
                            <div>
                            <h1>Are you sure you would like to continue?</h1>
                            </br>
                            You are making this payment with an external account. It may not have full amount of cash available
                            </div>
                         `,
          }
        )
        .then(() => {
          this.validatePayoffTransfers();
        });
    } else {
      this.validatePayoffTransfers();
    }
  }

  validatePayoff() {
    this.submitted = true;
    if (!this.isExternal) this.validatePayoffAmount();
    if (this.amountValid) {
      const loanPayment: LoanPaymentData = {
        isLoanFeatureEnabled: this._isLoanFeatureEnabled,
        isExternal: this.isExternal,
        accountIcon: this.icon,
        accountNickname: this.loanAccount.nickname,
        additionalPrincipal: this.additionalPrincipal,
        amount: this.payoffCalculated.currentBalance,
        sendUntil: this.selectedSendUntil ? this.selectedSendUntil.value : 2,
        totalAmount: this.payoffCalculated.payoffAmount,
        isPayoff: this.isPayoff,
        loanPaymentType: this.loanPaymentType,
      };
      if (this.isExternal && !this._isLoanFeatureEnabled) {
        loanPayment.externalTransaction = this.buildExternalTransfer();
      } else loanPayment.transaction = this.buildInternalTransfer();

      this.loadReviewSubmit = true;
      this._state.go('udb.transfers.loanPayment.review', {
        loanPaymentData: loanPayment,
        isPayoff: this.isPayoff,
        payoffCalculated: this.payoffCalculated,
      });
      this._serviceHelper.scrollTo('udb-transfer');
    }
    this.isSubmitting = false;
  }

  validatePayoffAmount() {
    this.amountValid = true;
    this.amountError = null;

    if (!this.submitted) return; // do not validate until first submission
    const today = this.dateHelper.normalizeDate(moment());
    if (
      this.payoffCalculated.payoffAmount >
        this.selectedFromAccount.availableBalance &&
      today.isSame(this.date, 'date')
    ) {
      this.amountError = 'Insufficient funds available';
      this.amountValid = false;
    }
  }

  redirectState(): void {
    this._scope.$emit('changeStateFromTransfers');
  }

  //#endregion

  //#region Events and public methods

  frequencyChange(): void {
    this.isRecurrent = this.selectedFrequency?.value === Frequency.MONTHLY;
    if (!this.isRecurrent) {
      this.showNumberOfTransfers = false;
      this.showExpirationDate = false;
      this.selectedSendUntil = null;
      this.numberTransfers = null;
      this.isSendUntilValid = true;
    }
    this.setTotalAmountLabel();
  }

  sendUntilChange(): void {
    this.showNumberOfTransfers =
      this.isRecurrent &&
      this.selectedSendUntil.value === SendUntilOption.NumberOfTransfers;
    this.showExpirationDate =
      this.isRecurrent &&
      this.selectedSendUntil.value === SendUntilOption.LastTransferDate;
    this.numberTransfers = null;
    this.isSendUntilValid = !this.isRecurrent || this.selectedSendUntil != null;
    if (this.showExpirationDate) {
      this.setEndDate();
    }
  }

  showCalendar(element: string): void {
    const dp = $('#' + element);
    if (!dp.data('daterangepicker').isShowing)
      dp.data('daterangepicker').show();
    else dp.data('daterangepicker').hide();
  }

  fromAccountChange(fromAcct?, isFromInput?: boolean): void {
    if (this.selectedFromAccount) {
      sessionStorage.setItem(
        'payoffFromAccountId',
        this.selectedFromAccount.id.toString()
      );
      if (isFromInput) {
        this.selectedFromAccount = fromAcct.control.value;
      }

      if (this.selectedFromAccount.id === -1) {
        this.goToAddExternalAccounts();
      }

      this.isExternal = this.selectedFromAccount.isExternal;
      // If the payment due date falls in the 4 days window for external transfers, then show a modal
      if (
        this.detailsLoaded &&
        !this._isLoanFeatureEnabled &&
        this.isExternal &&
        this.dueGraceDateWillExpire(this.loanAccount.maxGraceDate)
      ) {
        this.paymentDueModal();
      }

      // Check for cut off
      if (this.isPayoff) {
        this.submitted = false;
        this.amountError = null;
      } else {
        this.setCalendarOptions(moment());
      }

      this.setTotalAmountLabel();
      this.setValidationAmount();
    }
  }

  toAccountChange(data: DropdownItem): void {
    if (data) {
      this.isBusy.next(true);
      this.selectedToAccount = data;
      this.loanAccount = this.toAccounts.find(i => i.id === data.id);
      this._params['toAccountId'] = data.id;
      sessionStorage.setItem('payoffAccountId', data.id.toString());
      sessionStorage.setItem('payoffAccount', this.loanAccount.accountNumber);
      this.setIcon();
      this.initializeFields();
      this._accountsService
        .getAccountDetails(Number(this.loanAccount.id))
        .pipe(
          finalize(() => {
            this.isBusy.next(false);
            if (this.isPayoff) {
              this.avoidSelfPayment();
              this.calculatePayoffServiceCall(
                this.loanAccount.accountNumber,
                this.oldDate
              );
            } else {
              this.detailsLoadedEmitter();
            }
          })
        )
        .subscribe({
          next: res => {
            this.accountDetails = res.data;
            this.amountDue = this.accountDetails.totalAmountDue;
            this.setAmount(LoanPaymentType.AmountDue);
            this.setTooltip();
            this.setValidationAmount();
          },
          error: this._serviceHelper.errorHandler.bind(this._serviceHelper),
        });
    }
  }

  detailsLoadedEmitter(): void {
    this._scope.$broadcast('detailsLoaded');
    this.isBusy.next(false);
  }

  validate(): void {
    this.submitted = true;
    this.setValidationAmount();
    if (this.isErrorOnScreen()) return;

    this.isValid =
      this.latePaymentCheck() &&
      this.matureNotPaid() &&
      this.noPayoff() &&
      this.additionalPrincipalForExternalAccount();
    if (!this.isValid) {
      this._modalService.show(
        { size: 'sm' },
        {
          bodyText: this.modalText,
          hasCancelButton: false,
          hasIcon: true,
          hasRedirectLink: false,
          icon: 'bofi-warning',
          okText: 'Close',
          okButtonClass: 'uk-btn outline primary sm',
        }
      );
    } else {
      if (this.isExternal) {
        this._modalService
          .show(
            { size: 'sm' },
            {
              bodyText:
                'You are making this payment with an external account. It may not have the full amount of cash available.',
              hasCancelButton: true,
              hasIcon: true,
              hasRedirectLink: false,
              icon: 'bofi-warning',
              okText: 'Yes',
              cancelText: 'No',
              okButtonClass: 'uk-btn solid secondary sm',
            }
          )
          .then(() => this.goToReview());
      } else {
        this.goToReview();
      }
    }
  }

  showForm(): boolean {
    return !this.isBusy.getValue() && this.impedimentsLoaded;
  }
  //#region Loading and private methods

  private avoidSelfPayment(): void {
    const toAccountId = this.getToAccountId();
    if (toAccountId) {
      const temporalFromAccountsArray = this.fromAccounts.filter(
        account => account.id !== toAccountId
      );
      this.splitFromAccountsArrayForUI(temporalFromAccountsArray);
      this.cd.detectChanges();
    }
  }

  private calculatePayoffServiceCall(accountNumber, processDate): void {
    this.isBusy.next(true);
    this.subSink.sink = this._accountsService
      .calculatePayoff(accountNumber, processDate)
      .pipe(
        finalize(() => {
          this.detailsLoadedEmitter();
        })
      )
      .subscribe(response => {
        this.payoffCalculated = response.data;
        this.setTotalAmountLabel();
        this.tooltip = `<div>
        <b>Outstanding Balance</b>
      </div>
      <div>${this._filter('currency')(
        this.payoffCalculated.currentBalance
      )}</div>
      <div>
        <b>Accrued Interest</b>
      </div>
      <div>${this._filter('currency')(
        this.payoffCalculated.accruedInterest
      )}</div>
      <div>
        <b>Secondary Accrued Interest</b>
      </div>
      <div>${this._filter('currency')(
        this.payoffCalculated.secondaryAccruedInterest
      )}</div>
      <div>
        <b>Late Charge Due Amount</b>
      </div>
      <div>${this._filter('currency')(
        this.payoffCalculated.lateChargeDueAmount
      )}</div>
    `;
        this.setPayoff();
      });
  }

  private setAccountSelection() {
    // Look for the parameter or assign the first item in the list
    this.toAccountId = this.getToAccountId();
    this.selectedToAccount = this.dropdownAccounts.find(
      i => i.id == this.toAccountId
    );
    this.toAccountChange(this.selectedToAccount);

    // Look for the parameter or assign the first item in the list
    this.fromAccountId = this.getFromAccountId();
    this.selectedFromAccount = this.fromAccounts.find(
      i => i.id == this.fromAccountId
    );
    this.fromAccountChange();
  }

  private getToAccountId(): number {
    var sessionAccountId = null;
    if (this.getPayoffValue(sessionStorage.getItem('isPayoff'))) {
      sessionAccountId = parseInt(sessionStorage.getItem('payoffAccountId'));
    }
    return (
      this._params['toAccountId'] ||
      sessionAccountId ||
      this.dropdownAccounts[0].id
    );
  }

  private getFromAccountId(): number {
    var sessionFromAccountId = null;
    if (this.getPayoffValue(sessionStorage.getItem('isPayoff'))) {
      sessionFromAccountId = parseInt(
        sessionStorage.getItem('payoffFromAccountId')
      );
    }
    return (
      this._params['fromAccountId'] ||
      sessionFromAccountId ||
      this.fromAccounts[0].id
    );
  }

  private initializeFields() {
    this.selectedFrequency = this.filteredFrequencies[0];
    this.frequencyChange();
    this.numberTransfers = null;
    this.additionalPrincipal = 0;
    this.isNumberOfTransfersValid = true;
    this.isValid = false;
    this.amountValid = true;
    this.amountError = null;
    this.isSendUntilValid = true;
  }
  /**
   * Sets the icon according to the loan type
   */
  private setIcon(): void {
    switch (this.loanAccount.productTypeValue) {
      case 1: // Auto loan
        this.icon = `${this._brand}/car`;
        break;
      case 4: // HELOC
      case 8: // Mortgage
        this.icon = `${this._brand}/home`;
        break;
      default:
        this.icon = `${this._brand}/general`;
        break;
    }
  }

  private loadFrequencies(): void {
    this.frequencies = [
      {
        value: Frequency.ONE_TIME,
        label: mapFrequency(Frequency.ONE_TIME),
        subvalue: '',
        displayWhen: 'Due,Regular',
        cssClass: '',
        default: false,
      },
      {
        value: Frequency.MONTHLY,
        label: mapFrequency(Frequency.MONTHLY),
        subvalue: '',
        displayWhen: 'Due,Regular',
        cssClass: '',
        default: false,
      },
    ];
    this.filteredFrequencies = this.frequencies;
  }

  private filterFrequencies(): void {
    if (!this.frequencies) return;
    this.filteredFrequencies = this.frequencies.filter(col =>
      col.displayWhen.includes('Regular')
    );
    this.selectedFrequency = this.filteredFrequencies[0];
  }

  private changeLoanPaymentType(loanPaymentType: LoanPaymentType): void {
    this.loanPaymentType = loanPaymentType;
  }

  private loadSendUntilOptions(): void {
    this.sendUntilOptions = [
      {
        value: SendUntilOption.IChooseToStop,
        label: mapSendUntil(SendUntilOption.IChooseToStop),
        subvalue: '',
        displayWhen: '',
        cssClass: '',
        default: false,
      },
      {
        value: SendUntilOption.NumberOfTransfers,
        label: mapSendUntil(SendUntilOption.NumberOfTransfers),
        subvalue: '',
        displayWhen: '',
        cssClass: '',
        default: false,
      },
      {
        value: SendUntilOption.LastTransferDate,
        label: 'Expiration Date',
        subvalue: '',
        displayWhen: '',
        cssClass: '',
        default: false,
      },
    ];
  }

  private loadFromAccounts(): void {
    const {
      internalAccounts,
      externalAccounts,
    } = this._cachedAccountsService.getFromAccounts(true);
    const aggregatedAccounts = this._cachedAccountsService.aggregatedAccounts;
    const internalAccountsArray: any[] = internalAccounts;
    const externalAccountsArray: any[] = externalAccounts;

    this.fromAccounts = [];
    if (internalAccounts !== null) {
      internalAccountsArray
        .filter(account => account.canPayLoanFrom)
        .forEach(value => {
          this.fromAccounts.push({
            accountType: value.accountType,
            accountTypeCode: value.accountTypeCode,
            availableBalance: value.availableBalance,
            availableBalanceDisplay:
              '(Avail. Bal: ' +
              this._filter('currency')(value.availableBalance) +
              ')',
            id: value.id,
            nickname: value.nickname,
            accountNumber: value.accountNumber,
            productType: value.productType,
            Type: 'Internal Accounts',
            isExternal: false,
            isDeposit: value.isDeposit,
            isLoan: value.isLoan,
            isIra: value.isIra,
            principalAndInterest: value.principalAndInterest,
          });
        });
    }

    if (externalAccountsArray !== null) {
      externalAccountsArray.forEach(value => {
        const aggregatedAccnt = aggregatedAccounts.find(
          (x: AggregatedAccount) => x.accountNumber == value.accountNumber
        );
        const isAggregatedAccnt = !!aggregatedAccnt;
        this.fromAccounts.push({
          bankName: value.bankName,
          accountType: value.accountCategory,
          accountTypeCode: value.accountCategory,
          availableBalance: isAggregatedAccnt
            ? aggregatedAccnt.availableBalance
            : null,
          id: value.externalAccountId,
          nickname: value.displayName,
          accountNumber: value.accountMask,
          productType: value.accountCategory,
          Type: 'External Accounts',
          isExternal: true,
          isDeposit: true,
          displayName: value.displayName,
        });
      });
    }

    if (this._featureFlagService.isSBBActive()) {
      // Including option to add an External Account
      this.fromAccounts.push({
        displayName: 'Add External Account',
        id: -1,
        Type: 'Link External Account',
      });
    }

    this.splitFromAccountsArrayForUI(this.fromAccounts);
  }

  private splitFromAccountsArrayForUI(fromAccountsArray) {
    this.internalAccountsArray = fromAccountsArray.filter(
      acc => acc.Type === 'Internal Accounts'
    );
    this.linkExternalAccountArray = fromAccountsArray.filter(
      acc => acc.Type === 'Link External Account'
    );
    this.externalAccountArray = fromAccountsArray.filter(
      acc => acc.Type === 'External Accounts'
    );
  }

  private loadToAccounts(): void {
    const internalAccounts = this._cachedAccountsService.getLoanPaymentAccounts();
    const internalAccountsArray: any[] = internalAccounts;
    this.toAccounts = [];
    this.dropdownAccounts = [];

    if (internalAccountsArray !== null) {
      internalAccountsArray.forEach(value => {
        this.toAccounts.push({
          accountType: value.accountType,
          accountTypeCode: value.accountTypeCode,
          availableBalance: value.availableBalance,
          availableBalanceDisplay: `
                                        (Avail. Bal: ${this._filter('currency')(
                                          value.availableBalance
                                        )})`,
          id: value.id,
          nickname: value.nickname,
          accountNumber: value.accountNumber,
          productType: value.productType,
          productTypeValue: value.productTypeValue,
          Type: 'Internal Accounts',
          isExternal: false,
          isDeposit: value.isDeposit,
          isLoan: value.isLoan,
          loanDueDate: value.loanDueDate,
          outstandingBalance: value.outstandingBalance,
          paymentAmount: value.paymentAmount,
          loanDueAmount: value.loanDueAmount,
          minimumAmountDue: value.minimumAmountDue,
          maxGraceDate: value.maxGraceDate,
          principalAndInterest: value.principalAndInterest,
          totalAmountDue: value.totalAmountDue,
          hasEscrow: value.hasEscrow,
          statusCode: value.statusCode,
        });
        this.dropdownAccounts.push({
          text: value.nickname,
          id: value.id,
        });
      });
    }
  }

  private setTooltip(): void {
    this.amountDueTooltip = '';
    if (this.accountDetails.billedPrincipal) {
      this.amountDueTooltip += `<div class="form-group align-left">
                                <label class="uk-label">Billed Principal</label>
                                <p class='amount-label'>${this._filter(
                                  'currency'
                                )(this.accountDetails.billedPrincipal)}</p>
                            </div>`;
    }
    if (this.accountDetails.billedInterest) {
      this.amountDueTooltip += `<div class="form-group align-left">
                                <label class="uk-label">Billed Interest</label>
                                <p class='amount-label'>${this._filter(
                                  'currency'
                                )(this.accountDetails.billedInterest)}</p>
                            </div>`;
    }
    if (this.accountDetails.billedEscrow) {
      this.amountDueTooltip += `<div class="form-group align-left">
                                <label class="uk-label">Billed Escrow</label>
                                <p class='amount-label'>${this._filter(
                                  'currency'
                                )(this.accountDetails.billedEscrow)}</p>
                            </div>`;
    }
    if (this.accountDetails.billedLateCharges) {
      this.amountDueTooltip += `<div class="form-group align-left">
                                <label class="uk-label">Billed Late Charges</label>
                                <p class='amount-label'>${this._filter(
                                  'currency'
                                )(this.accountDetails.billedLateCharges)}</p>
                            </div>`;
    }
    if (this.accountDetails.billedOtherCharges) {
      this.amountDueTooltip += `<div class="form-group align-left">
                                <label class="uk-label">Billed Other Charges</label>
                                <p class='amount-label'>${this._filter(
                                  'currency'
                                )(this.accountDetails.billedOtherCharges)}</p>
                            </div>`;
    }
  }

  private paymentDueModal() {
    this._modalService.show(
      { size: 'lg' },
      {
        hasCancelButton: false,
        hasIcon: true,
        hasRedirectLink: false,
        icon: 'bofi-warning',
        okText: 'Close',
        okButtonClass: 'uk-btn outline primary sm',
        bodyText: `
                          <div>
                              Paying from an external account can take as long as 3-4 business days to post to your account which could result in late
                              fees. If your payment is due soon, please use our <a href="${this._axosLoanUrl}" target="_blank">Online Loan Payment Portal</a> to make your payment to avoid late fees.
                          </div>
                       `,
      }
    );
  }

  private goToAddExternalAccounts() {
    this._state.go(
      this._featureFlagService.isYodleeForFundingActive()
        ? 'udb.dashboard.account-aggregation'
        : 'udb.accounts.add-external'
    );
  }

  private buildInternalTransfer(): TransferSchedule {
    const transaction: TransferSchedule = {
      accountIdFrom: this.selectedFromAccount.id,
      accountNicknameFrom: this.selectedFromAccount.nickname,
      accountNumberFrom: this.selectedFromAccount.accountNumber,
      accountTypeFrom: this.selectedFromAccount.accountType,
      accountProductTypeFrom: this.selectedFromAccount.productType,
      accountIdTo: this.loanAccount.id,
      accountNicknameTo: this.loanAccount.nickname,
      accountNumberTo: this.loanAccount.accountNumber,
      accountTypeTo: this.loanAccount.accountType,
      accountProductTypeTo: this.loanAccount.productType,
      amount: this.isPayoff
        ? this.payoffCalculated.payoffAmount
        : this.paymentAmount,
      additionalPrincipal: this.additionalPrincipal,
      beginSendingOn: this.date,
      confirmationEmail: false,
      confirmationTextMessage: false,
      isExternal: this.isExternal,
      dateAddedString: moment().format('DD/MM/YYYY hh:mm:ss'),
      externalAccountId: this.selectedFromAccount.id.toString(),
      frequency: this.selectedFrequency.value,
      numberOfTransfers: 0,
      isPayoff: this.isPayoff,
      lastTransferDate: this.showExpirationDate ? this.endDate : null,
    };
    if (
      this.selectedFrequency.value !== Frequency.ONE_TIME ||
      (this.selectedFrequency.value === Frequency.ONE_TIME &&
        !moment(this.date).isSame(moment(), 'd'))
    ) {
      transaction.sendUntilOptions = this.selectedSendUntil
        ? this.selectedSendUntil.value
        : 2;
      transaction.numberOfTransfers = this.numberTransfers || 0;
      transaction.amountOption = 0;
    }

    return transaction;
  }

  private buildExternalTransfer(): ExternalAccountScheduleRequest {
    const transaction: ExternalAccountScheduleRequest = {
      bankName: this.selectedFromAccount.bankName,
      externalAccountId: this.selectedFromAccount.id,
      internalAccountId: this.loanAccount.id,
      frequency: this.selectedFrequency.value,
      amount: this.isPayoff
        ? this.payoffCalculated.payoffAmount
        : this.totalAmount,
      transferType: 0,
      accountCode: '',
      additionalPrincipal: 0,
      numberOfTransfers: this.numberTransfers || 0,
      processingDate: this.date,
      externalAccountNickName: this.selectedFromAccount.nickname,
      accountNumberFrom: this.selectedFromAccount.accountNumber,
      accountNumberTo: this.loanAccount.accountNumber,
      isPayoff: this.isPayoff,
      lastTransferDate: this.showExpirationDate ? this.endDate : null,
    };
    if (
      this.selectedFrequency.value !== Frequency.ONE_TIME ||
      (this.selectedFrequency.value === Frequency.ONE_TIME &&
        !moment(this.date).isSame(moment(), 'd'))
    ) {
      transaction.sendUntilOptions = this.selectedSendUntil
        ? this.selectedSendUntil.value
        : 2;
    }

    return transaction;
  }

  private goToReview(): void {
    const loanPayment: LoanPaymentData = {
      isLoanFeatureEnabled: this._isLoanFeatureEnabled,
      isExternal: this.isExternal,
      accountIcon: this.icon,
      accountNickname: this.loanAccount.nickname,
      additionalPrincipal: this.additionalPrincipal,
      amount: this.paymentAmount,
      sendUntil: this.selectedSendUntil ? this.selectedSendUntil.value : 2,
      totalAmount: this.totalAmount,
      loanPaymentType: this.loanPaymentType,
    };
    if (this.isExternal && !this._isLoanFeatureEnabled) {
      loanPayment.externalTransaction = this.buildExternalTransfer();
    } else loanPayment.transaction = this.buildInternalTransfer();
    this.loadReviewSubmit = true;
    this._state.go('udb.transfers.loanPayment.review', {
      loanPaymentData: loanPayment,
    });
    this._serviceHelper.scrollTo('udb-transfer');
  }
  //#endregion

  //#region Validations
  private latePaymentCheck(): boolean {
    if (
      moment().diff(this.accountDetails.accountPayment.dueDate, 'days') > 89
    ) {
      this.modalText = `Your loan is more than 90 days past due and is not eligible for online payments.
                            <br/><br/>Please call Collection Department at 858-350-6200 ext. 1241.`;

      return false;
    }
    this.modalText = '';

    return true;
  }

  private matureNotPaid(): boolean {
    if (this.loanAccount.statusCode === '3') {
      this.modalText = `Your loan is matured and is not eligible for online payments.
            <br/><br/>Please call Loan Servicing at 866-923-7112.`;

      return false;
    }
    this.modalText = '';

    return true;
  }

  private noPayoff(): boolean {
    // for mortgage
    if (
      this.loanAccount.productTypeValue === 8 &&
      this.loanAccount.outstandingBalance - this.totalAmount < 100
    ) {
      this.modalText = `Because you are attempting to make your last payment, please contact Loan Servicing at 866-923-7112.`;

      return false;
    }
    this.modalText = '';

    return true;
  }

  private additionalPrincipalForExternalAccount(): boolean {
    if (
      !this._isLoanFeatureEnabled &&
      this.isExternal &&
      this.loanPaymentType === LoanPaymentType.AmountDue &&
      this.amountDue == 0
    ) {
      this.modalText = `Principal payments can only be made in addition to your monthly payment when paying from an external account.
            <br/><br/>You can make a principal only payment from an internal account.`;

      return false;
    }
    this.modalText = '';

    return true;
  }

  validateNumberOfTransfers(): boolean {
    if (
      this.showNumberOfTransfers &&
      (!this.numberTransfers || this.numberTransfers <= 0)
    ) {
      this.isNumberOfTransfersValid = false;
    } else {
      this.isNumberOfTransfersValid = true;
    }

    return this.isNumberOfTransfersValid;
  }

  private dueGraceDateWillExpire(graceDate: string) {
    const maxGraceDate = moment(graceDate);

    const currentDate = moment().add(4, 'days');

    return (
      moment(maxGraceDate, 'day').isBefore(currentDate, 'day') ||
      moment(maxGraceDate, 'day').isSame(currentDate, 'day')
    );
  }

  /**
   * Check for violations to amount rules, such as:
   * a. Amount can't be 0
   * b. When paying from external account, can't exceed payveris limits
   * c. From account has to have funds (for same day transfer)
   * d. Total amount can't exceed outstanding balance
   */
  private setValidationAmount(): void {
    this.amountValid = true;
    this.amountError = null;

    if (!this.submitted) return; // do not validate until first submission
    if (isNaN(this.totalAmount)) {
      this.amountValid = false;
    } else if (this.totalAmount <= 0) {
      this.amountError = 'Total payment should be greater than $0.00 dollars';
      this.amountValid = false;
    } else if (this.totalAmount > this.loanAccount.outstandingBalance) {
      this.amountError = 'Payment exceeds your outstanding balance';
      this.amountValid = false;
    } else {
      // Payveris limits
      if (
        !this._isLoanFeatureEnabled &&
        this.isExternal &&
        this._customerLimits.extTransfersMaxIncomingPerPayment &&
        this.totalAmount >
          this._customerLimits.extTransfersMaxIncomingPerPayment
      ) {
        this.amountError =
          'Amount exceeds maximum customer limit per inbound transfer';
        this.amountValid = false;
      }

      if (moment(this.date).isSame(moment(), 'd')) {
        if (this.selectedFromAccount.isDeposit) {
          if (!this.isExternal) {
            if (this.totalAmount > this.selectedFromAccount.availableBalance) {
              this.amountError = 'Insufficient funds available';
              this.amountValid = false;
            }
          }
        }

        if (this.totalAmount > this.loanAccount.outstandingBalance) {
          if (!this.isExternal) {
            this.amountError = 'Payment exceeds your outstanding balance';
            this.amountValid = false;
          }
        }
      }
    }
  }

  /** Changes the calendar options depending on the from account
   * and the loan feature flag
   */
  private setCalendarOptions(inputDate: moment.Moment): void {
    this.isTodayValidForExternal = true;
    let today = this.dateHelper.normalizeDate(moment());
    let nextDay = this.dateHelper.normalizeDate(moment().add(1, 'days'));
    let nextInputDate = inputDate.clone().add(1, 'months');
    if (this.isExternal && !this._isLoanFeatureEnabled) {
      const externalTransferValidDate = this.dateHelper.getDefaultPaymentDate();
      if (externalTransferValidDate.diff(today, 'days') > 0) {
        today = externalTransferValidDate;
        this.isTodayValidForExternal = false;
      }
    }
    today = this.validateDate(today);
    nextDay = this.validateDate(nextDay);
    inputDate = this.validateDate(inputDate);
    nextInputDate = this.validateDate(nextInputDate);

    this.date = inputDate.toDate();
    this.endDate = nextInputDate.toDate();
    this.startDatePicker.startDate = inputDate;
    this.endDatePicker.startDate = nextInputDate;
    this.startDatePickerOptions.minDate = today;
    this.endDatePickerOptions.minDate = nextDay;
    // Allow selection 13 months in advance
    if (this.isPayoff) {
      this.startDatePickerOptions.maxDate = angular
        .copy(today)
        .add(this.payoffDays, 'd');
      this.endDatePickerOptions.maxDate = angular
        .copy(today)
        .add(this.payoffDays, 'd');
    } else {
      this.startDatePickerOptions.maxDate = angular.copy(today).add(13, 'M');
      this.endDatePickerOptions.maxDate = angular.copy(today).add(13, 'M');
    }
    // Update the input field value
    const dateString = inputDate.format('MM/DD/YY');
    const nextDateString = nextInputDate.format('MM/DD/YY');
    if (this.detailsLoaded && !this.isPayoff) {
      $('#date').val(dateString);
      $('#date').data('daterangepicker')?.setStartDate(today);
      $('#date').data('daterangepicker')?.setEndDate(today);
      $('#endDate').val(nextDateString);
    }
    this.isEndDateValid = true;
  }

  /* Setting info if it is payoff screen */
  private getPayoffData() {
    if (this._params.isPayoff) {
      this.isPayoff = this._params.isPayoff;
      this.tooltip = this._params.tooltip;
      this.payoffCalculated = this._params.payoffCalculated;
      sessionStorage.setItem('isPayoff', this._params.isPayoff);
      sessionStorage.setItem('payoffAccount', this.loanAccount.accountNumber);
      sessionStorage.setItem('payoffAccountId', this.loanAccount.id.toString());
      sessionStorage.setItem(
        'payoffFromAccountId',
        this.fromAccountId.toString()
      );
      sessionStorage.setItem(
        'payoffEffectiveDate',
        moment(this.payoffCalculated.effectiveDate).format('MM/DD/YYYY')
      );
      this.setPayoff();
    } else {
      this.isPayoff = this.getPayoffValue(sessionStorage.getItem('isPayoff'));

      if (this.isPayoff) {
        this.calculatePayoffServiceCall(
          sessionStorage.getItem('payoffAccount'),
          sessionStorage.getItem('payoffEffectiveDate')
        );
      }
    }
  }

  private getPayoffValue(value: string | null): boolean {
    return value === '1' || value === 'true';
  }

  private setPayoff() {
    this.oldDate = moment(this.payoffCalculated.effectiveDate).format(
      'MM/DD/YYYY'
    );
    this.subSink.sink = this.store
      .select(getSettings)
      .pipe(take(1))
      .subscribe(settings => {
        this.appSettings = settings;
        let daysToConsider = 0;
        const appSettingsPayoffDays = this.appSettings[AppSettings.PayoffDays]
          ? Number(this.appSettings[AppSettings.PayoffDays])
          : 10;

        this.maxDate = angular
          .copy(this.minDate)
          .add(appSettingsPayoffDays, 'd');

        this.isBusinessDays = this.appSettings[
          AppSettings.PayoffCalcAllowBusinessDays
        ]
          ? this.appSettings[
              AppSettings.PayoffCalcAllowBusinessDays
            ].toLowerCase() === 'true'
          : false;

        if (this.isBusinessDays) {
          daysToConsider = this.daysOff(
            this.minDate.toDate(),
            this.maxDate.toDate()
          );
        }

        this.payoffDays = appSettingsPayoffDays + daysToConsider;
      });
  }

  private validatePayoffTransfers(): void {
    if (!this.isPayoff) {
      this.validatePayoff();
    }
    if (this.loanPayoffImpediments?.length > 0) {
      const pendingScheduleTransfers = this.loanPayoffImpediments?.filter(
        impediment =>
          impediment.impedimentType === LoanImpedimentType.ScheduledTransfer
      );
      if (pendingScheduleTransfers && pendingScheduleTransfers?.length > 0) {
        this.showScheduleTransferAlert(pendingScheduleTransfers);
      } else {
        this.validatePayoff();
      }
    } else {
      this.validatePayoff();
    }
  }

  private showScheduleTransferAlert(pendingScheduleTransfers: any): void {
    const dialogData = new DialogData({
      title: '',
      okText: 'Continue',
      icon: AlertsIcons.ExclamationCircle,
      content: `
          <p>You currently have a scheduled payment for<br/>${this.selectedToAccount.text}. By selecting<br/>“Continue” we will cancel that
          payment to<br/>proceed with your loan payoff. Do you wish<br/>to continue?</p>
        `,
    });

    dialogConfig.data = dialogData;
    dialogConfig.disableClose = true;
    dialogConfig.width = '550px';
    this.subSink.sink = this.dialogService
      .openCustom(dialogConfig)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.cancelTransfer();
          this.loanService.logUserAction(
            new UserAction('PayoffLoan', [
              new UserActionField(
                'Impediment',
                '',
                pendingScheduleTransfers.impedimentMessage
              ),
            ])
          );
          this.validatePayoff();
        } else {
          this.isSubmitting = false;
        }
      });
  }

  private cancelTransfer(): void {
    const cancelPromises: Promise<any>[] = [];
    let schedulerTransfers: TransferSchedule[] = new Array<TransferSchedule>();
    this.transactionService.getScheduledTransfers(true, true).then(res => {
      schedulerTransfers = res.data?.filter(
        x =>
          x.pendingNumberOfTransfers != 0 &&
          (x.accountIdFrom == this.toAccountId ||
            x.accountIdTo == this.toAccountId)
      );
      schedulerTransfers.forEach(transfer => {
        const cancelTransactionInstance = new Promise(resolve => {
          if (!transfer.isExternal) {
            //Internal payments
            this.transactionService
              .removeScheduledSeriesTransfer(transfer)
              .then(response => {
                resolve(response);
              });
          } else {
            //External payments (ach)
            this.loanService.removeACHTransferSeries(transfer).subscribe({
              next: response => {
                console.log(response);
              },
              error: this._serviceHelper.errorHandler,
            });
          }
        });
        cancelPromises.push(cancelTransactionInstance);
      });

      if (schedulerTransfers.length > 0) {
        this.sendMessageAboutCanceledPayments(schedulerTransfers);
      }
    });

    Promise.all(cancelPromises).then(() => {
      this.validatePayoff();
    });
  }

  private getImpediments(): void {
    if (this.isPayoff) {
      this.loanService
        .getPaymentImpediments(this.toAccountId)
        .pipe(
          finalize(() => {
            this.impedimentsLoaded = true;
            this.cd.detectChanges();
          })
        )
        .subscribe({
          next: response => {
            this.loanPayoffImpediments = response.data;
          },
          error: this._serviceHelper.errorHandler,
        });
    } else {
      this.impedimentsLoaded = true;
    }
  }

  private setTotalAmountLabel(): void {
    if (this.isRecurrent) {
      this.totalAmountLabel = `Total monthly payment starting on ${this.datePipe.transform(
        this.date,
        'MM/dd/YYYY'
      )}`;
    } else {
      this.totalAmountLabel = `Total one time payment on ${this.datePipe.transform(
        this.date,
        'MM/dd/YYYY'
      )}`;
    }
    // If it's a one time, same day payment during nightly processing, disable the button
    const today = this.dateHelper.normalizeDate(moment());
    if (
      this._featureFlagService.isSiteInReadOnly() &&
      !this.isRecurrent &&
      today.isSame(this.date, 'date')
    ) {
      this.isReadOnly = true;
    } else this.isReadOnly = false;
  }

  private getPaymentAmount() {
    if (this.isAmountDue()) return this.amountDue;
    if (this.isRegularPayment())
      return this.accountDetails.accountPayment.amount;
  }

  isAmountDue() {
    return this.loanPaymentType === LoanPaymentType.AmountDue;
  }

  isRegularPayment() {
    return this.loanPaymentType === LoanPaymentType.RegularPayment;
  }

  private isErrorOnScreen(): boolean {
    this.isSendUntilValid = !this.isRecurrent || this.selectedSendUntil != null;

    return !(
      this.isSendUntilValid &&
      this.validateNumberOfTransfers() &&
      this.amountValid &&
      this.isEndDateValidated()
    );
  }

  private getMaxDate(): moment.Moment {
    return this.isPayoff
      ? moment().add(this.payoffDays, 'd')
      : moment().add(13, 'M');
  }

  private validateDate(date: moment.Moment): moment.Moment {
    if (!this.dateHelper.isValidDate(date)) {
      date = this.dateHelper.addWeekdays(date, 1);
    }

    return date;
  }

  private validateEndDate(): void {
    this.isEndDateValid = moment(this.endDatePicker.startDate).isAfter(
      this.startDatePicker.startDate
    );
    this.isEndDateValid = moment(this.endDatePicker.startDate).isAfter(
      this.startDatePicker.startDate
    );
  }

  private setEndDate(): void {
    let nextInputDate = this.startDatePicker.startDate.clone().add(1, 'months');
    if (moment(nextInputDate).isAfter(this.startDatePickerOptions.maxDate)) {
      nextInputDate = this.startDatePickerOptions.maxDate.clone();
    }
    nextInputDate = this.validateDate(nextInputDate);
    this.endDate = nextInputDate.toDate();
    this.endDatePicker.startDate = nextInputDate;
    this.endDatePickerOptions.startDate = nextInputDate;
    const nextDateString = nextInputDate.format('MM/DD/YY');
    $('#endDate').val(nextDateString);
    this.validateEndDate();
  }

  private isEndDateValidated(): boolean {
    return this.isEndDateValid || !this.showExpirationDate;
  }

  private sendMessageAboutCanceledPayments(
    canceledPayments: TransferSchedule[]
  ): void {
    const messageData: FormData = new FormData();
    messageData.append('category', 'Payments');
    messageData.append('subCategory', 'Canceled Payoff');

    let message: string = '';
    canceledPayments.forEach(payment => {
      message += `<b>PAYMENT CANCEL</b>
      From Account &emsp;&emsp;&emsp;&emsp;${payment.accountNicknameFrom}
      To Account &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;${
        payment.accountNicknameTo
      }
      Frequency &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;${mapFrequency(
        payment.frequency
      )}
      Next Transfer &emsp;&emsp;&emsp;&emsp;&nbsp;${formatDate(
        payment.nextTransferDate,
        'MM/dd/YYYY',
        'en-US'
      )}
      Amount &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;${
        payment.totalAmount
      }<br><br>`;
    });

    messageData.append('BodyMessage', message);

    this.messageService.sendMessage(messageData).subscribe(
      () => {
        this.send.emit();
      },
      exception => {
        this.serviceHelper.errorHandler(exception);
      }
    );
  }
}
