import { Inject } from '../../../decorators/decorators';
import { Milestone } from 'typings/app/wise-banyan/Milestone';
import { AxosInvestUrlHelper } from 'services/axos-invest-urls.service';

@Inject('axosInvestUrlHelper')
export class AxosInvestWalletController {
  walletIcon: any = '../../../tiles/invest-milestone/assets/milestone-wallet.svg';
  balance: number;
  deposit: number;
  frequency: string;
  hasWallet: boolean;

  constructor(private axosInvestUrlHelper: AxosInvestUrlHelper) {}

  $onChanges(changes: any): void {
    if (changes.wallet.currentValue) {
      this.getWalletInfo(changes.wallet.currentValue);
    }
  }

  actionWallet(): void {
    this.axosInvestUrlHelper.getManageWalletUrl().then(url => {
      const win = window.open(url, '_blank');
      win.focus();
    });
  }

  private getWalletInfo(wallet: Milestone) {
    if (wallet) {
      this.hasWallet = true;

      this.balance = wallet.currentValue;
      this.frequency = wallet.autoDeposit.frequency;
      this.deposit = wallet.autoDeposit.amount;
    }
  }
}
