import { UserAction } from '@legacy/typings/app/UserAction';
import { Inject } from '../decorators/decorators';
import { TransferSchedule } from '../transfers/typings/TransferSchedule';
import { BaseService } from './base.service';
import { ILoanService } from './typings/ILoanService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class LoanService extends BaseService implements ILoanService {
  constructor(http: ng.IHttpService, env: OlbSettings, serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'loans', serviceHelper, q);
  }

  isFeatureEnabled(): ApiResponse<boolean> {
    return this.get('isFeatureEnabled');
  }

  getPendingPayments(accounts: number[], limitTo?: number): ApiResponse<IncomingTransaction[]> {
    return this.get('transactions/pending', { accounts, limitTo });
  }

  removeACHTransferSeries(trans: TransferSchedule): ApiResponse<any> {
    return this.delete('ach/schedule/series', trans);
  }

  removeNextACHTransfer(trans: TransferSchedule): ApiResponse<any> {
    return this.delete('ach/schedule/single', trans);
  }

  editACHTransferSeries(trans: TransferSchedule): ApiResponse<any> {
    return this.put('ach/schedule/series', trans);
  }

  editNextACHTransfer(trans: TransferSchedule): ApiResponse<any> {
    return this.put('ach/schedule/single', trans);
  }

  convertExternalSeries(transfer: TransferSchedule, removeNextOcurrence: boolean = false): ApiResponse<any> {
    return this.put(`ach/conversion/series?removeNextOcurrence=${removeNextOcurrence}`, transfer);
  }

  convertNextExternalTransfer(trans: TransferSchedule): ApiResponse<any> {
    return this.put('ach/conversion/single', trans);
  }

  getPaymentImpediments(accountId: number) {
    return this.get(`pay/${accountId}/impediments`);
  }

  logUserAction(action: UserAction): ApiResponse<any> {
    return this.post('audit', action);
  }
}
