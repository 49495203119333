import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { IStateService } from 'angular-ui-router';
import { Recipient } from 'bill-pay/typings/Recipient';
import { IBillPayService } from 'services/typings/IBillPayService';
import * as moment from 'moment';
import { STATE } from '@core/tokens';
import { BILLPAYSERVICE, SERVICEHELPER, billPayServiceProvider } from '@app/bill-pay/ajs-upgraded-provider';

@Component({
  selector: 'app-e-statements-tile',
  templateUrl: './e-statements-tile.component.html',
  styleUrls: ['./e-statements-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [billPayServiceProvider],
})
export class EStatementsTileComponent implements OnInit {
  @Input() recipients: Recipient[];
  isLoading: boolean;
  statements: EBill[];
  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(SERVICEHELPER) private readonly serviceHelper: IServiceHelper,
    @Inject(BILLPAYSERVICE) private readonly billPayService: IBillPayService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this._loadEStatements();
  }

  redirectToPay(recipients: Recipient[], payeeId: number): void {
    const recipient = recipients.find(r => r.payeeId === payeeId);
    this.state.go('udb.billPay.schedulePayment', { recipient: recipient });
  }

  getMonthDescription(date: string): string {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const parsedDate = new Date(date);
    return monthNames[parsedDate.getMonth()];
  }

  markAsPaid(payeeId: number, eBillId: number) {
    this.isLoading = true;
    this.billPayService
      .markEBillAsPaid(payeeId, eBillId)
      .then(() => {
        this._loadEStatements();
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  private _loadEStatements(): void {
    this.statements = [];
    const today = new Date().toISOString().slice(0, 10);
    const next30Days = moment().add(30, 'days').toDate().toISOString().slice(0, 10);
    this.isLoading = true;
    this.billPayService
      .getEBillHistory(today, next30Days)
      .then(response => {
        response.data.forEach(payee => {
          payee.eBills.forEach(ebill => {
            const ebillToArray = {
              amountDue: ebill.amountDue,
              outstandingBalance: ebill.outstandingBalance,
              paymentDueDate: ebill.paymentDueDate,
              paymentDueDateSpecified: ebill.paymentDueDateSpecified,
              payee: ebill.payee,
              eBillId: ebill.eBillId,
              paid: ebill.paid,
              hasStatement: ebill.hasStatement,
              hasStatementSpecified: ebill.hasStatementSpecified,
              nickName: payee.nickName,
            };
            this.statements.push(ebillToArray);
          });
        });
      })
      .catch(() => {
        this.serviceHelper.errorHandler.bind(this.serviceHelper);
      })
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }
}
