<div
  *ngFor="let category of categories"
  class="option"
  [ngClass]="{ category__selected: !excludedCategories.has(category.id) }"
>
  <div class="category__row-item uk2-checkbox-container">
    <app-svg-icon
      class="category__image"
      [iconName]="category.imageFile"
      [width]="'32px'"
      [height]="'22px'"
      [iconColor]="category.fill"
      [ngStyle]="{ 'background-color': category.color }"
    ></app-svg-icon>
    <div class="category-name">{{ category.name }}</div>
    <mat-checkbox
      uk2Checkbox
      disableRipple
      [checked]="!excludedCategories.has(category.id)"
      (change)="selectedCategory(category)"
    ></mat-checkbox>
  </div>
</div>
<div class="cta-footer mt-4">
  <span class="reset" (click)="reset()">Reset</span>
</div>
