<app-dot-loader *ngIf="isInitiating; else enrollmentLite">We are getting ready</app-dot-loader>

<ng-template #enrollmentLite>
  <section [ngSwitch]="state.currentStep" class="form-group enrollment-view enrollment-lite">
    <app-progress-bar
      [steps]="state.viewSteps"
      [currentStep]="state.currentStep"
      class="mb-5"
    ></app-progress-bar>

    <app-create-username
      class="create-username col-12 col-md-8 col-xl-6"
      *ngSwitchCase="1"
      [header]="usernameHeading"
      (login)="goToLogin()"
      (next)="usernameNext($event)"
    ></app-create-username>

    <app-terms-and-conditions
      *ngSwitchCase="2"
      [isLoading]="isLoading"
      (agree)="agreeTermsAndConditions()"
    ></app-terms-and-conditions>

    <app-security-questions
      *ngSwitchCase="2.5"
      [username]="user.username"
      [isFinished]="areQuestionsAnswered"
      (save)="securityQuestionsSave()"
    ></app-security-questions>
  </section>
</ng-template>
