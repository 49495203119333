export enum AxosAdvisoryTransferFrequency {
  OneTime,
  Monthly,
  Quarterly,
  SemiAnnually,
  Annually,
}

export enum FrequencyEnum {
  NOTSET = 0,
  ONETIME = 1,
  WEEKLY = 2,
  EVERYTWOWEEKS = 3,
  MONTHLY = 4,
  QUARTERLY = 5,
  SEMIANNUALLY = 6,
  ANNUALLY = 7,
}
