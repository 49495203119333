import { Inject } from '../../../decorators/decorators';
import { ReceiptSettings } from './typings/ReceiptSettings';
import { ReceiptTemplate } from './typings/ReceiptTemplate';

@Inject('$state', 'env')
export class ReceiptTemplateController {
  hasFailed = false;

  settings: ReceiptSettings;
  template: ReceiptTemplate;

  constructor(private readonly state: ng.ui.IStateService, public readonly env: OlbSettings) {}

  $onInit(): void {
    if (!this.state.params.settings || !this.state.params.template) {
      this.hasFailed = true;

      history.back();
    }

    this.settings = this.state.params.settings;
    this.template = this.state.params.template;
  }
}
