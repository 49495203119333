import { AasAccountState } from '.';

export type PdpState = {
  aasAccounts: AasAccountState[];
  selectedAasAccountNumber: string | undefined;
  selectedTab: string | undefined;
};

export const initialPdpState: PdpState = {
  aasAccounts: [],
  selectedAasAccountNumber: undefined,
  selectedTab: undefined,
};
