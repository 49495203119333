import { PfmIcons } from '@shared/enums/svg-icons/pfm-icons.enum';

export interface Category {
  id: number;
  name?: string;
  imageFile?: string;
  color?: string;
  percentage?: number;
  amount?: number;
  fill?: string;
  fontSize?: string;
}
export const categories: Category[] = [
  {
    id: 1,
    name: 'Purchases',
    color: '#2268C0',
    imageFile: PfmIcons.Category_Purchases,
    fill: 'white',
    fontSize: 'normal',
  },
  {
    id: 2,
    name: 'Food',
    color: '#572F8E',
    imageFile: PfmIcons.Category_Food,
    fill: 'white',
    fontSize: 'normal',
  },
  {
    id: 3,
    name: 'Bills',
    color: '#1F3E6C',
    imageFile: PfmIcons.Category_Bills,
    fill: 'white',
    fontSize: 'normal',
  },
  {
    id: 4,
    name: 'Transportation',
    color: '#9D1949',
    imageFile: PfmIcons.Category_Transportation,
    fill: 'white',
    fontSize: 'small',
  },
  {
    id: 5,
    name: 'Personal Care',
    color: '#00805C',
    imageFile: PfmIcons.Category_Personal_Care,
    fill: 'white',
    fontSize: 'normal',
  },
  {
    id: 6,
    name: 'Travel & Entertainment',
    color: '#16A5BF',
    imageFile: PfmIcons.Category_Travel_Entertainment,
    fill: 'white',
    fontSize: 'small',
  },
  {
    id: 7,
    name: 'Transfers',
    color: '#711F8A',
    imageFile: PfmIcons.Category_Transfers,
    fill: 'white',
    fontSize: 'normal',
  },
  {
    id: 9,
    name: 'Other',
    color: '#0C9C96',
    imageFile: PfmIcons.Category_Other,
    fill: 'white',
    fontSize: 'normal',
  },
];

export const allCategories: Category[] = [
  ...categories,
  {
    id: 8,
    name: 'Deposits',
    color: '#0C9C96',
    imageFile: PfmIcons.Category_Deposits,
    fill: 'white',
    fontSize: 'normal',
  },
];
