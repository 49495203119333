import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal } from '@angular/upgrade/static';

import * as angular from 'angular';

import { bootsrapLegacyApp } from '@legacy/app';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

setAngularJSGlobal(angular);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    const upgrade = (ref.instance as any).upgrade;
    const configService = (ref.instance as any).configService;
    const { appConfig } = configService;
    upgrade.bootstrap(document.body, [bootsrapLegacyApp(appConfig)]);
  })
  .catch(err => console.error(err));
