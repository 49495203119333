import { IComponentOptions } from 'angular';
import { TxFooterMultipleController } from './tx-footer-multiple.controller';

export const TxFooterMultipleComponent: IComponentOptions = {
  controller: TxFooterMultipleController,
  controllerAs: '$tfm',
  templateUrl: 'accounts/transactions/tx-table/tx-footer-multiple/tx-footer-multiple.tpl.html',
  bindings: {
    selected: '<',
    onClearSelected: '&',
    onFileDispute: '&',
  },
};
