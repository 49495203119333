export class AccountSelectItem {
  id: string | number;
  name: string;
  details: string;
  icon: string;

  constructor(args?: Partial<AccountSelectItem>) {
    Object.assign(this, args);
  }
}
