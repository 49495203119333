import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-insights-container',
  templateUrl: './insights-container.component.html',
  styleUrls: ['./insights-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsContainerComponent implements OnInit {
  @Input() data: any[];
  @Input() isOpen: boolean;
  @Output() onClose = new EventEmitter<boolean>();

  isPfm2Active: boolean;

  constructor(private featureFlagService: FeatureFlagService) {}

  ngOnInit() {
    this.isPfm2Active = this.featureFlagService.isPFM2FlagActive();
  }

  closeChart() {
    console.log('Close insights container');
    this.isOpen = false;
    this.onClose.emit(this.isOpen);
  }
}
