export enum UserProfileIcons {
  Volleyball = 'profile-volleyball',
  Football = 'profile-football',
  Basketball = 'profile-basketball',
  Baseball = 'profile-baseball',
  Bowling = 'profile-bowling',
  Soccer = 'profile-soccer',
  Golf = 'profile-golf',
  Dance = 'profile-dance',
  Scuba = 'profile-scuba',
  Bike = 'profile-bike',
  Guitar = 'profile-guitar',
  Island = 'profile-island',
  Beer = 'profile-beer',
  Headphones = 'profile-headphones',
  Videogames = 'profile-videogames',
  SmileyFace = 'profile-smiley-face',
  Windsurf = 'profile-windsurf',
  Dog = 'profile-dog',
  Cat = 'profile-cat',
  Elephant = 'profile-elephant',
  Toucan = 'profile-toucan',
  Horse = 'profile-horse',
  Monkey = 'profile-monkey',
}
