import { DepositType, Frequency, SendUntil, TransactionType } from '@app/axos-invest/enums';

export class ScheduledTransferBase {
  transferSeriesId?: string;
  frequency?: Frequency;
  sendUntil?: SendUntil;
  startSendingDate?: Date;
  lastTransferDate?: Date;
  accountNumber?: string;
  milestoneId?: string;
  milestoneName?: string;
  amount?: number;
  transactionType?: TransactionType;
  depositType?: DepositType;
  contributionYear?: number;
}
