<div class="d-flex flex-column-reverse align-items-center terms-and-conditions">
  <div class="col-11 col-md-8 col-xl-7 loader-container">
    <app-dot-loader [showWhen]="isLoading"></app-dot-loader>
  </div>

  <div *ngIf="!isLoading" class="col-11 col-md-8 col-xl-7 agree-button-container">
    <button
      type="submit"
      class="uk-btn sm solid secondary text-button"
      (click)="acceptTermsAndConditions()"
    >
      {{ buttonText }}
    </button>
  </div>

  <div class="col-11 col-md-8 col-xl-7 text-lore">
    By selecting “{{ buttonText }}”, you consent to adhere to the Terms and Conditions as stated in
    the Axos Online Access Agreement. This Online Access Agreement governs your use of this site for
    your personal requests and transactions; and details the online policies relative to Axos Bank,
    Nationwide, and UFB Direct Online and Axos Invest accounts, products, services and features.
    Please read this Agreement carefully.
  </div>

  <div class="col-11 col-md-10 col-xl-7 pdf-container">
    <app-pdf-renderer [resourceUrl]="fileUrl"></app-pdf-renderer>
  </div>

  <div *ngIf="alertText" class="col-11 col-md-10 col-xl-7">
    <app-alert-notification alertType="error" (dismiss)="dismissAlert()">
      <p class="alert-text" [innerText]="alertText"></p>
    </app-alert-notification>
  </div>

  <div class="col-11 col-md-8 col-xl-7">
    <h2>Please read and accept our Terms &amp; Conditions.</h2>
  </div>
</div>
