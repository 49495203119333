export class TxFooterMultipleController {
  selected: number;
  onFileDispute: Function;

  hasNoSelection: boolean;

  constructor() {}

  /** Handles the action when trying to file a dispute */
  fileDispute() {
    this.hasNoSelection = !this.selected;
    if (this.hasNoSelection) {
      return;
    }

    this.onFileDispute();
  }
}
