import { Action, createReducer, on } from '@ngrx/store';
import { initialBrandingSettingsStateType, BrandingSettingsStateType } from '..';
import { signOut } from '@app/store/actions';
import { addBrandingAction, loadBrandsAction, selectBrandingAction } from '../branding-settings.actions';

const reducer = createReducer(
  initialBrandingSettingsStateType,
  on(addBrandingAction, (_state, action) => {
    return {
      brands: [..._state.brands, action.payload],
      selectedBrandingName: action.payload.name,
    };
  }),
  on(selectBrandingAction, (_state, action) => {
    return {
      brands: [..._state.brands],
      selectedBrandingName: action.payload,
    };
  }),
  on(loadBrandsAction, (_state, action) => {
    return {
      brands: [...action.payload],
      selectedBrandingName: _state.selectedBrandingName ?? undefined,
    };
  }),
  on(signOut, () => {
    return initialBrandingSettingsStateType;
  })
);

export function brandingSettingsReducer(state: BrandingSettingsStateType, action: Action): BrandingSettingsStateType {
  return reducer(state, action);
}
