import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';

import { AuthResponse } from '@app/authentication/models';
import { EnrollmentViewStateService } from '@app/enrollment/services';
import { EnrollmentStorage } from '@app/enrollment/utils/enrollment-storage.util';
import { FacingBrand, facingBrandMapping } from '@core/enums';
import { DialogService, EnrollmentService } from '@core/services';
import { olbSettings, STATE } from '@core/tokens';
import { DialogData, ServiceResult } from '@shared/models';

@Component({
  selector: 'app-enrollment-view',
  templateUrl: './enrollment-view.component.html',
  styleUrls: ['./enrollment-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentViewComponent implements OnInit {
  constructor(
    @Inject(STATE) private stateService: ng.ui.IStateService,
    @Inject(olbSettings) private settings: OlbSettings,
    public state: EnrollmentViewStateService,
    private enrollmentService: EnrollmentService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    const { isLite, comesFromEnrollment, isProxy } = this.state;

    if (isLite && !this.state.routeParams.hasOwnProperty('id')) {
      this.routeRedirect('auth.login');
    } else if (isLite && comesFromEnrollment) {
      this.liteEnrollmentAutoRegister();
    }

    if (isProxy && !this.state.routeParams.hasOwnProperty('id')) {
      this.goToLogin();
    }
  }

  goToLogin() {
    this.routeRedirect('auth.login');
  }

  /*   TODO: Once Migrated router
   *   update this method to use angular router instead IStateService
   */
  routeRedirect(path: string) {
    return this.stateService.go(path, { location: 'replace' });
  }

  private liteEnrollmentAutoRegister() {
    this.enrollmentService.autoRegister(this.state.regId).subscribe({
      next: this.handleAutoRegister.bind(this),
      error: this.showErrorAndGoToLogin.bind(this),
    });
  }

  private handleAutoRegister(res: ServiceResult<AuthResponse>) {
    const authResponse = res.data;

    const userFacingBrand = facingBrandMapping.find(({ brands }) => brands.includes(authResponse.brandId));

    EnrollmentStorage.expires = new Date(Date.now() + authResponse.tokenExpires * 1e3);
    EnrollmentStorage.userbrand = authResponse.brandId;
    EnrollmentStorage.userSubType = authResponse.userSubType ?? 0;
    EnrollmentStorage.userFacingBrand = userFacingBrand.facingBrand;
    EnrollmentStorage.firstName = authResponse.firstName;
    EnrollmentStorage.lastLogin = authResponse.lastLogin;

    // Prevent UFB customers going into the funding flow when coming from enrollment.
    if (this.settings.facingBrandId === FacingBrand.UFB) {
      return this.routeRedirect('udb.dashboard');
    }

    const accountsToBeFunded = authResponse.accountSyncSummary?.accountsAdded?.filter(
      account => account.isDeposit && !account.isIra
    );

    if (accountsToBeFunded) {
      EnrollmentStorage.accountsToBeFunded = accountsToBeFunded;

      return this.routeRedirect('udb.dashboard');
    }

    if (!authResponse.isOlbRegistered) {
      this.state.currentStep = 2;
    }
  }

  private showErrorAndGoToLogin(res: ServiceResult) {
    const observer = { next: () => this.routeRedirect('auth.login') };
    const data = new DialogData({
      title: 'Error',
      content: res.data.message,
    });

    this.dialogService.open(data).afterClosed().subscribe(observer);
  }
}
