<div class="frow center">
  <div
    class="fcol-cs-12"
    [ngClass]="{ 'fcol-lg-8': !isPfm3Active && !isRIAUser, 'fcol-lg-10': isPfm3Active || isRIAUser }"
  >
    <div class="header-section-title text-center frow">
      <h1 class="fcol-cs-12 header-section-title__header">
        Add External Account
      </h1>
    </div>

    <app-alert-notification *ngIf="errorMessage" alertType="error" (dismiss)="errorMessage = null">
      {{ errorMessage }}
    </app-alert-notification>

    <div *ngIf="!isPfm3Active && !isRIAUser">
      <div class="fi-search" *ngIf="!showAggregationValidation">
        <div class="frow center">
          <label for="fi-search__textbox" class="fi-search__label" style="margin-top: 3rem;">
            Find Your Financial Institution
          </label>
        </div>
        <div class="frow align-items-center center fi-search__textbox-container">
          <input
            type="text"
            class="uk-input fi-search__textbox"
            placeholder="Search financial institutions"
            [(ngModel)]="bankName"
            (keyup)="showResultsFromSearchBox($event)"
          />
          <img
            class="fi-search__btn"
            src="/img/icons/search.png"
            (click)="triggerShowResults()"
            alt="Search for banks"
            role="button"
          />
        </div>
      </div>

      <div class="fcol-cs-12">
        <app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>
      </div>

      <div class="frow center bank-logos" *ngIf="!isLoading && !showResults && !showAggregationValidation">
        <p class="fcol-cs-12 bank-logos__desc">
          Search or select from the financial institutions listed below:
        </p>

        <div class="row">
          <div class="fcol-cs-2" *ngFor="let logo of bankLogos">
            <img
              (click)="logo.onSelected()"
              class="bank-logos__logo-img"
              [src]="getBankLogo(logo.imageSrc)"
              width="100%"
              aria-hidden="true"
              role="button"
            />
          </div>
        </div>
      </div>

      <!-- Show page fot aggregation or validation account -->
      <div class="frow text-center center" *ngIf="!isLoading && showAggregationValidation" style="margin-top: 10px;">
        <div *ngIf="selectedBankCan(3)">
          <h3 class="fcol-cs-12" style="margin-bottom: 30px;">
            What would you like to do with your {{ selectedInstitution.name }} accounts?
          </h3>
          <br />
          <label class="fcol-cs-12 no-aggregation-validation-subheader" style="margin-bottom: 30px;">
            Choose one or both options.
          </label>

          <div class="frow center" style="margin-bottom: 2rem;">
            <div
              style="margin-right: 20px;"
              class="fcol-cs-5 card-flow"
              [ngClass]="{ 'card-flow-clicked': clickLinkAccounts, 'card-flow': !clickLinkAccounts }"
              (click)="checkItem(1)"
            >
              <div class="row" style="margin: 3px;">
                <div class="col-1 no-padding text-right">
                  <img class="" [src]="getLogo('link-accounts-unselected')" aria-hidden="true" />
                </div>

                <div class="col-10 text-left">
                  <h3 style="margin-bottom: 5px;">Link Accounts</h3>
                  <p>
                    Get your full financial picture. Your accounts will be included in your net worth, spending, and
                    more.
                  </p>
                </div>

                <div class="col-1 no-padding text-left">
                  <img *ngIf="!clickLinkAccounts" class="" [src]="getLogo('checkbox-unchecked')" aria-hidden="true" />
                  <img *ngIf="clickLinkAccounts" class="" [src]="getLogo('checkbox-checked')" aria-hidden="true" />
                </div>
              </div>
            </div>

            <div
              class="fcol-cs-5 card-flow"
              style="margin-left: 20px;"
              [ngClass]="{ 'card-flow-clicked': clickMoveMoney, 'card-flow': !clickMoveMoney }"
              (click)="checkItem(2)"
            >
              <div class="row" style="margin: 3px;">
                <div class="col-1 no-padding text-right">
                  <img class="" [src]="getLogo('move-money-unselected')" aria-hidden="true" />
                </div>

                <div class="col-10 text-left">
                  <h3 style="margin-bottom: 5px;">Move Money</h3>
                  <p>
                    Transfer funds to and from your eligible accounts within the Axos App.
                  </p>
                </div>

                <div class="col-1 no-padding text-left">
                  <img *ngIf="!clickMoveMoney" class="" [src]="getLogo('checkbox-unchecked')" aria-hidden="true" />
                  <img *ngIf="clickMoveMoney" class="" [src]="getLogo('checkbox-checked')" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedBankCan(1)">
          <div class="fcol-cs-12 no-aggregation-validation-header" style="margin-bottom: 3rem;">
            <h3>Link Your {{ selectedInstitution.name }} Accounts</h3>
          </div>

          <div class="fcol-cs-12 no-aggregation-validation-subheader" style="margin-top: 1rem;">
            {{ selectedInstitution.name }} doesn’t support moving money, but you can still link your accounts for a
            holistic view of your finances.
          </div>

          <div class="fcol-cs-12" style="margin-top: 1rem;">
            <img class="" [src]="getLogo('illustrationArea')" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isPfm3Active || isRIAUser">
      <div class="frow hide-xl hide-lg open-responsive">
        <div class="fcol-cs-9">
          <h4 class="open-text">
            Open a new Axos Account
          </h4>
        </div>
        <div class="fcol-cs-3">
          <app-open-account-popover [accountItems]="openAccItems"></app-open-account-popover>
        </div>
      </div>

      <div class="frow center">
        <div class="fcol-cs-12 fcol-md-10" [ngClass]="{ 'fcol-lg-9': !showResults, 'fcol-lg-12': showResults }">
          <div class="fi-search" *ngIf="!showAggregationValidation">
            <div class="frow center">
              <label for="fi-search__textbox" class="fi-search__label" style="margin-top: 3rem;">
                Find Your Financial Institution
              </label>
            </div>
            <div class="frow align-items-center center fi-search__textbox-container">
              <input
                type="text"
                class="uk-input fi-search__textbox"
                placeholder="Search financial institutions"
                [(ngModel)]="bankName"
                (keyup)="showResultsFromSearchBox($event)"
              />
              <img
                class="fi-search__btn"
                src="/img/icons/search.png"
                (click)="triggerShowResults()"
                alt="Search for banks"
                role="button"
              />
            </div>
          </div>

          <div class="fcol-cs-12">
            <app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>
          </div>

          <div class="frow center bank-logos" *ngIf="!isLoading && !showResults">
            <p class="fcol-cs-12 bank-logos__desc">
              Search or select from the financial institutions listed below:
            </p>

            <div class="row">
              <div class="fcol-cs-2" *ngFor="let logo of bankLogos">
                <img
                  (click)="logo.onSelected()"
                  class="bank-logos__logo-img"
                  [src]="getBankLogo(logo.imageSrc)"
                  width="100%"
                  aria-hidden="true"
                  role="button"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="fcol-lg-3 fcol-xl-3 hide-md hide-sm hide-cs hide-xs" *ngIf="showRightSidebar()">
          <div class="frow open-account" *ngIf="!isLoading && !showResults">
            <div class="fcol-cs-12">
              <div class="title-container">
                <h3 class="open-account-title">
                  {{ accountMessage }}
                </h3>
              </div>
              <p>
                Never lose sight of your money again. See it all here. It's fast, secure and efficient!
              </p>
              <div *ngIf="!isBusy">
                <div
                  class="frow open-account-item"
                  *ngFor="let item of openMenuItems"
                  (click)="redirectToUrl(item.redirectionUrl)"
                >
                  <div class="col-10 icon-description">
                    <app-svg-icon [iconName]="item.imageFile" [width]="'22px'" [height]="'22px'"></app-svg-icon>
                    <span class="description">{{ item.description }}</span>
                  </div>
                  <div class="col-2 open-chevron">
                    <img [src]="getChevronIcon('right')" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Show page fot aggregation or validation account -->
      <div class="frow text-center center" *ngIf="!isLoading && showAggregationValidation" style="margin-top: 10px;">
        <div *ngIf="selectedBankCan(3)">
          <h3 class="fcol-cs-12" style="margin-bottom: 30px;">
            What would you like to do with your {{ selectedInstitution.name }} accounts?
          </h3>
          <br />
          <label class="fcol-cs-12 no-aggregation-validation-subheader" style="margin-bottom: 30px;">
            Choose one or both options.
          </label>

          <div class="frow center" style="margin-bottom: 2rem;">
            <div
              style="margin-right: 20px;"
              class="fcol-cs-5 card-flow"
              [ngClass]="{ 'card-flow-clicked': clickLinkAccounts, 'card-flow': !clickLinkAccounts }"
              (click)="checkItem(1)"
            >
              <div class="row" style="margin: 3px;">
                <div class="col-1 no-padding text-right">
                  <img class="" [src]="getLogo('link-accounts-unselected')" aria-hidden="true" />
                </div>

                <div class="col-10 text-left">
                  <h3 style="margin-bottom: 5px;">Link Accounts</h3>
                  <p>
                    Get your full financial picture. Your accounts will be included in your net worth, spending, and
                    more.
                  </p>
                </div>

                <div class="col-1 no-padding text-left">
                  <img *ngIf="!clickLinkAccounts" class="" [src]="getLogo('checkbox-unchecked')" aria-hidden="true" />
                  <img *ngIf="clickLinkAccounts" class="" [src]="getLogo('checkbox-checked')" aria-hidden="true" />
                </div>
              </div>
            </div>

            <div
              class="fcol-cs-5 card-flow"
              style="margin-left: 20px;"
              [ngClass]="{ 'card-flow-clicked': clickMoveMoney, 'card-flow': !clickMoveMoney }"
              (click)="checkItem(2)"
            >
              <div class="row" style="margin: 3px;">
                <div class="col-1 no-padding text-right">
                  <img class="" [src]="getLogo('link-accounts-unselected')" aria-hidden="true" />
                </div>

                <div class="col-10 text-left">
                  <h3 style="margin-bottom: 5px;">Move Money</h3>
                  <p>
                    Transfer funds to and from your eligible accounts within the Axos App.
                  </p>
                </div>

                <div class="col-1 no-padding text-left">
                  <img *ngIf="!clickMoveMoney" class="" [src]="getLogo('checkbox-unchecked')" aria-hidden="true" />
                  <img *ngIf="clickMoveMoney" class="" [src]="getLogo('checkbox-checked')" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="selectedBankCan(1)">
          <div class="fcol-cs-12 no-aggregation-validation-header" style="margin-bottom: 3rem;">
            <h3>Link Your {{ selectedInstitution.name }} Accounts</h3>
          </div>

          <div class="fcol-cs-12 no-aggregation-validation-subheader" style="margin-top: 1rem;">
            {{ selectedInstitution.name }} doesn’t support moving money, but you can still link your accounts for a
            holistic view of your finances.
          </div>

          <div class="fcol-cs-12" style="margin-top: 1rem;">
            <img class="" [src]="getLogo('illustrationArea')" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>

    <!-- For Account Aggregation flow the label has different verbiage -->
    <div class="frow between search-results" *ngIf="showResults && visibleResults.length && !showAggregationValidation">
      <div
        class="finline fcol-cs-6 search-results__item middle between"
        *ngFor="let bank of visibleResults"
        (click)="setSelectedBank(bank)"
      >
        <span>{{ bank.name }}</span>
        <i class="bofi-arrow-chevron"></i>
      </div>
      <a
        style="margin-bottom: 2rem;"
        *ngIf="!isLoading && visibleResults.length < results.length"
        (click)="showMoreResults()"
        class="fcol-cs-12 m-t-lg search-results__show-more text-center"
      >
        Show more
      </a>

      <!-- For Account Aggregation flow the manual aggregation of accounts is not allowed -->
      <div
        class="m-t-lg frow center search-refine-hint"
        *ngIf="
          fundingState.isRunning &&
          !isLoading &&
          !isAccountAggregationFlow &&
          (currentResultChunk >= 2 || visibleResults.length == results.length)
        "
      >
        Don't see your financial institution?
        <a class="link" (click)="goToAddExternalAccount()">Add Manually</a>
      </div>
    </div>

    <!-- Show message at the bottom -->
    <div *ngIf="showOnKey && !isLoading && !showAggregationValidation">
      <div class="no-results frow text-center center" style="margin: 3rem;">
        <img [src]="noResultsImgSrc" *ngIf="showOnKey && !isLoading && !visibleResults.length" />
        <h2
          class="fcol-cs-12 no-results__title"
          style="margin: 3rem;"
          *ngIf="showOnKey && !isLoading && !visibleResults.length"
        >
          No financial institutions found.
        </h2>
        <h3 class="fcol-cs-12 no-results__subtitle">
          Don't see your financial institution?
          <a class="link" (click)="goToAddExternalAccount()">
            Add Manually
            <app-tooltip content="{{ toolTipMessage }}"></app-tooltip>
          </a>
        </h3>
      </div>
    </div>

    <!-- For Account Aggregation flow the manual aggregation of accounts is not allowed
      <div
        class="m-t-lg frow center search-refine-hint"
        *ngIf="!showResults && !isLoading && !visibleResults.length && !isAccountAggregationFlow"
      >
        <p>
          You can also add your account
          <a class="link" (click)="goToAddExternalAccount()">manually</a>
        </p>
      </div>
    -->

    <!-- For Account Aggregation flow the button verbiage is different and it should go back to the original page -->
    <div
      class="frow center"
      *ngIf="showAggregationValidation"
      [ngClass]="{ 'buttons-footer-top-separation': showFooter }"
    >
      <button type="button" class="uk-btn lg outline primary" (click)="goBack()" style="margin: 0 13px;">
        Back
      </button>

      <button
        type="button"
        class="uk-btn lg solid secondary"
        (click)="continueToFastLink()"
        *ngIf="showAggregationValidation && !isLoading"
        [disabled]="flowTypeSelected == null && !selectedBankCan(1)"
        style="margin: 0 13px;"
      >
        Continue
      </button>
    </div>

    <div
      class="frow center"
      *ngIf="!showAggregationValidation"
      [ngClass]="{ 'buttons-footer-top-separation': showFooter }"
    >
      <button type="button" class="uk-btn lg outline primary" (click)="goBack()" style="margin: 0 13px;">
        Back
      </button>
    </div>

    <!-- Show image at bottom -->
    <div class="frow center" *ngIf="showFooter">
      <p class="header-with-copy header-with-copy-font">
        On behalf of Axos Bank,
        <br />
        data access provided by
        <img class="" [src]="getLogo('invest-y')" aria-hidden="true" />
      </p>
    </div>
  </div>
</div>
