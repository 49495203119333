import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { cryptoComponents } from './components';

@NgModule({
    declarations: [...cryptoComponents],
    imports: [CommonModule, SharedModule],
    exports: [...cryptoComponents],
    providers: []
})
export class CryptoModule {}
