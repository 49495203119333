import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { GASignup } from '@app/authentication/models';
import { updateProfileDetails } from '@app/user-profile/store/actions';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { AuthService } from '@core/services';
import { CustomerDetail } from '@shared/models';

@Component({
  selector: 'app-google-authentication-view',
  templateUrl: './google-authentication-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './google-authentication-view.component.scss'],
})
export class GoogleAuthenticationViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  @Input()
  expanded = false;
  isMobileDevice = false;
  isLoading = true;
  qrCode: GASignup;
  code: string;
  profileInfo: Partial<CustomerDetail>;
  isGoogleAuthActive: boolean;
  action = 'Reset Auth';

  private subsink = new SubSink();

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService, private store: Store) {}

  ngOnInit(): void {
    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        if (!this.profileInfo) {
          this.profileInfo = data;
          this.isGoogleAuthActive = this.profileInfo.isGoogleAuthActive;
          this.loadQR();
        }
      });

    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  validateCode() {
    this.isLoading = true;
    this.authService.validateGoogleQR(this.code).subscribe(resp => {
      if (resp.data) {
        this.code = null;
        this.qrCode = null;
      }
      this.isLoading = false;
    });
  }

  reset() {
    this.isLoading = true;
    if (this.action === 'Reset Auth') {
      console.log('into');
      this.authService.resetGoogle().subscribe(resp => {
        console.log(`resp: ${resp}`);
        if (resp.data) {
          this.isGoogleAuthActive = false;
          const payload: Partial<CustomerDetail> = {
            ...this.profileInfo,
            isGoogleAuthActive: false,
          };

          this.store.dispatch(updateProfileDetails({ payload }));
        }
        this.isLoading = false;
      });
      this.action = 'Generate New Code';
    } else {
      this.loadQR();
      this.action = 'Reset Auth';
    }
  }

  private loadQR() {
    this.isLoading = true;
    if (!this.isGoogleAuthActive) {
      this.authService.getGoogleQR().subscribe(response => {
        this.qrCode = response.data;
        this.isGoogleAuthActive = true;
        const payload: Partial<CustomerDetail> = {
          ...this.profileInfo,
          isGoogleAuthActive: true,
        };
        this.store.dispatch(updateProfileDetails({ payload }));
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
    }
  }
}
