<div>
  <div class="col-12">
    <section class="section-container__top">
      <app-dot-loader *ngIf="isLoading">Loading...</app-dot-loader>
      <div class="row" *ngIf="!isLoading">
        <div class="col-12">
          <div class="total-value-text">Total Value</div>
          <div class="total-value-currency" *ngIf="displayTotalValueFromGraph">
            {{ totalValueFromGraph ? (totalValueFromGraph | currency) : '$0.00' }}
          </div>
          <div class="total-value-currency" *ngIf="!displayTotalValueFromGraph">
            {{
              totalValueTileAccountState$?.totalValue ? (totalValueTileAccountState$?.totalValue | currency) : '$0.00'
            }}
          </div>
          <div class="total-value-difference-today" *ngIf="!displayTotalValueFromGraph">
            <uk2-value-movement
              *ngIf="totalValueTileAccountState$"
              [amount]="getAbsValue(totalValueTileAccountState$.differencePriorToday)"
              [percentage]="getAbsValue(totalValueTileAccountState$.percentagePriorToday)"
              [description]="getDescriptionToday(totalValueTileAccountState$.differencePriorToday)"
              [valueMovementType]="getUk2ValueMovementTypeEnum(totalValueTileAccountState$.percentagePriorToday)"
              [isEmpty]="totalValueTileAccountState$.differencePriorToday === undefined"
            ></uk2-value-movement>
          </div>
          <div class="total-value-difference-alltime" [class.without-today]="displayTotalValueFromGraph">
            <div *ngIf="displayTotalValueFromGraph">
              <uk2-value-movement
                [amount]="getAbsValue(differenceAllTimeFromGraph)"
                [percentage]="getAbsValue(percentageAllTimeFromGraph)"
                [description]="timePeriodChangeTicker"
                [valueMovementType]="getUk2ValueMovementTypeEnum(percentageAllTimeFromGraph)"
                [isEmpty]="differenceAllTimeFromGraph === undefined"
              ></uk2-value-movement>
            </div>
            <div *ngIf="!displayTotalValueFromGraph">
              <uk2-value-movement
                [amount]="getAbsValue(differenceAllTime)"
                [percentage]="getAbsValue(percentageAllTime)"
                [description]="timePeriodChangeTicker"
                [valueMovementType]="getUk2ValueMovementTypeEnum(percentageAllTime)"
                [isEmpty]="differenceAllTime === undefined"
              ></uk2-value-movement>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div *ngIf="!isLoading">
    <app-total-value-graph
      [data]="totalValueIntervalPeriodDateState"
      [filter]="activeFilter"
      [displayColor]="displayColor"
      [hasError]="totalValueTileAccountState$?.hasError"
      (tooltipValue)="displayTotalValueFromTile($event)"
    ></app-total-value-graph>
  </div>
  <div class="col-12">
    <section class="section-container__bottom">
      <div class="row" *ngIf="!isLoading">
        <div class="col-12 pt-4 pb-4">
          <div class="col-12 col-sm-12 col-md-8 offset-md-2">
            <mat-button-toggle-group
              uk2ButtonToggleGroup
              value="{{ filterDatesEnum.M3 }}"
              [size]="'large'"
              (change)="changeGraph($event.value)"
            >
              <mat-button-toggle value="{{ filterDatesEnum.M3 }}">
                3M
              </mat-button-toggle>
              <mat-button-toggle value="{{ filterDatesEnum.M6 }}">
                6M
              </mat-button-toggle>
              <mat-button-toggle value="{{ filterDatesEnum.YTD }}">
                YTD
              </mat-button-toggle>
              <mat-button-toggle value="{{ filterDatesEnum.Y1 }}">
                1Y
              </mat-button-toggle>
              <mat-button-toggle value="{{ filterDatesEnum.Y3 }}">
                3Y
              </mat-button-toggle>
              <mat-button-toggle value="{{ filterDatesEnum.Y5 }}">
                5Y
              </mat-button-toggle>
              <mat-button-toggle value="{{ filterDatesEnum.All }}">
                ALL
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
