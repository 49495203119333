import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Uk2ToastComponent, Uk2ToastTypeEnum } from '@axos/uikit-v2-lib';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegisterUserAction } from '@app/sbloc-offers/utils/registerUserAction';
import { STATE } from '@core/tokens';
import { SblocService } from '@app/sbloc-offers/services/sbloc.service';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { SBlocApplicationRequest } from '@app/sbloc-offers/Models';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit {
  //submitButton: HTMLInputElement;
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private registerUserAction: RegisterUserAction,
    private _snackBar: MatSnackBar,
    private readonly _sblocService: SblocService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    //this.submitButton = <HTMLInputElement>document.getElementById('btnSendEmail');
  }

  NotRightNow() {
    this.registerUserAction.registerSelectedNotRightNow().subscribe(() => {
      this.state.go('udb.dashboard');
    });
  }

  emailMeEnrollmentDocuments() {
    this._sblocService.processingApplication.next(true);

    let sBlocApplicationRequest: SBlocApplicationRequest = {
      rate: this.data.apr,
      amount: this.data.amount,
      libertyAccountNumber: this.data.account,
      margin: this.data.margin,
    };

    this._sblocService
      .submitOffer(sBlocApplicationRequest, this._sblocService.getUtmCodes())
      .toPromise()
      .then(response => {
        if (response.data.success) {
          this._snackBar.openFromComponent(Uk2ToastComponent, {
            panelClass: ['uk2-toast.uk2-toast-success'],
            duration: 5000,
            data: {
              message: 'Please check your email and follow the instructions to complete your account setup.',
              type: Uk2ToastTypeEnum.success,
            },
          });

          this.registerUserAction
            .registerEmailMeEnrollmentDocuments(
              this.data.apr,
              this.data.amount,
              this.data.principalEmail,
              this.data.jointEmail
            )
            .subscribe(() => {
              this.state.go('udb.dashboard');
            });
        } else {
          if (response.data.message == 'The application already exists, Please review your data') {
            this._snackBar.openFromComponent(Uk2ToastComponent, {
              panelClass: ['uk2-toast.uk2-toast-alert'],
              duration: 5000,
              data: {
                message:
                  'This is a duplicate request. Please check your email and follow the instructions to complete account setup',
                type: Uk2ToastTypeEnum.alert,
              },
            });

            this.state.go('udb.dashboard');
          } else {
            this.showErrorModal();
          }
        }
      })
      .catch(() => {
        this.showErrorModal();
      })
      .finally(() => {
        this._sblocService.processingApplication.next(false);
      });
  }

  showErrorModal() {
    const defaultMessage =
      '<p class="something-text">Something went wrong.</p><br/><p> Something unexpected went wrong on our end. Please try again. Thank you.<p>';
    const modalSettings: ModalSettings = {
      icon: 'bofi-cancel',
      bodyText: defaultMessage,
      okText: 'Close and try again later',
      hasCancelButton: false,
    };

    this.modalService
      .show({ windowClass: 'modal-service internal-error-modal' }, modalSettings)
      .then(() => {
        this.state.go('udb.dashboard');
      })
      .catch(() => {});
  }
}
