<div class="minor-address">
  <div class="minor-address_title">
    <h3>Minor's Address</h3>
  </div>
  <div class="frow">
    <input
      type="checkbox"
      id="minorSameAsOwner"
      [(ngModel)]="minor.sameAddressAsOwner"
      [formControl]="sameAsOwnerControl"
    />
    <label for="minorSameAsOwner">Same as owner</label>
  </div>
  <div *ngIf="!minor?.sameAddressAsOwner">
    <div class="frow">
      <div
        class="group--form fcol-md-6 fcol-cs-12"
        [class.has-error]="
          (minorAddressStreetControl?.invalid || minorAddressStreetControl?.errors?.required) && isSubmitted
        "
      >
        <label for="minorAddressStreet" class="uk-label">Street Address</label>
        <input
          id="minorAddressStreet"
          name="minorAddressStreet"
          class="uk-input"
          type="text"
          [formControl]="minorAddressStreetControl"
          [required]="!minor?.sameAddressAsOwner"
        />
        <div
          *ngIf="
            (!minor.sameAddressAsOwner && minorAddressStreetControl?.invalid && minorAddressStreetControl?.touched) ||
            isSubmitted
          "
        >
          <span class="text-error" *ngIf="minorAddressStreetControl?.errors?.required">
            Street Address is required
          </span>
        </div>
      </div>
      <div
        class="group--form fcol-md-6 fcol-cs-12"
        [class.has-error]="
          (minorAddressZipCodeControl?.invalid || minorAddressZipCodeControl?.errors?.required) && isSubmitted
        "
      >
        <label for="minorAddressZipCode" class="uk-label">Zip Code</label>
        <input
          id="minorAddressZipCode"
          name="minorAddressZipCode"
          class="uk-input"
          type="text"
          maxlength="5"
          [formControl]="minorAddressZipCodeControl"
          (change)="getCityByZipCode()"
          [required]="!minor.sameAddressAsOwner"
        />
        <div
          *ngIf="
            (!minor?.sameAddressAsOwner &&
              minorAddressZipCodeControl?.invalid &&
              minorAddressZipCodeControl?.touched) ||
            isSubmitted
          "
        >
          <span class="text-error" *ngIf="minorAddressZipCodeControl?.errors?.required">
            Zip Code is required
          </span>
        </div>
      </div>
    </div>
    <div class="frow">
      <div class="group--form fcol-md-6 fcol-cs-12" [class.has-error]="minorCityControl?.invalid && isSubmitted">
        <label for="minorAddressCity" class="uk-label">City</label>
        <input
          id="minorAddressCity"
          name="minorAddressCity"
          class="uk-input"
          type="text"
          [formControl]="minorCityControl"
          [required]="!minor.sameAddressAsOwner"
        />
        <div
          *ngIf="(!minor.sameAddressAsOwner && minorCityControl?.invalid && minorCityControl?.touched) || isSubmitted"
        >
          <span class="text-error" *ngIf="minorCityControl?.errors?.required">
            City is required
          </span>
        </div>
      </div>
      <div class="group--form fcol-md-6 fcol-md-6" [class.has-error]="minorStateControl?.invalid && isSubmitted">
        <label for="minorAddressState" class="uk-label">State</label>
        <select
          id="minorAddressState"
          name="minorAddressState"
          class="uk-input"
          [formControl]="minorStateControl"
          [required]="!minor?.sameAddressAsOwner"
        >
          <option *ngIf="!minor.address?.state" value="" disabled selected>
            Select
          </option>
          <option *ngFor="let stateAbbr of statesAbbreviations" value="{{ stateAbbr }}">
            {{ statesFullNames[statesAbbreviations.indexOf(stateAbbr)] }} ({{ stateAbbr }})
          </option>
        </select>
        <div *ngIf="!minor?.sameAddressAsOwner && minorStateControl?.invalid && isSubmitted">
          <span class="text-error" *ngIf="minorStateControl?.errors?.required">
            State is required
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
