import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { OrderStatus } from '@app/axos-trading/enums/order-status';
import { Order, TradingAccount, Transaction } from '@app/axos-trading/models';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import * as OrderActions from '@app/axos-trading/store/orders/orders.actions';
import { MiscIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'tr[app-transactions-orders-row]',
  templateUrl: './transactions-orders-row.component.html',
  styleUrls: ['./transactions-orders-row.component.scss'],
})
export class TransactionsOrdersRowComponent {
  @Input() transaction: Transaction;
  @Input() accountId: number;
  iconName: string;
  icons = {
    other: MiscIcons.PuzzlePiece,
    Kebab: UtilityIcons.Kebab
  };
  orderStatus = OrderStatus;
  subsink: any;
  tradingAccount: TradingAccount;
  openTransactions: Order[];

 constructor(
   private routeHelper: TradingRoutingHelperService,
   private store: Store){
 }
 goDetails(reference: string){
   // Set current order selected in the store
   this.store.dispatch(OrderActions.setCurrentOrderReference({ currentOrderReference: reference }));
   this.routeHelper.goToTransactionDetails(this.accountId);
 }
}
