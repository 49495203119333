import { EditSingleTransferController } from './edit-single-transfer.controller'; // Load controller

let EditSingleTransfer: ng.IComponentOptions = {
  controller: EditSingleTransferController,
  templateUrl: 'transfers/edit-transfer/edit-single-transfer.tpl.html',
  controllerAs: '$ctrl',
  bindings: {
    transferAccountId: '=?',
  },
};

export { EditSingleTransfer };
