import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';

import { AuthStatus } from '@app/authentication/enums';
import { AuthRequest, AuthResponse, AuthSuccessResult } from '@app/authentication/models';
import { EnrollmentViewStateService } from '@app/enrollment/services';
import { OlbSettings } from '@core/models';
import { AuthService, MultifactorService } from '@core/services';
import { olbSettings, STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Output() signin = new EventEmitter();
  credentials: AuthRequest;
  hasLoginFailed = false;
  message = '';
  forgotPasswordBand = false;
  usernamePasswordMismatch = false;
  isLoggingIn = false;

  closeIcon = NavigationIcons.Ex;
  private authResponse: AuthResponse;

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    private authService: AuthService,
    private serviceHelper: ServiceHelper,
    private multifactorService: MultifactorService,
    private cookieHelper: CookieHelper,
    private enrollmentViewStateService: EnrollmentViewStateService
  ) {}

  ngOnInit(): void {
    this.credentials = {
      userName: '',
      password: '',
      brandCode: String(this.env.brandCode),
      deviceId: '',
      deviceSignature: '',
    };
  }

  // Send to the page for recovery the password.
  sendPassword(): void {
    if (parseInt(this.getAttempValueFromLocalStorage(), 10) >= 3) {
      this.state.go('auth.forgotpassword', { user: this.credentials.userName });
    } else {
      this.state.go('auth.forgotpassword');
    }
  }

  // Send to the page for recovery the username.
  sendUsername(): void {
    this.state.go('auth.forgotusername');
  }

  redirectToTermsAndConditionsFull(): void {
    this.state.go('auth.enrollment', { version: 'full' });
  }

  /** Attempts to log the user into the application. */
  login(): void {
    this.isLoggingIn = true;
    this.hasLoginFailed = false;
    this.usernamePasswordMismatch = false;
    this.credentials.userName = this.credentials.userName.trim();
    this.updateCredentialsWithMultifactorService();
    this.authService.login(this.credentials).subscribe({
      next: this.onLogin.bind(this),
      error: this.onLoginFailed.bind(this),
    });
  }

  updateCredentialsWithMultifactorService() {
    this.multifactorService.initializeDDNA();
    const deviceInfo = this.multifactorService.getDeviceInformation();
    this.credentials.deviceId = deviceInfo.deviceId;
    this.credentials.deviceSignature = deviceInfo.deviceSignature.replace(/\s/g, '');
  }

  private onLoginFailed(err: HttpErrorResponse) {
    this.hasLoginFailed = true;
    if (!this.verifyAttemps()) {
      if (err.status === 401) {
        this.usernamePasswordMismatch = true;
      } else if (err.status === 503) {
        this.message = err.error.message;
      } else {
        this.message = this.serviceHelper.errorHandler(err, false);
      }
    }

    this.isLoggingIn = false;
  }

  /**
   * Handles the login attempt response.
   * @param res The response from the server.
   */
  private onLogin(res: OlbResponse<AuthResponse>): void {
    this.authResponse = res.data;
    this.setSessionStorageVariables();

    if (res.data.authStatus === AuthStatus.Allow) {
      this.handleOnLoginAllowStatus(res);
    } else if (res.data.authStatus === AuthStatus.Challenge) {
      this.handleOnLoginChallengeStatus(res);
      this.isLoggingIn = false;
    }
  }

  private handleOnLoginAllowStatus(res: OlbResponse<AuthResponse>) {
    this.callMultifactorService(res);

    if (res.data.isOlbRegistered) {
      sessionStorage.setItem('isSBBAvailable', res.data.isSBBAvailable.toString());
      this.state.go('udb.dashboard');
    } else {
      this.redirectToTermsAndConditions(res.data.authInfoLiteToken);
    }
  }

  private handleOnLoginChallengeStatus(res: OlbResponse<AuthResponse>) {
    sessionStorage.setItem('SignInFlowToken', res.data.challengeToken);
    sessionStorage.setItem('lastLogin', res.data.lastLogin);
    if (res.data.authInfoLiteToken) {
      this.enrollmentViewStateService.authLiteToken = res.data.authInfoLiteToken;
    }
    this.callMultifactorService(res);
    this.message = '';

    const loginResultsToEmit: AuthSuccessResult = {
      loginState: res.data.hasGoogleAuthenticator ? 3 : 2,
      showEmailBtn: res.data.emailNotificationAvailable,
      showSmsBtn: res.data.smsNotificationAvailable,
      userName: this.credentials.userName.trim(),
      isOlbRegistered: res.data.isOlbRegistered,
      hasSecurityQuestions: res.data.hasSecurityQuestions,
      hasGoogleAuth: res.data.hasGoogleAuthenticator,
    };
    this.signin.emit(loginResultsToEmit);
  }
  private callMultifactorService(res: OlbResponse<AuthResponse>) {
    this.multifactorService.setUserInformation(
      this.credentials.userName,
      res.data.telephoneNumber,
      res.data.email,
      res.data.emailNotificationAvailable
    );
    this.multifactorService.updateDeviceInformation(res.data);
    localStorage.setItem('firstName', res.data.fullName);
    localStorage.setItem('lastLogin', res.data.lastLogin);
  }

  /** Sets the variables into the session storage. */
  private setSessionStorageVariables(): void {
    let expirationToken = new Date();
    expirationToken = new Date(expirationToken.getTime() + this.authResponse.tokenExpires * 1000);
    sessionStorage.setItem('username', this.credentials.userName);
    sessionStorage.setItem('expires', expirationToken.getTime().toString());
    sessionStorage.setItem('fundRemembered', 'false');
    sessionStorage.setItem('userbrand', this.authResponse.brandId.toString());
    sessionStorage.setItem(
      'userFacingBrand',
      this.cookieHelper.getUserFacingBrand(this.authResponse.brandId).toString()
    );

    if (this.authResponse.email) sessionStorage.setItem('maskedEmail', this.authResponse.email);
    if (this.authResponse.telephoneNumber) {
      sessionStorage.setItem('maskedCellPhone', `(***) ***-${this.authResponse.telephoneNumber}`);
    }

    // Add hasAxosInvest flag to root scope so we can use it in the dashboard
    sessionStorage.setItem('hasAxosInvest', this.authResponse.hasAxosInvest.toString());

    // Add userSubType so we can use it
    sessionStorage.setItem('userSubType', this.authResponse.userSubType?.toString() ?? '0');
  }

  private verifyAttemps(): boolean {
    this.validateUserValueFromLocalStorage();
    const attemps = this.getAttempValueFromLocalStorage();
    const stringAtt = parseInt(attemps, 10) + 1;

    localStorage.setItem('attemps', stringAtt.toString());
    if (stringAtt >= 3) {
      this.forgotPasswordBand = true;

      return true;
    }

    return false;
  }

  private validateUserValueFromLocalStorage() {
    const userNameFromLocalStorage = localStorage.getItem('username');
    const userNameFromCredentials = this.credentials.userName;

    if (!userNameFromLocalStorage || userNameFromLocalStorage !== userNameFromCredentials) {
      localStorage.setItem('username', userNameFromCredentials);
      localStorage.setItem('attemps', '0');
      this.forgotPasswordBand = false;
    }
  }

  private getAttempValueFromLocalStorage(): string {
    if (!localStorage.getItem('attemps')) {
      localStorage.setItem('attemps', '0');
    }

    return localStorage.getItem('attemps');
  }

  private redirectToTermsAndConditions(authLiteToken: string): void {
    this.state.go('auth.enrollment', {
      version: 'lite',
      id: authLiteToken,
      redirect: true,
    });
  }
}
