import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-modify-confirmation-banner',
  templateUrl: './modify-confirmation-banner.component.html',
  styleUrls: ['./modify-confirmation-banner.component.scss'],
})
export class ModifyConfirmationBannerComponent implements OnInit {
  @Input() bannerText: string;
  @Input() duration: number;
  @Output() dismissBannerEvent = new EventEmitter<any>();

  checkmarkIcon = UtilityIcons.CircleCheck;

  get progressBar() {
    return {
      'animation-duration': `${this.duration}ms`,
    };
  }

  constructor() {}

  ngOnInit() {
    if (this.duration) {
      setTimeout(() => {
        this.dismissBanner();
      }, this.duration);
    }
  }

  dismissBanner() {
    const modified = false;
    this.dismissBannerEvent.emit({
      modified,
    });
  }
}
