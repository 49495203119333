import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-reactive-transfer-amount',
  templateUrl: './reactive-transfer-amount.component.html',
  styleUrls: ['./reactive-transfer-amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveTransferAmountComponent implements OnInit {
  @Input() control: UntypedFormControl;

  maskedAmount = '0.00';
  constructor() {}

  ngOnInit(): void {
    this.onBlur();
  }

  onBlur() {
    if (!this.control.value) {
      this.control.setValue(this.maskedAmount, { emitEvent: false });
    }
  }

  onFocus() {
    if (this.control.value === this.maskedAmount) {
      this.control.setValue('', { emitEvent: false });
    }
  }
}
