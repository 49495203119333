import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DropdownItem } from '@uikit/clickdropdown';
import tippy, { Props, animateFill, hideAll, roundArrow } from 'tippy.js';

@Component({
  selector: 'app-click-dropdown',
  templateUrl: './click-dropdown.component.html',
  styleUrls: ['./click-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ClickDropdownComponent implements OnInit {
  @Input() dropdownItems: DropdownItem[] = [] as DropdownItem[];
  @Input() selectedItem: DropdownItem = { text: '[Select Account]' } as DropdownItem;
  @Output() item = new EventEmitter<DropdownItem>();
  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChild('dropdown', { read: ElementRef, static: false }) dropdown: ElementRef;

  ngOnInit(): void {}

  emitOnSelect(dropdownItem: DropdownItem) {
    this.selectedItem = { ...dropdownItem };
    this.item.emit(dropdownItem);
    hideAll();
  }

  options: Partial<Props> = {
    allowHTML: true,
    animateFill: false,
    arrow: roundArrow,
    placement: 'bottom',
    plugins: [animateFill],
    theme: 'olb',
    interactive: true,
    appendTo: 'parent',
    trigger: 'click',
    hideOnClick: false,
    onClickOutside(instance, event) {
      let mouseClick: any = event;
      if (mouseClick.srcElement.localName != 'app-click-dropdown') {
        instance.hide();
      }
    },
  };

  ngAfterViewInit(): void {
    const template = this.dropdown.nativeElement;
    this.options.content = template;
    tippy(this.tooltip.nativeElement, this.options);
  }
}
