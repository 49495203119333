import { IRootScopeService } from 'angular';
import { Inject } from 'decorators/decorators';
import { IStateService } from 'angular-ui-router';
import { AccountType } from 'common/enums/enums';

@Inject('$rootScope', '$state')
export class AccountRouterHelper {
  constructor(
    private readonly rootScope: IRootScopeService,
    private readonly state: IStateService
  ) {}

  goToAccountDetailPage(
    accountId: number | string,
    accountType: AccountType,
    selectedTab: number = 0
  ) {
    let type = this.getAccountType(accountType);
    let state = this.getRedirectState(accountType);

    this.state.go(state, {
      id: accountId,
      type: type,
      tab: selectedTab,
    });
  }

  private getRedirectState(accountType: AccountType): string {
    switch (accountType) {
      case AccountType.AxosInvest:
      case AccountType.AxosTrading:
        return 'udb.accounts.container';
      default:
        this.rootScope['accountContainer-tab'] = 0; // <- Set default tab for account container
        return 'udb.accounts.details';
    }
  }

  private getAccountType(accountType: AccountType): string {
    let type;

    switch (accountType) {
      case AccountType.AxosInvest:
        type = 'Invest';
        break;
      case AccountType.AxosTrading:
        type = 'Trading';
        break;
    }

    return type;
  }
}
