<mat-dialog-content class="mat-dialog-content">
  <div class="image"></div>
  <div class="titles">
    <h2>
      {{ startingSentence }} from your
      <br />
      business account?
    </h2>
    <p>
      To complete your request, you are being directed outside the Axos
      <br />
      website. Would you like to continue?
    </p>
  </div>
  <div class="button-bar">
    <button class="btn-cancel button--lg" mat-dialog-close>Cancel</button>
    <button class="uk-btn solid secondary lg" (click)="continue()">Yes, Continue</button>
  </div>
</mat-dialog-content>
