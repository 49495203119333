<div class="header">
  <div class="close-btn" (click)="goBack()">
    <app-svg-icon [iconName]="icons.ChevronLeft" [styles]="{ width: '1rem', height: '1rem' }"></app-svg-icon>
  </div>
  <span class="encode-sans" style="margin-left: -2rem;">{{ currentScreen }}</span>
  <span></span>
</div>
<div style="flex: 1;" *ngIf="currentScreen == FiltersModalScreen.Overview">
  <div class="filter">
    <div class="name">
      Filter by Account
    </div>
    <div class="value" (click)="goTo(FiltersModalScreen.AccountsFilter)">
      <span *ngIf="(uncommittedFiltersInDefaultState | async).accounts">
        All
      </span>
      <span *ngIf="!(uncommittedFiltersInDefaultState | async).accounts">
        {{ accAggFormatter.getSelectedAccountsSize(config.accounts, excludedAccountsFilter | async) }} of
        {{ accAggFormatter.getAccountsSize(config.accounts, true) }} Accounts
      </span>

      <app-svg-icon
        [iconName]="icons.ChevronRight"
        [styles]="{ width: '1rem', height: '1rem' }"
        class="float-left"
      ></app-svg-icon>
    </div>
  </div>
  <div class="filter">
    <div class="name">
      Filter by Time Period
    </div>
    <div class="value" (click)="goTo(FiltersModalScreen.TimePeriodFilter)">
      {{ this.timePeriodFilter | async }}
      <app-svg-icon
        [iconName]="icons.ChevronRight"
        [styles]="{ width: '1rem', height: '1rem' }"
        class="float-left"
      ></app-svg-icon>
    </div>
  </div>
</div>
<div *ngIf="currentScreen == FiltersModalScreen.Overview">
  <div class="btn primary" (click)="goBack()">Apply</div>
  <div class="btn mt-2" (click)="resetAllFilters()">Clear</div>
</div>
<div *ngIf="currentScreen == FiltersModalScreen.TimePeriodFilter">
  <app-time-period-filter [filter$]="timePeriodFilter" [parentPage]="ParentPage.NetWorth"></app-time-period-filter>
</div>
<div *ngIf="currentScreen == FiltersModalScreen.AccountsFilter">
  <app-accounts-filter
    [providers]="config.providers"
    [accounts]="config.accounts"
    [filter$]="excludedAccountsFilter"
    [balanceOverrides]="config.accountsFilterBalanceOverrides"
    (changeFilter)="onAccountsFilterChange($event)"
    (resetFilter)="onAccountsFilterReset()"
  ></app-accounts-filter>
</div>
