<div class="file-upload">
  <div [hidden]="hasCustomDropZone">
    <ul class="file-upload__files" *ngIf="allFiles">
      <li *ngFor="let file of allFiles" class="file-upload__files-item">
        <span [innerText]="file.name"></span>
        <app-svg-icon
          [iconName]="closeIcon"
          height=".8em"
          width=".8em"
          iconColor="var(--link)"
          (click)="removeItem(file)"
        ></app-svg-icon>
      </li>
    </ul>
    <div
      [class.file-upload__drop-zone-over]="dragOver"
      class="file-upload__drop-zone"
      (dragover)="onDragFilesOver($event)"
      (dragleave)="onDragFilesLeave($event)"
      (drop)="ondropFiles($event)"
    >
      <p class="text-center" *ngIf="!dragOver">
        <span class="hidden-xs">Drag and drop files to upload or</span>
        <button type="button" (click)="inputFileUpload.click()" class="file-upload__choose-file">
          Choose a file
        </button>
        <span>(Max file size 10MB)</span>
      </p>
      <p class="text-center" *ngIf="dragOver">
        <span>Drop files here</span>
      </p>
      <input
        class="file-upload__upload"
        type="file"
        #inputFileUpload
        multiple
        (change)="processFiles($event.target.files)"
      />
    </div>
  </div>

  <ng-container *ngIf="hasCustomDropZone">
    <button
      class="file-upload__custom-drop-zone"
      (dragover)="onDragFilesOver($event)"
      (dragleave)="onDragFilesLeave($event)"
      (drop)="ondropFiles($event)"
      (click)="inputFileUpload.click()"
    >
      <ng-content select="[drop-zone]"></ng-content>
    </button>
  </ng-container>

  <app-alert-notification
    *ngIf="validationError"
    alertType="error"
    class="file-upload__alert"
    (dismiss)="cleanValidationError()"
  >
    {{ validationError }}
  </app-alert-notification>
</div>
