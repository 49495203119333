<h3 class="mt-5 mb-4">How do I set up e-Bills?</h3>
<ol>
  <li>
    Click on the
    <b>Pay Bills tab.</b>
  </li>
  <li>
    After you set up the payee, click on
    <b>Enroll in eBills</b>
    for this payee.
  </li>
  <li>
    Select the Biller Site and click
    <b>Continue.</b>
  </li>
  <li>
    Enter your Login ID and password for your account with the payee and click
    <b>Login.</b>
  </li>
  <li>
    Select the Account that you want to use and click
    <b>Continue.</b>
  </li>
  <li>You can then select to make a one-time payment or automatic payments.</li>
  <li>
    For one time payment, click on
    <b>Make a one-time payment.</b>
  </li>
  <li>
    Select the account that you want to pay from, the payment amount and the date that you want to
    send the payment.
  </li>
  <li>
    You have an optional
    <b>Memo</b>
    section if you want to include any additional information.
  </li>
  <li>
    You can also select any alerts or notifications that you want to receive for the payment. When
    complete, select
    <b>Send Payment.</b>
  </li>
</ol>
