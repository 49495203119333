import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '../actions';
import {
  changeFundingAmount,
  changeFundingMethod,
  fundAccount,
  setupAccountsToBeFunded,
  setupFundingAccountId,
  setupFundingAccounts,
  setupFundingFromAccountId,
  turnOffFunding,
  turnOnFunding,
} from './funding.actions';
import { FundingState } from './funding.state';

export const initialState = new FundingState();

const _reducer = createReducer<FundingState>(
  initialState,
  on(turnOnFunding, state => {
    return { ...state, isRunning: true };
  }),
  on(turnOffFunding, state => {
    return { ...state, isRunning: false };
  }),
  on(changeFundingMethod, (state, action) => {
    return { ...state, method: action.payload };
  }),
  on(changeFundingAmount, (state, action) => {
    return { ...state, amount: action.payload };
  }),
  on(setupFundingAccountId, (state, action) => {
    return { ...state, accountId: action.payload };
  }),
  on(setupAccountsToBeFunded, (state, action) => {
    return { ...state, accountsToBeFunded: action.payload };
  }),
  on(setupFundingAccounts, (state, action) => {
    return { ...state, fundingAccounts: action.payload };
  }),
  on(setupFundingFromAccountId, (state, action) => {
    return { ...state, fromAccountId: action.payload };
  }),
  on(fundAccount, state => {
    const accountsToBeFunded = state.accountsToBeFunded.filter(account => account.id !== state.accountId);

    return { ...state, accountsToBeFunded, method: null, accountId: 0, depositAmount: 0 };
  }),
  on(signOut, () => {
    return new FundingState();
  })
);

export function fundingReducer(state: FundingState, action: Action) {
  return _reducer(state, action);
}
