<div *ngIf="isLoadingData">
  <app-view-header>
    <app-dot-loader [showWhen]="isLoadingData" [ngClass]="{ loading: isLoadingData }">
      Loading
    </app-dot-loader>
    <p>Transaction Details</p>
  </app-view-header>
</div>
<app-view-header [titleText]="description" *ngIf="!isLoadingData">
  <p>Transaction Details</p>
</app-view-header>
<div class="container" *ngIf="!isLoadingData">
  <div class="row">
    <button id="backToOverviewBtn" type="button" class="link d-flex align-items-center" (click)="goBackToSDT()">
      <app-svg-icon class="overview__icon mr-2" [iconName]="icons.backIcon" size="1.3em"></app-svg-icon>
      <span class="overview__text">Back to Activity</span>
    </button>
  </div>
  <div class="frow wrap">
    <div class="fcol-cs-12">
      <h2 class="transaction-details text-left">
        Transaction Details
      </h2>
    </div>
  </div>
  <div class="frow">
    <div class="fcol-cs-12">
      <div class="frow content-row">
        <div class="title">Description</div>
        <div class="description">{{ description }}</div>
      </div>
      <div class="frow content-row">
        <div class="title">Date</div>
        <div class="description">
          {{
            openOrder.status.code === orderStatus.ORDER_EXECUTED
              ? (openOrder.createdDate | date: 'MM/dd/yyyy')
              : 'Pending'
          }}
        </div>
      </div>
      <div class="frow content-row">
        <div class="title">Amount</div>
        <div class="description">
          {{ openOrder.expectedPrice * openOrder.quantity | currency: 'USD':'symbol':'1.2-2' }}
        </div>
      </div>
      <div class="frow content-row">
        <div class="title">Category</div>
        <div class="description">{{ openOrder.category.description }}</div>
      </div>
      <div class="frow content-row">
        <div class="title">Asset Symbol</div>
        <div class="description">{{ openOrder.symbol | slice: 0:5 }}</div>
      </div>
      <div class="frow content-row">
        <div class="title">Asset Name</div>
        <div class="description">{{ position.assetName }}</div>
      </div>
      <div class="frow content-row">
        <div class="title">Shares</div>
        <div class="description">{{ openOrder.quantity }}</div>
      </div>
      <div class="frow content-row">
        <!--Price per Share es el ExpectedPrice o ExecutedPrice, depends on if it is filled o not-->
        <div class="title">Price Per Share</div>
        <div class="description">
          {{
            openOrder.status.code === orderStatus.ORDER_EXECUTED
              ? (openOrder.executedPrice | currency: 'USD':'symbol':'1.2-2')
              : 'Pending'
          }}
        </div>
      </div>
      <div class="frow content-row">
        <div class="title">Order Type</div>
        <div class="description">{{ openOrder.orderType }}</div>
      </div>
      <div class="frow content-row">
        <div class="title">Order Filled</div>
        <div class="order-filled-row description">
          <span class="order-status-description">
            {{ orderStatusShortDescriptions[openOrder.status.code] || 'Pending' }}
          </span>
          <div class="order-filled-date" *ngIf="openOrder.status.code === orderStatus.ORDER_EXECUTED">
            {{ openOrder.executedDate | date: 'MM/dd/yyyy' }} at {{ openOrder.executedDate | date: 'h:mm a' }}
          </div>
          <div class="order-filled-date" *ngIf="openOrder.status.code !== orderStatus.ORDER_EXECUTED && orderStatusShortDescriptions[openOrder.status.code]">
            {{ openOrder.createdDate | date: 'MM/dd/yyyy' }} at {{ openOrder.createdDate | date: 'h:mm a' }}
          </div>
        </div>
      </div>
      <div class="frow content-row">
        <div class="title">Account</div>
        <div class="description">{{ tradingAccount.nickname }} - {{ tradingAccount.accountNumber | slice: -4 }}</div>
      </div>
    </div>
  </div>
</div>
