import * as angular from 'angular';
import { IFilterService, IRootScopeService, ISCEService, IWindowService } from 'angular';
import { IStateService } from 'angular-ui-router';
import * as moment from 'moment';

import { Inject } from '../decorators/decorators';
import { ModalService } from '../services/modal.service';
import { PaymentsService } from '../services/payments.service';
import { MakeAPayment } from '../transfers/receipt/typings/ReceiptConstants';
import { RecurrentPaymentInfo } from '../typings/app/bills/RecurrentPaymentInfo';
import { UserAccountType } from '../typings/app/bills/UserAccountType';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Inject(
  '$window',
  '$sce',
  '$filter',
  '$state',
  '$rootScope',
  'paymentsService',
  'serviceHelper',
  'modalService',
  'featureFlagService',
  'popups'
)
export class PayBillsHelper {
  static invalidEmail = 'invalid email';
  readonly oneTime = 'One Time';
  private readonly storage: Storage;
  private readonly enrolled = 'isEnrolled';
  private readonly endOn = ['When I cancel', 'Number of Payments'];

  constructor(
    private readonly windowService: IWindowService,
    private readonly sceService: ISCEService,
    private readonly filterService: IFilterService,
    private readonly stateService: IStateService,
    private readonly rootScopeService: IRootScopeService,
    private readonly paymentsService: PaymentsService,
    private readonly serviceHelper: IServiceHelper,
    private readonly modalService: ModalService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly popups: IPopups
  ) {
    this.storage = this.windowService.sessionStorage;
  }

  /** Returns true if no attempt to enroll the use has been made, false otherwise */
  needEnrollment(): boolean {
    return !this.storage.getItem(this.enrolled);
  }

  /** Sets the flag that indicates if the user is enrolled in payveris as true */
  setEnrolledInBillPay() {
    this.storage.setItem(this.enrolled, 'true');
  }

  /** Returns true if the user is enrolled y payveris, false otherwise */
  isEnrolledInBillPay(): boolean {
    const enrollmentAttempt = this.storage.getItem(this.enrolled);

    return enrollmentAttempt && enrollmentAttempt === 'true';
  }


  /**
   * Verifies if the user has been enrolled in Bill payee, if so, redirects to the make payments page
   * otherwise enrolls the user
   */
  enrollInBillPay(callback: Function) {
    let isEnrolled = false;
    let isInvalidEmail = false;

    this.paymentsService
      .enrollUser()
      .then(() => {
        isEnrolled = true;
        this.removeInvalidEmail();
      })
      .catch(reason => {
        isEnrolled = false;
        isInvalidEmail = this.isInvalidPayverisEmail(reason.data.message);
        if (!isInvalidEmail) {
          this.serviceHelper.errorHandler(reason);
        }
      })
      .finally(() => {
        this.storage.setItem(this.enrolled, isEnrolled.toString());
        callback(isEnrolled, isInvalidEmail);
      });
  }

  /**
   * Verifies if the selected date is valid for expedite a payment
   * @param date the date selected for processing the expedited payment
   * @param minValidDate The recommended/default date
   */
  isExpediteValidDate(date: Date | moment.Moment, minValidDate: moment.Moment): boolean {
    const limitDate = angular.copy(minValidDate).hour(10).minute(45);

    return (
      moment().isSame(date, 'day') ||
      (moment(date).isAfter(moment(), 'day') && moment(date).hour(0).minute(0).isBefore(limitDate))
    );
  }

  /**
   * Gets the account selected based on its account code.
   * @param accountsFrom Array with all the accounts
   * @param defaultAccountCode Account code to search
   */
  getSelectedAccount(accountsFrom: UserAccountType[], defaultAccountCode: string): UserAccountType {
    let result: UserAccountType = null;

    const accountsFromFilter = accountsFrom.filter((accountFrom: UserAccountType) => {
      return accountFrom.accountCode === defaultAccountCode;
    });

    if (accountsFromFilter && accountsFromFilter.length > 0) {
      result = accountsFromFilter.shift();
    }

    return result;
  }

  /**
   * Gets the given account's nickname or the account's formatted string if nickname's not available
   * @param account account details to format the account's label
   */
  getAccountLabel(account: UserAccountType): string {
    return account.nickName || `${account.accountType} ${this.getAccountMask(account)}`;
  }

  /**
   * Gets the most recent bill statement of the eBill sent as argument
   * @param eBillId The unique identifier of the eBill
   */
  getEBillStatement(eBillId: number) {
    this.paymentsService
      .getEBillStatement(eBillId)
      .then((res: any) => {
        const file = new Blob([res], { type: 'application/pdf' });

        // Fallback for IE
        if (this.windowService.navigator.msSaveOrOpenBlob) {
          this.windowService.navigator.msSaveOrOpenBlob(file, 'statement.pdf');
        } else {
          const fileURL = URL.createObjectURL(file);
          this.sceService.trustAsResourceUrl(fileURL);
          this.windowService.open(fileURL, '_blank');
        }
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }

  showExpediteTermsAndConditions() {
    return this.modalService.show(
      { windowClass: 'modal-service-md' },
      {
        hasIcon: false,
        hasHeaderText: true,
        hasClose: true,
        headerText: 'Expedite Payments',
        bodyText: `<h3>How It Works?</h3>
				<p>Requests for expedited checks received before 10:45 am PST on business days are
					express mailed the same day. Requests after this cutoff time are express mailed
					the next business day.   Please note that regular check payments are mailed within 5-7 days.
				</p>
				<br>
				<h3>Terms & Conditions</h3>
				<p>I authorize you to deduct a fee of $19.95 from my “pay from” account for this payee.
					I agree to the terms and conditions associated with the collection of this fee.
					I understand that my payment will be sent by check for overnight delivery.
				</p>
				<br>
				<p class="uppercase">Important: This payment will be processed immediately and cannot be updated or cancelled once requested.</p>
				<br>
				<p>By pressing Accept below, I am agreeing to these Terms & Conditions.</p>`,
      }
    );
  }

  /**
   * Stores/Removes in sessionStorage if the email for payveris is correct and
   * returns true if invalid false otherwise
   * @param message A string message containing the server response
   */
  isInvalidPayverisEmail(message: string): boolean {
    if (message.toLowerCase() === PayBillsHelper.invalidEmail) {
      this.storage.setItem(PayBillsHelper.invalidEmail, 'true');

      return true;
    }

    this.removeInvalidEmail();

    return false;
  }

  /** Removes the flag indicating that the email is invalid */
  removeInvalidEmail() {
    this.storage.removeItem(PayBillsHelper.invalidEmail);
  }

  /** Returns if the email is stored */
  isInvalidEmail(): boolean {
    return !!this.storage.getItem(PayBillsHelper.invalidEmail);
  }

  /** Returns if is allowed to create expedite payments */
  isExpeditePaymentAllowed(): boolean {
    return false;
  }

  /**
   * Handles the exception retrieved whenever a payment configuration fails
   * @param title Popup Title
   * @param action Popup action message
   * @param error Error object
   */
  handlePaymentException(title: string, action: string, error: ApiError) {
    if (error.status === 400) {
      this.serviceHelper.errorHandler(error);

      return;
    }

    this.popups.showAlert(
      title,
      `We could not ${action} at this time. Please try again later. If you continue to receive this message, please contact customer service`,
      'error'
    );
  }

  /** Adds a recurring payment to a biller
   * @param recurrentPayment
   * @param uibModalInstance
   * @param displayName
   * @param popupTitle
   * @param action
   */
  addScheduledPayment(
    recurrentPayment: RecurrentPaymentInfo,
    uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    displayName: string,
    popupTitle: string,
    action: string
  ): void {
    recurrentPayment.repeatUntilCanceled = recurrentPayment.endOn === 0;
    recurrentPayment.endOnNumberOfPayments = !recurrentPayment.repeatUntilCanceled;
    recurrentPayment.daysBeforeProcessingAlert = recurrentPayment.daysBeforeProcessingAlert || 0;
    this.paymentsService
      .addScheduledPayment(recurrentPayment)
      .then(result => {
        const schedule = angular.copy(MakeAPayment);
        const details = schedule.transactions[0].details;
        schedule.header = action;
        details[0].value = recurrentPayment.accountLabel || recurrentPayment.accountCode;
        details[1].value = displayName;
        details[2].value = this.filterService('currency')(recurrentPayment.amount);
        details[3].value = moment(recurrentPayment.processingDate).format('MM/DD/YYYY');
        details[4].value =
          typeof recurrentPayment.endOn !== undefined ? this.endOn[recurrentPayment.endOn] : '';
        details[5].value = mapFrequency(recurrentPayment.paymentFrequency) || this.oneTime;
        details[6].value = recurrentPayment.memo;
        schedule.header = schedule.header.replace(
          '{frequencyMsg}',
          details[5].value === this.oneTime ? 'a one-time' : 'recurring'
        );

        const receiptSettings = {
          amount: this.filterService('currency')(recurrentPayment.amount),
          fromAccount: recurrentPayment.accountCode,
          toAccount: `to ${displayName}`,
          confirmationEmail: '',
          confirmationNumber: result.data[0].confirmationNumber,
          transactions: [{ confirmationNumber: result.data[0].confirmationNumber, details }],
          additionalFootnote: '',
          navigateTo: 'udb.billPay.pay',
        };

        this.stateService.go('udb.myBills.receipt', {
          settings: receiptSettings,
          template: schedule,
        });
      })
      .catch(err => {
        action = action.replace('{frequencyMsg}', '').replace('{toAccount}', '');
        this.handlePaymentException(popupTitle, action, err);
      })
      .finally(() => {
        uibModalInstance.close({ result: true });
      });
  }

  /** Edits a scheduled payment.
   * @param recurrentPayment,
   * @param uibModalInstance,
   * @param popupTitle,
   * @param action
   */
  updateScheduledPayment(
    recurrentPayment: RecurrentPaymentInfo,
    uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    popupTitle: string,
    action: string
  ): void {
    this.paymentsService
      .updateScheduledPayment(recurrentPayment)
      .then(() => {
        this.stateService.go(
          'udb.myBills.scheduledPayments',
          {},
          { reload: 'udb.myBills.scheduledPayments' }
        );
      })
      .catch(err => {
        this.handlePaymentException(popupTitle, action, err);
      })
      .finally(() => {
        uibModalInstance.close({ result: true });
        this.paymentsService.clearCache();
      });
  }

  /** Gets all the payments due */
  setPaymentsDue(): void {
    this.paymentsService
      .getPayeeInfo(true)
      .then(({ data }) => {
        this.rootScopeService.$broadcast('numberPaymentsDue', data.length);
      })
      .catch(this.serviceHelper.errorHandler);
  }

  /**
   * Get the last 4 numbers of an account number
   * @param account account to take the account number
   */
  private getAccountMask(account: UserAccountType): string {
    return `**${account.accountNumber.substring(account.accountNumber.length - 4)}`;
  }

  isSBBActive(): boolean {
    return this.featureFlagService.isSBBActive();
  }
  
  isIPaySsoActive(): boolean {
    return this.featureFlagService.isIPaySsoActive();
  }
}

export enum Frequency {
  Weekly = 1,
  EveryTwoWeeks = 2,
  EveryFourWeeks = 4,
  Monthly = 5,
  SemiMonthly = 3,
  EveryTwoMonths = 6,
  Quarterly = 7,
  EveryFourMonths = 8,
  BiAnnual = 9,
  Annually = 10,
}

export function mapFrequency(frequency: number): string {
  switch (frequency) {
    case Frequency.Weekly:
      return 'Weekly';
    case Frequency.EveryTwoWeeks:
      return 'Every 2 Weeks';
    case Frequency.EveryFourWeeks:
      return 'Every 4 weeks';
    case Frequency.Monthly:
      return 'Monthly';
    case Frequency.SemiMonthly:
      return 'Twice a month (Semi Monthly - 1st and the 15th)';
    case Frequency.EveryTwoMonths:
      return 'Every two months';
    case Frequency.Quarterly:
      return 'Quarterly';
    case Frequency.EveryFourMonths:
      return 'Every 4 months';
    case Frequency.BiAnnual:
      return 'Every 6 months';
    case Frequency.Annually:
      return 'Yearly';
    default:
      return '';
  }
}
