import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import { addUpdateTotalValueAction } from '../total-value-tile.actions';
import { initialTotalValueState, TotalValueState } from '../types';

const reducer = createReducer(
  initialTotalValueState,
  on(addUpdateTotalValueAction, (_state, action) => {
    const indexOfUpdated = _state.accountsTotalValues.findIndex(m => m.accountNumber === action.payload.accountNumber);
    if (indexOfUpdated >= 0) {
      return {
        accountsTotalValues: _state.accountsTotalValues.map(item => {
          return item.accountNumber === action.payload.accountNumber ? (item = action.payload) : item;
        }),
        selectedAccountTotalValues: action.payload.accountNumber,
      };
    } else {
      return {
        accountsTotalValues: [..._state.accountsTotalValues, action.payload],
        selectedAccountTotalValues: action.payload.accountNumber,
      };
    }
  }),
  on(signOut, () => {
    return initialTotalValueState;
  })
);

export function totalValueReducer(state: TotalValueState, action: Action) {
  return reducer(state, action);
}
