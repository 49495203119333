import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import { addUpdateModelAccountAction, selectSortingOption } from '../models.actions';
import { initialModelState, ModelsState } from '../types';
import { dynamicSortModelsAccount } from '../functions/dynamic-sort-models-account/dynamic-sort-models-account.function';
import { getKeyByValueFromModel, sortingFamilyFunctions } from '../functions';
import { ModelSortTypes } from '../../const';
import { lowerFirst } from 'lodash';
const reducer = createReducer(
  initialModelState,
  on(addUpdateModelAccountAction, (state, action) => {
    const indexOfUpdated = state.accountsModels.findIndex(m => m.accountNumber === action.payload.accountNumber);
    const sortBy = getKeyByValueFromModel(
      action.payload.sortType,
      ModelSortTypes,
      key => lowerFirst(key),
      'portfolioPercent'
    );
    const sortFn =
      action.payload.models.length > 0 && action.payload.isSortingEnabled
        ? dynamicSortModelsAccount(sortBy, action.payload.sortDirection, typeof action.payload.models[0][sortBy])
        : sortingFamilyFunctions.default('portfolioPercent').Descending;

    if (indexOfUpdated >= 0) {
      return {
        accountsModels: state.accountsModels.map(item => {
          return item.accountNumber === action.payload.accountNumber
            ? {
                ...action.payload,
                models: action.payload.models.sort(sortFn),
                sortOptions: action.payload.sortOptions,
              }
            : item;
        }),
        selectedAccountModels: action.payload.accountNumber,
      };
    } else {
      return {
        accountsModels: [...state.accountsModels, action.payload].map(modelTile => {
          return modelTile.accountNumber === action.payload.accountNumber
            ? {
                ...modelTile,
                models: modelTile.models.sort(sortFn),
              }
            : modelTile;
        }),
        selectedAccountModels: action.payload.accountNumber,
      };
    }
  }),
  on(selectSortingOption, (state, action) => {
    return {
      ...state,
      accountsModels: state.accountsModels.map(accountModels => {
        if (accountModels.accountNumber === action.payload.accountNumber && accountModels.models.length >= 1) {
          const sortBy = getKeyByValueFromModel(
            action.payload.option.sortType,
            ModelSortTypes,
            key => lowerFirst(key),
            'portfolioPercent'
          );
          const sortFn = dynamicSortModelsAccount(
            sortBy,
            action.payload.option.sortDirection,
            typeof accountModels.models[0][sortBy]
          );
          const models = [...accountModels.models].sort(sortFn);
          const sortOptions = [...accountModels.sortOptions].map(option => {
            return {
              ...option,
              isSelected: option.value === action.payload.option.value,
            };
          });
          return {
            ...accountModels,
            models,
            sortOptions,
          };
        }
        return accountModels;
      }),
    };
  }),
  on(signOut, () => initialModelState)
);

export function modelsReducer(state: ModelsState, action: Action) {
  return reducer(state, action);
}
