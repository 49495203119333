<div id="udb-transactions" class="transactions-details-agg">
  <div class="transactions-content">
    <div
      class="transactions-details-agg__header"
      *ngIf="isAccountAggregationForInternalsActive"
    >
      <div class="desc-cont">
        <span class="desc">Transactions</span>

        <div class="download-cont">
          <!-- Download file from account-->
          <span
            class="download"
            [attr.disabled]="isDownloading"
            (click)="downloadTransactionsFile()"
          >
            <i class="bofi-download tx-icon"></i>
            {{ isDownloading ? 'Downloading...' : 'Download Transactions' }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="tx-aggregation-filters"
      *ngIf="isAccountAggregationForInternalsActive"
    >
      <div class="tx-aggregation-filters__group row row-no-gutters">
        <!-- Categories button -->
        <app-category-filter
          class="col-lg-2 col-md-3 col-sm-3 col-xs-6"
          (onValueChange)="setFilterValue($event.value, 'categories')"
        ></app-category-filter>

        <!-- insights button -->
        <div
          class="insights col-lg-2 col-md-3 col-sm-3 col-xs-6 border-right"
          *ngIf="!shouldHideInsights"
        >
          <button
            type="button"
            class="uk-btn sm solid insights insights-button test-class"
            [ngClass]="{ 'insights-button--active': isInsightsOpen }"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="isInsightsOpen = !isInsightsOpen"
          >
            <div
              class="category-btn--icon insights-svg"
              [ngClass]="{ 'insights-svg--active': isInsightsOpen }"
              [innerHTML]="insightsIcon"
            ></div>
            <span>Insights</span>
          </button>
        </div>

        <!--Search Transactions-->
        <app-tx-filter
          class="tx-aggregation-filters__item--search col-sm-6 col-xs-12"
          type="input"
          [value]="filters.query"
          [placeholder]="'Search Transactions'"
          [iconPlaceholder]="'search'"
          [isDisabled]="isLoading"
          [filterName]="'query'"
          (onValueChange)="setFilterValue($event.value, $event.filterName)"
        ></app-tx-filter>
        <!-- Filters button -->
        <div class="col-lg-2 col-md-12 filter--btn--container" ng-hide="">
          <button
            type="button"
            class="uk-btn transactions-details-agg--btn-filter hide-filter transactions-details-agg--btn-filter--custom"
            [ngClass]="{
              'transactions-details-agg--btn-filter--active': !!filtersApplied.length
            }"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="displayFilters = !displayFilters"
          >
            <span
              class="transactions-details-agg--btn-filter--svg"
              [ngClass]="{
                'transactions-details-agg--btn-filter--svg--active': !!filtersApplied.length
              }"
              [innerHTML]="filtersIcon"
            ></span>
            <span>Filters</span>
          </button>
        </div>
      </div>
      <app-tx-aggregation-filters
        *ngIf="isAccountAggregationForInternalsActive"
        [account]="account"
        [filters]="boundFilters"
        [isLoading]="isLoading"
        [inModal]="false"
        [displayFilters]="displayFilters"
        (onFiltersChange)="changeFilters($event.filters, $event.filtersApplied)"
        (onClearFilter)="clearFilter($event.filterName)"
      >
        <!-- html layout transcluded -->
        <div class="row">
          <div class="col-lg-2 col-md-12">
            <button
              type="button"
              style="width: 100%;"
              class="transactions-details-agg--btn-filter transactions-details-agg--btn-filter--custom visibility uk-btn"
              [ngClass]="{
                'transactions-details-agg--btn-filter--active': !!filtersApplied.length
              }"
              (click)="toggleFilterModal()"
            >
              <span
                class="transactions-details-agg--btn-filter--svg"
                [ngClass]="{
                  'transactions-details-agg--btn-filter--svg--active': !!filtersApplied.length
                }"
                ng-include="'/img/icons/filters/filter-white.svg'"
              ></span>
              <span>Filters</span>
            </button>
          </div>
        </div>
      </app-tx-aggregation-filters>
    </div>

    <!-- modal container -->
    <app-tx-modal-filters
      *ngIf="showModal"
      [account]="account"
      [filters]="boundFilters"
      [isAggregated]="isAccountAggregationForInternalsActive"
      (onApplyFilters)="changeFilters($event.filters, $event.filtersApplied)"
      (onCloseModal)="toggleFilterModal()"
    ></app-tx-modal-filters>

    <app-transaction-tags
      [filters]="filters"
      (tagRemoved)="clearFilter($event.filterName)"
    ></app-transaction-tags>

    <!-- PFM enabled, new implementation. -->
    <app-insights-container
      *ngIf="isInsightsOpen && !isLoading && isPfm2Enabled"
      [data]="filteredTx"
      [(isOpen)]="isInsightsOpen"
      (onClose)="isInsightsOpen = false"
      class="pfm2"
    >
      <app-chart-selection [chartTypes]="insightsChartTypes">
        <!-- Dummy attribute 'first' and 'second', nedded to match selector in ng-element -->
        <div first class="chart-container">
          <div class="row">
            <div class="expenses-section">
              <app-insights-expenses-grid
                style="display: block;"
                [transactionsWithAllCategories]="filteredTxWithAllCategories"
                [account]="account"
                [filters]="filters"
                (toggleCategoryFilter)="toggleCategoryFilter($event)"
              ></app-insights-expenses-grid>
            </div>
            <div class="income-section">
              <app-income
                [transactionsWithAllCategories]="filteredTxWithAllCategories"
                [filters]="filters"
                (toggleCategoryFilter)="toggleCategoryFilter($event)"
              ></app-income>
            </div>
          </div>
        </div>
        <div second class="chart-container">
          <app-insights-chart
            [isPfm2Active]="isPfm2Enabled"
            [transactions]="filteredTx"
            [account]="account"
          ></app-insights-chart>
        </div>
      </app-chart-selection>
    </app-insights-container>

    <!-- PFM disabled, legacy implementation. -->
    <app-insights-container
      *ngIf="isInsightsOpen && !isLoading && !isPfm2Enabled"
      [data]="filteredTx"
      [isOpen]="isInsightsOpen"
      (onClose)="isInsightsOpen = false"
      class="not-pfm2"
    >
      <app-insights-line-chart
        [transactions]="filteredTx"
      ></app-insights-line-chart>
    </app-insights-container>

    <app-tx-table
      [account]="account"
      [accountDetails]="accountDetails"
      [filters]="filters"
      (onLoaded)="
        onUpdateTransactions(
          $event.transactions,
          $event.transactionsWithAllCategories
        )
      "
      (onGetCheck)="getCheck($event)"
      (onSorted)="setSortValues($event.orderBy, $event.reverse)"
      (isOver2000)="onOver2000($event)"
      (savedFiltersLoaded)="onSaveFiltersLoaded($event)"
      (recategorizeEvent)="onRecategorizeEvent()"
    ></app-tx-table>

    <div *ngIf="showMoreInfo" class="text-center">
      <span class="section">
        For more transaction history, please refer to
        <a class="link" (click)="notifyNextPaymentTabIsActivated()">
          your statements
        </a>
      </span>
    </div>
  </div>
</div>
