import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Inject,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  Recipient,
  Credential,
  VerifyEBillResponse,
  VerifyEBillLogin,
  EBillLoginResponse,
} from '@app/bill-pay/typings';
import { BillPayRouterHelper } from '@app/bill-pay/utils';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { STATE, STATE_PARAMS } from '@core/tokens';
import {
  BILLPAYHELPER,
  BILLPAYROUTERHELPER,
  BILLPAYSERVICE,
  SERVICEHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';

@Component({
  selector: 'app-e-statements',
  templateUrl: './e-statements.component.html',
  styleUrls: ['./e-statements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class EStatementsComponent implements OnInit, OnChanges {
  recipient: Recipient;
  recipientId: number;
  sessionId: string;
  currentStep: number;
  sites: BillerSite[];
  credentials: Credential[];
  errorMessage: string;
  subtitle: string;

  isLoading: boolean;
  displayInfo: boolean;

  private addEBillResponse: VerifyEBillResponse;
  private selectBillerSite: SelectBillerSite = {};
  private verifyLoginInfo: VerifyEBillLogin = {};
  private verifyLoginResponse: EBillLoginResponse;

  constructor(
    @Inject(BILLPAYSERVICE) private readonly billPayService: IBillPayService,
    @Inject(STATE) private readonly stateService: IStateService,
    @Inject(STATE_PARAMS) private readonly stateParams: IStateParamsService,
    @Inject(SERVICEHELPER) private readonly serviceHelper: IServiceHelper,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper,
    @Inject(BILLPAYHELPER) private readonly billPayHelper: BillPayHelper,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.displayInfo = false;
    this.recipientId = this.stateParams['recipientId'];
    this.recipient = this.billPayRouterHelper.getRecipientDetails();
    this.credentials = this.billPayRouterHelper.getCredentials();
    if (this.recipient && this.credentials) {
      // In case it comes from ResolveEBills flow
      this.sessionId = this.billPayRouterHelper.getSessionId();
      this.currentStep = 1;
      this.subtitle = `Please log in to your ${this.recipient.displayName} account. This will allow us to obtain eBills.`;
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    } else if (!this.recipientId) {
      this.stateService.go('udb.billPay.pay');
    } else {
      this.getRecipientById(this.recipientId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentStep) {
      this.serviceHelper.scrollToTop();
    }
  }

  /**
   * Selects a site for a biller - Step 0
   */
  selectEBillSite(site: BillerSite): void {
    this.isLoading = true;
    this.selectBillerSite.site = site;
    this.selectBillerSite.sessionId = this.sessionId;
    this.billPayService
      .selectEBillSite(this.selectBillerSite)
      .then(response => {
        this.sessionId = response.data.manageEBillsSessionId;
        if (response.data.credentials != null && response.data.credentials.length > 0) {
          this.credentials = this.addEBillResponse.credentials = response.data.credentials;
          this.currentStep = 1;
          this.subtitle = `Please log in to your ${this.recipient.displayName} account. This will allow us to obtain eBills.`;
        }
      })
      .catch(err => {
        this.errorMessage = err.data.message;
      })
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  /**
   * Verifies if the credentials were provided correctly - Step 1
   */
  verifyEBillLogin(credentials: Credential[]): void {
    this.isLoading = true;
    this.verifyLoginInfo.credentials = credentials;
    this.verifyLoginInfo.sessionId = this.sessionId;
    this.billPayService
      .verifyEBillLogin(this.verifyLoginInfo)
      .then(response => {
        this.verifyLoginResponse = response.data;
        const timeout =
          new Date(this.verifyLoginResponse.callBackTime).getTime() -
          new Date(this.verifyLoginResponse.timestamp).getTime();
        setTimeout(() => {
          this.billPayHelper
            .checkForFurtherActions(this.verifyLoginResponse)
            .then(() => {
              this.isLoading = false;
              this.changeDetectorRef.markForCheck();
            })
            .catch(err => {
              this.isLoading = false;
              this.changeDetectorRef.markForCheck();
              this.errorMessage = !err.data && err.message ? err.message : err.data.message;
            });
        }, timeout);
      })
      .catch(err => {
        this.errorMessage = err.data.message;
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  //#endregion

  toggleInfo(): void {
    this.displayInfo = !this.displayInfo;
  }

  goBack(): void {
    history.back();
  }

  cancelSetUp() {
    this.billPayRouterHelper.cancelSetup();
  }

  private getRecipientById(recipientId: number): void {
    this.billPayService
      .getRecipient(recipientId)
      .then(response => {
        this.recipient = response.data;
        this.billPayRouterHelper.setRecipientDetails(this.recipient);
        // validate if the recipient is eligible for eStatements
        if (this.recipient.eBillsStatus == 1) {
          this.addEBill(this.recipient.payeeId);
        } else {
          this.stateService.go('udb.billPay.pay');
        }
      })
      .catch(err => {
        this.errorMessage = err.data.message;
      })
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }

  //#region Steps

  /**
   * First step to activate an eBill, could return a list of sites or credentials
   */
  private addEBill(recipientId: number): void {
    this.billPayService
      .addEBill(recipientId)
      .then(response => {
        this.addEBillResponse = response.data;
        this.sessionId = this.addEBillResponse.manageEBillsSessionId;
        this.sites = this.addEBillResponse.sites;
        this.credentials = this.addEBillResponse.credentials;
        if (this.sites != null && this.sites.length > 0) {
          this.currentStep = 0;
          this.subtitle = `Please select the website you use to log in to your ${this.recipient.displayName} account. You will sign in on the next page.`;
        } else {
          this.currentStep = 1;
          this.subtitle = `Please log in to your ${this.recipient.displayName} account. This will allow us to obtain eBills.`;
        }
      })
      .catch(err => {
        this.errorMessage = err.data.message;
      })
      .finally(() => {
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      });
  }
}
