import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AccountsService } from '@core/services';
import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { StopPaymentService } from '@legacy/services/stop-payment.service';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { StopPayment } from '@legacy/typings/app/StopPayment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-stop-payment-extend',
  templateUrl: './stop-payment-extend.component.html',
  styleUrls: ['./stop-payment-extend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopPaymentExtendComponent implements OnInit, OnDestroy {
  accountId: number;
  seqId: number;
  extendFee: number;
  spExtend: StopPayment = {};
  accountName: string;
  extendDate: Date = new Date();
  expirationTime: number = 6;
  isBusy: boolean = false;
  terms: string;
  private listeners: Function[] = [];
  private effectiveDate: moment.Moment;

  constructor(
    @Inject(STATE_PARAMS) private readonly params: ng.ui.IStateParamsService,
    private readonly stopPaymentService: StopPaymentService,
    private readonly serviceHelper: ServiceHelper,
    @Inject(ROOT_SCOPE) private readonly rootScope: ng.IRootScopeService,
    private readonly accountService: AccountsService,
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject('$scope') private readonly scope: ng.IScope,
    private readonly dateHelper: DateHelper,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.seqId = this.params['seq'];
    this.spExtend = this.params['spExtend'];

    if (this.rootScope['brandProperties']) {
      this.terms = this.rootScope['brandProperties']['TermsStopPaymentURL'];
    }

    if (!this.spExtend) {
      this.state.go('udb.accounts.stop-payment', {
        id: this.accountId,
      });
    } else {
      this.getSettingsConfig();

      this.listeners.push(
        this.scope.$on('brandPropertiesLoaded', () => {
          this.terms = this.rootScope['brandProperties']['TermsStopPaymentURL'];
        })
      );

      this.effectiveDate = this.dateHelper.isValidDate(moment()) ? moment() : this.dateHelper.addWeekdays(moment(), 1);

      this.extendDate = moment(this.effectiveDate).add(this.expirationTime, 'months').toDate();

      this.accountService
        .getAccount(this.accountId.toString())
        .pipe(
          finalize(() => {
            this.changeDetectorRef.detectChanges();
          })
        )
        .subscribe({
          next: res => {
            this.accountName = res.data.nickname || res.data.name;
            this.extendFee = res.data.stopPayFee;
          },
          error: this.serviceHelper.errorHandler,
        });
    }
  }

  ngOnDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }
  extend(): void {
    this.isBusy = true;
    this.spExtend.effectiveDate = this.effectiveDate?.toDate();
    this.spExtend.expirationDate = this.extendDate;
    this.spExtend.accountId = this.accountId;
    this.spExtend.stopCharge = this.extendFee;
    this.stopPaymentService
      .extendStopPayment(this.spExtend)
      .then(() => {
        this.state.go('udb.accounts.stop-payment-success', {
          id: this.accountId,
          seq: this.seqId,
          stopPayment: this.spExtend,
          actionMsg: 'extended',
        });
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isBusy = false;
      });
  }

  redirectToPage(paramAccountId: any): void {
    this.state.go('udb.accounts.stop-payment', {
      id: paramAccountId,
    });
  }

  private getSettingsConfig(): void {
    this.expirationTime = +this.rootScope['appSettings'].find((ap: any) => ap.name === 'StopPaymentAddTime').value;
  }
}
