// Use this file to export all your components.
// Then they can be imported on other files like this. E.g.
// import { MyComponent } from '@app/accounts/components';

import { AccountBannerComponent } from './account-banner/account-banner.component';
import { AccountBeneficiariesComponent } from './account-beneficiaries/account-beneficiaries.component';
import { InvestorCheckingAccountFeaturesComponent } from './investor-checking-account-features/investor-checking-account-features.component';
import { AccountSelectComponent } from './account-select/account-select.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { DepositInterestComponent } from './deposit-interest/deposit-interest.component';
import { DisputeDetailsComponent } from './dispute-details/dispute-details.component';
import { DisputeReviewComponent } from './dispute-review/dispute-review.component';
import { DisputeSubmitComponent } from './dispute-submit/dispute-submit.component';
import {
  InternalToExternalComponent,
  modalsDependencies,
  sharedModals,
} from './modals';
import { OpenAccountItemComponent } from './open-account-item/open-account-item.component';
import { OpenAccountItemsContainerComponent } from './open-account-items-container/open-account-items-container.component';
import { RewardsCriteriaComponent } from './rewards/rewards-criteria/rewards-criteria.component';
import { RewardsComponent } from './rewards/rewards.component';
import { StatementTaxFormsComponent } from './statement-tax-forms/statement-tax-forms.component';
import {
  StopPaymentAddComponent,
  StopPaymentComponent,
  StopPaymentExtendComponent,
  StopPaymentRemoveComponent,
  StopPaymentSuccessComponent,
} from './stop-payment';
import {
  DashboardAccountsComponent,
  InsightsContainerComponent,
  InsightsLineChartComponent,
} from './dashboard-accounts';
import { LoanPayoffIframeComponent } from './loan-payoff-iframe/loan-payoff-iframe.component';
import { DownloadTransactionsComponent } from './download-transactions/download-transactions.component';
import { DownloadFormComponent } from './download-transactions/download-form/download-form.component';
import { PeriodOptionsComponent } from './download-transactions/download-form/period-options/period-options.component';
import { ModalErrorComponent } from './modals/download-transactions/modal-error/modal-error.component';
import {
  AccountTransactionsComponent,
  TxAggregationFiltersComponent,
  TxFilterComponent,
  TxFooterMultipleComponent,
  TxModalFiltersComponent,
  TxOptionsComponent,
  TxRowAggComponent,
  TxRowComponent,
  TxTableComponent,
} from './account-transactions';
import { AccountGroupComponent } from './account-group/account-group.component';

export {
  AccountBannerComponent,
  AccountBeneficiariesComponent,
  AccountSelectComponent,
  BeneficiaryComponent,
  DepositInterestComponent,
  DisputeDetailsComponent,
  DisputeReviewComponent,
  DisputeSubmitComponent,
  OpenAccountItemComponent,
  OpenAccountItemsContainerComponent,
  RewardsComponent,
  RewardsCriteriaComponent,
  StatementTaxFormsComponent,
  InvestorCheckingAccountFeaturesComponent,
  TxFilterComponent,
  TxModalFiltersComponent,
  TxTableComponent,
  TxAggregationFiltersComponent,
  TxRowAggComponent,
  TxRowComponent,
  TxOptionsComponent,
  TxFooterMultipleComponent,
  AccountTransactionsComponent,
  DashboardAccountsComponent,
  InsightsLineChartComponent,
  InsightsContainerComponent,
  LoanPayoffIframeComponent,
  StopPaymentRemoveComponent,
  StopPaymentSuccessComponent,
  StopPaymentComponent,
  AccountGroupComponent,
  StopPaymentExtendComponent,
  StopPaymentAddComponent,
  DownloadTransactionsComponent,
  DownloadFormComponent,
  PeriodOptionsComponent,
  ModalErrorComponent,
  InternalToExternalComponent,
};

export const sharedComponents = [
  AccountBannerComponent,
  AccountBeneficiariesComponent,
  AccountSelectComponent,
  BeneficiaryComponent,
  DepositInterestComponent,
  DisputeDetailsComponent,
  DisputeReviewComponent,
  DisputeSubmitComponent,
  OpenAccountItemComponent,
  OpenAccountItemsContainerComponent,
  RewardsComponent,
  RewardsCriteriaComponent,
  StatementTaxFormsComponent,
  InvestorCheckingAccountFeaturesComponent,
  StopPaymentRemoveComponent,
  StopPaymentSuccessComponent,
  StopPaymentComponent,
  InvestorCheckingAccountFeaturesComponent,
  DashboardAccountsComponent,
  InsightsLineChartComponent,
  InsightsContainerComponent,
  TxFilterComponent,
  TxModalFiltersComponent,
  TxTableComponent,
  TxAggregationFiltersComponent,
  AccountGroupComponent,
  DownloadTransactionsComponent,
  DownloadFormComponent,
  PeriodOptionsComponent,
  ModalErrorComponent,
  TxRowAggComponent,
  TxRowComponent,
  TxOptionsComponent,
  TxFooterMultipleComponent,
  AccountTransactionsComponent,
  StopPaymentExtendComponent,
  StopPaymentAddComponent,
  AccountGroupComponent,
  StopPaymentExtendComponent,
  StopPaymentAddComponent,
  DownloadTransactionsComponent,
  DownloadFormComponent,
  PeriodOptionsComponent,
  ModalErrorComponent,
  InternalToExternalComponent,
  ...sharedModals,
  ...modalsDependencies,
];

export const standaloneComponents = [LoanPayoffIframeComponent];
