import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { AlertsIcons } from '@shared/enums';
import { IStateParamsService } from 'angular-ui-router';
import { LearnMoreTransfersModalComponent } from '../modals';

@Component({
  selector: 'app-transfer-funds-container',
  templateUrl: './transfer-funds-container.component.html',
  styleUrls: ['./transfer-funds-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferFundsContainerComponent implements OnInit {
  showError = false;
  badRequestError: string;
  fromAccountId: number;
  toAccountId: number;
  isMoveMoneyFlow: false;
  step = 1;
  questionIcon = AlertsIcons.QuestionCircle;

  private _toParams: any;
  private _toState: string;
  private _allowRedirection: boolean;

  constructor(
    @Inject('$scope') private scope: ng.IScope,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private stateParams: IStateParamsService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._allowRedirection = false;
    this._toParams = {};
    this._toState = '';
    this.fromAccountId = this.stateParams['fromAccountId'];
    this.toAccountId = this.stateParams['toAccountId'];
    this.isMoveMoneyFlow = this.stateParams['isMoveMoneyFlow'];

    this.scope.$on('$stateChangeStart', (event, _toState, _toParams, _fromState) => {
      this._toState = _toState.name;
      this._toParams = _toParams;
      if (_fromState.name == 'udb.transfers.transferFunds' && !this._allowRedirection) {
        event.preventDefault();
        this.scope.$broadcast('transferFundsHasChanges');
      }
    });

    this.scope.$on('changeStateFromTransfers', () => {
      this._allowRedirection = true;
      this.state.go(this._toState, this._toParams);
    });
  }

  showLearnMoreModal(): void {
    this.matDialog.open(LearnMoreTransfersModalComponent);
  }
}
