import { trigger, state, style, animate, transition } from '@angular/animations';
import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-simple-mat-stepper',
  templateUrl: './simple-mat-stepper.component.html',
  styleUrls: ['./simple-mat-stepper.component.scss'],
  animations: [
    trigger('stepTransition', [
      state('previous', style({ transform: 'translate3d(-100%, 0, 0)', visibility: 'hidden' })),
      state('current', style({ transform: 'none', visibility: 'inherit' })),
      state('next', style({ transform: 'translate3d(100%, 0, 0)', visibility: 'hidden' })),
      transition('* => *', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: SimpleMatStepperComponent }],
})
export class SimpleMatStepperComponent extends CdkStepper {}
