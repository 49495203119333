<div class="holding-quotes-tile" (click)="toggleMessage()">
  <div class="holding-quotes-tile__icon">
    <mat-icon
      class="holding-quotes-tile__icon--clock"
      [svgIcon]="Uk2Tier2ColoredWithAccentEnum.clockCircularArrow"
    ></mat-icon>
  </div>
  <div class="holding-quotes-tile__content">
    <span class="holding-quotes-tile__content--title">
      Quote Details
    </span>
    <p *ngIf="displayMessage" class="holding-quotes-tile__content--message">
      Intraday prices are generally provided for stocks, ETFs, and options on a delayed basis during market hours.
      Prices are delayed at least 20 minutes. If an intraday price is not available, the price displayed will reflect
      the previous business day's close. For mutual funds and fixed income holdings, the prices displayed are generally
      the previous business day's closing price.
    </p>
  </div>
  <div class="holding-quotes-tile__actions">
    <mat-icon
      *ngIf="!displayMessage"
      class="holding-quotes-tile__actions--chevronDown"
      [svgIcon]="Uk2Tier1NavigationEnum.chevronDown"
    ></mat-icon>
    <mat-icon
      *ngIf="displayMessage"
      class="holding-quotes-tile__actions--chevronUp"
      [svgIcon]="Uk2Tier1NavigationEnum.chevronUp"
    ></mat-icon>
  </div>
</div>
