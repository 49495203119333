import { JustifyChartLabelsContext, ProcessedLabel } from '../../types';
/**
 * This pure function is to be used to render the labels of the total value chart
 * must be used with an odd number of dates to show
 * @example
 * this.chart.options.scales.xAxes[0].ticks.callback = justifyChartLabelsEvenly.bind(5)
 * @param this number - dates to show
 * @param value number
 * @param index number
 * @param groupOfLabels {@link ProcessedLabel} [ ]
 * @returns either a string or number
 */
export function justifyChartLabelsEvenly(
  this: JustifyChartLabelsContext,
  value: number,
  index: number,
  groupOfLabels: Array<ProcessedLabel<number>>
): number | string {
  if (this.datesToShow % 2 !== 0) {
    throw new Error('The number of labels to render must be an even number');
  }
  if (index === 0) {
    return '';
  }

  const groupedBy = this.amount / (2.4 * this.datesToShow);
  this.unitStepSizeSetter(groupedBy);

  const labels: Array<number> = [];
  const offsetByStep = Math.floor(groupOfLabels?.length / this.datesToShow);
  const initialOffset = Math.floor(offsetByStep / 2);
  for (let dateLabelPosition = 0; dateLabelPosition < this.datesToShow; dateLabelPosition++) {
    const label = initialOffset + dateLabelPosition * offsetByStep;
    labels.push(label);
  }
  if (labels.includes(index)) {
    return value;
  }
  return '';
}
