import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getTradingAccount } from '@app/accounts/store/selectors';
import { Position } from '@app/axos-trading/models';
import { ClearingService } from '@app/axos-trading/services';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss'],
})
export class PositionComponent implements OnInit, OnDestroy {
  @Input() accountId: number;
  @Input() stockId: string;
  position: Position;
  icons = {
    ChevronUp: NavigationIcons.ChevronUp,
    ChevronDown: NavigationIcons.ChevronDown,
  };
  subSink = new SubSink();
  isExpanded = false;
  loadingPositions = false;
  constructor(private store: Store, private clearingService: ClearingService) {}

  ngOnInit(): void {
    this.loadingPositions = true;
    this.subSink.sink = this.store
      .select(getTradingAccount(this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.subSink.sink = this.clearingService.getPortfolioOverview(account.accountNumber).subscribe(result => {
          const positions = result.positions;
          this.position = positions?.find((x: Position) => x.symbol === this.stockId);
          this.loadingPositions = false;
        });
      });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
