<form
  class="sm-compose"
  #composeMessageForm="ngForm"
  name="form"
  (ngSubmit)="sendMessage(composeMessageForm.valid)"
>
  <div class="sm-compose-section">
    <p class="sm-compose-section__greeting" [innerText]="greeting | titlecase"></p>
    <p class="sm-compose-section__legend">
      Let us help you with your request. Please describe what you need assistance with below.
    </p>
    <textarea
      name="message"
      #message="ngModel"
      [(ngModel)]="messageModel"
      required
      class="uk-input"
      placeholder="I need help with..."
      rows="13"
      maxlength="1000"
    ></textarea>
    <div class="sm-compose-section__text-area-details">
      <p>
        <span *ngIf="composeMessageForm.submitted && message.invalid" class="text-error">
          You can't send an empty message
        </span>
      </p>
      <span [innerText]="messageLength"></span>
    </div>
  </div>
  <app-file-upload [(files)]="filesModel" [totalMaxSize]="totalMaxSize"></app-file-upload>
  <div class="sm-compose-section">
    <p class="sm-compose-section__footer">
      Prompted by one of our agents to submit a form(s) and looking for the fastest service? Attach
      the completed form to your existing conversation in the
      <a href="#" (click)="redirectToInbox()">
        message center.
      </a>
    </p>
    <button type="submit" class="btn-ok button--lg sm-compose__send" [disabled]="isSending">
      Send
    </button>
    <app-dot-loader [showWhen]="isSending"></app-dot-loader>
  </div>
</form>
