import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { StoreModule } from '@ngrx/store';

import { NgxErrorsModule } from '@axos/ngx-errors';

import { CryptoModule } from '@app/crypto/crypto.module';
import { TransfersModule } from '@app/transfers/transfers.module';
import { AppStateFeatures } from '@core/enums';
import { SharedModule } from '@shared/shared.module';

import { tradingComponents } from './components';
import { axosTradingModals } from './modals';
import { ordersReducer } from './store/orders/orders.reducer';
import {
  documentServiceProvider,
  serviceHelperProvider,
  statementsServiceProvider,
  stateParamsServiceProvider,
} from '@core/providers';
import { AxosClearingDocumentsService } from './services/axos-clearing-documents.service';

@NgModule({
  declarations: [...axosTradingModals, ...tradingComponents],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    SharedModule,
    TransfersModule,
    CryptoModule,
    StoreModule.forFeature(AppStateFeatures.AxosTrading, {
      ordersState: ordersReducer,
    }),
  ],
  providers: [
    DecimalPipe,    
    AxosClearingDocumentsService,
    statementsServiceProvider,
    documentServiceProvider,
    serviceHelperProvider,
    stateParamsServiceProvider,
  ],
})
export class AxosTradingModule {}
