import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getTradingAccount } from '@app/accounts/store/selectors';
import { ORDER_STATUS_SHORT_DESCRIPTIONS } from '@app/axos-trading/constants';
import { MenuOverview, OrderStatus } from '@app/axos-trading/enums';
import { Order, Position, TradingAccount } from '@app/axos-trading/models';
import { ClearingService, TradingRoutingHelperService } from '@app/axos-trading/services';
import { getCurrentOrder, getPositionsBySymbol } from '@app/axos-trading/store/orders/orders.selectors';
import { STATE_PARAMS } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-stock-transaction-details',
  templateUrl: './stock-transaction-details.component.html',
  styleUrls: ['./stock-transaction-details.component.scss'],
})
export class StockTransactionDetailsComponent implements OnInit, OnDestroy {
  get accountId() {
    return this.params['id'];
  }

  icons = {
    backIcon: NavigationIcons.ArrowBack,
  };
  tradingAccount: TradingAccount;
  openOrder: Order;
  orderStatus = OrderStatus;
  orderStatusShortDescriptions = ORDER_STATUS_SHORT_DESCRIPTIONS;
  position: Position;
  description: string;
  labelStatus: string;
  companyName: string;
  isLoadingDetails = false;
  isLoadingData = false;

  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private routingHelper: TradingRoutingHelperService,
    private store: Store,
    private clearingService: ClearingService
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  goBackToSDT(): void {
    this.routingHelper.goToTransactionsSubTab(this.accountId, MenuOverview.OpenOrders);
  }

  private getData() {
    this.isLoadingData = true;
    this.subsink.sink = combineLatest([
      this.store.select(getCurrentOrder),
      this.store.select(getTradingAccount(this.accountId)).pipe(filter(account => !!account)),
    ]).subscribe({
      next: ([order, account]) => {
        if (!order || !account) this.goBackToSDT();
        this.tradingAccount = account;
        this.openOrder = order;
        this.getPosition();
      },
    });
  }

  private getDescription() {
    this.description = `${this.openOrder.orderType} ${
      this.openOrder.category.description
    } : ${this.openOrder.symbol.slice(0, 5)} (${this.position.assetName.slice(0, 20)})`;
  }

  private getPosition() {
    this.subsink.sink = this.store.select(getPositionsBySymbol(this.openOrder.symbol)).subscribe(position => {
      if (!position) {
        this.subsink.sink = this.clearingService.getEquity(this.openOrder.symbol).subscribe(equity => {
          this.position = new Position({
            assetName: equity.stockName,
          });
          this.getDescription();
          this.isLoadingData = false;
        });
      } else {
        this.position = position;
        this.getDescription();
        this.isLoadingData = false;
      }
    });
  }
}
