import { OpenAccountIcons } from '@shared/enums/svg-icons/open-account-icons.enum';

export interface OpenAccountImage{
imageFile: string;
name: string;
}

export const openAccountImages: OpenAccountImage[] = [
  {
    imageFile: OpenAccountIcons.Account_Checking,
    name: 'checking'
  },
  {
    imageFile: OpenAccountIcons.Account_Savings,
    name: 'savings'
  },
  {
    imageFile: OpenAccountIcons.Account_CD,
    name: 'cd'
  },
  {
    imageFile: OpenAccountIcons.Account_Investment,
    name: 'investment'
  },
  {
    imageFile: OpenAccountIcons.Account_Mortgage,
    name: 'mortgage'
  },
  {
    imageFile: OpenAccountIcons.Account_Personal,
    name: 'personal'
  },
  {
    imageFile: OpenAccountIcons.Account_Auto,
    name: 'auto'
  }
];
