import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import NgRedux from 'ng-redux';

import { PortfolioBalanceHistoryFilter, PortfolioBalanceHistoryResponse } from '@app/axos-trading/models';
import { ClearingService } from '@app/axos-trading/services/clearing.service';
import { setupAccountsToBeFunded, turnOnFunding } from '@app/store/funding/funding.actions';
import { ngRedux, STATE } from '@core/tokens';
import { TradingAccount } from '@legacy/accounts/typings/TradingAccount';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-historic-overview',
  templateUrl: './historic-overview.component.html',
  styleUrls: ['./historic-overview.component.scss'],
})
export class HistoricOverviewComponent implements OnInit, OnDestroy {
  @Input() account: TradingAccount;

  portfolioBalance: PortfolioBalanceHistoryResponse[];
  filters: PortfolioBalanceHistoryFilter[] = [
    { id: 0, label: '1W', duration: { weeks: 1 } },
    { id: 1, label: '1M', duration: { months: 1 } },
    { id: 2, label: '3M', duration: { months: 3 } },
    { id: 3, label: '1Y', duration: { years: 1 } },
    { id: 4, label: 'ALL' },
  ];
  activeFilter: PortfolioBalanceHistoryFilter;
  isLoadingPortfolioBalance = true;
  icons = { arrowForward: NavigationIcons.ArrowForward };
  flowTypeEnum = FlowType;
  private subSink = new SubSink();
  constructor(
    private clearingService: ClearingService,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ngRedux) private ngredux: NgRedux.INgRedux,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.activeFilter = this.filters[0];
    this.getPortfolioBalanceHistory();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  changeGraph(filter: PortfolioBalanceHistoryFilter) {
    this.isLoadingPortfolioBalance = true;
    this.activeFilter = filter;
    this.isLoadingPortfolioBalance = false;
  }

  goToFundPage() {
    const accountOlb: OlbAccount = JSON.parse(JSON.stringify(this.account)) as OlbAccount;
    accountOlb.nickname = `${accountOlb.nickname} **${accountOlb.accountNumber.slice(
      accountOlb.accountNumber.length - 4
    )}`;
    this.ngredux.dispatch(FundingActions.turnOnFunding());
    this.store.dispatch(turnOnFunding());
    this.ngredux.dispatch(FundingActions.setupAccounts([accountOlb]));
    this.store.dispatch(setupAccountsToBeFunded({ payload: [accountOlb] }));
    this.state.go('udb.funding', { flow: this.flowTypeEnum.Funding });
  }

  private getPortfolioBalanceHistory() {
    this.subSink.sink = this.clearingService
      .getPortfolioBalanceHistory(
        this.account.accountNumber,
        new Date(this.account.openedDate).toISOString(),
        new Date().toISOString()
      )
      .pipe(finalize(() => (this.isLoadingPortfolioBalance = false)))
      .subscribe(
        result => (this.portfolioBalance = result),
        () => (this.portfolioBalance = [])
      );
  }
}
