import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';
import { FundingMethod } from '../enums';

const eventName = 'FundingIntercept';
const fieldName = 'Customer Selected';
const fundName = 'FundingAmount';

export function FundingInterceptAction(fundingMethod: FundingMethod) {
  let description = 'Remind me later';
  switch (fundingMethod) {
    case FundingMethod.ExternalTransfer:
      description = 'External Transfer';
      break;
    case FundingMethod.MailCheck:
      description = 'Mail a check';
      break;
    case FundingMethod.RemoteDeposit:
      description = 'Remote Deposit';
      break;
    case FundingMethod.WireTransfer:
      description = 'Wire Transfer';
      break;
    case FundingMethod.BrokerageTransfer:
      description = 'Brokerage Transfer';
      break;
    case FundingMethod.DirectDeposit:
      description = 'Direct Deposit Transfer';
      break;
  }

  return new UserAction(eventName, [new UserActionField(fieldName, null, description)]);
}

export function SourceFromHistroricalAction(isFromEnrollment: boolean) {
  return new UserAction(eventName, [
    new UserActionField('Funding Flow', null, `${isFromEnrollment ? 'From Enrollment' : 'From Login'}`),
  ]);
}

export function FundingAmountHistroricalAction(amount: string) {
  return new UserAction(fundName, [new UserActionField('Amount', null, amount)]);
}
