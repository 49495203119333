<!-- Checkbox -->
<td *ngIf="isMultiple && !isChild">
  <input
    type="checkbox"
    class="checkbox"
    id="chk-select-{{ id }}"
    [(ngModel)]="trxn.isSelected"
    (ngModelChange)="selectRow()"
    [disabled]="convertDisabled(trxn.dispute.key)"
  />
  <label for="chk-select-{{ id }}"></label>
</td>

<!-- Transaction type icon -->
<!-- remove next "td" html tag once ACCOUNT AGGREGATION feature has been released -->
<td *ngIf="!isAccountAggregationForInternalsActive">
  <span *ngIf="!isChild">
    <i *ngIf="!trxn.isPending" [ngClass]="{ 'bofi-atm': trxn.type === 'D', 'bofi-deposit': trxn.type === 'C' }"></i>
    <i *ngIf="trxn.isPending" class="bofi-pending" aria-hidden="true"></i>
  </span>
</td>

<td *ngIf="isAccountAggregationForInternalsActive" (click)="showDetails(trxn)" class="tx-table__row__category">
  <i *ngIf="trxn.isPending" style="display: flex;" class="bofi-pending" aria-hidden="true"></i>
  <span *ngIf="!trxn.isPending && transactionIsNotPending()" class="tx-table__row__category--icon">
    <div [innerHTML]="categoryIcon"></div>
  </span>
</td>

<!-- Date -->
<td (click)="showDetails(trxn)">
  <span *ngIf="trxn.isPending">{{ trxn.pendingStatus | uppercase }}</span>
  <span *ngIf="!trxn.isPending">{{ trxn.postedDate | date: 'MM/dd/yyyy' }}</span>
</td>

<!-- Description -->
<td class="tx-table__row__desc" (click)="showDetails(trxn)">
  <span class="tx__description" (click)="getCheck(trxn)" [ngClass]="trxn.hasCheck ? 'text-link' : 'not-clickable'">
    {{ trxn.description }}
  </span>
  <!-- PFM3 new tooltip -->
  <span *ngIf="trxn.simpleDescription" class="desc-tooltip">{{ trxn.simpleDescription }}</span>
</td>

<!-- Amount - FOr account aggregation only -->
<td
  class="text-right tx-table__row__deposit"
  *ngIf="isAccountAggregationForInternalsActive"
  (click)="showDetails(trxn)"
>
  <span>{{ trxn.amount | currency }}</span>
</td>

<!-- When account aggregation is disable - Delete after account aggregation is on -->
<!-- Deposit Amount -->
<td class="text-right tx-table__row__deposit" *ngIf="!isAccountAggregationForInternalsActive">
  <span *ngIf="trxn.type === 'C'">{{ trxn.amount | currency }}</span>
</td>

<!-- Withdrawal Amount -->
<td class="text-right tx-table__row__withdrawal" *ngIf="!isAccountAggregationForInternalsActive">
  <span *ngIf="trxn.type === 'D'">{{ trxn.amount | currency }}</span>
</td>

<!-- Total balance -->
<td class="tx-table__row__balance" (click)="showDetails(trxn)">
  <div class="text-righ">{{ trxn.amount | currency }}</div>
  <div class="text-right" *ngIf="!isLoan">{{ getAccountBalance() }}</div>
</td>

<!-- Options (View Details/Dispute Transaction) -->
<td class="tx-table__row__options">
  <div
    *ngIf="
      ((trxn.childDetails && trxn.childDetails.length) || isDisputeActive) &&
      !isChild &&
      (!trxn.dispute.key || hideTransactionsDisputedMark())
    "
  >
    <app-tx-options
      [options]="accountOptions"
      [isDisabled]="isMultiple"
      [isExpanded]="trxn.isExpanded"
      (onOptionSelected)="selectOption($event)"
      (onCollapse)="onCollapse()"
      [isDetailsExpanded]="trxn.isDetailsExpanded"
      class="options-tooltip"
    ></app-tx-options>
  </div>
  <app-tooltip
    [content]="disputeMessage()"
    [symbol]="'!'"
    *ngIf="trxn.dispute.key && !hideTransactionsDisputedMark()"
  ></app-tooltip>
</td>
