import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  ALERTSNOTIFICATIONSSERVICE,
  CACHEDACCOUNTSSERVICE,
  DEVICESSERVICE,
  FILTER,
  MODALSERVICE,
  USERSUBTYPEHELPER,
} from '@app/alerts-notifications/my-alerts/ajs-upgraded-provider';
import { AlertsUI } from '@app/alerts-notifications/my-alerts/typings/AlertsUI';
import { ROOT_SCOPE } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { DevicesService } from '@legacy/services/devices.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ModalService } from '@legacy/services/modal.service';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';
import { DropdownItem } from '@uikit/clickdropdown';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-create-alert',
  templateUrl: './create-alert.component.html',
  styleUrls: ['./create-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAlertComponent implements OnInit, OnChanges {
  $mac: any;
  @Input() alert: AlertsUI = {} as AlertsUI;
  @Input() indexAlert: number = {} as number;
  @Input() accounts: OlbAccount[] = [];
  @Input() deletingAlert: boolean = false;
  @Input() editingAlert: boolean = false;
  @Output() saveSuccess: EventEmitter<string> = new EventEmitter();
  @Output() deleteAlert: EventEmitter<AlertsUI> = new EventEmitter();
  @Output() cancelAlertEvent = new EventEmitter();
  alertCopy: AlertsUI = {} as AlertsUI;
  editAlert: Alerts = {} as Alerts;
  ddlAccounts: DropdownItem[] = [] as DropdownItem[];
  selectedItem: DropdownItem = {} as DropdownItem;
  errorMsg: string = '';

  /** Default boolean values. */
  hasOtherAccounts = false;
  savingAlert: boolean = false;
  savingAlert$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isInvalid: boolean = false;
  deleteMsg: boolean = false;
  smsEnabled: boolean = false;
  updateSMS: boolean = false;
  isSmsCheckBoxDisabled: boolean = false;
  isPushCheckBoxDisabled: boolean = false;
  showPNs: boolean = false;
  hasDevices: boolean = false;
  footerButtons: boolean = false;
  threshold: string | number = '';
  private isAxosTradingActive: boolean = false;
  private isInvalidAccount: boolean = false;
  private isCancelingAlertChanges: boolean = false;
  private activeManualFocus = false;

  private cdAlertsEnabled = [
    'balance snapshot',
    'large withdrawal',
    'large deposit',
    'low balance',
    'high balance',
    'featureFlagService',
  ];

  readonly alertsObj = {
    statement: { title: 'Statements' },
    taxForms: { title: 'Tax Forms' },
    documents: { title: 'Documents' },
  };

  constructor(
    private readonly featureFlagService: FeatureFlagService,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    @Inject(ROOT_SCOPE) private scope: any,
    @Inject(ALERTSNOTIFICATIONSSERVICE)
    private alertsService: IAlertsNotificationsService,
    @Inject(FILTER) private readonly filter: ng.IFilterService,
    @Inject(DEVICESSERVICE) private readonly devicesService: DevicesService,
    @Inject(CACHEDACCOUNTSSERVICE)
    private cachedAccountsService: CachedAccountsService,
    @Inject(MODALSERVICE) private readonly modalService: ModalService,
    @Inject(USERSUBTYPEHELPER) private userSubtypeHelper: UserSubtypeHelper
  ) {
    scope.$on('enableSMSNotifications', (_event: any, enabled: boolean) => {
      if (this.updateSMS && enabled) {
        this.alert.alert.sendConfirmationText = true;
        this.smsEnabled = true;
      }
      this.updateSMS = false;
    });

    scope.$on('errorDeleteAlert', (_event: any, alertId: string) => {
      if (this.alert.alert.alertId == alertId) {
        this.errorMsg =
          'Fail! Changes to your custom alert have not been saved, try again.';
        this.isInvalid = true;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deletingAlert) {
      this.alert.deletingAlert = false;
    }
    if (changes.editingAlert) {
      if (this.editingAlert && this.alert.isNew) {
        this.footerButtons = true;
      }
    }
  }
  ngOnInit(): void {
    this.alertCopy = JSON.parse(JSON.stringify(this.alert));
    this.scope.alert = this.alert;
    this.threshold = this.alert.alert.threshold;
    // this.deletingAlert$.next(false);
    this.showPNs = this.featureFlagService.isPushNotificationsActive();

    this.devicesService.getAllDevices().then(result => {
      this.hasDevices = result.data.length > 0;
      if (angular.equals(this.scope.alert.alert.alertId, '')) {
        this.alert.alert.pushNotification = result.data.length > 0;
      }
    });
    this.fillAccountsDropdown();
    /** Watch the alert for changes and create temporaly cached Alert. */
    this.scope.$watch(
      'alert.alert',
      (_newValue: Alerts, oldValue: Alerts) => {
        let alertHasChanged =
          (JSON.stringify(_newValue) !== JSON.stringify(oldValue) &&
            JSON.stringify(this.alert.alert) !== JSON.stringify(oldValue) &&
            !this.editAlert) ||
          this.alert.isNew;
        if (alertHasChanged) {
          this.editAlert = { ...oldValue };
          this.alert.editMode = true;
          this.isCancelingAlertChanges = false;
        } else if (this.isCancelingAlertChanges) {
          this.editAlert = undefined;
          this.isCancelingAlertChanges = false;
        }
      },
      true
    );

    this.smsEnabled = this.alert.alert.sendConfirmationText;
    this.isAxosTradingActive = this.featureFlagService.isAxosTradingActive();

    this.disableCheckBoxForAxosTradingUser(this.alert);
    this.checkAccounts();
  }

  setAmount(amount: string) {
    this.footerButtons = true;
    amount = amount.split(',').join('');
    let decimalAmount = amount.split('.');
    if (+decimalAmount[1] > 99) {
      decimalAmount[1] = decimalAmount[1].slice(0, 2);
    }
    this.threshold = decimalAmount.join('.');
    this.alert.alert.threshold = decimalAmount.join('.');
  }

  /** Save user Alert. */
  submitAlert(alertModified: AlertsUI, alertBox: NgForm) {
    this.displayErrorDiv(alertModified);

    if (!this.isInvalid && alertBox.valid && !this.isInvalidAccount) {
      this.footerButtons = false;
      this.savingAlert = true;
      this.savingAlert$.next(true);
      this.saveAlert(alertModified);
      //Enable Master Switch
      if (alertModified.alert.sendConfirmationEmail) {
        this.root.$broadcast('enableMasterSwitch', 'alert');
      }
    }
  }

  /** Call alert service. */
  saveAlert(alertModified: AlertsUI) {
    this.alertsService
      .saveAlert(alertModified.alert, alertModified.url)
      .then(
        (response: OlbResponse<any>) => {
          if (response.status === 'Success') {
            this.saveSuccess.emit(
              'Success! Changes to your custom alert have been saved.'
            );
            alertModified.alert.alertId = response.data.data
              ? response.data.data
              : response.data;
            alertModified.editMode = false;
            alertModified.isNew = false;
            this.editAlert = null;
          }
        },
        () => {
          this.errorMsg =
            'Fail! Changes to your custom alert have not been saved, try again';
          this.isInvalid = true;
        }
      )
      .finally(() => {
        this.savingAlert = false;
        this.savingAlert$.next(false);
      });
  }

  displayErrorDiv(a: AlertsUI): void {
    const { alert } = a;
    let accountValid: boolean = false;
    accountValid = !alert.accountNumber;

    let selectAccount: boolean = false;
    selectAccount = this.isInvalidAccount;

    switch (alert.alertType) {
      case 'Balance Snapshot':
        if (!alert.frequency && selectAccount) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your preferred Frequency and Account before saving your Alert.';
        } else if (!alert.frequency) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your preferred Frequency before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your preferred Account before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Check Cleared':
        if (!alert.threshold && selectAccount) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your Check number and Account before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your preferred Account before saving your Alert.';
        } else if (!alert.threshold) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your Check number before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Maturity Date':
      case 'Loan Payment Due Date':
        if (!alert.threshold && selectAccount) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your number of days and Account before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your preferred Account before saving your Alert.';
        } else if (!alert.threshold) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your number of days before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Large Withdrawal':
      case 'Low Balance':
      case 'Large Deposit':
      case 'High Balance':
        if (!alert.threshold && selectAccount) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select a valid Amount and Account before saving your Alert.';
        } else if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your preferred Account before saving your Alert.';
        } else if (!alert.threshold) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select a valid Amount before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      case 'Loan Overdue':
        if (selectAccount || accountValid) {
          this.isInvalid = true;
          this.errorMsg =
            'Please select your preferred Account before saving your Alert.';
        } else {
          this.isInvalid = false;
        }
        break;
      default:
        this.errorMsg = 'Unexpected error occurred';
        break;
    }
  }

  isInvalidFrequencyAlert(a: Alerts): boolean {
    return a.startDate ? false : true;
  }

  /** Show Delete message if Alert was already saved/created. */
  removeAlert(a: AlertsUI) {
    if (!a.isNew) {
      this.deleteMsg = true;
    }
  }

  removeAlertEmit(a: AlertsUI) {
    a.deletingAlert = true;
    this.deletingAlert = true;
    // this.deletingAlert$.next(true);
    this.deleteAlert.emit(a);
  }

  /** Cancel edit changes on Alert, remove from array if not saved/created. */
  cancelAlert() {
    if (!this.alert.isNew) {
      // verify if local variable 'this.editAlert' has values, otherwise preserve current value on 'a' variable
      this.alert = JSON.parse(JSON.stringify(this.alertCopy)); // set cached alert value

      // set default values
      this.selectedItem = this.ddlAccounts.filter((acc: DropdownItem) => {
        return (
          acc.id ===
          `${this.alert.alert.accountNumber}|${this.alert.alert.accountType}`
        );
      })[0];
      this.alert.editMode = false;
      this.scope.$broadcast('undoFrequencyChanges', this.alert.alert);
      this.isInvalid = false;
      this.isCancelingAlertChanges = true;
      this.smsEnabled = this.alert.alert.sendConfirmationText;
    } else {
      this.alert.alert.pushNotification = !this.alert.alert.pushNotification;
      this.cancelAlertEvent.emit(this.indexAlert);
    }
    this.footerButtons = false;
  }

  /** Select Account Dropdown Item and update Alert account number. */
  onSelect(data: DropdownItem) {
    this.selectedItem = data;
    this.isInvalidAccount =
      this.selectedItem.id === null || this.selectedItem.id === undefined;

    let accountNumber = '';
    let accountType = '';

    if (!this.isInvalidAccount) {
      let account: string[] = this.selectedItem.id.toString().split('|');
      accountNumber = account[0];
      accountType = account[1];
    }

    this.alert.editMode = this.alert.alert.accountNumber !== accountNumber;

    if (!this.isInvalidAccount && this.alert.editMode) {
      this.alert.alert.accountNumber = accountNumber;
      this.alert.alert.accountType = accountType;
      this.isInvalid = false;
    } else if (this.isInvalidAccount && this.alert.editMode) {
      this.alert.alert.accountNumber = '';
      this.alert.alert.accountType = '';
    }
  }

  setUpSMS(enabled: boolean) {
    this.smsEnabled = false;
    this.updateSMS = true;
    this.alert.editMode = true;
    if (enabled) {
      this.root.$broadcast('setUpSMSMasterSwitch', 'alert');
    } else {
      this.alert.alert.sendConfirmationText = false;
    }
  }

  popupPushNotification(): void {
    this.footerButtons = true;
    if (this.hasDevices || !this.alert.alert.pushNotification) return;

    this.modalService
      .show(
        { windowClass: 'modal-service-md' },
        {
          hasIcon: false,
          hasHeaderText: true,
          headerText: 'No Associated Mobile Devices',
          okText: 'Ok',
          hasCancelButton: true,
          bodyText: `<div>
                        <b>
                          Your account does not have any mobile devices configured to receive push notifications via the
                          ${this.root['brandProperties'].BrandDisplayName} mobile app.
                          In order to receive push notifications you will need to download the app and allow notifications.
                        </b>
                      </div>`,
        }
      )
      .catch(() => {
        this.scope.alert.alert.pushNotification = false;
      })
      .finally(() => {});
  }

  setUpEmail() {
    // this.scope.alert.editMode = true;
    this.footerButtons = true;
  }

  private checkAccounts() {
    const externals =
      this.cachedAccountsService.allAccounts?.externalAccounts?.length > 0;
    const internals =
      this.cachedAccountsService.allAccounts?.internalAccounts?.length > 0;
    this.hasOtherAccounts = externals || internals;
  }

  showTradingDisclaimer(alert: AlertsUI): boolean {
    return (
      (alert.alert.alertType === 'Statement Notification Account' ||
        alert.alert.alertType === 'Taxform') &&
      this.isAxosTradingActive &&
      this.hasOtherAccounts
    );
  }

  isFocusActive(): boolean {
    if (
      !this.activeManualFocus &&
      this.scope.alert.isNew &&
      this.scope.alert.editMode
    )
      this.activeManualFocus = true;

    return this.activeManualFocus;
  }

  private disableCheckBoxForAxosTradingUser(alert: AlertsUI): void {
    this.isSmsCheckBoxDisabled =
      (alert.alert.alertType === 'Statement Notification Account' ||
        alert.alert.alertType === 'Taxform') &&
      this.userSubtypeHelper.isAxosTradingOnly() &&
      this.isAxosTradingActive;
    this.isPushCheckBoxDisabled = this.isSmsCheckBoxDisabled;
  }

  private fillAccountsDropdown() {
    // this.$mac = this.scope.$parent.$mac;
    let accounts: OlbAccount[] = this.accounts;
    let alertObj: Alerts = this.alert.alert;

    //Fill Account DropDown List
    this.ddlAccounts = accounts
      //Filter Dropdown by Account category type
      .filter((a: OlbAccount) => {
        //Quick fix to match the new categories (admin sections)
        const category = a.category === 2 ? 1 : a.category;

        return (
          category === this.alert.category ||
          (category === 4 &&
            this.cdAlertsEnabled.includes(alertObj.alertType.toLowerCase()))
        );
      })
      .map(
        (a: OlbAccount) =>
          new DropdownItem(
            a.nickname,
            `${a.accountNumber}|${a.accountType}`,
            `Avail Bal: ${this.filter('currency')(
              a.availableBalance ? a.availableBalance : 0
            )}`
          )
      );

    this.ddlAccounts.unshift(new DropdownItem('[Select Account]'));

    //Select Current Alert Account
    this.selectedItem = this.ddlAccounts.filter((a: DropdownItem) => {
      return a.id === `${alertObj.accountNumber}|${alertObj.accountType}`;
    })[0];

    // Set "Select Account" if is new Alert
    if (!this.selectedItem) {
      this.selectedItem = this.ddlAccounts[0];
    }
  }
}
