<up-section-heading
  section="Google Authenticator"
  [mobile]="isMobileDevice"
  (expand)="expanded = $event"
></up-section-heading>

<p class="mb-3 col-12">
  Set google authenticator as your login verification code.
</p>

<app-dot-loader *ngIf="isLoading"></app-dot-loader>

<div class="google-auth__content" *ngIf="!isLoading">
  <div class="row" *ngIf="qrCode">
    <div class="col-6 center-align mobile">
      <img class="qr-code" *ngIf="qrCode.barCodeImage" id="qr-img" alt="QR" [src]="qrCode.barCodeImage | safe" />
    </div>
    <div class="col-6 mobile">
      <div class="row mobile__center">
        <input id="code" name="code" mask="000000" class="uk-input col-4 mr-2" [(ngModel)]="code" />
        <button class="uk-btn lg solid secondary" (click)="validateCode()">
          Validate
        </button>
      </div>
      <div class="row mt-2 mobile__center">
        <Label class="mr-1"><strong>Secret key:</strong></Label>
        <Label>{{ qrCode.manualCode }}</Label>
      </div>
    </div>
  </div>
  <div class="col-3 center-align mobile" *ngIf="!qrCode">
    <button class="uk-btn lg solid secondary" (click)="reset()">
      {{ action }}
    </button>
  </div>
</div>
