import { Inject } from '../decorators/decorators';
import { ObservableBaseService } from './observable-base.service';
import { ISignatureCardService } from './typings/ISignatureCardService';
import { UserAction } from 'typings/app/UserAction';
import { Observable } from 'rxjs';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class SignatureCardService extends ObservableBaseService implements ISignatureCardService {
  constructor(
    http: ng.IHttpService,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    q: ng.IQService
  ) {
    super(http, env, 'SignatureCards', serviceHelper, q);
  }

  getSigCardTemplate(): Promise<void> {
    return this.download('sigCardTemplate');
  }

  createSignatureCardCase(accountId: number, accountNumber: string): Observable<OlbResponse<void>> {
    return this.get(`generateCase/${accountId}/${accountNumber}`);
  }

  logUserAction(action: UserAction): Observable<OlbResponse<void>> {
    return this.post('audit', action);
  }
}
