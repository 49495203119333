<div class="header-section-title text-center">
  <section class="frow center header">
    <div class="header__title">
      <span>Spending</span>
    </div>
  </section>
</div>

<div class="main-cont" [ngClass]="{'loading_background': (transactionsLoading | async) || (accountsLoading | async)}">
  <div class="frow">
    <a class="center-align ml-2" (click)="goBack()">
      <app-svg-icon
        [iconName]="icons.ArrowBack"
        [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
        class="mr-1"
      ></app-svg-icon>
      Insights Overview
    </a>
  </div>

  <div class="gradient mt-4">
    <div class="insights-cont mt-4">
      <div class="row">
        <app-dot-loader
        [showWhen]="(transactionsLoading | async) || (accountsLoading | async)"
        [ngClass]="{'loading':(transactionsLoading | async) || (accountsLoading | async)}">Loading</app-dot-loader>
      </div>
      <div class="header-row">
        <h2 class="pb-3 encode-sans">{{ committedFilters.timePeriod }}</h2>

        <div class="flex-grow-1"></div>

        <div
          class="filters-button d-md-none"
          [class.nondefault]="!(committedFiltersInDefaultState | async).all"
          (click)="openFiltersModal()"
        >
          <app-svg-icon
            class="checkmark mr-2"
            [iconName]="icons.Checkmark"
            [styles]="{ width: '1rem', height: '1rem' }"
          ></app-svg-icon>
          <app-svg-icon [iconName]="icons.FilterSlider" [styles]="{ width: '1rem', height: '1rem' }"></app-svg-icon>
        </div>

        <app-filter-dropdown
          (collapse)="commitFilters()"
          [isHighlighted]="categories.length - committedFilters.excludedCategories.size != 0"
          class="d-none d-md-flex"
        >
          <span header>
            Categories
            <span *ngIf="(committedFiltersInDefaultState | async).categories">
              (All)
            </span>
            <span *ngIf="!(committedFiltersInDefaultState | async).categories">
              ({{ categories.length - committedFilters.excludedCategories.size }})
            </span>
          </span>
          <div body class="dropdown-body">
            <app-categories-filter [filter$]="categoriesFilter"></app-categories-filter>
          </div>
        </app-filter-dropdown>

        <app-filter-dropdown
          (collapse)="commitFilters()"
          [isHighlighted]="
            (committedFiltersInDefaultState | async).accounts ||
            (accounts && accAggFormatter.getSelectedAccountsSize(accounts, committedFilters.excludedAccounts) != 0)
          "
          class="d-none d-md-flex"
        >
          <span header>
            Accounts
            <span *ngIf="(committedFiltersInDefaultState | async).accounts">
              (All)
            </span>
            <span *ngIf="!(committedFiltersInDefaultState | async).accounts">
              ({{ accAggFormatter.getSelectedAccountsSize(accounts, committedFilters.excludedAccounts) }})
            </span>
          </span>
          <div body class="dropdown-body">
            <app-accounts-filter
              [providers]="providers"
              [accounts]="accounts"
              [filter$]="excludedAccountsFilter"
              (changeFilter)="onAccountsFilterChange($event)"
              (resetFilter)="onAccountsFilterReset()"
            ></app-accounts-filter>
          </div>
        </app-filter-dropdown>

        <app-filter-dropdown (collapse)="commitFilters()" [isHighlighted]="true" class="d-none d-md-flex">
          <span header>{{ committedFilters.timePeriod }}</span>
          <div body class="dropdown-body">
            <app-time-period-filter [filter$]="timePeriodFilter" [parentPage]="'Spending'"></app-time-period-filter>
          </div>
        </app-filter-dropdown>
      </div>

      <div class="row top-space">
        <div #donutChartCont class="col-md-6">
          <div class="inside-donut">
            <div class="category-name font-weight-light">{{ getExplodedCategoryName() }}</div>
            <div
              class="encode-sans font-weight-normal mt-3"
              [style.font-size]="getInsideDonutFontSize(getExplodedCategoryAmount() | currency, 24, 28)"
            >
              {{ getExplodedCategoryAmount() | currencyExtended: '':'':true }}
            </div>
            <div
              class="category-percentage font-weight-light"
              *ngIf="explode.categories.length"
              [style.font-size]="
                getInsideDonutFontSize(Math.trunc(totalSpent) | currency: 'USD':'symbol':'1.0-0', 13, 16)
              "
            >
              <span
                class="font-weight-normal"
                [textContent]="(getExplodedCategoryPercentage() / 100 | percentageRound) + '%'"
              ></span>
              of
              {{ Math.trunc(totalSpent) | currency: 'USD':'symbol':'1.0-0' }}
            </div>
          </div>
          <app-donut-chart
            [class.invisible]="isEmpty"
            class="donut-chart"
            [height]="405"
            [chartjsOptions]="{
              cutoutPercentage: 63,
              elements: { arc: { borderWidth: 1 } },
              plugins: { highlight: { shadowRadius: 12, shadowColor: 'rgba(0, 0, 0, 0.3)', explode: true } }
            }"
            [filters]="explode"
            [categories]="spentByCategories"
            [showTooltips]="true"
            [tooltipType]="toolTipType.White"
            (toggleCategoryFilter)="toggleExplodedCategory($event.categoryId)"
          ></app-donut-chart>
          <img
            class="empty-chart"
            [class.invisible]="!isEmpty"
            src="assets/img/logos/charts/empty-expenses.svg"
            alt=""
          />
        </div>

        <div class="col-md-6">
          <app-categories-grid
            [categories]="spentByCategories"
            [totalSpent]="totalSpent"
            [filters]="committedFilters"
          ></app-categories-grid>
        </div>
      </div>
    </div>
  </div>

  <div style="height: 1rem; background: #f4f5f7;"></div>

  <div class="insights-cont">
    <app-transaction-list
      [transactions]="transactions | async"
      [loadingTransactions]="(transactionsLoading | async) || (accountsLoading | async)"
      [filters]="lastFiltersRequest | async"
    ></app-transaction-list>
  </div>
</div>
