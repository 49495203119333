import {
  EquityType,
  FillType,
  FillTypeDescription,
  FILL_TYPE_MAPPING,
  GoodUntil,
  GoodUntilDescription,
  GOOD_UNTIL_MAPPING,
  OrderType,
  OrderTypeDescription,
  ORDER_TYPE_MAPPING,
  TradeStockType,
  TransactionType,
  TRANSACTION_TYPE_MAPPING,
} from '@app/axos-trading/enums';

export class OrderConfirmation {
  accountNumber: string;
  fillType: FillType;
  fillTypeDescription: FillTypeDescription;
  goodUntil: GoodUntil;
  goodUntilDescription?: GoodUntilDescription;
  limitPrice?: number;
  marketPrice: number;
  orderType: OrderType;
  orderTypeDescription: OrderTypeDescription;
  quantity: number;
  stock: string;
  symbol: string;
  totalCost: number;
  transaction: TransactionType;
  transactionTypeDescription: TradeStockType;
  orderReference: string;
  value?: number;
  stockType: EquityType;
  constructor(parameters?: Partial<OrderConfirmation>) {
    Object.assign(this, parameters);
    this.fillType = FILL_TYPE_MAPPING[this.fillTypeDescription];
    this.goodUntil = GOOD_UNTIL_MAPPING[this.goodUntilDescription];
    this.orderType = ORDER_TYPE_MAPPING[this.orderTypeDescription];
    this.transaction = TRANSACTION_TYPE_MAPPING[this.transactionTypeDescription];
  }
}
