import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function zipCodeValidatorFn(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    const ZIP_CODE_REGEX = /^[0-9]*$/;
    if (ZIP_CODE_REGEX.test(controlValue)) return null;

    return { zipCodeValidation: true };
  };
}
