import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {UIKitModalConfiguration} from "@uikit/tiles";

@Component({
  selector: 'app-tile-modal',
  templateUrl: './tile-modal.component.html',
  styleUrls: ['./tile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isActive: boolean;
  @Input() config: UIKitModalConfiguration;
  
  statuses: any;
  modifiers: any;
  statusIcon: string = '';
  modifier: string = '';
  
  constructor() {
    this.statuses = {
      'success': 'bofi-success',
      'warning': 'bofi-warning',
      'error': 'bofi-cancel',
      'info': 'bofi-information'
    };
    
    this.modifiers = {
      'success': 'tile-modal--success',
      'warning': 'tile-modal--warning',
      'error': 'tile-modal-error',
      'info': 'tile-modal--info'
    };
  }
  
  ngOnInit() {
    this.statusIcon = this.statuses[this.config.status];
  }
  
  ngOnChanges(changes: ng.IOnChangesObject) {
    if (!changes) {
      this.statusIcon = '';
      return;
    }
    
    if (changes.config) {
      this.statusIcon = this.statuses[changes.config.currentValue.status] || '';
      this.modifier = this.modifiers[changes.config.currentValue.status] || '';
    }
  }
  
  ngOnDestroy() {}
}
