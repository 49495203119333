import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import * as angular from 'angular';
import * as moment from 'moment';
import { Inject } from '../../../decorators/decorators';
import { ITransactionService } from '../../../services/typings/ITransactionService';
import { TransferSchedule } from '../../typings/TransferSchedule';

@Inject(
  'dateHelper',
  '$uibModalInstance',
  'transactionService',
  'serviceHelper',
  'frequencies',
  'transferToEdit',
  'isSeries',
  'dateRangePickerOptions'
)
export class EditInternalTransferModal {
  isLoadingSetup = false;
  // Datepickers
  datePickerOptions: any;
  datePicker: any;
  public minDate: Date;

  constructor(
    private readonly dateHelper: DateHelper,
    private _uibModalInstance: any,
    private _transactionService: ITransactionService,
    private _serviceHelper: IServiceHelper,
    public frequencies: GenericOption[],
    public transferToEdit: TransferSchedule,
    public isSeries: boolean,
    private _dateRangePickerOptions: any,
    public errorMsg: string
  ) {}

  /** Initializes the controller. */
  $onInit(): void {
    let { externalTransferValidDate } = this.dateHelper.getDefaultPaymentInformation();
    this.minDate = externalTransferValidDate.toDate();

    this.datePickerOptions = angular.extend(angular.copy(this._dateRangePickerOptions), {
      minDate: this.minDate,
      maxDate: moment().add(13, 'M'),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'hide.daterangepicker': (_evt: any) => {
          this.transferToEdit.newNextTransferDate = moment(_evt.model).toDate();
          this.transferToEdit.beginSendingOn = moment(_evt.model).toDate();
        },
        'show.daterangepicker': (_evt: any) => {
          this.setPropertiesForCalendar();
        },
      },
    });
    this.transferToEdit.newNextTransferDate = this.transferToEdit.nextTransferDate;

    this.transferToEdit.oldAmount = this.transferToEdit.amount;
    this.transferToEdit.oldFrequency = this.transferToEdit.frequency;
    this.transferToEdit.oldBeginSending = this.transferToEdit.beginSendingOn;
    this.errorMsg = null;
  }

  saveTransfer() {
    if (this.validateAmount()) {
      if (this.isSeries) {
        this.isLoadingSetup = true;

        this._transactionService
          .scheduleEditInternalTransfer(this.transferToEdit)
          .then(_res => {
            this._uibModalInstance.close(true);
          })
          .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper))
          .finally(() => {
            this.isLoadingSetup = false;
          });
      } else {
        this.isLoadingSetup = true;
        this._transactionService
          .scheduleEditNextInternalTransfer(this.transferToEdit)
          .then(_res => {
            this._uibModalInstance.close(true);
          })
          .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper))
          .finally(() => {
            this.isLoadingSetup = false;
          });
      }
    }
  }

  closeModal() {
    this._uibModalInstance.close(false);
  }

  showCalendar(): void {
    const dp = $('[date-range-picker]');
    if (!dp.data('daterangepicker').isShowing) dp.data('daterangepicker').show();
    else dp.data('daterangepicker').hide();
  }

  validateAmount(): boolean {
    if (this.transferToEdit.amount > 0) {
      this.errorMsg = null;

      return true;
    }
    this.errorMsg = 'Please enter Amount';

    return false;
  }

  private setPropertiesForCalendar() {
    var input = $('#date').first();
    var daterangepicker = $('.daterangepicker');

    daterangepicker.css('left', input.offset().left + input.outerWidth() + 10);
    daterangepicker.css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    daterangepicker.append('<div id="arrow-left"></div>');
    daterangepicker.addClass('no-after');

    $('#arrow-left')
      .css('position', 'absolute')
      .css('left', '-8px')
      .css('width', '0')
      .css('height', '0')
      .css('border-top', '8px solid transparent')
      .css('border-bottom', '8px solid transparent')
      .css('border-right', '8px solid darkgray')
      .css('top', daterangepicker.outerHeight() / 2);

    $(document).on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });

    $('.modal-body').on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });
  }
}
