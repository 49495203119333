import { BusinessInformationViewComponent } from './business-information-view/business-information-view.component';
import { ChangeIconViewComponent } from './change-icon-view/change-icon-view.component';
import { CompanyInformationViewComponent } from './company-information-view/company-information-view.component';
import { ContactInfoViewComponent } from './contact-info-view/contact-info-view.component';
import { DirectDepositViewComponent } from './direct-deposit-view/direct-deposit-view.component';
import { EmploymentInfoViewComponent } from './employment-info-view/employment-info-view.component';
import { GoogleAuthenticationViewComponent } from './google-authentication/google-authentication-view.component';
import { LoginAndSecurityViewComponent } from './login-and-security-view/login-and-security-view.component';
import { PersonalInfoViewComponent } from './personal-info-view/personal-info-view.component';
import { PersonalInformationViewComponent } from './personal-information-view/personal-information-view.component';
import { UserProfileViewComponent } from './user-profile-view/user-profile-view.component';

export const userProfileViews = [
  BusinessInformationViewComponent,
  ChangeIconViewComponent,
  ContactInfoViewComponent,
  DirectDepositViewComponent,
  EmploymentInfoViewComponent,
  GoogleAuthenticationViewComponent,
  LoginAndSecurityViewComponent,
  PersonalInfoViewComponent,
  PersonalInformationViewComponent,
  CompanyInformationViewComponent,
  UserProfileViewComponent,
];

export {
  BusinessInformationViewComponent,
  ChangeIconViewComponent,
  ContactInfoViewComponent,
  DirectDepositViewComponent,
  EmploymentInfoViewComponent,
  GoogleAuthenticationViewComponent,
  LoginAndSecurityViewComponent,
  PersonalInfoViewComponent,
  PersonalInformationViewComponent,
  CompanyInformationViewComponent,
  UserProfileViewComponent,
};
