<div class="transaction-details row">
  <div class="left-column col-md-6 col-12">
    <div #description class="row-info desc">
      <div class="col-lg-3 col-md-5" [ngClass]="isPfmTransaction ? 'col-4' : 'col-3'"><p>Description</p></div>
      <div class="col-lg-9 col-md-7 left-row" [ngClass]="isPfmTransaction ? 'col-8' : 'col-9'">
        <span>{{ getDescription }}</span>
      </div>
    </div>
    <div class="row-info">
      <div class="col-lg-3 col-md-5" [ngClass]="isPfmTransaction ? 'col-4' : 'col-3'"><p>Category</p></div>
      <div class="col-lg-9 col-md-7 left-row" [ngClass]="isPfmTransaction ? 'col-8' : 'col-9'">
        <span>
          {{ transaction.olbCategoryName }}
          <span *ngIf="transaction.olbCategoryName?.length && transaction.category?.length" class="ml-1 mr-1">></span>
          {{ transaction.category }}
        </span>
      </div>
    </div>
    <div class="row-info">
      <div class="col-lg-3 col-md-5" [ngClass]="isPfmTransaction ? 'col-4' : 'col-3'"><p>Type</p></div>
      <div class="col-lg-9 col-md-7 left-row" [ngClass]="isPfmTransaction ? 'col-8' : 'col-9'">
        <span>{{ getType }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="showAdditionalInfo" class="right-column col-md-6 col-12">
    <div #merchant class="row-info">
      <div class="col-lg-3 col-md-5" [ngClass]="isPfmTransaction ? 'col-4' : 'col-3'"><p>Merchant</p></div>
      <div class="col-lg-9 col-md-7 right-row" [ngClass]="isPfmTransaction ? 'col-8' : 'col-9'">
        <span>{{ transaction.merchantName }}</span>
      </div>
    </div>
    <div class="row-info">
      <div class="col-lg-3 col-md-5" [ngClass]="isPfmTransaction ? 'col-4' : 'col-3'"><p>Address</p></div>
      <div class="col-lg-6 col-md-5 right-row" [ngClass]="isPfmTransaction ? 'col-8' : 'col-9'">
        <span>{{ transaction.merchantAddress }}</span>
      </div>
    </div>
    <div class="row-info">
      <div class="col-lg-3 col-md-5" [ngClass]="isPfmTransaction ? 'col-4' : 'col-3'"><p>Event</p></div>
      <div class="col-lg-9 col-md-7 right-row" [ngClass]="isPfmTransaction ? 'col-8' : 'col-9'">
        <span>{{ isPhysical }} Transaction</span>
      </div>
    </div>
  </div>
</div>
