import { AxosInvestMilestonesController } from './axos-invest-milestones.controller';

export const AxosInvestMilestonesComponent: ng.IComponentOptions = {
  controller: AxosInvestMilestonesController,
  templateUrl: 'axos-invest/overview/milestones/axos-invest-milestones.tpl.html',
  controllerAs: '$aim',
  bindings: {
    milestonesDetail: '<?',
  },
};
