<app-view-header>
  <h1>Review and Submit</h1>
</app-view-header>

<div class="dispute-review-submit view-container">
  <div class="col-12 col-md-10 m-auto">
    <h3 class="mb-5 text-center">
      Please review the information you have provided before submitting your claim.
    </h3>

    <form #disputeReviewData="ngForm" class="css-form" novalidate (ngSubmit)="submitReview()">
      <div class="d-flex justify-content-between">
        <h4>Account Information</h4>
        <a href="#" id="ac-edit-link-redirection" class="link btn-edit-dispute" (click)="redirectToEdit()">Edit</a>
      </div>
      <hr />

      <div class="pl-4 pr-3 mb-4 d-flex justify-content-between">
        <span>Account Number</span>
        <span class="text-right">
          {{ account?.nickname }}
        </span>
      </div>

      <div class="pl-4 pr-3 mb-5 dispute-review-submit__lost-cards">
        <div class="col-12 col-sm-6 p-0">
          <span>Lost or stolen card(s)</span>
        </div>

        <div class="d-flex flex-column col-12 col-sm-6 p-0">
          <ng-container *ngIf="disputeData?.cardsLost.length > 0; else notLostCards">
            <span class="text-right" *ngFor="let card of disputeData.cardsLost">
              {{ card.prodDesc }}
            </span>
          </ng-container>
          <ng-template #notLostCards>
            <span class="text-right">
              N/A
            </span>
          </ng-template>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <h4>Disputed Transaction(s)</h4>
        <a href="#" class="link btn-edit-dispute" (click)="redirectToEdit()">Edit</a>
      </div>

      <table class="table disputes-table">
        <thead>
          <tr>
            <th *ngFor="let header of disputableHeaders" class="{{ header.cssClass }}">
              {{ header.label }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let transaction of transactions">
            <td>{{ transaction.description }}</td>
            <td class="amount text-right">
              {{ transaction.amount | currency }}
            </td>
          </tr>
          <tr *ngIf="transactions && transactions.length > 1">
            <td>Total</td>
            <td class="amount text-right">
              {{ getTotal() | currency }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-between">
        <h4>Reason for Disputing Transaction(s)</h4>
        <a href="#" id="dc-edit-link-redirection" class="link btn-edit-dispute" (click)="redirectToEdit()">Edit</a>
      </div>
      <hr />

      <div class="reason-container mb-4">
        <p [innerHTML]="disputeReason"></p>
      </div>

      <div class="file-uploader">
        <div class="file-uploader-files" *ngFor="let file of documents; let idx = index">
          <span class="text-link">
            {{ file.name }}
          </span>
          <button type="button" class="file-uploader-remove--btn" id="remove-file-{{ idx }}" (click)="removeFile(file)">
            <app-svg-icon [iconName]="icon.name" [styles]="icon.styles"></app-svg-icon>
          </button>
        </div>
      </div>

      <div class="acknowledgement-container">
        <h4 class="mb-3">Unauthorized Charges:</h4>
        <div class="mb-4 acknowledgement-value" [innerHTML]="unauthorizedChargesText"></div>
      </div>

      <div class="acknowledgement-container">
        <h4 class="mb-3">Acknowledgement:</h4>
        <div class="acknowledgement-value">
          <p>
            I state that I am making this affidavit for the purpose of establishing a disputed transaction of my Axos
            Bank ATM/Debit Card. I did not give, sell, transfer or trade my Card to anyone nor did I give anyone
            permission to use my Card. The transactions detailed above were not made by me or anyone authorized by me. I
            further state that I did not receive any benefit from the unauthorized use of my Card. I did not use this
            Card or authorize the use of this Card by anyone else after I discovered the Card was lost, stolen or
            counterfeited. I give my consent to Axos Bank to release any information regarding my Card and/or Card
            account to any local, state and/or federal law enforcement agency so that the information can, if necessary,
            be used in the investigation and/or prosecution of any person(s) who may be responsible for fraud involving
            my Card and/or Card account. I certify under penalty of perjury that this information provided by me in this
            Affidavit is true. I understand that making a false statement is subject to federal and/or state statutes
            and may be punishable by fines and/or imprisonment.
          </p>
        </div>

        <div class="acknowledgement-checkout">
          <input
            type="checkbox"
            id="checkbox-agree"
            name="checkbox-agree"
            class="focus-checkbox"
            [(ngModel)]="acceptedTerms"
          />
          <label for="checkbox-agree">
            I agree that the above information is truthful and accurate.
          </label>
          <p class="text-error" *ngIf="termsError && !acceptedTerms">
            Please confirm that the information displayed is truthful and accurate
          </p>
        </div>
      </div>
      <app-dot-loader [showWhen]="isBusy"></app-dot-loader>
      <div class="form__actions">
        <button id="submit-btn" type="submit" class="btn-ok button--lg" [disabled]="isBusy">
          Submit Claim
        </button>
        <button id="back-btn" type="button" class="btn-cancel button--lg" [disabled]="isBusy" (click)="goBack()">
          Back
        </button>
      </div>
    </form>
  </div>
</div>
