import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { add, differenceInYears, startOfToday } from 'date-fns';

import { GoalType, ModifyBannerType, TransactionType, TransferType } from '@app/axos-invest/enums';
import { ForecastItem, ForecastParams, Goal, ScheduledTransfer } from '@app/axos-invest/models';
import { AxosInvestService, AxosInvestTransferService } from '@app/axos-invest/services';
import { loadGoalSummary } from '@app/axos-invest/store/actions';
import { getGoalSummary, getScheduledTransfers } from '@app/axos-invest/store/selectors';
import { createGoalEditForm } from '@app/axos-invest/utils';
import { DialogService, UserProfileService } from '@core/services';
import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { AlertsIcons, NavigationIcons, UtilityIcons } from '@shared/enums';
import { DatePickerEvents, DatePickerOptions, DialogData } from '@shared/models';
@Component({
  selector: 'app-goal-edit',
  templateUrl: './goal-edit.component.html',
  styleUrls: ['./goal-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GoalEditComponent implements OnInit, OnDestroy {
  dataForm: UntypedFormGroup;
  goalEdit: Partial<Goal>;
  goalDatePickerOptions: DatePickerOptions;
  backIcon = NavigationIcons.ArrowBack;
  trashIcon = UtilityIcons.Trash;
  dateFormat = 'MM/dd/yyyy';
  projectionValueLow: number;
  projectionValueHigh: number;
  projectionValueDate: string;
  forecastList: ForecastItem[];
  projectionValue: ForecastItem;
  recurringDeposits: ScheduledTransfer[];
  isLoading = false;
  isLoadingUpdate = false;
  isLoadingProjection = true;
  isRetirement = false;
  isCustom = false;
  isEmergencyFund = false;
  canDelete = true;
  forcedRedirect = false;
  dialogData = new DialogData({});
  isDeleted = false;
  private unsubscribeRootScopeListener: () => void;
  private subSink = new SubSink();
  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService,
    private axosInvestService: AxosInvestService,
    private dialogService: DialogService,
    private transferService: AxosInvestTransferService,
    private store: Store,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit() {
    this.unsubscribeRootScopeListener = this.$rootScope.$on('$stateChangeStart', this.askBeforeRedirect.bind(this));
    this.isLoading = true;
    this.getMilestoneDetail();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    this.unsubscribeRootScopeListener();
  }

  goBack() {
    this.state.go('udb.axosinvest');
  }

  submitGoalEdit() {
    this.markFormAsTouched();
    if (this.dataForm.invalid) return;

    this.isLoadingUpdate = true;
    const editGoalForm = this.dataForm.value;
    this.createFormElements(editGoalForm);

    this.axosInvestService
      .updateMilestone(this.goalEdit.id, this.goalEdit)
      .pipe(finalize(() => (this.isLoadingUpdate = false)))
      .subscribe({
        next: () => {
          this.dataForm.markAsPristine();
          this.updateMilestonesInStore();
          this.state.go('udb.axosinvest', {
            id: this.goalEdit.id,
            referrerTabId: this.params['referrerTabId'] ? this.params['referrerTabId'] : 0,
            modifiedState: ModifyBannerType.GoalEdited,
            modifiedBannerText: 'You have successfully modified your goal.',
          });
        },
        error: () => {
          this.isLoadingUpdate = false;
        },
      });
  }

  onChangeTargetDate(event: DatePickerEvents) {
    this.dataForm.controls.targetDate.markAsDirty();
    this.dataForm.controls.targetDate.setValue(event.picker.startDate.toDate());
    this.configureTargetDateOptions();
  }

  deleteGoal() {
    this.getRecurringDeposits();
  }

  deleteMilestoneService() {
    this.axosInvestService.deleteMilestone(this.goalEdit.id).subscribe({
      next: () => {
        this.isDeleted = true;
        this.dataForm.markAsPristine();
        this.updateMilestonesInStore();
      },
      error: () => {
        this.isDeleted = false;
      },
    });
  }

  private updateMilestonesInStore() {
    this.axosInvestService
      .getMilestones()
      .pipe(
        finalize(() => {
          if (this.isDeleted) {
            this.goToGoalCard();
          }
        })
      )
      .subscribe(response => {
        this.store.dispatch(loadGoalSummary({ payload: response.data }));
      });
  }

  private getRecurringDeposits() {
    this.subSink.sink = this.store.select(getScheduledTransfers).subscribe(transfers => {
      if (transfers) {
        this.setTransfers(transfers);
      } else {
        this.transferService.getScheduledTransfers().subscribe(response => {
          this.setTransfers(response.data);
        });
      }
    });
  }

  private getMilestoneDetail() {
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(goalSummary => {
      const goal = goalSummary.milestones.find(milestone => milestone.id === this.params['id']);
      if (goal) {
        this.goalEdit = JSON.parse(JSON.stringify(goal));
        this.canDelete = goalSummary.milestones?.length > 1 && this.goalEdit?.deletable.isDeletable;
        this.createForm();
      }
    });
  }

  private configureTargetDateOptions() {
    this.goalDatePickerOptions = {
      minDate: add(startOfToday(), { days: 1 }),
      maxDate: add(startOfToday(), { years: 100 }),
      singleDatePicker: true,
      autoUpdateInput: false,
    };
  }

  private markFormAsTouched() {
    const controlKeys = Object.keys(this.dataForm.controls);
    controlKeys.forEach(key => {
      this.dataForm.get(key).markAsTouched();
    });
  }

  private createForm() {
    if (this.goalEdit.type === GoalType.PersonalWealth) {
      this.goBack();

      return;
    }
    this.validateFormType(this.goalEdit.type);
    if (this.isRetirement) {
      this.getAge();
    } else {
      this.dataForm = createGoalEditForm(this.goalEdit || {});
      this.isLoading = false;
    }

    if (this.isCustom) this.configureTargetDateOptions();
    this.getForecastList();
  }

  private validateFormType(goalEdit: GoalType) {
    // Emergency Fund
    if (goalEdit === GoalType.RainyDay) {
      this.isEmergencyFund = true;

      return;
    } else if (goalEdit === GoalType.Retirement) {
      this.isRetirement = true;

      return;
    } else {
      this.isCustom = true;

      return;
    }
  }

  private createFormElements(editGoalForm: any) {
    if (this.isRetirement) {
      this.goalEdit.metadata.retirementAge = editGoalForm.retirementAge;
      this.goalEdit.metadata.spendPerYear = editGoalForm.spendPerYear;
    }

    if (this.isEmergencyFund) {
      this.goalEdit.targetAmount = editGoalForm.targetAmount;
      this.goalEdit.metadata.monthsToGoal = editGoalForm.monthsToGoal;
    }

    if (this.isCustom) {
      this.goalEdit.name = editGoalForm.name;
      this.goalEdit.targetAmount = editGoalForm.targetAmount;
      this.goalEdit.metadata.targetDate = editGoalForm.targetDate;
    }
  }

  private getForecastList(forecastParams?: ForecastParams) {
    this.isLoadingProjection = true;

    this.axosInvestService
      .getMilestoneForecast(this.goalEdit.id, forecastParams)
      .pipe(
        finalize(() => {
          this.isLoadingProjection = false;
          this.calculateProjectionValues();
        })
      )
      .subscribe(response => {
        this.forecastList = response.data.total.totals;
        this.projectionValue = this.forecastList[this.forecastList.length - 1];
      });
  }

  private calculateProjectionValues() {
    this.projectionValueLow = Number(this.projectionValue.value) * 0.9;
    this.projectionValueHigh = Number(this.projectionValue.value) * 1.1;
    this.projectionValueDate = this.projectionValue.date;
  }

  private setTransfers(transfers: ScheduledTransfer[]) {
    this.recurringDeposits = transfers.filter(
      transfer =>
        transfer.milestoneId === this.goalEdit.id &&
        transfer.transactionType === TransactionType.Deposit &&
        transfer.frequency >= 2
    );
    this.displayModal();
  }

  private displayModal() {
    this.dialogData = new DialogData({
      title: 'Unable to Delete Goal',
      icon: AlertsIcons.ExclamationTriangle,
      hasCloseButton: true,
    });

    if (this.goalEdit.currentValue > 0) {
      this.dialogData.content = `<div>You must withdraw any remaining funds out of this goal before deleting it.</div>`;
      this.dialogData.okText = 'Withdraw Funds';
    } else if (this.recurringDeposits.length > 0) {
      this.dialogData.content = `<div>You must cancel any remaining scheduled transfers for this goal before deleting it.</div>`;
      this.dialogData.okText = 'Scheduled Transfers';
    } else {
      this.dialogData.title = 'Delete Goal?';
      this.dialogData.icon = AlertsIcons.CheckCircle;
      this.dialogData.content = `<div>Deleting this goal will close your portfolio.</div>`;
      this.dialogData.okText = 'Delete Goal';
    }

    this.subSink.sink = this.dialogService
      .open(this.dialogData)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          if (this.dialogData.okText === 'Withdraw Funds') {
            this.state.go('udb.axosinvest.transferfunds', {
              transferType: TransferType.OneTimeWithdrawal,
            });
          } else if (this.dialogData.okText === 'Scheduled Transfers') {
            this.state.go('udb.transfers.schedulerTransfers');
          } else {
            this.isDeleted = true;
            this.deleteMilestoneService();
          }
        }
      });
  }

  private askBeforeRedirect(event: ng.IAngularEvent, toState: ng.ui.IState) {
    if (!this.forcedRedirect && this.dataForm.dirty) {
      event.preventDefault();
      this.displayModalForDiscardChanges(toState);
    }
  }

  private displayModalForDiscardChanges(toState?: ng.ui.IState) {
    this.dialogData = new DialogData({
      title: 'Discard Changes?',
      icon: AlertsIcons.ExclamationCircle,
      hasCloseButton: true,
      content: '<div>If you go back without saving, your changes will be discarded</div>',
      okText: 'Discard Changes',
    });

    this.subSink.sink = this.dialogService
      .open(this.dialogData)
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.forcedRedirect = true;
          this.state.go(toState);
        }
      });
  }

  private getAge() {
    this.userProfileService
      .getUserProfileInfo()
      .pipe(
        finalize(() => {
          this.dataForm = createGoalEditForm(this.goalEdit || {});
          this.isLoading = false;
        })
      )
      .subscribe({
        next: res => {
          this.goalEdit.age = differenceInYears(new Date(), new Date(res.data.dateOfBirth)) + 1;
        },
      });
  }

  private goToGoalCard() {
    this.state.go('udb.accounts.container', {
      id: '',
      type: 'Invest',
      container: '',
      tab: '',
      modifiedState: ModifyBannerType.GoalDeleted,
      modifiedBannerText: `Your ${this.goalEdit.name} goal has been deleted.`,
    });
  }
}
