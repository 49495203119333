import { NgModule } from '@angular/core';
import { Uk2ValueMovementModule } from '@axos/uikit-v2-lib';
import { Uk2DirectivesModule } from '@axos/uikit-v2-lib';

// * Use this module to export all @axos/uikit-v2 modules required for this feature
@NgModule({
    exports: [Uk2ValueMovementModule,
        Uk2DirectivesModule    ],
})
export class TotalValueUk2Module {}
