import { Frequency, SendUntil } from '@app/axos-invest/enums';

export class CompletedTransfer {
  frequency: Frequency;
  frequencyDescription: string;
  transactionTypeDescription: string;
  sendUntil: SendUntil;
  amount: number;
  startDate?: Date | string;
  endDate?: Date | string;
  milestoneId: string;

  constructor(transfer?: Partial<CompletedTransfer>) {
    Object.assign(this, transfer);
  }
}
