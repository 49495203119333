<ng-container *ngIf="interestedPartiesFormatted && interestedPartiesFormatted.length > 0; else emptyState">
  <ng-container *ngFor="let interestedParty of interestedPartiesFormatted; let i = index">
    <div class="section-header">
      <div class="section-header__title">
        Interested Party {{ interestedPartiesFormatted.length > 1 ? '#' + (i + 1) : '' }}
      </div>
      <div *ngIf="userCanAddEditInterestedParty" class="section-header__edit-button">
        <button
          uk2LabeledIconButton
          mat-button
          disableRipple
          (click)="openInterestedPartyModal(modalActionType.Edit, interestedParties[i])"
          [uk2ButtonSize]="uk2ButtonSize"
          [uk2LabeledIconPosition]="labeledIconPosition"
        >
          Edit
          <mat-icon [svgIcon]="editSvgIconName"></mat-icon>
        </button>
      </div>
    </div>
    <div class="list">
      <div class="list-row">
        <span class="title">Name</span>
        <span class="value">{{ interestedParty.name }}</span>
      </div>
      <div class="list-row">
        <span class="title">Address</span>
        <div class="list-row__address">
          <p class="value" *ngFor="let address of interestedParty.address">{{ address }}</p>
        </div>
      </div>
      <div [class]="i === interestedPartiesFormatted.length - 1 ? 'list-row' : 'list-row last-row'">
        <span class="title">Documents Received</span>
        <span class="value">{{ interestedParty.documentsReceived }}</span>
      </div>
      <div
        class="list-row last-row"
        *ngIf="userCanAddEditInterestedParty && i === interestedPartiesFormatted.length - 1"
      >
        <div class="item-container" (click)="openInterestedPartyModal(modalActionType.Add)" id="btn-nonAxos">
          <div class="item-container__icon-plus">
            <mat-icon [svgIcon]="plusIcon"></mat-icon>
          </div>
          <div>Add Another Interested Party</div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyState>
  <div class="section-header">
    Interested Party
  </div>
  <div class="list">
    <div class="list-row" *ngIf="userCanAddEditInterestedParty; else notYetAssigned">
      <div class="item-container" (click)="openInterestedPartyModal(modalActionType.Add)" id="btn-nonAxos">
        <div class="item-container__icon-plus">
          <mat-icon [svgIcon]="plusIcon"></mat-icon>
        </div>
        <div>Add Interested Party</div>
      </div>
    </div>
    <ng-template #notYetAssigned>
      <div class="list-row">Not Yet Assigned</div>
    </ng-template>
  </div>
</ng-template>

<app-trusted-interested-modal
  [personType]="personType"
  [data]="interestedParty"
  [accountNumber]="accountNumber"
  [modalAction]="action"
  [showModal]="showModal"
  (modalClosed)="modalClosed()"
></app-trusted-interested-modal>
