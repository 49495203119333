<div class="upload-form">
  <app-alert-notification
    *ngIf="submitSuccess"
    alertType="success"
    (dismiss)="dismissSuccess()"
    class="upload-form__success"
  >
    Upload was successful. We will contact you if additional information is needed.
  </app-alert-notification>
  <app-file-upload
    #fileUpload
    [hasCustomDropZone]="true"
    [(files)]="documents"
    [totalMaxSize]="totalMaxSize"
    (filesChange)="prepareDocument($event)"
  >
    <div class="upload-form__drop-zone" drop-zone>
      <div class="upload-form__custom-view">
        <img [src]="uploadImage" alt="Upload" />
        <p>Drag file to upload</p>
        <p>-or-</p>
        <p><b>Choose a file</b></p>
        <p>File size limit for upload: 10 MB</p>
      </div>
    </div>
  </app-file-upload>
</div>
