import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AxosAdvisoryAccount, MultiFactorRequest } from '@core/models';
import { AlertsUI } from '../../typings/AlertsUI';
import { DropdownItem } from '@uikit/clickdropdown';
import { SubSink } from '@axos/subsink';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { Store } from '@ngrx/store';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AccountCategory } from '@app/accounts/enums';
import { ROOT_SCOPE, olbSettings } from '@core/tokens';
import {
  ALERTSNOTIFICATIONSSERVICE,
  ALERTSSMSSERVICE,
  BRANDPROPERTYSERVICE,
  DELIVERYMETHODSERVICE,
  FILTER,
  LOADUSERPROFILEHELPER,
  MULTIFACTORSERVICE,
  myAlertsServiceProvider,
} from '../../ajs-upgraded-provider';
import { IDeliveryMethodService } from '@legacy/services/typings/IDeliveryMethodService';
import { IMultifactorService } from '@legacy/services/typings/IMultifactorService';
import { LoadUserProfileHelper } from '@legacy/services/load-user-profile-helper';
import { BrandPropertyService } from '@legacy/services/brand-property.service';
import { FacingBrand } from '@core/enums';
import { getProfileDetails } from '@app/user-profile/store/profile-details/profile-details.selectors';
import { filter } from 'rxjs/operators';
import { formatPhone, maskPhone } from '@app/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-my-alerts',
  templateUrl: './my-alerts.component.html',
  styleUrls: ['./my-alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: myAlertsServiceProvider,
})
export class MyAlertsComponent implements OnInit {
  deletingAlert:boolean= false;
  removeAlert$:BehaviorSubject<boolean> = new BehaviorSubject(false);
  alerts: Alerts[];
  accounts: OlbAccount[] = [];
  riaAccounts: AxosAdvisoryAccount[] = [];
  availableAlerts: AlertsUI[] = [];
  userAlerts: AlertsUI[] = [];
  accountCategories: number[] = [];
  draggableAlerts: AlertsUI[] = [];
  noAxosProducts = false;
  noAxosAccountsIsLoading = true;
  isLoading = true;
  isLoading$ = new Subject<boolean>;
  isDragging = false;

  // array used to pass success/fail messages from  create-alert.controller to this controller
  saveSuccess: string[] = [];
  loadingUserAlerts = false;
  loadingUserAlerts$:BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingUpdateUserAlerts = false;
  editingAlert = false;
  isNewAlert = false;
  savingAlert = false;
  oldAlert = false;
  closeAccountCodes: string[];
  brandName: string;
  lastTrans = 5;
  editMode = false;
  savingTrans = false;
  savingTrans$:BehaviorSubject<boolean> = new BehaviorSubject(false);
  editAccMode = false;
  savingAccTrans = true;
  saveSmsSuccess = false;
  saveSmsSuccess$:BehaviorSubject<boolean> =  new BehaviorSubject(false);;
  userPhoneNumber$:BehaviorSubject<boolean> = new BehaviorSubject(false);
  showPNs = false;
  dropFrom: DropdownItem[] = new Array<DropdownItem>();
  dropTo: DropdownItem[] = new Array<DropdownItem>();
  dropIsLoading$:BehaviorSubject<boolean> = new BehaviorSubject(true);
  dropToLoading$:BehaviorSubject<boolean> =  new BehaviorSubject(false);

  fromAccount: DropdownItem = new DropdownItem('[Select Account]');
  toAccount: DropdownItem = new DropdownItem('[Select Account]');
  readonly transNumberOptions: number[] = [5, 4, 3, 2, 1];
  readonly alertsTitles: string[] = ['Statements', 'Tax Forms', 'Documents'];
  readonly alertsObj = {
    statement: { title: 'Statements' },
    taxForms: { title: 'Tax Forms' },
    documents: { title: 'Documents' },
  };

  // Settings to drag and drop alerts.
  alertOptions = {
    beforeDrop: (event: any) => {
      if (this.isDragging) {
        const alertUI: AlertsUI = event.source.nodeScope.$modelValue;
        this.addAlert(alertUI);
      }

      this.isDragging = false;

      return false;
    },
  };

  dragOptions = {
    accept: () => {
      this.isDragging = true;
    },
  };

  private menuAlertAvailable = false;
  private lastSmsConfiguration: SmsConfiguration={transactionsNumber:0} as SmsConfiguration;
  private subsink = new SubSink();
  //DMC props

  userPhoneNumber: string;
  flagtest: boolean = false;
  userInfo: UserBasicInfo = {phoneNumber:'', email:''} as UserBasicInfo;
  emailEnabled: boolean;
  userObj = {
    EmailAddress: '',
    PhoneNumber: '',
  };
  smsObject = {
    title: '',
    successMsg: '',
  };
  accessCode: number;
  regex: Object;
  email: string;

  isLoadingEmail: boolean;
  phoneError = false;
  gettingCode = false;
  sendingCode = false;
  errorCode = false;
  updateAlertSMS = false;
  expanded = false;
  isDisableSMS = false;
  otpValidationMessage = '';
  onlinePrivacyPolicyLink = '';
  smsOptInBrandInfo: ISmsOptInBrandInfo = {};
  enrollmentBrandTradeMark = '';
  deliveryMethodSettings: AlertsDeliveryMethodSetting = {
    id: '',
    emailAlertEnabled: false,
    smsAlertEnabled: false,
    setDeliveryMethodSettings: false,
  } as AlertsDeliveryMethodSetting;
  smsTermsAndConditionsBrand = '';
  savingEmailSetting = false;
  savingEmailSetting$:BehaviorSubject<boolean>= new BehaviorSubject(false);
  savingSMSSetting = false;
  savingSMSSetting$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  openPopup$:BehaviorSubject<boolean>=new BehaviorSubject(false);
  truncatedEmail: string;
  showEmailTooltip = false;
  // flag for IE explorer to be able to display border for input[type=checkbox] element
  deliveryMethodWasCanceled = false;
  // brandName: string;
  smsBusiness: string;

  private deliveryMethodSettingsCopy: AlertsDeliveryMethodSetting = {emailAlertEnabled:false} as AlertsDeliveryMethodSetting;
  private smsEnabledBy: string;
  private maxLenghtEmailTooltip = 30;
  // private subsink = new SubSink();
  isEditing: boolean = false;
  multifactorServiceEvent:boolean = false;
  smsModalLoading$:BehaviorSubject<boolean>= new BehaviorSubject(false);
  smsTooltip:string = `<div style='font-weight:400; text-align:center'>Tip: To transfer
                             money from your accounts,
                             text TRANS plus the dollar amount.
                             Example: Text <b>TRANS 100</b> to transfer $100.</div>`;
  actTooltip:string = `<div style='font-weight:400; text-align:center'>Tip: To activate your
                             debit card, text ACT plus the last 4 digits of the debit card plus
                             the last 4 digits of your SSN. Example: <b>ACT 1234 4321</b></div>`;
  constructor(
    @Inject('$scope') private readonly scope: ng.IScope,
    @Inject(ALERTSNOTIFICATIONSSERVICE) private readonly alertsNotificationsService: IAlertsNotificationsService,
    @Inject(ALERTSSMSSERVICE) private readonly alertsSmsService: IAlertsSmsService,
    private readonly cachedAccountsService: CachedAccountsService,
    @Inject(ROOT_SCOPE) private readonly rootScope: ng.IRootScopeService,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    private readonly supportViewFacade: SupportViewFacade,
    @Inject(olbSettings) private readonly env: OlbSettings,
    @Inject(DELIVERYMETHODSERVICE) private deliveryMethodService: IDeliveryMethodService,
    @Inject(MULTIFACTORSERVICE) private multifactorService: IMultifactorService,
    @Inject(LOADUSERPROFILEHELPER) private readonly loadUserProfileHelper: LoadUserProfileHelper,
    @Inject(BRANDPROPERTYSERVICE) private readonly brandPropertyService: BrandPropertyService,
    @Inject(FILTER)private readonly filter: ng.IFilterService,
  ) {
    this.regex = {
      email: new RegExp('^[_a-z0-9-]+(\\.[_a-z0-9-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,15})$'),
    };
  }

  ngOnInit(): void {
    this.supportViewFacade.getRIAUserFlag();
    this.supportViewFacade.axosAccountsLoading$.subscribe(isLoading => {
      this.noAxosAccountsIsLoading = isLoading;
      if (!isLoading) {
        this.supportViewFacade.noAxosAccounts$.subscribe(result => (this.noAxosProducts = result));
      }
    });
    this.closeAccountCodes = ['2', '3', '9', '12', '24', '19'];

    // SUBSCRIBES TO getRiaAccounts Selector
    this.subsink.sink = this.store.select(getAxosAdvisoryAccounts).subscribe((riaAccounts: AxosAdvisoryAccount[]) => {
      this.riaAccounts = riaAccounts;
    });

    const { internalAccounts } = angular.copy(this.cachedAccountsService.allAccounts);

    if (internalAccounts) {
      this.configureAccountsAlerts(internalAccounts);
      this.setUpSmsTable();
    }

    this.rootScope.$on('balancesAvailable', () => {
      this.configureAccountsAlerts(angular.copy(this.cachedAccountsService.allAccounts.internalAccounts));
      this.setUpSmsTable();
    });
    this.scope.$on('loadUserAlerts', () => {
      this.loadUserAlerts();
    });

    this.scope.$on('showLoadingAlert', (_event: any, show: boolean) => {
      this.showLoadingUpdateAlertAnimation(show);
    });

    this.scope.$on('showEditingAlert', (_event: any, show: boolean) => {
      this.editingAlert = show;
    });

    this.scope.$on('restoreAlerts', (_event: any, active: boolean) => {
      if (!this.availableAlerts.find(x => x.url === 'taxformsalerts') && !active) {
        this.restoreTaxtStatementAlert(this.getTaxAlert());
      }
      if (!this.availableAlerts.find(x => x.url === 'statementsalerts') && !active) {
        this.restoreTaxtStatementAlert(this.getStatementAlert());
      }
    });

    this.scope.$on('enableSMS', (_event: any, enabled: boolean) => {
      this.scope.$broadcast('enableSMSNotifications', enabled);
    });

    // $('.alerts-notifications').removeClass('alert-history-padding');

    //DMC OnInit

    this.alertsNotificationsService.getBrandContactInfo().then(x => {
      this.smsOptInBrandInfo.customerServiceMail = x.data.email;
      this.smsOptInBrandInfo.customerServicePhone = x.data.phoneNumber;
      this.smsOptInBrandInfo.displayName = x.data.name;
      this.onlinePrivacyPolicyLink = x.data.onlinePrivacyPolicyLink;
    });

    this.otpValidationMessage = 'Please enter 6 digits';

    switch (this.env.facingBrandId) {
      case FacingBrand.Axos:
        this.brandName = 'Axos Bank';
        break;
      case FacingBrand.Nationwide:
        this.brandName = 'Nationwide by Axos Bank';
        break;
      case FacingBrand.UFB:
        this.brandName = 'UFB Direct';
        break;
    }

    this.loadBrandProperties(this.env.brandId, this.env.brand);
    this.rootScope['userInfo']
      ? this._setUserInfo()
      : this.scope.$on('userInfoLoaded', () => {
          this._setUserInfo();
        });

    this._getDeliveryMethodSettings();

    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.expanded = true;
    } else {
      this.expanded = false;
    }

    this.scope.$on('enableMasterSwitch', (_event: any, type: string) => {
      this.setEmailNotificationSetting(type, true);
    });

    this.scope.$on('setUpSMSMasterSwitch', (_event: any, type: string) => {
      this.smsEnabledBy = type;

      if (!this.deliveryMethodSettings.smsAlertEnabled) {
        this.updateAlertSMS = true;
        this.openTextModal(type);
      } else {
        this.scope.$emit('enableSMS', true);
      }
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(profileInfo => {
        this.smsBusiness = profileInfo.businessInformation ? profileInfo.businessInformation.cellPhone.number : '';
        this._setUserInfo();
      });
  }

  $onDestroy(): void {
    this.subsink.unsubscribe();
  }
  addAlert(alertWidget: AlertsUI) {
    if (!this.loadingUserAlerts && !this.loadingUpdateUserAlerts) {
      if (this.getAlertTitleExists(alertWidget.title)) {
        this.availableAlerts = this.availableAlerts.filter(item => item.title !== alertWidget.title);
      }

      if (this.userAlerts.length > 0) {
        const firstAlert = this.userAlerts[0];

        if (firstAlert.title === this.alertsObj.statement.title && firstAlert.isNew) {
          this.availableAlerts.push(this.getStatementAlert());
        } else if (firstAlert.title === this.alertsObj.taxForms.title && firstAlert.isNew) {
          this.availableAlerts.push(this.getTaxAlert());
        }
      }

      // set id for alert ui on ng-repeat loop
      alertWidget.id = 'alert-' + Math.random();
      alertWidget.isNew = true;
      // position alert on top of the list
      this.userAlerts.unshift(angular.copy(alertWidget));
      const verify = this.getArrayLength();
      if (verify >= 2 && !this.savingAlert) {
        this.userAlerts.splice(1, 1);
      } else if (this.savingAlert && verify >= 2 && this.oldAlert) {
        this.userAlerts.splice(1, 1);
      } else if (this.savingAlert && verify >= 3) {
        this.userAlerts.splice(1, 1);
      }
      this.editingAlert = true;
      this.saveSuccess = [];
      this.isNewAlert = true;
    }
  }

  removeAlert(a: AlertsUI) {
    this.removeAlert$.next(true);
    this.alertsNotificationsService
      .deleteAlert(a.alert.alertId)
      .then(() => {
        const indexToDelete = this.userAlerts.indexOf(a);
        this.userAlerts.splice(indexToDelete, 1);

        if (this.isStatementAlert(a.alert.alertType) || this.isTaxAlert(a.alert.alertType)) {
          this.restoreTaxtStatementAlert(
            a.alert.alertType === this.getStatementDescription() ? this.getStatementAlert() : this.getTaxAlert()
          );
        }
      })
      .catch(() => {
        this.scope.$root.$broadcast('errorDeleteAlert', a.alert.alertId);
      })
      .finally(() => {
        this.removeAlert$.next(false);
        this.deletingAlert = false;
      });

  }

  onchangeClick(){
    this.deletingAlert = !this.deletingAlert
  }

  dragOut() {
    this.isDragging = false;
  }

  // hide Alets success message
  addmsgBox(saveSuccess:string):void{
    this.saveSuccess.push(saveSuccess)
  }
  deletemsgBox(): void {
    this.saveSuccess.pop();
  }

  hideCustomizeBox(): void {
    const verify = this.getArrayLength();
    if (verify === 0) {
      this.editingAlert = false;
    }
  }

  getStatementAlert(): AlertsUI {
    return new AlertsUI(
      {
        alertId: '',
        alertType: 'Statement Notification Account',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      true
    );
  }

  getDocumentsAlert(): AlertsUI {
    return new AlertsUI(
      {
        alertId: '',
        alertType: 'Documents',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      true
    );
  }

  getTaxAlert(): AlertsUI {
    return new AlertsUI(
      {
        alertId: '',
        alertType: 'Taxform',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      true
    );
  }

  showLoadingAlertAnimation(showLoading: boolean): void {
    this.loadingUserAlerts = showLoading;
    this.loadingUserAlerts$.next(showLoading);
  }

  showLoadingUpdateAlertAnimation(showLoading: boolean): void {
    this.loadingUpdateUserAlerts = showLoading;
  }

  fromChange(data: DropdownItem): void {
    this.dropToLoading$.next(true);
    this.dropTo = [];
    this.fromAccount = data;
    this.savingAccTrans = true;
    this.toAccount = new DropdownItem('[Select Account]');
    this.fillToAccounts(data.id);
  }

  toChange(data: DropdownItem): void {
    this.toAccount = data;
    this.editAccMode = true;
  }

  chngTransNumber(): void {
    this.editMode = false;
    this.savingTrans = true;
    this.savingTrans$.next(true);
    this.alertsSmsService.saveConfiguration(this.lastTrans).then(res => {
      if (res.status === 'Success') {
        this.savingTrans = false;
        this.savingTrans$.next(false);
        this.saveSmsSuccess = true;
        this.saveSmsSuccess$.next(true);
        this.lastSmsConfiguration = res.data as SmsConfiguration;
      }
    });
  }

  cancelAlertEvent(index:number){
    if(this.userAlerts[index].title === this.alertsObj.statement.title) { //Statements
      this.availableAlerts.push(this.getStatementAlert());
    }
    else if(this.userAlerts[index].title === this.alertsObj.taxForms.title) { //TaxForms
      this.availableAlerts.push(this.getTaxAlert());
    }
   this.userAlerts.splice(index, 1);
   this.editingAlert = false;
   this.isNewAlert = false;
  }

  cancelTransNumber(): void {
    this.lastTrans = this.lastSmsConfiguration.transactionsNumber;
    this.editMode = false;
  }

  cancelTransAccounts(): void {
    this.editAccMode = false;
    this.savingAccTrans = true;
    this.dropTo = [];
    this.toAccount.id = this.lastSmsConfiguration.toAccountId;
    this.fromAccount.id = this.lastSmsConfiguration.fromAccountId;
    this.fillFromAccounts();
    if (this.toAccount.id === 0) this.toAccount = new DropdownItem('[Select Account]');
    if (this.fromAccount.id === 0) this.fromAccount = new DropdownItem('[Select Account]');
  }

  changeSmsConfiguration(): void {
    const currentConfig = {} as SmsConfiguration;
    currentConfig.transactionsNumber = this.lastTrans;
    currentConfig.toAccountId = parseInt(this.toAccount.id.toString(), 10);
    currentConfig.fromAccountId = parseInt(this.fromAccount.id.toString(), 10);
    this.editAccMode = false;
    this.savingAccTrans = true;
    this.alertsSmsService.saveAccountsConfiguration(currentConfig).then(res => {
      if (res.status === 'Success') {
        this.savingAccTrans = false;
        this.saveSmsSuccess = true;
        this.saveSmsSuccess$.next(true);
        this.lastSmsConfiguration.toAccountId = currentConfig.toAccountId;
        this.lastSmsConfiguration.fromAccountId = currentConfig.fromAccountId;
      }
    });
  }

  isStatementAlert(alertType: string): boolean {
    return alertType === this.getStatementDescription();
  }

  isTaxAlert(alertType: string): boolean {
    return alertType === this.getTaxDescription();
  }

  // Checks if it's a RIA account
  // TRUE: Displays DocumentsAlert - FALSE: Don't displays Documents Alert
  isDocumentAlert(alertType: string): boolean {
    if (this.riaAccounts !== null && this.riaAccounts.length > 0) {
      return alertType === this.getDocumentDescription();
    } else {
      return false;
    }
  }

  getDocumentDescription(): string {
    return 'Documents';
  }

  getStatementDescription(): string {
    return 'Statement Notification Account';
  }

  getTaxDescription(): string {
    return 'Taxform';
  }

  checkAvailableAlerts(newAlertUI: AlertsUI): void {
    // regenerate collection to avoid to trigger watch() method, but just regenerate saved alerts
    // it is needed to keep also the alerts that are in edit mode.
    let alertsRegistered = [...this.userAlerts].filter(a => a.alert.alertId && !a.editMode);
    alertsRegistered = alertsRegistered.map(a => new AlertsUI(a.alert));

    this.userAlerts = this.userAlerts.map(a => {
      const al = alertsRegistered.find(ab => ab.alert.alertId === a.alert.alertId);

      return al ? al : a;
    });

    if (this.isStatementAlert(newAlertUI.alert.alertType) || this.isTaxAlert(newAlertUI.alert.alertType)) {
      this.availableAlerts = [...this.availableAlerts].filter(av => av.alert.alertType !== newAlertUI.alert.alertType);
    }
  }

  private getArrayLength(): number {
    const alertsArray: string[] = [];
    this.userAlerts.forEach(item => {
      if (item.alert.alertId === '') {
        alertsArray.push('1');
      }
    });

    return alertsArray.length;
  }

  // Sets the default available alerts.
  private configureInitialAlertTypes(): AlertsUI[] {
    const alertsArray: Alerts[] = [
      {
        alertId: '',
        alertType: 'Balance Snapshot',
        frequency: '',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Large Withdrawal',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Large Deposit',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Check Cleared',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Low Balance',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'High Balance',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Maturity Date',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Loan Payment Due Date',
        accountNumber: '',
        accountType: 'Loan',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
      {
        alertId: '',
        alertType: 'Loan Overdue',
        accountNumber: '',
        accountType: '',
        threshold: '',
        sendConfirmationEmail: true,
        sendConfirmationText: false,
        pushNotification: false,
      },
    ];
    const availableAlertsArray: AlertsUI[] = [];

    alertsArray.forEach((obj: Alerts) => {
      const alertUI = new AlertsUI(obj, true);
      availableAlertsArray.push(alertUI);
    });

    availableAlertsArray.push(this.getStatementAlert());
    availableAlertsArray.push(this.getTaxAlert());
    if (this.featureFlagService.isRiaPilotDocumentAlert()) {
      availableAlertsArray.push(this.getDocumentsAlert());
    }

    return availableAlertsArray;
  }

  private restoreTaxtStatementAlert(alert: AlertsUI) {
    // check if at exist one of them
    if (
      this.availableAlerts.findIndex(
        av => this.isStatementAlert(av.alert.alertType) || this.isTaxAlert(av.alert.alertType)
      ) !== -1
    ) {
      // preserve original order
      if (this.isTaxAlert(alert.alert.alertType)) this.availableAlerts.push(alert);
      else this.availableAlerts.splice(this.availableAlerts.length - 1, 0, alert);
    } else this.availableAlerts.push(alert);
  }

  /**
   * Get User Accounts, get user accounts categories and fill alert dropdowns list.
   */
  private configureAccounts(olbAccounts: OlbAccount[]): void {
    this.accounts = [];
    // If the status code is one of the defined close status
    // do not add it to accounts list
    olbAccounts.forEach((activeAccount: OlbAccount) => {
      let isActive = true;

      this.closeAccountCodes.forEach(closeCode => {
        if (activeAccount.statusCode === closeCode) {
          isActive = false;

          return false;
        }

        return false;
      });

      if (isActive) this.accounts.push(activeAccount);
    });
  }

  private GetAccountCatetories(): number[] {
    let accountCategories: number[] = [];

    // Get the user accounts categories
    this.accounts.forEach((account: OlbAccount) => {
      // Quick fix to match the new categories (admin sections)
      accountCategories.push(account.category === 2 ? 1 : account.category);
    });

    // Filter available alerts for user Account types
    accountCategories = [...accountCategories].filter((item: number, pos: number) => {
      return accountCategories.indexOf(item) === pos;
    });

    return accountCategories;
  }

  private ConfigureAvailableAlerts(alertsRegistered: Alerts[]): void {
    // set all alert types
    let availableAlerts = this.configureInitialAlertTypes();

    availableAlerts = this.SetAvailableAlertsByAccountCatetory(availableAlerts);
    this.availableAlerts = this.SetAvailableAlertsByAlertsRegistered(availableAlerts, alertsRegistered);
    this.menuAlertAvailable = true;
  }

  private SetAvailableAlertsByAccountCatetory(availableAlerts: AlertsUI[]): AlertsUI[] {
    this.accountCategories = this.GetAccountCatetories();
    const alerts: AlertsUI[] = [];
    availableAlerts = [...availableAlerts].filter((alert: AlertsUI) => {
      if (
        this.isStatementAlert(alert.alert.alertType) ||
        this.isTaxAlert(alert.alert.alertType) ||
        this.isDocumentAlert(alert.alert.alertType) ||
        this.accountCategories.indexOf(alert.category) !== -1
      ) {
        alerts.push(alert);
      }
    });

    return alerts;
  }

  private SetAvailableAlertsByAlertsRegistered(availableAlerts: AlertsUI[], alertsRegistered: Alerts[]): AlertsUI[] {
    let taxtStatementAlerts: Alerts[] = [];

    taxtStatementAlerts = alertsRegistered.filter(
      a => this.isStatementAlert(a.alertType) || this.isTaxAlert(a.alertType)
    );

    // removing Statement notification or Taxt Form if already exist
    availableAlerts = [...availableAlerts].filter(
      a => !taxtStatementAlerts.length || taxtStatementAlerts.findIndex(tx => tx.alertType === a.alert.alertType) === -1
    );

    return availableAlerts;
  }

  /**
   * set alerts created
   */
  private configureAlerts(alertsRegistered: Alerts[]): void {
    // reset this.saveSuccess array
    this.saveSuccess = [];
    // reset this.userAlerts property, to set user alerts correctly
    if (this.userAlerts && this.userAlerts.length) this.userAlerts = [];
    alertsRegistered.forEach(a => this.userAlerts.push(new AlertsUI(a)));
  }

  //#region User sms configuration section
  private setUpSmsTable(): void {
    this.brandName = '';

    if (this.rootScope['brandProperties']) {
      this.brandName = this.rootScope['brandProperties'].BrandDisplayName;
    } else {
      this.rootScope.$on('brandPropertiesLoaded', () => {
        this.brandName = this.rootScope['brandProperties'].BrandDisplayName;
      });
    }

    // Set default values before loading current user configuration
    this.alertsSmsService.getConfiguration().then(response => {
      const data = response.data as SmsConfiguration;
      this.lastTrans = data.transactionsNumber;
      this.toAccount.id = data.toAccountId;
      this.fromAccount.id = data.fromAccountId;
      this.lastSmsConfiguration = data;
      this.fillFromAccounts();
    });
  }


  dropFromDisplayed=[];
  private fillFromAccounts() {
    const { internalAccounts } = this.cachedAccountsService.getFromAccounts();
    // Load the drop down items
    this.dropFrom = internalAccounts.map(
      (account: OlbAccount) =>{
        let currency = this.filter('currency')(account.availableBalance)
        return  {'id':account.id,'text':account.nickname,'description': currency}
      }
    );
    // Select Current FROM Alert Account in case the user already has one selected
    if (this.fromAccount.id !== null && this.fromAccount.id !== 0) {
      const aux = this.fromAccount;
      this.fromAccount = this.dropFrom.filter((a: DropdownItem) => {
        return a.id === this.fromAccount.id;
      })[0];
      if (this.fromAccount !== undefined) this.fillToAccounts(this.fromAccount.id);
      // Load TO accounts depending on the FROM account currently selected by the user
      else {
        this.fromAccount = aux;
        this.editAccMode = false;
        this.savingAccTrans = false;
      }
    } else {
      this.editAccMode = false;
      this.savingAccTrans = false;
      this.fromAccount = new DropdownItem('[Select Account]');
    }
  }

  private fillToAccounts(accountID: any): void {
    this.alertsNotificationsService.getToAccounts(accountID).then(res => {
      const internalToAccountsArray = res.data.internalAccounts as OlbAccount[];
      if (internalToAccountsArray !== null) {
        this.dropTo = internalToAccountsArray
          .filter(a => a.category !== AccountCategory.Loan)
          .map((account: OlbAccount) => {
            let currency = this.filter('currency')(account.availableBalance)
            return  {'id':account.id,'text':account.nickname,'description': currency}
          });
        // Select Current TO Alert Account in case the user already has one selected
        if (this.toAccount.id !== null && this.toAccount.id !== 0 && this.toAccount.id !== undefined) {
          this.toAccount = this.dropTo.filter((a: DropdownItem) => {
            return a.id === this.toAccount.id;
          })[0];
        }
        this.editAccMode = false;
        this.savingAccTrans = false;
        this.dropIsLoading$.next(false);
      }
      this.dropToLoading$.next(false);
    });
  }
  //#endregion

  private configureAccountsAlerts(olbAccounts: OlbAccount[], configAlerts: boolean = true): void {
    this.showLoadingAlertAnimation(true);

    const alertsPromise: ApiResponse<Alerts[]> = this.alertsNotificationsService.getAlerts();

    Promise.all([alertsPromise])
      .then((result: any) => {
        this.configureAccounts(olbAccounts);

        const alerts = result[0].data.data ? result[0].data.data : result[0].data;
        if (!this.menuAlertAvailable) {
          this.ConfigureAvailableAlerts(alerts);
        }

        if (configAlerts) {
          this.configureAlerts(alerts);
          this.removeAlertForClosedAccounts();
        }

        // this loader is used for alert menu
        this.isLoading = false;
        this.isLoading$.next(false);
        // hide loading master switch animation
        if (configAlerts) {
          this.showLoadingAlertAnimation(false);
        }
      })
      .catch(() => {
        // hide loading master switch animation
        this.showLoadingAlertAnimation(false);
        // this loader is used for alert menu
        this.isLoading = false;
        this.isLoading$.next(false);
      });
  }

  private loadUserAlerts(): void {
    this.showLoadingUpdateAlertAnimation(true);
    this.alertsNotificationsService
      .getAlerts()
      .then((result: any) => {
        const alerts = result.data.data ? result.data.data : result.data;

        this.configureAlerts(alerts);
        this.removeAlertForClosedAccounts();
        this.showLoadingUpdateAlertAnimation(false);
      })
      .catch(() => {
        this.showLoadingUpdateAlertAnimation(false);
      });
  }

  private removeAlertForClosedAccounts(): void {
    if (this.accounts.length === 0 || this.userAlerts.length === 0) return;

    this.userAlerts.forEach((userAlert, inx) => {
      if (userAlert.alert.accountNumber && userAlert.alert.accountType) {
        const openAccount = this.accounts.filter(account => {
          return userAlert.alert.accountNumber === account.accountNumber;
        });
        if (openAccount.length === 0) this.userAlerts.splice(inx, 1);
      }
    });
  }

  private getAlertTitleExists(alertTitle: string): boolean {
    return this.alertsTitles.includes(alertTitle);
  }

  //DMC Component
  maskPhone(phone: string) {
    return maskPhone(phone?.replace('-', ''));
  }

  /** Constrains the inputs to allow only numbers
   * @param $event The event triggered by the input
   */
  allowOnlyNumbers($event: any): void {
    const isModifierkeyPressed = $event.metaKey || $event.ctrlKey || $event.shiftKey;
    const isCursorMoveOrDeleteAction = [46, 8, 37, 38, 39, 40].indexOf($event.keyCode) !== -1;
    const isNumKeyPressed =
      ($event.keyCode >= 48 && $event.keyCode <= 58) || ($event.keyCode >= 96 && $event.keyCode <= 105);
    const vKey = 86;
    const cKey = 67;
    const aKey = 65;
    const isEnterPressed = $event.keyCode === 13 ? true : false;
    const isTabPressed = $event.keyCode === 9 ? true : false;
    switch (true) {
      case isCursorMoveOrDeleteAction:
      case isModifierkeyPressed === false && isNumKeyPressed:
      case ($event.metaKey || $event.ctrlKey) && [vKey, cKey, aKey].indexOf($event.keyCode) !== -1:
        break;
      case isEnterPressed:
        break;
      case isTabPressed: {
        const active = document.activeElement as HTMLElement;
        if (active.classList[0] === 'modal-access-input') {
          const elem = document.getElementById('helper');
          elem.focus();
          $event.preventDefault();
        }
        break;
      }
      default:
        $event.preventDefault();
    }
  }
  /** Catches the keypress event on the RESEND button
   * @param $event The event triggered by pressing a key when the focus is in the RESEND label
   */
  resendKeyPress($event: any): void {
    if ($event.keyCode === 13 || $event.keyCode === 32) {
      // If enter or space is pressed the method should be trigger
      this.resendCode();
    } else if ($event.shiftKey && $event.keyCode === 9) {
      // Reverse tabing over resend should give focus to the input
      const elem = document.getElementsByClassName('modal-access-input')[0] as HTMLElement;
      elem.focus();
      $event.preventDefault();
    }
  }

  setEmailNotificationSetting = (type: string, enabled: boolean) => {
    if (type === 'alert') this.deliveryMethodSettings.emailAlertEnabled = enabled;

    // prevent make request when deliveryMethodSettings object has not changes
    const hasChanges =
      this.deliveryMethodSettingsCopy.emailAlertEnabled !== this.deliveryMethodSettings.emailAlertEnabled;
    if (hasChanges) this.deliveryMethodSettingsCopy = { ...this.deliveryMethodSettings };

    const deliveryPromise = new Promise(resolve => {
      if (hasChanges) {
        resolve(this.deliveryMethodService.setDeliveryMethodSettings(this.deliveryMethodSettings, false));
      } else resolve('update not required for delivery method settings');
    });

    const alertsPromise = new Promise(resolve => {
      const alertsEmailNotification: AlertsNotificationSetting = {
        notificationType: 'Email',
        enable: false,
      };

      if (!enabled) {
        resolve(this.alertsNotificationsService.configureAlertsNotification(alertsEmailNotification));
      } else resolve('no alerts update required');
    });

    if (type === 'delivery') {
      this.savingEmailSetting = true;
      this.savingEmailSetting$.next(true);
      if (!this.deliveryMethodSettings.emailAlertEnabled) {
        this.scope.$emit('showLoadingAlert', true);
      }

      Promise.all([deliveryPromise, alertsPromise])
        .then(() => {
          this.scope.$emit('showEditingAlert', false);
          this.scope.$emit('restoreAlerts', this.deliveryMethodSettings.emailAlertEnabled);
          this.savingEmailSetting = false;
          this.savingEmailSetting$.next(false);
          if (!this.deliveryMethodSettings.emailAlertEnabled) this.scope.$emit('loadUserAlerts');
          this.scope.$digest();
        })
        .catch(() => {
          this.savingEmailSetting = false;
          this.savingEmailSetting$.next(false);
          this.scope.$emit('showLoadingAlert', false);
          this.scope.$digest();
        });
    }
  };

  setSMSNotificationSetting = (type: string, enabled: boolean, openPopup: boolean) => {
    this.openPopup$.next(true)
    if(openPopup==false){this.openPopup$.next(false)}
    this.flagtest = true;
    if (this.rootScope['brandProperties']) {
      this.enrollmentBrandTradeMark = this.rootScope['brandProperties']['EnrollmentBrandTradeMark'];
    } else {
      this.rootScope.$on('brandPropertiesLoaded', function () {
        this.enrollmentBrandTradeMark = this.rootScope['brandProperties']['EnrollmentBrandTradeMark'];
      });
    }
    this.deliveryMethodWasCanceled = false;
    this.deliveryMethodSettings.smsAlertEnabled = enabled;
    if (!this.deliveryMethodSettings.smsAlertEnabled) {
      this.savingSMSSetting = true;
      this.savingSMSSetting$.next(true)
      this.updateAlerts(type);
    } else if (openPopup && this.deliveryMethodSettings.smsAlertEnabled) {
      this.openTextModal(type);
    }
  };

  hideDisableSMS(): void {
    this.isDisableSMS = false;
  }

  /** Open text message modal */
  openTextModal(type: string): void {
    this.multifactorServiceEvent = false;
    this.smsEnabledBy = type;
    this.smsObject.title = '<b>Mobile Number</b> to receive text messages';
    this.smsObject.successMsg = 'You have now opted in to receive text messages for your selected alerts.';

    // $('#smsAlerts').modal({
    //   backdrop: 'static',
    // });
    // $('#smsAlerts')['modal']('show');
  }

  /** Close text message modal */
  // closeTextModal(): void {
  //   $('#smsAlerts')['modal']('hide');
  // }

  /** Close text message modal */
  cancelSMSChanges(): void {
    this.userInfo.phoneNumber = this.rootScope['userInfo']['phoneNumber'];
    this.deliveryMethodWasCanceled = true;
    this.deliveryMethodSettings.smsAlertEnabled = false;
    this.updateAlertSMS = false;
    this.scope.$emit('enableSMS', false);
    // this.closeTextModal();
    // this.closeAccessCodeModal();
  }

  cancelSMSOTP(): void {
    this.cancelSMSChanges();
    this.setSMSNotificationSetting('none', this.deliveryMethodSettings.smsAlertEnabled, false);
  }

  // closeAccessCodeModal(): void {
  //   $('#accessCodeModal')['modal']('hide');
  // }

  openAccessCodeModal(): void {
    this.smsModalLoading$.next(true);
    if (!this.userInfo.phoneNumber || this.userInfo.phoneNumber.length < 10) {
      this.phoneError = true;
    } else {
      const request: MultiFactorRequest = {
        username: sessionStorage.getItem('username'),
        authenticationMethod: 1,
      };

      this.gettingCode = true;

      this.multifactorService
        .challenge(request)
        .then(() => {
          this.smsModalLoading$.next(false);
          this.multifactorServiceEvent = true;
        })
        .finally(() => {
          this.gettingCode = false;
        });
    }
  }

  resendCode(): void {
    this.smsModalLoading$.next(true)
    const request: MultiFactorRequest = {
      username: sessionStorage.getItem('username'),
      authenticationMethod: 1,
    };

    this.gettingCode = true;

    this.multifactorService.challenge(request).finally(() => {
      this.gettingCode = false;
    this.smsModalLoading$.next(false)
    });
  }

  submitAccessCode(form: NgForm): void {
    this.smsModalLoading$.next(true);
    this.accessCode = form.value.accessCode;

    if (form.valid) {
      this.sendingCode = false;
      this.errorCode = false;

      const request: MultiFactorRequest = {
        otp: this.accessCode.toString(),
        rememberDevice: false,
        username: sessionStorage.getItem('username'),
        authenticationMethod: 1,
      };

      this.deliveryMethodService
        .validateDeliveryOTP(request)
        .then((_response: any) => {
          this.sendingCode = true;
          this.accessCode = null;
          this.updateAlerts(this.smsEnabledBy);

          if (this.updateAlertSMS) {
            this.scope.$emit('enableSMS', true);
          }
        })
        .catch(() => {
          this.sendingCode = false;
          this.errorCode = true;
        })
        .finally(() => {
          this.smsModalLoading$.next(false);
        });
    }
  }

  closeModal(): void {
  this.deliveryMethodSettings.smsAlertEnabled = false;
  this.openPopup$.next(false);
  }

  clearObject(): void {
    this.smsObject.title = '';
    this.smsObject.successMsg = '';
  }

  /** Toggles the expansion of the section. */
  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  printConditions(): void {
    this.alertsNotificationsService
      .getSmsTermsPdf()
      .then((response: any) => {
        const bArray = this._base64ToArrayBuffer(response.data);
        const file = new Blob([bArray], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(file, 'TermsAndConditions.pdf');
        } else {
          window.open(fileURL);
        }
      })
      .catch((_: ApiError) => {
        console.error(_);
      });
  }

  private updateAlerts(type: string): void {
    if (type === 'alert') {
      this.deliveryMethodSettings.smsAlertEnabled = !this.deliveryMethodSettings.smsAlertEnabled;
    }

    const deliveryPromise = this.deliveryMethodService.setDeliveryMethodSettings(
      this.deliveryMethodSettings,
      type === 'none'
    );

    let alertsPromise: ng.IPromise<any>;

    const alertsSMSNotification: AlertsNotificationSetting = {
      notificationType: 'Sms',
      enable: false,
    };

    if (!this.deliveryMethodSettings.smsAlertEnabled && type === 'delivery') {
      alertsPromise = this.alertsNotificationsService.configureAlertsNotification(alertsSMSNotification);
    }
    alertsPromise = Promise.resolve('no alerts update required');

    if (type === 'delivery') {
      if (!this.deliveryMethodSettings.smsAlertEnabled) this.scope.$emit('showLoadingAlert', true);

      Promise.all([deliveryPromise, alertsPromise])
        .then(() => {
          this.scope.$emit('showEditingAlert', false);
          this.scope.$emit('restoreAlerts', this.deliveryMethodSettings.smsAlertEnabled);
          if (!this.deliveryMethodSettings.smsAlertEnabled) this.scope.$emit('loadUserAlerts');
          else this.scope.$emit('showLoadingAlert', false);

          this.savingSMSSetting = false;
          this.savingSMSSetting$.next(false);
          this.smsEnabledBy = '';
        })
        .catch(() => {
          this.restoreSavingSMSSettings();
        });
    } else {
      deliveryPromise
        .catch(() => {})
        .finally(() => {
          this.restoreSavingSMSSettings();
        });
    }
  }

  private restoreSavingSMSSettings(): void {
    this.scope.$emit('showLoadingAlert', false);
    this.savingSMSSetting = false;
    this.savingSMSSetting$.next(false)
    this.smsEnabledBy = '';
  }

  /**set cached user info */
  private _setUserInfo() {
    this.userInfo = this.rootScope['userInfo'];
    if (!this.userInfo) return;

    this.userInfo.phoneNumber = this.loadUserProfileHelper.formatPhone(
      this.loadUserProfileHelper.getUserProfile().cellPhone1
    );
    this.userPhoneNumber = formatPhone(this.getSmsPhoneNumber());
    this.userPhoneNumber$.next(true )
    // this._validatePhoneNumber(this.userInfo);
    this._validateEmail(this.userInfo.email);
  }

  private _getDeliveryMethodSettings() {
    this.savingEmailSetting = true;
    this.savingEmailSetting$.next(true);
    this.savingSMSSetting = true;
    this.savingSMSSetting$.next(true)
    this.deliveryMethodService
      .getDeliveryMethodSettings()
      .then((response: OlbResponse<AlertsDeliveryMethodSetting>) => {
        if (response.status === 'Success') {
          this.deliveryMethodSettings = response.data;
          this.deliveryMethodSettingsCopy = { ...response.data };
          this.savingEmailSetting = false;
          this.savingEmailSetting$.next(false);
          this.savingSMSSetting = false;
          this.savingSMSSetting$.next(false)
        }
      });
  }

  // private _validatePhoneNumber = (userInfo: UserBasicInfo) => {
  //   if (userInfo.phoneNumber) {
  //     const regex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);

  //     this.scope.$parent['$mac'].phoneNumberEnabled = regex.test(userInfo.phoneNumber);
  //   }
  // };

  private _base64ToArrayBuffer(base64: any): Uint8Array {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    return bytes;
  }

  private _validateEmail(email: string='') {
    if (email.length > this.maxLenghtEmailTooltip) {
      this.truncatedEmail = email.substring(0, this.maxLenghtEmailTooltip) + '...';
    }
    this.showEmailTooltip = true;
  }

  private loadBrandProperties(brandId: number, brand: string): void {
    this.brandPropertyService.getBrandProperties(brandId, brand).subscribe(() => {
      this.smsTermsAndConditionsBrand = this.rootScope['brandProperties']['SMSTermsAndConditions'];
    });
  }

  private getSmsPhoneNumber(): string {
    this.userInfo.phoneNumber = this.loadUserProfileHelper.formatPhone(
      this.loadUserProfileHelper.getUserProfile().cellPhone1
    );

    return this.userInfo.phoneNumber;
  }
}
