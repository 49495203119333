<div class="header-section-title text-center">
  <div>
    <h1>Alerts &amp; Notifications</h1>
    <p class="hide-cs hide-xs" id="padding-top-account">
      Receive customized text and email alerts for your key account activities.
    </p>
  </div>
</div>
<div class="frow link-container hide-cs hide-xs">
  <a class="bofi-link back-link" (click)="goBack()">
    <i class="fa fa-chevron-left"></i>
    Back
  </a>
</div>
<div class="hr-line"></div>
<section class="alert-history">
  <div class="frow filter-section-mobile hide-sm hide-md hide-lg">
    <a class="bofi-link back-link" (click)="goBack()">
      <i class="fa fa-chevron-left"></i>
      <span class="hide-cs">Back</span>
    </a>
    <h2 class="header-title-text">Alert History</h2>
    <div class="filter" (click)="this.mobileFilter = true">
      <i class="bofi-filter"></i>
      Filter
    </div>
  </div>
  <!-- end: new filter section mobile -->
  <div class="frow filter-section hide-cs hide-xs">
    <div class="fcol-xs-3">
      <h2 class="header-title-text">Alert History</h2>
    </div>
    <div class="fcol-xs-9 form-group display-flex">
      <label for="filter-by" class="filter-label">Filter by</label>
      <select
        [(ngModel)]="typeOfAlert"
        (ngModelChange)="getHistory()"
        id="alertTypes"
        name="alertTypes"
        class="type-dropdown"
      >
        <option *ngFor="let alertType of typeOfAlerts" [ngValue]="alertType.id">
          {{ alertType.title }}
        </option>
      </select>

      <select [(ngModel)]="days" (ngModelChange)="getHistory()" id="date-range" name="date-range" class="type-dropdown">
        <option *ngFor="let dayNumber of daysRange" [ngValue]="dayNumber">
          {{ dayNumber === '0' ? 'View All' : dayNumber + ' Days' }}
        </option>
      </select>
    </div>
  </div>
  <div class="alert-history-table table-container" [hidden]="this.mobileFilter">
    <div class="table-header frow hide-cs hide-xs hide--sm">
      <div class="fcol-md-2 text-center" (click)="changeSortType('createdDate')">
        <p>
          Date Sent
          <i *ngIf="this.sortType == 'createdDate' && this.sortReverse" class="fa fa-caret-down"></i>
        </p>
      </div>
      <div class="fcol-md-3 text-center" (click)="changeSortType('alertType')">
        <p>
          Alert Type
          <i *ngIf="this.sortType == 'alertType' && this.sortReverse" class="fa fa-caret-down"></i>
        </p>
      </div>
      <div class="fcol-md-7">
        <p>Description</p>
      </div>
    </div>
    <div class="table-body frow sm" *ngFor="let alert of filteredAlerts">
      <div class="fcol-md-2 text-center fcol-sm-4 fcol-xs-4 fcol-cs-4 header-small">
        <p class="text-bold">{{ alert.createdDate | date: 'MM/dd/yy' }}</p>
      </div>
      <div class="fcol-md-3 text-center fcol-sm-8 fcol-xs-8 fcol-cs-8 header-small">
        <p class="text-bold">{{ alert.alertType }}</p>
      </div>
      <div class="fcol-md-7 body-small" [ngSwitch]="alert.alertType">
        <div *ngSwitchCase="'High Balance'">
          <p>
            Your
            <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
            balance is
            <b>{{ alert.balanceSnapshot | currency }}</b>
            . This alert was sent because the balance was above
            <b>{{ getNumericValue(alert.thresholdValue) | currency }}</b>
          </p>
        </div>
        <div *ngSwitchCase="'Low Balance'">
          <p>
            Your
            <b>{{ alert.productType }} *{{ alert.account.substring(alert.account.length - 4) }}</b>
            balance is
            <b>{{ alert.balanceSnapshot | currency }}</b>
            . This alert was sent because the balance was below
            <b>{{ getNumericValue(alert.thresholdValue) | currency }}</b>
          </p>
        </div>
        <div *ngSwitchCase="'Large Deposit'">
          <p>
            A deposit to
            <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
            of
            <b>{{ getNumericValue(alert.amount) | currency }}</b>
            was made. This alert was sent because this deposit was above
            <b>{{ getNumericValue(alert.thresholdValue) | currency }}</b>
          </p>
        </div>
        <div *ngSwitchCase="'Large Withdrawal'">
          <p>
            A withdrawal from
            <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
            of
            <b>{{ getNumericValue(alert.amount) | currency }}</b>
            has occurred. This alert was sent because this withdrawal was above
            <b>{{ getNumericValue(alert.thresholdValue) | currency }}</b>
          </p>
        </div>
        <div *ngSwitchCase="'Check Cleared'">
          <p>
            Check
            <b>{{ alert.checkNumber }}</b>
            from
            <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
            of
            <b>{{ getNumericValue(alert.thresholdValue) | currency }}</b>
            has cleared
          </p>
        </div>
        <div *ngSwitchCase="'Balance Snapshot'">
          <div *ngIf="alert.frequency == 'None'">
            <p>
              A
              <b>One Time</b>
              balance update
              <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
              balance is
              <b>{{ getNumericValue(alert.balanceSnapshot) | currency }}</b>
            </p>
          </div>
          <div *ngIf="alert.frequency == 'Every Week'">
            <p>
              A
              <b>Once a Week</b>
              balance update
              <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
              balance is
              <b>{{ getNumericValue(alert.balanceSnapshot) | currency }}</b>
            </p>
          </div>
          <div *ngIf="alert.frequency != 'Every Week' && alert.frequency != 'None'">
            <p>
              Your
              <b>{{ alert.frequency }}</b>
              balance alert for
              <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
              is
              <b>{{ getNumericValue(alert.balanceSnapshot) | currency }}</b>
            </p>
          </div>
        </div>
        <div *ngSwitchCase="'Loan Payment Due Date'">
          <p>
            <b>{{ getNumericValue(alert.amountDue) | currency }}</b>
            is due for
            <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
            on
            <b>{{ alert.thresholdDate | date: 'MM/dd/yyyy' }}</b>
          </p>
        </div>
        <div *ngSwitchCase="'Loan Overdue'">
          <p>
            <b>{{ getNumericValue(alert.thresholdValue) }}</b>
            overdue for
            <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
            as of
            <b>{{ alert.thresholdDate | date: 'MMM d, yyyy' }}</b>
          </p>
        </div>
        <div *ngSwitchCase="'Maturity Date'">
          <p>
            Your
            <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
            is maturing in
            <b>{{ alert.thresholdValue }}</b>
            {{ alert.thresholdValue === '1' ? 'day' : 'days' }}. Threshold set to
            <b>{{ alert.thresholdValue }}</b>
          </p>
        </div>
        <div *ngSwitchCase="'Statements'">
          <div *ngIf="alert.account == null">
            <p>{{ alert.description }}</p>
          </div>
          <div *ngIf="alert.account != null">
            <p>
              Your statement form for the account
              <b>{{ alert.productType }} **{{ alert.account.substring(alert.account.length - 4) }}</b>
              is ready
            </p>
          </div>
        </div>
        <div *ngSwitchCase="'Tax Form'">
          <p>Your tax form is ready</p>
        </div>
      </div>
    </div>
    <div class="no-alerts-div" *ngIf="isLoading">
      <app-dot-loader>Loading</app-dot-loader>
    </div>
    <div
      class="no-alerts-div"
      *ngIf="!this.isLoading && !(this.alertHistory.length > 0 || this.filteredAlerts.length > 0)"
    >
      <label class="no-alerts-label" *ngIf="!isLoading">You have no alerts.</label>
    </div>
    <div class="more-div" *ngIf="showSeeMore()">
      <a (click)="seeMore()" class="more-link link">See More</a>
    </div>
  </div>
  <div class="filter-modal" *ngIf="this.mobileFilter">
    <i class="bofi-close right" data-dismiss="modal" aria-label="Close" (click)="this.mobileFilter = false"></i>
    <h3 class="more-info-title">Filter Alert History</h3>
    <div class="hr-line"></div>
    <div class="frow filters">
      <div>
        <label for="types" class="uk-label">Alert Types</label>
        <select
          [(ngModel)]="typeOfAlert"
          (ngModelChange)="displayCounter = 10"
          id="alertTypes"
          name="alertTypes"
          class="type-dropdown"
        >
          <option *ngFor="let alertType of typeOfAlerts" [ngValue]="alertType.id">
            {{ alertType.title }}
          </option>
        </select>
      </div>
      <div>
        <label for="date" class="uk-label">Date Range</label>
        <select [(ngModel)]="days" id="date-range" name="date-range" class="type-dropdown">
          <option *ngFor="let dayNumber of daysRange" [ngValue]="dayNumber">
            {{ dayNumber === '0' ? 'View All' : dayNumber + ' Days' }}
          </option>
        </select>
      </div>
      <div>
        <button class="uk-btn solid secondary lg" (click)="applyMobileFilter()">
          Apply Filter
        </button>
        <button class="uk-btn outline primary lg" (click)="cancelMobileFilter()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</section>
