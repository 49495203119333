import { Inject } from '../decorators/decorators';
import { ObservableResponse } from 'models';
import { ObservableBaseService } from './observable-base.service';
import { IDocumentService } from './typings/IDocumentService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class DocumentService extends ObservableBaseService implements IDocumentService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'documents', _serviceHelper, q);
  }

  getDocuments(accountId: number, docTypes: string): ObservableResponse<Document> {
    return this.get(`${accountId}/types${docTypes}`, false);
  }

  getDocument(documentId: string, key: string): ObservableResponse<Document> {
    return this.get(`${documentId}/${key}`, false, false, '', 'arraybuffer');
  }

  getTaxForm(documentId: string, key: string, fileName: string, onFinally?: any): Promise<void> {
    return this.download(`tax-form/${documentId}/${key}/${fileName}`, {}, onFinally);
  }
}
