import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { Schema } from 'joi';

/**
 *
 * @description  factory function that produces a reactive form validator.
 * @param Joi object validation schema
 * @return reactive form validator
 * @example
 * yourForm = this.formBuilder.group({
 *    key1: 'initial Value 1',
 *    key2: 'initial Value 2',
 *    key3: 'initial Value 3',
 *  }, {
 *    // Add as validator to the whole form group.
 *    validators: createReactiveValidatorFromSchema(yourJoiObjectSchema)
 *  });
 */
export function createReactiveValidatorFromSchema(schema: Schema, abortEarly = false): ValidatorFn {
  return function reactiveValidator(group: UntypedFormGroup) {
    const { error } = schema.validate(group.value, { abortEarly });
    let responseValue: Record<string, string> = null;

    if (error && error.details) {
      responseValue = error.details.reduce((accumulator: Record<string, string>, current) => {
        const key = current.path.join('.');
        const control = group.get(key);

        // fill object error
        accumulator[key] = current.message;

        // Set error value on each control
        if (control && (control.touched || !control.pristine)) {
          const controlError = { [key]: accumulator[key] };
          control.setErrors(controlError);
          group.setErrors(controlError);
        }

        return accumulator;
      }, {});
    }

    return responseValue;
  };
}
