import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

import {
  Uk2DirectivesModule,
  Uk2MenuButtonModule,
  Uk2ModalModule,
  Uk2ServicesModule,
  Uk2TextTileModule,
  Uk2ValueMovementModule,
} from '@axos/uikit-v2-lib';

// * Use this module to export all @axos/uikit-v2 modules required for this feature
@NgModule({
  exports: [
    Uk2ValueMovementModule,
    Uk2ModalModule,
    Uk2ServicesModule,
    Uk2DirectivesModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    Uk2MenuButtonModule,
    Uk2TextTileModule,
  ],
})
export class ModelsUk2Module {}
