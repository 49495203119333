import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { Category, SubCategory } from '@app/support/models';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { SupportService } from '@app/support/services';
import { OlbSettings } from '@core/models';
import { DialogService } from '@core/services';
import { olbSettings } from '@core/tokens';
import { AlertsIcons } from '@shared/enums';
import { AccountTypeConstants } from '@legacy/accounts/account-type.constants';

@Component({
  selector: 'app-support-menu',
  templateUrl: './support-menu.component.html',
  styleUrls: ['./support-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SupportMenuComponent {
  @Input() categories: Category[];
  @Input() noAxosAccounts: boolean;
  @Output() itemSelect = new EventEmitter<SubCategory>();
  @Output() categorySelect = new EventEmitter<Category>();

  subCategories$: Observable<Category[]>;
  businessAccounts: AccountProfile[] = [];
  isLoading = false;
  showBusinessAccounts: boolean = false;
  showSubCategories: boolean = false;
  selectedCategory: Category;
  selectedBusinessAccount: AccountProfile;
  showSDTMessage = false;

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    private supportService: SupportService,
    private dialogService: DialogService
  ) {}

  public loadSubMenuOptions(category: Category): void {
    this.categorySelect.emit(category);
    this.selectedCategory = category;

    if (category.description === AccountTypeConstants.BUSINESS_DEPOSIT_ACCOUNTS) {
      this.loadBusinessAccounts();
    } else {
      this.loadSubCategories(this.selectedCategory);
      this.showBusinessAccounts = false;
      this.showSubCategories = true;
    }
  }

  private loadSubCategories(category: Category): void {
    this.isLoading = true;
    this.showSDTMessage = this.selectedCategory.description === 'Self-Directed Trading';
    this.subCategories$ = this.supportService.getSubCategories(category.id).pipe(
      map(res => {
        return res.data;
      }),
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  private loadBusinessAccounts(): void {
    this.isLoading = true;
    // Filter by business profile (1)
    const ss_businessProfiles = sessionStorage.getItem('userBusinesses');

    if (ss_businessProfiles !== undefined && ss_businessProfiles !== null) {
      this.businessAccounts = (JSON.parse(ss_businessProfiles) as AccountProfile[]).filter(up => up.profileType === 1);

      if (this.businessAccounts?.length == 0) {
        this.loadSubCategories(this.selectedCategory);
        this.showBusinessAccounts = false;
        this.showSubCategories = true;
      } else if (this.businessAccounts.length == 1) {
        this.emitBusinessAccountInfo(this.businessAccounts[0]);
        this.isLoading = false;
      } else {
        this.showBusinessAccounts = true;
        this.showSubCategories = false;
        this.isLoading = false;
      }
    }
  }

  public emitBusinessAccountInfo(businessAccount: AccountProfile): void {
    this.selectedBusinessAccount = businessAccount;
    this.loadSubCategories(this.selectedCategory);
    this.showBusinessAccounts = false;
    this.showSubCategories = true;
  }

  public emitSubcategoryInfo(subcategory: Category) {
    if (subcategory.url) {
      this.dialogService
        .open({
          okText: 'Continue',
          cancelText: 'Cancel',
          icon: AlertsIcons.ExclamationCircle,
          title: "You're continuing to another website",
          content: `
          <p class="d-none d-sm-block mb-4 text-muted">
            ${this.settings.brandName} does not endorse the information, content, presentation or accuracy of any other website and claims no responsibiity for it.
          </p>
          <div class="dialog__summary text-muted">
            You will now be directed to
            <p class="text-bold">${subcategory.url}</p>
          </div>
        `,
        })
        .afterClosed()
        .subscribe((complete: boolean) => {
          if (complete) {
            window.open(subcategory.url, '_blank', 'noopener,noreferrer');
          }
        });
    } else {
      const event: SubCategory = {
        id: subcategory.id,
        name: subcategory.description,
        categoryId: this.selectedCategory.id,
        categoryName: this.selectedCategory.description,
        businessId: this.selectedBusinessAccount?.id,
        businessName: this.selectedBusinessAccount?.name,
        url: null,
      };

      this.itemSelect.emit(event);
    }
  }
}
