import { AssetCategory } from '@app/axos-invest/enums';

export class AssetDisplay {
  category: AssetCategory;
  name: string;
  background: string;
  target: string;
  code: string;

  constructor(args?: Partial<AssetDisplay>) {
    Object.assign(this, args);
  }
}
