import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { SubSink } from '@axos/subsink';

import { TradeStockType } from '@app/axos-trading/enums';
import { Equity, PortfolioOverview, TradingAccount } from '@app/axos-trading/models';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import { DialogService } from '@core/services';
import { AlertsIcons, NavigationIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

@Component({
  selector: 'app-trade-stock-mutual-fund',
  templateUrl: './trade-stock-mutual-fund.component.html',
  styleUrls: ['./trade-stock-mutual-fund.component.scss'],
})
export class TradeStockMutualFundComponent implements OnDestroy, OnInit {
  @Input() stock: Equity;
  @Input() tradeType: TradeStockType;
  @Input() form: UntypedFormGroup;
  @Input() portfolioOverview: PortfolioOverview;
  @Input() account: TradingAccount;
  @Output() formSubmitted = new EventEmitter<void>();
  submitted: boolean;
  isMutualFund = false;
  isValidMutualForm = false;
  tradeTypes = TradeStockType;
  sharesOwned: number;
  displayMarketValidation = false;

  icons = {
    backIcon: NavigationIcons.ArrowBack,
  };
  dialogData = new DialogData({
    title: 'Discard Changes?',
    icon: AlertsIcons.ExclamationCircle,
    hasCloseButton: true,
    content: '<p> If you go back without saving, your changes will be </p> <p> discarded. </p>',
    okText: 'Discard Changes',
    cancelText: 'Cancel',
  });
  private subSink = new SubSink();
  constructor(private routerHelper: TradingRoutingHelperService, private dialogService: DialogService) {}

  ngOnInit() {
    this.createFunctionalityMutualFund();
    this.calculateNumberOfShares();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  submitMutual() {
    this.submitted = true;
    this.isValidMutualForm = this.form.invalid;
    if (this.isValidMutualForm) return;

    this.formSubmitted.emit();
  }

  goBackToStock() {
    if (this.form.dirty) {
      this.subSink.sink = this.dialogService
        .open(this.dialogData)
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.routerHelper.goToPositionStockDetail(this.account.id, this.stock.symbol);
          }
        });
    } else {
      this.routerHelper.goToPositionStockDetail(this.account.id, this.stock.symbol);
    }
  }

  private createFunctionalityMutualFund() {
    if (this.tradeType === TradeStockType.Buy) {
      this.subSink.sink = this.form.get('value').valueChanges.subscribe(value => this.onValidateBuyPrice(value));
    } else {
      this.subSink.sink = this.form.get('quantity').valueChanges.subscribe(() => this.onValidateShareAmount());
    }
  }

  private onValidateBuyPrice(buyPrice: number) {
    this.isValidMutualForm = buyPrice > this.portfolioOverview.buyingPower;
    if (this.isValidMutualForm) {
      this.form.get('value').setErrors({ insufficient: true });
    }
  }

  private onValidateShareAmount() {
    const shareAmount = this.form.get('quantity').value;
    this.isValidMutualForm = shareAmount > this.sharesOwned;
    if (this.isValidMutualForm) {
      this.form.get('quantity').setErrors({ insufficient: true });
    }
  }

  private calculateNumberOfShares() {
    const positions = this.portfolioOverview.positions?.filter(position => position.symbol === this.stock.symbol);
    this.sharesOwned = positions?.length > 1 ? positions.map(position => position.shares).reduce((a, b) => a + b) : 0;
    this.sharesOwned = positions?.length === 1 ? positions[0].shares : this.sharesOwned;
  }
}
