import { Component, EventEmitter, Output } from '@angular/core';
import { Uk2Tier1NavigationEnum, Uk2Tier1UtilityEnum } from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-about-holding-quotes-tile',
  templateUrl: './about-holding-quotes-tile.component.html',
  styleUrls: ['./about-holding-quotes-tile.component.scss'],
})
export class AboutHoldingQuotesComponent {
  @Output() onHoldingQuoteToggle = new EventEmitter<boolean>(false);

  Uk2Tier2ColoredWithAccentEnum = Uk2Tier1UtilityEnum;
  Uk2Tier1NavigationEnum = Uk2Tier1NavigationEnum;

  displayMessage = false;

  constructor() {}

  toggleMessage() {
    this.displayMessage = !this.displayMessage;
    this.onHoldingQuoteToggle.emit(this.displayMessage);
  }
}
