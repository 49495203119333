import { createReducer, on } from '@ngrx/store';

import * as OrderActions from './orders.actions';
import { OrdersState } from './orders.state';

export const initialState: OrdersState = {
  orders: [],
  currentOrderReference: '',
  positions: []
};
export const ordersReducer = createReducer<OrdersState>(
  initialState,
  on(OrderActions.loadOrdersSuccess, (state, action): OrdersState => {
    return {
      ...state,
      orders: action.orders
    };
  }),
  on(OrderActions.setCurrentOrderReference, (state, action): OrdersState => {
    return {
      ...state,
      currentOrderReference: action.currentOrderReference
    };
  }),
  on(OrderActions.loadPositionsSuccess, (state, action): OrdersState => {
    return {
      ...state,
      positions: action.positions
    };
  })
);

