import { BrandPropertyService } from '@legacy/services/brand-property.service';

export function brandPropertyServiceFactory($injector: any) {
  return $injector.get('brandPropertyService');
}

export const brandPropertyServiceProvider = {
  provide: BrandPropertyService,
  useFactory: brandPropertyServiceFactory,
  deps: ['$injector'],
};
