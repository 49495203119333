import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Goal } from '@app/axos-invest/models';
import { AxosInvestUrlsService } from '@app/axos-invest/services';
import { FinancialIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletComponent implements OnInit {
  @Input() wallet: Goal;

  icon = FinancialIcons.DollarBillStack;
  redirectIcon = UtilityIcons.NewWindow;
  $manageWalletUrl: Observable<string>;
  tooltip = 'Your wallet is a location where unallocated cash is stored.';

  constructor(private axosInvestUrlHelper: AxosInvestUrlsService) {}

  ngOnInit(): void {
    this.getManageWalletUrl();
  }

  private getManageWalletUrl() {
    this.$manageWalletUrl = this.axosInvestUrlHelper.getManageWalletUrl().pipe(take(1));
  }
}
