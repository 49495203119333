import { TransfersController } from './transfers.controller';
import { Inject } from '../decorators/decorators';

@Inject('$stateProvider')
export class TransfersConfig {
  constructor($stateProvider: ng.ui.IStateProvider) {
    $stateProvider.state('udb.transfers', {
      url: '/Transfers',
      views: {
        content: {
          templateUrl: 'transfers/transfers.tpl.html',
          controller: TransfersController,
          controllerAs: '$ctrl',
        },
      },
    });
  }
}
