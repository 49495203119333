import { IStateService } from 'angular-ui-router';
import { Inject } from 'decorators/decorators';
import { FeatureFlagService } from 'services/feature-flag.service';

@Inject('featureFlagService', '$state', '$scope')
export class AccountController {
  subTabs: Tab[];
  allAccounts: Tab;
  isAccountAggregationEnhanceActive = false;
  isPfm3Active = false;
  stateService: IStateService;
  constructor(
    private readonly featureFlagService: FeatureFlagService,
    private readonly state: IStateService,
    private $scope: ng.IScope,
  ) {}

  /** Initializes required data once the module is fully loaded. */
  $onInit(): void {
    this.stateService = this.state;
    this.isAccountAggregationEnhanceActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    this.isPfm3Active = this.featureFlagService.isPFM3FlagActive();

    // Every time state changes we need to check which tab should be bolded
    this.$scope.$on('$stateChangeSuccess', (event, _toState, _toParams, _fromState) => {
      if (_toState === _fromState) {
        event.preventDefault();
      } else {
        this.setActiveMenuTab();
      }
    });
    this._setSubTabs();
    this.allAccounts = { name: 'All Accounts', ref: 'udb.accounts.dashboard' };
  }

  /** For PFM3 we need to bold Overview tab, even with account details which has a different state name
   * Add states that bold the parent separated by commas
   *
   */
  setActiveMenuTab(): void {
    this.subTabs.forEach(tab => {
      if (tab.parent.includes(',')) {
        const childStates = tab.parent.split(',');
        const current = childStates.find(state => state == this.stateService.current.name);
        tab.isActive = this.stateService.includes(current);
      } else if (tab.parent) {
        tab.isActive = this.stateService.includes(tab.parent);
      } else {
        tab.isActive = this.stateService.is(tab.ref);
      }
    });
  }

  private _setSubTabs(): void {
    this.subTabs = [
      {
        name: 'Overview',
        ref: 'udb.accounts.dashboard',
        isShown: false,
        parent: 'udb.accounts.dashboard,udb.accounts.details,udb.accounts.external-details',
      },
      {
        name: 'Insights',
        ref: 'udb.accounts.insights',
        isShown: false,
        parent: 'udb.accounts.insights,udb.accounts.spending,udb.accounts.net-worth',
      },
      {
        name: 'Add Account',
        ref:
          'udb.accounts.account-aggregation({isAccountAggregationFlow: ' +
          this.isAccountAggregationEnhanceActive +
          ', isPfm3Active: ' +
          this.isPfm3Active +
          '})',
        isShown: false,
        parent: 'udb.accounts.account-aggregation',
      },
    ];

    this.setActiveMenuTab();
  }
}
