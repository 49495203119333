<ng-container *ngIf="!displayConfirmation; else orderConfirmationTemplate">
  <app-view-header [titleText]="bannerTitle">
    <app-dot-loader *ngIf="!stock || !account"></app-dot-loader>
    <p class="account-name-banner" *ngIf="account">Self-Directed Trading - {{ account.accountNumber | slice: -4 }}</p>
  </app-view-header>
  <ng-container *ngIf="stock && account && portfolioOverview; else loader">
    <div [ngSwitch]="step" class="container">
      <app-trade-stock-form
        *ngSwitchCase="steps.Form"
        [stock]="stock"
        [tradeType]="tradeType"
        [form]="form"
        [account]="account"
        [portfolioOverview]="portfolioOverview"
        (formSubmitted)="onFormSubmitted($event)"
      ></app-trade-stock-form>
      <app-trade-stock-review
        *ngSwitchCase="steps.Review"
        [tradeType]="tradeType"
        [orderConfirmation]="orderConfirmation"
        [orderPreview]="orderPreview"
        (goBack)="loadStep(steps.Form)"
        (orderSubmitted)="displayConfirmation = true"
      ></app-trade-stock-review>
      <app-trade-stock-mutual-fund
        *ngSwitchCase="steps.MutualFund"
        [stock]="stock"
        [tradeType]="tradeType"
        [form]="form"
        [account]="account"
        [portfolioOverview]="portfolioOverview"
        (formSubmitted)="loadStep(steps.MutualReview)"
      ></app-trade-stock-mutual-fund>
      <app-trade-stock-mutual-review
        *ngSwitchCase="steps.MutualReview"
        [mutualFundOrder]="form.value"
        [stock]="stock"
        [tradeType]="tradeType"
        [account]="account"
        (goBack)="loadStep(steps.MutualFund)"
        (orderSubmitted)="orderConfirmation = $event; displayConfirmation = true"
      ></app-trade-stock-mutual-review>
    </div>
  </ng-container>
</ng-container>
<ng-template #orderConfirmationTemplate>
  <app-trade-stock-confirmation [order]="orderConfirmation" [account]="account"></app-trade-stock-confirmation>
</ng-template>
<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
