<h3 class="mt-5 mb-4">How do I order checks?</h3>

<ol>
  <li>
    Click on the
    <b>Accounts tab</b>
    or the
    <b>Recent Transactions</b>
    tile.
  </li>
  <li>Click on the blue name of the checking account you want checks for.</li>
  <li>
    Select the
    <b>Details tab</b>
    and click
    <b>Order Checks</b>
    under Account Actions.
  </li>
  <li>
    You will be directed to Harland Clarke, our partner that offers checks and check-related
    products and services.
  </li>
</ol>
