import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

import { PdpState } from '../types';

const getPdpState = createFeatureSelector<PdpState>(AasStoreFeatures.ProductDetailsPage);

export const getSelectedAasAccount = createSelector(getPdpState, state => {
  if (state.aasAccounts === undefined) {
    return undefined;
  }

  return state.aasAccounts.find(item => item.accountNumber === state.selectedAasAccountNumber) ?? undefined;
});

export const getSelectedTab = createSelector(getPdpState, state => {
  if (state.selectedTab === 'undefined') return 'undefined';
  return state.selectedTab;
});
