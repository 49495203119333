<form (ngSubmit)="submit()">
  <div class="pre-approved-container">
    <div class="pre-approved-header">
      <div class="header-nav">
        <div class="navigation-responsive">
          <svg (click)="goSblocOffer()" class="svg-icon" aria-hidden="true" stroke="#fff">
            <use xlink:href="assets/svg/icons/navigation.svg#navigation-chevron-left"></use>
          </svg>
        </div>

        <img src="../../../../assets/axos/logos/axos-logo.png" class="logo" alt="Axos" />

        <img
          (click)="goDashboard()"
          src="../../../../assets/img/icons/sbloc/primary.png"
          class="primary-close"
          alt="close"
        />
      </div>

      <div class="navigation-full">
        <svg (click)="goSblocOffer()" class="svg-icon" aria-hidden="true" stroke="#fff">
          <use xlink:href="assets/svg/icons/navigation.svg#navigation-chevron-left"></use>
        </svg>
        <span (click)="goSblocOffer()" class="back">
          Back to Securities-Backed Line of Credit Page
        </span>
      </div>
    </div>

    <div class="loader-spinner" *ngIf="isLoadingSubject | async"><app-dot-loader>Loading ...</app-dot-loader></div>

    <div class="pre-approved-content" *ngIf="!(isLoadingSubject | async)">
      <div class="pre-approved-text">
        <h1>How much are you looking to borrow?</h1>
        <p>
          Select an amount that aligns with your borrowing needs and repayment capabilities. The line of credit range
          has been pre-determined based on your investment portfolio
          <b>{{ eligibleAccountNumber }}.</b>
          The maximum line amount has been assessed at {{ apiModel.Max | currency }}.
        </p>
      </div>
      <div class="my-Class">
        <mat-form-field class="uk2-label" appearance="outline" floatLabel="always">
          <mat-label>Investment Portfolio</mat-label>
          <mat-select
            uk2Dropdown
            placeholder="{{ eligibleNickname }}"
            [uk2BottomSheetRef]="listBottomSheet"
            uk2BottomSheetLink
            (selectionChange)="accountSelected($event)"
          >
            <mat-select-trigger>{{ eligibleNickname }}</mat-select-trigger>
            <mat-option *ngFor="let eligible of eligibleListDisplayed" [value]="eligible.EligibleAccountNumber">
              <div class="bottom-sheet-grid">
                <div class="bottom-sheet-icon">
                  <img width="24px" height="24px" [src]="eligible.logo" />
                </div>
                <div class="bottom-sheet-account">
                  <h1>{{ eligible.nickname }}</h1>
                  <p>{{ eligible.displayName }}</p>
                </div>
                <div class="bottom-sheet-amount">{{ eligible.currentBalance | currency }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="ukBottomSheet">
          <ng-template #listBottomSheet>
            <uk2-bottom-sheet [title]="title" [description]="description" [uk2IsLoading]="uk2IsLoading">
              <section id="uk2-bottom-sheet-body">
                <div *ngFor="let eligible of eligibleListDisplayed" class="bottom-sheet-grid">
                  <div class="bottom-sheet-icon">
                    <img width="24px" height="24px" [src]="eligible.logo" />
                  </div>
                  <div class="bottom-sheet-account">
                    <h1 (click)="bottomSheetAccountSelected(eligible.EligibleAccountNumber)">
                      {{ eligible.nickname }}
                    </h1>
                    <p>{{ eligible.displayName }}</p>
                  </div>
                  <div class="bottom-sheet-amount">{{ eligible.currentBalance | currency }}</div>
                </div>
              </section>
            </uk2-bottom-sheet>
          </ng-template>
        </div>
        <div class="uk2Input">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            style="--uk2-form-field-general-active-color: var(--uk2-axos-primary-blue-400);"
            class="uk2-label"
          >
            <mat-label>Enter Amount or Slide Below</mat-label>
            <input
              (keypress)="keyPress($event)"
              required
              [errorStateMatcher]="customErrorState"
              [(ngModel)]="amount"
              [ngModelOptions]="{ standalone: true }"
              matInput
              uk2Input
              #sliderValueNumber="ngModel"
              (change)="onInputChange(sliderValueNumber.value)"
              (blur)="transformAmount($event)"
              type="text"
              [min]="apiModel.Min"
              [max]="apiModel.Max"
            />
            <mat-hint style="color: #2f5b88;" *ngIf="apiModel.Min <= sliderValue && sliderValue <= apiModel.Max">
              <span class="label-hint">Select a line of credit amount in $1,000 increments.</span>
            </mat-hint>
            <mat-error *ngIf="sliderValue < apiModel.Min">
              <mat-icon [svgIcon]="'uk2-exclamation-triangle'"></mat-icon>
              Amount cannot be less than {{ apiModel.Min | currency }}
            </mat-error>
            <mat-error *ngIf="sliderValue > apiModel.Max">
              <mat-icon [svgIcon]="'uk2-exclamation-triangle'"></mat-icon>
              Amount cannot exceed {{ apiModel.Max | currency }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="uk2Slider">
          <uk2-slider
            required
            [maxValueLabel]="'Max ' + (apiModel.Max | currency: 'USD':true:'2.0')"
            [minValueLabel]="'Min ' + (apiModel.Min | currency: 'USD':true:'2.0')"
            [minValue]="this.apiModel.Min"
            [maxValue]="this.apiModel.Max"
            [value]="this.sliderValue"
            [step]="1000"
            (valueChange)="onSliderChange($event)"
          ></uk2-slider>
        </div>
      </div>
      <div class="apr-content">
        <span class="apr-span">APR</span>
        <div class="aprModel">{{ this.apr | number: '1.2-2' }}%</div>
        <div class="uk2Tooltip">
          <uk2-tooltip
            style="--uk2-tooltip-icon-color: var(--uk2-axos-primary-blue-400);"
            [labelText]="''"
            [size]="size"
            [bodyText]="bodyText"
            [uk2IsLoading]="uk2IsLoading"
            [displayCloseButton]="displayCloseButton"
            [arrowOffset]="arrowOffset"
            [svgIconName]="svgIconName"
            [placement]="placement"
            [desktopOpenMode]="desktopOpenMode"
          ></uk2-tooltip>
        </div>
      </div>
      <div class="as-of-today">as of today</div>

      <div class="pre-approved-footer">
        <button
          class="sc-btn sc-btn-disabled"
          type="submit"
          [ngClass]="{ 'sc-btn-primary': this.apiModel.Min <= sliderValue && sliderValue <= this.apiModel.Max }"
        >
          Review Offer
        </button>
      </div>
    </div>
  </div>
</form>
