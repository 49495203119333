import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieHelperService } from '@app/core/services/cookie.service';
import { serviceHelperProvider, stateServiceProvider } from '@core/providers';
import { SharedModule } from '@shared/shared.module';

import { sharedComponents } from './components';

@NgModule({
  declarations: [...sharedComponents],
  imports: [SharedModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [CommonModule, ...sharedComponents],
  providers: [serviceHelperProvider, CookieHelperService, stateServiceProvider],
})
export class AuthenticationModule {}
