<div class="row">
  <p class="overview-title">Overview</p>
  <div class="col text-right">
    <select name="timeRangeFilter" id="timeRangeFilter" [formControl]="timeFilter">
      <option *ngFor="let option of filterOptions" [value]="option.id">{{ option.label }}</option>
    </select>
  </div>
</div>
<app-dot-loader *ngIf="isLoading || isChartUpdating">Loading Historic Data</app-dot-loader>
<div [class.hidden]="isChartUpdating" *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4 mt-3">
      <div class="row">
        <div class="col-6 data-label data-label-value">{{ selectedFilter.metricsLabel }} Value</div>
        <div class="col-6 data-label data-label-change">{{ selectedFilter.metricsLabel }} Change</div>
      </div>
      <div class="row mt-2">
        <div class="col-6 total-value">
          {{ metrics.totalValue | currency }}
        </div>
        <div
          class="col-6 total-change-container p-2 d-flex align-items-center"
          [class.equal]="metrics.dayChangePercentage === 0 || !metrics.dayChangePercentage"
          [class.positive]="metrics.dayChangePercentage > 0"
          [class.negative]="metrics.dayChangePercentage < 0"
        >
          <app-svg-icon
            class="day-change-label-btn-icon"
            [iconName]="
              metrics.dayChangePercentage === 0 || !metrics.dayChangePercentage
                ? icons.equal
                : metrics.dayChangePercentage > 0
                ? icons.triangleUp
                : icons.triangleDown
            "
            width="1.15em"
            height="1.15em"
            [iconColor]="
              metrics.dayChangePercentage === 0 || !metrics.dayChangePercentage
                ? '#494949'
                : metrics.dayChangePercentage > 0
                ? '#00805B'
                : '#fd0000'
            "
          ></app-svg-icon>
          <span class="goal-day-change-percentage ml-2">
            {{ metrics.dayChangePercentage | percent: '1.2' }} {{ metrics.dayChangeAmount | currency }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-5 offset-lg-3 mt-3">
      <div class="row">
        <div class="col-4 data-label data-label-deposits">{{ selectedFilter.metricsLabel }} Deposits</div>
        <div class="col-4 data-label data-label-withdrawals">{{ selectedFilter.metricsLabel }} Withdrawals</div>
        <div class="col-4 data-label data-label-dividends">{{ selectedFilter.metricsLabel }} Dividends</div>
      </div>
      <div class="row">
        <div class="col-4 metric">
          {{ metrics.deposits | currency }}
        </div>
        <div class="col-4 metric">
          {{ metrics.withdrawals | currency }}
        </div>
        <div class="col-4 metric">
          {{ metrics.dividends | currency }}
        </div>
      </div>
    </div>
  </div>
  <div class="flex mt-4">
    <app-historic-performance-chart [data]="goals" [updateData]="updateData"></app-historic-performance-chart>
  </div>
  <div class="row mt-3">
    <div class="mr-3" *ngFor="let goal of goals">
      <mat-checkbox
        *ngIf="goal.historicData"
        class="goal-checkbox"
        [(ngModel)]="goal.checked"
        (ngModelChange)="toggleGoal()"
      >
        {{ goal.name }}
      </mat-checkbox>
    </div>
  </div>
</div>
