<h3 class="mt-5 mb-4">How do I keep my account active?</h3>

<ol>
  <li>To keep your account(s) active, periodically log into online banking.</li>
</ol>

<p>
  When there are no logins, purchases, transfers, deposits, or other activity in your account for 21
  months, it is considered a pre-dormant account. After 24 months of inactivity, your account is
  regarded as dormant. We are then required by law to report the funds in your account as unclaimed
  to the State Treasurer’s Office.
</p>
