/**
 * Helper to show the Save File to device dialog.
 *
 * @param blob Blob object.
 * @param filename a string with the name of the file that you want to prompt to he user.
 *
 * @returns void.
 */
export function saveFile(blob: Blob, filename: string): void {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
