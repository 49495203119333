<ng-container *ngIf="!peopleTabAccountState$?.error; else failedStateContent">
  <section>
    <app-primary-beneficiary
      *ngIf="ShowBeneficiary && isRetirementAccount"
    ></app-primary-beneficiary>
    <app-contingent-beneficiary
      *ngIf="
        ShowBeneficiary &&
        isRetirementAccount &&
        primaryBeneficiaries &&
        primaryBeneficiaries.length > 0
      "
    ></app-contingent-beneficiary>
    <app-tod-primary-beneficiary></app-tod-primary-beneficiary>
    <app-tod-contingent-beneficiary></app-tod-contingent-beneficiary>
    <app-trusted-contact></app-trusted-contact>
    <app-interested-party></app-interested-party>
    <app-authorized-parties></app-authorized-parties>
  </section>
</ng-container>

<ng-template #failedStateContent>
  <mat-divider class="failed-state-divider"></mat-divider>
  <uk2-text-tile
    [uk2TextTileTitle]="'People Information Temporarily Unavailable'"
    [uk2TextTileIsStandaloneTile]="false"
  >
    We're sorry, but we're currently unable to provide you with your latest
    people information. This includes your
    <span class="uk2-font__bold--75">
      Beneficiaries, Interested Parties, Trusted Contacts, and Authorized
      Parties
    </span>
    information. Please check back later. Thank you for your patience.
  </uk2-text-tile>
</ng-template>
