import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { OrdersFacade } from '../../facade';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Order, OrdersTileState } from '../../core/store/types';
import { Uk2ButtonSizeEnum, Uk2Tier1InvestEnum, Uk2Tier1NavigationEnum, Uk2Tier1UtilityEnum } from '@axos/uikit-v2-lib';
import { STATE } from '@core/tokens';
import { PdpFacade } from '../../../product-details-page/facade/pdp.facade';
import { OrdersStateEnum } from '../../core/store/enums';
import { ORDERS_LABELS } from './constants';

@Component({
  selector: 'app-orders-tile',
  templateUrl: './orders-tile.component.html',
  styleUrls: ['./orders-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersTileComponent implements OnInit, OnDestroy {
  ordersTile: OrdersTileState;
  isLoading = true;
  uk2OrderBuyIcon = Uk2Tier1InvestEnum.wallet;
  uk2OrderSellIcon = Uk2Tier1InvestEnum.priceTag;
  uk2OrderPendingIcon = Uk2Tier1UtilityEnum.clock;
  uk2OrderCogIcon = Uk2Tier1UtilityEnum.cog;
  uk2OrderRightChevronIcon = Uk2Tier1NavigationEnum.chevronRight;
  viewAllButtonSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.medium;
  ordersStatePending: OrdersStateEnum = OrdersStateEnum.Pending;
  labels = ORDERS_LABELS;

  private destroy$ = new Subject<void>();
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private ordersFacade: OrdersFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private pdpFacade: PdpFacade
  ) {}

  ngOnInit(): void {
    this.ordersFacade.ordersTileAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined),
        tap(orders => {
          if (orders.apiCallWasSuccessful) {
            this.handleOrders(orders);
          }
        })
      )
      .subscribe();

    this.ordersFacade.isLoading$
      .pipe(
        takeUntil(this.destroy$),
        tap(isLoading => {
          this.isLoading = isLoading;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleOrders(ordersTileState: OrdersTileState) {
    let pending_orders: Order[] = ordersTileState.orders.filter(
      order => this.isWithinNextFiveDays(order.tradeDate) && this.isPending(order.state)
    );
    pending_orders.sort(this.sortByTradeDate);

    let processed_orders: Order[] = ordersTileState.orders.filter(order => !this.isPending(order.state));
    processed_orders.sort(this.sortByPostDate);

    ordersTileState.orders = [...pending_orders, ...processed_orders];
    ordersTileState.orders = ordersTileState.orders.slice(0, 8);
    this.ordersTile = ordersTileState;
    this.changeDetectorRef.detectChanges();
  }

  navigateToAllTransactions(order?: Order) {
    if (order != null && this.isPending(order.state)) return;
    const componentTab: string = 'Transactions';
    this.pdpFacade.setTransactionSelectedTab(componentTab);
    let state = 'udb.accounts.container';
    let accountType = 'Advisor';
    this.state.go(state, {
      id: this.ordersTile.accountNumber,
      type: accountType,
      transactionId: order ? order.transactionId : null,
    });
  }
  private isWithinNextFiveDays(tradeDate: Date | string): boolean {
    const currentDate = new Date().getTime();
    const targetDate = typeof tradeDate === 'string' ? new Date(tradeDate).getTime() : tradeDate.getTime();
    const differenceInDays = (targetDate - currentDate) / (1000 * 60 * 60 * 24);
    return differenceInDays >= 0 && differenceInDays <= 5 ? true : false;
  }

  private isPending(orderState: OrdersStateEnum): boolean {
    return orderState === this.ordersStatePending;
  }

  private sortByPostDate(a, b) {
    const dateA = typeof a.postDate === 'string' ? new Date(a.postDate).getTime() : a.postDate.getTime();
    const dateB = typeof b.postDate === 'string' ? new Date(b.postDate).getTime() : b.postDate.getTime();
    return dateA > dateB ? -1 : 1;
  }

  private sortByTradeDate(a, b) {
    const dateA = typeof a.tradeDate === 'string' ? new Date(a.tradeDate).getTime() : a.tradeDate.getTime();
    const dateB = typeof b.tradeDate === 'string' ? new Date(b.tradeDate).getTime() : b.tradeDate.getTime();
    return dateA > dateB ? -1 : 1;
  }
}
