import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { sblocOffersComponent } from './components/Index';
import { Uk2BottomSheetModule, Uk2ServicesModule, Uk2SliderModule, Uk2ToastModule } from '@axos/uikit-v2-lib';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Uk2TooltipModule } from '@axos/uikit-v2-lib';
import { MatInputModule } from '@angular/material/input';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Uk2ModalModule } from '@axos/uikit-v2-lib';
import { Uk2DirectivesModule } from '@axos/uikit-v2-lib';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@shared/shared.module';

const Uk2Components = [
  Uk2SliderModule,
  Uk2TooltipModule,
  Uk2DirectivesModule,
  Uk2ServicesModule,
  Uk2ModalModule,
  Uk2ToastModule,
  Uk2BottomSheetModule,
];

const MaterialModules = [
  MatIconModule,
  MatButtonModule,
  MatInputModule,
  MatDividerModule,
  MatDialogModule,
  MatSnackBarModule,
  BrowserAnimationsModule,
  MatSelectModule,
];

@NgModule({
  declarations: [...sblocOffersComponent],
  imports: [CommonModule, FormsModule, MaterialModules, Uk2Components, SharedModule],
  entryComponents: [...sblocOffersComponent],
  exports: [...sblocOffersComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: false, scrollStrategy: new NoopScrollStrategy() },
    },
  ],
})
export class SblocOfferModule {}
