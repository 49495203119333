import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { OrderType, TradeStockType } from '@app/axos-trading/enums';
import { OrderConfirmation, OrderPreview } from '@app/axos-trading/models';
import { ClearingService } from '@app/axos-trading/services';
import { validateHourMarketPrice } from '@app/axos-trading/utils';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons } from '@shared/enums';
import { ConfirmationField } from '@shared/models';

@Component({
  selector: 'app-trade-stock-review',
  templateUrl: './trade-stock-review.component.html',
  styleUrls: ['./trade-stock-review.component.scss'],
  providers: [CurrencyPipe, DecimalPipe],
})
export class TradeStockReviewComponent implements OnInit {
  @Input() tradeType: TradeStockType;
  @Input() orderConfirmation: OrderConfirmation;
  @Input() orderPreview: OrderPreview;

  @Output() goBack = new EventEmitter<void>();
  @Output() orderSubmitted = new EventEmitter<OrderConfirmation>();

  fields: ConfirmationField[] = [];
  icons = {
    backIcon: NavigationIcons.ArrowBack,
  };
  submittingOrder: boolean;
  displayMarketValidation = false;

  constructor(
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private clearingService: ClearingService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.displayMarketValidation = !validateHourMarketPrice() && this.orderConfirmation.orderType === OrderType.Market;
    this.createFields();
  }

  sendOrder() {
    this.submittingOrder = true;

    this.clearingService
      .submitOrder(this.orderConfirmation)
      .toPromise()
      .then(result => {
        this.orderConfirmation.orderReference = result.orderReference;
        this.orderSubmitted.emit(this.orderConfirmation);
      })
      .catch(errors => {
        this.serviceHelper.errorHandler(errors);
      })
      .finally(() => (this.submittingOrder = false));
  }

  private createFields() {
    this.fields.push(new ConfirmationField('Stock', this.orderConfirmation.stock));
    this.fields.push(new ConfirmationField('Order Type', this.orderConfirmation.orderTypeDescription));
    if (this.orderConfirmation.orderType === OrderType.Limit) {
      this.fields.push(
        new ConfirmationField('Limit Price', this.currencyPipe.transform(this.orderConfirmation.limitPrice))
      );
    }
    this.fields.push(new ConfirmationField('Fill Type', this.orderConfirmation.fillTypeDescription));
    this.fields.push(new ConfirmationField('Amount of Shares', this.orderConfirmation.quantity.toString()));

    // Market Price shouldn't display on Review page for Limit Flows
    if (this.orderConfirmation.orderType !== OrderType.Limit) {
      this.fields.push(
        new ConfirmationField('Market Price', this.currencyPipe.transform(this.orderConfirmation.marketPrice))
      );
    }

    const bidPrice = `${this.currencyPipe.transform(this.orderPreview.quote.bid)} x ${this.orderPreview.quote.bidSize}`;
    this.fields.push(new ConfirmationField('Bid Price', bidPrice));

    const askPrice = `${this.currencyPipe.transform(this.orderPreview.quote.ask)} x ${this.orderPreview.quote.askSize}`;
    this.fields.push(new ConfirmationField('Ask Price', askPrice));

    this.fields.push(new ConfirmationField('Volume', this.decimalPipe.transform(this.orderPreview.quote.volume)));

    this.fields.push(
      new ConfirmationField(this.totalCostLabel, this.currencyPipe.transform(this.orderConfirmation.totalCost))
    );
    if (this.orderConfirmation.orderType === OrderType.Limit) {
      this.fields.push(new ConfirmationField('Good Until', this.orderConfirmation.goodUntilDescription));
    }
  }

  private get totalCostLabel() {
    return this.tradeType === TradeStockType.Buy ? 'Total Cost' : 'Estimated Gain';
  }
}
