import * as angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { TransferFundsConfig } from './transfer-funds.config';
import { transferFundsComponent } from './transfer-funds-component/transfer-funds.component';
import { TransferService } from '@app/transfers/services/transfer.service';
import { AccountsService } from '@app/core/services/accounts.service';
import { RedirectToIpayModalComponent } from '@app/business/modals/redirect-to-ipay-modal/redirect-to-ipay-modal.component';
import { TransferFundsContainerComponent } from '@app/transfers/components';
import {
  LoanConfirmationPageComponent,
  LoanPaymentComponent,
  LoanReviewAndSubmitComponent,
  PaymentDetailItemComponent,
  PaymentDetailsComponent,
} from '@app/transfers/components';

export const TransferFunds = angular
  .module('udb.TransferFunds', [])
  .config(TransferFundsConfig)
  .component('udbTransferFund', transferFundsComponent)
  .directive('appTransferFundsContainer', downgradeComponent({ component: TransferFundsContainerComponent }))
  .directive('iPayRedirectModal', downgradeComponent({ component: RedirectToIpayModalComponent }))
  .directive('appLoanPayment', downgradeComponent({ component: LoanPaymentComponent }))
  .directive('appLoanReviewAndSubmit', downgradeComponent({ component: LoanReviewAndSubmitComponent }))
  .directive('appLoanConfirmationPage', downgradeComponent({ component: LoanConfirmationPageComponent }))
  .directive('appPaymentDetails', downgradeComponent({ component: PaymentDetailsComponent }))
  .directive('appPaymentDetailItem', downgradeComponent({ component: PaymentDetailItemComponent }))
  .factory('newAccountsService', downgradeInjectable(AccountsService))
  .factory('transferService', downgradeInjectable(TransferService)).name;
