<div class="header-section-title text-center">
  <section class="frow center header">
    <div class="header__title">
      <span>Net Worth</span>
    </div>
  </section>
</div>

<div class="main-cont" [ngClass]="{'loading_background': (netWorthSeriesLoading | async) || (accountsLoading | async) }">
  <div class="frow">
    <a class="center-align ml-2" (click)="goBack()">
      <app-svg-icon
        [iconName]="icons.arrowBack"
        [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
        class="mr-1"
      ></app-svg-icon>
      Insights Overview
    </a>
  </div>
  <div class="gradient mt-4">
    <div class="insights-cont mt-4">
      <div class="row">
        <app-dot-loader
        [showWhen]="(netWorthSeriesLoading | async) || (accountsLoading | async)"
        [ngClass]="{'loading': (netWorthSeriesLoading | async) || (accountsLoading | async) }">Loading</app-dot-loader>
      </div>
      <div class="header-row">
        <div class="d-md-none net-worth-amount encode-sans">
          <ng-container *ngIf="currentNetWorth">{{ currentNetWorth.worth.net | currency }}</ng-container>
          <ng-container *ngIf="!currentNetWorth">$--</ng-container>
        </div>
        <div class="flex-grow-1"></div>
        <div
          class="filters-button d-md-none"
          [class.nondefault]="!(committedFiltersInDefaultState | async).all"
          (click)="openFiltersModal()"
        >
          <app-svg-icon
            class="checkmark mr-2"
            [iconName]="icons.Checkmark"
            [styles]="{ width: '1rem', height: '1rem' }"
          ></app-svg-icon>
          <app-svg-icon [iconName]="icons.FilterSlider" [styles]="{ width: '1rem', height: '1rem' }"></app-svg-icon>
        </div>
        <app-filter-dropdown
          (collapse)="commitFilters()"
          [isHighlighted]="
            (committedFiltersInDefaultState | async).accounts ||
            (accounts && accAggFormatter.getSelectedAccountsSize(accounts, committedFilters.excludedAccounts) != 0)
          "
          class="d-none d-md-flex"
        >
          <span header>
            Accounts
            <span *ngIf="(committedFiltersInDefaultState | async).accounts">
              (All)
            </span>
            <span *ngIf="!(committedFiltersInDefaultState | async).accounts">
              ({{ accAggFormatter.getSelectedAccountsSize(accounts, committedFilters.excludedAccounts) }})
            </span>
          </span>
          <div body class="dropdown-body">
            <app-accounts-filter
              [providers]="providers"
              [accounts]="accounts"
              [filter$]="excludedAccountsFilter"
              [balanceOverrides]="accountsFilterBalanceOverrides"
              (resetFilter)="onAccountsFilterReset()"
              (changeFilter)="onAccountsFilterChange($event)"
            ></app-accounts-filter>
          </div>
        </app-filter-dropdown>
        <app-filter-dropdown (collapse)="commitFilters()" [isHighlighted]="true" class="d-none d-md-flex">
          <span header>{{ committedFilters.timePeriod }}</span>
          <div body class="dropdown-body">
            <app-time-period-filter [filter$]="timePeriodFilter" [parentPage]="'NetWorth'"></app-time-period-filter>
          </div>
        </app-filter-dropdown>
      </div>
      <div class="row">
        <div class="mt-n4 col-md-6 d-flex flex-column flex-md-column-reverse">
          <app-line-chart
            [height]="405"
            [points]="lineChartPoints"
            [xAxisTickFormatter]="formatAxisTick"
            [chartjsOptions]="{ scales: { xAxes: [{ ticks: { maxTicksLimit: xAxisMaxTicks } }] } }"
          ></app-line-chart>
          <app-net-worth-header
            [currentNetWorth]="currentNetWorth"
            [previousNetWorth]="previousNetWorth"
          ></app-net-worth-header>
        </div>
        <div class="mt-4 col-md-6 d-none d-md-block">
          <app-assets-liabilities-table
            [currentNetWorth]="currentNetWorth"
            [previousNetWorth]="previousNetWorth"
          ></app-assets-liabilities-table>
        </div>
      </div>
    </div>
  </div>

  <div style="height: 1rem; background: #f4f5f7;"></div>

  <div class="insights-cont">
    <app-accounts-list
      [accounts]="accounts"
      [excludedAccountsId]="committedFilters.excludedAccounts"
      [providers]="providers"
      [currentNetWorth]="currentNetWorth"
      [previousNetWorth]="previousNetWorth"
    ></app-accounts-list>
  </div>
</div>
