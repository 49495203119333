import { BaseService } from './base.service';
import { Inject } from '../decorators/decorators';
import { IBeneficiariesService } from './typings/IBeneficiariesService';
import { Beneficiary, BeneficiaryResponse } from '@shared/models';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class BeneficiariesService extends BaseService implements IBeneficiariesService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'beneficiaries', _serviceHelper, q);
  }

  addBeneficiaries(beneficiaries: Beneficiary[]): ApiResponse<BeneficiaryResponse> {
    return this.post('', beneficiaries);
  }

  editBeneficiaries(beneficiary: Beneficiary): ApiResponse<BeneficiaryResponse> {
    return this.put('', beneficiary);
  }

  removeBeneficiaries(beneficiaryId: string): ApiResponse<BeneficiaryResponse> {
    return this.delete(beneficiaryId);
  }
}
