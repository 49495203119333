<div id="investor-checking-container">
  <div id="investor-checking-header">
    <app-investor-checking-header></app-investor-checking-header>
  </div>
  <div id="investor-checking-body">
    <app-investor-checking-body></app-investor-checking-body>
  </div>
  <div id="investor-checking-footer">
    <app-investor-checking-footer></app-investor-checking-footer>
  </div>
</div>
