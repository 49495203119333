import { CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SendMoneyBusinessModalComponent } from '@app/business/modals/send-money-business-modal/send-money-business-modal.component';
import { LEGACY_FILTER_TOKEN } from '@app/inbox/injection-tokens';
import {
  LEGACY_SETTINGS_SERVICE,
  LEGACY_TRANSACTION_SERVICE,
} from '@app/transfers/ajs-upgraded-providers';
import { MULTIFACTOR } from '@app/transfers/constants';
import { P2PTransferTemplate } from '@app/transfers/typings';
import { SubSink } from '@axos/subsink';
import { AppSettings, AuthMethod, HttpStatusCode } from '@core/enums';
import { MultiFactorRequest } from '@core/models';
import { DialogService, MultifactorService } from '@core/services';
import { IPayService } from '@core/services/ipay.service';
import { ROOT_SCOPE, STATE, olbSettings } from '@core/tokens';
import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { CustomerLimitsHelper } from '@legacy/services/customer-limits.helper';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { ISettingsService } from '@legacy/services/typings/ISettingsService';
import { ITransactionService } from '@legacy/services/typings/ITransactionService';
import { CookieHelper } from '@legacy/shared/helpers/cookie.helper';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { IPaySSOResult } from '@legacy/typings/app/IPaySSOResult';
import { P2PMethod, P2PPayment } from '@legacy/typings/app/bills/P2PPayment';
import { P2PRecipient } from '@legacy/typings/app/bills/P2PRecipient';
import { UserAccountType } from '@legacy/typings/app/bills/UserAccountType';
import { IFilterService } from 'angular';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { P2PInfoModalComponent } from '../../modals';
import { solePropHelpers } from '@legacy/shared/helpers';

@Component({
  selector: 'app-send-money',
  templateUrl: './send-money.component.html',
  styleUrls: ['./send-money.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class SendMoneyComponent {
  constructor(
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    @Inject('$scope') public scope: ng.IScope,
    @Inject(LEGACY_FILTER_TOKEN) public readonly filterService: IFilterService,
    public serviceHelper: ServiceHelper,
    @Inject(LEGACY_TRANSACTION_SERVICE)
    public readonly transactionService: ITransactionService,
    @Inject(LEGACY_SETTINGS_SERVICE) public settingsService: ISettingsService,
    public modalService: ModalService,
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    private cachedAccountsService: CachedAccountsService,
    private customerLimitsHelper: CustomerLimitsHelper,
    public multifactorService: MultifactorService,
    public featureFlagService: FeatureFlagService,
    private matDialog: MatDialog,
    public iPayService: IPayService,
    private cookieHelper: CookieHelper,
    public fb: FormBuilder,
    public cp: CurrencyPipe,
    private dialogService: DialogService
  ) {}
  get P2PMaxPaymentLimitExceededMessage(): string {
    return `Amount exceeds the limit of ${this.cp.transform(
      this.p2PMaxPaymentAmount
    )}`;
  }
  public readonly USER_BUSINESSES = 'userBusinesses';

  // OTP properties
  step = 1;
  phoneNumber: Observable<string>;
  otp: string;
  accessCodeError: string;

  recipients: P2PRecipient[];
  filteredRecipients: P2PRecipient[];
  methods: GenericOption[];
  filteredMethods: GenericOption[];
  securityQuestions: GenericOption[];
  filteredSecurityQuestions: GenericOption[];
  accountsFrom: UserAccountType[];
  filteredAccountsFrom: UserAccountType[];
  isValidAccount: boolean = true;

  selectedRecipient: P2PRecipient = {};
  selectedMethod: GenericOption = {};
  selectedQuestion: GenericOption = {};
  amount: number;
  account: UserAccountType;
  message: string;
  answer: string;

  isLoadingAccounts: boolean;
  isErrorBalanceAmount: boolean;
  isSendingMoney: boolean;

  placeholderRecipient: string;
  placeholderQuestion: string;

  errorRecipient: GenericError = {};
  errorMethod: GenericError = {};
  errorAmount: GenericError = {};
  errorQuestion: GenericError = {};

  brandName: string;
  p2PMaxPaymentAmount?: number;
  modalInstance: angular.ui.bootstrap.IModalServiceInstance;

  isSBBActive: boolean;
  multifactorTitle: string = MULTIFACTOR.title;
  multifactorSubtitle: string = MULTIFACTOR.subtitle;
  private listeners: Function[] = [];
  hasIndividualAccounts: boolean = false;
  hasBusinessAccounts: boolean = false;
  userProfilesInfo: AccountProfile[] = [];
  isSolePropUser: boolean = false;
  businessId: number;
  showFormBusinessLink: boolean = false;

  payment: P2PPayment;
  method: P2PMethod;
  private subSink = new SubSink();
  sendMoneyForm: FormGroup;
  isFormSubmitted: boolean = false;
  recipientName = new FormControl();
  recipientMethod = new FormControl();
  from = new FormControl();
  displayAmount = '0.00';

  soleBusinessData: solePropHelpers.SolePropData;

  async ngOnInit(): Promise<void> {
    this.isFormSubmitted = false;
    this.checkCustomerP2PPaymentLimit();
    this.brandName = String(this.env.brand);
    this.payment;
    this.isLoadingAccounts = false;
    this.isSendingMoney = false;
    this.recipients = [];
    this.methods = [];
    this.securityQuestions = [];
    // SBB Logic
    this.isSBBActive = false;
    this.isLoadingAccounts = true;
    this.isErrorBalanceAmount = false;

    this.sendMoneyForm = this.fb.group({
      from: new FormControl('', [Validators.required]),
      answer: new FormControl('', [Validators.required]),
      recipientName: [''],
      recipientMethod: [''],
      question: [''],
      amount: ['', [Validators.required]],
      message: [''],
    });

    await Promise.all([this.onPaymentAccountsLoaded()]).then(_ => {
      this.setPayItNowData.bind(this)();
      this.checkSbbProfiles.bind(this)();
    });

    // quick fix menu
    $('.m-transfer,.transfers-nav>li:eq(3)').addClass('active');

    this._getRecipients();
    this._getSecurityQuestions();
    this.filterRecipients();
    this.filterMethods();
    this.filterSecurityQuestions();
  }

  payBillsFromBusinessAccounts(): void {
    const businessProfiles = this.userProfilesInfo?.filter(
      acc => acc.profileType === AccountProfileType.Business
    );
    let entryLength = businessProfiles?.length ?? 0;
    if (this.soleBusinessData.isSoleProp) {
      entryLength++;
    }

    if (entryLength > 1) {
      this.showBusinessModal();
      return;
    }

    this.redirectToIPay();
  }

  checkSbbProfiles(): void {
    this.userProfilesInfo = JSON.parse(
      sessionStorage.getItem(solePropHelpers.USER_BUSINESSES_KEY)
    ) as AccountProfile[];
    this.isSBBActive = this.featureFlagService.isSBBActive();

    if (!this.isSBBActive) {
      return;
    }

    this.soleBusinessData = solePropHelpers.computeSolePropData(
      this.cookieHelper.getUserCIF(),
      this.cookieHelper.getUserId(),
      this.cachedAccountsService.paymentAccounts ?? [],
      this.userProfilesInfo
    );

    this.isSolePropUser = this.soleBusinessData.isSoleProp;
    this.businessId = this.soleBusinessData.businessId;
  }

  showBusinessModal(): void {
    const dialogRef = this.matDialog.open(SendMoneyBusinessModalComponent, {
      data: { isBillPay: false, soleBusinessData: this.soleBusinessData },
      panelClass: ['modal-dialog'],
    });

    this.dialogService.updateSolePropGlobalData(state => ({
      ...state,
      ...this.soleBusinessData,
      dialogRef: dialogRef,
    }));
  }

  setPayItNowData(): void {
    this.accountsFrom = this.cachedAccountsService.getPaymentAccounts(true);
    this.isLoadingAccounts = false;
    this.validateP2PTransferFrom();

    this.hasIndividualAccounts =
      this.accountsFrom?.some(acc => !acc.isSBB) ?? false;
    this.hasBusinessAccounts =
      this.accountsFrom?.some(acc => acc.isSBB) ?? false;

    this.showFormBusinessLink = this.setShowFormBusinessLinks();
    this.filterAccountsFrom();
  }
  redirectToIPay(): void {
    if (!this.featureFlagService.isIPaySsoActive() || !this.isSBBActive) {
      return;
    }

    this.iPayService.getSsoUrlByBusinessId(this.businessId).subscribe({
      next: (resp: OlbResponse<IPaySSOResult>) => {
        // Open iPay in a new tab/window
        window.open(resp.data.ssoUrl, '_blank');
      },
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
    });
  }

  setShowFormBusinessLinks(): boolean {
    return (
      this.hasBusinessAccounts &&
      this.hasIndividualAccounts &&
      this.featureFlagService.isIPaySsoActive() &&
      this.featureFlagService.isIPaySsoActive()
    );
  }

  /** Validates the form before posting the transfer */
  transferMoney(formValid: boolean): void {
    this.isFormSubmitted = true;
    if (this._isFormValid() && formValid) {
      const currentBalance = this.account.balance;
      this.isErrorBalanceAmount = currentBalance < this.amount;
      if (this.isErrorBalanceAmount) return;

      const modalOpts: ModalSettings = {
        icon: 'bofi-modal-send',
        okText: 'Confirm',
        bodyText: `<h3>You are about to send ${this.cp.transform(
          this.amount
        )} to ${this.selectedRecipient.displayName}.</h3>
                            <p class="hidden-sm ms-secondary-text">To confirm this transaction, press the confirm button below.</p>`,
      };

      this.modalService
        .show({ appendTo: $('.send-money') }, modalOpts)
        .then(_res => {
          let ACHlimit = 100000;
          const ACHsetting = this.root['appSettings'].find(
            (ap: any) => ap.name === AppSettings.ACHLimitAmount
          );
          if (ACHsetting) {
            ACHlimit = Number(ACHsetting.value);
          }
          if (this.amount >= ACHlimit) {
            this.step = 2;
            this.root.$broadcast('payNowStepChanged', 2);
          } else {
            this.confirmTransaction();
          }
        })
        .catch(_res => {});
    } else {
      this.scrollToError();
    }
  }

  /** Performs the actual transfer by making the call in the server */
  confirmTransaction(): void {
    this.payment = {
      recipient: this.selectedRecipient,
      method: this.method,
      accountCode: this.account.accountCode,
      amount: this.amount,
      message: this.message,
      question: this.selectedQuestion.label,
      answer: this.answer,
      accountId: this.account.accountId,
    };
    this.isSendingMoney = true;
    this.transactionService
      .transferMoneyP2P(this.payment)
      .then(result => {
        this._showReceipt(result.data.confirmationNumber);
      })
      .catch((error: ApiError) => {
        if (error.status === HttpStatusCode.BadRequest) {
          if (error.data.message.includes('email')) {
            this.errorMethod = {
              hasError: true,
              errorDescription: 'Please provide a valid email address',
            };

            return;
          }
        }
        this.serviceHelper.errorHandler(error);
      })
      .finally(() => {
        this.isSendingMoney = false;
      });
  }

  /**
   * Selects the recipient either one already registered or a new one
   * @param item The item in the input selected
   */
  selectRecipient = (item: any, event: any) => {
    if (event.isUserInput) {
      if (item === undefined) {
        this.selectedRecipient = {
          recipientId: 0,
          displayName: item,
        };
        return;
      } else {
        if (item.displayName) {
          item.displayName = item.displayName.trim();
        }
        this.selectedRecipient = {
          recipientId: item.recipientId,
          displayName: item.displayName,
          emailAddress: item.emailAddress,
          phone: item.phone,
        };
      }
      this._validRecipient();
      this._loadMethodRecipient();
    }
  };

  /**
   * Selects the method either one already registered or a new one
   * @param item The item in the input selected
   */
  selectMethod = (item: any, event: any = undefined) => {
    if (event && event.isUserInput === true) {
      if (item === undefined) {
        this.selectedMethod = {
          value: 0,
          label: item,
        };
        return;
      } else {
        this.selectedMethod = {
          value: item.value,
          label: item.label,
        };
      }
      this._validMethod();
    }
  };

  validateMethod = () => {
    if (!this.selectedMethod || !this.selectedMethod?.label) return;
    var methodValue = this.selectedMethod.label;
    methodValue = methodValue.replace(/[^0-9]/g, '');
    const label = methodValue;
    const onlyDigits = /^[0-9]*$/;
    const sameDigits = /(\d)\1{9}/;

    if (
      !onlyDigits.test(label) ||
      sameDigits.test(label) ||
      label.length !== 10
    )
      return;

    const phoneLbl = `(${label.substring(0, 3)}) ${label.substring(
      3,
      6
    )}-${label.substring(6, label.length)}`;
    this.selectedMethod.label = phoneLbl;
    this.sendMoneyForm.get('recipientMethod').setValue(phoneLbl);
    this.scope.$broadcast(
      'mat-autocomplete:changeInput',
      'recipientMethod',
      phoneLbl
    );
  };

  /**
   * Selects the question either one already preloaded or a new one defined by the user
   * @param item The item/question in the input selected
   */
  selectQuestion = (item: any, event: any) => {
    if (event.isUserInput) {
      if (item === undefined) {
        return;
      }
      if (item) {
        this.selectedQuestion = {
          value: item.value,
          label: item.label,
        };
      } else {
        this.selectedQuestion = {
          value: 0,
          label: item,
        };
      }
      this.validQuestion();
    }
  };

  /** Displays the modal showing the 'More Information' steps */
  showMoreInfo(): void {
    const isMobile = window.innerWidth < 768;
    this.matDialog.open(P2PInfoModalComponent, {
      hasBackdrop: !isMobile,
      panelClass: [
        'send-money',
        'p2p-info-modal',
        'modal-dialog',
        'modal-lg',
        'modal-content',
        'modal-header',
        'custom-modal',
      ],
    });
  }

  closeMoreInfo(): void {
    this.modalInstance.close();
  }

  /** Closes the more information modal */
  dismissModal(): void {
    this.modalInstance.close();
  }

  /** Validates the recipient selected */
  _validRecipient(): boolean {
    if (
      !this.selectedRecipient ||
      !this.selectedRecipient.displayName ||
      this.selectedRecipient.displayName.length < 2
    ) {
      this.errorRecipient = {
        hasError: true,
        errorDescription:
          'Please enter a Recipient Name.  Must be at least 2 characters.',
      };

      return false;
    }
    if (
      !this.selectedRecipient.displayName.includes(' ') ||
      this.selectedRecipient.displayName.indexOf(' ') == 0 ||
      this.selectedRecipient.displayName.indexOf(' ') ==
        this.selectedRecipient.displayName.length
    ) {
      this.errorRecipient = {
        hasError: true,
        errorDescription: 'First and last name is required',
      };

      return false;
    }
    this.errorRecipient = {};

    return true;
  }

  /** Validates the selected method */
  _validMethod(): boolean {
    let valid = true;
    if (!this.selectedRecipient) {
      this.selectedRecipient = {
        phone: null,
        email: null,
      };
    } else {
      this.selectedRecipient.phone = null;
      this.selectedRecipient.email = null;
    }
    if (!this.selectedMethod || !this.selectedMethod.label) {
      valid = false;
      this.errorMethod = {
        hasError: true,
        errorDescription: 'Please enter a valid phone or email',
      };
    } else if (!this.validEmail(this.selectedMethod.label)) {
      const sameDigits = /(\d)\1{9}/;
      if (
        !this.validPhoneNumber(this.selectedMethod.label) ||
        sameDigits.test(this.selectedMethod.label)
      ) {
        valid = false;
        this.errorMethod = {
          hasError: true,
          errorDescription: 'Please enter a valid phone or email.',
        };
      } else {
        this.method = P2PMethod.MobilePhone;
        var label = this.selectedMethod.label;
        label = this.selectedMethod.label.replace(/[^0-9]/g, '');
        this.selectedRecipient.phone = {
          areaCode: label.substr(0, 3),
          prefix: label.substr(3, 3),
          extension: label.substr(6, 4),
          number: label.substr(6, 4),
          numberType: 'Evening',
        };
      }
    } else {
      this.method = P2PMethod.Email;
      this.selectedRecipient.emailAddress = this.selectedMethod.label;
    }
    if (valid) {
      this.errorMethod = {};
    }

    return valid;
  }

  resetAmountToDefault(amount) {
    if (this.displayAmount === '0.00') {
      this.displayAmount = '$';
    } else if (this.displayAmount === '' || this.displayAmount == null) {
      this.displayAmount = '0.00';
    }

    this.validAmount(amount);
  }
  onValueAmount(amount) {
    this.amount = amount;
    this.displayAmount = amount;
  }

  /** Validates the amount */
  validAmount(_amount: any): boolean {
    if (!this.amount || this.amount <= 0) {
      this.errorAmount = {
        hasError: true,
        errorDescription: 'Please enter an amount',
      };

      return false;
    }
    this.errorAmount = {};

    return true;
  }

  /** Validates the selected question */
  validQuestion(): boolean {
    if (!this.selectedQuestion || !this.selectedQuestion.label) {
      this.errorQuestion = {
        hasError: true,
        errorDescription: 'Please enter a question',
      };

      return false;
    }
    this.errorQuestion = {};

    return true;
  }

  sendCode() {
    this.isSendingMoney = true;
    const request: MultiFactorRequest = {
      username: window.sessionStorage.getItem('username'),
      authenticationMethod: AuthMethod.Sms,
    };
    this.multifactorService
      .challenge(request)
      .pipe(
        finalize(() => {
          this.isSendingMoney = false;
        })
      )
      .subscribe({
        next: res => {
          this.otp = res.data.otp;
          this.step = 3;
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }
  validateOtp(code: string) {
    this.isSendingMoney = true;
    const request: MultiFactorRequest = {
      username: window.sessionStorage.getItem('username'),
      otp: code,
      authenticationMethod: AuthMethod.Sms,
    };
    delete this.accessCodeError;
    this.multifactorService
      .validateOtp(request)
      .pipe(finalize(() => (this.isSendingMoney = false)))
      .subscribe({
        next: () => {
          this.confirmTransaction();
        },
        error: (err: HttpErrorResponse) => {
          if (err.status >= 500) {
            this.serviceHelper.errorHandler(err, true);
          }
          this.accessCodeError =
            err.error?.message ||
            'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
        },
      });
  }

  resetStep() {
    this.step = 1;
    this.root.$broadcast('payNowStepChanged', 1);
  }

  _showReceipt(confirmationNumber: string) {
    const receiptTemplate = P2PTransferTemplate;
    receiptTemplate.transactions[0].details[0].value = this.account.nickName;
    receiptTemplate.transactions[0].details[1].value = this.selectedRecipient.displayName;
    receiptTemplate.transactions[0].details[2].value = this.selectedMethod.label;
    receiptTemplate.transactions[0].details[3].value = this.cp.transform(
      this.amount
    );
    receiptTemplate.transactions[0].details[4].value = this.selectedQuestion.label;
    receiptTemplate.transactions[0].details[5].value = this.answer;
    receiptTemplate.transactions[0].details[6].value = this.message;
    receiptTemplate.transactions[0].confirmationNumber = confirmationNumber;
    const receiptSettings = {
      amount: this.cp.transform(this.amount),
      fromAccount: this.account.accountCode,
      confirmationEmail: this.root['userInfo'].email,
      confirmationNumber,
      transactions: receiptTemplate.transactions,
      navigateTo: 'udb.transfers.p2p.sendMoney',
    };
    this.state.go('udb.transfers.receipt', {
      settings: receiptSettings,
      template: receiptTemplate,
    });
    this._getRecipients();
    this.clearFields();
  }

  filterRecipients() {
    this.sendMoneyForm.get('recipientName').valueChanges.subscribe(response => {
      if (response && response.length >= 3) {
        this.filteredRecipients = this.recipients.filter(item => {
          return (
            item.displayName.toLowerCase().indexOf(response.toLowerCase()) > -1
          );
        });
      } else {
        this.filteredRecipients = [];
      }
    });
  }
  filterMethods() {
    this.sendMoneyForm
      .get('recipientMethod')
      .valueChanges.subscribe(response => {
        if (response && response.length > 0 && response.length < 12) {
          this.filteredMethods = this.methods.filter(item => {
            return (
              item.label.toLowerCase().indexOf(response.toLowerCase()) > -1
            );
          });
        } else {
          this.filteredMethods = [];
        }
      });
  }
  loadSecretQuestion() {
    this.filteredSecurityQuestions = this.securityQuestions;
  }
  filterSecurityQuestions() {
    this.sendMoneyForm.get('question').valueChanges.subscribe(response => {
      if (response && response.length) {
        if (response.length === 0) {
          this.filteredSecurityQuestions = this.securityQuestions;
        } else {
          this.filteredSecurityQuestions = this.securityQuestions.filter(
            item => {
              return (
                item.label.toLowerCase().indexOf(response.toLowerCase()) > -1
              );
            }
          );
        }
      } else {
        this.filteredSecurityQuestions = this.securityQuestions;
      }
    });
  }
  filterAccountsFrom() {
    this.filteredAccountsFrom = this.accountsFrom?.filter(item => {
      return item.isSBB === false;
    });
  }

  /** Loads the recipients to fill the recipient dropdown */
  _getRecipients(): void {
    this.recipients = [];
    this.placeholderRecipient = 'Loading recipients...';
    this.transactionService
      .getP2PRecipients()
      .then(res => {
        this.recipients = res.data;
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.placeholderRecipient = "Enter Recipient's Name";
      });
  }

  /** Loads the recipient's method to fill the method dropdown */
  _loadMethodRecipient(): void {
    this.methods = [];
    if (this.selectedRecipient.recipientId != 0) {
      if (this.selectedRecipient.emailAddress) {
        this.methods.push({
          label: this.selectedRecipient.emailAddress,
          value: 0,
        });
      }
      if (this.selectedRecipient.phone) {
        this.methods.push({
          label: `${this.selectedRecipient.phone.areaCode}-${this.selectedRecipient.phone.prefix}-${this.selectedRecipient.phone.number}`,
          value: 1,
        });
      }
    }

    if (this.methods.length > 0) {
      this.selectMethod(this.methods[0], { isUserInput: true });
      this.sendMoneyForm.get('recipientMethod').setValue(this.methods[0].label);
      this.scope.$broadcast(
        'mat-autocomplete:changeInput',
        'recipientMethod',
        this.methods[0].label
      );
    }
  }

  /** Loads security questions to fill the Questions dropdown */
  _getSecurityQuestions(): void {
    this.securityQuestions = [];
    this.placeholderQuestion = 'Loading questions...';
    this.settingsService
      .getP2PQuestions()
      .subscribe(
        (res: string[]) => {
          res.forEach((question, i) => {
            this.securityQuestions.push({ label: question, value: i });
          });
        },
        () => this.serviceHelper.errorHandler
      )
      .add(() => {
        this.placeholderQuestion = 'Enter your own question here';
      });
  }

  /** Validates the fields in the form */
  _isFormValid(): boolean {
    const recipient = this._validRecipient();
    const method = this._validMethod();
    const amount = this.validAmount(this.amount);
    const question = this.validQuestion();

    return recipient && method && amount && question && this.isValidAccount;
  }

  validateAccountFrom() {
    if (!this.account || this.sendMoneyForm.get('from').value === '') {
      this.isValidAccount = false;
    } else {
      this.isValidAccount = true;
    }
  }

  /**
   * Validates if the fiven string is a valid email address
   * @param email The email to be tested
   */
  private validEmail(email: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  }

  /**
   * Validates if the given string is a valid phone number
   * @param phone The phone number to be tested
   */
  private validPhoneNumber(phone: string) {
    phone = phone.replace(/[^0-9]/g, '');

    return phone.length === 10;
  }

  /** Clear all the fields once the form was submitted successfully */
  clearFields(): void {
    this.selectedRecipient = {};
    this.selectedMethod = {};
    this.selectedQuestion = {};
    this.account = null;
    this.amount = 0;
    this.answer = '';
    this.message = '';
    this.sendMoneyForm.markAsUntouched();
    this.sendMoneyForm.markAsPristine();
    this.scope.$broadcast('mat-autocomplete:clearInput');
  }

  scrollToError(): void {
    if (this.errorRecipient.hasError) {
      this.serviceHelper.scrollTo('recipientNameGroup');

      return;
    }
    if (this.errorMethod.hasError) {
      this.serviceHelper.scrollTo('recipientMethodGroup');

      return;
    }
    if (this.errorAmount.hasError) {
      this.serviceHelper.scrollTo('amountGroup');

      return;
    }
    if (this.errorQuestion.hasError) {
      this.serviceHelper.scrollTo('questionGroup');

      return;
    }
    if (this.sendMoneyForm.get('from').hasError) {
      this.serviceHelper.scrollTo('fromGroup');

      return;
    }
    if (this.scope['sendMoneyForm'].answer.$invalid) {
      this.serviceHelper.scrollTo('answerGroup');

      return;
    }
  }

  /**
   * Fetch p2p max payment amount customer limit
   */
  private checkCustomerP2PPaymentLimit() {
    this.customerLimitsHelper
      .fetchCustomerLimits()
      .then(({ p2PMaxPaymentAmount }) => {
        this.p2PMaxPaymentAmount = p2PMaxPaymentAmount;
      })
      .catch(this.serviceHelper.errorHandler);
  }

  validateP2PTransferFrom() {
    if (this.accountsFrom) {
      this.accountsFrom = this.accountsFrom.filter(
        (acc: OlbAccount) => acc.canTransferFromP2P
      );
    }
  }

  // This function returns a promise that will be resolved once we have balancesAvailable.
  private onPaymentAccountsLoaded() {
    return new Promise<void>(resolve => {
      if (this.root['balancesAvailable']) {
        resolve();
      } else {
        this.listeners.push(
          this.scope.$on('balancesAvailable', () => {
            resolve();
          })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
    this.subSink.unsubscribe();
  }
}
