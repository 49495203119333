/**
 * Loads the legacy stylesheets that correspond to the given brand.
 * @param brand Brand that will be used to pick the stylesheet.
 * @param appVersion Version of the application to use as query param for cache busting purposes.
 */
export function loadLegacyStylesheets(brand: string, appVersion: string): void {
  const oukStylesheet = `assets/css/${brand}-ouk.css?v=${appVersion}`;
  const brandStylesheet = `assets/css/${brand}.css?v=${appVersion}`;

  appendStylesheet(oukStylesheet);
  appendStylesheet(brandStylesheet);
}

/**
 * Appends a stylesheet to the <head> tag with the given href.
 * @param href Url of the stylesheet to load.
 */
export function appendStylesheet(href: string): void {
  if (!href) return;

  const linkTag = document.createElement('link');
  linkTag.rel = 'stylesheet';
  linkTag.href = href;

  document.head.appendChild(linkTag);
}
