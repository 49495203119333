import { AccountCategory } from '../enums';
import { AggregatedAccount, CategorizedAccounts } from '../models';

export function categorizeAccounts(
  previousState: CategorizedAccounts,
  accounts: AggregatedAccount[]
): CategorizedAccounts {
  const categorisedAccounts = accounts.reduce((acc, item) => {
    const accountNumber = item.accountMask ?? item.accountNumber ?? '';
    const accountMask = `*${accountNumber.substr(accountNumber.length - 4)}`;

    let categories = acc[item.category];
    categories = { ...categories, [item.id]: { ...previousState[item.id], ...item, accountMask } };

    return { ...acc, [item.category]: categories };
  }, {} as Record<AccountCategory, Record<number, AggregatedAccount>>);

  return new CategorizedAccounts({
    checking: categorisedAccounts[AccountCategory.Dda] ?? {},
    savings: categorisedAccounts[AccountCategory.Sav] ?? {},
    loan: categorisedAccounts[AccountCategory.Loan] ?? {},
    cd: categorisedAccounts[AccountCategory.Cd] ?? {},
    credit: categorisedAccounts[AccountCategory.Credit] ?? {},
    other: categorisedAccounts[AccountCategory.Other] ?? {},
    unknown: categorisedAccounts[AccountCategory.Unknown] ?? {},
  });
}
