<h3 class="mt-5 mb-4">How do I set up text alerts?</h3>
<ol>
  <li>
    Click on
    <b>Alerts</b>
    at the top right of the screen.
  </li>
  <li>
    Scroll down to
    <b>Delivery Method</b>
    and check the box next to
    <b>Enable text alert notifications.</b>
  </li>
  <li>
    <b>Send to:</b>
    There you will see your mobile number that we have on file.
  </li>
  <li>
    Once you click on the check box, it will bring up the SMS Opt In disclosure. Click
    <b>Accept and Continue.</b>
  </li>
</ol>
