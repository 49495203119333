import { downgradeComponent } from '@angular/upgrade/static';
import { ProductDetailsPageComponent } from '@app/Areas/AAS/features/product-details-page/view';

export const ProductDetailsPageLegacyModule = angular
  .module('udb.aasproductdetailspage', ['ui.router'])
  .directive('appProductDetailsPage', downgradeComponent({ component: ProductDetailsPageComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: ng.ui.IStateProvider) => {
      $stateProvider.state('udb.productDetailsPage', {
        url: '/Account/Advisor/:accountNumber',
        views: {
          content: {
            template: '<app-product-details-page></app-product-details-page>',
          },
        },
      });
    },
  ]).name;
