import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { add, getUnixTime, isAfter, isBefore } from 'date-fns';

import { IRA_ACCOUNT_TYPES, TRANSFER_CONFIGURATION, TRANSFER_FUNDS_CONTENT } from '@app/axos-invest/constants';
import { GoalAccountType, GoalType, TransactionType, TransferType } from '@app/axos-invest/enums';
import { Frequency } from '@app/axos-invest/enums/frequency.enum';
import { Goal, GoalAccount, MilestoneSummary, TransferFundsContentLabels } from '@app/axos-invest/models';
import { getGoalSummary } from '@app/axos-invest/store/selectors';
import { getRetirementAccounts, goalAccountToNewOlbAccount, mapFrequency } from '@app/axos-invest/utils';
import { getSettings } from '@app/store/selectors';
import { ContributionYearsData } from '@app/transfers/models';
import { TransferService } from '@app/transfers/services/transfer.service';
import { mapDisplayNameOfExternalAccounts } from '@app/utils';
import { AppSettings, OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { DatePickerEvents, DatePickerOptions } from '@shared/models';

@Component({
  selector: 'app-transfer-funds-form',
  templateUrl: './transfer-funds-form.component.html',
  styleUrls: ['./transfer-funds-form.component.scss'],
})
export class TransferFundsFormComponent implements OnInit, OnDestroy {
  @Input() goal: Goal;
  @Input() form: UntypedFormGroup;
  @Input() transferType: TransferType;

  @Output() formSubmitted = new EventEmitter();
  @Output() goBack = new EventEmitter();

  frequencies: GenericOption[];
  internalAccounts: OlbAccount[];
  externalAccounts: OlbAccount[];
  goalAccounts: NewOlbAccount[];
  accountsLoaded: boolean;
  retirementAccountsLoaded: boolean;
  startDatePickerOptions: DatePickerOptions;
  endDatePickerOptions: DatePickerOptions;
  dateFormat = 'MM/dd/yyyy';
  TRANSFER_TYPES = TransferType;
  FREQUENCIES = Frequency;
  retirementAccounts: GoalAccount[];
  transferTypesWithoutFrequency = [TransferType.OneTimeDeposit, TransferType.OneTimeWithdrawal];
  transferTypesWithDisabledStartDate = [TransferType.OneTimeDeposit, TransferType.OneTimeWithdrawal];
  labels: TransferFundsContentLabels;
  currentContributionYear: ContributionYearsData;
  previousContributionYear: ContributionYearsData;
  currentContributionYearLimit: number;
  previousContributionYearLimit: number;
  iraAccountSelected = false;
  selectedWithholdingOption: number;
  contributionYearsLoaded = false;
  appSettings: Record<string, string>;

  private goalSummary: MilestoneSummary;
  private subSink = new SubSink();

  private get transferConfig() {
    return TRANSFER_CONFIGURATION[this.transferType];
  }

  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    private featureFlagService: FeatureFlagService,
    private cachedAccountsService: CachedAccountsService,
    private transferService: TransferService,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private olbEventService: OlbEventService,
    private store: Store
  ) {}

  ngOnInit() {
    this.labels = TRANSFER_FUNDS_CONTENT[this.transferType].labels;
    this.getAppSettings();
    this.getGoalSummary();
    this.setFormEvents();
    this.checkIfAccountsAreLoaded();
    this.buildFrequencies();
    this.configureStartDateOptions();
    this.configureEndDateOptions();
    this.getContributionYears();
    this.getRetirementAccounts();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  submitForm() {
    this.markFormAsTouched();
    this.validateContributionYear();
    if (this.form.invalid) return;

    this.formSubmitted.emit(this.form.value);
  }

  validateContributionYear() {
    const control = this.form.get('contributionYear');
    if (!this.iraAccountSelected) {
      control.reset();
    } else if (!this.previousContributionYear) {
      control.setValue(this.currentContributionYear.year);
    }
  }

  onChangeStartDate(event: DatePickerEvents) {
    this.form.controls.startDate.setValue(event.picker.startDate.toDate());
    this.form.controls.endDate.reset();
  }

  onChangeEndDate(event: DatePickerEvents) {
    this.form.controls.endDate.setValue(event.picker.startDate.toDate());
  }

  getContributionYears() {
    this.transferService
      .getContributionYears()
      .subscribe(contributionYears => this.loadContributionYears(contributionYears));
  }

  goToAddExternalAccounts() {
    sessionStorage.setItem('transferType', this.transferType.valueOf().toString());
    sessionStorage.setItem('milestoneId', this.goal.id);
    const state = this.featureFlagService.isYodleeForFundingActive()
      ? 'udb.dashboard.account-aggregation'
      : 'udb.accounts.add-external';

    this.state.go(state, {
      flow: FlowType.ManagePortfoliosTransFun,
      transferType: this.transferType,
    });
  }

  loadContributionYears(contributionYears: ContributionYearsData[], date = new Date()) {
    contributionYears = contributionYears
      .filter(year => isAfter(date, year.startDate) && isBefore(date, year.endDate))
      .sort((a, b) => getUnixTime(a.startDate) - getUnixTime(b.startDate));
    this.currentContributionYear = contributionYears.slice(-1)[0];
    this.previousContributionYear = contributionYears.length > 1 ? contributionYears.slice(-2)[0] : null;
    this.onChangeContributionYear();
    this.contributionYearsLoaded = true;
  }

  private getGoalSummary() {
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(summary => {
      this.goalSummary = summary;
      this.loadGoalAccounts();
    });
  }

  private loadGoalAccounts() {
    const transferTypesSupported = [TransferType.OneTimeDeposit, TransferType.OneTimeWithdrawal];
    if (!transferTypesSupported.includes(this.transferType)) return;

    const goalAccount = this.form.get('goalAccount').value;

    if (this.goal.type === GoalType.Retirement) {
      const account = this.goal.accounts.find(a => a.accountNumber === goalAccount);
      if (!account || account.type !== GoalAccountType.Personal) {
        delete this.goalAccounts;

        return;
      }
    }

    const openGoals = this.goalSummary.milestones.filter(m => !m.isClosed).map(m => m.id);
    if (!!this.goalSummary.wallet && !this.goalSummary.wallet.isClosed) {
      openGoals.push(this.goalSummary.wallet.id);
    }
    this.goalAccounts = this.goalSummary.accounts
      .filter(
        account =>
          account.accountNumber === goalAccount &&
          account.milestoneId !== this.goal.id &&
          openGoals.includes(account.milestoneId)
      )
      .map(account => goalAccountToNewOlbAccount(account));
  }

  private getRetirementAccounts() {
    if (this.goal.type !== GoalType.Retirement) return;
    this.retirementAccounts = getRetirementAccounts(this.goal.accounts);
    this.retirementAccountsLoaded = true;
    if (this.retirementAccounts && this.retirementAccounts.length > 0 && this.form.value.goalAccount) {
      this.onChangeGoalAccount(this.form.value.goalAccount);
    }
  }

  private setFormEvents() {
    this.subSink.sink = this.form.controls.accountId.valueChanges.subscribe(() => {
      if (this.accountsLoaded) this.loadAccountFromId();
    });

    this.subSink.sink = this.form.controls.accountNumber.valueChanges.subscribe(() => {
      if (this.accountsLoaded) this.loadAccountFromNumber();
    });

    if (this.retirementAccounts !== null) {
      this.form.controls.goalAccount.valueChanges.subscribe(() => {
        this.onChangeGoalAccount(this.form.controls.goalAccount.value);
      });
    }

    this.form.controls.contributionYear.valueChanges.subscribe(() => {
      this.onChangeContributionYear();
    });

    this.subSink.sink = this.form.controls.account.valueChanges.subscribe(account => {
      if (account) this.setMaxAmountValidator();
    });

    this.subSink.sink = this.form.controls.frequency.valueChanges.subscribe(() => this.onChangeFrequency());
  }

  private onChangeGoalAccount(goalAccountNumber: string) {
    const goalAccountInfo = this.retirementAccounts.find(account => account.accountNumber === goalAccountNumber);
    this.previousContributionYearLimit = goalAccountInfo.previousYearContributions || 0;
    this.currentContributionYearLimit = goalAccountInfo.currentYearContributions || 0;
    this.iraAccountSelected = IRA_ACCOUNT_TYPES.includes(goalAccountInfo.type);
    if (this.iraAccountSelected) {
      this.form.controls.contributionYear.setValidators(Validators.required);
    } else {
      this.form.controls.contributionYear.setValidators(null);
      this.form.patchValue({
        contributionYear: null,
      });
      this.configureStartDateOptions();
      this.configureEndDateOptions();
    }
    this.setMaxAmountValidator();
    this.loadGoalAccounts();
  }

  private onChangeContributionYear() {
    const selectedContributionYear =
      this.form.controls.contributionYear.value === this.previousContributionYear?.year
        ? this.previousContributionYear?.endDate
        : this.currentContributionYear?.endDate;
    this.configureStartDateOptions(selectedContributionYear);
    this.configureEndDateOptions(selectedContributionYear);
  }

  private onChangeFrequency() {
    const frequency: Frequency = Number(this.form.controls.frequency.value);
    this.form.controls.frequency.setValue(frequency, { emitEvent: false });
    if (frequency === Frequency.ONE_TIME) {
      this.form.controls.endDate.reset();
    }
  }

  private loadAccountFromNumber() {
    const accountNumber = this.form.controls.accountNumber.value;
    let account = this.internalAccounts.find(internal => internal.accountNumber === accountNumber);
    if (!account) {
      account = this.externalAccounts.find(external => external.accountNumber === accountNumber);
    }
    if (account) {
      this.form.controls.accountId.setValue(account.id);
    }
  }

  private loadAccountFromId() {
    let accountId = this.form.get('accountId').value;

    if (accountId === '-1') {
      this.goToAddExternalAccounts();

      return;
    }

    let account: NewOlbAccount = null;
    if (!isNaN(accountId)) {
      accountId = Number(this.form.controls.accountId.value);
      account = this.internalAccounts.find(a => a.id === accountId);
      if (!account) {
        account = this.externalAccounts.find(a => a.id === accountId);
      }
    } else {
      // when the account id is a string it means it is a milestone account
      account = this.goalAccounts.find(goalAccount => goalAccount.routingNumber === accountId);
    }
    this.form.get('account').setValue(account);
    if (!account) {
      this.form.get('accountId').setValue('', { emitEvent: false });
    }
  }

  private setMaxAmountValidator() {
    let maxAmount = 0;

    const toAccount = this.form.get('account').value as NewOlbAccount;

    const isWithdrawal = this.transferConfig.transactionType === TransactionType.Withdrawal;

    if (isWithdrawal) {
      const goalAccountNumber = this.form.get('goalAccount').value;
      const goalAccount = this.goal.accounts.find(a => a.accountNumber === goalAccountNumber);
      maxAmount = goalAccount?.accountValue;
    } else if (toAccount) {
      maxAmount = toAccount.availableBalance;
    }

    if (toAccount && !toAccount.isAxosInvest) {
      // don't apply clearing caps to MP internal transfers
      const settingName = toAccount.isExternal ? AppSettings.ClearingExternalCap : AppSettings.ClearingInternalCap;
      const setting = Number(this.appSettings[settingName]);

      const isOneTime = this.form.get('frequency').value === Frequency.ONE_TIME;

      if ((toAccount.isExternal && !isWithdrawal) || setting < toAccount.availableBalance || !isOneTime) {
        // apply clearing cap when account is external and not a withdrawal or if the available balance is greater or if it is not a one time transfer
        maxAmount = setting;
      }
    }

    const amountControl = this.form.get('amount');
    const validators = [Validators.required, Validators.min(0.01), Validators.max(maxAmount)];
    amountControl.setValidators(validators);
    amountControl.updateValueAndValidity({ emitEvent: false });
  }

  private getAppSettings() {
    this.store
      .select(getSettings)
      .pipe(take(1))
      .subscribe(settings => (this.appSettings = settings));
  }

  private markFormAsTouched() {
    const controlKeys = Object.keys(this.form.controls);
    controlKeys.forEach(key => {
      this.form.get(key).markAsTouched();
    });
  }

  private checkIfAccountsAreLoaded() {
    if (this.root['balancesAvailable']) {
      this.loadAccounts();
    } else {
      this.subSink.sink = this.olbEventService.on(OlbEvents.BalancesAvailable, () => this.loadAccounts());
    }
  }

  private loadAccounts() {
    const accounts = this.cachedAccountsService.getFromAccounts(true);
    this.internalAccounts = accounts.internalAccounts;
    if (accounts.externalAccounts) {
      this.mapExternalAccounts(accounts.externalAccounts);
    }
    this.accountsLoaded = true;
    if (this.form.controls.accountNumber.value) this.loadAccountFromNumber();
    if (this.form.controls.accountId.value) this.loadAccountFromId();
  }

  private mapExternalAccounts(externalAccounts: OlbAccount[]) {
    this.externalAccounts = [];
    const aggregatedAccounts = this.cachedAccountsService.getAggregatedAccounts();
    externalAccounts.forEach(externalAccount => {
      const aggregatedAccount = aggregatedAccounts.find(
        account => account.accountNumber === externalAccount.accountNumber
      );
      externalAccount.availableBalance = aggregatedAccount?.availableBalance;
      externalAccount.nickname = mapDisplayNameOfExternalAccounts(externalAccount, aggregatedAccount);
      externalAccount.id = externalAccount['externalAccountId'];
      externalAccount.isExternal = true;
      externalAccount.isSBB = aggregatedAccount?.isSBB;
      this.externalAccounts.push(externalAccount);
    });

    const addExternalAccount: OlbAccount = {
      id: -1,
      availableBalance: null,
      nickname: '+Add Non-Axos Account',
    };
    this.externalAccounts.push(addExternalAccount);
  }

  private buildFrequencies() {
    if (this.transferTypesWithoutFrequency.includes(this.transferType)) return;

    this.frequencies = [
      this.getFrequencyOption(Frequency.WEEKLY),
      this.getFrequencyOption(Frequency.TWO_WEEKS),
      this.getFrequencyOption(Frequency.MONTHLY),
      this.getFrequencyOption(Frequency.QUARTERLY),
    ];
    if (this.transferType === TransferType.FundingFlow) {
      this.frequencies.splice(0, 0, this.getFrequencyOption(Frequency.ONE_TIME));
    }
  }

  private getFrequencyOption(frequency: Frequency) {
    return { value: frequency, label: mapFrequency(frequency) };
  }

  private configureStartDateOptions(endDate?: Date) {
    const startDate = this.form.controls.startDate.value;
    if (endDate) {
      this.startDatePickerOptions = {
        minDate: startDate,
        maxDate: endDate,
        singleDatePicker: true,
        autoUpdateInput: false,
      };
    } else {
      this.startDatePickerOptions = {
        minDate: startDate,
        maxDate: add(startDate, { months: 13 }),
        singleDatePicker: true,
        autoUpdateInput: false,
      };
    }
  }

  private configureEndDateOptions(endDate?: Date) {
    const startDate = this.form.controls.startDate.value;
    if (endDate) {
      this.endDatePickerOptions = {
        minDate: startDate,
        maxDate: endDate,
        singleDatePicker: true,
        autoUpdateInput: false,
      };
    } else {
      this.endDatePickerOptions = {
        minDate: startDate,
        maxDate: add(startDate, { months: 13 }),
        singleDatePicker: true,
        autoUpdateInput: false,
      };
    }
  }
}
