import { AccountDetailsTradingController } from './account-details-trading.controller';
//Do not migrate to newest angular version. This feature will not be used in Production
export const AccountDetailsTradingComponent: ng.IComponentOptions = {
  controller: AccountDetailsTradingController,
  controllerAs: '$trad',
  templateUrl: 'axos-trading/account-details-trading/account-details-trading.tpl.html',
  bindings: {
    tradingAccounts: '<',
  },
};
