<div class="text-center" *ngIf="hasFailed; else receipt">
  <app-view-header></app-view-header>
  <h2 id="has-failed-message" class="px-2 py-5">Receipt not available.</h2>
</div>

<ng-template #receipt>
  <app-view-header>
    <app-svg-icon
      [iconName]="brand.toLowerCase() === 'axos' ? icons.check : icons.checkcircle"
      size="3rem"
      iconColor="var(--transfer-receipt-head-icon)"
      class="pb-4"
    ></app-svg-icon>
    <h2 id="template-header" class="px-2">{{ template.header }}</h2>
  </app-view-header>

  <div class="receipt">
    <div class="col-12 receipt__content text-center">
      <div class="pb-4">
        <!-- sub-title [optional] -->
        <p class="receipt-text mt-2" *ngIf="settings.confirmationEmail">
          A confirmation email has been sent to {{ settings.confirmationEmail }}
        </p>

        <!-- confirmation [optional] -->
        <p class="text-bold" *ngIf="settings.confirmationNumber">Confirmation #{{ settings.confirmationNumber }}</p>
      </div>
      <div class="mb-4 px-3">
        <!-- details -->
        <table *ngFor="let tran of settings.transactions" class="table table-striped table--outer-border">
          <thead>
            <tr *ngIf="!tran.toAccount">
              <td colspan="2" class="text-bold">Details</td>
            </tr>
            <tr *ngIf="tran.toAccount">
              <td class="text-bold text-left truncate">{{ tran.toAccount }}</td>
              <td class="text-bold text-right">{{ tran.confirmationNumber }}</td>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let record of tran.details">
              <ng-container *ngIf="record.value">
                <td class="text-left">
                  {{ record.description }}
                </td>
                <td class="text-bold text-right truncate">
                  {{ record.value }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- footnote [optional] -->
      <div class="mb-4">
        <p id="receipt-footnote" *ngIf="template.footnote" [innerHTML]="footNote"></p>
        <p
          id="additional-footnote"
          class="mt-3"
          *ngIf="settings.additionalFootnote"
          [innerHTML]="additionalFootNote"
        ></p>
      </div>

      <!-- navigation -->
      <div class="button-section">
        <button
          id="navigate-to-button"
          type="button"
          class="btn-ok button--lg my-2"
          (click)="navigateTo()"
          *ngIf="settings.navigateTo"
        >
          {{ template.isIra ? 'View Accounts' : template.navigation }}
        </button>

        <button
          id="navigate-back-button"
          type="button"
          class="btn-cancel button--lg my-2"
          (click)="navigateBack()"
          *ngIf="settings.navigateBack"
        >
          {{ template.isIra ? 'Move More Money' : template.navigationBack }}
        </button>
      </div>

      <div *ngIf="template.showBanner" class="panel-receipt-alert">
        <div class="m-2">
          <img class="panel-receipt-icon" [src]="bannerImage" alt="{{ alertBanner.header }} icon" />
        </div>

        <div class="panel-receipt-content">
          <p class="panel-receipt-content__title">{{ alertBanner.header }}</p>
          <p class="panel-receipt-content__detail">
            {{ alertBanner.details[0] }}
            <a class="panel-receipt-link" href="#" id="alert-banner-link" (click)="alertBannerNavigation()">
              {{ alertBanner.linkText }}
            </a>
            {{ alertBanner.details[1] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
