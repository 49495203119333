import { BeneficiaryResponse } from '@shared/models/BeneficiaryResponse';

export class BeneficiariesHelper {
  private beneficiaryResponse: BeneficiaryResponse;

  saveBeneficiaryResponse(response: BeneficiaryResponse): void {
    this.beneficiaryResponse = response;
  }

  getBeneficiaryResponse(): BeneficiaryResponse {
    return this.beneficiaryResponse;
  }

  clearBeneficiaryResponse(): void {
    this.beneficiaryResponse = null;
  }

  isAddBeneficiariesActive(account: OlbAccount): boolean {
    return account.displayAddBeneficiaries && this.isAllowingBeneficiaries(account);
  }

  isAllowingBeneficiaries(account: OlbAccount): boolean {
    return !account.hasMinor;
  }
}
