<div class="statements">
  <h5>eBills Due Soon</h5>
  <div *ngIf="!isLoading" class="statement">
    <span *ngIf="!statements || statements.length === 0" class="empty">
      No eBills due.
    </span>
    <div *ngFor="let statement of statements" class="item">
      <div class="date">
        <span>{{ getMonthDescription(statement.paymentDueDate) }}</span>
        <p>{{ statement.paymentDueDate.slice(8, 10) }}</p>
      </div>
      <div style="margin-left: 5px; width: 70%;">
        <p style="width: 100%;">{{ statement.nickName ? statement.nickName : statement.payee.displayName }}</p>
        <span style="width: 85%;">Current Balance: {{ statement.outstandingBalance | currency }}</span>
        <span style="width: 85%;">Min. Amount: {{ statement.amountDue | currency }}</span>
        <span *ngIf="statement.paid" style="min-width: 50%;">
          <i class="bofi-success" style="margin-right: 5px;"></i>
          Paid.
        </span>
        <div *ngIf="!statement.paid">
          <a class="link" (click)="redirectToPay(recipients, statement.payee.payeeId)">
            Pay
          </a>
          <a class="link" (click)="markAsPaid(statement.payee.payeeId, statement.eBillId)">
            Mark as Paid
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading">
    <app-dot-loader [showWhen]="isLoading" class="frow wrap"></app-dot-loader>
  </div>
</div>
