import { ComponentPortal } from '@angular/cdk/portal';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { IDeferred, IQService } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { IStateService } from 'angular-ui-router';
import { Inject } from 'decorators/decorators';
import * as moment from 'moment';
import { BeneficiariesIraEnhHelper, ToastType } from 'services/beneficiaries-iraenh.helper';
import { ModalService } from 'services/modal.service';
import { SpousalConsentService } from 'services/spousal-consent.service';
import { IAccountsService } from 'services/typings/IAccountsService';
import { IBeneficiariesBaseService } from 'services/typings/IBeneficiariesBaseService';
import { ITaxPlanBeneficiariesService } from 'services/typings/ITaxPlanBeneficiariesService';
import { UserProfileService } from 'services/user-profile.service';

import { SpousalConsentModalComponent } from '@app/accounts/components/modals';
import { BeneficiaryType } from '@app/shared/enums/beneficiary-type';
import { BeneficiaryCommon } from '@app/shared/models';
import { DialogService } from '@core/services';
import { AlertsIcons } from '@shared/enums';
import { dialogConfig, DialogData } from '@shared/models';

import { AccountType } from './account-type.enum';
import { SpousalBeneficiary } from './typings/spousal-beneficiary';
import { SpousalConsent } from './typings/spousal-consent';
import { Beneficiary } from '@shared/models/Beneficiary';

@Inject(
  '$stateParams',
  'accountsService',
  'beneficiariesBaseService',
  'taxPlanBeneficiariesService',
  'modalService',
  '$state',
  '$q',
  'beneficiariesIraEnhHelper',
  'userProfileService',
  'serviceHelper',
  'spousalConsentService',
  'env',
  'dialogService'
)
export class BeneficiariesController {
  readonly spouseRelationshipId = 'Spouse';

  // Loadings
  isLoadingAccount: boolean;
  isLoadingRelationshipCatalog: boolean;
  isProfileLoading: boolean;
  isSaving: boolean;

  accountId: number;
  accountNickname: string;
  accountType: AccountType;
  beneficiariesType: BeneficiaryType; // Base benefs. are always primary (level 1)
  prefix: string;

  // Fields needed for Dira Beneficiaries (Tax Plan Benefs.)
  cif: string;
  taxPlanType: number;
  isCustomerMarried: boolean;
  isSpousalConsentNeededOld: boolean;
  spouseFirstName: string;
  spouseLastName: string;
  spouseEmail: string;

  beneficiaries: BeneficiaryCommon[];
  beneficiariesBackup: BeneficiaryCommon[]; // Shallow clone from 'beneficiaries' used to infer changes onSubmit
  contingencyBeneficiariesBackup: BeneficiaryCommon[]; // Shallow clone from 'beneficiaries' to be deleted when delete the only Primary Beneficiary

  relationshipCatalog: object;

  readonly commonDateFormat = 'MMDDYYYY'; // Format used within this component
  private subSink = new SubSink();

  constructor(
    private readonly params: IStateParamsService,
    private readonly accountService: IAccountsService,
    private readonly beneficiariesBaseService: IBeneficiariesBaseService,
    private readonly taxPlanBeneficiariesService: ITaxPlanBeneficiariesService,
    private readonly modalService: ModalService,
    private readonly stateService: IStateService,
    private readonly qService: IQService,
    private readonly beneficiariesIraEnhHelper: BeneficiariesIraEnhHelper,
    private readonly userProfileService: UserProfileService,
    private readonly serviceHelper: IServiceHelper,
    private readonly spousalConsentService: SpousalConsentService,
    private readonly env: OlbSettings,
    private dialogService: DialogService
  ) {}

  $onInit() {
    this.accountId = this.params.id;
    this.beneficiariesType = ['true', true].includes(this.params.contingent)
      ? BeneficiaryType.Contingent
      : BeneficiaryType.Primary;

    this.isLoadingAccount = true;
    this.accountService
      .getAccountDetails(this.accountId)
      .then(resp => {
        const account = resp.data;
        this.cif = account.cif;
        this.accountType = account.taxPlanType == null ? AccountType.Base : AccountType.Dira;

        // The replace won't be needed anymore after the PBI 480820 is finished,
        //   and the API is updated, this replaces '**' to '*' in the account nickname
        this.accountNickname = account.nickname.replace(/\*{2}(?=\d{4})/, '*');

        if (this.accountType === AccountType.Base) {
          // Map to common beneficiary interface
          this.beneficiariesBackup = account.beneficiaries.map(b => this.mapBaseBeneficiaryToCommon(b));
        } else if (this.accountType === AccountType.Dira) {
          this.taxPlanType = account.taxPlanType;
          this.prefix = this.beneficiariesType === BeneficiaryType.Primary ? 'Primary' : 'Contingent';

          // Map to common beneficiary interface
          this.beneficiariesBackup = account.taxPlanBeneficiaries
            .filter(b => b.level === this.beneficiariesType)
            .map(b => this.mapTaxPlanBeneficiaryToCommon(b));

          if (this.beneficiariesType === BeneficiaryType.Primary) {
            this.contingencyBeneficiariesBackup = account.taxPlanBeneficiaries
              .filter(b => b.level === BeneficiaryType.Contingent)
              .map(b => this.mapTaxPlanBeneficiaryToCommon(b));
          }

          // Need user's profile to know if he is married
          this.isProfileLoading = true;
          this.userProfileService
            .getUserProfileInfo()
            .then(response => (this.isCustomerMarried = response.data.maritalStatus === 'Married'))
            .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
            .finally(() => (this.isProfileLoading = false));
        }

        this.beneficiaries = angular.copy(this.beneficiariesBackup);
        if (this.beneficiaries.length === 0) this.addBeneficiary();

        this.isLoadingRelationshipCatalog = true;
        (this.accountType === AccountType.Base
          ? this.beneficiariesBaseService.getRelationshipCatalog()
          : this.taxPlanBeneficiariesService.getRelationshipCatalog()
        )
          .then(response => (this.relationshipCatalog = response.data))
          .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
          .finally(() => (this.isLoadingRelationshipCatalog = false));
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => (this.isLoadingAccount = false));
  }

  $onDestroy() {
    this.subSink.unsubscribe();
  }

  submit() {
    // Be careful, base beneficiaries key can be duplicated, Jack Henry updates/deletes
    //   the first key that it finds in its database

    const toastSuccessMsg = 'You have successfully modified the beneficiaries on your account.';
    const toastErrorMsg =
      'Some of the beneficiaries could not be saved, please verify the beneficiaries on your account.';

    const beneficiariesBackupCopy = angular.copy(this.beneficiariesBackup); // Copy it since we will mutate it
    let toUpdate: [BeneficiaryCommon, number][] = [];

    const toAdd = this.beneficiaries.filter(benef => {
      const index = beneficiariesBackupCopy.findIndex(benefBack => benefBack.key === benef.key);
      if (index === -1) return true; // Beneficiary wasn't found in the backup, means it is new

      // The beneficiary was updated
      if (this.hasBeneficiaryChanged(beneficiariesBackupCopy[index], benef)) {
        toUpdate.push([benef, benef.percent - beneficiariesBackupCopy[index].percent]);
      }

      beneficiariesBackupCopy.splice(index, 1);

      return false;
    });

    // At this point all items remaining in the backup array were deleted
    let toDelete = beneficiariesBackupCopy;

    // Since there is a validation that the share percentage never exceeds 100% within an account,
    //    sort the updates so that all percentage decrements are executed first
    toUpdate = toUpdate.sort(([_, percentageDifference]) => percentageDifference);

    if (this.accountType === AccountType.Base) {
      /* Save modifications sequentially since API isn't threadsafe within an account
      (because it validates that total percentage never exceeds 100%) */
      this.isSaving = true;
      this.executeRequestsSequentially(
        toDelete.map(b => () => this.beneficiariesBaseService.removeBeneficiary(this.accountId, b.key))
      )
        .then(_ =>
          this.executeRequestsSequentially(
            toUpdate.map(([b, _]) => () =>
              this.beneficiariesBaseService.editBeneficiary(this.accountId, this.mapCommonToBaseBeneficiary(b))
            )
          )
        )
        .then(_ =>
          this.executeRequestsSequentially(
            toAdd.map(b => () =>
              this.beneficiariesBaseService.addBeneficiary(this.accountId, this.mapCommonToBaseBeneficiary(b))
            )
          )
        )
        .then(_ => {
          this.beneficiariesIraEnhHelper.setToast(toastSuccessMsg, ToastType.success);
        })
        .catch(_ => {
          this.beneficiariesIraEnhHelper.setToast(toastErrorMsg, ToastType.error);
        })
        .finally(() => {
          this.isSaving = false;
          this.goBackToAccountDetails();
        });
    } else if (this.accountType === AccountType.Dira) {
      if (this.isSpousalConsentNeeded()) {
        // Beneficiaries will be created through the spusal consent flow

        const spousalConsent: SpousalConsent = {
          FacingBrandId: this.env.facingBrandId,
          Cif: this.cif,
          TaxPlanType: this.taxPlanType,
          FirstName: this.spouseFirstName,
          LastName: this.spouseLastName,
          FullName: `${this.spouseFirstName} ${this.spouseLastName}`,
          Email: this.spouseEmail,

          SpousalBeneficiaries: this.beneficiaries.map(b => this.mapCommonToSpousalBeneficiary(b)),
        };

        this.isSaving = true;
        this.spousalConsentService
          .create(spousalConsent)
          .then(() => {
            this.beneficiariesIraEnhHelper.setToast(toastSuccessMsg, ToastType.success);
          })
          .catch(_ => {
            this.beneficiariesIraEnhHelper.setToast(toastErrorMsg, ToastType.error);
          })
          .finally(() => {
            this.isSaving = false;
            this.goBackToAccountDetails();
          });
      } else {
        /* For Contingent benefs., and Primary benefs. if the account owner isn't married
          Save modifications sequentially since API isn't threadsafe within an account
          (because it validates that total percentage never exceeds 100%)
        */

        this.isSaving = true;
        if (
          this.prefix === 'Primary' &&
          this.beneficiaries.length === 0 &&
          toDelete.length > 0 &&
          this.contingencyBeneficiariesBackup.length > 0
        ) {
          toDelete = [...toDelete, ...this.contingencyBeneficiariesBackup];
        }

        this.executeRequestsSequentially(
          toDelete.map(b => () => this.taxPlanBeneficiariesService.removeBeneficiary(this.cif, this.taxPlanType, b.key))
        )
          .then(_ =>
            this.executeRequestsSequentially(
              toUpdate.map(([b, _]) => () =>
                this.taxPlanBeneficiariesService.editBeneficiary(
                  this.mapCommonToTaxPlanBeneficiary(b, this.cif, this.taxPlanType)
                )
              )
            )
          )
          .then(_ =>
            this.executeRequestsSequentially(
              toAdd.map(b => () =>
                this.taxPlanBeneficiariesService.addBeneficiary(
                  this.mapCommonToTaxPlanBeneficiary(b, this.cif, this.taxPlanType)
                )
              )
            )
          )
          .then(_ => {
            this.beneficiariesIraEnhHelper.setToast(toastSuccessMsg, ToastType.success);
          })
          .catch(_ => {
            this.beneficiariesIraEnhHelper.setToast(toastErrorMsg, ToastType.error);
          })
          .finally(() => {
            this.isSaving = false;
            this.goBackToAccountDetails();
          });
      }
    }
  }

  isBirthDateValid(date: string) {
    const dob = moment(date, this.commonDateFormat);

    return dob.isValid() && dob.isBefore(moment.now());
  }

  isEmailValid(email: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  }

  hasOnlyDigits(text: string) {
    return /^\d+$/.test(text);
  }

  getTotalShare() {
    if (!this.beneficiaries) return 0;

    const totalShare = this.beneficiaries.reduce((sum, benef) => sum + benef.percent, 0);

    return isNaN(totalShare) ? 0 : Math.floor(totalShare);
  }

  addBeneficiary() {
    this.beneficiaries.push({
      key: '',
      level: this.beneficiariesType,
      name: '',
      percent: this.beneficiaries.length === 0 ? 100 : null,
      identity: null,
      relationship: null,
    });

    this.spousalConsentPrompt().catch(_ => {
      this.beneficiaries.pop(); // Undo last action
      this.spousalConsentPrompt(); // So that it keeps track of the state
    });
  }

  onRelationshipChange(beneficiary: BeneficiaryCommon, oldRelationshipId: string) {
    this.spousalConsentPrompt().catch(() => {
      beneficiary.relationship = oldRelationshipId; // Undo last action
      this.spousalConsentPrompt(); // So that it keeps track of the state
    });
  }

  hasMultipleSpouses() {
    return this.beneficiaries.filter(b => b.relationship === this.spouseRelationshipId).length > 1;
  }

  removeBeneficiary(beneficiary: BeneficiaryCommon) {
    const remove = () => {
      const index = this.beneficiaries.indexOf(beneficiary);
      this.beneficiaries.splice(index, 1);
      this.spousalConsentPrompt(); // So that it keeps track of the state
    };

    if (this.beneficiaries.length > 1) {
      remove();

      return;
    }

    // If only 1 beneficiary remaining then ask for confirmation
    this.modalService
      .show(
        {},
        {
          bodyText: `
                    <h3>Remove All Your Beneficiaries?</h3></br>
                    <p>Any contingent beneficiaries will also be removed. If no beneficiaries are named, the assets in your account will go to your estate.</p>
                `,
          okText: 'Remove',
          cancelText: 'Cancel',
          icon: 'bofi-question',
        }
      )
      .then(() => {
        remove();
        this.submit(); // Save changes and go back to account details
      });
  }

  isSpousalConsentNeeded() {
    if (this.accountType === AccountType.Dira && this.beneficiariesType === BeneficiaryType.Primary) {
      if (this.isCustomerMarried && this.beneficiaries.length > 1) return true;
      if (
        this.isCustomerMarried &&
        this.beneficiaries.length === 1 &&
        this.beneficiaries[0].relationship &&
        this.beneficiaries[0].relationship !== this.spouseRelationshipId
      ) {
        return true;
      }

      if (
        !this.isCustomerMarried &&
        this.beneficiaries.length > 1 &&
        this.beneficiaries.find(b => b.relationship === this.spouseRelationshipId)
      ) {
        return true;
      }
    }

    return false;
  }

  spousalConsentPrompt() {
    // Only show the modal when going from a 'not needed' to a 'needed' state.
    //  Since this keeps track of the 'needed' state you need to call this function
    //  every time you add/remove beneficiaries or change the relationshipId

    const isSpousalConsentNeeded = this.isSpousalConsentNeeded();

    return new Promise((resolve, reject) => {
      const dialogData = new DialogData({
        title: 'Request spousal consent?',
        icon: AlertsIcons.QuestionCircle,
        component: new ComponentPortal(SpousalConsentModalComponent),
      });
      dialogConfig.data = dialogData;
      dialogConfig.disableClose = true;

      if (!this.isSpousalConsentNeededOld && isSpousalConsentNeeded) {
        this.subSink.sink = this.dialogService
          .openCustom(dialogConfig)
          .afterClosed()
          .pipe(
            finalize(() => {
              this.isSpousalConsentNeededOld = isSpousalConsentNeeded;
            })
          )
          .subscribe(result => (result ? resolve(result) : reject()));
      }
      // Please do not remove this as this method is being called twice
      else {
        this.isSpousalConsentNeededOld = isSpousalConsentNeeded;
      }
    });
  }

  goBackToAccountDetailsPrompt() {
    this.modalService
      .show(
        {},
        {
          bodyText: `
                    <h3>Back to Account Details?</h3></br>
                    <p>Your changes won't be saved if you leave</p>
                `,
          okText: 'Ok',
          cancelText: 'Cancel',
          icon: 'bofi-question',
        }
      )
      .then(() => this.goBackToAccountDetails());
  }

  goBackToAccountDetails() {
    this.stateService.go('udb.accounts.details', { id: this.accountId, tab: 1 });
  }

  executeRequestsSequentially<T>(
    requests: (() => ApiResponse<T>)[],
    deferred: IDeferred<undefined> = this.qService.defer()
  ): ng.IPromise<undefined> {
    // Given a list of functions that return a promise, execute them sequentially
    //   a new promise is returned which is resolved if all requests completed successfully
    //   the promise will be rejected in the first error and all remaining requests will NOT be executed

    if (requests.length === 0) {
      // All requests have been fulfilled
      deferred.resolve();

      return deferred.promise;
    }

    const request = requests.shift();

    request()
      .then(_ => this.executeRequestsSequentially(requests, deferred))
      .catch(_ => deferred.reject());

    return deferred.promise;
  }

  hasBeneficiaryChanged(old: BeneficiaryCommon, current: BeneficiaryCommon) {
    return (
      old.level !== current.level ||
      old.name !== current.name ||
      old.percent !== current.percent ||
      old.identity !== current.identity ||
      old.relationship !== current.relationship
    );
  }

  // ----------------------- Mappers -----------------------

  mapBaseBeneficiaryToCommon(source: Beneficiary): BeneficiaryCommon {
    // BirthDate is stored in 'Identity' field as a plain string
    //   parse it but check and react to invalid dates (nullify them)
    const birthDate = source.identity ? moment(source.identity, ['MM/DD/YYYY', this.commonDateFormat]) : null;

    return {
      key: source.key,
      level: BeneficiaryType.Primary, // Base beneficiaries are always primary
      name: source.name,
      percent: source.percent,
      identity: birthDate && birthDate.isValid() ? birthDate.format(this.commonDateFormat) : null,
      relationship: source.relationship,
    };
  }

  mapTaxPlanBeneficiaryToCommon(source: TaxPlanBeneficiary): BeneficiaryCommon {
    return {
      key: source.key,
      level: source.level,
      name: source.name,
      percent: source.percent,
      identity: source.birthDate == null ? null : moment(source.birthDate).format(this.commonDateFormat),
      relationship: source.relationshipId,
    };
  }

  mapCommonToBaseBeneficiary(source: BeneficiaryCommon): Beneficiary {
    return {
      key: source.key,
      name: source.name,
      percent: source.percent,
      identity: moment(source.identity, this.commonDateFormat).format('MM/DD/YYYY'),
      relationship: source.relationship,
    };
  }

  mapCommonToTaxPlanBeneficiary(source: BeneficiaryCommon, cif: string, taxPlanType: number): TaxPlanBeneficiary {
    return {
      key: source.key,
      level: source.level,
      name: source.name,
      percent: source.percent,
      birthDate: moment(source.identity, this.commonDateFormat).toISOString(),
      relationshipId: source.relationship,
      cif,
      planCode: taxPlanType,
    };
  }

  mapCommonToSpousalBeneficiary(source: BeneficiaryCommon): SpousalBeneficiary {
    return {
      FullName: source.name,
      Relationship: source.relationship,
      Birthday: moment(source.identity, this.commonDateFormat).toDate(),
      Percentage: source.percent,
    };
  }
}
