import { Action, createReducer, on } from '@ngrx/store';

import { ScheduledTransfer } from '@app/axos-invest/models';
import { signOut } from '@app/store/actions';

import { addScheduledTransfer, deleteScheduledTransfer, loadScheduledTransfers } from './scheduled-transfers.actions';

const _reducer = createReducer(
  null,
  on(loadScheduledTransfers, (_state, action) => {
    return [...action.payload];
  }),
  on(addScheduledTransfer, (state, action) => {
    if (state) {
      const index = state.findIndex(
        t =>
          t.transferSeriesId === action.payload.transferSeriesId ||
          (t.milestoneId === action.payload.milestoneId &&
            t.transactionType === action.payload.transactionType &&
            t.accountNumber === action.payload.accountNumber)
      );
      if (index >= 0) {
        const newState = [...state];
        newState[index] = action.payload;

        return newState;
      }
    }

    return [...state, action.payload];
  }),
  on(deleteScheduledTransfer, (state, action) => {
    if (state) {
      const index = state.findIndex(t => t.transferSeriesId === action.transferSeriesId);
      if (index >= 0) {
        const newState = [...state];
        newState.splice(index, 1);

        return newState;
      }
    }

    return state;
  }),
  on(signOut, () => {
    return [];
  })
);

export function scheduledTransfersReducer(state: ScheduledTransfer[], action: Action) {
  return _reducer(state, action);
}
