import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { AccountTab } from '@app/accounts/enums';
import { AccountSelectItem, AggregatedAccount, OlbAccount } from '@app/accounts/models';
import {
  getAggregatedAccountsForDropdown,
  getAggregatedAccountsList,
  getInternalDepositAccountsList,
  getLoanAccountsList,
  getProviders,
  getTradingAccountsList,
} from '@app/accounts/store/selectors';
import { createDropdownItems } from '@app/accounts/utils';
import { ModifyBannerType } from '@app/axos-invest/enums';
import { InvestAccount } from '@app/axos-invest/models';
import { getInvestAccount } from '@app/axos-invest/store/selectors';
import { capitalize } from '@app/utils';
import { AxosAdvisoryAccount, LegacyRoute, OlbSettings } from '@core/models';
import { ProviderService } from '@core/services';
import { olbSettings, ROOT_SCOPE, STATE_PARAMS } from '@core/tokens';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';

@Component({
  selector: 'acc-account-banner',
  templateUrl: './account-banner.component.html',
  styleUrls: ['./account-banner.component.scss'],
})
export class AccountBannerComponent implements OnInit, OnDestroy {
  @Input()
  availableTabs: AccountTab[];
  @Input()
  activeTab: AccountTab;
  @Input()
  accountType: string;
  @Input()
  selectedAccountId: number;

  @Output()
  navigate = new EventEmitter<LegacyRoute>();
  @Output()
  tabChange = new EventEmitter<AccountTab>();

  selectedItem: AccountSelectItem;
  dropdownItems: AccountSelectItem[];

  showModifiedBanner = false;
  modifiedState: ModifyBannerType;
  modifiedBannerText: string;

  private accounts: (OlbAccount | InvestAccount)[];
  private aggregatedAccounts: AggregatedAccount[];
  private riaAccounts: AxosAdvisoryAccount[];
  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateService,
    @Inject(olbSettings) public settings: OlbSettings,
    private providerSservice: ProviderService,
    private store: Store,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit(): void {
    this.modifiedState = this.params['modifiedState'];
    this.modifiedBannerText = this.params['modifiedBannerText'];

    if (this.modifiedState !== ModifyBannerType.None && this.modifiedBannerText != null) {
      this.showModifiedBanner = true;
    }

    const loadProviders = this.store.select(getProviders).pipe(
      switchMap(data => {
        if (data) {
          return of(data);
        }

        return this.providerSservice.getProviders();
      })
    );

    if (this.accountType == 'advisor') {
      this.subsink.sink = this.store
        .select(getAxosAdvisoryAccounts)
        .pipe(filter(riaAccounts => !!riaAccounts))
        .subscribe(accounts => {
          this.riaAccounts = accounts;
          this.setSelectedItem();
        });
    } else {
      this.subsink.sink = combineLatest([
        loadProviders,
        this.store.select(getInternalDepositAccountsList),
        this.store.select(getLoanAccountsList),
        this.store.select(getTradingAccountsList),
        this.getInvestAccounts(),
        this.store.select(getAggregatedAccountsForDropdown),
      ]).subscribe(([providers, internalDepositAccounts, loanAccounts, tradingAccounts, invest, aggregated]) => {
        const accounts = [...internalDepositAccounts, ...loanAccounts, ...tradingAccounts, ...invest, ...aggregated];
        this.accounts = accounts;
        this.dropdownItems = createDropdownItems(accounts, providers, this.settings);
        this.setSelectedItem();
      });
    }

    this.subsink.sink = this.store.select(getAggregatedAccountsList).subscribe(accounts => {
      this.aggregatedAccounts = accounts;
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  selectItem(data: AccountSelectItem): void {
    const aggrAccnt = this.accounts.find((account: OlbAccount) => account.isExternal && account.id === data.id);

    if (aggrAccnt) {
      const container = this.aggregatedAccounts.find(x => x.id === data.id).container;
      this.navigate.emit(
        new LegacyRoute({ state: 'udb.accounts.external-details', params: { id: data.id, container } })
      );
    } else if (data.id === -1) {
      this.navigate.emit(new LegacyRoute({ state: 'udb.accounts.dashboard' }));
    } else {
      const account = this.accounts.find(acc => acc.id === data.id) as OlbAccount;
      const route = new LegacyRoute({
        state: this.getRedirectState(account.accountType),
        params: {
          id: data.id,
          type: this.getAccountType(account.accountType),
          tab: 0,
        },
      });
      this.navigate.emit(route);
    }
  }

  dismissBannerEvent(params: any) {
    this.showModifiedBanner = params.modified;
    this.params['modifiedState'] = ModifyBannerType.None;
  }

  changeTab(tab: any) {
    this.showModifiedBanner = false;
    this.params['modifiedState'] = ModifyBannerType.None;
    this.tabChange.emit(tab);
  }

  private getInvestAccounts(): Observable<InvestAccount[]> {
    return this.store.select(getInvestAccount).pipe(
      map(account => {
        if (!account.isLoaded || account.isClosed) return [];
        else return [account];
      })
    );
  }

  private getRedirectState(accountType: string): string {
    const validTypes = ['invest', 'trading', 'advisor'];

    if (validTypes.includes(accountType)) {
      return 'udb.accounts.container';
    } else {
      this.$rootScope['accountContainer-tab'] = 0; // <- Set default tab for account container

      return 'udb.accounts.details';
    }
  }

  private getAccountType(accountType: string): string {
    const validTypes = ['invest', 'trading', 'advisor'];

    if (validTypes.includes(accountType)) {
      return capitalize(accountType);
    }
  }

  private setSelectedItem(): void {
    if (this.selectedAccountId && this.accountType === 'advisor') {
      const account = this.riaAccounts.find(acc => acc.accountNumber === this.selectedAccountId.toString());
      this.selectedItem = {
        name: account.accountNickname,
        id: account.accountNumber,
        details: account.accountDisplayName,
        icon: 'advisorIcon',
      };
    } else if (this.selectedAccountId) {
      this.selectedItem = this.dropdownItems.find(account => account.id === this.selectedAccountId);
    } else {
      const accountIndex = this.accounts.findIndex(account => account.accountType === this.accountType);
      this.selectedItem = this.dropdownItems[accountIndex];
    }
  }
}
