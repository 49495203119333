import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { STATE, STATE_PARAMS } from '@core/tokens';

import { FilesIcons, NavigationIcons } from '@shared/enums';
import { StatementsAndDocumentsFacade } from '../../facade';
import { filter, takeUntil } from 'rxjs/operators';
import { StatementStateType, TaxFormStateType } from '../../core/store/types';
import { Subject } from 'rxjs';
import {
  Uk2Tier1NavigationEnum,
  Uk2Tier1FilesEnum,
  Uk2LabeledIconPositionEnum,
  Uk2ButtonSizeEnum,
} from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-statements-and-documents-page',
  templateUrl: './statements-and-documents-page.component.html',
  styleUrls: ['./statements-and-documents-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementsAndDocumentsPageComponent implements OnInit, OnDestroy {
  accountId: number;
  selectedYear: string = 'All';
  selectedAccountDocuments: string = '';
  documentType: string = 'All';
  documentTypes: string[] = ['Statements', 'Documents'];
  private destroy$ = new Subject<void>();
  statementsIcon = FilesIcons.FilePdf;
  icons = {
    ChevronLeft: NavigationIcons.ChevronLeft,
  };
  statements: StatementStateType[] = [];
  filteredStatements: StatementStateType[] = [];
  taxForms: TaxFormStateType[] = [];
  filteredTaxForms: TaxFormStateType[] = [];
  chevronLeftIcon: Uk2Tier1NavigationEnum = Uk2Tier1NavigationEnum.chevronLeft;
  pdfIcon: Uk2Tier1FilesEnum = Uk2Tier1FilesEnum.filePdf;
  uk2LabeledIconPosition: Uk2LabeledIconPositionEnum = Uk2LabeledIconPositionEnum.left;
  uk2LabeledIconSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.small;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private statementsAndDocumentsFacade: StatementsAndDocumentsFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountId = this.params.id;

    this.statementsAndDocumentsFacade.selectedAccountAllDocuments$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(documents => {
        this.statements = documents.statements;
        this.taxForms = documents.taxForms;
        this.filteredStatements = [...this.statements];
        this.filteredTaxForms = [...this.taxForms];
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  filterByYear(selectedYear: number | string) {
    const selectedYearNumber = parseInt(selectedYear as string, 10);
    if (this.selectedYear === 'All') {
      this.filteredStatements =
        this.documentType === 'Statements' || this.documentType === 'All' ? this.statements : [];
      this.filteredTaxForms = this.documentType === 'Documents' || this.documentType === 'All' ? this.taxForms : [];
    } else {
      this.filteredStatements =
        this.documentType === 'Statements' || this.documentType === 'All'
          ? this.statements.filter(statement => new Date(statement.statementDate).getFullYear() === selectedYearNumber)
          : [];
      this.filteredTaxForms =
        this.documentType === 'Documents' || this.documentType === 'All'
          ? this.taxForms.filter(taxForm => parseInt(taxForm.taxYear, 10) === selectedYearNumber)
          : [];
    }
    this.changeDetectorRef.detectChanges();
  }

  filterByDocument() {
    const selectedYearNumber = parseInt(this.selectedYear as string, 10);
    this.filteredStatements = [];
    this.filteredTaxForms = [];

    if (this.documentType === 'All') {
      this.filteredStatements =
        this.selectedYear === 'All'
          ? [...this.statements]
          : this.statements.filter(statement => new Date(statement.statementDate).getFullYear() === selectedYearNumber);

      this.filteredTaxForms =
        this.selectedYear === 'All'
          ? [...this.taxForms]
          : this.taxForms.filter(taxForm => parseInt(taxForm.taxYear, 10) === selectedYearNumber);
    } else if (this.documentType === 'Statements') {
      this.filteredStatements =
        this.selectedYear === 'All'
          ? [...this.statements]
          : this.statements.filter(statement => new Date(statement.statementDate).getFullYear() === selectedYearNumber);
    } else if (this.documentType === 'Documents') {
      this.filteredTaxForms =
        this.selectedYear === 'All'
          ? [...this.taxForms]
          : this.taxForms.filter(taxForm => parseInt(taxForm.taxYear, 10) === selectedYearNumber);
    }

    this.changeDetectorRef.detectChanges();
  }

  getUniqueYears(statements: StatementStateType[], taxForms: TaxFormStateType[]): number[] {
    const uniqueYears = new Set<number>();

    for (const statement of statements) {
      const year = new Date(statement.statementDate).getFullYear();
      uniqueYears.add(year);
    }

    for (const taxForm of taxForms) {
      const year = parseInt(taxForm.taxYear, 10);
      if (!isNaN(year)) {
        uniqueYears.add(year);
      }
    }

    return Array.from(uniqueYears);
  }

  downloadDocument(document: any, show: false): void {
    if (document.id) {
      this.statementsAndDocumentsFacade.downloadStatement(
        (document.accountNumber = this.accountId.toString()),
        document.statementDate,
        document.statementName,
        show
      );
    } else if (document.taxYear) {
      this.statementsAndDocumentsFacade.downloadTaxForm(
        (document.accountNumber = this.accountId.toString()),
        document.taxFormType,
        document.taxYear,
        document.hostKey,
        document.taxFormName,
        show
      );
    }
  }

  goBack(): void {
    this.state.go('udb.productDetailsPage');
  }
}
