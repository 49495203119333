import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-percentage-amount',
  templateUrl: './percentage-amount.component.html',
})
export class PercentageAmountComponent implements OnInit, AfterViewInit {
  @ViewChild('percentageInput') percentageInput: ElementRef;

  @Input() name: string;
  @Input() percentage = 0;
  @Input() isDisabled: boolean;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Output() percentageChange = new EventEmitter<number>();
  @Output() percentageBlur = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.validateMinAndMax();
  }

  ngAfterViewInit(): void {
    if (this.name) {
      this.percentageInput.nativeElement.name = this.name;
    }
  }

  validateAndEmit() {
    this.validateMinAndMax();
    this.percentageBlur.emit();
  }

  emitModelChanges() {
    this.percentageChange.emit(this.percentage);
  }

  validateMinAndMax() {
    if (this.minValue && this.percentage < this.minValue) {
      this.percentage = this.minValue;
      this.emitModelChanges();
    }

    if (this.maxValue && this.percentage > this.maxValue) {
      this.percentage = this.maxValue;
      this.emitModelChanges();
    }
  }
}
