<div class="open-selector" #tooltip>
  <span>All Accounts</span>
  <img [src]="getChevronIcon('down')" aria-hidden="true" />
</div>

<div #dropdown>
  <div class="frow open-account-item" *ngFor="let item of accountItems" (click)="redirectToUrl(item.redirectionUrl)">
    <div class="col-10 icon-description">
      <app-svg-icon [iconName]="item.imageFile" [width]="'22px'" [height]="'22px'"></app-svg-icon>
      <span class="description">{{ item.description }}</span>
    </div>
    <div class="col-2 open-chevron">
      <img [src]="getChevronIcon('right')" aria-hidden="true" />
    </div>
  </div>
</div>
