<td colspan="5">
  <div class="row pt-4 pb-4">
    <div class="col-12 col-sm-3 col-md-3 col-lg-2 text-left text-sm-right">
      <div class="col-12 transaction-header">
        Category
      </div>
      <div class="col-12 mt-2 transaction-category">
        {{ transaction.description | titlecase }}
      </div>
    </div>
    <div class="col-12 col-sm-8 col-lg-7 mt-3 mt-sm-0">
      <div class="row">
        <div class="col-12 transaction-header">Description</div>
        <div class="col-12 mb-2 mt-2">
          <strong>Goal Name:</strong>
          {{ transaction.goal | titlecase }}
        </div>
        <div class="col-12 mb-2">
          <strong>Account Type:</strong>
          {{ transaction.data.accountType }}
        </div>
        <div class="col-12 mb-2" *ngIf="transaction.data.taxYear">
          <strong>Tax Year:</strong>
          {{ transaction.data.taxYear }}
        </div>
        <div class="col-12 mb-2">
          <strong>From Account:</strong>
          {{ transaction.data.bankType | titlecase }} - {{ transaction.data.bankAccountLastFour }}
        </div>
        <div class="col-12 mb-2">
          <strong>{{ transaction.description | titlecase }} Amount:</strong>
          {{ transaction.amount | currency }}
        </div>
        <div class="col-12 mb-2">
          <strong>Status:</strong>
          {{ transaction.subStatusDescription || (transaction.eventDescription | titlecase) }}
        </div>
      </div>
    </div>
  </div>
</td>
