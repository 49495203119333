import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  @Input() alertText = '';
  @Input() buttonText = 'Agree & Continue';
  @Input() isLoading = false;
  @Output() agree = new EventEmitter<void>();

  fileUrl: string;

  constructor(@Inject(olbSettings) private settings: OlbSettings) {}

  ngOnInit(): void {
    this.fileUrl = this.setFileUrl();
  }

  dismissAlert() {
    this.alertText = null;
  }

  acceptTermsAndConditions() {
    this.dismissAlert();
    this.agree.emit();
  }

  private setFileUrl() {
    const { api, facingBrandId } = this.settings;

    return `${api}/enrollment/termsandconditions?fbId=${facingBrandId}`;
  }
}
