import { Action, createReducer, on } from '@ngrx/store';

import { TimePeriod } from '../../enums/time-period.enum';
import { NetWorthFilters } from './net-worth-filters';
import {
  commitFilters,
  resetExcludedAccountsFilter,
  resetTimePeriodFilter,
  resetUncommittedFilters,
  setExcludedAccountsFilter,
  setNetWorthSeries,
  setNetWorthSeriesLoading,
  setTimePeriodFilter,
} from './net-worth.actions';
import { NetWorthState } from './net-worth.state';

export const initialState = new NetWorthState();

const _reducer = createReducer(
  initialState,

  on(setNetWorthSeries, (state, action) => {
    return {
      ...state,
      netWorthSeries: action.payload,
    };
  }),
  on(setNetWorthSeriesLoading, (state, action) => {
    return {
      ...state,
      netWorthSeriesLoading: action.payload,
    };
  }),
  // ---------- Filters ----------
  on(commitFilters, state => {
    return {
      ...state,
      committedFilters: {
        ...state.committedFilters,
        timePeriod: state.uncommittedFilters.timePeriod,
        excludedAccounts: new Set(state.uncommittedFilters.excludedAccounts),
      },
    };
  }),
  on(resetUncommittedFilters, state => {
    return {
      ...state,
      uncommittedFilters: new NetWorthFilters(),
    };
  }),
  on(setTimePeriodFilter, (state, action) => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, timePeriod: action.payload },
    };
  }),
  on(resetTimePeriodFilter, state => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, timePeriod: TimePeriod.LastMonth },
    };
  }),
  on(setExcludedAccountsFilter, (state, action) => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, excludedAccounts: action.payload },
    };
  }),
  on(resetExcludedAccountsFilter, state => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, excludedAccounts: new Set<string>() },
    };
  })
);

export function netWorthReducer(state: NetWorthState, action: Action) {
  return _reducer(state, action);
}
