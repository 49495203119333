import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { ManagedPortfolioAccount } from '@app/axos-invest/models';
import { OlbSettings } from '@core/models';
import { olbSettings, STATE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ProviderService } from '@legacy/services/provider.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FlowType } from '@legacy/typings/app/flow-type.enum';
import { Milestone } from '@legacy/typings/app/wise-banyan/Milestone';
import { UtilityIcons } from '@shared/enums';
import { banks } from '@shared/models';

@Component({
  selector: 'app-funding-account-group',
  templateUrl: './funding-account-group.component.html',
  styleUrls: ['./funding-account-group.component.scss'],
})
export class FundingAccountGroupComponent implements OnInit {
  @Input() axosAccounts: ManagedPortfolioAccount[];
  @Input() nonAxosAccounts: ManagedPortfolioAccount[];
  @Input() milestoneAccount: Milestone;

  @Output() selectAccount = new EventEmitter<number>();

  providers: Provider[];
  isAccountAggregationEnhancementsActive = false;
  plusIcon = UtilityIcons.PlusCircle;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(olbSettings) private env: OlbSettings,
    private providerService: ProviderService,
    private featureFlagService: FeatureFlagService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit() {
    this.isAccountAggregationEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    this.getProviders();
  }

  getProviders() {
    this.providerService
      .getProviders()
      .then((res: OlbResponse<Provider[]>) => {
        this.providers = res.data;
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }
  hasImage(bankName: string): boolean {
    if (this.providers) {
      return !!this.providers.find(provider => this.compareNames(bankName, provider.providerName));
    }

    return false;
  }
  getImage(account: ManagedPortfolioAccount): string {
    let bank: any;
    if (account.isExternal) {
      bank = banks.find(b => b.name.includes(account.bankName));
    }
    const provider = this.providers?.find(p => this.compareNames(account.bankName, p.providerName));
    let logoPath = '/assets/';
    if (this.isAccountAggregationEnhancementsActive) {
      logoPath =
        logoPath +
        (!!provider ? `svg/rounded-logos/${provider.logoName}` : `svg/rounded-logos/${this.env.brand}_round_logo.svg`);
    } else {
      logoPath = logoPath + (bank ? `img/logos/${bank.imageSrc}` : `img/logos/${this.env.brand}_logo.png`);
    }

    return `${logoPath}`;
  }

  goToSettingFund(account: Partial<ManagedPortfolioAccount>) {
    this.selectAccount.emit(account.id);
  }

  getBankProfile(bankName: string): string {
    const splitStr = bankName.split(' ').splice(0, 3);
    let initials = '';
    for (const word of splitStr) {
      initials += word.charAt(0);
    }

    return initials.replace(/[^\w\s]/gi, '');
  }

  redirectToAccAggr() {
    this.state.go('udb.dashboard.account-aggregation', {
      flow: FlowType.AxosInvest,
    });
  }
  private compareNames(bankName: string, providerName: string) {
    return providerName.localeCompare(bankName, 'en', { sensitivity: 'accent' }) === 0;
  }
}
