import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { supportDashboardComponents } from './components';

@NgModule({
  declarations: [...supportDashboardComponents],
  exports: [...supportDashboardComponents],
  imports: [CommonModule],
})
export class SupportDashboardModule {}
