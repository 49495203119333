import { AddBeneficiariesComponent } from './add-beneficiaries/add-beneficiaries.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { BeneficiaryFormComponent } from './beneficiary-form/beneficiary-form.component';
import { EditBeneficiariesComponent } from './edit-beneficiaries/edit-beneficiaries.component';
import { ListBeneficiariesComponent } from './list-beneficiaries/list-beneficiaries.component';

export {
  AddBeneficiariesComponent,
  BeneficiariesComponent,
  BeneficiaryFormComponent,
  EditBeneficiariesComponent,
  ListBeneficiariesComponent,
};

export const sharedComponents = [
  AddBeneficiariesComponent,
  BeneficiariesComponent,
  BeneficiaryFormComponent,
  EditBeneficiariesComponent,
  ListBeneficiariesComponent,
];
