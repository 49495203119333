import { Pipe, PipeTransform } from '@angular/core';

import { maskPhone } from '@app/utils';

@Pipe({
  name: 'maskPhone',
})
export class MaskPhonePipe implements PipeTransform {
  transform(tel: string): string {
    return maskPhone(tel);
  }
}
