import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { IBrokerageData } from 'typings/app/IBrokerageData';
import { UploadService } from 'services/upload.service';
import { IAngularEvent, IFormController } from 'angular';
import { IStateService } from 'angular-ui-router';
import { FundingState } from 'typings/app/FundingState';
import * as ReceiptConstants from '../../../transfers/typings/ReceiptConstants';
import * as moment from 'moment';
import NgRedux from 'ng-redux';
import { STATE, ngRedux } from '@core/tokens';
import { LOAD_USER_PROFILE_HELPER, billPayServiceProvider, SERVICEHELPER } from '@app/bill-pay/ajs-upgraded-provider';
import { SupportService } from '@app/support/services';
import { map } from 'rxjs/operators';
import { SupportCategory } from '@app/support/models';
import { CurrencyPipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-brokerage-form',
  templateUrl: './brokerage-form.component.html',
  styleUrls: ['./brokerage-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class BrokerageFormComponent implements OnInit {
  transfer: IBrokerageData;
  displayName: string;
  brokerageFormData: IFormController;
  isBusy = false;
  termsError = false;
  fundingState: FundingState;
  categories: Category[];

  brokerageForm: FormGroup;
  isSubmitted: boolean = false;
  constructor(
    @Inject(ngRedux) private readonly ngRedux: NgRedux.INgRedux,
    public readonly uploadService: UploadService,
    @Inject(STATE) private readonly state: IStateService,
    @Inject(LOAD_USER_PROFILE_HELPER) private readonly loadUserProfileHelper: ILoadUserProfileHelper,
    private cp: CurrencyPipe,
    @Inject(SERVICEHELPER) readonly serviceHelper: IServiceHelper,
    readonly supportService: SupportService
  ) {}

  ngOnInit() {
    this.isSubmitted = false;
    this.brokerageForm = new FormGroup({
      brokerageInstitution: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30),
      ]),
      brokerageAccountNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(30),
      ]),
      acceptTerms: new FormControl(false, [Validators.required]),
    });
    this.fundingState = this.ngRedux.getState().funding;
    const account = this.fundingState.accountsToBeFunded.find(
      (account: OlbAccount) => account.id === this.fundingState.accountId
    );
    this.transfer = {
      documents: [],
      brokerageInstitution: '',
      brokerageAccountNumber: '',
      toAccount: account,
      acceptTerms: false,
    };
    this.displayName = account.nickname;
    this.loadSMCategories();
  }
  submitTransfer(event: IAngularEvent) {
    this.isSubmitted = true;
    event.preventDefault();

    if (!this.transfer.acceptTerms) {
      this.termsError = true;
      return;
    }

    if (this.brokerageForm.invalid) {
      this.brokerageForm.markAllAsTouched();
      return;
    }

    this.isBusy = true;
    this.termsError = false;
    let request: any = {
      transaction: {
        brokerageInstitution: this.transfer.brokerageInstitution,
        brokerageAccountNumber: this.transfer.brokerageAccountNumber,
        toAccount: {
          accountNumber: this.transfer.toAccount.accountNumber,
          accountType: this.transfer.toAccount.accountType,
          nickname: this.transfer.toAccount.nickname,
        },
      },
    };
    if (this.transfer.documents.length) {
      request.file = this.transfer.documents.map(file => file.Document);
      request.fileFormDataName = this.transfer.documents.map(file => file.Name);
    }

    this.uploadService
      .upload(`transfers/brokerageTransfer`, request)
      .then(res => {
        if (res.data) {
          const userProfile = this.loadUserProfileHelper.getUserProfile();
          const receiptTemplate = { ...ReceiptConstants.RemoteDeposit };
          const details = receiptTemplate.transactions[0].details;
          details[0].value = this.displayName;
          details[1].value = this.cp.transform(this.fundingState.depositAmount);
          details[2].description = 'Date Submitted';
          details[2].value = moment(new Date()).format('MM/DD/YYYY');
          details[3] = {
            description: 'Confirmation #',
            value: res.data.data,
          };
          details.splice(0, 0, {
            description: 'From Account',
            value: `${this.transfer.brokerageInstitution} - **${this.transfer.brokerageAccountNumber
              .toString()
              .substr(this.transfer.brokerageAccountNumber.toString().length - 4)}`,
          });

          const receiptSettings = {
            amount: details[1].value,
            fromAccount: this.transfer.brokerageAccountNumber,
            toAccount: this.transfer.toAccount.accountNumber,
            confirmationEmail: userProfile.primaryEmail,
            confirmationNumber: res.data.data,
            transactions: [{ confirmationNumber: res.data.data, details }],
            isBrokerage: true,
          };
          this.state.go('udb.funding.receipt', {
            settings: receiptSettings,
          });
        }
      })
      .finally(() => (this.isBusy = false));
  }

  gotoSM() {
    const cat = this.categories.find(c => c.description === 'Self-Directed Trading');
    this.state.go('udb.messages.compose-message.subcategories', {
      cId: cat.id,
      cName: cat.name,
    });
  }

  loadSMCategories() {
    this.supportService
      .getCategories()
      .pipe(
        map(categories =>
          categories.data.map(category => {
            return new SupportCategory(category);
          })
        )
      )
      .subscribe(
        data => {
          this.categories = data;
        },
        error => {
          this.serviceHelper?.errorHandler(error);
        }
      );
  }
  goToFunding(funding: string) {
    this.state.go(funding);
  }
}
