import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { myAlertsComponents } from './Components/Index';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDatepickerModule } from '@angular/material/datepicker';

const MaterialModules = [MatIconModule, MatButtonModule, MatDividerModule, MatDialogModule];

@NgModule({
  declarations: [...myAlertsComponents],
  exports: [...myAlertsComponents],
  entryComponents: [...myAlertsComponents],
  imports: [CommonModule, FormsModule, SharedModule, MaterialModules, MatDatepickerModule],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: false, scrollStrategy: new NoopScrollStrategy(), hasBackdrop: false },
    },
  ],
})
export class MyAlertsModule {}
