import { Address } from '@shared/models';

export class MarkAsHotRequest {
  cardMask: string;
  deliveryAddress: Address;
  replaceCard: boolean;
  dueFee: boolean;
  addressChanged: boolean;

  constructor(args: Partial<MarkAsHotRequest>) {
    Object.assign(this, args);

    this.dueFee = !!args.dueFee;
  }
}
