import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { materialDialogProvider } from '@core/providers';
import { SharedModule } from '@shared/shared.module';

import {
  DocumentLibraryComponent,
  DocumentSectionComponent,
  SecureFormsComponent,
  UploadDocumentModalComponent,
  UploadFormComponent,
} from './components';

export const exportComponents = [
  DocumentLibraryComponent,
  DocumentSectionComponent,
  SecureFormsComponent,
  UploadDocumentModalComponent,
  UploadFormComponent,
];

@NgModule({
  declarations: [...exportComponents],
  imports: [CommonModule, FormsModule, SharedModule, MatDialogModule],
  exports: [...exportComponents],
  providers: [materialDialogProvider],
  entryComponents: [...exportComponents],
})
export class SecureFormsModule {}
