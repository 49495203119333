import { formatDate } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { Chart } from 'chart.js';
import { format } from 'date-fns';

import { ForecastItem, ForecastValueFormatted } from '@app/axos-invest/models';
import { AbbreviateCurrencyPipe } from '@shared/pipes';

@Component({
  selector: 'app-projection-graph',
  templateUrl: './projection-graph.component.html',
  styleUrls: ['./projection-graph.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectionGraphComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() projectionData: ForecastItem[];

  forecastData: ForecastValueFormatted[];
  forecastDataMinusTenPercent: ForecastValueFormatted[];
  forecastDataPlusTenPercent: ForecastValueFormatted[];
  forecastDataEndingYear: string;
  forecastDataScaleMin: number;
  forecastDataScaleMax: number;
  forecastDataScaleMinXAxis: string;
  todaysDate = new Date();
  abbreviateCurrency = new AbbreviateCurrencyPipe();
  chart: Chart;

  @ViewChild('chart') private chartRef;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.formatData();
    this.setScale();
    if (this.chartRef) this.initializeChart();
  }

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  formatData() {
    this.forecastData = this.projectionData.map(item => {
      return { x: item.date, y: parseFloat(item.value) };
    });

    this.forecastDataMinusTenPercent = this.forecastData.map(item => {
      return { x: item.x, y: item.y * 0.9 };
    });

    this.forecastDataPlusTenPercent = this.forecastData.map(item => {
      return { x: item.x, y: item.y * 1.1 };
    });

    this.forecastDataEndingYear = formatDate(this.forecastData[this.forecastData.length - 1].x, 'MMM YYY', 'en-US');
  }

  setScale() {
    this.forecastDataScaleMinXAxis = this.forecastData[0].x;
    this.forecastDataScaleMin = this.forecastDataMinusTenPercent[0].y;
    this.forecastDataScaleMax = this.forecastDataPlusTenPercent[this.forecastData.length - 1].y;
  }

    initializeChart() {
      this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        labels: [format(this.todaysDate, 'MMM YYY'), this.forecastDataEndingYear],
        datasets: [
          {
            data: this.forecastDataMinusTenPercent,
            label: 'Potential Losses',
            fill: '+1',
            backgroundColor: '#D4D4D4',
            borderColor: '#ffffff',
            pointRadius: '0',
            pointStyle: 'rect',
          },
          {
            data: this.forecastData,
            label: 'Total Deposits',
            fill: false,
            backgroundColor: '#D4D4D4',
            borderColor: '#616161',
            pointRadius: '0',
            pointStyle: 'line',
          },
          {
            data: this.forecastDataPlusTenPercent,
            label: 'Potential Gains',
            fill: '-1',
            backgroundColor: '#87B9D7',
            borderColor: '#ffffff',
            pointRadius: '0',
            pointStyle: 'rect',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        elements: {
          line: {
            tension: 0,
            fill: false,
            stepped: false,
            borderDash: [],
          },
        },
        legend: {
          display: true,
          align: 'start',
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          mode: null,
        },
        scales: {
          yAxes: [
            {
              type: 'linear',
              ticks: {
                display: true,
                min: this.forecastDataScaleMin,
                suggestedMin: this.forecastDataScaleMin,
                suggestedMax: this.forecastDataScaleMax,
                callback: (value: string): string => {
                  return '$' + this.abbreviateCurrency.transform(value, 2);
                },
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Account Value',
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
              ticks: {
                autoSkip: false,
                min: this.forecastDataScaleMinXAxis,
                source: 'labels',
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
        },
      },
    });

      this.chart.chart.update();
  }
}
