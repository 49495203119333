import { createFeatureSelector } from '@ngrx/store';

import { MilestoneSummary, ScheduledTransfer } from '@app/axos-invest/models';
import { AppStateFeatures } from '@core/enums';

import { InvestStatePieces } from '../enums';
import { goalSummaryInitialState } from './goal-summary/goal-summary.reducer';

export class InvestState {
  [InvestStatePieces.ScheduledTransfers]: ScheduledTransfer[];
  [InvestStatePieces.GoalSummary]: MilestoneSummary;

  constructor(args?: Partial<InvestState>) {
    Object.assign(this, args);
  }
}

export const getInvestState = createFeatureSelector<InvestState>(AppStateFeatures.AxosInvest);

export const investInitialState = new InvestState({
  [InvestStatePieces.ScheduledTransfers]: [],
  [InvestStatePieces.GoalSummary]: goalSummaryInitialState,
});
