<div class="debit-card" [ngClass]="isHorizontalCard ? 'debit-card-horizontal' : 'debit-card-vertical'">
  <div class="card" [ngClass]="cardType">
    <svg class="card__base" width="100%" height="100%">
      <defs>
        <filter id="grayFilter">
          <feComposite
            result="inputTo_38"
            in="SourceGraphic"
            in2="SourceGraphic"
            operator="arithmetic"
            k1="0"
            k2="1"
            k3="0"
            k4="0"
          />
          <feColorMatrix id="filter_38" type="saturate" values="0" data-filterid="38" />
        </filter>
      </defs>
      <image
        class="card__base"
        x="0"
        y="0"
        width="100%"
        height="100%"
        [attr.filter]="(!showNewCardImg && debitCard.isCardGray && 'url(&quot;#grayFilter&quot;)') || undefined"
        [attr.href]="debitCardImage"
        xlink:href=""
      />
    </svg>

    <div class="card-details">
      <div [ngStyle]="{ '--number-value': numberValue }" class="card-details__number row justify-content-around">
        <span>****</span>
        <span>****</span>
        <span>****</span>
        <span>{{ cardLastDigits }}</span>
      </div>
      <span
        [ngStyle]="{ '--date-right-value': dateValueRight, '--date-bottom-value': dateValueBottom }"
        class="card-details__expiration-date"
      >
        {{ debitCard.expirationDate | date: 'MM/yy' }}
      </span>
      <span [ngStyle]="{ '--name-value': nameValue }" class="card-details__customer-name">
        {{ debitCard.embosName | uppercase }}
      </span>
      <span [ngStyle]="{ '--second-name-value': secondNameValue }" class="card-details__customer-name-second">
        {{ debitCard.secondEmbosName | uppercase }}
      </span>
    </div>
  </div>
</div>
