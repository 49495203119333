<section class="investor-checking-enrollment">
  <ng-container
    *ngIf="!riaPilotICODLDebitCardFlagActive; else ICODLDebitCardSection"
  >
    <div style="border-radius: 20px;">
      <div class="dialog-header pb-0">
        <h3>Cash Management Checking Successfully Added</h3>
      </div>
      <div class="dialog-content pt-0 pb-0">
        <hr />
        <div class="dialog-content_body">
          <img
            src="../../../../../assets/axos/img/icons/Overdraft/Overdraft Success.svg"
            class="dialog-content_logo"
          />
          <p>
            Great news! Your
            <b>{{ investorCheckingAccountNickname }}</b>
            {{ successMessage }}
          </p>
          <p></p>
          <br />
        </div>
        <hr />
      </div>
      <div class="dialog-footer pt-0">
        <button type="button" class="dialog-footer_button" (click)="close()">
          Done
        </button>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #ICODLDebitCardSection>
  <div id="container">
    <div id="empty-top-actions"></div>
    <div id="body">
      <ng-lottie
        id="body-animation"
        [width]="'200px'"
        [height]="'200px'"
        [options]="approvedAnimationOptions"
      ></ng-lottie>
      <h3 id="body-title">Your account is successfully opened!</h3>
      <p id="body-text">
        Congratulations! Your
        <b>{{ investorCheckingAccountNickname }}</b>
        {{ successMessage }}
      </p>
      <div id="body-details">
        <div class="details-row">
          <mat-icon
            [svgIcon]="uk2Tier1CommunicationEnum.identification"
          ></mat-icon>
          <span class="details-row-title">Account Number</span>
          <span id="account-number" class="details-row-value">
            {{ this.data.investorCheckingAccountNumber }}
          </span>
        </div>
        <div class="details-row">
          <mat-icon [svgIcon]="uk2Tier1FinancialEnum.piggy"></mat-icon>
          <span class="details-row-title">Account</span>
          <span id="account-title" class="details-row-value">
            Cash Management Checking
          </span>
        </div>
        <div class="details-row">
          <mat-icon [svgIcon]="uk2Tier1FinancialEnum.bank"></mat-icon>
          <span class="details-row-title">Account Type</span>
          <span id="account-type" class="details-row-value">Individual</span>
        </div>
        <mat-card id="email-row" uk2NoteCard>
          <mat-card-content>
            <label id="email-label">
              An email including this information will be sent to {{ email }}
            </label>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div id="footer-buttons" class="dialog-footer">
      <mat-divider></mat-divider>
      <div id="footer-button-group">
        <button uk2TextButton disableRipple mat-button (click)="this.close(); getAllSharedAccounts()">
          Done For Now
        </button>
        <button
          id="button-fund-account"
          uk2PrimaryButton
          disableRipple
          mat-raised-button
          (click)="this.goToMoveMoney()"
        >
          Fund Account
        </button>
      </div>
    </div>
  </div>
</ng-template>
