import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';

import * as jsCookie from 'js-cookie';

import { signOut } from '@app/store/actions';
import { LogoutRequest, OlbSettings } from '@core/models';
import { olbSettings, ROOT_SCOPE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';

import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { MultifactorService } from './multifactor.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private sessionEnd$ = new Subject();

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService,
    private serviceHelper: ServiceHelper,
    private authService: AuthService,
    private logService: LogService,
    private multifactorService: MultifactorService,
    private store: Store
  ) {
    fromEvent(window, 'storage')
      .pipe(
        filter((ev: StorageEvent) => ev.key === 'message' && ev.newValue === 'sessionEnded'),
        takeUntil(this.sessionEnd$)
      )
      .subscribe({
        next: () => {
          this.clearSessionData();
          this.redirectToLogout(true);
        },
      });
  }

  /**
   * Ends the user session.
   * @param forced Indicates if the logout was forced (due to user inactivity).
   * @param redirectToMarketingPage Indicates if the user will be redirected to the login or to the marketing site.
   */
  endSession(forced: boolean, redirectToMarketingPage = true): void {
    const isWebBrowser = !navigator.userAgent.includes('iPhone') && !navigator.userAgent.includes('Android');

    const logoutRequest: LogoutRequest = {
      currentLocation: window.location.href,
      isWebBrowser,
    };

    const action = forced ? 'forcedLogout' : 'logout';

    this.logService.logWebInformation(`End session ${forced ? '- forced' : '- user initiated'}`);

    this.authService[action](logoutRequest)
      .pipe(
        finalize(() => {
          this.clearSessionData();
          this.redirectToLogout(forced, redirectToMarketingPage);
        })
      )
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  private clearSessionData(): void {
    this.store.dispatch(signOut());
    this.storeSessionEndedMessage();
    jsCookie.remove('XSRF-TOKEN', {
      domain: this.settings.antiforgeryCookieDomain,
    });
    this.multifactorService.clearDeviceInformation();
    localStorage.removeItem('lastActivity');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastLogin');
    sessionStorage.clear();
    this.sessionEnd$.next();
  }

  private storeSessionEndedMessage(): void {
    localStorage.setItem('message', 'sessionEnded');
    localStorage.removeItem('message');
  }

  private redirectToLogout(forced: boolean, redirectToMarketingPage = true): void {
    if (redirectToMarketingPage) {
      let logoutUrl = this.$rootScope['brandProperties']['LogoutUrl'];

      if (forced) {
        logoutUrl += '?status=inactive';
      } else {
        logoutUrl += '?status=active';
      }

      this.goTo(logoutUrl);

      return;
    }

    this.goTo('/');
  }

  private goTo(location: string): void {
    window.location.href = location;
  }
}
