import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { RIAUserEffect } from './support-view.effects';
import {
  selectNoAxosAccountsFlag,
  selectNoAxosAccountsIsLoading,
  selectRIAPilotFlag,
  selectRIAUserFlag,
  selectRIAUserLoading,
} from './support-view.selectors';
import { getAccountsState } from '@app/accounts/store/accounts.state';

@Injectable()
export class SupportViewFacade {
  isRIAUser$ = this.store.pipe(select(selectRIAUserFlag));
  isLoading$ = this.store.pipe(select(selectRIAUserLoading));
  isRIAPilotActive$ = this.store.pipe(select(selectRIAPilotFlag));
  noAxosAccounts$ = this.store.pipe(select(selectNoAxosAccountsFlag));
  axosAccountsLoading$ = this.store.pipe(select(selectNoAxosAccountsIsLoading));
  userAccounts$ = this.store.pipe(select(getAccountsState));

  constructor(private store: Store, private riaUserEffects: RIAUserEffect) {}
  getRIAUserFlag() {
    this.riaUserEffects.getRIAPilotFeatureFlag();

    this.isRIAPilotActive$.subscribe(isActive => {
      if (isActive) {
        this.riaUserEffects.getRIAUserFlag();
        this.riaUserEffects.getHasAxosAccounts();
      }
    });
  }
}
