import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UpdatePhonesRequest } from '@app/user-profile/models';
import { loadBusinessInfo, loadCompanyProfiles } from '@app/user-profile/store/actions';
import { loadCompanyDetails } from '@app/user-profile/store/company-details/company-details.actions';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';
import { AddressAccountAssociation, CompanyDetail, ServiceResult, WebApiResponse } from '@shared/models';
import { BusinessDetail } from '@shared/models/business-detail.model';

import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root',
})
export class BusinessService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient, private store: Store) {
    super(http, settings, 'business');
  }

  getCompanyDetails(companyId: number): Observable<ServiceResult<CompanyDetail>> {
    return this.get<CompanyDetail>(`details/${companyId}`).pipe(
      tap(({ data }) => {
        this.store.dispatch(loadCompanyDetails({ payload: data }));
      })
    );
  }

  // Get the Account Business Profiles
  getAccountProfiles(): WebApiResponse<AccountProfile[]> {
    const response = this.get<AccountProfile[]>('profiles');

    response.subscribe({
      next: resp => {
        this.store.dispatch(loadCompanyProfiles({ accountProfiles: resp.data }));
      },
      error: _ => {
        this.store.dispatch(loadCompanyProfiles({ accountProfiles: [] }));
      },
    });

    return response;
  }

  logSelectedAccountProfile(accountProfile: AccountProfile): Observable<any> {
    const userActionField = new UserActionField('', '', `Customer Selected ${accountProfile.name}`);
    const userAction = new UserAction('CorporateVeilIntercept', [userActionField]);

    return this.post('audit', userAction);
  }

  switchProfile(prevProfile: AccountProfile): Observable<any> {
    return this.post('switchProfile', prevProfile);
  }

  /**
   * Updates the company's email addresses.
   *
   * @param request Updated email addresses.
   *
   * @returns An Observable containing a string with the response from the serve.
   */
  updateCompanyEmailAddresses(email: string, altEmail: string, businessId: number): WebApiResponse<string> {
    return this.put<string>('email', { primaryEmail: email, alternateEmail: altEmail, selectedBusinessId: businessId });
  }

  /**
   * Updates the company's phone.
   *
   * @param request Updated phone.
   *
   * @returns An Observable containing a string with the response from the serve.
   */
  updateCompanyPhone(request: Partial<UpdatePhonesRequest>): WebApiResponse<string> {
    return this.put<string>('phone', request);
  }

  /**
   * Updates the company's fax.
   *
   * @param phone Updated fax.
   *
   * @returns An Observable containing a string with the response from the serve.
   */
  updateCompanyFax(fax: Phone): WebApiResponse<string> {
    return this.put<string>('phone', { workFax: fax });
  }

  /**
   * Updates company's addresses.
   *
   * @param addresses An array of addresses.
   *
   * @returns An Observable containing the result of the operation..
   */
  updateCompanyAddresses(companyId: number, addresses: AddressAccountAssociation[]): WebApiResponse<null> {
    return this.put<null>(`address/${companyId}`, addresses);
  }
  getBusinessProfileDetails(): Observable<ServiceResult<BusinessDetail>> {
    return this.get<BusinessDetail>('businessProfile').pipe(
      tap(({ data }) => {
        this.store.dispatch(loadBusinessInfo({ businessData: data }));
      })
    );
  }

  updateBusinessInformationEmail(businessModel: BusinessDetail): Observable<ServiceResult<BusinessDetail>> {
    return this.put<BusinessDetail>('businessProfile/email', businessModel).pipe(
      tap(({ data }) => {
        this.store.dispatch(loadBusinessInfo({ businessData: data }));
      })
    );
  }

  updateBusinessInformationPhone(businessModel: BusinessDetail): Observable<ServiceResult<BusinessDetail>> {
    return this.put<BusinessDetail>('businessProfile/phone', businessModel).pipe(
      tap(({ data }) => {
        this.store.dispatch(loadBusinessInfo({ businessData: data }));
      })
    );
  }

  updateBusinessInformationAddress(businessModel: BusinessDetail): Observable<ServiceResult<BusinessDetail>> {
    return this.put<BusinessDetail>('businessProfile/address', businessModel).pipe(
      tap(({ data }) => {
        this.store.dispatch(loadBusinessInfo({ businessData: data }));
      })
    );
  }
}
