import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Inject } from '../decorators/decorators';
import { ObservableBaseService } from './observable-base.service';
import { ISettingsService } from './typings/ISettingsService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class SettingsService extends ObservableBaseService implements ISettingsService {
  get axosInvestSSOToken(): string {
    return this.axosInvestSsoToken;
  }
  set axosInvestSSOToken(value: string) {
    this.axosInvestSsoToken = value;
  }
  get featureFlags(): FeatureFlag[] {
    return this._featureFlags;
  }
  get publicFeatureFlags(): FeatureFlag[] {
    return this._publicFeatureFlags;
  }

  private axosInvestSsoToken: string;

  private _featureFlags: FeatureFlag[] = [];

  private _publicFeatureFlags: FeatureFlag[] = [];
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'configuration', _serviceHelper, q);
  }

  getAllAppSettings(): Observable<AppSetting[]> {
    return this.get<AppSetting[]>('appsettings').pipe(map(({ data }) => data));
  }

  getSecurityQuestions(): Observable<string[]> {
    return this.get<string[]>('availableQuestions').pipe(map(({ data }) => data));
  }

  getP2PQuestions(): Observable<string[]> {
    return this.get<string[]>('PtoPQuestions').pipe(map(({ data }) => data));
  }

  getFeatureFlags(): Observable<FeatureFlag[]> {
    if (this._featureFlags && this._featureFlags.length) {
      return of(this._featureFlags);
    }

    return this.get<FeatureFlag[]>('featureFlags').pipe(
      tap(({ data }) => {
        this._featureFlags = data;
      }),
      map(({ data }) => data)
    );
  }

  getPublicFeatureFlags(): Observable<FeatureFlag[]> {
    if (this._publicFeatureFlags && this._publicFeatureFlags.length) {
      return of(this._publicFeatureFlags);
    }

    return this.get<FeatureFlag[]>('featureflags/public').pipe(
      tap(({ data }) => {
        this._publicFeatureFlags = data;
      }),
      map(({ data }) => data)
    );
  }
}
