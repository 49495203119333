<div class="atp">
  <div class="atp__container">
    <div class="atp__logo">
      <img src="assets/img/others/hands.svg" />
    </div>
    <div class="atp__text">
      <div class="atp__text--title">
        Authorize transfers with your advisor
      </div>
      <div class="atp__text--content">
        Authorize your advisor to manage fund transfers, enabling seamless transactions from your selected and
        advisor-managed accounts.
      </div>
      <div class="atp__text--tooltip">
        <uk2-tooltip
          [labelText]="tooltipProperties.labelText"
          [size]="tooltipProperties.size"
          [bodyText]="tooltipProperties.bodyText"
          [uk2IsLoading]="tooltipProperties.uk2IsLoading"
          [displayCloseButton]="tooltipProperties.displayCloseButton"
          [arrowOffset]="tooltipProperties.arrowOffset"
          [svgIconName]="tooltipProperties.svgIconName"
          [placement]="tooltipProperties.placement"
          [desktopOpenMode]="tooltipProperties.desktopOpenMode"
          [strategy]="tooltipProperties.strategy"
        ></uk2-tooltip>
      </div>
    </div>
    <div class="atp__card">
      <div class="atp__card--text">
        <strong>Please Note:</strong>
        You can change these settings anytime within each account details page
      </div>
    </div>
    <div class="atp__advisors">
      <ng-container *ngFor="let account of accounts">
        <div class="atp__advisors--advisor">
          <app-authorize-transfer-toggle
            [account]="account"
            (accountChanges)="onAuthorizationChange(account, $event)"
          ></app-authorize-transfer-toggle>
        </div>
      </ng-container>
    </div>
    <div class="atp__actions">
      <button
        class="atp__actions--action"
        uk2PrimaryButton
        disableRipple
        mat-raised-button
        [uk2ButtonSize]="btnOptions.size"
        [uk2IsLoading]="btnOptions.isLoading"
        [disabled]="!isAuthorizeEnable"
        (click)="authorize()"
      >
        Authorize
      </button>
      <div class="atp__actions--action skip" [ngClass]="{ 'skip-disabled': isAuthorizeEnable }" (click)="skipForNow()">
        Skip For Now
      </div>
    </div>
  </div>
</div>
