import { Component, Input, OnChanges } from '@angular/core';

import { SpendingFilters } from '@app/pfm/store/spending/spending-filters';
import { NavigationIcons } from '@shared/enums';

import { categories } from '../../models/category';
import { Category } from '../../models/category';

@Component({
  selector: 'app-categories-grid',
  templateUrl: './categories-grid.component.html',
  styleUrls: ['./categories-grid.component.scss'],
})
export class CategoriesGridComponent implements OnChanges {
  @Input() categories: Category[];
  @Input() totalSpent: number;
  @Input() filters: SpendingFilters;
  categoriesGrid: Category[] = [];
  ctaShow = false;
  showCategories = true;
  isEmpty = false;
  categoriesFiltered = false;
  maxCategoriesToShow = 3;
  icons = {
    chevronUp: NavigationIcons.ChevronUp,
    chevronDown: NavigationIcons.ChevronDown,
  };

  constructor() {}

  ngOnChanges(): void {
    this.initCategoriesData();
  }

  sortCategories(categ: Category[]): Category[] {
    return categ.sort((a, b) => (a.amount > b.amount ? -1 : a.amount === b.amount ? (a.name > b.name ? 1 : -1) : 1));
  }

  initCategoriesData(): void {
    this.categoriesGrid = this.sortCategories([...this.categories]);

    // For responsive only verify if categories are being filtered
    this.categoriesFiltered = this.categoriesGrid.length !== categories.length;

    // Recalculating percentages because these are sent to this component already rounded
    this.categoriesGrid = this.categoriesGrid.map(category => ({
      ...category,
      percentage: category.amount / this.totalSpent,
    }));

    // 0 percentages should be null in order to show two lines
    this.categoriesGrid.forEach(categ => {
      if (categ.percentage === 0) categ.percentage = null;
    });

    this.isEmpty = this.totalSpent === 0;
  }

  categoryTrackBy(_, category: Category) {
    return category.id;
  }

  ctaClick() {
    this.ctaShow = !this.ctaShow;
  }

  clickAccordion() {
    this.showCategories = !this.showCategories;
    if (this.showCategories) this.ctaShow = false;
  }
}
