export class HistoricMetrics {
  deposits: number;
  withdrawals: number;
  dividends: number;
  totalValue: number;
  dayChangeAmount: number;
  dayChangePercentage: number;

  constructor(params?: Partial<HistoricMetrics>) {
    Object.assign(this, params);
  }
}
