<app-funding-shared-header></app-funding-shared-header>

<div class="alert alert-info frow no-wrap center middle maintenance-banner" *ngIf="isSiteInReadOnly">
  <i class="bofi-information info"></i>
  <div class="message">
    We're performing nightly maintenance on your account. Most account functionality is still available during this
    time.
    <app-tooltip [content]="toolTipContent">
      <a>Learn more</a>
    </app-tooltip>
  </div>
</div>

<div class="funding px-0" ui-view="funding-content" autoscroll>
  <div class="fcol-cs-12 fcol-sm-10 fcol-lg-8 container--content pt-0">
    <div class="header-section-title text-center">
      <div [ngSwitch]="step">
        <h1 *ngSwitchCase="0">Account to Fund</h1>
        <h1 *ngSwitchDefault>Funding {{ step === 1 ? 'Amount' : 'Method' }}</h1>
        <p class="m-t-lg" *ngIf="step > 0">
          {{ selectedAccount.nickname }}
        </p>
      </div>
    </div>

    <app-dot-loader [showWhen]="isLoading && !balancesLoaded">
      Loading
    </app-dot-loader>
    <div>
      <div *ngIf="!isLoading" [ngSwitch]="step">
        <div *ngSwitchCase="0">
          <h1 class="text-center">
            Select an account to fund with an initial deposit.
          </h1>
          <div class="fcol middle search-results">
            <div
              class="frow search-results__item middle between"
              *ngFor="let account of accounts; trackBy: identifyAccountNickName"
              (click)="onAccountSelected(account)"
            >
              <span>{{ account.nickname }}</span>
              <i class="bofi-arrow-chevron"></i>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="1" class="deposit-amount">
          <h1 class="text-center">Enter the amount of your initial deposit.</h1>

          <div class="frow center" [ngClass]="{ 'has-error': !!errorAmount }">
            <input
              id="depositAmount"
              type="text"
              class="uk-input deposit-amount__input"
              prefix="$"
              mask="separator.2"
              [(ngModel)]="maskedAmount"
              (ngModelChange)="isValidAmount(false)"
              (focusin)="resetDepositAmountToDefault()"
              (blur)="resetDepositAmountToDefault()"
              required
            />
          </div>
          <div class="frow center">
            <span class="text-error">{{ errorAmount }}</span>
          </div>
        </div>

        <div *ngSwitchCase="2">
          <h3 class="text-center">
            Make your initial deposit by selecting the method that works best for you.
          </h3>

          <app-show-more
            class="funding-method__selection no-gutters"
            [shownItems]="2"
            [itemSelector]="'app-funding-method'"
          >
            <div class="col-sm-10 funding-methods">
              <!-- funding methods 1 and 2 will not be shown for Cd accounts type -->
              <app-funding-method
                *ngIf="showBankTransfer"
                class="col-md-6"
                [imgSrc]="'external-transfer.svg'"
                [name]="'Bank Transfer'"
                [description]="'The quickest way to transfer money.'"
                [directions]="fundingText"
                (onFunding)="toFundingMethod(fundingMethod.ExternalTransfer)"
                [isBusy]="isBusy"
              ></app-funding-method>

              <app-funding-method
                *ngIf="showDirectDeposit"
                class="col-md-6"
                [imgSrc]="'lightbulb.svg'"
                [name]="'Direct Deposit'"
                [description]="'Set up automatic deposits every payday.'"
                [directions]="
                  'Automatically deposit your paycheck or other recurring income directly to your bank account.'
                "
                (onFunding)="toFundingMethod(fundingMethod.DirectDeposit)"
              ></app-funding-method>

              <app-funding-method
                *ngIf="!isCdAccountType && isTradingAccount"
                class="col-md-6"
                [imgSrc]="'suitcase.svg'"
                [name]="'Brokerage Transfer'"
                [description]="'A convenient way to transfer investments.'"
                [directions]="
                  'Initiate a transfer of your holdings from an existing brokerage account to your new Axos account.'
                "
                (onFunding)="toFundingMethod(fundingMethod.BrokerageTransfer)"
              ></app-funding-method>

              <app-funding-method
                class="col-md-6"
                [imgSrc]="'wire-transfer.svg'"
                [name]="'Wire Transfer'"
                [description]="'A secure method to deposit large amounts.'"
                [directions]="wireTransferDirections"
                (onFunding)="toFundingMethod(fundingMethod.WireTransfer)"
              ></app-funding-method>

              <app-funding-method
                *ngIf="depositAmount < remoteDepositLimit && !isCdAccountType && !isTradingAccount"
                class="col-md-6"
                [imgSrc]="'remote-deposit.svg'"
                [name]="'Deposit a Check'"
                [description]="'Use a mobile device to deposit a check.'"
                [directions]="
                  'Use a mobile device to conveniently capture an image of your check and upload for deposit.'
                "
                (onFunding)="toFundingMethod(fundingMethod.RemoteDeposit)"
              ></app-funding-method>

              <app-funding-method
                class="col-md-6"
                [imgSrc]="'mail-check.svg'"
                [name]="'Mail a Check'"
                [description]="'Send us a check to manually deposit.'"
                [directions]="mailCheckInstructions"
                (onFunding)="toFundingMethod(fundingMethod.MailCheck)"
              ></app-funding-method>
            </div>
          </app-show-more>
        </div>
      </div>
    </div>

    <div class="frow center form-group funding-nav" *ngIf="!isLoading">
      <div class="col-12 col-md-8 col-lg-6 funding-nav__actions">
        <button type="button" class="btn-cancel button--lg" (click)="goBack()">
          {{ (step <= 1 && accounts.length == 1) || isCdAccountType ? 'Fund Later' : 'Back' }}
        </button>
        <button
          type="submit"
          class="btn-ok button--lg"
          [ngClass]="step === 1 ? 'funding-nav__continue--visible' : 'funding-nav__continue--hidden'"
          (click)="onClickContinue()"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
