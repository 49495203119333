<div class="container axos-invest-goal-projection">
  <app-dot-loader *ngIf="isLoading">Loading goal...</app-dot-loader>
  <div class="row" *ngIf="!isLoading">
    <div class="col-6">
      <h3 class="goal-projection-title">Goal Projection</h3>
    </div>
    <div class="col-6 ml-auto">
      <div class="edit-button" *ngIf="milestoneData.type !== 5">
        <button class="edit-label-btn" type="button" (click)="redirectToEditGoal(milestoneData.type)">
          <app-svg-icon
            class="edit-label-btn-icon"
            [iconName]="icons.edit"
            width="1.15em"
            height="1.15em"
            iconColor="#1c7fdd"
          ></app-svg-icon>
          <span class="edit-goal-label">Edit Goal</span>
        </button>
      </div>
    </div>
  </div>

  <div class="goal-info-section d-inline-flex" *ngIf="!isLoading">
    <app-svg-icon
      *ngIf="!isLoading"
      [iconName]="icons.goalType"
      width="1.5em"
      height="1.5em"
      iconColor="#4A5560"
    ></app-svg-icon>
    <h3 class="goal-title" *ngIf="!isLoading">{{ milestoneData.name }}</h3>
    <app-svg-icon
      *ngIf="!isLoading && milestoneData.targetAmount"
      [iconName]="icons.badge"
      width="1.5em"
      height="1.5em"
      iconColor="#4A5560"
    ></app-svg-icon>
    <h3 class="target-amount-title" *ngIf="!isLoading && milestoneData.targetAmount">
      {{ milestoneData.targetAmount | currency }}
      <span *ngIf="milestoneData.targetDate">by {{ milestoneData.targetDate | date: 'yyyy' }}</span>
    </h3>
  </div>
  <app-dot-loader *ngIf="isLoadingRecurringDeposit">Loading recurring deposits...</app-dot-loader>
  <app-recurring-deposit
    *ngIf="hasRecurringDeposit && !isLoadingRecurringDeposit"
    [recurringDesposits]="recurringDesposits"
    [referrerTabId]="referrerTabId"
  ></app-recurring-deposit>
  <app-dot-loader *ngIf="isLoadingProjection">Loading goal projection...</app-dot-loader>
  <div *ngIf="!isLoadingProjection">
    <p class="projection-value-title">Value Projection</p>
    <p class="projection-value-amount">
      {{ projectionValueLow | currency: 'USD':'symbol':'1.0-0' }} -
      {{ projectionValueHigh | currency: 'USD':'symbol':'1.0-0' }}*
    </p>
    <p class="projection-value-date">By {{ projectionValueDate | date: 'MMM YYYY' }}</p>
  </div>
  <app-projection-graph [projectionData]="forecastList" *ngIf="!isLoadingProjection"></app-projection-graph>
  <div class="projection-disclaimer" (click)="toggleProjectionDisclaimer()" *ngIf="!isLoadingProjection">
    <div class="projection-disclaimer-header">
      <p>How is this projection calculated?</p>
      <app-svg-icon class="projection-disclaimer-header-icon" [iconName]="icons.down" size="1em"></app-svg-icon>
    </div>
    <div class="projection-disclaimer-content" *ngIf="viewProjectionDisclaimer">
      <p>
        Future calculations are based upon previous ten-year performance of investments in your portfolio, with a gross
        rate of return net of 0.24% annual advisory fee, with 2% inflation. Potential variances of the calculations
        presented are figured by use of the actual, high and lowest values of your investments over the previous ten
        year value, then calculated using simple and time weighted return. The simple return is based upon your
        account’s rate of growth over the time period selected. Time weighted return measures your account’s compounded
        rate of growth over the previous time period selected, then used to forecast a potential future value. The
        projection is informational and are not intended to provide specific advice or recommendations for any
        individual. Asset allocation and diversification does not ensure a profit or protect against a loss. It is
        important to read the prospectus of the exchange traded fund recommended or selected to review the fund’s
        investment objectives, risk tolerance, tax objectives and liquidity needs before choosing an investment style or
        manager. All investments carry a certain degree of risk and no one particular investment style or manager is
        suitable for all types of investors. Investors should consult with their tax advisor before undertaking any
        strategy. This chart presented is for illustrative purposes only and should not be considered as the sole basis
        for your investment decision. Past performance is not a guarantee of future results, and future market
        conditions may vary. Advisory fees and expenses will reduce your portfolio’s rate of return.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6 ml-auto mr-auto">
      <app-dot-loader *ngIf="isLoadingSimulateDeposit">Loading deposit simulator...</app-dot-loader>
      <app-simulate-deposit
        (simulateDepositEvent)="simulateDepositEvent($event)"
        *ngIf="!isLoadingSimulateDeposit"
        [milestoneId]="milestoneId"
        [recommendedDepositAmount]="recommendedDepositAmount"
        [referrerTabId]="referrerTabId"
      ></app-simulate-deposit>
    </div>
  </div>
</div>
