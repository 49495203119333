import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UIKitModalConfiguration } from '@uikit/tiles';
import { AccountCategory } from 'accounts/account-category.enum';
import { containerType } from 'accounts/container-type.enum';
import { TradingAccount } from 'accounts/typings/TradingAccount';
import * as angular from 'angular';
import { ClearingAccountStatus } from 'common/enums/enums';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { TilesService } from 'services/tiles.service';
import { AggregatedAccount } from 'typings/app/account-aggregation';

import { InvestAccount } from '@app/axos-invest/models';
import { getInvestAccount } from '@app/axos-invest/store/selectors';

import { Inject } from '../../decorators/decorators';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';
import { BalanceService } from './../../services/balance.service';
import { CachedTradingAccountsService } from './../../services/cached-trading-accounts.service';
import { AxosInvestAccount } from './typings/AxosInvestAccount';
import { CategoryName } from './typings/CategoryName.enum';
import { OverviewAccount } from './typings/OverviewAccount';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryAccount } from '@core/models';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { InitializePdpInputType } from '@app/Areas/AAS/features/product-details-page/facade/types';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';

@Inject(
  '$scope',
  '$rootScope',
  '$element',
  '$state',
  'env',
  'tilesService',
  'serviceHelper',
  'popups',
  'cachedTradingAccountsService',
  'cachedAccountsService',
  'featureFlagService',
  'balanceService',
  'axosInvestHelper',
  'ngrxStore',
  'pdpFacade',
  'supportViewFacade'
)
export class AccountOverviewController extends BaseTile<AccountOverviewTileSettings> {
  modalConfig: UIKitModalConfiguration;
  title = 'Account Overview';
  quickActionText = 'See all accounts';
  enrollmentUrl: string;
  showModal = false;
  settings: string[] = [];
  accounts: OverviewAccount[] = [];
  accountsToDisplay: OverviewAccount[] = [];
  hasOnlyOneAccount = false;
  hasOpenAccount = true;
  isBusinessAccount = false;
  allowAccountAggregationEnhance;
  investAccount: InvestAccount;
  axosAdvisoryAccounts: AxosAdvisoryAccount[] = [];
  NO_LONGER_TEEN = 18;
  isTeen = false;
  isAasVisibleOpenAccount = false;

  private listeners: Function[] = [];
  private keyBusinessAccount = 'BusinessOpenAccount';

  constructor(
    scope: ng.IScope,
    private readonly rootScope: ng.IRootScopeService,
    elm: ng.IRootElementService,
    private state: ng.ui.IStateService,
    private env: any,
    tilesService: TilesService,
    serviceHelper: IServiceHelper,
    private readonly popups: IPopups,
    private readonly cachedTradingAccountsService: CachedTradingAccountsService,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly balanceService: BalanceService,
    private axosInvestHelper: AxosInvestHelper,
    private store: Store,
    private readonly pdpFacade: PdpFacade,
    private readonly supportViewFacade: SupportViewFacade
  ) {
    super(scope, elm, tilesService, serviceHelper);
  }

  /** Initializes the controller. */
  $onInit(): void {
    if (this.featureFlagService.isSBBActive()) {
      this.enrollmentUrl = this.rootScope['brandProperties'][this.keyBusinessAccount];
    } else {
      if (this.isBusinessAccount) {
        this.hasOpenAccount = false;
      }

      this.enrollmentUrl = this.env.depositsUrl;
    }

    this.allowAccountAggregationEnhance = this.featureFlagService.isAccountAggregationEnhancementsActive();

    const settingsLoaded = new Promise(resolve => this.getSettings(resolve));
    const internalAccountsLoaded = new Promise(resolve => {
      if (this.rootScope['accounts']) resolve(this.rootScope['accounts']);
      else this.listeners.push(this.scope.$on('accountsLiteLoaded', resolve));
    });
    const balancesLoaded = new Promise(resolve => {
      if (this.rootScope['balancesAvailable']) resolve(this.rootScope['balancesAvailable']);
      else this.listeners.push(this.scope.$on('balancesAvailable', resolve));
    });
    const aggAccountsLoaded = new Promise(resolve => {
      if (this.cachedAccountsService.aggregatedAccounts || !this.allowAccountAggregationEnhance) {
        resolve(this.cachedAccountsService?.aggregatedAccounts);
      } else this.listeners.push(this.scope.$on('aggregatedaccountsloaded', resolve));
    });
    const investAccountsLoaded = new Promise(resolve => {
      if (this.rootScope['isInvestAccountsLoaded']) resolve(this.rootScope['isInvestAccountsLoaded']);
      else this.listeners.push(this.scope.$on('investAccountsLoaded', resolve));
    });
    const axosInvestLoaded = new Promise<void>(resolve => {
      if (
        !this.featureFlagService.isAxosInvestActive() ||
        !this.axosInvestHelper.hasAxosInvest ||
        !this.allowAccountAggregationEnhance
      ) {
        resolve();
      } else {
        const notifier = new Subject();
        this.store
          .select(getInvestAccount)
          .pipe(takeUntil(notifier))
          .subscribe(investAccount => {
            if (!investAccount.isLoaded) return;

            this.investAccount = investAccount;
            notifier.next();
            notifier.complete();
            resolve();
          });
      }
    });

    const axosAdvisoryAccounts = new Promise<void>(resolve => {
      if (!this.featureFlagService.isRiaPilotActive()) {
        resolve();
      } else {
        const notifier = new Subject();
        this.store
          .select(getAxosAdvisoryAccounts)
          .pipe(takeUntil(notifier))
          .subscribe((riaAccounts: AxosAdvisoryAccount[]) => {
            if (!riaAccounts || riaAccounts?.length == 0) resolve();

            this.axosAdvisoryAccounts = riaAccounts;
            notifier.next();
            notifier.complete();
            resolve();
          });
      }
    });

    // initialize the tile after all dependencies are met
    Promise.all([
      settingsLoaded,
      internalAccountsLoaded,
      balancesLoaded,
      aggAccountsLoaded,
      investAccountsLoaded,
      axosInvestLoaded,
      axosAdvisoryAccounts,
    ]).then(() => this.setupTile());

    this.listeners.push(
      // Refresh the tile after a quick transfer
      this.rootScope.$on('transferCompleted', _ => this.setupTile())
    );

    this.isTeen = moment().diff(this.rootScope['profileInfo'].dateOfBirth, 'year') < this.NO_LONGER_TEEN;
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /**
   * Redirects the user to the account details page or the list of accounts.
   * @param toAccount account for redirection.
   */
  redirectToAccount(toAccount?: OverviewAccount): void {
    if (!toAccount && this.hasOnlyOneAccount) toAccount = this.accounts[0];
    if (!toAccount) {
      this.state.go('udb.accounts.dashboard');

      return;
    }

    const account = this.accounts.find(a => a.tileAccountId === toAccount.tileAccountId);
    if (!account) {
      this.popups.showAlert('Forbidden', 'The resource you are trying to access is not available.', 'error');

      return;
    }

    if (account.accountType === 'trading') {
      // clearing
      this.state.go('udb.accounts.container', { id: toAccount.id, type: 'Trading' });
    } else if (account.isExternal) {
      // aggregated
      this.state.go('udb.accounts.external-details', {
        id: toAccount.id,
        container: toAccount.container,
      });
    } else if (account.accountType === 'invest') {
      // axos invest
      this.state.go('udb.accounts.container', { type: 'Invest' });
    } else if (account.tileAccountId === 'AasAccount' || account.accountType === 'AAS') {
      //RIA accounts
      const initializePdpInputType: InitializePdpInputType = {
        axosAdvisoryAccount: {
          accountBalance: account.availableBalance,
          accountNumber: account.accountNumber,
          accountType: account.accountType,
          accountTypeCode: account.accountTypeCode,
          advisorName: account.advisorName,
          brandingName: account.brandingName,
          displayName: account.accountDisplayName,
          isRetirement: account.isRetirement,
          riaId: account.id.toString(),
          status: account.status,
          firstDepositDate: account.firstDepositDate,
          accountDisplayName: account.accountDisplayName,
          accountNickname: account.nickname,
          type: account.axosType,
          routingNumber: '',
          bankName: '',
          productType: '',
          availableCash: account.availableCash,
          clientPortalStatus: account.clientPortalStatus,
          dateTerminated: account.dateTerminated,
          dateCloseInitiated: account.dateCloseInitiated,
        },
      };
      this.pdpFacade.initializePdp(initializePdpInputType);
      this.state
        .go('udb.productDetailsPage', {
          type: 'RIA',
          id: toAccount.id,
          accountNumber: toAccount.accountNumber,
        })
        .then(() => {
          scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
    } else {
      // internal
      this.state.go('udb.accounts.details', { id: toAccount.id, tab: 1 });
    }
  }

  /** Saves the settings of the tile. */
  saveTileSettings(): void {
    if (this.tilesService.validateDuplicateAccounts(this.settings)) {
      this.modalConfig = {
        title: 'Error',
        message: "The same account can't be selected more than once.",
        status: 'error',
        okAction: () => {
          this.resetSettings();
          this.showModal = false;
        },
      };
      this.showModal = true;

      return;
    }

    this.settings.forEach((tileAccountId, settingId) => {
      this.accountsToDisplay[settingId] = this.accounts.find(a => a.tileAccountId === tileAccountId);
    });
    this.tileSettings.ShownAccounts.value = this.settings.filter(acc => acc).join(',');
    this.saveSettings();
  }

  /** Resets the settings to its normal state. */
  resetSettings(): void {
    this.settings = angular.copy(this.accountsToDisplay.map(a => a?.tileAccountId));
  }

  /**
   * Sets the tile up with its settings.
   */
  private setupTile(): void {
    let saveSettings = false;
    this.loadAccountsInformation();
    if (!this.tileSettings.ShownAccounts.value) {
      // User hasn't explicitly selected his preferred accounts, so just take the first 3 (order doesn't matter)
      this.accountsToDisplay[0] = this.accounts[0];
      this.accountsToDisplay[1] = this.accounts[1];
      this.accountsToDisplay[2] = this.accounts[2];
    } else {
      this.tileSettings.ShownAccounts.value.split(',').forEach((tileAccountId: string, i) => {
        if (!tileAccountId) return;
        this.accountsToDisplay[i] = this.accounts.find(a => a.tileAccountId === tileAccountId);
      });

      const shownAccs = new Set(this.accountsToDisplay.filter(a => !!a).map(a => a.tileAccountId));
      for (let i = 0; i < this.maxAccounts; i++) {
        if (this.accountsToDisplay[i]) continue;

        // If the account id is invalid, fill it with the next available account
        //   (that isn't already added) (order doesn't matter)
        this.accountsToDisplay[i] = this.accounts.find(a => !shownAccs.has(a.tileAccountId));
        shownAccs.add(this.accountsToDisplay[i]?.tileAccountId);
        saveSettings = true;
      }
    }

    this.resetSettings();
    if (saveSettings) this.saveTileSettings();

    this.hasOnlyOneAccount = this.accounts.length === 1;
    if (this.hasOnlyOneAccount) this.quickActionText = 'See account';

    this.isInitialising = false;
  }

  private loadAccountsInformation(): void {
    const internalDeposits = this.cachedAccountsService.internalAccounts?.depositAccounts || [];
    const internalLoans = this.cachedAccountsService.internalAccounts?.loanAccounts || [];
    const tradingsClearing =
      this.cachedTradingAccountsService.tradingAccounts.filter(acc => acc.statusName?.toLowerCase() === 'active') || [];
    const axosInvests: InvestAccount[] = this.allowAccountAggregationEnhance ? [this.investAccount] : [];
    const axosAdvisories: AxosAdvisoryAccount[] = this.featureFlagService.isRiaPilotActive()
      ? this.axosAdvisoryAccounts == null
        ? []
        : this.axosAdvisoryAccounts
      : [];
    let aggregated = this.allowAccountAggregationEnhance ? this.cachedAccountsService.aggregatedAccounts : [];
    aggregated = aggregated instanceof Error ? [] : aggregated || [];

    // merge and map all accounts
    const accounts = [
      ...internalDeposits.map(acc => this.fromInternalToOverviewAccount(acc as Required<OlbAccount>)),
      ...internalLoans.map(acc => this.fromInternalToOverviewAccount(acc as Required<OlbAccount>)),
      ...aggregated.map(acc => this.fromAggToOverviewAccount(acc as Required<AggregatedAccount>)),
      ...tradingsClearing
        .filter(trading => +trading.status !== ClearingAccountStatus.Closed)
        .map(acc => this.fromClearingToOverviewAccount(acc as Required<TradingAccount>)),
      ...axosInvests.filter(inv => inv && !inv.isClosed).map(acc => this.fromAxosInvestToOverviewAccount(acc)),
      ...axosAdvisories.filter(acc => acc).map(acc => this.fromAxosAdvisoryToOverviewAccount(acc)),
    ];

    // sort by categories
    this.accounts = [
      ...accounts.filter(acc => acc.categoryName === CategoryName.Checking),
      ...accounts.filter(acc => acc.categoryName === CategoryName.Savings),
      ...accounts.filter(acc => acc.categoryName === CategoryName.Investment),
      ...accounts.filter(acc => acc.categoryName === CategoryName.CreditCards),
      ...accounts.filter(acc => acc.categoryName === CategoryName.Loans),
    ];

    this.checkIfIsRiaUser();
  }

  private fromAxosInvestToOverviewAccount(account: AxosInvestAccount): OverviewAccount {
    return {
      ...account,
      tileAccountId: account.nickname,
      categoryName: CategoryName.Investment,
      isExternal: false,

      id: null,
    };
  }

  private fromAxosAdvisoryToOverviewAccount(account: AxosAdvisoryAccount): OverviewAccount {
    return {
      ...account,
      name: account.displayName,
      nickname: account.accountNickname,
      availableBalance: account.accountBalance,
      accountType: account.accountType,
      tileAccountId: account.accountNickname,
      categoryName: CategoryName.Investment,
      isExternal: false,
      id: parseInt(account.riaId),
      axosType: account.type,
    };
  }

  private fromClearingToOverviewAccount(account: Required<TradingAccount>): OverviewAccount {
    return {
      ...account,
      tileAccountId: account.id.toString(),
      nickname: `${account.nickname} *${account.accountNumber.substr(account.accountNumber.length - 4)}`,
      availableBalance: account.totalValue,
      categoryName: CategoryName.Investment,
      isExternal: false,

      name: null,
    };
  }

  private fromInternalToOverviewAccount(account: Required<OlbAccount>): OverviewAccount {
    return {
      ...account,
      nickname: account.nickname || account.name,
      tileAccountId: account.id.toString(),
      availableBalance: this.balanceService.getBalance(account),
      categoryName: this.getCategoryName(account.category, null),
    };
  }

  private fromAggToOverviewAccount(account: Required<AggregatedAccount>): OverviewAccount {
    return {
      ...account,
      tileAccountId: `aggregated:${account.id}`, // to prevent key conflicts
      nickname: account.nickname || account.name || account.bankName,
      availableBalance: this.balanceService.getBalance(account),
      categoryName: this.getCategoryName(account.category, account.container),
    };
  }

  private getCategoryName(category: number, container: string): CategoryName {
    if (category === AccountCategory.Dda) return CategoryName.Checking;
    if (category === AccountCategory.Loan) return CategoryName.Loans;
    if (category === AccountCategory.Credit) return CategoryName.CreditCards;
    if (container === containerType.Investment) return CategoryName.Investment;

    if (category === AccountCategory.Sav || category === AccountCategory.Cd) {
      return CategoryName.Savings;
    }

    return CategoryName.Default;
  }

  private checkIfIsRiaUser() {
    this.supportViewFacade.isRIAUser$.subscribe(isRiaUser => {
      this.isAasVisibleOpenAccount = isRiaUser && this.accounts.length < 3;
    });
  }
}

interface AccountOverviewTileSettings extends BaseTileSettings {
  ShownAccounts?: TileSetting;
}
