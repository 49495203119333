import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { DropdownItem } from '@uikit/clickdropdown';
import { AccountCategory } from 'accounts/account-category.enum';
import { IRootScopeService, IScope, IWindowService } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { AccountType, ClearingAccountStatus } from 'common/enums/enums';
import { Inject } from 'decorators/decorators';
import { BeneficiariesHelper } from 'services/beneficiaries.helper';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { CachedTradingAccountsService } from 'services/cached-trading-accounts.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { ModalService } from 'services/modal.service';
import { IAccountsService } from 'services/typings/IAccountsService';
import { IAxosClearingService } from 'services/typings/IAxosClearingService';

import { getProviders } from '@app/accounts/store/providers/providers.selectors';
import { getInvestAccount } from '@app/axos-invest/store/selectors';
import { OlbEvents } from '@core/enums';
import { OlbEventService, ProviderService } from '@core/services';
import { AccountNickname } from '@legacy/models';
import { RewardsCheckingHelper } from '@legacy/services/rewards-checking.helper';

import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { AggregatedAccount } from '../../typings/app/account-aggregation';
import { AccountRouterHelper } from '../account-router-helper';
import {
  RecategorizeEventData,
  RecategorizeEventName,
} from '../transactions/categorization/recategorize/typings/recategorizeEventData';
import { Beneficiary } from '@shared/models';
import { AccountDetails } from '../typings/AccountDetails';

@Inject(
  '$rootScope',
  '$scope',
  '$window',
  '$state',
  '$stateParams',
  'accountsService',
  'serviceHelper',
  'harlandClarkeService',
  'modalService',
  'cachedAccountsService',
  'featureFlagService',
  'axosInvestHelper',
  'env',
  'accountRouterHelper',
  'beneficiariesHelper',
  'cachedTradingAccountsService',
  'axosClearingService',
  'olbEventService',
  'ngrxStore',
  ProviderService.$token,
  'rewardsCheckingHelper'
)
export class AccountDetailsIndexController {
  isLoading = false;
  account: OlbAccount;
  apy: number;
  accountId: number;
  accountType: string;
  indexTabActive: number;
  dropdownItems: DropdownItem[] = [];
  selectedItem: DropdownItem;
  isLoadingHC = false;
  isODS = false;
  canShowBeneficiaries = false;
  canShowMinors = false;
  showWantToSection: boolean;
  isOrderCheckClicked = false;
  detailsRetrieved = false;
  collectedBalance: number;
  availableBalance: number;
  amountDue: number;
  outstandingBalance: number;
  currentBalance: number;
  displayBalanceMsg: string;
  activeStopPayment: boolean;
  brand: string;
  showDebitCardsTab = false;
  cards: DebitCard[] = [];
  beneficiaries?: Beneficiary[] = [];
  teens: string[];
  aggregatedAccnts: AggregatedAccount[] = [];
  messageOperation: string = null;
  statusOperation = false;
  providers: Provider[] = [];
  activeIraEnhBase = false;
  activeIraEnhDira = false;
  hasPendingConsent = false;
  activeDirectDeposit = false;
  showAmountDue = false;
  showOutstandingBalance = false;
  amountDueText = 'Outstanding Balance';
  isRewardsActive = false;
  isRewardsProduct = false;
  interestMaximumRate = 0;
  isFunded: boolean;
  accountDetails: AccountDetails;

  private subsink = new SubSink();
  private investAccounts: OlbAccount[] = [];
  private listeners: Function[] = [];
  private activeAxosInvest: boolean;
  get isEditAddressEnabled(): boolean {
    return this.featureFlagService.isAddressModificationEnabled();
  }
  get canOrderChecks(): boolean {
    return this.account.canOrderChecks;
  }

  private readonly viewAllAccountText = 'View All Accounts';

  constructor(
    private rootScope: IRootScopeService,
    private scope: IScope,
    private window: IWindowService,
    private state: IStateService,
    private params: IStateParamsService,
    private accountService: IAccountsService,
    private serviceHelper: IServiceHelper,
    private harlandClarkeService: IHarlandClarkeService,
    private modalService: ModalService,
    private cachedAccountsService: CachedAccountsService,
    private featureFlagService: FeatureFlagService,
    private axosInvestHelper: AxosInvestHelper,
    private env: OlbSettings,
    private accountRouterHelper: AccountRouterHelper,
    private beneficiariesHelper: BeneficiariesHelper,
    private cachedTradingAccountsService: CachedTradingAccountsService,
    private axosClearingService: IAxosClearingService,
    private olbEventService: OlbEventService,
    private store: Store,
    private providersService: ProviderService,
    private rewardsCheckingHelper: RewardsCheckingHelper
  ) {}
  /** Initializes required data once the module is fully loaded. */
  $onInit(): void {
    this.brand = String(this.env.brand);
    this.serviceHelper.scrollToTop();
    this.activeStopPayment = this.featureFlagService.isStopPaymentActive();
    this.activeAxosInvest = this.featureFlagService.isAxosInvestActive();
    this.activeIraEnhBase = this.featureFlagService.isIraEnhBaseFlagActive();
    this.activeIraEnhDira = this.featureFlagService.isIraEnhDiraFlagActive();
    this.accountId = this.params['id'];
    this.detailsRetrieved = false;

    if (this.rootScope['providers']) {
      this.providers = this.rootScope['providers'];
    } else {
      this.getProviders();
    }
    Promise.all([
      this.onInternalAccountsLoaded(),
      this.onAggregatedAccountsLoaded(),
    ]).then(this.initAccountsDropdown.bind(this));
    this.scope.$on(
      'investAccountsLoaded',
      this.handleInvestAccounts.bind(this)
    );
    this.scope.$on(
      RecategorizeEventName,
      this.showRecategorizeToast.bind(this)
    );

    this.scope.$on(
      'updatePendingConsent',
      this.updatePendingConsent.bind(this)
    );

    this.scope.$on(
      'nicknameUpdated',
      (_e: ng.IAngularEvent, accountNickname: AccountNickname) => {
        this.dropdownItems.find(e => e.id === accountNickname.id).text =
          accountNickname.nickname;
      }
    );

    this.subsink.sink = this.olbEventService.on(
      OlbEvents.NickName,
      this.updateNickname.bind(this)
    );

    this.loadAxosInvestAccounts();

    this.isLoading = true;
    this.indexTabActive = +this.params['tab'];
    this.accountService
      .getAccount(this.accountId.toString())
      .then(this.transformAccountName.bind(this))
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
      });

    this.accountService
      .getAccountDetails(this.accountId)
      .then(res => {
        this.accountDetails = res.data;
        this.onDetailsRetrievedMigrated(res.data);
      })
      .catch(this.serviceHelper?.errorHandler?.bind(this.serviceHelper));

    this.scope.$on(
      'nextPaymentTabSelected',
      (_e: ng.IAngularEvent, index: number) => {
        this.indexTabActive = index;
      }
    );

    this.scope.$on('detailsRetrieved', this.onDetailsRetrieved.bind(this));
    this.subsink.sink = this.olbEventService.on(
      OlbEvents.AccountDetailsRetrieved,
      this.onDetailsRetrieved.bind(this, null)
    );

    this.scope.$watch('vm.indexTabActive', (_oldVal: any, _newVal: any) => {
      if (_oldVal === 3) {
        this.scope.$broadcast('tabSelected', this.account);
      } else if (_oldVal === 2) {
        this.scope.$broadcast('statementSelected', this.account);
      } else if (_oldVal === 4) {
        this.scope.$broadcast('debitcardSelected', this.accountId);
        this.olbEventService.emit(OlbEvents.DebitCardSelected, this.accountId);
      }
    });

    if (this.rootScope['isInvestAccountsLoaded']) {
      this.handleInvestAccounts();
    }
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
    this.subsink.unsubscribe();
  }

  updateNickname(accountNickname: AccountNickname): void {
    this.dropdownItems.find(e => e.id === accountNickname.id).text =
      accountNickname.nickname;

    this.cachedAccountsService.allAccounts.internalAccounts.find(
      i => i.id === accountNickname.id
    ).nickname = accountNickname.nickname;
  }

  onSelect(data: DropdownItem): void {
    let accountType;
    if (
      this.cachedAccountsService.aggregatedAccounts &&
      this.cachedAccountsService.aggregatedAccounts.some(
        a => a.id == data.id && a.isExternal
      )
    ) {
      const aggrAccnts = this.cachedAccountsService.aggregatedAccounts.find(
        a => a.id == data.id && a.isExternal
      );
      this.state.go('udb.accounts.external-details', {
        id: data.id,
        container: aggrAccnts.container,
      });
    } else if (data.id === -1 && data.text === this.viewAllAccountText) {
      this.state.go('udb.accounts.dashboard');
    } else {
      accountType = this.getInvestAccountSelectedType(data);

      this.accountRouterHelper.goToAccountDetailPage(
        data.id,
        accountType,
        this.indexTabActive
      );
    }
  }

  showLedger(): boolean {
    return (
      this.account &&
      (this.account.category === AccountCategory.Dda ||
        this.account.category === AccountCategory.Sav) &&
      this.detailsRetrieved
    );
  }

  orderCheck(accountId: string): void {
    if (!this.isOrderCheckClicked) {
      this.isOrderCheckClicked = true;
      this.isLoadingHC = true;
      this.harlandClarkeService
        .encryptAndRedirect(accountId)
        .then(res => {
          this.modalService
            .show(
              {},
              {
                bodyText: `<h3>You're continuing to another website</h3>
                <p>You're heading to the check ordering site operated by Harland Clarke,
                ${this.env.facingBrandId == 1 ? 'an' : 'a'} ${
                  this.env.brandName
                } partner that offers checks and check-related products and services.</p><br>
                <p>Because you're leaving the ${
                  this.env.brandName
                } website, we'd like you to know that the Harland
                Clarke site may have a different privacy policy and level of security.
                Please refer to Harland Clarke's policies for the privacy and security practices for this site</p>`,
                okText: 'Continue',
                cancelText: 'Cancel',
              }
            )
            .then(() => {
              this.harlandClarkeService.OrderChecks(true);
              this.window.open(res.data, '_blank');
            })
            .catch(() => {
              this.harlandClarkeService.OrderChecks(false);

              return;
            });
        })
        .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
        .finally(() => {
          this.isLoadingHC = false;
          this.isOrderCheckClicked = false;
        });
    }
  }

  stopPayments(accountId: number): void {
    this.state.go('udb.accounts.stop-payment', { id: accountId });
  }

  collapseDisputePanels(): void {
    this.rootScope.$emit('collapseDisputePanels');
  }

  /**
   * Get provider info to map with logo
   */
  getProviders() {
    this.subsink.sink = this.store
      .select(getProviders)
      .pipe(
        switchMap(data => {
          if (data) {
            return of(data);
          }

          return this.providersService.getProviders();
        })
      )
      .subscribe(providers => {
        this.providers = providers;
        this.initAccountsDropdown();
      });
  }

  goToUpdateAddress(): void {
    const view = 'ContactInfo';
    this.state.go('udb.userProfile', { view });
  }

  private loadAxosInvestAccounts() {
    if (this.activeAxosInvest && this.axosInvestHelper.hasAxosInvest) {
      const notifier = new Subject();

      this.store
        .select(getInvestAccount)
        .pipe(
          takeUntil(notifier),
          filter(account => account.isLoaded)
        )
        .subscribe(account => {
          if (!account.isClosed) {
            this.investAccounts.push(account as any);
            this.initAccountsDropdown();
          }

          notifier.next();
          notifier.complete();
        });
    }
  }

  private compareNames(bankName: string, providerName: string) {
    return (
      providerName.localeCompare(bankName, 'en', { sensitivity: 'accent' }) ===
      0
    );
  }

  /**
   * Transforms the account name to have the last four digits and perform a call to get transactions.
   * @param res The response from the server containing the account information.
   */
  private transformAccountName(res: OlbResponse<OlbAccount>): void {
    this.accountType = res.data.productType;
    this.account = res.data;
    this.account.nickname = res.data.nickname || res.data.name;
    this.scope.$broadcast('accountSelected', this.account);
    this.canShowBeneficiaries =
      this.featureFlagService.isBeneficiariesActive() &&
      this.beneficiariesHelper.isAddBeneficiariesActive(this.account);
    this.canShowMinors = this.account.hasMinor
      ? false
      : this.featureFlagService.isMinorsActive() &&
        this.account.displayAddMinor;
    this.showWantToSection = this.iWantToMenuHasAtLeastOneOption();
    this.activeDirectDeposit =
      this.featureFlagService.isDirectDepositEnabled() &&
      this.account.canMakeDepositSwitch;
    this.isRewardsActive = this.featureFlagService.isRewardsActive();
    this.isRewardsProduct = this.rewardsCheckingHelper.isRewardsCheckingAccount(
      this.rootScope,
      this.account.productCode
    );
    this.interestMaximumRate = this.rewardsCheckingHelper.getMaximumInterestRate(
      this.rootScope
    );

    if (this.account.availableBalance > 0) {
      this.isFunded = true;
    } else {
      this.isFunded = false;
    }
  }

  private iWantToMenuHasAtLeastOneOption(): boolean {
    return (
      // Account not closed
      this.account.status !== 'Closed' &&
      // Account is not loan
      !this.account.isLoan &&
      // Order checks option
      (this.account.canOrderChecks ||
        // Stop payment option
        (this.activeStopPayment && this.account.canPlaceStops !== 0) ||
        // Update my address option
        this.isEditAddressEnabled ||
        this.canShowBeneficiaries ||
        this.canShowMinors)
    );
  }

  /** Notifies when internal accounts are loaded  */
  private onInternalAccountsLoaded() {
    return new Promise<void>(resolve => {
      if (this.cachedAccountsService.internalAccounts) {
        resolve();
      } else {
        this.listeners.push(
          this.scope.$on('balancesAvailable', () => {
            resolve();
          })
        );
      }
    });
  }

  private onAggregatedAccountsLoaded() {
    return new Promise<void>(resolve => {
      if (this.cachedAccountsService.aggregatedAccounts) {
        resolve();
      } else {
        this.listeners.push(
          this.rootScope.$on('aggregatedaccountsloaded', () => {
            resolve();
          })
        );
      }
    });
  }

  /** Populates the accounts' dropdown  */
  private initAccountsDropdown() {
    let depositAccounts: OlbAccount[] = [];
    let loanAccounts: OlbAccount[] = [];
    const aggrAccnts = this.cachedAccountsService.getAggregatedAccntsToDdl();

    if (this.rootScope['balancesAvailable']) {
      depositAccounts =
        this.rootScope['accounts'].depositAccounts != undefined
          ? this.rootScope['accounts'].depositAccounts
          : [];
      loanAccounts =
        this.rootScope['accounts'].loanAccounts != undefined
          ? this.rootScope['accounts'].loanAccounts
          : [];
    }

    const accountsInDropDown = depositAccounts
      .concat(loanAccounts, this.investAccounts, aggrAccnts)
      .sort((a1: OlbAccount, a2: OlbAccount) => {
        if (a1.category < a2.category) return -1;
        else if (a1.category > a2.category) return 1;
        else return 0;
      });

    this.dropdownItems = accountsInDropDown.map((a: OlbAccount) => {
      const bank = this.providers.find(provider =>
        this.compareNames(a.bankName, provider.providerName)
      );
      const externalIcon = !!bank ? bank.logoName : 'default.svg';
      const icon = a.isExternal
        ? externalIcon
        : this.getDropdownItemImage(a.accountType);
      const accountName = !!a.isExternal
        ? a.nickname
          ? a.nickname
          : a.name
          ? a.name
          : a.bankName
        : a.nickname;

      return new DropdownItem(
        accountName,
        a.id,
        a.isExternal
          ? `${a.bankName} ${this.maskAccountNumber(a.accountNumber)}`
          : a.accountType.toLocaleLowerCase() === 'invest'
          ? `${AxosInvestHelper.AXOS_INVEST} | ${a.bankName}`
          : a.bankName ||
            `${this.env.brandName} Bank ${this.maskAccountNumber(
              a.accountNumber
            )}`,
        false,
        `rounded-logos/${icon}`
      );
    });
    this.dropdownItems.push(this.getViewAllAcountItem());

    this.selectedItem = this.dropdownItems.filter((a: DropdownItem) => {
      return a.id == this.accountId;
    })[0];
  }

  /** Populates the accounts' details  */
  private onDetailsRetrieved(
    _e: ng.IAngularEvent,
    accountDetails: AccountDetails
  ) {
    this.collectedBalance = accountDetails.collectedBalance;
    this.availableBalance = accountDetails.availableBalance;
    this.currentBalance = accountDetails.currentBalance;
    this.beneficiaries = accountDetails.beneficiaries;
    this.hasPendingConsent = accountDetails.pendingSpousalConsent;
    this.teens = accountDetails?.teens;
    this.processDebitCards();
    switch (this.account.category) {
      case 3: // Loan
        if (accountDetails.accountPayment.outstandingBalance != null) {
          this.outstandingBalance =
            accountDetails.accountPayment.outstandingBalance;
          this.showOutstandingBalance = true;
          this.displayBalanceMsg = 'Outstanding Balance';
        }
        if (accountDetails.accountPayment.amount != null) {
          this.amountDue =
            this.outstandingBalance > 0
              ? accountDetails.accountPayment.amount
              : 0;
          this.showAmountDue = true;
          this.amountDueText = 'Amount Due';
        }
        break;
      case 4: // TimeDeposit
        this.account.displayBalance = accountDetails.currentBalance;
        this.displayBalanceMsg = 'Current Balance';
        break;
      default:
        this.account.displayBalance = accountDetails.availableBalance;
        this.displayBalanceMsg = 'Available Balance';
        break;
    }
    this.apy = accountDetails.accountInterest.apy;
    this.detailsRetrieved = true;
  }

  private onDetailsRetrievedMigrated(accountDetails) {
    this.collectedBalance = accountDetails?.collectedBalance ?? 0;
    this.availableBalance = accountDetails?.availableBalance ?? 0;
    this.currentBalance = accountDetails?.currentBalance ?? 0;
    this.beneficiaries = accountDetails?.beneficiaries;
    this.hasPendingConsent = accountDetails?.pendingSpousalConsent;
    this.teens = accountDetails?.teens;
    this.processDebitCards();
    switch (this.account?.category) {
      case 3: // Loan
        if (accountDetails?.accountPayment?.outstandingBalance != null) {
          this.outstandingBalance =
            accountDetails?.accountPayment?.outstandingBalance;
          this.showOutstandingBalance = true;
          this.displayBalanceMsg = 'Outstanding Balance';
        }
        if (accountDetails?.accountPayment?.amount != null) {
          this.amountDue =
            this.outstandingBalance > 0
              ? accountDetails?.accountPayment?.amount
              : 0;
          this.showAmountDue = true;
          this.amountDueText = 'Amount Due';
        }
        break;
      case 4: // TimeDeposit
        this.account.displayBalance = accountDetails?.currentBalance ?? 0;
        this.displayBalanceMsg = 'Current Balance';
        break;
      default:
        this.account.displayBalance = accountDetails?.availableBalance ?? 0;
        this.displayBalanceMsg = 'Available Balance';
        break;
    }
    this.apy = accountDetails?.accountInterest?.apy;
    this.detailsRetrieved = true;
  }

  private processDebitCards() {
    this.getLinkedCards();
    if (!this.cards) {
      this.listeners.push(
        this.scope.$on('linkedDebitCardsLoaded', () => {
          this.getLinkedCards();
        })
      );
    }
  }

  private getLinkedCards() {
    this.cards = this.cachedAccountsService.linkedDebitCards(
      this.accountId,
      this.teens
    ) as DebitCard[];
    if (this.cards) {
      // confirm if is not needed the filter that we have on the details or another rules to call the service
      this.showDebitCardsTab =
        this.cards !== null ? this.cards.length > 0 : false;
      this.redirectToDefaultTab();
    }
  }

  private redirectToDefaultTab() {
    const defaultTab = +this.params['tab'];
    if (defaultTab != 4 || (defaultTab == 4 && this.showDebitCardsTab)) return;
    this.indexTabActive =
      this.account &&
      this.account.accountType.toLowerCase().indexOf('loan') !== -1
        ? 1
        : 0;
  }

  private maskAccountNumber(accountNumber: string): string {
    if (!accountNumber || accountNumber.length < 4) return '';

    return `*${accountNumber.substring(accountNumber.length - 4)}`;
  }

  private getViewAllAcountItem(): DropdownItem {
    return {
      id: -1,
      text: this.viewAllAccountText,
    };
  }

  private handleInvestAccounts(): void {
    if (this.axosClearingService.isAxosTradingActiveForUser()) {
      this.handleAxosTradingAccounts();
    }

    this.initAccountsDropdown();
  }

  private handleAxosTradingAccounts(): void {
    if (this.cachedTradingAccountsService.isTradingAccountsLoaded) {
      this.investAccounts.push(
        ...this.cachedTradingAccountsService.tradingAccounts.filter(
          t => +t.status !== ClearingAccountStatus.Closed
        )
      );
    }
  }

  private getInvestAccountSelectedType(data: DropdownItem): AccountType {
    let accountType;

    if (data.text === AxosInvestHelper.MANAGED_PORTFOLIO) {
      accountType = AccountType.AxosInvest;

      return accountType;
    }

    if (this.investAccounts.some(a => a.id === data.id)) {
      accountType = AccountType.AxosTrading;
    }

    return accountType;
  }

  private getDropdownItemImage(accountType: string): string {
    switch (accountType) {
      case 'invest':
      case 'trading':
        return 'axos-invest_round_logo.svg';
      default:
        return this.env.brand + '_round_logo.svg';
    }
  }
  private showRecategorizeToast(
    event: ng.IAngularEvent,
    data: RecategorizeEventData
  ) {
    event.stopPropagation();
    const { message, status } = data;
    this.messageOperation = message;
    this.statusOperation = status;
    this.serviceHelper.scrollToTop();
  }

  private updatePendingConsent(_e: ng.IAngularEvent, pending: boolean) {
    this.hasPendingConsent = pending;
  }
}
