<div class="payment-details-box">
  <div class="box-title">
    <span>
      {{ title }}
    </span>
    <div class="box-edit" *ngIf="!busy && !payoff">
      <a class="box-edit-link link" (click)="edit()">
        <i class="bofi-edit"></i>
        <div class="box-edit-icon">Edit</div>
      </a>
    </div>
  </div>
  <ng-content class="box-content" select></ng-content>
</div>
