<div class="orders-tile">
  <div class="divider"></div>
  <app-dot-loader *ngIf="isLoading">Loading...</app-dot-loader>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="ordersTile && ordersTile.apiCallWasSuccessful; else errorState">
      <div class="orders-tile__list" *ngIf="ordersTile && ordersTile.orders.length > 0; else emptyState">
        <div
          class="orders-tile__item divider"
          *ngFor="let order of ordersTile.orders"
          (click)="navigateToAllTransactions(order)"
          [ngClass]="{ pointer: order.state != 'Pending' }"
        >
          <div class="orders-tile__item--icon">
            <mat-icon
              *ngIf="order.amount < 0 && order.postDate && order.state != ordersStatePending"
              class="orders-tile__icon"
              [svgIcon]="uk2OrderBuyIcon"
            ></mat-icon>
            <mat-icon
              *ngIf="order.amount > 0 && order.postDate && order.state != ordersStatePending"
              class="orders-tile__icon"
              [svgIcon]="uk2OrderSellIcon"
            ></mat-icon>
            <mat-icon
              *ngIf="order.state == ordersStatePending"
              class="orders-tile__icon"
              [svgIcon]="uk2OrderPendingIcon"
            ></mat-icon>
          </div>
          <div class="orders-tile__item--content">
            <p class="description" [ngClass]="{ pending: order.state == ordersStatePending }">
              {{ order.activityDescription }}
            </p>
            <span *ngIf="order.postDate && order.state != ordersStatePending" class="date">
              {{ order.postDate | date: 'MMMM d, YYYY':'+0000' }}
            </span>
            <span *ngIf="order.state == ordersStatePending" class="date pending">Pending</span>
          </div>
          <div class="orders-tile__item--amount" [ngClass]="{ pending: order.state == ordersStatePending }">
            <p>{{ order.amount | currency }}</p>
            <span class="shares">
              {{ order.quantity >= 0 ? order.quantity : -order.quantity }}
              {{ order.quantity === 1 || order.quantity === -1 ? 'share at' : 'shares at' }}
              {{ order.price | currency }}
            </span>
          </div>
          <div class="orders-tile__item--chevron-arrow">
            <mat-icon *ngIf="order.state != ordersStatePending" [svgIcon]="uk2OrderRightChevronIcon"></mat-icon>
          </div>
        </div>
        <div class="orders-tile__view-all">
          <button
            (click)="navigateToAllTransactions()"
            uk2TextButton
            disableRipple
            [uk2ButtonSize]="viewAllButtonSize"
            mat-button
          >
            View All Orders
          </button>
        </div>
      </div>
      <ng-template #emptyState>
        <div class="orders-tile__empty">
          <p>Once you have orders related to this account, you'll see them here</p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #errorState>
      <uk2-text-tile
        [uk2TextTileSvgIconName]="uk2OrderCogIcon"
        [uk2TextTileTitle]="labels.errorTitle"
        [uk2TextTileIsStandaloneTile]="false"
      >
        <p>
          {{ labels.errorContent }}
        </p>
      </uk2-text-tile>
    </ng-template>
  </ng-container>
</div>
