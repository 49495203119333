import { createReducer, on, Action } from '@ngrx/store';

import {
  addUpdateStatementsToAccountAction,
  addUpdateTaxFormsToAccountAction,
  setSelectedAccountDocumentsAction,
} from '../actions/statements-and-documents.actions';
import {
  AccountDocumentsTileStateType,
  StatementsAndDocumentsStateType,
  initialStatementsAndDocumentsState,
} from '../types';

export const reducer = createReducer(
  initialStatementsAndDocumentsState,
  on(addUpdateStatementsToAccountAction, (state, action) => {
    const indexOfUpdate = state.accountsDocuments.findIndex(account => account.accountNumber === action.accountNumber);
    let updatedAccountsDocuments: AccountDocumentsTileStateType[];

    if (indexOfUpdate >= 0) {
      const updatedAccount: AccountDocumentsTileStateType = {
        ...state.accountsDocuments[indexOfUpdate],
        statementsApiCallWasSuccesful: action.statementsApiCallWasSuccesful,
        statements: action.statements,
      };

      updatedAccountsDocuments = [...state.accountsDocuments];
      updatedAccountsDocuments[indexOfUpdate] = updatedAccount;
    } else {
      const newAccount: AccountDocumentsTileStateType = {
        accountNumber: action.accountNumber,
        statementsApiCallWasSuccesful: action.statementsApiCallWasSuccesful,
        statements: [...action.statements],
      };
      updatedAccountsDocuments = [...state.accountsDocuments, newAccount];
    }

    const updatedState: StatementsAndDocumentsStateType = {
      ...state,
      accountsDocuments: updatedAccountsDocuments,
      selectedAccountDocuments: action.accountNumber,
    };
    return updatedState;
  }),

  on(addUpdateTaxFormsToAccountAction, (state, action) => {
    const indexOfUpdate = state.accountsDocuments.findIndex(account => account.accountNumber === action.accountNumber);
    let updatedAccountsDocuments: AccountDocumentsTileStateType[];

    if (indexOfUpdate >= 0) {
      const updatedAccount: AccountDocumentsTileStateType = {
        ...state.accountsDocuments[indexOfUpdate],
        taxFormsApiCallWasSuccesful: action.taxFormsApiCallWasSuccesful,
        taxForms: action.taxForms,
      };

      updatedAccountsDocuments = [...state.accountsDocuments];
      updatedAccountsDocuments[indexOfUpdate] = updatedAccount;
    } else {
      const newAccount: AccountDocumentsTileStateType = {
        accountNumber: action.accountNumber,
        taxFormsApiCallWasSuccesful: action.taxFormsApiCallWasSuccesful,
        taxForms: [...action.taxForms],
      };
      updatedAccountsDocuments = [...state.accountsDocuments, newAccount];
    }

    const updatedState: StatementsAndDocumentsStateType = {
      ...state,
      accountsDocuments: updatedAccountsDocuments,
      selectedAccountDocuments: action.accountNumber,
    };
    return updatedState;
  }),

  on(setSelectedAccountDocumentsAction, (state, action) => {
    return {
      ...state,
      selectedAccountDocuments: action.accountNumber,
    };
  })
);

export function statementsAndDocumentsReducer(state: StatementsAndDocumentsStateType, action: Action) {
  return reducer(state, action);
}
