/**
 * Signature Card Status type Enum
 */
export enum SignatureCardStatusType {
  None,
  Pending,
  Received,
  Accepted,
  Rejected,
  Removed,
}
