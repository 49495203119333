<ng-container
  *ngIf="{
    isLoadingContactFirms: isLoadingFirms$ | async,
    contactFirms: contactFirms$ | async,
    isRiaUserFlag: riaUserFlag$ | async
  } as data"
>
  <app-dot-loader [showWhen]="data.isLoadingContactFirms">Loading...</app-dot-loader>
  <div *ngIf="data.isRiaUserFlag && !data.isLoadingContactFirms">
    <h2 class="text-center" id="contactHeader">{{ 'Contact Us' }}</h2>
    <br />
    <p class="text-center" [innerText]="riaText"></p>
    <br />
    <ng-container *ngFor="let firm of data.contactFirms">
      <div *ngIf="isFirmSectionVisible(firm)" style="width: 120%; margin-left: -5%;">
        <h3 class="contact-firm__title">{{ firm.commonName }}</h3>

        <div class="contact-firm-card">
          <ng-container *ngFor="let representative of firm.representatives">
            <div class="contact-firm-card__container" *ngIf="representative.managedAccounts.length > 0">
              <div
                [ngClass]="
                  representative.commonName
                    ? 'contact-firm-card__container__group'
                    : 'contact-firm-card__container__groupNoRep'
                "
              >
                <div style="width: 100%; height: 100%;">
                  <div class="contact-firm-card__container__group__name-text" *ngIf="representative.commonName">
                    {{ representative.commonName }}
                  </div>
                  <div class="contact-firm-card__container__group__firm-text d-flex align-items-center">
                    <div *ngIf="firm.logo; else noIcon" class="contact-firm-card__container__group__icon">
                      <div id="bannerLogo" [ngStyle]="{ background: firm.logo }"></div>
                    </div>
                    <ng-template #noIcon>
                      <div class="contact-firm-card__container__group__profile-icon">
                        <span style="width: inherit; align-self: center;">
                          {{ getInitials(firm.commonName) }}
                        </span>
                      </div>
                    </ng-template>
                    <span style="width: inherit; align-self: center;">
                      {{ firm.commonName }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                [ngClass]="
                  representative.commonName
                    ? 'contact-firm-card__container__groupContactInfo'
                    : 'contact-firm-card__container__groupContactInfoNoRep'
                "
                class="contact-firm-card__container__group"
                style="margin-left: 4px;"
              >
                <div
                  class="contact-firm-card__container__group__communication d-flex align-items-center"
                  *ngIf="representative.phone && representative.phone !== '0'"
                >
                  <app-svg-icon
                    [iconName]="icons.phone"
                    size="16px"
                    class="contact-firm-card__container__group__communication__icon"
                  ></app-svg-icon>
                  <a *ngIf="isMobileDevice" href="tel:{representative.phone}">
                    {{ representative.phone }}
                  </a>
                  <span *ngIf="!isMobileDevice">
                    {{ representative.phone }}
                  </span>
                </div>
                <div
                  class="contact-firm-card__container__group__communication d-flex align-items-center"
                  *ngIf="representative.email"
                >
                  <app-svg-icon
                    [iconName]="icons.mail"
                    size="16px"
                    class="contact-firm-card__container__group__communication__icon"
                  ></app-svg-icon>
                  <a href="mailto:{{ representative.email }}">Email {{ representative.commonName ? 'Me' : 'Us' }}</a>
                </div>
              </div>
              <div
                class="contact-firm-card__container__group responsive-container"
                style="min-height: 45%; padding-top: 12px;"
              >
                <div class="contact-firm-card__container__group__managed-accounts">
                  <div
                    class="contact-firm-card__container__group__managed-accounts-title"
                    style="width: 70%; line-height: 22px;"
                  >
                    {{ representative.managedAccounts.length }} Managed
                    {{ representative.managedAccounts.length > 1 ? 'Accounts' : 'Account' }}
                  </div>
                  <div
                    style="width: 30%; text-align: end; line-height: 22px;"
                    *ngIf="representative.managedAccounts.length > 3"
                  >
                    <app-popover
                      [content]="getAllAccountsList(representative.managedAccounts)"
                      innerText="View All"
                    ></app-popover>
                  </div>
                </div>
                <div
                  class="contact-firm-card__container__group__managed-accounts-list"
                  *ngFor="let managedAccount of sortedManagedAccounts(representative.managedAccounts) | slice: 0:3"
                >
                  {{ managedAccount }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
