<header class="investor-checking-header">
  <div class="investor-checking-header-image">
    <img src="/assets/img/logos/axos-w-logo.svg" alt="axos" />
  </div>
  <div class="investor-checking-header-title">
    <p>Open a Cash Management Checking Account</p>
  </div>
  <div class="investor-checking-header-close">
    <button (click)="togglePageModal()">
      <img src="\img\fileicons\Close-unexpand.svg" alt="close" />
    </button>
  </div>
</header>
<app-investor-checking-modal
  (displaySpeedBump)="togglePageModal()"
  (skipFunction)="skippingAccount()"
  (proceedFunction)="skippingAccount()"
  [brandName]="brandName"
  [displayUrl]="DisplayUrl"
  *ngIf="displaySpeedBump"
></app-investor-checking-modal>
