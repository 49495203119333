import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { turnOffFunding } from '@app/store/funding/funding.actions';
import { STATE, ngRedux } from '@core/tokens';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import NgRedux from 'ng-redux';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-mail-check',
  templateUrl: './mail-check.component.html',
  styleUrls: ['./mail-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailCheckComponent {
  accountNumber: string;
  displayName: string;
  imageSource: any;
  isTradingAccount = false;

  constructor(
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    @Inject(STATE) private state: ng.ui.IStateService,
    private store: Store
  ) {}

  ngOnInit() {
    const { funding } = this.ngRedux.getState();

    const account = funding.accountsToBeFunded.find((account: OlbAccount) => account.id === funding.accountId);

    this.accountNumber = account.accountNumber;
    if (account.accountType === 'trading') {
      this.isTradingAccount = true;
      this.displayName = account.nickname;
    }

    try {
      this.imageSource = '/img/icons/tiles/mail-check.svg';
    } catch {}
  }

  /** terminates funding flow and redirects */
  goToDashboard() {
    this.ngRedux.dispatch(FundingActions.turnOffFunding());
    this.store.dispatch(turnOffFunding());
    this.state.go('udb.dashboard');
  }

  goBack() {
    this.state.go('udb.funding');
  }
}
