<uk2-modal
  [title]="modalData.title"
  [disabledCloseOverlayBackdrop]="modalData.disabledCloseOverlayBackdrop"
  [showCloseButton]="modalData.showCloseButton"
  [showTitleDivider]="modalData.showTitleDivider"
  [showActionsDivider]="modalData.showActionsDivider"
  [actionsGrid]="modalData.actionsGrid"
  [innerPanelClass]="['uk2-custom-modal-white-label', 'modal-medium-size']"
  [titleFontWeight]="modalData.titleFontWeight"
  [titleAlign]="modalData.titleAlign"
  [bodyContentAlignment]="modalData.bodyContentAlignment"
  #modal
>
  <form [formGroup]="trustedInterestedModalForm">
    <ng-template #uk2ModalContent>
      <div *ngIf="!isLoading; else loading" class="form-container">
        <ng-container *ngIf="personType === modalPersonType.TrustedContact; else interestedForm">
          <app-trusted-contact-form-modal
            [parentFormModal]="trustedInterestedModalForm"
            [modalAction]="modalAction"
            [data]="data"
            (isFormDirty)="isFormDirty = $event"
          ></app-trusted-contact-form-modal>
        </ng-container>
        <ng-template #interestedForm>
          <app-interested-party-form-modal
            [parentFormModal]="trustedInterestedModalForm"
            [modalAction]="modalAction"
            [data]="data"
            (isFormDirty)="isFormDirty = $event"
          ></app-interested-party-form-modal>
        </ng-template>
      </div>
      <ng-template #loading>
        <app-dot-loader>Loading...</app-dot-loader>
      </ng-template>
    </ng-template>

    <ng-template #uk2ModalActions>
      <button
        *ngIf="modalAction === modalActionType.Edit"
        class="btn-remove"
        uk2TextButton
        uk2LabeledIconButton
        mat-button
        disableRipple
        [uk2ButtonSize]="uk2ButtonSize.small"
        [disabled]="isLoading"
        (click)="openRemoveModal()"
      >
        Remove
        <mat-icon [svgIcon]="svgIconName"></mat-icon>
      </button>
      <button
        uk2SecondaryButton
        mat-stroked-button
        disableRipple
        [uk2ButtonSize]="uk2ButtonSize.large"
        [disabled]="isLoading"
        (click)="closeModal()"
      >
        Cancel
      </button>
      <button
        type="submit"
        (click)="submit()"
        [disabled]="!trustedInterestedModalForm.valid || isLoading || !isFormDirty"
        uk2PrimaryButton
        mat-raised-button
        disableRipple
        [uk2ButtonSize]="uk2ButtonSize.large"
      >
        Save
      </button>
    </ng-template>
  </form>
</uk2-modal>

<app-remove-person-modal
  [personType]="personType"
  (removePerson)="deleteItem()"
  (modalClose)="onCloseRemoveModal()"
  #removePersonModal
></app-remove-person-modal>
