<label class="uk-label" for="amountTransfer">{{ labelText }}</label>

<input
  id="amountTransfer"
  type="text"
  class="uk-input"
  aria-label="Amount To Transfer"
  prefix="$ "
  mask="separator.2"
  thousandSeparator=","
  separatorLimit="100000"
  [dropSpecialCharacters]="[',']"
  [allowNegativeNumbers]="false"
  [ngClass]="{ red: isInvalid }"
  [disabled]="isContributedAmountReached"
  [(ngModel)]="maskedAmount"
  (ngModelChange)="emitModelChanges()"
  (focus)="resetMoneyToDefault()"
  (blur)="resetMoneyToDefault()"
  required
/>
