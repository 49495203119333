<div class="customer-support">
  <h3 class="customer-support__title">Axos</h3>
  <div class="customer-support__header frow">
    <div class="title">Axos Customer Support</div>
  </div>
  <div class="customer-support__body">
    <div class="item">Phone number</div>
    <div class="phone">1-888-509-9951</div>
  </div>
</div>
