import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { BehaviorSubject } from 'rxjs';
import { retry } from 'rxjs/operators';

import { InitializePortfolioAllocationTileInput } from './types';
import {
  PortfolioAllocationTileState,
  GetPortfolioAllocationRequest,
  PortfolioAllocationService,
  addUpdatePortfolioAllocationAccountAction,
  getSelectedAccountPortfolioAllocationTile,
} from '../core';

@Injectable({
  providedIn: 'root',
})
export class PortfolioAllocationFacade {
  isLoading$ = new BehaviorSubject<boolean>(true);
  portfolioAllocationTileAccountState$ = this.store.select(
    getSelectedAccountPortfolioAllocationTile
  );

  constructor(
    private store: Store<PortfolioAllocationTileState>,
    private portfolioAllocationService: PortfolioAllocationService
  ) {}

  initializePortfolioAllocationTile(
    input: InitializePortfolioAllocationTileInput
  ) {
    this.isLoading$.next(true);

    const portfolioAllocationRequest: GetPortfolioAllocationRequest = {
      accountNumber: input.accountNumber,
      limit: 5,
    };

    const payload: PortfolioAllocationTileState = {
      accountNumber: input.accountNumber,
      portfolioAllocations: [],
    };

    this.portfolioAllocationService
      .getPortfolioAllocation(portfolioAllocationRequest)
      .pipe(retry(3))
      .subscribe(
        serviceResult => {
          payload.portfolioAllocations = serviceResult.data ?? [];
          this.store.dispatch(
            addUpdatePortfolioAllocationAccountAction({ payload })
          );
          this.isLoading$.next(false);
        },
        err => {
          payload.error = err;
          this.store.dispatch(
            addUpdatePortfolioAllocationAccountAction({ payload })
          );
          this.isLoading$.next(false);
        }
      );
  }
}
