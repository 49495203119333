import * as Joi from 'joi';

export interface PasswordMap {
  length: string;
  lowercase: string;
  uppercase: string;
  number: string;
  symbol: string;
}

export type PasswordErrors = Partial<PasswordMap>;
export interface PasswordSchema {
  length: Joi.StringSchema;
  lowercase: Joi.StringSchema;
  uppercase: Joi.StringSchema;
  number: Joi.StringSchema;
  symbol: Joi.StringSchema;
}
export const defaultPasswordValidationSchema: PasswordSchema = {
  length: Joi.string().min(8).required(),
  lowercase: Joi.string()
    .regex(/^(?=.*[a-z]).*$/)
    .required(),
  uppercase: Joi.string()
    .regex(/^(?=.*[A-Z]).*$/)
    .required(),
  number: Joi.string()
    .regex(/^(?=.*[0-9]).*$/)
    .required(),
  symbol: Joi.string()
    .regex(/^(?!.*[`~_=[\]{}()\\\/:;"'<>,.|\s]).*[#?!@$%^&*+-].*$/)
    .required(),
};
