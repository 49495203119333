import { Component, Input, OnInit } from '@angular/core';

import { ChartSelectionItem } from '@app/pfm/models';

@Component({
  selector: 'app-chart-selection',
  templateUrl: './chart-selection.component.html',
  styleUrls: ['./chart-selection.component.scss'],
})
export class ChartSelectionComponent implements OnInit {
  @Input()
  chartTypes: ChartSelectionItem[];
  selectedChart: ChartSelectionItem;
  isLeftArrowActive = false;
  isRightArrowActive = true;

  constructor() {}

  ngOnInit(): void {
    this.initializeObjects();
    this.activateArrows(this.selectedChart);
  }

  initializeObjects(): void {
    if (this.chartTypes.length === 0) return;

    // Sort arrays.
    this.chartTypes = this.chartTypes.sort(x => x.id);

    // Deactivate all previous active elements.
    this.chartTypes.forEach(x => (x.isActive = false));

    // Set selected item to first element sorted by id.
    const firstItem = this.chartTypes[0];
    firstItem.isFirst = true;
    firstItem.isActive = true;
    this.selectedChart = firstItem;

    // Set las element to last element of array sorted by id.
    const lastItem = this.chartTypes[this.chartTypes.length - 1];
    lastItem.isLast = true;
  }

  switchToLeft(): void {
    if (this.selectedChart.isFirst || this.chartTypes.length === 1) return;

    const selectedId = this.selectedChart.id - 1;
    const selectedItem = this.findById(selectedId);

    this.assignSelected(selectedItem);

    this.activateArrows(this.selectedChart);
  }

  switchToRight(): void {
    if (this.selectedChart.isLast || this.chartTypes.length === 1) return;

    const selectedId = this.selectedChart.id + 1;
    const selectedItem = this.findById(selectedId);

    this.assignSelected(selectedItem);

    this.activateArrows(this.selectedChart);
  }

  switchToSelected(selectedItem: ChartSelectionItem) {
    this.assignSelected(selectedItem);
    this.activateArrows(selectedItem);
  }

  private assignSelected(selectedItem: ChartSelectionItem) {
    if (!!selectedItem) {
      const oldSelected = this.selectedChart;
      oldSelected.isActive = false;
      selectedItem.isActive = true;
      this.selectedChart = selectedItem;
    }
  }

  private findById(id: number): ChartSelectionItem {
    const result = this.chartTypes.filter(x => x.id === id)?.[0];

    return result;
  }

  private activateArrows(selectedItem: ChartSelectionItem): void {
    if (selectedItem.isFirst && selectedItem.isLast) {
      this.isLeftArrowActive = false;
      this.isRightArrowActive = false;
    } else if (selectedItem.isLast) {
      this.isRightArrowActive = false;
      this.isLeftArrowActive = true;
    } else if (selectedItem.isFirst) {
      this.isRightArrowActive = true;
      this.isLeftArrowActive = false;
    }
  }
}
