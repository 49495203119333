import * as angular from 'angular';
import { Inject } from '../decorators/decorators';

@Inject('$rootScope')
class LoadUserProfileHelper implements ILoadUserProfileHelper {
  userInfo: UserProfileInfo = {};

  constructor(private readonly rootScope: ng.IRootScopeService) {}

  getUserProfile(): UserProfileInfo {
    this.setProfileInfo();

    if (!this.userInfo) {
      this.rootScope.$on('profileInfoLoaded', () => this.setProfileInfo());
    }

    return angular.copy(this.userInfo);
  }

  formatPhone(phone: Phone): string {
    let retval: string = '';
    if (phone.number && phone.number.length == 10) {
      retval = `${phone.number.substring(0, 3)}-${phone.number.substring(
        3,
        6
      )}-${phone.number.substring(6, 10)}`;
      if (phone.extension && phone.extension.length > 0) {
        retval = `${retval} x${phone.extension}`;
      }
    }
    return retval;
  }
  getUserProfilePromise = () => {
    return new Promise(resolve => {
      let userInfo = this.rootScope['profileInfo'];
      if (!userInfo) {
        this.rootScope.$on('profileInfoLoaded', () => {
          userInfo = this.rootScope['profileInfo'];
          resolve(userInfo);
        });
        return;
      }
      resolve(userInfo);
    });
  };

  private setProfileInfo(): void {
    this.userInfo = this.rootScope['profileInfo'];
  }
}

export { LoadUserProfileHelper };
