import { DashboardBalanceService } from '@legacy/services/dashboard-balance.service';

export function dashboardBalanceServiceFactory($injector: any) {
  return $injector.get('dashboardBalanceService');
}

export const dashboardBalanceServiceProvider = {
  provide: DashboardBalanceService,
  useFactory: dashboardBalanceServiceFactory,
  deps: ['$injector'],
};
