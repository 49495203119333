import { Component, Inject, OnInit } from '@angular/core';

import { SwitchActions } from '@app/click-switch/enums';
import { Switch } from '@app/click-switch/models';
import { getKeyValueIncomeDetails, getKeyValueSelectedAccounts } from '@app/click-switch/utils';
import { KeyValuePair } from '@core/models';
import { DialogService, DirectDepositService } from '@core/services';
import { STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';

import { ClickSwitchBaseComponent } from '../click-switch-base/click-switch-base.component';

@Component({
  selector: 'app-click-switch-review-edit',
  templateUrl: './click-switch-review-edit.component.html',
  styleUrls: ['./click-switch-review-edit.component.scss'],
})
export class ClickSwitchReviewEditComponent extends ClickSwitchBaseComponent implements OnInit {
  sourceInformation: KeyValuePair<string, string>[] = [];
  depositInformation: KeyValuePair<string, string>[] = [];
  private termsNotAccepted = true;
  private continueButtonClicked = false;

  constructor(
    @Inject(STATE) state: ng.ui.IStateService,
    directDepositService: DirectDepositService,
    serviceHelper: ServiceHelper,
    dialogService: DialogService
  ) {
    super(state, directDepositService, serviceHelper, dialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    window.scrollTo(0, 0);

    if (!this.userSwitch.index) {
      this.state.go('udb.clickswitch.search-target');
    }
    this.sourceInformation = this.getSubmitTarget(this.userSwitch);
    this.depositInformation = this.getAccounts(this.userSwitch);
  }

  gotoAccountSelectionPage(): void {
    this.state.go('udb.clickswitch.accounts', { userSwitch: this.userSwitch });
  }

  gotoIncomeSourcePage(): void {
    this.cancelAndDeleteSwitch(() => {
      this.state.go('udb.clickswitch.search-target');
    });
  }

  goToSwitchStartPage(): void {
    this.showLeaveModal(() => {
      this.cancelAndDeleteSwitch(() => {
        this.state.go('udb.clickswitch');
      });
    });
  }

  acceptedTermsAndConditions(accepted: boolean) {
    this.termsNotAccepted = !accepted;
  }
  goToConfirmationPage(): void {
    this.continueButtonClicked = true;

    let action = -1;
    let redirect = '';

    const confirmationAction = this.userSwitch.state.actions.find(
      a => a.toLowerCase() === 'submit' || a.toLowerCase() === 'print'
    );

    if (confirmationAction) {
      switch (confirmationAction.toLowerCase()) {
        case 'submit':
          action = SwitchActions.Submit;
          redirect = 'udb.clickswitch.confirmation';
          break;
        case 'print':
          action = SwitchActions.Print;
          redirect = 'udb.clickswitch.confirmation-print';
          break;
      }
    }

    if (action !== -1) {
      this.confirmSwitch(action, () => {
        this.state.go(redirect, { userSwitch: this.userSwitch });
      });
    } else {
      this.continueButtonClicked = false;

      this.errorMessage =
        'Confirmation of the Direct Deposit cannot be submitted, please review the information provided';
    }
  }

  isContinueButtonDisabled() {
    return this.termsNotAccepted || this.continueButtonClicked;
  }

  private getSubmitTarget(userSwitch: Partial<Switch>) {
    return getKeyValueIncomeDetails(userSwitch);
  }

  private getAccounts(userSwitch: Partial<Switch>) {
    return getKeyValueSelectedAccounts(userSwitch);
  }
}
