import { createAction, props } from '@ngrx/store';
import { PdpLayoutBreadcrumbItemState, PdpLayoutState } from './types';

export const SetContextualTopBarStateAction = createAction(
  '[PDP Layout] SetContextualTopBarStateAction',
  props<{ payload: PdpLayoutState }>()
);

export const AddBreadcrumbAction = createAction(
  '[PDP Layout] AddBreadcrumbAction',
  props<{ payload: PdpLayoutBreadcrumbItemState[] }>()
);

export const selectBreadcrumbUrlAction = createAction(
  '[PDP Layout] SelectBreadcrumbUrlAction',
  props<{ payload: string }>()
);

export const updateContextualTopBarStatePageTitle = createAction(
  '[PDP Layout] UpdateContextualTopBarStatePageTitle',
  props<{ payload: string }>()
);