import { IFilterService, IScope } from 'angular';
import { Inject } from 'decorators/decorators';
import { FacingBrand } from 'models';
import { FeatureFlagService } from 'services/feature-flag.service';

@Inject('$scope', '$filter', '$rootScope', 'env', 'featureFlagService')
class TxRowController {
  // Bound properties
  isChild: boolean;
  isDisputeActive: boolean;
  isLoan: boolean;
  trxn: Transaction;
  isAccountActive: boolean;

  // Internal props to manage directive
  accountOptions: any[] = [];
  isAccountAggregationForInternalsActive = false;
  isPfm3InternalTransActive = false;

  constructor(
    private scope: IScope,
    private filterService: IFilterService,
    private root: ng.IRootScopeService,
    public env: OlbSettings,
    private featureFlagService: FeatureFlagService
  ) {}

  /** Initializes any required data */
  $onInit() {
    this.isAccountAggregationForInternalsActive = this.featureFlagService.isAccountAggregationForInternalsActive();
    this.isPfm3InternalTransActive = this.featureFlagService.isPFM3InternalTrans();

    this.isChild = this.scope['isChild'];
    this.trxn = this.scope['trxn'];
    this.isDisputeActive = this.scope['isDisputeActive'];
    this.isLoan = this.scope['isLoan'];
    this.isAccountActive = this.scope['isAccountActive'];

    if (this.isDisputeActive && this.isAccountActive && !this.trxn.dispute.key) {
      this.accountOptions.push(
        {
          label: 'Dispute this transaction',
          isCollapsible: true,
        },
        {
          label: 'Dispute multiple transactions',
          isCollapsible: false,
        }
      );

      if (this.isAccountAggregationForInternalsActive && !this.trxn.isPending && this.transactionIsNotPending()) {
        this.accountOptions.push({
          label: 'Recategorize transaction',
          isCollapsible: true,
        });
      }
    }

    if (this.trxn.childDetails && this.trxn.childDetails.length) {
      this.accountOptions.push({
        label: 'View details',
        icon: 'fa fa-file-text-o',
        isCollapsible: true,
      });
    }

    if (this.trxn.childDetails && this.trxn.childDetails.length && this.isDisputeActive) {
      this.accountOptions.splice(2, 0, { label: '', isDivider: true });
    }
  }

  /** Gets the account balance depending if filters are default or not */
  getAccountBalance(): string {
    return this.trxn.order < 2 ? this.filterService('currency')(this.trxn.balance) : '--';
  }

  /** Triggers the on select options defined */
  selectOption(option: any) {
    this.scope['onOptionSelected']({ option });
  }

  /** Triggers the select row function defined */
  selectRow() {
    this.trxn.isSelected = !this.trxn.isSelected;
    this.scope['onRowSelected']({ trxn: this.trxn });
  }

  disputeMessage() {
    let url = '';
    switch (this.env.facingBrandId) {
      case FacingBrand.Axos:
        url = this.env.facingBrandsUrls['axos'];
        break;
      case FacingBrand.Nationwide:
        url = this.env.facingBrandsUrls['nationwide'];
        break;
      case FacingBrand.UFB:
        url = this.env.facingBrandsUrls['ufb'];
    }

    url = `${url.substr(0, url.indexOf('auth'))}Messages/message-detail/${this.trxn.dispute.key}/${
      this.trxn.dispute.value
    }/true/message`;

    let msg = '';
    if (this.trxn.dispute.key && this.trxn.dispute.value) {
      if (!this.trxn.dispute.value.toLocaleLowerCase().includes('closed')) {
        msg = `This transaction is in the process of being disputed. <a href="${url}">Track your claim</a>`;
      } else {
        msg = `A determination on your dispute has been made. <a href="${url}">Review it here</a>`;
      }
    } else if (!this.trxn.dispute.key && !this.trxn.isDisputable) {
      msg = 'This transaction is not disputable';
    }

    return msg;
  }

  hideTransactionsDisputedMark(): boolean {
    return this.root['appSettings'].find((ap: any) => ap.name === 'HideTransactionsDisputedMark').value == 'true';
  }

  transactionIsNotPending(): boolean {
    const categoryName = this.trxn.olbCategoryName.toLowerCase();

    return categoryName !== 'pending';
  }

  getImagePath() {
    let categoryName = this.trxn.olbCategoryName.toLowerCase();
    const idx = categoryName.indexOf(' ');
    categoryName = idx < 0 ? categoryName : categoryName.substring(0, idx);

    const logoPath = `/img/icons/categories/${categoryName}_white.svg`;

    return logoPath;
  }

  showDetails(trxn: Transaction) {
    if (!this.isPfm3InternalTransActive) return;
    if (!trxn.isPending) trxn.isDetailsExpanded = !trxn.isDetailsExpanded;
  }
}

const txRowOptions: ng.IDirective = {
  restrict: 'A',
  scope: {
    id: '@',
    isMultiple: '=isMultipleDispute',
    isChild: '=',
    trxn: '=txRow',
    isDisputeActive: '=',
    isLoan: '=',
    onGetCheck: '&',
    onRowSelected: '&',
    onOptionSelected: '&',
    onCollapse: '&',
    isAccountActive: '=',
  },
  controller: TxRowController,
  controllerAs: '$tr',
  templateUrl: 'accounts/transactions/tx-table/tx-row/tx-row.tpl.html',
};

export const txRowDirective = () => {
  return txRowOptions;
};
