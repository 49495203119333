import { EquityType, IncomeOption, TradeStockType, TransactionType, TRANSACTION_TYPE_MAPPING } from '../enums';

export class MutualFundOrder {
  accountNumber: string;
  value: number;
  quantity?: number;
  symbol: string;
  transaction: TransactionType;
  capitalGains: IncomeOption;
  dividends: IncomeOption;
  transactionTypeDescription: TradeStockType;
  orderReference: string;
  stockType: EquityType;
  stock: string;
  constructor(parameters?: Partial<MutualFundOrder>) {
    Object.assign(this, parameters);
    this.transaction = TRANSACTION_TYPE_MAPPING[this.transactionTypeDescription];
  }
}
