<div class="modify-confirmation-banner">
  <div class="row modify-banner-row">
    <div class="col-md-10 frow">
      <app-svg-icon [iconName]="checkmarkIcon" width="1.375rem" height="1.375rem" iconColor="#26A680"></app-svg-icon>
      <p class="modify-banner-text">{{ bannerText }}</p>
    </div>
    <div *ngIf="!duration" class="col-md-2 text-right">
      <button class="dismiss-button" (click)="dismissBanner()">Dismiss</button>
    </div>
  </div>
</div>
<div *ngIf="duration" class="modify-confirmation-banner__progress-bar" [ngStyle]="progressBar"></div>
