export enum IraPlanCode {
  UNKNOWN,
  IRA,
  SEP,
  QRP,
  MSA,
  HSA,
  SIM,
  RTH,
  RCV,
  EDU,
}
