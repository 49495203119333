<div id="loan-payment">
  <div class="header-section-title text-center">
    <div>
      <img class="hide-cs" src="/img/icons/loan-payment/{{ icon }}.svg" />
      <h1>{{ isPayoff ? 'Loan Payoff' : 'Make a Payment' }}</h1>
      <app-uk-dropdown
        [dropdownItems]="dropdownAccounts"
        class="frow center"
        (onSelect)="toAccountChange($event)"
        [selectedItem]="selectedToAccount"
        *ngIf="!(isBusy|async)"
      ></app-uk-dropdown>
    </div>
  </div>
  <app-dot-loader [showWhen]="!showForm()">Loading payment details</app-dot-loader>
  <div class="fcol-lg-10 fcol-lg-offset-1" [hidden]="!showForm()">
    <div class="content d-flex flex-column align-items-center">
      <div class="col-12 col-lg-8">
        <form name="paymentForm" #paymentForm="ngForm">
          <div *ngIf="!isPayoff">
            <div class="subtitle">
              <span>How much would you like to pay?</span>
            </div>
            <div class="subtitle" [hidden]="!ShowBalance">
              <p>
                Your Outstanding Balance is
                <b>{{ loanAccount?.outstandingBalance | currency: 'USD':'symbol':'2.2-2' }}</b>
              </p>
            </div>

            <div class="frow center margin-bottom" *ngIf="areAmountPanelsVisible()">
              <div class="amount-panel fcol-sm-6">
                <div
                  class="radio-panel"
                  [ngClass]="{ 'panel-selected': isAmountDue() }"
                  (click)="setAmount('AmountDue')"
                >
                  <input
                    type="radio"
                    name="amount-type"
                    id="amount-due"
                    value="AmountDue"
                    [(ngModel)]="loanPaymentType"
                  />
                  <label for="amount-due">Amount Due</label>
                  <app-tooltip [content]="amountDueTooltip" [hidden]="!amountDueTooltip"></app-tooltip>
                  <div class="frow">
                    <h2 class="amount-label amount">{{ amountDue | currency }}</h2>
                  </div>
                </div>
              </div>

              <div class="amount-panel fcol-sm-6">
                <div
                  class="radio-panel"
                  [ngClass]="{ 'panel-selected': isRegularPayment() }"
                  (click)="setAmount('RegularPayment')"
                >
                  <input
                    #regularPayment="ngModel"
                    type="radio"
                    name="amount-type"
                    id="regular-payment"
                    value="RegularPayment"
                    [(ngModel)]="loanPaymentType"
                  />
                  <label for="regular-payment">Regular Payment</label>
                  <div class="frow">
                    <h2 class="amount-label amount">{{ accountDetails?.accountPayment.amount | currency }}</h2>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!areAmountPanelsVisible()" class="margin-bottom">
              <label class="uk-label">Amount Due</label>
              <app-tooltip [content]="amountDueTooltip" [hidden]="!amountDueTooltip"></app-tooltip>
              <h2 class="amount-label">{{ accountDetails?.accountPayment.amount | currency }}</h2>
            </div>
          </div>

          <div class="margin-bottom" *ngIf="isPayoff">
            <div class="subtitle margin-bottom">
              <span>From which account would you like to pay?</span>
            </div>
            <div class="subtitle">
              <p>
                Your loan payoff will be scheduled for
                <b>{{ startDatePicker.startDate.toDate() | date: 'MM/dd/yyyy' }}</b>
              </p>
            </div>
            <div class="frow center margin-bottom" *ngIf="areAmountPanelsVisible()">
              <div class="amount-panel fcol-sm-6">
                <div
                  class="radio-panel"
                  [ngClass]="{ 'panel-selected': isAmountDue() }"
                  (click)="setAmount('AmountDue')"
                >
                  <input
                    #amountDue="ngModel"
                    type="radio"
                    name="amount-type"
                    id="amount-due"
                    value="AmountDue"
                    [(ngModel)]="loanPaymentType"
                  />
                  <label for="amount-due">Total Payoff Amount</label>
                  <app-tooltip [content]="tooltip"></app-tooltip>
                  <div class="frow">
                    <h2 class="amount-label amount">{{ payoffCalculated?.payoffAmount | currency }}</h2>
                  </div>
                </div>
              </div>
              <div class="amount-panel fcol-sm-6"></div>
            </div>
          </div>
          <div class="form-group m-0">
            <label for="fromAcct" class="uk-label">From Account</label>
            <select
              #fromAcct="ngModel"
              id="fromAcct"
              name="fromAcct"
              class="uk-input"
              [ngModel]="selectedFromAccount"
              (ngModelChange)="fromAccountChange(fromAcct, true)"
            >
              <ng-container *ngIf="internalAccountsArray.length != 0">
                <optgroup [label]="'Internal Accounts'">
                  <option
                    *ngFor="let acc of internalAccountsArray"
                    [ngValue]="acc"
                    [innerText]="
                      acc.displayName
                        ? acc.displayName + ' ' + acc.availableBalanceDisplay
                        : acc.nickname + ' ' + acc.availableBalanceDisplay
                    "
                  ></option>
                </optgroup>
              </ng-container>
              <ng-container *ngIf="externalAccountArray.length != 0">
                <optgroup [label]="'External Account'">
                  <option
                    *ngFor="let acc of externalAccountArray"
                    [ngValue]="acc"
                    [innerText]="acc.displayName ? acc.displayName : acc.nickname"
                  ></option>
                </optgroup>
              </ng-container>

              <optgroup [label]="'Link External Account'">
                <option
                  *ngFor="let acc of linkExternalAccountArray"
                  [ngValue]="acc"
                  [innerText]="acc.displayName ? acc.displayName : acc.nickname"
                ></option>
              </optgroup>
            </select>

            <span class="text-error" *ngIf="!amountValid || totalAmount === 0">
              {{ amountError }}
            </span>
            <span class="uk-label margin-top" *ngIf="!_isLoanFeatureEnabled && isExternal">
              Paying from an external account may take as long as 3-4 business days to process. Please ensure that you
              schedule your payment in advance to avoid a late charge
            </span>
          </div>
          <div *ngIf="isPayoff">
            <div class="disclaimer margin-bottom">
              <p *ngFor="let disclaimerP of disclaimerMakePayment">
                {{ disclaimerP }}
              </p>
            </div>
            <hr />
            <div class="form-group">
              <label class="uk-label">{{ totalAmountLabel }}</label>
              <h2 class="amount-label">{{ payoffCalculated?.payoffAmount | currency }}</h2>
              <span class="text-error" *ngIf="!amountValid || totalAmount === 0">
                {{ amountError }}
              </span>
            </div>
          </div>
          <div *ngIf="!isPayoff">
            <div class="form-group" id="PayOutBalance">
              <label for="additionalPrincipal" class="uk-label">
                <label class="uk-label">{{ LabelPay }}</label>
                <app-tooltip [placement]="'top'" [content]="paymentOptionTooltip"></app-tooltip>
              </label>
              <input
                #additionalPrincipal="ngModel"
                type="text"
                id="additionalPrincipal"
                name="additionalPrincipal"
                class="uk-input"
                [(ngModel)]="payAdditionalPrincipal"
                (ngModelChange)="setAdditionalPrincipal($event)"
                maxlength="8"
                prefix="$"
                mask="separator.2"
                thousandSeparator=","
                [disabled]="disableAdditionalPrincipal"
                (focusin)="resetAdditionalPrincipalToDefault()"
                (blur)="resetAdditionalPrincipalToDefault()"
              />
            </div>

            <div class="fcol-cs-12 fcol-sm-7 fcol-md-6 panel">
              <div class="panel-heading">
                <div (click)="isExpanded = !isExpanded">
                  <h4 class="panel-title">
                    <a class="anchorTag">
                      Schedule and recurring options
                      <i class="bofi-arrow-chevron" [ngClass]="isExpanded ? 'up' : 'down'"></i>
                    </a>
                  </h4>
                </div>
              </div>
              <div *ngIf="isExpanded" class="panel-collapse in collapse">
                <div class="panel-body">
                  <div class="form-group">
                    <label for="date" class="uk-label">Start on date</label>
                    <i class="bofi-datepicker icon-input--inner" (click)="showCalendar('date')"></i>
                    <input
                      #date="ngModel"
                      appDateRangePicker
                      type="text"
                      id="date"
                      name="date"
                      class="uk-input icon-input--inner"
                      [ngModel]="startDatePicker.startDate | date: 'MM/dd/YY'"
                      (ngModelChange)="startDatePicker = $event"
                      [options]="startDatePickerOptions"
                      (dateApplied)="onChangeDate(date, $event)"
                      readonly
                    />
                    <span class="error-message margin-top" *ngIf="!isTodayValidForExternal">
                      *Transfers to an external account are scheduled the next business day if submitted before 1:45
                      p.m. Pacific Time. Any requests after this cut-off time will be scheduled for the second business
                      day.
                    </span>
                  </div>
                  <div class="form-group">
                    <label for="frequency" class="uk-label">Frequency</label>
                    <select
                      #frequency="ngModel"
                      id="frequency"
                      name="frequency"
                      class="uk-input"
                      [(ngModel)]="selectedFrequency"
                      (ngModelChange)="frequencyChange()"
                      [disabled]="useAmountDue"
                    >
                      <option *ngFor="let freq of filteredFrequencies" [ngValue]="freq">{{ freq.label }}</option>
                    </select>
                  </div>
                  <div class="form-group" *ngIf="isRecurrent && !useAmountDue && isRecurrent">
                    <label for="sendUntil" class="uk-label">Send Until</label>
                    <select
                      #sendUntil="ngModel"
                      name="sendUntil"
                      id="sendUntil"
                      class="uk-input"
                      [(ngModel)]="selectedSendUntil"
                      (ngModelChange)="sendUntilChange()"
                    >
                      <option *ngFor="let opt of sendUntilOptions" [ngValue]="opt">{{ opt.label }}</option>
                    </select>
                    <span class="text-error" *ngIf="!isSendUntilValid">Select an option</span>
                  </div>
                  <div class="form-group" *ngIf="showNumberOfTransfers && !useAmountDue && isRecurrent">
                    <label for="numberTransfers" class="uk-label">Number of transfers</label>
                    <input
                      #numberTransfersModel="ngModel"
                      id="numberTransfers"
                      name="numberTransfers"
                      type="text"
                      class="uk-input"
                      [(ngModel)]="numberTransfers"
                      maxlength="3"
                      pattern="^\d+$"
                      (ngModelChange)="validateNumberOfTransfers()"
                    />
                    <span class="text-error" *ngIf="numberTransfersModel.invalid || !isNumberOfTransfersValid">
                      You must specify a valid number of transfers
                    </span>
                  </div>
                  <div class="form-group" *ngIf="showExpirationDate">
                    <label for="endDate" class="uk-label">End on date</label>
                    <i class="bofi-datepicker icon-input--inner" (click)="showCalendar('endDate')"></i>
                    <input
                      #endDate="ngModel"
                      appDateRangePicker
                      type="text"
                      id="endDate"
                      name="endDate"
                      class="uk-input icon-input--inner"
                      [options]="endDatePickerOptions"
                      [ngModel]="endDatePicker.startDate | date: 'MM/dd/YY'"
                      (ngModelChange)="endDatePicker = $event"
                      (dateApplied)="onChangeEndDate(endDate, $event)"
                      readonly
                    />
                    <span class="text-error" *ngIf="!isEndDateValid">
                      The End on date needs to be greater than the Start on date.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="disclaimer">
              <p *ngFor="let disclaimerP of disclaimerMakePayment">
                {{ disclaimerP }}
              </p>
            </div>

            <hr />
            <div class="form-group">
              <label class="uk-label">{{ totalAmountLabel }}</label>
              <h2 class="amount-label">{{ totalAmount | currency }}</h2>
              <span class="text-error" *ngIf="!amountValid || totalAmount === 0">
                {{ amountError }}
              </span>
            </div>
          </div>

          <div class="form-group" *ngIf="isReadOnly">
            <span class="text-error">
              Current day payments are not available while the system is in maintenance. Please schedule your payment
              for a future date or try again later.
            </span>
          </div>
          <div class="form-group text-center">
            <button
              type="button"
              class="uk-btn solid secondary lg"
              (click)="isPayoff ? showExternalModal() : validate()"
              [disabled]="isReadOnly || isLoading || isSubmitting"
            >
              Continue
            </button>
          </div>
          <p class="foot-note">
            Note: If you have already scheduled a payment for the current date it cannot be viewed or modified while in
            process and will reflect on your loan on the next business day.
          </p>
        </form>
      </div>
    </div>
  </div>
</div>
