import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {
  ForecastFrequency,
  Frequency,
  GoalAccountType,
  GoalType,
  PortfolioType,
  TransactionType,
  TransferType,
} from '@app/axos-invest/enums';
import {
  ForecastItem,
  ForecastProjection,
  Goal,
  GoalAccount,
  GoalAllocationModel,
  PerformanceChange,
  PortfolioDetails,
  RecommendedAutoDepositFilter,
  TransferForm,
} from '@app/axos-invest/models';
import { hasOnlyNumbersRegex } from '@app/config/regexes';
import { normalizeDate } from '@app/utils';

import { TRANSFER_CONFIGURATION } from '../constants';

/**
 * Returns the frequency desciption passing as argument the frequency code
 * @param frequency Identifier for the frequency to be mapped
 * @returns A string which represents the label of the frequency
 */
export function mapFrequency(frequency: number): string {
  frequency = Number(frequency);
  switch (frequency) {
    case Frequency.ONE_TIME:
      return 'One Time';
    case Frequency.WEEKLY:
      return 'Weekly';
    case Frequency.TWO_WEEKS:
      return 'Every Two Weeks';
    case Frequency.MONTHLY:
      return 'Monthly';
    case Frequency.QUARTERLY:
      return 'Quarterly';
    default:
      return '';
  }
}

/**
 * Returns the frequency code passing as argument the frequency description
 * @param frequency Identifier for the frequency to be mapped
 * @returns A number which represents the code of the frequency
 */
export function mapFrequencyToCode(frequency: string): number {
  frequency = frequency.toLowerCase();
  switch (frequency) {
    case 'one time':
      return Frequency.ONE_TIME;
    case 'weekly':
      return Frequency.WEEKLY;
    case 'bi-weekly':
      return Frequency.TWO_WEEKS;
    case 'monthly':
      return Frequency.MONTHLY;
    case 'quarterly':
      return Frequency.QUARTERLY;
    default:
      return 1;
  }
}

export function getRecommendedAutoDepositFilter(goal: Goal): RecommendedAutoDepositFilter {
  const filter: RecommendedAutoDepositFilter = {
    allocationEnd: goal.allocation?.allocationEnd,
    allocationStart: goal.allocation?.allocationStart,
    cashStart: goal.allocation?.cashStart.toString(),
    cashEnd: goal.allocation?.cashEnd.toString(),
    milestoneId: goal.id,
    targetAmount: goal.targetAmount,
    oneTimeDeposit: goal.currentValue,
    targetDate: goal.targetDate,
    startDate: normalizeDate(new Date()),
  };
  const goalTypeMappings = {
    [GoalType.RainyDay]: () => {
      filter.monthsToGoal = goal.metadata?.monthsToGoal;
    },
    [GoalType.Retirement]: () => {
      filter.lifeExpectancy = goal.metadata?.lifeExpectancy;
      filter.socialSecurityAge = goal.metadata?.socialSecurityAge;
      filter.socialSecurityPayout = goal.metadata?.socialSecurityPayout;
      filter.retirementAge = goal.metadata?.retirementAge;
      filter.spendPerYear = goal.metadata?.spendPerYear;
    },
  };
  const goalTypeMapping = goalTypeMappings[goal.type];
  if (goalTypeMapping) goalTypeMapping();

  return filter;
}

export function createTransferFundsForm(transferData: Partial<TransferForm>, transferType: TransferType, goal: Goal) {
  const today = normalizeDate(new Date());
  const defaultFrequencies = {
    [TransferType.OneTimeDeposit]: Frequency.ONE_TIME,
    [TransferType.OneTimeWithdrawal]: Frequency.ONE_TIME,
    [TransferType.FundingFlow]: Frequency.ONE_TIME,
    [TransferType.RecurrentDeposit]: Frequency.MONTHLY,
    [TransferType.RecurrentWithdrawal]: Frequency.MONTHLY,
  };

  let defaultGoalAccount = goal.accounts.length > 0 ? goal.accounts[0].accountNumber : 0;
  if (goal.type === GoalType.Retirement) {
    const retirementAccounts = getRetirementAccounts(goal.accounts);
    if (retirementAccounts.length > 0) {
      // if funding flow, preselect an account from the retirement accounts
      defaultGoalAccount = transferType === TransferType.FundingFlow ? retirementAccounts[0].accountNumber : '';
    }
  }

  const minAmount = transferType === TransferType.RecurrentWithdrawal ? 100 : 0.1;
  const goalAccountNumber = transferData.goalAccount || defaultGoalAccount;
  const goalAccount = goal.accounts.find(a => a.accountNumber === goalAccountNumber);
  const amountValidators = [Validators.required, Validators.min(minAmount)];
  if (goalAccount && TRANSFER_CONFIGURATION[transferType].transactionType === TransactionType.Withdrawal) {
    amountValidators.push(Validators.max(goalAccount.accountValue));
  }

  return new UntypedFormGroup({
    accountId: new UntypedFormControl(transferData.accountId || '', Validators.required),
    account: new UntypedFormControl(transferData.account, Validators.required),
    accountNumber: new UntypedFormControl(transferData.accountNumber),
    amount: new UntypedFormControl(transferData.amount || '0.00', amountValidators),
    frequency: new UntypedFormControl(transferData.frequency || defaultFrequencies[transferType], Validators.required),
    startDate: new UntypedFormControl(transferData.startDate || today, Validators.required),
    endDate: new UntypedFormControl(transferData.endDate),
    transferSeriesId: new UntypedFormControl(transferData.transferSeriesId),
    goalAccount: new UntypedFormControl(goalAccountNumber, Validators.required),
    contributionYear: new UntypedFormControl(transferData.contributionYear || null),
  });
}

export function getRetirementAccounts(accounts: GoalAccount[]): GoalAccount[] {
  const types = {
    [GoalAccountType.TraditionalIRA]: 'Traditional IRA',
    [GoalAccountType.RothIRA]: 'Roth IRA',
    [GoalAccountType.RolloverIRA]: 'Rollover IRA',
    [GoalAccountType.SepIRA]: 'SEP IRA',
    [GoalAccountType.Personal]: 'Non-IRA',
  };
  const retirementAccounts = [];
  Object.keys(types).forEach(type => {
    const name = types[type];
    const account = accounts.find(a => a.type === type);

    if (account) {
      retirementAccounts.push({ ...account, name });
    }
  });

  return retirementAccounts;
}

export function mapFrequencyToForecastFrequency(frequency: Frequency): ForecastFrequency {
  const mappings = {
    [Frequency.MONTHLY]: ForecastFrequency.Monthly,
    [Frequency.TWO_WEEKS]: ForecastFrequency.BiWeekly,
    [Frequency.QUARTERLY]: ForecastFrequency.Quarterly,
    [Frequency.WEEKLY]: ForecastFrequency.Weekly,
  };

  return mappings[frequency];
}

export function calculateProjectionValues(forecast: ForecastItem[]): ForecastProjection {
  const lastProjection = forecast[forecast.length - 1];

  return new ForecastProjection({
    low: Number(lastProjection.value) * 0.9,
    high: Number(lastProjection.value) * 1.1,
    date: lastProjection.date,
  });
}

export function getRiskScoreDescription(riskScore: number) {
  if (riskScore >= 0.9 && riskScore < 2) {
    return 'Very Conservative, Preserving Capital';
  } else if (riskScore >= 2 && riskScore < 4) {
    return 'Conservative, Minimal Growth';
  } else if (riskScore >= 4 && riskScore < 7) {
    return 'Moderate, Steady Growth';
  } else if (riskScore >= 7 && riskScore < 9) {
    return 'Aggressive, Strong Growth';
  } else if (riskScore >= 9 && riskScore <= 10.1) {
    return 'Very Aggressive, Maximum Growth';
  } else if (riskScore === 0) {
    return 'All Cash';
  } else {
    return 'Error in calculating Risk Type';
  }
}

export function calculatePortfolioType(allocation: GoalAllocationModel): PortfolioType {
  if (allocation.allocationStart.length > 5) {
    return PortfolioType.Custom;
  } else if (allocation.allocationStart === allocation.allocationEnd && allocation.cashStart === allocation.cashEnd) {
    return PortfolioType.Basic;
  } else {
    return PortfolioType.GlidepathEnabled;
  }
}

export function interpolateColors(color1: string, color2: string, steps: number) {
  const stepFactor = 1 / (steps - 1);
  const interpolatedColorArray = [];

  const firstColor = color1.match(/\d+/g).map(Number);
  const secondColor = color2.match(/\d+/g).map(Number);

  for (let i = 0; i < steps; i++) {
    interpolatedColorArray.push(getColor(firstColor, secondColor, stepFactor * i));
  }

  return interpolatedColorArray;
}

export function interpolateColor(color1: string, color2: string, steps: number, step: number) {
  const stepFactor = 1 / (steps - 1);

  const firstColor = color1.match(/\d+/g).map(Number);
  const secondColor = color2.match(/\d+/g).map(Number);

  return getColor(firstColor, secondColor, stepFactor * step);
}

export function getColor(color1: number[], color2: number[], factor: number) {
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  const color = `rgba(${result[0]}, ${result[1]}, ${result[2]}, ${result[3]})`;

  return color;
}

export function roundValues(numbers: { id: number; val: number }[], roundValue: number) {
  const roundedNumbers = numbers.map(num => {
    return Math.floor(num.val);
  });

  const totalRoundedNumbers = roundedNumbers.reduce((a, b) => a + b, 0);

  let diff = roundValue - totalRoundedNumbers;

  const sortedNumbers = numbers.sort((a, b) => b.val - Math.floor(b.val) - (a.val - Math.floor(a.val)));

  let finalRoundedNumbers = sortedNumbers.map(num => {
    if (diff > 0) {
      num.val = Math.floor(num.val) + 1;
      diff -= 1;

      return num;
    } else {
      num.val = Math.floor(num.val);

      return num;
    }
  });

  finalRoundedNumbers = finalRoundedNumbers.sort((a, b) => a.id - b.id);

  const roundedValues = finalRoundedNumbers.map(num => num.val);

  return roundedValues;
}

export function createGoalEditForm(goalData: Partial<Goal>) {
  const today = normalizeDate(new Date());

  return new UntypedFormGroup({
    id: new UntypedFormControl(goalData.id),
    targetAmount: new UntypedFormControl(
      goalData.targetAmount || '0.00',
      goalData.type === GoalType.RainyDay
        ? [Validators.required, Validators.min(500), Validators.max(99999)]
        : goalData.type === GoalType.Custom
        ? [Validators.required, Validators.min(500), Validators.max(999999)]
        : null
    ),
    targetDate: new UntypedFormControl(
      goalData.targetDate || today,
      goalData.type === GoalType.Custom ? Validators.required : null
    ),
    name: new UntypedFormControl(goalData.name, goalData.type === GoalType.Custom ? Validators.required : null),
    retirementAge: new UntypedFormControl(
      goalData.metadata?.retirementAge,
      goalData.type === GoalType.Retirement
        ? [
            Validators.required,
            Validators.min(goalData.age),
            Validators.max(124),
            Validators.pattern(hasOnlyNumbersRegex),
          ]
        : null
    ),
    spendPerYear: new UntypedFormControl(
      goalData.metadata?.spendPerYear || '0.00',
      goalData.type === GoalType.Retirement
        ? [Validators.required, Validators.min(20000), Validators.max(9999999)]
        : null
    ),
    monthsToGoal: new UntypedFormControl(
      goalData.metadata?.monthsToGoal,
      goalData.type === GoalType.RainyDay
        ? [Validators.required, Validators.min(1), Validators.max(48), Validators.pattern(hasOnlyNumbersRegex)]
        : null
    ),
  });
}

export function goalAccountToNewOlbAccount(goalAccount: GoalAccount): NewOlbAccount {
  return {
    routingNumber: goalAccount.milestoneId,
    nickname: goalAccount.milestoneName,
    accountNumber: goalAccount.accountNumber,
    availableBalance: goalAccount.accountValue,
    isAxosInvest: true,
  };
}

export function createEditPortfolioForm(formData: PortfolioDetails, portfolioType: PortfolioType) {
  const maxCash = portfolioType === PortfolioType.Basic ? 100 : 99;

  return new UntypedFormGroup({
    stockAllocation: new UntypedFormControl(formData.stockAllocation),
    bondAllocation: new UntypedFormControl(formData.bondAllocation),
    cashAllocation: new UntypedFormControl(formData.cashAllocation, [
      Validators.required,
      Validators.min(0),
      Validators.max(maxCash),
    ]),
    allocationScore: new UntypedFormControl(formData.allocationScore),
  });
}

export function calculateDayChangeAmount(currentDay: PerformanceChange, previousDay: PerformanceChange) {
  return currentDay.close - currentDay.contributions - (previousDay.close - previousDay.contributions);
}

export function calculateDayChangePercentage(currentDay: PerformanceChange, previousDay: PerformanceChange) {
  const percentage =
    (currentDay.close - currentDay.contributions - (previousDay.close - previousDay.contributions)) / previousDay.close;

  return !isFinite(percentage) ? 0 : percentage;
}
