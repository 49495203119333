<div id="step-2">
  <div class="step-top-actions"></div>
  <div class="step-content">
    <div id="header">
      <img src="../../../../../../../assets/axos/img/icons/x_icon.svg" />
      <span>Cash Management Checking Account - Individual</span>
    </div>
    <h1 id="title">
      What account features would you like?
    </h1>
    <mat-selection-list
      id="features-container"
      uk2CheckboxSelectionCards
      disableRipple
      #featuresSelectionList
    >
      <mat-list-option
        id="dc-feature-container"
        [value]="investorCheckingAccountFeatures.DebitCard"
      >
        <div class="uk2-checkbox-selection-cards-icon-container">
          <mat-icon
            class="uk2-icon-tier-1"
            [svgIcon]="uk2Tier1FinancialIcons.axosCard"
          ></mat-icon>
        </div>
        <div class="uk2-checkbox-selection-cards-text-container">
          <label>Axos Debit Card</label>
          <p>
            Enjoy convenient access to your funds anytime, anywhere with an Axos
            Debit Card for your account
          </p>
        </div>
      </mat-list-option>

      <mat-list-option
        id="odl-feature-container"
        [value]="investorCheckingAccountFeatures.OverdraftLineOfCredit"
      >
        <div class="uk2-checkbox-selection-cards-icon-container">
          <mat-icon
            class="uk2-icon-tier-1"
            [svgIcon]="uk2Tier1FinancialIcons.moneyArrows"
          ></mat-icon>
        </div>
        <div class="uk2-checkbox-selection-cards-text-container">
          <label>Overdraft Protection</label>
          <p>
            Protect your account against low balances with an Overdraft Line of
            Credit up to $5,000, no credit check needed
          </p>
          <a uk2Anchor (click)="openODLTCModal()">
            Overdraft Line of Credit Terms and Conditions
          </a>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div class="step-bottom-actions">
    <mat-divider></mat-divider>
    <div class="button-group">
      <button
        id="skip-button"
        uk2TextButton
        disableRipple
        mat-button
        cdkStepperNext
        [disabled]="featuresSelectionList.selectedOptions.selected.length > 0"
      >
        Skip For Now
      </button>

      <button
        id="continue-button"
        uk2PrimaryButton
        disableRipple
        mat-raised-button
        cdkStepperNext
        [disabled]="featuresSelectionList.selectedOptions.selected.length === 0"
      >
        Continue
      </button>
    </div>
  </div>
</div>
