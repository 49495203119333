import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ChangePasswordRequest } from '@app/authentication/models';
import { UserProfileService } from '@core/services';
import { createPasswordValidators } from '@shared/utils/validators';

@Component({
  selector: 'up-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['../../scss/user-profile.common.scss', './change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Output() updatePassword = new EventEmitter<null>();
  @Output() back = new EventEmitter<null>();

  isBusy = false;
  changePasswordForm: UntypedFormGroup;
  errorMessage: string = null;

  constructor(private fb: UntypedFormBuilder, private userProfileService: UserProfileService) {}

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      currentpassword: this.fb.control('', [Validators.required]),
      password: this.fb.control('', createPasswordValidators(25)),
    });
  }

  changePassword(): void {
    this.errorMessage = null;
    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
    } else {
      this.isBusy = true;
      const request: ChangePasswordRequest = { ...this.changePasswordForm.value };

      this.userProfileService
        .changePassword(request)
        .pipe(
          finalize(() => {
            this.isBusy = false;
          })
        )
        .subscribe({
          next: () => {
            this.updatePassword.emit();
            this.back.emit();
          },
          error: res => {
            this.errorMessage = res.error.message;
          },
        });
    }
  }
}
