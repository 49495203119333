import * as angular from 'angular';
import { MyAlertsConfig } from './my-alerts.config';
import { MyAlertsController } from './my-alerts.controller';
import { common } from '../../common/common';
import { AlertBox } from './directives/alert';
import { CreateAlert } from './directives/create-alert';
import { CreateAlertController } from './directives/create-alert.controller';
import { AmountDirective } from './directives/amount';
import { frequencySelector } from './components/frequency-selector/frequency-selector';
import { services } from '../../services/services';
import { DeliveryMethodController } from './delivery-method.controller';
import { PreferencesController } from '../privacy-preferences/privacy-preferences.controller';

export const myAlerts = angular
  .module('udb.alertsNotifications.myAlerts', ['ui.router', 'ngMessages', 'udb.myAlertsLegacy', services, common])
  .controller('MyAlertsController', MyAlertsController)
  .controller('CreateAlertController', CreateAlertController)
  .controller('DeliveryMethodController', DeliveryMethodController)
  .controller('PreferencesController', PreferencesController)
  .directive('alert', AlertBox.factory())
  .directive('createAlert', CreateAlert.factory())
  .directive('amount', AmountDirective.factory())
  .component('frequencySelector', frequencySelector)
  .config(MyAlertsConfig).name;
