import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

import { TopBarState } from '@app/Areas/AAS/aas-shared/core';
import { MainLayoutFacade } from '@app/Areas/AAS/aas-shared/facade/main-layout.facade';
import { BrandingFacade } from '@app/Areas/AAS/features/branding';
import { PdpLayoutFacade } from '@app/Areas/AAS/features/product-details-page/layout/facade';
import {
  PdpLayoutBreadcrumbItemState,
  PdpLayoutContextualTopBarState,
} from '@app/Areas/AAS/features/product-details-page/layout/core';
import { IStateService } from 'angular-ui-router';
import { STATE } from '@core/tokens';
import { SEPARATOR_FORMAT, URL_FORMAT } from './constants';
import { SubSink } from '@axos/subsink';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  imgLogoPath: string;
  organizationName: string;
  topBarState$!: Observable<TopBarState>;
  contextualTopBarState$!: Observable<PdpLayoutContextualTopBarState>;
  isContextualTopBarLoading: boolean = false;
  isContextualTopBarSticky: boolean = true;
  showBrandTopBar: boolean = false;

  private subsink = new SubSink();

  constructor(
    private mainLayoutFacade: MainLayoutFacade,
    private brandingFacade: BrandingFacade,
    private pdpLayoutFacade: PdpLayoutFacade,
    private location: Location,
    private riaFacade: RiaFacade,
    private featureFlagService: FeatureFlagService,
    @Inject(STATE) private stateService: IStateService
  ) {}

  ngOnInit(): void {
    if (this.featureFlagService.isRiaPilotAllowedAccountsActive()) {
      this.topBarState$ = combineLatest([this.riaFacade.selectedRia$, this.mainLayoutFacade.topBarState$]).pipe(
        filter(([, topBarState]) => !!topBarState?.logoImgPath),
        filter(([selectedRia]) => selectedRia?.useCustomBranding),
        switchMap(([, topBarState]) => of(topBarState))
      );
    } else {
      this.topBarState$ = this.mainLayoutFacade.topBarState$.pipe(filter(state => state?.logoImgPath !== undefined));
    }

    this.mainLayoutFacade.topBarState$
      .pipe(
        switchMap(topBarState => {
          return this.riaFacade.selectedRia$.pipe(
            filter(selectedRia => selectedRia?.useCustomBranding),
            tap((selectedRia: RiaType) => {
              this.showBrandTopBar = selectedRia.useCustomBranding && !!topBarState?.logoImgPath;
            })
          );
        })
      )
      .subscribe();

    this.contextualTopBarState$ = this.pdpLayoutFacade.contextualTopBarState$.pipe(
      filter(state => state?.pageTitle !== undefined)
    );
    this.brandingFacade.loadBranding('app-main-layout');

    this.subsink.sink = this.location.subscribe(popState => {
      if (popState.url) {
        this.pdpLayoutFacade.selectBreadcrumbUrl(popState.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  onItemSelected(breadcrumbItem: PdpLayoutBreadcrumbItemState) {
    let accDetails = breadcrumbItem.url.split(SEPARATOR_FORMAT);
    let accNum = accDetails[3];

    this.pdpLayoutFacade.selectBreadcrumbUrl(breadcrumbItem.url);

    this.stateService.go(URL_FORMAT, {
      accountNumber: accNum,
    });
  }
}
