import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SendMoneyBusinessModalComponent } from '../send-money-business-modal/send-money-business-modal.component';
import { IPayService } from '@core/services/ipay.service';
import { olbSettings } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { IPaySSOResult } from '@legacy/typings/app/IPaySSOResult';
import { finalize } from 'rxjs/operators';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';

@Component({
  selector: 'app-redirect-to-ipay-modal',
  templateUrl: './redirect-to-ipay-modal.component.html',
  styleUrls: ['./redirect-to-ipay-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectToIpayModalComponent implements OnInit {
  isSBBActive: boolean;
  isEnabledForiPaySSO: boolean;
  isLoadingURL: boolean;
  hasBusiness: boolean;
  hasIndividual: boolean;
  isDisabled: boolean;
  selectedBusiness: AccountProfile | null;
  sbbAccounts: AccountProfile[];
  startingSentence: string;
  public readonly MOVE_MONEY_SENTENCE = 'Transfer money';
  public readonly BILL_PAY_SENTENCE = 'Pay bills';

  constructor(
    private dialogRef: MatDialogRef<SendMoneyBusinessModalComponent>,
    private iPayService: IPayService,
    private serviceHelper: ServiceHelper,
    private modalService: ModalService,
    @Inject(olbSettings) public settings: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // SBB Logic
    this.startingSentence = this.MOVE_MONEY_SENTENCE;
    this.selectedBusiness = null;
    this.isSBBActive = false;
    this.hasBusiness = false;
    this.hasIndividual = false;
    this.isDisabled = true;

    // Logic to complete the modal's sentence with bill pay or move money.
    if (this.data.isBillPay === true) {
      this.startingSentence = this.BILL_PAY_SENTENCE;
    }
  }
  continue() {
    // Data excepted is an id and a boolean to be true or false
    this.isLoadingURL = true;
    const service = this.data.isAccountId
      ? this.iPayService.getSsoUrlByAccountId
      : this.iPayService.getSsoUrlByBusinessId;

    service
      .call(this.iPayService, this.data.id)
      .pipe(
        finalize(() => {
          this.isLoadingURL = false;
        })
      )
      .subscribe({
        next: (resp: OlbResponse<IPaySSOResult>) => {
          // Send user to IPay
          if (resp.data.ssoUrl !== null && resp.data.ssoUrl !== '' && resp.data.status === 1) {
            window.open(resp.data.ssoUrl, '_blank');
            setTimeout(() => {
              // Close modal automatically after 1sec
              this.closeModal();
            }, 1000);
          } else {
            this.closeModal();
            this.iPayError(true);
          }
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private iPayError(showPopup: boolean): string {
    const defaultMessage = showPopup
      ? `<div><img src="/assets/img/icons/illustration-area.svg" /></div>
      It seems like we've stumbled upon a little glitch. But we’re on it! We apologize for the inconvenience. Please try again shortly.`
      : 'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
    const modalSettings: ModalSettings = {
      headerText: 'Oops! A Tiny Hiccup.',
      bodyText: defaultMessage,
      okText: 'Okay',
      hasCancelButton: false,
      hasIcon: false,
      hasHeaderText: true,
      okButtonClass: 'new-button-okay',
    };
    if (showPopup) {
      this.modalService
        .show({ windowClass: 'modal-service internal-error-modal' }, modalSettings)
        .then(() => {})
        .catch(() => {
          return defaultMessage;
        });
    }

    return defaultMessage;
  }
}
