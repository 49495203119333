import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { STATE } from '@core/tokens';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-billpay-nav',
  templateUrl: './billpay-nav.component.html',
  styleUrls: ['./billpay-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillpayNavComponent {
  @Input() leftRedirectionPath: string;
  @Input() rightRedirectionPath: string;
  @Input() leftTabText: string;
  @Input() rightTabText: string;
  @Input() activeTab: number = 0;
  constructor(@Inject(STATE) private readonly state: IStateService) {}
  onLeftTabClick(): void {
    this.state.go(this.leftRedirectionPath);
  }

  onRightTabClick(): void {
    this.state.go(this.rightRedirectionPath);
  }
}
