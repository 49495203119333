import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

import { MutualFundOrder, OrderPreview } from '../models';

@Injectable({
  providedIn: 'root',
})
export class MutualFundService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'trading/orders/mutualfunds');
  }

  previewMutualFundOrder(order: MutualFundOrder): Observable<OrderPreview> {
    return this.post<OrderPreview>('preview', order).pipe(map(result => result.data));
  }

  placeMutualFundOrder(order: MutualFundOrder): Observable<{ orderReference: string }> {
    return this.post<{ orderReference: string }>('', order).pipe(map(result => result.data));
  }
}
