import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { RewardsCheckingCharts, RewardsCheckingCriterias } from '@app/accounts/models/rewards-detail.model';

@Component({
  selector: 'app-rewards-criteria',
  templateUrl: './rewards-criteria.component.html',
  styleUrls: ['./rewards-criteria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsCriteriaComponent implements OnInit {
  @Input() rewardsCheckingCriterias: RewardsCheckingCriterias[];
  @Input() rewardsCheckingCharts: RewardsCheckingCharts;
  @Input() requiredComplete: boolean;
  constructor() {}
  ngOnInit(): void {}
}
