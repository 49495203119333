import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Company } from '@app/axos-trading/models';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-stock-company',
  templateUrl: './stock-company.component.html',
  styleUrls: ['./stock-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockCompanyComponent implements OnInit {
  @Input() data: Company;
  icons = {
    ChevronUp: NavigationIcons.ChevronUp,
    ChevronDown: NavigationIcons.ChevronDown,
  };
  isExpanded = false;
  constructor() {}

  ngOnInit(): void {}
}
