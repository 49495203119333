import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FeatureFlagService } from 'services/feature-flag.service';
import { FacingBrand } from 'models';
import { ROOT_SCOPE, olbSettings } from '@core/tokens';
import { CurrencyPipe } from '@angular/common';
import { TxTableOptionActions } from '@app/accounts/components/account-transactions/enums';
import { TxOptionType } from '@app/accounts/components/account-transactions/types/tx-table-option.type';
import { SvgIconHelperService } from '@core/services';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-tx-row',
  templateUrl: './tx-row.component.html',
  styleUrls: ['./tx-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TxRowComponent implements OnInit {
  @Input() id: number;
  @Input() isChild: boolean;
  @Input() isDisputeActive: boolean;
  @Input() isLoan: boolean;
  @Input() trxn: Transaction;
  @Input() isAccountActive: boolean;
  @Input() isAccountAggregationForInternalsActive = false;
  @Input() isPfm3InternalTransActive = false;
  @Input() isMultiple: boolean;
  @Output() onGetCheck = new EventEmitter<Transaction>();
  @Output() onCollapseEvent = new EventEmitter<void>();
  @Output() onOptionSelected = new EventEmitter<TxOptionType>();
  @Output() onRowSelected = new EventEmitter<any>();

  accountOptions: TxOptionType[] = [];
  categoryIcon: SafeHtml = '';

  constructor(
    @Inject(olbSettings) public env: OlbSettings,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private currencyPipe: CurrencyPipe,
    private featureFlagService: FeatureFlagService,
    private svgIconHelper: SvgIconHelperService,
    private changeDetector: ChangeDetectorRef
  ) {}

  // Initializes any required data
  ngOnInit() {
    this.isAccountAggregationForInternalsActive = this.featureFlagService.isAccountAggregationForInternalsActive();
    this.isPfm3InternalTransActive = this.featureFlagService.isPFM3InternalTrans();

    if (this.isDisputeActive && this.isAccountActive && !this.trxn.dispute.key) {
      this.accountOptions.push(
        {
          label: TxTableOptionActions.disputeTransaction,
          isCollapsible: true,
        },
        {
          label: TxTableOptionActions.dismuteMultipleTransactions,
          isCollapsible: false,
        }
      );

      if (this.isAccountAggregationForInternalsActive && !this.trxn.isPending && this.transactionIsNotPending()) {
        this.accountOptions.push({
          label: TxTableOptionActions.recategorizeTransaction,
          isCollapsible: true,
        });
      }
    }

    if (this.trxn.childDetails && this.trxn.childDetails.length) {
      this.accountOptions.push({
        label: TxTableOptionActions.viewDetails,
        icon: 'fa fa-file-text-o',
        isCollapsible: true,
      });
    }

    if (this.trxn.childDetails && this.trxn.childDetails.length && this.isDisputeActive) {
      this.accountOptions.splice(2, 0, { label: TxTableOptionActions.empty, isDivider: true });
    }
    this.getImageIcon();
    this.changeDetector.detectChanges();
  }

  //Gets the account balance depending if filters are default or not
  getAccountBalance(): string {
    return this.trxn.order < 2 ? this.currencyPipe.transform(this.trxn.balance) : '--';
  }

  //Triggers the on select option defined
  selectOption(option: TxOptionType) {
    this.onOptionSelected.emit(option);
  }

  //Triggers the selected row function defined
  selectRow() {
    this.trxn.isSelected = !this.trxn.isSelected;
    this.onRowSelected.emit({ trxn: this.trxn });
  }

  disputeMessage() {
    let url = '';
    switch (this.env.facingBrandId) {
      case FacingBrand.Axos:
        url = this.env.facingBrandsUrls['axos'];
        break;
      case FacingBrand.Nationwide:
        url = this.env.facingBrandsUrls['nationwide'];
        break;
      case FacingBrand.UFB:
        url = this.env.facingBrandsUrls['ufb'];
    }

    url = `${url.substr(0, url.indexOf('auth'))}Messages/message-detail/${this.trxn.dispute.key}/${
      this.trxn.dispute.value
    }/true/message`;

    let msg = '';
    if (this.trxn.dispute.key && this.trxn.dispute.value) {
      if (!this.trxn.dispute.value.toLocaleLowerCase().includes('closed')) {
        msg = `This transaction is in the process of being disputed. <a href="${url}">Track your claim</a>`;
      } else {
        msg = `A determination on your dispute has been made. <a href="${url}">Review it here </a>`;
      }
    } else if (!this.trxn.dispute.key && !this.trxn.isDisputable) {
      msg = 'This transaction is not disputable';
    }
    return msg;
  }

  hideTransactionsDisputedMark(): boolean {
    return this.root['appSettings'].find((ap: any) => ap.name === 'HideTransactionsDisputedMark').value == 'true';
  }

  transactionIsNotPending(): boolean {
    const categoryName = this.trxn.olbCategoryName.toLowerCase();

    return categoryName !== 'pending';
  }

  showDetails(trxn: Transaction) {
    if (!this.isPfm3InternalTransActive) return;
    if (!trxn.isPending) trxn.isDetailsExpanded = !trxn.isDetailsExpanded;
  }

  convertDisabled(key: string) {
    const disabled = ['disable', 'off', 'false'];
    return disabled.includes(key);
  }

  onCollapse() {
    this.onCollapseEvent.emit();
  }

  getCheck(transaction: Transaction) {
    if (transaction.hasCheck) {
      this.onGetCheck.emit(transaction);
    }
  }

  // getImagePath() {
  private getImageIcon() {
    let categoryName = this.trxn.olbCategoryName.toLowerCase();
    const idx = categoryName.indexOf(' ');
    categoryName = idx < 0 ? categoryName : categoryName.substring(0, idx);

    const icon = `${categoryName}_white`;
    this.svgIconHelper.loadSvgIcon('categories', icon).subscribe(icon => {
      this.categoryIcon = icon;
      this.changeDetector.detectChanges();
    });
  }
}
