import { createSelector } from '@ngrx/store';

import { AccountCategory, categoryMap } from '@app/accounts/enums';

import { getAccountsState } from '../accounts.state';

export const getInternalAccountsState = createSelector(getAccountsState, state => state.internal);
export const getInternalCheckingAccountsList = createSelector(getInternalAccountsState, state =>
  Object.values(state.checking)
);
export const getInternalSavingsAccountsList = createSelector(getInternalAccountsState, state =>
  Object.values(state.savings)
);
export const getInternalDepositAccountsList = createSelector(getInternalAccountsState, state => {
  return [...Object.values(state.checking), ...Object.values(state.savings)];
});
export const getInternalLoanAccountsList = createSelector(getInternalAccountsState, state => Object.values(state.loan));
export const getInternalCdIraAccountsList = createSelector(getInternalAccountsState, state => Object.values(state.cd));
export const getInternalCreditAccountsList = createSelector(getInternalAccountsState, state =>
  Object.values(state.credit)
);
export const getInternalOtherAccountsList = createSelector(getInternalAccountsState, state =>
  Object.values(state.other)
);
export const getInternalUnknownAccountsList = createSelector(getInternalAccountsState, state =>
  Object.values(state.unknown)
);

/**
 * Gets an specific internal account from the state.
 * If you want to get an aggregated account, please use the "getAggregatedAccount" selector.
 *
 * @param category Account category.
 * @param accountId Account ID.
 *
 * @returns The account that matches the given ID in the given category.
 */
export function getInternalAccount(category: AccountCategory, accountId: number) {
  const selectedCategory = categoryMap[category];

  return createSelector(getInternalAccountsState, state => state[selectedCategory][accountId]);
}
