import { downgradeComponent } from '@angular/upgrade/static';
import { SmsModalsComponent } from '@app/alerts-notifications/my-alerts/Components/Index';
import { MyAlertsComponent } from '@app/alerts-notifications/my-alerts/Components/Index';

import { Inject } from '@legacy/decorators/decorators';

@Inject('$stateProvider')
export class myAlertsLegacyConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.SmsModals', {
      url: '/SmsModals',
      views: {
        content: {
          template: '<app-sms-modals></app-sms-modals>',
        },
      },
    }),
      stateProvider.state('udb.alertsNotifications.myAlerts', {
        url: '/MyAlerts',
        views: {
          'alerts-notifications': {
            template: '<app-my-alerts></app-my-alerts>',
          },
        },
      });
  }
}

export const myAlertsLegacyModule = angular
  .module('udb.myAlertsLegacy', ['ui.router'])
  .directive('appSmsModals', downgradeComponent({ component: SmsModalsComponent }))
  .directive('appMyAlerts', downgradeComponent({ component: MyAlertsComponent }))

  .config(myAlertsLegacyConfig).name;
