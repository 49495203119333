export class Position {
  symbol: string;
  assetName: string;
  marketValue: number;
  dayChange: number;
  dayChangePercentage: number;
  todaysReturn: number;
  todaysReturnPercent: number;
  totalReturn: number;
  totalReturnPercent: number;
  shares: number;
  totalValue: number;
  // Average cost is the price at which the user acquired their shares (per share)
  price: number;
  // Average cost is the price at which the user acquired their shares (total for 100 shares)
  totalCost: number;
  percentageOfPortfolio: number;

  constructor(args?: Partial<Position>) {
    Object.assign(this, args);
  }
}
