import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Document, DocumentDetail } from '@app/accounts/models';
import { saveFile } from '@app/utils';
import { DeliveryPreferences } from '@core/models/delivery-preferences.model';
import { DocumentsService } from '@core/services';
import { STATE_PARAMS } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { FilesIcons } from '@shared/enums';

@Component({
  selector: 'app-statement-tax-forms',
  templateUrl: './statement-tax-forms.component.html',
  styleUrls: ['./statement-tax-forms.component.scss'],
})
export class StatementTaxFormsComponent implements OnInit {
  @Input() account: OlbAccount;
  accountId: number;
  error: boolean;
  loading: boolean;
  selectedYear: number;
  statementDocuments: DocumentDetail[] = [];
  statementsIcon = FilesIcons.FilePdf;
  taxDocuments: DocumentDetail[] = [];
  years: number[];
  deliveryPref: number;
  displayStatementOptions: boolean;
  isDownloading: boolean;
  docID: string;
  private statements: Document;
  private taxForms: Document;

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private documentsService: DocumentsService,
    private featureFlagService: FeatureFlagService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.displayStatementOptions =
      this.featureFlagService.isBlendFlagActive() && this.account.isLoan;
    this.loadStatementsAndTaxes();
  }

  loadStatementsAndTaxes() {
    this.loading = true;
    const calls = [
      this.documentsService.getDocuments(this.accountId, '?x'),
      this.documentsService.getDocuments(
        this.accountId,
        '?docTypes=x&docTypes=f'
      ),
      this.documentsService.getDeliveryPreferences(this.accountId),
    ];

    if (!this.displayStatementOptions) {
      calls.pop();
    }

    forkJoin(calls)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: ([statements, taxes, deliveryPreferences]) => {
          this.statements = statements.data as Document;
          this.taxForms = taxes.data as Document;
          if (deliveryPreferences) {
            const delivery = deliveryPreferences.data as DeliveryPreferences;
            this.deliveryPref = delivery.deliveryPreference;
          }

          this.processDocuments();
        },
        error: () => {
          this.error = true;
        },
      });
  }

  processDocuments() {
    const allDocuments = [
      ...this.statements.documents,
      ...this.taxForms.documents,
    ];
    this.years = allDocuments
      .reduce((previous, current) => {
        const year = new Date(current.doc_date.toString()).getFullYear();
        if (!previous.includes(year)) {
          previous.push(year);
        }

        return previous;
      }, [])
      .sort((a: number, b: number) => {
        return a < b ? 1 : b < a ? -1 : 0;
      });

    this.selectedYear = new Date().getFullYear();
    if (this.years.length > 0) {
      this.filterDocumentsByYear(this.selectedYear);
    }
  }

  filterDocumentsByYear(year: number) {
    this.selectedYear = year;
    this.statementDocuments = this.statements.documents
      .filter(document => {
        const documentYear = new Date(
          document.doc_date.toString()
        ).getFullYear();

        return documentYear === this.selectedYear;
      })
      .sort((a, b) => {
        return a.doc_date < b.doc_date ? 1 : b.doc_date < a.doc_date ? -1 : 0;
      });

    this.taxDocuments = this.taxForms.documents.filter(document => {
      const documentYear = new Date(document.doc_date.toString()).getFullYear();

      return documentYear === this.selectedYear;
    });
  }

  showTax(documentId: string, key: string, documentDate: string): void {
    const lastFourDigits = this.account.accountNumber.substring(
      this.account.accountNumber.length - 4
    );
    const fileName = `TAX_${lastFourDigits}_${documentDate}`;
    this.documentsService.getTaxForm(documentId, key, fileName).subscribe({
      next: response => {
        saveFile(response, `${fileName}.pdf`);
      },
    });
  }

  showStatement(documentId: string, key: string, documentDate: string): void {
    this.isDownloading = true;
    this.docID = documentId;
    const lastFourDigits = this.account.accountNumber.substring(
      this.account.accountNumber.length - 4
    );
    const fileName = `STMT_${lastFourDigits}_${formatDate(
      documentDate,
      'yyyyMMdd',
      'en-US'
    )}`;
    this.documentsService
      .getStatement(this.accountId, documentId, key, fileName, false)
      .subscribe({
        next: response => {
          saveFile(response, `${fileName}.pdf`);
          this.isDownloading = false;
        },
      });
  }

  updateDeliveryPreferences() {
    const deliveryPreference: DeliveryPreferences = {
      accountId: this.accountId,
      deliveryPreference: this.deliveryPref,
    };
    this.loading = true;
    this.documentsService
      .updateDeliveryPreferences(deliveryPreference)
      .subscribe({
        next: response => {
          this.loading = false;
          this.deliveryPref = response.data.deliveryPreference;
          this.loadStatementsAndTaxes();
        },
        error: () => {
          this.error = true;
        },
      });
  }
}
