import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatCardModule } from '@angular/material/card';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { Uk2DirectivesModule } from '@axos/uikit-v2-lib/src/lib/uk2-directives';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { Uk2ToastModule, Uk2TooltipModule } from '@axos/uikit-v2-lib';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';

export const materialImports = [
  MatMenuModule,
  MatDialogModule,
  MatCardModule,
  Uk2DirectivesModule,
  CdkStepperModule,
  MatCheckboxModule,
  MatListModule,
  MatIconModule,
  MatButtonModule,
  MatDividerModule,
  Uk2TooltipModule,
  MatSnackBarModule,
  Uk2ToastModule,
  MatGridListModule,
  MatTooltipModule,
];
