export function objectToFormData(model: any, name = '', form: FormData = null): FormData {
  const formData = form || new FormData();
  for (const propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
    const formKey = name ? `${name}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName].toISOString());
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        objectToFormData(element, tempFormKey, formData);
      });
    } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
      objectToFormData(model[propertyName], formKey, formData);
    } else formData.append(formKey, model[propertyName].toString());
  }

  return formData;
}
