import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ROOT_SCOPE, STATE } from '@core/tokens';
import { ModalComponent } from './modal/modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Uk2ToastComponent, Uk2ToastTypeEnum } from '@axos/uikit-v2-lib';
import { RegisterUserAction } from '@app/sbloc-offers/utils/registerUserAction';
import { SblocService } from '@app/sbloc-offers/services/sbloc.service';
import { IRootScopeService } from 'angular';
import { BehaviorSubject } from 'rxjs';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { SBlocApplicationRequest } from '@app/sbloc-offers/Models';

@Component({
  selector: 'app-acceptance-criteria',
  templateUrl: './acceptance-criteria.component.html',
  styleUrls: ['./acceptance-criteria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptanceCriteriaComponent implements OnInit, OnDestroy {
  apr: number = 0;
  amount: number = 0;
  emails: string[] = ['applicants@email.com', 'joint@email.com'];
  isLoading$ = new BehaviorSubject<boolean>(true);
  margin: number = 0;
  libertyAccountNumber: string = '';
  processing$ = this._sblocService.processingApplication;

  constructor(
    private readonly _sblocService: SblocService,
    @Inject(STATE) private state: ng.ui.IStateService,
    // private bodyRef: ElementRef,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private registerUserAction: RegisterUserAction,
    @Inject(ROOT_SCOPE) private rootScope: IRootScopeService,
    private modalService: ModalService
  ) {}
  ngOnDestroy(): void {
    angular.element((document.querySelector('footer').style.display = 'block'));
  }
  ngOnInit(): void {
    angular.element((document.querySelector('footer').style.display = 'none'));
    angular.element((document.querySelector('body').style.overflowX = 'hidden'));

    this.apr = this._sblocService.getSubmittedValues().apr;
    this.amount = this._sblocService.getSubmittedValues().amount;
    this.margin = this._sblocService.getSubmittedValues().margin;
    this.libertyAccountNumber = this._sblocService.getSubmittedValues().account;
    if (this.apr == 0 && this.amount == 0 && this.margin == 0) this.state.go('udb.dashboard');

    this._sblocService
      .getJointEmail(this._sblocService.getSubmittedValues().account)
      .toPromise()
      .then(response => {
        if (response.statusCode == 200) {
          this.isLoading$.next(false);
          this.emails[0] = this.rootScope['profileInfo'].primaryEmail;
          this.emails[1] = response.data;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  goPreApprovedOffer(): void {
    this.state.go('udb.preApproved');
  }

  openModal() {
    let dialogConfig: MatDialogConfig = {
      data: {
        principalEmail: this.emails[0],
        jointEmail: this.emails[1] ? this.emails[1] : '',
        apr: this.apr,
        amount: this.amount,
        account: this.libertyAccountNumber,
        margin: this.margin,
      },
    };

    this.dialog.open(ModalComponent, dialogConfig);
  }

  submit() {
    this._sblocService.processingApplication.next(true);

    let sBlocApplicationRequest: SBlocApplicationRequest = {
      rate: this.apr,
      amount: this.amount,
      libertyAccountNumber: this.libertyAccountNumber,
      margin: this.margin,
    };

    this._sblocService
      .submitOffer(sBlocApplicationRequest, this._sblocService.getUtmCodes())
      .toPromise()
      .then(response => {
        if (response.data.success) {
          this._snackBar.openFromComponent(Uk2ToastComponent, {
            panelClass: ['uk2-toast.uk2-toast-success'],
            duration: 5000,
            data: {
              message: 'Please check your email and follow the instructions to complete your account setup.',
              type: Uk2ToastTypeEnum.success,
            },
          });

          this.registerUserAction
            .registerEmailMeDocuments(
              this.apr.toString(),
              this.amount.toString(),
              this.emails[0],
              this.emails[1] ? this.emails[1] : ''
            )
            .subscribe(() => {
              this.state.go('udb.dashboard');
            });
        } else {
          if (response.data.message == 'The application already exists, Please review your data') {
            this._snackBar.openFromComponent(Uk2ToastComponent, {
              panelClass: ['uk2-toast.uk2-toast-alert'],
              duration: 5000,
              data: {
                message:
                  'This is a duplicate request. Please check your email and follow the instructions to complete account setup',
                type: Uk2ToastTypeEnum.alert,
              },
            });

            this.state.go('udb.dashboard');
          } else {
            this.showErrorModal();
          }
        }
      })
      .catch(() => {
        this.showErrorModal();
      })
      .finally(() => {
        this._sblocService.processingApplication.next(false);
      });
  }

  showErrorModal() {
    const defaultMessage =
      '<p class="something-text">Something went wrong.</p><br/><p> Something unexpected went wrong on our end. Please try again. Thank you.<p>';
    const modalSettings: ModalSettings = {
      icon: 'bofi-cancel',
      bodyText: defaultMessage,
      okText: 'Close and try again later',
      hasCancelButton: false,
    };

    this.modalService
      .show({ windowClass: 'modal-service internal-error-modal' }, modalSettings)
      .then(() => {
        this.state.go('udb.dashboard');
      })
      .catch(() => {});
  }
}
