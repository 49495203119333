import { createFeatureSelector } from '@ngrx/store';

import { AppStateFeatures } from '@core/enums';

import { FinancialCatalogsState } from './financial-catalogs/financial-catalogs.state';
import { ProfileDetailsState } from './profile-details/profile-details.state';
export class UserProfileState {
  maritalStatuses: Record<string, string>;
  financialCatalogs: FinancialCatalogsState;
  employmentStatuses: Record<string, string>;
  profileDetails: ProfileDetailsState;

  constructor(data?: Partial<UserProfileState>) {
    Object.assign(this, data);
  }
}

export const getUserProfile = createFeatureSelector<UserProfileState>(AppStateFeatures.UserProfile);
