<p class="mb-3">Card will be delivered to this address:</p>

<ng-container *ngIf="!showForm; else addressFields">
  <p id="debit-card-address" [innerText]="address | address"></p>
  <button
    id="change-address"
    type="button"
    class="link d-inline-flex align-items-center mt-1"
    (click)="editAddress()"
    [disabled]="!enabled"
  >
    <app-svg-icon [iconName]="pencilIcon" class="mr-1" size="1em"></app-svg-icon>
    Change Address
  </button>
</ng-container>

<ng-template #addressFields>
  <div cdkTrapFocus>
    <app-address [parentForm]="addressForm" [addressType]="0"></app-address>

    <button id="save-btn" type="button" class="link" (click)="emitAddress()">Save</button>
    <span class="mx-2">|</span>
    <button id="cancel-btn" type="button" class="link" (click)="cancel()" [disabled]="addressIsEmpty">Cancel</button>
  </div>
</ng-template>
