import { Inject } from '../../decorators/decorators';

@Inject('$stateProvider')
export class InboxConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.messages.inbox', {
      url: '/Inbox',
      views: {
        messagesContent: {
          template: '<app-inbox-component></app-inbox-component',
        },
      },
    });
  }
}
