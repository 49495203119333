import { IScope } from 'angular';
import { IStateService } from 'angular-ui-router';
import { Inject } from 'decorators/decorators';

@Inject('$state', '$scope')
export class LocalAlertController {
  shouldBeDisplayed = false;
  validRoutes: string[] = [
    'udb.dashboard',
    'udb.accounts.dashboard',
    'udb.accounts.details',
    'udb.makedeposit',
    'udb.userProfile',
    'udb.alertsNotifications.myAlerts',
    'udb.myBills',
    'udb.myBills.scheduledPayments',
    'udb.myBills.makePayment',
    'udb.myBills.paymentsDue',
    'udb.myBills.paymentHistory',
    'udb.billPay',
    'udb.billPay.pay',
    'udb.billPay.activity',
    'udb.billPay.welcome',
    'udb.transfers',
    'udb.transfers.transferFunds',
    'udb.transfers.schedulerTransfers',
    'udb.transfers.p2p.sendMoney',
    'udb.transfers.wireTransfers',
    'udb.support',
    'udb.messages.inbox',
    'auth.login'
  ];
  constructor(private readonly state: IStateService, private readonly scope: IScope) {}
  $onInit(): void {
    this.shouldBeDisplayed = this.validRoutes.includes(this.state.current.name);
    this.scope.$on('$stateChangeSuccess', () => {
      this.shouldBeDisplayed = false;
      setTimeout(() => {
        this.shouldBeDisplayed = this.validRoutes.includes(this.state.current.name);
      });
    });
  }
}
