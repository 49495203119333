<div class="d-none d-md-flex amount">
  <div class="encode-sans">
    <div class="header-title">Total Net Worth</div>
    <ng-container *ngIf="currentNetWorth">{{ currentNetWorth.worth.net | currency }}</ng-container>
    <ng-container *ngIf="!currentNetWorth">$--</ng-container>
  </div>
</div>
<div class="info" *ngIf="currentNetWorth && previousNetWorth">
  <div class="date">
    <span>Since {{ previousNetWorth?.date | date: 'MMM yyyy' }}</span>
  </div>
  <div class="values">
    <app-svg-icon
      *ngIf="percentageChange != 0"
      [iconName]="getPercentageArrow()"
      [styles]="{ width: '10.6px', height: '8px', fill: '#494949' }"
    ></app-svg-icon>
    {{ percentageChange | percentageRound }}%
  </div>
  <div *ngIf="!shouldShowCents()" class="values italic">
    {{ showSignValue() }}{{ dollarChange | currency: 'USD':'symbol':'1.0-0' }}
  </div>
  <div *ngIf="shouldShowCents()" class="values italic">
    {{ showSignValue() }}{{ dollarChange | currency: 'USD':'symbol':'1.2-2' }}
  </div>
</div>
