<p class="mb-4 text-center font-weight-normal col-12">
  In order to change your password, please re-enter your current password.
</p>

<form class="change-financial-info" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
  <app-alert-notification class="d-block mb-4" *ngIf="errorMessage" alertType="error" (dismiss)="errorMessage = null">
    {{ errorMessage }}
  </app-alert-notification>

  <div class="form-group" [class.invalid-field]="currentPass.hasError('*')">
    <div class="reverse">
      <input
        id="current-password"
        class="uk-input"
        type="password"
        name="current-password"
        formControlName="currentpassword"
        placeholder="Current Password"
      />
      <app-reveal-input-label target="current-password">
        Current Password
      </app-reveal-input-label>
    </div>

    <div aria-live="polite" role="alert" class="mt-1 text-error" ngxErrors="currentpassword" #currentPass="ngxErrors">
      <span ngxError="required">
        Please enter your current password.
      </span>
    </div>
  </div>

  <div class="form-group" [class.invalid-field]="newPassword.hasError('*')">
    <div class="reverse">
      <input
        id="password"
        class="uk-input"
        type="password"
        name="password"
        formControlName="password"
        placeholder="Create Password"
      />
      <app-reveal-input-label target="password">
        Create Password
      </app-reveal-input-label>
    </div>
    <div aria-live="polite" role="alert" class="mt-1 text-error" ngxErrors="password" #newPassword="ngxErrors">
      <span ngxError="maxlength">
        The password cannot have more than 25 characters.
      </span>
    </div>
    <app-password-strength [passwordField]="changePasswordForm.get('password')"></app-password-strength>
  </div>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="changePasswordForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
