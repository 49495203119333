import { IScope } from 'angular';

import { Inject } from '../decorators/decorators';
import { PayBillsHelper } from './pay-bills.helper';

@Inject('$scope', 'payBillsHelper')
export class BillPayController {
  isEnrolled = false;
  showErrorEmail = false;
  isVerifyingEnrollment = false;
  balancesAvailable: boolean = false;
  numberPaymentsDue: number;
  constructor(private readonly scope: IScope, private readonly payBillsHelper: PayBillsHelper) {}
  $onInit(): void {
    this.scope.$on('numberPaymentsDue', (_evt, payments) => {
      this.numberPaymentsDue = payments;
    });
    // We need to wait until balances are available for SBB pay bill CTA.
    this.scope.$on('balancesAvailable', ( ) =>{
      this.balancesAvailable = true; 
    } );

    if (this.payBillsHelper.needEnrollment()) {
      this.isVerifyingEnrollment = true;
      this.payBillsHelper.enrollInBillPay(this.finishEnrollment.bind(this));

      return;
    }

    this.isEnrolled = this.payBillsHelper.isEnrolledInBillPay();
    this.showErrorEmail = this.payBillsHelper.isInvalidEmail();
  }

  private finishEnrollment(isEnrolled: boolean, isInvalidEmail: boolean): void {
    this.isEnrolled = isEnrolled;
    this.showErrorEmail = isInvalidEmail;
    this.isVerifyingEnrollment = false;
  }
}

/**
 * Returns the types of accounts which could be charge per frequency
 */
export function getAccountTypes(): string[] {
  return ['money market', 'savings'];
}
