import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  Uk2ModalActionsGridEnum,
  Uk2ModalComponent,
  Uk2ModalFontWeightEnum,
  Uk2ModalTitleAlignEnum,
} from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-understanding-holdings-modal',
  templateUrl: './understanding-holdings-modal.component.html',
  styleUrls: ['./understanding-holdings-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnderstandingHoldingsModalComponent implements OnChanges {
  @Input() showUnderstandingHoldingsModal = false;
  @Output() hideUnderstandingHoldingsModal = new EventEmitter();

  @ViewChild('modal') modal?: Uk2ModalComponent;

  showCloseButton = true;
  titleAlign = Uk2ModalTitleAlignEnum.center;
  title = 'Understanding Holdings';
  disabledCloseOverlayBackdrop = false;
  titleFontWeight = Uk2ModalFontWeightEnum.bold300;
  actionsGrid = Uk2ModalActionsGridEnum.side;
  innerPanelClass = 'understanding-holdings-modal-container';

  indicationsList = [
    `Ticker symbol of a holding`,
    `Total shares owned of your holding`,
    `Total value of your holding`,
    `Today’s value movement of your holding`,
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.showUnderstandingHoldingsModal.firstChange &&
      changes.showUnderstandingHoldingsModal.currentValue === true
    ) {
      this.modal?.openDialog();
    }
  }

  closeModal() {
    this.hideUnderstandingHoldingsModal.emit();
  }
}
