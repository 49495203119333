<div class="debit-card-history">
  <h4 class="section__title mb-3">Activity History</h4>
  <app-dot-loader *ngIf="loading">Loading History</app-dot-loader>

  <p *ngIf="!activityLog.length">
    No history information has been found.
  </p>

  <div class="debit-card-history__header d-flex flex-wrap">
    <p class="col-12 d-block d-lg-none">Activity</p>
    <p class="col-1 d-none d-lg-flex text-left"></p>
    <p class="col-3 d-none d-lg-flex text-left">Date Submitted</p>
    <p class="col-4 d-none d-lg-flex text-left">Description</p>
    <p class="col-2 d-none d-lg-flex text-left">Status</p>
    <p class="col-2 d-none d-lg-flex text-left"></p>
  </div>

  <div class="debit-card-history__item d-flex flex-column flex-lg-row" *ngFor="let activity of activityLog">
    <div class="col-1 text-center d-none d-lg-inline">
      <app-svg-icon [iconName]="activity.icon" [size]="iconConfig.size" iconColor="var(--primary)"></app-svg-icon>
    </div>

    <p class="col-9 col-lg-3 text-left debit-card-history__date" [innerText]="activity.date | date: 'MM/dd/yyyy'"></p>
    <p class="col-9 col-lg-4 text-left" [innerText]="activity.description"></p>
    <p class="col-9 col-lg-2 text-left" [innerText]="activity.status"></p>

    <div class="col-12 col-lg-2 text-center">
      <button type="button" (click)="delete.emit(activity.id)" class="debit-card-history__remove link">
        <app-svg-icon
          iconColor="var(--link)"
          [iconName]="iconConfig.trash.icon"
          [size]="iconConfig.trash.size"
        ></app-svg-icon>
        Remove
      </button>
    </div>
  </div>
</div>
