export enum FilesIcons {
  FileBlank = 'files-file-blank',
  File = 'files-file',
  FilePlus = 'files-file-plus',
  FilePdf = 'files-file-pdf',
  FileRibbon = 'files-file-ribbon',
  Clipboard = 'files-clipboard',
  ClipboardCheck = 'files-clipboard-check',
  Scroll = 'files-scroll',
  Picture = 'files-picture',
  Presentation = 'files-presentation',
  PencilWriting2 = 'files-pencil-writing-2',
  Pencil = 'files-pencil',
  PencilWriting = 'files-pencil-writing',
  Disk = 'files-disk',
  Printer = 'files-printer',
  DownloadFile = 'files-download-file',
}
