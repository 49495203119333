import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { differenceInDays, format, parseISO } from 'date-fns';

import { GOAL_ICONS } from '@app/axos-invest/constants';
import { GoalType } from '@app/axos-invest/enums';
import { Filters, Goal, PastPerformance, PastPerformanceHistoricalItem, Transaction } from '@app/axos-invest/models';
import { AxosInvestService, AxosInvestUrlsService } from '@app/axos-invest/services';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { AxosInvestIcons, FilesIcons, NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-goal-summary',
  templateUrl: './goal-summary.component.html',
  styleUrls: ['./goal-summary.component.scss'],
})
export class GoalSummaryComponent implements OnInit, OnDestroy {
  @Input() milestoneData: Goal;
  @Output() changeTab = new EventEmitter<number>();

  transactions: Transaction[];
  milestoneId: string;
  isLoading = false;
  isLoadingTransactions = false;
  pastPerformance: PastPerformance;
  dayChangePercentage: number;
  dayChangeAmount: number;
  viewPerformanceDisclaimer = false;
  data: number[] = [];
  dataLabels: string[] = [];
  timeLabel: string[] = [];
  referrerTabId = 0;

  get goalIcon() {
    return GOAL_ICONS[this.milestoneData.type];
  }
  editGoalAxosInvest = [GoalType.Custom, GoalType.Retirement, GoalType.RainyDay];

  icons = {
    forward: NavigationIcons.ArrowForward,
    edit: FilesIcons.Pencil,
    goalType: null,
    badge: AxosInvestIcons.BadgeIcon,
    triangleUp: NavigationIcons.TriangleUp,
    triangleDown: NavigationIcons.TriangleDown,
    equal: NavigationIcons.Equal,
    down: NavigationIcons.ChevronDown,
  };
  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private axosInvestService: AxosInvestService,
    private axosInvestUrlsService: AxosInvestUrlsService
  ) {}

  ngOnInit(): void {
    this.milestoneId = this.params['id'];
    this.getMilestoneData();
    this.getTransactions();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  getPastPerformanceData() {
    this.axosInvestService
      .getPastPerformance(this.milestoneId)
      .pipe(
        finalize(() => {
          this.calculateChartData();
          this.isLoading = false;
        })
      )
      .subscribe(response => {
        this.pastPerformance = response.data.client;
        const historicalResponseLength = this.pastPerformance.historicalResponse.length;
        let previousDay: PastPerformanceHistoricalItem = this.pastPerformance.historicalResponse[
          historicalResponseLength - 2
        ];
        const currentDay: PastPerformanceHistoricalItem = this.pastPerformance.historicalResponse[
          historicalResponseLength - 1
        ];
        if (!previousDay) {
          previousDay = { close: 0, contributions: 0 };
        }
        this.dayChangeAmount =
          currentDay.close - currentDay.contributions - (previousDay.close - previousDay.contributions);
        this.dayChangePercentage =
          (currentDay.close - currentDay.contributions - (previousDay.close - previousDay.contributions)) /
          previousDay.close;
      });
  }

  getMilestoneData() {
    this.isLoading = true;
    this.icons.goalType = this.goalIcon;
    this.getPastPerformanceData();
  }

  calculateChartData() {
    const date1 = parseISO(this.pastPerformance.historicalResponse[0].date);
    const date2 = parseISO(this.pastPerformance.historicalResponse.slice(-1).pop().date);
    const difference = differenceInDays(date2, date1);

    if (difference < 14 && difference >= 2) {
      this.pastPerformance.historicalResponse.map(day => {
        this.dataLabels.push(format(parseISO(day.date), 'MMM do, yyyy'));
        this.data.push(day.close);
        this.timeLabel.push('Value at the End of the Day');
      });
    } else if (difference >= 14 && difference < 61) {
      for (let i = 0; i < this.pastPerformance.historicalResponse.length; i += 7) {
        this.dataLabels.push(format(parseISO(this.pastPerformance.historicalResponse[i].date), 'MMMM do, yyyy'));
        this.data.push(this.pastPerformance.historicalResponse[i].close);

        this.timeLabel.push(
          `Value on ${format(parseISO(this.pastPerformance.historicalResponse[i].date), 'MMM do, yyyy')}`
        );
      }
    } else if (difference >= 61 && difference < 1095) {
      for (let i = 0; i < this.pastPerformance.historicalResponse.length; i += 30) {
        this.dataLabels.push(format(parseISO(this.pastPerformance.historicalResponse[i].date), 'MMMM'));
        this.data.push(this.pastPerformance.historicalResponse[i].close);

        this.timeLabel.push(
          `Value on ${format(parseISO(this.pastPerformance.historicalResponse[i].date), 'MMMM do, yyyy')}`
        );
      }
    } else if (difference > 1095) {
      for (let i = 0; i < this.pastPerformance.historicalResponse.length; i += 365) {
        this.dataLabels.push(format(parseISO(this.pastPerformance.historicalResponse[i].date), 'yyyy'));
        this.data.push(this.pastPerformance.historicalResponse[i].close);

        this.timeLabel.push(
          `Value on ${format(parseISO(this.pastPerformance.historicalResponse[i].date), 'MMMM do, yyyy')}`
        );
      }
    }
  }

  togglePerformanceDisclaimer() {
    this.viewPerformanceDisclaimer = !this.viewPerformanceDisclaimer;

    return this.viewPerformanceDisclaimer;
  }

  goToTransactions() {
    this.changeTab.emit(3);
  }

  redirectToEditGoal(goalType: GoalType) {
    if (this.editGoalAxosInvest.includes(goalType)) {
      this.state.go('udb.axosinvest.editgoal', {
        referrerTabId: 0,
      });
    } else {
      this.subsink.sink = this.axosInvestUrlsService
        .getEditGoalUrl(this.milestoneId)
        .subscribe(url => window.open(url, '_blank', 'noopener noreferrer'));
    }
  }

  transferFunds() {
    this.state.go('udb.axosinvest.transferfunds', {
      id: this.milestoneId,
      referrerTabId: this.referrerTabId,
    });
  }

  private getTransactions() {
    this.isLoadingTransactions = true;
    const filters: Filters = {};
    filters.milestoneId = this.milestoneId;
    this.axosInvestService
      .getTransactions(filters)
      .pipe(
        finalize(() => {
          this.isLoadingTransactions = false;
        })
      )
      .subscribe(response => {
        this.transactions = response.data;
        this.transactions = this.transactions.slice(0, 4);
        this.isLoadingTransactions = false;
      });
  }

  calculatePercentagePerformance(pastPerformance: PastPerformance): number {
    const percentage =
      pastPerformance.marketValue - pastPerformance.totalDeposits > 0
        ? (pastPerformance.marketValue - pastPerformance.totalDeposits) / pastPerformance.totalDeposits
        : (pastPerformance.totalDeposits - pastPerformance.marketValue) / pastPerformance.totalDeposits;

    return !isFinite(percentage) ? 0 : percentage;
  }
}
