import { WiseBanyanService } from '@legacy/services/wise-banyan.service';

export function serviceHelperFactory($inject: any) {
  return $inject.get('wiseBanyanService');
}

export const wiseBanyanServiceProvider = {
  provide: WiseBanyanService,
  useFactory: serviceHelperFactory,
  deps: ['$injector'],
};
