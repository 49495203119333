import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fundingComponents } from './components';
import { SharedModule } from '@shared/shared.module';
import { customerLimitsProvider } from '@core/providers';
import { NgxMaskModule } from 'ngx-mask';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [...fundingComponents],
  imports: [CommonModule, SharedModule, NgxMaskModule.forRoot(), RouterLink],
  providers: [customerLimitsProvider],
})
export class FundingModule {}
