import { IPromise } from 'angular';
import {
  AggregatedAccount,
  AggregatedPayverisRequest,
  AuthenticatedInstitution,
  AuthenticationRequest,
  AuthenticationResponse,
  BankSearch,
  Institution,
} from 'typings/app/account-aggregation';
import { AggregatedSuccess } from 'typings/app/account-aggregation/AggregatedSuccess';
import { AggregatedTransaction } from 'typings/app/account-aggregation/AggregatedTransaction';
import { BeginPollableRefreshResult } from 'typings/app/account-aggregation/BeginPollableRefreshResult';
import { default as TransactionCategory } from 'typings/app/account-aggregation/Category';
import { PollRefreshResult } from 'typings/app/account-aggregation/PollRefreshResult';
import { RecategorizeTransactionRequest } from 'typings/app/account-aggregation/RecategorizeTransactionRequest';
import { TransactionRequest } from 'typings/app/account-aggregation/TransactionRequest';
import { UserAction } from 'typings/app/UserAction';

import { AddBulkVm } from '@legacy/dashboard/account-aggregation/typings/AddBulkVm';
import { AddExternalAccount } from '@legacy/dashboard/account-aggregation/typings/AddExternalAccount';

import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { FastlinkConfiguration } from '@legacy/typings/app/account-aggregation/FastlinkConfiguration';
import { FastlinkFlow } from '@legacy/dashboard/account-aggregation/enums/fast-link-flow.enum';
import { FastlinkErrorStatuses } from '@legacy/dashboard/account-aggregation/enums/fast-link-error-statuses.enum';
import { FastlinkOauthMigrationStatuses } from '@legacy/dashboard/account-aggregation/enums/fast-link-Oauth-Migration-Statuses-enum';
import { FastlinkActions } from '@legacy/dashboard/account-aggregation/enums/fast-link-actions.enum';
import { FastLinkValidStatuses } from '@legacy/dashboard/account-aggregation/enums/fast-link-valid-statuses.enum';
import { AggregatedError } from '@legacy/typings/app/account-aggregation/AggregatedError';
@Inject('$http', 'env', 'serviceHelper', '$q')
export class AccountAggregationService extends BaseService {
  readonly defaultErrorMsg =
    'We are experiencing an issue connecting to your financial institution.  Please try again later. If this problem persists, contact us for assistance.';
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'accountAggregation', _serviceHelper, q);
  }

  /**
   * Sync External accounts via FastLink
   *
   * @return {*}  {ApiResponse<string>}
   * @memberof AccountAggregationService
   */
  public syncFastLink(): ApiResponse<string> {
    return this.get('fastlink/syncAccounts');
  }

  /**
   * Get the fastlink configuration
   *
   * @param {FastlinkFlow} fastlinkFlow
   * @return {*}  {ApiResponse<FastlinkConfiguration>}
   * @memberof AccountAggregationService
   */
  public getFastlinkConfiguration(numFlow: number): ApiResponse<FastlinkConfiguration> {
    return this.get(`fastlink/connectioninfo/${numFlow}`);
  }

  /**
   * Get banks list.
   * @param bankName Param to perform a search for banks that contain a given string.
   * @returns A promise containing a list of the banks that contain the search string.
   */
  public searchBanks(bankName: string, page = 1, p: IPromise<any> = null): ApiResponse<BankSearch> {
    return this.get('search', { bankName, page }, null, null, null, null, p);
  }

  /**
   * Updates the given account's nickname.
   * @param accountId account's identifier
   * @param nickname new nickname for the account
   */
  public updateAccountNickname(accountId: string, nickname: string): ApiResponse<BankSearch> {
    return this.put(`${accountId}/${nickname}`);
  }

  /** Deletes an account by id
   * @param accountId account's identifier
   */
  public deleteAccount(accountId: string, name: string): ApiResponse<BankSearch> {
    return this.delete(accountId, { name });
  }

  /** Deletes the customer's accounts from a specific bank
   * @param accountId account's identifier
   */
  public deleteBankAccounts(bankId: number, providerId: number, providerAccountIds: number[]): ApiResponse<number[]> {
    return this.delete(`deleteBankAccounts/${bankId}`, { providerId, providerAccountIds });
  }

  public deleteBulkAccounts(
    providerId: number,
    providerAccountId: number,
    accounts: any,
    logCancel: boolean
  ): ApiResponse<number[]> {
    return this.post(`deleteBulkAccounts/${providerId}/${providerAccountId}?logCancel=${logCancel}`, accounts);
  }

  public addSelectedAccounts(vm: AddBulkVm): ApiResponse<any> {
    return this.post(`addSelectedAccounts`, vm);
  }

  /**
   * Sync the previously selected accounts via FastLink
   * @param vm Info from Fastlink
   * @param flow flow for fastlink
   */
  public syncFastLinkAccounts(vm: AddBulkVm, flow: FastlinkFlow): ApiResponse<any> {
    return this.post(`fastlink/addAccounts/${flow}`, vm);
  }

  /**
   * Gets an institution by id
   * @param bankId Bank's identifier
   */
  public getInstitution(bankId: string): ApiResponse<Institution> {
    return this.get(`institution/${bankId}`);
  }

  /**
   * Authenticates the user with the institution
   * @param authRequest Object containing the authentication info
   */
  public auth(justGetAuthStatus: boolean, authRequest: AuthenticationRequest): ApiResponse<AuthenticationResponse> {
    return this.post(`authenticate/${justGetAuthStatus}`, authRequest);
  }

  /**
   * Authenticates the user with the institution
   * @param authRequest Object containing the authentication info
   */
  public accountAggregationAuth(
    justGetAuthStatus: boolean,
    authRequest: AuthenticationRequest
  ): ApiResponse<AuthenticationResponse> {
    return this.post(`authenticate/aggregation/${justGetAuthStatus}`, authRequest);
  }

  /**
   * Authenticates the user with the institution
   * @param authRequest Object containing the authentication info
   */
  public authenticate(authRequest: AuthenticationRequest): ApiResponse<AuthenticatedInstitution> {
    return this.post('authenticate', authRequest);
  }

  /**
   * Get Aggregations external accounts
   * @returns A promise containing a list of Aggregation's external accounts.
   */
  public getAccounts(): ApiResponse<AggregatedAccount[]> {
    return this.get('allAccounts');
  }
  /**
   * Get Aggregation external account details
   */
  public getAccountDetails(accountId: number, container: string): ApiResponse<AggregatedAccount[]> {
    return this.get(`details/${accountId}/${container}`);
  }
  /**
   * Get Transactions by different filters
   * @param request
   */
  public getTransactions(request: TransactionRequest): ApiResponse<AggregatedTransaction[]> {
    return this.get('transactions', request);
  }

  public downloadTransactionsFile(request: TransactionRequest): ApiResponse<ArrayBuffer> {
    return this.get('download', request, false, null, 'arraybuffer');
  }
  /**
   * Add payment account to payveris
   * @param paymentRequest
   */
  public addPaymentAccount(paymentRequest: AggregatedPayverisRequest): ApiResponse<AggregatedSuccess> {
    return this.post('addPaymentAccounts', paymentRequest);
  }
  /*
   * Try to Add an aggregated account to payveris
   * eligible accounts for funding will try to be added to payveris
   */
  public addExternalAccount(addExternalAccount: AddExternalAccount): ApiResponse<ExternalAccount> {
    return this.post('addExternalAccount', addExternalAccount);
  }
  /** Update credentials of a provider
   * @param authRequest Object containing the authentication info
   */
  public updateProviderCredentials(authRequest: AuthenticationRequest): ApiResponse<AuthenticationResponse> {
    return this.put(`updateProviderCredentials`, authRequest);
  }

  /**
   * Used to fill the historic log in the API
   * @param action Action to save in the historic log
   */
  public logUserAction(action: UserAction): ApiResponse<void> {
    return this.post('audit', action);
  }

  public getCategories(): ApiResponse<TransactionCategory[]> {
    return this.get('categories');
  }
  /**
   *
   */
  public getPendigVerificationAccounts(): ApiResponse<ExternalAccount[]> {
    return this.get('pendingVerification');
  }

  public recategorizeTransaction(request: RecategorizeTransactionRequest): ApiResponse<any> {
    return this.put('transactions/recategorize', request);
  }

  public beginPollableRefresh(accountId: number): ApiResponse<BeginPollableRefreshResult> {
    return this.post(`${accountId}/begin-pollable-refresh`).catch(e => {
      throw e.data?.data;
    });
  }

  public pollRefresh(accountId: number, requestId: String): ApiResponse<PollRefreshResult> {
    return this.get(`${accountId}/poll-refresh?requestId=${requestId}`).catch(e => {
      throw e.data?.data;
    });
  }

  /**
  /* Returns an error message for the given status code and financial institution name.
  *
  * @param status The status code to check.
  * @param fiName The name of the financial institution.
  * @returns An error message for the given status code and financial institution name.
  */
  public getErrorMessage(status: string, fiName: string): AggregatedError {
    let aggregatedError: AggregatedError = {
      displayMessage: '',
      actionMessage: '',
      isFixableByReauthentication: false,
    };

    switch (status) {
      case FastlinkErrorStatuses.NEW_AUTHENTICATION_REQUIRED:
        aggregatedError.displayMessage = `${fiName} recently made a change that requires relinking of accounts.`;
        aggregatedError.actionMessage = FastlinkActions.RESTORE_CONNECTION;
        break;
      case FastlinkErrorStatuses.DATA_NOT_AVAILABLE:
        aggregatedError.displayMessage = `The requested account information is not available. Please contact ${fiName} for assistance.`;
        aggregatedError.actionMessage = '';
        break;
      case FastlinkErrorStatuses.SITE_NOT_SUPPORTED:
        aggregatedError.displayMessage = `The requested account information is not available. Please contact ${fiName} for assistance.`;
        aggregatedError.actionMessage = FastlinkActions.ADD_ANOTHER_ACCOUNT;
        break;

      case FastlinkErrorStatuses.ACCOUNT_LOCKED:
      case FastlinkErrorStatuses.ADDL_AUTHENTICATION_REQUIRED:
      case FastlinkErrorStatuses.CREDENTIALS_UPDATE_NEEDED:
      case FastlinkErrorStatuses.USER_ACTION_NEEDED_AT_SITE:
      case FastlinkOauthMigrationStatuses.CONSENT_EXPIRED:
      case FastlinkOauthMigrationStatuses.INCORRECT_OAUTH_TOKEN:
      case FastlinkOauthMigrationStatuses.CONSENT_REVOKED:
      case FastlinkOauthMigrationStatuses.CONSENT_REQUIRED:
        aggregatedError.displayMessage = `This account requires your attention. Please sign in to ${fiName}, perform any necessary updates, and then try again.`;
        aggregatedError.actionMessage = FastlinkActions.RESTORE_CONNECTION;
        break;

      case FastlinkErrorStatuses.DATA_RETRIEVAL_FAILED:
      case FastlinkErrorStatuses.REQUEST_TIME_OUT:
      case FastlinkErrorStatuses.SITE_BLOCKING_ERROR:
      case FastlinkErrorStatuses.UNEXPECTED_SITE_ERROR:
      case FastlinkErrorStatuses.SITE_UNAVAILABLE:
      case FastlinkErrorStatuses.TECH_ERROR:
      case FastlinkErrorStatuses.SITE_SESSION_INVALIDATED:
      case FastlinkErrorStatuses.DATASET_NOT_SUPPORTED:
        aggregatedError.displayMessage = `We are experiencing an issue connecting to ${fiName}. This issue is a result of a network issue at ${fiName} and typically resolves on its own in a few days. Please try again later.`;
        aggregatedError.actionMessage = FastlinkActions.ADD_ANOTHER_ACCOUNT;
        break;

      case FastlinkErrorStatuses.LOGIN_IN_PROGRESS:
      case FastlinkErrorStatuses.DATA_RETRIEVAL_IN_PROGRESS:
      case FastlinkErrorStatuses.ACCT_SUMMARY_RECEIVED:
      case FastlinkErrorStatuses.BETA_SITE_DEV_IN_PROGRESS:
      case FastlinkErrorStatuses.PROPERTY_VALUE_NOT_AVAILABLE:
      case FastlinkErrorStatuses.MIGRATION_IN_PROGRESS:
        aggregatedError.displayMessage = `We are experiencing an issue connecting to ${fiName}. This issue is a result of a network issue at ${fiName} and typically resolves on its own in a few days. Please try again later.`;
        aggregatedError.actionMessage = FastlinkActions.RESTORE_CONNECTION;
        break;

      case FastlinkErrorStatuses.PARTIAL_DATA_RETRIEVED:
        aggregatedError.displayMessage =
          'This account requires additional verification. Please try again and provide the additional requested information.';
        aggregatedError.actionMessage = FastlinkActions.RESTORE_CONNECTION;
        break;

      case FastlinkErrorStatuses.INCORRECT_CREDENTIALS:
        aggregatedError.displayMessage =
          'The credentials provided are incorrect. Please verify your information and then try again.';
        aggregatedError.actionMessage = FastlinkActions.RESTORE_CONNECTION;
        break;

      case FastlinkErrorStatuses.INVALID_ADDL_INFO_PROVIDED:
        aggregatedError.displayMessage =
          'The additional verification answer provided is incorrect. Please verify your information and then try again.';
        aggregatedError.actionMessage = FastlinkActions.RESTORE_CONNECTION;
        break;

      default:
        aggregatedError.displayMessage = `We are experiencing an issue connecting to ${fiName}. This issue is a result of a network issue at ${fiName} and typically resolves on its own in a few days. Please try again later.`;
        aggregatedError.actionMessage = FastlinkActions.ADD_ANOTHER_ACCOUNT;
        break;
    }

    return aggregatedError;
  }

  /**
   * Returns true if the given status code indicates that the account is valid, false otherwise.
   *
   * @param status The status code to check.
   * @returns True if the status code indicates that the account is valid, false otherwise.
   */
  public isValidStatus(status: string): boolean {
    return FastLinkValidStatuses.AVAILABLE_DATA_RETRIEVED === status;
  }
}
