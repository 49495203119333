import { Store } from '@ngrx/store';

import { IRootScopeService } from 'angular';
import NgRedux from 'ng-redux';
import { FundingActions } from 'state-store/actions/funding.actions';

import { setupAccountsToBeFunded, setupFundingAccounts, turnOnFunding } from '@app/store/funding/funding.actions';
import { FundingAccount } from '@core/models';

import { Inject } from '../decorators/decorators';
import { AppSettingsService } from '../services/app-settings.service';

/**
 * In the run phase of the layout, the funding flow is determined if the
 * accountsToBeFunded object is currently stored in the sessionStorage.
 */
@Inject('$rootScope', '$ngRedux', 'appSettingsService', 'serviceHelper', 'ngrxStore')
export class LayoutRun {
  constructor(
    rootScope: IRootScopeService,
    ngRedux: NgRedux.INgRedux,
    appSettingsService: AppSettingsService,
    serviceHelper: IServiceHelper,
    store: Store
  ) {
    const accountsToFund: OlbAccount[] = JSON.parse(sessionStorage.getItem('accountsToBeFunded'));
    const fundingAccounts: FundingAccount[] = JSON.parse(sessionStorage.getItem('fundingAccounts'));
    if (serviceHelper.isUserAuthenticated()) {
      const settingName = 'ActiveFunding';
      if (rootScope['appSettings'] !== undefined) {
        const activeFunding: boolean =
          rootScope['appSettings'].find((ap: any) => ap.name === settingName).value === 'true';
        this.runDispatchers(accountsToFund, fundingAccounts, activeFunding, ngRedux, store);
      } else {
        appSettingsService.getSettingParsed<boolean>(settingName).subscribe(response => {
          this.runDispatchers(accountsToFund, fundingAccounts, response, ngRedux, store);
        });
      }
    }
  }

  runDispatchers(
    accountsToFund: OlbAccount[],
    fundingAccounts: FundingAccount[],
    activeFunding: boolean,
    ngRedux: NgRedux.INgRedux,
    store: Store
  ) {
    if (activeFunding) {
      if (fundingAccounts && fundingAccounts.length > 0) {
        ngRedux.dispatch(FundingActions.turnOnFunding());
        store.dispatch(turnOnFunding());
        ngRedux.dispatch(FundingActions.setupFundingAccounts(fundingAccounts));
        store.dispatch(setupFundingAccounts({ payload: fundingAccounts }));
      }
      if (accountsToFund && accountsToFund.length) {
        ngRedux.dispatch(FundingActions.turnOnFunding());
        store.dispatch(turnOnFunding());
        ngRedux.dispatch(FundingActions.setupAccounts(accountsToFund));
        store.dispatch(setupAccountsToBeFunded({ payload: accountsToFund }));
      }
    }
  }
}
