import * as angular from 'angular';
import { IOnChangesObject } from 'angular';
import { Inject } from 'decorators/decorators';

@Inject('$filter')
export class PopoverComparerController {
  // Bound properties
  model: any = { min: 0, max: 0 };
  filterTypeName: string;
  elementType: string;
  clearText: string;
  bgColor: string;
  onOk: Function;
  onCancel: Function;
  onClear: Function;

  // Properties to manage the controller state
  popoverTemplateUrl: string;
  popoverIsOpen: boolean;
  minValue: number;
  maxValue: number;
  rangeDescription: string;
  hasClearFilter: boolean;

  constructor(private readonly filterService: ng.IFilterService) {}

  /** Initializes the controller. */
  $onInit(): void {
    this.popoverTemplateUrl = 'popoverTemplate.html';
    this.popoverIsOpen = false;
    this.hasClearFilter = !angular.isUndefined(this.clearText) || this.clearText == '';
    this.elementType = !!this.elementType ? this.elementType : 'input';
    this.bgColor = !!this.bgColor ? this.bgColor : '';

    this.model = this.model ?? { min: 0, max: 0 };

    if (this.model.max && this.model.min) {
      this.maxValue = this.model.max;
      this.minValue = this.model.min;
      this.rangeDescription = this.formatDescription();
    }
  }

  $onChanges(changes: IOnChangesObject) {
    if (
      changes.model &&
      !changes.model.isFirstChange() &&
      (!changes.model.currentValue ||
        (!changes.model.currentValue.min && !changes.model.currentValue.max))
    ) {
      this.cleanData();
    }
  }

  /** Changes the bound model to reflect changes */
  ok() {
    if (!this.onOk) {
      this.popoverIsOpen = false;
    }

    this.model = this.model ?? {};

    this.model.min = this.minValue === undefined ? this.minValue : +this.minValue;
    this.model.max = this.maxValue === undefined ? this.maxValue : +this.maxValue;

    this.rangeDescription = this.formatDescription();
    const rangeValues = { min: this.model.min, max: this.model.max };

    this.onOk({ rangeValues, filterType: this.filterTypeName });
    this.popoverIsOpen = false;
  }

  /** Closes the popover and executed the function defined */
  cancel() {
    if (this.onCancel) {
      this.onCancel();
    }
    this.popoverIsOpen = false;
  }

  /** Clears the model content  */
  clear() {
    if (this.onClear) {
      this.cleanData();
      this.model = { min: null, max: null };
      this.onClear({ filterName: this.filterTypeName });
    }
    this.popoverIsOpen = false;
  }

  cleanData() {
    this.minValue = undefined;
    this.maxValue = undefined;
    this.rangeDescription = null;
  }

  private formatDescription(): string {
    let description = '';
    switch (this.filterTypeName) {
      case 'amountRange':
        description = `${
          this.minValue === undefined ? 'Min' : this.filterService('currency')(this.minValue)
        } - ${this.maxValue === undefined ? 'Max' : this.filterService('currency')(this.maxValue)}`;
        break;
      default:
        description = `#${this.minValue} - #${this.maxValue}`;
    }

    return description;
  }
}
