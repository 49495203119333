import { OrganizationStateType } from '.';

export type OrganizationSettingsStateType = {
  organizations: OrganizationStateType[];
  selectedOrganizationName: string | undefined;
};
export const initialOrganizationSettingsStateType: OrganizationSettingsStateType = {
  organizations: [],
  selectedOrganizationName: undefined,
};
