import { NgModule } from '@angular/core';
import { AasSharedModule } from '../../aas-shared/aas-shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AuthorizeTransferPageMatModule } from './authorize-transfer-page-mat.module';
import { AuthorizeTransferPageUk2Module } from './authorize-transfer-page-uk2.module';
import { ShareAccountDetailsModule } from '../share-account-details/share-account-details.module';
import { FormsModule } from '@angular/forms';
import { AuthorizeTransferPageComponent, AuthorizeTransferToggleComponent } from './view';

@NgModule({
  declarations: [AuthorizeTransferPageComponent, AuthorizeTransferToggleComponent],
  imports: [
    AasSharedModule,
    BrowserModule,
    CommonModule,
    AuthorizeTransferPageMatModule,
    AuthorizeTransferPageUk2Module,
    ShareAccountDetailsModule,
    FormsModule,
  ],
  exports: [AuthorizeTransferPageComponent],
})
export class AuthorizeTransferPageModule {}
