import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { StateWithholdingInfo } from '@app/transfers/models';
import { olbSettings } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-federal-taxes-modal',
  templateUrl: './federal-taxes-modal.component.html',
  styleUrls: ['./federal-taxes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FederalTaxesModalComponent implements OnInit {
  backgroundUrl: string;
  closeIcon = NavigationIcons.Ex;
  info: StateWithholdingInfo[];

  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    private dialogRef: MatDialogRef<FederalTaxesModalComponent>
  ) {}

  ngOnInit(): void {
    const { brand } = this.settings;
    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
    this.setDefaultValues();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private setDefaultValues() {
    this.info = [
      { state: 'Alabama', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Alaska', option: 'No option to withhold.', conditions: '' },
      { state: 'Arizona', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Arkansas', option: 'Arkansas\tRequired when federal withholding is taken.', conditions: '5%' },
      {
        state: 'California',
        option: 'Required when federal withholding is taken, unless opted out.',
        conditions: '10% of federal withholding amount. ',
      },
      { state: 'Colorado', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Connecticut', option: 'Required unless State W4-P provided for opt-out.', conditions: '6.99%' },
      { state: 'Delaware', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'District of Columbia', option: 'Required for full account distributions.', conditions: '8.95%' },
      { state: 'Florida', option: 'No option to withhold.', conditions: '' },
      { state: 'Georgia', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Hawaii', option: 'No option to withhold.', conditions: '' },
      { state: 'Idaho', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Illinois', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Indiana', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Iowa', option: 'Required when federal withholding is taken.', conditions: '5%' },
      { state: 'Kansas', option: 'Kansas\tRequired when federal withholding is taken.', conditions: '5%' },
      { state: 'Kentucky', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Louisiana', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Maine', option: 'Required when federal withholding is taken, unless opted out.', conditions: '5%' },
      { state: 'Maryland', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Massachusetts', option: 'Required when federal withholding is taken.', conditions: '5%' },
      { state: 'Michigan', option: 'Required unless State W4-P provided for opt-out.', conditions: '4.25%' },
      { state: 'Minnesota', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Mississippi', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Missouri', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Montana', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Nebraska', option: 'Required when federal withholding is taken.', conditions: '5%' },
      { state: 'Nevada', option: 'No option to withhold.', conditions: '' },
      { state: 'New Hampshire', option: 'No option to withhold.', conditions: '' },
      { state: 'New Jersey', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'New Mexico', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'New York', option: 'Optional.', conditions: 'No conditions.' },
      {
        state: 'North Carolina',
        option: 'Required when federal withholding is taken, unless opted out. ',
        conditions: '4%',
      },
      { state: 'North Dakota', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Ohio', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Oklahoma', option: 'Required when federal withholding is taken.', conditions: '5%' },
      { state: 'Oregon', option: 'Required unless opted out.', conditions: '8%' },
      { state: 'Pennsylvania', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Rhode Island', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'South Carolina', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'South Dakota', option: 'No option to withhold.', conditions: '' },
      { state: 'Tennessee', option: 'No option to withhold.', conditions: '' },
      { state: 'Texas', option: 'No option to withhold.', conditions: '' },
      { state: 'Utah', option: 'Optional.', conditions: 'No conditions.' },
      {
        state: 'Vermont',
        option: 'Required when federal withholding is taken.',
        conditions: '30% of federal withholding amount. ',
      },
      { state: 'Virginia', option: 'Required when federal withholding is taken.', conditions: '4%' },
      { state: 'Washington', option: 'No option to withhold.', conditions: '' },
      { state: 'West Virginia', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Wisconsin', option: 'Optional.', conditions: 'No conditions.' },
      { state: 'Wyoming', option: 'No option to withhold.', conditions: '' },
    ];
  }
}
