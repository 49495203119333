import { Frequency } from '@app/axos-invest/enums';

export class TransferForm {
  accountId: number | string;
  accountNumber: string;
  account: NewOlbAccount;
  amount: number;
  frequency: Frequency;
  startDate: Date;
  endDate: Date;
  transferSeriesId: string;
  goalAccount: string;
  contributionYear?: number;

  constructor(transferData?: Partial<TransferForm>) {
    Object.assign(this, transferData);
  }
}
