import { AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class customErrorState implements ErrorStateMatcher {
  valMin: number;
  valMax: number;

  addValMin(value: number) {
    this.valMin = value;
  }

  addValMax(value: number) {
    this.valMax = value;
  }

  isErrorState(control: AbstractControl<any, any>): boolean {
    if (control != null) {
      var inputValue = Number(control.value?.replace(/[^0-9\.]+/g, ''));
      if (inputValue > this.valMax) {
        return true;
      }
      if (inputValue < this.valMin) {
        return true;
      }
      return false;
    }

    return false;
  }
}
