<div class="tooltip-message non-italic" *ngIf="isVisible">
  <span class="tooltip-message--icon">!</span>
  <span class="message">
    <div>
      {{ description }}
    </div>
    <span class="message--action" (click)="actionClick()">
      {{ action }}
    </span>
  </span>
</div>
