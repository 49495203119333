import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { DialogComponent } from '@shared/components';
import { DialogData, DialogDataArgs } from '@shared/models';

import { solePropHelpers } from '@legacy/shared/helpers';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  static $token = 'dialogService';
  private solePropGlobalData: solePropHelpers.SolePropData;
  private dialogSubject = new BehaviorSubject<solePropHelpers.SolePropData>({
    businessId: undefined,
    dialogRef: undefined,
    isSoleProp: undefined,
  });
  dialogState$ = this.dialogSubject.asObservable();

  constructor(private dialog: MatDialog) {}

  open(args: DialogDataArgs | DialogData, disableClose = false) {
    return this.dialog.open(DialogComponent, {
      data: new DialogData(args),
      disableClose,
    });
  }

  openCustom(config: MatDialogConfig) {
    return this.dialog.open(DialogComponent, config);
  }

  openByComponentRef(component: any, config?: MatDialogConfig) {
    return this.dialog.open(component, config);
  }

  updateSolePropGlobalData(
    updateFn: (
      data: solePropHelpers.SolePropData
    ) => solePropHelpers.SolePropData
  ): void {
    this.solePropGlobalData = updateFn(this.solePropGlobalData);
    this.dialogSubject.next(this.solePropGlobalData);
  }

  selectSolePropData(): solePropHelpers.SolePropData {
    return this.solePropGlobalData;
  }
}
