import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { StoreModule } from '@ngrx/store';

import { NgxMaskModule } from 'ngx-mask';

import { AppStateFeatures } from '@core/enums';
import { SharedModule } from '@shared/shared.module';

import { userProfileComponents } from './components';
import { userProfileReducers } from './store';
import { userProfileViews } from './views';

@NgModule({
  declarations: [...userProfileComponents, ...userProfileViews],
  imports: [
    SharedModule,
    NgxMaskModule,
    MatCheckboxModule,
    MatDialogModule,
    StoreModule.forFeature(AppStateFeatures.UserProfile, userProfileReducers),
  ],
})
export class UserProfileModule {}
