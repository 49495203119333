import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TransactionFacade } from '../../facade';
import { Subject } from 'rxjs';
import { filter, tap, takeUntil } from 'rxjs/operators';
import { Transaction, TransactionTileState } from '../../core/store/types';
import { TransactionsState } from '../../core/store/enums';
import {
  Uk2ButtonSizeEnum,
  Uk2Tier1FinancialEnum,
  Uk2Tier1InvestEnum,
  Uk2Tier1NavigationEnum,
  Uk2Tier1UtilityEnum,
} from '@axos/uikit-v2-lib';
import { TransactionCategory } from './types';
import * as moment from 'moment';
import { STATE } from '@core/tokens';
import { PdpFacade } from '../../../product-details-page/facade/pdp.facade';
import { TRANSACTIONS_LABELS } from './constants';

@Component({
  selector: 'app-transactions-tile',
  templateUrl: './transactions-tile.component.html',
  styleUrls: ['./transactions-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsTileComponent implements OnInit, OnDestroy {
  transactionTile: TransactionTileState | undefined;
  isLoading = true;
  transactionCategory = {
    contribution: { categoryName: 'Contribution', categoryIcon: Uk2Tier1FinancialEnum.dollarArrowBack },
    distribution: { categoryName: 'Distribution', categoryIcon: Uk2Tier1FinancialEnum.dollarArrowForward },
    transfer: { categoryName: 'Transfer', categoryIcon: Uk2Tier1NavigationEnum.arrowsHorizontal },
    split: { categoryName: 'Split', categoryIcon: Uk2Tier1InvestEnum.split },
    fee: { categoryName: 'Fee', categoryIcon: Uk2Tier1FinancialEnum.moneyHand },
    dividendInterest: { categoryName: 'Dividend/Interest', categoryIcon: Uk2Tier1InvestEnum.shortInterest },
    other: { categoryName: 'Other', categoryIcon: '' },
    pending: { categoryName: 'Pending', categoryIcon: Uk2Tier1UtilityEnum.clock },
  };
  uk2UtilityIcons = Uk2Tier1UtilityEnum;
  uk2NavigationIcons = Uk2Tier1NavigationEnum;
  uk2ViewAllButtonSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.medium;
  labels = TRANSACTIONS_LABELS;

  private destroy$ = new Subject<void>();
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private transactionFacade: TransactionFacade,
    private pdpFacade: PdpFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.transactionFacade.transactionTileAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(transactionTileState => transactionTileState !== undefined),
        tap(transactionTileState => {
          if (transactionTileState.apiCallWasSuccessful) {
            this.handleTransactions(transactionTileState);
          }
        })
      )
      .subscribe();

    this.transactionFacade.isLoading$
      .pipe(
        takeUntil(this.destroy$),
        tap(isLoading => {
          this.isLoading = isLoading;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTransactionStatus(transaction: Transaction): string {
    return transaction.state !== 0 ? moment(transaction.postDate).utc().format('LL') : 'Pending';
  }

  getTransactionCategory(activityCode: string, transactionState: TransactionsState): TransactionCategory {
    if (transactionState === TransactionsState.Pending) {
      return this.transactionCategory.pending;
    }
    if (activityCode.startsWith('1')) {
      return this.transactionCategory.contribution;
    }
    if (activityCode.startsWith('2') || activityCode === '304') {
      return this.transactionCategory.distribution;
    }
    if (activityCode.startsWith('6') && !['605', '606', '611', '617', '618', '666', '667'].includes(activityCode)) {
      return this.transactionCategory.transfer;
    }
    if (['605', '606', '617', '618'].includes(activityCode)) {
      return this.transactionCategory.split;
    }
    if (activityCode.startsWith('3') && activityCode !== '304') {
      return this.transactionCategory.fee;
    }
    if (activityCode.startsWith('4')) {
      return this.transactionCategory.dividendInterest;
    }

    return this.transactionCategory.other;
  }

  navigateToAllTransactions(transactionId?: string) {
    if (transactionId === null) return;

    const componentTab = 'Transactions';
    this.pdpFacade.setTransactionSelectedTab(componentTab);
    const state = 'udb.accounts.container';
    const accountType = 'Advisor';
    this.state.go(state, {
      id: this.transactionTile.accountNumber,
      type: accountType,
      transactionId: transactionId ? transactionId : null,
    });
  }

  private handleTransactions(transactionTileState: TransactionTileState) {
    this.transactionTile = structuredClone(transactionTileState);
    this.transactionTile.transactions = transactionTileState.transactions
      .filter(
        transaction =>
          !this.isPending(transaction.state) ||
          (this.isWithinNextFiveDays(transaction.tradeDate) && this.isPending(transaction.state))
      )
      .slice(0, 8);
    this.changeDetectorRef.markForCheck();
  }

  private isWithinNextFiveDays(tradeDate: Date | string): boolean {
    const currentDate = new Date().getTime();
    const targetDate = typeof tradeDate === 'string' ? new Date(tradeDate).getTime() : tradeDate.getTime();

    const differenceInDays = (targetDate - currentDate) / (1000 * 60 * 60 * 24);

    return differenceInDays <= 5 ? true : false;
  }

  private isPending(transactionState: TransactionsState): boolean {
    return transactionState === TransactionsState.Pending;
  }
}
