import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';

import { ToolTipType } from '@app/pfm/enums/tooltip-type-enum';
import { ChartOptionsDonut } from '@app/pfm/models';
import { TxFilter } from '@legacy/accounts/typings/TxFilter';

import { Category } from '@app/pfm/models/category';
import { Highlight } from '@app/pfm/utils/chartjs-plugins/highlight';
import { Chart } from 'chart.js';
import { setAxosDoughnutChart } from './donut-chart-extension';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() categories: Category[];
  @Input() filters: TxFilter;
  @Output() toggleCategoryFilter = new EventEmitter();
  @Input() chartjsOptions: any;
  @Input() width: number;
  @Input() height: number;
  @Input() showTooltips = true;
  @Input() tooltipType: ToolTipType;
  tooltipTypeEnum = ToolTipType;

  chart: Chart;
  @ViewChild('tooltip') tooltip;
  @ViewChild('chart') private chartRef;
  private data: any;
  private options: object;
  constructor() {}

  getTooltipStyle(tooltipType: ToolTipType): string {
    let toolTypeStyle = '';

    switch (tooltipType) {
      case ToolTipType.White:
        toolTypeStyle = 'tooltip-white';
        break;
      default:
        toolTypeStyle = 'tooltip-black';
        break;
    }

    return toolTypeStyle;
  }

  ngOnChanges() {
    this.updateChart();
  }
  ngAfterViewInit(): void {
    this.updateChart();
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  getOptions(): ChartOptionsDonut {
    const self = this;

    const options = {
      ...this.chartjsOptions,
      legend: {
        display: false,
      },
      onClick(_, elements) {
        // toggle clicked category in the filters
        if (!elements[0] || elements[0]['_index'] === undefined) return;
        const clickedSliceIndex = elements[0]['_index'];
        const categoryId = self.categories[clickedSliceIndex].id;

        self.toggleCategoryFilter.emit({ categoryId });
      },
      rotation: -1 * (Math.PI / 180),
      tooltips: {
        enabled: false,
      },
    };

    return options;
  }

  getData(tooltipType: ToolTipType): ChartData<any> {
    const datasets = [
      {
        data: this.categories.map(category => category.amount),
        backgroundColor: this.categories.map(category => category.color),
        categories: this.categories,
        borderRadius: 30,
      },
    ];
    let labels: string[] = [];
    switch (tooltipType) {
      case ToolTipType.White:
        labels = this.categories.map(category => category.name);
        break;
      default:
        labels = this.categories.map(category => category.imageFile);
        break;
    }
    const data = {
      labels,
      datasets,
    };

    return data;
  }

  createChart() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: setAxosDoughnutChart(),
      plugins: [new Highlight()],
      data: this.data,
      options: {
        ...this.options,
      },
    });
  }

  updateChart() {
    if (this.categories[0].color === undefined) return;
    if (!this.chartRef) return;

    this.data = this.getData(this.tooltipType);
    this.options = this.getOptions();

    if (!this.chart) {
      this.createChart();
    } else {
      // fields that might change after chart creation
      this.chart.config.data.labels = this.data.labels;
      this.chart.config.data.datasets[0].data = this.data.datasets[0].data;
      this.chart.config.data.datasets[0].backgroundColor = this.data.datasets[0].backgroundColor;
      this.tooltip.nativeElement.style.opacity = '0';
    }

    if (this.filters) {
      this.chart.options.plugins.highlight.indices = this.categories
        .map((category, i) => (this.filters.categories.indexOf(category.id) >= 0 ? i : null))
        .filter(i => i != null);
    }

    this.chart.update();
  }
}
