import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { CancelTransactionRequest, TransactionsRequest, TransactionsResponse } from './types';
import { WebApiResponse } from '@shared/models';
import { Observable } from 'rxjs';
import { CancelScheduledTransfersResponse } from './types/cancel-transactions-response.type';

@Injectable()
export class TransactionsService extends BaseService {
  /**
   * TransactionsService constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/transactions');
  }

  /**
   * Returns the transactions of account number from AAS customer.
   *
   * @param request TransactionsRequest
   * @returns WebApiResponse of TransactionsResponse
   *
   */
  getTransactions(request: TransactionsRequest): WebApiResponse<TransactionsResponse[]> {
    return this.get(request.accountNumber, {
      startDate: request.endDate ? request.endDate.toISOString() : '',
      endDate: request.startDate ? request.startDate.toISOString() : '',
      limit: request.limit ? request.limit.toString() : '',
    });
  }

  /**
   * Returns the file of transactions of account number from AAS customer.
   *
   * @param transaction IDownloadTransactionsRequest
   * @param extension ApiFileExtensions
   * @returns ApiResponse of ArrayBuffer
   *
   */
  downloadTransactionsFile(
    transactionDownloadFilters: IDownloadTransactionsRequest,
    accountId: string
  ): Observable<Blob> {
    return this.download(
      `download/${accountId}`,
      {
        startDate: transactionDownloadFilters.EndDate ? transactionDownloadFilters.EndDate.toISOString() : '',
        endDate: transactionDownloadFilters.StartDate ? transactionDownloadFilters.StartDate.toISOString() : '',
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  /**
   * Cancels a transaction from an AAS account
   *  @param transaction CancelTransactionRequest
   *  @returns ApiResponse (type TBD)
   */

  cancelTransactions(request: CancelTransactionRequest): WebApiResponse<CancelScheduledTransfersResponse> {
    return this.put('cancel', request);
  }
}
