import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { AuthorizeTransfersTransferOnlyMatModule } from './authorize-transfers-transfer-only-mat.module';
import { AuthorizeTransfersTransferOnlyUK2Module } from './authorize-transfers-transfer-only-uk2.module';
import { AuthorizeTransfersTransfersOnlyComponent } from './view';

@NgModule({
  declarations: [AuthorizeTransfersTransfersOnlyComponent],
  imports: [
    AuthorizeTransfersTransferOnlyUK2Module,
    AuthorizeTransfersTransferOnlyMatModule,
    SharedModule,
    CommonModule,
  ],
  exports: [AuthorizeTransfersTransfersOnlyComponent],
})
export class AuthorizeTransfersTransferOnlyModule {}
