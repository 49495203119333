import { TradingAccount } from 'accounts/typings/TradingAccount';
import { FeatureFlagService } from 'services/feature-flag.service';

import { DocumentDetail } from '@app/accounts/models';
import { AuthResponse } from '@app/authentication/models';
import { BeneficiariesRequest } from '@app/shared/models';

import { Inject } from '../decorators/Inject';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';
import { BaseService } from './base.service';
import { IAxosClearingService } from './typings/IAxosClearingService';

@Inject('$http', 'env', 'serviceHelper', '$q', 'featureFlagService', 'userSubtypeHelper')
export class AxosClearingService extends BaseService implements IAxosClearingService {
  constructor(
    http: ng.IHttpService,
    env: any,
    serviceHelper: IServiceHelper,
    q: ng.IQService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly userSubtypeHelper: UserSubtypeHelper
  ) {
    super(http, env, 'axosclearing', serviceHelper, q);
  }

  getDefaultNickName(): string {
    return 'Self-Directed Trading';
  }

  getSsoToken(): ApiResponse<string> {
    return this.get('sso/token');
  }

  handleRedirectFromOrbis(token: string): ApiResponse<AuthResponse> {
    return this.post(`sso/redirect/${token}`);
  }

  /**
   * Check if feature flag for axos trading is active and current user is axos trading
   */
  isAxosTradingActiveForUser(): boolean {
    return this.featureFlagService.isAxosTradingActive() && this.userSubtypeHelper.hasAxosTrading();
  }

  getAccounts(): ApiResponse<TradingAccount[]> {
    return this.get('accounts');
  }

  getDocuments(accountNumber: string): ApiResponse<DocumentDetail[]> {
    return this.get(`accounts/${accountNumber}/documents`);
  }

  getPositions(accountNumber: string): ApiResponse<TradingPosition[]> {
    return this.get(`accounts/${accountNumber}/positions`);
  }

  updateNickname(account: TradingAccount): ApiResponse<TradingAccount> {
    return this.put('accounts', account);
  }

  addBeneficiaries(accountNumber: string, request: BeneficiariesRequest): ApiResponse<string> {
    return this.post(`accounts/${accountNumber}/beneficiaries`, request);
  }
}
