import { createSelector } from '@ngrx/store';

import { AccountCategory, categoryMap } from '@app/accounts/enums';

import { getAccountsState } from '../accounts.state';

export const getAggregatedAccountsState = createSelector(getAccountsState, state => state.aggregated);
export const getAggregatedAccountsList = createSelector(getAggregatedAccountsState, state => {
  return Object.keys(state)
    .filter(key => !!state[key])
    .reduce((acc, key) => [...acc, ...Object.values(state[key])], []);
});
export const getAggregatedAccountsForDropdown = createSelector(getAggregatedAccountsList, state =>
  state.filter(a => !['bill', 'reward', 'insurance', 'realEstate'].includes(a.container))
);
export const getAggregatedCheckingAccountsList = createSelector(getAggregatedAccountsState, state =>
  Object.values(state.checking)
);
export const getAggregatedSavingsAccountsList = createSelector(getAggregatedAccountsState, state =>
  Object.values(state.savings)
);
export const getAggregatedLoanAccountsList = createSelector(getAggregatedAccountsState, state =>
  Object.values(state.loan)
);
export const getAggregatedCdIraAccountsList = createSelector(getAggregatedAccountsState, state =>
  Object.values(state.cd)
);
export const getAggregatedCreditAccountsList = createSelector(getAggregatedAccountsState, state =>
  Object.values(state.credit)
);
export const getAggregatedOtherAccountsList = createSelector(getAggregatedAccountsState, state =>
  Object.values(state.other)
);
export const getAggregatedUnknownAccountsList = createSelector(getAggregatedAccountsState, state =>
  Object.values(state.unknown)
);

/**
 * Gets an specific aggregated account from the state.
 * If you want to get an internal account, please use the "getInternalAccount" selector.
 *
 * @param category Account category.
 * @param accountId Account ID.
 *
 * @returns The account that matches the given ID in the given category.
 */
export function getAggregatedAccount(category: AccountCategory, accountId: number) {
  const selectedCategory = categoryMap[category];

  return createSelector(getAggregatedAccountsState, state => state[selectedCategory][accountId]);
}
