import { downgradeComponent } from '@angular/upgrade/static';
import {
  AcceptanceCriteriaComponent,
  NoLongerEligibleComponent,
  PreApprovedOfferComponent,
  SblocOffersComponent,
} from '@app/sbloc-offers/components/Index';
import { FeatureFlagOffComponent } from '@app/sbloc-offers/components/feature-flag-off/feature-flag-off.component';

import { Inject } from '@legacy/decorators/decorators';

@Inject('$stateProvider')
export class sblocOffersLegacyConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.FeatureFlagOff', {
      url: '/FeatureFlagOff',
      views: {
        content: {
          template: '<app-feature-flag-off></app-feature-flag-off>',
        },
      },
    }),
      stateProvider.state('udb.NoLongerEligible', {
        url: '/NoLongerEligible',
        views: {
          content: {
            template: '<app-no-longer-eligible></app-no-longer-eligible>',
          },
        },
      }),
      stateProvider.state('udb.preApproved', {
        url: '/preApprovedOffer',
        views: {
          content: {
            template: '<app-pre-approved-offer></app-pre-approved-offer>',
          },
        },
      }),
      stateProvider.state('udb.sblocOffers', {
        url:
          '/sblocOffers?' +
          'utm_campaign&' +
          'utm_medium&' +
          'utm_source&' +
          'utm_content&' +
          'utm_term&' +
          'utm_promocode&' +
          'experience_id&' +
          'hem&' +
          'identity&' +
          'msclkid&' +
          'gclid&' +
          'fbclid',
        views: {
          content: {
            template: '<app-sbloc-offers></app-sbloc-offers>',
          },
        },
      });
    stateProvider.state('udb.acceptanceCriteria', {
      url: '/AcceptanceCriteria',
      views: {
        content: {
          template: '<app-acceptance-criteria></app-acceptance-criteria>',
        },
      },
    });
  }
}

export const sblocOffersLegacyModule = angular
  .module('udb.sblocOffersLegacy', ['ui.router'])
  .directive('appSblocOffers', downgradeComponent({ component: SblocOffersComponent }))
  .directive('appPreApprovedOffer', downgradeComponent({ component: PreApprovedOfferComponent }))
  .directive('appAcceptanceCriteria', downgradeComponent({ component: AcceptanceCriteriaComponent }))
  .directive('appNoLongerEligible', downgradeComponent({ component: NoLongerEligibleComponent }))
  .directive('appFeatureFlagOff', downgradeComponent({ component: FeatureFlagOffComponent }))
  .config(sblocOffersLegacyConfig).name;
