<h3 class="mb-3">Other Linked Cards</h3>

<ul class="d-flex flex-column justify-content-center">
  <li *ngFor="let card of debitCards; let idx = index">
    <button type="button" class="link" (click)="pickCard.emit(idx)">
      <img src="assets/logos/card/{{ card.debitCardTypeDescription.toLowerCase() }}.svg" />

      <div class="description">
        <span class="description__name" [class.viewing]="selectedCardIndex === idx || frozenStatus === card.status">
          Debit Card
          {{ card.cardNumberMask.slice(card.cardNumberMask.length - 5) }}
          <span *ngIf="selectedCardIndex == idx">(Viewing)</span>
        </span>
        <span class="description__exp">Exp: {{ card.expirationDate | date: 'MM/yy' }}</span>
        <span class="description__status" [innerText]="'Status: ' + card.statusDescription"></span>
      </div>
    </button>
  </li>
</ul>
