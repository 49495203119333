<h3 class="mt-5 mb-4">How do I set up email alerts?</h3>
<ol>
  <li>
    Click on
    <b>Alerts</b>
    at the top right of the screen.
  </li>
  <li>
    Scroll down to
    <b>Delivery Method</b>
    and check the box next to
    <b>Enable email alert notifications,</b>
    there you will see your email address that we have on file.
  </li>
</ol>
