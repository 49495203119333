<div class="margin-notification col-md-4 col-sm-12 d-flex mt-2 mb-4 p-2" *ngIf="account.enableMargin">
  <div class="col-1">
    <app-svg-icon [iconName]="icon"></app-svg-icon>
  </div>
  <div class="col-11">
    <p class="mb-2">You are currently trading on margin</p>
    <a *ngIf="$orbisUrl | async as orbisUrl; else loader" target="_blank" [href]="orbisUrl" id="orbisSsoLink">
      Update Borrow Limit
    </a>
    <ng-template #loader><app-dot-loader></app-dot-loader></ng-template>
  </div>
</div>
