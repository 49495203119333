export enum ReminderOption {
  DontSend = 0,
  OneDayBefore = 1,
  TwoDaysBefore = 2,
  ThreeDaysBefore = 3,
  FiveDaysBefore = 5,
  OneWeekBefore = 7,
}

// export function mapOption(reminder: number): string {
//   switch (reminder) {
//     case ReminderOption.DontSend:
//       return 'Do not send';
//     case ReminderOption.OneDayBefore:
//       return '1 Day before payment is sent';
//     case ReminderOption.TwoDaysBefore:
//       return '2 Days before payment is sent';
//     case ReminderOption.ThreeDaysBefore:
//       return '3 Days before payment is sent';
//     case ReminderOption.FiveDaysBefore:
//       return '5 Days before payment is sent';
//     case ReminderOption.OneWeekBefore:
//       return '1 Week before payment is sent';
//     default:
//       return '';
//   }
// }
