import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { FirmsRepresentative } from '../models/firms-representative.models';

@Injectable()
export class RIAServices extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, httpClient: HttpClient) {
    super(httpClient, settings, 'aas');
  }

  getUserContactsFirms(): WebApiResponse<FirmsRepresentative[]> {
    return this.get('contacts/firms');
  }
}
