import { FirmContactAddressStateType, initialFirmContactAddressState } from '../firm-contact-address-state.type';

export type FirmContactStateBaseType = {
  name: string | undefined;
  mobilePhone: string | undefined;
  email: string | undefined;
  address: FirmContactAddressStateType;
};

export const initialFirmContactStateBase: FirmContactStateBaseType = {
  name: undefined,
  mobilePhone: undefined,
  email: undefined,
  address: initialFirmContactAddressState,
};
