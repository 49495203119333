import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import {
  StopPaymentAddComponent,
  StopPaymentComponent,
  StopPaymentExtendComponent,
  StopPaymentRemoveComponent,
  StopPaymentSuccessComponent,
} from '@app/accounts/components';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';

import * as angular from 'angular';

export const stopPaymentLegacyModule = angular
  .module('udb.stoppayment', ['ui.router'])
  .directive('appStopPayment', downgradeComponent({ component: StopPaymentComponent }))
  .directive('appStopPaymentRemove', downgradeComponent({ component: StopPaymentRemoveComponent }))
  .directive('appStopPaymentSuccess', downgradeComponent({ component: StopPaymentSuccessComponent }))
  .directive('appStopPaymentExtend', downgradeComponent({ component: StopPaymentExtendComponent }))
  .directive('appStopPaymentAdd', downgradeComponent({ component: StopPaymentAddComponent }))
  .service('dateHelper', downgradeInjectable(DateHelper))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider
        .state('udb.accounts.stop-payment', {
          url: '/Account/:id/StopPayment',
          views: {
            'acc-content': {
              template: '<div ui-view="acc-content"><app-stop-payment></app-stop-payment></div>',
            },
          },
        })
        .state('udb.accounts.stop-payment-remove', {
          url: '/Account/:id/StopPayment/Remove/:seq',
          views: {
            'acc-content': {
              template: '<app-stop-payment-remove></app-stop-payment-remove>',
            },
          },
          params: {
            spCancel: null,
          },
        })
        .state('udb.accounts.stop-payment-success', {
          url: '/Account/:id/StopPayment/Success/:seq',
          views: {
            'acc-content': {
              template: '<app-stop-payment-success></app-stop-payment-success>',
            },
          },
          params: {
            stopPayment: null,
            actionMsg: '',
          },
        })
        .state('udb.accounts.stop-payment-add', {
          url: '/Account/:id/AddStopPayment',
          views: {
            'acc-content': {
              template: '<app-stop-payment-add></app-stop-payment-add>',
            },
          },
        })
        .state('udb.accounts.stop-payment-extend', {
          url: '/Account/:id/StopPayment/Extend/:seq',
          views: {
            'acc-content': {
              template: '<app-stop-payment-extend></app-stop-payment-extend>',
            },
          },
          params: {
            spExtend: null,
          },
        });
    },
  ]).name;
