import { Inject } from 'decorators/decorators';

import { AccountDebitCardManagementController } from './debit-card-management.controller';

@Inject('$stateProvider')
export class AccountDebitCardManagementConfig {
  constructor($stateProvider: ng.ui.IStateProvider) {
    $stateProvider
      .state('udb.accounts.debit-cards', {
        url: '/Account/:id/DebitCards',
        params: { card: null },
        views: {
          'acc-content': {
            templateUrl: 'accounts/account-debit-cards/debit-card-management/debit-card-management.tpl.html',
            controller: AccountDebitCardManagementController,
            controllerAs: '$ctrl',
          },
        },
      })
      .state('udb.accounts.debit-cards.report-lost-or-stolen', {
        url: '/ReportLostOrStolen',
        params: { card: null },
        views: {
          'dcm-content': {
            template: '<app-report-lost-stolen-view></app-report-lost-stolen-view>',
          },
        },
      })
      .state('udb.accounts.debit-cards.limits', {
        url: '/RequestLimit/:cardId',
        params: { card: null },
        views: {
          'dcm-content': {
            template: '<app-request-limit-change-view></app-request-limit-change-view>',
          },
        },
      })
      .state('udb.accounts.debit-cards.replacement', {
        url: '/Replacement',
        params: { card: null },
        views: {
          'dcm-content': {
            template: '<app-request-replacement-view></app-request-replacement-view>',
          },
        },
      })
      .state('udb.accounts.debit-cards.schedule-travel', {
        url: '/ScheduleTravel',
        params: { card: null },
        views: {
          'dcm-content': {
            template: '<app-schedule-travel-view></app-schedule-travel-view>',
          },
        },
      })
      .state('udb.accounts.debit-cards.confirmation', {
        url: '/Confirmation',
        params: {
          message: null,
          email: null,
          accountId: null,
          card: null,
          header: null,
        },
        views: {
          'dcm-content': {
            template: '<app-debit-card-confirmation-view></app-debit-card-confirmation-view>',
          },
        },
      });
  }
}
