export const axosColorPalette = [
  '#87B9D7',
  '#4D85B0',
  '#2F5B88',
  '#1E3860',
  '#051A3F',
  '#D4D4D4',
  '#BDBDBD',
  '#5E6A74',
  '#4A5560',
  '#494949',
];
export const nwColorPalette = [
  '#CFF2EB',
  '#81D9D7',
  '#13AEBA',
  '#158994',
  '#0A5B62',
  '#D4D4D4',
  '#BDBDBD',
  '#5E6A74',
  '#4A5560',
  '#494949',
];

export const ufbColorPalette = [
  '#FFDAB6',
  '#FFCF99',
  '#FAA74A',
  '#E68D5D',
  '#D95F27',
  '#D4D4D4',
  '#BDBDBD',
  '#5E6A74',
  '#4A5560',
  '#494949',
];
