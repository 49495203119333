<div class="d-flex flex-column w-100">
  <div class="d-flex w-100">
    <canvas #chartCanvas class="chart"></canvas>
  </div>
  <div class="d-flex w-100 justify-content-between" id="dateLabelsContainer" *ngIf="displayCustomLabels">
    <div class="date-label" id="fromDate">
      {{ fromDate | date }}
    </div>
    <div class="date-label" id="toDate">
      {{ toDate | date }}
    </div>
  </div>
</div>
