import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import { InvestorCheckingAccountResponse } from '../../features/interestitial-page/core/store/models/investor-checking-account-response';

@Injectable({
  providedIn: 'root',
})
export class accountsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/accounts');
  }

  hasAxosCheckingAccounts(): WebApiResponse<boolean> {
    return this.get(`hasAxosCheckingAccount`);
  }

  hasAxosInvestorCheckingAccounts(): WebApiResponse<InvestorCheckingAccountResponse> {
    return this.get(`hasAxosInvestorCheckingAccount`);
  }
}
