import { Component, Input } from '@angular/core';

import { EquityType } from '@app/axos-trading/enums';
import { Equity } from '@app/axos-trading/models';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent {
  @Input() stats: Equity;
  equityTypeEnum = EquityType;
  icons = {
    ChevronUp: NavigationIcons.ChevronUp,
    ChevronDown: NavigationIcons.ChevronDown,
  };
  isExpanded = false;
  loadingStats = false;
}
