<div #parent>
  <ng-content></ng-content>
</div>

<div #content>
  <h4 class="text-center">Select the file format</h4>
  <app-transaction-history-options
    [fileFormat]="fileFormat"
    *ngFor="let fileFormat of fileFormats"
  ></app-transaction-history-options>
</div>
