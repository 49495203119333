import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NavigationIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-speedbump',
  templateUrl: './speedbump.component.html',
  styleUrls: ['./speedbump.component.scss'],
})
export class SpeedbumpComponent {
  @Output() displaySpeedBump = new EventEmitter<void>();
  @Input() url: string;
  @Input() displayUrl: string;
  @Input() otherUrl: string;
  @Input() brandName: string;

  closeIcon = NavigationIcons.Ex;
  bellIcon = UtilityIcons.Bell;

  closeSpeedBump() {
    this.displaySpeedBump.emit();
  }

  onProceed() {
    this.displaySpeedBump.emit();
    window.open(this.otherUrl || this.url, '_blank');
  }
}
