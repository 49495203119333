import { Action, createReducer, on } from '@ngrx/store';
import { AccountPeopleState, initialAccountPeopleState } from '../types';
import {
  addUpdateAccountPeopleAction,
  addUpdateInterestedPartyAction,
  addUpdateTrustedContactAction,
  deleteTrustedContactAction,
} from '../people.actions';
import { signOut } from '@app/store/actions';

const _reducer = createReducer(
  initialAccountPeopleState,
  on(addUpdateAccountPeopleAction, (_state, _action) => {
    const indexOfUpdate = _state.accountsPeople.findIndex(ap => ap.accountNumber === _action.payload.accountNumber);

    if (indexOfUpdate >= 0) {
      return {
        accountsPeople: _state.accountsPeople.map(accountPeople =>
          accountPeople.accountNumber === _action.payload.accountNumber ? { ..._action.payload } : accountPeople
        ),
        selectedAccountPeople: _action.payload.accountNumber,
      };
    } else {
      return {
        accountsPeople: [..._state.accountsPeople, _action.payload],
        selectedAccountPeople: _action.payload.accountNumber,
      };
    }
  }),
  on(addUpdateTrustedContactAction, (_state, _action) => {
    let output: AccountPeopleState;
    const indexOfAccountPeople = _state.accountsPeople.findIndex(
      a => a.accountNumber === _action.payload.accountNumber
    );
    const indexOfTrustedContact = _state.accountsPeople[indexOfAccountPeople].accountPeople.trustedContacts.findIndex(
      t => t.id === _action.payload.trustedContact.id
    );

    if (indexOfTrustedContact >= 0) {
      // Update trusted contact
      output = {
        accountsPeople: [
          ..._state.accountsPeople.filter(a => a.accountNumber !== _action.payload.accountNumber),
          {
            ..._state.accountsPeople[indexOfAccountPeople],
            accountPeople: {
              ..._state.accountsPeople[indexOfAccountPeople].accountPeople,
              trustedContacts: _state.accountsPeople[indexOfAccountPeople].accountPeople.trustedContacts.map(t =>
                t.id === _action.payload.trustedContact.id ? _action.payload.trustedContact : t
              ),
            },
          },
        ],
        selectedAccountPeople: _state.selectedAccountPeople,
      };
    } else {
      // Add trusted contact
      output = {
        accountsPeople: [
          ..._state.accountsPeople.filter(a => a.accountNumber !== _action.payload.accountNumber),
          {
            ..._state.accountsPeople[indexOfAccountPeople],
            accountPeople: {
              ..._state.accountsPeople[indexOfAccountPeople].accountPeople,
              trustedContacts: [
                ..._state.accountsPeople[indexOfAccountPeople].accountPeople.trustedContacts,
                _action.payload.trustedContact,
              ],
            },
          },
        ],
        selectedAccountPeople: _state.selectedAccountPeople,
      };
    }

    return output;
  }),
  on(deleteTrustedContactAction, (_state, _action) => {
    let output: AccountPeopleState;
    const indexOfAccountPeople = _state.accountsPeople.findIndex(
      a => a.accountNumber === _action.payload.accountNumber
    );

    // Delete trusted contact
    if (_action.payload.isDeleted) {
      output = {
        accountsPeople: [
          ..._state.accountsPeople.filter(a => a.accountNumber !== _action.payload.accountNumber),
          {
            ..._state.accountsPeople[indexOfAccountPeople],
            accountPeople: {
              ..._state.accountsPeople[indexOfAccountPeople].accountPeople,
              trustedContacts: [],
            },
          },
        ],
        selectedAccountPeople: _state.selectedAccountPeople,
      };
    } else {
      output = _state;
    }

    return output;
  }),
  on(addUpdateInterestedPartyAction, (_state, _action) => {
    let output: AccountPeopleState;
    const indexOfAccountPeople = _state.accountsPeople.findIndex(
      a => a.accountNumber === _action.payload.accountNumber
    );
    const indexOfInterestedParty = _state.accountsPeople[
      indexOfAccountPeople
    ].accountPeople.interestedParties.findIndex(t => t.id === _action.payload.interestedParty.id);

    if (indexOfInterestedParty >= 0) {
      // Update interested party
      output = {
        accountsPeople: [
          ..._state.accountsPeople.filter(a => a.accountNumber !== _action.payload.accountNumber),
          {
            ..._state.accountsPeople[indexOfAccountPeople],
            accountPeople: {
              ..._state.accountsPeople[indexOfAccountPeople].accountPeople,
              interestedParties: _state.accountsPeople[indexOfAccountPeople].accountPeople.interestedParties.map(t =>
                t.id === _action.payload.interestedParty.id ? _action.payload.interestedParty : t
              ),
            },
          },
        ],
        selectedAccountPeople: _state.selectedAccountPeople,
      };
    } else {
      // Add interested party
      output = {
        accountsPeople: [
          ..._state.accountsPeople.filter(a => a.accountNumber !== _action.payload.accountNumber),
          {
            ..._state.accountsPeople[indexOfAccountPeople],
            accountPeople: {
              ..._state.accountsPeople[indexOfAccountPeople].accountPeople,
              interestedParties: [
                ..._state.accountsPeople[indexOfAccountPeople].accountPeople.interestedParties,
                _action.payload.interestedParty,
              ],
            },
          },
        ],
        selectedAccountPeople: _state.selectedAccountPeople,
      };
    }

    return output;
  }),
  on(signOut, () => {
    return initialAccountPeopleState;
  })
);

export function accountPeopleReducer(state: AccountPeopleState, action: Action) {
  return _reducer(state, action);
}
