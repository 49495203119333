import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { BillPayRouterHelper } from 'bill-pay/bill-pay-router.helper';
import { BillPayHelper } from 'bill-pay/bill-pay.helper';
import { ModalService } from 'services/modal.service';
import { IBillPayService } from 'services/typings/IBillPayService';

import {
  BILLPAYHELPER,
  BILLPAYROUTERHELPER,
  BILLPAYSERVICE,
  MODALSERVICE,
  SERVICEHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { EBillsStatus, Recipient } from '@app/bill-pay/typings';
import { STATE } from '@core/tokens';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-estatements-tab',
  templateUrl: './estatements-tab.component.html',
  styleUrls: ['./estatements-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class EstatementsTabComponent implements OnInit {
  @Input() recipient: Recipient;
  @Input() amountOpt: number;
  @Output() setAmountOpt: EventEmitter<number> = new EventEmitter<number>();

  eStatements: EBill[];
  isLoading: boolean;
  isBusy: boolean;
  constructor(
    @Inject(SERVICEHELPER) private readonly serviceHelper: IServiceHelper,
    @Inject(BILLPAYSERVICE) private readonly billPayService: IBillPayService,
    @Inject(BILLPAYHELPER) private readonly billPayHelper: BillPayHelper,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper,
    @Inject(MODALSERVICE) private readonly modalService: ModalService,
    @Inject(STATE) private readonly stateService: IStateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.recipient.eBillsStatus === 0) {
      this.loadeStatementsForRecipient(this.recipient.payeeId);
    }
  }

  getStatement(eBillId: number): void {
    this.billPayHelper.getEBillStatement(eBillId);
  }

  fixEBills(): void {
    // If the status is NoLongerSupported, then call DeactivateeBills;
    if (this.recipient.eBillsStatus === EBillsStatus.ErrorNoLongerSupported) {
      this.deactivateEBill();
    } else {
      // otherwise, call the new service
      this.resolveEBillsError();
    }
  }

  markAsPaid(eBillId: number) {
    this.isLoading = true;
    this.billPayService
      .markEBillAsPaid(this.recipient.payeeId, eBillId)
      .then(() => {
        this.loadeStatementsForRecipient(this.recipient.payeeId);
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isLoading = false;
        this.changeDetector.markForCheck();
      });
  }

  confirmTurnOff() {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Turn Off eBills',
          cancelText: 'Cancel',
          okButtonClass: 'uk-btn solid secondary lg',
          bodyText: `<h3>Are you sure you want to turn off eBills for ${this.recipient.displayName}?</h3>
                <br><br><div><strong>NOTE:</strong> Future scheduled payments and historical bills will no longer be available.<div>`,
        }
      )
      .then(() => {
        this.deactivateEBill();
      })
      .catch(() => {
        return;
      });
  }

  gotoSchedule() {
    this.stateService.go('udb.billPay.schedulePayment', { recipient: this.recipient, callback: 'ebill' });
  }

  goToEStatements() {
    this.stateService.go('udb.billPay.eStatements', { recipientId: this.recipient.payeeId });
  }

  private resolveEBillsError(): void {
    this.isLoading = true;
    this.billPayRouterHelper.setRecipientDetails(this.recipient);
    this.billPayService
      .resolveEBillsError(this.recipient.payeeId)
      .then(response => {
        // if it asks for credentials, send the user to the login page
        if (response.data.credentials != null && response.data.credentials.length > 0) {
          this.billPayRouterHelper.setCredentials(response.data.credentials);
          this.billPayRouterHelper.setSessionId(response.data.manageEBillsSessionId);
          this.stateService.go('udb.billPay.eStatements', { recipientId: this.recipient.payeeId });
        } else {
          // check the getEbillStatus
          const timeout = new Date(response.data.callBackTime).getTime() - new Date(response.data.timestamp).getTime();
          setTimeout(() => {
            this.billPayHelper
              .checkForFurtherActions(response.data)
              .then(() => {
                this.isLoading = false;
              })
              .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
          }, timeout);
        }
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
        this.isLoading = false;
        this.changeDetector.markForCheck();
      });
  }

  private loadeStatementsForRecipient(payeeId: number) {
    this.eStatements = [];
    this.isLoading = true;
    this.billPayService
      .getEBillHistory(null, null, payeeId)
      .then(response => {
        response.data.forEach(payee => {
          payee.eBills.forEach(ebill => this.eStatements.push(ebill));
        });
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
        this.isLoading = false;
        this.changeDetector.markForCheck();
      });
  }

  private deactivateEBill() {
    this.isBusy = true;
    this.billPayService
      .deactivateEBill(this.recipient.payeeId)
      .then(() => {
        this.recipient.eBillsStatus = 1;
        this.amountOpt = 2;
        this.setAmountOpt.emit(this.amountOpt);
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isBusy = false;
        this.changeDetector.markForCheck();
      });
  }
}
