import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { billPayComponents } from './components/Index';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [...billPayComponents],
  exports: [...billPayComponents],
  entryComponents: [...billPayComponents],
  imports: [CommonModule, FormsModule, SharedModule, MatDatepickerModule, MatTabsModule],
})
export class BillPayModule {}
