import { AlertsNotificationsController } from './alerts-notifications.controller';
import { Inject } from '../decorators/decorators';

@Inject('$stateProvider')
export class AlertsNotificationsConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.alertsNotifications', {
      url: '/AlertsNotifications',
      views: {
        content: {
          templateUrl: 'alerts-notifications/alerts-notifications.tpl.html',
          controller: AlertsNotificationsController,
          controllerAs: 'anc',
        },
      },
    });
  }
}
