<!-- Name -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Name</mat-label>
  <input name="name" #inputName matInput uk2Input type="text" [formControl]="name" maxlength="40" minlength="1" />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!name.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear name"
    (mousedown)="clearName()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="name.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ nameErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- Street Address -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Street Address</mat-label>
  <input
    name="streetAddress"
    #inputStreetAddress
    matInput
    uk2Input
    type="text"
    [formControl]="streetAddress"
    maxlength="40"
  />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!streetAddress.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear street address"
    (mousedown)="clearStreetAddress()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="streetAddress.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ streetAddressErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- Apt / Unit (optional) -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Apt / Unit (optional)</mat-label>
  <input
    name="additionalAddress"
    #inputAdditionalAddress
    matInput
    uk2Input
    type="text"
    [formControl]="additionalAddress"
    maxlength="40"
  />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!additionalAddress.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear additional address"
    (mousedown)="clearAdditionalAddress()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="additionalAddress.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ additionalAddressErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- Zip Code -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Zip Code</mat-label>
  <input
    name="zipCode"
    #inputZipCode
    matInput
    uk2Input
    type="text"
    [formControl]="zipCode"
    maxlength="5"
    minlength="5"
  />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!zipCode.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear zip code"
    (mousedown)="clearZipCode()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="zipCode.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ zipCodeErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- City -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>City</mat-label>
  <input name="city" #inputCity matInput uk2Input type="text" [formControl]="city" maxlength="50" />
  <button
    class="uk2-input-clear-button"
    *ngIf="!!city.value && showClearFieldButton"
    matSuffix
    tabIndex="-1"
    mat-icon-button
    aria-label="Clear city"
    (mousedown)="clearCity()"
  >
    <mat-icon [svgIcon]="clearButtonSvgIconName"></mat-icon>
  </button>
  <mat-error *ngIf="city.invalid">
    <mat-icon [svgIcon]="exclamationTriangleSvgIconName"></mat-icon>
    {{ cityErrorMessageSubject | async }}
  </mat-error>
</mat-form-field>
<!-- State -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>State</mat-label>
  <mat-select name="state" uk2Dropdown [formControl]="state">
    <ng-container *ngFor="let stateCode of statesKeys">
      <mat-option [value]="stateCode">{{ states[stateCode] }}</mat-option>
    </ng-container>
  </mat-select>
  <mat-error>State is required</mat-error>
</mat-form-field>
<!-- Documents Received -->
<mat-form-field class="form-field" appearance="outline" floatLabel="always">
  <mat-label>Documents Received</mat-label>
  <mat-select
    uk2Dropdown
    [uk2DropdownSize]="dropdownSize"
    [uk2CSSProperties]="customCSSProperties"
    [formControl]="documentsReceived"
    multiple
  >
    <mat-select-trigger>
      <mat-chip-listbox>
        <mat-chip-option
          uk2TagInputChips
          *ngFor="let document of documentsReceived.value"
          (removed)="onItemRemoved(document)"
        >
          {{ document }}
          <mat-icon matChipRemove svgIcon="uk2-x"></mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </mat-select-trigger>

    <mat-option class="document-option" *ngFor="let document of documentList" [value]="document">
      {{ document }}
    </mat-option>
  </mat-select>
</mat-form-field>
