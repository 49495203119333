import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ROOT_SCOPE } from '@core/tokens';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { TxFilter } from '../../types';
import { FiltersHelperService } from '../../services';

@Component({
  selector: 'app-tx-aggregation-filters',
  templateUrl: './tx-aggregation-filters.component.html',
  styleUrls: ['./tx-aggregation-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TxAggregationFiltersComponent implements OnInit, OnChanges, OnDestroy {
  // Bound properties
  filter: UntypedFormGroup;
  defaultFilters: TxFilter;

  @Input() filters: TxFilter;
  @Input() isLoading: boolean;
  @Input() account;
  @Input() inModal: boolean;
  @Input() displayFilters = false;

  @Output() onFiltersChange = new EventEmitter<any>();
  @Output() onModalFiltersChange = new EventEmitter<any>();
  @Output() onClearFilter = new EventEmitter<any>();

  // Properties to manage the controller state
  transactionsType: GenericOption[];
  timePeriodType: GenericOption[];
  filtersApplied: string[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private filtersHelperService: FiltersHelperService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.createForm();
    this.setFilterOptions();
    this.setFilterEvents();

    this.root.$on('filtersAppliedChange', (_e, obj) => {
      this.removeFilterApplied(obj);
    });
    this.root.$on('filtersReset', (_e, _obj) => (this.filtersApplied = []));
  }

  /**
   * Listen for any change in the account property
   * @param changes Object containing bound properties changes
   */
  ngOnChanges(changes: SimpleChanges) {
    const { filters } = changes;

    if (!!filters && !filters.isFirstChange() && !!filters.currentValue && !!filters.previousValue)
      this.checkFilterChanges(filters.currentValue, filters.previousValue);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Assign the new filter value to the filters object
   * @param value Filter value
   * @param propertyName Actual filter name
   */
  setFilterValue(value: any, propertyName: string) {
    let filterValue = value;
    if (propertyName == 'transactionType' || propertyName == 'days') {
      filterValue =
        propertyName == 'transactionType'
          ? this.transactionsType.find(val => val.value == value)
          : this.timePeriodType.find(val => val.value == value);
    }
    if (propertyName == 'amountRange' || propertyName == 'checkRange') {
      filterValue = { min: value.rangeValues.min, max: value.rangeValues.max };
    }
    this.filters = this.filtersHelperService.setFilterValue(this.filters, filterValue, propertyName);

    this.filtersApplied = this.filtersHelperService.setFiltersApplied(propertyName, this.filtersApplied);

    if (
      ['transactionType', 'days'].some(x => x === propertyName) &&
      this.areDropdownFiltersSameAsDefault(filterValue, propertyName)
    ) {
      this.removeFilterApplied(propertyName);
    }

    // Triggers the filter function
    const emitPayload = { filters: this.filters, filtersApplied: { ...this.filtersApplied } };
    !!this.inModal ? this.onModalFiltersChange.emit(emitPayload) : this.onFiltersChange.emit(emitPayload);
  }

  /**
   * This methods responds to 'Clear Filters',
   * removes the filter then triggers the filter update fn
   * @param filterName Filter to be removed from the tags
   */
  clearFilter(filterName: string) {
    this.removeFilterApplied(filterName);

    // Triggers the filter function
    this.onClearFilter.emit({ filterName });
  }

  filterIsActive(filterName: string) {
    return !!this.filtersApplied.find(name => name === filterName);
  }

  private createForm() {
    this.filter = this.formBuilder.group({
      dateRange: [],
    });
  }

  private setFilterOptions() {
    this.transactionsType = [
      { value: 0, subvalue: '', label: 'All transactions', default: true },
      { value: 1, subvalue: 'C', label: 'Deposits' },
      { value: 2, subvalue: 'D', label: 'Withdrawals' },
    ];

    this.timePeriodType = [
      { value: 30, label: 'Past 30 days' },
      { value: 60, label: 'Past 60 days' },
      { value: 90, label: 'Past 90 days', default: true },
      { value: 180, label: 'Past 180 days' },
      { value: 365, label: 'Past 12 months' },
      { value: 730, label: 'Past 24 months' },
      { value: 1095, label: 'Past 36 months' },
    ];
    this.defaultFilters = this.filters;
  }

  private setFilterEvents() {
    this.filter.controls.dateRange.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(value => {
          if (!!value) {
            const dateRange = { start: value.min, end: value.max };
            this.setFilterValue(dateRange, 'dateRange');
          } else {
            this.clearFilter('dateRange');
          }
        })
      )
      .subscribe();
  }

  private removeFilterApplied(filterName: string) {
    this.filtersApplied = this.filtersApplied.filter(name => name !== filterName);
  }

  private checkFilterChanges(previousFilters: TxFilter, currentFilters: TxFilter) {
    switch (true) {
      case previousFilters.amount !== currentFilters.amount:
        this.removeFilterApplied('amount');
        break;
      case previousFilters.amountRange !== currentFilters.amountRange:
        this.removeFilterApplied('amountRange');
        break;
      case previousFilters.check !== currentFilters.check:
        this.removeFilterApplied('check');
        break;
      case previousFilters.checkRange !== currentFilters.checkRange:
        this.removeFilterApplied('checkRange');
        break;
      case previousFilters.dateRange !== currentFilters.dateRange:
        this.removeFilterApplied('dateRange');
        break;
    }
  }

  /**Determines if the values for the filters are the same as default */
  private areDropdownFiltersSameAsDefault(value: any, propertyName: string): boolean {
    const defaultValue = this.defaultFilters[propertyName];

    const result = defaultValue.label == value.label;
    return result;
  }
}
