import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';

import { DirectDepositUserActions } from '../enums/direct-deposit-user-actions.enum';

export function DirectDepositInterceptAction(userAction: DirectDepositUserActions) {
  let eventName = '';
  let description = 'Clicked';
  let fieldName = 'Customer';

  switch (userAction) {
    case DirectDepositUserActions.IncomeSource:
      eventName = '3rdPartyHRClick';
      break;
    case DirectDepositUserActions.IncomeSourceSpeedbump:
      eventName = 'IncomeSourceSpeedbump';
      fieldName = 'Customer Selected';
      description = 'Yes';
      break;
    case DirectDepositUserActions.CheckRequestStatus:
      eventName = 'CheckRequestStatusClick';
      break;
    case DirectDepositUserActions.SubmittableReviewContinue:
      eventName = 'SubmittableReviewContinue';
      description = 'Submitted';
      break;
    case DirectDepositUserActions.PrintedForm:
      eventName = 'PrintedFormClick';
      description = 'Submitted';
      break;
  }

  return new UserAction(eventName, [new UserActionField(fieldName, null, description)]);
}
