import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  BILLPAYROUTERHELPER,
  LOAD_USER_PROFILE_HELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';

import { PaymentData, UserAccountType } from '@app/bill-pay/typings';
import { BillPayRouterHelper } from '@app/bill-pay/utils';
import { STATE } from '@core/tokens';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-pay-confirmation',
  templateUrl: './pay-confirmation.component.html',
  styleUrls: ['./pay-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class PayConfirmationComponent implements OnInit {
  payments: PaymentData[] = [];
  email: string;
  accountFrom: UserAccountType;
  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper,
    @Inject(LOAD_USER_PROFILE_HELPER) private readonly loadUserProfileHelper: ILoadUserProfileHelper
  ) {}

  ngOnInit(): void {
    this.payments = this.billPayRouterHelper.getPaymentData();
    this.accountFrom = this.billPayRouterHelper.getAccountFrom();
    if (!this.payments) {
      this.state.go('udb.billPay.pay');
    }
    const profile = this.loadUserProfileHelper.getUserProfile();
    this.email = profile ? profile.primaryEmail : '';
  }

  redirectToPay() {
    this.state.go('udb.billPay.pay');
  }
}
