import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

import tippy, { animateFill, Props, roundArrow } from 'tippy.js';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopOverComponent implements AfterViewInit {
  @ViewChild('tooltip') tooltip: ElementRef;
  @Input() white = false;

  @Input() set placement(placement: Props['placement']) {
    this.options.placement = placement;
  }

  @Input() set content(content: Props['content']) {
    this.options.content = content;
  }

  @Input() innerText;

  options: Partial<Props> = {
    allowHTML: true,
    animateFill: true,
    arrow: roundArrow,
    placement: 'bottom',
    plugins: [animateFill],
    theme: 'olb',
    interactive: true,
    appendTo: 'parent',
    hideOnClick: true,
    trigger: 'click',
  };

  ngAfterViewInit(): void {
    tippy(this.tooltip.nativeElement, this.options);
  }
}
