import { UserAccountType } from 'typings/app/bills/UserAccountType';
import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';

export const USER_BUSINESSES_KEY = 'userBusinesses';

export function computeSolePropData(
  userCif: string,
  userId: number,
  paymentAccounts: UserAccountType[],
  accountProfiles: AccountProfile[]
): SolePropData {
  const hasSoleProp = paymentAccounts.some(
    acc => acc.businessCif === userCif && acc.isSBB
  );

  const businessId = hasSoleProp
    ? userId
    : accountProfiles?.filter(
        acc => acc.profileType === AccountProfileType.Business
      )[0]?.id ?? null;

  return {
    businessId: businessId,
    isSoleProp: hasSoleProp,
    dialogRef: undefined
  };
}

export type SolePropData = {
  businessId: number | undefined;
  isSoleProp: boolean | undefined;
  dialogRef: any;
};
