import { AccountPeopleTabState } from './account-people-tab-state.type';

export type AccountPeopleState = {
  accountsPeople: AccountPeopleTabState[];
  selectedAccountPeople: string | undefined;
};

export const initialAccountPeopleState: AccountPeopleState = {
  accountsPeople: [],
  selectedAccountPeople: undefined,
};
