import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Props, animateFill, roundArrow } from 'tippy.js';

@Component({
  selector: 'app-tooltip-shadowdom',
  templateUrl: './tooltip-shadowdom.component.html',
  styleUrls: ['./tooltip-shadowdom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TooltipShadowdomComponent {
  @Input() tooltip: string = '';

  options: Partial<Props> = {
    allowHTML: true,
    animateFill: true,
    arrow: roundArrow,
    placement: 'bottom',
    plugins: [animateFill],
    theme: 'olb',
    interactive: true,
    appendTo: 'parent',
  };
  @Input() set content(content: Props['content']) {
    this.options.content = content;
  }
}
