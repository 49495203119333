import { Pipe, PipeTransform } from '@angular/core';

import { truncate } from '@app/utils';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length = 10, end = '...'): string {
    return truncate(value, length, end);
  }
}
