import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ActionFromModal, ModalAction, ModalPersonType } from './enums';
import {
  Uk2ButtonSizeEnum,
  Uk2ModalActionsGridEnum,
  Uk2ModalComponent,
  Uk2ModalFontWeightEnum,
  Uk2ModalTitleAlignEnum,
  Uk2Tier1UtilityEnum,
  Uk2ToastComponent,
  Uk2ToastTypeEnum,
} from '@axos/uikit-v2-lib';
import { Uk2Modal } from './types';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DOCUMENTS_RECEIVED, TOAST_BODY_TEXT } from './constants';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { takeUntil, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AddInterestedPartyRequest,
  AddTrustedContactRequest,
  DeleteTrustedContactRequest,
  EditInterestedPartyRequest,
  EditTrustedContactRequest,
  InterestedParty,
} from '@app/Areas/AAS/features/account-details/core/services/account-people';
import { TrustedContactsType } from '@app/Areas/AAS/features/account-details/core';
import { BrandingFacade } from '@app/Areas/AAS/features/branding';
import { RemovePersonConfirmationModalComponent } from './components';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-trusted-interested-modal',
  templateUrl: './trusted-interested-modal.component.html',
  styleUrls: ['./trusted-interested-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustedInterestedModalComponent implements OnChanges, OnDestroy {
  @ViewChild('modal') modal?: Uk2ModalComponent;
  @ViewChild('removePersonModal') removePersonModal?: RemovePersonConfirmationModalComponent;
  @Input() personType: ModalPersonType;

  @Input() modalAction: ModalAction = ModalAction.Add;
  @Input() showModal = false;
  @Input() data: TrustedContactsType | InterestedParty;
  @Input() accountNumber: string;
  @Output() modalClosed = new EventEmitter();

  readonly uk2ButtonSize = Uk2ButtonSizeEnum;
  readonly modalActionType = ModalAction;
  readonly modalPersonType = ModalPersonType;

  titleFontWeight = Uk2ModalFontWeightEnum.bold400;
  actionsGrid = Uk2ModalActionsGridEnum.side;
  titleAlign = Uk2ModalTitleAlignEnum.center;
  bodyContentAlignment = Uk2ModalTitleAlignEnum.left;
  svgIconName = Uk2Tier1UtilityEnum.trash;
  toastDurationInSeconds = 5;
  modalData: Uk2Modal = {
    title: '',
    disabledCloseOverlayBackdrop: true,
    showCloseButton: false,
    showTitleDivider: true,
    showActionsDivider: true,
    actionsGrid: this.actionsGrid,
    titleFontWeight: this.titleFontWeight,
    titleAlign: this.titleAlign,
    bodyContentAlignment: this.bodyContentAlignment,
  };
  isLoading = false;
  isFormDirty = false;
  trustedInterestedModalForm: FormGroup = this.fb.group({});

  private destroy$ = new Subject<void>();
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private peopleFacade: PeopleFacade,
    private brandingFacade: BrandingFacade,
    private snackBar: MatSnackBar
  ) {}

  get trustedContactFormModal(): any {
    return this.trustedInterestedModalForm.get('trustedContactFormModal');
  }

  get firstName(): any {
    return this.trustedContactFormModal.get('firstName');
  }

  get lastName(): any {
    return this.trustedContactFormModal.get('lastName');
  }

  get suffix(): any {
    return this.trustedContactFormModal.get('suffix');
  }

  get email(): any {
    return this.trustedContactFormModal.get('email');
  }
  get phoneNumber(): any {
    return this.trustedContactFormModal.get('phoneNumber');
  }
  get relationShip(): any {
    return this.trustedContactFormModal.get('relationShip');
  }

  get interestedPartyFormModal(): any {
    return this.trustedInterestedModalForm.get('interestedPartyFormModal');
  }

  get name(): any {
    return this.interestedPartyFormModal.get('name');
  }

  get streetAddress(): any {
    return this.interestedPartyFormModal.get('streetAddress');
  }

  get additionalAddress(): any {
    return this.interestedPartyFormModal.get('additionalAddress');
  }

  get zipCode(): any {
    return this.interestedPartyFormModal.get('zipCode');
  }

  get city(): any {
    return this.interestedPartyFormModal.get('city');
  }

  get state(): any {
    return this.interestedPartyFormModal.get('state');
  }

  get documentsReceived(): any {
    return this.interestedPartyFormModal.get('documentsReceived');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges() {
    if (this.showModal) {
      this.setModalConfig();
      this.openModal();
    }
  }

  closeModal() {
    this.trustedInterestedModalForm.removeControl('trustedContactFormModal');
    this.trustedInterestedModalForm.removeControl('interestedPartyFormModal');
    this.modal?.closeDialog();
    this.isFormDirty = false;
    this.modalClosed.emit();
  }

  submit() {
    if (this.personType === ModalPersonType.TrustedContact) {
      this.submitTrustedContact();
    }
    if (this.personType === ModalPersonType.InterestedParty) {
      this.submitInterestedParty();
    }
  }

  openRemoveModal() {
    this.removePersonModal.openModal();
  }

  onCloseRemoveModal() {
    this.brandingFacade.loadBranding('.uk2-custom-modal-white-label');
    this.changeDetectorRef.detectChanges();
  }

  deleteItem() {
    let input: DeleteTrustedContactRequest;
    input = { accountNumber: this.accountNumber };
    if (this.personType === ModalPersonType.TrustedContact) {
      this.peopleFacade
        .deleteTrustedContact(input)
        .pipe(tap(response => this.handleTrustedContactToast(response, ActionFromModal.TrustedContactDelete)))
        .subscribe();
    }
  }

  private openModal() {
    this.modal?.openDialog();
    this.trustedInterestedModalForm.removeControl('trustedContactFormModal');
    this.trustedInterestedModalForm.removeControl('interestedPartyFormModal');
    this.brandingFacade.loadBranding('.uk2-custom-modal-white-label');
    this.initializeSubscriptions();
  }

  private setModalConfig() {
    this.modalData.actionsGrid =
      this.modalAction === ModalAction.Edit ? Uk2ModalActionsGridEnum.sideSpaced : Uk2ModalActionsGridEnum.side;
    this.modalData.title = `${this.modalAction} ${this.personType}`;
    this.changeDetectorRef.detectChanges();
  }

  private submitTrustedContact() {
    let input: AddTrustedContactRequest | EditTrustedContactRequest;
    input = {
      accountNumber: this.accountNumber,
      trustedContact: {
        id: Number(this.accountNumber),
        prefixType: null, // Removed from AC.
        prefixDisplay: null, // Removed from AC.
        firstName: this.firstName.value.trim(),
        lastName: this.lastName.value.trim(),
        suffixType: this.suffix.value ? this.suffix.value.toUpperCase() : null,
        relationship: this.relationShip.value,
        phoneCountryCode: '1',
        phoneNumber: this.phoneNumber.value,
        email: this.email.value,
        suffixDisplay: this.suffix.value ? `${this.suffix.value}.` : null,
        phoneNumberCleaned: this.phoneNumber.value,
        phoneNumberFormatted: this.formatNumber(this.phoneNumber.value),
      },
    };
    if (this.modalAction === ModalAction.Add) {
      this.peopleFacade
        .addTrustedContact(input)
        .pipe(tap(response => this.handleTrustedContactToast(response, ActionFromModal.TrustedContactAdd)))
        .subscribe();
    } else {
      this.peopleFacade
        .editTrustedContact(input)
        .pipe(tap(response => this.handleTrustedContactToast(response, ActionFromModal.TrustedContactEdit)))
        .subscribe();
    }
  }

  private handleTrustedContactToast(output: any, action: ActionFromModal) {
    this.closeModal();
    if (action === ActionFromModal.TrustedContactAdd) {
      if (output.apiCallWasSuccessful) {
        this.displayToast(TOAST_BODY_TEXT.trustedContactAddSuccess, Uk2ToastTypeEnum.success);
      } else {
        this.displayToast(TOAST_BODY_TEXT.trustedContactAddFailure, Uk2ToastTypeEnum.alert);
      }
    }

    if (action === ActionFromModal.TrustedContactEdit) {
      if (output.apiCallWasSuccessful) {
        this.displayToast(TOAST_BODY_TEXT.trustedContactUpdateSuccess, Uk2ToastTypeEnum.success);
      } else {
        this.displayToast(TOAST_BODY_TEXT.trustedContactUpdateFailure, Uk2ToastTypeEnum.alert);
      }
    }

    if (action === ActionFromModal.TrustedContactDelete) {
      this.removePersonModal.closeModal(false);
      if (output.apiCallWasSuccessful) {
        this.displayToast(TOAST_BODY_TEXT.trustedContactDeleteSuccess, Uk2ToastTypeEnum.success);
      } else {
        this.displayToast(TOAST_BODY_TEXT.trustedContactDeleteFailure, Uk2ToastTypeEnum.alert);
      }
    }
  }

  private submitInterestedParty() {
    let input: AddInterestedPartyRequest | EditInterestedPartyRequest;
    input = {
      accountNumber: this.accountNumber,
      interestedParty: {
        id: this.data?.id ? this.data?.id : null,
        name: this.name.value.trim(),
        address: {
          address1: this.streetAddress.value.trim(),
          address2: this.additionalAddress.value.trim(),
          address3: null, // Removed from AC.
          city: this.city.value.trim(),
          state: this.state.value,
          zipCode: this.zipCode.value,
        },
        phones: null, // Removed from AC.
        taxForms: this.documentsReceived.value.includes(DOCUMENTS_RECEIVED.TAX_FORMS),
        statementForms: this.documentsReceived.value.includes(DOCUMENTS_RECEIVED.STATEMENTS),
        transferOnDeath: null, // Removed from AC.
        todType: null, // Removed from AC.
        ipPercent: null, // Removed from AC.
        statementFrequency: this.documentsReceived.value.includes(DOCUMENTS_RECEIVED.STATEMENTS) ? 'Quarterly' : 'No',
      },
    };
    if (this.modalAction === ModalAction.Add) {
      this.peopleFacade
        .addInterestedParty(input)
        .pipe(tap(response => this.handleInterestedPartyToast(response, ActionFromModal.InterestedPartyAdd)))
        .subscribe();
    } else {
      this.peopleFacade
        .editInterestedParty(input)
        .pipe(tap(response => this.handleInterestedPartyToast(response, ActionFromModal.InterestedPartyEdit)))
        .subscribe();
    }
  }

  private handleInterestedPartyToast(output: any, action: ActionFromModal) {
    this.closeModal();
    if (action === ActionFromModal.InterestedPartyAdd) {
      if (output.apiCallWasSuccessful) {
        this.displayToast(TOAST_BODY_TEXT.interestedPartyAddSuccess, Uk2ToastTypeEnum.success);
      } else {
        this.displayToast(TOAST_BODY_TEXT.interestedPartyAddFailure, Uk2ToastTypeEnum.alert);
      }
    }

    if (action === ActionFromModal.InterestedPartyEdit) {
      if (output.apiCallWasSuccessful) {
        this.displayToast(TOAST_BODY_TEXT.interestedPartyUpdateSuccess, Uk2ToastTypeEnum.success);
      } else {
        this.displayToast(TOAST_BODY_TEXT.interestedPartyUpdateFailure, Uk2ToastTypeEnum.alert);
      }
    }
  }

  private displayToast(message: string, type: Uk2ToastTypeEnum) {
    this.snackBar.openFromComponent(Uk2ToastComponent, {
      duration: this.toastDurationInSeconds * 1000,
      data: {
        message: message,
        type: type,
      },
    });
  }

  private formatNumber(phoneNumber: string): string {
    return phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6);
  }

  private initializeSubscriptions() {
    this.peopleFacade.isLoading$
      .pipe(
        takeUntil(this.destroy$),
        tap(isLoading => {
          this.isLoading = isLoading;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }
}
