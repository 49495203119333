import { Component, OnInit } from '@angular/core';

import { OmnichannelService } from '@legacy/services/omnichannel.service';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-chat-switcher',
  templateUrl: './chat-switcher.component.html',
})
export class ChatSwitcherComponent implements OnInit {
  isBusy: boolean;
  isChatbotActive: boolean;

  constructor(
    private omnichannelService: OmnichannelService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.isBusy = true;
    this.omnichannelService
      .isFeatureActive()
      .then(response => {
        this.isChatbotActive = response.data;
        if (this.isChatbotActive) {
          this.removeLiveChatIfExist();
        }
        this.isBusy = false;
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }

  removeLiveChatIfExist() {
    document.querySelectorAll('[class*=embeddedService]').forEach(element => {
      element.parentNode.removeChild(element);
    });
  }
}
