import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { loadBrandProperties } from '@app/store/actions';
import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';

import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q', '$rootScope', 'olbEventService', 'ngrxStore')
export class BrandPropertyService extends BaseService {
  constructor(
    http: ng.IHttpService,
    env: any,
    _serviceHelper: IServiceHelper,
    q: ng.IQService,
    private rootScope: ng.IRootScopeService,
    private olbEventService: OlbEventService,
    private store: Store
  ) {
    super(http, env, 'brand', _serviceHelper, q);
  }

  getBrandProperties(brandId: number, brand: string): Observable<OlbResponse<BrandProperty[]>> {
    return from(this.getBrandProps(brandId, brand)).pipe(
      tap(res => {
        const result: { [key: string]: string } = {};
        res.data.forEach(item => {
          result[item.name] = item.value;
        });
        this.rootScope['brandProperties'] = result;
        this.olbEventService.emit(OlbEvents.BrandPropertiesLoaded, result);
        this.store.dispatch(loadBrandProperties({ payload: res.data }));
        this.rootScope.$broadcast('brandPropertiesLoaded');
      })
    );
  }

  /**
   * Get the brand-specific values for live chat configuration:
   * DeploymentURL, ChatURL, DeploymentId, ButtonId, and ButtonId for guests.
   * @returns A list of properties.
   */
  getLiveChatProperties(brandId: number): ApiResponse<BrandProperty[]> {
    return this.get(`${brandId}/properties/livechat`);
  }

  /**
   * Get the brand-specific values for secure support configuration:
   * ScriptUrl: Cobrowse source path.
   * @returns A list of properties.
   */
  getSecureSupportProperties(brandId: number): ApiResponse<BrandProperty[]> {
    return this.get(`${brandId}/properties/glance`);
  }

  getChatbotProperties(brandId: number): ApiResponse<BrandProperty[]> {
    return this.get(`${brandId}/properties/chatbot`);
  }

  private getBrandProps(brandId: number, brand: string): ApiResponse<BrandProperty[]> {
    return this.get(`${brandId}/properties`, { brand });
  }
}
