<div class="eStatements-tab">
  <div class="row" *ngIf="isLoading">
    <div class="col-md-12">
      <app-dot-loader [showWhen]="isLoading">Loading</app-dot-loader>
    </div>
  </div>
  <div class="row" *ngIf="recipient.eBillsStatus === 1 && !isLoading">
    <div class="col-md-12">
      <p class="text-center empty not-recipient">
        eBills are not currently active for this recipient.
      </p>
      <button
        type="button"
        (click)="goToEStatements()"
        class="uk-btn outline primary icon-right lg pull center-block turn-on-btn"
      >
        Turn On eBills
      </button>
    </div>
  </div>
  <div class="row" *ngIf="recipient.eBillsStatus === 0 && !isLoading">
    <div class="col-md-6">
      <p class="title"><strong>Last 3 Bills</strong></p>
      <p class="info-statements" *ngFor="let eBill of eStatements">
        <a class="link" (click)="getStatement(eBill.eBillId)" *ngIf="eBill.hasStatement">
          <span>{{ eBill.paymentDueDate | date: 'MMMM dd, yyyy' }}</span>
        </a>
        <span *ngIf="!eBill.hasStatement">{{ eBill.paymentDueDate | date: 'MMMM dd, yyyy' }}</span>

        <a *ngIf="!eBill.paid" href="" class="pull-right" (click)="markAsPaid(eBill.eBillId)">
          Mark As Paid
        </a>
        <span *ngIf="!eBill.paid" class="spacer pull-right"></span>
        <a *ngIf="!eBill.paid" href="#" class="pull-right" (click)="gotoSchedule()">
          Pay
        </a>

        <span class="pull-right" *ngIf="eBill.paid">
          <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          Paid
        </span>
      </p>
    </div>
    <div class="col-md-6">
      <button
        type="button"
        (click)="confirmTurnOff()"
        [disabled]="isBusy"
        class="uk-btn outline primary right lg pull turn-on-btn"
      >
        Turn Off eBills
      </button>
    </div>
  </div>
  <div class="row" *ngIf="recipient.eBillsStatus > 2 && !isLoading">
    <div class="col-md-12">
      <p class="text-center empty">
        An error occurred when communicating with {{ recipient.nickName || recipient.displayName }}.
      </p>
      <button
        type="button"
        (click)="fixEBills()"
        class="uk-btn outline primary icon-right lg pull center-block turn-on-btn"
      >
        Fix eBills
      </button>
    </div>
  </div>
</div>
