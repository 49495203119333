<button class="sm-item" title="{{name}}">
  <div class="sm-item-container">
    <app-svg-icon
      *ngIf="icon"
      class="sm-item-container__image"
      width="auto"
      height="100%"
      [iconName]="icon"
    ></app-svg-icon>
    <span [innerText]="name" class="sm-item-container__text"></span>
  </div>
  <app-svg-icon
    iconName="navigation-chevron-right"
    iconColor="var(--link)"
    width="18px"
    height="18px"
  ></app-svg-icon>
</button>
