import { downgradeComponent } from '@angular/upgrade/static';
import { SecureFormsComponent } from '@app/secure-forms/components';
import * as angular from 'angular';

export const secureForms = angular
  .module('udb.secureForms', ['ui.router'])
  .directive('appSecureForms', downgradeComponent({ component: SecureFormsComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider) => {
      $stateProvider.state('udb.secureForms', {
        url: '/SecureForms',
        views: {
          content: {
            template: '<app-secure-forms></app-secure-forms>',
          },
        },
      });
    },
  ]).name;
