import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { olbSettings } from '@core/tokens';
import { BaseService } from '@core/services/base.service';
import { OlbSettings } from '@core/models';
import { AppDefaultPreferences, SaveUserPreferencesRequest, UserPreference } from './types';
import { WebApiResponse } from '@shared/models';
import { CookieHelperService } from '@app/core/services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient, private cookieHelper: CookieHelperService) {
    super(http, settings, 'aas/userPreferences');
  }

  getUserPreferences(): WebApiResponse<UserPreference[]> {
    const userId = this.cookieHelper.getUserId();
    return this.get<UserPreference[]>(`${userId}`);
  }

  getAppPreferences(): WebApiResponse<AppDefaultPreferences[]> {
    return this.get<AppDefaultPreferences[]>('app');
  }

  saveUserPreferences(request: SaveUserPreferencesRequest): WebApiResponse<UserPreference[]> {
    return this.post<UserPreference[]>(null, request.userPreferences);
  }
}
