<!--Header-->
<div class="header-row">
  <div class="header-title" [class.grayed-out]="getFilteredAccountsLength() == 0">{{ groupTitle }}</div>
  <div class="header-column" *ngIf="displayHeader">
    <div class="percentage-column d-none d-sm-block">
      <div class="column-content nowrap">
        % Change
      </div>
    </div>
    <div class="each-column d-none d-sm-block">
      <div class="column-content nowrap">
        Amt. Change
      </div>
    </div>
    <div class="each-column d-none d-sm-block">
      <div class="column-content nowrap">
        Acct. Balance
      </div>
    </div>
  </div>
  <div class="header-column d-block d-sm-none">
    <div class="each-column">
      <div class="column-content balance-group" [class.grayed-out]="getFilteredAccountsLength() == 0">
        {{ totalBalanceByGroup | currency }}
      </div>
    </div>
  </div>
</div>
<div class="d-none d-sm-block">
  <div class="filter-size" *ngIf="accounts?.length != getFilteredAccountsLength()">
    <span>{{ getFilteredAccountsLength() }} of</span>
    {{ accounts?.length }}
    <span>Account</span>
    <span *ngIf="accounts?.length > 1">s</span>
  </div>
  <div *ngFor="let account of accounts">
    <div class="account-row" *ngIf="!excludedAccountsId.has(account.globalId)">
      <!--FI Icon-->
      <div class="title-column">
        <img
          class="logo"
          *ngIf="providers && !account.isExternal"
          [src]="getImage(account)"
          [ngClass]="{ invest: account.isAxosInvest }"
        />
        <span class="logo" *ngIf="providers && account.isExternal">
          {{ getBankProfile(account) }}
        </span>
        <div class="text-overflow">
          <div class="account-fi text-overflow" *ngIf="getBankName(account) != null">
            {{ getBankName(account) }}
          </div>
          <a class="account-title-wrapper" (click)="goToDetails(account)">
            <span class="account-title text-overflow">{{ getAccountTitle(account) }}</span>
            <span class="account-title nowrap">{{ getAccountMask(account) }}</span>
          </a>
        </div>
      </div>
      <div class="historic-balances-column">
        <div class="percentage-column d-flex justify-content-end">
          <div class="column-content">
            <app-svg-icon [iconName]="getArrowPosition(account)" [iconColor]="'#8F8F8F'" width="10px"></app-svg-icon>
            <div class="percentage-change">{{ getPercentageChange(account) | percentageRound }}%</div>
          </div>
        </div>
        <div class="each-column d-flex justify-content-end">
          <div class="column-content amount-change">
            {{ getDisplayAmountChange(account) }}
          </div>
        </div>
        <div class="each-column">
          <div class="column-content account-balance">
            <div class="tooltip-message non-italic" *ngIf="account.credentialsHaveChanged">
              <span class="tooltip-message--icon">!</span>
              <span class="message">
                There was a problem accessing your account.
                <span class="message--action" (click)="goToRestoreConnection(account)">
                  Restore connection.
                </span>
              </span>
            </div>
            <div class="d-flex flex-column">
              <span [class.text-gray]="account.credentialsHaveChanged" class="text-right">
                {{ getAccountBalance(account) == null ? '$--' : (getAccountBalance(account) | currency) }}
              </span>
              <span *ngIf="account.credentialsHaveChanged" class="last-updated">
                {{ getTimePeriod(account.lastUpdated) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-block d-sm-none">
  <div class="filter-size" *ngIf="accounts?.length != getFilteredAccountsLength()">
    <span>{{ getFilteredAccountsLength() }} of</span>
    {{ accounts?.length }}
    <span>Account</span>
    <span *ngIf="accounts?.length > 1">s</span>
  </div>
  <div *ngFor="let account of accounts">
    <div class="account-row" *ngIf="!excludedAccountsId.has(account.globalId)">
      <!--FI Image-->
      <div>
        <img
          class="logo"
          *ngIf="providers && !account.isExternal"
          [src]="getImage(account)"
          [class.red-circle]="account.credentialsHaveChanged"
          [ngClass]="{ invest: account.isAxosInvest }"
        />
        <span class="logo" [class.red-circle]="account.credentialsHaveChanged" *ngIf="providers && account.isExternal">
          {{ getBankProfile(account) }}
        </span>
      </div>
      <div class="account-content-row-res">
        <div class="fi-balance-row" *ngIf="getBankName(account) != null">
          <div class="account-financialIns-res">
            <div class="text-overflow">
              {{ getBankName(account) }}
            </div>
          </div>
          <div class="account-balance-res">
            <div class="d-flex justify-content-end align-items-center">
              <div class="tooltip-message" *ngIf="account.credentialsHaveChanged">
                <span class="tooltip-message--icon">!</span>
                <span class="message">
                  There was a problem accessing your account.
                  <span class="message--action" (click)="goToRestoreConnection(account)">
                    Restore connection.
                  </span>
                </span>
              </div>
              <span [class.text-gray]="account.credentialsHaveChanged">
                {{ getAccountBalance(account) == null ? '$--' : (getAccountBalance(account) | currency) }}
              </span>
            </div>
          </div>
        </div>
        <div class="fi-balance-row">
          <div class="account-title-res text-overflow">
            <a class="text-overflow account-name" (click)="goToDetails(account)">
              {{ getAccountTitle(account) }}
            </a>
            <a class="nowrap" (click)="goToDetails(account)">&nbsp;{{ getAccountMask(account) }}</a>
          </div>
          <span *ngIf="account.credentialsHaveChanged" class="last-updated">
            {{ getTimePeriod(account.lastUpdated) }}
          </span>
          <div class="account-balance-res" *ngIf="getBankName(account) === null">
            <div class="d-flex justify-content-end align-items-center">
              <div class="tooltip-message" *ngIf="account.credentialsHaveChanged">
                <span class="tooltip-message--icon">!</span>
                <span class="message">
                  There was a problem accessing your account.
                  <span class="message--action" (click)="goToRestoreConnection(account)">
                    Restore connection.
                  </span>
                </span>
              </div>
              <span [class.text-gray]="account.credentialsHaveChanged">
                {{ getAccountBalance(account) == null ? '$--' : (getAccountBalance(account) | currency) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
