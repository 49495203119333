import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { StoreModule } from '@ngrx/store';

import { NgxMaskModule } from 'ngx-mask';

import { TransfersModule } from '@app/transfers/transfers.module';
import { AppStateFeatures } from '@core/enums';
import {
  axosInvestHelperProvider,
  axosInvestUrlHelperProvider,
  cachedAccountsServiceProvider,
  featureFlagServiceProvider,
} from '@core/providers';
import { SharedModule } from '@shared/shared.module';

import { investComponents } from './components';
import {
  AxosClearingService,
  AxosInvestDeleteTransferService,
  AxosInvestService,
  AxosInvestTransferService,
  AxosInvestUrlsService,
} from './services';
import { investReducers } from './store/reducers';
import { RouterLink } from '@angular/router';

const providers = [
  featureFlagServiceProvider,
  axosInvestUrlHelperProvider,
  axosInvestHelperProvider,
  AxosInvestService,
  AxosClearingService,
  cachedAccountsServiceProvider,
  AxosInvestDeleteTransferService,
  AxosInvestTransferService,
  AxosInvestUrlsService,
];

@NgModule({
  declarations: [...investComponents],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    TransfersModule,
    MatCheckboxModule,
    StoreModule.forFeature(AppStateFeatures.AxosInvest, investReducers),
    RouterLink,
  ],
  exports: [...investComponents],
  providers: [...providers],
})
export class AxosInvestModule {}
