import { NgModule } from '@angular/core';

import { ModelsModule } from '../../../../../models/models.module';
// import { ModelsHoldingsTabMatModule } from './models-holdings-tab-mat.module';
// import { ModelsHoldingsTabUk2Module } from './models-holdings-tab-uk2.module';
import { AdvisorContributionComponent } from './View/advisor-contribution.component';
import { StoreModule } from '@ngrx/store';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { authorizationsReducer, initialAuthorizationsStateType } from '../../../../core';

@NgModule({
  declarations: [AdvisorContributionComponent],
  imports: [
    ModelsModule,
    StoreModule.forFeature(AasStoreFeatures.AccountDetails, authorizationsReducer, {
      initialState: initialAuthorizationsStateType,
    }),
  ],
  exports: [AdvisorContributionComponent],
})
export class AdvisorContributionModule {}
