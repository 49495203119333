<div class="card-activated">
  <h4 class="mt-4">
    Your card has been activated.
  </h4>
  <h4 class="font-weight-bold">
    Now setup your PIN.
  </h4>

  <button
    id="see-how"
    type="button"
    class="card-activated-button link"
    (click)="toggleContent()"
    *ngIf="!isViewMoreVisible"
  >
    See How
  </button>

  <div *ngIf="isViewMoreVisible">
    <p class="card-activated-text">
      You may create a personal identification number (PIN) by calling:
    </p>
    <p class="card-activated-text">800-290-7893 (within U.S.)</p>
    <p class="card-activated-text">206-624-7998 (outside U.S.)</p>
    <p class="card-activated-text-small">
      * If this is a reissued or reactivated card, your 16-digit card number and PIN are the same. You do not need to
      set a new PIN.
    </p>

    <button id="view-less" type="button" class="card-activated-button link" (click)="toggleContent()">
      View Less
    </button>
  </div>
</div>
