import { downgradeComponent } from '@angular/upgrade/static';
import { InvestorCheckingComponent } from '../../FrontEnd/src/app/investor-checking/components';

export const InvestorCheckingModule = angular
  .module('udb.InvestorChecking', ['ui.router'])
  .directive('appInvestorChecking', downgradeComponent({ component: InvestorCheckingComponent }))
  .config([
    '$stateProvider',
    ($stateProvider: ng.ui.IStateProvider) => {
      $stateProvider.state('udb.InvestorChecking', {
        url: '/InvestorChecking',
        views: {
          content: {
            template: '<app-investor-checking></app-investor-checking>',
          },
        },
        params: {
          organizationData: null,
        },
      });
    },
  ]).name;
