export enum FeatureFlagType {
  Beneficiaries = 1,
  Minors,
  AxosInvest,
  AccountFeatures,
  MobileInterceptor,
  AxosTrading,
  Chatbot,
  ODSTransactions,
  ODSAccounts,
  PushNotifications,
  AccountAggregation,
  AccountAggregationEnhance,
  AccountAggregationForInternals,
  OutdatedContactInfo,
  IraEnhBase = 16, // IRA enhancements enabled for non IRA accounts
  IraEnhDira, // IRA enhancements enabled for deposit IRA accounts
  IraEnhTrad, // IRA enhancements enabled for trading IRA accounts
  InterstitialService,
  ChatbotNW,
  ChatbotUFB,
  ClickSwitch = 23,
  PFM2 = 25,
  ManagedPortfolios = 26,
  CorporateVeil = 27,
  SBBPersonalInfo = 28,
  PFM3 = 29,
  Blend,
  PFM3InternalTrans = 32,
  PFM3ExternalTrans = 33,
  SBBOpenAccount = 35,
  MPEditPortfolio = 41,
  GoogleAuthenticator = 45,
  MPOverview = 49,
  SDTFeature = 50,
  RewardsChecking = 51,
  PayoffCalc = 52,
  SDTMutualFunds = 53,
  PayoffFlow = 58,
  RiaPilot = 63,
  SBLOCMVP = 73,
  SBBActive = 74,
  SBBAccountsInPlanVertical = 75,
  RiaPilotTransactionOrderScheduled = 76,
  RiaPilotMoveMoney = 77,
  RiaPilotAddEditTrustedContacts = 78,
  RiaPilotAddEditIntertestedParty = 79,
  RiaPilotAddEditNickname = 80,
  EnableiPaySSO = 81,
  RiaPilotFirmContacts = 82,
  RiaPilotDocumentAlert = 83,
  RiaPilotPortfolioAllocation = 84,
  RiaPilotOpenCheckingAccount = 85,
  RiaPilotAccountSharing = 86,
  RiaModelsAndHoldingsSortManagement = 87,
  RiaPilotODL = 88,
  SBBNewExperienceForRelationshipUandV = 89,
  SBBShowAccountsToRelationshipQ = 90,
  RiaPilotAllowedAccounts = 91,
  isLoanPayOff = 95,
  RiaPilotContributionFrequency = 96,
  Web2Footer = 100,
  RiaPilotICODLDebitCard = 98,
  RequestLoanPayoffOutsystems = 102,
  RiaPilotHideAllTimeChange = 105,
  EnableNinthWaveWebConnect = 106,
  RiaPilotInsights = 107,
  RiaHoldingsRealTime = 108,
  RiaShareAccount = 109,
  RiaAuthorizeTransfers = 110,
  RiaPilotRealizedGainLoss = 114,
  PdpEnhancement = 120,
  MpMigration = 121,
  MpModelSelection = 122,
  MpPortfolioTab = 123,
  EditBeneficiary = 124,
  MpDistribution = 125,
  DecisionMatrix = 126,
  StatementsPage = 127,
  TransactionPage = 128,
  PerformancePage = 129,
  AccDetailsPage = 130,
  UpdateAddress = 131,
  ShowTotalUnrealizedGainLossForInvestmentOverview = 141,
}
