<div class="dialog-header" [style.backgroundImage]="backgroundUrl">
  <app-svg-icon [iconName]="exclamationIcon" size="5rem"></app-svg-icon>
</div>

<div class="mat-dialog-content">
  <p class="mb-3">
    We have detected some issues with the check image uploaded which may cause the check deposit to be declined. To
    continue with the current images uploaded, select Continue below. If you would like to upload a new check image(s),
    select Cancel and upload a new image.
  </p>

  <ul class="col-12 text-left mb-3" *ngFor="let risk of risks">
    <li class="risk">{{ risk }}</li>
  </ul>
</div>

<div mat-dialog-actions class="mt-3">
  <button class="btn-ok" type="button" [mat-dialog-close]="true" cdkFocusInitial>
    Continue
  </button>
  <button class="btn-cancel" type="button" [mat-dialog-close]="false">Cancel</button>
</div>
