export enum IncomeType {
  'Payroll' = 1,
  'Pension',
  'Annuity',
  'Investment',
  'Social Security',
  'Insurance',
  'Retirement',
  'Mutual Fund',
  'Federal Benefit',
  'VA',
  'Military',
  'State County Benefits',
  'Child Support',
  'Restitution',
}
