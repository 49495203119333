<div class="transaction-search">
  <div class="transaction-count">Transactions ({{ transactionCount }})</div>
  <div class="d-md-none transaction-expand" (click)="toggleIsCollapsed()">
    <app-svg-icon
      *ngIf="!isCollapsed"
      [iconName]="icons.ChevronDown"
      [styles]="{ width: '1.5rem', height: '1.5rem', fill: '#2F5B88', position: 'relative', top: '0.6rem' }"
    ></app-svg-icon>
    <app-svg-icon
      *ngIf="isCollapsed"
      [iconName]="icons.ChevronUp"
      [styles]="{ width: '1.5rem', height: '1.5rem', fill: '#2F5B88', position: 'relative', top: '0.6rem' }"
    ></app-svg-icon>
  </div>
  <div class="search" *ngIf="!isCollapsed">
    <app-search-filter (search)="searchTransaction($event)"></app-search-filter>
  </div>
  <div
    class="download-file font-smaller d-none d-md-block"
    [ngClass]="transactionCount <= 0 ? 'disable' : ''"
    (click)="downloadTransactions()"
  >
    <app-svg-icon [iconName]="icons.Download" [styles]="downloadStyle"></app-svg-icon>
    <span class="d-none d-lg-inline ml-2">
      Download Transactions
    </span>
  </div>
</div>

<ng-template #sortDesc>
  <app-svg-icon
    [iconName]="icons.SortDescArrow"
    [styles]="{ width: '1rem', height: '0.5rem', fill: '#0070D9' }"
  ></app-svg-icon>
</ng-template>
<ng-template #sortAsc>
  <app-svg-icon
    [iconName]="icons.SortAscArrow"
    [styles]="{ width: '1rem', height: '0.5rem', fill: '#0070D9' }"
  ></app-svg-icon>
</ng-template>

<h2 class="no-transactions mt-5 mb-5 d-md-none" *ngIf="transactionCount <= 0 && !loadingTransactions && isCollapsed">
  No transactions found
</h2>
<h2 class="no-transactions mt-5 mb-5 d-none d-md-block" *ngIf="transactionCount <= 0 && !loadingTransactions">
  No transactions found
</h2>
<!--  TODO: The header needs to be break into a different component. -->
<div class="grid-container transaction-list-header d-none d-md-flex" *ngIf="transactionCount > 0">
  <div
    class="grid-item date font-smaller text-center header-item clickable"
    (click)="sortBy('date', getNextSortOrder('date'))"
  >
    Date
    <span *ngIf="!(sortedBy === 'date' && sortedOrder == sortOrderEnum.Descending); else sortDesc"></span>
    <span *ngIf="!(sortedBy === 'date' && sortedOrder == sortOrderEnum.Ascending); else sortAsc"></span>
  </div>
  <div
    class="grid-item category font-smaller text-center header-item d-none d-lg-block clickable"
    (click)="sortBy('category', getNextSortOrder('category'))"
  >
    Category
    <span *ngIf="!(sortedBy === 'category' && sortedOrder == sortOrderEnum.Descending); else sortDesc"></span>
    <span *ngIf="!(sortedBy === 'category' && sortedOrder == sortOrderEnum.Ascending); else sortAsc"></span>
  </div>
  <div
    class="grid-item category font-smaller text-center header-item d-sm-block d-lg-none clickable"
    (click)="sortBy('category', getNextSortOrder('category'))"
  >
    Cat.
    <span *ngIf="!(sortedBy === 'category' && sortedOrder == sortOrderEnum.Descending); else sortDesc"></span>
    <span *ngIf="!(sortedBy === 'category' && sortedOrder == sortOrderEnum.Ascending); else sortAsc"></span>
  </div>
  <div
    class="grid-item desc font-smaller header-item clickable"
    (click)="sortBy('displayDescription', getNextSortOrder('displayDescription'))"
  >
    Description
    <span *ngIf="!(sortedBy === 'displayDescription' && sortedOrder == sortOrderEnum.Descending); else sortDesc"></span>
    <span *ngIf="!(sortedBy === 'displayDescription' && sortedOrder == sortOrderEnum.Ascending); else sortAsc"></span>
  </div>
  <div
    class="grid-item bank font-smaller text-right header-item clickable"
    (click)="sortBy('displayName', getNextSortOrder('displayName'))"
  >
    Account
    <span *ngIf="!(sortedBy === 'displayName' && sortedOrder == sortOrderEnum.Descending); else sortDesc"></span>
    <span *ngIf="!(sortedBy === 'displayName' && sortedOrder == sortOrderEnum.Ascending); else sortAsc"></span>
  </div>
  <div
    class="grid-item amount font-smaller text-right header-item clickable"
    (click)="sortBy('amount', getNextSortOrder('amount'))"
  >
    Amount
    <span *ngIf="!(sortedBy === 'amount' && sortedOrder == sortOrderEnum.Descending); else sortDesc"></span>
    <span *ngIf="!(sortedBy === 'amount' && sortedOrder == sortOrderEnum.Ascending); else sortAsc"></span>
  </div>
  <div class="grid-item menu font-smaller"></div>
</div>
<div class="transaction-panel" [ngClass]="isCollapsed ? 'collapse-transactions' : ''">
  <ng-container *ngFor="let transaction of filteredTransactions">
    <div
      class="grid-container transaction-list-body"
      [ngClass]="expandedDetailTransactions.has(transaction) ? 'selected' : ''"
    >
      <div
        class="grid-item date text-md-center font-smaller"
        (click)="toggleExpandedDetail(transaction)"
        [class.clickable]="canExpandDetails(transaction)"
      >
        {{ transaction.date | date: 'MM/dd/yyyy' }}
      </div>
      <div
        class="grid-item category text-center"
        (click)="toggleExpandedDetail(transaction)"
        [class.clickable]="canExpandDetails(transaction)"
      >
        <app-svg-icon
          *ngIf="transaction.isCategorized && !transaction.isPending"
          [iconName]="categoryIcon(transaction.olbCategoryName)"
          [styles]="{ width: '23px', height: '19px', fill: '#4A5560' }"
        ></app-svg-icon>
        <app-svg-icon
          *ngIf="transaction.isPending"
          [iconName]="icons.Pending"
          [styles]="{ width: '23px', height: '19px', fill: '#4A5560' }"
        ></app-svg-icon>
      </div>
      <div
        class="grid-item desc text-overflow"
        (click)="toggleExpandedDetail(transaction)"
        [class.clickable]="canExpandDetails(transaction)"
      >
        {{ formatText(transaction.displayDescription, 50) }}
        <span class="desc-tooltip">
          {{ transaction.originalDescription ? transaction.originalDescription : transaction.displayDescription }}
        </span>
      </div>
      <div
        class="grid-item bank font-smaller text-md-right text-nowrap"
        (click)="toggleExpandedDetail(transaction)"
        [class.clickable]="canExpandDetails(transaction)"
      >
        {{ transaction.displayName }}
      </div>
      <!--All expense transactions should have negative sign-->
      <div
        class="grid-item amount text-right"
        (click)="toggleExpandedDetail(transaction)"
        [class.clickable]="canExpandDetails(transaction)"
      >
         {{ (transaction.amount * -1) | currency }}
      </div>
      <div class="grid-item text-center menu">
        <app-meatball-menu
          *ngIf="transaction.isCategorized && !transaction.isPending"
          [options]="accountOptions"
          [isRecategorizeExpanded]="expandedRecategorizeTransactions.has(transaction)"
          [isDetailsExpanded]="expandedDetailTransactions.has(transaction)"
          (optionSelected)="selectOption(transaction)"
          (collapse)="collapseRow(transaction)"
          class="options-tooltip"
        ></app-meatball-menu>
      </div>
    </div>
    <app-recategorize
      *ngIf="expandedRecategorizeTransactions.has(transaction)"
      [transaction]="transaction"
      (closeTray)="collapseRow(transaction)"
    ></app-recategorize>
    <div class="transaction-list-body details" *ngIf="expandedDetailTransactions.has(transaction)">
      <app-transaction-details [transaction]="transaction" [isPfmTransaction]="true"></app-transaction-details>
    </div>
  </ng-container>
</div>
