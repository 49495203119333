<button type="button" (click)="toggleExpand()">
  <app-svg-icon
    class="caret-icon"
    *ngIf="subsection || canReturn"
    [iconName]="icon.glyph"
    [width]="icon.size"
    [height]="icon.size"
  ></app-svg-icon>

  <span
    class="mr-1"
    *ngIf="!subsection || !mobile"
    [class.title_ellipsis]="titleEllipsis"
    [class.expanded]="expanded"
    [innerText]="title"
  ></span>

  <span [innerText]="subsection"></span>

  <app-svg-icon
    *ngIf="!subsection && mobile"
    class="section__toggle d-md-none"
    [attr.aria-expanded]="expanded"
    [iconName]="openIcon.icon"
    size="1em"
  ></app-svg-icon>
</button>
