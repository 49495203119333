import { OrganizationSettingsListStateType } from '.';

export type OrganizationStateType = {
  name: string | undefined;
  displayName: string;
  email: string;
  phone: string;
  url: string;
  settings: OrganizationSettingsListStateType[];
};

export const initialOrganizationStateType: OrganizationStateType = {
  name: undefined,
  displayName: '',
  email: '',
  phone: '',
  url: '',
  settings: [],
};
