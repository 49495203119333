import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { subDays } from 'date-fns';

import { AccountTab } from '@app/accounts/enums';
import { getTradingAccount } from '@app/accounts/store/selectors';
import { MenuOverview } from '@app/axos-trading/enums';
import { OrderStatus } from '@app/axos-trading/enums/order-status';
import {
  Filters,
  Order,
  OrderFilter,
  TradingAccount,
  Transaction,
} from '@app/axos-trading/models';
import { ClearingService } from '@app/axos-trading/services';
import * as OrderActions from '@app/axos-trading/store/orders/orders.actions';
import { STATE_PARAMS } from '@core/tokens';
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  @Output() changeTab = new EventEmitter<AccountTab>();
  menuOverview = [
    { id: 0, name: 'All Activity' },
    { id: 1, name: 'Open Orders' },
  ];
  menuOverviewEnum = MenuOverview;
  allActivity: Transaction[];
  openTransactions: Order[];
  tradingAccount: TradingAccount;
  isLoadingActivity = true;
  isLoadingOpenTransactions = true;
  isActive = 0;
  OPEN_ORDER_STATUS = [
    OrderStatus.WAITING_FOR_EXECUTION,
    OrderStatus.WAITING_TO_BE_SENT,
    OrderStatus.PARTIALLY_EXECUTED,
  ];
  accountId: number;
  private subsink = new SubSink();
  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private clearingService: ClearingService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.accountId = this.params['id'];
    const subTab = this.params['subTab'];

    this.displayMenu(subTab);
    this.getTradingAccount();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  displayMenu(id: number) {
    this.isActive = id;
  }

  getData() {
    forkJoin({
      activity: this.getAllActivities(),
      orders: this.getOpenOrders(),
    });
  }

  getAllActivities(filters?: Filters) {
    this.isLoadingActivity = true;
    if (!filters || !filters.datePeriod) {
      filters = filters ? filters : {};
      filters.from = subDays(new Date(), 90);
      filters.to = new Date();
    }

    if (filters.search) {
      this.isLoadingActivity = true;
      this.allActivity = this.allActivity.filter(
        x =>
          x.description.toUpperCase().includes(filters.search.toUpperCase()) ||
          x.date.toString().includes(filters.search) ||
          x.amount.toString().includes(filters.search)
      );
      this.isLoadingActivity = false;

      return;
    }

    return this.clearingService
      .getPortfolioActivity(this.tradingAccount.accountNumber, filters)
      .pipe(
        finalize(() => {
          this.isLoadingActivity = false;
        }),
        catchError(error => of(error.statusCode))
      )
      .subscribe(
        activity =>
          (this.allActivity = activity?.map(x => {
            x.date = x.date.replace(/-/g, '/').replace(/T.+/, '');
            x.description = this.GetPortfolioActivityDescription(x);
            x.amount = this.ValidateAmountByTransactionType(x);
            return x;
          }))
      );
  }

  getOpenOrders(filters?: OrderFilter) {
    this.isLoadingOpenTransactions = true;
    if (filters === undefined) {
      filters = new OrderFilter();
      filters.symbol = '';
    }

    if (filters.search) {
      this.isLoadingOpenTransactions = true;
      this.openTransactions = this.openTransactions.filter(
        x =>
          x.category.description
            .toUpperCase()
            .includes(filters.search.toUpperCase()) ||
          x.executedDate.toString().includes(filters.search) ||
          x.createdDate.toString().includes(filters.search) ||
          x.orderType.toUpperCase().includes(filters.search.toUpperCase()) ||
          x.executedPrice.toString().includes(filters.search) ||
          x.expectedPrice.toString().includes(filters.search) ||
          x.status.description
            .toUpperCase()
            .includes(filters.search.toUpperCase()) ||
          x.symbol.toUpperCase().includes(filters.search.toUpperCase())
      );
      this.isLoadingOpenTransactions = false;

      return;
    }

    return this.clearingService
      .getOrders(this.tradingAccount.accountNumber, filters)
      .pipe(
        finalize(() => {
          this.isLoadingOpenTransactions = false;
        }),
        map(orders =>
          orders?.filter(order =>
            this.OPEN_ORDER_STATUS.includes(order.status.code as OrderStatus)
          )
        ),
        catchError(error => of(error.status))
      )
      .subscribe(orders => {
        this.openTransactions = orders;
        // Store orders in store, to access them in the order details page
        this.store.dispatch(OrderActions.loadOrdersSuccess({ orders }));
      });
  }

  goToTab(accountTab: AccountTab) {
    this.changeTab.emit(accountTab);
  }

  private getTradingAccount() {
    this.subsink.sink = this.store
      .select(getTradingAccount(+this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.tradingAccount = account;
        this.getData();
      });
  }

  private GetPortfolioActivityDescription(activityRecord: any): string {
    switch (activityRecord.transactionCategory) {
      case 1: //Buy
      case 2: //Sell
        const assetName = activityRecord.assetName ?? '';
        const orderType = activityRecord.orderType ?? '';
        return `${
          activityRecord.transactionCategory === 1 ? 'Buy' : 'Sell'
        } - ${
          assetName !== '' ? assetName[0].toUpperCase() : ''
        }${assetName.substr(1).toLowerCase()} ${orderType.toLowerCase()}`;
      default:
        return activityRecord.description;
    }
  }

  private ValidateAmountByTransactionType(activityRecord: any): number {
    switch (activityRecord.transactionCategory) {
      case 1: //Buy
        return activityRecord.amount < 0
          ? activityRecord.amount
          : activityRecord.amount * -1;
      default:
        return activityRecord.amount;
    }
  }
}
