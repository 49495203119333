<div class="hero--wrapper">
  <app-toolbar></app-toolbar>
  <banner-component [placeholder]="'content-header'" class="hero"></banner-component>
</div>

<div class="forgot-username">
  <div class="forgot-username__container">
    <form [formGroup]="forgotUserNameForm" (ngSubmit)="sendUsername()" *ngIf="!submitted">
      <div class="forgot-username__form-group">
        <label for="email" class="uk-label">Email</label>
        <input
          type="text"
          id="email"
          name="email"
          aria-label="email"
          class="uk-input"
          formControlName="email"
          [ngClass]="{ 'invalid-input': isInvalid }"
          required
        />

        <div class="text-error" *ngIf="isInvalid">
          <span>{{ errorMessage }}</span>
        </div>
      </div>

      <div class="forgot-username__legend">
        <h3>Forgot your username?</h3>
        <p>
          Just enter the email associated with your bank account, and we'll send you an email with your username.
        </p>
      </div>

      <div class="forgot-username__button-container" [hidden]="loading">
        <button id="submit-btn" class="btn-ok button--sm" type="submit">
          Continue
        </button>

        <button id="back-btn" class="btn-cancel button--sm" type="button" (click)="backToLogin()">
          Back
        </button>
      </div>
    </form>

    <div *ngIf="submitted" class="text-center">
      <div class="forgot-username__icon">
        <app-svg-icon
          [iconName]="iconProps.icon"
          [iconColor]="iconProps.color"
          [height]="iconProps.iconSize"
          [width]="iconProps.iconSize"
        ></app-svg-icon>
      </div>

      <div class="forgot-username__content">
        <h3>Thank you</h3>
        <p>
          If the information entered matches our records, a message with the username will be sent to the email address
          associated with your account.
        </p>
        <br />
        <br />
        <p>
          Didn’t receive the email?
        </p>
        <a id="mail-link" (click)="backToMail()" class="cursor-pointer" href="#">Re-enter your email address</a>
      </div>

      <div class="form-group text-center">
        <a id="login-link" (click)="backToLogin()" class="cursor-pointer" href="#">Login Now</a>
      </div>
    </div>
  </div>

  <div class="text-center">
    <app-dot-loader [showWhen]="loading"></app-dot-loader>
    <app-chatbot-help-label></app-chatbot-help-label>
  </div>
</div>

<app-footer></app-footer>
