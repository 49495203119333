import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PayBillsHelper } from '@legacy/bill-pay/pay-bills.helper';
import { ROOT_SCOPE, STATE, WINDOW, olbSettings } from '@core/tokens';
import { P2P_MAIN } from '@app/transfers/constants';
import { UserProfileViews } from '@app/user-profile/enums';

@Component({
  selector: 'app-p2p',
  templateUrl: './p2p.component.html',
  styleUrls: ['./p2p.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class P2PComponent implements OnInit, OnDestroy {
  isVerifyingEnrollment = false;
  isEnrolled = false;
  showEmailError = false;
  brand: string;
  step = 1;
  mainSubtitle: string = P2P_MAIN.subtitleStep1;
  listeners: Function[] = [];
  isSendMoney = true;
  isHistory = false;

  constructor(
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    @Inject(olbSettings) public env: OlbSettings,
    @Inject(STATE) public state: ng.ui.IStateService,
    private payBillsHelper: PayBillsHelper,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit() {
    this.brand = String(this.env.brand);
    if (this.window.location.pathname.indexOf('/send-money') > -1) {
      this.isSendMoney = true;
      this.isHistory = false;
    } else if (this.window.location.pathname.indexOf('/history') > -1) {
      this.isHistory = true;
      this.isSendMoney = false;
    }
    if (this.payBillsHelper.needEnrollment()) {
      this.isVerifyingEnrollment = true;
      this.payBillsHelper.enrollInBillPay(this.finishEnrollment.bind(this));
      return;
    }

    this.isEnrolled = this.payBillsHelper.isEnrolledInBillPay();
    this.showEmailError = this.payBillsHelper.isInvalidEmail();

    this.listeners.push(this.root.$on('payNowStepChanged', this.setTitle));
  }

  ngOnDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /**
   * Callback function executed when the user is enrolled un bill pay
   * @param isEnrolled Indicator if the user is enrolled or not
   * @param isInvalidEmail Flag indicatinf the user has an invalid email configured
   */
  private finishEnrollment(isEnrolled: boolean, isInvalidEmail: boolean) {
    this.isEnrolled = isEnrolled;
    this.showEmailError = isInvalidEmail;
    this.isVerifyingEnrollment = false;
  }
  private setTitle = (_event: ng.IAngularEvent, step: number): void => {
    this.step = step;
    this.mainSubtitle =
      this.step === 1 ? P2P_MAIN.subtitleStep1 : P2P_MAIN.subtitleStep2;
  };
  goToSendMoneyTab(tab: string) {
    this.isSendMoney = true;
    this.isHistory = false;
    this.state.go(tab);
  }
  goToHistoryTab(tab: string) {
    this.isSendMoney = false;
    this.isHistory = true;
    this.state.go(tab);
  }
  goToUserProfile() {
    this.state.go('udb.userProfile', { view: UserProfileViews.ContactInfo });
  }
}
