import { CardType, DebitCardStatus } from '../enums';

export class DebitCard {
  get isNotInProcess() {
    return this.status !== DebitCardStatus.InProcess;
  }

  get isAtm() {
    return this.debitCardTypeDescription === 'ATM';
  }

  accountId: number;
  addressChangedRecently: boolean;
  allowChangeLimits: boolean;
  allowManagement: boolean;
  allowReplacementCard: boolean;
  allowReportLostOrStolen: boolean;
  allowTravelNotification: boolean;
  areCardDetailsVisible: boolean;
  cardNumberMask: string;
  cardSufxNumber: number;
  cif: string;
  debitCardId: number;
  debitCardType: CardType;
  debitCardTypeDescription: string;
  disabledToggle: boolean;
  displayPOSLimit: number;
  displayWithdrawalLimit: number;
  embosName: string;
  expirationDate: Date;
  hotCardDate: Date;
  isActive: boolean;
  isBlocked: boolean;
  isCardGray: boolean;
  lastActivityDate: Date;
  lastStatusChangeDate: Date;
  posLimit: number;
  posLimitUsed: number;
  prefix: string;
  prodCode: string;
  prodDesc: string;
  secondEmbosName: string;
  ssn: string;
  status: DebitCardStatus;
  statusDescription: string;
  taxId: string;
  temporaryPOSLimit: number;
  temporaryWithdrawalLimit: number;
  tempPOSLimitEndDate: Date;
  tempPOSLimitStartDate: Date;
  tempWithdrawalLimitEndDate: Date;
  tempWithdrawalLimitStartDate: Date;
  withdrawalLimit: number;
  withdrawalLimitUsed: number;
  originalIssueDate?: Date;
  lastIssueDate?: Date;
  constructor(args: Partial<DebitCard>) {
    Object.assign(this, args, { isActive: !args.isBlocked });

    if (this.isAtm && this.displayPOSLimit === 0) {
      this.displayPOSLimit = undefined;
    }
  }
}
