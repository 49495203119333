import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { catchError, finalize } from 'rxjs/operators';

import { UpdatePhonesRequest } from '@app/user-profile/models';
import { OlbSettings } from '@core/models';
import { UserProfileService } from '@core/services';
import { olbSettings } from '@core/tokens';
import { EMPTY } from 'rxjs';

export interface AccessCodeModalData {
  otp: string | null;
  updateRequest: Partial<UpdatePhonesRequest>;
}

@Component({
  selector: 'app-access-code-modal',
  templateUrl: './access-code-modal.component.html',
  styleUrls: ['./access-code-modal.component.scss'],
})
export class AccessCodeModalComponent implements OnInit {
  backgroundUrl: string;
  svgStyle = {
    width: '5rem',
    height: '5rem',
    fill: 'white',
  };
  maskedPhone: string;
  isBusy: boolean;
  codeForm: UntypedFormGroup;

  errors = [
    {
      name: 'required',
      message: 'Please enter the code you received via SMS.',
    },
    { name: 'minlength', message: 'The code must be 6-digit' },
    { name: 'maxlength', message: 'The code must be 6-digit' },
    {
      name: 'customError',
      message: 'The provided Authentication credentials are not correct.',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<AccessCodeModalComponent>,
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: AccessCodeModalData,
    private userProfileService: UserProfileService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    const { brand } = this.env;
    const { cellPhone1 } = this.data.updateRequest;

    this.data.updateRequest.otp = this.data.otp;
    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
    this.maskedPhone = `(***) ***-${cellPhone1.number.substring(6)}`;

    this.codeForm = this.fb.group({
      code: this.fb.control(this.data.otp, [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
      ]),
    });
  }

  validateCode() {
    if (this.codeForm.invalid) {
      this.codeForm.markAllAsTouched();
    } else {
      this.isBusy = true;
      // Re-assign value for request if the user changes the otp
      this.data.updateRequest.otp = this.codeForm.controls['code'].value;
      this.userProfileService
        .verifyUpdatePhones(this.data.updateRequest)
        .pipe(
          catchError(() => {
            var control: AbstractControl = this.codeForm.get('code');
            control.setErrors({ customError: true });
            return EMPTY;
          }),
          finalize(() => {
            this.isBusy = false;
          })
        )
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    }
  }

  resendCode() {
    this.isBusy = true;
    this.userProfileService
      .requestOtpToUpdatePhones(this.data.updateRequest)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(response => {
        this.codeForm.get('code').patchValue(response.data);
      });
  }

  allowOnlyNumbers($event: any): void {
    if (
      !(
        ($event.charCode >= 48 && $event.charCode <= 57) ||
        $event.keyCode === 8 ||
        $event.keyCode === 13
      )
    ) {
      $event.preventDefault();
    }
  }
}
