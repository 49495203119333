import { NgModule } from '@angular/core';
import { PrimaryBeneficiaryComponent } from './view/primary-beneficiary.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PrimaryBeneficiaryComponent],
  imports: [CommonModule],
  exports: [PrimaryBeneficiaryComponent],
})
export class PrimaryBeneficiaryModule {}
