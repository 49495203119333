<app-funding-shared-header></app-funding-shared-header>

<div class="funding frow center container--content" ui-view="funding-content" autoscroll>
  <div>
    <div class="header-section-title text-center">
      <h1>
        Online Access Terms & Conditions
      </h1>
    </div>

    <app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>

    <app-terms-and-conditions
      [isLoading]="isLoading"
      [buttonText]="'I Agree'"
      (agree)="acceptTerms()"
    ></app-terms-and-conditions>
  </div>
</div>
<app-footer></app-footer>
