import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ROOT_SCOPE, STATE, olbSettings } from '@core/tokens';
import { PrivacyPreferencesService } from '@legacy/services/privacy-preferences.service';

@Component({
  selector: 'app-privacy-preferences',
  templateUrl: './privacy-preferences.component.html',
  styleUrls: ['./privacy-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PrivacyPreferencesComponent implements OnInit {
  public isSuccess: boolean = false;
  public submitted: boolean= false;
  public isError: boolean;
  public share: boolean;
  public allow: boolean;
  public personal: boolean;
  public isLoading: boolean = false;
  public loadingText: string = 'Loading Preferences';
  public preferences: PrivacyPreference = {};
  public privacyUrl: string = '';

  constructor(
    @Inject(STATE) private stateService: ng.ui.IStateService,
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    private privacyService: PrivacyPreferencesService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getPreferences();
  }

  public showSuccess(): void {
    this.isSuccess = true;
    let element = document.getElementsByClassName('privacy-preferences-text')[0] as HTMLElement;
    element.scrollIntoView();
  }

  public showError(): void {
    this.isError = true;
    this.isSuccess = false;
    let element = document.getElementsByClassName('privacy-preferences-text')[0] as HTMLElement;
    element.scrollIntoView();
  }

  public goToMyAlerts(isPristine: boolean): void {
    if (!isPristine) {
      this.showError();
    } else {
      this.stateService.go('udb.alertsNotifications.myAlerts');
    }
  }

  public getPrivacyUrl(): void {
    this.privacyService
      .getPrivacyUrl(this.env.facingBrandId)
      .then(res => {
        this.privacyUrl = res.data.value;
        if (this.privacyUrl) {
        }
      })
      .catch(_ => {
        console.error(_);
      })
      .finally(() => {
        this.isLoading = false;
      });

    if (this.rootScope['brandProperties']) {
      this.privacyUrl = this.rootScope['brandProperties']['PrivacyURL'];
    }
  }

  public SavePreferences(formControl: NgForm): void {
    this.loadingText = 'Saving Preferences';
    this.isSuccess = false;
    this.isError = false;
    this.isLoading = true;
    this.privacyService
      .savePrivacyPreferences(
        this.preferences.privacyDoNotShareCreditPersonalInfo,
        this.preferences.privacyDoNotMarketPersonalInfo,
        this.preferences.privacyDoNotSharePersonalInfoNonAffiliates
      )
      .then((_response: OlbResponse<any>) => {
        this.showSuccess();
        formControl.form.markAsPristine();
      })
      .catch(() => {
        this.showError();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public getPreferences(): void {
    this.privacyService
      .getPrivacyPreferences()
      .then((response: OlbResponse<any>) => {
        this.preferences.privacyDoNotShareCreditPersonalInfo = response.data.privacyDoNotShareCreditPersonalInfo;
        this.preferences.privacyDoNotMarketPersonalInfo = response.data.privacyDoNotMarketPersonalInfo;
        this.preferences.privacyDoNotSharePersonalInfoNonAffiliates =
          response.data.privacyDoNotSharePersonalInfoNonAffiliates;

        this.getPrivacyUrl();
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
