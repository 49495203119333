import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { NavigationIcons } from '@shared/enums';
import { SupportFile } from '@shared/models';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('inputFileUpload') inputFileUpload: ElementRef;
  @Input() files: SupportFile[];
  @Input() totalMaxSize: number;
  @Input() hasCustomDropZone = false;
  @Output() filesChange = new EventEmitter<SupportFile[]>();

  allFiles: File[] = [];
  closeIcon = NavigationIcons.Ex;
  dragOver = false;
  selectedFiles: File[] = [];
  totalSize = 0;
  validationError: string;

  ngOnInit(): void {
    this.setInitialFiles();
  }

  onDragFilesOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
  }

  onDragFilesLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
  }

  ondropFiles(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
    this.processFiles(event.dataTransfer.files);
  }

  removeItem(file: File) {
    this.allFiles = this.allFiles.filter(item => item !== file);
    this.bindFiles();
  }

  processFiles(fileList: FileList) {
    this.selectedFiles = [];

    Array.from(fileList).forEach(file => {
      this.selectedFiles.push(file);
    });

    this.inputFileUpload.nativeElement.value = null;

    if (!this.validateFilesType(this.selectedFiles)) {
      this.validationError = 'Acceptable file types are: PDF, Word, JPG, PNG, XLS';

      return;
    }

    if (this.totalMaxSize) {
      if (!this.validateTotalSize(this.selectedFiles, this.totalMaxSize)) {
        this.validationError = 'The maximum file size is 10MB.';

        return;
      }
    }

    this.validationError = '';
    this.allFiles = [...this.allFiles, ...this.selectedFiles];
    this.bindFiles();
  }

  cleanValidationError() {
    this.validationError = '';
  }

  cleanFiles() {
    this.allFiles = [];
    this.bindFiles();
  }

  private validateFilesType(files: File[]) {
    const allowedFiles = [
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];

    try {
      files.forEach(file => {
        const isJpg = /\.(jpg|jpeg)$/i.test(file.name);
        if (!allowedFiles.includes(file.type) && !isJpg) {
          throw Error('type not allowed');
        }
      });
    } catch {
      return false;
    }

    return true;
  }

  private validateTotalSize(files: File[], maxSize: number): boolean {
    let currentSize = this.totalSize;
    files.forEach(file => {
      currentSize += file.size;
    });

    return !(currentSize > maxSize);
  }

  private bindFiles() {
    this.files = [];
    this.totalSize = 0;
    this.allFiles.forEach(file => {
      this.totalSize += file.size;
      this.files.push({ document: file, name: file.name });
    });
    this.filesChange.emit(this.files);
  }

  private setInitialFiles() {
    this.files.forEach(file => {
      this.allFiles.push(file.document);
    });
    this.bindFiles();
  }
}
