<div class="electronic-confirmation">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <h1>
        <img [src]="'/img/icons/billPay/checkmark.svg'" width="100" alt="Favorite Recipient" aria-hidden="true" />
      </h1>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-md-push-2 text-center">
        <h4>
          <span [textContent]="biller.name"></span>
          Added.
        </h4>
        <p>We've sent an email with this information to {{ email }}</p>

        <table class="table table-striped confirmation__table">
          <tbody class="text-bold">
            <tr>
              <td class="text-left">
                Recipient
                <br class="show-responsive" />
                <span class="show-responsive text-right" [textContent]="biller.name"></span>
              </td>
              <td class="text-right hide-responsive">
                <span [textContent]="biller.name"></span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Account Number
                <br class="show-responsive" />
                <span class="show-responsive text-right" [textContent]="recipient.paymentAccount"></span>
              </td>
              <td class="text-right hide-responsive">
                <span [textContent]="recipient.paymentAccount"></span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Recipient Type
                <br class="show-responsive" />
                <span
                  class="text-right show-responsive"
                  [textContent]="recipient.isElectronic ? 'Electronic' : 'Manual'"
                ></span>
                <app-tooltip-shadowdom
                  class="details-help show-responsive"
                  [tooltip]="detailsTooltip"
                ></app-tooltip-shadowdom>
              </td>
              <td class="text-right hide-responsive">
                <span [textContent]="recipient.isElectronic ? 'Electronic' : 'Manual'"></span>
                <app-tooltip-shadowdom class="details-help" [tooltip]="detailsTooltip"></app-tooltip-shadowdom>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Recipient’s Address
                <span class="text-right show-responsive">
                  <br />
                  {{ recipient.addressLine }}
                  <br />
                  {{ recipient.city }}, {{ recipient.stateCode }},
                  {{ recipient.zipCode }}
                </span>
              </td>
              <td class="text-right hide-responsive">
                <span>
                  {{ recipient.addressLine }}
                  <br />
                  {{ recipient.city }}, {{ recipient.stateCode }},
                  {{ recipient.zipCode }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Nickname
                <br class="show-responsive" />
                <span class="show-responsive text-right" [textContent]="recipient.nickName"></span>
              </td>
              <td class="text-right hide-responsive">
                <span [textContent]="recipient.nickName"></span>
              </td>
            </tr>
            <tr *ngIf="recipient.ebillStatus === 1">
              <td class="text-left">
                eBills
                <br class="show-responsive" />
                <span
                  class="circle show-responsive text-right"
                  [ngClass]="recipient.eStatementsStatus ? 'green' : 'red'"
                ></span>
                <span
                  class="show-responsive text-right"
                  [textContent]="recipient.eStatementsStatus ? 'On' : 'Off'"
                ></span>
              </td>
              <td class="text-right hide-responsive">
                <span class="circle" [ngClass]="recipient.eStatementsStatus ? 'green' : 'red'"></span>
                <span [textContent]="recipient.eStatementsStatus ? 'On' : 'Off'"></span>
              </td>
            </tr>
          </tbody>
        </table>
        <p>To modify or delete this recipient, go to {{ biller.name }} Details tab in the Bill Pay Dashboard.</p>
        <!--| possessive  -->
        <div class="text-center">
          <button class="uk-btn solid secondary lg button-mobile" type="button" (click)="goSchedulePayment()">
            <span>
              Schedule A Payment
            </span>
          </button>
        </div>
        <div class="text-center">
          <a [href]="'billpay/pay'">Go to Bill Pay Dashboard</a>
        </div>
      </div>
    </div>
  </div>
</div>
