import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { CategoryEventData } from '@app/messages/models';
import { STATE_PARAMS } from '@core/tokens';
import { IStateParamsService } from 'angular-ui-router';

@Component({
  selector: 'app-cm-business-selection',
  templateUrl: './cm-business-selection.component.html',
  styleUrls: ['./cm-business-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CmBusinessSelectionComponent {
  public readonly STATE_PARAMS = {
    categoryId: 'categoryId',
    categoryName: 'categoryName',
  };
  public categoryId: number;
  public categoryName: string;
  @Output() public businessSelectionHandler = new EventEmitter<CategoryEventData>();

  constructor(@Inject(STATE_PARAMS) private stateParams: IStateParamsService) {}
  ngOnInit(): void {
    this.categoryId = this.stateParams[this.STATE_PARAMS.categoryId];
    this.categoryName = this.stateParams[this.STATE_PARAMS.categoryName];
  }

  /**
   * Goes to the compose message section
   * @param bId Business Id
   */
  public navigateTo(businessId: string, isSingleAccount: boolean): void {
    let business: CategoryEventData = {
      categoryId: this.categoryId,
      categoryName: this.categoryName,
      businessId: businessId,
      isSingleAccount: isSingleAccount,
    };
    this.businessSelectionHandler.emit(business);
  }
}
