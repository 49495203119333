import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LEGACY_UIB_MODAL } from '@app/transfers/ajs-upgraded-providers';
import { olbSettings } from '@core/tokens';

@Component({
  selector: 'app-p2p-info-modal',
  templateUrl: './p2p-info-modal.component.html',
  styleUrls: ['./p2p-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class P2PInfoModalComponent implements OnInit {
  brandName: string;
  sendMoneyImg: string;
  messageReceivedImg: string;
  transferMoneyImg: string;
  @Output() closeModalEvent = new EventEmitter<void>();

  modalInstance: angular.ui.bootstrap.IModalServiceInstance;

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<P2PInfoModalComponent>,
    @Inject(LEGACY_UIB_MODAL) public uibModalService: any
  ) {}

  ngOnInit() {
    this.brandName = String(this.env.brand);
    this.sendMoneyImg = '/img/p2p/' + this.brandName + '/send_money.svg';
    this.messageReceivedImg = '/img/p2p/' + this.brandName + '/message_recd.svg';
    this.transferMoneyImg = '/img/p2p/' + this.brandName + '/transfer_money.svg';
    this.changeDetectorRef.detectChanges();
  }

  dismissModal() {
    this.dialogRef.close();
  }
}
