import { MergedSegment, ProcessedSegment } from '../types';
export function createChartSegments(mergedSegments: ReadonlyArray<MergedSegment>): ReadonlyArray<ProcessedSegment> {
  const TOTAL_RADIANS_IN_CIRCUMFERENCE = 2 * Math.PI;
  if (mergedSegments.length <= 1) {
    return [
      {
        startAngle: 0, //  this will set the first part of the segment at 90 degrees from the to the right but it do not matter because the ring closes on it self
        endAngle: Math.PI * 2, // this is zero but with math notation to let javascript figure out that we mean 360 degrees and not 0 degrees
        backgroundColor: mergedSegments[0]?.backgroundColor ?? 'rgba(189, 189, 189, 1)', // color for single segment or fallback to figma reference color for no data
        amount: mergedSegments[0]?.amount ?? 1, // give amount for single segment or `1` in both cases it do not matter 1 or the given amount always will be 100% of the chart if it is the single segment
        percentage: 100, // single segment always must be 100% hardcoded btw
      },
    ];
  }
  let availableRadians = TOTAL_RADIANS_IN_CIRCUMFERENCE;
  // sets initial point to to to be 90 degrees notated this way to since I like the notation that goes counter clock wise but it's the same as using Math.PI * 1.5;
  // the magic number 0.075 it's just half of the spacing that we always give in the + .15, just to make it pixel perfect to the -90 degrees or 90 degrees counter click wise

  const spacingCoefficient = ((Math.PI * 2) / 100) * 2;
  let currentAngleEnd = -(Math.PI / 2) - spacingCoefficient / 2;
  const segments = mergedSegments.map(segment => {
    const segmentLength = TOTAL_RADIANS_IN_CIRCUMFERENCE * (segment.percentage / 100);
    const startAngle = currentAngleEnd + spacingCoefficient;
    const preCalculatedEndAngle = currentAngleEnd + segmentLength;
    currentAngleEnd = preCalculatedEndAngle;
    availableRadians = availableRadians - segmentLength;
    const endAngle = startAngle >= preCalculatedEndAngle ? startAngle : preCalculatedEndAngle;
    currentAngleEnd = endAngle;
    return {
      ...segment,
      startAngle,
      endAngle,
    };
  });
  
  return segments;
}
