export enum Frequency {
  OneTime = 0,
  Weekly = 1,
  EveryTwoWeeks = 2,
  EveryFourWeeks = 4,
  Monthly = 5,
  SemiMonthly = 3,
  EveryTwoMonths = 6,
  Quarterly = 7,
  EveryFourMonths = 8,
  BiAnnual = 9,
  Annually = 10,
}
