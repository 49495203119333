<ng-template #uploadPhoto>
  <button
    type="button"
    class="upload"
    [class.upload--dragging]="dragOver"
    [class.invalid-file]="errors.invalidFile || errors.invalidSize"
    (dragover)="onDragFilesOver($event)"
    (dragleave)="onDragFilesLeave($event)"
    (drop)="onDropFiles($event)"
    (click)="pictureField.click()"
  >
    <div class="upload__container">
      <app-svg-icon class="mb-2" [iconName]="icon" size="3rem"></app-svg-icon>
      <p class="upload__text">Upload Photo</p>
    </div>

    <input
      type="file"
      name="picture"
      [accept]="validFileTypes"
      hidden
      #pictureField
      (change)="validateFile($event.target.files)"
    />
  </button>

  <div class="mt-1 text-error" *ngIf="errors.invalidFile">Acceptable file types are: JPG, PNG.</div>
  <div class="mt-1 text-error" *ngIf="errors.invalidSize && !errors.invalidFile">The maximum file size is 2MB.</div>
</ng-template>

<div class="upload upload--with-file" *ngIf="imageDataUrl; else uploadPhoto">
  <div class="preview">
    <img class="preview__img" [src]="imageDataUrl" alt="Check image" />

    <button type="button" class="link preview-btn" (click)="showImageModal()">View Image</button>
  </div>
</div>
