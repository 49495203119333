import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { BusinessSelection } from '@app/accounts/models/business-selection.model';

@Component({
  selector: 'app-sm-business-account',
  templateUrl: './sm-business-account.component.html',
})
export class SmBusinessAccountComponent implements OnInit {
  @Output()
  public selectItem = new EventEmitter<BusinessSelection>();

  public isLoading: boolean = false;
  public userBusinessProfiles: AccountProfile[] = [];

  constructor(private serviceHelper: ServiceHelper) {}

  public ngOnInit(): void {
    this.fillAccounts();
  }

  public handleItemClick(profileId: string, isSingleAccount: boolean): void {
    const businessSelection: BusinessSelection = {
      businessProfileId: profileId,
      isSingleAccount,
    };

    this.selectItem.emit(businessSelection);
  }

  private async fillAccounts() {
    this.isLoading = true;

    try {
      // Filter by business profile (1)
      const ss_businessProfiles = sessionStorage.getItem('userBusinesses');

      if (ss_businessProfiles !== undefined && ss_businessProfiles !== null) {
        this.userBusinessProfiles = (JSON.parse(ss_businessProfiles) as AccountProfile[]).filter(
          up => up.profileType === 1
        );

        if (this.userBusinessProfiles?.length == 0) {
          this.handleItemClick('', true);
        }

        if (this.userBusinessProfiles.length == 1) {
          this.handleItemClick(this.userBusinessProfiles[0].id.toString(), true);
        }
      }
    } catch (exception) {
      this.serviceHelper.errorHandler(exception);
    } finally {
      this.isLoading = false;
    }
  }
}
