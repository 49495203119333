import { Inject } from '../../../decorators/decorators';

@Inject('$state', 'env')
export class UploadCardCompleteController {
  env: any;

  constructor(private state: ng.ui.IStateService, private _env: any) {
    this.env = this._env;
  }

  goToDashboard(): void {
    this.state.go('udb.dashboard');
  }
}
