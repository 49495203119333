import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { alertsNotificationsComponents } from './components';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { privacyPreferencesServiceProvider } from './providers/Index';

@NgModule({
  declarations: [...alertsNotificationsComponents],
  exports: [...alertsNotificationsComponents],

  entryComponents: [...alertsNotificationsComponents],
  imports: [CommonModule, FormsModule, SharedModule],
  providers: [...privacyPreferencesServiceProvider],
})
export class AlertsNotificationsModule {}
