import { Inject } from '../../../decorators/decorators';

@Inject('$stateProvider')
export class BankDetailsConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('udb.transfers.wireTransfers.bankDetails', {
      url: '/bank-details',
      params: { wiretransfer: null },
      views: {
        'wire-content': {
          templateUrl: 'transfers/wire-transfers/bank-details/bank-details.tpl.html',
          controller: 'BankDetailsController',
          controllerAs: '$bdc',
        },
      },
    });
  }
}
