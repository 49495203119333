<div class="row">
  <div class="col-12">
    <div
      class="insights-selectors insights-content d-flex align-items-center justify-content-center"
    >
      <div class="d-flex justify-content-center">
        <div
          *ngFor="let chart of chartTypes"
          class="chart-selection__dot"
          (click)="switchToSelected(chart)"
          [class.chart-selection__dot--active]="chart.isActive"
        ></div>
      </div>
    </div>
    <div id="chart-selection-container" class="d-flex align-items-center">
      <i
        class="fa fa-chevron-left chart-selection__arrow chart-selection__arrow--left"
        [class.chart-selection__arrow--enabled]="isLeftArrowActive"
        (click)="switchToLeft()"
      ></i>
      <div class="chart-selection__transclude-cont" [class.d-block]="selectedChart.id === 0">
        <ng-content select="[first]"></ng-content>
      </div>
      <div class="chart-selection__transclude-cont" [class.d-block]="selectedChart.id === 1">
        <ng-content select="[second]"></ng-content>
      </div>
      <i
        class="fa fa-chevron-right chart-selection__arrow chart-selection__arrow--right"
        [class.chart-selection__arrow--enabled]="isRightArrowActive"
        (click)="switchToRight()"
      ></i>
    </div>
  </div>
</div>
