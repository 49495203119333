/**
 * Masks phone number characters and let visible the last 4 ones if have the correct format.
 *
 * @param phone Phone number to format.
 * @param mask Character to replace the string.
 * @returns A masked phone string.
 *
 * @example
 * (123) 456-7890 -> C (***) ***-7890
 * 1 (234) 567-8909 -> C * (***) ***-8909
 * 123 (45) 678-9098 -> C *** (**) ***-9098
 */

export function maskPhone(tel: string) {
  if (!tel) {
    return '';
  }

  const len = tel.length;
  const visible = tel.substring(len - 4, len);

  return `(***) ***-${visible}`;
}
