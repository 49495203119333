import { NgModule } from '@angular/core';
import { InboxComponent, InboxPaginatorComponent } from './components';
import { CommonModule } from '@angular/common';
import { LEGACY_MESSAGES_PROVIDERS } from './injection-tokens';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [InboxPaginatorComponent, InboxComponent],
  imports: [CommonModule, FormsModule, SharedModule],
  providers: [...LEGACY_MESSAGES_PROVIDERS],
})
export class InboxModule {}
