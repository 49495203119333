<ng-container
  *ngIf="{
    riaUserFlag: riaUserFlag$ | async,
    riaUserIsLoading: riaUserIsLoading$ | async,
    riaPilotActive: riaPilotFlag$ | async,
    noAxosAccountsIsLoading: noAxosAccountsIsLoading$ | async,
    noAxosAccounts: noAxosAccounts$ | async
  } as data"
>
  <app-view-header titleText="" *ngIf="data.riaUserIsLoading; else noRIAUserTemplate"></app-view-header>

  <ng-template #noRIAUserTemplate>
    <app-view-header *ngIf="!data.riaUserFlag && !data.riaUserIsLoading; else riaUserTemplate" titleText="How To's">
      <p>Choose the section you want to learn more about.</p>
    </app-view-header>
  </ng-template>

  <ng-template #riaUserTemplate>
    <app-view-header *ngIf="!data.riaUserIsLoading" titleText="Support"></app-view-header>
  </ng-template>
  <div class="container col-12 col-md-10 col-lg-8" *ngIf="!data.noAxosAccountsIsLoading">
    <div class="d-flex flex-wrap" *ngIf="!activeHowTo; else howToNav">
      <div class="col-6 col-md-4 col-xl-3" *ngFor="let section of howTos">
        <button class="how-to__item" (click)="selectHowToCategory(section.category)">
          <p [innerText]="section.label" class="how-to__item-label"></p>
          <app-svg-icon height="4.125rem" width="auto" [iconName]="section.icon"></app-svg-icon>
        </button>
      </div>
    </div>

    <ng-template #howToNav>
      <div class="how-to__nav">
        <div class="col-md-3 d-none d-md-block">
          <ul class="how-to-menu">
            <li *ngFor="let howTo of howTos" (click)="selectHowToCategory(howTo.category)">
              <a
                href="#"
                class="how-to__link"
                [class.active]="activeHowTo?.category === howTo.category"
                [innerText]="howTo.label"
              ></a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-9">
          <ng-container [ngSwitch]="activeHowTo?.category">
            <how-to-dashboard-topics
              class="support-dashboard"
              *ngSwitchCase="categories.Dashboard"
            ></how-to-dashboard-topics>
            <how-to-deposit-loans-topics
              class="support-accounts"
              *ngSwitchCase="categories.Accounts"
            ></how-to-deposit-loans-topics>
            <how-to-alerts-topics class="support-alerts" *ngSwitchCase="categories.Alerts"></how-to-alerts-topics>
            <how-to-bill-pay-topics
              class="support-bill-pay"
              *ngSwitchCase="categories.BillPay"
            ></how-to-bill-pay-topics>
            <how-to-deposits-topics
              class="support-deposits"
              *ngSwitchCase="categories.Deposits"
            ></how-to-deposits-topics>
            <how-to-transfers-topics
              class="support-transfers"
              *ngSwitchCase="categories.Transfers"
            ></how-to-transfers-topics>
            <how-to-pay-it-now-topics
              class="support-pay-it-now"
              *ngSwitchCase="categories.PayItNow"
            ></how-to-pay-it-now-topics>
            <how-to-profile-settings-topics
              class="support-profile-settings"
              *ngSwitchCase="categories.Other"
            ></how-to-profile-settings-topics>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <support-contact-us
      class="d-block mt-5"
      (navigate)="goTo($event)"
      [riaPilotActive]="data.riaPilotActive"
      [riaUserFlag]="data.riaUserFlag"
      [riaUserIsLoading]="data.riaUserIsLoading"
    ></support-contact-us>
  </div>
</ng-container>
