import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';
import NgRedux from 'ng-redux';

import { TransferType } from '@app/axos-invest/enums';
import { Goal, ManagedPortfolioAccount, MilestoneSummary, TransferForm } from '@app/axos-invest/models';
import { getGoalSummary } from '@app/axos-invest/store/selectors';
import { turnOffFunding } from '@app/store/funding/funding.actions';
import { mapDisplayNameOfExternalAccounts } from '@app/utils';
import { OlbEvents } from '@core/enums';
import { FundingAccount } from '@core/models/funding-account.model';
import { OlbEventService } from '@core/services';
import { ngRedux, ROOT_SCOPE, STATE } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { AggregatedAccount } from '@legacy/typings/app/account-aggregation';
import { FlowType } from '@legacy/typings/app/flow-type.enum';

@Component({
  selector: 'app-funding',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.scss'],
})
export class FundingComponent implements OnInit, OnDestroy {
  axosAccounts: ManagedPortfolioAccount[] = [];
  nonAxosAccounts: ManagedPortfolioAccount[] = [];
  aggregatedAccounts: AggregatedAccount[];
  typeAccounts: TypesAccounts;
  milestoneDetail: MilestoneSummary;
  milestoneAccount: Goal;
  showFundingForm: boolean;
  fundingCompleted: boolean;
  transferType = TransferType.FundingFlow;
  transferFormData: TransferForm;
  subSink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    @Inject(ngRedux) private ngredux: NgRedux.INgRedux,
    private cachedAccountsService: CachedAccountsService,
    private olbEventService: OlbEventService,
    private store: Store
  ) {}

  ngOnInit() {
    if (this.root['balancesAvailable']) {
      this.getFromAccounts();
    } else {
      this.olbEventService.on(OlbEvents.BalancesAvailable, () => this.getFromAccounts());
    }
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    if (this.fundingCompleted) this.turnOffFunding();
  }

  getFromAccounts() {
    this.typeAccounts = this.cachedAccountsService.getFromAccounts(true);
    if (this.typeAccounts.internalAccounts.length === 0 && this.typeAccounts.externalAccounts.length === 0) {
      if (this.state.params.source && this.state.params.source === 'account-aggregation') {
        this.skipFund();
      } else {
        this.state.go('udb.dashboard.account-aggregation', {
          flow: FlowType.AxosInvest,
        });
      }

      return;
    }
    this.aggregatedAccounts = this.cachedAccountsService.getAggregatedAccounts();
    this.fillInternalAccounts();
    this.fillExternalAccounts();
    this.getMilestoneDetail();
  }

  fillInternalAccounts() {
    this.typeAccounts.internalAccounts
      .filter(x => x.canBeFromAccount)
      .forEach((m: any) => {
        if (m.availableBalance > 0) {
          const axosAccount: ManagedPortfolioAccount = {
            id: m.id,
            accountNumber: m.accountNumber,
            bankName: m.bankName,
            nickname: `${m.name} - ${m.accountMask}`,
            isExternal: m.isExternal,
            availableBalance: m.availableBalance,
          };
          this.axosAccounts.push(axosAccount);
        }
      });
  }

  fillExternalAccounts() {
    this.typeAccounts.externalAccounts.forEach(externalAccount => {
      const aggregatedAccount = this.aggregatedAccounts.find(
        (x: AggregatedAccount) => x.accountNumber === externalAccount.accountNumber
      );

      const displayName = mapDisplayNameOfExternalAccounts(externalAccount, aggregatedAccount);

      const axosAccount: ManagedPortfolioAccount = {
        id: externalAccount['externalAccountId'],
        accountNumber: externalAccount['accountMask'],
        bankName: aggregatedAccount?.bankName ?? externalAccount.bankName,
        nickname: displayName,
        isExternal: true,
        availableBalance: aggregatedAccount?.availableBalance,
      };
      this.nonAxosAccounts.push(axosAccount);
    });
  }

  getMilestoneAccount() {
    const fundingAccounts = JSON.parse(sessionStorage.getItem('fundingAccounts')) as FundingAccount[];
    const milestoneAccount = this.milestoneDetail.milestones.find(x => x.id === fundingAccounts[0].id);
    this.milestoneAccount = milestoneAccount;
  }

  getMilestoneDetail() {
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(response => {
      this.milestoneDetail = response;
      this.getMilestoneAccount();
    });
  }

  showFunding(accountId: number) {
    this.transferFormData = new TransferForm({ accountId });
    this.showFundingForm = true;
  }

  skipFund() {
    this.state.go('udb.dashboard');
  }

  turnOffFunding() {
    this.ngredux.dispatch(FundingActions.turnOffFunding());
    this.store.dispatch(turnOffFunding());
    sessionStorage.removeItem('fundingAccounts');
  }
}
