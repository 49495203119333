export const TOAST_BODY_TEXT = {
  trustedContactAddSuccess: 'Trusted Contact added',
  trustedContactAddFailure: 'Trusted Contact failed to be added. Please try again.',
  trustedContactUpdateSuccess: 'Trusted Contact updated',
  trustedContactUpdateFailure: 'Trusted Contact failed to be updated. Please try again.',
  trustedContactDeleteSuccess: 'Trusted Contact removed',
  trustedContactDeleteFailure: 'Trusted Contact failed to be removed. Please try again.',
  interestedPartyAddSuccess: 'Interested Party added',
  interestedPartyAddFailure: 'Interested Party failed to be added. Please try again.',
  interestedPartyUpdateSuccess: 'Interested Party updated',
  interestedPartyUpdateFailure: 'Interested Party failed to be updated. Please try again.',
  interestedPartyDeleteSuccess: 'Interested Party removed',
  interestedPartyDeleteFailure: 'Interested Party failed to be removed. Please try again.',
};
