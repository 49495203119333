import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

import { SubSink } from '@axos/subsink';

import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';
import { olbSettings, STATE, STATE_PARAMS } from '@core/tokens';
import { FeatureFlagType } from '@legacy/common/enums/featureFlagsType.enum';
import { GlanceCobrowseService } from '@legacy/services/cobrowse.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ModalService } from '@legacy/services/modal.service';

@Component({
  selector: 'app-legacy-footer',
  templateUrl: './legacy-footer.component.html',
  styleUrls: ['./legacy-footer.component.scss'],
})
export class LegacyFooterComponent implements OnInit, OnDestroy {
  @Input() isLoggedUser: boolean;
  showButton: boolean;
  loggedUser: boolean;
  appVersion: string;
  brand: string;
  brandName: string;
  privacyUrl: string;
  disclosuresUrl: string;
  ehl: string;
  fdic: string;
  date: string | Date;
  umbrellaBrand: string;
  nmls: string;
  nmlsDisplayUrl: string;
  poweredByBrandName: string;
  isAxosInvestActive: boolean;
  isAxosTradingActive: boolean;
  isProxyRegistration: boolean;
  displaySpeedBump = false;
  otherUrl: string;
  clickHereOrReadOurFDICNotice = '';

  private subsink = new SubSink();

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    @Inject(STATE) private $state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) public routeParams: ng.ui.IStateParamsService,
    private olbEventService: OlbEventService,
    private modalService: ModalService,
    private glanceCobrowseService: GlanceCobrowseService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.addListeners();
    this.isAxosInvestActive = this.getAxosInvestStatus();
    this.isAxosTradingActive = this.getAxosTradingStatus();
    this.isProxyRegistration = this.routeParams.version === 'proxy';
    this.showButton = this.isLoggedUser;

    this.loggedUser = this.showButton;
    this.appVersion = this.settings.version;
    this.brand = this.settings.brand.replace(' ', '');
    this.brandName = this.settings.brandName || this.settings.brand;
    this.date = new Date();
    if (this.brand !== 'nationwide') {
      this.clickHereOrReadOurFDICNotice = this.brand !== 'ufb' ? 'read our FDIC Notice.' : 'click here.';
    }
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  changeState(state: string) {
    this.$state.go(state);
  }

  openContinueToPageModal(url?: string) {
    this.displaySpeedBump = !this.displaySpeedBump;
    this.otherUrl = url;
  }

  openCobrowseSession() {
    if (localStorage.getItem('glance_cobrowse_session')) return;
    this.modalService
      .show(
        {},
        {
          bodyText: `<h3>Show your Browser?</h3>
                      <p class='hidden-sm ms-secondary-text'>Your privacy is our priority. The agent will only be able to see this specific web page. They can't see any other open websites or anything on your computer.</p>`,
          okText: 'Accept',
          cancelText: 'Decline',
        }
      )
      .then(() => {
        this.glanceCobrowseService.startSessionWithRandomKey();
      })
      .catch(() => {});
  }

  private addListeners() {
    this.subsink.sink = this.olbEventService.on(OlbEvents.BrandPropertiesLoaded, this.brandPropertiesLoaded.bind(this));
  }

  private brandPropertiesLoaded(brandProperties: BrandProperty[]) {
    this.privacyUrl = brandProperties['PrivacyURL'];
    this.disclosuresUrl = brandProperties['DisclosuresURL'];
    this.umbrellaBrand = brandProperties['UmbrellaBrandDisplayName'];
    this.ehl = brandProperties['EhlUrl'];
    this.fdic = brandProperties['FdicUrl'];
    this.nmls = brandProperties['NmlsUrl'];
    this.nmlsDisplayUrl = brandProperties['NmlsDisplayUrl'];
    this.poweredByBrandName = brandProperties['PoweredByBrandName'];
  }

  private getAxosInvestStatus(): boolean {
    return (
      this.featureFlagService.isPublicFeatureFlagActive(FeatureFlagType.AxosInvest) ||
      this.featureFlagService.isAxosInvestActive()
    );
  }

  private getAxosTradingStatus(): boolean {
    return (
      this.featureFlagService.isPublicFeatureFlagActive(FeatureFlagType.AxosTrading) ||
      this.featureFlagService.isAxosTradingActive()
    );
  }
}
