<app-contact-firm></app-contact-firm>
<br />
<br />
<app-customer-support *ngIf="riaPilotActive && riaUserFlag"></app-customer-support>
<br />
<br />
<h2 class="text-center">{{ 'Message Us' }}</h2>
<br />
<p class="text-center" [innerText]="subtitle"></p>
<br />
<app-support-menu
  [categories]="items$ | async"
  (categorySelect)="selectCategory($event)"
  (itemSelect)="goToMessageCenter($event)"
></app-support-menu>

<app-dot-loader *ngIf="isLoading"></app-dot-loader>
