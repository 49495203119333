<header class="funding-header">
  <div class="frow middle toolbar">
    <div class="fcol-cs-2 finline middle logo-container">
      <img [src]="imgBrand" class="logo" [alt]="brand" />
    </div>
    <div class="fcol-cs-9 middle contact-box">
      <p class="hidden-xs hidden-sm">
        <span>Need Assistance?</span>
      </p>
      <ul class="contact-box-options">
        <li
          [ngClass]="showPhone ? 'contact-box-options__item--show' : 'contact-box-options__item--hide'"
          class="contact-box-options__item"
        >
          <img
            (click)="showPhone = !showPhone"
            src="../../../../assets/axos/img/icons/contact_phone.svg"
            class="contact-box-options__item-img hidden-xs hidden-sm"
            alt="Contact by phone"
          />

          <a href="tel:{{ phoneNumber }}" class="contact-box-options__item-link">
            <span class="hidden-xs hidden-sm">{{ phoneNumber }}</span>

            <img
              src="../../../../assets/axos/img/icons/contact_phone.svg"
              class="contact-box-options__item-img visible-xs visible-sm"
              alt="Contact by phone"
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>
