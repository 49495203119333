<div class="billing-details">
  <div class="header-section-title text-center hide-responsive">
    <h1>Add A Recipient</h1>
  </div>
  <div class="container container-responsive">
    <div class="row">
      <div class="col-md-12 cancel-setup-responsive">
        <span (click)="cancelSetup()" role="button">&#10005;</span>
      </div>
      <div class="col-md-2 hide-responsive">
        <a class="back" (click)="goToSearchRecipient()">
          <img
            [src]="'img/icons/billPay/back_icon.svg'"
            width="15"
            alt="Favorite Recipient"
            aria-hidden="true"
            class="back-arrow"
          />
          <span>Back</span>
        </a>
      </div>
      <div class="col-md-8 text-center">
        <h4>
          Please provide billing details for
          <span>{{ biller?.name }}</span>
          .
        </h4>
      </div>
    </div>
    <form
      class="frow center wrap recipient-content"
      novalidate
      [formGroup]="addRecipientForm"
      (ngSubmit)="submitRecipient($event)"
      #addForm="ngForm"
    >
      <div class="fcol-cs-12 fcol-md-8">
        <div class="row" *ngIf="hasError">
          <div class="col-md-12">
            <p class="error">
              <i class="icon bofi-information"></i>
              <span>
                Recipient name and account number already exist. Please review and try again.
              </span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="uk-label" for="account">* Account Number</label>
            <app-tooltip class="details-help" [content]="hintMessage" tabindex="-1"></app-tooltip>
            <input
              type="text"
              id="account"
              name="account"
              class="uk-input"
              maxlength="50"
              required
              formControlName="account"
              [ngClass]="{
                'error-message-input': addForm.submitted && formProperties.account.invalid
              }"
              (keyup)="removeCustomValidation()"
            />
            <div *ngIf="formProperties.account.invalid && addForm.submitted">
              <div *ngIf="formProperties.account.errors?.required" class="error-message">
                Please enter Account Number.
              </div>
              <div *ngIf="formProperties.account.errors?.invalidAccount" class="error-message">
                <span>{{ errorMessage }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="uk-label" for="zip">Recipient's Zip Code</label>
            <app-tooltip
              class="details-help"
              [content]="
                'Check your invoice or billing statement to find recipient’s zip code. If the zip code is unavailable, leave this field blank.'
              "
              tabindex="-1"
            ></app-tooltip>
            <input type="text" id="zip" name="zip" class="uk-input" maxlength="40" formControlName="zip" />
          </div>
          <div class="col-md-6">
            <label class="uk-label" for="nickname">Nickname (Optional)</label>
            <input
              type="text"
              id="nickname"
              name="nickname"
              class="uk-input"
              maxlength="50"
              formControlName="nickname"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 actions text-center">
            <button
              type="button"
              class="uk-btn outline primary lg hide-responsive"
              id="cancelButton"
              [disabled]="isBusy"
              (click)="cancelSetup()"
            >
              Cancel Setup
            </button>
            <button
              type="submit"
              class="uk-btn solid secondary lg hide-responsive"
              [disabled]="isBusy"
              id="continue-btn"
            >
              Continue
            </button>
            <button
              type="submit"
              class="uk-btn solid secondary lg show-responsive button-responsive top-button"
              [disabled]="isBusy"
            >
              Add Recipient
            </button>
            <button
              type="button"
              class="uk-btn outline primary lg show-responsive button-responsive"
              [disabled]="isBusy"
              (click)="goToSearchRecipient()"
            >
              Back
            </button>
          </div>
          <div class="fcol-cs-12 fcol-md-12" *ngIf="isBusy">
            <app-dot-loader *ngIf="isBusy"></app-dot-loader>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
