<div class="e-statements">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <h1>Set Up eBills</h1>
    </div>
  </div>

  <div class="frow center error-message">
    <app-alert-notification
      class="fcol-cs-12 fcol-md-6"
      *ngIf="errorMessage"
      message="errorMessage"
      type="'error'"
      on-close="errorMessage = null"
    ></app-alert-notification>
  </div>

  <div class="container e-statements-content" id="e-statements-content">
    <div class="cancel-responsive">
      <span (click)="cancelSetUp()">&#10005;</span>
    </div>
    <!--For account selection-->
    <div>
      <div class="frow">
        <a class="link fcol-sm-2 go-back hide-responsive" (click)="goBack()">
          <img [src]="'/img/icons/billPay/back_icon.svg'" width="15" aria-hidden="true" class="back-arrow" />
          Back
        </a>
        <p class="fcol-sm-6 fcol-sm-offset-1 text-center">
          Please select the account you want to use to set up eBills.
        </p>
      </div>

      <div class="frow center hide-responsive">
        <ul class="fcol-cs-8 recipient-accounts">
          <li *ngFor="let account of accounts" (click)="selectEBillAccount(account)">
            <a>{{ account.value }}</a>
          </li>
        </ul>
      </div>
      <div class="frow show-responsive responsive-accounts">
        <ul class="fcol-cs-12 responsive-account-list">
          <li *ngFor="let account of accounts" (click)="selectEBillAccount(account)">
            <hr />
            <a>{{ account.value }}</a>
          </li>
          <hr />
        </ul>
      </div>
      <div class="frow center form-buttons">
        <button type="button" class="uk-btn lg outline primary hide-responsive" (click)="cancelSetUp()">
          Cancel Setup
        </button>
        <button type="button" class="uk-btn lg outline primary show-responsive" (click)="goBack()">
          Back
        </button>
      </div>

      <app-dot-loader *ngIf="isLoading">It may take a while</app-dot-loader>
    </div>
  </div>
</div>
