<p class="mb-4 text-center font-weight-normal col-12">
  Enter a security word and a hint that only you would know.
</p>

<app-alert-notification class="d-block mb-4" *ngIf="errorMessage" alertType="error" (dismiss)="errorMessage = null">
  {{ errorMessage }}
</app-alert-notification>

<form class="change-word" [formGroup]="secretWordForm" (ngSubmit)="changeSecretWord()" *ngIf="secretWordForm">
  <div class="form-group" [class.invalid-field]="secretWord.hasError('*')">
    <div class="reverse">
      <input type="password" id="secret-word" maxlength="16" class="uk-input" formControlName="secretWord" />
      <app-reveal-input-label target="secret-word" [targetIsInvalid]="secretWord.hasError('*')">
        Security Word
      </app-reveal-input-label>
    </div>

    <div class="mt-1 text-error" *ngIf="secretWordForm.get('secretWord').errors?.minlength">
      <span>
        Security Word must be at least 4 characters long.
      </span>
    </div>

    <div class="mt-1 text-error" ngxErrors="secretWord" #secretWord="ngxErrors">
      <span ngxError="required">
        Security Word is required.
      </span>
      <span ngxError="pattern">
        Security Word is not valid, please enter a valid text.
      </span>
    </div>
  </div>

  <div class="form-group" [class.invalid-field]="secretHint.hasError('*')">
    <div class="reverse">
      <input type="password" id="secret-hint" maxlength="16" class="uk-input" formControlName="secretHint" />
      <app-reveal-input-label target="secret-hint" [targetIsInvalid]="secretHint.hasError('*')">
        Security Word Hint
      </app-reveal-input-label>
    </div>

    <div class="mt-1 text-error" *ngIf="secretWordForm.get('secretHint').errors?.minlength">
      <span>
        Security Word Hint must be at least 4 characters long.
      </span>
    </div>

    <div class="mt-1 text-error" ngxErrors="secretHint" #secretHint="ngxErrors">
      <span ngxError="required">
        Security Word Hint is required.
      </span>
      <span ngxError="pattern">
        Security Word Hint is not valid, please enter a valid text.
      </span>
    </div>
  </div>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="secretWordForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
