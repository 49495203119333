import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { isEqual } from 'date-fns';

import { TRANSFER_CONFIGURATION, TRANSFER_FUNDS_CONTENT } from '@app/axos-invest/constants';
import { DepositType, Frequency, GoalType, SendUntil, TransactionType, TransferType } from '@app/axos-invest/enums';
import {
  CompletedTransfer,
  Goal,
  MilestoneTransfer,
  MilestoneTransferResponse,
  ScheduledTransfer,
  ScheduledTransferRequest,
  Transfer,
  TransferForm,
  TransferFundsContentLabels,
  TransferResponse,
} from '@app/axos-invest/models';
import { AxosInvestTransferService } from '@app/axos-invest/services';
import { addScheduledTransfer } from '@app/axos-invest/store/scheduled-transfers/scheduled-transfers.actions';
import { mapFrequency } from '@app/axos-invest/utils';
import { normalizeDate } from '@app/utils';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons } from '@shared/enums';
import { ConfirmationField } from '@shared/models';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  providers: [DatePipe, CurrencyPipe],
})
export class ConfirmationComponent implements OnInit {
  @Input() transferData: Partial<TransferForm>;
  @Input() transferType: TransferType;
  @Input() fields: ConfirmationField[];
  @Input() goal: Goal;

  @Output() transferCompleted = new EventEmitter<CompletedTransfer>();
  @Output() goBack = new EventEmitter();

  fieldStrategy = {
    [TransferType.RecurrentDeposit]: this.setRecurrentDepositFields,
    [TransferType.FundingFlow]: this.setFundingFlowFields,
    [TransferType.OneTimeDeposit]: this.setOneTimeDepositFields,
    [TransferType.OneTimeWithdrawal]: this.setOneTimeWithdrawalFields,
    [TransferType.RecurrentWithdrawal]: this.setRecurringWithdrawalFields,
  };
  dateFormat = 'MM/dd/yyyy';
  isLoading: boolean;
  labels: TransferFundsContentLabels;
  showIraDisclosure = false;
  iraDisclosureAccepted = false;
  iraDisclosureTouched = false;
  iraAccount: string;

  icons = {
    arrow: NavigationIcons.ChevronDown,
  };

  get transferConfiguration() {
    return TRANSFER_CONFIGURATION[this.transferType];
  }

  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private transferService: AxosInvestTransferService,
    private serviceHelper: ServiceHelper,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.labels = TRANSFER_FUNDS_CONTENT[this.transferType].labels;
    window.scrollTo(0, 0);
    this.setFields();
  }

  createTransfer(): void {
    if (this.validateIraDisclosure()) {
      this.isLoading = true;
      const isExternal = !!this.transferData.account.isExternal;
      let transferAction: (isExternal: boolean) => Promise<any>;
      if (this.transferData.account.isAxosInvest) {
        transferAction = this.milestoneTransfer;
      } else if (this.validateIfOneTimeSameDay()) {
        transferAction = this.oneTimeSameDay;
      } else {
        transferAction = this.scheduleTransfer;
      }
      transferAction
        .bind(this)(isExternal)
        .then((result: CompletedTransfer) => this.transferCompleted.emit(result))
        .catch((error: ApiError | HttpErrorResponse) => this.serviceHelper.errorHandler(error))
        .finally(() => (this.isLoading = false));
    }
  }

  toggleIraDisclosure() {
    this.showIraDisclosure = this.showIraDisclosure ? false : true;
    this.icons.arrow = this.showIraDisclosure ? NavigationIcons.ChevronUp : NavigationIcons.ChevronDown;
  }

  acceptIraDisclosure(event: boolean) {
    this.iraDisclosureAccepted = event;
  }

  private setFields(): void {
    const action = this.fieldStrategy[this.transferType];
    this.fields = action.bind(this)();
  }

  private validateIfOneTimeSameDay(): boolean {
    const isSameDay = this.checkIfIsSameDay();
    const isOneTime = this.transferData.frequency === Frequency.ONE_TIME;

    return isSameDay && isOneTime;
  }

  private validateIraDisclosure(): boolean {
    if (this.transferData.contributionYear && !this.iraDisclosureAccepted) {
      this.iraDisclosureTouched = true;

      return false;
    } else {
      return true;
    }
  }

  private checkIfIsSameDay(): boolean {
    const today = normalizeDate(new Date());
    const startDate = normalizeDate(this.transferData.startDate);

    return isEqual(startDate, today);
  }

  private setRecurrentDepositFields(): ConfirmationField[] {
    let fields: ConfirmationField[];
    fields = [
      { label: 'Goal', value: this.goal.name },
      { label: this.labels.account, value: this.transferData.account.nickname },
      { label: this.labels.amount, value: this.formatCurrency(this.transferData.amount) },
      { label: this.labels.frequency, value: mapFrequency(this.transferData.frequency) },
      { label: this.labels.startDate, value: this.formatDate(this.transferData.startDate) },
    ];
    if (this.goal.type === GoalType.Retirement) {
      this.iraAccount = this.goal.accounts.filter(
        account => account.accountNumber === this.transferData.goalAccount
      )[0].name;
      const additionalFields = [{ label: 'Retirement Account Type', value: this.iraAccount }];
      if (this.transferData.contributionYear) {
        additionalFields.push({ label: 'IRA Contribution Year', value: this.transferData.contributionYear.toString() });
      }
      fields.splice(1, 0, ...additionalFields);
    }
    if (this.transferData.endDate) {
      fields.push({
        label: this.labels.endDate,
        value: this.formatDate(this.transferData.endDate),
      });
    }

    return fields;
  }

  private setFundingFlowFields(): ConfirmationField[] {
    const fields: ConfirmationField[] = [
      { label: 'Goal', value: this.goal.name },
      { label: this.labels.account, value: this.transferData.account.nickname },
      { label: this.labels.amount, value: this.formatCurrency(this.transferData.amount) },
      { label: this.labels.frequency, value: mapFrequency(this.transferData.frequency) },
      { label: this.labels.startDate, value: this.formatDate(this.transferData.startDate) },
    ];
    if (this.transferData.endDate) {
      fields.push({
        label: this.labels.endDate,
        value: this.formatDate(this.transferData.endDate),
      });
    }

    return fields;
  }

  private setOneTimeDepositFields(): ConfirmationField[] {
    let fields: ConfirmationField[];
    fields = [
      { label: 'Goal', value: this.goal.name },
      { label: this.labels.account, value: this.transferData.account.nickname },
      { label: this.labels.amount, value: this.formatCurrency(this.transferData.amount) },
      { label: this.labels.startDate, value: this.formatDate(this.transferData.startDate) },
    ];
    if (this.goal.type === GoalType.Retirement || this.transferData.account.nickname === 'Retirement') {
      this.iraAccount = this.goal.accounts.filter(
        account => account.accountNumber === this.transferData.goalAccount
      )[0].name;
      const additionalFields = [
        {
          label: 'Retirement Account Type',
          value: this.iraAccount === 'Personal Account' ? 'Non-IRA' : this.iraAccount,
        },
      ];
      if (this.transferData.contributionYear) {
        additionalFields.push({ label: 'IRA Contribution Year', value: this.transferData.contributionYear.toString() });
      }
      fields.splice(1, 0, ...additionalFields);
    }

    return fields;
  }

  private setOneTimeWithdrawalFields(): ConfirmationField[] {
    const fields: ConfirmationField[] = [
      { label: 'Goal', value: this.goal.name },
      { label: this.labels.account, value: this.transferData.account.nickname },
      { label: this.labels.amount, value: this.formatCurrency(this.transferData.amount) },
      { label: this.labels.startDate, value: this.formatDate(this.transferData.startDate) },
    ];
    if (this.goal.type === GoalType.Retirement || this.transferData.account.nickname === 'Retirement') {
      this.iraAccount = this.goal.accounts.filter(
        account => account.accountNumber === this.transferData.goalAccount
      )[0].name;
      const additionalFields = [
        {
          label: 'Retirement Account Type',
          value: this.iraAccount === 'Personal Account' ? 'Non-IRA' : this.iraAccount,
        },
      ];
      fields.splice(1, 0, ...additionalFields);
    }

    return fields;
  }

  private setRecurringWithdrawalFields(): ConfirmationField[] {
    const fields: ConfirmationField[] = [
      { label: 'Goal', value: this.goal.name },
      { label: this.labels.account, value: this.transferData.account.nickname },
      { label: this.labels.amount, value: this.formatCurrency(this.transferData.amount) },
      { label: this.labels.frequency, value: mapFrequency(this.transferData.frequency) },
      { label: this.labels.startDate, value: this.formatDate(this.transferData.startDate) },
    ];
    if (this.goal.type === GoalType.Retirement || this.transferData.account.nickname === 'Retirement') {
      this.iraAccount = this.goal.accounts.filter(
        account => account.accountNumber === this.transferData.goalAccount
      )[0].name;
      const additionalFields = [
        {
          label: 'Retirement Account Type',
          value: this.iraAccount === 'Personal Account' ? 'Non-IRA' : this.iraAccount,
        },
      ];
      fields.splice(1, 0, ...additionalFields);
    }

    return fields;
  }

  private formatCurrency(currency: number): string {
    return this.currencyPipe.transform(currency);
  }

  private formatDate(date: Date): string {
    return this.datePipe.transform(date, this.dateFormat);
  }

  private oneTimeSameDay(isExternal: boolean): Promise<CompletedTransfer> {
    const transfer = this.getRegularTransferModel();

    return this.transferService
      .createTransfer(transfer, isExternal)
      .toPromise()
      .then(response => this.convertTransferIntoCompletedTransfer(response.data));
  }

  private convertTransferIntoCompletedTransfer(transfer: TransferResponse): CompletedTransfer {
    const today = normalizeDate(new Date());

    return new CompletedTransfer({
      amount: Math.abs(transfer.amount),
      frequency: Frequency.ONE_TIME,
      frequencyDescription: mapFrequency(Frequency.ONE_TIME).toLowerCase(),
      transactionTypeDescription: transfer.transactionType === TransactionType.Deposit ? 'deposit' : 'withdrawal',
      startDate: today,
      milestoneId: transfer.milestoneId,
    });
  }

  private getRegularTransferModel(): Transfer {
    const transfer = new Transfer();
    // set goal data
    transfer.milestoneId = this.goal.id;
    // set form data
    transfer.accountNumber = this.transferData.goalAccount;
    transfer.accountId = this.transferData.accountId as number;
    transfer.amount = this.transferData.amount;
    if (this.transferData.contributionYear) {
      transfer.contributionYear = this.transferData.contributionYear;
    }
    // set configuration data
    transfer.depositType = this.getDepositType();
    transfer.transactionType = this.transferConfiguration.transactionType;

    return transfer;
  }

  private scheduleTransfer(isExternal: boolean): Promise<CompletedTransfer> {
    const transfer = this.getScheduledTransferModel();

    return this.transferService
      .createScheduledTransfer(transfer, isExternal)
      .toPromise()
      .then(response => this.processScheduledTransfer(response.data));
  }

  private processScheduledTransfer(transfer: ScheduledTransfer): CompletedTransfer {
    this.store.dispatch(addScheduledTransfer({ payload: transfer }));

    return new CompletedTransfer({
      amount: Math.abs(transfer.amount),
      frequency: transfer.frequency,
      frequencyDescription: transfer.frequencyDescription,
      transactionTypeDescription: transfer.transactionType === TransactionType.Deposit ? 'deposit' : 'withdrawal',
      sendUntil: transfer.sendUntil,
      startDate: transfer.startSendingDate,
      endDate: transfer.lastTransferDate,
      milestoneId: transfer.milestoneId,
    });
  }

  private getScheduledTransferModel(): ScheduledTransferRequest {
    const account = this.goal.accounts.find(a => a.accountNumber === this.transferData.goalAccount);
    const transfer = new ScheduledTransferRequest();
    // set goal data
    transfer.milestoneId = this.goal.id;
    transfer.milestoneName = `${this.goal.name} - ${account.name}`;
    // set form data
    transfer.accountNumber = this.transferData.goalAccount;
    transfer.sendUntil = this.transferData.endDate ? SendUntil.LastTransferDate : SendUntil.UntilIChooseToStop;
    transfer.accountId = this.transferData.accountId as number;
    transfer.amount = this.transferData.amount;
    transfer.startSendingDate = this.transferData.startDate;
    transfer.lastTransferDate = this.transferData.endDate;
    transfer.frequency = this.transferData.frequency;
    transfer.transferSeriesId = this.transferData.transferSeriesId;
    if (this.transferData.contributionYear) {
      transfer.contributionYear = this.transferData.contributionYear;
    }
    // set transfer types
    transfer.transactionType = this.transferConfiguration.transactionType;
    transfer.depositType = this.getDepositType();

    return transfer;
  }

  private getDepositType(): DepositType {
    if (this.transferType === TransferType.FundingFlow) {
      return this.transferData.frequency === Frequency.ONE_TIME ? DepositType.OneTime : DepositType.Auto;
    } else {
      return this.transferConfiguration.depositType;
    }
  }

  private milestoneTransfer(_isExternal: boolean): Promise<CompletedTransfer> {
    const model = this.getMilestoneTransferModel();

    return this.transferService
      .createMilestoneTransfer(model)
      .toPromise()
      .then(response => this.processMilestoneTransferResponse(response.data));
  }

  private getMilestoneTransferModel(): MilestoneTransfer {
    const isDeposit = this.transferConfiguration.transactionType === TransactionType.Deposit;

    return new MilestoneTransfer({
      accountNumber: this.transferData.goalAccount,
      amount: this.transferData.amount,
      toMilestoneId: isDeposit ? this.goal.id : this.transferData.account.routingNumber,
      fromMilestoneId: !isDeposit ? this.goal.id : this.transferData.account.routingNumber,
    });
  }

  private processMilestoneTransferResponse(response: MilestoneTransferResponse): CompletedTransfer {
    const isDeposit = this.transferConfiguration.transactionType === TransactionType.Deposit;

    return new CompletedTransfer({
      amount: response.amount,
      frequency: Frequency.ONE_TIME,
      frequencyDescription: mapFrequency(Frequency.ONE_TIME),
      transactionTypeDescription: isDeposit ? 'deposit' : 'withdrawal',
      startDate: response.createdAt,
      milestoneId: isDeposit ? response.toMilestoneId : response.fromMilestoneId,
    });
  }
}
