import { Component, Inject, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SCHEDULED_TRANSFER_MODAL_LABELS } from '@app/transfers/constants/cancel-scheduled-transfer-modal.constants';
import { CancelScheduledTransferModalTypes } from '@app/transfers/enums';
import { CancelScheduledTransferModalData } from '@app/transfers/models';

import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-cancel-scheduled-transfer-confirmation-modal',
  templateUrl: './cancel-scheduled-transfer-confirmation-modal.component.html',
  styleUrls: ['./cancel-scheduled-transfer-confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelScheduledTransferConfirmationModalComponent implements OnInit {
  cancelScheduledModalTypes = CancelScheduledTransferModalTypes;
  modalType: CancelScheduledTransferModalTypes;
  modalContentLabels: string[];
  modalHeaderLabel: string;
  modalHeaderIconName: AlertsIcons;
  modalCallback: Function | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CancelScheduledTransferModalData,
    private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CancelScheduledTransferConfirmationModalComponent>
  ) {}

  ngOnInit(): void {
    this.modalType = this.data.modalType;
    this.modalCallback = this.data.modalCallback;
    this.modalHeaderIconName = SCHEDULED_TRANSFER_MODAL_LABELS.icon[this.modalType];
    this.modalHeaderLabel = SCHEDULED_TRANSFER_MODAL_LABELS.header[this.modalType];
    this.modalContentLabels = SCHEDULED_TRANSFER_MODAL_LABELS.content[this.modalType];
    this.changeDetectorRef.detectChanges();
  }

  cancelSeries() {
    if (this.modalCallback) {
      this.modalCallback();
    }
    this.dialogRef.close();
  }

  dismissModal(): void {
    this.dialogRef.close();
  }
}
