import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';

import { AasCoreMatModule } from './aas-core-mat.module';
import { AasCoreUk2Module } from './aas-core-uk2.module';
import { AasStoreFeatures } from './enums';
import { initialOrganizationSettingsStateType, organizationSettingsReducer } from './organization-settings/core';
import { brandingSettingsReducer, initialBrandingSettingsStateType } from './branding-settings/core';
import { initialRiaStateType, riaReducer } from './rias/core';

const exportedModules = [CommonModule, BrowserModule, BrowserAnimationsModule, HttpClientModule];

@NgModule({
  imports: [
    ...exportedModules,
    AasCoreMatModule,
    AasCoreUk2Module,
    StoreModule.forFeature(AasStoreFeatures.OrganizationSettings, organizationSettingsReducer, {
      initialState: initialOrganizationSettingsStateType,
    }),
    StoreModule.forFeature(AasStoreFeatures.BrandingSettings, brandingSettingsReducer, {
      initialState: initialBrandingSettingsStateType,
    }),
    StoreModule.forFeature(AasStoreFeatures.Rias, riaReducer, {
      initialState: initialRiaStateType,
    }),
  ],
  exports: [...exportedModules],
})
export class AasCoreModule {}
