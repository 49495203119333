<section id="account-sharing-section" *ngIf="hasAccount">
  <div
    [ngClass]="{ 'account-sharing-initial-loans': isRedesign }"
    class="account-sharing"
  >
    <h5 [ngClass]="{ 'account-sharing-loans': isRedesign }">Account Sharing</h5>
    <div
      [ngClass]="{ 'account-tooltip-loans': isRedesign }"
      class="tooltip-sharing"
    >
      <uk2-tooltip
        [size]="size"
        [uk2IsLoading]="uk2IsLoading"
        [displayCloseButton]="displayCloseButton"
        [arrowOffset]="arrowOffset"
        [svgIconName]="svgIconName"
        [placement]="placement"
        [desktopOpenMode]="desktopOpenMode"
        [strategy]="strategy"
      >
        <div [innerHTML]="bodyText"></div>
      </uk2-tooltip>
    </div>
  </div>
  <div class="feature-container">
    <div
      [ngClass]="{ 'container-details-loans': isRedesign }"
      *ngFor="let advisor of advisors"
    >
      <div class="row">
        <div class="col-12 account-text">
          <diV class="checkbox-aas-account aas-accounts-line">
            <div class="uk2-checkbox-container">
              <img
                [src]="getAasImage(advisor)"
                class="image-aas-account"
                aria-hidden="true"
              />
              <span class="aasaccount-displayname">
                {{ advisor.advisorName }}
              </span>
            </div>
          </diV>
        </div>
      </div>
      <div class="row" id="share" *ngIf="shareAccount && displayShareAccount">
        <diV class="col-11 container-text-middle">
          <div class="uk2-checkbox-container">
            <span class="aas-container-text">
              Share account details
            </span>
          </div>
        </diV>
        <div class="col-1 checkbox-responsive">
          <span class="checkbox-switch">
            <label class="uk-switch custom-switch">
              <input
                type="checkbox"
                [(ngModel)]="advisor.sharedAccount"
                (change)="openModalFromComponent($event, advisor)"
              />
              <span class="slider">
                <i class="slider--icon"></i>
              </span>
            </label>
          </span>
        </div>
      </div>
      <div
        class="row"
        id="authorize"
        *ngIf="authorizeTransfers && displayAuthorizeTransfer"
      >
        <diV class="col-11 container-text-middle">
          <div class="uk2-checkbox-container">
            <span class="aas-container-text">
              Authorize transfers
            </span>
          </div>
        </diV>
        <div class="col-1 checkbox-responsive">
          <span class="feature-switch">
            <label class="uk-switch custom-switch">
              <input
                type="checkbox"
                [(ngModel)]="advisor.authorizedTransfer"
                (change)="openModalFromComponent($event, advisor)"
              />
              <span class="slider">
                <i class="slider--icon"></i>
              </span>
            </label>
          </span>
        </div>
        <div
          [ngClass]="{ 'space-between-sections-loans': isRedesign }"
          class="space-between-sections"
        ></div>
      </div>
    </div>
  </div>
</section>
