import { SupportFile } from '@shared/models/support-file.model';

import { Transaction } from './transaction.model';

/** Represents the data information to dispute a transaction. */
export class DisputeDataTransaction {
  /** Transaction unique identifier. */
  transaction: Transaction;
  /** User reason to dispute transaction. */
  disputeReason = '';
  /** User documents to dispute. */
  documents: SupportFile[] = [];
  /** User card(s) lost. */
  cardsLost: Partial<DebitCard>[];
  /** Phone selected in the dropdown */
  phoneSelected: string;

  constructor(args?: Partial<DisputeDataTransaction>) {
    Object.assign(this, args);
  }
}
