import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';
import {
  addSharedAccounts,
  addSharedAccountsByAdvisor,
  addSharedAccountsPrompting,
  addUpdateSharedAccounts,
  addUpdateSharedAccountsByAdvisor,
} from '../shared-accounts.actions';
import { SharedAccountsByAdvisorResponse } from '../../services/ria-account-for-sharing-response/types';
import { initialSharedAccountsStateType, SharedAccountsStateType } from '..';

const _reducerShared = createReducer(
  initialSharedAccountsStateType,
  on(addSharedAccounts, (_state, action) => {
    return action.payload;
  }),
  on(addUpdateSharedAccounts, (_state, action) => {
    if (!_state || !_state.sharedAccounts) {
      return initialSharedAccountsStateType;
    }

    return {
      sharedAccounts: {
        accounts: action.payload.accounts,
        udbUserId: _state.sharedAccounts.udbUserId,
      },
    };
  }),
  on(signOut, () => {
    return null;
  })
);

export function sharedAccountsReducer(state: SharedAccountsStateType, action: Action) {
  return _reducerShared(state, action);
}

const _reducerAdvisor = createReducer(
  [],
  on(addSharedAccountsByAdvisor, (_state, action) => {
    return [..._state, action.payload];
  }),
  on(addUpdateSharedAccountsByAdvisor, (_state, action) => {
    const indexOfUpdated = _state.findIndex(
      m => m.advisorId === action.payload.advisorId && m.udbUserId === action.payload.udbUserId
    );
    if (indexOfUpdated >= 0) {
      return [..._state].map(item => {
        return item.advisorId === action.payload.advisorId && item.udbUserId === action.payload.udbUserId
          ? (item = action.payload)
          : item;
      });
    } else {
      return [..._state];
    }
  }),
  on(signOut, () => {
    return null;
  })
);

export function sharedAccountsByAdvisorReducer(state: SharedAccountsByAdvisorResponse, action: Action) {
  return _reducerAdvisor(state, action);
}

const _reducerSharedPrompting = createReducer(
  initialSharedAccountsStateType,
  on(addSharedAccountsPrompting, (_state, action) => {
    return action.payload;
  }),
  on(signOut, () => {
    return null;
  })
);

export function sharedAccountsPromptingReducer(state: SharedAccountsStateType, action: Action) {
  return _reducerSharedPrompting(state, action);
}
