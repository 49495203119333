import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AuthRequest,
  AuthResponse,
  ChallengeQuestionResponse,
  ChangePasswordRequest,
  GASignup,
  RandomSecurityQuestion,
  ResetPasswordResponse,
  UnlockRequest,
  UnlockUserStatusResponse,
  UsernameAndToken,
} from '@app/authentication/models';
import { LogoutRequest, OlbSettings, Restriction } from '@core/models';
import { olbSettings } from '@core/tokens';
import { ServiceResult, WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'authentication');
  }

  /**
   * Logs the user into the application using v2.
   * @param credentials User credentials to use on login.
   * @param token Token to check if user comes from an Admin Reset password flow
   * @returns An Observable containing the response from the server.
   */
  login(credentials: AuthRequest, token: string = null): WebApiResponse<AuthResponse> {
    let endpoint = 'signin/v2';

    if (token) endpoint += `?token=${token}`;

    return this.post(endpoint, credentials);
  }

  /**
   * Logs the user out of the application.
   * @param logoutRequest Specifies if the event is from a desktop browser and the current users location before logout.
   * @returns An Observable which can be resolved in case of success.
   */
  logout(logoutRequest: LogoutRequest): WebApiResponse<null> {
    return this.post('logout', logoutRequest);
  }

  /**
   * Forces a log out for the user.
   * @param logoutRequest Specifies if the event is from a desktop browser and the current users location before logout.
   * @returns An Observable which can be resolved in case of success.
   */
  forcedLogout(logoutRequest: LogoutRequest): WebApiResponse<null> {
    return this.post('logout/forced', logoutRequest);
  }

  /**
   * Send the reset password to the email.
   * @param username: Valid username for the user to reset the password.
   * @returns A promise containing the response from the server.
   */
  sendForgotPassword(username: string): WebApiResponse<null> {
    return this.get(`password/reset/${username}`);
  }

  /**
   * Get a token for resetting the password
   * @param username: The username whose password is going to be reset
   * @returns A promise containing the response from server
   */
  resetPassword(username: string): WebApiResponse<ResetPasswordResponse> {
    return this.get<ResetPasswordResponse>(`password/reset/${username}/v2`);
  }

  /**
   * Gets a random security queston so the user can continue unlocking their
   * account or resetting their password.
   * @param token URL token.
   * @returns An Observable containing a random security question.
   */
  getSecurityQuestion(token: string, v2: boolean): WebApiResponse<RandomSecurityQuestion> {
    const brandId = this.settings.brandId.toString();

    return this.get(`unlock/securityquestion/${token}?v2=${v2}`, null, { brandId });
  }

  /**
   * Validates the answer to the security question.
   * @param username Username
   * @param unlockRequest Object containing the answered security question.
   * @returns An Observable containing the response from the server.
   */
  checkSecurityQuestionAnswer(
    username: string,
    unlockRequest: UnlockRequest
  ): WebApiResponse<ChallengeQuestionResponse> {
    const brandId = this.settings.brandId.toString();

    return this.post(`unlock/securityquestion/${username}`, unlockRequest, { brandId });
  }

  /**
   * Saves new password for user.
   * @param username Username.
   * @param changePasswordRequest Object containing current and new password with  an auth token.
   * @returns An Observable containing the response from the server.
   */
  setNewPassword(username: string, changePasswordRequest: ChangePasswordRequest): WebApiResponse<string> {
    return this.post(`unlock/${username}`, changePasswordRequest);
  }

  /**
   * Validates the reset password link.
   * @param token URL Token.
   * @param securityToken Additional token for security.
   * @returns An Observables containing the response from the server.
   */
  checkResetPasswordLinkValidity(token: string, securityToken: string): WebApiResponse<UsernameAndToken> {
    return this.get(`password/reset/${token}/${securityToken}`);
  }

  /**
   * Gets the restrictions for the current user.
   * @returns An Observable containing a collection of restrictions.
   */
  getUserRestrictions(): WebApiResponse<Restriction[]> {
    return this.get('restrictions');
  }

  /**
   * Gets the Sitecore Identity.
   * @returns An Observable containing the response from the server.
   */
  getSiteCoreIdentity(username: string, token: string): WebApiResponse<UserIdentity> {
    const requestUrl = `${this.url}/sitecore/${username}`;
    const headers = { Authorization: `Bearer ${token}` };

    return this.http.get<ServiceResult<UserIdentity>>(requestUrl, { headers });
  }

  /**
   * Checks if the user has the restriction with the given ID.
   * @returns An Observable containing the response from the server.
   */
  hasUserRestriction(restrictionId: number): WebApiResponse<boolean> {
    return this.get(`restrictions/${restrictionId}`);
  }

  /**
   * Gets the current user status.
   * @param token Security token.
   * @returns An Observable containing the response from the server.
   */
  getUserStatus(token: string): WebApiResponse<UnlockUserStatusResponse> {
    return this.get(`completeregistration/${token}`);
  }

  /**
   * Refreshes the current user token.
   * @returns An Observable containing the response from the server.
   */
  refreshToken(): WebApiResponse<AuthResponse> {
    return this.get('refreshtoken');
  }

  /**
   * Gets the user last login date.
   * @returns An Observable containing the response from the server.
   */
  getUserLastLoginDate(): WebApiResponse<string> {
    return this.get('lastlogin');
  }

  /**
   * Gets google QR.
   * @returns An Observable containing the response from the server.
   */
  getGoogleQR(): WebApiResponse<GASignup> {
    return this.get('auth-qr');
  }

  /**
   * Validate google QR.
   * @returns An Observable containing the response from the server.
   */
  validateGoogleQR(code: string): WebApiResponse<boolean> {
    return this.post(`auth-qr?code=${code}`);
  }

  /**
   * reset google authentication.
   * @returns An Observable containing the response from the server.
   */
  resetGoogle(): WebApiResponse<boolean> {
    return this.post('auth-qr/reset');
  }
}
