import { Inject } from './decorators/decorators';
import { ICompileProvider } from 'angular';
import { IUiViewScrollProvider } from 'angular-ui-router';
import { RootReducer } from './state-store/reducers/index';
import { createLogger } from 'redux-logger';
import NgRedux from 'ng-redux';

@Inject(
  '$compileProvider',
  '$uiViewScrollProvider',
  '$httpProvider',
  '$ngReduxProvider',
  'env',
  '$locationProvider'
)
export class AppConfig {
  constructor(
    compiler: ICompileProvider,
    uiViewScrollProvider: IUiViewScrollProvider,
    http: ng.IHttpService,
    ngReduxProvider: NgRedux.INgReduxProvider,
    env: OlbSettings,
    _location: ng.ILocationProvider
  ) {
    compiler.debugInfoEnabled(false);
    uiViewScrollProvider.useAnchorScroll();
    http.defaults.withCredentials = true;

    let middlewares: Array<any> = [];

    if (env.environment === 'Development') {
      middlewares.push(
        createLogger({
          level: 'info',
          collapsed: true,
        })
      );
    }

    ngReduxProvider.createStoreWith(RootReducer, middlewares);

    _location.html5Mode({
      enabled: true,
      requireBase: false,
    });
  }
}
