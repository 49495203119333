import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OlbSettings } from '@core/models';
import { ReadAgreementResponse } from '@core/models/read-agreement-response';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';

@Injectable({
  providedIn: 'root',
})
export class AgreementService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/Agreements');
  }

  validateAgreements(agreementType: AasAgreementType): WebApiResponse<ReadAgreementResponse> {
    return this.get(`validateAgreements/${agreementType}`, { connectionId: 'N/A' });
  }

  updateAgreements(accepted: boolean): WebApiResponse<boolean> {
    return this.post('updateAgreements', accepted);
  }

  updateAgreementsByValue(accepted: boolean, agreementType: AasAgreementType): WebApiResponse<boolean> {
    return this.post(`updateAgreements/${agreementType}`, accepted);
  }
}
