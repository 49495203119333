import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AccountAuthorizationsService } from '../core/services';
import { accountsSelectors } from '../core/store';
import { addUpdateAuthorizationsToAccountAction } from '../core/store/authorizations.actions';
import { AuthorizationsTabState } from '../core/store/types';
import { InitializeAuthorizationsInputType } from './types';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationsFacade {
  authorizationsTabAccountState$ = this.store.select(accountsSelectors.selectedAccountAuthorizations);

  constructor(
    private readonly accountAuthorizations: AccountAuthorizationsService,
    private readonly store: Store<AuthorizationsTabState>
  ) {}

  initializeAuthorizationsTab(input: InitializeAuthorizationsInputType): void {
    this.accountAuthorizations
      .getAccountsAuthorization(input)
      .pipe(retry(3))
      .subscribe({
        next: response => {
          const stateItem: AuthorizationsTabState = {
            advisorContributionAuthority: response.data?.advisorContributionAuthority,
            advisorDistributionAuthority: response.data?.advisorDistributionAuthority,
            bankWireAuthorities: response.data?.bankWireAuthorities,
            achOneTimeAuthorities: response.data?.achOneTimeAuthorities,
            other: response.data?.other,
            accountNumber: input.accountNumber,
            error: undefined,
          };

          this.store.dispatch(
            addUpdateAuthorizationsToAccountAction({
              payload: {
                authorizationTabState: stateItem,
                accountNumber: input.accountNumber,
              },
            })
          );
        },
        error: error => {
          const stateItem: AuthorizationsTabState = {
            advisorContributionAuthority: undefined,
            advisorDistributionAuthority: undefined,
            bankWireAuthorities: undefined,
            achOneTimeAuthorities: undefined,
            other: undefined,
            accountNumber: input.accountNumber,
            error: error,
          };

          this.store.dispatch(
            addUpdateAuthorizationsToAccountAction({
              payload: {
                authorizationTabState: stateItem,
                accountNumber: input.accountNumber,
              },
            })
          );
        },
      });
  }
}
