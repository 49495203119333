import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit } from '@angular/core';

import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  isValid,
  parseISO,
} from 'date-fns';

import { AccountCategory } from '@app/accounts/enums';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { HistoricalBalanceCalculation } from '@app/pfm/models/historical-balance-calculation.model';
import { olbSettings } from '@core/tokens';
import { STATE } from '@core/tokens';
import { containerType } from '@legacy/accounts/container-type.enum';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { CategoryName } from '@legacy/tiles/account-overview/typings/CategoryName.enum';
import { AggregatedAccount } from '@legacy/typings/app/account-aggregation';
import { UtilityIcons } from '@shared/enums';
import { ExternalBankProvider } from '@shared/models';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { InitializePdpInputType } from '@app/Areas/AAS/features/product-details-page/facade/types';
import { AxosAdvisoryAccount } from '@core/models';
import { BrandingSettingsFacade, BrandingStateType } from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PfmAccountState } from '@app/pfm/models';

@Component({
  selector: 'app-account-group',
  templateUrl: './account-group.component.html',
  styleUrls: ['./account-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountGroupComponent implements OnInit, OnChanges {
  @Input() accounts: AccountOverview[];
  @Input() excludedAccountsId: Set<string>;
  @Input() displayHeader: boolean;
  @Input() groupTitle: string;
  @Input() providers: ExternalBankProvider[];
  @Input() historicalBalances: HistoricalBalanceCalculation[];
  arrowPosition = '';
  totalBalanceByGroup: number;
  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  destroy$ = new Subject<void>();
  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(olbSettings) private readonly env: OlbSettings,
    readonly accAggFormatter: AccountAggregationChartFormatterService,
    readonly featureFlagService: FeatureFlagService,
    private pdpFacade: PdpFacade,
    private brandingSettingsFacade: BrandingSettingsFacade,
    private riaFacade: RiaFacade
  ) {}

  ngOnInit() {
    this.brandingSettingsFacade.allBrandingSettings$.pipe(takeUntil(this.destroy$)).subscribe(brandings => {
      this.allBrandingSettings = brandings;
    });

    this.riaFacade.allRias$.pipe(takeUntil(this.destroy$)).subscribe(rias => {
      this.allRiaAccounts = rias;
    });
  }

  getImage(account: AggregatedAccount) {
    return this.accAggFormatter.getImage(
      true,
      this.providers,
      this.env,
      account,
      this.allBrandingSettings,
      this.allRiaAccounts
    );
  }

  getBankProfile(account: AggregatedAccount) {
    const defaultBankName = '';
    const bankName = account.bankName !== null ? account.bankName : defaultBankName;
    return this.accAggFormatter.getBankProfile(bankName);
  }

  getAccountTitle = (account: AggregatedAccount) => {
    const { nickname, container, bankName, category } = account;
    let accountName = '';

    if (nickname != null) {
      const riaDefaultName = 'Advisor Managed Account';
      const riaName = bankName || riaDefaultName;
      accountName = container === 'RIA' ? riaName : `${nickname} ${this.getCategoryName(category, container)}`;
    } else {
      accountName = this.getCategoryName(category, container);
    }

    return accountName;
  };
  getAccountMask(account: AggregatedAccount) {
    if (!account?.accountMask) return '';
    const accountMask =
      account?.accountMask && account?.accountMask.length > 4
        ? `\u00A0- ${account.accountMask.substring(account.accountMask.length - 4)}`
        : `\u00A0- ${account.accountMask}`;

    return accountMask;
  }
  getBankName(account: AggregatedAccount): string {
    if (account?.accountType === 'trading' && account.categoryName === CategoryName.Investment) {
      const bankName = account.bankName.split('*')[0];

      return bankName;
    } else {
      if (account.container === 'RIA') {
        return account.nickname;
      } else {
        return account?.bankName;
      }
    }
  }

  goToDetails(account: AggregatedAccount) {
    let state = '';
    let accountType = '';

    if (account.isAxosInvest) {
      state = 'udb.accounts.container';
      accountType = 'Invest';
    } else if (account.isTrading) {
      state = 'udb.accounts.container';
      accountType = 'Trading';
    } else if (account.isAxosAdvisory) {
      const axosAdvisorSatet = this.setAxosAdvisoryAccount(account);
      state = axosAdvisorSatet.state;
      accountType = axosAdvisorSatet.accountType;
    } else {
      const flagActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
      account.isExternal && flagActive
        ? (state = 'udb.accounts.external-details')
        : !account.isExternal
        ? (state = 'udb.accounts.details')
        : (state = '');
    }
    this.state.go(state, {
      id: account.id,
      type: accountType,
      container: account.container,
      tab: 0,
      accountNumber: account.accountNumber,
    });
  }
  ngOnChanges(): void {
    this.totalBalanceByGroup = this.historicalBalances.reduce(
      (prev: number, next: HistoricalBalanceCalculation) => prev + (next.currentBalance || 0),
      0
    );
  }
  getArrowPosition(account: AggregatedAccount): string {
    let position = '';
    const percentageChange = this.historicalBalances.find(
      (historicBalance: HistoricalBalanceCalculation) => historicBalance.accountId === account.id
    );

    if (percentageChange) {
      position =
        percentageChange.arrowPosition === 'upward arrow'
          ? UtilityIcons.ArrowUp
          : percentageChange.arrowPosition === 'downward arrow'
          ? UtilityIcons.ArrowDown
          : '';
    }

    return position;
  }

  getCategoryName(category: AccountCategory, container: string): CategoryName {
    if (category === AccountCategory.Dda) return CategoryName.Checking;
    if (category === AccountCategory.Loan) return CategoryName.Loans;
    if (category === AccountCategory.Credit) return CategoryName.CreditCards;
    if (container === containerType.Investment) return CategoryName.Investment;

    if (category === AccountCategory.Sav || category === AccountCategory.Cd) {
      return CategoryName.Savings;
    }

    return CategoryName.Default;
  }
  getPercentageChange(account: AggregatedAccount): number {
    const percentageChange = this.historicalBalances.find(
      (historicBalance: HistoricalBalanceCalculation) => historicBalance.accountId === account.id
    );

    return percentageChange?.percentageChange;
  }

  getDisplayAmountChange(account: AggregatedAccount) {
    const amountChange = this.historicalBalances.find(
      (historicBalance: HistoricalBalanceCalculation) => historicBalance.accountId === account.id
    )?.amountChange;

    if (amountChange == null) return '$--';
    if (amountChange === 0) return '$0';

    const sign = amountChange > 0 ? '+' : '-';

    return (
      sign +
      Math.abs(amountChange).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    );
  }

  getAccountBalance(account: AggregatedAccount) {
    const balance = this.historicalBalances.find(
      (historicBalance: HistoricalBalanceCalculation) => historicBalance.accountId === account.id
    );

    return balance?.currentBalance;
  }

  getFilteredAccountsLength() {
    return this.accounts?.filter(a => !this.excludedAccountsId.has(a.globalId)).length;
  }

  getTimePeriod(date: string) {
    const givenDate = this.parseLastUpdate(date);
    const today = new Date();
    let period = '';

    const daysDiff = differenceInDays(today, givenDate);
    const weekDiff = differenceInWeeks(today, givenDate);
    const monthDiff = differenceInMonths(today, givenDate);
    const yearDiff = differenceInYears(today, givenDate);

    if (yearDiff >= 1) period = `${yearDiff} year${yearDiff > 1 ? 's' : ''} ago`;
    else if (monthDiff >= 1) period = `${monthDiff} month${monthDiff > 1 ? 's' : ''} ago`;
    else if (weekDiff >= 1) period = `${weekDiff} week${weekDiff > 1 ? 's' : ''} ago`;
    else if (daysDiff >= 1) period = `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
    else if (daysDiff === 0) period = 'Today';

    return period;
  }
  goToRestoreConnection(account: AggregatedAccount) {
    this.state.go('udb.dashboard.account-aggregation.auth', {
      bankId: account.providerId,
      updateCredentials: true,
      providerAccountId: account.providerAccountId,
      accountNickName: this.getNickName(account),
    });
  }

  getNickName(account: AggregatedAccount) {
    if (account.nickname) {
      return account.nickname.split('**')[0];
    } else {
      return account.name || account.bankName;
    }
  }

  parseLastUpdate(date: string) {
    const lastUpdateInLocalTimeZone = parseISO(date + (date.endsWith('Z') ? '' : 'Z'));

    if (!isValid(lastUpdateInLocalTimeZone)) return null;

    return lastUpdateInLocalTimeZone;
  }

  private setAxosAdvisoryAccount(account: AggregatedAccount): PfmAccountState {
    const stateAxosAdvisory = 'udb.productDetailsPage';
    const accountTypeAxosAdvisory = 'Ria';
    const pfmAccountState: PfmAccountState = { state: stateAxosAdvisory, accountType: accountTypeAxosAdvisory };
    const initializePdpInputType: InitializePdpInputType = {
      axosAdvisoryAccount: { ...(account as AxosAdvisoryAccount) },
    };
    this.pdpFacade.initializePdp(initializePdpInputType);
    return pfmAccountState;
  }
}
