import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { HoldingsSummaryRequest, HoldingsSummaryResponse } from './types';

@Injectable()
export class HoldingsSummaryService extends BaseService {
  /**
   * HoldingsSummaryService constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/holdings');
  }

  /**
   * Returns Holding Summary response
   *
   * @param accountNumber - string
   * @returns Holding Summary response observable
   */
  getHoldingsSummary(request: HoldingsSummaryRequest): WebApiResponse<HoldingsSummaryResponse[]> {
    return this.get(request.accountNumber + '/summary');
  }
}
