import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { GoalType } from '@app/axos-invest/enums';
import {
  DateFilter,
  Filters,
  Forecast,
  ForecastParams,
  Formula,
  Goal,
  GoalAllocation,
  MilestoneSummary,
  PastPerformanceClient,
  Portfolio,
  RecommendedAutoDeposit,
  RecommendedAutoDepositFilter,
  RiskScore,
  Transaction,
} from '@app/axos-invest/models';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable()
export class AxosInvestService extends BaseService {
  riskScore: Observable<RiskScore>;
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'axosinvest');
  }

  getTransactions(filters?: Filters) {
    let params = new HttpParams();
    if (filters) {
      params = this.setHttpParams(params, filters);
    }

    return this.get<Transaction[]>('transactions', params);
  }

  getMilestoneForecast(milestoneId: string, forecastParams?: ForecastParams) {
    let params = new HttpParams();
    if (forecastParams) {
      params = this.setHttpParams(params, forecastParams);
    }
    const endpoint = `milestones/${milestoneId}/forecast`;

    return this.get<Forecast>(endpoint, params);
  }

  getPortfolio(milestoneId: string) {
    return this.get<Portfolio>(`milestones/${milestoneId}/positions`);
  }

  getFormulas() {
    return this.get<Formula>('formulas');
  }

  getMilestones() {
    return this.get<MilestoneSummary>('milestones');
  }

  getPastPerformance(milestoneId: string, filter?: DateFilter) {
    let params = new HttpParams();
    if (filter) {
      params = this.setHttpParams(params, filter);
    }

    return this.get<PastPerformanceClient>(`pastperformance?milestoneId=${milestoneId}`, params);
  }

  getRiskScore() {
    if (!this.riskScore) {
      this.riskScore = this.get<RiskScore>('assets/risk-score-model').pipe(shareReplay(1));
    }

    return this.riskScore;
  }

  getRecommendedAutoDeposit(goalType: GoalType, filter: RecommendedAutoDepositFilter) {
    return this.post<RecommendedAutoDeposit>(`${goalType}/recommendedautodeposit`, filter);
  }

  getSsoToken() {
    return this.get<string>('sso/token');
  }

  updateMilestone(milestoneId: string, goal: Goal) {
    return this.put<MilestoneSummary>(`milestones/${milestoneId}`, goal);
  }

  updateAllocation(milestoneId: string, allocation: GoalAllocation) {
    return this.put(`milestones/${milestoneId}/allocation`, allocation);
  }

  deleteMilestone(milestoneId: string) {
    return this.delete<string>(`milestone/${milestoneId}`);
  }

  private setHttpParams(params: HttpParams, obj: any, prefix?: string) {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      const objValue = obj[key];
      if (objValue !== null) {
        const name = prefix ? prefix + '.' + key : key;
        let value = '';
        if (objValue instanceof Date) {
          value = obj[key].toISOString();
          params = params.set(name, value);
        } else if (objValue instanceof Array) {
          const array = objValue as any[];
          array.forEach(itemValue => {
            params = params.append(name, itemValue);
          });
        } else if (typeof objValue === 'object') {
          params = this.setHttpParams(params, objValue, key);
        } else {
          value = objValue;
          params = params.set(name, value);
        }
      }
    });

    return params;
  }
}
