<div class="dialog-header" [style.backgroundImage]="backgroundUrl">
  <h3 class="text-white font-weight-normal">Verification Code</h3>
</div>

<div class="mat-dialog-content access-code__content">
  <form id="verification-form" class="access-code__form" [formGroup]="codeForm" (ngSubmit)="validateCode()">
    <p class="mb-3 access-code__text">
      We've sent a text with your verification code to
      <br />
      {{ maskedPhone }}
    </p>

    <label for="code" class="mb-3 access-code__label">
      Enter your code and click continue.
    </label>

    <div class="text-error" ngxErrors="code" #codeField="ngxErrors">
      <span *ngFor="let error of errors" [id]="error.name" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>
    <input
      type="tel"
      formControlName="code"
      id="code"
      class="uk-input"
      (keypress)="allowOnlyNumbers($event)"
      [class.invalid-field]="codeField.hasError('*')"
      pattern="[0-9]*"
    />

    <p class="mt-3">
      Didn't receive your code?
      <button id="resend-code" type="button" class="link" aria-label="Resend the code" (click)="resendCode()">
        RESEND
      </button>
    </p>

    <div class="dialog__actions">
      <ng-container *ngIf="!isBusy; else loader">
        <button type="button" class="btn-cancel button--lg" [mat-dialog-close]="false">
          Cancel
        </button>
        <button type="submit" class="btn-ok button--lg" cdkFocusInitial>
          Continue
        </button>
      </ng-container>
      <ng-template #loader>
        <app-dot-loader></app-dot-loader>
      </ng-template>
    </div>
  </form>
</div>
