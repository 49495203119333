import { ChangeDetectionStrategy, Component, Input, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import tippy, { Props, animateFill, roundArrow } from 'tippy.js';
import { OpenAccountItem } from '@app/accounts/models/open-account-item.model';

@Component({
  selector: 'app-open-account-popover',
  templateUrl: './open-account-popover.component.html',
  styleUrls: ['./open-account-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OpenAccountPopoverComponent {
  constructor() {}
  @Input() accountItems: OpenAccountItem[] = [];
  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChild('dropdown', { read: ElementRef, static: false }) dropdown: ElementRef;

  getChevronIcon(chevronName: string): any {
    return `assets/chevron-${chevronName}.svg`;
  }

  redirectToUrl(url: string) {
    window.open(url, '_blank');
  }

  options: Partial<Props> = {
    allowHTML: true,
    animateFill: false,
    arrow: roundArrow,
    placement: 'bottom',
    plugins: [animateFill],
    theme: 'olbAcc',
    interactive: true,
    appendTo: 'parent',
    trigger: 'click',
    hideOnClick: true,
  };

  ngAfterViewInit(): void {
    const template = this.dropdown.nativeElement;
    this.options.content = template;
    tippy(this.tooltip.nativeElement, this.options);
  }
}
