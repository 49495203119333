import { TotalValueIntervalPeriodState } from './total-value-interval-period-state.type';

export type TotalValueTileState = {
  accountNumber: string | undefined;
  totalValue: number | undefined;
  differencePriorToday: number | undefined;
  percentagePriorToday: number | undefined;
  interval: { [key: string]: TotalValueIntervalPeriodState };
  hasError: boolean;
};

export const initialTotalValueTileState: TotalValueTileState = {
  accountNumber: undefined,
  totalValue: 0,
  differencePriorToday: 0,
  percentagePriorToday: 0,
  interval: {},
  hasError: false,
};
