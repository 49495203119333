<app-dot-loader [showWhen]="isLoading">
  Loading transactions
</app-dot-loader>

<table class="table tx-table" *ngIf="!isLoading">
  <thead>
    <tr>
      <th *ngIf="isMultipleDispute">
        <input
          type="checkbox"
          class="checkbox"
          id="chk-select-all"
          [(ngModel)]="allSelected"
          (ngModelChange)="toggleSelection(allSelected)"
        />
        <label for="chk-select-all"></label>
      </th>
      <th
        *ngFor="let column of columns"
        [ngClass]="{ 'text-right': column.value > 3 }"
        (click)="sort(column.subvalue, column.value)"
      >
        {{ column.label }}
        <i
          *ngIf="column.value > 0 && orderBy === column.subvalue"
          [ngClass]="{ 'fa-caret-up': orderBy === column.subvalue && !reverse }"
          class="fa fa-caret-down"
          aria-hidden="true"
        ></i>
      </th>
      <th colspan="2"></th>
    </tr>
  </thead>
  <tbody>
    <!-- No records -->
    <tr *ngIf="!filtered.length">
      <td colspan="7">
        <h3 class="text-center m-t-lg">
          No transactions meet your search criteria
        </h3>
      </td>
    </tr>
    <!-- Aggregated transactions -->
    <ng-container
      *ngFor="
        let trxn of filtered | orderBy: orderBy:reverse;
        let index = $index
      "
    >
      <app-tx-row-agg
        *ngIf="isAggregatedAccount"
        [ngClass]="[
          {
            'expanded-row':
              trxn.isRecategorizeExpanded || trxn.isDetailsExpanded
          },
          {
            hoverable:
              isTransactionDisputeActive && !trxn.isRecategorizeExpanded
          },
          { multiple: isMultipleDispute },
          { selected: trxn.isSelected || trxn.isDetailsExpanded }
        ]"
        [trxn]="trxn"
        [isAccountActive]="account.isOpen"
        (onOptionSelected)="performAction($event, trxn)"
        (onCollapse)="collapse(trxn)"
      ></app-tx-row-agg>
      <!-- Transaction Details-->
      <tr
        *ngIf="
          isPfm3ExternalTransActive &&
          isAggregatedAccount &&
          trxn.isDetailsExpanded &&
          !trxn.isRecategorizeExpanded
        "
        class="expanded-content details-row"
      >
        <td colspan="7">
          <app-transaction-details
            [transaction]="trxn"
          ></app-transaction-details>
        </td>
      </tr>
      <!--recategorize a transaction-->
      <tr
        *ngIf="isAggregatedAccount && trxn.isRecategorizeExpanded"
        class="expanded-content recategorize-focus"
      >
        <td colspan="7">
          <app-recategorize-transactions
            [transaction]="trxn"
            (afterSave)="onRecategoriziationSuccess(trxn)"
            (afterCancel)="collapse(trxn)"
            (onError)="errorMesage()"
          ></app-recategorize-transactions>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="!isAggregatedAccount">
    <!-- Regular transaction row -->
    <ng-container
      *ngFor="let trxn of filtered | orderBy: orderBy; let i = index"
    >
      <app-tx-row
        [id]="'tx-' + i"
        [ngClass]="{
          'expanded-row':
            trxn.isExpanded ||
            trxn.isRecategorizeExpanded ||
            trxn.isDetailsExpanded,
          hoverable: isTransactionDisputeActive && !trxn.isExpanded,
          multiple: isMultipleDispute,
          selected: trxn.isSelected || trxn.isDetailsExpanded
        }"
        [trxn]="trxn"
        [isMultiple]="isMultipleDispute"
        [isChild]="false"
        [isLoan]="account.isLoan"
        [isDisputeActive]="isTransactionDisputeActive"
        [isAccountActive]="account.isOpen"
        (onRowSelected)="selectTransaction($event.trxn)"
        (onOptionSelected)="performAction($event, trxn)"
        (onCollapseEvent)="collapse(trxn)"
        (onGetCheck)="getCheck($event)"
      ></app-tx-row>
      <!-- </tr> -->

      <!-- Details content (ODL details/children transactions) -->
      <ng-container *ngIf="trxn.show">
        <app-tx-row
          class="child expanded-content"
          *ngFor="let trxnDetail of trxn.childDetails"
          [trxn]="trxn"
          [isMultiple]="false"
          [isChild]="true"
          [isLoan]="account.isLoan"
          [isDisputeActive]="isTransactionDisputeActive"
        ></app-tx-row>
      </ng-container>

      <!-- Transaction Details-->
      <tr
        *ngIf="
          isPfm3InternalTransActive &&
          trxn.isDetailsExpanded &&
          !isAggregatedAccount &&
          !trxn.isRecategorizeExpanded
        "
        class="expanded-content details-row"
      >
        <td colspan="7">
          <app-transaction-details
            [transaction]="trxn"
          ></app-transaction-details>
        </td>
      </tr>
      <!--recategorize a transaction-->
      <tr
        *ngIf="!isAggregatedAccount && trxn.isRecategorizeExpanded"
        class="expanded-content recategorize-focus"
      >
        <td colspan="7">
          <app-recategorize
            [transaction]="trxn"
            (afterSave)="onRecategoriziationSuccess(trxn)"
            (afterCancel)="collapse(trxn)"
            (onError)="errorMesage()"
          ></app-recategorize>
        </td>
      </tr>

      <!-- Disputable Content -->
      <tr *ngIf="trxn.isExpanded" class="expanded-content">
        <td [attr.colspan]="isMultipleDispute ? 8 : 7">
          <div
            *ngIf="trxn.isDisputable"
            class="center frow"
            [id]="'tx-dispute-' + trxn.sequenceNumber"
          >
            <app-transaction-dispute-form
              class="fcol-cs-12 fcol-md-9"
              [transactions]="selected"
              [accountId]="account.id"
              [accountDetail]="accountDetails"
              [isMultiTransaction]="false"
              (cancel)="collapse(trxn)"
            ></app-transaction-dispute-form>
          </div>

          <div
            *ngIf="!trxn.isDisputable && trxn.isPending"
            class="text-error no-disputable"
          >
            <i class="bofi-warning" aria-hidden="true"></i>
            <p>
              You cannot dispute pending transactions until they have been
              processed.
            </p>
          </div>

          <div
            *ngIf="!trxn.isDisputable && !trxn.isPending"
            class="text-error no-disputable"
          >
            <i class="bofi-warning" aria-hidden="true"></i>
            <p>This transaction is not disputable.</p>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<app-tx-footer-multiple
  *ngIf="isMultipleDispute"
  [selected]="selected.length"
  (onFileDispute)="fileDispute()"
  (onClearSelected)="onClearSelected()"
></app-tx-footer-multiple>
