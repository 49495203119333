import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LogLevel } from '@core/enums';
import { WebLog } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private http: HttpClient) {}

  /**
   * Logs an application error.
   * @param error Error object.
   */
  logWebError(error: Error): void {
    const message = `Message: ${error.message} at ${window.location.pathname}`;
    const webError = new WebLog(LogLevel.Error, message);
    webError.stack = error.stack;

    this.http.post<null>('/log', webError).subscribe();
  }

  /**
   * Logs information about an
   * @param message Error message.
   * @param jsonData Additional information for the log.
   */
  logWebInformation(message: string, jsonData: any = null): void {
    message = `Message: ${message}, Data: {@jsonData}`;
    const logObject = new WebLog(LogLevel.Information, message, jsonData);

    this.http.post<null>('/log', logObject).subscribe();
  }
}
