<div class="dialog-header" [style.backgroundImage]="backgroundUrl">
  <app-svg-icon *ngIf="data.hasIcon" [iconName]="data.icon" [styles]="svgStyle"></app-svg-icon>

  <h1 *ngIf="data.headerTitle" class="header-title mt-2" [innerText]="data.headerTitle"></h1>

  <button type="button" class="dialog-header__close" [mat-dialog-close]="null" *ngIf="data.hasCloseButton">
    <app-svg-icon [iconName]="closeIcon" size="1rem"></app-svg-icon>
  </button>
</div>

<h3 mat-dialog-title [innerText]="data.title"></h3>
<div *ngIf="content" mat-dialog-content [innerHTML]="content" class="mat-dialog-content"></div>

<div *ngIf="data.component" mat-dialog-content>
  <ng-template [cdkPortalOutlet]="data.component"></ng-template>
</div>

<div mat-dialog-actions>
  <button
    class="btn-ok"
    type="button"
    [mat-dialog-close]="true"
    [innerText]="data.okText"
    *ngIf="data.hasOkButton"
    cdkFocusInitial
  ></button>
  <button
    class="btn-cancel"
    type="button"
    [mat-dialog-close]="false"
    [innerText]="data.cancelText"
    *ngIf="data.cancelText"
  ></button>
</div>
