import { Inject } from '../../../../decorators/decorators';
import { Recipient } from 'bill-pay/typings/Recipient';
import { PaymentHistory } from 'bill-pay/typings/PaymentHistory';
import { BillPayRouterHelper } from 'bill-pay/bill-pay-router.helper';
import { IStateService } from 'angular-ui-router';
import { ScheduledPayment } from 'bill-pay/typings/ScheduledPayment';

@Inject('billPayRouterHelper', '$state', 'serviceHelper')
export class PaymentsTabController {
  recipient: Recipient;
  paymentsHistory: PaymentHistory[];
  scheduledPayments: ScheduledPayment[];
  isEmpty: boolean;
  loadScheduledPayments: any;

  constructor(
    private readonly _billPayRouterHelper: BillPayRouterHelper,
    private readonly _stateService: IStateService
  ) {}

  $onInit(): void {
    this.isEmpty = this.scheduledPayments.length == 0 && this.paymentsHistory.length == 0;
  }

  goToHistory(): void {
    this._billPayRouterHelper.setHistoryFilter(this.recipient.displayName);
    this._stateService.go('udb.billPay.activity');
  }
}
