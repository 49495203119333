import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { getMultipleBrandProperties } from '@app/store/selectors';
import { BrandProperty } from '@core/enums';
import { OlbSettings } from '@core/models';
import { olbSettings, STATE } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-learn-more-transfers-modal',
  templateUrl: './learn-more-transfers-modal.component.html',
  styleUrls: ['./learn-more-transfers-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnMoreTransfersModalComponent implements OnInit, OnDestroy {
  backgroundUrl: string;
  closeIcon = NavigationIcons.Ex;
  properties = {
    ContactPhoneNumber: BrandProperty.ContactPhoneNumber,
    TransfersInternalLimitAmount: BrandProperty.TransfersInternalLimitAmount,
    TransfersIncomingExternalLimitAmount: BrandProperty.TransfersIncomingExternalLimitAmount,
    TransfersOutgoingExternalLimitAmount: BrandProperty.TransfersOutgoingExternalLimitAmount,
    TransfersIncomingExternalLimitAmountNewCustomer: BrandProperty.TransfersIncomingExternalLimitAmountNewCustomer,
  };
  propertyValues: Record<string, string> = {};

  private subsink = new SubSink();

  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    @Inject(STATE) private $state: ng.ui.IStateService,
    private dialogRef: MatDialogRef<LearnMoreTransfersModalComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {
    const { brand } = this.settings;

    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;

    this.subsink.sink = this.store.select(getMultipleBrandProperties(Object.values(this.properties))).subscribe(res => {
      this.propertyValues = res;
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  redirectToWireTransfers(): void {
    this.dialogRef.close();
    this.$state.go('udb.transfers.wireTransfers');
  }
}
