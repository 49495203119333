import { TotalValueConfiguration } from '../models';

export const TOTAL_VALUE_CONFIGURATION: TotalValueConfiguration[] = [
  {
    filter: '3M',
    dataLabelFormat: 'MM/DD',
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
    unit: 'week',
    unitStepSize: 1,
  },
  {
    filter: '6M',
    dataLabelFormat: 'MMM yyyy',
    timeLabelFormat: 'MMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
    unit: 'week',
    unitStepSize: 5.5,
  },
  {
    filter: '1Y',
    dataLabelFormat: 'MMM yyyy',
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
    unit: 'week',
    unitStepSize: 2,
  },
  {
    filter: '3Y',
    dataLabelFormat: 'MMM YYYY',
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
    unit: 'week',
    unitStepSize: 26,
  },
  {
    filter: '5Y',
    dataLabelFormat: 'YYYY',
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
    unit: 'week',
    unitStepSize: 52,
  },
  {
    filter: 'All',
    dataLabelFormat: 'YYYY',
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
    unit: 'week',
    unitStepSize: 1,
  },
];
