import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { EnrollmentViewComponent } from '@app/enrollment/views';

import { Inject } from '../decorators/decorators';

@Inject('$stateProvider')
export class EnrollmentConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider.state('auth.enrollment', {
      url: '/Enrollment/:version/:id?freg&redirect',
      views: {
        content: {
          template: '<app-enrollment-view></app-enrollment-view>',
        },
      },
    });
  }
}

export const enrollmentLegacyModule = angular
  .module('auth.enrollment', [])
  .directive(
    'appEnrollmentView',
    downgradeComponent({
      component: EnrollmentViewComponent,
    })
  )
  .config(EnrollmentConfig).name;
