<div class="minor-information">
  <div class="minor-information_title">
    <h3>Minor Information</h3>
  </div>
  <div class="frow">
    <div class="group--form fcol-md-6 fcol-cs-12" [class.has-error]="firstNameControl.invalid && isSubmitted">
      <label for="minorFirstName" class="uk-label">First Name</label>
      <input
        id="minorFirstName"
        name="minorFirstName"
        class="uk-input"
        type="text"
        [formControl]="firstNameControl"
        required
      />
      <div *ngIf="firstNameControl.touched || (firstNameControl.invalid && isSubmitted)">
        <span class="text-error" *ngIf="firstNameControl.errors?.required">
          First Name is required
        </span>
      </div>
    </div>
    <div class="group--form fcol-md-6 fcol-cs-12" [class.has-error]="lastNameControl.invalid && isSubmitted">
      <label for="minorLastName" class="uk-label">Last Name</label>
      <input
        id="minorLastName"
        name="minorLastName"
        class="uk-input"
        type="text"
        [formControl]="lastNameControl"
        required
      />
      <div *ngIf="lastNameControl.touched || (lastNameControl.invalid && isSubmitted)">
        <span class="text-error" *ngIf="lastNameControl.errors?.required">
          Last Name is required
        </span>
      </div>
    </div>
  </div>

  <div class="frow">
    <div
      class="group--form fcol-md-6 fcol-cs-12"
      [class.has-error]="socialSecurityNumberControl.invalid && isSubmitted"
    >
      <app-ssn-entry (validSsn)="getSsn($event)"></app-ssn-entry>
    </div>
    <div
      class="group--form fcol-md-6 fcol-cs-12"
      [class.has-error]="(dateOfBirthControl?.invalid || !isDobValid) && isSubmitted"
    >
      <label for="minorDob" class="uk-label">Date of Birth</label>
      <input
        id="minorDob"
        name="minorDob"
        class="uk-input"
        placeholder="MM/DD/YYYY"
        type="text"
        mask="00/00/0000"
        required
        (change)="isDateOfBirthValid()"
        [formControl]="dateOfBirthControl"
      />
      <div *ngIf="dateOfBirthControl?.touched || ((dateOfBirthControl?.invalid || !isDobValid) && isSubmitted)">
        <span class="text-error" *ngIf="dateOfBirthControl?.errors?.required">
          Date of Birth is required
        </span>
        <span class="text-error" *ngIf="!dateOfBirthControl?.errors?.required && !isDobValid">
          Date of Birth is invalid
        </span>
      </div>
    </div>
  </div>

  <div class="frow">
    <div class="group--form fcol-md-6 fcol-md-6" [class.has-error]="relationShipControl?.invalid && isSubmitted">
      <label for="minorRelationship" class="uk-label">Relationship</label>
      <select
        id="minorRelationship"
        name="minorRelationship"
        class="uk-input"
        required
        [formControl]="relationShipControl"
        (change)="onRelationshipChange(relationShipControl?.value)"
        test-id="selectTestRelationShip"
      >
        <option *ngIf="!minor?.relationship" value="" disabled selected>Select</option>
        <option *ngFor="let item of relationshipType" value="{{ item.value }}">
          {{ item.value }}
        </option>
      </select>
      <div *ngIf="relationShipControl?.invalid && isSubmitted">
        <span class="text-error" *ngIf="relationShipControl?.errors?.required">
          Relationship is required
        </span>
      </div>
      <div
        class="group--form other-relationship"
        [class.has-error]="otherRelationShipControl?.invalid && isSubmitted"
        *ngIf="relationShipControl?.value == 'Other'"
      >
        <label for="minorOtherRelationship" class="uk-label">Other Relationship</label>
        <input
          id="minorOtherRelationship"
          name="minorOtherRelationship"
          class="uk-input"
          type="text"
          required
          maxlength="15"
          [formControl]="otherRelationShipControl"
          (change)="onRelationshipChange(otherRelationShipControl?.value)"
        />
        <div *ngIf="otherRelationShipControl?.invalid && isSubmitted">
          <span class="text-error" *ngIf="otherRelationShipControl?.errors?.required">
            Other Relationship Name is required
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
