import { Inject } from '../../../decorators/decorators';
import { PayBillsHelper } from '../../pay-bills.helper';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SendMoneyBusinessModalComponent } from '@app/business/modals/send-money-business-modal/send-money-business-modal.component';
import { IPayService } from '@core/services/ipay.service';
import { IPaySSOResult } from '@legacy/typings/app/IPaySSOResult';
import { ServiceHelper } from '@legacy/services/service.helper';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { IRootScopeService, IScope } from 'angular';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { RedirectToIpayModalComponent } from '@app/business/modals/redirect-to-ipay-modal/redirect-to-ipay-modal.component';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { Recipient } from '@app/bill-pay/typings';
import { IStateService } from 'angular-ui-router';
import { solePropHelpers } from '@legacy/shared/helpers'

@Inject(
  'payBillsHelper',
  'matDialog',
  'iPayService',
  'serviceHelper',
  'cookieHelper',
  'cachedAccountsService',
  '$rootScope',
  '$scope',
  'billPayService',
  '$state'
)
export class WelcomeController {
  hasBusinessProfileType: boolean = false;
  hasIndividualAccounts: boolean = false;
  hasBusinessAccounts: boolean = false;
  hasIndividualProfileType: boolean = false;
  userProfilesInfo: AccountProfile[] = [];
  businessId: number;
  showBusinessOnlyCTA: boolean = false;
  showAddRecipientCTA: boolean = false;
  showFormBusinessLink: boolean = false;
  balancesAvailable: boolean = false;
  private listeners: Function[] = [];
  public stateLoaded: boolean = false;
  recipients: Recipient[];
  soleBusinessData: solePropHelpers.SolePropData;

  constructor(
    private readonly payBillsHelper: PayBillsHelper,
    private matDialog: MatDialog,
    private iPayService: IPayService,
    private serviceHelper: ServiceHelper,
    private cookieHelper: CookieHelper,
    private cachedAccountsService: CachedAccountsService,
    private rootScope: IRootScopeService,
    private readonly scope: IScope,
    private readonly billPayService: IBillPayService,
    private readonly state: IStateService
  ) {}

  $onInit(): void {
    // Once the promises have been resolved, the billPayData method will be called and set the values
    //  of hasBusinessAccounts and hasIndividualAccounts based on the cachedAccountService.
    Promise.all([this.onPaymentAccountsLoaded(), this.loadRecipients()]).then(
      this.setBillPayData.bind(this)
    );

    if (!this.payBillsHelper.isSBBActive()) {
      return;
    }

    this.userProfilesInfo = JSON.parse(
      sessionStorage.getItem(solePropHelpers.USER_BUSINESSES_KEY)
    ) as AccountProfile[];
  }

  private setBillPayData() {
    //Using cachedAccountsService is a more reliable way to know if a user has personal and business accounts.
    this.hasIndividualAccounts = this.cachedAccountsService.paymentAccounts?.some(
      acc => !acc.isSBB
    );
    this.hasBusinessAccounts = this.cachedAccountsService.paymentAccounts?.some(
      acc => acc.isSBB
    );

    //According to business requirements, users with only individuals accounts should experience the actual workflow.
    if (
      this.recipients.length > 0 &&
      this.hasIndividualAccounts &&
      !this.hasBusinessAccounts
    ) {
      this.state.go('udb.billPay.pay');
    }

    // Business Only - Previously added recipients should be displayed when the FF is ON.
    if (
      this.recipients.length > 0 &&
      this.hasBusinessAccounts &&
      !this.hasIndividualAccounts
    ) {
      this.state.go('udb.billPay.pay');
    }

    // set flags in the view regarding CTA bill pay.
    this.showFormBusinessLink = this.setShowFormBusinessLink();
    this.balancesAvailable = true;
    this.stateLoaded = true;
  }

  payBillsFromBusinessAccounts(): void {
    const businessProfiles = this.userProfilesInfo?.filter(
      acc => acc.profileType === AccountProfileType.Business
    );

    this.soleBusinessData = solePropHelpers.computeSolePropData(
      this.cookieHelper.getUserCIF(),
      this.cookieHelper.getUserId(),
      this.cachedAccountsService.paymentAccounts ?? [],
      this.userProfilesInfo
    );
    this.businessId = this.soleBusinessData.businessId;

    let entryLength = businessProfiles?.length ?? 0;
    if (this.soleBusinessData.isSoleProp) {
      entryLength++;
    }

    if (entryLength > 1) {
      this.showBusinessModal();
      return;
    }

    this.showConfirmationModal();
  }

  showConfirmationModal(): void {
    this.matDialog.open(RedirectToIpayModalComponent, {
      data: { id: this.businessId, isAccountId: false, isBillPay: true },
    });
  }

  showBusinessModal(): void {
    this.matDialog.open(SendMoneyBusinessModalComponent, {
      data: { isBillPay: true, soleBusinessData: this.soleBusinessData },
    });
  }

  redirectToIPay(): void {
    if (
      !this.payBillsHelper.isIPaySsoActive() ||
      !this.payBillsHelper.isSBBActive()
    ) {
      return;
    }

    this.iPayService.getSsoUrlByBusinessId(this.businessId).subscribe({
      next: (resp: OlbResponse<IPaySSOResult>) => {
        // Open iPay in a new tab/window
        window.open(resp.data.ssoUrl, '_blank');
      },
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
    });
  }

  setShowFormBusinessLink(): boolean {
    return (
      this.hasBusinessAccounts &&
      this.payBillsHelper.isIPaySsoActive() &&
      this.payBillsHelper.isSBBActive()
    );
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  // This function returns a promise that will be resolved once we have balancesAvailable.
  private onPaymentAccountsLoaded() {
    return new Promise<void>(resolve => {
      if (this.rootScope['balancesAvailable']) {
        resolve();
      } else {
        this.listeners.push(
          this.scope.$on('balancesAvailable', () => {
            resolve();
          })
        );
      }
    });
  }

  private loadRecipients(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.billPayService
        .getRecipients()
        .then(response => {
          this.recipients = response.data;
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
