export enum MiscIcons {
  Bullhorn = 'misc-bullhorn',
  Scale = 'misc-scale',
  Sun = 'misc-sun',
  Leaf = 'misc-leaf',
  SocialDistance = 'misc-social-distance',
  ToiletPaper = 'misc-toilet-paper',
  WashHands = 'misc-wash-hands',
  HandSanitizer = 'misc-hand-sanitizer',
  Coronavirus = 'misc-coronavirus',
  N95Mask = 'misc-n95-mask',
  Gavel = 'misc-gavel',
  LightBulb = 'misc-light-bulb',
  Satellite = 'misc-satellite',
  Ticket = 'misc-ticket',
  CoffeeCup = 'misc-coffee-cup',
  PuzzlePiece = 'misc-puzzle-piece',
  Car = 'misc-car',
  FoodAndDrink = 'misc-food-and-drink',
  Present = 'misc-present',
}
