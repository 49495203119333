import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterestedPartyComponent } from './view/interested-party.component';
import { SharedModule } from '@shared/shared.module';
import { TrustedInterestedModalModule } from '../trusted-interested-modal';
import { InterestedPartyUk2Module } from './interested-party-uk2.module';
import { InterestedPartyMatModule } from './interested-party-mat.module';

@NgModule({
  declarations: [InterestedPartyComponent],
  exports: [InterestedPartyComponent],
  imports: [
    CommonModule,
    SharedModule,
    TrustedInterestedModalModule,
    InterestedPartyUk2Module,
    InterestedPartyMatModule,
  ],
})
export class InterestedPartyModule {}
