<div class="message-detail compose-message">
  <app-view-header [titleText]="'Message Center'">
    <p>{{ this.subheader }}</p>
  </app-view-header>
  <app-dot-loader *ngIf="this.loading">Loading case details</app-dot-loader>
  <hr class="hidden-lg hidden-md" />
  <div *ngIf="!this.loading" class="view-container">
    <a class="link hidden-xs hidden-sm" (click)="goMessages()">
      <i class="fa fa-chevron-left color-chevron"></i>
      Back
    </a>
    <div class="container-success" *ngIf="this.alert">
      <div class="alert">
        <i class="bofi-check-mark" aria-hidden="true"></i>
        <span [innerHTML]="this.alert"></span>
        <i (click)="this.close()" class="bofi-close close-icon" aria-hidden="true"></i>
      </div>
    </div>
    <div class="detail-box">
      <div class="frow">
        <div class="fcol-sm-9">
          <h2 class="overflow">{{ title }}</h2>
          <br />
          <p class="subtitle hidden-xs hidden-sm">{{ createDate }}</p>
        </div>
        <div class="fcol-sm-3">
          <div class="type fcol-md-12">
            Case #:
            <p class="type-p">{{ case }}</p>
          </div>
          <div class="type fcol-md-12">
            Case status:
            <p class="type-p">{{ status }}</p>
          </div>
        </div>
      </div>
      <hr class="att-hr" />
      <div class="attachments" *ngIf="!downloading">
        <div class="attt-box" *ngFor="let dep of attachments | orderBy: 'createdDate'">
          <img [src]="'/img/fileicons/' + this.getSvgIcon(dep.fileExtension)" alt="" />
          <a
            (click)="downloadAttachments(dep.fileName, dep.fileId)"
            [innerHTML]="this.setName(dep.fileName, dep.fileSize)"
          ></a>
        </div>
      </div>
      <div class="attachments"><app-dot-loader *ngIf="downloading">Downloading</app-dot-loader></div>
    </div>
    <mat-expansion-panel
      #matPanel
      hideToggle
      *ngFor="let msg of messages; let index = index; last as lastExpansion"
      [expanded]="lastExpansion"
      (afterExpand)="matPanelOpened(index, lastExpansion)"
      (afterCollapse)="matPanelClosed(index, lastExpansion)"
      id="matExpansionPanel{{ index }}"
      [ngClass]="{ 'back-color': matPanel.expanded === false && !lastExpansion }"
    >
      <mat-expansion-panel-header class="box-pad frow m-header">
        <mat-panel-title>
          <div class="box-pad frow m-header">
            <div class="fcol-sm-5">
              <div class="inline">
                <div *ngIf="msg.messageOwnerType === 1">
                  <app-profile-image></app-profile-image>
                </div>
                <div *ngIf="msg.messageOwnerType === 0" style="width: 75px;">
                  <img [src]="setImage(isCollapsed)" class="image-icon" />
                </div>
                <div class="message-detail" style="width: 400px;">
                  <p class="m-username">{{ msg.messageOwner }}</p>
                  <p class="m-date">{{ msg.messageDate | date: 'MMM d, y @ h:mm:ss a' }}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div class="fcol-sm-7">
            <div
              [hidden]="!(matPanel.expanded === false && !lastExpansion)"
              class="pad-hide truncate"
              id="matDesc{{ index }}"
              [innerHTML]="
                !msg.messageSubject ? (msg.messageBody.startsWith('<table') ? '' : msg.messageBody) : msg.messageSubject
              "
            ></div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="box-pad frow">
        <p class="m-description" [innerHTML]="msg.messageBody"></p>
      </div>
      <div class="hr-line" *ngIf="!matPanel.open"></div>
    </mat-expansion-panel>
    <div class="reopen-message" *ngIf="(status === 'Closed' || reopen_answer === 'no') && !this.isClosing">
      <div class="fcol-md-12 center-align" [ngSwitch]="reopen_answer">
        <div *ngSwitchDefault>
          <h3>Did this answer your question?</h3>
          <br />
          <button type="button" class="uk-btn solid secondary lg space" (click)="reopen_answer = 'yes'">
            Yes, Thanks!
          </button>
          <button type="button" class="uk-btn outline primary lg" (click)="reopen_answer = 'no'">
            Not Really
          </button>
        </div>
        <div *ngSwitchCase="'yes'">
          <app-face
            face="happy"
            message="Thank you for contacting us, this case has been closed."
            title="Awesome!"
          ></app-face>
        </div>
        <div *ngSwitchCase="'no'">
          <app-face face="sad" message="Let us know below what you need help with." title="Ohh No!"></app-face>
        </div>
      </div>
    </div>
    <div class="frow center">
      <app-dot-loader *ngIf="isClosing"></app-dot-loader>
    </div>
    <div *ngIf="status !== 'Closed' || reopen_answer === 'no'">
      <form>
        <div *ngIf="dropboxVisible">
          <app-file-upload [files]="documents"></app-file-upload>
        </div>
        <div class="frow">
          <div class="fcol-cs-12 file-upload">
            <i class="bofi-attach icon-input--inner" (click)="toggleDropbox()" alt="Add an attachment"></i>
            <textarea
              name="messageText"
              placeholder="Reply"
              alt="Reply to thread"
              maxlength="1000"
              class="uk-input"
              [(ngModel)]="messageText"
              maxlength="1000"
              [required]="true"
              (focus)="checkFormStatus()"
              (ngModelChange)="getLength()"
              (blur)="this.getLength()"
              [ngClass]="{ red: !validMessage }"
              rows="13"
            ></textarea>
            <span class="text--error" *ngIf="!validMessage">
              You can't send an empty message.
            </span>
            <p [ngClass]="{ invalid: counter >= 1000 }" class="text-right counter">{{ this.counter }}/1000</p>
          </div>
        </div>
        <div class="frow">
          <div class="fcol-cs-3 button-upload">
            <button type="button" class="uk-btn solid secondary lg center" (click)="submit()" [disabled]="isSending">
              Send
            </button>
          </div>
          <div class="frow center">
            <app-dot-loader *ngIf="isSending"></app-dot-loader>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
