export class LiveChatSettings {
  isActive: boolean;
  isUserAuthenticated: boolean;
  eswScriptUrl: string;
  salesforceUrl: string;
  liveAgentUrl: string;
  orgId: string;
  site: string;
  siteUrl: string;
  devName: string;
  domain: string;
  deploymentId: string;
  buttonId: string;
  routingButtons: { [key: string]: string };
}
