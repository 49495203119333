<section class="investor-checking-enrollment">
  <app-simple-mat-stepper>
    <cdk-step>
      <app-information-step></app-information-step>
    </cdk-step>
    <cdk-step>
      <app-features-step
        (selectFeatures)="updateSelectedFeatures($event)"
      ></app-features-step>
    </cdk-step>
    <cdk-step>
      <app-disclosures-step
        (acceptDisclosures)="submitApplication()"
      ></app-disclosures-step>
    </cdk-step>
  </app-simple-mat-stepper>
</section>
