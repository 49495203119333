import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BrandPropertyService } from '@legacy/services/brand-property.service';
import { ROOT_SCOPE, olbSettings } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'app-funding-shared-header',
  templateUrl: './funding-header.component.html',
  styleUrls: ['./funding-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundingHeaderComponent implements OnInit {
  showPhone = false;
  phoneImgSrc: any;
  brand: string;
  imgBrand: string;
  phoneNumber: string;

  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    private readonly serviceHelper: ServiceHelper,
    @Inject(ROOT_SCOPE) private root: ng.IRootScopeService,
    private readonly brandPropertyService: BrandPropertyService
  ) {}

  ngOnInit() {
    try {
      this.phoneImgSrc = '/FrontEnd/src/assets/axos/img/icons/contact_phone.svg';
      this.brand = this.settings.brand;
      this.imgBrand = `./img/logos/${this.brand}-w-logo.svg`;
      if (!this.root['brandProperties']) {
        this.loadBrandProperties(this.settings.brandId, this.brand);
        return;
      }
      this.phoneNumber = this.root['brandProperties']['Contact_PhoneNumber'];
    } catch {}
  }

  private loadBrandProperties(brandId: number, brand: string) {
    this.brandPropertyService.getBrandProperties(brandId, brand).subscribe(() => {
      this.phoneNumber = this.root['brandProperties']['Contact_PhoneNumber'];
    }, this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }
}
