import { Component, EventEmitter, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TransactionsState } from '@app/Areas/AAS/features/transactions/core/store/enums';

import { TransactionEvent } from '@app/axos-invest/enums';
import { Transaction } from '@app/axos-invest/models';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() transactions: Transaction[] = [];
  @Input() hideCategory: boolean = false;
  @Input() hideOptions: boolean = true;

  unselectEvent = new EventEmitter<string>();

  lastSortedColumn = 'date';

  get isAscendent() {
    return this.counter % 2 === 0;
  }
  counter = 0;

  ngOnInit(): void {
    this.sortByDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.transactions) {
      this.counter = this.counter - 1;
      if (this.lastSortedColumn === 'date') {
        this.sortByDate();
      } else {
        this.sortBy(this.lastSortedColumn);
      }
    }
  }

  sortByDate() {
    const column = 'date';
    this.counter = this.lastSortedColumn === column ? this.counter + 1 : 0;
    this.lastSortedColumn = column;
    const order = this.isAscendent ? 'asc' : 'desc';
    if (!this.transactions || this.transactions.length <= 1) return;
    const createdTransactions = this.transactions.filter(t => t.event === TransactionEvent.Created);
    const otherTransactions = this.transactions
      .filter(t => t.event !== TransactionEvent.Created)
      .sort(this.compareValues([column], order));
    this.transactions = [...createdTransactions, ...otherTransactions];
    this.transactions = this.sortByOrder(this.transactions, order);
  }

  sortBy(...columns: string[]) {
    const column = columns.join('-');
    this.counter = this.lastSortedColumn === column ? this.counter + 1 : 0;
    this.lastSortedColumn = column;
    const order = this.isAscendent ? 'asc' : 'desc';
    if (!this.transactions || this.transactions.length <= 1) return;
    this.transactions = this.transactions.sort(this.compareValues(columns, order));
  }

  onSelectRow(transactionUuid: string) {
    this.unselectEvent.emit(transactionUuid);
  }

  expandRow(transaction: any) {
    const transactionToExpandIndex = this.transactions.findIndex(t => t.transactionId === transaction.transactionId);
    this.transactions[transactionToExpandIndex].isExpanded = !this.transactions[transactionToExpandIndex].isExpanded;
  }

  private compareValues(keys: string[], order = 'asc') {
    return (a: any, b: any) => {
      const key = keys[0];
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      } else if (keys.length > 1) {
        return this.compareValues(keys.slice(1, keys.length), order)(a, b);
      }

      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  private sortByOrder(transactions: any, order: string): Transaction[] {
    transactions.sort((A: Transaction, B: Transaction) => {
      if (A.state == TransactionsState.Pending) return -1;
      if (B.state == TransactionsState.Pending) return 1;
      if (!A.date) return 1;
      if (!B.date) return -1;
      const dateA = typeof A.date === 'string' ? new Date(A.date) : A.date;
      const dateB = typeof B.date === 'string' ? new Date(A.date) : B.date;
      return order === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    });
    return transactions;
  }
}
