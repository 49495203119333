import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { SubSink } from '@axos/subsink';

import { getBrandPropertyValue } from '@app/utils';
import { OlbEvents } from '@core/enums';
import { OlbSettings } from '@core/models';
import { OlbEventService, ScriptLoaderService } from '@core/services';
import { olbSettings } from '@core/tokens';
import { BrandPropertyService } from '@legacy/services/brand-property.service';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
})
export class ChatbotComponent implements OnInit, OnDestroy {
  @ViewChild('abe', { static: true }) abe: ElementRef<HTMLDivElement>;
  userId: number;
  controlName = 'embeddedWebSdk';
  controlReadyEventName = 'embeddedWebSdkReady';
  brand: string;
  openAttempts = 0;

  get isChatOpen(): boolean {
    return Boolean(this.abe.nativeElement.querySelector('.abe-ew-dialogue.open'));
  }

  private subsink = new SubSink();

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    private olbEventService: OlbEventService,
    private scriptLoaderService: ScriptLoaderService,
    private brandPropertyService: BrandPropertyService,
    private cookieHelper: CookieHelper
  ) {}

  ngOnInit() {
    this.brand = this.settings.brand;
    this.addListeners();
    this.getChatbotProperties().then(chatbotProps => {
      this.addAbeTags(chatbotProps.data);
    });
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  private getChatbotProperties(): ApiResponse<BrandProperty[]> {
    return this.brandPropertyService.getChatbotProperties(this.settings.brandId);
  }

  private addListeners() {
    this.subsink.sink = this.olbEventService.on(OlbEvents.EndChatbotSession, this.endSession.bind(this));
    this.subsink.sink = this.olbEventService.on(OlbEvents.OpenEvoChat, () => this.openChatWindow());
    window.addEventListener(this.controlReadyEventName, this.onEmbeddedWebSdkReady.bind(this));
  }

  private endSession() {
    if (window[this.controlName]) {
      window[this.controlName].unauthenticateUser();
    }
  }

  private onEmbeddedWebSdkReady() {
    this.userId = this.cookieHelper.getUserId();
    const token = this.cookieHelper.getToken();

    if (this.userId) {
      window[this.controlName].authenticateUser(this.userId, { token });
    } else {
      window[this.controlName].unauthenticateUser();
    }
  }

  private addAbeTags(brandProperties: BrandProperty[]) {
    this.abe.nativeElement.insertAdjacentHTML('beforeend', getBrandPropertyValue(brandProperties, 'Component'));
    this.scriptLoaderService.load(getBrandPropertyValue(brandProperties, 'WidgetScript'));
  }

  private openChatWindow(): void {
    console.log(this.openAttempts);
    if (!this.isChatOpen) {
      const element = this.abe.nativeElement.querySelector<HTMLDivElement>('.abe-ew-interaction-icon');
      if (element) {
        this.openAttempts = 0;
        element.click();
      } else if (this.openAttempts < 5) {
        this.openAttempts++;
        setTimeout(this.openChatWindow.bind(this), 1000);
      }
    }
  }
}
