import { IRootScopeService, ISCEService, ITimeoutService, IWindowService } from 'angular';
import { IStateService } from 'angular-ui-router';
import * as moment from 'moment';
import { IBillPayService } from 'services/typings/IBillPayService';
import { UserAccountType } from 'typings/app/bills/UserAccountType';

import { Frequency, mapFrequency } from '../bill-pay/typings/Frequency';
import { Inject } from '../decorators/decorators';
import { BillPayRouterHelper } from './bill-pay-router.helper';
import { PaymentFrequency } from './typings/PaymentFrequency';
import { PaymentMethod } from './typings/PaymentMethod';
import { RecurrentPaymentInfo } from './typings/RecurrentPaymentInfo';
import { ScheduledPayment } from './typings/ScheduledPayment';

@Inject('$window', '$sce', 'billPayService', 'billPayRouterHelper', '$state', '$timeout', '$rootScope', 'serviceHelper')
export class BillPayHelper {
  constructor(
    private readonly windowService: IWindowService,
    private readonly sceService: ISCEService,
    private readonly _billPayService: IBillPayService,
    private readonly _billPayRouterHelper: BillPayRouterHelper,
    private readonly _stateService: IStateService,
    private readonly _timeoutService: ITimeoutService,
    private readonly _root: IRootScopeService,
    private readonly serviceHelper: IServiceHelper
  ) {}

  getHeaderTooltip(header: string): string {
    switch (header) {
      case 'processingDate':
        return 'This is when we will send your payment. Electronic recipients are paid instantly. We mail checks 5 days before your payment’s due date.';
      case 'deliveryDate':
        return 'This is our estimate of when your recipient will receive your payment. Your recipient may receive the payment before this date.';
      case 'paymentMethod':
        return 'Your Recipient Type can be electronic or check. Electronic recipients are paid instantly. Check recipients are paid with paper checks. We mail checks 5 days before your payment’s due date.';
      default:
        return '';
    }
  }

  mapFrequency(frequency: number): string {
    switch (frequency) {
      case PaymentFrequency.OneTime:
        return 'One time';
      case PaymentFrequency.Weekly:
        return 'Weekly';
      case PaymentFrequency.EveryTwoWeeks:
        return 'Every 2 Weeks';
      case PaymentFrequency.EveryFourWeeks:
        return 'Every 4 weeks';
      case PaymentFrequency.Monthly:
        return 'Monthly';
      case PaymentFrequency.SemiMonthly:
        return 'Twice a month (Semi Monthly - 1st and the 15th)';
      case PaymentFrequency.EveryTwoMonths:
        return 'Every two months';
      case PaymentFrequency.Quarterly:
        return 'Quarterly';
      case PaymentFrequency.EveryFourMonths:
        return 'Every 4 months';
      case PaymentFrequency.BiAnnual:
        return 'Every 6 months';
      case PaymentFrequency.Annually:
        return 'Yearly';
      default:
        return '';
    }
  }

  mapPaymentMethod(paymentMethod: number): string {
    switch (paymentMethod) {
      case PaymentMethod.Check:
        return 'Check';
      case PaymentMethod.Electronic:
        return 'Electronic';
      default:
        return '';
    }
  }

  /**
   * Review if there's another action needed in order to complete the set up
   * @param loginResponse Response from the previous call
   */
  checkForFurtherActions(loginResponse: EBillResponseBase) {
    return new Promise<void>((resolve, reject) => {
      // call getEbillLoginStatus
      this._billPayService
        .getEBillStatus(loginResponse.manageEBillsSessionId)
        .then(response => {
          this._billPayRouterHelper.setSessionId(response.data.manageEBillsSessionId);
          // if the response contains credentials, show them in the next step view
          if (response.data.credentials != null && response.data.credentials.length > 0) {
            if (
              response.data.furtherActionRequired &&
              this._stateService.current.name === 'udb.billPay.eStatements' &&
              response.data.credentials.length === 2 &&
              response.data.credentials[0].label === 'Login ID' &&
              response.data.credentials[1].label === 'Password'
            ) {
              const err = new Error('Login Failed: Invalid Username or Password');
              reject(err);
              throw err;
            }

            this._billPayRouterHelper.setCredentials(response.data.credentials);
            this._billPayRouterHelper.setCaptcha(response.data.captcha);
            this._billPayRouterHelper.setOtp(response.data.oneTimePasscode);
            this._root.$broadcast('credentialsUpdated');

            this._stateService.go('udb.billPay.eStatements.authentication');
            resolve();
          } else if (response.data.eBillsAccounts != null && response.data.eBillsAccounts.length > 0) {
            // if the response doesn't have credentials, check for accounts and move to the next step
            this._billPayRouterHelper.setAccounts(response.data.eBillsAccounts);
            this._stateService.go('udb.billPay.eStatements.accounts');
            resolve();
          } else if (response.data.furtherActionRequired) {
            const timeout =
              new Date(response.data.callBackTime).getTime() - new Date(response.data.timestamp).getTime();
            this._timeoutService(() => {
              this.checkForFurtherActions(response.data).catch(err => {
                reject(err);
              });
            }, timeout);
          } else {
            // if there are no further actions, then the setup is complete
            // If we are following the add recipient flow, go to the recipient confirmation page
            const partialRecipient = this._billPayRouterHelper.getRecipient();
            if (partialRecipient) {
              partialRecipient.eStatementsStatus = true;
              this._stateService.go('udb.billPay.electronicConfirmation');
            } // otherwise, go to the current confirmation page
            else this._stateService.go('udb.billPay.eStatements.confirmation');
            resolve();
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getEBillStatement(eBillId: number) {
    this._billPayService
      .getEBillStatement(eBillId)
      .then((res: any) => {
        const file = new Blob([res], { type: 'application/pdf' });

        if (this.windowService.navigator.msSaveOrOpenBlob) {
          this.windowService.navigator.msSaveOrOpenBlob(file, 'statement.pdf');
        } else {
          const fileURL = URL.createObjectURL(file);
          this.sceService.trustAsResourceUrl(fileURL);
          this.windowService.open(fileURL, '_blank');
        }
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper));
  }

  getStates(): any {
    return {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FM: 'Federated States Of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
    };
  }
  /** Synces the payment which is being set up */
  syncPayment(payment: ScheduledPayment): RecurrentPaymentInfo {
    const recurrent: RecurrentPaymentInfo = {};
    recurrent.payeeId = payment.payeeId;
    recurrent.accountCode = payment.accountCode;
    recurrent.amount = payment.amount;
    recurrent.daysBeforeDeliveryAlert = payment.alert ? payment.alert.daysBeforeDelivery : 0;
    recurrent.daysBeforeDeliverySpecified = payment.alert ? payment.alert.daysBeforeDeliverySpecified : false;
    recurrent.daysBeforeProcessingAlert = payment.alert ? payment.alert.daysBeforeProcessing : 0;
    recurrent.daysBeforeProcessingSpecified = payment.alert ? payment.alert.daysBeforeProcessingSpecified : false;
    recurrent.alertOnCompletion = payment.alert ? payment.alert.onCompletion : false;
    recurrent.isSent = payment.alert
      ? payment.alert.daysBeforeProcessingSpecified && payment.alert.daysBeforeProcessing == 0
      : false;
    recurrent.repeatUntilCanceled = payment.items.length === 1 ? payment.items[0] : false;
    recurrent.processingDate = moment(payment.processingDate).toDate();
    recurrent.deliveryDate = moment(payment.deliveryDate).toDate();
    recurrent.paymentFrequency = payment.paymentFrequency;
    recurrent.memo = payment.memo;
    recurrent.numberOfPayments = !recurrent.repeatUntilCanceled ? payment.items[1] : 1; //
    recurrent.endOn = recurrent.repeatUntilCanceled ? 0 : 1; //
    recurrent.endOnNumberOfPayments = !recurrent.repeatUntilCanceled; //
    recurrent.notifyMeNumberOfPayments = payment.alert
      ? payment.alert.daysBeforeProcessingSpecified && payment.alert.daysBeforeProcessing > 0
      : false; //
    recurrent.isRecurring = true; // always is recurring in this modal
    recurrent.scheduledPaymentId = payment.scheduledPaymentId;

    return recurrent;
  }

  getFrequencies(): GenericOption[] {
    return [
      {
        value: Frequency.OneTime,
        label: mapFrequency(Frequency.OneTime),
      },
      {
        value: Frequency.Weekly,
        label: mapFrequency(Frequency.Weekly),
      },
      {
        value: Frequency.EveryTwoWeeks,
        label: mapFrequency(Frequency.EveryTwoWeeks),
      },
      {
        value: Frequency.EveryFourWeeks,
        label: mapFrequency(Frequency.EveryFourWeeks),
      },
      {
        value: Frequency.Monthly,
        label: mapFrequency(Frequency.Monthly),
      },
      {
        value: Frequency.EveryTwoMonths,
        label: mapFrequency(Frequency.EveryTwoMonths),
      },
      {
        value: Frequency.Quarterly,
        label: mapFrequency(Frequency.Quarterly),
      },
      {
        value: Frequency.EveryFourMonths,
        label: mapFrequency(Frequency.EveryFourMonths),
      },
      {
        value: Frequency.BiAnnual,
        label: mapFrequency(Frequency.BiAnnual),
      },
      {
        value: Frequency.Annually,
        label: mapFrequency(Frequency.Annually),
      },
    ];
  }

  /**
   * Gets the given account's nickname or the account's formatted string if nickname's not available
   * @param account account details to format the account's label
   */
  getAccountLabel(account: UserAccountType): string {
    return account.nickName || `${account.accountType} ${this.getAccountMask(account)}`;
  }

  /**
   * Gets the account selected based on its account code.
   * @param accountsFrom Array with all the accounts
   * @param defaultAccountCode Account code to search
   */
  getSelectedAccount(accountsFrom: UserAccountType[], defaultAccountCode: string): UserAccountType {
    let result: UserAccountType = null;

    const accountsFromFilter = accountsFrom.filter((accountFrom: UserAccountType) => {
      return accountFrom.accountCode === defaultAccountCode;
    });

    if (accountsFromFilter && accountsFromFilter.length > 0) {
      result = accountsFromFilter.shift();
    }

    return result;
  }

  getWeekDay(date: moment.Moment): string {
    const weekday = new Array(7);
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';

    return weekday[date.day()];
  }
  /**
   * Get the last 4 numbers of an account number
   * @param account account to take the account number
   */
  private getAccountMask(account: UserAccountType): string {
    return `**${account.accountNumber.substring(account.accountNumber.length - 4)}`;
  }
}
