import { AccountAggDetailsIndexController } from './account-details-index.controller';

export const AccountAggDetailsIndexComponent: ng.IComponentOptions = {
  controller: AccountAggDetailsIndexController,
  controllerAs: 'vm',
  templateUrl: 'accounts/external-accounts/account-details-Index/account-details-index.tpl.html',
  bindings: {
    onAccountOperations: '&',
  },
};
