import { TimePeriod } from '@app/pfm/enums/time-period.enum';

export class SpendingFilters {
  timePeriod = TimePeriod.ThisMonth;
  excludedAccounts = new Set<string>();
  excludedCategories = new Set<number>();

  constructor(args?: Partial<SpendingFilters>) {
    Object.assign(this, args);
  }
}
