<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Dashboard How To's</h3>

  <ul class="how-to-list">
    <li>
      <button id="move-tile" class="link" (click)="selectTopic(topics.MoveTile)">
        How do I move a tile?
      </button>
    </li>
    <li>
      <button id="add-delete-tile" class="link" (click)="selectTopic(topics.AddDeleteTile)">
        How do I add or delete a tile?
      </button>
    </li>
    <li>
      <button id="change-content-tile" class="link" (click)="selectTopic(topics.ChangeContentTile)">
        How do I change the contents of a tile?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-move-tile *ngSwitchCase="topics.MoveTile"></how-to-move-tile>
    <how-to-add-delete-tile *ngSwitchCase="topics.AddDeleteTile"></how-to-add-delete-tile>
    <how-to-change-content-tile
      *ngSwitchCase="topics.ChangeContentTile"
    ></how-to-change-content-tile>
  </ng-container>
</ng-template>
