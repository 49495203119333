import { AggregatedAccount, OlbAccount } from '@app/accounts/models';

export function mapDisplayNameOfExternalAccounts(olbAccount: OlbAccount, aggregatedAccount: AggregatedAccount) {
  if (aggregatedAccount) {
    if (aggregatedAccount.nickname) return aggregatedAccount.nickname;
    else if (aggregatedAccount.name) return `${aggregatedAccount.name} *${olbAccount.accountMask}`;
    else return `${aggregatedAccount.bankName} *${olbAccount.accountMask}`;
  } else {
    return olbAccount.nickname ?? `${olbAccount.bankName} *${olbAccount.accountMask}`;
  }
}
