import { FastlinkFlow } from "../enums/fast-link-flow.enum";

export type Bank = {
  id?: number;
  name?: string;
  imageSrc?: string;
  secondaryImageSrc?: string;
  description?: string;
  onSelected?: Function;
  fastlinkFlow?: FastlinkFlow;
};

export const Banks: Bank[] = [
  { name: 'Chase', imageSrc: 'chase_logo.png', secondaryImageSrc: 'chase_round_logo.svg' },
  {
    name: 'Capital One',
    imageSrc: 'capital-one_logo.png',
    secondaryImageSrc: 'capital-one_round_logo.svg',
  },
  { name: 'Citibank', imageSrc: 'citi_logo.png', secondaryImageSrc: 'citi_round_logo.svg' },
  {
    name: 'Bank of America',
    imageSrc: 'bank-of-america_logo.png',
    secondaryImageSrc: 'bank-of-america_round_logo.svg',
  },
  {
    name: 'Wells Fargo',
    imageSrc: 'wells-fargo_logo.png',
    secondaryImageSrc: 'wells-fargo_round_logo.svg',
  },
  { name: 'US bank', imageSrc: 'us-bank_logo.png', secondaryImageSrc: 'us-bank_round_logo.svg' },
  { name: 'Fidelity', imageSrc: 'fidelity_logo.png', secondaryImageSrc: 'fidelity_round_logo.svg' },
  { name: 'PNC', imageSrc: 'pnc_logo.png', secondaryImageSrc: 'pnc_round_logo.svg' },
  {
    name: 'American Express',
    imageSrc: 'american-express_logo.png',
    secondaryImageSrc: 'american-express_round_logo.svg',
  },
  {
    name: 'Discover',
    imageSrc: 'discover_card_logo.png',
    secondaryImageSrc: 'discover_round_logo.svg',
  },
  { name: 'USAA', imageSrc: 'usaa_logo.png', secondaryImageSrc: 'usaa_round_logo.svg' },
  {
    name: 'Charles Schwab',
    imageSrc: 'charles-schwab_logo.png',
    secondaryImageSrc: 'charles-schwab_round_logo.svg',
  },
  {
    name: 'E*TRADE',
    imageSrc: 'e-trade_logo.png',
    secondaryImageSrc: 'e-trade_round_logo.svg',
  },
  { name: 'Ally', imageSrc: 'ally_logo.png', secondaryImageSrc: 'ally_round_logo.svg' },
  {
    name: 'Navy Federal Credit Union',
    imageSrc: 'navy-federal_logo.png',
    secondaryImageSrc: 'navy-federal-credit-union_round_logo.svg',
  },
  { name: 'TD Bank', imageSrc: 'td-bank_logo.png', secondaryImageSrc: 'td_round_logo.svg' },
  { name: 'Chime', imageSrc: 'chime_logo.png', secondaryImageSrc: 'chime_round_logo.svg' },
  { name: 'SoFi', imageSrc: 'sofi_logo.png', secondaryImageSrc: 'td_round_logo.svg' },
];

export const fundingFlowBanks: Bank[] = [
  { name: 'Chase', imageSrc: 'chase_logo.png', secondaryImageSrc: 'chase_round_logo.svg' },
  {
    name: 'Capital One',
    imageSrc: 'capital-one_logo.png',
    secondaryImageSrc: 'capital-one_round_logo.svg',
  },
  { name: 'Citibank', imageSrc: 'citi_logo.png', secondaryImageSrc: 'citi_round_logo.svg' },
  {
    name: 'Bank of America',
    imageSrc: 'bank-of-america_logo.png',
    secondaryImageSrc: 'bank-of-america_round_logo.svg',
  },
  {
    name: 'Wells Fargo',
    imageSrc: 'wells-fargo_logo.png',
    secondaryImageSrc: 'wells-fargo_round_logo.svg',
  },
  { name: 'US bank', imageSrc: 'us-bank_logo.png', secondaryImageSrc: 'us-bank_round_logo.svg' },
  { name: 'USAA', imageSrc: 'usaa_logo.png', secondaryImageSrc: 'usaa_round_logo.svg' },
  {
    name: 'Marcus by Goldman Sachs',
    imageSrc: 'marcus-by-goldman-sachs_logo.png',
    secondaryImageSrc: 'goldman-sachs_round_logo.svg',
  },
  { name: 'PNC', imageSrc: 'pnc_logo.png', secondaryImageSrc: 'pnc_round_logo.svg' },
  { name: 'TD Bank', imageSrc: 'td-bank_logo.png', secondaryImageSrc: 'td_round_logo.svg' },
  { name: 'Ally', imageSrc: 'ally_logo.png', secondaryImageSrc: 'ally_round_logo.svg' },
  {
    name: 'SunTrust',
    imageSrc: 'sun-trust_logo.png',
    secondaryImageSrc: 'sun-trust_round_logo.svg',
  },
  { name: 'Chime', imageSrc: 'chime_logo.png', secondaryImageSrc: 'chime_round_logo.svg' },
  { name: 'Fidelity', imageSrc: 'fidelity_logo.png', secondaryImageSrc: 'fidelity_round_logo.svg' },
  {
    name: 'Fifth-Third',
    imageSrc: 'fifth-third-bank_logo.png',
    secondaryImageSrc: 'fifth-third-bank_round_logo.svg',
  },
  {
    name: 'Charles Schwab',
    imageSrc: 'charles-schwab_logo.png',
    secondaryImageSrc: 'charles-schwab_round_logo.svg',
  },
  {
    name: 'Simple',
    imageSrc: 'simple_logo.png',
    secondaryImageSrc: 'simple_round_logo.svg',
  },
  {
    name: 'Navy Federal Credit Union',
    imageSrc: 'navy-federal_logo.png',
    secondaryImageSrc: 'navy-federal-credit-union_round_logo.svg',
  },
];
