import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertsUI } from '@app/alerts-notifications/my-alerts/typings/AlertsUI';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() alert: AlertsUI = {} as AlertsUI;
  @Output() alertSelected = new EventEmitter<AlertsUI>();
  addAlert(alert: AlertsUI) {
    this.alertSelected.emit(alert);
  }
}
