import { P2PRecipient } from './P2PRecipient';

export class P2PTransferHistory {
  /** Recipient where the transfer is made  */
  recipient: P2PRecipient;
  /** number of account */
  accountCode: string;
  /** Amount to make a payment */
  amount: number;
  /** method of delivery for the P2P payment */
  method: P2PMethod;
  /** optional message to describe transaction */
  message: string;
  /** date to create transfer */
  creationDate: Date;
  /** date of trasnfer progressing */
  procesingDate: Date;
  /** number assigned to transactions */
  confirmationNumber: number;
  /** Id of trasnfer */
  transferId: number;
  /** status of P2P transfer */
  status: P2PTransferStatus;
}

export enum P2PMethod {
  /**Email method*/
  Email,
  /**Phone method*/
  MobilePhone,
}

export enum P2PTransferStatus {
  /**Pending status*/
  Pending,
  /**Canceled status*/
  Canceled,
  /**Expired status*/
  Expired,
  /**Failed authentication status*/
  FailedAuthentication,
  /**In process status*/
  InProcess,
  /**Failed status*/
  Failed,
  /**Sent status*/
  Sent,
  /**Retuned status*/
  Returned,
}
