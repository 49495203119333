import { format } from 'date-fns';

import { DocumentDetail, GroupedDocuments, GroupedDocumentsByMonth } from '@app/accounts/models';

export function groupDocuments(document: DocumentDetail[]): GroupedDocuments[] {
  const groupedStatements = document.reduce((r, c) => {
    const year = new Date(c.doc_date as string).getFullYear();
    const currentMonth = new Date(c.doc_date as string).getMonth();
    r[year] = r[year] || {};
    r[year][currentMonth] = r[year][currentMonth] || [];
    r[year][currentMonth].push(c);

    return r;
  }, {});

  const groupedDisplayStatements = Object.keys(groupedStatements).map(year => {
    const docMonth = groupedStatements[year];
    const monthGroups = Object.keys(docMonth).map(month => {
      const documents = docMonth[month];

      return new GroupedDocumentsByMonth({
        month: format(new Date(documents[0].doc_date as string), 'MMMM'),
        documents,
      });
    });

    return new GroupedDocuments({
      year,
      documentsByMonth: monthGroups,
    });
  }, this);

  if (
    groupedDisplayStatements[0] &&
    groupedDisplayStatements[0].documentsByMonth &&
    groupedDisplayStatements[0].documentsByMonth.length > 0
  ) {
    groupedDisplayStatements.forEach((_value, index) => {
      groupedDisplayStatements[index].documentsByMonth.reverse();

      groupedDisplayStatements[index].documentsByMonth.forEach(docMonth => {
        docMonth.documents = docMonth.documents.sort(
          (a, b) => Date.parse(b.doc_date as string) - Date.parse(a.doc_date as string)
        );
      });
    });
  }

  return groupedDisplayStatements.reverse();
}
