import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { DebitCard, ReplacementRequest } from '@app/accounts/submodules/debit-card/models';
import { DebitCardService } from '@app/accounts/submodules/debit-card/services';
import { AddressType } from '@app/user-profile/enums';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { areAddressesEqual, createAddressForm } from '@app/user-profile/utils';
import { extractServerError } from '@app/utils';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { Address } from '@shared/models';

import { DebitCardStatus, ReplacementReason } from '../../enums';

@Component({
  selector: 'app-request-replacement-view',
  templateUrl: './request-replacement-view.component.html',
  styleUrls: ['./request-replacement-view.component.scss'],
})
export class RequestReplacementViewComponent implements OnInit, OnDestroy {
  card: DebitCard;
  cardMask: string;
  replacementForm: UntypedFormGroup;
  loading = false;
  loadingData = true;
  canSubmit = true;
  primaryAddress: Address;
  possibleReasons = [
    ReplacementReason.Bent,
    ReplacementReason.Broken,
    ReplacementReason.UnableToRead,
    ReplacementReason.Other,
  ];
  errors = {
    reason: [{ name: 'required', message: 'Please select a reason.' }],
    comments: [{ name: 'maxlength', message: 'Comments cannot exceed 120 characters.' }],
  };
  apiErrorMessage: string;
  charactersCount = 0;

  private subsink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private $stateParams: ng.ui.IStateParamsService,
    @Inject(STATE) private $state: ng.ui.IStateService,
    private store: Store,
    private fb: UntypedFormBuilder,
    private debitCardService: DebitCardService,
    private cachedAccountsService: CachedAccountsService
  ) {}

  ngOnInit(): void {
    const { card, id } = this.$stateParams;

    if (!card) {
      this.$state.go('udb.accounts.details', { id, tab: 1 });
    } else {
      this.card = card;
      this.cardMask = this.card.cardNumberMask.slice(this.card.cardNumberMask.length - 5);
      this.subsink.sink = this.store
        .select(getProfileDetails)
        .pipe(filter(data => data !== null))
        .subscribe(profile => {
          this.primaryAddress = profile.primaryAddress.address;
          this.replacementForm = this.fb.group({
            deliveryAddress: createAddressForm(this.primaryAddress, AddressType.None),
            reason: this.fb.control(ReplacementReason.Bent, [Validators.required]),
            comments: this.fb.control('', [Validators.maxLength(120)]),
            dueFee: this.fb.control(false),
          });

          this.subsink.sink = this.replacementForm.get('comments').valueChanges.subscribe((val: string) => {
            this.charactersCount = val.length;
          });

          this.loadingData = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  changeAddress(address: Address): void {
    this.replacementForm.get('deliveryAddress').patchValue(address);
  }

  setDueFee(value: boolean): void {
    this.replacementForm.patchValue({ dueFee: value });
  }

  submitInformation(): void {
    this.loading = true;
    const formValue = this.replacementForm.value as ReplacementRequest;
    const request = new ReplacementRequest({
      ...formValue,
      cardMask: this.card.cardNumberMask,
      isDefaultAddress: areAddressesEqual(this.primaryAddress, formValue.deliveryAddress),
    });
    let confirmationMsg =
      'We have completed your request to replace your card. Your new card will be mailed to the address you provided in ';
    confirmationMsg += `${formValue.dueFee ? '3' : '7-10'} business days.`;

    this.debitCardService
      .requestReplacement(this.card.accountId, request)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: res => {
          if (!res.addressChangedRecently) {
            res.status = DebitCardStatus.Reorder;
            res.isActive = false;
          } else {
            confirmationMsg =
              'Your request has been submitted. A representative will contact you within one business day.';
          }
          this.cachedAccountsService.updateLinkedDebitCards(res);

          this.$state.go('udb.accounts.debit-cards.confirmation', {
            message: confirmationMsg,
            accountId: this.card.accountId,
            card: res,
            header: 'Success',
          });
        },
        error: res => {
          this.apiErrorMessage = extractServerError(res);
        },
      });
  }

  returnToDebitCards(): void {
    this.$state.go('udb.accounts.details', { id: this.card.accountId, tab: 4, debitCardId: this.card.debitCardId });
  }
}
