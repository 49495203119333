<div class="payment">
  <h3 class="payment-title">{{ info.title }}</h3>
  <h2 *ngIf="info.dueDate" class="payment-title">
    Due Date
    <span class="payment-date" [class.text-error]="info.isPastDue">
      {{ info.dueDate | date: 'MM/dd/yyyy' }}
    </span>
  </h2>
  <p *ngFor="let item of info.items" class="payment-item" [class.text-bold]="item.bold">
    {{ item.name }}&nbsp;
    <span class="text-bold" [class.bank-name]="item.name.includes('Account')">
      {{ item.value }}
    </span>
  </p>
</div>

<div class="payment-another" *ngIf="info.status === 1 || info.status === 3">
  <a id="make-another-payment" href="#" (click)="goToLoanPayment()" class="make-another-payment">
    Make another payment
  </a>
  <app-svg-icon
    class="payment-icon"
    [iconName]="iconProps.icon"
    [iconColor]="iconProps.color"
    [height]="iconProps.iconSize"
    [width]="iconProps.iconSize"
  ></app-svg-icon>
</div>

<div *ngIf="canMakePayment" class="text-center mt-4">
  <button type="button" class="btn-ok" (click)="makePayment.emit()">Make Monthly Payment</button>
</div>
