import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvgIconHelperService {
  private internalCache = new Map<string, SafeHtml>();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  loadSvgIcon(iconBaseRoute: string, iconName: string): Observable<SafeHtml> {
    const iconKey = `${iconBaseRoute}/${iconName}`;
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');

    if (this.internalCache.has(iconKey)) {
      const cached = this.internalCache.get(iconKey);
      return of(cached);
    }

    return this.http.get(`/img/icons/${iconKey}.svg`, { headers, responseType: 'text' }).pipe(
      map(svg => {
        const sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(svg);
        this.internalCache.set(iconKey, sanitizedContent);
        return sanitizedContent;
      })
    );
  }
}
