<section class="enrollment-view" [ngSwitch]="state.currentStep">
  <app-progress-bar [steps]="state.steps" [currentStep]="state.currentStep"></app-progress-bar>

  <app-user-data-form
    *ngSwitchCase="1"
    (next)="userDataForm($event)"
    (goTo)="routeRedirect.emit($event)"
  ></app-user-data-form>

  <app-terms-and-conditions
    *ngSwitchCase="2"
    [isLoading]="isLoading"
    [alertText]="termsAndConditions.alertText"
    (agree)="agreeTermsAndConditions()"
  ></app-terms-and-conditions>

  <app-multifactor
    *ngSwitchCase="2.5"
    [isLoading]="isLoading"
    (sendCode)="multifactorSendCode($event)"
  ></app-multifactor>

  <app-access-code
    *ngSwitchCase="3"
    (back)="accessCodeBack()"
    (continue)="accessCodeContinue($event)"
    [isLoading]="isLoading"
    [otp]="accessCode.otp"
    [displayRememberDevice]="false"
    [errorMessage]="errorMessage"
  ></app-access-code>

  <app-security-questions
    *ngSwitchCase="4"
    [username]="user.username"
    (save)="securityQuestionsSave()"
  ></app-security-questions>
</section>
