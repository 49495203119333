<div class="col-12 col-sm-8 col-md-6 col-xl-12 m-t-lg">
  <div class="row">
    <div class="form-group row col-lg-3 col-md-12 mr-1">
      <label class="uk-label" for="choose-year">Choose Year</label>
      <select
        [(ngModel)]="selectedYear.value"
        (ngModelChange)="filter()"
        id="choose-year"
        name="choose-year"
        class="uk-input"
      >
        <option *ngFor="let year of years" [ngValue]="year.value">
          {{ year.label }}
        </option>
      </select>
    </div>
    <div class="form-group row col-lg-3 col-md-12">
      <label class="uk-label" for="document-type">Document Type</label>
      <select
        [(ngModel)]="selectedDocType.value"
        (ngModelChange)="filter()"
        id="document-type"
        name="document-type"
        class="uk-input"
      >
        <option *ngFor="let document of documentTypeFilter" [ngValue]="document.value">
          {{ document.label }}
        </option>
      </select>
    </div>
  </div>
</div>
<div class="document__line"></div>
<div class="col-12 col-sm-8 col-md-6 col-xl-12 m-t-lg">
  <div class="row">
    <div class="form-group col-lg-9 col-md-12 col-sm-12 statements">
      <h4>Documents</h4>
      <div *ngFor="let main of groupedStatements">
        <div *ngFor="let sub of main.documentsByMonth">
          <h4 class="month-year-title">{{ sub.month }} {{ main.year }}</h4>
          <div class="uk-list">
            <ul class="list">
              <li class="list-item" *ngFor="let statement of sub.documents">
                <app-svg-icon
                  [iconName]="pdfIcon"
                  iconColor="var(--primary)"
                  width="1.4rem"
                  height="1.4rem"
                  class="list-item__icon"
                ></app-svg-icon>
                <span (click)="downloadDocument(statement)" class="document-display-name">
                  {{ statement.subTypeName }} - {{ statement.doc_date | date: 'MM/dd/yyyy' }}
                </span>
                <a (click)="downloadDocument(statement)">
                  View
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="groupedStatements.length === 0" class="no-statements">
        <span>No Statements found</span>
      </div>
      <div class="previous-documents" (click)="redirectToPreviousDocuments()">
        <a href="#" class="previous-docs-button">View Previous Documents</a>
      </div>
    </div>
    <div class="form-group col-lg-3 col-md-12 col-sm-12">
      <h4>Tax Forms</h4>
      <div *ngFor="let main of groupedTaxForms">
        <h4 class="tax-forms__year">{{ main.year }}</h4>
        <div *ngFor="let sub of main.documentsByMonth">
          <a class="tax-forms__link" *ngFor="let document of sub.documents" (click)="downloadTaxForm(document)">
            {{ document.displayname }}
          </a>
        </div>
      </div>
      <div *ngIf="groupedTaxForms.length === 0">
        <span>You do not currently have any documents available</span>
      </div>
      <div class="previous-documents" (click)="redirectToPreviousTaxForms()">
        <a href="#" class="previous-tax-forms">View Previous Tax Forms</a>
      </div>
    </div>
  </div>
</div>
