import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  exports: [MatButtonModule, MatDividerModule, MatDialogModule, MatIconModule, MatCardModule],
})
export class MaintenanceInProgressMatModule {}
