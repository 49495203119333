import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getInternalDepositAccountsList } from '@app/accounts/store/selectors';
import { STATE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch',
  templateUrl: './click-switch.component.html',
  styleUrls: ['./click-switch.component.scss'],
})
export class ClickSwitchComponent implements OnInit, OnDestroy {
  backIcon = NavigationIcons.ArrowBack;
  isLoading = true;

  private subsink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private featureFlagService: FeatureFlagService,
    private store: Store
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.subsink.sink = this.store
      .select(getInternalDepositAccountsList)
      .pipe(filter(data => data.length > 0))
      .subscribe(accounts => {
        if (
          !this.featureFlagService.isDirectDepositEnabled() ||
          accounts.filter(acc => {
            return acc.canMakeDepositSwitch;
          }).length === 0
        ) {
          this.state.go('udb.dashboard');
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goBack(): void {
    const backState = this.state.params.backstate || 'udb.dashboard';

    if (this.state.params.id) {
      this.state.go(backState, { id: this.state.params.id });
    } else {
      this.state.go(backState, this.state.params.backStateParams);
    }
  }

  goSearchTarget(): void {
    this.state.go('udb.clickswitch.search-target');
  }
}
