export enum TimePeriod {
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  ThisYear = 'This Year',
  LastYear = 'Last Year',
  Last3Months = 'Last 3 Months',
  Last6Months = 'Last 6 Months',
  Last12Months = 'Last 12 Months',
  AllTime = 'All Time',
}
