import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import { addOrUpdateAasAccountAction, addTransactionSelectedTab, updateAasAccountNicknameAction } from '../pdp.actions';
import { initialPdpState, PdpState } from '../types';

const reducer = createReducer(
  initialPdpState,
  on(addOrUpdateAasAccountAction, (_state, action) => {
    const indexOfUpdated = _state.aasAccounts.findIndex(m => m.accountNumber === action.payload.accountNumber);
    if (indexOfUpdated >= 0) {
      return {
        aasAccounts: _state.aasAccounts.map(item => {
          return item.accountNumber === action.payload.accountNumber ? (item = action.payload) : item;
        }),
        selectedAasAccountNumber: action.payload.accountNumber,
        selectedTab: _state.selectedTab,
      };
    } else {
      return {
        aasAccounts: [..._state.aasAccounts, action.payload],
        selectedAasAccountNumber: action.payload.accountNumber,
        selectedTab: _state.selectedTab,
      };
    }
  }),
  on(addTransactionSelectedTab, (_state, action) => {
    return {
      aasAccounts: [..._state.aasAccounts],
      selectedAasAccountNumber: _state.selectedAasAccountNumber,
      selectedTab: action.payload,
    };
  }),
  on(signOut, () => {
    return initialPdpState;
  }),
  on(updateAasAccountNicknameAction, (_state,action) => {
    return {
      ..._state,
      aasAccounts: _state.aasAccounts.map(item => {
        return item.accountNumber === action.payload.accountNumber ? (item = {...item, accountNickname: action.payload.accountNickname}) : item;
      }),
    };
  })
);

export function pdpReducer(state: PdpState, action: Action) {
  return reducer(state, action);
}
