<div class="scheduled-transfers-tile">
  <div class="divider"></div>
  <app-dot-loader *ngIf="isLoading">Loading...</app-dot-loader>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="scheduledTransfersTile && scheduledTransfersTile.apiCallWasSuccessful; else errorState">
      <ng-container
        *ngIf="scheduledTransfersTile && scheduledTransfersTile.scheduledTransfers.length > 0; else emptyState"
      >
        <div class="scheduled-transfers-tile__list">
          <div
            class="scheduled-transfers-tile__item divider"
            (click)="navigateToScheduledTransfers()"
            *ngFor="let scheduledTransfer of scheduledTransfersTile.scheduledTransfers"
          >
            <div class="scheduled-transfers-tile__item--icon">
              <mat-icon [svgIcon]="uk2UtilityIcons.clockCircularArrow"></mat-icon>
            </div>
            <div class="scheduled-transfers-tile__item--content">
              <p class="description">{{ scheduledTransfer.description }}</p>
              <span class="date">{{ scheduledTransfer.nextDate | date: 'MMMM d, YYYY':'+0000' }}</span>
            </div>
            <div class="scheduled-transfers-tile__item--amount">
              <p>{{ scheduledTransfer.amount | currency }}</p>
            </div>
            <div class="scheduled-transfers-tile__item--chevron-arrow">
              <mat-icon [svgIcon]="uk2NavigationIcons.chevronRight"></mat-icon>
            </div>
          </div>
        </div>
        <div class="scheduled-transfers-tile__navigation-link">
          <button (click)="navigateToScheduledTransfers()">View All Scheduled</button>
        </div>
      </ng-container>
      <ng-template #emptyState>
        <div class="scheduled-transfers-tile__empty">
          <p>Once you have scheduled transfers related to this account, you'll see them here</p>
          <a (click)="navigateToScheduledTransfers()">Set Up New Transfer</a>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #errorState>
      <uk2-text-tile
        [uk2TextTileSvgIconName]="uk2UtilityIcons.cog"
        [uk2TextTileTitle]="labels.errorTitle"
        [uk2TextTileIsStandaloneTile]="false"
      >
        <p>
          {{ labels.errorContent }}
        </p>
      </uk2-text-tile>
    </ng-template>
  </ng-container>
</div>
