<div class="container">
  <div class="row justify-content-center mt-5">
    <h1>Portfolio Updated!</h1>
  </div>
  <div class="text-center mt-5 mb-3" *ngIf="!wasGlidepathEnabled">
    Your portfolio allocations have been changed.
  </div>
  <div class="col-12 mt-2 text-center">
    <div class="col-md-6 offset-md-3 basic-portfolio-edited-text" *ngIf="showAddEndAllocation">
      You can also add an end target allocation in order to change your portfolio allocation automatically over time as
      you get closer to your goal.
    </div>
    <div class="col-md-6 offset-md-3 end-target-deleted-text" *ngIf="wasGlidepathEnabled">
      Your end target allocation has been deleted. Your portfolio will remain at the current target allocation.
    </div>
    <div class="mt-5 mb-5">
      <img src="assets/img/icons/axos-invest/portfolio-updated.svg" alt="" aria-hidden="true" />
    </div>
  </div>
  <div class="justify-content-center form__actions">
    <button class="btn-ok" type="button" (click)="goBack.emit()">
      Back to Portfolio
    </button>
    <button class="btn-cancel" type="button" (click)="endingAllocation.emit()" *ngIf="showAddEndAllocation">
      Add End Target
    </button>
  </div>
</div>
