import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';

import { AccountsEffects } from '@app/pfm/store/accounts/accounts.effects';
import {
  commitFilters as commitFilterNetWorth,
  resetUncommittedFilters as resetUncommittedFiltersNetWorth,
} from '@app/pfm/store/net-worth/net-worth.actions';
import { commitFilters, resetUncommittedFilters } from '@app/pfm/store/spending/spending.actions';
import { STATE } from '@core/tokens';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
@Component({
  selector: 'app-insights-overview',
  templateUrl: './insights-overview.component.html',
  styleUrls: ['./insights-overview.component.scss'],
})
export class InsightsOverviewComponent implements OnInit, OnDestroy {
  subsink = new SubSink();
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    public accAggFormatter: AccountAggregationChartFormatterService,
    public accountsEffects: AccountsEffects,
    private store: Store
  ) {}

  ngOnInit(): void {
    // reset Spending committed and uncommitted filters
    this.store.dispatch(resetUncommittedFilters());
    this.store.dispatch(resetUncommittedFiltersNetWorth());
    this.store.dispatch(commitFilters());
    this.store.dispatch(commitFilterNetWorth());

    this.accountsEffects.loadAccounts(this.subsink);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goToSpending() {
    this.state.go('udb.accounts.spending');
  }

  goToNetWorth() {
    this.state.go('udb.accounts.net-worth');
  }
}
