import { Component } from '@angular/core';

import { GoalToGoalTransaction } from '@app/axos-invest/models';

import { TransactionDetailComponent } from '../..';
@Component({
  selector: 'app-transaction-information',
  templateUrl: './transaction-information.component.html',
  styleUrls: ['./transaction-information.component.scss'],
})
export class TransactionInformationComponent implements TransactionDetailComponent {

  transaction: GoalToGoalTransaction;

}
