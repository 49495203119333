import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DownloadOptionsConstants } from '../../download-options.constants';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-period-options',
  templateUrl: './period-options.component.html',
  styleUrls: ['./period-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PeriodOptionsComponent,
      multi: true,
    },
  ],
})
export class PeriodOptionsComponent implements ControlValueAccessor {
  @Input() selectedPeriod: string;
  @Input() isDisabled: boolean;
  @Output() updateOptionSelected = new EventEmitter<string>();
  months: string[];
  quarters: string[];
  years: number[];

  // Define constants for each period option to avoid hardcoding string literals
  monthPeriod = DownloadOptionsConstants.MONTH_PERIOD.value;
  quarterPeriod = DownloadOptionsConstants.QUARTER_PERIOD.value;
  yearPeriod = DownloadOptionsConstants.YEAR_PERIOD.value;

  constructor() {
    // Initialize arrays for months, quarters, and years
    this.months = this.generateMonthOptions();
    this.quarters = this.generateQuarterOptions();
    this.years = this.generateYearOptions();
  }

  writeValue(): void {}
  registerOnChange(): void {}
  registerOnTouched(): void {}
  setDisabledState?(): void {}

  onSelect(value: any): void {
    this.updateOptionSelected.emit(value);
  }

  // Generate options for the month dropdown
  private generateMonthOptions(): string[] {
    const options = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    for (let year = currentYear; year >= currentYear - 3; year--) {
      for (let month = year === currentYear ? currentMonth : 11; month >= 0; month--) {
        const monthName = Object.keys(DownloadOptionsConstants.MonthMap.MONTH_MAP).find(
          key => DownloadOptionsConstants.MonthMap.MONTH_MAP[key] === month
        );
        options.push(`${monthName} ${year}`);
      }
    }
    return options;
  }

  // Generate options for the quarter dropdown
  private generateQuarterOptions(): string[] {
    const options = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentQuarter = Math.floor(currentDate.getMonth() / 3);

    for (let year = currentYear; year >= currentYear - 3; year--) {
      for (let quarter = year === currentYear ? currentQuarter : 3; quarter >= 0; quarter--) {
        options.push(`Q${quarter + 1} ${year}`);
      }
    }
    return options;
  }

  // Generate options for the year dropdown
  private generateYearOptions(): number[] {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 3; i--) {
      years.push(i);
    }
    return years;
  }
}
