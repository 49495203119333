import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { STATE } from '@core/tokens';

import { AccountDetail } from '../../models';
import { Transaction } from '../../submodules/transactions/models/transaction.model';

@Component({
  selector: 'app-dispute-details',
  templateUrl: './dispute-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisputeDetailsComponent implements OnInit {
  transactions: Transaction[];
  accountId: number;
  accountDetail: AccountDetail;
  previousTrx: Transaction[];

  constructor(@Inject(STATE) private state: ng.ui.IStateService) {}

  ngOnInit(): void {
    if (!this.state.params.transactions || !this.state.params.accountId || !this.state.params.accountDetail) {
      this.state.go('udb.dashboard');

      return;
    }

    this.transactions = this.state.params.transactions;
    this.accountId = this.state.params.accountId;
    this.accountDetail = this.state.params.accountDetail;
    this.previousTrx = this.state.params.previousTrx;
  }

  redirectTo() {
    this.state.go('udb.accounts.dispute-review', {
      transactions: this.previousTrx,
      accountId: this.accountId,
      accountDetail: this.accountDetail,
    });
  }
}
