import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import {
  Uk2ButtonSizeEnum,
  Uk2Tier1AlertsEnum,
  Uk2ToastComponent,
  Uk2ToastTypeEnum,
  Uk2TooltipPlacementEnum,
  Uk2TooltipSizeEnum,
  Uk2TooltipStrategyEnum,
  Uk2TooltipTriggerEnum,
} from '@axos/uikit-v2-lib';
import { AuthorizeTransferAccount } from '../../core/types';
import { STATE } from '@core/tokens';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-authorize-transfer-page',
  templateUrl: './authorize-transfer-page.component.html',
  styleUrls: ['./authorize-transfer-page.component.scss'],
})
export class AuthorizeTransferPageComponent implements OnInit {
  tooltipProperties = {
    placement: Uk2TooltipPlacementEnum.right,
    labelText: 'Learn More',
    bodyText:
      'This feature is exclusively available for deposit accounts like checking and savings.',
    displayCloseButton: false,
    size: Uk2TooltipSizeEnum.medium,
    svgIconName: 'uk2-info-circle',
    alertsIcons: Uk2Tier1AlertsEnum,
    uk2IsLoading: false,
    arrowOffset: 0,
    desktopOpenMode: Uk2TooltipTriggerEnum.hover,
    strategy: Uk2TooltipStrategyEnum.absolute,
  };
  btnOptions = {
    size: Uk2ButtonSizeEnum.large,
    isLoading: false,
    isDisabled: false,
  };
  isAuthorizeEnable = false;

  accounts: AuthorizeTransferAccount[] = [
    {
      bankName: 'Axos Bank',
      accountName: 'Investor Checking - 8888',
      logo:
        'https://axossitecore-v10.uat.axosbank.com/-/media/White-Label/ufb-direct/icon/ufb-direct-icon_202306281630458920.png?thn=1//',
      authorizeTransfer: false,
      advisors: [
        {
          name: 'Lexman Advisors',
          logo:
            'https://axossitecore-v10.uat.axosbank.com/-/media/White-Label/Nationwide/icon/nationwide-icon_202306281633102895.png?thn=1/',
          authorizeTransfer: false,
        },
        {
          name: 'Novafi',
          logo:
            'https://axossitecore-v10.uat.axosbank.com/-/media/White-Label/bwm/secondaryLogo/Secondary-Logo_Lexman_202312281414569129.png?thn=1/',
          authorizeTransfer: false,
        },
      ],
    },
    {
      bankName: 'UFB',
      accountName: 'Direct ufb - 8888',
      logo:
        'https://axossitecore-v10.uat.axosbank.com/-/media/White-Label/ufb-direct/icon/ufb-direct-icon_202306281630458920.png?thn=1//',
      authorizeTransfer: false,
      advisors: [
        {
          name: 'Lexman Advisors',
          logo:
            'https://axossitecore-v10.uat.axosbank.com/-/media/White-Label/Nationwide/icon/nationwide-icon_202306281633102895.png?thn=1/',
          authorizeTransfer: false,
        },
        {
          name: 'Novafi',
          logo:
            'https://axossitecore-v10.uat.axosbank.com/-/media/White-Label/bwm/secondaryLogo/Secondary-Logo_Lexman_202312281414569129.png?thn=1/',
          authorizeTransfer: false,
        },
      ],
    },
  ];

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private breakpointObserver: BreakpointObserver,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .subscribe(result => {
        if (result.matches) {
          this.tooltipProperties = {
            ...this.tooltipProperties,
            placement: Uk2TooltipPlacementEnum.bottom,
            arrowOffset: -35,
            displayCloseButton: true,
          };
        } else {
          this.tooltipProperties = {
            ...this.tooltipProperties,
            placement: Uk2TooltipPlacementEnum.right,
            arrowOffset: 0,
            displayCloseButton: false,
          };
        }
      });
  }

  authorize() {
    // turn on advisors on accounts detail page
    sessionStorage.setItem('viewedAuthorizeTransferPage', 'true');
    this.successMessage();
    this.state.go('udb.dashboard');
  }

  skipForNow() {
    // turn off advisors on accounts detail page
    sessionStorage.setItem('viewedAuthorizeTransferPage', 'true');
    this.state.go('udb.dashboard');
  }

  onAuthorizationChange(account: any, accountChanges: any) {
    // set new account changes to the account
    this.accounts = this.accounts.map(acc =>
      acc === account ? accountChanges : acc
    );
    this.isAuthorizeEnable = this.accounts.some(acc => acc.authorizeTransfer);
  }

  private successMessage() {
    this._snackBar.openFromComponent(Uk2ToastComponent, {
      duration: 5000,
      data: {
        message: 'Account transfers successfully authorized with your advisor',
        type: Uk2ToastTypeEnum.success,
      },
    });
  }
}
