<div class="transactions-categorization">
    <button
            type="button"
            class="uk-btn sm category-btn"
            (click)="onClick()"
            [ngClass]="!!isFilterActive() ? 'category-btn--active' : 'category-btn--custom'"
            
    >
        <div
                class="category-btn--icon category-btn--icon--svg"
                [ngClass]="{ 'category-btn--icon--svg--active': !!isFilterActive() }"
        >
            <img alt="" [src]="'assets/img/icons/filters/categories-white.svg'" />
        </div>
        <span>
      Categories&nbsp;
      <span class="number" [ngClass]="{ 'number--show': !!isFilterActive() }">
        {{ !!isFilterActive() ? '(' + categoriesSelected + ')' : '' }}
      </span>
    </span>
    </button>

    <div *ngIf="popup">
        <div class="transactions-category">
            <app-dot-loader [showWhen]="loadingCategories">Loading</app-dot-loader>
            <div class="transactions-category__body">
                <app-category-element
                        *ngFor="let category of categories; trackBy: categoryTrackBy"
                        [category]="category"
                        (click)="checkCategoriesSelected()"
                ></app-category-element>
            </div>
            <div class="transactions-category__footer">
                <p class="transactions-category__footer--desc" (click)="clearCategories()">View all Transactions</p>
            </div>
        </div>
    </div>
</div>
