<div class="container">
  <div class="header-section-title text-center">
    <div>
      <span class="header__title">
        Request Submitted
      </span>
    </div>
  </div>
  <div class="row center">
    <p class="confirmation-title mt-4">
      Congratulations! Your request has been submitted for processing.
    </p>
    <div class="col-12 col-sm-8 text-center mt-1">
      <p class="confirmation-subtitle">
        It can take up to 2 pay periods before your direct deposit takes effect.
      </p>
    </div>
  </div>

  <div class="d-flex align-items-center information-container">
    <app-click-switch-details
      class="row justify-content-center col-12 col-md-10 col-lg-8"
      title="Source Information"
      [details]="sourceInformation"
      [canEdit]="false"
    ></app-click-switch-details>

    <hr class="mt-5 d-sm-none" />

    <app-click-switch-details
      class="row justify-content-center col-12 col-md-10 col-lg-8 mt-5"
      title="Deposit Information"
      [details]="depositInformation"
      [canEdit]="false"
    ></app-click-switch-details>

    <div class="address__form-btns row justify-content-center mt-5">
      <button type="submit" class="uk-btn lg solid secondary m-4" (click)="goToStatusPage()">
        View Request Status
      </button>
      <button type="button" class="link col-12" (click)="goToDashboard()">
        Go to Dashboard
      </button>
    </div>
  </div>
</div>
