<app-view-header>
  <h1>Review Selections</h1>
</app-view-header>

<div class="app-dispute-review view-container">
  <div class="col-12 col-sm-10 col-lg-8">
    <h3 class="text-center mb-5">
      Please review the transactions you selected before moving forward with your claim.
    </h3>

    <div *ngIf="disputableTransactions.length" id="disputable-transactions-section">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h4>Disputed Transaction(s)</h4>
        <a
          id="edit-disputable-transacions"
          class="app-dispute-review__edit-btn mr-2"
          href="#"
          (click)="goToAccountDetails()"
        >
          Edit
        </a>
      </div>

      <table class="table app-dispute-review__table">
        <thead>
          <tr>
            <th *ngFor="let header of disputableHeaders" class="{{ header.cssClass }}">
              {{ header.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let transaction of disputableTransactions">
            <td>{{ transaction.description }}</td>
            <td>{{ transaction.amount | currency }}</td>
          </tr>
          <tr *ngIf="disputableTransactions.length > 1">
            <td>Total</td>
            <td>{{ total | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="nonDisputableTransactions.length" id="non-disputable-transactions-section">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h4>Non-Disputable Transaction(s)</h4>
        <a
          id="edit-non-disputable-transacions"
          class="app-dispute-review__edit-btn mr-2"
          href="#"
          (click)="goToAccountDetails()"
        >
          Edit
        </a>
      </div>
      <table class="table app-dispute-review__table">
        <thead>
          <tr>
            <th *ngFor="let header of nonDisputableHeaders" class="{{ header.cssClass }}">
              {{ header.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let transaction of nonDisputableTransactions">
            <td>{{ transaction.description }}</td>
            <td>{{ transaction.amount | currency }}</td>
            <td>
              <span *ngIf="transaction.isPending">
                You cannot dispute pending transactions until they have been processed.
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form__actions justify-content-around">
      <a
        id="continue-link"
        href="#"
        *ngIf="disputableTransactions.length"
        class="btn-ok button--lg"
        (click)="goToAccountsDisputeDetails()"
      >
        Continue
      </a>
      <a id="back-link" class="btn-cancel button--lg" href="#" (click)="goToAccountDetails()">
        Back
      </a>
    </div>
  </div>
</div>
