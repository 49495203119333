import { Action, createReducer, on } from '@ngrx/store';

import { TradingAccount } from '@app/axos-trading/models';
import { signOut } from '@app/store/actions';

import { loadTradingAccounts } from './trading-accounts.actions';

export const tradingAccountsInitialState: Record<number, TradingAccount> = {};

const _reducer = createReducer(
  tradingAccountsInitialState,
  on(loadTradingAccounts, (state, action) => {
    const transferOnlyAccounts = action.payload.reduce((acc, item) => {
      return { ...acc, [item.id]: { ...state[item.id], ...item } };
    }, {}) as Record<number, TradingAccount>;

    return { ...state, ...transferOnlyAccounts };
  }),
  on(signOut, () => {
    return {};
  })
);

export function tradingAccountsReducer(state: Record<number, TradingAccount>, action: Action) {
  return _reducer(state, action);
}
