import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { appRoutesNames } from '@app/app.routes.names';
import { environmentNames } from '@app/config/constants';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

@Injectable({
  providedIn: 'root',
})
export class HideOnProdCanActivateGuard implements CanActivate {
  constructor(private router: Router, @Inject(olbSettings) private settings: OlbSettings) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.settings.environment === environmentNames.Production) {
      this.router.navigate([appRoutesNames.Base]);

      return false;
    }

    return true;
  }
}
