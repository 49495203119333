import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { holdingsReducer, HoldingsSummaryService, initialHoldingState } from './core';
import { HoldingFacade } from './facade';
import { HoldingsMatModule } from './holdings-mat.module';
import { HoldingsUk2Module } from './holdings-uk2.module';
import { HoldingsTileComponent } from './view/holdings-tile.component';
import { UnderstandingHoldingsModalComponent } from './view/components/understanding-holdings-modal/understanding-holdings-modal.component';

@NgModule({
  declarations: [HoldingsTileComponent, UnderstandingHoldingsModalComponent],
  providers: [HoldingsSummaryService, HoldingFacade],
  imports: [
    CommonModule,
    HttpClientModule,
    HoldingsMatModule,
    HoldingsUk2Module,
    StoreModule.forFeature(AasStoreFeatures.Holdings, holdingsReducer, {
      initialState: initialHoldingState,
    }),
  ],
  exports: [HoldingsTileComponent],
})
export class HoldingsModule {}
