<div class="container">
  <div class="header-section-title text-center">
    <div>
      <span class="header__title">Update {{ beneficiaryTypes[beneficiariesType] }} Beneficiaries</span>
      <p class="m-2 mt-4">{{ subtitle }}</p>
    </div>
  </div>
  <div class="shared-back">
    <a class="shared-back__link" (click)="back()">
      <app-svg-icon [iconName]="backIcon" [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"></app-svg-icon>
      <span class="shared-back__label">Back to Account Details</span>
    </a>
  </div>
  <div class="row center">
    <h3 class="text-bold m-4">Add or modify your {{ beneficiaryTypes[beneficiariesType] }} Beneficiaries</h3>
    <div class="col-12 col-sm-8 text-center font-italic">
      <p>
        You may not designate yourself or another account owner as a beneficiary. If no beneficiaries are named, the
        assets in your account will go to your estate.
      </p>
    </div>

    <form [formGroup]="beneficiariesDataForm" novalidate class="beneficiaries col-10" (ngSubmit)="save()">
      <div formArrayName="beneficiaries">
        <div *ngFor="let _ of beneficiaries()?.controls; index as i; trackBy: trackByBeneficiaryName">
          <div [formGroupName]="i">
            <div style="display: flex; justify-content: space-between; margin: 40px 0;">
              <h4>{{ beneficiaryTypes[beneficiariesType] }} Beneficiary #{{ i + 1 }}</h4>

              <a id="delete_btn_{{ i }}" class="delete-btn" (click)="deleteBeneficiary(i)">
                <app-svg-icon
                  [iconName]="deleteIcon"
                  [styles]="{ width: '1rem', height: '1rem', fill: '#176acf', strokeWidth: 1 }"
                ></app-svg-icon>
                <span>Remove</span>
              </a>
            </div>

            <div class="row">
              <div class="col-sm-5 form-group">
                <label>
                  <span class="uk-label">First Name</span>
                  <input
                    id="firstname_{{ i }}"
                    formControlName="firstname"
                    name="name"
                    class="uk-input"
                    type="text"
                    required
                  />
                </label>
                <div *ngIf="beneficiaryValidator(i, 'firstname') as error">
                  <span class="text-error">
                    {{ error }}
                  </span>
                </div>
              </div>
              <div class="col-sm-5 form-group">
                <label>
                  <span class="uk-label">Last Name</span>
                  <input
                    id="lastname_{{ i }}"
                    formControlName="lastname"
                    name="lastname"
                    class="uk-input"
                    type="text"
                    required
                  />
                </label>
                <div *ngIf="beneficiaryValidator(i, 'lastname') as error">
                  <span class="text-error">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-5 form-group">
                <label>
                  <span class="uk-label">Date of Birth</span>
                  <input
                    id="birthDate_{{ i }}"
                    formControlName="birthDate"
                    name="birthDate"
                    class="uk-input"
                    type="text"
                    placeholder="MM/DD/YYYY"
                    [dropSpecialCharacters]="false"
                    mask="00/00/0000"
                    required
                  />
                </label>
                <div *ngIf="beneficiaryValidator(i, 'birthDate') as error">
                  <span class="text-error">
                    {{ error }}
                  </span>
                </div>
              </div>

              <div class="col-sm-5 form-group">
                <label>
                  <span class="uk-label">Relationship</span>
                  <select
                    id="relationship_{{ i }}"
                    formControlName="relationship"
                    name="relationship"
                    class="uk-input"
                    required
                  >
                    <option *ngFor="let relationshipId of relationshipKeys" [value]="relationshipId">
                      {{ relationships[relationshipId] }}
                    </option>
                  </select>
                </label>
                <div class="text-error">
                  <div *ngIf="showMultipleSpousalError(i)">
                    You can only have one spouse selected as a beneficiary.
                  </div>
                </div>
              </div>

              <div class="col-sm-2 form-group">
                <label>
                  <span class="uk-label">Share</span>
                  <div style="display: flex; align-items: center;">
                    <input
                      id="percentage_{{ i }}"
                      formControlName="percentage"
                      name="percentage"
                      class="uk-input"
                      maxlength="3"
                      required
                    />
                    <div class="percent-sign">%</div>
                  </div>
                </label>
                <div *ngIf="beneficiaryValidator(i, 'percentage') as error">
                  <span class="text-error">
                    {{ error }}
                  </span>
                </div>
              </div>
            </div>

            <div class="separator"></div>
          </div>
        </div>
      </div>

      <div class="percentage-cont">
        <a class="add-btn" (click)="addBeneficiary()">
          <app-svg-icon
            [iconName]="addIcon"
            [styles]="{ width: '1rem', height: '1rem', fill: '#176acf', strokeWidth: 1 }"
          ></app-svg-icon>
          <span>Add another beneficiary</span>
        </a>

        <div class="percentage-sub-cont">
          <div class="mr-3">
            Total Share
            <app-tooltip content="The total of all beneficiaries must equal 100%."></app-tooltip>
          </div>

          <div class="share-percentage">{{ totalPercentage }} %&nbsp;</div>

          <app-svg-icon
            [iconName]="percentageStatus.icon"
            [iconColor]="percentageStatus.color"
            [styles]="percentageIconStyles"
          ></app-svg-icon>
        </div>
      </div>

      <div *ngIf="isSpousalConsentNeeded" class="spousal-consent">
        <div>
          <h4>Spousal Consent Required</h4>
          <div class="mt-3">
            Your spouse needs to provide consent via email if you elect not to have them as your sole primary
            beneficiary.
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-6 form-group">
            <label>
              <span class="uk-label">Spouse First Name</span>
              <input
                id="spouseName"
                name="spouseName"
                formControlName="spouseName"
                class="uk-input"
                type="text"
                required
              />
            </label>
            <div *ngIf="spouseValidator('spouseName') as error">
              <span class="text-error">
                {{ error }}
              </span>
            </div>
          </div>

          <div class="col-sm-6 form-group">
            <label>
              <span class="uk-label">Spouse Last Name</span>
              <input
                id="spouseLastName"
                name="spouseLastName"
                formControlName="spouseLastName"
                class="uk-input"
                type="text"
                required
              />
            </label>
            <div *ngIf="spouseValidator('spouseLastName') as error">
              <span class="text-error">
                {{ error }}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 form-group">
            <label>
              <span class="uk-label">Spouse Email</span>
              <input
                id="spouseEmail"
                name="spouseEmail"
                formControlName="spouseEmail"
                class="uk-input"
                type="text"
                required
              />
            </label>
            <div *ngIf="spouseValidator('spouseEmail') as error">
              <span class="text-error">
                {{ error }}
              </span>
            </div>
          </div>
        </div>

        <div>
          <a class="learn-more" (click)="otherOptionsOpen = !otherOptionsOpen">
            Learn About Other Options
            <app-svg-icon
              [iconName]="otherOptionsIcon.icon"
              [iconColor]="otherOptionsIcon.color"
              [styles]="{ width: '1rem', height: '1rem' }"
            ></app-svg-icon>
          </a>

          <div class="mt-3" [hidden]="!otherOptionsOpen">
            Spousal consent is not required when adding contingent beneficiaries. You can add them to your account after
            you have updated your primary beneficiaries.
            <br />
            <br />
            If you no longer have a spouse, update your marital status before adding your beneficiaries.

            <a class="go-profile" (click)="gotoProfile()">Go To Account Profile</a>
          </div>
        </div>
      </div>

      <div class="text-center">
        <div [hidden]="isSaving">
          <div class="m-t-b-15">
            <button type="submit" class="uk-btn solid secondary lg">
              Save
            </button>
          </div>
          <div
            *ngIf="
              showSaveMessage && (beneficiariesDataForm.invalid || !isValidPercentage() || hasMultipleSpousalSelected())
            "
            class="text-error m-t-b-15"
          >
            Please correct the errors highlighted above and try again
          </div>
        </div>
        <app-dot-loader [hidden]="!isSaving">Saving</app-dot-loader>
      </div>
    </form>

    <div class="beneficiaries-acknowledgment row justify-content-center">
      <div class="col-sm-10">
        <div class="text-justify">
          Accounts held in the names of two or more owners will become payable to the designated beneficiaries only upon
          the death of all account owners. If there is more than one beneficiary, the funds in the account will be paid
          to the beneficiaries based on their designated percentage share. The interest of any beneficiary who
          predeceases the account owner(s) terminates completely, and the percentage share of any remaining
          beneficiaries will be increased on a pro rata basis. In the event that all named primary beneficiaries
          predecease the account owner(s), those named as contingent beneficiaries, if any, will become the primary
          beneficiaries. In the event that all named beneficiaries predecease the account owner(s), the estate of the
          account owner will be the beneficiary. You may change your designated beneficiaries at any time.
        </div>
      </div>
    </div>
  </div>
</div>
