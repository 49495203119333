<div class="axos-invest-portfolio">
  <div class="container axos-invest-portfolio__content">
    <div class="flex-wrap justify-content-center">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 pt-3">
          <div class="header">
            <h3 class="portfolio-title">Portfolio</h3>
            <div class="edit-button">
              <button class="edit-label__btn" type="button" (click)="redirectToEditProfile()">
                <app-svg-icon
                  class="edit-label__btn-icon"
                  [iconName]="icons.edit"
                  width="1.10em"
                  height="1.10em"
                  iconColor="#1c7fdd"
                ></app-svg-icon>
                <span>Edit Portfolio</span>
              </button>
            </div>
          </div>

          <div class="portfolio-type">
            <div class="portfolio-type__title">
              <p>Portfolio Type</p>
            </div>
            <div class="portfolio-type__content" *ngIf="!isMilestoneDataLoading">
              <p>{{ portfolioRiskType }}</p>
            </div>
          </div>
          <app-dot-loader *ngIf="isMilestoneDataLoading">
            Loading Portfolio Type ...
          </app-dot-loader>

          <div class="main-portfolio-details" *ngIf="!isPortfolioDataLoading">
            <div class="main-portfolio-details__item">
              <p>Stocks</p>
              <h1 class="stock">{{ !portfolioDataError ? stocksDetails.target : '--' }}%</h1>
            </div>
            <div class="main-portfolio-details__item">
              <p>Bonds</p>
              <h1 class="bond">{{ !portfolioDataError ? bondsDetails.target : '--' }}%</h1>
            </div>
            <div class="main-portfolio-details__item" *ngIf="portfolioDataError || cashDetails.target > 0">
              <p>Cash</p>
              <h1 class="cash">{{ !portfolioDataError ? cashDetails.target : '--' }}%</h1>
            </div>
          </div>
          <app-dot-loader *ngIf="isPortfolioDataLoading">
            Loading Portfolio Allocation ...
          </app-dot-loader>

          <div class="d-lg-none">
            <div class="chart-wrapper">
              <app-portfolio-graph [portfolioData]="chartData" *ngIf="!isPortfolioDataLoading"></app-portfolio-graph>
              <div class="donut-inner" *ngIf="!isPortfolioDataLoading && !portfolioDataError">
                <p>Your Assets</p>
              </div>
              <div class="donut-inner-error" *ngIf="!isPortfolioDataLoading && portfolioDataError">
                <p>Unable to load portfolio</p>
              </div>
            </div>
            <app-dot-loader *ngIf="isPortfolioDataLoading">
              Loading Portfolio Allocation Graph...
            </app-dot-loader>
            <div class="disclaimer-tooltip">
              <span class="disclaimer-tooltip-text">
                <app-tooltip
                  symbol="!"
                  content="Asset Classes displayed are based upon the class of assets that most closely relates to the exchange
                  traded funds selected in your account, and the percentage of your assets that have been allocated to those
                  particular exchange-traded fund(s) (“ETF” or “ETFs”). Further information on the ETFs selected for the for
                  your portfolio is available by prospectus, which can be obtained through your customer profile or by
                  contacting support@axosinvest.com. Investors should carefully consider the investment objectives, risks,
                  charges and expenses of each ETF before investing or changing your asset allocation. All investments are
                  subject to risk. The prospectus contains this and other information about the funds and should be read
                  carefully before investing."
                ></app-tooltip>
                <p>
                  Asset Disclaimer
                </p>
              </span>
            </div>
          </div>

          <div class="portfolio-table" *ngIf="!isPortfolioDataLoading">
            <table class="portfolio-details">
              <thead>
                <tr class="table-head-row">
                  <th></th>
                  <th>Target</th>
                  <th>Current</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-body-head">
                  <td class="main-asset">Stocks</td>
                  <td>{{ !portfolioDataError ? stocksDetails.target : '--' }}%</td>
                  <td>{{ !portfolioDataError ? stocksDetails.percent.toFixed(0) : '--' }}%</td>
                  <td class="value">{{ !portfolioDataError ? (stocksDetails.marketValue | currency) : '--' }}</td>
                </tr>
                <ng-container *ngIf="!portfolioDataError && stocksList.length > 0">
                  <tr class="table-body-content" *ngFor="let stock of stocksList">
                    <td class="asset">
                      <div class="square" [ngStyle]="{ 'background-color': stock.color }"></div>
                      {{ stock.name }}
                    </td>
                    <td>{{ stock.target }}%</td>
                    <td>{{ stock.percent }}%</td>
                    <td class="value">{{ stock.marketValue | currency }}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="portfolioDataError">
                  <tr class="table-body-content" *ngFor="let color of stockColors">
                    <td class="asset">
                      <div class="square" [ngStyle]="{ 'background-color': color }"></div>
                      <div class="error-square"></div>
                    </td>
                  </tr>
                </ng-container>

                <tr class="table-body-head" id="below-row">
                  <td class="main-asset" id="below-row">Bonds</td>
                  <td>{{ !portfolioDataError ? bondsDetails.target : '--' }}%</td>
                  <td>{{ !portfolioDataError ? bondsDetails.percent.toFixed(0) : '--' }}%</td>
                  <td class="value">{{ !portfolioDataError ? (bondsDetails.marketValue | currency) : '--' }}</td>
                </tr>
                <ng-container *ngIf="!portfolioDataError && bondsList.length > 0">
                  <tr class="table-body-content" *ngFor="let bond of bondsList">
                    <td class="asset">
                      <div class="square" [ngStyle]="{ 'background-color': bond.color }"></div>
                      {{ bond.name }}
                    </td>
                    <td>{{ bond.target }}%</td>
                    <td>{{ bond.percent }}%</td>
                    <td class="value">{{ bond.marketValue | currency }}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="portfolioDataError">
                  <tr class="table-body-content" *ngFor="let color of bondColors">
                    <td class="asset">
                      <div class="square" [ngStyle]="{ 'background-color': color }"></div>
                      <div class="error-square"></div>
                    </td>
                  </tr>
                </ng-container>

                <ng-container *ngIf="portfolioDataError || cashDetails.target > 0">
                  <tr class="table-body-head">
                    <td class="main-asset" id="below-row">Cash</td>
                    <td>{{ !portfolioDataError ? cashDetails.target : '--' }}%</td>
                    <td>{{ !portfolioDataError ? cashDetails.percent.toFixed(0) : '--' }}%</td>
                    <td class="value">{{ !portfolioDataError ? (cashDetails.marketValue | currency) : '--' }}</td>
                  </tr>
                  <ng-container *ngIf="!portfolioDataError && cashDetails.target > 0">
                    <tr class="table-body-content">
                      <td class="asset">
                        <div class="square" [ngStyle]="{ 'background-color': cash.color }"></div>
                        {{ cash.name }}
                      </td>
                      <td>{{ cash.target }}%</td>
                      <td>{{ cash.percent.toFixed(0) }}%</td>
                      <td class="value">{{ cash.marketValue | currency }}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="portfolioDataError">
                    <tr class="table-body-content">
                      <td class="asset">
                        <div class="square" [ngStyle]="{ 'background-color': 'rgba(38, 166, 128, 1)' }"></div>
                        <div class="error-square"></div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 pt-3">
          <div class="chart-wrapper d-none d-lg-block">
            <app-portfolio-graph [portfolioData]="chartData" *ngIf="!isPortfolioDataLoading"></app-portfolio-graph>
            <div class="donut-inner" *ngIf="!isPortfolioDataLoading && !portfolioDataError">
              <p>Your Assets</p>
            </div>
            <div class="donut-inner-error" *ngIf="!isPortfolioDataLoading && portfolioDataError">
              <p>Unable to load portfolio</p>
            </div>
            <app-dot-loader *ngIf="isPortfolioDataLoading">
              Loading Portfolio Allocation Graph...
            </app-dot-loader>
          </div>

          <div class="d-flex justify-content-center">
            <div class="portfolio-features" *ngIf="!isFormulaDataLoading && !isRiskScoreDataLoading">
              <a class="portfolio-features__header" (click)="toggleProfileFeatures()">
                <p>Portfolio Features</p>
                <app-svg-icon
                  class="portfolio-features__header-icon"
                  [iconName]="icons.down"
                  width="1em"
                  height="1em"
                  iconColor="#3d6690"
                ></app-svg-icon>
              </a>
              <div class="portfolio-features__content" *ngIf="viewPortfolioFeatures">
                <div class="portfolio-features__content-formula">
                  <div class="portfolio-features__content-formula-header">
                    <span class="formula-text">
                      <p>Formula</p>
                      <app-tooltip
                        [white]="false"
                        content="Features such as round-up, dividend match, bargain hunting, and level up
                      that can help you build towards your goals."
                      ></app-tooltip>
                    </span>
                    <button class="formula-edit" (click)="redirectToFormula()">
                      <p>Edit</p>
                      <app-svg-icon
                        class="portfolio-features__header-icon"
                        [iconName]="icons.go"
                        width="1.15em"
                        height="1.15em"
                        iconColor="#1c7fdd"
                      ></app-svg-icon>
                    </button>
                  </div>
                  <div class="portfolio-features__content-formula-content">
                    <p>{{ portfolioFormulas }}</p>
                  </div>
                </div>
                <div class="portfolio-features__content-glidePath">
                  <div class="portfolio-features__content-glidePath-header">
                    <span class="glidePath-text">
                      <p>End Target Allocation</p>
                      <app-tooltip [white]="false" content=""></app-tooltip>
                    </span>
                  </div>
                  <div class="portfolio-features__content-glidePath-content">
                    <div class="has-glidePath" *ngIf="hasGlidePath">
                      <div class="has-glidePath" *ngIf="hasGlidePath && !riskScoreDataError">
                        <table class="glidePath-Table">
                          <tr>
                            <td class="asset">Stocks</td>
                            <td class="asset">Bonds</td>
                            <ng-container *ngIf="!portfolioDataError && cashDetails.target > 0">
                              <td class="asset">Cash</td>
                            </ng-container>
                          </tr>
                          <tr>
                            <td>{{ stockRateEnd | percent }}</td>
                            <td>{{ bondRateEnd | percent }}</td>
                            <ng-container *ngIf="!portfolioDataError && cashDetails.target > 0">
                              <td>{{ goalData.allocation.cashEnd | percent }}</td>
                            </ng-container>
                          </tr>
                          <tr>
                            <td class="asset" colspan="3">
                              Goal End Year
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3">
                              {{ goalData.targetDate | date: 'yyyy' }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="no-glidePath" *ngIf="hasGlidePath && riskScoreDataError">
                      <p>---</p>
                    </div>
                    <div class="set-targets pt-4" *ngIf="!hasGlidePath">
                      <app-svg-icon
                        class="portfolio-features__header-icon"
                        [iconName]="icons.pieChart"
                        width="1.5em"
                        height="1.5em"
                      ></app-svg-icon>
                      <div class="col-12 mt-2">
                        <button class="link" (click)="redirectToEditProfile(true)">
                          <p>Set Up End Targets</p>
                          <app-svg-icon
                            class="icon-target"
                            [iconName]="icons.arrowForward"
                            width="1.15em"
                            height="1.15em"
                          ></app-svg-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <app-dot-loader *ngIf="isFormulaDataLoading && isRiskScoreDataLoading">
              Loading Portfolio Features ...
            </app-dot-loader>
          </div>
        </div>
        <div class="portfolio-disclaimer d-none d-lg-block" (click)="togglePortfolioDisclaimer()">
          <div class="portfolio-disclaimer-header">
            <p>How are asset classes determined?</p>
            <app-svg-icon class="portfolio-disclaimer-header-icon" [iconName]="icons.down" size="1em"></app-svg-icon>
          </div>
          <div class="portfolio-disclaimer-content" *ngIf="viewPortfolioDisclaimer">
            <p>
              Asset Classes displayed are based upon the class of assets that most closely relates to the exchange
              traded funds selected in your account, and the percentage of your assets that have been allocated to those
              particular exchange-traded fund(s) (“ETF” or “ETFs”). Further information on the ETFs selected for the for
              your portfolio is available by prospectus, which can be obtained through your customer profile or by
              contacting support@axosinvest.com. Investors should carefully consider the investment objectives, risks,
              charges and expenses of each ETF before investing or changing your asset allocation. All investments are
              subject to risk. The prospectus contains this and other information about the funds and should be read
              carefully before investing.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
