import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { Observable, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { InterestedParties } from '@app/Areas/AAS/features/account-details/core';
import { BLANK_FORMAT, DATE_FORMAT, ALLOWED_ACCOUNTS_TYPES } from '@app/Areas/AAS/features/account-details/view';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';

@Component({
  selector: 'app-tod-contingent-beneficiary',
  templateUrl: './tod-contingent-beneficiary.component.html',
  styleUrls: ['./tod-contingent-beneficiary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodContingentBeneficiaryComponent implements OnInit {
  todContingentBeneficiaries$: Observable<Array<InterestedParties>>;

  blankFormat = BLANK_FORMAT;
  dateFormat = DATE_FORMAT;

  constructor(private peopleFacade: PeopleFacade, private pdpFacade: PdpFacade) {}

  ngOnInit(): void {
    this.todContingentBeneficiaries$ = combineLatest([
      this.peopleFacade.accountPeopleTabState$,
      this.pdpFacade.selectedAasAccount$,
    ]).pipe(
      filter(
        ([accountPeopleTabState, selectedAasAccount]) =>
          !!accountPeopleTabState?.accountPeople?.todContingentBeneficiaries && !!selectedAasAccount
      ),
      filter(([, selectedAasAccount]) =>
        ALLOWED_ACCOUNTS_TYPES.some(allowedAccountsType => allowedAccountsType === selectedAasAccount.type)
      ),
      filter(([accountPeopleTabState]) => accountPeopleTabState.accountPeople.todPrimaryBeneficiaries.length > 0),
      map(([accountPeopleTabState]) => accountPeopleTabState.accountPeople.todContingentBeneficiaries),
      map(todContingentBeneficiaries => todContingentBeneficiaries.sort((a, b) => a.name.localeCompare(b.name))),
      map(todContingentBeneficiaries => todContingentBeneficiaries.sort((a, b) => b.ipPercent - a.ipPercent))
    );
  }
}
