import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'messages');
  }

  sendMessage(message: FormData): WebApiResponse<null> {
    const endpoint = message.get('subCategory') !== 'Escrow Inquiry' ? 'messageNew' : 'customerNew';

    return this.post(endpoint, message);
  }

  sendLoanQuoteMessage(message: FormData): WebApiResponse<null> {
    const endpoint = 'loanQuote';
    return this.post(endpoint, message);
  }
}
