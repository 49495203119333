import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BeneficiariesHelper } from 'services/beneficiaries.helper';
import { ModalService } from 'services/modal.service';
import { IBeneficiariesService } from 'services/typings/IBeneficiariesService';

import { BeneficiaryRelationshipType, UtilityIcons } from '@app/shared/enums';
import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { Beneficiary } from '@shared/models';

@Inject('beneficiariesHelper')
@Component({
  selector: 'app-edit-beneficiaries',
  templateUrl: './edit-beneficiaries.component.html',
  styleUrls: ['./edit-beneficiaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBeneficiariesComponent implements OnInit {
  icons = {
    trash: UtilityIcons.Trash,
  };
  beneficiary: Beneficiary;
  account: OlbAccount;
  accountId: number;
  isBusy: boolean;
  isOtherRelationship: boolean;
  formBeneficiaries: FormGroup;
  constructor(
    @Inject(ROOT_SCOPE) private readonly rootScope: ng.IRootScopeService,
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private readonly params: ng.ui.IStateParamsService,
    @Inject('beneficiariesService') private readonly beneficiariesService: IBeneficiariesService,
    private readonly beneficiariesHelper: BeneficiariesHelper,
    private readonly modalService: ModalService,
    @Inject('serviceHelper') private readonly serviceHelper: IServiceHelper,
    private fb: FormBuilder
  ) {
    this.beneficiary = this.params['beneficiary'];
    this.accountId = this.params['accountId'];
    this.getAccount();
    this.handleRelationship();
  }

  ngOnInit(): void {
    this.formBeneficiaries = this.fb.group({});
  }
  removeBeneficiary(): void {
    this.modalService
      .show(
        {},
        {
          bodyText: '<h3>This beneficiary will be removed</h3>',
          okText: 'Yes',
          cancelText: 'No',
        }
      )
      .then(() => {
        this.isBusy = true;
        this.beneficiariesService
          .removeBeneficiaries(this.beneficiary.id)
          .then(res => {
            this.beneficiariesHelper.saveBeneficiaryResponse(res.data);
            this.state.go('udb.accounts.details', { id: this.accountId, tab: 1 });
          })
          .catch(_err => {
            this.isBusy = false;
            this.serviceHelper.errorHandler.bind(this.serviceHelper);
          });
      });
  }

  editBeneficiary(form: any): void {
    if (!form.$valid) {
      return;
    }

    this.isBusy = true;
    this.beneficiary.accounts = [this.account];
    this.beneficiariesService
      .editBeneficiaries(this.beneficiary)
      .then(res => {
        this.beneficiariesHelper.saveBeneficiaryResponse(res.data);
        this.state.go('udb.accounts.details', { id: this.accountId, tab: 1 });
      })
      .catch(_err => {
        this.isBusy = false;
        this.serviceHelper.errorHandler.bind(this.serviceHelper);
      });
  }

  redirectToPage(paramAccountId: any): void {
    this.state.go('udb.accounts.details', {
      id: paramAccountId,
      tab: 1,
    });
  }

  private getAccount(): void {
    const accounts: OlbAccount[] = this.rootScope['accounts'].depositAccounts;
    this.account = accounts.filter(a => a.id === this.accountId)[0];
  }

  private handleRelationship(): void {
    const relationships: string[] = Object.keys(BeneficiaryRelationshipType);
    this.isOtherRelationship = !relationships.some(r => r === this.beneficiary.relationship);
  }
}
