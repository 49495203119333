<div class="library">
  <ng-container *ngIf="library">
    <div *ngFor="let section of sections">
      <app-document-section
        [label]="section.label"
        [documents]="library[section.key]"
        *ngIf="library[section.key].length > 0"
      ></app-document-section>
    </div>
  </ng-container>
</div>
