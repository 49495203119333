import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ROOT_SCOPE, STATE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { NavigationIcons, UtilityIcons } from '@shared/enums';
import { AnimationOptions } from 'ngx-lottie';
import {
  Uk2Tier1CommunicationEnum,
  Uk2Tier1FinancialEnum,
} from '@axos/uikit-v2-lib';
import { AddInvestorCheckingInReviewData } from './types';

@Component({
  selector: 'app-account-review-modal',
  templateUrl: './account-review-modal.component.html',
  styleUrls: ['./account-review-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountReviewModalComponent implements OnInit {
  uk2Tier1CommunicationEnum = Uk2Tier1CommunicationEnum;
  uk2Tier1FinancialEnum = Uk2Tier1FinancialEnum;
  iconConfig = {
    header: {
      size: '2rem',
      icon: UtilityIcons.ThumbsUp,
    },
    close: {
      size: '1rem',
      icon: NavigationIcons.Ex,
    },
  };

  pendingAnimationOptions: AnimationOptions = {
    path: '/assets/axos/img/icons/accounts/Pending.json',
    loop: false,
  };

  productName = 'Cash Management Checking';
  vestingName = 'Individual';
  confirmationNumber: string;
  successMessage: string;
  email: string;
  riaPilotICODLDebitCardFlagActive: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddInvestorCheckingInReviewData,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    public dialogRef: MatDialogRef<AccountReviewModalComponent>,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.riaPilotICODLDebitCardFlagActive = this.featureFlagService.isRiaPilotICODLDebitCardActive();
    this.setDialogData();
  }

  setDialogData() {
    this.confirmationNumber = this.data.confirmationNumber;
    this.email = this.data.email;
  }

  close() {
    this.dialogRef.close();
    this.rootScope['dashboardModalsCanBeDisplayed'] = true;
    this.rootScope.$broadcast('dashboardModalsCanBeDisplayed');
    this.state.go('udb.dashboard');
  }
}
