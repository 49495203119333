import { Component } from '@angular/core';

import { CashTransaction } from '@app/axos-invest/models';

import { TransactionDetailComponent } from '../transaction-row/transaction-detail.component';

@Component({
  selector: 'app-cash-transaction-information',
  templateUrl: './cash-transaction-information.component.html',
  styleUrls: ['./cash-transaction-information.component.scss'],
})
export class CashTransactionInformationComponent implements TransactionDetailComponent {
  transaction: CashTransaction;
}
