import { IFilterService } from 'angular';
import { Inject } from 'decorators/decorators';

import { TxFilter } from '../typings/TxFilter';

@Inject('$filter')
export default class FiltersHelperService {
  constructor(private readonly filterService: IFilterService) {}

  /**
   * Assign the new filter value to the filters object
   * @param value Filter value
   * @param propertyName Actual filter name
   */
  setFilterValue(filters: TxFilter, value: any, propertyName: string) {
    let newFilters: TxFilter;
    newFilters = { ...filters };
    newFilters[propertyName] = value;

    filters = { ...newFilters };

    return filters;
  }

  setFiltersApplied(filterName: string, filtersApplied: string[]): string[] {
    if (!['categories', 'query'].some(f => f === filterName)) {
      const idx = filtersApplied.findIndex(name => name === filterName);
      if (idx < 0) {
        filtersApplied.push(filterName);
      } else filtersApplied[idx] = filterName;
    }

    return [...filtersApplied];
  }

  resetFilter(filterName: string, filter: TxFilter) {
    const newFilter = { ...filter };

    switch (filterName) {
      case 'amount':
        newFilter.amount = null;
        break;
      case 'amountRange':
        newFilter.amountRange = { min: 0, max: 0 };
        break;
      case 'check':
        newFilter.check = null;
        break;
      case 'checkRange':
        newFilter.checkRange = { min: 0, max: 0 };
        break;
      case 'dateRange':
        newFilter.dateRange = { start: null, end: null };
    }

    return newFilter;
  }

  checkFilter(tx: Transaction, check: number) {
    return tx.checkNumber.includes(`${check}`) || !check;
  }

  checkRangeFilter(tx: Transaction, checkRange: any) {
    return (
      (+tx.checkNumber.replace('#', '') <= +checkRange.max && +tx.checkNumber.replace('#', '') >= +checkRange.min) ||
      (!checkRange.max && !checkRange.min)
    );
  }

  dateFilter(date: any, pattern: string) {
    return (
      this.filterService('date')(date, 'MM/dd/yyyy').includes(pattern) ||
      this.filterService('date')(date, 'MM/dd/yy').includes(pattern) ||
      this.filterService('date')(date, 'M/d/yyyy').includes(pattern) ||
      this.filterService('date')(date, 'M/d/yy').includes(pattern) ||
      this.filterService('date')(date, 'M/dd/yyyy').includes(pattern) ||
      this.filterService('date')(date, 'M/dd/yy').includes(pattern) ||
      this.filterService('date')(date, 'MM/d/yyyy').includes(pattern) ||
      this.filterService('date')(date, 'MM/d/yy').includes(pattern)
    );
  }

  amountPatternFilter(amount: number, pattern: string) {
    return amount.toFixed(2).includes(pattern);
  }

  amountRangeFilter(tx: Transaction, amountRange: any) {
    if (amountRange.min === undefined) {
      amountRange.min = 0;
    }
    const amount = tx.signedAmount || tx.amount;

    return (
      (amount <= amountRange.max && amount >= amountRange.min) ||
      (amountRange.min === undefined && amount <= amountRange.max) ||
      (amountRange.max === undefined && amount >= amountRange.min) ||
      (!amountRange.max && !amountRange.min)
    );
  }
}
