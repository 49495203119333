export const FUNDING = {
  TURN_ON: 'TURN_FUNDING_ON',
  TURN_OFF: 'TURN_FUNDING_OFF',
  CHANGE_METHOD: 'CHANGE_FUNDING_METHOD',
  CHANGE_AMOUNT: 'CHANGE_FUNDING_AMOUNT',
  SETUP_ACCOUNT_ID: 'SETUP_FUNDING_ACCOUNT_ID',
  SETUP_FROM_ACCOUNT_ID: 'SETUP_FUNDING_FROM_ACCOUNT_ID',
  SETUP_ACCOUNTS: 'SETUP_FUNDING_ACCOUNTS',
  SETUP_FUNDING_ACCOUNTS: 'SETUP_FUNDING_ACCOUNTS_NEW',
  ON_FUNDED_ACCOUNT: 'ON_FUNDED_ACCOUNT',
};
