import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { AxosClearingService } from '@app/axos-invest/services';
import { TradingAccount } from '@app/axos-trading/models';
import { STATE_PARAMS } from '@core/tokens';
import { AxosClearingUrlHelper } from '@legacy/services/axos-clearing-urls.service';
import { CachedTradingAccountsService } from '@legacy/services/cached-trading-accounts.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { IScope } from 'angular';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-axos-trading-overview',
  templateUrl: './axos-trading-overview.component.html',
  styleUrls: ['./axos-trading-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxosTradingOverviewComponent implements OnInit {
  @Input() tradingAccounts: TradingAccount[] = [];
  summary: TradingAccount;
  showSummary = true;
  showChart = true;
  positions: TradingPosition[] = [];

  isLoading = false;
  marketValuePercent: number;
  marketValue: number;
  private accountId: number;

  constructor(
    @Inject('$scope') private scope: IScope,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private axosClearingService: AxosClearingService,
    private axosClearingUrlHelper: AxosClearingUrlHelper,
    private readonly cachedTradingAccountsService: CachedTradingAccountsService,
    private serviceHelper: ServiceHelper,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.accountId = this.params['id'];
  }

  ngOnInit(): void {
    this.scope.$on('onPositionDetailResponsiveClick', this.onPositionDetailResponsiveClick);
    this.scope.$on('onPositionDetailResponsiveBack', this.onPositionDetailResponsiveBack);
    if (this.tradingAccounts && this.tradingAccounts.length > 0) {
      this.setSummaryData();
      this.getPositions();
    }
  }

  goToOrbis(responsive: boolean): void {
    this.axosClearingUrlHelper.getOrbisUrl(this.summary.accountNumber, responsive).then(url => {
      const win = window.open(url, '_blank', 'noreferrer noopener');
      win.focus();
    });
  }

  isClosedAccount(): boolean {
    if (this.summary) {
      return this.summary?.statusName === 'Closed' || this.summary?.statusName === 'Redeemed';
    }

    return true;
  }

  private setSummaryData(): void {
    this.summary = this.cachedTradingAccountsService.tradingAccounts.find(c => c.id == this.accountId);
  }

  private getPositions(): void {
    this.isLoading = true;

    this.axosClearingService
      .getPositions(this.summary.accountNumber)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: res => {
          this.positions = res.data;
          this.marketValue = this.positions.reduce((sum, value) => sum + value.marketValue, 0);
          this.marketValuePercent = this.positions.reduce(
            (sum, value) => sum + Math.round(value.percentageOfAccount * 10000) / 10000,
            0
          );
        },
        error: err => {
          this.serviceHelper.errorHandler(err);
        },
      });
  }

  private onPositionDetailResponsiveClick = (_e: ng.IAngularEvent) => {
    this.showSummary = false;
  };
  private onPositionDetailResponsiveBack = (_e: ng.IAngularEvent) => {
    this.showSummary = true;
  };
}
