<ng-container [ngSwitch]="currentView">
  <up-section-heading
    #heading
    [section]="menuTitle"
    [mobile]="isMobileDevice"
    [titleEllipsis]="true"
    (return)="goBack()"
    (expand)="expandingMenu($event)"
  ></up-section-heading>
  <div class="section__heading" *ngIf="controlActive">
    <p class="mb-3 subtitles_header">Ensure your business information is up-to-date</p>
    <br />
  </div>
  <div class="business-profile" *ngIf="controlActive">
    <up-profile-item
      titleText="test"
      itemName="Business Profile"
      [editable]="false"
      (edit)="goTo(views.Email)"
      [alertMessage]="alertMessages.email"
      class="companyName"
    >
      <app-dot-loader *ngIf="profileLoading; else emailContent"></app-dot-loader>
      <ng-template #emailContent>
        <div class="mb-3 d-flex flex-column flex-lg-row no-gutters subtitles_col">
          <p class="col-12 col-lg-5 font-weight-bold">Company Name:</p>
          <div *ngIf="companyInfo?.businessEmail" class="col-12 col-lg-7 email-container">
            <p
              [attr.aria-label]="companyInfo?.companyName"
              class="font-weight-normal profile-email"
              [innerText]="companyInfo?.companyName"
            ></p>
            <div aria-hidden="true" class="uk-bottom-tip" [attr.data-uk-tooltip]="companyInfo?.companyName"></div>
          </div>
        </div>
      </ng-template>
    </up-profile-item>
  </div>

  <div *ngSwitchDefault [@startStaticSlideFromLeft]="animationState">
    <div *ngIf="(!isMobileDevice || expanded) && controlActive" @openClose [@.disabled]="!isMobileDevice">
      <up-profile-item
        itemName="Business Contact Information"
        [editable]="false"
        (edit)="goTo(views.Email)"
        [alertMessage]="alertMessages.email"
        class="email"
      >
        <app-dot-loader *ngIf="profileLoading; else emailContent"></app-dot-loader>
        <ng-template #emailContent>
          <div class="mb-3 d-flex flex-column flex-lg-row no-gutters subtitles_col">
            <p class="col-12 col-lg-5 font-weight-bold">Business Email:</p>
            <div *ngIf="companyInfo?.businessEmail" class="col-12 col-lg-7 email-container">
              <p
                [attr.aria-label]="companyInfo?.businessEmail"
                class="font-weight-normal profile-email"
                [innerText]="companyInfo?.businessEmail"
              ></p>
              <div aria-hidden="true" class="uk-bottom-tip" [attr.data-uk-tooltip]="companyInfo?.businessEmail"></div>
            </div>
          </div>
        </ng-template>
      </up-profile-item>
      <up-profile-item
        itemName=""
        [editable]="false"
        (edit)="goTo(views.Phone)"
        [alertMessage]="alertMessages.phone"
        class="work-phone"
      >
        <app-dot-loader *ngIf="profileLoading; else phoneContent"></app-dot-loader>
        <ng-template #phoneContent>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 work-phone subtitles_col">
            <p class="col-12 col-lg-5 font-weight-bold">Business Phone:</p>
            <p
              class="col-12 col-lg-7 font-weight-normal value"
              *ngIf="companyInfo?.businessPhone?.number && companyInfo?.businessPhone?.number !== '0'"
            >
              {{ companyInfo?.businessPhone?.number | phone }}&nbsp;
              <span
                *ngIf="companyInfo?.businessPhone?.extension"
                [innerText]="'x' + companyInfo?.businessPhone?.extension"
              ></span>
            </p>
          </div>
        </ng-template>
      </up-profile-item>
      <up-profile-item
        itemName=""
        [editable]="false"
        (edit)="goTo(views.Address)"
        [alertMessage]="alertMessages.address"
        class="address"
      >
        <app-dot-loader *ngIf="profileLoading; else addressContent"></app-dot-loader>
        <ng-template #addressContent>
          <div class="d-flex flex-column flex-lg-row no-gutters mb-3 home-address subtitles_col">
            <p class="col-12 col-lg-5 font-weight-bold">Business Address:</p>
            <p class="col-12 col-lg-7 invalid-address" *ngIf="primaryAddressIsIncomplete; else primaryAddress">
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Address Needed
            </p>
            <ng-template #primaryAddress>
              <p
                class="col-12 col-lg-7 font-weight-normal"
                [innerText]="companyInfo?.businessAddress?.address | address"
              ></p>
            </ng-template>
          </div>
          <div
            class="d-flex flex-column flex-lg-row no-gutters mb-3 mailing-address subtitles_col"
            *ngIf="companyInfo?.mailingAddress?.address"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Business Mailing Address:</p>
            <p class="col-12 col-lg-7 invalid-address" *ngIf="mailingAddressIsIncomplete; else mailingAddressSection">
              <app-svg-icon [iconName]="errorIcon"></app-svg-icon>
              Address Needed
            </p>

            <ng-template #mailingAddressSection>
              <p
                class="col-12 col-lg-7 font-weight-normal"
                [innerText]="companyInfo?.mailingAddress?.address | address"
              ></p>
            </ng-template>
          </div>
        </ng-template>
      </up-profile-item>

      <up-profile-item
        itemName=""
        [editable]="false"
        (edit)="goTo(views.Fax)"
        [alertMessage]="alertMessages.fax"
        class="fax"
      >
        <app-dot-loader *ngIf="profileLoading; else faxContent"></app-dot-loader>
        <ng-template #faxContent>
          <div
            *ngIf="companyInfo?.businessFax?.number && companyInfo?.businessFax?.number !== '0'"
            class="d-flex flex-column flex-lg-row no-gutters mb-3 subtitles_col"
          >
            <p class="col-12 col-lg-5 font-weight-bold">Business Fax:</p>
            <p class="col-12 col-lg-7 font-weight-normal value">
              {{ companyInfo?.businessFax?.number | phone }}
            </p>
          </div>
        </ng-template>
      </up-profile-item>
    </div>
  </div>
  <up-change-email
    @slideFromRight
    *ngSwitchCase="views.Email"
    [profileType]="profileTypes.Company"
    [userProfile]="profileInfo"
    [selectedBusinessId]="activeProfile"
    (updateEmails)="updateEmails($event)"
    (back)="goBack()"
  ></up-change-email>

  <up-change-phone
    @slideFromRight
    *ngSwitchCase="views.Phone"
    [profileType]="profileTypes.Company"
    [userProfile]="profileInfo"
    [selectedBusinessId]="activeProfile"
    (updatePhones)="updatePhones($event)"
    (back)="goBack()"
  ></up-change-phone>

  <up-change-address
    @slideFromRight
    *ngSwitchCase="views.Address"
    [profileType]="profileTypes.Company"
    [userProfile]="profileInfo"
    [selectedBusinessId]="activeProfile"
    (updateAddresses)="updateAddresses($event)"
    (back)="goBack()"
  ></up-change-address>

  <up-change-phone
    @slideFromRight
    *ngSwitchCase="views.Fax"
    [profileType]="profileTypes.Company"
    [isFax]="true"
    [userProfile]="profileInfo"
    [selectedBusinessId]="activeProfile"
    (updatePhones)="updatePhones($event)"
    (back)="goBack()"
  ></up-change-phone>
</ng-container>
