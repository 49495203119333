import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { DashboardTopics } from '@app/support/enums';

@Component({
  selector: 'how-to-dashboard-topics',
  templateUrl: './dashboard-topics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DashboardTopicsComponent {
  selectedTopic: DashboardTopics;
  topics = DashboardTopics;

  selectTopic(topic: DashboardTopics): void {
    this.selectedTopic = topic;
  }
}
