import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { FundingAccount, OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class FundingService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'funding');
  }

  getAccounts() {
    return this.get<FundingAccount[]>('accounts');
  }
}
