import { createSelector } from '@ngrx/store';

import { InvestAccount, MilestoneSummary } from '@app/axos-invest/models';

import { getInvestState } from '../invest.state';

export const getGoalSummary = createSelector(getInvestState, state => state.goalSummary);

export const getInvestAccount = createSelector(getInvestState, state => {
  if (!state.goalSummary.isLoaded) return setUninitializedAccount();

  const action = state.goalSummary.isClosed ? setInvestAccountClosed : setInvestAccount;

  return action(state.goalSummary);
});

const setUninitializedAccount = () => {
  return new InvestAccount({
    isLoaded: false,
    bankName: '-- Goals',
  });
};

const setInvestAccount = (goalSummary: MilestoneSummary) => {
  const investAccount = new InvestAccount({
    availableBalance: goalSummary.totalBalance,
    hasError: goalSummary.hasError,
    status: 'Active',
    isClosed: false,
    isLoaded: goalSummary.isLoaded,
  });
  const numberOfGoals = goalSummary.milestones?.filter(m => !m.isClosed).length;
  if (numberOfGoals === 0) {
    investAccount.bankName = '-- Goals';
  } else if (numberOfGoals > 1) {
    investAccount.bankName = `${numberOfGoals} Goals`;
  } else {
    investAccount.bankName = '1 Goal';
  }

  return investAccount;
};

const setInvestAccountClosed = (goalSummary: MilestoneSummary) => {
  return new InvestAccount({
    bankName: '0 Goals',
    availableBalance: 0,
    hasError: false,
    status: 'Closed',
    isClosed: true,
    isLoaded: goalSummary.isLoaded,
  });
};
