<table class="table">
  <thead>
    <tr class="table-head-row">
      <th class="date-column" (click)="sortByDate()">
        Date
        <i
          class="fa"
          *ngIf="lastSortedColumn === 'date'"
          [class.fa-caret-down]="!isAscendent"
          [class.fa-caret-up]="isAscendent"
        ></i>
      </th>
      <th class="category-icon-column" (click)="sortBy('category')" hidden="hideCategory">
        Category
        <i
          class="fa"
          *ngIf="lastSortedColumn === 'category'"
          [class.fa-caret-down]="!isAscendent"
          [class.fa-caret-up]="isAscendent"
        ></i>
      </th>
      <th class="description-column" (click)="sortBy('description', 'goal')">
        Description
        <i
          class="fa"
          *ngIf="lastSortedColumn === 'description-goal'"
          [class.fa-caret-down]="!isAscendent"
          [class.fa-caret-up]="isAscendent"
        ></i>
      </th>
      <th class="amount-column" (click)="sortBy('amount')">
        Amount
        <i
          class="fa"
          *ngIf="lastSortedColumn === 'amount'"
          [class.fa-caret-down]="!isAscendent"
          [class.fa-caret-up]="isAscendent"
        ></i>
      </th>
      <th class="options-column"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="transactions?.length > 0; else noTransactions">
      <ng-container *ngFor="let transaction of transactions">
        <tr
          app-transaction-row
          [transaction]="transaction"
          [class]="transaction.eventDescription"
          [unselectEvent]="unselectEvent"
          [hideCategory]="hideCategory"
          [hideOptions]="hideOptions"
          [isExpanded]="transaction.isExpanded"
          (rowSelected)="onSelectRow($event)"
          (onClickArrow)="expandRow(transaction)"
        ></tr>
        <tr *ngIf="transaction.isExpanded">
          <td colspan="5">
            <app-transaction-description [transaction]="transaction"></app-transaction-description>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    <ng-template #noTransactions>
      <tr class="no-transactions-row">
        <td colspan="5">
          <h3 class="text-center m-t-lg">
            No transactions meet your search criteria
          </h3>
        </td>
      </tr>
    </ng-template>
  </tbody>
</table>
