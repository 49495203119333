import { formatPhone } from '@app/utils';

import { Inject } from '../../decorators/decorators';
import { ITransactionService } from '../../services/typings/ITransactionService';
import { TransferSchedule } from '../typings/TransferSchedule';

@Inject('$scope', 'transactionService', 'popups')
export class EditSingleTransferController {
  isLoading: boolean;
  transferAccountId: number;
  transferAccount: TransferSchedule;
  userProfile: any;

  get userPhoneNumber() {
    return formatPhone(this.userProfile?.phoneNumber);
  }

  constructor(private _transactionService: ITransactionService, private _popups: IPopups) {}

  $onInit(): void {
    this.isLoading = true;

    this._transactionService
      .getScheduledTransfer(this.transferAccountId)
      .then(trans => {
        this.transferAccount = trans.data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Send the transaction modified to server
   */
  submit(): void {
    // TODO: Validation on amount, TBD

    this._transactionService.editSingleTransfer(this.transferAccount).then(response => {
      this.successTransfer(response);
    });
  }

  /**
   * Once a transfer is success display an alert
   */
  successTransfer(response: any): void {
    this._popups.showAlert('Bank X', response.data, 'success', () => {
      this.transferAccount = null;
      this.transferAccountId = null;
    });
  }
}
