import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  Uk2Tier1AlertsEnum,
  Uk2Tier1UtilityEnum,
  Uk2Tier2ColoredWithAccentEnum,
} from '@axos/uikit-v2-lib';
import { MOBILE_MAX_WIDTH } from './constants';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { TransactionFacade } from '../../../transactions/facade';
import { OrdersFacade } from '../../../orders/facade';
import { ScheduledTransfersFacade } from '../../../scheduled-transfers/facade';
import { TotalValueFacade } from '../../../total-value/facade/total-value.facade';
import { StatementsAndDocumentsFacade } from '../../../statements-and-documents/facade';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, combineLatest, zip } from 'rxjs';
import { PortfolioAllocationFacade } from '../../../portfolio-allocation/facade/portfolio-allocation.facade';
import { ModelsFacade } from '../../../models/facade';
import { HoldingFacade } from '../../../holdings/facade';
import { PortfolioAllocationTileState } from '../../../portfolio-allocation/core';

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details-page.component.html',
  styleUrls: ['./product-details-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsPageComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('appModelsHoldingsTabContainer')
  appModelsHoldingsTabContainer: ElementRef<HTMLDivElement> = new ElementRef(
    null
  );

  @ViewChild('mainLayoutReference', { static: true })
  mainLayoutReference: ElementRef<HTMLDivElement>;

  modelsHoldingsTabContainerHeightArea = 0;
  Uk2Tier1AlertsEnum = Uk2Tier1AlertsEnum;
  Uk2Tier2ColoredWithAccentEnum = Uk2Tier2ColoredWithAccentEnum;
  isRiaPilotTransactionOrderScheduledActive = false;
  isZeroStateTransactionOrderScheduled = false;
  isZeroStateStatementsAndDocuments = false;
  isZeroStatePortfolioAllocationTileAccount = true;
  isLoadingPortfolioAllocationTile$ = this.portfolioAllocationFacade.isLoading$.pipe(
    tap(() => {
      this.changeDetectorRef.detectChanges();
    })
  );
  isZeroStateModelsTileAccount = false;
  isZeroStateHoldingTileAccount = false;
  isRiaPilotPortfolioAllocationFlagActive = false;
  isLoadingTransactionOrderScheduledTiles = true;
  isLoadingStatementAndDocuments = true;
  isZeroStateForTotalValue = false;

  uk2TextTileSvgIconName = Uk2Tier1UtilityEnum.cog;
  uk2TextTileDisplayBackground = true;
  uk2TextTileIsStandaloneTile = true;

  portfolioAllocationFailedTileTitle =
    'Portfolio Allocation Temporarily Unavailable';
  portfolioAllocationTileAccountState$: Observable<
    PortfolioAllocationTileState
  >;
  readonly showAboutHoldingQuoteTile = this.featureFlagService.isWeb2FooterActive();
  totalValueFailedTileTitle = 'Total Value Temporarily Unavailable';
  totalValueFailedState$: Observable<boolean>;
  isPdpEnhancementOn: boolean = this.featureFlagService.isPdpEnhancementActive();
  private holdingsQuoteOpenSize = 110;
  private holdingsQuoteCloseSize = 43;
  private holdingsContainerExtraHeight = -this.holdingsQuoteOpenSize;

  private destroy$ = new Subject<void>();
  constructor(
    private featureFlagService: FeatureFlagService,
    private transactionFacade: TransactionFacade,
    private ordersFacade: OrdersFacade,
    private scheduledTransferFacade: ScheduledTransfersFacade,
    private totalValueFacade: TotalValueFacade,
    private statementsAndDocumentsFacade: StatementsAndDocumentsFacade,
    private portfolioAllocationFacade: PortfolioAllocationFacade,
    private modelsFacade: ModelsFacade,
    private holdings: HoldingFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.scrollToTop();
    this.isRiaPilotTransactionOrderScheduledActive = this.featureFlagService.isRiaPilotTransactionOrderScheduledActive();
    this.isRiaPilotPortfolioAllocationFlagActive = this.featureFlagService.isRiaPilotPortfolioAllocationFlagActive();
    if (this.isRiaPilotTransactionOrderScheduledActive) this.isZeroState();
    this.onResize();

    this.handleZeroState(this.isZeroState())
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    this.handleTransactionsOrdersScheduledLoading().subscribe();
    this.handleStatementsAndDocumentsLoading().subscribe();

    this.portfolioAllocationTileAccountState$ = this.portfolioAllocationFacade.portfolioAllocationTileAccountState$;

    this.totalValueFailedState$ = this.totalValueFacade.totalValueTileAccountState$.pipe(
      map(totalValueTileAccountState => !!totalValueTileAccountState.hasError)
    );
  }

  ngAfterViewChecked() {
    this.onResize();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize')
  onResize() {
    const screenWidth = window.innerWidth;
    this.modelsHoldingsTabContainerHeightArea = window.innerHeight - 307;
    if (!this.appModelsHoldingsTabContainer?.nativeElement) {
      return;
    }
    if (screenWidth > MOBILE_MAX_WIDTH) {
      this.appModelsHoldingsTabContainer.nativeElement.style.height =
        (window.innerHeight + this.holdingsContainerExtraHeight).toString() +
        'px';
    } else {
      this.appModelsHoldingsTabContainer.nativeElement.style.removeProperty(
        'height'
      );
    }
  }

  onToggleHoldingsQuote(toggleState: boolean) {
    if (toggleState) {
      this.holdingsContainerExtraHeight = this.holdingsQuoteCloseSize;
    } else {
      this.holdingsContainerExtraHeight = -this.holdingsQuoteOpenSize;
    }
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private handleStatementsAndDocumentsLoading() {
    return this.statementsAndDocumentsFacade.isLoadingAllDocuments$.pipe(
      takeUntil(this.destroy$),
      tap(isLoading => {
        this.isLoadingStatementAndDocuments = isLoading;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private handleTransactionsOrdersScheduledLoading() {
    return combineLatest([
      this.transactionFacade.isLoading$,
      this.ordersFacade.isLoading$,
      this.scheduledTransferFacade.isLoading$,
    ]).pipe(
      takeUntil(this.destroy$),
      tap(([transfersLoading, ordersLoading, scheduledTransfersLoading]) => {
        this.isLoadingTransactionOrderScheduledTiles =
          transfersLoading || ordersLoading || scheduledTransfersLoading;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private isZeroState() {
    const transactionObs$ = this.transactionFacade.transactionTileAccountState$.pipe(
      takeUntil(this.destroy$),
      filter(transactionTileState => transactionTileState !== undefined),
      map(
        transactionTileState => transactionTileState.transactions.length === 0
      )
    );

    const ordersObs$ = this.ordersFacade.ordersTileAccountState$.pipe(
      takeUntil(this.destroy$),
      filter(ordersTileState => ordersTileState !== undefined),
      map(ordersTileState => ordersTileState.orders.length === 0)
    );

    const scheduledTransferObs$ = this.scheduledTransferFacade.scheduledTransferTileAccountState$.pipe(
      takeUntil(this.destroy$),
      filter(
        scheduledTransferTileState => scheduledTransferTileState !== undefined
      ),
      map(
        scheduledTransferTileState =>
          scheduledTransferTileState.scheduledTransfers.length === 0
      )
    );

    const totalValueObs$ = this.totalValueFacade.totalValueTileAccountState$.pipe(
      takeUntil(this.destroy$),
      filter(totalValueTileState => totalValueTileState !== undefined),
      map(totalValueTileState => totalValueTileState.totalValue === 0)
    );

    const statementsAndDocumentsObs$ = this.statementsAndDocumentsFacade.selectedAccountAllDocuments$.pipe(
      takeUntil(this.destroy$),
      filter(
        statementsAndDocumentsTileState =>
          statementsAndDocumentsTileState !== undefined
      ),
      map(
        statementsAndDocumentsTileState =>
          statementsAndDocumentsTileState.statements.length === 0 &&
          statementsAndDocumentsTileState.taxForms.length === 0
      )
    );

    const portfolioAllocationTileAccountStateObs$ = this.portfolioAllocationFacade.portfolioAllocationTileAccountState$.pipe(
      takeUntil(this.destroy$),
      filter(
        portfolioAllocationTileAccountState =>
          !!portfolioAllocationTileAccountState
      ),
      map(
        portfolioAllocationTileAccountState =>
          portfolioAllocationTileAccountState.portfolioAllocations.length === 0
      )
    );

    const modelsTileAccountStateObs$ = this.modelsFacade.modelsTileAccountState$.pipe(
      takeUntil(this.destroy$),
      filter(modelsTileAccountState => !!modelsTileAccountState),
      map(
        modelsTileAccountState =>
          modelsTileAccountState.models.length === 0 &&
          !modelsTileAccountState.error
      )
    );

    const holdingTileAccountStateObs$ = this.holdings.holdingTileAccountState$.pipe(
      takeUntil(this.destroy$),
      filter(holdingTileAccountState => !!holdingTileAccountState),
      map(
        holdingTileAccountState =>
          holdingTileAccountState.holdings.length === 0 &&
          !holdingTileAccountState.error
      )
    );

    const observables = [
      transactionObs$,
      ordersObs$,
      scheduledTransferObs$,
      totalValueObs$,
      statementsAndDocumentsObs$,
      portfolioAllocationTileAccountStateObs$,
      modelsTileAccountStateObs$,
      holdingTileAccountStateObs$,
    ];
    return observables;
  }

  private handleZeroState(observables: Observable<boolean>[]) {
    return zip(...observables).pipe(
      tap(
        ([
          isTransactionEmpty,
          isOrdersEmpty,
          isScheduledTransferEmpty,
          isTotalValueZero,
          isStatementsAndDocumentsEmpty,
          isPortfolioAllocationTileAccountStateEmpty,
          isModelsTileAccountStateEmpty,
          isHoldingTileAccountStateEmpty,
        ]) => {
          this.isZeroStateTransactionOrderScheduled =
            isTransactionEmpty &&
            isOrdersEmpty &&
            isScheduledTransferEmpty &&
            isTotalValueZero;
          this.isZeroStateStatementsAndDocuments =
            this.isZeroStateTransactionOrderScheduled &&
            isStatementsAndDocumentsEmpty;
          this.isZeroStatePortfolioAllocationTileAccount = isPortfolioAllocationTileAccountStateEmpty;
          this.isZeroStateModelsTileAccount =
            isTransactionEmpty &&
            isOrdersEmpty &&
            isScheduledTransferEmpty &&
            isModelsTileAccountStateEmpty;
          this.isZeroStateHoldingTileAccount = isHoldingTileAccountStateEmpty;

          this.isZeroStateForTotalValue =
            !this.isZeroStateTransactionOrderScheduled &&
            !this.isZeroStateStatementsAndDocuments &&
            !this.isZeroStatePortfolioAllocationTileAccount &&
            !this.isZeroStateModelsTileAccount &&
            !this.isZeroStateHoldingTileAccount;
        }
      )
    );
  }
}
