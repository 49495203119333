import { CustomerLimitsHelper } from '@legacy/services/customer-limits.helper';

export function customerLimitsHelperFactory($injector: any) {
  return $injector.get('customerLimitsHelper');
}

export const customerLimitsProvider = {
  provide: CustomerLimitsHelper,
  useFactory: customerLimitsHelperFactory,
  deps: ['$injector'],
};
