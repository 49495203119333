import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WINDOW, STATE, ngRedux, olbSettings } from '@core/tokens';
import NgRedux from 'ng-redux';
import { IStateService } from 'angular-ui-router';
import { FacingBrand } from '@core/enums';
import { AccountTypeConstants } from '@app/messages/constants/account-type.constants';
import { CategoryEventData, SubCategoryEventData } from '@app/messages/models';

@Component({
  selector: 'app-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ComposeMessageComponent {
  public readonly BASE_STATE_URL = 'udb.messages.compose-message';
  public readonly SUBCATEGORIES = 'udb.messages.compose-message.subcategories';
  public isLoading: boolean;
  public isLoadingSub: boolean;
  public isLoadingBusinessSelection: boolean;
  public categories: Category[];
  public catId: any;
  public catName: any;
  public showSDTMessage = false;
  public subheader = 'All of your communication with the bank lives here.';
  public isDisplayMessageComponent: boolean = false;
  public subCategoryData: SubCategoryEventData;
  public categoryEventData: CategoryEventData;

  constructor(
    @Inject(STATE) private state: IStateService,
    @Inject(WINDOW) private window: Window,
    @Inject(ngRedux) private ngRedux: NgRedux.INgRedux,
    @Inject(olbSettings) private settings: OlbSettings
  ) {}
  ngOnInit(): void {
    if (this.settings.facingBrandId === FacingBrand.Axos) {
      this.subheader =
        'A secure location within your Axos account for communications with us.';
    }
    this.renderComponentBasedonURL();
  }

  public renderComponentBasedonURL() {
    if (this.window.location.pathname.indexOf('/subcategories') > -1) {
      this.renderSubCategories();
    } else if (this.window.location.pathname.indexOf('/message') > -1) {
      this.renderMessageComponent();
    } else {
      this.renderComposeMessage();
    }
  }

  /** Goes to the previous state in the Message Center flow */
  public goPrevious(): void {
    if (this.ngRedux.getState().funding.isRunning) {
      this.state.go('udb.funding.brokerage-form');

      return;
    }

    if (this.state.current.name === this.BASE_STATE_URL) {
      this.state.go('udb.messages.inbox');

      return;
    }
    if (this.state.current.name === this.SUBCATEGORIES) {
      //If there is only one business account, you are returned to the category selection page.
      // Otherwise you are returned to the business selection page.
      // index number 3 corresponds to the isSingleAccount parameter in the query string
      const isSingleAccount = Object.values(this.state.params)[3];
      if (/true/.test(isSingleAccount)) {
        this.goBack2StepsInHistory();
      } else {
        this.renderComposeMessage();
        this.goBackToHistory();
      }
      return;
    }
    if (this.state.current.name === `${this.BASE_STATE_URL}.message`) {
      this.renderSubCategories();
      this.goBackToHistory();

      return;
    }
    this.goBackToHistory();
  }

  public goBackToHistory(): void {
    this.window.history.back();
  }
  public goBack2StepsInHistory(): void {
    this.window.history.go(-2);
  }

  /**
   * Navigates to the required/next state
   * @param categoryId Category Id
   * @param categoryName Category name
   */
  public navigateTo(categoryId: number, categoryName: string): void {
    if (categoryName === AccountTypeConstants.BUSINESS_DEPOSIT_ACCOUNTS) {
      this.renderBusinessSelection();
      this.categoryEventData = {
        categoryId: categoryId,
        categoryName: categoryName,
      };
      this.state.go(`${this.BASE_STATE_URL}.business-selection`, {
        categoryId: categoryId,
        categoryName: categoryName,
      });
    } else {
      this.renderSubCategories();
      this.categoryEventData = {
        categoryId: categoryId,
        categoryName: categoryName,
      };
      this.state.go(`${this.BASE_STATE_URL}.subcategories`, {
        categoryId: categoryId,
        categoryName: categoryName,
      });
    }
  }
  public handleComposeMessageEvent(subcategories: SubCategoryEventData) {
    this.renderMessageComponent();
    this.subCategoryData = subcategories;
    this.state.go('udb.messages.compose-message.message', {
      subcategoryName: subcategories.subCategoryName,
      subcategoryId: subcategories.subCategoryId,
      categoryId: subcategories.categoryId,
      categoryName: subcategories.categoryName,
      businessId: subcategories.businessId,
    });
  }

  public enableSDTMessage(show: boolean) {
    this.showSDTMessage = show;
  }
  public handleBusinessSelectionEvent(businessData: CategoryEventData) {
    this.renderSubCategories();
    this.categoryEventData = businessData;
    this.state.go('udb.messages.compose-message.subcategories', businessData);
  }

  public renderSubCategories() {
    this.isLoadingSub = true;
    this.isLoadingBusinessSelection = false;
    this.isDisplayMessageComponent = false;
  }
  public renderBusinessSelection() {
    this.isLoadingBusinessSelection = true;
    this.isLoadingSub = false;
    this.isDisplayMessageComponent = false;
  }
  public renderMessageComponent() {
    this.isDisplayMessageComponent = true;
    this.isLoadingSub = false;
    this.isLoadingBusinessSelection = false;
  }
  public renderComposeMessage() {
    this.isDisplayMessageComponent = false;
    this.isLoadingSub = false;
    this.isLoadingBusinessSelection = false;
  }
}
