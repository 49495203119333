// @angular dependencies
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// App-specific dependencies
import * as jsCookie from 'js-cookie';

// Relative paths
import { OlbSettings } from '@core/models';
import { BusinessService } from '@core/services';
import { olbSettings, STATE } from '@core/tokens';
import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { AlertsIcons, NavigationIcons, UtilityIcons } from '@shared/enums';

interface AccountProfileWithIcon extends AccountProfile {
  iconUrl: string;
}

@Component({
  selector: 'app-switch-profile-modal',
  templateUrl: './switch-profile-modal.component.html',
  styleUrls: ['./switch-profile-modal.component.scss'],
})
export class SwitchProfileModalComponent implements OnInit {
  accountProfiles: AccountProfileWithIcon[];
  showIconCheck: number;
  profileColors = ['#1e3860', '#2f5b88', '#4d85b0', '#87b9d7', '#def4ff', '#f4f4f4'];
  icons = {
    add: UtilityIcons.Plus,
    circle: UtilityIcons.CircleCheck,
    check: AlertsIcons.CheckCircle,
    ex: AlertsIcons.ExCircle,
    arrow: NavigationIcons.ChevronLeft,
    up: NavigationIcons.ChevronUp,
    down: NavigationIcons.ChevronDown,
    close: NavigationIcons.Ex,
  };

  errorMessage = '';

  private svgUrl = 'assets/img/icons/business';

  constructor(
    private dialogRef: MatDialogRef<SwitchProfileModalComponent>,
    @Inject(olbSettings) private settings: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private businessService: BusinessService,
    @Inject(STATE) private state: ng.ui.IStateService
  ) { }

  ngOnInit(): void {
    const currentAccountProfile = JSON.parse(sessionStorage.getItem('currentAccountProfile')) as AccountProfileWithIcon;
    this.accountProfiles = (JSON.parse(sessionStorage.getItem('accountProfiles')) as AccountProfileWithIcon[])
    .filter((x) => x.id !== currentAccountProfile.id)
    .map(accountProfile => {
      accountProfile.iconUrl = this.getSvgIconUrl(accountProfile);

      return accountProfile;
    });
  }

  getSvgIconUrl(accountProfile: AccountProfile): string {
    const icon: string = accountProfile.profileType === AccountProfileType.Individual ? 'individual' : 'business';

    return `${this.svgUrl}/${this.settings.brand}-${icon}.svg`;
  }
  getProfileType(accountProfile: AccountProfile): string {
    if (accountProfile.profileType === AccountProfileType.Individual) {
      return 'Personal';
    } else {
      return 'Business';
    }
  }

  getProfileCircleColor(index) {
    const num = this.getColorFromArray(index);

    return this.profileColors[num];
  }

  getColorFromArray(index) {
    let i = index;
    if (i > this.profileColors.length - 1) {
      i = i - this.profileColors.length;
      if (i < this.profileColors.length) {
        return i;
      } else {
        return this.getColorFromArray(i);
      }
    } else {
      return i;
    }
  }

  selectAccountProfile(accountProfile: AccountProfileWithIcon, index): void {
    this.showIconCheck = index;
    accountProfile = { ...accountProfile, iconUrl: undefined };

    const prevProfile = JSON.parse(sessionStorage.getItem('currentAccountProfile')) as AccountProfile;
    sessionStorage.setItem('currentAccountProfile', JSON.stringify(accountProfile));

    jsCookie.set('SBB-TOKEN', sessionStorage.getItem('currentAccountProfile'), {
      Domain: this.settings.antiforgeryCookieDomain,
    });

    this.businessService.switchProfile(prevProfile)
    .subscribe(() => {
      this.dialogRef.close();
      this.state.go('udb.dashboard');
      this.reloadPage();
    }, (err) => {
      this.errorMessage = err.statusText;
    });
  }

  reloadPage() {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
