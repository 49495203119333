import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { NavigationIcons } from '@shared/enums';
import { DialogData } from '@shared/models';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  content: SafeHtml = null;
  backgroundUrl: string;
  hideCancel: boolean;
  svgStyle = {
    width: '5rem',
    height: '5rem',
    fill: 'white',
  };
  closeIcon = NavigationIcons.Ex;

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const { brand } = this.env;
    const { content } = this.data;

    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
    this.hideCancel = !this.data.cancelText;

    if (content) {
      this.content = this.domSanitizer.bypassSecurityTrustHtml(content);
    }
  }
}
