import { I_WINDOW } from '@core/tokens';

export function IWindowServiceFactory($inject: any) {
  return $inject.get('$window');
}

export const iWindowServiceProvider = {
  provide: I_WINDOW,
  useFactory: IWindowServiceFactory,
  deps: ['$injector'],
};
