import { IAccountsService } from 'services/typings/IAccountsService';
import { UserAction } from 'typings/app/UserAction';

import { Inject } from '../decorators/decorators';
import { MinorResponse } from '../minors/typing/minorResponse';
import { UserAccountType } from '../typings/app/bills/UserAccountType';
import { BaseService } from './base.service';
import { AccountDetails } from '@legacy/accounts/typings/AccountDetails';

@Inject('$http', 'env', 'serviceHelper', '$q')
class AccountsService extends BaseService implements IAccountsService {
  constructor(http: ng.IHttpService, env: OlbSettings, serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'accounts', serviceHelper, q);
  }

  getAccount(accountId: string): ApiResponse<OlbAccount> {
    return this.get(accountId);
  }

  getAccounts(withAmount: boolean = false): ApiResponse<OlbAccount[]> {
    return this.get(withAmount.toString());
  }

  getFromAccounts(): ApiResponse<OlbAccount[]> {
    return this.get('from');
  }

  getFromAccountsBoth(): ApiResponse<TypesAccounts> {
    return this.get('eligible/from');
  }

  getFromAccountsInternal(): ApiResponse<TypesAccounts> {
    return this.get('eligible/from?externalAccounts=false');
  }

  getToAccounts(accountId: number, isExternal: boolean = false): ApiResponse<TypesAccounts> {
    return this.get(`eligible/to/${accountId}`, { isExternal });
  }

  getWithdrawalCounter(accountId: number): ApiResponse<WithdrawalCounter> {
    return this.get(`${accountId}/withdrawalCounter`);
  }

  getMaximumGraceDate(accountNumber: number, accountType: string): ApiResponse<Date> {
    return this.get('gracedate', { accountNumber, accountType });
  }

  getAccountDetails(accountId: number): ApiResponse<AccountDetails> {
    return this.get(`${accountId}/detail`);
  }

  getClosedAccounts(): ApiResponse<OlbAccount[]> {
    return this.get('closed', null, true);
  }

  getSortedAccounts(): ApiResponse<AccountsPage> {
    return this.get('sorted');
  }

  getSortedLiteAccounts(): ApiResponse<AccountsPage> {
    return this.get('sorted/lite');
  }

  getSortedAccountsBalance(): ApiResponse<AccountsPage> {
    return this.get('sorted/balances');
  }

  getSortedAccountsIntercepts(accountsVm: AccountsPage): ApiResponse<AccountsPage> {
    return this.post('sorted/interceptors', accountsVm);
  }

  saveSortedAccounts(accountsDictionary: any): ApiResponse<any> {
    return this.post('sort', { accountsDictionary });
  }

  getLoanDetails(accountId: number): ApiResponse<NextPayment> {
    return this.get(`loans/${accountId}`);
  }

  updateNickname(accountId: number, nickname: string): ApiResponse<string> {
    return this.put('nickname', { id: accountId, nickname });
  }

  verifyExternalAccount(external: OlbAccount): ApiResponse<IVerifyLoginResponse> {
    return this.post('external/verify', external);
  }

  provideLoginCredentials(externalAccountLogin: ExternalAccountLogin): ApiResponse<ILoginCredentialsResponse> {
    return this.post('external/login', externalAccountLogin);
  }

  getExternalAccountLoginStatus(sessionId: string): ApiResponse<IExternalLoginStatusResponse> {
    return this.get(`external/status/${sessionId}`);
  }

  addExternalAccount(external: OlbAccount): ApiResponse<OlbAccount> {
    return this.post('external', external);
  }

  getExternalAccounts(): ApiResponse<ExternalAccount[]> {
    return this.get('external/true');
  }

  updateExternalAccount(externalAccountId: number, nickname: string): ApiResponse<void> {
    return this.put('external', { externalAccountId, nickname });
  }

  deleteExternalAccount(externalAccountId: number): ApiResponse<void> {
    return this.delete(`external/${externalAccountId}`);
  }

  getDepositAccounts(): ApiResponse<OlbAccount[]> {
    return this.get('deposit');
  }

  getRecurringPaymentFromAccounts(): ApiResponse<UserAccountType[]> {
    return this.get('billers');
  }

  activateDormantAccounts(dormantAccounts: OlbAccount[]): ApiResponse<number[]> {
    return this.put('activate', dormantAccounts);
  }

  logUserAction(action: UserAction): ApiResponse<void> {
    return this.post('audit', action);
  }

  addMinor(minor: Minor): ApiResponse<MinorResponse> {
    return this.post('minor', minor);
  }

  shouldShowDiraPrimaryBeneficiary(cif: string, taxPlanType: number): ApiResponse<boolean> {
    return this.get(`intercepts/tax-plans/${cif}/${taxPlanType}/primarybeneficiarymissing/should-display`);
  }

  diraPrimaryBeneficiaryDismissed(cif: string, taxPlanType: number): ApiResponse<void> {
    return this.post(`intercepts/tax-plans/${cif}/${taxPlanType}/primarybeneficiarymissing/dismissed`);
  }

  getRewardsInfoByAccountNumber(accountNumber: string): ApiResponse<any> {
    return this.get(`rewards/${accountNumber}`);
  }
}

export { AccountsService };
