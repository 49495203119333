<h3 class="mt-5 mb-4">How do I make a payment?</h3>

<ol>
  <li>
    Select the
    <b>Move Money tab.</b>
  </li>
  <li>
    Complete the form by selecting the account you’re paying from and to which loan account you want
    to pay.
  </li>
  <li>
    Select the payment frequency, the date to send the payment, and the amount you want to pay.
  </li>
  <li>
    Click on
    <b>Transfer Funds</b>
    and you will see a confirmation message when your transfer has been posted.
  </li>
</ol>
