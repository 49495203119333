import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';
import { AxosAdvisoryAccount } from '@core/models';

import { loadAxosAdvisoryAccounts, updateAasAccountDataAction } from './accounts.actions';

const _reducer = createReducer(
  null,
  on(loadAxosAdvisoryAccounts, (_state, action) => {
    return [...action.payload];
  }),
  on(updateAasAccountDataAction, (state, action) => {
    const updatedAccounts = state.map(account => {
      if (account.accountNumber === action.payload.accountNumber) {
        return { ...account, accountNickname: action.payload.accountNickname };
      }
      return account;
    });

    return updatedAccounts;
  }),
  on(signOut, () => {
    return null;
  })
);

export function axosAdvisoryAccountsReducer(state: AxosAdvisoryAccount[], action: Action) {
  return _reducer(state, action);
}
