import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConfirmationField } from '@shared/models';

@Component({
  selector: 'app-confirmation-table',
  templateUrl: './confirmation-table.component.html',
  styleUrls: ['./confirmation-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationTableComponent {
  @Input() fields: ConfirmationField[];

  constructor() {}
}
