import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-account-action',
  templateUrl: './account-action.component.html',
  styleUrls: ['./account-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountActionComponent {
  @Input() title: string;
  @Input() imageSource: string;
  @Input() busy: boolean;

  @Output() action = new EventEmitter();
}
