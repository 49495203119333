import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizationsTabComponent } from './view/authorizations-tab.component';
import { AdvisorContributionModule } from './advisor-contribution/advisor-contribution.module';
import { BankWireModule } from './bank-wire/bank-wire.module';
import { AdvisorDistributionModule } from './advisor-distribution/advisor-distribution.module';
import { ACHOneTimeModule } from './ach-one-time/ach-one-time.module';
import { AuthorizationsOtherModule } from './authorizations-other/authorizations-other.module';
import { AuthorizationsTabUk2Module } from './authorizations-tab-uk2.module';
import { AuthorizationsTabMatModule } from './authorizations-tab-mat.module';

@NgModule({
  declarations: [AuthorizationsTabComponent],
  imports: [
    CommonModule,
    AdvisorContributionModule,
    AdvisorDistributionModule,
    BankWireModule,
    ACHOneTimeModule,
    AuthorizationsOtherModule,
    AuthorizationsTabUk2Module,
    AuthorizationsTabMatModule,
  ],
  exports: [AuthorizationsTabComponent],
})
export class AuthorizationsTabModule {}
