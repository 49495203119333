import { NgModule } from '@angular/core';

import { Uk2SuggestedActionModule } from '@axos/uikit-v2-lib';
import { Uk2TextTileModule } from '@axos/uikit-v2-lib';

// * Use this module to export all @axos/uikit-v2 modules required for this feature
@NgModule({
  exports: [Uk2SuggestedActionModule, Uk2TextTileModule],
})
export class ProductDetailsPageUk2Module {}
