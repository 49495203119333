<div class="goal-card col-xs-12 col-sm-12 mb-4" *ngFor="let milestone of milestones">
  <div class="row col-12 mt-4">
    <div class="col-1">
      <app-svg-icon
        iconColor="#2F5B88"
        [iconName]="getGoalIcon(milestone.type)"
        size="3rem"
        class="goal-card__plus-icon"
      ></app-svg-icon>
    </div>
    <div class="col-9 col-lg-10 goal-card__text">
      <div class="goal-card__goal-text">
        Goal
      </div>
      <div class="goal-card__name">
        {{ milestone.name }}
      </div>
    </div>
    <div class="col-1 text-right">
      <button class="goal-card__btn-Goal" (click)="goToGoalSummary(milestone.id)">
        <app-svg-icon [iconName]="icons.iconChevronRight"></app-svg-icon>
      </button>
    </div>
  </div>
  <div class="row col-12">
    <div class="col-lg-2 col-sm-6">
      <div>
        Value
      </div>
      <div class="goal-card__text-value">
        {{ milestone.currentValue | currency }}
      </div>
    </div>
    <div class="col-lg-4 col-sm-6">
      <app-dot-loader *ngIf="!milestone.isLoadingChange">
        Loading Change
      </app-dot-loader>
      <div *ngIf="milestone.isLoadingChange">
        <div>
          Change
        </div>
        <div
          class="goal-day-change-content d-inline-flex"
          [class.equal]="milestone.dayChangePercentage === 0 || !milestone.dayChangePercentage"
          [class.positive]="milestone.dayChangePercentage > 0"
          [class.negative]="milestone.dayChangePercentage < 0"
        >
          <app-svg-icon
            class="day-change-label-btn-icon"
            [iconName]="
              milestone.dayChangePercentage === 0 || !milestone.dayChangePercentage
                ? icons.equal
                : milestone.dayChangePercentage > 0
                ? icons.triangleUp
                : icons.triangleDown
            "
            width="1.15em"
            height="1.15em"
            [iconColor]="
              milestone.dayChangePercentage === 0 || !milestone.dayChangePercentage
                ? '#494949'
                : milestone.dayChangePercentage > 0
                ? '#00805B'
                : '#fd0000'
            "
          ></app-svg-icon>
          <span class="goal-day-change-percentage">{{ milestone.dayChangePercentage | number: '1.0-2' }}%</span>
          <span class="goal-day-change-amount">{{ milestone.dayChangeAmount | currency }}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-sm-6" *ngIf="milestone.recurringDeposits && milestone.recurringDeposits.length !== 0">
      <div>
        Transfers
      </div>
      <div *ngFor="let recurringDeposit of milestone.recurringDeposits">
        {{ recurringDeposit.amount | currency }} {{ recurringDeposit.frequencyDescription }}
        <span *ngIf="milestone.recurringDeposits.length > 1">({{ recurringDeposit.accountName }})</span>
      </div>
    </div>
  </div>
  <div class="row col-12 mb-3">
    <div class="col-12">
      Goal
    </div>
    <div class="col-12">
      <div class="progress">
        <div class="progress-goal" [ngStyle]="getProgressBar(milestone.completion)"></div>
      </div>
    </div>
    <div class="row col-12 goal-card__values-text">
      <div class="col-10">{{ milestone.targetAmount | currency }} by {{ milestone.targetDate | date: 'yyyy' }}</div>
      <div class="col-2 text-right">{{ milestone.completion / 100 | percent }}</div>
    </div>
  </div>
</div>
