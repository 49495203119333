import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { STATE, STATE_PARAMS } from '@core/tokens';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us-view.component.html',
  styleUrls: ['./contact-us-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsViewComponent implements OnInit {
  categoryName: string;
  subCategoryName: string;
  businessId: number;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService
  ) {}

  ngOnInit() {
    this.categoryName = this.params['categoryName'];
    this.subCategoryName = this.params['subCategoryName'];
    this.businessId = this.params['businessId'];
  }

  /** Goes to the confirmation page once the message has been sent */
  goToConfirmation() {
    this.state.go('udb.support.confirmation');
  }
}
