<div class="electronic-address">
  <div class="header-section-title text-center hide-responsive">
    <h1>Add A Recipient</h1>
  </div>
  <div class="container responsive-container">
    <div class="row">
      <div class="col-md-2 hide-responsive">
        <a class="back" (click)="changeRouteState('udb.billPay.billingDetails')">
          <img
            [src]="'/img/icons/billPay/back_icon.svg'"
            width="15"
            alt="Favorite Recipient"
            aria-hidden="true"
            class="back-arrow"
          />
          <span>Back</span>
        </a>
      </div>
      <div class="col-md-12 cancel-setup-responsive">
        <span (click)="cancelSetup()" role="button">&#10005;</span>
      </div>
      <div class="col-md-8 text-center">
        <h4 class="legend-multi-address">
          We found multiple addresses for
          <span>{{ biller.name }}</span>
          . Please select the address you’d like to use.
        </h4>
      </div>
    </div>
    <div class="frow center wrap electroni-address-content">
      <div class="fcol-cs-12 fcol-md-8">
        <div class="row">
          <div class="col-md-12 address-container" *ngFor="let address of billerAddresses">
            <div class="address" (click)="selectAddress(address)">
              <h4>{{ biller.name }}</h4>
              <p>
                {{ address.addressLine1 }}, {{ address.city }}, {{ address.stateCode }}
                {{ address.zipCode }}
              </p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isBusy">
          <div class="fcol-cs-12">
            <app-dot-loader *ngIf="isBusy"></app-dot-loader>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="text-center manually">
              Can’t find the address you’re looking for?
              <span (click)="changeRouteState('udb.billPay.addCheckRecipient')">Manually enter address</span>
              for this recipient.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 actions text-center hide-responsive">
            <button type="button" class="uk-btn outline primary lg" [disabled]="isBusy" (click)="cancelSetup()">
              Cancel Setup
            </button>
          </div>
          <div class="col-md-12 actions text-center show-responsive">
            <button
              type="button"
              class="uk-btn outline primary lg"
              [disabled]="isBusy"
              (click)="changeRouteState('udb.billPay.billingDetails')"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
