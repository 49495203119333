<div class="details">
  <div class="details__header mb-3">
    <span class="details__header-text">{{ title }}</span>
    <div *ngIf="canEdit" class="header-edit">
      <a class="details__edit" (click)="edit.emit()">
        <app-svg-icon
          [iconName]="editIcon"
          [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
        ></app-svg-icon>
        <span class="details__edit-text">EDIT</span>
      </a>
    </div>
  </div>

  <div class="details__table">
    <div class="details__row" *ngFor="let detail of details">
      <span class="details__leftspan">{{ detail.key }}</span>
      <span class="details__rightspan">{{ detail.value }}</span>
    </div>
  </div>
</div>
