<app-dot-loader *ngIf="isInitiating; else enrollmentProxy">We are getting ready</app-dot-loader>

<ng-template #enrollmentProxy>
  <section [ngSwitch]="state.currentStep" class="form-group enrollment-view">
    <app-progress-bar [steps]="state.viewSteps" [currentStep]="state.currentStep" class="mb-5"></app-progress-bar>

    <app-create-username-proxy
      class="create-username col-12 col-md-8 col-xl-6"
      *ngSwitchCase="1"
      [header]="usernameHeading"
      [brandName]="brandName"
      (login)="goToLogin()"
      (next)="usernameNext($event)"
    ></app-create-username-proxy>

    <app-terms-and-conditions-proxy
      *ngSwitchCase="2"
      [isLoading]="isLoading"
      [brandName]="brandName"
      (agree)="agreeTermsAndConditions()"
    ></app-terms-and-conditions-proxy>

    <app-security-questions-proxy
      *ngSwitchCase="2.5"
      [username]="user.username"
      [brandName]="brandName"
      [isFinished]="areQuestionsAnswered"
      (save)="securityQuestionsSave()"
    ></app-security-questions-proxy>
  </section>
</ng-template>
