<div class="review-and-submit">
  <div class="header-section-title text-center">
    <div>
      <img class="hide-cs" src="img/icons/loan-payment/{{ loanPayment.accountIcon }}.svg" />
      <h1>Review and submit</h1>
      <h3 class="payment-account">{{ loanPayment.accountNickname }}</h3>
    </div>
  </div>

  <div class="content-section fcol-xs-8 fcol-xs-offset-2 fcol-cs-12">
    <div class="payment-header">
      <span>
        Please confirm your payment details before we initiate the payment
      </span>
    </div>

    <app-payment-details (onEdit)="goBack()" [title]="'Account Information'" [busy]="isBusy">
      <div class="box-content">
        <app-payment-detail-item *ngFor="let record of accountInformation">
          <left-side class="detail-item">
            <p>{{ record.name }}</p>
          </left-side>

          <right-side class="detail-item">
            <p>{{ record.value }}</p>
          </right-side>
        </app-payment-detail-item>
      </div>
    </app-payment-details>

    <app-payment-details (onEdit)="goBack()" [title]="'Schedule'" [busy]="isBusy" [payoff]="isPayoff" *ngIf="isPayoff">
      <div class="box-content">
        <app-payment-detail-item *ngFor="let record of scheduleInformation">
          <left-side class="detail-item">
            <p>{{ record.name }}</p>
          </left-side>
          <right-side class="detail-item">
            <p>{{ record.value }}</p>
          </right-side>
        </app-payment-detail-item>
      </div>
    </app-payment-details>

    <app-payment-details
      (onEdit)="goBack()"
      [title]="'Schedule and Recurring selections'"
      [busy]="isBusy"
      [payoff]="isPayoff"
      *ngIf="!isPayoff"
    >
      <div class="box-content">
        <app-payment-detail-item *ngFor="let record of scheduleInformation">
          <left-side class="detail-item">
            <p>{{ record.name }}</p>
          </left-side>
          <right-side class="detail-item">
            <p>{{ record.value }}</p>
          </right-side>
        </app-payment-detail-item>
      </div>
    </app-payment-details>

    <app-payment-details (onEdit)="goBack()" [title]="'Payments'" [payoff]="isPayoff" [busy]="isBusy">
      <div class="box-content">
        <app-payment-detail-item *ngFor="let record of paymentInformation">
          <left-side class="detail-item">
            <p>{{ record.name }}</p>
          </left-side>
          <right-side class="detail-item">
            <p>{{ record.value }}</p>
          </right-side>
        </app-payment-detail-item>
      </div>

      <div class="payment-total"></div>
      <div class="box-content">
        <app-payment-detail-item>
          <left-side class="detail-item">
            <p>{{ isPayoff ? 'Payoff Amount' : 'Total Amount' }}</p>
          </left-side>
          <right-side class="detail-item">
            <p>{{ totalAmount }}</p>
          </right-side>
        </app-payment-detail-item>
      </div>
    </app-payment-details>

    <div class="frow no-wrap-xs center">
      <app-dot-loader [showWhen]="isBusy"></app-dot-loader>
    </div>
    <div class="payment-buttons frow no-wrap-xs center" *ngIf="!isBusy">
      <app-dot-loader [showWhen]="isBusy"></app-dot-loader>
      <button type="button" class="uk-btn lg outline primary" (click)="goBack()">
        Back
      </button>
      <button type="submit" class="uk-btn lg solid secondary" (click)="submitPayment()">
        Submit Payment
      </button>
    </div>
    <p class="foot-note margin-top">
      Note: If you have already scheduled a payment for the current date it cannot be viewed or modified while in
      process and will reflect on your loan on the next business day.
    </p>
  </div>
</div>
