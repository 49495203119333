import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  ExternalAccount,
  ExternalAccountActivation,
  LoanPayoffQuote,
  PayoffDetailsResponse,
} from '@app/accounts/models';
import { AccountDetail, Beneficiary } from '@app/accounts/models';
import { RewardsDetail } from '@app/accounts/models/rewards-detail.model';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';
import { UserAction } from '@legacy/typings/app/UserAction';
import { MinorResponse } from '@app/minors/models';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'accounts');
  }

  /**
   * Activates an external account, it also communicates with payveris in the back end
   * @param externalAccount The account info to activate the account
   * @returns An Observable containing the response from the server.
   */
  activateExternalAccount(externalAccount: ExternalAccountActivation): WebApiResponse<ExternalAccount> {
    return this.post('external/activate', externalAccount);
  }

  getAccountDetails(accountId: number): WebApiResponse<AccountDetail> {
    return this.get(`${accountId}/detail`);
  }

  getRelationshipCatalog(): WebApiResponse<Record<string, string>> {
    return this.get('beneficiaries-base/catalogs/relationship');
  }

  getRewardsInfoByAccountNumber(accountNumber: string): WebApiResponse<RewardsDetail> {
    return this.get(`rewards/${accountNumber}`);
  }

  addBeneficiary(olbAccountId: number, beneficiary: Beneficiary): WebApiResponse<string> {
    return this.post(`${olbAccountId}/beneficiaries-base`, beneficiary);
  }

  editBeneficiary(olbAccountId: number, beneficiary: Beneficiary): WebApiResponse<undefined> {
    return this.put(`${olbAccountId}/beneficiaries-base`, beneficiary);
  }

  removeBeneficiary(olbAccountId: number, beneficiaryKey: string): WebApiResponse<undefined> {
    return this.post(`${olbAccountId}/beneficiaries-base/delete`, { key: beneficiaryKey });
  }

  updateNickname(olbAccountId: number, nickname: string) {
    return this.put<string>('nickname', { id: olbAccountId, nickname });
  }

  calculatePayoff(accountNumber: string, effectiveDate: string): WebApiResponse<PayoffDetailsResponse> {
    return this.get<PayoffDetailsResponse>('payoffCalculator', { accountNumber, effectiveDate });
  }

  getAccount(accountId: string): WebApiResponse<OlbAccount> {
    return this.get(accountId);
  }
  getAccounts(withAmount: boolean = false): WebApiResponse<OlbAccount[]> {
    return this.get(withAmount.toString());
  }
  updateExternalAccount(externalAccountId: number, nickname: string): WebApiResponse<void> {
    return this.put('external', { externalAccountId, nickname });
  }

  saveSortedAccounts(accountsDictionary: any): WebApiResponse<any> {
    return this.post('sort', { accountsDictionary });
  }
  getClosedAccounts(): WebApiResponse<OlbAccount[]> {
    return this.get('closed', null);
  }
  getExternalAccounts(): WebApiResponse<ExternalAccount[]> {
    return this.get('external/true');
  }

  generateLoanQuote(
    accountNumber: string,
    effectiveDate: Date,
    creationDate: Date,
    accountId: number,
    loanType: string
  ): WebApiResponse<undefined> {
    creationDate.setHours(0);
    creationDate.setMinutes(0);
    creationDate.setSeconds(0);
    creationDate.setMilliseconds(0);
    effectiveDate.setHours(0);
    effectiveDate.setMinutes(0);
    effectiveDate.setSeconds(0);
    effectiveDate.setMilliseconds(0);
    return this.post('loanQuote', {
      accountNumber: accountNumber,
      creationDate: creationDate.toLocaleDateString(),
      effectiveDate: effectiveDate.toLocaleDateString(),
      accountId: accountId.toString(),
      loanType: loanType,
    });
  }

  getActiveLoanPayoffQuote(accountId: number): WebApiResponse<LoanPayoffQuote> {
    return this.get<LoanPayoffQuote>(`loanQuote/activeQuote?accountId=${accountId}`);
  }
  logUserAction(action: UserAction): WebApiResponse<void> {
    return this.post('audit', action);
  }

  addMinor(minor: Minor): WebApiResponse<MinorResponse> {
    return this.post('minor', minor);
  }

  getSortedAccountsBalance(): WebApiResponse<AccountsPage> {
    return this.get('sorted/balances');
  }
}
