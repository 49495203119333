import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

@Injectable()
export class AxosInvestDeleteTransferService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'transfers/schedule');
  }

  deleteScheduledTransfers(transferSeriesId: string) {
    return this.delete<any>(`serie?isMP=true&transferSeriesId=${transferSeriesId}`);
  }
}
