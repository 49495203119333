import { Inject } from '../decorators/decorators';

@Inject('$window')
class UrlSearchParamsHelper implements IUrlSearchParamsHelper {
  constructor(private $window: ng.IWindowService) {}

  getSearchParam(name: string): string {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(this.$window.location.search);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}

export { UrlSearchParamsHelper };
