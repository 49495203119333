import { HoldingsSortDirection } from '../../const';
import { Holding, THoldingSortDirectionKeys, THoldingSortDirectionValues, THoldingSortingFamilies } from '../../store';
import { holdingSortingFunctionsFamilies } from '../holding-sorting-functions-family';

export function dynamicSortHoldingsAccount(
  sortBy: keyof Holding & string,
  sortDirection: THoldingSortDirectionValues,
  valueTypeComparison: Holding[keyof Holding]
): (modelA: Holding, modelB: Holding) => number {
  const comparisonDirectionType: THoldingSortDirectionKeys =
    sortDirection === HoldingsSortDirection.Ascending ? 'Ascending' : 'Descending';
  const family: keyof THoldingSortingFamilies =
    valueTypeComparison === 'string' ? 'stringFamily' : valueTypeComparison === 'number' ? 'numericFamily' : 'default';
  return holdingSortingFunctionsFamilies[family](sortBy)[comparisonDirectionType];
}
