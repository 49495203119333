import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { SharedModule } from '@shared/shared.module';

import { accountsSharedComponents } from './components';

@NgModule({
  imports: [MatDatepickerModule, SharedModule],
  declarations: [...accountsSharedComponents],
  exports: [SharedModule, ...accountsSharedComponents],
})
export class AccountsSharedModule {}
