import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InteractionStudioService {
  private CtaLink = new Subject<string>();
  private PublicProductPage = new Subject<string>();
  private angularJSServices: any;
  constructor() {
    this.angularJSServices = angular.element(document.body).injector().get('personalizedContentService');
    this.angularJSServices.hasContent.then(() =>
      this.angularJSServices.GetPersonalizedContent('UDB2', '/Products/Bank/Checking/Investor Checking').then(data => {
        this.PublicProductPage.next(
          data?.data?.data?.route?.placeholders?.main[0]?.placeholders?.ProductDetailItem[0]?.fields?.PublicProductPage
            ?.Url
        );
        this.CtaLink.next(
          data?.data?.data?.route?.placeholders?.main[0]?.placeholders?.ProductDetailItem[0]?.fields?.CtaLink?.Url
        );
      })
    );
  }

  getInvestorCheckingScreen(): Observable<string> {
    return this.PublicProductPage.asObservable();
  }

  getInvestorCheckingCtaLink(): Observable<string> {
    return this.CtaLink.asObservable();
  }
}
