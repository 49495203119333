import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import {
  BILLPAYHELPER,
  BILLPAYROUTERHELPER,
  BILLPAYSERVICE,
  SERVICEHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { STATE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { Recipient } from '@legacy/bill-pay/typings/Recipient';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-e-statements-accounts',
  templateUrl: './e-statements-accounts.component.html',
  styleUrls: ['./e-statements-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class EStatementsAccountsComponent implements OnInit {
  recipient: Recipient;
  recipientId: number;
  sessionId: string;
  accounts: BillerAccount[];
  errorMessage: string;

  isLoading: boolean;
  // private readonly _timeoutService: ITimeoutService;

  private selectBillerAccount: SelectBillerAccount = {};
  constructor(
    @Inject(BILLPAYSERVICE) private readonly _billPayService: IBillPayService,
    @Inject(STATE) private readonly _stateService: IStateService,

    @Inject(SERVICEHELPER) private readonly _serviceHelper: IServiceHelper,
    @Inject(BILLPAYROUTERHELPER) private readonly _billPayRouterHelper: BillPayRouterHelper,
    @Inject(BILLPAYHELPER) private readonly _billPayHelper: BillPayHelper
  ) {}
  ngOnInit(): void {
    this.recipient = this._billPayRouterHelper.getRecipientDetails();
    this.accounts = this._billPayRouterHelper.getAccounts();
    this.sessionId = this._billPayRouterHelper.getSessionId();

    this._serviceHelper.scrollToTop();
  }

  /**
   * Select an account for the eBill - Step 3
   */
  selectEBillAccount(account: BillerAccount): void {
    this.isLoading = true;
    this.selectBillerAccount.sessionId = this.sessionId;
    this.selectBillerAccount.account = account;
    this._billPayService
      .selectEBillAccount(this.selectBillerAccount)
      .then(response => {
        if (response.data.furtherActionRequired) {
          //check status again
          let timeout = new Date(response.data.callBackTime).getTime() - new Date(response.data.timestamp).getTime();
          setTimeout(() => {
            this._billPayHelper.checkForFurtherActions(response.data);
          }, timeout);
        } else {
          this.isLoading = false;
          //If we are following the add recipient flow, got to the recipient confirmation page
          let partialRecipient = this._billPayRouterHelper.getRecipient();
          if (partialRecipient) {
            partialRecipient.eStatementsStatus = true;
            this._stateService.go('udb.billPay.electronicConfirmation');
          } //go to confirmation page
          else this._stateService.go('udb.billPay.eStatements.confirmation');
        }
      })
      .catch(err => {
        this.errorMessage = err.data.message;
        this.isLoading = false;
      });
  }

  public goBack(): void {
    history.back();
  }

  public cancelSetUp() {
    this._billPayRouterHelper.cancelSetup();
  }
}
