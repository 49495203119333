import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig } from '@angular/material/legacy-dialog';
import { DialogService } from '@core/services';
import { Store } from '@ngrx/store';
import { AggregatedAccount } from '@app/accounts/models';
import { getInternalAccount, getInternalLoanAccountsList } from '@app/accounts/store/selectors';
import { OverdraftCreditLineModalComponent } from '../modals';
import { AccountCategory } from '@app/accounts/enums';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { STATE } from '@core/tokens';

@Component({
  selector: 'app-account-features',
  templateUrl: './investor-checking-account-features.component.html',
  styleUrls: ['./investor-checking-account-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingAccountFeaturesComponent implements OnInit, OnDestroy {
  @Input() accountId: number;
  account: AggregatedAccount;
  hasFeaturesToShow: boolean;
  private destroy$ = new Subject<void>();

  // Odl feature
  isRiaPilotOdlActive: boolean;
  accountHasOdl: boolean;
  odlAccountId: number;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    private featureFlagService: FeatureFlagService,
    private dialogService: DialogService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.hasFeaturesToShow = false;

    // Odl feature
    this.isRiaPilotOdlActive = false;
    this.accountHasOdl = false;
  }

  ngOnInit(): void {
    this.isRiaPilotOdlActive = this.featureFlagService.isRiaPilotODLActive();
    let account$ = this.store.select(getInternalAccount(AccountCategory.Dda, this.accountId)).pipe(
      filter(account => account != null),
      takeUntil(this.destroy$)
    );
    account$.subscribe(account => {
      this.account = account;
      if (this.isRiaPilotOdlActive) this.initializeOdlFeature();
      this.changeDetectorRef.markForCheck();
    });

    this.changeDetectorRef.markForCheck();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
  private initializeOdlFeature(): void {
    this.hasFeaturesToShow = true;
    let loanAccounts$ = this.store.select(getInternalLoanAccountsList).pipe(
      filter(loanAccounts => loanAccounts != null),
      takeUntil(this.destroy$)
    );
    loanAccounts$.subscribe(loanAccounts => {
      let odlAccount = loanAccounts.find(x => x.accountNumber === this.account.accountNumber);
      if (odlAccount != null) {
        this.accountHasOdl = true;
        this.odlAccountId = odlAccount.id;
      }
    });
  }

  goToODLAccountDetails() {
    this.state.go('udb.accounts.details', { id: this.odlAccountId, tab: 1 });
  }

  openOverdraftLineOfCreditCrossSellModal(event: any) {
    let checkbox = event.target;
    if (checkbox.checked) {
      const config: MatLegacyDialogConfig = {
        disableClose: true,
        data: {
          accountNickname: this.account.nickname,
          accountNumber: this.account.accountNumber,
        },
      };

      let dialogRef: MatDialogRef<any> = this.dialogService.openByComponentRef(
        OverdraftCreditLineModalComponent,
        config
      );

      dialogRef?.afterClosed().subscribe(_ => (checkbox.checked = false));
    }
  }
}
