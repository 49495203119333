<div class="details-tab">
  <div class="row">
    <div class="col-lg-4 space-down">
      <p class="title">Recipient Name</p>
      <p class="details space-down">{{ recipient.displayName }}</p>
      <div *ngIf="recipient.paymentMethod === 0">
        <p class="title">Account/Reference Number/Memo</p>
        <p class="details wrap-text" title="{{ recipient.paymentAccount }}">
          {{ recipient.paymentAccount || 'Check Payment' }}
        </p>
      </div>
      <div *ngIf="recipient.paymentMethod === 1">
        <p class="title">Account Number</p>
        <p class="details">
          <span>
            {{ showAccount ? recipient.paymentAccount : '****' + recipient.paymentAccountMask }}
          </span>
          <i class="show-account fa fa-eye{{ showAccount ? '-slash' : '' }}" (click)="toggleAccount()"></i>
        </p>
        <p class="account-disclaimer">
          For your protection, we show only part of your account number.
        </p>
      </div>
    </div>
    <div class="col-lg-4">
      <p class="title">Address</p>
      <p class="details">{{ recipient.billingAddress.addressLine1 }}</p>
      <p class="details">{{ recipient.billingAddress.addressLine2 }}</p>
      <p class="details space-down">
        {{ recipient.billingAddress.city }}, {{ recipient.billingAddress.stateCode }}
        {{ recipient.billingAddress.zipCode }}
      </p>
      <p class="title">Recipient Type</p>
      <p class="details">
        <span>{{ recipient.paymentMethod === 0 ? 'Check' : 'Electronic' }}</span>
        <app-tooltip class="details-help" [content]="detailsTooltip"></app-tooltip>
      </p>
    </div>
    <div class="col-lg-4 text-right">
      <button class="button button--primary button--outline arrow--right button--lg" (click)="goToEdit()">
        Edit Recipient
      </button>
    </div>
  </div>
</div>
