import * as angular from 'angular';
import * as moment from 'moment';

import { Inject } from '../../decorators/decorators';
import { ITransactionService } from '../../services/typings/ITransactionService';
import { mapFrequency } from '../transfers.controller';
import { TransferSchedule } from '../typings/TransferSchedule';

@Inject('$filter', '$uibModal', 'transactionService', 'serviceHelper')
export class UpcomingTransfersController {
  public isLoading: boolean;
  public headers: GenericOption[];
  public upcomingTransfers: TransferSchedule[];
  public filteredTransfers: TransferSchedule[];

  // Dropdowns for filters
  public filterFrom: GenericOption[];
  public filterTo: GenericOption[];
  public filterFrequency: GenericOption[];
  public filterAmount: GenericOption[];
  public filterDays: GenericOption[];
  public noData: boolean = true;

  // Selected options
  public selectedFrom: GenericOption;
  public selectedTo: GenericOption;
  public selectedFrequency: GenericOption;
  public selectedAmount: GenericOption;
  public selectedDays: GenericOption;

  public transferPeriodTotal: number = 0;

  //Option for transfers
  public currentTransfer: TransferSchedule;
  //Option for transfer edit
  public currentTransferEdit: TransferSchedule = {};

  public orderBy: string;
  public headerIdOrderBy: number;
  public reverse: boolean;

  constructor(
    private _filter: ng.IFilterService,
    private _modal: any,
    private _transactionService: ITransactionService,
    private _serviceHelper: IServiceHelper
  ) {}

  /** Initializes any controller required data. */
  public $onInit(): void {
    this.headers = [
      { value: 0, subvalue: 'beginSendingOn', label: 'Date' },
      { value: 1, subvalue: 'accountNameFrom', label: 'From account' },
      { value: 2, subvalue: 'accountNameTo', label: 'To account' },
      { value: 3, subvalue: 'frequency', label: 'Type' },
      { value: 4, subvalue: 'frequency', label: 'Frequency' },
      { value: 5, subvalue: 'amount', label: 'Amount' },
    ];

    this.filterFrom = [{ value: 0, label: 'From: All Accounts' }];

    this.filterTo = [{ value: 0, label: 'To: All Accounts' }];

    this.filterFrequency = [{ value: 0, label: 'All Frequencies' }];

    this.filterAmount = [{ value: 0, label: 'All Amounts' }];

    this.filterDays = [
      { value: 1, label: 'Next Day' },
      { value: 7, label: 'Next Week' },
      { value: 14, label: 'Next 2 Weeks' },
      { value: 30, label: 'Next 30 Days' },
    ];

    this._preselectFilterOptions();
    this._loadUpcomingTransfers(this.selectedDays.value);
  }

  /**
   * Filters the upcoming frequencies taking as criteria the selected options in the dropdowns
   */
  public filterUpcomingTransfers(): Function {
    return (row: TransferSchedule): boolean => {
      return (
        (+row.accountNumberFrom == this.selectedFrom.value || this.selectedFrom.value == 0) &&
        (+row.accountNumberTo == this.selectedTo.value || this.selectedTo.value == 0) &&
        (row.frequency == this.selectedFrequency.value || this.selectedFrequency.value == 0) &&
        (row.amount == this.selectedAmount.value || this.selectedAmount.value == 0)
      );
    };
  }

  /**
   * Filters the upcoming transfers by Days
   */
  public filterDaysChange(): void {
    this._loadUpcomingTransfers(this.selectedDays.value);
  }

  /**
   * Cancel a single transfer schedule
   */
  public cancelCurrentTransfer(): void {
    if (this.currentTransfer && this.currentTransfer !== null) {
      this._transactionService
        .cancelSingleScheduledTransfer(this.currentTransfer, false)
        .then(_res => {
          this._loadUpcomingTransfers(this.selectedDays.value);
          $('#CancelTransferConfirmation')['modal']('show');
        })
        .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
    }
  }

  /**
   * Remove a series of transfer funds
   */
  public removeTransferSeries(): void {
    if (this.currentTransfer && this.currentTransfer !== null) {
      this._transactionService
        .removeScheduledSeriesTransfer(this.currentTransfer)
        .then(_res => {
          this._loadUpcomingTransfers(this.selectedDays.value);
          $('#RemoveTransferSeriesConfirmation')['modal']('show');
        })
        .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
    }
  }

  /**
   * Select the transfer to be removed/canceled
   * @param trans The transfer object to be set as current/selected
   */
  public cancel(trans: TransferSchedule): void {
    this.currentTransfer = trans;
    this.currentTransfer.dateAddedString = moment().format('DD/MM/YYYY hh:mm:ss');
  }

  /**
   * Selects the transfer to be removed/canceled
   * @param trans The transfer object to be set as current/selected
   */
  public remove(trans: TransferSchedule): void {
    this.currentTransfer = trans;
    this.currentTransfer.dateAddedString = moment().format('DD/MM/YYYY hh:mm:ss');
  }

  /**
   * Selects the transfer to be edited (one single transfer)
   * @param trans The transfer object to be passed to the edition modal
   */
  public edit(trans: TransferSchedule): void {
    this._modal.open({
      animation: true,
      keyboard: true,
      controller: (_trans: TransferSchedule) => {},
      bindToController: true,
      controllerAs: '$ctrl',
      backdrop: 'static',
      templateUrl: 'transfers/modal-edit-single-transfer.tpl.html',
      size: 'md',
      resolve: {
        _trans: () => {
          return trans;
        },
        _isEditSeries: () => {
          return false;
        },
      },
    });

    this.currentTransferEdit = trans;
  }

  public vMapFrecuency(option: Number): string {
    switch (option) {
      case 1:
        return 'One Time';
      case 2:
        return 'Daily';
      case 3:
        return 'Weekly';
      case 4:
        return 'Monthly';
      case 5:
        return 'Every 2 Weeks';
      case 6:
        return 'Quarterly';
      case 7:
        return 'Semiannually';
      case 8:
        return 'Annually';
      case 9:
        return 'Hourly';
      default:
        return '-';
    }
  }

  /**
   * Selects the transfer (for a series) to be edited
   * @param trans The transfer object to be passed to the edition modal
   */
  public editSeries(trans: TransferSchedule): void {
    this._modal.open({
      animation: true,
      keyboard: true,
      controller: (_trans: TransferSchedule) => {},
      bindToController: true,
      controllerAs: '$ctrl',
      backdrop: 'static',
      templateUrl: 'transfers/upcoming-transfers/edit-upcoming-transfer.html',
      size: 'md',
      resolve: {
        _trans: () => {
          return trans;
        },
        _isEditSeries: () => {
          return true;
        },
      },
    });

    this.currentTransferEdit = trans;
  }

  /**
   * Sorting function in the header table
   * @param orderBy a string representing the name of the header to be sort by
   * @param headerId the actual header identifier for sorting
   */
  public sort(orderBy: string, headerId: number): void {
    this.orderBy = orderBy;
    this.headerIdOrderBy = headerId;
    this.reverse = !this.reverse;
  }

  /**
   * Calculates the Transfer Period Total, everytime we filter
   * the upcoming transfers as well
   */
  public calculateTransferPeriodTotal(): void {
    this.transferPeriodTotal = 0;
    angular.forEach(this.filteredTransfers, item => {
      this.transferPeriodTotal += item.amount;
    });
  }

  /**
   * Gets the message depending on criterias or default filters
   *
   * @returns {string} message
   */
  public messageOnNoData(): string {
    //this.isDefaultFilters = this.isDefaultFiltersSet();

    if (this.noData) {
      return 'You do not have any upcoming transfers within the ' + this.selectedDays.label.toLowerCase();
    }

    if (this.filteredTransfers == null || this.filteredTransfers.length == 0) {
      return 'No results match your selected search criteria';
    }

    return '';
  }

  /** Preselcts the options in filters */
  private _preselectFilterOptions(): void {
    this.selectedFrom = this.filterFrom[0];
    this.selectedTo = this.filterTo[0];
    this.selectedFrequency = this.filterFrequency[0];
    this.selectedAmount = this.filterAmount[0];
    this.selectedDays = this.filterDays[3];
  }

  /**
   * Load the upcoming transfers receiving as argument the days
   * @param days The days to count in the past to retrieve the upcoming transfers
   */
  private _loadUpcomingTransfers(days: number): void {
    this.isLoading = true;
    this._transactionService
      .getUpcomingTransfers(days)
      .then(res => {
        this.upcomingTransfers = res.data;
        this._fillOptionsFromToAmount();
        this.calculateTransferPeriodTotal();
        this.noData = res.data == null || res.data.length == 0;
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper))
      .finally(() => {
        this.isLoading = false;
      });
  }

  /** When loading the upcoming transfers, we attach the options in the dropdowns for filtering */
  private _fillOptionsFromToAmount(): void {
    angular.forEach(this.upcomingTransfers, item => {
      let existFromOption = this.filterFrom.filter(opt => {
        return opt.value == +item.accountNumberFrom;
      });
      let existToOption = this.filterTo.filter(opt => {
        return opt.value == +item.accountNumberTo;
      });
      let existAmouuntOption = this.filterAmount.filter(opt => {
        return opt.value == +item.amount;
      });
      let existFreqOption = this.filterFrequency.filter(opt => {
        return opt.value == item.frequency;
      });

      if (existFromOption.length == 0) {
        this.filterFrom.push({ value: +item.accountNumberFrom, label: item.accountNicknameFrom });
      }
      if (existToOption.length == 0) {
        this.filterTo.push({ value: +item.accountNumberTo, label: item.accountNicknameTo });
      }
      if (existAmouuntOption.length == 0) {
        this.filterAmount.push({
          value: item.amount,
          label: this._filter('currency')(item.amount),
        });
      }
      if (existFreqOption.length == 0) {
        this.filterFrequency.push({ value: item.frequency, label: mapFrequency(item.frequency) });
      }
    });
  }
}
