import { Component, Inject, Input, type OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { IStateService } from 'angular-ui-router';
import { STATE } from '@core/tokens';
import { BILLPAYROUTERHELPER, billPayServiceProvider } from '@app/bill-pay/ajs-upgraded-provider';
import { PaymentHistory, Recipient, ScheduledPayment } from '@app/bill-pay/typings';
import { BillPayRouterHelper } from '@app/bill-pay/utils';

@Component({
  selector: 'app-payments-tab',
  templateUrl: './payments-tab.component.html',
  styleUrls: ['./payments-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class PaymentsTabComponent implements OnInit {

  @Input()
  public recipient: Recipient;
  @Input()
  public paymentsHistory: PaymentHistory[];
  @Input()
  public scheduledPayments: ScheduledPayment[];
  @Output()
  public loadScheduledPayments = new EventEmitter<void>();
  public isEmpty: boolean;

  ngOnInit(): void {
    this.isEmpty = this.scheduledPayments.length == 0 && this.paymentsHistory.length == 0;
  }
  constructor(@Inject(BILLPAYROUTERHELPER) private readonly _billPayRouterHelper: BillPayRouterHelper,
    @Inject(STATE) private readonly _stateService: IStateService) {}

  public goToHistory(): void {
    this._billPayRouterHelper.setHistoryFilter(this.recipient.displayName);
    this._stateService.go('udb.billPay.activity');
  }

  public goToScheduledPayment(): void
  {
    this._stateService.go('udb.billPay.schedulePayment',{ recipient: this.recipient, callback: 'payments'});
  }

  public getIcon(payment: PaymentHistory) : string
  {
    return `/img/icons/mybills/${payment.paymentFrequency === 0 ? 'onetime-icon': 'recurring-icon'}.svg`;
  }

  public _loadScheduledPayments(){
    this.loadScheduledPayments.emit();
  }
}
