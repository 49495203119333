<p class="mb-4 text-center font-weight-normal col-12">
  Select questions that only you would know.
</p>

<app-alert-notification class="d-block mb-4" *ngIf="errorMessage" alertType="error" (dismiss)="errorMessage = null">
  {{ errorMessage }}
</app-alert-notification>

<form class="change-questions" [formGroup]="questionsForm" (ngSubmit)="changeQuestions()" *ngIf="questionsForm">
  <div class="form-group">
    <label for="question-1" class="uk-label">Question 1</label>
    <select formControlName="question1" name="question-1" id="question-1" class="uk-input">
      <option
        [value]="questionText"
        *ngFor="let questionText of firstQuestions"
        [innerText]="questionText + '?'"
      ></option>
    </select>
  </div>

  <div class="form-group" [class.invalid-field]="answer1.hasError('*')">
    <div class="reverse">
      <input type="password" id="answer-1" class="uk-input" formControlName="answer1" />
      <app-reveal-input-label target="answer-1" [targetIsInvalid]="answer1.hasError('*')">
        Your answer (not case-sensitive)
      </app-reveal-input-label>
    </div>

    <div class="mt-1 text-error" ngxErrors="answer1" #answer1="ngxErrors">
      <span [ngxError]="error.name" [innerHTML]="error.message" *ngFor="let error of errors.answer1"></span>
    </div>
  </div>

  <div class="form-group">
    <label for="question-2" class="uk-label">Question 2</label>
    <select formControlName="question2" name="question-2" id="question-2" class="uk-input">
      <option
        [value]="questionText"
        *ngFor="let questionText of secondQuestions"
        [innerText]="questionText + '?'"
      ></option>
    </select>
  </div>

  <div class="form-group" [class.invalid-field]="answer2.hasError('*')">
    <div class="reverse">
      <input type="password" id="answer-2" class="uk-input" formControlName="answer2" />
      <app-reveal-input-label target="answer-2" [targetIsInvalid]="answer2.hasError('*')">
        Your answer (not case-sensitive)
      </app-reveal-input-label>
    </div>

    <div class="mt-1 text-error" ngxErrors="answer2" #answer2="ngxErrors">
      <span [ngxError]="error.name" [innerHTML]="error.message" *ngFor="let error of errors.answer2"></span>
    </div>
  </div>

  <div class="form-group">
    <label for="question-3" class="uk-label">Question 3</label>
    <select formControlName="question3" name="question-3" id="question-3" class="uk-input">
      <option
        [value]="questionText"
        *ngFor="let questionText of thirdQuestions"
        [innerText]="questionText + '?'"
      ></option>
    </select>
  </div>

  <div class="form-group" [class.invalid-field]="answer3.hasError('*')">
    <div class="reverse">
      <input type="password" id="answer-3" class="uk-input" formControlName="answer3" />
      <app-reveal-input-label target="answer-3" [targetIsInvalid]="answer3.hasError('*')">
        Your answer (not case-sensitive)
      </app-reveal-input-label>
    </div>

    <div class="mt-1 text-error" ngxErrors="answer3" #answer3="ngxErrors">
      <span [ngxError]="error.name" [innerHTML]="error.message" *ngFor="let error of errors.answer3"></span>
    </div>
  </div>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="questionsForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
