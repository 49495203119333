import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig } from '@angular/material/legacy-dialog';
import { AccountsModalTexts } from '@app/accounts/enums';
import { DialogService } from '@core/services';
import { STATE, olbSettings } from '@core/tokens';
import { AlertsIcons, NavigationIcons } from '@shared/enums';
import { AddInvestorCheckingSuccessComponent, AddInvestorCheckingSuccessData } from '../add-investor-checking-success';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { AccountReviewModalComponent } from '../account-review-modal/account-review-modal.component';
import { OverdraftTermsConditionsComponent } from '@app/Areas/AAS/features/overdraft-credit-terms-conditions-modal/view/overdraft-credit-terms-conditions-modal';
import { EDisclosureModal } from '../modal-documents/e-disclosure/view/e-disclosure-modal.component';
import { PersonalDepositModal } from '../modal-documents/personal-deposit/view/personal-deposit-modal.component';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import {
  E_DISCLOSURE_STATEMENT_TITLE,
  ODL_TERMS_AND_CONDITIONS_TITLE,
  PERSONAL_DEPOSIT_ACCOUNT_AGREEMENT_TITLE,
  PRIVACY_NOTICE_TITLE,
  DISCLOSURE1_ID,
  DISCLOSURE2_ID,
  DISCLOSURE3_ID,
  DISCLOSURE4_ID,
  STATUS_APPROVED,
  STATUS_DECLINED,
  ACCOUNT_TYPE_DDX2,
  ERROR_DEFAULT_MESSAGE,
  ERROR_HEADER_TEXT,
  ERROR_OKAY_TEXT,
  ERROR_OKAY_BUTTON_CLASS,
  SUBMIT_BUTTON_TEXT,
  MODAL_WINDOW_CLASS,
  SUBMIT_BUTTON_TEXT_CONTEXT,
  DOCS_PANEL_CLASS,
  DOCS_MODAL_WIDTH,
} from './constants';
import { InvestorCheckingService } from '@app/Areas/AAS/aas-shared/services/investor-checking';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { filter } from 'rxjs/operators';
import { SubSink } from '@axos/subsink';
import { Store } from '@ngrx/store';
import { AddInvestorCheckingInReviewData } from '../account-review-modal/types';

@Component({
  selector: 'app-investor-checking-enrollment-modal',
  templateUrl: './investor-checking-enrollment-modal.component.html',
  styleUrls: ['./investor-checking-enrollment-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: '0' })),
      state('out', style({ opacity: '1' })),
      transition('in => out', animate('500ms ease-out')),
      transition('out => in', animate('500ms ease-in')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingEnrollmentModalComponent {
  accountModalTexts = AccountsModalTexts;
  isRiaPilotOdlActive: boolean;
  email: string;
  acknowledgementItems = [
    {
      id: DISCLOSURE1_ID,
      title: E_DISCLOSURE_STATEMENT_TITLE,
      link: this.settings.eDisclosureStatementAxos,
      selected: false,
      optional: false,
    },
    {
      id: DISCLOSURE2_ID,
      title: PERSONAL_DEPOSIT_ACCOUNT_AGREEMENT_TITLE,
      link: this.settings.personalDepositAccountAgreementAndScheduleOfFeesAxos,
      selected: false,
      optional: false,
    },
    {
      id: DISCLOSURE3_ID,
      title: PRIVACY_NOTICE_TITLE,
      link: this.settings.privacyNoticeAxos,
      selected: false,
      optional: false,
    },
  ];
  questionIcon = AlertsIcons.QuestionCircle;
  openIcon = NavigationIcons.ChevronDown;
  closeIcon = NavigationIcons.ChevronUp;
  openedAccordion = false;
  animationState = 'in';
  iconColor = 'var(--primary)';
  submitButton: HTMLInputElement;
  subsink = new SubSink();

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(olbSettings) private settings: OlbSettings,
    public dialogRef: MatDialogRef<InvestorCheckingEnrollmentModalComponent>,
    private aasInvestorCheckingService: InvestorCheckingService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private agreementsService: AgreementService,
    private serviceHelper: ServiceHelper,
    private featureFlagService: FeatureFlagService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.isRiaPilotOdlActive = this.featureFlagService.isRiaPilotODLActive();
    this.submitButton = <HTMLInputElement>document.getElementById('btnSubmit');
    this.checkAddOdlCheckbox();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  toggleChevron() {
    this.openedAccordion = !this.openedAccordion;
    this.animationState = this.animationState === 'in' ? 'out' : 'in';
  }

  validateAcceptedAgreements(controlId: any) {
    this.acknowledgementItems.find(control => control.id === controlId).selected = (<HTMLInputElement>(
      document.getElementById(controlId)
    )).checked;

    if (
      this.acknowledgementItems
        .filter(agreement => agreement.optional === false)
        .filter(acceptedAggr => acceptedAggr.selected).length ===
      this.acknowledgementItems.filter(mustAgreement => mustAgreement.optional === false).length
    ) {
      this.submitButton.disabled = false;
    } else {
      this.submitButton.disabled = true;
    }
  }

  submitAgreements() {
    let isOdlChecked = false;
    this.submitButton.disabled = true;
    this.submitButton.textContent = SUBMIT_BUTTON_TEXT_CONTEXT;

    if (this.isRiaPilotOdlActive) {
      if (this.acknowledgementItems.find(control => control.id === DISCLOSURE4_ID).selected) {
        isOdlChecked = true;
      }
    }

    if (isOdlChecked) {
      this.aasInvestorCheckingService
        .addInvestorCheckingAndOdlAccounts(false)
        .toPromise()
        .then(res => {
          this.handleSubmit(res, isOdlChecked);
        });
    } else {
      this.aasInvestorCheckingService
        .addInvestorCheckingAccount(false)
        .toPromise()
        .then(res => {
          this.handleSubmit(res, isOdlChecked);
        });
    }
    this.state.go('udb.dashboard');
  }

  private handleSubmit(res, isOdlChecked) {
    if (res.statusCode == 200 && res.data) {
      this.retrieveAccountEmail();

      switch (res.data.applicationStatus) {

        case STATUS_APPROVED:
          this.updateAgreement(false);
          
          var account = res.data.accounts.find(account => {
            if (account.productCode == ACCOUNT_TYPE_DDX2) {
              return account;
            }
          });

          var accountNickname = account.accountNumber.substring(
            account.accountNumber.length - 4
          )

          var data: AddInvestorCheckingSuccessData = {
            investorCheckingAccountNumber: account.accountNumber ? account.accountNumber : res.data.account.accountNumber,
            investorCheckingAccountNickname: accountNickname,
            wasOdlSelected: isOdlChecked,
            email: this.email,
          };

          this.dialogRef.close();
          this.dialogService.openByComponentRef(AddInvestorCheckingSuccessComponent, {
            data: data,
          });
          break;

        case STATUS_DECLINED:
          this.showErrorModal();
          break;
          
        default:
          // FLOW FOR ALL OTHER TYPE OF STATUS
          var reviewData: AddInvestorCheckingInReviewData = {
            confirmationNumber: res.data.confirmationNumber,
            email: this.email,
          };
          
          this.updateAgreement(false);
          this.dialogRef.close();
          this.dialogService.openByComponentRef(AccountReviewModalComponent, {
            data: reviewData,
          });
          break;
      }
    } else {
      this.showErrorModal();
    }
  }

  updateAgreement(value: boolean) {
    this.agreementsService
      .updateAgreementsByValue(value, AasAgreementType.MultiAccountAgreementInvestorChecking)
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  showErrorModal() {
    const defaultMessage = ERROR_DEFAULT_MESSAGE;
    const modalSettings: ModalSettings = {
      headerText: ERROR_HEADER_TEXT,
      bodyText: defaultMessage,
      okText: ERROR_OKAY_TEXT,
      hasCancelButton: false,
      hasIcon: false,
      hasHeaderText: true,
      okButtonClass: ERROR_OKAY_BUTTON_CLASS,
    };

    this.modalService
      .show({ windowClass: MODAL_WINDOW_CLASS }, modalSettings)
      .then(() => {})
      .catch(() => {});

    this.submitButton.disabled = false;
    this.submitButton.textContent = SUBMIT_BUTTON_TEXT;

    this.dialogRef.close();
  }

  showDocsModal(id: string): void {
    let docsComp;

    const config: MatLegacyDialogConfig = {
      width: DOCS_MODAL_WIDTH,
      panelClass: DOCS_PANEL_CLASS,
      disableClose: true,
    };

    switch (id) {
      case DISCLOSURE1_ID:
        docsComp = EDisclosureModal;
        break;
      case DISCLOSURE2_ID:
        docsComp = PersonalDepositModal;
        break;
      case DISCLOSURE3_ID:
        docsComp = EDisclosureModal;
        break;
      case DISCLOSURE4_ID:
        docsComp = OverdraftTermsConditionsComponent;
        break;
    }

    this.dialogService.openByComponentRef(docsComp, config);
  }

  private checkAddOdlCheckbox() {
    if (this.isRiaPilotOdlActive) {
      let odlDisclosureCheckboxItem = {
        id: DISCLOSURE4_ID,
        title: ODL_TERMS_AND_CONDITIONS_TITLE,
        link: this.settings.overdraftLineOfCreditTermsAndConditions,
        selected: false,
        optional: true,
      };

      this.acknowledgementItems.push(odlDisclosureCheckboxItem);
    }
  }

  private retrieveAccountEmail() {
    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(profileDetails => profileDetails && profileDetails.hasOwnProperty('primaryEmail')))
      .subscribe({
        next: profileDetails => {
          this.email = profileDetails.primaryEmail;
        },
      });
  }
}
