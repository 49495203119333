import { Inject } from '../../../decorators/decorators';
import { BillPayRouterHelper } from '../../bill-pay-router.helper';
import { Biller } from '../../typings/Biller';
import { IStateService } from 'angular-ui-router';
import { PartialRecipient } from 'bill-pay/typings/PartialRecipient';

@Inject('$state', 'billPayRouterHelper')
export class ElectronicCheckpointController {
  biller: Biller;
  recipient: PartialRecipient;
  showingMobileDetails: boolean;
  constructor(
    private readonly state: IStateService,
    private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}
  $onInit(): void {
    this.showingMobileDetails = false;
    this.biller = this.billPayRouterHelper.getBiller();
    this.recipient = this.billPayRouterHelper.getRecipient();
    if (!this.biller) this.state.go('udb.billPay.searchRecipient');
  }
  showEBillDetail() {
    this.showingMobileDetails = true;
    console.log(this.showingMobileDetails);
  }
  hideEBillDetails() {
    this.showingMobileDetails = false;
  }
}
