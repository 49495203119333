import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { SubSink } from '@axos/subsink';
import { AxosInvestUrlHelper } from 'services/axos-invest-urls.service';
import { TilesService } from 'services/tiles.service';

import { Goal, MilestoneSummary } from '@app/axos-invest/models';
import { getGoalSummary } from '@app/axos-invest/store/selectors';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

import { Inject } from '../../decorators/decorators';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';

@Inject(
  '$scope',
  '$element',
  'tilesService',
  'serviceHelper',
  '$state',
  'axosInvestUrlHelper',
  'ngrxStore',
  'featureFlagService',
  'env'
)
export class InvestMilestoneController extends BaseTile<InvestMilestoneTileSettings> {
  title: string;
  quickActionText = '';
  userMilestones: Goal[] = [];
  userMilestoneDdlOne: Goal[] = [];
  userMilestoneDdlTwo: Goal[] = [];
  userMilestonesSelected: Goal[] = [];
  hasAxosInvest: boolean;
  hasError = false;
  firstMilestoneSelected: Goal;
  secondMilestoneSelected: Goal;
  firstMilestone: Goal;
  secondMilestone: Goal;
  settings: number[] = [];
  isManagedPortfoliosEnabled: boolean;
  subSink = new SubSink();

  constructor(
    scope: ng.IScope,
    elm: ng.IRootElementService,
    tilesService: TilesService,
    serviceHelper: IServiceHelper,
    private state: ng.ui.IStateService,
    private readonly axosInvestUrlHelper: AxosInvestUrlHelper,
    private readonly store: Store,
    private readonly featureFlagService: FeatureFlagService,
    private readonly env: OlbSettings
  ) {
    super(scope, elm, tilesService, serviceHelper);
  }

  $onInit(): void {
    this.hasAxosInvest = Boolean(JSON.parse(sessionStorage.getItem('hasAxosInvest')));
    this.hasAxosInvest = true;
    this.isManagedPortfoliosEnabled = this.featureFlagService.isManagedPortfoliosEnabled();

    this.getSettings();
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(goalSummary => {

      if (goalSummary.milestones?.length > 0) {
        this.setInitialData(goalSummary);
      }
      this.isInitialising = false;
    });
  }

  $onDestroy() {
    this.subSink.unsubscribe();
  }

  actionMilestone(): void {
    const urlPromise = this.isManagedPortfoliosEnabled
      ? of(`${this.env.enrollmentInvestUrl}?products[]=MPO`).toPromise()
      : this.axosInvestUrlHelper.getCreateMilestoneUrl();
    urlPromise.then(url => {
      window.open(url, '_blank', 'noopener noreferrer');
    });
  }

  redirectToGoals(goal): void {
    const goalId = goal;
    this.state.go('udb.axosinvest', { id: goalId });
  }

  quickActionRedirect(): void {
    this.state.go('udb.accounts.container', { type: 'Invest' });
  }

  setInitialData(userMilestonesDetail: MilestoneSummary): void {
    this.hasError = userMilestonesDetail.hasError;

    if (userMilestonesDetail) {
      this.userMilestones = userMilestonesDetail.milestones.filter(x => !x.isClosed);
      if (userMilestonesDetail.wallet) {
        this.userMilestones = [...this.userMilestones, userMilestonesDetail.wallet];
      }

      this.userMilestoneDdlOne = this.userMilestones;
      this.userMilestoneDdlTwo = this.userMilestones;
      this.userMilestonesSelected = [];
      const milestoneNextInLine = [];

      if (this.tileSettings.Milestones && this.tileSettings.Milestones.value != null) {
        const settingsValues = this.tileSettings.Milestones.value.toString();

        if (!settingsValues.includes(',')) {
          this.userMilestonesSelected.push(this.userMilestones.find(x => x.id == settingsValues));

          if (this.userMilestonesSelected[0]) {
            this.firstMilestoneSelected = this.userMilestonesSelected[0];
            this.firstMilestone = this.userMilestonesSelected[0];
            milestoneNextInLine.push(this.userMilestones.find(x => x.id != settingsValues));
            if (milestoneNextInLine[0]) {
              this.userMilestonesSelected.push(milestoneNextInLine[0]);
              this.secondMilestoneSelected = milestoneNextInLine[0];
              this.secondMilestone = milestoneNextInLine[0];
            } else this.userMilestonesSelected.pop();
          } else {
            this.userMilestonesSelected = [];
            this.firstMilestoneSelected = this.userMilestones[0];
            this.firstMilestone = this.userMilestones[0];
            this.secondMilestoneSelected = this.userMilestones[1];
            this.secondMilestone = this.userMilestones[1];
          }
        } else {
          const settingsValuesProcessed = [];
          settingsValuesProcessed.push(this.tileSettings.Milestones.value.toString().split(',')[0]);
          settingsValuesProcessed.push(this.tileSettings.Milestones.value.toString().split(',')[1]);

          settingsValuesProcessed.forEach(value => {
            this.userMilestonesSelected.push(this.userMilestones.find(x => x.id == value));
          });

          if (!this.userMilestonesSelected[0] && !this.userMilestonesSelected[1]) {
            this.userMilestonesSelected = [];
            this.firstMilestoneSelected = this.userMilestones[0];
            this.firstMilestone = this.userMilestones[0];
            this.secondMilestoneSelected = this.userMilestones[1];
            this.secondMilestone = this.userMilestones[1];
          } else if (this.userMilestonesSelected[0] && this.userMilestonesSelected[1]) {
            this.firstMilestoneSelected = this.userMilestonesSelected[0];
            this.firstMilestone = this.userMilestonesSelected[0];
            this.secondMilestoneSelected = this.userMilestonesSelected[1];
            this.secondMilestone = this.userMilestonesSelected[1];
          } else {
            if (this.userMilestonesSelected[0] && !this.userMilestonesSelected[1]) {
              this.userMilestonesSelected.pop();
            } else if (!this.userMilestonesSelected[0] && this.userMilestonesSelected[1]) {
              this.userMilestonesSelected.shift();
            }

            this.firstMilestoneSelected = this.userMilestonesSelected[0];
            this.firstMilestone = this.userMilestonesSelected[0];

            this.userMilestonesSelected.push(this.userMilestones.find(x => x.id != this.userMilestonesSelected[0].id));
            if (this.userMilestonesSelected[1]) {
              this.secondMilestoneSelected = this.userMilestonesSelected[1];
              this.secondMilestone = this.userMilestonesSelected[1];
            } else this.userMilestonesSelected.pop();
          }
        }
      } else {
        this.userMilestonesSelected = this.userMilestones;

        this.firstMilestoneSelected = this.userMilestonesSelected[0];
        this.firstMilestone = this.userMilestonesSelected[0];

        this.secondMilestoneSelected = this.userMilestonesSelected[1];
        this.secondMilestone = this.userMilestonesSelected[1];
      }
    } else {
      this.userMilestones = [];
      this.userMilestonesSelected = [];
    }
    // Wallet does not count as a milestone
    const userMilestonesWithoutWallet = this.userMilestonesSelected.filter(function (milestone) {
      return milestone.type !== 7;
    });

    const milestoneOrGoal = this.isManagedPortfoliosEnabled ? 'Goal' : 'Milestone';

    if (userMilestonesWithoutWallet.length === 1 || userMilestonesDetail.hasError) {
      this.title = `Investment ${milestoneOrGoal}*`;
      this.quickActionText = 'View My Account';
    } else if (userMilestonesWithoutWallet.length > 1) {
      this.title = `Investment ${milestoneOrGoal}s*`;
      this.quickActionText = 'View My Account';
    } else if (userMilestonesWithoutWallet.length === 0) {
      this.title = `Investment ${milestoneOrGoal}*`;
    }

    this.onChange();
  }

  onChange(): void {
    this.userMilestoneDdlOne = this.userMilestones.filter(X => X.id != this.secondMilestoneSelected.id);
    this.userMilestoneDdlTwo = this.userMilestones.filter(Y => Y.id != this.firstMilestoneSelected.id);
  }

  onCancel(): void {
    this.firstMilestoneSelected = this.firstMilestone;
    this.secondMilestoneSelected = this.secondMilestone;
    this.onChange();
  }

  saveTileSettings(): void {
    if (this.secondMilestoneSelected) {
      this.tileSettings.Milestones.value = this.firstMilestoneSelected.id + ',' + this.secondMilestoneSelected.id;
      this.saveSettings();
      this.firstMilestone = this.firstMilestoneSelected;
      this.secondMilestone = this.secondMilestoneSelected;
    } else {
      this.tileSettings.Milestones.value = this.firstMilestoneSelected.id;
      this.saveSettings();
      this.firstMilestone = this.firstMilestoneSelected;
    }
  }
}

interface InvestMilestoneTileSettings extends BaseTileSettings {
  Milestones?: TileSetting;
}
