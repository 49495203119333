<ng-container *ngIf="primaryBeneficiaries && primaryBeneficiaries.length > 0; else emptyState">
  <ng-container *ngFor="let primaryBeneficiary of primaryBeneficiaries; let i = index">
    <div class="section-header">Primary Beneficiary {{ primaryBeneficiaries.length > 1 ? '#' + (i + 1) : '' }}</div>
    <div class="list">
      <div class="list-row">
        <span class="title">Name</span>
        <span class="value">{{ primaryBeneficiary.name }}</span>
      </div>
      <div class="list-row">
        <span class="title">Date of Birth</span>
        <span class="value">
          {{ primaryBeneficiary.birthDate }}
        </span>
      </div>
      <div class="list-row">
        <span class="title">Percentage</span>
        <span class="value">{{ primaryBeneficiary.primaryPercentage + '%' }}</span>
      </div>
      <div class="list-row">
        <span class="title">Relationship</span>
        <span class="value">{{ primaryBeneficiary.spouse ? 'Spouse' : 'Other' }}</span>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyState>
  <div class="section-header">
    Primary Beneficiary
  </div>
  <div class="list">
    <div class="list-row">Not Yet Assigned</div>
  </div>
</ng-template>
