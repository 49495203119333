import { Action, createReducer, on } from '@ngrx/store';

import { MilestoneSummary } from '@app/axos-invest/models';
import { signOut } from '@app/store/actions';

import { loadGoalSummary, loadGoalSummaryWithError, updateGoal } from './goal-summary.actions';

export const goalSummaryInitialState = new MilestoneSummary({
  milestones: [],
  accounts: [],
  totalBalance: 0,
  isLoaded: false,
});

const reducer = createReducer(
  goalSummaryInitialState,
  on(loadGoalSummary, (_state, action) => {
    return new MilestoneSummary({
      ...action.payload,
      isLoaded: true,
    });
  }),
  on(loadGoalSummaryWithError, () => {
    return new MilestoneSummary({
      milestones: [],
      accounts: [],
      totalBalance: 0,
      hasError: true,
      isLoaded: true,
    });
  }),
  on(updateGoal, (state, action) => {
    const indexOfUpdatedGoal = state.milestones.findIndex(m => m.id === action.payload.id);
    const milestones = JSON.parse(JSON.stringify(state.milestones));
    if (indexOfUpdatedGoal >= 0) {
      milestones[indexOfUpdatedGoal] = action.payload;
    }

    return new MilestoneSummary({
      ...state,
      milestones: [...milestones],
    });
  }),
  on(signOut, () => {
    return {};
  })
);

export function goalSummaryReducer(state: any, action: Action) {
  return reducer(state, action);
}
