import { APP_INITIALIZER } from '@angular/core';
import { noop } from 'rxjs';

import { appendStylesheet, loadLegacyStylesheets } from '@app/utils';
import { ConfigService, ScriptLoaderService } from '@core/services';

export function appInitializerFactory(configService: ConfigService, scriptLoaderService: ScriptLoaderService) {
  return () =>
    configService.loadConfig().then(config => {
      const { brand, version } = config;
      const varsStylesheet = `assets/${brand}/css/variables.css?v=${version}`;
      appendStylesheet(`assets/invest/variables.css`);
      appendStylesheet(`assets/global-variables.css?v=${version}`);
      appendStylesheet(varsStylesheet);
      loadLegacyStylesheets(brand, version);

      const faviconLinkTag = document.getElementById('favicon') as HTMLLinkElement;
      faviconLinkTag.href = `assets/${config.brand}/favicon.ico`;
      scriptLoaderService.load('assets/vendors/pendo.js').then(noop);
    });
}

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: appInitializerFactory,
  deps: [ConfigService, ScriptLoaderService],
  multi: true,
};
