import { Inject } from 'decorators/decorators';
import { AccountAggregationService } from 'services/account-aggregation.service';
import Category from 'typings/app/account-aggregation/Category';

@Inject('accountAggregationService', '$scope')
export default class CategoryFilterController {
  showPopover = false;
  categoriesSelected = 0;
  loadingCategories = false;
  onValueChange: Function;

  private categories: Category[];
  private listeners: (() => void)[] = [];

  constructor(
    private _accountAggregationService: AccountAggregationService,
    private _$scope: ng.IScope
  ) {}

  $onInit() {
    this.categories = [];
    this.loadingCategories = true;
    this._accountAggregationService
      .getCategories()
      .then((response: OlbResponse<Category[]>) => {
        let categories = response.data;
        categories = this.setupCategory(categories.filter(c => c.name.toLowerCase() !== 'pending'));
        this.categories = categories;
        this.loadingCategories = false;
      })
      .catch(() => {
        this.categories = [];
        this.loadingCategories = false;
      });

    this.listeners.push(
      this._$scope.$on('toggleCategoryFilter', (_, categoryId: number) => {
        const category = this.categories.find(c => c.id === categoryId);
        if (!category) return;

        category.active = !category.active;
        this.checkCategoriesSelected();
      })
    );
  }

  $onDestroy() {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  isFilterActive() {
    return this.categories.filter(c => c.active).length;
  }

  checkCategoriesSelected() {
    this.categoriesSelected = this.categories.filter(c => c.active).length;
    this.triggerCategoriesChanges();
  }

  clearCategories() {
    this.categories.forEach(a => (a.active = false));
    this.categoriesSelected = 0;
    this.triggerCategoriesChanges();
    this.showPopover = false;
  }

  setupCategory(categories: Category[]): Category[] {
    categories.forEach(c => {
      const name = c.name.toLowerCase();
      const idx = name.indexOf(' ');
      c.logoName = idx < 0 ? name : name.substring(0, idx);
      c.active = false;
    });

    return categories;
  }

  private triggerCategoriesChanges() {
    const activeCategories = this.categories.filter(ca => ca.active).map(c => c.id);
    this.onValueChange({ value: [...activeCategories] });
  }
}
