<div class="atp__authorize-transfer">
  <mat-accordion>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      [disabled]="panelOpenState"
      [ngClass]="{ authorized: authorizeSome() || authorizeAllAdvisors }"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-description (click)="openPanel($event)">
          <div class="atp__authorize-transfer--header">
            <div class="atp__authorize-transfer--logo">
              <img [src]="account.logo" />
            </div>
            <div class="atp__authorize-transfer--account-description">
              <div class="bank-name">{{ account.bankName }}</div>
              <div class="account-name">{{ account.accountName }}</div>
            </div>
            <div class="atp__authorize-transfer--checkbox">
              <mat-checkbox
                uk2Checkbox
                [checked]="authorizeAllAdvisors"
                [indeterminate]="authorizeSome()"
                (change)="authorizeAll($event.checked)"
              ></mat-checkbox>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="atp__authorize-transfer__advisors">
        <ul class="atp__authorize-transfer__advisors--list">
          <li class="atp__authorize-transfer__advisors--list-item" *ngFor="let advisor of account.advisors">
            <div class="atp__authorize-transfer--header">
              <div class="atp__authorize-transfer--logo">
                <img [src]="advisor.logo" />
              </div>
              <div class="atp__authorize-transfer--account-description">
                <div class="bank-name">{{ advisor.name }}</div>
                <div class="account-name">Authorize transfers</div>
              </div>
              <div class="atp__authorize-transfer--checkbox">
                <mat-checkbox
                  uk2Checkbox
                  [(ngModel)]="advisor.authorizeTransfer"
                  (ngModelChange)="updateAllAuthorized()"
                ></mat-checkbox>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
