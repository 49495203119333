import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AdvisorDistributionAuthorityType } from '@app/Areas/AAS/features/account-details/core';
import { AuthorizationsFacade } from '@app/Areas/AAS/features/account-details/facade/authorizations.facade';
import { format, parseISO } from 'date-fns';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BLANK_FORMAT, DATE_FORMAT } from './constants';
@Component({
  selector: 'app-advisor-distribution',
  templateUrl: './advisor-distribution.component.html',
  styleUrls: ['./advisor-distribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvisorDistributionComponent {
  isLoading = true;
  destroy$ = new Subject<void>();
  authorizationsTabState: AdvisorDistributionAuthorityType;
  blankFormat = BLANK_FORMAT;

  constructor(private authorizationsFacade: AuthorizationsFacade, private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.authorizationsFacade.authorizationsTabAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(data => {
        this.authorizationsTabState = data?.advisorDistributionAuthority;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormattedDate(value): string {
    return value || value != '' ? format(parseISO(value as any), DATE_FORMAT) : null;
  }

  getFormattedYesNo(value): string {
    return value != undefined ? (value ? 'Yes' : 'No') : null;
  }
}
