<div class="tx-options">
  <button
    *ngIf="!isExpanded && options.length && !isDetailsExpanded"
    type="button"
    class="btn-link tx-options__trigger dropdown-toggle"
    #txOptionsTrigger="matMenuTrigger"
    [matMenuTriggerFor]="TxOptions"
    [disabled]="isDisabled"
    (click)="moreOptionsClicked()"
  >
    <i aria-hidden="true">&bull;&bull;&bull;</i>
    <span class="options-tooltip">More Options</span>
  </button>
  <button
    type="button"
    *ngIf="isExpanded || isDetailsExpanded"
    class="btn-link tx-options__collapse"
    (click)="collapse()"
  >
    <i class="bofi-arrow-chevron" aria-hidden="true"></i>
  </button>

  <mat-menu class="tx-option-menu" #TxOptions>
    <div class="tx-options__list">
      <button
        mat-menu-item
        *ngFor="let opt of options"
        disableRipple
        role="menu-item"
        [ngClass]="{ divider: opt.isDivider }"
        (click)="executeAction(opt)"
      >
        <a class="link" *ngIf="!opt.isDivider">
          <i [ngClass]="opt.icon" *ngIf="opt.icon"></i>
          <span>{{ opt.label }}</span>
        </a>
      </button>
    </div>
  </mat-menu>
</div>
