<h5 [innerText]="title"></h5>

<app-update-nickname
    *ngIf="nicknameRow"
    [title]="nicknameRow.title"
    [content]="nicknameRow.content"
    [accountId]="nicknameRow.accountId"
    (updateNickName) ="updateNickNameEvent()"
  >
</app-update-nickname>
<div *ngFor="let row of otherRows">
  <div  class="detail-row">
    <p class="detail-row__label" [innerText]="row.title"></p>
    <p class="detail-row__content" [innerText]="row.content"></p>
  </div>
</div>


