import { EditPortfolioStep } from '@app/axos-invest/enums';

export const EDIT_PORTFOLIO_CONTENT = {
  [EditPortfolioStep.AllocationSelector]: {
    title: 'Edit your current and end target allocations of stocks, bonds and cash.',
  },
  [EditPortfolioStep.Form]: {
    title: 'Enter a cash allocation. Adjust the slider to change stock and bond allocation.',
    subtitle: 'Note: Editing cash automatically adjusts stocks and bonds with what remains.',
  },
};
