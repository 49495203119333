<div class="row mt-4">
  <div class="col-sm-9">
    <ul>
      <li *ngFor="let menu of menuOverview" (click)="displayMenu(menu.id)">
        <span class="menu" [class.active]="isActive === menu.id">
          {{ menu.name }}
        </span>
      </li>
    </ul>

    <div class="mt-5">
      <ng-container [ngSwitch]="isActive">
        <div *ngSwitchCase="menuOverviewEnum.AllActivity">
          <app-filters (filtersUpdated)="getAllActivities($event)"></app-filters>
          <app-dot-loader *ngIf="isLoadingActivity">Loading</app-dot-loader>
          <app-table-activity [hidden]="isLoadingActivity" [transactions]="allActivity"></app-table-activity>
        </div>
        <div *ngSwitchCase="menuOverviewEnum.OpenOrders">
          <app-filters (filtersUpdated)="getOpenOrders($event)"></app-filters>
          <app-dot-loader *ngIf="isLoadingOpenTransactions">Loading</app-dot-loader>
          <app-table-orders
            [hidden]="isLoadingOpenTransactions"
            [transactions]="openTransactions"
            [accountId]="accountId"
          ></app-table-orders>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="col-sm-3 account-information">
    <app-right-navigation (changeTab)="goToTab($event)"></app-right-navigation>
  </div>

  <div class="ml-4 mr-4 mt-5 col">
    <app-feature-link></app-feature-link>
  </div>
</div>
