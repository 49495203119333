import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  SharedAccountsByAdvisorRequest,
  SharedAccountsByAdvisorResponse,
  SharedAccountsRequest,
  SharedAccountsResponse,
  SharedAccountsService,
} from '../core/services/ria-account-for-sharing-response';
import {
  addSharedAccounts,
  addSharedAccountsByAdvisor,
  addUpdateSharedAccounts,
  addUpdateSharedAccountsByAdvisor,
  SharedAccountsStateType,
} from '../core';
import {
  getSharedAccounts,
  getSharedAccountsByAdvisor,
  getSharedAccountsPrompting,
} from '../core/store/selector/shared-accounts.selector';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedAccountsFacade {
  addSharedAccountsResult$ = new Subject<boolean>();
  sharedAccounts$ = this.store.select(getSharedAccounts);
  sharedAccountsPrompting$ = this.store.select(getSharedAccountsPrompting);
  sharedAccountsByAdvisor$ = this.store.select(getSharedAccountsByAdvisor);

  constructor(
    private store: Store,
    private sharedAccountsService: SharedAccountsService
  ) {}

  getSharedAccounts() {
    this.sharedAccountsService.getSharedAccounts().subscribe(
      response => {
        this.handleSuccessSharedResponse(response.data);
      },
      () => {
        this.handleFailedSharedResponse();
      }
    );
  }

  getSharedAccountsByAdvisor(userId: number, accountNumber: number) {
    this.sharedAccountsService
      .getSharedAccountsByAdvisorId(userId, accountNumber)
      .subscribe(
        response => {
          this.handleSuccessSharedByAdvisorResponse(response.data);
        },
        () => {
          this.handleFailedSharedByAdvisorResponse(userId);
        }
      );
  }

  addSharedAccounts(sharedAccountsRequest: SharedAccountsRequest) {
    this.sharedAccountsService
      .addSharedAccounts(sharedAccountsRequest)
      .subscribe(
        response => {
          this.handleSuccessUpdateSharedResponse(response.data);
        },
        () => {
          this.addSharedAccountsResult$.next(false);
        }
      );
  }

  addSharedAccountsByAdvisor(
    sharedAccountsByAdvisorRequest: SharedAccountsByAdvisorRequest
  ) {
    this.sharedAccountsService
      .addSharedAccountsByAdvisorId(sharedAccountsByAdvisorRequest)
      .subscribe(response => {
        this.handleSuccessUpdateSharedByAdvisorResponse(response.data);
      });
  }

  updateSharedAccounts(sharedAccountsRequest: SharedAccountsRequest) {
    this.sharedAccountsService
      .updateSharedAccounts(sharedAccountsRequest)
      .subscribe(response => {
        this.handleSuccessUpdateSharedResponse(response.data);
      });
  }

  updateSharedAccountsByAdvisor(
    sharedAccountsByAdvisorRequest: SharedAccountsByAdvisorRequest
  ) {
    this.sharedAccountsService
      .updateSharedAccountsByAdvisorId(sharedAccountsByAdvisorRequest)
      .subscribe(response => {
        this.handleSuccessUpdateSharedByAdvisorResponse(response.data);
      });
  }

  private handleSuccessSharedByAdvisorResponse(
    response: SharedAccountsByAdvisorResponse
  ) {
    this.store.dispatch(addSharedAccountsByAdvisor({ payload: response }));
  }

  private handleSuccessUpdateSharedByAdvisorResponse(
    response: SharedAccountsByAdvisorResponse
  ) {
    this.store.dispatch(
      addUpdateSharedAccountsByAdvisor({ payload: response })
    );
  }

  private handleFailedSharedByAdvisorResponse(udbUserId: number) {
    const sharedAccountsByAdvisorResponse: SharedAccountsByAdvisorResponse = {
      advisorId: 0,
      udbUserId: udbUserId,
      accounts: [],
    };
    this.store.dispatch(
      addSharedAccountsByAdvisor({ payload: sharedAccountsByAdvisorResponse })
    );
  }

  private handleSuccessSharedResponse(response: SharedAccountsResponse) {
    const sharedAccountsStateTypeState: SharedAccountsStateType = {
      sharedAccounts: response,
    };
    this.store.dispatch(
      addSharedAccounts({ payload: sharedAccountsStateTypeState })
    );
  }

  private handleSuccessUpdateSharedResponse(response: SharedAccountsResponse) {
    this.store.dispatch(addUpdateSharedAccounts({ payload: response }));
    this.addSharedAccountsResult$.next(true);
  }

  private handleFailedSharedResponse() {
    const sharedAccountsResponse: SharedAccountsResponse = {
      udbUserId: 0,
      accounts: [],
    };
    const sharedAccountsStateTypeState: SharedAccountsStateType = {
      sharedAccounts: sharedAccountsResponse,
    };
    this.store.dispatch(
      addSharedAccounts({ payload: sharedAccountsStateTypeState })
    );
  }
}
