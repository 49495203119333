<app-dot-loader *ngIf="isLoading; else debitCardView">
  Loading debit cards details...
</app-dot-loader>

<ng-template #debitCardView>
  <div class="view-container d-flex flex-wrap pt-3">
    <app-debit-card-details
      class="d-block col-12 col-lg-9 mb-3 mb-lg-0"
      [debitCard]="debitCards[selectedCardIndex]"
      (updatedCard)="handleUpdatedCard($event)"
      (goTo)="routeRedirect($event)"
    ></app-debit-card-details>

    <app-debit-card-list
      class="col-12 col-lg-3"
      [debitCards]="debitCards"
      [selectedCardIndex]="selectedCardIndex"
      (pickCard)="selectCard($event)"
    ></app-debit-card-list>
  </div>
</ng-template>
