<h3 class="mt-5 mb-4">How do I add or delete a tile?</h3>

<ol>
  <li>
    Click on
    <b>Add Tiles</b>
    in your dashboard.
  </li>
  <li>Add the tiles that you want to appear on your Homepage.</li>
</ol>

<p class="mt-4 mb-4">You can also have more than one of the same type of tile on your Homepage.</p>

<h5 class="mb-3">To delete a tile:</h5>
<ol>
  <li>
    Click on the
    <b>Settings</b>
    wheel next to the title of the tile.
  </li>
  <li>
    The tile will flip around, then click
    <b>Remove Tile.</b>
  </li>
  <li>
    Select
    <b>Remove Tile</b>
    again to confirm.
  </li>
</ol>
