export enum BeneficiaryRelationshipType {
  Spouse = 1,
  Child,
  Grandchild,
  Parent,
  Grandparent,
  Sibling,
  Relative,
  Friend,
  Other,
}
