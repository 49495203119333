import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';

import { Chart } from 'chart.js';
import { format } from 'date-fns';

import { CHART_CONFIGURATION } from '@app/axos-trading/constants';
import { EquityHistory } from '@app/axos-trading/models';
import { AbbreviateNumber } from '@app/utils';

@Component({
  selector: 'app-stock-graph',
  templateUrl: './stock-graph.component.html',
  styleUrls: ['./stock-graph.component.scss'],
})
export class StockGraphComponent implements OnInit, AfterViewInit {
  @Input() data: EquityHistory[];

  @Input() sortable: number;
  @Input() updateData: EventEmitter<void>;

  @ViewChild('chartCanvas') chartCanvas: ElementRef;

  chart: Chart;
  dataLabels: string[] = [];
  tooltipLabels: string[] = [];
  dataNumber: number[] = [];
  firstAvailableDate: string;
  lastItemDate: string;
  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit() {
    this.updateData?.subscribe(() => this.calculateTimelapse());
  }
  ngAfterViewInit() {
    this.calculateTimelapse();
  }

  private calculateTimelapse() {
    this.getData();
    const operation = this.chart ? this.updateChart : this.createChart;
    operation.bind(this)(this.dataNumber, this.dataLabels, this.tooltipLabels);
  }

  private createChart(data: number[], dataLabels: string[], tooltipLabels: string[]) {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, '#60a3ccc9');
    gradient.addColorStop(1, '#FFFFFFa3');

    this.chart = new Chart(this.chartCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: dataLabels,
        datasets: [
          {
            data,
            borderColor: '#4d85b0',
            fill: true,
            lineTension: 0,
            pointRadius: 0,
            pointHitRadius: 9.5,
            pointHoverBackgroundColor: '#4d85b0',
            pointHoverBorderWidth: 0,
            pointHoverRadius: 12,
            backgroundColor: gradient,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 10,
          },
        },
        responsive: false,
        stacked: false,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                callback: (value: string): string => {
                  return '$' + AbbreviateNumber(Number(value), 2);
                },
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#ffffff',
          xPadding: 25,
          yPadding: 20,
          caretPadding: 20,
          caretSize: 10,
          borderColor: '#cac9c9',
          borderWidth: 1,
          titleFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          titleFontColor: '#000',
          titleFontStyle: 'bold',
          titleMarginBottom: 15,
          titleFontSize: 14,
          bodyFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          bodyFontSize: 12,
          bodyFontColor: '#000',
          footerFontSize: 20,
          footerFontColor: '#466d95',
          footerFontFamily: "'Encode Sans', 'Helvetica', sans-serif",
          footerMarginTop: 15,
          displayColors: false,
          callbacks: this.setupTooltipCallback(tooltipLabels),
        },
      },
    });
  }

  private updateChart(data: number[], dataLabels: string[], tooltipLabels: string[]) {
    this.chart.data.labels = dataLabels;
    this.chart.data.datasets[0].data = data;
    this.chart.options.tooltips.callbacks = this.setupTooltipCallback(tooltipLabels);
    this.chart.update();
  }

  private setupTooltipCallback(tooltipLabels: string[]) {
    return {
      title: (tooltip: TooltipItem[], tooltipData: any): string => {
        return tooltipData.labels[tooltip[0].index];
      },
      label: (tooltip: TooltipItem): string => {
        return tooltipLabels[tooltip.index];
      },
      footer: (tooltip: TooltipItem[], tooltipData: any): string => {
        return this.currencyPipe.transform(tooltipData.datasets[tooltip[0].datasetIndex].data[tooltip[0].index]);
      },
    };
  }

  private getData() {
    const historicData = this.data;
    const configuration = CHART_CONFIGURATION.find(x => x.sortable === this.sortable);
    const allTotalData = historicData.map(x => x.price);
    const allDates = historicData.map(x => x.date);
    allDates.forEach(x => {
      if (this.sortable === 3 || this.sortable === 4) {
        this.dataLabels.push('');
      } else {
        this.dataLabels.push(format(new Date(x), configuration.dataLabelFormat));
      }
      this.tooltipLabels.push(
        configuration.timeLabel.replace('${date}', format(new Date(x), configuration.timeLabelFormat))
      );
    });
    if (this.sortable === 3 || this.sortable === 4) {
      this.firstAvailableDate = format(new Date(this.data[0].date), configuration.dataLabelFormat);
      this.lastItemDate = format(new Date(historicData[historicData.length - 1].date), configuration.dataLabelFormat);
    }
    this.dataNumber = allTotalData;
  }
}
