import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import {
  RewardsCheckingCharts,
  RewardsCheckingCriterias,
  RewardsDetail,
} from '@app/accounts/models/rewards-detail.model';
import { AccountsService } from '@core/services';
import { NavigationIcons } from '@shared/enums';
import { ServiceResult } from '@shared/models';
@Inject('accountsService')
@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsComponent implements OnInit, AfterViewInit {
  @Input() account: OlbAccount;
  chart: Chart;
  overallAPYTitle = '';
  overallAPYValue = '0.0 / 1.0%';
  overallEarnedAPY = 0.0;
  overallPossibleAPY = 0.0;
  goalToReach = 0.0;
  rewardsCheckingCharts: RewardsCheckingCharts;
  rewardsCheckingCriterias: RewardsCheckingCriterias[];
  backIcon = NavigationIcons.ArrowBack;
  requiredComplete = true;
  isLoading = true;
  hasError = false;
  errorMessage = '';

  private subsink = new SubSink();

  constructor(private accountsService: AccountsService) { }

  ngOnInit(): void {
    this.subsink.sink = this.accountsService
      .getRewardsInfoByAccountNumber(this.account.accountNumber)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (i: ServiceResult<RewardsDetail>) => {
          if (i.data) {
            this.rewardsCheckingCriterias = i.data.rewardsCheckingCriterias;
            this.rewardsCheckingCharts = i.data.rewardsCheckingCharts[0];
            this.overallAPYValue = this.rewardsCheckingCharts.overallAPYValue;
            this.overallAPYTitle = this.rewardsCheckingCharts.overallAPYTitle;
            this.overallEarnedAPY = this.rewardsCheckingCharts.overallEarnedAPY;
            this.overallPossibleAPY = this.rewardsCheckingCharts.overallPossibleAPY;
            this.goalToReach = Number((this.overallPossibleAPY - this.overallEarnedAPY).toFixed(1));
            this.rewardsCheckingCriterias.forEach(x => {
              if (x.criteriaName.includes('required')) {
                if (this.requiredComplete) {
                  this.requiredComplete = x.criteriaProgressBar === 1 ? true : false;
                }
              }
            });
          } else {
            this.hasError = true;
            this.errorMessage =
              'We are currently having issues retrieving your rewards information. ' +
              'If this is a new account, please allow 24-48 hours for rewards information to be available.';
          }
        },
        error: (err: ApiError | HttpErrorResponse) => {
          this.hasError = true;
          if (err.status === 400) {
            this.errorMessage =
              'We are currently having issues retrieving your rewards information. ' +
              'If this is a new account, please allow 24-48 hours for rewards information to be available.';
          }
          if (err.status === 500 || err.status === 404) {
            this.errorMessage =
              'We are currently having issues retrieving your rewards information. ' +
              'We are currently working to display this information.';
          }
        },
      });
  }

  ngAfterViewInit(): void {
    this.displayChart();
  }

  displayChart(): void {
    const ctx = document.querySelector<HTMLCanvasElement>('#doughnut-chart').getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['APY', '1.0'],
        datasets: [
          {
            data: [this.overallEarnedAPY, this.goalToReach],
            backgroundColor: ['gold', 'lightGray'],
          },
        ],
      },
      options: {
        devicePixelRatio: 1,
        layout: {
          padding: 0,
        },
        elements: {
          arc: { borderWidth: 2 },
        },
        animation: false,
        legend: {
          display: false,
        },
        rotation: Math.PI,
        cutoutPercentage: 70,
        radius: '100%',
      },
    });
  }

  cleanErrorMessage(): void {
    this.errorMessage = '';
  }
}
