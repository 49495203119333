import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, skip } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { endOfMonth, endOfYear, format, startOfMonth, startOfYear, subMonths, subYears } from 'date-fns';

import { AccountOrigin } from '@app/pfm/enums/account-origin.enum';
import { AccountSource } from '@app/pfm/enums/account-source.enums';
import { TimePeriod } from '@app/pfm/enums/time-period.enum';
import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { PfmTransaction } from '@app/pfm/models/pfm-transaction.model';
import { ReferenceAccount } from '@app/pfm/models/reference-account.model';
import { PfmService } from '@app/pfm/services/pfm.service';
import { getAccountsExcludingOthers } from '@app/pfm/store/accounts/accounts.selectors';
import {
  setLastFiltersRequest,
  setSpentByCategories,
  setTransactions,
  setTransactionsLoading,
} from '@app/pfm/store/spending/spending.actions';
import { getCommittedFilters, getTransactions } from '@app/pfm/store/spending/spending.selectors';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { ServiceHelper } from '@legacy/services/service.helper';

import { categories } from '../../models/category';
import { SpendingFilters } from './spending-filters';
import { AAS, AGGREGATED } from '../../constants/account.constants';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { SourceTransaction } from '@app/pfm/enums/source-transaction.enum';

@Injectable()
export class SpendingEffects {
  filters: any;
  constructor(
    public accAggFormatter: AccountAggregationChartFormatterService,
    private store: Store,
    private readonly pfmService: PfmService,
    private serviceHelper: ServiceHelper,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  startListening(subsink: SubSink): void {
    // todo once ngrx/effects is available, remove this method

    const getDistinctCommittedFilters = this.store
      .select(getCommittedFilters)
      .pipe(
        distinctUntilChanged(
          (previousFilters, filters) =>
            previousFilters.timePeriod === filters.timePeriod &&
            this.arrayEquals(previousFilters.excludedCategories, filters.excludedCategories) &&
            this.arrayEquals(previousFilters.excludedAccounts, filters.excludedAccounts)
        )
      );

    subsink.sink = combineLatest([getDistinctCommittedFilters, this.store.select(getAccountsExcludingOthers)])
      .pipe(skip(1))
      .subscribe(([committedFilters, accounts]) => this.loadTransactions(committedFilters, accounts, new Date()));

    subsink.sink = this.store
      .select(getTransactions)
      .pipe(skip(1))
      .subscribe(transactions => this.loadCategories(transactions));
  }

  loadTransactions(committedFilters: SpendingFilters, accounts: AccountOverview[], now: Date) {
    // todo once ngrx/effects is available, this method should be listening to actions (use merge):
    // [Insights.Accounts] Loaded
    // [Insights.Spending] Commit filters

    let startDate: Date;
    let endDate: Date;
    if (committedFilters.timePeriod === TimePeriod.ThisMonth) {
      startDate = startOfMonth(now);
      endDate = now;
    } else if (committedFilters.timePeriod === TimePeriod.LastMonth) {
      const oneMonthAgo = subMonths(now, 1);
      startDate = startOfMonth(oneMonthAgo);
      endDate = endOfMonth(oneMonthAgo);
    } else if (committedFilters.timePeriod === TimePeriod.ThisYear) {
      startDate = startOfYear(now);
      endDate = now;
    } else if (committedFilters.timePeriod === TimePeriod.LastYear) {
      const oneYearAgo = subYears(now, 1);
      startDate = startOfYear(oneYearAgo);
      endDate = endOfYear(oneYearAgo);
    } else if (committedFilters.timePeriod === TimePeriod.Last3Months) {
      const threeMonthsAgo = subMonths(now, 3);
      startDate = startOfMonth(threeMonthsAgo);
      const oneMonthAgo = subMonths(now, 1);
      endDate = endOfMonth(oneMonthAgo);
    } else if (committedFilters.timePeriod === TimePeriod.Last12Months) {
      const twelveMonthsAgo = subMonths(now, 12);
      startDate = startOfMonth(twelveMonthsAgo);
      const oneMonthAgo = subMonths(now, 1);
      endDate = endOfMonth(oneMonthAgo);
    }

    const relevantAccounts: ReferenceAccount[] = accounts
      .filter(a => !committedFilters.excludedAccounts.has(a.globalId))
      // Axos Invest and Clearing Trading transactions aren't supported yet
      .filter(a => a.origin !== AccountOrigin.AxosInvest && a.origin !== AccountOrigin.Clearing)
      .map(a => ({
        accountSource: this.setAccountSource(a),
        accountId: a.origin == AccountOrigin.AAS ? a.accountNumberAas : a.id,
      }));

    this.filters = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      category: categories.filter(c => !committedFilters.excludedCategories.has(c.id)).map(c => c.name),
      accounts: relevantAccounts,
    };
    this.store.dispatch(setLastFiltersRequest({ payload: this.filters }));

    // Abort if no accounts/categories satisfy the filters
    if (!this.filters.accounts.length || !this.filters.category.length) {
      this.store.dispatch(setTransactions({ payload: [] }));

      return;
    }

    this.store.dispatch(setTransactionsLoading({ payload: true }));
    this.pfmService
      .getTransactions(this.filters)
      .pipe(
        catchError(err => {
          // show error popup
          this.serviceHelper.errorHandler(err, true);
          throw err;
        }),
        finalize(() => this.store.dispatch(setTransactionsLoading({ payload: false })))
      )
      .subscribe(resp => {
        let transactions = resp.data.transactions;
        if (transactions) {
          transactions.forEach(trx => {
            trx.displayName = this.formatAccountName(trx);

            // replace <br> tags with '. '
            trx.originalDescription = trx.originalDescription.replace(/<br\/>/g, '. ');

            // if the transaction doesn't have a simple description fallback to the original description
            //    this is common for internal accounts that have not been categorized by Yodlee yet
            trx.displayDescription = trx.simpleDescription || trx.originalDescription;
          });
        }

        if (!this.featureFlagService.isRiaPilotInsightsActive()) {
          transactions = transactions.filter(transaction => transaction.source !== SourceTransaction.AAS);
        }

        this.store.dispatch(setTransactions({ payload: transactions }));
      });
  }

  loadCategories(transactions: PfmTransaction[]) {
    // todo once ngrx/effects is available, this method should be listening to action:
    // [Insights.Spending] Set categories

    const relevantTransactions = this.filterTransactions(transactions);
    const totalSpent = relevantTransactions.reduce((a, b) => a + b.amount, 0);

    const categs = categories.map(category => ({
      ...category,

      percentage:
        (relevantTransactions.filter(t => t.olbCategoryId === category.id).reduce((a, b) => a + b.amount, 0) /
          totalSpent) *
        100,

      amount: relevantTransactions.filter(t => t.olbCategoryId === category.id).reduce((a, b) => a + b.amount, 0),
    }));

    this.accAggFormatter.roundCategoriesPercentages(categs);
    this.accAggFormatter.SortCategories(categs);

    this.store.dispatch(setSpentByCategories({ payload: categs }));
  }

  filterTransactions(transactions: PfmTransaction[]) {
    return transactions.filter(
      transaction =>
        !transaction.isPending &&
        transaction.olbCategoryId !== 10 && // exclude pending category
        transaction.olbCategoryId !== 8 && // exclude deposits category
        !transaction.isIncome &&
        transaction.amount !== undefined &&
        transaction.amount != null
    );
  }

  arrayEquals(array1: Set<string | number>, array2: Set<string | number>): boolean {
    const array1Sorted = [...array1].sort();
    const array2Sorted = [...array2].sort();

    return (
      array1Sorted.length === array2Sorted.length &&
      array1Sorted.every((v, i) => {
        return v === array2Sorted[i];
      })
    );
  }

  formatAccountName(transaction: PfmTransaction): string {
    const maxlengthFI = 23;
    if (
      (transaction.source === AccountSource.Internal || transaction.source === AccountSource.AAS) &&
      transaction.accountName
    ) {
      return `${this.formatText(transaction.accountName, maxlengthFI)} - ${transaction.accountNumber}`;
    }

    return `${this.formatText(transaction.bankName, maxlengthFI)} - ${transaction.accountNumber}`;
  }

  setAccountSource(a: AccountOverview) {
    if (a.globalId.includes(AGGREGATED)) {
      return AccountSource.Aggregated;
    } else if (a.globalId.includes(AAS)) {
      return AccountSource.AAS;
    } else {
      return AccountSource.Internal;
    }
  }

  formatText(text: string, maxLength: number): string {
    const ending = '...';
    const endingLength = ending.length;
    if (maxLength <= endingLength) return ending;
    if (!text) return '';
    if (text.length >= maxLength) return text.substr(0, maxLength - endingLength).trimRight() + ending;

    return text;
  }
}
