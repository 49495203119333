import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';

import {
  BatchTransactionSummary,
  RemoteDepositSessionInfo,
  RemoteDepositSessionResponse,
  RemoteSessionRequest,
  SingleDeposit,
} from '../models';

@Injectable()
export class RemoteDepositService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'remoteDeposit');
  }

  /**
   * Initialises the session with Ensenta with the given data.
   *
   * @param request Request for Remote Deposit.
   *
   * @returns An Observable containing
   */
  signOn(request: RemoteSessionRequest) {
    return this.post<RemoteDepositSessionResponse>('startSession', request);
  }

  /**
   * Gets the terms and conditions for Remote Deposit.
   *
   * @param request Request for Remote Deposit.
   *
   * @returns An Observable containing the HTML of the terms and conditions.
   */
  getTermsAndConditions(request: RemoteSessionRequest) {
    return this.post<string>('Terms', request);
  }

  /**
   * Accepts the terms and conditions for Remote Deposit.
   *
   * @param request Request for Remote Deposit.
   *
   * @returns An Observable containing the sessionId and token.
   */
  acceptTermsAndConditions(request: RemoteSessionRequest) {
    return this.post<RemoteDepositSessionInfo>('acceptTerms', request);
  }

  /**
   * Closes the session for remote deposits.
   *
   * @param request Request for Remote Deposit.
   *
   * @returns An Observable that contains the result of the operation.
   */
  endSession(request: RemoteSessionRequest) {
    return this.post<null>('endSession', request);
  }

  /**
   * Performs all the steps required to do a single deposit.
   * It creates the batch, adds the deposit item to the batch, evaluates the risk.
   *
   * @param request Request for remote deposit.
   *
   * @returns An Observable that contains the data associated with the uploaded check.
   */
  uploadCheck(request: RemoteSessionRequest) {
    return this.post<SingleDeposit>('singleDeposit', request);
  }

  /**
   * Cancels the deposit batch.
   *
   * @param request Request for remote deposit.
   *
   * @returns An Observable that contains the result of the operation.
   */
  cancelDepositBatch(request: RemoteSessionRequest) {
    return this.post<null>('cancelDepositBatch', request);
  }

  /**
   * Confirms and posts the batch.
   *
   * @param request Request for remote deposit.
   *
   * @returns An Observable that contains the summary of the deposit.
   */
  addDeposit(request: RemoteSessionRequest) {
    return this.post<BatchTransactionSummary>('postDepositBatch', request);
  }
}
