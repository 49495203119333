import { UIKitTile, TileTypes } from '@uikit/tiles';

export interface TileStore {
  catalog: UIKitTile[];
  storeType: TileTypes;
}

export interface StoreTile extends UIKitTile {
  settings?: UserTileSetting[];
}

/** Represents an user set tile setting. */
export class UserTileSetting {
  constructor(
    /** ID of the tile setting. */
    public id: number,
    /** Tile setting code.
     * This is for generating the appropriate items in the tile.
     */
    public settingCode: string,
    /** User set value. */
    public value: string,
    /** ID of the tile that the settings belong to. */
    public userTileId?: number
  ) {}
}

/** Represents a tile owned by the user. */
export class UserTile {
  constructor(
    /** ID of the tile in the tile catalog. */
    public tileId: number,
    /** Settings for this particular tile. */
    public settings?: UserTileSetting[],
    /**
     * User tile instance ID.
     * There can be more than one instance of a tile.
     */
    public instanceId: number = 0,
    /**
     * Specifies if the tile is totally new.
     * This helps the base tile to determine when to send the olbTileCreated event.
     */
    public isNew?: boolean
  ) {}
}
