import { HttpErrorResponse } from '@angular/common/http';

export const enum DefaultErrorMessages {
  ConnectionRefused = 'It appears that our servers are not responding. Please try again later.',
  Timeout = 'The operation has timed out, please try again.',
  BadRequest = 'Your request cannot be processed, please check it and try again later.',
  NotFound = 'The resource you requested was not found.',
  Conflict = 'We could not authenticate you. Please log back in.',
  Generic = 'Something unexpected went wrong on our end. Please try again. Thank you.',
}

/**
 * Gets the error message from the server or a default one.
 *
 * @param response HttpErrorResponse that can be obtained via the "error" callback.
 *
 * @returns An error message.
 */
export function extractServerError(response: HttpErrorResponse): string {
  if (response.error.message) {
    return response.error.message;
  }

  switch (response.status) {
    // Angular puts a status code -1 for connection refused.
    case -1:
      return DefaultErrorMessages.ConnectionRefused;
    // Angular puts a status code 0 for timeouts.
    case 0:
      return DefaultErrorMessages.Timeout;
    case 400:
      return DefaultErrorMessages.BadRequest;
    case 404:
      return DefaultErrorMessages.NotFound;
    case 409:
      return DefaultErrorMessages.Conflict;
    default:
      return DefaultErrorMessages.Generic;
  }
}
