import { Action, createReducer, on } from '@ngrx/store';

import { loadMaritalStatuses } from './marital-statuses.actions';

export const initialState = null;

const _reducer = createReducer<Record<string, string>>(
  initialState,
  on(loadMaritalStatuses, (_state, action) => {
    return action.payload;
  })
);

export function maritalStatusesReducer(state: Record<string, string>, action: Action) {
  return _reducer(state, action);
}
