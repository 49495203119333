<div class="main-portfolio-details">
  <div class="main-portfolio-details__item">
    <p>Stocks</p>
    <h1 class="stock">{{ !hasError ? details.stockAllocation : '--' }}%</h1>
  </div>
  <div class="main-portfolio-details__item">
    <p>Bonds</p>
    <h1 class="bond">{{ !hasError ? details.bondAllocation : '--' }}%</h1>
  </div>
  <div class="main-portfolio-details__item" *ngIf="!hasError || details?.cashAllocation > 0">
    <p>Cash</p>
    <h1 class="cash">{{ !hasError ? details.cashAllocation : '--' }}%</h1>
  </div>
</div>
