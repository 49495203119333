import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CategoryEvent, SubcategoryEvent } from '@app/support/models';
import { olbSettings } from '@core/tokens';
import { ModalService } from '@legacy/services/modal.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { SupportService } from '@legacy/services/support.service';

@Component({
  selector: 'app-sm-submenu',
  templateUrl: './sm-submenu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmSubmenuComponent implements OnInit {
  @Input()
  public category: CategoryEvent;
  @Output()
  public selectItem = new EventEmitter<SubcategoryEvent>();

  public isLoading: boolean = false;
  public subcategories: SubcategoryEvent[] = [];

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    private supportService: SupportService,
    private serviceHelper: ServiceHelper,
    private modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.fillSubcategories();
  }

  public handleItemClick(subcategory: SubcategoryEvent): void | Promise<void> {
    return subcategory.url ? this.triggerModal(subcategory.url) : this.selectItem.emit(subcategory);
  }

  private async fillSubcategories(): Promise<void> {
    this.isLoading = true;

    try {
      let { data } = await this.supportService.getSubCategories(this.category.categoryId);

      this.mapResponseToSubcategories(data);
      this.isLoading = false;
      this.cd.detectChanges();
    } catch (exception) {
      console.error({ exception });
      this.isLoading = false;
      this.serviceHelper.errorHandler(exception);
    } finally {
      this.isLoading = false;
      this.cd.detectChanges();
    }
  }

  private mapResponseToSubcategories(data: any[]): void {
    data.forEach(element => {
      this.subcategories.push(
        new SubcategoryEvent({
          ...this.category,
          subcategoryId: Number(element.id),
          description: element.description,
          url: element.url ?? null,
        })
      );
    });
  }

  private async triggerModal(url: string): Promise<void> {
    const modalSettings = {
      icon: 'bofi-information',
      cancelText: 'Cancel',
      okText: 'Continue',
      bodyText: `<h3>You're continuing to another website</h3>
                 <p class="hidden-sm ms-secondary-text">
                  ${this.env.brandName} does not endorse the information, content, presentation or accuracy of any other website and claims no responsibility for it.
                 </p>
                 <div class="modal-link-summary ms-secondary-text">You will now be directed to:<a>${url}</a></div>`,
    };

    await this.modalService.show({}, modalSettings);
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}
