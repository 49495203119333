import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppStateFeatures, BrandProperty } from '@core/enums';

export const getBrandProperties = createFeatureSelector<Record<string, string>>(AppStateFeatures.BrandProperties);

/**
 * Gets the value of the setting as-is.
 *
 * @param propertyName Name of the property. Please use the BrandProperty enum for property names.
 *
 * @returns The string value for the property.
 */
export function getBrandProperty(propertyName: string) {
  return createSelector(getBrandProperties, state => state[propertyName]);
}

/**
 * Gets multiple brand properties at the same time.
 *
 * @param propertyNames A list of brand property names.
 *
 * @returns An object in which the key is the property name and the value is the property value.
 */
export function getMultipleBrandProperties(propertyNames: BrandProperty[]) {
  return createSelector(
    getBrandProperties,
    state =>
      propertyNames.reduce((acc, current) => {
        return { ...acc, [current]: state[current] };
      }, {}) as Record<string, string>
  );
}
