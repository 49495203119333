import { AccountStatePieces } from '../enums';
import { aggregatedAccountsReducer } from './aggregated-accounts/aggregated-accounts.reducer';
import { closedAccountsReducer } from './closed-accounts/closed-accounts.reducer';
import { externalAccountsReducer } from './external-accounts/external-accounts.reducer';
import { internalAccountsReducer } from './internal-accounts/internal-accounts.reducer';
import { investAccountsReducer } from './invest-accounts/invest-accounts.reducer';
import { providersReducer } from './providers/providers.reducer';
import { tradingAccountsReducer } from './trading-accounts/trading-accounts.reducer';

export const accountReducers = {
  [AccountStatePieces.Aggregated]: aggregatedAccountsReducer,
  [AccountStatePieces.Closed]: closedAccountsReducer,
  [AccountStatePieces.External]: externalAccountsReducer,
  [AccountStatePieces.InternalAccounts]: internalAccountsReducer,
  [AccountStatePieces.Invest]: investAccountsReducer,
  [AccountStatePieces.Providers]: providersReducer,
  [AccountStatePieces.Trading]: tradingAccountsReducer,
};
