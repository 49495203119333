export enum GoalType {
  Retirement,
  RainyDay,
  SaveCash,
  NewHome,
  NewVehicle,
  PersonalWealth,
  Custom,
  Wallet,
}
