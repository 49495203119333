import { JustifyChartLabelsContext, ProcessedLabel } from '../../types';
/**
 * this pure function plots the labels for the total value chart using the center
 * as pivot, use only when the dates to show on the chart are odd
 * @example
 * this.chart.options.scales.xAxes[0].ticks.callback = justifyChartLabelsCenter.bind(5)
 * @param this number
 * @param value number
 * @param index number
 * @param groupOfLabels {@link ProcessedLabel} [ ]
 * @returns either string or number
 */
export function justifyChartLabelsCenter(
  this: JustifyChartLabelsContext,
  value: number,
  index: number,
  groupOfLabels: Array<ProcessedLabel<number>>
): string | number {
  if (this.datesToShow % 2 === 0) {
    throw new Error('The number of labels to render must be an odd number');
  }
  if (index === 0) {
    return '';
  }

  const groupedBy = this.amount / (2.4 * this.datesToShow);

  this.unitStepSizeSetter(groupedBy);
  const middlePosition = Math.floor(groupOfLabels?.length / 2) - 1;
  const offsetByStep = Math.floor(groupOfLabels?.length / this.datesToShow);
  const missingPositions = Math.floor((this.datesToShow - 1) / 2);
  const labels = [middlePosition];
  for (let k = 0; k < missingPositions; k++) {
    const leftLabelOffset = offsetByStep * (k + 1);
    const rightLabelOffset = offsetByStep * (k + 1);
    labels.push(middlePosition - leftLabelOffset, middlePosition + rightLabelOffset);
  }
  if (labels.includes(index)) {
    return value;
  }
  return '';
}
