import { DecimalPipe } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import {
  Uk2ButtonSizeEnum,
  Uk2MenuButtonSelectionTypeEnum,
  Uk2Tier1NavigationEnum,
  Uk2Tier1UtilityEnum,
  Uk2ValueMovementTypeEnum,
} from '@axos/uikit-v2-lib';

import { TradeMarketService } from '@app/Areas/AAS/aas-core/services';

import { ModelsTileState, TFilterOptionLabels } from '../core';
import { ModelsFacade } from '../facade';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { Uk2Tier1AlertsEnum } from '@axos/uikit-v2-lib';

@Component({
  selector: 'app-models-tile',
  templateUrl: './models-tile.component.html',
  styleUrls: ['./models-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsTileComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() heightScrollArea = 0;
  @Input() modelsTileAccountState$: ModelsTileState;
  isMarkedOpened$ = false;
  isDataLoaded = false;
  hideAllTimePortfolio = false;
  defaultText = null;
  alertsIcons = Uk2Tier1AlertsEnum;
  showUnderstandingModelsModal = false;

  @ViewChild('contentSection') content: ElementRef<HTMLDivElement> = new ElementRef(null);
  readonly icon = Uk2Tier1NavigationEnum.arrowsVertical;
  readonly singleSortButton: Uk2MenuButtonSelectionTypeEnum = Uk2MenuButtonSelectionTypeEnum.single;
  readonly sortButtonSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.small;
  readonly cogIcon = Uk2Tier1UtilityEnum.cog;
  readonly keepLastSelection: boolean = true;
  readonly customPortalCSSProperties = this.modelsFacade.portalCSSProperties;
  destroy$ = new Subject<void>();
  readonly closedMarketLabel: string = 'Previous Close';
  showUnderstandingHoldingsModal = false;

  private readonly AMOUNT_FORMAT = '1.2-2';
  private readonly PX = 'px';
  private readonly IS_BOTTOM_OVERFLOWING_CLASS = 'is-bottom-overflowing';
  private readonly DASH_PLACEHOLDER = '--';
  private readonly today = new Date();

  uk2TextTileTitle = 'Models Temporarily Unavailable';
  uk2TextTileSvgIconName = Uk2Tier1UtilityEnum.cog;
  uk2TextTileDisplayBackground = false;
  uk2TextTileIsStandaloneTile = false;

  modelsFailedState$: Observable<boolean>;

  constructor(
    private decimalPipe: DecimalPipe,
    private modelsFacade: ModelsFacade,
    private readonly tradeMarket: TradeMarketService,
    private changeDetectorRef: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.modelsFailedState$ = this.modelsFacade.modelsTileAccountState$.pipe(
      map(holdingTileAccountState => !!holdingTileAccountState.error)
    );

    this.tradeMarket
      .isMarketOpened(this.today)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: isMarkedOpened => {
          this.isDataLoaded = true;
          this.isMarkedOpened$ = isMarkedOpened;
          this.changeDetectorRef.markForCheck();
        },
      });
    this.modelsFacade.modelsTileAccountState$
      .pipe(
        filter(state => state !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(modelTile => {
        if (this.featureFlagService.isRiaPilotHideAllTimeChangeActive()) {
          this.hideAllTimePortfolio = true;
          modelTile.sortOptions = modelTile.sortOptions.filter(
            sortOption => sortOption.sortByKey !== 'allTimeChangePercent'
          );
        }
        this.defaultText = modelTile.sortOptions.find(x => x.isSelected)?.text;
        this.modelsTileAccountState$ = modelTile;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngAfterViewChecked(): void {
    this.setHeightScrollArea();
    this.setFade();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectSortingFilter([selectedOption]: TFilterOptionLabels[]): void {
    this.modelsFacade.selectSortingFilter(selectedOption);
  }

  getUk2ValueMovementTypeEnum(value: number): Uk2ValueMovementTypeEnum {
    switch (true) {
      case value === 0:
        return Uk2ValueMovementTypeEnum.none;
      case value > 0:
        return Uk2ValueMovementTypeEnum.increasePositive;
      default:
        return Uk2ValueMovementTypeEnum.decreaseNegative;
    }
  }

  formatAmount(amount: number | undefined): string {
    if (amount === undefined || amount === null) {
      return this.DASH_PLACEHOLDER;
    }

    const formattedAmount = Math.abs(amount).toFixed(2);

    return this.decimalPipe.transform(formattedAmount, this.AMOUNT_FORMAT);
  }

  getAmountFormat(amount: number | undefined, removeCommas: boolean = false): number | string {
    if (amount === undefined) {
      return this.DASH_PLACEHOLDER;
    }

    return removeCommas
      ? this.decimalPipe.transform(Math.abs(amount).toFixed(2), this.AMOUNT_FORMAT).replace(/,/g, '')
      : this.decimalPipe.transform(Math.abs(amount).toFixed(2), this.AMOUNT_FORMAT);
  }

  getFormattedMarketValue(amount: number): number | string {
    if (amount == null) {
      return this.DASH_PLACEHOLDER;
    }

    return `${amount < 0 ? '-' : ''}$${this.decimalPipe.transform(Math.abs(amount).toFixed(2), this.AMOUNT_FORMAT)}`;
  }

  getFormattedPercentageValue(amount: number): string {
    const transformedAmount = this.formatAmount(amount);

    return `${amount < 0 ? '-' : ''}${transformedAmount}`;
  }

  getAbsoluteAmount(amount: number): number {
    return Math.abs(amount);
  }

  onResize(): void {
    this.setHeightScrollArea();
    this.setFade();
  }

  setHeightScrollArea(): void {
    if (this.heightScrollArea !== 0) {
      this.content.nativeElement.style.height = this.heightScrollArea.toString() + this.PX;
    }
  }

  setFade(): void {
    const el = this.content.nativeElement;
    if (el !== null) {
      const isScrollable = el.scrollHeight > el.clientHeight;
      if (!isScrollable) {
        el.classList.remove(this.IS_BOTTOM_OVERFLOWING_CLASS);
      } else {
        const isScrolledToBottom = el.scrollHeight <= el.clientHeight + el.scrollTop;
        el.classList.toggle(this.IS_BOTTOM_OVERFLOWING_CLASS, !isScrolledToBottom);
      }
    }
  }

  toggleUnderstandingModelsModal(): void {
    this.showUnderstandingModelsModal = !this.showUnderstandingModelsModal;
  }
}
