import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsComponent implements OnInit {
  @Output() onEdit = new EventEmitter<any>();
  @Input() busy: boolean;
  @Input() title: string;
  @Input() payoff: boolean;

  constructor() {}

  ngOnInit() {}

  edit() {
    if (this.onEdit) {
      this.onEdit.emit();
    }
  }
}
