import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as Joi from 'joi';

import { AlertsIcons } from '@shared/enums';

import { defaultPasswordValidationSchema, PasswordErrors, PasswordSchema } from './password-field.schema';

export interface SvgIconProps {
  icon: AlertsIcons.Circle | AlertsIcons.CheckCircle;
  color: 'var(--danger)' | 'var(--success)';
}

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFieldComponent),
      multi: true,
    },
  ],
})
export class PasswordFieldComponent implements OnInit, ControlValueAccessor {
  @Input() set value(val: string) {
    if (val && this._value !== val) {
      this._value = val;

      this.onChange(val);
      this.onTouch(val);
      this.validatePasswordComplexity(this._value);
    }
  }

  get value() {
    return this._value;
  }

  get showChecker() {
    return this.passwordHintsClasses['field-touched'];
  }

  get uppercaseIconName(): SvgIconProps {
    const icon = this.passwordErrors.uppercase ? AlertsIcons.Circle : AlertsIcons.CheckCircle;
    const color = this.passwordErrors.uppercase ? 'var(--danger)' : 'var(--success)';

    return { icon, color };
  }

  get numberIconName(): SvgIconProps {
    const icon = this.passwordErrors.number ? AlertsIcons.Circle : AlertsIcons.CheckCircle;
    const color = this.passwordErrors.number ? 'var(--danger)' : 'var(--success)';

    return { icon, color };
  }

  get lowercaseIconName(): SvgIconProps {
    const icon = this.passwordErrors.lowercase ? AlertsIcons.Circle : AlertsIcons.CheckCircle;
    const color = this.passwordErrors.lowercase ? 'var(--danger)' : 'var(--success)';

    return { icon, color };
  }

  get lengthCharIconName(): SvgIconProps {
    const icon = this.passwordErrors.length ? AlertsIcons.Circle : AlertsIcons.CheckCircle;
    const color = this.passwordErrors.length ? 'var(--danger)' : 'var(--success)';

    return { icon, color };
  }

  get specialCharIconName(): SvgIconProps {
    const icon = this.passwordErrors.symbol ? AlertsIcons.Circle : AlertsIcons.CheckCircle;
    const color = this.passwordErrors.symbol ? 'var(--danger)' : 'var(--success)';

    return { icon, color };
  }

  get progressValue() {
    return 5 - this.errorCount;
  }

  get hasError() {
    return this.errorCount > 0;
  }

  get strength(): string {
    if (this.errorCount < 2) {
      return 'Strong';
    } else if (this.errorCount < 4) {
      return 'Medium';
    } else {
      return 'Weak';
    }
  }

  get exceedsMaxLength() {
    if (!this.maxLength) return false;

    return this._value?.length > this.maxLength;
  }

  @Output() inputChange = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() labelText = 'Password';
  @Input() name = 'password';
  @Input() maxLength: number;

  @Input() placeholder = '';

  hidePassword = true;
  passwordHintsClasses = {
    'with-error': false,
    'field-touched': false,
  };
  iconSize = '18px';

  private errorCount = 0;
  private passwordErrors: PasswordErrors = {};
  private _value = null;
  private validationSchema: PasswordSchema;

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    let validationSchema = defaultPasswordValidationSchema;

    if (this.maxLength) {
      validationSchema = {
        ...validationSchema,
        length: defaultPasswordValidationSchema.length.max(this.maxLength),
      };
    }

    this.validationSchema = validationSchema;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validatePasswordComplexity(value: string) {
    this.valueChange.emit(value);

    const passwordMap = {
      length: value,
      lowercase: value,
      uppercase: value,
      number: value,
      symbol: value,
    };

    const { error } = Joi.object(this.validationSchema).validate(passwordMap, {
      abortEarly: false,
    });
    const details = error ? error.details : [];
    const isValid = details.length === 0;

    this.errorCount = details.length;
    this.passwordHintsClasses['with-error'] = isValid;
    this.passwordHintsClasses['field-touched'] = true;
    this.passwordErrors = details.reduce((errorMap: PasswordErrors, current) => {
      const key = current.path.join('.');

      errorMap[key] = current.message;

      return errorMap;
    }, {});

    this.inputChange.emit(isValid);
  }
}
