export class Beneficiary {
  id: string;
  key: string;
  name: string;
  relationship: string;
  identity: string;
  accounts: OlbAccount[];
  percent: number;

  constructor(args?: Partial<Beneficiary>) {
    Object.assign(this, args);
  }
}
