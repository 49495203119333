import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Uk2TooltipPlacementEnum, Uk2TooltipSizeEnum, Uk2TooltipTriggerEnum } from '@axos/uikit-v2-lib';
import { STATE, olbSettings } from '@core/tokens';
import { customErrorState } from '@app/sbloc-offers/utils/customErrorState';
import { SblocService } from '@app/sbloc-offers/services/sbloc.service';
import { ApiModel } from '@app/sbloc-offers/Models/ApiModel';
import { RegisterUserAction } from '@app/sbloc-offers/utils/registerUserAction';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { RateSheet } from '@app/sbloc-offers/Models/rate-sheet.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EligibleAccount } from '@app/sbloc-offers/Models/eligible-account.model';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { Store } from '@ngrx/store';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { filter } from 'rxjs/operators';
import { AxosAdvisoryAccount } from '@core/models';
import { NO_CONTRIBUTION_ACCOUNT_TYPES_CONST } from '@legacy/common/constants';
import { IProviderService } from '@legacy/services/typings/IProviderService';
import { PROVIDERSERVICE, SblocServiceProvider } from '@app/sbloc-offers/ajs-upgraded-provider';
import { AggregatedAccount } from '@app/accounts/models';
import { BrandingSettingsFacade, BrandingStateType } from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { ExternalBankProvider } from '@shared/models';

@Component({
  selector: 'app-pre-approved-offer',
  templateUrl: './pre-approved-offer.component.html',
  styleUrls: ['./pre-approved-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [CurrencyPipe, SblocServiceProvider],
})
export class PreApprovedOfferComponent implements OnInit, OnDestroy {
  apiModel: ApiModel = { CIF: '', Account: 'string', Min: 0, Max: 0 };
  amount: string = '';
  formattedAmount: string | null = '';
  sliderValue: number = 0;
  inputNumber: number = 0;
  apr;
  aprModel = [] as RateSheet[];
  uk2IsLoading = false;
  labelText = 'Your APR';
  size: Uk2TooltipSizeEnum = Uk2TooltipSizeEnum.large;
  bodyText =
    'Your APR is based on the 1 month SOFR (Secured Overnight Financing Rate) index + the add-on rate associated with the credit amount you selected.';
  displayCloseButton = true;
  svgIconName = 'uk2-info-circle';
  arrowOffset = 0;
  placement: Uk2TooltipPlacementEnum = Uk2TooltipPlacementEnum.auto;
  desktopOpenMode: Uk2TooltipTriggerEnum = Uk2TooltipTriggerEnum.hover;
  customErrorState = new customErrorState();
  portFolioInfo: EligibleAccount;
  isLoadingSubject: Subject<boolean> = new BehaviorSubject(true);
  isLoading$ = new BehaviorSubject<boolean>(true);
  eligibleAccountNumber: string;
  margin: number;
  title = 'Select a Portfolio Account';
  description =
    'Select the investment portfolio account you would like to use for your securities-backed line of credit.';

  @ViewChild('listBottomSheet') bottomSheetTemplate!: TemplateRef<any>;

  eligibleList: EligibleAccount[] = [];
  eligibleListDisplayed: any;
  eligibleSelected: string;
  eligibleNickname: string;
  eligibleCurrentBalance: number;
  isRiaUser = false;
  advisoryAccounts: NewOlbAccount[] = [];
  advisoryAggregatedAccounts: AggregatedAccount[] = [];
  userCommonName: string;
  providers: Provider[];
  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  isAccountAggregationEnhancementsActive = false;

  constructor(
    @Inject(olbSettings) private readonly env: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    private currencyPipe: CurrencyPipe,
    private readonly _sblocService: SblocService,
    private bodyRef: ElementRef,
    private modalService: ModalService,
    private registerUserAction: RegisterUserAction,
    private bottomSheetService: MatBottomSheet,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    private readonly brandingSettingsFacade: BrandingSettingsFacade,
    private readonly riaFacade: RiaFacade,
    private readonly accAggChartFormatterService: AccountAggregationChartFormatterService,
    @Inject(PROVIDERSERVICE) private readonly _providerService: IProviderService
  ) {}

  ngOnInit(): void {
    this.setupAccounts();
    this.getEligibleAccounts();
  }
  getEligibleAccounts() {
    this.isLoadingSubject.next(true);
    this._sblocService
      .getRateSheetInfo()
      .toPromise()
      .then(async eligible => {
        this.eligibleList = eligible.data.Response;
        this.loadRiaAccounts();
      })
      .catch(ex => {
        if (ex.error.statusCode == 400 && ex.error.message == 'Not Eligible') {
          this.state.go('udb.NoLongerEligible');
        } else {
          this.showErrorModal();
        }
      });
  }
  loadRiaAccounts(): void {
    if (this.featureFlagService.isRiaPilotActive()) {
      this.store
        .select(getAxosAdvisoryAccounts)
        .pipe(filter(result => result != null))
        .subscribe((riaAccounts: AxosAdvisoryAccount[]) => {
          this.isRiaUser = riaAccounts.filter(x => x.status === 'active')?.length > 0 ? true : false;
          riaAccounts = riaAccounts.filter(x => !NO_CONTRIBUTION_ACCOUNT_TYPES_CONST.includes(x.accountTypeCode));

          this.advisoryAccounts = riaAccounts.map((acc: AxosAdvisoryAccount) => ({
            nickname: acc.accountNickname,
            displayName: acc.accountDisplayName,
            id: Number(acc.riaId),
            accountNumber: acc.accountNumber,
            currentBalance: acc.accountBalance,
            displayBalance: acc.accountBalance,
            availableBalance: acc.accountBalance,
            availableBalanceDisplay: acc?.accountBalance?.toString(),
            accountType: acc.accountType,
            Type: 'Investment Accounts',
            status: acc.status,
            accountTypeCode: acc.accountTypeCode,
            productType: acc.productType,
            isRia: true,
            isIra: acc.isRetirement,
            isTrading: true,
            bankName: acc.bankName,
            routingNumber: acc.routingNumber,
          }));

          (this.advisoryAggregatedAccounts = riaAccounts.map(
            (a: AxosAdvisoryAccount) =>
              ({
                id: +a.riaId,
                name: a.type,
                nickname: a.accountNickname,
                bankName: a.displayName,
                availableBalance: a.accountBalance,
                accountNumber: a.accountNumber,
                accountMask: a.accountNumber.substr(a.accountNumber.length - 4),
                active: a.status === 'active',
                status: a.status,
                isAxosAdvisory: true,
                accountType: a.accountType,
                accountTypeCode: a.accountTypeCode,
                advisoryName: a.advisorName,
                brandingName: a.brandingName,
                displayedName: a.accountDisplayName,
                isRetirement: a.isRetirement,
                firstDepositDate: a.firstDepositDate,
                type: a.type,
                riaId: a.riaId,
                clientPortalStatus: a.clientPortalStatus,
                dateCloseInitiated: a.dateCloseInitiated,
                dateTerminated: a.dateTerminated,
              } as AggregatedAccount)
          )),
            (this.eligibleListDisplayed = this.eligibleList.map(account => {
              const { Eligible_Account_Number: EligibleAccountNumber } = account;
              let currentBalance: number;
              let nickname: string;
              let displayName: string;
              let logo: string;

              const advisoryAccount = this.advisoryAccounts.find(
                acc => acc.accountNumber == account['Eligible_Account_Number']
              );

              const aggAccount = this.advisoryAggregatedAccounts.find(
                acc => acc.accountNumber == account['Eligible_Account_Number']
              );

              if (account) {
                currentBalance = advisoryAccount?.currentBalance;
                nickname = advisoryAccount?.nickname;
                displayName = advisoryAccount.displayName;
                logo = this.getImage(aggAccount);
              } else {
              }
              return { EligibleAccountNumber, currentBalance, nickname, displayName, logo };
            }));

          const eligible = this.eligibleList.find(
            account => account['Eligible_Account_Number'] == this.advisoryAccounts[0]?.accountNumber
          );

          if (eligible) {
            this.eligibleAccountNumber = eligible['Eligible_Account_Number'];
            this.setValuesByAccount(this.eligibleAccountNumber);
            this.isLoadingSubject.next(false);
          }
        });
    }
  }

  setValuesByAccount(accountNumber: string) {
    this.eligibleNickname = this.advisoryAccounts.find(acc => acc.accountNumber == accountNumber).nickname;
    this.portFolioInfo = this.eligibleList.find(account => account['Eligible_Account_Number'] == accountNumber);
    this.apiModel.Min = this.portFolioInfo.Minimum;
    this.sliderValue = this.apiModel.Min;
    this.apiModel.Max = this.portFolioInfo['SBLOC_Value'];
    this.aprModel = this.portFolioInfo.RateSheet;
    this.customErrorState.addValMax(this.apiModel.Max);
    this.customErrorState.addValMin(this.apiModel.Min);
    this.amount = this.currencyPipe.transform(this.apiModel.Min, 'USD');
    this.eligibleAccountNumber = this.portFolioInfo['Eligible_Account_Number'];
    this.portFolioInfo.RateSheet.forEach(eligible => {
      eligible.lowRange <= this.portFolioInfo.Minimum ? (this.apr = eligible.combinedAPR) : null;
    });
  }

  accountSelected(event: any) {
    this.eligibleSelected = event.value;
    this.setValuesByAccount(this.eligibleSelected);
  }

  bottomSheetAccountSelected(accountSelected: string) {
    this.bottomSheetService.dismiss();
    this.eligibleSelected = accountSelected;
    this.setValuesByAccount(this.eligibleSelected);
  }
  ngOnDestroy(): void {
    this.bodyRef.nativeElement.ownerDocument.body.style.overflow = 'scroll';
  }

  transformAmount(element: any) {
    this.formattedAmount = this.currencyPipe.transform(this.amount, 'USD');
    element.target.value = this.formattedAmount;
  }
  inputRoundUp(n: number) {
    var remainder = 0;
    var total = 0;

    total = Math.trunc(n / 1000) * 1000;
    remainder = n % 1000;
    if (remainder > 0) {
      return total + 1000;
    }

    return total;
  }

  keyPress(event: KeyboardEvent) {
    const pattern = /^[0-9.]/;
    const inputChar = event.key;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  submit() {
    var amountNum = Number(this.amount.replace(/[^0-9\.]+/g, ''));
    if (this.apiModel.Min <= amountNum && amountNum <= this.apiModel.Max) {
      this.registerUserAction.registerReviewedOffer(this.amount, this.apr).subscribe(() => {
        this._sblocService.sendValuesToAcceptanceCriteria(
          this.apr,
          this.amount,
          this.eligibleAccountNumber,
          this.margin
        );
        this.state.go('udb.acceptanceCriteria');
      });
    }
  }

  onSliderChange(value: number) {
    this.sliderValue = value;
    for (let item of this.aprModel) {
      if (this.sliderValue >= item.lowRange && this.sliderValue <= item.highRange) {
        this.apr = item.combinedAPR;
        this.amount = this.currencyPipe.transform(value, 'USD');
        this.margin = item.margin;
        return;
      }
    }
  }

  onInputChange(sliderValueNumber: string) {
    this.inputNumber = Number(sliderValueNumber.replace(/[^0-9\.]+/g, ''));
    this.inputNumber = this.inputRoundUp(this.inputNumber);
    this.onSliderChange(this.inputNumber);
  }
  goSblocOffer(): void {
    this.state.go('udb.sblocOffers');
  }

  goDashboard() {
    this.state.go('udb.dashboard');
  }

  showErrorModal() {
    const defaultMessage =
      '<p class="something-text">Something went wrong.</p><br/><p> Something unexpected went wrong on our end. Please try again. Thank you.<p>';
    const modalSettings: ModalSettings = {
      icon: 'bofi-cancel',
      bodyText: defaultMessage,
      okText: 'Close and try again later',
      hasCancelButton: false,
    };

    this.modalService.show({ windowClass: 'modal-service internal-error-modal' }, modalSettings);
  }
  openBottomSheet() {
    this.bottomSheetService.open(this.bottomSheetTemplate);
  }

  setupAccounts() {
    this.isAccountAggregationEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();

    this.brandingSettingsFacade.allBrandingSettings$.subscribe(brandings => {
      this.allBrandingSettings = brandings;
    });

    this.riaFacade.allRias$.subscribe(rias => {
      this.allRiaAccounts = rias;
    });

    this._providerService.getProviders().then(res => {
      this.providers = res.data;
    });
  }

  getImage(account: AggregatedAccount) {
    return this.accAggChartFormatterService.getImage(
      this.isAccountAggregationEnhancementsActive,
      this.providers as ExternalBankProvider[],
      this.env,
      account,
      this.allBrandingSettings,
      this.allRiaAccounts
    );
  }
}
