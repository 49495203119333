// import { IStateService } from 'angular-ui-router';
// import { IBillPayService } from 'services/typings/IBillPayService';

import { RestrictionType } from '../common/enums/enums';
import { Inject } from '../decorators/decorators';
// import { AccountProfile } from '@legacy/typings/app/AccountProfile';
// import { AccountProfileType } from '@legacy/common/enums/accountProfileType.enum';

@Inject('$stateProvider')
export class BillPayConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb.myBills', {
        url: '/MyBills',
        views: {
          content: {
            template: '',
          },
        },
        resolve: {
          isRestricted: [
            'authHelper',
            (authHelper: IAuthHelper): any => {
              return authHelper.hasCustomerServiceRestriction(RestrictionType.BillPay);
            },
          ],
        },
      })
      .state('udb.billPay', {
        url: '/billpay',
        abstract: true,
        views: {
          content: {
            templateUrl: 'bill-pay/bill-pay.tpl.html',
            controller: 'BillPayController',
            controllerAs: 'vm',
          },
        },
        resolve: {
          isRestricted: [
            'authHelper',
            (authHelper: IAuthHelper): any => {
              return authHelper.hasCustomerServiceRestriction(RestrictionType.BillPay);
            },
          ],
        },
      })
      .state('udb.billPay.welcome', {
        url: '/welcome',
        views: {
          billpay: {
            templateUrl: 'bill-pay/add-electronic-recipient/welcome/welcome.tpl.html',
            controller: 'WelcomeController',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('udb.billPay.pay', {
        url: '/pay',
        views: {
          billpay: {
            templateUrl: 'bill-pay/pay/pay.tpl.html',
            controller: 'PayController',
            controllerAs: '$ctrl',
          },
        },
      })
      // .state('udb.billPay.activity', {
      //   url: '/activity',
      //   views: {
      //     billpay: {
      //       templateUrl: 'bill-pay/activity/activity.tpl.html',
      //       controller: 'ActivityController',
      //       controllerAs: '$ctrl',
      //     },
      //   },
      // })
      .state('udb.billPay.searchRecipient', {
        url: '/search-recipient',
        views: {
          billpay: {
            templateUrl: 'bill-pay/add-electronic-recipient/search-recipient/search-recipient.tpl.html',
            controller: 'SearchRecipientController',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('udb.billPay.schedulePayment', {
        url: '/schedule-payment',
        params: { recipient: null, callback: '', recipientId: null },
        views: {
          billpay: {
            templateUrl: 'bill-pay/schedule-payment/schedule-payment.tpl.html',
            controller: 'SchedulePaymentController',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('udb.billPay.eStatements.confirmation', {
        url: '/confirmation',
        views: {
          eStatements: {
            template: '<app-e-statements-confirmation></app-e-statements-confirmation>',
          },
        },
      })
      .state('udb.billPay.updateRecurrent', {
        url: '/schedule-payment/update-series',
        views: {
          billpay: {
            templateUrl: 'bill-pay/update-recurrent-payment/update-recurrent-payment.tpl.html',
            controller: 'UpdateRecurrentPayment',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('udb.billPay.updateSingle', {
        url: '/schedule-payment/update-single-payment',
        views: {
          billpay: {
            templateUrl: 'bill-pay/update-single-payment/update-single-payment.tpl.html',
            controller: 'UpdateSinglePayment',
            controllerAs: '$ctrl',
          },
        },
      })
      .state('udb.billPay.updatedConfirmation', {
        url: '/updated-payment-confirmation',
        views: {
          billpay: {
            templateUrl:
              'bill-pay/update-single-payment/updated-payment-confirmation/updated-payment-confirmation.tpl.html',
            controller: 'UpdatedPaymentConfirmation',
            controllerAs: '$ctrl',
          },
        },
      });
  }
}
