<div class="position-view-all">
  <div class="my-position">My Position</div>
  <button
    *ngIf="!loadingPositions && position"
    type="button"
    class="btn-link view-all row no-underline"
    (click)="isExpanded = !isExpanded"
  >
    <div class="view-all view-all-des">View {{isExpanded ? "Fewer" : "All"}}</div>
    <app-svg-icon
      (click)="isExpanded = !isExpanded"
      [iconName]="isExpanded ? icons.ChevronUp : icons.ChevronDown"
      [styles]="{ width: '14px' }"
      [iconColor]="'#2F5B88'"
    ></app-svg-icon>
  </button>
</div>
<app-dot-loader [showWhen]="loadingPositions">Loading</app-dot-loader>
<div *ngIf="!loadingPositions && position">
  <div class="header-row">
    <div class="column-header-content">
      <div>Market Value</div>
      <app-tooltip
        symbol="?"
        content="This is the value of your stock based on its most recent trading price."
      ></app-tooltip>
    </div>
    <div class="column-header-content">
      <div>Today´s Returns</div>
      <app-tooltip
        symbol="?"
        content="The expected change in your investment’s value from its price at the close of the most recent trading day."
      ></app-tooltip>
    </div>
    <div class="column-header-content">
      <div>Total Return</div>
      <app-tooltip
        symbol="?"
        content="This is the collective value earned or lost since the stock was purchased."
      ></app-tooltip>
    </div>
    <div class="column-header-content">
      <div>Shares</div>
      <app-tooltip
        symbol="?"
        content="The number of units of ownership of this particular stock in your portfolio."
      ></app-tooltip>
    </div>
  </div>
  <div class="position-row">
    <div class="position-row-content"> {{ position.marketValue > 0 ? '+' : '' }} {{position.marketValue | currency: 'USD': 'symbol': '2.2-2'}}</div>
    <div class="position-row-content"> {{ position.todaysReturn > 0 ? '+' : '' }} {{position.todaysReturn | currency: 'USD': 'symbol': '2.2-2'}} ( {{position.todaysReturnPercent > 0 ? '+': ''}} {{position.todaysReturnPercent | number : '1.0-2'}} %)</div>
    <div class="position-row-content"> {{ position.totalReturn > 0 ? '+' : ''}} {{position.totalReturn | currency: 'USD': 'symbol': '2.2-2'}} ( {{position.totalReturnPercent > 0 ? '+' : ''}} {{position.totalReturnPercent | number: '1.0-2'}} %)</div>
    <div class="position-row-content">{{position.shares}}</div>
  </div>
  <div class="header-row" *ngIf="isExpanded">
    <div class="column-header-content">
      <div>Average Cost</div>
      <app-tooltip symbol="?" content="The mean price you paid for all of your shares of this stock."></app-tooltip>
    </div>
    <div class="column-header-content">
      <div>% of Portfolio</div>
      <app-tooltip
        symbol="?"
        content="This represents the portion size of this stock in your overall portfolio."
      ></app-tooltip>
    </div>
  </div>
  <div class="position-row" *ngIf="isExpanded">
    <div class="position-row-content">{{ position.price | currency: 'USD': 'symbol': '1.2-2' }}</div>
    <div class="position-row-content">{{ position.percentageOfPortfolio | number: '1.2-2' }} %</div>
  </div>
</div>
