import { NgModule } from '@angular/core';
import { ShareAccountDetailsModule } from '@app/Areas/AAS/features/share-account-details/share-account-details.module';

import {
  accountAggregationServiceProvider,
  cachedAccountsServiceProvider,
  serviceHelperProvider,
} from '@core/providers';

import { AccountsSharedModule } from '../accounts-shared/accounts-shared.module';
import { externalAccountComponents } from './components';
import { externalAccountViews } from './views';

const importedProviders = [
  accountAggregationServiceProvider,
  serviceHelperProvider,
  cachedAccountsServiceProvider,
];

@NgModule({
  imports: [AccountsSharedModule, ShareAccountDetailsModule],
  declarations: [...externalAccountComponents, ...externalAccountViews],
  exports: [...externalAccountComponents, ...externalAccountViews],
  providers: [...importedProviders],
})
export class ExternalAccountsModule {}
