import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { openClose, slideFromRight, startStaticSlideFromLeft } from '@app/user-profile/animations';
import { EmploymentStatus } from '@app/user-profile/enums/employment-status.enum';
import {
  EmploymentInfo,
  FamilyInfo,
  FinancialInfo,
  UpdateEmailsRequest,
  UpdatePhonesRequest,
} from '@app/user-profile/models';
import { updateProfileDetails } from '@app/user-profile/store/actions';
import {
  getEmploymentStatuses,
  getFinancialCatalogs,
  getIsNewUser,
  getMaritalStatuses,
  getProfileDetails,
  getUserInfoIsOutdated,
} from '@app/user-profile/store/selectors';
import { areAddressesEqual } from '@app/user-profile/utils';
import { OutdatedContactInfoService, UserProfileService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { AlertsIcons } from '@shared/enums';
import { Address, AddressAccountAssociation, CustomerDetail } from '@shared/models';

@Component({
  selector: 'app-personal-information-view',
  templateUrl: './personal-information-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './personal-information-view.component.scss'],
  animations: [openClose, startStaticSlideFromLeft, slideFromRight],
})
export class PersonalInformationViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  expanded = false;

  isMobileDevice = false;
  profileLoading = true;
  catalogsLoading = true;
  userCanEdit = false;
  canEditAddress = false;
  maritalStatusesLoading = true;
  financialCatalogsLoading = true;
  employmentStatusesLoading = true;
  primaryAddressIsIncomplete: boolean;
  mailingAddressIsIncomplete: boolean;
  currentView: PersonalInformationView = null;
  errorIcon = AlertsIcons.ExclamationCircle;
  profileInfo: Partial<CustomerDetail>;
  employmentInfo: EmploymentInfo;
  financialInfo: FinancialInfo;
  familyInfo: FamilyInfo;
  maritalStatuses: Record<string, string>;
  annualIncomes: Record<string, string>;
  totalNetWorths: Record<string, string>;
  liquidNetWorths: Record<string, string>;
  taxBrackets: Record<string, string>;
  employmentStatuses: Record<string, string>;
  occupationLabel = 'Occupation';
  hasOccupation: boolean;
  isEmployed: boolean;
  isIraEnhBaseFlagActive: boolean;
  employerName: string;
  contactInfoIsValid: boolean;

  alertMessages = {
    email: '',
    phone: '',
    address: '',
    employment: '',
    financial: '',
    family: '',
  };
  views = PersonalInformationView;
  animationState: 'initial' | 'in' = 'initial';
  mailingAddress: Address;
  hasOutdatedContactInfo$: Observable<boolean> = of(false);

  private statusesWithOccupation = [
    EmploymentStatus.Retired,
    EmploymentStatus.Unemployed,
    EmploymentStatus.SelfEmployed,
    EmploymentStatus.Employed,
  ];
  private statusesWithMostRecentOccupation = [EmploymentStatus.Unemployed, EmploymentStatus.Retired];

  private subsink = new SubSink();

  constructor(
    private store: Store,
    private userProfileService: UserProfileService,
    private breakpointObserver: BreakpointObserver,
    private outdatedContactInfoService: OutdatedContactInfoService,
    private featureFlagService: FeatureFlagService,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit(): void {
    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.profileInfo = data;
        this.userCanEdit = !this.featureFlagService.isSiteInReadOnly();
        this.recomputeViewValues(data);
        this.profileLoading = false;
        this.contactInfoIsValid = this.isContactInfoValid();
      });

    this.subsink.sink = this.store
      .select(getEmploymentStatuses)
      .pipe(
        switchMap(data => {
          if (!data) {
            return this.userProfileService.getPossibleEmploymentStatuses();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.employmentStatuses = data;

        this.catalogsLoading = false;
      });

    if (this.featureFlagService.isOutdatedContactInfoFlagActive()) {
      this.hasOutdatedContactInfo$ = this.store.select(getUserInfoIsOutdated);
    }

    this.isIraEnhBaseFlagActive = this.featureFlagService.isIraEnhBaseFlagActive();

    this.subsink.sink = this.store.select(getIsNewUser).subscribe({
      next: isNewUser => {
        const readOnly = this.featureFlagService.isSiteInReadOnly();
        const canEditAddress = this.featureFlagService.isAddressModificationEnabled();

        this.userCanEdit = !isNewUser && !readOnly;
        this.canEditAddress = this.userCanEdit && canEditAddress;
      },
    });

    this.loadCatalogs();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  goTo(view: PersonalInformationView): void {
    this.currentView = view;
    this.alertMessages = {
      email: '',
      phone: '',
      address: '',
      employment: '',
      financial: '',
      family: '',
    };
    this.animationState = 'initial';
  }

  goBack(): void {
    this.currentView = null;
    this.animationState = 'in';
  }

  confirmInfoIsUpdated(): void {
    this.outdatedContactInfoService.confirmContactInfoIsUpdated().subscribe();
    sessionStorage.setItem('outdatedContactInfoRemembered', 'true');
  }

  updateEmails(data: UpdateEmailsRequest): void {
    const payload: Partial<CustomerDetail> = {
      ...this.profileInfo,
      primaryEmail: data.primaryEmail,
      alternateEmail: data.alternateEmail,
    };
    this.alertMessages.email = 'You have successfully saved your email.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
    this.goBack();
  }

  updatePhones(data: UpdatePhonesRequest): void {
    const payload: Partial<CustomerDetail> = {
      ...this.profileInfo,
      workPhone: data.workPhone,
      homePhone: data.homePhone,
      cellPhone1: data.cellPhone1,
      cellPhone2: data.cellPhone2,
    };
    this.alertMessages.phone = 'You have successfully saved your phone.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
    this.goBack();
  }

  updateAddresses(data: AddressAccountAssociation[]): void {
    const payload: Partial<CustomerDetail> = {
      ...this.profileInfo,
      primaryAddress: data[0],
      mailingAddress: data[1],
    };
    this.alertMessages.address = 'You have successfully saved your address.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
    this.goBack();
  }

  updateEmploymentInfo(data: EmploymentInfo): void {
    const payload: Partial<CustomerDetail> = { ...this.profileInfo, ...data };
    this.alertMessages.employment = 'You have successfully saved your employment information.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
  }

  updateFamilyInfo(data: FamilyInfo): void {
    const payload: Partial<CustomerDetail> = { ...this.profileInfo, ...data };
    this.familyInfo = data;
    this.alertMessages.family = 'You have successfully saved your family information.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
  }

  updateFinancialInfo(data: FinancialInfo): void {
    const payload: Partial<CustomerDetail> = { ...this.profileInfo, ...data };
    this.alertMessages.financial = 'You have successfully saved your financial information.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
  }

  private isContactInfoValid(): boolean {
    const validEmail = !!this.profileInfo.primaryEmail;
    const validPhone =
      !!this.profileInfo.cellPhone1 &&
      !!this.profileInfo.cellPhone1.number &&
      this.profileInfo.cellPhone1.number !== '0';
    const validHomeAddress = !this.primaryAddressIsIncomplete && !this.mailingAddressIsIncomplete;
    const isValid = validHomeAddress && validPhone && validEmail;

    return isValid;
  }

  private loadCatalogs(): void {
    this.subsink.sink = this.store
      .select(getMaritalStatuses)
      .pipe(
        switchMap(data => {
          if (!data) {
            return this.userProfileService.getPossibleMaritalStatuses();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.maritalStatuses = data;
        this.maritalStatusesLoading = false;
      });

    this.subsink.sink = this.store
      .select(getFinancialCatalogs)
      .pipe(
        switchMap(data => {
          if (!data) {
            return this.userProfileService.getFinancialCatalogs();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.annualIncomes = data.annualIncomes;
        this.totalNetWorths = data.totalNetWorths;
        this.liquidNetWorths = data.liquidNetWorths;
        this.taxBrackets = data.taxBrackets;

        this.financialCatalogsLoading = false;
      });

    this.subsink.sink = this.store
      .select(getEmploymentStatuses)
      .pipe(
        switchMap(data => {
          if (!data) {
            return this.userProfileService.getPossibleEmploymentStatuses();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.employmentStatuses = data;

        this.employmentStatusesLoading = false;
      });
  }

  private recomputeViewValues(data: Partial<CustomerDetail>): void {
    this.primaryAddressIsIncomplete =
      !data.primaryAddress.address.city ||
      !data.primaryAddress.address.stateCode ||
      !data.primaryAddress.address.postalCode ||
      !data.primaryAddress.address.streetAddress1;

    this.mailingAddressIsIncomplete =
      !data.mailingAddress.address.city ||
      !data.mailingAddress.address.stateCode ||
      !data.mailingAddress.address.postalCode ||
      !data.mailingAddress.address.streetAddress1;

    if (!areAddressesEqual(data.primaryAddress.address, data.mailingAddress.address)) {
      this.mailingAddress = data.mailingAddress.address;
    } else {
      this.mailingAddress = null;
    }

    const employmentStatus = data.employmentStatus ? data.employmentStatus.toLowerCase() : undefined;
    this.employmentInfo = new EmploymentInfo({
      employer: data.employer,
      employmentStatus: employmentStatus,
      occupation: data.occupation,
      yearsEmployed: data.yearsEmployed,
      employerAddress: data.employerAddress,
    });

    this.employerName =
      this.employmentInfo.employmentStatus !== EmploymentStatus.Employed ? null : this.employmentInfo.employer;

    if (this.statusesWithMostRecentOccupation.some(status => status === employmentStatus)) {
      this.occupationLabel = 'Most Recent Occupation';
    }

    this.isEmployed = employmentStatus === EmploymentStatus.Employed;

    this.hasOccupation = this.statusesWithOccupation.some(status => status === employmentStatus);

    this.financialInfo = {
      annualIncome: data.annualIncome,
      totalNetWorth: data.totalNetWorth,
      taxBracket: data.taxBracket,
      liquidNetWorth: data.liquidNetWorth,
    };

    this.familyInfo = {
      maritalStatus: data.maritalStatus ?? '',
      numberOfDependents: data.numberOfDependents,
    };
  }
}

export enum PersonalInformationView {
  Email = 'Email',
  Phone = 'Phone Number',
  Address = 'Address',
  Employment = 'Employment Information',
  Financial = 'Financial Information',
  Family = 'Family Information',
}
