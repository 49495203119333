import { PortfolioChartConfiguration } from '@app/axos-trading/models';

export const PORTFOLIO_CHART_CONFIGURATION: PortfolioChartConfiguration[] = [
  {
    min: 2,
    max: 14,
    dataLabelFormat: 'MMMM do, yyyy',
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value at the End of the Day',
  },
  {
    min: 14,
    max: 32,
    dataLabelFormat: 'MMM do',
    timeLabelFormat: 'MMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
  },
  {
    min: 32,
    max: 1095,
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
  },
  {
    min: 366,
    max: -1,
    timeLabelFormat: 'MMMM do, yyyy',
    tooltipLabel: 'Value on ${date}',
  },
];
