<div class="position-list">
  <div class="position-content">
    <!--Header-->
    <div class="header-row">
      <div class="header-title">My Positions</div>
      <div class="d-none d-sm-block">
        <div class="header-column">
          <div class="name-column text-overflow">
            <div class="header-column-content nowrap">
              Name
            </div>
          </div>
          <div class="each-column">
            <div class="header-column-content nowrap">
              Market Value
            </div>
          </div>
          <div class="daych-totval-head-column text-overflow">
            <div class="header-column-content nowrap">
              Day Change
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-none">
        <div class="header-column">
          <div class="name-column">
            <div class="header-column-content nowrap">
              Name
            </div>
          </div>
          <div class="daych-totval-head-column text-overflow">
            <div class="header-column-content nowrap">
              Day Change
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block">
      <div *ngFor="let position of positions">
        <div class="position-row">
          <div class="name-column text-overflow">
            <div class="row-column-content text-overflow nowrap">
              <a class="text-overflow text-overflow asset-name-link" (click)="goDetails(position)">
                {{ position.assetName | slice: 0:20 }}
              </a>
              <div class="symbol-shares-row text-overflow">
                {{ position.symbol | slice: 0:5 }}
                <!--<div class="shares text-overflow">{{ position.shares }} shares</div>-->
                <div class="shares text-overflow">{{ position.shares }} {{ getShares(position.shares) }}</div>
              </div>
            </div>
          </div>
          <div class="each-column">
            <div class="row-column-content nowrap market-value">
              {{ getFormattedAmount(position.marketValue) }}
            </div>
          </div>
          <div class="daych-totval-column" [ngClass]="getDayChangeColor(position)">
            <div class="arrow_day-cha">
              <app-svg-icon
                class="arrow-icon"
                [iconName]="getArrowPosition(position)"
                [iconColor]="position.dayChange === 0 ? '#494949' : position.dayChange > 0 ? '#00805B' : '#C01349'"
                width="17.09px"
                height="16"
              ></app-svg-icon>
              <div class="row-daych-totval-content nowrap">{{ position.dayChangePercentage | number: '1.0-2' }}%</div>
            </div>
            <div class="row-daych-totval-content nowrap">
              {{ getFormattedAmount(position.dayChange) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block d-sm-none position-content">
      <div *ngFor="let position of positions">
        <div class="position-row">
          <div class="name-column text-overflow">
            <div class="row-column-content text-overflow nowrap">
              <a class="text-overflow asset-name-link" (click)="goDetails(position)">
                {{ position.assetName | slice: 0:20 }}
              </a>
              <div class="symbol-shares-row text-overflow">
                {{ position.symbol | slice: 0:5 }}
                <!--<div class="shares text-overflow">{{ position.shares }} shares</div>-->
                <div class="shares text-overflow">{{ position.shares }} {{ getShares(position.shares) }}</div>
              </div>
            </div>
          </div>
          <div class="daych-totval-column" [ngClass]="getDayChangeColor(position)">
            <div class="arrow_day-cha">
              <app-svg-icon
                class="arrow-icon"
                [iconName]="getArrowPosition(position)"
                [iconColor]="position.dayChange === 0 ? '#494949' : position.dayChange > 0 ? '#00805B' : '#C01349'"
                width="10px"
              ></app-svg-icon>
              <div class="row-daych-totval-content nowrap">{{ position.dayChangePercentage | number: '1.0-2' }}%</div>
            </div>
            <div class="row-daych-totval-content nowrap">
              {{ getFormattedAmount(position.dayChange) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
