import { TradeStockType } from '@app/axos-trading/enums';

export enum TransactionType {
  buy = 1,
  sell = 2,
  shortSell = 3,
  buyToCover = 4,
  liquidDate = 5,
}

export const TRANSACTION_TYPE_MAPPING = {
  [TradeStockType.Buy]: TransactionType.buy,
  [TradeStockType.Sell]: TransactionType.sell,
};
