import { Component, Input } from '@angular/core';

import { OrderStatus } from '@app/axos-trading/enums/order-status';
import { Order } from '@app/axos-trading/models';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-recent-orders',
  templateUrl: './recent-orders.component.html',
  styleUrls: ['./recent-orders.component.scss'],
})
export class RecentOrdersComponent {
  @Input() orders: Order[] = [];
  @Input() accountId: string;
  iconName: string;

  icons = {
    rightIcon: NavigationIcons.ArrowForward,
  };

  orderStatus = OrderStatus;
  constructor(private routeHelper: TradingRoutingHelperService) {}

  goToAllActivity() {
    this.routeHelper.goToTransactions(+this.accountId);
  }

  hasPendingStatus(order: Order) {
    return ![OrderStatus.ORDER_EXECUTED, OrderStatus.CANCELLATION_OPERATION, OrderStatus.ORDER_REJECTED].includes(
      order.status.code as OrderStatus
    );
  }

  getStatusDescription(order: Order) {
    if (order.status.description) {
      return '- ' + order.status.description[0].toUpperCase() + order.status.description.substr(1).toLocaleLowerCase();
    }
  }
}
