import { AxosInvestWalletController } from './axos-invest-wallet.controller';

export const AxosInvestWalletComponent: ng.IComponentOptions = {
  controller: AxosInvestWalletController,
  templateUrl: 'axos-invest/overview/wallet/axos-invest-wallet.tpl.html',
  controllerAs: 'aw',
  bindings: {
    wallet: '<',
  },
};
