import { TransactionTileState } from './transactions-tile-state.type';

export type TransactionsState = {
  accountsTransactions: TransactionTileState[];
  selectedAccountTransactions: string | undefined;
};

export const initialTransactionsState: TransactionsState = {
  accountsTransactions: [],
  selectedAccountTransactions: undefined,
};
