import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

// * Use this module to export all Angular Material modules required for this feature
@NgModule({
  exports: [MatButtonModule, MatIconModule, MatCheckboxModule],
})
export class ShareAccountDetailsMatModule {}
