import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { FilesIcons } from '@shared/enums';
import { StatementsAndDocumentsFacade } from '../../facade/statements-and-documents.facade';
import { StatementStateType, TaxFormStateType } from '../../core/store/types';
import { DatePipe } from '@angular/common';
import { STATE } from '@core/tokens';
import { PdpFacade } from '../../../product-details-page/facade/pdp.facade';
import { Uk2ButtonSizeEnum, Uk2Tier1UtilityEnum } from '@axos/uikit-v2-lib';
import { TileState } from './enums';
import { STATEMENTS_AND_DOCUMENTS_LABELS } from './constants/statements-and-documents-labels.constants';

@Component({
  selector: 'app-statements-and-documents-tile',
  templateUrl: './statements-and-documents-tile.component.html',
  styleUrls: ['./statements-and-documents-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementsAndDocumentsTileComponent implements OnInit, OnDestroy {
  selectedAccountDocuments: string = '';
  selectedAccountStatementsLatestThree: StatementStateType[] = [];
  selectedAccountTaxFormsLatestThree: TaxFormStateType[] = [];
  statementsIcon = FilesIcons.FilePdf;
  downloadIcon = FilesIcons.DownloadFile;
  documents: (StatementStateType | TaxFormStateType)[] = [];
  viewAllButtonSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.medium;
  uk2UtilityIcons = Uk2Tier1UtilityEnum;
  tileStates = TileState;
  tileState: TileState;
  errorStateLabels = {
    title: '',
    body: '',
  };

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(STATE) private readonly state: ng.ui.IStateService,
    private statementsAndDocumentsFacade: StatementsAndDocumentsFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private pdpFacade: PdpFacade
  ) {}

  ngOnInit(): void {
    this.statementsAndDocumentsFacade?.selectedAccountDocuments$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(accountNumber => {
        this.selectedAccountDocuments = accountNumber;
      });

    this.getLatestThreeStatmentsAndTaxForms().pipe(takeUntil(this.destroy$)).subscribe();
  }

  getLatestThreeStatmentsAndTaxForms() {
    this.tileState = TileState.Loading;
    const latestThreeStatements$ = this.statementsAndDocumentsFacade?.selectedAccountStatementsLatestThree$.pipe(
      filter(state => state !== undefined)
    );
    const latestThreeTaxForms$ = this.statementsAndDocumentsFacade?.selectedAccountTaxFormsLatestThree$.pipe(
      filter(state => state !== undefined)
    );

    return combineLatest([latestThreeStatements$, latestThreeTaxForms$]).pipe(
      tap(([statementsResponse, taxFormsResponse]) => {
        this.selectedAccountStatementsLatestThree = statementsResponse.statements;
        this.selectedAccountTaxFormsLatestThree = taxFormsResponse.taxForms;
        if (!statementsResponse.apiCallWasSuccesful || !taxFormsResponse.apiCallWasSuccesful) {
          this.tileState =
            statementsResponse.apiCallWasSuccesful || taxFormsResponse.apiCallWasSuccesful
              ? TileState.PartialError
              : TileState.TotalError;
          this.errorStateLabels.title = STATEMENTS_AND_DOCUMENTS_LABELS[this.tileState].errorTitle;
          this.errorStateLabels.body = STATEMENTS_AND_DOCUMENTS_LABELS[this.tileState].errorContent;
        } else {
          this.tileState = TileState.Success;
        }
        this.updateDocumentsArray();
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  updateDocumentsArray(): void {
    this.documents = [...this.selectedAccountTaxFormsLatestThree, ...this.selectedAccountStatementsLatestThree];

    this.documents?.sort((a, b) => {
      const aDate = 'statementDate' in a ? a.statementDate : a.taxFormDate;
      const bDate = 'statementDate' in b ? b.statementDate : b.taxFormDate;

      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    this.documents = this.documents?.slice(0, 3);

    this.documents = this.documents?.filter(function (element) {
      return element !== undefined;
    });

    const updatedDocuments = this.documents?.map(document => {
      if ('statementDate' in document) {
        const statementDate = new Date(document?.statementDate);
        return { ...document, statementDate: this.formatStatementDate(statementDate) };
      } else {
        const taxdate = new Date(document?.taxFormDate);
        return { ...document, taxFormDate: this.formatTaxFormDate(taxdate) };
      }
    });
    this.documents = updatedDocuments;
  }

  formatStatementDate(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return this.datePipe?.transform(date, 'MMMM d, yyyy');
  }

  formatTaxFormDate(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return this.datePipe?.transform(date, 'yyyy');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showOrDownloadDocument(document: any, show: boolean): void {
    if (document.id) {
      var statementDate = this.datePipe.transform(document.statementDate, 'MMMM d, yyyy');
      this.statementsAndDocumentsFacade?.downloadStatement(
        (document.accountNumber = this.selectedAccountDocuments),
        statementDate,
        document.statementName,
        show
      );
    } else if (document.taxYear) {
      this.statementsAndDocumentsFacade?.downloadTaxForm(
        (document.accountNumber = this.selectedAccountDocuments),
        document.taxFormType,
        document.taxYear,
        document.hostKey,
        document.taxFormName,
        show
      );
    }
  }

  goToDetails() {
    const componentTab: string = 'Statements/Tax Forms';
    this.pdpFacade.setTransactionSelectedTab(componentTab);
    let state = 'udb.accounts.container';
    let accountType = 'Advisor';
    this.state.go(state, {
      id: this.selectedAccountDocuments,
      type: accountType,
      tab: 0,
    });
  }
}
