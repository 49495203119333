<app-dot-loader *ngIf="isLoading">Loading</app-dot-loader>
<div *ngIf="!isLoading">
    <h3 class="mb-2"> I want to... </h3>
    <p *ngIf="!isHideResearchTab">
        <a id="research" class="link" (click)="goToResearch()"> Find and trade securities </a>
    </p>
    <p>
        <a id="enhanced-trading" class="link" [href]="axosOrbisUrl" target="_blank"> Enhanced trading </a>
    </p>
    <p>
        <a id="move-money" class="link" (click)="goToMoveMoney()"> Move money </a>
    </p>
</div>
