import { ConsentStatus, IraPlanCode } from '@app/accounts/enums';

export class SpousalConsentRequest {
  consentRequestStatus: ConsentStatus;
  taxPlanType: IraPlanCode;
  spouseEmail: string;
  accountNumber: string;

  constructor(data?: Partial<SpousalConsentRequest>) {
    Object.assign(this, data);
  }
}
