import { Address } from '@shared/models';

/** Represents the employment information of the user. */
export class EmploymentInfo {
  /** User's employer. */
  employer = '';
  /** User's occupation. */
  occupation = '';
  /** User's employment status (unemployed/employed). */
  employmentStatus = '';
  /** User's years working for the given employer. */
  yearsEmployed: number | null = 0;
  /** User's employer address. */
  employerAddress: Address | null;

  constructor(data?: Partial<EmploymentInfo>) {
    Object.assign(this, data);
  }
}
