import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { SubCategoryEventData } from '@app/messages/models';
import { turnOffFunding } from '@app/store/funding/funding.actions';
import { ROOT_SCOPE, STATE, STATE_PARAMS, ngRedux } from '@core/tokens';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { Store } from '@ngrx/store';
import { IRootScopeService } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import NgRedux from 'ng-redux';

@Component({
  selector: 'app-cm-message',
  templateUrl: './cm-message.component.html',
  styleUrls: ['./cm-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmMessageComponent implements OnInit {
  @Input() subCategoryData: SubCategoryEventData;
  public categoryId: number;
  public businessId: number;
  public categoryName: string;
  public subCategoryId: number;
  public subCategoryName: string;
  public uploadEndpoint: string;

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(STATE_PARAMS) private stateParams: IStateParamsService,
    @Inject(ngRedux) private ngredux: NgRedux.INgRedux,
    @Inject(ROOT_SCOPE) private rootScope: IRootScopeService,
    private store: Store
  ) {}
  ngOnInit(): void {
    const { categoryId, categoryName, subcategoryId, subcategoryName, businessId } = this.stateParams;
    if (this.stateParams !== undefined) {
      this.subCategoryData = {
        categoryId: categoryId,
        businessId: businessId || '',
        categoryName: categoryName,
        subCategoryId: subcategoryId,
        subCategoryName: subcategoryName,
      };
    }
  }

  /** Goes to the confirmation page for this section */
  public goToConfirmation(): void {
    if (this.ngredux.getState().funding.isRunning) this.fundingTurnOff();

    this.state.go('udb.messages.confirmation');
  }

  /**Turnoff funding process */
  private fundingTurnOff(): void {
    this.ngredux.dispatch(FundingActions.turnOffFunding());
    this.store.dispatch(turnOffFunding());
    sessionStorage.removeItem('accountsToBeFunded');
    const fundingPromise = this.rootScope['accountsWithOutFundsPromise'];
    fundingPromise.resolve();
  }
}
