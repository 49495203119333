<up-section-heading
  section="Profile Icon"
  [mobile]="isMobileDevice"
  [expanded]="expanded"
  (expand)="expanded = $event"
></up-section-heading>

<p class="mb-3 view-subtitle d-none d-md-block">
  Personalize your profile by selecting a color and icon.
</p>

<div class="mx-3 mx-sm-5 mobile-content" *ngIf="!isMobileDevice || expanded" @openClose [@.disabled]="!isMobileDevice">
  <app-alert-notification *ngIf="showAlert" [duration]="3000" (dismiss)="showAlert = false">
    You have successfully saved your profile image.
  </app-alert-notification>

  <div class="text-center mt-5 mb-4">
    <div class="preview" [style.backgroundColor]="selectedColor">
      <app-svg-icon
        *ngIf="selectedIcon !== 'initials'; else initialsText"
        size="2.875rem"
        [iconName]="selectedIcon"
      ></app-svg-icon>

      <ng-template #initialsText>
        {{ initials }}
      </ng-template>
    </div>
    <p class="mt-2">Preview</p>
  </div>

  <p class="mb-4">Colors</p>
  <div id="colours" class="items-container my-3">
    <button
      type="button"
      class="colour-btn mx-2 mx-sm-4 mx-md-2 mx-lg-4 my-2"
      *ngFor="let item of availableColors"
      [class.selected]="selectedColor === item.color"
      [style.backgroundColor]="item.color"
      [attr.aria-label]="item.name"
      (click)="changeColor(item.color)"
    ></button>
  </div>

  <p class="mb-4">Icons</p>
  <div id="icons" class="items-container my-3">
    <button
      type="button"
      class="icon-btn mx-2 mx-sm-4 mx-md-2 mx-lg-4 my-2 initials-btn"
      [class.selected]="selectedIcon === 'initials'"
      aria-label="My Initials"
      [innerText]="initials"
      (click)="changeIcon('initials')"
    ></button>
    <button
      type="button"
      class="icon-btn mx-2 mx-sm-4 mx-md-2 mx-lg-4 my-2"
      [class.selected]="selectedIcon === item.icon"
      *ngFor="let item of availableIcons"
      [attr.aria-label]="item.label"
      (click)="changeIcon(item.icon)"
    >
      <app-svg-icon [iconName]="item.icon" size="2.25rem"></app-svg-icon>
    </button>
  </div>

  <div class="form__actions mt-5">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="button" class="btn-ok button--lg" (click)="submitInformation()">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="goToSignInAndSecurity()">
        Cancel
      </button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</div>
