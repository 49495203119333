export class Username {
  /** Username. */
  username = '';
  /** Password. */
  password = '';

  constructor(args: Partial<ValidUsername> = {}) {
    Object.assign(this, args);
  }
}

/** Defines a user that is going to be enrolled to the UDB platform. */
export class User extends Username {
  /** First name. */
  firstName = '';
  /** Last name. */
  lastName = '';
  /** Date of birth. */
  dateOfBirth = '';
  /** Social Security Number. */
  ssn = '';
}

export class ValidUsername extends User {
  /** User email in masked format. */
  maskedEmail = '';
  /** User cell phone number in masked format. */
  maskedCellPhone = '';
}
