import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { FastLinkComponent } from './components/fast-link.component';

@NgModule({
  declarations: [
    FastLinkComponent
  ],
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [
    FastLinkComponent
  ],
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class FastLinkModule { }
