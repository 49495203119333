import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Countries, DirectDepositUserActions, IncomeType, SwitchType } from '@app/click-switch/enums';
import { DirectDepositInterceptAction, SubmitTarget, Switch } from '@app/click-switch/models';
import { createReactiveValidatorFromSchema } from '@app/utils';
import { DialogService, DirectDepositService } from '@core/services';
import { STATE } from '@core/tokens';
import { EnrollmentService } from '@legacy/services/enrollment.service';
import { ServiceHelper } from '@legacy/services/service.helper';

import { ClickSwitchBaseComponent } from '../click-switch-base/click-switch-base.component';
import { submittableFormSchema, submittablePartialFormSchema } from './click-switch-submittable-form.schema';

@Component({
  selector: 'app-click-switch-submittable-form',
  templateUrl: './click-switch-submittable-form.component.html',
  styleUrls: ['./click-switch-submittable-form.component.scss'],
})
export class ClickSwitchSubmittableFormComponent extends ClickSwitchBaseComponent implements OnInit, OnDestroy {
  targetDataForm: UntypedFormGroup;
  isValidatingZipCode = false;
  incomeTypes = IncomeType;
  countries = Countries;
  countriesKeys = Object.keys(Countries);
  incomeTypeKeys = Object.keys(IncomeType).filter(item => parseInt(item, 10) > 0);
  isLoading = false;
  existingTarget = false;
  partialDataCompleted = false;
  headerText = 'Your income source could not be identified';
  subHeaderText = 'Please provide more information to complete your request.';

  get isUnavailable(): boolean {
    return this.targetDataForm.invalid;
  }

  private stateName = '';

  constructor(
    @Inject(STATE) state: ng.ui.IStateService,
    private formBuilder: UntypedFormBuilder,
    private enrollmentService: EnrollmentService,
    serviceHelper: ServiceHelper,
    directDepositService: DirectDepositService,
    dialogService: DialogService
  ) {
    super(state, directDepositService, serviceHelper, dialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    window.scrollTo(0, 0);

    if (Object.keys(this.state.params.switchInfo).length > 0) {
      this.userSwitch = this.state.params.switchInfo as Partial<Switch>;
    } else {
      this.state.go('udb.clickswitch.search-target');
    }

    if (this.userSwitch.targetId || this.userSwitch.locationId) {
      this.headerText = 'More information is needed to identify you';
      this.subHeaderText = 'Refer to your paystub or income statement for this information.';
      this.existingTarget = true;
      this.addressIsNeeded(this.userSwitch);
    }
    this.initializeFormBuilder();
  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  goBack(): void {
    this.state.go('udb.clickswitch.search-target');
  }

  goToStartPage(): void {
    this.showLeaveModal(() => {
      if (this.userSwitch.index) {
        this.cancelAndDeleteSwitch(() => {
          this.state.go('udb.clickswitch');
        });
      } else {
        this.state.go('udb.clickswitch');
      }
    });
  }

  getCityByZipCode(zipcode): void {
    this.isValidatingZipCode = true;
    this.enrollmentService
      .getCityAndState(zipcode)
      .then(res => {
        const info = res.data;
        this.targetDataForm.get('city').setValue(info.city);
        this.targetDataForm.get('stateCode').setValue(info.stateCode);
        this.stateName = info.stateName;
      })
      .catch(err => {
        if (err.status !== 404) {
          this.serviceHelper.errorHandler(err);

          return;
        }
      })
      .finally(() => {
        this.isValidatingZipCode = false;
      });
  }

  saveTarget(): void {
    this.isLoading = true;
    this.directDepositService
      .logUserAction(DirectDepositInterceptAction(DirectDepositUserActions.SubmittableReviewContinue))
      .subscribe();

    if (this.userSwitch.targetId || this.userSwitch.locationId) {
      this.updateTarget();
    } else {
      this.saveNewTarget();
    }
  }

  saveNewTarget(): void {
    const dataTarget = this.targetDataForm.value as SubmitTarget;
    dataTarget.incomeType = this.targetDataForm.controls.incometype.value;
    dataTarget.ssnLast4Digits = this.targetDataForm.controls.ssn.value;
    dataTarget.switchType = SwitchType.unknowTarget;
    dataTarget.state = this.stateName;

    this.createSwitch(dataTarget, () => {
      this.state.go('udb.clickswitch.accounts', { userSwitch: this.userSwitch });
    });
  }

  updateTarget(): void {
    const fields = {
      category: this.targetDataForm.controls.incometype.value,
      Custom_SSN4: this.targetDataForm.controls.ssn.value,
      'Custom_Last 4 of SSN': this.targetDataForm.controls.ssn.value,
    };

    if (!this.partialDataCompleted) {
      const addressKey = Object.keys(this.userSwitch.values.fields).find(k => k.includes('Address'));
      if (addressKey) {
        const address = {
          city: this.targetDataForm.controls.city.value,
          line1: this.targetDataForm.controls.street.value,
          line2: `${this.targetDataForm.controls.street2.value}|${this.targetDataForm.controls.country.value}`,
          state: this.targetDataForm.controls.stateCode.value,
          zip: this.targetDataForm.controls.zipcode.value,
        };
        fields[addressKey] = address;
      }
    }

    Object.assign(this.userSwitch.values.fields, fields);

    this.updateSwitch(() => {
      this.userSwitch.incomeType = this.targetDataForm.controls.incometype.value;
      this.state.go('udb.clickswitch.accounts', { userSwitch: this.userSwitch });
    });
  }

  submittableValidator(formControlName: string) {
    const { touched } = this.targetDataForm.get(formControlName);

    const error = this.targetDataForm.errors ? this.targetDataForm.errors[formControlName] : null;

    return touched && error;
  }

  private initializeFormBuilder() {
    const switchDefault = this.setDefaultValues();
    const incomeT = this.getEnumIndexByValue(switchDefault.incomeType);

    if (this.partialDataCompleted) {
      this.targetDataForm = this.formBuilder.group(
        {
          id: this.userSwitch.targetId.toString(),
          targetname: this.userSwitch.name ?? this.state.params.target,
          ssn: this.userSwitch.targetId > 0 ? switchDefault.ssnLast4Digits : '',
          incometype: incomeT,
        },
        {
          validators: createReactiveValidatorFromSchema(submittablePartialFormSchema),
        }
      );
    } else {
      this.targetDataForm = this.formBuilder.group(
        {
          id: switchDefault.id,
          targetname: switchDefault.targetName,
          street: switchDefault.street,
          street2: switchDefault.street2,
          city: switchDefault.city,
          ssn: switchDefault.ssnLast4Digits,
          stateCode: switchDefault.stateCode,
          incometype: incomeT,
          zipcode: switchDefault.zipCode,
          country: switchDefault.country,
        },
        {
          validators: createReactiveValidatorFromSchema(submittableFormSchema),
        }
      );

      this.subsink.sink = this.targetDataForm.get('zipcode').valueChanges.subscribe(value => {
        this.getCityByZipCode(value);
      });
    }
  }

  private setDefaultValues(): SubmitTarget {
    const switchDefault: Partial<SubmitTarget> = {
      id: '',
      targetName: null,
      street: '',
      street2: '',
      city: '',
      ssnLast4Digits: '',
      stateCode: '',
      incomeType: IncomeType.Payroll,
      zipCode: '',
      country: this.countriesKeys[0],
    };

    switchDefault.targetName = !this.userSwitch.values?.fields['name']
      ? this.userSwitch.targetId === 0 && !this.userSwitch.name
        ? this.state.params.target
        : this.userSwitch.name
      : this.userSwitch.values?.fields['name'];

    const mailingAddress =
      this.userSwitch.values?.fields['mailingAddress'] ??
      this.userSwitch.values?.fields['Custom_Company/MailingAddress'];
    switchDefault.ssnLast4Digits = this.userSwitch.values?.fields['Custom_SSN4'] ?? '';
    switchDefault.street = mailingAddress?.line1 ?? '';
    switchDefault.street2 = mailingAddress?.line2?.split('|')[0] ?? '';
    switchDefault.zipCode = mailingAddress?.zip ?? '';
    switchDefault.city = mailingAddress?.city ?? '';
    switchDefault.stateCode = mailingAddress?.state ?? '';
    switchDefault.incomeType = this.userSwitch.values?.fields['category']
      ? IncomeType[this.userSwitch.values?.fields['category'] as keyof typeof IncomeType]
      : this.userSwitch.incomeType ?? IncomeType.Payroll;

    return switchDefault as SubmitTarget;
  }

  private addressIsNeeded(switchInfo: Partial<Switch>) {
    if (switchInfo.values && switchInfo.values.fields) {
      const fieldsString = JSON.stringify(switchInfo.values.fields);
      if (fieldsString.indexOf('MailingAddress') < 0) {
        this.partialDataCompleted = true;
      }
    }
  }

  private getEnumIndexByValue(incomeType): number {
    let incomeT = 1; // Payroll
    if (Object.values(IncomeType).includes(incomeType.toString())) {
      incomeT = parseInt(Object.keys(IncomeType)[Object.values(IncomeType).indexOf(incomeType.toString())], 10);
    } else {
      incomeT = incomeType;
    }

    return incomeT;
  }
}
