export enum DebitCardStatus {
  Active,
  Closed,
  Deleted,
  Expired,
  HotCard,
  InProcess,
  Limits,
  Manually,
  OrderNew,
  PinMailer,
  Reorder,
  Securomatic,
  Warm,
  PinRetries,
  DoNotReorder,
}
