<h3 class="mt-5 mb-4">
  How do I request a transfer to an account outside of {{ settings.brandName }} (an external
  transfer)?
</h3>

<h4 class="mb-3">SET UP THE EXTERNAL ACCOUNT</h4>

<ol class="mb-3">
  <li>
    Click on
    <b>Move Money tab</b>
    and select the
    <b>External Accounts.</b>
  </li>
  <li>
    Select
    <b>“Add New Account.”</b>
    You will be prompted to enter account details and acknowledge that you are an owner on the
    non-{{ settings.brandName }} external account in order to set it up for transfers and payments.
  </li>
  <li>
    <p class="mb-3">
      To help us verify that you are the account owner, you can select one of two options:
    </p>

    <ol>
      <li>
        You can provide your user name and password to the other financial institution. Once this is
        verified by your other financial institution, the transfers can begin immediately.
      </li>
      <li>
        we can deposit two small deposits into your non-{{ settings.brandName }} account. You will
        see these deposits post to your external account within 1-3 business days. Under the
        <b>EXTERNAL ACCOUNTS</b>
        tab, your account will be in a
        <b>PENDING ACTIVATION</b>
        status. Simply click on the round setting icon on the right side of the external account. A
        pop up will display and ask you to enter the amount of the two small deposits that were
        credited to your external account. Once you’ve confirmed these two deposit amounts, your
        account will be considered
        <b>ACTIVE</b>
        and will appear on the
        <b>"From Account"</b>
        and
        <b>"To Account"</b>
        drop-down menus on the
        <b>"Move Money"</b>
        page.
      </li>
    </ol>
  </li>
</ol>

<h4 class="mb-3">REQUEST THE TRANSFER</h4>

<ol>
  <li>
    Click on the
    <b>Move Money tab</b>
    and select
    <b>Scheduled Transfers,</b>
    press the “Set Up New Transfer” button.
  </li>
  <li>
    Completed transfers will be listed in your online account activity and on your account
    statements.
  </li>
</ol>
