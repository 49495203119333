<div class="statement-tax-forms">
  <h2 class="statement-tax-forms__title text-center text-md-left">
    Statements/Tax Forms
  </h2>

  <app-dot-loader *ngIf="loading">Loading Statements/Tax Forms</app-dot-loader>

  <app-alert-notification *ngIf="error" alertType="error" (dismiss)="error = false">
    There was an error retrieving your documents. Please try again later.
  </app-alert-notification>

  <div class="statement-tax-forms__content no-gutters" *ngIf="!loading && displayStatementOptions">
    <div class="col-12 col-md-12">
      <div class="header__title">
        <h4>Delivery Preferences</h4>
      </div>
      <div class="frow">
        <input
          type="radio"
          id="eStatements"
          name="eStatements"
          [(ngModel)]="deliveryPref"
          class="ng-pristine ng-untouched ng-valid ng-empty"
          [value]="0"
        />
        <label for="eStatements">I Would like to receive E-Statements regarding {{ account?.nickname }}.</label>
      </div>
      <div class="frow">
        <input
          type="radio"
          id="paperStatements"
          name="paperStatements"
          [(ngModel)]="deliveryPref"
          class="ng-pristine ng-untouched ng-valid ng-empty"
          [value]="1"
        />
        <label for="paperStatements">
          I Would like to receive paper statements regarding {{ account?.nickname }} via mail.
        </label>
      </div>
      <div class="frow">
        <a href="https://www.axosbank.com/-/media/Axos/Documents/Legal/Online-Access-Agreement.pdf" target="_blank">
          View information regarding E-Statements
        </a>
      </div>
      <br />
      <div class="frow">
        <button type="button" class="statement-tax-forms__delivery" (click)="updateDeliveryPreferences()">
          <span>Update Preferences</span>
        </button>
      </div>
    </div>
  </div>

  <div class="no-gutters" *ngIf="years?.length > 0">
    <label for="selectedYear">Choose a Year</label>
    <select
      [(ngModel)]="selectedYear"
      (ngModelChange)="filterDocumentsByYear($event)"
      id="selectedYear"
      name="selectedYear"
      class="col-12 col-md-3 uk-input"
      [disabled]="this.isDownloading"
    >
      <option *ngFor="let year of years" [ngValue]="year">{{ year }}</option>
    </select>
  </div>

  <div class="statement-tax-forms__content no-gutters" *ngIf="!loading">
    <div class="col-12 col-md-9">
      <h4>
        Statements
      </h4>
      <span *ngIf="statementDocuments?.length === 0" class="statement-tax-forms__empty">
        No Statements found
      </span>
      <div class="statement-tax-forms__documents">
        <button
          type="button"
          *ngFor="let statement of statementDocuments"
          class="statement-tax-forms__statements"
          (click)="showStatement(statement.doc_id, statement.key, statement.doc_date)"
          [disabled]="this.isDownloading"
        >
          <div class="d-flex align-items-center">
            <app-svg-icon
              [iconName]="statementsIcon"
              iconColor="var(--primary)"
              width="1.5rem"
              height="1.5rem"
            ></app-svg-icon>
            <span class="ml-3" [innerText]="statement.doc_date | date: 'MM/dd/yyyy'"></span>
          </div>
          <span class="links">
            {{ this.isDownloading && this.docID === statement.doc_id ? 'Downloading...' : 'View' }}
          </span>
        </button>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <h4>Tax Forms</h4>
      <span *ngIf="taxDocuments?.length === 0" class="statement-tax-forms__empty">
        You do not currently have any documents available. You will only receive a 1099-INT if you accrued $10 or more
        of interest during the year
      </span>
      <span *ngIf="account?.relationshipType === 'J'" class="d-block statement-tax-forms__empty">
        If you are a Joint account holder on an account, you do not have access to tax forms on that account. The
        Primary account holder will need to log in to access those forms.
      </span>
      <div class="statement-tax-forms__documents">
        <button
          type="button"
          *ngFor="let tax of taxDocuments"
          class="statement-tax-forms__tax"
          [innerText]="tax.displayname"
          (click)="showTax(tax.doc_id, tax.key, tax.doc_date)"
        ></button>
      </div>
    </div>
  </div>
</div>
