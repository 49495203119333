export const ACCOUNTS = {
  AGGREGATED: {
    LOAD: 'LOAD_AGGREGATED_ACCOUNTS',
    ADD: 'ADD_AGGREGATED_ACCOUNTS',
    UPDATE: 'UPDATE_AGGREGATED_ACCOUNTS',
    REMOVE: 'REMOVE_AGGREGATED_ACCOUNTS',
    FLUSH: 'FLUSH_AGGREGATED_ACCOUNTS',
    NICKNAME_UPDATE: 'ACCOUNT_NICKNAME_UPDATE',
    UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  },
  EXTERNAL: {
    LOAD: 'LOAD_EXTERNAL_ACCOUNTS',
    ADD: 'ADD_EXTERNAL_ACCOUNTS',
    UPDATE: 'UPDATE_EXTERNAL_ACCOUNTS',
    REMOVE: 'REMOVE_EXTERNAL_ACCOUNTS',
    FLUSH: 'FLUSH_EXTERNAL_ACCOUNTS',
  },
  INTERNAL: {
    LOAD: 'LOAD_INTERNAL_ACCOUNTS',
    ADD: 'ADD_INTERNAL_ACCOUNTS',
    UPDATE: 'UPDATE_INTERNAL_ACCOUNTS',
    REMOVE: 'REMOVE_INTERNAL_ACCOUNTS',
    FLUSH: 'FLUSH_INTERNAL_ACCOUNTS',
    ACCOUNTS_LOADED: 'INTERNAL_ACCOUNTS_LOADED',
    BALANCE_LOADED: 'INTERNAL_ACCOUNTS_BALANCE_LOADED',
    INTERCEPTORS_LOADED: 'INTERNAL_ACCOUNTS_INTERCEPTORS_LOADED',
  },
  TRADING: {
    LOAD: 'LOAD_TRADING_ACCOUNTS',
    ADD: 'ADD_TRADING_ACCOUNTS',
    UPDATE: 'UPDATE_TRADING_ACCOUNTS',
  },
};
