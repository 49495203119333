import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DebitCardActivity } from '@app/accounts/submodules/debit-card/models';
import { AlertsIcons, FinancialIcons, UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-debit-card-activity',
  templateUrl: './debit-card-activity.component.html',
  styleUrls: ['./debit-card-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebitCardActivityComponent {
  @Output() delete = new EventEmitter<number>();

  @Input() loading: boolean;
  @Input() set activities(value: DebitCardActivity[]) {
    this.activityLog = value.map(activity => {
      activity.icon = this.getActivityIcon(activity);

      return activity;
    });
  }

  activityLog: DebitCardActivity[] = [];

  iconConfig = {
    size: '1.5rem',
    trash: {
      icon: UtilityIcons.Trash,
      size: '1.4rem',
    },
  };

  getActivityIcon(activity: DebitCardActivity): string {
    let icon: string;

    if (activity.actionType === 3) {
      icon = UtilityIcons.PaperAirplane;
    } else if (activity.actionType === 4) {
      icon = AlertsIcons.InfoCircle;
    } else if (activity.actionType === 8 || activity.actionType === 9) {
      icon = FinancialIcons.CoinDollarSign;
    } else {
      icon = FinancialIcons.CreditCard;
    }

    return icon;
  }
}
