import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IHttpService, IQService } from 'angular';
import { TileInformation } from '@uikit/tiles';
import { Inject } from 'decorators/decorators';
import { UserTile, UserTileSetting } from 'models';
import { TileStore, ObservableResponse, StoreTile } from 'models';
import { ObservableBaseService } from 'services/observable-base.service';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class TilesService extends ObservableBaseService {
  userTiles$: Observable<UserTile[]>;
  tileStore: TileStore;

  private userTilesSubject: BehaviorSubject<UserTile[]>;

  constructor(http: IHttpService, env: any, serviceHelper: IServiceHelper, q: IQService) {
    super(http, env, 'tiles', serviceHelper, q);
    this.userTilesSubject = new BehaviorSubject<UserTile[]>([]);
    this.userTiles$ = this.userTilesSubject.asObservable();
  }

  /**
   * Gets the user tiles for the dashboard.
   * @returns A promise containing a list of user tiles.
   */
  getUserTiles(): Observable<UserTile[]> {
    return this.get<UserTile[]>().pipe(
      map(res => {
        this.userTilesSubject.next(res.data);

        return res.data;
      })
    );
  }

  /**
   * Saves the order of the tiles as they appear in the dashboard.
   * @param tilesOrder User tiles ordered as they appear in the dashboard.
   * @returns A promise that can be resolved in case of success.
   */
  saveTilesOrder(tilesOrder: UserTile[]): ObservableResponse<void> {
    return this.post('save/order', tilesOrder);
  }

  /**
   * Gets the tiles catalog that will appear in the tile store.
   * @returns A promise containing a list of tiles.
   */
  getTileStore(): Observable<TileStore> {
    if (this.tileStore) {
      return of(this.tileStore);
    }

    return this.get<TileStore>('store').pipe(
      map(res => {
        this.tileStore = res.data;

        return res.data;
      })
    );
  }

  /**
   * Saves the settings of the specified tile.
   * @param tileId ID of the tile to save settings.
   * @param settings Settings to be saved.
   * @returns A promise containing a list of the updated settings.
   */
  saveTileSettings(tileId: number, settings: UserTileSetting[]): Observable<UserTileSetting[]> {
    const { userTileId } = settings[0];

    return this.put(`${tileId}/save/settings`, settings).pipe(
      tap((res: OlbResponse<UserTileSetting[]>) => {
        const currentTiles = this.userTilesSubject.getValue();
        const currentTile = currentTiles.find(
          tile => tile.tileId == +tileId && tile.instanceId == +userTileId
        );

        currentTile.instanceId = res.data[0].userTileId;
        currentTile.settings = res.data;

        this.userTilesSubject.next(currentTiles);

        return res;
      }),
      map((res: OlbResponse<UserTileSetting[]>) => res.data)
    );
  }

  public validateDuplicateAccounts(accounts : any[]) : boolean {
    let hasDuplicates :boolean = false;

    for(const account of accounts) {
        if(!account || account === '')
        {
          continue;
        }
        if (accounts.filter( acc => acc === account).length > 1) {
          hasDuplicates = true;
          break;
        }
    }

    return hasDuplicates; 
  } 



  /**
   * Creates a new instance of the specified tile.
   * @param tileId ID of the tile, from the store, to be created.
   * @returns A promise containing a new instance of the given tile.
   */
  createTile(tileId: number): ObservableResponse<UserTile> {
    return this.post(tileId.toString()).pipe(
      tap(res => {
        const currentTiles = this.userTilesSubject.getValue();
        res.data.isNew = true;

        this.userTilesSubject.next([...currentTiles, res.data]);
      })
    );
  }

  /**
   * Deletes the specified instance of a tile.
   * @param userTileId ID of the tile to be deleted.
   * @returns A promise that can be resolved in case of success.
   */
  deleteTile(userTileId: number): ObservableResponse<void> {
    return this.delete(userTileId.toString()).pipe(
      tap(() => {
        const currentTiles = this.userTilesSubject.getValue();

        this.userTilesSubject.next(currentTiles.filter(tile => tile.instanceId != userTileId));
      })
    );
  }

  /**
   * Adds a new tile to the local tile references.
   * @param tile Tile information to be added.
   */
  addDefaultTile(tile: StoreTile): void {
    const currentTiles = this.userTilesSubject.getValue();
    this.userTilesSubject.next([...currentTiles, new UserTile(tile.id, tile.settings, 0, true)]);
  }

  /**
   * Removes a tile from the local tile references.
   * @param tile Tile to be removed.
   */
  removeDefaultTile(tile: TileInformation): void {
    const currentTiles = this.userTilesSubject.getValue();
    const filteredTiles = currentTiles.filter(
      currentTile =>
        currentTile.instanceId !== tile.instanceId ||
        (currentTile.instanceId === 0 && currentTile.tileId !== tile.tileId)
    );

    this.userTilesSubject.next(filteredTiles);
  }
}
