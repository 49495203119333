import { IStateService, IStateParamsService } from 'angular-ui-router';
import { Inject } from 'decorators/decorators';
import { UploadService } from 'services/upload.service';

@Inject('$state', '$stateParams', 'serviceHelper', 'uploadService')
export class UploadCardController {
  accountNumber: string;
  docName: string;
  errorMessage: string;
  document: any;
  isFileUploading: boolean;
  imageSource: any;
  uploaderText: string = 'Upload File';

  constructor(
    private state: IStateService,
    private stateParams: IStateParamsService,
    private serviceHelper: IServiceHelper,
    private uploadService: UploadService
  ) {
    this.accountNumber = this.stateParams['accountNumber'];
  }

  /** Initializes any required data */
  $onInit() {
    this.imageSource = '/img/icons/tiles/upload-illustration.svg';
  }

  validateFile($file: any, $invalidFiles: any) {
    this.errorMessage = '';

    if ($invalidFiles != null && $invalidFiles.length > 0) {
      if ($invalidFiles[0].$error == 'maxSize') {
        this.errorMessage = 'The maximum file size is 10MB.';
      }

      if ($invalidFiles[0].$error == 'pattern') {
        this.errorMessage =
          'Please, select a valid file type. Acceptable file types are: JPEG, JPG, GIF, PNG TIFF, TIF, PDF';
      }
      return;
    }

    if (typeof $file !== 'undefined' && $file !== null) {
      this.document = $file;
      this.docName = $file['name'];

      this.imageSource = 'assets/upload-check-illustration.svg';
      this.uploaderText = this.truncateFileName(this.docName);
    }
  }

  /** Upload the documents and the data to the specified endpoint */
  uploadFile() {
    if (!this.document) {
      this.errorMessage = 'Please select a file to be uploaded.';
      return;
    }

    this.isFileUploading = true;

    const data = {
      file: this.document,
      documentName: this.docName,
      accountNumber: this.accountNumber,
    };

    this.uploadService
      .upload('SignatureCards/upload', data)
      .then(() => {
        this.state.go('udb.signaturecard.signature-card-upload-complete');
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isFileUploading = false;
      });
  }

  /**
   * Gives format to the file name truncating it to at certain length
   * @param fileName The name of the file
   */
  private truncateFileName(fileName: string) {
    if (fileName.length <= 45) {
      return fileName;
    }

    return fileName.substr(0, 15) + '...' + fileName.substr(fileName.length - 10, fileName.length);
  }
}
