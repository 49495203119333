import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { openClose, slideFromRight, startStaticSlideFromLeft } from '@app/user-profile/animations';
import { EmploymentStatus } from '@app/user-profile/enums';
import { EmploymentInfo } from '@app/user-profile/models';
import { updateProfileDetails } from '@app/user-profile/store/actions';
import { getEmploymentStatuses, getProfileDetails } from '@app/user-profile/store/selectors';
import { UserProfileService } from '@core/services';
import { ROOT_SCOPE } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { CustomerDetail } from '@shared/models';

@Component({
  selector: 'app-employment-info-view',
  templateUrl: './employment-info-view.component.html',
  styleUrls: ['../../scss/user-profile-view.common.scss', './employment-info-view.component.scss'],
  animations: [slideFromRight, startStaticSlideFromLeft, openClose],
})
export class EmploymentInfoViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.expanded')
  expanded = false;

  isMobileDevice = false;
  profileLoading = true;
  catalogsLoading = true;
  userCanEdit = false;
  currentView = EmploymentInfoView.EmploymentInfo;
  hasOccupation: boolean;
  isEmployed: boolean;
  isIraEnhBaseFlagActive: boolean;
  views = EmploymentInfoView;
  animationState: 'initial' | 'in' = 'initial';
  alertMessages = {
    employment: '',
  };
  employmentInfo: EmploymentInfo;
  profileInfo: Partial<CustomerDetail>;
  employmentStatuses: Record<string, string>;
  occupationLabel = 'Occupation';

  private statusesWithOccupation = [
    EmploymentStatus.Retired,
    EmploymentStatus.Unemployed,
    EmploymentStatus.SelfEmployed,
    EmploymentStatus.Employed,
  ];
  private statusesWithMostRecentOccupation = [EmploymentStatus.Unemployed, EmploymentStatus.Retired];

  private subsink = new SubSink();

  constructor(
    private store: Store,
    private userProfileService: UserProfileService,
    private breakpointObserver: BreakpointObserver,
    private featureFlagService: FeatureFlagService,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService
  ) {}

  ngOnInit(): void {
    this.subsink.sink = this.breakpointObserver.observe(['(max-width: 767.98px)']).subscribe(result => {
      this.isMobileDevice = result.matches;
    });

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.profileInfo = data;
        this.userCanEdit = !this.featureFlagService.isSiteInReadOnly();
        this.recomputeViewValues(data);

        this.isIraEnhBaseFlagActive = this.featureFlagService.isIraEnhBaseFlagActive();
        this.profileLoading = false;
      });

    this.subsink.sink = this.store
      .select(getEmploymentStatuses)
      .pipe(
        switchMap(data => {
          if (!data) {
            return this.userProfileService.getPossibleEmploymentStatuses();
          } else {
            return of(data);
          }
        })
      )
      .subscribe(data => {
        this.employmentStatuses = data;

        this.catalogsLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  updateEmploymentInfo(data: EmploymentInfo): void {
    const payload: Partial<CustomerDetail> = { ...this.profileInfo, ...data };
    this.alertMessages.employment = 'You have successfully saved your employment information.';
    this.store.dispatch(updateProfileDetails({ payload }));
    this.$rootScope['profileInfo'] = { ...payload };
  }

  goTo(view: EmploymentInfoView): void {
    this.currentView = view;
    this.alertMessages = { employment: '' };
    this.animationState = 'initial';
  }

  goBack(): void {
    this.currentView = EmploymentInfoView.EmploymentInfo;
    this.animationState = 'in';
  }

  private recomputeViewValues(data: Partial<CustomerDetail>): void {
    const employmentStatus = data.employmentStatus ? data.employmentStatus.toLowerCase() : undefined;
    this.employmentInfo = new EmploymentInfo({
      employer: data.employer,
      employmentStatus: employmentStatus,
      occupation: data.occupation,
      yearsEmployed: data.yearsEmployed,
      employerAddress: data.employerAddress,
    });

    if (this.statusesWithMostRecentOccupation.some(status => status === employmentStatus)) {
      this.occupationLabel = 'Most Recent Occupation';
    }

    this.isEmployed = employmentStatus === EmploymentStatus.Employed;

    this.hasOccupation = this.statusesWithOccupation.some(status => status === employmentStatus);
  }
}

export enum EmploymentInfoView {
  EmploymentInfo = 'Employment Info',
  ChangeEmploymentInfo = 'Employment Information',
}
