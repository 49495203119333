import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { OrdersState } from '../types';

const getOrdersState = createFeatureSelector<OrdersState>(AasStoreFeatures.Orders);

export const getSelectedAccountOrdersTile = createSelector(getOrdersState, state => {
  const { accountsOrders, selectedAccountOrders } = state;
  if (selectedAccountOrders === undefined) {
    return undefined;
  }
  const matchingAccountOrder =
    accountsOrders.find(account => account.accountNumber === selectedAccountOrders) ?? undefined;
  return matchingAccountOrder;
});
