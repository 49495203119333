<mat-drawer-container class="side-menu">
  <mat-drawer
    #sideMenu
    backdrop="false"
    mode="over"
    opened="false"
    [position]="position"
    class="side-menu__container"
  >
    <div class="side-menu__header mx-3">
      <ng-content select="[sidebar-menu-logo]"></ng-content>
      <app-svg-icon
        (click)="toggleMainMenu()"
        class="side-menu__close"
        [iconName]="closeIcon"
        ariaLabel="Close"
        size=".9em"
        role="button"
      ></app-svg-icon>
    </div>
    <nav *ngIf="items">
      <app-side-menu-item
        *ngFor="let menuItem of items"
        [menuItem]="menuItem"
        (toggleMainMenu)="toggleMainMenu()"
      ></app-side-menu-item>
    </nav>
  </mat-drawer>
  <mat-drawer-content>
    <button (click)="toggleMainMenu()" class="side-menu__icon" [attr.aria-expanded]="toggled">
      <ng-content select="[sidebar-menu-icon]"></ng-content>
    </button>
  </mat-drawer-content>
</mat-drawer-container>
