import { Inject, Injectable } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

import {
  addDays,
  addMonths,
  addYears,
  format,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getYear,
  Locale,
  parse,
  parseISO,
  setDay,
  setMonth,
  toDate,
} from 'date-fns';

import { MaterialDateFnsLocale } from './material-date-fns-locale.model';

@Injectable()
export class MaterialDateFnsAdapter extends DateAdapter<Date> {
  private dateFnsLocale: Locale;

  constructor(@Inject(MAT_DATE_LOCALE) dateLocale: MaterialDateFnsLocale) {
    super();

    super.setLocale(dateLocale.localeKey);

    this.dateFnsLocale = dateLocale.locale;
  }

  addCalendarDays(date: Date, days: number): Date {
    return addDays(date, days);
  }

  addCalendarMonths(date: Date, months: number): Date {
    return addMonths(date, months);
  }

  addCalendarYears(date: Date, years: number): Date {
    return addYears(date, years);
  }

  clone(date: Date): Date {
    return toDate(date);
  }

  createDate(year: number, month: number, date: number): Date {
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const result = this.createDateWithOverflow(year, month, date);
    if (result.getMonth() !== month) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  deserialize(value: any): Date | null {
    let res: Date = null;

    if (typeof value === 'string') {
      res = parseISO(value);
    }

    if (typeof value === 'number') {
      res = toDate(value);
    }

    if (value instanceof Date) {
      res = this.clone(value as Date);
    }

    return res;
  }

  format(date: Date, displayFormat: string): string {
    return format(date, displayFormat, { locale: this.dateFnsLocale });
  }

  getDate(date: Date): number {
    return getDate(date);
  }

  getDateNames(): string[] {
    return this.getRange(1, 31).map(day => String(day));
  }

  getDayOfWeek(date: Date): number {
    return getDay(date);
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const map = {
      long: 'EEEE',
      short: 'EEE',
      narrow: 'EEEEE',
    };

    const formatStr = map[style];
    const date = new Date();

    return this.getRange(0, 6).map(month =>
      format(setDay(date, month), formatStr, {
        locale: this.dateFnsLocale,
      })
    );
  }

  getFirstDayOfWeek(): number {
    return this.dateFnsLocale.options.weekStartsOn;
  }

  getMonth(date: Date): number {
    return getMonth(date);
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const map = {
      long: 'LLLL',
      short: 'LLL',
      narrow: 'LLLLL',
    };

    const formatStr = map[style];
    const date = new Date();

    return this.getRange(0, 11).map(month =>
      format(setMonth(date, month), formatStr, {
        locale: this.dateFnsLocale,
      })
    );
  }

  getNumDaysInMonth(date: Date): number {
    return getDaysInMonth(date);
  }

  getYear(date: Date): number {
    return getYear(date);
  }

  getYearName(date: Date): string {
    return format(date, 'yyyy', {
      locale: this.dateFnsLocale,
    });
  }

  invalid(): Date {
    return new Date(NaN);
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }

  isValid(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  parse(value: any, parseFormat: any): Date | null {
    let res: Date = null;

    if (typeof value === 'string') {
      res = parse(value, parseFormat, new Date(), {
        locale: this.dateFnsLocale,
      });
    }

    if (typeof value === 'number') {
      res = toDate(value);
    }

    if (value instanceof Date) {
      res = this.clone(value as Date);
    }

    return res;
  }

  toIso8601(date: Date): string {
    return date.toISOString();
  }

  today(): Date {
    return new Date();
  }

  private createDateWithOverflow(year: number, month: number, date: number) {
    const result = new Date(year, month, date);

    if (year >= 0 && year < 100) {
      result.setFullYear(this.getYear(result) - 1900);
    }

    return result;
  }

  private getRange(start: number, end: number) {
    const diff = Math.abs(end - start + 1);

    return Array.from(Array(diff).keys()).map(v => v + start);
  }
}
