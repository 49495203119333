import { IOnChangesObject } from 'angular';
import { Inject } from 'decorators/decorators';
import * as moment from 'moment';

import { template } from './date-range-picker-customized.tpl.html';
@Inject('$window', 'dateRangePickerOptions')
export default class DateRangeFilterController {
  // Bound properties
  filterName: string;
  elementType: string;
  onFilterChange: Function;
  onClear: Function;

  // Properties to manage the controller state
  dateRangeOptions: any;
  dateRangeModel: any;
  localDateRange: { startDate: Date; endDate: Date };

  constructor(private readonly $window: ng.IWindowService, private readonly dateRangePickerOptions: any) {}

  $onInit() {
    // Will be called by the 'Clean filters' button
    this.$window.cleanCalendarFilters = () => {
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent('cleanFilters', false, false, undefined);
      this.$window.dispatchEvent(evt);
    };

    // This listener will remove the filter set for date range, since is using a custom template.
    this.$window.addEventListener('cleanFilters', () => {
      // reset calendar
      this.setupCalendar();

      this.onClear({ filterName: this.filterName });
    });

    this.elementType = !!this.elementType ? this.elementType : 'input';
    this.setupCalendar();
  }

  $onChanges(changes: IOnChangesObject) {
    if (!!changes.dateRangeModel && !!changes.dateRangeModel.currentValue && !changes.dateRangeModel.isFirstChange()) {
      const currentDateRange = changes.dateRangeModel.currentValue;
      if (!currentDateRange.start && !currentDateRange.end) this.setupCalendar();
    }
  }

  private setupCalendar() {
    this.initializeDateRange();
    this.setupDateRange();
  }

  private initializeDateRange = () => {
    this.localDateRange = { startDate: null, endDate: null };
  };

  private setupDateRange() {
    this.dateRangeOptions = {
      ...this.dateRangePickerOptions,
      ...this.getDateRangeOptions(),
    };
  }

  private getDateRangeOptions(): any {
    const datePickerOptions = {
      ...this.getDateRange(),
      buttonClasses: 'uk-btn sm',
      applyButtonClasses: 'solid secondary',
      cancelButtonClasses: 'outline primary',
      eventHandlers: {
        'showCalendar.daterangepicker': (picker: any) => {
          const { startDate, endDate } = picker.model;
          const optStartDate = picker.opts.startDate;
          const optEndDate = picker.opts.endDate;

          const today = moment();
          const model = {
            startDate: !!startDate ? startDate : !!optStartDate ? optStartDate : today,
            endDate: !!endDate ? endDate : !!optEndDate ? optEndDate : today,
          };

          picker.model = model;
        },
        'apply.daterangepicker': (pickerEvt: any) => {
          const { startDate, endDate } = pickerEvt.model;

          const datesRange = { start: startDate.toDate(), end: endDate.toDate() };
          !!this.onFilterChange && this.onFilterChange({ datesRange, filterType: this.filterName });
        },
      },
      template,
    };

    return datePickerOptions;
  }

  private getDateRange(): any {
    const today = new Date();

    const dateRange = {
      startDate: moment(today),
      endDate: moment(today),
      minDate: moment(today).subtract(3, 'years'),
      maxDate: moment(today),
    };

    return dateRange;
  }
}
