<div class="axos-invest-filters-row">
  <!-- <app-categories-filter class="transaction-filter" id="categories-filter"></app-categories-filter> -->
  <app-category-filter
    class="transaction-filter"
    id="categories-filter"
    (onValueChange)="onChangeCategoryFilter($event)"
    hidden="hideCategoriesFilter"
  ></app-category-filter>
  <app-input-filter
    class="transaction-filter"
    id="search-filter"
    [iconPlaceholder]="'search'"
    [inputPlaceholder]="'Search'"
    [control]="filters.get('search')"
  ></app-input-filter>
  <div class="filter-btn-container transaction-filter">
    <button
      type="button"
      class="uk-btn filter-btn"
      [class.filter-btn-active]="isFilterBtnActive"
      aria-haspopup="true"
      aria-expanded="false"
      (click)="toggleDisplayFilters()"
    >
      <span class="filter-btn-icon">
        <app-svg-icon
          [iconName]="filterIcon.iconName"
          [height]="filterIcon.height"
          [width]="filterIcon.width"
        ></app-svg-icon>
      </span>
      <span>Filters</span>
    </button>
  </div>
</div>
<div class="transaction-filters" [class.show-section]="displayFilters">
  <div class="transaction-filters-modal">
    <div class="transaction-filters-header">
      <button class="tx-btn" (click)="resetSubFilters()">
        Reset
      </button>
      <span>Filter Transactions</span>
      <button class="tx-btn" (click)="toggleDisplayFilters()">Cancel</button>
    </div>
    <div class="transaction-filters-body">
      <app-dropdown-filter
        [options]="transactionFilterOptions"
        [control]="filters.get('transaction')"
        class="transaction-sub-filter"
      ></app-dropdown-filter>
      <app-dropdown-filter
        [options]="periodFilterOptions"
        [control]="filters.get('datePeriod')"
        class="transaction-sub-filter"
      ></app-dropdown-filter>
      <app-date-range-filter
        [control]="filters.get('dateRange')"
        class="transaction-sub-filter"
      ></app-date-range-filter>
      <popover-comparer
        title="Amount Range"
        inputClasses="uk-input tx-filter-input"
        placement="bottom"
        placeholder="$"
        filterTypeName="amountRange"
        elementType="button"
        elementDescription="Amount Range"
        activeBgColor="tx-aggregation-filters__btn--active"
        [elementActive]="filters.get('amountRange').value"
        buttonClasses="uk-btn lg transaction-filter-button"
        [model]="filters.get('amountRange').value"
        (onOk)="onChangeAmountRangeFilter($event)"
        (onClear)="clearAmountRangeFilter()"
        clearText="Clear Filters"
        class="transaction-sub-filter"
      ></popover-comparer>
    </div>
    <div class="transaction-filters-footer">
      <button class="uk-btn solid secondary lg" (click)="toggleDisplayFilters()">
        Apply Filter
      </button>
    </div>
  </div>
</div>
<div class="filter-tags" *ngIf="filtersApplied && filtersApplied.length > 0">
  <ng-container *ngFor="let filter of tagFilters">
    <app-tag [filter]="filter" (remove)="removeFilterTag(filter)"></app-tag>
  </ng-container>
</div>
