<h3 class="mt-5 mb-4">How do I set up recurring bill payments?</h3>
<ol>
  <li>
    Click on the
    <b>Pay Bills tab.</b>
    You will then see your list of payees.
  </li>
  <li>
    Enter the
    <b>amount</b>
    that you want to pay next to the payee that you want to pay.
  </li>
  <li>
    Then click on the date that you want to send the payment and then click
    <b>Continue.</b>
  </li>
  <li>
    You then need to verify the amount and the payment date. Once all information is correct, click
    on
    <b>Send Payment.</b>
  </li>
  <li>
    You can also set up notifications for payments:
    <br />
    <br />
  </li>
</ol>
<ul>
  <li>
    Click on the
    <b>setting</b>
    wheel after the payment date.
  </li>
  <li>
    You can choose to be notified a specific number of days before the payment is sent or when the
    payment is sent.
  </li>
  <li>You can also be notified when the payment is delivered.</li>
</ul>
