<div class="view-container d-flex flex-column pt-3">
  <app-dot-loader
    class="mx-auto"
    *ngIf="isLoading; else loanAccountDetailsView"
  >
    Loading account details...
  </app-dot-loader>
</div>

<ng-template #loanAccountDetailsView>
  <h2>Loan Account Details</h2>

  <div class="row mt-3 bt-3">
    <div
      *ngIf="!account.displayAsClosedAccount"
      class="col-12 col-lg-4 mb-3 payment-info"
    >
      <app-payment-info
        [info]="paymentInfo"
        [canMakePayment]="showPaymentButton"
        (makePayment)="goToTransferFunds()"
      ></app-payment-info>
    </div>

    <app-loan-account-detail
      class="col-12 col-lg-8 order-lg-first"
      [account]="account"
      [details]="details"
      (updatedNickname)="details.nickname = $event"
      [hasSignatureCard]="true"
    ></app-loan-account-detail>

    <app-loan-payment-detail
      class="col-12 col-lg-8 mt-5"
      [details]="details"
      [isPastDue]="isPastDue"
    ></app-loan-payment-detail>

    <div class="col-12 col-lg-4 mb-3 payment-info" *ngIf="showPayoffCalc">
      <app-payment-calculator
        [isPayoffFlowActive]="isPayoffFlowActive"
        [info]="paymentPayoff"
        [payoffCalculated]="payoffCalculated"
        (calculatePayoff)="calculatePayoff($event)"
        (generateLoanQuote)="generateLoanQuote($event)"
        (makePayment)="makePayment($event)"
      ></app-payment-calculator>
    </div>

    <div class="col-12 col-lg-8 mt-5" *ngIf="isRiaShareAccountFlag">
      <app-share-account-details
        [shareAccount]="isRiaShareAccountFlag"
        [authorizeTransfers]="isRiaAuthorizeTransfersFlag"
        [displayAuthorizeTransfer]="shouldDisplayAuthorizeTransfer"
        [displayShareAccount]="shouldDisplayShareAccount"
        [isRedesign]="true"
        [accountId]="account.id.toString()"
      ></app-share-account-details>
      <div class="space-between-sections"></div>
    </div>
  </div>
</ng-template>
