import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScheduledTransferState } from '../types';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';

const getScheduledTransfersState = createFeatureSelector<ScheduledTransferState>(AasStoreFeatures.ScheduledTransfers);

export const getSelectedAccountScheduledTransferTile = createSelector(getScheduledTransfersState, state => {
  if (state.selectedAccountScheduledTransfers === undefined) {
    return undefined;
  }

  return (
    state.scheduledTransfers.find(item => item.accountNumber === state.selectedAccountScheduledTransfers) ?? undefined
  );
});
