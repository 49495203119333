import { Injectable } from '@angular/core';
import { TradeMarketService } from '@app/Areas/AAS/aas-core/services';
import { Store } from '@ngrx/store';
import { BalancesResponse } from '../../balances/core/services/balances';

import { BalancesService } from '../../balances/core/services/balances/balances.service';
import {
  addUpdateTotalValueAction,
  getSelectedAccountTotalValueTile,
  initialTotalValueTileState,
  TotalValueState,
  TotalValueTileState,
} from '../core/store';
import { Delta } from '../core/store/enums';
import { FilterDates } from '../view/total-value-tile';
import { InitializeTotalValueTileInput } from './types';
import { Subject } from 'rxjs';
import { retry, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TotalValueFacade {
  isLoading$ = new Subject<boolean>();
  totalValueTileAccountState$ = this.store.select(getSelectedAccountTotalValueTile);
  initIntervalMilliseconds = 100;
  maxIntervalMilliseconds = 10 * 1000;
  maxRequestAttempts = 10;
  isMarketOpened = true;

  constructor(
    private store: Store<TotalValueState>,
    private balancesService: BalancesService,
    private tradeMarketService: TradeMarketService
  ) {
    this.initializeIsMarketOpened();
  }

  initializeIsMarketOpened() {
    this.tradeMarketService
      .isMarketOpened(new Date())
      .pipe(
        tap(
          isMarketOpened => {
            this.isLoading$.next(false);
            this.isMarketOpened = isMarketOpened;
          },
          () => {
            this.isLoading$.next(false);
            this.isMarketOpened = false;
          }
        )
      )
      .subscribe();
  }

  initializeTotalValueTile(input: InitializeTotalValueTileInput) {
    this.isLoading$.next(true);
    this.balancesService
      .getBalances({ accountNumber: input.accountNumber })
      .pipe(retry(3))
      .subscribe(
        response => {
          this.handleSuccessResponse(response.data, input);
        },
        () => {
          this.handleFailedResponse(input.accountNumber);
        }
      );
  }

  handleSuccessResponse(response: BalancesResponse, input: InitializeTotalValueTileInput) {
    const totalValueTileState: TotalValueTileState = {
      accountNumber: input.accountNumber,
      ...response,
      hasError: false,
    };
    this.store.dispatch(addUpdateTotalValueAction({ payload: totalValueTileState }));
    this.isLoading$.next(false);
  }

  handleFailedResponse(accountNumber: string) {
    initialTotalValueTileState.accountNumber = accountNumber;
    initialTotalValueTileState.hasError = true;
    initialTotalValueTileState.interval = this.setupTotalValueTileInterval();
    this.store.dispatch(addUpdateTotalValueAction({ payload: initialTotalValueTileState }));
    this.isLoading$.next(false);
  }

  setupTotalValueTileInterval() {
    return {
      [FilterDates.M3]: {
        delta: Delta.NoChange,
        differenceAllTime: 0,
        percentageAllTime: 0,
        list: [],
      },
    };
  }
}
