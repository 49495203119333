<div
  class="profile-image"
  *ngIf="iconDetails$ | async as details; else skeleton"
  [style.backgroundColor]="details.selectedColor"
>
  <span *ngIf="details.selectedIcon === 'initials'; else icon">
    {{ businessProfile ? initials : (details.initials | uppercase) }}
  </span>

  <ng-template #icon>
    <app-svg-icon [iconName]="details.selectedIcon"></app-svg-icon>
  </ng-template>
</div>

<ng-template #skeleton>
  <div class="profile-image--skeleton"></div>
</ng-template>
