import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TrustedContactsType } from '@app/Areas/AAS/features/account-details/core';
import { PeopleFacade } from '@app/Areas/AAS/features/account-details/facade';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalAction, ModalPersonType } from '../../trusted-interested-modal';
import {
  Uk2ButtonSizeEnum,
  Uk2LabeledIconPositionEnum,
  Uk2Tier1FilesEnum,
  Uk2Tier1UtilityEnum,
} from '@axos/uikit-v2-lib';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';

@Component({
  selector: 'app-trusted-contact',
  templateUrl: './trusted-contact.component.html',
  styleUrls: ['./trusted-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustedContactComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject<void>();
  readonly modalActionType = ModalAction;
  uk2ButtonSize = Uk2ButtonSizeEnum.small;
  labeledIconPosition = Uk2LabeledIconPositionEnum.left;
  editSvgIconName = Uk2Tier1FilesEnum.pencil;
  plusIcon = Uk2Tier1UtilityEnum.plusCircle;
  trustedContact: TrustedContactsType;
  accountNumber: string;
  personType = ModalPersonType.TrustedContact;
  action: ModalAction;
  showModal = false;
  userCanAddEditTrustedContact = false;

  constructor(
    private peopleFacade: PeopleFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
    private pdpFacade: PdpFacade
  ) {}

  ngOnInit(): void {
    this.peopleFacade.accountPeopleTabState$
      .pipe(
        takeUntil(this.destroy$),
        filter(
          accountPeopleTabState =>
            !!accountPeopleTabState?.accountPeople?.trustedContacts
        )
      )
      .subscribe(accountPeopleTabState => {
        this.trustedContact =
          accountPeopleTabState.accountPeople.trustedContacts[0];
        this.accountNumber = accountPeopleTabState.accountNumber;
        this.changeDetectorRef.markForCheck();
      });

    this.pdpFacade.selectedAasAccount$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(account => {
        this.userCanAddEditTrustedContact =
          this.featureFlagService.isRiaPilotAddEditTrustedContactsActive() &&
          !account.dateCloseInitiated &&
          !account.dateTerminated;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalClosed() {
    this.showModal = false;
  }

  openTrustedContactModal(modalAction: ModalAction) {
    this.action = modalAction;
    this.showModal = true;
  }

  get trustedContactFullName(): string {
    return `${this.trustedContact?.firstName} ${this.trustedContact?.lastName} ${this.trustedContact?.suffixDisplay}`;
  }
}
