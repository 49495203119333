import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DialogService } from '@core/services';
import { STATE } from '@core/tokens';
import { AddInvestorCheckingSuccessComponent } from '../add-investor-checking-success/add-investor-checking-success.component';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { AccountReviewModalComponent } from '../account-review-modal/account-review-modal.component';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';
import { ServiceHelper } from '@legacy/services/service.helper';
import {
  STATUS_APPROVED,
  STATUS_DECLINED,
  ACCOUNT_TYPE_DDX2,
  ERROR_DEFAULT_MESSAGE,
  ERROR_HEADER_TEXT,
  ERROR_OKAY_TEXT,
  ERROR_OKAY_BUTTON_CLASS,
  MODAL_WINDOW_CLASS,
} from './constants';
import {
  InvestorCheckingResponse,
  InvestorCheckingService,
} from '@app/Areas/AAS/aas-shared/services/investor-checking';
import { InvestorCheckingAccountFeatures } from './components/features-step';
import { WebApiResponse } from '@shared/models';
import { AddInvestorCheckingSuccessData } from '../add-investor-checking-success';
import { SubSink } from '@axos/subsink';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { AddInvestorCheckingInReviewData } from '../account-review-modal';

@Component({
  selector: 'app-investor-checking-enrollment-modal-v2',
  templateUrl: './investor-checking-enrollment-modal.component-v2.html',
  styleUrls: ['./investor-checking-enrollment-modal.component-v2.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorCheckingEnrollmentModalComponentV2 implements OnDestroy {
  selectedFeatures: InvestorCheckingAccountFeatures[];
  subsink = new SubSink();
  email: string;
  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    public dialogRef: MatLegacyDialogRef<InvestorCheckingEnrollmentModalComponentV2>,
    private aasInvestorCheckingService: InvestorCheckingService,
    private dialogService: DialogService,
    private modalService: ModalService,
    private agreementsService: AgreementService,
    private serviceHelper: ServiceHelper,
    private store: Store
  ) {
    this.selectedFeatures = [];
    this.retrieveAccountEmail();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  submitApplication() {
    const includeDC = this.selectedFeatures.includes(InvestorCheckingAccountFeatures.DebitCard);
    const includeOdl = this.selectedFeatures.includes(InvestorCheckingAccountFeatures.OverdraftLineOfCredit);
    let applicationResponse: WebApiResponse<InvestorCheckingResponse>;

    switch (true) {
      case includeDC && includeOdl:
        applicationResponse = this.aasInvestorCheckingService.addInvestorCheckingAndOdlAccounts(true);
        break;
      case !includeDC && includeOdl:
        applicationResponse = this.aasInvestorCheckingService.addInvestorCheckingAndOdlAccounts(false);
        break;
      case includeDC && !includeOdl:
        applicationResponse = this.aasInvestorCheckingService.addInvestorCheckingAccount(true);
        break;
      case !includeDC && !includeOdl:
        applicationResponse = this.aasInvestorCheckingService.addInvestorCheckingAccount(false);
        break;
    }

    applicationResponse.toPromise().then(res => {
      this.handleSubmitApplicationResponse(res, includeOdl);
    });

    this.state.go('udb.dashboard');
  }

  handleSubmitApplicationResponse(res, isOdlChecked) {
    if (res.statusCode == 200 && res.data) {
      switch (res.data.applicationStatus) {
        case STATUS_APPROVED:
          this.updateAgreement(false);
          var account = res.data.accounts.find(account => {
            if (account.productCode == ACCOUNT_TYPE_DDX2) {
              return account;
            }
          });
          let data: AddInvestorCheckingSuccessData = {
            investorCheckingAccountNumber: account.accountNumber,
            investorCheckingAccountNickname: account.accountNumber.substring(account.accountNumber.length - 4),
            wasOdlSelected: isOdlChecked,
            email: this.email,
          };
          this.dialogRef.close();
          this.dialogService.openByComponentRef(AddInvestorCheckingSuccessComponent, {
            data: data,
          });
          break;
        case STATUS_DECLINED:
          this.showErrorModal();
          break;
        default:
          // FLOW FOR ALL OTHER TYPE OF STATUS
          this.updateAgreement(false);
          this.dialogRef.close();
          let data2: AddInvestorCheckingInReviewData = {
            confirmationNumber: res.data.confirmationNumber,
            email: this.email,
          };
          this.dialogService.openByComponentRef(AccountReviewModalComponent, {
            data: data2,
          });
          break;
      }
    } else {
      this.showErrorModal();
    }
  }

  updateAgreement(value: boolean) {
    this.agreementsService
      .updateAgreementsByValue(value, AasAgreementType.MultiAccountAgreementInvestorChecking)
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  showErrorModal() {
    const defaultMessage = ERROR_DEFAULT_MESSAGE;
    const modalSettings: ModalSettings = {
      headerText: ERROR_HEADER_TEXT,
      bodyText: defaultMessage,
      okText: ERROR_OKAY_TEXT,
      hasCancelButton: false,
      hasIcon: false,
      hasHeaderText: true,
      okButtonClass: ERROR_OKAY_BUTTON_CLASS,
    };

    this.modalService
      .show({ windowClass: MODAL_WINDOW_CLASS }, modalSettings)
      .then(() => {})
      .catch(() => {});

    this.dialogRef.close();
  }

  updateSelectedFeatures(selectedFeatures: InvestorCheckingAccountFeatures[]): void {
    this.selectedFeatures = selectedFeatures;
  }

  private retrieveAccountEmail() {
    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(profileDetails => profileDetails && profileDetails.hasOwnProperty('primaryEmail')))
      .subscribe({
        next: profileDetails => {
          this.email = profileDetails.primaryEmail;
        },
      });
  }
}
