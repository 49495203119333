<form (ngSubmit)="login()" class="login">
  <div class="form-group">
    <label for="login-username" class="uk-label">Username</label>
    <input
      id="login-username"
      type="text"
      name="login-username"
      class="uk-input"
      [(ngModel)]="credentials.userName"
      required
    />
  </div>
  <div class="form-group d-flex flex-column-reverse">
    <input
      id="login-password"
      type="password"
      name="login-password"
      class="uk-input"
      [(ngModel)]="credentials.password"
      required
    />
    <app-reveal-input-label target="login-password">
      Password
    </app-reveal-input-label>
  </div>

  <div class="error-message" *ngIf="hasLoginFailed" role="alert" aria-live="assertive">
    <div class="text-error">
      <app-svg-icon
        [iconName]="closeIcon"
        iconColor="var(--alert-color)"
        [styles]="{ width: '0.7em', height: '0.7em', strokeWidth: 4 }"
      ></app-svg-icon>
      <span *ngIf="!forgotPasswordBand" [innerText]="message"></span>

      <span *ngIf="usernamePasswordMismatch">
        We did not recognize your username or password. If you have not already,
        <a
          (click)="redirectToTermsAndConditionsFull()"
          id="redirectToTermsAndConditionsLink"
          href="#"
        >
          register for the new site.
        </a>
      </span>

      <span *ngIf="forgotPasswordBand">
        Your account may be locked. Please check your email or use the
        <a (click)="sendPassword()" class="cursor-pointer" href="#">forgot password</a>
        feature to access it.
      </span>
    </div>
  </div>

  <div class="form-group text-center">
    <button class="btn-ok button--lg" id="btn-login" type="submit" [hidden]="isLoggingIn">
      Login
    </button>
  </div>

  <div class="form-group text-center">
    <p>
      Forgot
      <a
        (click)="sendUsername()"
        id="sendUsernameLink"
        class="login__link"
        aria-label="Forgot your username?"
        href="#"
      >
        username
      </a>
      or
      <a
        (click)="sendPassword()"
        id="sendPasswordLink"
        class="login__link"
        aria-label="Forgot your password?"
        href="#"
      >
        password
      </a>
      ?
    </p>
  </div>
  <app-dot-loader [showWhen]="isLoggingIn"></app-dot-loader>
</form>
