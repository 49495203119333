import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { RecommendedAutoDeposit } from '@app/axos-invest/models';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationComponent {
  @Input() recommendation: RecommendedAutoDeposit;
  @Input() hideButton: boolean;

  @Output() useRecommendation = new EventEmitter<RecommendedAutoDeposit>();
}
