import { Inject } from 'decorators/Inject';
import { SignatureCardService } from 'services/signature-card.service';

@Inject('$state', '$stateParams', 'signatureCardService', 'popups')
export class UnableDownloadController {
  accountId: number;
  accountNumber: string;
  isGeneratingCase: boolean;

  constructor(
    private state: ng.ui.IStateService,
    private params: ng.ui.IStateParamsService,
    private signatureCardService: SignatureCardService,
    private popups: IPopups
  ) {
    this.accountNumber = this.params['accountNumber'];
    this.accountId = this.params['accountId'];
    this.isGeneratingCase = false;
  }

  requestSignatureCard(): void {
    this.isGeneratingCase = true;
    this.signatureCardService.createSignatureCardCase(this.accountId, this.accountNumber).subscribe(
      () => {
        this.state.go('udb.signaturecard.unable-download-complete');
      },
      () => {
        this.isGeneratingCase = false;
        this.popups.showError(
          'Sorry we are unable to process your request right now, please try again later'
        );
      }
    );
  }
}
