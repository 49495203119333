export const chartOptions = {
  cutoutPercentage: 88.5,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  plugins: {
    highlight: {
      growAmount: 0,
      shadowRadius: 0,
    },
  },
  layout: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
};
