import { Action, createReducer, on } from '@ngrx/store';
import { OrdersState, initialOrdersState, OrdersTileState } from '../types';
import { addUpdateOrdersAccountAction } from '../orders-tile.actions';

const reducer = createReducer(
  initialOrdersState,
  on(addUpdateOrdersAccountAction, (_state, action) => {
    let ordersTileState: OrdersTileState[] = [];
    const accountIndex = _state.accountsOrders.findIndex(m => m.accountNumber === action.payload.accountNumber);
    if (accountIndex < 0) {
      ordersTileState = [..._state.accountsOrders, action.payload];
    } else {
      ordersTileState = _state.accountsOrders.map(item =>
        item.accountNumber === action.payload.accountNumber ? action.payload : item
      );
    }
    return {
      accountsOrders: ordersTileState,
      selectedAccountOrders: action.payload.accountNumber,
    };
  })
);

export function ordersReducer(state: OrdersState, action: Action) {
  return reducer(state, action);
}
