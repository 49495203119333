import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AasStoreFeatures } from '../../aas-core';
import { ordersReducer } from './core';
import { OrdersMatModule } from './orders-mat.module';
import { OrdersUk2Module } from './orders-uk2.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { initialOrdersState } from './core/store/types';
import { OrdersService } from './core/services';
import { OrdersFacade } from './facade';
import { OrdersTileComponent } from './view/orders-tile/orders-tile.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [OrdersTileComponent],
  providers: [OrdersService, OrdersFacade],
  imports: [
    CommonModule,
    HttpClientModule,
    OrdersMatModule,
    OrdersUk2Module,
    SharedModule,
    StoreModule.forFeature(AasStoreFeatures.Orders, ordersReducer, {
      initialState: initialOrdersState,
    }),
  ],
  exports: [OrdersTileComponent],
})
export class OrdersModule {}
