import { DepositType, TransactionType, TransferType } from '@app/axos-invest/enums';

export const TRANSFER_CONFIGURATION = {
  [TransferType.OneTimeDeposit]: {
    depositType: DepositType.OneTime,
    transactionType: TransactionType.Deposit,
  },
  [TransferType.RecurrentDeposit]: {
    depositType: DepositType.Auto,
    transactionType: TransactionType.Deposit,
  },
  [TransferType.OneTimeWithdrawal]: {
    depositType: DepositType.OneTime,
    transactionType: TransactionType.Withdrawal,
  },
  [TransferType.RecurrentWithdrawal]: {
    depositType: DepositType.Auto,
    transactionType: TransactionType.Withdrawal,
  },
  [TransferType.FundingFlow]: {
    depositType: DepositType.Auto,
    transactionType: TransactionType.Deposit,
  },
};
