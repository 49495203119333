import { CamelCaseKeyName, Holding, THoldingSortType, THoldingSortTypeValues } from '../../store';
export function getKeyByValueFromHolding(
  value: THoldingSortTypeValues,
  object: THoldingSortType,
  stringMapperFn: (key: string) => CamelCaseKeyName,
  defaultKey: keyof Holding
): keyof Holding {
  for (const [key, keyValue] of Object.entries(object)) {
    if (keyValue === value) {
      return stringMapperFn(key) as keyof Holding;
    }
  }
  return defaultKey;
}
