export const axosInvestServiceConstants = {
  MANAGED_PORTFOLIO: 'Managed Portfolios',
  AXOS_INVEST: 'Axos Invest',
  HAS_AXOS_INVEST: 'hasAxosInvest',
  MILESTONE_DETAIL: 'milestonesDetail',
  AXOS_INVEST_ACCOUNTS: 'axosInvestAccounts',
  ACCOUNT_CLOSED: 'Closed',
  ACCOUNT_ACTIVE: 'Active',
  ACCOUNT_TYPE: 'invest',
  GOAL: 'Goal',
  GOALS: 'Goals',
};
