import { Inject } from 'decorators/decorators';

@Inject()
export class TxOptionsController {
  // Bound properties
  options: any[];
  isDisabled: boolean;
  onOptionSelected: Function;
  onCollapse: Function;
  isExpanded: boolean;
  isDetailsExpanded: boolean;

  constructor() {}

  /**
   * Triggers the function specified for the option selected
   * @param option Option selected
   */
  executeAction(option: any) {
    this.isExpanded = option.isCollapsible;
    this.isDetailsExpanded = !this.isDetailsExpanded;
    this.onOptionSelected({ option });
  }

  /** Triggers the collapse function */
  collapse() {
    this.isExpanded = false;
    this.isDetailsExpanded = !this.isDetailsExpanded;
    this.onCollapse();
  }

  moreOptionsClicked() {
    this.isDetailsExpanded = false;
  }
}
