<app-view-header class="header">
  <div class="account__info">
    <acc-account-select
      [selectedItem]="selectedItem"
      [items]="dropdownItems"
      (selectItem)="selectItem($event)"
    ></acc-account-select>

    <div class="account__separator mx-4 d-none d-sm-inline-block"></div>

    <div class="account__balances mt-3 mt-sm-0 d-inline-block">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="tabs-container">
    <div [ngClass]="{ 'hide-tabs': accountType == 'advisor' }" class="tabs">
      <button
        type="button"
        class="tab-btn tab-btn--lg"
        *ngFor="let tab of availableTabs"
        [class.tab-btn--active]="tab === activeTab"
        [innerText]="tab"
        (click)="changeTab(tab)"
      ></button>
    </div>
  </div>
</app-view-header>

<app-modify-confirmation-banner
  *ngIf="showModifiedBanner"
  [bannerText]="modifiedBannerText"
  (dismissBannerEvent)="dismissBannerEvent($event)"
></app-modify-confirmation-banner>
