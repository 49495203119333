export class PortfolioDetails {
  stockAllocation: number;
  bondAllocation: number;
  cashAllocation: number;
  allocationScore: string;

  constructor(args?: Partial<PortfolioDetails>) {
    Object.assign(this, args);
  }
}
