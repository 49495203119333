import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { olbSettings } from '@core/tokens';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'rd-risk-factor-modal',
  templateUrl: './risk-factor-modal.component.html',
  styleUrls: ['./risk-factor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskFactorModalComponent implements OnInit {
  backgroundUrl: string;
  exclamationIcon = AlertsIcons.ExclamationCircle;
  risksForm: UntypedFormArray;

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public risks: string[],
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    const { brand } = this.env;

    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;

    const controls = this.risks.map(() => {
      return this.fb.group({
        risk: new UntypedFormControl(false, [Validators.requiredTrue]),
      });
    });

    this.risksForm = this.fb.array(controls);
  }
}
