import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SwitchAccountOption } from '@app/click-switch/models';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-click-switch-account-dropdown',
  templateUrl: './click-switch-account-dropdown.component.html',
  styleUrls: ['./click-switch-account-dropdown.component.scss'],
})
export class ClickSwitchAccountDropdownComponent implements OnInit {
  @Input()
  account: SwitchAccountOption;
  @Input()
  index: number;
  @Input()
  showDelete: boolean;
  @Input()
  allowSplit: boolean;
  @Output()
  delete = new EventEmitter<number>();
  @Output()
  validTotal = new EventEmitter<number>();
  @Output()
  updateChoice = new EventEmitter();

  deleteIcon = UtilityIcons.Trash;
  invalidPercentage = false;

  private percentageExp = new RegExp('^[1-9][0-9]?$|^100$');

  constructor() {}

  ngOnInit(): void {
    this.account.id = this.account.id || this.account.choices[0].index;
  }

  checkPercentage(index: number): void {
    this.invalidPercentage = !this.percentageExp.test(this.account.percentage);
    if (this.invalidPercentage) {
      this.account.errorMessage = 'Percentage is not valid.';

      return;
    }

    this.account.errorMessage = null;
    this.validTotal.emit(index);
  }
}
