import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-transactions-orders-scheduled-tab',
  templateUrl: './transactions-orders-scheduled-tab.component.html',
  styleUrls: ['./transactions-orders-scheduled-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TransactionsOrdersScheduledTabComponent {
  tabs = ['Transactions', 'Orders', 'Scheduled'];
  tabTitle = 'Transactions';
  selectedTabIndex = 0;

  constructor() {}

  onSelectedTab(event: MatTabChangeEvent): void {
    this.tabTitle = event.tab.textLabel;
    this.selectedTabIndex = event.index;
  }
}
