<form class="edit-loan-payment-modal" name="editLoanPaymentForm" novalidate>
  <!-- HEADER -->
  <div class="modal-header hide-cs legacy-modal-header">
    <div class="frow middle center text-center">
      <p class="modal-title legacy-header-title">{{ title }}</p>
    </div>
  </div>
  <!-- CONTENT -->
  <div class="modal-body">
    <h3 class="text-center title">
      Edit {{ transferToEdit.isExternal ? 'External' : 'Internal' }} Transfer from
      {{ transferToEdit.accountNicknameFrom }} to {{ transferToEdit.accountNicknameTo }}
    </h3>
    <div class="container-fields" *ngIf="!isLoadingSetup">
      <div class="text-left">
        <label class="uk-label">Amount</label>
        <axos-legacy-input-formatted-amount
          [(amount)]="transferToEdit.amount"
          [hasError]="!!errorMsg"
          (change)="validateAmount()"
          placeholder="Amount"
          name="amount"
          [disabled]="true"
        ></axos-legacy-input-formatted-amount>
        <span class="text--error" *ngIf="errorMsg">{{ errorMsg }}</span>
      </div>
      <div class="field-separator"></div>
      <div class="text-left">
        <label class="uk-label">Additional Principal</label>
        <axos-legacy-input-formatted-amount
          [(amount)]="transferToEdit.additionalPrincipal"
          [hasError]="!!errorMsg"
          (change)="validateAmount()"
          placeholder="Additional price"
          name="additionalPrincipal"
          [disabled]="true"
          aria-label="additionalPrincipal"
        ></axos-legacy-input-formatted-amount>
        <span class="text--error" *ngIf="errorMsg">{{ errorMsg }}</span>
      </div>
      <div class="field-separator"></div>
      <div class="text-left container-date daterangePickerContainer">
        <label class="uk-label" for="nextTransferDate">Next Transfer Date</label>
        <i class="bofi-datepicker icon-input--inner" (click)="showCalendar('date')"></i>
        <input
          appDateRangePicker
          type="text"
          name="date"
          id="date"
          aria-label="date"
          class="uk-input icon-input--inner"
          [options]="datePickerOptions"
          [ngModel]="dateStartHandler.dateWithFormat"
          (calendarShown)="setPropertiesForCalendar()"
          (dateApplied)="setDateStart($event)"
          readonly
        />
        <span class="error-message uk-label margin-top" *ngIf="!isTodayValidForExternal">
          *Transfers to an external account are scheduled the next business day if submitted before 1:45 p.m. Pacific
          Time. Any requests after this cut-off time will be scheduled for the second business day.
        </span>
      </div>
      <div class="field-separator"></div>
      <div class="text-left">
        <label for="frequency" class="uk-label label-form-upper">Frequency</label>
        <select
          class="uk-input frequency-select"
          [(ngModel)]="transferToEdit.frequency"
          disabled="disabled"
          id="frequency"
        >
          <option *ngFor="let frequency of frequencies" [value]="frequency.value">
            {{ frequency.label }}
          </option>
        </select>
      </div>
      <div *ngIf="isSeries">
        <div class="field-separator"></div>
        <div class="text-left">
          <label for="sendUntil" class="uk-label">Send Until</label>
          <select
            id="sendUntil"
            class="uk-input"
            [(ngModel)]="transferToEdit.sendUntilOptions"
            (change)="sendUntilChange()"
          >
            <option *ngFor="let opt of sendUntilOptions" [value]="opt.value">
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div class="field-separator" *ngIf="showNumberOfTransfers"></div>
        <div class="text-left" *ngIf="showNumberOfTransfers">
          <label for="numberTransfers" class="uk-label">Number Of Transfers</label>
          <input
            id="numberTransfers"
            name="numberTransfers"
            type="text"
            class="uk-input"
            [(ngModel)]="transferToEdit.numberOfTransfers"
            maxlength="3"
            ngPattern="/^\d+$/"
            (change)="validateNumberOfTransfers()"
          />
          <span class="text-error" *ngIf="!isNumberOfTransfersValid">
            You must specify a valid number of transfers
          </span>
        </div>
        <div class="field-separator" *ngIf="showExpirationDate"></div>
        <div class="text-left container-date" *ngIf="showExpirationDate">
          <label class="uk-label" for="lastTransferDate">End on Date</label>
          <i class="bofi-datepicker icon-input-expiration-inner" (click)="showCalendar('endDate')"></i>
          <input
            date-range-picker
            type="text"
            name="endDate"
            id="endDate"
            aria-label="date"
            class="uk-input icon-input-expiration-inner"
            [ngClass]="{ red: !isEndDateValid }"
            options="$ctrl.expirationDatePickerOptions"
            (calendarShown)="setPropertiesForCalendar()"
            (dateApplied)="setDateEnd($event)"
            [ngModel]="dateEndHandler.dateWithFormat"
            readonly
          />
          <span class="text-error" *ngIf="!isEndDateValid">
            The End on date needs to be greater than the Start on date.
          </span>
        </div>
      </div>
      <div class="field-separator"></div>
    </div>
  </div>
  <!-- FOOTER -->
  <div class="modal-footer">
    <div *ngIf="!isLoadingSetup" class="frow no-wrap center footer-internal">
      <button
        type="button"
        class="uk-btn lg outline primary action-button"
        (click)="closeModal()"
        [disabled]="isLoadingSetup"
      >
        Cancel
      </button>
      <button
        type="button"
        class="uk-btn lg solid secondary action-button"
        (click)="saveTransfer()"
        [disabled]="isLoadingSetup"
      >
        Save
      </button>
    </div>
    <app-dot-loader [showWhen]="isLoadingSetup"></app-dot-loader>
  </div>
</form>
