<p class="mb-4 text-center font-weight-normal col-12">
  Ensure your information is up-to-date for the best experience.
</p>

<form class="change-family-info col-12" [formGroup]="changeFamilyInfoForm" (ngSubmit)="submitFamilyInfo()">
  <div class="form-group">
    <label for="marital-status" class="uk-label">Marital Status</label>
    <select name="marital-status" id="marital-status" class="uk-input" formControlName="maritalStatus">
      <option value="" *ngIf="!changeFamilyInfoForm.get('maritalStatus')?.value">Select Marital Status</option>
      <option [value]="status.key" *ngFor="let status of maritalStatuses$ | async">
        {{ status.value }}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="dependents" class="uk-label">Number of Dependents</label>
    <input
      class="uk-input"
      type="number"
      name="dependents"
      id="dependents"
      formControlName="numberOfDependents"
      placeholder="Number of Dependents"
      (keydown)="restrictOnlyTwoCharacters($event)"
      min="0"
      max="99"
      oninput="validity.valid||(value='');"
    />
  </div>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="changeFamilyInfoForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
