<a #tooltip>{{ selectedItem.text }}</a>

<div #dropdown>
  <ul class="dropdown-list">
    <li *ngFor="let item of dropdownItems" class="list-item" (click)="emitOnSelect(item)">
      <span class="item-text">{{ item.text }}</span>
      <span class="item-complementary-text">{{ item.description }}</span>
    </li>
  </ul>
</div>
