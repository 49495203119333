import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Inject,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AggregatedAccount } from '@app/accounts/models';
import { IPayService } from '@core/services/ipay.service';
import { olbSettings } from '@core/tokens';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';

import { ServiceHelper } from '@legacy/services/service.helper';
import { AccountProfile } from '@legacy/typings/app/AccountProfile';
import { IPaySSOResult } from '@legacy/typings/app/IPaySSOResult';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';

import { solePropHelpers } from '@legacy/shared/helpers';
import { DialogService } from '@core/services';

@Component({
  selector: 'app-send-money-business-modal',
  templateUrl: './send-money-business-modal.component.html',
  styleUrls: ['./send-money-business-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMoneyBusinessModalComponent implements OnInit, OnDestroy {
  isLoadingURL: boolean;
  hasBusiness: boolean;
  hasIndividual: boolean;
  isDisabled: boolean;
  selectedBusiness: AggregatedAccount | null;
  sbbAccounts: AggregatedAccount[];
  endingSentence: string;
  soleBusinessData: solePropHelpers.SolePropData;
  onDestroy$ = new EventEmitter<boolean>();
  public readonly MOVE_MONEY_SENTENCE = 'move money';
  public readonly BILL_PAY_SENTENCE = 'pay bills';

  dialogRefFallback$ = this.dialogService.dialogState$
    .pipe(
      takeUntil(this.onDestroy$),
      filter(x => x.dialogRef != null),
      tap(x => {
        this.computeOptions.bind(this)();
        this.dialogRef = x.dialogRef;
      })
    )
    .subscribe();

  constructor(
    private dialogRef: MatDialogRef<SendMoneyBusinessModalComponent>,
    private iPayService: IPayService,
    private serviceHelper: ServiceHelper,
    private modalService: ModalService,
    private dialogService: DialogService,
    @Inject(olbSettings) public settings: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.emit(true);
  }

  ngOnInit() {
    // SBB Logic
    this.endingSentence = this.MOVE_MONEY_SENTENCE;
    this.selectedBusiness = null;
    this.hasBusiness = false;
    this.hasIndividual = false;
    this.isDisabled = true;
    this.computeOptions();
  }
  closeModal(): void {
    this.dialogRef.close();
  }

  onBusinessChange(selectedBusiness: any) {
    this.selectedBusiness = selectedBusiness;
    this.isDisabled = false;
  }
  continue() {
    this.isLoadingURL = true;
    this.iPayService
      .getSsoUrlByBusinessId(this.selectedBusiness.id)
      .pipe(
        finalize(() => {
          this.isLoadingURL = false;
        })
      )
      .subscribe({
        next: (resp: OlbResponse<IPaySSOResult>) => {
          // Send user to IPay
          if (
            resp.data.ssoUrl !== null &&
            resp.data.ssoUrl !== '' &&
            resp.data.status === 1
          ) {
            window.open(resp.data.ssoUrl, '_blank');
            setTimeout(() => {
              // Close modal automatically after 1sec
              this.closeModal();
            }, 1000);
          } else {
            this.closeModal();
            this.iPayError(true);
          }
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }
  private checkSbbAccounts() {
    const userProfilesInfo = JSON.parse(
      sessionStorage.getItem(solePropHelpers.USER_BUSINESSES_KEY)
    ) as AccountProfile[];
    // Profile Type 1 = Business, 0 = Individual
    this.hasBusiness = userProfilesInfo?.some(acc => acc.profileType === 1);
    this.hasIndividual = userProfilesInfo?.some(acc => acc.profileType === 0);
    this.sbbAccounts = userProfilesInfo?.filter(acc => acc.profileType === 1);

    if (!this.soleBusinessData.isSoleProp) {
      return;
    }

    this.sbbAccounts.unshift({
      id: this.soleBusinessData.businessId as number,
      name: 'Sole Proprietorship Account',
    });
  }

  private iPayError(showPopup: boolean): string {
    const defaultMessage = showPopup
      ? `<div><img src="/assets/img/icons/illustration-area.svg" /></div>
      It seems like we've stumbled upon a little glitch. But we’re on it! We apologize for the inconvenience. Please try again shortly.`
      : 'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
    const modalSettings: ModalSettings = {
      headerText: 'Oops! A Tiny Hiccup.',
      bodyText: defaultMessage,
      okText: 'Okay',
      hasCancelButton: false,
      hasIcon: false,
      hasHeaderText: true,
      okButtonClass: 'new-button-okay',
    };
    if (showPopup) {
      this.modalService
        .show(
          { windowClass: 'modal-service internal-error-modal' },
          modalSettings
        )
        .then(() => {})
        .catch(() => {
          return defaultMessage;
        });
    }

    return defaultMessage;
  }

  private computeOptions(): void {
    this.soleBusinessData =
      this.data?.soleBusinessData ?? this.dialogService.selectSolePropData();
    this.checkSbbAccounts();
    // Logic to complete the modal's sentence with bill pay or move money.
    if (this.data?.isBillPay === true) {
      this.endingSentence = this.BILL_PAY_SENTENCE;
    }
  }
}
