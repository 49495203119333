import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { AccountInformationStateType } from '../../../core';
import { AccountDetailsFacade, EditNicknameOutputType } from '../../../facade';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { dateFormat, statementFrequency } from './constants';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { CurrencyPipe } from '@angular/common';
import {
  Uk2ButtonSizeEnum,
  Uk2ModalComponent,
  Uk2Tier1FilesEnum,
  Uk2ToastComponent,
  Uk2ToastTypeEnum,
} from '@axos/uikit-v2-lib';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { INPUT_EVENT_TYPES } from '@app/Areas/AAS/aas-shared/constants';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandingFacade } from '@app/Areas/AAS/features/branding';

@Component({
  selector: 'app-accounts-tab',
  templateUrl: './accounts-tab.component.html',
  styleUrls: ['./accounts-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountsTabComponent implements OnInit, OnDestroy {
  @ViewChild('inputNickname') inputNickname!: ElementRef<HTMLInputElement>;
  @ViewChild('modal') modal?: Uk2ModalComponent;
  accountsTabAccountState: AccountInformationStateType;
  accountNickname: string;
  accountType: string;
  accountNumber: string;
  isRetirementAccount: boolean;
  displayEditNicknameIcon = false;
  uk2Pencil = Uk2Tier1FilesEnum.pencil;
  form: UntypedFormGroup;
  uk2ButtonSize: Uk2ButtonSizeEnum = Uk2ButtonSizeEnum.large;
  isDisabled = true;
  private destroy$ = new Subject<void>();

  constructor(
    private accountDetailsFacade: AccountDetailsFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private pdpFacade: PdpFacade,
    private currencyPipe: CurrencyPipe,
    private featureFlagService: FeatureFlagService,
    private snackBar: MatSnackBar,
    private brandingFacade: BrandingFacade
  ) {}
  ngOnInit(): void {
    this.accountDetailsFacade.accountInformation$
      .pipe(
        filter(state => state !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(accounts => {
        this.accountsTabAccountState = accounts;
        // Notifies the UI that the value has changed and should re-render the html
        this.changeDetectorRef.markForCheck();
      });

    this.pdpFacade.selectedAasAccount$
      .pipe(
        filter(state => state !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe(account => {
        this.accountNickname = account.accountNickname;
        this.accountType = account.type;
        this.isRetirementAccount = account.isRetirement;
        this.accountNumber = account.accountNumber;
        this.displayEditNicknameIcon =
          this.featureFlagService.isRiaPilotAddEditNickname() &&
          (account.dateCloseInitiated === null ||
            account.dateCloseInitiated === undefined) &&
          (account.dateTerminated === null ||
            account.dateTerminated === undefined);
        this.changeDetectorRef.markForCheck();
      });
    this.createForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getFormattedDate(): string {
    if (this.accountsTabAccountState?.profile?.dateOpened) {
      let utcDate = utcToZonedTime(
        this.accountsTabAccountState.profile.dateOpened,
        'UTC'
      );
      return format(utcDate, dateFormat);
    }

    return null;
  }

  /**
   * Returns nickname if the account has it, else it returns a default value in the form of <account type - ####>
   * where #### are the last 4 digits of the account number
   * @return {*}  {string}
   */
  getNickname(): string {
    let defaultNickname: string;
    if (
      this.accountType == null ||
      this.accountsTabAccountState?.accountNumber == null
    ) {
      defaultNickname = null;
    } else {
      defaultNickname = `${this.accountType} - ${this.getLast4Characters(
        this.accountsTabAccountState.accountNumber
      )}`;
    }

    // If accountNickname has the default format it will be changed to PDP nickname format
    return this.accountNickname?.replace(/\*\*(\d\d\d\d)/g, '- $1') || defaultNickname || '';
  }

  getLast4Characters(s: string): string {
    if (s.length <= 4) return s;
    return s.substring(s.length - 4, s.length);
  }

  getAsCurrencyFormat(value: number) {
    return this.currencyPipe.transform(value);
  }

  getStatementFrequencyText(): string {
    if (+this.accountsTabAccountState?.profile?.statementFrequency) {
      const statementFrequencyIndex = +this.accountsTabAccountState?.profile
        .statementFrequency;
      return statementFrequency[statementFrequencyIndex];
    }

    return null;
  }

  /**
   * Only retirement accounts that meet the criteria to be required to make distributions should display the RMD section.
   * When a retirement account doesn't meet the criteria we receive null for all RMD data.
   */
  shouldShowRMD(): boolean {
    const rmd = this.accountsTabAccountState?.requiredMinimumDistributions;

    const meetsRMDCriteria =
      rmd?.year != null && rmd?.totalAmount != null && rmd?.withdrawn != null;

    return this.isRetirementAccount && !!meetsRMDCriteria;
  }

  createForm() {
    this.form = new UntypedFormGroup({
      accountNickname: new UntypedFormControl(this.getNickname(), [
        Validators.maxLength(30),
      ]),
      accountNumber: new UntypedFormControl(this.accountNumber),
    });
  }

  editNickname() {
    this.modal?.openDialog();
    this.brandingFacade.loadBranding('.uk2-custom-modal-white-label');
    this.changeDetectorRef.markForCheck();
    this.waitUntilTargetStyleElementExists();
  }

  closeModal() {
    this.isDisabled = true;
    this.createForm();
    this.modal?.closeDialog();
  }

  submitNickname() {
    if (this.form.invalid) return;

    this.accountDetailsFacade
      .editNickname(this.form.value)
      .pipe(
        tap(response =>
          this.handleRecoverNicknameResult(
            response,
            this.form.value.accountNickname
          )
        )
      )
      .subscribe();
  }

  listenEvents() {
    fromEvent(
      this.inputNickname?.nativeElement,
      INPUT_EVENT_TYPES.keyup
    ).subscribe(() => {
      if (this.form.dirty) {
        this.isDisabled = false;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  private handleRecoverNicknameResult(
    response: EditNicknameOutputType,
    accountNickname: string
  ) {
    if (response.apiCallWasSuccessful && response.nicknameChangedSuccessfully) {
      this.accountNickname = accountNickname;
      this.closeModal();
      this.changeDetectorRef.markForCheck();
      this.displayToast(response.messageToDisplay, Uk2ToastTypeEnum.success);
    } else {
      this.closeModal();
      this.changeDetectorRef.markForCheck();
      this.displayToast(response.messageToDisplay, Uk2ToastTypeEnum.alert);
    }
  }

  private waitUntilTargetStyleElementExists() {
    const bodyElement = document.querySelector('body');
    const config = { childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = (_, observer) => {
      const targetElement: HTMLElement = bodyElement.querySelector(
        '#inputNickname'
      );
      if (targetElement !== null) {
        observer.disconnect();
        this.listenEvents();
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(bodyElement, config);
  }

  displayToast(message: string, toastType: Uk2ToastTypeEnum) {
    this.snackBar.openFromComponent(Uk2ToastComponent, {
      data: {
        message: message,
        type: toastType,
      },
    });
  }
}
