import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FacingBrand } from '@core/enums';
import { ROOT_SCOPE, STATE, olbSettings } from '@core/tokens';
import { IScope } from 'angular';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-cm-confirmation',
  templateUrl: './cm-confirmation.component.html',
  styleUrls: ['./cm-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmConfirmationComponent {
  public brandName: string;
  public userInfo: UserBasicInfo;
  public brandTeamName: string;

  constructor(
    @Inject(ROOT_SCOPE) private readonly _root: ng.IRootScopeService,
    @Inject('$scope') private readonly _scope: IScope,
    @Inject(olbSettings) private readonly env: OlbSettings,
    @Inject(STATE) private readonly state: IStateService
  ) {}

  ngOnInit(): void {
    switch (this.env.facingBrandId) {
      case FacingBrand.Axos:
        this.brandName = 'Axos';
        this.brandTeamName = 'Axos Bank';
        break;
      case FacingBrand.Nationwide:
        this.brandName = 'Nationwide';
        this.brandTeamName = 'Nationwide Banking Provided by Axos Bank';
        break;
      case FacingBrand.UFB:
        this.brandName = 'UFB Direct';
        this.brandTeamName = 'UFB Direct Bank';
        break;
    }

    this._root['userInfo']
      ? this._setUserInfo()
      : this._scope.$on('userInfoLoaded', () => {
          this._setUserInfo();
        });
  }

  private _setUserInfo(): void {
    this.userInfo = this._root['userInfo'];
  }
  public returnToMessageCenter(state: string) {
    this.state.go(state);
  }
}
