import { accountAggregationChartFormatterServiceProvider } from './account-aggregation-chart-formatter-service.provider';
import { accountAggregationServiceProvider } from './account-aggregation-service.provider';
import { appSettingsServiceProvider } from './app-settings-service.provider';
import { axosClearingServiceProvider } from './axos-clearing-service.provider';
import { axosClearingUrlHelperProvider } from './axos-clearing-url-helper.provider';
import { axosInvestHelperProvider } from './axos-invest-helper.provider';
import { axosInvestUrlHelperProvider } from './axos-invest-url-helper.provider';
import { balanceServiceProvider } from './balance-service.provider';
import { beneficiariesIraEnhHelperProvider } from './beneficiaries-ira-ehn-helper.provider';
import { brandPropertyServiceProvider } from './brand-property-service.provider';
import { cachedAccountsServiceProvider } from './cached-accounts-service.provider';
import { cachedTradingAccountsServiceProvider } from './cached-trading-account-service.provider';
import { cookieHelperProvider } from './cookie-helper.provider';
import { dashboardBalanceServiceProvider } from './dashboard-balance-service.provider';
import { customerLimitsProvider } from './customer-limits-helper.provider';
import { dateRangePickerOptionsProvider } from './date-range-picker-options.provider';
import { documentServiceProvider } from './document-service.provider';
import { enrollmentServiceProvider } from './enrollment-service.provider';
import { externalAccountsHelperProvider } from './external-account-helper.provider';
import { featureFlagServiceProvider } from './feature-flag-service.provider';
import { filtersHelperServiceProvider } from './filters-helper-service.provider';
import { flowServiceProvider } from './flow-service-provider';
import { glanceCobrowseServiceProvider } from './glance-cobrowse-service.provider';
import { livechatServiceProvider } from './live-chat-service.provider';
import { materialDialogProvider } from './material.provider';
import { messagesServiceProvider } from './messages-service.provider';
import { messagesSharedServiceProvider } from './messages-shared-service.provider';
import { modalServiceProvider } from './modal-service.provider';
import { ngReduxServiceProvider } from './ngredux-service.provider';
import { omnichannelServiceProvider } from './omnichannel-service-provider';
import { providerServiceProvider } from './provider-service.provider';
import { payBillsHelperProvider } from './payBillsHelper.provider';
import { redirectStateServiceProvider } from './redirect-state-service.provider';
import { rootScopeProvider } from './rootscope.provider';
import { serviceHelperProvider } from './service-helper.provider';
import { stateParamsServiceProvider } from './state-params-service.provider';
import { stateServiceProvider } from './state-service.provider';
import { stopPaymentServiceProvider } from './stop-payment-service-provider';
import { statementsServiceProvider } from './statements-service.provider';
import { supportServiceProvider } from './support-service.provider';
import { userSubtypeHelperProvider } from './user-subtype-helper.provider';
import { uploadServiceProvider } from './upload-service.provider';
import { iWindowServiceProvider } from './window-service.provider';
import { signatureCardServiceProvider } from './signature-card-service.provider';
import { loadUserProfileHelperProvider } from './load-user-profile-helper.provider';
import { wiseBanyanServiceProvider } from './wise-banyan-service.provider';
import { tilesServiceProvider } from './tile-service.provider';

export {
  accountAggregationChartFormatterServiceProvider,
  accountAggregationServiceProvider,
  axosClearingServiceProvider,
  axosInvestUrlHelperProvider,
  axosInvestHelperProvider,
  brandPropertyServiceProvider,
  cachedAccountsServiceProvider,
  cachedTradingAccountsServiceProvider,
  cookieHelperProvider,
  enrollmentServiceProvider,
  featureFlagServiceProvider,
  glanceCobrowseServiceProvider,
  livechatServiceProvider,
  materialDialogProvider,
  messagesServiceProvider,
  messagesSharedServiceProvider,
  modalServiceProvider,
  ngReduxServiceProvider,
  omnichannelServiceProvider,
  serviceHelperProvider,
  stateParamsServiceProvider,
  stateServiceProvider,
  supportServiceProvider,
  providerServiceProvider,
  payBillsHelperProvider,
  beneficiariesIraEnhHelperProvider,
  externalAccountsHelperProvider,
  stopPaymentServiceProvider,
  axosClearingUrlHelperProvider,
  iWindowServiceProvider,
  redirectStateServiceProvider,
  dashboardBalanceServiceProvider,
  userSubtypeHelperProvider,
  dateRangePickerOptionsProvider,
  filtersHelperServiceProvider,
  flowServiceProvider,
  appSettingsServiceProvider,
  statementsServiceProvider,
  documentServiceProvider,
  uploadServiceProvider,
  customerLimitsProvider,
  signatureCardServiceProvider,
  loadUserProfileHelperProvider,
  wiseBanyanServiceProvider,
  tilesServiceProvider,
};

export const coreProviders = [
  accountAggregationChartFormatterServiceProvider,
  accountAggregationServiceProvider,
  axosClearingServiceProvider,
  axosInvestUrlHelperProvider,
  axosInvestHelperProvider,
  brandPropertyServiceProvider,
  cachedAccountsServiceProvider,
  cachedTradingAccountsServiceProvider,
  cookieHelperProvider,
  enrollmentServiceProvider,
  featureFlagServiceProvider,
  glanceCobrowseServiceProvider,
  livechatServiceProvider,
  materialDialogProvider,
  messagesServiceProvider,
  messagesSharedServiceProvider,
  modalServiceProvider,
  ngReduxServiceProvider,
  omnichannelServiceProvider,
  payBillsHelperProvider,
  rootScopeProvider,
  serviceHelperProvider,
  stateParamsServiceProvider,
  stateServiceProvider,
  supportServiceProvider,
  providerServiceProvider,
  beneficiariesIraEnhHelperProvider,
  balanceServiceProvider,
  externalAccountsHelperProvider,
  stopPaymentServiceProvider,
  axosClearingUrlHelperProvider,
  iWindowServiceProvider,
  redirectStateServiceProvider,
  dashboardBalanceServiceProvider,
  userSubtypeHelperProvider,
  dateRangePickerOptionsProvider,
  filtersHelperServiceProvider,
  flowServiceProvider,
  appSettingsServiceProvider,
  statementsServiceProvider,
  documentServiceProvider,
  axosClearingUrlHelperProvider,
  uploadServiceProvider,
  customerLimitsProvider,
  signatureCardServiceProvider,
  loadUserProfileHelperProvider,
  wiseBanyanServiceProvider,
  tilesServiceProvider,
];
