import { Store } from '@ngrx/store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UIKitModalConfiguration } from '@uikit/tiles';
import { AccountCategory } from 'accounts/account-category.enum';
import * as angular from 'angular';
import { ClearingAccountStatus } from 'common/enums/enums';
import * as moment from 'moment';
import { AxosClearingService } from 'services/axos-clearing.service';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { CachedTradingAccountsService } from 'services/cached-trading-accounts.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { TilesService } from 'services/tiles.service';
import { IAccountsService } from 'services/typings/IAccountsService';
import { AggregatedAccount } from 'typings/app/account-aggregation';

import { truncate } from '@app/utils';
import { ClearingAccountType } from '@shared/enums';

import { Inject } from '../../decorators/decorators';
import { PaymentsService } from '../../services/payments.service';
import { ITransactionService } from '../../services/typings/ITransactionService';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { Freq } from '../../transfers/transfers.controller';
import { ExternalAccountScheduleRequest } from '../../transfers/typings/ExternalAccountScheduleRequest';
import { TransferSchedule } from '../../transfers/typings/TransferSchedule';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryAccount } from '@core/models';
import { AxosAdvisoryService } from '@core/services';
import { filter, finalize, map } from 'rxjs/operators';
import { AxosAdvisoryTransferRequest } from '@app/transfers/models/axos-advisory-transfer-request.model';
import {
  AxosAdvisoryTransferAccountType,
  AxosAdvisoryTransferFrequency,
  AxosAdvisoryTransferType,
} from '@app/transfers/enums';
import { NO_CONTRIBUTION_ACCOUNT_TYPES_CONST } from '@legacy/common/constants';
import { RedirectToIpayModalComponent } from '@app/business/modals/redirect-to-ipay-modal/redirect-to-ipay-modal.component';
import { AccountTypeConstants } from '@legacy/accounts/account-type.constants';
import NgRedux from 'ng-redux';
import { containerType } from '@legacy/accounts/container-type.enum';

@Inject(
  '$rootScope',
  '$scope',
  '$element',
  'serviceHelper',
  'tilesService',
  '$state',
  'accountsService',
  'cachedAccountsService',
  'transactionService',
  '$filter',
  'dateHelper',
  'paymentsService',
  'featureFlagService',
  'axosClearingService',
  'cachedTradingAccountsService',
  'ngrxStore',
  'axosAdvisoryService',
  'loadUserProfileHelper',
  'matDialog',
  '$ngRedux'
)
export class QuickTransferController extends BaseTile<QuickTransferTileSettings> {
  get isSiteInReadOnly(): boolean {
    return this.featureFlagService.isSiteInReadOnly();
  }
  get isSBBActive(): boolean {
    return this.featureFlagService.isSBBActive();
  }
  from: NewOlbAccount;
  to: NewOlbAccount;
  modalConfig: UIKitModalConfiguration;
  fromAccounts: NewOlbAccount[] = [] as NewOlbAccount[];
  toAccounts: NewOlbAccount[];
  filteredToAccounts: NewOlbAccount[] = [];
  tradingAccounts: NewOlbAccount[] = [];
  showModal = false;
  title = 'Move Money';
  quickActionText = 'More Transfer Options';
  isBusy = true;
  hasOnlyOneAccount = false;
  areToAccountsLoading = false;
  settingsToAccounts: NewOlbAccount[] = [];
  amount = 0;
  hasMemo = true;
  hasErrored = false;
  memo = '';
  settings: QuickTransferRawSettings = {};
  transferMoneyImgSrc: any;
  disableTransferButton = false;
  isIraEnhDiraFlagActive = false;
  isRiaUser = false;
  advisoryAccounts: NewOlbAccount[] = [];
  userCommonName: string;
  private validateWithdrawals: boolean;
  private withdrawals: WithdrawalCounter;
  private frequency: Freq;
  private isFromExternal = false;
  private isToExternal = false;
  private regularMonthlyPayment = 0;
  private aggregatedAccounts: AggregatedAccount[];
  private listeners: Function[] = [];
  containerType = containerType;
  isAccountAggregationActive = false;
  isAccountAggregationEnhancementsActive = false;
  private unsubscribe: Function;
  fromAccountDisplayed: NewOlbAccount[] = [];
  allAccounts = {
    checkingAccounts: new Array<AggregatedAccount>(),
    savingAccounts: new Array<AggregatedAccount>(),
  };
  constructor(
    private rootScope: ng.IRootScopeService,
    scope: ng.IScope,
    elm: ng.IRootElementService,
    serviceHelper: IServiceHelper,
    tilesService: TilesService,
    private state: ng.ui.IStateService,
    private accountsService: IAccountsService,
    private cachedAccountsService: CachedAccountsService,
    private transactionService: ITransactionService,
    private filter: ng.IFilterService,
    private dateHelper: DateHelper,
    private paymentService: PaymentsService,
    private featureFlagService: FeatureFlagService,
    private axosClearingService: AxosClearingService,
    private cachedTradingAccountsService: CachedTradingAccountsService,
    private store: Store,
    private axosAdvisoryService: AxosAdvisoryService,
    private loadUserProfileHelper: ILoadUserProfileHelper,
    private matDialog: MatDialog,
    private ngRedux: NgRedux.INgRedux
  ) {
    super(scope, elm, tilesService, serviceHelper);
    this.frequency = Freq.ONETIME;
  }

  /** Initializes the controller. */
  $onInit(): void {
    try {
      this.transferMoneyImgSrc = '/img/icons/tiles/transfer-money.svg';
    } catch {}

    this.isIraEnhDiraFlagActive = this.featureFlagService.isIraEnhTradFlagActive();

    if (this.rootScope['accounts']) this.getSettings(this.setupTile.bind(this));

    this.listeners.push(
      this.scope.$on('accountsLoaded', () => {
        this.getSettings(this.setupTile.bind(this));
      })
    );

    if (this.axosClearingService.isAxosTradingActiveForUser()) {
      this.listeners.push(
        this.scope.$on('investAccountsLoaded', () => {
          this.getTradingAccounts.bind(this)('fromAccounts');
        })
      );
    }
  }

  /** Cleans up the controller. */
  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
    this.unsubscribe();
  }

  /** Redirects the user to the transfers view. */
  redirectToTransfers(): void {
    this.state.go('udb.transfers.transferFunds');
  }

  saveTileSettings(): void {
    this.tileSettings.Amount.value = this.settings.amount.toString();
    this.tileSettings.From.value = this.settings.from
      ? this.settings.from.isTrading
        ? this.settings.from.accountNumber
        : this.settings.from.id.toString()
      : null;
    this.tileSettings.To.value = this.settings.to
      ? this.settings.to.isTrading
        ? this.settings.to.accountNumber
        : this.settings.to.id.toString()
      : null;

    if (this.tileSettings.From.value && this.tileSettings.To.value && this.tileSettings.Amount.value > 0) {
      this.saveSettings();
      this.amount = this.settings.amount;
      this.toAccounts = this.settingsToAccounts;
      this.from = this.settings.from;
      this.to = this.settings.to;
      this.isToExternal = this.settings.to.Type === 'Non-Axos Accounts' || this.to.Type === 'External Accounts';
      this.isFromExternal = this.settings.from.Type === 'Non-Axos Accounts' || this.to.Type === 'External Accounts';
    }
  }

  resetSettings(): void {
    this.settings.amount = +this.tileSettings.Amount.value;
    this.settings.from = this.fromAccounts.filter(
      a => a.id === +this.tileSettings.From.value || a.accountNumber === this.tileSettings.From.value
    )[0];
    this.settings.to = this.toAccounts.filter(
      a => a.id === +this.tileSettings.To.value || a.accountNumber === this.tileSettings.To.value
    )[0];
  }

  /**
   * Load the accounts that are eligible to transfer to
   * @param isFromSettings Specifies if the change is from the settings section.
   */
  fromAccountChanged(isFromSettings: boolean = false): void {
    this.resetFields();

    if (isFromSettings) {
      this.settings.to = null;
      this.settings.amount = null;
      this.loadAccounts(this.settings.from, isFromSettings);

      return;
    }

    const { productType } = this.from;
    this.isFromExternal = this.from.Type === 'Non-Axos Accounts' || this.from.Type === 'External Accounts';
    this.to = null;
    if (this.isFromExternal) {
      this.hasMemo = false;
      this.modalConfig = {
        title: 'Confirm external transfer',
        message: 'Transferring money from external account may take up to 4-5 business days to process.',
        okAction: () => {
          this.loadAccounts(this.from);
          this.showModal = false;
        },
      };
      this.showModal = true;
    } else {
      if (productType?.toLowerCase() === 'money market' || productType?.toLowerCase() === 'savings') {
        this.loadWithdrawals(this.from.id);
      }
      this.loadAccounts(this.from);
    }
  }

  /** Evaluates the account in the "To" dropdown to perform according action. */
  toAccountChanged(): void {
    this.amount = 0;
    this.isToExternal =
      this.to.Type === AccountTypeConstants.NON_AXOS_ACCOUNTS ||
      this.to.Type === AccountTypeConstants.EXTERNAL_ACCOUNTS;

    if (this.isToExternal && this.from.isSBB) {
      const dialog = this.matDialog.open(RedirectToIpayModalComponent, {
        // Account id and, accountId true
        data: { id: this.from.id, isAccountId: true },
      });
      dialog.afterClosed().subscribe(() => {
        this.resetFields();
        this.from = null;
        this.to = null;
      });
    }

    if (this.isToExternal && !this.isIRATrading(this.from)) return;

    if (this.to.productType?.toLowerCase() === 'mortgage') {
      this.hasMemo = false;
      this.regularMonthlyPayment = angular.copy(this.to.loanDueAmount);
      this.amount = this.settings.amount || this.regularMonthlyPayment;
    }

    if (
      +this.to.accountTypeCode === ClearingAccountType.IraTraditional ||
      +this.to.accountTypeCode === ClearingAccountType.IraRoth ||
      +this.from.accountTypeCode === ClearingAccountType.IraTraditional ||
      +this.from.accountTypeCode === ClearingAccountType.IraRoth ||
      (this.to.isIra && this.to.isRia) ||
      this.from.isRia
    ) {
      this.state.go('udb.transfers.transferFunds', { id: this.from.id, toAccountId: this.to.id });
    }
  }

  /** Opens a confirmation modal for the transfer. */
  confirmTransfer(): void {
    this.modalConfig = {
      title: 'Error',
      message: '',
      okAction: this.closeModal.bind(this),
      status: 'error',
    };

    if (this.isTransferValid()) {
      this.showTransferConfirmation();
    }

    this.showModal = true;
  }

  /**
   * Performs all the validations needed for the transfer to occur.
   * @returns True if the transfer is valid.
   */
  private isTransferValid(): boolean {
    let isValid = true;
    if (!this.isFormValid()) {
      return false;
    }
    if (this.to.Type === 'Internal Accounts' || this.from.isTrading) {
      if (!this.isAmountValid()) {
        return false;
      }

      if (this.validateWithdrawals) {
        isValid = this.isWithdrawalCounterValid();
      } else if (this.isADepositAccount(this.to, 'heloc')) {
        isValid = this.isAmountValid();
      } else if (this.isALoanAccount(this.to)) {
        isValid = this.isValidForLoanAccount();
      } else if (this.to.productType.toLowerCase() === 'overdraft line of credit') {
        isValid = this.isValidForLineOfCredit();
      } else if (this.to.productType.toLowerCase() === 'mortgage') {
        isValid = this.isValidForMortgage();
      }
    }

    return isValid;
  }

  /**
   * Loads the number of withdrawals for the specified account.
   * @param accId Account ID.
   */
  private loadWithdrawals(accId: number): void {
    this.accountsService
      .getWithdrawalCounter(accId)
      .then(res => {
        this.withdrawals = res.data;
        this.validateWithdrawals = true;
      })
      .catch(this.serviceHelper.errorHandler);
  }

  /** Posts the transfer. */
  private postTransfer(): void {
    const amount = this.filter('currency')(this.amount);
    const todayString = this.filter('date')(new Date(), 'MM/dd/yyyy hh:mm:ss');
    this.modalConfig.cancelAction = null;
    this.modalConfig.okAction = (): null => null;
    this.modalConfig.okText = 'Transferring';

    if (this.from.accountType === 'AAS' || this.to.accountType === 'AAS') {
      let accountType;

      if (this.from.productType.toLowerCase() == 'checking') {
        accountType = AxosAdvisoryTransferAccountType.Checking;
      } else if (this.from.productType.toLowerCase() == 'savings') {
        accountType = AxosAdvisoryTransferAccountType.Savings;
      }
      const transferData: AxosAdvisoryTransferRequest = {
        accountNumber: this.to.accountNumber,
        amount: this.amount,
        transactionType: AxosAdvisoryTransferType.Contribution,
        frequency: AxosAdvisoryTransferFrequency.OneTime,
        executionDate: this.dateHelper.normalizeDate(moment()).toDate(),
        taxYear: moment().year(),
        bankAccountNumber: this.from.accountNumber,
        bankAccountType: accountType,
        bankName: this.from.isExternal ? this.from.bankName : 'Axos Bank',
        bankRoutingNumber: this.from.routingNumber,
        bankOwnerName: this.userCommonName,
      };
      this.axosAdvisoryService
        .createAxosAdvisoryTransfer(transferData)
        .pipe(
          finalize(() => {
            this.showModal = true;
          })
        )
        .subscribe({
          next: response => {
            this.modalConfig = {
              title: 'Money Transferred',
              message: `${amount} has been successfully transferred from ${this.from.nickname} to ${this.to.nickname} transactionId: ${response.data.transactionId}`,
              okAction: this.closeModal.bind(this, true),
            };
          },
          error: err => {
            this.modalConfig = {
              title: 'An error has occurred',
              message: err.error.message,
              okAction: this.closeModal.bind(this),
              status: 'error',
            };
          },
        });
      return;
    }

    if (this.from.isTrading || this.to.isTrading) {
      const transaction: TransferSchedule = {
        accountNumberFrom: this.from.accountNumber,
        accountTypeFrom: this.from.accountType,
        accountProductTypeFrom: this.from.productType,
        accountNicknameFrom: this.from.nickname,
        accountNumberTo: this.to.accountNumber,
        accountTypeTo: this.to.accountType,
        accountProductTypeTo: this.to.productType,
        accountNicknameTo: this.to.nickname,
        amount: this.amount,
        fromIsExternal: !!this.from.externalAccountId,
        fromIsTrading: this.from.isTrading,
        toIsExternal: !!this.to.externalAccountId,
        toIsTrading: this.to.isTrading,
      };

      this.sendTradingTransfer(transaction);

      return;
    }

    if (!this.isToExternal && !this.isFromExternal) {
      // Internal to internal transfer.
      const internalTransfer: TransferSchedule = {
        accountIdFrom: this.from.id,
        accountNumberFrom: this.from.accountNumber,
        accountTypeFrom: this.from.accountType,
        accountProductTypeFrom: this.from.productType,
        accountIdTo: this.to.id,
        accountNumberTo: this.to.accountNumber,
        accountTypeTo: this.to.accountType,
        accountProductTypeTo: this.to.productType,
        amount: this.amount,
        memo: this.memo,
        dateAddedString: todayString,
        frequency: this.frequency,
        confirmationEmail: false,
        confirmationTextMessage: false,
      };

      this.transactionService
        .transfer(internalTransfer)
        .then(() => {
          // Update the accounts' amounts
          this.accountsService.getSortedAccounts().then(accounts => {
            this.updateAccountAmounts(accounts.data);
            this.transactionService.clearCache();
            this.paymentService.clearCache();
            this.rootScope.$broadcast('transferCompleted');

            this.modalConfig = {
              title: 'Money Transferred',
              message: `${amount} has been successfully transferred from ${this.from.nickname} to ${this.to.nickname}`,
              okAction: this.closeModal.bind(this, true),
            };
          });
        })
        .catch(err => {
          this.modalConfig = {
            title: 'An error has occurred',
            message: err.data.message,
            okAction: this.closeModal.bind(this),
            status: 'error',
          };
        })
        .finally(() => {
          this.showModal = true;
        });

      return;
    }

    const { externalTransferValidDate } = this.dateHelper.getDefaultPaymentInformation();
    const externalTransfer: ExternalAccountScheduleRequest = {
      externalAccountId: this.isFromExternal ? this.from.id : this.to.id,
      internalAccountId: this.isFromExternal ? this.to.id : this.from.id,
      amount: this.amount,
      frequency: this.frequency,
      transferType: this.isFromExternal ? 0 : 1,
      accountCode: '',
      numberOfTransfers: 1,
      processingDate: externalTransferValidDate.toDate(),
      additionalPrincipal: 0,
      accountNumberFrom: this.from.accountNumber,
      accountNumberTo: this.to.accountNumber,
    };

    this.transactionService
      .scheduleExternalTransfer(externalTransfer)
      .then(() => {
        const fromDisplayName = this.setNickName(this.from);
        const toDisplayName = this.setNickName(this.to);

        this.modalConfig = {
          title: 'Transfer Scheduled',
          message: `${amount} will be transferred from ${fromDisplayName} to ${toDisplayName}`,
          okAction: this.closeModal.bind(this, true),
        };
      })
      .catch(err => {
        this.modalConfig = {
          title: 'An error has occurred',
          message: err.data.message,
          okAction: this.closeModal.bind(this),
          status: 'error',
        };
      })
      .finally(() => {
        this.showModal = true;
      });
  }

  private setNickName(account: NewOlbAccount): string {
    const productTypeTrading = 'AxosTrading';
    const defaultNicknameTrading = 'Self-Directed Trading';
    const nonAxos = 'Non-Axos Accounts';

    if( account.Type === nonAxos ) {
      return account.nickname+' **'+account.accountMask
    }

    if (account.productType && account.productType != productTypeTrading) {
      return account.nickname
        ? account.nickname
        : `${account.productType} *${account.accountNumber.substr(
            account.accountNumber.length - 4
          )}`;
    } else {
      return account.nickname == defaultNicknameTrading ||
        account.accountType === 'AAS'
        ? `${account.nickname} *${account.accountNumber.substr(
            account.accountNumber.length - 4
          )}`
        : account.nickname;
    }
  }

  /** Shows a modal to confirm the transfer. */
  private showTransferConfirmation(): void {
    const amount = this.filter('currency')(this.amount);
    const isExternalTransfer = this.isToExternal || this.isFromExternal;

    const today = this.dateHelper.normalizeDate(moment());
    const { externalTransferValidDate } = this.dateHelper.getDefaultPaymentInformation();
    const diffDays = externalTransferValidDate.diff(today, 'days');
    let nextBusinessDayMsg = '';
    if (isExternalTransfer && diffDays > 0) {
      if (diffDays == 1) {
        nextBusinessDayMsg = 'Transfer will be scheduled next business day.\n';
      } else {
        nextBusinessDayMsg = 'Transfer will be scheduled next 2 business day.\n';
      }
    }

    const fromDisplayName = this.setNickName(this.from);
    const toDisplayName = this.setNickName(this.to);

    this.modalConfig = {
      title: isExternalTransfer ? `Transfer ${amount}?` : `Transfer ${amount} Now?`,
      message: `${nextBusinessDayMsg}Are you sure you want to transfer ${amount} from ${fromDisplayName} to ${toDisplayName}?`,
      okText: isExternalTransfer ? 'Transfer' : 'Transfer Now',
      okAction: this.postTransfer.bind(this),
      cancelText: 'Cancel',
      cancelAction: this.showTransferCancellation.bind(this),
    };
  }

  private sendTradingTransfer(transaction: TransferSchedule) {
    this.transactionService
      .transferTrading(transaction)
      .then(() => {
        const fromDisplayName = this.setNickName(this.from);
        const toDisplayName = this.setNickName(this.to);

        const amount = this.filter('currency')(transaction.amount);
        this.modalConfig = {
          title: 'Transfer Scheduled',
          message: `${amount} will be transferred from ${fromDisplayName} to ${toDisplayName}`,
          okAction: this.closeModal.bind(this, true),
        };
      })
      .catch(err => {
        this.modalConfig = {
          title: 'An error has occurredd',
          message: err.data.message,
          okAction: this.closeModal.bind(this),
          status: 'error',
        };
      })
      .finally(() => {
        this.showModal = true;
      });
  }

  /** Shows a modal confirming the cancellation of the transfer. */
  private showTransferCancellation(): void {
    this.modalConfig = {
      title: 'Transfer Canceled',
      message: 'No transfer has occurred between any of your accounts.\n\n Have a great day!',
      okAction: this.closeModal.bind(this),
    };
  }

  /** Closes the shown modal. */
  private closeModal(resetForm: boolean = false): void {
    this.showModal = false;
    if (resetForm) {
      this.resetFields();
      this.from = null;
      this.to = null;
    }
  }

  /** Resets the component's state to initial. */
  private resetFields(): void {
    this.amount = 0;
    this.memo = '';
    this.hasMemo = true;
    this.validateWithdrawals = false;
    this.regularMonthlyPayment = 0;
    this.isToExternal = false;
    this.isFromExternal = false;
  }

  /**
   * Validates the form.
   * @returns True if its valid.
   */
  private isFormValid(): boolean {
    let isValid = true;
    this.modalConfig = {
      title: 'Error',
      message: '',
      okAction: this.closeModal.bind(this),
      status: 'error',
    };

    if (!this.from) {
      this.modalConfig.message = 'Please select an account to transfer from.';
      isValid = false;
    } else if (!this.to) {
      this.modalConfig.message = 'Please select an account to transfer to.';
      isValid = false;
    } else if (!this.amount) {
      this.modalConfig.message = 'The amount to transfer should be at least $0.01';
      isValid = false;
    }

    return isValid;
  }

  /**
   * Validates the withdrawal counters of the from account.
   * @returns True if the withdrawal is valid.
   */
  private isWithdrawalCounterValid(): boolean {
    let { lastBusinessDayInCycle, withdrawalCounter, eligibleWithdrawals } = this.withdrawals;
    if (withdrawalCounter > eligibleWithdrawals) {
      lastBusinessDayInCycle = this.filter('date')(lastBusinessDayInCycle, 'MM/dd/yyyy');

      const text = `As of ${lastBusinessDayInCycle} there have been ${withdrawalCounter}
        of ${eligibleWithdrawals} eligible withdrawals during this cycle`;

      this.modalConfig = {
        title: 'Attention',
        message: text,
        okText: 'Continue',
        okAction: this.showTransferConfirmation.bind(this),
        cancelText: 'Cancel',
        cancelAction: this.closeModal.bind(this),
        status: 'warning',
      };

      return false;
    }

    return true;
  }

  /** Sets up the tile with its needed configurations. */
  private setupTile(): void {
    try {
      let toTradingAccounts = [];
      this.loadRiaAccounts();

      const allAccounts = angular.copy(this.cachedAccountsService.getFromAccounts(true));
      this.aggregatedAccounts = this.cachedAccountsService.aggregatedAccounts;

      if (this.axosClearingService.isAxosTradingActiveForUser()) {
        this.tradingAccounts = this.getTradingAccounts.bind(this)('fromAccounts');
        toTradingAccounts = this.getTradingAccounts.bind(this)('toAccounts');
      }

      const externalAccounts = allAccounts.externalAccounts;
      let filteredExternalAccounts = externalAccounts;
      if (externalAccounts) {
        externalAccounts.forEach(value => {
          const aggregatedAccnt = this.aggregatedAccounts?.find(
            (x: AggregatedAccount) => x.accountNumber == value.accountNumber
          );
          const isAggregatedAccnt = !!aggregatedAccnt;
          value.availableBalance = isAggregatedAccnt ? aggregatedAccnt.availableBalance : null;
        });
        // Don't show External Yodlee Business Accounts if SBB Acc is present
        if (allAccounts.internalAccounts.some(acc => acc.isSBB)) {
          filteredExternalAccounts = filteredExternalAccounts.filter(acc => !acc.isSBB);
        }
      }

      this.fromAccounts = [
        ...allAccounts.internalAccounts,
        ...this.tradingAccounts,
        ...filteredExternalAccounts,
        ...this.advisoryAccounts,
      ];

      this.fromAccounts = this.fromAccounts.filter(a => !a.isIra || a.accountType === 'AAS');

      this.setDisplayNames(this.fromAccounts);

      this.toAccounts = [
        ...allAccounts.internalAccounts,
        ...toTradingAccounts,
        ...allAccounts.externalAccounts,
        ...this.advisoryAccounts,
      ];

      this.toAccounts = this.toAccounts.filter(a => !a.isIra || a.accountType === 'AAS');
      this.setDisplayNames(this.toAccounts);

      // check data source to disable Transfer now button
      this.disableTransferButton = this.fromAccounts.length <= 0;

      this.isAccountAggregationActive = this.featureFlagService.isAccountAggregationActive();
      this.isAccountAggregationEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
      this.isAccountAggregationActive &&
        (this.unsubscribe = this.ngRedux.subscribe(this.loadAccountsInformation.bind(this)));

      if (this.rootScope['accounts']) {
        this.loadAccountsInformation();
      }

      this.resetSettings();
      this.from = this.settings.from;
      this.to = this.settings.to;
      this.isFromExternal = !!(this.settings.from && this.settings.from.Type === 'Non-Axos Accounts');
      this.amount = angular.copy(this.settings.amount);
      // Will background-load the "to" accounts presented in the settings section only when settings are present.
      if (this.from) {
        this.loadAccounts(this.from, true);
      }
      this.isBusy = false;
      this.hasOnlyOneAccount = this.fromAccounts.length < 2;
      if (this.hasOnlyOneAccount) {
        this.quickActionText = '';
      }
    } catch (err) {
      this.serviceHelper.serviceError(err);
    }
  }

  /**
   * Loads the accounts that the specified one can transfer to.
   * @param account Account from which the transfer will be processed.
   * @param isFromSettings Specifies if the call is being triggered from the settings section.
   */
  private loadAccounts(account: NewOlbAccount, isFromSettings?: boolean): void {
    const { Type, id, isSBB } = account;
    account.isSBB = isSBB != null && isSBB;
    const request = {
      id,
      isExternal: false,
      // Set if it is an SBB Account
      isSBB: isSBB != null && isSBB,
    };
    // this.areToAccountsLoading = true;

    if (Type === AccountTypeConstants.NON_AXOS_ACCOUNTS || Type === AccountTypeConstants.EXTERNAL_ACCOUNTS) {
      request.isExternal = true;
    }

    if (account.isTrading) {
      if (this.isIRATrading(account)) {
        this.filteredToAccounts = this.toAccounts.filter(
          a => a.productType.toLowerCase() === 'savings' || a.productType.toLowerCase() === 'checking'
        );
      } else {
        this.filteredToAccounts = this.toAccounts.filter(a => !a.isTrading);
      }
    } else {
      if (request.isExternal) {
        this.filteredToAccounts = this.toAccounts.filter(a => a.id !== account.id);
        this.filteredToAccounts = this.filteredToAccounts.filter(
          a => a.Type === AccountTypeConstants.INTERNAL_ACCOUNTS && !a.isSBB
        );
      } else {
        this.filteredToAccounts = this.toAccounts.filter(a => a.id !== account.id);
      }
    }

    // Logic For SBB:
    if (account?.isSBB && !request.isExternal) {
      // SBB Buisness
      this.filteredToAccounts = this.toAccounts.filter(acc => {
        return (
          (acc.Type === AccountTypeConstants.INTERNAL_ACCOUNTS ||
            acc.Type === AccountTypeConstants.NON_AXOS_ACCOUNTS) &&
          acc.id !== account.id
        );
      });
    }
    // Personal Accounts
    else if (!account?.isSBB && !request.isExternal && !account.isTrading) {
      this.filteredToAccounts = this.toAccounts.filter(acc => {
        return (
          (acc.Type === AccountTypeConstants.INTERNAL_ACCOUNTS ||
            (acc.Type === AccountTypeConstants.NON_AXOS_ACCOUNTS && !acc.isSBB) ||
            acc.Type === AccountTypeConstants.INVESTMENT_ACCOUNTS) &&
          acc.id !== account.id
        );
      });
    }

    //Restrict Business Accounts for any Investment Account
    this.filterBusinessAccountsForInvestmentFromAccount();

    if (isFromSettings) {
      this.settingsToAccounts = this.filteredToAccounts;
      const defaultAccount = this.filteredToAccounts.filter(
        a => a.id === +this.tileSettings.To.value || a.accountNumber === this.tileSettings.To.value
      )[0];
      this.isToExternal = !!(
        this.settings.to &&
        (this.settings.to.Type === AccountTypeConstants.NON_AXOS_ACCOUNTS ||
          this.settings.to.Type === AccountTypeConstants.EXTERNAL_ACCOUNTS)
      );
      this.to = defaultAccount;
    } else {
      this.settingsToAccounts = this.filteredToAccounts;
    }
  }

  /**
   * States if the specified account is of demand deposit type.
   * Demand deposit includes Checking, Money Market and Savings.
   * @param account Account to check.
   * @param accounts Extra loan account types.
   * @returns True if the account is of demand deposit type.
   */
  private isADepositAccount(account: OlbAccount, ...accountTypes: string[]): boolean {
    const acct = ['checking', 'money market', 'savings', ...accountTypes];

    if (account && account.productType) {
      return acct.some(t => t === account.productType.toLowerCase());
    }

    return false;
  }

  /**
   * States if the specified account is of loan type.
   * Loan includes HELOC, Auto Loan and Personal Loan.
   * @param account Account to check.
   * @param accounts Extra loan account types.
   * @returns True if the account is of demand deposit type.
   */
  private isALoanAccount(account: OlbAccount, ...accountTypes: string[]): boolean {
    const acct = ['heloc', 'auto loan', 'personal loan', 'payment protection program', ...accountTypes];

    if (account && account.productType) {
      return acct.some(t => t === account.productType.toLowerCase());
    }

    return false;
  }

  /**
   * Validates if the amount of the transfer is valid.
   * @returns True if it's valid.
   */
  private isAmountValid(): boolean {
    let amountValid = true;

    if (this.from.isTrading) {
      if (this.amount > this.from.amountAvailableToWithdraw) {
        this.modalConfig.message = 'Insufficient funds available';
        amountValid = false;
      }
    } else if (!this.isFromExternal) {
      if (this.amount > this.from.availableBalance) {
        this.modalConfig.message = 'Insufficient funds available';
        amountValid = false;
      }
    } else if (this.isFromExternal) {
      if (this.from.availableBalance) {
        if (this.amount > this.from.availableBalance) {
          this.modalConfig.message = 'Insufficient funds available';
          amountValid = false;
        }
      }
    }

    return amountValid;
  }

  /**
   * Validates the amount when transferring to loan accounts.
   * @param bypassExternal Specifies if validation of the from account (if it's external) should be excluded.
   * @returns True if it's valid.
   */
  private isValidForLoanAccount(bypassExternal: boolean = this.isFromExternal): boolean {
    let isValid = true;
    if (bypassExternal) {
      if (this.isADepositAccount(this.from)) {
        isValid = this.isAmountValid();
        isValid = this.isOutstandingBalancePaymentValid();
      }
    }

    return isValid;
  }

  /**
   * Validates if the transfer is valid when transfer int to Overdraft Line of Credit accounts.
   * @returns True if it's valid.
   */
  private isValidForLineOfCredit(): boolean {
    let isValid = true;

    if (this.amount < this.to.minimumAmountDue) {
      const { minimumAmountDue } = this.to;
      const balance = this.filter('currency')(minimumAmountDue);
      this.modalConfig.message = `The amount does not cover the current amount due (${balance})`;
      isValid = false;
    }

    isValid = this.isValidForLoanAccount(true);

    return isValid;
  }

  /**
   * Validates if the amount transferred is valid for the outstanding balance.
   * @returns True if it's valid.
   */
  private isOutstandingBalancePaymentValid(): boolean {
    let isValid = true;
    if (this.amount > this.to.outstandingBalance) {
      const { outstandingBalance } = this.to;
      const balance = this.filter('currency')(outstandingBalance);
      this.modalConfig.message = `Payment exceeds your outstanding balance (${balance})`;
      isValid = false;
    }

    return isValid;
  }

  /**
   * Validates if the transfer is valid for mortgage accounts.
   * @returns True if it's valid.
   */
  private isValidForMortgage(): boolean {
    let isValid = true;

    if (this.amount > this.to.loanDueAmount) {
      this.modalConfig.message = 'Payment exceeds your current amount due';
      isValid = false;
    }

    isValid = this.isAmountValid();

    isValid = this.isOutstandingBalancePaymentValid();

    return isValid;
  }

  /**
   * Sets the display names of the given accounts.
   * @param accounts Accounts to set display names. Includes both internal and external.
   * @returns An array of sorted accounts.
   */
  private setDisplayNames(accounts: NewOlbAccount[]): NewOlbAccount[] {
    const internalAccounts = accounts.filter(a => !a.isExternal && !a.isTrading && a.category != AccountCategory.Loan);

    const externalAccounts = accounts.filter(a => a.externalAccountId);
    const tradingAccounts = accounts.filter(a => a.isTrading);
    const loanAccounts = accounts.filter(a => a.category == AccountCategory.Loan);

    loanAccounts.forEach(account => {
      account.Type = 'Loan Accounts';
      account.accountType = account.accountCategory;
      account.productType = account.accountCategory;
    });

    internalAccounts.forEach(account => {
      const name = truncate(account.nickname, 10);

      if (account.nickname?.length <= 13) {
        account.displayName = account.nickname;
        if (account.accountMask) {
          account.displayName = `${account.nickname} *${account.accountMask}`;
        }
      } else if (account.accountMask) {
        account.displayName = name
          ? `${name} *${account.accountMask}`
          : `${account.displayName.substring(0, 10)}... *${account.accountMask}`;
      }

      account.Type = 'Internal Accounts';
    });

    externalAccounts.forEach(account => {
      account.id = account.externalAccountId;
      account.nickname = account.nickname || account.displayName;
      const name = truncate(account.nickname, 10);
      account.displayName = `${name} *${account.accountMask}`;
      account.Type = 'Non-Axos Accounts';
      account.accountType = account.accountCategory;
      account.productType = account.accountCategory;
    });

    tradingAccounts.forEach(account => {
      if (account.accountType && account.accountType != 'Individual') account.displayName = account.nickname;
    });

    return [...internalAccounts, ...tradingAccounts, ...externalAccounts];
  }

  private updateAccountAmounts(accounts: AccountsPage): void {
    if (!this.rootScope['accounts']) return;

    this.cachedAccountsService.fixIraBalance(accounts);
    const depositAccounts: OlbAccount[] = accounts.depositAccounts;
    const loanAccounts: OlbAccount[] = accounts.loanAccounts;

    if (depositAccounts) {
      this.rootScope['accounts'].depositAccounts = depositAccounts;
    }

    if (loanAccounts) {
      this.rootScope['accounts'].loanAccounts = loanAccounts;
    }
  }

  private isIRATrading(account: any): boolean {
    return (
      +account?.accountTypeCode === ClearingAccountType.IraTraditional ||
      +account?.accountTypeCode === ClearingAccountType.IraRoth ||
      +account?.type === ClearingAccountType.IraTraditional ||
      +account?.type === ClearingAccountType.IraRoth
    );
  }

  // Gets Trading Accounts for Drop down lists
  private getTradingAccounts(accountsArrayProperty: 'fromAccounts' | 'toAccounts'): NewOlbAccount[] {
    try {
      const tradingAccounts: NewOlbAccount[] = [];

      const tradingAccountsArray: any[] = this.cachedTradingAccountsService.tradingAccounts;

      if (tradingAccountsArray) {
        let filteredTradingAccountsArray = [];

        if (this.isIraEnhDiraFlagActive) {
          filteredTradingAccountsArray = tradingAccountsArray.filter(
            a =>
              a.status === ClearingAccountStatus.Active &&
              (accountsArrayProperty === 'fromAccounts' || accountsArrayProperty === 'toAccounts')
          );
        } else {
          filteredTradingAccountsArray = tradingAccountsArray.filter(
            a =>
              a.status === ClearingAccountStatus.Active &&
              ((accountsArrayProperty === 'fromAccounts' && !this.isIRATrading(a)) ||
                accountsArrayProperty === 'toAccounts')
          );
        }

        filteredTradingAccountsArray.forEach(value => {
          const totalValue = `(Total Value: ${value.amountAvailableToWithdraw.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })})`;
          const displayName = `${truncate(value.nickname, 10)} *${value.accountNumber.substr(
            value.accountNumber.length - 4
          )}`;

          tradingAccounts.push({
            id: value.id,
            bankName: value.bankName,
            accountType: value.typeName,
            accountTypeCode: value.type,
            availableBalance: value.amountAvailableToWithdraw,
            nickname: value.nickname,
            accountNumber: value.accountNumber,
            productType: 'AxosTrading',
            Type: 'Investment Accounts',
            isTrading: true,
            displayName: `${displayName}`,
            displayFromTypeName: accountsArrayProperty === 'fromAccounts' ? displayName : '',
            displayToTypeName: accountsArrayProperty === 'toAccounts' ? displayName : '',
            availableBalanceDisplay: totalValue,
            amountAvailableToWithdraw: value.amountAvailableToWithdraw,
          });
        });
      }

      return tradingAccounts;
    } catch (err) {
      this.serviceHelper.serviceError(err);
    }
  }

  private getRiaUserInfo(): void {
    this.loadUserProfileHelper.getUserProfilePromise().then(res => {
      this.userCommonName = res.lastName + ' ' + res.firstName;
    });
  }

  private loadRiaAccounts(): void {
    if (this.featureFlagService.isRiaPilotActive()) {
      this.store
        .select(getAxosAdvisoryAccounts)
        .pipe(
          filter(result => result != null),
          map(accounts => accounts.filter(account => !!account.dateCloseInitiated == false)) //Filter out accounts with closing date set
        )
        .subscribe((riaAccounts: AxosAdvisoryAccount[]) => {
          this.isRiaUser = riaAccounts.filter(x => x.status === 'active')?.length > 0 ? true : false;
          if (this.isRiaUser) {
            this.getRiaUserInfo();
          }

          riaAccounts = riaAccounts.filter(x => !NO_CONTRIBUTION_ACCOUNT_TYPES_CONST.includes(x.accountTypeCode));

          this.advisoryAccounts = riaAccounts.map((acc: AxosAdvisoryAccount) => ({
            nickname: acc.accountNickname,
            displayName: acc.accountDisplayName,
            id: Number(acc.riaId),
            accountNumber: acc.accountNumber,
            currentBalance: acc.accountBalance,
            displayBalance: acc.accountBalance,
            availableBalance: acc.accountBalance,
            availableBalanceDisplay: acc.accountBalance.toString(),
            accountType: acc.accountType,
            Type: 'Investment Accounts',
            status: acc.status,
            accountTypeCode: acc.accountTypeCode,
            productType: acc.productType,
            isRia: true,
            isIra: acc.isRetirement,
            isTrading: true,
            bankName: acc.bankName,
            routingNumber: acc.routingNumber,
          }));
        });
    }
  }

  private filterBusinessAccountsForInvestmentFromAccount() {
    if (this.from.Type === AccountTypeConstants.INVESTMENT_ACCOUNTS) {
      this.filteredToAccounts = this.filteredToAccounts.filter(
        acc =>
          (acc.Type === AccountTypeConstants.NON_AXOS_ACCOUNTS && !acc.isSBB) ||
          (acc.Type === AccountTypeConstants.INTERNAL_ACCOUNTS && !acc.isSBB)
      );
    }
  }
  loadAccountsInformation() {
    const depositAccounts = this.cachedAccountsService.internalAccounts
      ? this.cachedAccountsService.internalAccounts.depositAccounts
      : [];

    let externalAccounts = this.isAccountAggregationActive
      ? this.cachedAccountsService.aggregatedAccounts || []
      : [];
    externalAccounts =
      externalAccounts instanceof Error ? [] : externalAccounts;

    // checking accounts
    let accounts = this.filterAccounts(
      depositAccounts,
      externalAccounts,
      (account: AggregatedAccount) => account.category === AccountCategory.Dda
    );
    this.allAccounts.checkingAccounts = accounts.allAccounts;

    // saving accounts
    accounts = this.filterAccounts(
      depositAccounts,
      externalAccounts,
      (account: AggregatedAccount) =>
        account.container !== this.containerType.Investment &&
        (account.category === AccountCategory.Sav ||
          account.category === AccountCategory.Cd)
    );
    this.allAccounts.savingAccounts = accounts.allAccounts;

    if (this.fromAccounts.length > 0) {
      this.fromAccountDisplayed = [...this.fromAccounts];

      let fromMap = new Map();

      this.allAccounts.checkingAccounts.forEach(acc => {
        fromMap.set(acc.accountNumber, {
          nickname: acc.nickname,
          mask: acc.accountMask.replace('xxxx', ''),
        });
      });

      this.allAccounts.savingAccounts.forEach(acc => {
        fromMap.set(acc.accountNumber, {
          nickname: acc.nickname,
          mask: acc.accountMask.replace('xxxx', ''),
        });
      });

      this.fromAccountDisplayed.forEach(fromAcc => {
        if (fromMap.get(fromAcc.accountNumber)) {
          fromAcc.nickname = fromMap.get(fromAcc.accountNumber).nickname;
          fromAcc.accountMask = fromMap.get(fromAcc.accountNumber).mask;
        }

        if (!fromAcc.hasOwnProperty('displayName')) {
          fromAcc.displayName = fromAcc.nickname;
        }
      });

      this.setDisplayNames(this.fromAccountDisplayed);
    }
  }

  private filterAccounts(
    internalAccounts: AggregatedAccount[],
    externalAccounts: AggregatedAccount[],
    condition: Function
  ): any {
    let onlyAxosAccounts: AggregatedAccount[];
    let allAccounts: AggregatedAccount[];

    onlyAxosAccounts = [...internalAccounts].filter(account => condition(account));
    allAccounts = [...internalAccounts, ...externalAccounts].filter(account => condition(account));
    const accounts = {
      onlyAxosAccounts,
      allAccounts,
    };

    return accounts;
  }
}

interface QuickTransferTileSettings extends BaseTileSettings {
  To?: TileSetting;
  From?: TileSetting;
  Amount?: TileSetting;
}

interface QuickTransferRawSettings {
  amount?: number;
  from?: NewOlbAccount;
  to?: NewOlbAccount;
}