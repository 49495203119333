<div class="frow wrap baseline account-information">
  <div class="fcol-cs-12">
    <h5>{{ this.rewardsCheckingCharts.apyTitle }}</h5>
    <div *ngFor="let crit of rewardsCheckingCriterias" class="overflow-au cTable">
      <div class="mb-3">
        {{ crit.criteriaName.trim() }}
        <b>{{ '+' + crit.criteriaAPY + '%' }}</b>
      </div>
      <progress class="progress purchase-limit col-sm-11 p-0" [value]="crit.criteriaProgressBar" max="1"></progress>
      <i
        id="colorTest"
        class="col-sm-1"
        [ngClass]="crit.criteriaStatus === 'green' ? 'fa fa-check-circle' : 'fa fa-times-circle'"
        [style]="
          requiredComplete || crit.criteriaName.includes('required') ? 'color: ' + crit.criteriaStatus : 'color: gray;'
        "
      ></i>
      <div class="col-sm-11 criteria">
        <small>{{ crit.criteriaValue.trim() }}</small>
      </div>
    </div>
  </div>
</div>
