import { IStateService } from 'angular-ui-router';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';

import { RestrictionType } from '../common/enums/enums';
import { Inject } from '../decorators/decorators';
import { CachedAccountsService } from '../services/cached-accounts.service';

import { CachedTradingAccountsService } from '@legacy/services/cached-trading-accounts.service';
import { Store } from '@ngrx/store';
import { AxosAdvisoryAccount } from '@core/models';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';

@Inject(
  '$rootScope',
  'env',
  'cachedAccountsService',
  '$scope',
  'userSubtypeHelper',
  '$state',
  'ngrxStore',
  'cachedTradingAccountsService'
)
export class TransfersController {
  isTransferFundsChanged = false;
  active: number;
  isUnderAge: boolean;
  subTabs: Tab[];
  brandName: string;
  stateService: IStateService;
  isRiaUser: boolean;
  hasOtherAccounts = false;

  constructor(
    private _root: ng.IRootScopeService,
    private env: OlbSettings,
    private cachedAccountsService: CachedAccountsService,
    private scope: ng.IScope,
    private userSubtypeHelper: UserSubtypeHelper,
    private readonly state: IStateService,
    private store: Store,

    private cachedClearingAccounts: CachedTradingAccountsService
  ) {}

  /** Loads layout required data. */
  $onInit(): void {
    this.isUnderAge = this._root['userInfo'] ? this._root['userInfo'].isUnderAge : false;
    this.brandName = String(this.env.brandName);
    this.stateService = this.state;

    ///Check if the user is RIA type or has other accounts
    this.checkIfUserHasAccounts();

    this._setSubTapRestriction();
    this.validateWires();
    this.scope.$on('accountsLoaded', () => {
      this.validateWires();
    });

    this.scope.$emit('showSubmenuLocalAlert', true);

    this.validatePayItNowTab();

    this.scope.$on('$destroy', () => this.scope.$emit('showSubmenuLocalAlert', false));
  }

  /**
   * Centers the tab clicked
   * @param evt The angular event
   */
  scrollToView(evt: any): void {
    const wrapper = $(document.querySelector('.tab-set--wrapper'))[0];
    const target = evt.target.parentElement;
    // Centers the selected tab (50 is the sum of the right and left padding of the li item)
    wrapper.scrollLeft = target.offsetLeft - (wrapper.clientWidth - target.clientWidth + 50) / 2;
  }

  private _setSubTapRestriction(): void {
    this.subTabs = [
      {
        name: 'Move Money',
        ref: 'udb.transfers.transferFunds',
        isShown: false,
        restriction: RestrictionType.InternalTransfer,
      },
      {
        name: 'Scheduled Transfers',
        ref: 'udb.transfers.schedulerTransfers',
        isShown: false,
      },
      {
        name: 'Pay It Now',
        ref: 'udb.transfers.p2p.sendMoney',
        isShown: false,
        restriction: RestrictionType.P2P,
      },
      {
        name: 'Wire Transfers',
        ref: 'udb.transfers.wireTransfers',
        isShown: false,
      },
    ];

    const restriction = this._root['userRestrictions'];

    const accounts = this.cachedAccountsService.getPaymentAccounts(true);
    const atLeastOneAccountHasP2P = accounts.some(a => a.canTransferFromP2P);

    this.subTabs.forEach(tab => {
      tab.isShown = !restriction || restriction.indexOf(tab.restriction) === -1;
      if (tab.name === 'Wire Transfers') {
        tab.isShown = false;
      }

      if (this.isRiaUser && !this.hasOtherAccounts) {
        if (tab.name === 'Move Money' || tab.name === 'Scheduled Transfers') {
          tab.isShown = true;
        } else {
          tab.isShown = false;
        }
      }

      if (tab.name === 'Pay It Now' && !atLeastOneAccountHasP2P) {
        tab.isShown = false;
      }
    });
  }
  // Validate if user can make wire transactions
  private validateWires(): void {
    // User must have FROM accounts with category 1,2 or 3 in order to make wire transactions.
    const accounts = this.cachedAccountsService.getFromAccounts();
    if (accounts.internalAccounts) {
      accounts.internalAccounts.forEach(acc => {
        if (acc.category == 1 || acc.category == 2 || acc.category == 4) {
          this.subTabs[3].isShown = true;
        }
      });
    }
  }

  private checkIfUserHasAccounts() {
    ///Check if the user is RIA type user
    this.store.select(getAxosAdvisoryAccounts).subscribe((riaAccounts: AxosAdvisoryAccount[]) => {
      this.isRiaUser = riaAccounts?.length > 0 ? true : false;
    });

    const externals = this.cachedAccountsService.allAccounts?.externalAccounts?.length > 0;
    const internals = this.cachedAccountsService.allAccounts?.internalAccounts?.length > 0;
    const trading = this.cachedClearingAccounts.tradingAccounts.length > 0;

    this.hasOtherAccounts = externals || internals || trading;
  }

  private validatePayItNowTab(): void {
    if (!this.userSubtypeHelper.userHasAxosBankAccounts()) {
      this.subTabs[2].isShown = false;
    }
  }
}

/**
 * Returns the frequency desciption passing as argument the frequency code
 * @param frequency Identifier for the frequency to be mapped
 * @returns A string which represents the label of the frequency
 */
export function mapFrequency(frequency: number): string {
  switch (frequency) {
    case Freq.ONETIME:
      return 'One Time';
    case Freq.WEEKLY:
      return 'Weekly';
    case Freq.EVERYTWOWEEKS:
      return 'Every Two Weeks';
    case Freq.MONTHLY:
      return 'Monthly';
    case Freq.QUARTERLY:
      return 'Quarterly';
    case Freq.SEMIANNUALLY:
      return 'Semiannually';
    case Freq.ANNUALLY:
      return 'Annually';
    default:
      return '';
  }
}

/**
 * Returns the Send Until desciption passing as argument the Send until option
 * @param Send Until option Identifier for the Send Until to be mapped
 * @returns A string which represents the label of the Send until option
 */
export function mapSendUntil(option: number): string {
  switch (option) {
    case SendUntilOption.IChooseToStop:
      return 'I choose to stop';
    case SendUntilOption.NumberOfTransfers:
      return 'Number of transfers';
    case SendUntilOption.LastTransferDate:
      return 'Last Transfer Date';
    default:
      return '';
  }
}

/**
 * Returns the Amount desciption passing as argument the amount code
 * @param amount Identifier for the amount to be mapped
 * @returns A string which represents the label of the amount
 */
export function mapAmountOption(amount: number): string {
  switch (amount) {
    case AmountOption.MINIMUM_PAYMENT:
      return 'Minimum Payment';
    case AmountOption.OUTSTANDING_BALANCE:
      return 'Outstanding Balance';
    case AmountOption.OTHER:
      return 'Other';
    default:
      return '';
  }
}

export enum Freq {
  NOTSET = 0,
  ONETIME = 1,
  WEEKLY = 2,
  EVERYTWOWEEKS = 3,
  MONTHLY = 4,
  QUARTERLY = 5,
  SEMIANNUALLY = 6,
  ANNUALLY = 7,
}

export enum SendUntilOption {
  IChooseToStop = 0,
  LastTransferDate = 1,
  NumberOfTransfers = 2,
}

export enum AmountOption {
  NOTSET = 0,
  MINIMUM_PAYMENT = 1,
  OUTSTANDING_BALANCE = 2,
  OTHER = 3,
}
