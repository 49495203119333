import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  resetTimePeriodFilter as resetTimePeriodFilterNetWorth,
  setTimePeriodFilter as setTimePeriodFilterNetWorth,
} from '@app/pfm/store/net-worth/net-worth.actions';
import { resetTimePeriodFilter, setTimePeriodFilter } from '@app/pfm/store/spending/spending.actions';

import { ParentPage } from '../../enums/parent-page.enum';
import { TimePeriod } from '../../enums/time-period.enum';

@Component({
  selector: 'app-time-period-filter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './time-period-filter.component.html',
  styleUrls: ['./time-period-filter.component.scss'],
})
export class TimePeriodFilterComponent implements OnInit {
  @Input() filter$: Observable<TimePeriod>;
  @Input() parentPage: ParentPage;

  timePeriods = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    switch (this.parentPage) {
      case ParentPage.Spending:
        this.timePeriods = [
          TimePeriod.ThisMonth,
          TimePeriod.LastMonth,
          TimePeriod.ThisYear,
          TimePeriod.LastYear,
          TimePeriod.Last3Months,
          TimePeriod.Last12Months,
        ];
        break;
      case ParentPage.NetWorth:
        this.timePeriods = [
          TimePeriod.LastMonth,
          TimePeriod.Last3Months,
          TimePeriod.Last6Months,
          TimePeriod.Last12Months,
          TimePeriod.AllTime,
        ];
        break;
    }
  }

  selectTimePeriod(timePeriod: TimePeriod) {
    if (this.parentPage === ParentPage.Spending) {
      this.store.dispatch(setTimePeriodFilter({ payload: timePeriod }));
    }

    if (this.parentPage === ParentPage.NetWorth) {
      this.store.dispatch(setTimePeriodFilterNetWorth({ payload: timePeriod }));
    }
  }

  reset() {
    if (this.parentPage === ParentPage.Spending) {
      this.store.dispatch(resetTimePeriodFilter());
    }

    if (this.parentPage === ParentPage.NetWorth) {
      this.store.dispatch(resetTimePeriodFilterNetWorth());
    }
  }
}
