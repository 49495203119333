<h3 class="mt-5 mb-4">How do I access my tax forms?</h3>

<ol>
  <li>
    Click on the
    <b>Accounts tab</b>
    and select the account you want to see.
  </li>
  <li>
    Select the
    <b>Tax Forms tab.</b>
    On this page, you will be able to download your tax forms.
  </li>
</ol>
