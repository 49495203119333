<div class="header-section-title text-center">
  <div class="mt-4">
    <p class="mb-4 header__title">{{ headerTitle }}</p>
    <p class="header__subtitle" *ngIf="showSubtitle">{{ subtitle }}</p>
  </div>
</div>
<div class="row center">
  <div class="col-12 text-center mb-3">
    <div class="funding-back left">
      <a class="funding-back__link" (click)="back.emit()" *ngIf="showBack">
        <app-svg-icon
          [iconName]="backIcon"
          [styles]="{ width: '1rem', height: '1rem', fill: '#0070d9' }"
        ></app-svg-icon>
        <span class="funding-back__label">BACK</span>
      </a>
    </div>
    <div class="center">
      <h4>{{ title }}</h4>
    </div>
  </div>
</div>
