import { ComponentPortal } from '@angular/cdk/portal';

import { AlertsIcons, SvgIconNames } from '@shared/enums';

export type DialogDataArgs = Partial<DialogData> & Pick<DialogData, 'title'>;

/**
 * Data passed to Dialog (Modal) component.
 */
export class DialogData {
  /**
   * Optional param used as Modal Title.
   */
  // Disabling whitespace as the linter will complain, but the formatter will always remove the whitespace.
  // tslint:disable-next-line: whitespace
  title? = '';
  /**
   * Optional param used as Modal Title below the icon.
   */
  headerTitle?: string;
  /**
   * Optional: content used as innerHtml
   */
  content: string = null;
  /**
   * content for Cancel button as well used as returned value when user clicks on it; Defaults to 'Cancel'.
   */
  cancelText = 'Cancel';

  /**
   * If this property is manually set to false then the dialog header wont display
   * the SVG Icon
   */
  // Disabling whitespace as the linter will complain, but the formatter will always remove the whitespace.
  // tslint:disable-next-line: whitespace
  hasIcon? = true;

  /**
   * Optional: Specifies if the modal should include a close icon.
   * When clicking it the modal result will return "null".
   */
  // tslint:disable-next-line: whitespace
  hasCloseButton? = false;

  /**
   * Optional: Specifies if the modal should include a ok button.
   */
  // tslint:disable-next-line: whitespace
  hasOkButton? = true;

  /**
   * SVG Icon to use within Dialog (Modal) title; Defaults to 'Cancel'.
   */
  icon: SvgIconNames = AlertsIcons.ExclamationCircle;
  /**
   * content for Ok button as well used as returned value when user clicks on it; Defaults to 'Ok'.
   */
  okText = 'Ok';
  /**
   * Optional: Custom component to be displayed within the modal after the title and before the buttons
   */
  component?: ComponentPortal<any> = null;
  /**
   * Optional: Custom params to be passed to the component
   */
  params?: any = null;

  constructor(args: DialogDataArgs) {
    Object.assign(this, args);
  }
}
