<div class="legacy-p2p-info-modal">
  <div class="modal-header"></div>
  <div class="p2p-info modal-body">
    <i class="bofi-close right" aria-label="Close" (click)="dismissModal()"></i>
    <h3 class="more-info-title">Sending money should be as simple as...</h3>
    <div class="frow center more-info-wrapper">
      <div class="fcol-md-4 step" data-step="1">
        <div class="info-text-container">
          <p class="text-center">Notify the recipient through their email or mobile phone.</p>
        </div>
        <img class="hide-cs" src="{{ sendMoneyImg }}" type="image/svg+xml" align="center" />
      </div>
      <div class="fcol-md-4 step" data-step="2">
        <div class="info-text-container">
          <p class="text-center">The recipient is prompted to answer your security question.</p>
        </div>
        <img class="hide-cs" src="{{ messageReceivedImg }}" type="image/svg+xml" align="center" />
      </div>
      <div class="fcol-md-4 step" data-step="3">
        <div class="info-text-container">
          <p class="text-center">
            Once the question is answered correctly, your money is deposited into their preferred account. That's it!
          </p>
        </div>
        <img class="hide-cs" src="{{ transferMoneyImg }}" type="image/svg+xml" align="center" />
      </div>
    </div>
  </div>
</div>
