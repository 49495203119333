import { AnyAction } from 'redux';
import { AggregatedAccount } from 'typings/app/account-aggregation';

import { ACCOUNTS } from '../../constants/accounts';

/**
 * Functions in charge of handling the transformations in the store
 * NOTE: This function never mutates the state directly, redux does
 */
export function AggregatedReducer(state: AggregatedAccount[] = null, action: AnyAction) {
  switch (action.type) {
    case ACCOUNTS.AGGREGATED.LOAD:
      return action.payload;
    case ACCOUNTS.AGGREGATED.ADD:
      return [
        ...state,
        ...action.payload.filter(
          (newAccount: AggregatedAccount) => !state.find(account => account.id === newAccount.id)
        ),
      ];
    case ACCOUNTS.AGGREGATED.UPDATE:
      return state.map(account => {
        const updatedAccount = action.payload.find(
          (newAccount: AggregatedAccount) => newAccount.id === account.id
        );

        return updatedAccount || account;
      });
    case ACCOUNTS.AGGREGATED.REMOVE:
      return state.filter(account => account.id !== action.payload);
    case ACCOUNTS.AGGREGATED.FLUSH:
      return [];
    case ACCOUNTS.AGGREGATED.NICKNAME_UPDATE:
      const accnt = state.find(account => account.id === action.payload.id);
      accnt.nickname = action.payload.nickname;

      return [...state.filter(account => account.id !== action.payload.id), accnt];
    case ACCOUNTS.AGGREGATED.UPDATE_ACCOUNT:
      return [...state.filter(account => account.id !== action.payload.id), action.payload];
    default:
      return state;
  }
}
