import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { COLORS_BONDS, COLORS_STOCKS, COLOR_CASH } from '@app/axos-invest/constants';
import { PortfolioType } from '@app/axos-invest/enums';
import { AssetClassesEntity, BondsOrCashOrStocks, Formula, Goal, RiskScorePortfolio } from '@app/axos-invest/models';
import { AxosInvestService, AxosInvestUrlsService } from '@app/axos-invest/services';
import { calculatePortfolioType, getRiskScoreDescription } from '@app/axos-invest/utils';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { FilesIcons, FinancialIcons, NavigationIcons } from '@shared/enums';
@Component({
  selector: 'app-goal-portfolio',
  templateUrl: './goal-portfolio.component.html',
  styleUrls: ['./goal-portfolio.component.scss'],
})
export class GoalPortfolioComponent implements OnInit, OnDestroy {
  @Input() goalData: Goal;
  @Output() showErrorBanner = new EventEmitter<boolean>();
  isLoading: boolean;
  isMilestoneDataLoading: boolean;
  isFormulaDataLoading: boolean;
  isRiskScoreDataLoading: boolean;
  isPortfolioDataLoading: boolean;
  chartData: any;

  icons = {
    edit: FilesIcons.Pencil,
    down: NavigationIcons.ChevronDown,
    go: NavigationIcons.ExternalLink,
    pieChart: FinancialIcons.PieChart,
    arrowForward: NavigationIcons.ArrowForward,
  };

  milestoneID = '';

  formulas: Formula;
  riskScores: RiskScorePortfolio;

  viewPortfolioFeatures = false;
  hasGlidePath: boolean;
  allocationStart: string;
  allocationEnd: string;
  stockRateStart = 0;
  stockRateEnd = 0;
  bondRateStart = 0;
  bondRateEnd = 0;

  portfolioRiskType: string;
  portfolioFormulas: string;

  stocksList: AssetClassesEntity[] = [];
  bondsList: AssetClassesEntity[] = [];
  cash: AssetClassesEntity;
  stocksDetails: BondsOrCashOrStocks;
  bondsDetails: BondsOrCashOrStocks;
  cashDetails: BondsOrCashOrStocks;

  labels: string[] = [];
  percentageVal: string[] = [];
  backgroundColor: string[] = [];
  stockColors: string[] = [];
  bondColors: string[] = [];
  sinkSubscription = new SubSink();
  viewPortfolioDisclaimer = false;
  portfolioDataError = false;
  riskScoreDataError = false;
  portfolioType: PortfolioType;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private axosInvestService: AxosInvestService,
    private axosInvestUrlHelper: AxosInvestUrlsService,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.milestoneID = this.params['id'];

    this.getMilestoneData();
    this.getFormulasData();
    this.getPortfolioData();
  }

  ngOnDestroy() {
    this.sinkSubscription.unsubscribe();
  }
  getPortfolioData() {
    this.isPortfolioDataLoading = true;
    this.sinkSubscription.sink = this.axosInvestService
      .getPortfolio(this.milestoneID)
      .pipe(
        finalize(() => {
          this.assignBackgroundColors();

          this.roundData();

          this.chartData = {
            labels: this.labels,
            datasets: [
              {
                data: this.percentageVal,
                backgroundColor: this.backgroundColor,
                fill: true,
              },
            ],
          };

          this.isPortfolioDataLoading = false;
        })
      )
      .subscribe(
        response => {
          this.stocksDetails = response.data.client.stocks;
          this.bondsDetails = response.data.client.bonds;
          this.cashDetails = response.data.client.cash;

          response.data.client.assetClasses.map(asset => {
            this.labels.push(asset.name);
            this.percentageVal.push(asset.target.toFixed(2));
            switch (asset.category) {
              case 'Stocks':
                this.stocksList.push(asset);
                break;
              case 'Bonds':
                this.bondsList.push(asset);
                break;
              case 'Cash':
                this.cash = asset;
                break;
            }
          });
        },
        () => {
          this.portfolioDataError = true;
          this.isPortfolioDataLoading = false;

          this.stocksList.length = 4;
          this.bondsList.length = 4;

          this.chartData = {
            isError: true,
          };

          this.showErrorBanner.emit(true);
        }
      );
  }

  getMilestoneData() {
    this.isMilestoneDataLoading = true;

    const riskScore = parseFloat(this.goalData.allocation.allocationModel);
    this.allocationStart = this.goalData.allocation.allocationStart;
    this.allocationEnd = this.goalData.allocation.allocationEnd;

    this.portfolioRiskType = getRiskScoreDescription(riskScore);
    this.portfolioType = calculatePortfolioType(this.goalData.allocation);
    this.hasGlidePath = this.portfolioType === PortfolioType.GlidepathEnabled;

    this.isMilestoneDataLoading = false;
    this.getRiskScore();
  }

  getFormulasData() {
    this.isFormulaDataLoading = true;
    this.sinkSubscription.sink = this.axosInvestService
      .getFormulas()
      .pipe(
        finalize(() => {
          this.isFormulaDataLoading = false;
        })
      )
      .subscribe(
        response => {
          this.formulas = response;

          const formulas = [];

          this.formulas.data.map(formula => {
            formula.milestones.map(id => {
              if (id === this.milestoneID) {
                formulas.push(formula.title);
              }
            });
          });

          formulas.length > 0 ? (this.portfolioFormulas = formulas.join(', ')) : (this.portfolioFormulas = 'None');
        },
        () => {
          this.portfolioFormulas = '---';
          this.isFormulaDataLoading = false;
        }
      );
  }

  getRiskScore() {
    this.isRiskScoreDataLoading = true;
    this.sinkSubscription.sink = this.axosInvestService
      .getRiskScore()
      .pipe(
        finalize(() => {
          this.isRiskScoreDataLoading = false;
        })
      )
      .subscribe(
        response => {
          this.riskScores = response.data.portfolios;

          let stockRate = {
            start: this.riskScores[this.allocationStart].targetStockRate,
            end: this.riskScores[this.allocationEnd].targetStockRate,
          };

          let bondRate = {
            start: this.riskScores[this.allocationStart].targetBondRate,
            end: this.riskScores[this.allocationEnd].targetBondRate,
          };

          stockRate = {
            start: (1 - this.goalData.allocation.cashStart) * stockRate.start,
            end: (1 - this.goalData.allocation.cashEnd) * stockRate.end,
          };

          bondRate = {
            start: (1 - this.goalData.allocation.cashStart) * bondRate.start,
            end: (1 - this.goalData.allocation.cashEnd) * bondRate.end,
          };

          this.stockRateStart = stockRate.start;
          this.stockRateEnd = stockRate.end;
          this.bondRateStart = bondRate.start;
          this.bondRateEnd = bondRate.end;
        },
        () => {
          this.riskScoreDataError = true;
          this.isRiskScoreDataLoading = false;
        }
      );
  }

  assignBackgroundColors() {
    if (this.stocksList.length > 1) {
      this.stockColors = COLORS_STOCKS.slice(0, this.stocksList.length);
      this.backgroundColor.push(...this.stockColors);
      this.stocksList.map((stock, index) => {
        stock.color = this.stockColors[index];
      });
    } else if (this.stocksList.length === 1) {
      this.backgroundColor.push(COLORS_STOCKS[0]);
      this.stocksList[0].color = COLORS_STOCKS[0];
    }

    if (this.bondsList.length > 1) {
      this.bondColors = COLORS_BONDS.slice(0, this.bondsList.length);
      this.backgroundColor.push(...this.bondColors);
      this.bondsList.map((bond, index) => {
        bond.color = this.bondColors[index];
      });
    } else if (this.bondsList.length === 1) {
      this.backgroundColor.push(COLORS_BONDS[0]);
      this.bondsList[0].color = COLORS_BONDS[0];
    }

    if (this.cashDetails.target > 0) {
      this.backgroundColor.push(COLOR_CASH[0]);
      this.cash.color = COLOR_CASH[0];
    }
  }

  getColor(color1: number[], color2: number[], factor: number) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    const color = `rgba(${result[0]}, ${result[1]}, ${result[2]}, ${result[3]})`;

    return color;
  }

  roundData() {
    const portfolioTargetDetails = this.roundValues(
      [
        { id: 0, val: this.stocksDetails.target },
        { id: 1, val: this.bondsDetails.target },
        { id: 2, val: this.cashDetails.target },
      ],
      100
    );

    this.stocksDetails.target = portfolioTargetDetails[0];
    this.bondsDetails.target = portfolioTargetDetails[1];
    this.cashDetails.target = portfolioTargetDetails[2];

    const stockTargetPercentage = this.stocksList.map((stock, index) => {
      return { id: index, val: stock.target };
    });

    const roundedStockTargetPercentage = this.roundValues(stockTargetPercentage, this.stocksDetails.target);

    const stockCurrentPercentage = this.stocksList.map((stock, index) => {
      return { id: index, val: stock.percent };
    });

    const roundedStockCurrentPercentage = this.roundValues(stockCurrentPercentage, this.stocksDetails.percent);

    this.stocksList = this.stocksList.map((stock: any, index) => {
      stock.target = roundedStockTargetPercentage[index];
      stock.percent = roundedStockCurrentPercentage[index];

      return stock;
    });

    const bondPercentage = this.bondsList.map((bond, index) => {
      return { id: index, val: bond.target };
    });

    const roundedBondPercentage = this.roundValues(bondPercentage, this.bondsDetails.target);

    const bondCurrentPercentage = this.bondsList.map((bond, index) => {
      return { id: index, val: bond.percent };
    });

    const roundedBondCurrentPercentage = this.roundValues(bondCurrentPercentage, this.bondsDetails.percent);

    this.bondsList = this.bondsList.map((bond: any, index) => {
      bond.target = roundedBondPercentage[index];
      bond.percent = roundedBondCurrentPercentage[index];

      return bond;
    });

    if (this.cashDetails.target > 0) {
      this.cash.target = this.cashDetails.target;
    }
  }

  roundValues(numbers: { id: number; val: number }[], roundValue: number) {
    const roundedNumbers = numbers.map(num => {
      return Math.floor(num.val);
    });

    const totalRoundedNumbers = roundedNumbers.reduce((a, b) => a + b, 0);

    let diff = roundValue - totalRoundedNumbers;

    const sortedNumbers = numbers.sort((a, b) => b.val - Math.floor(b.val) - (a.val - Math.floor(a.val)));

    let finalRoundedNumbers = sortedNumbers.map(num => {
      if (diff > 0) {
        num.val = Math.floor(num.val) + 1;
        diff -= 1;

        return num;
      } else {
        num.val = Math.floor(num.val);

        return num;
      }
    });

    finalRoundedNumbers = finalRoundedNumbers.sort((a, b) => a.id - b.id);

    const roundedValues = finalRoundedNumbers.map(num => num.val);

    return roundedValues;
  }

  toggleProfileFeatures() {
    this.viewPortfolioFeatures = !this.viewPortfolioFeatures;

    return this.viewPortfolioFeatures;
  }

  redirectToFormula() {
    this.axosInvestUrlHelper.getFormulasUrl().subscribe(url => window.open(url, '_blank', 'noopener noreferrer'));
  }

  redirectToEditProfile(isSetUpEndTarget?: boolean) {
    if (this.featureFlagService.isEditPortfolioFlagActive()) {
      if ([PortfolioType.Basic, PortfolioType.GlidepathEnabled].includes(this.portfolioType)) {
        this.state.go('udb.axosinvest.editportfolio', {
          id: this.goalData.id,
          isSetUpEndTarget,
        });
      } else {
        this.axosInvestUrlHelper
          .getEditProfileUrl(this.goalData.id)
          .subscribe(url => window.open(url, '_blank', 'noopener noreferrer'));
      }
    } else {
      this.axosInvestUrlHelper
        .getEditProfileUrl(this.goalData.id)
        .subscribe(url => window.open(url, '_blank', 'noopener noreferrer'));
    }
  }
  togglePortfolioDisclaimer() {
    this.viewPortfolioDisclaimer = !this.viewPortfolioDisclaimer;

    return this.viewPortfolioDisclaimer;
  }
}
