import { Inject } from '../../decorators/decorators';

@Inject('$stateProvider')
export class StopPaymentConfig {
  constructor($stateProvider: ng.ui.IStateProvider) {
    $stateProvider
      .state('udb.accounts.stop-payment', {
        url: '/Account/:id/StopPayment',
        views: {
          'acc-content': {
            template: '<app-stop-payment></app-stop-payment>',
          },
        },
      })
      .state('udb.accounts.stop-payment-remove', {
        url: '/Account/:id/StopPayment/Remove/:seq',
        views: {
          'acc-content': {
            template: '<app-stop-payment-remove></app-stop-payment-remove>',
          },
        },
        params: {
          spCancel: null,
        },
      })
      .state('udb.accounts.stop-payment-add', {
        url: '/Account/:id/AddStopPayment',
        views: {
          'acc-content': {
            template: '<app-stop-payment-add></app-stop-payment-add>',
          },
        },
      })
      .state('udb.accounts.stop-payment-extend', {
        url: '/Account/:id/StopPayment/Extend/:seq',
        views: {
          'acc-content': {
            template: '<app-stop-payment-extend></app-stop-payment-extend>',
          },
        },
        params: {
          spExtend: null,
        },
      })

      .state('udb.accounts.stop-payment-success', {
        url: '/Account/:id/StopPayment/Success/:seq',
        views: {
          'acc-content': {
            template: '<app-stop-payment-success></app-stop-payment-success>',
          },
        },
        params: {
          stopPayment: null,
          actionMsg: '',
        },
      });
  }
}
