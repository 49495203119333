export class UtagService {
  script_src = '';

  constructor() {
    // Object to override the utag.js settings set in the utag.cfg object.
    //      noview: Suppresses the automatic page tracking event called by utag.js.
    //              Commonly used on single page application sites.
    (window as any).utag_cfg_ovrd = { noview: true };
    (window as any).utag_data = {
      event_action: '',
      business_unit: '',
      gAds_conversionLabel: '',
      page_type: '',
      event_name: '',
      tealium_event: '',
      product_category: '',
      product_name: '',
    };
  }

  // Generic script loader with callback
  generateScript(vendor: string, callback?: Function): void {
    const d = document;
    const o = { callback: callback || function () {} };
    let s, t;

    if (this.script_src === '') {
      console.log(vendor + ' config not set.');

      return;
    }

    // Prevent loading javascript more than one time
    var universalTagScript = d.getElementById(vendor);
    if(universalTagScript !== null) return;

    s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.charset = 'utf-8';
    s.src = this.script_src;
    s.id = vendor;

    if (typeof o.callback === 'function') {
      if (d.addEventListener) {
        s.addEventListener(
          'load',
          () => {
            o.callback();
          },
          false
        );
      } else {
        // Old IE support.
        d.onreadystatechange = function() {
          if (this.readyState === 'complete') {
            this.onreadystatechange = null;
            o.callback();
          }
        };
      }
    }
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // This method tracks page views, virtual page views, and other AJAX page flows.
  // Calling this method will trigger the corresponding page tracking functionality within your configured vendor tags.
  view(data?: any): void {
    this.track('view', 'Tealium', data);
  }

  // This method tracks non-page views, page interactions, and other dynamic events that might occur on a page.
  // Calling this method will trigger the corresponding event tracking functionality within your configured vendor tags.
  link(data?: any): void {
    this.track('link', 'Tealium', data);
  }

  // Data layer is optional set of key/value pairs
  private track(tealiumEvent: string, vendor: string = 'Tealium', data?: any): void {
    if ((window as any).utag === undefined) {
      this.generateScript(vendor, () => {
        (window as any).utag.track(tealiumEvent, data);
      });
    } else {
      (window as any).utag.track(tealiumEvent, data);
    }
  }
}
