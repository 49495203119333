import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { STATE } from '@core/tokens';

@Component({
  selector: 'app-no-longer-eligible',
  templateUrl: './no-longer-eligible.component.html',
  styleUrls: ['./no-longer-eligible.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoLongerEligibleComponent implements OnInit, OnDestroy {
  constructor(@Inject(STATE) private state: ng.ui.IStateService, private bodyRef: ElementRef) {}

  ngOnInit(): void {
    this.bodyRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
  }

  ngOnDestroy(): void {
    this.bodyRef.nativeElement.ownerDocument.body.style.overflow = 'scroll';
  }
  goDashboard() {
    this.state.go('udb.dashboard');
  }
}
