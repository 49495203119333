import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AasStoreFeatures } from '@app/Areas/AAS/aas-core';
import { AccountDetailsStateType } from '../types';
import { getSelectedAasAccount } from '@app/Areas/AAS/features/product-details-page/core';

export const getAccountDetailsState = createFeatureSelector<AccountDetailsStateType>(AasStoreFeatures.AccountDetails);

export const getSelectedAccountInformation = createSelector(getAccountDetailsState, state => {
  if (state.accountInformationList === undefined) {
    return undefined;
  }

  return state.accountInformationList.find(item => item.accountNumber === state.selectedAccountDetails) ?? undefined;
});

export const getSelectedAccountFirmContacts = createSelector(getAccountDetailsState, state => {
  if (state.accountFirmContactsList === undefined) {
    return undefined;
  }

  const selectedAccountFirmContact = state.accountFirmContactsList.find(
    accountFirmContact => accountFirmContact.accountNumber === state.selectedAccountDetails
  );
  if (selectedAccountFirmContact === undefined) return undefined;

  return {
    ...selectedAccountFirmContact,
    advisors: selectedAccountFirmContact.advisors?.sort((a, b) => a.name.localeCompare(b.name)),
  };
});


export const getSelectedAccountTypeAndAccountNumber = createSelector(getSelectedAccountInformation, getSelectedAasAccount, (accountInformation, aasAccount) => {
  return {
    accountNumber: accountInformation?.accountNumber,
    accountType: aasAccount?.type,
  };
});