import { Component, Input, OnInit } from '@angular/core';

import { SubSink } from '@axos/subsink';

import { EquityType, TransactionType } from '@app/axos-trading/enums';
import { OrderStatus } from '@app/axos-trading/enums/order-status';
import { Order, OrderConfirmation, OrderFilter, TradingAccount } from '@app/axos-trading/models';
import { ClearingService, TradingRoutingHelperService } from '@app/axos-trading/services';

@Component({
  selector: 'app-trade-stock-confirmation',
  templateUrl: './trade-stock-confirmation.component.html',
  styleUrls: ['./trade-stock-confirmation.component.scss'],
})
export class TradeStockConfirmationComponent implements OnInit {
  @Input() order: OrderConfirmation;
  @Input() account: TradingAccount;

  tradeTypes = TransactionType;
  stockType = EquityType;
  orderInfo: Order;
  isPending: boolean;

  icon = 'assets/img/icons/axos-invest/transfer-completed.svg';
  private subSink = new SubSink();

  constructor(private routeHelper: TradingRoutingHelperService, private clearingService: ClearingService) {}

  ngOnInit(): void {
    this.getOrderInfo();
  }

  goToOverview() {
    this.routeHelper.goToSDTOverview(this.account.id);
  }

  goToActivity() {
    this.routeHelper.goToTransactions(this.account.id);
  }

  private getOrderInfo() {
    const filters: OrderFilter = new OrderFilter();
    filters.symbol = this.order.symbol;
    this.subSink.sink = this.clearingService.getOrders(this.order.accountNumber, filters).subscribe(result => {
      this.orderInfo = result.filter(e => e.reference === this.order.orderReference)[0];

      this.isPending = [
        OrderStatus.WAITING_FOR_EXECUTION,
        OrderStatus.AWAITING_CANCELLATION,
        OrderStatus.WAITING_TO_BE_SENT,
      ].includes(this.orderInfo?.status?.code as OrderStatus);
    });
  }
}
