import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { getProfileDetails } from '@app/user-profile/store/selectors';
import { MessageService } from '@core/services';
import { STATE } from '@core/tokens';
import { PopupsHelper } from '@legacy/services/popups.helper';
import { ServiceHelper } from '@legacy/services/service.helper';
import { SupportFile } from '@shared/models';
@Component({
  selector: 'app-sm-compose',
  templateUrl: './sm-compose.component.html',
  styleUrls: ['./sm-compose.component.scss'],
})
export class SmComposeComponent implements OnInit, OnDestroy {
  @Input()
  public category: string;
  @Input()
  public subCategory: string;
  @Input()
  public businessId: string;
  @Output()
  public send = new EventEmitter();

  public errorMessage: string;
  public messageModel: string = '';
  public isSending: boolean;
  public firstName: string = '';
  public totalMaxSize: number = 10485760; // 10mb
  public filesModel: SupportFile[] = [];
  public popups: PopupsHelper;
  private subsink = new SubSink();

  public get greeting(): string {
    return `Hi, ${this.firstName}`;
  }
  public get messageLength(): string {
    return `${this.messageModel.length}/1000`;
  }

  constructor(
    @Inject(STATE) private $state: ng.ui.IStateService,
    private serviceHelper: ServiceHelper,
    private messageService: MessageService,
    private store: Store
  ) {}
  public ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
  public ngOnInit(): void {
    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.firstName = data.firstName;
      });
  }

  public redirectToInbox(): void {
    this.$state.go('udb.messages.inbox');
  }

  public sendMessage(formIsValid: boolean): void {
    if (formIsValid) {
      this.isSending = true;
      this.messageService.sendMessage(this.getFormData()).subscribe(
        () => {
          this.send.emit();
        },
        exception => {
          if (exception?.error.statusCode === 400) {
            this.errorMessage = exception.error.message;
          } else {
            this.serviceHelper.errorHandler(exception);
          }
        }
      );
    }
  }

  private getFormData(): FormData {
    const formData = new FormData();
    formData.append('bodyMessage', this.messageModel);
    formData.append('category', this.category);
    formData.append('subCategory', this.subCategory);
    formData.append('businessId', this.businessId);

    for (const [i, fileModel] of this.filesModel.entries()) {
      formData.append(`file[${i}]`, fileModel.document);
      formData.append(`fileFormDataName[${i}]`, fileModel.name);
    }

    return formData;
  }
}
