import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'tx-options',
})
export class TxOptionsDirective extends UpgradeComponent {
  @Input() options: any[];
  @Input() isDisabled: boolean;
  @Input() isExpanded: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onOptionSelected: EventEmitter<any>;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCollapse: EventEmitter<void>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('txOptions', elementRef, injector);
  }
}
