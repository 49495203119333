import { Action, createReducer, on } from '@ngrx/store';

import { ExternalAccount } from '@app/accounts/models';
import { signOut } from '@app/store/actions';

import { loadExternalAccounts } from './external-accounts.actions';

export const externalAccountsInitialState: Record<number, ExternalAccount> = {};

const _reducer = createReducer(
  externalAccountsInitialState,
  on(loadExternalAccounts, (state, action) => {
    const transferOnlyAccounts = action.payload.reduce((acc, item) => {
      return { ...acc, [item.externalAccountId]: { ...state[item.externalAccountId], ...item } };
    }, {}) as Record<number, ExternalAccount>;

    return { ...state, ...transferOnlyAccounts };
  }),
  on(signOut, () => {
    return {};
  })
);

export function externalAccountsReducer(state: Record<number, ExternalAccount>, action: Action) {
  return _reducer(state, action);
}
