<section id="account-sharing-section">
  <div class="feature-container">
    <div *ngFor="let aasAccount of axosAdvisoryAccounts">
      <div class="row authorize-name">
        <div class="col-12 account-text">
          <diV class="checkbox-aas-account aas-accounts-line">
            <div class="uk2-checkbox-container">
              <img [src]="getAasImage(aasAccount)" class="image-aas-account" aria-hidden="true" />
              <span class="aasaccount-displayname">
                {{ aasAccount.displayName }}
              </span>
            </div>
          </diV>
        </div>
      </div>
      <div class="row authorize-transfers" id="authorize">
        <diV class="col-3 container-text-middle">
          <div class="uk2-checkbox-container">
            <span class="aas-container-text">
              Authorize transfers
            </span>
          </div>
        </diV>
        <div class="col-9 checkbox-responsive">
          <span class="feature-switch">
            <label class="uk-switch custom-switch">
              <input
                type="checkbox"
                [(ngModel)]="aasAccount.isAuthorizeTransfers"
                (change)="openModalFromComponent($event, aasAccount)"
              />
              <span class="slider">
                <i class="slider--icon"></i>
              </span>
            </label>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
