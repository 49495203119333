<div class="mat-dialog-content">
  <div class="account-profile-container">
     <!-- title -->
    <div class="account-title-container">
      <h1 class="account-title">Select Profile</h1>
      <a class="account-close__icon" mat-dialog-close="cancel">
        <app-svg-icon [iconName]="icons.close" size="1.3rem"></app-svg-icon>
      </a>
    </div>
    <!-- profile list -->
    <ul class="account-profile-list">
      <li
        class="account-profile-item"
        *ngFor="let accountProfile of accountProfiles; let i = index"
        (click)="selectAccountProfile(accountProfile, i)"
      >
        <div class="profile-circle__icon">
          <span [ngStyle]="{ 'background-color': getProfileCircleColor(i) }" width="48" height="48"></span>
        </div>
        <div class="profile-text">
          <div class="profile-text__head">{{ accountProfile.name }}</div>
          <div class="profile-text__subHead">{{ getProfileType(accountProfile) + ' Banking' }}</div>
        </div>
        <div class="profile-check__icon" *ngIf="showIconCheck === i">
          <app-svg-icon
              [iconName]="icons.circle"
              [styles]="{ strokeWidth: 0, fill: 'green' }"
              size="0.75rem"
              height="21px"
              width="21px"
            ></app-svg-icon>
        </div>
      </li>
    </ul>    
  </div>
  <!-- Error Message -->
  <span class="text-danger" *ngIf="errorMessage">{{errorMessage}}</span>
</div>
