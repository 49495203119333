import * as Joi from 'joi';
const answerRegex = new RegExp("^[a-zA-Z0-9\u00F1\u00D1 '-]+$");

function commonValidationMessages(questionNumber: number) {
  return {
    'string.empty': `Answer for security question ${questionNumber} is required.`,
    'string.pattern.base': `The answer to the security question ${questionNumber} is not valid, please enter a valid answer.`,
    'any.invalid': 'Cannot have the same answer for multiple security questions.',
  };
}

export const securityQuestionsFormSchema = Joi.object({
  question1Id: Joi.string().required(),
  answer1: Joi.string().required().regex(answerRegex).messages(commonValidationMessages(1)),

  question2Id: Joi.string().required(),
  answer2: Joi.string()
    .required()
    .invalid(Joi.ref('answer1'))
    .regex(answerRegex)
    .messages(commonValidationMessages(2)),

  question3Id: Joi.string().required(),
  answer3: Joi.string()
    .required()
    .invalid(Joi.ref('answer1'), Joi.ref('answer2'))
    .regex(answerRegex)
    .messages(commonValidationMessages(3)),
});
