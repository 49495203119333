import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AasDataTileListRowTypeEnum } from '../enums';
import { FirmContactAddressStateType } from '@app/Areas/AAS/features/account-details/core';

@Component({
  selector: 'aas-data-tile-list-row',
  templateUrl: './data-tile-list-row.component.html',
  styleUrls: ['./data-tile-list-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTileListRowComponent implements OnInit {
  @Input() type: AasDataTileListRowTypeEnum = AasDataTileListRowTypeEnum.Text;
  @Input() title: string = '';
  @Input() value: string | FirmContactAddressStateType = '';

  aasDataTileListRowTypeEnum = AasDataTileListRowTypeEnum;
  addressList: string[];

  constructor() {}
  ngOnInit(): void {
    if (this.type == AasDataTileListRowTypeEnum.Address)
      this.addressList = this.formatAddress(this.value as FirmContactAddressStateType);
  }
  formatAddress(addressObject: FirmContactAddressStateType): string[] {
    let line1 = [];
    let line2 = '';
    let addressHasCity = addressObject.city && addressObject.city.length > 0;
    let addressHasState = addressObject.state && addressObject.state.length > 0;
    let addressHasZipCode = addressObject.zipCode && addressObject.zipCode.length > 0;

    for (const address in addressObject) {
      if (address.includes('address') && addressObject[address]) line1.push(addressObject[address]);
    }

    if (addressHasCity) {
      line2 += addressObject.city;
    }
    if (addressHasState) {
      line2 += (addressHasCity ? ', ' : '') + addressObject.state;
    }
    if (addressHasZipCode) {
      line2 +=
        (addressHasCity && !addressHasState ? ',' : '') +
        (addressHasCity || addressHasState ? ' ' : '') +
        addressObject.zipCode;
    }

    return [...line1, line2];
  }
}
