import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import NgRedux from 'ng-redux';

import { Switch } from '@app/click-switch/models';
import { getKeyValueIncomeDetails, getKeyValueSelectedAccounts } from '@app/click-switch/utils';
import { turnOffFunding } from '@app/store/funding/funding.actions';
import { UserProfileViews } from '@app/user-profile/enums';
import { KeyValuePair } from '@core/models';
import { ngRedux, STATE } from '@core/tokens';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';

@Component({
  selector: 'app-click-switch-confirmation',
  templateUrl: './click-switch-confirmation.component.html',
  styleUrls: ['./click-switch-confirmation.component.scss'],
})
export class ClickSwitchConfirmationComponent implements OnInit {
  userSwitch: Switch;
  sourceInformation: KeyValuePair<string, string>[] = [];
  depositInformation: KeyValuePair<string, string>[] = [];

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ngRedux) private ngredux: NgRedux.INgRedux,
    private store: Store
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    const { userSwitch } = this.state.params;
    this.userSwitch = userSwitch;

    if (!this.userSwitch || !this.userSwitch.index) {
      this.state.go('udb.clickswitch');
    }

    this.sourceInformation = this.getSubmitTarget(userSwitch);
    this.depositInformation = this.getAccounts(userSwitch);
  }

  goToDashboard(): void {
    const { isRunning } = this.ngredux.getState().funding;
    if (isRunning) {
      this.ngredux.dispatch(FundingActions.turnOffFunding());
      this.store.dispatch(turnOffFunding());
    }

    this.state.go('udb.dashboard');
  }

  goToStatusPage(): void {
    const { isRunning } = this.ngredux.getState().funding;
    if (isRunning) {
      this.ngredux.dispatch(FundingActions.turnOffFunding());
      this.store.dispatch(turnOffFunding());
    }

    this.state.go('udb.userProfile', { view: UserProfileViews.DirectDeposit });
  }

  private getSubmitTarget(userSwitch: Switch) {
    return getKeyValueIncomeDetails(userSwitch);
  }

  private getAccounts(userSwitch: Switch) {
    return getKeyValueSelectedAccounts(userSwitch);
  }
}
