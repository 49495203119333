<form class="change-email col-12" [formGroup]="changeEmailsForm" (ngSubmit)="updateEmailAddresses()">
  <div [ngSwitch]="profileType">
    <div *ngSwitchCase="profileTypes.Personal">
      <p class="mb-4 text-center font-weight-normal">
        Update your primary email where you receive bank notifications
        <br />
        or add an alternative email address.
      </p>
      <div class="form-group">
        <label for="primaryEmail" class="uk-label" [class.invalid]="primaryEmail.hasError('*', 'touched')">
          Primary Email:
        </label>
        <input
          type="email"
          formControlName="primaryEmail"
          id="primaryEmail"
          class="uk-input"
          [maxlength]="emailMaxLength"
          placeholder="Primary Email Address"
        />

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="primaryEmail" #primaryEmail="ngxErrors">
          <span ngxError="required" when="touched">Please provide a primary email address.</span>
          <span ngxError="pattern" when="touched">Please provide a valid email address.</span>
          <span ngxError="maxlength" when="touched">
            Primary Email Address must be {{ emailMaxLength }} characters or less.
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="alternateEmail" class="uk-label" [class.invalid]="alternateEmail.hasError('*', 'touched')">
          Alternate Email:
        </label>
        <input
          type="email"
          formControlName="alternateEmail"
          id="alternateEmail"
          class="uk-input"
          [maxlength]="emailMaxLength"
          placeholder="Alternate Email Address"
        />

        <div
          role="alert"
          aria-live="polite"
          class="mt-1 text-error"
          ngxErrors="alternateEmail"
          #alternateEmail="ngxErrors"
        >
          <span ngxError="pattern" when="touched">Please provide a valid email address.</span>
          <span ngxError="maxlength" when="touched">
            Alternate Email Address must be {{ emailMaxLength }} characters or less.
          </span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="profileTypes.Business">
      <div class="form-group">
        <label for="primaryEmail" class="uk-label" [class.invalid]="primaryEmail.hasError('*', 'touched')">
          Work Email:
        </label>
        <input
          type="email"
          formControlName="primaryEmail"
          id="primaryEmail"
          class="uk-input"
          [maxlength]="emailMaxLength"
          placeholder="Work Email Address"
        />

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="primaryEmail" #primaryEmail="ngxErrors">
          <span ngxError="required" when="touched">Please provide a work email address.</span>
          <span ngxError="pattern" when="touched">Please provide a valid email address.</span>
          <span ngxError="maxlength" when="touched">
            Email Address must be {{ emailMaxLength }} characters or less.
          </span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="profileTypes.Company">
      <p class="mb-4 text-center font-weight-normal">
        Update your business email where your company receive bank notifications
      </p>
      <div class="form-group">
        <label for="primaryEmail" class="uk-label" [class.invalid]="primaryEmail.hasError('*', 'touched')">
          Business Email:
        </label>
        <input
          type="email"
          formControlName="primaryEmail"
          id="primaryEmail"
          class="uk-input"
          [maxlength]="emailMaxLength"
          placeholder="Business Email Address"
        />

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="primaryEmail" #primaryEmail="ngxErrors">
          <span ngxError="required" when="touched">Please provide a primary business email address.</span>
          <span ngxError="pattern" when="touched">Please provide a valid email address.</span>
        </div>
      </div>
    </div>
  </div>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="changeEmailsForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
