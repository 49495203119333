import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-models-holdings-tab',
  templateUrl: './models-holdings-tab.component.html',
  styleUrls: ['./models-holdings-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModelsHoldingsTabComponent {
  @Input() heightScrollArea = 0;
  constructor() {}
}
