import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BrandingSettingsService,
  BrandingStateType,
  getSelectedBranding,
  initialBrandingStateType,
  loadBrandsAction,
  selectBrandingAction,
  getAllBrandings,
} from '..';
import { GetBrandingSettingsByOrganizationNameRequest } from '../core/services/branding-settings/types';

@Injectable({
  providedIn: 'root',
})
export class BrandingSettingsFacade {
  selectedBranding$ = this.store.select(getSelectedBranding);
  allBrandingSettings$ = this.store.select(getAllBrandings);

  constructor(private brandingService: BrandingSettingsService, private store: Store<BrandingStateType>) {}

  loadBrandsSettings(organizationsNames: Array<string>): void {
    const organizationsSettings: Array<GetBrandingSettingsByOrganizationNameRequest> = organizationsNames.map(
      organizationName => ({
        includeSettings: true,
        organizationName: organizationName,
      })
    );
    this.brandingService
      .getBrandsByOrganizationsNames({
        BrandingByOrganizationNames: organizationsSettings,
      })
      .subscribe(
        response => {
          this.store.dispatch(loadBrandsAction({ payload: response.data?.brandings }));
        },
        () => {
          this.store.dispatch(loadBrandsAction({ payload: [initialBrandingStateType] }));
        }
      );
  }

  loadBrandingSettings(brandName: string): void {
    this.store.dispatch(selectBrandingAction({ payload: brandName }));
  }
}
