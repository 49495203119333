<p class="mb-4 text-center font-weight-normal col-12">
  Verify your Employment Information. This information is helpful when qualifying for a loan or opening a new account.
</p>

<form class="change-employment-info col-12" [formGroup]="employmentInfoForm" (ngSubmit)="submitEmploymentInfo()">
  <ng-container *ngIf="!iraEnhBaseFlagActive; then basicForm; else fullForm"></ng-container>

  <ng-template #basicForm>
    <div class="basic-form">
      <div class="form-group" [class.invalid-field]="employer.hasError('*', 'touched')">
        <label for="occupation" class="uk-label">Most Recent Occupation *</label>
        <select
          name="occupation"
          id="occupation"
          class="uk-input"
          formControlName="occupation"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
          (change)="onChange($event)"
        >
          <option value="">Select Occupation</option>
          <option [value]="item" [innerText]="item" *ngFor="let item of ocupationsList"></option>
        </select>

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="occupation" #occupation="ngxErrors">
          <span ngxError="required" when="touched">Occupation is required.</span>
        </div>
      </div>

      <div class="form-group" [class.invalid-field]="employer.hasError('*', 'touched')">
        <label for="employer" class="uk-label">Employer Name</label>
        <input
          type="text"
          class="uk-input"
          id="employer"
          name="employer"
          formControlName="employer"
          placeholder="Employer Name"
          maxlength="30"
        />

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="employer" #employer="ngxErrors">
          <span ngxError="required" when="touched">Employer Name is required.</span>
        </div>
      </div>

      <div class="row justify-content-center">
        <p class="col-sm-8 col-12">
          * If your occupation is retired, self-employed or unemployed, please indicate in the employer box your
          occupation (if retired or unemployed) or your line of business if self-employed.
        </p>
      </div>
    </div>
  </ng-template>

  <ng-template #fullForm>
    <p class="mb-3 font-weight-bold">Employee Information</p>
    <div class="form-group" [class.invalid-field]="employmentStatus.hasError('*', 'touched')">
      <label for="employment-status" class="uk-label">Employment Status</label>
      <select name="employment-status" id="employment-status" class="uk-input" formControlName="employmentStatus">
        <option value="">Select Employment Status</option>
        <option [value]="item.key" [innerText]="item.value" *ngFor="let item of employmentStatuses"></option>
      </select>

      <div
        role="alert"
        aria-live="polite"
        class="mt-1 text-error"
        ngxErrors="employmentStatus"
        #employmentStatus="ngxErrors"
      >
        <span ngxError="required" when="touched">Employment Status is required.</span>
      </div>
    </div>

    <ng-container *ngIf="hasOccupation">
      <div class="form-group" [class.invalid-field]="occupation.hasError('*', 'touched')">
        <label for="occupation" class="uk-label" [innerText]="occupationLabel"></label>
        <select
          name="occupation"
          id="occupation"
          class="uk-input"
          formControlName="occupation"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
          (change)="onChange($event)"
        >
          <option value="">Select Occupation</option>
          <option [value]="item" [innerText]="item" *ngFor="let item of ocupationsList"></option>
        </select>
        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="occupation" #occupation="ngxErrors">
          <span ngxError="required" when="touched">Occupation is required.</span>
        </div>
      </div>

      <div class="form-group" [class.invalid-field]="yearsEmployed.hasError('*', 'touched')" *ngIf="employed">
        <label for="years-employed" class="uk-label">Years Employed</label>
        <input
          type="number"
          class="uk-input"
          id="years-employed"
          name="years-employed"
          formControlName="yearsEmployed"
          placeholder="Years Employed"
          min="0"
          max="99"
          (keydown)="restrictOnlyTwoCharacters($event)"
        />

        <div
          role="alert"
          aria-live="polite"
          class="mt-1 text-error"
          ngxErrors="yearsEmployed"
          #yearsEmployed="ngxErrors"
        >
          <span ngxError="required" when="touched">Years Employed is required.</span>
        </div>
      </div>
    </ng-container>

    <div class="mt-5 employer-information" *ngIf="employed">
      <p class="mb-3">Employer Information</p>
      <div class="form-group" [class.invalid-field]="employer.hasError('*', 'touched')">
        <label for="employer" class="uk-label">Employer Name</label>
        <input
          type="text"
          class="uk-input"
          id="employer"
          name="employer"
          formControlName="employer"
          placeholder="Employer Name"
        />

        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="employer" #employer="ngxErrors">
          <span ngxError="required" when="touched">Employer Name is required.</span>
        </div>
        <div role="alert" aria-live="polite" class="mt-1 text-error" ngxErrors="employer" #employer="ngxErrors">
          <span ngxError="maxlength" when="touched">Maximum of 30 characters.</span>
        </div>
      </div>
      <app-address
        [parentForm]="employmentInfoForm.get('employerAddress')"
        [addressType]="addressTypes.Employer"
        [formLabels]="employerAddressLabels"
      ></app-address>
    </div>
  </ng-template>

  <div class="form__actions mt-4">
    <ng-container *ngIf="!isBusy; else loader">
      <button type="submit" class="btn-ok button--lg" [disabled]="employmentInfoForm.pristine">
        Save
      </button>
      <button type="button" class="btn-cancel button--lg" (click)="back.emit()">Cancel</button>
    </ng-container>
    <ng-template #loader>
      <app-dot-loader aria-live="polite" role="alert">Saving</app-dot-loader>
    </ng-template>
  </div>
</form>
