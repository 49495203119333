import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  BILLPAYROUTERHELPER,
  LOAD_USER_PROFILE_HELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { Biller, PartialRecipient } from '@app/bill-pay/typings';
import { STATE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { IStateService } from 'angular-ui-router';

@Component({
  selector: 'app-electronic-confirmation',
  templateUrl: './electronic-confirmation.component.html',
  styleUrls: ['./electronic-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class ElectronicConfirmationComponent implements OnInit {
  biller: Biller = {} as Biller;
  recipient: PartialRecipient = {} as PartialRecipient;
  detailsTooltip: string = '';
  email: string = '';

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper,
    @Inject(LOAD_USER_PROFILE_HELPER) private readonly loadUserProfileHelper: ILoadUserProfileHelper
  ) {}
  ngOnInit(): void {
    {
      this.biller = this.billPayRouterHelper.getBiller();
      this.recipient = this.billPayRouterHelper.getRecipient();
      const profile = this.loadUserProfileHelper.getUserProfile();
      this.email = profile ? profile.primaryEmail : '';
      if (!this.biller) this.state.go('udb.billPay.searchRecipient');

      this.detailsTooltip = `
          <div style='text-align:center; font-weight: 400;'>
            <span style='font-weight:bold'>Electronic recipients</span>
            are paid instantly, We'll withdraw  the payment the day before your payment is due. Your recipient will receive your payment on its due date.
          </div>
          <br>
          <div style='text-align:center; font-weight: 400;'>
            <span style='font-weight:bold'>Check recipients</span>
            are paid with paper checks. We mail checks 5 business days before your payment's due date. Your money will leave your account when the recipient deposits the check.
          </div>
          `;
    }
  }

  goSchedulePayment() {
    this.state.go('udb.billPay.schedulePayment', { recipientId: this.recipient.payeeId });
  }
}
