import { createAction, props } from '@ngrx/store';

import { AasAccountState } from '.';

export const addOrUpdateAasAccountAction = createAction(
  '[PDP] AddOrUpdateAasAccount',
  props<{ payload: AasAccountState }>()
);

export const addTransactionSelectedTab = createAction('[PDP] AddPDPSelectedTab', props<{ payload: string }>());

export const updateAasAccountNicknameAction = createAction(
  '[PDP] UpdateAasAccountNickname',
  props<{ payload: { accountNumber: string; accountNickname: string } }>()
);