import { LoadUserProfileHelper } from '@legacy/services/load-user-profile-helper';

export function loadUserProfileHelperServiceFactory($injector: any) {
  return $injector.get('loadUserProfileHelper');
}
export const loadUserProfileHelperProvider = {
  provide: LoadUserProfileHelper,
  useFactory: loadUserProfileHelperServiceFactory,
  deps: ['$injector'],
};
