import { createAction, props } from '@ngrx/store';

import { TimePeriod } from '@app/pfm/enums/time-period.enum';
import { NetWorthPoint } from '@app/pfm/models/net-worth-point.model';

export const setNetWorthSeries = createAction(
  '[Insights.NetWorth Set net worth series]',
  props<{ payload: NetWorthPoint[] }>()
);
export const setNetWorthSeriesLoading = createAction(
  '[Insights.NetWorth Set net worth series loading]',
  props<{ payload: boolean }>()
);

// ---------- Filters ----------
export const commitFilters = createAction('[Insights.NetWorth] Commit Filters');
export const resetUncommittedFilters = createAction('[Insights.NetWorth] Reset uncommitted filters');

// Time series
export const setTimePeriodFilter = createAction(
  '[Insights.NetWorth] Change time period filter',
  props<{ payload: TimePeriod }>()
);

export const resetTimePeriodFilter = createAction('[Insights.NetWorth] Reset time period filter');

// Accounts
export const setExcludedAccountsFilter = createAction(
  '[Insights.NetWorth] Change excluded accounts filter',
  props<{ payload: Set<string> }>()
);
export const resetExcludedAccountsFilter = createAction('[Insights.NetWorth] Reset excluded accounts filter');
