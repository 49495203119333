import { UserAction } from 'typings/app/UserAction';

import { ApiFileExtensions } from '@app/transaction-history/enums/apiFileExtensions.enum';
import { TradingTransferResponse } from '@app/transfers/models';
import { MilestoneTransaction } from '@legacy/typings/app/wise-banyan/MilestoneTransaction';
import { Transfer } from '@legacy/typings/app/wise-banyan/Transfer';
import { ContributionYear } from '@shared/models';

import { Inject } from '../decorators/decorators';
import { ExternalAccountScheduleRequest } from '../transfers/typings/ExternalAccountScheduleRequest';
import { TransferSchedule } from '../transfers/typings/TransferSchedule';
import { WireRequest } from '../transfers/typings/WireTransfer';
import { P2PPayment } from '../typings/app/bills/P2PPayment';
import { P2PRecipient } from '../typings/app/bills/P2PRecipient';
import { P2PTransferHistory } from '../typings/app/bills/P2PTransferHistory';
import { BaseService } from './base.service';
import { ITransactionService } from './typings/ITransactionService';
@Inject('$http', 'env', 'serviceHelper', '$q', '$cacheFactory')
export class TransactionService extends BaseService implements ITransactionService {
  private readonly transactionsCache: ng.ICacheObject;
  private readonly transCacheName: string = 'transactionsCache';
  constructor(
    http: ng.IHttpService,
    env: any,
    serviceHelper: IServiceHelper,
    q: ng.IQService,
    private readonly cacheFactory: ng.ICacheFactoryService
  ) {
    super(http, env, 'transfers', serviceHelper, q);
    // Create CacheObject for transactions if no exist
    let cache = this.cacheFactory.get(this.transCacheName);
    if (!cache) {
      cache = this.cacheFactory(this.transCacheName);
    }
    this.transactionsCache = cache;
  }

  getTransactionsByAccount(accountId: number, startDate: Date, endDate: Date): ApiResponse<Transaction[]> {
    return this.get(`${accountId}`, { startDate, endDate });
  }

  getRecentTransactions(accounts: number[], numberOfDays: number = 30, limitTo?: number): ApiResponse<Transaction[]> {
    // Set end date hour '23:59:59.999' to make the query string consistent in every call, for cache purposes
    const endDate = new Date();
    endDate.setUTCHours(23, 59, 59, 999);

    // Set start date hour '0:0:0' to make the query string consistent in every call, for cache purposes
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - numberOfDays);
    startDate.setUTCHours(0, 0, 0, 0);

    return this.get('transactions/tile', { accounts, startDate, endDate, limitTo }, this.transactionsCache);
  }

  getLoanIncomingTransactions(accounts: number[], limitTo?: number): ApiResponse<IncomingTransaction[]> {
    return this.get('incoming/loan', { accounts, limitTo }, this.transactionsCache);
  }

  getSummary(year: number): ApiResponse<TransactionsSummary> {
    return this.get('summary', { year }, true);
  }

  transfer(
    transaction: TransferSchedule,
    validateDuplicate: boolean = false,
    acceptWarning: boolean = false
  ): ApiResponse<string> {
    return this.post(`transfer?validateDuplicate=${validateDuplicate}&acceptWarning=${acceptWarning}`, transaction);
  }

  transferTrading(transaction: TransferSchedule, isFunding?: boolean): ApiResponse<TradingTransferResponse> {
    return this.post(`trading?confirmation=true&isFunding=${isFunding}`, transaction);
  }

  transferInvest(transaction: Transfer, isExternal?: boolean): ApiResponse<any> {
    return this.post(`invest?isExternal=${isExternal}`, transaction);
  }

  transferInvestMilestoneToMilestone(milestoneTransaction: MilestoneTransaction): ApiResponse<any> {
    return this.post(`invest/milestone`, milestoneTransaction);
  }

  editSingleTransfer(transaction: TransferSchedule): ApiResponse<any> {
    return this.put('edit', transaction);
  }

  editSeriesTransfer(transaction: TransferSchedule): ApiResponse<any> {
    return this.put('edit/series', transaction);
  }

  getScheduledTransfer(id: number): ApiResponse<TransferSchedule> {
    return this.get(`scheduled/${id}`);
  }

  scheduleTransfer(
    transaction: TransferSchedule,
    validateDuplicate: boolean = false,
    acceptWarning: boolean = false
  ): ApiResponse<string> {
    return this.post(`schedule?validateDuplicate=${validateDuplicate}&acceptWarning=${acceptWarning}`, transaction);
  }

  scheduleACHTransfer(transaction: TransferSchedule): ApiResponse<string> {
    return this.post('ach/schedule', transaction);
  }

  getScheduledTransfers(includeInvest: boolean, includeTrading: boolean): ApiResponse<TransferSchedule[]> {
    return this.get('schedule', { includeInvest, includeTrading });
  }

  cancelSingleScheduledTransfer(transaction: TransferSchedule, isNextTransferdate: Boolean): ApiResponse<any> {
    return this.delete('schedule/single?isNextTransferdate=' + isNextTransferdate, transaction);
  }

  removeScheduledSeriesTransfer(transaction: TransferSchedule): ApiResponse<any> {
    return this.delete(`schedule/serie`, transaction);
  }

  getUpcomingTransfers(days: number): ApiResponse<TransferSchedule[]> {
    return this.get('schedule/upcoming', { days });
  }

  getCheckFiles(accountId: number, checkNumber: string, checkId: string): ApiResponse<CheckFiles> {
    return this.get(`${accountId}/check/${checkNumber}/${checkId}`);
  }

  scheduleEditInternalTransfer(transaction: TransferSchedule): ApiResponse<string> {
    return this.put('schedule/series', transaction);
  }

  scheduleEditNextInternalTransfer(transaction: TransferSchedule): ApiResponse<string> {
    return this.put('schedule/single', transaction);
  }

  /* external accounts */
  scheduleExternalTransfer(
    transaction: ExternalAccountScheduleRequest,
    validateDuplicate?: Boolean,
    acceptWarning?: Boolean
  ): ApiResponse<string> {
    return this.post(
      `schedule/external?validateDuplicate=${validateDuplicate}&acceptWarning=${acceptWarning}`,
      transaction
    );
  }

  scheduleEditExternalTransfer(transaction: ExternalAccountEditRequest): ApiResponse<string> {
    return this.put('schedule/external', transaction);
  }

  scheduleEditNextExternalTransfer(transaction: ExternalAccountEditRequest): ApiResponse<string> {
    return this.put('schedule/external/next', transaction);
  }

  removeScheduledExternalSeries(transaction: TransferSchedule): ApiResponse<string> {
    return this.delete('schedule/external', transaction);
  }

  cancelSingleScheduledTransferExternal(transaction: TransferSchedule): ApiResponse<any> {
    return this.delete('schedule/external/next', transaction);
  }

  /** P2P transfers */
  getP2PRecipients(): ApiResponse<P2PRecipient[]> {
    return this.get('recipients');
  }

  transferMoneyP2P(payment: P2PPayment): ApiResponse<any> {
    return this.post('transfer/p2p', payment);
  }

  downloadTransactionsFile(
    transaction: IDownloadTransactionsRequest,
    extension: number = ApiFileExtensions.Xlsx
  ): ApiResponse<ArrayBuffer> {
    return this.get(`download/${extension}`, transaction, false, null, 'arraybuffer');
  }

  getTransfersP2P(): ApiResponse<P2PTransferHistory[]> {
    return this.get('p2p');
  }

  cancelTransferP2P(payverisTransferId: number): ApiResponse<any> {
    return this.put(`p2p/${payverisTransferId}/cancel`);
  }

  getBalances(accountIds: number[]): ApiResponse<number[][]> {
    return this.get('balances', { accountIds }, this.transactionsCache);
  }

  // Holiday dates
  GetHolidayDates(): ApiResponse<any> {
    return this.get('holidays');
  }

  getWireTransferFees(CIF: string): ApiResponse<any> {
    return this.get(`wireFees/${CIF}`);
  }

  createWireTransaction(wireRequest: WireRequest): ApiResponse<any> {
    return this.post('wire', { wireRequest });
  }

  clearCache(): void {
    if (this.transactionsCache) {
      this.transactionsCache.removeAll();
    }
  }

  logUserAction(action: UserAction): ApiResponse<void> {
    return this.post('audit', action);
  }

  getContributionYears(): ApiResponse<ContributionYear[]> {
    return this.get('contributionyears');
  }
}
