import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { isAfter, isBefore, parseISO, set, subDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { isDateValid } from '@app/utils';
import { TransactionService } from '@core/services';
import { defaultFormatString, etTimeZoneString, lastUpdatedFormatString, quoteTimestampPlaceholder, todayAfterMarketFormatString, todayOnMarketFormatString } from './constants';

@Component({
  selector: 'app-quote-timestamp',
  templateUrl: './quote-timestamp.component.html',
  styleUrls: ['./quote-timestamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteTimestampComponent implements OnInit {

  today = new Date();
  lastUpdatedText = quoteTimestampPlaceholder;

  constructor(private transactionService: TransactionService, private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.initializeLastUpdatedText();
  }

  initializeLastUpdatedText(): void {
    this.transactionService.getHolidayDates().subscribe(response => {
      const holidays = response.data.dates.map(holiday => parseISO(holiday));
      // Checks today date
      const isTodayDateValid = isDateValid(this.today, holidays);

      if(isTodayDateValid){
        // Check today's hours
        const firstHourET = this.getETDateTimeBasedOnDate(this.today, 9, 30, 0, 0);
        const lastHourET = this.getETDateTimeBasedOnDate(this.today, 16, 0, 0, 0);

        if(isBefore(this.today, firstHourET)) {
          this.initializeLastUpdatedTextWithPreviousDays(this.today, holidays);
          return;
        }

        if(isAfter(this.today, lastHourET)) {
          this.setLastUpdatedTextWithTodayAfterMarket();
          return;
        }

        this.setLastUpdatedTextWithTodayOnMarket();
      }else{
        this.initializeLastUpdatedTextWithPreviousDays(this.today, holidays);
      }
    });
  }

  initializeLastUpdatedTextWithPreviousDays(currentDate: Date, holidays: Date[]): void {
    // Try 5 previous days
    let dayToTest = new Date(
      format(subDays(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 16, 0, 0, 0), 1), defaultFormatString, {
       timeZone: etTimeZoneString,
      })
    );
    for(let daysToTest = 5; daysToTest > 0; daysToTest--) {
      let isDayToTestValid = isDateValid(dayToTest, holidays);
      if(isDayToTestValid) {
        this.lastUpdatedText = format(new Date(dayToTest.toLocaleString('en-US', { timeZone: etTimeZoneString })), lastUpdatedFormatString, { timeZone: etTimeZoneString });
        this.changeDetectorRef.markForCheck();
        break;
      }
      dayToTest = subDays(dayToTest, 1);
    }
  }

  setLastUpdatedTextWithTodayAfterMarket(){
    this.lastUpdatedText = todayAfterMarketFormatString;
    this.changeDetectorRef.markForCheck();
  }

  setLastUpdatedTextWithTodayOnMarket(){
    this.lastUpdatedText = format(new Date(this.today.toLocaleString('en-US', { timeZone: etTimeZoneString })), todayOnMarketFormatString, {
      timeZone: etTimeZoneString
    });
    this.changeDetectorRef.markForCheck();
  }

  getETDateTimeBasedOnDate(date: Date, hours: number, minutes: number, seconds: number, milliseconds: number): Date {
    return new Date(
      format(set(date, {hours: hours, minutes: minutes, seconds: seconds, milliseconds: milliseconds}), defaultFormatString, {
        timeZone: etTimeZoneString,
      })
    );
  }
}
