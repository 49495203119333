import { NgModule } from '@angular/core';
import {
  Uk2DirectivesModule,
  Uk2ToastModule,
  Uk2TooltipModule,
} from '@axos/uikit-v2-lib';

// * Use this module to export all @axos/uikit-v2 modules required for this feature
@NgModule({
  exports: [Uk2DirectivesModule, Uk2TooltipModule, Uk2ToastModule],
})
export class ShareAccountUk2Module {}
