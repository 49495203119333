import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UtmCodesRequest } from '@app/sbloc-offers/Models';
import { SblocService } from '@app/sbloc-offers/services/sbloc.service';
import { RegisterUserAction } from '@app/sbloc-offers/utils/registerUserAction';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';

@Inject('loadUserProfileHelper')
@Component({
  selector: 'app-sbloc-offers',
  templateUrl: './sbloc-offers.component.html',
  styleUrls: ['./sbloc-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
})
export class SblocOffersComponent implements OnDestroy {
  overflowflag = 'hidden';
  flagSBLOC: boolean = false;
  safeUrl: string;
  utmInfo: UtmCodesRequest;

  constructor(
    @Inject(STATE) private state: ng.ui.IStateService,
    readonly featureFlagService: FeatureFlagService,
    private bodyRef: ElementRef,
    private registerUserAction: RegisterUserAction,
    private readonly _sblocService: SblocService,
    @Inject(STATE_PARAMS) private stateParams: ng.ui.IStateParamsService
  ) {
    bodyRef.nativeElement.ownerDocument.body.style.overflow = this.overflowflag;
  }
  ngOnInit() {
    this.loadParams();
    this._sblocService.getInvestorCheckingScreen().subscribe(result => {
      this.safeUrl = result;
      const iframe = document.getElementById('SblocIFrame') as HTMLIFrameElement;
      iframe.src = this.safeUrl;
    });
  }

  ngOnDestroy(): void {
    this.overflowflag = null;
    this.bodyRef.nativeElement.ownerDocument.body.style.overflow = this.overflowflag;
  }

  goPreApprovedOffer(): void {
    this.flagSBLOC = this.featureFlagService.isSBLOCMVPActive();
    if (this.flagSBLOC == false) {
      this.state.go('udb.FeatureFlagOff');
    } else {
      this.registerUserAction.registerSeeingPreOffer().subscribe(() => {
        this.state.go('udb.preApproved');
      });
    }
  }

  goDashboard() {
    this.state.go('udb.dashboard');
  }

  private loadParams(): void {
    const {
      utm_campaign,
      utm_medium,
      utm_source,
      utm_content,
      utm_term,
      utm_promocode,
      experience_id,
      hem,
      identity,
      msclkid,
      gclid,
      fbclid,
    } = this.stateParams;

    this.utmInfo = {
      utm_campaign: utm_campaign ?? '',
      utm_medium: utm_medium ?? '',
      utm_source: utm_source ?? '',
      utm_content: utm_content ?? '',
      utm_term: utm_term ?? '',
      utm_promcode: utm_promocode ?? '',
      experience_id: experience_id ?? '',
      hem: hem ?? '',
      identity: identity ?? '',
      msclkid: msclkid ?? '',
      gclid: gclid ?? '',
      fbclid: fbclid ?? '',
    };
    this._sblocService.setUtmCodes(this.utmInfo);
  }
}
