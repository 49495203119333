import { IRootScopeService, IScope } from 'angular';
import { TilesService } from 'services/tiles.service';

import { Inject } from '../../decorators/decorators';
import { PaymentsService } from '../../services/payments.service';
import { ScheduledPaymentInfo } from '../../typings/app/bills/ScheduledPaymentInfo';
import { BaseTile, BaseTileSettings, TileSetting } from '../base-tile';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { LoadUserProfileHelper } from '@legacy/services/load-user-profile-helper';
import { IStateService } from 'angular-ui-router';
import { IBillPayService } from '@legacy/services/typings/IBillPayService';
import { Recipient } from '@app/bill-pay/typings/Recipient';
@Inject(
  '$scope',
  '$element',
  'tilesService',
  'serviceHelper',
  '$rootScope',
  'paymentsService',
  'cachedAccountsService',
  'loadUserProfileHelper',
  '$state',
  'billPayService'
)
export class ScheduledPaymentsController extends BaseTile<ScheduledPaymentsTileSettings> {
  title = 'Scheduled Bill Payments';
  isBusy: boolean;
  accounts: OlbAccount[] = [];
  accountInfo: ScheduledPaymentInfo;
  account: OlbAccount = {};
  dayRange = 7;
  settings: SchedulePaymentSettings = {};
  hasScheduledPayments = false;
  hasErrored = false;
  noPaymentsImg = '/img/icons/tiles/no-payments.svg';
  scheduledPaymentsImg = '/img/icons/tiles/scheduled-payments.svg';
  recipients: Recipient[];

  private listeners: Function[] = [];

  constructor(
    scope: IScope,
    elm: ng.IRootElementService,
    tilesService: TilesService,
    serviceHelper: IServiceHelper,
    private rootScope: IRootScopeService,
    private paymentsService: PaymentsService,
    private cachedAccountsService: CachedAccountsService,
    private loadUserProfileHelper: LoadUserProfileHelper,
    private state: IStateService,
    private billPayService: IBillPayService
  ) {
    super(scope, elm, tilesService, serviceHelper);
  }

  /** Initializes the controller. */
  $onInit(): void {
    if (this.rootScope['accounts']) this.getSettings(this.setupTile.bind(this));

    this.listeners.push(
      this.scope.$on('accountsLoaded', () => {
        this.getSettings(this.setupTile.bind(this));
      }),
      this.scope.$on('updateTransactions', () => this.getSettings(this.setupTile.bind(this))),
      this.scope.$on('transferCompleted', () => this.getSettings(this.setupTile.bind(this)))
    );

    this.billPayService.getRecipients().then(res => {
      this.recipients = res.data;
    });
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
  }

  /** Saves the tile settings. */
  saveTileSettings(): void {
    this.tileSettings.Account.value = this.settings.account.id.toString();
    this.tileSettings.Dayrange.value = this.settings.dayRange.toString();
    this.saveSettings();
    this.account = this.settings.account;
    this.dayRange = this.settings.dayRange;
    this.getScheduledPayments();
  }

  /** Resets the tile settings to its normal/previous state. */
  resetSettings(): void {
    this.settings.account = this.accounts.filter(a => a.id === +this.tileSettings.Account.value)[0];
    this.settings.dayRange = +this.tileSettings.Dayrange.value;
    this.account = this.settings.account;
    this.dayRange = this.settings.dayRange;
  }

  public redirectUserToBillPay(): void {
    this.state.go(this.getBillPayUrl());
  }

  /** Returns the URL for the bill pay page based on the user's account type and recipient availability. */
  private getBillPayUrl(): string {
    const personalAccounts = this.cachedAccountsService.hasPersonalAccounts();
    const businessAccounts = this.cachedAccountsService.hasBusinessAccounts();
    const userCif = this.loadUserProfileHelper.getUserProfile().cif;
    const isSolePropUser = this.cachedAccountsService.isSolePropUser(userCif);

    if (personalAccounts && businessAccounts) {
      return 'udb.billPay.welcome'; // URL for users with both personal and business accounts
    } else if (businessAccounts) {
      return 'udb.billPay.welcome'; // URL for users with only business accounts
    } else if (personalAccounts || (isSolePropUser && this.recipients.length > 0)) {
      return 'udb.billPay.pay'; // URL for users with only personal accounts or sole prop users with recipients
    }

    return 'udb.billPay.welcome'; // Default URL
  }

  /** Sets the tile up with its settings. */
  private setupTile(): void {
    this.accounts = this.rootScope['accounts'].depositAccounts.filter((acc: OlbAccount) => {
      return acc.canPayFromBillPay;
    });

    if (!this.accounts[0]) {
      this.removeThisTile();

      return;
    }

    // Sets as default the first account in case there is no setting
    if (!this.tileSettings.Account.value || !this.settings.account) {
      this.tileSettings.Account.value = this.accounts[0].id.toString();
    }

    this.resetSettings();

    this.getScheduledPayments();
    this.isInitialising = false;
  }

  /** Gets the scheduled payments for the account selected */
  private getScheduledPayments(): void {
    this.isBusy = true;
    this.paymentsService
      .getScheduledPaymentsByAccount(this.account.id, this.dayRange)
      .then(res => {
        this.accountInfo = res.data;
        this.hasScheduledPayments = !!this.accountInfo.scheduledPayments.length;
      })
      .catch(() => {
        this.hasErrored = true;
      })
      .finally(() => {
        this.isBusy = false;
      });
  }
}

interface ScheduledPaymentsTileSettings extends BaseTileSettings {
  Account?: TileSetting;
  Dayrange?: TileSetting;
}

interface SchedulePaymentSettings {
  account?: OlbAccount;
  dayRange?: number;
}
