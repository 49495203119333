<div class="dialog-header">
  <app-svg-icon class="icon" [iconName]="modalHeaderIconName" size="70px"></app-svg-icon>
</div>

<h3 mat-dialog-title class="title">{{ modalHeaderLabel }}</h3>
<div mat-dialog-content class="dialog-content">
  <p *ngFor="let label of modalContentLabels">
    {{ label }}
  </p>
</div>
<div class="dialog-actions">
  <ng-container *ngIf="modalType == cancelScheduledModalTypes.SeriesCancelConfirm; else closeActions">
    <button type="button" class="uk-btn lg solid secondary" (click)="cancelSeries()">Yes</button>
    <button type="button" class="uk-btn lg outline secondary back-button" (click)="dismissModal()">No</button>
  </ng-container>
  <ng-template #closeActions>
    <button type="button" class="uk-btn lg solid secondary" (click)="dismissModal()">Ok</button>
  </ng-template>
</div>
