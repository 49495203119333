<p class="terms-conditions__title text-left mb-2">Terms and Conditions</p>

<div class="d-flex">
  <input
    id="accept-terms"
    type="checkbox"
    class="checkbox"
    (change)="showTermsConditions($event.target.checked)"
    [checked]="accepted"
  />
  <label for="accept-terms" class="terms-conditions__disclaimer">
    I agree to Axos Bank's
    <button class="link" id="linkTermsConditions" (click)="showTermsConditions(true)">
      Electronic Record and Signature Agreement for Direct Deposit
    </button>
  </label>
</div>
