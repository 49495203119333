import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalAction } from '../../enums';
import { Uk2DropdownSizeEnum, Uk2Tier1AlertsEnum, Uk2Tier1NavigationEnum } from '@axos/uikit-v2-lib';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS } from '../../../validators';
import { INPUT_EVENT_TYPES, TRUSTED_INTERESTED_MODAL_FORM_LABELS } from '../../constants';
import { usStates } from '@app/config/constants';
import { DOCUMENT_LIST } from './constants';
import { BrandingFacade } from '@app/Areas/AAS/features/branding';
import { takeUntil, tap } from 'rxjs/operators';
import { InterestedParty } from '@app/Areas/AAS/features/account-details/core/services/account-people';

@Component({
  selector: 'app-interested-party-form-modal',
  templateUrl: './interested-party-form-modal.component.html',
  styleUrls: ['./interested-party-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterestedPartyFormModalComponent implements OnInit, OnDestroy {
  @Input() parentFormModal: FormGroup;
  @Input() data: InterestedParty;
  @Input() modalAction: ModalAction;
  @Output() isFormDirty = new EventEmitter();

  @ViewChild('inputName') inputName!: ElementRef<HTMLInputElement>;
  @ViewChild('inputZipCode') inputZipCode!: ElementRef<HTMLInputElement>;
  @ViewChild('inputCity') inputCity!: ElementRef<HTMLInputElement>;
  @ViewChild('inputStreetAddress') inputStreetAddress!: ElementRef<HTMLInputElement>;
  @ViewChild('inputAdditionalAddress') inputAdditionalAddress!: ElementRef<HTMLInputElement>;

  states = usStates;
  statesKeys = Object.keys(this.states);
  showClearFieldButton = true;
  clearButtonSvgIconName = Uk2Tier1NavigationEnum.x;
  exclamationTriangleSvgIconName = Uk2Tier1AlertsEnum.exclamationTriangle;
  dropdownSize = Uk2DropdownSizeEnum.large;
  documentList = DOCUMENT_LIST;
  nameErrorMessageSubject = new BehaviorSubject('');
  streetAddressErrorMessageSubject = new BehaviorSubject('');
  additionalAddressErrorMessageSubject = new BehaviorSubject('');
  zipCodeErrorMessageSubject = new BehaviorSubject('');
  cityErrorMessageSubject = new BehaviorSubject('');
  zipCodeInputBlurFlag = false;
  customCSSProperties;
  interestedPartyFormModal: FormGroup = this.fb.group({
    name: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.name]),
    streetAddress: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.streetAddressDefault]),
    additionalAddress: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.additionalAddressDefault]),
    zipCode: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.zipCode]),
    city: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.city]),
    state: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.state]),
    documentsReceived: new FormControl('', [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.documentsReceived]),
  });

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private brandingFacade: BrandingFacade) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.brandingFacade.cssBrandingProperties
      .pipe(
        takeUntil(this.destroy$),
        tap(cssProperty => {
          this.customCSSProperties = cssProperty;
        })
      )
      .subscribe();
    this.interestedPartyFormModal.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isFormDirty.emit(this.interestedPartyFormModal.dirty);
    });
    this.parentFormModal.addControl('interestedPartyFormModal', this.interestedPartyFormModal);
    this.listenInputs();
  }

  get name(): any {
    return this.interestedPartyFormModal.get('name');
  }

  get streetAddress(): any {
    return this.interestedPartyFormModal.get('streetAddress');
  }

  get additionalAddress(): any {
    return this.interestedPartyFormModal.get('additionalAddress');
  }

  get zipCode(): any {
    return this.interestedPartyFormModal.get('zipCode');
  }

  get city(): any {
    return this.interestedPartyFormModal.get('city');
  }

  get state(): any {
    return this.interestedPartyFormModal.get('state');
  }

  get documentsReceived(): any {
    return this.interestedPartyFormModal.get('documentsReceived');
  }

  clearName() {
    this.interestedPartyFormModal.patchValue({ name: '' });
  }

  clearStreetAddress() {
    this.interestedPartyFormModal.patchValue({ streetAddress: '' });
  }

  clearAdditionalAddress() {
    this.interestedPartyFormModal.patchValue({ additionalAddress: '' });
  }

  clearZipCode() {
    this.interestedPartyFormModal.patchValue({ zipCode: '' });
  }

  clearCity() {
    this.interestedPartyFormModal.patchValue({ city: '' });
  }

  onItemRemoved(item: string) {
    const items = this.documentsReceived.value!;
    this.removeFirst(items, item);
    this.documentsReceived.setValue(items);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  private listenInputs() {
    const targetElement = this.inputStreetAddress?.nativeElement;
    if (targetElement) {
      this.fillFields();
      // Events for Name
      this.listenInputNameEvents(INPUT_EVENT_TYPES.input);
      this.listenInputNameEvents(INPUT_EVENT_TYPES.blur);
      // Events for Street Address
      this.listenInputStreetAddressEvents(INPUT_EVENT_TYPES.input);
      this.listenInputStreetAddressEvents(INPUT_EVENT_TYPES.blur);
      // Events for Additional Address
      this.listenInputAdditionalAddressEvents(INPUT_EVENT_TYPES.input);
      this.listenInputAdditionalAddressEvents(INPUT_EVENT_TYPES.blur);
      // Events for Zip Code
      this.listenInputZipCodeEvents(INPUT_EVENT_TYPES.input);
      this.listenInputZipCodeEvents(INPUT_EVENT_TYPES.blur);
      // Events for City
      this.listenInputCityEvents(INPUT_EVENT_TYPES.input);
      this.listenInputCityEvents(INPUT_EVENT_TYPES.blur);
    } else {
      setTimeout(() => {
        this.listenInputs();
      }, 100);
    }
  }

  private fillFields() {
    // Set values to the form fields
    if (this.modalAction === ModalAction.Edit) {
      this.name.setValue(this.data.name);
      this.streetAddress.setValue(this.data.address.address1);
      this.additionalAddress.setValue(this.data.address.address2);
      this.zipCode.setValue(this.data.address.zipCode);
      this.city.setValue(this.data.address.city);
      this.state.setValue(this.data.address.state);
      this.documentsReceived.setValue(this.formatDocumentsReceived());
    }
  }

  private hasInvalidName() {
    return (
      this.interestedPartyFormModal.dirty && this.interestedPartyFormModal.get('name')?.hasError('textOnlyValidation')
    );
  }

  private getNameErrorMessage(typeEvent?: string) {
    let errorMessage: string | null = null;

    if (this.hasInvalidName() && (typeEvent === INPUT_EVENT_TYPES.input || typeEvent === INPUT_EVENT_TYPES.blur)) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.NAME_ERROR.invalidCharacter;
    }

    if ((!this.hasInvalidName() && !this.name.value) || this.name.value === '') {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.NAME_ERROR.required;
    }

    if (errorMessage) {
      this.name?.markAsTouched();
      this.nameErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputNameEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.name];

    fromEvent(this.inputName?.nativeElement, eventType).subscribe(event => {
      this.name?.setValidators(validations);
      this.name?.updateValueAndValidity();
      this.getNameErrorMessage(event.type);
    });
  }

  private hasInvalidSymbolStreetAddress(controlName: string) {
    return (
      this.interestedPartyFormModal.dirty &&
      this.interestedPartyFormModal.get(controlName)?.hasError('addressValidation')
    );
  }

  private getStreetAddressErrorMessage(typeEvent?: string) {
    const controlName = 'streetAddress';
    let errorMessage: string | null = null;
    if (
      this.hasInvalidSymbolStreetAddress(controlName) &&
      (typeEvent === INPUT_EVENT_TYPES.input || typeEvent === INPUT_EVENT_TYPES.blur)
    ) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.STREET_ADDRESS_ERROR.invalidCharacter;
    }

    if (
      (!this.hasInvalidSymbolStreetAddress(controlName) && !this.streetAddress.value) ||
      this.streetAddress.value === ''
    ) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.STREET_ADDRESS_ERROR.required;
    }

    if (errorMessage) {
      this.streetAddress?.markAsTouched();
      this.streetAddressErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputStreetAddressEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.streetAddress];

    fromEvent(this.inputStreetAddress?.nativeElement, eventType).subscribe(event => {
      this.streetAddress?.setValidators(validations);
      this.streetAddress?.updateValueAndValidity();
      this.getStreetAddressErrorMessage(event.type);
    });
  }

  private getAdditionalAddressErrorMessage(typeEvent?: string) {
    const controlName = 'additionalAddress';
    let errorMessage: string | null = null;

    if (
      this.hasInvalidSymbolStreetAddress(controlName) &&
      (typeEvent === INPUT_EVENT_TYPES.input || typeEvent === INPUT_EVENT_TYPES.blur)
    ) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.STREET_ADDRESS_ERROR.invalidCharacter;
    }

    if (errorMessage) {
      this.additionalAddress?.markAsTouched();
      this.additionalAddressErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputAdditionalAddressEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.additionalAddress];

    fromEvent(this.inputAdditionalAddress?.nativeElement, eventType).subscribe(event => {
      this.additionalAddress?.setValidators(validations);
      this.additionalAddress?.updateValueAndValidity();
      this.getAdditionalAddressErrorMessage(event.type);
    });
  }

  private hasInvalidZipCode() {
    return (
      this.interestedPartyFormModal.dirty && this.interestedPartyFormModal.get('zipCode')?.hasError('zipCodeValidation')
    );
  }

  private hasInvalidLengthZipCode() {
    return this.interestedPartyFormModal.dirty && this.interestedPartyFormModal.get('zipCode')?.hasError('minlength');
  }

  private getZipCodeErrorMessage(typeEvent?: string) {
    let errorMessage: string | null = null;

    if (typeEvent === INPUT_EVENT_TYPES.blur) {
      this.zipCodeInputBlurFlag = true;
    }

    if (
      this.hasInvalidLengthZipCode() &&
      (typeEvent === INPUT_EVENT_TYPES.blur || (typeEvent === INPUT_EVENT_TYPES.input && this.zipCodeInputBlurFlag))
    ) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.ZIP_CODE_ERROR.minLength;
    }

    if (this.hasInvalidZipCode() && (typeEvent === INPUT_EVENT_TYPES.input || typeEvent === INPUT_EVENT_TYPES.blur)) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.ZIP_CODE_ERROR.invalidCharacter;
    }

    if ((!this.hasInvalidZipCode() && !this.zipCode.value) || this.zipCode.value === '') {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.ZIP_CODE_ERROR.required;
    }

    if (errorMessage) {
      this.zipCode?.markAsTouched();
      this.zipCodeErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputZipCodeEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.zipCode];

    fromEvent(this.inputZipCode?.nativeElement, eventType).subscribe(event => {
      this.zipCode?.setValidators(validations);
      this.zipCode?.updateValueAndValidity();
      this.getZipCodeErrorMessage(event.type);
    });
  }

  private hasInvalidCity() {
    return (
      this.interestedPartyFormModal.dirty &&
      this.interestedPartyFormModal.get('city')?.hasError('alphanumericOnlyValidation')
    );
  }

  private getCityErrorMessage(typeEvent?: string) {
    let errorMessage: string | null = null;

    if (this.hasInvalidCity() && (typeEvent === INPUT_EVENT_TYPES.input || typeEvent === INPUT_EVENT_TYPES.blur)) {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.CITY_ERROR.invalidCharacter;
    }

    if ((!this.hasInvalidCity() && !this.city.value) || this.city.value === '') {
      errorMessage = TRUSTED_INTERESTED_MODAL_FORM_LABELS.CITY_ERROR.required;
    }

    if (errorMessage) {
      this.city?.markAsTouched();
      this.cityErrorMessageSubject.next(errorMessage);
    }
  }

  private listenInputCityEvents(eventType: string) {
    let validations: any = [...TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS.city];

    fromEvent(this.inputCity?.nativeElement, eventType).subscribe(event => {
      this.city?.setValidators(validations);
      this.city?.updateValueAndValidity();
      this.getCityErrorMessage(event.type);
    });
  }

  private formatDocumentsReceived(): string[] {
    let documentReceived = [];

    if (this.data.statementForms) {
      documentReceived.push('Statements');
    }

    if (this.data.taxForms) {
      documentReceived.push('Tax Forms');
    }

    return documentReceived;
  }
}
