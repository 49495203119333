<mat-dialog-content class="mat-dialog-content">
  <div class="image"></div>
  <div class="titles">
    <h2>Select a business</h2>
    <p>Select the business for which you want to {{ endingSentence }}.</p>
  </div>

  <div class="dropdown">
    <select [(ngModel)]="selectedBusiness" (ngModelChange)="onBusinessChange($event)" [disabled]="isLoadingURL">
      <option value="null" disabled selected>Select a Business</option>
      <option *ngFor="let acc of sbbAccounts" [ngValue]="acc">{{ acc.name }}</option>
    </select>
  </div>

  <div class="button-bar">
    <button class="btn-cancel button--lg" mat-dialog-close (click)="closeModal()">Cancel</button>
    <button class="uk-btn solid secondary lg" (click)="continue()" [disabled]="isDisabled || isLoadingURL">
      Continue
    </button>
  </div>
</mat-dialog-content>
