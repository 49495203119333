import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { AuthorizationsFacade } from '../../../../../facade/authorizations.facade';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AdvisorContributionAuthorityType } from '@app/Areas/AAS/features/account-details/core';

import { format, parseISO } from 'date-fns';
import { DATE_FORMAT } from './constants';

@Component({
  selector: 'app-advisor-contribution',
  templateUrl: './advisor-contribution.component.html',
  styleUrls: ['./advisor-contribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvisorContributionComponent implements OnInit, OnDestroy {
  isLoading = true;
  destroy$ = new Subject<void>();
  authorizationsTabState: AdvisorContributionAuthorityType;

  constructor(private authorizationsFacade: AuthorizationsFacade, private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.authorizationsFacade.authorizationsTabAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(data => {
        this.authorizationsTabState = data?.advisorContributionAuthority;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormattedDate(value): string {
    return value ? format(parseISO(value as any), DATE_FORMAT) : null;
  }

  getFormattedYesNo(value): string {
    if (value != undefined) {
      if (value == true) {
        return 'Yes';
      } else {
        return 'No';
      }
    }
    return null;
  }
}
