import { Inject, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AggregatedTransaction, OlbSettings, TransactionRequest } from '@core/models';
import { olbSettings } from '@core/tokens';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WebApiResponse } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class AccountAggregationService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'accountAggregation');
  }

  /**
   * Get Transactions by different filters
   * @param request
   */
  public getTransactions(request: TransactionRequest): WebApiResponse<AggregatedTransaction[]> {
    let params = new HttpParams();
    for (const key in request) {
      if (request.hasOwnProperty(key)) {
        params = params.set(key, request[key] ?? '');
      }
    }
    return this.get('transactions', params);
  }
}
