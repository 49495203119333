import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { StopPaymentService } from '@legacy/services/stop-payment.service';
import { StopPayment } from '@legacy/typings/app/StopPayment';

@Component({
  selector: 'app-stop-payment',
  templateUrl: './stop-payment.component.html',
  styleUrls: ['./stop-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopPaymentComponent implements OnInit {
  accountId: string;
  stopPayments: StopPayment[];
  isBusy: boolean = false;
  orderBy: string;
  reverse: boolean = false;
  headers: GenericOption[];
  private isNightlyProcess = false;
  private readonly popups: IPopups;
  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private stopPaymentService: StopPaymentService,
    private featureFlagService: FeatureFlagService,
    private serviceHelper: ServiceHelper,
    @Inject(STATE) private state: ng.ui.IStateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /** Initializes any required data */
  ngOnInit(): void {
    this.accountId = this.params['id'];
    this.headers = [
      { value: 0, subvalue: 'stopType', label: 'Type' },
      {
        value: 1,
        subvalue: 'payeeDebitingCompany',
        label: 'Payee/Debiting Company',
      },
      { value: 2, subvalue: 'lowCheckNumber', label: 'Check #' },
      { value: 3, subvalue: 'amount', label: 'Amount' },
      { value: 4, subvalue: 'requestedDate', label: 'Requested Date' },
      { value: 5, subvalue: 'expirationDate', label: 'Expiration Date' },
      { value: 6, subvalue: 'action', label: 'Action' },
    ];
    this.loadStopPayments();
    this.isNightlyProcess = this.featureFlagService.isSiteInReadOnly();
  }

  /** Performs a call to the service to retrieve the stop payments queued */
  loadStopPayments(): void {
    this.isBusy = true;
    this.stopPaymentService
      .getStopPayments(this.accountId)
      .then(res => {
        this.stopPayments = res.data;
        this.stopPayments.forEach(sp => {
          sp.lowCheckNumber = +sp.lowCheckNumber;
          sp.highCheckNumber = +sp.highCheckNumber;
        });
      })
      .catch(this.serviceHelper.errorHandler)
      .finally(() => {
        this.isBusy = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Sorting function in the header table
   * @param orderBy header name to used to order by
   * @param headerId actual id taken for ordering
   */
  sort(orderBy: string): void {
    // The following columns are not sortable
    if (['payeeDebitingCompany', 'action'].includes(orderBy)) return;
    this.orderBy = orderBy;
    this.reverse = !this.reverse;
  }

  removeStopPayment(spCancelSend: StopPayment): void {
    if (this.isNightlyProcess) {
      this.showPopUpError();
    } else {
      this.state.go('udb.accounts.stop-payment-remove', {
        id: this.accountId,
        seq: spCancelSend.sequenceId,
        spCancel: spCancelSend,
      });
    }
  }

  extendStopPayment(spExtendSend: StopPayment): void {
    if (this.isNightlyProcess) {
      this.showPopUpError();
    } else {
      this.state.go('udb.accounts.stop-payment-extend', {
        id: this.accountId,
        seq: spExtendSend.sequenceId,
        spExtend: spExtendSend,
      });
    }
  }

  redirectTo(accountId: string) {
    this.state.go('udb.accounts.stop-payment-add', { id: accountId });
  }

  redirectBack(accountId: string) {
    this.state.go('udb.accounts.details', { id: accountId, tab: 1 });
  }

  private showPopUpError(): void {
    let defaultMessage =
      'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
    this.popups?.showAlert('Error', defaultMessage, 'error');
  }
}
