import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  BILLPAYHELPER,
  BILLPAYROUTERHELPER,
  BILLPAYSERVICE,
  CACHEDACCOUNTSSERVICE,
  DATERANGEPICKEROPTIONS,
  SERVICEHELPER,
  billPayServiceProvider,
} from '@app/bill-pay/ajs-upgraded-provider';
import { ROOT_SCOPE } from '@core/tokens';
import { BillPayRouterHelper } from '@legacy/bill-pay/bill-pay-router.helper';
import { BillPayHelper } from '@legacy/bill-pay/bill-pay.helper';
import { PaymentHistory } from '@legacy/bill-pay/typings/PaymentHistory';
import { PaymentHistoryVM } from '@legacy/bill-pay/typings/PaymentHistoryVM';
import { PaymentStates } from '@legacy/bill-pay/typings/PaymentStates';
import { BillPayService } from '@legacy/services/bill-pay.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { UserAccountType } from '@legacy/typings/app/bills/UserAccountType';
import { CommunicationIcons, UtilityIcons } from '@shared/enums';
import { add, startOfToday, startOfTomorrow } from 'date-fns';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-payments-history',
  templateUrl: './payments-history.component.html',
  styleUrls: ['./payments-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: billPayServiceProvider,
})
export class PaymentsHistoryComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isLoading$ = new Subject();
  headers: GenericOption[];
  orderBy: string[];
  headerIdOrderBy: number;
  reverse: boolean;
  payments: PaymentHistory[] = [] as PaymentHistory[];
  // Datepickers
  fromDatePickerOptions: any;
  toDatePickerOptions: any;
  fromDate: Date;
  toDate: Date;
  recipient: string = '';
  hasAtLeastOnePayment: boolean = false;
  paymentsInTable: PaymentHistoryVM[];
  limitedPayments: number = 20;
  showMoreCounter: number;
  accountsFrom: UserAccountType[];
  minFromDate = '';
  minToDate = startOfToday();
  maxFromDate = startOfToday();
  maxToDate = add(startOfTomorrow(), { days: 6 });
  isCalculateDisabled = false;
  isBusinessDays = false;

  constructor(
    @Inject(ROOT_SCOPE) private scope: ng.IRootScopeService,
    @Inject(BILLPAYSERVICE) private readonly _billPayService: BillPayService,
    @Inject(SERVICEHELPER) private readonly _serviceHelper: IServiceHelper,
    @Inject(BILLPAYHELPER) private readonly _billPayHelper: BillPayHelper,
    @Inject(DATERANGEPICKEROPTIONS) private readonly _dateRangePickerOptions: any,
    @Inject(BILLPAYROUTERHELPER) private readonly _billPayRouterHelper: BillPayRouterHelper,
    @Inject(CACHEDACCOUNTSSERVICE) private readonly cachedAccountsService: CachedAccountsService,
    private dateHelper: DateHelper
  ) {}
  ngOnDestroy() {}
  ngOnInit() {
    this.isLoading = true;
    this.isLoading$.next(this.isLoading);

    this.scope.$on('accountsLoaded', () => {
      this._loadAccounts();
    });

    this.headers = [
      { value: 0, subvalue: 'payeeName', label: 'Recipient' },
      { value: 1, subvalue: 'amount', label: 'Amount To Pay' },
      { value: 2, subvalue: 'paymentFrequency', label: 'Pay Frequency' },
      { value: 3, subvalue: 'accountName', label: 'Pay From' },
      { value: 4, subvalue: 'deliveryDate', label: 'Delivery On' },
      { value: 5, subvalue: 'paymentMethod', label: 'Recipient Type' },
      { value: 6, subvalue: 'status', label: 'Status' },
      { value: 7, subvalue: 'confirmationNumber', label: 'Confirmation #' },
      { value: 8, subvalue: '', label: '' },
    ];

    this.fromDate = undefined;
    this.toDate = undefined;
    this.recipient = this._billPayRouterHelper.getHistoryFilter() || '';
    this.fromDatePickerOptions = this.setupDatePickerOptions(true);
    this.toDatePickerOptions = this.setupDatePickerOptions();
    this._loadAccounts();
    this.getPaymentHistory(this.fromDate, this.toDate, this.recipient);
  }

  ShowMore() {
    this.showMoreCounter++;
    this.paymentsInTable = this.payments
      .slice(0, this.showMoreCounter * this.limitedPayments)
      .map(p => this.toPaymentHistoryVM(p));
  }

  toggle(paymentHistory: PaymentHistory) {
    paymentHistory.isToggled = !paymentHistory.isToggled;
  }

  /**
   * Sorting function in the header table
   * @param orderBy header name used to order by
   * @param headerId actual id taken for ordering
   */
  sort(orderBy: string, headerId: number) {
    if (headerId === 8) return;
    this.orderBy = [orderBy];
    if (headerId === 0) {
      this.orderBy = ['payeeName', '!!nickName', 'nickName'];
    }

    this.headerIdOrderBy = headerId;
    this.reverse = !this.reverse;
    if (orderBy == 'paymentMethod') orderBy = 'checkNumber';
    if (this.reverse) {
      this.paymentsInTable = this.paymentsInTable.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    } else {
      this.paymentsInTable = this.paymentsInTable.sort((a, b) => (a[orderBy] > b[orderBy] ? -1 : 1));
    }
  }

  getAccountBalance(accountCode: string): number {
    const selectedAccount = this.accountsFrom.find(a => a.accountCode === accountCode);
    return selectedAccount.balance;
  }

  getClassName(state: PaymentStates): string {
    switch (state) {
      case PaymentStates.Canceled:
      case PaymentStates.FailedFunding:
      case PaymentStates.PostalReturn:
      case PaymentStates.Returned:
      case PaymentStates.ReturnedMoneyOwed:
      case PaymentStates.ReturnedRefunded:
      case PaymentStates.PaymentStopped:
      case PaymentStates.Undeliverable:
        return 'red';
      case PaymentStates.Cleared:
      case PaymentStates.Delivered:
      case PaymentStates.Mailed:
        return 'green';
      case PaymentStates.Created:
        return 'gray';
      case PaymentStates.InProcess:
      case PaymentStates.Pending:
        return 'yellow';
      default:
        return '';
    }
  }

  getHeaderTooltip(header: string): string {
    return this._billPayHelper.getHeaderTooltip(header);
  }

  private _loadAccounts() {
    this.accountsFrom = this.cachedAccountsService.paymentAccounts;
  }

  private setupDatePickerOptions(
    isFrom: boolean = false,
    optionalMaxDate: Date = null,
    optionalMinDate: Date = null
  ): any {
    return angular.extend(angular.copy(this._dateRangePickerOptions), {
      minDate: optionalMinDate !== null ? moment(optionalMinDate) : moment().subtract(18, 'M'),
      maxDate: isFrom ? (optionalMaxDate !== null ? moment(optionalMaxDate) : moment()) : moment().add(7, 'days'),
      singleDatePicker: true,
      autoUpdateInput: true,
      eventHandlers: {
        'apply.daterangepicker': this.datePickerEventHandler.bind(this),
      },
    });
  }

  getPaymentHistory(startDate: Date, endDate: Date, recipient: string) {
    this.isLoading = true;
    this.isLoading$.next(this.isLoading);
    if (startDate && !endDate) {
      this.toDate = this.maxToDate;
      endDate = this.toDate;
    }
    const formatedStartDate = this.FormatDates(startDate);
    const formatedEndDate = this.FormatDates(endDate);

    this.showMoreCounter = 1;

    this._billPayService
      .getPaymentHistory(formatedStartDate, formatedEndDate, recipient)
      .then(res => {
        this.payments = res.data;
        this.paymentsInTable = this.payments.slice(0, this.limitedPayments).map(p => this.toPaymentHistoryVM(p));

        if (this.hasAtLeastOnePayment === false) {
          this.hasAtLeastOnePayment = this.payments.length > 0 ? true : false;
        }
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper))
      .finally(() => {
        this.isLoading = false;
        this.isLoading$.next(this.isLoading);
      });
  }

  private toPaymentHistoryVM(payment: PaymentHistory): PaymentHistoryVM {
    return {
      payeeName: payment.payeeInformation.payeeName,
      nickName:
        payment.payeeInformation.nickName !== payment.payeeInformation.payeeName
          ? payment.payeeInformation.nickName
          : null,
      accountCode: payment.accountCode,
      accountName: payment.accountName,
      amount: payment.amount,
      confirmationNumber: payment.confirmationNumber,
      currentState: payment.currentState,
      deliveryDate: payment.deliveryDate,
      frequency: payment.paymentFrequency,
      paymentFrequency: this._billPayHelper.mapFrequency(payment.paymentFrequency),
      paymentMethod: this._billPayHelper.mapPaymentMethod(payment.paymentMethod),
      processingDate: payment.processingDate,
      status: payment.currentState.displayName,
      checkNumber: payment.checkNumber ? ` - #${payment.checkNumber}` : '',
    };
  }
  private FormatDates(date: Date): string {
    if (date === undefined) return '';
    return date.toISOString().slice(0, 10);
  }

  datePickerEventHandler() {
    if (this.toDate === undefined) {
      this.toDate = moment().add(7, 'days').toDate();
    }
    if (this.fromDate === undefined) {
      this.fromDate = moment(this.toDate).add(-30, 'days').toDate();
      this.fromDatePickerOptions = this.setupDatePickerOptions(true);
    }
    if (this.toDate < moment().toDate()) {
      this.fromDatePickerOptions = this.setupDatePickerOptions(true, this.toDate);
    } else {
      this.fromDatePickerOptions = this.setupDatePickerOptions(true);
    }

    this.toDatePickerOptions = this.setupDatePickerOptions(false, null, this.fromDate);
    this.getPaymentHistory(this.fromDate, this.toDate, this.recipient);
  }

  dateAllowed = (d: Date): boolean => {
    this.isCalculateDisabled = false;
    if (!this.isBusinessDays) {
      return true;
    }

    /* Prevent Saturday, Sunday and Holidays for select. */
    return this.dateHelper.isValidDate(moment(d));
  };

  iconConfig = {
    calendar: {
      size: '1.2rem',
      icon: CommunicationIcons.Calendar,
    },
    check: {
      size: '3rem',
      icon: UtilityIcons.Checkmark,
      fill: 'var(--accent)',
    },
  };
}
