<div class="container">
  <app-click-switch-header step="1" subtitle="Step 1 of 3" (back)="goBack()"></app-click-switch-header>
  <div class="row center">
    <p class="search-title text-center">Provide the source of your income</p>
    <div class="col-12 col-sm-8 text-center mt-4">
      <p class="search-subtitle">
        Enter the source of your income in the search bar. It might be an employer name, a retirement
        account, Social Security benefits, a pension fund, or something else.
      </p>
    </div>
  </div>
  <div class="row center m-t-lg">
    <div class="fi-search__textbox-container">
      <app-svg-icon
        class="fi-search__btn"
        alt="Search for a target"
        [iconName]="searchIcon"
        [styles]="searchIconStyles"
      ></app-svg-icon>
      <input
        id="search-target"
        name="search-target"
        class="uk-input fi-search__textbox"
        placeholder="Employer or Income Source"
        (ngModelChange)="searchTarget($event)"
        [(ngModel)]="search"
      />
    </div>
  </div>
  <app-dot-loader [showWhen]="isLoading"></app-dot-loader>
  <div *ngIf="targetResult && search" class="row justify-content-center mt-4">
    <div class="col-md-8">
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center mb-2"
          *ngFor="let target of targetsSelect"
          (click)="createTargetSwitch(target)"
        >
          <span class="target-result">
            {{ target.name }}
          </span>
          <app-svg-icon
            [iconName]="goIcon"
            [styles]="{ width: '0.7em', height: '0.7em' }"
          ></app-svg-icon>
        </li>
      </ul>
    </div>
    <div *ngIf="!showMoreResults && hasLocation" class="col-12">
      <div class="search-manually-title">Can't find the source you're looking for?</div>
      <div class="search-manually-link mt-3">
        <a (click)="showMore()">More Results</a>
      </div>
    </div>
    <div *ngIf="showMoreResults || !hasLocation" class="col-12">
      <div class="search-manually-title">Still looking?</div>
      <div class="search-manually-link mt-3">
        <a (click)="goSubmitTable()">Add "{{ search }}" Manually</a>
      </div>
    </div>
  </div>
</div>
