export const TAB_TITLE = 'Portfolio Allocation';

export const STOCK_COLORS = {
  BLUE_500: '#1E3860',
  GREEN_500: '#29B48B',
  TEAL_500: '#158994',
  GOLD_500: '#FAA74A',
  RED_500: '#C01349',
  PURPLE_500: '#5D2EB2',
  GRAY_500: '#E5E5E5'
};

export const BORDER_WIDTH = {
  SINGLE_ASSET: 0,
  MULTIPLE_ASSETS: 4,
};

export const EMPTY_CATEGORY_NAME = '--';

export const STRING_CURRENCY_VALUE_MAX_LEN = 12;