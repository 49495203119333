import { SupportAlertsModule } from './support-alerts/support-alerts.module';
import { SupportBillPayModule } from './support-bill-pay/support-bill-pay.module';
import { SupportDashboardModule } from './support-dashboard/support-dashboard.module';
import { SupportDepositsLoansModule } from './support-deposits-loans/support-deposits-loans.module';
import { SupportDepositsModule } from './support-deposits/support-deposits.module';
import { SupportPayItNowModule } from './support-pay-it-now/support-pay-it-now.module';
import { SupportProfileSettingsModule } from './support-profile-settings/support-profile-settings.module';
import { SupportTransfersModule } from './support-transfers/support-transfers.module';

export {
  SupportAlertsModule,
  SupportBillPayModule,
  SupportDashboardModule,
  SupportDepositsModule,
  SupportDepositsLoansModule,
  SupportPayItNowModule,
  SupportProfileSettingsModule,
  SupportTransfersModule,
};

export const supportSubmodules = [
  SupportAlertsModule,
  SupportBillPayModule,
  SupportDashboardModule,
  SupportDepositsModule,
  SupportDepositsLoansModule,
  SupportPayItNowModule,
  SupportProfileSettingsModule,
  SupportTransfersModule,
];
