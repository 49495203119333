import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  IncomingTransaction,
  LoanPaymentImpediment,
} from '@app/accounts/models';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { UserAction } from '@legacy/typings/app/UserAction';
import { WebApiResponse } from '@shared/models';

import { BaseService } from './base.service';
import { TransferSchedule } from '@legacy/transfers/typings/TransferSchedule';

@Injectable({
  providedIn: 'root',
})
export class LoanService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'loans');
  }

  getPendingPayments(accounts: number[], limitTo = 1) {
    const params = {
      accounts: `${accounts}`,
      limitTo: `${limitTo}`,
    };

    return this.get<IncomingTransaction[]>('transactions/pending', params).pipe(
      map(res => res.data.map(row => new IncomingTransaction(row)))
    );
  }

  getPaymentImpediments(accountId: number) {
    return this.get<LoanPaymentImpediment[]>(`pay/${accountId}/impediments`);
  }

  logUserAction(action: UserAction): WebApiResponse<void> {
    return this.post('audit', action);
  }

  removeACHTransferSeries(trans: TransferSchedule): WebApiResponse<any> {
    let params: string = '';
    for (const key in trans) {
      if (trans.hasOwnProperty(key) && trans[key]) {
        params += `${key}=${trans[key]}&`;
      }
    }

    return this.delete(`ach/schedule/series?${params}`);
  }

  removeNextACHTransfer(trans: TransferSchedule): WebApiResponse<any> {
    return this.delete(`ach/schedule/single?${JSON.stringify(trans)}`);
  }
}
