class AmountDirective implements ng.IDirective {
  restrict = 'A';
  require = 'ngModel';

  constructor() {}

  link = (_scope: ng.IScope, _element: ng.IAugmentedJQuery, _attrs: ng.IAttributes, _ctrl: any) => {
    _element.on('blur', () => {
      let value = _ctrl.$modelValue.replace(',', '');

      if (value && !isNaN(value)) {
        _element.val(formatAmount(value));
        _ctrl.$setViewValue(value);
      } else {
        _element.val('');
      }
    });

    _ctrl.$formatters.unshift(() => {
      if (_ctrl.$modelValue && !isNaN(_ctrl.$modelValue)) {
        return formatAmount(_ctrl.$modelValue);
      }

      return '';
    });

    _ctrl.$validators.validAmount = (_modelValue: any, _viewValue: any) => {
      _viewValue = _viewValue.replace(',', '');
      return /^\d{0,6}(\.\d{0,2})?$/.test(_viewValue) && parseFloat(_viewValue) > 0;
    };

    function formatAmount(value: string): string {
      value = value.replace(',', '');
      return parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
      });
    }
  };

  static factory(): ng.IDirectiveFactory {
    const directive = () => new AmountDirective();
    directive.$inject = [];
    return directive;
  }
}

export { AmountDirective };
