import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NinthWaveService } from '@core/services';
import { ROOT_SCOPE, STATE, STATE_PARAMS } from '@core/tokens';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { ModalService, ModalSettings } from '@legacy/services/modal.service';
import { NWFileDownloadTypeProperty } from '@legacy/typings/app/download-transaction/nw-file-download-type-property.interface';
import { NwFileFormatConfiguration } from '@legacy/typings/app/download-transaction/nw-file-format-configuration.interface';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-download-transactions',
  templateUrl: './download-transactions.component.html',
  styleUrls: ['./download-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadTransactionsComponent implements OnInit {
  accountId: number;
  accounts: AccountsPage;
  combinedAccounts: OlbAccount[];
  idsList: number[];
  showDropdown: boolean = false;
  isLoadingFileFormats: boolean;
  nwFileFormats: NWFileDownloadTypeProperty[];
  closedAccounts: OlbAccount[];

  constructor(
    @Inject(ROOT_SCOPE) private rootScope: ng.IRootScopeService,
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private cachedAccountsService: CachedAccountsService,
    private modalService: ModalService,
    private ninthWaveService: NinthWaveService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadFileFormats();
    this.loadAccountsAndCheckId();
  }

  private async loadAccountsAndCheckId(): Promise<void> {
    await this.onPaymentAccountsLoaded();
    await this.onClosedAccountsLoaded();

    // Get the accounts using the getFromAccounts method
    this.accounts = this.cachedAccountsService.internalAccounts;
    this.accountId = +this.params['id'];
    this.closedAccounts = this.rootScope['closedAccounts'] || [];

    // Check if internalAccounts and loanAccounts are not null before combining them
    this.combinedAccounts = (this.accounts?.depositAccounts || [])
    .concat(this.accounts?.loanAccounts || []).concat(this.closedAccounts);

    this.idsList = this.combinedAccounts.map(account => account.id);
    if (!this.idsList.includes(this.accountId)) {
      await this.showErrorMessage();
      return;
    }
    this.showDropdown = true;
    this.cdr.detectChanges();
  }

  onPaymentAccountsLoaded(): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.rootScope['accounts']) {
        resolve();
      } else {
        this.rootScope.$on('accountsLoaded', () => {
          resolve();
        });
      }
    });
  }

  onClosedAccountsLoaded(): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.rootScope['closedAccounts']) {
        resolve();
      } else {
        this.rootScope.$on('closedAccountsLoaded', () => {
          resolve();
        });
      }
    });
  }

  loadFileFormats() {
    this.isLoadingFileFormats = true;
    this.ninthWaveService
      .getFileFormats()
      .pipe(
        finalize(() => {
          this.isLoadingFileFormats = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe({
        next: (resp: OlbResponse<NwFileFormatConfiguration>) => {
          this.nwFileFormats = resp.data?.nwFileFormats;
        },
      });
  }

  async showErrorMessage(): Promise<void> {
    const defaultMessage = `<div><img src="/img/icons/illustration-area.svg" /></div>
      It seems like we've stumbled upon a little glitch. But we’re on it! We apologize for the inconvenience. 
      Please try again shortly.`;

    const modalSettings: ModalSettings = {
      headerText: 'Oops! A Tiny Hiccup.',
      bodyText: defaultMessage,
      okText: 'Okay',
      hasCancelButton: false,
      hasIcon: false,
      hasHeaderText: true,
      okButtonClass: 'new-button-okay',
      hasRedirectLink: true,
      linkUrl: '/Dashboard/Accounts',
    };

    await this.modalService.show({ windowClass: 'modal-service internal-error-modal' }, 
      modalSettings).then(() => {
      this.state.go('udb.accounts.dashboard');
      setTimeout(function () {
        location.reload();
      }, 500);
    });
  }
}
