import { PopoverComparerController } from './popover-comparer.controller';

const popoverComparer: ng.IComponentOptions = {
  controller: PopoverComparerController,
  controllerAs: '$ctrl',
  templateUrl: 'common/components/popover-comparer/popover-comparer.tpl.html',
  bindings: {
    model: '<',
    title: '@',
    filterTypeName: '@',
    elementType: '@?',
    elementDescription: '@',
    placement: '@?',
    placeholder: '@?',
    minAllowed: '@?',
    maxAllowed: '@?',
    inputClasses: '@?',
    buttonClasses: '@?',
    clearText: '@?',
    activeBgColor: '@?',
    elementActive: '=?',
    onOk: '&?',
    onCancel: '&?',
    onClear: '&?',
  },
};

export { popoverComparer };
