import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Filter } from '@shared/models';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() filter: Filter;

  @Output() remove = new EventEmitter<Filter>();

  constructor() {}

  removeFilter() {
    this.remove.emit(this.filter);
  }
}
