import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';
import { BaseService } from '@core/services/base.service';
import { GetPortfolioAllocationRequest, GetPortfolioAllocationResponse } from './types';

@Injectable({
  providedIn: 'root'
})
export class PortfolioAllocationService extends BaseService {
  /**
   * HoldingsSummaryService constructor
   *
   * @param settings - OlbSettings
   * @param httpClient - HttpClient
   */
  constructor(
    @Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'aas/accounts');
  }

  /**
 * Retrieves the portfolio allocation for a given account number.
 * 
 * @param getPortfolioAllocationRequest - The request object containing the account number and optional limit.
 * @returns A promise that resolves to a WebApiResponse containing the portfolio allocation response.
 */
  getPortfolioAllocation(getPortfolioAllocationRequest: GetPortfolioAllocationRequest): WebApiResponse<GetPortfolioAllocationResponse[]> {
    const { accountNumber , limit } = getPortfolioAllocationRequest;

    const url = limit
      ? `portfolioallocation/${accountNumber}/${limit}`
      : `portfolioallocation/${accountNumber}`;

    return this.get(url);
  }
}
