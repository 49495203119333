import { OlbEvents } from '@core/enums';

/**
 * Represents an OLB-specific event.
 */
export class OlbEvent<T> {
  /**
   * Creates a new OLB Event.
   * @param eventType Type of the event.
   * @param payload Any payload that the event needs to pass.
   */
  constructor(public eventType: OlbEvents, public payload: T) {}
}
