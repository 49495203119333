<h3 class="mt-5 mb-4">How do I use mobile deposit?</h3>

<p class="mb-3">
  With Mobile Banking, {{ settings.brandName }} customers can access and use many of the same
  features available through {{ settings.brandName }}’s Online Banking, such as reviewing account
  balances, transferring funds and paying bills.
</p>

<p class="mb-3">Our Mobile Banking offers you additional convenience with 24/7 accessibility.</p>
<p class="mb-2">
  To download the Mobile Banking App we invite you to choose one of the following options:
</p>

<ul>
  <li>
    Click
    <a
      href="https://www.axosbank.com/Tools/Payment-Apps/Mobile-App"
      target="_blank"
      rel="noreferrer noopener"
    >
      here
    </a>
    to download the
    <b>Mobile Banking App.</b>
  </li>
  <li>
    Or you can go directly to the App Store on your iPhone, iTunes&reg; or Android Market&trade; and
    search for
    <b>{{ settings.brandName }}.</b>
  </li>
</ul>
