import { Inject, Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { HttpClient } from '@angular/common/http';
import { WebApiResponse } from '@shared/models';
import { NwFileFormatConfiguration } from '@legacy/typings/app/download-transaction/nw-file-format-configuration.interface';
import { NinthWaveWebConnect } from '@app/accounts/components/download-transactions/ninth-wave-web-connect.interface';
import { FileResponse } from '@legacy/typings/app/download-transaction/file-response.interface';

@Injectable({
  providedIn: 'root',
})
export class NinthWaveService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    // Workaround necessary in order to hit NinthWave controller.
    settings.api = settings.api.replace('/api', '');
    super(http, settings, 'ninthwave');
  }

  /**
   * Retrieves the available file formats from the server.
   *
   * @return {WebApiResponse<NwFileFormatConfiguration>} The response containing the
   * file format configuration
   */
  getFileFormats(): WebApiResponse<NwFileFormatConfiguration> {
    return this.get(`fileformats`);
  }
  
  /**
   * Downloads transactions using the NinthWave Web Connect.
   *
   * @param {Partial<NinthWaveWebConnect>} request - the request object for the download
   * @return {WebApiResponse<FileResponse>} the response containing the downloaded file
   */
  TransactionsDownload(request: Partial<NinthWaveWebConnect>): WebApiResponse<FileResponse> {
    return this.post<FileResponse>(`nwinitiatewctransactionsdownload`, request);
  }
}
