import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { differenceInMinutes, format } from 'date-fns';

import { ensentaSessionLifeInMinutes, standardDateFormat } from '@app/config/constants';
import { RiskFactorModalComponent } from '@app/make-deposit/components/modals';
import { AmountLimitType } from '@app/make-deposit/enums';
import { RemoteDepositAccount, RemoteSessionRequest, RiskFactor, SingleDeposit } from '@app/make-deposit/models';
import { RemoteDepositService } from '@app/make-deposit/services';
import { getFundingState } from '@app/store/selectors';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { extractServerError } from '@app/utils';
import { OlbSettings } from '@core/models';
import { DialogService } from '@core/services';
import { olbSettings, ROOT_SCOPE, STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { RemoteDeposit as remoteDepositReceipt } from '@legacy/transfers/receipt/typings/ReceiptConstants';
import { AlertsIcons } from '@shared/enums';
import { CustomerDetail, DialogData } from '@shared/models';
import { hasNumberNotZeroValidator } from '@shared/utils/validators';

@Component({
  selector: 'app-make-deposit-view',
  templateUrl: './make-deposit-view.component.html',
  styleUrls: ['./make-deposit-view.component.scss'],
  providers: [CurrencyPipe],
})
export class MakeDepositViewComponent implements OnInit, OnDestroy {
  // #region Initialized properties
  apiErrorMessage: SafeHtml = null;
  icons = {
    exclamationCircle: AlertsIcons.ExclamationCircle,
  };
  errors = {
    depositToAccountNumberIndex: [{ name: 'required', message: 'You need to select an account.' }],
    amount: [{ name: 'number', message: 'Check amount is required and needs to be positive.' }],
    frontBase64Image: [{ name: 'required', message: 'Please upload a photo of the front of the check.' }],
    backBase64Image: [{ name: 'required', message: 'Please upload a photo of the back of the check.' }],
  };
  accountsAreLoading = true;
  depositInProgress = false;
  forcedRedirect = false;
  riskBand = false;
  // #endregion Initialized properties

  // #region Uninitialized properties
  depositAccounts: RemoteDepositAccount[];
  remoteSessionRequest: RemoteSessionRequest;
  depositForm: UntypedFormGroup;
  frontCheck: string;
  backCheck: string;
  sessionCreationDate: Date;
  closestLimit: number;
  isFundingStateRunning: boolean;
  singleDepositResponse: SingleDeposit;
  canDepositBatch: boolean;
  errorMessages: string[];
  selectedAccount: RemoteDepositAccount;
  userProfile: Partial<CustomerDetail>;
  // #endregion Uninitialized properties
  private subsink = new SubSink();
  private unsubscribeRootScopeListener: () => void;

  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    @Inject(STATE) private $state: ng.ui.IStateService,
    @Inject(ROOT_SCOPE) private $rootScope: ng.IRootScopeService,
    private domSanitizer: DomSanitizer,
    private fb: UntypedFormBuilder,
    private store: Store,
    private matDialog: MatDialog,
    private dialogService: DialogService,
    private remoteDepositService: RemoteDepositService,
    private serviceHelper: ServiceHelper,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.unsubscribeRootScopeListener = this.$rootScope.$on('$stateChangeStart', this.askBeforeRedirect.bind(this));

    this.depositForm = this.fb.group({
      depositToAccountNumberIndex: this.fb.control('', [Validators.required]),
      amount: this.fb.control(0, [Validators.required, hasNumberNotZeroValidator]),
      frontBase64Image: this.fb.control(null, [Validators.required]),
      backBase64Image: this.fb.control(null, [Validators.required]),
      accountId: this.fb.control(''),
      accountNickname: this.fb.control(''),
    });

    this.setupSessionRequest();
    this.signOn();

    this.subsink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => data !== null))
      .subscribe(data => {
        this.userProfile = data;
      });

    this.subsink.sink = this.depositForm.get('depositToAccountNumberIndex').valueChanges.subscribe({
      next: value => {
        const account = this.depositAccounts.find(acc => +acc.accountNumberIndex === +value);
        this.selectedAccount = account;

        this.depositForm.patchValue({
          accountId: account.tag,
          accountNickname: account.description,
        });
      },
    });
  }

  ngOnDestroy(): void {
    this.remoteDepositService
      .endSession(this.remoteSessionRequest)
      .subscribe({ error: this.serviceHelper.errorHandler.bind(this.serviceHelper) });

    this.subsink.unsubscribe();
    this.unsubscribeRootScopeListener();
  }

  setupSessionRequest(): void {
    const now = new Date();
    const location = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zoneAbbreviation = now.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    const utcOffset = format(now, 'xxx');

    this.remoteSessionRequest = new RemoteSessionRequest({
      userId: 0,
      depositorIpAddress: '',
      sessionId: '',
      localDateTime: now,
      timezone: `${zoneAbbreviation} ${utcOffset} ${location}`,
      deviceIdentifier: '',
      sessionToken: '',
    });
  }

  clearErrorMessages(): void {
    this.apiErrorMessage = null;
    this.errorMessages = [];
  }

  clearMessage(message: string): void {
    this.errorMessages = this.errorMessages.filter(item => item !== message);
  }

  submitCheck(): void {
    if (this.depositForm.invalid) {
      this.depositForm.markAllAsTouched();
    } else {
      if (this.sessionHasExpired()) {
        this.updateSessionAndResend();
      } else {
        this.toggleFormControls(true);
        const formValue = { ...this.depositForm.value } as RemoteSessionRequest;

        this.remoteSessionRequest = {
          ...this.remoteSessionRequest,
          ...formValue,
        };
        this.depositInProgress = true;

        this.remoteDepositService.uploadCheck(this.remoteSessionRequest).subscribe({
          next: res => {
            this.singleDepositResponse = res.data;
            this.remoteSessionRequest.batchId = res.data.batchId;
            this.remoteSessionRequest.sessionId = res.data.sessionId;
            this.canDepositBatch = res.data.canPostDepositBatch;
            const risks = res.data.depositItems[0].riskFactors.filter(risk => !risk.canBeOverridden);

            if (risks.length) {
              // Display all errors in separate alert boxes.
              this.errorMessages = risks.map(risk => risk.riskFactorLocalizedDescription);
              this.depositInProgress = false;
              this.toggleFormControls(false);
            } else {
              this.clearErrorMessages();

              const overridableRisks = res.data.depositItems[0].riskFactors.filter(risk => risk.canBeOverridden);

              this.processOverridableRisks(overridableRisks);
            }
          },
          error: err => {
            this.apiErrorMessage = this.domSanitizer.bypassSecurityTrustHtml(extractServerError(err));
            this.depositInProgress = false;
            this.toggleFormControls(false);
          },
        });
      }
    }
  }

  cancelOperation(): void {
    this.depositForm.reset({
      depositToAccountNumberIndex: null,
      amount: 0,
      frontBase64Image: null,
      backBase64Image: null,
    });
    this.frontCheck = null;
    this.backCheck = null;
    this.clearErrorMessages();

    if (this.isFundingStateRunning) {
      this.$state.go('udb.funding');
    }
  }

  cancelDepositBatch(): void {
    if (!this.riskBand) {
      this.riskBand = true;
      this.remoteDepositService.cancelDepositBatch(this.remoteSessionRequest).subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
      this.toggleFormControls(false);
    }
  }

  sendRemoteDeposit(): void {
    let redirectTo = 'udb.makedepositReceipt';
    this.depositInProgress = true;

    this.remoteDepositService
      .addDeposit(this.remoteSessionRequest)
      .pipe(
        finalize(() => {
          this.depositInProgress = false;
        })
      )
      .subscribe({
        next: res => {
          const receiptTemplate = { ...remoteDepositReceipt };
          const amount = this.currencyPipe.transform(res.data.submittedAmount);
          const details = receiptTemplate.transactions[0].details;
          details[0].value = this.selectedAccount.description;
          details[1].value = amount;
          details[2].value = format(new Date(), standardDateFormat);

          const receiptSettings = {
            amount,
            fromAccount: '',
            toAccount: this.selectedAccount.description,
            confirmationNumber: res.data.receiptReferenceNo,
            transactions: [{ confirmationNumber: res.data.receiptReferenceNo, details }],
            navigateTo: 'udb.makedeposit',
            confirmationEmail: this.userProfile.primaryEmail,
          };

          if (this.isFundingStateRunning) {
            redirectTo = 'udb.funding.receipt';
            // In the funding receipt, the confirmation number is displayed in the details table
            details[3] = {
              description: 'Confirmation Number',
              value: res.data.receiptReferenceNo,
            };
          }
          this.forcedRedirect = true;
          this.$state.go(redirectTo, {
            settings: receiptSettings,
            template: receiptTemplate,
          });
        },
        error: err => {
          this.apiErrorMessage = this.domSanitizer.bypassSecurityTrustHtml(extractServerError(err));
          this.toggleFormControls(false);
        },
      });
  }

  processOverridableRisks(risks: RiskFactor[]): void {
    if (!risks.length) {
      this.sendRemoteDeposit();
    } else {
      this.remoteSessionRequest.warnings = risks;
      // Show risks modal
      this.matDialog
        .open(RiskFactorModalComponent, {
          disableClose: true,
          data: risks.map(risk => risk.riskFactorLocalizedDescription),
        })
        .afterClosed()
        .subscribe(issuesResolved => {
          if (issuesResolved) {
            this.sendRemoteDeposit();
          } else {
            this.cancelDepositBatch();
            this.depositInProgress = false;
          }
        });
    }
  }

  loadFrontCheck(checkBase64: string): void {
    this.frontCheck = checkBase64;
    this.depositForm.get('frontBase64Image').patchValue(checkBase64);
  }

  loadBackCheck(checkBase64: string): void {
    this.backCheck = checkBase64;
    this.depositForm.get('backBase64Image').patchValue(checkBase64);
  }

  sessionHasExpired(): boolean {
    const sessionLife = Math.abs(differenceInMinutes(this.sessionCreationDate, new Date()));

    return sessionLife >= ensentaSessionLifeInMinutes;
  }

  updateSessionAndResend(): void {
    this.setupSessionRequest();

    this.remoteDepositService
      .signOn(this.remoteSessionRequest)
      .pipe(
        finalize(() => {
          this.depositInProgress = false;
        })
      )
      .subscribe({
        next: res => {
          this.remoteSessionRequest.sessionId = res.data.sessionStateId;
          this.remoteSessionRequest.sessionToken = res.data.sessionToken;
          this.sessionCreationDate = new Date();

          this.submitCheck();
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  private signOn(): void {
    this.remoteDepositService
      .signOn(this.remoteSessionRequest)
      .pipe(
        finalize(() => {
          this.accountsAreLoading = false;
        })
      )
      .subscribe({
        next: ({ data }) => {
          const { sessionStateId, sessionToken, accounts, isTermsAcceptanceRequired, amountLimits } = data;
          this.remoteSessionRequest.sessionId = sessionStateId;
          this.remoteSessionRequest.sessionToken = sessionToken;
          this.sessionCreationDate = new Date();
          this.depositAccounts = accounts;

          this.closestLimit = amountLimits.find(limit => limit.amountLimitType === AmountLimitType.Closest)?.available;

          this.getFundingState();

          if (isTermsAcceptanceRequired) {
            this.getTermsAndConditions();
          }
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  private getFundingState() {
    this.subsink.sink = this.store
      .select(getFundingState)
      .pipe(filter(fundingState => fundingState.isRunning))
      .subscribe({
        next: fundingState => {
          this.isFundingStateRunning = fundingState.isRunning;

          const selectedAccount = this.depositAccounts.find(
            account => account.tag === fundingState.accountId.toString()
          );

          const accountField = this.depositForm.get('depositToAccountNumberIndex');
          accountField.patchValue(selectedAccount.accountNumberIndex);
          accountField.markAsTouched({ onlySelf: true });
        },
      });
  }

  private getTermsAndConditions(): void {
    this.remoteDepositService.getTermsAndConditions(this.remoteSessionRequest).subscribe({
      next: res => {
        const termsText = res.data.replace(/(?:\r\n|\r|\n)/g, '<br />');
        const dialogData = new DialogData({
          headerTitle: 'Terms & Conditions',
          hasIcon: false,
          title: 'Remote and Mobile Deposit',
          okText: 'Accept',
          content: `<p class="mb-4 px-2 text-justify" style="color:#6c7780;">${termsText}</p><small>By pressing accept you are agreeing to these Terms & Conditions</small>`,
          hasCloseButton: true,
        });

        this.dialogService
          .open(dialogData)
          .afterClosed()
          .subscribe({
            next: accepted => {
              // Accepted can be null, therefore we need to be specific in the if-else clause
              if (accepted === false) {
                this.rejectTermsAndConditions();
              } else if (accepted === true) {
                this.acceptTermsAndConditions();
              }
            },
          });
      },
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
    });
  }

  private acceptTermsAndConditions(): void {
    this.remoteDepositService.acceptTermsAndConditions(this.remoteSessionRequest).subscribe({
      next: ({ data }) => {
        this.remoteSessionRequest.sessionId = data.sessionId;
        this.remoteSessionRequest.sessionToken = data.sessionToken;
      },
      error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
    });
  }

  private rejectTermsAndConditions(): void {
    let state = 'udb.dashboard';
    if (this.isFundingStateRunning) {
      state = 'udb.funding';
    }

    this.forcedRedirect = true;
    this.$state.go(state);
  }

  private showRedirectConfirmationModal(): Observable<boolean | null> {
    const dialogData = new DialogData({
      title: 'Cancel Remote Deposit',
      content: 'Are you sure you want to cancel the deposit?',
      okText: 'Yes',
      cancelText: 'No',
    });

    return this.dialogService.open(dialogData).afterClosed();
  }

  private askBeforeRedirect(event: ng.IAngularEvent, toState: ng.ui.IState): void {
    if (!this.forcedRedirect && this.depositForm.dirty) {
      event.preventDefault();
      this.showRedirectConfirmationModal().subscribe({
        next: confirmed => {
          if (confirmed) {
            this.forcedRedirect = true;
            this.$state.go(toState.name);
          }
        },
      });
    }
  }

  private toggleFormControls(disable: boolean): void {
    if (disable) {
      document.getElementById('deposit-account').setAttribute('disabled', 'disabled');
      document.getElementById('amount').setAttribute('disabled', 'disabled');
    } else {
      document.getElementById('deposit-account').removeAttribute('disabled');
      document.getElementById('amount').removeAttribute('disabled');
    }
  }
}
