import * as angular from 'angular';
import { MessagesConfig } from './messages.config';
import { MessagesController } from './messages.controller';
import { InboxModule } from './inbox/inbox.module';
import { ComposeMessageModule } from './compose-message/compose-message.module';
import { MessageDetailModule } from './message-detail/message-detail.module';
import { MessagesComponent } from '@app/messages/components';
import { downgradeComponent } from '@angular/upgrade/static';
export const MessagesModule = angular
  .module('udb.messages', ['ui.router', InboxModule, ComposeMessageModule, MessageDetailModule])
  .directive('appMessagesComponent', downgradeComponent({ component: MessagesComponent }))
  .controller('MessagesController', MessagesController)
  .config(MessagesConfig).name;
