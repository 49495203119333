<div class="payment-options">
  <div class="dropdown">
    <span
      class="dropdown-toggle ut-btn-options"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      aria-label="Payment Options"
    >
      <i class="bofi-gear"></i>
    </span>
    <ul class="dropdown-menu ut-options-menu">
      <li *ngIf="payment.paymentFrequency !== 0" (click)="editPayment(false)">
        <a>
          <i class="bofi-edit"></i>
          Edit Next Payment
        </a>
      </li>
      <li (click)="deleteNextPayment()" *ngIf="payment.paymentFrequency !== 0">
        <a>
          <i class="bofi-close"></i>
          Cancel Next Payment
        </a>
      </li>
      <li (click)="editPayment(payment.paymentFrequency !== 0)">
        <a>
          <i class="bofi-edit"></i>
          {{ editOptionText }}
        </a>
      </li>
      <li (click)="deletePayment()">
        <a>
          <i class="bofi-close"></i>
          {{ deleteOptionText }}
        </a>
      </li>
    </ul>
  </div>
</div>
