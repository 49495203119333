import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { IScope } from 'angular';
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  isValid,
  parseISO,
} from 'date-fns';

import { ActivateAccountModalComponent } from '@app/accounts/components/modals';
import { AggregatedAccount } from '@app/accounts/models';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { InitializePdpInputType } from '@app/Areas/AAS/features/product-details-page/facade/types';
import { olbSettings, ROOT_SCOPE, STATE } from '@core/tokens';
import { AccountCategory } from '@legacy/accounts/account-category.enum';
import { AccountTabs } from '@legacy/accounts/account-tabs.enum';
import { accountType } from '@legacy/accounts/account-type.enum';
import { containerType } from '@legacy/accounts/container-type.enum';
import { ExternalAccountsHelper } from '@legacy/accounts/external-accounts/external-accounts.helper';
import { GroupTitle } from '@legacy/accounts/groupTitle.enum';
import { RewardUnits } from '@legacy/accounts/rewardUnits.enum';
import { FastlinkOauthMigrationStatuses } from '@legacy/dashboard/account-aggregation/enums/fast-link-Oauth-Migration-Statuses-enum';
import { FastlinkUpdateEligibility } from '@legacy/dashboard/account-aggregation/enums/fast-link-Update-Eligibility-enum';
import { ExternalAccountStatus } from '@legacy/layout/external-account-status.enum';
import { AccountAggregationChartFormatterService } from '@legacy/services/account-aggregation-chart-formatter.service';
import { AccountAggregationService } from '@legacy/services/account-aggregation.service';
import { BalanceService } from '@legacy/services/balance.service';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ModalService } from '@legacy/services/modal.service';
import { AxosInvestHelperService } from '@app/core/services/axos-invest.service';
import { ExternalBankProvider } from '@shared/models';
import { FastlinkOauthMigrationStatus } from '@legacy/dashboard/account-aggregation/enums/fast-link-Oauth-Migration-Status-enum';
import { AccountsService, ProviderService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { finalize } from 'rxjs/operators';
import { IStateService } from 'angular-ui-router';
import { BrandingSettingsFacade, BrandingStateType } from '@app/Areas/AAS/aas-core/branding-settings';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AccountDetailsService } from '@app/Areas/AAS/features/account-details/core';

// ! DO NOT DELETE FOR TESTING PURPOSES
declare var $: any;
@Component({
  selector: 'app-account-list-group',
  templateUrl: './account-group.component.html',
  styleUrls: ['./account-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountGroupComponent implements OnInit, OnChanges {
  get internal(): AggregatedAccount[] {
    if (this.accounts) {
      return this.accounts.filter(account => !account.isExternal);
    } else return [];
  }
  @Input() accounts: AggregatedAccount[];
  isRedirecting: boolean;
  @Input() actionText: string;
  @Input() actionLink: string;
  @Input() defaultTab: number;
  @Input() trasferSection: boolean;
  @Input() trasferMesgtool: string;
  @Input() displayAmount: boolean;
  totalAmount: number = 0;
  @Output() saveSorting = new EventEmitter<any>();
  external: any;
  accountBrandName: string;
  @Output() onAccountOperation = new EventEmitter<{ message: string }>();
  @Output() onHandleNicknameUpdate = new EventEmitter<{ accountId: number; isAnUpdate: boolean }>();
  /** Indicates if it's editing the nickname */
  isEditing = false;
  /** Flag to validate if the user has pressed the Save button */
  mouseDown = false;
  /** Indicates if it's saving the nickname */
  isSaving = false;
  selectedAccount: AggregatedAccount;
  balanceLoading = true;
  areClosedAccounts = false;
  isAccountAggregationEnhancementsActive = false;
  accountNickname: string;
  accountCategory = AccountCategory;
  accountType = accountType;
  containerType = containerType;
  groupTitleEnum = GroupTitle;
  rewardUnits = RewardUnits;
  providers: Provider[];
  @Input() groupTitle: string;
  @Input() pendingActivation: boolean;
  isShowIconMessageAndLink = false;
  externalAccountStatus = ExternalAccountStatus;
  allBrandingSettings: BrandingStateType[] = [];
  allRiaAccounts: RiaType[] = [];
  hasCustomSecondaryLogo: boolean;
  authorizeTransfersAAS = false;
  readonly extAccPendingActivation = 'PendingActivation';
  readonly extAccountPendingActivation = 'Pending Activation';
  readonly extAccInProcess = 'InProcess';
  readonly extAccountInProcess = 'In Process';
  readonly extAccActive = 'Active';
  readonly extAccFailedVerification = 'FailedVerification';
  readonly extAccountFailedVerification = 'Failed Verification';
  dialogConfig = new MatDialogConfig();
  modalDialog: MatDialogRef<ActivateAccountModalComponent, any> | undefined;
  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(olbSettings) private readonly env: OlbSettings,
    @Inject(ROOT_SCOPE) private readonly _root: ng.IRootScopeService,
    @Inject('$scope') private readonly scope: IScope,
    private readonly accountsService: AccountsService,
    private readonly providerService: ProviderService,
    private readonly serviceHelper: ServiceHelper,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly externalAccountsHelper: ExternalAccountsHelper,
    private readonly featureFlagService: FeatureFlagService,
    private readonly modalService: ModalService,
    private readonly accountAggregationService: AccountAggregationService,
    private readonly balanceService: BalanceService,
    private readonly matDialog: MatDialog,
    private readonly accAggChartFormatterService: AccountAggregationChartFormatterService,
    private readonly pdpFacade: PdpFacade,
    private readonly brandingSettingsFacade: BrandingSettingsFacade,
    private readonly riaFacade: RiaFacade,
    private cd: ChangeDetectorRef,
    private readonly accountDetailsService: AccountDetailsService
  ) {}

  /** Initializes the required data */
  ngOnInit(): void {
    this.isAccountAggregationEnhancementsActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
    this.authorizeTransfersAAS = this.featureFlagService.isRiaAuthorizeTransfersActive();
    this.getProviders();
    this.areClosedAccounts =
      this.internal.length && this.internal.every((ac: AggregatedAccount) => ac.status.toLowerCase() === 'closed');

    if (this._root['balancesAvailable']) {
      this.balanceLoading = false;
    } else {
      this.scope.$on('balancesAvailable', () => (this.balanceLoading = false));
    }

    if (this._root['brandProperties']) {
      this.accountBrandName = this._root['brandProperties'].AccountsBrandName;
    } else {
      this._root.$on('brandPropertiesLoaded', () => {
        this.accountBrandName = this._root['brandProperties'].AccountsBrandName;
      });
    }

    if (this.pendingActivation) {
      // There area external accounts with pending activation
      this.raiseActivationPopup(null);
    }

    this.brandingSettingsFacade.allBrandingSettings$.subscribe(brandings => {
      this.allBrandingSettings = brandings;
    });

    this.riaFacade.allRias$.subscribe(rias => {
      this.allRiaAccounts = rias;
    });
  }

  ngOnChanges() {
    if (
      this.accounts &&
      this.accounts.length &&
      this.accounts.some(a => !!a.availableBalance || a.availableBalance === 0)
    ) {
      this.totalAmount = this.accounts.reduce((prev: number, next: AggregatedAccount) => {
        return this.calculateBalance(prev, next);
      }, 0);
    }
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.saveSortedAccounts(this);
  }

  showIconMessageAndLink(acc: AggregatedAccount) {
    const aggActive = this.isAccountAggregationEnhancementsActive;
    const external = aggActive && acc.isExternal;
    const verificationStatuses = [
      FastlinkOauthMigrationStatuses.CONSENT_EXPIRED,
      FastlinkOauthMigrationStatuses.INCORRECT_OAUTH_TOKEN,
      FastlinkOauthMigrationStatuses.CONSENT_REVOKED,
      FastlinkOauthMigrationStatuses.CONSENT_REQUIRED,
    ];
    const verificationStatusesOnlyMessage = [
      FastlinkOauthMigrationStatuses.OAUTH_MIGRATION_FAILED,
      FastlinkOauthMigrationStatuses.MIGRATION_IN_PROGRESS,
    ];

    // Check if we should show icon, message and link
    const checkEligibility = acc.updateEligibility === FastlinkUpdateEligibility.ALLOW_UPDATE_WITH_CREDENTIALS;
    const credentialsAndUpdate = acc.credentialsHaveChanged && checkEligibility;
    const migrationToBe = acc.oAuthMigrationStatus === FastlinkOauthMigrationStatus.To_Be_Migrated && external;
    let migrationCompleted = false;

    verificationStatuses.forEach(verificationStatus => {
      if (acc.oAuthMigrationAdditionalStatuses) {
        const accountStatuses = acc.oAuthMigrationAdditionalStatuses.split(',');
        if (accountStatuses.includes(verificationStatus.toString())) {
          migrationCompleted = true && acc.oAuthMigrationStatus === FastlinkOauthMigrationStatus.Complete && external;
        }
      }
    });

    // check if we should show icon and message
    const credentialsAndDisallowUpdate =
      acc.credentialsHaveChanged && acc.updateEligibility === FastlinkUpdateEligibility.DISALLOW_UPDATE;
    const migrationToBeOnlyMessage = acc.oAuthMigrationStatus === FastlinkOauthMigrationStatus.To_Be_Migrated;
    let migrationCompletedOnlyMessage = false;

    verificationStatusesOnlyMessage.forEach(verificationStatus => {
      if (acc.oAuthMigrationAdditionalStatuses) {
        const accountStatuses = acc.oAuthMigrationAdditionalStatuses.split(',');
        if (accountStatuses.includes(verificationStatus.toString())) {
          migrationCompletedOnlyMessage = true && acc.oAuthMigrationStatus === FastlinkOauthMigrationStatus.Complete;
        }
      }
    });

    this.isShowIconMessageAndLink = credentialsAndUpdate || migrationToBe || migrationCompleted;

    return (
      credentialsAndUpdate ||
      migrationToBe ||
      migrationCompleted ||
      credentialsAndDisallowUpdate ||
      migrationToBeOnlyMessage ||
      migrationCompletedOnlyMessage
    );
  }

  getImage = (account: AggregatedAccount) =>
    this.accAggChartFormatterService.getImage(
      this.isAccountAggregationEnhancementsActive,
      this.providers as ExternalBankProvider[],
      this.env,
      account,
      this.allBrandingSettings,
      this.allRiaAccounts
    );

  hasImage = (account: AggregatedAccount) =>
    this.accAggChartFormatterService.hasImage(this.providers as ExternalBankProvider[], account);

  getProviders() {
    this.providerService
      .getProviders()
      .pipe(
        finalize(() => {
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: res => {
          this.providers = res;
          this._root['providers'] = this.providers;
        },
        error: err => {
          if (err.status !== 404) {
            this.serviceHelper.errorHandler(err);

            return;
          }
        },
      });
  }

  getBankProfile(account: AggregatedAccount) {
    const defaultBankName = 'Advisor Managed Account';
    const name = account.bankName || account.advisoryName || defaultBankName;
    return this.accAggChartFormatterService.getBankProfile(name);
  }

  /**
   * Redirects either the account details page or the external accounts view
   * @param id Account unique identifier
   * @param isExternal Indicator if the account is external
   * @param container Indicates the container to get its details
   */
  goToDetails(account: AggregatedAccount) {
    let state;
    let accountType = '';

    if (account.isAxosInvest) {
      state = 'udb.accounts.container';
      accountType = 'Invest';
    } else if (account.isTrading) {
      state = 'udb.accounts.container';
      accountType = 'Trading';

      if (+account.active) {
        this._root['accountContainer-tab'] = AccountTabs.Overview;
      } else {
        this._root['accountContainer-tab'] = AccountTabs.AccountDetails;
      }
    } else if (account.isAxosAdvisory) {
      state = 'udb.productDetailsPage';
      accountType = 'Ria';
      const initializePdpInputType: InitializePdpInputType = {
        axosAdvisoryAccount: {
          accountBalance: account.availableBalance,
          accountNumber: account.accountNumber,
          accountType: account.accountType,
          accountTypeCode: account.accountTypeCode,
          advisorName: account.advisoryName,
          brandingName: account.brandingName,
          displayName: account.displayedName,
          isRetirement: account.isRetirement,
          riaId: account.riaId,
          status: account.status,
          firstDepositDate: account.firstDepositDate,
          accountDisplayName: account.displayedName,
          accountNickname: account.nickname,
          type: account.type,
          routingNumber: '',
          bankName: '',
          productType: '',
          availableCash: account.availableCash,
          clientPortalStatus: account.clientPortalStatus,
          dateTerminated: account.dateTerminated,
          dateCloseInitiated: account.dateCloseInitiated,
        },
      };
      this.pdpFacade.initializePdp(initializePdpInputType);
    } else {
      const isAccAggrEnhancementActive = this.featureFlagService.isAccountAggregationEnhancementsActive();
      if (account.isExternal && isAccAggrEnhancementActive) state = 'udb.accounts.external-details';
      else if (!account.isExternal) state = 'udb.accounts.details';
    }

    if (
      (this.defaultTab === undefined || this.defaultTab === 0) &&
      account.accountType?.toLowerCase() === 'loan' &&
      !account.isOpen
    ) {
      this.defaultTab = 1;
    }

    this.state
      .go(state, {
        id: account.id,
        type: accountType,
        container: account.container,
        tab: this.defaultTab,
        accountNumber: account.accountNumber,
      })
      .then(() => {
        if (account.isAxosAdvisory) {
          scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      });
  }

  goToRestoreConnection(acc) {
    this.state.go('udb.dashboard.account-aggregation.auth', {
      bankId: acc.providerId,
      updateCredentials: true,
      providerAccountId: acc.providerAccountId,
      accountNickName: this.getNickName(acc),
      flow: 'edit',
      accountSelected: acc,
    });
  }

  getNickName(account: AggregatedAccount) {
    if (account.nickname) {
      return account.nickname;
    } else {
      return account.name || account.bankName;
    }
  }

  getBankName(account: AggregatedAccount) {
    let accountName = '';
    if (account.isTrading) {
      return account.bankName;
    }

    if (account.isAxosAdvisory) {
      const riaDefaultName = 'Advisor Managed Account';
      const riaName = account.advisoryName || riaDefaultName;
      return `${riaName} - ${this.formatAccountMask(account.accountNumber, false)}`;
    }

    if (!account.transferOnly) {
      if (!account.isAxosInvest) {
        switch (this.groupTitle) {
          case 'Others':
            // reward account types do not have account number or mask
            if (account.accountMask != null) {
              accountName = `${account.bankName} ${this.formatAccountMask(account.accountMask)}`;
            } else accountName = account.bankName;
            break;
          default:
            accountName = `${account.isExternal ? account.bankName : this.accountBrandName} ${this.formatAccountMask(
              account.accountMask
            )}`;
        }

        return accountName;
      } else {
        return `${AxosInvestHelperService.AXOS_INVEST} | ${account.bankName} ${
          account.accountMask ? '*' + account.accountMask : ''
        }`;
      }
    } else {
      if (account.accountNumber != null) {
        accountName = `${account.bankName} *${account.accountNumber.substr(account.accountNumber.length - 4)}`;
      } else accountName = `${account.bankName} *${account.accountMask}`;

      return accountName;
    }
  }

  goToMoveMoney(account: AggregatedAccount) {
    this.state.go('udb.transfers.transferFunds', { id: account.externalAccountId });
  }

  removeAccount(account: AggregatedAccount): void {
    const externalAccount = this.converToExternalAccount(account);
    this.externalAccountsHelper
      .showRemovalConfirmation(externalAccount)
      .then((result: boolean) => {
        if (!result) return;

        this.handleResult(account, 'removed');
      })
      .catch(this.serviceHelper.errorHandler);
  }
  /**
   * Triggered when the user clicks outside the nickname's entry box and has not clicked
   * the Save button, then the nickname editing is cancelled
   */
  cancelNicknameUpdate(): void {
    if (!this.mouseDown) {
      this.onHandleNicknameUpdate.emit({ accountId: 0, isAnUpdate: false });
    }
  }

  editNickname(account: AggregatedAccount): void {
    const accountId = account.id;
    this.accountNickname = account.nickname;
    this.onHandleNicknameUpdate.emit({ accountId, isAnUpdate: true });

    setTimeout(() => {
      angular.element('#nickname').focus();
    }, 200);
  }

  saveAccountNickname(account: AggregatedAccount): void {
    if (!account.nickname || account.nickname === this.accountNickname) {
      this.onHandleNicknameUpdate.emit({ accountId: 0, isAnUpdate: false });

      return;
    }

    this.isSaving = true;

    let updateExternalAccountPromise: any;

    if (account.isAuthorizeTransfer) {
      updateExternalAccountPromise = this.accountDetailsService.updateAasAccountData({
        accountNumber: account.externalAccountId.toString(),
        accountNickname: account.nickname,
      });
    } else {
      updateExternalAccountPromise = this.accountsService.updateExternalAccount(
        account.externalAccountId,
        account.nickname
      );
    }

    updateExternalAccountPromise
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          const externalAccount = this.converToExternalAccount(account);
          this.cachedAccountsService.updateExternalAccount(externalAccount);
          this.handleResult(account, 'updated');
        },
        error: this.serviceHelper.errorHandler,
      });
  }
  getFinancialInstitution(account: AggregatedAccount): string {
    let financialInstitution = ' ';

    if (account.accountType === this.accountType.RewardPoints) {
      financialInstitution = account.bankName;
    } else {
      let accountMask = '';

      if (account.accountMask != null) {
        accountMask = this.formatAccountMask(account.accountMask);
      } else if (account.accountNumber != null) {
        accountMask = this.formatAccountMask(account.accountNumber);
      }

      financialInstitution = `${account.bankName}  ${accountMask}`;
    }

    return financialInstitution;
  }

  getTimePeriod(date: string): string {
    let description = '';
    const today = new Date();
    const givenDate = this.parseLastUpdate(date);

    const daysDifference = differenceInDays(today, givenDate);
    const weeksDifference = differenceInWeeks(today, givenDate);
    const monthsDifference = differenceInMonths(today, givenDate);
    const yearsDifference = differenceInYears(today, givenDate);

    switch (true) {
      case yearsDifference >= 1:
        description = `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ago`;
        break;
      case monthsDifference >= 1:
        description = `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
        break;
      case weeksDifference >= 1:
        description = `${weeksDifference} week${weeksDifference > 1 ? 's' : ''} ago`;
        break;
      default:
        if (daysDifference === 0) description = 'Today';
        else description = `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
        break;
    }

    return description;
  }

  parseLastUpdate(date: string) {
    const lastUpdateInLocalTimeZone = parseISO(date + (date.endsWith('Z') ? '' : 'Z'));

    if (!isValid(lastUpdateInLocalTimeZone)) return null;

    return lastUpdateInLocalTimeZone;
  }

  getRewardUnits(account: AggregatedAccount): string {
    return this.balanceService.getRewardUnits(account);
  }

  removeAggregatedAccount(account: AggregatedAccount) {
    let accountType = '';
    let accountMask = '';

    if (account.accountType != null) {
      this.containerType;
      if (account.accountType == this.accountType.RewardPoints) {
        accountType = account.container.charAt(0).toUpperCase() + account.container.slice(1).toLocaleLowerCase();
      } else {
        accountType = account.accountType.charAt(0).toUpperCase() + account.container.slice(1).toLocaleLowerCase();
      }
    }

    if (account.accountMask != null) {
      accountMask = this.formatAccountMask(account.accountMask);
    } else accountMask = account.nickname;

    const warning =
      account.status.toLowerCase() === 'active'
        ? `<br> You will no longer be able to see account details through ${this.env.brandName} Online Banking.</p>`
        : '';
    // You will no longer be able to see account details through Axos Online Banking.
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-information',
          bodyText: `<h3>Remove account?</h3><p class="hidden-sm ms-secondary-text">Remove your ${account.bankName} account (${accountType}  ${accountMask}) from ${this.env.brandName} Online Banking?${warning}`,
          cancelText: 'No',
          okText: 'Yes',
        }
      )
      .then(() => {
        this.deleteAccount(account);
      });
  }

  /**
   * Removes an aggregated account
   * @param account Account to be removed.
   */
  deleteAccount(account: AggregatedAccount) {
    account['isRemoving'] = true;
    this.accountAggregationService
      .deleteAccount(account.id.toString(), !!account.nickname ? account.nickname : account.name)
      .then(() => {
        this.cachedAccountsService.removeAggregatedAccount(account.id);
        this.handleResult(account, 'removed');
      })
      .catch(error => {
        this.serviceHelper.errorHandler(error);
        account['isRemoving'] = false;
      });
  }
  /**
   * Opens a modal to activate the external account passed as argument
   * @param externalAccount The external account to be verified for activation
   */
  raiseActivationPopup = (aggregatedAccount: AggregatedAccount) => {
    // The accounts shown in All Account Page for Transfer Only Section were parse to
    // AggregatedAccount in loadExternalAccounts/toAggregatedAccount, go to cach and retrive externalaccount
    let externalAccount: ExternalAccount;

    const externalAccounts = [...this.cachedAccountsService.externalAccounts];
    if (aggregatedAccount !== null) {
      externalAccount = externalAccounts.find((x: ExternalAccount) => x.externalAccountId === aggregatedAccount.id);
    } else {
      externalAccount = externalAccounts
        .sort((first: ExternalAccount, second: ExternalAccount) =>
          first.displayName.toLowerCase() > second.displayName.toLowerCase() ? 1 : -1
        )
        .find(a => a.status === this.externalAccountStatus.PendingActivation);

      aggregatedAccount = this.accounts.find(
        (account: AggregatedAccount) => account.id === externalAccount?.externalAccountId
      );
    }

    const dialogRef = this.matDialog.open(ActivateAccountModalComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: { account: { ...externalAccount } },
    });

    const sub = dialogRef.componentInstance.deleteAccount.subscribe(() => {
      dialogRef.close();
      this.removeAccount(aggregatedAccount);
    });

    dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
      if (result) {
        const message = `You have successfully verified your ${externalAccount.bankName} account (${externalAccount.accountCategory} *${externalAccount.accountMask})`;
        externalAccount.status = 'Active';
        this.cachedAccountsService.updateExternalAccount(externalAccount);
        this.onAccountOperation.emit({ message });
      }
    });
  };

  getBalance(account: AggregatedAccount): number {
    return this.balanceService.getBalance(account);
  }

  private calculateBalance(prev: number, next: AggregatedAccount): number {
    return prev + this.getBalance(next);
  }

  /**
   * Stores the new order making a call to the responsible service
   * @param evt The event triggered when the order is changed
   */
  private saveSortedAccounts(_evt: any): void {
    this.saveSorting.emit(this.accounts);
  }

  private handleResult(account: AggregatedAccount, action: string): void {
    let accountMask = '';
    let productType = '';

    if (account.productType != null) productType = account.productType;
    else {
      if (account.accountType != null) {
        if (account.accountType === this.accountType.RewardPoints) {
          productType = account.container.charAt(0).toUpperCase() + account.container.slice(1).toLocaleLowerCase();
        } else {
          productType = account.accountType.charAt(0).toUpperCase() + account.container.slice(1).toLocaleLowerCase();
        }
      }
    }

    if (account.accountNumber != null) {
      accountMask = this.formatAccountMask(account.accountNumber);
    } else if (account.accountMask != null) {
      accountMask = this.formatAccountMask(account.accountMask);
    } else {
      if (account.nickname != null) accountMask = account.nickname;
      else accountMask = account.name;
    }

    const accountDesc = `${account.bankName} account (${productType} ${accountMask})`;

    const message = action.includes('updated')
      ? `Your changes to ${accountDesc} have been saved`
      : `${accountDesc} removed successfully`;

    this.onAccountOperation.emit({ message });
  }

  private converToExternalAccount(account: AggregatedAccount): ExternalAccount {
    let externalAccount: ExternalAccount;
    let accountMask = '';
    if (account.accountNumber != null) {
      accountMask = this.formatAccountMask(account.accountNumber, false);
    } else accountMask = account.nickname;

    externalAccount = {
      ...account,
      accountMask,
      accountCategory: account.productType,
      displayName: account.bankName + ' - ' + account.nickname,
    };

    return externalAccount;
  }

  private formatAccountMask(accountNumber: string, includeAsterisk = true) {
    if (!accountNumber || accountNumber.length < 4) return '';
    const result = includeAsterisk ? '*' : '';

    return result + accountNumber.substr(accountNumber.length - 4);
  }
}
