import { GenericOption } from '../../../models/generic-options.model';
import { DisputeDataTransaction } from './dispute-data-transaction.model';
import { TransactionFilter } from './transaction-filter.model';
import { Transaction } from './transaction.model';

export class DisputeRedirectionDataTransaction {
  /** The account number of the dispute */
  accountNumber: number;
  /** Data corresponding to dispute transaction */
  disputeData: DisputeDataTransaction;
  /** Data of the transaction in dispute */
  transaction: Transaction[];
  /** Card status */
  cardsDisplay: any;
  /** Account Details */
  accountDetail: any;
  /** Transactions filter */
  filters: TransactionFilter;
  /** Advanced options selection */
  advancedOptions: GenericOption;
  /** Tag filters array */
  tagFilters: GenericOption[];

  constructor(args?: Partial<DisputeRedirectionDataTransaction>) {
    Object.assign(this, args);
  }
}
