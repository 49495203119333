import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BillPayRouterHelper } from '@app/bill-pay/utils';
import { Biller, PartialRecipient } from '@app/bill-pay/typings';
import { BillPayService } from '@legacy/services/bill-pay.service';
import { IStateService } from 'angular-ui-router';

import { STATE } from '@core/tokens';
import { BILLPAYSERVICE, BILLPAYROUTERHELPER, billPayServiceProvider } from '@app/bill-pay/ajs-upgraded-provider';

@Component({
  selector: 'app-electronic-address',
  templateUrl: './electronic-address.component.html',
  styleUrls: ['./electronic-address.component.scss'],
  providers: [billPayServiceProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElectronicAddressComponent implements OnInit {
  biller: Biller;
  recipient: PartialRecipient;
  billerAddresses: BillerAccountValidationAddress[];
  isBusy: boolean;

  constructor(
    @Inject(STATE) private readonly state: IStateService,
    @Inject(BILLPAYROUTERHELPER) private readonly billPayRouterHelper: BillPayRouterHelper,
    @Inject(BILLPAYSERVICE) private readonly billPayService: BillPayService
  ) {}

  ngOnInit(): void {
    this.biller = this.billPayRouterHelper.getBiller();
    this.recipient = this.billPayRouterHelper.getRecipient();
    this.billerAddresses = this.billPayRouterHelper.getBillerAddresses();
    if (!this.biller || !this.billerAddresses || !this.recipient) this.state.go('udb.billPay.searchRecipient');
  }

  selectAddress(selectedAddress: BillerAccountValidationAddress): void {
    if (!this.isBusy) {
      this.recipient.addressLine = selectedAddress.addressLine1;
      this.recipient.zipCode = selectedAddress.zipCode;
      this.recipient.city = selectedAddress.city;
      this.recipient.stateCode = selectedAddress.stateCode;
      this.recipient.country = selectedAddress.country;
      this.isBusy = true;
      this._saveRecipient();
    }
  }

  cancelSetup() {
    this.billPayRouterHelper.cancelSetup();
  }

  private _saveRecipient(): void {
    this.isBusy = true;

    this.billPayService
      .saveElectronicRecipient(this.recipient)
      .then(response => {
        this.recipient.payeeId = response.data.payee.payeeId;
        this.recipient.ebillStatus = response.data.payee.eBillsStatus;
        this.billPayRouterHelper.setRecipient(this.recipient);
        if (this.recipient.ebillStatus === 1) this.state.go('udb.billPay.electronicCheckpoint');
        else this.state.go('udb.billPay.electronicConfirmation');
      })
      .catch(() => {
        this.state.go('udb.billPay.billingDetails');
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  changeRouteState(route: string) {
    this.state.go(route);
  }
}
