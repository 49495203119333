import {
  AppDefaultPreferences,
  UserPreferenceState,
  UserPreferencesState,
} from '@app/Areas/AAS/aas-shared/features/user-preferences';
import { Holding, THoldingSortDirectionValues, THoldingSortTypeValues } from '../../store';
import {
  HoldingSortDirectionName,
  HoldingSortType,
  HoldingSortTypePreferenceName,
  HoldingsSortDirection,
} from '../../const';
import { queryHoldingPreference } from '../query-holding-preference';
import { getKeyByValueFromHolding } from '../get-key-by-value-from-holding/get-key-by-value-from-holding.function';
import { lowercaseFirstLetter } from '../lower-case-first-letter/lower-case-first-letter.function';

export function findHoldingSortTypePreference(
  accountNumber: string,
  preferences: UserPreferencesState | undefined
): {
  holdingsSortTypePreference: THoldingSortTypeValues;
  holdingsSortDirectionPreference: THoldingSortDirectionValues;
  sortByKey: keyof Holding;
} {
  if (!preferences) {
    return {
      holdingsSortTypePreference: HoldingSortType.PortfolioPercent,
      holdingsSortDirectionPreference: HoldingsSortDirection.Descending,
      sortByKey: 'portfolioPercent',
    };
  }
  const queriedHoldingSortingTypePreference = queryHoldingPreference(
    accountNumber,
    preferences,
    HoldingSortTypePreferenceName
  );
  const queriedHoldingSortingDirectionPreference = queryHoldingPreference(
    accountNumber,
    preferences,
    HoldingSortDirectionName
  );
  const holdingsSortTypePreference = castPreferenceValue<THoldingSortTypeValues>(
    HoldingSortType.PortfolioPercent,
    queriedHoldingSortingTypePreference
  );
  const holdingsSortDirectionPreference = castPreferenceValue<THoldingSortDirectionValues>(
    HoldingsSortDirection.Descending,
    queriedHoldingSortingDirectionPreference
  );
  const sortByKey = getKeyByValueFromHolding(
    holdingsSortTypePreference,
    HoldingSortType,
    lowercaseFirstLetter,
    'portfolioPercent'
  );
  return {
    holdingsSortDirectionPreference,
    holdingsSortTypePreference,
    sortByKey,
  };
}

function castPreferenceValue<T>(
  defaultValue: T,
  queriedItem: undefined | AppDefaultPreferences | UserPreferenceState
): T {
  const hasPreference = (queriedItem as UserPreferenceState)?.value ?? queriedItem?.defaultValue;
  return hasPreference ? (parseInt(hasPreference) as T) : defaultValue;
}
