<span class="transfer-type-label">
  Edit Recurring {{ depositName }}
  <span class="d-none d-md-inline" *ngIf="isRetirement">- {{ scheduledTransfer.accountName }}</span>
</span>
<span class="transfer-type-description d-none" [class.d-md-block]="isRetirement" [class.d-block]="!isRetirement">
  {{ scheduledTransfer.amount | currency }} {{ scheduledTransfer.frequencyDescription | lowercase }}
  <ng-container *ngIf="scheduledTransfer.sendUntil === sendUntil.UntilIChooseToStop">
    until I choose to stop
  </ng-container>
  <ng-container *ngIf="scheduledTransfer.sendUntil === sendUntil.LastTransferDate">
    until {{ scheduledTransfer.lastTransferDate | date: 'MM/dd/yy' }}
  </ng-container>
  <span>
    from {{ scheduledTransfer.bankAccountType | titlecase }} -
    {{ scheduledTransfer.bankAccountNumber.slice(scheduledTransfer.bankAccountNumber.length - 4) }}
  </span>
</span>
<span class="transfer-type-description d-block d-md-none" *ngIf="isRetirement">
  {{ scheduledTransfer.accountName }}
  <ng-container *ngIf="scheduledTransfer.contributionYear">({{ scheduledTransfer.contributionYear }})</ng-container>
</span>
