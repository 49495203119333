import { MessagesType } from '@app/inbox/enums';

export class InboxMapper {
  static mapMessageTypes(type: number): string {
    switch (type) {
      case MessagesType.INBOX:
        return 'Inbox';
      case MessagesType.ARCHIVED:
        return 'Archived';
      default:
        return '';
    }
  }
}
