import { FundingAccount } from '@core/models';
import { FundingMethod } from '@shared/enums';

export class FundingState {
  isRunning = false;
  depositAmount = 0;
  accountId = 0;
  fromAccountId = 0;
  method: FundingMethod = null;
  accountsToBeFunded: OlbAccount[] = [];
  fundingAccounts: FundingAccount[];

  constructor(args?: Partial<FundingState>) {
    Object.assign(this, args);
  }
}
