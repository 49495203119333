import { Action, createReducer, on } from '@ngrx/store';

import { ExternalBankProvider } from '@shared/models';

import { loadProviders } from './providers.actions';

export const initialState: ExternalBankProvider[] = null;

const _reducer = createReducer(
  initialState,
  on(loadProviders, (_state, action) => {
    return action.payload;
  })
);

export function providersReducer(state: ExternalBankProvider[], action: Action) {
  return _reducer(state, action);
}
