// Use this file to export all your components.
// Then they can be imported on other files like this. E.g.
// import { MyComponent } from './my-component-folder/my-component.component';

import { PercentageAmountComponent } from './percentage-amount/percentage-amount.component';
import { ReactiveTransferAmountComponent } from './reactive-transfer-amount/reactive-transfer-amount.component';
import { SchedulerTransfersComponent } from './scheduler-transfers/scheduler-transfers.component';
import { TransferAmountComponent } from './transfer-amount/transfer-amount.component';
import {
  LoanConfirmationPageComponent,
  LoanPaymentComponent,
  LoanReviewAndSubmitComponent,
  PaymentDetailItemComponent,
  PaymentDetailsComponent,
} from './transfer-funds';
import { TransferReceiptComponent } from './transfer-receipt/transfer-receipt.component';
import { HistoryComponent } from './p2p/history/history.component';
import { P2PComponent } from './p2p/p2p/p2p.component';
import { SendMoneyComponent } from './p2p/send-money/send-money.component';
import { HistoryPaginatorComponent } from './p2p/history-paginator/history-paginator/history-paginator.component';
import { SendMoneySbbContainerComponent } from './p2p/send-money/send-money-sbb-container/send-money-sbb-container/send-money-sbb-container.component';
import { TransferFundsComponent } from './transfer-funds/transfer-funds.component';
import { TransferFundsContainerComponent } from './transfer-funds-container/transfer-funds-container.component';
import { AxosLegacyInputFormattedAmountComponent } from './axos-legacy-input-formatted-amount';


export {
  PercentageAmountComponent,
  ReactiveTransferAmountComponent,
  TransferReceiptComponent,
  TransferAmountComponent,
  PaymentDetailItemComponent,
  PaymentDetailsComponent,
  LoanConfirmationPageComponent,
  LoanPaymentComponent,
  LoanReviewAndSubmitComponent,
  HistoryComponent,
  P2PComponent,
  SendMoneyComponent,
  SendMoneySbbContainerComponent,
  HistoryPaginatorComponent,
  SchedulerTransfersComponent,
  TransferFundsComponent,
  TransferFundsContainerComponent,
  AxosLegacyInputFormattedAmountComponent,
};

export const sharedComponents = [
  PercentageAmountComponent,
  ReactiveTransferAmountComponent,
  TransferReceiptComponent,
  TransferAmountComponent,
  SchedulerTransfersComponent,
  PaymentDetailItemComponent,
  PaymentDetailsComponent,
  LoanConfirmationPageComponent,
  LoanPaymentComponent,
  LoanReviewAndSubmitComponent,
  HistoryComponent,
  P2PComponent,
  SendMoneyComponent,
  SendMoneySbbContainerComponent,
  HistoryPaginatorComponent,
  SchedulerTransfersComponent,
  TransferFundsComponent,
  TransferFundsContainerComponent,
  AxosLegacyInputFormattedAmountComponent,
];
