import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AasStoreFeatures } from '../../../enums';
import { BrandingSettingsStateType } from '.';

const getBrandingSettingsState = createFeatureSelector<BrandingSettingsStateType>(AasStoreFeatures.BrandingSettings);

export const getSelectedBranding = createSelector(getBrandingSettingsState, state => {
  if (state.brands === undefined) {
    return undefined;
  }

  return state.brands.find(item => item.name === state.selectedBrandingName) ?? undefined;
});

export const getAllBrandings = createSelector(getBrandingSettingsState, state => {
  return state.brands;
});
