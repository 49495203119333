import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function addressValidatorFn(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    const ADDRESS_REGEX = /^[a-zA-Z0-9 #\/.,]*$/;
    if (ADDRESS_REGEX.test(controlValue)) return null;

    return { addressValidation: true };
  };
}
