import { ContainerType } from '@app/accounts/enums';

/**
 *  @description function to map string to a normalized ContainerType value.
 *  @param needle string
 *  @return ContainerType normalized string.
 *  @example
 *  ```
 *  mapStringToContainerType('lOaN');
 *  ```
 */
export function mapStringToContainerType(needle: string) {
  return Object.keys(ContainerType).reduce((acc, curr, _idx, haystack) => {
    const regEx = new RegExp(`^${needle}$`, 'i');

    if (!haystack.includes(curr) || !regEx.test(curr)) return acc;

    if (needle.charAt(0).toUpperCase() === curr.charAt(0)) {
      acc = ContainerType[curr as keyof typeof ContainerType];
    } else {
      acc = curr;
    }

    return acc;
  }, null) as ContainerType;
}
