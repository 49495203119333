<div class="message-form">
  <app-view-header titleText="Message Us"></app-view-header>

  <div class="d-flex justify-content-center">
    <div class="material-tab col-12 col-lg-8 d-none d-sm-block">
      <app-sm-compose
        [category]="categoryName"
        [subCategory]="subCategoryName"
        [businessId]="businessId"
        (send)="goToConfirmation()"
      ></app-sm-compose>
    </div>

    <div class="material-expansion col-12 d-block d-sm-none">
      <app-sm-compose
        [category]="categoryName"
        [subCategory]="subCategoryName"
        [businessId]="businessId"
        (send)="goToConfirmation()"
      ></app-sm-compose>
    </div>
  </div>
</div>
