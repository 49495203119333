import { AccountOverview } from '@app/pfm/models/account-overview.model';
import { ExternalBankProvider } from '@shared/models';

export class AccountsState {
  accounts: AccountOverview[]; // null means not loaded yet
  accountsLoading: boolean;

  providers: ExternalBankProvider[]; // null means not loaded yet
  providersLoading: boolean;

  constructor(args?: Partial<AccountsState>) {
    Object.assign(this, args);
  }
}
