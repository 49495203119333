import { Action, createReducer, on } from '@ngrx/store';

import { addUpdateAuthorizationsToAccountAction } from '../authorizations.actions';
import { AuthorizationsStateType, initialAuthorizationsStateType } from '../types';

const _authorizationsReducer = createReducer(
  initialAuthorizationsStateType,
  on(addUpdateAuthorizationsToAccountAction, (state, action) => {
    if (action.payload.accountNumber) {
      const existingTabIndex: number = state.authorizationsTabState.findIndex(
        tab => tab.accountNumber === action.payload.accountNumber
      );
      if (existingTabIndex >= 0) {
        const updatedTab = {
          ...state.authorizationsTabState[existingTabIndex],
          ...action.payload.authorizationTabState,
        };
        const authorizationsTabState = [
          ...state.authorizationsTabState.filter(
            authorizationTab => authorizationTab.accountNumber !== action.payload.accountNumber
          ),
          updatedTab,
        ];

        return {
          selectedAccountAuthorizations: action.payload.accountNumber,
          authorizationsTabState,
        };
      }

      return {
        selectedAccountAuthorizations: action.payload.accountNumber,
        authorizationsTabState: [...state.authorizationsTabState, action.payload.authorizationTabState],
      };
    }

    return { ...state };
  })
);

export function authorizationsReducer(
  state: AuthorizationsStateType = initialAuthorizationsStateType,
  action: Action
): AuthorizationsStateType {
  return _authorizationsReducer(state, action);
}
