import { InjectionToken } from '@angular/core';
import { DialogService, TransactionService } from '@core/services';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ModalService } from '@legacy/services/modal.service';
import { ILoanService } from '@legacy/services/typings/ILoanService';
import { ITransactionService } from '@legacy/services/typings/ITransactionService';
import { Store } from '@ngrx/store';
import { IFilterService } from 'angular';
import { TransferSchedule } from './models';
import { CustomerLimitsHelper } from '@legacy/services/customer-limits.helper';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';
import { LoanAccountHelper } from '@legacy/services/loan-account.helper';
import { ISettingsService } from '@legacy/services/typings/ISettingsService';

export const LEGACY_FILTER = new InjectionToken<IFilterService>('$filter');
export const LEGACY_TRANSACTION_SERVICE = new InjectionToken<ITransactionService>('transactionService');
export const LEGACY_SETTINGS_SERVICE = new InjectionToken<ISettingsService>('settingsService');
export const LEGACY_LOAN_SERVICE = new InjectionToken<ILoanService>('loanService');
export const LEGACY_FEATURE_FLAG_SERVICE = new InjectionToken<FeatureFlagService>('featureFlagService');
export const LEGACY_SERVICE_HELPER = new InjectionToken<IServiceHelper>('serviceHelper');
/**
 * @deprecated
 */
export const LEGACY_UIB_MODAL = new InjectionToken('$uibModal');
export const LEGACY_MODAL_SERVICE = new InjectionToken<ModalService>('modalService');
export const LEGACY_AAS_TRANSACTION_SERVICE = new InjectionToken<TransactionService>('TransactionsService');
export const LEGACY_WINDOW_SERVICE = new InjectionToken<ng.IWindowService>('$window');
export const LEGACY_DIALOG_SERVICE = new InjectionToken<DialogService>('dialogService');
export const LEGACY_ENV_SERVICE = new InjectionToken('env');
export const LEGACY_ROOT_SCOPE = new InjectionToken<ng.IRootScopeService>('$rootScope');
export const STORE = new InjectionToken<Store>('ngrxStore');
/**
 * @deprecated
 */
export const LEGACY_UIB_MODAL_INSTANCE = new InjectionToken<any>('$uibModalInstance');
/**
 * @deprecated
 */
export const LEGACY_FREQUENCIES = new InjectionToken<GenericOption[]>('frequencies');
/**
 * @deprecated
 */
export const LEGACY_TRANSFER_TO_EDIT = new InjectionToken<TransferSchedule>('transferToEdit');
/**
 * @deprecated
 */
export const LEGACY_IS_SERIES = new InjectionToken<boolean>('isSeries');
/**
 * @deprecated to be removed on pull request
 */
export const LEGACY_RANGE_PICKER_OPTIONS = new InjectionToken<any>('dateRangePickerOptions');
export const LEGACY_CUSTOMER_LIMITS_HELPER = new InjectionToken<CustomerLimitsHelper>('customerLimitsHelper');
export const LEGACY_CACHED_ACCOUNTS_SERVICE = new InjectionToken<CachedAccountsService>('cachedAccountsService');
export const LEGACY_LOAN_ACCOUNT_HELPER = new InjectionToken<LoanAccountHelper>('loanAccountHelper');
export interface ILegacyInjector {
  get(tokenId: string): any;
}

export const LEGACY_SCHEDULER_TRANSFER_PROVIDERS = [
  {
    provide: LEGACY_FILTER,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('$filter'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_TRANSACTION_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('transactionService'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_SETTINGS_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('settingsService'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_LOAN_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('loanService'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_FEATURE_FLAG_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('featureFlagService'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_SERVICE_HELPER,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('serviceHelper'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_UIB_MODAL,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('$uibModal'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_MODAL_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('modalService'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_AAS_TRANSACTION_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('TransactionsService'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_WINDOW_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('dialogService'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_DIALOG_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('$window'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_ENV_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('env'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_ROOT_SCOPE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('$rootScope'),
    deps: ['$injector'],
  },
  {
    provide: STORE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('ngrxStore'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_UIB_MODAL_INSTANCE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('$uibModalInstance'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_FREQUENCIES,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('frequencies'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_TRANSFER_TO_EDIT,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('transferToEdit'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_IS_SERIES,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('isSeries'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_RANGE_PICKER_OPTIONS,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('dateRangePickerOptions'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_CUSTOMER_LIMITS_HELPER,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('customerLimitsHelper'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_LOAN_ACCOUNT_HELPER,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('loanAccountHelper'),
    deps: ['$injector'],
  },
  {
    provide: LEGACY_CACHED_ACCOUNTS_SERVICE,
    useFactory: (legacyInjector: ILegacyInjector) => legacyInjector.get('cachedAccountsService'),
    deps: ['$injector'],
  },
];
