import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';

import { AuthorizationsFacade } from '@app/Areas/AAS/features/account-details/facade/authorizations.facade';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BankWireAuthoritiesType } from '@app/Areas/AAS/features/account-details/core';
import { ABA_NUM_SEPARATOR_FORMAT, BLANK_FORMAT } from './constants';

@Component({
  selector: 'app-bank-wire',
  templateUrl: './bank-wire.component.html',
  styleUrls: ['./bank-wire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankWireComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  @Input() authorizationsTabState: BankWireAuthoritiesType[];
  blankFormat = BLANK_FORMAT;
  isBankWireEmptyCounter: number = 0;

  constructor(private authorizationsFacade: AuthorizationsFacade, private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.authorizationsFacade.authorizationsTabAccountState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state !== undefined)
      )
      .subscribe(data => {
        if (data?.bankWireAuthorities) {
          data?.bankWireAuthorities.forEach(element => {
            if (
              (element.abaRoutingNumber == null || element.abaRoutingNumber == ' ') &&
              (element.bankAccountNumber == null || element.bankAccountNumber == ' ') &&
              (element.bankAccountName == null || element.bankAccountName == ' ')
            ) {
              this.isBankWireEmptyCounter += 1;
            }
          });
        }
        if (this.isBankWireEmptyCounter != data?.bankWireAuthorities.length) {
          this.authorizationsTabState = data?.bankWireAuthorities;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormattedAccNum(value): string {
    if (value) {
      if (value.length > 4) {
        let newStr: string = '';
        for (let i = 0; i < value.length - 4; i++) {
          newStr += '*';
        }
        newStr += value.substring(value.length - 4, value.length);
        return newStr;
      } else {
        return value;
      }
    }
    return null;
  }

  abaRoutingFormat(value): string {
    if (value) {
      value = value.split(ABA_NUM_SEPARATOR_FORMAT)[0];
    }
    return value;
  }
}
