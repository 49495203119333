import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { format } from 'date-fns';

import { DocumentSubType, DocumentTypes } from '@app/accounts/enums';
import { DocumentDetail, GroupedDocuments } from '@app/accounts/models';
import { groupDocuments } from '@app/accounts/utils';
import { MilestoneSummary } from '@app/axos-invest/models';
import { AxosClearingService, AxosInvestUrlsService } from '@app/axos-invest/services';
import { getGoalSummary } from '@app/axos-invest/store/selectors';
import { saveFile } from '@app/utils';
import { DocumentsService } from '@core/services';
import { TradingDocumentAcountType } from '@legacy/axos-trading/statements/axos-trading-document-account-type.enum';
import { FilesIcons } from '@shared/enums';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  milestoneDetail: MilestoneSummary;
  years: GenericOption[] = [];
  groupedStatements: GroupedDocuments[] = [];
  groupedTaxForms: GroupedDocuments[] = [];
  statements: DocumentDetail[] = [];
  taxForms: DocumentDetail[] = [];
  pdfIcon = FilesIcons.FilePdf;
  selectedYear: GenericOption = { value: 0, label: 'All' };
  selectedDocType: GenericOption = { value: 0, label: 'All' };
  documentTypeFilter: GenericOption[] = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Trade Confirmations' },
    { value: 2, label: 'Notice Documents' },
    { value: 3, label: 'Monthly Statements' },
  ];
  private filteredStatements: DocumentDetail[] = [];
  private subSink = new SubSink();
  constructor(
    private axosClearingService: AxosClearingService,
    private documentsService: DocumentsService,
    private axosInvestUrlHelper: AxosInvestUrlsService,
    private store: Store
  ) {}

  ngOnInit() {
    this.getMilestoneDetail();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  downloadDocument(document: DocumentDetail) {
    const fileName = this.getFileName(document);
    this.documentsService.getStatement(0, document.doc_id, document.key, fileName, true).subscribe({
      next: response => {
        saveFile(response, fileName + '.pdf');
      },
    });
  }

  downloadTaxForm(document: DocumentDetail) {
    const fileName = this.getFileName(document);
    this.documentsService.getTaxForm(document.doc_id, document.key, fileName).subscribe({
      next: response => {
        saveFile(response, fileName + '.pdf');
      },
    });
  }

  filter() {
    this.filterYear();
    this.filterDocumentType();
  }

  redirectToPreviousDocuments() {
    this.axosInvestUrlHelper.getPreviousDocuments().subscribe(url => window.open(url, '_blank', 'noopener noreferrer'));
  }

  redirectToPreviousTaxForms() {
    this.axosInvestUrlHelper.getPreviousTaxForms().subscribe(url => window.open(url, '_blank', 'noopener noreferrer'));
  }

  private getFileName(document: DocumentDetail) {
    const formatedDate = format(new Date(document.doc_date), 'yyyyMMdd');
    const defualtNamesByAccountType = {
      [TradingDocumentAcountType.ManagedPortfolio]: document.subTypeName,
      [TradingDocumentAcountType.TaxForms]: document.displayname,
    };
    const customNameBySubType = {
      [DocumentSubType.Statement]: this.formatStatementFileName,
    };
    const shouldApplyCustomName = customNameBySubType[document.subType];
    let displayName = defualtNamesByAccountType[document.acct_type];
    displayName = displayName.toLowerCase().split(' ').join('_');

    if (shouldApplyCustomName) {
      displayName = shouldApplyCustomName(document);
    }

    return `${displayName}_${formatedDate}`;
  }

  private formatStatementFileName(document: DocumentDetail) {
    const accountNumberLength = document.accountNumber.length;

    return `STMT_${document.accountNumber.substring(accountNumberLength - 4, accountNumberLength)}`;
  }

  private getDocuments() {
    const accountNumbers = this.milestoneDetail.accounts
      .map(account => account.accountNumber)
      .filter((value, index, self) => self.indexOf(value) === index);

    const requests = accountNumbers.map(accountNumber =>
      this.axosClearingService.getDocuments(accountNumber).pipe(
        map(result => {
          result.data.forEach(document => {
            document.accountNumber = accountNumber;
          });

          return result.data;
        }),
        catchError(() => [])
      )
    );
    forkJoin(requests).subscribe({
      next: (requestData: Document[][]) => {
        const documents = [];
        requestData.forEach(request => documents.push(...request));
        this.setDocuments(documents);
      },
    });
  }

  private setDocuments(documents: DocumentDetail[]) {
    this.statements = documents.filter(c => c.acct_type === TradingDocumentAcountType.ManagedPortfolio);
    this.taxForms = documents.filter(c => c.acct_type === TradingDocumentAcountType.TaxForms);

    this.setFilterYears(documents);
    this.groupedStatements = groupDocuments(this.statements);
    this.groupedTaxForms = groupDocuments(this.taxForms);
  }

  private getMilestoneDetail() {
    this.subSink.sink = this.store.select(getGoalSummary).subscribe(goalSummary => {
      if (goalSummary.accounts.length > 0) {
        this.milestoneDetail = goalSummary;
        this.getDocuments();
      }
    });
  }

  private setFilterYears(documents: DocumentDetail[]) {
    this.years = [
      {
        label: 'All',
        value: 0,
      },
    ];

    const orderYears = documents.map(item => new Date(item.doc_date.toString()).getFullYear());
    const uniqueYears = orderYears
      .filter((x, y, z) => {
        return y === z.indexOf(x);
      })
      .sort((p, n) => n - p)
      .map(year => {
        return {
          label: year.toString(),
          value: year,
        };
      });
    this.years = this.years.concat(uniqueYears);
  }

  private filterYear() {
    this.filteredStatements =
      this.selectedYear.value === 0
        ? this.statements
        : this.statements.filter(e => {
            return new Date(e.doc_date.toString()).getFullYear() === this.selectedYear.value;
          });

    this.groupedStatements = groupDocuments(this.filteredStatements);

    const taxFiltered =
      this.selectedYear.value === 0
        ? this.taxForms
        : this.taxForms.filter(e => {
            return new Date(e.doc_date.toString()).getFullYear() === this.selectedYear.value;
          });
    this.groupedTaxForms = groupDocuments(taxFiltered);
  }

  private filterDocumentType() {
    const documentTypes = {
      [DocumentTypes.TradeConfirmation]: 'c',
      [DocumentTypes.ETF]: 'l',
      [DocumentTypes.Statements]: 's',
    };
    const document = documentTypes[this.selectedDocType.value];
    this.filteredStatements = document
      ? this.filteredStatements.filter(e => e.sub_type === document)
      : this.filteredStatements;
    this.groupedStatements = groupDocuments(this.filteredStatements);
  }
}
