import { createSelector } from '@ngrx/store';

import { getAccountsState } from './accounts.state';

export const getCheckingAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.internal.checking), ...Object.values(state.aggregated.checking)];
});
export const getSavingsAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.internal.savings), ...Object.values(state.aggregated.savings)];
});
export const getDepositAccountsList = createSelector(
  getCheckingAccountsList,
  getSavingsAccountsList,
  (checking, savings) => [...checking, ...savings]
);
export const getLoanAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.internal.loan), ...Object.values(state.aggregated.loan)];
});
export const getCdIraAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.internal.cd), ...Object.values(state.aggregated.cd)];
});
export const getCreditAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.internal.credit), ...Object.values(state.aggregated.credit)];
});
export const getOtherAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.internal.other), ...Object.values(state.aggregated.other)];
});
export const getUnknownAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.internal.unknown), ...Object.values(state.aggregated.unknown)];
});

export const getExternalAccountsList = createSelector(getAccountsState, state => {
  return [...Object.values(state.external)];
});

export * from './aggregated-accounts/aggregated-accounts.selectors';
export * from './closed-accounts/closed-accounts.selectors';
export * from './external-accounts/external-accounts.selectors';
export * from './internal-accounts/internal-accounts.selectors';
export * from './invest-accounts/invest-accounts.selectors';
export * from './trading-accounts/trading-accounts.selectors';
export * from './providers/providers.selectors';
