import { Component, Inject, OnInit } from '@angular/core';

import { IncomeType, SwitchType } from '@app/click-switch/enums';
import { Location, SubmitTarget, Target, TargetSearchResponse } from '@app/click-switch/models';
import { DialogService, DirectDepositService } from '@core/services';
import { STATE } from '@core/tokens';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons, UtilityIcons } from '@shared/enums';

import { ClickSwitchBaseComponent } from '../click-switch-base/click-switch-base.component';

@Component({
  selector: 'app-click-switch-search-target',
  templateUrl: './click-switch-search-target.component.html',
  styleUrls: ['./click-switch-search-target.component.scss'],
})
export class ClickSwitchSearchTargetComponent extends ClickSwitchBaseComponent implements OnInit {
  goIcon = NavigationIcons.ChevronRight;
  searchIcon = UtilityIcons.MagnifyingGlass;
  search = '';
  targetResult: TargetSearchResponse;
  targetsSelect: (Target | Location)[];
  isLoading = false;
  showMoreResults = false;
  searchIconStyles = {
    width: '1.8em',
    height: '1.8em',
    strokeWidth: 1,
    fill: 'var(--primary)',
  };
  target: Partial<SubmitTarget> = {
    id: '',
  };
  get hasLocation(): boolean {
    return this.targetResult.locations.length > 0;
  }
  private minCharacters = 4;

  constructor(
    @Inject(STATE) state: ng.ui.IStateService,
    directDepositService: DirectDepositService,
    serviceHelper: ServiceHelper,
    dialogService: DialogService
  ) {
    super(state, directDepositService, serviceHelper, dialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.userSwitch = {
      name: null,
      targetId: 0,
      location: '',
      locationId: null,
    };
  }

  goBack(): void {
    this.state.go('udb.clickswitch');
  }

  goSubmitTable(): void {
    const switchInfo = this.userSwitch;

    if (switchInfo.state === undefined || switchInfo.state.status === 'Incomplete') {
      this.state.go('udb.clickswitch.submittable-form', {
        switchInfo,
        target: this.search,
      });
    } else if (switchInfo.state.status === 'Ready') {
      const userSwitch = switchInfo;
      this.state.go('udb.clickswitch.online-path', {
        userSwitch,
      });
    }
  }

  searchTarget(value): void {
    this.showMoreResults = false;

    if (value?.length < this.minCharacters || this.isLoading) {
      this.targetsSelect = [];
      this.targetResult = null;

      return;
    }

    this.isLoading = true;
    this.directDepositService.getSearchTargets(value).subscribe(result => {
      this.isLoading = false;
      this.targetResult = result.data;
      this.targetsSelect = [];
      this.targetResult.targets.forEach(target => {
        if (target.name.toLowerCase().includes(this.search.toLowerCase())) {
          this.targetsSelect.push(target);
        }
      });
    });
  }

  createTargetSwitch(income): void {
    this.setSubmitTargetData(income);

    this.createSwitch(this.target, () => {
      this.setSwitchCreatedData();
      this.goSubmitTable();
    });
  }

  setSubmitTargetData(income): void {
    this.target.id = income.targetId ?? income.locationId;
    this.target.targetName = income.name;
    this.target.incomeType = IncomeType.Payroll;
    this.target.switchType = income.targetId ? SwitchType.target : SwitchType.location;
    this.target.completeAddress = income.address ?? '';
  }

  setSwitchCreatedData(): void {
    if (this.target.switchType === SwitchType.location) {
      this.userSwitch.locationId = this.target.id;
    }
    this.userSwitch.name = this.target.targetName;
    this.userSwitch.location = this.userSwitch.location ?? this.target.completeAddress;
  }

  showMore(): void {
    this.showMoreResults = true;
    this.targetResult.locations.forEach(location => {
      this.targetsSelect.push(location);
    });
  }
}
