import { IPaySSOResult } from '@legacy/typings/app/IPaySSOResult';
import { BaseService } from './base.service';
import { WebApiResponse } from '@shared/models';
import { Inject, Injectable } from '@angular/core';
import { olbSettings } from '@core/tokens';
import { OlbSettings } from '@core/models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class IPayService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'ipay/sso');
  }

  /**
   * Gets IPay Punch Out URL by AccountID.
   * @param accountId Account ID to be retrieved.
   * @returns A URL for punching out.
   */
  getSsoUrlByAccountId(accountId: number): WebApiResponse<IPaySSOResult> {
    return this.get(`account/${accountId}`);
  }

  /**
   * Gets IPay Punch Out URL by BusinessID.
   * @param businessId Business ID unique identifier.
   * @returns A URL for punching out.
   */
  getSsoUrlByBusinessId(businessId: number): WebApiResponse<IPaySSOResult> {
    return this.get(`business/${businessId}`);
  }
}
