<div class="header-section-title text-center">
  <h1>Add Minor to Account</h1>
</div>
<div class="minor-title frow text-center">
  <div class="fcol-cs-12 hide-xs">
    <h2>Make a minor the permanent owner and convert to a UTMA account.</h2>
  </div>
  <div class="fcol-cs-12">
    <button class="btn__link" (click)="showMore = !showMore">
      Learn More
      <i class="bofi-arrow-chevron display-more-arrow" [ngClass]="{ after: showMore }"></i>
    </button>
  </div>
  <div *ngIf="showMore" class="minor-title_show-more">
    UTMA accounts follow the rules of the Uniform Transfer/Gifts to Minors Act. Once you establish the UTMA account, you
    will manage it as the custodian until the minor reaches the age of majority (18 or 21, depending on state law). All
    funds deposited in the UTMA account permanently become the property of the minor.
  </div>
</div>

<form [formGroup]="minorForm" novalidate (ngSubmit)="acceptAndSave()">
  <div class="frow center">
    <div class="minor-content fcol-lg-6 fcol-md-8 fcol-cs-12">
      <div class="minor-content_account">
        <h3>Account</h3>
        <div class="frow">
          <input class="uk-input" type="text" name="txtMinorAccount" [value]="selectedAccount?.nickname" disabled />
        </div>
      </div>

      <div class="minor-content_information">
        <app-minor-information
          [minor]="minor"
          [parentForm]="minorForm"
          [isSubmitted]="submitted"
        ></app-minor-information>
      </div>
      <div class="minor-content_address">
        <app-minor-address [minor]="minor" [parentForm]="minorForm" [isSubmitted]="submitted"></app-minor-address>
      </div>
      <div class="minor-content_confirmation">
        <h3>Are you sure you want to add a minor to this account?</h3>
        <div class="accept_terms">
          <input type="checkbox" id="minorAcceptTerms" [formControl]="minorAcceptTermsControl" />
          <label for="minorAcceptTerms"></label>
          <div>
            <span>
              Yes, I want to convert my individual account to UTMA account by adding a minor. I understand this action
              is
              <b>not reversible</b>
              and the
              <b>minor cannot be removed.</b>
            </span>
          </div>
        </div>
        <div *ngIf="minorForm.get('minorAcceptTerms').invalid && submitted">
          <span class="text-error">
            You must accept the agreement
          </span>
        </div>
      </div>
      <div class="minor-content_disclaimer">
        <div class="frow">
          <div class="group--form fcol-md-12 fcol-cs-12">
            <span>
              By submitting this form, you agree that the Social Security Number of the minor is true and accurate and
              contains no willful falsifications or misrepresentation. I understand that falsifications,
              representations, or omissions may disqualify me from consideration.
            </span>
          </div>
        </div>
      </div>
      <div class="minor-content_actions frow text-center">
        <div class="fcol-cs-12">
          <app-dot-loader [showWhen]="isBusy"></app-dot-loader>
        </div>
        <div class="fcol-cs-12" *ngIf="!isBusy">
          <button id="accept-submit" class="uk-btn solid secondary lg" type="submit">
            Agree & Submit
          </button>
        </div>
        <div class="fcol-cs-12" *ngIf="!isBusy">
          <button type="button" class="back-btn" (click)="redirectToPage(accountId)">
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
