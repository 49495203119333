import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { createReactiveValidatorFromSchema, keyDownValidate } from '@app/utils';
import { AuthMethod } from '@core/enums';
import { MultiFactorRequest } from '@core/models';
import { MultifactorService } from '@core/services';

import { accessCodeSchema } from './access-code.schema';

@Component({
  selector: 'app-access-code',
  templateUrl: './access-code.component.html',
  styleUrls: ['./access-code.component.scss'],
})
export class AccessCodeComponent implements OnInit, OnDestroy {
  @Output() continue = new EventEmitter<{ code: string; remember: boolean }>();
  @Output() back = new EventEmitter<void>();
  @Output() resendBack = new EventEmitter<void>();
  @Input() isLoading: boolean;
  @Input() otp = '';
  @Input() displayRememberDevice = true;
  @Input() rememberDeviceHash = '';
  @Input() errorMessage = '';
  @Input() displayPersonalInfo = true;
  @Input() isGoogleAuth = false;
  @Input() resendOut = false;

  infoBoxText: string;
  accessCodeForm: UntypedFormGroup;

  private multiFactorRequest: MultiFactorRequest;
  private remember: boolean;
  private isEmail: boolean;

  constructor(private formBuilder: UntypedFormBuilder, private multifactorService: MultifactorService) {}

  ngOnInit(): void {
    this.setValuesFromStorage();
    this.initInfoBoxText();
    this.focusOtpInput();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.accessCodeForm.reset();
  }

  @HostListener('keydown', ['$event'])
  validateNumbersOnly(keyboardEvent: KeyboardEvent) {
    return keyDownValidate(keyboardEvent, /^\d+$/);
  }

  getFieldError(formControlName: string) {
    const { pristine, errors } = this.accessCodeForm.get(formControlName);

    return !pristine && errors ? this.accessCodeForm.errors[formControlName] : null;
  }

  resendCode() {
    if (this.resendOut) {
      this.resendBack.emit();
      this.accessCodeForm.patchValue({ code: '' });

      return;
    }

    this.isLoading = true;
    this.multifactorService
      .requestOtp(this.multiFactorRequest)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: response => {
          const data = response.data;
          const otp = !this.isEmail ? data.otp : '';

          this.accessCodeForm.patchValue({ code: otp });
          sessionStorage.setItem('AuthenticationToken', data.authenticationToken);
          sessionStorage.removeItem('authRequest');

          this.focusOtpInput();
        },
      });
  }

  clickedBack() {
    this.back.emit();
  }

  onSubmit() {
    if (this.accessCodeForm.valid) {
      const { value } = this.accessCodeForm;

      if (this.displayRememberDevice) {
        this.persistRememberDevice();
      }

      this.continue.emit(value);
    }
  }

  private persistRememberDevice() {
    const key = `rememberWebDevice:${this.rememberDeviceHash}`;
    localStorage.setItem(key, `${this.rememberDeviceHash}`);
  }

  private initForm() {
    this.accessCodeForm = this.formBuilder.group(
      {
        code: this.otp,
        remember: this.remember,
      },
      {
        validators: createReactiveValidatorFromSchema(accessCodeSchema),
      }
    );
  }

  private initInfoBoxText() {
    const connector = this.isEmail ? 'an' : 'a';
    const method = this.isEmail ? 'email' : 'message';
    const storageKey = this.isEmail ? 'maskedEmail' : 'maskedCellPhone';
    const to = this.displayPersonalInfo
      ? sessionStorage.getItem(storageKey) || ''
      : `the ${this.isEmail ? 'email' : 'phone number'} associated with your account`;

    this.infoBoxText = this.isGoogleAuth
      ? 'Please enter the code from the Google Authenticator'
      : `We've sent ${connector} ${method} with your verification code to ${to}.`;
  }

  private focusOtpInput() {
    document.getElementById('access-code-entry').focus();
  }

  private setValuesFromStorage() {
    const item = localStorage.getItem(`rememberWebDevice:${this.rememberDeviceHash}`);
    const authRequest = JSON.parse(sessionStorage.getItem('authRequest') || '{}');

    this.multiFactorRequest = authRequest;
    this.remember = item === 'true';
    this.isEmail = authRequest.authenticationMethod === AuthMethod.Email;
  }
}
