import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import {
  FileUploadComponent,
  FooterComponent,
  FundingHeaderComponent,
  MultifactorComponent,
  PdfRendererComponent,
  SvgIconComponent,
  TermsAndConditionsComponent,
  ViewHeaderComponent,
} from '@shared/components';

import { AlertNotificationComponent } from './alert-notification/alert-notification.component';
import { AlertNotificationController } from './alert-notification/alert-notification.controller';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';

export const SharedModule = angular
  .module('udb.shared', [])
  .component('alertNotification', AlertNotificationComponent)
  .component('fileUploader', FileUploaderComponent)
  .directive('appSvgIcon', downgradeComponent({ component: SvgIconComponent }))
  .controller('AlertNotificationController', AlertNotificationController)
  .directive('appViewHeader', downgradeComponent({ component: ViewHeaderComponent }))
  .directive('appMultifactor', downgradeComponent({ component: MultifactorComponent }))
  .directive('appFileUpload', downgradeComponent({ component: FileUploadComponent }))
  .directive('appFooter', downgradeComponent({ component: FooterComponent }))
  .directive('appPdfRenderer', downgradeComponent({ component: PdfRendererComponent }))
  .directive('appTermsAndConditions', downgradeComponent({ component: TermsAndConditionsComponent }))
  .directive('appFundingSharedHeader', downgradeComponent({ component: FundingHeaderComponent })).name;
