import { downgradeComponent } from '@angular/upgrade/static';

import * as angular from 'angular';

import { ContactUsConfirmationComponent } from '@app/support/components';
import { ContactUsViewComponent, SupportViewComponent } from '@app/support/views';

import { Inject } from '../decorators/decorators';

@Inject('$stateProvider')
class SupportConfig {
  constructor($stateProvider: ng.ui.IStateProvider) {
    $stateProvider
      .state('udb.support', {
        url: '/Support',
        views: {
          content: {
            template: '<div ui-view="support"><app-support></app-support></div>',
          },
        },
        ncyBreadcrumb: {
          label: 'Support',
        },
      })
      .state('udb.support.message', {
        url: '/Message/:subCategoryName?categoryId?subCategoryId?categoryName?businessId',
        views: {
          support: {
            template: '<app-contact-us></app-contact-us>',
          },
        },
      })
      .state('udb.support.confirmation', {
        url: '/Confirmation',
        views: {
          support: {
            template: '<app-contact-us-confirmation></app-contact-us-confirmation>',
          },
        },
      });
  }
}

export const supportLegacyModule = angular
  .module('udb.support', [])
  .directive('appSupport', downgradeComponent({ component: SupportViewComponent }))
  .directive('appContactUsConfirmation', downgradeComponent({ component: ContactUsConfirmationComponent }))
  .directive('appContactUs', downgradeComponent({ component: ContactUsViewComponent }))
  .config(SupportConfig).name;
