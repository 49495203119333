import { Action, createReducer, on } from '@ngrx/store';

import { loadSettings } from './settings.actions';

export const initialState: Record<string, string> = null;

const _reducer = createReducer<Record<string, string>>(
  initialState,
  on(loadSettings, (_state, action) => {
    return action.payload.reduce((accumulator, item) => {
      const setting = {
        [item.name]: item.value,
      };

      return { ...accumulator, ...setting };
    }, {});
  })
);

export function settingsReducer(state: Record<string, string>, action: Action) {
  return _reducer(state, action);
}
