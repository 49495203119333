<div class="activity">
  <div class="frow center axos-info-container">
    <div class="row-flex frow center">
      <h1>Bill Pay</h1>
    </div>
    <app-billpay-nav
      leftRedirectionPath="udb.billPay.pay"
      leftTabText="Pay"
      rightRedirectionPath="udb.billPay.activity"
      rightTabText="Activity"
      activeTab="1"
    ></app-billpay-nav>
  </div>

  <div class="view-container activity-content">
    <app-scheduled-payments></app-scheduled-payments>
    <hr />
    <app-payments-history></app-payments-history>
  </div>
</div>
