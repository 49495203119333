<div class="axos-invest-filters-row">
  <app-category-filter class="transaction-filter col-lg-2" id="categories-filter"></app-category-filter>
  <app-input-filter
    class="transaction-filter col-lg-7"
    id="search-filter"
    [iconPlaceholder]="'search'"
    [inputPlaceholder]="'Search'"
    [control]="filters.get('search')"
  ></app-input-filter>
  <div class="filter-btn-container transaction-filter col-lg-3">
    <button type="button" class="uk-btn filter-btn" aria-haspopup="true" aria-expanded="false">
      <span class="filter-btn-icon">
        <app-svg-icon
          [iconName]="filterIcon.iconName"
          [height]="filterIcon.height"
          [width]="filterIcon.width"
        ></app-svg-icon>
      </span>
      <span>Filters</span>
    </button>
  </div>
</div>
<div class="transaction-filters" [class.show-section]="displayFilters">
  <div class="transaction-filters-modal">
    <div class="transaction-filters-header">
      <button class="tx-btn">
        Reset
      </button>
      <span>Filter Transactions</span>
      <button class="tx-btn">Cancel</button>
    </div>
    <div class="transaction-filters-body">
      <app-dropdown-filter class="transaction-sub-filter"></app-dropdown-filter>
      <app-dropdown-filter class="transaction-sub-filter"></app-dropdown-filter>
      <app-date-range-filter class="transaction-sub-filter"></app-date-range-filter>
      <popover-comparer
        title="Amount Range"
        inputClasses="uk-input tx-filter-input"
        placement="bottom"
        placeholder="$"
        filterTypeName="amountRange"
        elementType="button"
        elementDescription="Amount Range"
        activeBgColor="tx-aggregation-filters__btn--active"
        buttonClasses="uk-btn lg transaction-filter-button"
        clearText="Clear Filters"
        class="transaction-sub-filter"
      ></popover-comparer>
    </div>
    <div class="transaction-filters-footer">
      <button class="uk-btn solid secondary lg">
        Apply Filter
      </button>
    </div>
  </div>
</div>
<div class="filter-tags" *ngIf="filtersApplied && filtersApplied.length > 0">
  <ng-container *ngFor="let filter of tagFilters">
    <app-tag [filter]="filter"></app-tag>
  </ng-container>
</div>
