<table class="portfolio-details">
  <thead>
    <tr class="table-head-row">
      <th></th>
      <th>Target</th>
    </tr>
  </thead>
  <tbody>
    <tr class="table-body-head">
      <td class="main-asset">Stocks</td>
      <td>{{ portfolioDetails.stockAllocation }}%</td>
    </tr>
    <tr class="table-body-content" *ngFor="let stock of stocks">
      <td class="asset">
        <div class="square" [style.background-color]="stock.background"></div>
        {{ stock.name }}
      </td>
      <td>{{ stock.target }}%</td>
    </tr>
    <tr class="table-body-head">
      <td class="main-asset">Bonds</td>
      <td>{{ portfolioDetails.bondAllocation }}%</td>
    </tr>
    <tr class="table-body-content" *ngFor="let bond of bonds">
      <td class="asset">
        <div class="square" [style.background-color]="bond.background"></div>
        {{ bond.name }}
      </td>
      <td>{{ bond.target }}%</td>
    </tr>
    <tr class="table-body-head">
      <td class="main-asset">Cash</td>
      <td>{{ portfolioDetails.cashAllocation }}%</td>
    </tr>
    <tr class="table-body-content" *ngFor="let item of cash">
      <td class="asset">
        <div class="square" [style.background-color]="item.background"></div>
        {{ item.name }}
      </td>
      <td>{{ item.target }}%</td>
    </tr>
  </tbody>
</table>
