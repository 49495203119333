import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailValidatorFn(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    const RFC_EMAIL_REGEX = /^[A-Za-z0-9\\.+_-~-]+@[A-Za-z.]+\.[A-Za-z]{2,3}$/;
    if (RFC_EMAIL_REGEX.test(controlValue)) return null;

    return { emailValidation: true };
  };
}
