import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import { TileInformation, TileTypes, UIKitTile } from '@uikit/tiles';
import { UserTile } from 'models';
import { TilesService } from 'services/tiles.service';

import { AggregatedAccount } from '@app/accounts/models';
import {
  getAxosInvestAccount,
  getCdIraAccountsList,
  getDepositAccountsList,
  getTradingAccountsList,
  getLoanAccountsList,
} from '@app/accounts/store/selectors';
import { TradingAccount } from '@app/axos-trading/models';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';

import { Inject } from '../decorators/decorators';
import { getExternalAccountsList } from '@app/accounts/store/external-accounts/external-accounts.selectors';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryAccount } from '@core/models';
import { accountsService } from '@app/Areas/AAS/aas-shared/services/accounts.service';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { AgreementsTypeResponse } from '@core/enums/agreements-type-response.enum';
import { InvestorCheckingEnrollmentModalComponent } from '@app/accounts/components/modals/investor-checking-enrollment-modal/investor-checking-enrollment-modal.component';
import { IStateService } from 'angular-ui-router';
import { DialogService } from '@core/services';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { AasAgreementType } from '@app/Areas/AAS/aas-core';
import { RIAContactsService } from '@app/Areas/AAS/aas-shared/services/contacts-service';
import { RiaFacade, RiaType } from '@app/Areas/AAS/aas-core/rias';
import { InvestorCheckingEnrollmentModalComponentV2 } from '@app/accounts/components/modals';

@Inject(
  '$rootScope',
  '$scope',
  'serviceHelper',
  'tilesService',
  'env',
  'ngrxStore',
  'axosInvestHelper',
  'featureFlagService',
  'userSubtypeHelper',
  'aasAccountsService',
  'aasAgreementService',
  '$state',
  DialogService.$token,
  'supportViewFacade',
  'riaContactService',
  'riaFacade',
  'pendoTracker'
)
export class DashboardController {
  tiles: TileInformation[];
  dashboardType: TileTypes;
  isBusy = true;
  tileStoreCatalog: UIKitTile[];
  loansUrl: string;
  depositsUrl: string;
  brandName: string;
  balanceCalculate = true;
  isBlendFlagActive = false;
  userHaveAdvisoryAccounts = false;
  allRias: RiaType[];
  riasOrganizationsActive: RiaType[] = [];
  private advisoryAccounts: AxosAdvisoryAccount[] = null;
  private tilesContainer: JQuery;
  private currentTiles: UIKitTile[];
  private tilesCatalog: UIKitTile[];
  private listeners: Function[] = [];
  private subSink = new SubSink();
  private allAccounts: AggregatedAccount[] = [];
  private cdAccounts: AggregatedAccount[] = [];
  private tilesNeedOpenAccountIds: UIKitTile[];
  private tilesNeedOpenAccounts = [
    'quick-transfer',
    'account-distribution',
    'account-trends',
    'recent-transactions',
    'scheduled-payments',
  ];

  private userTiles: UserTile[] = [];
  private tradingAccounts: TradingAccount[] = [];
  isRiaPilotOdlActive: boolean;
  constructor(
    private rootScope: ng.IRootScopeService,
    private scope: ng.IScope,
    private serviceHelper: IServiceHelper,
    private tilesService: TilesService,
    private _env: any,
    private store: Store,
    private axosInvestHelper: AxosInvestHelper,
    private featureFlagService: FeatureFlagService,
    private userSubtypeHelper: UserSubtypeHelper,
    private accountsService: accountsService,
    private agreementService: AgreementService,
    private state: IStateService,
    private dialogService: DialogService,
    private supportViewFacade: SupportViewFacade,
    private riaContactsService: RIAContactsService,
    private riaFacade: RiaFacade
  ) {}

  /** Initializes the component. */
  $onInit(): void {
    this.isRiaPilotOdlActive = this.featureFlagService.isRiaPilotODLActive();

    this.subSink.sink = this.riaFacade.allRias$
      .pipe(filter(rias => !!rias))
      .subscribe(rias => {
        this.allRias = rias;
      });

    this.subSink.sink = this.supportViewFacade.isRIAPilotActive$
      .pipe(
        filter(isRiaPilotActive => isRiaPilotActive),
        switchMap(() => this.store.select(getAxosAdvisoryAccounts)),
        tap(riaAccounts => {
          if (riaAccounts == null) {
            this.rootScope['dashboardModalsCanBeDisplayed'] = true;
            this.rootScope['welcomePagePriority'] = false;
          }

          if (riaAccounts?.length > 0) {
            this.advisoryAccounts = riaAccounts;
            this.userHaveAdvisoryAccounts = true;
          }
        }),
        filter(riaAccounts => riaAccounts != null),
        switchMap(() => this.riaFacade.isLoading$),
        filter(isLoadedRias => isLoadedRias),
        switchMap(() => this.supportViewFacade.isLoading$),
        filter(isRiaUserValidationLoading => !isRiaUserValidationLoading),
        switchMap(() => this.supportViewFacade.isRIAUser$),
        tap(isRiaUser => {
          if (!isRiaUser) {
            this.rootScope['dashboardModalsCanBeDisplayed'] = true;
            this.rootScope['welcomePagePriority'] = false;
          }
        }),
        filter(userIsRIAUser => userIsRIAUser),
        switchMap(() => {
          const viewedWelcomePage = Boolean(
            sessionStorage.getItem('viewedWelcomePage')
          );
          return viewedWelcomePage
            ? of(null)
            : this.riaContactsService.getLibertyAccountOrgInfo();
        }),
        catchError(() => of(null))
      )
      .subscribe((response: any) => {
        if (response && response.data && response.data.length > 0) {
          response.data.forEach(riaAccount => {
            const riaOrganizationActive = this.allRias.find(
              ria =>
                ria.udbUserId === riaAccount.firmId &&
                ria.clientPortalStatus != 0
            );
            if (riaOrganizationActive) {
              const riaAccountActive = this.advisoryAccounts.find(
                advisoryAccount =>
                  advisoryAccount.riaId === riaOrganizationActive.riaId &&
                  (advisoryAccount.status == 'open' ||
                    advisoryAccount.status == 'active')
              );
              if (riaAccountActive) {
                this.riasOrganizationsActive.push(riaOrganizationActive);
              }
            }
          });

          if (this.riasOrganizationsActive.length > 0) {
            // Unique value of riasOrganizationsActive
            const riasOrganizationsActiveWP = [
              ...new Set(this.riasOrganizationsActive),
            ];
            sessionStorage.setItem('viewedWelcomePage', 'true');
            this.state.go('udb.interestitialPage', {
              organizationData: riasOrganizationsActiveWP,
            });
          } else {
            this.rootScope['welcomePagePriority'] = false;
            this.rootScope.$broadcast('welcomePagePriority');
          }
        } else {
          this.validateRiaInvestorCheckingAccountFlow();
          this.rootScope['welcomePagePriority'] = false;
          this.rootScope.$broadcast('welcomePagePriority');
        }
      });

    window.scrollTo(0, 0);
    this.serviceHelper.logWebInformation("User's Dashboard Initialized");
    this.initEvents();
    this.brandName = String(this._env.brand);

    this.subSink.sink = this.store
      .select(getProfileDetails)
      .pipe(
        finalize(() => {
          this.loadAccounts();
          this.setBusyFalse();
        }),
        filter(data => data !== null),
        switchMap(() => {
          return forkJoin([
            this.tilesService.getUserTiles(),
            this.tilesService.getTileStore(),
          ]);
        }),
        take(1)
      )
      .subscribe(([userTiles, tileStore]) => {
        this.tileStoreCatalog = tileStore.catalog.map(tile => {
          tile.imgUrl = `/img/tiles/${tile.code}.png`;

          return tile;
        });

        this.userTiles = userTiles;
        this.tilesCatalog = tileStore.catalog;
        this.dashboardType = tileStore.storeType;
        this.setDefaultTilesForOnlyTrading();
      });

    if (this.rootScope['brandProperties']) {
      this.setUrls();
    }

    this.listeners.push(
      this.scope.$on('brandPropertiesLoaded', () => {
        this.setUrls();
      })
    );

    this.isBlendFlagActive = this.featureFlagService.isBlendFlagActive();
    this.serviceHelper.logWebInformation("User's Dashboard Loaded");
  }

  $onDestroy(): void {
    this.subSink.unsubscribe();
    this.listeners.forEach(unsubscribe => unsubscribe());
  }
  /**
   * Deletes a user tile.
   * @param tileInfo Information of the tile to delete.
   */
  deleteTile(tileInfo: TileInformation): void {
    const { instanceId, tileId } = tileInfo;

    if (instanceId) {
      this.tilesService
        .deleteTile(instanceId as number)
        .pipe(take(1))
        .subscribe(null, this.serviceHelper.errorHandler);
    }

    this.tilesService.removeDefaultTile(tileInfo);

    this.currentTiles = this.currentTiles.filter(
      tile =>
        tile.instanceId !== instanceId ||
        (tile.instanceId === 0 && tile.id !== tileId)
    );

    this.updateDashboardTiles();
  }

  loadAccounts() {
    this.subSink.sink = this.store
      .select(getTradingAccountsList)
      .pipe(filter(data => data && data.length > 0))
      .subscribe(data => {
        this.tradingAccounts = data;
      });

    this.subSink.sink = this.store
      .select(getAxosInvestAccount)
      .pipe(filter(_data => !this.axosInvestHelper.isInvestAccountEmpty()))
      .subscribe(_data => {});

    this.subSink.sink = this.store
      .select(getDepositAccountsList)
      .pipe(filter(data => data.length !== 0))
      .subscribe(data => {
        this.allAccounts = data;
      });

    this.subSink.sink = this.store
      .select(getCdIraAccountsList)
      .pipe(filter(data => data && data.length > 0))
      .subscribe(data => {
        this.cdAccounts = data;
      });

    this.subSink.sink = this.store
      .select(getLoanAccountsList)
      .pipe(filter(data => data && data.length > 0))
      .subscribe(_data => {});

    this.subSink.sink = this.store
      .select(getExternalAccountsList)
      .pipe(filter(data => data && data.length > 0))
      .subscribe(data => {
        this.allAccounts = data;
      });
  }

  setBusyFalse(): void {
    this.isBusy = false;
    this.loadDashboard(this.userTiles);
  }

  /**
   * Saves the dashboard.
   * @param order Order of the user tile IDs as they appear on the dashboard
   */
  saveDashboard(order: UserTile[]): void {
    const filtered: UserTile[] = order.filter(element => element !== undefined);
    this.tilesService
      .saveTilesOrder(filtered)
      .pipe(take(1))
      .subscribe(null, this.serviceHelper.errorHandler);
  }

  /** Displays the tile store where the user can add more tiles. */
  createTile(tile: UIKitTile): void {
    tile = Object.assign({}, tile);
    this.tilesService
      .createTile(tile.id)
      .pipe(take(1))
      .subscribe(res => {
        tile.instanceId = res.data.instanceId;
        this.addTile(tile);
      }, this.serviceHelper.errorHandler);
  }

  /** Initializes dashboard events. */
  private initEvents(): void {
    this.listeners.push(
      this.scope.$on('olbTileCreated', this.registerCreatedTile.bind(this))
    );
    this.listeners.push(
      this.rootScope.$on('balancesAvailable', () => {
        this.balanceCalculate = false;
      })
    );
    this.listeners.push(
      this.scope.$on('$destroy', () => {
        this.$onDestroy();
      })
    );
  }
  /**
   * Adds a new tile to the current tiles references.
   * @param tile Tile information to add.
   */
  private addTile(tile: UIKitTile): void {
    this.scope.$broadcast('$uikitCloseTileStore');
    delete tile.hasActiveInstance;
    this.tilesService.addDefaultTile(tile);
    this.currentTiles.push(tile);
    // const searchedTile: any = this.tilesCatalog.filter(t => t.code == tile.code && t.title == tile.title)[0];
    // this.tilesService.saveTilesOrder([...this.userTiles, new UserTile(tile.id, searchedTile.settings, 0, true)]);
    this.updateDashboardTiles();

    // Animates the dashboard to take you to focus the added tile.
    setTimeout(() => {
      this.tilesContainer = $(document.querySelector('.uikit-tiles'));
      $('html, body').animate(
        {
          scrollTop: $(
            `[instance-id="${tile.instanceId}"][tile-id="${tile.id}"]`
          ).offset().top,
        },
        'slow'
      );
      this.tilesContainer[0]
        .querySelector(
          `[instance-id="${tile.instanceId}"][tile-id="${tile.id}"]`
        )
        .setAttribute('class', 'add-tile-class');
      setTimeout(() => {
        this.tilesContainer[0]
          .querySelector(
            `[instance-id="${tile.instanceId}"][tile-id="${tile.id}"]`
          )
          .removeAttribute('class');
      }, 5500);
    }, 250);
  }

  /**
   * Loads the dashboard based on the information from the server.
   * @param userTiles Response containing the ordered tile IDs.
   */
  private loadDashboard(userTiles: UserTile[]): void {
    this.currentTiles = [];

    this.tilesNeedOpenAccountIds = this.tilesCatalog.filter(t =>
      this.tilesNeedOpenAccounts.includes(t.code)
    );

    for (const userTile of userTiles) {
      this.TilesValidation(userTile);
    }

    this.addMarketingTiles();

    this.updateDashboardTiles();
    /** Check if the balances are already calculate and disable the Loader */
    if (this.rootScope['balancesAvailable']) {
      this.balanceCalculate = false;
    }

    this.serviceHelper.logWebInformation("User's Tiles Loaded");
  }

  /** Updates the tiles that are rendered in the dashboard. */
  private updateDashboardTiles(): void {
    this.tiles = this.currentTiles
      .filter(t => t !== undefined)
      .map(
        tile =>
          new TileInformation(
            tile.id,
            tile.instanceId,
            `<${tile.code} tile-id="${tile.id}" instance-id="${
              tile.instanceId || 0
            }"></${tile.code}>`
          )
      );
    this.isBusy = false;
    this.scope.$broadcast('$uikitDashboardSave');
  }

  /**
   * Registers the ID of the newly created tile.
   * @param e Angular event object.
   * @param tileInfo Information of the newly created tile.
   */
  private registerCreatedTile(e: ng.IAngularEvent, tileInfo: UserTile): void {
    e.stopPropagation();

    const tileDoesNotExistsYet =
      this.currentTiles.filter(
        tile =>
          tile.instanceId === tileInfo.instanceId && tile.id === tileInfo.tileId
      ).length === 0;

    if (tileDoesNotExistsYet) {
      const tile = this.currentTiles.find(
        tile => !tile.instanceId && tile.id === tileInfo.tileId
      );
      if (tile) {
        tile.instanceId = tileInfo.instanceId;
      }

      this.updateDashboardTiles();
    } else {
      this.scope.$broadcast('$uikitDashboardSave');
    }
  }

  /**
   * Adds the marketing tiles in case they do not exist yet.
   */
  private addMarketingTiles(): void {
    this.tilesCatalog = this.tilesCatalog.filter(t => t !== undefined);
    this.currentTiles = this.currentTiles.filter(t => t !== undefined);

    const sitecoreTile = this.tilesCatalog.find(
      tile => tile.code === 'sitecore'
    );
    sitecoreTile.instanceId = 0;
    const doesNotHaveSitecoreTile = !this.currentTiles.some(
      tile => tile.id === sitecoreTile.id
    );
    if (doesNotHaveSitecoreTile) {
      this.currentTiles.push(sitecoreTile);
    }

    const sitecoreMortgageTile = this.tilesCatalog.find(
      tile => tile.code === 'sitecore-mortgage-tile'
    );
    if (sitecoreMortgageTile) {
      if (this.isBlendFlagActive) {
        if (
          !this.currentTiles.some(t => t.title === sitecoreMortgageTile.title)
        ) {
          sitecoreMortgageTile.instanceId = 0;
          this.currentTiles.push(sitecoreMortgageTile);
        }
      } else {
        const indexTile = this.currentTiles.findIndex(
          t => t.title === sitecoreMortgageTile.title
        );
        if (indexTile >= 0) {
          this.currentTiles.splice(indexTile, 1);
        }
      }
    }
  }

  private setUrls(): void {
    this.loansUrl = this._env.loansUrl;
    this.depositsUrl = this._env.depositsUrl;
  }

  private setDefaultTilesForOnlyTrading() {
    if (
      this.userSubtypeHelper.isAxosTradingOnly() &&
      this.featureFlagService.isAxosTradingActive()
    ) {
      const searchedTile: any = this.tilesCatalog.filter(
        t => t.code == 'quick-transfer' && t.title == 'Move Money'
      )[0];
      const moveMoneyTile = this.userTiles.find(
        userTile => userTile.tileId == searchedTile.id
      );
      const tileToPush = {
        instanceId: 0,
        tileId: searchedTile.id,
        settings: searchedTile.settings,
      };
      if (!moveMoneyTile) {
        if (this.userTiles.length > 2) {
          const thirdElement = this.userTiles[2];
          this.userTiles[2] = tileToPush;
          this.userTiles.push(thirdElement);
        } else this.userTiles.push(tileToPush);
      } else {
        if (this.userTiles.length > 2) {
          const existingTileIndex = this.userTiles.indexOf(moveMoneyTile);
          const thirdElement = this.userTiles[2];
          this.userTiles[2] = tileToPush;
          this.userTiles[existingTileIndex] = thirdElement;
        }
      }
    }
    this.userTiles = this.userTiles.filter(x => x);
    return;
  }

  private accOverviewValidation_Or_NeedsAccountOpen(
    tile: any,
    userTile: any
  ): Boolean {
    return (
      (!this.allAccounts.length &&
        this.tilesNeedOpenAccountIds.filter(t => t.id === userTile.tileId)
          .length !== 0) ||
      (tile.code === 'account-overview' &&
        !this.cdAccounts.length &&
        !this.allAccounts.length &&
        !this.tradingAccounts.length &&
        this.axosInvestHelper.isInvestAccountEmpty() &&
        !this.advisoryAccounts)
    );
  }

  private TilesValidation(userTile: any) {
    const tile = Object.assign(
      {},
      this.tilesCatalog.find(tileObject => tileObject.id === userTile.tileId)
    );

    if (!tile) {
      const { tileId, instanceId } = userTile;
      this.deleteTile(new TileInformation(tileId, instanceId));
    } else if (this.accOverviewValidation_Or_NeedsAccountOpen(tile, userTile)) {
      // force to push tile move money in the currentTiles array when user is only trading
      if (
        (this.userSubtypeHelper.isAxosTradingOnly() ||
          this.userHaveAdvisoryAccounts) &&
        this.featureFlagService.isAxosTradingActive()
      ) {
        if (tile.title === 'Move Money' && tile.code === 'quick-transfer') {
          tile.instanceId = userTile.instanceId;
          this.currentTiles.push(tile);
        }
      }
    } else {
      tile.instanceId = userTile.instanceId;
      this.currentTiles.push(tile);
    }
  }

  validateRiaInvestorCheckingAccountFlow() {
    this.reloadAccountsIfOnboardingFlow();
    combineLatest([
      this.accountsService.hasAxosInvestorCheckingAccounts(),
      this.store.select(getAxosAdvisoryAccounts),
    ])
      .pipe(
        filter(
          ([hasAxosInvestorCheckingAccountsResponse, aasAccounts]) =>
            hasAxosInvestorCheckingAccountsResponse != null &&
            aasAccounts != null
        ),
        first()
      )
      .subscribe(([hasAxosInvestorCheckingAccountsResponse, aasAccounts]) => {
        if (
          !hasAxosInvestorCheckingAccountsResponse.data
            .hasInvestorCheckingAccount
        ) {
          this.agreementService
            .validateAgreements(
              AasAgreementType.MultiAccountAgreementInvestorChecking
            )
            .subscribe(agreementsValidationResponse => {
              switch (agreementsValidationResponse.data.agreementResult) {
                case AgreementsTypeResponse.Refused:
                  this.rootScope['dashboardModalsCanBeDisplayed'] = true;
                  this.rootScope.$broadcast('dashboardModalsCanBeDisplayed');
                  break;
                case AgreementsTypeResponse.Accepted:
                  if (this.isRiaPilotOdlActive) {
                    const agreementDecision = Boolean(
                      sessionStorage.getItem('mAgreementDecision')
                    );
                    const viewedWelcomePage = Boolean(
                      sessionStorage.getItem('viewedWelcomePage')
                    );

                    if (!agreementDecision && !viewedWelcomePage) {
                      if (
                        this.featureFlagService.isRiaPilotICODLDebitCardActive()
                      ) {
                        this.dialogService.openByComponentRef(
                          InvestorCheckingEnrollmentModalComponentV2,
                          {
                            disableClose: true,
                          }
                        );
                      } else {
                        this.dialogService.openByComponentRef(
                          InvestorCheckingEnrollmentModalComponent,
                          {
                            disableClose: true,
                          }
                        );
                      }
                    }
                  } else {
                    this.rootScope['dashboardModalsCanBeDisplayed'] = true;
                    this.rootScope.$broadcast('dashboardModalsCanBeDisplayed');
                  }
                  break;
                case AgreementsTypeResponse.AgreementNotFound:
                  if (aasAccounts.length > 0) {
                    // Only show cross sell page if user has at least 1 active ria with at least 1 active account
                    this.state.go('udb.InvestorChecking');
                  } else {
                    this.rootScope['dashboardModalsCanBeDisplayed'] = true;
                    this.rootScope.$broadcast('dashboardModalsCanBeDisplayed');
                  }
                  break;
              }
            });
        } else {
          this.rootScope['dashboardModalsCanBeDisplayed'] = true;
          this.rootScope.$broadcast('dashboardModalsCanBeDisplayed');
        }
      });
  }

  reloadAccountsIfOnboardingFlow() {
    if (localStorage.getItem('onboardingStatus')) {
      this.rootScope['reloadAccounts'] = true;
      this.rootScope.$broadcast('reloadAccounts');
      this.rootScope['reloadInternalAccounts'] = true;
      this.rootScope.$broadcast('reloadInternalAccounts');
      localStorage.removeItem('onboardingStatus');
    }
  }
}
