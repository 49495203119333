import { Action, createReducer, on } from '@ngrx/store';

import { TimePeriod } from '../../enums/time-period.enum';
import { SpendingFilters } from './spending-filters';
import {
  commitFilters,
  recategorizeTransaction,
  resetExcludedAccountsFilter,
  resetExcludedCategoriesFilter,
  resetTimePeriodFilter,
  resetUncommittedFilters,
  setExcludedAccountsFilter,
  setExcludedCategoriesFilter,
  setLastFiltersRequest,
  setSpentByCategories,
  setTimePeriodFilter,
  setTransactions,
  setTransactionsLoading,
} from './spending.actions';
import { SpendingState } from './spending.state';

export const initialState = new SpendingState();

const _reducer = createReducer(
  initialState,
  on(setTransactionsLoading, (state, action) => {
    return {
      ...state,
      transactionsLoading: action.payload,
    };
  }),
  on(setLastFiltersRequest, (state, action) => {
    return {
      ...state,
      lastFiltersRequest: action.payload,
    };
  }),
  on(setTransactions, (state, action) => {
    return {
      ...state,
      transactions: action.payload,
    };
  }),
  on(setSpentByCategories, (state, action) => {
    return {
      ...state,
      spentByCategories: action.payload,
    };
  }),
  on(setTimePeriodFilter, (state, action) => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, timePeriod: action.payload },
    };
  }),
  on(resetTimePeriodFilter, state => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, timePeriod: TimePeriod.ThisMonth },
    };
  }),
  on(setExcludedAccountsFilter, (state, action) => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, excludedAccounts: action.payload },
    };
  }),
  on(setExcludedCategoriesFilter, (state, action) => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, excludedCategories: action.payload },
    };
  }),
  on(resetExcludedCategoriesFilter, state => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, excludedCategories: new Set<number>() },
    };
  }),
  on(resetExcludedAccountsFilter, state => {
    return {
      ...state,
      uncommittedFilters: { ...state.uncommittedFilters, excludedAccounts: new Set<string>() },
    };
  }),
  on(resetUncommittedFilters, state => {
    return {
      ...state,
      uncommittedFilters: new SpendingFilters(),
    };
  }),
  on(commitFilters, state => {
    return {
      ...state,
      committedFilters: {
        ...state.committedFilters,
        timePeriod: state.uncommittedFilters.timePeriod,
        excludedAccounts: new Set(state.uncommittedFilters.excludedAccounts),
        excludedCategories: new Set(state.uncommittedFilters.excludedCategories),
      },
    };
  }),
  on(recategorizeTransaction, (state, action) => {
    const transaction = state.transactions.find(x => x.transactionId === action.payload.transactionId);

    return {
      ...state,
      transactions: [
        ...state.transactions.filter(x => x.transactionId !== action.payload.transactionId),
        { ...transaction, olbCategoryId: action.payload.olbCategoryId, olbCategoryName: action.payload.olbCategory },
      ],
    };
  })
);

export function spendingReducer(state: SpendingState, action: Action) {
  return _reducer(state, action);
}
