import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { GOAL_ICONS } from '@app/axos-invest/constants';
import { GoalType, TransactionType } from '@app/axos-invest/enums';
import { Accounts, ForecastItem, ForecastParams, Goal, ScheduledTransfer, Transfer } from '@app/axos-invest/models';
import { AxosInvestService, AxosInvestUrlsService } from '@app/axos-invest/services';
import { getScheduledTransfers } from '@app/axos-invest/store/selectors';
import { getRecommendedAutoDepositFilter, mapFrequencyToForecastFrequency } from '@app/axos-invest/utils';
import { STATE, STATE_PARAMS } from '@core/tokens';
import { AxosInvestIcons, FilesIcons, NavigationIcons } from '@shared/enums';

@Component({
  selector: 'app-goal-projection',
  templateUrl: './goal-projection.component.html',
  styleUrls: ['./goal-projection.component.scss'],
})
export class GoalProjectionComponent implements OnInit, OnDestroy {
  @Input() milestoneData: Goal;

  transfersList: Transfer[];
  accountsList: Accounts[];
  milestoneId: string;
  forecastList: ForecastItem[];
  projectionValue: ForecastItem;
  simulateDepositValues: ForecastParams;
  initialDepositValues: ForecastParams;
  projectionValueLow: number;
  projectionValueHigh: number;
  projectionValueDate: string;
  hasRecurringDeposit = false;
  recurringDesposits: ScheduledTransfer[];
  recommendedDepositAmount: number;
  isFeatureActive: boolean;
  isLoading = true;
  isLoadingRecurringDeposit = true;
  isLoadingProjection = true;
  isLoadingSimulateDeposit = true;
  referrerTabId = 1;
  viewProjectionDisclaimer = false;
  editGoalAxosInvest = [GoalType.Custom, GoalType.Retirement, GoalType.RainyDay];
  get goalIcon() {
    return GOAL_ICONS[this.milestoneData.type];
  }

  icons = {
    edit: FilesIcons.Pencil,
    goalType: null,
    badge: AxosInvestIcons.BadgeIcon,
    down: NavigationIcons.ChevronDown,
  };
  private subSink = new SubSink();

  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    @Inject(STATE) private state: ng.ui.IStateService,
    private axosInvestService: AxosInvestService,
    private store: Store,
    private changeDetector: ChangeDetectorRef,
    private axosInvestUrlsService: AxosInvestUrlsService
  ) {}

  ngOnInit(): void {
    this.milestoneId = this.params['id'];
    this.getMilestoneData();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  getForecastList(forecastParams?: ForecastParams) {
    this.isLoadingProjection = true;

    this.axosInvestService
      .getMilestoneForecast(this.milestoneId, forecastParams)
      .pipe(finalize(() => {}))
      .subscribe(response => {
        this.forecastList = response.data.total.totals;
        this.projectionValue = this.forecastList[this.forecastList.length - 1];
        this.calculateProjectionValues();
      });
  }

  calculateProjectionValues() {
    this.projectionValueLow = Number(this.projectionValue.value) * 0.9;
    this.projectionValueHigh = Number(this.projectionValue.value) * 1.1;
    this.projectionValueDate = this.projectionValue.date;
    this.isLoadingProjection = false;
  }

  getRecurringDeposits() {
    this.subSink.sink = this.store.select(getScheduledTransfers).subscribe(transactions => {
      if (transactions === null) {
        this.getForecastList();
        this.isLoadingRecurringDeposit = false;
      } else {
        transactions = JSON.parse(JSON.stringify(transactions));
        this.recurringDesposits = transactions.filter(
          transfer =>
            transfer.milestoneId === this.milestoneId &&
            transfer.transactionType === TransactionType.Deposit &&
            transfer.frequency >= 2
        );
        if (this.recurringDesposits.length > 0) {
          this.hasRecurringDeposit = true;
          this.getAccounts();
        } else {
          this.getForecastList();
          this.isLoadingRecurringDeposit = false;
        }
      }
    });
  }

  getMilestoneData() {
    this.icons.goalType = this.goalIcon;
    this.accountsList = this.milestoneData.accounts;
    this.isLoading = false;
    this.getRecurringDeposits();
    this.getRecommendation();
    this.isLoadingRecurringDeposit = false;
  }

  getAccounts() {
    this.recurringDesposits.forEach(deposit => {
      const account = this.accountsList.find(a => a.accountNumber === deposit.accountNumber);
      if (account) {
        deposit.accountName = account.name;
      }
    });
    const transfer = this.recurringDesposits.reduce((res, deposit) => {
      return deposit.frequency > res.frequency ? deposit : res;
    });
    const highestFrequencyDeposit = {
      amount: transfer.amount,
      frequency: mapFrequencyToForecastFrequency(transfer.frequency),
    };
    this.buildForecastParams(highestFrequencyDeposit);
    this.getForecastList(this.simulateDepositValues);
    this.isLoadingRecurringDeposit = false;
  }

  simulateDepositEvent(forecastValues: any) {
    this.buildForecastParams(forecastValues);
    this.getForecastList(this.simulateDepositValues);
  }

  buildForecastParams(forecastValues: any) {
    this.simulateDepositValues = {
      autoDepositAmount: forecastValues.amount,
      autoDepositFrequency: forecastValues.frequency,
    };
  }

  getRecommendation() {
    const filter = getRecommendedAutoDepositFilter(this.milestoneData);
    this.axosInvestService
      .getRecommendedAutoDeposit(this.milestoneData.type, filter)
      .pipe(
        finalize(() => {
          this.isLoadingSimulateDeposit = false;
          this.changeDetector.detectChanges();
        })
      )
      .subscribe(response => (this.recommendedDepositAmount = response.data.amount));
  }

  redirectToEditGoal(goalType: GoalType) {
    if (this.editGoalAxosInvest.includes(goalType)) {
      this.state.go('udb.axosinvest.editgoal', {
        referrerTabId: 1,
      });
    } else {
      this.subSink.sink = this.axosInvestUrlsService
        .getEditGoalUrl(this.milestoneId)
        .subscribe(url => window.open(url, '_blank', 'noopener noreferrer'));
    }
  }

  toggleProjectionDisclaimer() {
    this.viewProjectionDisclaimer = !this.viewProjectionDisclaimer;

    return this.viewProjectionDisclaimer;
  }
}
