import { Action, createReducer, on } from '@ngrx/store';

import { signOut } from '@app/store/actions';

import { addUpdateAvailableCashAccountAction } from '../available-cash.actions';
import { AvailableCashState, initialAvailableCashState } from '../types';

const reducer = createReducer(
  initialAvailableCashState,

  on(addUpdateAvailableCashAccountAction, (_state, action) => {
    const indexOfUpdated = _state.accountsAvailableCash.findIndex(
      m => m.accountNumber === action.payload.accountNumber
    );
    if (indexOfUpdated >= 0) {
      return {
        accountsAvailableCash: _state.accountsAvailableCash.map(item => {
          return item.accountNumber === action.payload.accountNumber ? (item = action.payload) : item;
        }),
        selectedAccountAvailableCash: action.payload.accountNumber,
      };
    } else {
      return {
        accountsAvailableCash: [..._state.accountsAvailableCash, action.payload],
        selectedAccountAvailableCash: action.payload.accountNumber,
      };
    }
  }),

  on(signOut, () => {
    return initialAvailableCashState;
  })
);

export function availableCashReducer(state: AvailableCashState, action: Action) {
  return reducer(state, action);
}
