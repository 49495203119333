<div class="main">
  <div class="main-container">
    <div class="logos-container">
      <div *ngIf="!isUsingCustomBranding; else customBranding">
        <ng-lottie [width]="'200px'" [height]="'200px'" [options]="options"></ng-lottie>
      </div>
      <ng-template #customBranding>
        <div id="brandLogo" [ngStyle]="{ background: 'url(' + logo + ')' }"></div>
        <hr />
        <img src="assets/img/logos/axos-w-logo.svg" style="width: 144px; height: 31px;" />
      </ng-template>
    </div>
    <div class="text-container">
      <div class="text-title">
        {{ title }}
      </div>
      <div [innerHTML]="text" class="text"></div>
    </div>
    <div class="button-container">
      <button class="continue-button" (click)="onClick()">Continue</button>
    </div>
  </div>
</div>
