import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';

import { materialTabsProvider } from '@app/support/providers';
import { SharedModule } from '@shared/shared.module';

import { supportComponents } from './components';
import { materialImports } from './config';
import { SupportService } from './services';
import { RIAServices } from './services/ria.service';
import { ContactFirmsEffects } from './store/contact-firms/contact-firms.effects';
import { ContactFirmFacade } from './store/contact-firms/contact-firms.facade';
import { contactFirmsReducer } from './store/contact-firms/contact-firms.reducer';
import { SupportViewFacade } from './store/support-view/support-view-facade';
import { RIAUserEffect } from './store/support-view/support-view.effects';
import { hasAxosAccountsReducer, isRIAPilotReducer, isRIAUserReducer } from './store/support-view/support-view.reducer';
import { supportSubmodules } from './submodules';
import { supportViews } from './views';

@NgModule({
  declarations: [...supportComponents, ...supportViews],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    ...materialImports,
    ...supportSubmodules,
    StoreModule.forFeature('IsRIAUser', isRIAUserReducer),
    StoreModule.forFeature('RIAPilotFlag', isRIAPilotReducer),
    StoreModule.forFeature('ContactFirms', contactFirmsReducer),
    StoreModule.forFeature('userHasNoAxosAccounts', hasAxosAccountsReducer),
  ],
  exports: [...supportComponents, ...supportViews],
  providers: [
    materialTabsProvider,
    RIAUserEffect,
    RIAServices,
    SupportService,
    SupportViewFacade,
    ContactFirmsEffects,
    ContactFirmFacade,
  ],
})
export class SupportModule {}
