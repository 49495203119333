import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LEGACY_CUSTOMER_LIMITS_HELPER,
  LEGACY_FILTER,
  LEGACY_SERVICE_HELPER,
  LEGACY_TRANSACTION_SERVICE,
} from '@app/transfers/ajs-upgraded-providers';
import { EditExternalTransferModalData, ExternalAccountEditRequest } from '@app/transfers/models';
import { CustomerLimitsHelper } from '@legacy/services/customer-limits.helper';
import { ITransactionService } from '@legacy/services/typings/ITransactionService';
import { DateHelperService } from '@app/core/services/date.service';
import { IFilterService } from 'angular';
// ! DO NOT DELETE THIS IS JUST FOR UNIT TESTING -> JQUERY RELATED
import * as moment from 'moment';
import { DatePickerReferenceHandler } from '@app/transfers/utils';
import { DatePickerEvents } from '@shared/models';
declare var $: any;

@Component({
  selector: 'app-edit-external-transfer-modal',
  templateUrl: './edit-external-transfer-modal.component.html',
  styleUrls: ['./edit-external-transfer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditExternalTransferModalComponent implements OnInit {
  errorMsg: string | undefined | null;
  isLoadingSetup: boolean = false;
  // Datepickers
  datePickerOptions: any;
  datePicker: any;
  minDate: Date;
  isTodayValidForExternal: boolean;

  notificationErrorMsg: string;
  customerLimits: CustomerLimits;

  transferToEdit: ExternalAccountEditRequest;
  isSeries: boolean;
  dateRangePickerOptions: any;
  frequencies: GenericOption[];
  dateHandler!: DatePickerReferenceHandler;
  constructor(
    @Inject(LEGACY_TRANSACTION_SERVICE) public _transactionService: ITransactionService,
    @Inject(LEGACY_SERVICE_HELPER) public _serviceHelper: IServiceHelper,
    @Inject(LEGACY_FILTER) public filterService: IFilterService,
    @Inject(LEGACY_CUSTOMER_LIMITS_HELPER) public customerLimitsHelper: CustomerLimitsHelper,
    @Inject(MAT_DIALOG_DATA) public dialogContext: EditExternalTransferModalData,
    public dateHelper: DateHelperService,
    public dialogRef: MatDialogRef<EditExternalTransferModalComponent>
  ) {
    this.setContextProperties();
  }

  private setContextProperties(): void {
    this.transferToEdit = this.dialogContext?.transferToEdit;
    this.isSeries = this.dialogContext?.isSeries;
    this.dateRangePickerOptions = this.dialogContext?.datePickerOptions;
    this.frequencies = this.dialogContext?.frequencies;
    this.dateHandler = new DatePickerReferenceHandler(this.transferToEdit?.processingDate);
  }

  ngOnInit(): void {
    this.checkCustomerLimits();
    this.isTodayValidForExternal = true;
    this.minDate = this.dateHelper.normalizeDate(moment()).toDate();
    let { isCutOffTime, externalTransferValidDate } = this.dateHelper.getDefaultPaymentInformation();

    this.minDate = externalTransferValidDate.toDate();
    if (isCutOffTime) this.isTodayValidForExternal = false;
    this.datePickerOptions = {
      ...this.dateRangePickerOptions,
      startDate: moment(this.transferToEdit?.processingDate),
      minDate: this.minDate,
      maxDate: moment().add(13, 'M'),
      singleDatePicker: true,
      autoUpdateInput: true,
      isInvalidDate: (date: Date) => {
        return !this.dateHelper.isValidDate(moment(date));
      },
    };
    this.errorMsg = null;
  }

  transformDateEvent($event: DatePickerEvents) {
    this.transferToEdit.processingDate = $event.picker.startDate.toDate();
  }

  public async saveTransfer(): Promise<void> {
    if (this.validateAmount()) {
      this.isLoadingSetup = true;
      const serviceMethod = this.isSeries ? 'scheduleEditExternalTransfer' : 'scheduleEditNextExternalTransfer';
      try {
        await this._transactionService[serviceMethod]({
          ...this.transferToEdit,
          Amount: this.transferToEdit.amount,
          ProcessingDate: this.transferToEdit.processingDate,
        } as any);
        this.dialogRef.close(true);
      } catch (err) {
        if (err.data.message.includes('amount exceeds maximum customer limit')) {
          return this.showAmountExceededErrorMessage();
        }
        this._serviceHelper.errorHandler(err);
      } finally {
        this.isLoadingSetup = false;
      }
    }
  }

  public closeModal() {
    this.dialogRef.close(false);
  }

  public showCalendar(): void {
    const dp = $('#date');
    if (!dp.data('daterangepicker').isShowing) dp.data('daterangepicker').show();
    else dp.data('daterangepicker').hide();
  }
  public validateAmount(): boolean {
    if (this.transferToEdit.amount > 0) {
      this.errorMsg = null;
      return true;
    }
    this.errorMsg = 'Please enter Amount';
    return false;
  }

  async checkCustomerLimits(): Promise<void> {
    try {
      const response = await this.customerLimitsHelper.fetchCustomerLimits();
      this.customerLimits = response;
    } catch (error) {
      this._serviceHelper.errorHandler(error);
    }
  }

  private showAmountExceededErrorMessage() {
    this.notificationErrorMsg =
      this.transferToEdit.transferType === 1
        ? `Transfer amount exceeds daily outbound limit of
             ${this.filterService('currency')(this.customerLimits.extTransfersMaxOutgoingAmount)}`
        : `Transfer amount exceeds daily inbound limit of
             ${this.filterService('currency')(this.customerLimits.extTransfersMaxIncomingAmount)}`;
  }

  setPropertiesForCalendar() {
    const input = $('#date').first();
    const daterangepicker = $('.daterangepicker');

    daterangepicker.css('left', input.offset().left + input.outerWidth() + 10);
    daterangepicker.css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    daterangepicker.append('<div id="arrow-left"></div>');
    daterangepicker.addClass('no-after');

    $('#arrow-left')
      .css('position', 'absolute')
      .css('left', '-8px')
      .css('width', '0')
      .css('height', '0')
      .css('border-top', '8px solid transparent')
      .css('border-bottom', '8px solid transparent')
      .css('border-right', '8px solid darkgray')
      .css('top', daterangepicker.outerHeight() / 2);

    $(document).on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });

    $('.modal-body').on('scroll', function () {
      daterangepicker.first().css('top', input.offset().top - daterangepicker.outerHeight() / 2);
    });
  }
}
