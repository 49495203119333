import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { finalize, map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { AddressType, EmploymentStatus } from '@app/user-profile/enums';
import { EmploymentInfo } from '@app/user-profile/models';
import { getEmploymentStatuses } from '@app/user-profile/store/selectors';
import { createAddressForm } from '@app/user-profile/utils';
import { capitalize, mapObjectToKeyValuePair } from '@app/utils';
import { KeyValuePair } from '@core/models';
import { UserProfileService } from '@core/services';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'up-change-employment-info',
  templateUrl: './change-employment-info.component.html',
  styleUrls: ['../../scss/user-profile.common.scss'],
})
export class ChangeEmploymentInfoComponent implements OnInit, OnDestroy {
  @Input() employmentInfo: Partial<EmploymentInfo>;

  @Output() back = new EventEmitter<null>();
  @Output() updateEmploymentInfo = new EventEmitter<EmploymentInfo>();

  ocupationsList: string[] = [];
  addressTypes = AddressType;
  employmentInfoForm: UntypedFormGroup;
  isBusy = false;
  employmentStatuses: KeyValuePair<string, string>[];
  iraEnhBaseFlagActive: boolean;
  employed = false;
  employerAddressLabels = {
    streetAddress1: 'Employer Address 1',
    streetAddress2: 'Employer Address 2',
    postalCode: 'Employer Zip Code',
    city: 'Employer City/Town',
    state: 'Employer State',
  };
  hasOccupation = false;
  occupationLabel = 'Occupation';

  private unavailableOccupations: string[] = ['retired'];
  private statusesWithOccupation: string[] = [
    EmploymentStatus.Retired,
    EmploymentStatus.Unemployed,
    EmploymentStatus.SelfEmployed,
    EmploymentStatus.Employed,
  ];
  private statusesWithMostRecentOccupation: string[] = [EmploymentStatus.Unemployed, EmploymentStatus.Retired];
  private subsink = new SubSink();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private userProfileService: UserProfileService,
    private featureFlagService: FeatureFlagService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    this.iraEnhBaseFlagActive = this.featureFlagService.isIraEnhBaseFlagActive();
    const { employer, employerAddress, employmentStatus, occupation, yearsEmployed } = this.employmentInfo;
    this.hasOccupation = this.statusesWithOccupation.includes(employmentStatus);
    this.employed = employmentStatus === EmploymentStatus.Employed;

    this.employmentInfoForm = this.fb.group({
      employer: this.fb.control(employer, [Validators.required, Validators.maxLength(30)]),
      employmentStatus: this.fb.control(employmentStatus ?? '', [Validators.required]),
      occupation: this.fb.control(capitalize(occupation), [Validators.required]),
      yearsEmployed: this.fb.control(yearsEmployed ?? 0, [Validators.required]),
    });

    if (this.iraEnhBaseFlagActive && this.employed) {
      this.employmentInfoForm.addControl(
        'employerAddress',
        createAddressForm(employerAddress ?? {}, AddressType.Employer)
      );
    }

    this.occupationLabel = this.statusesWithMostRecentOccupation.includes(employmentStatus)
      ? 'Most Recent Occupation'
      : 'Occupation';
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  submitEmploymentInfo(): void {
    if (this.employmentInfoForm.invalid) {
      this.employmentInfoForm.markAllAsTouched();
    } else {
      this.isBusy = true;
      const employmentInfo: EmploymentInfo = this.employmentInfoForm.value;

      this.userProfileService
        .updateEmploymentInfo(employmentInfo)
        .pipe(
          finalize(() => {
            this.isBusy = false;
          })
        )
        .subscribe({
          next: () => {
            this.updateEmploymentInfo.emit(employmentInfo);
            this.back.emit();
          },
          error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        });
    }
  }

  restrictOnlyTwoCharacters(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;

    if (/[0-9]/.test(event.key) && target.value.length === 2) {
      event.preventDefault();
    }
  }

  onBlur(event) {
    event.currentTarget.size = 1;
  }

  onFocus(event) {
    event.currentTarget.size = 5;
    event.currentTarget.style.backgroundPosition = '100% 50%';
  }

  onChange(event) {
    event.currentTarget.size = 1;
    event.currentTarget.style.backgroundPosition = '98% 50%';
    event.currentTarget.blur();
  }

  private initSubscriptions(): void {
    this.subsink.sink = this.store
      .select(getEmploymentStatuses)
      .pipe(map(data => mapObjectToKeyValuePair(data)))
      .subscribe(statuses => {
        this.employmentStatuses = statuses;
      });

    this.subsink.sink = this.userProfileService.getOccuppactionList().subscribe({
      next: result => {
        this.ocupationsList = result.data
          .filter(o => this.unavailableOccupations
              .find(u =>
                u !== o.toLowerCase()
                )
              );
      },
      error: exception => {
        this.serviceHelper.errorHandler(exception);
      },
    });

    this.subsink.sink = this.employmentInfoForm.get('employmentStatus').valueChanges.subscribe(status => {
      if (!status) {
        this.employed = false;
        this.hasOccupation = false;
      } else {
        const hasOccupation = this.statusesWithOccupation.includes(status);
        const employed = status === EmploymentStatus.Employed;
        this.employed = employed;
        this.hasOccupation = hasOccupation;
        this.occupationLabel = this.statusesWithMostRecentOccupation.includes(status)
          ? 'Most Recent Occupation'
          : 'Occupation';
        this.toggleAddressFields(employed);
        this.toggleOccupationFields(hasOccupation, employed, status);
      }
    });
  }

  private toggleAddressFields(employed: boolean): void {
    if (!employed) {
      this.employmentInfoForm.removeControl('employerAddress');
    } else {
      this.employmentInfoForm.get('employer').patchValue('');
      this.employmentInfoForm.get('yearsEmployed').patchValue(0);
      this.employmentInfoForm.addControl('employerAddress', createAddressForm({}, AddressType.Employer));
    }
  }

  private toggleOccupationFields(hasOccupation: boolean, employed: boolean, status: string): void {
    const statusItem = this.employmentStatuses.find(item => item.key === status).value;
    if (hasOccupation) {
      this.employmentInfoForm.get('occupation').patchValue('');
    } else {
      this.employmentInfoForm.get('occupation').patchValue(statusItem);
    }

    if (!employed) {
      this.employmentInfoForm.get('employer').patchValue(statusItem);
    }
  }
}
