class CreateAlert implements ng.IDirective {
  restrict = 'E';
  templateUrl = 'alerts-notifications/my-alerts/directives/create-alert.html';
  replace = true;
  controller = 'CreateAlertController as vm';

  constructor() {}

  static factory(): ng.IDirectiveFactory {
    const directive = () => new CreateAlert();
    directive.$inject = [];
    return directive;
  }
}

export { CreateAlert };
