import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { DocumentDetail } from '@app/accounts/models';
import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';
import { WebApiResponse } from '@shared/models';

@Injectable()
export class AxosClearingService extends BaseService {
  constructor(
    @Inject(olbSettings) settings: OlbSettings,
    http: HttpClient,
    private readonly featureFlagService: FeatureFlagService,
    private readonly userSubtypeHelper: UserSubtypeHelper
  ) {
    super(http, settings, 'axosclearing');
  }

  getDocuments(accountNumber: string): WebApiResponse<DocumentDetail[]> {
    return this.get(`accounts/${accountNumber}/documents`);
  }

  getPositions(accountNumber: string): WebApiResponse<TradingPosition[]> {
    return this.get(`accounts/${accountNumber}/positions`);
  }

  isAxosTradingActiveForUser(): boolean {
    return this.featureFlagService.isAxosTradingActive() && this.userSubtypeHelper.hasAxosTrading();
  }
}
