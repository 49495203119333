<div class="main-container">
  <div class="portfolio-allocation__tab-header">
    <div class="portfolio-allocation__tab-title">
      <h3>{{ tabTitle }}</h3>
    </div>
  </div>

  <div class="portfolio-allocation__divider"></div>
  <app-dot-loader *ngIf="!chartReady">Loading Portfolio Allocation...</app-dot-loader>

  <div class="portfolio-allocation__chart-container">
    <!-- Left container - Chart -->
    <div class="portfolio-allocation__chart" *ngIf="chartReady">
      <app-donut-chart
        class="portfolio-allocation__chart-donut"
        [height]="210"
        [width]="210"
        [chartjsOptions]="chartOptions"
        [categories]="portfolioAllocationGrid"
      ></app-donut-chart>
    </div>

    <!-- Right container - Table -->
    <div class="portfolio-allocation__chart-table">
      <!-- Asset column-->
      <div class="portfolio-allocation__container-asset">
        <div class="portfolio-allocation__title-asset">Asset Type</div>

        <ng-container *ngIf="emptyChart; else asset">
          <p class="portfolio-allocation__row-empty">--</p>
        </ng-container>

        <ng-template #asset>
          <div
            class="portfolio-allocation__container-row-asset"
            *ngFor="let portfolioAllocation of portfolioAllocationTile?.portfolioAllocations; let i = index"
          >
            <span class="portfolio-allocation__row-box-asset" [style.backgroundColor]="getColorBox(i)"></span>
            <p class="portfolio-allocation__row-asset">
              {{ descriptionFormat(portfolioAllocation.description) }}
            </p>
          </div>
        </ng-template>
      </div>

      <!-- Quantity column -->
      <div class="portfolio-allocation__container-quantity">
        <div class="portfolio-allocation__title-quantity">Value $</div>

        <ng-container *ngIf="emptyChart; else quantity">
          <p class="portfolio-allocation__row-empty">--</p>
        </ng-container>

        <ng-template #quantity>
          <p
            class="portfolio-allocation__row-quantity"
            *ngFor="let portfolioAllocation of portfolioAllocationTile?.portfolioAllocations; let i = index"
          >
            {{ formatValue(portfolioAllocation.value) }}
          </p>
        </ng-template>
      </div>

      <!-- Percent column -->
      <div class="portfolio-allocation__container-percent">
        <div class="portfolio-allocation__title-percent">Current %</div>

        <ng-container *ngIf="emptyChart; else percent">
          <p class="portfolio-allocation__row-empty">--</p>
        </ng-container>

        <ng-template #percent>
          <ng-container *ngIf="portfolioAllocationTile?.portfolioAllocations.length === 1; else normalRendering">
            <p class="portfolio-allocation__row-percent">100%</p>
          </ng-container>

          <ng-template #normalRendering>
            <p
              class="portfolio-allocation__row-percent"
              *ngFor="let portfolioAllocation of portfolioAllocationTile?.portfolioAllocations; let i = index"
            >
              {{ portfolioAllocation.percent < 0.0001 ? '<0.01' : percentFormat(portfolioAllocation.percent) }}%
            </p>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
