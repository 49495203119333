export class DecodedJwt extends Map<string, string> {
  constructor(token: string) {
    const [, rawBase64Url] = token.split('.');
    const base64 = rawBase64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    const entries = Object.entries(JSON.parse(jsonPayload)) as [string, string][];

    super(entries);
  }
}
