import { Inject } from '../decorators/decorators';

@Inject('$stateParams')
export class SignatureCardController {
  accountNumber: string;
  accountId: number;

  constructor(private stateParamsService: ng.ui.IStateParamsService) {
    this.accountNumber = this.stateParamsService['accountNumber'];
    this.accountId = this.stateParamsService['accountId'];
  }
}
