import { Inject } from '../decorators/decorators';
import { StopPayment } from '../typings/app/StopPayment';
import { BaseService } from './base.service';
import { IStopPaymentService } from './typings/IStopPaymentService';

@Inject('$http', 'env', 'serviceHelper', '$q')
class StopPaymentService extends BaseService implements IStopPaymentService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'stopPayment', _serviceHelper, q);
  }

  getStopPayments(accountId: string): ApiResponse<StopPayment[]> {
    return this.get(`${accountId}`);
  }

  addStopPayment(
    stopPayment: StopPayment,
    validate: boolean = true
    ): ApiResponse<string> {
    return this.post(`${validate}`, stopPayment);
  }

  cancelStopPayment(accountId: number, seq: number): ApiResponse<string> {
    return this.post(`${accountId}/${seq}`);
  }

  extendStopPayment(stopPayment: StopPayment): ApiResponse<string> {
    return this.put('extend', stopPayment);
  }
}

export { StopPaymentService };
