<div class="header-section-title text-center">
  <section class="frow center header">
    <div class="header__title">
      <h1 class="font-size">Insights</h1>
    </div>
  </section>
</div>

<div class="frow center">
  <div (click)="goToSpending()" class="cursor-pointer">
    <app-spending-module></app-spending-module>
  </div>
</div>
<div class="frow center mt-2">
  <div (click)="goToNetWorth()" class="cursor-pointer">
    <app-net-worth-module></app-net-worth-module>
  </div>
</div>
