import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';

import { StatementsService, TaxFormsResponse } from '../core/services/statements-service';
import { StatementsRequest, StatementsResponse, TaxFormDocumentRequest, TaxFormsRequest } from '../core';
import {
  addUpdateStatementsToAccountAction,
  addUpdateTaxFormsToAccountAction,
  setSelectedAccountDocumentsAction,
  getAllDocumentsAction,
} from '../core/store/actions';
import {
  getAllDocuments,
  getSelectedAccountDocuments,
  getSelectedAccountStatementsLatestThree,
  getSelectedAccountTaxFormsLatestThree,
} from '../core/store/selectors';
import { StatementsAndDocumentsStateType, AccountDocumentsTileStateType, TaxFormStateType } from '../core';
import { InitializeStatementsAndDocumentsInputType } from './types/initialize-statements-and-documents-input.type';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StatementsAndDocumentsFacade {
  isLoadingAllDocuments$ = new BehaviorSubject<boolean>(false);
  selectedAccountDocuments$ = this.store.select(getSelectedAccountDocuments);
  selectedAccountStatementsLatestThree$ = this.store.select(getSelectedAccountStatementsLatestThree);
  selectedAccountTaxFormsLatestThree$ = this.store.select(getSelectedAccountTaxFormsLatestThree);
  selectedAccountAllDocuments$ = this.store.select(getAllDocuments);

  constructor(private statementsService: StatementsService, private store: Store<StatementsAndDocumentsStateType>) {}

  initializeStatementsAndDocuments(input: InitializeStatementsAndDocumentsInputType): void {
    this.isLoadingAllDocuments$.next(true);
    const statementsRequest: StatementsRequest = {
      accountNumber: input.accountNumber,
      statementDate: undefined,
    };

    const taxFormsRequest: TaxFormsRequest = {
      accountNumber: input.accountNumber,
    };

    const getStatementsObservable$ = this.getStatements(statementsRequest);
    const getTaxFormsObservable$ = this.getTaxForms(taxFormsRequest);

    this.getAllDocuments(getStatementsObservable$, getTaxFormsObservable$, input.accountNumber).subscribe();

    this.store.dispatch(
      setSelectedAccountDocumentsAction({
        accountNumber: input.accountNumber,
      })
    );
  }

  getStatements(statementsRequest: StatementsRequest): Observable<AccountDocumentsTileStateType> {
    return this.statementsService.getStatements(statementsRequest).pipe(
      map(response => {
        const statementsResponse = response.data ?? [];
        return this.handleGetStatementsSuccess(statementsResponse, statementsRequest.accountNumber);
      }),
      catchError(() => this.handleGetStatementsErrorResponse(statementsRequest.accountNumber))
    );
  }

  getTaxForms(taxFormsRequest: TaxFormsRequest): Observable<AccountDocumentsTileStateType> {
    return this.statementsService.getTaxForms(taxFormsRequest).pipe(
      map(response => {
        const taxResponse = response.data ?? [];
        return this.handleGetTaxFormsToAccountSuccess(taxResponse, taxFormsRequest.accountNumber);
      }),
      catchError(() => this.handleGetTaxFormsToAccountError(taxFormsRequest.accountNumber))
    );
  }

  getAllDocuments(
    statementsObservable$: Observable<AccountDocumentsTileStateType>,
    taxFormsObservable$: Observable<AccountDocumentsTileStateType>,
    accountNumber: string
  ) {
    return zip(statementsObservable$, taxFormsObservable$).pipe(
      tap(([statements, taxForms]) => {
        this.isLoadingAllDocuments$.next(false);
        const statementsResponse = statements.statements;
        const taxFormsResponse = taxForms.taxForms;

        this.store.dispatch(
          getAllDocumentsAction({
            statementsApiCallWasSuccesful: statements.statementsApiCallWasSuccesful,
            taxFormsApiCallWasSuccesful: taxForms.taxFormsApiCallWasSuccesful,
            accountNumber: accountNumber,
            statements: statementsResponse,
            taxForms: taxFormsResponse,
          })
        );
      })
    );
  }

  downloadStatement(accountNumber: string, date: string, name: string, show: boolean): void {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    const statementDate = `${year}-${month}-${day}`;
    const request: StatementsRequest = {
      accountNumber,
      statementDate,
    };

    this.statementsService.getStatement(request).subscribe(blob => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const jsonResponse = JSON.parse(event.target.result);
        const data = jsonResponse.data.statement;
        const pdfData = this.base64toBlob(data, 'application/pdf');
        const fileName = name + '.pdf';
        if (!show) {
          saveAs(pdfData, fileName);
        } else {
          const blobUrl = URL.createObjectURL(pdfData);
          window.open(blobUrl, '_blank');
        }
      };

      reader.readAsText(blob);
    });
  }

  downloadTaxForm(
    accountNumber: string,
    formType: string,
    taxYear: string,
    hostKey: string,
    name: string,
    show: boolean
  ): void {
    const request: TaxFormDocumentRequest = {
      accountNumber,
      formType,
      taxYear,
      hostKey,
    };

    this.statementsService.getTaxForm(request).subscribe(blob => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const jsonResponse = JSON.parse(event.target.result);
        const data = jsonResponse.data.taxForm;
        const pdfData = this.base64toBlob(data, 'application/pdf');
        const fileName = name + '.pdf';
        if (!show) {
          saveAs(pdfData, fileName);
        } else {
          const blobUrl = URL.createObjectURL(pdfData);
          window.open(blobUrl, '_blank');
        }
      };

      reader.readAsText(blob);
    });
  }

  base64toBlob(base64Data: string, contentType: string): Blob {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  private handleGetStatementsSuccess(statementsResponse: StatementsResponse[], accountNumber: string) {
    const newState: AccountDocumentsTileStateType = {
      statementsApiCallWasSuccesful: true,
      accountNumber: accountNumber,
      statements: statementsResponse,
    };
    this.store.dispatch(addUpdateStatementsToAccountAction(newState));
    return newState;
  }

  private handleGetTaxFormsToAccountSuccess(taxFormsResponse: TaxFormsResponse[], accountNumber: string) {
    const newState: AccountDocumentsTileStateType = {
      taxFormsApiCallWasSuccesful: true,
      accountNumber: accountNumber,
      taxForms: taxFormsResponse as TaxFormStateType[],
    };
    this.store.dispatch(addUpdateTaxFormsToAccountAction(newState));
    return newState;
  }

  private handleGetStatementsErrorResponse(accountNumber: string) {
    const emptyState: AccountDocumentsTileStateType = {
      statementsApiCallWasSuccesful: false,
      accountNumber: accountNumber,
      statements: [],
    };
    this.store.dispatch(addUpdateStatementsToAccountAction(emptyState));
    return of(emptyState);
  }

  private handleGetTaxFormsToAccountError(accountNumber: string) {
    const emptyState: AccountDocumentsTileStateType = {
      taxFormsApiCallWasSuccesful: false,
      accountNumber: accountNumber,
      taxForms: [],
    };
    this.store.dispatch(addUpdateTaxFormsToAccountAction(emptyState));
    return of(emptyState);
  }
}
