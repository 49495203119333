import { combineReducers } from 'redux';
import { AggregatedReducer } from './aggregated.reducer';
import { ExternalReducer } from './external.reducer';
import { InternalAccountReducer } from './internal.reducer';
import { TradingReducer } from './trading.reducer';

// Add here the internal and external reducers when implemented
export const AccountsReducer = combineReducers({
  aggregated: AggregatedReducer,
  external: ExternalReducer,
  internal: InternalAccountReducer,
  trading: TradingReducer,
});
