import { IStateService } from 'angular-ui-router';
import { Inject } from 'decorators/decorators';
import { ModalService } from 'services/modal.service';
import { UserAccountType } from 'typings/app/bills/UserAccountType';

import { Biller } from './typings/Biller';
import { BillerAccount } from './typings/BillerAccount';
import { Captcha } from './typings/Captcha';
import { Credential } from './typings/Credential';
import { NextPaymentResponse } from './typings/NextPaymentResponse';
import { OneTimePasscode } from './typings/OneTimePasscode';
import { PartialRecipient } from './typings/PartialRecipient';
import { PaymentData } from './typings/PaymentData';
import { Recipient } from './typings/Recipient';
import { ScheduledPayment } from './typings/ScheduledPayment';

@Inject('$state', 'modalService')
export class BillPayRouterHelper {
  criteria: string;
  recipient: PartialRecipient;
  biller: Biller;
  billerAddresses: BillerAccountValidationAddress[];
  /* eStatements */
  credentials: Credential[];
  accounts: BillerAccount[];
  sessionId: string;
  recipientDetails: Recipient;
  captcha: Captcha;
  otp: OneTimePasscode;
  scheduledPaymet: ScheduledPayment;
  NextPayment: NextPaymentResponse;
  paymentData: PaymentData[];
  accountFrom: UserAccountType;
  paymentFrequency: number;
  // payment history
  historyFilter: string;
  // active recipient for cancel button returning to Pay section
  activeRecipient: Recipient;
  activeTab: number;

  constructor(private readonly state: IStateService, private readonly modalService: ModalService) {}

  validateRoute(_event: ng.IAngularEvent, routeName: string) {
    switch (routeName) {
      case 'udb.myBills':
      case 'udb.myBills.makePayment':
      case 'udb.myBills.paymentsDue':
        _event.preventDefault();
        this.state.go('udb.billPay.pay');
        break;
      case 'udb.myBills.scheduledPayments':
      case 'udb.myBills.paymentHistory':
        _event.preventDefault();
        this.state.go('udb.billPay.activity');
        break;
    }
  }

  /* cancel setup modal */
  cancelSetup() {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          cancelText: 'No',
          bodyText: `<h3>Are you sure you want to cancel this setup?</h3>`,
        }
      )
      .then(() => {
        this.clean();
        this.state.go('udb.billPay.pay');
      })
      .catch(() => {
        return;
      });
  }

  clean() {
    this.setCriteria(null);
    this.setBiller(null);
    this.setBillerAddresses(null);
    this.setRecipient(null);
    this.setAccounts(null);
    this.setCredentials(null);
    this.setRecipientDetails(null);
    this.setSessionId(null);
    this.setCaptcha(null);
    this.setOtp(null);
    this.setScheduledPayment(null);
    this.setNextPayment(null);
    this.setHistoryFilter(null);
    this.setPaymentFrequency(null);
  }

  cleanActiveRecipient() {
    this.setActiveRecipient(null, '');
  }

  /** helpers to pass info between routes and views */
  getCriteria(): string {
    return this.criteria;
  }

  setCriteria(criteria: string): void {
    this.criteria = criteria;
  }

  getRecipient(): PartialRecipient {
    return this.recipient;
  }

  setRecipient(recipient: PartialRecipient): void {
    this.recipient = recipient;
  }

  getBiller(): Biller {
    return this.biller;
  }

  setBiller(biller: Biller): void {
    this.biller = biller;
  }

  getBillerAddresses(): BillerAccountValidationAddress[] {
    return this.billerAddresses;
  }

  setBillerAddresses(billerAccountValidationAddress: BillerAccountValidationAddress[]): void {
    this.billerAddresses = billerAccountValidationAddress;
  }

  getAccounts(): BillerAccount[] {
    return this.accounts;
  }

  setAccounts(accounts: BillerAccount[]): void {
    this.accounts = accounts;
  }

  getCredentials(): Credential[] {
    return this.credentials;
  }

  setCredentials(credentials: Credential[]): void {
    this.credentials = credentials;
  }

  getRecipientDetails(): Recipient {
    return this.recipientDetails;
  }

  setRecipientDetails(recipient: Recipient): void {
    this.recipientDetails = recipient;
  }

  getSessionId(): string {
    return this.sessionId;
  }

  setSessionId(sessionId: string): void {
    this.sessionId = sessionId;
  }

  getCaptcha(): Captcha {
    return this.captcha;
  }

  setCaptcha(captcha: Captcha): void {
    this.captcha = captcha;
  }

  getOtp(): OneTimePasscode {
    return this.otp;
  }

  setOtp(otp: OneTimePasscode): void {
    this.otp = otp;
  }

  setScheduledPayment(payment: ScheduledPayment): void {
    this.scheduledPaymet = payment;
  }
  getScheduledPayment(): ScheduledPayment {
    return this.scheduledPaymet;
  }
  setNextPayment(payment: NextPaymentResponse): void {
    this.NextPayment = payment;
  }
  getNextPayment(): NextPaymentResponse {
    return this.NextPayment;
  }

  setPaymentsData(paymentData: PaymentData[]): void {
    this.paymentData = paymentData;
  }

  getPaymentData(): PaymentData[] {
    return this.paymentData;
  }

  setAccountFrom(accountFrom: UserAccountType): void {
    this.accountFrom = accountFrom;
  }

  getAccountFrom(): UserAccountType {
    return this.accountFrom;
  }

  getHistoryFilter(): string {
    return this.historyFilter;
  }

  setHistoryFilter(historyFilter: string): void {
    this.historyFilter = historyFilter;
  }
  getPaymentFrequency(): number {
    return this.paymentFrequency;
  }
  setPaymentFrequency(frequencyValue: number): void {
    this.paymentFrequency = frequencyValue;
  }
  setActiveRecipient(recipientToSelect: Recipient, tab: string) {
    this.activeRecipient = recipientToSelect;
    this.activeTab = this.setActiveTab(tab);
  }

  private setActiveTab(section: string): number {
    switch (section) {
      case 'details':
        return 1;
      case 'payments':
        return 2;
      case 'ebill':
        return 3;
      default:
        return 0;
    }
  }
}
