<app-view-header titleText="Report Lost or Stolen">Debit Card {{ cardMask }}</app-view-header>

<div class="view-container">
  <div class="col-12 col-lg-8 mx-auto">
    <h2 class="text-center mb-3">Lost your card? Not to worry, we can send you a new card.</h2>

    <p class="mb-4">
      Keep in mind that by completing this process your card(s) will be immediately and
      <b>permanently canceled</b>
      and a new card(s) will be issued.
    </p>

    <app-alert-notification
      class="col-12 mb-3"
      *ngIf="apiErrorMessage"
      alertType="error"
      (dismiss)="apiErrorMessage = null"
    >
      {{ apiErrorMessage }}
    </app-alert-notification>

    <form [formGroup]="reportForm" *ngIf="!loadingData" (ngSubmit)="submitInformation()">
      <div class="form-group col-12">
        <mat-checkbox formControlName="replaceCard" id="send-replacement">
          Send me a replacement card(s).
        </mat-checkbox>
      </div>

      <div class="form-group col-xl-6">
        <dc-debit-card-address
          [address]="reportForm.get('deliveryAddress').value"
          [enabled]="reportForm.get('replaceCard').value"
          (edit)="canSubmit = !$event"
          (changeAddress)="changeAddress($event)"
        ></dc-debit-card-address>
      </div>

      <app-card-replacement-fee
        [replace]="reportForm.get('replaceCard').value"
        [dueFee]="reportForm.get('dueFee').value"
        (feeSelection)="setDueFee($event)"
      ></app-card-replacement-fee>

      <div class="form__actions" *ngIf="canSubmit">
        <app-dot-loader *ngIf="loading; else formActions">Verifying...</app-dot-loader>

        <ng-template #formActions>
          <button class="btn-ok">Confirm Cancel Card(s)</button>
          <button type="button" class="btn-cancel" (click)="returnToDebitCards()">Cancel</button>
        </ng-template>
      </div>
    </form>
  </div>
</div>
