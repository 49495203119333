import * as angular from 'angular';
import { WireTransfersConfig } from './wire-transfers.config';
import { WireTransfersController } from './wire-transfers.controller';
import { WireTransfersAboutModalController } from './wire-transfers-about-modal/wire-transfers-about-modal.controller';

export const WireTransfers = angular
  .module('udb.WireTransfers', ['ui.router'])
  .config(WireTransfersConfig)
  .controller('WireTransfersController', WireTransfersController)
  .controller('WireTransfersAboutController', WireTransfersAboutModalController).name;
