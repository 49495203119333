export const SIZE_DIFFERENCE = {
  Eleven: 11,
  TwentySeven: 27,
  FortyOne: 41,
  FiftyFour: 54,
  SeventyTwo: 72,
  NinetyEight: 98,
  ThreeHundredSixty: 360,
  ThreeHundredNinety: 390,
  FourHundredTwenty: 420,
  FourHundredThirty: 430,
  FourHundredNinetyTwo: 492,
  SevenHundredTwenty: 720,
  SixHundredFifty: 650,
  EightHundredEighty: 880,
  OneThousandTwoHundredSixty: 1260,
  OneThousandTwoHundredSixtyFive: 1265,
};
