import { validEmailRegex } from '@app/config/regexes';

/**
 * Masks email characters and let visible the last part.
 * If the first part of the email has 6 characters or more
 * the mask will show the first 2 characters mask 4 and show the last two
 *
 * If the first part of the email has less than 6 characters
 * the mask will show the first character mask 4 and show the one
 * @param email string email to be masked.
 * @returns A masked mail string.
 *
 * @example
 * someexample@test.com -> C so****le@test.com
 * some@test.com -> C s****e@test.com
 */

export function maskEmail(email: string): string {
  const emailSplitter = /(.{1,})@{1}(.{1,})/;

  if (!validEmailRegex.test(email)) {
    return '';
  }

  const result = email.match(emailSplitter);
  const charactersToGet = result[1].length >= 6 ? 2 : 1;
  const mask =
    result[1].substring(0, charactersToGet) + '****' + result[1].substring(result[1].length - charactersToGet);

  return `${mask}@${result[2]}`;
}
