import { Action, createReducer, on } from '@ngrx/store';

import { OlbAccount } from '@app/accounts/models';
import { signOut } from '@app/store/actions';

import { loadClosedAccounts } from './closed-accounts.actions';

export const closedAccountsInitialState: Record<number, OlbAccount> = {};

const _reducer = createReducer(
  closedAccountsInitialState,
  on(loadClosedAccounts, (state, action) => {
    const transferOnlyAccounts = action.payload.reduce((acc, item) => {
      return { ...acc, [item.id]: { ...state[item.id], ...item } };
    }, {}) as Record<number, OlbAccount>;

    return { ...state, ...transferOnlyAccounts };
  }),
  on(signOut, () => {
    return {};
  })
);

export function closedAccountsReducer(state: Record<number, OlbAccount>, action: Action) {
  return _reducer(state, action);
}
