<div class="activate-modal">
  <div class="d-none d-sm-block dialog-header" [style.backgroundImage]="backgroundUrl">
    <app-svg-icon
      class="dialog-header__icon"
      [iconName]="iconConfig.header.icon"
      [size]="iconConfig.header.size"
    ></app-svg-icon>
    <button type="button" class="dialog-header__close" (click)="dialogRef.close(false)">
      <app-svg-icon [iconName]="iconConfig.close.icon" [size]="iconConfig.close.size"></app-svg-icon>
    </button>
  </div>
  <form [formGroup]="activateForm" (ngSubmit)="activateExternalAccount()">
    <div class="dialog-content mt-md-3 px-1 px-md-5">
      <button type="button" class="dialog-content__close d-sm-none" (click)="dialogRef.close(false)">
        <app-svg-icon [iconName]="iconConfig.close.icon" [size]="iconConfig.close.size"></app-svg-icon>
      </button>
      <h3 class="text-center mt-4 mb-2">Verify your new external account</h3>
      <p class="dialog-content__description mb-4 px-1 px-md-4 text-center">
        Verify your {{ data.account?.bankName }} account ({{ data.account?.accountCategory }} *{{
          data.account?.accountMask
        }}) by entering the amounts below:
      </p>
      <div class="form-group">
        <label for="firstAmount" class="dialog-content__label mb-1">Amount of first deposit</label>
        <input
          formControlName="firstAmount"
          id="firstAmount"
          name="firstAmount"
          type="text"
          class="uk-input firstAmount"
          (focusin)="onFocus($event)"
          (blur)="onBlur($event)"
          autocomplete="off"
          mask=".00"
          prefix="$0"
        />
      </div>
      <div class="form-group">
        <label for="secondAmount" class="dialog-content__label mb-1">Amount of second deposit</label>
        <input
          formControlName="secondAmount"
          id="secondAmount"
          name="secondAmount"
          type="text"
          class="uk-input"
          (focusin)="onFocus($event)"
          (blur)="onBlur($event)"
          autocomplete="off"
          mask=".00"
          prefix="$0"
        />
      </div>
    </div>
    <div class="mat-dialog-actions modal-footer-action" *ngIf="!busy">
      <button type="submit" class="btn-ok button--lg small-screen-btn">Verify Account</button>
      <button type="button" class="btn-cancel button--lg" (click)="deleteAccount.emit()">
        Remove Account
      </button>
    </div>
    <app-dot-loader *ngIf="busy">Processing...</app-dot-loader>
  </form>
</div>
