import { Component, OnInit } from '@angular/core';

import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';

@Component({
  selector: 'app-chatbot-help-label',
  templateUrl: './chatbot-help-label.component.html',
  styleUrls: ['./chatbot-help-label.component.scss'],
})
export class ChatbotHelpLabelComponent implements OnInit {
  constructor(private olbEventService: OlbEventService) {}

  ngOnInit(): void {}

  openChat(): void {
    this.olbEventService.emit(OlbEvents.OpenEvoChat);
  }
}
