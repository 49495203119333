import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EditExternalTransferModalData,
  EditInternalTransferData,
  EditLoanPaymentModalData,
  ExternalAccountEditRequest,
  GenericOption,
  TransferSchedule,
} from '@app/transfers/models';
import {
  SchedulerTransferMappings,
  SchedulerTransferUtils,
} from '@app/transfers/utils/mappings';
import { IFilterService } from 'angular';
import {
  CancelScheduledTransferModalTypes,
  FrequencyEnum,
  SendUntilOption,
} from '@app/transfers/enums';
import {
  CancelTransactionRequest,
  TransactionsService,
} from '@app/Areas/AAS/features/transactions/core/services';
import { Store } from '@ngrx/store';
import { catchError, filter, tap } from 'rxjs/operators';
import { AxosAdvisoryAccount } from '@core/models';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { deleteScheduledTransfer } from '@app/axos-invest/store/actions';
import {
  LEGACY_AAS_TRANSACTION_SERVICE,
  LEGACY_CACHED_ACCOUNTS_SERVICE,
  LEGACY_DIALOG_SERVICE,
  LEGACY_ENV_SERVICE,
  LEGACY_FEATURE_FLAG_SERVICE,
  LEGACY_FILTER,
  LEGACY_LOAN_SERVICE,
  LEGACY_MODAL_SERVICE,
  LEGACY_ROOT_SCOPE,
  LEGACY_SERVICE_HELPER,
  LEGACY_TRANSACTION_SERVICE,
  LEGACY_WINDOW_SERVICE,
  STORE,
} from '@app/transfers/ajs-upgraded-providers';
import {
  CancelScheduledTransferConfirmationModalComponent,
  EditExternalTransferModalComponent,
  EditInternalTransferModalComponent,
  EditLoanPaymentModalComponent,
} from '../modals';
import { differenceInDays } from 'date-fns';
import { DialogService } from '@core/services';

// ! DO NOT DELETE THIS IS JUST FOR UNIT TESTING -> JQUERY RELATED
import * as moment from 'moment';
declare var $: any;

// * LEGACY SERVICE DEFINITIONS OR IMPLEMENTATIONS
import { ITransactionService } from '@legacy/services/typings/ITransactionService';
import { ILoanService } from '@legacy/services/typings/ILoanService';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import { ModalService } from '@legacy/services/modal.service';
import { STATE } from '@core/tokens';
import { IStateService } from 'angular-ui-router';
import { CachedAccountsService } from '@legacy/services/cached-accounts.service';

@Component({
  selector: 'app-scheduler-transfers',
  templateUrl: './scheduler-transfers.component.html',
  styleUrls: ['./scheduler-transfers.component.scss'],
})
export class SchedulerTransfersComponent implements OnInit {
  isLoading: boolean = false;
  headers: GenericOption[] = [
    { value: 1, subValue: 'accountNameFrom', label: 'From account' },
    { value: 2, subValue: 'accountNameTo', label: 'To account' },
    { value: 3, subValue: 'frequency', label: 'Frequency' },
    { value: 4, subValue: 'sendUntil', label: 'Send until' },
    { value: 6, subValue: 'nextTransferDate', label: 'Next Transfer' },
    { value: 7, subValue: 'recurringAmount', label: 'Amount' },
  ];
  schedulerTransfers: TransferSchedule[] = [];
  filteredTransfers: TransferSchedule[] = [];
  filterFrom: GenericOption[] = [{ value: 0, label: 'From: All Accounts' }];
  filterTo: GenericOption[] = [
    { value: 0, subValue: '0', label: 'To: All Accounts' },
  ];
  filterFrequency: GenericOption[] = [{ value: 0, label: 'All Frequencies' }];
  filterRecurringAmount: GenericOption[] = [
    { value: 0, label: 'All recurring amounts' },
  ];
  filterDays: GenericOption[] = [
    { value: 1, label: 'Next Day' },
    { value: 7, label: 'Next Week' },
    { value: 14, label: 'Next 2 Weeks' },
    { value: 30, label: 'Next 30 Days' },
  ];
  isDefaultFilters = true;
  brand: string = String(this.env.brand);
  selectedFrom: GenericOption;
  selectedTo: GenericOption;
  selectedFrequency: GenericOption;
  selectedAmount: GenericOption;
  selectedDays: GenericOption;
  transferPeriodTotal = 0;
  // Option for transfers
  currentTransfer: TransferSchedule = {};
  // Option for transfer edit
  currentTransferEdit: TransferSchedule = {};

  orderByFilter: string;
  headerIdOrderBy: number;
  reverse: boolean;
  frequencies: GenericOption[];
  externalAccountEditRequest: ExternalAccountEditRequest;
  // flag to show the same modal for edit series and edit next external transfer
  isExternalSeries: boolean;
  // flag to show the same modal for edit series and edit next internal transfer
  isInternalSeries: boolean;
  transfers: TransferSchedule;
  mobileFilter = false;
  mobileEdit = false;
  mobileCancel = false;
  mobileRemove = false;
  showConfirmation: string;
  riaMoveMoneyPilotEnabled: boolean;
  confirmationEmail: string = '';
  confirmationTextMessage: string = '';
  getProductTypeDesc = SchedulerTransferMappings.getProductTypeDescription;
  private _isLoanFeatureEnabled: boolean;
  readonly mapFrequency = SchedulerTransferMappings.mapFrequency;
  readonly mapSendUntil = SchedulerTransferMappings.mapSendUntil;
  constructor(
    public matDialog: MatDialog,
    @Inject(LEGACY_FILTER) public _filter: IFilterService,
    @Inject(LEGACY_TRANSACTION_SERVICE)
    public _transactionService: ITransactionService,
    @Inject(LEGACY_LOAN_SERVICE) public _loanService: ILoanService,
    @Inject(LEGACY_FEATURE_FLAG_SERVICE)
    public _featureFlagService: FeatureFlagService,
    @Inject(LEGACY_SERVICE_HELPER) public _serviceHelper: IServiceHelper,
    @Inject(LEGACY_MODAL_SERVICE) public _modalService: ModalService,
    @Inject(LEGACY_AAS_TRANSACTION_SERVICE)
    public aasTransactionService: TransactionsService,
    @Inject(LEGACY_WINDOW_SERVICE) public window: ng.IWindowService,
    @Inject(LEGACY_DIALOG_SERVICE) public dialogService: DialogService,
    @Inject(LEGACY_ENV_SERVICE) public env: any,
    @Inject(LEGACY_ROOT_SCOPE) public _rootScope: ng.IRootScopeService,
    @Inject(STORE) public store: Store,
    @Inject(STATE) public state: IStateService,
    @Inject(LEGACY_CACHED_ACCOUNTS_SERVICE)
    public cachedAccountsService: CachedAccountsService
  ) {}

  ngOnInit(): void {
    this.setFilterOptions();
    this.preselectFilterOptions();
    this.loadScheduledTransfers(this.selectedDays.value);
    // jquery this should be deprecated in the future
    $('.m-transfer').addClass('active');
    this.checkFeatureFlags();
  }
  goToRoute(route: string): void {
    this.state.go(route);
  }
  messageOnNoDate(): string {
    this.isDefaultFilters = this.isDefaultFiltersSet();

    if (this.isDefaultFilters) {
      return 'You do not have any scheduled transfers at this time';
    }
    if (this.filteredTransfers == null || this.filteredTransfers.length == 0) {
      return 'No results match your selected search criteria';
    }

    return '';
  }

  cancelMobileFilter(): void {
    this.mobileFilter = false;
    this.preselectFilterOptions();
  }

  openEdit(transfer: TransferSchedule): void {
    this.transfers = transfer;
    if (this.transfers.isRia == false) {
      this.mobileEdit = true;
      this.window.scrollTo(0, 0);
    }
  }

  mapRemaining(transfer: TransferSchedule): string {
    switch (transfer.sendUntilOptions) {
      case SendUntilOption.IChooseToStop:
      case SendUntilOption.NumberOfTransfers:
        return transfer.pendingNumberOfTransfers.toString();
      default:
        return '-';
    }
  }
  isDefaultFiltersSet(): boolean {
    return (
      this.selectedFrom.value == this.filterFrom[0].value &&
      this.selectedTo.value == this.filterTo[0].value &&
      this.selectedFrequency.value == this.filterFrequency[0].value &&
      this.selectedDays.value == this.filterDays[3].value &&
      (this.filteredTransfers == null || this.filteredTransfers.length == 0)
    );
  }
  mapSendUntilOptions(
    option: number,
    frequency: number,
    pendingNumberOfTransfers?: any
  ): string {
    if (option == SendUntilOption.NumberOfTransfers) {
      if (frequency == FrequencyEnum.ONETIME) {
        return '';
      }

      return pendingNumberOfTransfers + ' Remaining';
    }

    return SchedulerTransferMappings.mapSendUntil(option);
  }
  mapExternalFrequency(option: number): string {
    return SchedulerTransferMappings.mapFrequency(option + 100);
  }

  mapRecurringAmount(trans: TransferSchedule): string {
    return this._filter('currency')(trans.totalAmount);
  }
  filterSchedulerTransferRow(
    rows: TransferSchedule[],
    selectedFrom: number,
    selectedTo: number,
    selectedFrequency: number,
    selectedAmount: number
  ): TransferSchedule[] {
    return rows.filter(row => {
      return this.filterFromAccountToAccount(
        row,
        selectedFrom,
        selectedTo,
        selectedAmount,
        selectedFrequency
      );
    });
  }

  private validateNumber(compareValue: number, rowValue: number): boolean {
    if (
      compareValue === null ||
      compareValue === undefined ||
      isNaN(compareValue)
    ) {
      return false;
    }
    return compareValue === rowValue || compareValue === 0;
  }

  filterFromAccountToAccount(
    row: TransferSchedule,
    selectedFrom: number,
    /**
     * @todo use this parameter for validation purpose instead of usage of selectedToValidation method
     */
    _selectedTo: number,
    amount: number,
    frequency: number
  ): boolean {
    const rowMatches =
      this.validateNumber(selectedFrom, +row.accountNumberFrom) &&
      this.selectedToValidation(row) &&
      this.validateNumber(amount, row.amount) &&
      this.validateNumber(frequency, row.frequency);
    return rowMatches;
  }
  /**
   * @deprecated
   * this method should not use `this` keyword it may be better to use parameter accomplish validation
   * will be kept around due to time
   */
  selectedToValidation(row: TransferSchedule): boolean {
    let result = false;
    const accountNumberAsNumber = this.selectedTo.value;
    const accountNumberAsString = this.selectedTo.subValue;
    if (!isNaN(+row.accountNumberTo)) {
      result =
        +row.accountNumberTo == accountNumberAsNumber ||
        accountNumberAsNumber == 0;
    } else {
      result =
        row.accountNumberTo == accountNumberAsString ||
        +accountNumberAsString == 0;
    }

    return result;
  }

  filterDaysChange(): void {
    this.loadScheduledTransfers(this.selectedDays.value);
  }
  cancelCurrentTransfer(isMobile: boolean): void {
    if (this.currentTransfer && this.currentTransfer !== null) {
      this.isLoading = true;
      switch (this.currentTransfer.scheduledTransferType) {
        case 0: // Internal
          this.deleteNextInternalTransfer(isMobile);
          break;
        case 1: // ACH
          this.deleteNextACHTransfer(isMobile);
          break;
        default:
          if (
            this.isLoanPayment(this.currentTransfer) &&
            this._isLoanFeatureEnabled
          ) {
            this.convertToAxosScheduler(isMobile);
          } else this._deleteNextPayverisTransfer(isMobile);
          break;
      }
    }
  }

  removeTransferSeries(isMobile: boolean): void {
    if (this.currentTransfer && this.currentTransfer !== null) {
      this.isLoading = true;
      switch (this.currentTransfer.scheduledTransferType) {
        case 0: // Internal
          this.deleteInternalTransferSeries(isMobile);
          break;
        case 1: // ACH
          this.deleteACHTransferSeries(isMobile);
          break;
        default:
          // Payveris
          this.deletePayverisTransferSeries(isMobile);
          break;
      }
    }
  }
  editmobileSeries(trans: TransferSchedule): void {
    this.currentTransferEdit = { ...trans };

    if (this.isLoanPayment(trans)) {
      this.showLoanPaymentModal(true);
    } else if (trans.isExternal) {
      this.showExternalMobileModal(trans, true);
    } else {
      this.showInternalMobileModal(true);
    }
  }
  cancel(transference: TransferSchedule): void {
    this.currentTransfer = { ...transference };
    this.currentTransfer.dateAddedString = moment().format(
      'DD/MM/YYYY hh:mm:ss'
    );
    const body =
      transference.frequency == 1
        ? `<h3>Cancel Upcoming Transfer</h3><p class="ms-secondary-text">Are you sure you want to cancel your upcoming transfer?</p>`
        : `<h3>Cancel Next Transfer</h3><p class="ms-secondary-text">Are you sure you want to cancel your next transfer? This will not affect other transfers in this series.</p>`;
    this._modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          cancelText: 'No',
          bodyText: body,
        }
      )
      .then(() => {
        this.cancelCurrentTransfer(false);
      });
  }
  cancelMobile(transference: TransferSchedule): void {
    this.currentTransfer = { ...transference };
    this.currentTransfer.dateAddedString = this.getCurrentDateTime();
    this.mobileCancel = true;
  }
  async cancelRIATransfer(transfer: TransferSchedule) {
    const today = new Date();
    const nextTransferDate = new Date(transfer.nextTransferDate);
    const daysUntil = differenceInDays(nextTransferDate, today);
    if (daysUntil > 3) {
      await this._modalService
        .show(
          {},
          {
            icon: 'bofi-warning',
            okText: 'Yes',
            cancelText: 'No',
            bodyText: `<h3>Cancel Transfer Series</h3><p class="ms-secondary-text">Are you sure you want to cancel your transfer series?</p>`,
          }
        )
        .then(() => this._cancelRiaTransfer(transfer));
    } else {
      this.matDialog.open(CancelScheduledTransferConfirmationModalComponent, {
        panelClass: 'cancel-scheduled-transfer-confirmation-modal-component',
        data: {
          modalType: CancelScheduledTransferModalTypes.SeriesCancelConfirm,
          modalCallback: () => this._cancelRiaTransfer(transfer, true),
        },
      });
    }
  }
  cancelNextRiaTransfer() {
    this.matDialog.open(CancelScheduledTransferConfirmationModalComponent, {
      panelClass: 'cancel-scheduled-transfer-confirmation-modal-component',
      data: {
        modalType: CancelScheduledTransferModalTypes.TransferCancelError,
        modalCallback: undefined,
      },
    });
  }
  messageOnNoData(transfers: TransferSchedule[]): string {
    this.isDefaultFilters = this.isDefaultFiltersSet();

    if (this.isDefaultFilters) {
      return 'You do not have any scheduled transfers at this time';
    }
    if (transfers == null || transfers.length == 0) {
      return 'No results match your selected search criteria';
    }

    return '';
  }
  async remove(transference: TransferSchedule): Promise<void> {
    this.currentTransfer = { ...transference };
    this.currentTransfer.dateAddedString = moment().format(
      'DD/MM/YYYY hh:mm:ss'
    );
    await this._modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          cancelText: 'No',
          bodyText: `<h3>Cancel Transfer Series</h3><p class="ms-secondary-text">Are you sure you want to cancel your transfer series?</p>`,
        }
      )
      .then(() => {
        this.removeTransferSeries(false);
      });
  }
  removeMobile(transference: TransferSchedule): void {
    this.currentTransfer = { ...transference };
    this.currentTransfer.dateAddedString = this.getCurrentDateTime();
    this.mobileRemove = true;
  }
  edit(trans: TransferSchedule): void {
    this.currentTransferEdit = { ...trans };

    if (this.isLoanPayment(trans)) {
      this.showLoanPaymentModal(false);
    } else if (trans.isExternal) {
      this.showExternalModal(trans, false);
    } else {
      this.showInternalModal(false);
    }
  }

  /**
   * This method is used to sort the 'schedulerTransfers' array based on a given field.
   *
   * @param {string} orderBy - The field to sort by.
   * @param {number} headerId - The ID of the header that triggered the sort.
   *
   * The method first sets the 'orderByFilter' and 'headerIdOrderBy' properties based on the provided arguments.
   * It then toggles the 'reverse' property to determine the sort direction.
   *
   * If the 'orderByFilter' is 'recurringAmount', it calls the 'sortByAmount' method and then returns.
   *
   * If the 'orderByFilter' is not 'recurringAmount', it gets the correct field for sorting by calling 'getFieldForSorting'
   * with 'orderBy' as an argument.
   *
   * Finally, it sorts the 'schedulerTransfers' array based on the 'orderBy' field. The sorting is done by calling
   * 'sortValues' with the values of 'orderBy' field of the two objects being compared.
   */
  sort(orderBy: string, headerId: number): void {
    this.orderByFilter = orderBy;
    this.headerIdOrderBy = headerId;
    this.reverse = !this.reverse;

    if (this.orderByFilter === 'recurringAmount') {
      this.sortByAmount();
      return;
    }

    orderBy = this.getFieldForSorting(orderBy);

    this.schedulerTransfers = [...this.schedulerTransfers].sort((a, b) =>
      this.sortValues(a[orderBy], b[orderBy])
    );
  }

  /**
   * This method is used to compare two values for sorting.
   *
   * @param {any} valueA - The first value to compare.
   * @param {any} valueB - The second value to compare.
   * @returns {number} - A negative, zero, or positive value, depending on the comparison.
   *
   * The method first checks the types of the values. It checks if they are strings or Date objects.
   *
   * If either value is a string, it sorts them as strings. It uses the 'localeCompare' method, which compares strings
   * based on their sequence of UTF-16 code unit values. The sort direction is determined by the 'reverse' property.
   *
   * If either value is a Date object, it sorts them as dates. It converts the dates to timestamps and subtracts them.
   * The sort direction is determined by the 'reverse' property.
   *
   * If the values are neither strings nor Date objects, it tries to convert them to numbers and sort them numerically.
   * It only does this if both values can be successfully converted to a number.
   *
   * If none of the above conditions are met, it returns 0, which means the values are considered equal in terms of sorting.
   */
  sortValues(valueA: any, valueB: any): number {
    const isAString = typeof valueA === 'string';
    const isBString = typeof valueB === 'string';
    const isADate = valueA instanceof Date;
    const isBDate = valueB instanceof Date;

    if (isAString || isBString) {
      // Sort as strings
      return this.reverse
        ? `${valueB}`.localeCompare(`${valueA}`)
        : `${valueA}`.localeCompare(`${valueB}`);
    } else if (isADate || isBDate) {
      // Sort as dates
      return this.reverse
        ? new Date(valueB).getTime() - new Date(valueA).getTime()
        : new Date(valueA).getTime() - new Date(valueB).getTime();
    } else {
      const valueANumber = Number(valueA);
      const valueBNumber = Number(valueB);
      if (!isNaN(valueANumber) && !isNaN(valueBNumber)) {
        // Both values can be converted to a number, so sort them numerically
        return this.reverse
          ? valueBNumber - valueANumber
          : valueANumber - valueBNumber;
      }
    }
    return 0; // return 0 if none of the above conditions are met
  }

  /**
   * This method is used to get the correct field for sorting.
   *
   * @param {string} orderBy - The field to sort by.
   * @returns {string} - The actual field to be used for sorting.
   *
   * If the 'orderBy' argument is 'sendUntil', it returns 'sendUntilOptionsConcat' as the field for sorting.
   * This is because the actual sorting should be done based on the 'sendUntilOptionsConcat' field, not the 'sendUntil' field.
   *
   * If the 'orderBy' argument is not 'sendUntil', it returns the 'orderBy' argument as is. This means the sorting should be
   * done based on the 'orderBy' field.
   */
  private getFieldForSorting(orderBy: string): string {
    return orderBy === 'sendUntil' ? 'sendUntilOptionsConcat' : orderBy;
  }

  /**
   * This method is used to sort the 'schedulerTransfers' array based on the 'amount' field.
   *
   * It creates a new array from 'schedulerTransfers' and sorts it. The sorting is done by subtracting the 'amount'
   * field of the two objects being compared. The sort direction is determined by the 'reverse' property.
   *
   * If 'reverse' is true, it sorts in ascending order (from smallest to largest).
   * If 'reverse' is false, it sorts in descending order (from largest to smallest).
   *
   * The sorted array replaces the original 'schedulerTransfers' array.
   */
  sortByAmount(): void {
    this.schedulerTransfers = [...this.schedulerTransfers].sort((a, b) => {
      return this.reverse ? a.amount - b.amount : b.amount - a.amount;
    });
  }

  // Function to determine if the scheduled transfers table should be displayed
  shouldShowScheduledTransfersTable(): boolean {
    return (
      Array.isArray(this.schedulerTransfers) &&
      this.schedulerTransfers.length > 0
    );
  }

  async editSingleTransfer(): Promise<void> {
    await this._transactionService
      .editSingleTransfer(null)
      .then(_res => {
        this.loadScheduledTransfers(this.selectedDays.value);
      })
      .catch(err => {
        this._serviceHelper.errorHandler(err);
      });
  }
  async editSeriesTransfer(): Promise<void> {
    await this._transactionService
      .editSeriesTransfer(null)
      .then(_res => {
        this.loadScheduledTransfers(this.selectedDays.value);
      })
      .catch(err => {
        this._serviceHelper.errorHandler(err);
      });
  }
  editSeries(transfer: TransferSchedule): void {
    this.currentTransferEdit = { ...transfer };

    if (this.isLoanPayment(transfer)) {
      this.showLoanPaymentModal(true);
    } else if (transfer.isExternal) {
      this.showExternalModal(transfer, true);
    } else {
      this.showInternalModal(true);
    }
  }
  editMobile(trans: TransferSchedule): void {
    this.currentTransferEdit = { ...trans };

    if (this.isLoanPayment(trans)) {
      this.showLoanPaymentModal(false);
    } else if (trans.isExternal) {
      this.showExternalMobileModal(trans, false);
    } else {
      this.showInternalMobileModal(false);
    }
  }

  showInternalMobileModal(isSeries: boolean) {
    this.isInternalSeries = isSeries;

    this.frequencies = this.getFrequencies([
      FrequencyEnum.ONETIME,
      FrequencyEnum.WEEKLY,
      FrequencyEnum.EVERYTWOWEEKS,
      FrequencyEnum.MONTHLY,
      FrequencyEnum.QUARTERLY,
      FrequencyEnum.SEMIANNUALLY,
      FrequencyEnum.ANNUALLY,
    ]);
    this.matDialog
      .open<
        EditInternalTransferModalComponent,
        EditInternalTransferData,
        boolean
      >(EditInternalTransferModalComponent, {
        panelClass: 'legacy-edit-internal-transfer-modal',
        data: {
          frequencies: this.resolveFrequencies(this.currentTransferEdit),
          transferToEdit: this.currentTransfer,
          isSeries: this.isInternalSeries,
          isMobile: true,
          dateRangePickerOptions: {},
        },
      })
      .afterClosed()
      .subscribe({
        next: result => {
          if (result) {
            this.loadScheduledTransfers(this.selectedDays.value);
            if (this.isInternalSeries) {
              this.showConfirmation =
                'Your changes to the transfer series have been saved!';
            } else {
              this.showConfirmation =
                'Your changes to the next transfer have been saved!';
            }
          }
        },
      });
  }
  showExternalMobileModal(trans: TransferSchedule, isSeries: boolean): void {
    this.isExternalSeries = isSeries;
    this.externalAccountEditRequest = {
      amount: trans.amount,
      externalAccountId: trans.externalAccountId,
      frequency: trans.frequency,
      internalAccountCode: trans.internalAccountCode,
      scheduledTransferId: trans.scheduledTransferId,
      accountCode: trans.internalAccountCode,
      confirmationNumber: 0,
      confirmationNumberspecified: 0,
      internalAccountId: 0,
      numberOfTransfers: trans.numberOfTransfers,
      remainingTransfers: trans.pendingNumberOfTransfers,
      transferType: trans.externalTransferType,
      fromNickName: trans.accountNicknameFrom,
      toNickName: trans.accountNicknameTo,
      processingDate: trans.nextTransferDate,
      accountNumberFrom: trans.accountNumberFrom.toString(),
      accountNumberTo: trans.accountNumberTo.toString(),
    };

    this.frequencies = this.getFrequencies([
      FrequencyEnum.ONETIME,
      FrequencyEnum.WEEKLY,
      FrequencyEnum.EVERYTWOWEEKS,
      FrequencyEnum.MONTHLY,
      FrequencyEnum.QUARTERLY,
      FrequencyEnum.SEMIANNUALLY,
      FrequencyEnum.ANNUALLY,
    ]);

    this.matDialog
      .open<EditExternalTransferModalComponent, EditExternalTransferModalData>(
        EditExternalTransferModalComponent,
        {
          panelClass: [
            'modal-service-md',
            'legacy-edit-external-transfer-modal-component',
          ],
          data: {
            datePickerOptions: {},
            isSeries: this.isExternalSeries,
            transferToEdit: this.externalAccountEditRequest,
            frequencies: this.resolveFrequencies(trans),
            isMobile: true,
          },
        }
      )
      .afterClosed()
      .subscribe({
        next: result => {
          if (result) {
            this.loadScheduledTransfers(this.selectedDays.value);
            if (this.isExternalSeries) {
              this.showConfirmation =
                'Your changes to the transfer series have been saved!';
              this.mobileEdit = false;
            } else {
              this.showConfirmation =
                'Your changes to the next transfer have been saved!';
              this.mobileEdit = false;
            }
          }
        },
      });
  }
  /**
   * @param isSeries {@link boolean}
   */
  showLoanPaymentModal(isSeries: boolean): void {
    this.frequencies = this.getFrequencies([
      FrequencyEnum.ONETIME,
      FrequencyEnum.WEEKLY,
      FrequencyEnum.EVERYTWOWEEKS,
      FrequencyEnum.MONTHLY,
      FrequencyEnum.QUARTERLY,
      FrequencyEnum.SEMIANNUALLY,
      FrequencyEnum.ANNUALLY,
    ]);
    this.matDialog
      .open<EditLoanPaymentModalComponent, EditLoanPaymentModalData, boolean>(
        EditLoanPaymentModalComponent,
        {
          panelClass: ['modal-service-md', 'legacy-edit-loan-payment-modal'],
          data: {
            frequencies: this.resolveFrequencies(this.currentTransferEdit),
            transferToEdit: this.currentTransferEdit,
            isSeries,
            dateRangePickerOptions: {},
          },
        }
      )
      .afterClosed()
      .subscribe({
        next: result => {
          if (result) {
            this.loadScheduledTransfers(this.selectedDays.value);
            if (isSeries) {
              this.showConfirmExternalTransferEdit(true);
            } else {
              this.showConfirmExternalTransferEdit();
            }
          }
        },
      });
  }

  showInternalModal(isSeries: boolean): void {
    this.isInternalSeries = isSeries;
    this.frequencies = this.getFrequencies([
      FrequencyEnum.ONETIME,
      FrequencyEnum.WEEKLY,
      FrequencyEnum.EVERYTWOWEEKS,
      FrequencyEnum.MONTHLY,
      FrequencyEnum.QUARTERLY,
      FrequencyEnum.SEMIANNUALLY,
      FrequencyEnum.ANNUALLY,
    ]);

    this.matDialog
      .open<
        EditInternalTransferModalComponent,
        EditInternalTransferData,
        boolean
      >(EditInternalTransferModalComponent, {
        panelClass: [
          'modal-service-md',
          'modal-dialog',
          'legacy-edit-internal-transfer-modal',
        ],

        data: {
          frequencies: this.frequencies,
          transferToEdit: this.currentTransferEdit,
          isSeries: this.isInternalSeries,
          dateRangePickerOptions: {},
          isMobile: false,
        },
      })
      .afterClosed()
      .subscribe({
        next: result => {
          if (result) {
            this.loadScheduledTransfers(this.selectedDays.value);
            if (this.isInternalSeries) {
              this.showConfirmExternalTransferEdit(true);
            } else {
              this.showConfirmExternalTransferEdit();
            }
          }
        },
      });
  }

  /**
   * Show an external modal for editing transfer schedules.
   *
   * @param trans {@link TransferSchedule}- the transfer schedule to be edited
   * @param isSeries {@link boolean}- flag indicating if the transfer is part of a series
   * @return a Promise that resolves when the modal is closed
   */
  showExternalModal(trans: TransferSchedule, isSeries: boolean): void {
    this.isExternalSeries = isSeries;
    this.externalAccountEditRequest = {
      amount: trans.amount,
      externalAccountId: trans.externalAccountId,
      frequency: trans.frequency,
      internalAccountCode: trans.internalAccountCode,
      scheduledTransferId: trans.scheduledTransferId,
      accountCode: trans.internalAccountCode,
      confirmationNumber: 0,
      confirmationNumberspecified: 0,
      internalAccountId: 0,
      numberOfTransfers: trans.numberOfTransfers,
      remainingTransfers: trans.pendingNumberOfTransfers,
      transferType: trans.externalTransferType,
      fromNickName: trans.accountNicknameFrom,
      toNickName: trans.accountNicknameTo,
      processingDate: trans.nextTransferDate,
      nextTransferDate: trans.nextTransferDate,
      accountNumberFrom: trans.accountNumberFrom.toString(),
      accountNumberTo: trans.accountNumberTo.toString(),
    };

    this.frequencies = this.getFrequencies([
      FrequencyEnum.ONETIME,
      FrequencyEnum.WEEKLY,
      FrequencyEnum.EVERYTWOWEEKS,
      FrequencyEnum.MONTHLY,
      FrequencyEnum.QUARTERLY,
      FrequencyEnum.SEMIANNUALLY,
      FrequencyEnum.ANNUALLY,
    ]);
    this.matDialog
      .open<EditExternalTransferModalComponent, EditExternalTransferModalData>(
        EditExternalTransferModalComponent,
        {
          panelClass: [
            'modal-service-md',
            'legacy-edit-external-transfer-modal-component',
          ],
          data: {
            datePickerOptions: {},
            isSeries: this.isExternalSeries,
            transferToEdit: this.externalAccountEditRequest,
            frequencies: this.resolveFrequencies(trans),
            isMobile: false,
          },
        }
      )
      .afterClosed()
      .subscribe({
        next: result => {
          if (result) {
            this.loadScheduledTransfers(this.selectedDays.value);
            if (this.isExternalSeries) {
              this.showConfirmExternalTransferEdit(true);
            } else {
              this.showConfirmExternalTransferEdit();
            }
          }
        },
      });
  }

  private showConfirmExternalTransferEdit(multiple = false) {
    this._modalService.show(
      {},
      {
        icon: 'bofi-success',
        okText: 'Ok',
        hasCancelButton: false,
        bodyText: multiple
          ? `<h3>Your transfers have been edited.</h3> <br/>`
          : `<h3>Your transfer has been edited.</h3> <br/>`,
      }
    );
  }

  private checkFeatureFlags(): void {
    if (this._rootScope['loanFlagAvailable']) {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
    }
    this._rootScope.$on('loanFlagAvailable', () => {
      this._isLoanFeatureEnabled = this._featureFlagService.isAxosSchedulerActive();
    });

    this.riaMoveMoneyPilotEnabled = this._featureFlagService.isRiaPilotMoveMoneyActive();
  }

  private setFilterOptions(): void {
    const {
      headerOptions,
      fromOptions,
      toOptions,
      frequencyOptions,
      amountOptions,
      daysOptions,
    } = SchedulerTransferUtils.defaultOptions();
    this.headers = headerOptions;

    this.filterFrom = fromOptions;

    this.filterTo = toOptions;
    this.filterFrequency = frequencyOptions;
    this.filterRecurringAmount = amountOptions;
    this.filterDays = daysOptions;
  }

  private preselectFilterOptions(): void {
    this.selectedFrom = this.filterFrom[0];
    this.selectedTo = this.filterTo[0];
    this.selectedFrequency = this.filterFrequency[0];
    this.selectedAmount = this.filterRecurringAmount[0];
    this.selectedDays = this.filterDays[3];
  }
  private async loadScheduledTransfers(_days: number): Promise<void> {
    this.isLoading = true;
    try {
      const response: OlbResponse<
        TransferSchedule[]
      > = await this._transactionService.getScheduledTransfers(true, true);
      this.schedulerTransfers = response.data.filter(
        transfer => transfer.pendingNumberOfTransfers != 0
      );
      this.filteredTransfers = response.data;

      this.store
        .select(getAxosAdvisoryAccounts)
        .pipe(filter(accounts => accounts !== null))
        .subscribe({
          next: (riaAccounts: AxosAdvisoryAccount[]) => {
            if (riaAccounts.length >= 0) {
              const riaScheduledTransfers = response.data.filter(
                x => x.pendingNumberOfTransfers == 0
              );
              riaScheduledTransfers.forEach(riaScheduledTransfer => {
                const found = this.schedulerTransfers?.find(
                  toFind =>
                    toFind.amount == riaScheduledTransfer.amount &&
                    toFind.nextTransferDate ==
                      riaScheduledTransfer.nextTransferDate &&
                    toFind.sendUntilOptions ==
                      riaScheduledTransfer.sendUntilOptions &&
                    toFind.frequency == riaScheduledTransfer.frequency &&
                    toFind.accountNicknameFrom ==
                      riaScheduledTransfer.accountNicknameFrom &&
                    toFind.accountNicknameTo ==
                      riaScheduledTransfer.accountNicknameTo
                );
                if (found == null) {
                  const newSchedulerTransfers = this.schedulerTransfers.concat(
                    riaScheduledTransfer
                  );
                  this.schedulerTransfers = newSchedulerTransfers;
                }
              });
            }
            this.filteredTransfers = response.data;
            this.fillOptionsFromToAmount();
            this.reverse = true;
            this.sort('nextTransferDate', 6);
          },
        });
      this.fillOptionsFromToAmount();
      this.reverse = true;
      this.sort('nextTransferDate', 6);
    } catch (error) {
      this._serviceHelper.errorHandler(error);
    } finally {
      this.isLoading = false;
    }

    if (Array.isArray(this.cachedAccountsService.getAggregatedAccounts())) {
      this.getAggAccounts();
    } else {
      this._rootScope.$on('aggregatedaccountsloaded', () => {
        this.getAggAccounts();
      });
    }
    this.concatSendUntilOptionToSort();
  }

  getAggAccounts() {
    const aggregatedAccounts = this.cachedAccountsService.getAggregatedAccounts();
    const accMap = new Map();

    aggregatedAccounts?.forEach(acc => {
      if (!acc.accountNumber) return;
      if (acc.nickname && acc.accountMask) {
        accMap.set(
          acc.accountNumber,
          acc.nickname + acc.accountMask.replace('xxxx', ' **')
        );
        return;
      }
      if (acc.name && acc.accountMask)
        accMap.set(
          acc.accountNumber,
          acc.name + acc.accountMask.replace('xxxx', ' **')
        );
    });

    this.schedulerTransfers.forEach(schedule => {
      const accFrom = accMap.get(schedule.accountNumberFrom);
      if (accFrom) schedule.accountNicknameFrom = accFrom;

      const accTo = accMap.get(schedule.accountNumberTo);
      if (accTo) schedule.accountNicknameTo = accTo;
    });
  }

  /**
   * This method is used to concatenate the 'sendUntilOptions' property to the scheduler transfers.
   * It maps over the 'schedulerTransfers' array and for each object, it destructures 'sendUntilOptions',
   * 'frequency', 'pendingNumberOfTransfers', and the rest of the properties.
   * It then returns a new object with the original properties and a new 'sendUntilOptionsConcat' property.
   * The 'sendUntilOptionsConcat' property is the result of calling 'this.mapSendUntilOptions' with
   * 'sendUntilOptions', 'frequency', and 'pendingNumberOfTransfers' as arguments.
   */
  private concatSendUntilOptionToSort() {
    this.schedulerTransfers = this.schedulerTransfers.map(
      ({ sendUntilOptions, frequency, pendingNumberOfTransfers, ...rest }) => ({
        ...rest,
        sendUntilOptionsConcat: this.mapSendUntilOptions(
          sendUntilOptions,
          frequency,
          pendingNumberOfTransfers
        ),
        sendUntilOptions,
        frequency,
        pendingNumberOfTransfers,
      })
    );
  }

  private fillOptionsFromToAmount(): void {
    const {
      fromOptions,
      toOptions,
      amountOptions,
      frequencyOptions,
    } = SchedulerTransferUtils.defaultOptions();
    this.filterFrom = fromOptions;
    this.filterTo = toOptions;
    this.filterRecurringAmount = amountOptions;
    this.filterFrequency = frequencyOptions.sort((a, b) => {
      return a.value > b.value ? 1 : -1;
    });
    this.schedulerTransfers.forEach(transferSchedule => {
      const existFromOption = this.filterFrom.filter(opt => {
        return opt.value == +transferSchedule.accountNumberFrom;
      });
      const existToOption = this.filterTo.filter(opt => {
        return opt.value == +transferSchedule.accountNumberTo;
      });
      const existAmountOption = this.filterRecurringAmount.filter(opt => {
        return opt.value == +transferSchedule.amount;
      });
      const existFreqOption = this.filterFrequency.filter(opt => {
        return opt.value == transferSchedule.frequency;
      });

      if (existFromOption.length == 0) {
        this.filterFrom.push({
          value: +transferSchedule.accountNumberFrom,
          label: transferSchedule.accountNicknameFrom,
        });
      }

      if (existToOption.length == 0) {
        if (!isNaN(+transferSchedule.accountNumberTo)) {
          this.filterTo.push({
            value: +transferSchedule.accountNumberTo,
            label: transferSchedule.accountNicknameTo,
          });
        } else {
          //MP Accounts, account number is an alphanumeric value, e.g. "ST00000013"
          this.filterTo.push({
            subValue: transferSchedule.accountNumberTo,
            label: transferSchedule.accountNicknameTo,
          });
        }
      }
      if (existAmountOption.length == 0) {
        this.filterRecurringAmount.push({
          value: transferSchedule.amount,
          label: this._filter('currency')(transferSchedule.amount),
        });
      }
      if (existFreqOption.length == 0) {
        this.filterFrequency.push({
          value: transferSchedule.frequency,
          label: SchedulerTransferMappings.mapFrequency(
            transferSchedule.frequency
          ),
        });
      }
    });
    const defaultFilter = this.filterRecurringAmount[0];
    if (this.filterRecurringAmount.length > 2) {
      const sortedRecurringAmount = [...this.filterRecurringAmount].slice(1);
      this.filterRecurringAmount = [defaultFilter, ...sortedRecurringAmount];
    }
    this.filterFrequency.sort((a, b) => {
      return a.value > b.value ? 1 : -1;
    });

    const aggregatedAccounts = this.cachedAccountsService.getAggregatedAccounts();
    const accMap = new Map();

    aggregatedAccounts?.forEach(acc => {
      if (!acc.accountNumber) return;
      if (acc.nickname && acc.accountMask) {
        accMap.set(
          +acc.accountNumber,
          acc.nickname + acc.accountMask.replace('xxxx', ' **')
        );
        return;
      }
      if (acc.name && acc.accountMask)
        accMap.set(
          +acc.accountNumber,
          acc.name + acc.accountMask.replace('xxxx', ' **')
        );
    });

    this.filterFrom.forEach(acc => {
      const accFrom = accMap.get(acc.value);
      if (accFrom) acc.label = accFrom;
    });

    this.filterTo.forEach(acc => {
      const accTo = accMap.get(acc.value);
      if (accTo) acc.label = accTo;
    });
  }

  private resolveFrequencies(transfer: TransferSchedule): GenericOption[] {
    let type = transfer?.accountProductTypeTo;
    if (!isNaN(+transfer.accountProductTypeTo))
      type = this.getProductTypeDesc(+transfer.accountProductTypeTo);
    switch (type.toLowerCase()) {
      case 'mortgage':
      case 'auto loan':
      case 'personal loan':
      case 'heloc':
      case 'payment protection program':
        // if is ACH remove 'Monthly' option
        if (transfer.isAch)
          this.frequencies = this.getFrequencies([FrequencyEnum.ONETIME]);
        else
          this.frequencies = this.getFrequencies([
            FrequencyEnum.ONETIME,
            FrequencyEnum.MONTHLY,
          ]);
        break;
      case 'overdraft line of credit':
        this.frequencies = this.getFrequencies([
          FrequencyEnum.ONETIME,
          FrequencyEnum.MONTHLY,
        ]);
        break;
      default:
        if (this.frequencies.length === 0) {
          this.frequencies = this.getFrequencies([
            FrequencyEnum.ONETIME,
            FrequencyEnum.WEEKLY,
            FrequencyEnum.EVERYTWOWEEKS,
            FrequencyEnum.MONTHLY,
            FrequencyEnum.QUARTERLY,
            FrequencyEnum.SEMIANNUALLY,
            FrequencyEnum.ANNUALLY,
          ]);
        }
    }

    return this.frequencies;
  }
  private getFrequencies(frequenciesInput?: number[]): GenericOption[] {
    const allFrequencies: GenericOption[] = SchedulerTransferUtils.createAllFrequenciesGenericOptions();

    if (frequenciesInput === null) return allFrequencies;

    const frequencies: GenericOption[] = [];

    frequenciesInput.forEach(value => {
      if (value >= 100) {
        frequencies.splice(value - 92, 0, allFrequencies[value - 92]); // start index of external FrequencyEnum - num of internal FrequencyEnum
      } else {
        frequencies.splice(value - 1, 0, allFrequencies[value - 1]);
      }
    });

    return frequencies;
  }
  private getCurrentDateTime(): string {
    return SchedulerTransferUtils.getCurrentDateTime();
  }
  private _cancelRiaTransfer(
    transfer: TransferSchedule,
    seriesHasPending: boolean = false
  ) {
    this.currentTransfer = { ...transfer };
    if (
      !this.riaMoveMoneyPilotEnabled ||
      this.currentTransfer.riaTransferType !== 1
    ) {
      return;
    }

    let accountNumber = this.currentTransfer.accountNumberTo;

    const cancelRequest: CancelTransactionRequest = {
      Id: `${this.currentTransfer.id}`,
      AccountNumber: accountNumber,
    };

    this.isLoading = true;
    this.aasTransactionService
      .cancelTransactions(cancelRequest)
      .pipe(
        tap(() =>
          this.handleCancelRiaTransferSuccess(
            this.currentTransfer.frequency,
            seriesHasPending
          )
        ),
        catchError(err => {
          this._serviceHelper.errorHandler(err, true);
          return err;
        })
      )
      .subscribe();
  }
  private handleCancelRiaTransferSuccess(
    frequency: number,
    seriesHasPending: boolean
  ) {
    this.loadScheduledTransfers(this.selectedDays.value);
    if (seriesHasPending) {
      this.matDialog.open(CancelScheduledTransferConfirmationModalComponent, {
        panelClass: 'cancel-scheduled-transfer-confirmation-modal-component',
        data: {
          modalType: CancelScheduledTransferModalTypes.SeriesCancelSuccess,
          modalCallback: undefined,
        },
      });
    } else {
      if (frequency == 1) {
        this.showCancelTransferConfirmation();
      } else {
        this.showRemoveTransferSeriesConfirmation();
      }
    }
  }
  private isLoanPayment(transfer: TransferSchedule): boolean {
    return +transfer.accountTypeTo == 4 || transfer.accountTypeTo == 'Loan';
  }
  private async deleteNextACHTransfer(isMobile: boolean): Promise<void> {
    await this._loanService
      .removeNextACHTransfer(this.currentTransfer)
      .then(() => {
        this.handleCancelSingleScheduledTransferResponse(isMobile);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
  }
  private async deleteACHTransferSeries(isMobile: boolean): Promise<void> {
    await this._loanService
      .removeACHTransferSeries(this.currentTransfer)
      .then(() => {
        this.handleRemoveScheduledSeries(isMobile);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
  }
  private async convertToAxosScheduler(isMobile: boolean): Promise<void> {
    await this._loanService
      .convertExternalSeries(this.currentTransfer, true)
      .then(() => {
        this.handleCancelSingleScheduledTransferResponse(isMobile);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
  }
  private async deleteNextInternalTransfer(isMobile: boolean): Promise<void> {
    await this._transactionService
      .cancelSingleScheduledTransfer(this.currentTransfer, true)
      .then(() => {
        this.handleCancelSingleScheduledTransferResponse(isMobile);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
  }
  private async _deleteNextPayverisTransfer(isMobile: boolean): Promise<void> {
    await this._transactionService
      .cancelSingleScheduledTransferExternal(this.currentTransfer)
      .then(() => {
        this.handleCancelSingleScheduledTransferResponse(isMobile);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
  }
  private async deletePayverisTransferSeries(isMobile: boolean): Promise<void> {
    await this._transactionService
      .removeScheduledExternalSeries(this.currentTransfer)
      .then(() => {
        this.handleRemoveScheduledSeries(isMobile);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
  }
  private async deleteInternalTransferSeries(isMobile: boolean): Promise<void> {
    await this._transactionService
      .removeScheduledSeriesTransfer(this.currentTransfer)
      .then(() => {
        this.handleRemoveScheduledSeries(isMobile);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper));
  }
  private handleRemoveScheduledSeries(isMobile: boolean) {
    this.loadScheduledTransfers(this.selectedDays.value);
    if (isMobile) {
      this.showConfirmation =
        'You have successfully cancelled your transfer series';
      this.mobileEdit = false;
      this.mobileRemove = false;

      return;
    }
    if (this.currentTransfer.isMP) {
      this.store.dispatch(
        deleteScheduledTransfer({
          transferSeriesId: this.currentTransfer.transferSeriesId,
        })
      );
    }
    this.showRemoveTransferSeriesConfirmation();
  }

  private handleCancelSingleScheduledTransferResponse(isMobile: boolean) {
    this.loadScheduledTransfers(this.selectedDays.value);
    if (isMobile) {
      this.showConfirmation =
        'You have successfully cancelled your next transfer.';
      this.mobileEdit = false;
      this.mobileCancel = false;

      return;
    }
    this.showCancelTransferConfirmation();
  }

  // confirmation modals region
  private showCancelTransferConfirmation() {
    this._modalService.show(
      {},
      {
        icon: 'bofi-success',
        okText: 'Ok',
        hasCancelButton: false,
        bodyText: `<h3>This transfer has been deleted</h3><br/>`,
      }
    );
  }

  private showRemoveTransferSeriesConfirmation() {
    const accountNameFrom = this.currentTransfer.isRia
      ? this.currentTransfer.accountNicknameFrom
      : this.currentTransfer.accountNameFrom;
    const accountNameTo = this.currentTransfer.isRia
      ? this.currentTransfer.accountNicknameTo
      : this.currentTransfer.accountNameTo;

    this._modalService.show(
      {},
      {
        icon: 'bofi-success',
        okText: 'Ok',
        hasCancelButton: false,
        bodyText: `<h1>Transfer Series Removed</h1> <br/>
        Your ${SchedulerTransferMappings.mapFrequency(
          this.currentTransfer.frequency
        )} transfers from ${accountNameFrom}
        to ${accountNameTo} will no longer take place.<br/><br/>`,
      }
    );
  }
}
