import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { usernameRegex } from '@app/config/regexes';
import { Username } from '@app/enrollment/models';
import { EnrollmentService } from '@core/services';
import { ServiceResult } from '@shared/models';

export interface Header {
  title: string;
  subtitle: string;
  name: string;
  hideLogin: boolean;
}

@Component({
  selector: 'app-create-username-proxy',
  templateUrl: './create-username-proxy.component.html',
  styleUrls: ['./create-username-proxy.component.scss'],
})
export class CreateUsernameProxyComponent implements OnInit, OnDestroy {
  @Input() header: Header;

  @Input() brandName: string;
  @Output() login = new EventEmitter<void>();
  @Output() next = new EventEmitter<Username>();
  set password(password: string) {
    this.userNameForm.patchValue({ password: password ?? '' });
  }

  get password(): string {
    return this.userNameForm.get('password').value;
  }

  passwordError = false;
  userNameForm: UntypedFormGroup;
  usernameMessage: string = null;
  usernameValidationStatus: 'valid' | 'taken' = null;
  usernameMessageClass: 'text__success' | 'text__error' = 'text__error';
  errors = {
    username: [
      { name: 'required', message: 'Username is required.' },
      { name: 'pattern', message: 'Invalid characters.' },
      { name: 'maxlength', message: 'Username needs to be max 30 characters long.' },
      { name: 'minlength', message: 'Username needs to be at least 6 characters long.' },
    ],
    password: [{ name: 'required', message: 'Password is required.' }],
  };
  isDisabled = true;
  private subSink = new SubSink();
  private subSinkPass = new SubSink();

  constructor(private fb: UntypedFormBuilder, private enrollmentService: EnrollmentService) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
    this.subSinkPass.unsubscribe();
  }

  submitData(): void {
    if (this.userNameForm.invalid || this.usernameValidationStatus === 'taken') {
      this.userNameForm.markAllAsTouched();
    } else {
      this.trimFormValues();

      const username = this.userNameForm.value as Username;

      this.next.emit(username);
    }
  }

  validateFields(): void {
    this.isDisabled = !(
      this.password !== '' &&
      !this.passwordError &&
      this.userNameForm.valid &&
      this.usernameValidationStatus === 'valid'
    );
  }

  private trimFormValues(): void {
    const trimmed = Object.keys(this.userNameForm.value).reduce((acc, curr) => {
      const val = `${this.userNameForm.value[curr]}`.trim();

      acc[curr] = val;

      return acc;
    }, {});

    this.userNameForm.patchValue(trimmed);
  }

  private initForm(): void {
    this.userNameForm = this.fb.group({
      username: this.fb.control('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
        Validators.pattern(usernameRegex),
      ]),
      password: this.fb.control('', [Validators.required]),
    });

    this.subSink.sink = this.userNameForm
      .get('username')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(value => {
          this.usernameMessage = null;
          this.usernameMessageClass = null;

          return value.trim();
        }),
        filter(trimmed => usernameRegex.test(trimmed)),
        switchMap(value => {
          this.usernameMessage = 'Checking username validity...';

          return this.enrollmentService.validateUsernameInfo(value).pipe(catchError(res => of(res.error)));
        })
      )
      .subscribe(this.validateUsername.bind(this));

    this.subSinkPass.sink = this.userNameForm.get('password').valueChanges.subscribe(this.validateFields.bind(this));
  }

  private validateUsername(res: ServiceResult<string>): void {
    if (!res) return;

    if (res.statusCode === 200) {
      this.usernameMessage = res.data;
      this.usernameValidationStatus = 'valid';
      this.usernameMessageClass = 'text__success';
    } else {
      this.usernameMessage = res.message;
      this.usernameValidationStatus = 'taken';
      this.usernameMessageClass = 'text__error';
    }

    this.validateFields();
    if (this.userNameForm.controls['username'].status !== 'VALID') {
      this.usernameMessage = null;
      this.usernameMessageClass = null;

      return;
    }
  }
}
