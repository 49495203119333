import { IraPlanCode } from '@app/accounts/enums';
import { ClearingAccountType } from '@app/shared/enums';
import { BeneficiaryTrading } from '@app/shared/models';

export class TradingAccount {
  [index: string]: any;
  accountNumber: string;
  accountName: string;
  accountType: string;
  type: ClearingAccountType;
  typeName: string;
  status: string;
  statusName: string;
  openedDate: Date;
  additionalOwnerNames?: string[];
  beneficiaries: BeneficiaryTrading[];
  totalValue: number;
  amountAvailableToWithdraw: number;
  cashInvest: number;
  marketValue: number;
  dayChange: number;
  dayChangePercent: number;
  costBasis: number;
  gainLoss: number;
  gainLossPercent: number;
  nickname: string;
  id: number;
  bankName: string;
  cashInvestPercent: number;
  withoutFunds: boolean;
  pendingSpousalConsent: boolean;
  spousalConsentEmail: string;
  remainingDaysForFund: number;
  displayName: string;
  /** Brokerage data*/
  investmentObjective?: string;
  riskTolerance?: string;
  timeHorizon?: string;
  fundingSource?: string;
  dividendDripOptions?: string;
  moneyFundInstructions?: string;
  investmentKnowledge?: string;
  marginsExperience?: string;
  stocksExperience?: string;
  annuitiesExperience?: string;
  bondsExperience?: string;
  mutualFundsExperience?: string;
  optionsTransactionsExperience?: string;
  optionsTranExperience?: string;

  // Trust Certification
  trustName?: string;
  trustFormationDate?: string;
  trustTaxPayerIdNumber?: string;
  trustEmail?: string;
  taxPlanType: IraPlanCode;
  isRia?: boolean;
  constructor(args?: Partial<TradingAccount>) {
    Object.assign(this, args);
  }
}
