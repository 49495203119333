import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ModalData, SecureFormsDocument } from '@app/secure-forms/models';
import { SecureFormsService } from '@core/services';
import { olbSettings } from '@core/tokens';

@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.scss'],
})
export class UploadDocumentModalComponent implements OnInit {
  backgroundUrl: string;
  descriptionModel = '';
  documentModel: SecureFormsDocument;
  progress: number;
  uploading: boolean;

  private cancelUpload$ = new Subject<null>();

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private dialogRef: MatDialogRef<UploadDocumentModalComponent>,
    private secureFormsService: SecureFormsService
  ) {}

  ngOnInit(): void {
    const { brand } = this.env;
    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
    this.cancelUpload$.pipe(take(1));
  }

  submitFormDocument() {
    this.secureFormsService
      .sendDocumentForms(this.getFormData())
      .pipe(takeUntil(this.cancelUpload$))
      .subscribe({
        next: (event: HttpEvent<object>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.uploading = true;
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round((event.loaded / event.total) * 100);
              break;
            case HttpEventType.Response:
              this.uploading = false;
              this.dialogRef.close(true);
          }
        },
      });
  }

  closeModal() {
    this.cancelUpload$.next();
    this.dialogRef.close(false);
  }

  private getFormData(): FormData {
    const formData = new FormData();
    formData.append('file', this.data.file.document);
    formData.append('description', this.descriptionModel);
    formData.append('documentGroupType', this.documentModel.formType);
    formData.append('documentType', this.documentModel.displayName);

    return formData;
  }
}
