import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { allowedAddressCharactersRegex, zipCodeRegex } from '@app/config/regexes';
import { Address } from '@shared/models';

import { AddressType } from '../enums';
import { notPoBoxValidator } from '../validators';

/**
 * Creates a FormGroup with all the necessary controls for an Address form.
 *
 * @param addressType Address type for the given form group.
 * @param address Address object for initial values.
 *
 * @returns A FormGroup to create an address of the given type.
 */
export function createAddressForm(address: Partial<Address>, addressType?: AddressType) {
  const streetAddressValidators = [Validators.required, Validators.pattern(allowedAddressCharactersRegex)];
  const form = new UntypedFormGroup({
    streetAddress1: new UntypedFormControl(address.streetAddress1),
    streetAddress2: new UntypedFormControl(address.streetAddress2, [Validators.pattern(allowedAddressCharactersRegex)]),
    postalCode: new UntypedFormControl(address.postalCode, [Validators.required, Validators.pattern(zipCodeRegex)]),
    city: new UntypedFormControl(address.city, [Validators.required, Validators.pattern(allowedAddressCharactersRegex)]),
    state: new UntypedFormControl(address.state, [Validators.required]),
    stateCode: new UntypedFormControl(address.stateCode ?? '', [Validators.required]),
    addressType: new UntypedFormControl(address.addressType ?? addressType),
    id: new UntypedFormControl(address.id),
  });

  if (addressType === AddressType.Physical) {
    streetAddressValidators.push(notPoBoxValidator);
  }

  if (addressType === AddressType.None) {
    form.removeControl('id');
    form.removeControl('addressType');
  }

  form.get('streetAddress1').setValidators(streetAddressValidators);
  form.get('streetAddress1').updateValueAndValidity();

  return form;
}
