import { DebitCardType } from '../enums/debitCardType.enum';
/**
 * Gets description from a DebitCardType enum
 *
 * @export {function} mapDebitCardType
 * @param {DebitCardType} debitType
 * @returns {string} Description of an Debit Card Type
 */
export function mapDebitCardType(debitType: DebitCardType): string {
  switch (debitType) {
    case DebitCardType.VISA:
      return 'visa';
    case DebitCardType.DISCOVER:
      return 'discover';
    case DebitCardType.ATM:
      return 'atm';
    default:
      return '';
  }
}
