import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';

import { ExchangeAgreement } from '@app/axos-trading/models/exchange-agreement.model';
import { ExchangeAgreementService, PortfolioService, TradingRoutingHelperService } from '@app/axos-trading/services';
import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { FilesIcons } from '@shared/enums';

@Component({
  selector: 'app-exchange-agreement',
  templateUrl: './exchange-agreement.component.html',
  styleUrls: ['./exchange-agreement.component.scss'],
})
export class ExchangeAgreementComponent implements OnInit, OnDestroy {
  @Input() accountNumber: string;
  @Input() accountId: number;
  @Input() stockId = '';
  @Output() exchangeAgreementState = new EventEmitter();

  exchangeAgreement: ExchangeAgreement[];
  svgIcon = FilesIcons.Scroll;

  isShowExchangeAgreement: boolean;
  isAxosElite = false;
  interval: Subscription;

  private subSink = new SubSink();

  constructor(
    @Inject(olbSettings) private settings: OlbSettings,
    private exchangeAgreementService: ExchangeAgreementService,
    private portfolioService: PortfolioService,
    private routingHelper: TradingRoutingHelperService
  ) {}

  ngOnInit(): void {
    this.getAxosEliteInfo();
    this.startTimer();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    this.stopTimer();
  }

  startTimer() {
    this.interval = interval(180000).subscribe(() => this.getExchangeAgreement());
    // 180,000 ms = 3 minutes
  }

  stopTimer() {
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

  goToExchangeAgreementsIframe(type: string, url: string) {
    this.stopTimer();

    this.stockId === ''
      ? this.routingHelper.goToExchangeAgreementsPortfolio(this.accountId, type, url)
      : this.routingHelper.goToExchangeAgreementsIndividual(this.accountId, this.stockId, type, url);
  }

  private getAxosEliteInfo() {
    this.subSink.sink = this.portfolioService
      .getPortfolioAccountInformation(this.accountNumber)
      .pipe(map(data => data.repCode))
      .subscribe(result => {
        this.isAxosElite = result === this.settings.axosEliteRepCode;

        if (this.isAxosElite) {
          this.getExchangeAgreement();
        } else {
          this.exchangeAgreementState.emit(false);
        }
      });
  }

  private getExchangeAgreement() {
    this.subSink.sink = this.exchangeAgreementService.getExchangeAgreement().subscribe(result => {
      this.exchangeAgreement = result.filter(e => !e.signed);
      this.isShowExchangeAgreement = this.exchangeAgreement.length > 0 && this.isAxosElite;

      if (!this.isShowExchangeAgreement) {
        this.stopTimer();
      }
      this.exchangeAgreementState.emit(this.isShowExchangeAgreement);
    });
  }
}
