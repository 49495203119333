import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { SubSink } from '@axos/subsink';

import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'acc-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss'],
})
export class BeneficiaryComponent implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() index: number;
  @Input() relationshipCatalog: Record<string, string>[];
  @Input() hasMultipleSpouses: boolean;
  @Input() labelPrefix = '';

  @Output() remove = new EventEmitter<number>();
  @Output() relationshipChange = new EventEmitter<{ index: number; previous: string }>();
  @Output() currentBeneficiariesChange = new EventEmitter<never>();

  errors = {
    name: [{ name: 'required', message: 'First and Last Name is required.' }],
    identity: [
      { name: 'required', message: 'Date of Birth is required.' },
      { name: 'invalidDate', message: 'Date of Birth is invalid.' },
      { name: 'pattern', message: 'Date of Birth is invalid.' },
    ],
    percent: [
      { name: 'required', message: 'Share is required' },
      { name: 'min', message: 'Share is invalid.' },
      { name: 'max', message: 'Share is invalid.' },
    ],
    relationship: [{ name: 'required', message: 'Relationship is required' }],
  };
  icons = {
    trash: UtilityIcons.Trash,
  };

  private previousRelationship: string;
  private subsink = new SubSink();

  constructor() {}

  ngOnInit(): void {
    const nameField = this.parentForm.get('name');
    const dateOfBirthField = this.parentForm.get('identity');
    const percentField = this.parentForm.get('percent');

    const relationshipField = this.parentForm.get('relationship');
    this.previousRelationship = relationshipField.value;

    this.subsink.sink = relationshipField.valueChanges.subscribe(value => {
      if (this.previousRelationship !== value) {
        this.relationshipChange.emit({ index: this.index, previous: this.previousRelationship });
        this.previousRelationship = value;
      }
    });

    this.subsink.sink = nameField.valueChanges.subscribe(() => {
      this.currentBeneficiariesChange.emit();
    });
    this.subsink.sink = dateOfBirthField.valueChanges.subscribe(() => {
      this.currentBeneficiariesChange.emit();
    });
    this.subsink.sink = percentField.valueChanges.subscribe(() => {
      this.currentBeneficiariesChange.emit();
    });
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}
