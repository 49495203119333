import { Inject } from '../decorators/decorators';

@Inject('$stateProvider')
export class UdbConfig {
  constructor(stateProvider: ng.ui.IStateProvider) {
    stateProvider
      .state('udb2', {
        url: '/udb',
        views: {
          root: {
            template: '',
          },
        },
      })
      .state('udb2.level1', {
        url: '/:arg1',
        views: {
          root: {
            template: '',
          },
        },
      })
      .state('udb2.level2', {
        url: '/:arg1/:arg2',
        views: {
          root: {
            template: '',
          },
        },
      });
  }
}
