import * as moment from 'moment';
import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IBlastMessagesService } from 'services/typings/IBlastMessagesService';
import { BlastMessageType } from 'layout/blast-message/blast-messages-type.enum';
import { BlastMessage } from 'typings/app/BlastMessage';
import { UserActionField, UserAction } from 'typings/app/UserAction';

@Inject('$http', 'env', 'serviceHelper', '$q')
class BlastMessagesService extends BaseService implements IBlastMessagesService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'blastmessages', _serviceHelper, q);
  }

  getBlastMessages(messageType: BlastMessageType): ApiResponse<BlastMessage[]> {
    return this.get(messageType.toString());
  }

  updateUserBlastMessage(blastMessage: BlastMessage): ApiResponse<void> {
    return this.post('userblastmessage', blastMessage);
  }

  logMessageDisplayed(blastMessage: BlastMessage): ApiResponse<void> {
    const fromDetails = `From ${moment(blastMessage.startDate).format('MM/DD/YYYY')} to ${moment(
      blastMessage.endDate
    ).format('MM/DD/YYYY')}`;
    const userActionField = new UserActionField(
      `Subject: ${blastMessage.subject}`,
      fromDetails,
      '1 time displayed'
    );
    const userAction = new UserAction('BlastMessageDisplayed', [userActionField]);

    return this.post('audit', userAction);
  }
}

export { BlastMessagesService };
