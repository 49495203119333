import { createAction, props } from '@ngrx/store';

import { FirmsRepresentative } from '@app/support/models/firms-representative.models';

const actionPrefix = '[Contact Firms]';

export const getUserContactFirms = createAction(`${actionPrefix} Get User Contact Firms`);
export const getUserContactFirmsSuccess = createAction(
  `${actionPrefix} Get User Contact Firms Success`,
  props<{ userContactFirms: FirmsRepresentative[] }>()
);
