import * as angular from 'angular';
import { IHttpProvider, IIntervalService, IRootScopeService, IWindowService } from 'angular';
import { IState, IStateService } from 'angular-ui-router';
import NgRedux from 'ng-redux';

import { UserProfileViews } from '@app/user-profile/enums';

import { BillPayRouterHelper } from './bill-pay/bill-pay-router.helper';
import { Inject } from './decorators/decorators';
import { FeatureFlagService } from './services/feature-flag.service';

@Inject(
  '$rootScope',
  '$window',
  '$interval',
  '$http',
  'env',
  'serviceHelper',
  '$ngRedux',
  'billPayRouterHelper',
  '$state',
  'featureFlagService'
)
export class AppRun {
  constructor(
    root: IRootScopeService,
    $window: IWindowService,
    interval: IIntervalService,
    http: IHttpProvider,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    ngRedux: NgRedux.INgRedux,
    billPayRouterHelper: BillPayRouterHelper,
    state: IStateService,
    featureFlagService: FeatureFlagService
  ) {
    let isFundingRunning = false;

    http.defaults.headers = Object.assign({ 'X-FacingBrandId': `${env.facingBrandId}` }, http.defaults.headers);

    ngRedux.subscribe(() => {
      isFundingRunning = ngRedux.getState().funding.isRunning;
    });

    root.$on('$stateChangeStart', (event, _toState, _toParams) => {
      if (!serviceHelper.isUserAuthenticated()) {
        if (_toState.name != 'udb.dashboard' && !_toState.name.includes('auth.')) {
          const goState = angular.copy(_toState);
          goState.params = _toParams;
          localStorage.setItem('goAhead', JSON.stringify(goState));
        }
        if (!_toState.name.includes('auth.')) {
          event.preventDefault();
          serviceHelper.signOut();
        }
      }
      if (_toState.name && (_toState.name.includes('udb.myBills') || _toState.name.includes('udb.billPay'))) {
        billPayRouterHelper.validateRoute(event, _toState.name);
      }
    });

    // when the url changes, call sitecore to register the page
    root.$on('$stateChangeSuccess', function (_event, toState: IState, toParams, fromState: IState) {
      if (toState.name.includes('udb.userProfile') || toState.name === 'udb.dashboard') {
        document.body.style.backgroundColor = '#F6F8F9';
      } else {
        document.body.style.backgroundColor = 'white';
      }

      if (toParams.type && toParams.type === 'Invest') {
        $('.dagger-axos-invest').css('display', 'block');
      } else {
        $('.dagger-axos-invest').css('display', 'none');
      }

      if (toState.name && (toState.name.includes('udb.myBills') || toState.name.includes('udb.billPay'))) {
        billPayRouterHelper.validateRoute(_event, toState.name);
      }

      redirectToUserProfile(fromState);
    });

    root.$on('$locationChangeSuccess', function (_event, $location) {
      document.title = GetTitleName($location);
    });

    let stopSearchingFeedbackTab = interval(() => {
      if ($('.QSISlider').children().length == 5 && root['brandProperties']) {
        const isFeedbackTabEnable = root['brandProperties']['FeedbackTabDisplayed'].toLowerCase() == 'true';
        // If FeedbackTab is enable, it will be displayed.
        if (isFeedbackTabEnable) {
          $('.QSISlider').css('display', 'block');
          $('.QSISlider').css('z-index', '1000');
          $('.QSISlider')[0].classList.add('hide-cs');

          const feedbackChildren = $('.QSISlider').children();
          const feedbackButton = feedbackChildren.eq(0);
          feedbackButton.css('top', '205px');
          feedbackButton.css('height', '120px');
          feedbackButton.css('outline', 'none');

          feedbackChildren.eq(1).css('left', '50px');
          feedbackChildren.eq(2).css('left', '52px');
          feedbackChildren.eq(3).css('left', '50px');
          feedbackChildren.eq(4).css('left', '6px');
        } else {
          // FeedBackTab will be removed.
          ($window as any).QSI.API.unload();
        }

        cancelInterval();
      }
    }, 1000);

    function cancelInterval() {
      if (angular.isDefined(stopSearchingFeedbackTab)) {
        interval.cancel(stopSearchingFeedbackTab);
        stopSearchingFeedbackTab = undefined;
      }
    }
    function GetTitleName(location: string) {
      const split = location.split('/').reverse();
      let title = '';
      for (let i = 0; i < split.length; i++) {
        if (split[i] !== '/' && split[i] !== '') {
          split.length == 9 ? (title = split[4]) : (title = split[i]);
          if (!Number(title) && title.indexOf('?') == -1) break;
        }
      }

      switch (title) {
        case 'AxosInvest':
          title = 'Axos Invest';
          break;
        case 'MakePayment':
          title = 'Pay Bills';
          break;
        case 'scheduled-payments':
          title = 'Scheduled Payments';
          break;
        case 'payments-due':
          title = 'Payments Due';
          break;
        case 'PayeeDetails':
          title = 'Payee Details';
          break;
        case 'payment-history':
          title = 'Payment History';
          break;
        case 'TransferFunds':
          title = 'Move Money';
          break;
        case 'ExternalAccounts':
          title = 'External Accounts';
          break;
        case 'SchedulerTransfers':
          title = 'Scheduled Transfers';
          break;
        case 'send-money':
          title = 'Pay It Now Send Money';
          break;
        case 'history':
          title = 'Pay It Now History';
          break;
        case 'MakeDeposit':
          title = 'Make a Deposit';
          break;
        case 'MyAlerts':
          title = 'Alerts & Notifications';
          break;
        case 'SignInAndSecurity':
          title = 'Login & Security';
          break;
        case 'PersonalInfo':
          title = 'Personal Info';
          break;
        case 'PersonalInformation':
          title = 'Personal Info';
          break;
        case 'CompanyInformation':
          title = 'Company Info';
          break;
        case 'ContactInfo':
          title = 'Contact Info';
          break;
        case 'EmploymentInfo':
          title = 'Employment Info';
          break;
        case 'ProfileIcon':
          title = 'Profile Icon';
          break;
        case 'DepositAndLoans':
          title = 'Deposit & Loans';
          break;
        case 'ProfileSettings':
          title = 'Profile & Settings';
          break;
        case 'PayItNow':
          title = 'Pay It Now Payments';
          break;
        case 'MyMessages':
          title = 'Message Center';
          break;
        case 'Messages':
          title = 'Case Details';
          break;
        case 'message-detail':
          title = 'Message Detail';
          break;
        case 'wire-transfers':
          title = 'Wire Transfers';
          break;
        case 'AccountAggregation':
        case 'auth':
          title = isFundingRunning ? 'Funding' : 'Account Aggregation';
          break;
        case 'RequestLimit':
          title = 'Request Limit Change';
          break;
        case 'bank':
        case 'investment':
        case 'bank':
        case 'creditCard':
        case 'investment':
        case 'insurance':
        case 'loan':
        case 'otherAssets':
        case 'otherLiabilities':
        case 'realEstate':
        case 'reward':
        case 'bill':
        case 'AasAccount':
          title = 'Account';
          break;
        case 'welcome':
        case 'pay':
        case 'activity':
        case 'search-recipient':
        case 'schedule-payment':
        case 'billing-details':
        case 'electronic-address':
        case 'electronic-confirmation':
        case 'electronic-checkpoint':
        case 'pay-review':
        case 'pay-confirmation':
          title = 'Bill Pay';
          break;
        case 'full':
        case 'ForgotPassword':
        case 'ForgotUsername':
          title = 'Login';
          break;
        case 'BusinessInformation':
          title = 'Business Info';
          break;
      }
      // Support titles
      if (title.indexOf('cName') != -1) {
        title = parseQueryString(title).cname.replace(/%20/g, ' ').toString();
      }

      return `${title} | ${env.brandName}`;
    }

    //Todo SBB 947: remove this method.
    function redirectToUserProfile(fromState: IState) {
      if (!featureFlagService.isSBBActive) {
        const splicedUrl: string[] = $window.location.href.split('/');
        if (splicedUrl.includes('UserProfile') && fromState.name !== 'udb.userProfile') {
          let userProfileView: string = splicedUrl.filter(item => {
            if (item === UserProfileViews.ContactInfo || item === UserProfileViews.PersonalInfo) return item;
          })[0];
          state.go('udb.userProfile', { view: userProfileView });
        }
      }
    }

    let feedbackLeft = '';

    $window.onclick = function () {
      if (!feedbackLeft) {
        feedbackLeft = $('.QSISlider').css('left');
      }
    };

    const parseQueryString = function getAllUrlParams(url: string) {
      let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
      const obj: any = {};
      if (queryString) {
        queryString = queryString.split('#')[0];
        const arr = queryString.split('&');
        for (let i = 0; i < arr.length; i++) {
          const a = arr[i].split('=');
          let paramNum;
          let paramName = a[0].replace(/\[\d*\]/, function (v) {
            paramNum = v.slice(1, -1);

            return '';
          });
          let paramValue = typeof a[1] === 'undefined' ? true : a[1];
          paramName = paramName.toString().toLowerCase();
          paramValue = paramValue.toString();
          if (obj[paramName]) {
            if (typeof obj[paramName] === 'string') {
              obj[paramName] = [obj[paramName]];
            }
            if (typeof paramNum === 'undefined') {
              obj[paramName].push(paramValue);
            } else {
              obj[paramName][paramNum] = paramValue;
            }
          } else {
            obj[paramName] = paramValue;
          }
        }
      }

      return obj;
    };
  }
}
