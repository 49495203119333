import { Inject } from '../../../decorators/decorators';
import { IStateService } from 'angular-ui-router';
import { BillPayRouterHelper } from '../../bill-pay-router.helper';
import { PartialRecipient } from '../../typings/PartialRecipient';
import { Recipient } from 'bill-pay/typings/Recipient';

@Inject('$state', 'billPayRouterHelper', 'loadUserProfileHelper')
export class CheckConfirmationController {
  recipient: PartialRecipient;
  detailsTooltip: string;
  email: string;
  recipientDetails: Recipient;
  constructor(
    private readonly state: IStateService,
    private readonly billPayRouterHelper: BillPayRouterHelper,
    private readonly loadUserProfileHelper: ILoadUserProfileHelper
  ) {}
  $onInit(): void {
    this.recipient = this.billPayRouterHelper.getRecipient();
    this.recipientDetails = this.billPayRouterHelper.getRecipientDetails();
    if (!this.recipientDetails) this.state.go('udb.billPay.searchRecipient');

    this.billPayRouterHelper.clean();
    const profile = this.loadUserProfileHelper.getUserProfile();
    this.email = profile ? profile.primaryEmail : '';
    this.detailsTooltip = `
        <strong>Electronic recipients</strong>
        are paid instantly, We'll withdraw  the payment the day before your payment is due. Your recipient will receive your payment on its due date.
        <br><br>
        <strong>Check recipients</strong>
        are paid with paper checks. We mail checks 5 business days before your payment's due date. Your money will leave your account when the recipient deposits the check.
        `;
  }

  redirectToSchedule(): void {
    this.state.go('udb.billPay.schedulePayment', { recipient: this.recipientDetails });
  }
}
