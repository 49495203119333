import { Inject } from '../decorators/decorators';
import { IMessagesService } from '../services/typings/IMessagesService';

@Inject('serviceHelper', 'messagesService')
export class MessagesSharedService {
  private readonly messagesSharedData: MessagesSharedData;

  constructor(
    private readonly serviceHelper: IServiceHelper,
    private readonly messagesService: IMessagesService
  ) {
    this.messagesSharedData = new MessagesSharedData();
  }

  callUnreadMessagesCount(): void {
    this.messagesService.getUnreadMessagesCount().subscribe(
      res => {
        this.messagesSharedData.unreadMessagesCount = res.data;
      },
      err => {
        this.serviceHelper.errorHandler(err, false);
      }
    );
  }

  getUnreadMessagesCount(): MessagesSharedData {
    return this.messagesSharedData;
  }
}

export class MessagesSharedData {
  /** Number of unread messages. */
  unreadMessagesCount: number = 0;
}
