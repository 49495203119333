import * as angular from 'angular';
import { IFilterService, IRootScopeService, IScope } from 'angular';
import { IStateService } from 'angular-ui-router';
import * as moment from 'moment';
import { BeneficiariesIraEnhHelper } from 'services/beneficiaries-iraenh.helper';
import { BeneficiariesHelper } from 'services/beneficiaries.helper';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { LoanAccountHelper } from 'services/loan-account.helper';
import { MinorHelper } from 'services/minor.helper';
import { IAccountsService } from 'services/typings/IAccountsService';
import { ILoanService } from 'services/typings/ILoanService';
import { IPropertyBagService } from 'services/typings/IPropertyBagService';

import { BeneficiaryType } from '@app/shared/enums/beneficiary-type';
import { AccountNickname } from '@legacy/models';
import { RewardsCheckingHelper } from '@legacy/services/rewards-checking.helper';

import { DebitCardStatusType } from '../../common/enums/debitCardStatusType.enum';
import { mapDebitCardStatusType } from '../../common/mappings/debit-card-statusType.mapping';
import { mapDebitCardType } from '../../common/mappings/debit-cardType.mapping';
import { Inject } from '../../decorators/decorators';
import { DebitCardService } from '../../services/debit-card.service';
import { ModalService } from '../../services/modal.service';
import { AccountCategory } from '../account-category.enum';
import { PaymentStatus } from './payment-status.enum';
import { AccountDetails } from '../typings/AccountDetails';
import { UserSubType } from '@core/enums';
import { AxosAdvisoryAccount } from '@core/models';
import { CookieHelper } from '@legacy/shared/helpers/cookie.helper';

@Inject(
  '$rootScope',
  '$scope',
  '$filter',
  '$state',
  'accountsService',
  'serviceHelper',
  'debitCardService',
  'modalService',
  'env',
  'cachedAccountsService',
  '$uibModal',
  '$sanitize',
  'featureFlagService',
  'loanService',
  'loanAccountHelper',
  'beneficiariesHelper',
  'minorHelper',
  'beneficiariesIraEnhHelper',
  'propertyBagService',
  'rewardsCheckingHelper',
  'cookieHelper'
)
export class AccountDetailsController {
  /** Input parameter for the component */
  accountId: number;
  /** Input parameter for the component */
  category: number;
  /** Account detail object */
  accountDetails: AccountDetails = {};
  /** Nickname linked to the view */
  nickname: string;
  /** Indicates if card is loading or not */
  isLoading = false;
  /** Indicates if it's saving the nickname */
  isSaving = false;
  /** Indicates if it's editing the nickname */
  isEditing = false;
  /** Flag to validate if the user has pressed the Save button */
  mouseDown = false;
  /** Display if the account has mandatory distribution */
  mandatoryDistributionDisplay: string;
  isODS = false;
  account: OlbAccount;
  accountDetailsBand = true;
  pastDue: number;
  primaryToolTipMesage = `Beneficiaries who are first to receive the assets in your account.
  If no beneficiaries are named, the assets will go to your estate.`;
  contingentToolTipMessage = `Beneficiaries who receive the assets in your account only when
  there are no living primary beneficiaries.`;
  pendingConsentToolTipMessage = `Your change of beneficiaries is pending until your spouse
  provides their consent.`;
  isLoadingHC = false;
  activeStopPayment = false;
  isSignatureCardActive = false; // Changed to false because PBI 1045210: OLB - Remove Signature Card Prompt
  canPlaceStops: number;
  /* It's used to display routing number information to converted users */
  routingNumberInfo = '';

  /** Indicates if the payment is past due */
  isPastDue = false;
  paymentDetails: NextPayment = {};
  isLoadingEscrowDetails = false;
  isLoadingCards = false;
  pendingSpousalConsent = false;
  primaryBeneficiaries: TaxPlanBeneficiary[];
  contingentBeneficiaries: TaxPlanBeneficiary[];
  getDebitCardDesc = mapDebitCardType;
  getDebitCardStatusDesc = mapDebitCardStatusType;
  dontShowStatus = [
    DebitCardStatusType.Closed,
    DebitCardStatusType.HotCard,
    DebitCardStatusType.Limits,
    DebitCardStatusType.Manually,
    DebitCardStatusType.PinMailer,
    DebitCardStatusType.Securomatic,
    DebitCardStatusType.PinRetries,
    DebitCardStatusType.DoNotReorder,
    DebitCardStatusType.Deleted,
  ];
  brand: string;
  /* Incoming loan payment expected to hit the account first and with the largers amount. */
  incomingTransaction: IncomingTransaction;
  /** Indicates if the loan account has incoming transactions. */
  hasIncomingTransaction = false;
  paymentStatus: PaymentStatus;
  paymentInfo: any;
  canShowBeneficiaries = false;
  canShowMinors = false;
  canAddEditBeneficiaries = false;
  hasBeneficiaries = false;
  hasJointOwners = false;
  modalText: string;
  isToastPendingMessages = false;
  toastStatus = 'success';
  toastMessage: string;
  activeIraEnhBase = false;
  activeIraEnhDira = false;
  isRewardsCheckingAccount = false;
  isSBB = false;
  private listeners: Function[] = [];
  showInvestorCheckingAccountFeaturesForRia: boolean = false;
  activeRequestLoanPayoff = false;
  showLoanForm = false;
  loanPayoffUrl: string;
  loanPayoffJWT: string;
  isRiaShareAccountFlag = false;
  isRiaAuthorizeTransfersFlag = false;
  shouldDisplayShareAccount = false;
  shouldDisplayAuthorizeTransfer = false;
  accountSent: AxosAdvisoryAccount;

  constructor(
    private root: IRootScopeService,
    private scope: IScope,
    private filter: IFilterService,
    private state: IStateService,
    private accountsService: IAccountsService,
    private serviceHelper: IServiceHelper,
    private debitCardService: DebitCardService,
    private modalService: ModalService,
    private env: OlbSettings,
    private cachedAccountsService: CachedAccountsService,
    private uibModal: ng.ui.bootstrap.IModalService,
    private sanitize: any,
    private featureFlagService: FeatureFlagService,
    private loanService: ILoanService,
    private loanAccountHelper: LoanAccountHelper,
    private beneficiariesHelper: BeneficiariesHelper,
    private minorHelper: MinorHelper,
    private beneficiariesIraEnhHelper: BeneficiariesIraEnhHelper,
    private propertyBagService: IPropertyBagService,
    private rewardsCheckingHelper: RewardsCheckingHelper,
    private cookieHelper: CookieHelper
  ) {}

  /** Initializes any required data */
  $onInit(): void {
    this.isLoading = true;
    this.activeStopPayment = this.featureFlagService.isStopPaymentActive();
    this.activeIraEnhBase = this.featureFlagService.isIraEnhBaseFlagActive();
    this.activeIraEnhDira = this.featureFlagService.isIraEnhDiraFlagActive();
    this.activeRequestLoanPayoff = this.featureFlagService.isRequestLoanPayoffOutsystemsActive();
    this.isRiaShareAccountFlag = this.featureFlagService.isRiaShareAccountActive();
    this.isRiaAuthorizeTransfersFlag = this.featureFlagService.isRiaAuthorizeTransfersActive();
    this.canPlaceStops = this.scope.$parent['vm'].account.canPlaceStops;
    this.brand = String(this.env.brand);
    this.paymentInfo = {};
    this.handleBeneficiaries();
    this.handleMinors();
    this.handleBeneficiariesIraEnh();
    this.setShowInvestorCheckingAccountFeaturesForRia();
    this.validateIfIsDepositAccount();

    if (this.root['brandProperties']) {
      this.routingNumberInfo = this.sanitize(
        this.root['brandProperties']['RoutingNumberInformation']
      );
    } else {
      this.root.$on('brandPropertiesLoaded', function () {
        this.routingNumberInfo = this._sanitize(
          this.root['brandProperties']['RoutingNumberInformation']
        );
      });
    }

    this.listeners.push(
      this.root.$on(
        'cancelResendSpousalConsent',
        this.cancelResendSpousalConsent.bind(this)
      )
    );

    this.accountsService
      .getAccountDetails(this.accountId)
      .then(res => {
        this.accountDetails = res.data;
        // Check if Additional and/or primary owners will be shown
        this.isSBB =
          (this.accountDetails.isSbb &&
            this.accountDetails.primaryOwnerName?.length > 0) ||
          this.accountDetails.additionalOwnerNames?.length > 0;
        if (this.accountDetails.bofiAccountType.toLowerCase() === 'mortgage') {
          this.pastDue =
            this.accountDetails.totalAmountDue -
            this.accountDetails.billedPrincipal -
            this.accountDetails.billedEscrow;
          this.pastDue <= 0
            ? (this.accountDetailsBand = false)
            : (this.accountDetailsBand = true);
        }
        if (this.accountDetails.bofiAccountType == 'Personal Loan') {
          this.accountDetails.accountPayment.pastDueAmount <= 0
            ? (this.accountDetailsBand = false)
            : (this.accountDetailsBand = true);
        }

        this.nickname = this.accountDetails.nickname;
        this.mandatoryDistributionDisplay = this.accountDetails
          .mandatoryDistribution
          ? 'YES'
          : 'NO';
        this.scope.$emit('detailsRetrieved', res.data);
        if (this.accountDetails.bofiAccountType.toLowerCase() === 'mortgage') {
          this.loadLoanDetails(this.accountId);
        }
        if (this.category === AccountCategory.Loan) {
          this.getLoanPendingPayments();
          this.isPastDue = this.getIsPastDue(
            this.accountDetails.accountPayment.dueDate,
            this.accountDetails.accountPayment.gracePeriod
          );
        }

        // Deposit IRA Accounts (primary and contingent beneficiaries)
        if (
          this.accountDetails.taxPlanType != null &&
          this.accountDetails.taxPlanBeneficiaries
        ) {
          this.primaryBeneficiaries = this.accountDetails.taxPlanBeneficiaries.filter(
            (x: TaxPlanBeneficiary) => x.level === BeneficiaryType.Primary
          );
          this.contingentBeneficiaries = this.accountDetails.taxPlanBeneficiaries.filter(
            (x: TaxPlanBeneficiary) => x.level === BeneficiaryType.Contingent
          );
        }

        // Pending spousal consent request
        this.pendingSpousalConsent = this.accountDetails.pendingSpousalConsent;

        this.hasBeneficiaries =
          this.accountDetails.beneficiaries &&
          this.accountDetails.beneficiaries.length != 0;
        this.hasJointOwners =
          this.accountDetails.additionalOwnerNames &&
          this.accountDetails.additionalOwnerNames.length > 0;
        this.canShowBeneficiaries =
          this.featureFlagService.isBeneficiariesActive() &&
          this.account.displayBeneficiariesSection;
        this.canAddEditBeneficiaries =
          this.featureFlagService.isBeneficiariesActive() &&
          this.beneficiariesHelper.isAddBeneficiariesActive(this.account);
        this.canShowMinors =
          this.featureFlagService.isMinorsActive() &&
          this.account.displayMinorsSection;

        this.setIsRewardsCheckingAccount();
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
        this.isLoading = false;
      });

    // quick fix menu
    $('.m-accounts').addClass('active');

    this.loanPayoffUrl =
      this.env.loanPayoffOutsystemsURL + '|' + this.account.accountNumber;
    this.loanPayoffJWT = this.cookieHelper.getToken();
  }

  $onDestroy(): void {
    this.listeners.forEach(unsubscribe => unsubscribe());
    localStorage.removeItem('minorHelperResponse');
  }

  /**
   * Gets the Description of the product type
   * @returns Description of product type
   */
  mapProduct(): string {
    return this.accountDetails.bofiAccountType;
    // return mapProductType(this.accountDetails ? this.accountDetails.productType : 0);
  }

  /**
   * Updates the account nickname, performs a call to the service and emit an event
   * to update the nickname in the main Account page
   */
  saveAccountNickname(): void {
    this.isSaving = true;
    let accounts: OlbAccount[];

    switch (this.account.category) {
      case AccountCategory.Dda:
      case AccountCategory.Sav:
      case AccountCategory.Cd:
        accounts = this.root['accounts'].depositAccounts as OlbAccount[];
        break;
      case AccountCategory.Loan:
        accounts = this.root['accounts'].loanAccounts as OlbAccount[];
        break;
      default:
        accounts = new Array<OlbAccount>();
        break;
    }

    const clearNickName = !this.nickname;

    this.accountsService
      .updateNickname(this.accountId, clearNickName ? null : this.nickname)
      .then(() => {
        const account = accounts.find(a => a.id === this.account.id);
        if (clearNickName) {
          const accNo = this.account.accountNumber.toString();
          this.nickname = `${account.name} **${accNo.substring(
            accNo.length - 4,
            accNo.length
          )}`;
        }
        account.nickname = this.nickname;
        this.isEditing = !this.isEditing;
        this.accountDetails.nickname = this.nickname;
        const accountNickname: AccountNickname = {
          id: +this.accountId,
          nickname: this.nickname,
        };
        this.scope.$emit('nicknameUpdated', accountNickname);
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
        this.isSaving = false;
      });
  }

  /**
   * Triggered when the user clicks outside the nickname's entry box and has not clicked
   * the Save button, then the nickname editing is cancelled
   */
  cancelNicknameUpdate(): void {
    if (!this.mouseDown) {
      this.nickname = this.accountDetails.nickname;
      this.isEditing = !this.isEditing;
    }
  }

  /**
   * Gets the amount formatted
   * @param amount
   */
  getAmount(amount: any): string {
    if (amount == null || amount.length === 0 || amount === 0) {
      return '-';
    } else {
      return this.filter('currency')(amount);
    }
  }

  /**
   * Gets a date from a string, if it's not a valid date returns -
   * @param date Date to be evaluated
   */
  getDate(date: any): string {
    if (date == null || date.length === 0 || date === '0001-01-01T00:00:00') {
      return '-';
    } else {
      return this.filter('date')(new Date(date), 'MM/dd/yyyy');
    }
  }

  /**  Enables the nickname's entry box for editing */
  editNickname(): void {
    if (!this.isODS) {
      this.isEditing = !this.isEditing;
      if (this.nickname?.length > 30) {
        this.nickname = this.nickname.substring(0, 30);
      }
      setTimeout(() => {
        angular.element('#nickname').focus();
      }, 200);
    }
  }

  /** Redirects to transfer funds page */
  gotransferFunds(): void {
    const { isLate, lateMessage } = this.loanAccountHelper.isPaymentLate(
      this.account.loanDueDate
    );
    const { isMature, matureMessage } = this.loanAccountHelper.isAccountMature(
      this.account
    );
    if (!isLate && !isMature) {
      sessionStorage.setItem('isPayoff', 'false');
      this.state.go('udb.transfers.loanPayment', {
        toAccountId: this.account.id,
      });
    } else {
      this.modalText = isLate ? lateMessage : matureMessage;
      this.showLoanWarning();
    }
  }

  /**
   * States if the user can view details for the specified card
   * @param status The card status
   * @param isBlocked The blocked flag
   */
  canViewCardDetails(status: DebitCardStatusType, isBlocked: boolean): boolean {
    switch (status) {
      case DebitCardStatusType.Active:
      case DebitCardStatusType.InProcess:
      case DebitCardStatusType.Reorder:
        return true;

      case DebitCardStatusType.Warm:
        return isBlocked;
    }

    return false;
  }

  /**
   * Blocks the card (by the user)
   * @param card Debit card object
   */
  blockingCard(card: DebitCard): void {
    this.isLoading = true;
    this.debitCardService
      .blockCard(this.accountId, card.cardNumberMask, !card.isActive)
      .then(resp => {
        card.isActive = !resp.data.isBlocked;
        card.isBlocked = resp.data.isBlocked;
        card.status = resp.data.status;
        this.cachedAccountsService.updateLinkedDebitCards(card);
        this.disabledToggle(card.status.valueOf(), card.isBlocked);

        this.modalService
          .show(
            {},
            {
              bodyText: 'Card status updated successfully',
              okText: 'Continue',
              hasCancelButton: false,
            }
          )
          .catch(() => {
            return;
          });
      })
      .catch(error => {
        card.isActive = !card.isBlocked;
        this.serviceHelper.errorHandler(error, true);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Determines if the toggles should be shown or not
   * @param status Debit card's status
   * @param isBlocked Flag if blocked by user or JH
   */
  showToogle(status: DebitCardStatusType, isBlocked: boolean): boolean {
    return (
      status === DebitCardStatusType.Active ||
      (status === DebitCardStatusType.Warm && isBlocked)
    );
  }

  /**
   * Validates if the credit card is "In Process" status.
   * @param status Debit card's status
   */
  isInProcess(status: DebitCardStatusType): boolean {
    return status === DebitCardStatusType.InProcess;
  }

  /**
   * Activates the card (by the user)
   * @param card Debit card object
   */
  activateCard(card: DebitCard) {
    this.isLoading = true;
    this.debitCardService
      .activateCard(this.accountId, card.cardNumberMask)
      .then(resp => {
        if (resp.data) {
          card.isActive = !resp.data.isBlocked;
          card.isBlocked = resp.data.isBlocked;
          card.status = DebitCardStatusType.Active.valueOf();
          card.disabledToggle = this.disabledToggle(
            card.status.valueOf(),
            card.isBlocked
          );
          this.cachedAccountsService.updateLinkedDebitCards(card);

          this.uibModal.open({
            templateUrl:
              'accounts/account-details/card-activated-modal/card-activated-modal.tpl.html',
            windowClass: 'modal-service',
            controller: 'CardActivatedModalController',
            controllerAs: '$ctrl',
            backdrop: 'static',
            resolve: {},
          });
        }
      })
      .catch(error => {
        this.serviceHelper.errorHandler(error, true);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  editBeneficiaries(event: Event, contingent: boolean): void {
    const isDepositIra = this.accountDetails.taxPlanType != null;

    if (
      (this.activeIraEnhBase && !isDepositIra) ||
      (this.activeIraEnhDira && isDepositIra)
    ) {
      if (this.pendingSpousalConsent) {
        this.showSpousalConsentWarning();
      } else {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.state.go('udb.accounts.beneficiaries', {
          id: this.accountId,
          contingent,
        });
      }
    } else {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.state.go('udb.beneficiaries.list', {
        accountId: this.accountId,
        beneficiaries: this.accountDetails.beneficiaries,
      });
    }
  }

  cancelResendSpousalConsent(
    _e: ng.IAngularEvent,
    resend: boolean,
    spouseEmail: string
  ) {
    const spousalConsentUpdate: SpousalConsentUpdate = {
      consentRequestStatus: 'Cancelled',
      taxPlanType: this.accountDetails.taxPlanType,
    };
    if (resend) {
      spousalConsentUpdate.consentRequestStatus = 'Resend';
      spousalConsentUpdate.spouseEmail = spouseEmail;
    }
    this.propertyBagService
      .cancelResendSpousalConsent(spousalConsentUpdate)
      .then(() => {
        if (resend) {
          this.showConfirmationEmailModal(spouseEmail);
        } else {
          this.pendingSpousalConsent = false;
          this.scope.$emit('updatePendingConsent', this.pendingSpousalConsent);
        }
      })
      .catch(err => {
        if (err.status !== 404) {
          this.serviceHelper.errorHandler(err);

          return;
        }
      });
  }

  gotoRewardsPage() {
    this.state.go('udb.accounts.details', { id: this.accountId, tab: 5 });
  }

  /**
   * Loads the details of the specified account
   * @param accountId Acount's identifier
   */
  private loadLoanDetails(accountId: number): void {
    this.isLoadingEscrowDetails = true;
    this.accountsService
      .getLoanDetails(accountId)
      .then(res => {
        this.paymentDetails = res.data;
      })
      .catch(this.serviceHelper.errorHandler.bind(this.serviceHelper))
      .finally(() => {
        this.isLoadingEscrowDetails = false;
      });
  }

  /**
   * Determines if the the date given is past the due date
   * @param dueDate the due date
   * @param gracePeriod grace period
   */
  private getIsPastDue(dueDate: Date, gracePeriod: number): boolean {
    if (dueDate == null) return false;
    const due = new Date(dueDate);
    // We will be using 15 days as grace period for all accounts for now, instead of the JH field
    gracePeriod = 15;
    due.setDate(due.getDate() + gracePeriod);
    const nowDate = new Date();
    if (nowDate > due) return true;

    return false;
  }

  /**
   * Determines if the switch to update the card should be enabled or not
   * @param status Debit card's status
   * @param isBlocked Flag if blocked by user or JH
   */
  private disabledToggle(status: DebitCardStatusType, isBlocked: boolean) {
    switch (status) {
      case DebitCardStatusType.Active:
        return false;
      case DebitCardStatusType.Warm:
        return !isBlocked;
      default:
        return true;
    }
  }

  /**
   * Gets the incoming loan payment expected to hit the account first and with the largers amount.
   */
  private getLoanPendingPayments(): void {
    this.loanService
      .getPendingPayments([this.accountId], 1)
      .then(result => {
        this.hasIncomingTransaction = false;
        if (result.data.length > 0) {
          this.hasIncomingTransaction = true;
          this.incomingTransaction = result.data[0];
        }
        this.paymentStatus = this.getPaymentStatus();
      })
      .catch(this.serviceHelper.errorHandler);
  }

  /**
   * Gets a status to indicate the payment info to display
   * (Pending, Confirmed, Next Payment, Past Due Payment or none)
   */
  private getPaymentStatus(): PaymentStatus {
    // Pending Payment
    if (this.hasIncomingTransaction) {
      this.paymentInfo = {
        title: 'Loan Payment Pending',
        account: this.account,
        status: PaymentStatus.PaymentConfirmed,
        items: [
          {
            name: 'Payment Amount',
            value: this.filter('currency')(
              this.incomingTransaction.paymentAmout
            ),
          },
          {
            name: 'Scheduled Date',
            value: this.filter('date')(
              this.incomingTransaction.scheduledDate,
              'MM/dd/yyyy'
            ),
          },
          {
            name: 'Post Date',
            value: this.filter('date')(
              this.incomingTransaction.deliveryDate,
              'MM/dd/yyyy'
            ),
          },
          {
            name: 'Payment Account',
            value: this.incomingTransaction.externalBankName,
          },
        ],
      };

      return PaymentStatus.PaymentPending;
    }

    // Past Due Payment
    if (
      !this.hasIncomingTransaction &&
      this.accountDetails.totalAmountDue > 0
    ) {
      this.paymentInfo = {
        title: this.isPastDue ? 'Past Due Loan Payment' : 'Next Loan Payment',
        isPastDue:
          moment().diff(this.accountDetails.accountPayment.dueDate, 'days') > 0, // This is used for styling
        dueDate: this.filter('date')(
          this.accountDetails.accountPayment.dueDate,
          'MM/dd/yyyy'
        ),
        items: [],
      };
      if (this.accountDetails.billedPrincipal) {
        this.paymentInfo.items.push({
          name: 'Billed Principal',
          value: this.filter('currency')(this.accountDetails.billedPrincipal),
        });
      }
      if (this.accountDetails.billedInterest) {
        this.paymentInfo.items.push({
          name: 'Billed Interest',
          value: this.filter('currency')(this.accountDetails.billedInterest),
        });
      }
      if (this.accountDetails.billedEscrow) {
        this.paymentInfo.items.push({
          name: 'Billed Escrow',
          value: this.filter('currency')(this.accountDetails.billedEscrow),
        });
      }
      if (this.accountDetails.billedLateCharges) {
        this.paymentInfo.items.push({
          name: 'Billed Late Charges',
          value: this.filter('currency')(this.accountDetails.billedLateCharges),
        });
      }
      if (this.accountDetails.billedOtherCharges) {
        this.paymentInfo.items.push({
          name: 'Billed Other Charges',
          value: this.filter('currency')(
            this.accountDetails.billedOtherCharges
          ),
        });
      }
      this.paymentInfo.items.push({
        name: 'Total Amount Due',
        value: this.filter('currency')(this.accountDetails.totalAmountDue),
        bold: true,
      });

      return PaymentStatus.NextDuePayment;
    }

    // Confirmed Payment
    if (
      moment().diff(
        this.accountDetails.accountPayment.lastPaymentDate,
        'days'
      ) <= 3
    ) {
      this.paymentInfo = {
        title: 'Payment Confirmed',
        account: this.account,
        status: PaymentStatus.PaymentConfirmed,
        items: [
          {
            name: 'Payment Date',
            value: this.filter('date')(
              this.accountDetails.accountPayment.lastPaymentDate,
              'MM/dd/yyyy'
            ),
          },
          {
            name: 'Payment Amount',
            value: this.filter('currency')(
              this.accountDetails.accountPayment.lastPaymentAmount
            ),
          },
        ],
      };

      return PaymentStatus.PaymentConfirmed;
    }

    // Next Payment
    if (this.accountDetails.accountPayment.dueDate) {
      this.paymentInfo = {
        title: 'Next Payment',
        items: [
          {
            name: 'Due Date',
            value: this.filter('date')(
              this.accountDetails.accountPayment.dueDate,
              'MM/dd/yyyy'
            ),
          },
          {
            name: 'Payment Amount',
            value: this.filter('currency')(
              this.accountDetails.accountPayment.amount
            ),
          },
        ],
      };

      return PaymentStatus.NextPayment;
    }
    // Default; no payment due
    this.paymentInfo = {
      title: 'No Payment Due at this time',
    };

    return PaymentStatus.Default;
  }

  //#region Loan Validations

  private showLoanWarning() {
    this.modalService.show(
      { size: 'sm' },
      {
        bodyText: this.modalText,
        hasCancelButton: false,
        hasIcon: true,
        hasRedirectLink: false,
        icon: 'bofi-warning',
        okText: 'Close',
        okButtonClass: 'uk-btn outline primary sm',
      }
    );
  }

  private handleBeneficiaries() {
    const beneficiarieResponse = this.beneficiariesHelper.getBeneficiaryResponse();

    if (beneficiarieResponse) {
      this.beneficiariesHelper.clearBeneficiaryResponse();

      if (beneficiarieResponse.hasErrors) {
        this.toastStatus =
          beneficiarieResponse.successCount === 0 ? 'error' : 'warning';
      }
      this.toastMessage = beneficiarieResponse.message;
      this.isToastPendingMessages = true;
    }
  }

  private handleMinors() {
    const minorResponse = this.minorHelper.getMinorResponse();

    if (minorResponse) {
      this.toastStatus = minorResponse.hasErrors ? 'error' : 'success';
      this.toastMessage = minorResponse.message;
      this.isToastPendingMessages = true;
    }

    const minorResponseStorage = JSON.parse(
      localStorage.getItem('minorHelperResponse')
    );
    if (minorResponseStorage) {
      this.toastStatus = minorResponseStorage.hasErrors ? 'error' : 'success';
      this.toastMessage = minorResponseStorage.message;
      this.isToastPendingMessages = true;
    }
  }

  private handleBeneficiariesIraEnh() {
    if (this.beneficiariesIraEnhHelper.toastMessage) {
      this.toastStatus = this.beneficiariesIraEnhHelper.toastType.toString();
      this.toastMessage = this.beneficiariesIraEnhHelper.toastMessage;
      this.isToastPendingMessages = true;

      this.beneficiariesIraEnhHelper.clearToast();
    }
  }

  private setIsRewardsCheckingAccount() {
    this.isRewardsCheckingAccount = this.rewardsCheckingHelper.isRewardsCheckingAccount(
      this.root,
      this.account.productCode
    );
  }

  private showSpousalConsentWarning() {
    this.uibModal.open({
      templateUrl:
        'accounts/account-details/spousal-consent-modal/spousal-consent-modal.tpl.html',
      windowClass: 'modal-service',
      controller: 'SpousalConsentModalController',
      controllerAs: '$ctrl',
      backdrop: true,
      resolve: {
        spouseConsentEmail: (): string =>
          this.accountDetails.spousalConsentEmail,
      },
    });
  }

  private showConfirmationEmailModal(spouseEmail: string) {
    this.modalService.show(
      {
        size: 'sm',
      },
      {
        icon: 'bofi-success',
        hasIcon: true,
        bodyText: `<h3>Email Sent!</h3>
        <p>The consent email was sent to your spouse's address below</p></br>
        <p><u>${spouseEmail}</u></p>`,
        okText: 'Done',
        hasHeaderText: false,
        hasCancelButton: false,
        hasClose: false,
      }
    );
  }
  //#endregion

  private setShowInvestorCheckingAccountFeaturesForRia() {
    const userSubType = parseInt(sessionStorage.getItem('userSubType'));
    const hasAxosAdvisoryService =
      (userSubType & UserSubType.AxosAdvisoryService) > 0;
    const isInvestorCheckingAccount = this.account.productCode === 'X2';
    this.showInvestorCheckingAccountFeaturesForRia =
      hasAxosAdvisoryService && isInvestorCheckingAccount;
  }

  private validateIfIsDepositAccount() {
    if (this.account.isDeposit) {
      this.shouldDisplayAuthorizeTransfer = true;
      this.shouldDisplayShareAccount = true;
    } else if (this.account.isLoan) {
      this.shouldDisplayAuthorizeTransfer = false;
      this.shouldDisplayShareAccount = true;
    }
  }

  showLoanPayoff(): void {
    if (this.showLoanForm) {
      // Update iframe URL when displayed already
      let urlCopy = this.loanPayoffUrl;
      this.loanPayoffUrl = '';

      setTimeout(() => {
        this.loanPayoffUrl = urlCopy;
      }, 1);
    }

    if (!this.showLoanForm) {
      // Show Loan Payoff iframe
      this.showLoanForm = true;
    }
  }
}
