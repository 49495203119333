import { Pipe, PipeTransform } from '@angular/core';

import { formatPhone } from '@app/utils';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phone: string): string {
    return formatPhone(phone);
  }
}
