<app-view-header titleText="Update {{ prefix }} Beneficiaries">{{ account?.nickname }}</app-view-header>
<div class="beneficiaries-ira-enh row justify-content-center px-3 p-sm-0">
  <div class="col-12 col-sm-10 col-lg-8">
    <button
      autofocus
      type="button"
      class="link d-flex align-items-center"
      id="back-to-account-details-btn"
      (click)="goBackToAccountDetailsPrompt()"
    >
      <app-svg-icon [iconName]="icons.arrow" [styles]="{ strokeWidth: 3 }" size="0.75rem"></app-svg-icon>
      <span class="ml-1">Back to Account Details</span>
    </button>

    <app-dot-loader *ngIf="isLoadingAccount || isLoadingRelationshipCatalog; else content">
      Loading your beneficiaries
    </app-dot-loader>

    <ng-template #content>
      <div class="text-center my-4">
        <h4>Add or modify your {{ prefix }} Beneficiaries</h4>
        <div style="font-style: italic;" class="col-md-10 my-3 mx-auto">
          You may not designate yourself or another account owner as a beneficiary. If no beneficiaries are named, the
          assets in your account will go to your estate.
        </div>
      </div>

      <form [formGroup]="beneficiariesForm" (ngSubmit)="submit()">
        <ng-container *ngFor="let beneficiaryForm of beneficiariesFormArray?.controls; let i = index">
          <acc-beneficiary
            [parentForm]="beneficiaryForm"
            [index]="i"
            [relationshipCatalog]="relationshipCatalog"
            [hasMultipleSpouses]="hasMultipleSpousesError"
            [labelPrefix]="prefix"
            (relationshipChange)="changedRelationship = $event"
            (currentBeneficiariesChange)="setEditingCurrentBeneficiaries()"
            (remove)="removeBeneficiaryByIndex($event)"
          ></acc-beneficiary>
        </ng-container>

        <div class="d-flex mt-3 beneficiaries-ira-enh__items-container">
          <div class="col-sm-6 beneficiaries-ira-enh__add-beneficiary">
            <button type="button" id="add-beneficiary-btn" class="link" (click)="addBeneficiaryFromUI()">
              <app-svg-icon [iconName]="icons.add" [styles]="{ strokeWidth: 3 }" size="0.75rem"></app-svg-icon>
              Add another beneficiary
            </button>
          </div>

          <div class="d-flex col-sm-6 beneficiaries-ira-enh__total-share">
            <span class="d-flex align-items-center mr-3">
              Total Share
              <app-tooltip [content]="tooltip"></app-tooltip>
            </span>
            <span class="d-flex align-items-center beneficiaries-ira-enh__share-percentage">
              {{ totalShare }} %
              <app-svg-icon
                class="ml-2"
                [iconName]="currentShareState.icon"
                [iconColor]="currentShareState.color"
                size="1.5rem"
              ></app-svg-icon>
            </span>
          </div>
        </div>

        <ng-container *ngIf="isSpousalConsentNeeded">
          <div [formGroup]="spousalConsentForm" class="beneficiaries-ira-enh__spousal-consent">
            <h4>Spousal Consent Required</h4>
            <div class="mt-3">
              Your spouse needs to provide consent via email if you elect not to have them as your sole primary
              beneficiary.
            </div>

            <div class="row mx-0 my-4">
              <div class="form-group col-sm-6 pl-0 pr-3" [class.invalid-field]="firstNameField.hasError('*')">
                <label for="firstName" class="uk-label">Spouse First Name</label>
                <input name="firstName" class="uk-input" type="text" formControlName="firstName" />
                <div class="mt-1 text-error" ngxErrors="firstName" #firstNameField="ngxErrors">
                  <span
                    *ngFor="let error of errors.spousalConsent.firstName"
                    [ngxError]="error.name"
                    [innerText]="error.message"
                  ></span>
                </div>
              </div>

              <div class="form-group col-sm-6 pl-0 pr-3" [class.invalid-field]="lastNameField.hasError('*')">
                <label for="lastName" class="uk-label">Spouse Last Name</label>
                <input name="lastName" class="uk-input" type="text" formControlName="lastName" />
                <div class="mt-1 text-error" ngxErrors="lastName" #lastNameField="ngxErrors">
                  <span
                    *ngFor="let error of errors.spousalConsent.lastName"
                    [ngxError]="error.name"
                    [innerText]="error.message"
                  ></span>
                </div>
              </div>
            </div>

            <div class="form-group col-sm-6 pl-0">
              <label for="spouse-email" class="uk-label">Spouse Email</label>
              <input name="spouse-email" class="uk-input" type="text" formControlName="email" />
              <div class="mt-1 text-error" ngxErrors="email" #emailField="ngxErrors">
                <span
                  *ngFor="let error of errors.spousalConsent.email"
                  [ngxError]="error.name"
                  [innerText]="error.message"
                ></span>
              </div>
            </div>

            <button class="link" type="button" id="toggle-other-options" (click)="otherOptionsOpen = !otherOptionsOpen">
              Learn About Other Options
              <app-svg-icon
                [iconName]="otherOptionsOpen ? icons.up : icons.down"
                size="1em"
                [styles]="{ strokeWidth: 2 }"
              ></app-svg-icon>
            </button>

            <div class="mt-3" *ngIf="otherOptionsOpen">
              <p>
                Spousal consent is not required when adding contingent beneficiaries. You can add them to your account
                after you have updated your primary beneficiaries.
              </p>
              <p class="mt-4">
                If you no longer have a spouse, update your marital status before adding your beneficiaries.
                <button type="button" class="link" id="go-to-account-profile" (click)="goToPersonalInformation()">
                  Go To Account Profile
                </button>
              </p>
            </div>
          </div>
        </ng-container>

        <div *ngIf="!isSaving">
          <div class="d-flex justify-content-center">
            <button id="save-btn" class="btn-ok mt-3">Save</button>
          </div>

          <div
            class="text-error mt-3 text-center"
            *ngIf="
              submitAttempted &&
              ((beneficiariesFormArray.touched &&
                (beneficiariesFormArray.invalid || totalShare !== 100 || hasMultipleSpousesError)) ||
                (isSpousalConsentNeeded && spousalConsentForm.invalid))
            "
          >
            Please correct the errors highlighted above and try again
          </div>
        </div>
        <app-dot-loader *ngIf="isSaving">Saving</app-dot-loader>
      </form>

      <div class="beneficiaries-ira-enh-acknowledgment">
        Accounts held in the names of two or more owners will become payable to the designated beneficiaries only upon
        the death of all account owners. If there is more than one beneficiary, the funds in the account will be paid to
        the beneficiaries based on their designated percentage share. The interest of any beneficiary who predeceases
        the account owner(s) terminates completely, and the percentage share of any remaining beneficiaries will be
        increased on a pro rata basis. In the event that all named primary beneficiaries predecease the account
        owner(s), those named as contingent beneficiaries, if any, will become the primary beneficiaries. In the event
        that all named beneficiaries predecease the account owner(s), the estate of the account owner will be the
        beneficiary. You may change your designated beneficiaries at any time.
      </div>
    </ng-template>
  </div>
</div>
