import { ApiFileExtensions } from '../enums/apiFileExtensions.enum';
import { ApplicationTypes } from '../enums/applicationTypes.enum';
import { FileExtensions } from '../enums/fileExtensions.enum';
import { FileFormats } from './fileFormats';

export const buttonsInfo: FileFormats[] = [
  {
    displayText: 'XLSX - Excel',
    extension: FileExtensions.Xlsx,
    applicationType: ApplicationTypes.Xlsx,
    apiFileExtension: ApiFileExtensions.Xlsx,
    disabled: false,
  },
  {
    displayText: 'CSV - Comma-separated values',
    extension: FileExtensions.Csv,
    applicationType: ApplicationTypes.Csv,
    apiFileExtension: ApiFileExtensions.Csv,
    disabled: false,
  },
  {
    displayText: 'TXT - Text File',
    extension: FileExtensions.Txt,
    applicationType: ApplicationTypes.Txt,
    apiFileExtension: ApiFileExtensions.Txt,
    disabled: false,
  },

  {
    displayText: 'IIF - Intuit Interchange Format',
    extension: FileExtensions.Iif,
    applicationType: ApplicationTypes.Iif,
    apiFileExtension: ApiFileExtensions.Iif,
    disabled: false,
  },
  {
    displayText: 'QIF - Quicken Interchange Format',
    extension: FileExtensions.Qif,
    applicationType: ApplicationTypes.Qif,
    apiFileExtension: ApiFileExtensions.Qif,
    disabled: false,
  },
  {
    displayText: 'OFX - Open Financial Exchange',
    extension: FileExtensions.Ofx,
    applicationType: ApplicationTypes.Ofx,
    apiFileExtension: ApiFileExtensions.Ofx,
    disabled: false,
  },
  {
    displayText: 'QBO - Quickbooks Online',
    extension: FileExtensions.Qbo,
    applicationType: ApplicationTypes.Qbo,
    apiFileExtension: ApiFileExtensions.Qbo,
    disabled: false,
  },
  {
    displayText: 'QFX - Quicken Financial Exchange',
    extension: FileExtensions.Qfx,
    applicationType: ApplicationTypes.Qfx,
    apiFileExtension: ApiFileExtensions.Qfx,
    disabled: false,
  },
];
