import { UserProfileIcons } from '../enums';

export class IconDetails {
  initials: string;
  selectedIcon: UserProfileIcons | 'initials';
  selectedColor: string;

  constructor(data?: Partial<IconDetails>) {
    Object.assign(this, data);
  }
}
