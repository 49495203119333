import * as angular from 'angular';
import { IStateService } from 'angular-ui-router';
import * as moment from 'moment';
import { CachedAccountsService } from 'services/cached-accounts.service';
import { ModalService } from 'services/modal.service';
import { IBillPayService } from 'services/typings/IBillPayService';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { UserAccountType } from 'typings/app/bills/UserAccountType';

import { Inject } from '../../decorators/decorators';
import { BillPayRouterHelper } from '../bill-pay-router.helper';
import { BillPayHelper } from '../bill-pay.helper';
import { PayBillsHelper } from '../pay-bills.helper';
import { NextPaymentResponse } from '../typings/NextPaymentResponse';
import { RecurrentPaymentInfo } from '../typings/RecurrentPaymentInfo';
import { mapOption, ReminderOption } from '../typings/ReminderOption';
import { ScheduledPayment } from '../typings/ScheduledPayment';

@Inject(
  'modalService',
  '$state',
  'popups',
  'billPayRouterHelper',
  'loadUserProfileHelper',
  'cachedAccountsService',
  'dateRangePickerOptions',
  'dateHelper',
  'payBillsHelper',
  'billPayService',
  'billPayHelper'
)
export class UpdateRecurrentPayment {
  scheduledPayment: ScheduledPayment;
  email: string;
  accountsFrom: UserAccountType[];
  accountFrom: UserAccountType;
  datePickerOptions: any;
  datePicker: moment.Moment;
  reminderOptions: GenericOption[];
  selectedReminder: GenericOption;
  showReminderLabel = false;
  paymentForm: ng.IFormController;
  isBusy = false;
  frequencies: GenericOption[];
  selectedFrequency: GenericOption;
  paymentConditions: GenericOption[];
  paymentCondition: GenericOption;
  numberOfPayments: number;
  isValidReminder: boolean;
  loadingForm = true;
  minDate: moment.Moment;
  businessDaysToDeliver: number;
  isCheck: boolean;

  constructor(
    private modalService: ModalService,
    private readonly state: IStateService,
    private readonly popups: IPopups,
    private readonly routerHelper: BillPayRouterHelper,
    private readonly loadUserProfileHelper: ILoadUserProfileHelper,
    private readonly cachedAccountsService: CachedAccountsService,
    private readonly _dateRangePickerOptions: any,
    private readonly dateHelper: DateHelper,
    private readonly payBillsHelper: PayBillsHelper,
    private readonly billPayService: IBillPayService,
    private readonly billPayHelper: BillPayHelper
  ) {}
  $onInit(): void {
    this.scheduledPayment = this.routerHelper.getScheduledPayment();
    if (!this.scheduledPayment) this.state.go('udb.billPay.pay');

    this.setRemainingAsTotal();
    this.billPayService
      .getRecipient(this.scheduledPayment.payeeId)
      .then(r => {
        this.routerHelper.setRecipientDetails(r.data);
        this.datePicker = moment(r.data.nextAvailablePaymentDeliveryDate).clone();
        this.minDate = moment(r.data.nextAvailablePaymentDeliveryDate).clone();
        this.businessDaysToDeliver = r.data.businessDaysToDeliver;
        this.isCheck = r.data.paymentMethod !== 1;
      })
      .catch(response => {
        this.popups.showAlert('Error ocurred during payment update', response.data.message, 'error');
      })
      .finally(() => {
        this.addingDateInDP();
        this.scheduledPayment.processingDate = this.dateHelper
          .getProcessingDate(moment(this.scheduledPayment.deliveryDate), -this.businessDaysToDeliver)
          .toDate();
        this.loadingForm = false;
        this.datePickerOptions = this.setupDatePickerOptions();
        this.reminderOptions = this.getAllReminderOptions();
        this.getReminderFromPayment();
        this.reminderChanged();
        this.frequencies = this.billPayHelper.getFrequencies();
        this.selectedFrequency = this.frequencies.find(f => f.value === this.scheduledPayment.paymentFrequency);
        this.paymentConditions = this.getPaymentConditions();
        this.setupPaymentConditions();
      });

    const profile = this.loadUserProfileHelper.getUserProfile();
    this.email = profile ? profile.primaryEmail : '';
    this.accountsFrom = this.cachedAccountsService.paymentAccounts;
    this.accountFrom = this.accountsFrom.find(a => a.accountCode === this.scheduledPayment.accountCode);
  }
  isInvalidFrequency() {
    return !this.selectedFrequency;
  }
  changeFrequency() {
    if (this.selectedFrequency.value === 0) {
      const days = moment(this.scheduledPayment.processingDate).diff(moment(), 'days') + 1;

      switch (days) {
        case 0:
        case 1:
          this.reminderOptions = this.getAllReminderOptions().slice(0, 1);
          break;
        case 2:
          this.reminderOptions = this.getAllReminderOptions().slice(0, 2);
          break;
        case 3:
          this.reminderOptions = this.getAllReminderOptions().slice(0, 3);
          break;
        case 5:
        case 4:
          this.reminderOptions = this.getAllReminderOptions().slice(0, 4);
          break;
        case 6:
        case 7:
          this.reminderOptions = this.getAllReminderOptions().slice(0, 5);
          break;
        default:
          this.reminderOptions = days < 0 ? this.getAllReminderOptions().slice(0, 1) : this.getAllReminderOptions();
          break;
      }
    } else {
      this.reminderOptions = this.getAllReminderOptions();
      const daysDiff = this.datePicker.weekday() == 7 ? 2 : this.datePicker.weekday() == 6 ? 1 : 0;
      this.scheduledPayment.deliveryDate = this.datePicker.add(daysDiff, 'days').toDate();
    }
    this.selectedReminder = this.reminderOptions.find(o => o.value === this.selectedReminder.value);
    if (this.selectedReminder === undefined || this.selectedReminder === null) {
      this.selectedReminder = this.reminderOptions[this.reminderOptions.length - 1];
    }
    this.frequencyChanged();
  }
  onPaymentConditionChange(): void {
    if (this.paymentCondition.value === 0) {
      this.scheduledPayment.items = [true];
    } else {
      this.scheduledPayment.items = [this.numberOfPayments, this.numberOfPayments];
    }
  }
  getReminderOptionsOnDateChange(): boolean {
    const days = moment(this.scheduledPayment.processingDate).diff(moment(), 'days') + 1;

    switch (days) {
      case 0:
      case 1:
        return this.selectedReminder.value <= this.getAllReminderOptions()[0].value;
      case 2:
        return this.selectedReminder.value <= this.getAllReminderOptions()[1].value;
      case 3:
        return this.selectedReminder.value <= this.getAllReminderOptions()[2].value;
      case 5:
      case 4:
        return this.selectedReminder.value <= this.getAllReminderOptions()[3].value;
      case 6:
      case 7:
        return this.selectedReminder.value <= this.getAllReminderOptions()[4].value;
      default:
        return true;
    }
  }
  frequencyChanged() {
    this.scheduledPayment.paymentFrequency = this.selectedFrequency.value;
  }
  /**
   * Changes the value of the selected 'Payment Reminder' option
   */
  reminderChanged() {
    // payment has alert if something different to not send is selected.

    // payment has alert if something different to not send is selected.
    if (this.selectedReminder.value !== this.scheduledPayment.alert.daysBeforeProcessing) {
      this.scheduledPayment.alert.daysBeforeProcessingSpecified = true;
      this.scheduledPayment.alert.daysBeforeProcessing = this.selectedReminder.value;
      this.scheduledPayment.alert.onCompletion = true;
    }
    // if payment has alert show label at side.
    this.showReminderLabel = this.selectedReminder.value != ReminderOption.DontSend;
    this.isValidReminder = this.getReminderOptionsOnDateChange();
  }

  payFromChanged(): void {
    if (!this.accountFrom) {
      this.scheduledPayment.accountCode = null;

      return;
    }

    this.scheduledPayment.accountCode = this.accountFrom.accountCode;

    this.scheduledPayment.fromAccount = this.payBillsHelper.getAccountLabel(this.accountFrom);

    const accountType = this.accountFrom.accountType.trim().toLowerCase();
    if (this.accountTypes().indexOf(accountType) !== -1) {
      this.modalService.show(
        {},
        {
          icon: 'bofi-information',
          hasCancelButton: false,
          bodyText: `<h3>Note that only six transactions are allowed per month from this account.
                      Each transaction over six will occur a charge.</h3>`,
        }
      );
    }
  }
  cancelSetup(): void {
    this.modalService
      .show(
        {},
        {
          icon: 'bofi-warning',
          okText: 'Yes',
          hasCancelButton: true,
          cancelText: 'No',
          bodyText: `<h3>Are you sure you want to cancel this setup?</h3>`,
          hasRedirectLink: true,
        }
      )
      .then(() => {
        window.history.back();
      });
  }
  isInvalidAmount(): boolean {
    if (!this.scheduledPayment.amount || this.scheduledPayment.amount <= 0 || isNaN(this.scheduledPayment.amount)) {
      this.paymentForm.amount.$setValidity('required', false);
    }

    let result = this.paymentForm.amount.$invalid && (this.paymentForm.$submitted || this.paymentForm.amount.$touched);

    return result;
  }
  isInvalidStartOn(): boolean {
    return this.paymentForm.date.$invalid && (this.paymentForm.$submitted || this.paymentForm.date.$touched);
  }
  hasInsufficientFunds() {
    if (this.scheduledPayment.processingDate.toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10)) {
      const account = this.accountsFrom.find(a => this.scheduledPayment.accountCode === a.accountCode);

      return this.scheduledPayment.amount > account.balance;
    }

    return false;
  }
  isInvalidNumberOfPayments(): boolean {
    return !this.numberOfPayments;
  }
  updatePayment() {
    if (this.hasInsufficientFunds() || this.isInvalidNumberOfPayments()) return;
    this.onPaymentConditionChange();
    this.reminderChanged();
    if (this.selectedFrequency.value === 0) this.scheduledPayment.items = [1, 1];

    if (this.scheduledPayment.alert.daysBeforeDeliverySpecified) {
      const difference = moment().diff(moment(this.scheduledPayment.deliveryDate), 'days');
      this.scheduledPayment.alert.daysBeforeDelivery =
        this.scheduledPayment.alert.daysBeforeDelivery > difference
          ? difference
          : this.scheduledPayment.alert.daysBeforeDelivery;
    }
    const paymentInfo: RecurrentPaymentInfo = this.billPayHelper.syncPayment(this.scheduledPayment);
    this.isBusy = true;
    this.billPayService
      .updateScheduledPayment(paymentInfo)
      .then(r => {
        const nextPayment: NextPaymentResponse = r.data[0];
        this.routerHelper.setNextPayment(nextPayment);
        this.routerHelper.setScheduledPayment(this.scheduledPayment);
        this.state.go('udb.billPay.updatedConfirmation');
      })
      .catch(response => {
        this.popups.showAlert('Error ocurred during payment update', response.data.message, 'error');
      })
      .finally(() => {
        this.isBusy = false;
      });
  }

  private setRemainingAsTotal() {
    // Checks if series  has remaining payments
    if (this.scheduledPayment.items.length > 1) {
      // If so, sets the remaining payments as the current total of payments
      this.scheduledPayment.items[1] = this.scheduledPayment.items[0];
      // If remaining payments are equal to 1, changes the frequency to one time
      if (this.scheduledPayment.items[0] === 1) this.scheduledPayment.paymentFrequency = 0;
    }
  }
  private addingDateInDP() {
    if (moment(this.scheduledPayment.deliveryDate) > this.datePicker) {
      this.datePicker = moment(this.scheduledPayment.deliveryDate);
    } else {
      this.scheduledPayment.deliveryDate = this.datePicker.toDate();
    }
  }
  private setupPaymentConditions(): void {
    const value = this.scheduledPayment.items.length === 1 ? 0 : 1;
    this.paymentCondition = this.paymentConditions.find(c => c.value === value);
    this.numberOfPayments = this.paymentCondition.value === 1 ? this.scheduledPayment.items[0] : 2;
  }
  private getPaymentConditions(): GenericOption[] {
    return [
      {
        value: 0,
        label: 'I Choose to Stop',
      },
      {
        value: 1,
        label: 'A Number of Payments',
      },
    ];
  }
  private setupDatePickerOptions(): any {
    return angular.extend(angular.copy(this._dateRangePickerOptions), {
      minDate: this.minDate,
      maxDate: moment().add(1, 'years'),
      singleDatePicker: true,
      eventHandlers: {
        'apply.daterangepicker': () => {
          this.scheduledPayment.deliveryDate = this.datePicker.toDate();
          this.scheduledPayment.processingDate = this.dateHelper
            .getProcessingDate(moment(this.scheduledPayment.deliveryDate), -this.businessDaysToDeliver)
            .toDate();
          this.changeFrequency();
          this.isValidReminder = this.selectedFrequency ? this.getReminderOptionsOnDateChange() : this.isValidReminder;
        },
      },
      autoUpdateInput: true,
      isInvalidDate: (date: any) => {
        return !this.dateHelper.isValidDate(date); // Disable weekends and Holidays when paying a Loan
      },
    });
  }

  /**
   * Returns the complete list of available options for payment reminder
   */
  private getAllReminderOptions(): GenericOption[] {
    return [
      {
        value: ReminderOption.DontSend,
        label: mapOption(ReminderOption.DontSend),
      },
      {
        value: ReminderOption.OneDayBefore,
        label: mapOption(ReminderOption.OneDayBefore),
      },
      {
        value: ReminderOption.TwoDaysBefore,
        label: mapOption(ReminderOption.TwoDaysBefore),
      },
      {
        value: ReminderOption.ThreeDaysBefore,
        label: mapOption(ReminderOption.ThreeDaysBefore),
      },
      {
        value: ReminderOption.FiveDaysBefore,
        label: mapOption(ReminderOption.FiveDaysBefore),
      },
      {
        value: ReminderOption.OneWeekBefore,
        label: mapOption(ReminderOption.OneWeekBefore),
      },
    ];
  }
  private getReminderFromPayment() {
    if (!this.scheduledPayment.alert.daysBeforeProcessingSpecified) {
      this.selectedReminder = this.reminderOptions[0];
    } else {
      switch (this.scheduledPayment.alert.daysBeforeProcessing) {
        case 1:
          this.selectedReminder = this.reminderOptions[1] ? this.reminderOptions[1] : this.reminderOptions[0];
          break;
        case 2:
          this.selectedReminder = this.reminderOptions[2] ? this.reminderOptions[2] : this.reminderOptions[0];
          break;
        case 3:
          this.selectedReminder = this.reminderOptions[3] ? this.reminderOptions[3] : this.reminderOptions[0];
          break;
        case 4:
          this.selectedReminder = this.reminderOptions[4] ? this.reminderOptions[4] : this.reminderOptions[0];
          break;
        case 5:
          this.selectedReminder = this.reminderOptions[5] ? this.reminderOptions[5] : this.reminderOptions[0];
          break;
        case 7:
          this.selectedReminder = this.reminderOptions[6] ? this.reminderOptions[6] : this.reminderOptions[0];
        default:
          this.selectedReminder = this.reminderOptions[0];
          break;
      }
    }
  }

  private accountTypes(): string[] {
    return ['money market', 'savings'];
  }

  openDatePicker(): void {
    let datePickerInput: HTMLElement = document.getElementById('date') as HTMLElement;
    datePickerInput.click(); // This will open the date picker
  }
}
