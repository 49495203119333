import idleService from '@kurtz1993/idle-service';
import { ILocationService } from 'angular';

import { LiveChatSettings } from '@shared/models';

import { Inject } from '../decorators/decorators';

@Inject('$window', '$rootScope', '$location')
export class LiveChatService {
  settings: LiveChatSettings;

  constructor(
    private window: ng.IWindowService,
    private root: ng.IRootScopeService,
    private location: ILocationService
  ) {}

  generateScript(): void {
    if (this.settings.isUserAuthenticated) {
      this.root['profileInfo']
        ? this.addScriptToDom()
        : this.root.$on('profileInfoLoaded', () => this.addScriptToDom());
    } else {
      this.addScriptToDom();
    }
  }

  addScriptToDom() {
    const d = document;
    let s, t;
    const url = 'https://service.force.com';
    if (typeof this.settings == 'undefined') {
      console.log('LiveChat config not set.');

      return;
    }

    if (!this.window.embedded_svc) {
      s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.charset = 'utf-8';
      s.src = this.settings.eswScriptUrl;
      s.onload = () => {
        this.initESW(url);
      };
      t = d.getElementsByTagName('script')[0];
      t.parentNode.insertBefore(s, t);
    } else {
      this.initESW(url);
    }
  }
  initESW(baseUrl: string): void {
    this.setESWGeneralParameters();
    this.setESWChatButtonParameters();
    this.setESWPreChatParameters();
    this.setESWChatParameters();
    this.setESWGlanceEvents();
    this.setESWEvents();

    (window as any).embedded_svc.init(
      this.settings.salesforceUrl,
      this.settings.siteUrl,
      baseUrl,
      this.settings.orgId,
      this.settings.site,
      {
        baseLiveAgentContentURL: `${this.settings.liveAgentUrl}/content`,
        buttonId: this.settings.buttonId,
        baseLiveAgentURL: `${this.settings.liveAgentUrl}/chat`,
        deploymentId: this.settings.deploymentId,
        eswLiveAgentDevName: this.settings.devName,
        isOfflineSupportEnabled: false,
      }
    );

    // Workaround to hide the "Start a New Chat" button.
    setInterval(() => {
      this.updateCustomerUrl();
      const buttons = $('.dialog-button-0.dialogButton');
      if (buttons[0]) {
        const text = buttons[0].innerText.trim();
        if (text == 'Start a New Chat') {
          buttons.first().css('display', 'none');
        } else {
          buttons.first().css('display', 'inline');
        }
      }
    }, 1000);
  }

  private setESWGeneralParameters(): void {
    (window as any).embedded_svc.settings.displayHelpButton = true;
    (window as any).embedded_svc.settings.storageDomain = this.settings.domain;
    (window as any).embedded_svc.settings.language = 'en-US';
  }

  private setESWChatButtonParameters(): void {
    (window as any).embedded_svc.settings.defaultMinimizedText = 'Chat';
    (window as any).embedded_svc.settings.disabledMinimizedText = 'Chat is Closed';
    (window as any).embedded_svc.settings.onlineLoadingText = 'Loading';
  }

  private setESWPreChatParameters(): void {
    const profileInfo = this.root['profileInfo'];
    let cif = '';
    if (this.settings.isUserAuthenticated && profileInfo) {
      cif = profileInfo.cif;
      (window as any).embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: profileInfo.firstName,
        LastName: profileInfo.lastName,
        Email: profileInfo.primaryEmail,
        CIF: cif,
        Phone: this.getPhoneNumber(),
      };
    }

    (window as any).embedded_svc.settings.extraPrechatInfo = [
      {
        entityName: 'Lead',
        saveToTranscript: 'LeadId',
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: true,
            isExactMatch: true,
            fieldName: 'LastName',
            label: 'Last Name',
          },
          { doCreate: false, doFind: true, isExactMatch: true, fieldName: 'Email', label: 'Email' },
          { doCreate: false, doFind: true, isExactMatch: true, fieldName: 'Phone', label: 'Phone' },
        ],
      },
      {
        entityName: 'Contact',
        saveToTranscript: 'ContactId',
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: true,
            isExactMatch: true,
            fieldName: 'LastName',
            label: 'Last Name',
          },
          { doCreate: false, doFind: true, isExactMatch: true, fieldName: 'Email', label: 'Email' },
          { doCreate: false, doFind: true, isExactMatch: true, fieldName: 'Phone', label: 'Phone' },
        ],
      },
      {
        entityName: 'Account',
        saveToTranscript: 'AccountId',
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: true,
            isExactMatch: true,
            fieldName: 'CIF_SFDC2__c',
            label: 'CIF',
          },
        ],
      },
    ];
    (window as any).embedded_svc.settings.extraPrechatFormDetails = [
      { displayToAgent: true, label: 'CIF', value: cif },
      { displayToAgent: true, label: 'First Name', transcriptFields: ['ChatFirstName__c'] },
      { displayToAgent: true, label: 'Last Name', transcriptFields: ['ChatLastName__c'] },
      { displayToAgent: true, label: 'Email', transcriptFields: ['ChatEmail__c'] },
      { displayToAgent: true, label: 'Phone', transcriptFields: ['ChatPhone__c'] },
      {
        displayToAgent: true,
        label: 'How may I assist you today?',
        transcriptFields: ['Chat_Inquiry_Type__c'],
      },
      {
        displayToAgent: true,
        label: 'Customer URL',
        value: this.location.path(),
        transcriptFields: ['Customer_URL__c'],
      },
    ];

    (window as any).embedded_svc.settings.directToButtonRouting = (dataComponents: any) => {
      const inquiryRouting = this.settings.routingButtons;
      let buttonToRoute = this.settings.buttonId;
      const inquiryType = dataComponents[4].value;

      if (inquiryRouting.hasOwnProperty(inquiryType)) {
        buttonToRoute = inquiryRouting[dataComponents[4].value];
      }

      return buttonToRoute;
    };
  }

  private setESWChatParameters(): void {
    (window as any).embedded_svc.settings.widgetHeight = '580px';
    (window as any).embedded_svc.settings.widgetWidth = '320px';
  }

  private setESWGlanceEvents(): void {
    (window as any).embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    (window as any).embedded_svc.settings.entryFeature = 'LiveAgent';

    let visitor: any;
    function messageListener(e: any) {
      if (typeof e.data === 'string' && e.data.indexOf('startSession') == 0) {
        const key = e.data.split(':')[1];
        console.log('Chat started with key - key=' + key);
        (window as any).GLANCE.Cobrowse.Visitor.startSession(key);
      }
      if (e.data) {
        if (e.data.data) {
          // Chat started.
          if (e.data.data.event == 'chasitorChatEstablished') {
            if (e.data.data.chasitorData) {
              const visitorid = e.data.data.chasitorData.chatKey.replace(/-/g, '');
              visitor = new (window as any).GLANCE.Presence.Visitor({
                groupid: 20280,
                visitorid,
              });
              console.log('Chat started - visitorid=' + visitorid);
              visitor.connect();
            }
          }
          // Chat restarted.
          else if (e.data.method == 'liveagent.restored') {
            if (e.data.data.chasitorSessionData) {
              const visitorid = e.data.data.chasitorSessionData.chatKey.replace(/-/g, '');
              visitor = new (window as any).GLANCE.Presence.Visitor({
                groupid: 20280,
                visitorid,
              });
              console.log('Chat restarted - visitorid=' + visitorid);
              visitor.connect();
            }
          }
          // Chat timed out.
          else if (e.data.data.event == 'chasitorIdleTimeout') {
            if (visitor) {
              visitor.disconnect();
              console.log('Chat timed out');
              if ((window as any).GLANCE.Cobrowse.Visitor.inSession()) {
                (window as any).GLANCE.Cobrowse.Visitor.stopSession();
              }
            }
          }
          // Chat ended.
          else if (
            e.data.data.event == 'chasitorAgentChatEnded' ||
            e.data.data.event == 'chasitorChasitorChatEnded'
          ) {
            if (visitor) {
              visitor.disconnect();
              console.log('Chat ended');
              if ((window as any).GLANCE.Cobrowse.Visitor.inSession()) {
                (window as any).GLANCE.Cobrowse.Visitor.stopSession();
              }
            }
          }
        }
      }
    }
    if ((window as any).addEventListener) {
      (window as any).addEventListener('message', messageListener, false);
    } else {
      (window as any).attachEvent('onmessage', messageListener);
    }
  }

  private setESWEvents(): void {
    idleService.configure({
      listenFor: 'click keypress touchstart',
    });

    const stop = () => {
      if (this.settings.isUserAuthenticated) {
        idleService.stop();
        console.log('Event: idle.unwatch');
      }
    };
    const start = () => {
      if (this.settings.isUserAuthenticated) {
        idleService.start();
        console.log('Event: idle.watch');
      }
    };

    (window as any).embedded_svc.addEventHandler('onChatEstablished', () => {
      stop();
    });
    (window as any).embedded_svc.addEventHandler('onChatEndedByAgent', () => {
      start();
    });
    (window as any).embedded_svc.addEventHandler('onChatEndedByChasitor', () => {
      start();
    });
    (window as any).embedded_svc.addEventHandler('onIdleTimeoutOccurred', () => {
      start();
    });
  }

  private getPhoneNumber(): string {
    const profileInfo = this.root['profileInfo'];

    if (profileInfo.primaryPhone) return profileInfo.primaryPhone;

    for (const phone of [
      profileInfo.cellPhone1,
      profileInfo.homePhone,
      profileInfo.cellPhone2,
      profileInfo.workPhone,
    ]) {
      if (phone && phone.number) {
        return phone.number;
      }
    }

    return '000-000-0000';
  }

  private updateCustomerUrl(): void {
    if ((window as any).embedded_svc.settings.extraPrechatFormDetails) {
      (window as any).embedded_svc.settings.extraPrechatFormDetails.find(
        (setting: any) => (setting.label = 'Customer URL')
      ).value = this.location.path();
    }
  }
}
