import { Component, Input, OnInit } from '@angular/core';

import { Equity } from '@app/axos-trading/models';
import { TradingRoutingHelperService } from '@app/axos-trading/services';

@Component({
  selector: 'tr[app-research-row]',
  templateUrl: './research-row.component.html',
  styleUrls: ['./research-row.component.scss']
})
export class ResearchRowComponent implements OnInit {
  @Input() equity: Equity;
  @Input() accountId: number;

  constructor(private routeHelper: TradingRoutingHelperService) { }

  ngOnInit(): void {
  }

  goToPositionStockDetail() {
    this.routeHelper.goToPositionStockDetail(this.accountId, this.equity.symbol);
  }

}
