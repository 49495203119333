<div class="toolbar" [class.toolbar--primary-color]="isUfb">
  <div class="col-5 col-xl-2 toolbar__logo-container">
    <img
      (click)="goTo('auth.login')"
      class="toolbar__logo"
      [src]="currentLogo"
      [alt]="brandName"
      onload="removeAxosLoader && removeAxosLoader()"
    />
  </div>
</div>
