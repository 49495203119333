import { Component, Input } from '@angular/core';

import { LibrarySection, SecureFormsLibrary } from '@app/secure-forms/models';

@Component({
  selector: 'app-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
})
export class DocumentLibraryComponent {
  @Input() library: SecureFormsLibrary;
  @Input() sections: LibrarySection[];
}
