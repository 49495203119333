<div class="meatball-menu">
  <div>
    <button
      type="button"
      *ngIf="!isRecategorizeExpanded && !isDetailsExpanded && options.length"
      class="btn-link meatball-menu__trigger"
      aria-haspopup="true"
      aria-expanded="false"
      data-toggle="dropdown"
      (click)="moreOptionsClicked()"
    >
      <i aria-hidden="true">
        <app-svg-icon
          *ngIf="!isRecategorizeExpanded && !isDetailsExpanded"
          [iconName]="icons.Kebab"
          [styles]="{ height: '14px' }"
          [iconColor]="'#8F8F8F'"
          [ngClass]="'d-md-none'"
        ></app-svg-icon>
        <app-svg-icon
          *ngIf="!isRecategorizeExpanded && !isDetailsExpanded"
          [iconName]="icons.Meatball"
          [styles]="{ width: '14px' }"
          [iconColor]="'#0070D9'"
          [ngClass]="'d-none d-md-inline-flex'"
        ></app-svg-icon>
      </i>
      <span class="options-tooltip">More Options</span>
    </button>
    <ul
      class="dropdown-menu meatball-menu__list"
      uib-dropdown-menu
      role="menu"
      aria-labelledby="more options"
      *ngIf="!isRecategorizeExpanded && !isDetailsExpanded && options.length"
    >
      <li *ngFor="let opt of options" role="menu-item" [ngClass]="{ divider: opt.isDivider }">
        <a class="link dropdown-item-text" (click)="executeAction()" *ngIf="!opt.isDivider">
          <i [ngClass]="opt.icon" *ngIf="opt.icon"></i>
          <span>{{ opt.label }}</span>
        </a>
      </li>
    </ul>
    <button
      type="button"
      *ngIf="isDetailsExpanded || isRecategorizeExpanded"
      class="btn-link meatball-menu__trigger"
      (click)="collapseRow()"
    >
      <app-svg-icon
        [iconName]="icons.ChevronUp"
        [styles]="{ width: '1rem', height: '1rem', fill: '#2F5B88', position: 'relative' }"
      ></app-svg-icon>
    </button>
  </div>
</div>
