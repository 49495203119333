import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DebitCardStatus } from '../../enums';
import { DebitCard } from '../../models';

@Component({
  selector: 'app-debit-card-list',
  templateUrl: './debit-card-list.component.html',
  styleUrls: ['./debit-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebitCardListComponent {
  @Input() debitCards: DebitCard[] = [];
  @Input() selectedCardIndex = 0;

  @Output() pickCard = new EventEmitter<number>();

  frozenStatus = DebitCardStatus.Warm;
}
