import { createAction, props } from '@ngrx/store';

import { AddressAccountAssociation, CompanyDetail, Phone } from '@shared/models';

export const loadCompanyDetails = createAction(
  '[UserProfile] Load company Details',
  props<{ payload: Partial<CompanyDetail> }>()
);

export const updateCompanyEmail = createAction('[UserProfile] Update company Email', props<{ payload: string }>());

export const updateCompanyPhone = createAction(
  '[UserProfile] Update company Phone',
  props<{ payload: Partial<Phone> }>()
);

export const updateCompanyAddresses = createAction(
  '[UserProfile] Update company Addresses',
  props<{ payload: Partial<AddressAccountAssociation>[] }>()
);

export const updateCompanyFax = createAction('[UserProfile] Update company Fax', props<{ payload: Partial<Phone> }>());
