export type UserPreferenceState = {
  id: number;
  udbUserId: number;
  accountNumber: string;
  appPreferenceId: number;
  name: string;
  defaultValue: string;
  value: string;
};

type AppDefaultPreferences = {
  id: number;
  name: string;
  defaultValue: string;
};

export type UserPreferencesState = {
  defaultAppPreferences: AppDefaultPreferences[];
  userPreferences: UserPreferenceState[];
};

export const initialUserPreferencesState: UserPreferencesState = {
  userPreferences: [],
  defaultAppPreferences: [],
};
