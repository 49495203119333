import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  ALERTSNOTIFICATIONSSERVICE,
  alertsNotificationsServiceProvider,
} from '@app/alerts-notifications/ajs-upgraded-provider';
import { STATE, olbSettings } from '@core/tokens';

@Component({
  selector: 'app-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.scss'],
  providers: alertsNotificationsServiceProvider,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertHistoryComponent {
  public sortType: string = 'createdDate';
  public sortReverse: boolean = false;
  public brand: string;
  public typeOfAlert: any = 'All';
  public isLoading: boolean = true;
  public alertHistory: AlertHistory[] = [];
  public filteredAlerts: AlertHistory[] = [];
  public typeOfAlerts: AlertHistory[] = [
    {
      id: 'Low Balance',
      title: 'Low Balance',
    },
    {
      id: 'Documents',
      title: 'Documents',
    },
    {
      id: 'High Balance',
      title: 'High Balance',
    },
    {
      id: 'Large Deposit',
      title: 'Large Deposit',
    },
    {
      id: 'Large Withdrawal',
      title: 'Large Withdrawal',
    },
    {
      id: 'Balance Snapshot',
      title: 'Balance Snapshot',
    },
    {
      id: 'Check Cleared',
      title: 'Check Cleared',
    },
    {
      id: 'Loan Overdue',
      title: 'Loan Overdue',
    },
    {
      id: 'Loan Payment Due Date',
      title: 'Loan Payment Due',
    },
    {
      id: 'Maturity Date',
      title: 'CD Maturity',
    },
    {
      id: 'Statements',
      title: 'Statements',
    },
    {
      id: 'Tax Form',
      title: 'Tax Form',
    },
    {
      id: 'All',
      title: 'All Alert Types',
    },
  ];

  public currentDateStr: string;
  public previousDateStr: string;
  public days: string = '30';
  public displayCounter: number = 10;
  public showMobileFilter: boolean = false;
  public daysRange: string[] = ['30', '60', '90', '0'];
  private tempFilter: { typeOfAlert: string; days: string } = { typeOfAlert: '', days: '0' };

  constructor(
    @Inject(olbSettings) private env: OlbSettings,
    @Inject(STATE) private state: ng.ui.IStateService,
    @Inject(ALERTSNOTIFICATIONSSERVICE) private _alertsNotificationsService: IAlertsNotificationsService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.brand = String(this.env.brand);
    this.getHistory();

    this.typeOfAlerts.sort((a, b) => a.id.localeCompare(b.id));
  }

  public goBack() {
    this.state.go('udb.alertsNotifications.myAlerts');
  }

  public get mobileFilter(): boolean {
    return this.showMobileFilter;
  }

  public set mobileFilter(show: boolean) {
    this.showMobileFilter = show;

    if (this.showMobileFilter) {
      const { typeOfAlert, days } = this;
      this.tempFilter = { typeOfAlert, days };
    }
  }

  public applyMobileFilter(): void {
    this.showMobileFilter = false;
    this.getHistory();
  }

  public cancelMobileFilter(): void {
    const { typeOfAlert, days } = this.tempFilter;
    this.typeOfAlert = typeOfAlert;
    this.days = days;
    this.showMobileFilter = false;
  }

  //Function that will bring the Alert History Information
  public getAlertHistory(): void {
    this.isLoading = true;
    this._alertsNotificationsService
      .getAlertsHistory(this.previousDateStr, this.currentDateStr)
      .then(
        (response: OlbResponse<AlertHistory[]>) => {
          this.alertHistory = [];
          response.data.forEach((obj: AlertHistory) => {
            if (obj.alertType == 'Statement Notification Account') {
              obj.alertType = 'Statements';
            }
            if (obj.alertType == 'Taxform') {
              obj.alertType = 'Tax Form';
            }
            if (this.typeOfAlert === 'All' || obj.alertType === this.typeOfAlert) this.alertHistory.push(obj);
          });
          this.updateFilteredAlerts();
        },
        (_response: any) => {}
      )
      .finally(() => {
        this.isLoading = false;
        this.changeDetector.markForCheck();
      });
  }

  //Function that will calculate the dates based on the current day and range of days
  public getHistory(): void {
    this.displayCounter = 10;
    let currentDate = new Date();
    let dateFormat = 'yyyy-MM-dd';

    if (this.days !== '0') {
      this.currentDateStr = formatDate(new Date(), dateFormat, 'en-US');

      this.previousDateStr = formatDate(
        currentDate.setDate(currentDate.getDate() - Number(this.days)),
        dateFormat,
        'en-US'
      );
    } else {
      this.currentDateStr = formatDate(currentDate, dateFormat, 'en-US');
      this.previousDateStr = '';
    }
    this.getAlertHistory();
  }

  public seeMore(): void {
    this.displayCounter = this.displayCounter + 10;
    this.updateFilteredAlerts();
  }

  public showSeeMore(): Boolean {
    return this.alertHistory.length > this.displayCounter && this.filteredAlerts.length >= this.displayCounter;
  }

  public changeSortType(sortTypeParam: string): void {
    this.sortType = sortTypeParam;
    this.sortReverse = !this.sortReverse;
    this.updateFilteredAlerts();
  }

  public getNumericValue(thresholdValue: string): number {
    return parseFloat(thresholdValue);
  }

  private updateFilteredAlerts(): void {
    if (this.sortType === 'createdDate') {
      this.filteredAlerts = this.alertHistory
        .sort((a, b) => b.createdDate.localeCompare(a.createdDate) * (this.sortReverse ? -1 : 1))
        .slice(0, this.displayCounter);
    } else {
      this.filteredAlerts = this.alertHistory
        .sort((a, b) => b.alertType.localeCompare(a.alertType) * (this.sortReverse ? -1 : 1))
        .slice(0, this.displayCounter);
    }
  }
}
