import { Inject } from '../../../../decorators/decorators';
import { IBillPayService } from 'services/typings/IBillPayService';
import { IStateService } from 'angular-ui-router';
import { BillPayRouterHelper } from '../../../bill-pay-router.helper';
import { PaymentData } from 'bill-pay/typings/PaymentData';
import { UserAccountType } from 'typings/app/bills/UserAccountType';
import { RecurrentPaymentInfo } from 'bill-pay/typings/RecurrentPaymentInfo';
import * as moment from 'moment';

@Inject('$state', 'serviceHelper', 'billPayService', 'billPayRouterHelper')
export class PayReviewController {
  isBusy: boolean;
  headers: GenericOption[];
  detailsTooltip: string;
  payments: PaymentData[] = [];
  accountFrom: UserAccountType;
  severalPayments: boolean;
  electronicRecipientLabel = 'Payment will be withdrawn on';
  checkRecipientLabel = 'Your check will be mailed on';
  pays: RecurrentPaymentInfo[];
  totalAmount: number = 0;

  constructor(
    private readonly state: IStateService,
    private readonly serviceHelper: IServiceHelper,
    private readonly billPayService: IBillPayService,
    private readonly billPayRouterHelper: BillPayRouterHelper
  ) {}

  $onInit(): void {
    this.payments = this.billPayRouterHelper.getPaymentData();
    this.accountFrom = this.billPayRouterHelper.getAccountFrom();
    if (!this.payments) {
      this.state.go('udb.billPay.pay');
    } else {
      this.severalPayments = this.payments.length > 1;
      this.headers = [
        { value: 0, label: 'Recipient' },
        { value: 1, label: 'Amount To Pay' },
        { value: 2, label: 'Deliver By' },
        { value: 3, label: 'Payment Type' },
      ];
      this.payments.forEach(p => {
        this.totalAmount += parseFloat(p.amount.toString());
      });

      this.detailsTooltip = `This is our estimate of when your recipient will receive your payment. Your recipient may receive the payment before this date.`;
    }
  }

  submitPayments() {
    this.isBusy = true;
    this.pays = this.payments.map(
      (payment): RecurrentPaymentInfo => {
        const pay: RecurrentPaymentInfo = {
          payeeId: payment.recipient.payeeId,
          accountCode: this.accountFrom.accountCode,
          amount: payment.amount,
          daysBeforeDeliveryAlert: 0,
          daysBeforeDeliverySpecified: false,
          daysBeforeProcessingAlert: 0,
          daysBeforeProcessingSpecified: false,
          alertOnCompletion: false,
          repeatUntilCanceled: false,
          processingDate: moment(payment.processingDate).toDate(),
          deliveryDate: moment(payment.selectedDate).toDate(),
          numberOfPayments: 1,
          endOnNumberOfPayments: true,
          isSent: true,
          isRecurring: false,
          notifyMeNumberOfPayments: false,
          isExpedited: false,
          hasDuplicateWarning: payment.hasDuplicateWarning,
        };
        return pay;
      }
    );

    this.billPayService
      .addMultipleScheduledPayments(this.pays)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.state.go('udb.billPay.payConfirmation');
      })
      .catch(this.serviceHelper.errorHandler);
  }
}
