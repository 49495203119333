<div>
  <div>
    <div class="payment-calculator">
      <h3 class="payment-calculator-title">{{ info.title }}</h3>
      <p
        *ngFor="let item of info.items"
        class="payment-calculator-item"
        [class.text-bold]="item.bold"
      >
        {{ item.name }}&nbsp;
        <span
          class="text-bold"
          [class.bank-name]="item.name.includes('Account')"
        >
          {{ item.value }}
        </span>
      </p>
      <p class="payment-calculator-item">
        Loan Payoff Date&nbsp;
        <span class="flex-row--flex-end">
          <input
            [matDatepicker]="startDatePicker"
            [matDatepickerFilter]="dateAllowed"
            [min]="minDate"
            [max]="maxDate"
            (click)="startDatePicker.open()"
            type="text"
            name="payoffDate"
            id="payoffDate"
            aria-label="payoffDate"
            class="uk-input date-input date-calc"
            readonly
            required
            [(ngModel)]="payoffDate"
            (dateChange)="startDateChange()"
          />
          <button
            type="button"
            class="date-icon"
            (click)="startDatePicker.open()"
          >
            <app-svg-icon
              [iconName]="iconConfig.calendar.icon"
              [size]="iconConfig.calendar.size"
            ></app-svg-icon>
          </button>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </span>
      </p>
    </div>

    <div class="text-center mt-4 mb-4">
      <button
        id="calculate-loan"
        type="button"
        class="btn-ok"
        [disabled]="isCalculateDisabled || !isLoanPayOffFlagActive"
        (click)="calculateLoan()"
      >
        Calculate
      </button>
    </div>
  </div>
  <app-dot-loader [showWhen]="isLoading">Calculating</app-dot-loader>
  <div class="payment-calculator-line" *ngIf="payoffCalculated && !isLoading">
    <div class="calculated-items">
      <div class="calculated-items-data">
        <div class="calculated-items-extra">
          <div class="payment-calculator-result">
            <span class="payment-calculator-subtitle bold">
              Payoff Amount
            </span>
            <app-tooltip
              class="payment-calculator-tooltip"
              [content]="payoffDetail"
            ></app-tooltip>
          </div>
          <h3 class="payment-calculator-amount bold mb-4">
            {{ payoffCalculated.payoffAmount | currency }}
          </h3>
        </div>
        <button
          hidden
          *ngIf="isPayoffFlowActive"
          type="button"
          class="btn-ok center-block"
          [disabled]="isPaymentDisabled"
          (click)="payLoan()"
        >
          Payoff Loan
        </button>
      </div>
      <div class="calculated-items-actions">
        <button
          id="generate-quote"
          type="button"
          class="btn-ok"
          [disabled]="isGenerateQuoteDisabled"
          (click)="generateQuote()"
        >
          Generate Quote
        </button>
        <button
          type="button"
          class="button button--primary button--outline arrow--none"
          [disabled]="isSchedulePaymentDisabled"
          (click)="payLoan()"
        >
          Schedule Payment
        </button>
      </div>
    </div>
    <app-dot-loader [showWhen]="isLoadingPayLoan">Validating</app-dot-loader>
  </div>
</div>
