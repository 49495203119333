<div [formGroup]="parentForm" class="beneficiary-form pb-3">
  <div class="beneficiary-form--header">
    <h4>{{ labelPrefix }} Beneficiary #{{ index + 1 }}</h4>

    <button type="button" class="link font-weight-bold" (click)="remove.emit(index)">
      <app-svg-icon size="1em" [iconName]="icons.trash"></app-svg-icon>
      Remove
    </button>
  </div>

  <div
    class="form-group beneficiary-form--first-group col-12 col-sm-10"
    [class.invalid-field]="nameField.hasError('*')"
  >
    <label for="name-{{ index }}" class="uk-label">First and Last Name</label>
    <input name="name-{{ index }}" class="uk-input" type="text" formControlName="name" />

    <div id="name-errors-{{ index }}" class="mt-1 text-error" ngxErrors="name" #nameField="ngxErrors">
      <span *ngFor="let error of errors.name" [ngxError]="error.name" [innerText]="error.message"></span>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-5" [class.invalid-field]="identityField.hasError('*')">
      <label for="identity-{{ index }}" class="uk-label">Date of Birth</label>
      <input
        name="identity-{{ index }}"
        class="uk-input"
        type="text"
        formControlName="identity"
        placeholder="MM/DD/YYYY"
        mask="00/00/0000"
        [dropSpecialCharacters]="false"
      />

      <div id="identity-errors-{{ index }}" class="mt-1 text-error" ngxErrors="identity" #identityField="ngxErrors">
        <span *ngFor="let error of errors.identity" [ngxError]="error.name" [innerText]="error.message"></span>
      </div>
    </div>

    <div
      class="form-group col-sm-5 beneficiary-form--relationship-group"
      [class.invalid-field]="
        relationshipField.hasError('*') ||
        (hasMultipleSpouses && relationshipField.control.value.toLowerCase() === 'spouse')
      "
    >
      <label for="relationship-{{ index }}" class="uk-label">Relationship</label>
      <select
        id="relationship-{{ index }}"
        name="relationship-{{ index }}"
        formControlName="relationship"
        class="uk-input"
      >
        <option value=""></option>
        <option *ngFor="let relationshipItem of relationshipCatalog | keyvalue" [value]="relationshipItem.value">
          {{ relationshipItem.value }}
        </option>
      </select>
      <div
        id="relationship-errors-{{ index }}"
        class="mt-1 text-error"
        ngxErrors="relationship"
        #relationshipField="ngxErrors"
      >
        <span *ngFor="let error of errors.relationship" [ngxError]="error.name" [innerText]="error.message"></span>
        <span
          id="multiple-spouse-error-{{ index }}"
          *ngIf="hasMultipleSpouses && relationshipField.control.value.toLowerCase() === 'spouse'"
        >
          You can only have one spouse selected as a beneficiary.
        </span>
      </div>
    </div>

    <div class="form-group col-sm-2 beneficiary-form--share-group" [class.invalid-field]="percentField.hasError('*')">
      <label for="percent-{{ index }}" class="uk-label">Share</label>
      <div class="d-flex align-items-center">
        <input name="percent-{{ index }}" class="uk-input" formControlName="percent" type="text" mask="separator.0" />
        <span class="beneficiary-form--percent-sign ml-1">%</span>
      </div>

      <div id="percent-errors-{{ index }}" class="mt-1 text-error" ngxErrors="percent" #percentField="ngxErrors">
        <span *ngFor="let error of errors.percent" [ngxError]="error.name" [innerText]="error.message"></span>
      </div>
    </div>
  </div>
</div>
