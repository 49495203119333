<div class="side-menu-item">
  <div class="side-menu-item__main" [class.isSubMenu]="isSubMenu">
    <div class="side-menu-item__main-text">
      <app-svg-icon
        class="side-menu-item__icon"
        *ngIf="menuItem.iconName"
        [iconName]="menuItem.iconName"
        iconColor="#fff"
        width="1.6em"
        height="1.6em"
      ></app-svg-icon>
      <button
        (click)="triggerMenuAction(menuItem, $event)"
        [innerText]="menuItem.label"
        class="side-menu-item__label"
      ></button>
    </div>
    <button
      [attr.aria-expanded]="expanded"
      class="side-menu-item__chevron"
      *ngIf="menuItem.subItems"
      (click)="toggleExpanded()"
    >
      <app-svg-icon
        [iconName]="expanded ? contractIcon : expandIcon"
        iconColor="#fff"
        width="1em"
        height="1em"
      ></app-svg-icon>
    </button>
  </div>

  <div class="side-menu-item__sub-menu" *ngIf="expanded">
    <app-side-menu-item
      class="side-menu-item__sub-menu-items"
      *ngFor="let subMenuItem of menuItem.subItems"
      [menuItem]="subMenuItem"
      [isSubMenu]="true"
      (toggleMainMenu)="toggleMain()"
    ></app-side-menu-item>
  </div>
</div>
