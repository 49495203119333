import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { OlbSettings } from '@core/models';
import { BaseService } from '@core/services/base.service';
import { olbSettings } from '@core/tokens';
import { WebApiResponse } from '@shared/models';

import { CaseData, Category } from '../models';

@Injectable()
export class SupportService extends BaseService {
  constructor(@Inject(olbSettings) settings: OlbSettings, http: HttpClient) {
    super(http, settings, 'messages');
  }

  /**
   * Get the support categories.
   * @returns An observable containing a list of the available support categories.
   */
  getCategories(): WebApiResponse<Category[]> {
    return this.get('questions');
  }

  /**
   * Gets the support subcategories corresponding to the given category ID.
   * @param categoryId Id of the parent category.
   * @returns An observable containing the subcategories.
   */
  getSubCategories(categoryId: number): WebApiResponse<Category[]> {
    return this.get('questions', { parentId: categoryId.toString() });
  }

  /**
   * Creates a new case for support.
   * @param caseData Information to open a case.
   * @returns An observable containing the result of the operation.
   */
  createCase(caseData: CaseData): WebApiResponse<string> {
    return this.post('', { caseCreationDto: caseData });
  }
}
