import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function textOnlyValidatorFn(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    const TEXT_ONLY_REGEX = /^[a-zA-Z -]*$/;
    if (TEXT_ONLY_REGEX.test(controlValue)) return null;

    return { textOnlyValidation: true };
  };
}
