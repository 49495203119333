import { of } from 'rxjs';

import { Inject } from '../decorators/decorators';
import { SettingsService } from './settings.service';
import { WiseBanyanService } from './wise-banyan.service';

@Inject('wiseBanyanService', 'settingsService', 'env')
export class AxosInvestUrlHelper {
  private axosInvestUrl: string;

  constructor(
    private readonly wiseBanyanService: WiseBanyanService,
    private settingsService: SettingsService,
    env: OlbSettings
  ) {
    this.axosInvestUrl = env.wiseBanyanUrl;
  }

  async getOverviewUrl(): Promise<string> {
    return this.createAxosInvestUrl('dashboard/overview');
  }

  async getCreateMilestoneUrl(): Promise<string> {
    return this.createAxosInvestUrl('dashboard/milestone/create');
  }

  async getEditMilestoneUrl(id: string): Promise<string> {
    return this.createAxosInvestUrl(`dashboard/milestone/${id}`);
  }

  async getManageWalletUrl(): Promise<string> {
    return this.createAxosInvestUrl('dashboard/formulas');
  }

  async getTransactionsUrl(): Promise<string> {
    return this.createAxosInvestUrl('dashboard/funding/activity');
  }

  async getDocumentsUrl(): Promise<string> {
    return this.createAxosInvestUrl('dashboard/profile/documents/');
  }

  async getCreateFundsUrl(): Promise<string> {
    const token = await this.getUserToken();
    const url = `${this.axosInvestUrl}/dashboard/funding/transfer-funds/select?referrer=axos_olb&referrer_token=${token}`;

    return of(url).toPromise();
  }

  async getUserToken(): Promise<string> {
    if (!this.settingsService.axosInvestSSOToken) {
      const result = await this.wiseBanyanService.getSsoToken();
      this.settingsService.axosInvestSSOToken = result.data;
    }

    return of(this.settingsService.axosInvestSSOToken).toPromise();
  }

  async createAxosInvestUrl(path: string): Promise<string> {
    const token = await this.getUserToken();
    const url = `${this.axosInvestUrl}/${path}?referrer=axos_olb&referrer_token=${token}`;

    return of(url).toPromise();
  }
}
