import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { AvailableCashTileState } from '../../core/store/types';
import { AvailableCashFacade } from '../../facade';
import { Uk2Tier1AlertsEnum, Uk2Tier2ColoredWithAccentEnum, Uk2TooltipPlacementEnum } from '@axos/uikit-v2-lib';
import { TOOLTIP_CONFIG } from '../../core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@shared/constants';
import { TradingRoutingHelperService } from '@app/axos-trading/services';
import { NO_CONTRIBUTION_ACCOUNT_TYPE_CODES } from '@app/Areas/AAS/aas-shared/constants';

@Component({
  selector: 'app-available-cash-tile',
  templateUrl: './available-cash-tile.component.html',
  styleUrls: ['./available-cash-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableCashTileComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  hideMoveMoneyButton = false;
  availableCashTileState$: Observable<AvailableCashTileState>;
  availableCashTileState: AvailableCashTileState;

  Uk2Tier1AlertsEnum = Uk2Tier1AlertsEnum;
  Uk2Tier2ColoredWithAccentEnum = Uk2Tier2ColoredWithAccentEnum;

  tooltipConfig = TOOLTIP_CONFIG;

  destroy$ = new Subject<void>();

  displayButtonText: string;
  noContributionAccountTypeCodes = [
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountType403,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountType403R,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountType4PS,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeK,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeMPP,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeOQP,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypePSP,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeQP,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeRoth401,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSEP,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSK,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSKR,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSUB,
    NO_CONTRIBUTION_ACCOUNT_TYPE_CODES.accountTypeSimpleIRA,
  ];

  constructor(
    private availableCashFacade: AvailableCashFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private routeHelper: TradingRoutingHelperService
  ) {}

  ngOnInit(): void {
    this.setAvailableCashTileState();
    this.setBreakpointConfig();
    this.setDisplayButtonText();
  }

  setAvailableCashTileState() {
    this.availableCashTileState$ = this.availableCashFacade.availableCashTileState$.pipe(
      filter(state => state !== undefined),
      map(state => {
        if (state && state.availableCash && state.availableCash < 0) {
          state.availableCash = 0;
        }
        this.changeDetectorRef.markForCheck();
        return state;
      }),
      tap(state => {
        this.hideMoveMoneyButton = !!state.dateCloseInitiated;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  setDisplayButtonText() {
    this.availableCashTileState$.pipe(take(1)).subscribe(state => {
      if (state && state.accountTypeCode) {
        this.displayButtonText = this.validateAccountTypes(state.accountTypeCode) ? 'Move Money' : null;
      }
      this.isLoading = false;
      this.availableCashTileState = state;
      this.changeDetectorRef.markForCheck();
    });
  }

  setBreakpointConfig() {
    this.breakpointObserver
      .observe(['(max-width: ' + BREAKPOINTS.md + 'px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result.matches) {
          this.handleTooltipConfig(true, Uk2TooltipPlacementEnum.topStart, -24);
        } else {
          this.handleTooltipConfig(false, Uk2TooltipPlacementEnum.left, 0);
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToTransferFunds() {
    this.routeHelper.goToTransferFunds();
  }

  private handleTooltipConfig(
    displayCloseButton: boolean,
    placement: Uk2TooltipPlacementEnum,
    arrowOffset: number
  ): void {
    this.tooltipConfig = {
      ...this.tooltipConfig,
      displayCloseButton: displayCloseButton,
      placement: placement,
      arrowOffset: arrowOffset,
    };
  }

  private validateAccountTypes(accountTypeCode: string) {
    return !this.noContributionAccountTypeCodes.includes(accountTypeCode);
  }
}
