<div class="recipient-information" [ngClass]="{ isInteralTabSelected: isToggled() }">
  <div class="row basic-info">
    <div class="col-md-5 toggleTabs" (click)="toggleTabs()"></div>
    <div class="col-md-3 pointer">
      <p class="title margin-top" [ngClass]="{ 'eBills-error': recipient.eBillsStatus > 2 }">
        {{ recipient.nickName || recipient.displayName }}
        <img
          [src]="'/img/icons/billPay/favorite-icon.svg'"
          width="12"
          alt="Favorite Recipient"
          aria-hidden="true"
          *ngIf="isFavorite"
          class="hide-md hide-lg hide-xl"
        />
      </p>
      <p class="details hide--sm hide--xs hide--cs" *ngIf="isFavorite">
        <img [src]="'/img/icons/billPay/favorite-icon.svg'" width="12" alt="Favorite Recipient" aria-hidden="true" />
        Favorite Recipient
      </p>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-7 pointer">
      <p *ngIf="scheduledPayments[0]" class="details margin-top">
        Scheduled:
        {{ (scheduledPayments[0].amount | currency) + ' on ' + (scheduledPayments[0].deliveryDate | date: 'M/dd') }}
      </p>
      <br *ngIf="!scheduledPayments[0] && paymentsHistory[0]" />
      <br *ngIf="!scheduledPayments[0] && paymentsHistory[0]" />
      <p *ngIf="paymentsHistory[0]" class="details">
        Last Paid:
        {{ (paymentsHistory[0].amount | currency) + ' on ' + (paymentsHistory[0].deliveryDate | date: 'M/dd') }}
      </p>
    </div>
    <div class="col-md-3 hide--sm hide--xs hide--cs">
      <label class="uk-label">Amount to Pay</label>
      <div uib-dropdown class="amount-input">
        <app-dropdown-amount
          [recipient]="recipient"
          [amount]="amount"
          [amountOpt]="amountOpt"
          (amntChange)="amountChange($event)"
          (setAmount)="setAmmount($event)"
        ></app-dropdown-amount>
      </div>
    </div>
    <div class="col-md-3 date-group hide--sm hide--xs hide--cs">
      <i class="bofi-datepicker date-group__icon"></i>
      <label class="uk-label" for="startDate">Deliver By</label>
      <input
        class="uk-input date-group__input"
        aria-label="Deliver By"
        appDateRangePicker
        type="text"
        [options]="datePickerOptions"
        [ngModel]="selectedDate | date: 'MM/dd/YY'"
        (dateApplied)="dateChangeEvent($event)"
        readonly
      />
    </div>
    <div class="col-sm-6 d-lg-none text-right">
      <button type="button" class="btn-ok pay-btn" (click)="goToScheduledPayment(recipient)">
        <span>Pay</span>
      </button>
    </div>
  </div>
  <div class="row custom-tabs">
    <div class="col-md-8">
      <i class="fa arrow" [ngClass]="isToggled() ? 'fa-angle-up' : 'fa-angle-down'" (click)="toggleTabs()"></i>
      <a class="tab link" type="button" (click)="tabSelected = 1" uib-btn-radio="1" uncheckable>
        Details
      </a>
      <span class="spacer"></span>
      <a class="tab link" type="button" (click)="tabSelected = 2" uib-btn-radio="2" uncheckable>
        Payments
      </a>
      <!--In the next two tags, change the *ngIf to be less than 2 as to hide the eBills error status-->
      <span class="spacer" *ngIf="recipient.eBillsStatus !== 2"></span>
      <a
        class="tab"
        type="button"
        (click)="tabSelected = 3"
        uib-btn-radio="3"
        uncheckable
        *ngIf="recipient.eBillsStatus !== 2"
        [ngClass]="{ 'eBills-error': recipient.eBillsStatus > 2 }"
      >
        eBills
        <span class="flag-status" *ngIf="recipient.eBillsStatus === 0">
          <i class="dot green"></i>
          On
        </span>
        <span class="flag-status" *ngIf="recipient.eBillsStatus === 1">
          <i class="dot red"></i>
          Off
        </span>
        <span class="eBills-error" *ngIf="recipient.eBillsStatus > 2">
          <i class="bofi-warning"></i>
          <span class="hide-cs">Action Needed</span>
        </span>
      </a>
    </div>
    <div class="col-md-4">
      <span *ngIf="amount > 0" class="text-right message right">
        {{ processingPaymentLabel }} {{ processingDate | date: 'MM/dd/yyyy' }}
      </span>
    </div>
  </div>
  <div class="row custom-tabs-content">
    <div class="col-md-12">
      <div [ngSwitch]="tabSelected">
        <div *ngSwitchCase="1">
          <app-details-tab [recipient]="recipient"></app-details-tab>
        </div>
        <div *ngSwitchCase="2">
          <app-payments-tab
            [recipient]="recipient"
            [paymentsHistory]="paymentsHistory"
            [scheduledPayments]="scheduledPayments"
            (loadScheduledPayments)="_loadScheduledPayments()"
          ></app-payments-tab>
        </div>
        <div *ngSwitchCase="3">
          <app-estatements-tab
            [recipient]="recipient"
            [amountOpt]="amountOpt"
            (setAmountOpt)="setAmountOpt()"
          ></app-estatements-tab>
        </div>
      </div>
    </div>
  </div>
</div>
