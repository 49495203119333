import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AccountPeopleTabState, PrimaryBeneficiary } from '../../../../core';
import { PeopleFacade } from '../../../../facade/people.facade';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PdpFacade } from '@app/Areas/AAS/features/product-details-page/facade/pdp.facade';
import { ALLOWED_ACCOUNTS_TYPES } from '../constants';

@Component({
  selector: 'app-people-tab',
  templateUrl: './people-tab.component.html',
  styleUrls: ['./people-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleTabComponent implements OnInit, OnDestroy {
  ShowBeneficiary: boolean = false;
  isRetirementAccount: boolean = false;
  primaryBeneficiaries: Array<PrimaryBeneficiary>;
  peopleTabAccountState$: AccountPeopleTabState;
  readonly destroy$ = new Subject<void>();

  constructor(
    private pdpFacade: PdpFacade,
    private peopleFacade: PeopleFacade,
    private changeDeterctorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.pdpFacade.selectedAasAccount$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state != undefined)
      )
      .subscribe(pdpSelectedAasAccountState => {
        const selectedAccountType = pdpSelectedAasAccountState.type;
        const allowedAccountTypes = ALLOWED_ACCOUNTS_TYPES;
        this.ShowBeneficiary = !allowedAccountTypes.includes(
          selectedAccountType
        );

        this.isRetirementAccount = pdpSelectedAasAccountState.isRetirement;
        this.changeDeterctorRef.markForCheck();
      });

    this.peopleFacade.accountPeopleTabState$
      .pipe(
        takeUntil(this.destroy$),
        filter(state => state != undefined)
      )
      .subscribe(peopleTabState => {
        this.peopleTabAccountState$ = peopleTabState;
        this.primaryBeneficiaries = this.peopleTabAccountState$.accountPeople?.primaryBeneficiaries;
        this.changeDeterctorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
