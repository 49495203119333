import { ScheduledPayment } from '../../../typings/app/bills/ScheduledPayment';
import { Inject } from '../../../decorators/decorators';
import * as moment from 'moment';

@Inject('$state')
export class TilePmntDetailController {
  payments: ScheduledPayment[];
  removedPayments: ScheduledPayment[];
  sum: number = 0;
  showSum: boolean = false;
  constructor(private state: ng.ui.IStateService) {}

  /** Listen for any change in the bindings
   * @param changes The object containing the bindings with their previous value and current value
   */
  $onChanges(_changes: any): void {
    this._listScheduledPayments();
  }

  /**
   * Transforms the date the a human readable date
   * @param date The date for be tranformed
   */
  toHumanDate(date: Date): string {
    return moment(date).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      sameElse: 'MMM D',
    });
  }

  redirectToBillPay() {
    this.state.go('udb.billPay.activity');
  }

  /** Handles the payments list in order to limit to 3 in case there are more than 4 scheduled payments */
  private _listScheduledPayments(): void {
    this.removedPayments = [];
    this.sum = 0;
    this.showSum = false;

    if (this.payments.length > 4) {
      this.removedPayments = this.payments.splice(3);
      this.sum = this.removedPayments.reduce((sum, pmnt) => sum + pmnt.amount, 0);
      this.showSum = true;
    }
  }
}
