import { UserAction, UserActionField } from '@legacy/typings/app/UserAction';
import { SblocService } from '../services/sbloc.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RegisterUserAction {
  constructor(private sblocService: SblocService) {}

  registerSeeingPreOffer(): Observable<any> {
    let userActionFields: UserActionField[] = [];
    userActionFields.push(new UserActionField('', '', ''));

    return this.sblocService.logUserAction(new UserAction('SblocSeePreApprovedOffer', userActionFields));
  }

  registerReviewedOffer(amount: string, apr: string): Observable<any> {
    let userActionFields: UserActionField[] = [];

    userActionFields.push(new UserActionField('Amount', '', amount));
    userActionFields.push(new UserActionField('APR', '', apr));

    return this.sblocService.logUserAction(new UserAction('SblocReviewedOffer', userActionFields));
  }

  registerEmailMeDocuments(apr: string, amount: string, principalEmail: string, jointEmail: string) {
    let userActionFields: UserActionField[] = [];
    userActionFields.push(new UserActionField('APR', '', apr));
    userActionFields.push(new UserActionField('Amount', '', amount));
    userActionFields.push(new UserActionField('Principal Email', '', principalEmail));
    userActionFields.push(new UserActionField('Joint Email', '', jointEmail));

    return this.sblocService.logUserAction(new UserAction('SblocEmailMeDocuments', userActionFields));
  }

  registerEmailMeEnrollmentDocuments(apr: string, amount: string, principalEmail: string, jointEmail: string) {
    let userActionFields: UserActionField[] = [];
    userActionFields.push(new UserActionField('APR', '', apr));
    userActionFields.push(new UserActionField('Amount', '', amount));
    userActionFields.push(new UserActionField('Principal Email', '', principalEmail));
    userActionFields.push(new UserActionField('Joint Email', '', jointEmail));

    return this.sblocService.logUserAction(new UserAction('SblocEmailMeEnrollmentDocuments', userActionFields));
  }

  registerSelectedNotRightNow() {
    let userActionFields: UserActionField[] = [];
    userActionFields.push(new UserActionField('', '', ''));

    return this.sblocService.logUserAction(new UserAction('SblocNotRightNow', userActionFields));
  }
}
