import { FeatureFlagService } from '@legacy/services/feature-flag.service';

export function featureFlagServiceFactory($injector: any) {
  return $injector.get('featureFlagService');
}

export const featureFlagServiceProvider = {
  provide: FeatureFlagService,
  useFactory: featureFlagServiceFactory,
  deps: ['$injector'],
};
