<div class="list-row" *ngIf="value">
  <span class="list-row__title">{{ title }}</span>
  <ng-container [ngSwitch]="type">
    <span *ngSwitchCase="aasDataTileListRowTypeEnum.Text" class="list-row__value">{{ value }}</span>
    <a
      *ngSwitchCase="aasDataTileListRowTypeEnum.MobilePhone"
      href="tel:{{ value.toString() }}"
      class="list-row__value list-row__value--phone--mobile"
    >
      {{ value.toString() | phone }}
    </a>
    <span
      *ngSwitchCase="aasDataTileListRowTypeEnum.MobilePhone"
      class="list-row__value list-row__value--phone--desktop"
    >
      {{ value.toString() | phone }}
    </span>
    <a
      *ngSwitchCase="aasDataTileListRowTypeEnum.Email"
      href="mailto:{{ value.toString() }}"
      class="list-row__value list-row__value--email"
    >
      {{ value.toString() }}
    </a>
    <ng-container *ngSwitchCase="aasDataTileListRowTypeEnum.Address">
      <div class="value-group">
        <span class="list-row__value" *ngFor="let address of addressList">{{ address }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="list-row__divider"></div>
