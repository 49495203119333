<form
  [ngClass]="{
    'edit-external-mobile-transfer-modal': dialogContext.isMobile,
    'edit-external-transfer-modal': !dialogContext.isMobile
  }"
  class="mdc-dialog__surface mdc-dialog"
  name="editExternalTransferForm"
  novalidate
>
  <!-- HEADER -->
  <div class="modal-header hide-cs legacy-modal-header">
    <div class="frow middle center text-center">
      <p class="modal-title legacy-header-title">Edit scheduled transfer</p>
    </div>
  </div>
  <!-- CONTENT -->
  <div class="modal-body legacy-modal-body">
    <h3 class="text-center title">
      Edit External Transfer from {{ transferToEdit.fromNickName }} to Savings
      {{ transferToEdit.toNickName }}
    </h3>
    <div class="container-fields">
      <div class="text-left">
        <label class="uk-label">Amount</label>
        <axos-legacy-input-formatted-amount
          [(amount)]="transferToEdit.amount"
          [hasError]="!!errorMsg"
          (change)="validateAmount()"
          placeholder="Amount"
          name="amount"
        ></axos-legacy-input-formatted-amount>
        <span class="text--error" *ngIf="errorMsg">{{ errorMsg }}</span>
      </div>
      <div class="field-separator"></div>
      <div class="text-left container-date daterangePickerContainer">
        <label class="uk-label">Next Transfer Date</label>
        <i class="bofi-datepicker icon-input--inner" (click)="showCalendar()"></i>
        <input
          appDateRangePicker
          type="text"
          name="date"
          id="date"
          aria-label="date"
          class="uk-input icon-input--inner"
          [options]="datePickerOptions"
          [ngModel]="dateHandler.dateWithFormat"
          (calendarShown)="setPropertiesForCalendar()"
          (dateApplied)="transformDateEvent($event)"
          readonly
        />
        <span class="error-message uk-label margin-top" *ngIf="!isTodayValidForExternal">
          *Transfers to an external account are scheduled the next business day if submitted before 1:45 p.m. Pacific
          Time. Any requests after this cut-off time will be scheduled for the second business day.
        </span>
      </div>
      <div class="field-separator"></div>
      <div class="text-left">
        <label for="frequency" class="uk-label label-form-upper">Frequency</label>
        <select
          name="frequency"
          class="uk-input frequency-select"
          [(ngModel)]="transferToEdit.frequency"
          [disabled]="!isSeries"
          id="frequency"
        >
          <option *ngFor="let frequency of frequencies" [value]="frequency.value">
            {{ frequency.label }}
          </option>
        </select>
      </div>
      <div class="field-separator"></div>
    </div>
  </div>
  <!-- FOOTER -->
  <div class="modal-footer">
    <div class="frow no-wrap center footer-external">
      <div *ngIf="!isLoadingSetup">
        <button
          type="button"
          class="uk-btn lg outline primary action-button"
          (click)="closeModal()"
          [disabled]="isLoadingSetup"
        >
          Cancel
        </button>
        <button
          type="button"
          class="uk-btn lg solid secondary action-button"
          (click)="saveTransfer()"
          [disabled]="isLoadingSetup"
        >
          Save
        </button>
      </div>
      <app-dot-loader [showWhen]="isLoadingSetup"></app-dot-loader>
    </div>
  </div>
</form>
