import {
  AppDefaultPreferences,
  UserPreferenceState,
  UserPreferencesState,
} from '@app/Areas/AAS/aas-shared/features/user-preferences';
import { ModelSortDirectionName, ModelSortTypeName, ModelSortTypes, ModelsSortDirection } from '../../../const';
import { TModelSortTypeValues, TSortDirectionTypeValues } from '../../../types';
import { queryPreference } from '../query-preference';
import { lowercaseFirstLetter } from '../lowercase-first-letter';
import { getKeyByValueFromModel } from '../get-key-by-value-from-model';
import { Model } from '../../types';
/**
 * returns context object containing:
 * - `sortByKey` - key to sort by current model list
 * - `typePreference` - {@link TModelSortTypeValues}
 * - `directionPreference` - {@link directionPreference}
 * @param accountNumber string
 * @param preferences {@link UserPreferencesState}
 */
export function findModelSortTypePreference(
  accountNumber: string,
  preferences: UserPreferencesState | undefined
): {
  typePreference: TModelSortTypeValues;
  directionPreference: TSortDirectionTypeValues;
  sortByKey: keyof Model;
} {
  if (!preferences) {
    return {
      typePreference: ModelSortTypes.PortfolioPercent,
      directionPreference: ModelsSortDirection.Descending,
      sortByKey: 'portfolioPercent',
    };
  }
  const queriedTypePreference = queryPreference(accountNumber, preferences, ModelSortTypeName);
  const queriedDirectionPreference = queryPreference(accountNumber, preferences, ModelSortDirectionName);

  const typePreference = castPreferenceValue<TModelSortTypeValues>(
    ModelSortTypes.PortfolioPercent,
    queriedTypePreference
  );

  const directionPreference = castPreferenceValue<TSortDirectionTypeValues>(
    ModelsSortDirection.Descending,
    queriedDirectionPreference
  );
  const sortByKey = getKeyByValueFromModel(typePreference, ModelSortTypes, lowercaseFirstLetter, 'portfolioPercent');
  return {
    typePreference,
    directionPreference,
    sortByKey,
  };
}

function castPreferenceValue<T>(
  defaultValue: T,
  queriedItem: undefined | AppDefaultPreferences | UserPreferenceState
): T {
  const hasPreference = (queriedItem as UserPreferenceState)?.value ?? queriedItem?.defaultValue;
  return hasPreference ? (parseInt(hasPreference) as T) : defaultValue;
}
