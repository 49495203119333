import { downgradeInjectable } from '@angular/upgrade/static';

import * as angular from 'angular';
import 'angular-jwt';
import { BillPayHelper } from 'bill-pay/bill-pay.helper';
import { CustomerLimitsHelper } from 'services/customer-limits.helper';
import { DashboardBalanceService } from 'services/dashboard-balance.service';
import { FeatureFlagService } from 'services/feature-flag.service';

import {
  AuthService,
  BusinessService,
  DialogService,
  LogService,
  MultifactorService as NewMultifactorService,
  OlbEventService,
  IPayService,
  MessageService,
  AxosAdvisoryService,
} from '@core/services';

import { MessagesSharedService } from '../messages/messages.shared.service';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';
import { UserSubtypeHelper } from '@legacy/shared/helpers/user-subtype.helper';
import { UserPreferenceService } from './../services/user-preference.service';
import { AccountAggregationChartFormatterService } from './account-aggregation-chart-formatter.service';
import { AccountAggregationService } from './account-aggregation.service';
import { AccountsService } from './accounts.service';
import { AddressHelper } from './address.helper.service';
import { AlertsNotificationsService } from './alerts-notifications.service';
import { AlertsSmsService } from './alerts-sms.service';
import { AppSettingsService } from './app-settings.service';
import { AuthHelper } from './auth.helper';
import { AxosClearingUrlHelper } from './axos-clearing-urls.service';
import { AxosClearingService } from './axos-clearing.service';
import { AxosInvestUrlHelper } from './axos-invest-urls.service';
import { BalanceService } from './balance.service';
import { BeneficiariesBaseService } from './beneficiaries-base.service';
import { BeneficiariesIraEnhHelper } from './beneficiaries-iraenh.helper';
import { BeneficiariesHelper } from './beneficiaries.helper';
import { BeneficiariesService } from './beneficiaries.service';
import { BillPayService } from './bill-pay.service';
import { BillersService } from './billers.service';
import { BlastMessagesService } from './blast-messages.service';
import { BrandPropertyService } from './brand-property.service';
import { CachedAccountsService } from './cached-accounts.service';
import { CachedTradingAccountsService } from './cached-trading-accounts.service';
import { GlanceCobrowseService } from './cobrowse.service';
import { DebitCardService } from './debit-card.service';
import { DeliveryMethodService } from './deliverymethod.service';
import { DevicesService } from './devices.service';
import { DocumentService } from './document.service';
import { EnrollmentService } from './enrollment.service';
import { FlowService } from './flow.service';
import { HarlandClarkeService } from './harland-clarke.service';
import { LiveChatService } from './live-chat.service';
import { LoadUserProfileHelper } from './load-user-profile-helper';
import { LoanAccountHelper } from './loan-account.helper';
import { LoanService } from './loan.service';
import { MessagesService } from './messages.service';
import { MinorHelper } from './minor.helper';
import { ModalService } from './modal.service';
import { MultifactorService } from './multifactor.service';
import { OmnichannelService } from './omnichannel.service';
import { PaymentsService } from './payments.service';
import { PopupsHelper } from './popups.helper';
import { PrivacyPreferencesService } from './privacy-preferences.service';
import { PropertyBagService } from './property-bag.service';
import { ProviderService } from './provider.service';
import { RedirectStateService } from './RedirectStateService';
import { RewardsCheckingHelper } from './rewards-checking.helper';
import { SecureFormsService } from './secure-forms.service';
import { ServiceHelper } from './service.helper';
import { SettingsService } from './settings.service';
import { SignatureCardService } from './signature-card.service';
import { SpousalConsentService } from './spousal-consent.service';
import { StatementsService } from './statements.service';
import { StopPaymentService } from './stop-payment.service';
import { SupportService } from './support.service';
import { TaxPlanBeneficiariesService } from './tax-plan-beneficiaries.service';
import { TilesService } from './tiles.service';
import { TransactionService } from './transaction.service';
import { UploadService } from './upload.service';
import { UrlSearchParamsHelper } from './url-search-params.helper';
import { UserProfileService } from './user-profile.service';
import { UserRecoveryService } from './user-recovery.service';
import { UtagService } from './utag.service';
import { WiseBanyanService } from './wise-banyan.service';
import {
  AxosClearingDocumentsService,
  ClearingService,
} from '@app/axos-trading/services';
import { TradingTransferScheduleService } from '@app/axos-trading/services';
import { accountsService } from '@app/Areas/AAS/aas-shared/services/accounts.service';
import { AgreementService } from '@app/Areas/AAS/aas-shared/services/agreements.service';
import { SupportViewFacade } from '@app/support/store/support-view/support-view-facade';
import { LoanPayoffUrlHelper } from './loan-payoff-url.helper';
import { SharedAccountsFacade } from '@app/Areas/AAS/features/account-details/facade/shared-accounts.facade';

export const services = angular
  .module('udb.services', ['ngFileUpload', 'angular-jwt'])
  .factory('olbEventService', downgradeInjectable(OlbEventService))
  .factory('authService', downgradeInjectable(AuthService))
  .factory(DialogService.$token, downgradeInjectable(DialogService))
  .factory('businessService', downgradeInjectable(BusinessService))
  .factory('logService', downgradeInjectable(LogService))
  .factory('newmultifactorService', downgradeInjectable(NewMultifactorService))
  .factory('clearingService', downgradeInjectable(ClearingService))
  .factory(
    'tradingTransferScheduleService',
    downgradeInjectable(TradingTransferScheduleService)
  )
  .factory(
    'axosClearingDocumentsService',
    downgradeInjectable(AxosClearingDocumentsService)
  )
  .service('uploadService', UploadService)
  .service('glanceCobrowseService', GlanceCobrowseService)
  .service('utagService', UtagService)
  .service('liveChatService', LiveChatService)
  .service('multifactorService', MultifactorService)
  .service('enrollmentService', EnrollmentService)
  .service('transactionService', TransactionService)
  .service('billersService', BillersService)
  .service('settingsService', SettingsService)
  .service('paymentsService', PaymentsService)
  .service('accountsService', AccountsService)
  .service('accountAggregationService', AccountAggregationService)
  .service('statementsService', StatementsService)
  .service('popups', PopupsHelper)
  .service('serviceHelper', ServiceHelper)
  .service('authHelper', AuthHelper)
  .service('urlSearchParamsHelper', UrlSearchParamsHelper)
  .service('userProfileService', UserProfileService)
  .service('tilesService', TilesService)
  .service('userRecoveryService', UserRecoveryService)
  .service('userPreferenceService', UserPreferenceService)
  .service('secureFormsService', SecureFormsService)
  .service('harlandClarkeService', HarlandClarkeService)
  .service('alertsNotificationsService', AlertsNotificationsService)
  .service('alertsSmsService', AlertsSmsService)
  .service('deliveryMethodService', DeliveryMethodService)
  .service('privacyPreferencesService', PrivacyPreferencesService)
  .service('modalService', ModalService)
  .service('documentService', DocumentService)
  .service('supportService', SupportService)
  .service('stopPaymentService', StopPaymentService)
  .service('debitCardService', DebitCardService)
  .service('messagesService', MessagesService)
  .service('messagesSharedService', MessagesSharedService)
  .service('brandPropertyService', BrandPropertyService)
  .service('blastMessagesService', BlastMessagesService)
  .service('cachedAccountsService', CachedAccountsService)
  .service('signatureCardService', SignatureCardService)
  .service('loadUserProfileHelper', LoadUserProfileHelper)
  .service('customerLimitsHelper', CustomerLimitsHelper)
  .service('cookieHelper', CookieHelper)
  .service('featureFlagService', FeatureFlagService)
  .service('dashboardBalanceService', DashboardBalanceService)
  .service('balanceService', BalanceService)
  .service('flowService', FlowService)
  .service('loanService', LoanService)
  .service('appSettingsService', AppSettingsService)
  .service('loanAccountHelper', LoanAccountHelper)
  .service('beneficiariesService', BeneficiariesService)
  .service('taxPlanBeneficiariesService', TaxPlanBeneficiariesService)
  .service('beneficiariesBaseService', BeneficiariesBaseService)
  .service('beneficiariesIraEnhHelper', BeneficiariesIraEnhHelper)
  .service('beneficiariesHelper', BeneficiariesHelper)
  .service('spousalConsentService', SpousalConsentService)
  .service('minorHelper', MinorHelper)
  .service('wiseBanyanService', WiseBanyanService)
  .service('addressHelper', AddressHelper)
  .service('axosInvestUrlHelper', AxosInvestUrlHelper)
  .service('axosClearingUrlHelper', AxosClearingUrlHelper)
  .service('omnichannelService', OmnichannelService)
  .service(
    'accAggChartFormatterService',
    AccountAggregationChartFormatterService
  )
  .service('redirectStateService', RedirectStateService)
  .service('axosClearingService', AxosClearingService)
  .service('cachedTradingAccountsService', CachedTradingAccountsService)
  .service('userSubtypeHelper', downgradeInjectable(UserSubtypeHelper))
  .service('devicesService', DevicesService)
  .service('redirectStateService', RedirectStateService)
  .service('billPayService', BillPayService)
  .service('billPayHelper', BillPayHelper)
  .service('providerService', ProviderService)
  .service('propertyBagService', PropertyBagService)
  .service('rewardsCheckingHelper', RewardsCheckingHelper)
  .service('loanPayoffUrlHelper', LoanPayoffUrlHelper)
  .service('axosAdvisoryService', AxosAdvisoryService)
  .service('sharedAccountsFacade', SharedAccountsFacade)
  .factory('iPayService', downgradeInjectable(IPayService))
  .factory('aasAccountsService', downgradeInjectable(accountsService))
  .factory('aasAgreementService', downgradeInjectable(AgreementService))
  .factory('messageService', downgradeInjectable(MessageService))
  .factory('supportViewFacade', downgradeInjectable(SupportViewFacade)).name;
