<div class="dialog-header" [style.backgroundImage]="backgroundUrl">
  <app-svg-icon [iconName]="icon.name" [iconColor]="icon.color" [size]="icon.size"></app-svg-icon>
</div>

<h3 mat-dialog-title class="mb-3" [innerText]="title"></h3>

<div class="mat-dialog-content">
  <ng-container *ngIf="!showForm; else form">
    <p class="mb-5">
      You can resend a consent request to your spouse or cancel the consent, which reverts your beneficiaries.
    </p>

    <div class="dialog__actions">
      <button type="submit" class="btn-ok mb-3" cdkFocusInitial (click)="showResendForm()">
        Resend Consent
      </button>
      <button type="button" class="arrow--right button button--accent button--outline" (click)="cancelSpousalConsent()">
        Cancel Consent and Revert
      </button>
    </div>
  </ng-container>
</div>

<ng-template #form>
  <form [formGroup]="resendForm" novalidate class="text-left" (ngSubmit)="resendConsent()">
    <div class="form-group" [class.invalid-field]="email.hasError('*')">
      <label for="spouse-email" class="uk-label">Spouse's Email</label>
      <input type="email" class="uk-input" name="spouse-email" formControlName="spouseEmail" />

      <div class="text-error mt-1" ngxErrors="spouseEmail" #email="ngxErrors">
        <span *ngFor="let error of errors" [ngxError]="error.name" [innerText]="error.message"></span>
      </div>
    </div>

    <div class="dialog__actions">
      <app-dot-loader *ngIf="isBusy; else buttons"></app-dot-loader>

      <ng-template #buttons>
        <button type="submit" class="btn-ok button--lg arrow--none mb-3">Resend email</button>
        <button type="button" class="link" mat-dialog-close>Cancel</button>
      </ng-template>
    </div>
  </form>
</ng-template>
