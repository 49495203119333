/**
 * Signature Card Status type Enum
 *
 * @export
 * @enum {number}
 */
export enum SignatureCardStatusType {
  None = 0,
  Pending = 1,
  Received = 2,
  Accepted = 3,
  Rejected = 4,
  Removed = 5,
}
