import { Validators } from '@angular/forms';
import { textOnlyValidatorFn } from '../text-only';
import { zipCodeValidatorFn } from '../zip-code-field';
import { phoneNumberValidatorFn } from '../phone-number-field';
import { characterEmailValidatorFn, emailValidatorFn } from '../email-field';
import { addressValidatorFn } from '../address-field';
import { alphanumericOnlyValidatorFn } from '../alphanumeric-only';

export const TRUSTED_INTERESTED_MODAL_FORM_VALIDATORS = {
  firstName: [Validators.required, Validators.maxLength(12), textOnlyValidatorFn()],
  lastName: [Validators.required, Validators.maxLength(20), textOnlyValidatorFn()],
  name: [Validators.required, Validators.maxLength(40), textOnlyValidatorFn()],
  relationShip: [Validators.required],
  phoneNumberDefault: [Validators.required, Validators.minLength(10), Validators.maxLength(10)],
  phoneNumber: [Validators.required, Validators.minLength(10), Validators.maxLength(10), phoneNumberValidatorFn()],
  emailDefault: [Validators.required],
  emailOnChange: [characterEmailValidatorFn(), emailValidatorFn(), Validators.required],
  emailOnBlur: [emailValidatorFn(), Validators.required],
  streetAddressDefault: [Validators.required, Validators.maxLength(40)],
  streetAddress: [Validators.required, Validators.maxLength(40), addressValidatorFn()],
  additionalAddressDefault: [Validators.maxLength(40)],
  additionalAddress: [Validators.maxLength(40), addressValidatorFn()],
  city: [Validators.required, Validators.maxLength(50), alphanumericOnlyValidatorFn()],
  state: [Validators.required],
  documentsReceived: [Validators.required],
  zipCode: [Validators.required, Validators.maxLength(5), Validators.minLength(5), zipCodeValidatorFn()],
};
