import { HttpErrorResponse } from '@angular/common/http';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { finalize, take, filter } from 'rxjs/operators';

import { SubSink } from '@axos/subsink';
import * as angular from 'angular';
import { IFilterService } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { getUnixTime, isAfter, isBefore } from 'date-fns';
import { FundingAmountHistoricalAction } from 'funding/typings/funding-intercept-action.model';
import * as moment from 'moment';
import NgRedux from 'ng-redux';
import { AxosClearingService } from 'services/axos-clearing.service';
import { AxosInvestUrlHelper } from 'services/axos-invest-urls.service';
import { CachedTradingAccountsService } from 'services/cached-trading-accounts.service';
import { CustomerLimitsHelper } from 'services/customer-limits.helper';
import { FeatureFlagService } from 'services/feature-flag.service';
import { PaymentsService } from 'services/payments.service';
import { IAccountsService } from 'services/typings/IAccountsService';
import { AxosInvestHelperService as AxosInvestHelper } from '@app/core/services/axos-invest.service';
import { AccountsService } from '@app/core/services/accounts.service';
import { AggregatedAccount } from 'typings/app/account-aggregation';
import { FundingState } from 'typings/app/FundingState';
import { IIraTxConstraint } from 'typings/app/IIraTxConstraint';
import {
  Milestone,
  MilestoneDetailVm,
} from 'typings/app/wise-banyan/Milestone';

import { loadTradingAccounts } from '@app/accounts/store/actions';
import { IRA_ACCOUNT_TYPES } from '@app/axos-invest/constants';
import {
  DepositType as mpDepositType,
  Frequency as mpFrequency,
  GoalAccountType,
  GoalType,
  TransactionType,
  TransferType,
} from '@app/axos-invest/enums';
import {
  GoalAccount,
  MilestoneSummary,
  ScheduledTransferRequest as mpScheduledTransfer,
} from '@app/axos-invest/models';
import { AxosInvestTransferService } from '@app/axos-invest/services';
import { getGoalSummary } from '@app/axos-invest/store/selectors';
import { getRetirementAccounts } from '@app/axos-invest/utils';
import { mapFrequency as mpMapFrequency } from '@app/axos-invest/utils';
import { SCHEDULED_FREQUENCIES_MAPPING } from '@app/axos-trading/constants/scheduled-frequencies.const';
import { DepositType } from '@app/axos-trading/enums/deposit-type.enum';
import { ScheduleFrequency } from '@app/axos-trading/enums/schedule-frequency.enum';
import { TransferTransactionType } from '@app/axos-trading/enums/transfer-transaction-type.enum';
import { ScheduledTransferRequest } from '@app/axos-trading/models/scheduled-transfer-request.model';
import { TradingTransferScheduleService } from '@app/axos-trading/services/trading-transfer-schedule.service';
import {
  AlertsIcons,
  ClearingAccountType,
  NavigationIcons,
} from '@app/shared/enums';
import { changeFundingAmount } from '@app/store/funding/funding.actions';
import {
  FederalTaxesModalComponent,
  IraContributionComponent,
  RiaDistributionModalComponent,
} from '@app/transfers/components/modals';
import { ContributionYearsData } from '@app/transfers/models/contribution-years-data.model';
import { TransferService } from '@app/transfers/services/transfer.service';
import { getProfileDetails } from '@app/user-profile/store/selectors';
import { maskPhone } from '@app/utils';
import { AppSettings, AuthMethod } from '@core/enums';
import { AxosAdvisoryAccount, MultiFactorRequest } from '@core/models';
import {
  AxosAdvisoryService,
  DialogService,
  MultifactorService,
} from '@core/services';
import { TradingAccount } from '@legacy/accounts/typings/TradingAccount';
import { AxosInvestDepositType } from '@legacy/common/enums/axosInvestDepositType.enum';
import { AxosInvestTransactionType } from '@legacy/common/enums/axosInvestTransactionType.enum';
import { MilestoneType } from '@legacy/common/enums/milestoneType.enum';
import { ServiceHelper } from '@legacy/services/service.helper';
import { FundingActions } from '@legacy/state-store/actions/funding.actions';
import { MilestoneTransaction } from '@legacy/typings/app/wise-banyan/MilestoneTransaction';
import { Transfer } from '@legacy/typings/app/wise-banyan/Transfer';
import { DialogComponent } from '@shared/components';
import { ContributionYear, DialogData } from '@shared/models';
import { ClearingAccountStatus } from '../../../common/enums/clearingAccountStatus.enum';
import { Inject } from '../../../decorators/decorators';
import { ModalService, ModalSettings } from '../../../services/modal.service';
import { RedirectStateService } from '../../../services/RedirectStateService';
import { ITransactionService } from '../../../services/typings/ITransactionService';
import { DateHelperService as DateHelper } from '@app/core/services/date.service';
import { FlowType } from '../../../typings/app/flow-type.enum';
import {
  AmountOption,
  Freq as Frequency,
  mapFrequency,
  mapSendUntil,
  SendUntilOption,
} from '../../transfers.controller';
import { ExternalAccountScheduleRequest } from '../../typings/ExternalAccountScheduleRequest';
import { TransferSchedule } from '../../typings/TransferSchedule';
import { CachedAccountsService } from './../../../services/cached-accounts.service';
import * as ReceiptConstants from './../../receipt/typings/ReceiptConstants';
import { ReceiptTemplate } from './../../receipt/typings/ReceiptTemplate';
import { getAxosAdvisoryAccounts } from '@app/axos-advisory/store/selectors';
import { AxosAdvisoryTransferRequest } from '@app/transfers/models/axos-advisory-transfer-request.model';
import {
  AxosAdvisoryTransferAccountType,
  AxosAdvisoryTransferFrequency,
  AxosAdvisoryTransferType,
} from '@app/transfers/enums';
import { TotalContributions } from '@app/transfers/models/total-contributions.model';
import * as ContributionSelector from '@app/axos-advisory/store/contributions/contributions.selectors';
import { NO_CONTRIBUTION_ACCOUNT_TYPES_CONST } from '@legacy/common/constants';
import { RedirectToIpayModalComponent } from '@app/business/modals/redirect-to-ipay-modal/redirect-to-ipay-modal.component';
import { AccountTypeConstants } from '@legacy/accounts/account-type.constants';
import { isNil } from 'lodash';
import { LoanPayoffQuote, PayoffDetailsResponse } from '@app/accounts/models';
import { InternalToExternalComponent } from '@app/accounts/components';
@Inject(
  '$rootScope',
  '$scope',
  '$timeout',
  'accountsService',
  'cachedAccountsService',
  'transactionService',
  'serviceHelper',
  'dateRangePickerOptions',
  '$filter',
  'modalService',
  '$window',
  '$state',
  '$uibModal',
  'dateHelper',
  '$element',
  'loadUserProfileHelper',
  'customerLimitsHelper',
  'featureFlagService',
  '$ngRedux',
  'paymentsService',
  'redirectStateService',
  'axosInvestUrlHelper',
  'axosClearingService',
  'cachedTradingAccountsService',
  '$stateParams',
  'dialogService',
  'transferService',
  'axosInvestTransferService',
  'tradingTransferScheduleService',
  'ngrxStore',
  'matDialog',
  'newmultifactorService',
  'axosAdvisoryService',
  'newAccountsService'
)
export class TransferFundsController {
  get totalAmount(): number {
    const subTotal = +this.additionalPrincipal + +this.escrow;
    if (this.isRegularMonthly)
      return this.selectedToAccount.paymentAmount + subTotal;
    else return subTotal;
  }

  get settingUpIncomingTransfer(): boolean {
    const fromExternal =
      this.selectedFromAccount && this.selectedFromAccount.isExternal;
    const toInternal =
      this.selectedToAccount && !this.selectedToAccount.isExternal;

    return fromExternal && toInternal;
  }

  get settingUpOutgoingTransfer(): boolean {
    const fromInternal =
      this.selectedFromAccount && !this.selectedFromAccount.isExternal;
    const toExternal =
      this.selectedToAccount && this.selectedToAccount.isExternal;

    return fromInternal && toExternal;
  }

  /** returns true if from/to accounts are selected (one of them external) and the customer
   * limits are loaded
   */
  get externalTransferLimitsAndFromToAccountsReady(): boolean {
    return (
      (this.settingUpIncomingTransfer &&
        !!this.customerLimits.extTransfersMaxIncomingPerPayment) ||
      (this.settingUpOutgoingTransfer &&
        !!this.customerLimits.extTransfersMaxOutgoingPerPayment)
    );
  }

  /** returns the external transfer limit per payment (incoming or outgoing) formatted as currency*/
  get formattedExternalTransferLimitPerPayment() {
    return this.settingUpIncomingTransfer
      ? this.filterService('currency')(
          this.customerLimits.extTransfersMaxIncomingPerPayment
        )
      : this.filterService('currency')(
          this.customerLimits.extTransfersMaxOutgoingPerPayment
        );
  }

  get isSiteInReadOnly(): boolean {
    return this.featureFlagService.isSiteInReadOnly();
  }

  get isSBBActive(): boolean {
    return this.featureFlagService.isSBBActive();
  }

  get transferHasAxosInvest(): boolean {
    return (
      this.selectedFromAccount?.isAxosInvest ||
      this.selectedToAccount?.isAxosInvest
    );
  }

  get transferHasRiaAccounts(): boolean {
    return this.selectedFromAccount?.isRia || this.selectedToAccount?.isRia;
  }

  get transferIsBetweenMilestones(): boolean {
    return (
      this.selectedFromAccount?.isAxosInvest &&
      this.selectedToAccount?.isAxosInvest
    );
  }

  get transferHasTrading(): boolean {
    return (
      this.selectedFromAccount?.isTrading || this.selectedToAccount?.isTrading
    );
  }

  //TODO: set a constant for this value instead of hardcoding it
  get riaAmountLimit(): number {
    const riaLimit = 100000;

    return riaLimit;
  }

  // OTP properties
  step = 1;
  phoneNumber: string;
  maskedCellPhone: string;
  otp: string;
  accessCodeError: string;

  // Bound properties
  currentYear = 0;
  fromAccountId: number;
  toAccountId: number;

  selectedFromAccount: NewOlbAccount;
  isLoadingFromAcc: boolean;
  isBalancesAvailable = false;
  fromAccountsArray: NewOlbAccount[] = [];
  externalFromAccountsArray: any[] = [];
  internalFromAccountsArray: any[] = [];
  investmentAccounts: any[] = [];
  tradingAccountsArray: any[] = [];
  supportedProductTypesForInvest = ['savings', 'checking', 'money market'];
  selectedFromAccountValid = true;
  // To Account
  selectedToAccount: NewOlbAccount;
  isLoadingToAcc: boolean;
  toAccountsArray: NewOlbAccount[] = [];
  externalToAccountsArray: any[] = [];
  internalToAccountsArray: any[] = [];
  tradingToAccountsArray: any[] = [];
  selectedToAccountValid = true;
  // Frequency
  frequencies: GenericOption[];
  selectedFrequency: GenericOption;
  isRecurrent: boolean;
  selectedFrequencyValid = true;
  // Send Until
  sendUntilOptions: GenericOption[];
  selectedSendUntil: GenericOption;
  showLastTransfer: boolean;
  showNumberTransfers: boolean;
  // Amount options
  selectedAmountOption: GenericOption;
  showAmount: boolean;
  showLoanSection: boolean;
  showMortgageSection: boolean;
  showAmountOptions: boolean;
  showMinimumAmount: boolean;
  showOutstandingBalance: boolean;
  amountEditable: boolean;
  amountValid = true;
  frequencySelectDisabled: boolean = false;
  // Mortgage specific fields
  isRegularMonthly: boolean;
  enableRegularMonthly: boolean;
  regularMonthlyPayment: number;
  additionalPrincipal: number;
  escrow: number;

  // external account flag
  hasExternalAccount = false;
  // is internal same day
  isInternalSameDay = true;
  validNumber = new RegExp('^[0-9]*$');

  isShowFITW = false;
  isShowSITW = false;
  isDisclosure = false;
  acceptDisclosure = false;
  // Federal Witholding
  withholdPercentageValue = 10;

  withholdAmountValue = 0;
  withholding = 0;
  // State Withholding
  stateWithholdPercentageValue = 5;
  stateWithholdAmountValue = 0;
  stateWithhold = 0;
  withholdNetValue = 0;
  federalWithHoldMin = 10;
  stateWithHoldMin = 1;
  axosInvestUrl: string;
  milestoneDetail: MilestoneSummary;
  isManagedPortfoliosEnabled: boolean;

  selectedFromAccountNumber: string;
  selectedToAccountNumber: string;
  // Models
  date: moment.Moment;
  endDate: moment.Moment;
  lastTransferDate: Date;
  numberTransfers: number;
  memo: string;
  amount: number;
  confirmEmail: boolean;
  confirmText: boolean;
  // User profile info
  isLoadingUserInfo: boolean;
  // Withdrawal
  withdrawal: WithdrawalCounter;
  showWithdrawals: boolean;
  withdrawalMsg: string;
  showWithdrawalsTo: boolean;
  withdrawalMsgTo: string;
  externalMessage: string =
    '*Transfers To or From an external account are scheduled the next business day if submitted before 1:45 p.m. Pacific Time. Any requests after this cut-off time will be scheduled for the second business day.';
  // Datepickers
  datePicker: {
    options: any;
    date: any;
  };

  datePickerEndDate: {
    options: any;
    date: any;
  };
  // Error objects
  amountErrors: GenericError;
  TransferFromErrors: GenericError;
  lastDateErrors: GenericError;
  numberTransfersErrors: GenericError;
  sendUntilErrors: GenericError;
  amountAdditionalErrors: GenericError;
  regularMonthlyErrors: GenericError;

  isBusy: boolean;
  isFromExternal = false;
  isToExternal = false;
  isFromTrading = false;
  isFromRIA = false;
  isToTrading = false;
  isToRIA = false;
  isProductTypeNotCheckingOrSaving = false;
  // SBB Logic
  isToSBB = false;
  isFromSBB = false;
  isSBB = false;
  customerLimits: CustomerLimits;
  isTodayValidForExternal: boolean;
  errorMessage: string;
  /** funding flow */
  fundingState: FundingState;
  userAge: number;
  userDOB: string;
  userCommonName: string;
  showIRAWithdrawalDisclaimer = false;
  toggleIRAPenaltyInfo = false;
  retirementIraSelected: boolean;
  retirementNonIraSelected: boolean;
  /**contribution years */
  currentContributionYear: ContributionYearsData;
  previousContributionYear: ContributionYearsData;
  contributionYearCutoff = false;
  currentContributionYearLimit: number;
  previousContributionYearLimit: number;
  selectedContributionYear: number;
  contributionYearIsValid = true;
  //  if a customer will become 70.5 years or older at any time during the current calendar year
  showIRADistributionDisclaimer = false;
  toggleIRADistributionInfo = false;
  buttonText: string;
  isReadOnly: boolean;
  addNonAxosAccntText = '';
  isLoanFeatureEnabled: boolean;
  transferFromRetirementMp: GoalAccount[];
  transferToRetirementMp: GoalAccount[];
  readDisclosureMp = false;
  readIRaDisclosureMp = false;
  displayTaxImplicationsDisclaimer = false;
  acceptDisclosureMp = false;
  acceptIraDisclosureMp = false;
  isManagedPortfolioAccount = false;
  displayIraDisclosure = false;
  autoDepositMp = false;
  displayWithdrawalPenaltyDisclaimerMp = false;
  displayWithdrawalPdMore = false;
  transferMpValue: GoalAccount;
  icons = {
    arrowUp: NavigationIcons.ChevronUp,
    arrowDown: NavigationIcons.ChevronDown,
    arrowForward: NavigationIcons.ArrowForward,
    question: AlertsIcons.QuestionCircle,
  };
  flowTypeEnum = FlowType;
  cyear: ContributionYear;
  isIraEnhDiraFlagActive = false;
  isRiaPilotFlagActive = false;
  isSBLOCMVPFlagActive = false;
  isTransferFromIraTradingEnabled = false;
  isValidIraTransaction = false;
  hasUserPhone: boolean; // Validate if user has configured a phone number.
  isLastTransferDate = false;
  hasRiaAccounts: boolean;
  riaAccounts: AxosAdvisoryAccount[];
  isRiaDistribution: boolean;
  //To indicate that the contribution amount
  //has been reached for the contribution year
  isContributedAmountReached = false;
  contributionReachedMessage = '';
  sendUntilDisabled = false;
  totalContributions: TotalContributions[];
  haveContributed: number;
  stillContribute: number;
  displayCutOffDisclaimer = false;
  isRiaPilotContributionFrequencyActive = false;

  moveMoneyErrorMessage = 'Same Day ACH Transfer not allowed';

  private contributionsYears: ContributionYear[] = [];
  private modalHeader: string;
  private modalFooter: string;
  // Transfer complete state.
  private hasFinished = false;

  private unsubscribe: Function;
  private loadedMilestonesDetail = false;
  private hasAxosInvest: boolean;
  private aggregatedAccounts: AggregatedAccount[];
  private dialog: MatDialogRef<DialogComponent>;
  private subSink = new SubSink();

  constructor(
    private root: ng.IRootScopeService,
    private scope: ng.IScope,
    private timeoutService: ng.ITimeoutService,
    private accountsService: IAccountsService,
    private cachedAccountsService: CachedAccountsService,
    private transactionService: ITransactionService,
    private serviceHelper: ServiceHelper,
    private dateRangePickerOptions: any,
    private filterService: IFilterService,
    private modalService: ModalService,
    private windowService: ng.IWindowService,
    private stateService: ng.ui.IStateService,
    private uibModalService: any,
    private dateHelper: DateHelper,
    private elem: ng.IRootElementService,
    private loadUserProfileHelper: ILoadUserProfileHelper,
    private customerLimitsHelper: CustomerLimitsHelper,
    private featureFlagService: FeatureFlagService,
    private ngRedux: NgRedux.INgRedux,
    private paymentsService: PaymentsService,
    private redirectStateService: RedirectStateService,
    private axosInvestUrlHelper: AxosInvestUrlHelper,
    private axosClearingService: AxosClearingService,
    private cachedTradingAccountsService: CachedTradingAccountsService,
    private params: IStateParamsService,
    private dialogService: DialogService,
    private transferService: TransferService,
    private axosInvestTransferService: AxosInvestTransferService,
    private tradingTransferScheduleService: TradingTransferScheduleService,
    private store: Store,
    private matDialog: MatDialog,
    private multifactorService: MultifactorService,
    private axosAdvisoryService: AxosAdvisoryService,
    private newAccountsService: AccountsService
  ) {}

  /** Initializes the controller. */
  $onInit(): void {
    this.getContributionYears();
    this.getTotalContributions();
    this.initialize();
    this.checkCustomerLimits();
    this.setupMaskedCellPhone();

    this.unsubscribe = this.ngRedux.subscribe(this.initialize.bind(this));

    this.isIraEnhDiraFlagActive = this.featureFlagService.isIraEnhTradFlagActive();
    this.isSBLOCMVPFlagActive = this.featureFlagService.isSBLOCMVPActive();
    this.isRiaPilotContributionFrequencyActive = this.featureFlagService.isRiaPilotContributionFrequencyActive();

    if (this.root['loanFlagAvailable']) {
      this.isLoanFeatureEnabled = this.featureFlagService.isAxosSchedulerActive();
    }

    this.scope.$on('loanFlagAvailable', () => {
      this.isLoanFeatureEnabled = this.featureFlagService.isAxosSchedulerActive();
    });

    this.subSink.sink = this.store
      .select(getAxosAdvisoryAccounts)
      .pipe(filter(riaAccounts => !!riaAccounts))
      .subscribe(riaAccounts => {
        this.hasRiaAccounts = riaAccounts?.length > 0;
        this.riaAccounts = riaAccounts.filter(
          x => !NO_CONTRIBUTION_ACCOUNT_TYPES_CONST.includes(x.accountTypeCode)
        );
      });

    this.isManagedPortfoliosEnabled = this.featureFlagService.isManagedPortfoliosEnabled();

    this.isRiaPilotFlagActive = this.featureFlagService.isRiaPilotActive();

    if (this.featureFlagService.isAxosInvestActive()) {
      this.hasAxosInvest = JSON.parse(sessionStorage.getItem('hasAxosInvest'));
      if (this.hasAxosInvest) {
        this.getAxosInvestUrl();
        this.subSink.sink = this.store
          .select(getGoalSummary)
          .subscribe(goalSummary => {
            if (goalSummary.milestones.length > 0) {
              this.milestoneDetail = JSON.parse(JSON.stringify(goalSummary));
              this.loadedMilestonesDetail = true;
            }
          });
      }
    }

    if (this.axosClearingService.isAxosTradingActiveForUser()) {
      this.scope.$on(
        'investAccountsLoaded',
        this.loadTradingAccounts.bind(this)
      );
    }

    this.loadUserProfileHelper.getUserProfilePromise().then(res => {
      this.userDOB = res.dateOfBirth;
      this.userAge = Math.round(
        moment().diff(moment(res.dateOfBirth), 'years', true)
      );
      this.userCommonName = res.lastName + ' ' + res.firstName;

      this.setShowIRADistributionDisclaimer();
      this.setShowIRAWithdrawalDisclaimer();
    });

    this.initializeFields(true);

    this.datePicker = {
      options: angular.extend(angular.copy(this.dateRangePickerOptions), {
        minDate: moment(),
        maxDate: moment().add(13, 'M'),
        singleDatePicker: true,
        autoUpdateInput: true,
        eventHandlers: {
          'hide.daterangepicker': (_evt: any) => {
            if (!_evt.model.startDate && !_evt.model.endDate)
              this.date = moment(_evt.model);
            this.isInternalSameDay =
              moment(this.date).isSame(moment(), 'd') &&
              !this.hasExternalAccount;
            if (!this.isInternalSameDay) {
              this.additionalPrincipal = 0;
              this.escrow = 0;
              this.isTodayValidForExternal = true;
            }
          },
        },
        isInvalidDate: (date: any) => {
          return (
            (this.hasExternalAccount && !this.dateHelper.isValidDate(date)) ||
            !this.isValidContributionYearDate(date)
          );
        },
      }),
      date: { startDate: moment(), endDate: moment() },
    };

    this.scope.$on('transferFundsHasChanges', () => {
      if (
        this.hasChanges() &&
        !this.hasFinished &&
        !(this.selectedToAccount && this.selectedToAccount.isLoan)
      ) {
        this.showLeaveModal();
      } else {
        this.redirectState();
      }
    });

    if (this.root['brandProperties']) {
      this.addAccntText();
    } else {
      this.root.$on('brandPropertiesLoaded', this.addAccntText.bind(this));
    }

    const {
      internalAccounts,
      externalAccounts,
    } = this.cachedAccountsService.getFromAccounts(true);
    this.internalFromAccountsArray = internalAccounts;
    this.externalFromAccountsArray = externalAccounts;
    this.aggregatedAccounts = this.cachedAccountsService.aggregatedAccounts;

    if (internalAccounts && this.root['balancesAvailable']) {
      this.isBalancesAvailable = true;
      this.handleFromAccounts();

      return;
    }

    this.isLoadingFromAcc = true;
    this.scope.$on('balancesAvailable', () => {
      if (!this.root['balancesAvailable']) return;
      const {
        internalAccounts,
        externalAccounts,
      } = this.cachedAccountsService.getFromAccounts(true);
      this.internalFromAccountsArray = internalAccounts;
      this.externalFromAccountsArray = externalAccounts;
      this.aggregatedAccounts = this.cachedAccountsService.aggregatedAccounts;
      this.isLoadingFromAcc = false;
      this.isBalancesAvailable = true;
      this.handleFromAccounts();
    });
  }

  /**
   * When the controller is destroyed,
   * unsubscription to the state changes is performed
   */
  $onDestroy() {
    this.unsubscribe();
    this.subSink.unsubscribe();
  }

  redirectState(): void {
    this.scope.$emit('changeStateFromTransfers');
  }

  addAccntText() {
    const toggleText = this.root['brandProperties']['AccountToggleText'];
    this.addNonAxosAccntText = `+Add Non-${toggleText} Account`;
  }

  /** Initializes all the fields for the form
   * @param all An indicator if should initialize all fields or only some of them
   */
  initializeFields(all: boolean): void {
    this.currentYear = new Date().getFullYear();

    if (all) {
      this.selectedFromAccount = null;
      this.isLoadingFromAcc = false;
      this.showWithdrawals = false;
      this.showWithdrawalsTo = false;
      this.withdrawalMsg = '';
      this.withdrawalMsgTo = '';
      this.withdrawal = null;
      this.selectedToAccount = null;
      this.isLoadingToAcc = false;
      this.frequencies = [];
      this.selectedFrequency = null;
      this.isRecurrent = false;
      this.isBalancesAvailable = false;
    }

    this.sendUntilOptions = [];
    this.selectedSendUntil = null;
    this.showLastTransfer = false;
    this.showNumberTransfers = false;
    this.showAmount = true;
    this.showMortgageSection = false;
    this.showAmountOptions = false;
    this.showLoanSection = false;
    this.showMinimumAmount = false;
    this.showOutstandingBalance = false;
    this.amountEditable = false;
    this.isRegularMonthly = true;
    this.enableRegularMonthly = false;
    this.isManagedPortfolioAccount = false;
    this.date = null;
    this.lastTransferDate = null;
    this.numberTransfers = null;
    this.memo = '';
    // If we come from funding, an amount has been previously specified, so we need to keep it
    this.fundingState.isRunning &&
      (this.amount = this.fundingState.depositAmount);
    this.confirmEmail = false;
    this.confirmText = false;
    this.regularMonthlyPayment = 0;
    this.additionalPrincipal = 0;
    this.escrow = 0;
    this.isLoadingUserInfo = false;
    this.isBusy = false;
    this.isInternalSameDay = true;
    this.buttonText = 'Transfer Funds';
    this.fundingState.isRunning && (this.buttonText = 'Fund Account');
  }

  /** Triggered when the From account dropdown changes */
  fromAccountChange(): void {
    if (this.selectedFromAccount?.id === -1) {
      this.goToAddExternalAccounts();
    }

    this.resetErrors();
    this.isValidIraTransaction = false;
    this.selectedToAccount = null;
    this.frequencies = [];
    this.transferFromRetirementMp = [];
    this.transferToRetirementMp = [];
    this.displayTaxImplicationsDisclaimer = false;
    this.displayIraDisclosure = false;
    this.readDisclosureMp = false;
    this.readIRaDisclosureMp = false;
    this.selectedFrequency = null;
    this.isRecurrent = false;
    this.transferMpValue = null;
    this.retirementNonIraSelected = false;
    this.initializeFields(false);
    this.selectedFromAccountValid = !(this.selectedFromAccount === null);
    this.isLastTransferDate = false;

    const isFromExternal = this.selectedFromAccount?.isExternal;
    this.isFromTrading = this.selectedFromAccount.isTrading;
    this.isFromRIA = this.selectedFromAccount.isRia;

    this.setShowIRADistributionDisclaimer();
    this.setShowIRAWithdrawalDisclaimer();

    this.isFromExternal = isFromExternal;
    this.hasExternalAccount = isFromExternal;

    this.isProductTypeNotCheckingOrSaving = !(
      this.selectedFromAccount.productType.toLowerCase() == 'checking' ||
      this.selectedFromAccount.productType.toLowerCase() == 'savings'
    );

    if (
      this.selectedFromAccount.productCode == 'SB' &&
      !this.selectedFromAccount.canTransferToExternal
    ) {
      this.TransferFromErrors = {
        hasError: true,
        errorDescription:
          'SBLOC accounts can only transfer to internal accounts.',
      };
    } else {
      this.TransferFromErrors = {
        hasError: false,
        errorDescription: null,
      };
    }

    if (
      this.selectedFromAccount.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
      this.validateMilestoneRetirementAccount(
        this.selectedFromAccount.routingNumber
      )
    ) {
      this.displayManagedPortfolioIras(true);
    }

    if (this.hasRiaAccounts && this.selectedFromAccount.isRia) {
      this.showDistributionModal();
      this.isRiaDistribution = true;
      this.validIraTransaction();
    }

    this.loadToAccounts.bind(this)(
      this.selectedFromAccount.id,
      isFromExternal,
      this.selectedFromAccount.accountTypeCode
    );

    this.loadWithdrawals.bind(this)(this.selectedFromAccount);

    if (this.selectedFromAccount.isExternal) {
      const externalAccount = this.externalFromAccountsArray.find(
        ea => ea.externalAccountId === this.selectedFromAccount.id
      );
      this.selectedFromAccountNumber = externalAccount.accountNumber;
    } else {
      this.selectedFromAccountNumber = this.selectedFromAccount.accountNumber;
    }
  }

  displayManagedPortfolioIras(isFrom: boolean) {
    this.displayWithdrawalPdMore = false;
    this.displayWithdrawalPenaltyDisclaimerMp = false;
    this.isManagedPortfolioAccount = true;
    this.acceptDisclosureMp = false;
    this.acceptIraDisclosureMp = false;
    this.autoDepositMp = false;
    this.retirementNonIraSelected = false;

    isFrom
      ? (this.displayTaxImplicationsDisclaimer = true)
      : (this.displayIraDisclosure = true);

    const milestoneId = isFrom
      ? this.selectedFromAccount.routingNumber
      : this.selectedToAccount.routingNumber;
    const milestoneAccounts = this.milestoneDetail.milestones.find(
      milestone => milestone.id === milestoneId
    ).accounts;
    const isMilestoneToMilestone =
      (this.selectedToAccount?.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
        this.validateMilestoneRetirementAccount(
          this.selectedFromAccount?.routingNumber
        )) ||
      (this.selectedFromAccount?.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
        this.validateMilestoneRetirementAccount(
          this.selectedToAccount?.routingNumber
        ));

    let retirementAccounts = getRetirementAccounts(milestoneAccounts);
    if (isMilestoneToMilestone) {
      retirementAccounts = retirementAccounts.filter(
        x => x.type === GoalAccountType.Personal
      );
    }

    if (retirementAccounts.length === 1) {
      this.transferMpValue = retirementAccounts[0];
      if (IRA_ACCOUNT_TYPES.includes(this.transferMpValue.type)) {
        this.retirementIraSelected = true;
        this.validateRetirementAccount();
      } else if (this.transferMpValue.type === GoalAccountType.Personal) {
        this.retirementNonIraSelected = true;
      }
    }

    if (isFrom) {
      this.transferFromRetirementMp = retirementAccounts;
    } else {
      this.transferToRetirementMp = retirementAccounts;
    }
  }

  getContributionYears() {
    this.transferService
      .getContributionYears()
      .subscribe(res => this.loadMPContributionYears(res));
  }
  getTotalContributions() {
    this.subSink.sink = this.store
      .select(ContributionSelector.getContributions)
      .subscribe((contribution: TotalContributions[]) => {
        this.totalContributions = contribution;
      });
  }

  loadMPContributionYears(
    contributionYears: ContributionYearsData[],
    date = new Date()
  ) {
    contributionYears = contributionYears
      .filter(
        year => isAfter(date, year.startDate) && isBefore(date, year.endDate)
      )
      .sort((a, b) => getUnixTime(a.startDate) - getUnixTime(b.startDate));
    this.currentContributionYear = contributionYears.slice(-1)[0];
    this.previousContributionYear =
      contributionYears.length > 1 ? contributionYears.slice(-2)[0] : null;
    if (!this.previousContributionYear) {
      this.selectedContributionYear = this.currentContributionYear.year;
    }
  }

  contributionYearChange(): void {
    this.contributionYearIsValid = this.validateContributionYear();
  }

  validateMilestoneRetirementAccount(milestoneId: string) {
    const milestone = this.milestoneDetail?.milestones.find(
      x => x.id === milestoneId
    );

    return milestone?.type === GoalType.Retirement;
  }

  validateRetirementAccount() {
    if (
      this.selectedToAccount?.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
      this.validateMilestoneRetirementAccount(
        this.selectedToAccount?.routingNumber
      )
    ) {
      const milestoneId = this.selectedToAccount.routingNumber;
      const milestoneAccounts = this.milestoneDetail.milestones.find(
        milestone => milestone.id === milestoneId
      ).accounts;
      const retirementAccounts = getRetirementAccounts(milestoneAccounts);

      if (retirementAccounts.length === 1) {
        this.transferMpValue = retirementAccounts[0];
      }
    }
    this.retirementNonIraSelected =
      this.transferMpValue?.type === GoalAccountType.Personal;

    if (
      !this.retirementNonIraSelected &&
      this.selectedFromAccount?.isAxosInvest
    ) {
      this.toAccountsArray = this.toAccountsArray.filter(
        x => x.goalAccountType !== GoalAccountType.Personal
      );
    } else if (this.selectedFromAccount?.isAxosInvest) {
      this.loadAxosInvestAccounts('toAccountsArray');
    }

    this.validateMpDeposit();
    this.displayWithdrawalPdMore = false;
    this.previousContributionYearLimit = this.transferMpValue
      ?.previousYearContributions
      ? this.transferMpValue.previousYearContributions
      : 0;
    this.currentContributionYearLimit = this.transferMpValue
      ?.currentYearContributions
      ? this.transferMpValue.currentYearContributions
      : 0;
    const retirementType = IRA_ACCOUNT_TYPES.includes(
      this.transferMpValue?.type
    );
    this.displayWithdrawalPenaltyDisclaimerMp = retirementType;
    this.retirementIraSelected = this.displayWithdrawalPenaltyDisclaimerMp;
  }

  getAxosInvestUrl() {
    this.axosInvestUrlHelper.getCreateFundsUrl().then(url => {
      this.axosInvestUrl = url;
    });
  }

  setAmount(value: number): void {
    this.amount = value;
    this.setValidationAmount();
  }

  setPercentage(value: number): void {
    this.withholdPercentageValue = value;
    this.calculateWithholdValue();
  }

  setStatePercentage(value: number): void {
    this.stateWithholdPercentageValue = value;
    this.calculateWithholdValue();
  }

  showTradingTransferLimit(): number {
    if (!this.selectedToAccount?.isTrading) return;

    if (
      this.selectedFromAccount.isDeposit &&
      !this.selectedFromAccount.isExternal &&
      this.selectedToAccount.isTrading &&
      !this.selectedToAccount.isIra
    ) {
      return this.getClearingAmountLimit(AppSettings.ClearingInternalCap);
    }

    if (
      this.selectedFromAccount.isExternal &&
      this.selectedToAccount.isTrading &&
      !this.selectedToAccount.isIra
    ) {
      return this.getClearingAmountLimit(AppSettings.ClearingExternalCap);
    }
  }

  showRiaTransferLimit(): number {
    if (!this.selectedToAccount?.isRia) return;

    return this.riaAmountLimit;
  }

  // Validate Amount
  setValidationAmount(): void {
    let errorMessage: string;
    this.amountValid = false;

    if (isNaN(this.amount) || this.amount <= 0) {
      errorMessage = 'Enter an amount';
    } else if (
      this.showTradingTransferLimit() &&
      this.amount > this.showTradingTransferLimit()
    ) {
      this.amountValid = false;
    } else if (this.selectedToAccount?.isIra) {
      errorMessage = this.validateRetirementAmount();
      if (!errorMessage) {
        errorMessage = this.validateFromAvailableFunds();
      }
    } else if (
      this.showRiaTransferLimit() &&
      this.amount > this.showRiaTransferLimit()
    ) {
      this.amountValid = false;
      errorMessage = this.validateRiaAccountLimits();
    } else if (
      (this.transferHasAxosInvest || this.transferHasTrading) &&
      !this.transferIsBetweenMilestones
    ) {
      errorMessage = this.isTransferToIraTrading()
        ? this.validateIRATradingAmount()
        : this.validateClearingAmountLimits();

      if (this.selectedFromAccount.isTrading) {
        this.calculateWithholdValue();
        this.calculateStateWithholdValue();
      }
      if (!errorMessage) {
        this.amountValid = true;
      }
    } else if (
      this.settingUpIncomingTransfer &&
      this.customerLimits.extTransfersMaxIncomingPerPayment &&
      this.amount > this.customerLimits.extTransfersMaxIncomingPerPayment
    ) {
      this.errorMessage = errorMessage =
        'Amount exceeds maximum customer limit per inbound transfer';
    } else if (
      this.settingUpOutgoingTransfer &&
      this.customerLimits.extTransfersMaxOutgoingPerPayment &&
      this.amount > this.customerLimits.extTransfersMaxOutgoingPerPayment
    ) {
      this.errorMessage = errorMessage =
        'Amount exceeds maximum customer limit per outbound transfer';
    } else if (this.isSBLOCMVPFlagActive) {
      //The validation for SBLOC and HELOC accounts must be against available credit
      if (
        this.selectedFromAccount.productCode == 'SB' ||
        this.selectedFromAccount.productCode == '16'
      ) {
        if (this.selectedFromAccount.availableCredit !== null) {
          let availableFunds = this.selectedFromAccount.availableCredit;

          this.amountValid =
            availableFunds !== null && this.amount <= availableFunds;

          this.errorMessage = this.amountValid
            ? null
            : 'Insufficient Funds available';
        } else {
          this.amountValid = true;
          this.errorMessage = null;
          this.calculateWithholdValue();
        }
      } else {
        // TODO: Review the availableBalance validation cause some accounts does not have availableBalance (i.e.
        // external accounts added for OnlyTransfers or by microdepostits). In this case, this was sending an
        // the message, but it should not be validated.
        errorMessage = this.validateFromAvailableFunds();
      }
    } else {
      errorMessage = this.validateFromAvailableFunds();
    }

    this.amountErrors = {
      hasError: !this.amountValid,
      errorDescription: this.amountValid ? null : errorMessage,
    };
  }

  /**
   * Fetch customer limits
   */
  checkCustomerLimits() {
    this.customerLimitsHelper
      .fetchCustomerLimits()
      .then(res => {
        this.customerLimits = res;
      })
      .catch(this.serviceHelper.errorHandler);
  }

  /** Triggered when the To account dropdown changes */
  toAccountChange(): void {
    if (this.selectedToAccount.id === -1) {
      this.goToAddExternalAccounts();
    }

    this.resetErrors();
    this.loadFrequencies();
    if (this.selectedToAccount?.isLoan) this.buttonText = 'Continue';
    this.showWithdrawalsTo = false;
    this.isToExternal = this.selectedToAccount.isExternal;
    this.isToTrading = this.selectedToAccount.isTrading;
    this.isToRIA = this.selectedToAccount.isRia;
    this.selectedToAccountValid = !(this.selectedToAccount === null);
    this.transferToRetirementMp = [];
    this.displayIraDisclosure = false;
    this.retirementIraSelected = false;
    this.isLastTransferDate = false;

    if (
      this.selectedFromAccount.isExternal &&
      (this.selectedToAccount.accountType === 'DemandDeposit' ||
        this.selectedToAccount.productType?.toLowerCase() === 'savings')
    ) {
      this.isFromExternal = true;
      this.showWithdrawalsTo = true;
      this.withdrawalMsgTo =
        'Transferring money from an external account will take 5 business days before it becomes available in your account.';
    }

    if (this.validIraTransaction()) this.calculateWithholdValue();

    this.setShowIRADistributionDisclaimer();
    this.setShowIRAWithdrawalDisclaimer();

    if (
      this.selectedFromAccount?.isSBB &&
      this.selectedToAccount.Type === AccountTypeConstants.NON_AXOS_ACCOUNTS
    ) {
      this.openRedirectToIPayModal();
    }
    if (this.selectedToAccount.isExternal) {
      const externalAccount = this.externalToAccountsArray.find(
        ea => ea.externalAccountId === this.selectedToAccount.id
      );
      this.selectedToAccountNumber = externalAccount.accountNumber;
    } else {
      this.selectedToAccountNumber = this.selectedToAccount.accountNumber;
    }

    if (this.selectedToAccount.isIra && !this.selectedFromAccount.isIra) {
      this.retirementIraSelected = true;
      this.loadContributionYears();
    }

    if (
      this.selectedFromAccount?.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
      this.validateMilestoneRetirementAccount(
        this.selectedFromAccount?.routingNumber
      )
    ) {
      this.isManagedPortfolioAccount = true;
    } else {
      this.retirementNonIraSelected = false;
      this.transferMpValue = null;
    }

    if (
      (this.selectedToAccount.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
        this.validateMilestoneRetirementAccount(
          this.selectedToAccount.routingNumber
        )) ||
      this.isTransferToIraTrading()
    ) {
      if (this.isTransferToIraTrading()) {
        this.loadContributionYears();
        this.validateRetirementAccount();
      } else {
        this.displayManagedPortfolioIras(false);
      }
    }

    if (
      (this.selectedToAccount.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
        this.validateMilestoneRetirementAccount(
          this.selectedFromAccount?.routingNumber
        )) ||
      (this.selectedFromAccount?.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
        this.validateMilestoneRetirementAccount(
          this.selectedFromAccount?.routingNumber
        ) &&
        this.transferFromRetirementMp.length === 1)
    ) {
      this.displayManagedPortfolioIras(true);
    }

    if (!this.isTransferToIraTrading()) {
      this.isContributedAmountReached = false;
    } else if (this.cyear) {
      this.calculateContributions();
    }

    this.shouldDisplayCutOffDisclaimer();
  }

  shouldDisplayCutOffDisclaimer() {
    if (
      this.selectedFromAccount.isExternal === undefined ||
      this.isToExternal === undefined
    ) {
      this.displayCutOffDisclaimer = false;
    } else if (
      (!this.selectedFromAccount.isExternal && this.isToExternal) ||
      (this.selectedFromAccount.isExternal && !this.isToExternal)
    ) {
      this.displayCutOffDisclaimer = true;
    }
  }

  setContributionYearChange(): void {
    this.calculateContributions();
  }
  /** Triggered when the Frequency dropdown changes */
  frequencyChange(): void {
    this.selectedFrequencyValid = !(this.selectedFrequency === null);
    this.initializeFields(false);
    this.isRecurrent = this.selectedFrequency.value !== Frequency.ONETIME;

    this.loadSendUntilOptions();
    this.loadAmountOptions(
      this.selectedToAccount,
      this.selectedFrequency.value
    );

    if (
      this.isRecurrent &&
      (this.selectedFromAccount.isAxosInvest ||
        this.selectedToAccount.isAxosInvest)
    ) {
      this.sendUntilOptions = this.sendUntilOptions.filter(
        x => x.value === SendUntilOption.IChooseToStop
      );
      this.datePickerEndDate = {
        options: angular.extend(angular.copy(this.dateRangePickerOptions), {
          minDate: this.datePicker.date,
          maxDate: moment().add(13, 'M'),
          singleDatePicker: true,
          autoUpdateInput: true,
          eventHandlers: {
            'hide.daterangepicker': (_evt: any) => {
              if (!_evt.model.startDate && !_evt.model.endDate)
                this.endDate = moment(_evt.model);
            },
          },
        }),
        date: { startDate: moment(), endDate: moment() },
      };
      this.sendUntilOptions = this.sendUntilOptions.filter(
        x => x.value === SendUntilOption.IChooseToStop
      );
      this.sendUntilOptions.push({
        value: SendUntilOption.NumberOfTransfers,
        label: mapSendUntil(SendUntilOption.NumberOfTransfers),
      });
    }

    if (this.isTransferToNonRetirementRia()) {
      if (this.isRecurrent) {
        this.selectedSendUntil = this.sendUntilOptions[0];
        this.sendUntilDisabled = true;
      }
    }

    if (this.isSBBActive && this.fundingState.isRunning) {
      if (this.selectedFromAccount.isExternal && this.selectedToAccount.isSBB) {
        this.selectedSendUntil = this.sendUntilOptions[0];
        this.sendUntilDisabled = true;
        this.frequencySelectDisabled = true;
        return;
      }
      this.frequencySelectDisabled = false;
    }
    this.setCalendarOptions(this.selectedFrequency.value);
  }

  /** Triggered when the Send Until dropdown changes */
  sendUntilChange(): void {
    this.showLastTransfer = false;
    this.showNumberTransfers = false;
    this.isLastTransferDate = false;

    this.lastTransferDate = null;
    this.numberTransfers = null;
    this.endDate = null;

    if (this.selectedSendUntil == null) {
      return;
    } else {
      this.sendUntilErrors = {
        hasError: false,
      };
      switch (this.selectedSendUntil.value) {
        case 2:
          this.showNumberTransfers = true;
          break;
        case 1:
          this.isLastTransferDate = true;
          this.validateEndDate();
          break;
      }
    }
  }

  /** Triggered when the Radio option for Amounts changes */
  amountOptionsChange(): void {
    // Transfer to Loan Account (No mortgage)
    if (
      this.selectedToAccount.isLoan &&
      !this.isMortgage(this.selectedToAccount)
    ) {
      // If “Minimum Payment” is selected, then field is to be pre-filled
      // with the current minimum amount due of the “To” Account.
      // The field is not editable by the user.
      // This field should display the total payment amount as well as a breakdown
      // of the principal and interest. [$Amount Due ($xx.xx Pri, $xx.xx Int)].
      if (this.selectedAmountOption.value === AmountOption.MINIMUM_PAYMENT) {
        this.amountEditable = false;
        this.amount = this.selectedToAccount.minimumAmountDue;
      } else if (
        this.selectedAmountOption.value === AmountOption.OUTSTANDING_BALANCE
      ) {
        // If “Outstanding Balance” is selected, then this field is to be pre-filled
        // with the current total outstanding balance of the “To” Account.
        // The field is not editable by the user.
        this.amountEditable = false;
        this.amount = this.selectedToAccount.outstandingBalance;
      } else {
        this.amountEditable = true;
      }
    } else if (
      this.selectedToAccount.productType.toLowerCase() ==
      'overdraft line of credit'
    ) {
      // Transfer to Overdraft Line of Credit
      // If “Minimum Payment” is selected, then field is to be pre-filled
      // with the current minimum amount due of the “To” Account [$xx.xx].
      // The field is not editable by the user.
      // This field should display the total minimum payment amount.
      if (this.selectedAmountOption.value === AmountOption.MINIMUM_PAYMENT) {
        this.amountEditable = false;
        this.amount = this.selectedToAccount.minimumAmountDue;
      } else if (
        this.selectedAmountOption.value === AmountOption.OUTSTANDING_BALANCE
      ) {
        // b. If “Outstanding Balance” is selected, then this field is to be pre-filled
        // with the current total outstanding balance of the “To” Account.
        // The field is not editable by the user.
        this.amountEditable = false;
        this.amount = this.selectedToAccount.outstandingBalance;
      } else {
        this.amountEditable = true;
      }
    }

    this.setValidationAmount();
  }

  /** Sends the transfer funds to service */
  submit(): void {
    if (!this.isManagedPortfoliosEnabled && this.transferHasAxosInvest) {
      this.windowService.open(this.axosInvestUrl, '_blank');
    } else if (this.transferHasRiaAccounts && this.isToRIA) {
      //Perform a IRA Individual Retirement Account (IRA) Transfer
      this.resetErrors();
      if (this.setIraValidations()) {
        this.transferFunds();
      } else {
        this.serviceHelper.scrollTo('udb-transfer');
      }
    } else {
      this.resetErrors();
      const isValid = this.setValidations();
      if (
        this.isTransferFromIraTrading() &&
        !this.validateTradingIraAmounts()
      ) {
        return;
      }
      // Go to new Loan Payment page
      if (this.selectedToAccount?.isLoan) {
        this.redirectToLoanPaymentValidation();
      } else {
        if (
          isValid &&
          this.selectedFromAccountValid &&
          this.selectedToAccountValid &&
          this.selectedFrequencyValid
        ) {
          const amount = this.selectedToAccount.isLoan
            ? this.totalAmount
            : this.validIraTransaction()
            ? this.withholdNetValue
            : this.amount;
          const selectedFromAccount = this.getSelectedFromAccountForReceipt(
            this.selectedFromAccount
          );
          const selectedToAccount = this.getSelectedToAccountForReceipt(
            this.selectedToAccount
          );

          this.buildPreTransferConfirmationMessage();
          const modal = this.uibModalService.open({
            templateUrl:
              'transfers/transfer-funds/pre-transfer-confirmation-modal/pre-transfer-confirmation-modal.tpl.html',
            windowClass: 'modal-service',
            controller: 'PreTransferConfirmationModal',
            controllerAs: 'ctrl',
            backdrop: 'static',
            resolve: {
              headerStr: () => {
                return this.modalHeader;
              },
              footerStr: () => {
                return this.modalFooter;
              },
              amount: () => {
                return this.filterService('currency')(amount);
              },
              selectedFrom: () => {
                return selectedFromAccount;
              },
              selectedTo: () => {
                return selectedToAccount;
              },
            },
          });
          modal.result.then((result: boolean) => {
            if (result) {
              if (this.ngRedux.getState().funding.isRunning) {
                this.transactionService.logUserAction(
                  FundingAmountHistoricalAction(
                    this.filterService('currency')(amount)
                  )
                );
              }
              this.transferFunds(
                this.selectedToAccount.accountType.toLowerCase() === 'loan'
              );
            }
          });
        } else {
          this.serviceHelper.scrollTo('udb-transfer');
        }
      }
    }
  }

  /**
   * validate number of transfers if selected and while the user is typing
   */
  validateNumberOfTransfers() {
    this.isValidForRecurrent();
  }

  showCalendar(): void {
    if (!this.isFromTrading && !this.isToTrading) {
      const dp = $('[date-range-picker]');
      if (!dp.data('daterangepicker').isShowing)
        dp.data('daterangepicker').show();
      else dp.data('daterangepicker').hide();
    }
  }

  validateEndDate() {
    if (this.isLastTransferDate) {
      const date = this.date?.toDate();
      const endDate = this.endDate?.toDate();
      this.datePickerEndDate.options.minDate = date;
      this.datePickerEndDate.options.maxDate = this.datePicker?.options.maxDate;
      if (moment(date).isAfter(endDate)) {
        this.datePickerEndDate.date = { startDate: date, endDate: date };
        this.endDate = moment(date);
        const dateString = moment(date).format('MM/DD/YY');
        this.timeoutService(() => {
          $('#endDate').val(dateString);
        }, 50);
      } else {
        this.datePickerEndDate.date = { startDate: endDate, endDate };
        this.endDate = moment(endDate);
        const dateString = moment(endDate).format('MM/DD/YY');
        this.timeoutService(() => {
          $('#endDate').val(dateString);
        }, 50);
      }
    }
  }

  /** Open/close Federal Income Tax Withholding clause  */
  showFITW() {
    this.isShowFITW = !this.isShowFITW;
  }

  /** Open/close Federal Income Tax Withholding clause  */
  showSITW() {
    this.isShowSITW = !this.isShowSITW;
  }

  /** Open/close IRA disclosure clause  */
  showDisclosure() {
    this.isDisclosure = !this.isDisclosure;
  }

  /** Check if the amount withhold is at least $10  */
  meetsMinValueAmount(): boolean {
    return (
      this.withholdPercentageValue >= 10 &&
      this.withholding === 0 &&
      this.withholdAmountValue < 10
    );
  }

  meetsMinValueStateAmount(): boolean {
    return (
      this.stateWithholdPercentageValue >= 1 &&
      this.stateWithhold === 0 &&
      this.stateWithholdAmountValue < 1
    );
  }

  /** Calculate withhold value amount depends percentage or amount radio selected */
  calculateWithholdValue() {
    if (this.withholding === 0) {
      if (this.withholdPercentageValue === 0) this.withholdPercentageValue = 10;

      const base = +this.amount * +this.withholdPercentageValue;
      this.withholdAmountValue = base / 100;
      if (this.isTransferFromIraTradingEnabled) {
        this.withholdNetValue =
          +this.amount -
          this.withholdAmountValue -
          this.stateWithholdAmountValue;
      } else {
        this.withholdNetValue = +this.amount - this.withholdAmountValue;
      }

      return;
    }

    if (this.withholding === 1) {
      this.withholdAmountValue = this.withholdAmountValue || 10;

      if (this.withholdAmountValue > +this.amount * 0.99) {
        this.withholdAmountValue = +this.amount * 0.99;
      }

      if (this.isTransferFromIraTradingEnabled) {
        this.withholdNetValue =
          +this.amount -
          +this.withholdAmountValue -
          this.stateWithholdAmountValue;
      } else {
        this.withholdNetValue = +this.amount - +this.withholdAmountValue;
      }

      this.withholdPercentageValue = Math.round(
        (+this.withholdAmountValue * 100) / +this.amount
      );

      return;
    }

    this.withholdAmountValue = 0;
    this.withholdPercentageValue = 0;
    if (this.isTransferFromIraTradingEnabled) {
      this.withholdNetValue = +this.amount - this.stateWithholdAmountValue;
    } else {
      this.withholdNetValue = +this.amount;
    }
  }

  calculateStateWithholdValue() {
    if (this.stateWithhold === 0) {
      if (this.stateWithholdPercentageValue === 0)
        this.stateWithholdPercentageValue = 5;
      const base = +this.amount * +this.stateWithholdPercentageValue;
      this.stateWithholdAmountValue = base / 100;
      this.withholdNetValue =
        +this.amount - this.withholdAmountValue - this.stateWithholdAmountValue;

      return;
    }

    if (this.stateWithhold === 1) {
      this.stateWithholdAmountValue = this.stateWithholdAmountValue || 1;

      if (this.stateWithholdAmountValue > +this.amount * 0.99) {
        this.stateWithholdAmountValue = +this.amount * 0.99;
      }

      this.withholdNetValue =
        +this.amount -
        +this.withholdAmountValue -
        this.stateWithholdAmountValue;
      this.stateWithholdPercentageValue = Math.round(
        (+this.stateWithholdAmountValue * 100) / +this.amount
      );

      return;
    }

    this.stateWithholdAmountValue = 0;
    this.stateWithholdPercentageValue = 0;
    this.withholdNetValue = +this.amount - this.withholdAmountValue;
  }

  /** validate when show withhold values */
  validIraTransaction(): boolean {
    this.isValidIraTransaction = !!(
      (this.selectedFromAccount?.isIra || this.isTransferFromIraTrading()) &&
      (this.selectedFromAccount?.isIra || this.isTransferFromRetirementRia()) &&
      this.fromAccountIsValidIra() &&
      !this.selectedToAccount?.isIra
    );

    return this.isValidIraTransaction;
  }

  cleanupInput(): void {
    if (this.isRegularMonthly)
      this.regularMonthlyPayment = this.selectedToAccount.paymentAmount;
    else this.regularMonthlyPayment = 0;
  }

  loadAxosInvestAccounts(accArray: 'fromAccountsArray' | 'toAccountsArray') {
    if (this.featureFlagService.isAxosInvestActive() && this.hasAxosInvest) {
      if (this.milestoneDetail && !this.milestoneDetail.isClosed) {
        this.setInvestementAccount(this.milestoneDetail, accArray);
      } else if (!this.loadedMilestonesDetail) {
        // if it hasn't been loaded yet, drop the previous watcher and add new one to add the elements to the list once it loads.
        this.store
          .select(getGoalSummary)
          .pipe(take(2))
          .subscribe(() =>
            this.setInvestementAccount(this.milestoneDetail, accArray)
          );
      }
    }
  }

  setInvestementAccount(
    milestoneDetail: any,
    accountsArray: 'fromAccountsArray' | 'toAccountsArray'
  ): void {
    if (
      !milestoneDetail ||
      !milestoneDetail.milestones ||
      milestoneDetail.milestones.length === 0 ||
      milestoneDetail.hasError
    ) {
      return;
    }
    let investmentAccounts = [];
    investmentAccounts = this.featureFlagService.isManagedPortfoliosEnabled()
      ? this.getInvestmentsAccounts(milestoneDetail)
      : this.getOldInvestmentsAccount(milestoneDetail);

    if (this.selectedFromAccount?.isAxosInvest) {
      investmentAccounts = investmentAccounts.filter(
        x => x.routingNumber !== this.selectedFromAccount?.routingNumber
      );
    }
    if (investmentAccounts.length > 0) {
      this[accountsArray] = [
        ...this[accountsArray].filter(acc => acc.Type === 'Internal Accounts'),
        ...this[accountsArray].filter(
          acc => acc.Type === 'Investment Accounts' && !acc.isAxosInvest
        ),
        ...investmentAccounts,
        ...this[accountsArray].filter(acc => acc.Type === 'Non-Axos Accounts'),
      ];
    }
  }

  getOldInvestmentsAccount(milestoneDetail: any): NewOlbAccount[] {
    if (
      milestoneDetail &&
      milestoneDetail.milestones &&
      milestoneDetail.milestones.length > 0
    ) {
      const { length: milestones } = milestoneDetail.milestones;
      const milestoneAccount = {
        name: AxosInvestHelper.MANAGED_PORTFOLIO,
        isAxosInvest: true,
        bankName: `${milestones} Milestone${milestones > 1 ? 's' : ''}`,
        availableBalance: milestoneDetail.totalBalance,
        displayFromTypeName: AxosInvestHelper.MANAGED_PORTFOLIO,
        displayName:
          AxosInvestHelper.MANAGED_PORTFOLIO +
          ` (Total Value: ${this.filterService('currency')(
            milestoneDetail.totalBalance
          )})`,
        nickname: AxosInvestHelper.MANAGED_PORTFOLIO,
      };

      return [{ Type: 'Investment Accounts', ...milestoneAccount }];
    } else return null;
  }

  getInvestmentsAccounts(milestoneDetail: MilestoneDetailVm): NewOlbAccount[] {
    const olbAccounts = [];
    if (
      milestoneDetail &&
      milestoneDetail.accounts &&
      milestoneDetail.accounts.length > 0
    ) {
      milestoneDetail.milestones
        .filter(m => !m.isClosed)
        .forEach(milestone => {
          if (milestone.type !== MilestoneType.Retirement) {
            const milestoneAccounts = this.milestoneToOlbAccount(milestone);
            olbAccounts.push(...milestoneAccounts);
          } else {
            const retirementAccount = this.retirementMilestoneToOlbAccount(
              milestone
            );
            olbAccounts.push(retirementAccount);
          }
        });

      if (milestoneDetail.wallet) {
        const wallet = this.milestoneToOlbAccount(milestoneDetail.wallet);
        olbAccounts.push(...wallet);
      }
    }

    return olbAccounts;
  }

  redirectToRecurringDepositMp() {
    this.stateService.go('udb.axosinvest.transferfunds', {
      id:
        this.selectedFromAccount?.routingNumber ||
        this.selectedToAccount?.routingNumber,
      transferType: TransferType.RecurrentDeposit,
    });
  }

  getSelectedFromAccountForReceipt(account: NewOlbAccount) {
    if (account.isExternal) {
      return account.nickname
        ? `${account.nickname} *${account.accountMask}`
        : account.displayFromTypeName;
    } else {
      if (this.isFromTrading) {
        return account.hasNickname
          ? account.nickname
          : account.displayFromTypeName;
      } else {
        return account.displayFromTypeName ?? account.nickname;
      }
    }
  }

  getSelectedToAccountForReceipt(account: NewOlbAccount) {
    if (account.isExternal) {
      return account.nickname
        ? `${account.nickname} *${account.accountMask}`
        : account.displayToTypeName;
    } else if (this.isToTrading || account.isRia) {
      return account.nickname ?? account.displayToTypeName;
    } else {
      return account.displayToTypeName ?? account.nickname;
    }
  }

  //To distinguish the Axos Ira accounts to show/hide elements in the UI
  isIraAxosAccount(account: any): boolean {
    let isIraAxos =
      account?.Type == 'Internal Accounts' && account?.isIra === true;

    return isIraAxos;
  }

  isTransferToIraTrading() {
    return (
      this.isIraEnhDiraFlagActive && this.isIraAccount(this.selectedToAccount)
    );
  }

  isTransferToIraSupportedAccounts() {
    return (
      this.isIraEnhDiraFlagActive &&
      this.isIraSupportedAccount(this.selectedToAccount)
    );
  }
  isTransferFromIraTrading() {
    this.isTransferFromIraTradingEnabled =
      this.isIraEnhDiraFlagActive &&
      this.isIraAccount(this.selectedFromAccount);

    return this.isTransferFromIraTradingEnabled;
  }

  isTransferFromRetirementRia() {
    this.isRiaDistribution =
      this.selectedFromAccount?.isRia && this.selectedFromAccount?.isIra;

    return this.isRiaDistribution;
  }

  isTransferToNonRetirementRia() {
    return this.selectedToAccount?.isRia && !this.selectedToAccount?.isIra;
  }

  isTransferToIra() {
    return this.selectedToAccount?.isIra;
  }

  isTransferToRiaNonIra() {
    if (this.isRiaPilotContributionFrequencyActive) {
      return this.selectedToAccount?.isRia && !this.selectedToAccount?.isIra;
    }
    return false;
  }

  getMaxAmounttradingIra(): number {
    return this.userAge > 50
      ? Number(this.cyear?.maxAmountOlderThan50)
      : Number(this.cyear?.maxAmountMinor50);
  }

  showStateModal() {
    this.matDialog.open(FederalTaxesModalComponent, {
      disableClose: true,
    });
  }

  showDistributionModal() {
    this.matDialog
      .open(RiaDistributionModalComponent)
      .afterClosed()
      .subscribe(() => {
        this.clearFields();
      });
  }

  showContributionDetailsModal() {
    this.matDialog.open(IraContributionComponent);
  }

  sendCode() {
    this.isBusy = true;
    const request: MultiFactorRequest = {
      username: window.sessionStorage.getItem('username'),
      authenticationMethod: AuthMethod.Sms,
    };
    this.multifactorService
      .challenge(request)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: res => {
          this.otp = res.data.otp;
          this.step = 3;
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }

  validateOtp(code: string) {
    this.isBusy = true;
    const request: MultiFactorRequest = {
      username: window.sessionStorage.getItem('username'),
      otp: code,
      authenticationMethod: AuthMethod.Sms,
    };
    delete this.accessCodeError;
    this.multifactorService
      .validateOtp(request)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: () => {
          this.makeExternalTransfer(
            this.selectedToAccount.accountType.toLowerCase() === 'loan'
          );
        },
        error: (err: HttpErrorResponse) => {
          if (err.status >= 500) {
            this.serviceHelper.errorHandler(err, true);
          }
          this.accessCodeError =
            err.error?.message ||
            'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
        },
      });
  }

  private redirectToLoanPaymentValidation(): void {
    if (this.isPayoff()) {
      this.loanQuotePayoffProcess();
    } else {
      this.redirectToLoanPaymentNormal();
    }
  }

  private redirectToLoanPaymentNormal(): void {
    sessionStorage.setItem('isPayoff', 'false');
    this.stateService.go('udb.transfers.loanPayment', {
      fromAccountId: this.selectedFromAccount.id,
      toAccountId: this.selectedToAccount.id,
    });
  }

  private isPayoff(): boolean {
    return (
      this.featureFlagService.isLoanPayOffFlagActive() &&
      this.selectedToAccount.canPayoffLoan &&
      this.selectedToAccount.isOpen &&
      this.loanDueDateValidation(this.selectedToAccount.loanDueDate) &&
      !this.selectedToAccount.isClosed // Considering both flags (isOpen and isClosed) since Admin portal currently does not validate each other to avoid a yes/yes scenario
    );
  }

  private loanQuotePayoffProcess(): void {
    this.subSink.sink = this.newAccountsService
      .getActiveLoanPayoffQuote(this.selectedToAccount.id)
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: response => {
          const today = moment();
          const loanQuoteDate = moment(response.data?.dateSelected);
          if (response.data !== null && loanQuoteDate >= today) {
            this.getPayoffDetailsToRedirect(response.data);
          } else {
            this.redirectToLoanPaymentNormal();
          }
        },
      });
  }

  private getPayoffDetailsToRedirect(loanPayoffQuote: LoanPayoffQuote): void {
    this.subSink.sink = this.newAccountsService
      .calculatePayoff(
        this.selectedToAccount.accountNumber,
        loanPayoffQuote.dateSelected.toString()
      )
      .subscribe({
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
        next: response => {
          this.stateService.go('udb.transfers.loanPayment', {
            fromAccountId: this.selectedFromAccount.id,
            toAccountId: this.selectedToAccount.id,
            isPayoff: true,
            tooltip: this.getLoanPayoffToolTip(response.data),
            payoffCalculated: response.data,
          });
        },
      });
  }

  private getLoanPayoffToolTip(
    payoffDetailsResponse: PayoffDetailsResponse
  ): string {
    return `<div>
            <b>Outstanding Balance</b>
          </div>
          <div>${this.filterService('currency')(
            payoffDetailsResponse.currentBalance
          )}</div>
          <div>
            <b>Accrued Interest</b>
          </div>
          <div>${this.filterService('currency')(
            payoffDetailsResponse.accruedInterest
          )}</div>
          <div>
            <b>Secondary Accrued Interest</b>
          </div>
          <div>${this.filterService('currency')(
            payoffDetailsResponse.secondaryAccruedInterest
          )}</div>
          <div>
            <b>Late Charge Due Amount</b>
          </div>
          <div>${this.filterService('currency')(
            payoffDetailsResponse.lateChargeDueAmount
          )}</div>`;
  }

  private loanDueDateValidation(date: Date): boolean {
    const limitDate = moment.utc().subtract(90, 'days');
    return moment(date).isAfter(limitDate);
  }

  private setupMaskedCellPhone() {
    this.subSink.sink = this.store
      .select(getProfileDetails)
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.maskedCellPhone = maskPhone(data.cellPhone1.number);
      });
  }

  private isIraAccount(account: any): boolean {
    let isIraTrading =
      +account?.accountTypeCode === ClearingAccountType.IraTraditional ||
      +account?.accountTypeCode === ClearingAccountType.IraRoth ||
      +account?.type === ClearingAccountType.IraTraditional ||
      +account?.type === ClearingAccountType.IraRoth;

    let isIraRia =
      account?.accountType === 'AAS' &&
      account.isIra === true &&
      account.isRia === true;

    let isIraAxos =
      account?.Type == 'Internal Accounts' && account?.isIra === true;

    return isIraTrading || isIraRia || isIraAxos;
  }

  private isIraSupportedAccount(account: any): boolean {
    let isIraTrading =
      +account?.accountTypeCode === ClearingAccountType.IraTraditional ||
      +account?.accountTypeCode === ClearingAccountType.IraRoth ||
      +account?.type === ClearingAccountType.IraTraditional ||
      +account?.type === ClearingAccountType.IraRoth;

    let isIraRia =
      account?.accountType === 'AAS' &&
      account.isIra === true &&
      account.isRia === true;

    return isIraTrading || isIraRia;
  }

  private milestoneToOlbAccount(milestone: Milestone) {
    return milestone.accounts.map(milestoneAccount => {
      const account: NewOlbAccount = {
        name: milestoneAccount.name,
        isAxosInvest: true,
        availableBalance: milestoneAccount.accountValue,
        displayFromTypeName: `${AxosInvestHelper.MANAGED_PORTFOLIO} - ${milestone.name}`,
        displayName: `${AxosInvestHelper.MANAGED_PORTFOLIO} - ${
          milestone.name
        } (Total Value: ${this.filterService('currency')(
          milestoneAccount.accountValue
        )})`,
        nickname: `${AxosInvestHelper.MANAGED_PORTFOLIO} - ${milestone.name}`,
        Type: 'Investment Accounts',
        accountNumber: milestoneAccount.accountNumber,
        routingNumber: milestone.id,
        productType: AxosInvestHelper.MANAGED_PORTFOLIO,
        accountType: AxosInvestHelper.MANAGED_PORTFOLIO,
        goalAccountType: milestoneAccount.type,
      };

      return account;
    });
  }

  private retirementMilestoneToOlbAccount(milestone: Milestone) {
    const account: NewOlbAccount = {
      name: milestone.name,
      isAxosInvest: true,
      availableBalance: milestone.currentValue,
      displayFromTypeName: `${AxosInvestHelper.MANAGED_PORTFOLIO} - ${milestone.name}`,
      displayName: `${AxosInvestHelper.MANAGED_PORTFOLIO} - ${
        milestone.name
      } (Total Value: ${this.filterService('currency')(
        milestone.currentValue
      )})`,
      nickname: `${AxosInvestHelper.MANAGED_PORTFOLIO} - ${milestone.name}`,
      Type: 'Investment Accounts',
      accountNumber: milestone.accounts[0].accountNumber,
      routingNumber: milestone.id,
      productType: AxosInvestHelper.MANAGED_PORTFOLIO,
      accountType: AxosInvestHelper.MANAGED_PORTFOLIO,
    };

    return account;
  }

  /** return true when withholding value is correct */
  private wrongIraValue(): boolean {
    if (this.isTransferFromIraTrading()) {
      const withholding =
        (this.withholding < 2 &&
          (this.withholdPercentageValue < 10 ||
            this.withholdAmountValue < 10)) ||
        this.withholdNetValue == 0;
      const withholdingState =
        (this.stateWithhold < 1 &&
          (this.stateWithholdPercentageValue < 1 ||
            this.stateWithholdAmountValue < 1)) ||
        this.withholdNetValue == 0;

      return withholding || withholdingState;
    } else {
      return (
        (this.withholding < 2 &&
          (this.withholdPercentageValue < 10 ||
            this.withholdAmountValue < 10)) ||
        this.withholdNetValue == 0
      );
    }
  }
  /** Check valid Ira amounts and disclosure checked */
  private validateWhenIra(): boolean {
    if (!this.acceptDisclosure && !this.wrongIraValue()) {
      this.errorMessage =
        'Please confirm you understand the potential tax implication of this withdrawal.';
    }

    return this.acceptDisclosure && !this.wrongIraValue();
  }

  private validateTradingIraAmounts(): boolean {
    if (this.withholdNetValue < 0) {
      this.errorMessage =
        'Sum of Federal and State taxes can not be greater than the amount.';
      this.serviceHelper.scrollToTop();

      return false;
    }

    return true;
  }

  /** go to add external */
  private goToAddExternalAccounts() {
    this.redirectStateService.setOriginalState(this.stateService.current.name);
    this.stateService.go(
      this.featureFlagService.isYodleeForFundingActive()
        ? 'udb.dashboard.account-aggregation'
        : 'udb.accounts.add-external',
      {
        isAccountAggregationFlow: false,
        isMoveMoneyFlow: true,
        flow: this.flowTypeEnum.MoveMoney,
      }
    );
  }

  /** When init and every time the state changes this is executed reassigning the funding state */
  private initialize() {
    this.fundingState = this.ngRedux.getState().funding;
    if (
      !this.fundingState.isRunning &&
      (this.fundingState.accountId !== 0 ||
        this.fundingState.depositAmount !== 0)
    ) {
      this.ngRedux.dispatch(FundingActions.setupAccountId(0));
      this.ngRedux.dispatch(FundingActions.changeAmount(0));
      this.store.dispatch(changeFundingAmount({ payload: 0 }));
    }

    /* readonly */
    if (this.isSiteInReadOnly) {
      this.scope.$watchGroup(
        [
          '$tf.selectedFromAccount',
          '$tf.selectedToAccount',
          '$tf.selectedFrequency',
          '$tf.date',
        ],
        () => {
          this.applyRulesForReadOnly();
        }
      );
    }

    this.scope.$watchGroup(
      ['$tf.withholdAmountValue', '$tf.stateWithholdAmountValue'],
      () => {
        if (this.isTransferFromIraTrading()) {
          if (this.amount > this.federalWithHoldMin) {
            this.calculateWithholdValue();
          }

          if (this.amount > this.stateWithHoldMin) {
            this.calculateStateWithholdValue();
          }
        } else {
          if (this.amount > this.federalWithHoldMin) {
            this.calculateWithholdValue();
          }
        }
      }
    );

    this.scope.$watch('$tf.date', () => {
      this.validateEndDate();
    });
  }

  private applyRulesForReadOnly() {
    let isDisabled = false;
    if (this.selectedFromAccount && this.selectedToAccount) {
      if (
        !this.selectedFromAccount.isExternal &&
        !this.selectedToAccount.isExternal &&
        !this.selectedToAccount.isLoan
      ) {
        if (
          this.selectedFrequency &&
          this.selectedFrequency.value === Frequency.ONETIME
        ) {
          const today = this.dateHelper.normalizeDate(moment());
          isDisabled = moment(today).isSame(this.date);
        }
      }
    }
    this.isReadOnly = isDisabled;
  }

  private validateMpDeposit() {
    if (
      !this.transferMpValue &&
      (this.transferFromRetirementMp?.length > 0 ||
        this.transferToRetirementMp?.length > 0)
    ) {
      this.errorMessage = 'Please select a Retirement Account Type';

      return false;
    } else {
      this.errorMessage = null;
    }

    return true;
  }

  private validateTransferFromDisclosureMp() {
    let valid = false;
    if (!this.acceptDisclosureMp) {
      this.errorMessage =
        'Please confirm you understand the potential tax implication of this withdrawal.';
      valid = false;
    } else if (this.amount > this.transferMpValue?.accountValue) {
      this.amountErrors = {
        hasError: true,
        errorDescription: 'Insufficient funds available',
      };
      valid = false;
    } else {
      valid = true;
    }

    return valid;
  }

  private validateIraDisclosureMp() {
    if (!this.acceptIraDisclosureMp) {
      if (!this.isTransferToIraTrading) {
        this.errorMessage =
          'Please confirm you read and understood the IRA Disclosure.';
      } else {
        this.errorMessage =
          'Please confirm you have provided accurate information on this page.';
      }
    }

    return this.acceptIraDisclosureMp;
  }

  private validateContributionYear() {
    if (this.selectedContributionYear === undefined) {
      return false;
    } else {
      return true;
    }
  }

  /** Load frequencies from the ok confirmation */
  private loadFrequencies() {
    this.fillFrequencies(this.selectedFromAccount, this.selectedToAccount);
    this.selectedFrequency = this.frequencies[0];
    this.frequencyChange();
    this.setCalendarOptions(this.selectedFrequency.value);
  }

  private buildPreTransferConfirmationMessage(): void {
    this.isInternalSameDay = false;
    const isCurrentDate = moment.utc(this.date).isSame(moment(), 'd');
    // Internal one time
    if (
      isCurrentDate &&
      this.selectedFrequency.value === Frequency.ONETIME &&
      (!this.hasExternalAccount ||
        (this.transferHasAxosInvest && this.isManagedPortfoliosEnabled))
    ) {
      this.isInternalSameDay = true;
      this.modalHeader = 'You are about to transfer:';
      this.modalFooter = '';
    } else {
      this.modalHeader = `You are about to schedule a ${this.selectedFrequency.label} transfer of:`;
      if (this.selectedFrequency.value === Frequency.ONETIME) {
        this.modalFooter = `Transfer Date: ${moment(this.date).format(
          'MM/DD/YYYY'
        )}`;
      } else
        this.modalFooter = `Begins on ${moment(this.date).format(
          'MM/DD/YYYY'
        )}`;
    }
  }

  private setValidations(isValid = false): boolean {
    // From Account Validation
    this.selectedFromAccountValid = !(this.selectedFromAccount === null);
    // To Account Validation
    this.selectedToAccountValid = !(this.selectedToAccount === null);
    // Frequency Validation
    this.selectedFrequencyValid = !(this.selectedFrequency === null);
    // Amount Validation
    this.setValidationAmount();

    if (!this.selectedSendUntil) {
      this.sendUntilErrors = {
        hasError: true,
        errorDescription: 'Select an option',
      };
    }

    if (!this.numberTransfers) {
      this.numberTransfersErrors = {
        hasError: true,
        errorDescription: 'Please enter number of transfers',
      };
    }

    if (
      this.selectedToAccount?.isDeposit ||
      this.selectedToAccount?.isTrading ||
      this.selectedToAccount?.isRia
    ) {
      isValid = this.isValidForDemandDeposit();
    } else if (
      this.selectedToAccount?.isLoan &&
      !this.isMortgage(this.selectedToAccount)
    ) {
      // To Loan Account (No Mortgage) validations
      isValid = this.isValidForLoanAccount();
    } else if (
      this.selectedToAccount !== null &&
      this.selectedToAccount.productType?.toLowerCase() ===
        'overdraft line of credit'
    ) {
      isValid = this.isValidForLineOfCredit();
    } else if (
      this.selectedToAccount !== null &&
      this.isMortgage(this.selectedToAccount)
    ) {
      isValid = this.isValidForMortgage();
    } else if (this.selectedToAccount?.isAxosInvest) {
      isValid = this.isValidForAxosInvest();
    }

    if (
      this.selectedFrequency !== null &&
      this.selectedFrequency.value !== Frequency.ONETIME
    ) {
      if (this.selectedSendUntil.value === 2) {
        isValid = isValid && this.isValidForRecurrent();
      }
    }

    if (this.selectedFromAccountValid && this.validIraTransaction()) {
      isValid = isValid && this.validateWhenIra();
    }

    if (
      this.selectedFromAccount.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
      this.validateMilestoneRetirementAccount(
        this.selectedFromAccount.routingNumber
      )
    ) {
      isValid =
        isValid &&
        this.validateMpDeposit() &&
        this.validateTransferFromDisclosureMp();
    } else if (
      this.selectedToAccount?.productType ===
        AxosInvestHelper.MANAGED_PORTFOLIO &&
      this.validateMilestoneRetirementAccount(
        this.selectedToAccount?.routingNumber
      )
    ) {
      this.contributionYearIsValid = this.validateContributionYear();
      if (this.retirementIraSelected) {
        isValid =
          isValid &&
          this.validateMpDeposit() &&
          this.validateIraDisclosureMp() &&
          this.contributionYearIsValid;
      } else {
        isValid =
          isValid && this.validateMpDeposit() && this.validateIraDisclosureMp();
      }
    }

    if (this.isTransferToIraTrading()) {
      isValid = isValid && this.validateIraDisclosureMp();
    }

    if (this.amountErrors?.hasError) {
      isValid = false;
    }
    return isValid;
  }

  private setIraValidations(isValid = false): boolean {
    // From Account Validation
    this.selectedFromAccountValid = !(this.selectedFromAccount === null);
    // To Account Validation
    this.selectedToAccountValid = !(this.selectedToAccount === null);

    this.setValidationAmount();

    //RIA Non Retirement Accounts does not need a disclosure validation
    var disclosure = this.selectedToAccount.isIra
      ? this.validateIraDisclosureMp()
      : true;
    isValid = !this.amountErrors.hasError && disclosure;

    return isValid;
  }

  private goToReceipt(
    template: ReceiptTemplate,
    confirmationNumber: string
  ): void {
    this.isBusy = true;
    this.hasFinished = true;
    const receiptTemplate = angular.copy(template);
    const amount = this.selectedToAccount.isLoan
      ? this.filterService('currency')(this.totalAmount)
      : this.filterService('currency')(this.amount);
    const frequency = this.selectedFrequency.label;
    const selectedFromAccount = this.getSelectedFromAccountForReceipt(
      this.selectedFromAccount
    );
    const selectedToAccount = this.getSelectedToAccountForReceipt(
      this.selectedToAccount
    );
    const today = moment(new Date());
    const sendOn = this.date ? moment(this.date).format('MM/DD/YYYY') : '';
    const lastTransferDate = this.endDate
      ? moment(this.endDate).format('MM/DD/YYYY')
      : null;
    const contributionYear = this.selectedContributionYear
      ? this.selectedContributionYear.toString()
      : null;
    const retirementAccountName = this.transferMpValue
      ? this.transferMpValue.name
      : null;

    let confirmationEmail = '';
    let additionalFootnote = '';
    const details = [...receiptTemplate.transactions[0].details];
    const userProfile = this.loadUserProfileHelper.getUserProfile();
    receiptTemplate.isIra =
      this.selectedFromAccount.isIra || this.selectedToAccount.isIra;
    switch (receiptTemplate.type) {
      /**
       *  Internal - Internal: One-time on same day.
       */
      case 'MoneyTransfer_In_OneTime': {
        console.log('this. should be defined');
        details[0].value = selectedFromAccount;
        details[1].value = selectedToAccount;
        details[2].value = amount;
        details[3] = {
          description: 'Send On',
          value: sendOn === '' ? today.format('MM/DD/YYYY') : sendOn,
        };
        details[4] = {
          description: 'Frequency',
          value: frequency,
        };
        break;
      }
      /**
       *  Internal - Internal: One-time scheduled.
       *  Internal - External: One-time on same day and scheduled.
       *  External - Internal: One-time on same day and scheduled.
       */
      case 'MoneyTransfer_Ex_OneTime': {
        details[0].value = selectedFromAccount;
        details[1].value = selectedToAccount;
        details[2].value = amount;
        details[3].value = sendOn;
        details[4].value = frequency;

        if (
          !this.isFromTrading &&
          !this.isToTrading &&
          !(this.selectedFromAccount.isIra || this.selectedToAccount.isIra)
        ) {
          additionalFootnote += `To modify or delete this transfer, go to the <a href='/Transfers/SchedulerTransfers'>Scheduled Transfers</a> page`;
        }
        if (
          this.isFromExternal ||
          this.isToExternal ||
          this.isFromTrading ||
          this.isToTrading
        ) {
          confirmationEmail = userProfile.primaryEmail;
        }
        break;
      }

      /**
       * DemandDeposit - Trading IRA
       */
      case 'MoneyTransfer_Trading_Ira': {
        details[0].value = amount;
        details[1].value = selectedFromAccount;
        details[2].value = selectedToAccount;
        details[3].value = this.cyear.year.toString();
        details[4].value = sendOn;
        details[5].value = confirmationNumber;
        confirmationEmail = userProfile.primaryEmail;
        receiptTemplate.isIra = true;
        break;
      }

      case 'MoneyTransfer_RIA_Contribution': {
        details[0].value = amount;
        details[1].value = selectedFromAccount;
        details[2].value = selectedToAccount;
        details[3].value = frequency;
        details[4].value = sendOn;
        details[5].value = confirmationNumber;
        break;
      }

      /**
       * From Trading IRA - To:
       * Internal - Checking|Saving - One Time
       * External - Checking|Saving - One Time
       */
      case 'MoneyTransfer_From_Trading_Ira': {
        details[0].value = selectedFromAccount;
        details[1].value = selectedToAccount;
        details[2].value = amount;
        details[3].value = this.filterService('currency')(
          this.withholdAmountValue
        );
        details[4].value = this.filterService('currency')(
          this.stateWithholdAmountValue
        );
        details[5].value = this.filterService('currency')(
          this.withholdNetValue
        );
        details[6].value = frequency;
        details[7].value = sendOn;
        details[8].value = confirmationNumber;
        receiptTemplate.isIra = true;
        confirmationEmail = userProfile.primaryEmail;

        break;
      }

      /**
       *  Internal - Internal: Recurring.
       *  Internal - External: Recurring.
       *  External - Internal: Recurring.
       */
      case 'MoneyTransfer_Recurring': {
        const sendUntil =
          this.selectedSendUntil.value == SendUntilOption.NumberOfTransfers
            ? this.numberTransfers + ' Transfers'
            : this.selectedSendUntil.label;

        details[0].value = selectedFromAccount;
        details[1].value = selectedToAccount;
        details[2].value = amount;
        details[3].value = sendOn;
        details[4].value = lastTransferDate;
        details[5].value = frequency;
        details[6].value = sendUntil;
        if (this.isFromExternal || this.isToExternal) {
          confirmationEmail = userProfile.primaryEmail;
        }
        break;
      }

      /**
       *  Internal - Internal: One-time on same day Invest.
       */
      case 'MoneyTransfer_AxosInvest_In_OneTime': {
        details[0].value = selectedFromAccount;
        details[1].value = selectedToAccount;
        details[2].value = retirementAccountName;
        details[3].value = contributionYear;
        details[4].value = amount;
        details[5].value = frequency;
        break;
      }

      /**
       *  Internal - Internal: Recurring Invest.
       *  Internal - External: Recurring Invest.
       *  External - Internal: Recurring Invest.
       */
      case 'MoneyTransfer_AxosInvest_Recurring': {
        const sendUntil =
          this.selectedSendUntil.value == SendUntilOption.NumberOfTransfers
            ? this.numberTransfers + ' Transfers'
            : this.selectedSendUntil.label;

        details[0].value = selectedFromAccount;
        details[1].value = selectedToAccount;
        details[2].value = retirementAccountName;
        details[3].value = contributionYear;
        details[4].value = amount;
        details[5].value = sendOn;
        details[6].value = lastTransferDate;
        details[7].value = frequency;
        details[8].value = sendUntil;
        if (this.isFromExternal || this.isToExternal) {
          confirmationEmail = userProfile.primaryEmail;
        }
        break;
      }
    }

    if (this.isFromExternal || this.isToExternal) {
      if (additionalFootnote) {
        additionalFootnote += `<br>Transferring funds may take as long as 5-7 business day to become available. `;
      } else {
        additionalFootnote = `Transferring funds may take as long as 5-7 business day to become available. `;
      }
    } else if (
      (this.selectedToAccount?.isAxosInvest ||
        this.selectedFromAccount?.isAxosInvest ||
        this.selectedToAccount?.isTrading ||
        this.selectedFromAccount?.isTrading) &&
      !this.selectedToAccount.isIra &&
      !this.selectedFromAccount.isIra
    ) {
      if (additionalFootnote) {
        additionalFootnote += `<br>Transferring funds may take as long as 3-4 business day to become available. `;
      } else {
        additionalFootnote = `Transferring funds may take as long as 3-4 business day to become available. `;
      }
    }

    if (this.retirementNonIraSelected) {
      details.splice(2, 0, {
        description: 'Retirement Account Type',
        value: retirementAccountName,
      });
    }

    // Add the redirection to Dashboard in Receipt when comes from funding
    if (
      this.fundingState.isRunning &&
      receiptTemplate.type !== 'MoneyTransfer_Trading_Ira' &&
      receiptTemplate.type !== 'MoneyTransfer_From_Trading_Ira'
    ) {
      // In the funding receipt, the confirmation number is displayed in the details table
      details.push({
        description: 'Confirmation Number',
        value: confirmationNumber,
      });
    }

    if (
      this.validIraTransaction() &&
      receiptTemplate.type !== 'MoneyTransfer_From_Trading_Ira'
    ) {
      details.splice(
        3,
        0,
        {
          description: 'Amount to withhold',
          value: this.filterService('currency')(this.withholdAmountValue),
        },
        {
          description: 'Net Amount',
          value: this.filterService('currency')(this.withholdNetValue),
        }
      );
    }

    if (
      (this.isFromTrading || this.isToTrading) &&
      !this.isTransferToIraTrading() &&
      !this.isTransferFromIraTrading()
    ) {
      receiptTemplate.showBanner = false;
      receiptTemplate.isIra = false;
      receiptTemplate.navigationBack = 'Move More Money';
      receiptTemplate.navigation = null;
    }

    if (
      (this.selectedToAccount.isIra && this.selectedToAccount.isRia) ||
      this.isToRIA
    ) {
      confirmationNumber = '';
      additionalFootnote += `Incoming funds processed before 2PM MT will be available same day. Funds processed after 2PM MT will be available the next business day.`;
    }

    if (this.selectedFromAccount.isIra || this.selectedToAccount.isIra) {
      confirmationEmail = userProfile.primaryEmail;
      receiptTemplate.header =
        !this.selectedFromAccount.isIra && this.selectedToAccount.isIra
          ? `You've Submitted a Request for an IRA Transfer from ${selectedFromAccount}`
          : `You've Submitted a Request for an IRA Transfer to {toAccount}`;
      !this.selectedToAccount.isIra &&
        (additionalFootnote += `<br>Want to track the progress of this request? Visit your <a href='/Messages/Inbox'>Message Center.</a>`);
    }

    const receiptSettings = {
      amount,
      fromAccount: selectedFromAccount,
      toAccount: selectedToAccount,
      confirmationEmail,
      confirmationNumber,
      transactions: [{ confirmationNumber, details }],
      additionalFootnote,
      navigateTo: receiptTemplate.isIra
        ? 'udb.accounts.dashboard'
        : receiptTemplate.type === 'MoneyTransfer_In_OneTime' ||
          receiptTemplate.type === 'MoneyTransfer_AxosInvest_In_OneTime' ||
          this.isFromTrading ||
          this.isToTrading ||
          this.isToRIA
        ? null
        : 'udb.transfers.schedulerTransfers',
      navigateBack:
        receiptTemplate.isIra ||
        this.isFromTrading ||
        this.isToTrading ||
        this.isToRIA
          ? 'udb.transfers.transferFunds'
          : 'udb.accounts.dashboard',
      isFromExternalAccount: this.selectedFromAccount.isExternal,
    };

    this.isBusy = false;

    this.stateService.go(
      this.fundingState.isRunning
        ? 'udb.funding.receipt'
        : 'udb.transfers.receipt',
      {
        settings: receiptSettings,
        template: receiptTemplate,
      }
    );
  }

  private getClearingAmountLimit(settingName): number {
    const setting = this.root['appSettings'].find(
      (ap: any) => ap.name === settingName
    );
    if (setting) {
      return Number(setting.value);
    }

    return 0;
  }

  private validateClearingAmountLimits() {
    let errorMessage = null;

    const settingName =
      this.selectedToAccount.isExternal || this.selectedFromAccount.isExternal
        ? AppSettings.ClearingExternalCap
        : AppSettings.ClearingInternalCap;

    const limit = this.getClearingAmountLimit(settingName);

    if (limit && this.amount > limit) {
      this.amountValid = false;
      errorMessage = `Amount may not exceed ${this.filterService('currency')(
        limit
      )}`;
    }

    return errorMessage;
  }

  private validateIRATradingAmount() {
    const limit =
      this.userAge > 50
        ? Number(this.cyear?.maxAmountOlderThan50)
        : Number(this.cyear?.maxAmountMinor50);
    let errorMessage = null;

    if (limit && this.amount > limit) {
      errorMessage = `Amount may not exceed ${this.filterService('currency')(
        limit
      )}`;
    }

    return errorMessage;
  }

  private validateRiaAccountLimits() {
    let errorMessage = null;

    const limit = this.riaAmountLimit;

    if (limit && this.amount > limit) {
      this.amountValid = false;
      errorMessage = `Amount may not exceed ${this.filterService('currency')(
        limit
      )}`;
    }

    return errorMessage;
  }

  private validateRetirementAmount() {
    const limit =
      this.userAge > 50
        ? Number(this.cyear?.maxAmountOlderThan50)
        : Number(this.cyear?.maxAmountMinor50);

    //Only for Invest and RIA Accounts exist contributions. For other accounts, take the cyear limit.
    if (!this.isTransferToIraSupportedAccounts()) {
      this.totalContributions = [];
    }

    const contributionYtd = this.totalContributions?.find(
      x => x.year === this.cyear.year
    );
    const contributionLeft = contributionYtd
      ? limit - contributionYtd.amount
      : limit;

    let errorMessage = null;

    if (
      (limit && this.amount > limit) ||
      (contributionLeft && this.amount > contributionLeft)
    ) {
      this.amountValid = false;
      errorMessage = `Amount exceeds the maximum contribution for ${
        this.cyear?.year
      }. You May Still Contribute ${this.filterService('currency')(
        contributionLeft
      )}`;
    } else {
      this.amountValid = true;
    }

    return errorMessage;
  }

  private transferFunds(
    validateDuplicate: boolean = false,
    acceptDuplicateWarning?: boolean
  ): void {
    this.errorMessage = null;
    this.maskedCellPhone =
      sessionStorage.getItem('maskedCellPhone') || this.maskedCellPhone;
    this.hasUserPhone =
      !!this.maskedCellPhone || this.maskedCellPhone === 'null'; // Validate if the user has a masked phone.

    if (this.transferHasAxosInvest) {
      this.isBusy = true;
      if (this.isRecurrent) {
        this.sendRecurringInvestTransfer();
      } else {
        this.sendAxosInvestTransfer();
      }

      return;
    }

    if (this.transferHasRiaAccounts) {
      this.isBusy = true;
      //Only contributions are allowed for RIA Accounts at the moment
      this.sendTransferForAxosAdvisory(AxosAdvisoryTransferType.Contribution);

      return;
    }

    if (
      this.selectedFromAccount.isTrading ||
      this.selectedToAccount.isTrading
    ) {
      const todayDate = this.dateHelper.normalizeDate(moment());
      if (
        this.selectedFrequency.value !== Frequency.ONETIME ||
        !todayDate.isSame(this.date)
      ) {
        // Trading transfer schedule
        const isOneTimeSchedule =
          this.selectedFrequency.value === Frequency.ONETIME &&
          !todayDate.isSame(this.date);
        this.sendTradingTransferSchedule(isOneTimeSchedule);

        return;
      }

      // Trading one time transfer
      const transaction: TransferSchedule = {
        accountIdFrom: this.selectedFromAccount.id,
        accountNumberFrom: this.selectedFromAccount.accountNumber,
        accountTypeFrom: this.selectedFromAccount.accountType,
        accountProductTypeFrom: this.selectedFromAccount.productType,
        accountNicknameFrom: this.getSelectedFromAccountForReceipt(
          this.selectedFromAccount
        ),
        accountNumberTo: this.selectedToAccountNumber,
        accountTypeTo: this.selectedToAccount.accountType,
        accountProductTypeTo: this.selectedToAccount.productType,
        accountNicknameTo: this.getSelectedToAccountForReceipt(
          this.selectedToAccount
        ),
        amount: this.selectedToAccount.isLoan
          ? this.regularMonthlyPayment
          : this.validIraTransaction()
          ? this.withholdNetValue
          : this.amount,
        fromIsExternal: this.isFromExternal,
        fromIsTrading: this.isFromTrading,
        toIsExternal: this.isToExternal,
        toIsTrading: this.isToTrading,
        routingNumber: this.selectedFromAccount.routingNumber,
        bankName: this.selectedFromAccount.bankName,
      };

      if (this.isTransferToIraTrading()) {
        transaction.toAccountIsIra = true;
        transaction.contributionYear = this.cyear.year;
        transaction.accountTypeTo = this.selectedToAccount.accountTypeCode;
      } else if (this.isTransferFromIraTrading()) {
        transaction.accountTypeFrom = this.selectedFromAccount.accountTypeCode;
        transaction.fromAccountIsIra = true;
        transaction.fromIsTrading = true;
        transaction.fromIsExternal = false;
        transaction.accountIdTo = this.selectedToAccount.id;
        transaction.dateAddedString = moment().format('DD/MM/YYYY hh:mm:ss');

        if (this.selectedToAccount.isExternal) {
          (transaction.bankName = this.selectedToAccount.bankName),
            (transaction.routingNumber = this.selectedToAccount.routingNumber);
        }

        if (this.withholding === 0) {
          transaction.federalWithholdingPercent = this.withholdPercentageValue;
          transaction.federalWithholdingAmount = 0;
        } else if (this.withholding === 1) {
          transaction.federalWithholdingPercent = 0;
          transaction.federalWithholdingAmount = this.withholdAmountValue;
        } else {
          transaction.federalWithholdingPercent = 0;
          transaction.federalWithholdingAmount = 0;
        }

        if (this.stateWithhold === 0) {
          transaction.stateWithholdingPercent = this.stateWithholdPercentageValue;
          transaction.stateWithholdingAmount = 0;
        } else if (this.stateWithhold === 1) {
          transaction.stateWithholdingPercent = 0;
          transaction.stateWithholdingAmount = this.stateWithholdAmountValue;
        } else {
          transaction.stateWithholdingPercent = 0;
          transaction.stateWithholdingAmount = 0;
        }
      }

      // some trading accounts include blank spaces on AccountType, i.e "Joint tenant with ros"
      // the following code fix this issue using the AccountTypeCode
      // instead of the Account Type literal.
      if (transaction.toIsTrading) {
        transaction.accountTypeTo = this.selectedToAccount.accountTypeCode;
      } else {
        if (transaction.fromIsTrading) {
          transaction.accountTypeFrom = this.selectedFromAccount.accountTypeCode;
        }
      }
      this.sendTradingTransfer(transaction);

      return;
    }

    if (!this.isToExternal && !this.isFromExternal) {
      // Internal to internal account
      const transaction: TransferSchedule = {
        accountIdFrom: this.selectedFromAccount.id,
        accountNumberFrom: this.selectedFromAccount.accountNumber,
        accountTypeFrom: this.selectedFromAccount.accountType,
        accountProductTypeFrom: this.selectedFromAccount.productType,
        accountIdTo: this.selectedToAccount.id,
        accountNumberTo: this.selectedToAccount.accountNumber,
        accountTypeTo: this.selectedToAccount.accountType,
        accountProductTypeTo: this.selectedToAccount.productType,
        amount: this.selectedToAccount.isLoan
          ? this.regularMonthlyPayment
          : this.validIraTransaction()
          ? this.withholdNetValue
          : this.amount,
        memo: this.memo,
        confirmationEmail: this.confirmEmail,
        confirmationTextMessage: this.confirmText,
        dateAddedString: moment().format('DD/MM/YYYY hh:mm:ss'),
        additionalPrincipal: this.additionalPrincipal,
        escrow: this.escrow,
        fromAccountIsIra: this.selectedFromAccount.isIra,
        toAccountIsIra: this.selectedToAccount.isIra,
        requiredMinimumDistributionAmount: this.showIRADistributionDisclaimer
          ? this.selectedFromAccount.requiredMinimumDistributionAmount
          : null,
        accountNicknameFrom: this.selectedFromAccount.nickname,
        accountNicknameTo: this.selectedToAccount.nickname,
      };

      if (this.validIraTransaction()) {
        transaction.withholdType = this.withholding;
        transaction.withholdValue =
          this.withholding === 0
            ? this.withholdPercentageValue
            : this.withholdAmountValue;
      }

      if (
        this.selectedFrequency.value === Frequency.ONETIME &&
        moment(this.date).isSame(moment(), 'd')
      ) {
        // Performs a single transfer
        this.isBusy = true;

        this.transactionService
          .transfer(transaction)
          .then(response => {
            this.accountsService.getSortedAccountsBalance().then(accounts => {
              this.transactionService.clearCache();
              this.paymentsService.clearCache();
              if (
                !this.selectedFromAccount.isIra &&
                !this.selectedToAccount.isIra
              ) {
                this.updateAccountAmounts(accounts.data);
                this.root.$broadcast('transferCompleted');
                this.root.$broadcast('updateTransactions');
                // One-time | Internal (same day).
                this.goToReceipt(ReceiptConstants.MoneyTransfer_In_OneTime, '');
              } else {
                this.goToReceipt(
                  ReceiptConstants.MoneyTransfer_In_OneTime,
                  response.data
                );
              }
            });
          })
          .catch((err: ApiError) => {
            this.isBusy = false;
            this.serviceHelper.errorHandler(err, true);
          })
          .finally(() => {
            this.resetErrors();
          });

        return;
      }

      // Schedules a transfer
      transaction.amountOption = this.selectedAmountOption
        ? this.selectedAmountOption.value
        : 0;
      transaction.beginSendingOn = this.date.toDate();
      transaction.frequency = this.selectedFrequency.value;
      transaction.sendUntilOptions = this.selectedSendUntil
        ? this.selectedSendUntil.value
        : 0;
      transaction.numberOfTransfers = this.numberTransfers || 0;
      transaction.regularMonthPayment = this.regularMonthlyPayment;
      this.isBusy = true;

      this.transactionService
        .scheduleTransfer(transaction)
        .then(response => {
          let confirmationNumber = '';
          if (this.isToExternal || this.isFromExternal)
            confirmationNumber = response.data;
          if (this.selectedFrequency.value != Frequency.ONETIME) {
            // Recurring | Internal
            this.goToReceipt(
              ReceiptConstants.MoneyTransfer_Recurring,
              confirmationNumber
            );
          } else {
            // One-time | Internal (scheduled)
            this.goToReceipt(
              ReceiptConstants.MoneyTransfer_Ex_OneTime,
              this.validIraTransaction() ? response.data : confirmationNumber
            );
          }
        })
        .catch((err: ApiError) => {
          this.isBusy = false;
          this.serviceHelper.errorHandler(err, true);
        })
        .finally(() => {
          this.resetErrors();
        });

      return;
    }

    let ACHlimit = 100000;
    const ACHsetting = this.root['appSettings'].find(
      (ap: any) => ap.name === AppSettings.ACHLimitAmount
    );
    if (ACHsetting) {
      ACHlimit = Number(ACHsetting.value);
    }

    if (this.amount >= ACHlimit) {
      this.step = 2;
      this.phoneNumber = this.root['brandProperties']['Contact_PhoneNumber'];
    } else {
      this.makeExternalTransfer(validateDuplicate, acceptDuplicateWarning);
    }
  }

  private makeExternalTransfer(
    validateDuplicate: boolean,
    acceptDuplicateWarning?: boolean
  ): void {
    // Internal to external or external to internal
    const externalTransfer: ExternalAccountScheduleRequest = {
      bankName: this.isFromExternal
        ? this.selectedFromAccount.bankName
        : this.selectedToAccount.bankName,
      externalAccountId: this.isFromExternal
        ? this.selectedFromAccount.id
        : this.selectedToAccount.id,
      internalAccountId: this.isFromExternal
        ? this.selectedToAccount.id
        : this.selectedFromAccount.id,
      amount: this.selectedToAccount.isLoan
        ? this.regularMonthlyPayment
        : this.validIraTransaction()
        ? this.withholdNetValue
        : this.amount,
      frequency: this.selectedFrequency.value,
      transferType: this.isFromExternal ? 0 : 1,
      accountCode: '',
      numberOfTransfers: this.numberTransfers || 0,
      processingDate: this.date.toDate(),
      externalAccountNickName: this.isFromExternal
        ? this.selectedFromAccount.nickname
        : this.selectedToAccount.nickname,
      accountNumberFrom: this.selectedFromAccount.accountNumber,
      accountNumberTo: this.selectedToAccount.accountNumber,
      fromAccountIsIra: this.selectedFromAccount.isIra,
      toAccountIsIra: this.selectedToAccount.isIra,
      requiredMinimumDistributionAmount: this.showIRADistributionDisclaimer
        ? this.selectedFromAccount.requiredMinimumDistributionAmount
        : null,
      accountTypeFrom: this.selectedFromAccount.displayFromTypeName,
      accountTypeTo: this.selectedToAccount.displayToTypeName,
      FromNickName: this.selectedFromAccount.nickname,
      ToNickName: this.selectedToAccount.nickname,
      sendUntilOptions: this.selectedSendUntil
        ? this.selectedSendUntil.value
        : 0,
      additionalPrincipal: 0,
      isSBB: this.selectedFromAccount.isSBB,
    };
    if (this.validIraTransaction()) {
      externalTransfer.withholdType = this.withholding;
      externalTransfer.withholdValue =
        this.withholding === 0
          ? this.withholdPercentageValue
          : this.withholdAmountValue;
    }

    this.isBusy = true;
    this.transactionService
      .scheduleExternalTransfer(
        externalTransfer,
        validateDuplicate,
        acceptDuplicateWarning
      )
      .then(response => {
        let confirmationNumber = '';
        if (response.statusCode == 202) {
          this.isBusy = false;
          this.DuplicateTransferWarning();
        } else {
          if (this.isToExternal || this.isFromExternal)
            confirmationNumber = response.data;
          if (this.selectedFrequency.value != Frequency.ONETIME) {
            // Recurring | Internal to external or external to internal
            this.goToReceipt(
              ReceiptConstants.MoneyTransfer_Recurring,
              this.validIraTransaction() ? response.data : confirmationNumber
            );
          } else {
            // One-time | Internal to external or external to internal (Scheduled)
            this.goToReceipt(
              angular.copy(ReceiptConstants.MoneyTransfer_Ex_OneTime),
              this.validIraTransaction() ? response.data : confirmationNumber
            );
          }
        }
      })
      .catch((err: ApiError) => {
        this.isBusy = false;
        if (err.data.message.includes(this.moveMoneyErrorMessage)) {
          this.matDialog.open(InternalToExternalComponent, {
            disableClose: true,
          });
          return;
        }
        if (err.data.statusCode != 400) {
          this.serviceHelper.errorHandler(err, true);

          return;
        }
        const { message } = err.data;
        this.errorMessage =
          message.includes('amount exceeds maximum customer limit') ||
          message.includes('amount exceeds maximum customer daily limit')
            ? this.settingUpOutgoingTransfer
              ? `Transfer amount exceeds daily outbound limit of
          ${this.filterService('currency')(
            this.customerLimits.extTransfersMaxOutgoingAmount
          )}`
              : `Transfer amount exceeds daily inbound limit of
          ${this.filterService('currency')(
            this.customerLimits.extTransfersMaxIncomingAmount
          )}`
            : err.data.message;
      })
      .finally(() => {
        this.resetErrors();
      });
  }

  private sendTradingTransfer(transaction: TransferSchedule) {
    this.isBusy = true;
    let receiptTemplate: ReceiptTemplate;
    if (this.isTransferToIraTrading()) {
      receiptTemplate = { ...ReceiptConstants.MoneyTransfer_Trading_Ira };
    } else if (this.isTransferFromIraTrading()) {
      receiptTemplate = { ...ReceiptConstants.MoneyTransfer_From_Trading_Ira };
    } else {
      receiptTemplate = { ...ReceiptConstants.MoneyTransfer_Ex_OneTime };
    }

    this.transactionService
      .transferTrading(transaction, this.fundingState.isRunning)
      .then(response => {
        receiptTemplate.isPending = response.data?.warning;
        this.accountsService.getSortedAccountsBalance().then(accounts => {
          this.transactionService.clearCache();
          this.paymentsService.clearCache();
          this.updateAccountAmounts(accounts.data);
          this.UpdateTradingAccounts();
          this.goToReceipt(
            receiptTemplate,
            response.data.requestId
              .substring(0, response.data.requestId.indexOf('-'))
              .toUpperCase()
          );
        });
      })
      .catch((err: ApiError) => {
        this.isBusy = false;
        this.serviceHelper.errorHandler(err, true);
      })
      .finally(() => {
        this.isBusy = false;
        this.resetErrors();
      });
  }

  private sendTradingTransferSchedule(isOneTimeSchedule: boolean) {
    const tradingAccount = this.selectedToAccount.isTrading
      ? this.selectedToAccount
      : this.selectedFromAccount;
    const bankAccount = this.selectedToAccount.isTrading
      ? this.selectedFromAccount
      : this.selectedToAccount;
    const transType = this.selectedToAccount.isTrading
      ? TransferTransactionType.Deposit
      : TransferTransactionType.Withdrawal;

    const transaction: ScheduledTransferRequest = {
      accountNumber: tradingAccount.accountNumber,
      amount: this.amount,
      transactionType: transType,
      depositType: DepositType.Auto,

      accountId: bankAccount.id,

      startSendingDate: this.date.toDate(),
      frequency:
        SCHEDULED_FREQUENCIES_MAPPING[this.selectedFrequency.value] ??
        ScheduleFrequency.None,
      numberofTransfers: this.numberTransfers || 0,
      sendUntil: this.selectedSendUntil ? this.selectedSendUntil.value : 0,
    };
    this.isBusy = true;
    const template = isOneTimeSchedule
      ? ReceiptConstants.MoneyTransfer_Ex_OneTime
      : ReceiptConstants.MoneyTransfer_Recurring;

    this.tradingTransferScheduleService
      .transferTradingSchedule(transaction, bankAccount.isExternal)
      .subscribe(
        _response => {
          template.isPending = false;
          this.goToReceipt(template, '');
        },
        err => {
          this.isBusy = false;
          this.serviceHelper.errorHandler(err, true);
        },
        () => {
          this.isBusy = false;
          this.resetErrors();
        }
      );
  }

  private sendAxosInvestTransfer() {
    const investAccount = this.selectedToAccount.isAxosInvest
      ? this.selectedToAccount
      : this.selectedFromAccount;
    const secondAccount = this.selectedToAccount.isAxosInvest
      ? this.selectedFromAccount
      : this.selectedToAccount;
    const transactionType = this.selectedToAccount.isAxosInvest
      ? AxosInvestTransactionType.Deposit
      : AxosInvestTransactionType.Withdrawal;
    const depositType = AxosInvestDepositType.OneTime;

    if (
      this.selectedFromAccount.isAxosInvest &&
      this.selectedToAccount.isAxosInvest
    ) {
      this.makeTransferBetweenMilestones(investAccount);

      return;
    }
    // create today reference in case this.date is falsy
    const today = moment(new Date());
    const transaction: Transfer = {
      accountNumber: this.transferMpValue
        ? this.transferMpValue.accountNumber
        : investAccount.accountNumber, // when retirement
      milestoneId: investAccount.routingNumber,
      amount: this.amount,
      transactionType,
      depositType,
      accountId: secondAccount.id,
      sendingOn: this.dateHelper
        .normalizeDate(this.date ? moment(this.date) : today)
        .format('MM-DD-YYYY'),
      contributionYear: this.retirementIraSelected
        ? this.selectedContributionYear
        : null,
    };
    this.transactionService
      .transferInvest(transaction, secondAccount.isExternal)
      .then(response => {
        let template;
        if (this.retirementIraSelected) {
          template =
            transaction.depositType === AxosInvestDepositType.OneTime
              ? ReceiptConstants.MoneyTransfer_AxosInvest_In_OneTime
              : ReceiptConstants.MoneyTransfer_AxosInvest_Recurring;
        } else {
          template =
            transaction.depositType === AxosInvestDepositType.OneTime
              ? ReceiptConstants.MoneyTransfer_In_OneTime
              : ReceiptConstants.MoneyTransfer_Recurring;
        }
        this.goToReceipt(angular.copy(template), response.data.uuid);
      })
      .catch((err: ApiError) => {
        this.isBusy = false;
        this.serviceHelper.errorHandler(err, true);
      })
      .finally(() => {
        this.resetErrors();
      });
  }

  private sendRecurringInvestTransfer() {
    const investAccount = this.selectedToAccount.isAxosInvest
      ? this.selectedToAccount
      : this.selectedFromAccount;
    const secondAccount = this.selectedToAccount.isAxosInvest
      ? this.selectedFromAccount
      : this.selectedToAccount;
    const transactionType = this.selectedToAccount.isAxosInvest
      ? TransactionType.Deposit
      : TransactionType.Withdrawal;
    const transfer: mpScheduledTransfer = {
      accountId: secondAccount.id,
      accountNumber: this.transferMpValue
        ? this.transferMpValue.accountNumber
        : investAccount.accountNumber,
      amount: this.amount,
      contributionYear: this.currentContributionYear.year,
      depositType: mpDepositType.Auto,
      frequency: this.selectedFrequency.value,
      milestoneId: investAccount.routingNumber,
      milestoneName: investAccount.displayFromTypeName,
      sendUntil: this.selectedSendUntil.value,
      startSendingDate: this.date.toDate(),
      lastTransferDate: this.endDate?.toDate(),
      transactionType,
    };
    const containsExternalAccount =
      this.selectedToAccount.isExternal || this.selectedFromAccount.isExternal
        ? true
        : false;
    this.axosInvestTransferService
      .createScheduledTransfer(transfer, containsExternalAccount)
      .pipe(
        finalize(() => {
          this.resetErrors();
        })
      )
      .subscribe({
        next: response => {
          let template;
          if (this.retirementIraSelected) {
            template = ReceiptConstants.MoneyTransfer_AxosInvest_Recurring;
          } else {
            template = ReceiptConstants.MoneyTransfer_Recurring;
          }
          this.goToReceipt(
            angular.copy(template),
            response.data.transferSeriesId
          );
        },
        error: err => {
          this.isBusy = false;
          this.serviceHelper.errorHandler(err, true);
        },
      });
  }

  private makeTransferBetweenMilestones(investAccount: NewOlbAccount) {
    const milestoneTransactions: MilestoneTransaction = {
      FromMilestoneId: this.selectedFromAccount.routingNumber,
      ToMilestoneId: this.selectedToAccount.routingNumber,
      accountNumber: this.transferMpValue
        ? this.transferMpValue.accountNumber
        : investAccount.accountNumber,
      amount: this.amount,
    };
    this.transactionService
      .transferInvestMilestoneToMilestone(milestoneTransactions)
      .then(response => {
        let template;
        if (this.retirementIraSelected) {
          template = ReceiptConstants.MoneyTransfer_AxosInvest_In_OneTime;
        } else {
          template = ReceiptConstants.MoneyTransfer_In_OneTime;
        }
        this.goToReceipt(angular.copy(template), response.data.uuid);
      })
      .catch((err: ApiError) => {
        this.isBusy = false;
        this.serviceHelper.errorHandler(err, true);
      })
      .finally(() => {
        this.resetErrors();
      });
  }

  private sendTransferForAxosAdvisory(
    transactionType: AxosAdvisoryTransferType
  ) {
    const accountFrom = this.selectedFromAccount;
    const accountTo = this.selectedToAccount;
    let accountType;

    const selectedFromAccount = this.getSelectedFromAccountForReceipt(
      this.selectedFromAccount
    );
    const selectedToAccount = this.getSelectedToAccountForReceipt(
      this.selectedToAccount
    );

    // Only checking and savings product types are allowed by this point, other product types are validated before this method
    const productType = accountFrom.productType.toLowerCase();
    if (productType == 'checking') {
      accountType = AxosAdvisoryTransferAccountType.Checking;
    } else if (productType == 'savings') {
      accountType = AxosAdvisoryTransferAccountType.Savings;
    }

    const transferData: AxosAdvisoryTransferRequest = {
      accountNumber: accountTo.accountNumber,
      amount: this.amount,
      transactionType: transactionType,
      frequency: this.mapLibertyFrequencies(this.selectedFrequency.value),
      executionDate: this.date.toDate(),
      taxYear: this.currentContributionYear.year,
      bankAccountNumber: accountFrom.accountNumber,
      bankAccountType: accountType,
      bankName: accountFrom.isExternal ? accountFrom.bankName : 'Axos Bank',
      bankRoutingNumber: accountFrom.routingNumber,
      bankOwnerName: this.userCommonName,
    };

    const modal = this.uibModalService.open({
      templateUrl:
        'transfers/transfer-funds/pre-transfer-confirmation-modal/pre-transfer-confirmation-modal.tpl.html',
      windowClass: 'modal-service',
      controller: 'PreTransferConfirmationModal',
      controllerAs: 'ctrl',
      backdrop: 'static',
      resolve: {
        headerStr: () => {
          return this.modalHeader;
        },
        footerStr: () => {
          return this.modalFooter;
        },
        amount: () => {
          return this.filterService('currency')(this.amount);
        },
        selectedFrom: () => {
          return selectedFromAccount;
        },
        selectedTo: () => {
          return selectedToAccount;
        },
      },
    });

    modal.result.then((result: boolean) => {
      if (result) {
        this.axosAdvisoryService
          .createAxosAdvisoryTransfer(transferData)
          .pipe(
            finalize(() => {
              this.resetErrors();
            })
          )
          .subscribe({
            next: response => {
              let template = ReceiptConstants.MoneyTransfer_RIA_Contribution;
              this.goToReceipt(
                angular.copy(template),
                response.data.transactionId
              );
            },
            error: err => {
              this.isBusy = false;
              if (err.data.statusCode === 400) {
                this.internalServerError(true);

                return;
              }
              this.serviceHelper.errorHandler(err, true);
            },
          });
      } else {
        this.isBusy = false;
      }
    });
  }

  private DuplicateTransferWarning(): void {
    this.modalService
      .show(
        {},
        {
          bodyText: `<h3>Potential Duplicate Payment</h3>
          <p>There is already a scheduled payment to this loan account. Are you sure you would like to continue with this payment?</p>`,
          okText: 'Continue',
          cancelText: 'Cancel',
        }
      )
      .then(() => {
        this.transferFunds(false, true);
      })
      .catch(() => {
        return;
      });
  }

  /** Loads the FROM accounts */
  private handleFromAccounts(): void {
    this.isTodayValidForExternal = true;
    this.fromAccountsArray = [];
    if (this.internalFromAccountsArray != null) {
      this.internalFromAccountsArray
        .filter(value => this.isAValidAccountForFunding(value))
        .forEach(value => {
          const fromAccount: NewOlbAccount = {
            accountType: value.accountType,
            accountTypeCode: value.accountTypeCode,
            accountMask: value.accountMask,
            availableBalance: value.availableBalance,
            id: value.id,
            nickname: value.nickname,
            accountNumber: value.accountNumber,
            productType: value.productType,
            productCode: value.productCode,
            Type: 'Internal Accounts',
            isExternal: false,
            isDeposit: value.isDeposit,
            isLoan: value.isLoan,
            isIra: value.isIra,
            isSBB: value.isSBB,
            principalAndInterest: value.principalAndInterest,
            displayFromTypeName: value.nickname || value.name,
            canPayLoanFrom: value.canPayLoanFrom,
            displayName:
              (value.nickname || value.name) + this.getDisplayNameFormat(value),
            availableCredit: value.availableCredit,
            canTransferToExternal: value.canTransferToExternal,
          };
          fromAccount.requiredMinimumDistributionAmount =
            value.requiredMinimumDistributionAmount;
          this.fromAccountsArray.push(fromAccount);
        });
    }

    this.loadTradingAccounts.bind(this)('fromAccountsArray');
    if (this.externalFromAccountsArray != null) {
      if (
        this.featureFlagService.isSBBActive() &&
        !this.fundingState.isRunning
      ) {
        // Remove From Accounts Yodlee Business Accounts
        let externalFromAccountsFiltered = this.externalFromAccountsArray.filter(
          a => a.isSBB === false
        );
        this.externalFromAccountsArray = externalFromAccountsFiltered;
      }

      this.externalFromAccountsArray.forEach(value => {
        const aggregatedAccnt = this.aggregatedAccounts.find(
          (x: AggregatedAccount) => x.accountNumber == value.accountNumber
        );
        const isAggregatedAccnt = !!aggregatedAccnt;
        const displayName =
          isAggregatedAccnt && aggregatedAccnt.nickname
            ? `${aggregatedAccnt.nickname} (Avail. Bal: ${this.filterService(
                'currency'
              )(aggregatedAccnt.availableBalance)})`
            : isAggregatedAccnt && aggregatedAccnt.name
            ? `${aggregatedAccnt.name} *${
                value.accountMask
              } (Avail. Bal: ${this.filterService('currency')(
                aggregatedAccnt.availableBalance
              )})`
            : isAggregatedAccnt && !aggregatedAccnt.name
            ? `${aggregatedAccnt.bankName} *${
                value.accountMask
              } (Avail. Bal: ${this.filterService('currency')(
                aggregatedAccnt.availableBalance
              )})`
            : !isAggregatedAccnt && value.nickname
            ? `${value.nickname}`
            : `${value.bankName} *${value.accountMask}`;

        if (isAggregatedAccnt) {
          if (!aggregatedAccnt.nickname)
            aggregatedAccnt.nickname = aggregatedAccnt.name;
        }

        this.fromAccountsArray.push({
          bankName: value.bankName,
          accountType: value.accountCategory,
          accountTypeCode: value.accountCategory,
          accountMask: value.accountMask,
          availableBalance: isAggregatedAccnt
            ? aggregatedAccnt.availableBalance
            : null,
          id: value.externalAccountId,
          nickname: isAggregatedAccnt
            ? aggregatedAccnt.nickname
            : value.nickname,
          accountNumber: value.accountNumber,
          productType: value.accountCategory,
          Type: 'Non-Axos Accounts',
          isExternal: true,
          isDeposit: true,
          isSBB: value.isSBB,
          displayName,
          displayFromTypeName: value.nickname
            ? `${value.bankName} - ${value.nickname}`
            : `${value.bankName} - ${value.accountCategory}`,
          canPayLoanFrom: true,
          routingNumber: value.routingNumber,
        }); // 'Deposit / Withdrawal Forms' });
      });

      // Select the first external account as default when comes from funding
      if (this.fundingState.isRunning) {
        this.selectedFromAccount =
          this.fromAccountsArray.find(
            acc => acc.id == this.fundingState.fromAccountId
          ) ||
          this.fromAccountsArray.filter(
            account =>
              account.isExternal ||
              (account.isDeposit &&
                account.availableBalance > 0 &&
                account.id !== this.fundingState.accountId)
          )[0];
        this.fromAccountChange();

        return;
      }
    }
    this.loadAxosInvestAccounts('fromAccountsArray');
    if (this.hasRiaAccounts) {
      this.loadRiaAccounts('fromAccountsArray');
    }

    if (this.fromAccountId) {
      this.selectedFromAccount =
        this.fromAccountsArray.find(acc => acc.id == this.fromAccountId) ||
        this.fromAccountsArray.find(
          acc => acc.routingNumber === this.fromAccountId.toString()
        );
      this.fromAccountChange();
    }

    // Select the account when coming from external account details
    const accountId = this.params['id'];
    if (accountId) {
      this.selectedFromAccount = this.fromAccountsArray.find(
        acc => acc.id == accountId
      );
      this.loadToAccounts.bind(this)(
        this.selectedFromAccount.id,
        this.selectedFromAccount.isExternal,
        this.selectedFromAccount.accountTypeCode
      );
    }
    if (this.toAccountId) {
      if (this.fromAccountsArray.length !== 0) {
        this.selectedFromAccount =
          this.selectedFromAccount || this.fromAccountsArray[0];
        this.fromAccountChange();
      }
      return;
    }

    if (!this.fundingState.isRunning) {
      this.fromAccountsArray.push({
        displayName: this.addNonAxosAccntText,
        id: -1,
        Type: AccountTypeConstants.NON_AXOS_ACCOUNTS,
      });
    }
  }

  // Gets Trading Accounts for Dropdownlists
  private loadTradingAccounts(
    accountsArrayProperty: 'fromAccountsArray' | 'toAccountsArray'
  ): void {
    this.tradingAccountsArray = this.cachedTradingAccountsService.tradingAccounts;

    if (this.tradingAccountsArray) {
      let filteredTradingAccountsArray = [];

      if (this.isIraEnhDiraFlagActive) {
        filteredTradingAccountsArray = this.tradingAccountsArray.filter(
          a =>
            a.status === ClearingAccountStatus.Active &&
            (accountsArrayProperty === 'fromAccountsArray' ||
              accountsArrayProperty === 'toAccountsArray')
        );
      } else {
        filteredTradingAccountsArray = this.tradingAccountsArray.filter(
          a =>
            a.status === ClearingAccountStatus.Active &&
            ((accountsArrayProperty === 'fromAccountsArray' &&
              !this.isIraAccount(a)) ||
              accountsArrayProperty === 'toAccountsArray')
        );
      }

      filteredTradingAccountsArray.forEach(value => {
        const totalValue = `(Avail. Bal: ${value.amountAvailableToWithdraw.toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: 'USD',
          }
        )}†)`;
        const displayName = `${value.nickname} *${value.accountNumber.substr(
          value.accountNumber.length - 4
        )}`;

        this[accountsArrayProperty].push({
          id: value.id,
          bankName: value.bankName,
          accountType: value.typeName,
          accountTypeCode: value.type,
          availableBalance: value.totalValue,
          nickname: value.nickname,
          hasNickname: value.hasNickname,
          accountNumber: value.accountNumber,
          productType: 'AxosTrading',
          Type: 'Investment Accounts',
          isTrading: true,
          displayName: `${displayName} ${totalValue}`,
          displayFromTypeName:
            accountsArrayProperty === 'fromAccountsArray' ? displayName : '',
          displayToTypeName:
            accountsArrayProperty === 'toAccountsArray' ? displayName : '',
          availableBalanceDisplay: totalValue,
          amountAvailableToWithdraw: value.amountAvailableToWithdraw,
          isIra: this.isIraTradingAccount(value),
        });
      });
    }
  }

  isIraTradingAccount(account: TradingAccount) {
    let isIraTrading =
      +account?.accountTypeCode === ClearingAccountType.IraTraditional ||
      +account?.accountTypeCode === ClearingAccountType.IraRoth ||
      +account?.type === ClearingAccountType.IraTraditional ||
      +account?.type === ClearingAccountType.IraRoth;

    return isIraTrading;
  }
  private loadRiaAccounts(
    accountsArrayProperty: 'fromAccountsArray' | 'toAccountsArray'
  ): void {
    if (this.isRiaPilotFlagActive) {
      let riaAccountsToAdd = this.riaAccounts
        .filter(acc => !!acc.dateCloseInitiated === false) //Filter out accounts with closing date set
        .map(acc => {
          // We need to display available cash for RIA Accounts
          let availableCash =
            acc && acc.availableCash && acc.availableCash < 0
              ? 0
              : acc.availableCash;
          let totalValue = `(Avail. Bal: ${availableCash.toLocaleString(
            'en-US',
            {
              style: 'currency',
              currency: 'USD',
            }
          )}†)`;
          let displayName = `${acc.type} **${acc.accountNumber.substring(
            acc.accountNumber.length - 4
          )}`;

          return {
            id: Number(acc.riaId),
            accountNumber: acc.accountNumber,
            accountType: acc.accountType,
            availableBalance: acc.accountBalance,
            displayName: `${acc.accountNickname} ${totalValue}`,
            Type: 'Investment Accounts',
            displayFromTypeName:
              accountsArrayProperty === 'fromAccountsArray' ? displayName : '',
            displayToTypeName:
              accountsArrayProperty === 'toAccountsArray' ? displayName : '',
            availableBalanceDisplay: totalValue,
            isRia: true,
            isIra: acc.isRetirement,
            isDeposit: false,
            isTrading: false,
            productType: '',
            nickname: acc.accountNickname,
          } as NewOlbAccount;
        });

      this[accountsArrayProperty].push(...riaAccountsToAdd);
    }
  }
  private isAValidAccountForFunding(acc: any): boolean {
    return (
      !this.fundingState.isRunning ||
      (this.fundingState.isRunning && !acc.isLoan)
    );
  }

  private isAValidLoanForPay(acc: any): boolean {
    return !acc.isLoan ? true : this.selectedFromAccount.canPayLoanFrom;
  }

  /** Loads the TO accounts */
  private loadToAccounts(
    idFromAcc: number,
    isExternal: boolean,
    typeProduct: String
  ): void {
    this.isTodayValidForExternal = true;
    this.toAccountsArray = [];
    const {
      internalAccounts,
      externalAccounts,
    } = this.cachedAccountsService.getToAccounts(
      idFromAcc,
      isExternal,
      typeProduct
    );

    this.internalToAccountsArray = this.fromAccountIsValidIra()
      ? this.filterToAccountsFromIRA(internalAccounts)
      : internalAccounts;

    if (this.internalToAccountsArray != null) {
      this.internalToAccountsArray
        .filter(value => this.isAValidAccountForFunding(value))
        .filter(value => this.isAValidLoanForPay(value))
        .filter(value => this.isToAccountValidForTrading(value))
        .filter(value => this.isToAccountValidForMP(value))
        .forEach(value => {
          this.toAccountsArray.push({
            accountType: value.accountType,
            accountTypeCode: value.accountTypeCode,
            accountMask: value.accountMask,
            availableBalance: value.availableBalance,
            id: value.id,
            nickname: value.nickname,
            accountNumber: value.accountNumber,
            productType: value.productType,
            Type: 'Internal Accounts',
            isExternal: false,
            isDeposit: value.isDeposit,
            isLoan: value.isLoan,
            isIra: value.isIra,
            isSBB: value.isSBB,
            loanDueDate: value.loanDueDate,
            outstandingBalance: value.outstandingBalance,
            paymentAmount: value.paymentAmount,
            loanDueAmount: value.loanDueAmount,
            minimumAmountDue: value.minimumAmountDue,
            maxGraceDate: value.maxGraceDate,
            principalAndInterest: value.principalAndInterest,
            totalAmountDue: value.totalAmountDue,
            hasEscrow: value.hasEscrow,
            isClosed: value.isClosed,
            isOpen: value.isOpen,
            canPayoffLoan: value.canPayoffLoan,
            displayToTypeName: value.nickname || value.name,
            displayName:
              (value.nickname || value.name) +
              (value.isDeposit
                ? `(Avail. Bal: ${this.filterService('currency')(
                    value.availableBalance
                  )})`
                : `(Total Amount Due: ${this.filterService('currency')(
                    value.isLoan
                      ? value.totalAmountDue == null
                        ? 0
                        : value.totalAmountDue
                      : value.outstandingBalance
                  )})`),
          }); // 'Deposit / Withdrawal Forms' });
        });
    }

    const fromProductType = this.selectedFromAccount?.productType?.toLowerCase();

    if (
      !this.selectedFromAccount.isTrading &&
      !this.selectedFromAccount.isAxosInvest
    ) {
      if (
        isExternal ||
        this.supportedProductTypesForInvest.includes(fromProductType)
      ) {
        this.loadTradingAccounts('toAccountsArray');
        this.loadAxosInvestAccounts('toAccountsArray');
      }
    }

    if (
      this.selectedFromAccount.isAxosInvest &&
      fromProductType === 'managed portfolios'
    ) {
      this.loadAxosInvestAccounts('toAccountsArray');
    }

    //TODO: Verify is the first condition is needed
    if (this.hasRiaAccounts && !this.selectedFromAccount.isRia) {
      this.loadRiaAccounts('toAccountsArray');
    }

    this.externalToAccountsArray = externalAccounts;

    if (
      this.externalToAccountsArray.length > 0 &&
      ((this.selectedFromAccount.productType == 'SBLOC' &&
        this.selectedFromAccount.canTransferToExternal) ||
        this.selectedFromAccount.productType != 'SBLOC')
    ) {
      this.externalToAccountsArray.forEach(value => {
        const aggregatedAccnt = this.aggregatedAccounts.find(
          (x: AggregatedAccount) => x.accountNumber === value.accountNumber
        );
        const isAggregatedAccnt = !!aggregatedAccnt;
        const displayName =
          isAggregatedAccnt && aggregatedAccnt.nickname
            ? `${aggregatedAccnt.nickname} (Avail. Bal: ${this.filterService(
                'currency'
              )(aggregatedAccnt.availableBalance)})`
            : isAggregatedAccnt && aggregatedAccnt.name
            ? `${aggregatedAccnt.name} *${
                value.accountMask
              } (Avail. Bal: ${this.filterService('currency')(
                aggregatedAccnt.availableBalance
              )})`
            : isAggregatedAccnt && !aggregatedAccnt.name
            ? `${aggregatedAccnt.bankName} *${
                value.accountMask
              } (Avail. Bal: ${this.filterService('currency')(
                aggregatedAccnt.availableBalance
              )})`
            : !isAggregatedAccnt && value.nickname
            ? `${value.nickname}`
            : `${value.bankName} *${value.accountMask}`;

        if (isAggregatedAccnt) {
          if (!aggregatedAccnt.nickname)
            aggregatedAccnt.nickname = aggregatedAccnt.name;
        }

        return this.toAccountsArray.push({
          bankName: value.bankName,
          accountType: value.accountCategory,
          accountTypeCode: value.accountCategory,
          accountMask: value.accountMask,
          availableBalance: null,
          id: value.externalAccountId,
          nickname: isAggregatedAccnt
            ? aggregatedAccnt.nickname
            : value.nickname,
          accountNumber: value.accountMask,
          productType: value.accountCategory,
          Type: 'Non-Axos Accounts',
          isExternal: true,
          isDeposit: true,
          isSBB: value.isSBB,
          isClosed: value.isClosed,
          isOpen: value.isOpen,
          canPayoffLoan: value.canPayoffLoan,
          displayName,
          displayToTypeName: value.nickname
            ? `${value.bankName} - ${value.nickname}`
            : `${value.bankName} - ${value.accountCategory}`,
          routingNumber: value.routingNumber,
        }); // 'Deposit / Withdrawal Forms' });
      });
    }

    //SBB Logic
    this.filterAccounts(this.isSBBActive, this.fundingState.isRunning);

    if (!this.fundingState.isRunning) {
      this.toAccountsArray.push({
        displayName: this.addNonAxosAccntText,
        id: -1,
        Type: AccountTypeConstants.NON_AXOS_ACCOUNTS,
        isExternal: true,
      });
    }

    if (this.toAccountsArray.length === 0) {
      return;
    }

    if (this.toAccountId) {
      this.selectedToAccount =
        this.toAccountsArray.find(account => account.id === this.toAccountId) ||
        this.toAccountsArray.find(
          account => account.routingNumber === this.toAccountId.toString()
        );
      this.toAccountChange();

      return;
    }

    const accountId = this.fundingState.accountId || this.params['toAccountId'];
    if (accountId) {
      this.selectedToAccount = this.toAccountsArray.find(
        account => account.id === Number(accountId)
      );

      if (this.selectedToAccount) {
        this.toAccountChange();

        return;
      }
    }
  }

  private isToAccountValidForTrading(value: any): boolean {
    return (
      !this.isFromTrading ||
      this.supportedProductTypesForInvest.includes(
        value.productType.toLowerCase()
      )
    );
  }

  private isToAccountValidForMP(value: any): boolean {
    return (
      !this.selectedFromAccount.isAxosInvest ||
      this.supportedProductTypesForInvest.includes(
        value.productType.toLowerCase()
      )
    );
  }

  /** Depending on the rules, fill the frequencies dropdown */
  private fillFrequencies(
    fromAccount: NewOlbAccount,
    toAccount: NewOlbAccount
  ): void {
    if (fromAccount == null || toAccount == null) return;
    this.frequencies = [];

    if (toAccount.isAxosInvest || fromAccount.isAxosInvest) {
      this.frequencies = this.getFrequenciesMp();

      return;
    }

    if (fromAccount.isRia || toAccount.isRia) {
      if (!toAccount.isIra) {
        this.frequencies = this.getFrequencies([
          Frequency.ONETIME,
          Frequency.MONTHLY,
          Frequency.QUARTERLY,
          Frequency.SEMIANNUALLY,
          Frequency.ANNUALLY,
        ]);
      } else {
        this.frequencies = this.getFrequencies([
          Frequency.ONETIME,
          Frequency.WEEKLY,
          Frequency.EVERYTWOWEEKS,
          Frequency.MONTHLY,
          Frequency.QUARTERLY,
        ]);
      }

      return;
    }

    /** if is external account in from or to dropdowns */
    if (fromAccount.isExternal || toAccount.isExternal) {
      switch (toAccount.productType.toLowerCase()) {
        case 'mortgage':
        case 'auto loan':
        case 'personal loan':
        case 'heloc':
        case 'payment protection program':
          // if is ACH remove 'Monthly' option
          if (toAccount.isAch)
            this.frequencies = this.getFrequencies([Frequency.ONETIME]);
          else
            this.frequencies = this.getFrequencies([
              Frequency.ONETIME,
              Frequency.MONTHLY,
            ]);
          break;
        case 'overdraft line of credit':
          this.frequencies = this.getFrequencies([
            Frequency.ONETIME,
            Frequency.MONTHLY,
          ]);
          break;
        default:
          if (
            fromAccount.productType.toLocaleLowerCase() == 'sbloc' &&
            toAccount.isExternal
          ) {
            this.frequencies = this.getFrequencies([Frequency.ONETIME]);
          } else {
            if (this.frequencies.length === 0) {
              this.frequencies = this.getFrequencies([
                Frequency.ONETIME,
                Frequency.WEEKLY,
                Frequency.EVERYTWOWEEKS,
                Frequency.MONTHLY,
                Frequency.QUARTERLY,
                Frequency.SEMIANNUALLY,
                Frequency.ANNUALLY,
              ]);
            }
          }
      }

      this.hasExternalAccount = true;

      return;
    }

    this.hasExternalAccount = false;

    /** if account doesn't include external accounts  */
    switch (fromAccount.productType.toLowerCase()) {
      case 'money market':
      case 'savings':
        this.frequencies = this.getFrequencies([
          Frequency.ONETIME,
          Frequency.WEEKLY,
          Frequency.EVERYTWOWEEKS,
          Frequency.MONTHLY,
          Frequency.QUARTERLY,
          Frequency.SEMIANNUALLY,
          Frequency.ANNUALLY,
        ]);
        break;
      case 'heloc':
        this.frequencies = this.getFrequencies([Frequency.ONETIME]);
        break;
    }
    switch (toAccount.productType.toLowerCase()) {
      case 'mortgage':
      case 'auto loan':
      case 'personal loan':
      case 'heloc':
      case 'payment protection program':
        // if is ACH remove 'Monthly' option
        if (toAccount.isAch)
          this.frequencies = this.getFrequencies([Frequency.ONETIME]);
        else
          this.frequencies = this.getFrequencies([
            Frequency.ONETIME,
            Frequency.MONTHLY,
          ]);
        break;
      case 'overdraft line of credit':
        this.frequencies = this.getFrequencies([
          Frequency.ONETIME,
          Frequency.MONTHLY,
        ]);
        break;
      default:
        // Make sure to not override frequencies if they were set in the 'From Account' rules
        if (this.frequencies.length === 0) {
          this.frequencies = this.getFrequencies([
            Frequency.ONETIME,
            Frequency.WEEKLY,
            Frequency.EVERYTWOWEEKS,
            Frequency.MONTHLY,
            Frequency.QUARTERLY,
            Frequency.SEMIANNUALLY,
            Frequency.ANNUALLY,
          ]);
        }
    }
  }

  /**
   *
   */
  private loadSendUntilOptions(): void {
    this.sendUntilOptions = [
      {
        value: SendUntilOption.IChooseToStop,
        label: mapSendUntil(SendUntilOption.IChooseToStop),
      },
      {
        value: SendUntilOption.NumberOfTransfers,
        label: mapSendUntil(SendUntilOption.NumberOfTransfers),
      },
    ];
  }

  /**
   *
   */
  private loadAmountOptions(toAccount: NewOlbAccount, frequency: number): void {
    if (toAccount == null || frequency == null) return;

    this.showAmount = false;
    this.showLoanSection = false;
    this.showMortgageSection = false;
    this.showAmountOptions = false;
    this.showMinimumAmount = false;
    this.showOutstandingBalance = false;

    this.showAmount = true;

    if (this.toAccountId) {
      this.timeoutService(() => {
        const checkreg = this.elem.find('#regMonthly')[0] as HTMLInputElement;
        if (checkreg) checkreg.checked = true;
        this.isRegularMonthly = true;
        this.regularMonthlyPayment = this.selectedToAccount.paymentAmount;
      }, 200);
    }
  }

  /**
   *
   */
  private loadWithdrawals(fromAcc: any): void {
    if (fromAcc == null || fromAcc.Type === 'Non-Axos Accounts') return;
    this.showWithdrawals = false;
    this.showWithdrawalsTo = false;
    if (!fromAcc.isExternal) {
      if (
        fromAcc.productType?.toLowerCase() === 'money market' ||
        fromAcc.productType?.toLowerCase() === 'savings'
      ) {
        this.showWithdrawals = false;
      }
    }
  }

  /**
   * Filter the frequencies sending their code
   * @returns Array<Frequency> filtered frequencies
   */
  private getFrequencies(freqs?: number[]): GenericOption[] {
    const allFrequencies: GenericOption[] = [
      { value: Frequency.ONETIME, label: mapFrequency(Frequency.ONETIME) },
      { value: Frequency.WEEKLY, label: mapFrequency(Frequency.WEEKLY) },
      {
        value: Frequency.EVERYTWOWEEKS,
        label: mapFrequency(Frequency.EVERYTWOWEEKS),
      },
      { value: Frequency.MONTHLY, label: mapFrequency(Frequency.MONTHLY) },
      { value: Frequency.QUARTERLY, label: mapFrequency(Frequency.QUARTERLY) },
      {
        value: Frequency.SEMIANNUALLY,
        label: mapFrequency(Frequency.SEMIANNUALLY),
      },
      { value: Frequency.ANNUALLY, label: mapFrequency(Frequency.ANNUALLY) },
    ];

    if (freqs == null) return allFrequencies;

    const frequencies: GenericOption[] = [];

    angular.forEach(freqs, function (value) {
      frequencies.splice(value - 1, 0, allFrequencies[value - 1]);
    });

    return frequencies;
  }

  private getFrequenciesMp() {
    const allFrequencies: GenericOption[] = [
      {
        value: mpFrequency.ONE_TIME,
        label: mpMapFrequency(mpFrequency.ONE_TIME),
      },
      { value: mpFrequency.WEEKLY, label: mpMapFrequency(mpFrequency.WEEKLY) },
      {
        value: mpFrequency.TWO_WEEKS,
        label: mpMapFrequency(mpFrequency.TWO_WEEKS),
      },
      {
        value: mpFrequency.MONTHLY,
        label: mpMapFrequency(mpFrequency.MONTHLY),
      },
      {
        value: mpFrequency.QUARTERLY,
        label: mpMapFrequency(mpFrequency.QUARTERLY),
      },
    ];

    return allFrequencies;
  }

  /** Changes the calendar options according to the frequency selection */
  private setCalendarOptions(selectedFrequency: number): void {
    this.isTodayValidForExternal = true;
    if (selectedFrequency == null) return;

    let today = this.dateHelper.normalizeDate(moment());
    // Store previously selected date
    const selectedDate = this.datePicker?.date
      ? moment(this.datePicker.date)
      : today;

    if (
      this.isTransferToNonRetirementRia() ||
      (this.hasExternalAccount &&
        !(
          this.selectedFromAccount.isTrading ||
          this.selectedToAccount.isTrading ||
          (this.isManagedPortfoliosEnabled &&
            (this.selectedToAccount.isAxosInvest ||
              this.selectedFromAccount.isAxosInvest))
        ))
    ) {
      let {
        isCutOffTime,
        externalTransferValidDate,
      } = this.dateHelper.getDefaultPaymentInformation(
        this.isTransferToNonRetirementRia(),
        this.isTransferToIra()
      );

      today = externalTransferValidDate;

      if (isCutOffTime) {
        this.isTodayValidForExternal = false;
      }
    }

    // Change Date to the starting permited date if not selected date from DatePicker
    this.date = selectedDate.isBefore(today) ? today : selectedDate;
    this.datePicker.date = {
      startDate: this.date,
      endDate: this.date,
    };
    // Min date for transaction to be done
    this.datePicker.options.minDate = today;
    // Allow selection 13 months in advance
    this.datePicker.options.maxDate = angular.copy(today).add(13, 'M');
    // Update the input field value
    const dateString = this.date.format('MM/DD/YY');
    this.timeoutService(() => {
      $('#date').val(dateString);
    }, 50);
  }

  private resetErrors(): void {
    this.amountErrors = null;
    this.lastDateErrors = null;
    this.numberTransfersErrors = null;
    this.sendUntilErrors = null;
    this.amountAdditionalErrors = null;
    this.regularMonthlyErrors = null;
    this.amountValid = true;
  }

  /**
   * Validates fields when is recurrent the frequency
   * @returns True if no errors, False otherwise
   */
  private isValidForRecurrent(): boolean {
    if (!this.numberTransfers) {
      this.numberTransfersErrors = {
        hasError: true,
        errorDescription: 'Please enter number of transfers',
      };
    } else {
      this.numberTransfersErrors = {
        hasError: false,
      };
    }

    if (this.selectedFrequency.value != Frequency.ONETIME) {
      if (this.selectedSendUntil) {
        switch (this.selectedSendUntil.value) {
          case 2:
            if (
              !this.numberTransfers ||
              this.numberTransfers == 0 ||
              !this.validNumber.test(this.numberTransfers.toString())
            ) {
              this.numberTransfersErrors = {
                hasError: true,
                errorDescription:
                  'You must specify a valid number of transfers',
              };

              return false;
            }
            break;
        }
      } else {
        this.sendUntilErrors = {
          hasError: true,
          errorDescription: 'You must select a valid option',
        };

        return false;
      }
    }

    return true;
  }

  private isValidForAxosInvest() {
    let amountValid = true;
    if (
      !this.isFromExternal &&
      this.amount > this.selectedFromAccount.availableBalance
    ) {
      this.amountErrors = {
        hasError: true,
        errorDescription: 'Insufficient funds available',
      };
      amountValid = false;
    }

    return amountValid;
  }

  /**
   * Validates the date and amount when the transfer is to Demand Deposit
   * If the date entered in the “Begin Sending On” or “Date” field = “Today”, then the amount entered cannot exceed:
   * a) The Available Balance in the “From” Account when the “From” Account is a Checking, Money Market, or Savings
   * b) The Available Credit in the “From” Account when the “From” account is a HELOC
   * @returns True if is valid, False otherwise
   */
  private isValidForDemandDeposit(): boolean {
    let amountValid = true;
    let recurrentValid = true;

    this.regularMonthlyErrors = null;
    if (isNaN(this.amount) || this.amount <= 0) {
      this.amountErrors = {
        hasError: true,
        errorDescription: 'Please enter Amount',
      };

      return false;
    }

    if (moment(this.date).isSame(moment(), 'd')) {
      if (this.isFromTrading) {
        if (this.amount > this.selectedFromAccount.amountAvailableToWithdraw) {
          this.amountErrors = {
            hasError: true,
            errorDescription: 'Insufficient funds available',
          };
          amountValid = false;
        }
      } else if (!this.isFromExternal) {
        if (
          (this.selectedFromAccount.productType === 'HELOC' ||
            this.selectedFromAccount.productType === 'SBLOC') &&
          this.selectedFromAccount.availableCredit
        ) {
          if (this.amount > this.selectedFromAccount.availableCredit) {
            this.amountErrors = {
              hasError: true,
              errorDescription: 'Insufficient funds available',
            };
            amountValid = false;
          }
        } else if (this.amount > this.selectedFromAccount.availableBalance) {
          this.amountErrors = {
            hasError: true,
            errorDescription: 'Insufficient funds available',
          };
          amountValid = false;
        }
      } else if (this.isFromExternal) {
        if (this.selectedFromAccount.availableBalance) {
          if (this.amount > this.selectedFromAccount.availableBalance) {
            this.amountErrors = {
              hasError: true,
              errorDescription: 'Insufficient funds available',
            };
            amountValid = false;
          }
        }
      }
    }

    if (this.selectedFrequency.value !== Frequency.ONETIME) {
      recurrentValid = this.isValidForRecurrent();
    }

    return amountValid && recurrentValid;
  }

  /**
   *  If the date entered = Today', then the Total Withdrawal Amount cannot exceed:
   *   a. The Available Balance in the 'From' Account when the 'From' Account is a Checking, Money Market, or Savings
   *   b. The Total Outstanding Balance in the 'To' Account
   * @returns True if is valid, False otherwise
   */
  private isValidForLoanAccount(): boolean {
    let validAmount = true;

    this.regularMonthlyErrors = null;
    if (this.totalAmount <= 0) {
      this.regularMonthlyErrors = {
        hasError: true,
        errorDescription: 'Please enter Amount',
      };

      return false;
    }

    if (moment(this.date).isSame(moment(), 'd')) {
      if (this.selectedFromAccount.isDeposit) {
        if (!this.isFromExternal) {
          if (this.totalAmount > this.selectedFromAccount.availableBalance) {
            this.regularMonthlyErrors = {
              hasError: true,
              errorDescription: 'Insufficient funds available',
            };
            validAmount = false;
          }
        } else if (this.isFromExternal) {
          if (this.totalAmount > this.selectedFromAccount.availableBalance) {
            this.regularMonthlyErrors = {
              hasError: true,
              errorDescription: 'Insufficient funds available',
            };
          }
        }
      }

      if (this.totalAmount > this.selectedToAccount.outstandingBalance) {
        if (!this.isFromExternal) {
          this.regularMonthlyErrors = {
            hasError: true,
            errorDescription: 'Payment exceeds your outstanding balance',
          };
          validAmount = false;
        }
      }
    }

    // If isFromExternal, the total amount cannot be more than 2x Regular Monthly Payment
    if (
      this.isFromExternal &&
      this.totalAmount > 2 * this.selectedToAccount.paymentAmount
    ) {
      this.regularMonthlyErrors = {
        hasError: true,
        errorDescription:
          'Total Payment cannot be more than 2x Regular Monthly Payment',
      };
      validAmount = false;
    }

    return validAmount;
  }

  /**
   * Validates the date and amount when the transfer is to Overdraft Line of Credit (PTYPE1)
   * If the date entered in the “Date” field = “Today” then the amount entered cannot exceed:
   * a) The Available Balance in the “From” Account when the “From” Account is a Checking, Money Market, or Savings.
   * b) The Total Outstanding Balance in the “To” Account.
   * The amount entered cannot be < the current minimum amount due.
   * @returns True if is valid, False otherwise
   */
  private isValidForLineOfCredit(): boolean {
    let validAmount = true;

    if (!this.amountValid) {
      return false;
    }

    if (this.selectedFrequency.value == Frequency.ONETIME) {
      if (this.amount < this.selectedToAccount.minimumAmountDue) {
        this.amountErrors = {
          hasError: true,
          errorDescription: 'The amount does not cover the current amount due',
        };
        validAmount = false;
      }
      if (
        this.selectedFromAccount.isDeposit &&
        moment(this.date).isSame(moment(), 'd')
      ) {
        if (this.amount > this.selectedFromAccount.availableBalance) {
          this.amountErrors = {
            hasError: true,
            errorDescription: 'Insufficient funds available',
          };
          validAmount = false;
        }
        if (this.amount > this.selectedToAccount.outstandingBalance) {
          this.amountErrors = {
            hasError: true,
            errorDescription: 'Payment exceeds your outstanding balance',
          };
          validAmount = false;
        }
      }
    }

    return validAmount;
  }

  /** Verifies if the process is valid or not for mortgage transfers */
  private isValidForMortgage(): boolean {
    let validAmount = true;

    this.regularMonthlyErrors = null;
    if (this.totalAmount <= 0) {
      this.regularMonthlyErrors = {
        hasError: true,
        errorDescription: 'Please enter Amount',
      };

      return false;
    }

    // If the date entered = Today', then the Total Withdrawal Amount cannot exceed:
    // a. The Available Balance in the 'From' Account when the 'From' Account is a Checking, Money Market, or Savings
    // b. The Total Outstanding Balance in the 'To' Account
    if (moment(this.date).isSame(moment(), 'd')) {
      if (this.selectedFromAccount.isDeposit) {
        if (!this.isFromExternal) {
          if (this.totalAmount > this.selectedFromAccount.availableBalance) {
            this.regularMonthlyErrors = {
              hasError: true,
              errorDescription: 'Insufficient funds available',
            };
            validAmount = false;
          }
        }
      }

      if (this.totalAmount > this.selectedToAccount.outstandingBalance) {
        if (!this.isFromExternal) {
          this.regularMonthlyErrors = {
            hasError: true,
            errorDescription: 'Payment exceeds your outstanding balance',
          };
          validAmount = false;
        }
      }
    }

    // If isFromExternal, the total amount cannot be more than 2x Regular Monthly Payment
    if (
      this.isFromExternal &&
      this.totalAmount > 2 * this.selectedToAccount.paymentAmount
    ) {
      this.regularMonthlyErrors = {
        hasError: true,
        errorDescription:
          'Total Payment cannot be more than 2x Regular Monthly Payment',
      };
      validAmount = false;
    }

    return validAmount;
  }

  private hasChanges(): boolean {
    if (
      this.selectedFromAccount !== null &&
      this.selectedFrequency !== null &&
      this.amount !== 0
    ) {
      return true;
    }

    if (this.showMortgageSection) {
      if (
        (this.selectedFromAccount !== null ||
          this.selectedToAccount !== undefined) &&
        (this.selectedFrequency !== null ||
          this.selectedFrequency !== undefined) &&
        this.totalAmount !== 0
      ) {
        return true;
      }
    }

    return false;
  }

  private updateAccountAmounts(accounts: AccountsPage): void {
    if (!this.root['accounts']) return;

    const depositAccounts: OlbAccount[] = accounts.depositAccounts;
    const loanAccounts: OlbAccount[] = accounts.loanAccounts;

    if (depositAccounts) {
      depositAccounts.forEach(account => {
        if (!account.isIra) return;

        account.availableBalance = account.currentBalance;
      });
      this.root['accounts'].depositAccounts = depositAccounts;
    }

    if (loanAccounts) {
      this.root['accounts'].loanAccounts = loanAccounts;
    }
  }

  private UpdateTradingAccounts() {
    this.axosClearingService
      .getAccounts()
      .then((res: OlbResponse<TradingAccount[]>) => {
        this.store.dispatch(
          loadTradingAccounts({
            payload: JSON.parse(JSON.stringify(res.data || [])),
          })
        );
        this.cachedTradingAccountsService.loadTradingAccounts(res.data || []);
      });
  }

  private isMortgage(account: NewOlbAccount): boolean {
    return account.productType?.toLowerCase() === 'mortgage';
  }

  private setShowIRADistributionDisclaimer() {
    const isIra = this.fromAccountIsValidIra();

    this.showIRADistributionDisclaimer =
      moment('12/31', 'MM/DD').diff(moment(this.userDOB), 'years', true) >=
        70.5 &&
      isIra &&
      !!this.selectedFromAccount.requiredMinimumDistributionAmount &&
      this.selectedToAccount &&
      !this.selectedToAccount.isIra;
  }

  private setShowIRAWithdrawalDisclaimer() {
    const isIra = this.fromAccountIsValidIra();
    this.showIRAWithdrawalDisclaimer =
      this.userAge < 59.5 &&
      isIra &&
      this.selectedToAccount &&
      !this.selectedToAccount.isIra;
  }

  private filterToAccountsFromIRA(
    internalAccounts: OlbAccount[]
  ): OlbAccount[] {
    const rules: IIraTxConstraint[] = JSON.parse(
      this.root['appSettings'].find(
        (ap: any) => ap.name === 'IraTransactionConstraint'
      ).value
    );

    let toIraAccounts: OlbAccount[] = internalAccounts.filter(
      a => a.isDeposit && !a.isIra
    );
    rules.forEach(r => {
      if (r.from.includes(this.selectedFromAccount.productCode)) {
        toIraAccounts = toIraAccounts.concat(
          internalAccounts.filter(a => a.isIra && r.to.includes(a.productCode))
        );
      }
    });

    return toIraAccounts;
  }

  private fromAccountIsValidIra(): boolean {
    let isValidIra = false;

    if (this.selectedFromAccount) {
      const rules: IIraTxConstraint[] = JSON.parse(
        this.root['appSettings'].find(
          (ap: any) => ap.name === 'IraTransactionConstraint'
        ).value
      );

      rules.forEach(r => {
        if (
          r.from.includes(this.selectedFromAccount.productCode) &&
          this.selectedFromAccount.isIra
        ) {
          isValidIra = true;
        }
      });

      if (!isValidIra && this.isTransferFromIraTrading()) {
        isValidIra = true;
      }

      if (!isValidIra && this.isTransferFromRetirementRia()) {
        isValidIra = true;
      }
    }

    return isValidIra;
  }

  private showLeaveModal() {
    if (this.dialog) return;

    const dialogData = new DialogData({
      title: 'Are you sure you want to leave?',
      content:
        '<p class="ms-secondary-text">This transfer has not been completed</p>',
      cancelText: 'Stay',
      okText: 'Leave',
    });

    this.dialog = this.dialogService.open(dialogData);

    this.subSink.sink = this.dialog.afterClosed().subscribe(leave => {
      if (leave) {
        this.redirectState();
      }

      this.dialog = null;
    });
  }

  private loadContributionYears() {
    if (this.contributionsYears.length === 0) {
      this.transactionService.getContributionYears().then(({ data }) => {
        const toDay = this.dateHelper.normalizeDate(moment());

        let currentContributionYear = data.filter(
          cy =>
            this.dateHelper.normalizeDate(moment(cy.startDate)) <= toDay &&
            this.dateHelper.normalizeDate(moment(cy.endDate)) >= toDay
        );
        this.contributionsYears = currentContributionYear;
        this.cyear = currentContributionYear[0];

        this.calculateContributions();
      });
    }
  }
  calculateContributions() {
    if (
      this.isIraEnhDiraFlagActive &&
      this.isIraAccount(this.selectedToAccount)
    ) {
      var contributionsForSelectedYear = this.totalContributions.find(
        x => x.year === this.cyear.year
      );
      if (contributionsForSelectedYear != undefined) {
        //if user is below 50 years
        if (this.userAge < 50) {
          var stillContributeBelowAge =
            this.cyear.maxAmountMinor50 - contributionsForSelectedYear.amount;
          this.stillContribute = stillContributeBelowAge;
          this.haveContributed = contributionsForSelectedYear.amount;
          this.isContributedAmountReached =
            stillContributeBelowAge <= 0 ? true : false;
        } else {
          //if the user is >= 50 years
          var stillContributeAboveAge =
            this.cyear.maxAmountOlderThan50 -
            contributionsForSelectedYear.amount;
          this.stillContribute = stillContributeAboveAge;
          this.haveContributed = contributionsForSelectedYear.amount;
          this.isContributedAmountReached =
            stillContributeAboveAge <= 0 ? true : false;
        }

        this.contributionReachedMessage = this.isContributedAmountReached
          ? `You've already reached your contribution limit for ${this.cyear.year}`
          : `You May Still Contribute ${this.filterService('currency')(
              this.stillContribute
            )}`;
      } else {
        //if user has not contribute any amount
        this.stillContribute =
          this.userAge < 50
            ? this.cyear.maxAmountMinor50
            : this.cyear.maxAmountOlderThan50;
        this.contributionReachedMessage = `You May Still Contribute ${this.filterService(
          'currency'
        )(this.stillContribute)}`;
      }
    }
  }

  /**
   * Validates a date to check if it's valid in a Contribution Year.
   * @param date Date to validate.
   * @returns True if the given date is in a Contribution Year period.
   */
  private isValidContributionYearDate(date: moment.Moment): boolean {
    if (!this.isTransferToIraTrading()) {
      return true;
    }

    const normalizedDate = this.dateHelper.normalizeDate(date);
    const normalizedEndDate = this.dateHelper.normalizeDate(moment());

    return normalizedDate <= normalizedEndDate;
  }

  private getDisplayNameFormat(account: any): string {
    if (
      (account.productType === 'HELOC' || account.productType === 'SBLOC') &&
      account.availableCredit !== null
    ) {
      return `(Avail. Credit: ${account.availableCredit.toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
        }
      )})`;
    } else {
      return `(Avail. Bal: ${account.availableBalance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })})`;
    }
  }

  private mapLibertyFrequencies(frequency: number): number {
    switch (frequency) {
      case Frequency.ONETIME:
        return AxosAdvisoryTransferFrequency.OneTime;
      case Frequency.MONTHLY:
        return AxosAdvisoryTransferFrequency.Monthly;
      case Frequency.QUARTERLY:
        return AxosAdvisoryTransferFrequency.Quarterly;
      case Frequency.SEMIANNUALLY:
        return AxosAdvisoryTransferFrequency.SemiAnnually;
      case Frequency.ANNUALLY:
        return AxosAdvisoryTransferFrequency.Annually;
    }
  }

  private clearFields(): void {
    this.timeoutService(() => {
      this.isValidIraTransaction = false;
      this.selectedFromAccount = null;
      this.selectedToAccount = null;
      this.toAccountsArray = [];
      this.frequencies = [];
      this.selectedFrequency = null;
      this.datePicker.date = { startDate: moment(), endDate: moment() };
    }, 100);
  }

  private internalServerError(showPopup: boolean): string {
    const defaultMessage = showPopup
      ? `<div><img src="/img/icons/illustration-area.svg" /></div>
      It seems like we've stumbled upon a little glitch. But we’re on it! We apologize for the inconvenience. Please try again shortly.`
      : 'Something went wrong. Something unexpected went wrong on our end. Please try again. Thank you.';
    const modalSettings: ModalSettings = {
      headerText: 'Oops! A Tiny Hiccup.',
      bodyText: defaultMessage,
      okText: 'Okay',
      hasCancelButton: false,
      hasIcon: false,
      hasHeaderText: true,
      okButtonClass: 'new-button-okay',
    };
    if (showPopup) {
      this.modalService
        .show(
          { windowClass: 'modal-service internal-error-modal' },
          modalSettings
        )
        .then(() => {})
        .catch(() => {
          return defaultMessage;
        });
    }

    return defaultMessage;
  }

  private filterAccounts(
    isSBBActive: boolean,
    isFundingRunning: boolean
  ): void {
    if (isSBBActive && !isFundingRunning) {
      if (this.selectedFromAccount.isExternal) {
        this.toAccountsArray = this.toAccountsArray.filter(
          acc =>
            (acc.Type === AccountTypeConstants.INTERNAL_ACCOUNTS &&
              acc.isSBB === false) ||
            acc.Type === AccountTypeConstants.INVESTMENT_ACCOUNTS
        );
      }

      // Don't show Axos Business or External Business if Any Investment Account is Selected From
      if (
        this.selectedFromAccount.Type ===
        AccountTypeConstants.INVESTMENT_ACCOUNTS
      ) {
        this.toAccountsArray = this.toAccountsArray.filter(
          acc =>
            (acc.Type === AccountTypeConstants.INTERNAL_ACCOUNTS &&
              !acc.isSBB) ||
            (acc.Type == AccountTypeConstants.NON_AXOS_ACCOUNTS &&
              !acc.isSBB) ||
            acc.Type === AccountTypeConstants.INVESTMENT_ACCOUNTS
        );
      }

      if (
        !this.selectedFromAccount.isSBB &&
        this.selectedFromAccount.Type === AccountTypeConstants.INTERNAL_ACCOUNTS
      ) {
        this.toAccountsArray = this.toAccountsArray.filter(
          acc =>
            acc.Type === AccountTypeConstants.INTERNAL_ACCOUNTS ||
            (acc.Type == AccountTypeConstants.NON_AXOS_ACCOUNTS &&
              !acc.isSBB) ||
            acc.Type === AccountTypeConstants.INVESTMENT_ACCOUNTS
        );
      }

      if (this.selectedFromAccount.isSBB) {
        this.toAccountsArray = this.toAccountsArray.filter(
          ac =>
            ac.Type === AccountTypeConstants.INTERNAL_ACCOUNTS ||
            ac.Type === AccountTypeConstants.NON_AXOS_ACCOUNTS
        );
      }
    }
    if (isSBBActive && isFundingRunning) {
      this.toAccountsArray = this.toAccountsArray.filter(
        acc => acc.availableBalance <= 0
      );
    }
  }

  private openRedirectToIPayModal(): void {
    const modal = this.matDialog.open(RedirectToIpayModalComponent, {
      data: { id: this.selectedFromAccount.id, isAccountId: true },
    });
    modal.afterClosed().subscribe(() => {
      this.selectedToAccount = null;
    });
  }

  private validateFromAvailableFunds() {
    let errorMessage = null;
    if (
      !isNil(this.selectedFromAccount.availableBalance) &&
      this.amount > this.selectedFromAccount.availableBalance
    ) {
      errorMessage = 'Insufficient Funds available';
      this.amountValid = false;
    } else {
      this.amountValid = true;
      this.errorMessage = null;
      this.calculateWithholdValue();
    }
    return errorMessage;
  }
}
