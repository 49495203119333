import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { getTradingAccount } from '@app/accounts/store/selectors';
import { TradingAccount } from '@app/axos-trading/models';
import { SubSink } from '@axos/subsink';
import { STATE_PARAMS } from '@core/tokens';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-experience-levels',
  templateUrl: './experience-levels.component.html',
  styleUrls: ['./experience-levels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceLevelsComponent implements OnInit, OnDestroy {
  investment: TradingAccount;
  isExpanded = false;
  public subsink = new SubSink();
  accountId: number;

  constructor(@Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService, private store: Store) {
    this.accountId = Number(this.params.id);
  }

  ngOnInit(): void {
    this.getExperienceDetails();
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
  private getExperienceDetails(): void {
    this.subsink.sink = this.store
      .select(getTradingAccount(this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.investment = account;
      });
  }
}
