import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { AccountTab } from '@app/accounts/enums';
import { AccountSelectItem } from '@app/accounts/models';
import { NavigationIcons } from '@shared/enums';

@Component({
  selector: 'acc-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSelectComponent implements OnChanges, OnInit {
  @HostBinding('class.expanded')
  expanded = false;

  @Input()
  items: AccountSelectItem[];
  @Input()
  selectedItem: AccountSelectItem;
  @Input()
  selectedTab: AccountTab;
  isRia: boolean;
  @Output()
  selectItem = new EventEmitter<Partial<AccountSelectItem>>();
  openIcon = NavigationIcons.ChevronDown;
  filteredItems: AccountSelectItem[];

  constructor() {}
  ngOnInit(): void {
    if (this.selectedItem) this.setAccountName(this.selectedItem?.name, this.selectedItem?.id);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItem && this.items) {
      this.filteredItems = this.items.filter(acc => acc.id !== this.selectedItem.id);
    }
  }
  checkRia() {
    return this.selectedItem && this.selectedItem.icon === 'advisorIcon';
  }

  setAccountName(name: string, accountNumber: number | string) {
    const stringHelper = '**';
    if (typeof accountNumber === 'number') accountNumber = accountNumber.toString();
    const accountHelper = accountNumber.substring(accountNumber.length - 4);
    if (name.includes(accountHelper)) return name;
    this.selectedItem.name = name + `${stringHelper}${accountHelper}`;
  }
}
