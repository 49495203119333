import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { FinancialInfo } from '@app/user-profile/models';
import {
  getAnnualIncomes,
  getLiquidNetWorths,
  getTaxBrackets,
  getTotalNetWorths,
} from '@app/user-profile/store/selectors';
import { mapObjectToKeyValuePair } from '@app/utils';
import { KeyValuePair } from '@core/models';
import { UserProfileService } from '@core/services';
import { ServiceHelper } from '@legacy/services/service.helper';

@Component({
  selector: 'up-change-financial-info',
  templateUrl: './change-financial-info.component.html',
  styleUrls: ['../../scss/user-profile.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeFinancialInfoComponent implements OnInit {
  @Input() financialInfo: FinancialInfo;

  @Output() updateFinancialInfo = new EventEmitter<FinancialInfo>();
  @Output() back = new EventEmitter<null>();

  financialInfoForm: UntypedFormGroup;
  isBusy = false;
  annualIncomes$: Observable<KeyValuePair<string, string>[]>;
  totalNetWorths$: Observable<KeyValuePair<string, string>[]>;
  taxBrackets$: Observable<KeyValuePair<string, string>[]>;
  liquidNetWorths$: Observable<KeyValuePair<string, string>[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private userProfileService: UserProfileService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit(): void {
    const { annualIncome, totalNetWorth, taxBracket, liquidNetWorth } = this.financialInfo;
    this.annualIncomes$ = this.store
      .select(getAnnualIncomes)
      .pipe(map(catalog => mapObjectToKeyValuePair(catalog)));
    this.totalNetWorths$ = this.store
      .select(getTotalNetWorths)
      .pipe(map(catalog => mapObjectToKeyValuePair(catalog)));
    this.taxBrackets$ = this.store
      .select(getTaxBrackets)
      .pipe(map(catalog => mapObjectToKeyValuePair(catalog)));
    this.liquidNetWorths$ = this.store
      .select(getLiquidNetWorths)
      .pipe(map(catalog => mapObjectToKeyValuePair(catalog)));

    this.financialInfoForm = this.fb.group({
      annualIncome: this.fb.control(annualIncome ?? ''),
      totalNetWorth: this.fb.control(totalNetWorth ?? ''),
      taxBracket: this.fb.control(taxBracket ?? ''),
      liquidNetWorth: this.fb.control(liquidNetWorth ?? ''),
    });
  }

  submitFinancialInfo() {
    this.isBusy = true;
    const financialInfo = new FinancialInfo(this.financialInfoForm.value);

    this.userProfileService
      .updateFinancialInfo(financialInfo)
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe({
        next: () => {
          this.updateFinancialInfo.emit(financialInfo);
          this.back.emit();
        },
        error: this.serviceHelper.errorHandler.bind(this.serviceHelper),
      });
  }
}
