<div ui-view="eStatements" class="e-statements">
  <div class="header-section-title text-center hide-responsive">
    <div>
      <h1>Set Up eBills</h1>
    </div>
  </div>
  <app-dot-loader [showWhen]="isLoading && !currentStep">Loading</app-dot-loader>
  <div class="frow center error-message">
    <app-alert-notification
      class="fcol-cs-12 fcol-md-6"
      *ngIf="errorMessage"
      message="errorMessage"
      alertType="error"
      (dismiss)="errorMessage = null"
    >
      {{ errorMessage }}
    </app-alert-notification>
  </div>

  <div class="container e-statements-content" id="e-statements-content">
    <div *ngIf="currentStep == 0 || currentStep == 1">
      <div class="cancel-responsive">
        <span (click)="cancelSetUp()">&#10005;</span>
      </div>
      <div class="frow subtitle">
        <a class="fcol-sm-2 go-back hide-responsive link" (click)="goBack()">
          <img src="/img/icons/billPay/back_icon.svg" width="15" aria-hidden="true" class="back-arrow" />
          Back
        </a>
        <p class="fcol-sm-12 text-center">
          {{ subtitle }}
        </p>
      </div>

      <div class="frow center info">
        <a class="fcol-sm-12 text-center link" (click)="toggleInfo()">
          <i class="bofi-arrow-chevron info__icon" [ngClass]="{ up: displayInfo, down: !displayInfo }"></i>
          How does this work?
        </a>
        <div class="fcol-sm-10 text-center info__text" [hidden]="!displayInfo">
          <span>
            Once you’re logged in, we’ll connect your account bills to Bill Pay. Your login credentials will remain
            private – we do not store login information.
          </span>
        </div>
      </div>
    </div>

    <!--For selecting site-->
    <div *ngIf="currentStep == 0">
      <div class="frow center">
        <ul class="fcol-cs-8 recipient-sites">
          <li *ngFor="let site of sites" (click)="selectEBillSite(site)">
            <a>{{ site.value }}</a>
          </li>
        </ul>
      </div>

      <div class="frow center">
        <button type="button" class="uk-btn lg outline primary" (click)="goBack()">
          Cancel Setup
        </button>
      </div>
    </div>

    <!--For login-->
    <div *ngIf="currentStep == 1" class="frow center login-form">
      <form #loginForm="ngForm" name="loginForm" id="loginForm" (submit)="verifyEBillLogin(credentials)">
        <div class="form-group" *ngFor="let item of credentials">
          <label *ngIf="item.label.toLowerCase() !== 'password'" class="uk-label">{{ item.label }}</label>
          <input
            *ngIf="item.label.toLowerCase() !== 'password'"
            class="uk-input"
            type="text"
            [name]="item.label.toLowerCase()"
            [(ngModel)]="item.value"
            required
          />
          <app-reveal-input-label *ngIf="item.label.toLowerCase() == 'password'" [target]="item.label.toLowerCase()">
            Password
          </app-reveal-input-label>
          <input
            *ngIf="item.label.toLowerCase() == 'password'"
            class="uk-input"
            type="password"
            [name]="item.label.toLowerCase()"
            [id]="item.label.toLowerCase()"
            [(ngModel)]="item.value"
            required
          />
        </div>
        <div class="frow center form-buttons">
          <button
            class="uk-btn primary outline lg hide-responsive"
            type="button"
            (click)="cancelSetUp()"
            [disabled]="isLoading"
          >
            Cancel Setup
          </button>
          <button
            class="uk-btn primary outline lg show-responsive"
            type="button"
            (click)="goBack()"
            [disabled]="isLoading"
          >
            Back
          </button>
          <button
            class="uk-btn secondary solid lg"
            form="loginForm"
            type="submit"
            [disabled]="loginForm.invalid || isLoading"
          >
            Sign In
          </button>
        </div>
        <app-dot-loader [showWhen]="isLoading">It may take a while</app-dot-loader>
      </form>
    </div>
  </div>
</div>
