import { Action, createReducer, on } from '@ngrx/store';

import { addOrganizationAction, initialOrganizationSettingsStateType, OrganizationSettingsStateType } from '..';

import { signOut } from '@app/store/actions';

const reducer = createReducer(
  initialOrganizationSettingsStateType,
  on(addOrganizationAction, (_state, action) => {
    return {
      organizations: [..._state.organizations, action.payload],
      selectedOrganizationName: action.payload.name,
    };
  }),
  on(signOut, () => {
    return initialOrganizationSettingsStateType;
  })
);

export function organizationSettingsReducer(
  state: OrganizationSettingsStateType,
  action: Action
): OrganizationSettingsStateType {
  return reducer(state, action);
}
