<ng-template #smsAlerts>
  <div class="modal-container">
    <div class="modal-header">
      <div>
        Text Message Activation
      </div>
      <div class="close-modal">
        <i class="bofi-close" (click)="closeModal()"></i>
      </div>
    </div>
    <div class="modal-body modalBody">
      <p class="textModal" style="font-size: 14px;" [innerHtml]="smsobject.title"></p>
      <div class="flex">
        <input
          style="height: 45px;"
          class="uk-input textInput"
          type="text"
          value="{{ phonenumber }}"
          aria-label="Phone number"
          name="inputPhoneNumber"
          ui-mask="(999) 999-9999"
          disabled="disabled"
        />
      </div>

      <div class="text--error initial" *ngIf="phoneError">
        "Please enter a valid phone number.
      </div>
      <div class="initial">
        By providing your phone number and selecting consent, you have agreed to these
        <a target="_blank" href="{{ smstermsandconditionsbrand }}">
          Terms and Conditions,
        </a>
        and you have agreed to receive automated text messages, calls, and emails for any purpose including but not
        limited to marketing of products and services by {{ brandname }}. You understand and agree that such messages
        may be sent via Automatic Telephone Dialing System and/or artificial or pre-recorded voice, and that such
        consent is not a condition of receipt of any good or service. Mobile carrier messages and data rates may apply.
        You may opt out at any time.
      </div>
      <div class="flexButtons" *ngIf="!(gettingCode$ | async)">
        <div>
          <button class="uk-btn lg solid secondary" (click)="openAccessCodeModal()">
            Accept &amp; Continue
          </button>
        </div>
        <div>
          <button class="uk-btn outline primary lg" data-dismiss="modal" (click)="cancelSMSChanges()">
            Cancel
          </button>
        </div>
      </div>
      <app-dot-loader *ngIf="gettingCode$ | async"></app-dot-loader>
    </div>
  </div>
</ng-template>

<ng-template #accessCodeModal>
  <div class="access-modal-container">
    <div class="access-modal-header">
      <div>
        Verification Code
      </div>
      <div class="access-close-modal">
        <i class="bofi-close" (click)="closeModal()"></i>
      </div>
    </div>
    <div class="modal-body modalBody">
      <form id="verificationModalForm" #accessCodeForm="ngForm" (submit)="submitAccessCode(accessCodeForm)" novalidate>
        <p class="modal-access-text">
          We’ve sent a text with your verification code to
          <br />
          {{ maskPhone(userphonenumber) }}
        </p>
        <br />
        <p class="modal-access-subText">Enter your code and click continue.</p>
        <br />
        <div class="modal-access-inputs">
          <input
            type="text"
            name="accessCode"
            aria-label="Access code"
            [(ngModel)]="accessCode"
            (keydown)="allowOnlyNumbers($event)"
            maxlength="8"
            class="modal-access-input"
            autofocus
            required
          />
        </div>
        <p *ngIf="errorCode" class="text--error">Could not validate Verification Code</p>
        <div class="text--error" style="text-align: center;" *ngIf="accessCodeForm.submitted && accessCodeForm.invalid">
          {{ otpvalidationmessage }}
        </div>
        <div class="modal-access-resendDiv">
          <p class="modal-access-resendText">
            Didn’t receive your code?
            <a
              tabindex="1"
              id="helper"
              class="verificationModalResend link"
              (keydown)="resendKeyPress($event)"
              (click)="resendCode()"
            >
              RESEND
            </a>
          </p>
        </div>
        <div class="modal-access-buttons" *ngIf="!(sendingCode$ | async) && !(gettingCode$ | async)">
          <button
            class="uk-btn outline primary lg"
            type="button"
            tabindex="2"
            data-dismiss="modal"
            (click)="cancelSMSOTP()"
          >
            Cancel
          </button>
          &nbsp; &nbsp; &nbsp;
          <button id="verificationModalBtn" class="uk-btn solid secondary lg" tabindex="3" type="submit">
            Continue
          </button>
        </div>
        <app-dot-loader *ngIf="(sendingCode$ | async) || (gettingCode$ | async)"></app-dot-loader>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #successModal>
  <div class="success-modal-container">
    <div class="success-modal-header">
      <div class="fcol-cs-offset-11">
        <i class="bofi-close" (click)="closeSuccessModal()"></i>
      </div>
      <i class="bofi-success" style="font-size: 82px;"></i>
    </div>

    <div class="sucess-modal-body">
      <p class="success-modal-title">Success!</p>
      <br />
      <p class="success-modal-text">{{ smsobject.successMsg }}</p>

      <div class="modal-success-buttons">
        <button type="button" class="uk-btn modal-access-button solid" (click)="closeSuccessModal()">
          DONE
        </button>
      </div>
    </div>
  </div>
</ng-template>
