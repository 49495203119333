import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TradeStockType } from '@app/axos-trading/enums';
import { Equity, MutualFundOrder, TradingAccount } from '@app/axos-trading/models';
import { MutualFundService } from '@app/axos-trading/services';
import { ServiceHelper } from '@legacy/services/service.helper';
import { NavigationIcons } from '@shared/enums';
import { ConfirmationField } from '@shared/models';

@Component({
  selector: 'app-trade-stock-mutual-review',
  templateUrl: './trade-stock-mutual-review.component.html',
  styleUrls: ['./trade-stock-mutual-review.component.scss'],
  providers: [CurrencyPipe],
})
export class TradeStockMutualReviewComponent implements OnInit {
  @Input() account: TradingAccount;
  @Input() stock: Equity;
  @Input() tradeType: TradeStockType;
  @Input() mutualFundOrder: MutualFundOrder;
  @Output() goBack = new EventEmitter<void>();
  @Output() orderSubmitted = new EventEmitter<any>();
  fields: ConfirmationField[] = [];
  displayMarketValidation = false;
  submittingOrder: boolean;
  icons = {
    backIcon: NavigationIcons.ArrowBack,
  };
  constructor(
    private currencyPipe: CurrencyPipe,
    private mutualFundService: MutualFundService,
    private serviceHelper: ServiceHelper
  ) {}

  ngOnInit() {
    this.mutualFundOrder = this.buildMutualFundOrder();
    this.createMutualFundsFields();
  }

  sendOrder() {
    this.submittingOrder = true;
    this.previewMutualFundOrder()
      .then(() => this.submitMutualFundOrder())
      .then(result => {
        this.mutualFundOrder.orderReference = result.orderReference;
        this.orderSubmitted.emit(this.mutualFundOrder);
      })
      .catch(errors => this.serviceHelper.errorHandler(errors))
      .finally(() => (this.submittingOrder = false));
  }

  private buildMutualFundOrder() {
    return new MutualFundOrder({
      stock: `${this.stock.stockName} (${this.stock.symbol.slice(0, 5)})`,
      accountNumber: this.account.accountNumber,
      symbol: this.stock.symbol,
      value: this.mutualFundOrder.value,
      quantity: this.mutualFundOrder.quantity,
      transactionTypeDescription: this.tradeType,
      stockType: this.stock.type,
    });
  }
  private previewMutualFundOrder() {
    return this.mutualFundService.previewMutualFundOrder(this.mutualFundOrder).toPromise();
  }

  private submitMutualFundOrder() {
    return this.mutualFundService.placeMutualFundOrder(this.mutualFundOrder).toPromise();
  }

  private createMutualFundsFields() {
    this.fields.push(new ConfirmationField('Stock', `${this.stock.stockName} (${this.stock.symbol.slice(0, 5)})`));
    if (this.tradeType === TradeStockType.Buy) {
      this.fields.push(
        new ConfirmationField('Total Buy Price', this.currencyPipe.transform(this.mutualFundOrder.value))
      );
    }
    if (this.tradeType === TradeStockType.Sell) {
      this.fields.push(new ConfirmationField('Amount of Shares', this.mutualFundOrder.quantity.toString()));
    }
  }
}
