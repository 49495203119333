<div class="multifactor">
  <h2 class="multifactor__title">{{ title }}</h2>

  <p class="multifactor__information">
    {{ subtitle }}
  </p>

  <app-alert-notification *ngIf="noMethodAvailable" alertType="error">
    In order to login to online banking, we must first verify your contact information. Please call customer service at
    {{ supportPhone }}
  </app-alert-notification>

  <div class="multifactor__options">
    <button class="multifactor__option option--text" *ngIf="smsAvailable" (click)="continue(authMethod.Sms)">
      <app-svg-icon
        class="multifactor__option-icon"
        [iconName]="icons.message"
        iconColor="var(--button-color)"
      ></app-svg-icon>
      Text Message
      {{ phone }}
    </button>
    <button class="multifactor__option option--email" *ngIf="emailAvailable" (click)="continue(authMethod.Email)">
      <app-svg-icon
        class="multifactor__option-icon"
        [iconName]="icons.email"
        iconColor="var(--button-color)"
      ></app-svg-icon>
      Email
      {{ email }}
    </button>
  </div>

  <div class="multifactor__disclaimer">
    <p class="text--small">*Standard text messaging and data rates may apply.</p>
  </div>

  <app-dot-loader [showWhen]="isLoading">Sending your code</app-dot-loader>
</div>
