import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { STATE } from '@core/tokens';
import {
  Uk2Tier1CommunicationEnum,
  Uk2Tier1FinancialEnum,
} from '@axos/uikit-v2-lib';
import { AnimationOptions } from 'ngx-lottie';
import { AddInvestorCheckingSuccessData } from './types';
import { ACCOUNT_NAME, SUCCESS_DIALOG } from './constants';
import { FeatureFlagService } from '@legacy/services/feature-flag.service';
import {
  addSharedAccountsPrompting,
  SharedAccountsService,
  SharedAccountsStateType,
} from '@app/Areas/AAS/features/account-details/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ShareAccountRiaComponent } from '../share-account-ria/view';

@Component({
  selector: 'app-add-investor-checking-success',
  templateUrl: './add-investor-checking-success.component.html',
  styleUrls: ['./add-investor-checking-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddInvestorCheckingSuccessComponent {
  uk2Tier1CommunicationEnum = Uk2Tier1CommunicationEnum;
  uk2Tier1FinancialEnum = Uk2Tier1FinancialEnum;
  investorCheckingAccountNickname: string;
  successMessage: string;
  email: string;
  riaPilotICODLDebitCardFlagActive: boolean;

  approvedAnimationOptions: AnimationOptions = {
    path: '/assets/animations/approved.json',
    loop: false,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddInvestorCheckingSuccessData,
    @Inject(STATE) private state: ng.ui.IStateService,
    public dialogRef: MatDialogRef<AddInvestorCheckingSuccessComponent>,
    private featureFlagService: FeatureFlagService,
    private store: Store,
    private sharedAccountsService: SharedAccountsService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.riaPilotICODLDebitCardFlagActive = this.featureFlagService.isRiaPilotICODLDebitCardActive();
    this.setDialogData();
  }

  setDialogData() {
    this.investorCheckingAccountNickname = `${ACCOUNT_NAME} - ${this.data.investorCheckingAccountNickname}`;
    this.email = this.data.email;
    this.setSuccessMessage();
  }

  setSuccessMessage() {
    if (this.riaPilotICODLDebitCardFlagActive) {
      this.successMessage = this.data.wasOdlSelected
        ? SUCCESS_DIALOG.WITH_OVERDRAFT_ICODL
        : SUCCESS_DIALOG.WITHOUT_OVERDRAFT;
    } else {
      this.successMessage = this.data.wasOdlSelected
        ? SUCCESS_DIALOG.WITH_OVERDRAFT
        : SUCCESS_DIALOG.WITHOUT_OVERDRAFT;
    }
  }

  close() {
    this.dialogRef.close();
    this.state.go('udb.dashboard');
  }

  goToMoveMoney() {
    this.dialogRef.close();
    this.state.go('udb.transfers.transferFunds');
  }

  getAllSharedAccounts() {
    if (this.featureFlagService.IsRiaPilotAccountSharing()) {
      this.sharedAccountsService
        .getSharedAccountsPrompting()
        .subscribe(response => {
          if (response.data.accounts.length !== 0) {
            const sharedAccountsStateTypeState: SharedAccountsStateType = {
              sharedAccounts: response.data,
            };
            this.store.dispatch(
              addSharedAccountsPrompting({
                payload: sharedAccountsStateTypeState,
              })
            );
            this.showSharedAccountsModalModal();
          }
        });
    }
  }

  private showSharedAccountsModalModal() {
    const modalSharedAccounts = this.matDialog.open(ShareAccountRiaComponent, {
      maxWidth: 1440,
      width: '1440px',
      height: '900px',
      maxHeight: 900,
      disableClose: true,
    });
    modalSharedAccounts.componentInstance.closeModal.subscribe(() => {
      modalSharedAccounts.close();
    });
  }
}
