import { Store } from '@ngrx/store';

import { SubSink } from '@axos/subsink';
import { IStateParamsService, IStateService } from 'angular-ui-router';

import { getProfileDetails } from '@app/user-profile/store/selectors';
import { maskEmail, maskPhone } from '@app/utils';
import { AuthMethod } from '@core/enums';
import { MultiFactorRequest } from '@core/models';
import { IDeliveryMethodService } from '@legacy/services/typings/IDeliveryMethodService';
import { IMultifactorService } from '@legacy/services/typings/IMultifactorService';
import { WireTransfer } from '@legacy/transfers/typings/WireTransfer';

import { Inject } from '../../../decorators/decorators';

@Inject(
  '$window',
  'multifactorService',
  'deliveryMethodService',
  'serviceHelper',
  'popups',
  '$rootScope',
  '$state',
  '$stateParams',
  'ngrxStore'
)
export class WireAccessCodeController {
  wiretransfer: WireTransfer;
  onSuccess: Function;
  onBack: Function;
  phone: string;
  email: string;
  isEmail: boolean;
  code: string;
  isLoading: boolean;
  errorMessage: string;
  saveDevice: boolean;
  message = '';
  phoneNumber: string;
  displayRememberDevice: boolean;
  rememberDeviceHash: string;
  attempts = 0;

  private subsink = new SubSink();
  private _storage: Storage;
  private _request: MultiFactorRequest;

  constructor(
    private _window: ng.IWindowService,
    private _multifactorService: IMultifactorService,
    private _deliveryMethodService: IDeliveryMethodService,
    private _serviceHelper: IServiceHelper,
    private _popups: IPopups,
    private _root: ng.IRootScopeService,
    private state: IStateService,
    private stateParams: IStateParamsService,
    private store: Store
  ) {
    this._storage = this._window.sessionStorage;
  }

  /** Initializes any required data */
  $onInit(): void {
    this.subsink.sink = this.store.select(getProfileDetails).subscribe(data => {
      this._request = JSON.parse(this._storage.getItem('wireAuthRequest'));
      this.isEmail = this._request.authenticationMethod === AuthMethod.Email;
      this.phoneNumber = this._root['brandProperties']['Contact_PhoneNumber'];

      const wireTransferData = this.stateParams['wiretransfer'];

      this.wiretransfer = wireTransferData.transfer.wireTransferDetails;
      this.wiretransfer.documents = wireTransferData.file;
      this.phone = maskPhone(data.cellPhone1.number);
      this.email = maskEmail(data.primaryEmail);
    });
  }

  $onDestroy(): void {
    this.subsink.unsubscribe();
  }

  /** Continue to the nex step  */
  continue(): void {
    this.errorMessage = '';

    this._request.otp = this.code;

    this._deliveryMethodService
      .validateDeliveryOTP(this._request)
      .then(() => {
        this.onSuccess({ code: this.code, remember: null });
      })
      .catch(() => {
        this.attempts++;

        if (this.attempts === 3) {
          this._popups.showAlert(
            '',
            'Your account may be locked. Please contact Customer Support at (844) 999-2967 for assistance.',
            'error',
            () => {
              this.back();
            }
          );
        }
        this.errorMessage = 'Could not validate Verification Code';
      });
  }

  back(): void {
    this.state.go('udb.transfers.wireTransfers.reviewTransfer', {
      wiretransfer: this.wiretransfer,
    });
  }

  /** Perfomrs a call to resend the OTP  */
  resendCode(): void {
    this.isLoading = true;
    this.errorMessage = '';
    this._multifactorService
      .challenge(this._request)
      .then(res => {
        this.attempts = 0;
        // Store in challenge token due resend request
        this._request.challengeToken = res.data.authenticationToken;
        this._storage.setItem('AuthenticationToken', res.data.authenticationToken);

        this._storage.removeItem('wireAuthRequest');

        if (!this.isEmail && res.data.otp) {
          this._popups.showAlert('', `Your OTP is ${res.data.otp} (Disclaimer: Testing purposes only!!!)`, 'info');
        }

        setTimeout(() => {
          angular.element('#access-code-entry').focus();
        }, 200);
      })
      .catch(this._serviceHelper.errorHandler.bind(this._serviceHelper))
      .finally(() => {
        this.isLoading = false;
      });
  }

  /** Constrains the inputs to allow only numbers
   * @param $event The event triggered by the input
   */
  allowOnlyNumbers($event: any): void {
    if (!(($event.charCode >= 48 && $event.charCode <= 57) || $event.keyCode === 8 || $event.keyCode === 13)) {
      $event.preventDefault();
    }
  }
}
