import { AvailableCashTileState } from './available-cash-tile-state.type';

export type AvailableCashState = {
    accountsAvailableCash: AvailableCashTileState[];
    selectedAccountAvailableCash: string | undefined;
};

export const initialAvailableCashState: AvailableCashState = {
    accountsAvailableCash: [],
    selectedAccountAvailableCash: undefined
};
