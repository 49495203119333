<ng-container *ngIf="!selectedTopic; else topic">
  <h3>Deposit & Loan Accounts How To's</h3>

  <ul class="how-to-list">
    <li>
      <button
        id="transaction-history"
        class="link"
        (click)="selectTopic(topics.TransactionHistory)"
      >
        How do I access my transaction history?
      </button>
    </li>
    <li>
      <button id="routing-number" class="link" (click)="selectTopic(topics.RoutingNumber)">
        How do I find the routing number?
      </button>
    </li>
    <li>
      <button id="account-number" class="link" (click)="selectTopic(topics.AccountNumber)">
        How do I find my full account number?
      </button>
    </li>
    <li>
      <button id="order-checks" class="link" (click)="selectTopic(topics.OrderChecks)">
        How do I order checks?
      </button>
    </li>
    <li>
      <button id="statements" class="link" (click)="selectTopic(topics.Statements)">
        How do I access my statements?
      </button>
    </li>
    <li>
      <button id="tax-forms" class="link" (click)="selectTopic(topics.TaxForms)">
        How do I access my tax forms?
      </button>
    </li>
    <li>
      <button id="make-payment" class="link" (click)="selectTopic(topics.MakePayment)">
        How do I make a payment?
      </button>
    </li>
    <li>
      <button id="escrow-information" class="link" (click)="selectTopic(topics.EscrowInformation)">
        How do I access my escrow information?
      </button>
    </li>
    <li>
      <button id="dormant-accounts" class="link" (click)="selectTopic(topics.DormantAccounts)">
        How do I keep my account active?
      </button>
    </li>
  </ul>
</ng-container>

<ng-template #topic>
  <ng-container [ngSwitch]="selectedTopic">
    <how-to-transaction-history
      *ngSwitchCase="topics.TransactionHistory"
    ></how-to-transaction-history>
    <how-to-routing-number *ngSwitchCase="topics.RoutingNumber"></how-to-routing-number>
    <how-to-account-number *ngSwitchCase="topics.AccountNumber"></how-to-account-number>
    <how-to-order-checks *ngSwitchCase="topics.OrderChecks"></how-to-order-checks>
    <how-to-statements *ngSwitchCase="topics.Statements"></how-to-statements>
    <how-to-tax-forms *ngSwitchCase="topics.TaxForms"></how-to-tax-forms>
    <how-to-make-payment *ngSwitchCase="topics.MakePayment"></how-to-make-payment>
    <how-to-escrow-information *ngSwitchCase="topics.EscrowInformation"></how-to-escrow-information>
    <how-to-dormant-accounts *ngSwitchCase="topics.DormantAccounts"></how-to-dormant-accounts>
  </ng-container>
</ng-template>
