import { IRootScopeService } from 'angular';
import NgRedux from 'ng-redux';
import { FeatureFlagService } from 'services/feature-flag.service';
import { AggregatedAccount } from 'typings/app/account-aggregation';
import { UserAccountType } from 'typings/app/bills/UserAccountType';

import { DebitCard as NewDebitCard } from '@app/accounts/submodules/debit-card/models';
import { OlbEvents } from '@core/enums';
import { OlbEventService } from '@core/services';
import { PayBillsHelper } from '@legacy/bill-pay/pay-bills.helper';

import { Inject } from '../decorators/decorators';
import { DebitCardService } from '../services/debit-card.service';
import { AggregatedAccountActions } from './../state-store/actions/accounts/aggregated.actions';
import { ExternalAccountActions } from './../state-store/actions/accounts/external.actions';

@Inject('$rootScope', '$ngRedux', 'payBillsHelper', 'debitCardService', 'featureFlagService', 'olbEventService')
export class CachedAccountsService {
  constructor(
    private rootScope: IRootScopeService,
    private ngRedux: NgRedux.INgRedux,
    private paybillsHelper: PayBillsHelper,
    private debitCardService: DebitCardService,
    private featureFlagService: FeatureFlagService,
    private olbEventService: OlbEventService
  ) {}

  get internalAccounts(): AccountsPage {
    return this.rootScope['accounts'];
  }

  get externalAccounts(): ExternalAccount[] {
    return this.ngRedux.getState().accounts.external
      ? this.ngRedux.getState().accounts.external.map((a: ExternalAccount) => ({ ...a }))
      : [];
  }

  get loanAccounts(): AccountsPage {
    return this.rootScope['accounts'];
  }

  get paymentAccounts(): UserAccountType[] {
    return this.getPaymentAccounts();
  }

  get aggregatedAccounts(): AggregatedAccount[] {
    return this.ngRedux.getState().accounts.aggregated;
  }

  get allAccounts(): TypesAccounts {
    return {
      externalAccounts: this.externalAccounts,
      internalAccounts: this.internalAccounts
        ? this.internalAccounts.depositAccounts.concat(this.internalAccounts.loanAccounts)
        : null,
      loanAccounts: this.internalAccounts
        ? this.internalAccounts.depositAccounts.concat(this.internalAccounts.loanAccounts)
        : null,
    };
  }

  getSwitchAccounts(): OlbAccount[] {
    return !this.rootScope['accounts']?.depositAccounts
      ? []
      : this.rootScope['accounts'].depositAccounts.filter((acc: OlbAccount) => {
          return acc.canMakeDepositSwitch;
        });
  }

  getAggregatedAccounts(): AggregatedAccount[] {
    return this.aggregatedAccounts;
  }

  /* #region Internal Accounts */
  /** Sets the list of internal accounts */
  loadInternalAccounts(data: AccountsPage) {
    this.fixIraBalance(data);
    this.rootScope['accounts'] = data;
    this.rootScope.$broadcast('accountsLoaded');
  }

  /** Sets currentBalance with availableBalance in Ira accounts */
  fixIraBalance(data: AccountsPage) {
    data.depositAccounts.forEach(account => {
      if (!account.isIra) return;

      account.availableBalance = account.currentBalance;
    });
  }
  /* #endregion */

  /* #region External Accounts (Payveris) */

  /** Loads the list of external accounts */
  loadExternalAccounts(data: ExternalAccount[]) {
    this.paybillsHelper.setEnrolledInBillPay();
    this.ngRedux.dispatch(ExternalAccountActions.loadExternalAccounts(data));
  }

  /**
   * Adds an external account to the array of external accounts cached
   * @param externalAccounts External account object
   */
  addExternalAccounts(externalAccounts: ExternalAccount[]) {
    this.ngRedux.dispatch(ExternalAccountActions.addExternalAccounts(externalAccounts));
  }

  /**
   *  Set as an active an Account
   */
  activateInternalAccount(accountId: number, currentAccount: OlbAccount): void {
    const accounts = this.rootScope['accounts'];

    const allAccounts = {
      depositAccounts: accounts.depositAccounts,
      loanAccounts: accounts.loanAccounts,
    };

    let account = allAccounts.depositAccounts.find((acc: OlbAccount) => acc.id === accountId);

    if (!account) {
      account = allAccounts.loanAccounts.find((acc: OlbAccount) => acc.id === accountId);
    }

    if (account) {
      account.status = currentAccount.status;
      account.statusCode = currentAccount.statusCode;
      account.isActive = true;
      account.isDormant = currentAccount.isDormant;
      account.nonActiveMessage = currentAccount.nonActiveMessage;
      account.canBeFromAccount = currentAccount.canBeFromAccount;
      account.canBeToAccount = currentAccount.canBeToAccount;
      account.canPayFromBillPay = currentAccount.canPayFromBillPay;
      account.canTransferFromP2P = currentAccount.canTransferFromP2P;
    }

    accounts.depositAccounts = allAccounts.depositAccounts;
    accounts.loanAccounts = allAccounts.loanAccounts;
    this.rootScope['accounts'] = accounts;
  }

  /**
   * Edits the external account setting a proper nickname
   * @param externalAccount External account object
   */
  updateExternalAccount(externalAccount: ExternalAccount) {
    externalAccount.displayName = this.buildDisplayName(externalAccount);
    this.ngRedux.dispatch(ExternalAccountActions.updateExternalAccounts([externalAccount]));
  }

  /**
   * Removes an external account from the store
   * @param id External account id
   */
  removeExternalAccount(id: number) {
    this.ngRedux.dispatch(ExternalAccountActions.removeExternalAccount(id));
  }
  /* #endregion */

  /* #region Aggregation Accounts */

  /**
   * Sets the aggregated accounts to the redux store
   * @param accounts The aggregated accounts list
   */
  loadAggregatedAccounts(accounts: AggregatedAccount[] | Error) {
    this.ngRedux.dispatch(AggregatedAccountActions.loadAggregatedAccounts(accounts));
    this.rootScope.$broadcast('aggregatedaccountsloaded');
    this.olbEventService.emit(OlbEvents.AggregatedAccountsLoaded);
  }

  /**
   * Adds aggregated accounts to the redux store
   * @param accounts The aggregated accounts list
   */
  addAggregatedAccounts(accounts: AggregatedAccount[]) {
    this.ngRedux.dispatch(AggregatedAccountActions.addAggregatedAccounts(accounts));
  }

  /**
   * Updates the account(s) stored in the redux state
   * @param accounts The aggregated accounts list
   */
  updateAggregatedAccounts(accounts: AggregatedAccount[]) {
    this.ngRedux.dispatch(AggregatedAccountActions.updateAggregatedAccounts(accounts));
  }

  updateAggregatedAccount(account: AggregatedAccount) {
    this.ngRedux.dispatch(AggregatedAccountActions.updateAggregatedAccount(account));
  }

  /**
   * Removes an account from the redux store
   * @param accountId Account unique identifier
   */
  removeAggregatedAccount(accountId: number) {
    this.ngRedux.dispatch(AggregatedAccountActions.removeAggregatedAccount(accountId));
  }

  /**
   * Flush cached aggregated accounts
   */
  flushAggregatedAccounts() {
    this.ngRedux.dispatch(AggregatedAccountActions.flushAggregatedAccounts());
  }
  /* #endregion */

  /** Gets the payment accounts */
  getPaymentAccounts(isP2p: boolean = false): UserAccountType[] {
    if (!this.rootScope['accounts']) return [];

    const accounts = this.rootScope['accounts'].depositAccounts.filter((acc: OlbAccount) => {
      return (acc.canPayFromBillPay && !isP2p) || (acc.canBeFromAccount && isP2p);
    });

    return accounts.map((acc: OlbAccount) => {
      const payAcc = {
        accountId: acc.id,
        accountNumber: acc.accountNumber,
        accountType: acc.name,
        nickName: acc.nickname,
        accountCode: `${acc.accountNumber}-${acc.accountType}`,
        balance: acc.availableBalance,
        balanceSpecified: true,
        payBillsFrom: acc.canPayFromBillPay,
        transferFrom: true,
        transferTo: true,
        canTransferFromP2P: acc.canTransferFromP2P,
        isSBB: acc.isSBB,
        businessCif: acc.sbbUserCif,
      } as UserAccountType;

      return payAcc;
    });
  }

  /**
   * Gets the list of FROM accounts
   * @param withExternal
   */
  getFromAccounts(withExternal: boolean = false): TypesAccounts {
    const { internalAccounts, externalAccounts, loanAccounts } = this.allAccounts;

    if (!internalAccounts) return { internalAccounts: null, externalAccounts: null, loanAccounts: null };

    const fromLoanAccounts = loanAccounts.filter(account => account.isLoan);
    const fromInternalAccounts = internalAccounts.filter(account => account.canBeFromAccount);
    const fromExternalAccounts =
      withExternal && externalAccounts ? externalAccounts.filter(account => account.status == 'Active') : [];

    return {
      internalAccounts: fromInternalAccounts,
      externalAccounts: fromExternalAccounts,
      loanAccounts: fromLoanAccounts,
    };
  }

  /**
   * Gets the list of FROM accounts
   * @param withExternal
   */
  getWireFromAccounts(withExternal: boolean = false): TypesAccounts {
    const { internalAccounts, externalAccounts, loanAccounts } = this.allAccounts;
    if (!internalAccounts) return { internalAccounts: null, externalAccounts: null, loanAccounts: null };

    const fromLoanAccount = loanAccounts.filter(account => account.isLoan);
    const fromInternalAccounts = internalAccounts.filter(account => account.canBeWireAccount);
    const fromExternalAccounts =
      withExternal && externalAccounts ? externalAccounts.filter(account => account.status == 'Active') : [];

    return {
      internalAccounts: fromInternalAccounts,
      externalAccounts: fromExternalAccounts,
      loanAccounts: fromLoanAccount,
    };
  }

  /**
   * Gets the list of TO accounts
   * @param fromAccount
   * @param withExternal
   */
  getToAccounts(fromAccount: number, isExternal: boolean, TypeCode: String) {
    const { internalAccounts, externalAccounts } = this.allAccounts;
    var toInternalAccounts;
    if (TypeCode != 'L') {
      toInternalAccounts = internalAccounts.filter(account => account.canBeToAccount && account.id !== fromAccount);
    } else {
      toInternalAccounts = internalAccounts.filter(
        account => account.canBeToAccount && account.id !== fromAccount && account.accountTypeCode != 'L'
      );
    }

    const toBillPayAccounts =
      !isExternal && externalAccounts
        ? externalAccounts.filter(account => account.status?.toLowerCase() === 'active')
        : [];

    return {
      internalAccounts: toInternalAccounts,
      externalAccounts: toBillPayAccounts,
    };
  }

  /**
   * Constructs the proper display name for the external account
   * @param externalAccount External account object
   */
  buildDisplayName(externalAccount: ExternalAccount): string {
    return !externalAccount.nickname
      ? `${externalAccount.bankName} - ${externalAccount.accountCategory} *${externalAccount.accountMask}`
      : `${externalAccount.bankName} - ${externalAccount.nickname}`;
  }

  /**
   * Return linked Debit Cards
   * @param accountId
   * @param teens
   */
  linkedDebitCards(accountId: number, teens: string[] = []): DebitCard[] | NewDebitCard[] {
    if (!this.rootScope['accounts']) {
      this.rootScope.$on('accountsLoaded', () => {
        this.getDebitCards(accountId, teens);
      });

      return undefined;
    }

    const mergedAccounts = [];
    if (this.rootScope['accounts'].depositAccounts) {
      mergedAccounts.push(...this.rootScope['accounts'].depositAccounts);
    }
    if (this.rootScope['accounts'].loanAccounts) {
      mergedAccounts.push(...this.rootScope['accounts'].loanAccounts);
    }
    const account = mergedAccounts.find((acc: OlbAccount) => acc.id == accountId);

    if (!account) return new Array<DebitCard>();
    const debitCards = account.linkedDebitCards;

    if (!debitCards) this.getDebitCards(accountId, teens);

    return debitCards;
  }

  /**
   * Update Debit Card on cache
   * @param card
   */
  updateLinkedDebitCards(card: DebitCard | NewDebitCard) {
    const debitCard = this.rootScope['accounts'].depositAccounts
      .concat(this.rootScope['accounts'].loanAccounts)
      .find((acc: OlbAccount) => acc.id == card.accountId)
      .linkedDebitCards.find((dc: DebitCard) => dc.cardNumberMask === card.cardNumberMask);

    debitCard.isActive = card.isActive;
    debitCard.isBlocked = card.isBlocked;
    debitCard.status = card.status;
    debitCard.lastStatusChangeDate = card.lastStatusChangeDate;

    debitCard.allowManagement = card.allowManagement;
    debitCard.allowReportLostOrStolen = card.allowReportLostOrStolen;
    debitCard.allowReplacementCard = card.allowReplacementCard;
    debitCard.allowChangeLimits = card.allowChangeLimits;
    debitCard.allowTravelNotification = card.allowTravelNotification;
    debitCard.statusDescription = card.statusDescription;
    debitCard.areCardDetailsVisible = card.areCardDetailsVisible;
  }

  /**
   * Call Debit Card service to get linked Debit Cards
   * @param accountId
   */
  getDebitCards(accountId: number, teens: string[] = []) {
    let accountTeens = '';

    if (teens?.length > 0) {
      teens.map(t => {
        accountTeens = `${t},${accountTeens}`;
      });
    }

    this.debitCardService
      .getCards(accountId, accountTeens)
      .then(resp => {
        const mergedAccounts = [];
        if (this.rootScope['accounts'].depositAccounts) {
          mergedAccounts.push(...this.rootScope['accounts'].depositAccounts);
        }
        if (this.rootScope['accounts'].loanAccounts) {
          mergedAccounts.push(...this.rootScope['accounts'].loanAccounts);
        }
        const account = mergedAccounts.find((acc: OlbAccount) => acc.id == accountId);
        account.linkedDebitCards = resp.data;
        account.linkedDebitCards.map((card: DebitCard) => {
          card.isActive = !card.isBlocked;

          return (card.accountId = accountId);
        });
        this.rootScope.$broadcast('linkedDebitCardsLoaded');
        this.olbEventService.emit(OlbEvents.LinkedDebitCardsLoaded);
      })
      .catch(() => {});
  }

  /**
   * Used to retrieve only loan accounts for make a payment
   */
  getLoanPaymentAccounts() {
    const internalAccounts = this.internalAccounts.loanAccounts;
    const toLoanAccounts = internalAccounts.filter(account => account.canBeToAccount);

    return toLoanAccounts;
  }

  getLoanAccount(accountId: number) {
    return this.internalAccounts.loanAccounts.find(l => l.id == accountId);
  }

  updateAggregatedAccountNickname(id: number, nickname: string) {
    this.ngRedux.dispatch(AggregatedAccountActions.updateNickname(id, nickname));
  }

  getAggregatedAccntsToDdl(): OlbAccount[] {
    if (this.aggregatedAccounts && this.featureFlagService.isAccountAggregationEnhancementsActive()) {
      const accnts = this.aggregatedAccounts
        .map((a: AggregatedAccount) => {
          const displayName = a.nickname ?? a.name;
          let accntNo = a.accountNumber ?? a.accountMask ?? '';
          accntNo = accntNo.substr(accntNo.length >= 4 ? accntNo.length - 4 : 0);

          return { ...a, nickname: displayName, accountNumber: accntNo };
        })
        .filter(a => !['bill', 'reward', 'insurance', 'realEstate'].includes(a.container))
        .sort((a1, a2) => {
          if (a1.category > a2.category) return 1;
          else if (a1.category < a2.category) return -1;
          else {
            if (a1.name > a2.name) return 1;
            if (a1.name < a2.name) return -1;

            return 0;
          }
        });

      return accnts;
    }

    return [];
  }

  // Validate user with personal accounts
  // This method checks if the user has any personal accounts by iterating through the internal accounts
  // and checking if any of the deposit, loan, or RIA accounts have the isSBB flag set to false.
  // If any of the accounts have the isSBB flag set to false, it returns true indicating that the user has personal accounts.
  // Otherwise, it returns false indicating that the user does not have any personal accounts.
  hasPersonalAccounts(): boolean {
    const { depositAccounts, loanAccounts, riaAccounts } = this.internalAccounts;
    return (
      (depositAccounts && depositAccounts.some((acc: OlbAccount) => !acc.isSBB)) ||
      (loanAccounts && loanAccounts.some((acc: OlbAccount) => !acc.isSBB)) ||
      (riaAccounts && riaAccounts.some((acc: OlbAccount) => !acc.isSBB))
    );
  }

  // Validate user with business accounts
  // This method checks if the user has any business accounts by iterating through the internal accounts
  // and checking if any of the deposit, loan, or RIA accounts have the isSBB flag set to true.
  // If any of the accounts have the isSBB flag set to true, it returns true indicating that the user has business accounts.
  // Otherwise, it returns false indicating that the user does not have any business accounts.
  hasBusinessAccounts(): boolean {
    const { depositAccounts, loanAccounts, riaAccounts } = this.internalAccounts;
    return (
      (depositAccounts && depositAccounts.some((acc: OlbAccount) => acc.isSBB)) ||
      (loanAccounts && loanAccounts.some((acc: OlbAccount) => acc.isSBB)) ||
      (riaAccounts && riaAccounts.some((acc: OlbAccount) => acc.isSBB))
    );
  }

  /**
   * Checks if the user is a sole prop based on their CIF number
   * @param userCif The CIF number of the user
   * @returns True if the user is a sole prop, false otherwise
   */
  isSolePropUser(userCif: string): boolean {
    let sbbUserCif = this.internalAccounts.depositAccounts.map(acc => acc.sbbUserCif);

    return sbbUserCif.includes(userCif) ? true : false;
  }
}
