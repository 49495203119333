<td colspan="5">
  <div class="row pt-4">
    <div class="col-12 col-sm-3 col-md-3 col-lg-2 text-left text-sm-right">
      <div class="col-12 transfer-header">
        Category
      </div>
      <div class="col-12 mt-2">
        Fee
      </div>
    </div>
    <div class="col-12 col-sm-8 col-lg-7 mt-3 mt-sm-0">
      <div class="row">
        <div class="col-12">
          <div class="col-12 transfer-header">Description</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-sm-6 mb-4 transfer-detail" *ngFor="let milestoneFee of transaction.data.milestones">
          <div class="col-12 mb-2 goal-name">
            <strong>Goal Name:</strong>
            {{ milestoneFee.milestoneName | titlecase }}
          </div>
          <div class="col-12 mb-2 fee-title">
            <strong>Fee Title:</strong>
            {{ transaction.goal }}
          </div>
          <div class="col-12 mb-2 fee-amount">
            <strong>Fee Amount:</strong>
            {{ milestoneFee.amount | currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</td>
