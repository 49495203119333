import { NgModule } from '@angular/core';
import {
  Uk2TooltipModule,
  Uk2DirectivesModule,
  Uk2ModalModule,
  Uk2ToastModule,
  Uk2TextTileModule,
} from '@axos/uikit-v2-lib';

// * Use this module to export all @axos/uikit-v2 modules required for this feature
@NgModule({
  exports: [Uk2TooltipModule, Uk2DirectivesModule, Uk2ModalModule, Uk2ToastModule, Uk2TextTileModule],
})
export class AccountDetailsUk2Module {}
