import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AxosInvestUrlsService } from '@app/axos-invest/services';
import { UtilityIcons } from '@shared/enums';

@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.scss'],
})
export class ManageAccountComponent implements OnInit {
  $axosInvestUrl: Observable<string>;
  redirectIcon = UtilityIcons.NewWindow;

  constructor(private urlHelper: AxosInvestUrlsService) {}

  ngOnInit(): void {
    this.$axosInvestUrl = this.urlHelper.getOverviewUrl().pipe(take(1));
  }
}
