<div class="outdated-contact-info-banner">
  <div class="row">
    <div class="col-sm-3 col-md-2">
      <img [src]="currentLogo" alt="logo" />
    </div>
    <div class="col-sm-9 col-md-10">
      <h4>Is your personal information correct?</h4>
      <p>Please ensure everything is up to date in case we need to contact you.</p>
    </div>
  </div>
</div>
