import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { AccountTab } from '@app/accounts/enums';
import { DocumentDetail, GroupedDocuments } from '@app/accounts/models';
import { getTradingAccount } from '@app/accounts/store/selectors';
import { groupDocuments } from '@app/accounts/utils';
import { TradingDocumentAccountSubType } from '@app/axos-trading/enums/axos-trading-document-account-sub-type.enum';
import { AxosClearingDocumentsService } from '@app/axos-trading/services/axos-clearing-documents.service';
import { SubSink } from '@axos/subsink';
import { STATE_PARAMS } from '@core/tokens';
import { TradingDocumentAcountType } from '@legacy/axos-trading/statements/axos-trading-document-account-type.enum';
import { DocumentService } from '@legacy/services/document.service';
import { ServiceHelper } from '@legacy/services/service.helper';
import { StatementsService } from '@legacy/services/statements.service';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-axos-trading-statements',
  templateUrl: './axos-trading-statements.component.html',
  styleUrls: ['./axos-trading-statements.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AxosTradingStatementsComponent implements OnInit, OnDestroy {
  @Output() changeTab = new EventEmitter<AccountTab>();

  statements: DocumentDetail[] = [];
  taxForms: DocumentDetail[] = [];
  groupedStatements: GroupedDocuments[] = [];
  groupedTaxForms: GroupedDocuments[] = [];
  months: GenericOption[] = [];
  years: GenericOption[] = [];
  selectedYear: GenericOption;

  documentTypeFilter: GenericOption[] = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Statements' },
    { value: 2, label: 'Documents' },
  ];
  selectedDocType: GenericOption;

  isBusy = false;
  accountNumber: string;
  accountId: number;
  filteredStatements: DocumentDetail[] = [];
  private subsink = new SubSink();
  constructor(
    @Inject(STATE_PARAMS) private params: ng.ui.IStateParamsService,
    private axosClearingDocumentsService: AxosClearingDocumentsService,
    private statementsService: StatementsService,
    private documentService: DocumentService,
    private serviceHelper: ServiceHelper,
    private store: Store,
    private datePipe: DatePipe
  ) {
    this.accountId = Number(this.params.id);
  }

  ngOnInit(): void {
    this.getDocuments();
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  filter(): void {
    this.filterYear();
    this.filterDocumentType();
  }

  getDocumentTypeName(subTypeName: string): string {
    let docName = '';

    switch (subTypeName) {
      case 'Statements':
        docName = 'Statement';
        break;
      case 'Confirms':
        docName = 'Trade Confirmation';
        break;
      case 'Letters':
        docName = 'Notice';
        break;
      default:
        break;
    }

    return docName;
  }

  downloadDocument(acctType: string, documentId: string, key: string, docDate: string): void {
    const onFinally = () => {
      this.isBusy = false;
    };

    let documentDate = this.datePipe.transform(docDate, 'yyyyMMdd');

    if (acctType === TradingDocumentAcountType.Brokerage) {
      this.downloadStatement(documentId, key, documentDate, onFinally);
    } else {
      this.downloadTaxForm(documentId, key, documentDate, onFinally);
    }
  }

  goToTab(tab: AccountTab): void {
    this.changeTab.emit(tab);
  }

  private getDocuments(): void {
    this.isBusy = true;

    this.subsink.sink = this.store
      .select(getTradingAccount(this.accountId))
      .pipe(filter(account => !!account))
      .subscribe(account => {
        this.accountNumber = account.accountNumber;
        this.axosClearingDocumentsService.getDocuments(account.accountNumber).subscribe(res => {
          this.statements = res.filter(c => c.acct_type === TradingDocumentAcountType.Brokerage);
          this.taxForms = res.filter(c => c.acct_type === TradingDocumentAcountType.TaxForms);

          this.setFilterYears(res);

          this.selectedYear = this.years[0];
          this.selectedDocType = this.documentTypeFilter[0];

          // Group documents for display
          this.groupedStatements = groupDocuments(this.statements);
          this.groupedTaxForms = groupDocuments(this.taxForms);

          this.isBusy = false;
        });
      });
  }

  setFilterYears(documents: DocumentDetails[]): void {
    this.years = [
      {
        label: 'All',
        value: 0,
      },
    ];

    const orderYears = documents.map(item => new Date(item.doc_date.toString()).getFullYear());

    // Delete duplicates and set an array with that.
    const uniqueYears = Array.from(new Set(orderYears).values())
      .sort((prev, next) => next - prev)
      .map(year => {
        return {
          label: String(year),
          value: year,
        };
      });
    this.years = this.years.concat(uniqueYears);
  }

  filterYear(): void {
    if (this.selectedYear.value === 0) {
      this.filteredStatements = this.statements;
    } else {
      this.filteredStatements = this.statements.filter(e => {
        return new Date(e.doc_date.toString()).getFullYear() === this.selectedYear.value;
      });
    }

    this.groupedStatements = groupDocuments(this.filteredStatements);
  }

  filterDocumentType(): void {
    switch (this.selectedDocType.value) {
      // All
      case 0:
        {
          this.filteredStatements = this.filteredStatements;
        }
        break;
      // Statements
      case 1:
        {
          this.filteredStatements = this.filteredStatements.filter(
            e => e.sub_type === TradingDocumentAccountSubType.Statements
          );
        }
        break;
      // Documents
      case 2:
        {
          this.filteredStatements = this.filteredStatements.filter(
            e =>
              e.sub_type === TradingDocumentAccountSubType.Confirms ||
              e.sub_type === TradingDocumentAccountSubType.Letters
          );
        }
        break;
      default:
        break;
    }

    this.groupedStatements = groupDocuments(this.filteredStatements);
  }

  downloadStatement(documentId: string, key: string, docDate: string, onFinally: any): void {
    this.isBusy = true;

    const fileName = `STMT_${this.accountNumber.toString().substr(-4)}_${docDate}`;

    this.statementsService
      .getStatement(this.accountId.toString(), documentId, key, fileName, onFinally, true)
      .catch(err => {
        this.serviceHelper.errorHandler(err);
      });
  }

  downloadTaxForm(documentId: string, key: string, docDate: string, onFinally: any): void {
    this.isBusy = true;

    const fileName = `TAX_${this.accountNumber.toString().substr(-4)}_${docDate}`;

    this.documentService.getTaxForm(documentId, key, fileName, onFinally).catch(err => {
      this.serviceHelper.errorHandler(err);
    });
  }
}
