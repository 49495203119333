import { IRootScopeService } from 'angular';
import { Inject } from 'decorators/decorators';
import { CookieHelperService as CookieHelper } from '@app/core/services/cookie.service';

import { FeatureFlagType } from '../common/enums/featureFlagsType.enum';
import { ISettingsService } from './typings/ISettingsService';

@Inject('$rootScope', 'cookieHelper', 'settingsService', 'env')
export class FeatureFlagService {
  constructor(
    private readonly rootScope: IRootScopeService,
    private readonly cookieHelper: CookieHelper,
    private readonly settingService: ISettingsService
  ) {}

  /**
   * Validates the state of the stop payments flag in the app settings.
   * @returns true if the value of the flag is 'true'
   */
  isStopPaymentActive(): boolean {
    return this.isFeatureActive('ActiveStopPayment');
  }

  /** Validates the state of the funding flag in the app settings.
   * @returns true if the value of the flag is 'true'
   */
  isFundingActive(): boolean {
    return this.isFeatureActive('ActiveFunding');
  }
  /**
   * Determines if the current user has account aggregation feature available
   * @returns A promise containing 'true' if the current user can use account aggregation feature
   */
  isAccountAggregationActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.AccountAggregation);
  }
  /**
   * Determines if the current user has account aggregation feature enhancements available
   * @returns A promise containing 'true' if the current user can use account aggregation
   * enhancements feature and account aggregation feature
   */
  isAccountAggregationEnhancementsActive(): boolean {
    return (
      this.isAccountAggregationActive() &&
      this.isFeatureFlagActive(FeatureFlagType.AccountAggregationEnhance)
    );
  }
  /**
   * Flag controls account aggregation features for internal accounts
   */
  isAccountAggregationForInternalsActive(): boolean {
    return (
      this.isAccountAggregationEnhancementsActive() &&
      this.isFeatureFlagActive(FeatureFlagType.AccountAggregationForInternals)
    );
  }

  /**
   * Validates the state of the facing brand redirect flag in the app settings.
   * @returns true if the value of the flag is 'true'
   */
  isOtherFacingBrandRedirectActive(): boolean {
    return this.isFeatureActive('AllowOtherFacingBrandRedirect');
  }

  /** Validates the state of the splash screen flag in the app settings.
   * @returns true if the value of the flag is 'true'
   */
  isSplashScreenActive(): boolean {
    return this.isFeatureActive('SplashScreen');
  }

  /** Validates the state of the yodlee for funding flag in the app settings.
   * @returns true if the value of the flag is 'true'
   */
  isYodleeForFundingActive(): boolean {
    return this.isFeatureActive('ActiveYodleeForFunding', true);
  }

  /** Validates the state of the Transaction Dispute flag in the app settings.
   * @returns true if the value of the flag is 'true'
   */
  isTransactionDisputeActive(): boolean {
    return this.isFeatureActive('ActiveTransactionDispute', true);
  }

  /**
   * Determines if the current user has axos scheduler feature available
   * @returns A promise containing 'true' if the current user can use axos scheduler feature
   */
  isAxosSchedulerActive(): boolean {
    return this.rootScope['isLoanFeatureEnabled'] || false;
  }

  /**
   * Determines if the current user has beneficiaries feature available
   * @returns 'true' if the current user can use beneficiaries feature
   */
  isBeneficiariesActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.Beneficiaries);
  }

  /**
   * Determines if the current user has minors feature available
   * @returns 'true' if the current user can use minors feature
   */
  isMinorsActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.Minors);
  }

  /**
   * Verify if address modification flag is enabled
   */
  isAddressModificationEnabled(): boolean {
    return this.isFeatureActive('AddressModificationEnabled', true);
  }

  /**
   * Verify if the MaintenanceMode flag is in read-only.
   */
  isSiteInReadOnly(): boolean {
    return this.getFeatureValue('MaintenanceMode') === 'readonly';
  }

  /**
   * Verify if the MaintenanceMode flag is active.
   */
  isSiteInMaintenance(): boolean {
    return this.isFeatureActive('MaintenanceMode');
  }

  /**
   * Verify if the MaintenanceMode flag is active.
   */
  isValidateExternalAccounts(): boolean {
    return this.isFeatureActive('ValidateExternalAccount', true);
  }

  isAxosInvestActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.AxosInvest);
  }

  isAxosTradingActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.AxosTrading);
  }

  isRiaPilotActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilot);
  }

  isPushNotificationsActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PushNotifications);
  }

  isPublicFeatureFlagActive(featureFlagType: FeatureFlagType): boolean {
    return this.settingService.publicFeatureFlags.some(
      (f: FeatureFlag) => f.id === featureFlagType
    );
  }

  isOutdatedContactInfoFlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.OutdatedContactInfo);
  }

  isIraEnhBaseFlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.IraEnhBase);
  }

  isIraEnhDiraFlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.IraEnhDira);
  }

  isIraEnhTradFlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.IraEnhTrad);
  }

  isInterstitialServiceEnabled(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.InterstitialService);
  }

  isPFM2FlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PFM2);
  }

  isDirectDepositEnabled(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.ClickSwitch);
  }

  isSBBActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.SBBActive);
  }

  isSBBAccountsInPlanVerticalActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.SBBAccountsInPlanVertical);
  }

  isManagedPortfoliosEnabled(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.ManagedPortfolios);
  }

  isPFM3FlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PFM3);
  }
  isPFM3InternalTrans(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PFM3InternalTrans);
  }
  isPFM3ExternalTrans(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PFM3ExternalTrans);
  }

  isBlendFlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.Blend);
  }

  isEditPortfolioFlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.MPEditPortfolio);
  }

  isGoogleAuthenticatorActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.GoogleAuthenticator);
  }
  isRewardsActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RewardsChecking);
  }
  isSDTMutualFundsActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.SDTMutualFunds);
  }

  isPayoffCalcActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PayoffCalc);
  }

  isPayoffFlowActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PayoffFlow);
  }

  isRiaPilotTransactionOrderScheduledActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RiaPilotTransactionOrderScheduled
    );
  }

  isRiaPilotAddEditTrustedContactsActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RiaPilotAddEditTrustedContacts
    );
  }

  isRiaPilotAddEditInterestedPartyActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RiaPilotAddEditIntertestedParty
    );
  }

  isSBLOCMVPActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.SBLOCMVP);
  }

  isLoanPayOffFlagActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.isLoanPayOff);
  }

  isRiaPilotDocumentAlert(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotDocumentAlert);
  }

  isRiaPilotFirmContactsActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotFirmContacts);
  }

  isFeatureFlagActive(featureFlag: FeatureFlagType): boolean {
    return this.settingService.featureFlags.some(
      (f: FeatureFlag) => f.id === featureFlag
    );
  }
  isRiaPilotAddEditNickname(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotAddEditNickname);
  }

  isRiaPilotOpenCheckingAccount(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RiaPilotOpenCheckingAccount
    );
  }

  isRiaPilotODLActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotODL);
  }

  isRiaPilotAllowedAccountsActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotAllowedAccounts);
  }

  isRiaPilotMoveMoneyActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotMoveMoney);
  }

  isRiaPilotContributionFrequencyActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RiaPilotContributionFrequency
    );
  }

  public isIPaySsoActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.EnableiPaySSO);
  }

  public isRiaModelsAndHoldingsSortActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RiaModelsAndHoldingsSortManagement
    );
  }

  isRiaPilotPortfolioAllocationFlagActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RiaPilotPortfolioAllocation
    );
  }

  isWeb2FooterActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.Web2Footer);
  }
  isRiaPilotICODLDebitCardActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotICODLDebitCard);
  }

  isRequestLoanPayoffOutsystemsActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.RequestLoanPayoffOutsystems
    );
  }

  isRiaPilotHideAllTimeChangeActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotHideAllTimeChange);
  }

  isRiaHoldingsRealTimeActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaHoldingsRealTime);
  }

  isRiaPilotInsightsActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotInsights);
  }

  isRiaShareAccountActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaShareAccount);
  }

  isRiaAuthorizeTransfersActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaAuthorizeTransfers);
  }

  isRiaPilotRealizedGainLossActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotRealizedGainLoss);
  }

  isNinthWaveWebConnectActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.EnableNinthWaveWebConnect);
  }

  isPdpEnhancementActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PdpEnhancement);
  }

  isMpMigrationActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.MpMigration);
  }

  isMpModelSelectionActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.MpModelSelection);
  }

  isMpPortfolioTabActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.MpPortfolioTab);
  }

  isEditBeneficiaryActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.EditBeneficiary);
  }

  isMpDistributionActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.MpDistribution);
  }

  isDecisionMatrixActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.DecisionMatrix);
  }

  isStatementsPageActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.StatementsPage);
  }

  isTransactionPageActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.TransactionPage);
  }

  isPerformancePageActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.PerformancePage);
  }

  isAccDetailsPageActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.AccDetailsPage);
  }

  isUpdateAddressActive(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.UpdateAddress);
  }

  IsRiaPilotAccountSharing(): boolean {
    return this.isFeatureFlagActive(FeatureFlagType.RiaPilotAccountSharing);
  }

  isShowTotalUnrealizedGainLossForInvestmentOverviewActive(): boolean {
    return this.isFeatureFlagActive(
      FeatureFlagType.ShowTotalUnrealizedGainLossForInvestmentOverview
    );
  }

  /**
   * Gets the setting value.
   * @param settingName Name of the setting.
   */
  private getFeatureValue(settingName: string): string {
    const setting = this.rootScope['appSettings'].find(
      (s: AppSetting) => s.name === settingName
    );
    if (!setting) return '';

    return setting.value.toLowerCase();
  }

  /**
   * Validates if a setting is on.
   * @param settingName Name of the setting.
   * @param validateUser Flag to validate the setting against the user id.
   */
  private isFeatureActive(settingName: string, validateUser = false): boolean {
    const value = this.getFeatureValue(settingName);
    if (!value) return false;

    const userId = this.cookieHelper.getUserId() || 0;
    if (validateUser) {
      return value === '*' || value.split(',').includes(userId.toString());
    }

    return value === 'true';
  }
}
