<div *ngIf="todPrimaryBeneficiaries$ | async" class="primary-beneficiary__container">
  <ng-container *ngIf="(todPrimaryBeneficiaries$ | async).length > 0; else emptyState">
    <ng-container *ngFor="let primaryBeneficiary of todPrimaryBeneficiaries$ | async; let i = index">
      <div class="primary-beneficiary__title">
        Transfer on Death Primary Beneficiary {{ (todPrimaryBeneficiaries$ | async).length > 1 ? '#' + (i + 1) : '' }}
      </div>
      <div class="accountNumber-container primary-beneficiary__flex-container">
        <div class="accountNumber-text">Name</div>
        <div class="accountNumber-value">{{ primaryBeneficiary.name ?? blankFormat }}</div>
      </div>
      <div class="primary-beneficiary__divider"></div>
      <div class="accountType-container primary-beneficiary__flex-container">
        <div class="accountType-text">Percentage</div>
        <div class="accountNumber-value">{{ primaryBeneficiary.ipPercent + '%' ?? blankFormat }}</div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #emptyState>
  <div class="primary-beneficiary__title">
    Transfer on Death Primary Beneficiary
  </div>
  <div class="accountNumber-container primary-beneficiary__flex-container">
    <div class="accountNumber-text">Not Yet Assigned</div>
  </div>
</ng-template>
