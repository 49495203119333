import { TooltipController } from './tooltip.controller';

export const TooltipComponent: ng.IComponentOptions = {
  controller: TooltipController,
  controllerAs: '$ctrl',
  templateUrl: 'common/components/tooltip/tooltip.tpl.html',
  transclude: true,
  bindings: {
    message: '@',
    symbol: '@',
  },
};
