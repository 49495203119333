import { GoalType } from '@app/axos-invest/enums';
import { AxosInvestIcons } from '@shared/enums/svg-icons';

export const GOAL_ICONS = {
  [GoalType.NewHome]: AxosInvestIcons.Home,
  [GoalType.NewVehicle]: AxosInvestIcons.Car,
  [GoalType.Retirement]: AxosInvestIcons.Island,
  [GoalType.PersonalWealth]: AxosInvestIcons.Piggy,
  [GoalType.RainyDay]: AxosInvestIcons.Umbrella,
  [GoalType.Custom]: AxosInvestIcons.Briefcase,
  [GoalType.SaveCash]: AxosInvestIcons.Piggy,
};
