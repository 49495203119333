import { Disclosure } from '../types';

export const consumerPrivacyDisclosure: Disclosure = {
  disclosureId: '5aa5acc2-f387-4576-afb7-220b93b3f6fb',
  name: 'ConsumerPrivacyPolicy',
  displayName: 'Privacy Policy',
  link: 'https://www.axosbank.com/-/media/Axos/Documents/Legal/Privacy-and-Security/Privacy-Notice-Axos.pdf',
  accepted: true,
  version: '1',
  rawDisclosure: null,
  ecmName: 'PRIVACY POLICY',
  cabinetName: 'AXOS_CONSUMER_DEPOSIT_ENROLLMENT_DISCLOSURES',
  acceptedMethod: 1,
};

export const eDisclosure: Disclosure = {
  disclosureId: 'f872780e-9de3-4f4d-9538-63e5ee373c2a',
  name: 'EDisclosuresStatementAxos',
  displayName: 'E-Disclosures Statement',
  link: 'https://www.axosbank.com/-/media/Axos/Documents/E-Disclosure-Statement/E-Disclosure-Statement-Axos.pdf',
  accepted: true,
  version: '1',
  rawDisclosure: null,
  ecmName: 'E-CONSENT',
  cabinetName: 'AXOS_CONSUMER_DEPOSIT_ENROLLMENT_DISCLOSURES',
  acceptedMethod: 1,
};

export const personalDepositAccountDisclosure: Disclosure = {
  disclosureId: '945ec119-1682-4e2f-bafd-9cc652fc83b0',
  name: 'PersonalDepositAccountAgreementScheduleOfFeeAxos(Axos)',
  displayName: 'Personal Deposit Account Agreement',
  link:
    'https://www.axosbank.com/-/media/Axos/Documents/Legal/Personal-Deposit-Account-Agreement-and-Schedule-of-Fees--Axos.pdf',
  accepted: true,
  version: '1',
  rawDisclosure: null,
  ecmName: 'CONSUMER ACCOUNT AGREEMENT - AXOS',
  cabinetName: 'AXOS_CONSUMER_DEPOSIT_ENROLLMENT_DISCLOSURES',
  acceptedMethod: 1,
};

export const odlDisclosure: Disclosure = {
  disclosureId: 'D3AE985E-BC2B-4E6C-AE18-269910AEB32A',
  name: 'OverdraftLineofCredit',
  displayName: 'Overdraft Line of Credit Terms and Conditions',
  link: null,
  accepted: true,
  version: '1',
  rawDisclosure: null,
  ecmName: null,
  cabinetName: 'AXOS_CONSUMER_DEPOSIT_ENROLLMENT_DISCLOSURES',
  acceptedMethod: 1,
};

export const fairTradeDisclosure: Disclosure = {
  disclosureId: '7a476266-c69f-431a-9b2b-2a680b63ec79',
  name: 'FCRA',
  displayName: 'Fair Credit Reporting Act',
  link: null,
  accepted: true,
  version: '1',
  rawDisclosure: null,
  ecmName: null,
  cabinetName: 'Axos',
  acceptedMethod: 1,
};

export const tinDisclosure: Disclosure = {
  disclosureId: '5b73e763-a45b-44b5-bfec-9e54f5028a34',
  name: 'TINCertification',
  displayName: 'Taxpayer Identification Number Certification',
  link: null,
  accepted: true,
  version: '1',
  rawDisclosure: null,
  ecmName: null,
  cabinetName: 'Axos',
  acceptedMethod: 1,
};
