<section id="account-features-section" *ngIf="hasFeaturesToShow">
  <h5>Account Features</h5>
  <div id="odl-feature" class="feature-container" *ngIf="isRiaPilotOdlActive">
    <img src="../../../../assets/axos/img/icons/money-arrows.svg" class="feature-icon" [hidden]="accountHasOdl" />
    <div class="feature-text">
      <p class="feature-name">{{ this.accountHasOdl ? 'Overdraft Protection' : 'Activate Overdraft Protection' }}</p>
      <p class="feature-details" [hidden]="accountHasOdl">
        This account is eligible for
        <span style="font-weight: 500;">up to $5,000 in protection,</span>
        with no credit check required.
      </p>
      <p class="feature-details" [hidden]="!accountHasOdl">
        This account currently has
        <span style="font-weight: 500;">$5,000 in overdraft protection.</span>
      </p>
      <span class="feature-tooltip" [hidden]="accountHasOdl">
        How it works
        <app-tooltip
          class="odl-tooltip"
          symbol="i"
          content="An Overdraft Line of Credit is a cost-effective, convenient way to protect against situations where you have a low account balance."
        ></app-tooltip>
      </span>
      <a href="#" (click)="goToODLAccountDetails()" class="view-odl-account" [hidden]="!accountHasOdl">
        View overdraft protection account
      </a>
    </div>
    <span class="feature-switch">
      <label class="uk-switch custom-switch" [hidden]="accountHasOdl">
        <input id="odl-switch" type="checkbox" (click)="openOverdraftLineOfCreditCrossSellModal($event)" />
        <span class="slider">
          <i class="slider--icon"></i>
        </span>
      </label>
      <p class="feature-name" [hidden]="!accountHasOdl">Activated</p>
    </span>
  </div>
</section>
