import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationModule } from '@app/authentication/authentication.module';
import { sharedComponents } from './components';
import { coreProviders } from './providers';
import { WINDOW } from './tokens';

const exportedModules = [BrowserModule, HttpClientModule, AuthenticationModule, BrowserAnimationsModule];

@NgModule({
  declarations: [...sharedComponents],
  imports: [...exportedModules],
  exports: [...exportedModules, ...sharedComponents],
  providers: [...coreProviders, { provide: WINDOW, useValue: window }],
})
export class CoreModule {}
