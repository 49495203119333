import { createAction, props } from '@ngrx/store';

import { AuthorizationsTabState } from './types';

export type AddUpdateAuthorizationsToAccountActionProps = {
  payload: {
    authorizationTabState: AuthorizationsTabState;
    accountNumber: string | undefined;
  };
};

export const addUpdateAuthorizationsToAccountAction = createAction(
  '[AUTHORIZATIONS] Add - Update authorizations to account',
  props<AddUpdateAuthorizationsToAccountActionProps>()
);
