import { Inject } from '../decorators/decorators';
import { BaseService } from './base.service';
import { IProviderService } from 'services/typings/IProviderService';

@Inject('$http', 'env', 'serviceHelper', '$q')
export class ProviderService extends BaseService implements IProviderService {
  constructor(http: ng.IHttpService, env: any, _serviceHelper: IServiceHelper, q: ng.IQService) {
    super(http, env, 'provider', _serviceHelper, q);
  }

  getProviders(): ApiResponse<Provider[]> {
    return this.get();
  }
}
