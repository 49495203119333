import { FUNDING } from '../constants/funding';
import { FundingMethod } from 'typings/app/FundingMethod';
import { FundingAccount } from '@core/models';

/**
 * Funding actions that will be dispatched to the store and then evaluated in the reducer
 * when the state changes
 */
export const FundingActions = {
  turnOnFunding: () => {
    return {
      type: FUNDING.TURN_ON,
    };
  },
  turnOffFunding: () => {
    return {
      type: FUNDING.TURN_OFF,
    };
  },
  changeMethod: (method: FundingMethod) => {
    return {
      type: FUNDING.CHANGE_METHOD,
      payload: method,
    };
  },
  changeAmount: (amount: number) => {
    return {
      type: FUNDING.CHANGE_AMOUNT,
      payload: amount,
    };
  },
  setupAccountId: (accountId: number) => {
    return {
      type: FUNDING.SETUP_ACCOUNT_ID,
      payload: accountId,
    };
  },
  setupAccounts: (accounts: OlbAccount[]) => {
    return {
      type: FUNDING.SETUP_ACCOUNTS,
      payload: accounts,
    };
  },
  setupFundingAccounts: (accounts: FundingAccount[]) => {
    return {
      type: FUNDING.SETUP_FUNDING_ACCOUNTS,
      payload: accounts,
    };
  },
  setupFromAccountId: (fromAccountId: number) => {
    return {
      type: FUNDING.SETUP_FROM_ACCOUNT_ID,
      payload: fromAccountId,
    };
  },
  onFundedAccount: () => {
    return {
      type: FUNDING.ON_FUNDED_ACCOUNT,
    };
  },
};
