import { ChartSelectionItem } from '@app/pfm/models';

export const pfmChartItemsConst: ChartSelectionItem[] = [
  {
    id: 0,
    name: 'donutChart',
    isFirst: true,
    isLast: false,
    isActive: true,
  },
  {
    id: 1,
    name: 'lineChart',
    isFirst: false,
    isLast: true,
    isActive: false,
  },
];
