import * as angular from 'angular';

import { SharedModule } from '@legacy/shared/shared.module';

import { MessageDetailConfig } from './message-detail.config';
//import { MessageDetailController } from './message-detail.controller';
import { MessageDetailComponent } from '@app/messages/components';
import { downgradeComponent } from '@angular/upgrade/static';

export const MessageDetailModule = angular
  .module('udb.messageDetail', ['ui.router', 'ui.bootstrap', SharedModule])
  .directive('appMessageDetail', downgradeComponent({ component: MessageDetailComponent }))
  //.controller('MessageDetailController', MessageDetailController)
  .config(MessageDetailConfig).name;
