import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef  } from '@angular/material/legacy-dialog';

import { OlbSettings } from '@core/models';
import { olbSettings } from '@core/tokens';
import { AlertsIcons } from '@shared/enums';

@Component({
  selector: 'app-ria-distribution-modal',
  templateUrl: './ria-distribution-modal.component.html',
  styleUrls: ['./ria-distribution-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiaDistributionModalComponent implements OnInit {
  icon = {
    size: '5rem',
    name: AlertsIcons.ExclamationCircle
  };

  backgroundUrl: string;
  constructor(
    @Inject(olbSettings) public settings: OlbSettings,
    private dialogRef: MatDialogRef<RiaDistributionModalComponent>
  ) { }

  ngOnInit(): void {
    const { brand } = this.settings;

    this.backgroundUrl = `url('/assets/${brand}/bg_pattern.jpg')`;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
