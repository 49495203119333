import { Biller } from 'typings/app/bills/Biller';
import { BillerGroup } from 'typings/app/bills/BillerGroup';
import { PartialPayee } from 'typings/app/bills/PartialPayee';

import { OlbPaymentResponse } from '@legacy/bill-pay/typings/PaymentResponse';

import { Inject } from '../decorators/decorators';
import { AddPayeeResult } from '../typings/app/bills/AddPayeeResult';
import { DeletePayeeResponse } from '../typings/app/bills/DeletePayeeResponse';
import { Payee } from '../typings/app/bills/Payee';
import { PayeeInfo } from '../typings/app/bills/PayeeInfo';
import { PaymentHistory } from '../typings/app/bills/PaymentHistory';
import { PaymentInfo } from '../typings/app/bills/PaymentInfo';
import { RecurrentPaymentInfo } from '../typings/app/bills/RecurrentPaymentInfo';
import { ScheduledPayment } from '../typings/app/bills/ScheduledPayment';
import { ScheduledPaymentInfo } from '../typings/app/bills/ScheduledPaymentInfo';
import { UserAccountType } from '../typings/app/bills/UserAccountType';
import { BaseService } from './base.service';

@Inject('$http', 'env', 'serviceHelper', '$q', '$cacheFactory')
export class PaymentsService extends BaseService {
  private readonly paymentsCacheName: string = 'paymentsCache';
  private readonly paymentsCache: ng.ICacheObject;
  constructor(
    http: ng.IHttpService,
    env: OlbSettings,
    serviceHelper: IServiceHelper,
    q: ng.IQService,
    private readonly cacheFactory: ng.ICacheFactoryService
  ) {
    super(http, env, 'payment', serviceHelper, q);
    // Initialize cache object for payments
    let cache = cacheFactory.get('paymentsCache');
    if (!cache) {
      cache = this.cacheFactory(this.paymentsCacheName);
    }
    this.paymentsCache = cache;
  }

  /**
   * Enrolls current user in Bill Pay
   */
  enrollInBillPay(): ApiResponse<string> {
    return this.post('enrollUser');
  }

  /**
   * Get the customer limits for p2p, bill pay, ext transfers, etc.
   * @returns A promise containing the customer limits.
   */
  getCustomerLimits(): ApiResponse<CustomerLimits> {
    return this.get('customerLimits');
  }

  /**
   * Get the list of payees that the user has added.
   * @returns A promise containing a list of the payees.
   */
  getPayees(): ApiResponse<Payee[]> {
    return this.get('billers');
  }

  /**
   * Get the a payee that the user has added.
   * @param payeeId Unique payee identifier.
   * @returns A promise containing a list of the payees.
   */
  getPayee(payeeId: number): ApiResponse<Payee> {
    return this.get(`payee/${payeeId}`);
  }

  /**
   * Get the list of payees info to the make payment page.
   * @returns A promise containing a list of the payees ifno.
   */
  getPayeeInfo(justPaymentsDue: boolean = false): ApiResponse<PayeeInfo[]> {
    return this.get(`payeeInfo/${justPaymentsDue}`);
  }

  /**
   * Get the payee payee payee info
   * @param payeeId Unique payee identifier
   * @returns A promise containing the payee info.
   */
  getPayeeDetails(payeeId: number): ApiResponse<any> {
    return this.get(`payee/${payeeId}/detail`);
  }

  /**
   * Saves an electronic payee
   * @param payee An electronic payee.
   * @returns A promise containing a list of addresses for the payee.
   */
  saveElectronicPayee(payee: PartialPayee): ApiResponse<AddPayeeResult> {
    return this.post('payee/electronic', payee);
  }

  /**
   * Saves an Check payee
   * @param payee A check payee.
   * @returns A promise containing a list of addresses for the payee.
   */
  saveCheckPayee(payee: PartialPayee): ApiResponse<AddPayeeResult> {
    return this.post('payee/check', payee);
  }

  /**
   * Get billers list.
   * @param query Param to perform a search in Payveris to get billers.
   * @returns A promise containing a list of the billers that matched the search string.
   */
  getBillers(query: string): ApiResponse<Biller[]> {
    return this.get(`billers/${query}`);
  }

  /**
   * Get biller Address by biller.
   * @param billerId Param to perform a search in payveris to get biller address.
   * @returns A promise containing the address or addresses for the biller.
   */
  getBillerAddress(billerId: number): ApiResponse<BillerAddress[]> {
    return this.get(`billers/${billerId}/addresses`);
  }

  /**
   * Get the top billers from Payveris.
   * @returns A promise containing a list of top billers.
   */
  getTopBillers(): ApiResponse<BillerGroup[]> {
    return this.get('billers');
  }

  /**
   * Gets the payment history
   * @returns {promise} A promise with the result
   */
  getPaymentHistory(
    startDate: string = '',
    endDate: string = '',
    recipient: string = ''
  ): ApiResponse<PaymentHistory[]> {
    return this.get('payee/history', { startDate, endDate, recipient });
  }

  /**
   * Gets the payment history of a specific payee
   * @returns {promise} A promise with the result
   */
  getPaymentHistoryByPayee(
    payeeId: number,
    startDate: string,
    endDate: string
  ): ApiResponse<PaymentHistory[]> {
    return this.get(`payee/${payeeId}/history`, { startDate, endDate });
  }

  getAccountsForPayments(): ApiResponse<UserAccountType[]> {
    return this.get('accounts');
  }

  /**
   * Make a payment to payveris.
   * @returns a Promise containing the result of the operation.
   */
  makePayment(payInfo: PaymentInfo): ApiResponse<OlbPaymentResponse> {
    return this.post('makePayment', payInfo);
  }

  /**
   * Validates if a account number is valid for a biller.
   * @returns A promise containing the result and a list of addresses for the biller.
   */
  validateBillerAccountNumber(
    accountNumber: string,
    Id: Number
  ): ApiResponse<BillerAccountValidation> {
    return this.post('billers/validate', { accountNumber, Id });
  }

  /**
   * Check if the user is enrolled or not, if not it enrolls the user.
   * @returns { A promise the result of the enrollment}
   */
  enrollUser(): ApiResponse<any> {
    return this.get('enroll');
  }

  /**
   * Updates the payee details (electronic & check).
   * @param Payee the payee to update
   * @returns A promise containing the payee updated
   */
  updatePayee(payee: Payee): ApiResponse<Payee> {
    return this.put('payee', payee);
  }

  /**
   * First step to activate an EBill to a Payee.
   * @returns A promise the result of the first step.
   */
  addEBill(payeeId: number): ApiResponse<VerifyEBillResponse> {
    return this.put(`payee/${payeeId}/ebill`);
  }

  /**
   * Selects a site from the biller
   * @returns A promise the result of the selection.
   */
  selectEBillSite(billerSite: SelectBillerSite): ApiResponse<VerifyEBillResponse> {
    return this.post('payees/ebill/site', billerSite);
  }

  /**
   * validates the credentials to activate an EBill
   * @returns A promise the result of the login.
   */
  verifyEBillLogin(loginInfo: VerifyEBillLogin): ApiResponse<EBillLoginResponse> {
    return this.post('payee/eBill/login', loginInfo);
  }

  /**
   * Check the status of the session
   * @returns A promise the result of the status.
   */
  getEBillStatus(sessionId: string): ApiResponse<EBillStatusResponse> {
    return this.get(`eBill/status/${sessionId}`);
  }

  /**
   * Selects an account to link it to the EBill
   * @returns A promise hte result of the selection.
   */
  selectEBillAccount(billerAccount: SelectBillerAccount): ApiResponse<EBillStatusResponse> {
    return this.post('eBill/account', billerAccount);
  }

  /**
   * Selects an account to link it to the EBill
   * @returns A promise hte result of the selection.
   */
  deactivateEBill(payeeId: number): ApiResponse<EBillResponseBase> {
    return this.delete(`payee/${payeeId}/eBill`);
  }

  /**
   * Adds a recurring payment to a biller;
   * @returns A promise with the result of the scheduled payment.
   */
  addScheduledPayment(payment: RecurrentPaymentInfo): ApiResponse<ScheduledPayment[]> {
    return this.post('', payment);
  }

  /**
   * Schedule multiple non recurrent payments
   * @param payments
   * @returns A promise with the result of the scheduled payments.
   */
  addMultipleScheduledPayments(payments: RecurrentPaymentInfo[]): ApiResponse<ScheduledPayment[]> {
    return this.post('multipleSchedule', payments);
  }

  /**
   * Gets the most recent EBills detailed statement for the payee.
   * @returns A promise containing the pdf with the statement.
   */
  getEBillStatement(eBillId: number): any {
    return this.get(`eBill/${eBillId}/statement`, {}, false, null, 'arraybuffer');
  }

  /**
   * Gets the scheduled payment from the current user
   * @returns A promise containing all the scheduled payments.
   */
  getAllScheduledPayments(): ApiResponse<ScheduledPaymentInfo[]> {
    return this.get();
  }

  /**
   * Gets the scheduled payment by account
   * @returns A promise containing all the scheduled payments of an account.
   */
  getScheduledPaymentsByAccount(
    accountId: number,
    dayRange?: number
  ): ApiResponse<ScheduledPaymentInfo> {
    return this.get(`account/${accountId}/${dayRange}`, null, this.paymentsCache);
  }

  /**
   * Updates a scheduled payment series
   * @returns A promise containing all the updated scheduled payments.
   */
  updateScheduledPayment(payment: RecurrentPaymentInfo): ApiResponse<ScheduledPayment> {
    return this.put('', payment);
  }

  /**
   * Delete a scheduled payment series.
   */
  deleteScheduledPayment(ids: number[]): ApiResponse<ScheduledPayment> {
    return this.delete('schedule', { ids });
  }

  /**
   * Delete a Payee and its scheduled payments.
   */
  deletePayee(id: number): ApiResponse<DeletePayeeResponse> {
    return this.delete(`payee/${id}`);
  }

  /**
   * Get the default account for payments.
   */
  getDefaultAccount(): ApiResponse<UserAccountType> {
    return this.get(`account`);
  }

  /**
   * Gets the name of a bank with the given routing number.
   * @param routingNumber Bank Routing number.
   * @returns A promise containing the bank name.
   */
  getBankName(routingNumber: string): ApiResponse<string> {
    return this.get(`bankName/${routingNumber}`);
  }

  /**
   * Clears paymentsCache to reload data
   */
  clearCache(): void {
    if (this.paymentsCache) {
      this.paymentsCache.removeAll();
    }
  }
}
