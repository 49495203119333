import * as moment from 'moment';

export class LoanAccountHelper {
  constructor() {}

  isPaymentLate(dueDate: Date) {
    if (moment().diff(dueDate, 'days') > 89) {
      let modalText =
        `Your loan is more than 90 days past due and is not eligible for online payments.\n\n` +
        `Please call Collection Department at 858-350-6200 ext. 1241.`;
      return { isLate: true, lateMessage: modalText };
    }
    return { isLate: false, lateMessage: '' };
  }

  isAccountMature(loanAccount: OlbAccount) {
    if (loanAccount.statusCode === '3') {
      let modalText =
        `Your loan is matured and is not eligible for online payments. \n\n` +
        `Please call Loan Servicing at 866-923-7112.`;
      return { isMature: true, matureMessage: modalText };
    }
    return { isMature: false, matureMessage: '' };
  }

  isPayoff(loanAccount: OlbAccount, totalAmount: number) {
    if (loanAccount.productTypeValue === 8 && loanAccount.outstandingBalance - totalAmount < 100) {
      let modalText = `Because you are attempting to make your last payment, please contact Loan Servicing at 866-923-7112.`;
      return { payOff: true, payOffMessage: modalText };
    }
    return { payOff: false, payOffMessage: '' };
  }
}
