<div class="sbloc-offers-container">
  <div class="sbloc-offers-header">
    <div class="img-line">
      <div class="left">
        <img src="../../../../assets/axos/logos/axos-logo.png" class="logo" alt="Axos" />
      </div>
    </div>
    <div class="center"><h1>Securities Backed Line of Credit</h1></div>
    <div class="center-responsive"><h1>Securities Backed Line...</h1></div>
    <img
      style="cursor: pointer;"
      (click)="goDashboard()"
      src="../../../../assets/img/icons/sbloc/primary.png"
      class="right"
      alt="close"
    />
  </div>

  <div class="sbloc-iframe-container">
    <iframe
      height="100%"
      width="100%"
      id="SblocIFrame"
      title="personalized Content"
      class="content"
      [src]="safeUrl"
    ></iframe>
  </div>

  <div class="sbloc-offers-footer">
    <button class="PrimaryButton" (click)="goPreApprovedOffer()" type="button">
      See My Pre-Approved Offer
    </button>
  </div>
</div>
