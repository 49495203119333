import { formatCurrency } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { ToolTipType } from '@app/pfm/enums/tooltip-type-enum';
import { ChartOptionsDonut } from '@app/pfm/models';
import { TxFilter } from '@legacy/accounts/typings/TxFilter';

import { Category } from '../../models/category';
import { Highlight } from '../../utils/chartjs-plugins/highlight';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() categories: Category[];
  @Input() filters: TxFilter;
  @Output() toggleCategoryFilter = new EventEmitter();
  @Input() chartjsOptions: any;
  @Input() width: number;
  @Input() height: number;
  @Input() showTooltips = true;
  @Input() tooltipType: ToolTipType;
  tooltipTypeEnum = ToolTipType;

  chart: Chart;
  @ViewChild('tooltip') tooltip;
  @ViewChild('chart') private chartRef;
  private data: any;
  private options: object;
  constructor() {}

  getTooltipStyle(tooltipType: ToolTipType): string {
    let toolTypeStyle = '';

    switch (tooltipType){
      case ToolTipType.White:
          toolTypeStyle = 'tooltip-white';
          break;
        default:
          toolTypeStyle = 'tooltip-black';
          break;
    }

    return toolTypeStyle;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }
  getOptions(): ChartOptionsDonut {
    const self = this;

    const options = {
      ...this.chartjsOptions,
      legend: {
        display: false,
      },
      onClick(_, elements) {
        // toggle clicked category in the filters
        if (!elements[0] || elements[0]['_index'] === undefined) return;
        const clickedSliceIndex = elements[0]['_index'];
        const categoryId = self.categories[clickedSliceIndex].id;

        self.toggleCategoryFilter.emit({ categoryId });
      },
      rotation: -1 * (Math.PI / 180),
      tooltips: {
        enabled: false,
        backgroundColor: '#333D46',
        titleFontColor: 'white',
        titleFontSize: 0,
        bodyFontColor: 'white',
        displayColors: false,
        custom: (tooltip: any) => {
          if (!this.showTooltips) return;
          this.createTooltip(tooltip);
        },
      },
    };

    return options;
  }

  getData(tooltipType: ToolTipType): ChartData<any> {
    let labels: string[] = [];
    switch (tooltipType){
      case ToolTipType.White:
        labels = this.categories.map(category => category.name);
        break;
       default:
        labels = this.categories.map(category => category.imageFile);
        break;
    }

    const data = {
      labels,
      datasets: [
        {
          data: this.categories.map(category => category.amount),
          backgroundColor: this.categories.map(category => category.color),
        },
      ],
    };

    return data;
  }

  updateChart() {
    if (!this.chartRef) return;

    this.data = this.getData(this.tooltipType);
    this.options = this.getOptions();

    if (!this.chart) {
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'doughnut',
        plugins: [new Highlight()],
        data: this.data,
        options: this.options,
      });
    } else {
      this.tooltip.nativeElement.style.opacity = '0';
    }

    // fields that might change after chart creation
    this.chart.chart.config.data.labels = this.data.labels;
    this.chart.chart.config.data.datasets[0].data = this.data.datasets[0].data;
    this.chart.chart.config.data.datasets[0].backgroundColor = this.data.datasets[0].backgroundColor;

    if (this.filters) {
      this.chart.chart.options.plugins.highlight.indices = this.categories
        .map((category, i) => (this.filters.categories.indexOf(category.id) >= 0 ? i : null))
        .filter(i => i != null);
    }

    this.chart.chart.update();
  }

  createTooltip(tooltip: any) {
    if (tooltip.opacity === 0) {
      this.tooltip.nativeElement.style.opacity = '0';

      return;
    }

    this.tooltip.nativeElement.classList.remove('above', 'below', 'no-transform');
    if (tooltip.yAlign) {
      this.tooltip.nativeElement.classList.add(tooltip.yAlign);
    } else {
      this.tooltip.nativeElement.classList.add('no-transform');
    }

    if (tooltip.body) {
      this.tooltip.nativeElement = this.setTooltipText(this.tooltip.nativeElement, tooltip, this.tooltipType);
    }

    this.tooltip.nativeElement = this.setPosition(this.tooltip.nativeElement, tooltip, this.tooltipType);
  }

  setTooltipText(tooltipEl: HTMLElement, tooltip: any, tooltipType: ToolTipType): HTMLElement {
    const bodyLines = tooltip.body.map(item => item.lines);

    let innerHtml = '<thead>';
    innerHtml += '</thead><tbody>';
    let bodyArray = bodyLines[0];
    let logo: string;
    let balance: string;
    switch (tooltipType){
      case ToolTipType.White:
        bodyArray = bodyArray[0].split(':');
        const categoryName = bodyArray[0];
        balance = formatCurrency(bodyArray[1], 'en-US', '$');
        innerHtml += '<tr><td>' + categoryName + '<tr><td>';
        innerHtml += '<tr><td><b>' + balance + '</b></td></tr>';
        break;
      default:
        bodyArray = bodyArray[0].split(' ');
        balance = formatCurrency(bodyArray[1], 'en-US', '$');
        logo = `<img alt="" style="filter: brightness(200)" src="assets/img/logos/categories/` +
                bodyArray[0].replace(':', '') +
                `"/>`;
        innerHtml += '<tr><td>' + logo + ': ' + balance + '</td></tr>';
        break;
    }

    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;

    return tooltipEl;
  }

  setPosition(tooltipEl: HTMLElement, tooltip: any, tooltipType: ToolTipType): HTMLElement {
    const ctx = this.chartRef.nativeElement.getContext('2d');
    const positionY = ctx.canvas.offsetTop;
    const positionX = ctx.canvas.offsetLeft;
    let movePositionY: number;

    movePositionY = tooltipType === ToolTipType.White ?  -55 : 0;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + movePositionY + tooltip.caretY + 'px';
    tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
    tooltipEl.style.fontSize = tooltip.bodyFontSize;
    tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
    tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';

    return tooltipEl;
  }
}
