import { RedirectStateService } from '@legacy/services/RedirectStateService';

export function redirectStateServiceFactory($injector: any) {
  return $injector.get('redirectStateService');
}

export const redirectStateServiceProvider = {
  provide: RedirectStateService,
  useFactory: redirectStateServiceFactory,
  deps: ['$injector'],
};
