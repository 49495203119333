import { OnlinePath } from '../enums';
import { SwitchField } from '../models';

/**
 * Get Html Instructions
 * @param fields Collection of UX fields to search for instructions
 * @returns Return the bodyHtml, incomeSourceUrl and webSite data
 */
export function getInstructions(fields: SwitchField[]) {
  const result = {
    bodyHtml: '',
    incomeSourceUrl: '',
    webSite: '',
  };

  const htmlBody = fields?.find(
    f => f.key === OnlinePath.keyInstructions && f.type === OnlinePath.htmlType
  );

  if (htmlBody && htmlBody.body) {
    result.bodyHtml = htmlBody.body;

    const parser = new DOMParser();
    const doc = parser.parseFromString(result.bodyHtml, 'text/html');
    const links = doc.getElementsByTagName('a');

    if (links.length > 0) {
      result.incomeSourceUrl = links[0].href.replace(/&amp;/g, '&');
      result.webSite = result.incomeSourceUrl.substring(0, result.incomeSourceUrl.indexOf('/', 8));
    }
  }

  return result;
}
