import { ChartConfiguration } from '@app/axos-trading/models';

export const CHART_CONFIGURATION: ChartConfiguration[] = [
  {
    sortable: 0,
    dataLabelFormat: 'MMMM do, yyyy',
    timeLabelFormat: 'MMM do, yyyy',
    timeLabel: 'Value on ${date}',
    duration: 'days',
    difference: 7,
  },
  {
    sortable: 1,
    dataLabelFormat: 'MMMM do, yyyy',
    timeLabelFormat: 'MMMM do, yyyy',
    timeLabel: 'Value on ${date}',
    duration: 'months',
    difference: 1,
  },
  {
    sortable: 2,
    dataLabelFormat: 'MM/dd/yy',
    timeLabelFormat: 'MMMM do, yyyy',
    timeLabel: 'Value on ${date}',
    duration: 'months',
    difference: 3,
  },
  {
    sortable: 3,
    dataLabelFormat: 'MMMM do, yyyy',
    timeLabelFormat: 'MMMM do, yyyy',
    timeLabel: 'Value on ${date}',
    duration: 'months',
    difference: 12,
  },
  {
    sortable: 4,
    dataLabelFormat: 'yyyy',
    timeLabelFormat: 'MMMM do, yyyy',
    timeLabel: 'Value on ${date}',
    duration: 'years',
    difference: 5,
  },
];
